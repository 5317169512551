import React from 'react'
import { lineLiff, LINE_CHANNEL_ID, LINE_ORG_ID } from '../../config/line';
import { getRouteParams, redirectReplace, ROUTE_PATH } from '../../helpers';
import { DATA } from '../../helpers/constants/data';
import { changeLanguage } from '../../helpers/functions/language';
import { setAuthApp, setLineData } from '../../helpers/functions/main';

export class LineLiffPage extends React.Component {
  constructor(props) {
    super(props);
    const search = props.location.search;
    const params = new URLSearchParams(search);
    let data = params.get('data');
    this.state = {
      data,
    }
  }

  componentDidMount() {
    const {
      data,
    } = this.state;
    lineLiff.ready.then(async () => {
      if (!lineLiff.isLoggedIn()) {
        lineLiff.login();
      } else {
        // lineLiff.logout();
        const profile = await lineLiff.getProfile();
        console.log('profile', profile);
        const lineData = {
          orgId: LINE_ORG_ID,
          lineId: profile.userId,
          channelId: LINE_CHANNEL_ID,
          name: profile.displayName,
          statusMessage: "loading...",
          language: lineLiff.getLanguage(),
          profileImageUrl: profile.pictureUrl || '',
        }
        console.log('profile', profile);
        console.log('lineData', lineData);
        setLineData(lineData);
        redirectReplace(`${ROUTE_PATH.AUTH.LINK_WITH_PARAMS}${data}/${profile.userId}`);
      }
    });
  }


  render() {
    return null
  }
}
