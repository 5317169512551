import React from 'react'
import _ from 'lodash'
import axios from 'axios'
import ReactHtmlParser from 'react-html-parser';
import {
  TransitionContainer,
  Container,
  Image,
  Section,
  Text,
  NavigationBar,
} from './../../components'
import {
  VARIABLES,
  TYPOGRAPHYS,
  ICONS,
} from './../../themes'
// import {
//   ROUTE_PATH,
//   redirect,
// } from './../../helpers'
import config from './../../config'
import { getLanguage } from '../../helpers/functions/language';
import { getAdminRoleByCountryType, getCountryType } from '../../helpers/functions/data';
import { getUserAuth } from '../../helpers/functions/auth';

export class TermPolicyPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isLoading: false,
      data: {
        subject: '',
        content: '',
        app: '',
        status: '',
      }
    }
  }

  componentDidMount() {
    this.setState({ isLoading: true }, async () => {
      try {
        // const url = `${config.api.cms}/terms/get/`
        const url = `${config.api.cms}/terms/search/`
        const userAuth = getUserAuth();
        const addressType = getCountryType(userAuth.countries)
        const options = {
          app: 'mobileapp',
          sort: 'id desc',
          status: 'active',
          available_country: addressType
          // created_by_admin_role: getAdminRoleByCountryType(),
        }
        const response = await axios.post(url, options)
        const data = _.get(response, 'data.data.0')
        if (response.status === 200 && data) {
          this.setState({ data })
        }
      } catch (_) {
      } finally {
        this.setState({ isLoading: false })
      }
    })
  }

  // handleBack() {
  //   redirect(ROUTE_PATH.HOME.LINK)
  // }

  render() {
    const { data } = this.state
    return (
      <TransitionContainer>
        <Container
          width={VARIABLES.WEBSITE_WIDTH}
          calcMinHeight='100vh'
          bgColor={VARIABLES.COLORS.WHITE}
        >
          <NavigationBar
            shadow
            iconWidth='19'
            iconHeight='19'
            iconSrc={ICONS['ic-info-black.svg']}
            // pageTitleIcon={getLanguage('termPolicy.title')}
            pageTitleIcon={getLanguage('termsAndConditions.title')}
            actionLeft={
              <Image
                widthRatio='11'
                widthRatioUnit='px'
                heightRatio='21'
                heightRatioUnit='px'
                src={ICONS['ic-left-black.svg']}
                onClick={this.props.history.goBack}
              />
            }
          />
          <Section
            scroll
            calcHeight='100vh - 70px'
            paddingTop='45'
            paddingBottom='145'
            paddingHorizontal='30'
          >
            <Section
              className='text-editor-content'
              spacingBottom='25'
            >
              {ReactHtmlParser(data.content)}
            </Section>
          </Section>
          <Section className='is-fade-gradient' />
        </Container>
      </TransitionContainer>
    )
  }
}
