import axios from 'axios'
import {
  isValidResponse,
} from './../helpers'
import apiConfig from '../config'
import { onOpenAlert } from '../helpers/functions/main'
import { getLanguage } from '../helpers/functions/language'
import { DATA } from '../helpers/constants/data'
import _ from 'lodash';
import { handleValidateAuth } from '../helpers/functions/auth'

const getConfig = (configService) => {
  const BASE_API = apiConfig.api.base
  const config = {
    baseURL: configService.baseApiPath ? configService.baseApiPath : BASE_API,
    headers: {},
    params: {}
  }
  if (configService.params) {
    config.params = configService.params
  }
  return config
}

const axiosSuccess = (res) => {
  return res.data
}

const axiosError = (error, configService) => {
  const message = _.get(error, 'response.data.message', error.message);
  console.log('axiosError', message)
  const _isShowError = configService.isShowError !== undefined ? configService.isShowError : true;
  if (_isShowError) {
    onOpenAlert({
      type: DATA.ALERT_TYPE.ERROR,
      title: getLanguage('message.fail'),
      message,
    });
  }
  return false
}

const axiosService = (type, url, params, configService) => {
  let config = getConfig(configService);
  config = getOptions(config.baseURL, config);
  if (!handleValidateAuth()) {
    return false;
  }
  switch (type) {
    case 'get': return axios.get(url, config).then(axiosSuccess).catch(err => axiosError(err, configService))
    case 'post': return axios.post(url, params, config).then(axiosSuccess).catch(err => axiosError(err, configService))
    case 'put': return axios.put(url, params, config).then(axiosSuccess).catch(err => axiosError(err, configService))
    case 'delete': return axios.delete(url, config).then(axiosSuccess).catch(err => axiosError(err, configService))
    default: return false
  }
}

export default (configService = {}) => {
  return {
    get: (url, params) => axiosService('get', url, params, configService),
    post: (url, params) => axiosService('post', url, params, configService),
    put: (url, params) => axiosService('put', url, params, configService),
    delete: (url, params) => axiosService('delete', url, params, configService),
  }
}


// for test lineoa ngrok
const getOptions = (url, options = {}) => {
  if (url.indexOf('ngrok') >= 0) {
    return {
      ...options,
      headers: {
        "ngrok-skip-browser-warning": true,
        ...options.headers
      }
    }
  }
  return options;
}

export const fetchApi = {
  get: (url, options) => axios.get(url, getOptions(url, options)),
  post: (url, body, options) => axios.post(url, body, getOptions(url, options)),
  put: (url, body, options) => axios.put(url, body, getOptions(url, options)),
  delete: (url, body, options) => axios.delete(url, body, getOptions(url, options)),
}