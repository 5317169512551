import { newsPromotionController } from "../../apiService/apiController/newsPromotionService";
import { ModelNewsPromotion } from "../../models/ModelNewsPromotion";
import _ from "lodash";
import { isValidResponse } from "../functions";

export const getCountCommentByNewsPromotionId = async (params) => {
  const newsPromotionService = newsPromotionController();
  let newParam = {
    id: params
  }
  const res = await newsPromotionService.getCountCommentByNewsPromotionId(newParam);
  if (isValidResponse(res)) {
    let data = new ModelNewsPromotion(res.data)
    console.log('getCountCommentByNewsPromotionId', res.data, data);
    return data;
  }
}