import {
  createReducer,
} from 'reduxsauce';
import _ from 'lodash';
import { MAIN_REDUX_TYPES, MAIN_REDUX_INITIAL_STATE } from '../actions/mainAction';

/* ------------- Reducers ------------- */

export const setMainRedux = (state, { key, data }) => {
  state[key] = _.clone(data)
  return _.clone(state)
}

/* ------------- Hookup Reducers To Types ------------- */

export const mainReducer = createReducer(MAIN_REDUX_INITIAL_STATE, {
  [MAIN_REDUX_TYPES.SET_MAIN_REDUX]: setMainRedux,
})