import React from 'react'
import _ from 'lodash'
import qs from 'qs'
import {
  TransitionContainer,
  Container,
  Image,
  Section,
  Text,
  Grid,
  NavigationBar,
} from '../../components'
import {
  VARIABLES,
  TYPOGRAPHYS,
  ICONS,
} from '../../themes'
import {
  redirect,
  ROUTE_PATH,
  formatDatetime,
} from '../../helpers'
import { ENUM } from '../../helpers/constants/enum'

export class OrderInquiryDetailPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      id: (this.props.match.params && this.props.match.params.id) ? +this.props.match.params.id : '',
      data: {
        id: '',
        subject: '',
        description: '',
        submit_at: '',
      },
    }
  }

  componentDidMount() {
    const data = JSON.parse(localStorage.getItem('orderInquiryList'))
    if (_.isEmpty(data)) return
    const result = data.find(item => item.id === this.state.id)
    if (result) {
      this.setState({ data: result })
    }
  }

  handleBack() {
    const query = qs.parse(_.get(this.props, 'location.search', '').replace('?', ''))
    redirect(`${ROUTE_PATH.ORDER_INQUIRY.LINK}?dealerType=${query.dealerType}&dealerId=${query.dealerId}`)
  }

  render() {
    const { data } = this.state;
    return (
      <TransitionContainer>
        <Container
          width={VARIABLES.WEBSITE_WIDTH}
          calcMinHeight='100vh'
          bgColor={VARIABLES.COLORS.WHITE}
        >
          <NavigationBar
            pageTitle={`Order ID: ${data.id}`}
            actionLeft={
              <Image
                widthRatio='11'
                widthRatioUnit='px'
                heightRatio='21'
                heightRatioUnit='px'
                src={ICONS['ic-left-black.svg']}
                onClick={this.handleBack.bind(this)}
              />
            }
          />
          <Section
            paddingTop='35'
            paddingBottom='25'
            paddingHorizontal='20'
          >
            <Section spacingBottom='5'>
              <Text
                fontStyle={TYPOGRAPHYS.FIRST.BOLD.FS_22}
                color={VARIABLES.COLORS.BLACK}
              >
                {data.subject}
              </Text>
            </Section>
            <Section spacingBottom='31'>
              <Grid gutter='6'>
                <Grid.Column>
                  <Image
                    widthRatio='12'
                    widthRatioUnit='px'
                    heightRatio='13'
                    heightRatioUnit='px'
                    top='-2'
                    src={ICONS['ic-date-gray.svg']}
                  />
                </Grid.Column>
                <Grid.Column>
                  <Text
                    fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_12}
                    color={VARIABLES.COLORS.GRAY_3}
                  >
                    {formatDatetime(data.submit_at, 'DD MMM, YYYY - H:mm')}
                  </Text>
                </Grid.Column>
              </Grid>
            </Section>
            <Section
              paddingBottom='5'
            >
              <Text
                fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_14}
                color={VARIABLES.COLORS.GRAY_2}
              >
                Subject
              </Text>
            </Section>
            <Section
              spacingBottom='10'
              paddingBottom='15'
              borderBottomColor={VARIABLES.COLORS.GRAY_1}
              borderBottomWidth='1'
              borderBottomStyle='solid'
            >
              <Text
                fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_16}
                color={VARIABLES.COLORS.BLACK}
              >
                {data.subject}
              </Text>
            </Section>
            <Section
              paddingBottom='5'
            >
              <Text
                fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_14}
                color={VARIABLES.COLORS.GRAY_2}
              >
                Description
              </Text>
            </Section>
            <Section
              spacingBottom='10'
              paddingBottom='15'
              borderBottomColor={VARIABLES.COLORS.GRAY_1}
              borderBottomWidth='1'
              borderBottomStyle='solid'
            >
              <Text
                fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_16}
                color={VARIABLES.COLORS.BLACK}
              >
                {data.description}
              </Text>
            </Section>
            {data.status === ENUM.ORDER_INQUIRY_STATUS.ACKNOWLEDGED &&
              <React.Fragment>
                <Section
                  paddingBottom='5'
                >
                  <Text
                    fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_14}
                    color={VARIABLES.COLORS.GRAY_2}
                  >
                    Comments/Remarks
              </Text>
                </Section>
                <Section
                  spacingBottom='10'
                  paddingBottom='15'
                  borderBottomColor={VARIABLES.COLORS.GRAY_1}
                  borderBottomWidth='1'
                  borderBottomStyle='solid'
                >
                  <Text
                    fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_16}
                    color={VARIABLES.COLORS.BLACK}
                  >
                    {data.remark}
                  </Text>
                </Section>
              </React.Fragment>
            }
          </Section>
        </Container>
      </TransitionContainer>
    )
  }
}
