import axios from 'axios';
import _ from 'lodash';
import React from 'react';
import config from '../../config';
import { BC_LINE_CREATE_CONNECTOR_AND_SALESFORCE_PATH, CONNECTX_TOKEN } from '../../config/line';
import { DATA } from '../../helpers/constants/data';
import { setUerAuthLocalStorage } from '../../helpers/functions/data';
import { getLanguage } from '../../helpers/functions/language';
import { getLineData, isShowLoading, onOpenAlert } from '../../helpers/functions/main';
import { getUserBy } from '../../helpers/services/users';
import {
  AlertModal
} from '../AlertModal';
import {
  Loader
} from '../Loader';
import {
  Alert,
  Button,
  Container,
  Field,
  Image,
  NavigationBar,
  Section,
  Text,
  TransitionContainer
} from './../../components';
import {
  ROUTE_PATH,
  getRouteData,
  isValidResponse,
  redirect,
  redirectData
} from './../../helpers';
import {
  ICONS,
  TYPOGRAPHYS,
  VARIABLES,
} from './../../themes';

export class VerifyWithQuestionPage extends React.Component {
  constructor(props) {
    super(props);
    const data = getRouteData(props, 'data');
    console.log('VerifyWithQuestionPage routeData', data);
    if (!data) {
      this.verifyQuestion.goBack();
    }
    const verifyTypeList = ['register', 'forgetpassword'];
    this.state = {
      data: data || {},
      verifyType: data.verifyType,
      verifyTypeList,
      questionInput1: data.questionInput1 || '',
      questionInput2: data.questionInput2 || '',
      questionInput3: data.questionInput3 || '',

      ssoId: _.get(data, 'ssoId'),
      isShowLoading: false,
    };
  }

  componentDidMount() {
    const {
      questionInput1,
      questionInput2,
      questionInput3,
    } = this.state;
    if (questionInput1 && questionInput2 && questionInput3) {
      this.verifyQuestion.onClickSubmit();
    }
  }

  verifyQuestion = {
    handleRegisterSuccess: async (data = {}) => {
      const {
        ssoId,
      } = this.state;
      if (data.timeCampaign) {
        redirectData(ROUTE_PATH.REGISTER_SUCCESS.LINK, {
          data: {
            ssoId,
          },
          isGetVoucher: data.receiveVoucher
        });
      }
      else {
        // is register after campaign
        redirectData(ROUTE_PATH.TERMS_CONDITIONS.LINK, {
          data: {
            ssoId,
          }
        });
      }
    },
    goBack: () => {
      const {
        ssoId,
      } = this.state;
      if (ssoId) {
        redirect(`${ROUTE_PATH.LOGIN_BY_LINE_PHONE.LINK}/${ssoId}`);
      }
      else {
        redirect(ROUTE_PATH.LOGIN_BY_PHONE.LINK)
      }
    },
    isValidatePass: () => {
      const {
        questionInput1,
        questionInput2,
        questionInput3,
      } = this.state;
      let isPass = true;

      if (
        questionInput1.length === 0 ||
        questionInput2.length === 0 ||
        questionInput3.length === 0
      ) {
        onOpenAlert({
          type: DATA.ALERT_TYPE.ERROR,
          title: getLanguage('message.fail'),
          message: getLanguage('message.pleaseInputAllRequiredField')
        })
        isPass = false;
      }

      return isPass;
    },
    getInfoUser: (phone, data = {}) => {
      const {
        ssoId,
      } = this.state;
      // axios.post(`${config.api.base}/api/users`, {
      //   phone: phone
      // }).then(async (response) => {
      getUserBy({ phone: phone })
        .then((res) => {
          const userData = res;
          if (userData) {
            setUerAuthLocalStorage(userData);
            this.setState({
              isShowLoading: false,
            });
            isShowLoading(false);
            if (ssoId) {
              this.verifyQuestion.handleRegisterSuccess(data);
            }
            else {
              redirect(ROUTE_PATH.WELCOME.LINK);
            }
          } else {
            console.log('error save to storage');
            this.setState({
              isShowLoading: false,
            });
            isShowLoading(false);
            onOpenAlert({
              type: DATA.ALERT_TYPE.ERROR,
              title: getLanguage('message.fail'),
              message: getLanguage('message.cantGetUserInfo'),
            });
          }
        }, (error) => {
          console.log(error);
          this.setState({
            isShowLoading: false,
          });
          isShowLoading(false);
          onOpenAlert({
            type: DATA.ALERT_TYPE.ERROR,
            title: getLanguage('message.fail'),
            message: error.message,
          });
        });
    },
    handleLineConnector: async (params, callback = () => { }) => {
      // const paramsLine = {
      //   "lineId": "U87fb7120d6bdc74dc8bd8ab85748315c",
      //   "name": "Man",
      //   // "lineId": "U1ff247d8c7e813a31bb9f8c2c6f0ca3c",
      //   // "name": "Ploy",
      //   "customers": {
      //     "cx_mobilePhone": `0${params.phone}`
      //   },
      //   "orgId": "rBPhxRSzfxnpGZOTU4ya",
      //   "channelId": "1656663982",
      //   "statusMessage": "loading...",
      //   "language": "en",
      //   "profileImageUrl": "https://profile.line-scdn.net/0h20XNkdVObR9lN3vaJQESSFlyY3ISGWtXHVR2KUVnYXtBBCkeWQN3LEgwMCtAVCtLDQMhekg2MSoY",
      // };
      const paramsLine = {
        ...getLineData(),
        "customers": {
          "cx_mobilePhone": `0${params.phone}`//  mobilePhone is sync customer 
        }
      };
      axios.post(BC_LINE_CREATE_CONNECTOR_AND_SALESFORCE_PATH, paramsLine, {
        headers: {
          'Authorization': CONNECTX_TOKEN,
        }
      }).then(res => {
        const data = _.get(res, 'data');
        console.log('BC_LINE_CREATE_CONNECTOR_AND_SALESFORCE_PATH', res, data)
        if (res.status === 201 && data.customerId) {
          callback();
        }
        else {
          this.setState({
            isShowLoading: false,
          });
          isShowLoading(false);
          onOpenAlert({
            type: DATA.ALERT_TYPE.ERROR,
            title: getLanguage('message.fail'),
            message: JSON.stringify(_.get(res, 'data', 'lineconnector failed')),
          });
        }
      }, (error) => {
        console.log('errr===>', error);
        this.setState({
          isShowLoading: false,
        });
        isShowLoading(false);
        onOpenAlert({
          type: DATA.ALERT_TYPE.ERROR,
          title: getLanguage('message.fail'),
          message: _.get(error, 'response.data.message', 'lineconnector failed'),
        });
      });
    },
    getUserParams: () => {
      const {
        data,
        questionInput1,
        questionInput2,
        questionInput3,
        ssoId,
      } = this.state;
      return {
        type: data.type,
        email: data.email || '',
        first_name: data.firstName,
        last_name: data.lastName,
        nick_name: data.nickName,
        phone: data.phone,
        birth_date: data.birthDate,
        countries: data.countries,
        profileImage: data.profileImage,
        loginType: 'default',
        // uid: generateUUID(),
        // address: data.address,
        address_json: data.address,
        address_text: data.address_text,
        sub_district: data.subDistrict,
        district: data.district,
        province: data.province,
        city: data.city,
        alley: data.alley,
        group: data.group,
        street: data.street,
        ward: data.ward,
        address_type: data.addressType,
        post_code: data.postCode,
        check_duplicate_email: true,
        security_answer_1: questionInput1,
        security_answer_2: questionInput2,
        security_answer_3: questionInput3,

        id: data.userId,
        line_id: ssoId,
      };
    },
    handleUpdateUserData: async () => {
      this.setState({
        isShowLoading: true,
      }, () => {
        const params = this.verifyQuestion.getUserParams();
        params.check_duplicate_email = undefined;

        axios.post(`${config.api.base}/api/users/confirmProfile`, params).then(async (response) => {
          const res = response.data;
          if (isValidResponse(res)) {
            this.verifyQuestion.handleLineConnector(params, () => {
              this.verifyQuestion.getInfoUser(params.phone, res);
            });
          } else {
            onOpenAlert({
              type: DATA.ALERT_TYPE.ERROR,
              title: getLanguage('message.fail'),
              message: getLanguage('message.phoneEmailAlreadyExists'),
            });
            this.setState({
              isShowLoading: false,
            });
          }
        }, (error) => {
          console.log('errr===>', error);
          this.setState({
            isShowLoading: false,
          });
          onOpenAlert({
            type: DATA.ALERT_TYPE.ERROR,
            title: getLanguage('message.fail'),
            message: (error || {}).message,
          });
        });
      });
    },
    handleRegister: async () => {
      isShowLoading(true);
      const params = this.verifyQuestion.getUserParams();
      axios.post(`${config.api.base}/api/users/register`, params).then(async (response) => {
        const res = response.data;
        if (isValidResponse(res)) {
          this.verifyQuestion.handleLineConnector(params, () => {
            this.verifyQuestion.getInfoUser(params.phone, res);
          });
        } else {
          onOpenAlert({
            type: DATA.ALERT_TYPE.ERROR,
            title: getLanguage('message.fail'),
            message: getLanguage('message.phoneEmailAlreadyExists'),
          });
          isShowLoading(false);
        }
      }, (error) => {
        console.log('errr===>', error);
        isShowLoading(false);
        onOpenAlert({
          type: DATA.ALERT_TYPE.ERROR,
          title: getLanguage('message.fail'),
          message: (error || {}).message,
        });
      });
    },
    onClickSubmit: () => {
      const {
        verifyType,
        verifyTypeList,
        questionInput1,
        questionInput2,
        questionInput3,
        data,
      } = this.state
      if (this.verifyQuestion.isValidatePass()) {
        switch (verifyType) {
          case verifyTypeList[0]:
            if (data.isUpdateUserData) {
              this.verifyQuestion.handleUpdateUserData();
            }
            else {
              this.verifyQuestion.handleRegister();
            }
            break;
          case verifyTypeList[1]:
            isShowLoading(true);
            const params = {
              security_answer_1: questionInput1,
              security_answer_2: questionInput2,
              security_answer_3: questionInput3
            }
            axios.post(`${config.api.base}/api/users/verify`, params).then((response) => {
              const res = response.data;
              if (isValidResponse(res)) {
                isShowLoading(false);
                redirectData(ROUTE_PATH.RESET_PASSWORD.LINK, {
                  data: {
                    id: res.user_id,
                  }
                });
              }
              else {
                isShowLoading(false);
                onOpenAlert({
                  type: DATA.ALERT_TYPE.ERROR,
                  title: getLanguage('message.fail'),
                  // message: res.message,
                  message: getLanguage('message.wrongAnswer')
                })
              }
            }, (error) => {
              console.log({ error });
              isShowLoading(false);
              onOpenAlert({
                type: DATA.ALERT_TYPE.ERROR,
                title: getLanguage('message.fail'),
                // message: _.get(error, 'response.data.message', ''),
                message: getLanguage('message.wrongAnswer')
              });
            });
            break;
          default:
            break;
        }
      }
    }
  }

  input = {
    onChangeInput: (ev) => {
      const target = ev.target.name;
      const value = ev.target.value;
      this.setState({
        [target]: value
      });
    },
  }

  render() {
    const {
      verifyType,
      verifyTypeList,
      questionInput1,
      questionInput2,
      questionInput3,
      isShowLoading,
    } = this.state
    return (
      <TransitionContainer>
        <Container
          width={VARIABLES.WEBSITE_WIDTH}
          height='100'
          heightUnit='vh'
          bgColor={VARIABLES.COLORS.WHITE}
        >
          <NavigationBar
            shadow
            // RL-707 : Setup Verify Questions >>> getLanguage('verify.title1')
            // RL-707 : Verify with Questions >>> getLanguage('verify.title2')
            pageTitle={verifyType === verifyTypeList[0] ? getLanguage('verify.title1') : getLanguage('verify.title2')}
            actionLeft={
              <Image
                widthRatio='11'
                widthRatioUnit='px'
                heightRatio='21'
                heightRatioUnit='px'
                src={ICONS['ic-left-black.svg']}
                onClick={this.verifyQuestion.goBack}
              />
            }
          />
          <Section
            scroll
            justify='center'
            calcHeight='100vh - 70px'
            paddingTop='45'
            paddingBottom='55'
          >
            <Section width='275'>
              <React.Fragment>
                <Section justify='center' spacingBottom='25'>
                  <img src={ICONS['ic-verify-question-blue.svg']} alt='Icon' />
                </Section>
                <Section spacingBottom='25'>
                  <Section spacingBottom='5'>
                    <Text
                      className='is-floating-label'
                      fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_12}
                      color={VARIABLES.COLORS.GRAY_2}
                    >
                      {getLanguage('verify.question1')}&nbsp;
                      <Text
                        fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_12}
                        color={VARIABLES.COLORS.RED_2}
                      >
                        *
                      </Text>
                    </Text>
                  </Section>
                  <Field
                    fluid
                    name='questionInput1'
                    error={false}
                    height='28'
                    placeholder={getLanguage('field.questionPlaceholder')}
                    placeholderColor={VARIABLES.COLORS.GRAY_5}
                    paddingHorizontal='10'
                    paddingBottom='10'
                    bgColor={VARIABLES.COLORS.TRANSPARENT}
                    borderBottomWidth='1'
                    borderBottomStyle='solid'
                    value={questionInput1}
                    onChange={this.input.onChangeInput}
                    borderBottomColor={VARIABLES.COLORS.GRAY_3}
                    fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_15}
                    fontColor={VARIABLES.COLORS.BLACK}
                  />
                </Section>
                <Section spacingBottom='25'>
                  <Section spacingBottom='5'>
                    <Text
                      className='is-floating-label'
                      fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_12}
                      color={VARIABLES.COLORS.GRAY_2}
                    >
                      {getLanguage('verify.question2')}&nbsp;
                      <Text
                        fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_12}
                        color={VARIABLES.COLORS.RED_2}
                      >
                        *
                      </Text>
                    </Text>
                  </Section>
                  <Field
                    fluid
                    name='questionInput2'
                    error={false}
                    height='28'
                    placeholder={getLanguage('field.questionPlaceholder')}
                    placeholderColor={VARIABLES.COLORS.GRAY_5}
                    paddingHorizontal='10'
                    paddingBottom='10'
                    bgColor={VARIABLES.COLORS.TRANSPARENT}
                    borderBottomWidth='1'
                    borderBottomStyle='solid'
                    value={questionInput2}
                    onChange={this.input.onChangeInput}
                    borderBottomColor={VARIABLES.COLORS.GRAY_3}
                    fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_15}
                    fontColor={VARIABLES.COLORS.BLACK}
                  />
                </Section>
                <Section spacingBottom='60'>
                  <Section spacingBottom='5'>
                    <Text
                      className='is-floating-label'
                      fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_12}
                      color={VARIABLES.COLORS.GRAY_2}
                    >
                      {getLanguage('verify.question3')}&nbsp;
                      <Text
                        fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_12}
                        color={VARIABLES.COLORS.RED_2}
                      >
                        *
                      </Text>
                    </Text>
                  </Section>
                  <Section position='relative'>
                    <Field
                      fluid
                      name='questionInput3'
                      error={false}
                      height='28'
                      placeholder={getLanguage('field.questionPlaceholder')}
                      placeholderColor={VARIABLES.COLORS.GRAY_5}
                      paddingHorizontal='10'
                      paddingBottom='10'
                      bgColor={VARIABLES.COLORS.TRANSPARENT}
                      borderBottomWidth='1'
                      borderBottomStyle='solid'
                      value={questionInput3}
                      onChange={this.input.onChangeInput}
                      borderBottomColor={VARIABLES.COLORS.GRAY_3}
                      fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_15}
                      fontColor={VARIABLES.COLORS.BLACK}
                    />
                  </Section>
                </Section>
                <Section justify='center'>
                  <Button
                    shadow
                    className='is-gradient-blue'
                    width='275'
                    height={VARIABLES.BUTTON.HEIGHTS.H_40}
                    borderRadius={VARIABLES.BUTTON.BORDER_RADIUSES.BR_10}
                    onClick={this.verifyQuestion.onClickSubmit}
                  >
                    <Text
                      fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_16}
                      color={VARIABLES.COLORS.WHITE}
                    >
                      {/* Setup Verify Questions >>> field.buttonConfirm */}
                      {/* Verify with Questions >>> field.buttonSubmit */}
                      {verifyType === verifyTypeList[0] ? getLanguage('field.buttonConfirm') : getLanguage('field.buttonSubmit')}
                    </Text>
                  </Button>
                </Section>
              </React.Fragment>
            </Section>
          </Section>
        </Container>

        {/* Modal Fail */}
        {false ?
          <AlertModal
            topic={getLanguage('message.fail')}
            msg={true ? getLanguage('message.phoneNotMatch') : getLanguage('message.wrongAnswer')}
            closeModal={() => { }}
          />
          :
          ''
        }

        {/* Modal Message */}
        {false &&
          <Alert open type='send'
            title={getLanguage('modal.otpSend.title')}
            description={getLanguage('modal.otpSend.description')}
            onClose={() => { }}
          />
        }

        {<Loader loading={isShowLoading} isOverlay={true} />}
      </TransitionContainer>
    )
  }
}
