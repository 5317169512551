import { handleQueryDocFirebase } from './firebaseManageData';
import { handleAppVersion } from '../functions/version';

let qSnapVersionUnsub = () => { };

export const firebaseUnsubscribeVersion = () => {
  qSnapVersionUnsub();
}

export const handleWatchVersion = () => {
  return new Promise(async (resolve, reject) => {
    try {
      firebaseUnsubscribeVersion();
      qSnapVersionUnsub = await handleQueryDocFirebase('misc', 'version', {
        callType: 'onSnapshot',
        callbackSuccess: async (qSnapVersion) => {
          let dataVersion = qSnapVersion.data();
          if (dataVersion && dataVersion.mobile) {
            handleAppVersion(dataVersion.mobile);
          }
          resolve();
        }
      });
    }
    catch (err) {
      reject(err);
      console.log('err', { err });
    }
  });
}