import firebaseCore from 'firebase';

//staging, dev
// const firebaseConfig = {
//   apiKey: "AIzaSyCgG_mR6zEmFmEPMhmXtdDIB5Qr9AnrFv4",
//   authDomain: "bluescope-stg.firebaseapp.com",
//   databaseURL: "https://bluescope-stg.firebaseio.com",
//   projectId: "bluescope-stg",
//   storageBucket: "bluescope-stg.appspot.com",
//   messagingSenderId: "627588703215",
//   appId: "1:627588703215:web:1beeca8b70ceba17ea8c1a"
// };

//prod
const firebaseConfig = {
  apiKey: "AIzaSyDFlJ9XDLGIENX4tK8y3vKWhgX2b0VbYwE",
  authDomain: "bluescope-955e7.firebaseapp.com",
  databaseURL: "https://bluescope-955e7.firebaseio.com",
  projectId: "bluescope-955e7",
  storageBucket: "bluescope-955e7.appspot.com",
  messagingSenderId: "599788077551",
  appId: "1:599788077551:web:e81a89f4d6b080e43bb61a",
  measurementId: "G-R7CCMQ9Z36"
};

firebaseCore.initializeApp(firebaseConfig);

export const firebase = firebaseCore;
