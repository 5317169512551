import React from 'react'
import _ from 'lodash'
import axios from 'axios'
import ReactHtmlParser from 'react-html-parser';
import {
  TransitionContainer,
  Container,
  Image,
  Section,
  Text,
  Button,
  Field,
  NavigationBar,
  Modal
} from './../../components'
import {
  VARIABLES,
  TYPOGRAPHYS,
  ICONS,
} from './../../themes'
import {
  ROUTE_PATH,
  redirect,
  getRouteData,
} from './../../helpers'
import config from './../../config'
import { getLanguage, getLanguageLocalStorage } from '../../helpers/functions/language';
import { getAdminRoleByCountryType } from '../../helpers/functions/data';

export class TermsConditionsPage extends React.Component {
  constructor(props) {
    super(props);
    const data = getRouteData(props, 'data');
    console.log('data', data);
    this.state = {
      termAndConditionChecked: false,
      isOpenNotificationTermsAndConditionsModal: false,
      isLoading: false,
      data: {
        subject: '',
        content: '',
        app: '',
        status: '',
      },

      ssoId: _.get(data, 'ssoId')
    };
  }

  componentDidMount() {
    // localStorage.removeItem('termsAndConditions')
    this.setState({ isLoading: true }, async () => {
      try {
        const language = getLanguageLocalStorage();
        // const url = `${config.api.cms}/terms/get/`;
        const url = `${config.api.cms}/terms/search/`;
        const options = {
          app: 'mobileapp',
          sort: 'id desc',
          status: 'active',
          available_country: language
          // created_by_admin_role: getAdminRoleByCountryType(),
        }
        const response = await axios.post(url, options)
        const data = _.get(response, 'data.data.0')
        if (response.status === 200 && data) {
          this.setState({ data })
        }
      } catch (_) {
      } finally {
        this.setState({ isLoading: false })
      }
    })
  }

  onClickContinue() {
    // check agree
    if (this.state.termAndConditionChecked) {
      this.onClickAgree();
    } else {
      this.setState({
        isOpenNotificationTermsAndConditionsModal: true
      })
    }
  }

  onClickAgree() {
    const {
      ssoId,
    } = this.state;
    if (ssoId) {
      redirect(ROUTE_PATH.WELCOME.LINK);
    }
    else {
      redirect(ROUTE_PATH.REGISTER.LINK);
    }
  }

  handleCheckbox() {
    this.setState({ termAndConditionChecked: !this.state.termAndConditionChecked });
  }

  handleBack() {
    const {
      ssoId,
    } = this.state;
    if (ssoId) {
      redirect(`${ROUTE_PATH.LOGIN_BY_LINE_PHONE.LINK}/${ssoId}`);
    }
    else {
      redirect(ROUTE_PATH.LOGIN_BY_PHONE.LINK);
    }
  }

  modal = {
    onClickOpenNotificationTermsAndConditionsModal: () => {
      this.setState({
        isOpenNotificationTermsAndConditionsModal: true
      })
    },

    onClickCloseNotificationTermsAndConditionsModal: () => {
      this.setState({
        isOpenNotificationTermsAndConditionsModal: false
      })
    }
  }

  render() {
    const {
      isOpenNotificationTermsAndConditionsModal,
      data,
    } = this.state
    return (
      <TransitionContainer>
        <Container
          width={VARIABLES.WEBSITE_WIDTH}
          calcMinHeight='100vh'
          bgColor={VARIABLES.COLORS.WHITE}
        >
          <NavigationBar
            shadow
            pageTitle={getLanguage('termsAndConditions.title')}
            actionLeft={
              <Image
                widthRatio='11'
                widthRatioUnit='px'
                heightRatio='21'
                heightRatioUnit='px'
                src={ICONS['ic-left-black.svg']}
                onClick={this.handleBack.bind(this)}
              />
            }
          />
          <Section
            scroll
            calcHeight='100vh - 235px'
            paddingTop='45'
            paddingBottom='45'
            paddingHorizontal='30'
          >
            <Section
              className='text-editor-content'
              spacingBottom='25'
            >
              {ReactHtmlParser(data.content)}
            </Section>
          </Section>
          <Section
            className='is-agree-terms'
            bgColor={VARIABLES.COLORS.WHITE}
            paddingTop='25'
            paddingBottom='55'
            paddingHorizontal='50'
          >
            <Section
              spacingBottom='25'
            >
              <Field
                className='is-checkbox-terms'
                type='checkbox'
                id='checkbox-agree-terms'
                name='checkbox-agree-terms'
                checked={this.state.termAndConditionChecked}
                checkboxLabel={getLanguage('field.agreeTermsAndConditions')}
                fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_14}
                fontColor={VARIABLES.COLORS.BLUE_2}
                checkboxSize='20'
                checkboxLabelSpacingLeft='15'
                checkboxBorderWidth='1'
                checkboxBorderStyle='solid'
                checkboxBorderColor={VARIABLES.COLORS.GRAY_2}
                checkboxCheckedBgColor={VARIABLES.COLORS.BLUE_2}
                checkboxBorderCheckedColor={VARIABLES.COLORS.BLUE_2}
                checkboxBorderRadius='4'
                checkboxCheckedIcon={
                  <Image
                    widthRatio='18'
                    widthRatioUnit='px'
                    heightRatio='18'
                    heightRatioUnit='px'
                    src={ICONS['ic-check.svg']}
                  />
                }
                onChange={this.handleCheckbox.bind(this)}
              />
            </Section>
            <Section
              justify='center'
            >
              <Button
                fluid
                shadow
                className='is-gradient-blue'
                height={VARIABLES.BUTTON.HEIGHTS.H_40}
                borderRadius={VARIABLES.BUTTON.BORDER_RADIUSES.BR_10}
                onClick={this.onClickContinue.bind(this)}
              >
                <Text
                  fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_16}
                  color={VARIABLES.COLORS.WHITE}
                >
                  {getLanguage('field.buttonContinue')}
                </Text>
              </Button>
            </Section>
          </Section>
        </Container>

        <Modal
          open={isOpenNotificationTermsAndConditionsModal}
          width={VARIABLES.MODAL.WIDTHS.W_300}
          overlayColor={VARIABLES.COLORS.OVERLAY_3}
          spacing={VARIABLES.MODAL.CONTAINER.C_1.SPACING}
          transition={VARIABLES.TRANSITIONS.DEFAULT}
          transformStart={VARIABLES.MODAL.CONTAINER.C_1.TRANSFORM_START}
          transformEnd={VARIABLES.MODAL.CONTAINER.C_1.TRANSFORM_END}
          onClickClose={this.modal.onClickCloseNotificationTermsAndConditionsModal}
        >
          <Modal.Section
            paddingTop={VARIABLES.MODAL.BODY.B_2.PADDING_TOP}
            paddingBottom={VARIABLES.MODAL.BODY.B_2.PADDING_BOTTOM}
            paddingHorizontal={VARIABLES.MODAL.BODY.B_2.PADDING_HORIZONTAL}
            borderTopRightRadius={VARIABLES.MODAL.BODY.B_2.BORDER_TOP_RIGHT_RADIUS}
            borderTopLeftRadius={VARIABLES.MODAL.BODY.B_2.BORDER_TOP_LEFT_RADIUS}
          >
            <Section
              spacingBottom='20'
              justify='center'
            >
              <Text
                fontStyle={TYPOGRAPHYS.FIRST.BOLD.FS_18}
                color={VARIABLES.COLORS.BLACK}
              >
                {getLanguage('modal.notiTnc.title_1')}
                <br />
                {getLanguage('modal.notiTnc.title_2')}
              </Text>
            </Section>
            <Section
              justify='center'
            >
              <Text
                fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_15}
                color={VARIABLES.COLORS.GRAY_6}
                align='center'
              >
                {getLanguage('modal.notiTnc.message_1')}
                <br />
                {getLanguage('modal.notiTnc.message_2')}
                <br />
                {getLanguage('modal.notiTnc.message_3')}
              </Text>
            </Section>
          </Modal.Section>
          <Modal.Section
            paddingBottom={VARIABLES.MODAL.FOOTER.F_2.PADDING_BOTTOM}
            paddingHorizontal={VARIABLES.MODAL.FOOTER.F_2.PADDING_HORIZONTAL}
            borderBottomRightRadius={VARIABLES.MODAL.FOOTER.F_2.BORDER_BOTTOM_RIGHT_RADIUS}
            borderBottomLeftRadius={VARIABLES.MODAL.FOOTER.F_2.BORDER_BOTTOM_LEFT_RADIUS}
          >
            <Section
              justify='center'
              spacingBottom='10'
            >
              <Button
                shadow
                className='is-gradient-blue'
                width={VARIABLES.BUTTON.WIDTHS.W_200}
                height={VARIABLES.BUTTON.HEIGHTS.H_35}
                borderRadius={VARIABLES.BUTTON.BORDER_RADIUSES.BR_10}
                onClick={this.onClickAgree.bind(this)}
              >
                <Text
                  fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_16}
                  color={VARIABLES.COLORS.WHITE}
                >
                  {getLanguage('field.buttonAgree')}
                </Text>
              </Button>
            </Section>
            <Section justify='center'>
              <Button
                width={VARIABLES.BUTTON.WIDTHS.W_200}
                height={VARIABLES.BUTTON.HEIGHTS.H_35}
                bgColor={VARIABLES.COLORS.TRANSPARENT}
                borderRadius={VARIABLES.BUTTON.BORDER_RADIUSES.BR_10}
                borderWidth='1'
                borderStyle='solid'
                borderColor={VARIABLES.COLORS.BLUE_4}
                onClick={this.modal.onClickCloseNotificationTermsAndConditionsModal}
              >
                <Text
                  fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_16}
                  color={VARIABLES.COLORS.BLUE_4}
                >
                  {getLanguage('field.buttonCancel')}
                </Text>
              </Button>
            </Section>
          </Modal.Section>
        </Modal>
      </TransitionContainer>
    )
  }
}
