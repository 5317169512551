import React from 'react';
import {
  connect,
} from 'react-redux';
import { Modal } from '../../../components';
import { ICONS } from '../../../themes';
import { getLanguage } from '../../../helpers/functions/language';
import { DATA } from '../../../helpers/constants/data';

export class ModalNoticeContainer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
    }
  }

  render() {
    const {
      openModalType,
      iconWidth,
      iconHeight,
      iconSrc,
      title,
      description,
      buttonSubmitName,
      onClickSubmit = () => { },
    } = this.props;
    return openModalType === DATA.MODAL_TYPE.NOTICE ?
      <Modal open ui='notice'
        iconWidth={iconWidth}
        iconHeight={iconHeight}
        iconSrc={iconSrc}
        title={title}
        description={description}
        buttonSubmitName={buttonSubmitName}
        onClickSubmit={onClickSubmit}
      />
      :
      null
  }
}


const mapStateToProps = (state) => {
  const mainRedux = state.mainRedux;
  const modalData = mainRedux.modalData;
  return {
    openModalType: modalData.openModalType,
    iconWidth: modalData.iconWidth,
    iconHeight: modalData.iconHeight,
    iconSrc: modalData.iconSrc,
    title: modalData.title,
    description: modalData.description,
    buttonSubmitName: modalData.buttonSubmitName,
    onClickSubmit: modalData.onClickSubmit,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
  }
}

export const ModalNoticeContainerConnected = connect(
  mapStateToProps,
  mapDispatchToProps
)(ModalNoticeContainer)
