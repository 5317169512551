import {
  combineReducers,
} from 'redux'
import { mainReducer } from './mainReducer'
import { dataReducer } from './dataReducer'
import {
  routeHistoryRedux,
} from './routeReducers'

export const rootReducer = () => {
  return combineReducers({
    routeHistoryRedux,
    mainRedux: mainReducer,
    dataRedux: dataReducer,
  })
}