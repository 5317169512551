import styled, {
  css
} from 'styled-components'
import {
  default as VARIABLES
} from './../../../themes/styles/bases/variables'
// import {
//   default as TYPOGRAPHYS
// } from './../../../themes/styles/bases/typographys'
// import {
//   default as MIXINS
// } from './../../../themes/styles/helpers/mixins'

// Sample using image in style
// url(${require('./images/icons/icon-sample.svg')});
// url(${require('./../../../themes/images/icons/icon-sample.svg')});

// Wrapper
// ============================================================
export const ButtonBaseWrapper = styled.div`
  /* Parent
  ------------------------------- */
  cursor: pointer;
  transition: ${VARIABLES.TRANSITIONS.DEFAULT};
  display: inline-block;

  ${props => {
    const theme = props.theme
    return css`
      background-repeat: ${theme.bgRepeat};
      background-size: ${theme.bgSize};
      background-position: ${theme.bgPosition};

      ${theme.width && css`
          min-width: ${theme.width + `px`};
        `
      }

      ${theme.height && css`
          height: ${theme.height + `px`};
        `
      }

      /* All */
      ${theme.padding && css`
          padding: ${theme.padding + `px`};
        `
      }

      /* Horizontal */
      ${theme.paddingHorizontal && css`
          padding-right: ${theme.paddingHorizontal + `px`};
          padding-left: ${theme.paddingHorizontal + `px`};
        `
      }

      /* Vertical */
      ${theme.paddingVertical && css`
          padding-top: ${theme.paddingVertical + `px`};
          padding-bottom: ${theme.paddingVertical + `px`};
        `
      }

      /* Separate */
      ${theme.paddingTop && css`
          padding-top: ${theme.paddingTop + `px`};
        `
      }

      ${theme.paddingRight && css`
          padding-right: ${theme.paddingRight + `px`};
        `
      }

      ${theme.paddingBottom && css`
          padding-bottom: ${theme.paddingBottom + `px`};
        `
      }

      ${theme.paddingLeft && css`
          padding-left: ${theme.paddingLeft + `px`};
        `
      }

      ${theme.bgImage && css`
          background-image: url(${theme.bgImage});
        `
      }

      background-color: ${theme.bgColor};

      ${theme.borderWidth && css`
          border-width: ${theme.borderWidth + `px`};
        `
      }

      border-style: ${theme.borderStyle};
      border-color: ${theme.borderColor};

      ${theme.borderRadius && css`
          border-radius: ${theme.borderRadius + `px`};
        `
      }

      ${theme.paddingBottomPercent && css`
          padding-bottom: ${theme.paddingBottomPercent + `%`};
        `
      }

      &:hover {
        background-color: ${theme.bgHoverColor};
        border-color: ${theme.borderHoverColor};

        .button-body-base {
          > * {
            transition: ${VARIABLES.TRANSITIONS.DEFAULT};
            color: ${theme.textHoverColor};
          }
        }
      }
    `
  }}

  /* Childrens
  ------------------------------- */
  .button-body-base {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }

  /* Modifiers
  ------------------------------- */
  &.is-fluid {
    width: 100%;
  }

  &.is-disabled {
    cursor: default;
    pointer-events: none;

    ${props => {
      const theme = props.theme
      return css`
        background-color: ${theme.bgColorDisabled};
      `
    }}
  }

  &.is-column {
    .button-body-base {
      flex-direction: column;
    }
  }

  /* Media queries
  ------------------------------- */
`

export const ButtonIconBaseWrapper = styled.div`
  /* Parent
  ------------------------------- */
  ${props => {
    const theme = props.theme
    return css`
      ${theme.spacingRight && css`
          margin-right: ${theme.spacingRight + `px`};
        `
      }

      ${theme.spacingLeft && css`
          margin-left: ${theme.spacingLeft + `px`};
        `
      }
    `
  }}

  /* Childrens
  ------------------------------- */
  /* Modifiers
  ------------------------------- */
  /* Media queries
  ------------------------------- */
`
