import React from 'react'
import {
  TransitionContainer,
  Container,
  Image,
  Section,
  Text,
  Grid,
  NavigationBar,
  Button,
  Modal,
} from './../../components'
import {
  VARIABLES,
  TYPOGRAPHYS,
  ICONS,
  FLAGS,
} from './../../themes'
import {
  ROUTE_PATH,
  redirect,
} from './../../helpers'
import { getLanguageLocalStorage, changeLanguage, language, getLanguage } from '../../helpers/functions/language';
import { DATA } from '../../helpers/constants/data';
import _ from 'lodash';
import { getUserAuth } from '../../helpers/functions/auth';
import { setCountryDataList } from '../../helpers/functions/data';

const languages = [{
  title: 'English',
  value: 'english',
  image: FLAGS['flag-united-kingdom.svg'],
  languageType: DATA.LANGUAGE.ENGLISH,
}, {
  title: 'ภาษาไทย',
  value: 'thai',
  image: FLAGS['flag-thailand.svg'],
  languageType: DATA.LANGUAGE.THAI,
}, {
  title: 'Bahasa indonesia',
  value: 'indonesian',
  image: FLAGS['flag-indonesia.svg'],
  languageType: DATA.LANGUAGE.INDONESIA,
}, {
  title: 'Tiếng việt',
  value: 'vietnamese',
  image: FLAGS['flag-vietnam.svg'],
  languageType: DATA.LANGUAGE.VIETNAM,
}, {
  title: 'Bahasa Malaysia',
  value: 'malaysian',
  image: FLAGS['flag-malaysia.svg'],
  languageType: DATA.LANGUAGE.MALAYSIA,
}, {
  title: '中文',
  value: 'chinese',
  image: FLAGS['flag-china.svg'],
  languageType: DATA.LANGUAGE.CHINA,
}, {
  title: 'ລາວ',
  value: 'laos',
  image: FLAGS['flag-lao.svg'],
  languageType: DATA.LANGUAGE.LAOS,
}];

export class SettingLanguagePage extends React.Component {
  constructor(props) {
    super(props)
    const currentLanguage = getLanguageLocalStorage();
    const userAuth = getUserAuth();
    let language = languages[0].value;
    let newLanguage = language[0].languageType;
    languages.forEach(e => {
      if (e.languageType === currentLanguage) {
        language = e.value;
        newLanguage = e.languageType
      }
    })
    this.state = {
      language,
      userAuth,
      showModalSuccess: false,
      newLanguage,
    }
  }

  handleBack() {
    redirect(ROUTE_PATH.SETTING.LINK);
  }

  changeLanguage(language) {
    this.setState({
      language: language.value,
      newLanguage: language.languageType,
    });
  }

  saveData() {
    // to do something..
    this.setState({ showModalSuccess: true });
  }

  closeModal() {
    this.setState({ showModalSuccess: false });
  }

  onClickSubmitChangeLanguage() {
    const {
      newLanguage,
      userAuth
    } = this.state;
    this.closeModal();
    changeLanguage(newLanguage);
    setCountryDataList(userAuth.countries);
    redirect(ROUTE_PATH.HOME.LINK);
  }

  render() {
    return (
      <TransitionContainer
      // motion='overlap-from'
      >
        <Container
          width={VARIABLES.WEBSITE_WIDTH}
          calcMinHeight='100vh'
          bgColor={VARIABLES.COLORS.LIGHT_BLUE_2}
        >
          <NavigationBar
            shadow
            iconWidth='21'
            iconHeight='20'
            iconSrc={ICONS['ic-navigation-language.svg']}
            pageTitleIcon={getLanguage('settingLanguage.title')}
            actionLeft={
              <Image
                widthRatio='11'
                widthRatioUnit='px'
                heightRatio='21'
                heightRatioUnit='px'
                src={ICONS['ic-left-black.svg']}
                onClick={this.handleBack.bind(this)}
              />
            }
          />
          <Section
            scroll
            calcHeight='100vh - 70px'
          >
            <Section
              paddingHorizontal='50'
              paddingTop='50'
            >
              <Image
                widthRatio='56'
                widthRatioUnit='px'
                heightRatio='56'
                heightRatioUnit='px'
                src={ICONS['ic-translation-flat.svg']}
              />
              <Section
                paddingTop='30'
                paddingBottom='5'
              >
                <Text
                  fontStyle={TYPOGRAPHYS.FIRST.BOLD.FS_16}
                  color={VARIABLES.COLORS.BLACK}
                >
                  {getLanguage('settingLanguage.chooseLanguage')}
                </Text>
              </Section>
              <Section
                paddingBottom='20'
              >
                <Text
                  fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_14}
                  color={VARIABLES.COLORS.GRAY_3}
                >
                  {getLanguage('settingLanguage.pleaseSelectLanguage')}
                </Text>
              </Section>
              {languages.map((language, index) => (<Section
                key={index}
                paddingVertical='13'
                borderBottomColor={VARIABLES.COLORS.GRAY_5}
                borderBottomWidth='1'
                borderBottomStyle='solid'
                onClick={this.changeLanguage.bind(this, language)}
              >
                <Grid gutter='12'>
                  <Grid.Column flex='none'>
                    <Image
                      widthRatio='18'
                      widthRatioUnit='px'
                      heightRatio='12'
                      heightRatioUnit='px'
                      src={language.image}
                    />
                  </Grid.Column>
                  <Grid.Column flex='1'>
                    <Text
                      fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_16}
                      color={(this.state.language === language.value) ? VARIABLES.COLORS.BLACK : VARIABLES.COLORS.GRAY_3}
                    >
                      {language.title}
                    </Text>
                  </Grid.Column>
                  {this.state.language === language.value &&
                    <Grid.Column flex='none'>
                      <Image
                        widthRatio='17'
                        widthRatioUnit='px'
                        heightRatio='12'
                        heightRatioUnit='px'
                        src={ICONS['ic-check-blue.svg']}
                      />
                    </Grid.Column>
                  }
                </Grid>
              </Section>
              ))}
              <Section
                justify='center'
                paddingTop='60'
                paddingBottom='30'
              >
                <Button
                  shadow
                  className='is-gradient-blue is-icon-front'
                  width='275'
                  height={VARIABLES.BUTTON.HEIGHTS.H_40}
                  borderRadius={VARIABLES.BUTTON.BORDER_RADIUSES.BR_10}
                  onClick={this.saveData.bind(this)}
                >
                  <Text
                    fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_16}
                    color={VARIABLES.COLORS.WHITE}
                  >
                    {getLanguage('field.buttonSaveAndChange')}
                  </Text>
                </Button>
              </Section>
            </Section>
          </Section>
          <Modal
            open={this.state.showModalSuccess}
            width={VARIABLES.MODAL.WIDTHS.W_300}
            overlayColor={VARIABLES.COLORS.OVERLAY_3}
            spacing={VARIABLES.MODAL.CONTAINER.C_1.SPACING}
            transition={VARIABLES.TRANSITIONS.DEFAULT}
            transformStart={VARIABLES.MODAL.CONTAINER.C_1.TRANSFORM_START}
            transformEnd={VARIABLES.MODAL.CONTAINER.C_1.TRANSFORM_END}
            onClick={this.closeModal.bind(this)}
          >
            <Modal.Section
              paddingTop={VARIABLES.MODAL.HEADER.H_1.PADDING_TOP}
              paddingBottom={VARIABLES.MODAL.HEADER.H_1.PADDING_BOTTOM}
              paddingHorizontal={VARIABLES.MODAL.HEADER.H_1.PADDING_HORIZONTAL}
              borderTopRightRadius={VARIABLES.MODAL.HEADER.H_1.BORDER_TOP_RIGHT_RADIUS}
              borderTopLeftRadius={VARIABLES.MODAL.HEADER.H_1.BORDER_TOP_LEFT_RADIUS}
            >
              <Section justify='center'>
                <Image
                  widthRatio='61'
                  widthRatioUnit='px'
                  heightRatio='61'
                  heightRatioUnit='px'
                  src={ICONS['ic-success.svg']}
                />
              </Section>
            </Modal.Section>
            <Modal.Section
              paddingBottom={VARIABLES.MODAL.BODY.B_1.PADDING_BOTTOM}
              paddingHorizontal={VARIABLES.MODAL.BODY.B_1.PADDING_HORIZONTAL}
            >
              <Section
                spacingBottom='12'
                justify='center'
              >
                <Text
                  fontStyle={TYPOGRAPHYS.FIRST.BOLD.FS_18}
                  color={VARIABLES.COLORS.BLACK}
                >
                  {getLanguage('modal.changeLanguage.title')}
                </Text>
              </Section>
              <Section
                justify='center'
              >
                <Text
                  fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_15}
                  color={VARIABLES.COLORS.GRAY_6}
                  align='center'
                >
                  {getLanguage('modal.changeLanguage.message')}
                </Text>
              </Section>
            </Modal.Section>
            <Modal.Section
              paddingBottom={VARIABLES.MODAL.FOOTER.F_1.PADDING_BOTTOM}
              paddingHorizontal={VARIABLES.MODAL.FOOTER.F_1.PADDING_HORIZONTAL}
              borderBottomRightRadius={VARIABLES.MODAL.FOOTER.F_1.BORDER_BOTTOM_RIGHT_RADIUS}
              borderBottomLeftRadius={VARIABLES.MODAL.FOOTER.F_1.BORDER_BOTTOM_LEFT_RADIUS}
            >
              <Button
                fluid
                shadow
                className='is-gradient-blue'
                height={VARIABLES.BUTTON.HEIGHTS.H_35}
                borderRadius={VARIABLES.BUTTON.BORDER_RADIUSES.BR_10}
                onClick={this.onClickSubmitChangeLanguage.bind(this)}
              >
                <Text
                  fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_16}
                  color={VARIABLES.COLORS.WHITE}
                >
                  {getLanguage('field.buttonOK')}
                </Text>
              </Button>
            </Modal.Section>
          </Modal>
        </Container>
      </TransitionContainer >
    )
  }
}
