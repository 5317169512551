import React from 'react'
import ClassNames from 'classnames'
import PropTypes from 'prop-types'
import {
  TransitionContainerWrapper
} from './styled'

/**
 * TransitionContainer description:
 * - use to wrap content in page conainer for enable page transition
 * - Default: [TransitionContainer] Insert content... [/TransitionContainer]
 * - Motion: [TransitionContainer motion='overlap'] Insert content... [/TransitionContainer]
 */

export class TransitionContainer extends React.PureComponent {
  render () {
    const {
      className,
      children,
      motion,
      top,
      right,
      bottom,
      left
    } = this.props

    return (
      <TransitionContainerWrapper
        className={ClassNames(
          'transition-container',
          {[`is-motion-${motion}`]: motion },
          // Option
          className
        )}
        theme={{
          // Default
          top,
          right,
          bottom,
          left
        }}
      >
        <div className='transition-scroll-container'>
          {children}
        </div>
      </TransitionContainerWrapper>
    )
  }

  static defaultProps = {
    // Option
    // children: undefined,
    // className: undefined,
    // Default
    top: 0,
    right: 0,
    bottom: 0,
    left: 0
  }

  static propTypes = { // TYPE > node, string, number, bool, array, object, symbol, func
    /**
    * Additional classes
    */
    className: PropTypes.string,

    /**
    * Additional Text, HTML, Component
    */
    children: PropTypes.oneOfType([
      PropTypes.node,
      PropTypes.string
    ]),

    /**
    * Motion type of page transition
    */
    motion: PropTypes.oneOf([
      'overlap-from',
      'overlap-to'
    ])
  }
}
