import React from 'react'
import {
  TransitionContainer,
  Container,
  Section,
  Image,
  Button,
  Grid,
  Text,
  Field
} from './../../components'
import {
  VARIABLES,
  TYPOGRAPHYS,
  SHAREDS,
  LOGOS,
  ICONS
} from './../../themes'
// import {
//   ROUTE_PATH,
//   redirect,
// } from './../../helpers'

export class LoginMethodPage extends React.Component {
  render() {
    return (
      <TransitionContainer
        // motion='overlap-from'
      >
        <Container
          width={VARIABLES.WEBSITE_WIDTH}
          height='100'
          heightUnit='vh'
          bgImage={SHAREDS['bg-login.jpg']}
          bgSize='cover'
          bgRepeat='no-repeat'
          bgPosition='center'
        >
          <Container
            height='100'
            heightUnit='vh'
            bgColor={VARIABLES.COLORS.OVERLAY_1}
            justify='space-between'
            align='center'
            wrap='no-wrap'
          >
            <Section
              className='is-login-container'
              width='275'
            >
              <Section
                className='is-login-logo'
                justify='center'
              >
                <Image
                  widthRatio='121'
                  widthRatioUnit='px'
                  heightRatio='69'
                  heightRatioUnit='px'
                  src={LOGOS['logo.png']}
                />
              </Section>
              <Section
                spacingBottom='20'
                spacingLeft='10'
              >
                <Grid gutter='12'>
                  <Grid.Column>
                    <Image
                      widthRatio='24'
                      widthRatioUnit='px'
                      heightRatio='24'
                      heightRatioUnit='px'
                      src={ICONS['ic-phone.svg']}
                    />
                  </Grid.Column>
                  <Grid.Column>
                    <Text
                      fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_16}
                      color={VARIABLES.COLORS.WHITE}
                      top='2'
                      onClick={() => {}}
                    >
                      Phone number
                    </Text>
                  </Grid.Column>
                </Grid>
              </Section>
              <Section spacingBottom='34'>
                <Field
                  fluid
                  className='is-phone-id'
                  placeholder='88 888 8888'
                  placeholderColor={VARIABLES.COLORS.OVERLAY_2}
                  height='28'
                  paddingBottom='10'
                  paddingLeft='107'
                  bgColor={VARIABLES.COLORS.TRANSPARENT}
                  borderBottomWidth='1'
                  borderBottomStyle='solid'
                  borderBottomColor={VARIABLES.COLORS.WHITE}
                  fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_15}
                  fontColor={VARIABLES.COLORS.WHITE}
                  fieldIcon={
                    <Grid gutter='10'>
                      <Grid.Column>
                        <Image
                          widthRatio='27'
                          widthRatioUnit='px'
                          heightRatio='18'
                          heightRatioUnit='px'
                          src={ICONS['ic-flag-th.png']}
                        />
                      </Grid.Column>
                      <Grid.Column>
                        <Text
                          fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_15}
                          color={VARIABLES.COLORS.WHITE}
                        >
                          +66
                        </Text>
                      </Grid.Column>
                    </Grid>
                  }
                  fieldIconSpacingLeft='10'
                />
              </Section>
              <Section spacingBottom='34'>
                <Field
                  fluid
                  className='is-email'
                  placeholder='Email'
                  placeholderColor={VARIABLES.COLORS.WHITE}
                  height='28'
                  paddingBottom='10'
                  paddingLeft='47'
                  bgColor={VARIABLES.COLORS.TRANSPARENT}
                  borderBottomWidth='1'
                  borderBottomStyle='solid'
                  borderBottomColor={VARIABLES.COLORS.WHITE}
                  fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_15}
                  fontColor={VARIABLES.COLORS.WHITE}
                  fieldIcon={
                    <Image
                      widthRatio='24'
                      widthRatioUnit='px'
                      heightRatio='18'
                      heightRatioUnit='px'
                      src={ICONS['ic-email.svg']}
                    />
                  }
                  fieldIconSpacingLeft='10'
                />
              </Section>
              <Section spacingBottom='12'>
                <Field
                  fluid
                  className='is-password'
                  inputTextType='password'
                  placeholder='Password'
                  placeholderColor={VARIABLES.COLORS.WHITE}
                  height='28'
                  paddingBottom='10'
                  paddingLeft='47'
                  bgColor={VARIABLES.COLORS.TRANSPARENT}
                  borderBottomWidth='1'
                  borderBottomStyle='solid'
                  borderBottomColor={VARIABLES.COLORS.WHITE}
                  fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_15}
                  fontColor={VARIABLES.COLORS.WHITE}
                  fieldIcon={
                    <Image
                      widthRatio='21'
                      widthRatioUnit='px'
                      heightRatio='28'
                      heightRatioUnit='px'
                      src={ICONS['ic-password.svg']}
                    />
                  }
                  fieldIconSpacingLeft='10'
                />
              </Section>
              <Section
                className='is-login-forgotpassword'
                justify='flex-end'
              >
                <Text
                  fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_14}
                  color={VARIABLES.COLORS.BLUE_2}
                  onClick={() => {}}
                >
                  forgot password?
                </Text>
              </Section>
              <Section spacingBottom='18'>
                <Button
                  fluid
                  className='is-icon-left'
                  height={VARIABLES.BUTTON.HEIGHTS.H_40}
                  borderRadius={VARIABLES.BUTTON.BORDER_RADIUSES.BR_10}
                  bgColor={VARIABLES.COLORS.BLUE_2}
                >
                  <Button.Icon>
                    <Image
                      widthRatio='18'
                      widthRatioUnit='px'
                      heightRatio='18'
                      heightRatioUnit='px'
                      src={ICONS['ic-facebook.svg']}
                    />
                  </Button.Icon>
                  <Text
                    fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_16}
                    color={VARIABLES.COLORS.WHITE}
                  >
                    Login
                  </Text>
                </Button>
              </Section>
              <Section spacingBottom='18'>
                <Button
                  fluid
                  className='is-icon-left'
                  height={VARIABLES.BUTTON.HEIGHTS.H_40}
                  bgColor={VARIABLES.COLORS.TRANSPARENT}
                  borderRadius={VARIABLES.BUTTON.BORDER_RADIUSES.BR_10}
                  borderWidth='1'
                  borderStyle='solid'
                  borderColor={VARIABLES.COLORS.WHITE}
                >
                  <Button.Icon>
                    <Image
                      widthRatio='18'
                      widthRatioUnit='px'
                      heightRatio='18'
                      heightRatioUnit='px'
                      src={ICONS['ic-facebook.svg']}
                    />
                  </Button.Icon>
                  <Text
                    fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_16}
                    color={VARIABLES.COLORS.WHITE}
                  >
                    Connect with Facebook
                  </Text>
                </Button>
              </Section>
              <Section>
                <Button
                  fluid
                  height={VARIABLES.BUTTON.HEIGHTS.H_40}
                  bgColor={VARIABLES.COLORS.TRANSPARENT}
                  borderRadius={VARIABLES.BUTTON.BORDER_RADIUSES.BR_10}
                  borderWidth='1'
                  borderStyle='solid'
                  borderColor={VARIABLES.COLORS.WHITE}
                >
                  <Text
                    fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_16}
                    color={VARIABLES.COLORS.WHITE}
                  >
                    Sign up
                  </Text>
                </Button>
              </Section>
            </Section>
            <Section className='is-login-back'>
              <Grid
                gutter='3'
                justify='center'
              >
                <Grid.Column>
                  <Text
                    fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_14}
                    color={VARIABLES.COLORS.BLUE_2}
                  >
                    Choose again?
                  </Text>
                </Grid.Column>
                <Grid.Column>
                  <Text
                    className='is-underline'
                    fontStyle={TYPOGRAPHYS.FIRST.BOLD.FS_14}
                    color={VARIABLES.COLORS.BLUE_2}
                    onClick={() => {}}
                  >
                    Back
                  </Text>
                </Grid.Column>
              </Grid>
            </Section>
          </Container>
        </Container>
      </TransitionContainer>
    )
  }
}
