import React from 'react'
import ClassNames from 'classnames'
import PropTypes from 'prop-types'
import {
  SectionBaseWrapper
} from './styled'
// import {
//   default as VARIABLES
// } from './../../../themes/styles/bases/variables'
// import {
//   default as TYPOGRAPHYS
// } from './../../../themes/styles/bases/typographys'

/**
 * SectionBase description:
 * - base component Section
 */

export class SectionBase extends React.PureComponent {
  render () {
    const {
      // Option
      children,
      className,
      scroll,
      nonFluid,
      // Default
      flex,
      direction,
      justify,
      align,
      width,
      maxWidth,
      widthUnit,
      height,
      minHeight,
      maxHeight,
      calcHeight,
      calcMinHeight,
      heightUnit,
      padding,
      paddingTop,
      paddingRight,
      paddingBottom,
      paddingLeft,
      paddingHorizontal,
      paddingVertical,
      spacing,
      spacingTop,
      spacingRight,
      spacingBottom,
      spacingLeft,
      spacingHorizontal,
      spacingVertical,
      bgColor,
      borderTopWidth,
      borderTopStyle,
      borderTopColor,
      borderRightWidth,
      borderRightStyle,
      borderRightColor,
      borderBottomWidth,
      borderBottomStyle,
      borderBottomColor,
      borderLeftWidth,
      borderLeftStyle,
      borderLeftColor,
      borderWidth,
      borderStyle,
      borderColor,
      onClick,
      position,
      borderRadius,
      textAlign,
      bottom,
      top,
      right,
      borderBottomRightRadius,
      borderBottomLeftRadius,
      breakAll,
    } = this.props

    return (
      <SectionBaseWrapper
        className={ClassNames(
          'section-base',
          {'is-scroll': scroll},
          {'is-non-fluid': nonFluid},
          // Option
          className
        )}
        theme={{
          // Default
          flex,
          direction,
          justify,
          align,
          width,
          maxWidth,
          widthUnit,
          height,
          minHeight,
          maxHeight,
          calcHeight,
          calcMinHeight,
          heightUnit,
          padding,
          paddingTop,
          paddingRight,
          paddingBottom,
          paddingLeft,
          paddingHorizontal,
          paddingVertical,
          spacing,
          spacingTop,
          spacingRight,
          spacingBottom,
          spacingLeft,
          spacingHorizontal,
          spacingVertical,
          bgColor,
          borderTopWidth,
          borderTopStyle,
          borderTopColor,
          borderRightWidth,
          borderRightStyle,
          borderRightColor,
          borderBottomWidth,
          borderBottomStyle,
          borderBottomColor,
          borderLeftWidth,
          borderLeftStyle,
          borderLeftColor,
          borderWidth,
          borderStyle,
          borderColor,
          position,
          borderRadius,
          textAlign,
          bottom,
          top,
          right,
          borderBottomRightRadius,
          borderBottomLeftRadius,
          breakAll,
        }}
        onClick={onClick}
      >
        {children}
      </SectionBaseWrapper>
    )
  }

  static defaultProps = {
    // Option
    // children: undefined,
    // className: undefined,
    // Default
    widthUnit: 'px',
    heightUnit: 'px'
  }

  static propTypes = { // TYPE > node, string, number, bool, array, object, symbol, func
    /**
    * Additional classes
    */
    className: PropTypes.string,

    /**
    * Additional Text, HTML, Component
    */
    children: PropTypes.oneOfType([
      PropTypes.node,
      PropTypes.string
    ])
  }
}
