import React from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import {
  TransitionContainer,
  Container,
  Image,
  Section,
  Text,
  Grid,
  SwiperCarousel,
  CommentPanel,
  Field,
  Button,
} from './../../components';
import {
  VARIABLES,
  TYPOGRAPHYS,
  CONTENTS,
  ICONS,
} from './../../themes';
import {
  ROUTE_PATH,
  redirect,
} from './../../helpers'
import { getLanguage } from '../../helpers/functions/language'

export class PublicNewsAndPromotionsWriteCommentPage extends React.Component {

  constructor(props){
    super(props);
    this.state={
      // state
    };
  }

  render() {
    const {
      // state
    } = this.state

    return (
      <TransitionContainer
        // motion='overlap-from'
      >
        <Container
          calcMinHeight='100vh'
          bgColor={VARIABLES.COLORS.GRAY_5}
        >
          <Scrollbars style={{ height: '100vh' }}>
            <Container
              width='640'
              calcMinHeight='100vh'
              paddingVertical='35'
              paddingHorizontal='35'
              bgColor={VARIABLES.COLORS.WHITE}
            >
              <Section
                nonFluid
                paddingBottom='60'
                bgColor={VARIABLES.COLORS.WHITE}
                onClick={() => {
                  redirect(`${ROUTE_PATH.PUBLIC_NEWS_PROMOTIONS.LINK}/0`)
                }}
              >
                <Grid gutter='15'>
                  <Grid.Column>
                    <Image
                      widthRatio='11'
                      widthRatioUnit='px'
                      heightRatio='21'
                      heightRatioUnit='px'
                      src={ICONS['ic-left-black.svg']}
                    />
                  </Grid.Column>
                  <Grid.Column>
                    <Text
                      fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_18}
                      color={VARIABLES.COLORS.BLACK}
                      top='-1'
                    >
                      Back
                    </Text>
                  </Grid.Column>
                </Grid>
              </Section>
              <Section spacingBottom='20'>
                <Field
                  fluid
                  floatLabel
                  height='40'
                  label={getLanguage('field.title')}
                  labelFontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_12}
                  labelFontColor={VARIABLES.COLORS.GRAY_3}
                  placeholder={getLanguage('field.titlePlaceholder')}
                  placeholderColor={VARIABLES.COLORS.GRAY_5}
                  paddingHorizontal='10'
                  borderWidth='1'
                  borderStyle='solid'
                  borderColor={VARIABLES.COLORS.GRAY_5}
                  borderRadius='5'
                  fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_14}
                  fontColor={VARIABLES.COLORS.BLACK}
                  // value={}
                  onChange={() => {}}
                />
              </Section>
              <Section spacingBottom='6'>
                <Field
                  fluid
                  floatLabel
                  type='textarea'
                  maxLength='1000'
                  height='155'
                  label={getLanguage('field.comment')}
                  labelFontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_12}
                  labelFontColor={VARIABLES.COLORS.GRAY_3}
                  placeholder={getLanguage('field.commentPlaceholder')}
                  placeholderColor={VARIABLES.COLORS.GRAY_5}
                  paddingTop='12'
                  paddingHorizontal='10'
                  borderWidth='1'
                  borderStyle='solid'
                  borderColor={VARIABLES.COLORS.GRAY_5}
                  borderRadius='5'
                  fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_14}
                  fontColor={VARIABLES.COLORS.BLACK}
                  // value={}
                  onChange={() => {}}
                />
              </Section>
              <Section spacingBottom='28' justify='flex-end'>
                <Text
                  fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_12}
                  color={VARIABLES.COLORS.GRAY_4}
                >
                  {getLanguage('newsAndPromotionsWriteComment.characters')} ( 0 / 1,000 )
                </Text>
              </Section>
              <Section justify='center'>
                <Button
                  shadow
                  className='is-gradient-blue'
                  width='275'
                  height={VARIABLES.BUTTON.HEIGHTS.H_40}
                  borderRadius={VARIABLES.BUTTON.BORDER_RADIUSES.BR_10}
                  onClick={() => {}}
                >
                  <Text
                    fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_16}
                    color={VARIABLES.COLORS.WHITE}
                  >
                    {getLanguage('field.buttonSubmit')}
                  </Text>
                </Button>
              </Section>
            </Container>
          </Scrollbars>
        </Container>
      </TransitionContainer>
    )
  }
}
