import React from 'react'
import _ from 'lodash'
import axios from 'axios'
import {
  TransitionContainer,
  Container,
  Section,
  Image,
  Grid,
  Text,
  Field,
  Button,
  NavigationBar,
} from './../../components'
import {
  VARIABLES,
  TYPOGRAPHYS,
  ICONS,
} from './../../themes'
import {
  SearchResult,
} from './SearchResult'
import config from './../../config'
import { getLanguage } from '../../helpers/functions/language'
import { getAvailableCountryDataList } from '../../helpers/functions/data'

export class RewardSearchPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      id: (this.props.match.params && this.props.match.params.id) ? this.props.match.params.id : 1,
      toggleSearch: false,
      keyword: '',
      list: [],
    }
    this.timer = null
  }

  setToggleSearch() {
    this.setState({ toggleSearch: true })
  }

  onChangeKeyword(event) {
    const keyword = event.target.value
    this.setToggleSearch()
    this.setState({ keyword })
    if (keyword) {
      clearTimeout(this.timer)
      this.timer = setTimeout(() => {
        this.setState({ isLoading: true }, async () => {
          try {
            const url = `${config.api.cms}/reward/search/`
            const options = { name: keyword, sort: 'id desc', status: 'active' }
            const response = await axios.post(url, options)
            let data = _.get(response, 'data.data')
            if (response.status === 200 && !_.isEmpty(data)) {
              data = getAvailableCountryDataList(data);
              this.setState({ list: data })
              const storage = JSON.parse(localStorage.getItem('rewardCatalogList'))
              if (storage) {
                const rewardCatalogList = _.uniqBy(storage.concat(data), 'id')
                localStorage.setItem('rewardCatalogList', JSON.stringify(rewardCatalogList))
              } else {
                localStorage.setItem('rewardCatalogList', JSON.stringify(data))
              }
            }
          } catch (_) {
          } finally {
            this.setState({ isLoading: false })
          }
        })
      }, 500)
    } else {
      this.resetData()
    }
  }

  resetData() {
    this.setState({ keyword: '', list: [] })
  }

  onClickClearKeyword() {
    this.resetData()
  }

  render() {
    const { list, toggleSearch, isLoading, keyword } = this.state;
    return (
      <TransitionContainer>
        <Container
          width={VARIABLES.WEBSITE_WIDTH}
          calcMinHeight='100vh'
          bgColor={VARIABLES.COLORS.LIGHT_BLUE_2}
        >
          <NavigationBar
            pageTitle={`${toggleSearch ? getLanguage('rewardSearch.title_1') : getLanguage('rewardSearch.title_2')}`}
            actionLeft={
              <Image
                widthRatio='21'
                widthRatioUnit='px'
                heightRatio='21'
                heightRatioUnit='px'
                src={ICONS['ic-close-black.svg']}
                onClick={this.props.history.goBack}
              />
            }
          />
          <Section
            paddingHorizontal='20'
            paddingTop='23'
            paddingBottom='10'
          >
            <Section spacingBottom='25' position='relative'>
              {keyword && <Button
                className='is-clear-search'
                width={20}
                height={20}
                bgImage={ICONS['ic-close-blue-circle.svg']}
                bgSize='cover'
                bgRepeat='no-repeat'
                bgPosition='center'
                bgColor={VARIABLES.COLORS.LIGHT_BLUE_2}
                onClick={this.onClickClearKeyword.bind(this)}
              />}
              <Section position='absolute' className='is-search-placeholder'>
                <Grid gutter='10'>
                  <Grid.Column>
                    <Image
                      widthRatio='15'
                      widthRatioUnit='px'
                      heightRatio='15'
                      heightRatioUnit='px'
                      top='-2'
                      src={`${keyword ? ICONS['ic-search-blue.png'] : ICONS['ic-search-light-blue.png']}`}
                    />
                  </Grid.Column>
                  <Grid.Column >
                    {<Text
                      fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_14}
                      color={`${keyword ? VARIABLES.COLORS.BLUE_2 : VARIABLES.COLORS.LIGHT_BLUE_3}`}
                      ellipsis
                    >
                      {!keyword ? getLanguage('rewardSearch.rewardName') : ''}
                    </Text>}
                  </Grid.Column>
                  <Grid.Column >
                  </Grid.Column>
                </Grid>
              </Section>
              <Field
                fluid
                value={keyword}
                bgColor={VARIABLES.COLORS.TRANSPARENT}
                borderWidth='1'
                borderStyle='solid'
                borderColor={VARIABLES.COLORS.BLUE_2}
                borderRadius='25'
                fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_15}
                fontColor={VARIABLES.COLORS.BLUE_2}
                paddingHorizontal={42}
                paddingTop={10}
                paddingBottom={10}
                onChange={this.onChangeKeyword.bind(this)}
              />
            </Section>
            {toggleSearch && !isLoading && <SearchResult list={list} />}
          </Section>
        </Container>
      </TransitionContainer>
    )
  }
}

