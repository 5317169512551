import React from 'react'
import ClassNames from 'classnames'
import PropTypes from 'prop-types'
import {
  AccordionControlBaseWrapper
} from './styled'
// import {
//   default as VARIABLES
// } from './../../../themes/styles/bases/variables'
// import {
//   default as TYPOGRAPHYS
// } from './../../../themes/styles/bases/typographys'

/**
 * AccordionControlBase description:
 * - base component AccordionControl
 */

export class AccordionControlBase extends React.PureComponent {
  render () {
    const {
      // Option
      children,
      className,
      // Default
      dropdown
    } = this.props

    return (
      <AccordionControlBaseWrapper
        className={ClassNames(
          'accordion-control-base',
          {'is-dropdown': dropdown},
          // Option
          className
        )}
        theme={{
          // Default
        }}
      >
        {children}
      </AccordionControlBaseWrapper>
    )
  }

  static defaultProps = {
    // Option
    // children: undefined,
    // className: undefined,
    // Default
  }

  static propTypes = { // TYPE > node, string, number, bool, array, object, symbol, func
    /**
    * Additional classes
    */
    className: PropTypes.string,

    /**
    * Additional Text, HTML, Component
    */
    children: PropTypes.oneOfType([
      PropTypes.node,
      PropTypes.string
    ])
  }
}
