import React from "react";
import { connect } from "react-redux";
import { Modal, Section, Text, Button, Image } from "../../../components";
import { ICONS, VARIABLES, TYPOGRAPHYS } from "../../../themes";
import { getLanguage } from "../../../helpers/functions/language";
import { DATA } from "../../../helpers/constants/data";
import { displayWithComma } from "../../../helpers/functions/display";

export class ModalConfirmWinPrizesContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const {
      isShowModal,
      isSuccess,
      onClickClose,
      onClickConfirm,
      redemptionPoint,
      totalPoint
    } = this.props;
    return (
      <Modal
        open={isShowModal}
        width={320}
        overlayColor={VARIABLES.COLORS.OVERLAY_3}
        spacing={VARIABLES.MODAL.CONTAINER.C_1.SPACING}
        transiti
        on={VARIABLES.TRANSITIONS.DEFAULT}
        transformStart={VARIABLES.MODAL.CONTAINER.C_1.TRANSFORM_START}
        transformEnd={VARIABLES.MODAL.CONTAINER.C_1.TRANSFORM_END}
        onClickClose={onClickClose}
      >
        <Modal.Section
          paddingTop="35"
          borderTopRightRadius={
            VARIABLES.MODAL.HEADER.H_1.BORDER_TOP_RIGHT_RADIUS
          }
          borderTopLeftRadius={
            VARIABLES.MODAL.HEADER.H_1.BORDER_TOP_LEFT_RADIUS
          }
          paddingBottom={VARIABLES.MODAL.BODY.B_1.PADDING_BOTTOM}
          paddingHorizontal="20"
        >
          {isSuccess ? (
            <React.Fragment>
              <Section justify="center" spacingBottom="15">
                <Image
                  widthRatio="65"
                  widthRatioUnit="px"
                  heightRatio="65"
                  heightRatioUnit="px"
                  src={ICONS["ic-check-circle-outline-green.svg"]}
                />
              </Section>
              <Section justify="center" spacingBottom="15">
                <Text
                  fontStyle={TYPOGRAPHYS.FIRST.BOLD.FS_20}
                  color={"#3CC480"}
                >
                  {getLanguage("luckyDraw.goodLuckWithWinnigPrizes")}
                </Text>
              </Section>
              <Section justify="center">
                <Text
                  fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_14}
                  color={VARIABLES.COLORS.BLACK}
                >
                  {getLanguage("luckyDraw.youHave")} {displayWithComma(totalPoint)} {getLanguage("luckyDraw.pointLeft")}
                </Text>
              </Section>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <Section justify="center" spacingBottom="15">
                <Image
                  widthRatio="63"
                  widthRatioUnit="px"
                  heightRatio="63"
                  heightRatioUnit="px"
                  src={ICONS["ic-lucky-draw-blue.svg"]}
                />
              </Section>
              <Section justify="center" spacingBottom="15">
                <Text fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_18}>
                  {getLanguage("luckyDraw.youUseYourRightToWinPrizes")}
                </Text>
              </Section>
              <Section justify="center">
                <Text
                  fontStyle={TYPOGRAPHYS.FIRST.BOLD.FS_20}
                  color={VARIABLES.COLORS.PRIMARY_2}
                >
                  {displayWithComma(redemptionPoint)} {getLanguage("luckyDraw.points")}
                </Text>
              </Section>
            </React.Fragment>
          )}
        </Modal.Section>
        <Modal.Section
          paddingBottom={VARIABLES.MODAL.FOOTER.F_2.PADDING_BOTTOM}
          paddingHorizontal={VARIABLES.MODAL.FOOTER.F_2.PADDING_HORIZONTAL}
          borderBottomRightRadius={
            VARIABLES.MODAL.FOOTER.F_2.BORDER_BOTTOM_RIGHT_RADIUS
          }
          borderBottomLeftRadius={
            VARIABLES.MODAL.FOOTER.F_2.BORDER_BOTTOM_LEFT_RADIUS
          }
        >
          <Section justify="center" spacingBottom="15">
            <Button
              width="200"
              bgColor={VARIABLES.COLORS.TRANSPARENT}
              borderWidth="1"
              borderStyle="solid"
              borderColor={VARIABLES.COLORS.BLUE_2}
              shadow
              height={VARIABLES.BUTTON.HEIGHTS.H_35}
              borderRadius={VARIABLES.BUTTON.BORDER_RADIUSES.BR_10}
              onClick={onClickClose}
            >
              <Text
                fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_16}
                color={VARIABLES.COLORS.BLUE_2}
              >
                {getLanguage("field.buttonClose")}
              </Text>
            </Button>
          </Section>
          {!isSuccess && (
            <Section justify="center">
              <Button
                width="200"
                className="is-gradient-blue"
                borderWidth="1"
                borderStyle="solid"
                borderColor={VARIABLES.COLORS.BLUE_2}
                shadow
                height={VARIABLES.BUTTON.HEIGHTS.H_35}
                borderRadius={VARIABLES.BUTTON.BORDER_RADIUSES.BR_10}
                onClick={onClickConfirm}
              >
                <Text
                  fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_16}
                  color={VARIABLES.COLORS.WHITE}
                >
                  {getLanguage("field.buttonConfirm")}
                </Text>
              </Button>
            </Section>
          )}
        </Modal.Section>
      </Modal>
    );
  }
}
