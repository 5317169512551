import React, { Fragment } from 'react'
import _ from 'lodash'
import {
  TransitionContainer,
  Container,
  Section,
  Image,
  NavigationBar,
  Text,
} from './../../components'
import {
  VARIABLES,
  ICONS,
  TYPOGRAPHYS,
} from './../../themes'
import {
  ROUTE_PATH,
  redirect,
  getRouteData,
} from './../../helpers'
import {
  WarrantyProductList,
} from './WarrantyProductList'
import {
  Loader
} from '../Loader'
import { getLanguage } from '../../helpers/functions/language'

export class WarrantyProductPage extends React.Component {
  constructor(props) {
    super(props)
    const detailList = getRouteData(props, 'detailList') || [];
    this.state = {
      id: (this.props.match.params && this.props.match.params.id) ? this.props.match.params.id : '',
      // data: {},
      isLoading: false,
      detailList
    }
  }

  componentDidMount() {
    // localStorage.setItem('warrantySearchType', 'warrantyProduct')
    // const data = JSON.parse(localStorage.getItem('warrantyList'))
    // if (_.isEmpty(data)) return
    // const result = data.find(item => item.wrt_no === this.state.id)
    // if (result) {
    //   this.setState({ data: result })
    // }
  }

  handleBack() {
    redirect(ROUTE_PATH.WARRANTY.LINK)
  }

  render() {
    const {
      // data, 
      isLoading,
      id,
      detailList
    } = this.state;
    return (
      <TransitionContainer>
        <Container
          width={VARIABLES.WEBSITE_WIDTH}
          calcMinHeight='100vh'
          bgColor={VARIABLES.COLORS.LIGHT_BLUE_2}
        >
          <NavigationBar
            shadow
            pageTitle={`Order ID ${id || ''}`}
            actionLeft={
              <Image
                widthRatio='11'
                widthRatioUnit='px'
                heightRatio='21'
                heightRatioUnit='px'
                src={ICONS['ic-left-black.svg']}
                onClick={this.handleBack.bind(this)}
              />
            }
            actionRight={
              <Fragment>
                <Section>
                  <Image
                    widthRatio='19'
                    widthRatioUnit='px'
                    heightRatio='17'
                    heightRatioUnit='px'
                    src={ICONS['ic-filter-black.svg']}
                    onClick={() => { redirect(`${ROUTE_PATH.WARRANTY_SEARCH_DATE.LINK}?id=${id}`) }}
                  />
                </Section>
                <Section paddingLeft='15'>
                  <Image
                    widthRatio='20'
                    widthRatioUnit='px'
                    heightRatio='20'
                    heightRatioUnit='px'
                    src={ICONS['ic-search-black.svg']}
                    onClick={() => { redirect(ROUTE_PATH.WARRANTY_SEARCH_NAME.LINK) }}
                  />
                </Section>
              </Fragment>
            }
          />
          <Section
            scroll
            calcHeight='100vh - 70px'
          >
            <Section
              paddingHorizontal='20'
              paddingTop='24'
              paddingBottom='40'
            >
              {/* {!_.isEmpty(data) && <WarrantyProductList list={data.detail} id={id} />} */}
              {!_.isEmpty(detailList) && <WarrantyProductList list={detailList} id={id} />}
              {_.isEmpty(detailList) && <Section
                paddingTop='24'
                justify='center'
              >
                <Text
                  fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_16}
                  color={VARIABLES.COLORS.BLUE_2}
                >
                  {getLanguage('warrantyProduct.dataNotFound')}
                </Text>
              </Section>}
            </Section>
          </Section>
        </Container>
        <Loader loading={isLoading} isOverlay />
      </TransitionContainer>
    )
  }
}

