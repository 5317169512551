import moment from 'moment';
import {
  getPhoneRemovePrefix,
  getIsLanguageTH,
} from '../helpers/functions/data';
import { displayUrlPath } from '../helpers/functions/display';

export class ModelLuckyDraw {
  constructor(props) {
    props = props || {};
    this.id = props.id;
    this.campaignName = props.campaign_name || '';
    this.startDate = props.start_date;
    this.endDate = props.end_date;
    this.luckyDrawDate = props.lucky_draw_date;
    this.announcementDate = props.announcement_date;
  }
}

export class ModelLuckyDrawRewardWinning {
  constructor(props = {}) {
    this.id = props.id;
    this.customerName = props.customer_name;
    this.province = props.province;
    this.phone = props.phone__c;
    this.winningQuantity = props.winning_quantity || 0;
    this.runningNumber = props.running_number;

    this.displayPhone = getPhoneRemovePrefix(props.phone__c).padStart(10, '0');
  }
}

export class ModelLuckyDrawReward {
  constructor(props = {}) {
    this.id = props.id;
    this.orderNo = props.order_no;
    this.rewardName = props.reward_name || '';
    this.quantity = props.quantity;
    this.rewardDetail = props.reward_detail || '';
    this.rewardImage = props.reward_image || '';
    this.winningList = (props.winnings || []).map((e) => {
      return new ModelLuckyDrawRewardWinning(e);
    });

    this.rewardImagePath = displayUrlPath(this.rewardImage);
  }
}

export class ModelLuckyDrawDetail {
  constructor(props) {
    props = props || {};
    this.id = props.id;
    this.campaignName = props.campaign_name || '';
    this.campaignTNC = props.campaign_tnc;
    this.startDate = props.start_date;
    this.endDate = props.end_date;
    this.luckyDrawDate = props.lucky_draw_date;
    this.announcementDate = props.announcement_date;
    this.thumbnail = props.thumbnail || '';
    this.rewardList = (props.rewards || []).map((e) => {
      return new ModelLuckyDrawReward(e);
    });

    this.thumbnailPath = displayUrlPath(this.thumbnail);
  }
}

export class ModelLuckyDrawTNC {
  constructor(props) {
    props = props || {};
    this.id = props.id;
    this.detail = props.detail;
    this.isNotShowAgain = !!props.is_not_show_again;
  }
}

export class ModelLuckyExpirePoint {
  constructor(props = {}) {
    const isLanguageThai = getIsLanguageTH();

    this.expireDate = props.expiration_date__c;
    this.displayExpireDate = this.expireDate
      ? moment(this.expireDate)
          .locale(isLanguageThai ? 'th' : 'en')
          .format('DD MMM YYYY')
      : '-';
    this.point = props.point_left__c;
  }
}
