import React from 'react'
import _ from 'lodash'
import {
  SwiperCarousel,
  Image,
  Section,
  Text,
  Grid,
  Button,
} from '../../components'
import {
  VARIABLES,
  TYPOGRAPHYS,
  ICONS,
} from '../../themes'
import {
  ROUTE_PATH,
  redirect,
} from '../../helpers'
import {
  getDistanceFromLatLonInKm
} from '../../helpers/functions/data'
import config from './../../config'

export class DealerDetailCard extends React.Component {
  renderRating(rating) {
    if (!rating) return
    rating = parseFloat(rating)
    const stars = []
    for (let i = 0; i < Math.floor(rating); i++) {
      if (i === 5) break
      stars.push(<Grid.Column key={i}>
        <Image
          widthRatio='14'
          widthRatioUnit='px'
          heightRatio='14'
          heightRatioUnit='px'
          src={ICONS['ic-star-blue.svg']}
        />
      </Grid.Column>)
    }

    return (
      <Grid.Column>
        <Grid gutter='2'>
          {stars}
          <Grid.Column>
            <Section
              paddingTop='2'
            >
              <Text
                fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_12}
                color={VARIABLES.COLORS.BLUE_2}
              >
                {rating.toFixed(1)}
              </Text>
            </Section>
          </Grid.Column>
        </Grid>
      </Grid.Column>
    )
  }

  dealerDetail = {
    onClickPath: (path) => {
      if (path) {
        let url = path.indexOf('http') !== 0 ? `http://${path}` : path;
        window.open(url, '_blank');
      }
    },
    onClickEmail: (email) => {
      if (email) {
        window.open(`mailto:${email}`, '_blank');
      }
    }
  }

  render() {
    const {
      data,
      index,
      userLatitude,
      userLongitude,
      isMalay,
    } = this.props;

    // force value when empty gallery for display img box
    const gallery = _.isArray(data.gallery) ? data.gallery : ['']
    let distance = null
    if (userLatitude && userLongitude) {
      distance = getDistanceFromLatLonInKm(userLatitude, userLongitude, data.latitude, data.longitude)
      distance = distance.toFixed(2)
    }
    return (
      <Section
        className='is-dealer-detail-card'
        width={VARIABLES.WEBSITE_WIDTH}
        maxWidth={VARIABLES.WEBSITE_WIDTH}
        bgColor={VARIABLES.COLORS.WHITE}
        borderRadius={5}
        spacingBottom={30}
      >
        <Section
          relative
          borderRadius={5}
        >
          <Section >
            <SwiperCarousel className='is-dealer-card'>
              {gallery.map((img, i) => (<SwiperCarousel.Item key={i}>
                <Image
                  className='is-dealer-card-img'
                  widthRatio='100'
                  widthRatioUnit='%'
                  heightRatio='97'
                  heightRatioUnit='px'
                  // src={`${config.imgPrefix}/${img}`}
                  src={`${img}`}
                />
                <Section
                  className='is-dealer-card-number'
                  bgColor={VARIABLES.COLORS.BLUE_3}
                  position='absolute'
                  width='32'
                  height='32'
                  justify='center'
                  align='center'
                >
                  <Text
                    fontStyle={TYPOGRAPHYS.FIRST.BOLD.FS_14}
                    color={VARIABLES.COLORS.WHITE}
                  >
                    {index}
                  </Text>
                </Section>
                {distance && <Section
                  className='is-dealer-card-distance'
                  bgColor='#00000080'
                  position='absolute'
                  width='auto'
                  paddingHorizontal='10'
                  borderRadius='15'
                  height='23'
                  justify='center'
                  align='center'
                >
                  <Text
                    fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_11}
                    color={VARIABLES.COLORS.WHITE}
                  >
                    {distance} km
                  </Text>
                </Section>}
              </SwiperCarousel.Item>
              ))}
            </SwiperCarousel>
          </Section>
        </Section>
        <Section
          paddingTop='12'
          paddingHorizontal='15'
        >
          <Text
            fontStyle={TYPOGRAPHYS.FIRST.BOLD.FS_18}
            color={VARIABLES.COLORS.BLACK}
          >
            {data.name}
          </Text>
          <Grid gutter='25'>
            {this.renderRating(data.total_rating)}
            <Grid.Column>
              <Section paddingTop='2'>
                <Grid gutter='8'>
                  <Grid.Column>
                    <Image
                      widthRatio='15'
                      widthRatioUnit='px'
                      heightRatio='15'
                      heightRatioUnit='px'
                      src={ICONS['ic-comment.svg']}
                    />
                  </Grid.Column>
                  <Grid.Column>
                    <Text
                      className='is-underline'
                      fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_14}
                      color={VARIABLES.COLORS.BLUE_2}
                      onClick={() => {
                        if (data.total_comment) {
                          redirect(`${ROUTE_PATH.FIND_DEALER_COMMENTS.LINK}/${data.id}`)
                        }
                      }}
                    >
                      {data.total_comment || 0} comments
                  </Text>
                  </Grid.Column>
                </Grid>
              </Section>
            </Grid.Column>
          </Grid>
          <Section
            paddingTop='19'
            paddingBottom='13'
            borderBottomWidth='1'
            borderBottomStyle='solid'
            borderBottomColor={VARIABLES.COLORS.GRAY_5}
          >
            <Section
              paddingBottom='6'
            >
              <Text
                fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_14}
                color={VARIABLES.COLORS.GRAY_3}
              >
                Phone number
              </Text>
            </Section>
            <Grid gutter='5'>
              <Grid.Column>
                <Image
                  widthRatio='19'
                  widthRatioUnit='px'
                  heightRatio='19'
                  heightRatioUnit='px'
                  src={ICONS['ic-phone-blue.svg']}
                />
              </Grid.Column>
              <Grid.Column>
                <Text
                  fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_16}
                  color={VARIABLES.COLORS.BLUE_2}
                >
                  Tel. {data.phone_number}
                </Text>
              </Grid.Column>
            </Grid>
          </Section>
          <Section
            paddingTop='19'
            paddingBottom='13'
            borderBottomWidth='1'
            borderBottomStyle='solid'
            borderBottomColor={VARIABLES.COLORS.GRAY_5}
          >
            <Section
              paddingBottom='6'
            >
              <Text
                fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_14}
                color={VARIABLES.COLORS.GRAY_3}
              >
                Facebook
              </Text>
            </Section>
            <Grid gutter='5'>
              <Grid.Column>
                <Image
                  widthRatio='19'
                  widthRatioUnit='px'
                  heightRatio='19'
                  heightRatioUnit='px'
                  src={ICONS['ic-facebook-blue.svg']}
                />
              </Grid.Column>
              <Grid.Column>
                <Text
                  fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_16}
                  color={VARIABLES.COLORS.BLUE_2}
                  onClick={() => {
                    this.dealerDetail.onClickPath(data.facebook ? data.facebook : '');
                  }}
                >
                  {data.facebook ? data.facebook : ''}
                </Text>
              </Grid.Column>
            </Grid>
          </Section>
          <Section
            paddingTop='19'
            paddingBottom='13'
            borderBottomWidth='1'
            borderBottomStyle='solid'
            borderBottomColor={VARIABLES.COLORS.GRAY_5}
          >
            {
              isMalay ?
                <React.Fragment>
                  <Section
                    paddingBottom='6'
                  >
                    <Text
                      fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_14}
                      color={VARIABLES.COLORS.GRAY_3}
                    >
                      EMAIL
              </Text>
                  </Section>
                  <Grid>
                    <Text
                      fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_16}
                      color={VARIABLES.COLORS.BLACK}
                      top='2'
                      onClick={() => {
                        this.dealerDetail.onClickEmail(data.email ? data.email : '');
                      }}
                    >
                      {_.get(data, 'email', '')}
                    </Text>
                  </Grid>
                </React.Fragment>
                :
                <React.Fragment>
                  <Section
                    paddingBottom='6'
                  >
                    <Text
                      fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_14}
                      color={VARIABLES.COLORS.GRAY_3}
                    >
                      LINE
            </Text>
                  </Section>
                  <Grid gutter='5'>
                    <Grid.Column>
                      <Image
                        widthRatio='19'
                        widthRatioUnit='px'
                        heightRatio='19'
                        heightRatioUnit='px'
                        src={ICONS['ic-line-blue.svg']}
                      />
                    </Grid.Column>
                    <Grid.Column>
                      <Text
                        fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_16}
                        color={VARIABLES.COLORS.BLUE_2}
                      >
                        {_.get(data, 'line_id', '')}
                      </Text>
                    </Grid.Column>
                  </Grid>
                </React.Fragment>
            }
          </Section>
          <Section
            paddingTop='19'
            paddingBottom='13'
            borderBottomWidth='1'
            borderBottomStyle='solid'
            borderBottomColor={VARIABLES.COLORS.GRAY_5}
          >
            <Section
              paddingBottom='6'
            >
              <Text
                fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_14}
                color={VARIABLES.COLORS.GRAY_3}
              >
                AD Website
              </Text>
            </Section>
            <Grid gutter='5'>
              <Grid.Column>
                <Image
                  widthRatio='19'
                  widthRatioUnit='px'
                  heightRatio='19'
                  heightRatioUnit='px'
                  src={ICONS['ic-website.svg']}
                />
              </Grid.Column>
              <Grid.Column>
                <Text
                  fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_16}
                  color={VARIABLES.COLORS.BLUE_2}
                  onClick={() => {
                    this.dealerDetail.onClickPath(data.website ? data.website : '');
                  }}
                >
                  {data.website ? data.website : ''}
                </Text>
              </Grid.Column>
            </Grid>
          </Section>
          <Section
            paddingTop='19'
            paddingBottom='13'
            borderBottomWidth='1'
            borderBottomStyle='solid'
            borderBottomColor={VARIABLES.COLORS.GRAY_5}
          >
            <Section
              paddingBottom='6'
            >
              <Text
                fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_14}
                color={VARIABLES.COLORS.GRAY_3}
              >
                Address
              </Text>
            </Section>
            <Grid>
              <Text
                fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_16}
                color={VARIABLES.COLORS.BLACK}
                top='2'
              >
                {/* 954/2 Ekkachai Road, Kh.Bangbon
                Bangkok, 10150 */}
                {data.address ? data.address : ''}
              </Text>
            </Grid>
          </Section>
          <Section
            paddingTop='19'
            paddingBottom='13'
            borderBottomWidth='1'
            borderBottomStyle='solid'
            borderBottomColor={VARIABLES.COLORS.GRAY_5}
          >
            <Section
              paddingBottom='6'
            >
              <Text
                fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_14}
                color={VARIABLES.COLORS.GRAY_3}
              >
                Open days
              </Text>
            </Section>
            <Grid>
              <Text
                fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_16}
                color={VARIABLES.COLORS.BLACK}
                top='2'
              >
                {data.open_day}
              </Text>
            </Grid>
          </Section>
          <Section
            paddingTop='19'
            paddingBottom='13'
            borderBottomWidth='1'
            borderBottomStyle='solid'
            borderBottomColor={VARIABLES.COLORS.GRAY_5}
          >
            <Section
              paddingBottom='6'
            >
              <Text
                fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_14}
                color={VARIABLES.COLORS.GRAY_3}
              >
                Open hours
              </Text>
            </Section>
            <Grid>
              <Text
                fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_16}
                color={VARIABLES.COLORS.BLACK}
                top='2'
              >
                {/* Mon - Fri  08:00 - 17:30 */}
                {data.opening_hours}{`${data.close_hours ? ` - ${data.close_hours}` : ''}`}
              </Text>
            </Grid>
          </Section>
          {
            isMalay ?
              <React.Fragment>
                {(data.line_id || data.whats_app || data.website) && <Section
                  paddingTop='19'
                  paddingBottom='13'
                  borderBottomWidth='1'
                  borderBottomStyle='solid'
                  borderBottomColor={VARIABLES.COLORS.GRAY_5}
                >
                  <Section
                    paddingBottom='6'
                  >
                    <Text
                      fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_14}
                      color={VARIABLES.COLORS.GRAY_3}
                    >
                      Contact channel
              </Text>
                  </Section>
                  {data.line_id && <Grid>
                    <Grid gutter='5'>
                      <Grid.Column>
                        <Image
                          widthRatio='14'
                          widthRatioUnit='px'
                          heightRatio='14'
                          heightRatioUnit='px'
                          src={ICONS['ic-line.svg']}
                        />
                      </Grid.Column>
                      <Grid.Column>
                        <Text
                          fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_16}
                          color={VARIABLES.COLORS.BLACK}
                          top='2'
                        >
                          Line ID : {data.line_id}
                        </Text>
                      </Grid.Column>
                    </Grid>
                  </Grid>}
                  {data.whats_app && <Grid>
                    <Grid gutter='5'>
                      <Grid.Column>
                        <Image
                          widthRatio='14'
                          widthRatioUnit='px'
                          heightRatio='14'
                          heightRatioUnit='px'
                          src={ICONS['ic-whatsapp.svg']}
                        />
                      </Grid.Column>
                      <Grid.Column>
                        <Text
                          fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_16}
                          color={VARIABLES.COLORS.BLACK}
                          top='2'
                        >
                          Whatsapp : {data.whats_app}
                        </Text>
                      </Grid.Column>
                    </Grid>
                  </Grid>}
                  {data.website && <Grid>
                    <Grid gutter='5'>
                      <Grid.Column>
                        <Image
                          widthRatio='14'
                          widthRatioUnit='px'
                          heightRatio='14'
                          heightRatioUnit='px'
                          src={ICONS['ic-web.svg']}
                        />
                      </Grid.Column>
                      <Grid.Column>
                        <Text
                          fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_16}
                          color={VARIABLES.COLORS.BLACK}
                          top='2'
                        >
                          Website : {''}
                          <Text
                            className='is-underline'
                            fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_16}
                            color={VARIABLES.COLORS.BLUE_2}
                            onClick={() => {
                              this.dealerDetail.onClickPath(data.website ? data.website : '');
                            }}
                          >
                            {data.website ? data.website : ''}
                          </Text>
                        </Text>
                      </Grid.Column>
                    </Grid>
                  </Grid>}
                </Section>}
              </React.Fragment>
              :
              <React.Fragment>
                {data.whats_app && <Section
                  paddingTop='19'
                  paddingBottom='13'
                  borderBottomWidth='1'
                  borderBottomStyle='solid'
                  borderBottomColor={VARIABLES.COLORS.GRAY_5}
                >
                  <Section
                    paddingBottom='6'
                  >
                    <Text
                      fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_14}
                      color={VARIABLES.COLORS.GRAY_3}
                    >
                      Contact channel
              </Text>
                  </Section>
                  <Grid>
                    <Grid gutter='5'>
                      <Grid.Column>
                        <Image
                          widthRatio='14'
                          widthRatioUnit='px'
                          heightRatio='14'
                          heightRatioUnit='px'
                          src={ICONS['ic-whatsapp.svg']}
                        />
                      </Grid.Column>
                      <Grid.Column>
                        <Text
                          fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_16}
                          color={VARIABLES.COLORS.BLACK}
                          top='2'
                        >
                          Whatsapp : {data.whats_app}
                        </Text>
                      </Grid.Column>
                    </Grid>
                  </Grid>
                </Section>}
              </React.Fragment>
          }
          <Section
            paddingTop='20'
            paddingBottom='20'
          >
            <Grid gutter='15'>
              {!isMalay &&
                <Grid.Column flex='1'>
                  <Button
                    shadow
                    className='is-gradient-blue'
                    fluid
                    height={31}
                    borderRadius={VARIABLES.BUTTON.BORDER_RADIUSES.BR_10}
                    onClick={() => {
                      window.location.href = `https://www.google.com/maps/search/?api=1&query=${data.latitude},${data.longitude}`;
                    }}
                  >
                    <Text
                      fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_14}
                      color={VARIABLES.COLORS.WHITE}
                    >
                      Get Direction
                  </Text>
                  </Button>
                </Grid.Column>
              }
              <Grid.Column flex='1'>
                <Button
                  shadow
                  className='is-gradient-blue'
                  fluid
                  height={31}
                  borderRadius={VARIABLES.BUTTON.BORDER_RADIUSES.BR_10}
                  onClick={() => redirect(`${ROUTE_PATH.FIND_DEALER_WRITE_COMMENT.LINK}/${data.id}`)}
                >
                  <Text
                    fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_14}
                    color={VARIABLES.COLORS.WHITE}
                  >
                    Rate & Review
                  </Text>
                </Button>
              </Grid.Column>
            </Grid>
          </Section>
        </Section>
      </Section>
    )
  }
}
