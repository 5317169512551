import React from 'react'
import _ from 'lodash'
import moment from 'moment'
import axios from 'axios'
import {
  Tab,
  Tabs,
  TabList,
  TabPanel
} from 'react-tabs'
import {
  TransitionContainer,
  Container,
  Section,
  Image,
  NavigationBar,
  ReactTabsControl,
  NewsAndPromotionsList
} from './../../components'
import {
  VARIABLES,
  ICONS,
} from './../../themes'
import {
  ROUTE_PATH,
  redirect,
  formatDatetime,
  diffDays,
} from './../../helpers'
import {
  Loader
} from '../Loader'
import config from './../../config'
import { getLanguage } from '../../helpers/functions/language'
import { LOCAL_STORAGE } from '../../helpers/constants/localStorage'
import { getCountryType } from '../../helpers/functions/data'

export class NewsPromotionsPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      tabIndex: 0,
      isLoading: false,
      newsList: [],
      promotionList: [],
    }
  }

  componentDidMount() {
    localStorage.removeItem('newsPromotionList')
    this.fetchList()
  }

  fetchList() {
    this.setState({ isLoading: true }, async () => {
      try {
        const { tabIndex } = this.state
        const type = this.getTypeByTabIndex(tabIndex)
        const key = this.getKeyDataByTabIndex(tabIndex)

        const url = `${config.api.cms}/newspromotion/get/`
        const options = { type, sort: 'display_index asc, public_date desc', status: 'active', app: 'mobileapp' }
        const response = await axios.post(url, options)
        let data = _.get(response, 'data.data')
        if (response.status === 200 && data) {
          let a = JSON.parse(localStorage.getItem(LOCAL_STORAGE.REGISTER_INFO));
          const addressType = getCountryType(a.countries);
          data = data.filter(e => e.available_country.indexOf(addressType) >= 0);
          this.setState({ [key]: data }, () => {
            const newsPromotionList = _.uniqBy(this.state.newsList.concat(this.state.promotionList), 'id')
            localStorage.setItem('newsPromotionList', JSON.stringify(newsPromotionList))
          });
        }
      } catch (_) {
      } finally {
        this.setState({ isLoading: false })
      }
    })
  }

  getTypeByTabIndex(index) {
    const types = { 0: 'news', 1: 'promotion' }
    return types[index]
  }

  getKeyDataByTabIndex(index) {
    const keys = { 0: 'newsList', 1: 'promotionList' }
    return keys[index]
  }

  onSelectTab(index) {
    const key = this.getKeyDataByTabIndex(index)
    const data = this.state[key]
    this.setState({ tabIndex: index }, () => {
      if (!_.isEmpty(data)) return
      this.fetchList()
    })
  }

  convertDatetime(datetime) {
    return formatDatetime(datetime, 'DD MMM, YYYY')
  }

  showLabel(now, publicDate) {
    if (!publicDate || !now) return
    const diff = diffDays(now, publicDate)
    return diff <= 7
  }

  handleBack() {
    redirect(ROUTE_PATH.HOME.LINK)
  }

  redirectToDetailPage(id) {
    redirect(`${ROUTE_PATH.NEWS_PROMOTIONS_DETAIL.LINK}/${id}`)
  }

  render() {
    const { newsList, promotionList, tabIndex } = this.state
    const now = moment()
    return (
      <TransitionContainer>
        <Container
          width={VARIABLES.WEBSITE_WIDTH}
          calcMinHeight='100vh'
          bgColor={VARIABLES.COLORS.LIGHT_BLUE_2}
        >
          <NavigationBar
            iconWidth='19'
            iconHeight='17'
            iconSrc={ICONS['ic-navigation-news-promotions.svg']}
            pageTitleIcon={getLanguage('newsAndPromotions.title')}
            actionLeft={
              <Image
                widthRatio='24'
                widthRatioUnit='px'
                heightRatio='23'
                heightRatioUnit='px'
                src={ICONS['ic-home-black.svg']}
                onClick={this.handleBack.bind(this)}
              />
            }
            actionRight={
              <Image
                widthRatio='21'
                widthRatioUnit='px'
                heightRatio='21'
                heightRatioUnit='px'
                src={ICONS['ic-search-black.svg']}
                onClick={() => redirect(ROUTE_PATH.NEWS_PROMOTIONS_SEARCH.LINK)}
              />
            }
          />
          <ReactTabsControl>
            <Tabs
              defaultIndex={tabIndex}
              onSelect={this.onSelectTab.bind(this)}
            >
              <TabList>
                <Tab>{getLanguage('newsAndPromotions.tab.news.title')}</Tab>
                <Tab>{getLanguage('newsAndPromotions.tab.promotions.title')}</Tab>
              </TabList>
              <TabPanel>
                <Loader
                  loading={this.state.isLoading}
                  paddingTop={30}
                  widthRatio={50}
                  heightRatio={50}
                />
                <Section
                  scroll
                  calcHeight='100vh - 106px'
                  paddingTop='30'
                  paddingBottom='10'
                  paddingHorizontal='20'
                >
                  {newsList.map((data, index) => (
                    <NewsAndPromotionsList
                      key={index}
                      label={this.showLabel(now, data.public_date) ? getLanguage('field.labelNew') : ''}
                      coverImage={`${config.imgPrefix}/${(data.cover) ? data.cover : _.get(data, 'images.0')}`}
                      title={data.subject}
                      datetime={this.convertDatetime(data.public_date || data.create_at)}
                      description={(data.content || '').replace(/<\/?[^>]+(>|$)/g, " ")}
                      onClick={this.redirectToDetailPage.bind(this, data.id)}
                    />
                  ))}
                  {/* 
                  <NewsAndPromotionsList
                    label='NEW'
                    coverImage={CONTENTS['news-4.jpg']}
                    title='BlueScope Design 2017'
                    datetime='20 Oct, 2017 - 13:10'
                    description='NS BlueScope (Thailand) Limited was selected for The Prime Minis Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nemo, tenetur officia rem mollitia, autem magni quae totam eos odit, numquam reprehenderit. Culpa fuga, reiciendis repudiandae cum minus tempora ducimus sit!'
                    onClick={() => redirect(ROUTE_PATH.NEWS_PROMOTIONS_DETAIL.LINK)}
                  />
                  <NewsAndPromotionsList
                    coverImage={CONTENTS['news-1.jpg']}
                    title='Kon Lek Mini Marathon Lorem ipsum, dolor sit amet consectetur adipisicing elit. Nisi esse itaque laudantium consequatur odit, totam at quod eius recusandae non ratione ipsum atque molestias. Tenetur dolores saepe maiores modi voluptates!'
                    datetime='20 Apr, 2019 - 13:10'
                    description='NS BlueScope (Thailand) Limited was selected for The Prime Minis Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nemo, tenetur officia rem mollitia, autem magni quae totam eos odit, numquam reprehenderit. Culpa fuga, reiciendis repudiandae cum minus tempora ducimus sit!'
                    onClick={() => redirect(ROUTE_PATH.NEWS_PROMOTIONS_DETAIL.LINK)}
                  />
                  <NewsAndPromotionsList
                    coverImage={CONTENTS['news-2.jpg']}
                    title='Architect Expo 2018'
                    datetime='20 Oct, 2018 - 13:10'
                    description='NS BlueScope (Thailand) Limited was selected for The Prime Minis Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nemo, tenetur officia rem mollitia, autem magni quae totam eos odit, numquam reprehenderit. Culpa fuga, reiciendis repudiandae cum minus tempora ducimus sit!'
                    onClick={() => redirect(ROUTE_PATH.NEWS_PROMOTIONS_DETAIL.LINK)}
                  /> */}
                </Section>
              </TabPanel>
              <TabPanel>
                <Loader
                  loading={this.state.isLoading}
                  paddingTop={30}
                  widthRatio={50}
                  heightRatio={50}
                />
                <Section
                  scroll
                  calcHeight='100vh - 106px'
                  paddingTop='30'
                  paddingBottom='10'
                  paddingHorizontal='20'
                >
                  {promotionList.map((data, index) => (
                    <NewsAndPromotionsList
                      key={index}
                      label={this.showLabel(now, data.public_date) ? getLanguage('field.labelNew') : ''}
                      coverImage={`${config.imgPrefix}/${(data.cover) ? data.cover : _.get(data, 'images.0')}`}
                      title={data.subject}
                      datetime={this.convertDatetime(data.public_date || data.create_at)}
                      description={(data.content || '').replace(/<\/?[^>]+(>|$)/g, " ")}
                      onClick={this.redirectToDetailPage.bind(this, data.id)}
                    />
                  ))}
                  {/*   <NewsAndPromotionsList
                    coverImage={CONTENTS['news-1.jpg']}
                    title='Kon Lek Mini Marathon Lorem ipsum, dolor sit amet consectetur adipisicing elit. Nisi esse itaque laudantium consequatur odit, totam at quod eius recusandae non ratione ipsum atque molestias. Tenetur dolores saepe maiores modi voluptates!'
                    datetime='20 Apr, 2019 - 13:10'
                    description='NS BlueScope (Thailand) Limited was selected for The Prime Minis Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nemo, tenetur officia rem mollitia, autem magni quae totam eos odit, numquam reprehenderit. Culpa fuga, reiciendis repudiandae cum minus tempora ducimus sit!'
                    onClick={() => redirect(ROUTE_PATH.NEWS_PROMOTIONS_DETAIL.LINK)}
                  />
                  <NewsAndPromotionsList
                    coverImage={CONTENTS['news-2.jpg']}
                    title='Architect Expo 2018'
                    datetime='20 Oct, 2018 - 13:10'
                    description='NS BlueScope (Thailand) Limited was selected for The Prime Minis Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nemo, tenetur officia rem mollitia, autem magni quae totam eos odit, numquam reprehenderit. Culpa fuga, reiciendis repudiandae cum minus tempora ducimus sit!'
                    onClick={() => redirect(ROUTE_PATH.NEWS_PROMOTIONS_DETAIL.LINK)}
                  /> */}
                </Section>
              </TabPanel>
            </Tabs>
          </ReactTabsControl>
        </Container>
      </TransitionContainer>
    )
  }
}
