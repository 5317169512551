import React from 'react'
import { LoginContainerConnected } from '../../containers/LoginContainer/LoginContainer'
import { isShowLoading, onOpenAlert } from '../../helpers/functions/main';
import { getLanguage } from '../../helpers/functions/language';
import { DATA } from '../../helpers/constants/data';
import axios from 'axios'
import config from '../../config'

export class LoginByPhonePage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }

  login = {
    handleLogin: (state, callbackSuccess = () => { }) => {
      if (state.username === '' && state.password === '') {
        onOpenAlert({
          type: DATA.ALERT_TYPE.ERROR,
          title: getLanguage('message.fail'),
          message: getLanguage('message.pleaseEnterPhoneAndPassword'),
        });
      }
      else if (state.username === '') {
        onOpenAlert({
          type: DATA.ALERT_TYPE.ERROR,
          title: getLanguage('message.fail'),
          message: getLanguage('message.pleaseEnterPhone'),
        });
      } else if (state.password === '') {
        onOpenAlert({
          type: DATA.ALERT_TYPE.ERROR,
          title: getLanguage('message.fail'),
          message: getLanguage('message.pleaseEnterPassword'),
        });
      } else {
        isShowLoading(true);
        axios.post(`${config.api.base}/api/login`, {
          phone: parseInt(state.username),
          password: state.password,
        }).then((response) => {
          if (response.data.validation) {
            callbackSuccess(response);
          } else {
            isShowLoading(false);
            onOpenAlert({
              type: DATA.ALERT_TYPE.ERROR,
              title: getLanguage('message.fail'),
              message: getLanguage('message.wrongUsernameOrPassword'),
            });
          }
        }, (error) => {
          console.log(error);
          isShowLoading(false);
          onOpenAlert({
            type: DATA.ALERT_TYPE.ERROR,
            title: getLanguage('message.fail'),
            message: (error || {}).message,
          });
        });
      }
    }
  }

  render() {
    return (
      <LoginContainerConnected
        handleLogin={this.login.handleLogin}
      />
    )
  }
}
