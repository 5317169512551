import React from 'react'
import {
  TransitionContainer,
  Container,
  Image,
  Section,
  Text,
  Field,
  NavigationBar,
} from './../../components'
import {
  VARIABLES,
  TYPOGRAPHYS,
  CONTENTS,
  ICONS,
} from './../../themes'
import {
  ROUTE_PATH,
  redirect,
} from './../../helpers'
import { getLanguage } from '../../helpers/functions/language'
import { getUserAuth } from '../../helpers/functions/auth'
import { getCountryType } from '../../helpers/functions/data'
import { ENUM } from '../../helpers/constants/enum'

export class FindDealerPage extends React.Component {

  constructor(props) {
    super(props);
    const userAuth = getUserAuth();
    this.state = {
      userAuth,
    }
  }

  componentDidMount() {
    localStorage.removeItem('dealerType')
  }

  onChangeType(event) {
    const type = event.target.value
    if (!type) return
    localStorage.setItem('dealerType', type)
    redirect(`${ROUTE_PATH.FIND_DEALER_TYPE.LINK}/${type}`)
  }

  handleBack() {
    redirect(ROUTE_PATH.HOME.LINK)
  }

  render() {
    const {
      userAuth,
    } = this.state;
    return (
      <TransitionContainer>
        <Container
          width={VARIABLES.WEBSITE_WIDTH}
          calcMinHeight='100vh'
          bgColor={VARIABLES.COLORS.LIGHT_BLUE_2}
        >
          <NavigationBar
            shadow
            iconWidth='16'
            iconHeight='19'
            iconSrc={ICONS['ic-navigation-findadealer.svg']}
            pageTitleIcon={getLanguage('findDealer.title')}
            actionLeft={
              <Image
                widthRatio='24'
                widthRatioUnit='px'
                heightRatio='23'
                heightRatioUnit='px'
                src={ICONS['ic-home-black.svg']}
                onClick={this.handleBack.bind(this)}
              />
            }
          />
          <Section
            paddingHorizontal='20'
            paddingTop='60'
            justify='center'
          >
            <Image
              widthRatio='217'
              widthRatioUnit='px'
              heightRatio='163'
              heightRatioUnit='px'
              src={CONTENTS['find-dealer.png']}
            />
            <Section
              paddingTop='34'
              paddingBottom='5'
              justify='center'
            >
              <Text
                fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_22}
                color={VARIABLES.COLORS.BLACK}
              >
                {getLanguage('findDealer.findDealer')}
              </Text>
            </Section>
            <Section
              paddingBottom='25'
              justify='center'
            >
              <Text
                fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_14}
                color={VARIABLES.COLORS.GRAY_3}
              >
                {getLanguage('findDealer.selectDealer.title')}
              </Text>
            </Section>
            <Section spacingBottom='25'>
              <Field
                type='select'
                fluid
                height='40'
                paddingRight='10'
                paddingLeft='50'
                bgColor={VARIABLES.COLORS.TRANSPARENT}
                borderWidth='1'
                borderStyle='solid'
                borderColor={VARIABLES.COLORS.BLUE_2}
                borderRadius='20'
                fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_14}
                fontColor={VARIABLES.COLORS.BLUE_2}
                id='dealer-select'
                name='dealer-select'
                fieldIcon={<img src={ICONS['ic-arrow-down-blue.svg']} alt='Icon' />}
                fieldIconWidth='41'
                fieldIconSpacingRight='10'
                fieldIconLeft={<img src={ICONS['ic-list-blue.svg']} alt='Icon' />}
                fieldIconLeftSpacingLeft='10'
                onChange={this.onChangeType.bind(this)}
              // value=""
              // defaultValue=""
              >
                <option value="" disabled selected hidden>{getLanguage('field.selectDealerPlaceholder')}</option>
                {
                  userAuth.countries.toLowerCase() === ENUM.COUNTRY.INDONESIA.toLowerCase() ?
                    <React.Fragment>
                      <option value="Hardware Shop Toko">{getLanguage('findDealer.selectDealer.tokoStore')}</option>
                      <option value="BAD">{getLanguage('findDealer.selectDealer.indoBad')}</option>
                      <option value="Distributor">{getLanguage('findDealer.selectDealer.distributor')}</option>
                    </React.Fragment>
                    :
                    <React.Fragment>
                      <option value="BAD">{getLanguage('findDealer.selectDealer.bad')}</option>
                      <option value="OR">{getLanguage('findDealer.selectDealer.or')}</option>
                    </React.Fragment>
                }
              </Field>
            </Section>
          </Section>
        </Container>
      </TransitionContainer>
    )
  }
}
