import moment from 'moment';
import 'moment/locale/th';
import React from 'react';
import { getIsLanguageTH } from '../../helpers/functions/data';
import { displayMaskPhoneNumber } from '../../helpers/functions/display';
import { getLanguage } from '../../helpers/functions/language';
import { isShowLoading } from '../../helpers/functions/main';
import { getLuckyDrawAnnouncementDetail } from '../../helpers/services/luckyDraw';
import { ModelLuckyDrawDetail } from '../../models/ModelLuckyDraw';
import {
  Container,
  Grid,
  Image,
  NavigationBar,
  Section,
  ShapeContainer,
  Text,
  TransitionContainer,
} from './../../components';
import { ROUTE_PATH, getRouteParams, redirectData } from './../../helpers';
import { ICONS, TYPOGRAPHYS, VARIABLES } from './../../themes';

export class AwardAnnouncementPage extends React.Component {
  constructor(props) {
    super(props);
    const dataId = parseInt(getRouteParams(props, 'id'));

    this.state = {
      dataId,
      data: new ModelLuckyDrawDetail(),
      isShowMore: false,
      dataRewardList: []
    };
  }

  handleBack = () => {
    // const {
    //   history
    // } = this.props;
    // history.goBack()
    redirectData(`${ROUTE_PATH.WIN_PRIZES.LINK}`, {
      data: {
        isFromAnnouncement: true,
      },
    });
  };

  componentDidMount() {
    setTimeout(() => {
      this.awardAnnouncement.getData();
    }, 1);
  }

  awardAnnouncement = {
    getData: async () => {
      const { dataId } = this.state;
      isShowLoading(true);
      const params = {
        id: dataId,
      };
      const data = await getLuckyDrawAnnouncementDetail(params);
      this.setState({
        data,
        dataRewardList: data.rewardList,
      });
      isShowLoading(false);
    },
    toggleClickShowDetail: (item) => {
      const { dataRewardList } = this.state;
      this.setState({
        dataRewardList: dataRewardList.map((e) => {
          if (e.id === item.id) {
            e.isShowAnnouncementRewardDetailMore = !e.isShowAnnouncementRewardDetailMore
          }
          return e;
        })
      })
    }
  };

  render() {
    const { data, isShowMore, dataRewardList } = this.state;
    const isLanguageTH = getIsLanguageTH();
    return (
      <TransitionContainer>
        <Container
          width={VARIABLES.WEBSITE_WIDTH}
          calcMinHeight='100vh'
          bgColor={VARIABLES.COLORS.WHITE}
        >
          <NavigationBar
            shadow
            iconWidth='18'
            iconHeight='18'
            iconSrc={ICONS['ic-lucky-draw-black.svg']}
            pageTitleIcon={getLanguage('luckyDraw.title')}
            actionLeft={
              <Image
                widthRatio='11'
                widthRatioUnit='px'
                heightRatio='21'
                heightRatioUnit='px'
                src={ICONS['ic-left-black.svg']}
                onClick={this.handleBack}
              />
            }
          />
          <Section scroll calcHeight='100vh - 70px' paddingBottom='25'>
            <Section
              justify='center'
              fluid
              bgColor={VARIABLES.COLORS.PRIMARY_2}
              className='is-bg-logo'
              padding='15'
            >
              <Section justify='center' spacingBottom='10'>
                <Text
                  fontStyle={TYPOGRAPHYS.FIRST.BOLD.FS_14}
                  color={VARIABLES.COLORS.WHITE}
                >
                  {getLanguage('luckyDraw.announcementOfNameWinners')}
                </Text>
              </Section>
              <Section overflowHidden justify='center' spacingBottom='10'>
                <Section justify='center' spacingBottom='5'>
                  <Text
                    fontStyle={TYPOGRAPHYS.FIRST.BOLD.FS_22}
                    color={VARIABLES.COLORS.WHITE}
                    align='center'
                  >
                    {getLanguage('luckyDraw.label.campaign')}
                  </Text>
                </Section>
                <Section paddingHorizontal='8'>
                  <Grid gutter='2' justify='center'>
                    <Grid.Column>
                      <Text
                        fontStyle={TYPOGRAPHYS.FIRST.BOLD.FS_22}
                        color={VARIABLES.COLORS.WHITE}
                        align='center'
                      >
                        "
                      </Text>
                    </Grid.Column>
                    <Grid.Column>
                      <Section overflowHidden maxWidth='282'>
                        <Text
                          fontStyle={TYPOGRAPHYS.FIRST.BOLD.FS_22}
                          color={VARIABLES.COLORS.WHITE}
                          align='center'
                          breakAll={isShowMore}
                          noWrap={!isShowMore}
                        >
                          {data.campaignName} {isShowMore ? '"' : ''}
                        </Text>
                      </Section>
                    </Grid.Column>
                    {!isShowMore && (
                      <Grid.Column>
                        <Text
                          fontStyle={TYPOGRAPHYS.FIRST.BOLD.FS_22}
                          color={VARIABLES.COLORS.WHITE}
                          align='center'
                        >
                          "
                        </Text>
                      </Grid.Column>
                    )}
                  </Grid>
                </Section>
              </Section>
              {data.campaignName.length >= 22 && (
                <Section spacingBottom={10} justify='center'>
                  <Text
                    fontStyle={TYPOGRAPHYS.FIRST.MEDIUM.FS_12}
                    color={VARIABLES.COLORS.WHITE}
                    underlineWhite
                    onClick={() => {
                      this.setState({
                        isShowMore: !isShowMore,
                      });
                    }}
                  >
                    {isShowMore
                      ? getLanguage('luckyDraw.showLess')
                      : getLanguage('luckyDraw.showMore')}
                  </Text>
                </Section>
              )}
              <Section justify='center'>
                <Text
                  fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_12}
                  color={VARIABLES.COLORS.WHITE}
                >
                  {getLanguage('luckyDraw.campaignDurationDate')
                    .replace(
                      '[startDate]',
                      data.startDate
                        ? moment(data.startDate)
                          .locale(isLanguageTH ? 'th' : 'en')
                          .format('D MMM YYYY')
                        : '-'
                    )
                    .replace(
                      '[endDate]',
                      data.endDate
                        ? moment(data.endDate)
                          .locale(isLanguageTH ? 'th' : 'en')
                          .format('D MMM YYYY')
                        : '-'
                    )}
                </Text>
              </Section>
            </Section>
            <Section
              padding='20'
              justify='center'
              bgColor={VARIABLES.COLORS.LIGHT_BLUE_2}
            >
              {dataRewardList.map((e, i) => {
                return (
                  <Section spacingBottom='10' key={i}>
                    <ShapeContainer
                      padding='10'
                      className='is-gold-card-header'
                      fluid
                      justify='center'
                    >
                      <Section
                        paddingHorizontal='20'
                        justify='center'
                        spacingBottom='2'
                      >
                        <Grid gutter='5' justify='center'>
                          <Grid.Column>
                            <Image
                              fill
                              widthRatio="24"
                              widthRatioUnit="px"
                              heightRatio="24"
                              heightRatioUnit="px"
                              src={e.rewardImagePath}
                            />
                          </Grid.Column>
                          <Grid.Column>
                            <Section maxWidth='240'>
                              <Text
                                ellipsis
                                align='center'
                                verticalAlign='center'
                                fontStyle={TYPOGRAPHYS.FIRST.BOLD.FS_20}
                                title={e.rewardName}
                              >
                                {e.rewardName}
                              </Text>
                            </Section>
                          </Grid.Column>
                        </Grid>
                      </Section>
                      <Section justify='center' spacingBottom='10' overflowHidden={!e.isShowAnnouncementRewardDetailMore}>
                        <Text
                          ellipsisTwoLine={!e.isShowAnnouncementRewardDetailMore}
                          fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_14}
                          breakAll
                        >
                          {e.rewardDetail}
                        </Text>
                      </Section>
                      {
                        e.rewardDetail.length >= 110 && (
                          <Section justify='center' spacingBottom='10'>
                            <Text
                              fontStyle={TYPOGRAPHYS.FIRST.MEDIUM.FS_12}
                              underline
                              onClick={(ev) => {
                                ev.stopPropagation();
                                this.awardAnnouncement.toggleClickShowDetail(e)
                              }
                              }
                            >
                              {e.isShowAnnouncementRewardDetailMore
                                ? getLanguage('luckyDraw.showLess')
                                : getLanguage('luckyDraw.showMore')}
                            </Text>
                          </Section>
                        )
                      }
                    </ShapeContainer>
                    {e.winningList.map((ew, iw) => {
                      return (
                        <ShapeContainer
                          key={iw}
                          padding='16'
                          fluid
                          overflowHidden
                          bg={
                            'linear-gradient(180deg, rgba(229, 207, 102, 0.83) 0%, #FFF2B3 100%)'
                          }
                          className={
                            iw === e.winningList.length - 1
                              ? 'is-gold-card-body'
                              : ''
                          }
                        >
                          <Section relative justify='center'>
                            <Section spacingBottom='16'>
                              <Grid gutter='10'>
                                <Grid.Column>
                                  <Image
                                    widthRatio='20'
                                    widthRatioUnit='px'
                                    heightRatio='20'
                                    heightRatioUnit='px'
                                    src={ICONS['ic-person.svg']}
                                  />
                                </Grid.Column>
                                <Grid.Column>
                                  <Text
                                    fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_20}
                                  >
                                    {ew.customerName}
                                  </Text>
                                </Grid.Column>
                              </Grid>
                            </Section>
                            <Section spacingBottom='16'>
                              <Grid gutter='10'>
                                <Grid.Column>
                                  <Image
                                    widthRatio='20'
                                    widthRatioUnit='px'
                                    heightRatio='20'
                                    heightRatioUnit='px'
                                    src={ICONS['ic-map-pin.svg']}
                                  />
                                </Grid.Column>
                                <Grid.Column>
                                  <Text
                                    fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_16}
                                  >
                                    {ew.province}
                                  </Text>
                                </Grid.Column>
                              </Grid>
                            </Section>
                            <Section spacingBottom='16'>
                              <Grid gutter='10'>
                                <Grid.Column>
                                  <Section spacingLeft='6'>
                                    <Image
                                      widthRatio='9'
                                      widthRatioUnit='px'
                                      heightRatio='14'
                                      heightRatioUnit='px'
                                      src={ICONS['ic-mobile-gold.svg']}
                                    />
                                  </Section>
                                </Grid.Column>
                                <Grid.Column>
                                  <Text
                                    fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_14}
                                  >
                                    {displayMaskPhoneNumber(ew.displayPhone)}
                                  </Text>
                                </Grid.Column>
                              </Grid>
                            </Section>
                            {/* <Section>
                              <Grid gutter='10'>
                                <Grid.Column>
                                  <Section spacingLeft='6'>
                                    <Image
                                      widthRatio='19'
                                      widthRatioUnit='px'
                                      heightRatio='19'
                                      heightRatioUnit='px'
                                      src={
                                        ICONS['ic-information-circle-gold.svg']
                                      }
                                    />
                                  </Section>
                                </Grid.Column>
                                <Grid.Column>
                                  <Text
                                    fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_14}
                                  >
                                    {`${getLanguage('luckyDraw.awardCount')} (${ew.winningQuantity})`}
                                  </Text>
                                </Grid.Column>
                              </Grid>
                            </Section> */}
                            <Section
                              className='is-gold-card'
                              justify='flex-end'
                              absolute
                            >
                              <Text
                                fontStyle={TYPOGRAPHYS.FIRST.BOLD.FS_48}
                                color={'#2A2B2A'}
                                top='-28'
                                left='-16'
                              >
                                {iw + 1}
                              </Text>
                            </Section>
                          </Section>
                        </ShapeContainer>
                      );
                    })}
                  </Section>
                );
              })}
            </Section>
          </Section>
        </Container>
      </TransitionContainer>
    );
  }
}
