import React from 'react'
import * as _ from 'lodash'
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemPanel,
  AccordionItemButton,
} from 'react-accessible-accordion'
import {
  Tab,
  Tabs,
  TabList,
  TabPanel
} from 'react-tabs'
import {
  TransitionContainer,
  Container,
  Image,
  SwiperCarousel,
  Section,
  Text,
  ReactTabsControl,
  Button,
  AccordionControl,
  ShapeContainer,
  Grid
} from '../../components'
import {
  VARIABLES,
  ICONS,
  TYPOGRAPHYS,
  CONTENTS,
} from '../../themes'
import {
  redirect,
  ROUTE_PATH
} from '../../helpers';
import config from '../../config'
import { getLanguage } from '../../helpers/functions/language'
import { onOpenAlert } from '../../helpers/functions/main'
import { DATA } from '../../helpers/constants/data'


export class KnowledgeBaseDetailPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      catId: (this.props.match.params && this.props.match.params.catid) ? +this.props.match.params.catid : '',
      knowledgeId: (this.props.match.params && this.props.match.params.knowledgeid) ? +this.props.match.params.knowledgeid : '',
      data: {
        gallery: [],
        topic_name: '',
        document: [],
        sub_topic: []
      }
    }
  }

  componentWillMount() {
    const data = JSON.parse(localStorage.getItem('knowledgeBase'))
    const { catId, knowledgeId } = this.state;
    if (_.isEmpty(data)) return
    const category = data.find(item => item.id === catId)
    if (category && category.knowledge) {
      const knowledge = category.knowledge.find(c => c.id === knowledgeId);
      this.setState({ data: knowledge })
    }
  }

  onDownloadClick(url) {
    window.open(`${config.imgPrefix}/${url}`, "_blank")
  }

  onShareLink() {
    const {
      catId,
      knowledgeId,
    } = this.state;
    const shareUrl = `${window.location.origin}${ROUTE_PATH.PUBLIC_KNOWLEDGE_BASE.LINK}/${catId}/${knowledgeId}`;
    console.log('shareUrl', shareUrl)
    if (navigator.share) {
      navigator.share({
        // url: window.location.href
        url: shareUrl
      })
    }
    else {
      onOpenAlert({
        type: DATA.ALERT_TYPE.ERROR,
        title: getLanguage('message.fail'),
        message: getLanguage('message.browserCantShare'),
      });
    }
  }

  render() {
    let { data } = this.state;
    return (
      <TransitionContainer>
        <Container
          width={VARIABLES.WEBSITE_WIDTH}
          calcMinHeight='100vh'
          bgColor={VARIABLES.COLORS.WHITE}
        >
          <Section
            relative
            spacingBottom='25'
          >
            <Button
              className='is-icon is-content-detail-back'
              width={36}
              height={36}
              borderRadius={18}
              bgColor={VARIABLES.COLORS.WHITE}
              onClick={this.props.history.goBack}
            >
              <Button.Icon>
                <Image
                  widthRatio='11'
                  widthRatioUnit='px'
                  heightRatio='21'
                  heightRatioUnit='px'
                  left='-1'
                  src={ICONS['ic-left-black.svg']}
                />
              </Button.Icon>
            </Button>
            {/* <Section
              nonFluid
              className='is-social-buttons'
            >
              <Button
                width={38}
                height={38}
                borderRadius={19}
                bgColor={VARIABLES.COLORS.BLUE_2}
                onClick={this.onShareLink.bind(this)}
              >
                <Button.Icon>
                  <Image
                    widthRatio='17'
                    widthRatioUnit='px'
                    heightRatio='19'
                    heightRatioUnit='px'
                    src={ICONS['ic-share.svg']}
                  />
                </Button.Icon>
              </Button>
            </Section> */}
            <SwiperCarousel className='is-banner-subcategory-detail'>
              {
                (data.gallery || []).map((url, i) => (
                  <SwiperCarousel.Item
                    key={i}
                    onClick={() => { }}>
                    <Image
                      heightRatio='93'
                      src={`${config.imgPrefix}/${url}`}
                    />
                  </SwiperCarousel.Item>
                ))
              }
            </SwiperCarousel>
          </Section>
          <ReactTabsControl>
            <Tabs>
              <TabList>
                <Tab>{getLanguage('knowledgeBaseDetail.tab.description.title')}</Tab>
                <Tab>{getLanguage('knowledgeBaseDetail.tab.documentsDownload.title')}</Tab>
              </TabList>
              <TabPanel>
                <Section paddingVertical='18'>
                  <Section
                    paddingHorizontal='20'
                    spacingBottom='18'
                  >
                    <Section spacingBottom='15'>
                      <Text
                        fontStyle={TYPOGRAPHYS.FIRST.BOLD.FS_22}
                        color={VARIABLES.COLORS.BLACK}
                      >
                        {data.topic_name}
                      </Text>
                    </Section>
                    {/* {[1, 2].map((data, index) => (<Section
                      key={index}
                      spacingBottom='10'
                      paddingBottom='10'
                      borderBottomWidth='1'
                      borderBottomStyle='solid'
                      borderBottomColor={VARIABLES.COLORS.GRAY_5}
                    >
                      <Grid gutter='9' align='center'>
                        <Grid.Column>
                          <Image
                            widthRatio='21'
                            widthRatioUnit='px'
                            heightRatio='15'
                            heightRatioUnit='px'
                            src={ICONS['ic-youtube.svg']}
                          />
                        </Grid.Column>
                        <Grid.Column>
                          <Text
                            fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_12}
                            color={VARIABLES.COLORS.BLACK}
                          >
                            YouTube :&nbsp;
                          </Text>
                          <Text
                            fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_12}
                            color={VARIABLES.COLORS.BLUE_2}
                          >
                            https://www.youtube.com/?feature=ytca
                          </Text>
                        </Grid.Column>
                      </Grid>
                    </Section>))} */}
                  </Section>
                  <AccordionControl description>
                    <Accordion allowMultipleExpanded allowZeroExpanded>
                      {(data.sub_topic || []).map((data, index) => (
                        <AccordionItem key={index} style={{ marginBottom: '5px' }}>
                          <AccordionItemHeading><AccordionItemButton>{data.sub_topic_name}</AccordionItemButton></AccordionItemHeading>
                          <AccordionItemPanel>
                            <div className='text-editor-content'>
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: data.description
                                }}
                              />
                            </div>
                          </AccordionItemPanel>
                        </AccordionItem>
                      ))}
                    </Accordion>
                  </AccordionControl>
                </Section>
              </TabPanel>
              <TabPanel>
                <Section
                  paddingVertical='25'
                  paddingHorizontal='20'
                >
                  {(data.document || []).map((data, index) => (
                    <ShapeContainer
                      fluid
                      key={index}
                      padding='20'
                      spacingBottom='15'
                      borderRadius='6'
                      boxShadow={VARIABLES.COLORS.SHADOW_2}
                    >
                      <Section
                        spacingBottom='7'
                        onClick={() => {
                          if (data.file) {
                            // redirect(ROUTE_PATH.PRODUCT_DETAIL_BROCHURE.LINK)
                            this.onDownloadClick(data.file);
                          }
                        }}
                      >
                        <Text
                          fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_14}
                          color={VARIABLES.COLORS.BLACK}
                        >
                          {data.name}
                        </Text>
                      </Section>
                      {data.file &&
                        <Section onClick={this.onDownloadClick.bind(this, data.file)}>
                          <Grid gutter='7'>
                            <Grid.Column>
                              <Image
                                widthRatio='24'
                                widthRatioUnit='px'
                                heightRatio='20'
                                heightRatioUnit='px'
                                src={ICONS['ic-feather-download-cloud.svg']}
                              />
                            </Grid.Column>
                            <Grid.Column>
                              <Text
                                fontStyle={TYPOGRAPHYS.FIRST.BOLD.FS_10}
                                color={VARIABLES.COLORS.BLUE_2}
                              >
                                DOWNLOAD
                              </Text>
                            </Grid.Column>
                          </Grid>
                        </Section>
                      }
                    </ShapeContainer>
                  ))}
                </Section>
              </TabPanel>
            </Tabs>
          </ReactTabsControl>
        </Container>
      </TransitionContainer>
    )
  }
}