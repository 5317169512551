import React from 'react'
import ClassNames from 'classnames'
import PropTypes from 'prop-types'
import {
  ShapeContainerBaseWrapper
} from './styled'
// import {
//   default as VARIABLES
// } from './../../../themes/styles/bases/variables'
// import {
//   default as TYPOGRAPHYS
// } from './../../../themes/styles/bases/typographys'

/**
 * ShapeContainerBase description:
 * - base component ShapeContainer
 */

export class ShapeContainerBase extends React.PureComponent {
  render () {
    const {
      // Option
      children,
      className,
      // Default
      direction,
      wrap,
      justify,
      align,
      width,
      widthUnit,
      minWidth,
      height,
      calcHeight,
      calcMinHeight,
      heightUnit,
      minHeight,
      padding,
      paddingTop,
      paddingRight,
      paddingBottom,
      paddingLeft,
      paddingHorizontal,
      paddingVertical,
      spacing,
      spacingTop,
      spacingRight,
      spacingBottom,
      spacingLeft,
      spacingHorizontal,
      spacingVertical,
      bgColor,
      bgHoverColor,
      borderWidth,
      borderStyle,
      borderColor,
      borderHoverColor,
      borderTopWidth,
      borderTopStyle,
      borderTopColor,
      borderTopHoverColor,
      borderRightWidth,
      borderRightStyle,
      borderRightColor,
      borderRightHoverColor,
      borderBottomWidth,
      borderBottomStyle,
      borderBottomColor,
      borderBottomHoverColor,
      borderLeftWidth,
      borderLeftStyle,
      borderLeftColor,
      borderLeftHoverColor,
      borderRadius,
      boxShadow,
      fluid,
      bg,
      overflowHidden,
      onClick
    } = this.props

    return (
      <ShapeContainerBaseWrapper
        className={ClassNames(
          'shape-container-base',
          {'is-fluid': fluid},
          {'is-overflow-hidden': overflowHidden},
          {'is-cursor-pointer': onClick},
          // Option
          className
        )}
        theme={{
          // Default
          direction,
          wrap,
          justify,
          align,
          width,
          widthUnit,
          minWidth,
          height,
          calcHeight,
          calcMinHeight,
          heightUnit,
          minHeight,
          padding,
          paddingTop,
          paddingRight,
          paddingBottom,
          paddingLeft,
          paddingHorizontal,
          paddingVertical,
          spacing,
          spacingTop,
          spacingRight,
          spacingBottom,
          spacingLeft,
          spacingHorizontal,
          spacingVertical,
          bgColor,
          bgHoverColor,
          borderWidth,
          borderStyle,
          borderColor,
          borderHoverColor,
          borderTopWidth,
          borderTopStyle,
          borderTopColor,
          borderTopHoverColor,
          borderRightWidth,
          borderRightStyle,
          borderRightColor,
          borderRightHoverColor,
          borderBottomWidth,
          borderBottomStyle,
          borderBottomColor,
          borderBottomHoverColor,
          borderLeftWidth,
          borderLeftStyle,
          borderLeftColor,
          borderLeftHoverColor,
          borderRadius,
          boxShadow,
          bg
        }}
        onClick={onClick}
      >
        <div className='shape-container-content-base'>
          {children}
        </div>
      </ShapeContainerBaseWrapper>
    )
  }

  static defaultProps = {
    // Option
    // children: undefined,
    // className: undefined,
    // Default
    direction: 'column',
    wrap: 'wrap',
    align: 'flex-start',
    widthUnit: 'px',
    heightUnit: 'px'
  }

  static propTypes = { // TYPE > node, string, number, bool, array, object, symbol, func
    /**
    * Additional classes
    */
    className: PropTypes.string,

    /**
    * Additional Text, HTML, Component
    */
    children: PropTypes.oneOfType([
      PropTypes.node,
      PropTypes.string
    ])
  }
}
