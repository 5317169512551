import React from 'react'
import _ from 'lodash'
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemPanel,
  AccordionItemButton,
} from 'react-accessible-accordion'
import {
  AccordionControl,
  Section,
  Button,
  Text,
  Image,
  Alert,
} from '../../components'
import {
  VARIABLES,
  TYPOGRAPHYS,
  ICONS,
  CONTENTS,
} from '../../themes'
import {
  CategoryItem
} from './CategoryItem'
import { downloadCaptureHtml, getBlobtoPath, captureHtml2Blob } from '../../helpers/functions/addon'
import { Loader } from '../Loader';
import { getLanguage } from '../../helpers/functions/language';
import { estimator } from '../../helpers/functions/estimator';
import {
  redirectData,
} from '../../helpers/functions/routes';
import {
  ROUTE_PATH,
} from '../../helpers/constants';
import { DATA } from '../../helpers/constants/data';
import { onOpenAlert } from '../../helpers/functions/main';
import {
  isIOS
} from 'react-device-detect'

export class OutputSummary extends React.Component {
  constructor(props) {
    super(props)
    const imageList = ['estimator-1.png', 'estimator-2.png', 'estimator-3.png'];
    const categoryGroupList = [{
      id: 1,
      categoryList: [],
    }, {
      id: 2,
      categoryList: [],
    }, {
      id: 3,
      categoryList: [],
    }];
    const preExpanded = _.map(categoryGroupList, 'id')
    this.state = {
      showModal: false,
      titleModal: '',
      descriptionModal: '',
      typeModal: '',
      categoryGroupList,
      preExpanded,
      imageList,
      dataInput: props.input,
    }
  }

  static defaultProps = {
    legend: true,
    action: true
  }

  componentDidMount() {
    const {
      isCaptureImage,
    } = this.props;
    this.outputSummary.setCategoryGroupList();
    if (isCaptureImage) {
      this.outputSummary.handleCaptureImage();
    }
  }

  outputSummary = {
    setCategoryGroupList: () => {
      const {
        dataInput,
      } = this.state;
      const data = estimator(
        dataInput.roofType,
        dataInput.profileType,
        dataInput.width,
        dataInput.length,
        dataInput.height,
        dataInput.overhang
      );
      console.log('data', dataInput, data);
      if (typeof data === typeof {}) {
        const categoryGroupList = [
          {
            id: 1,
            header: getLanguage('estimatorSummary.category.title_1'),
            categoryList: [{
              type: 'Roof slope area',
              profile: data.profile.roof_slope_area,
              qty: data.qty.roof_slope_area,
              unit: 'm2',
            },
              // {
              //   type: 'Number of roof profile',
              //   profile: '-',
              //   qty: 2,
              //   unit: 'pcs',
              // }
            ],
            note: getLanguage('estimatorSummary.category.note_1')
          },
          {
            id: 2,
            header: getLanguage('estimatorSummary.category.title_2'),
            categoryList: [{
              type: 'Chord',
              profile: data.profile.chord,
              qty: data.qty.chord,
              unit: 'pcs',
            }, {
              type: 'Web',
              profile: data.profile.web,
              qty: data.qty.web,
              unit: 'pcs',
            }, {
              type: 'Bottom bracing',
              profile: data.profile.bottom_bracing,
              qty: data.qty.bottom_bracing,
              unit: 'pcs',
            }, {
              type: 'Web bracing',
              profile: data.profile.web_bracing,
              qty: data.qty.web_bracing,
              unit: 'pcs',
            }, {
              type: 'Top bracing',
              profile: data.profile.top_bracing,
              qty: data.qty.top_bracing,
              unit: 'pcs',
            }, {
              type: 'Purling',
              profile: data.profile.purling,
              qty: data.qty.purling,
              unit: 'pcs',
            }, {
              type: 'Eave purlin',
              profile: data.profile.eave_purlin,
              qty: data.qty.eave_purlin,
              unit: 'pcs',
            }]
          },
          {
            id: 3,
            header: getLanguage('estimatorSummary.category.title_3'),
            categoryList: [{
              type: 'Screw for structure',
              profile: data.profile.screw,
              qty: data.qty.screw,
              unit: 'pcs',
            }, {
              type: 'Plate BM3',
              profile: data.profile.plate_bm3,
              qty: data.qty.plate_bm3,
              unit: 'pcs',
            }, {
              type: 'Plate BM1',
              profile: data.profile.plate_bm1,
              qty: data.qty.plate_bm1,
              unit: 'pcs',
            }, {
              type: 'Exp-bolt',
              profile: data.profile.exp_bolt,
              qty: data.qty.exp_bolt,
              unit: 'pcs',
            }]
          }
        ];
        this.setState({
          categoryGroupList,
        });
      }
      else {
        let message = '';
        if (data === 'error_width') {
          message = getLanguage('message.widthOutOfCondition');
        }
        else if (data === 'error_pitch') {
          message = getLanguage('message.pitchOutOfCondition');
        }
        else {
          message = getLanguage('message.dataNotFound');
        }
        onOpenAlert({
          type: DATA.ALERT_TYPE.ERROR,
          title: '',
          message,
          onClickClose: () => {
            redirectData(ROUTE_PATH.ESTIMATOR.LINK, {
              data: dataInput
            });
          }
        });
      }
    },
    handleCaptureImage: async () => {
      this.setState({
        isShowLoading: true,
      }, async () => {
        const dom = document.getElementById('captureContainer').firstChild;
        const blob = await captureHtml2Blob(dom);
        const file = new File([blob], 'estimator.png', {
          type: blob.type
        });
        console.log('handleCaptureImage', file);
        this.setState({
          shareFile: file,
          isShowLoading: false,
        });
      });
    },
  }

  onSavePhoto() {
    const userAgent = window.navigator.userAgent.toLowerCase();

    //andriod;
    let isWebViewAndriod = userAgent.includes('wv');
    //ios
    let isWebViewIOS = /iphone|ipod|ipad/.test(userAgent) &&
      /safari line/.test(userAgent);
    // const standalone = window.navigator.standalone,
    //   safari = /safari/.test(userAgent),
    //   ios = /iphone|ipod|ipad/.test(userAgent);

    // if (ios) {
    //   if (!standalone && safari) {
    //     //browser
    //   } else if (standalone && !safari) {
    //     //standalone
    //   } else if (!standalone && !safari) {
    //     //uiwebview
    //     isWebViewIOS = true;
    //   };
    // } else {
    //   //not iOS
    // };

    // if (isWebViewAndriod || isWebViewIOS) {
    //   this.setState({
    //     showModal: true,
    //     typeModal: 'error',
    //     titleModal: '',
    //     descriptionModal: getLanguage('message.browserNotSupport')
    //   })
    // }
    // else {
    this.setState({
      isShowLoading: true,
    }, async () => {
      const dom = document.getElementById('captureContainer').firstChild;
      // await downloadCaptureHtml(dom);
      const blob = await captureHtml2Blob(dom);

      this.setState({
        isShowLoading: false,
        showModal: true,
        typeModal: 'success',
        titleModal: 'Successful',
        // descriptionModal: 'Save photo success, Please check your gallery.'
        descriptionModal: 'Save photo success.'
      }, () => {
        // TODO: change
        window.open(URL.createObjectURL(blob), '_blank')
      })
    });
    // }
  }

  async onSharePhoto() {
    const {
      shareFile,
    } = this.state;
    if (navigator.share) {
      //iOS sharing works, but only text shared. File skipped
      let shareUrlPath = await getBlobtoPath(shareFile);
      console.log('shareFile', shareFile);
      console.log('shareUrlPath', shareUrlPath);
      const filesArray = [shareFile];
      try {
        if (navigator.canShare && navigator.canShare({ files: filesArray })) {
          navigator.share({
            title: '',
            files: filesArray
          }).then(() => {
            console.log('shareFile', shareFile, shareUrlPath)
          }).catch(err => {
            console.log('shareFile err', { err });
          });
        }
        else {
          navigator.share({
            title: '',
            url: shareUrlPath,
          }).then(() => {
            console.log('shareUrlPath', shareFile, shareUrlPath)
          }).catch(err => {
            console.log('shareUrlPath err', { err });
          });
        }
      } catch (err) {
        console.log('navigator.share error', { err });
        navigator.share({
          title: '',
          url: shareUrlPath,
        }).then(() => {
          console.log('shareUrlPath', shareFile, shareUrlPath)
        }).catch(err => {
          console.log('shareUrlPath err', { err });
        });
      }
    }
    else {
      this.setState({
        showModal: true,
        typeModal: 'error',
        titleModal: '',
        descriptionModal: getLanguage('message.browserCantShare')
      })
    }
  }

  onCloseModal() {
    this.setState({
      showModal: false
    });
  }

  render() {
    const {
      legend,
      action
    } = this.props

    const {
      showModal,
      typeModal,
      titleModal,
      descriptionModal,
      categoryGroupList,
      preExpanded,
      shareUrlPath,
      isShowLoading,
      imageList,
    } = this.state;
    return (
      <Section spacingBottom='40'>
        <AccordionControl className='is-estimator'>
          <Accordion allowMultipleExpanded allowZeroExpanded preExpanded={preExpanded}>
            {
              categoryGroupList.map((data, index) => {
                return (
                  <AccordionItem
                    key={index}
                    uuid={data.id}
                    style={{ marginBottom: '14px' }}
                  >
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        {data.header}
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <CategoryItem
                        data={data}
                        categoryList={data.categoryList}
                      />
                    </AccordionItemPanel>
                  </AccordionItem>
                )
              })
            }
          </Accordion>
        </AccordionControl>
        {legend &&
          <Section
            paddingHorizontal='20'
            justify='center'
          >
            {imageList.map((image, index) => (
              <Section
                key={index}
                paddingVertical='30'
                borderBottomWidth='1'
                borderBottomStyle='solid'
                borderBottomColor={VARIABLES.COLORS.GRAY_4}
                className='is-estimator-image-item'
                width={VARIABLES.WEBSITE_WIDTH}
                flex
              >
                <Image
                  className='is-full-width'
                  src={CONTENTS[image]}
                />
              </Section>
            ))}
          </Section>
        }
        {action &&
          <React.Fragment>
            <Section
              spacingTop='58'
              spacingBottom='17'
              justify='center'
            >
              <Button
                shadow
                className='is-gradient-blue-image is-icon-front'
                width='275'
                height={VARIABLES.BUTTON.HEIGHTS.H_40}
                borderRadius={VARIABLES.BUTTON.BORDER_RADIUSES.BR_10}
                onClick={this.onSavePhoto.bind(this)}
              >
                <Button.Icon paddingRight='10'>
                  <Image
                    widthRatio='24'
                    widthRatioUnit='px'
                    heightRatio='20'
                    heightRatioUnit='px'
                    src={ICONS['ic-download-cloud.svg']}
                  />
                </Button.Icon>
                <Text
                  fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_16}
                  color={VARIABLES.COLORS.WHITE}
                >
                  {getLanguage('field.buttonSaveAsPhoto')}
                </Text>
              </Button>
            </Section>
            {/* <Section
              spacingBottom='21'
              justify='center'
            >
              <Button
                className='is-icon-front'
                width='275'
                height={VARIABLES.BUTTON.HEIGHTS.H_40}
                bgColor={VARIABLES.COLORS.TRANSPARENT}
                borderRadius={VARIABLES.BUTTON.BORDER_RADIUSES.BR_10}
                borderWidth='1'
                borderStyle='solid'
                borderColor={VARIABLES.COLORS.BLUE_2}
                onClick={this.onSharePhoto.bind(this)}
              >
                <Button.Icon paddingRight='10'>
                  <Image
                    widthRatio='16'
                    widthRatioUnit='px'
                    heightRatio='19'
                    heightRatioUnit='px'
                    src={ICONS['ic-share-blue.svg']}
                  />
                </Button.Icon>
                <Text
                  fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_16}
                  color={VARIABLES.COLORS.BLUE_2}
                >
                  {getLanguage('field.buttonSharePhoto')}
                </Text>
              </Button>
            </Section> */}
          </React.Fragment>
        }
        <Alert type={typeModal} title={titleModal} description={descriptionModal} onClose={this.onCloseModal.bind(this)} open={showModal} />
        <Loader loading={isShowLoading} isOverlay />
      </Section >
    )
  }
}