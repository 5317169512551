
import _ from 'lodash';
import {
  firebase,
} from '../../config/firebase';

let db = firebase.firestore();

export const firebaseRemoveField = () => {
  return firebase.firestore.FieldValue.delete();
}

export const firebaseIncreaseField = (number) => {
  return firebase.firestore.FieldValue.increment(number)
}

export const firebasePathToRef = async (target, doc) => {
  try {
    if (doc) {
      return await db.collection(target).doc(doc)
    }
    return await db.collection(target)
  }
  catch (err) {
    return {};
  }
}

export const firebaseGetSnapDataDocRef = async (object, target = 'path', isGetRef) => {
  try {
    const snap = await object[target].get()
    object.snapData = snap.data()
    object.snapData.firebaseKey = snap.id;
    object.snapData.firebasePath = snap.ref.parent.path;
    if (isGetRef) {
      object.snapData.firebaseRef = snap.ref;
    }
    return object
  }
  catch (err) {
    return {
      snapData: {}
    }
  }
}

export const firebaseGetSearchSnapData = async (object) => {
  try {
    const cObject = { ...object };
    const doc = cObject.path._path.segments.pop();
    const collection = cObject.path._path.segments.join('/');
    const dataRef = await firebasePathToRef(collection, doc);
    object.path = dataRef;
    return firebaseGetSnapDataDocRef(object);
  }
  catch (err) {
    console.log('err', { err });
    object.snapData = {}
  }
}

export const firebaseGetCurrentDocRef = async (path) => {
  const snap = await path.get()
  return snap.data()
}

export const handleQueryFirebase = async (target, params = {}) => {
  let dbRef = db.collection(target)
  if (params.whereList) {
    params.whereList.forEach(e => {
      dbRef = dbRef.where(e[0], e[1], e[2]);
    })
  }
  else if (params.where) {
    dbRef = dbRef.where(params.where[0], params.where[1], params.where[2]);
  }
  else if (params.orderBy) {
    const orderType = params.orderType ? params.orderType : 'desc'
    dbRef = dbRef.orderBy(params.orderBy, orderType)
  }
  if (params.lastRef && params.lastDocRef) {
    // const lastDocSnap = await params.lastRef.get();
    const lastDocRef = await firebasePathToRef(params.lastRef, params.lastDocRef);
    const lastDocSnap = await lastDocRef.get();
    dbRef = dbRef.startAfter(lastDocSnap);
  }
  if (params.maxLength) {
    dbRef = dbRef.limit(params.maxLength)
  }
  if (params.callType) {
    return await dbRef[params.callType](params.callbackSuccess)
  }
  else {
    return await dbRef.get()
  }
}

export const handleQueryDocFirebase = async (target, doc, params = {}) => {
  try {
    let dbRef = db.collection(target).doc(doc)
    if (params.whereList) {
      params.whereList.forEach(e => {
        dbRef = dbRef.where(e[0], e[1], e[2]);
      })
    }
    else if (params.where) {
      dbRef = dbRef.where(params.where[0], params.where[1], params.where[2])
    }
    else if (params.orderBy) {
      const orderType = params.orderType ? params.orderType : 'desc'
      dbRef = dbRef.orderBy(params.orderBy, orderType)
    }
    if (params.maxLength) {
      dbRef = dbRef.limit(params.maxLength)
    }
    if (params.callType) {
      return await dbRef[params.callType](params.callbackSuccess)
    }
    else {
      return await dbRef.get()
    }
  }
  catch (err) {
    return {}
  }
}

export const handleUpdateObjectFirebase = async (target, doc, objectKey, params) => {
  const qSnap = await handleQueryDocFirebase(target, doc)
  let data = qSnap.data()[objectKey]
  let paramsUpdate = {
    [objectKey]: { ...data, ...params }
  }
  await handleUpdateFirebase(target, doc, paramsUpdate)
}

export const handleUpdateFirebase = (target, doc, params) => {
  return db.collection(target).doc(doc).update(params)
    .then(() => {
      console.log(`${target}/${doc} Document successfully updated!`, params);
    })
    .catch(err => {
      console.error(`${target}/${doc} ${params} Error updating document: ${err}`, params);
    });
}

export const handleSetFirebase = (target, doc, params) => {
  return db.collection(target).doc(doc).set(params)
    .then(() => {
      console.log(`${target}/${doc} Document successfully set!`, params);
    })
    .catch(err => {
      console.error(`${target}/${doc} ${params} Error updating document: ${err}`, params);
    });
}

export const handleAddFirebase = async (target, params) => {
  return await db.collection(target).add(params)
    .then((res) => {
      console.log(`${target} Document successfully added!`, params, res);
      return res
    })
    .catch(err => {
      console.error(`${target} ${params} Error adding document: ${err}`, params);
    });
}

export const handleDeleteDocFirebase = (target, doc) => {
  return db.collection(target).doc(doc).delete().then((res) => {
    console.log(`${target}/${doc} Document successfully deleted!`, res)
    return res
  }).catch((err) => {
    console.error(`${target}/${doc} Error removing document: ${err}`)
  });
}

export const getDataSnapshot = (type = 'on', params = { success: () => { } }) => {
  let ref = firebase.database().ref(params.target);
  if (params.child) {
    ref = ref.child(params.child);
  }
  if (params.orderByChild) {
    ref = ref.orderByChild(params.orderByChild);
  }
  if (params.orderByKey) {
    ref = ref.orderByKey(params.orderByKey);
  }
  if (params.orderByValue) {
    ref = ref.orderByValue(params.orderByValue);
  }
  if (params.equalTo) {
    ref = ref.equalTo(params.equalTo);
  }
  if (params.startAt) {
    ref = ref.startAt(params.startAt);
  }
  ref[type]('value', snapshot => {
    params.success(snapshot);
  })
}