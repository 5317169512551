import React from 'react'
import _ from 'lodash'
import moment from 'moment'
import qs from 'qs'
import {
  TransitionContainer,
  Container,
  Image,
  Section,
  Text,
  Field,
  NavigationBar,
  Button,
  Alert,
} from './../../components'
import {
  VARIABLES,
  TYPOGRAPHYS,
  ICONS,
} from './../../themes'
import {
  ROUTE_PATH,
  redirect,
} from './../../helpers'
import { getLanguage } from '../../helpers/functions/language'

export class WarrantySearchByDatePage extends React.Component {
  constructor(props) {
    super(props)
    const searchType = localStorage.getItem('warrantySearchType')
    this.state = {
      startDate: '',
      endDate: '',
      searchType,
      showModal: false,
      descriptionModal: '',
      typeModal: '',
    }
  }

  formatDate(input) {
    if (!input) { return '' }
    const datePart = input.match(/\d+/g),
      year = datePart[0].substring(2), // get only two digits
      month = datePart[1], day = datePart[2]

    return day + '/' + month + '/' + year
  }

  onChangeField(key, event) {
    this.setState({ [key]: event.target.value })
  }

  onSubmit() {
    const { startDate, endDate } = this.state

    if (!startDate || !endDate) {
      this.setState({
        showModal: true,
        typeModal: 'error',
        descriptionModal: getLanguage('message.pleaseSelectStartDateAndEndDate')
      })
    } else if (moment(endDate).isBefore(startDate)) {
      this.setState({
        showModal: true,
        typeModal: 'error',
        descriptionModal: getLanguage('message.invalidDateFormat')
      })
    } else {
      const query = qs.parse(_.get(this.props, 'location.search', '').replace('?', ''))
      redirect(`${ROUTE_PATH.WARRANTY_SEARCH_RESULT.LINK}?startDate=${startDate}&endDate=${endDate}&id=${_.get(query, 'id', '')}`)
    }
  }

  // handleBack() {
  //   if (this.state.searchType === 'warrantyProduct') {
  //     redirect(ROUTE_PATH.WARRANTY_PRODUCT.LINK)
  //   } else {
  //     redirect(ROUTE_PATH.WARRANTY.LINK)
  //   }
  // }

  onCloseModal() {
    this.setState({ showModal: false, typeModal: '' })
  }

  render() {
    const { startDate, endDate, showModal, typeModal, descriptionModal } = this.state
    return (
      <TransitionContainer >
        <Container
          width={VARIABLES.WEBSITE_WIDTH}
          calcMinHeight='100vh'
          bgColor={VARIABLES.COLORS.LIGHT_BLUE_2}
        >
          <NavigationBar
            pageTitle={getLanguage('warrantySearchByDate.title')}
            actionLeft={
              <Image
                widthRatio='21'
                widthRatioUnit='px'
                heightRatio='21'
                heightRatioUnit='px'
                src={ICONS['ic-close-black.svg']}
                //  onClick={this.handleBack.bind(this)}
                onClick={this.props.history.goBack}
              />
            }
          />
          <Section
            paddingHorizontal='20'
            paddingTop='33'
            justify='center'
          >
            <Section spacingBottom='15'>
              <Field
                // input date cannot change format, use placeholder for display dd/mm/yy
                // fixed ios cannot hide value by css when focus input
                value={startDate}
                convertValue={startDate ? moment(startDate, 'YYYY-MM-DD').format('DD-MM-YYYY') : ''}
                className='field-date'
                type='date'
                placeholder={`${(startDate) ? this.formatDate(startDate) : getLanguage('field.startDatePlaceholder')}`}
                fluid
                height='40'
                paddingRight='10'
                paddingLeft='50'
                bgColor={VARIABLES.COLORS.TRANSPARENT}
                borderWidth='1'
                borderStyle='solid'
                borderColor={VARIABLES.COLORS.BLUE_2}
                borderRadius='20'
                fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_14}
                fontColor={VARIABLES.COLORS.BLUE_2}
                id='start-date-select'
                name='start-date-select'
                fieldIcon={<img src={ICONS['ic-arrow-down-blue.svg']} alt='Icon' />}
                fieldIconWidth='41'
                fieldIconSpacingRight='10'
                fieldIconLeft={<img src={ICONS['ic-date-blue.svg']} alt='Icon' />}
                fieldIconLeftSpacingLeft='10'
                onChange={this.onChangeField.bind(this, 'startDate')}
              />
            </Section>
            <Section>
              <Field
                value={endDate}
                convertValue={endDate ? moment(endDate, 'YYYY-MM-DD').format('DD-MM-YYYY') : ''}
                className='field-date'
                type='date'
                placeholder={`${(endDate) ? this.formatDate(endDate) : getLanguage('field.endDatePlaceholder')}`}
                fluid
                height='40'
                paddingRight='10'
                paddingLeft='50'
                bgColor={VARIABLES.COLORS.TRANSPARENT}
                borderWidth='1'
                borderStyle='solid'
                borderColor={VARIABLES.COLORS.BLUE_2}
                borderRadius='20'
                fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_14}
                fontColor={VARIABLES.COLORS.BLUE_2}
                id='end-date-select'
                name='end-date-select'
                fieldIcon={<img src={ICONS['ic-arrow-down-blue.svg']} alt='Icon' />}
                fieldIconWidth='41'
                fieldIconSpacingRight='10'
                fieldIconLeft={<img src={ICONS['ic-date-blue.svg']} alt='Icon' />}
                fieldIconLeftSpacingLeft='10'
                onChange={this.onChangeField.bind(this, 'endDate')}
              />
            </Section>
            <Section
              paddingTop='42'
              justify='center'>
              <Button
                shadow
                className='is-gradient-blue'
                width='275'
                height={VARIABLES.BUTTON.HEIGHTS.H_40}
                borderRadius={VARIABLES.BUTTON.BORDER_RADIUSES.BR_10}
                onClick={this.onSubmit.bind(this)}
              >
                <Text
                  fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_16}
                  color={VARIABLES.COLORS.WHITE}
                >
                  {getLanguage('field.buttonFilter')}
                </Text>
              </Button>
            </Section>
          </Section>
          <Alert
            open={showModal}
            type={typeModal}
            description={descriptionModal}
            onClose={this.onCloseModal.bind(this)}
          />
        </Container>
      </TransitionContainer>
    )
  }
}