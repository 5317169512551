import React from 'react'
import {
  isIOS
} from 'react-device-detect'
import {
  TransitionContainer,
  Container,
  Image,
  Section,
  Text,
  Button,
  Modal
} from './../../components'
import {
  VARIABLES,
  TYPOGRAPHYS,
  CONTENTS,
  LOGOS,
  ICONS,
  SHAREDS
} from './../../themes'
import {
  ROUTE_PATH,
  redirect,
} from './../../helpers'
import { LOCAL_STORAGE } from '../../helpers/constants/localStorage';
import { getLocalStorage, setLocalStorage } from '../../helpers/functions/localStorage';
import { getLanguage } from '../../helpers/functions/language'
import { getAuthSuccess } from '../../helpers/functions/auth'
import { storeGetState } from '../../store'

export class SplashScreenPage extends React.Component {
  constructor(props) {
    super(props)
    let isOpenAddToHomeScreenModal = getLocalStorage(LOCAL_STORAGE.IS_IOS_OPEN_ADD_TO_HOME_SCREEN_RBP_MOBILE_APP);
    if (typeof isOpenAddToHomeScreenModal !== typeof true) {
      isOpenAddToHomeScreenModal = true;
    }
    this.state = {
      isOpenAddToHomeScreenModal,
    }
  }

  componentDidMount() {
    const isOtpAuthSuccess = getAuthSuccess();
    let a = isOtpAuthSuccess ? JSON.parse(localStorage.getItem(LOCAL_STORAGE.REGISTER_INFO)) : undefined;
    const devHostnameList = storeGetState().mainRedux.devHostnameList;
    if (a) {
      redirect(ROUTE_PATH.HOME.LINK)
    } else if (devHostnameList.indexOf(window.location.hostname) >= 0) {
      redirect(`${ROUTE_PATH.LOGIN_BY_LINE_PHONE.LINK}/lineId`)
    }
  }

  modal = {
    onClickCloseAddToHomeScreen: () => {
      this.setState({
        isOpenAddToHomeScreenModal: false,
      }, () => {
        setLocalStorage(LOCAL_STORAGE.IS_IOS_OPEN_ADD_TO_HOME_SCREEN_RBP_MOBILE_APP, false);
      })
    }
  }

  render() {
    const {
      isOpenAddToHomeScreenModal
    } = this.state

    return (
      <TransitionContainer
      // motion='overlap-from'
      >
        <Container
          width={VARIABLES.WEBSITE_WIDTH}
          height='100'
          heightUnit='vh'
        >
          {/*   <SwiperCarousel>
            <SwiperCarousel.Item>
              <Image
                widthRatio='100'
                heightRatio='100'
                heightRatioUnit='vh'
                src={CONTENTS['splash-screen-1.jpg']}
              />
            </SwiperCarousel.Item>
            <SwiperCarousel.Item>
              <Image
                widthRatio='100'
                heightRatio='100'
                heightRatioUnit='vh'
                src={CONTENTS['splash-screen-2.jpg']}
              />
            </SwiperCarousel.Item>
            <SwiperCarousel.Item>
              <Container
                height='100'
                heightUnit='vh'
                bgImage={CONTENTS['splash-screen-3.jpg']}
                bgSize='cover'
                bgRepeat='no-repeat'
                bgPosition='center'
              >
                <Container
                  height='100'
                  heightUnit='vh'
                  bgColor={VARIABLES.COLORS.OVERLAY_1}
                  justify='space-between'
                  align='center'
                  wrap='no-wrap'
                >
                  <Section>
                    <Section
                      className='is-splashscreen-logo'
                      justify='center'
                    >
                      <Image
                        widthRatio='121'
                        widthRatioUnit='px'
                        heightRatio='69'
                        heightRatioUnit='px'
                        src={LOGOS['logo.png']}
                      />
                    </Section>
                    <Section
                      paddingHorizontal='30'
                      spacingBottom='45'
                    >
                      <Section spacingBottom='45'>
                        <Text
                          className='is-splashscreen-title'
                          fontStyle={TYPOGRAPHYS.FIRST.MEDIUM.FS_54}
                          color={VARIABLES.COLORS.WHITE}
                        >
                          We Craft Solutions with Steel
                        </Text>
                      </Section>
                      <Section paddingRight='40'>
                        <Text
                          className='is-splashscreen-description'
                          fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_22}
                          color={VARIABLES.COLORS.WHITE}
                        >
                          BlueScope is a one of the
                          leading players in advanced
                          steel products and custom
                          engineered steel buildings.
                        </Text>
                      </Section>
                    </Section>
                  </Section>
                  <Section
                    spacingBottom='75'
                    justify='center'
                  >
                    <Button
                      shadow
                      className='is-gradient-blue is-icon-right'
                      width='275'
                      height={VARIABLES.BUTTON.HEIGHTS.H_40}
                      borderRadius={VARIABLES.BUTTON.BORDER_RADIUSES.BR_10}
                      onClick={() => {
                        redirect(ROUTE_PATH.LOGIN.LINK)
                      }}
                    >
                      <Button.Icon>
                        <Image
                          widthRatio='15'
                          widthRatioUnit='px'
                          heightRatio='12'
                          heightRatioUnit='px'
                          src={ICONS['ic-arrow-right.svg']}
                        />
                      </Button.Icon>
                      <Text
                        fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_16}
                        color={VARIABLES.COLORS.WHITE}
                      >
                        Login
                      </Text>
                    </Button>
                  </Section>
                </Container>
              </Container>
            </SwiperCarousel.Item>
          </SwiperCarousel> */}
          <Container
            height='100'
            heightUnit='vh'
            bgImage={CONTENTS['splash-screen-3.jpg']}
            bgSize='cover'
            bgRepeat='no-repeat'
            bgPosition='center'
          >
            <Container
              height='100'
              heightUnit='vh'
              bgImage={SHAREDS['bg-splash-screen-circle.svg']}
              bgSize='cover'
              bgRepeat='no-repeat'
              bgPosition='center'
              justify='flex-end'
              align='center'
              wrap='no-wrap'
            >
              <Section>
                <Section
                  className='is-splashscreen-logo'
                  justify='center'
                >
                  <Image
                    widthRatio='121'
                    widthRatioUnit='px'
                    heightRatio='120'
                    heightRatioUnit='px'
                    src={LOGOS['logo.svg']}
                  />
                </Section>
                <Section
                  className='is-splashscreen-content'
                  paddingHorizontal='30'
                  spacingBottom='80'
                >
                  <Section
                    paddingHorizontal='25'
                    spacingBottom='25'
                  >
                    <Text
                      className='is-splashscreen-title'
                      fontStyle={TYPOGRAPHYS.FIRST.BOLD.FS_30}
                      fontStyleMobileSm={TYPOGRAPHYS.FIRST.BOLD.FS_22}
                      color={VARIABLES.COLORS.WHITE}
                      align='center'
                    >
                      {getLanguage('splash.title')}
                    </Text>
                  </Section>
                  <Section justify='center'>
                    <Text
                      className='is-splashscreen-description'
                      fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_22}
                      fontStyleMobileSm={TYPOGRAPHYS.FIRST.REGULAR.FS_16}
                      color={VARIABLES.COLORS.WHITE}
                      align='center'
                    >
                      {getLanguage('splash.description')}
                    </Text>
                  </Section>
                </Section>
                <Section
                  spacingBottom='60'
                  justify='center'
                >
                  <Button
                    shadow
                    className='is-gradient-blue is-icon-right'
                    width='275'
                    height={VARIABLES.BUTTON.HEIGHTS.H_40}
                    borderRadius={VARIABLES.BUTTON.BORDER_RADIUSES.BR_10}
                    onClick={() => {
                      redirect(ROUTE_PATH.HOME.LINK)
                    }}
                  >
                    <Button.Icon>
                      <Image
                        widthRatio='15'
                        widthRatioUnit='px'
                        heightRatio='12'
                        heightRatioUnit='px'
                        src={ICONS['ic-arrow-right.svg']}
                      />
                    </Button.Icon>
                    <Text
                      fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_16}
                      color={VARIABLES.COLORS.WHITE}
                    >
                      {getLanguage('field.buttonContinue')}
                    </Text>
                  </Button>
                </Section>
              </Section>
            </Container>
          </Container>
        </Container>
        {
          isIOS &&
          <Modal
            open={isOpenAddToHomeScreenModal}
            isNoIcon
            ui='notice'
            title={
              <React.Fragment>
                {getLanguage('modal.iosAddToHomeScreen.title_1')}<br />
                {getLanguage('modal.iosAddToHomeScreen.title_2')}
              </React.Fragment>
            }
            content={
              <Image
                heightRatio='178'
                src={CONTENTS['save-app-to-ios-home-screen.png']}
              />
            }
            buttonSubmitName={getLanguage('field.buttonClose')}
            onClickSubmit={this.modal.onClickCloseAddToHomeScreen}
          />
        }
      </TransitionContainer>
    )
  }
}
