import React from 'react';
import {
  TransitionContainer,
  Container,
  Section,
  Image,
  Button,
  Grid,
  Text,
  Field,
  Alert,
} from './../../components';
import { VARIABLES, TYPOGRAPHYS, SHAREDS, LOGOS, ICONS } from './../../themes';
import {
  ROUTE_PATH,
  redirect,
  isValidNumberNoPrefixZero,
} from './../../helpers';
import axios from 'axios';
import { Loader } from '../../pages/Loader';
import { LOCAL_STORAGE } from '../../helpers/constants/localStorage';
import { handleWatchNoti } from '../../helpers/firebases/firebaseNotification';
import config from '../../config';
import { ENUM } from '../../helpers/constants/enum';
import { isShowLoading, onOpenAlert } from '../../helpers/functions/main';
import { getCampaignData, setCountryDataList } from '../../helpers/functions/data';
import { DATA } from '../../helpers/constants/data';
import {
  getLanguage,
  getLanguageLocalStorage,
} from '../../helpers/functions/language';
import {
  getCountryByLanguage,
  getCountryPhoneByCountry,
  setUerAuthLocalStorage,
} from '../../helpers/functions/data';
import { getCurrentCountryLanguage } from '../../helpers/functions/addon';
import {
  getRouteRefreshWithAuthApp,
  isValidResponse,
  redirectData,
} from '../../helpers/functions';
import {
  removeLocalStorage,
  setLocalStorage,
} from '../../helpers/functions/localStorage';
import { connect } from 'react-redux';
import { AlertModal } from '../../pages/AlertModal';
import { firebaseUnsubscribeOTPAuth, handleWatchOTPAuth } from '../../helpers/firebases/firebaseOTPAuth';
import { getLastOTPSuccessDay, setLastOTPSuccessDay } from '../../helpers/functions/auth';
import { fetchApi } from '../../apiService/apiService';

export class LoginContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      username: '',
      password: '',
      showModal: false,
      msg: '',
      topic: '',
      type: '',
      isLoading: false,
      countries: ENUM.COUNTRY.THAI,
      isOpenSendOTPModal: false,
      otpData: {},
      sendOtpTo: '',
      userData: {},
      isOpenMemberNotMatchModal: false,
      maximumOTPSuccesDay: 30,
    };
  }

  componentDidMount() {
    getCurrentCountryLanguage().then((language) => {
      let countries = getCountryByLanguage(language);
      console.log('getCurrentCountryLanguage', language, countries);
      setCountryDataList(countries);
      this.setState({
        countries,
      });
    });
  }

  closeAlert() {
    this.setState({
      showModal: false,
    });
  }

  handlePhoneChange(key, ev) {
    let value = ev.target.value;
    switch (key) {
      case 'username':
        if (value.length && !isValidNumberNoPrefixZero(value)) {
          return;
        }
      default:
        this.setState({
          [key]: value,
        });
    }
  }

  handlePasswordChange(e) {
    this.setState({
      password: e.target.value,
    });
  }

  otpSend(sendOtpTo) {
    const {
      username
    } = this.state;
    isShowLoading(true);
    console.log('sendOtpTo', sendOtpTo);
    fetchApi.get(`${config.api.base}/api/otp/getsecret`).then((resGetSecret) => {
      const dataSecret = resGetSecret.data;
      if (isValidResponse(dataSecret)) {
        const params = {
          secret: dataSecret.secret,
          reference_code: dataSecret.reference_code,
          to: sendOtpTo,
          message_type: 'login',
          url: `${window.location.origin}${ROUTE_PATH.AUTH.LINK_WITH_PARAMS}${ROUTE_PATH.REFERENCE_CODE.LINK}/${dataSecret.reference_code}`,
        };
        axios.post(`${config.api.base}/api/otp/send`, params).then((res) => {
          const data = res.data;
          if (isValidResponse(data)) {
            const otpData = {
              referenceCode: dataSecret.reference_code,
              secret: dataSecret.secret,
            };
            this.setState({
              isOpenSendOTPModal: true,
              otpData,
              sendOtpTo,
            }, () => {
              const {
                userData,
              } = this.state;
              isShowLoading(false);
              handleWatchOTPAuth({
                ...otpData,
                phone: sendOtpTo,
                id: userData.id,
                email: userData.email,
              }, () => {
                firebaseUnsubscribeOTPAuth()
                setUerAuthLocalStorage(userData);
                setLocalStorage(LOCAL_STORAGE.IS_OTP_AUTH_SUCCESS, true);
                setLastOTPSuccessDay({
                  username,
                  otpSuccessDate: new Date(),
                });
                window.location.href = getRouteRefreshWithAuthApp();
              });
            });
          } else {
            isShowLoading(false);
            onOpenAlert({
              type: DATA.ALERT_TYPE.ERROR,
              title: getLanguage('message.fail'),
              message: data.message,
            });
          }
        });
      }
      else {
        isShowLoading(false);
        onOpenAlert({
          type: DATA.ALERT_TYPE.ERROR,
          title: getLanguage('message.fail'),
          message: (dataSecret || {}).message,
        });
      }
    });
  }

  signIn() {
    const {
      username
    } = this.state;
    const { handleLogin } = this.props;
    const {
      countries,
      maximumOTPSuccesDay
    } = this.state;
    handleLogin(this.state, (response) => {
      const res = response.data;
      const statusCode = res.status_code;
      if (statusCode === 1) {
        this.setState({
          isOpenMemberNotMatchModal: true,
        });
      } else {
        if (getLastOTPSuccessDay(username) <= maximumOTPSuccesDay) {
          // for login without otp
          setUerAuthLocalStorage(res.data);
          setLocalStorage(LOCAL_STORAGE.IS_OTP_AUTH_SUCCESS, true);
          window.location.href = getRouteRefreshWithAuthApp();
        }
        else {
          // for login with otp
          this.setState({
            userData: res.data
          }, () => {
            const prefix = getCountryPhoneByCountry(countries);
            const number = res.data.phone;
            let sendOtpTo = `${prefix}${number}`;
            this.otpSend(sendOtpTo);
          });
        }
      }
    });
  }

  handleSelect(event) {
    console.log('event', event.target.value);
    this.setState({
      countries: event.target.value,
    });
  }

  login = {
    onClickResendOtp: () => {
      const {
        sendOtpTo,
      } = this.state;
      this.otpSend(sendOtpTo);
    },
    onClickSignUp: () => {
      const { ssoId } = this.props;
      if (ssoId) {
        isShowLoading(true);
        const campaignData = getCampaignData();
        fetchApi.get(`${config.api.base}/api/campaign/check?campaign_code=${campaignData.campaignCode}`).then(async (response) => {
          const res = response.data;
          let isVoucherFull = true;
          if (isValidResponse(res)) {
            isVoucherFull = false;
          }
          isShowLoading(false);
          console.log('isVoucherFull', isVoucherFull);
          console.log('isEndCampaign', campaignData.isEndCampaign);
          if (campaignData.isEndCampaign) {
            if (isVoucherFull) {
              redirect(`${ROUTE_PATH.REGISTER.LINK}/${ssoId}`);
            }
            else {
              redirect(`${ROUTE_PATH.VOUCHER_OUT_OF_STOCK.LINK}/${ssoId}`);
            }
          }
          else {
            if (isVoucherFull) {
              redirect(`${ROUTE_PATH.REGISTER.LINK}/${ssoId}`);
            }
            else {
              redirect(`${ROUTE_PATH.REGISTER.LINK}/${ssoId}`);
            }
          }
        }, (error) => {
          console.log('errr===>', error);
          isShowLoading(false);
          onOpenAlert({
            type: DATA.ALERT_TYPE.ERROR,
            title: getLanguage('message.fail'),
            message: (error || {}).message,
          });
        });
      } else {
        redirect(ROUTE_PATH.TERMS_CONDITIONS.LINK);
      }
    },
    onCloseMemberNotMatchModal: () => {
      this.setState({
        isOpenMemberNotMatchModal: false,
      });
    },
    backToLogin: () => {
      this.setState({
        isOpenSendOTPModal: false,
      });
      firebaseUnsubscribeOTPAuth();
    }
  };

  render() {
    const {
      ssoId,
    } = this.props;
    const {
      countries,
      username,
      isOpenSendOTPModal,
      otpData,
      isOpenMemberNotMatchModal,
    } = this.state;

    return (
      <TransitionContainer
      // motion='overlap-from'
      >
        <Container
          width={VARIABLES.WEBSITE_WIDTH}
          height="100"
          heightUnit="vh"
          bgImage={SHAREDS['bg-login.jpg']}
          bgSize="cover"
          bgRepeat="no-repeat"
          bgPosition="center"
        >
          <Container
            height="100"
            heightUnit="vh"
            bgColor={VARIABLES.COLORS.OVERLAY_5}
            justify="space-between"
            align="center"
            wrap="no-wrap"
          >
            <Section className="is-login-container" width="275">
              <Section className="is-login-phone-logo" justify="center">
                <Image
                  widthRatio="121"
                  widthRatioUnit="px"
                  heightRatio="120"
                  heightRatioUnit="px"
                  src={LOGOS['logo.png']}
                />
              </Section>
              <Section spacingBottom="20" spacingLeft="10">
                <Grid gutter="12">
                  <Grid.Column>
                    <Image
                      widthRatio="24"
                      widthRatioUnit="px"
                      heightRatio="24"
                      heightRatioUnit="px"
                      src={ICONS['ic-phone-lightblue.svg']}
                    />
                  </Grid.Column>
                  <Grid.Column>
                    <Text
                      fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_16}
                      color={VARIABLES.COLORS.BLUE_2}
                      top="2"
                      onClick={() => { }}
                    >
                      {getLanguage('field.phoneNumber')}
                    </Text>
                  </Grid.Column>
                </Grid>
              </Section>
              <Section spacingBottom="34">
                <Field
                  fluid
                  inputTextType="number"
                  className="is-phone-id"
                  placeholder="Insert phone number"
                  placeholderColor={VARIABLES.COLORS.OVERLAY_6}
                  height="28"
                  paddingBottom="10"
                  paddingLeft={
                    this.state.countries.toLowerCase() ===
                      ENUM.COUNTRY.THAI.toLowerCase()
                      ? '105'
                      : this.state.countries.toLowerCase() ===
                        ENUM.COUNTRY.INDONESIA.toLowerCase()
                        ? '110'
                        : this.state.countries.toLowerCase() ===
                          ENUM.COUNTRY.MALAYSIA.toLowerCase()
                          ? '116'
                          : this.state.countries.toLowerCase() ===
                            ENUM.COUNTRY.LAOS.toLowerCase()
                            ? '132'
                            : '106'
                  }
                  bgColor={VARIABLES.COLORS.TRANSPARENT}
                  borderBottomWidth="1"
                  borderBottomStyle="solid"
                  value={username}
                  onChange={this.handlePhoneChange.bind(this, 'username')}
                  borderBottomColor={VARIABLES.COLORS.BLUE_2}
                  fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_15}
                  fontColor={VARIABLES.COLORS.BLUE_2}
                  fieldIcon={
                    <Grid gutter="10">
                      <Grid.Column>
                        {this.state.countries.toLowerCase() ===
                          ENUM.COUNTRY.THAI.toLowerCase() ? (
                          <Image
                            widthRatio="27"
                            widthRatioUnit="px"
                            heightRatio="18"
                            heightRatioUnit="px"
                            src={ICONS['ic-flag-th.png']}
                          />
                        ) : this.state.countries.toLowerCase() ===
                          ENUM.COUNTRY.INDONESIA.toLowerCase() ? (
                          <Image
                            widthRatio="27"
                            widthRatioUnit="px"
                            heightRatio="18"
                            heightRatioUnit="px"
                            src={ICONS['ic-flag-in.png']}
                          />
                        ) : this.state.countries.toLowerCase() ===
                          ENUM.COUNTRY.MALAYSIA.toLowerCase() ? (
                          <Image
                            widthRatio="27"
                            widthRatioUnit="px"
                            heightRatio="18"
                            heightRatioUnit="px"
                            src={ICONS['ic-flag-ma.png']}
                          />
                        ) : this.state.countries.toLowerCase() ===
                          ENUM.COUNTRY.LAOS.toLowerCase() ? (
                          <Image
                            widthRatio="27"
                            widthRatioUnit="px"
                            heightRatio="18"
                            heightRatioUnit="px"
                            src={ICONS['ic-flag-la.png']}
                          />
                        ) : (
                          <Image
                            widthRatio="27"
                            widthRatioUnit="px"
                            heightRatio="18"
                            heightRatioUnit="px"
                            src={ICONS['ic-flag-vt.png']}
                          />
                        )}
                      </Grid.Column>
                      <Grid.Column>
                        <Section paddingTop="1">
                          <select
                            name=""
                            id="select-flag"
                            className="select-flag"
                            onChange={this.handleSelect.bind(this)}
                          >
                            <option
                              selected={
                                countries.toLowerCase() ===
                                  ENUM.COUNTRY.THAI.toLowerCase()
                                  ? 'selected'
                                  : undefined
                              }
                              value={ENUM.COUNTRY.THAI}
                            >
                              TH +66{' '}
                            </option>
                            <option
                              selected={
                                countries.toLowerCase() ===
                                  ENUM.COUNTRY.INDONESIA.toLowerCase()
                                  ? 'selected'
                                  : undefined
                              }
                              value={ENUM.COUNTRY.INDONESIA}
                            >
                              INA +62{' '}
                            </option>
                            <option
                              selected={
                                countries.toLowerCase() ===
                                  ENUM.COUNTRY.MALAYSIA.toLowerCase()
                                  ? 'selected'
                                  : undefined
                              }
                              value={ENUM.COUNTRY.MALAYSIA}
                            >
                              MAS +60
                            </option>
                            <option
                              selected={
                                countries.toLowerCase() ===
                                  ENUM.COUNTRY.LAOS.toLowerCase()
                                  ? 'selected'
                                  : undefined
                              }
                              value={ENUM.COUNTRY.LAOS}
                            >
                              LAOS +856
                            </option>
                            <option
                              selected={
                                countries.toLowerCase() ===
                                  ENUM.COUNTRY.VIETNAM.toLowerCase()
                                  ? 'selected'
                                  : undefined
                              }
                              value={ENUM.COUNTRY.VIETNAM}
                            >
                              VIE +84
                            </option>
                          </select>
                        </Section>
                      </Grid.Column>
                    </Grid>
                  }
                  fieldIconSpacingLeft="10"
                />
              </Section>
              {!ssoId && (
                <Section spacingBottom="12">
                  <Field
                    fluid
                    className="is-password"
                    inputTextType="password"
                    placeholder={getLanguage('field.password')}
                    placeholderColor={VARIABLES.COLORS.BLUE_2}
                    height="28"
                    paddingBottom="10"
                    paddingLeft="47"
                    bgColor={VARIABLES.COLORS.TRANSPARENT}
                    borderBottomWidth="1"
                    borderBottomStyle="solid"
                    onChange={this.handlePasswordChange.bind(this)}
                    borderBottomColor={VARIABLES.COLORS.BLUE_2}
                    fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_15}
                    fontColor={VARIABLES.COLORS.BLUE_2}
                    fieldIcon={
                      <Image
                        widthRatio="21"
                        widthRatioUnit="px"
                        heightRatio="28"
                        heightRatioUnit="px"
                        src={ICONS['ic-password-lightblue.svg']}
                      />
                    }
                    fieldIconSpacingLeft="10"
                  />
                </Section>
              )}
              {!ssoId && (
                <Section
                  className="is-login-phone-forgotpassword"
                  justify="flex-end"
                >
                  <Text
                    fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_14}
                    color={VARIABLES.COLORS.GRAY_3}
                    onClick={() => {
                      redirect(ROUTE_PATH.FORGOT_PASSWORD_METHOD.LINK);
                    }}
                  >
                    {getLanguage('loginPhone.message')}
                  </Text>
                </Section>
              )}
              <Section spacingBottom="18">
                <Button
                  fluid
                  shadow
                  height={VARIABLES.BUTTON.HEIGHTS.H_40}
                  borderRadius={VARIABLES.BUTTON.BORDER_RADIUSES.BR_10}
                  bgColor={VARIABLES.COLORS.BLUE_2}
                  onClick={this.signIn.bind(this)}
                >
                  <Text
                    fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_16}
                    color={VARIABLES.COLORS.WHITE}
                  >
                    {getLanguage('field.buttonLogin')}
                  </Text>
                </Button>
              </Section>
              <Section>
                <Button
                  fluid
                  height={VARIABLES.BUTTON.HEIGHTS.H_40}
                  bgColor={VARIABLES.COLORS.TRANSPARENT}
                  borderRadius={VARIABLES.BUTTON.BORDER_RADIUSES.BR_10}
                  borderWidth="1"
                  borderStyle="solid"
                  borderColor={VARIABLES.COLORS.BLUE_2}
                  onClick={this.login.onClickSignUp}
                >
                  <Text
                    fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_16}
                    color={VARIABLES.COLORS.BLUE_2}
                  >
                    {getLanguage('field.buttonSignUp')}
                  </Text>
                </Button>
              </Section>
            </Section>
            {/* <Section className='is-login-back'>
              <Grid
                gutter='3'
                justify='center'
              >
                <Grid.Column>
                  <Text
                    fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_16}
                    color={VARIABLES.COLORS.BLUE_2}
                  >
                    {getLanguage('loginPhone.message_2')}
                  </Text>
                </Grid.Column>
                <Grid.Column>
                  <Text
                    className='is-underline'
                    fontStyle={TYPOGRAPHYS.FIRST.BOLD.FS_18}
                    color={VARIABLES.COLORS.BLUE_2}
                    onClick={() => {
                      redirect(ROUTE_PATH.LOGIN.LINK)
                    }}
                  >
                    {getLanguage('field.buttonBack')}
                  </Text>
                </Grid.Column>
              </Grid>
            </Section> */}
          </Container>
        </Container>

        {this.state.showModal ? (
          <AlertModal
            topic={this.state.topic}
            msg={this.state.msg}
            closeModal={this.closeAlert.bind(this)}
          />
        ) : (
          ''
        )}

        {/* RL-707 : Modal authentication link */}
        {isOpenSendOTPModal && (
          <Alert
            open
            singleButton={false}
            type="send"
            title={getLanguage('modal.authentication.title')}
            description={getLanguage('modal.authentication.description1')}
            descriptionSecondary={
              <React.Fragment>
                <Section spacingTop="12" spacingBottom="12" justify="center">
                  <Text
                    fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_15}
                    color={VARIABLES.COLORS.GRAY_6}
                  >
                    {getLanguage('modal.authentication.description2')}
                  </Text>
                  &nbsp;
                  <Text
                    fontStyle={TYPOGRAPHYS.FIRST.BOLD.FS_15}
                    color={VARIABLES.COLORS.BLUE_2}
                  >
                    {otpData.referenceCode}
                  </Text>
                </Section>
                <Section justify="center">
                  <Text
                    fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_15}
                    color={VARIABLES.COLORS.RED_2}
                    align="center"
                  >
                    {getLanguage('modal.authentication.description3')}
                  </Text>
                </Section>
              </React.Fragment>
            }
            buttons={
              <React.Fragment>
                <Section justify="center" paddingTop="40" spacingBottom="7">
                  <Text
                    underline
                    fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_15}
                    color={VARIABLES.COLORS.BLUE_2}
                    onClick={this.login.onClickResendOtp}
                  >
                    {getLanguage('modal.authentication.link')}
                  </Text>
                </Section>
                <Section justify="center" spacingBottom="7">
                  <Text
                    fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_15}
                    color={VARIABLES.COLORS.GRAY_6}
                  >
                    {getLanguage('modal.authentication.description4')}
                  </Text>
                </Section>
                <Section justify="center">
                  <Button
                    width="200"
                    height={VARIABLES.BUTTON.HEIGHTS.H_40}
                    bgColor={VARIABLES.COLORS.TRANSPARENT}
                    borderRadius={VARIABLES.BUTTON.BORDER_RADIUSES.BR_10}
                    borderWidth="1"
                    borderStyle="solid"
                    borderColor={VARIABLES.COLORS.BLUE_2}
                    onClick={this.login.backToLogin}
                  >
                    <Text
                      fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_16}
                      color={VARIABLES.COLORS.BLUE_2}
                    >
                      {getLanguage('field.buttonToLogin')}
                    </Text>
                  </Button>
                </Section>
              </React.Fragment>
            }
            onClose={this.login.backToLogin}
          />
        )}

        {/* RL-708 : Phone number doesn't match  */}
        {isOpenMemberNotMatchModal && (
          <Alert
            open
            logoHeadingTrueBlue
            singleButton={false}
            title={getLanguage('message.phoneNotMatch')}
            description={getLanguage('message.phoneNotMatch1')}
            descriptionSecondary={
              <React.Fragment>
                <Section justify="center">
                  <a href="tel:023333030">
                    <Text
                      underline
                      fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_15}
                      color={VARIABLES.COLORS.BLUE_2}
                    >
                      {getLanguage('message.phoneNotMatch2')}
                    </Text>
                  </a>
                </Section>
                <Section spacingTop="12" paddingBottom="12" justify="center">
                  <Text
                    fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_15}
                    color={VARIABLES.COLORS.GRAY_6}
                  >
                    {getLanguage('message.phoneNotMatch3')}
                  </Text>
                </Section>
              </React.Fragment>
            }
            buttons={
              <React.Fragment>
                <Section spacingBottom="15">
                  <Button
                    fluid
                    shadow
                    className="is-gradient-blue"
                    height={VARIABLES.BUTTON.HEIGHTS.H_35}
                    borderRadius={VARIABLES.BUTTON.BORDER_RADIUSES.BR_10}
                    onClick={this.login.onClickSignUp}
                  >
                    <Text
                      fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_16}
                      color={VARIABLES.COLORS.WHITE}
                    >
                      {getLanguage('field.buttonRegisterNow')}
                    </Text>
                  </Button>
                </Section>
                <Button
                  fluid
                  height={VARIABLES.BUTTON.HEIGHTS.H_35}
                  bgColor={VARIABLES.COLORS.WHITE}
                  borderStyle="solid"
                  borderColor={VARIABLES.COLORS.BLUE_2}
                  borderWidth="1"
                  borderRadius={VARIABLES.BUTTON.BORDER_RADIUSES.BR_10}
                  onClick={this.login.onCloseMemberNotMatchModal}
                >
                  <Text
                    fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_16}
                    color={VARIABLES.COLORS.BLUE_2}
                  >
                    {getLanguage('field.buttonClose')}
                  </Text>
                </Button>
              </React.Fragment>
            }
            onClose={this.login.onCloseMemberNotMatchModal}
          />
        )}

        {<Loader loading={this.state.isLoading} isOverlay={true} />}
      </TransitionContainer>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export const LoginContainerConnected = connect(
  mapStateToProps,
  mapDispatchToProps
)(LoginContainer);
