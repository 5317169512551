import styled, {
  css
} from 'styled-components'
// import {
//   default as VARIABLES
// } from './../../../themes/styles/bases/variables'
// import {
//   default as TYPOGRAPHYS
// } from './../../../themes/styles/bases/typographys'
// import {
//   default as MIXINS
// } from './../../../themes/styles/helpers/mixins'

// Sample using image in style
// url(${require('./images/icons/icon-sample.svg')});
// url(${require('./../../../themes/images/icons/icon-sample.svg')});

// Wrapper
// ============================================================
export const SectionBaseWrapper = styled.div`
  /* Parent
  ------------------------------- */
  ${props => {
    const theme = props.theme
    return css`
      /* Align */
      ${(theme.flex || theme.direction || theme.justify || theme.align) && css`
          display: flex;
          flex-wrap: wrap;
          flex: ${theme.flex};
          flex-direction: ${theme.direction};
          justify-content: ${theme.justify};
          align-items: ${theme.align};
        `
      }

      /* Width, Height */
      ${theme.width ?
        css`
          width: ${theme.width + theme.widthUnit};
        `
        :
        css`
          width: 100%;
        `
      }

      ${theme.maxWidth && css`
          max-width: ${theme.maxWidth + theme.widthUnit};
        `
      }

      ${theme.height && css`
          height: ${theme.height + theme.heightUnit};
        `
      }

      ${theme.minHeight && css`
          min-height: ${theme.minHeight + theme.heightUnit};
        `
      }
      
      ${theme.maxHeight && css`
          max-height: ${theme.maxHeight + theme.heightUnit};
        `
      }

      ${theme.calcHeight && css`
          height: calc(${theme.calcHeight});
        `
      }

      ${theme.calcMinHeight && css`
          min-height: calc(${theme.calcMinHeight});
        `
      }

      /* Padding */
      ${theme.padding && css`
          padding: ${theme.padding + `px`};
        `
      }

      ${theme.paddingTop && css`
          padding-top: ${theme.paddingTop + `px`};
        `
      }

      ${theme.paddingRight && css`
          padding-right: ${theme.paddingRight + `px`};
        `
      }

      ${theme.paddingBottom && css`
          padding-bottom: ${theme.paddingBottom + `px`};
        `
      }

      ${theme.paddingLeft && css`
          padding-left: ${theme.paddingLeft + `px`};
        `
      }

      ${theme.paddingHorizontal && css`
          padding-right: ${theme.paddingHorizontal + `px`};
          padding-left: ${theme.paddingHorizontal + `px`};
        `
      }

      ${theme.paddingVertical && css`
          padding-top: ${theme.paddingVertical + `px`};
          padding-bottom: ${theme.paddingVertical + `px`};
        `
      }

      /* Spacing (Margin) */
      ${theme.spacing && css`
          margin: ${theme.spacing + `px`};
        `
      }

      ${theme.spacingTop && css`
          margin-top: ${theme.spacingTop + `px`};
        `
      }

      ${theme.spacingRight && css`
          margin-right: ${theme.spacingRight + `px`};
        `
      }

      ${theme.spacingBottom && css`
          margin-bottom: ${theme.spacingBottom + `px`};
        `
      }

      ${theme.spacingLeft && css`
          margin-left: ${theme.spacingLeft + `px`};
        `
      }

      ${theme.spacingHorizontal && css`
          margin-right: ${theme.spacingHorizontal + `px`};
          margin-left: ${theme.spacingHorizontal + `px`};
        `
      }

      ${theme.spacingVertical && css`
          margin-top: ${theme.spacingVertical + `px`};
          margin-bottom: ${theme.spacingVertical + `px`};
        `
      }

      /* Background color */
      background-color: ${theme.bgColor};

      /* Border */
      ${theme.borderTopWidth && css`
          border-top: ${theme.borderTopWidth && `${theme.borderTopWidth + `px`}`} ${theme.borderTopStyle} ${theme.borderTopColor};
        `
      }

      ${theme.borderRightWidth && css`
          border-right: ${theme.borderRightWidth && `${theme.borderRightWidth + `px`}`} ${theme.borderRightStyle} ${theme.borderRightColor};
        `
      }

      ${theme.borderBottomWidth && css`
          border-bottom: ${theme.borderBottomWidth && `${theme.borderBottomWidth + `px`}`} ${theme.borderBottomStyle} ${theme.borderBottomColor};
        `
      }

      ${theme.borderLeftWidth && css`
          border-left: ${theme.borderLeftWidth && `${theme.borderLeftWidth + `px`}`} ${theme.borderLeftStyle} ${theme.borderLeftColor};
        `
      }

      ${theme.borderWidth && css`
          border: ${theme.borderWidth && `${theme.borderWidth + `px`}`} ${theme.borderStyle} ${theme.borderColor};
        `
      }

      ${theme.position && css`
          position: ${theme.position};
        `
      }
      
      ${theme.bottom && css`
          bottom: ${theme.bottom + `px`} ;
        `
      }
      
      ${theme.top && css`
          top: ${theme.top + `px`} ;
        `
      }

      ${theme.right && css`
          right: ${theme.right + `px`} ;
        `
      }

      ${theme.borderRadius && css`
          border-radius: ${theme.borderRadius + `px`};
        `
      }
      
      ${theme.borderBottomRightRadius && css`
          border-bottom-right-radius: ${theme.borderBottomRightRadius + `px`};
        `
      }
      
      ${theme.borderBottomLeftRadius && css`
          border-bottom-left-radius: ${theme.borderBottomLeftRadius + `px`};
        `
      }

      ${theme.textAlign && css`
          text-align: ${theme.textAlign};
        `
      }
      
      ${theme.breakAll && css`
          word-break: break-all;
        `
      }
    `
  }}

  /* Childrens
  ------------------------------- */
  /* Modifiers
  ------------------------------- */
  &.is-scroll {
    overflow: auto;
  }

  &.is-non-fluid {
    flex: none;
    width: auto;
  }

  /* Media queries
  ------------------------------- */
`
