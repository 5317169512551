import React from 'react';
import {
  connect,
} from 'react-redux';
import { getLanguage } from '../../helpers/functions/language';
import { getUserAuth } from '../../helpers/functions/auth';
import { ENUM } from '../../helpers/constants/enum';
import _ from 'lodash';

class SelectProvinceContainer extends React.Component {

  constructor(props) {
    super(props);
    const userAuth = getUserAuth() || {};
    this.state = {
      userAuth,
      isMalay: _.get(userAuth, 'countries', '').toLowerCase() === ENUM.COUNTRY.MALAYSIA.toLowerCase(),
    }
  }

  render() {
    const {
      dataList,
      value = '',
    } = this.props;
    return (
      <React.Fragment>
        <option value=''>{getLanguage('field.provincePlaceholder')}</option>
        {dataList.map((e, i) => {
          return (
            <option
              key={i}
              selected={e.value.toString() === value.toString()}
              value={e.value}
            >
              {e.label}
            </option>
          )
        })}
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state) => {
  const dataRedux = state.dataRedux;
  return {
    dataList: dataRedux.countryList,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
  }
}

export const SelectProvinceContainerConnected = connect(
  mapStateToProps,
  mapDispatchToProps
)(SelectProvinceContainer)