import styled, {
  css
} from 'styled-components'
import {
  default as VARIABLES
} from './../../../themes/styles/bases/variables'
// import {
//   default as VARIABLES
// } from './../../../themes/styles/bases/variables'
// import {
//   default as TYPOGRAPHYS
// } from './../../../themes/styles/bases/typographys'
// import {
//   default as MIXINS
// } from './../../../themes/styles/helpers/mixins'

// Sample using image in style
// url(${require('./images/icons/icon-sample.svg')});
// url(${require('./../../../themes/images/icons/icon-sample.svg')});

// Wrapper
// ============================================================
export const ModalBaseWrapper = styled.div`
  /* Parent
  ------------------------------- */
  pointer-events: none;
  visibility: hidden;
  opacity: 0;
  position: absolute;
  z-index: ${VARIABLES.Z_INDEXS.LV_7};
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 100%;

  ${props => {
    const theme = props.theme
    return css`
      /* Transition duration must consistent with a children element for continuous animation */
      transition: ${theme.transition};
    `
  }}

  /* Childrens
  ------------------------------- */
  .modal-container-base {
    opacity: 0;
    position: relative;
    z-index: ${VARIABLES.Z_INDEXS.LV_6};
    width: 100%;

    ${props => {
      const theme = props.theme
      return css`
        /* Transition duration must consistent with a children element for continuous animation */
        transition: ${theme.transition};
        transform: ${theme.transformStart};

        ${theme.width && css`
            max-width: ${theme.width + `px`};
          `
        }

        ${theme.spacing && css`
            margin: ${theme.spacing + `px`};
          `
        }

        ${theme.spacingTop && css`
            margin-top: ${theme.spacingTop + `px`};
          `
        }

        ${theme.spacingRight && css`
            margin-right: ${theme.spacingRight + `px`};
          `
        }

        ${theme.spacingBottom && css`
            margin-bottom: ${theme.spacingBottom + `px`};
          `
        }

        ${theme.spacingLeft && css`
            margin-left: ${theme.spacingLeft + `px`};
          `
        }

        ${theme.spacingHorizontal && css`
            margin-right: ${theme.spacingHorizontal + `px`};
            margin-left: ${theme.spacingHorizontal + `px`};
          `
        }

        ${theme.spacingVertical && css`
            margin-top: ${theme.spacingVertical + `px`};
            margin-bottom: ${theme.spacingVertical + `px`};
          `
        }
      `
    }}
  }

  .modal-button-close-base {
    position: absolute;
    z-index: ${VARIABLES.Z_INDEXS.LV_2};

    ${props => {
      const theme = props.theme
      return css`
        ${theme.buttonCloseSpacingTop && css`
            top: ${theme.buttonCloseSpacingTop + `px`};
          `
        }

        ${theme.buttonCloseSpacingRight && css`
            right: ${theme.buttonCloseSpacingRight + `px`};
          `
        }
      `
    }}
  }

  .modal-overlay-clickable-area-base {
    position: absolute;
    z-index: ${VARIABLES.Z_INDEXS.LV_3};
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  /* Modifiers
  ------------------------------- */
  &.is-open {
    pointer-events: auto;
    visibility: visible;
    opacity: 1;

    .modal-container-base {
      opacity: 1;

      ${props => {
        const theme = props.theme
        return css`
          transform: ${theme.transformEnd};
        `
      }}
    }
  }

  &.is-full-page {
    align-items: baseline;

    .modal-section-base {
      height: 100vh;
    }
  }
  /* Media queries
  ------------------------------- */
`

// Section
// ============================================================
export const ModalSectionBaseWrapper = styled.div`
  /* Parent
  ------------------------------- */
  ${props => {
    const theme = props.theme
    return css`
      ${theme.padding && css`
          padding: ${theme.padding + `px`};
        `
      }

      ${theme.paddingTop && css`
          padding-top: ${theme.paddingTop + `px`};
        `
      }

      ${theme.paddingRight && css`
          padding-right: ${theme.paddingRight + `px`};
        `
      }

      ${theme.paddingBottom && css`
          padding-bottom: ${theme.paddingBottom + `px`};
        `
      }

      ${theme.paddingLeft && css`
          padding-left: ${theme.paddingLeft + `px`};
        `
      }

      ${theme.paddingHorizontal && css`
          padding-right: ${theme.paddingHorizontal + `px`};
          padding-left: ${theme.paddingHorizontal + `px`};
        `
      }

      ${theme.paddingVertical && css`
          padding-top: ${theme.paddingVertical + `px`};
          padding-bottom: ${theme.paddingVertical + `px`};
        `
      }

      background-color: ${theme.bgColor};

      ${theme.borderTopWidth && css`
          border-top: ${theme.borderTopWidth && `${theme.borderTopWidth + `px`}`} ${theme.borderTopStyle} ${theme.borderTopColor};
        `
      }

      ${theme.borderBottomWidth && css`
          border-bottom: ${theme.borderBottomWidth && `${theme.borderBottomWidth + `px`}`} ${theme.borderBottomStyle} ${theme.borderBottomColor};
        `
      }

      ${theme.borderTopRightRadius && css`
          border-top-right-radius: ${theme.borderTopRightRadius + `px`};
        `
      }

      ${theme.borderTopLeftRadius && css`
          border-top-left-radius: ${theme.borderTopLeftRadius + `px`};
        `
      }

      ${theme.borderBottomRightRadius && css`
          border-bottom-right-radius: ${theme.borderBottomRightRadius + `px`};
        `
      }

      ${theme.borderBottomLeftRadius && css`
          border-bottom-left-radius: ${theme.borderBottomLeftRadius + `px`};
        `
      }

      ${theme.borderRadius && css`
          border-radius: ${theme.borderRadius + `px`};
        `
      }
      
      ${theme.maxHeight && css`
          max-height: ${theme.maxHeight + `px`};
        `
      }
      
      ${theme.overflow && css`
          overflow: ${theme.overflow};
        `
      }
    `
  }}

  /* Childrens
  ------------------------------- */
  /* Modifiers
  ------------------------------- */
  /* Media queries
  ------------------------------- */
`

// Overlay
// ============================================================
export const ModalOverlayBaseWrapper = styled.div`
  /* Parent
  ------------------------------- */
  pointer-events: none;
  opacity: 0;
  position: fixed;
  z-index: ${VARIABLES.Z_INDEXS.LV_4};
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  ${props => {
    const theme = props.theme
    return css`
      transition: ${theme.transition};
      background-color: ${theme.overlayColor};
    `
  }}

  /* Childrens
  ------------------------------- */
  /* Modifiers
  ------------------------------- */
  &.is-open {
    pointer-events: auto;
    opacity: 1;
  }

  /* Media queries
  ------------------------------- */
`
