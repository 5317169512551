import React from 'react'
import ClassNames from 'classnames'
import PropTypes from 'prop-types'
import {
  CmsLayoutBaseWrapper,
  CmsLayoutTopbarBaseWrapper,
  CmsLayoutSidebarBaseWrapper,
  CmsLayoutContentBaseWrapper
} from './styled'
import {
  default as VARIABLES
} from './../../../themes/styles/bases/variables'
// import {
//   default as TYPOGRAPHYS
// } from './../../../themes/styles/bases/typographys'

/**
 * CmsLayoutBase description:
 * - base component CmsLayout
 */

class CmsLayoutTopbarBase extends React.PureComponent {
  render () {
    const {
      // Option
      children,
      className,
      // Default
      topbarHeight,
      topbarPadding,
      topbarPaddingTop,
      topbarPaddingRight,
      topbarPaddingBottom,
      topbarPaddingLeft,
      topbarPaddingHorizontal,
      topbarPaddingVertical,
      topbarBgColor,
      topbarBorderBottomWidth,
      topbarBorderBottomStyle,
      topbarBorderBottomColor,
      topbarBoxShadow
    } = this.props

    return (
      <CmsLayoutTopbarBaseWrapper
        className={ClassNames(
          'cms-layout-topbar-base',
          // Option
          className
        )}
        theme={{
          // Default
          topbarHeight,
          topbarPadding,
          topbarPaddingTop,
          topbarPaddingRight,
          topbarPaddingBottom,
          topbarPaddingLeft,
          topbarPaddingHorizontal,
          topbarPaddingVertical,
          topbarBgColor,
          topbarBorderBottomWidth,
          topbarBorderBottomStyle,
          topbarBorderBottomColor,
          topbarBoxShadow
        }}
      >
        {children}
      </CmsLayoutTopbarBaseWrapper>
    )
  }

  static defaultProps = {
    // Option
    // children: undefined,
    // className: undefined,
    // Default
    topbarHeight: VARIABLES.CMS_LAYOUT.TOPBAR.HEIGHT
  }

  static propTypes = { // TYPE > node, string, number, bool, array, object, symbol, func
    /**
    * Additional classes
    */
    className: PropTypes.string,

    /**
    * Additional Text, HTML, Component
    */
    children: PropTypes.oneOfType([
      PropTypes.node,
      PropTypes.string
    ])
  }
}

class CmsLayoutSidebarBase extends React.PureComponent {
  render () {
    const {
      // Option
      children,
      className,
      // Default
      sidebarWidth,
      sidebarPadding,
      sidebarPaddingTop,
      sidebarPaddingRight,
      sidebarPaddingBottom,
      sidebarPaddingLeft,
      sidebarPaddingHorizontal,
      sidebarPaddingVertical,
      sidebarBgColor,
      sidebarBoxShadow
    } = this.props

    return (
      <CmsLayoutSidebarBaseWrapper
        className={ClassNames(
          'cms-layout-sidebar-base',
          // Option
          className
        )}
        theme={{
          // Default
          sidebarWidth,
          sidebarPadding,
          sidebarPaddingTop,
          sidebarPaddingRight,
          sidebarPaddingBottom,
          sidebarPaddingLeft,
          sidebarPaddingHorizontal,
          sidebarPaddingVertical,
          sidebarBgColor,
          sidebarBoxShadow
        }}
      >
        {children}
      </CmsLayoutSidebarBaseWrapper>
    )
  }

  static defaultProps = {
    // Option
    // children: undefined,
    // className: undefined,
    // Default
    sidebarWidth: VARIABLES.CMS_LAYOUT.SIDEBAR.WIDTH
  }

  static propTypes = { // TYPE > node, string, number, bool, array, object, symbol, func
    /**
    * Additional classes
    */
    className: PropTypes.string,

    /**
    * Additional Text, HTML, Component
    */
    children: PropTypes.oneOfType([
      PropTypes.node,
      PropTypes.string
    ])
  }
}

class CmsLayoutContentBase extends React.PureComponent {
  render () {
    const {
      // Option
      children,
      className,
      // Default
      contentPadding,
      contentPaddingTop,
      contentPaddingRight,
      contentPaddingBottom,
      contentPaddingLeft,
      contentPaddingHorizontal,
      contentPaddingVertical,
      contentBgColor
    } = this.props

    return (
      <CmsLayoutContentBaseWrapper
        className={ClassNames(
          'cms-layout-content-base',
          // Option
          className
        )}
        theme={{
          // Default
          contentPadding,
          contentPaddingTop,
          contentPaddingRight,
          contentPaddingBottom,
          contentPaddingLeft,
          contentPaddingHorizontal,
          contentPaddingVertical,
          contentBgColor
        }}
      >
        {children}
      </CmsLayoutContentBaseWrapper>
    )
  }

  static defaultProps = {
    // Option
    // children: undefined,
    // className: undefined,
    // Default
  }

  static propTypes = { // TYPE > node, string, number, bool, array, object, symbol, func
    /**
    * Additional classes
    */
    className: PropTypes.string,

    /**
    * Additional Text, HTML, Component
    */
    children: PropTypes.oneOfType([
      PropTypes.node,
      PropTypes.string
    ])
  }
}

export class CmsLayoutBase extends React.PureComponent {
  render () {
    const {
      // Option
      children,
      className,
      // Default
      bgColor
    } = this.props

    return (
      <CmsLayoutBaseWrapper
        className={ClassNames(
          'cms-layout-base',
          // Option
          className
        )}
        theme={{
          // Default
          bgColor
        }}
      >
        {children}
      </CmsLayoutBaseWrapper>
    )
  }

  static Topbar = CmsLayoutTopbarBase
  static Sidebar = CmsLayoutSidebarBase
  static Content = CmsLayoutContentBase

  static defaultProps = {
    // Option
    // children: undefined,
    // className: undefined,
    // Default
  }

  static propTypes = { // TYPE > node, string, number, bool, array, object, symbol, func
    /**
    * Additional classes
    */
    className: PropTypes.string,

    /**
    * Additional Text, HTML, Component
    */
    children: PropTypes.oneOfType([
      PropTypes.node,
      PropTypes.string
    ])
  }
}
