import { DATA } from '../constants/data';

export const isValidResponse = (res) => {
  if (!res) {
    return false
  }
  if (res.message === 'success' || res.validation || res.success) {
    return res
  }
  else {
    return false
  }
}

// export const isValidResponse = (res) => {
//   if (res.validation || res.message === 'success') {
//     return res
//   }
// }

export const isValidEmail = (email = '') => {
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email.toLowerCase()) && isValidEmailEnglish(email);
}

export const isValidEmailEnglish = (string = '') => {
  const re = /^[A-Za-z0-9|\@|\.|\_]*$/;
  return re.test(string.toLowerCase());
}

export const isValidNumberNoPrefixZero = (number) => {
  // const re = /^-?\d*\.?\d*$/;
  const re = /^[1-9][0-9]*$/;
  return re.test(number);
}

export const isValidFloatNumber = (number) => {
  const re = /^\d*\.?\d*$/;
  return re.test(number);
}

export const isValidFloatNumber1Digit = (number) => {
  const re = /^\d*\.?\d?$/;
  return re.test(number);
}

export const isValidNumber = (number) => {
  // const re = /^-?\d*\.?\d*$/;
  const re = /^[0-9][0-9]*$/;
  return re.test(number);
}

export const isValidCountryPhoneLength = (countries, phone) => {
  let isPass = false;
  DATA.PHONE_PREFIX_LIST.forEach(e => {
    if (
      e.country.toLowerCase() === countries.toLowerCase() &&
      e.lengthList.indexOf(parseInt(phone).toString().length) >= 0
    ) {
      isPass = true;
    }
  })
  return isPass;
}