export const lang_th = {
  sideMenu: {
    points: 'คะแนน',
    myProfile: 'ข้อมูลส่วนตัว',
    settings: 'ตั้งค่า',
    shareWithFriends: 'แชร์ให้เพื่อน',
    termsAndConditions: 'เงื่อนไขและข้อกำหนด',
    logOut: 'ออกจากระบบ',
  },
  splash: {
    title: 'Growing your business with you',
    description: 'Engage . Showcase . Grow',
  },
  login: {
    loginByEmail: 'Login by Email',
    or: 'Or',
    loginByPhoneNumber: 'Login by Phone Number',
    orLoginWith: 'Or login with',
    reLogin: 'เพื่อความปลอดภัยของ Account โปรดเข้าสู่ระบบใหม่',
    incorrectMemberType: 'Member type ไม่ถูกต้อง',
  },
  loginEmail: {
    message: 'forgot password?',
    message_2: 'Choose again?',
  },
  loginPhone: {
    message: 'forgot password?',
    message_2: 'Choose again?',
  },
  welcome: {
    message_1: 'ยินดีต้อนรับ',
    message_2: 'ขอบคุณ ตอนนี้คุณได้เป็นสมาชิกของทรูบูล',
    message_3: 'และ',
    message_4: 'สามารถตรวจสอบคะแนนได้แล้ว',
  },
  referenceCode: {
    message_1: 'รหัสอ้างอิง',
    message_2: 'หมดอายุ',
  },
  referralAd: {
    message_1: 'วิธีการรับ',
    message_2: 'บัตรกำนัล',
    message_3: 'ต้องเป็นลูกค้าบลูสโคป',
    message_4: 'และสมาชิกทรูบูล ก่อน',
    message_5: '30 มิถุนายน 2564',
  },
  lineOaValidation: {
    message_1: 'เราได้เพิ่มช่องทางใหม่',
    message_2: 'สำหรับข่าวสารและผลประโยชน์',
    message_3: 'สามารถเข้าถึงโดยสแกน QR หรือ',
    message_4: 'กดปุ่มด้านล่าง',
    message_5: 'หรือ',
    message_6: 'ยินดีต้อนรับ',
  },
  voucherOutOfStock: {
    message_1: 'ขออภัย,',
    message_2: "คุณไม่เข้าเงื่อนไข",
    message_3: 'ได้สิทธิ์รับ E-Voucher',
    message_4: 'เพื่อให้ไม่พลาดการรับสิทธิพิเศษจากทาง',
    message_5: 'BlueScope ในครั้งถัดไป',
    message_6: 'คุณสามารถลงทะเบียนเข้าใช้เพื่อเป็น',
    message_7: 'TrueBlue Member ผ่านช่องทาง',
    message_8: 'LineOA ได้แล้วตั้งแต่วันนี้',
    message_9: 'เพื่อแจ้งเตือนคะแนนทรูบูล',
    message_10: 'ทุกยอดการซื้อ',
  },
  registerSuccess: {
    message_1: 'ลงทะเบียนสำเร็จ!',
    message_2: 'และคุณได้รับ',
    message_3: 'E-Voucher',
    message_4: 'E-Voucher จะถูกส่งไปยัง line ที่ท่านได้ลงทะเบียนไว้',
    message_5: 'ขออภัย E-Voucher',
    message_6: 'ครบจำนวนแล้ว',
  },
  authenticationSuccess: {
    message_1: 'ตรวจสอบสิทธิ์เรียบร้อยแล้ว!',
    message_2: 'โปรดกลับไปที่ TrueBlue',
  },
  register: {
    title_1: 'Sign Up',
    title_2: 'Verification OTP',
    message_1: 'Already have an account?',
    message_2: 'sent to',
  },
  home: {
    buttonViewMore: 'ดูเพิ่มเติม',
    tbPoints: 'คะแนนทรูบูล',
    tbRewards: 'ของรางวัล',
    estimator: 'คำนวนวัสดุ',
    newsAndPromotions: 'ข่าว & โปรโมชั่น',
    myProjects: 'โปรเจคของฉัน',
    products: 'ข้อมูลสินค้า',
    findDealer: 'ค้นหาโรงรีด',
    knowledgeBase: 'แหล่งความรู้',
    purchaseHistory: 'คำสั่งซื้อที่ผ่านมา',
    orderInquiry: 'คำถาม',
    warranty: 'ใบรับประกัน',
    training: 'TRAINING',
    your: 'ค่า',
    length: 'ความยาว',
    metre: 'เมตร',
    purchasing: 'การซื้อ',
    cumulativeAmountSince: 'ยอดสะสมตั้งแต่',
    tbMember: 'สมาชิกทรูบูล',
    rankInformation: 'ข้อมูลระดับ',
    moreDetail: 'รายละเอียด',
    moreDetailExtra: 'รายละเอียดเพิ่มเติม',
    noRank: 'ไม่มีข้อมูลระดับ',
    luckyDraw: 'ลุ้นรางวัล',
    willExpireOn: 'จะหมดอายุในวันที่',
    point: 'คะแนน',
    checkPoint: 'ตรวจสอบคะแนน',

  },
  myProfile: {
    title: 'ข้อมูลส่วนตัว',
    updateProfile: 'ปรับปรุงข้อมูล',
    changePassword: 'เปลี่ยนรหัสผ่าน',
  },
  changePassword: {
    title: 'เปลี่ยนรหัสผ่าน',
  },
  forgotPassword: {
    title: 'ลืมรหัสผ่าน',
    labelEnterEmail: 'กรุณากรอก อีเมล',
    labelEnterPhone: 'กรุณากรอก เบอร์โทรศัพท์มือถือ',
  },
  resetPassword: {
    title: 'ตั้งรหัสผ่านใหม่',
  },
  setting: {
    title: 'แก้ไขข้อมูล',
    language: 'เปลี่ยนภาษา',
    notification: 'การแจ้งเตือน',
  },
  settingNoti: {
    title: 'การแจ้งเตือน',
    orderInquiry: 'คำถาม',
    newsAndPromotions: 'ข่าว & โปรโมชั่น',
  },
  settingLanguage: {
    title: 'ภาษา',
    chooseLanguage: 'เลือกภาษา',
    pleaseSelectLanguage: 'กรุณาเลือกภาษา',
  },
  termPolicy: {
    title: 'เงื่อนไข',
  },
  loyaltyPoint: {
    title: 'คะแนนทรูบูล',
    points: 'คะแนน',
    expire: 'หมดอายุ',
    expireIn: '(ที่จะหมดอายุในไตรมาสนี้)',
    tab: {
      earnedPoints: {
        title: 'คะแนนที่ได้รับ',
      },
      redeemedPoints: {
        title: 'คะแนนที่ถูกใช้',
      },
    },
    tbPoints: 'คะแนนทั้งหมด',
    numberOfRewards: 'จำนวนรางวัล',
  },
  rewardCat: {
    title: 'คะแนนทรูบลู',
    blank: 'ไม่พบข้อมูล',
    new: 'ใหม่',
    points: 'คะแนน',
  },
  reward: {
    title: 'ของรางวัล',
  },
  rewardSearch: {
    title_1: 'ผลการค้นหา',
    title_2: 'ค้นหา',
    rewardName: 'ชื่อของรางวัล',
    searchResult: 'ผลการค้นหา',
  },
  rewardDetail: {
    points: 'คะแนน',
  },
  estimator: {
    title: 'คำนวนวัสดุ',
    roofType: {
      gableRoof: 'ทรงจั่ว',
      hipRoof: 'ทรงปั้นหยา',
    },
    profileType: {
      tile: 'กระเบื้อง',
      sheet: 'เมทัลชีท',
    },
    notes: 'Notes',
    noteWidth: 'กว้าง: 4 : 12 เมตร',
    notePitch: 'องศา: 25 : 35 องศา',
    noteOverhang: 'ชายคา <= 500 มม.',
    buttonEstimation: 'คำนวนวัสดุ',
    footer_1: 'คำแนะนำ : การคำนวณนี้เป็นการประมาณราคาเท่านั้น',
    footer_2: 'เกิดจากข้อมูลที่ท่านได้ให้ไว้',
    footer_3: 'บริษัทฯ ไม่ขอรับผิดชอบ และไม่สามารถสามารถรับประกันความถูกต้อง',
    footer_4: 'ของผลที่คำนวนในทุกกรณี ',
    footer_5: 'ทากท่านมีข้อสงสัย สามารถอ่านเพิ่มเติมได้ที่',
    footer_6: 'ข้อกำหนดและความรับผิดชอบ',
    footer_7: 'ของการใช้งาน',
  },
  estimatorSummary: {
    title: 'ผลจากการคำนวน',
    quantityEstimate: 'ปริมาณจากการคำนวน',
    congratulations: 'ยินดีด้วย !!',
    note_1: 'ท่านเหลืออีกแค่ 1 ขั้นตอน',
    note_2: 'โปรดติตต่อ ศูนย์เมทัลชีท บลูสโคป ที่ใกล้ท่าน',
    note_3: 'เสร็จสิ้น',
    category: {
      title_1: 'Category : Roof',
      note_1: 'number of screw for roofing sheet will depend on profile type.',
      title_2: 'Category : Roof Structure',
      title_3: 'Category : Accessory',
      type: 'ชนิด',
      profile: 'รูปลอน',
      qty: 'ปริมาณ',
      unit: 'หน่วย',
      notes: 'หมายเหตุ',
    }
  },
  newsAndPromotions: {
    title: 'ข่าว & โปรโมชั่น',
    tab: {
      news: {
        title: 'ข่าว',
      },
      promotions: {
        title: 'โปรโมชั่น',
      },
    },
  },
  newsAndPromotionsComment: {
    title: 'ความคิดเห็น'
  },
  newsAndPromotionsWriteComment: {
    title: 'ความคิดเห็น',
    specifyTitle: 'กรุณาใส่ชื่อเรื่อง',
    specifyComment: 'กรุณาใส่ความคิดเห็น',
    characters: 'จำนวนตัวอักษร',
  },
  newsAndPromotionsSearch: {
    title: 'ค้นหา',
    searchResult: 'ผลการค้นหา :',
  },
  myProjects: {
    title: 'โปรเจคของฉัน',
  },
  projectsAdd: {
    title: 'โปรเจคของฉัน',
  },
  projectsEdit: {
    title: 'แก้ไขโปรเจค',
  },
  projectsSearch: {
    title_1: 'ผลการค้นหา',
    title_2: 'ค้นหา',
    searchProject: 'ค้นหาโปรเจค',
    searchResult: 'ผลการค้นหา :',
  },
  productCatalogs: {
    title: 'กลุ่มสินค้า',
  },
  findDealer: {
    title: 'ค้นหาโรงรีด',
    findDealer: 'ค้นหาโรงรีด',
    selectDealer: {
      title: 'กรุณาเลือกประเภท',
      bad: 'ศูนย์เมทัลชีทบลูสโคป',
      or: 'โรงรีดอื่นๆ',
      distributor: 'Distributor',
      tokoStore: 'Toko / Store BlueScope Zacs™',
      indoBad: 'BlueScope Authorized Dealer',
    }
  },
  findDealerType: {
    title: 'ประเภทของโรงรีด',
    nearestDealers: 'หาโรงรีดที่ใกล้ที่สุด',
    byName: 'ค้นหาโรงรีดจากชื่อ',
    byArea: 'ค้นหาโรงรีดจากพื้นที่',
    or: 'หรือ',
  },
  findDealerSearch: {
    title: 'โรงรีดที่อยู่ใกล้',
    dealerCard: {
      buttonGetDirection: 'ค้นหาเส้นทาง',
      buttonViewDetail: 'ดูรายละเอียด',
    }
  },
  findDealerSearchByName: {
    title: 'ค้นหาโรงรีดจากชื่อ',
    byName: 'ค้นหาโรงรีดจากชื่อ',
  },
  findDealerSearchByArea: {
    title: 'ค้นหาโรงรีดจากพื้นที่',
  },
  knowledgeBase: {
    title: 'แหล่งความรู้'
  },
  knowledgeBaseDetail: {
    title: 'แหล่งความรู้',
    tab: {
      description: {
        title: 'คำอธิบาย',
      },
      documentsDownload: {
        title: 'เอกสาร / ดาวน์โหลด',
      },
    },
  },
  purchaseHistory: {
    title_1: 'ผลการค้นหา',
    title_2: 'คำสั่งซื้อที่ผ่านมา',
    totalSales: 'การขายทั้งหมด',
    DataNotFound: 'ไม่พบข้อมูล',
  },
  purchaseHistorySearch: {
    title: 'วันที่',
  },
  purchaseHistoryDetail: {
    title: 'คำสั่งซื้อที่ผ่านมา',
  },
  orderInquiry: {
    title: 'คำถาม',
    category: {
      bad: 'ดีลเลอร์',
      or: 'โรงรีดอื่นๆ',
    },
    selectDealer: 'ดีลเลอร์',
    result: 'ผลการค้นหา',
    orderInquiry: 'คำถาม',
    allPreviousOrderInquiry: 'คำถามที่ผ่านมา',
    dataNotFound: 'ไม่พบข้อมูล',
  },
  orderInquirySearch: {
    title: 'ค้นหา',
    category: {
      bad: 'ดีลเลอร์',
      or: 'โรงรีดอื่นๆ',
    },
    selectDealer: 'ดีลเลอร์',
    suggestions: 'คำแนะนำ',
  },
  orderInquiryAdd: {
    title: 'สร้างคำถาม',
  },
  warranty: {
    title: 'ใบรับประกัน',
  },
  warrantySearchByDate: {
    title: 'ค้นหาจากวันที่',
  },
  warrantySearchByName: {
    title_1: 'ผลการค้นหา',
    title_2: 'ค้นหา',
    warranty: 'เลขใบรับประกัน, ชื่อใบรับกระกัน',
    salesADProduct: 'เลขที่การขาย, ดีลเลอร์, ชื่อสินค้า',
    searchResult: 'ผลการค้นหา',
  },
  warrantySearch: {
    title: 'ผลการค้นหา',
    totalOrders: 'การสั่งซื้อทั้งหมด',
  },
  warrantyProduct: {
    dataNotFound: 'ไม่พบข้อมูล',
  },
  warrantyProductItem: {
    issuedDate: 'วันที่สร้าง',
    product: 'สินค้า',
    thickness: 'ความหนา',
    length: 'ความยาว',
    warrantyID: 'เลขที่ใบรับประกัน',
  },
  termsAndConditions: {
    title: 'ข้อกำหนดและเงื่อนไข',
  },
  verify: {
    title1: 'ตั้งค่าคำถามเพื่อยืนยันสิทธิ์',
    title2: 'ยืนยันสิทธิ์ด้วยคำถาม',
    title3: 'ยืนยันสิทธิ์ด้วยเบอร์โทรศัพท์',
    title4: 'Verify Methods',
    question1: 'ชื่อเล่นของคุณ?',
    question2: 'สัตว์ที่คุณชื่นชอบคืออะไร?',
    question3: 'จังหวัดที่คุณเกิดคืออะไร?',
  },
  container: {
    inputOtp: {
      title: 'Verification OTP',
      message: 'sent to',
      message_2: 'OTP is incorrect, Please re-send again',
    },
  },
  modal: {
    changeLanguage: {
      title: 'เปลี่ยนภาษา',
      message: 'ภาษาได้ถูกเปลี่ยนแล้ว',
    },
    editProfile: {
      title: 'แก้ไขข้อมูลส่วนตัว',
      message_1: 'ข้อมูลส่วนตัวได้ถูกปรับปรุงแล้ว',
      message_2: 'ข้อมูลยังไม่ถูกต้อง กรุณาลองอีกครั้ง',
      message_3: 'เกิดข้อผิดพลาด',
    },
    changePassword: {
      title: 'รหัสถูกเปลี่ยนเรียบร้อยแล้ว',
      message: 'กรุณาเข้าระบบด้วยรหัสใหม่',
      fail: 'ไม่สำเร็จ',
      passwordNotMatch: 'รหัสไม่ตรงกัน',
      passwordIncorrectFormat: 'รูปแบบรหัสไม่ถูกต้อง',
      changeSuccess: 'รหัสถูกเปลี่ยนเรียบร้อยแล้ว!',
      nextLogin: 'กรุณาเข้าระบบด้วยรหัสใหม่',
    },
    commentPosted: {
      title: 'ข้อความได้ถูกโพสแล้ว',
      message: 'ขอบคุณสำหรับข้อคิดเห็น',
    },
    confirmation: {
      title: 'ยืนยัน',
      message: 'คุณต้องการลบข้อมูล?',
      message_2: 'คุณแน่ใจที่ต้องการลบข้อมูล?',
      message_2_2: 'โปรเจคที่เลือก?',
    },
    warrantyDetail: {
      projectName: 'ชื่อโปรเจค',
      address: 'ที่อยู่',
      quantity: 'จำนวน',
      color: 'สี (ถ้ามี)',
    },
    newVersion: {
      title_1: 'โปรแกรม ทรูบลู เวอร์ชั้นใหม่',
      title_2: 'พร้อมแล้ว',
    },
    forgotPassword: {
      titleSendResetLink: 'ส่งรหัสใหม่!',
      messageCheckEmail: 'โปรดตรวจสอบ อีเมล ของท่าน',
      titleSendOtp: 'รหัส OTP ถูกส่งแล้ว!',
      messageCheckNotification: 'โปรดตรวจสอบ ข้อความในมือถือของท่าน',
    },
    resetPassword: {
      title: 'รหัสใหม่ได้ถูกแก้ไขเรียบร้อย!',
      message_1: 'โปรดทดลอง รหัสใหม่ของท่าน',
      message_2: 'ในการเข้าสู่ระบบครั้งต่อไป',
    },
    iosAddToHomeScreen: {
      title_1: 'Add to home screen',
      title_2: 'by share',
    },
    socialInputPhone: {
      title: 'Please fill your phone number',
    },
    notiTnc: {
      title_1: 'การใช้งาน ทรูบลู',
      title_2: 'ข้อกำหนดและเงื่อนไข',
      message_1: 'กรุณาอ่าน และกดยอมรับ',
      message_2: 'ข้อกำหนดและเงื่อนไข',
      message_3: 'ก่อนการใช้งาน',
    },
    otpSend: {
      title: 'รหัสผ่าน OTP ได้ถูกส่งไปยังมือถือที่คุณได้ให้ไว้',
      description: 'กรุณาตรวจสอบข้อความของคุณ',
    },
    authentication: {
      title: 'ลิงก์การตรวจสอบสิทธิ์ถูกส่งให้เบอร์โทรศัพท์ของคุณทาง SMS',
      description1: 'โปรดกดลิงก์เข้าสู่ระบบ ภายในเวลา 3 นาที',
      description2: 'รหัสอ้างอิง คือ',
      description3: 'อย่าปิดหน้าต่างนี้จนกว่าการตรวจสอบสิทธิ์จะเสร็จสิ้น',
      description4: 'หรือ',
      link: 'ส่งลิงก์การตรวจสอบสิทธิ์ใหม่',
    },
  },
  component: {
    component: {
      likes: 'ไลค์',
      comments: 'ความเห็น',
      buttonComment: 'ข้อคิดเห็น',
    }
  },
  field: {
    buttonOK: 'ตกลง',
    buttonClose: 'ปิด',
    buttonSearch: 'ค้นหา',
    buttonClear: 'ล้าง',
    buttonSave: 'บันทึก',
    buttonSaveAndChange: 'บันทึก & เปลี่ยนแปลง',
    buttonSaveAsPhoto: 'บันทุกรูปภาพ',
    buttonSharePhoto: 'แชร์รูปภาพ',
    buttonSubmit: 'ส่งความเห็น',
    buttonAddNewProject: 'เพิ่มโครงการใหม่',
    buttonEditProject: 'แก้ไขโครงการ',
    buttonDeleteProject: 'ลบโครงการ',
    buttonAddPhoto: 'เพิ่มรูปภาพ',
    hintButtonAddPhotoLineOne: 'เลือกไฟล์ .jpg, .jpeg, .png และ',
    hintButtonAddPhotoLineTwo: 'ขนาดไฟล์ไม่เกิน 1MB.',
    buttonDelete: 'ลบ',
    buttonCancel: 'ยกเลิก',
    buttonFilter: 'กรอง',
    buttonAddNewOrderInquiry: 'เพิ่มคำถามใหม่',
    buttonMoreDetail: 'เพิ่มเติม',
    buttonUpdate: 'ปรับปรุง',
    buttonSend: 'ส่ง',
    buttonContinue: 'ดำเนินการต่อ',
    buttonContinueRegister: 'ดำเนินการลงทะเบียนต่อ',
    buttonSignUp: 'Sign up',
    buttonAgree: 'ยอมรับ',
    buttonReSend: 'Re-send',
    buttonLogin: 'Login',
    buttonBack: 'Back',
    buttonConfirm: 'ยืนยัน',
    buttonApprove: 'ยอมรับ',
    buttonToLogin: 'กลับหน้าเข้าสู่ระบบ',
    buttonRegisterNow: 'ลงทะเบียนตอนนี้',
    buttonGoToTrueBlue: 'ไปที่ทรูบูล',
    buttonBlueScopeThailand: 'บลูสโคป ประเทศไทย',
    labelNew: 'ใหม่',
    labelSample: 'ตัวอย่าง',
    firstname: 'ชื่อ',
    firstnamePlaceholder: 'ใส่ชื่อ',
    lastname: 'นามสกุล',
    lastnamePlaceholder: 'ใส่นามสกุล',
    nickname: 'ชื่อเล่น',
    nicknamePlaceholder: 'ใส่ชื่อเล่น',
    company: 'ชื่อบริษัท',
    companyPlaceholder: 'ใส่ชื่อบริษัท',
    type: 'ชนิด',
    typePlaceholder: 'กรุณาเลือกชนิด',
    phoneNumber: 'เบอร์โทร',
    mobilePhoneNumber: 'Mobile Phone Number',
    email: 'อีเมลล์',
    emailPlaceholder: 'ใส่อีเมลล์',
    birthDate: 'วันเกิด',
    birthDatePlaceholder: 'กรุณาเลือกวันเดือนปีเกิด',
    address: 'ที่อยู่ สำหรับติดต่อ และส่งของ',
    addressPlaceholder: 'ใส่ที่อยู่ สำหรับติดต่อ และส่งของ',
    province: 'จังหวัด',
    provincePlaceholder: 'กรุณาเลือกจังหวัด',
    city: 'เมือง',
    cityPlaceholder: 'กรุณาเลือกเมือง',
    district: 'อำเภอ',
    districtPlaceholder: 'กรุณเลือกอำเภอ',
    subDistrict: 'ตำบล',
    subDistrictPlaceholder: 'กรุณาเลือกตำบล',
    subDistrictSelectPlaceholder: 'กรุณาเลือกตำบล',
    alley: 'Alley/Lane',
    alleyPlaceholder: 'กรุณาใส่ซอย',
    group: 'Group/Hamlet',
    groupPlaceholder: 'กรุณาใส่หมู่บ้าน',
    street: 'Street',
    streetPlaceholder: 'ถนน',
    ward: 'Ward',
    wardPlaceholder: 'ท้องที่',
    postCode: 'รหัสไปรษณีย์',
    postCodePlaceholder: 'ใส่รหัสไปรษณีย์',
    postCodeSelectPlaceholder: 'กรุณาเลือกรหัสไปรษณีย์',
    country: 'ประเทศ',
    countryPlaceholder: 'ใส่ประเทศ',
    password: 'รหัสผ่าน',
    passwordHint: '8 ถึง 24 ตัวอักษร',
    oldPassword: 'รหัสผ่านเก่า',
    newPassword: 'รหัสผ่านใหม่',
    newPasswordHint: '8 ถึง 24 ตัวอักษร',
    confirmPassword: 'ยืนยันรหัสผ่าน',
    roofType: 'รูปแบบหลังคา',
    roofTypePlaceholder: 'เลือกรูปแบบหลังคา',
    profileType: 'ชนิดหลังคา',
    profileTypePlaceholder: 'เลือกชนิดหลังคา',
    widthUnit: 'กว้าง (ม)',
    lengthUnit: 'ยาว (ม)',
    heightUnit: 'สูง (ม)',
    overhangUnit: 'ชายคา',
    title: 'เรื่อง',
    titlePlaceholder: 'ใส่เรื่อง',
    comment: 'ความคิดเห็น',
    commentPlaceholder: 'คุณมีความคิดเห็นอย่างไร',
    newsAndPromotionsPlaceholder: 'ค้นหา ข่าวและ โปรโมชั่น',
    projectName: 'ชื่อโปรเจค',
    location: 'สถานที่',
    areaSizeProject: 'ขนาดโปรเจค',
    product: 'สินค้า',
    description: 'คำอธิบาย',
    descriptionPlaceholder: 'ใส่คำอธิบาย',
    selectDealerPlaceholder: 'กรุณาเลือกโรงรีด',
    selectProvincePlaceholder: 'กรุณาเลือกจังหวัด',
    selectAmphoePlaceholder: 'เลือก เขต/อำเภอ',
    filterStartDateEndDatePlaceholder: 'ค้นหาจากวันที่',
    startDatePlaceholder: 'วันเริ่มต้น',
    endDatePlaceholder: 'วันสุดท้าย',
    productGroup: 'ชนิดสินค้า',
    coilName: 'ชื่อคอยล์',
    thickness: 'ความหนา',
    color: 'สี',
    length: 'ความยาว',
    meter: 'เมตร',
    points: 'คะแนน',
    points_2: 'คะแนน',
    where: 'สถานที่ซื้อ',
    status: 'สถานะ',
    trasactionID: 'เลขที่คำสั่งซื้อ',
    selectCategoryPlaceholder: 'เลือกกลุ่มสินค้า',
    subject: 'หัวข้อ',
    subjectPlaceholder: 'ใส่หัวข้อ',
    selectAreaPlaceholder: 'เลือกพื้นที่',
    phoneIsIncorrectFormat: 'Phone is incorrect format.',
    agreeTermsAndConditions: 'Agree Terms and conditions',
    questionPlaceholder: 'ใส่คำตอบ',
    birthDayPlaceholder: 'เลือกวันเกิด',
    customerTypeFixer: 'ช่างติดตั้ง/ผู้รับเหมา',
    customerTypeHardware: 'ร้านค้า/ร้านขายวัสดุก่อสร้าง',
    customerTypeHomeowner: 'เจ้าของบ้าน/เจ้าของโครงการ',
    customerTypeRollFormer: 'โรงรีดซื้อแผ่นไปขายต่อ พนักงานของร้าน ช่างติดตั้งของร้าน',
    customerTypeOthers: 'อื่นๆ เช่น หน่วยงานราชการ ทหาร',
    customerTypeArchitect: "สถาปนิค / ผู้ออกแบบ / ที่ปรึกษา",
    customerTypeEngineer: "วิศวกร"
  },
  message: {
    fail: 'ล้มเหลว',
    pleaseInputPhone: 'โปรดใส่เบอร์มือถือ',
    pleaseInputAllField: 'โปรดใส่ข้อมูลทุกช่อง',
    pleaseInputAllRequiredField: 'Please insert all required field',
    somethingWrong: 'เกิดข้อผิดพลาด กรุณาลองอีกครั้ง',
    cannotGetYourCurrentLocation: 'ไม่สามารถระบุที่ตั้งของคุณได้',
    pleaseSelectStartDateAndEndDate: 'กรุณาเลือกวันที่',
    invalidDateFormat: 'รูปแบบวันที่ไม่ถูกต้อง',
    pleaseSelectCategoryAndDealer: 'กรุณาเลือกกลุ่มสินค้าและดีลเลอร์',
    yourOrderInquiryHasBeenSent: 'คำถามของคุณได้ถูกส่งแล้ว',
    pleaseSpecifySubject: 'กรุณเลือกหัวข้อ',
    pleaseSpecifyDescription: 'กรุณาระบุคำอธิบาย',
    browserCantShare: `บราวเซอร์ไม่สามารถทำการแชร์ได้ กรุณาแชร์ผ่าน มือถือของท่าน`,
    phoneDoesntExist: 'เบอร์มือถือนี้ ยังไม่ได้ลงทะเบียน',
    passwordNotMatch: 'รหัสไม่ตรงกับที่คุณได้ตั้งไว้',
    passwordIncorrectFormat: 'รหัสไม่ถูกตัอง กรุณาลองใหม่',
    dataNotFound: 'ไม่พบข้อมูล',
    widthOutOfCondition: 'Width out of range',
    pitchOutOfCondition: 'Pitch out of range',
    invalidEmailFormat: 'อีเมลไม่ถูกต้อง',
    pleaseInputFirstnameAndLastname: 'กรุณาใส่ชื่อและนามสกุล',
    phoneInCorrectFormat: 'เบอร์มือถือไม่ถูกต้อง',
    emailInCorrectFormat: 'Email is incorrect format.',
    nameInCorrectFormat: 'Name is incorrect format.',
    emailAddressAlreadyExists: 'Email address already exists.',
    loginBySocialError: 'Login by social error.',
    cantGetUserInfo: "Can't get user info.",
    phoneEmailAlreadyExists: 'Phone/Email is already exist.',
    phoneAlreadyExists: 'Phone is already exist.',
    sendOtpError: 'Send otp error.',
    getSecretOtpError: 'Get secret otp error.',
    otpIncorrect: 'OTP is incorrect, Please re-send again.',
    pleaseEnterPassword: 'Please Enter Password',
    pleaseEnterPhone: 'Please Enter Phone',
    pleaseEnterPhoneAndPassword: 'Please Enter Phone and Password',
    wrongUsernameOrPassword: 'Wrong username or password.',
    browserNotSupport: `This browser not support please use Chrome or Safari.`,
    phoneNotMatch: "เบอร์มือถือนี้ ยังไม่ได้ลงทะเบียน",
    phoneNotMatch1: 'ถ้าคุณเคยเป็นสมาชิกทรูบูล โปรดติดต่อ',
    phoneNotMatch2: 'ศูนย์บลูสโคปเมทัลชีท',
    phoneNotMatch3: 'หากคุณยังไม่ได้เป็นสมาชิก',
    wrongAnswer: 'คำตอบผิด',
    passwordAndConfirmPasswordNotMatch: "รหัสผ่านไม่ตรงกัน",
  },
  luckyDraw: {
    title: 'ลุ้นรางวัล',
    termsAndConditions: 'ข้อตกลงและเงื่อนไข',
    agreeTermsAndConditions: 'ยอมรับข้อตกลงและเงื่อนไข',
    dontShowThisMessageAgainNextTime: `อย่าแสดงข้อความนี้อีกในครั้งต่อไป`,
    accept: 'ยอมรับ',
    winPrizes: 'ลุ้นรางวัล',
    awardAnnouncement: 'ประกาศรางวัล',
    noLuckyDrawEvent: 'ไม่มีอีเว้นท์',
    usePointToWinPrizes: 'ใช้คะแนนแลกสิทธิ์ลุ้นรางวัล',
    point: 'คะแนน',
    right: 'สิทธิ์',
    numberOfUsedPoints: 'จำนวนคะแนนที่ใช้',
    yourPointIsNotEnough: 'คะแนนของคุณไม่เพียงพอ',
    buttonWinPrizes: 'ลุ้นรางวัล',
    reward: 'รางวัล  ',
    viewDetail: 'ดูรายละเอียด',
    readTermsAndConditions: 'อ่านข้อกำหนดและเงื่อนไข',
    announcement: 'ประกาศ',
    announcementOfNameWinners: 'ประกาศรายชื่อผู้ได้รับรางวัล',
    campaign: 'แคมเปญ',
    dateFrom: 'ตั้งแต่วันที่',
    dateUntil: 'ถึง',
    showMore: 'เพิ่มเติม',
    awardAnnounced: 'ประกาศผลรางวัล!',
    youCanClickBtnBelowToSeeYourListName: 'คุณสามารถคลิกปุ่มด้านล่างเพื่อไปดูรายชื่อ ของคุณ',
    buttonCheckMyName: 'ตรวจสอบชื่อของฉัน!',
    expirePoint: 'คะแนนใกล้หมดอายุ',
    toExpire: 'ที่จะหมดอายุ',
    points: 'คะแนน',
    dontWasteYourPoint: `ใช้คะแนน ก่อนจะหมดอายุ`,
    useYourPointToExchangeForAChanceToWinPrizes: 'ใช้คะแนนของคุณเพื่อแลกรับรางวัล',
    buttonGoToWinPrizes: 'ใช้คะแนน เพื่อลุ้นรางวัล',
    total: 'ทั้งหมด',
    youUseYourRightToWinPrizes: 'คุณใช้สิทธิ์ลุ้นรับรางวัล',
    goodLuckWithWinnigPrizes: 'แลกคะแนนสำเร็จ',
    youHave: 'คุณเหลือ',
    pointLeft: 'คะแนน',
    awardCount: 'รางวัลที่ได้รับ',
    showLess: "แสดงน้อยลง",
    rewardTitle: 'จำนวน [quantity] รางวัล',
    campaignDurationDate: 'เริ่ม [startDate] ถึง [endDate]',
    campaignLuckyDrawDate: 'จับรางวัลวันที่',
    announcementRewardDetail: '[rewardDetail] ([quantity] รางวัล)',
    noAwardAnnouncement: 'ไม่มีการประกาศรางวัล',
    youHaveAllThePoint: 'คุณมีคะแนนทั้งหมด :',
    rewardTitleQuantity: 'จำนวน [quantity] รางวัล',
    youHaveAMaximumRedeemablePointOfPoint: 'คุณมีคะแนนสูงสุดที่แลกได้ [point] คะแนน',

  },
}