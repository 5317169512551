import React from 'react';
import {
  getRouteParams,
  isValidResponse,
  redirectData,
} from '../../helpers/functions';
import { LoginContainerConnected } from '../../containers/LoginContainer/LoginContainer';
import { isShowLoading, onOpenAlert } from '../../helpers/functions/main';
import { getLanguage } from '../../helpers/functions/language';
import { DATA } from '../../helpers/constants/data';
import axios from 'axios';
import config from '../../config';
import { ROUTE_PATH } from '../../helpers';
import moment from 'moment';
import { ModelAddress } from '../../models/ModelAddress';
import { convertString, getCountryPhoneByCountry, getCountryType, getPhoneRemovePrefix } from '../../helpers/functions/data';
import { getUserBy } from '../../helpers/services/users';
import { getLocalStorage, removeLocalStorage } from '../../helpers/functions/localStorage';
import { LOCAL_STORAGE } from '../../helpers/constants/localStorage';

export class LoginByLinePhonePage extends React.Component {
  constructor(props) {
    super(props);
    const ssoId = getRouteParams(props, 'id');
    console.log('ssoId', ssoId);
    this.state = {
      ssoId,
    };
  }

  componentDidMount() {
    if (getLocalStorage(LOCAL_STORAGE.IS_TOAST_RE_LOGIN)) {
      onOpenAlert({
        type: DATA.ALERT_TYPE.ERROR,
        title: getLanguage('message.fail'),
        message: getLanguage('login.reLogin'),
      });
      removeLocalStorage(LOCAL_STORAGE.IS_TOAST_RE_LOGIN);
    }
    if (getLocalStorage(LOCAL_STORAGE.IS_TOAST_INCORRECT_MEMBER_TYPE)) {
      onOpenAlert({
        type: DATA.ALERT_TYPE.ERROR,
        title: getLanguage('message.fail'),
        message: getLanguage('login.incorrectMemberType'),
      });
      removeLocalStorage(LOCAL_STORAGE.IS_TOAST_INCORRECT_MEMBER_TYPE);
    }
  }

  login = {
    getMobileUserDataForRegister: (userData) => {
      let addressGroup = {};
      try {
        addressGroup = new ModelAddress(userData.address);
      } catch { }
      return {
        userId: userData.id,
        profileImage: userData.image_url || '',
        firstName: userData.first_name || '',
        lastName: userData.last_name || '',
        nickName: (userData.nick_name || userData.nick_name__c) || '',
        type: userData.type || '',
        phone: userData.phone || '',
        email:
          userData.email === 'null'
            ? ''
            : userData.email || '',
        birthDate: userData.birth_date
          ? moment(userData.birth_date).format(
            'YYYY-MM-DD'
          )
          : undefined,
        address: addressGroup.address || '',
        selectedProvince:
          addressGroup.selectedProvince || '',
        selectedProvinceText:
          addressGroup.selectedProvinceText || '',
        selectedCity: addressGroup.selectedCity || '',
        selectedCityText:
          addressGroup.selectedCityText || '',
        district: addressGroup.district || '',
        subDistrict: addressGroup.subDistrict || '',
        alley: addressGroup.alley || '',
        group: addressGroup.group || '',
        street: addressGroup.street || '',
        ward: addressGroup.ward || '',
        addressType: getCountryType(userData.countries),
        postCode: userData.post_code || '',
        questionInput1: convertString(
          userData.security_answer_1
        ),
        questionInput2: convertString(
          userData.security_answer_2
        ),
        questionInput3: convertString(
          userData.security_answer_3
        ),
        isUpdateUserData: true,
      }
    },
    getTrueblueUserDataForRegister: (userData) => {
      let addressGroup = {};
      try {
        addressGroup = new ModelAddress(userData.contact_address);
      } catch { }
      return {
        firstName: userData.first_name || '',
        lastName: userData.last_name || '',
        nickName: (userData.nick_name || userData.nick_name__c) || '',
        type: userData.type || '',
        phone: getPhoneRemovePrefix(userData.phone || ''),
        email:
          userData.email === 'null'
            ? ''
            : userData.email || '',
        birthDate: userData.date_of_birth
          ? moment(userData.date_of_birth).format(
            'YYYY-MM-DD'
          )
          : undefined,
        address: addressGroup.address || '',
        selectedProvince:
          addressGroup.selectedProvince || '',
        selectedProvinceText:
          addressGroup.selectedProvinceText || '',
        selectedCity: addressGroup.selectedCity || '',
        selectedCityText:
          addressGroup.selectedCityText || '',
        district: addressGroup.district || '',
        subDistrict: addressGroup.subDistrict || '',
        alley: addressGroup.alley || '',
        group: addressGroup.group || '',
        street: addressGroup.street || '',
        ward: addressGroup.ward || '',
        addressType: getCountryType(userData.country__c || ''),
        postCode: userData.contact_post_code || '',
        questionInput1: convertString(
          userData.security_answer_1
        ),
        questionInput2: convertString(
          userData.security_answer_2
        ),
        questionInput3: convertString(
          userData.security_answer_3
        )
      }
    },
    handleLogin: (state = {}, callbackSuccess = () => { }) => {
      const { ssoId } = this.state;
      if (state.username === '') {
        onOpenAlert({
          type: DATA.ALERT_TYPE.ERROR,
          title: getLanguage('message.fail'),
          message: getLanguage('message.pleaseEnterPhone'),
        });
      } else {
        isShowLoading(true);
        const phonePrefix = getCountryPhoneByCountry(state.countries);
        axios
          .post(`${config.api.base}/api/login/line`, {
            line_id: ssoId,
            phone: parseInt(state.username),
          })
          .then(
            async (response) => {
              const res = response.data;
              // 0 = login, 1 = no customer, 2 = customer no line id, 3 = member type incorrect 
              const statusCode = res.status_code;
              console.log('statusCode', statusCode);
              if (statusCode === 1) {
                //check trueblue phone
                axios
                  .post(`${config.api.trueblue}/customer/search`, {
                    page: 1,
                    limit: 1,
                    sort: 'id desc',
                    phone: `${phonePrefix}${state.username}`,
                  })
                  .then(
                    async (responseUserSearch) => {
                      isShowLoading(false);
                      const resUserSearch = responseUserSearch.data;
                      if (isValidResponse(resUserSearch)) {
                        if (resUserSearch.data.length) {
                          const userData = resUserSearch.data[0];
                          console.log('userData', userData);
                          redirectData(`${ROUTE_PATH.REGISTER.LINK}/${ssoId}`, {
                            data: this.login.getTrueblueUserDataForRegister(userData)
                          });
                        }
                        else {
                          //alert register
                          callbackSuccess(response);
                        }
                      }
                      else {
                        onOpenAlert({
                          type: DATA.ALERT_TYPE.ERROR,
                          title: getLanguage('message.fail'),
                          message: (resUserSearch || {}).message,
                        });
                      }
                    },
                    (error) => {
                      console.log(error);
                      isShowLoading(false);
                      onOpenAlert({
                        type: DATA.ALERT_TYPE.ERROR,
                        title: getLanguage('message.fail'),
                        message: (error || {}).message,
                      });
                    });
              } else if (statusCode === 0 || statusCode === 2) {
                // axios
                //   .post(`${config.api.base}/api/users`, {
                //     phone: parseInt(state.username),
                //   })
                getUserBy({ phone: parseInt(state.username) })
                  .then(
                    async (responseUserSearch) => {
                      const resUserSearch = responseUserSearch;
                      // const resUserSearch = responseUserSearch.data;
                      if (resUserSearch) {
                        // if (isValidResponse(resUserSearch)) {
                        isShowLoading(false);
                        const userData = resUserSearch;
                        // const userData = resUserSearch.data;
                        console.log('userData', userData);
                        if (statusCode === 0) {
                          response.data.data = userData;
                          //send otp login
                          callbackSuccess(response);
                        } else {
                          redirectData(`${ROUTE_PATH.REGISTER.LINK}/${ssoId}`, {
                            data: this.login.getMobileUserDataForRegister(userData)
                          });
                        }
                      } else {
                        isShowLoading(false);
                        onOpenAlert({
                          type: DATA.ALERT_TYPE.ERROR,
                          title: getLanguage('message.fail'),
                          message: getLanguage('message.somethingWrong'),
                        });
                      }
                    },
                    (error) => {
                      console.log(error);
                      isShowLoading(false);
                      onOpenAlert({
                        type: DATA.ALERT_TYPE.ERROR,
                        title: getLanguage('message.fail'),
                        message: (error || {}).message,
                      });
                    }
                  );
              } else if (statusCode === 3) {
                isShowLoading(false);
                onOpenAlert({
                  type: DATA.ALERT_TYPE.ERROR,
                  title: getLanguage('message.fail'),
                  message: getLanguage('login.incorrectMemberType'),
                });
              }
            },
            (error) => {
              console.log(error);
              isShowLoading(false);
              onOpenAlert({
                type: DATA.ALERT_TYPE.ERROR,
                title: getLanguage('message.fail'),
                message: (error || {}).message,
              });
            }
          );
      }
    },
  };

  render() {
    const { ssoId } = this.state;

    return (
      <LoginContainerConnected
        ssoId={ssoId}
        handleLogin={this.login.handleLogin}
      />
    );
  }
}
