import React from 'react'
import _ from 'lodash'
import {
  TransitionContainer,
  Container,
  Image,
  Section,
  Text,
  Button,
  NavigationBar,
  Grid,
} from './../../components'
import {
  VARIABLES,
  TYPOGRAPHYS,
  ICONS,
} from './../../themes'
import {
  ROUTE_PATH,
  redirect,
  redirectData
} from './../../helpers';
import { getLanguage } from '../../helpers/functions/language';

export class ForgetPasswordMethodPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    return (
      <TransitionContainer>
        <Container
          width={VARIABLES.WEBSITE_WIDTH}
          height='100'
          heightUnit='vh'
          bgColor={VARIABLES.COLORS.WHITE}
        >
          <NavigationBar
            shadow
            pageTitle={getLanguage('verify.title4')}
            actionLeft={
              <Image
                widthRatio='11'
                widthRatioUnit='px'
                heightRatio='21'
                heightRatioUnit='px'
                src={ICONS['ic-left-black.svg']}
                onClick={() => {
                  redirect(ROUTE_PATH.LOGIN_BY_PHONE.LINK)
                }}
              />
            }
          />
          <Section
            scroll
            justify='center'
            calcHeight='100vh - 70px'
            paddingTop='45'
            paddingBottom='55'
          >
            <Section width='275'>
              <Section paddingTop='50'>
                <Section spacingBottom='18'>
                  <Button
                    fluid
                    className='is-border-blue'
                    paddingRight='5'
                    paddingBottom='10'
                    paddingLeft='10'
                    bgColor={VARIABLES.COLORS.TRANSPARENT}
                    onClick={() => {
                      redirect(ROUTE_PATH.FORGOT_PASSWORD_PHONE_NUMBER.LINK);
                    }}
                  >
                    <Grid justify='space-between'>
                      <Grid.Column>
                        <Section
                          direction='row'
                          align='center'
                        >
                          <Button.Icon spacingRight='15'>
                            <Image
                              widthRatio='24'
                              widthRatioUnit='px'
                              heightRatio='24'
                              heightRatioUnit='px'
                              src={ICONS['ic-phone-lightblue.svg']}
                            />
                          </Button.Icon>
                          <Text
                            fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_15}
                            color={VARIABLES.COLORS.BLUE_2}
                          >
                            {getLanguage('verify.title3')}
                          </Text>
                        </Section>
                      </Grid.Column>
                      <Grid.Column>
                        <Section paddingTop='3'>
                          <Image
                            widthRatio='17'
                            widthRatioUnit='px'
                            heightRatio='11'
                            heightRatioUnit='px'
                            src={ICONS['ic-arrow-right-lightblue.svg']}
                          />
                        </Section>
                      </Grid.Column>
                    </Grid>
                  </Button>
                </Section>
                <Section
                  spacingBottom='15'
                  justify='center'
                >
                  <Text
                    fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_14}
                    color={VARIABLES.COLORS.BLUE_1}
                  >
                    {getLanguage('login.or')}
                  </Text>
                </Section>
                <Section>
                  <Button
                    fluid
                    className='is-border-blue'
                    paddingRight='5'
                    paddingBottom='10'
                    paddingLeft='10'
                    bgColor={VARIABLES.COLORS.TRANSPARENT}
                    onClick={() => {
                      redirectData(ROUTE_PATH.VERIFY_QUESTION.LINK, {
                        data: {
                          verifyType: 'forgetpassword'
                        }
                      });
                    }}
                  >
                    <Grid justify='space-between'>
                      <Grid.Column>
                        <Section
                          direction='row'
                          align='center'
                        >
                          <Button.Icon spacingRight='15'>
                            <Image
                              widthRatio='26'
                              widthRatioUnit='px'
                              heightRatio='26'
                              heightRatioUnit='px'
                              src={ICONS['ic-questions-blue.svg']}
                            />
                          </Button.Icon>
                          <Text
                            fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_15}
                            color={VARIABLES.COLORS.BLUE_2}
                          >
                            {getLanguage('verify.title2')}
                          </Text>
                        </Section>
                      </Grid.Column>
                      <Grid.Column>
                        <Section paddingTop='5'>
                          <Image
                            widthRatio='17'
                            widthRatioUnit='px'
                            heightRatio='11'
                            heightRatioUnit='px'
                            src={ICONS['ic-arrow-right-lightblue.png']}
                          />
                        </Section>
                      </Grid.Column>
                    </Grid>
                  </Button>
                </Section>
              </Section>
            </Section>
          </Section>
        </Container>
      </TransitionContainer>
    )
  }
}
