import React from 'react'
import ClassNames from 'classnames'
import PropTypes from 'prop-types'
import {
  NewsAndPromotionsListWrapper
} from './styled'
import {
  Section,
  Grid,
  Image,
  Text,
  ShapeContainer
} from './../../../components'
import {
  VARIABLES,
  TYPOGRAPHYS,
  ICONS
} from './../../../themes'
// import {
//   default as VARIABLES
// } from './../../../themes/styles/bases/variables'
// import {
//   default as TYPOGRAPHYS
// } from './../../../themes/styles/bases/typographys'
// import {
//   default as UTILITIES
// } from './../../../themes/styles/helpers/utilities'

/**
 * TemplateBase description:
 * - TemplateBase
 */

export class NewsAndPromotionsList extends React.PureComponent {
  render () {
    const {
      className,
      // children,
      // modifierName
      onClick,
      coverImage,
      title,
      datetime,
      description,
      label
    } = this.props

    // props for css classes
    const classes = ClassNames(
      'news-and-promotions-list',
      // {'is-modifier-name': modifierName},
      className
    )

    return (
      <NewsAndPromotionsListWrapper
        className={classes}
      >
        <Section
          paddingBottom='20'
          spacingBottom='20'
          borderBottomColor={VARIABLES.COLORS.BORDER_BLUE_1}
          borderBottomWidth='1'
          borderBottomStyle='solid'
          onClick={onClick}
        >
          <Grid gutter='12'>
            <Grid.Column flex='none'>
              <Section spacingLeft='10'>
                <div className='news-and-promotions-list-cover-image'>
                  <Image
                    widthRatio='66'
                    widthRatioUnit='px'
                    heightRatio='66'
                    heightRatioUnit='px'
                    borderRadius='5'
                    src={coverImage}
                  />
                  {label &&
                    <div className='news-and-promotions-list-label'>
                      {label}
                    </div>
                  }
                </div>
              </Section>
            </Grid.Column>
            <Grid.Column flex='1'>
              <Section className='is-news-promotions-content'>
                <Section spacingBottom='-5'>
                  <Text
                    ellipsis
                    singleLine
                    fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_16}
                    color={VARIABLES.COLORS.BLACK}
                  >
                    {title}
                  </Text>
                </Section>
                <Section spacingBottom='3'>
                  <Grid gutter='6'>
                    <Grid.Column>
                      <Image
                        widthRatio='12'
                        widthRatioUnit='px'
                        heightRatio='13'
                        heightRatioUnit='px'
                        top='-2'
                        src={ICONS['ic-date-blue.svg']}
                      />
                    </Grid.Column>
                    <Grid.Column>
                      <Text
                        fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_12}
                        color={VARIABLES.COLORS.BLUE_2}
                      >
                        {datetime}
                    </Text>
                    </Grid.Column>
                  </Grid>
                </Section>
                <Section>
                  <Text
                    ellipsisLine='2'
                    fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_12}
                    color={VARIABLES.COLORS.GRAY_3}
                  >
                    {description}
                  </Text>
                </Section>
              </Section>
            </Grid.Column>
            <Grid.Column flex='none'>
              <Section
                width='48'
                height='100'
                heightUnit='%'
                justify='center'
                align='center'
              >
                <ShapeContainer
                  justify='center'
                  align='center'
                  width='18'
                  height='18'
                  bgColor={VARIABLES.COLORS.BLUE_2}
                  borderRadius='9'
                >
                  <Image
                    widthRatio='8'
                    widthRatioUnit='px'
                    heightRatio='8'
                    heightRatioUnit='px'
                    src={ICONS['ic-arrow-more.svg']}
                    left='-1'
                  />
                </ShapeContainer>
              </Section>
            </Grid.Column>
          </Grid>
        </Section>
      </NewsAndPromotionsListWrapper>
    )
  }

  static defaultProps = {
    // * If you set new default props, you should create propsType for correct styleguide document.
  }

  static propTypes = { // TYPE > node, string, number, bool, array, object, symbol, func
    /**
    * Additional classes
    */
    className: PropTypes.string
  }
}
