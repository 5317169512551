import React from 'react'
import {
  TransitionContainer,
  Container,
  Section,
  Image,
  Button,
  Grid,
  Text,
  Field,
  NavigationBar,
  Modal
} from './../../components'
import {
  VARIABLES,
  TYPOGRAPHYS,
  ICONS,
} from './../../themes'
import {
  ROUTE_PATH,
  redirect,
} from './../../helpers'
import {
  AlertModal
} from '../AlertModal'
import {
  Loader
} from '../Loader';

import axios from 'axios'
import $ from 'jquery';
import config from '../../config'
import { loadImage } from '../../helpers/functions/data'
import { LOCAL_STORAGE } from '../../helpers/constants/localStorage';
import { getLanguage } from '../../helpers/functions/language'
import {
  projectUpload,
  updateProjectById,
  deleteProjectById
} from '../../helpers/services/projects'


// TODO: adjust style
export class ProjectEditPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      id: (this.props.match.params && this.props.match.params.id) ? this.props.match.params.id : '',
      name: '',
      location: '',
      area_size: '',
      product: '',
      description: '',
      msg: '',
      topic: '',
      image_url: [],
      isLoading: false,
      selectedFile: [],
      old_image_url: [],
      showModalConfirmDelete: false
    }
    // console.log(this.props)
  }

  componentWillMount() {
    const data = JSON.parse(localStorage.getItem('project')) || [];
    if (data && data.length) {
      const result = data.find(d => d.id === +this.state.id);
      if (result) {
        const {
          name,
          id,
          location,
          area_size,
          product,
          description,
          image_url
        } = result;
        this.setState({
          id,
          name,
          location,
          area_size,
          product,
          description,
          image_url: image_url || [],
          old_image_url: image_url || []
        })
      }
    }
  }

  onChangeField(key, e) {
    if (e && e.target) {
      this.setState({ [key]: e.target.value })
    }
  }

  isValid() {
    const { name, location, msg } = this.state;
    let isValid = '';
    if (!name && location) {
      isValid = msg + 'project name';
    } else if (name && !location) {
      isValid = msg + 'location';
    } else if (!name && !location) {
      isValid = msg + 'project name and location'
    }
    this.setState({ msg: isValid });
    return !isValid;
  }

  async saveData() {
    const isValid = this.isValid();
    // const data = JSON.parse(localStorage.getItem('project'))
    const user = JSON.parse(localStorage.getItem(LOCAL_STORAGE.REGISTER_INFO))
    const {
      name,
      location,
      area_size,
      product,
      description,
      id,
      image_url
    } = this.state;
    const data = {
      name,
      location,
      area_size,
      product,
      description,
      id,
    }
    if (isValid) {
      this.setState({ isLoading: true });
      // let imageUrl = [...this.state.old_image_url];
      let imageUrl = [];
      if (this.state.selectedFile && this.state.selectedFile.length) {
        for (let i = 0; i < this.state.selectedFile.length; i++) {
          const formdata = new FormData()
          formdata.append('file', this.state.selectedFile[i])
          formdata.append('user_id', user.id)
          formdata.append('folder', 'projects')
          await projectUpload(formdata, {}).then(res => {
            if (res) {
              imageUrl.push(res.url)
            }
          })
        }
      }
      imageUrl = [...imageUrl, ...image_url.filter(e => e.indexOf('blob:') === -1)]
      let params = { ...data, image_url: imageUrl };
      await updateProjectById(params).then(async (res) => {
        if (res) {
          await setTimeout(redirect(`${ROUTE_PATH.MY_PROJECTS.LINK}/${user.id}`), 2000);
        } else {
          alert(getLanguage('message.somethingWrong'))
        }
        this.setState({ isLoading: false });
      });
    } else {
      this.setState({ showModal: true })
    }
  }

  closeAlert() {
    this.setState({ showModal: false, msg: '' })
  }

  handleBack() {
    const user = JSON.parse(localStorage.getItem(LOCAL_STORAGE.REGISTER_INFO))
    redirect(`${ROUTE_PATH.MY_PROJECTS.LINK}/${user.id}`);
  }

  uploadImgHandler() {
    $("input[id='my_file']").click();
  }

  // uploadImgChange(event) {
  //   let file = [];
  //   let image_url = [];
  //   file.push(...this.state.selectedFile);
  //   for (let i of event.target.files){
  //         file.push(i);
  //         image_url.push(URL.createObjectURL(i));
  //   };
  //   for (let i of this.state.image_url){
  //     image_url.push(i);
  // };
  //   this.setState({selectedFile :file,image_url});
  // }

  async uploadImgChange(event) {
    const {
      selectedFile,
      image_url,
    } = this.state;
    const file = event.target.files[0];
    console.log('file', file);
    const newFile = await loadImage(file);
    // selectedFile.push(newFile);
    selectedFile.unshift(newFile);
    image_url.unshift(URL.createObjectURL(newFile))
    this.setState({
      selectedFile,
      image_url
    });
  }

  image = {
    onClickDelete: (index) => {
      const {
        selectedFile,
        image_url,
      } = this.state;
      if (selectedFile[index]) {
        selectedFile.splice(index, 1);
      }
      image_url.splice(index, 1);
      this.setState({
        selectedFile,
        image_url
      });
    }
  }

  // modal = {
  //   onClickOpenConfirmDeleteModal: () => {
  //     this.setState({
  //       showModalConfirmDelete: true
  //     })
  //   },

  //   onClickCloseConfirmDeleteModal: () => {
  //     this.setState({
  //       showModalConfirmDelete: false
  //     })
  //   },
  // }

  async onDelete() {
    this.setState({ showModalConfirmDelete: true });
  }

  modal = {
    onClickCancel: () => {
      this.setState({
        showModalConfirmDelete: false
      })
    },
    onClickDelete: async () => {
      this.setState({
        showModalConfirmDelete: false
      })
      let params = {
        id: this.state.id,
      }
      // await axios.delete(`${config.api.base}/api/projects/${this.state.id}`).then(async (res) => {
      await deleteProjectById(params).then(async (res) => {
        if (res) {
          // if (res.status === 200) {
          await setTimeout(this.handleBack.bind(this), 1000);
        } else {
          alert(getLanguage('message.somethingWrong'))
        }
      });
    }
  }

  render() {
    const { name, location, description, area_size, product, showModalConfirmDelete } = this.state;
    // const leftButton = <Image
    //             widthRatio='12'
    //             widthRatioUnit='px'
    //             heightRatio='21'
    //             heightRatioUnit='px'
    //             src={ICONS['ic-left-black.png']} //TODO: need to change icon
    //             onClick={this.handleBack.bind(this)}
    //           />
    const rightButton = <Text
      fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_16}
      color={VARIABLES.COLORS.BLACK}
      onClick={this.saveData.bind(this)}
    >
      {getLanguage('field.buttonSave')}
    </Text>
    return (
      <TransitionContainer
      // motion='overlap-from'
      >
        <Container
          width={VARIABLES.WEBSITE_WIDTH}
          bgColor={VARIABLES.COLORS.WHITE}
          calcMinHeight='100vh'
        >
          <Container
            bgColor={VARIABLES.COLORS.WHITE}
            justify='space-between'
            align='center'
            wrap='no-wrap'
          >
            <NavigationBar
              pageTitle={getLanguage('projectsEdit.title')}
              actionLeft={
                <Image
                  widthRatio='11'
                  widthRatioUnit='px'
                  heightRatio='21'
                  heightRatioUnit='px'
                  src={ICONS['ic-left-black.svg']}
                  onClick={this.handleBack.bind(this)}
                />
              }
              actionRight={rightButton}
            />
            <Section
              paddingHorizontal='20'
              paddingTop='31'
              paddingBottom='20'
            >
              <Section spacingBottom='25' className='is-project-field'>
                <Field
                  fluid
                  value={name}
                  placeholder='ex. Kamnottvidya Science Academy'
                  placeholderColor={VARIABLES.COLORS.GRAY_1}
                  bgColor={VARIABLES.COLORS.TRANSPARENT}
                  borderWidth='1'
                  borderStyle='solid'
                  borderColor={VARIABLES.COLORS.GRAY_1}
                  borderRadius='5'
                  fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_15}
                  fontColor={VARIABLES.COLORS.BLACK}
                  paddingHorizontal={10}
                  paddingTop={15}
                  paddingBottom={10}
                  onChange={this.onChangeField.bind(this, 'name')}
                />
                <Section className='is-top-section'>
                  <Text
                    fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_14}
                    color={VARIABLES.COLORS.GRAY_3}
                    className='is-top-label'
                  >
                    {getLanguage('field.projectName')}
                    <Text
                      fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_14}
                      color={VARIABLES.COLORS.ERROR}
                    >
                      *
                    </Text>
                  </Text>
                </Section>
              </Section>
              <Section spacingBottom='25' className='is-project-field'>
                <Field
                  fluid
                  value={location}
                  placeholder='ex. Wangchan, Rayong'
                  placeholderColor={VARIABLES.COLORS.GRAY_1}
                  bgColor={VARIABLES.COLORS.TRANSPARENT}
                  borderWidth='1'
                  borderStyle='solid'
                  borderColor={VARIABLES.COLORS.GRAY_1}
                  borderRadius='5'
                  fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_15}
                  fontColor={VARIABLES.COLORS.BLACK}
                  paddingHorizontal={10}
                  paddingTop={15}
                  paddingBottom={10}
                  onChange={this.onChangeField.bind(this, 'location')}

                />
                <Section className='is-top-section'>
                  <Text
                    fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_14}
                    color={VARIABLES.COLORS.GRAY_3}
                    className='is-top-label'
                  >
                    {getLanguage('field.location')}
                    <Text
                      fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_14}
                      color={VARIABLES.COLORS.ERROR}
                    >
                      *
                    </Text>
                  </Text>
                </Section>
              </Section>
              <Section spacingBottom='25' className='is-project-field'>
                <Field
                  fluid
                  value={area_size}
                  placeholder='ex. 2,000 sq.m.'
                  placeholderColor={VARIABLES.COLORS.GRAY_1}
                  bgColor={VARIABLES.COLORS.TRANSPARENT}
                  borderWidth='1'
                  borderStyle='solid'
                  borderColor={VARIABLES.COLORS.GRAY_1}
                  borderRadius='5'
                  fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_15}
                  fontColor={VARIABLES.COLORS.BLACK}
                  paddingHorizontal={10}
                  paddingTop={15}
                  paddingBottom={10}
                  onChange={this.onChangeField.bind(this, 'area_size')}
                />
                <Section className='is-top-section'>
                  <Text
                    fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_14}
                    color={VARIABLES.COLORS.GRAY_3}
                    className='is-top-label'
                  >
                    {getLanguage('field.areaSizeProject')}
                    <Text
                      fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_14}
                      color={VARIABLES.COLORS.ERROR}
                    >
                    </Text>
                  </Text>
                </Section>
              </Section>
              <Section spacingBottom='25' className='is-project-field'>
                <Field
                  fluid
                  value={product}
                  placeholder='ex. Zincalume®'
                  placeholderColor={VARIABLES.COLORS.GRAY_1}
                  bgColor={VARIABLES.COLORS.TRANSPARENT}
                  borderWidth='1'
                  borderStyle='solid'
                  borderColor={VARIABLES.COLORS.GRAY_1}
                  borderRadius='5'
                  fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_15}
                  fontColor={VARIABLES.COLORS.BLACK}
                  paddingHorizontal={10}
                  paddingTop={15}
                  paddingBottom={10}
                  onChange={this.onChangeField.bind(this, 'product')}
                />
                <Section className='is-top-section'>
                  <Text
                    fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_14}
                    color={VARIABLES.COLORS.GRAY_3}
                    className='is-top-label'
                  >
                    {getLanguage('field.product')}
                  </Text>
                </Section>
              </Section>
              <Section spacingBottom='30' className='is-project-field'>
                <Field
                  fluid
                  value={description}
                  type='textarea'
                  placeholder='ex. Kamnoetvidya Science Academy is a leading
                  science high school with a specific purpose of
                  nurturing gifted and talented students in
                  mathematics and science in grades 10 to 12
                  who will make a significant contribution to
                  Thailand’s science and technology development.'
                  placeholderColor={VARIABLES.COLORS.GRAY_1}
                  height='130'
                  bgColor={VARIABLES.COLORS.TRANSPARENT}
                  borderWidth='1'
                  borderStyle='solid'
                  borderColor={VARIABLES.COLORS.GRAY_1}
                  borderRadius='5'
                  fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_15}
                  fontColor={VARIABLES.COLORS.BLACK}
                  paddingHorizontal={10}
                  paddingTop={15}
                  paddingBottom={10}
                  onChange={this.onChangeField.bind(this, 'description')}
                />
                <Section className='is-top-section'>
                  <Text
                    fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_14}
                    color={VARIABLES.COLORS.GRAY_3}
                    className='is-top-label'
                  >
                    {getLanguage('field.description')}
                  </Text>
                </Section>
              </Section>
              <Section
                spacingBottom='35'
                justify='center'
              >
                <Grid
                  gutter='3'
                >
                  {(this.state.image_url || []).map((img, i) => (
                    <Grid.Column grid={4} className='is-project-img'>
                      <Section
                        paddingLeft='10'
                        paddingBottom='10'
                      >
                        <Image
                          widthRatio='100'
                          widthRatioUnit='%'
                          heightRatio='100'
                          heightRatioUnit='%'
                          src={img}
                          borderRadius={5}
                        />
                      </Section>
                      <Section className='is-project-delete-img'>
                        <Button
                          fluid
                          className='is-icon'
                          width={23}
                          height={23}
                          borderRadius={30}
                          bgColor={'#FA295C'}
                          onClick={() => {
                            this.image.onClickDelete(i)
                          }}
                        >
                          <Button.Icon>
                            <Image
                              top='-1'
                              widthRatio='10'
                              widthRatioUnit='px'
                              heightRatio='10'
                              heightRatioUnit='px'
                              src={ICONS['ic-close.png']}
                            />
                          </Button.Icon>
                        </Button>
                      </Section>
                    </Grid.Column>
                  ))}
                </Grid>
              </Section>
              <Section
                justify='center'
                spacingBottom='15'
              >
                <Section
                  width='275'
                  paddingBottom='15'
                  marginHorizontal='auto'
                  justify='center'
                >
                  <Text
                    fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_11}
                    color={VARIABLES.COLORS.GRAY_3}
                    align='center'
                  >
                    {getLanguage('field.hintButtonAddPhotoLineOne')}
                    <br />
                    {getLanguage('field.hintButtonAddPhotoLineTwo')}
                  </Text>
                </Section>
                <Button
                  type="file"
                  shadow
                  className='is-gradient-blue is-icon-front'
                  width='275'
                  height={VARIABLES.BUTTON.HEIGHTS.H_40}
                  borderRadius={VARIABLES.BUTTON.BORDER_RADIUSES.BR_10}
                  // onClick={this.onButtonClick.bind(this)}
                  onClick={this.uploadImgHandler.bind(this)}

                // onClick={this.saveData.bind(this)} //TODO: Add Photo
                >
                  <Button.Icon>
                    <Image
                      widthRatio='16'
                      widthRatioUnit='px'
                      heightRatio='16'
                      heightRatioUnit='px'
                      top='-1'
                      src={ICONS['ic-image.png']}
                    />
                  </Button.Icon>
                  <input style={{ display: 'none' }} type="file" id="my_file" multiple onChange={this.uploadImgChange.bind(this)} />

                  {/* <input ref={(e)=> {this.file = e; }} type='file' style={{display: 'none'}} id='multi' accept="image/*" onChange={this.handleOnChange.bind(this)} multiple /> */}
                  <Text
                    fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_16}
                    color={VARIABLES.COLORS.WHITE}
                  >
                    {getLanguage('field.buttonAddPhoto')}
                  </Text>
                </Button>
              </Section>
              <Section justify='center'>
                <Button
                  shadow
                  width='275'
                  height={VARIABLES.BUTTON.HEIGHTS.H_40}
                  bgColor={VARIABLES.COLORS.GRAY_3}
                  borderRadius={VARIABLES.BUTTON.BORDER_RADIUSES.BR_10}
                  // onClick={this.modal.onClickOpenConfirmDeleteModal}
                  onClick={this.onDelete.bind(this)}
                >
                  <Text
                    fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_16}
                    color={VARIABLES.COLORS.WHITE}
                  >
                    {getLanguage('field.buttonDeleteProject')}
                  </Text>
                </Button>
              </Section>
            </Section>
          </Container>
        </Container>
        {this.state.showModal ? <AlertModal topic={'Error'} msg={'Please specify ' + this.state.msg} closeModal={this.closeAlert.bind(this)} /> : ''}
        {<Loader loading={this.state.isLoading} isOverlay={true} />}

        <Modal
          open={showModalConfirmDelete}
          width={VARIABLES.MODAL.WIDTHS.W_300}
          overlayColor={VARIABLES.COLORS.OVERLAY_3}
          spacing={VARIABLES.MODAL.CONTAINER.C_1.SPACING}
          transition={VARIABLES.TRANSITIONS.DEFAULT}
          transformStart={VARIABLES.MODAL.CONTAINER.C_1.TRANSFORM_START}
          transformEnd={VARIABLES.MODAL.CONTAINER.C_1.TRANSFORM_END}
          onClickClose={this.modal.onClickCancel}
        >
          <Modal.Section
            paddingTop={VARIABLES.MODAL.HEADER.H_1.PADDING_TOP}
            paddingBottom={VARIABLES.MODAL.HEADER.H_1.PADDING_BOTTOM}
            paddingHorizontal={VARIABLES.MODAL.HEADER.H_1.PADDING_HORIZONTAL}
            borderTopRightRadius={VARIABLES.MODAL.HEADER.H_1.BORDER_TOP_RIGHT_RADIUS}
            borderTopLeftRadius={VARIABLES.MODAL.HEADER.H_1.BORDER_TOP_LEFT_RADIUS}
          >
            <Section justify='center'>
              <Image
                widthRatio='50'
                widthRatioUnit='px'
                heightRatio='50'
                heightRatioUnit='px'
                src={ICONS['ic-warning.svg']}
              />
            </Section>
          </Modal.Section>
          <Modal.Section
            paddingBottom={VARIABLES.MODAL.BODY.B_1.PADDING_BOTTOM}
            paddingHorizontal={VARIABLES.MODAL.BODY.B_1.PADDING_HORIZONTAL}
          >
            <Section
              spacingBottom='12'
              justify='center'
            >
              <Text
                fontStyle={TYPOGRAPHYS.FIRST.BOLD.FS_18}
                color={VARIABLES.COLORS.BLACK}
              >
                {getLanguage('modal.confirmation.title')}
              </Text>
            </Section>
            <Section
              justify='center'
            >
              <Text
                fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_15}
                color={VARIABLES.COLORS.GRAY_6}
                align='center'
              >
                {getLanguage('modal.confirmation.message_2')}
                <br />
                {getLanguage('modal.confirmation.message_2_2')}
              </Text>
            </Section>
          </Modal.Section>
          <Modal.Section
            paddingBottom={10}
            paddingHorizontal={VARIABLES.MODAL.FOOTER.F_2.PADDING_HORIZONTAL}
          >
            <Button
              fluid
              shadow
              className='is-gradient-blue'
              height={VARIABLES.BUTTON.HEIGHTS.H_35}
              borderRadius={VARIABLES.BUTTON.BORDER_RADIUSES.BR_10}
              onClick={this.modal.onClickDelete}
            >
              <Text
                fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_16}
                color={VARIABLES.COLORS.WHITE}
              >
                {getLanguage('field.buttonDelete')}
              </Text>
            </Button>
          </Modal.Section>
          <Modal.Section
            paddingBottom={VARIABLES.MODAL.FOOTER.F_2.PADDING_BOTTOM}
            paddingHorizontal={VARIABLES.MODAL.FOOTER.F_2.PADDING_HORIZONTAL}
            borderBottomRightRadius={VARIABLES.MODAL.FOOTER.F_2.BORDER_BOTTOM_RIGHT_RADIUS}
            borderBottomLeftRadius={VARIABLES.MODAL.FOOTER.F_2.BORDER_BOTTOM_LEFT_RADIUS}
          >
            <Button
              fluid
              bgColor={VARIABLES.COLORS.TRANSPARENT}
              borderWidth='1'
              borderStyle='solid'
              borderColor={VARIABLES.COLORS.BLUE_2}
              shadow
              height={VARIABLES.BUTTON.HEIGHTS.H_35}
              borderRadius={VARIABLES.BUTTON.BORDER_RADIUSES.BR_10}
              onClick={this.modal.onClickCancel}
            >
              <Text
                fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_16}
                color={VARIABLES.COLORS.BLUE_2}
              >
                {getLanguage('field.buttonCancel')}
              </Text>
            </Button>
          </Modal.Section>
        </Modal>
      </TransitionContainer>
    )
  }
}
