import styled, {
  // css
} from 'styled-components'
import {
  default as VARIABLES
} from './../../../themes/styles/bases/variables'
// import {
//   default as TYPOGRAPHYS
// } from './../../../themes/styles/bases/typographys'
// import {
//   default as MIXINS
// } from './../../../themes/styles/helpers/mixins'

// Sample using image in style
// url(${require('./../../../themes/images/icons/icon-sample.svg')});

// Wrapper
// ============================================================
export const NewsAndPromotionsListWrapper = styled.div`
  /* Parent
  ------------------------------- */
  /* Childrens
  ------------------------------- */
  .news-and-promotions-list-cover-image {
    position: relative;
  }

  .news-and-promotions-list-label {
    position: absolute;
    z-index: ${VARIABLES.Z_INDEXS.LV_1};
    top: -5px;
    right: 2px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 28px;
    height: 28px;
    background-color: ${VARIABLES.COLORS.ORANGE_1};
    border-radius: 50%;
    font-family: ${VARIABLES.FONT.FAMILIES.FIRST.WEIGHTS.BOLD};
    font-size: 7px;
    line-height: 1;
    color: ${VARIABLES.COLORS.WHITE};
  }

  /* Modifiers
  ------------------------------- */
  &.is-modifier-name {}

  /* Media queries
  ------------------------------- */
`
