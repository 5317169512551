import React from 'react';
import _ from 'lodash';
import axios from 'axios';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import {
  TransitionContainer,
  Container,
  Section,
  Image,
  NavigationBar,
  Text,
  Grid,
  ReactTabsControl,
} from './../../components';
import { VARIABLES, ICONS, TYPOGRAPHYS } from './../../themes';
import { ROUTE_PATH, redirect, formatDatetime } from './../../helpers';
import { Loader } from '../Loader';
import { PointItem } from './PointItem';
import { LOCAL_STORAGE } from '../../helpers/constants/localStorage';
import config from '../../config';
import { getLanguage } from '../../helpers/functions/language';
import { displayWithComma } from '../../helpers/functions/display';
import { ENUM } from '../../helpers/constants/enum';
import { fetchApi } from '../../apiService/apiService';
import { CustomerProfileContainerConnected } from '../../containers/CustomerProfileContainer';

export class LoyaltyPointPage extends React.Component {
  constructor(props) {
    super(props);
    const user = JSON.parse(localStorage.getItem(LOCAL_STORAGE.REGISTER_INFO));
    this.state = {
      isLoading: false,
      data: {},
      user,
      userId: _.get(user, 'id'),
      expirePoint: 0,
      isVI: user.countries.toLowerCase() === ENUM.COUNTRY.VIETNAM.toLowerCase(),
      totalPoint: 0,
    };
  }

  componentDidMount() {
    this.setState({ isLoading: true }, async () => {
      try {
        // await this.loyaltyPoint.getPointSearch();
        // await this.customerProfileRef.customerProfile.getData();
        const url = `${config.api.base}/api/points/${this.state.userId}`;
        const response = await fetchApi.get(url);
        const data = _.get(response, 'data');
        if (response.status === 200 && !_.isEmpty(data)) {
          // const expirePointList = _.get(data, 'expire_point_detail', []);
          // let expireDate;
          // let expirePoint = 0;
          // if (expirePointList.length) {
          //   expireDate = formatDatetime(
          //     expirePointList[0].expire_date,
          //     'DD/MM/YYYY'
          //   );
          //   expirePoint = expirePointList[0].points;
          // }
          this.setState({
            data,
            // expireDate,
            // expirePoint,
          });
        }
      } catch (_) {
      } finally {
        this.setState({ isLoading: false });
      }
    });
  }

  // loyaltyPoint = {
  //   getPointSearch: async () => {
  //     const { user } = this.state;
  //     const url = `${config.api.base}/api/points/user/${user.sfid}`;
  //     const response = await fetchApi.get(url);
  //     const resData = _.get(response, 'data');
  //     if (response.status === 200 && !_.isEmpty(resData)) {
  //       const data = resData.data;
  //       this.setState({
  //         totalPoint: _.get(data, 'point_left__c', 0),
  //       });
  //     }
  //   },
  // };

  handleBack() {
    redirect(ROUTE_PATH.HOME.LINK);
  }

  render() {
    const {
      isLoading,
      data,
      user,
      isVI,
    } = this.state;
    console.log('data', data);
    return (
      <TransitionContainer>
        <Container
          width={VARIABLES.WEBSITE_WIDTH}
          calcMinHeight='100vh'
          bgColor={VARIABLES.COLORS.LIGHT_BLUE_2}
        >
          <NavigationBar
            shadow
            iconWidth='15'
            iconHeight='20'
            iconSrc={ICONS['ic-medal-black.svg']}
            pageTitleIcon={getLanguage('loyaltyPoint.title')}
            actionLeft={
              <Image
                widthRatio='24'
                widthRatioUnit='px'
                heightRatio='23'
                heightRatioUnit='px'
                src={ICONS['ic-home-black.svg']}
                onClick={this.handleBack.bind(this)}
              />
            }
          />
          <ReactTabsControl>
            <Tabs>
              <TabList>
                <Tab>{getLanguage('loyaltyPoint.tab.earnedPoints.title')}</Tab>
                <Tab>
                  {getLanguage('loyaltyPoint.tab.redeemedPoints.title')}
                </Tab>
              </TabList>
              <Section scroll calcHeight='100vh - 106px'>
                <CustomerProfileContainerConnected />
                <TabPanel>
                  <Section
                    paddingHorizontal='20'
                    paddingTop='24'
                    paddingBottom='10'
                  >
                    {!_.isEmpty(data.points_earn) &&
                      data.points_earn.map((data, index) => (
                        <PointItem data={data} key={index} type='earn' />
                      ))}
                  </Section>
                </TabPanel>
                <TabPanel>
                  <Section
                    paddingHorizontal='20'
                    paddingTop='24'
                    paddingBottom='40'
                  >
                    {!_.isEmpty(data.points_redeem) &&
                      data.points_redeem.map((data, index) => (
                        <PointItem
                          data={data}
                          key={index}
                          type='redeem'
                          isVI={isVI}
                        />
                      ))}
                  </Section>
                </TabPanel>
              </Section>
            </Tabs>
          </ReactTabsControl>
        </Container>
        <Loader loading={isLoading} isOverlay />
      </TransitionContainer>
    );
  }
}
