import React from "react";
import ClassNames from "classnames";
import PropTypes from "prop-types";
import { CommentListWrapper } from "./styled";
import { Section, Grid, Image, Text } from "./../../../components";
import { VARIABLES, TYPOGRAPHYS, ICONS } from "./../../../themes";
// import {
//   default as VARIABLES
// } from './../../../themes/styles/bases/variables'
// import {
//   default as TYPOGRAPHYS
// } from './../../../themes/styles/bases/typographys'
// import {
//   default as UTILITIES
// } from './../../../themes/styles/helpers/utilities'

/**
 * CommentList description:
 * - CommentList
 */

export class CommentList extends React.PureComponent {
  createRating = (num) => {
    const rating = [];
    for (let i = 0; i < num; i++) {
      rating.push(
        <Grid.Column>
          <Image
            widthRatio="12"
            widthRatioUnit="px"
            heightRatio="12"
            heightRatioUnit="px"
            src={ICONS["ic-star-blue.svg"]}
          />
        </Grid.Column>
      );
    }
    return rating;
  };

  render() {
    const {
      className,
      // children,
      // modifierName
      userAvatar,
      username,
      date,
      commentTitle,
      comment,
      rating,
    } = this.props;

    // props for css classes
    const classes = ClassNames(
      "comment-list",
      // {'is-modifier-name': modifierName},
      className
    );

    return (
      <CommentListWrapper className={classes}>
        <Section spacingBottom="8">
          <Grid gutter="12">
            <Grid.Column>
              <Image
                widthRatio="56"
                widthRatioUnit="px"
                heightRatio="56"
                heightRatioUnit="px"
                src={userAvatar}
              />
            </Grid.Column>
            <Grid.Column>
              <Section spacingTop="3" spacingBottom="3">
                <Text
                  fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_14}
                  color={VARIABLES.COLORS.BLACK}
                >
                  {username}
                </Text>
              </Section>
              {rating > 0 && (
                <Section>
                  <Grid gutter="2">{this.createRating(rating)}</Grid>
                </Section>
              )}
              <Section>
                <Text
                  fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_12}
                  color={VARIABLES.COLORS.GRAY_8}
                >
                  Date : {date}
                </Text>
              </Section>
            </Grid.Column>
          </Grid>
        </Section>
        <Section spacingBottom="3">
          <Text
            className="comment-list-title"
            fontStyle={TYPOGRAPHYS.FIRST.BOLD.FS_14}
            color={VARIABLES.COLORS.BLACK}
          >
            {commentTitle}
          </Text>
        </Section>
        <Section>
          <Text
            className="comment-list-detail"
            fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_14}
            color={VARIABLES.COLORS.BLACK}
          >
            {comment}
          </Text>
        </Section>
      </CommentListWrapper>
    );
  }

  static defaultProps = {
    // * If you set new default props, you should create propsType for correct styleguide document.
  };

  static propTypes = {
    // TYPE > node, string, number, bool, array, object, symbol, func
    /**
     * Additional classes
     */
    className: PropTypes.string,
  };
}
