import React from 'react'
import axios from 'axios'
import * as _ from 'lodash'
import {
  TransitionContainer,
  Container,
  Section,
  Image,
  Grid,
  NavigationBar,
  Text,
  ShapeContainer,
} from './../../components'
import {
  VARIABLES,
  TYPOGRAPHYS,
  ICONS,
} from './../../themes'
import {
  ROUTE_PATH,
  redirect,
} from './../../helpers'
import {
  Loader
} from '../Loader'
import config from '../../config'
import { getLanguage } from '../../helpers/functions/language'
import { getAvailableCountryDataList } from '../../helpers/functions/data'
// import config from './../../config'
import { getAllKnowledgeBaseCategory } from '../../helpers/services/knowledgeBase'
import { isValidResponse } from './../../helpers'


export class KnowledgeBasePage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      list: [],
    }
  }

  componentDidMount() {
    localStorage.removeItem('knowledgeBase')
    this.setState({ isLoading: true }, async () => {
      try {
        const params = { sort: 'id asc' }
        let data = await getAllKnowledgeBaseCategory(params)
        if (data) {
          data = getAvailableCountryDataList(data);
          this.setState({ list: data }, () => {
            localStorage.setItem('knowledgeBase', JSON.stringify(data))
          });
        }
      } catch (_) {
      } finally {
        this.setState({ isLoading: false })
      }
    })
  }


  handleBack() {
    redirect(ROUTE_PATH.HOME.LINK)
  }

  onClickDetail(id) {
    redirect(`${ROUTE_PATH.KNOWLEDGE_BASE_TOPIC.LINK}/${id}`)
  }

  render() {
    const { list } = this.state;
    return (
      <TransitionContainer>
        <Container
          width={VARIABLES.WEBSITE_WIDTH}
          calcMinHeight='100vh'
          bgColor={VARIABLES.COLORS.LIGHT_BLUE_2}
        >
          <NavigationBar
            shadow
            iconWidth='20'
            iconHeight='18'
            iconSrc={ICONS['ic-navigation-knowledgebase.svg']}
            iconPositionTop='1'
            pageTitleIcon={getLanguage('knowledgeBase.title')}
            actionLeft={<Image
              widthRatio='24'
              widthRatioUnit='px'
              heightRatio='23'
              heightRatioUnit='px'
              src={ICONS['ic-home-black.svg']}
              onClick={this.handleBack.bind(this)}
            />}
          />
          <Section
            scroll
            calcHeight='100vh - 70px'
          >
            <Section
              paddingHorizontal='20'
              paddingTop='24'
              paddingBottom='40'
            >
              {<Loader loading={this.state.isLoading} paddingTop={30} widthRatio={50} heightRatio={50} />}
              {(list || []).map((data, index) =>
              (
                <Section
                  key={index}
                  index={index}
                  className='is-shadow'
                  spacingBottom='14'
                  bgColor={VARIABLES.COLORS.WHITE}
                  paddingVertical='25'
                  paddingLeft='18'
                  onClick={this.onClickDetail.bind(this, data.id)}
                >
                  <Grid gutter='20' align='center'>
                    <Grid.Column flex='none'>
                      <Image
                        widthRatio='39'
                        widthRatioUnit='px'
                        heightRatio='36'
                        heightRatioUnit='px'
                        src={`${config.imgPrefix}/${data.cover}`}
                      />
                    </Grid.Column>
                    <Grid.Column flex='1'>
                      <Section>
                        <Text
                          fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_16}
                        >
                          {data.name}
                        </Text>
                      </Section>
                      <Text
                        fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_12}
                        color={VARIABLES.COLORS.GRAY_3}
                      >
                        {data.description}
                      </Text>
                    </Grid.Column>
                    <Grid.Column flex='none'>
                      <Section
                        width='48'
                        height='100'
                        heightUnit='%'
                        justify='center'
                        align='center'
                      >
                        <ShapeContainer
                          justify='center'
                          align='center'
                          width='18'
                          height='18'
                          bgColor={VARIABLES.COLORS.BLUE_2}
                          borderRadius='9'
                        >
                          <Image
                            widthRatio='8'
                            widthRatioUnit='px'
                            heightRatio='8'
                            heightRatioUnit='px'
                            src={ICONS['ic-arrow-more.svg']}
                            left='-1'
                          />
                        </ShapeContainer>
                      </Section>
                    </Grid.Column>
                  </Grid>
                </Section>
              ))}
            </Section>
          </Section>
        </Container>
      </TransitionContainer>
    )
  }
}

