export const COUNTRY_INDONESIA = {
  //state
  PROVINCE_LIST: [
    {
      "province": "Aceh (NAD)",
      "provinceCode": "Aceh (NAD)"
    },
    {
      "province": "Bali",
      "provinceCode": "Bali"
    },
    {
      "province": "Banten",
      "provinceCode": "Banten"
    },
    {
      "province": "Bengkulu",
      "provinceCode": "Bengkulu"
    },
    {
      "province": "DI Yogyakarta",
      "provinceCode": "DI Yogyakarta"
    },
    {
      "province": "DKI Jakarta",
      "provinceCode": "DKI Jakarta"
    },
    {
      "province": "Gorontalo",
      "provinceCode": "Gorontalo"
    },
    {
      "province": "Jambi",
      "provinceCode": "Jambi"
    },
    {
      "province": "Jawa Barat",
      "provinceCode": "Jawa Barat"
    },
    {
      "province": "Jawa Tengah",
      "provinceCode": "Jawa Tengah"
    },
    {
      "province": "Jawa Timur",
      "provinceCode": "Jawa Timur"
    },
    {
      "province": "Kalimantan Barat",
      "provinceCode": "Kalimantan Barat"
    },
    {
      "province": "Kalimantan Selatan",
      "provinceCode": "Kalimantan Selatan"
    },
    {
      "province": "Kalimantan Tengah",
      "provinceCode": "Kalimantan Tengah"
    },
    {
      "province": "Kalimantan Timur",
      "provinceCode": "Kalimantan Timur"
    },
    {
      "province": "Kalimantan Utara",
      "provinceCode": "Kalimantan Utara"
    },
    {
      "province": "Kepulauan Bangka Belitung",
      "provinceCode": "Kepulauan Bangka Belitung"
    },
    {
      "province": "Kepulauan Riau",
      "provinceCode": "Kepulauan Riau"
    },
    {
      "province": "Lampung",
      "provinceCode": "Lampung"
    },
    {
      "province": "Maluku",
      "provinceCode": "Maluku"
    },
    {
      "province": "Maluku Utara",
      "provinceCode": "Maluku Utara"
    },
    {
      "province": "Nusa Tenggara Barat (NTB)",
      "provinceCode": "Nusa Tenggara Barat (NTB)"
    },
    {
      "province": "Nusa Tenggara Timur (NTT)",
      "provinceCode": "Nusa Tenggara Timur (NTT)"
    },
    {
      "province": "Papua",
      "provinceCode": "Papua"
    },
    {
      "province": "Papua Barat",
      "provinceCode": "Papua Barat"
    },
    {
      "province": "Riau",
      "provinceCode": "Riau"
    },
    {
      "province": "Sulawesi Barat",
      "provinceCode": "Sulawesi Barat"
    },
    {
      "province": "Sulawesi Selatan",
      "provinceCode": "Sulawesi Selatan"
    },
    {
      "province": "Sulawesi Tengah",
      "provinceCode": "Sulawesi Tengah"
    },
    {
      "province": "Sulawesi Tenggara",
      "provinceCode": "Sulawesi Tenggara"
    },
    {
      "province": "Sulawesi Utara",
      "provinceCode": "Sulawesi Utara"
    },
    {
      "province": "Sumatera Barat",
      "provinceCode": "Sumatera Barat"
    },
    {
      "province": "Sumatera Selatan",
      "provinceCode": "Sumatera Selatan"
    },
    {
      "province": "Sumatera Utara",
      "provinceCode": "Sumatera Utara"
    },
  ],
  //amphoe
  CITY_LIST: [
    {
      "city": "Simeulue",
      "cityCode": "Simeulue",
      "provinceCode": "Aceh (NAD)"
    },
    {
      "city": "Pidie Jaya",
      "cityCode": "Pidie Jaya",
      "provinceCode": "Aceh (NAD)"
    },
    {
      "city": "Pidie",
      "cityCode": "Pidie",
      "provinceCode": "Aceh (NAD)"
    },
    {
      "city": "Nagan Raya",
      "cityCode": "Nagan Raya",
      "provinceCode": "Aceh (NAD)"
    },
    {
      "city": "Gayo Lues",
      "cityCode": "Gayo Lues",
      "provinceCode": "Aceh (NAD)"
    },
    {
      "city": "Bireuen",
      "cityCode": "Bireuen",
      "provinceCode": "Aceh (NAD)"
    },
    {
      "city": "Bener Meriah",
      "cityCode": "Bener Meriah",
      "provinceCode": "Aceh (NAD)"
    },
    {
      "city": "Aceh Utara",
      "cityCode": "Aceh Utara",
      "provinceCode": "Aceh (NAD)"
    },
    {
      "city": "Aceh Timur",
      "cityCode": "Aceh Timur",
      "provinceCode": "Aceh (NAD)"
    },
    {
      "city": "Aceh Tenggara",
      "cityCode": "Aceh Tenggara",
      "provinceCode": "Aceh (NAD)"
    },
    {
      "city": "Aceh Tengah",
      "cityCode": "Aceh Tengah",
      "provinceCode": "Aceh (NAD)"
    },
    {
      "city": "Aceh Tamiang",
      "cityCode": "Aceh Tamiang",
      "provinceCode": "Aceh (NAD)"
    },
    {
      "city": "Aceh Singkil",
      "cityCode": "Aceh Singkil",
      "provinceCode": "Aceh (NAD)"
    },
    {
      "city": "Aceh Selatan",
      "cityCode": "Aceh Selatan",
      "provinceCode": "Aceh (NAD)"
    },
    {
      "city": "Aceh Jaya",
      "cityCode": "Aceh Jaya",
      "provinceCode": "Aceh (NAD)"
    },
    {
      "city": "Aceh Besar",
      "cityCode": "Aceh Besar",
      "provinceCode": "Aceh (NAD)"
    },
    {
      "city": "Aceh Barat Daya",
      "cityCode": "Aceh Barat Daya",
      "provinceCode": "Aceh (NAD)"
    },
    {
      "city": "Aceh Barat",
      "cityCode": "Aceh Barat",
      "provinceCode": "Aceh (NAD)"
    },
    {
      "city": "Subulussalam",
      "cityCode": "Subulussalam",
      "provinceCode": "Aceh (NAD)"
    },
    {
      "city": "Sabang",
      "cityCode": "Sabang",
      "provinceCode": "Aceh (NAD)"
    },
    {
      "city": "Lhokseumawe",
      "cityCode": "Lhokseumawe",
      "provinceCode": "Aceh (NAD)"
    },
    {
      "city": "Langsa",
      "cityCode": "Langsa",
      "provinceCode": "Aceh (NAD)"
    },
    {
      "city": "Banda Aceh",
      "cityCode": "Banda Aceh",
      "provinceCode": "Aceh (NAD)"
    },
    {
      "city": "Tabanan",
      "cityCode": "Tabanan",
      "provinceCode": "Bali"
    },
    {
      "city": "Klungkung",
      "cityCode": "Klungkung",
      "provinceCode": "Bali"
    },
    {
      "city": "Karangasem",
      "cityCode": "Karangasem",
      "provinceCode": "Bali"
    },
    {
      "city": "Jembrana",
      "cityCode": "Jembrana",
      "provinceCode": "Bali"
    },
    {
      "city": "Gianyar",
      "cityCode": "Gianyar",
      "provinceCode": "Bali"
    },
    {
      "city": "Buleleng",
      "cityCode": "Buleleng",
      "provinceCode": "Bali"
    },
    {
      "city": "Bangli",
      "cityCode": "Bangli",
      "provinceCode": "Bali"
    },
    {
      "city": "Badung",
      "cityCode": "Badung",
      "provinceCode": "Bali"
    },
    {
      "city": "Denpasar",
      "cityCode": "Denpasar",
      "provinceCode": "Bali"
    },
    {
      "city": "Tangerang",
      "cityCode": "Tangerang",
      "provinceCode": "Banten"
    },
    {
      "city": "Serang",
      "cityCode": "Serang",
      "provinceCode": "Banten"
    },
    {
      "city": "Pandeglang",
      "cityCode": "Pandeglang",
      "provinceCode": "Banten"
    },
    {
      "city": "Lebak",
      "cityCode": "Lebak",
      "provinceCode": "Banten"
    },
    {
      "city": "Tangerang Selatan",
      "cityCode": "Tangerang Selatan",
      "provinceCode": "Banten"
    },
    {
      "city": "Tangerang",
      "cityCode": "Tangerang",
      "provinceCode": "Banten"
    },
    {
      "city": "Serang",
      "cityCode": "Serang",
      "provinceCode": "Banten"
    },
    {
      "city": "Cilegon",
      "cityCode": "Cilegon",
      "provinceCode": "Banten"
    },
    {
      "city": "Seluma",
      "cityCode": "Seluma",
      "provinceCode": "Bengkulu"
    },
    {
      "city": "Rejang Lebong",
      "cityCode": "Rejang Lebong",
      "provinceCode": "Bengkulu"
    },
    {
      "city": "Muko Muko",
      "cityCode": "Muko Muko",
      "provinceCode": "Bengkulu"
    },
    {
      "city": "Lebong",
      "cityCode": "Lebong",
      "provinceCode": "Bengkulu"
    },
    {
      "city": "Kepahiang",
      "cityCode": "Kepahiang",
      "provinceCode": "Bengkulu"
    },
    {
      "city": "Kaur",
      "cityCode": "Kaur",
      "provinceCode": "Bengkulu"
    },
    {
      "city": "Bengkulu Utara",
      "cityCode": "Bengkulu Utara",
      "provinceCode": "Bengkulu"
    },
    {
      "city": "Bengkulu Tengah",
      "cityCode": "Bengkulu Tengah",
      "provinceCode": "Bengkulu"
    },
    {
      "city": "Bengkulu Selatan",
      "cityCode": "Bengkulu Selatan",
      "provinceCode": "Bengkulu"
    },
    {
      "city": "Bengkulu",
      "cityCode": "Bengkulu",
      "provinceCode": "Bengkulu"
    },
    {
      "city": "Sleman",
      "cityCode": "Sleman",
      "provinceCode": "DI Yogyakarta"
    },
    {
      "city": "Kulon Progo",
      "cityCode": "Kulon Progo",
      "provinceCode": "DI Yogyakarta"
    },
    {
      "city": "Gunung Kidul",
      "cityCode": "Gunung Kidul",
      "provinceCode": "DI Yogyakarta"
    },
    {
      "city": "Bantul",
      "cityCode": "Bantul",
      "provinceCode": "DI Yogyakarta"
    },
    {
      "city": "Yogyakarta",
      "cityCode": "Yogyakarta",
      "provinceCode": "DI Yogyakarta"
    },
    {
      "city": "Kepulauan Seribu",
      "cityCode": "Kepulauan Seribu",
      "provinceCode": "DKI Jakarta"
    },
    {
      "city": "Jakarta Utara",
      "cityCode": "Jakarta Utara",
      "provinceCode": "DKI Jakarta"
    },
    {
      "city": "Jakarta Timur",
      "cityCode": "Jakarta Timur",
      "provinceCode": "DKI Jakarta"
    },
    {
      "city": "Jakarta Selatan",
      "cityCode": "Jakarta Selatan",
      "provinceCode": "DKI Jakarta"
    },
    {
      "city": "Jakarta Pusat",
      "cityCode": "Jakarta Pusat",
      "provinceCode": "DKI Jakarta"
    },
    {
      "city": "Jakarta Barat",
      "cityCode": "Jakarta Barat",
      "provinceCode": "DKI Jakarta"
    },
    {
      "city": "Pohuwato",
      "cityCode": "Pohuwato",
      "provinceCode": "Gorontalo"
    },
    {
      "city": "Gorontalo Utara",
      "cityCode": "Gorontalo Utara",
      "provinceCode": "Gorontalo"
    },
    {
      "city": "Gorontalo",
      "cityCode": "Gorontalo",
      "provinceCode": "Gorontalo"
    },
    {
      "city": "Bone Bolango",
      "cityCode": "Bone Bolango",
      "provinceCode": "Gorontalo"
    },
    {
      "city": "Boalemo",
      "cityCode": "Boalemo",
      "provinceCode": "Gorontalo"
    },
    {
      "city": "Gorontalo",
      "cityCode": "Gorontalo",
      "provinceCode": "Gorontalo"
    },
    {
      "city": "Tebo",
      "cityCode": "Tebo",
      "provinceCode": "Jambi"
    },
    {
      "city": "Tanjung Jabung Timur",
      "cityCode": "Tanjung Jabung Timur",
      "provinceCode": "Jambi"
    },
    {
      "city": "Tanjung Jabung Barat",
      "cityCode": "Tanjung Jabung Barat",
      "provinceCode": "Jambi"
    },
    {
      "city": "Sarolangun",
      "cityCode": "Sarolangun",
      "provinceCode": "Jambi"
    },
    {
      "city": "Muaro Jambi",
      "cityCode": "Muaro Jambi",
      "provinceCode": "Jambi"
    },
    {
      "city": "Merangin",
      "cityCode": "Merangin",
      "provinceCode": "Jambi"
    },
    {
      "city": "Kerinci",
      "cityCode": "Kerinci",
      "provinceCode": "Jambi"
    },
    {
      "city": "Bungo",
      "cityCode": "Bungo",
      "provinceCode": "Jambi"
    },
    {
      "city": "Batang Hari",
      "cityCode": "Batang Hari",
      "provinceCode": "Jambi"
    },
    {
      "city": "Sungaipenuh",
      "cityCode": "Sungaipenuh",
      "provinceCode": "Jambi"
    },
    {
      "city": "Jambi",
      "cityCode": "Jambi",
      "provinceCode": "Jambi"
    },
    {
      "city": "Tasikmalaya",
      "cityCode": "Tasikmalaya",
      "provinceCode": "Jawa Barat"
    },
    {
      "city": "Sumedang",
      "cityCode": "Sumedang",
      "provinceCode": "Jawa Barat"
    },
    {
      "city": "Sukabumi",
      "cityCode": "Sukabumi",
      "provinceCode": "Jawa Barat"
    },
    {
      "city": "Subang",
      "cityCode": "Subang",
      "provinceCode": "Jawa Barat"
    },
    {
      "city": "Purwakarta",
      "cityCode": "Purwakarta",
      "provinceCode": "Jawa Barat"
    },
    {
      "city": "Pangandaran",
      "cityCode": "Pangandaran",
      "provinceCode": "Jawa Barat"
    },
    {
      "city": "Majalengka",
      "cityCode": "Majalengka",
      "provinceCode": "Jawa Barat"
    },
    {
      "city": "Kuningan",
      "cityCode": "Kuningan",
      "provinceCode": "Jawa Barat"
    },
    {
      "city": "Karawang",
      "cityCode": "Karawang",
      "provinceCode": "Jawa Barat"
    },
    {
      "city": "Indramayu",
      "cityCode": "Indramayu",
      "provinceCode": "Jawa Barat"
    },
    {
      "city": "Garut",
      "cityCode": "Garut",
      "provinceCode": "Jawa Barat"
    },
    {
      "city": "Cirebon",
      "cityCode": "Cirebon",
      "provinceCode": "Jawa Barat"
    },
    {
      "city": "Cianjur",
      "cityCode": "Cianjur",
      "provinceCode": "Jawa Barat"
    },
    {
      "city": "Ciamis",
      "cityCode": "Ciamis",
      "provinceCode": "Jawa Barat"
    },
    {
      "city": "Bogor",
      "cityCode": "Bogor",
      "provinceCode": "Jawa Barat"
    },
    {
      "city": "Bekasi",
      "cityCode": "Bekasi",
      "provinceCode": "Jawa Barat"
    },
    {
      "city": "Bandung Barat",
      "cityCode": "Bandung Barat",
      "provinceCode": "Jawa Barat"
    },
    {
      "city": "Bandung",
      "cityCode": "Bandung",
      "provinceCode": "Jawa Barat"
    },
    {
      "city": "Tasikmalaya",
      "cityCode": "Tasikmalaya",
      "provinceCode": "Jawa Barat"
    },
    {
      "city": "Sukabumi",
      "cityCode": "Sukabumi",
      "provinceCode": "Jawa Barat"
    },
    {
      "city": "Depok",
      "cityCode": "Depok",
      "provinceCode": "Jawa Barat"
    },
    {
      "city": "Cirebon",
      "cityCode": "Cirebon",
      "provinceCode": "Jawa Barat"
    },
    {
      "city": "Cimahi",
      "cityCode": "Cimahi",
      "provinceCode": "Jawa Barat"
    },
    {
      "city": "Bogor",
      "cityCode": "Bogor",
      "provinceCode": "Jawa Barat"
    },
    {
      "city": "Bekasi",
      "cityCode": "Bekasi",
      "provinceCode": "Jawa Barat"
    },
    {
      "city": "Banjar",
      "cityCode": "Banjar",
      "provinceCode": "Jawa Barat"
    },
    {
      "city": "Bandung",
      "cityCode": "Bandung",
      "provinceCode": "Jawa Barat"
    },
    {
      "city": "Wonosobo",
      "cityCode": "Wonosobo",
      "provinceCode": "Jawa Tengah"
    },
    {
      "city": "Wonogiri",
      "cityCode": "Wonogiri",
      "provinceCode": "Jawa Tengah"
    },
    {
      "city": "Temanggung",
      "cityCode": "Temanggung",
      "provinceCode": "Jawa Tengah"
    },
    {
      "city": "Tegal",
      "cityCode": "Tegal",
      "provinceCode": "Jawa Tengah"
    },
    {
      "city": "Sukoharjo",
      "cityCode": "Sukoharjo",
      "provinceCode": "Jawa Tengah"
    },
    {
      "city": "Sragen",
      "cityCode": "Sragen",
      "provinceCode": "Jawa Tengah"
    },
    {
      "city": "Semarang",
      "cityCode": "Semarang",
      "provinceCode": "Jawa Tengah"
    },
    {
      "city": "Rembang",
      "cityCode": "Rembang",
      "provinceCode": "Jawa Tengah"
    },
    {
      "city": "Purworejo",
      "cityCode": "Purworejo",
      "provinceCode": "Jawa Tengah"
    },
    {
      "city": "Purbalingga",
      "cityCode": "Purbalingga",
      "provinceCode": "Jawa Tengah"
    },
    {
      "city": "Pemalang",
      "cityCode": "Pemalang",
      "provinceCode": "Jawa Tengah"
    },
    {
      "city": "Pekalongan",
      "cityCode": "Pekalongan",
      "provinceCode": "Jawa Tengah"
    },
    {
      "city": "Pati",
      "cityCode": "Pati",
      "provinceCode": "Jawa Tengah"
    },
    {
      "city": "Magelang",
      "cityCode": "Magelang",
      "provinceCode": "Jawa Tengah"
    },
    {
      "city": "Kudus",
      "cityCode": "Kudus",
      "provinceCode": "Jawa Tengah"
    },
    {
      "city": "Klaten",
      "cityCode": "Klaten",
      "provinceCode": "Jawa Tengah"
    },
    {
      "city": "Kendal",
      "cityCode": "Kendal",
      "provinceCode": "Jawa Tengah"
    },
    {
      "city": "Kebumen",
      "cityCode": "Kebumen",
      "provinceCode": "Jawa Tengah"
    },
    {
      "city": "Karanganyar",
      "cityCode": "Karanganyar",
      "provinceCode": "Jawa Tengah"
    },
    {
      "city": "Jepara",
      "cityCode": "Jepara",
      "provinceCode": "Jawa Tengah"
    },
    {
      "city": "Grobogan",
      "cityCode": "Grobogan",
      "provinceCode": "Jawa Tengah"
    },
    {
      "city": "Demak",
      "cityCode": "Demak",
      "provinceCode": "Jawa Tengah"
    },
    {
      "city": "Cilacap",
      "cityCode": "Cilacap",
      "provinceCode": "Jawa Tengah"
    },
    {
      "city": "Brebes",
      "cityCode": "Brebes",
      "provinceCode": "Jawa Tengah"
    },
    {
      "city": "Boyolali",
      "cityCode": "Boyolali",
      "provinceCode": "Jawa Tengah"
    },
    {
      "city": "Blora",
      "cityCode": "Blora",
      "provinceCode": "Jawa Tengah"
    },
    {
      "city": "Batang",
      "cityCode": "Batang",
      "provinceCode": "Jawa Tengah"
    },
    {
      "city": "Banyumas",
      "cityCode": "Banyumas",
      "provinceCode": "Jawa Tengah"
    },
    {
      "city": "Banjarnegara",
      "cityCode": "Banjarnegara",
      "provinceCode": "Jawa Tengah"
    },
    {
      "city": "Tegal",
      "cityCode": "Tegal",
      "provinceCode": "Jawa Tengah"
    },
    {
      "city": "Surakarta(solo)",
      "cityCode": "Surakarta(solo)",
      "provinceCode": "Jawa Tengah"
    },
    {
      "city": "Semarang",
      "cityCode": "Semarang",
      "provinceCode": "Jawa Tengah"
    },
    {
      "city": "Salatiga",
      "cityCode": "Salatiga",
      "provinceCode": "Jawa Tengah"
    },
    {
      "city": "Pekalongan",
      "cityCode": "Pekalongan",
      "provinceCode": "Jawa Tengah"
    },
    {
      "city": "Magelang",
      "cityCode": "Magelang",
      "provinceCode": "Jawa Tengah"
    },
    {
      "city": "Tulungagung",
      "cityCode": "Tulungagung",
      "provinceCode": "Jawa Timur"
    },
    {
      "city": "Tuban",
      "cityCode": "Tuban",
      "provinceCode": "Jawa Timur"
    },
    {
      "city": "Trenggalek",
      "cityCode": "Trenggalek",
      "provinceCode": "Jawa Timur"
    },
    {
      "city": "Sumenep",
      "cityCode": "Sumenep",
      "provinceCode": "Jawa Timur"
    },
    {
      "city": "Situbondo",
      "cityCode": "Situbondo",
      "provinceCode": "Jawa Timur"
    },
    {
      "city": "Sidoarjo",
      "cityCode": "Sidoarjo",
      "provinceCode": "Jawa Timur"
    },
    {
      "city": "Sampang",
      "cityCode": "Sampang",
      "provinceCode": "Jawa Timur"
    },
    {
      "city": "Probolinggo",
      "cityCode": "Probolinggo",
      "provinceCode": "Jawa Timur"
    },
    {
      "city": "Ponorogo",
      "cityCode": "Ponorogo",
      "provinceCode": "Jawa Timur"
    },
    {
      "city": "Pasuruan",
      "cityCode": "Pasuruan",
      "provinceCode": "Jawa Timur"
    },
    {
      "city": "Pamekasan",
      "cityCode": "Pamekasan",
      "provinceCode": "Jawa Timur"
    },
    {
      "city": "Pacitan",
      "cityCode": "Pacitan",
      "provinceCode": "Jawa Timur"
    },
    {
      "city": "Ngawi",
      "cityCode": "Ngawi",
      "provinceCode": "Jawa Timur"
    },
    {
      "city": "Nganjuk",
      "cityCode": "Nganjuk",
      "provinceCode": "Jawa Timur"
    },
    {
      "city": "Mojokerto",
      "cityCode": "Mojokerto",
      "provinceCode": "Jawa Timur"
    },
    {
      "city": "Malang",
      "cityCode": "Malang",
      "provinceCode": "Jawa Timur"
    },
    {
      "city": "Magetan",
      "cityCode": "Magetan",
      "provinceCode": "Jawa Timur"
    },
    {
      "city": "Madiun",
      "cityCode": "Madiun",
      "provinceCode": "Jawa Timur"
    },
    {
      "city": "Lumajang",
      "cityCode": "Lumajang",
      "provinceCode": "Jawa Timur"
    },
    {
      "city": "Lamongan",
      "cityCode": "Lamongan",
      "provinceCode": "Jawa Timur"
    },
    {
      "city": "Kediri",
      "cityCode": "Kediri",
      "provinceCode": "Jawa Timur"
    },
    {
      "city": "Jombang",
      "cityCode": "Jombang",
      "provinceCode": "Jawa Timur"
    },
    {
      "city": "Jember",
      "cityCode": "Jember",
      "provinceCode": "Jawa Timur"
    },
    {
      "city": "Gresik",
      "cityCode": "Gresik",
      "provinceCode": "Jawa Timur"
    },
    {
      "city": "Bondowoso",
      "cityCode": "Bondowoso",
      "provinceCode": "Jawa Timur"
    },
    {
      "city": "Bojonegoro",
      "cityCode": "Bojonegoro",
      "provinceCode": "Jawa Timur"
    },
    {
      "city": "Blitar",
      "cityCode": "Blitar",
      "provinceCode": "Jawa Timur"
    },
    {
      "city": "Banyuwangi",
      "cityCode": "Banyuwangi",
      "provinceCode": "Jawa Timur"
    },
    {
      "city": "Bangkalan",
      "cityCode": "Bangkalan",
      "provinceCode": "Jawa Timur"
    },
    {
      "city": "Surabaya",
      "cityCode": "Surabaya",
      "provinceCode": "Jawa Timur"
    },
    {
      "city": "Probolinggo",
      "cityCode": "Probolinggo",
      "provinceCode": "Jawa Timur"
    },
    {
      "city": "Pasuruan",
      "cityCode": "Pasuruan",
      "provinceCode": "Jawa Timur"
    },
    {
      "city": "Mojokerto",
      "cityCode": "Mojokerto",
      "provinceCode": "Jawa Timur"
    },
    {
      "city": "Malang",
      "cityCode": "Malang",
      "provinceCode": "Jawa Timur"
    },
    {
      "city": "Madiun",
      "cityCode": "Madiun",
      "provinceCode": "Jawa Timur"
    },
    {
      "city": "Kediri",
      "cityCode": "Kediri",
      "provinceCode": "Jawa Timur"
    },
    {
      "city": "Blitar",
      "cityCode": "Blitar",
      "provinceCode": "Jawa Timur"
    },
    {
      "city": "Batu",
      "cityCode": "Batu",
      "provinceCode": "Jawa Timur"
    },
    {
      "city": "Sintang",
      "cityCode": "Sintang",
      "provinceCode": "Kalimantan Barat"
    },
    {
      "city": "Sekadau",
      "cityCode": "Sekadau",
      "provinceCode": "Kalimantan Barat"
    },
    {
      "city": "Sanggau",
      "cityCode": "Sanggau",
      "provinceCode": "Kalimantan Barat"
    },
    {
      "city": "Sambas",
      "cityCode": "Sambas",
      "provinceCode": "Kalimantan Barat"
    },
    {
      "city": "Mempawah",
      "cityCode": "Mempawah",
      "provinceCode": "Kalimantan Barat"
    },
    {
      "city": "Melawi",
      "cityCode": "Melawi",
      "provinceCode": "Kalimantan Barat"
    },
    {
      "city": "Landak",
      "cityCode": "Landak",
      "provinceCode": "Kalimantan Barat"
    },
    {
      "city": "Kubu Raya",
      "cityCode": "Kubu Raya",
      "provinceCode": "Kalimantan Barat"
    },
    {
      "city": "Ketapang",
      "cityCode": "Ketapang",
      "provinceCode": "Kalimantan Barat"
    },
    {
      "city": "Kayong Utara",
      "cityCode": "Kayong Utara",
      "provinceCode": "Kalimantan Barat"
    },
    {
      "city": "Kapuas Hulu",
      "cityCode": "Kapuas Hulu",
      "provinceCode": "Kalimantan Barat"
    },
    {
      "city": "Bengkayang",
      "cityCode": "Bengkayang",
      "provinceCode": "Kalimantan Barat"
    },
    {
      "city": "Singkawang",
      "cityCode": "Singkawang",
      "provinceCode": "Kalimantan Barat"
    },
    {
      "city": "Pontianak",
      "cityCode": "Pontianak",
      "provinceCode": "Kalimantan Barat"
    },
    {
      "city": "Tapin",
      "cityCode": "Tapin",
      "provinceCode": "Kalimantan Selatan"
    },
    {
      "city": "Tanah Laut",
      "cityCode": "Tanah Laut",
      "provinceCode": "Kalimantan Selatan"
    },
    {
      "city": "Tanah Bumbu",
      "cityCode": "Tanah Bumbu",
      "provinceCode": "Kalimantan Selatan"
    },
    {
      "city": "Tabalong",
      "cityCode": "Tabalong",
      "provinceCode": "Kalimantan Selatan"
    },
    {
      "city": "Baru",
      "cityCode": "Baru",
      "provinceCode": "Kalimantan Selatan"
    },
    {
      "city": "Hulu Sungai Utara",
      "cityCode": "Hulu Sungai Utara",
      "provinceCode": "Kalimantan Selatan"
    },
    {
      "city": "Hulu Sungai Tengah",
      "cityCode": "Hulu Sungai Tengah",
      "provinceCode": "Kalimantan Selatan"
    },
    {
      "city": "Hulu Sungai Selatan",
      "cityCode": "Hulu Sungai Selatan",
      "provinceCode": "Kalimantan Selatan"
    },
    {
      "city": "Barito Kuala",
      "cityCode": "Barito Kuala",
      "provinceCode": "Kalimantan Selatan"
    },
    {
      "city": "Banjar",
      "cityCode": "Banjar",
      "provinceCode": "Kalimantan Selatan"
    },
    {
      "city": "Balangan",
      "cityCode": "Balangan",
      "provinceCode": "Kalimantan Selatan"
    },
    {
      "city": "Banjarmasin",
      "cityCode": "Banjarmasin",
      "provinceCode": "Kalimantan Selatan"
    },
    {
      "city": "Banjarbaru",
      "cityCode": "Banjarbaru",
      "provinceCode": "Kalimantan Selatan"
    },
    {
      "city": "Sukamara",
      "cityCode": "Sukamara",
      "provinceCode": "Kalimantan Tengah"
    },
    {
      "city": "Seruyan",
      "cityCode": "Seruyan",
      "provinceCode": "Kalimantan Tengah"
    },
    {
      "city": "Pulang Pisau",
      "cityCode": "Pulang Pisau",
      "provinceCode": "Kalimantan Tengah"
    },
    {
      "city": "Murung Raya",
      "cityCode": "Murung Raya",
      "provinceCode": "Kalimantan Tengah"
    },
    {
      "city": "Lamandau",
      "cityCode": "Lamandau",
      "provinceCode": "Kalimantan Tengah"
    },
    {
      "city": "waringin Timur",
      "cityCode": "waringin Timur",
      "provinceCode": "Kalimantan Tengah"
    },
    {
      "city": "waringin Barat",
      "cityCode": "waringin Barat",
      "provinceCode": "Kalimantan Tengah"
    },
    {
      "city": "Katingan",
      "cityCode": "Katingan",
      "provinceCode": "Kalimantan Tengah"
    },
    {
      "city": "Kapuas",
      "cityCode": "Kapuas",
      "provinceCode": "Kalimantan Tengah"
    },
    {
      "city": "Gunung Mas",
      "cityCode": "Gunung Mas",
      "provinceCode": "Kalimantan Tengah"
    },
    {
      "city": "Barito Utara",
      "cityCode": "Barito Utara",
      "provinceCode": "Kalimantan Tengah"
    },
    {
      "city": "Barito Timur",
      "cityCode": "Barito Timur",
      "provinceCode": "Kalimantan Tengah"
    },
    {
      "city": "Barito Selatan",
      "cityCode": "Barito Selatan",
      "provinceCode": "Kalimantan Tengah"
    },
    {
      "city": "Palangka Raya",
      "cityCode": "Palangka Raya",
      "provinceCode": "Kalimantan Tengah"
    },
    {
      "city": "Penajam Paser Utara",
      "cityCode": "Penajam Paser Utara",
      "provinceCode": "Kalimantan Timur"
    },
    {
      "city": "Paser",
      "cityCode": "Paser",
      "provinceCode": "Kalimantan Timur"
    },
    {
      "city": "Mahakam Ulu",
      "cityCode": "Mahakam Ulu",
      "provinceCode": "Kalimantan Timur"
    },
    {
      "city": "Kutai Timur",
      "cityCode": "Kutai Timur",
      "provinceCode": "Kalimantan Timur"
    },
    {
      "city": "Kutai Kartanegara",
      "cityCode": "Kutai Kartanegara",
      "provinceCode": "Kalimantan Timur"
    },
    {
      "city": "Kutai Barat",
      "cityCode": "Kutai Barat",
      "provinceCode": "Kalimantan Timur"
    },
    {
      "city": "Berau",
      "cityCode": "Berau",
      "provinceCode": "Kalimantan Timur"
    },
    {
      "city": "Samarinda",
      "cityCode": "Samarinda",
      "provinceCode": "Kalimantan Timur"
    },
    {
      "city": "Bontang",
      "cityCode": "Bontang",
      "provinceCode": "Kalimantan Timur"
    },
    {
      "city": "Balikpapan",
      "cityCode": "Balikpapan",
      "provinceCode": "Kalimantan Timur"
    },
    {
      "city": "Tana Tidung",
      "cityCode": "Tana Tidung",
      "provinceCode": "Kalimantan Utara"
    },
    {
      "city": "Nunukan",
      "cityCode": "Nunukan",
      "provinceCode": "Kalimantan Utara"
    },
    {
      "city": "Malinau",
      "cityCode": "Malinau",
      "provinceCode": "Kalimantan Utara"
    },
    {
      "city": "Bulungan",
      "cityCode": "Bulungan",
      "provinceCode": "Kalimantan Utara"
    },
    {
      "city": "Tarakan",
      "cityCode": "Tarakan",
      "provinceCode": "Kalimantan Utara"
    },
    {
      "city": "Belitung Timur",
      "cityCode": "Belitung Timur",
      "provinceCode": "Kepulauan Bangka Belitung"
    },
    {
      "city": "Belitung",
      "cityCode": "Belitung",
      "provinceCode": "Kepulauan Bangka Belitung"
    },
    {
      "city": "Bangka Tengah",
      "cityCode": "Bangka Tengah",
      "provinceCode": "Kepulauan Bangka Belitung"
    },
    {
      "city": "Bangka Selatan",
      "cityCode": "Bangka Selatan",
      "provinceCode": "Kepulauan Bangka Belitung"
    },
    {
      "city": "Bangka Barat",
      "cityCode": "Bangka Barat",
      "provinceCode": "Kepulauan Bangka Belitung"
    },
    {
      "city": "Bangka",
      "cityCode": "Bangka",
      "provinceCode": "Kepulauan Bangka Belitung"
    },
    {
      "city": "Pangkal Pinang",
      "cityCode": "Pangkal Pinang",
      "provinceCode": "Kepulauan Bangka Belitung"
    },
    {
      "city": "Natuna",
      "cityCode": "Natuna",
      "provinceCode": "Kepulauan Riau"
    },
    {
      "city": "Lingga",
      "cityCode": "Lingga",
      "provinceCode": "Kepulauan Riau"
    },
    {
      "city": "Kepulauan Anambas",
      "cityCode": "Kepulauan Anambas",
      "provinceCode": "Kepulauan Riau"
    },
    {
      "city": "Karimun",
      "cityCode": "Karimun",
      "provinceCode": "Kepulauan Riau"
    },
    {
      "city": "Bintan",
      "cityCode": "Bintan",
      "provinceCode": "Kepulauan Riau"
    },
    {
      "city": "Tanjung Pinang",
      "cityCode": "Tanjung Pinang",
      "provinceCode": "Kepulauan Riau"
    },
    {
      "city": "Batam",
      "cityCode": "Batam",
      "provinceCode": "Kepulauan Riau"
    },
    {
      "city": "Way Kanan",
      "cityCode": "Way Kanan",
      "provinceCode": "Lampung"
    },
    {
      "city": "Tulang Bawang Barat",
      "cityCode": "Tulang Bawang Barat",
      "provinceCode": "Lampung"
    },
    {
      "city": "Tulang Bawang",
      "cityCode": "Tulang Bawang",
      "provinceCode": "Lampung"
    },
    {
      "city": "Tanggamus",
      "cityCode": "Tanggamus",
      "provinceCode": "Lampung"
    },
    {
      "city": "Pringsewu",
      "cityCode": "Pringsewu",
      "provinceCode": "Lampung"
    },
    {
      "city": "Pesisir Barat",
      "cityCode": "Pesisir Barat",
      "provinceCode": "Lampung"
    },
    {
      "city": "Pesawaran",
      "cityCode": "Pesawaran",
      "provinceCode": "Lampung"
    },
    {
      "city": "Mesuji",
      "cityCode": "Mesuji",
      "provinceCode": "Lampung"
    },
    {
      "city": "Lampung Utara",
      "cityCode": "Lampung Utara",
      "provinceCode": "Lampung"
    },
    {
      "city": "Lampung Timur",
      "cityCode": "Lampung Timur",
      "provinceCode": "Lampung"
    },
    {
      "city": "Lampung Tengah",
      "cityCode": "Lampung Tengah",
      "provinceCode": "Lampung"
    },
    {
      "city": "Lampung Selatan",
      "cityCode": "Lampung Selatan",
      "provinceCode": "Lampung"
    },
    {
      "city": "Lampung Barat",
      "cityCode": "Lampung Barat",
      "provinceCode": "Lampung"
    },
    {
      "city": "Metro",
      "cityCode": "Metro",
      "provinceCode": "Lampung"
    },
    {
      "city": "Bandar Lampung",
      "cityCode": "Bandar Lampung",
      "provinceCode": "Lampung"
    },
    {
      "city": "Seram Bagian Timur",
      "cityCode": "Seram Bagian Timur",
      "provinceCode": "Maluku"
    },
    {
      "city": "Seram Bagian Barat",
      "cityCode": "Seram Bagian Barat",
      "provinceCode": "Maluku"
    },
    {
      "city": "Maluku Tenggara Barat",
      "cityCode": "Maluku Tenggara Barat",
      "provinceCode": "Maluku"
    },
    {
      "city": "Maluku Tenggara",
      "cityCode": "Maluku Tenggara",
      "provinceCode": "Maluku"
    },
    {
      "city": "Maluku Tengah",
      "cityCode": "Maluku Tengah",
      "provinceCode": "Maluku"
    },
    {
      "city": "Maluku Barat Daya",
      "cityCode": "Maluku Barat Daya",
      "provinceCode": "Maluku"
    },
    {
      "city": "Kepulauan Aru",
      "cityCode": "Kepulauan Aru",
      "provinceCode": "Maluku"
    },
    {
      "city": "Buru Selatan",
      "cityCode": "Buru Selatan",
      "provinceCode": "Maluku"
    },
    {
      "city": "Buru",
      "cityCode": "Buru",
      "provinceCode": "Maluku"
    },
    {
      "city": "Tual",
      "cityCode": "Tual",
      "provinceCode": "Maluku"
    },
    {
      "city": "Ambon",
      "cityCode": "Ambon",
      "provinceCode": "Maluku"
    },
    {
      "city": "Pulau Taliabu",
      "cityCode": "Pulau Taliabu",
      "provinceCode": "Maluku Utara"
    },
    {
      "city": "Pulau Morotai",
      "cityCode": "Pulau Morotai",
      "provinceCode": "Maluku Utara"
    },
    {
      "city": "Kepulauan Sula",
      "cityCode": "Kepulauan Sula",
      "provinceCode": "Maluku Utara"
    },
    {
      "city": "Halmahera Utara",
      "cityCode": "Halmahera Utara",
      "provinceCode": "Maluku Utara"
    },
    {
      "city": "Halmahera Timur",
      "cityCode": "Halmahera Timur",
      "provinceCode": "Maluku Utara"
    },
    {
      "city": "Halmahera Tengah",
      "cityCode": "Halmahera Tengah",
      "provinceCode": "Maluku Utara"
    },
    {
      "city": "Halmahera Selatan",
      "cityCode": "Halmahera Selatan",
      "provinceCode": "Maluku Utara"
    },
    {
      "city": "Halmahera Barat",
      "cityCode": "Halmahera Barat",
      "provinceCode": "Maluku Utara"
    },
    {
      "city": "Tidore Kepulauan",
      "cityCode": "Tidore Kepulauan",
      "provinceCode": "Maluku Utara"
    },
    {
      "city": "Ternate",
      "cityCode": "Ternate",
      "provinceCode": "Maluku Utara"
    },
    {
      "city": "Sumbawa Barat",
      "cityCode": "Sumbawa Barat",
      "provinceCode": "Nusa Tenggara Barat (NTB)"
    },
    {
      "city": "Sumbawa",
      "cityCode": "Sumbawa",
      "provinceCode": "Nusa Tenggara Barat (NTB)"
    },
    {
      "city": "Lombok Utara",
      "cityCode": "Lombok Utara",
      "provinceCode": "Nusa Tenggara Barat (NTB)"
    },
    {
      "city": "Lombok Timur",
      "cityCode": "Lombok Timur",
      "provinceCode": "Nusa Tenggara Barat (NTB)"
    },
    {
      "city": "Lombok Tengah",
      "cityCode": "Lombok Tengah",
      "provinceCode": "Nusa Tenggara Barat (NTB)"
    },
    {
      "city": "Lombok Barat",
      "cityCode": "Lombok Barat",
      "provinceCode": "Nusa Tenggara Barat (NTB)"
    },
    {
      "city": "Dompu",
      "cityCode": "Dompu",
      "provinceCode": "Nusa Tenggara Barat (NTB)"
    },
    {
      "city": "Bima",
      "cityCode": "Bima",
      "provinceCode": "Nusa Tenggara Barat (NTB)"
    },
    {
      "city": "Mataram",
      "cityCode": "Mataram",
      "provinceCode": "Nusa Tenggara Barat (NTB)"
    },
    {
      "city": "Bima",
      "cityCode": "Bima",
      "provinceCode": "Nusa Tenggara Barat (NTB)"
    },
    {
      "city": "Timor Tengah Utara",
      "cityCode": "Timor Tengah Utara",
      "provinceCode": "Nusa Tenggara Timur (NTT)"
    },
    {
      "city": "Timor Tengah Selatan",
      "cityCode": "Timor Tengah Selatan",
      "provinceCode": "Nusa Tenggara Timur (NTT)"
    },
    {
      "city": "Sumba Timur",
      "cityCode": "Sumba Timur",
      "provinceCode": "Nusa Tenggara Timur (NTT)"
    },
    {
      "city": "Sumba Tengah",
      "cityCode": "Sumba Tengah",
      "provinceCode": "Nusa Tenggara Timur (NTT)"
    },
    {
      "city": "Sumba Barat Daya",
      "cityCode": "Sumba Barat Daya",
      "provinceCode": "Nusa Tenggara Timur (NTT)"
    },
    {
      "city": "Sumba Barat",
      "cityCode": "Sumba Barat",
      "provinceCode": "Nusa Tenggara Timur (NTT)"
    },
    {
      "city": "Sikka",
      "cityCode": "Sikka",
      "provinceCode": "Nusa Tenggara Timur (NTT)"
    },
    {
      "city": "Sabu Raijua",
      "cityCode": "Sabu Raijua",
      "provinceCode": "Nusa Tenggara Timur (NTT)"
    },
    {
      "city": "Rote Ndao",
      "cityCode": "Rote Ndao",
      "provinceCode": "Nusa Tenggara Timur (NTT)"
    },
    {
      "city": "Ngada",
      "cityCode": "Ngada",
      "provinceCode": "Nusa Tenggara Timur (NTT)"
    },
    {
      "city": "Nagekeo",
      "cityCode": "Nagekeo",
      "provinceCode": "Nusa Tenggara Timur (NTT)"
    },
    {
      "city": "Manggarai Timur",
      "cityCode": "Manggarai Timur",
      "provinceCode": "Nusa Tenggara Timur (NTT)"
    },
    {
      "city": "Manggarai Barat",
      "cityCode": "Manggarai Barat",
      "provinceCode": "Nusa Tenggara Timur (NTT)"
    },
    {
      "city": "Manggarai",
      "cityCode": "Manggarai",
      "provinceCode": "Nusa Tenggara Timur (NTT)"
    },
    {
      "city": "Malaka",
      "cityCode": "Malaka",
      "provinceCode": "Nusa Tenggara Timur (NTT)"
    },
    {
      "city": "Lembata",
      "cityCode": "Lembata",
      "provinceCode": "Nusa Tenggara Timur (NTT)"
    },
    {
      "city": "Kupang",
      "cityCode": "Kupang",
      "provinceCode": "Nusa Tenggara Timur (NTT)"
    },
    {
      "city": "Flores Timur",
      "cityCode": "Flores Timur",
      "provinceCode": "Nusa Tenggara Timur (NTT)"
    },
    {
      "city": "Ende",
      "cityCode": "Ende",
      "provinceCode": "Nusa Tenggara Timur (NTT)"
    },
    {
      "city": "Belu",
      "cityCode": "Belu",
      "provinceCode": "Nusa Tenggara Timur (NTT)"
    },
    {
      "city": "Alor",
      "cityCode": "Alor",
      "provinceCode": "Nusa Tenggara Timur (NTT)"
    },
    {
      "city": "Kupang",
      "cityCode": "Kupang",
      "provinceCode": "Nusa Tenggara Timur (NTT)"
    },
    {
      "city": "Yalimo",
      "cityCode": "Yalimo",
      "provinceCode": "Papua"
    },
    {
      "city": "Yahukimo",
      "cityCode": "Yahukimo",
      "provinceCode": "Papua"
    },
    {
      "city": "Waropen",
      "cityCode": "Waropen",
      "provinceCode": "Papua"
    },
    {
      "city": "Tolikara",
      "cityCode": "Tolikara",
      "provinceCode": "Papua"
    },
    {
      "city": "Supiori",
      "cityCode": "Supiori",
      "provinceCode": "Papua"
    },
    {
      "city": "Sarmi",
      "cityCode": "Sarmi",
      "provinceCode": "Papua"
    },
    {
      "city": "Puncak Jaya",
      "cityCode": "Puncak Jaya",
      "provinceCode": "Papua"
    },
    {
      "city": "Puncak",
      "cityCode": "Puncak",
      "provinceCode": "Papua"
    },
    {
      "city": "Pegunungan Bintang",
      "cityCode": "Pegunungan Bintang",
      "provinceCode": "Papua"
    },
    {
      "city": "Paniai",
      "cityCode": "Paniai",
      "provinceCode": "Papua"
    },
    {
      "city": "Nduga",
      "cityCode": "Nduga",
      "provinceCode": "Papua"
    },
    {
      "city": "Nabire",
      "cityCode": "Nabire",
      "provinceCode": "Papua"
    },
    {
      "city": "Mimika",
      "cityCode": "Mimika",
      "provinceCode": "Papua"
    },
    {
      "city": "Merauke",
      "cityCode": "Merauke",
      "provinceCode": "Papua"
    },
    {
      "city": "Mappi",
      "cityCode": "Mappi",
      "provinceCode": "Papua"
    },
    {
      "city": "Mamberamo Tengah",
      "cityCode": "Mamberamo Tengah",
      "provinceCode": "Papua"
    },
    {
      "city": "Mamberamo Raya",
      "cityCode": "Mamberamo Raya",
      "provinceCode": "Papua"
    },
    {
      "city": "Lanny Jaya",
      "cityCode": "Lanny Jaya",
      "provinceCode": "Papua"
    },
    {
      "city": "Kepulauan Yapen",
      "cityCode": "Kepulauan Yapen",
      "provinceCode": "Papua"
    },
    {
      "city": "Keerom",
      "cityCode": "Keerom",
      "provinceCode": "Papua"
    },
    {
      "city": "Jayawijaya",
      "cityCode": "Jayawijaya",
      "provinceCode": "Papua"
    },
    {
      "city": "Jayapura",
      "cityCode": "Jayapura",
      "provinceCode": "Papua"
    },
    {
      "city": "Intan Jaya",
      "cityCode": "Intan Jaya",
      "provinceCode": "Papua"
    },
    {
      "city": "Dogiyai",
      "cityCode": "Dogiyai",
      "provinceCode": "Papua"
    },
    {
      "city": "Deiyai",
      "cityCode": "Deiyai",
      "provinceCode": "Papua"
    },
    {
      "city": "Boven Digoel",
      "cityCode": "Boven Digoel",
      "provinceCode": "Papua"
    },
    {
      "city": "Biak Numfor",
      "cityCode": "Biak Numfor",
      "provinceCode": "Papua"
    },
    {
      "city": "Asmat",
      "cityCode": "Asmat",
      "provinceCode": "Papua"
    },
    {
      "city": "Jayapura",
      "cityCode": "Jayapura",
      "provinceCode": "Papua"
    },
    {
      "city": "Teluk Wondama",
      "cityCode": "Teluk Wondama",
      "provinceCode": "Papua Barat"
    },
    {
      "city": "Teluk Bintuni",
      "cityCode": "Teluk Bintuni",
      "provinceCode": "Papua Barat"
    },
    {
      "city": "Tambrauw",
      "cityCode": "Tambrauw",
      "provinceCode": "Papua Barat"
    },
    {
      "city": "Sorong Selatan",
      "cityCode": "Sorong Selatan",
      "provinceCode": "Papua Barat"
    },
    {
      "city": "Sorong",
      "cityCode": "Sorong",
      "provinceCode": "Papua Barat"
    },
    {
      "city": "Raja Ampat",
      "cityCode": "Raja Ampat",
      "provinceCode": "Papua Barat"
    },
    {
      "city": "Pegunungan Arfak",
      "cityCode": "Pegunungan Arfak",
      "provinceCode": "Papua Barat"
    },
    {
      "city": "Maybrat",
      "cityCode": "Maybrat",
      "provinceCode": "Papua Barat"
    },
    {
      "city": "Manokwari Selatan",
      "cityCode": "Manokwari Selatan",
      "provinceCode": "Papua Barat"
    },
    {
      "city": "Manokwari",
      "cityCode": "Manokwari",
      "provinceCode": "Papua Barat"
    },
    {
      "city": "Kaimana",
      "cityCode": "Kaimana",
      "provinceCode": "Papua Barat"
    },
    {
      "city": "Fakfak",
      "cityCode": "Fakfak",
      "provinceCode": "Papua Barat"
    },
    {
      "city": "Sorong",
      "cityCode": "Sorong",
      "provinceCode": "Papua Barat"
    },
    {
      "city": "Siak",
      "cityCode": "Siak",
      "provinceCode": "Riau"
    },
    {
      "city": "Rokan Hulu",
      "cityCode": "Rokan Hulu",
      "provinceCode": "Riau"
    },
    {
      "city": "Rokan Hilir",
      "cityCode": "Rokan Hilir",
      "provinceCode": "Riau"
    },
    {
      "city": "Pelalawan",
      "cityCode": "Pelalawan",
      "provinceCode": "Riau"
    },
    {
      "city": "Kuantan Singingi",
      "cityCode": "Kuantan Singingi",
      "provinceCode": "Riau"
    },
    {
      "city": "Kepulauan Meranti",
      "cityCode": "Kepulauan Meranti",
      "provinceCode": "Riau"
    },
    {
      "city": "Kampar",
      "cityCode": "Kampar",
      "provinceCode": "Riau"
    },
    {
      "city": "Indragiri Hulu",
      "cityCode": "Indragiri Hulu",
      "provinceCode": "Riau"
    },
    {
      "city": "Indragiri Hilir",
      "cityCode": "Indragiri Hilir",
      "provinceCode": "Riau"
    },
    {
      "city": "Bengkalis",
      "cityCode": "Bengkalis",
      "provinceCode": "Riau"
    },
    {
      "city": "Pekanbaru",
      "cityCode": "Pekanbaru",
      "provinceCode": "Riau"
    },
    {
      "city": "Dumai",
      "cityCode": "Dumai",
      "provinceCode": "Riau"
    },
    {
      "city": "Polewali Mandar",
      "cityCode": "Polewali Mandar",
      "provinceCode": "Sulawesi Barat"
    },
    {
      "city": "Mamuju Utara",
      "cityCode": "Mamuju Utara",
      "provinceCode": "Sulawesi Barat"
    },
    {
      "city": "Mamuju Tengah",
      "cityCode": "Mamuju Tengah",
      "provinceCode": "Sulawesi Barat"
    },
    {
      "city": "Mamuju",
      "cityCode": "Mamuju",
      "provinceCode": "Sulawesi Barat"
    },
    {
      "city": "Mamasa",
      "cityCode": "Mamasa",
      "provinceCode": "Sulawesi Barat"
    },
    {
      "city": "Majene",
      "cityCode": "Majene",
      "provinceCode": "Sulawesi Barat"
    },
    {
      "city": "Wajo",
      "cityCode": "Wajo",
      "provinceCode": "Sulawesi Selatan"
    },
    {
      "city": "Toraja Utara",
      "cityCode": "Toraja Utara",
      "provinceCode": "Sulawesi Selatan"
    },
    {
      "city": "Tana Toraja",
      "cityCode": "Tana Toraja",
      "provinceCode": "Sulawesi Selatan"
    },
    {
      "city": "Takalar",
      "cityCode": "Takalar",
      "provinceCode": "Sulawesi Selatan"
    },
    {
      "city": "Soppeng",
      "cityCode": "Soppeng",
      "provinceCode": "Sulawesi Selatan"
    },
    {
      "city": "Sinjai",
      "cityCode": "Sinjai",
      "provinceCode": "Sulawesi Selatan"
    },
    {
      "city": "Sidenreng Rappang",
      "cityCode": "Sidenreng Rappang",
      "provinceCode": "Sulawesi Selatan"
    },
    {
      "city": "Pinrang",
      "cityCode": "Pinrang",
      "provinceCode": "Sulawesi Selatan"
    },
    {
      "city": "Pangkajene Kepulauan",
      "cityCode": "Pangkajene Kepulauan",
      "provinceCode": "Sulawesi Selatan"
    },
    {
      "city": "Maros",
      "cityCode": "Maros",
      "provinceCode": "Sulawesi Selatan"
    },
    {
      "city": "Luwu Utara",
      "cityCode": "Luwu Utara",
      "provinceCode": "Sulawesi Selatan"
    },
    {
      "city": "Luwu Timur",
      "cityCode": "Luwu Timur",
      "provinceCode": "Sulawesi Selatan"
    },
    {
      "city": "Luwu",
      "cityCode": "Luwu",
      "provinceCode": "Sulawesi Selatan"
    },
    {
      "city": "Kepulauan Selayar",
      "cityCode": "Kepulauan Selayar",
      "provinceCode": "Sulawesi Selatan"
    },
    {
      "city": "Jeneponto",
      "cityCode": "Jeneponto",
      "provinceCode": "Sulawesi Selatan"
    },
    {
      "city": "Gowa",
      "cityCode": "Gowa",
      "provinceCode": "Sulawesi Selatan"
    },
    {
      "city": "Enrekang",
      "cityCode": "Enrekang",
      "provinceCode": "Sulawesi Selatan"
    },
    {
      "city": "Bulukumba",
      "cityCode": "Bulukumba",
      "provinceCode": "Sulawesi Selatan"
    },
    {
      "city": "Bone",
      "cityCode": "Bone",
      "provinceCode": "Sulawesi Selatan"
    },
    {
      "city": "Barru",
      "cityCode": "Barru",
      "provinceCode": "Sulawesi Selatan"
    },
    {
      "city": "Bantaeng",
      "cityCode": "Bantaeng",
      "provinceCode": "Sulawesi Selatan"
    },
    {
      "city": "Parepare",
      "cityCode": "Parepare",
      "provinceCode": "Sulawesi Selatan"
    },
    {
      "city": "Palopo",
      "cityCode": "Palopo",
      "provinceCode": "Sulawesi Selatan"
    },
    {
      "city": "Makassar",
      "cityCode": "Makassar",
      "provinceCode": "Sulawesi Selatan"
    },
    {
      "city": "Toli-Toli",
      "cityCode": "Toli-Toli",
      "provinceCode": "Sulawesi Tengah"
    },
    {
      "city": "Tojo Una-Una",
      "cityCode": "Tojo Una-Una",
      "provinceCode": "Sulawesi Tengah"
    },
    {
      "city": "Sigi",
      "cityCode": "Sigi",
      "provinceCode": "Sulawesi Tengah"
    },
    {
      "city": "Poso",
      "cityCode": "Poso",
      "provinceCode": "Sulawesi Tengah"
    },
    {
      "city": "Parigi Moutong",
      "cityCode": "Parigi Moutong",
      "provinceCode": "Sulawesi Tengah"
    },
    {
      "city": "Morowali Utara",
      "cityCode": "Morowali Utara",
      "provinceCode": "Sulawesi Tengah"
    },
    {
      "city": "Morowali",
      "cityCode": "Morowali",
      "provinceCode": "Sulawesi Tengah"
    },
    {
      "city": "Donggala",
      "cityCode": "Donggala",
      "provinceCode": "Sulawesi Tengah"
    },
    {
      "city": "Buol",
      "cityCode": "Buol",
      "provinceCode": "Sulawesi Tengah"
    },
    {
      "city": "Banggai Laut",
      "cityCode": "Banggai Laut",
      "provinceCode": "Sulawesi Tengah"
    },
    {
      "city": "Banggai Kepulauan",
      "cityCode": "Banggai Kepulauan",
      "provinceCode": "Sulawesi Tengah"
    },
    {
      "city": "Banggai",
      "cityCode": "Banggai",
      "provinceCode": "Sulawesi Tengah"
    },
    {
      "city": "Palu",
      "cityCode": "Palu",
      "provinceCode": "Sulawesi Tengah"
    },
    {
      "city": "Wakatobi",
      "cityCode": "Wakatobi",
      "provinceCode": "Sulawesi Tenggara"
    },
    {
      "city": "Muna Barat",
      "cityCode": "Muna Barat",
      "provinceCode": "Sulawesi Tenggara"
    },
    {
      "city": "Muna",
      "cityCode": "Muna",
      "provinceCode": "Sulawesi Tenggara"
    },
    {
      "city": "Konawe Utara",
      "cityCode": "Konawe Utara",
      "provinceCode": "Sulawesi Tenggara"
    },
    {
      "city": "Konawe Selatan",
      "cityCode": "Konawe Selatan",
      "provinceCode": "Sulawesi Tenggara"
    },
    {
      "city": "Konawe Kepulauan",
      "cityCode": "Konawe Kepulauan",
      "provinceCode": "Sulawesi Tenggara"
    },
    {
      "city": "Konawe",
      "cityCode": "Konawe",
      "provinceCode": "Sulawesi Tenggara"
    },
    {
      "city": "Kolaka Utara",
      "cityCode": "Kolaka Utara",
      "provinceCode": "Sulawesi Tenggara"
    },
    {
      "city": "Kolaka Timur",
      "cityCode": "Kolaka Timur",
      "provinceCode": "Sulawesi Tenggara"
    },
    {
      "city": "Kolaka",
      "cityCode": "Kolaka",
      "provinceCode": "Sulawesi Tenggara"
    },
    {
      "city": "Buton Utara",
      "cityCode": "Buton Utara",
      "provinceCode": "Sulawesi Tenggara"
    },
    {
      "city": "Buton Tengah",
      "cityCode": "Buton Tengah",
      "provinceCode": "Sulawesi Tenggara"
    },
    {
      "city": "Buton Selatan",
      "cityCode": "Buton Selatan",
      "provinceCode": "Sulawesi Tenggara"
    },
    {
      "city": "Buton",
      "cityCode": "Buton",
      "provinceCode": "Sulawesi Tenggara"
    },
    {
      "city": "Bombana",
      "cityCode": "Bombana",
      "provinceCode": "Sulawesi Tenggara"
    },
    {
      "city": "Kendari",
      "cityCode": "Kendari",
      "provinceCode": "Sulawesi Tenggara"
    },
    {
      "city": "Kendari",
      "cityCode": "Kendari",
      "provinceCode": "Sulawesi Tenggara"
    },
    {
      "city": "Minahasa Utara",
      "cityCode": "Minahasa Utara",
      "provinceCode": "Sulawesi Utara"
    },
    {
      "city": "Minahasa Selatan",
      "cityCode": "Minahasa Selatan",
      "provinceCode": "Sulawesi Utara"
    },
    {
      "city": "Minahasa",
      "cityCode": "Minahasa",
      "provinceCode": "Sulawesi Utara"
    },
    {
      "city": "Kepulauan Talaud",
      "cityCode": "Kepulauan Talaud",
      "provinceCode": "Sulawesi Utara"
    },
    {
      "city": "Kepulauan Siau Tagulandang Biaro (Sitaro)",
      "cityCode": "Kepulauan Siau Tagulandang Biaro (Sitaro)",
      "provinceCode": "Sulawesi Utara"
    },
    {
      "city": "Kepulauan Sangihe",
      "cityCode": "Kepulauan Sangihe",
      "provinceCode": "Sulawesi Utara"
    },
    {
      "city": "Bolaang Mongondow Utara",
      "cityCode": "Bolaang Mongondow Utara",
      "provinceCode": "Sulawesi Utara"
    },
    {
      "city": "Bolaang Mongondow Timur",
      "cityCode": "Bolaang Mongondow Timur",
      "provinceCode": "Sulawesi Utara"
    },
    {
      "city": "Bolaang Mongondow Selatan",
      "cityCode": "Bolaang Mongondow Selatan",
      "provinceCode": "Sulawesi Utara"
    },
    {
      "city": "Bolaang Mongondow",
      "cityCode": "Bolaang Mongondow",
      "provinceCode": "Sulawesi Utara"
    },
    {
      "city": "Tomohon",
      "cityCode": "Tomohon",
      "provinceCode": "Sulawesi Utara"
    },
    {
      "city": "Manado",
      "cityCode": "Manado",
      "provinceCode": "Sulawesi Utara"
    },
    {
      "city": "mobagu",
      "cityCode": "mobagu",
      "provinceCode": "Sulawesi Utara"
    },
    {
      "city": "Bitung",
      "cityCode": "Bitung",
      "provinceCode": "Sulawesi Utara"
    },
    {
      "city": "Tanah Datar",
      "cityCode": "Tanah Datar",
      "provinceCode": "Sumatera Barat"
    },
    {
      "city": "Solok Selatan",
      "cityCode": "Solok Selatan",
      "provinceCode": "Sumatera Barat"
    },
    {
      "city": "Solok",
      "cityCode": "Solok",
      "provinceCode": "Sumatera Barat"
    },
    {
      "city": "Sijunjung",
      "cityCode": "Sijunjung",
      "provinceCode": "Sumatera Barat"
    },
    {
      "city": "Pesisir Selatan",
      "cityCode": "Pesisir Selatan",
      "provinceCode": "Sumatera Barat"
    },
    {
      "city": "Pasaman Barat",
      "cityCode": "Pasaman Barat",
      "provinceCode": "Sumatera Barat"
    },
    {
      "city": "Pasaman",
      "cityCode": "Pasaman",
      "provinceCode": "Sumatera Barat"
    },
    {
      "city": "Padang Pariaman",
      "cityCode": "Padang Pariaman",
      "provinceCode": "Sumatera Barat"
    },
    {
      "city": "Lima Puluh",
      "cityCode": "Lima Puluh",
      "provinceCode": "Sumatera Barat"
    },
    {
      "city": "Kepulauan Mentawai",
      "cityCode": "Kepulauan Mentawai",
      "provinceCode": "Sumatera Barat"
    },
    {
      "city": "Dharmasraya",
      "cityCode": "Dharmasraya",
      "provinceCode": "Sumatera Barat"
    },
    {
      "city": "Agam",
      "cityCode": "Agam",
      "provinceCode": "Sumatera Barat"
    },
    {
      "city": "Solok",
      "cityCode": "Solok",
      "provinceCode": "Sumatera Barat"
    },
    {
      "city": "Sawah Lunto",
      "cityCode": "Sawah Lunto",
      "provinceCode": "Sumatera Barat"
    },
    {
      "city": "Payakumbuh",
      "cityCode": "Payakumbuh",
      "provinceCode": "Sumatera Barat"
    },
    {
      "city": "Pariaman",
      "cityCode": "Pariaman",
      "provinceCode": "Sumatera Barat"
    },
    {
      "city": "Padang Panjang",
      "cityCode": "Padang Panjang",
      "provinceCode": "Sumatera Barat"
    },
    {
      "city": "Padang",
      "cityCode": "Padang",
      "provinceCode": "Sumatera Barat"
    },
    {
      "city": "Bukittinggi",
      "cityCode": "Bukittinggi",
      "provinceCode": "Sumatera Barat"
    },
    {
      "city": "Penukal Abab Lematang Ilir",
      "cityCode": "Penukal Abab Lematang Ilir",
      "provinceCode": "Sumatera Selatan"
    },
    {
      "city": "Ogan Komering Ulu Timur",
      "cityCode": "Ogan Komering Ulu Timur",
      "provinceCode": "Sumatera Selatan"
    },
    {
      "city": "Ogan Komering Ulu Selatan",
      "cityCode": "Ogan Komering Ulu Selatan",
      "provinceCode": "Sumatera Selatan"
    },
    {
      "city": "Ogan Komering Ulu",
      "cityCode": "Ogan Komering Ulu",
      "provinceCode": "Sumatera Selatan"
    },
    {
      "city": "Ogan Komering Ilir",
      "cityCode": "Ogan Komering Ilir",
      "provinceCode": "Sumatera Selatan"
    },
    {
      "city": "Ogan Ilir",
      "cityCode": "Ogan Ilir",
      "provinceCode": "Sumatera Selatan"
    },
    {
      "city": "Musi Rawas Utara",
      "cityCode": "Musi Rawas Utara",
      "provinceCode": "Sumatera Selatan"
    },
    {
      "city": "Musi Rawas",
      "cityCode": "Musi Rawas",
      "provinceCode": "Sumatera Selatan"
    },
    {
      "city": "Musi Banyuasin",
      "cityCode": "Musi Banyuasin",
      "provinceCode": "Sumatera Selatan"
    },
    {
      "city": "Muara Enim",
      "cityCode": "Muara Enim",
      "provinceCode": "Sumatera Selatan"
    },
    {
      "city": "Lahat",
      "cityCode": "Lahat",
      "provinceCode": "Sumatera Selatan"
    },
    {
      "city": "Empat Lawang",
      "cityCode": "Empat Lawang",
      "provinceCode": "Sumatera Selatan"
    },
    {
      "city": "Banyuasin",
      "cityCode": "Banyuasin",
      "provinceCode": "Sumatera Selatan"
    },
    {
      "city": "Prabumulih",
      "cityCode": "Prabumulih",
      "provinceCode": "Sumatera Selatan"
    },
    {
      "city": "Palembang",
      "cityCode": "Palembang",
      "provinceCode": "Sumatera Selatan"
    },
    {
      "city": "Pagar Alam",
      "cityCode": "Pagar Alam",
      "provinceCode": "Sumatera Selatan"
    },
    {
      "city": "Lubuk Linggau",
      "cityCode": "Lubuk Linggau",
      "provinceCode": "Sumatera Selatan"
    },
    {
      "city": "Toba Samosir",
      "cityCode": "Toba Samosir",
      "provinceCode": "Sumatera Utara"
    },
    {
      "city": "Tapanuli Utara",
      "cityCode": "Tapanuli Utara",
      "provinceCode": "Sumatera Utara"
    },
    {
      "city": "Tapanuli Tengah",
      "cityCode": "Tapanuli Tengah",
      "provinceCode": "Sumatera Utara"
    },
    {
      "city": "Tapanuli Selatan",
      "cityCode": "Tapanuli Selatan",
      "provinceCode": "Sumatera Utara"
    },
    {
      "city": "Simalungun",
      "cityCode": "Simalungun",
      "provinceCode": "Sumatera Utara"
    },
    {
      "city": "Serdang Bedagai",
      "cityCode": "Serdang Bedagai",
      "provinceCode": "Sumatera Utara"
    },
    {
      "city": "Samosir",
      "cityCode": "Samosir",
      "provinceCode": "Sumatera Utara"
    },
    {
      "city": "Pakpak Bharat",
      "cityCode": "Pakpak Bharat",
      "provinceCode": "Sumatera Utara"
    },
    {
      "city": "Padang Lawas Utara",
      "cityCode": "Padang Lawas Utara",
      "provinceCode": "Sumatera Utara"
    },
    {
      "city": "Padang Lawas",
      "cityCode": "Padang Lawas",
      "provinceCode": "Sumatera Utara"
    },
    {
      "city": "Nias Utara",
      "cityCode": "Nias Utara",
      "provinceCode": "Sumatera Utara"
    },
    {
      "city": "Nias Selatan",
      "cityCode": "Nias Selatan",
      "provinceCode": "Sumatera Utara"
    },
    {
      "city": "Nias Barat",
      "cityCode": "Nias Barat",
      "provinceCode": "Sumatera Utara"
    },
    {
      "city": "Nias",
      "cityCode": "Nias",
      "provinceCode": "Sumatera Utara"
    },
    {
      "city": "Mandailing Natal",
      "cityCode": "Mandailing Natal",
      "provinceCode": "Sumatera Utara"
    },
    {
      "city": "Langkat",
      "cityCode": "Langkat",
      "provinceCode": "Sumatera Utara"
    },
    {
      "city": "Labuhan batu Utara",
      "cityCode": "Labuhan batu Utara",
      "provinceCode": "Sumatera Utara"
    },
    {
      "city": "Labuhan batu Selatan",
      "cityCode": "Labuhan batu Selatan",
      "provinceCode": "Sumatera Utara"
    },
    {
      "city": "Labuhan batu",
      "cityCode": "Labuhan batu",
      "provinceCode": "Sumatera Utara"
    },
    {
      "city": "Karo",
      "cityCode": "Karo",
      "provinceCode": "Sumatera Utara"
    },
    {
      "city": "Humbang Hasundutan",
      "cityCode": "Humbang Hasundutan",
      "provinceCode": "Sumatera Utara"
    },
    {
      "city": "Deli Serdang",
      "cityCode": "Deli Serdang",
      "provinceCode": "Sumatera Utara"
    },
    {
      "city": "Dairi",
      "cityCode": "Dairi",
      "provinceCode": "Sumatera Utara"
    },
    {
      "city": "Batu Bara",
      "cityCode": "Batu Bara",
      "provinceCode": "Sumatera Utara"
    },
    {
      "city": "Asahan",
      "cityCode": "Asahan",
      "provinceCode": "Sumatera Utara"
    },
    {
      "city": "Tebing Tinggi",
      "cityCode": "Tebing Tinggi",
      "provinceCode": "Sumatera Utara"
    },
    {
      "city": "Tanjung Balai",
      "cityCode": "Tanjung Balai",
      "provinceCode": "Sumatera Utara"
    },
    {
      "city": "Sibolga",
      "cityCode": "Sibolga",
      "provinceCode": "Sumatera Utara"
    },
    {
      "city": "Pematang Siantar",
      "cityCode": "Pematang Siantar",
      "provinceCode": "Sumatera Utara"
    },
    {
      "city": "Padang Sidempuan",
      "cityCode": "Padang Sidempuan",
      "provinceCode": "Sumatera Utara"
    },
    {
      "city": "Medan",
      "cityCode": "Medan",
      "provinceCode": "Sumatera Utara"
    },
    {
      "city": "Gunungsitoli",
      "cityCode": "Gunungsitoli",
      "provinceCode": "Sumatera Utara"
    },
    {
      "city": "Binjai",
      "cityCode": "Binjai",
      "provinceCode": "Sumatera Utara"
    },
  ],
}