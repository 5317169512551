import styled, {
  css
} from 'styled-components'
import {
  default as VARIABLES
} from './../../../themes/styles/bases/variables'
// import {
//   default as TYPOGRAPHYS
// } from './../../../themes/styles/bases/typographys'
import {
  default as MIXINS
} from './../../../themes/styles/helpers/mixins'

// Sample using image in style
// url(${require('./images/icons/icon-sample.svg')});
// url(${require('./../../../themes/images/icons/icon-sample.svg')});

// Wrapper
// ============================================================
export const TextBaseWrapper = styled.span`
  /* Parent
  ------------------------------- */
  transition: ${VARIABLES.TRANSITIONS.DEFAULT};
  position: relative;

  ${props => {
    const theme = props.theme
    return css`
      display: inline-block;

      ${theme.top && css`
          top: ${theme.top + `px`};
        `
      }

      ${theme.left && css`
          left: ${theme.left + `px`};
        `
      }

      ${theme.fontStyle}

      ${theme.width && css`
          width: ${theme.width + `px`};
        `
      }

      color: ${theme.color};
      text-align: ${theme.align};
      vertical-align: ${theme.verticalAlign};

      ${theme.ellipsisLine && css`
          overflow: hidden;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: ${theme.ellipsisLine};
        `
      }

      &:hover {
        color: ${theme.hoverColor};
      }

      @media (max-width: ${theme.breakpointFontStyleMobileSm}) {
        ${theme.fontStyleMobileSm}
      }

      @media (min-width: ${theme.breakpointFontStyleMobileMd}) {
        ${theme.fontStyleMobileMd}
      }

      @media (min-width: ${theme.breakpointFontStyleMobileLg}) {
        ${theme.fontStyleMobileLg}
      }

      @media (min-width: ${theme.breakpointFontStylePhabletSm}) {
        ${theme.fontStylePhabletSm}
      }

      @media (min-width: ${theme.breakpointFontStylePhabletMd}) {
        ${theme.fontStylePhabletMd}
      }

      @media (min-width: ${theme.breakpointFontStylePhabletLg}) {
        ${theme.fontStylePhabletLg}
      }

      @media (min-width: ${theme.breakpointFontStyleTabletSm}) {
        ${theme.fontStyleTabletSm}
      }

      @media (min-width: ${theme.breakpointFontStyleTabletMd}) {
        ${theme.fontStyleTabletMd}
      }

      @media (min-width: ${theme.breakpointFontStyleTabletLg}) {
        ${theme.fontStyleTabletLg}
      }
    `
  }}

  /* Childrens
  ------------------------------- */
  /* Modifiers
  ------------------------------- */
  &.is-ellipsis {
    ${MIXINS.ELLIPSIS({})};
  }

  &.is-single-line {
    line-height: 1;
  }

  &.is-cursor-pointer {
    cursor: pointer;
  }

  /* Media queries
  ------------------------------- */
`
