//https://www.citypopulation.de/en/malaysia/cities/
export const COUNTRY_MALAYSIA = {
  //state
  PROVINCE_LIST: [
    {
      "province": "Johor",
      "provinceCode": "JOH"
    },
    {
      "province": "Kedah",
      "provinceCode": "KED"
    },
    {
      "province": "Kelantan",
      "provinceCode": "KEL"
    },
    {
      "province": "Kuala Lumpur",
      "provinceCode": "KLP"
    },
    {
      "province": "Labuan",
      "provinceCode": "LAB"
    },
    {
      "province": "Melaka",
      "provinceCode": "MEL"
    },
    {
      "province": "Negeri Sembilan",
      "provinceCode": "NEG"
    },
    {
      "province": "Pahang",
      "provinceCode": "PAH"
    },
    {
      "province": "Perak",
      "provinceCode": "PER"
    },
    {
      "province": "Perlis",
      "provinceCode": "PES"
    },
    {
      "province": "Pulau Pinang",
      "provinceCode": "PIN"
    },
    {
      "province": "Putrajaya",
      "provinceCode": "PUT"
    },
    {
      "province": "Sabah",
      "provinceCode": "SAB"
    },
    {
      "province": "Sarawak",
      "provinceCode": "SAR"
    },
    {
      "province": "Selangor",
      "provinceCode": "SEL"
    },
    {
      "province": "Terengganu",
      "provinceCode": "TER"
    },
  ],
  //amphoe
  //Urban Areas
  CITY_LIST: [
    //==========Johor=========
    {
      "city": "Ayer Baloi",
      "cityCode": "Ayer Baloi",
      "provinceCode": "JOH"
    },
    {
      "city": "Ayer Hitam",
      "cityCode": "JOH_Ayer Hitam",
      "provinceCode": "JOH"
    },
    {
      "city": "Ayer Tawar 2",
      "cityCode": "JOH_Ayer Tawar 2",
      "provinceCode": "JOH"
    },
    {
      "city": "Bandar Penawar",
      "cityCode": "Bandar Penawar",
      "provinceCode": "JOH"
    },
    {
      "city": "Bandar Tenggara",
      "cityCode": "Bandar Tenggara",
      "provinceCode": "JOH"
    },
    {
      "city": "Batu Anam",
      "cityCode": "Batu Anam",
      "provinceCode": "JOH"
    },
    {
      "city": "Batu Pahat",
      "cityCode": "i510",
      "provinceCode": "JOH"
    },
    {
      "city": "Bekok",
      "cityCode": "Bekok",
      "provinceCode": "JOH"
    },
    {
      "city": "Benut",
      "cityCode": "Benut",
      "provinceCode": "JOH"
    },
    {
      "city": "Bukit Gambir",
      "cityCode": "Bukit Gambir",
      "provinceCode": "JOH"
    },
    {
      "city": "Bukit Pasir",
      "cityCode": "Bukit Pasir",
      "provinceCode": "JOH"
    },
    {
      "city": "Chaah",
      "cityCode": "i29824",
      "provinceCode": "JOH"
    },
    {
      "city": "Endau",
      "cityCode": "Endau",
      "provinceCode": "JOH"
    },
    {
      "city": "Gelang Patah",
      "cityCode": "Gelang Patah",
      "provinceCode": "JOH"
    },
    {
      "city": "Gerisek",
      "cityCode": "Gerisek",
      "provinceCode": "JOH"
    },
    {
      "city": "Gugusan Taib Andak",
      "cityCode": "Gugusan Taib Andak",
      "provinceCode": "JOH"
    },
    {
      "city": "Jementah",
      "cityCode": "Jementah",
      "provinceCode": "JOH"
    },
    {
      "city": "Johor Bahru",
      "cityCode": "i514",
      "provinceCode": "JOH"
    },
    {
      "city": "Kahang",
      "cityCode": "Kahang",
      "provinceCode": "JOH"
    },
    {
      "city": "Kluang",
      "cityCode": "i516",
      "provinceCode": "JOH"
    },
    {
      "city": "Kota Tinggi",
      "cityCode": "i12084",
      "provinceCode": "JOH"
    },
    {
      "city": "Kukup",
      "cityCode": "Kukup",
      "provinceCode": "JOH"
    },
    {
      "city": "Kulai",
      "cityCode": "i10462",
      "provinceCode": "JOH"
    },
    {
      "city": "Labis",
      "cityCode": "i29775",
      "provinceCode": "JOH"
    },
    {
      "city": "Layang-Layang",
      "cityCode": "Layang-Layang",
      "provinceCode": "JOH"
    },
    {
      "city": "Masai",
      "cityCode": "Masai",
      "provinceCode": "JOH"
    },
    {
      "city": "Mersing",
      "cityCode": "i16632",
      "provinceCode": "JOH"
    },
    {
      "city": "Muar",
      "cityCode": "i525",
      "provinceCode": "JOH"
    },
    {
      "city": "Nusajaya",
      "cityCode": "JOH_Nusajaya",
      "provinceCode": "JOH"
    },
    {
      "city": "Pagoh",
      "cityCode": "JOH_Pagoh",
      "provinceCode": "JOH"
    },
    {
      "city": "Paloh",
      "cityCode": "Paloh",
      "provinceCode": "JOH"
    },
    {
      "city": "Panchor",
      "cityCode": "Panchor",
      "provinceCode": "JOH"
    },
    {
      "city": "Parit Jawa",
      "cityCode": "Parit Jawa",
      "provinceCode": "JOH"
    },
    {
      "city": "Parit Raja",
      "cityCode": "i29781",
      "provinceCode": "JOH"
    },
    {
      "city": "Parit Sulong",
      "cityCode": "Parit Sulong",
      "provinceCode": "JOH"
    },
    {
      "city": "Pasir Gudang",
      "cityCode": "i10463",
      "provinceCode": "JOH"
    },
    {
      "city": "Pekan Nenas",
      "cityCode": "i29778",
      "provinceCode": "JOH"
    },
    {
      "city": "Pengerang",
      "cityCode": "Pengerang",
      "provinceCode": "JOH"
    },
    {
      "city": "Pontian Kecil",
      "cityCode": "i10464",
      "provinceCode": "JOH"
    },
    {
      "city": "Pulau Satu",
      "cityCode": "JOH_Pulau Satu",
      "provinceCode": "JOH"
    },
    {
      "city": "Rengam",
      "cityCode": "Rengam",
      "provinceCode": "JOH"
    },
    {
      "city": "Rengit",
      "cityCode": "Rengit",
      "provinceCode": "JOH"
    },
    {
      "city": "Segamat",
      "cityCode": "i10461",
      "provinceCode": "JOH"
    },
    {
      "city": "Semerah",
      "cityCode": "Semerah",
      "provinceCode": "JOH"
    },
    {
      "city": "Senai",
      "cityCode": "i16628",
      "provinceCode": "JOH"
    },
    {
      "city": "Senggarang",
      "cityCode": "Senggarang",
      "provinceCode": "JOH"
    },
    {
      "city": "Seri Gading",
      "cityCode": "Seri Gading",
      "provinceCode": "JOH"
    },
    {
      "city": "Seri Medan",
      "cityCode": "Seri Medan",
      "provinceCode": "JOH"
    },
    {
      "city": "Simpang Rengam",
      "cityCode": "i29798",
      "provinceCode": "JOH"
    },
    {
      "city": "Sungai Mati",
      "cityCode": "Sungai Mati",
      "provinceCode": "JOH"
    },
    {
      "city": "Tangkak",
      "cityCode": "i16629",
      "provinceCode": "JOH"
    },
    {
      "city": "Ulu Tiram",
      "cityCode": "i12099",
      "provinceCode": "JOH"
    },
    {
      "city": "Yong Peng",
      "cityCode": "i16630",
      "provinceCode": "JOH"
    },
    // {
    //   "city": "Bukit Bakri",
    //   "cityCode": "i16631",
    //   "provinceCode": "JOH"
    // },
    // {
    //   "city": "Buloh Kasap",
    //   "cityCode": "i29814",
    //   "provinceCode": "JOH"
    // },
    // {
    //   "city": "Kelapa Sawit",
    //   "cityCode": "i29812",
    //   "provinceCode": "JOH"
    // },
    // {
    //   "city": "Sekudai",
    //   "cityCode": "i16627",
    //   "provinceCode": "JOH"
    // },
    // {
    //   "city": "Simpang Ampat",
    //   "cityCode": "Simpang Ampat",
    //   "provinceCode": "JOH"
    // },
    // {
    //   "city": "Skudai",
    //   "cityCode": "Skudai",
    //   "provinceCode": "JOH"
    // },
    // {
    //   "city": "UTM Skudai",
    //   "cityCode": "UTM Skudai",
    //   "provinceCode": "JOH"
    // },
    // ===============
    // ============Kedah============
    {
      "city": "Alor Setar",
      "cityCode": "i509",
      "provinceCode": "KED"
    },
    {
      "city": "Ayer Hitam",
      "cityCode": "KED_Ayer Hitam",
      "provinceCode": "KED"
    },
    {
      "city": "Baling",
      "cityCode": "Baling",
      "provinceCode": "KED"
    },
    {
      "city": "Bandar Baharu",
      "cityCode": "Bandar Baharu",
      "provinceCode": "KED"
    },
    {
      "city": "Bedong",
      "cityCode": "i16633",
      "provinceCode": "KED"
    },
    {
      "city": "Bukit Kayu Hitam",
      "cityCode": "Bukit Kayu Hitam",
      "provinceCode": "KED"
    },
    {
      "city": "Changloon",
      "cityCode": "Changloon",
      "provinceCode": "KED"
    },
    {
      "city": "Gurun",
      "cityCode": "i29803",
      "provinceCode": "KED"
    },
    {
      "city": "Jeniang",
      "cityCode": "KED_Jeniang",
      "provinceCode": "KED"
    },
    {
      "city": "Jitra",
      "cityCode": "i10466",
      "provinceCode": "KED"
    },
    {
      "city": "Karangan",
      "cityCode": "Karangan",
      "provinceCode": "KED"
    },
    {
      "city": "Kepala Batas",
      "cityCode": "KED_Kepala Batas",
      "provinceCode": "KED"
    },
    {
      "city": "Kodiang",
      "cityCode": "Kodiang",
      "provinceCode": "KED"
    },
    {
      "city": "Kota Kuala Muda",
      "cityCode": "KED_Kota Kuala Muda",
      "provinceCode": "KED"
    },
    {
      "city": "Kota Sarang Semut",
      "cityCode": "Kota Sarang Semut",
      "provinceCode": "KED"
    },
    {
      "city": "Kuala Kedah",
      "cityCode": "i29782",
      "provinceCode": "KED"
    },
    {
      "city": "Kuala Ketil",
      "cityCode": "KED_Kuala Ketil",
      "provinceCode": "KED"
    },
    {
      "city": "Kuala Nerang",
      "cityCode": "Kuala Nerang",
      "provinceCode": "KED"
    },
    {
      "city": "Kuala Pegang",
      "cityCode": "Kuala Pegang",
      "provinceCode": "KED"
    },
    {
      "city": "Kulim",
      "cityCode": "i10465",
      "provinceCode": "KED"
    },
    {
      "city": "Kupang",
      "cityCode": "Kupang",
      "provinceCode": "KED"
    },
    {
      "city": "Langgar",
      "cityCode": "KED_Langgar",
      "provinceCode": "KED"
    },
    {
      "city": "Langkawi",
      "cityCode": "KED_Langkawi",
      "provinceCode": "KED"
    },
    {
      "city": "Lunas",
      "cityCode": "Lunas",
      "provinceCode": "KED"
    },
    {
      "city": "Merbok",
      "cityCode": "KED_Merbok",
      "provinceCode": "KED"
    },
    {
      "city": "Padang Serai",
      "cityCode": "KED_Padang Serai",
      "provinceCode": "KED"
    },
    {
      "city": "Pendang",
      "cityCode": "KED_Pendang",
      "provinceCode": "KED"
    },
    {
      "city": "Pokok Sena",
      "cityCode": "KED_Pokok Sena",
      "provinceCode": "KED"
    },
    {
      "city": "Serdang",
      "cityCode": "KED_Serdang",
      "provinceCode": "KED"
    },
    {
      "city": "Sik",
      "cityCode": "KED_Sik",
      "provinceCode": "KED"
    },
    {
      "city": "Simpang Empat",
      "cityCode": "KED_Simpang Empat",
      "provinceCode": "KED"
    },
    {
      "city": "Sungai Petani",
      "cityCode": "i534",
      "provinceCode": "KED"
    },
    {
      "city": "Universiti Utara Malaysia",
      "cityCode": "KED_Universiti Utara Malaysia",
      "provinceCode": "KED"
    },
    {
      "city": "Yan",
      "cityCode": "KED_Yan",
      "provinceCode": "KED"
    },
    // {
    //   "city": "Kuah",
    //   "cityCode": "i16634",
    //   "provinceCode": "KED"
    // },
    // {
    //   "city": "Sungai Jawi",
    //   "cityCode": "KED_Sungai Jawi",
    //   "provinceCode": "KED"
    // },
    // ===========================
    // ===============Kelantan============
    {
      "city": "Ayer Lanas",
      "cityCode": "KEL_Ayer Lanas",
      "provinceCode": "KEL"
    },
    {
      "city": "Bachok",
      "cityCode": "KEL_Bachok",
      "provinceCode": "KEL"
    },
    {
      "city": "Cherang Ruku",
      "cityCode": "KEL_Cherang Ruku",
      "provinceCode": "KEL"
    },
    {
      "city": "Dabong",
      "cityCode": "KEL_Dabong",
      "provinceCode": "KEL"
    },
    {
      "city": "Gua Musang",
      "cityCode": "i29785",
      "provinceCode": "KEL"
    },
    {
      "city": "Jeli",
      "cityCode": "KEL_Jeli",
      "provinceCode": "KEL"
    },
    {
      "city": "Kem Desa Pahlawan",
      "cityCode": "Kem Desa Pahlawan",
      "provinceCode": "KEL"
    },
    {
      "city": "Ketereh",
      "cityCode": "KEL_Ketereh",
      "provinceCode": "KEL"
    },
    {
      "city": "Kota Bharu",
      "cityCode": "i517",
      "provinceCode": "KEL"
    },
    {
      "city": "Kuala Balah",
      "cityCode": "KEL_Kuala Balah",
      "provinceCode": "KEL"
    },
    {
      "city": "Kuala Krai",
      "cityCode": "i29767",
      "provinceCode": "KEL"
    },
    {
      "city": "Machang",
      "cityCode": "KEL_Machang",
      "provinceCode": "KEL"
    },
    {
      "city": "Melor",
      "cityCode": "Melor",
      "provinceCode": "KEL"
    },
    {
      "city": "Pasir Mas",
      "cityCode": "i29777",
      "provinceCode": "KEL"
    },
    {
      "city": "Pasir Puteh",
      "cityCode": "KEL_Pasir Puteh",
      "provinceCode": "KEL"
    },
    {
      "city": "Pulai Chondong",
      "cityCode": "KEL_Pulai Chondong",
      "provinceCode": "KEL"
    },
    {
      "city": "Rantau Panjang",
      "cityCode": "KEL_Rantau Panjang",
      "provinceCode": "KEL"
    },
    {
      "city": "Selising",
      "cityCode": "KEL_Selising",
      "provinceCode": "KEL"
    },
    {
      "city": "Tanah Merah",
      "cityCode": "i12097",
      "provinceCode": "KEL"
    },
    {
      "city": "Temangan",
      "cityCode": "KEL_Temangan",
      "provinceCode": "KEL"
    },
    {
      "city": "Tumpat",
      "cityCode": "i29813",
      "provinceCode": "KEL"
    },
    {
      "city": "Wakaf Bharu",
      "cityCode": "i29780",
      "provinceCode": "KEL"
    },
    // {
    //   "city": "Kadok",
    //   "cityCode": "i29779",
    //   "provinceCode": "KEL"
    // },

    // {
    //   "city": "Pangkal Kalong",
    //   "cityCode": "i10467",
    //   "provinceCode": "KEL"
    // },
    // {
    //   "city": "Peringat",
    //   "cityCode": "i16635",
    //   "provinceCode": "KEL"
    // },
    // ===============
    // =========Melaka=========
    {
      "city": "Alor Gajah",
      "cityCode": "i29773",
      "provinceCode": "MEL"
    },
    {
      "city": "Asahan",
      "cityCode": "MEL_Asahan",
      "provinceCode": "MEL"
    },
    {
      "city": "Ayer Keroh",
      "cityCode": "i16636",
      "provinceCode": "MEL"
    },
    {
      "city": "Bemban",
      "cityCode": "i29808",
      "provinceCode": "MEL"
    },
    {
      "city": "Durian Tunggal",
      "cityCode": "MEL_Durian Tunggal",
      "provinceCode": "MEL"
    },
    {
      "city": "Jasin",
      "cityCode": "MEL_Jasin",
      "provinceCode": "MEL"
    },
    {
      "city": "Kem Trendak",
      "cityCode": "MEL_Kem Trendak",
      "provinceCode": "MEL"
    },
    {
      "city": "Kuala Sungai Baru",
      "cityCode": "i29817",
      "provinceCode": "MEL"
    },
    {
      "city": "Lubok China",
      "cityCode": "MEL_Lubok China",
      "provinceCode": "MEL"
    },
    {
      "city": "Masjid Tanah",
      "cityCode": "i16637",
      "provinceCode": "MEL"
    },
    {
      "city": "Melaka",
      "cityCode": "i523",
      "provinceCode": "MEL"
    },
    {
      "city": "Merlimau",
      "cityCode": "MEL_Merlimau",
      "provinceCode": "MEL"
    },
    {
      "city": "Selandar",
      "cityCode": "MEL_Selandar",
      "provinceCode": "MEL"
    },
    {
      "city": "Sungai Rambai",
      "cityCode": "MEL_Sungai Rambai",
      "provinceCode": "MEL"
    },
    {
      "city": "Sungai Udang",
      "cityCode": "i16638",
      "provinceCode": "MEL"
    },
    {
      "city": "Tanjong Kling",
      "cityCode": "MEL_Tanjong Kling",
      "provinceCode": "MEL"
    },
    // {
    //   "city": "Ayer Molek",
    //   "cityCode": "i29799",
    //   "provinceCode": "MEL"
    // },
    // {
    //   "city": "Batu Berendam",
    //   "cityCode": "i16639",
    //   "provinceCode": "MEL"
    // },
    // {
    //   "city": "Bukit Baru",
    //   "cityCode": "i12079",
    //   "provinceCode": "MEL"
    // },
    // {
    //   "city": "Bukit Rambai",
    //   "cityCode": "i29795",
    //   "provinceCode": "MEL"
    // },
    // {
    //   "city": "Klebang",
    //   "cityCode": "i12083",
    //   "provinceCode": "MEL"
    // },
    // {
    //   "city": "Pulau Sebang",
    //   "cityCode": "i29818",
    //   "provinceCode": "MEL"
    // },
    // {
    //   "city": "Linggi",
    //   "cityCode": "MEL_Linggi",
    //   "provinceCode": "MEL"
    // },
    // {
    //   "city": "Rompin",
    //   "cityCode": "MEL_Rompin",
    //   "provinceCode": "MEL"
    // },
    // ==================
    // ===========Negeri Sembilan==========
    {
      "city": "Bahau",
      "cityCode": "i16641",
      "provinceCode": "NEG"
    },
    {
      "city": "Bandar Enstek",
      "cityCode": "NEG_Bandar Enstek",
      "provinceCode": "NEG"
    },
    {
      "city": "Bandar Seri Jempol",
      "cityCode": "NEG_Bandar Seri Jempol",
      "provinceCode": "NEG"
    },
    {
      "city": "Batu Kikir",
      "cityCode": "NEG_Batu Kikir",
      "provinceCode": "NEG"
    },
    {
      "city": "Gemas",
      "cityCode": "NEG_Gemas",
      "provinceCode": "NEG"
    },
    {
      "city": "Gemencheh",
      "cityCode": "NEG_Gemencheh",
      "provinceCode": "NEG"
    },
    {
      "city": "Johol",
      "cityCode": "NEG_Johol",
      "provinceCode": "NEG"
    },
    {
      "city": "Kota",
      "cityCode": "NEG_Kota",
      "provinceCode": "NEG"
    },
    {
      "city": "Kuala Klawang",
      "cityCode": "NEG_Kuala Klawang",
      "provinceCode": "NEG"
    },
    {
      "city": "Kuala Pilah",
      "cityCode": "i29772",
      "provinceCode": "NEG"
    },
    {
      "city": "Labu",
      "cityCode": "NEG_Labu",
      "provinceCode": "NEG"
    },
    {
      "city": "Linggi",
      "cityCode": "NEG_Linggi",
      "provinceCode": "NEG"
    },
    {
      "city": "Mantin",
      "cityCode": "NEG_Mantin",
      "provinceCode": "NEG"
    },
    {
      "city": "Niai",
      "cityCode": "NEG_Niai",
      "provinceCode": "NEG"
    },
    {
      "city": "Port Dickson",
      "cityCode": "i10468",
      "provinceCode": "NEG"
    },
    {
      "city": "Pusat Bandar Palong",
      "cityCode": "NEG_Pusat Bandar Palong",
      "provinceCode": "NEG"
    },
    {
      "city": "Rantau",
      "cityCode": "NEG_Rantau",
      "provinceCode": "NEG"
    },
    {
      "city": "Rembau",
      "cityCode": "NEG_Rembau",
      "provinceCode": "NEG"
    },
    {
      "city": "Rompin",
      "cityCode": "NEG_Rompin",
      "provinceCode": "NEG"
    },
    {
      "city": "Seremban",
      "cityCode": "i531",
      "provinceCode": "NEG"
    },
    {
      "city": "Si Rusa",
      "cityCode": "NEG_Si Rusa",
      "provinceCode": "NEG"
    },
    {
      "city": "Simpang Durian",
      "cityCode": "NEG_Simpang Durian",
      "provinceCode": "NEG"
    },
    {
      "city": "Simpang Pertang",
      "cityCode": "NEG_Simpang Pertang",
      "provinceCode": "NEG"
    },
    {
      "city": "Tampin",
      "cityCode": "i16642",
      "provinceCode": "NEG"
    },
    {
      "city": "Tanjong Ipoh",
      "cityCode": "NEG_Tanjong Ipoh",
      "provinceCode": "NEG"
    },
    // {
    //   "city": "Nilai",
    //   "cityCode": "i16640",
    //   "provinceCode": "NEG"
    // },
    // {
    //   "city": "Bandar Baru Enstek",
    //   "cityCode": "NEG_Bandar Baru Enstek",
    //   "provinceCode": "NEG"
    // },
    // =====================
    // =======Pahang=========
    {
      "city": "Balok",
      "cityCode": "PAH_Balok",
      "provinceCode": "PAH"
    },
    {
      "city": "Bandar Bera",
      "cityCode": "PAH_Bandar Bera",
      "provinceCode": "PAH"
    },
    {
      "city": "Bandar Pusat Jengka",
      "cityCode": "PAH_Bandar Pusat Jengka",
      "provinceCode": "PAH"
    },
    {
      "city": "Bandar Tun Abdul Razak",
      "cityCode": "PAH_Bandar Tun Abdul Razak",
      "provinceCode": "PAH"
    },
    {
      "city": "Benta",
      "cityCode": "PAH_Benta",
      "provinceCode": "PAH"
    },
    {
      "city": "Bentong",
      "cityCode": "i10474",
      "provinceCode": "PAH"
    },
    {
      "city": "Brinchang",
      "cityCode": "PAH_Brinchang",
      "provinceCode": "PAH"
    },
    {
      "city": "Bukit Fraser",
      "cityCode": "PAH_Bukit Fraser",
      "provinceCode": "PAH"
    },
    {
      "city": "Bukit Goh",
      "cityCode": "PAH_Bukit Goh",
      "provinceCode": "PAH"
    },
    {
      "city": "Bukit Kuin",
      "cityCode": "PAH_Bukit Kuin",
      "provinceCode": "PAH"
    },
    {
      "city": "Chenor",
      "cityCode": "PAH_Chenor",
      "provinceCode": "PAH"
    },
    {
      "city": "Chini",
      "cityCode": "PAH_Chini",
      "provinceCode": "PAH"
    },
    {
      "city": "Damak",
      "cityCode": "PAH_Damak",
      "provinceCode": "PAH"
    },
    {
      "city": "Dong",
      "cityCode": "PAH_Dong",
      "provinceCode": "PAH"
    },
    {
      "city": "Gambang",
      "cityCode": "PAH_Gambang",
      "provinceCode": "PAH"
    },
    {
      "city": "Genting Highlands",
      "cityCode": "PAH_Genting Highlands",
      "provinceCode": "PAH"
    },
    {
      "city": "Jerantut",
      "cityCode": "i16645",
      "provinceCode": "PAH"
    },
    {
      "city": "Karak",
      "cityCode": "PAH_Karak",
      "provinceCode": "PAH"
    },
    {
      "city": "Kemayan",
      "cityCode": "PAH_Kemayan",
      "provinceCode": "PAH"
    },
    {
      "city": "Kuala Krau",
      "cityCode": "PAH_Kuala Krau",
      "provinceCode": "PAH"
    },
    {
      "city": "Kuala Lipis",
      "cityCode": "i29801",
      "provinceCode": "PAH"
    },
    {
      "city": "Kuala Rompin",
      "cityCode": "PAH_Kuala Rompin",
      "provinceCode": "PAH"
    },
    {
      "city": "Kuantan",
      "cityCode": "i521",
      "provinceCode": "PAH"
    },
    {
      "city": "Lanchang",
      "cityCode": "PAH_Lanchang",
      "provinceCode": "PAH"
    },
    {
      "city": "Lurah Bilut",
      "cityCode": "PAH_Lurah Bilut",
      "provinceCode": "PAH"
    },
    {
      "city": "Maran",
      "cityCode": "PAH_Maran",
      "provinceCode": "PAH"
    },
    {
      "city": "Mentakab",
      "cityCode": "i12087",
      "provinceCode": "PAH"
    },
    {
      "city": "Muadzam Shah",
      "cityCode": "PAH_Muadzam Shah",
      "provinceCode": "PAH"
    },
    {
      "city": "Padang Tengku",
      "cityCode": "PAH_Padang Tengku",
      "provinceCode": "PAH"
    },
    {
      "city": "Pekan",
      "cityCode": "i16644",
      "provinceCode": "PAH"
    },
    {
      "city": "Raub",
      "cityCode": "i10475",
      "provinceCode": "PAH"
    },
    {
      "city": "Ringlet",
      "cityCode": "PAH_Ringlet",
      "provinceCode": "PAH"
    },
    {
      "city": "Sega",
      "cityCode": "PAH_Sega",
      "provinceCode": "PAH"
    },
    {
      "city": "Sungai Koyan",
      "cityCode": "PAH_Sungai Koyan",
      "provinceCode": "PAH"
    },
    {
      "city": "Sungai Lembing",
      "cityCode": "PAH_Sungai Lembing",
      "provinceCode": "PAH"
    },
    {
      "city": "Tanah Rata",
      "cityCode": "PAH_Tanah Rata",
      "provinceCode": "PAH"
    },
    {
      "city": "Temerloh",
      "cityCode": "i16643",
      "provinceCode": "PAH"
    },
    {
      "city": "Triang",
      "cityCode": "PAH_Triang",
      "provinceCode": "PAH"
    },
    // {
    //   "city": "Bandar Jengka Pusat",
    //   "cityCode": "i29816",
    //   "provinceCode": "PAH"
    // },
    // {
    //   "city": "Bukit Tinggi",
    //   "cityCode": "i29820",
    //   "provinceCode": "PAH"
    // },
    // {
    //   "city": "Beserah",
    //   "cityCode": "PAH_Beserah",
    //   "provinceCode": "PAH"
    // },
    // {
    //   "city": "Jaya Gading",
    //   "cityCode": "PAH_Jaya Gading",
    //   "provinceCode": "PAH"
    // },
    // {
    //   "city": "Sungai Ruan",
    //   "cityCode": "PAH_Sungai Ruan",
    //   "provinceCode": "PAH"
    // },
    // {
    //   "city": "Puchong",
    //   "cityCode": "PAH_Puchong",
    //   "provinceCode": "PAH"
    // },
    // ==================
    // ==========Perak===========
    {
      "city": "Ayer Tawar",
      "cityCode": "i29788",
      "provinceCode": "PER"
    },
    {
      "city": "Bagan Datoh",
      "cityCode": "PER_Bagan Datoh",
      "provinceCode": "PER"
    },
    {
      "city": "Bagan Serai",
      "cityCode": "i29783",
      "provinceCode": "PER"
    },
    {
      "city": "Bandar Seri Iskandar",
      "cityCode": "PER_Bandar Seri Iskandar",
      "provinceCode": "PER"
    },
    {
      "city": "Batu Gajah",
      "cityCode": "i16646",
      "provinceCode": "PER"
    },
    {
      "city": "Batu Kurau",
      "cityCode": "PER_Batu Kurau",
      "provinceCode": "PER"
    },
    {
      "city": "Behrang Stesen",
      "cityCode": "PER_Behrang Stesen",
      "provinceCode": "PER"
    },
    {
      "city": "Bidor",
      "cityCode": "i16648",
      "provinceCode": "PER"
    },
    {
      "city": "Bota",
      "cityCode": "PER_Bota",
      "provinceCode": "PER"
    },
    {
      "city": "Bruas",
      "cityCode": "PER_Bruas",
      "provinceCode": "PER"
    },
    {
      "city": "Changkat Jering",
      "cityCode": "PER_Changkat Jering",
      "provinceCode": "PER"
    },
    {
      "city": "Changkat Keruing",
      "cityCode": "PER_Changkat Keruing",
      "provinceCode": "PER"
    },
    {
      "city": "Chemor",
      "cityCode": "PER_Chemor",
      "provinceCode": "PER"
    },
    {
      "city": "Chenderiang",
      "cityCode": "PER_Chenderiang",
      "provinceCode": "PER"
    },
    {
      "city": "Chenderong Balai",
      "cityCode": "PER_Chenderong Balai",
      "provinceCode": "PER"
    },
    {
      "city": "Chikus",
      "cityCode": "PER_Chikus",
      "provinceCode": "PER"
    },
    {
      "city": "Enggor",
      "cityCode": "PER_Enggor",
      "provinceCode": "PER"
    },
    {
      "city": "Gerik",
      "cityCode": "PER_Gerik",
      "provinceCode": "PER"
    },
    {
      "city": "Gopeng",
      "cityCode": "PER_Gopeng",
      "provinceCode": "PER"
    },
    {
      "city": "Hutan Melintang",
      "cityCode": "PER_Hutan Melintang",
      "provinceCode": "PER"
    },
    {
      "city": "Intan",
      "cityCode": "PER_Intan",
      "provinceCode": "PER"
    },
    {
      "city": "Ipoh",
      "cityCode": "i513",
      "provinceCode": "PER"
    },
    {
      "city": "Jeram",
      "cityCode": "PER_Jeram",
      "provinceCode": "PER"
    },
    {
      "city": "Kampar",
      "cityCode": "i10469",
      "provinceCode": "PER"
    },
    {
      "city": "Kampung Gajah",
      "cityCode": "PER_Kampung Gajah",
      "provinceCode": "PER"
    },
    {
      "city": "Kampung Kepayang",
      "cityCode": "PER_Kampung Kepayang",
      "provinceCode": "PER"
    },
    {
      "city": "Kamunting",
      "cityCode": "PER_Kamunting",
      "provinceCode": "PER"
    },
    {
      "city": "Kuala Kangsar",
      "cityCode": "i12085",
      "provinceCode": "PER"
    },
    {
      "city": "Kuala Kurau",
      "cityCode": "PER_Kuala Kurau",
      "provinceCode": "PER"
    },
    {
      "city": "Kuala Sepetang",
      "cityCode": "PER_Kuala Sepetang",
      "provinceCode": "PER"
    },
    {
      "city": "Lambor Kanan",
      "cityCode": "PER_Lambor Kanan",
      "provinceCode": "PER"
    },
    {
      "city": "Langkap",
      "cityCode": "PER_Langkap",
      "provinceCode": "PER"
    },
    {
      "city": "Lenggong",
      "cityCode": "PER_Lenggong",
      "provinceCode": "PER"
    },
    {
      "city": "Lumut",
      "cityCode": "i12086",
      "provinceCode": "PER"
    },
    {
      "city": "Malim Nawar",
      "cityCode": "PER_Malim Nawar",
      "provinceCode": "PER"
    },
    {
      "city": "Manong",
      "cityCode": "PER_Manong",
      "provinceCode": "PER"
    },
    {
      "city": "Matang",
      "cityCode": "PER_Matang",
      "provinceCode": "PER"
    },
    {
      "city": "Padang Rengas",
      "cityCode": "PER_Padang Rengas",
      "provinceCode": "PER"
    },
    {
      "city": "Pangkor",
      "cityCode": "PER_Pangkor",
      "provinceCode": "PER"
    },
    {
      "city": "Pantai Remis",
      "cityCode": "i29802",
      "provinceCode": "PER"
    },
    {
      "city": "Parit",
      "cityCode": "PER_Parit",
      "provinceCode": "PER"
    },
    {
      "city": "Parit Buntar",
      "cityCode": "i12089",
      "provinceCode": "PER"
    },
    {
      "city": "Pengkalan Hulu",
      "cityCode": "PER_Pengkalan Hulu",
      "provinceCode": "PER"
    },
    {
      "city": "Pusing",
      "cityCode": "PER_Pusing",
      "provinceCode": "PER"
    },
    {
      "city": "Rantau Panjang",
      "cityCode": "PER_Rantau Panjang",
      "provinceCode": "PER"
    },
    {
      "city": "Sauk",
      "cityCode": "PER_Sauk",
      "provinceCode": "PER"
    },
    {
      "city": "Selama",
      "cityCode": "PER_Selama",
      "provinceCode": "PER"
    },
    {
      "city": "Selekoh",
      "cityCode": "PER_Selekoh",
      "provinceCode": "PER"
    },
    {
      "city": "Seri Manjong",
      "cityCode": "PER_Seri Manjong",
      "provinceCode": "PER"
    },
    {
      "city": "Seri Manjung",
      "cityCode": "PER_Seri Manjung",
      "provinceCode": "PER"
    },
    {
      "city": "Simpang",
      "cityCode": "PER_Simpang",
      "provinceCode": "PER"
    },
    {
      "city": "Simpang Ampat Semanggol",
      "cityCode": "PER_Simpang Ampat Semanggol",
      "provinceCode": "PER"
    },
    {
      "city": "Sitiawan",
      "cityCode": "PER_Sitiawan",
      "provinceCode": "PER"
    },
    {
      "city": "Slim River",
      "cityCode": "PER_Slim River",
      "provinceCode": "PER"
    },
    {
      "city": "Sungai Siput",
      "cityCode": "i10470",
      "provinceCode": "PER"
    },
    {
      "city": "Sungai Sumun",
      "cityCode": "PER_Sungai Sumun",
      "provinceCode": "PER"
    },
    {
      "city": "Sungkai",
      "cityCode": "PER_Sungkai",
      "provinceCode": "PER"
    },
    {
      "city": "Taiping",
      "cityCode": "i535",
      "provinceCode": "PER"
    },
    {
      "city": "Tanjong Malim",
      "cityCode": "PER_Tanjong Malim",
      "provinceCode": "PER"
    },
    {
      "city": "Tanjong Piandang",
      "cityCode": "PER_Tanjong Piandang",
      "provinceCode": "PER"
    },
    {
      "city": "Tanjong Rambutan",
      "cityCode": "PER_Tanjong Rambutan",
      "provinceCode": "PER"
    },
    {
      "city": "Tanjong Tualang",
      "cityCode": "PER_Tanjong Tualang",
      "provinceCode": "PER"
    },
    {
      "city": "Tapah",
      "cityCode": "i16649",
      "provinceCode": "PER"
    },
    {
      "city": "Tapah Road",
      "cityCode": "PER_Tapah Road",
      "provinceCode": "PER"
    },
    {
      "city": "Teluk Intan",
      "cityCode": "i537",
      "provinceCode": "PER"
    },
    {
      "city": "Temoh",
      "cityCode": "PER_Temoh",
      "provinceCode": "PER"
    },
    {
      "city": "TLDM Lumut",
      "cityCode": "PER_TLDM Lumut",
      "provinceCode": "PER"
    },
    {
      "city": "Trolak",
      "cityCode": "PER_Trolak",
      "provinceCode": "PER"
    },
    {
      "city": "Trong",
      "cityCode": "PER_Trong",
      "provinceCode": "PER"
    },
    {
      "city": "Tronoh",
      "cityCode": "PER_Tronoh",
      "provinceCode": "PER"
    },
    {
      "city": "Ulu Bernam",
      "cityCode": "PER_Ulu Bernam",
      "provinceCode": "PER"
    },
    {
      "city": "Ulu Kinta",
      "cityCode": "PER_Ulu Kinta",
      "provinceCode": "PER"
    },
    // {
    //   "city": "Kampung Koh",
    //   "cityCode": "i16647",
    //   "provinceCode": "PER"
    // },
    // {
    //   "city": "Lawan Kuda Baharu",
    //   "cityCode": "i29774",
    //   "provinceCode": "PER"
    // },
    // {
    //   "city": "Simpang Empat",
    //   "cityCode": "i12094",
    //   "provinceCode": "PER"
    // },
    // {
    //   "city": "Tanjung Malim",
    //   "cityCode": "i29797",
    //   "provinceCode": "PER"
    // },
    // {
    //   "city": "Kampong Kepayang",
    //   "cityCode": "PER_Kampong Kepayang",
    //   "provinceCode": "PER"
    // },
    // {
    //   "city": "Sri Manjong",
    //   "cityCode": "PER_Sri Manjong",
    //   "provinceCode": "PER"
    // },
    // {
    //   "city": "Bandar Baru Sri Iskandar",
    //   "cityCode": "PER_Bandar Baru Sri Iskandar",
    //   "provinceCode": "PER"
    // },
    // {
    //   "city": "Hulu Melintang",
    //   "cityCode": "PER_Hulu Melintang",
    //   "provinceCode": "PER"
    // },
    // =====================
    // ========Perlis==========
    {
      "city": "Arau",
      "cityCode": "PES_Arau",
      "provinceCode": "PES"
    },
    {
      "city": "Kaki Bukit",
      "cityCode": "PES_Kaki Bukit",
      "provinceCode": "PES"
    },
    {
      "city": "Kangar",
      "cityCode": "i539",
      "provinceCode": "PES"
    },
    {
      "city": "Kuala Perlis",
      "cityCode": "i29806",
      "provinceCode": "PES"
    },
    {
      "city": "Padang Besar",
      "cityCode": "PER_Padang Besar",
      "provinceCode": "PES"
    },
    {
      "city": "Simpang Ampat",
      "cityCode": "PER_Simpang Ampat",
      "provinceCode": "PES"
    },
    // ======================
    // ========Pulau Pinang=============
    {
      "city": "Ayer Itam",
      "cityCode": "i10471",
      "provinceCode": "PIN"
    },
    {
      "city": "Balik Pulau",
      "cityCode": "PIN_Balik Pulau",
      "provinceCode": "PIN"
    },
    {
      "city": "Batu Ferringhi",
      "cityCode": "PIN_Batu Ferringhi",
      "provinceCode": "PIN"
    },
    {
      "city": "Batu Maung",
      "cityCode": "PIN_Batu Maung",
      "provinceCode": "PIN"
    },
    {
      "city": "Bayan Lepas",
      "cityCode": "PIN_Bayan Lepas",
      "provinceCode": "PIN"
    },
    {
      "city": "Bukit Mertajam",
      "cityCode": "i10473",
      "provinceCode": "PIN"
    },
    {
      "city": "Butterworth",
      "cityCode": "i511",
      "provinceCode": "PIN"
    },
    {
      "city": "Gelugor",
      "cityCode": "i10472",
      "provinceCode": "PIN"
    },
    {
      "city": "Jelutong",
      "cityCode": "PIN_Jelutong",
      "provinceCode": "PIN"
    },
    {
      "city": "Kepala Batas",
      "cityCode": "i29793",
      "provinceCode": "PIN"
    },
    {
      "city": "Kubang Semang",
      "cityCode": "PIN_Kubang Semang",
      "provinceCode": "PIN"
    },
    {
      "city": "Nibong Tebal",
      "cityCode": "i12088",
      "provinceCode": "PIN"
    },
    {
      "city": "Penaga",
      "cityCode": "PIN_Penaga",
      "provinceCode": "PIN"
    },
    {
      "city": "Penang Hill",
      "cityCode": "PIN_Penang Hill",
      "provinceCode": "PIN"
    },
    {
      "city": "Perai",
      "cityCode": "i12090",
      "provinceCode": "PIN"
    },
    {
      "city": "Permatang Pauh",
      "cityCode": "PIN_Permatang Pauh",
      "provinceCode": "PIN"
    },
    {
      "city": "Pulau Pinang",
      "cityCode": "PIN_Pulau Pinang",
      "provinceCode": "PIN"
    },
    {
      "city": "Simpang Ampat",
      "cityCode": "PIN_Simpang Ampat",
      "provinceCode": "PIN"
    },
    {
      "city": "Sungai Jawi",
      "cityCode": "PIN_Sungai Jawi",
      "provinceCode": "PIN"
    },
    {
      "city": "Tanjong Bungah",
      "cityCode": "PIN_Tanjong Bungah",
      "provinceCode": "PIN"
    },
    {
      "city": "Tanjung Bungah",
      "cityCode": "i29787",
      "provinceCode": "PIN"
    },
    {
      "city": "Tasek Gelugor",
      "cityCode": "PIN_Tasek Gelugor",
      "provinceCode": "PIN"
    },
    {
      "city": "Tasek Gelugur",
      "cityCode": "PIN_Tasek Gelugur",
      "provinceCode": "PIN"
    },
    {
      "city": "USM Pulau Pinang",
      "cityCode": "PIN_USM Pulau Pinang",
      "provinceCode": "PIN"
    },
    // {
    //   "city": "George Town",
    //   "cityCode": "i512",
    //   "provinceCode": "PIN"
    // },
    // {
    //   "city": "Juru",
    //   "cityCode": "i29815",
    //   "provinceCode": "PIN"
    // },
    // {
    //   "city": "Permatang Kuching",
    //   "cityCode": "i12091",
    //   "provinceCode": "PIN"
    // },
    // {
    //   "city": "Sungai Ara",
    //   "cityCode": "i12095",
    //   "provinceCode": "PIN"
    // },

    // {
    //   "city": "Tanjung Tokong",
    //   "cityCode": "i12098",
    //   "provinceCode": "PIN"
    // },
    // {
    //   "city": "Val d'Or",
    //   "cityCode": "i16650",
    //   "provinceCode": "PIN"
    // },
    // {
    //   "city": "Batu Feringgi",
    //   "cityCode": "PIN_Batu Feringgi",
    //   "provinceCode": "PIN"
    // },
    // =================
    //=============Sabah=========
    {
      "city": "Beaufort",
      "cityCode": "i29807",
      "provinceCode": "SAB"
    },
    {
      "city": "Beluran",
      "cityCode": "SAB_Beluran",
      "provinceCode": "SAB"
    },
    {
      "city": "Beverly",
      "cityCode": "SAB_Beverly",
      "provinceCode": "SAB"
    },
    {
      "city": "Bongawan",
      "cityCode": "SAB_Bongawan",
      "provinceCode": "SAB"
    },
    {
      "city": "Inanam",
      "cityCode": "SAB_Inanam",
      "provinceCode": "SAB"
    },
    {
      "city": "Keningau",
      "cityCode": "i16653",
      "provinceCode": "SAB"
    },
    {
      "city": "Kota Belud",
      "cityCode": "i29823",
      "provinceCode": "SAB"
    },
    {
      "city": "Kota Kinabalu",
      "cityCode": "i518",
      "provinceCode": "SAB"
    },
    {
      "city": "Kota Kinabatangan",
      "cityCode": "SAB_Kota Kinabatangan",
      "provinceCode": "SAB"
    },
    {
      "city": "Kota Marudu",
      "cityCode": "SAB_Kota Marudu",
      "provinceCode": "SAB"
    },
    {
      "city": "Kuala Penyu",
      "cityCode": "SAB_Kuala Penyu",
      "provinceCode": "SAB"
    },
    {
      "city": "Kudat",
      "cityCode": "i10478",
      "provinceCode": "SAB"
    },
    {
      "city": "Kunak",
      "cityCode": "i29790",
      "provinceCode": "SAB"
    },
    {
      "city": "Lahad Datu",
      "cityCode": "i10476",
      "provinceCode": "SAB"
    },
    {
      "city": "Likas",
      "cityCode": "SAB_Likas",
      "provinceCode": "SAB"
    },
    {
      "city": "Membakut",
      "cityCode": "SAB_Membakut",
      "provinceCode": "SAB"
    },
    {
      "city": "Menumbok",
      "cityCode": "SAB_Menumbok",
      "provinceCode": "SAB"
    },
    {
      "city": "Nabawan",
      "cityCode": "SAB_Nabawan",
      "provinceCode": "SAB"
    },
    {
      "city": "Pamol",
      "cityCode": "SAB_Pamol",
      "provinceCode": "SAB"
    },
    {
      "city": "Papar",
      "cityCode": "i29794",
      "provinceCode": "SAB"
    },
    {
      "city": "Penampang",
      "cityCode": "SAB_Penampang",
      "provinceCode": "SAB"
    },
    {
      "city": "Putatan",
      "cityCode": "i16651",
      "provinceCode": "SAB"
    },
    {
      "city": "Ranau",
      "cityCode": "i29789",
      "provinceCode": "SAB"
    },
    {
      "city": "Sandakan",
      "cityCode": "i529",
      "provinceCode": "SAB"
    },
    {
      "city": "Semporna",
      "cityCode": "i10477",
      "provinceCode": "SAB"
    },
    {
      "city": "Sipitang",
      "cityCode": "SAB_Sipitang",
      "provinceCode": "SAB"
    },
    {
      "city": "Tambunan",
      "cityCode": "SAB_Tambunan",
      "provinceCode": "SAB"
    },
    {
      "city": "Tamparuli",
      "cityCode": "SAB_Tamparuli",
      "provinceCode": "SAB"
    },
    {
      "city": "Tanjung Aru",
      "cityCode": "SAB_Tanjung Aru",
      "provinceCode": "SAB"
    },
    {
      "city": "Tawau",
      "cityCode": "i536",
      "provinceCode": "SAB"
    },
    {
      "city": "Tenghilan",
      "cityCode": "SAB_Tenghilan",
      "provinceCode": "SAB"
    },
    {
      "city": "Tenom",
      "cityCode": "SAB_Tenom",
      "provinceCode": "SAB"
    },
    {
      "city": "Tuaran",
      "cityCode": "SAB_Tuaran",
      "provinceCode": "SAB"
    },
    // ====================

    //=======SAR=========
    // {
    //   "city": "Batu 8 Bazaar",
    //   "cityCode": "i29805",
    //   "provinceCode": "SAR"
    // },
    // {
    //   "city": "Kuching",
    //   "cityCode": "i522",
    //   "provinceCode": "SAR"
    // },
    {
      "city": "Asajaya",
      "cityCode": "SAR_Asajaya",
      "provinceCode": "SAR"
    },
    {
      "city": "Baram",
      "cityCode": "SAR_Baram",
      "provinceCode": "SAR"
    },
    {
      "city": "Bau",
      "cityCode": "SAR_Bau",
      "provinceCode": "SAR"
    },
    {
      "city": "Bekenu",
      "cityCode": "SAR_Bekenu",
      "provinceCode": "SAR"
    },
    {
      "city": "Betong",
      "cityCode": "SAR_Betong",
      "provinceCode": "SAR"
    },
    {
      "city": "Bintangor",
      "cityCode": "SAR_Bintangor",
      "provinceCode": "SAR"
    },
    {
      "city": "Bintulu",
      "cityCode": "i12078",
      "provinceCode": "SAR"
    },
    {
      "city": "Dalat",
      "cityCode": "SAR_Dalat",
      "provinceCode": "SAR"
    },
    {
      "city": "Kanowit",
      "cityCode": "SAR_Kanowit",
      "provinceCode": "SAR"
    },
    {
      "city": "Kapit",
      "cityCode": "i29800",
      "provinceCode": "SAR"
    },
    {
      "city": "Kota Samarahan",
      "cityCode": "i29822",
      "provinceCode": "SAR"
    },
    {
      "city": "Lawas",
      "cityCode": "SAR_Lawas",
      "provinceCode": "SAR"
    },
    {
      "city": "Limbang",
      "cityCode": "i29768",
      "provinceCode": "SAR"
    },
    {
      "city": "Lundu",
      "cityCode": "SAR_Lundu",
      "provinceCode": "SAR"
    },
    {
      "city": "Lutong",
      "cityCode": "SAR_Lutong",
      "provinceCode": "SAR"
    },
    {
      "city": "Miri",
      "cityCode": "i524",
      "provinceCode": "SAR"
    },
    {
      "city": "Mukah",
      "cityCode": "SAR_Mukah",
      "provinceCode": "SAR"
    },
    {
      "city": "Saratok",
      "cityCode": "SAR_Saratok",
      "provinceCode": "SAR"
    },
    {
      "city": "Sarikei",
      "cityCode": "i12093",
      "provinceCode": "SAR"
    },
    {
      "city": "Sebuyau",
      "cityCode": "SAR_Sebuyau",
      "provinceCode": "SAR"
    },
    {
      "city": "Serian",
      "cityCode": "SAR_Serian",
      "provinceCode": "SAR"
    },
    {
      "city": "Sibu",
      "cityCode": "i533",
      "provinceCode": "SAR"
    },
    {
      "city": "Siburan",
      "cityCode": "SAR_Siburan",
      "provinceCode": "SAR"
    },
    {
      "city": "Simunjan",
      "cityCode": "SAR_Simunjan",
      "provinceCode": "SAR"
    },
    {
      "city": "Song",
      "cityCode": "SAR_Song",
      "provinceCode": "SAR"
    },
    {
      "city": "Spaoh",
      "cityCode": "SAR_Spaoh",
      "provinceCode": "SAR"
    },
    {
      "city": "Sri Aman",
      "cityCode": "i16654",
      "provinceCode": "SAR"
    },
    {
      "city": "Sundar",
      "cityCode": "SAR_Sundar",
      "provinceCode": "SAR"
    },
    {
      "city": "Tatau",
      "cityCode": "SAR_Tatau",
      "provinceCode": "SAR"
    },
    {
      "city": "Kabong",
      "cityCode": "SAR_Kabong",
      "provinceCode": "SAR"
    },
    {
      "city": "Lingga",
      "cityCode": "SAR_Lingga",
      "provinceCode": "SAR"
    },
    {
      "city": "Pusa",
      "cityCode": "SAR_Pusa",
      "provinceCode": "SAR"
    },
    {
      "city": "Roban",
      "cityCode": "SAR_Roban",
      "provinceCode": "SAR"
    },
    {
      "city": "Debak",
      "cityCode": "SAR_Debak",
      "provinceCode": "SAR"
    },
    {
      "city": "Engkilili",
      "cityCode": "SAR_Engkilili",
      "provinceCode": "SAR"
    },
    {
      "city": "Lubok Antu",
      "cityCode": "SAR_Lubok Antu",
      "provinceCode": "SAR"
    },
    {
      "city": "Belawai",
      "cityCode": "SAR_Belawai",
      "provinceCode": "SAR"
    },
    {
      "city": "Daro",
      "cityCode": "SAR_Daro",
      "provinceCode": "SAR"
    },
    {
      "city": "Matu",
      "cityCode": "SAR_Matu",
      "provinceCode": "SAR"
    },
    {
      "city": "Balingian",
      "cityCode": "SAR_Balingian",
      "provinceCode": "SAR"
    },
    {
      "city": "Julau",
      "cityCode": "SAR_Julau",
      "provinceCode": "SAR"
    },
    {
      "city": "Belaga",
      "cityCode": "SAR_Belaga",
      "provinceCode": "SAR"
    },
    {
      "city": "Sebauh",
      "cityCode": "SAR_Sebauh",
      "provinceCode": "SAR"
    },
    {
      "city": "Niah",
      "cityCode": "SAR_Niah",
      "provinceCode": "SAR"
    },
    {
      "city": "Long Lama",
      "cityCode": "SAR_Long Lama",
      "provinceCode": "SAR"
    },
    {
      "city": "Nanga Medamit",
      "cityCode": "SAR_Nanga Medamit",
      "provinceCode": "SAR"
    },
    // =================
    // =========SEL========
    {
      "city": "Ampang",
      "cityCode": "SEL_Ampang",
      "provinceCode": "SEL"
    },
    {
      "city": "Bandar Baru Bangi",
      "cityCode": "SEL_Bandar Baru Bangi",
      "provinceCode": "SEL"
    },
    {
      "city": "Bandar Puncak Alam",
      "cityCode": "SEL_Bandar Puncak Alam",
      "provinceCode": "SEL"
    },
    {
      "city": "Banting",
      "cityCode": "i12077",
      "provinceCode": "SEL"
    },
    {
      "city": "Batang Kali",
      "cityCode": "SEL_Batang Kali",
      "provinceCode": "SEL"
    },
    {
      "city": "Batu Arang",
      "cityCode": "i29786",
      "provinceCode": "SEL"
    },
    {
      "city": "Batu Caves",
      "cityCode": "SEL_Batu Caves",
      "provinceCode": "SEL"
    },
    {
      "city": "Beranang",
      "cityCode": "i29821",
      "provinceCode": "SEL"
    },
    {
      "city": "Bestari Jaya",
      "cityCode": "i29791",
      "provinceCode": "SEL"
    },
    {
      "city": "Bukit Rotan",
      "cityCode": "SEL_Bukit Rotan",
      "provinceCode": "SEL"
    },
    {
      "city": "Cheras",
      "cityCode": "SEL_Cheras",
      "provinceCode": "SEL"
    },
    {
      "city": "Cyberjaya",
      "cityCode": "SEL_Cyberjaya",
      "provinceCode": "SEL"
    },
    {
      "city": "Dengkil",
      "cityCode": "SEL_Dengkil",
      "provinceCode": "SEL"
    },
    {
      "city": "Hulu Langat",
      "cityCode": "SEL_Hulu Langat",
      "provinceCode": "SEL"
    },
    {
      "city": "Jenjarom",
      "cityCode": "i29765",
      "provinceCode": "SEL"
    },
    {
      "city": "Jeram",
      "cityCode": "SEL_Jeram",
      "provinceCode": "SEL"
    },
    {
      "city": "Kajang",
      "cityCode": "i8262",
      "provinceCode": "SEL"
    },
    {
      "city": "Kapar",
      "cityCode": "SEL_Kapar",
      "provinceCode": "SEL"
    },
    {
      "city": "Kerling",
      "cityCode": "SEL_Kerling",
      "provinceCode": "SEL"
    },
    {
      "city": "Klang",
      "cityCode": "i515",
      "provinceCode": "SEL"
    },
    {
      "city": "KLIA",
      "cityCode": "SEL_KLIA",
      "provinceCode": "SEL"
    },
    {
      "city": "Kuala Kubu Baru",
      "cityCode": "i29769",
      "provinceCode": "SEL"
    },
    {
      "city": "Kuala Selangor",
      "cityCode": "i16659",
      "provinceCode": "SEL"
    },
    {
      "city": "Kuang",
      "cityCode": "i29764",
      "provinceCode": "SEL"
    },
    {
      "city": "Pelabuhan Klang",
      "cityCode": "SEL_Pelabuhan Klang",
      "provinceCode": "SEL"
    },
    {
      "city": "Petaling Jaya",
      "cityCode": "i526",
      "provinceCode": "SEL"
    },
    {
      "city": "Puchong",
      "cityCode": "SEL_Puchong",
      "provinceCode": "SEL"
    },
    {
      "city": "Pulau Carey",
      "cityCode": "SEL_Pulau Carey",
      "provinceCode": "SEL"
    },
    {
      "city": "Pulau Indah",
      "cityCode": "SEL_Pulau Indah",
      "provinceCode": "SEL"
    },
    {
      "city": "Pulau Ketam",
      "cityCode": "SEL_Pulau Ketam",
      "provinceCode": "SEL"
    },
    {
      "city": "Rasa",
      "cityCode": "SEL_Rasa",
      "provinceCode": "SEL"
    },
    {
      "city": "Rawang",
      "cityCode": "i12092",
      "provinceCode": "SEL"
    },
    {
      "city": "Sabak Bernam",
      "cityCode": "SEL_Sabak Bernam",
      "provinceCode": "SEL"
    },
    {
      "city": "Sekinchan",
      "cityCode": "i29792",
      "provinceCode": "SEL"
    },
    {
      "city": "Semenyih",
      "cityCode": "i16657",
      "provinceCode": "SEL"
    },
    {
      "city": "Sepang",
      "cityCode": "SEL_Sepang",
      "provinceCode": "SEL"
    },
    {
      "city": "UPM Serdang",
      "cityCode": "SEL_UPM Serdang",
      "provinceCode": "SEL"
    },
    {
      "city": "Serendah",
      "cityCode": "i16660",
      "provinceCode": "SEL"
    },
    {
      "city": "Seri Kembangan",
      "cityCode": "SEL_Seri Kembangan",
      "provinceCode": "SEL"
    },
    {
      "city": "Shah Alam",
      "cityCode": "i532",
      "provinceCode": "SEL"
    },
    {
      "city": "Subang Jaya",
      "cityCode": "i16655",
      "provinceCode": "SEL"
    },
    {
      "city": "Sungai Ayer Tawar",
      "cityCode": "SEL_Sungai Ayer Tawar",
      "provinceCode": "SEL"
    },
    {
      "city": "Sungai Besar",
      "cityCode": "i16661",
      "provinceCode": "SEL"
    },
    {
      "city": "Sungai Buloh",
      "cityCode": "SEL_Sungai Buloh",
      "provinceCode": "SEL"
    },
    {
      "city": "Sungai Pelek",
      "cityCode": "i29819",
      "provinceCode": "SEL"
    },
    {
      "city": "Tanjong Karang",
      "cityCode": "SEL_Tanjong Karang",
      "provinceCode": "SEL"
    },
    {
      "city": "Tanjong Sepat",
      "cityCode": "SEL_Tanjong Sepat",
      "provinceCode": "SEL"
    },
    {
      "city": "Telok Panglima Garang",
      "cityCode": "SEL_Telok Panglima Garang",
      "provinceCode": "SEL"
    },
    // {
    //   "city": "Ampang Jaya",
    //   "cityCode": "i10481",
    //   "provinceCode": "SEL"
    // },
    // {
    //   "city": "Balakong",
    //   "cityCode": "i16656",
    //   "provinceCode": "SEL"
    // },
    // {
    //   "city": "Bandar Baru Salak Tinggi",
    //   "cityCode": "i29784",
    //   "provinceCode": "SEL"
    // },
    // {
    //   "city": "Batu",
    //   "cityCode": "i10483",
    //   "provinceCode": "SEL"
    // },
    // {
    //   "city": "Bukit Beruntung",
    //   "cityCode": "i29766",
    //   "provinceCode": "SEL"
    // },
    // {
    //   "city": "Gombak Setia",
    //   "cityCode": "i16658",
    //   "provinceCode": "SEL"
    // },
    // {
    //   "city": "Pengkalan Kundang",
    //   "cityCode": "i29826",
    //   "provinceCode": "SEL"
    // },
    // {
    //   "city": "Sabak",
    //   "cityCode": "i29804",
    //   "provinceCode": "SEL"
    // },
    // {
    //   "city": "Selayang Baru",
    //   "cityCode": "i530",
    //   "provinceCode": "SEL"
    // },
    // {
    //   "city": "Taman",
    //   "cityCode": "i12096",
    //   "provinceCode": "SEL"
    // },
    // {
    //   "city": "Tanjung Karang",
    //   "cityCode": "i29810",
    //   "provinceCode": "SEL"
    // },
    // {
    //   "city": "Tanjung Sepat",
    //   "cityCode": "i29796",
    //   "provinceCode": "SEL"
    // },
    // {
    //   "city": "Tanjung Sepat",
    //   "cityCode": "i29796",
    //   "provinceCode": "SEL"
    // },
    // {
    //   "city": "Batu 9 Cheras",
    //   "cityCode": "SEL_Batu 9 Cheras",
    //   "provinceCode": "SEL"
    // },
    // {
    //   "city": "UKM Bangi",
    //   "cityCode": "SEL_UKM Bangi",
    //   "provinceCode": "SEL"
    // },
    // {
    //   "city": "Subang Airport",
    //   "cityCode": "SEL_Subang Airport",
    //   "provinceCode": "SEL"
    // },
    // ================
    // ===========Terengganu=============
    {
      "city": "Ajil",
      "cityCode": "TER_Ajil",
      "provinceCode": "TER"
    },
    {
      "city": "Al Muktatfi Billah Shah",
      "cityCode": "TER_Al Muktatfi Billah Shah",
      "provinceCode": "TER"
    },
    {
      "city": "Ayer Puteh",
      "cityCode": "TER_Ayer Puteh",
      "provinceCode": "TER"
    },
    {
      "city": "Bukit Besi",
      "cityCode": "TER_Bukit Besi",
      "provinceCode": "TER"
    },
    {
      "city": "Bukit Payong",
      "cityCode": "TER_Bukit Payong",
      "provinceCode": "TER"
    },
    {
      "city": "Ceneh",
      "cityCode": "TER_Ceneh",
      "provinceCode": "TER"
    },
    {
      "city": "Chalok",
      "cityCode": "TER_Chalok",
      "provinceCode": "TER"
    },
    {
      "city": "Cukai",
      "cityCode": "TER_Cukai",
      "provinceCode": "TER"
    },
    {
      "city": "Dungun",
      "cityCode": "i10479",
      "provinceCode": "TER"
    },
    {
      "city": "Jerteh",
      "cityCode": "TER_Jerteh",
      "provinceCode": "TER"
    },
    {
      "city": "Kampung Raja",
      "cityCode": "TER_Kampung Raja",
      "provinceCode": "TER"
    },
    {
      "city": "Kemasek",
      "cityCode": "TER_Kemasek",
      "provinceCode": "TER"
    },
    {
      "city": "Kerteh",
      "cityCode": "i29771",
      "provinceCode": "TER"
    },
    {
      "city": "Ketengah Jaya",
      "cityCode": "TER_Ketengah Jaya",
      "provinceCode": "TER"
    },
    {
      "city": "Kijal",
      "cityCode": "TER_Kijal",
      "provinceCode": "TER"
    },
    {
      "city": "Kuala Berang",
      "cityCode": "TER_Kuala Berang",
      "provinceCode": "TER"
    },
    {
      "city": "Kuala Besut",
      "cityCode": "TER_Kuala Besut",
      "provinceCode": "TER"
    },
    {
      "city": "Kuala Terengganu",
      "cityCode": "i520",
      "provinceCode": "TER"
    },
    {
      "city": "Marang",
      "cityCode": "i29776",
      "provinceCode": "TER"
    },
    {
      "city": "Paka",
      "cityCode": "i29770",
      "provinceCode": "TER"
    },
    {
      "city": "Permaisuri",
      "cityCode": "TER_Permaisuri",
      "provinceCode": "TER"
    },
    {
      "city": "Sungai Tong",
      "cityCode": "TER_Sungai Tong",
      "provinceCode": "TER"
    },
    // {
    //   "city": "Jertih",
    //   "cityCode": "i29811",
    //   "provinceCode": "TER"
    // },
    // {
    //   "city": "Kemaman",
    //   "cityCode": "i12082",
    //   "provinceCode": "TER"
    // },
    // {
    //   "city": "Wakaf Tengah",
    //   "cityCode": "TER_Wakaf Tengah",
    //   "provinceCode": "TER"
    // },
    // {
    //   "city": "Kampong Raja",
    //   "cityCode": "TER_Kampong Raja",
    //   "provinceCode": "TER"
    // },
    // {
    //   "city": "Al Muktafi Billah Shah",
    //   "cityCode": "TER_Al Muktafi Billah Shah",
    //   "provinceCode": "TER"
    // },
    // =================
    {
      "city": "None",
      "cityCode": "-",
      "provinceCode": "KLP"
    },
    {
      "city": "None",
      "cityCode": "-",
      "provinceCode": "LAB"
    },
    {
      "city": "None",
      "cityCode": "-",
      "provinceCode": "PUT"
    },
  ],
}