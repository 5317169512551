import React from 'react'
import {
  Section,
  Text,
  Image,
  Grid,
  Button,
} from './../../components'
import {
  VARIABLES,
  TYPOGRAPHYS,
  ICONS
} from './../../themes'
import {
  ROUTE_PATH,
  redirect,
} from './../../helpers'
import { getLanguage } from '../../helpers/functions/language'

export class Information extends React.Component {

  render() {
    const { onSubmit } = this.props
    return (
      <Section
        justify='center'
        align='center'
      >
        <Grid gutter='7' align='center' justify='center'>
          <Grid.Column flex='none'>
            <Image
              widthRatio='18'
              widthRatioUnit='px'
              heightRatio='18'
              heightRatioUnit='px'
              src={ICONS['ic-info-black.svg']}
            />
          </Grid.Column>
          <Grid.Column flex='none'>
            <Text
              fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_22}
              color={VARIABLES.COLORS.BLACK}
            >
              {getLanguage('estimator.notes')}
            </Text>
          </Grid.Column>
        </Grid>
        <Section
          spacingBottom='20'
          padding='20'
          borderBottomWidth='1'
          borderBottomStyle='solid'
          borderBottomColor={VARIABLES.COLORS.GRAY_5}
        >
          <Grid justify='space-between'>
            <Grid.Column>
              <Text
                fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_12}
                color={VARIABLES.COLORS.GRAY_3}
              >
                {getLanguage('estimator.noteWidth')}
              </Text>
            </Grid.Column>
            <Grid.Column>
              <Text
                fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_12}
                color={VARIABLES.COLORS.GRAY_2}
              >
                |
              </Text>
            </Grid.Column>
            <Grid.Column>
              <Text
                fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_12}
                color={VARIABLES.COLORS.GRAY_2}
              >
                {getLanguage('estimator.notePitch')}
              </Text>
            </Grid.Column>
            <Grid.Column>
              <Text
                fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_12}
                color={VARIABLES.COLORS.GRAY_2}
              >
                |
              </Text>
            </Grid.Column>
            <Grid.Column>
              <Text
                fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_12}
                color={VARIABLES.COLORS.GRAY_2}
              >
                {getLanguage('estimator.noteOverhang')}
              </Text>
            </Grid.Column>
          </Grid>
        </Section>
        <Section
          spacingBottom='21'
          justify='center'
        >
          <Button
            shadow
            className='is-gradient-blue is-icon-right'
            width='275'
            height={VARIABLES.BUTTON.HEIGHTS.H_40}
            borderRadius={VARIABLES.BUTTON.BORDER_RADIUSES.BR_10}
            onClick={onSubmit.bind(this)}
          >
            <Button.Icon>
              <Image
                widthRatio='15'
                widthRatioUnit='px'
                heightRatio='12'
                heightRatioUnit='px'
                src={ICONS['ic-arrow-right.svg']}
              />
            </Button.Icon>
            <Text
              fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_16}
              color={VARIABLES.COLORS.WHITE}
            >
              {getLanguage('estimator.buttonEstimation')}
            </Text>
          </Button>
        </Section>
        <Section
          spacingBottom='40'>
          <Text
            align='center'
            fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_12}
            color={VARIABLES.COLORS.GRAY_3}
          >
            {getLanguage('estimator.footer_1')}
            <br />
            {getLanguage('estimator.footer_2')}
            <br />
            {getLanguage('estimator.footer_3')}
            <br />
            {getLanguage('estimator.footer_4')}
            <br />
            {getLanguage('estimator.footer_5')}{' '}
            <Text
              className='is-underline'
              fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_12}
              color={VARIABLES.COLORS.BLUE_2}
              onClick={() => { redirect(ROUTE_PATH.TERM_POLICY.LINK) }}
            >
              {getLanguage('estimator.footer_6')}
            </Text>
            {' '} {getLanguage('estimator.footer_7')}
          </Text>
        </Section>
      </Section>
    )
  }
}
