import React from 'react'
import ClassNames from 'classnames'
import PropTypes from 'prop-types'
import {
  NavigationBarWrapper
} from './styled'
import {
  Grid,
  Section,
  Text,
  Image
} from './../../../components'
import {
  default as VARIABLES
} from './../../../themes/styles/bases/variables'
import {
  default as TYPOGRAPHYS
} from './../../../themes/styles/bases/typographys'
// import {
//   default as UTILITIES
// } from './../../../themes/styles/helpers/utilities'

/**
 * Navigation bar description:
 * - Navigation bar
 */

export class NavigationBar extends React.PureComponent {
  render () {
    const {
      className,
      children,
      pageTitle,
      pageTitleIcon,
      actionLeft,
      actionRight,
      shadow,
      iconWidth,
      iconHeight,
      iconSrc,
      iconPositionTop
    } = this.props

    // props for css classes
    const classes = ClassNames(
      'navigation-bar',
      {'is-shadow': shadow},
      className
    )

    return (
      <NavigationBarWrapper
        className={classes}
      >
        <div className='navigation-bar-container'>
          <Grid>
            <Grid.Column className='navigation-bar-action is-left'>
              {actionLeft}
            </Grid.Column>
            <Grid.Column flex='1'>
              <Section justify='center'>
                {pageTitleIcon &&
                  <Grid
                    gutter='10'
                    justify='center'
                  >
                    {iconSrc && <Grid.Column>
                      <Image
                        widthRatio={iconWidth}
                        widthRatioUnit='px'
                        heightRatio={iconHeight}
                        heightRatioUnit='px'
                        src={iconSrc}
                        top={iconPositionTop}
                      />
                    </Grid.Column>}
                    <Grid.Column>
                      <Text
                        singleLine
                        fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_18}
                        color={VARIABLES.COLORS.BLACK}
                      >
                        {pageTitleIcon}
                      </Text>
                    </Grid.Column>
                  </Grid>
                }
                {pageTitle &&
                  <Text
                    singleLine
                    fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_18}
                    color={VARIABLES.COLORS.BLACK}
                  >
                    {pageTitle}
                  </Text>
                }
              </Section>
            </Grid.Column>
            <Grid.Column className='navigation-bar-action is-right'>
              {actionRight}
            </Grid.Column>
          </Grid>
        </div>
        {children}
      </NavigationBarWrapper>
    )
  }

  static defaultProps = {
    // * If you set new default props, you should create propsType for correct styleguide document.
    iconPositionTop: '-1'
  }

  static propTypes = { // TYPE > node, string, number, bool, array, object, symbol, func
    /**
    * Additional classes
    */
    className: PropTypes.string
  }
}
