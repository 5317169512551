import React from 'react'
import ReactDOM from 'react-dom'
// Reset & Element style
import './themes/styles/bases/reset'
import './themes/styles/bases/scaffolding'
// Component style
import './components/custom/AccordionControl/styled'
import './components/custom/Button/styled'
import './components/custom/CmsLayout/styled'
import './components/custom/Container/styled'
import './components/custom/Field/styled'
import './components/custom/Grid/styled'
import './components/custom/Image/styled'
import './components/custom/Modal/styled'
import './components/custom/Notification/styled'
import './components/custom/PaginationControl/styled'
import './components/custom/Section/styled'
import './components/custom/ShapeContainer/styled'
import './components/custom/Table/styled'
import './components/custom/TemplateForBase/styled'
import './components/custom/Text/styled'
// Page & Global style
import './themes/styles/layouts/page'
import './themes/styles/layouts/global'
import App from './App'
import * as serviceWorker from './serviceWorker'
import ReactGA from 'react-ga';

ReactGA.initialize('UA-150843057-1');

ReactDOM.render(<App />, document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister()
serviceWorker.register()