export const lang_ml = {
  sideMenu: {
    points: 'Mata ganjaran',
    myProfile: 'Profil saya',
    settings: 'Tetapan',
    shareWithFriends: 'Kongsi',
    termsAndConditions: 'Terma dan syarat',
    logOut: 'Log keluar',
  },
  splash: {
    title: 'Memperkembang bisnes dengan anda',
    description: 'Penglibatan . Mempamerkan . Berkembang',
  },
  login: {
    loginByEmail: 'Log masuk dengan e-mel',
    or: 'Or',
    loginByPhoneNumber: 'Log masuk dengan telefon bimbit',
    orLoginWith: 'Atau log masuk dengan',
    reLogin: 'For account security, please log in again.',
    incorrectMemberType: 'Incorrect member type.',
  },
  loginEmail: {
    message: 'Lupa kata laluan?',
    message_2: 'Pilih lagi?',
  },
  loginPhone: {
    message: 'Lupa kata laluan?',
    message_2: 'Pilih lagi?',
  },
  welcome: {
    message_1: 'Selamat Datang',
    message_2: 'Terima Kasih, Anda adalah ahli TrueBlue',
    message_3: 'and',
    message_4: 'you can check your point as well',
  },
  referenceCode: {
    message_1: 'Reference Code',
    message_2: 'Expired',
  },
  referralAd: {
    message_1: 'How to get',
    message_2: 'Voucher',
    message_3: 'Must be BlueScope customer',
    message_4: 'and TrueBlue member from',
    message_5: '30 Jun 2021',
  },
  lineOaValidation: {
    message_1: 'We have added a new way to',
    message_2: 'provide news and benefits.',
    message_3: 'Please scan QR code or',
    message_4: 'click button to receive.',
    message_5: 'or',
    message_6: 'Welcome',
  },
  voucherOutOfStock: {
    message_1: 'Sorry,',
    message_2: "you do not meet the conditions to",
    message_3: 'receive the E-Voucher.',
    message_4: 'In order not to miss out on receiving special privileges from',
    message_5: 'BlueScope next time',
    message_6: 'You can sign up to become a TrueBlue',
    message_7: 'Member through',
    message_8: 'LineOA today.',
    message_9: 'The purpose is to notify TrueBlue',
    message_10: 'points on every purchase.',
  },
  registerSuccess: {
    message_1: 'Register Success!',
    message_2: 'and you get',
    message_3: 'a voucher',
    message_4: 'A Voucher will be sent to the registered address',
    message_5: 'Sorry, the voucher',
    message_6: 'is sold out.',
  },
  authenticationSuccess: {
    message_1: 'Authentication Success!',
    message_2: 'Please return to TrueBlue',
  },
  register: {
    title_1: 'Daftar',
    title_2: 'Pengesahan OTP',
    message_1: 'Sudah memiliki akaun?',
    message_2: 'Dihantar',
  },
  home: {
    buttonViewMore: 'Maklumat lanjut',
    tbPoints: 'Mata ganjaran TrueBlue',
    tbRewards: 'Ganjaran TrueBlue',
    estimator: 'Penganggaran',
    newsAndPromotions: 'Berita & Promosi',
    myProjects: 'Projek saya',
    products: 'Produk',
    findDealer: 'Mencari pengedar sah',
    knowledgeBase: 'Pangkalan pengetahuan',
    purchaseHistory: 'Sejarah pembelian',
    orderInquiry: 'Pesanan',
    warranty: 'Waranti',
    training: 'TRAINING',
    your: 'Your',
    length: 'Length',
    metre: 'm',
    purchasing: 'Purchasing',
    cumulativeAmountSince: 'Cumulative amount since',
    tbMember: 'TrueBlue Member',
    rankInformation: 'Rank Information',
    moreDetail: 'More Detail',
    moreDetailExtra: 'More Details',
    noRank: 'No Rank',
    luckyDraw: 'Lucky Draw',
    willExpireOn: 'will expire on',
    point: 'point',
    checkPoint: 'Check points will expire',

  },
  myProfile: {
    title: 'Profil saya',
    updateProfile: 'Kemaskini profil',
    changePassword: 'Tukar kata laluan',
  },
  changePassword: {
    title: 'Tukar kata laluan',
  },
  forgotPassword: {
    title: 'Lupa kata laluan',
    labelEnterEmail: 'Sila isikan e-mel anda',
    labelEnterPhone: 'Sila isikan telefon anda',
  },
  resetPassword: {
    title: 'Menetap semula kata laluan',
  },
  setting: {
    title: 'Tetapan',
    language: 'Bahasa',
    notification: 'Notifikasi',
  },
  settingNoti: {
    title: 'Notifikasi tetapan',
    orderInquiry: 'Pesanan',
    newsAndPromotions: 'Berita & Promosi',
  },
  settingLanguage: {
    title: 'Bahasa',
    chooseLanguage: 'Pilih bahasa kegemaran anda',
    pleaseSelectLanguage: 'Sila pilih bahasa anda',
  },
  termPolicy: {
    title: 'Terma dan syarat',
  },
  loyaltyPoint: {
    title: 'Mata ganjaran TrueBlue',
    points: 'Mata ganjaran',
    expire: 'Mata ganjaran tamat tempoh',
    expireIn: 'Mata ganjaran tamat tempoh dalam',
    tab: {
      earnedPoints: {
        title: 'Mata ganjaran terkumpul',
      },
      redeemedPoints: {
        title: 'Mata ganjaran ditebus',
      },
    },
    tbPoints: 'Mata ganjaran TrueBlue',
    numberOfRewards: 'Nombor ganjaran',
  },
  rewardCat: {
    title: 'Ganjaran TrueBlue',
    blank: 'Data tidak dijumpai',
    new: 'Baharu',
    points: 'Mata ganjaran',
  },
  reward: {
    title: 'Ganjaran',
  },
  rewardSearch: {
    title_1: 'Keputusan carian',
    title_2: 'Carian',
    rewardName: 'Nama ganjaran',
    searchResult: 'Keputusan carian',
  },
  rewardDetail: {
    points: 'Mata ganjaran',
  },
  estimator: {
    title: 'Penganggaran',
    roofType: {
      gableRoof: 'Gable Roof',
      hipRoof: 'Hip Roof',
    },
    profileType: {
      tile: 'Metal Tile',
      sheet: 'Metal Sheet',
    },
    notes: 'Nota',
    noteWidth: 'Lebar: 4 : 12m',
    notePitch: 'Pitch: 25 : 35 deg',
    noteOverhang: 'Overhang <= 500mm',
    buttonEstimation: 'Anggaran',
    footer_1: 'Penafian : Alat penganggaran hanya untuk rujukan sahaja',
    footer_2: 'dan berdasrkan kepada maklumat yang disediakan oleh pengguna.',
    footer_3: 'NS BlueScope tidak menjamin ketepatan maklumat yang mana-mana',
    footer_4: 'Atau kesesuaian untuk spesifikasi anda.',
    footer_5: 'Sila rujuk kepada hyperlink',
    footer_6: 'Terma penggunaan',
    footer_7: 'Untuk maklumat lebih lanjut.',
  },
  estimatorSummary: {
    title: 'Rumusan kiraan',
    quantityEstimate: 'Bilangan penganggaran',
    congratulations: 'Tahniah!',
    note_1: 'Anda satu langkah lebih dekat dengan membina projek impian',
    note_2: 'Sila kunjungi Pengedar Sah BlueScope yang terdekat anda untuk',
    note_3: 'Melengkapkan transaksi',
    category: {
      title_1: 'Kategori: Bumbung',
      note_1: 'Bilangan skru bergantung kepada jenis profil bumbung',
      title_2: 'Kategori: Struktur bumbung',
      title_3: 'Kategori: Aksesori',
      type: 'Jenis',
      profile: 'Profil',
      qty: 'Qty',
      unit: 'Unit',
      notes: 'Nota',
    }
  },
  newsAndPromotions: {
    title: 'Berita & Promosi',
    tab: {
      news: {
        title: 'Berita',
      },
      promotions: {
        title: 'Promosi',
      },
    },
  },
  newsAndPromotionsComment: {
    title: 'Komen'
  },
  newsAndPromotionsWriteComment: {
    title: 'Komen',
    specifyTitle: 'Sila menentukan tajuk',
    specifyComment: 'Sila menentukan komen',
    characters: 'Teks',
  },
  newsAndPromotionsSearch: {
    title: 'Carian',
    searchResult: 'Keputusan carian :',
  },
  myProjects: {
    title: 'Projek saya',
  },
  projectsAdd: {
    title: 'Projek saya',
  },
  projectsEdit: {
    title: 'Ubah projek',
  },
  projectsSearch: {
    title_1: 'Keputusan carian',
    title_2: 'Carian',
    searchProject: 'Carian projek',
    searchResult: 'Keputusan carian:',
  },
  productCatalogs: {
    title: 'Senarai Produk',
  },
  findDealer: {
    title: 'Mencari Pengedar Sah',
    findDealer: 'Mencari Pengedar Sah',
    selectDealer: {
      title: 'Sila pilih jenis pengedar',
      bad: 'Pengedar Sah BlueScope',
      or: 'Peniaga Perkakasan',
      distributor: 'Pengedar',
      tokoStore: 'Peniaga perkakasan',
      indoBad: 'Pengedar Sah BlueScope ',
    }
  },
  findDealerType: {
    title: 'Jenis Pengedar',
    nearestDealers: 'Pengedar berhampiran',
    byName: 'Carian dengan nama',
    byArea: 'Pilih kawasan',
    or: 'Atau',
  },
  findDealerSearch: {
    title: 'Pengedar dekat anda',
    dealerCard: {
      buttonGetDirection: 'Petunjuk arah',
      buttonViewDetail: 'Semak butiran',
    }
  },
  findDealerSearchByName: {
    title: 'Carian dengan nama',
    byName: 'Carian dengan nama',
  },
  findDealerSearchByArea: {
    title: 'Pilih kawasan',
  },
  knowledgeBase: {
    title: 'Pangkalan Pengetahuan'
  },
  knowledgeBaseDetail: {
    title: 'Pangkalan pengetahuan',
    tab: {
      description: {
        title: 'Penerangan',
      },
      documentsDownload: {
        title: ' Muat turun dokumen',
      },
    },
  },
  purchaseHistory: {
    title_1: 'Keputusan carian',
    title_2: 'Sejarah pembelian',
    totalSales: 'Jumlah jualan',
    DataNotFound: 'Data tidak dijumpai',
  },
  purchaseHistorySearch: {
    title: 'Carian tarikh',
  },
  purchaseHistoryDetail: {
    title: 'Sejarah pembelian',
  },
  orderInquiry: {
    title: 'Pesanan',
    category: {
      bad: 'Pengedar Sah',
      or: 'Peniaga perkakasan',
    },
    selectDealer: 'Pilih pengedar',
    result: 'Keputusan',
    orderInquiry: 'Pesanan',
    allPreviousOrderInquiry: 'Semua pesanan dahulu',
    dataNotFound: 'Data tidak dijumpai',
  },
  orderInquirySearch: {
    title: 'Carian',
    category: {
      bad: 'Pengedar Sah',
      or: 'Peniaga perkakasan',
    },
    selectDealer: 'Pilih pengedar',
    suggestions: 'Cadangan',
  },
  orderInquiryAdd: {
    title: 'Tambah pesanan',
  },
  warranty: {
    title: 'Waranti',
  },
  warrantySearchByDate: {
    title: 'Cari tarikh',
  },
  warrantySearchByName: {
    title_1: 'Keputusan carian',
    title_2: 'Carian',
    warranty: 'Nombor waranti, Nama waranti',
    salesADProduct: 'ID Transaksi Jualan, AD, Produk nama',
    searchResult: 'Keputusan carian',
  },
  warrantySearch: {
    title: 'Keputusan carian',
    totalOrders: 'Jumlah pesanan',
  },
  warrantyProduct: {
    dataNotFound: 'Data tidak dijumpai',
  },
  warrantyProductItem: {
    issuedDate: 'Tarikh menawar',
    product: 'Produk',
    thickness: 'Ketebalan',
    length: 'Panjang',
    warrantyID: 'ID Waranti',
  },
  termsAndConditions: {
    title: 'Terma dan syarat',
  },
  verify: {
    title1: 'Setup Verify Questions',
    title2: 'Verify with Questions',
    title3: 'Verify with Phone Number',
    question1: 'What is your nickname?',
    question2: "What is your favorite animal?",
    question3: 'What is the province where you were born?',
  },
  container: {
    inputOtp: {
      title: 'Pengesahan OTP',
      message: 'Hantar',
      message_2: 'OTP Salah, Sila hantar semula',
    },
  },
  modal: {
    changeLanguage: {
      title: 'Tukar Bahasa',
      message: 'Bahasa anda telah dikemaskini.',
    },
    editProfile: {
      title: 'Ubah Profil',
      message_1: 'Profil anda telah dikemaskini.',
      message_2: 'Maklumat anda mungkin tidak tepat, sila cuba lagi',
      message_3: 'Sesuatu tersalah',
    },
    changePassword: {
      title: 'Tukar kata laluan sukses!',
      message: 'Sila guna kata laluan baharu untuk log masuk.',
      fail: 'Gagal',
      passwordNotMatch: 'Kata laluan tidak padan',
      passwordIncorrectFormat: 'Format kata laluan tidak betul',
      changeSuccess: 'Tukar kata laluan sukses!',
      nextLogin: 'Sila guna kata laluan baharu untuk log masuk',
    },
    commentPosted: {
      title: 'Komen terpapar!',
      message: 'Terima Kasih untuk maklum balas anda',
    },
    confirmation: {
      title: 'Pengesahan',
      message: 'Adakah anda ingin memadam',
      message_2: 'Adakah anda pasti untuk memadam',
      message_2_2: 'Projek ini?',
    },
    warrantyDetail: {
      projectName: 'Nama Projek ',
      address: 'Alamat',
      quantity: 'Bilangan',
      color: 'Warna (Jika ada)',
    },
    newVersion: {
      title_1: 'Versi TrueBlue baharu',
      title_2: 'Kini tersedia.',
    },
    forgotPassword: {
      titleSendResetLink: 'Link kata laluan telah dihantar!',
      messageCheckEmail: 'Sila semak e-mail anda',
      titleSendOtp: 'OTP telah dihantar!',
      messageCheckNotification: 'Sila semak notifikasi anda',
    },
    resetPassword: {
      title: 'Tukar kata laluan sukses!',
      message_1: 'Sila cuba kata laluan baru anda',
      message_2: 'Log masuk yang seterusnya',
    },
    iosAddToHomeScreen: {
      title_1: 'Tambah ke skrin utama',
      title_2: 'Kongsi dengan',
    },
    socialInputPhone: {
      title: 'Sila isikan telefon nombor anda',
    },
    notiTnc: {
      title_1: 'Perkhidmatan TrueBlue',
      title_2: 'Terma dan syarat',
      message_1: 'Sila baca sebelum anda dapat meneruskan',
      message_2: 'Baca and bersetuju',
      message_3: 'Terma dan Syarat',
    },
    otpSend: {
      title: 'OTP dihantar!',
      description: 'Sila periksa notifikasi anda',
    },
    authentication: {
      title: 'Authentication link has been sent to your phone (SMS)',
      description1: 'Please approve the access from the link within 3 minutes',
      description2: 'Reference code is',
      description3: 'Do not close this window until the authentication is complete',
      description4: 'or',
      link: 'Re-Send Authentication Link',
    },
  },
  component: {
    component: {
      likes: 'Suka',
      comments: 'Komen',
      buttonComment: 'Komen',
    }
  },
  field: {
    buttonOK: 'OK',
    buttonClose: 'Tutup',
    buttonSearch: 'Carian',
    buttonClear: 'Kembali',
    buttonSave: 'Kemas kini',
    buttonSaveAndChange: 'Kemas kini & ubah',
    buttonSaveAsPhoto: 'Simpan sebagai gambar',
    buttonSharePhoto: 'Kongsi gambar',
    buttonSubmit: 'Hantar',
    buttonAddNewProject: 'Tambah projek baharu',
    buttonEditProject: 'Ubah projek',
    buttonDeleteProject: 'Padam projek',
    buttonAddPhoto: 'Tambah gambar',
    hintButtonAddPhotoLineOne: 'Select a .jpg, .jpeg, .png file and',
    hintButtonAddPhotoLineTwo: 'maximum upload file size is 1MB.',
    buttonDelete: 'Padam',
    buttonCancel: 'Batal',
    buttonFilter: 'Penapisan',
    buttonAddNewOrderInquiry: 'Tambah pesanan baharu',
    buttonMoreDetail: 'Maklumat lanjut',
    buttonUpdate: 'Kemaskini',
    buttonSend: 'Hantar',
    buttonContinue: 'Seterusnya',
    buttonContinueRegister: 'Continue Register',
    buttonSignUp: 'Sign up',
    buttonAgree: 'Setuju',
    buttonReSend: 'Hantar semula',
    buttonLogin: 'Log masuk',
    buttonBack: 'Balik',
    buttonConfirm: 'Confirm',
    buttonApprove: 'Approve',
    buttonToLogin: 'Back to Login',
    buttonRegisterNow: 'Register Now',
    buttonGoToTrueBlue: 'Go to TrueBlue',
    buttonBlueScopeThailand: 'BlueScope Thailand',
    labelNew: 'BAHARU',
    labelSample: 'SAMPEL',
    firstname: 'Nama pertama',
    firstnamePlaceholder: 'Isikan nama pertama',
    lastname: 'Nama Keluarga',
    lastnamePlaceholder: 'Isikan nama keluarga',
    nickname: 'Nick Name',
    nicknamePlaceholder: 'Insert nick name',
    company: 'Company',
    companyPlaceholder: 'Insert company',
    type: 'Kategori',
    typePlaceholder: 'Sila pilih kategori',
    phoneNumber: 'Nombor telefon',
    mobilePhoneNumber: 'Nombor telefon bimbit',
    email: 'E-mel',
    emailPlaceholder: 'Isikan e-mel',
    birthDate: 'Tarikh lahir',
    birthDatePlaceholder: 'Sila pilih tarikh lahir anda',
    address: 'Alamat',
    addressPlaceholder: 'Isikan alamat',
    province: 'Negeri',
    provincePlaceholder: 'Sila pilih negeri',
    city: 'Bandar',
    cityPlaceholder: 'Sila pilih bandar',
    district: 'Daerah',
    districtPlaceholder: 'Isikan daerah',
    subDistrict: 'Daerah',
    subDistrictPlaceholder: 'Isikan daerah',
    subDistrictSelectPlaceholder: 'Please select sub-district',
    alley: 'Jalan',
    alleyPlaceholder: 'Isikan jalan',
    group: 'Kumpulan',
    groupPlaceholder: 'Isikan kumpulan',
    street: 'Jalan',
    streetPlaceholder: 'Isikan jalan',
    ward: 'Ward',
    wardPlaceholder: 'Isikan ward',
    postCode: 'Poskod code',
    postCodePlaceholder: 'Isikan poskod',
    postCodeSelectPlaceholder: 'Please select post code',
    country: 'Negara',
    countryPlaceholder: 'Isikan negara',
    password: 'Kata laluan',
    passwordHint: '8 to 24 teks panjang',
    oldPassword: 'Kata laluan lama',
    newPassword: 'Kata laluan baharu',
    newPasswordHint: '8 to 24 teks panjang',
    confirmPassword: 'Tetap kata laluan',
    roofType: 'Jenis Bumbung',
    roofTypePlaceholder: 'Pilih jenis bumbung',
    profileType: 'Jenis Profil',
    profileTypePlaceholder: 'Pilih jenis profil',
    widthUnit: 'Lebar (m)',
    lengthUnit: 'Panjang (m)',
    heightUnit: 'Ketinggian (m)',
    overhangUnit: 'Overhang (mm)',
    title: 'Tajuk',
    titlePlaceholder: 'Isikan tajuk',
    comment: 'Komen',
    commentPlaceholder: 'Apakan pendapat anda mengenai…',
    newsAndPromotionsPlaceholder: 'Carian berita & promosi',
    projectName: 'Nama projek',
    location: 'Lokasi',
    areaSizeProject: 'Saiz kawasan projek',
    product: 'Produk',
    description: 'Penerangan',
    descriptionPlaceholder: 'Isikan penerangan…',
    selectDealerPlaceholder: 'Pilih pengedar',
    selectProvincePlaceholder: 'Pilih negeri',
    selectAmphoePlaceholder: 'Pilih khet/amphoe',
    filterStartDateEndDatePlaceholder: 'Penapis tarikh mula - tarikh tamat',
    startDatePlaceholder: 'Tarikh mula',
    endDatePlaceholder: 'Tarikh tamat',
    productGroup: 'Kumpulan produk',
    coilName: 'Nama koil',
    thickness: 'Ketebalan',
    color: 'Warna',
    length: 'Panjang',
    meter: 'Meter',
    points: 'Mata ganjaran',
    points_2: 'Mata ganjaran',
    where: 'Nama pengedar',
    status: 'Status',
    trasactionID: 'Transaksi ID',
    selectCategoryPlaceholder: 'Pilih kategori',
    subject: 'Subjek',
    subjectPlaceholder: 'Isikan subjek',
    selectAreaPlaceholder: 'Pilih kawasan',
    phoneIsIncorrectFormat: 'Salah format untuk nombor telefon',
    agreeTermsAndConditions: 'Setuju dengan terma dan syarat',
    questionPlaceholder: 'Insert Answer',
    birthDayPlaceholder: 'Select Birthday',
    customerTypeFixer: 'Kontraktor',
    customerTypeHardware: 'Kedai Logam',
    customerTypeHomeowner: 'Pemilik Rumah / Pemilik Projek',
    customerTypeRollFormer: 'Pengilang bahan bumbung',
    customerTypeOthers: 'Lain (Military, Government)',
    customerTypeArchitect: 'Architect / Designer / Consult',
    customerTypeEngineer: 'Engineer'
  },
  message: {
    fail: 'Gagal',
    pleaseInputPhone: 'Sila isikan nombor telefon',
    pleaseInputAllField: 'Sila isikan semua butiran',
    pleaseInputAllRequiredField: 'Please insert all required field',
    somethingWrong: 'Ada sesuatu yang tersalah, Sila cuba lagi',
    cannotGetYourCurrentLocation: 'Tidak dapat lokasi anda sekarang',
    pleaseSelectStartDateAndEndDate: 'Sila pilih tarikh mula dan tarikh tamat',
    invalidDateFormat: 'Format tarikh salah',
    pleaseSelectCategoryAndDealer: 'Sila pilih kategori and pengedar',
    yourOrderInquiryHasBeenSent: 'Pesanan anda telah dihantar!',
    pleaseSpecifySubject: 'Sila menentukan subjek',
    pleaseSpecifyDescription: 'Sila menentukan penerangan',
    browserCantShare: `Pelayar ini tidak dapat dikongsikan. Sila guna Chrome atau Safari.`,
    phoneDoesntExist: 'Nombor telefon ini tidak wujud',
    passwordNotMatch: 'Kata laluan tidak padan',
    passwordIncorrectFormat: 'Format kata laluan salah',
    dataNotFound: 'Data tidak dijumpai',
    widthOutOfCondition: 'Lebar di luar jangkauan',
    pitchOutOfCondition: 'Padang di luar jangkauan',
    invalidEmailFormat: 'E-mel format tidak betul',
    pleaseInputFirstnameAndLastname: 'Sila isi nama',
    phoneInCorrectFormat: 'Format telefon tidak betul',
    emailInCorrectFormat: 'Format e-mel tidak betul',
    nameInCorrectFormat: 'Format nama tidak betul.',
    emailAddressAlreadyExists: 'E-mel sudah didaftar',
    loginBySocialError: 'Log masuk dengan media sosial salah.',
    cantGetUserInfo: "Tidak dapat maklumat penguna",
    phoneEmailAlreadyExists: 'Telefon/E-mel sudah wujud',
    phoneAlreadyExists: 'Telefon sudah wujud',
    sendOtpError: 'Kesilapan dengan OTP',
    getSecretOtpError: 'Dapatkan rahsia OTP yang salah',
    otpIncorrect: 'OTP tidak betul , Sila hantar semula',
    pleaseEnterPassword: 'Sila masuk kata laluan',
    pleaseEnterPhone: 'Sila masuk telefon',
    pleaseEnterPhoneAndPassword: 'Please Enter Phone and Password',
    wrongUsernameOrPassword: 'Nama penguna atau Kata laluan tidak betul',
    browserNotSupport: `This browser not support please use Chrome or Safari.`,
    phoneNotMatch: "Phone number doesn't match the TrueBlue member system.",
    phoneNotMatch1: 'If you have been a TrueBlue member. Please contact',
    phoneNotMatch2: 'BlueScope Metal Sheet Center',
    phoneNotMatch3: 'or if you are not a member yet.',
    wrongAnswer: 'Wrong answer',
    passwordAndConfirmPasswordNotMatch: "Password and Confirm Password aren’t match.",
  },
  luckyDraw: {
    title: 'Lucky Draw',
    termsAndConditions: 'Terms & Conditions',
    agreeTermsAndConditions: 'Agree terms and conditions',
    dontShowThisMessageAgainNextTime: `Don't show this message again next time`,
    accept: 'Accept',
    winPrizes: 'Win Prizes',
    awardAnnouncement: 'Award Announcement',
    noLuckyDrawEvent: 'No Lucky Draw Event',
    usePointToWinPrizes: 'Use points to win prizes',
    point: 'point',
    right: 'right',
    numberOfUsedPoints: 'Number of used points',
    yourPointIsNotEnough: 'Your points is not enough',
    buttonWinPrizes: 'Win prizes',
    reward: 'Reward',
    viewDetail: 'View detail',
    readTermsAndConditions: 'Read terms and conditions',
    announcement: 'Announcement',
    announcementOfNameWinners: 'Announcement of name winners',
    campaign: 'Campaign',
    dateFrom: 'From',
    dateUntil: 'until',
    showMore: 'Show more',
    awardAnnounced: 'Awards announced!',
    youCanClickBtnBelowToSeeYourListName: 'You can click the button below to see your list name',
    buttonCheckMyName: 'Check my name!',
    expirePoint: 'Expire point',
    toExpire: 'To Expire',
    points: 'points',
    dontWasteYourPoint: `Don't waste your point`,
    useYourPointToExchangeForAChanceToWinPrizes: 'Use your points to exchange for a chance to win prizes',
    buttonGoToWinPrizes: 'Go to win prizes',
    total: 'Total',
    youUseYourRightToWinPrizes: 'You use your right to win prizes',
    youHave: 'You have',
    pointLeft: 'points left',
    awardCount: 'Number of awards',
    showLess: "Show less",
    rewardTitle: '[quantity] prize',
    campaignDurationDate: 'Start [startDate] until [endDate]',
    campaignLuckyDrawDate: 'Prize draw on',
    announcementRewardDetail: '[rewardDetail] ([quantity] prize)',
    noAwardAnnouncement: 'No Award Announcement',
    youHaveAllThePoint: 'You have all the points :',
    rewardTitleQuantity: 'Quantity [quantity] prize',
    youHaveAMaximumRedeemablePointOfPoint: 'You have a maximum redeemable point of [point] points.',

  },
}