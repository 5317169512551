import React from 'react'
import ClassNames from 'classnames'
// import PropTypes from 'prop-types'
import {
  ModalBase
} from './../../base'
import {
  Modal
} from './../../custom'
import {
  Section,
  Image,
  Text,
  Button
} from './../../../components'
import {
  VARIABLES,
  TYPOGRAPHYS,
  ICONS,
  LOGOS,
} from './../../../themes'
import { DATA } from '../../../helpers/constants/data'
// import {
//   default as TYPOGRAPHYS
// } from './../../../themes/styles/bases/typographys'
// import {
//   default as UTILITIES
// } from './../../../themes/styles/helpers/utilities'

/**
 * Alert description:
 * - Alert
 */

export class Alert extends React.PureComponent {
  render() {
    const {
      className,
      type,
      open,
      title,
      description,
      descriptionSecondary,
      onClose,
      logoHeadingTrueBlue,
      singleButton,
      buttons,
      // buttonText,
    } = this.props

    // props for css classes
    const classes = ClassNames(
      className,
      // { 'is-modifier-name': modifierName }
    )

    return (
      <Modal
        className={classes}
        open={open}
        width={VARIABLES.MODAL.WIDTHS.W_300}
        overlayColor={VARIABLES.COLORS.OVERLAY_3}
        spacing={VARIABLES.MODAL.CONTAINER.C_1.SPACING}
        transition={VARIABLES.TRANSITIONS.DEFAULT}
        transformStart={VARIABLES.MODAL.CONTAINER.C_1.TRANSFORM_START}
        transformEnd={VARIABLES.MODAL.CONTAINER.C_1.TRANSFORM_END}
      // onClickClose={this.modal.onClickCloseModalSuccess}
      >
        <Modal.Section
          paddingTop={VARIABLES.MODAL.HEADER.H_1.PADDING_TOP}
          paddingBottom={VARIABLES.MODAL.HEADER.H_1.PADDING_BOTTOM}
          paddingHorizontal={VARIABLES.MODAL.HEADER.H_1.PADDING_HORIZONTAL}
          borderTopRightRadius={VARIABLES.MODAL.HEADER.H_1.BORDER_TOP_RIGHT_RADIUS}
          borderTopLeftRadius={VARIABLES.MODAL.HEADER.H_1.BORDER_TOP_LEFT_RADIUS}
        >
          <Section justify='center'>
            {type === DATA.ALERT_TYPE.SUCCESS && <Image
              widthRatio='61'
              widthRatioUnit='px'
              heightRatio='61'
              heightRatioUnit='px'
              src={ICONS['ic-success.svg']}
            />}
            {type === DATA.ALERT_TYPE.ERROR && <Image
              widthRatio='50'
              widthRatioUnit='px'
              heightRatio='50'
              heightRatioUnit='px'
              src={ICONS['ic-warning.svg']}
            />}
            {type === DATA.ALERT_TYPE.SEND && <Image
              widthRatio='58'
              widthRatioUnit='px'
              heightRatio='58'
              heightRatioUnit='px'
              src={ICONS['ic-send.svg']}
            />}
            {logoHeadingTrueBlue && <Image
              widthRatio='122'
              widthRatioUnit='px'
              heightRatio='48'
              heightRatioUnit='px'
              src={LOGOS['logo-light-blue.png']}
            />}
          </Section>
        </Modal.Section>
        <Modal.Section
          paddingBottom={singleButton ? VARIABLES.MODAL.BODY.B_1.PADDING_BOTTOM : '0'}
          paddingHorizontal={VARIABLES.MODAL.BODY.B_1.PADDING_HORIZONTAL}
        >
          {title &&
            <Section
              spacingBottom='12'
              justify='center'
            >
              <Text
                fontStyle={TYPOGRAPHYS.FIRST.BOLD.FS_18}
                color={VARIABLES.COLORS.BLACK}
                align='center'
              >
                {title}
              </Text>
            </Section>
          }

          {description &&
            <Section
              justify='center'
            >
              <Text
                fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_15}
                color={VARIABLES.COLORS.GRAY_6}
                align='center'
              >
                {description}
              </Text>
            </Section>
          }

          {descriptionSecondary}
        </Modal.Section>
        <Modal.Section
          paddingBottom={VARIABLES.MODAL.FOOTER.F_1.PADDING_BOTTOM}
          paddingHorizontal={VARIABLES.MODAL.FOOTER.F_1.PADDING_HORIZONTAL}
          borderBottomRightRadius={VARIABLES.MODAL.FOOTER.F_1.BORDER_BOTTOM_RIGHT_RADIUS}
          borderBottomLeftRadius={VARIABLES.MODAL.FOOTER.F_1.BORDER_BOTTOM_LEFT_RADIUS}
        >
          {singleButton ?
            <Button
              fluid
              shadow
              className='is-gradient-blue'
              height={VARIABLES.BUTTON.HEIGHTS.H_35}
              borderRadius={VARIABLES.BUTTON.BORDER_RADIUSES.BR_10}
              onClick={onClose}
            >
              <Text
                fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_16}
                color={VARIABLES.COLORS.WHITE}
              >
                OK
              </Text>
            </Button>
            :
            <React.Fragment>
              {buttons}
            </React.Fragment>
          }
        </Modal.Section>
      </Modal>
    )
  }

  static Section = ModalBase.Section

  static defaultProps = {
    // * If you set new default props, you should create propsType for correct styleguide document.
    singleButton: true,
  }

  static propTypes = {
    // TYPE > node, string, number, bool, array, object, symbol, func
    /**
    * props_name_oneType
    */
    // props_name_onetype: PropTypes.string,

    /**
    * props_name_oneMoreType
    */
    // props_name_oneMoreType: PropTypes.oneOfType([
    //   PropTypes.node,
    //   PropTypes.string
    // ])
  }
}
