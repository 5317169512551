export class ModelUser {
  constructor(props) {
    props = props || {}
    this.id = props.id;
    this.first_name = props.first_name;
    this.last_name = props.last_name;
    this.email = props.email;
    this.phone = props.phone;
    this.image_url = props.image_url;
    this.created_at = props.created_at;
    this.password = props.password;
    this.type = props.type;
    this.countries = props.countries;
    this.account_type = props.account_type;
    this.uid = props.uid;
    this.mobile_no = props.mobile_no;
    this.post_code = props.post_code;
    this.house_number = props.house_number;
    this.road = props.road;
    this.business_reg_no = props.business_reg_no;
    this.rbp_member_ref_id = props.rbp_member_ref_id;
    this.address = new ModelUserAddress(props.address);
    this.birth_date = props.birth_date;
    this.security_answer_1 = props.security_answer_1;
    this.security_answer_2 = props.security_answer_2;
    this.security_answer_3 = props.security_answer_3;
    this.address_text = props.address_text;
    this.sub_district = props.sub_district;
    this.district = props.district;
    this.province = props.province;
    this.line_id = props.line_id;
    this.sfid = props.sfid;
    this.location_account__c = props.location_account__c;
    this.customer_id = props.customer_id;
    this.nick_name__c = props.nick_name__c;
    this.trained__c = props.trained__c;
    this.trained_tier__c = props.trained_tier__c;
    this.company = props.company;
  }
}


export class ModelUserAddress {
  constructor(props) {
    props = props || {};
    this.address_text = props.address_text;
    this.province = props.province;
    this.province_text = props.province_text;
    this.city = props.city;
    this.city_text = props.city_text;
    this.sub_district = props.sub_district;
    this.address_type = props.address_type;
    this.district = props.district;
    this.alley = props.alley;
    this.group = props.group;
    this.street = props.street;
    this.ward = props.ward;
  }
}
