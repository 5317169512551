import React from 'react'
import {
  TransitionContainer,
  Container,
  Section,
  Button,
  Grid,
  Text,
  Field,
  Image,
  NavigationBar,
} from './../../components'
import {
  VARIABLES,
  TYPOGRAPHYS,
  ICONS,
} from './../../themes'
import {
  ROUTE_PATH,
  redirect,
} from './../../helpers'
import axios from 'axios'

import {
  AlertModal
} from '../AlertModal'
import {
  Loader
} from '../Loader';
import $ from 'jquery';
import config from '../../config'
import { loadImage } from '../../helpers/functions/data'
import { getLanguage } from '../../helpers/functions/language'
import {
  createProjectById,
  projectUpload,
} from '../../helpers/services/projects'

// TODO: adjust style
export class ProjectAddPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      location: '',
      area_size: '',
      product: '',
      description: '',
      showModal: false,
      msg: '',
      topic: '',
      isLoading: false,
      selectedFile: [],
      file: '',
    }
  }

  onChangeField(key, e) {
    if (e && e.target) {
      this.setState({ [key]: e.target.value })
    }
  }

  isValid() {
    const { name, location, msg } = this.state;
    let isValid = '';
    if (!name && location) {
      isValid = msg + 'project name';
    } else if (name && !location) {
      isValid = msg + 'location';
    } else if (!name && !location) {
      isValid = msg + 'project name and location'
    }
    this.setState({ msg: isValid });
    return !isValid;
  }

  async saveProject() {
    const isValid = this.isValid();
    const { name, location, area_size, product, description } = this.state;
    const data = {
      name,
      location,
      area_size,
      product,
      description,
    }
    if (isValid) {
      this.setState({ isLoading: true });
      const id = (this.props.match.params && this.props.match.params.id) ? this.props.match.params.id : '';
      let imageUrl = [];
      if (this.state.selectedFile && this.state.selectedFile.length) {
        for (let i = 0; i < this.state.selectedFile.length; i++) {
          const formdata = new FormData()
          formdata.append('file', this.state.selectedFile[i])
          formdata.append('user_id', id)
          formdata.append('folder', 'projects')
          await projectUpload(formdata, {}).then(res => {
            if (res) {
              imageUrl.push(res.url)
            }
          })
        }
      }

      let params = { ...data, image_url: imageUrl, id: id };
      await createProjectById(params).then(async (res) => {
        if (res) {
          await setTimeout(redirect(`${ROUTE_PATH.MY_PROJECTS.LINK}/${id}`), 2000);
        } else {
          alert(getLanguage('message.somethingWrong'))
        }
      });
      this.setState({ isLoading: false });
    } else {
      this.setState({ showModal: true })
    }
  }

  closeAlert() {
    this.setState({ showModal: false, msg: '' })
  }

  handleBack() {
    const id = (this.props.match.params && this.props.match.params.id) ? this.props.match.params.id : '';
    redirect(`${ROUTE_PATH.MY_PROJECTS.LINK}/${id}`);
  }

  uploadImgHandler() {
    $("input[id='my_file']").click();
  }

  // uploadImgChange(event) {
  //   // console.log('event.target.files[0] ==> ', event.target.files[0]);

  //   let file = [];
  //   // if()
  //   file.push(...this.state.selectedFile);
  //   for (let i of event.target.files){
  //         file.push(i);
  //   };
  //   // this.state.selectedFile.push
  //   this.setState({
  //     selectedFile :file
  //   });

  // }

  async uploadImgChange(event) {
    const {
      selectedFile,
    } = this.state;
    const file = event.target.files[0];
    console.log('file', file);
    const newFile = await loadImage(file);
    selectedFile.push(newFile)
    this.setState({
      selectedFile
    });
  }

  image = {
    onClickDelete: (index) => {
      const {
        selectedFile,
      } = this.state;
      selectedFile.splice(index, 1);
      this.setState({
        selectedFile,
      });
    }
  }


  // u

  render() {
    const rightButton = <Text
      fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_16}
      color={VARIABLES.COLORS.BLACK}
      onClick={this.saveProject.bind(this)}
    >
      {getLanguage('field.buttonSave')}
    </Text>

    return (
      <TransitionContainer
      // motion='overlap-from'
      >

        <Container
          width={VARIABLES.WEBSITE_WIDTH}
          bgColor={VARIABLES.COLORS.WHITE}
          calcMinHeight='100vh'
        >
          <Container
            bgColor={VARIABLES.COLORS.WHITE}
            align='center'
            justify='space-between'
            wrap='no-wrap'
          >
            <NavigationBar
              pageTitle={getLanguage('projectsAdd.title')}
              actionLeft={
                <Image
                  widthRatio='11'
                  widthRatioUnit='px'
                  heightRatio='21'
                  heightRatioUnit='px'
                  src={ICONS['ic-left-black.svg']}
                  onClick={this.handleBack.bind(this)}
                />
              }
              actionRight={rightButton}
            />
            <Section
              paddingHorizontal='20'
              paddingTop='31'
              paddingBottom='20'
            >
              <Section spacingBottom='25' className='is-project-field'>
                <Field
                  fluid
                  placeholder='ex. Kamnottvidya Science Academy'
                  placeholderColor={VARIABLES.COLORS.GRAY_1}
                  bgColor={VARIABLES.COLORS.TRANSPARENT}
                  borderWidth='1'
                  borderStyle='solid'
                  borderColor={VARIABLES.COLORS.GRAY_1}
                  borderRadius='5'
                  fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_15}
                  fontColor={VARIABLES.COLORS.BLACK}
                  onChange={this.onChangeField.bind(this, 'name')}
                  paddingHorizontal={10}
                  paddingTop={15}
                  paddingBottom={10}
                />
                <Section className='is-top-section'>
                  <Text
                    fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_14}
                    color={VARIABLES.COLORS.GRAY_3}
                    className='is-top-label'
                  >
                    {getLanguage('field.projectName')}
                    <Text
                      fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_14}
                      color={VARIABLES.COLORS.ERROR}
                    >
                      *
                    </Text>
                  </Text>
                </Section>
              </Section>
              <Section spacingBottom='25' className='is-project-field'>
                <Field
                  fluid
                  placeholder='ex. Wangchan, Rayong'
                  placeholderColor={VARIABLES.COLORS.GRAY_1}
                  bgColor={VARIABLES.COLORS.TRANSPARENT}
                  borderWidth='1'
                  borderStyle='solid'
                  borderColor={VARIABLES.COLORS.GRAY_1}
                  borderRadius='5'
                  fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_15}
                  fontColor={VARIABLES.COLORS.BLACK}
                  onChange={this.onChangeField.bind(this, 'location')}
                  paddingHorizontal={10}
                  paddingTop={15}
                  paddingBottom={10}
                />
                <Section className='is-top-section'>
                  <Text
                    fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_14}
                    color={VARIABLES.COLORS.GRAY_3}
                    className='is-top-label'
                  >
                    {getLanguage('field.location')}
                    <Text
                      fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_14}
                      color={VARIABLES.COLORS.ERROR}
                    >
                      *
                    </Text>
                  </Text>
                </Section>
              </Section>
              <Section spacingBottom='25' className='is-project-field'>
                <Field
                  fluid
                  placeholder='ex. 2,000 sq.m.'
                  placeholderColor={VARIABLES.COLORS.GRAY_1}
                  bgColor={VARIABLES.COLORS.TRANSPARENT}
                  borderWidth='1'
                  borderStyle='solid'
                  borderColor={VARIABLES.COLORS.GRAY_1}
                  borderRadius='5'
                  fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_15}
                  fontColor={VARIABLES.COLORS.BLACK}
                  onChange={this.onChangeField.bind(this, 'area_size')}
                  paddingHorizontal={10}
                  paddingTop={15}
                  paddingBottom={10}
                />
                <Section className='is-top-section'>
                  <Text
                    fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_14}
                    color={VARIABLES.COLORS.GRAY_3}
                    className='is-top-label'
                  >
                    {getLanguage('field.areaSizeProject')}
                    <Text
                      fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_14}
                      color={VARIABLES.COLORS.ERROR}
                    >
                    </Text>
                  </Text>
                </Section>
              </Section>
              <Section spacingBottom='25' className='is-project-field'>
                <Field
                  fluid
                  placeholder='ex. Zincalume®'
                  placeholderColor={VARIABLES.COLORS.GRAY_1}
                  bgColor={VARIABLES.COLORS.TRANSPARENT}
                  borderWidth='1'
                  borderStyle='solid'
                  borderColor={VARIABLES.COLORS.GRAY_1}
                  borderRadius='5'
                  fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_15}
                  fontColor={VARIABLES.COLORS.BLACK}
                  onChange={this.onChangeField.bind(this, 'product')}
                  paddingHorizontal={10}
                  paddingTop={15}
                  paddingBottom={10}
                />
                <Section className='is-top-section'>
                  <Text
                    fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_14}
                    color={VARIABLES.COLORS.GRAY_3}
                    className='is-top-label'
                  >
                    {getLanguage('field.product')}
                  </Text>
                </Section>
              </Section>
              <Section spacingBottom='30' className='is-project-field'>
                <Field
                  fluid
                  type='textarea'
                  placeholder='ex. Kamnoetvidya Science Academy is a leading
                  science high school with a specific purpose of
                  nurturing gifted and talented students in
                  mathematics and science in grades 10 to 12
                  who will make a significant contribution to
                  Thailand’s science and technology development.'
                  placeholderColor={VARIABLES.COLORS.GRAY_1}
                  height='130'
                  bgColor={VARIABLES.COLORS.TRANSPARENT}
                  borderWidth='1'
                  borderStyle='solid'
                  borderColor={VARIABLES.COLORS.GRAY_1}
                  borderRadius='5'
                  fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_15}
                  fontColor={VARIABLES.COLORS.BLACK}
                  onChange={this.onChangeField.bind(this, 'description')}
                  paddingHorizontal={10}
                  paddingTop={15}
                  paddingBottom={10}
                />
                <Section className='is-top-section'>
                  <Text
                    fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_14}
                    color={VARIABLES.COLORS.GRAY_3}
                    className='is-top-label'
                  >
                    {getLanguage('field.description')}
                  </Text>
                </Section>
              </Section>
              <Section
                spacingBottom='35'
                justify='center'
              >
                <Grid
                  gutter='3'
                >
                  {this.state.selectedFile.map((f, index) => (
                    <Grid.Column key={index} grid={4} className='is-project-img'>
                      <Section
                        paddingLeft='10'
                        paddingBottom='10'
                      >
                        <Image
                          widthRatio='100'
                          widthRatioUnit='%'
                          heightRatio='100'
                          heightRatioUnit='%'
                          src={`${URL.createObjectURL(f)}`}
                          borderRadius={5}
                        />
                      </Section>
                      <Section className='is-project-delete-img'>
                        <Button
                          fluid
                          className='is-icon'
                          width={23}
                          height={23}
                          borderRadius={30}
                          bgColor={'#FA295C'}
                          onClick={() => {
                            this.image.onClickDelete(index)
                          }}
                        >
                          <Button.Icon>
                            <Image
                              top='-1'
                              widthRatio='10'
                              widthRatioUnit='px'
                              heightRatio='10'
                              heightRatioUnit='px'
                              src={ICONS['ic-close.png']}
                            />
                          </Button.Icon>
                        </Button>
                      </Section>
                    </Grid.Column>
                  ))}
                </Grid>
              </Section>
              <Section
                justify='center'
              >
                <Section
                  width='275'
                  paddingBottom='15'
                  marginHorizontal='auto'
                  justify='center'
                >
                  <Text
                    fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_11}
                    color={VARIABLES.COLORS.GRAY_3}
                    align='center'
                  >
                    {getLanguage('field.hintButtonAddPhotoLineOne')}
                    <br />
                    {getLanguage('field.hintButtonAddPhotoLineTwo')}
                  </Text>
                </Section>
                <Button
                  shadow
                  className='is-gradient-blue is-icon-front'
                  width='275'
                  height={VARIABLES.BUTTON.HEIGHTS.H_40}
                  borderRadius={VARIABLES.BUTTON.BORDER_RADIUSES.BR_10}
                  onClick={this.uploadImgHandler.bind(this)}
                //TODO: Added Photo for new project
                >
                  <Button.Icon>
                    <Image
                      widthRatio='16'
                      widthRatioUnit='px'
                      heightRatio='16'
                      heightRatioUnit='px'
                      top='-1'
                      src={ICONS['ic-image.png']}
                    />
                  </Button.Icon>
                  <input ref={e => { this.ref = e; }} style={{ display: 'none' }} type="file" id="my_file" multiple onChange={this.uploadImgChange.bind(this)} />
                  <Text
                    fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_16}
                    color={VARIABLES.COLORS.WHITE}
                  >
                    {getLanguage('field.buttonAddPhoto')}
                  </Text>
                </Button>
              </Section>
            </Section>
          </Container>
        </Container>
        {this.state.showModal ? <AlertModal topic={'Error'} msg={'Please specify ' + this.state.msg} closeModal={this.closeAlert.bind(this)} /> : ''}
        {<Loader loading={this.state.isLoading} isOverlay={true} />}
      </TransitionContainer>
    )
  }
}
