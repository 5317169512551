import {
  injectGlobal
} from 'styled-components'
import {
  default as VARIABLES
} from './../../../themes/styles/bases/variables'
// import {
//   default as TYPOGRAPHYS
// } from './../../../themes/styles/bases/typographys'
// import {
//   default as MIXINS
// } from './../../../themes/styles/helpers/mixins'

// Sample using image in style
// url(${require('./../../../themes/images/icons/icon-sample.svg')});

injectGlobal`
  .grid-base {
    /* Parent
    ------------------------------- */
    /* Childrens
    ------------------------------- */
    .grid-column-base {
      &.is-relative {
        position: relative;
      }
    }

    /* Modifiers
    ------------------------------- */
    .is-project-img {
      position: relative;
      .is-project-delete-img {
        position: absolute;
        z-index: ${VARIABLES.Z_INDEXS.LV_1};
        top: 4px;
        right: 4px;
        width: auto;
      }
    }

    /* Media queries
    ------------------------------- */
  }
`
