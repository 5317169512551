import React from 'react'
import {
  TransitionContainer,
  Container,
  Section,
  Image,
  Button,
  Text,
  Field,
  Modal,
  NavigationBar
} from './../../components'
import {
  VARIABLES,
  TYPOGRAPHYS,
  ICONS,
} from './../../themes'
import {
  ROUTE_PATH,
  redirect,
} from './../../helpers'
import {
  AlertModal
} from '../AlertModal'
import {
  Loader
} from '../Loader';
import { LOCAL_STORAGE } from '../../helpers/constants/localStorage';

import axios from 'axios';
import { getLanguage } from '../../helpers/functions/language';
import config from '../../config';
import sha1 from 'sha1';
import schemaConfig from '../../config/schema_config';
import { onOpenAlert } from '../../helpers/functions/main'
import { DATA } from '../../helpers/constants/data'

export class ChangePasswordPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      showOldPassword: false,
      showPassword: false,
      showConfirmPassword: false,
      showModalSuccess: false,
      userId: JSON.parse(localStorage.getItem(LOCAL_STORAGE.REGISTER_INFO)) ? JSON.parse(localStorage.getItem(LOCAL_STORAGE.REGISTER_INFO)).id : '',
      // TODO: get data from api
      data: {
        password: '',
      },
      oldPassword: '',
      newPassword: '',
      confirmPassword: '',
      showModal: false,
      msg: '',
      topic: '',
      isLoading: false,
    }
  }

  onChangeField(key, event) {
    this.setState({ [key]: event.target.value });
  }

  onTogglePassword(key) {
    this.setState({ [key]: !this.state[key] });
  }

  componentWillMount() {
    axios.post(`${config.api.base}/api/users/id`, {
      id: this.state.userId
    }).then(async (response) => {
      const password = { password: response.data.data.password };
      this.setState({ data: password })
    }, (error) => {
      console.log(error);
      onOpenAlert({
        type: DATA.ALERT_TYPE.ERROR,
        title: getLanguage('message.fail'),
        message: (error || {}).message,
      });
    });
  }

  // copy from register page
  // TODO: move to utils or something
  validateData() {
    const { data, oldPassword, newPassword, confirmPassword } = this.state;
    const oldPasswordHash = sha1(oldPassword + schemaConfig.SALT);
    if (data.password !== oldPasswordHash || newPassword !== confirmPassword) {
      this.setState({
        topic: getLanguage('modal.changePassword.fail'),
        msg: getLanguage('modal.changePassword.passwordNotMatch'),
        showModal: true,
      })
      return { valid: false }
    }

    if (newPassword.length < 8 || newPassword.length > 24 || confirmPassword.length < 8 || confirmPassword.length > 24) {
      this.setState({
        topic: getLanguage('modal.changePassword.fail'),
        msg: getLanguage('modal.changePassword.passwordIncorrectFormat'),
        showModal: true,
      });
      return { valid: false }
    };

    return { valid: true }
  }

  onSubmit() {
    if (this.validateData().valid) {
      axios.put(`${config.api.base}/api/users/password`, {
        id: this.state.userId,
        password: this.state.newPassword
      }).then(async (response) => {
        this.setState({
          topic: getLanguage('modal.changePassword.changeSuccess'),
          msg: getLanguage('modal.changePassword.nextLogin'),
          showModalSuccess: true,
        });
      }, (error) => {
        console.log(error);
        onOpenAlert({
          type: DATA.ALERT_TYPE.ERROR,
          title: getLanguage('message.fail'),
          message: (error || {}).message,
        });
      });
    }
  }

  closeAlert() {
    this.setState({
      showModal: false
    })
  }

  modal = {
    onClickOpenModalSuccess: () => {
      this.setState({
        showModalSuccess: true
      })
    },

    onClickCloseModalSuccess: () => {
      redirect(ROUTE_PATH.MY_PROFILE.LINK)
      // this.setState({
      //   showModalSuccess: false
      // })
    }
  }

  render() {
    const {
      showOldPassword,
      showPassword,
      showConfirmPassword,
      showModalSuccess,
    } = this.state
    return (
      <TransitionContainer
      // motion='overlap-from'
      >
        <Container
          width={VARIABLES.WEBSITE_WIDTH}
          height='100'
          heightUnit='vh'
          bgColor={VARIABLES.COLORS.WHITE}
        >
          <NavigationBar
            shadow
            pageTitle={getLanguage('changePassword.title')}
            actionLeft={
              <Image
                widthRatio='11'
                widthRatioUnit='px'
                heightRatio='21'
                heightRatioUnit='px'
                src={ICONS['ic-left-black.svg']}
                onClick={() => {
                  redirect(ROUTE_PATH.MY_PROFILE.LINK)
                }}
              />
            }
          />
          <Section
            scroll
            justify='center'
            calcHeight='100vh - 70px'
          >
            <Section
              width='275'
              paddingTop='53'
            >
              <Section spacingBottom='30' className='is-register-floating-label'>
                <Field
                  fluid
                  className='is-password is-register'
                  inputTextType={`${showOldPassword ? 'text' : 'password'}`}
                  height='28'
                  paddingBottom='10'
                  bgColor={VARIABLES.COLORS.TRANSPARENT}
                  borderBottomWidth='1'
                  borderBottomStyle='solid'
                  borderBottomColor={VARIABLES.COLORS.GRAY_3}
                  fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_15}
                  fontColor={VARIABLES.COLORS.BLACK}
                  onChange={this.onChangeField.bind(this, 'oldPassword')}
                />
                <Text
                  className={'is-floating-label'}
                  fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_15}
                  color={VARIABLES.COLORS.GRAY_2}
                >
                  {getLanguage('field.oldPassword')}
                </Text>
                <Image
                  className='is-icon-password'
                  widthRatio='18'
                  widthRatioUnit='px'
                  heightRatio='18'
                  heightRatioUnit='px'
                  src={(showOldPassword) ? ICONS['ic-eye-password-close.svg'] : ICONS['ic-eye-password.svg']}
                  onClick={this.onTogglePassword.bind(this, 'showOldPassword')}
                />
              </Section>
              <Section spacingBottom='10' className='is-register-floating-label'>
                <Field
                  fluid
                  className='is-password is-register'
                  inputTextType={`${showPassword ? 'text' : 'password'}`}
                  height='28'
                  paddingBottom='10'
                  bgColor={VARIABLES.COLORS.TRANSPARENT}
                  borderBottomWidth='1'
                  borderBottomStyle='solid'
                  borderBottomColor={VARIABLES.COLORS.GRAY_3}
                  fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_15}
                  fontColor={VARIABLES.COLORS.BLACK}
                  onChange={this.onChangeField.bind(this, 'newPassword')}
                />
                <Text
                  className={'is-floating-label'}
                  fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_15}
                  color={VARIABLES.COLORS.GRAY_2}
                >
                  {getLanguage('field.newPassword')}
                </Text>
                <Image
                  className='is-icon-password'
                  widthRatio='18'
                  widthRatioUnit='px'
                  heightRatio='18'
                  heightRatioUnit='px'
                  src={(showPassword) ? ICONS['ic-eye-password-close.svg'] : ICONS['ic-eye-password.svg']}
                  onClick={this.onTogglePassword.bind(this, 'showPassword')}
                />
              </Section>
              <Section spacingBottom='13'>
                <Text
                  fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_14}
                  color={VARIABLES.COLORS.GRAY_4}
                  className='is-password-note'
                >
                  {getLanguage('field.newPasswordHint')}
                </Text>
              </Section>
              <Section spacingBottom='40' className='is-register-floating-label'>
                <Field
                  fluid
                  className='is-password'
                  inputTextType={`${showConfirmPassword ? 'text' : 'password'}`}
                  height='28'
                  paddingBottom='10'
                  bgColor={VARIABLES.COLORS.TRANSPARENT}
                  borderBottomWidth='1'
                  borderBottomStyle='solid'
                  borderBottomColor={VARIABLES.COLORS.GRAY_3}
                  fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_15}
                  fontColor={VARIABLES.COLORS.BLACK}
                  onChange={this.onChangeField.bind(this, 'confirmPassword')}
                />
                <Text
                  className='is-floating-label'
                  fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_15}
                  color={VARIABLES.COLORS.GRAY_2}
                >
                  {getLanguage('field.confirmPassword')}
                </Text>
                <Image
                  className='is-icon-password'
                  widthRatio='18'
                  widthRatioUnit='px'
                  heightRatio='18'
                  heightRatioUnit='px'
                  src={(showConfirmPassword) ? ICONS['ic-eye-password-close.svg'] : ICONS['ic-eye-password.svg']}
                  onClick={this.onTogglePassword.bind(this, 'showConfirmPassword')}
                />
              </Section>
              <Section spacingBottom='40'>
                <Button
                  fluid
                  shadow
                  className='is-gradient-blue'
                  height={VARIABLES.BUTTON.HEIGHTS.H_40}
                  borderRadius={VARIABLES.BUTTON.BORDER_RADIUSES.BR_10}
                  //  onClick={this.modal.onClickOpenModalSuccess}
                  onClick={this.onSubmit.bind(this)}
                >
                  <Text
                    fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_16}
                    color={VARIABLES.COLORS.WHITE}
                  >
                    {getLanguage('field.buttonSaveAndChange')}
                  </Text>
                </Button>
              </Section>
            </Section>
          </Section>
        </Container>

        <Modal
          open={showModalSuccess}
          width={VARIABLES.MODAL.WIDTHS.W_300}
          overlayColor={VARIABLES.COLORS.OVERLAY_3}
          spacing={VARIABLES.MODAL.CONTAINER.C_1.SPACING}
          transition={VARIABLES.TRANSITIONS.DEFAULT}
          transformStart={VARIABLES.MODAL.CONTAINER.C_1.TRANSFORM_START}
          transformEnd={VARIABLES.MODAL.CONTAINER.C_1.TRANSFORM_END}
        // onClickClose={this.modal.onClickCloseModalSuccess}
        >
          <Modal.Section
            paddingTop={VARIABLES.MODAL.HEADER.H_1.PADDING_TOP}
            paddingBottom={VARIABLES.MODAL.HEADER.H_1.PADDING_BOTTOM}
            paddingHorizontal={VARIABLES.MODAL.HEADER.H_1.PADDING_HORIZONTAL}
            borderTopRightRadius={VARIABLES.MODAL.HEADER.H_1.BORDER_TOP_RIGHT_RADIUS}
            borderTopLeftRadius={VARIABLES.MODAL.HEADER.H_1.BORDER_TOP_LEFT_RADIUS}
          >
            <Section justify='center'>
              <Image
                widthRatio='61'
                widthRatioUnit='px'
                heightRatio='61'
                heightRatioUnit='px'
                src={ICONS['ic-success.svg']}
              />
            </Section>
          </Modal.Section>
          <Modal.Section
            paddingBottom={VARIABLES.MODAL.BODY.B_1.PADDING_BOTTOM}
            paddingHorizontal={VARIABLES.MODAL.BODY.B_1.PADDING_HORIZONTAL}
          >
            <Section
              spacingBottom='12'
              justify='center'
            >
              <Text
                fontStyle={TYPOGRAPHYS.FIRST.BOLD.FS_18}
                color={VARIABLES.COLORS.BLACK}
              >
                {getLanguage('modal.changePassword.title')}
              </Text>
            </Section>
            <Section
              justify='center'
            >
              <Text
                fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_15}
                color={VARIABLES.COLORS.GRAY_6}
                align='center'
              >
                {getLanguage('modal.changePassword.message')}
              </Text>
            </Section>
          </Modal.Section>
          <Modal.Section
            paddingBottom={VARIABLES.MODAL.FOOTER.F_1.PADDING_BOTTOM}
            paddingHorizontal={VARIABLES.MODAL.FOOTER.F_1.PADDING_HORIZONTAL}
            borderBottomRightRadius={VARIABLES.MODAL.FOOTER.F_1.BORDER_BOTTOM_RIGHT_RADIUS}
            borderBottomLeftRadius={VARIABLES.MODAL.FOOTER.F_1.BORDER_BOTTOM_LEFT_RADIUS}
          >
            <Button
              fluid
              shadow
              className='is-gradient-blue'
              height={VARIABLES.BUTTON.HEIGHTS.H_35}
              borderRadius={VARIABLES.BUTTON.BORDER_RADIUSES.BR_10}
              onClick={this.modal.onClickCloseModalSuccess}
            >
              <Text
                fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_16}
                color={VARIABLES.COLORS.WHITE}
              >
                {getLanguage('field.buttonOK')}
              </Text>
            </Button>
          </Modal.Section>
        </Modal>
        {this.state.showModal ? <AlertModal topic={this.state.topic} msg={this.state.msg} closeModal={this.closeAlert.bind(this)} /> : ''}
        {<Loader loading={this.state.isLoading} isOverlay={true} />}
      </TransitionContainer>
    )
  }
}
