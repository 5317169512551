import React, { Fragment } from 'react'
import _ from 'lodash'
import {
  Section,
  Image,
  Grid,
  Button,
  Text,
  Alert,
} from './../../components'
import {
  VARIABLES,
  TYPOGRAPHYS,
  ICONS
} from './../../themes'
import {
  ROUTE_PATH,
  redirect,
  formatDatetime,
} from './../../helpers'
import { getLanguage } from '../../helpers/functions/language'
import config from '../../config'
import { displayWithComma } from '../../helpers/functions/display'

export class WarrantyProductItem extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      showModal: false,
      titleModal: '',
      descriptionModal: '',
      typeModal: '',
    }
  }

  onShareLink(event) {
    const { data } = this.props;
    event.stopPropagation();
    const sharePath = `${config.imgPrefix}/${data.wrt_path}`;
    console.log('sharePath', sharePath);
    if (navigator.share) {
      navigator.share({
        // url: window.location.href
        url: sharePath
      })
    } else {
      this.setState({
        showModal: true,
        typeModal: 'error',
        titleModal: '',
        descriptionModal: getLanguage('message.browserCantShare'),
      })
    }
  }

  onViewFile(event) {
    event.stopPropagation()
    const { data, id } = this.props;
    let urlPath = data.wrt_path;
    if (urlPath.indexOf(config.imgPrefix) === -1) {
      urlPath = `${config.imgPrefix}/${urlPath}`;
    }
    window.open(urlPath,'_blank');
    // redirect(`${ROUTE_PATH.WARRANTY_PRODUCT.LINK}/${id}/file/${data.order_no}`)
  }

  onCloseModal(event) {
    event.stopPropagation()
    this.setState({ showModal: false })
  }

  render() {
    const { data, openDetail, onOpenDetail, index } = this.props
    const { showModal, titleModal, descriptionModal, typeModal } = this.state
    return (
      <Section
        className='is-shadow'
        spacingBottom='14'
        bgColor={VARIABLES.COLORS.WHITE}
        paddingTop='20'
        paddingBottom='11'
        paddingLeft='18'
        paddingRight='20'
        onClick={onOpenDetail.bind(this, index)}
      >
        <Grid gutter='20'>
          <Grid.Column flex='none'>
            <Image
              widthRatio='30'
              widthRatioUnit='px'
              heightRatio='34'
              heightRatioUnit='px'
              src={ICONS['ic-warranty-blue.svg']}
            />
          </Grid.Column>
          <Grid.Column flex='1'>
            <Section>
              <Text
                fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_16}
              >
                {_.get(data, 'coil_name')}
              </Text>
            </Section>
            <Section spacingBottom='5'>
              <Grid gutter='7'>
                <Grid.Column>
                  <Image
                    widthRatio='12'
                    widthRatioUnit='px'
                    heightRatio='13'
                    heightRatioUnit='px'
                    top='-2'
                    src={ICONS['ic-date-blue.svg']}
                  />
                </Grid.Column>
                <Grid.Column>
                  <Text
                    fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_12}
                    color={VARIABLES.COLORS.BLUE_2}
                  >
                    {getLanguage('warrantyProductItem.issuedDate')} : {formatDatetime(data.order_date, 'DD MMM, YYYY')}
                  </Text>
                </Grid.Column>
              </Grid>
            </Section>
            <Section spacingLeft='20'>
              <Text
                fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_12}
                color={VARIABLES.COLORS.GRAY_3}
              >
                {getLanguage('warrantyProductItem.product')} : {_.get(data, 'coil_grp', '-')}
              </Text>
            </Section>
            {openDetail && <Fragment>
              <Section spacingLeft='20' spacingTop='7'>
                <Text
                  fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_12}
                  color={VARIABLES.COLORS.GRAY_3}
                >
                  {/* Thickness : 0.35 mm */}
                  {getLanguage('warrantyProductItem.thickness')} : {data.thickness ? `${data.thickness} mm` : '-'}
                </Text>
              </Section>
              <Section spacingLeft='20' spacingTop='7'>
                <Text
                  fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_12}
                  color={VARIABLES.COLORS.GRAY_3}
                >
                  {/* Length : 250 meters */}
                  {getLanguage('warrantyProductItem.length')} : {data.length ? `${displayWithComma(data.length)} meters` : '-'}
                </Text>
              </Section>
              <Section spacingLeft='20' spacingTop='7'>
                <Text
                  fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_12}
                  color={VARIABLES.COLORS.GRAY_3}
                >
                  {getLanguage('warrantyProductItem.warrantyID')} : {_.get(data, 'wrt_no', '')}
                </Text>
              </Section>
            </Fragment>}
          </Grid.Column>
          <Grid.Column flex='none'>
            <Section
              width='48'
              height='100'
              heightUnit='%'
              justify='flex-end'
              align='start'
            >
              <Image
                widthRatio='18'
                widthRatioUnit='px'
                heightRatio='18'
                heightRatioUnit='px'
                src={`${openDetail ? ICONS['ic-arrow-accordion-open-blue.svg'] : ICONS['ic-arrow-accordion-close.svg']}`}
              />
            </Section>
          </Grid.Column>
        </Grid>
        <Grid align='center'>
          <Grid.Column flex='none'>
            <Section width='28' />
          </Grid.Column>
          <Grid.Column flex='1'>
            <Section
              spacingVertical='14'
              borderTopWidth='1'
              borderTopStyle='solid'
              borderTopColor={VARIABLES.COLORS.GRAY_5}
            />
          </Grid.Column>
        </Grid>
        <Grid gutter='7' align='center' justify='center'>
          {/* <Grid.Column>
            <Button
              className='is-icon is-circle is-shadow'
              width='33'
              height='33'
              borderRadius='20'
              bgColor={VARIABLES.COLORS.BLUE_2}
              onClick={this.onShareLink.bind(this)}
            >
              <Button.Icon>
                <Image
                  top='-1'
                  widthRatio='15.5'
                  widthRatioUnit='px'
                  heightRatio='17'
                  heightRatioUnit='px'
                  src={ICONS['ic-share.svg']}
                />
              </Button.Icon>
            </Button>
          </Grid.Column> */}
          <Grid.Column>
            <Button
              className='is-icon is-circle is-shadow'
              width='33'
              height='33'
              borderRadius='20'
              bgColor={VARIABLES.COLORS.BLUE_6}
              onClick={this.onViewFile.bind(this)}
            >
              <Button.Icon>
                <Image
                  top='-1'
                  widthRatio='15'
                  widthRatioUnit='px'
                  heightRatio='17'
                  heightRatioUnit='px'
                  src={ICONS['ic-file.svg']}
                />
              </Button.Icon>
            </Button>
          </Grid.Column>
        </Grid>
        <Alert type={typeModal} title={titleModal} description={descriptionModal} onClose={this.onCloseModal.bind(this)} open={showModal} />
      </Section>
    )
  }
}

