import React from 'react'
import {
  connect,
} from 'react-redux'
import {
  Redirect,
  Route,
  Switch,
} from 'react-router-dom'
import {
  ToastContainer,
  cssTransition
} from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import {
  CSSTransition,
  TransitionGroup
} from 'react-transition-group'
import { ENUM } from '../../helpers/constants/enum'
import { handleWatchNoti } from '../../helpers/firebases/firebaseNotification'
import { handleWatchVersion } from '../../helpers/firebases/firebaseVersoin'
import { getCurrentCountryLanguage } from '../../helpers/functions/addon'
import { getAuthSuccess, getLastOTPSuccessDay, getUserAuth, handleValidateAuth, logout } from '../../helpers/functions/auth'
import { getCountryDataList, setCountryDataList } from '../../helpers/functions/data'
import { addGA } from '../../helpers/functions/ga'
import { changeLanguage, getLanguageLocalStorage } from '../../helpers/functions/language'
import { LineLiffPage } from '../../pages/LineLiffPage'
import { AlertModalContainerConnected } from '../AlertModalContainer/AlertModalContainer'
import { AlertVersionContainerConnected } from '../AlertVersionContainer'
import { LoaderContainerConnected } from '../LoaderContainer'
import { ModalNoticeContainerConnected } from '../ModalContainer/ModalNoticeContainer'
import {
  setRouteHistory,
} from './../../actions'
import {
  ROUTE_PATH, checkDomain, isValidResponse
} from './../../helpers'
import {
  AuthPage,
  AuthenticationSuccessPage,
  AwardAnnouncementPage,
  ChangePasswordPage,
  EstimatorPage,
  EstimatorSummaryPage,
  FindDealerDetailCommentPage,
  FindDealerDetailWriteCommentPage,
  FindDealerListPage,
  FindDealerPage,
  FindDealerSearchByAreaPage,
  FindDealerSearchByNamePage,
  FindDealerSearchResultPage,
  FindDealerTypePage,
  ForgetPasswordMethodPage,
  ForgotPasswordVerifyWithPhoneNumberPage,
  HomePage,
  KnowledgeBaseDetailPage,
  KnowledgeBasePage,
  KnowledgeBaseTopicPage,
  LineOaValidationPage,
  LoginByLinePhonePage,
  LoginByPhonePage,
  LoyaltyPointPage,
  MainLayout,
  MyProfilePage,
  MyProjectsPage,
  NewsPromotionsCommentPage,
  NewsPromotionsDetailPage,
  NewsPromotionsPage,
  NewsPromotionsSearchPage,
  NewsPromotionsWriteCommentPage,
  OrderInquiryAddPage,
  OrderInquiryDetailPage,
  OrderInquiryNotificationPage,
  OrderInquiryPage,
  OrderInquirySearchPage,
  ProductCatPage,
  ProductDetailBrochurePage,
  ProductPage,
  ProjectAddPage,
  ProjectDetailPage,
  ProjectEditPage,
  ProjectSearchPage,
  PublicKnowledgeBasePage,
  PublicMyProjectPage,
  PublicNewsAndPromotionsCommentPage,
  PublicNewsAndPromotionsPage,
  PublicNewsAndPromotionsWriteCommentPage,
  PublicProductPage,
  PurchaseHistoryDetailPage,
  PurchaseHistoryPage,
  PurchaseHistorySearchPage,
  ReferenceCodePage,
  ReferralAdPage,
  RegisterPage,
  RegisterSuccessPage,
  ResetPasswordPage,
  // RewardPage,
  RewardCatPage,
  RewardDetailPage,
  RewardSearchPage,
  SettingLanguagePage,
  SettingNotificationPage,
  SettingPage,
  SplashScreenPage,
  TermPolicyPage,
  TermsConditionsPage,
  VerifyWithQuestionPage,
  VoucherOutOfStockPage,
  WarrantyPage,
  WarrantyProductPage,
  WarrantySearchByDatePage,
  WarrantySearchByNamePage,
  WarrantySearchResultPage,
  WelcomePage,
  WinPrizesPage
} from './../../pages'
import { userController } from '../../apiService/apiController/userService'
import { setLocalStorage } from '../../helpers/functions/localStorage'
import { LOCAL_STORAGE } from '../../helpers/constants/localStorage'

export class RouteApp extends React.Component {

  constructor(props) {
    super(props);
    props.setRouteHistory(props.history);


    const isOtpAuthSuccess = getAuthSuccess();
    const userAuth = isOtpAuthSuccess ? getUserAuth() : undefined;

    const language = getLanguageLocalStorage();
    if (language) {
      changeLanguage(language);
    }

    // set default country for thai
    const countryDataList = getCountryDataList();
    if (countryDataList.length === 0) {
      setCountryDataList(ENUM.COUNTRY.THAI);
    }

    handleWatchNoti();
    handleWatchVersion();

    console.log('userAuth', userAuth)

    if (userAuth) {
      setCountryDataList(userAuth.countries);
      // for check log
      getLastOTPSuccessDay(userAuth.phone);
    }
    this.state = {
      isRefresh: false,
      userAuth
    }
  }

  componentDidMount() {
    const language = getLanguageLocalStorage();
    if (!language) {
      getCurrentCountryLanguage().then(country => {
        changeLanguage(country);
        this.route.handleRefresh();
      });
    }
    this.route.validateUserAuth()
  }

  route = {
    handleRefresh: () => {
      this.setState({
        isRefresh: true,
      }, () => {
        this.setState({
          isRefresh: false
        })
      })
    },
    validateUserAuth: async () => {
      const {
        userAuth,
      } = this.state;
      if (userAuth) {
        const params = {
          phone: userAuth.phone
        }
        const configService = {
          isShowError: false,
        }
        const userService = userController(configService);
        const res = await userService.getValidateUser(params);
        if (isValidResponse(res)) {
        }
        else {
          setLocalStorage(LOCAL_STORAGE.IS_TOAST_INCORRECT_MEMBER_TYPE, true);
          logout();
        }
      }
    }
  }



  render() {
    const {
      location,
      authApp
    } = this.props
    const {
      isRefresh,
      userAuth,
    } = this.state;
    checkDomain();

    if (!!userAuth) {
      handleValidateAuth();
    }

    const toastAnimation = cssTransition({
      enter: 'slideDownFadeIn',
      exit: 'slideDownFadeOut',
      duration: 300
    });

    return isRefresh ?
      <div />
      :
      (authApp.isAuthRedirect ?
        <React.Fragment>
          <MainLayout>
            <TransitionGroup className='transition-group'>
              <CSSTransition classNames='page'
                key={location.key}
                timeout={{
                  // Consistent with CSS transition duration in TransitionContainer style
                  // File path: src/components/TransitionContainer/styled.js
                  // 0.5s = 500
                  enter: 500,
                  exit: 500
                }}
              >
                <Switch location={location}>
                  <Route exact path={ROUTE_PATH.PUBLIC_NEWS_PROMOTIONS.LINK_WITH_PARAMS} component={(props) => { addGA(); return <PublicNewsAndPromotionsPage {...props} /> }} />
                  <Route exact path={ROUTE_PATH.PUBLIC_NEWS_PROMOTIONS_COMMENT.LINK} component={(props) => { addGA(); return <PublicNewsAndPromotionsCommentPage {...props} /> }} />
                  <Route exact path={ROUTE_PATH.PUBLIC_NEWS_PROMOTIONS_WRITE_COMMENT.LINK} component={(props) => { addGA(); return <PublicNewsAndPromotionsWriteCommentPage {...props} /> }} />
                  <Route exact path={ROUTE_PATH.PUBLIC_MY_PROJECT.LINK_WITH_PARAMS} component={(props) => { addGA(); return <PublicMyProjectPage {...props} /> }} />
                  <Route exact path={ROUTE_PATH.PUBLIC_PRODUCT.LINK_WITH_PARAMS} component={(props) => { addGA(); return <PublicProductPage {...props} /> }} />
                  <Route exact path={ROUTE_PATH.PUBLIC_KNOWLEDGE_BASE.LINK_WITH_PARAMS} component={(props) => { addGA(); return <PublicKnowledgeBasePage {...props} /> }} />
                  <Route exact path={ROUTE_PATH.REFERRAL_AD.LINK_WITH_PARAMS} component={(props) => { addGA(); return <ReferralAdPage {...props} /> }} />
                  <Route exact path={ROUTE_PATH.REFERENCE_CODE.LINK_WITH_PARAMS} component={(props) => { addGA(); return <ReferenceCodePage {...props} /> }} />
                  <Route exact path={ROUTE_PATH.AUTH.LINK} component={(props) => { addGA(); return <AuthPage {...props} /> }} />
                  <Route exact path={ROUTE_PATH.AUTH_SUCCESS.LINK} component={(props) => { addGA(); return <AuthenticationSuccessPage {...props} /> }} />
                  {!!userAuth ?
                    <Switch location={location}>
                      {/* Use props 'exact' for match single container(not share container) */}
                      <Route exact path={ROUTE_PATH.SPLASH_SCREEN.LINK} component={(props) => { addGA(); return <SplashScreenPage {...props} /> }} />
                      <Route exact path={ROUTE_PATH.HOME.LINK} component={(props) => { addGA(); return <HomePage {...props} /> }} />
                      <Route exact path={ROUTE_PATH.CHANGE_PASSWORD.LINK} component={(props) => { addGA(); return <ChangePasswordPage {...props} /> }} />
                      <Route exact path={ROUTE_PATH.TERM_POLICY.LINK} component={(props) => { addGA(); return <TermPolicyPage {...props} /> }} />
                      <Route exact path={`${ROUTE_PATH.AWARD_ANNOUNCEMENT.LINK}/:id`} component={(props) => { addGA(); return <AwardAnnouncementPage {...props} /> }} />
                      <Route exact path={ROUTE_PATH.WIN_PRIZES.LINK} component={(props) => { addGA(); return <WinPrizesPage {...props} /> }} />
                      <Route exact path={ROUTE_PATH.NEWS_PROMOTIONS.LINK} component={(props) => { addGA(); return <NewsPromotionsPage {...props} /> }} />
                      <Route exact path={ROUTE_PATH.NEWS_PROMOTIONS_DETAIL.LINK_WITH_PARAMS} component={(props) => { addGA(); return <NewsPromotionsDetailPage {...props} /> }} />
                      <Route exact path={ROUTE_PATH.NEWS_PROMOTIONS_COMMENT.LINK_WITH_PARAMS} component={(props) => { addGA(); return <NewsPromotionsCommentPage {...props} /> }} />
                      <Route exact path={ROUTE_PATH.NEWS_PROMOTIONS_WRITE_COMMENT.LINK_WITH_PARAMS} component={(props) => { addGA(); return <NewsPromotionsWriteCommentPage {...props} /> }} />
                      <Route exact path={ROUTE_PATH.NEWS_PROMOTIONS_SEARCH.LINK} component={(props) => { addGA(); return <NewsPromotionsSearchPage {...props} /> }} />
                      <Route exact path={ROUTE_PATH.MY_PROFILE.LINK} component={(props) => { addGA(); return <MyProfilePage {...props} /> }} />
                      <Route exact path={ROUTE_PATH.MY_PROJECTS.LINK_WITH_PARAMS} component={(props) => { addGA(); return <MyProjectsPage {...props} /> }} />
                      <Route exact path={ROUTE_PATH.PROJECT_DETAIL.LINK_WITH_PARAMS} component={(props) => { addGA(); return <ProjectDetailPage {...props} /> }} />
                      <Route exact path={ROUTE_PATH.PROJECT_EDIT.LINK_WITH_PARAMS} component={(props) => { addGA(); return <ProjectEditPage {...props} /> }} />
                      <Route exact path={ROUTE_PATH.PROJECT_ADD.LINK_WITH_PARAMS} component={(props) => { addGA(); return <ProjectAddPage {...props} /> }} />
                      <Route exact path={ROUTE_PATH.PROJECT_SEARCH.LINK_WITH_PARAMS} component={(props) => { addGA(); return <ProjectSearchPage {...props} /> }} />
                      <Route exact path={ROUTE_PATH.SETTING.LINK} component={(props) => { addGA(); return <SettingPage {...props} /> }} />
                      <Route exact path={ROUTE_PATH.SETTING_NOTIFICATION.LINK} component={(props) => { addGA(); return <SettingNotificationPage {...props} /> }} />
                      <Route exact path={ROUTE_PATH.SETTING_LANGUAGE.LINK} component={(props) => { addGA(); return <SettingLanguagePage {...props} /> }} />
                      <Route exact path={ROUTE_PATH.FIND_DEALER.LINK} component={(props) => { addGA(); return <FindDealerPage {...props} /> }} />
                      <Route exact path={ROUTE_PATH.FIND_DEALER_TYPE.LINK_WITH_PARAMS} component={(props) => { addGA(); return <FindDealerTypePage {...props} /> }} />
                      <Route exact path={ROUTE_PATH.FIND_DEALER_LIST.LINK_WITH_PARAMS} component={(props) => { addGA(); return <FindDealerListPage {...props} /> }} />
                      <Route exact path={ROUTE_PATH.FIND_DEALER_SEARCH_NAME.LINK} component={(props) => { addGA(); return <FindDealerSearchByNamePage {...props} /> }} />
                      <Route exact path={ROUTE_PATH.FIND_DEALER_SEARCH_AREA.LINK} component={(props) => { addGA(); return <FindDealerSearchByAreaPage {...props} /> }} />
                      <Route exact path={ROUTE_PATH.FIND_DEALER_SEARCH_RESULT.LINK} component={(props) => { addGA(); return <FindDealerSearchResultPage {...props} /> }} />
                      <Route exact path={ROUTE_PATH.FIND_DEALER_COMMENTS.LINK_WITH_PARAMS} component={(props) => { addGA(); return <FindDealerDetailCommentPage {...props} /> }} />
                      <Route exact path={ROUTE_PATH.FIND_DEALER_WRITE_COMMENT.LINK_WITH_PARAMS} component={(props) => { addGA(); return <FindDealerDetailWriteCommentPage {...props} /> }} />
                      <Route exact path={ROUTE_PATH.PRODUCT.LINK} component={(props) => { addGA(); return <ProductPage {...props} /> }} />
                      <Route exact path={ROUTE_PATH.PRODUCTCAT.LINK_WITH_PARAMS} component={(props) => { addGA(); return <ProductCatPage {...props} /> }} />
                      {/* <Route exact path={ROUTE_PATH.PRODUCT_DETAIL.LINK_WITH_PARAMS} component={(props) => { addGA(); return <ProductDetailPage {...props} /> }} /> */}
                      <Route exact path={ROUTE_PATH.PRODUCT_DETAIL_BROCHURE.LINK} component={(props) => { addGA(); return <ProductDetailBrochurePage {...props} /> }} />
                      <Route exact path={ROUTE_PATH.ORDER_INQUIRY.LINK} component={(props) => { addGA(); return <OrderInquiryPage {...props} /> }} />
                      <Route exact path={ROUTE_PATH.ORDER_INQUIRY_SEARCH.LINK} component={(props) => { addGA(); return <OrderInquirySearchPage {...props} /> }} />
                      <Route exact path={ROUTE_PATH.ORDER_INQUIRY_DETAIL.LINK_WITH_PARAMS} component={(props) => { addGA(); return <OrderInquiryDetailPage {...props} /> }} />
                      <Route exact path={ROUTE_PATH.ORDER_INQUIRY_ADD.LINK} component={(props) => { addGA(); return <OrderInquiryAddPage {...props} /> }} />
                      <Route exact path={ROUTE_PATH.ORDER_INQUIRY_NOTIFICATION.LINK} component={(props) => { addGA(); return <OrderInquiryNotificationPage {...props} /> }} />
                      <Route exact path={ROUTE_PATH.KNOWLEDGE_BASE.LINK} component={(props) => { addGA(); return <KnowledgeBasePage {...props} /> }} />
                      <Route exact path={ROUTE_PATH.KNOWLEDGE_BASE_TOPIC.LINK_WITH_PARAMS} component={(props) => { addGA(); return <KnowledgeBaseTopicPage {...props} /> }} />
                      <Route exact path={ROUTE_PATH.KNOWLEDGE_BASE_DETAIL.LINK_WITH_PARAMS} component={(props) => { addGA(); return <KnowledgeBaseDetailPage {...props} /> }} />
                      <Route exact path={ROUTE_PATH.PURCHASE_HISTORY.LINK} component={(props) => { addGA(); return <PurchaseHistoryPage {...props} /> }} />
                      <Route exact path={ROUTE_PATH.PURCHASE_HISTORY_DETAIL.LINK_WITH_PARAMS} component={(props) => { addGA(); return <PurchaseHistoryDetailPage {...props} /> }} />
                      <Route exact path={ROUTE_PATH.PURCHASE_HISTORY_SEARCH.LINK} component={(props) => { addGA(); return <PurchaseHistorySearchPage {...props} /> }} />
                      <Route exact path={ROUTE_PATH.LOYALTY_POINT.LINK} component={(props) => { addGA(); return <LoyaltyPointPage {...props} /> }} />
                      <Route exact path={ROUTE_PATH.REWARD.LINK} component={(props) => { addGA(); return <RewardCatPage {...props} /> }} />
                      {/* <Route exact path={ROUTE_PATH.REWARD.LINK} component={(props) => { addGA(); return <RewardPage {...props} /> }} />
                    <Route exact path={ROUTE_PATH.REWARD_CAT.LINK_WITH_PARAMS} component={(props) => { addGA(); return <RewardCatPage {...props} /> }} />*/}
                      <Route exact path={ROUTE_PATH.REWARD_DETAIL.LINK_WITH_PARAMS} component={(props) => { addGA(); return <RewardDetailPage {...props} /> }} />
                      <Route exact path={ROUTE_PATH.REWARD_SEARCH.LINK} component={(props) => { addGA(); return <RewardSearchPage {...props} /> }} />
                      <Route exact path={ROUTE_PATH.WARRANTY.LINK} component={(props) => { addGA(); return <WarrantyPage {...props} /> }} />
                      <Route exact path={ROUTE_PATH.WARRANTY_PRODUCT.LINK_WITH_PARAMS} component={(props) => { addGA(); return <WarrantyProductPage {...props} /> }} />
                      {/* <Route exact path={ROUTE_PATH.WARRANTY_PRODUCT_FILE.LINK_WITH_PARAMS} component={(props) => { addGA(); return <WarrantyProductFilePage {...props} /> }} /> */}
                      <Route exact path={ROUTE_PATH.WARRANTY_SEARCH_NAME.LINK} component={(props) => { addGA(); return <WarrantySearchByNamePage {...props} /> }} />
                      <Route exact path={ROUTE_PATH.WARRANTY_SEARCH_DATE.LINK} component={(props) => { addGA(); return <WarrantySearchByDatePage {...props} /> }} />
                      <Route exact path={ROUTE_PATH.WARRANTY_SEARCH_RESULT.LINK} component={(props) => { addGA(); return <WarrantySearchResultPage {...props} /> }} />
                      <Route exact path={ROUTE_PATH.ESTIMATOR.LINK} component={(props) => { addGA(); return <EstimatorPage {...props} /> }} />
                      <Route exact path={ROUTE_PATH.ESTIMATOR_SUMMARY.LINK} component={(props) => { addGA(); return <EstimatorSummaryPage {...props} /> }} />
                      <Redirect to={ROUTE_PATH.HOME.LINK} />
                    </Switch>
                    :
                    <Switch location={location}>
                      <Route exact path={ROUTE_PATH.SPLASH_SCREEN.LINK} component={(props) => { addGA(); return <SplashScreenPage {...props} /> }} />
                      {/* <Route exact path={ROUTE_PATH.LOGIN.LINK} component={(props) => { addGA(); return <LoginPage {...props} /> }} />
                    <Route exact path={ROUTE_PATH.LOGIN_METHOD.LINK} component={(props) => { addGA(); return <LoginMethodPage {...props} /> }} />
                    <Route exact path={ROUTE_PATH.LOGIN_BY_EMAIL.LINK} component={(props) => { addGA(); return <LoginByEmailPage {...props} /> }} /> */}
                      <Route exact path={ROUTE_PATH.LOGIN_BY_PHONE.LINK} component={(props) => { addGA(); return <LoginByPhonePage {...props} /> }} />
                      <Route exact path={ROUTE_PATH.LOGIN_BY_LINE_PHONE.LINK_WITH_PARAMS} component={(props) => { addGA(); return <LoginByLinePhonePage {...props} /> }} />
                      <Route exact path={ROUTE_PATH.TERMS_CONDITIONS.LINK} component={(props) => { addGA(); return <TermsConditionsPage {...props} /> }} />
                      <Route exact path={ROUTE_PATH.REGISTER.LINK} component={(props) => { addGA(); return <RegisterPage {...props} /> }} />
                      <Route exact path={ROUTE_PATH.REGISTER.LINK_WITH_PARAMS} component={(props) => { addGA(); return <RegisterPage {...props} /> }} />
                      <Route exact path={ROUTE_PATH.FORGOT_PASSWORD_METHOD.LINK} component={(props) => { addGA(); return <ForgetPasswordMethodPage {...props} /> }} />
                      <Route exact path={ROUTE_PATH.FORGOT_PASSWORD_PHONE_NUMBER.LINK} component={(props) => { addGA(); return <ForgotPasswordVerifyWithPhoneNumberPage {...props} /> }} />
                      <Route exact path={ROUTE_PATH.VERIFY_QUESTION.LINK} component={(props) => { addGA(); return <VerifyWithQuestionPage {...props} /> }} />
                      <Route exact path={ROUTE_PATH.RESET_PASSWORD.LINK} component={(props) => { addGA(); return <ResetPasswordPage {...props} /> }} />
                      <Route exact path={ROUTE_PATH.WELCOME.LINK} component={(props) => { addGA(); return <WelcomePage {...props} /> }} />
                      <Route exact path={ROUTE_PATH.VOUCHER_OUT_OF_STOCK.LINK_WITH_PARAMS} component={(props) => { addGA(); return <VoucherOutOfStockPage {...props} /> }} />
                      <Route exact path={ROUTE_PATH.REGISTER_SUCCESS.LINK} component={(props) => { addGA(); return <RegisterSuccessPage {...props} /> }} />
                      <Redirect to={ROUTE_PATH.SPLASH_SCREEN.LINK} />
                    </Switch>
                  }
                </Switch>
              </CSSTransition>
            </TransitionGroup>
          </MainLayout>
          {/* React Toastify - global options */}
          <ToastContainer
            className='react-toastify'
            position='top-center'
            hideProgressBar
            closeOnClick={false}
            transition={toastAnimation}
          />
          <AlertModalContainerConnected />
          <AlertVersionContainerConnected />
          <ModalNoticeContainerConnected />
          <LoaderContainerConnected />
        </React.Fragment>
        :
        <Switch>
          <Route exact path={ROUTE_PATH.LINE_LIFF.LINK} component={(props) => { addGA(); return <LineLiffPage {...props} /> }} />
          <Route exact path={ROUTE_PATH.AUTH.LINK} component={(props) => { addGA(); return <AuthPage {...props} /> }} />
          <Route exact path={ROUTE_PATH.LINE_OA_VALIDATION.LINK} component={(props) => { addGA(); return <LineOaValidationPage {...props} /> }} />
          <Redirect to={ROUTE_PATH.LINE_OA_VALIDATION.LINK} />
        </Switch>
      )
  }
}

const mapStateToProps = (state) => {
  return {
    authApp: state.mainRedux.authApp,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setRouteHistory: (data) => dispatch(setRouteHistory(data))
  }
}

export const RouteContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(RouteApp)