import React from 'react'
import _ from 'lodash'
import axios from 'axios'
// import qs from 'qs'
import {
  TransitionContainer,
  Container,
  Image,
  Section,
  Text,
  Field,
  NavigationBar,
  Button,
  Grid,
  Alert,
} from './../../components'
import {
  VARIABLES,
  TYPOGRAPHYS,
  ICONS,
} from './../../themes'
import {
  ROUTE_PATH,
  redirect,
} from './../../helpers'
import {
  Loader
} from '../Loader'
import config from './../../config'
import { getLanguage } from '../../helpers/functions/language'
import { getUserAuth } from '../../helpers/functions/auth'

export class FindDealerSearchByNamePage extends React.Component {
  constructor(props) {
    super(props);
    // const query = qs.parse(_.get(this.props, 'location.search', '').replace('?', ''))
    const type = localStorage.getItem('dealerType')
    this.state = {
      keyword: '',
      type,
      isLoading: false,
      showModal: false,
    }
  }

  onChangeKeyword(event) {
    this.setState({ keyword: event.target.value })
  }

  onClickSearch() {
    const { keyword, type } = this.state
    // if (!keyword) return
    this.setState({ isLoading: true }, async () => {
      try {
        const userAuth = getUserAuth();
        const url = `${config.api.cms}/bsad/search/`
        const options = {
          name: keyword,
          status: 'active',
          type,
          countries: userAuth.countries,
        }
        const response = await axios.post(url, options)
        const data = _.get(response, 'data.data')
        if (response.status === 200 && !_.isEmpty(data)) {
          localStorage.setItem('dealerList', JSON.stringify(data))
          redirect(ROUTE_PATH.FIND_DEALER_SEARCH_RESULT.LINK)
        } else {
          this.setState({ showModal: true })
        }
      } catch (_) {
      } finally {
        this.setState({ isLoading: false })
      }
    })
  }

  onClickClearKeyword() {
    this.setState({ keyword: '' })
  }

  onCloseModal() {
    this.setState({ showModal: false })
  }

  // handleBack() {
  //   redirect(ROUTE_PATH.FIND_DEALER_TYPE.LINK)
  // }

  render() {
    const { keyword, isLoading, showModal } = this.state
    return (
      <TransitionContainer>
        <Container
          width={VARIABLES.WEBSITE_WIDTH}
          calcMinHeight='100vh'
          bgColor={VARIABLES.COLORS.LIGHT_BLUE_2}
        >
          <NavigationBar
            pageTitle={getLanguage('findDealerSearchByName.title')}
            actionLeft={
              <Image
                widthRatio='11'
                widthRatioUnit='px'
                heightRatio='21'
                heightRatioUnit='px'
                src={ICONS['ic-left-black.svg']}
                //onClick={this.handleBack.bind(this)}
                onClick={this.props.history.goBack}
              />
            }
          />
          <Section
            paddingHorizontal='20'
            paddingTop='33'
            justify='center'
          >
            <Section
              position='relative'
            >
              {keyword && <Button
                className='is-clear-search'
                width={20}
                height={20}
                bgImage={ICONS['ic-close-blue-circle.svg']}
                bgSize='cover'
                bgRepeat='no-repeat'
                bgPosition='center'
                bgColor={VARIABLES.COLORS.LIGHT_BLUE_2}
                onClick={this.onClickClearKeyword.bind(this)}
              />}
              <Section position='absolute' className='is-search-placeholder'>
                <Grid gutter='10'>
                  <Grid.Column>
                    <Image
                      widthRatio='15'
                      widthRatioUnit='px'
                      heightRatio='15'
                      heightRatioUnit='px'
                      top='-2'
                      src={`${keyword ? ICONS['ic-search-blue.svg'] : ICONS['ic-search-light-blue.svg']}`}
                    />
                  </Grid.Column>
                  <Grid.Column >
                    {<Text
                      fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_14}
                      color={`${keyword ? VARIABLES.COLORS.BLUE_2 : VARIABLES.COLORS.LIGHT_BLUE_3}`}
                      ellipsis
                    >
                      {!keyword ? getLanguage('findDealerSearchByName.byName') : ''}
                    </Text>}
                  </Grid.Column>
                  <Grid.Column >
                  </Grid.Column>
                </Grid>
              </Section>
              <Field
                fluid
                value={keyword}
                bgColor={VARIABLES.COLORS.TRANSPARENT}
                borderWidth='1'
                borderStyle='solid'
                borderColor={VARIABLES.COLORS.BLUE_2}
                borderRadius='25'
                fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_15}
                fontColor={VARIABLES.COLORS.BLUE_2}
                paddingHorizontal={42}
                paddingTop={10}
                paddingBottom={10}
                onChange={this.onChangeKeyword.bind(this)}
              />
            </Section>
            <Section
              paddingTop='47'
              justify='center'>
              <Button
                shadow
                className='is-gradient-blue'
                width='275'
                height={VARIABLES.BUTTON.HEIGHTS.H_40}
                borderRadius={VARIABLES.BUTTON.BORDER_RADIUSES.BR_10}
                onClick={this.onClickSearch.bind(this)}
              >
                <Text
                  fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_16}
                  color={VARIABLES.COLORS.WHITE}
                >
                  {getLanguage('field.buttonSearch')}
                </Text>
              </Button>
            </Section>
          </Section>
        </Container>
        <Alert type='error' title='Data not found' onClose={this.onCloseModal.bind(this)} open={showModal} />
        <Loader loading={isLoading} isOverlay />
      </TransitionContainer>
    )
  }
}
