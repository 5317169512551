import React from 'react'
import _ from 'lodash'
import moment from 'moment';
import axios from 'axios'
import qs from 'qs'
import {
  TransitionContainer,
  Container,
  Section,
  Image,
  Button,
  NavigationBar,
  Text,
} from './../../components'
import {
  VARIABLES,
  TYPOGRAPHYS,
  ICONS,
} from './../../themes'
import {
  ROUTE_PATH,
  redirect,
} from './../../helpers'
import {
  PurchaseHistoryItem,
} from './PurchaseHistoryItem'
import {
  Loader
} from '../Loader'
import { LOCAL_STORAGE } from '../../helpers/constants/localStorage';
import config from '../../config';
import { getLanguage } from '../../helpers/functions/language';
import { getPurchaseHistory } from '../../helpers/services/purchaseHistory';

export class PurchaseHistoryPage extends React.Component {
  constructor(props) {
    super(props)
    const query = qs.parse(_.get(this.props, 'location.search', '').replace('?', ''))
    const user = JSON.parse(localStorage.getItem(LOCAL_STORAGE.REGISTER_INFO))
    this.state = {
      startDate: _.get(query, 'startDate'),
      endDate: _.get(query, 'endDate'),
      list: [],
      userId: _.get(user, 'id'),
      isLoading: false,
    }
  }

  componentDidMount() {
    localStorage.removeItem('purchaseHistoryList')
    this.setState({ isLoading: true }, async () => {
      try {
        const url = `${config.api.base}/api/purchase/${this.state.userId}`
        // const options = { sort: 'id desc' }
        const options = {}
        // const response = await axios.post(url, options)
        const params = {
          user_ref_id: this.state.userId,
          ...options
        }
        const data = await getPurchaseHistory(params);
        // const data = _.get(response, 'data.data')
        if (!_.isEmpty(data)) {
          // if (response.status === 200 && !_.isEmpty(data)) {
          const sortData = _.orderBy(data, ['id'], ['desc']);
          this.setState({ list: sortData }, () => {
            localStorage.setItem('purchaseHistoryList', JSON.stringify(sortData))
          })
        }
      } catch (_) {
      } finally {
        this.setState({ isLoading: false })
      }
    })
  }

  handleBack() {
    redirect(ROUTE_PATH.HOME.LINK)
  }

  handleBackSearch() {
    redirect(ROUTE_PATH.PURCHASE_HISTORY.LINK)
  }

  formatDate(input) {
    if (!input) { return '' }
    const datePart = input.match(/\d+/g),
      year = datePart[0].substring(2), // get only two digits
      month = datePart[1], day = datePart[2]

    return day + '/' + month + '/' + year
  }

  filterListByDate() {
    const { list, startDate, endDate } = this.state
    return list.filter(data => (data.create_at && (moment(data.create_at).startOf('day')).isBetween(moment(startDate), moment(endDate), null, '[]')))
  }

  render() {
    let { list, startDate, endDate, isLoading } = this.state
    const hasDateSelected = startDate && endDate
    if (hasDateSelected) {
      list = this.filterListByDate()
    }
    const dateSelected = (hasDateSelected) ? `${this.formatDate(startDate)} - ${this.formatDate(endDate)}` : getLanguage('field.filterStartDateEndDatePlaceholder')
    return (
      <TransitionContainer>
        <Container
          width={VARIABLES.WEBSITE_WIDTH}
          calcMinHeight='100vh'
          bgColor={VARIABLES.COLORS.LIGHT_BLUE_2}
        >
          <NavigationBar
            shadow
            iconWidth='20'
            iconHeight='19'
            iconSrc={`${hasDateSelected ? '' : ICONS['ic-bill-black.svg']}`}
            pageTitleIcon={`${hasDateSelected ? getLanguage('purchaseHistory.title_1') : getLanguage('purchaseHistory.title_2')}`}
            actionLeft={hasDateSelected ?
              <Image
                widthRatio='21'
                widthRatioUnit='px'
                heightRatio='21'
                heightRatioUnit='px'
                src={ICONS['ic-close-black.svg']}
                onClick={this.handleBackSearch.bind(this)}
              /> :
              <Image
                widthRatio='24'
                widthRatioUnit='px'
                heightRatio='23'
                heightRatioUnit='px'
                src={ICONS['ic-home-black.svg']}
                onClick={this.handleBack.bind(this)}
              />
            }
          />
          <Section
            scroll
            calcHeight='100vh - 70px'
          >
            <Section
              paddingHorizontal='20'
              paddingTop='24'
              paddingBottom='30'
            >
              <Section spacingBottom='29'>
                <Button
                  className='is-icon-right is-text-left'
                  fluid
                  height='40'
                  paddingRight='10'
                  paddingLeft='50'
                  bgColor={VARIABLES.COLORS.TRANSPARENT}
                  borderWidth='1'
                  borderStyle='solid'
                  borderColor={VARIABLES.COLORS.BLUE_2}
                  borderRadius='20'
                  justify='left'
                  onClick={() => {
                    redirect(ROUTE_PATH.PURCHASE_HISTORY_SEARCH.LINK)
                  }}
                >
                  <Image
                    className='icon-left-button'
                    widthRatio='14'
                    widthRatioUnit='px'
                    heightRatio='16'
                    heightRatioUnit='px'
                    src={ICONS['ic-date-blue.svg']}
                  />
                  <Button.Icon>
                    <Image
                      widthRatio='13'
                      widthRatioUnit='px'
                      heightRatio='7'
                      heightRatioUnit='px'
                      src={ICONS['ic-arrow-down-blue.svg']}
                    />
                  </Button.Icon>
                  <Text
                    fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_14}
                    color={VARIABLES.COLORS.BLUE_2}
                  >
                    {dateSelected}
                  </Text>
                </Button>
              </Section>
              <Section spacingBottom='13'>
                <Text
                  fontStyle={TYPOGRAPHYS.FIRST.MEDIUM.FS_14}
                >
                  {getLanguage('purchaseHistory.totalSales')} : {list.length}
                </Text>
              </Section>
              {list.map((data, index) => (<PurchaseHistoryItem key={index} data={data} />))}
              {!isLoading && _.isEmpty(list) && <Section
                paddingTop='24'
                justify='center'
              >
                <Text
                  fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_16}
                  color={VARIABLES.COLORS.BLUE_2}
                >
                  {getLanguage('purchaseHistory.DataNotFound')}
                </Text>
              </Section>}
            </Section>
          </Section>
        </Container>
        {<Loader loading={isLoading} isOverlay />}
      </TransitionContainer>
    )
  }
}

