import { ROUTE_PATH } from '..';
import {
  store, storeGetState,
} from './../../store'

export const redirect = (path) => {
  const routeHistoryRedux = storeGetState().routeHistoryRedux;
  routeHistoryRedux.push(path)
}

export const redirectReplace = (path) => {
  const routeHistoryRedux = storeGetState().routeHistoryRedux;
  routeHistoryRedux.replace(path)
}

export const redirectGoBack = (path) => {
  const routeHistoryRedux = storeGetState().routeHistoryRedux;
  routeHistoryRedux.goBack()
}

export const redirectData = (path, params) => {
  const routeHistoryRedux = storeGetState().routeHistoryRedux
  routeHistoryRedux.replace(path, {
    pathname: path,
    ...params,
  })
}

export const getRouteParams = (props, target) => {
  return props.match.params[target];
}

export const getRouteData = (props, target) => {
  if (props.location.state) {
    return props.location.state[target];
  }
}

export const getUrlParams = (target) => {
  const url = new URL(window.location.href);
  return url.searchParams.get(target);
}

export const checkDomain = () => {
  const devHostnameList = storeGetState().mainRedux.devHostnameList;
  if (devHostnameList.indexOf(window.location.hostname) === -1) {
    if (window.location.protocol !== 'https:') {
      // window.location.href = `https://${window.location.host}`;
      window.location.href = window.location.href.replace('http://', 'https://');
    }
  }
}

export const getRouteRefreshWithAuthApp = (target = ROUTE_PATH.SPLASH_SCREEN.LINK) => {
  return `${window.location.origin}${ROUTE_PATH.AUTH.LINK_WITH_PARAMS}${target}`;
}