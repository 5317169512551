import { handleQueryFirebase, handleUpdateFirebase, handleQueryDocFirebase, handleSetFirebase } from './firebaseManageData';

let qSnapOTPAuthUnsub = () => { };

export const firebaseUnsubscribeOTPAuth = () => {
  console.log('firebaseUnsubscribeOTPAuth')
  qSnapOTPAuthUnsub();
}

export const handleUpdateOTPAuth = (data, callback = () => { }) => {
  return new Promise(async (resolve, reject) => {
    try {
      let where = ['reference_code', '==', data.referenceCode];
      const qSnapOTP = await handleQueryFirebase('otp_authen_mobile', {
        where
      });
      console.log('handleUpdateOTPAuth', where, qSnapOTP.size);
      if (qSnapOTP.size) {
        const qSnap = qSnapOTP.docs[0];
        let dataOTP = qSnap.data();
        dataOTP.firebaseKey = qSnap.id;
        dataOTP.firebasePath = qSnap.ref.parent.path;
        dataOTP = {
          referenceCode: dataOTP.reference_code,
          secret: dataOTP.secret,
        }
        console.log('dataOTP', dataOTP);
        await handleUpdateFirebase('otp_authen_mobile', qSnap.id, {
          is_otp_success: true
        })
        callback(dataOTP)
      }
      else {
        callback();
      }
      resolve();
    }
    catch (err) {
      reject(err);
      console.log('err', { err });
    }
  });
}

export const handleWatchOTPAuth = (data, callback = () => { }) => {
  return new Promise(async (resolve, reject) => {
    try {
      qSnapOTPAuthUnsub();
      await handleSetFirebase('otp_authen_mobile', data.phone, {
        id: data.id || 0,
        email: data.email || '',
        reference_code: (data.referenceCode || '').toString(),
        secret: data.secret || '',
        is_otp_success: false
      })

      qSnapOTPAuthUnsub = await handleQueryDocFirebase('otp_authen_mobile', data.phone, {
        callType: 'onSnapshot',
        callbackSuccess: async (doc) => {
          let dataOTP = doc.data();
          console.log('qSnapOTPAuthUnsub dataOTP', dataOTP)
          if (dataOTP.reference_code === data.referenceCode.toString() && dataOTP.is_otp_success) {
            console.log('handleWatchOTPAuth dataOTP', dataOTP)
            callback(dataOTP);
          }
          resolve();
        }
      });
    }
    catch (err) {
      reject(err);
      console.log('err', { err });
    }
  });
}