import React from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import { Helmet } from 'react-helmet';
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemPanel,
  AccordionItemButton,
} from 'react-accessible-accordion'
import {
  Tab,
  Tabs,
  TabList,
  TabPanel
} from 'react-tabs'
import {
  TransitionContainer,
  Container,
  Image,
  Section,
  Text,
  Grid,
  SwiperCarousel,
  ShapeContainer,
  ReactTabsControl,
  AccordionControl,
} from './../../components';
import {
  VARIABLES,
  TYPOGRAPHYS,
  CONTENTS,
  ICONS,
} from './../../themes';
import {
  ROUTE_PATH,
  redirect,
  getRouteParams,
  formatDatetime,
} from './../../helpers'
import axios from 'axios';
import config from '../../config';
import _ from 'lodash';
import { Loader } from '../Loader';
import { getLanguage } from '../../helpers/functions/language'
import { getAllKnowledgeBaseCategory } from '../../helpers/services/knowledgeBase';

export class PublicKnowledgeBasePage extends React.Component {

  constructor(props) {
    super(props);
    const categoryId = parseInt(getRouteParams(props, 'categoryId'));
    const knowledgeId = parseInt(getRouteParams(props, 'knowledgeId'));
    this.state = {
      categoryId,
      knowledgeId,
      data: {}
    };
  }

  componentDidMount() {
    this.publicKnowledgeBase.getData();
  }

  publicKnowledgeBase = {
    getData: () => {
      const {
        categoryId,
        knowledgeId,
      } = this.state;
      this.setState({
        isLoading: true,
      }, async () => {
        const urlCategory = `${config.api.base}/api/knowledge/category`
        const optionsCategory = { sort: 'id asc' }
        // const resCategory = await axios.post(urlCategory, optionsCategory)
        // const dataList = _.get(resCategory, 'data.data')
        let dataList = await getAllKnowledgeBaseCategory(optionsCategory);
        // if (resCategory.status === 200 && !_.isEmpty(dataList)) {
        if (!_.isEmpty(dataList)) {
          const category = dataList.filter(e => e.id === categoryId)[0];
          if (category && category.knowledge) {
            const knowledge = category.knowledge.filter(e => e.id === knowledgeId)[0];
            this.setState({
              data: knowledge || {},
              isLoading: false
            });
          }
          else {
            this.setState({
              isLoading: false
            })
          }
        }
        else {
          this.setState({
            isLoading: false
          })
        }
      })
    },
    onDownloadClick(url) {
      window.open(`${config.imgPrefix}/${url}`, "_blank")
    }
  }

  render() {
    const {
      data,
      isLoading,
    } = this.state
    return (
      <React.Fragment>
        <Helmet>
          <title>{data.topic_name}</title>
          <meta name='description' content={data.topic_name} />
          <meta property='og:url' content={window.location.href} />
          <meta property='og:type' content='article' />
          <meta property='og:title' content={data.topic_name} />
          <meta property='og:description' content={data.topic_name} />
          <meta property='og:image' content={!_.isEmpty(data.gallery) ? `${config.imgPrefix}/${data.gallery[0]}` : ''} />
          <meta name='twitter:card' content='summary' />
        </Helmet>
        <TransitionContainer
        // motion='overlap-from'
        >
          <Container
            calcMinHeight='100vh'
            bgColor={VARIABLES.COLORS.GRAY_5}
          >
            <Scrollbars style={{ height: '100vh' }}>
              <Container
                width='640'
                calcMinHeight='100vh'
                paddingTop='35'
                paddingHorizontal='15'
                bgColor={VARIABLES.COLORS.WHITE}
              >
                <Section
                  paddingBottom='35'
                  paddingHorizontal='20'
                >
                  <SwiperCarousel className='is-banner-news-and-promotions'>
                    {
                      (data.gallery || []).map((e, i) => (
                        <SwiperCarousel.Item
                          key={i}
                          onClick={() => { }}
                        >
                          <Image
                            heightRatio='93'
                            src={`${config.imgPrefix}/${e}`}
                          />
                        </SwiperCarousel.Item>
                      ))
                    }
                  </SwiperCarousel>
                </Section>
                <Section paddingHorizontal='20'>
                  <ReactTabsControl>
                    <Tabs>
                      <TabList>
                        <Tab>{getLanguage('knowledgeBaseDetail.tab.description.title')}</Tab>
                        <Tab>{getLanguage('knowledgeBaseDetail.tab.documentsDownload.title')}</Tab>
                      </TabList>
                      <TabPanel>
                        <Section
                          paddingTop='18'
                          paddingBottom='30'
                        >
                          <Section spacingBottom='18'>
                            <Text
                              fontStyle={TYPOGRAPHYS.FIRST.BOLD.FS_22}
                              color={VARIABLES.COLORS.BLACK}
                            >
                              {data.topic_name}
                            </Text>
                          </Section>
                          <AccordionControl description>
                            <Accordion>
                              {(data.sub_topic || []).map((e, index) => (
                                <AccordionItem className='accordion-list'
                                  key={index}
                                >
                                  <AccordionItemHeading>
                                    <AccordionItemButton>
                                      {e.sub_topic_name}
                                    </AccordionItemButton>
                                  </AccordionItemHeading>
                                  <AccordionItemPanel>
                                    <div className='text-editor-content is-public-content'>
                                      <div
                                        dangerouslySetInnerHTML={{
                                          __html: e.description
                                        }}
                                      />
                                    </div>
                                  </AccordionItemPanel>
                                </AccordionItem>
                              ))}
                            </Accordion>
                          </AccordionControl>
                        </Section>
                      </TabPanel>
                      <TabPanel>
                        <Section paddingVertical='30'>
                          {(data.document || []).map((e, index) => (
                            <ShapeContainer fluid
                              key={index}
                              padding='20'
                              spacingBottom='15'
                              borderRadius='6'
                              boxShadow={VARIABLES.COLORS.SHADOW_2}
                            >
                              <Section
                                spacingBottom='7'
                                onClick={() => { }}
                              >
                                <Text
                                  fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_14}
                                  color={VARIABLES.COLORS.BLACK}
                                >
                                  {e.name}
                                </Text>
                              </Section>
                              <Section onClick={() => {
                                this.publicKnowledgeBase.onDownloadClick(e.file);
                              }}>
                                <Grid gutter='7'>
                                  <Grid.Column>
                                    <Image
                                      widthRatio='24'
                                      widthRatioUnit='px'
                                      heightRatio='20'
                                      heightRatioUnit='px'
                                      src={ICONS['ic-feather-download-cloud.svg']}
                                    />
                                  </Grid.Column>
                                  <Grid.Column>
                                    <Text
                                      fontStyle={TYPOGRAPHYS.FIRST.BOLD.FS_10}
                                      color={VARIABLES.COLORS.BLUE_2}
                                    >
                                      DOWNLOAD
                          </Text>
                                  </Grid.Column>
                                </Grid>
                              </Section>
                            </ShapeContainer>
                          ))}
                        </Section>
                      </TabPanel>
                    </Tabs>
                  </ReactTabsControl>
                </Section>
              </Container>
            </Scrollbars>
          </Container>
        </TransitionContainer>
        <Loader isOverlay
          loading={isLoading}
        />
      </React.Fragment>
    )
  }
}
