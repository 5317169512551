import React from 'react';
import { connect } from 'react-redux';
import {
  Modal,
  Section,
  Text,
  Button,
  Image,
  NavigationBar,
  Field,
} from '../../../components';
import { ICONS, VARIABLES, TYPOGRAPHYS } from '../../../themes';
import { getLanguage } from '../../../helpers/functions/language';
import { DATA } from '../../../helpers/constants/data';

export class ModalTermsAndConditionContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isAcceptTNC: false,
      isNotShowAgain: false,
    };
  }

  modalTNC = {
    onClickSubmit: () => {
      const { onAccept } = this.props;
      onAccept({
        ...this.state,
      });
    },
  };

  input = {
    onChangeCheckbox: (target, isChecked) => {
      this.setState({
        [target]: isChecked,
      });
    },
  };

  render() {
    const { isShowModal, onClickBack, data, isHideAccpetButton } = this.props;
    const { isAcceptTNC, isNotShowAgain } = this.state;
    return (
      <Modal
        open={isShowModal}
        width={414}
        className='is-full-page'
        overlayColor={VARIABLES.COLORS.OVERLAY_3}
        // spacing={VARIABLES.MODAL.CONTAINER.C_1.SPACING}
        transiti
        on={VARIABLES.TRANSITIONS.DEFAULT}
        transformStart={VARIABLES.MODAL.CONTAINER.C_1.TRANSFORM_START}
        transformEnd={VARIABLES.MODAL.CONTAINER.C_1.TRANSFORM_END}
        onClickClose={onClickBack}
      >
        <Modal.Section>
          <React.Fragment>
            <NavigationBar
              shadow
              pageTitleIcon={getLanguage('luckyDraw.termsAndConditions')}
              actionLeft={
                <Image
                  widthRatio='24'
                  widthRatioUnit='px'
                  heightRatio='24'
                  heightRatioUnit='px'
                  src={ICONS['ic-close-black.svg']}
                  onClick={onClickBack}
                />
              }
            />
            <Section
              scroll
              paddingHorizontal='10'
              justify='center'
              spacingTop='30'
            >
              <Section
                className='is-terms-and-condition'
                scroll
                calcHeight={`100vh - ${isHideAccpetButton ? '140px' : '320px'}`}
                width='323'
                justify='center'
                spacingBottom='10'
                breakAll
              >
                <div dangerouslySetInnerHTML={{ __html: data.detail }} />
              </Section>

              {!isHideAccpetButton && (
                <Section
                  paddingTop='24'
                  paddingHorizontal='40'
                  spacingBottom='24'
                >
                  <Section spacingBottom='16'>
                    <Field
                      className='is-checkbox-terms'
                      type='checkbox'
                      id='checkbox-agree-terms'
                      name='checkbox-agree-terms'
                      checked={isAcceptTNC}
                      checkboxLabel={getLanguage(
                        'luckyDraw.agreeTermsAndConditions'
                      )}
                      fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_14}
                      fontColor={VARIABLES.COLORS.BLUE_2}
                      checkboxSize='20'
                      checkboxLabelSpacingLeft='15'
                      checkboxBorderWidth='1'
                      checkboxBorderStyle='solid'
                      checkboxBorderColor={VARIABLES.COLORS.GRAY_2}
                      checkboxCheckedBgColor={VARIABLES.COLORS.BLUE_2}
                      checkboxBorderCheckedColor={VARIABLES.COLORS.BLUE_2}
                      checkboxBorderRadius='4'
                      checkboxCheckedIcon={
                        <Image
                          widthRatio='18'
                          widthRatioUnit='px'
                          heightRatio='18'
                          heightRatioUnit='px'
                          src={ICONS['ic-check.svg']}
                        />
                      }
                      onChange={(ev) => {
                        this.input.onChangeCheckbox(
                          'isAcceptTNC',
                          ev.target.checked
                        );
                      }}
                    />
                  </Section>
                  <Section spacingBottom='16'>
                    <Field
                      className='is-checkbox-terms'
                      type='checkbox'
                      id='checkbox-agree-terms'
                      name='checkbox-agree-terms'
                      checked={isNotShowAgain}
                      checkboxLabel={getLanguage(
                        'luckyDraw.dontShowThisMessageAgainNextTime'
                      )}
                      fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_14}
                      fontColor={VARIABLES.COLORS.BLUE_2}
                      checkboxSize='20'
                      checkboxLabelSpacingLeft='15'
                      checkboxBorderWidth='1'
                      checkboxBorderStyle='solid'
                      checkboxBorderColor={VARIABLES.COLORS.GRAY_2}
                      checkboxCheckedBgColor={VARIABLES.COLORS.BLUE_2}
                      checkboxBorderCheckedColor={VARIABLES.COLORS.BLUE_2}
                      checkboxBorderRadius='4'
                      checkboxCheckedIcon={
                        <Image
                          widthRatio='18'
                          widthRatioUnit='px'
                          heightRatio='18'
                          heightRatioUnit='px'
                          src={ICONS['ic-check.svg']}
                        />
                      }
                      onChange={(ev) => {
                        this.input.onChangeCheckbox(
                          'isNotShowAgain',
                          ev.target.checked
                        );
                      }}
                    />
                  </Section>
                </Section>
              )}
            </Section>
            {!isHideAccpetButton && (
              <Section justify='center'>
                <Button
                  disabled={!isAcceptTNC}
                  width='200'
                  className={!isAcceptTNC ? '' : 'is-gradient-blue'}
                  borderWidth='1'
                  borderStyle='solid'
                  borderColor={
                    !isAcceptTNC
                      ? VARIABLES.COLORS.DISABLED
                      : VARIABLES.COLORS.BLUE_2
                  }
                  shadow
                  height={VARIABLES.BUTTON.HEIGHTS.H_35}
                  borderRadius={VARIABLES.BUTTON.BORDER_RADIUSES.BR_10}
                  onClick={this.modalTNC.onClickSubmit}
                >
                  <Text
                    fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_16}
                    color={VARIABLES.COLORS.WHITE}
                  >
                    {getLanguage('luckyDraw.accept')}
                  </Text>
                </Button>
              </Section>
            )}
          </React.Fragment>
        </Modal.Section>
      </Modal>
    );
  }
}
