import React from 'react'
import ClassNames from 'classnames'
import PropTypes from 'prop-types'
import {
  TrueBlueRankPanelWrapper
} from './styled'
import {
  Grid,
  Text,
  Section,
} from './../../../components'
import {
  default as VARIABLES
} from './../../../themes/styles/bases/variables'
import {
  default as TYPOGRAPHYS
} from './../../../themes/styles/bases/typographys'
import {
  ICONS,
} from './../../../themes'
import { getLanguage } from './../../../helpers/functions/language'

/**
 * TrueBlueRankPanel description:
 * - TrueBlueRankPanel
 */

export class TrueBlueRankPanel extends React.PureComponent {
  render() {
    const {
      className,
      isFluid,
      rankGold,
      rankSilver,
      rankBronze,
      length,
      duration,
      totalPurchasing,
    } = this.props

    // props for css classes
    const classes = ClassNames(
      'trueblue-rank-panel',
      {'is-fluid': isFluid},
      {'is-no-rank': !rankGold || !rankSilver || !rankBronze},
      className
    )

    return (
      <TrueBlueRankPanelWrapper
        className={classes}
      >
        {rankGold && <img className='tbrp-image is-rank' src={ICONS['ic-medal-gold.png']} alt='Icon' />}
        {rankSilver && <img className='tbrp-image is-rank' src={ICONS['ic-medal-silver.png']} alt='Icon' />}
        {rankBronze && <img className='tbrp-image is-rank' src={ICONS['ic-medal-bronze.png']} alt='Icon' />}

        {!rankGold && !rankSilver && !rankBronze ?
          <div className='tbrp-panel is-no-rank'>
            <Grid gutter='10' justify='center' align='center'>
              <Grid.Column><img src={ICONS['ic-info-circle-gray.svg']} alt='Icon' /></Grid.Column>
              <Grid.Column><Text fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_16} color={VARIABLES.COLORS.GRAY_4}>{getLanguage('home.noRank')}</Text></Grid.Column>
            </Grid>
          </div>
          :
          <React.Fragment>
            <div className='tbrp-section is-information'>
              <div className={ClassNames('tbrp-section is-information-length',
                { 'is-information-length-gold': rankGold },
                { 'is-information-length-silver': rankSilver },
                { 'is-information-length-bronze': rankBronze },
              )}>
                <Grid align='flex-end' gutter='5'>
                  <Grid.Column>
                    <Section spacingBottom='1'>
                      <Text fontStyle={TYPOGRAPHYS.FIRST.MEDIUM.FS_14} color={VARIABLES.COLORS.WHITE}>{getLanguage('home.your')}</Text>
                      <br />
                      <Text top='-2' fontStyle={TYPOGRAPHYS.FIRST.MEDIUM.FS_14} color={VARIABLES.COLORS.WHITE}>{getLanguage('home.length')}</Text>
                    </Section>
                  </Grid.Column>
                  <Grid.Column>
                    <Text singleLine fontStyle={TYPOGRAPHYS.FIRST.BOLD.FS_42} color={VARIABLES.COLORS.WHITE}>
                      {length}
                    </Text>
                  </Grid.Column>
                  <Grid.Column>
                    <Text fontStyle={TYPOGRAPHYS.FIRST.MEDIUM.FS_14} color={VARIABLES.COLORS.WHITE}>{getLanguage('home.metre')}</Text>
                  </Grid.Column>
                </Grid>
              </div>
              {(rankGold || rankSilver) &&
                <div className='tbrp-section is-information-purchasing'>
                  <Grid align='center' gutter='10'>
                    <Grid.Column>
                      <Text fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_12} color={VARIABLES.COLORS.BLACK}>{getLanguage('home.purchasing')}</Text>
                    </Grid.Column>
                    <Grid.Column>
                      <div className={ClassNames('tbrp-badge is-purchasing',
                        { 'is-purchasing-gold-active': rankGold },
                        { 'is-purchasing-silver-active': rankSilver },
                      )}>
                        <Text fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_14} color={VARIABLES.COLORS.WHITE}>{totalPurchasing}</Text>
                      </div>
                    </Grid.Column>
                    {/* <Grid.Column>
                      <div className={ClassNames('tbrp-badge is-purchasing is-purchasing-circle',
                        {'is-purchasing-gold-active': true},
                        {'is-purchasing-silver-active': false},
                      )}>
                        <Text fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_14} color={VARIABLES.COLORS.WHITE}>1</Text>
                      </div>
                    </Grid.Column>
                    <Grid.Column>
                      <div className={ClassNames('tbrp-badge is-purchasing is-purchasing-circle',
                        {'is-purchasing-gold-active': false},
                        {'is-purchasing-silver-active': false},
                      )}>
                        <Text fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_14} color={VARIABLES.COLORS.WHITE}>2</Text>
                      </div>
                    </Grid.Column>
                    <Grid.Column>
                      <div className={ClassNames('tbrp-badge is-purchasing is-purchasing-circle',
                        {'is-purchasing-gold-active': false},
                        {'is-purchasing-silver-active': false},
                      )}>
                        <Text fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_14} color={VARIABLES.COLORS.WHITE}>3</Text>
                      </div>
                    </Grid.Column> */}
                  </Grid>
                </div>
              }
            </div>

            <div className={ClassNames('tbrp-section is-duration',
              { 'is-duration-gold': rankGold },
              { 'is-duration-silver': rankSilver },
              { 'is-duration-bronze': rankBronze },
            )}>
              <Grid justify='space-between'>
                <Grid.Column>
                  <Text fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_12} color={VARIABLES.COLORS.BLACK}>{getLanguage('home.cumulativeAmountSince')}</Text>
                </Grid.Column>
                <Grid.Column>
                  <Text fontStyle={TYPOGRAPHYS.FIRST.BOLD.FS_12} color={VARIABLES.COLORS.BLACK}>
                    {duration}
                  </Text>
                </Grid.Column>
              </Grid>
            </div>
          </React.Fragment>
        }
      </TrueBlueRankPanelWrapper>
    )
  }

  static defaultProps = {
    // * If you set new default props, you should create propsType for correct styleguide document.
    length: '0',
  }

  static propTypes = { // TYPE > node, string, number, bool, array, object, symbol, func
    /**
    * Additional classes
    */
    className: PropTypes.string
  }
}
