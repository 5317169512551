import React from 'react'
import ClassNames from 'classnames'
import PropTypes from 'prop-types'
import {
  TextBaseWrapper
} from './styled'
import {
  default as VARIABLES
} from './../../../themes/styles/bases/variables'
// import {
//   default as TYPOGRAPHYS
// } from './../../../themes/styles/bases/typographys'
// import {
//   default as UTILITIES
// } from './../../../themes/styles/bases/typographys'

/**
 * TextBase description:
 * - control font style
 */

export class TextBase extends React.PureComponent {
  render () {
    const {
      // Option
      children,
      className,
      // Default
      top,
      left,
      fontStyle,
      width,
      color,
      hoverColor,
      align,
      verticalAlign,
      ellipsis,
      ellipsisLine,
      singleLine,
      // Responsive
      fontStyleMobileSm,
      fontStyleMobileMd,
      fontStyleMobileLg,
      fontStylePhabletSm,
      fontStylePhabletMd,
      fontStylePhabletLg,
      fontStyleTabletSm,
      fontStyleTabletMd,
      fontStyleTabletLg,
      // Breakpoint
      breakpointFontStyleMobileSm,
      breakpointFontStyleMobileMd,
      breakpointFontStyleMobileLg,
      breakpointFontStylePhabletSm,
      breakpointFontStylePhabletMd,
      breakpointFontStylePhabletLg,
      breakpointFontStyleTabletSm,
      breakpointFontStyleTabletMd,
      breakpointFontStyleTabletLg,
      href,
      target,
      onClick,
      title,
    } = this.props

    return (
      <TextBaseWrapper
        className={ClassNames(
          'text-base',
          { 'is-ellipsis': ellipsis },
          { 'is-single-line': singleLine },
          { 'is-cursor-pointer': onClick },
          // Option
          className
        )}
        theme={{
          // Default
          top,
          left,
          fontStyle,
          width,
          color,
          hoverColor,
          align,
          verticalAlign,
          ellipsisLine,
          // Responsive
          fontStyleMobileSm,
          fontStyleMobileMd,
          fontStyleMobileLg,
          fontStylePhabletSm,
          fontStylePhabletMd,
          fontStylePhabletLg,
          fontStyleTabletSm,
          fontStyleTabletMd,
          fontStyleTabletLg,
          // Breakpoint
          breakpointFontStyleMobileSm,
          breakpointFontStyleMobileMd,
          breakpointFontStyleMobileLg,
          breakpointFontStylePhabletSm,
          breakpointFontStylePhabletMd,
          breakpointFontStylePhabletLg,
          breakpointFontStyleTabletSm,
          breakpointFontStyleTabletMd,
          breakpointFontStyleTabletLg
        }}
        href={href}
        target={target}
        onClick={onClick}
        title={title}
      >
        {children}
      </TextBaseWrapper>
    )
  }

  static defaultProps = {
    // Option
    // children: undefined,
    // className: undefined,
    // Default
    // Breakpoint
    verticalAlign: 'baseline',
    breakpointFontStyleMobileSm: VARIABLES.BREAKPOINTS.BP_359,
    breakpointFontStyleMobileMd: VARIABLES.BREAKPOINTS.BP_360,
    breakpointFontStyleMobileLg: VARIABLES.BREAKPOINTS.BP_414,
    breakpointFontStylePhabletSm: VARIABLES.BREAKPOINTS.BP_480,
    breakpointFontStylePhabletMd: VARIABLES.BREAKPOINTS.BP_640,
    breakpointFontStylePhabletLg: VARIABLES.BREAKPOINTS.BP_736,
    breakpointFontStyleTabletSm: VARIABLES.BREAKPOINTS.BP_768,
    breakpointFontStyleTabletMd: VARIABLES.BREAKPOINTS.BP_812,
    breakpointFontStyleTabletLg: VARIABLES.BREAKPOINTS.BP_1024
  }

  static propTypes = { // TYPE > node, string, number, bool, array, object, symbol, func
    /**
    * Additional classes
    */
    className: PropTypes.string,

    /**
    * Additional Text, HTML, Component
    */
    children: PropTypes.oneOfType([
      PropTypes.node,
      PropTypes.string
    ])
  }
}
