import React from 'react'
import {
  TransitionContainer,
  Container,
  Section,
  Image,
  Grid,
  Text,
  Button,
  NavigationBar,
  ShapeContainer,
} from './../../components'
import {
  VARIABLES,
  TYPOGRAPHYS,
  ICONS,
  CONTENTS
} from './../../themes'
import {
  ROUTE_PATH,
  redirect,
} from './../../helpers'
import {
  Loader
} from '../Loader';
import axios from 'axios'
import * as _ from 'lodash';
import config from '../../config';
import { ENUM } from '../../helpers/constants/enum';
import { getLanguage } from '../../helpers/functions/language';
import { getUserAuth } from '../../helpers/functions/auth';
import { getCountryType } from '../../helpers/functions/data';
import { fetchApi } from '../../apiService/apiService';
import { getProjectByUserID } from '../../helpers/services/projects';

export class MyProjectsPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      //mock
      id: (this.props.match.params && this.props.match.params.id) ? this.props.match.params.id : 1,
      data: [],
      // dataSample: [{
      //   isSample: true,
      //   image_url: CONTENTS['thumbnail-my-project-sample-1.jpg'],
      //   name: 'Siam Square One',
      //   location: 'Bangkok, Thailand'
      // }, {
      //   isSample: true,
      //   image_url: CONTENTS['thumbnail-my-project-sample-2.jpg'],
      //   name: 'Bangkok University Communication Arts',
      //   location: 'Rangsit, Pathum Thani'
      // }, {
      //   isSample: true,
      //   image_url: CONTENTS['thumbnail-my-project-sample-3.jpg'],
      //   name: 'PTT Oil Business Academy',
      //   location: 'Wong noi, Ayutthaya'
      // }, {
      //   isSample: true,
      //   image_url: CONTENTS['thumbnail-my-project-sample-4.jpg'],
      //   name: 'MRT Blue Line Station',
      //   location: 'Bangkok'
      // }],
      isLoading: false,
    }
  }

  async componentWillMount() {
    //load data
    // const data = JSON.parse(localStorage.getItem('project'));
    //user id
    this.setState({ isLoading: true });
    localStorage.removeItem('project');
    // const getData = await fetchApi.get(`${config.api.base}/api/projects/${this.state.id}`)
    const param = {
      user_id: this.state.id,
    }
    const getData = await getProjectByUserID(param);
    if (getData && getData.length) {
      // if (getData && getData.data && getData.data.length) {
      // const sortData = _.orderBy(getData.data, ['id'], ['desc']);

      const userAuth = getUserAuth();
      const addressType = getCountryType(userAuth.countries);
      let sortData = getData.filter(e => e.available_country !== null && (e.available_country.indexOf(addressType) >= 0 || e.user_id));
      // let sortData = getData.data.filter(e => e.available_country !== null && (e.available_country.indexOf(addressType) >= 0 || e.user_id));

      // let sortData = getData.data;
      sortData = sortData.sort((a, b) => {
        const dateA = new Date(a.created_at);
        const dateB = new Date(b.created_at);
        return dateB - dateA;
      });

      let dataList = sortData.filter(e => e.type === ENUM.PROJECT_TYPE.REFERENCE);
      dataList = [...dataList, ...sortData.filter(e => e.type !== ENUM.PROJECT_TYPE.REFERENCE)];
      localStorage.setItem('project', JSON.stringify(dataList))
      this.setState({ data: dataList, isLoading: false })
    } else {
      this.setState({ data: this.state.data, isLoading: false })
    }


    // if (data && data.length) {
    //   this.setState({ data });
    // } else {
    //   const data = {
    //     id: 1,
    //     name: 'Babellow',
    //     location: 'Bangkok, Thailand',
    //     area_size: '1,000 sq.m',
    //     product: 'ZINCALUME',
    //     description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.',
    //   }
    //   localStorage.setItem('project', JSON.stringify([data]))
    //   this.setState({ data: [data] });
    // }
  }


  handleBack() {
    redirect(ROUTE_PATH.HOME.LINK);
  }


  handleSearch() {
    redirect(`${ROUTE_PATH.PROJECT_SEARCH.LINK}/${this.state.id}`);
  }

  render() {
    const {
      data,
    } = this.state;
    console.log('data', data);
    return (
      <TransitionContainer
      // motion='overlap-from'
      >
        <Container
          width={VARIABLES.WEBSITE_WIDTH}
          calcMinHeight='100vh'
          bgColor={VARIABLES.COLORS.LIGHT_BLUE_2}
        >
          <Container
            bgColor={VARIABLES.COLORS.LIGHT_BLUE_2}
          >
            <NavigationBar
              shadow
              iconWidth='18'
              iconHeight='18'
              iconSrc={ICONS['ic-navigation-myproject.svg']}
              pageTitleIcon={getLanguage('myProjects.title')}
              actionLeft={
                <Image
                  widthRatio='24'
                  widthRatioUnit='px'
                  heightRatio='23'
                  heightRatioUnit='px'
                  src={ICONS['ic-home-black.svg']}
                  onClick={this.handleBack.bind(this)}
                />
              }
              actionRight={
                <Image
                  widthRatio='21'
                  widthRatioUnit='px'
                  heightRatio='21'
                  heightRatioUnit='px'
                  src={ICONS['ic-search-black.svg']}
                  onClick={this.handleSearch.bind(this)}
                />
              }
            />
            <Section
              paddingHorizontal='20'
              paddingTop='23'
              paddingBottom='127'
            >
              {<Loader loading={this.state.isLoading} paddingTop={30} widthRatio={50} heightRatio={50} />}
              {data.map((data, index) => {
                const isSample = data.type === ENUM.PROJECT_TYPE.REFERENCE;
                return (
                  <Section
                    className='is-my-project'
                    spacingBottom='20'
                    bgColor={VARIABLES.COLORS.WHITE}
                    paddingVertical='10'
                    paddingLeft='10'
                    paddingRight='30'
                    key={index}
                    position='sticky'
                    borderRadius='6'
                    onClick={() => {
                      redirect(`${ROUTE_PATH.PROJECT_DETAIL.LINK}/${data.id}`)
                    }}
                  >
                    <Grid gutter='12' align='center'>
                      <Grid.Column>
                        <Section relative>
                          <Image
                            widthRatio='60'
                            widthRatioUnit='px'
                            heightRatio='60'
                            heightRatioUnit='px'
                            borderRadius='5'
                            src={data.image_url ? data.image_url[0] : 'https://www.amrita.edu/sites/default/files/school-of-business-research.jpg'}
                          />
                          {isSample &&
                            <ShapeContainer
                              className='is-badge-thumbnail'
                              justify='center'
                              align='center'
                              width='28'
                              height='28'
                              bgColor={VARIABLES.COLORS.GREEN_2}
                              borderRadius='14'
                            >
                              <Text
                                fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_10}
                                color={VARIABLES.COLORS.WHITE}
                              >
                                {getLanguage('field.labelSample')}
                              </Text>
                            </ShapeContainer>
                          }
                        </Section>
                      </Grid.Column>
                      <Grid.Column grid={7}>
                        <Section>
                          <Text
                            fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_15}
                            ellipsis
                          >
                            {data.name}
                          </Text>
                        </Section>
                        <Grid gutter='5'>
                          <Grid.Column>
                            <Image
                              widthRatio='11'
                              widthRatioUnit='px'
                              heightRatio='13'
                              heightRatioUnit='px'
                              top='-2'
                              src={ICONS['ic-pin-blue.svg']}
                            />
                          </Grid.Column>
                          <Grid.Column>
                            <Text
                              fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_12}
                              color={VARIABLES.COLORS.BLUE_2}
                              ellipsis
                            >
                              {data.location}
                            </Text>
                          </Grid.Column>
                        </Grid>
                      </Grid.Column>
                      <Grid.Column grid={1} className='is-right'>
                        <Section
                          width='48'
                          height='100'
                          heightUnit='%'
                          justify='center'
                          align='center'
                        >
                          <ShapeContainer
                            justify='center'
                            align='center'
                            width='18'
                            height='18'
                            bgColor={VARIABLES.COLORS.BLUE_2}
                            borderRadius='9'
                          >
                            <Image
                              widthRatio='8'
                              widthRatioUnit='px'
                              heightRatio='8'
                              heightRatioUnit='px'
                              src={ICONS['ic-arrow-more.svg']}
                              left='-1'
                            />
                          </ShapeContainer>
                        </Section>
                      </Grid.Column>
                    </Grid>
                  </Section>
                )
              })}
            </Section>
            <Section
              className='is-floating-footer-button'
              justify='center'
              align='flex-end'
            >
              <Button
                shadow
                className='is-gradient-blue is-icon-front'
                width='275'
                height={VARIABLES.BUTTON.HEIGHTS.H_40}
                borderRadius={VARIABLES.BUTTON.BORDER_RADIUSES.BR_10}
                onClick={() => redirect(`/projects/${this.state.id}/add`)}
              >
                <Button.Icon>
                  <Image
                    widthRatio='17'
                    widthRatioUnit='px'
                    heightRatio='17'
                    heightRatioUnit='px'
                    top='-2'
                    src={ICONS['ic-plus-circle.png']}
                  />
                </Button.Icon>
                <Text
                  fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_16}
                  color={VARIABLES.COLORS.WHITE}
                >
                  {getLanguage('field.buttonAddNewProject')}
                </Text>
              </Button>
            </Section>
          </Container>
        </Container>
      </TransitionContainer>
    )
  }
}

