import React from 'react'
import _ from 'lodash'
import {
  SwiperCarousel,
  Section,
} from '../../components'
import {
  DealerCard,
} from './DealerCard'

export class DealerList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeIndex: 0,
      userLatitude: 0,
      userLongitude: 0,
    }
  }

  componentDidMount() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(this.setPosition)
    }
  }

  setPosition = (position) => {
    this.setState({
      userLatitude: position.coords.latitude,
      userLongitude: position.coords.longitude,
    })
  }

  // for access refs of a child component
  setRef(input) {
    this.childRef = input;
  }

  setActiveIndex(activeIndex) {
    this.setState({ activeIndex })
  }

  checkChildRef() {
    return this.childRef && this.childRef.swiper;
  }

  goNext() {
    if (this.checkChildRef()) {
      this.childRef.swiper.slideNext()
      this.setActiveIndex(this.childRef.swiper.activeIndex)
    };
  }

  goPrev() {
    if (this.checkChildRef()) {
      this.childRef.swiper.slidePrev()
      this.setActiveIndex(this.childRef.swiper.activeIndex)
    }

  }

  slideChange() {
    if (this.checkChildRef()) {
      this.setActiveIndex(this.childRef.swiper.activeIndex)
    }
  }

  render() {
    const { list, type } = this.props
    const { activeIndex, userLatitude, userLongitude } = this.state
    if (_.isEmpty(list)) {
      return null
    }

    const options = {
      pagination: {
        clickable: false,
      },
      spaceBetween: 10,
      centeredSlides: true,
      slidesPerView: 'auto',
      // noSwiping: true,
      on: {
        slideChange: () => { this.slideChange() }
      }
    };

    const renderList = list.map((data, index) => {
      if (index === activeIndex) {
        return (
          <SwiperCarousel.Item
            key={index}
          >
            <DealerCard
              type={type}
              data={data}
              index={index + 1}
              userLatitude={userLatitude}
              userLongitude={userLongitude}
            />
          </SwiperCarousel.Item>
        );
      } else {
        return (
          <SwiperCarousel.Item
            key={index}
            onClick={() => {
              (index < activeIndex) ? this.goPrev() : this.goNext()
            }} >
            <DealerCard
              mini
              data={data}
              index={index + 1}
              userLatitude={userLatitude}
              userLongitude={userLongitude}
            />
          </SwiperCarousel.Item>
        );
      }
    });

    return (
      <Section
        className='is-dealer-list'
      >
        <SwiperCarousel
          className='is-dealer-list'
          setRef={(e) => { this.setRef(e) }}
          options={options}
        >
          {renderList}
        </SwiperCarousel>
      </Section>
    );

  }
}
