import { knowledgeBaseController } from "../../apiService/apiController/knowledgeBaseService";
import { ModelKnowleadgeBase } from "../../models/ModelKnowleadgeBase";
import { isValidResponse } from '../functions';
import { handleTrimParams } from "../functions/data";

export const getAllKnowledgeBaseCategory = async (params) => {
  const knowledgeBaseSsevice = knowledgeBaseController();
  let newParams = handleTrimParams({
    ...params
  })
  const res = await knowledgeBaseSsevice.getAllKnowledgeBaseCategory(newParams);
  if (isValidResponse(res)) {
    let dataList = res.data.map((e) => new ModelKnowleadgeBase(e));
    console.log('getAllKnowledgeBaseCategory', res.data, dataList);
    return dataList;
  }
  return [];
}