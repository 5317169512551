import React from 'react'
import {
  TransitionContainer,
  Container,
  Image,
  Section,
  Text,
  Grid,
  NavigationBar,
} from './../../components'
import {
  VARIABLES,
  TYPOGRAPHYS,
  ICONS,
} from './../../themes'
import {
  ROUTE_PATH,
  redirect,
} from './../../helpers'
import { getLanguage } from '../../helpers/functions/language';
import { TEXT_VERSION } from '../../config/config';

export class SettingPage extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      list: [{
        title: getLanguage('setting.language'),
        link: ROUTE_PATH.SETTING_LANGUAGE.LINK,
        icon: {
          url: ICONS['ic-translation-blue.svg'],
          width: '24',
          height: '24',
        },
      }, {
        title: getLanguage('setting.notification'),
        link: ROUTE_PATH.SETTING_NOTIFICATION.LINK,
        icon: {
          url: ICONS['ic-notification-blue.svg'],
          width: '20',
          height: '25',
        },
      }]
    }
  }

  handleBack() {
    redirect(ROUTE_PATH.HOME.LINK);
  }

  render() {
    const {
      list,
    } = this.state;
    return (
      <TransitionContainer
      // motion='overlap-from'
      >
        <Container
          width={VARIABLES.WEBSITE_WIDTH}
          calcMinHeight='100vh'
          bgColor={VARIABLES.COLORS.LIGHT_BLUE_2}
        >
          <NavigationBar
            shadow
            iconWidth='19'
            iconHeight='19'
            iconSrc={ICONS['ic-navigation-settings.svg']}
            pageTitleIcon={getLanguage('setting.title')}
            actionLeft={
              <Image
                widthRatio='11'
                widthRatioUnit='px'
                heightRatio='21'
                heightRatioUnit='px'
                src={ICONS['ic-left-black.svg']}
                onClick={this.handleBack.bind(this)}
              />
            }
          />
          <Section
            paddingHorizontal='20'
            paddingTop='10'
            position='relative'
            calcMinHeight='calc(100vh - 70px)'
          >
            {list.map((item, index) => (
              <Section
                key={index}
                paddingVertical='22'
                borderBottomColor={VARIABLES.COLORS.BORDER_BLUE_1}
                borderBottomWidth='1'
                borderBottomStyle='solid'
                onClick={() => {
                  redirect(item.link)
                }}
              >
                <Grid gutter='12'>
                  <Grid.Column flex='1'>
                    <Text
                      fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_16}
                      color={VARIABLES.COLORS.BLACK}
                    >
                      {item.title}
                    </Text>
                  </Grid.Column>
                  <Grid.Column flex='none'>
                    <Image
                      widthRatio={item.icon.width}
                      widthRatioUnit='px'
                      heightRatio={item.icon.height}
                      heightRatioUnit='px'
                      src={item.icon.url}
                    />
                  </Grid.Column>
                </Grid>
              </Section>
            ))}
            <Section
              className='is-version-text'
              justify='center'
              position='absolute'
            >
              <Text

                fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_16}
                color={VARIABLES.COLORS.GRAY_4}
              >
                Version {TEXT_VERSION}
              </Text>
            </Section>
          </Section>
        </Container>
      </TransitionContainer>
    )
  }
}
