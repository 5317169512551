import { firebaseUploadFile } from '../firebases/firebaseStorage';
import { ENUM } from '../constants/enum';
import { FIREBASE_PUBLIC_STORAGE_PATH, FIREBASE_STORAGE } from '../../config/config';
import domtoimage from 'dom-to-image';
import { DATA } from '../constants/data';
import { googleGeocode } from '../../config/google';

export const captureHtml2Blob = (dom) => {
  return new Promise((resolve, reject) => {
    domtoimage.toBlob(dom).then((blob) => {
      resolve(blob)
    });
  });
}

export const downloadCaptureHtml = async (dom) => {
  const fileName = new Date().getTime() + '.png';
  const blob = await captureHtml2Blob(dom);
  const link = document.createElement('a');
  link.download = fileName;
  link.href = URL.createObjectURL(blob);
  link.click();
}

export const getBlobtoPath = (blob) => {
  return new Promise(async (resolve, reject) => {
    const fileName = `/share_${new Date().getTime() + '.png'}`;
    const pathUpload = ENUM.UPLOAD_PATH.ESTIMATOR;
    firebaseUploadFile(pathUpload, blob, fileName)
    resolve(`${FIREBASE_PUBLIC_STORAGE_PATH}/${FIREBASE_STORAGE}${ENUM.UPLOAD_PATH.ESTIMATOR}${fileName}`);
  });
}

const getCountryFromGoogleGeoCode = (addrComponents) => {
  for (var i = 0; i < addrComponents.length; i++) {
    if (addrComponents[i].types[0] == "country") {
      return addrComponents[i].short_name;
    }
    if (addrComponents[i].types.length == 2) {
      if (addrComponents[i].types[0] == "political") {
        return addrComponents[i].short_name;
      }
    }
  }
  return false;
}

export const getCurrentCountryLanguage = () => {
  return new Promise(resolve => {
    navigator.geolocation.getCurrentPosition(position => {
      const latitude = position.coords.latitude;
      const longitude = position.coords.longitude;
      googleGeocode.fromLatLng(latitude, longitude).then(
        res => {
          const countryShortName = getCountryFromGoogleGeoCode(res.results[0].address_components);
          console.log('getCountryFromGoogleGeoCode', countryShortName);
          switch (countryShortName) {
            case 'CN':
              resolve(DATA.LANGUAGE.CHINA);
              break;
            case 'TH':
              resolve(DATA.LANGUAGE.THAI);
              break;
            case 'VN':
              resolve(DATA.LANGUAGE.VIETNAM);
              break;
            case 'ID':
              resolve(DATA.LANGUAGE.INDONESIA);
              break;
            case 'MY':
              resolve(DATA.LANGUAGE.MALAYSIA);
              break;
            case 'LA':
              resolve(DATA.LANGUAGE.LAOS);
              break;
            default:
              resolve(DATA.LANGUAGE.THAI);
              break;
          }
        },
        err => {
          console.log('googleGeocode error', err);
          resolve(DATA.LANGUAGE.THAI);
        }
      );
    }, err => {
      console.log('getCurrentPosition error', err);
      resolve(DATA.LANGUAGE.THAI);
    })
  });
}