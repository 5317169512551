import React from 'react'
import {
  TransitionContainer,
  Container,
  Image,
  Section,
  Text,
  Button,
} from './../../components'
import {
  VARIABLES,
  TYPOGRAPHYS,
  CONTENTS,
  ICONS,
} from './../../themes'
import {
  ROUTE_PATH,
  redirect,
} from './../../helpers'
import { LOCAL_STORAGE } from '../../helpers/constants/localStorage';
import { handleWatchNoti } from '../../helpers/firebases/firebaseNotification';
import { getLanguage } from '../../helpers/functions/language';
import config from '../../config';
import axios from 'axios';
import { isValidResponse, redirectReplace, getRouteRefreshWithAuthApp, getRouteParams } from '../../helpers'
import { getLocalStorage, removeLocalStorage, setLocalStorage } from '../../helpers/functions/localStorage';
import { isShowLoading, onOpenAlert } from '../../helpers/functions/main';
import { setUerAuthLocalStorage } from '../../helpers/functions/data';
import { DATA } from '../../helpers/constants/data';

export class AuthenticationSuccessPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {}

  render() {
    const {} = this.state;
    return (
      <TransitionContainer>
        <Container
          width={VARIABLES.WEBSITE_WIDTH}
          height='100'
          heightUnit='vh'
          bgColor={VARIABLES.COLORS.LIGHT_BLUE_2}
          justify='center'
        >
          <Section
            spacingBottom='30'
            justify='center'
          >
            <img src={ICONS['ic-authentication-success.png']} width='163' height='144' alt='Icon' />
          </Section>
          <Section
            spacingBottom='10'
            justify='center'
          >
            <Text
              fontStyle={TYPOGRAPHYS.FIRST.BOLD.FS_20}
              color={VARIABLES.COLORS.GREEN_5}
              align='center'
            >
              {getLanguage('authenticationSuccess.message_1')}
            </Text>
          </Section>
          <Section
            spacingBottom='60'
            justify='center'
          >
            <Text
              fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_16}
              color={VARIABLES.COLORS.GRAY_3}
              align='center'
            >
              {getLanguage('authenticationSuccess.message_2')}
            </Text>
          </Section>
        </Container>
      </TransitionContainer>
    )
  }
}
