import React from 'react'
import {
  connect,
} from 'react-redux';
import { Alert } from '../../components';

export class AlertModalContainer extends React.Component {

  constructor(props) {
    super(props);
    this.state = {}
  }

  render() {
    const {
      isOpen,
      title,
      type,
      message,
      onClickClose = () => { },
    } = this.props;
    return isOpen ?
      <Alert open
        type={type}
        title={title}
        description={message}
        onClose={onClickClose}
      />
      :
      null
  }
}

const mapStateToProps = (state) => {
  const mainRedux = state.mainRedux;
  const alertData = mainRedux.alertData;
  return {
    isOpen: alertData.isOpen,
    type: alertData.type,
    title: alertData.title,
    message: alertData.message,
    onClickClose: alertData.onClickClose,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
  }
}

export const AlertModalContainerConnected = connect(
  mapStateToProps,
  mapDispatchToProps
)(AlertModalContainer)