import apiService from "../apiService";

export const orderInquiryController = (configService = {}) => {
  const service = apiService(configService);
  return {
    getOrderInquiryByUserId: (params) => {
      return service.post(`/api/orderinq/get/${params.user_id}`, params)
    },
    createOrderInquiry: (params) => {
      return service.post(`/api/orderinq/${params.user_id}`, params)
    },
  }
}