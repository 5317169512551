import React from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import {
  TransitionContainer,
  Container,
  Image,
  Section,
  Text,
  Grid,
  CommentList,
} from './../../components';
import {
  VARIABLES,
  TYPOGRAPHYS,
  CONTENTS,
  ICONS,
} from './../../themes';
import {
  ROUTE_PATH,
  redirect,
} from './../../helpers'

export class PublicNewsAndPromotionsCommentPage extends React.Component {

  constructor(props){
    super(props);
    this.state={
      // state
    };
  }

  render() {
    const {
      // state
    } = this.state

    return (
      <TransitionContainer
        // motion='overlap-from'
      >
        <Container
          calcMinHeight='100vh'
          bgColor={VARIABLES.COLORS.GRAY_5}
        >
          <Scrollbars style={{ height: '100vh' }}>
            <Container
              relative
              width='640'
              calcMinHeight='100vh'
              paddingTop='83'
              paddingBottom='35'
              paddingHorizontal='35'
              bgColor={VARIABLES.COLORS.WHITE}
            >
              <Section
                className='is-public-back-button'
                maxWidth='640'
                paddingTop='35'
                paddingBottom='35'
                paddingHorizontal='35'
                bgColor={VARIABLES.COLORS.WHITE}
                align='flex-end'
                onClick={() => {
                  redirect(`${ROUTE_PATH.PUBLIC_NEWS_PROMOTIONS.LINK}/0`)
                }}
              >
                <Grid gutter='15'>
                  <Grid.Column>
                    <Image
                      widthRatio='11'
                      widthRatioUnit='px'
                      heightRatio='21'
                      heightRatioUnit='px'
                      src={ICONS['ic-left-black.svg']}
                    />
                  </Grid.Column>
                  <Grid.Column>
                    <Text
                      fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_18}
                      color={VARIABLES.COLORS.BLACK}
                      top='-1'
                    >
                      Back
                    </Text>
                  </Grid.Column>
                </Grid>
              </Section>
              <CommentList
                className='is-public't
                userAvatar={CONTENTS['user-comment-1.jpg']}
                username='Liza Hall'
                date='01/02/2019'
                commentTitle='Awesome!!!'
                comment='Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s. Lorem Ipsum is simply dummy text of the printing'
              />
              <CommentList
                className='is-public'
                userAvatar={CONTENTS['user-comment-2.jpg']}
                username='Carolyne Grat'
                date='01/02/2019'
                commentTitle='Very Good'
                comment='Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s. Lorem Ipsum is simply dummy text of the printing'
              />
              <CommentList
                className='is-public'
                userAvatar={CONTENTS['user-comment-3.jpg']}
                username='Ethan Lin'
                date='01/02/2019'
                commentTitle='Goodjob'
                comment='Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s. Lorem Ipsum is simply dummy text of the printing'
              />
              <CommentList
                className='is-public'
                userAvatar={CONTENTS['user-comment-4.jpg']}
                username='Kristin Tomson'
                date='01/02/2019'
                commentTitle='Awesome!!!'
                comment='Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s. Lorem Ipsum is simply dummy text of the printing'
              />
              <CommentList
                className='is-public't
                userAvatar={CONTENTS['user-comment-1.jpg']}
                username='Liza Hall'
                date='01/02/2019'
                commentTitle='Awesome!!!'
                comment='Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s. Lorem Ipsum is simply dummy text of the printing'
              />
              <CommentList
                className='is-public'
                userAvatar={CONTENTS['user-comment-2.jpg']}
                username='Carolyne Grat'
                date='01/02/2019'
                commentTitle='Very Good'
                comment='Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s. Lorem Ipsum is simply dummy text of the printing'
              />
              <CommentList
                className='is-public'
                userAvatar={CONTENTS['user-comment-3.jpg']}
                username='Ethan Lin'
                date='01/02/2019'
                commentTitle='Goodjob'
                comment='Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s. Lorem Ipsum is simply dummy text of the printing'
              />
              <CommentList
                className='is-public'
                userAvatar={CONTENTS['user-comment-4.jpg']}
                username='Kristin Tomson'
                date='01/02/2019'
                commentTitle='Awesome!!!'
                comment='Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s. Lorem Ipsum is simply dummy text of the printing'
              />
            </Container>
          </Scrollbars>
        </Container>
      </TransitionContainer>
    )
  }
}
