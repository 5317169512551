import apiService from '../apiService'

export const pointController = (configService = {}) => {
  const service = apiService(configService)
  return {
    pointUserById: (params = {}) => {
      return service.get(`/api/points/user/${params.sfid}`)
    },
  }
}

