import { orderInquiryController } from "../../apiService/apiController/orderInquiryService";
import { isValidResponse } from "../functions";
import { handleTrimParams } from "../functions/data";
import _ from "lodash";

export const getOrderInquiryByUserId = async (params) => {
  const orderInquiryService = orderInquiryController();
  let newParam = handleTrimParams({
    ...params
  });
  const res = await orderInquiryService.getOrderInquiryByUserId(newParam);
  if (isValidResponse(res)) {
    let dataList = res.data;
    console.log('getOrderInquiryByUserId', res.data, dataList);
    return dataList;
  }
  return [];
}

export const createOrderInquiry = async (params) => {
  const orderInquiryService = orderInquiryController();
  let newParam = handleTrimParams({
    ...params
  });
  const res = await orderInquiryService.createOrderInquiry(newParam);
  if (isValidResponse(res)) {
    let data = res.data;
    console.log('createOrderInquiry', res.data, data);
    return data;
  }
}