
export class ModelKnowleadgeBase {
  constructor(props) {
    props = props || {};
    this.id = props.id;
    this.name = props.name;
    this.description = props.description;
    this.status = props.status;
    this.cover = props.cover;
    this.available_country = props.available_country
    this.created_by_admin_role = props.created_by_admin_role;
    this.knowledge = (props.knowledge || []).map((e) => {
      return new ModelKnowledge(e);
    })
  }
}

export class ModelKnowledge {
  constructor(props) {
    props = props || {};
    this.id = props.id;
    this.topic_name = props.topic_name;
    this.gallery = props.gallery
    this.status = props.status;
    this.category_id = props.category_id;
    this.sub_topic = (props.sub_topic || []).map((e) => {
      return new ModelSubtopic(e);
    });
    this.document = (props.document || []).map((e) => {
      return new ModelDocument(e)
    })
    this.available_country = props.available_country;
    this.created_by_admin_role = props.created_by_admin_role;
  }
}

export class ModelDocument {
  constructor(props) {
    props = props || {};
    this.file = props.file;
    this.name = props.name;
  }
}

export class ModelSubtopic {
  constructor(props) {
    props = props || {};
    this.description = props.description;
    this.sub_topic_name = props.sub_topic_name;
  }
}