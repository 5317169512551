import React from 'react'
import _ from 'lodash'
import {
  Section,
  Text,
  Grid,
  Image,
} from '../../components'
import {
  VARIABLES,
  TYPOGRAPHYS,
  LOGOS,
  ICONS,
} from '../../themes'
import { getLanguage } from '../../helpers/functions/language'

export class CategoryItem extends React.Component {
  render() {
    const {
      data,
      categoryList,
    } = this.props
    return (
      <Section
        bgColor={VARIABLES.COLORS.LIGHT_BLUE_1}
        paddingHorizontal='13'
        paddingTop='23'
        paddingBottom='23'
        spacingBottom='25'
        relative
      >
        <Section
          position='absolute'
          justify='center'
          align='center'
          height='100'
          heightUnit='%'
          width='calc(100% - 13px)'
          widthUnit=''
        >
          <Image
            widthRatio='72'
            widthRatioUnit='px'
            heightRatio='71'
            heightRatioUnit='px'
            src={LOGOS['logo-fade.svg']}
          />
        </Section>
        <Section
          bgColor={VARIABLES.COLORS.BLUE_1}
          borderRadius={VARIABLES.BUTTON.BORDER_RADIUSES.BR_10}
          paddingHorizontal='13'
          paddingVertical='7'
          spacingBottom='13'
        >
          <Grid >
            <Grid.Column flex='2'>
              <Text
                fontStyle={TYPOGRAPHYS.FIRST.BOLD.FS_12}
                color={VARIABLES.COLORS.WHITE}
              >
                {getLanguage('estimatorSummary.category.type')}
              </Text>
            </Grid.Column>
            <Grid.Column flex='2'>
              <Text
                fontStyle={TYPOGRAPHYS.FIRST.BOLD.FS_12}
                color={VARIABLES.COLORS.WHITE}
              >
                {getLanguage('estimatorSummary.category.profile')}
              </Text>
            </Grid.Column>
            <Grid.Column flex='1'>
              <Text
                fontStyle={TYPOGRAPHYS.FIRST.BOLD.FS_12}
                color={VARIABLES.COLORS.WHITE}
              >
                {getLanguage('estimatorSummary.category.qty')}
              </Text>
            </Grid.Column>
            <Grid.Column flex='end'>
              <Text
                fontStyle={TYPOGRAPHYS.FIRST.BOLD.FS_12}
                color={VARIABLES.COLORS.WHITE}
              >
                {getLanguage('estimatorSummary.category.unit')}
              </Text>
            </Grid.Column>
          </Grid>
        </Section>
        {categoryList.map((item, index) => (
          <Section
            key={index}
            paddingHorizontal='13'
            paddingVertical='7'
            borderBottomWidth='1'
            borderBottomStyle='solid'
            borderBottomColor={VARIABLES.COLORS.GRAY_4}
          >
            <Grid gutter='10' >
              <Grid.Column flex='2'>
                <Text
                  fontStyle={TYPOGRAPHYS.FIRST.BOLD.FS_12}
                  color={VARIABLES.COLORS.GRAY_3}
                >
                  {item.type}
                </Text>
              </Grid.Column>
              <Grid.Column flex='2'>
                <Text
                  fontStyle={TYPOGRAPHYS.FIRST.BOLD.FS_12}
                  color={VARIABLES.COLORS.GRAY_3}
                >
                  {item.profile}
                </Text>
              </Grid.Column>
              <Grid.Column flex='1'>
                <Text
                  fontStyle={TYPOGRAPHYS.FIRST.BOLD.FS_12}
                  color={VARIABLES.COLORS.GRAY_3}
                >
                  {item.qty}
                </Text>
              </Grid.Column>
              <Grid.Column flex='end'>
                <Text
                  fontStyle={TYPOGRAPHYS.FIRST.BOLD.FS_12}
                  color={VARIABLES.COLORS.GRAY_3}
                >
                  {item.unit}
                </Text>
              </Grid.Column>
            </Grid>
          </Section>))}
        {data.note && <Section
          spacingTop='20'
          spacingBottom='10'>
          <Grid gutter='7' align='center'>
            <Grid.Column flex='none'>
              <Image
                widthRatio='13'
                widthRatioUnit='px'
                heightRatio='13'
                heightRatioUnit='px'
                src={ICONS['ic-info-yellow.svg']}
              />
            </Grid.Column>
            <Grid.Column flex='none'>
              <Text
                fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_16}
                color={VARIABLES.COLORS.YELLOW_1}
              >
                {getLanguage('estimatorSummary.category.notes')}
              </Text>
            </Grid.Column>
          </Grid>
          <Text
            fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_12}
            color={VARIABLES.COLORS.YELLOW_1}
          >
            {data.note}
          </Text>
        </Section>}
      </Section>
    )
  }
}