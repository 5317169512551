//https://en.wikipedia.org/wiki/List_of_districts_of_Vietnam#H%E1%BB%93_Ch%C3%AD_Minh_City
// var provinceCode = ''
// Array.from(document.getElementById('test').children).forEach(e => {
//   if (e.localName === 'h2') {
//     provinceCode = e.innerText
//   }
//   if (e.localName === 'ul') {
//     Array.from(e.children).forEach(el => {
//       if (el.firstElementChild.localName === 'ul') {
//         Array.from(el.children).forEach(elu => {
//           Array.from(elu.children).forEach(elul => {
//             x.push({
//               city: elul.innerText,
//               cityCode: elul.innerText,
//               provinceCode,
//             })
//           })
//         })
//       }
//       else {
//         x.push({
//           city: el.innerText,
//           cityCode: el.innerText,
//           provinceCode,
//         })
//       }
//     })
//   }
// })
export const COUNTRY_VIETNAM = {
  //state
  PROVINCE_LIST: [
    {
      "province": "An Giang Province",
      "provinceCode": "An Giang Province"
    },
    {
      "province": "Bà Rịa–Vũng Tàu Province",
      "provinceCode": "Bà Rịa–Vũng Tàu Province"
    },
    {
      "province": "Bắc Giang Province",
      "provinceCode": "Bắc Giang Province"
    },
    {
      "province": "Bắc Kạn Province",
      "provinceCode": "Bắc Kạn Province"
    },
    {
      "province": "Bạc Liêu Province",
      "provinceCode": "Bạc Liêu Province"
    },
    {
      "province": "Bắc Ninh Province",
      "provinceCode": "Bắc Ninh Province"
    },
    {
      "province": "Bến Tre Province",
      "provinceCode": "Bến Tre Province"
    },
    {
      "province": "Bình Định Province",
      "provinceCode": "Bình Định Province"
    },
    {
      "province": "Bình Dương Province",
      "provinceCode": "Bình Dương Province"
    },
    {
      "province": "Bình Phước Province",
      "provinceCode": "Bình Phước Province"
    },
    {
      "province": "Bình Thuận Province",
      "provinceCode": "Bình Thuận Province"
    },
    {
      "province": "Cà Mau Province",
      "provinceCode": "Cà Mau Province"
    },
    {
      "province": "Cần Thơ",
      "provinceCode": "Cần Thơ"
    },
    {
      "province": "Cao Bằng Province",
      "provinceCode": "Cao Bằng Province"
    },
    {
      "province": "Đà Nẵng",
      "provinceCode": "Đà Nẵng"
    },
    {
      "province": "Đắk Lắk Province",
      "provinceCode": "Đắk Lắk Province"
    },
    {
      "province": "Đắk Nông Province",
      "provinceCode": "Đắk Nông Province"
    },
    {
      "province": "Điện Biên Province",
      "provinceCode": "Điện Biên Province"
    },
    {
      "province": "Đồng Nai Province",
      "provinceCode": "Đồng Nai Province"
    },
    {
      "province": "Đồng Tháp Province",
      "provinceCode": "Đồng Tháp Province"
    },
    {
      "province": "Gia Lai Province",
      "provinceCode": "Gia Lai Province"
    },
    {
      "province": "Hà Giang Province",
      "provinceCode": "Hà Giang Province"
    },
    {
      "province": "Hà Nam Province",
      "provinceCode": "Hà Nam Province"
    },
    {
      "province": "Hà Nội",
      "provinceCode": "Hà Nội"
    },
    {
      "province": "Hà Tĩnh Province",
      "provinceCode": "Hà Tĩnh Province"
    },
    {
      "province": "Hải Dương Province",
      "provinceCode": "Hải Dương Province"
    },
    {
      "province": "Hải Phòng",
      "provinceCode": "Hải Phòng"
    },
    {
      "province": "Hậu Giang Province",
      "provinceCode": "Hậu Giang Province"
    },
    {
      "province": "Hồ Chí Minh City",
      "provinceCode": "Hồ Chí Minh City"
    },
    {
      "province": "Hòa Bình Province",
      "provinceCode": "Hòa Bình Province"
    },
    {
      "province": "Hưng Yên Province",
      "provinceCode": "Hưng Yên Province"
    },
    {
      "province": "Khánh Hòa Province",
      "provinceCode": "Khánh Hòa Province"
    },
    {
      "province": "Kiên Giang Province",
      "provinceCode": "Kiên Giang Province"
    },
    {
      "province": "Kon Tum Province",
      "provinceCode": "Kon Tum Province"
    },
    {
      "province": "Lai Châu Province",
      "provinceCode": "Lai Châu Province"
    },
    {
      "province": "Lâm Đồng Province",
      "provinceCode": "Lâm Đồng Province"
    },
    {
      "province": "Lạng Sơn Province",
      "provinceCode": "Lạng Sơn Province"
    },
    {
      "province": "Lào Cai Province",
      "provinceCode": "Lào Cai Province"
    },
    {
      "province": "Long An Province",
      "provinceCode": "Long An Province"
    },
    {
      "province": "Nam Định Province",
      "provinceCode": "Nam Định Province"
    },
    {
      "province": "Nghệ An Province",
      "provinceCode": "Nghệ An Province"
    },
    {
      "province": "Ninh Bình Province",
      "provinceCode": "Ninh Bình Province"
    },
    {
      "province": "Ninh Thuận Province",
      "provinceCode": "Ninh Thuận Province"
    },
    {
      "province": "Phú Thọ Province",
      "provinceCode": "Phú Thọ Province"
    },
    {
      "province": "Phú Yên Province",
      "provinceCode": "Phú Yên Province"
    },
    {
      "province": "Quảng Bình Province",
      "provinceCode": "Quảng Bình Province"
    },
    {
      "province": "Quảng Nam Province",
      "provinceCode": "Quảng Nam Province"
    },
    {
      "province": "Quảng Ngãi Province",
      "provinceCode": "Quảng Ngãi Province"
    },
    {
      "province": "Quảng Ninh Province",
      "provinceCode": "Quảng Ninh Province"
    },
    {
      "province": "Quảng Trị Province",
      "provinceCode": "Quảng Trị Province"
    },
    {
      "province": "Sóc Trăng Province",
      "provinceCode": "Sóc Trăng Province"
    },
    {
      "province": "Sơn La Province",
      "provinceCode": "Sơn La Province"
    },
    {
      "province": "Tây Ninh Province",
      "provinceCode": "Tây Ninh Province"
    },
    {
      "province": "Thái Bình Province",
      "provinceCode": "Thái Bình Province"
    },
    {
      "province": "Thái Nguyên Province",
      "provinceCode": "Thái Nguyên Province"
    },
    {
      "province": "Thanh Hóa Province",
      "provinceCode": "Thanh Hóa Province"
    },
    {
      "province": "Thừa Thiên–Huế Province",
      "provinceCode": "Thừa Thiên–Huế Province"
    },
    {
      "province": "Tiền Giang Province",
      "provinceCode": "Tiền Giang Province"
    },
    {
      "province": "Trà Vinh Province",
      "provinceCode": "Trà Vinh Province"
    },
    {
      "province": "Tuyên Quang Province",
      "provinceCode": "Tuyên Quang Province"
    },
    {
      "province": "Vĩnh Long Province",
      "provinceCode": "Vĩnh Long Province"
    },
    {
      "province": "Vĩnh Phúc Province",
      "provinceCode": "Vĩnh Phúc Province"
    },
    {
      "province": "Yên Bái Province",
      "provinceCode": "Yên Bái Province"
    }
  ],
  //amphoe
  //district
  CITY_LIST: [
    {
      "city": "An Phú",
      "cityCode": "An Phú",
      "provinceCode": "An Giang Province"
    },
    {
      "city": "Châu Đốc city",
      "cityCode": "Châu Đốc city",
      "provinceCode": "An Giang Province"
    },
    {
      "city": "Châu Phú",
      "cityCode": "Châu Phú",
      "provinceCode": "An Giang Province"
    },
    {
      "city": "Châu Thành",
      "cityCode": "Châu Thành",
      "provinceCode": "An Giang Province"
    },
    {
      "city": "Chợ Mới",
      "cityCode": "Chợ Mới",
      "provinceCode": "An Giang Province"
    },
    {
      "city": "Long Xuyên city",
      "cityCode": "Long Xuyên city",
      "provinceCode": "An Giang Province"
    },
    {
      "city": "Phú Tân",
      "cityCode": "Phú Tân",
      "provinceCode": "An Giang Province"
    },
    {
      "city": "Tân Châu town",
      "cityCode": "Tân Châu town",
      "provinceCode": "An Giang Province"
    },
    {
      "city": "Thoại Sơn",
      "cityCode": "Thoại Sơn",
      "provinceCode": "An Giang Province"
    },
    {
      "city": "Tịnh Biên",
      "cityCode": "Tịnh Biên",
      "provinceCode": "An Giang Province"
    },
    {
      "city": "Tri Tôn",
      "cityCode": "Tri Tôn",
      "provinceCode": "An Giang Province"
    },
    {
      "city": "Bà Rịa city",
      "cityCode": "Bà Rịa city",
      "provinceCode": "Bà Rịa–Vũng Tàu Province"
    },
    {
      "city": "Châu Đức",
      "cityCode": "Châu Đức",
      "provinceCode": "Bà Rịa–Vũng Tàu Province"
    },
    {
      "city": "Côn Đảo Island",
      "cityCode": "Côn Đảo Island",
      "provinceCode": "Bà Rịa–Vũng Tàu Province"
    },
    {
      "city": "Đất Đỏ",
      "cityCode": "Đất Đỏ",
      "provinceCode": "Bà Rịa–Vũng Tàu Province"
    },
    {
      "city": "Long Điền",
      "cityCode": "Long Điền",
      "provinceCode": "Bà Rịa–Vũng Tàu Province"
    },
    {
      "city": "Phú Mỹ town",
      "cityCode": "Phú Mỹ town",
      "provinceCode": "Bà Rịa–Vũng Tàu Province"
    },
    {
      "city": "Vũng Tàu city",
      "cityCode": "Vũng Tàu city",
      "provinceCode": "Bà Rịa–Vũng Tàu Province"
    },
    {
      "city": "Xuyên Mộc",
      "cityCode": "Xuyên Mộc",
      "provinceCode": "Bà Rịa–Vũng Tàu Province"
    },
    {
      "city": "Bắc Giang city",
      "cityCode": "Bắc Giang city",
      "provinceCode": "Bắc Giang Province"
    },
    {
      "city": "Hiệp Hòa",
      "cityCode": "Hiệp Hòa",
      "provinceCode": "Bắc Giang Province"
    },
    {
      "city": "Lạng Giang",
      "cityCode": "Lạng Giang",
      "provinceCode": "Bắc Giang Province"
    },
    {
      "city": "Lục Nam",
      "cityCode": "Lục Nam",
      "provinceCode": "Bắc Giang Province"
    },
    {
      "city": "Lục Ngạn",
      "cityCode": "Lục Ngạn",
      "provinceCode": "Bắc Giang Province"
    },
    {
      "city": "Sơn Động",
      "cityCode": "Sơn Động",
      "provinceCode": "Bắc Giang Province"
    },
    {
      "city": "Tân Yên",
      "cityCode": "Tân Yên",
      "provinceCode": "Bắc Giang Province"
    },
    {
      "city": "Việt Yên",
      "cityCode": "Việt Yên",
      "provinceCode": "Bắc Giang Province"
    },
    {
      "city": "Yên Dũng",
      "cityCode": "Yên Dũng",
      "provinceCode": "Bắc Giang Province"
    },
    {
      "city": "Yên Thế",
      "cityCode": "Yên Thế",
      "provinceCode": "Bắc Giang Province"
    },
    {
      "city": "Ba Bể",
      "cityCode": "Ba Bể",
      "provinceCode": "Bắc Kạn Province"
    },
    {
      "city": "Bắc Kạn city",
      "cityCode": "Bắc Kạn city",
      "provinceCode": "Bắc Kạn Province"
    },
    {
      "city": "Bạch Thông",
      "cityCode": "Bạch Thông",
      "provinceCode": "Bắc Kạn Province"
    },
    {
      "city": "Chợ Đồn",
      "cityCode": "Chợ Đồn",
      "provinceCode": "Bắc Kạn Province"
    },
    {
      "city": "Chợ Mới",
      "cityCode": "Chợ Mới",
      "provinceCode": "Bắc Kạn Province"
    },
    {
      "city": "Na Rì",
      "cityCode": "Na Rì",
      "provinceCode": "Bắc Kạn Province"
    },
    {
      "city": "Ngân Sơn",
      "cityCode": "Ngân Sơn",
      "provinceCode": "Bắc Kạn Province"
    },
    {
      "city": "Pác Nặm",
      "cityCode": "Pác Nặm",
      "provinceCode": "Bắc Kạn Province"
    },
    {
      "city": "Bạc Liêu city",
      "cityCode": "Bạc Liêu city",
      "provinceCode": "Bạc Liêu Province"
    },
    {
      "city": "Đông Hải",
      "cityCode": "Đông Hải",
      "provinceCode": "Bạc Liêu Province"
    },
    {
      "city": "Giá Rai town",
      "cityCode": "Giá Rai town",
      "provinceCode": "Bạc Liêu Province"
    },
    {
      "city": "Hòa Bình",
      "cityCode": "Hòa Bình",
      "provinceCode": "Bạc Liêu Province"
    },
    {
      "city": "Hồng Dân",
      "cityCode": "Hồng Dân",
      "provinceCode": "Bạc Liêu Province"
    },
    {
      "city": "Phước Long",
      "cityCode": "Phước Long",
      "provinceCode": "Bạc Liêu Province"
    },
    {
      "city": "Vĩnh Lợi",
      "cityCode": "Vĩnh Lợi",
      "provinceCode": "Bạc Liêu Province"
    },
    {
      "city": "Bắc Ninh city",
      "cityCode": "Bắc Ninh city",
      "provinceCode": "Bắc Ninh Province"
    },
    {
      "city": "Gia Bình",
      "cityCode": "Gia Bình",
      "provinceCode": "Bắc Ninh Province"
    },
    {
      "city": "Lương Tài",
      "cityCode": "Lương Tài",
      "provinceCode": "Bắc Ninh Province"
    },
    {
      "city": "Quế Võ",
      "cityCode": "Quế Võ",
      "provinceCode": "Bắc Ninh Province"
    },
    {
      "city": "Thuận Thành",
      "cityCode": "Thuận Thành",
      "provinceCode": "Bắc Ninh Province"
    },
    {
      "city": "Tiên Du",
      "cityCode": "Tiên Du",
      "provinceCode": "Bắc Ninh Province"
    },
    {
      "city": "Từ Sơn town",
      "cityCode": "Từ Sơn town",
      "provinceCode": "Bắc Ninh Province"
    },
    {
      "city": "Yên Phong",
      "cityCode": "Yên Phong",
      "provinceCode": "Bắc Ninh Province"
    },
    {
      "city": "Ba Tri",
      "cityCode": "Ba Tri",
      "provinceCode": "Bến Tre Province"
    },
    {
      "city": "Bến Tre city",
      "cityCode": "Bến Tre city",
      "provinceCode": "Bến Tre Province"
    },
    {
      "city": "Bình Đại",
      "cityCode": "Bình Đại",
      "provinceCode": "Bến Tre Province"
    },
    {
      "city": "Châu Thành",
      "cityCode": "Châu Thành",
      "provinceCode": "Bến Tre Province"
    },
    {
      "city": "Chợ Lách",
      "cityCode": "Chợ Lách",
      "provinceCode": "Bến Tre Province"
    },
    {
      "city": "Giồng Trôm",
      "cityCode": "Giồng Trôm",
      "provinceCode": "Bến Tre Province"
    },
    {
      "city": "Mỏ Cày Bắc",
      "cityCode": "Mỏ Cày Bắc",
      "provinceCode": "Bến Tre Province"
    },
    {
      "city": "Mỏ Cày Nam",
      "cityCode": "Mỏ Cày Nam",
      "provinceCode": "Bến Tre Province"
    },
    {
      "city": "Thạnh Phú",
      "cityCode": "Thạnh Phú",
      "provinceCode": "Bến Tre Province"
    },
    {
      "city": "An Lão",
      "cityCode": "An Lão",
      "provinceCode": "Bình Định Province"
    },
    {
      "city": "An Nhơn town",
      "cityCode": "An Nhơn town",
      "provinceCode": "Bình Định Province"
    },
    {
      "city": "Hoài Ân",
      "cityCode": "Hoài Ân",
      "provinceCode": "Bình Định Province"
    },
    {
      "city": "Hoài Nhơn",
      "cityCode": "Hoài Nhơn",
      "provinceCode": "Bình Định Province"
    },
    {
      "city": "Phù Cát",
      "cityCode": "Phù Cát",
      "provinceCode": "Bình Định Province"
    },
    {
      "city": "Phù Mỹ",
      "cityCode": "Phù Mỹ",
      "provinceCode": "Bình Định Province"
    },
    {
      "city": "Quy Nhơn city",
      "cityCode": "Quy Nhơn city",
      "provinceCode": "Bình Định Province"
    },
    {
      "city": "Tây Sơn",
      "cityCode": "Tây Sơn",
      "provinceCode": "Bình Định Province"
    },
    {
      "city": "Tuy Phước",
      "cityCode": "Tuy Phước",
      "provinceCode": "Bình Định Province"
    },
    {
      "city": "Vân Canh",
      "cityCode": "Vân Canh",
      "provinceCode": "Bình Định Province"
    },
    {
      "city": "Vĩnh Thạnh",
      "cityCode": "Vĩnh Thạnh",
      "provinceCode": "Bình Định Province"
    },
    {
      "city": "Bắc Tân Uyên",
      "cityCode": "Bắc Tân Uyên",
      "provinceCode": "Bình Dương Province"
    },
    {
      "city": "Bàu Bàng",
      "cityCode": "Bàu Bàng",
      "provinceCode": "Bình Dương Province"
    },
    {
      "city": "Bến Cát town",
      "cityCode": "Bến Cát town",
      "provinceCode": "Bình Dương Province"
    },
    {
      "city": "Dầu Tiếng",
      "cityCode": "Dầu Tiếng",
      "provinceCode": "Bình Dương Province"
    },
    {
      "city": "Dĩ An town",
      "cityCode": "Dĩ An town",
      "provinceCode": "Bình Dương Province"
    },
    {
      "city": "Phú Giáo",
      "cityCode": "Phú Giáo",
      "provinceCode": "Bình Dương Province"
    },
    {
      "city": "Tân Uyên town",
      "cityCode": "Tân Uyên town",
      "provinceCode": "Bình Dương Province"
    },
    {
      "city": "Thủ Dầu Một city",
      "cityCode": "Thủ Dầu Một city",
      "provinceCode": "Bình Dương Province"
    },
    {
      "city": "Thuận An town",
      "cityCode": "Thuận An town",
      "provinceCode": "Bình Dương Province"
    },
    {
      "city": "Bình Long town",
      "cityCode": "Bình Long town",
      "provinceCode": "Bình Phước Province"
    },
    {
      "city": "Bù Đăng",
      "cityCode": "Bù Đăng",
      "provinceCode": "Bình Phước Province"
    },
    {
      "city": "Bù Đốp",
      "cityCode": "Bù Đốp",
      "provinceCode": "Bình Phước Province"
    },
    {
      "city": "Bù Gia Mập",
      "cityCode": "Bù Gia Mập",
      "provinceCode": "Bình Phước Province"
    },
    {
      "city": "Chơn Thành",
      "cityCode": "Chơn Thành",
      "provinceCode": "Bình Phước Province"
    },
    {
      "city": "Đồng Phú",
      "cityCode": "Đồng Phú",
      "provinceCode": "Bình Phước Province"
    },
    {
      "city": "Đồng Xoài city",
      "cityCode": "Đồng Xoài city",
      "provinceCode": "Bình Phước Province"
    },
    {
      "city": "Hớn Quản",
      "cityCode": "Hớn Quản",
      "provinceCode": "Bình Phước Province"
    },
    {
      "city": "Lộc Ninh",
      "cityCode": "Lộc Ninh",
      "provinceCode": "Bình Phước Province"
    },
    {
      "city": "Phú Riềng",
      "cityCode": "Phú Riềng",
      "provinceCode": "Bình Phước Province"
    },
    {
      "city": "Phước Long town",
      "cityCode": "Phước Long town",
      "provinceCode": "Bình Phước Province"
    },
    {
      "city": "Bắc Bình",
      "cityCode": "Bắc Bình",
      "provinceCode": "Bình Thuận Province"
    },
    {
      "city": "Đức Linh",
      "cityCode": "Đức Linh",
      "provinceCode": "Bình Thuận Province"
    },
    {
      "city": "Hàm Tân",
      "cityCode": "Hàm Tân",
      "provinceCode": "Bình Thuận Province"
    },
    {
      "city": "Hàm Thuận Bắc",
      "cityCode": "Hàm Thuận Bắc",
      "provinceCode": "Bình Thuận Province"
    },
    {
      "city": "Hàm Thuận Nam",
      "cityCode": "Hàm Thuận Nam",
      "provinceCode": "Bình Thuận Province"
    },
    {
      "city": "La Gi town",
      "cityCode": "La Gi town",
      "provinceCode": "Bình Thuận Province"
    },
    {
      "city": "Phan Thiết city",
      "cityCode": "Phan Thiết city",
      "provinceCode": "Bình Thuận Province"
    },
    {
      "city": "Phú Quý Island",
      "cityCode": "Phú Quý Island",
      "provinceCode": "Bình Thuận Province"
    },
    {
      "city": "Tánh Linh",
      "cityCode": "Tánh Linh",
      "provinceCode": "Bình Thuận Province"
    },
    {
      "city": "Tuy Phong",
      "cityCode": "Tuy Phong",
      "provinceCode": "Bình Thuận Province"
    },
    {
      "city": "Cà Mau city",
      "cityCode": "Cà Mau city",
      "provinceCode": "Cà Mau Province"
    },
    {
      "city": "Cái Nước",
      "cityCode": "Cái Nước",
      "provinceCode": "Cà Mau Province"
    },
    {
      "city": "Đầm Dơi",
      "cityCode": "Đầm Dơi",
      "provinceCode": "Cà Mau Province"
    },
    {
      "city": "Năm Căn",
      "cityCode": "Năm Căn",
      "provinceCode": "Cà Mau Province"
    },
    {
      "city": "Ngọc Hiển",
      "cityCode": "Ngọc Hiển",
      "provinceCode": "Cà Mau Province"
    },
    {
      "city": "Phú Tân",
      "cityCode": "Phú Tân",
      "provinceCode": "Cà Mau Province"
    },
    {
      "city": "Thới Bình",
      "cityCode": "Thới Bình",
      "provinceCode": "Cà Mau Province"
    },
    {
      "city": "Trần Văn Thời",
      "cityCode": "Trần Văn Thời",
      "provinceCode": "Cà Mau Province"
    },
    {
      "city": "U Minh",
      "cityCode": "U Minh",
      "provinceCode": "Cà Mau Province"
    },
    {
      "city": "Bình Thủy",
      "cityCode": "Bình Thủy",
      "provinceCode": "Cần Thơ"
    },
    {
      "city": "Cái Răng",
      "cityCode": "Cái Răng",
      "provinceCode": "Cần Thơ"
    },
    {
      "city": "Ninh Kiều",
      "cityCode": "Ninh Kiều",
      "provinceCode": "Cần Thơ"
    },
    {
      "city": "Ô Môn",
      "cityCode": "Ô Môn",
      "provinceCode": "Cần Thơ"
    },
    {
      "city": "Thốt Nốt",
      "cityCode": "Thốt Nốt",
      "provinceCode": "Cần Thơ"
    },
    {
      "city": "Cờ Đỏ",
      "cityCode": "Cờ Đỏ",
      "provinceCode": "Cần Thơ"
    },
    {
      "city": "Phong Điền",
      "cityCode": "Phong Điền",
      "provinceCode": "Cần Thơ"
    },
    {
      "city": "Thới Lai",
      "cityCode": "Thới Lai",
      "provinceCode": "Cần Thơ"
    },
    {
      "city": "Vĩnh Thạnh",
      "cityCode": "Vĩnh Thạnh",
      "provinceCode": "Cần Thơ"
    },
    {
      "city": "Bảo Lạc",
      "cityCode": "Bảo Lạc",
      "provinceCode": "Cao Bằng Province"
    },
    {
      "city": "Bảo Lâm",
      "cityCode": "Bảo Lâm",
      "provinceCode": "Cao Bằng Province"
    },
    {
      "city": "Cao Bằng city",
      "cityCode": "Cao Bằng city",
      "provinceCode": "Cao Bằng Province"
    },
    {
      "city": "Hạ Lang",
      "cityCode": "Hạ Lang",
      "provinceCode": "Cao Bằng Province"
    },
    {
      "city": "Hà Quảng",
      "cityCode": "Hà Quảng",
      "provinceCode": "Cao Bằng Province"
    },
    {
      "city": "Hòa An",
      "cityCode": "Hòa An",
      "provinceCode": "Cao Bằng Province"
    },
    {
      "city": "Nguyên Bình",
      "cityCode": "Nguyên Bình",
      "provinceCode": "Cao Bằng Province"
    },
    {
      "city": "Phục Hòa",
      "cityCode": "Phục Hòa",
      "provinceCode": "Cao Bằng Province"
    },
    {
      "city": "Quảng Uyên",
      "cityCode": "Quảng Uyên",
      "provinceCode": "Cao Bằng Province"
    },
    {
      "city": "Thạch An",
      "cityCode": "Thạch An",
      "provinceCode": "Cao Bằng Province"
    },
    {
      "city": "Thông Nông",
      "cityCode": "Thông Nông",
      "provinceCode": "Cao Bằng Province"
    },
    {
      "city": "Trà Lĩnh",
      "cityCode": "Trà Lĩnh",
      "provinceCode": "Cao Bằng Province"
    },
    {
      "city": "Trùng Khánh",
      "cityCode": "Trùng Khánh",
      "provinceCode": "Cao Bằng Province"
    },
    {
      "city": "Cẩm Lệ",
      "cityCode": "Cẩm Lệ",
      "provinceCode": "Đà Nẵng"
    },
    {
      "city": "Hải Châu",
      "cityCode": "Hải Châu",
      "provinceCode": "Đà Nẵng"
    },
    {
      "city": "Liên Chiểu",
      "cityCode": "Liên Chiểu",
      "provinceCode": "Đà Nẵng"
    },
    {
      "city": "Ngũ Hành Sơn",
      "cityCode": "Ngũ Hành Sơn",
      "provinceCode": "Đà Nẵng"
    },
    {
      "city": "Sơn Trà",
      "cityCode": "Sơn Trà",
      "provinceCode": "Đà Nẵng"
    },
    {
      "city": "Thanh Khê",
      "cityCode": "Thanh Khê",
      "provinceCode": "Đà Nẵng"
    },
    {
      "city": "Hòa Vang",
      "cityCode": "Hòa Vang",
      "provinceCode": "Đà Nẵng"
    },
    {
      "city": "Hoàng Sa Island",
      "cityCode": "Hoàng Sa Island",
      "provinceCode": "Đà Nẵng"
    },
    {
      "city": "Buôn Đôn",
      "cityCode": "Buôn Đôn",
      "provinceCode": "Đắk Lắk Province"
    },
    {
      "city": "Buôn Hồ town",
      "cityCode": "Buôn Hồ town",
      "provinceCode": "Đắk Lắk Province"
    },
    {
      "city": "Buôn Ma Thuột city",
      "cityCode": "Buôn Ma Thuột city",
      "provinceCode": "Đắk Lắk Province"
    },
    {
      "city": "Cư M'gar",
      "cityCode": "Cư M'gar",
      "provinceCode": "Đắk Lắk Province"
    },
    {
      "city": "Cư Kuin",
      "cityCode": "Cư Kuin",
      "provinceCode": "Đắk Lắk Province"
    },
    {
      "city": "Ea H'leo",
      "cityCode": "Ea H'leo",
      "provinceCode": "Đắk Lắk Province"
    },
    {
      "city": "Ea Kar",
      "cityCode": "Ea Kar",
      "provinceCode": "Đắk Lắk Province"
    },
    {
      "city": "Ea Súp",
      "cityCode": "Ea Súp",
      "provinceCode": "Đắk Lắk Province"
    },
    {
      "city": "Krông Ana",
      "cityCode": "Krông Ana",
      "provinceCode": "Đắk Lắk Province"
    },
    {
      "city": "Krông Bông",
      "cityCode": "Krông Bông",
      "provinceCode": "Đắk Lắk Province"
    },
    {
      "city": "Krông Buk",
      "cityCode": "Krông Buk",
      "provinceCode": "Đắk Lắk Province"
    },
    {
      "city": "Krông Năng",
      "cityCode": "Krông Năng",
      "provinceCode": "Đắk Lắk Province"
    },
    {
      "city": "Krông Pắk",
      "cityCode": "Krông Pắk",
      "provinceCode": "Đắk Lắk Province"
    },
    {
      "city": "Lắk",
      "cityCode": "Lắk",
      "provinceCode": "Đắk Lắk Province"
    },
    {
      "city": "M'Đrăk",
      "cityCode": "M'Đrăk",
      "provinceCode": "Đắk Lắk Province"
    },
    {
      "city": "Cư Jút",
      "cityCode": "Cư Jút",
      "provinceCode": "Đắk Nông Province"
    },
    {
      "city": "Đắk Glong",
      "cityCode": "Đắk Glong",
      "provinceCode": "Đắk Nông Province"
    },
    {
      "city": "Đắk Mil",
      "cityCode": "Đắk Mil",
      "provinceCode": "Đắk Nông Province"
    },
    {
      "city": "Đắk R'Lấp",
      "cityCode": "Đắk R'Lấp",
      "provinceCode": "Đắk Nông Province"
    },
    {
      "city": "Đắk Song",
      "cityCode": "Đắk Song",
      "provinceCode": "Đắk Nông Province"
    },
    {
      "city": "Gia Nghĩa town",
      "cityCode": "Gia Nghĩa town",
      "provinceCode": "Đắk Nông Province"
    },
    {
      "city": "Krông Nô",
      "cityCode": "Krông Nô",
      "provinceCode": "Đắk Nông Province"
    },
    {
      "city": "Tuy Đức",
      "cityCode": "Tuy Đức",
      "provinceCode": "Đắk Nông Province"
    },
    {
      "city": "Điện Biên",
      "cityCode": "Điện Biên",
      "provinceCode": "Điện Biên Province"
    },
    {
      "city": "Điện Biên Đông",
      "cityCode": "Điện Biên Đông",
      "provinceCode": "Điện Biên Province"
    },
    {
      "city": "Điện Biên Phủ city",
      "cityCode": "Điện Biên Phủ city",
      "provinceCode": "Điện Biên Province"
    },
    {
      "city": "Mường Ảng",
      "cityCode": "Mường Ảng",
      "provinceCode": "Điện Biên Province"
    },
    {
      "city": "Mường Chà",
      "cityCode": "Mường Chà",
      "provinceCode": "Điện Biên Province"
    },
    {
      "city": "Mường Lay town",
      "cityCode": "Mường Lay town",
      "provinceCode": "Điện Biên Province"
    },
    {
      "city": "Mường Nhé",
      "cityCode": "Mường Nhé",
      "provinceCode": "Điện Biên Province"
    },
    {
      "city": "Nậm Pồ",
      "cityCode": "Nậm Pồ",
      "provinceCode": "Điện Biên Province"
    },
    {
      "city": "Tủa Chùa",
      "cityCode": "Tủa Chùa",
      "provinceCode": "Điện Biên Province"
    },
    {
      "city": "Tuần Giáo",
      "cityCode": "Tuần Giáo",
      "provinceCode": "Điện Biên Province"
    },
    {
      "city": "Biên Hòa city",
      "cityCode": "Biên Hòa city",
      "provinceCode": "Đồng Nai Province"
    },
    {
      "city": "Cẩm Mỹ",
      "cityCode": "Cẩm Mỹ",
      "provinceCode": "Đồng Nai Province"
    },
    {
      "city": "Định Quán",
      "cityCode": "Định Quán",
      "provinceCode": "Đồng Nai Province"
    },
    {
      "city": "Long Khánh city",
      "cityCode": "Long Khánh city",
      "provinceCode": "Đồng Nai Province"
    },
    {
      "city": "Long Thành",
      "cityCode": "Long Thành",
      "provinceCode": "Đồng Nai Province"
    },
    {
      "city": "Nhơn Trạch",
      "cityCode": "Nhơn Trạch",
      "provinceCode": "Đồng Nai Province"
    },
    {
      "city": "Tân Phú",
      "cityCode": "Tân Phú",
      "provinceCode": "Đồng Nai Province"
    },
    {
      "city": "Thống Nhất",
      "cityCode": "Thống Nhất",
      "provinceCode": "Đồng Nai Province"
    },
    {
      "city": "Trảng Bom",
      "cityCode": "Trảng Bom",
      "provinceCode": "Đồng Nai Province"
    },
    {
      "city": "Vĩnh Cữu",
      "cityCode": "Vĩnh Cữu",
      "provinceCode": "Đồng Nai Province"
    },
    {
      "city": "Xuân Lộc",
      "cityCode": "Xuân Lộc",
      "provinceCode": "Đồng Nai Province"
    },
    {
      "city": "Cao Lãnh city",
      "cityCode": "Cao Lãnh city",
      "provinceCode": "Đồng Tháp Province"
    },
    {
      "city": "Cao Lãnh",
      "cityCode": "Cao Lãnh",
      "provinceCode": "Đồng Tháp Province"
    },
    {
      "city": "Châu Thành",
      "cityCode": "Châu Thành",
      "provinceCode": "Đồng Tháp Province"
    },
    {
      "city": "Hồng Ngự town",
      "cityCode": "Hồng Ngự town",
      "provinceCode": "Đồng Tháp Province"
    },
    {
      "city": "Hồng Ngự",
      "cityCode": "Hồng Ngự",
      "provinceCode": "Đồng Tháp Province"
    },
    {
      "city": "Lai Vung",
      "cityCode": "Lai Vung",
      "provinceCode": "Đồng Tháp Province"
    },
    {
      "city": "Lấp Vò",
      "cityCode": "Lấp Vò",
      "provinceCode": "Đồng Tháp Province"
    },
    {
      "city": "Sa Đéc city",
      "cityCode": "Sa Đéc city",
      "provinceCode": "Đồng Tháp Province"
    },
    {
      "city": "Tam Nông",
      "cityCode": "Tam Nông",
      "provinceCode": "Đồng Tháp Province"
    },
    {
      "city": "Tân Hồng",
      "cityCode": "Tân Hồng",
      "provinceCode": "Đồng Tháp Province"
    },
    {
      "city": "Thanh Bình",
      "cityCode": "Thanh Bình",
      "provinceCode": "Đồng Tháp Province"
    },
    {
      "city": "Tháp Mười",
      "cityCode": "Tháp Mười",
      "provinceCode": "Đồng Tháp Province"
    },
    {
      "city": "Ayun Pa town",
      "cityCode": "Ayun Pa town",
      "provinceCode": "Gia Lai Province"
    },
    {
      "city": "An Khê town",
      "cityCode": "An Khê town",
      "provinceCode": "Gia Lai Province"
    },
    {
      "city": "Chư Păh",
      "cityCode": "Chư Păh",
      "provinceCode": "Gia Lai Province"
    },
    {
      "city": "Chư Prông",
      "cityCode": "Chư Prông",
      "provinceCode": "Gia Lai Province"
    },
    {
      "city": "Chư Pưh",
      "cityCode": "Chư Pưh",
      "provinceCode": "Gia Lai Province"
    },
    {
      "city": "Chư Sê",
      "cityCode": "Chư Sê",
      "provinceCode": "Gia Lai Province"
    },
    {
      "city": "Đắk Đoa",
      "cityCode": "Đắk Đoa",
      "provinceCode": "Gia Lai Province"
    },
    {
      "city": "Đắk Pơ",
      "cityCode": "Đắk Pơ",
      "provinceCode": "Gia Lai Province"
    },
    {
      "city": "Đức Cơ",
      "cityCode": "Đức Cơ",
      "provinceCode": "Gia Lai Province"
    },
    {
      "city": "Ia Grai",
      "cityCode": "Ia Grai",
      "provinceCode": "Gia Lai Province"
    },
    {
      "city": "Ia Pa",
      "cityCode": "Ia Pa",
      "provinceCode": "Gia Lai Province"
    },
    {
      "city": "K'Bang",
      "cityCode": "K'Bang",
      "provinceCode": "Gia Lai Province"
    },
    {
      "city": "Kông Chro",
      "cityCode": "Kông Chro",
      "provinceCode": "Gia Lai Province"
    },
    {
      "city": "Krông Pa",
      "cityCode": "Krông Pa",
      "provinceCode": "Gia Lai Province"
    },
    {
      "city": "Mang Yang",
      "cityCode": "Mang Yang",
      "provinceCode": "Gia Lai Province"
    },
    {
      "city": "Phú Thiện",
      "cityCode": "Phú Thiện",
      "provinceCode": "Gia Lai Province"
    },
    {
      "city": "Pleiku city",
      "cityCode": "Pleiku city",
      "provinceCode": "Gia Lai Province"
    },
    {
      "city": "Bắc Mê",
      "cityCode": "Bắc Mê",
      "provinceCode": "Hà Giang Province"
    },
    {
      "city": "Bắc Quang",
      "cityCode": "Bắc Quang",
      "provinceCode": "Hà Giang Province"
    },
    {
      "city": "Đồng Văn",
      "cityCode": "Đồng Văn",
      "provinceCode": "Hà Giang Province"
    },
    {
      "city": "Hà Giang city",
      "cityCode": "Hà Giang city",
      "provinceCode": "Hà Giang Province"
    },
    {
      "city": "Hoàng Su Phì",
      "cityCode": "Hoàng Su Phì",
      "provinceCode": "Hà Giang Province"
    },
    {
      "city": "Mèo Vạc",
      "cityCode": "Mèo Vạc",
      "provinceCode": "Hà Giang Province"
    },
    {
      "city": "Quản Bạ",
      "cityCode": "Quản Bạ",
      "provinceCode": "Hà Giang Province"
    },
    {
      "city": "Quang Bình",
      "cityCode": "Quang Bình",
      "provinceCode": "Hà Giang Province"
    },
    {
      "city": "Vị Xuyên",
      "cityCode": "Vị Xuyên",
      "provinceCode": "Hà Giang Province"
    },
    {
      "city": "Xín Mần",
      "cityCode": "Xín Mần",
      "provinceCode": "Hà Giang Province"
    },
    {
      "city": "Yên Minh",
      "cityCode": "Yên Minh",
      "provinceCode": "Hà Giang Province"
    },
    {
      "city": "Bình Lục",
      "cityCode": "Bình Lục",
      "provinceCode": "Hà Nam Province"
    },
    {
      "city": "Duy Tiên",
      "cityCode": "Duy Tiên",
      "provinceCode": "Hà Nam Province"
    },
    {
      "city": "Kim Bảng",
      "cityCode": "Kim Bảng",
      "provinceCode": "Hà Nam Province"
    },
    {
      "city": "Lý Nhân",
      "cityCode": "Lý Nhân",
      "provinceCode": "Hà Nam Province"
    },
    {
      "city": "Phủ Lý city",
      "cityCode": "Phủ Lý city",
      "provinceCode": "Hà Nam Province"
    },
    {
      "city": "Thanh Liêm",
      "cityCode": "Thanh Liêm",
      "provinceCode": "Hà Nam Province"
    },
    {
      "city": "Ba Đình",
      "cityCode": "Ba Đình",
      "provinceCode": "Hà Nội"
    },
    {
      "city": "Bắc Từ Liêm",
      "cityCode": "Bắc Từ Liêm",
      "provinceCode": "Hà Nội"
    },
    {
      "city": "Cầu Giấy",
      "cityCode": "Cầu Giấy",
      "provinceCode": "Hà Nội"
    },
    {
      "city": "Đống Đa",
      "cityCode": "Đống Đa",
      "provinceCode": "Hà Nội"
    },
    {
      "city": "Hà Đông",
      "cityCode": "Hà Đông",
      "provinceCode": "Hà Nội"
    },
    {
      "city": "Hai Bà Trưng",
      "cityCode": "Hai Bà Trưng",
      "provinceCode": "Hà Nội"
    },
    {
      "city": "Hoàn Kiếm",
      "cityCode": "Hoàn Kiếm",
      "provinceCode": "Hà Nội"
    },
    {
      "city": "Hoàng Mai",
      "cityCode": "Hoàng Mai",
      "provinceCode": "Hà Nội"
    },
    {
      "city": "Long Biên",
      "cityCode": "Long Biên",
      "provinceCode": "Hà Nội"
    },
    {
      "city": "Nam Từ Liêm",
      "cityCode": "Nam Từ Liêm",
      "provinceCode": "Hà Nội"
    },
    {
      "city": "Tây Hồ",
      "cityCode": "Tây Hồ",
      "provinceCode": "Hà Nội"
    },
    {
      "city": "Thanh Xuân",
      "cityCode": "Thanh Xuân",
      "provinceCode": "Hà Nội"
    },
    {
      "city": "Sơn Tây town",
      "cityCode": "Sơn Tây town",
      "provinceCode": "Hà Nội"
    },
    {
      "city": "Ba Vì",
      "cityCode": "Ba Vì",
      "provinceCode": "Hà Nội"
    },
    {
      "city": "Chương Mỹ",
      "cityCode": "Chương Mỹ",
      "provinceCode": "Hà Nội"
    },
    {
      "city": "Đan Phượng",
      "cityCode": "Đan Phượng",
      "provinceCode": "Hà Nội"
    },
    {
      "city": "Đông Anh",
      "cityCode": "Đông Anh",
      "provinceCode": "Hà Nội"
    },
    {
      "city": "Gia Lâm",
      "cityCode": "Gia Lâm",
      "provinceCode": "Hà Nội"
    },
    {
      "city": "Hoài Đức",
      "cityCode": "Hoài Đức",
      "provinceCode": "Hà Nội"
    },
    {
      "city": "Mê Linh",
      "cityCode": "Mê Linh",
      "provinceCode": "Hà Nội"
    },
    {
      "city": "Mỹ Đức",
      "cityCode": "Mỹ Đức",
      "provinceCode": "Hà Nội"
    },
    {
      "city": "Long Biên",
      "cityCode": "Long Biên",
      "provinceCode": "Hà Nội"
    },
    {
      "city": "Phú Xuyên",
      "cityCode": "Phú Xuyên",
      "provinceCode": "Hà Nội"
    },
    {
      "city": "Phúc Thọ",
      "cityCode": "Phúc Thọ",
      "provinceCode": "Hà Nội"
    },
    {
      "city": "Quốc Oai",
      "cityCode": "Quốc Oai",
      "provinceCode": "Hà Nội"
    },
    {
      "city": "Sóc Sơn",
      "cityCode": "Sóc Sơn",
      "provinceCode": "Hà Nội"
    },
    {
      "city": "Thanh Oai",
      "cityCode": "Thanh Oai",
      "provinceCode": "Hà Nội"
    },
    {
      "city": "Thạch Thất",
      "cityCode": "Thạch Thất",
      "provinceCode": "Hà Nội"
    },
    {
      "city": "Thanh Trì",
      "cityCode": "Thanh Trì",
      "provinceCode": "Hà Nội"
    },
    {
      "city": "Thường Tín",
      "cityCode": "Thường Tín",
      "provinceCode": "Hà Nội"
    },
    {
      "city": "Ứng Hòa",
      "cityCode": "Ứng Hòa",
      "provinceCode": "Hà Nội"
    },
    {
      "city": "Cẩm Xuyên",
      "cityCode": "Cẩm Xuyên",
      "provinceCode": "Hà Tĩnh Province"
    },
    {
      "city": "Can Lộc",
      "cityCode": "Can Lộc",
      "provinceCode": "Hà Tĩnh Province"
    },
    {
      "city": "Đức Thọ",
      "cityCode": "Đức Thọ",
      "provinceCode": "Hà Tĩnh Province"
    },
    {
      "city": "Hà Tĩnh city",
      "cityCode": "Hà Tĩnh city",
      "provinceCode": "Hà Tĩnh Province"
    },
    {
      "city": "Hồng Lĩnh town",
      "cityCode": "Hồng Lĩnh town",
      "provinceCode": "Hà Tĩnh Province"
    },
    {
      "city": "Hương Khê",
      "cityCode": "Hương Khê",
      "provinceCode": "Hà Tĩnh Province"
    },
    {
      "city": "Hương Sơn",
      "cityCode": "Hương Sơn",
      "provinceCode": "Hà Tĩnh Province"
    },
    {
      "city": "Kỳ Anh town",
      "cityCode": "Kỳ Anh town",
      "provinceCode": "Hà Tĩnh Province"
    },
    {
      "city": "Kỳ Anh",
      "cityCode": "Kỳ Anh",
      "provinceCode": "Hà Tĩnh Province"
    },
    {
      "city": "Lộc Hà",
      "cityCode": "Lộc Hà",
      "provinceCode": "Hà Tĩnh Province"
    },
    {
      "city": "Nghi Xuân",
      "cityCode": "Nghi Xuân",
      "provinceCode": "Hà Tĩnh Province"
    },
    {
      "city": "Thạch Hà",
      "cityCode": "Thạch Hà",
      "provinceCode": "Hà Tĩnh Province"
    },
    {
      "city": "Vũ Quang",
      "cityCode": "Vũ Quang",
      "provinceCode": "Hà Tĩnh Province"
    },
    {
      "city": "Bình Giang",
      "cityCode": "Bình Giang",
      "provinceCode": "Hải Dương Province"
    },
    {
      "city": "Cẩm Giàng",
      "cityCode": "Cẩm Giàng",
      "provinceCode": "Hải Dương Province"
    },
    {
      "city": "Chí Linh city",
      "cityCode": "Chí Linh city",
      "provinceCode": "Hải Dương Province"
    },
    {
      "city": "Gia Lộc",
      "cityCode": "Gia Lộc",
      "provinceCode": "Hải Dương Province"
    },
    {
      "city": "Hải Dương city",
      "cityCode": "Hải Dương city",
      "provinceCode": "Hải Dương Province"
    },
    {
      "city": "Kim Thành",
      "cityCode": "Kim Thành",
      "provinceCode": "Hải Dương Province"
    },
    {
      "city": "Kinh Môn town",
      "cityCode": "Kinh Môn town",
      "provinceCode": "Hải Dương Province"
    },
    {
      "city": "Nam Sách",
      "cityCode": "Nam Sách",
      "provinceCode": "Hải Dương Province"
    },
    {
      "city": "Ninh Giang",
      "cityCode": "Ninh Giang",
      "provinceCode": "Hải Dương Province"
    },
    {
      "city": "Thanh Hà",
      "cityCode": "Thanh Hà",
      "provinceCode": "Hải Dương Province"
    },
    {
      "city": "Thanh Miện",
      "cityCode": "Thanh Miện",
      "provinceCode": "Hải Dương Province"
    },
    {
      "city": "Tứ Kỳ",
      "cityCode": "Tứ Kỳ",
      "provinceCode": "Hải Dương Province"
    },
    {
      "city": "Đồ Sơn",
      "cityCode": "Đồ Sơn",
      "provinceCode": "Hải Phòng"
    },
    {
      "city": "Dương Kinh",
      "cityCode": "Dương Kinh",
      "provinceCode": "Hải Phòng"
    },
    {
      "city": "Hải An",
      "cityCode": "Hải An",
      "provinceCode": "Hải Phòng"
    },
    {
      "city": "Hồng Bàng",
      "cityCode": "Hồng Bàng",
      "provinceCode": "Hải Phòng"
    },
    {
      "city": "Kiến An",
      "cityCode": "Kiến An",
      "provinceCode": "Hải Phòng"
    },
    {
      "city": "Lê Chân",
      "cityCode": "Lê Chân",
      "provinceCode": "Hải Phòng"
    },
    {
      "city": "Ngô Quyền",
      "cityCode": "Ngô Quyền",
      "provinceCode": "Hải Phòng"
    },
    {
      "city": "An Dương",
      "cityCode": "An Dương",
      "provinceCode": "Hải Phòng"
    },
    {
      "city": "An Lão",
      "cityCode": "An Lão",
      "provinceCode": "Hải Phòng"
    },
    {
      "city": "Bạch Long Vĩ Island",
      "cityCode": "Bạch Long Vĩ Island",
      "provinceCode": "Hải Phòng"
    },
    {
      "city": "Cát Hải Island",
      "cityCode": "Cát Hải Island",
      "provinceCode": "Hải Phòng"
    },
    {
      "city": "Kiến Thuỵ",
      "cityCode": "Kiến Thuỵ",
      "provinceCode": "Hải Phòng"
    },
    {
      "city": "Thủy Nguyên",
      "cityCode": "Thủy Nguyên",
      "provinceCode": "Hải Phòng"
    },
    {
      "city": "Tiên Lãng",
      "cityCode": "Tiên Lãng",
      "provinceCode": "Hải Phòng"
    },
    {
      "city": "Vĩnh Bảo",
      "cityCode": "Vĩnh Bảo",
      "provinceCode": "Hải Phòng"
    },
    {
      "city": "Châu Thành",
      "cityCode": "Châu Thành",
      "provinceCode": "Hậu Giang Province"
    },
    {
      "city": "Châu Thành A",
      "cityCode": "Châu Thành A",
      "provinceCode": "Hậu Giang Province"
    },
    {
      "city": "Long Mỹ town",
      "cityCode": "Long Mỹ town",
      "provinceCode": "Hậu Giang Province"
    },
    {
      "city": "Long Mỹ",
      "cityCode": "Long Mỹ",
      "provinceCode": "Hậu Giang Province"
    },
    {
      "city": "Ngã Bảy town",
      "cityCode": "Ngã Bảy town",
      "provinceCode": "Hậu Giang Province"
    },
    {
      "city": "Phụng Hiệp",
      "cityCode": "Phụng Hiệp",
      "provinceCode": "Hậu Giang Province"
    },
    {
      "city": "Vị Thanh city",
      "cityCode": "Vị Thanh city",
      "provinceCode": "Hậu Giang Province"
    },
    {
      "city": "Vị Thủy",
      "cityCode": "Vị Thủy",
      "provinceCode": "Hậu Giang Province"
    },
    {
      "city": "District 1",
      "cityCode": "District 1",
      "provinceCode": "Hồ Chí Minh City"
    },
    {
      "city": "District 2",
      "cityCode": "District 2",
      "provinceCode": "Hồ Chí Minh City"
    },
    {
      "city": "District 3",
      "cityCode": "District 3",
      "provinceCode": "Hồ Chí Minh City"
    },
    {
      "city": "District 4",
      "cityCode": "District 4",
      "provinceCode": "Hồ Chí Minh City"
    },
    {
      "city": "District 5",
      "cityCode": "District 5",
      "provinceCode": "Hồ Chí Minh City"
    },
    {
      "city": "District 6",
      "cityCode": "District 6",
      "provinceCode": "Hồ Chí Minh City"
    },
    {
      "city": "District 7",
      "cityCode": "District 7",
      "provinceCode": "Hồ Chí Minh City"
    },
    {
      "city": "District 8",
      "cityCode": "District 8",
      "provinceCode": "Hồ Chí Minh City"
    },
    {
      "city": "District 9",
      "cityCode": "District 9",
      "provinceCode": "Hồ Chí Minh City"
    },
    {
      "city": "District 10",
      "cityCode": "District 10",
      "provinceCode": "Hồ Chí Minh City"
    },
    {
      "city": "District 11",
      "cityCode": "District 11",
      "provinceCode": "Hồ Chí Minh City"
    },
    {
      "city": "District 12",
      "cityCode": "District 12",
      "provinceCode": "Hồ Chí Minh City"
    },
    {
      "city": "Bình Tân",
      "cityCode": "Bình Tân",
      "provinceCode": "Hồ Chí Minh City"
    },
    {
      "city": "Bình Thạnh",
      "cityCode": "Bình Thạnh",
      "provinceCode": "Hồ Chí Minh City"
    },
    {
      "city": "Gò Vấp",
      "cityCode": "Gò Vấp",
      "provinceCode": "Hồ Chí Minh City"
    },
    {
      "city": "Phú Nhuận",
      "cityCode": "Phú Nhuận",
      "provinceCode": "Hồ Chí Minh City"
    },
    {
      "city": "Tân Bình",
      "cityCode": "Tân Bình",
      "provinceCode": "Hồ Chí Minh City"
    },
    {
      "city": "Tân Phú",
      "cityCode": "Tân Phú",
      "provinceCode": "Hồ Chí Minh City"
    },
    {
      "city": "Thủ Đức",
      "cityCode": "Thủ Đức",
      "provinceCode": "Hồ Chí Minh City"
    },
    {
      "city": "Bình Chánh",
      "cityCode": "Bình Chánh",
      "provinceCode": "Hồ Chí Minh City"
    },
    {
      "city": "Cần Giờ",
      "cityCode": "Cần Giờ",
      "provinceCode": "Hồ Chí Minh City"
    },
    {
      "city": "Củ Chi",
      "cityCode": "Củ Chi",
      "provinceCode": "Hồ Chí Minh City"
    },
    {
      "city": "Hóc Môn",
      "cityCode": "Hóc Môn",
      "provinceCode": "Hồ Chí Minh City"
    },
    {
      "city": "Nhà Bè",
      "cityCode": "Nhà Bè",
      "provinceCode": "Hồ Chí Minh City"
    },
    {
      "city": "Cao Phong",
      "cityCode": "Cao Phong",
      "provinceCode": "Hòa Bình Province"
    },
    {
      "city": "Đà Bắc",
      "cityCode": "Đà Bắc",
      "provinceCode": "Hòa Bình Province"
    },
    {
      "city": "Hòa Bình city",
      "cityCode": "Hòa Bình city",
      "provinceCode": "Hòa Bình Province"
    },
    {
      "city": "Kim Bôi",
      "cityCode": "Kim Bôi",
      "provinceCode": "Hòa Bình Province"
    },
    {
      "city": "Kỳ Sơn",
      "cityCode": "Kỳ Sơn",
      "provinceCode": "Hòa Bình Province"
    },
    {
      "city": "Lạc Sơn",
      "cityCode": "Lạc Sơn",
      "provinceCode": "Hòa Bình Province"
    },
    {
      "city": "Lạc Thủy",
      "cityCode": "Lạc Thủy",
      "provinceCode": "Hòa Bình Province"
    },
    {
      "city": "Lương Sơn",
      "cityCode": "Lương Sơn",
      "provinceCode": "Hòa Bình Province"
    },
    {
      "city": "Mai Châu",
      "cityCode": "Mai Châu",
      "provinceCode": "Hòa Bình Province"
    },
    {
      "city": "Tân Lạc",
      "cityCode": "Tân Lạc",
      "provinceCode": "Hòa Bình Province"
    },
    {
      "city": "Yên Thủy",
      "cityCode": "Yên Thủy",
      "provinceCode": "Hòa Bình Province"
    },
    {
      "city": "Ân Thi",
      "cityCode": "Ân Thi",
      "provinceCode": "Hưng Yên Province"
    },
    {
      "city": "Hưng Yên city",
      "cityCode": "Hưng Yên city",
      "provinceCode": "Hưng Yên Province"
    },
    {
      "city": "Khoái Châu",
      "cityCode": "Khoái Châu",
      "provinceCode": "Hưng Yên Province"
    },
    {
      "city": "Kim Động",
      "cityCode": "Kim Động",
      "provinceCode": "Hưng Yên Province"
    },
    {
      "city": "Mỹ Hào town",
      "cityCode": "Mỹ Hào town",
      "provinceCode": "Hưng Yên Province"
    },
    {
      "city": "Phù Cừ",
      "cityCode": "Phù Cừ",
      "provinceCode": "Hưng Yên Province"
    },
    {
      "city": "Tiên Lữ",
      "cityCode": "Tiên Lữ",
      "provinceCode": "Hưng Yên Province"
    },
    {
      "city": "Văn Giang",
      "cityCode": "Văn Giang",
      "provinceCode": "Hưng Yên Province"
    },
    {
      "city": "Văn Lâm",
      "cityCode": "Văn Lâm",
      "provinceCode": "Hưng Yên Province"
    },
    {
      "city": "Yên Mỹ",
      "cityCode": "Yên Mỹ",
      "provinceCode": "Hưng Yên Province"
    },
    {
      "city": "Cam Lâm",
      "cityCode": "Cam Lâm",
      "provinceCode": "Khánh Hòa Province"
    },
    {
      "city": "Cam Ranh city",
      "cityCode": "Cam Ranh city",
      "provinceCode": "Khánh Hòa Province"
    },
    {
      "city": "Diên Khánh",
      "cityCode": "Diên Khánh",
      "provinceCode": "Khánh Hòa Province"
    },
    {
      "city": "Khánh Sơn",
      "cityCode": "Khánh Sơn",
      "provinceCode": "Khánh Hòa Province"
    },
    {
      "city": "Khánh Vĩnh",
      "cityCode": "Khánh Vĩnh",
      "provinceCode": "Khánh Hòa Province"
    },
    {
      "city": "Nha Trang city",
      "cityCode": "Nha Trang city",
      "provinceCode": "Khánh Hòa Province"
    },
    {
      "city": "Ninh Hòa town",
      "cityCode": "Ninh Hòa town",
      "provinceCode": "Khánh Hòa Province"
    },
    {
      "city": "Trường Sa Island",
      "cityCode": "Trường Sa Island",
      "provinceCode": "Khánh Hòa Province"
    },
    {
      "city": "Vạn Ninh",
      "cityCode": "Vạn Ninh",
      "provinceCode": "Khánh Hòa Province"
    },
    {
      "city": "An Biên",
      "cityCode": "An Biên",
      "provinceCode": "Kiên Giang Province"
    },
    {
      "city": "An Minh",
      "cityCode": "An Minh",
      "provinceCode": "Kiên Giang Province"
    },
    {
      "city": "Châu Thành",
      "cityCode": "Châu Thành",
      "provinceCode": "Kiên Giang Province"
    },
    {
      "city": "Giang Thành",
      "cityCode": "Giang Thành",
      "provinceCode": "Kiên Giang Province"
    },
    {
      "city": "Giồng Riềng",
      "cityCode": "Giồng Riềng",
      "provinceCode": "Kiên Giang Province"
    },
    {
      "city": "Gò Quao",
      "cityCode": "Gò Quao",
      "provinceCode": "Kiên Giang Province"
    },
    {
      "city": "Hà Tiên city",
      "cityCode": "Hà Tiên city",
      "provinceCode": "Kiên Giang Province"
    },
    {
      "city": "Hòn Đất",
      "cityCode": "Hòn Đất",
      "provinceCode": "Kiên Giang Province"
    },
    {
      "city": "Kiên Hải Island",
      "cityCode": "Kiên Hải Island",
      "provinceCode": "Kiên Giang Province"
    },
    {
      "city": "Kiên Lương",
      "cityCode": "Kiên Lương",
      "provinceCode": "Kiên Giang Province"
    },
    {
      "city": "Phú Quốc Island",
      "cityCode": "Phú Quốc Island",
      "provinceCode": "Kiên Giang Province"
    },
    {
      "city": "Rạch Giá city",
      "cityCode": "Rạch Giá city",
      "provinceCode": "Kiên Giang Province"
    },
    {
      "city": "Tân Hiệp",
      "cityCode": "Tân Hiệp",
      "provinceCode": "Kiên Giang Province"
    },
    {
      "city": "Vĩnh Thuận",
      "cityCode": "Vĩnh Thuận",
      "provinceCode": "Kiên Giang Province"
    },
    {
      "city": "U Minh Thượng",
      "cityCode": "U Minh Thượng",
      "provinceCode": "Kiên Giang Province"
    },
    {
      "city": "Đắk Glei",
      "cityCode": "Đắk Glei",
      "provinceCode": "Kon Tum Province"
    },
    {
      "city": "Đắk Hà",
      "cityCode": "Đắk Hà",
      "provinceCode": "Kon Tum Province"
    },
    {
      "city": "Đắk Tô",
      "cityCode": "Đắk Tô",
      "provinceCode": "Kon Tum Province"
    },
    {
      "city": "Ia H'Drai",
      "cityCode": "Ia H'Drai",
      "provinceCode": "Kon Tum Province"
    },
    {
      "city": "Kon Plông",
      "cityCode": "Kon Plông",
      "provinceCode": "Kon Tum Province"
    },
    {
      "city": "Kon Rẫy",
      "cityCode": "Kon Rẫy",
      "provinceCode": "Kon Tum Province"
    },
    {
      "city": "Kon Tum city",
      "cityCode": "Kon Tum city",
      "provinceCode": "Kon Tum Province"
    },
    {
      "city": "Ngọc Hồi",
      "cityCode": "Ngọc Hồi",
      "provinceCode": "Kon Tum Province"
    },
    {
      "city": "Sa Thầy",
      "cityCode": "Sa Thầy",
      "provinceCode": "Kon Tum Province"
    },
    {
      "city": "Tu Mơ Rông",
      "cityCode": "Tu Mơ Rông",
      "provinceCode": "Kon Tum Province"
    },
    {
      "city": "Lai Châu city",
      "cityCode": "Lai Châu city",
      "provinceCode": "Lai Châu Province"
    },
    {
      "city": "Mường Tè",
      "cityCode": "Mường Tè",
      "provinceCode": "Lai Châu Province"
    },
    {
      "city": "Nậm Nhùn",
      "cityCode": "Nậm Nhùn",
      "provinceCode": "Lai Châu Province"
    },
    {
      "city": "Phong Thổ",
      "cityCode": "Phong Thổ",
      "provinceCode": "Lai Châu Province"
    },
    {
      "city": "Sìn Hồ",
      "cityCode": "Sìn Hồ",
      "provinceCode": "Lai Châu Province"
    },
    {
      "city": "Tam Đường",
      "cityCode": "Tam Đường",
      "provinceCode": "Lai Châu Province"
    },
    {
      "city": "Than Uyên",
      "cityCode": "Than Uyên",
      "provinceCode": "Lai Châu Province"
    },
    {
      "city": "Tân Uyên",
      "cityCode": "Tân Uyên",
      "provinceCode": "Lai Châu Province"
    },
    {
      "city": "Bảo Lâm",
      "cityCode": "Bảo Lâm",
      "provinceCode": "Lâm Đồng Province"
    },
    {
      "city": "Bảo Lộc city",
      "cityCode": "Bảo Lộc city",
      "provinceCode": "Lâm Đồng Province"
    },
    {
      "city": "Cát Tiên",
      "cityCode": "Cát Tiên",
      "provinceCode": "Lâm Đồng Province"
    },
    {
      "city": "Đạ Huoai",
      "cityCode": "Đạ Huoai",
      "provinceCode": "Lâm Đồng Province"
    },
    {
      "city": "Đà Lạt city",
      "cityCode": "Đà Lạt city",
      "provinceCode": "Lâm Đồng Province"
    },
    {
      "city": "Đạ Tẻh",
      "cityCode": "Đạ Tẻh",
      "provinceCode": "Lâm Đồng Province"
    },
    {
      "city": "Đam Rông",
      "cityCode": "Đam Rông",
      "provinceCode": "Lâm Đồng Province"
    },
    {
      "city": "Di Linh",
      "cityCode": "Di Linh",
      "provinceCode": "Lâm Đồng Province"
    },
    {
      "city": "Đơn Dương",
      "cityCode": "Đơn Dương",
      "provinceCode": "Lâm Đồng Province"
    },
    {
      "city": "Đức Trọng",
      "cityCode": "Đức Trọng",
      "provinceCode": "Lâm Đồng Province"
    },
    {
      "city": "Lạc Dương",
      "cityCode": "Lạc Dương",
      "provinceCode": "Lâm Đồng Province"
    },
    {
      "city": "Lâm Hà",
      "cityCode": "Lâm Hà",
      "provinceCode": "Lâm Đồng Province"
    },
    {
      "city": "Bắc Sơn",
      "cityCode": "Bắc Sơn",
      "provinceCode": "Lạng Sơn Province"
    },
    {
      "city": "Bình Gia",
      "cityCode": "Bình Gia",
      "provinceCode": "Lạng Sơn Province"
    },
    {
      "city": "Cao Lộc",
      "cityCode": "Cao Lộc",
      "provinceCode": "Lạng Sơn Province"
    },
    {
      "city": "Chi Lăng",
      "cityCode": "Chi Lăng",
      "provinceCode": "Lạng Sơn Province"
    },
    {
      "city": "Đình Lập",
      "cityCode": "Đình Lập",
      "provinceCode": "Lạng Sơn Province"
    },
    {
      "city": "Hữu Lũng",
      "cityCode": "Hữu Lũng",
      "provinceCode": "Lạng Sơn Province"
    },
    {
      "city": "Lạng Sơn city",
      "cityCode": "Lạng Sơn city",
      "provinceCode": "Lạng Sơn Province"
    },
    {
      "city": "Lộc Bình",
      "cityCode": "Lộc Bình",
      "provinceCode": "Lạng Sơn Province"
    },
    {
      "city": "Tràng Định",
      "cityCode": "Tràng Định",
      "provinceCode": "Lạng Sơn Province"
    },
    {
      "city": "Văn Lãng",
      "cityCode": "Văn Lãng",
      "provinceCode": "Lạng Sơn Province"
    },
    {
      "city": "Văn Quân",
      "cityCode": "Văn Quân",
      "provinceCode": "Lạng Sơn Province"
    },
    {
      "city": "Bắc Hà",
      "cityCode": "Bắc Hà",
      "provinceCode": "Lào Cai Province"
    },
    {
      "city": "Bảo Thắng",
      "cityCode": "Bảo Thắng",
      "provinceCode": "Lào Cai Province"
    },
    {
      "city": "Bảo Yên",
      "cityCode": "Bảo Yên",
      "provinceCode": "Lào Cai Province"
    },
    {
      "city": "Bát Xát",
      "cityCode": "Bát Xát",
      "provinceCode": "Lào Cai Province"
    },
    {
      "city": "Lào Cai city",
      "cityCode": "Lào Cai city",
      "provinceCode": "Lào Cai Province"
    },
    {
      "city": "Mường Khương",
      "cityCode": "Mường Khương",
      "provinceCode": "Lào Cai Province"
    },
    {
      "city": "Sa Pa town",
      "cityCode": "Sa Pa town",
      "provinceCode": "Lào Cai Province"
    },
    {
      "city": "Si Ma Cai",
      "cityCode": "Si Ma Cai",
      "provinceCode": "Lào Cai Province"
    },
    {
      "city": "Văn Bàn",
      "cityCode": "Văn Bàn",
      "provinceCode": "Lào Cai Province"
    },
    {
      "city": "Bến Lức",
      "cityCode": "Bến Lức",
      "provinceCode": "Long An Province"
    },
    {
      "city": "Cần Đước",
      "cityCode": "Cần Đước",
      "provinceCode": "Long An Province"
    },
    {
      "city": "Cần Giuộc",
      "cityCode": "Cần Giuộc",
      "provinceCode": "Long An Province"
    },
    {
      "city": "Châu Thành",
      "cityCode": "Châu Thành",
      "provinceCode": "Long An Province"
    },
    {
      "city": "Đức Hòa",
      "cityCode": "Đức Hòa",
      "provinceCode": "Long An Province"
    },
    {
      "city": "Đức Huệ",
      "cityCode": "Đức Huệ",
      "provinceCode": "Long An Province"
    },
    {
      "city": "Kiến Tường town",
      "cityCode": "Kiến Tường town",
      "provinceCode": "Long An Province"
    },
    {
      "city": "Mộc Hóa",
      "cityCode": "Mộc Hóa",
      "provinceCode": "Long An Province"
    },
    {
      "city": "Tân An city",
      "cityCode": "Tân An city",
      "provinceCode": "Long An Province"
    },
    {
      "city": "Tân Hưng",
      "cityCode": "Tân Hưng",
      "provinceCode": "Long An Province"
    },
    {
      "city": "Tân Thạnh",
      "cityCode": "Tân Thạnh",
      "provinceCode": "Long An Province"
    },
    {
      "city": "Tân Trụ",
      "cityCode": "Tân Trụ",
      "provinceCode": "Long An Province"
    },
    {
      "city": "Thạnh Hóa",
      "cityCode": "Thạnh Hóa",
      "provinceCode": "Long An Province"
    },
    {
      "city": "Thủ Thừa",
      "cityCode": "Thủ Thừa",
      "provinceCode": "Long An Province"
    },
    {
      "city": "Vĩnh Hưng",
      "cityCode": "Vĩnh Hưng",
      "provinceCode": "Long An Province"
    },
    {
      "city": "Giao Thủy",
      "cityCode": "Giao Thủy",
      "provinceCode": "Nam Định Province"
    },
    {
      "city": "Hải Hậu",
      "cityCode": "Hải Hậu",
      "provinceCode": "Nam Định Province"
    },
    {
      "city": "Mỹ Lộc",
      "cityCode": "Mỹ Lộc",
      "provinceCode": "Nam Định Province"
    },
    {
      "city": "Nam Định city",
      "cityCode": "Nam Định city",
      "provinceCode": "Nam Định Province"
    },
    {
      "city": "Nam Trực",
      "cityCode": "Nam Trực",
      "provinceCode": "Nam Định Province"
    },
    {
      "city": "Nghĩa Hưng",
      "cityCode": "Nghĩa Hưng",
      "provinceCode": "Nam Định Province"
    },
    {
      "city": "Trực Ninh",
      "cityCode": "Trực Ninh",
      "provinceCode": "Nam Định Province"
    },
    {
      "city": "Vụ Bản",
      "cityCode": "Vụ Bản",
      "provinceCode": "Nam Định Province"
    },
    {
      "city": "Xuân Trường",
      "cityCode": "Xuân Trường",
      "provinceCode": "Nam Định Province"
    },
    {
      "city": "Ý Yên",
      "cityCode": "Ý Yên",
      "provinceCode": "Nam Định Province"
    },
    {
      "city": "Anh Sơn",
      "cityCode": "Anh Sơn",
      "provinceCode": "Nghệ An Province"
    },
    {
      "city": "Con Cuông",
      "cityCode": "Con Cuông",
      "provinceCode": "Nghệ An Province"
    },
    {
      "city": "Cửa Lò town",
      "cityCode": "Cửa Lò town",
      "provinceCode": "Nghệ An Province"
    },
    {
      "city": "Diễn Châu",
      "cityCode": "Diễn Châu",
      "provinceCode": "Nghệ An Province"
    },
    {
      "city": "Đô Lương",
      "cityCode": "Đô Lương",
      "provinceCode": "Nghệ An Province"
    },
    {
      "city": "Hoàng Mai town",
      "cityCode": "Hoàng Mai town",
      "provinceCode": "Nghệ An Province"
    },
    {
      "city": "Hưng Nguyên",
      "cityCode": "Hưng Nguyên",
      "provinceCode": "Nghệ An Province"
    },
    {
      "city": "Kỳ Sơn",
      "cityCode": "Kỳ Sơn",
      "provinceCode": "Nghệ An Province"
    },
    {
      "city": "Nam Đàn",
      "cityCode": "Nam Đàn",
      "provinceCode": "Nghệ An Province"
    },
    {
      "city": "Nghi Lộc",
      "cityCode": "Nghi Lộc",
      "provinceCode": "Nghệ An Province"
    },
    {
      "city": "Nghĩa Đàn",
      "cityCode": "Nghĩa Đàn",
      "provinceCode": "Nghệ An Province"
    },
    {
      "city": "Quế Phong",
      "cityCode": "Quế Phong",
      "provinceCode": "Nghệ An Province"
    },
    {
      "city": "Quỳ Châu",
      "cityCode": "Quỳ Châu",
      "provinceCode": "Nghệ An Province"
    },
    {
      "city": "Quỳ Hợp",
      "cityCode": "Quỳ Hợp",
      "provinceCode": "Nghệ An Province"
    },
    {
      "city": "Quỳnh Lưu",
      "cityCode": "Quỳnh Lưu",
      "provinceCode": "Nghệ An Province"
    },
    {
      "city": "Tân Kỳ",
      "cityCode": "Tân Kỳ",
      "provinceCode": "Nghệ An Province"
    },
    {
      "city": "Thanh Chương",
      "cityCode": "Thanh Chương",
      "provinceCode": "Nghệ An Province"
    },
    {
      "city": "Tương Dương",
      "cityCode": "Tương Dương",
      "provinceCode": "Nghệ An Province"
    },
    {
      "city": "Vinh city",
      "cityCode": "Vinh city",
      "provinceCode": "Nghệ An Province"
    },
    {
      "city": "Yên Thành",
      "cityCode": "Yên Thành",
      "provinceCode": "Nghệ An Province"
    },
    {
      "city": "Thái Hòa town",
      "cityCode": "Thái Hòa town",
      "provinceCode": "Nghệ An Province"
    },
    {
      "city": "Gia Viễn",
      "cityCode": "Gia Viễn",
      "provinceCode": "Ninh Bình Province"
    },
    {
      "city": "Hoa Lư",
      "cityCode": "Hoa Lư",
      "provinceCode": "Ninh Bình Province"
    },
    {
      "city": "Kim Sơn",
      "cityCode": "Kim Sơn",
      "provinceCode": "Ninh Bình Province"
    },
    {
      "city": "Nho Quan",
      "cityCode": "Nho Quan",
      "provinceCode": "Ninh Bình Province"
    },
    {
      "city": "Ninh Bình city",
      "cityCode": "Ninh Bình city",
      "provinceCode": "Ninh Bình Province"
    },
    {
      "city": "Tam Điệp city",
      "cityCode": "Tam Điệp city",
      "provinceCode": "Ninh Bình Province"
    },
    {
      "city": "Yên Khánh",
      "cityCode": "Yên Khánh",
      "provinceCode": "Ninh Bình Province"
    },
    {
      "city": "Yên Mô",
      "cityCode": "Yên Mô",
      "provinceCode": "Ninh Bình Province"
    },
    {
      "city": "Bác Ái",
      "cityCode": "Bác Ái",
      "provinceCode": "Ninh Thuận Province"
    },
    {
      "city": "Ninh Hải",
      "cityCode": "Ninh Hải",
      "provinceCode": "Ninh Thuận Province"
    },
    {
      "city": "Ninh Phước",
      "cityCode": "Ninh Phước",
      "provinceCode": "Ninh Thuận Province"
    },
    {
      "city": "Ninh Sơn",
      "cityCode": "Ninh Sơn",
      "provinceCode": "Ninh Thuận Province"
    },
    {
      "city": "Phan Rang–Tháp Chàm city",
      "cityCode": "Phan Rang–Tháp Chàm city",
      "provinceCode": "Ninh Thuận Province"
    },
    {
      "city": "Thuận Bắc",
      "cityCode": "Thuận Bắc",
      "provinceCode": "Ninh Thuận Province"
    },
    {
      "city": "Thuận Nam",
      "cityCode": "Thuận Nam",
      "provinceCode": "Ninh Thuận Province"
    },
    {
      "city": "Cẩm Khê",
      "cityCode": "Cẩm Khê",
      "provinceCode": "Phú Thọ Province"
    },
    {
      "city": "Đoan Hùng",
      "cityCode": "Đoan Hùng",
      "provinceCode": "Phú Thọ Province"
    },
    {
      "city": "Hạ Hòa",
      "cityCode": "Hạ Hòa",
      "provinceCode": "Phú Thọ Province"
    },
    {
      "city": "Lâm Thao",
      "cityCode": "Lâm Thao",
      "provinceCode": "Phú Thọ Province"
    },
    {
      "city": "Phù Ninh",
      "cityCode": "Phù Ninh",
      "provinceCode": "Phú Thọ Province"
    },
    {
      "city": "Phú Thọ town",
      "cityCode": "Phú Thọ town",
      "provinceCode": "Phú Thọ Province"
    },
    {
      "city": "Tam Nông",
      "cityCode": "Tam Nông",
      "provinceCode": "Phú Thọ Province"
    },
    {
      "city": "Tân Sơn",
      "cityCode": "Tân Sơn",
      "provinceCode": "Phú Thọ Province"
    },
    {
      "city": "Thanh Ba",
      "cityCode": "Thanh Ba",
      "provinceCode": "Phú Thọ Province"
    },
    {
      "city": "Thanh Sơn",
      "cityCode": "Thanh Sơn",
      "provinceCode": "Phú Thọ Province"
    },
    {
      "city": "Thanh Thủy",
      "cityCode": "Thanh Thủy",
      "provinceCode": "Phú Thọ Province"
    },
    {
      "city": "Việt Trì city",
      "cityCode": "Việt Trì city",
      "provinceCode": "Phú Thọ Province"
    },
    {
      "city": "Yên Lập",
      "cityCode": "Yên Lập",
      "provinceCode": "Phú Thọ Province"
    },
    {
      "city": "Đông Hòa",
      "cityCode": "Đông Hòa",
      "provinceCode": "Phú Yên Province"
    },
    {
      "city": "Đồng Xuân",
      "cityCode": "Đồng Xuân",
      "provinceCode": "Phú Yên Province"
    },
    {
      "city": "Phú Hòa",
      "cityCode": "Phú Hòa",
      "provinceCode": "Phú Yên Province"
    },
    {
      "city": "Sơn Hòa",
      "cityCode": "Sơn Hòa",
      "provinceCode": "Phú Yên Province"
    },
    {
      "city": "Sông Cầu town",
      "cityCode": "Sông Cầu town",
      "provinceCode": "Phú Yên Province"
    },
    {
      "city": "Sông Hinh",
      "cityCode": "Sông Hinh",
      "provinceCode": "Phú Yên Province"
    },
    {
      "city": "Tây Hòa",
      "cityCode": "Tây Hòa",
      "provinceCode": "Phú Yên Province"
    },
    {
      "city": "Tuy An",
      "cityCode": "Tuy An",
      "provinceCode": "Phú Yên Province"
    },
    {
      "city": "Tuy Hòa city",
      "cityCode": "Tuy Hòa city",
      "provinceCode": "Phú Yên Province"
    },
    {
      "city": "Ba Đồn town",
      "cityCode": "Ba Đồn town",
      "provinceCode": "Quảng Bình Province"
    },
    {
      "city": "Bố Trạch",
      "cityCode": "Bố Trạch",
      "provinceCode": "Quảng Bình Province"
    },
    {
      "city": "Đồng Hới city",
      "cityCode": "Đồng Hới city",
      "provinceCode": "Quảng Bình Province"
    },
    {
      "city": "Lệ Thủy",
      "cityCode": "Lệ Thủy",
      "provinceCode": "Quảng Bình Province"
    },
    {
      "city": "Minh Hóa",
      "cityCode": "Minh Hóa",
      "provinceCode": "Quảng Bình Province"
    },
    {
      "city": "Quảng Ninh",
      "cityCode": "Quảng Ninh",
      "provinceCode": "Quảng Bình Province"
    },
    {
      "city": "Quảng Trạch",
      "cityCode": "Quảng Trạch",
      "provinceCode": "Quảng Bình Province"
    },
    {
      "city": "Tuyên Hóa",
      "cityCode": "Tuyên Hóa",
      "provinceCode": "Quảng Bình Province"
    },
    {
      "city": "Bắc Trà My",
      "cityCode": "Bắc Trà My",
      "provinceCode": "Quảng Nam Province"
    },
    {
      "city": "Đại Lộc",
      "cityCode": "Đại Lộc",
      "provinceCode": "Quảng Nam Province"
    },
    {
      "city": "Điện Bàn town",
      "cityCode": "Điện Bàn town",
      "provinceCode": "Quảng Nam Province"
    },
    {
      "city": "Đông Giang",
      "cityCode": "Đông Giang",
      "provinceCode": "Quảng Nam Province"
    },
    {
      "city": "Duy Xuyên",
      "cityCode": "Duy Xuyên",
      "provinceCode": "Quảng Nam Province"
    },
    {
      "city": "Hiệp Đức",
      "cityCode": "Hiệp Đức",
      "provinceCode": "Quảng Nam Province"
    },
    {
      "city": "Hội An city",
      "cityCode": "Hội An city",
      "provinceCode": "Quảng Nam Province"
    },
    {
      "city": "Nam Giang",
      "cityCode": "Nam Giang",
      "provinceCode": "Quảng Nam Province"
    },
    {
      "city": "Nam Trà My",
      "cityCode": "Nam Trà My",
      "provinceCode": "Quảng Nam Province"
    },
    {
      "city": "Nông Sơn",
      "cityCode": "Nông Sơn",
      "provinceCode": "Quảng Nam Province"
    },
    {
      "city": "Núi Thành",
      "cityCode": "Núi Thành",
      "provinceCode": "Quảng Nam Province"
    },
    {
      "city": "Phước Sơn",
      "cityCode": "Phước Sơn",
      "provinceCode": "Quảng Nam Province"
    },
    {
      "city": "Quế Sơn",
      "cityCode": "Quế Sơn",
      "provinceCode": "Quảng Nam Province"
    },
    {
      "city": "Tam Kỳ city",
      "cityCode": "Tam Kỳ city",
      "provinceCode": "Quảng Nam Province"
    },
    {
      "city": "Tây Giang",
      "cityCode": "Tây Giang",
      "provinceCode": "Quảng Nam Province"
    },
    {
      "city": "Thăng Bình",
      "cityCode": "Thăng Bình",
      "provinceCode": "Quảng Nam Province"
    },
    {
      "city": "Tiên Phước",
      "cityCode": "Tiên Phước",
      "provinceCode": "Quảng Nam Province"
    },
    {
      "city": "Ba Tơ",
      "cityCode": "Ba Tơ",
      "provinceCode": "Quảng Ngãi Province"
    },
    {
      "city": "Bình Sơn",
      "cityCode": "Bình Sơn",
      "provinceCode": "Quảng Ngãi Province"
    },
    {
      "city": "Đức Phổ",
      "cityCode": "Đức Phổ",
      "provinceCode": "Quảng Ngãi Province"
    },
    {
      "city": "Lý Sơn Island",
      "cityCode": "Lý Sơn Island",
      "provinceCode": "Quảng Ngãi Province"
    },
    {
      "city": "Minh Long",
      "cityCode": "Minh Long",
      "provinceCode": "Quảng Ngãi Province"
    },
    {
      "city": "Mộ Đức",
      "cityCode": "Mộ Đức",
      "provinceCode": "Quảng Ngãi Province"
    },
    {
      "city": "Nghĩa Hành",
      "cityCode": "Nghĩa Hành",
      "provinceCode": "Quảng Ngãi Province"
    },
    {
      "city": "Quảng Ngãi city",
      "cityCode": "Quảng Ngãi city",
      "provinceCode": "Quảng Ngãi Province"
    },
    {
      "city": "Sơn Hà",
      "cityCode": "Sơn Hà",
      "provinceCode": "Quảng Ngãi Province"
    },
    {
      "city": "Sơn Tây",
      "cityCode": "Sơn Tây",
      "provinceCode": "Quảng Ngãi Province"
    },
    {
      "city": "Sơn Tịnh",
      "cityCode": "Sơn Tịnh",
      "provinceCode": "Quảng Ngãi Province"
    },
    {
      "city": "Tây Trà",
      "cityCode": "Tây Trà",
      "provinceCode": "Quảng Ngãi Province"
    },
    {
      "city": "Trà Bồng",
      "cityCode": "Trà Bồng",
      "provinceCode": "Quảng Ngãi Province"
    },
    {
      "city": "Tư Nghĩa",
      "cityCode": "Tư Nghĩa",
      "provinceCode": "Quảng Ngãi Province"
    },
    {
      "city": "Ba Chẽ",
      "cityCode": "Ba Chẽ",
      "provinceCode": "Quảng Ninh Province"
    },
    {
      "city": "Bình Liêu",
      "cityCode": "Bình Liêu",
      "provinceCode": "Quảng Ninh Province"
    },
    {
      "city": "Cẩm Phả city",
      "cityCode": "Cẩm Phả city",
      "provinceCode": "Quảng Ninh Province"
    },
    {
      "city": "Cô Tô Island",
      "cityCode": "Cô Tô Island",
      "provinceCode": "Quảng Ninh Province"
    },
    {
      "city": "Đầm Hà",
      "cityCode": "Đầm Hà",
      "provinceCode": "Quảng Ninh Province"
    },
    {
      "city": "Đông Triều town",
      "cityCode": "Đông Triều town",
      "provinceCode": "Quảng Ninh Province"
    },
    {
      "city": "Hạ Long city",
      "cityCode": "Hạ Long city",
      "provinceCode": "Quảng Ninh Province"
    },
    {
      "city": "Hải Hà",
      "cityCode": "Hải Hà",
      "provinceCode": "Quảng Ninh Province"
    },
    {
      "city": "Hoành Bồ",
      "cityCode": "Hoành Bồ",
      "provinceCode": "Quảng Ninh Province"
    },
    {
      "city": "Móng Cái city",
      "cityCode": "Móng Cái city",
      "provinceCode": "Quảng Ninh Province"
    },
    {
      "city": "Quảng Yên town",
      "cityCode": "Quảng Yên town",
      "provinceCode": "Quảng Ninh Province"
    },
    {
      "city": "Tiên Yên",
      "cityCode": "Tiên Yên",
      "provinceCode": "Quảng Ninh Province"
    },
    {
      "city": "Uông Bí city",
      "cityCode": "Uông Bí city",
      "provinceCode": "Quảng Ninh Province"
    },
    {
      "city": "Vân Đồn Island",
      "cityCode": "Vân Đồn Island",
      "provinceCode": "Quảng Ninh Province"
    },
    {
      "city": "Cam Lộ",
      "cityCode": "Cam Lộ",
      "provinceCode": "Quảng Trị Province"
    },
    {
      "city": "Cồn Cỏ Island",
      "cityCode": "Cồn Cỏ Island",
      "provinceCode": "Quảng Trị Province"
    },
    {
      "city": "Đa Krông",
      "cityCode": "Đa Krông",
      "provinceCode": "Quảng Trị Province"
    },
    {
      "city": "Đông Hà city",
      "cityCode": "Đông Hà city",
      "provinceCode": "Quảng Trị Province"
    },
    {
      "city": "Gio Linh",
      "cityCode": "Gio Linh",
      "provinceCode": "Quảng Trị Province"
    },
    {
      "city": "Hải Lăng",
      "cityCode": "Hải Lăng",
      "provinceCode": "Quảng Trị Province"
    },
    {
      "city": "Hướng Hóa",
      "cityCode": "Hướng Hóa",
      "provinceCode": "Quảng Trị Province"
    },
    {
      "city": "Quảng Trị town",
      "cityCode": "Quảng Trị town",
      "provinceCode": "Quảng Trị Province"
    },
    {
      "city": "Triệu Phong",
      "cityCode": "Triệu Phong",
      "provinceCode": "Quảng Trị Province"
    },
    {
      "city": "Vĩnh Linh",
      "cityCode": "Vĩnh Linh",
      "provinceCode": "Quảng Trị Province"
    },
    {
      "city": "Châu Thành",
      "cityCode": "Châu Thành",
      "provinceCode": "Sóc Trăng Province"
    },
    {
      "city": "Cù Lao Dung",
      "cityCode": "Cù Lao Dung",
      "provinceCode": "Sóc Trăng Province"
    },
    {
      "city": "Long Phú",
      "cityCode": "Long Phú",
      "provinceCode": "Sóc Trăng Province"
    },
    {
      "city": "Kế Sách",
      "cityCode": "Kế Sách",
      "provinceCode": "Sóc Trăng Province"
    },
    {
      "city": "Mỹ Tú",
      "cityCode": "Mỹ Tú",
      "provinceCode": "Sóc Trăng Province"
    },
    {
      "city": "Mỹ Xuyên",
      "cityCode": "Mỹ Xuyên",
      "provinceCode": "Sóc Trăng Province"
    },
    {
      "city": "Ngã Năm town",
      "cityCode": "Ngã Năm town",
      "provinceCode": "Sóc Trăng Province"
    },
    {
      "city": "Sóc Trăng city",
      "cityCode": "Sóc Trăng city",
      "provinceCode": "Sóc Trăng Province"
    },
    {
      "city": "Thạnh Trị",
      "cityCode": "Thạnh Trị",
      "provinceCode": "Sóc Trăng Province"
    },
    {
      "city": "Trần Đề",
      "cityCode": "Trần Đề",
      "provinceCode": "Sóc Trăng Province"
    },
    {
      "city": "Vĩnh Châu town",
      "cityCode": "Vĩnh Châu town",
      "provinceCode": "Sóc Trăng Province"
    },
    {
      "city": "Bắc Yên",
      "cityCode": "Bắc Yên",
      "provinceCode": "Sơn La Province"
    },
    {
      "city": "Mai Sơn",
      "cityCode": "Mai Sơn",
      "provinceCode": "Sơn La Province"
    },
    {
      "city": "Mộc Châu",
      "cityCode": "Mộc Châu",
      "provinceCode": "Sơn La Province"
    },
    {
      "city": "Mường La",
      "cityCode": "Mường La",
      "provinceCode": "Sơn La Province"
    },
    {
      "city": "Phù Yên",
      "cityCode": "Phù Yên",
      "provinceCode": "Sơn La Province"
    },
    {
      "city": "Quỳnh Nhai",
      "cityCode": "Quỳnh Nhai",
      "provinceCode": "Sơn La Province"
    },
    {
      "city": "Sơn La city",
      "cityCode": "Sơn La city",
      "provinceCode": "Sơn La Province"
    },
    {
      "city": "Sông Mã",
      "cityCode": "Sông Mã",
      "provinceCode": "Sơn La Province"
    },
    {
      "city": "Sốp Cộp",
      "cityCode": "Sốp Cộp",
      "provinceCode": "Sơn La Province"
    },
    {
      "city": "Thuận Châu",
      "cityCode": "Thuận Châu",
      "provinceCode": "Sơn La Province"
    },
    {
      "city": "Vân Hồ",
      "cityCode": "Vân Hồ",
      "provinceCode": "Sơn La Province"
    },
    {
      "city": "Yên Châu",
      "cityCode": "Yên Châu",
      "provinceCode": "Sơn La Province"
    },
    {
      "city": "Bến Cầu",
      "cityCode": "Bến Cầu",
      "provinceCode": "Tây Ninh Province"
    },
    {
      "city": "Châu Thành",
      "cityCode": "Châu Thành",
      "provinceCode": "Tây Ninh Province"
    },
    {
      "city": "Dương Minh Châu",
      "cityCode": "Dương Minh Châu",
      "provinceCode": "Tây Ninh Province"
    },
    {
      "city": "Gò Dầu",
      "cityCode": "Gò Dầu",
      "provinceCode": "Tây Ninh Province"
    },
    {
      "city": "Hòa Thành",
      "cityCode": "Hòa Thành",
      "provinceCode": "Tây Ninh Province"
    },
    {
      "city": "Tân Biên",
      "cityCode": "Tân Biên",
      "provinceCode": "Tây Ninh Province"
    },
    {
      "city": "Tân Châu",
      "cityCode": "Tân Châu",
      "provinceCode": "Tây Ninh Province"
    },
    {
      "city": "Tây Ninh city",
      "cityCode": "Tây Ninh city",
      "provinceCode": "Tây Ninh Province"
    },
    {
      "city": "Trảng Bàng",
      "cityCode": "Trảng Bàng",
      "provinceCode": "Tây Ninh Province"
    },
    {
      "city": "Đông Hưng",
      "cityCode": "Đông Hưng",
      "provinceCode": "Thái Bình Province"
    },
    {
      "city": "Hưng Hà",
      "cityCode": "Hưng Hà",
      "provinceCode": "Thái Bình Province"
    },
    {
      "city": "Kiến Xương",
      "cityCode": "Kiến Xương",
      "provinceCode": "Thái Bình Province"
    },
    {
      "city": "Quỳnh Phụ",
      "cityCode": "Quỳnh Phụ",
      "provinceCode": "Thái Bình Province"
    },
    {
      "city": "Thái Bình city",
      "cityCode": "Thái Bình city",
      "provinceCode": "Thái Bình Province"
    },
    {
      "city": "Thái Thụy",
      "cityCode": "Thái Thụy",
      "provinceCode": "Thái Bình Province"
    },
    {
      "city": "Tiền Hải",
      "cityCode": "Tiền Hải",
      "provinceCode": "Thái Bình Province"
    },
    {
      "city": "Vũ Thư",
      "cityCode": "Vũ Thư",
      "provinceCode": "Thái Bình Province"
    },
    {
      "city": "Đại Từ",
      "cityCode": "Đại Từ",
      "provinceCode": "Thái Nguyên Province"
    },
    {
      "city": "Định Hóa",
      "cityCode": "Định Hóa",
      "provinceCode": "Thái Nguyên Province"
    },
    {
      "city": "Đồng Hỷ",
      "cityCode": "Đồng Hỷ",
      "provinceCode": "Thái Nguyên Province"
    },
    {
      "city": "Phổ Yên town",
      "cityCode": "Phổ Yên town",
      "provinceCode": "Thái Nguyên Province"
    },
    {
      "city": "Phú Bình",
      "cityCode": "Phú Bình",
      "provinceCode": "Thái Nguyên Province"
    },
    {
      "city": "Phú Lương",
      "cityCode": "Phú Lương",
      "provinceCode": "Thái Nguyên Province"
    },
    {
      "city": "Sông Công city",
      "cityCode": "Sông Công city",
      "provinceCode": "Thái Nguyên Province"
    },
    {
      "city": "Thái Nguyên city",
      "cityCode": "Thái Nguyên city",
      "provinceCode": "Thái Nguyên Province"
    },
    {
      "city": "Võ Nhai",
      "cityCode": "Võ Nhai",
      "provinceCode": "Thái Nguyên Province"
    },
    {
      "city": "Bá Thước",
      "cityCode": "Bá Thước",
      "provinceCode": "Thanh Hóa Province"
    },
    {
      "city": "Bỉm Sơn town",
      "cityCode": "Bỉm Sơn town",
      "provinceCode": "Thanh Hóa Province"
    },
    {
      "city": "Cẩm Thủy",
      "cityCode": "Cẩm Thủy",
      "provinceCode": "Thanh Hóa Province"
    },
    {
      "city": "Đông Sơn",
      "cityCode": "Đông Sơn",
      "provinceCode": "Thanh Hóa Province"
    },
    {
      "city": "Hà Trung",
      "cityCode": "Hà Trung",
      "provinceCode": "Thanh Hóa Province"
    },
    {
      "city": "Hậu Lộc",
      "cityCode": "Hậu Lộc",
      "provinceCode": "Thanh Hóa Province"
    },
    {
      "city": "Hoằng Hóa",
      "cityCode": "Hoằng Hóa",
      "provinceCode": "Thanh Hóa Province"
    },
    {
      "city": "Lang Chánh",
      "cityCode": "Lang Chánh",
      "provinceCode": "Thanh Hóa Province"
    },
    {
      "city": "Mường Lát",
      "cityCode": "Mường Lát",
      "provinceCode": "Thanh Hóa Province"
    },
    {
      "city": "Nga Sơn",
      "cityCode": "Nga Sơn",
      "provinceCode": "Thanh Hóa Province"
    },
    {
      "city": "Ngọc Lặc",
      "cityCode": "Ngọc Lặc",
      "provinceCode": "Thanh Hóa Province"
    },
    {
      "city": "Như Thanh",
      "cityCode": "Như Thanh",
      "provinceCode": "Thanh Hóa Province"
    },
    {
      "city": "Như Xuân",
      "cityCode": "Như Xuân",
      "provinceCode": "Thanh Hóa Province"
    },
    {
      "city": "Nông Cống",
      "cityCode": "Nông Cống",
      "provinceCode": "Thanh Hóa Province"
    },
    {
      "city": "Quan Hóa",
      "cityCode": "Quan Hóa",
      "provinceCode": "Thanh Hóa Province"
    },
    {
      "city": "Quan Sơn",
      "cityCode": "Quan Sơn",
      "provinceCode": "Thanh Hóa Province"
    },
    {
      "city": "Quảng Xương",
      "cityCode": "Quảng Xương",
      "provinceCode": "Thanh Hóa Province"
    },
    {
      "city": "Sầm Sơn city",
      "cityCode": "Sầm Sơn city",
      "provinceCode": "Thanh Hóa Province"
    },
    {
      "city": "Thạch Thành",
      "cityCode": "Thạch Thành",
      "provinceCode": "Thanh Hóa Province"
    },
    {
      "city": "Thanh Hóa city",
      "cityCode": "Thanh Hóa city",
      "provinceCode": "Thanh Hóa Province"
    },
    {
      "city": "Thiệu Hóa",
      "cityCode": "Thiệu Hóa",
      "provinceCode": "Thanh Hóa Province"
    },
    {
      "city": "Thọ Xuân",
      "cityCode": "Thọ Xuân",
      "provinceCode": "Thanh Hóa Province"
    },
    {
      "city": "Thường Xuân",
      "cityCode": "Thường Xuân",
      "provinceCode": "Thanh Hóa Province"
    },
    {
      "city": "Tĩnh Gia",
      "cityCode": "Tĩnh Gia",
      "provinceCode": "Thanh Hóa Province"
    },
    {
      "city": "Triệu Sơn",
      "cityCode": "Triệu Sơn",
      "provinceCode": "Thanh Hóa Province"
    },
    {
      "city": "Vĩnh Lộc",
      "cityCode": "Vĩnh Lộc",
      "provinceCode": "Thanh Hóa Province"
    },
    {
      "city": "Yên Định",
      "cityCode": "Yên Định",
      "provinceCode": "Thanh Hóa Province"
    },
    {
      "city": "A Lưới",
      "cityCode": "A Lưới",
      "provinceCode": "Thừa Thiên–Huế Province"
    },
    {
      "city": "Huế city",
      "cityCode": "Huế city",
      "provinceCode": "Thừa Thiên–Huế Province"
    },
    {
      "city": "Hương Thủy town",
      "cityCode": "Hương Thủy town",
      "provinceCode": "Thừa Thiên–Huế Province"
    },
    {
      "city": "Hương Trà town",
      "cityCode": "Hương Trà town",
      "provinceCode": "Thừa Thiên–Huế Province"
    },
    {
      "city": "Nam Đông",
      "cityCode": "Nam Đông",
      "provinceCode": "Thừa Thiên–Huế Province"
    },
    {
      "city": "Phong Điền",
      "cityCode": "Phong Điền",
      "provinceCode": "Thừa Thiên–Huế Province"
    },
    {
      "city": "Phú Lộc",
      "cityCode": "Phú Lộc",
      "provinceCode": "Thừa Thiên–Huế Province"
    },
    {
      "city": "Phú Vang",
      "cityCode": "Phú Vang",
      "provinceCode": "Thừa Thiên–Huế Province"
    },
    {
      "city": "Quảng Điền",
      "cityCode": "Quảng Điền",
      "provinceCode": "Thừa Thiên–Huế Province"
    },
    {
      "city": "Cái Bè",
      "cityCode": "Cái Bè",
      "provinceCode": "Tiền Giang Province"
    },
    {
      "city": "Cai Lậy town",
      "cityCode": "Cai Lậy town",
      "provinceCode": "Tiền Giang Province"
    },
    {
      "city": "Cai Lậy",
      "cityCode": "Cai Lậy",
      "provinceCode": "Tiền Giang Province"
    },
    {
      "city": "Châu Thành",
      "cityCode": "Châu Thành",
      "provinceCode": "Tiền Giang Province"
    },
    {
      "city": "Chợ Gạo",
      "cityCode": "Chợ Gạo",
      "provinceCode": "Tiền Giang Province"
    },
    {
      "city": "Gò Công town",
      "cityCode": "Gò Công town",
      "provinceCode": "Tiền Giang Province"
    },
    {
      "city": "Gò Công Dông",
      "cityCode": "Gò Công Dông",
      "provinceCode": "Tiền Giang Province"
    },
    {
      "city": "Gò Công Tây",
      "cityCode": "Gò Công Tây",
      "provinceCode": "Tiền Giang Province"
    },
    {
      "city": "Mỹ Tho city",
      "cityCode": "Mỹ Tho city",
      "provinceCode": "Tiền Giang Province"
    },
    {
      "city": "Tân Phú Đông",
      "cityCode": "Tân Phú Đông",
      "provinceCode": "Tiền Giang Province"
    },
    {
      "city": "Tân Phước",
      "cityCode": "Tân Phước",
      "provinceCode": "Tiền Giang Province"
    },
    {
      "city": "Càng Long",
      "cityCode": "Càng Long",
      "provinceCode": "Trà Vinh Province"
    },
    {
      "city": "Cầu Kè",
      "cityCode": "Cầu Kè",
      "provinceCode": "Trà Vinh Province"
    },
    {
      "city": "Cầu Ngang",
      "cityCode": "Cầu Ngang",
      "provinceCode": "Trà Vinh Province"
    },
    {
      "city": "Châu Thành",
      "cityCode": "Châu Thành",
      "provinceCode": "Trà Vinh Province"
    },
    {
      "city": "Duyên Hải town",
      "cityCode": "Duyên Hải town",
      "provinceCode": "Trà Vinh Province"
    },
    {
      "city": "Duyên Hải",
      "cityCode": "Duyên Hải",
      "provinceCode": "Trà Vinh Province"
    },
    {
      "city": "Tiểu Cần",
      "cityCode": "Tiểu Cần",
      "provinceCode": "Trà Vinh Province"
    },
    {
      "city": "Trà Cú",
      "cityCode": "Trà Cú",
      "provinceCode": "Trà Vinh Province"
    },
    {
      "city": "Trà Vinh city",
      "cityCode": "Trà Vinh city",
      "provinceCode": "Trà Vinh Province"
    },
    {
      "city": "Chiêm Hóa",
      "cityCode": "Chiêm Hóa",
      "provinceCode": "Tuyên Quang Province"
    },
    {
      "city": "Hàm Yên",
      "cityCode": "Hàm Yên",
      "provinceCode": "Tuyên Quang Province"
    },
    {
      "city": "Lâm Bình",
      "cityCode": "Lâm Bình",
      "provinceCode": "Tuyên Quang Province"
    },
    {
      "city": "Nà Hang",
      "cityCode": "Nà Hang",
      "provinceCode": "Tuyên Quang Province"
    },
    {
      "city": "Sơn Dương",
      "cityCode": "Sơn Dương",
      "provinceCode": "Tuyên Quang Province"
    },
    {
      "city": "Tuyên Quang city",
      "cityCode": "Tuyên Quang city",
      "provinceCode": "Tuyên Quang Province"
    },
    {
      "city": "Yên Sơn",
      "cityCode": "Yên Sơn",
      "provinceCode": "Tuyên Quang Province"
    },
    {
      "city": "Bình Minh town",
      "cityCode": "Bình Minh town",
      "provinceCode": "Vĩnh Long Province"
    },
    {
      "city": "Bình Tân",
      "cityCode": "Bình Tân",
      "provinceCode": "Vĩnh Long Province"
    },
    {
      "city": "Long Hồ",
      "cityCode": "Long Hồ",
      "provinceCode": "Vĩnh Long Province"
    },
    {
      "city": "Mang Thít",
      "cityCode": "Mang Thít",
      "provinceCode": "Vĩnh Long Province"
    },
    {
      "city": "Tâm Bình",
      "cityCode": "Tâm Bình",
      "provinceCode": "Vĩnh Long Province"
    },
    {
      "city": "Trà Ôn",
      "cityCode": "Trà Ôn",
      "provinceCode": "Vĩnh Long Province"
    },
    {
      "city": "Vĩnh Long city",
      "cityCode": "Vĩnh Long city",
      "provinceCode": "Vĩnh Long Province"
    },
    {
      "city": "Vũng Liêm",
      "cityCode": "Vũng Liêm",
      "provinceCode": "Vĩnh Long Province"
    },
    {
      "city": "Bình Xuyên",
      "cityCode": "Bình Xuyên",
      "provinceCode": "Vĩnh Phúc Province"
    },
    {
      "city": "Lập Thạch",
      "cityCode": "Lập Thạch",
      "provinceCode": "Vĩnh Phúc Province"
    },
    {
      "city": "Phúc Yên city",
      "cityCode": "Phúc Yên city",
      "provinceCode": "Vĩnh Phúc Province"
    },
    {
      "city": "Sông Lô",
      "cityCode": "Sông Lô",
      "provinceCode": "Vĩnh Phúc Province"
    },
    {
      "city": "Tam Đảo",
      "cityCode": "Tam Đảo",
      "provinceCode": "Vĩnh Phúc Province"
    },
    {
      "city": "Tam Dương",
      "cityCode": "Tam Dương",
      "provinceCode": "Vĩnh Phúc Province"
    },
    {
      "city": "Vĩnh Tường",
      "cityCode": "Vĩnh Tường",
      "provinceCode": "Vĩnh Phúc Province"
    },
    {
      "city": "Vĩnh Yên city",
      "cityCode": "Vĩnh Yên city",
      "provinceCode": "Vĩnh Phúc Province"
    },
    {
      "city": "Yên Lạc",
      "cityCode": "Yên Lạc",
      "provinceCode": "Vĩnh Phúc Province"
    },
    {
      "city": "Lục Yên",
      "cityCode": "Lục Yên",
      "provinceCode": "Yên Bái Province"
    },
    {
      "city": "Mù Cang Chải",
      "cityCode": "Mù Cang Chải",
      "provinceCode": "Yên Bái Province"
    },
    {
      "city": "Nghĩa Lộ town",
      "cityCode": "Nghĩa Lộ town",
      "provinceCode": "Yên Bái Province"
    },
    {
      "city": "Trạm Tấu",
      "cityCode": "Trạm Tấu",
      "provinceCode": "Yên Bái Province"
    },
    {
      "city": "Trấn Yên",
      "cityCode": "Trấn Yên",
      "provinceCode": "Yên Bái Province"
    },
    {
      "city": "Văn Chấn",
      "cityCode": "Văn Chấn",
      "provinceCode": "Yên Bái Province"
    },
    {
      "city": "Văn Yên",
      "cityCode": "Văn Yên",
      "provinceCode": "Yên Bái Province"
    },
    {
      "city": "Yên Bái city",
      "cityCode": "Yên Bái city",
      "provinceCode": "Yên Bái Province"
    },
    {
      "city": "Yên Bình",
      "cityCode": "Yên Bình",
      "provinceCode": "Yên Bái Province"
    }
  ],
}