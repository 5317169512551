import React from 'react'
import _ from 'lodash'
import moment from 'moment';
import qs from 'qs'
import {
  TransitionContainer,
  Container,
  Section,
  Image,
  Button,
  NavigationBar,
  Text,
} from './../../components'
import {
  VARIABLES,
  TYPOGRAPHYS,
  ICONS,
} from './../../themes'
import {
  ROUTE_PATH,
  redirect,
} from './../../helpers'
import {
  WarrantyItem,
} from '../WarrantyPage/WarrantyItem'
import {
  WarrantyProductList,
} from '../WarrantyProductPage/WarrantyProductList'
import { getLanguage } from '../../helpers/functions/language';

export class WarrantySearchResultPage extends React.Component {
  constructor(props) {
    super(props)
    const query = qs.parse(_.get(this.props, 'location.search', '').replace('?', ''));
    const searchType = localStorage.getItem('warrantySearchType')
    const list = JSON.parse(localStorage.getItem('warrantyList'))
    this.state = {
      id: _.get(query, 'id', ''),
      startDate: query.startDate,
      endDate: query.endDate,
      list: !_.isEmpty(list) ? list : [],
      listSearch: [],
      isLoading: false,
      searchType,
    }
  }

  formatDate(input) {
    if (!input) { return '' }
    const datePart = input.match(/\d+/g),
      year = datePart[0].substring(2), // get only two digits
      month = datePart[1], day = datePart[2]

    return day + '/' + month + '/' + year
  }

  handleBack() {
    const { id, searchType } = this.state
    if (searchType === 'warrantyProduct') {
      redirect(`${ROUTE_PATH.WARRANTY_PRODUCT.LINK}/${id}`)
    } else {
      redirect(ROUTE_PATH.WARRANTY.LINK)
    }
  }

  filterListByDate() {
    const { list, startDate, endDate, searchType } = this.state
    if (searchType === 'warrantyProduct') {
      const details = _.flatten(_.map(list, 'detail'))
      if (!_.isEmpty(details)) {
        return details.filter(data => (data.order_date && (moment(data.order_date).startOf('day')).isBetween(moment(startDate), moment(endDate), null, '[]')))
      } else {
        return []
      }
    } else {
      return list.filter(data => (data.created_date && (moment(data.created_date).startOf('day')).isBetween(moment(startDate), moment(endDate), null, '[]')))
    }
  }

  render() {
    let { list, startDate, endDate, searchType, id } = this.state

    const hasDateSelected = startDate && endDate
    const dateSelected = (hasDateSelected) ? `${this.formatDate(startDate)} - ${this.formatDate(endDate)}` : getLanguage('field.filterStartDateEndDatePlaceholder')
    if (hasDateSelected && !_.isEmpty(list)) {
      list = this.filterListByDate()
    }
    return (
      <TransitionContainer>
        <Container
          width={VARIABLES.WEBSITE_WIDTH}
          calcMinHeight='100vh'
          bgColor={VARIABLES.COLORS.LIGHT_BLUE_2}
        >
          <NavigationBar
            shadow
            pageTitle={getLanguage('warrantySearch.title')}
            actionLeft={<Image
              widthRatio='21'
              widthRatioUnit='px'
              heightRatio='21'
              heightRatioUnit='px'
              src={ICONS['ic-close-black.svg']}
              onClick={this.handleBack.bind(this)}
            />
            }
          />
          <Section
            scroll
            calcHeight='100vh - 70px'
          >
            <Section
              paddingHorizontal='20'
              paddingTop='24'
              paddingBottom='10'
            >
              <Section spacingBottom='29'>
                <Button
                  className='is-icon-right is-text-left'
                  fluid
                  height='40'
                  paddingRight='10'
                  paddingLeft='50'
                  bgColor={VARIABLES.COLORS.TRANSPARENT}
                  borderWidth='1'
                  borderStyle='solid'
                  borderColor={VARIABLES.COLORS.BLUE_2}
                  borderRadius='20'
                  justify='left'
                  onClick={() => { redirect(`${ROUTE_PATH.WARRANTY_SEARCH_DATE.LINK}?id=${id}`) }}
                >
                  <Image
                    className='icon-left-button'
                    widthRatio='14'
                    widthRatioUnit='px'
                    heightRatio='16'
                    heightRatioUnit='px'
                    src={ICONS['ic-date-blue.svg']}
                  />
                  <Button.Icon>
                    <Image
                      widthRatio='13'
                      widthRatioUnit='px'
                      heightRatio='7'
                      heightRatioUnit='px'
                      src={ICONS['ic-arrow-down-blue.svg']}
                    />
                  </Button.Icon>
                  <Text
                    fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_14}
                    color={VARIABLES.COLORS.BLUE_2}
                  >
                    {dateSelected}
                  </Text>
                </Button>
              </Section>
              <Section spacingBottom='13'>
                <Text
                  fontStyle={TYPOGRAPHYS.FIRST.MEDIUM.FS_14}
                >
                  {getLanguage('warrantySearch.totalOrders')} : {list.length}
                </Text>
              </Section>
              {!_.isEmpty(list) && searchType === 'warranty' && list.map((data, index) => (<WarrantyItem key={index} data={data} />))}
              {!_.isEmpty(list) && searchType === 'warrantyProduct' && <WarrantyProductList list={list} />}
            </Section>
          </Section>
        </Container>

      </TransitionContainer>
    )
  }
}

