import React from 'react'
import firebase from 'firebase'
import {
  Image,
  Section,
  Text,
  Grid,
  ShapeContainer,
} from './../../components'
import {
  VARIABLES,
  TYPOGRAPHYS,
  ICONS,
} from './../../themes'
import {
  ROUTE_PATH,
  redirect,
} from './../../helpers'
import { TEXT_VERSION } from '../../config/config'
import { LOCAL_STORAGE } from '../../helpers/constants/localStorage';
import { getLanguage } from '../../helpers/functions/language'
import { logout } from '../../helpers/functions/auth'
import { onOpenAlert } from '../../helpers/functions/main'
import { DATA } from '../../helpers/constants/data'
import { displayUrlPath } from '../../helpers/functions/display'

var config = {
  apiKey: "AIzaSyDFlJ9XDLGIENX4tK8y3vKWhgX2b0VbYwE",
  authDomain: "bluescope-955e7.firebaseapp.com",
}

export class SideMenu extends React.Component {
  constructor() {
    super();
    this.state = {
      name: null,
      profileImage: null
    }
  }
  componentWillMount() {
    if (!firebase.apps.length) {
      firebase.initializeApp(config);
    }
    let a = JSON.parse(localStorage.getItem(LOCAL_STORAGE.REGISTER_INFO));
    if (a) {
      this.setState({
        name: a.firstName + ' ' + a.lastName,
        profileImage: a.profileImage,
      })
    }
  }
  handleLogout() {
    logout();
  }

  handleMyProfile() {
    redirect(ROUTE_PATH.MY_PROFILE.LINK)
  }

  onShareClick() {
    if (navigator.share) {
      navigator.share({
        url: window.location.origin
      })
        .then(() => console.log('shared success'))
        .catch((error) => console.log('error ', error));
    }
    else {
      onOpenAlert({
        type: DATA.ALERT_TYPE.ERROR,
        title: getLanguage('message.fail'),
        message: getLanguage('message.browserCantShare'),
      });
    }
  }

  render() {
    const {
      points,
    } = this.props;
    const {
      profileImage
    } = this.state;
    return (
      <Section
        className='is-side-menu'
        height='100'
        heightUnit='vh'
      >
        <Section
          className='is-side-menu-content'
          width='290'
          height='100'
          heightUnit='vh'
          bgColor={VARIABLES.COLORS.WHITE}
        >
          <Section
            paddingTop='25'
            paddingRight='25'
            paddingLeft='20'
            paddingBottom='45'
            justify='flex-end'
          >
            <Image
              widthRatio='21'
              widthRatioUnit='px'
              heightRatio='21'
              heightRatioUnit='px'
              src={ICONS['ic-close-black.svg']}
              onClick={() => this.props.onCloseSideMenu()}
            />
          </Section>
          <Section
            paddingRight='40'
            paddingLeft='20'
          >
            <Section spacingBottom='80'>
              <Grid gutter='10'>
                <Grid.Column>
                  <Image
                    widthRatio='60'
                    widthRatioUnit='px'
                    heightRatio='60'
                    heightRatioUnit='px'
                    borderRadius='30'
                    src={profileImage ? displayUrlPath(profileImage) : ICONS['ic-placeholder-user.svg']}
                  />
                </Grid.Column>
                <Grid.Column flex='1'>
                  <Section
                    paddingTop='2'
                    spacingBottom='10'
                  >
                    <Text
                      fontStyle={TYPOGRAPHYS.FIRST.BOLD.FS_18}
                      color={VARIABLES.COLORS.BLACK}
                      width='160'
                      className='is-side-menu-name'
                    >
                      {this.state.name ? this.state.name : 'TrueBlue'}
                    </Text>
                  </Section>
                  <Section>
                    <ShapeContainer
                      fluid
                      paddingVertical='3'
                      paddingHorizontal='10'
                      bgColor={VARIABLES.COLORS.BLUE_2}
                      borderRadius={VARIABLES.BUTTON.BORDER_RADIUSES.BR_10}
                    >
                      <Grid justify='space-between'>
                        <Grid.Column>
                          <Image
                            widthRatio='11'
                            widthRatioUnit='px'
                            heightRatio='15'
                            heightRatioUnit='px'
                            src={ICONS['ic-medal.svg']}
                            top='-1'
                          />
                        </Grid.Column>
                        <Grid.Column >
                          <Text
                            singleLine
                            fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_14}
                            color={VARIABLES.COLORS.WHITE}
                          >
                            {points.toLocaleString()} {getLanguage('sideMenu.points')}
                          </Text>
                        </Grid.Column>
                      </Grid>
                    </ShapeContainer>
                  </Section>
                </Grid.Column>
              </Grid>
            </Section>
            <Section
              spacingBottom='35'
              onClick={() => {
                redirect(ROUTE_PATH.MY_PROFILE.LINK)
              }}
            >
              <Grid gutter='20'>
                <Grid.Column>
                  <Image
                    widthRatio='18'
                    widthRatioUnit='px'
                    heightRatio='18'
                    heightRatioUnit='px'
                    src={ICONS['ic-user-blue.svg']}
                  />
                </Grid.Column>
                <Grid.Column>
                  <Text
                    fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_16}
                    color={VARIABLES.COLORS.GRAY_12}
                  >
                    {getLanguage('sideMenu.myProfile')}
                  </Text>
                </Grid.Column>
              </Grid>
            </Section>
            <Section
              spacingBottom='35'
              borderBottomWidth='1'
              borderBottomStyle='solid'
              borderBottomColor={VARIABLES.COLORS.GRAY_10}
            />
            <Section
              spacingBottom='25'
              onClick={() => {
                redirect(ROUTE_PATH.SETTING.LINK)
              }}
            >
              <Grid gutter='20'>
                <Grid.Column>
                  <Image
                    widthRatio='18'
                    widthRatioUnit='px'
                    heightRatio='18'
                    heightRatioUnit='px'
                    src={ICONS['ic-setting-blue.svg']}
                  />
                </Grid.Column>
                <Grid.Column>
                  <Text
                    fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_16}
                    color={VARIABLES.COLORS.GRAY_12}
                  >
                    {getLanguage('sideMenu.settings')}
                  </Text>
                </Grid.Column>
              </Grid>
            </Section>
            {/* <Section
              spacingBottom='25'
              onClick={this.onShareClick.bind(this)}
            >
              <Grid gutter='20'>
                <Grid.Column>
                  <Image
                    widthRatio='18'
                    widthRatioUnit='px'
                    heightRatio='20'
                    heightRatioUnit='px'
                    src={ICONS['ic-share-outline.svg']}
                  />
                </Grid.Column>
                <Grid.Column>
                  <Text
                    fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_16}
                    color={VARIABLES.COLORS.GRAY_12}
                  >
                    {getLanguage('sideMenu.shareWithFriends')}
                  </Text>
                </Grid.Column>
              </Grid>
            </Section> */}
            <Section
              spacingBottom='25'
              onClick={() => {
                redirect(ROUTE_PATH.TERM_POLICY.LINK)
              }}
            >
              <Grid gutter='20'>
                <Grid.Column>
                  <Image
                    widthRatio='18'
                    widthRatioUnit='px'
                    heightRatio='18'
                    heightRatioUnit='px'
                    src={ICONS['ic-information-blue.svg']}
                  />
                </Grid.Column>
                <Grid.Column>
                  <Text
                    fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_16}
                    color={VARIABLES.COLORS.GRAY_12}
                  >
                    {getLanguage('sideMenu.termsAndConditions')}
                  </Text>
                </Grid.Column>
              </Grid>
            </Section>
            <Section spacingBottom='25'>
              <Grid gutter='20'>
                <Grid.Column>
                  <Image
                    widthRatio='19'
                    widthRatioUnit='px'
                    heightRatio='18'
                    heightRatioUnit='px'
                    src={ICONS['ic-logout-blue.svg']}
                  />
                </Grid.Column>
                <Grid.Column>
                  <Text
                    fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_16}
                    color={VARIABLES.COLORS.GRAY_12}
                    onClick={this.handleLogout.bind(this)}
                  >
                    {getLanguage('sideMenu.logOut')}
                  </Text>
                </Grid.Column>
              </Grid>
            </Section>

          </Section>
          <Section className='is-version-text-side-menu'>
            <Text
              fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_14}
              color={VARIABLES.COLORS.GRAY_4}
            >
              Version {TEXT_VERSION}
            </Text>
          </Section>
        </Section>
      </Section>
    )
  }
}
