import { purchaseHistoryController } from "../../apiService/apiController/purchaseHistoryService";
import { isValidResponse } from "../functions";
import _ from "lodash";
import { handleTrimParams } from "../functions/data";
import { ModelPurchaseHistory } from "../../models/ModelPurchaseHistory";

export const getPurchaseHistory = async (params) => {
  const purchaseHistoryService = purchaseHistoryController();
  let newParam = handleTrimParams({
    ...params
  })
  const res = await purchaseHistoryService.getPurchaseHistory(newParam);
  if (isValidResponse(res)) {
    let dataList = res.data.map((e) => new ModelPurchaseHistory(e));
    console.log('getPurchaseHistory', res.data, dataList);
    return dataList;
  }
  return [];
}
