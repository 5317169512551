import React from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import { Helmet } from 'react-helmet';
import {
  TransitionContainer,
  Container,
  Image,
  Section,
  Text,
  Grid,
  SwiperCarousel,
  CommentPanel,
} from './../../components';
import {
  VARIABLES,
  TYPOGRAPHYS,
  CONTENTS,
  ICONS,
} from './../../themes';
import {
  ROUTE_PATH,
  redirect,
  getRouteParams,
  formatDatetime,
} from './../../helpers'
import axios from 'axios';
import config from '../../config';
import _ from 'lodash';
import { Loader } from '../Loader';
import ReactHtmlParser from 'react-html-parser';

export class PublicNewsAndPromotionsPage extends React.Component {

  constructor(props) {
    super(props);
    const id = parseInt(getRouteParams(props, 'id'));
    this.state = {
      id,
      data: {}
    };
  }

  componentDidMount() {
    this.publicNewsAndPromotions.getData();
  }

  publicNewsAndPromotions = {
    getData: () => {
      const {
        id,
      } = this.state;
      this.setState({
        isLoading: true,
      }, async () => {
        const url = `${config.api.cms}/newspromotion/get/`
        const options = {
          sort: 'display_index asc, public_date desc',
          status: 'active',
          app: 'mobileapp'
        }
        const response = await axios.post(url, options)
        const dataList = _.get(response, 'data.data');
        if (response.status === 200 && !_.isEmpty(dataList)) {
          const data = dataList.filter(e => e.id === id)[0];
          if (data) {
            this.setState({
              data,
              isLoading: false
            })
          }
          else {
            this.setState({
              isLoading: false
            })
          }
        }
        else {
          this.setState({
            isLoading: false
          })
        }
      });
    }
  }

  render() {
    const {
      data,
      isLoading,
    } = this.state
    return (
      <React.Fragment>
        <Helmet>
          <title>{data.subject}</title>
          <meta name='description' content={data.content} />
          <meta property='og:url' content={window.location.href} />
          <meta property='og:type' content='article' />
          <meta property='og:title' content={data.subject} />
          <meta property='og:description' content={data.content} />
          <meta property='og:image' content={!_.isEmpty(data.images) ? `${config.imgPrefix}/${data.images[0]}` : ''} />
          <meta name='twitter:card' content='summary' />
        </Helmet>
        <TransitionContainer
        // motion='overlap-from'
        >
          <Container
            calcMinHeight='100vh'
            bgColor={VARIABLES.COLORS.GRAY_5}
          >
            <Scrollbars style={{ height: '100vh' }}>
              <Container
                width='640'
                calcMinHeight='100vh'
                paddingTop='35'
                paddingHorizontal='15'
                bgColor={VARIABLES.COLORS.WHITE}
              >
                <Section
                  paddingBottom='35'
                  paddingHorizontal='20'
                >
                  {!_.isEmpty(data.images) ?
                    <SwiperCarousel className='is-banner-news-and-promotions'>
                      {data.images.map((e, i) => {
                        return (
                          <SwiperCarousel.Item
                            key={i}
                          >
                            <Image
                              heightRatio='93'
                              src={`${config.imgPrefix}/${e}`}
                            />
                          </SwiperCarousel.Item>
                        )
                      })}
                    </SwiperCarousel>
                    :
                    undefined
                  }
                </Section>
                <Section
                  paddingBottom='125'
                  paddingHorizontal='20'
                >
                  <Section spacingBottom='10'>
                    <Text
                      fontStyle={TYPOGRAPHYS.FIRST.BOLD.FS_22}
                      color={VARIABLES.COLORS.BLACK}
                    >
                      {data.subject}
                    </Text>
                  </Section>
                  <Section spacingBottom='20'>
                    <Grid gutter='6'>
                      <Grid.Column>
                        <Image
                          widthRatio='12'
                          widthRatioUnit='px'
                          heightRatio='13'
                          heightRatioUnit='px'
                          top='-2'
                          src={ICONS['ic-date-gray.svg']}
                        />
                      </Grid.Column>
                      <Grid.Column>
                        <Text
                          fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_12}
                          color={VARIABLES.COLORS.GRAY_3}
                        >
                          {formatDatetime(data.public_date || data.create_at, 'DD MMM, YYYY')}
                        </Text>
                      </Grid.Column>
                    </Grid>
                  </Section>
                  <Section
                    className='text-editor-content'
                    spacingBottom='15'
                  >
                    {ReactHtmlParser(data.content)}
                  </Section>
                </Section>
              </Container>
            </Scrollbars>
            {/* <CommentPanel
              className='is-public'
              likeAmount='0'
              commentAmount='0'
              onClickSeeComments={() => {
                redirect(ROUTE_PATH.PUBLIC_NEWS_PROMOTIONS_COMMENT.LINK);
              }}
              onClickWriteComment={() => {
                redirect(ROUTE_PATH.PUBLIC_NEWS_PROMOTIONS_WRITE_COMMENT.LINK);
              }}
            /> */}
          </Container>
        </TransitionContainer>
        <Loader isOverlay
          loading={isLoading}
        />
      </React.Fragment>
    )
  }
}
