import React from 'react'
import {
  TransitionContainer,
  Container,
  Text,
  Image,
  NavigationBar,
  Section,
} from './../../components'
import {
  VARIABLES,
  TYPOGRAPHYS,
  ICONS
} from './../../themes'
import {
  ROUTE_PATH,
  redirect,
} from './../../helpers'
import {
  EstimatorInput,
} from './EstimatorInput'
import {
  Information,
} from './Information'
import {
  Loader
} from '../Loader'
import { getLanguage } from '../../helpers/functions/language'
import { DATA } from '../../helpers/constants/data';
import { onOpenAlert } from '../../helpers/functions/main';
import { getRouteData } from '../../helpers/functions/routes';

const values = {
  roofType: '',
  profileType: '',
  width: '',
  length: '',
  height: '',
  overhang: '',
}

export class EstimatorPage extends React.Component {
  constructor(props) {
    super(props);
    const data = getRouteData(props, 'data') || {};
    console.log('data', data);
    this.state = {
      ...values,
      isLoading: false,
      isEstimate: false,
      roofType: data.roofType || '',
      profileType: data.profileType || '',
      width: data.width || '',
      length: data.length || '',
      height: data.height || '',
      overhang: data.overhang || '',
    }
  }

  onChangeField(key, event) {

    this.setState({ [key]: event.target.value })
  }

  onClickClearValue() {
    this.setState({ ...values })
  }

  onSubmit() {
    let { roofType, profileType, width, length, height, overhang } = this.state;
    if (roofType && profileType && width && length && height && overhang) {
      width = parseFloat(width);
      length = parseFloat(length);
      height = parseFloat(height);
      overhang = parseFloat(overhang);
      redirect(`${ROUTE_PATH.ESTIMATOR_SUMMARY.LINK}?&roofType=${roofType}&profileType=${profileType}&width=${width}&length=${length}&height=${height}&overhang=${overhang}`)
    }
    else {
      onOpenAlert({
        type: DATA.ALERT_TYPE.ERROR,
        title: '',
        message: getLanguage('message.pleaseInputAllField'),
      });
    }
  }

  handleBack() {
    redirect(ROUTE_PATH.HOME.LINK)
  }

  render() {
    const { roofType, profileType, width, length, height, overhang, isLoading } = this.state
    const input = { roofType, profileType, width, length, height, overhang }
    const hasValue = roofType || profileType || width || length || height || overhang
    return (
      <TransitionContainer>
        <Container
          width={VARIABLES.WEBSITE_WIDTH}
          calcMinHeight='100vh'
          bgColor={VARIABLES.COLORS.WHITE}
        >
          <NavigationBar
            shadow
            iconWidth='21'
            iconHeight='18'
            iconSrc={ICONS['ic-navigation-estimator.svg']}
            pageTitleIcon={getLanguage('estimator.title')}
            actionLeft={
              <Image
                widthRatio='24'
                widthRatioUnit='px'
                heightRatio='23'
                heightRatioUnit='px'
                src={ICONS['ic-home-black.svg']}
                onClick={this.handleBack.bind(this)}
              />
            }
            actionRight={
              hasValue ? <Text
                fontStyle={TYPOGRAPHYS.FIRST.MEDIUM.FS_14}
                color={VARIABLES.COLORS.BLUE_2}
                onClick={this.onClickClearValue.bind(this)}
              >
                {getLanguage('field.buttonClear')}
              </Text> : ''
            }
          />
          <Section
            paddingHorizontal='20'
            paddingTop='30'
            paddingBottom='10'
          >
            <EstimatorInput input={input} onChangeField={this.onChangeField.bind(this)} />
            <Information onSubmit={this.onSubmit.bind(this)} />
          </Section>
        </Container>
        <Loader loading={isLoading} isOverlay />
      </TransitionContainer>
    )
  }
}
