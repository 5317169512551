import React from 'react'
import {
  Section,
  Image,
  Grid,
  Field,
} from './../../components'
import {
  VARIABLES,
  TYPOGRAPHYS,
  ICONS
} from './../../themes'
import { isValidNumberNoPrefixZero, isValidFloatNumber1Digit } from '../../helpers';
import { getLanguage } from '../../helpers/functions/language';

export class EstimatorInput extends React.Component {

  input = {
    onChangeInput: (target, ev) => {
      const {
        onChangeField,
      } = this.props;
      const value = ev.target.value;
      switch (target) {
        case 'width':
        case 'length':
        case 'height':
        case 'overhang':
          if (value.length && !isValidFloatNumber1Digit(value)) {
            return;
          }
        default:
          onChangeField(target, ev)
      }
    },
  }

  render() {
    const { onChangeField, input, readOnly } = this.props
    const { roofType, profileType, width, length, height, overhang } = input
    return (
      <Section>
        <Section spacingBottom='25'>
          <Grid gutter='16'>
            <Grid.Column>
              <Section
                width='105'
                height='65'
                justify='center'
                align='center'
              >
                <Image
                  widthRatio='82'
                  widthRatioUnit='px'
                  heightRatio='40'
                  heightRatioUnit='px'
                  src={ICONS['ic-es-roof-type.svg']}
                />
              </Section>
            </Grid.Column>
            <Grid.Column flex='1'>
              <Section
                height='65'
                justify='center'
                align='center'
              >
                <Field
                  className='is-estimator'
                  type='select'
                  fluid
                  floatLabel
                  height='46'
                  label={getLanguage('field.roofType')}
                  labelFontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_12}
                  labelFontColor={VARIABLES.COLORS.GRAY_3}
                  paddingHorizontal='20'
                  borderWidth='1'
                  borderStyle='solid'
                  borderColor={VARIABLES.COLORS.GRAY_5}
                  borderRadius='5'
                  fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_14}
                  fontColor={roofType ? VARIABLES.COLORS.BLACK : VARIABLES.COLORS.GRAY_5}
                  value={roofType}
                  onChange={(e) => {
                    if (!readOnly) onChangeField('roofType', e)
                  }}
                  fieldIcon={(readOnly) ? '' : <img src={ICONS['ic-arrow-down-gray.svg']} alt='Icon' />}
                  fieldIconWidth='38'
                  fieldIconSpacingRight='10'
                  disabled={readOnly}
                  disabledColor={VARIABLES.COLORS.TRANSPARENT}
                >
                  <option value="" disabled selected hidden>{getLanguage('field.roofTypePlaceholder')}</option>
                  <option value="gable_roof">{getLanguage('estimator.roofType.gableRoof')}</option>
                  <option value="hip_roof">{getLanguage('estimator.roofType.hipRoof')}</option>
                </Field>
              </Section>
            </Grid.Column>
          </Grid>
        </Section>
        <Section spacingBottom='25'>
          <Grid gutter='16'>
            <Grid.Column>
              <Section
                width='105'
                height='65'
                justify='center'
                align='center'
              >
                <Image
                  widthRatio='68'
                  widthRatioUnit='px'
                  heightRatio='47'
                  heightRatioUnit='px'
                  src={ICONS['ic-es-profile-type.svg']}
                />
              </Section>
            </Grid.Column>
            <Grid.Column flex='1'>
              <Section
                height='65'
                justify='center'
                align='center'
              >
                <Field
                  className='is-estimator'
                  type='select'
                  fluid
                  floatLabel
                  height='46'
                  label={getLanguage('field.profileType')}
                  labelFontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_12}
                  labelFontColor={VARIABLES.COLORS.GRAY_3}
                  paddingHorizontal='20'
                  borderWidth='1'
                  borderStyle='solid'
                  borderColor={VARIABLES.COLORS.GRAY_5}
                  borderRadius='5'
                  fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_14}
                  fontColor={profileType ? VARIABLES.COLORS.BLACK : VARIABLES.COLORS.GRAY_5}
                  value={profileType}
                  onChange={(e) => {
                    if (!readOnly) onChangeField('profileType', e)
                  }}
                  fieldIcon={readOnly ? '' : <img src={ICONS['ic-arrow-down-gray.svg']} alt='Icon' />}
                  fieldIconWidth='38'
                  fieldIconSpacingRight='10'
                  disabled={readOnly}
                  disabledColor={VARIABLES.COLORS.TRANSPARENT}
                >
                  <option value="" disabled selected hidden>{getLanguage('field.profileTypePlaceholder')}</option>
                  <option value="Tile">{getLanguage('estimator.profileType.tile')}</option>
                  <option value="Sheet">{getLanguage('estimator.profileType.sheet')}</option>
                </Field>
              </Section>
            </Grid.Column>
          </Grid>
        </Section>
        <Section spacingBottom='25'>
          <Grid gutter='16'>
            <Grid.Column>
              <Section
                width='105'
                height='65'
                justify='center'
                align='center'
              >
                <Image
                  widthRatio='52'
                  widthRatioUnit='px'
                  heightRatio='64'
                  heightRatioUnit='px'
                  src={ICONS['ic-es-width.svg']}
                />
              </Section>
            </Grid.Column>
            <Grid.Column flex='1'>
              <Section
                height='65'
                justify='center'
                align='center'
              >
                <Field
                  className='is-estimator'
                  fluid
                  floatLabel
                  height='46'
                  label={getLanguage('field.widthUnit')}
                  labelFontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_12}
                  labelFontColor={VARIABLES.COLORS.GRAY_3}
                  placeholder='ex.8.00'
                  placeholderColor={VARIABLES.COLORS.GRAY_5}
                  paddingHorizontal='20'
                  borderWidth='1'
                  borderStyle='solid'
                  borderColor={VARIABLES.COLORS.GRAY_5}
                  borderRadius='5'
                  fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_14}
                  fontColor={VARIABLES.COLORS.BLACK}
                  value={width}
                  onChange={(e) => {
                    if (!readOnly) this.input.onChangeInput('width', e)
                  }}
                  disabled={readOnly}
                  disabledColor={VARIABLES.COLORS.TRANSPARENT}
                />
              </Section>
            </Grid.Column>
          </Grid>
        </Section>
        <Section spacingBottom='25'>
          <Grid gutter='16'>
            <Grid.Column>
              <Section
                width='105'
                height='65'
                justify='center'
                align='center'
              >
                <Image
                  widthRatio='70'
                  widthRatioUnit='px'
                  heightRatio='65'
                  heightRatioUnit='px'
                  src={ICONS['ic-es-length.svg']}
                />
              </Section>
            </Grid.Column>
            <Grid.Column flex='1'>
              <Section
                height='65'
                justify='center'
                align='center'
              >
                <Field
                  className='is-estimator'
                  fluid
                  floatLabel
                  height='46'
                  label={getLanguage('field.lengthUnit')}
                  labelFontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_12}
                  labelFontColor={VARIABLES.COLORS.GRAY_3}
                  placeholder='ex. 12.00'
                  placeholderColor={VARIABLES.COLORS.GRAY_5}
                  paddingHorizontal='20'
                  borderWidth='1'
                  borderStyle='solid'
                  borderColor={VARIABLES.COLORS.GRAY_5}
                  borderRadius='5'
                  fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_14}
                  fontColor={VARIABLES.COLORS.BLACK}
                  value={length}
                  onChange={(e) => {
                    if (!readOnly) this.input.onChangeInput('length', e)
                  }}
                  disabled={readOnly}
                  disabledColor={VARIABLES.COLORS.TRANSPARENT}
                />
              </Section>
            </Grid.Column>
          </Grid>
        </Section>
        <Section spacingBottom='25'>
          <Grid gutter='16'>
            <Grid.Column>
              <Section
                width='105'
                height='65'
                justify='center'
                align='center'
              >
                <Image
                  widthRatio='52'
                  widthRatioUnit='px'
                  heightRatio='57'
                  heightRatioUnit='px'
                  src={ICONS['ic-es-height.svg']}
                />
              </Section>
            </Grid.Column>
            <Grid.Column flex='1'>
              <Section
                height='65'
                justify='center'
                align='center'
              >
                <Field
                  className='is-estimator'
                  fluid
                  floatLabel
                  height='46'
                  label={getLanguage('field.heightUnit')}
                  labelFontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_12}
                  labelFontColor={VARIABLES.COLORS.GRAY_3}
                  placeholder='ex. 2.00'
                  placeholderColor={VARIABLES.COLORS.GRAY_5}
                  paddingHorizontal='20'
                  borderWidth='1'
                  borderStyle='solid'
                  borderColor={VARIABLES.COLORS.GRAY_5}
                  borderRadius='5'
                  fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_14}
                  fontColor={VARIABLES.COLORS.BLACK}
                  value={height}
                  onChange={(e) => {
                    if (!readOnly) this.input.onChangeInput('height', e)
                  }}
                  disabled={readOnly}
                  disabledColor={VARIABLES.COLORS.TRANSPARENT}
                />
              </Section>
            </Grid.Column>
          </Grid>
        </Section>
        <Section
          spacingBottom='40'
        >
          <Grid gutter='16'>
            <Grid.Column>
              <Section
                width='105'
                height='65'
                justify='center'
                align='center'
              >
                <Image
                  widthRatio='89'
                  widthRatioUnit='px'
                  heightRatio='48'
                  heightRatioUnit='px'
                  src={ICONS['ic-es-overhang.svg']}
                />
              </Section>
            </Grid.Column>
            <Grid.Column flex='1'>
              <Section
                height='65'
                justify='center'
                align='center'
              >
                <Field
                  className='is-estimator'
                  fluid
                  floatLabel
                  height='46'
                  label={getLanguage('field.overhangUnit')}
                  labelFontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_12}
                  labelFontColor={VARIABLES.COLORS.GRAY_3}
                  placeholder='ex. 200'
                  placeholderColor={VARIABLES.COLORS.GRAY_5}
                  paddingHorizontal='20'
                  borderWidth='1'
                  borderStyle='solid'
                  borderColor={VARIABLES.COLORS.GRAY_5}
                  borderRadius='5'
                  fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_14}
                  fontColor={VARIABLES.COLORS.BLACK}
                  value={overhang}
                  onChange={(e) => {
                    if (!readOnly) this.input.onChangeInput('overhang', e)
                  }}
                  disabled={readOnly}
                  disabledColor={VARIABLES.COLORS.TRANSPARENT}
                />
              </Section>
            </Grid.Column>
          </Grid>
        </Section>
      </Section>
    )
  }
}
