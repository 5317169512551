import React from 'react'
import _ from 'lodash'
import axios from 'axios'
import {
  TransitionContainer,
  Container,
  Section,
  Image,
  NavigationBar,
  Text,
} from './../../components'
import {
  VARIABLES,
  TYPOGRAPHYS,
  ICONS,
} from './../../themes'
import {
  ROUTE_PATH,
  redirect,
} from './../../helpers'
import {
  RewardCatItem,
} from './RewardCatItem'
import {
  Loader
} from '../Loader'
import config from './../../config'
import { getTodayDateDiff, getAvailableCountryDataList } from '../../helpers/functions/data'
import { getLanguage } from '../../helpers/functions/language'

export class RewardCatPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      id: (this.props.match.params && this.props.match.params.id) ? +this.props.match.params.id : '',
      isLoading: false,
      list: [],
      titleRewardGroup: '',
    }
  }

  componentDidMount() {
    localStorage.removeItem('rewardCatalogList')
    this.setState({ isLoading: true }, async () => {
      try {
        const url = `${config.api.cms}/reward/search/`
        // const options = { category_id: this.state.id, sort: 'id desc', status: 'active' }
        const options = {
          category_id: '',
          sort: 'id desc',
          status: 'active'
        }
        const response = await axios.post(url, options)
        let data = _.get(response, 'data.data')
        if (response.status === 200 && !_.isEmpty(data)) {
          data = getAvailableCountryDataList(data);
          data = data.map(e => {
            e.isNew = getTodayDateDiff(e.create_at, 'month') < 1;
            return e
          })
          this.setState({ list: data }, () => {
            localStorage.setItem('rewardCatalogList', JSON.stringify(data))
          });
        } else {
          this.setTitleFromRewardGroup()
        }
      } catch (_) {
        this.setTitleFromRewardGroup()
      } finally {
        this.setState({ isLoading: false })
      }
    })
  }

  setTitleFromRewardGroup() {
    const data = JSON.parse(localStorage.getItem('rewardGroupList'))
    if (_.isEmpty(data)) return
    const result = data.find(item => item.id === this.state.id)
    if (result) {
      this.setState({ titleRewardGroup: result.name })
    }
  }

  handleBack() {
    // redirect(ROUTE_PATH.REWARD.LINK);
    redirect(ROUTE_PATH.HOME.LINK);
  }

  onClickSearch() {
    redirect(ROUTE_PATH.REWARD_SEARCH.LINK);
  }

  render() {
    const { list, isLoading, titleRewardGroup } = this.state
    return (
      <TransitionContainer>
        <Container
          width={VARIABLES.WEBSITE_WIDTH}
          calcMinHeight='100vh'
          bgColor={VARIABLES.COLORS.LIGHT_BLUE_2}
        >
          <NavigationBar
            shadow
            // pageTitle={_.isEmpty(list) ? titleRewardGroup : _.get(list, '0.category_name')}
            iconWidth='20'
            iconHeight='20'
            iconSrc={ICONS['ic-navigation-reward.svg']}
            pageTitleIcon={getLanguage('rewardCat.title')}
            actionLeft={
              <Image
                widthRatio='11'
                widthRatioUnit='px'
                heightRatio='21'
                heightRatioUnit='px'
                src={ICONS['ic-left-black.svg']}
                onClick={this.handleBack.bind(this)}
              />
            }
            actionRight={
              <Image
                widthRatio='21'
                widthRatioUnit='px'
                heightRatio='21'
                heightRatioUnit='px'
                src={ICONS['ic-search-black.svg']}
                onClick={this.onClickSearch.bind(this)}
              />
            }
          />
          <Section
            scroll
            calcHeight='100vh - 70px'
          >
            <Section
              paddingHorizontal='20'
              paddingTop='24'
              paddingBottom='10'
            >
              {!_.isEmpty(list) && list.map((data, index) => (<RewardCatItem key={index} data={data} />))}
              {!isLoading && _.isEmpty(list) && <Section
                paddingTop='24'
                justify='center'
              >
                <Text
                  fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_16}
                  color={VARIABLES.COLORS.BLUE_2}
                >
                  {getLanguage('rewardCat.blank')}
                </Text>
              </Section>}
            </Section>
          </Section>
        </Container>
        <Loader loading={isLoading} isOverlay />
      </TransitionContainer>
    )
  }
}

