import axios from 'axios';
import _ from 'lodash';
import moment from 'moment';
import React from 'react';
import { fetchApi } from '../../apiService/apiService';
import {
  Container,
  Grid,
  Image,
  Section,
  ShapeContainer,
  Text,
  TransitionContainer,
} from '../../components';
import { ModalExpriePointContainer } from '../../containers/ModalContainer';
import { ENUM } from '../../helpers/constants/enum';
import { LOCAL_STORAGE } from '../../helpers/constants/localStorage';
import { getUserAuth } from '../../helpers/functions/auth';
import {
  getCountryType,
  setCountryDataList,
  setUerAuthLocalStorage,
} from '../../helpers/functions/data';
import {
  displayUrlPath,
  displayWithComma,
} from '../../helpers/functions/display';
import { getLanguage } from '../../helpers/functions/language';
import { isShowLoading } from '../../helpers/functions/main';
import {
  getLuckyDrawAnnouncementList,
  getLuckyDrawExpirePoint
} from '../../helpers/services/luckyDraw';
import { getUserBy } from '../../helpers/services/users';
import { ModelLuckyDraw } from "../../models/ModelLuckyDraw";
import { storeGetState } from '../../store';
import config from './../../config';
import { ICONS, SHAREDS, TYPOGRAPHYS, VARIABLES } from './../../themes';
import { MenuTrueBlue } from './MenuTrueBlue';
import { SideMenu } from './SideMenu';

export class HomePage extends React.Component {
  constructor(props) {
    super(props);
    const user = getUserAuth();
    if (user) {
      const countryList = storeGetState().dataRedux.countryList;
      if (!countryList.length) {
        setCountryDataList(user.countries);
      }
    }
    this.state = {
      showSideMenu: false,
      newsPromotionList: [],
      isLoading: false,
      points: 0,
      user: user || {},

      accumulateDate: {
        startDate: moment(),
        endDate: moment(),
      },
      totalPoint: 0,
      totalLength: 0,
      totalPurchasing: 0,

      expirePoint: 0,
      expireDate: '',
      isShowModalExpire: false,
      expireDataList: undefined,
      dataAnnouncement: new ModelLuckyDraw(),
    };
  }

  componentWillMount() {
    // let a = JSON.parse(localStorage.getItem(LOCAL_STORAGE.REGISTER_INFO));
    // if (a) {
    //   this.setState({
    //     profileImage: a.profileImage,
    //   })
    // }

    localStorage.removeItem('newsPromotionList');
    this.setState({ isLoading: true }, async () => {
      try {
        const url = `${config.api.cms}/newspromotion/get/`;
        const options = {
          sort: 'display_index asc, public_date desc',
          status: 'active',
          app: 'mobileapp',
          is_banner: true,
        };
        const response = await axios.post(url, options);
        let data = _.get(response, 'data.data');
        if (response.status === 200 && !_.isEmpty(data)) {
          let a = JSON.parse(localStorage.getItem(LOCAL_STORAGE.REGISTER_INFO));
          const addressType = getCountryType(a.countries);
          data = data.filter(
            (e) => e.available_country.indexOf(addressType) >= 0
          );
          this.setState({ newsPromotionList: data }, () => {
            localStorage.setItem('newsPromotionList', JSON.stringify(data));
          });
        }
        await this.handleUpdateCustomerRefId();
        // this.getPoints()
      } catch (_) {
      } finally {
        this.setState({ isLoading: false });
      }
    });
  }

  componentDidMount() {
    this.home.getHomeData();
  }

  handleUpdateCustomerRefId = async () => {
    let a = JSON.parse(localStorage.getItem(LOCAL_STORAGE.REGISTER_INFO));
    if (a) {
      let prefix_number;
      switch (a.countries.toLowerCase()) {
        case ENUM.COUNTRY.THAI.toLowerCase():
          prefix_number = 66;
          break;
        case ENUM.COUNTRY.MALAYSIA.toLowerCase():
          prefix_number = 60;
          break;
        case ENUM.COUNTRY.VIETNAM.toLowerCase():
          prefix_number = 84;
          break;
        case ENUM.COUNTRY.INDONESIA.toLowerCase():
          prefix_number = 62;
          break;
        case ENUM.COUNTRY.LAOS.toLowerCase():
          prefix_number = 856;
          break;
        default:
          prefix_number = 66;
          break;
      }
      const url = `${config.api.trueblue}/customer/search`;
      const options = {
        page: 1,
        limit: 1,
        sort: 'id desc',
        phone: prefix_number + a.phone,
      };
      const response = await axios.post(url, options);
      const data = _.get(response, 'data.data');
      if (response.status === 200 && !_.isEmpty(data)) {
        const customerData = data[0];
        if (
          !customerData.user_ref_id ||
          customerData.user_ref_id !== a.id.toString()
        ) {
          customerData.user_ref_id = a.id;
          console.log('customerData', customerData, a);
          const urlUpdateCustomer = `${config.api.trueblue}/customer/${customerData.id}`;
          customerData.no_sms = true;
          await axios.put(urlUpdateCustomer, customerData);
        }
      }
    }
  };

  // async getPoints() {
  //   try {
  //     const { user } = this.state;
  //     if (!user.id) return
  //     const url = `${config.api.base}/api/points/total/earn/${user.id}`
  //     const response = await fetchApi.get(url)
  //     const points = _.get(response, 'data.points_earn_total')
  //     if (response.status === 200 && points) {
  //       this.setState({ points })
  //     }
  //   } catch (_) {
  //   }
  // }

  onToggleShowSideMenu() {
    this.setState({ showSideMenu: !this.state.showSideMenu });
  }

  onCloseSideMenu() {
    this.setState({ showSideMenu: false });
  }

  onOpenModalExpire() {
    this.setState({ isShowModalExpire: true });
  }

  onCloseModalExpire() {
    this.setState({ isShowModalExpire: false });
  }

  home = {
    getHomeData: async () => {
      isShowLoading(true);
      const accumulateDate = this.home.getAccumulate();
      console.log('accumulateDate', accumulateDate);
      this.setState(
        {
          accumulateDate,
        },
        () => {
          // this.home.getUser(
          //   async () => {
          //     await this.home.getPointSearch();
          //     await this.home.getProductMember();
          //     await this.home.getLuckyDrawCampaign();
          //     await this.home.getLuckyDrawExpirePoint();
          //     isShowLoading(false);
          //   },
          //   () => {
          //     isShowLoading(false);
          //   }
          // );
          const time1 = new Date().getTime();
          Promise.all([
            this.home.getUser(),
            this.home.getPointSearch(),
            this.home.getProductMember(),
            this.home.getLuckyDrawCampaign(),
            this.home.getLuckyDrawExpirePoint(),
          ]).then(() => {
            const time2 = new Date().getTime();
            console.log('diff time success', time2 - time1)
            isShowLoading(false);
          }).catch(() => {
            const time2 = new Date().getTime();
            console.log('diff time error', time2 - time1)
            isShowLoading(false);
          });
        }
      );
    },
    getAccumulate: () => {
      const currentYear = moment().format('YYYY');
      const firstDateOfYear = moment(`01/07/${currentYear}`, 'DD/MM/YYYY');
      const diffCount = moment().diff(firstDateOfYear);
      let startDate, endDate;
      if (diffCount >= 0) {
        // use current year and year + 1
        startDate = firstDateOfYear;
        endDate = moment(firstDateOfYear).add(1, 'years').add(-1, 'days');
      } else {
        // use year - 1 and current year
        startDate = moment(firstDateOfYear).add(-1, 'years');
        endDate = moment(firstDateOfYear).add(-1, 'days');
      }
      return {
        startDate,
        endDate,
      };
    },
    // getPointSearch: async () => {
    //   const {
    //     accumulateDate,
    //     user,
    //   } = this.state;
    //   if (user.locationAccount && user.customerId) {
    //     const url = `${config.api.trueblue}/point/search/`
    //     const options = {
    //       ad_ref_id: user.locationAccount,
    //       customer_id: user.customerId,
    //       // limit: 10,
    //       // page: 1,
    //       sort: "id desc",
    //     }
    //     const response = await axios.post(url, options)
    //     let data = _.get(response, 'data.points');
    //     console.log('getPointSearch', data);
    //     if (response.status === 200 && !_.isEmpty(data)) {
    //       const pointList = [];
    //       let totalPoint = 0;
    //       data.forEach(e => {
    //         if (moment(e.createddate).isBetween(accumulateDate.startDate, accumulateDate.endDate)) {
    //           pointList.push(e);
    //           totalPoint += e.points || 0;
    //         }
    //       });
    //       console.log('pointList', pointList, totalPoint);
    //       this.setState({
    //         totalPoint,
    //       })
    //     }
    //   }
    // },
    // getPointSearch: async () => {
    //   const {
    //     user,
    //   } = this.state;
    //   const url = `${config.api.base}/api/points/${user.id}`
    //   const response = await fetchApi.get(url)
    //   const data = _.get(response, 'data')
    //   if (response.status === 200 && !_.isEmpty(data)) {
    //     this.setState({
    //       totalPoint: _.get(data, 'remain_point', 0),
    //     })
    //   }
    // },
    getPointSearch: async () => {
      const { user } = this.state;
      const url = `${config.api.base}/api/points/user/${user.sfid}`;
      const response = await fetchApi.get(url);
      const resData = _.get(response, 'data');
      if (response.status === 200 && !_.isEmpty(resData)) {
        const data = resData.data;
        this.setState({
          totalPoint: _.get(data, 'point_left__c', 0),
        });
      }
    },
    getUser: async (callbackSuccess = () => { }, callbackError = () => { }) => {
      const { user } = this.state;
      const options = {
        // id: 875,
        id: user.id,
      };
      let data = await getUserBy(options);
      console.log('getUser', data);
      if (!_.isEmpty(data)) {
        setUerAuthLocalStorage(data);
        this.setState(
          {
            user: getUserAuth(),
          },
          () => {
            callbackSuccess();
          }
        );
      } else {
        callbackError();
      }
    },
    getProductMember: async () => {
      const { user, accumulateDate } = this.state;
      if (user.sfid) {
        const url = `${config.api.trueblue}/sfdc/product/member/${user.sfid}`;
        const response = await fetchApi.get(url);
        let data = _.get(response, 'data.data');
        console.log('getProductMember', data);
        if (response.status === 200 && !_.isEmpty(data)) {
          const productMemberList = [];
          let totalLength = 0;
          let totalPurchasing = data.length;
          data.forEach((e) => {
            if (
              moment(e.createddate).isBetween(
                accumulateDate.startDate,
                accumulateDate.endDate
              )
            ) {
              productMemberList.push(e);
              totalLength += e.coil_length__c || 0;
            }
          });
          console.log('productMemberList', productMemberList);
          console.log('totalPurchasing', totalPurchasing);
          console.log('totalLength', totalLength);
          this.setState({
            totalPurchasing,
            totalLength,
          });
        }
      }
    },
    getLuckyDrawCampaign: async () => {
      const params = {
        page: 1,
        limit: 1
      }
      const dataList = await getLuckyDrawAnnouncementList(params);
      if (dataList.length) {
        this.setState({
          dataAnnouncement: dataList[0]
        });
      }
    },
    getLuckyDrawExpirePoint: async () => {
      const { user } = this.state;
      const data = await getLuckyDrawExpirePoint(user.sfid);
      let sumPoint = 0;

      this.setState({
        expirePoint: data[0].point,
        expireDate: data[0].displayExpireDate,
        expireDataList: data,
      });
    },
  };

  render() {
    const {
      showSideMenu,
      isLoading,
      // points,
      accumulateDate,
      totalPoint,
      user,
      totalLength,
      totalPurchasing,
      dataAnnouncement,
      expirePoint,
      expireDate,
      expireDataList,
      isShowModalExpire,
    } = this.state;
    return (
      <TransitionContainer>
        <Container
          width={VARIABLES.WEBSITE_WIDTH}
          calcMinHeight='100vh'
          bgColor={VARIABLES.COLORS.GRAY_11}
        >
          <Section relative>
            <ShapeContainer
              className='is-user-profile'
              width='46'
              height='46'
              borderRadius='23'
              boxShadow={VARIABLES.COLORS.SHADOW_3}
              onClick={() => this.onToggleShowSideMenu()}
            >
              <Image
                widthRatio='46'
                widthRatioUnit='px'
                heightRatio='46'
                heightRatioUnit='px'
                borderRadius='23'
                // src={CONTENTS['user-1.jpg']
                src={
                  user.profileImage
                    ? displayUrlPath(user.profileImage)
                    : ICONS['ic-placeholder-avatar.svg']
                }
              />
              <ShapeContainer
                className='is-menu-button'
                justify='center'
                align='center'
                width='22'
                height='22'
                bgColor={VARIABLES.COLORS.WHITE}
                borderRadius='11'
                boxShadow={VARIABLES.COLORS.SHADOW_4}
              >
                <Image
                  widthRatio='12'
                  widthRatioUnit='px'
                  heightRatio='8'
                  heightRatioUnit='px'
                  src={ICONS['ic-hamburger.svg']}
                />
              </ShapeContainer>
            </ShapeContainer>

            <Section className='is-account-info'>
              <Section width='165' spacingBottom='-2'>
                <Text
                  ellipsis
                  fontStyle={TYPOGRAPHYS.FIRST.BOLD.FS_18}
                  color={VARIABLES.COLORS.WHITE}
                >
                  {user.fullname}
                </Text>
              </Section>
              <Section>
                <Text
                  fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_14}
                  color={VARIABLES.COLORS.WHITE}
                >
                  {user.displayType}
                </Text>
              </Section>
            </Section>

            {user.isTrained && (
              <Section className='is-certified'>
                <Section justify='center'>
                  <img
                    src={ICONS['ic-certified-green.svg']}
                    width='49'
                    height='46'
                    alt='Icon'
                  />
                </Section>
                <Text
                  fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_10}
                  color={VARIABLES.COLORS.GREEN_3}
                  align='center'
                >
                  {getLanguage('home.training')}
                </Text>
              </Section>
            )}
            <Section relative>
              <ShapeContainer
                className='is-trueblue-points'
                justify='center'
                align='center'
                width='160'
                height='160'
                borderWidth='5'
                borderStyle='solid'
                borderColor={VARIABLES.COLORS.WHITE}
                borderRadius='80'
              >
                <Section justify='center' spacingBottom='8'>
                  <Text
                    fontStyle={TYPOGRAPHYS.FIRST.BOLD.FS_48}
                    color={VARIABLES.COLORS.WHITE}
                    align='center'
                  >
                    {displayWithComma(totalPoint)}
                  </Text>
                </Section>
                <Text
                  fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_14}
                  color={VARIABLES.COLORS.WHITE}
                  align='center'
                >
                  {getLanguage('home.tbPoints')}
                </Text>
              </ShapeContainer>
              <Image
                heightRatio='100'
                src={SHAREDS['bg-trueblue-member.png']}
              />
            </Section>
            <Section zIndexLv1 justify='center' top='-72'>
              <Grid justify='center' gutter='10' align='center'>
                <Grid.Column>
                  <Section
                    borderRadius='20'
                    padding='6'
                    bgColor={VARIABLES.COLORS.WHITE}
                    align='center'
                  >
                    <Text
                      color={VARIABLES.COLORS.RED_1}
                      fontStyle={TYPOGRAPHYS.FIRST.BOLD.FS_14}
                    >
                      {displayWithComma(expirePoint)}
                      {getLanguage('home.point')}
                    </Text>
                  </Section>
                </Grid.Column>
                <Grid.Column>
                  <Section
                    align='center'
                    onClick={this.onOpenModalExpire.bind(this)}
                  >
                    <Text
                      color={VARIABLES.COLORS.WHITE}
                      fontStyle={TYPOGRAPHYS.FIRST.MEDIUM.FS_14}
                      underlineWhite
                    >
                      {getLanguage('home.willExpireOn')} {expireDate}
                    </Text>
                  </Section>
                </Grid.Column>
              </Grid>
            </Section>
          </Section>
          <MenuTrueBlue
            dataAnnouncement={dataAnnouncement}
            accumulateDate={accumulateDate}
            totalLength={totalLength}
            totalPurchasing={totalPurchasing}
            trainedTier={user.trainedTier__c}
            onClickExpire={this.onOpenModalExpire.bind(this)}
          />

          {showSideMenu && (
            <SideMenu
              onCloseSideMenu={this.onCloseSideMenu.bind(this)}
              points={totalPoint}
            />
          )}

          {isShowModalExpire && (
            <ModalExpriePointContainer
              isShowModal={isShowModalExpire}
              dataList={expireDataList}
              summaryPoint={totalPoint}
              onClickClose={this.onCloseModalExpire.bind(this)}
            />
          )}
        </Container>
      </TransitionContainer>
    );
  }
}
