import React from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import { Helmet } from 'react-helmet';
import {
  TransitionContainer,
  Container,
  Image,
  Section,
  Text,
  Grid,
  SwiperCarousel,
  CommentPanel,
} from './../../components';
import {
  VARIABLES,
  TYPOGRAPHYS,
  CONTENTS,
  ICONS,
} from './../../themes';
import {
  ROUTE_PATH,
  redirect,
  getRouteParams,
} from './../../helpers';
import { getLanguage } from '../../helpers/functions/language';
import axios from 'axios';
import config from '../../config';
import { Loader } from '../Loader';
import { fetchApi } from '../../apiService/apiService';

export class PublicMyProjectPage extends React.Component {

  constructor(props) {
    super(props);
    const userId = parseInt(getRouteParams(props, 'userId'));
    const projectId = parseInt(getRouteParams(props, 'projectId'));
    this.state = {
      userId,
      projectId,
      data: {}
    };
  }

  componentDidMount() {
    this.publicProject.getProject();
  }

  publicProject = {
    getProject: () => {
      const {
        userId,
        projectId,
      } = this.state;
      this.setState({
        isLoading: true,
      }, async () => {
        const res = await fetchApi.get(`${config.api.base}/api/projects/${userId}`)
        if (res && res.data && res.data.length) {
          const data = res.data.filter(e => e.id === projectId)[0]
          if (data) {
            this.setState({
              data,
              isLoading: false
            })
          }
          else {
            this.setState({
              isLoading: false
            })
          }
        } else {
          this.setState({
            isLoading: false
          })
        }
      });
    }
  }

  render() {
    const {
      isLoading,
      data,
    } = this.state
    console.log('data', data)
    return (
      <React.Fragment>
        <Helmet>
          <title>{data.name}</title>
          <meta name='description' content={data.description} />
          <meta property='og:url' content={window.location.href} />
          <meta property='og:type' content='article' />
          <meta property='og:title' content={data.name} />
          <meta property='og:description' content={data.description} />
          <meta property='og:image' content={(data.image_url && data.image_url.length) ? data.image_url[0] : ''} />
          <meta name='twitter:card' content='summary' />
        </Helmet>
        <TransitionContainer
        // motion='overlap-from'
        >
          <Container
            calcMinHeight='100vh'
            bgColor={VARIABLES.COLORS.GRAY_5}
          >
            <Scrollbars style={{ height: '100vh' }}>
              <Container
                width='640'
                calcMinHeight='100vh'
                paddingVertical='35'
                paddingHorizontal='35'
                bgColor={VARIABLES.COLORS.WHITE}
              >
                <Section spacingBottom='35'>
                  {data.image_url && data.image_url.length ?
                    <SwiperCarousel className='is-banner-news-and-promotions'>
                      {data.image_url.map((e, i) => {
                        return (
                          <SwiperCarousel.Item
                            key={i}
                          >
                            <Image
                              heightRatio='93'
                              src={e}
                            />
                          </SwiperCarousel.Item>
                        )
                      })}
                    </SwiperCarousel>
                    :
                    undefined
                  }
                </Section>
                <Section spacingBottom='5'>
                  <Text
                    fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_12}
                    color={VARIABLES.COLORS.GRAY_2}
                  >
                    {getLanguage('field.projectName')}
                  </Text>
                </Section>
                <Section
                  spacingBottom='10'
                  paddingBottom='15'
                  borderBottomColor={VARIABLES.COLORS.GRAY_1}
                  borderBottomWidth='1'
                  borderBottomStyle='solid'
                >
                  <Text
                    fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_14}
                    color={VARIABLES.COLORS.BLACK}
                  >
                    {data.name}
                  </Text>
                </Section>
                <Section spacingBottom='5'>
                  <Text
                    fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_12}
                    color={VARIABLES.COLORS.GRAY_2}
                  >
                    {getLanguage('field.location')}
                  </Text>
                </Section>
                <Section
                  spacingBottom='10'
                  paddingBottom='15'
                  borderBottomColor={VARIABLES.COLORS.GRAY_1}
                  borderBottomWidth='1'
                  borderBottomStyle='solid'
                >
                  <Text
                    fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_14}
                    color={VARIABLES.COLORS.BLACK}
                  >
                    {data.location}
                  </Text>
                </Section>
                <Section spacingBottom='5'>
                  <Text
                    fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_12}
                    color={VARIABLES.COLORS.GRAY_2}
                  >
                    {getLanguage('field.areaSizeProject')}
                  </Text>
                </Section>
                <Section
                  spacingBottom='10'
                  paddingBottom='15'
                  borderBottomColor={VARIABLES.COLORS.GRAY_1}
                  borderBottomWidth='1'
                  borderBottomStyle='solid'
                >
                  <Text
                    fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_14}
                    color={VARIABLES.COLORS.BLACK}
                  >
                    {data.area_size}
                  </Text>
                </Section>
                <Section spacingBottom='5'>
                  <Text
                    fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_12}
                    color={VARIABLES.COLORS.GRAY_2}
                  >
                    {getLanguage('field.product')}
                  </Text>
                </Section>
                <Section
                  spacingBottom='10'
                  paddingBottom='15'
                  borderBottomColor={VARIABLES.COLORS.GRAY_1}
                  borderBottomWidth='1'
                  borderBottomStyle='solid'
                >
                  <Text
                    fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_14}
                    color={VARIABLES.COLORS.BLACK}
                  >
                    {data.product}
                  </Text>
                </Section>
                <Section spacingBottom='5'>
                  <Text
                    fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_12}
                    color={VARIABLES.COLORS.GRAY_2}
                  >
                    {getLanguage('field.description')}
                  </Text>
                </Section>
                <Section className='text-editor-content is-my-project-description'>
                  {data.description}
                </Section>
              </Container>
            </Scrollbars>
          </Container>
        </TransitionContainer>
        <Loader isOverlay
          loading={isLoading}
        />
      </React.Fragment>
    )
  }
}
