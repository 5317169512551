export const lang_vi = {
  sideMenu: {
    points: 'Điểm',
    myProfile: 'Thông tin của tôi',
    settings: 'Cài đặt',
    shareWithFriends: 'Chia sẻ với bạn bè',
    termsAndConditions: 'Điều kiện và điều khoản',
    logOut: 'Đăng xuất',
  },
  splash: {
    title: 'Growing your business with you',
    description: 'Engage . Showcase . Grow',
  },
  login: {
    loginByEmail: 'Login by Email',
    or: 'Or',
    loginByPhoneNumber: 'Login by Phone Number',
    orLoginWith: 'Or login with',
    reLogin: 'For account security, please log in again.',
    incorrectMemberType: 'Incorrect member type.',
  },
  loginEmail: {
    message: 'forgot password?',
    message_2: 'Choose again?',
  },
  loginPhone: {
    message: 'forgot password?',
    message_2: 'Choose again?',
  },
  welcome: {
    message_1: 'Welcome',
    message_2: 'Thanks, you are now a member of TrueBlue Program',
    message_3: 'and',
    message_4: 'you can check your point as well',
  },
  referenceCode: {
    message_1: 'Reference Code',
    message_2: 'Expired',
  },
  referralAd: {
    message_1: 'How to get',
    message_2: 'Voucher',
    message_3: 'Must be BlueScope customer',
    message_4: 'and TrueBlue member from',
    message_5: '30 Jun 2021',
  },
  lineOaValidation: {
    message_1: 'We have added a new way to',
    message_2: 'provide news and benefits.',
    message_3: 'Please scan QR code or',
    message_4: 'click button to receive.',
    message_5: 'or',
    message_6: 'Welcome',
  },
  voucherOutOfStock: {
    message_1: 'Sorry,',
    message_2: "you do not meet the conditions to",
    message_3: 'receive the E-Voucher.',
    message_4: 'In order not to miss out on receiving special privileges from',
    message_5: 'BlueScope next time',
    message_6: 'You can sign up to become a TrueBlue',
    message_7: 'Member through',
    message_8: 'LineOA today.',
    message_9: 'The purpose is to notify TrueBlue',
    message_10: 'points on every purchase.',
  },
  registerSuccess: {
    message_1: 'Register Success!',
    message_2: 'and you get',
    message_3: 'a voucher',
    message_4: 'A Voucher will be sent to the registered address',
    message_5: 'Sorry, the voucher',
    message_6: 'is sold out.',
  },
  authenticationSuccess: {
    message_1: 'Authentication Success!',
    message_2: 'Please return to TrueBlue',
  },
  register: {
    title_1: 'Sign Up',
    title_2: 'Verification OTP',
    message_1: 'Already have an account?',
    message_2: 'sent to',
  },
  home: {
    buttonViewMore: 'Xem thêm',
    tbPoints: 'Điểm TrueBlue',
    tbRewards: 'Phần thưởng TrueBlue',
    estimator: 'Ước tính',
    newsAndPromotions: 'Tin tức & Ưu đãi',
    myProjects: 'Dự án của tôi',
    products: 'Sản phẩm',
    findDealer: 'Tìm kiếm đại lý',
    knowledgeBase: 'Tài liệu tham khảo',
    purchaseHistory: 'Lịch sử mua hàng',
    orderInquiry: 'Yêu cầu đặt hàng',
    warranty: 'Bảo hành',
    training: 'TRAINING',
    your: 'Your',
    length: 'Length',
    metre: 'm',
    purchasing: 'Purchasing',
    cumulativeAmountSince: 'Cumulative amount since',
    tbMember: 'TrueBlue Member',
    rankInformation: 'Rank Information',
    moreDetail: 'More Detail',
    moreDetailExtra: 'More Details',
    noRank: 'No Rank',
    luckyDraw: 'Lucky Draw',
    willExpireOn: 'will expire on',
    point: 'point',
    checkPoint: 'Check points will expire',

  },
  myProfile: {
    title: 'Thông tin của tôi',
    updateProfile: 'Cập nhật thông tin',
    changePassword: 'Thay đổi mật khẩu',
  },
  changePassword: {
    title: 'Thay đổi mật khẩu',
  },
  forgotPassword: {
    title: 'Forgot Password',
    labelEnterEmail: 'Vui lòng nhập email của bạn',
    labelEnterPhone: 'Vui lòng nhập Số điện thoại của bạn',
  },
  resetPassword: {
    title: 'Thay đổi mật khẩu',
  },
  setting: {
    title: 'Cài đặt',
    language: 'Cài đặt ngôn ngữ',
    notification: 'Cài đặt thông báo',
  },
  settingNoti: {
    title: 'Cài đặt thông báo',
    orderInquiry: 'Yêu cầu đặt hàng',
    newsAndPromotions: 'Tin tức & Ưu đãi',
  },
  settingLanguage: {
    title: 'Language',
    chooseLanguage: 'Chọn ngôn ngữ của bạn',
    pleaseSelectLanguage: 'Vui lòng chọn ngôn ngữ của bạn',
  },
  termPolicy: {
    title: 'Chính sách và điều khoản',
  },
  loyaltyPoint: {
    title: 'Điểm TrueBlue',
    points: 'Điểm',
    expire: 'Điểm hết hạn trong',
    expireIn: 'Điểm hết hạn trong vòng',
    tab: {
      earnedPoints: {
        title: 'Điểm đạt được',
      },
      redeemedPoints: {
        title: 'Điểm đã quy đổi',
      },
    },
    tbPoints: 'Điểm TrueBlue',
    numberOfRewards: 'Number of Rewards',
  },
  rewardCat: {
    title: 'Phần thưởng TrueBlue',
    blank: 'Dữ liệu không tìm thấy',
    new: 'Mới',
    points: 'Điểm',
  },
  reward: {
    title: 'Phần thưởng',
  },
  rewardSearch: {
    title_1: 'Tìm kiếm Kết quả',
    title_2: 'Tìm kiếm',
    rewardName: 'Tên phần thưởng',
    searchResult: 'Tìm kiếm Kết quả',
  },
  rewardDetail: {
    points: 'Điểm',
  },
  estimator: {
    title: 'Ước tính',
    roofType: {
      gableRoof: 'Mái dốc một phương',
      hipRoof: 'Mái dốc hai phương',
    },
    profileType: {
      tile: 'Ngói',
      sheet: 'Tôn',
    },
    notes: 'Ghi chú',
    noteWidth: 'Chiều rộng: 4 : 12m',
    notePitch: 'Độ dốc: 25 : 35 độ',
    noteOverhang: 'Đoạn đua mái <= 500mm',
    buttonEstimation: 'Ước tính',
    footer_1: 'Lưu ý : Công cụ Ước tính chỉ để mục đích tham khảo',
    footer_2: 'và dựa vào thông tin được cung cấp bởi người dùng.',
    footer_3: 'NS BlueScope không bảo đảm độ chính xác của bất kỳ nội dung',
    footer_4: 'kết quả hay chi tiết cụ thể của bạn',
    footer_5: 'Vui lòng tham khảo đường dẫn',
    footer_6: 'Điều khoản sử dụng',
    footer_7: 'của việc sử dụng để biết thêm chi tiết.',
  },
  estimatorSummary: {
    title: 'Kết quả tổng quát',
    quantityEstimate: 'Số lượng Ước tính',
    congratulations: 'Chúc mừng !!',
    note_1: 'Bạn đã tiến đến một bước gần hơn đến việc xây dựng dự án mơ ước',
    note_2: 'Vui lòng ghé thăm đại lý Tôn Zacs gần nhất',
    note_3: 'Hoàn thành giao dịch',
    category: {
      title_1: 'Category : Roof',
      note_1: 'number of screw for roofing sheet will depend on profile type.',
      title_2: 'Category : Roof Structure',
      title_3: 'Category : Accessory',
      type: 'Thể loại',
      profile: 'Thông tin tài khoản',
      qty: 'Số lượng',
      unit: 'Đơn vị tính',
      notes: 'Ghi chú',
    }
  },
  newsAndPromotions: {
    title: 'Tin tức & Ưu đãi',
    tab: {
      news: {
        title: 'Tin tức',
      },
      promotions: {
        title: 'Ưu đãi',
      },
    },
  },
  newsAndPromotionsComment: {
    title: 'Bình luận'
  },
  newsAndPromotionsWriteComment: {
    title: 'Bình luận',
    specifyTitle: 'Vui lòng nhập tiêu đề',
    specifyComment: 'Vui lòng nhập bình luận',
    characters: 'Ký tự',
  },
  newsAndPromotionsSearch: {
    title: 'Tìm kiếm',
    searchResult: 'Tìm kiếm Kết quả :',
  },
  myProjects: {
    title: 'Dự án của tôi',
  },
  projectsAdd: {
    title: 'Dự án của tôi',
  },
  projectsEdit: {
    title: 'Chỉnh sửa Dự án',
  },
  projectsSearch: {
    title_1: 'Tìm kiếm Kết quả',
    title_2: 'Tìm kiếm',
    searchProject: 'Tìm kiếm dự án',
    searchResult: 'Tìm kiếm Kết quả :',
  },
  productCatalogs: {
    title: 'Danh mục sản phẩm',
  },
  findDealer: {
    title: 'Tìm kiếm đại lý',
    findDealer: 'Tìm kiếm đại lý',
    selectDealer: {
      title: 'Vui lòng chọn loại đại lý‎',
      bad: 'Đại lý uỷ quyền Tôn Zacs',
      or: 'Xưởng cán khác',
      distributor: 'Distributor',
      tokoStore: 'Toko / Store BlueScope Zacs™',
      indoBad: 'BlueScope Authorized Dealer',
    }
  },
  findDealerType: {
    title: 'Loại đại lý',
    nearestDealers: 'Tìm kiếm đại lý gần nhất',
    byName: 'Tìm kiếm theo tên',
    byArea: 'Chọn theo khu vực',
    or: 'Hoặc',
  },
  findDealerSearch: {
    title: 'Đại lý gần bạn',
    dealerCard: {
      buttonGetDirection: 'Chỉ đường',
      buttonViewDetail: 'Xem chi tiết',
    }
  },
  findDealerSearchByName: {
    title: 'Tìm kiếm theo tên',
    byName: 'Tìm kiếm theo tên',
  },
  findDealerSearchByArea: {
    title: 'Chọn theo khu vực',
  },
  knowledgeBase: {
    title: 'Tài liệu tham khảo'
  },
  knowledgeBaseDetail: {
    title: 'Tài liệu tham khảo',
    tab: {
      description: {
        title: 'Mô tả',
      },
      documentsDownload: {
        title: 'Tài liệu / Tải về',
      },
    },
  },
  purchaseHistory: {
    title_1: 'Filter Result',
    title_2: 'Lịch sử mua hàng',
    totalSales: 'Tổng doanh số',
    DataNotFound: 'Không tìm thấy dữ liệu',
  },
  purchaseHistorySearch: {
    title: 'Lọc theo ngày',
  },
  purchaseHistoryDetail: {
    title: 'Lịch sử mua hàng',
  },
  orderInquiry: {
    title: 'Yêu cầu đặt hàng',
    category: {
      bad: 'Đại l‎y’ ủy quyền',
      or: 'Xưởng cán khác',
    },
    selectDealer: 'Chọn đại lý',
    result: 'Kết quả',
    orderInquiry: 'Yêu cầu đặt hàng',
    allPreviousOrderInquiry: 'Lịch sử yêu cầu đặt hàng',
    dataNotFound: 'Không tìm thấy dữ liệu',
  },
  orderInquirySearch: {
    title: 'Tìm kiếm',
    category: {
      bad: 'Đại lý uỷ quyền',
      or: 'Xưởng cán khác',
    },
    selectDealer: 'Chọn đại lý',
    suggestions: 'Gợi ý',
  },
  orderInquiryAdd: {
    title: 'Nhập yêu cầu đặt hàng',
  },
  warranty: {
    title: 'Warranty',
  },
  warrantySearchByDate: {
    title: 'Lọc theo ngày',
  },
  warrantySearchByName: {
    title_1: ' Kết quả tìm kiếm ',
    title_2: 'Tìm kiếm',
    warranty: 'Số bảo hành., Tên bảo hành',
    salesADProduct: 'Mã giao dịch, AD, Tên Sản phẩm',
    searchResult: 'Kết quả tìm kiếm',
  },
  warrantySearch: {
    title: 'Lọc kết quả',
    totalOrders: 'Tổng cộng đơn đặt hàng',
  },
  warrantyProduct: {
    dataNotFound: 'Không tìm thấy dữ liệu',
  },
  warrantyProductItem: {
    issuedDate: 'Ngày phát hành',
    product: 'Sản phẩm',
    thickness: 'Độ dày',
    length: 'Chiều dài',
    warrantyID: 'Mã bảo hành',
  },
  termsAndConditions: {
    title: 'Chính sách và điều khoản',
  },
  verify: {
    title1: 'Setup Verify Questions',
    title2: 'Verify with Questions',
    title3: 'Verify with Phone Number',
    question1: 'What is your nickname?',
    question2: "What is your favorite animal?",
    question3: 'What is the province where you were born?',
  },
  container: {
    inputOtp: {
      title: 'Verification OTP',
      message: 'sent to',
      message_2: 'OTP is incorrect, Please re-send again',
    },
  },
  modal: {
    changeLanguage: {
      title: 'Chọn Ngôn ngữ',
      message: 'Ngôn ngữ của bạn đã được cập nhật.',
    },
    editProfile: {
      title: 'Chỉnh sửa thông tin',
      message_1: 'Thông tin của bạn đã được cập nhật.',
      message_2: 'Một vài thông tin có thể bị lỗi, vui lòng thử lại',
      message_3: 'Có lỗi xảy ra',
    },
    changePassword: {
      title: 'Thay đổi mật khẩu thành công!',
      message: 'Vui lòng thử mật khẩu mới của bạn vào lần đăng nhập tiếp theo.',
      fail: 'Lỗi',
      passwordNotMatch: 'Mật khẩu không khớp',
      passwordIncorrectFormat: 'Mật khẩu không đúng định dạng',
      changeSuccess: 'Thay đổi mật khẩu thành công!',
      nextLogin: 'Vui lòng thử mật khẩu mới của bạn vào lần đăng nhập tiếp theo',
    },
    commentPosted: {
      title: 'Bình luận đã được đăng!',
      message: 'Cảm ơn ý kiến đóng góp của bạn',
    },
    confirmation: {
      title: 'Xác nhận',
      message: 'Bạn muốn xoá?',
      message_2: 'Bạn có chắc chắn xoá?',
      message_2_2: 'dự án này?',
    },
    warrantyDetail: {
      projectName: 'Tên dự án',
      address: 'Địa chỉ',
      quantity: 'Số lượng',
      color: 'Màu sắc (bất kỳ)',
    },
    newVersion: {
      title_1: 'Phiên bản TrueBlue mới',
      title_2: 'có sẵn.',
    },
    forgotPassword: {
      titleSendResetLink: 'Đường dẫn đặt lại mật khẩu đã gửi đi!',
      messageCheckEmail: 'Vui lòng kiểm tra email của bạn',
      titleSendOtp: 'OTP đã được gửi!',
      messageCheckNotification: 'Vui lòng kiểm tra thông báo',
    },
    resetPassword: {
      title: 'Đặt lại mật khẩu thành công!',
      message_1: 'Vui lòng thử mật khẩu mới',
      message_2: 'vào lần đăng nhập tiếp theo',
    },
    iosAddToHomeScreen: {
      title_1: 'Add to home screen',
      title_2: 'by share',
    },
    socialInputPhone: {
      title: 'Please fill your phone number',
    },
    notiTnc: {
      title_1: 'TrueBlue Services',
      title_2: 'Terms & Condition',
      message_1: 'Before you can proceed you must',
      message_2: 'Read and accept the new',
      message_3: 'Terms & Conditions.',
    },
    otpSend: {
      title: 'OTP has been sent!',
      description: 'Please check your notification',
    },
    authentication: {
      title: 'Authentication link has been sent to your phone (SMS)',
      description1: 'Please approve the access from the link within 3 minutes',
      description2: 'Reference code is',
      description3: 'Do not close this window until the authentication is complete',
      description4: 'or',
      link: 'Re-Send Authentication Link',
    },
  },
  component: {
    component: {
      likes: 'Thích',
      comments: 'bình luận',
      buttonComment: 'Bình luận',
    }
  },
  field: {
    buttonOK: 'OK',
    buttonClose: 'Close',
    buttonSearch: 'Tìm kiếm',
    buttonClear: 'XOÁ',
    buttonSave: 'LƯU',
    buttonSaveAndChange: 'Lưu & Thay đổi',
    buttonSaveAsPhoto: 'Lưu dưới dạng hình ảnh',
    buttonSharePhoto: 'Chia sẻ hình ảnh',
    buttonSubmit: 'Gửi đi',
    buttonAddNewProject: 'Thêm dự án mới',
    buttonEditProject: 'Chỉnh sửa dự án',
    buttonDeleteProject: 'Xoá dự án',
    buttonAddPhoto: 'Thêm hình ảnh',
    buttonDelete: 'Xoá',
    buttonCancel: 'Huỷ bỏ',
    buttonFilter: 'Lọc',
    buttonAddNewOrderInquiry: 'Thêm mới yêu cầu đặt hàng',
    buttonMoreDetail: 'Thông tin chi tiết',
    buttonUpdate: 'Cập nhật',
    buttonSend: 'Gửi',
    labelNew: 'MỚI',
    labelSample: 'MẪU',
    buttonContinue: 'Continue',
    buttonSignUp: 'Sign up',
    buttonAgree: 'Agree',
    buttonReSend: 'Re-send',
    buttonLogin: 'Login',
    buttonBack: 'Back',
    buttonConfirm: 'Confirm',
    buttonApprove: 'Approve',
    buttonToLogin: 'Back to Login',
    buttonRegisterNow: 'Register Now',
    buttonGoToTrueBlue: 'Go to TrueBlue',
    buttonBlueScopeThailand: 'BlueScope Thailand',
    labelNew: 'NEW',
    labelSample: 'SAMPLE',
    firstname: 'Tên',
    firstnamePlaceholder: 'Nhập tên',
    lastname: 'Họ và tên lót',
    lastnamePlaceholder: 'Nhập Họ và tên lót',
    nickname: 'Nick Name',
    nicknamePlaceholder: 'Insert nick name',
    company: 'Company',
    companyPlaceholder: 'Insert company',
    type: 'Loại',
    typePlaceholder: 'Vui lòng chọn loại',
    phoneNumber: 'Số điện thoại',
    mobilePhoneNumber: 'Mobile Phone Number',
    email: 'Email',
    emailPlaceholder: 'Nhập email',
    birthDate: 'Ngày sinh',
    birthDatePlaceholder: 'Vui lòng chọn Ngày sinh',
    address: 'Địa chỉ',
    addressPlaceholder: 'Nhập Địa chỉ',
    province: 'Tỉnh',
    provincePlaceholder: 'Vui lòng chọn Tỉnh',
    city: 'Thành phố',
    cityPlaceholder: ' Vui lòng chọn Thành phố',
    district: 'Quận',
    districtPlaceholder: 'Nhập quận',
    subDistrict: 'Phường',
    subDistrictPlaceholder: 'Nhập Phường',
    subDistrictSelectPlaceholder: 'Please select sub-district',
    alley: 'Thôn/Ấp',
    alleyPlaceholder: 'Nhập Thôn/Ấp',
    group: 'Nhóm/Thôn',
    groupPlaceholder: 'Nhập Nhóm/Thôn',
    street: 'Đường',
    streetPlaceholder: 'Nhập tên đường',
    ward: 'Phường',
    wardPlaceholder: 'Nhập phường',
    postCode: 'Mã bưu chính',
    postCodePlaceholder: 'Nhập Mã bưu chính',
    postCodeSelectPlaceholder: 'Please select post code',
    country: 'Quốc gia',
    countryPlaceholder: 'Nhập quốc gia',
    password: 'Password',
    passwordHint: '8 to 24 characters long',
    oldPassword: 'Mật khẩu cũ',
    newPassword: 'Mật khẩu mới',
    newPasswordHint: '8 đến 24 ký tự dài',
    confirmPassword: 'Xác nhận mật khẩu',
    roofType: 'Loại mái',
    roofTypePlaceholder: 'Chọn loại mái',
    profileType: 'Loại sóng tôn',
    profileTypePlaceholder: 'Chọn loại sóng tôn',
    widthUnit: 'Chiều rộng (m)',
    lengthUnit: 'Chiều dài (m)',
    heightUnit: 'Chiều cao (m)',
    overhangUnit: 'Khoảng cách nhô ra (mm)',
    title: 'Tiêu đề',
    titlePlaceholder: 'Nhập tiêu đề',
    comment: 'Bình luận',
    commentPlaceholder: 'bạn nghĩ gì về …',
    newsAndPromotionsPlaceholder: 'Tìm kiếm tin tức & ưu đãi',
    projectName: 'Tên dự án',
    location: 'Địa điểm',
    areaSizeProject: 'Tổng diện tích của dự án',
    product: 'Sản phẩm',
    description: 'Mô tả',
    descriptionPlaceholder: 'Nhập Mô tả…',
    selectDealerPlaceholder: 'Vui lòng chọn đại lý',
    selectProvincePlaceholder: 'Chọn Tỉnh',
    selectAmphoePlaceholder: 'Select a Khet/Amphoe',
    filterStartDateEndDatePlaceholder: 'Từ ngày - Đến ngày',
    startDatePlaceholder: 'Ngày bắt đầu',
    endDatePlaceholder: 'Ngày kết thúc',
    productGroup: 'Nhóm sản phẩm',
    coilName: 'Tên cuộn',
    thickness: 'Độ dày',
    color: 'Màu sắc',
    length: 'Chiều dài',
    meter: 'meter',
    points: 'Điểm',
    points_2: 'điểm',
    where: 'Địa điểm',
    status: 'Trạng thái',
    trasactionID: 'ID giao dịch',
    selectCategoryPlaceholder: 'Chọn danh mục',
    subject: 'Chủ đề',
    subjectPlaceholder: 'Nhập Chủ đề',
    selectAreaPlaceholder: 'Chọn khu vực',
    phoneIsIncorrectFormat: 'Phone is incorrect format.',
    agreeTermsAndConditions: 'Agree Terms and conditions',
    questionPlaceholder: 'Insert Answer',
    birthDayPlaceholder: 'Select Birthday',
    customerTypeFixer: 'Thợ/Nhà thầu/Thợ lắp dựng',
    customerTypeHardware: 'Cửa hàng/Cửa hàng VLXD',
    customerTypeHomeowner: 'Chủ nhà/Chủ dự án',
    customerTypeRollFormer: 'Xưởng cán',
    customerTypeOthers: 'Khác (Military, Government)',
    customerTypeArchitect: 'Architect / Designer / Consult',
    customerTypeEngineer: 'Engineer'
  },
  message: {
    fail: 'Lỗi',
    pleaseInputPhone: 'Vui lòng nhập số điện thoại',
    pleaseInputAllField: 'Please nhập phạm vi',
    pleaseInputAllRequiredField: 'Please insert all required field',
    somethingWrong: 'Đã có lỗi xảy ra, Vui lòng thử lại sau',
    cannotGetYourCurrentLocation: 'Không thể tìm thấy địa điểm hiện tại',
    pleaseSelectStartDateAndEndDate: 'Vui lòng chọn ngày bắt đầu và kết thúc',
    invalidDateFormat: 'Định dạng ngày không hợp lệ',
    pleaseSelectCategoryAndDealer: 'Vui lòng chọn danh mục và đại lý',
    yourOrderInquiryHasBeenSent: 'Yêu cầu đặt hàng của bạn đã được gửi đi!',
    pleaseSpecifySubject: 'Vui lòng nhập chủ đề',
    pleaseSpecifyDescription: 'Vui lòng nhập mô tả',
    browserCantShare: `Trình duyệt này không thể chia sẻ, vui lòng sử dụng trình duyệt Chrome hoặc Safari.`,
    phoneDoesntExist: 'Số điện thoại này không tồn tại',
    passwordNotMatch: 'Mật khẩu không khớp',
    passwordIncorrectFormat: 'Mật khẩu không đúng định dạng',
    dataNotFound: 'Data not found',
    widthOutOfCondition: 'Width out of range',
    pitchOutOfCondition: 'Pitch out of range',
    invalidEmailFormat: 'Invalid email format',
    pleaseInputFirstnameAndLastname: 'Please insert firstname and lastname',
    phoneInCorrectFormat: 'Phone is incorrect format',
    emailInCorrectFormat: 'Email is incorrect format.',
    nameInCorrectFormat: 'Name is incorrect format.',
    emailAddressAlreadyExists: 'Email address already exists.',
    loginBySocialError: 'Login by social error.',
    cantGetUserInfo: "Can't get user info.",
    phoneEmailAlreadyExists: 'Phone/Email is already exist.',
    phoneAlreadyExists: 'Phone is already exist.',
    sendOtpError: 'Send otp error.',
    getSecretOtpError: 'Get secret otp error.',
    otpIncorrect: 'OTP is incorrect, Please re-send again.',
    pleaseEnterPassword: 'Please Enter Password',
    pleaseEnterPhone: 'Please Enter Phone',
    pleaseEnterPhoneAndPassword: 'Please Enter Phone and Password',
    wrongUsernameOrPassword: 'Wrong username or password.',
    browserNotSupport: `This browser not support please use Chrome or Safari.`,
    phoneNotMatch: "Phone number doesn't match the TrueBlue member system.",
    phoneNotMatch1: 'If you have been a TrueBlue member. Please contact',
    phoneNotMatch2: 'BlueScope Metal Sheet Center',
    phoneNotMatch3: 'or if you are not a member yet.',
    wrongAnswer: 'Wrong answer',
    passwordAndConfirmPasswordNotMatch: "Password and Confirm Password aren’t match.",
  },
  luckyDraw: {
    title: 'Lucky Draw',
    termsAndConditions: 'Terms & Conditions',
    agreeTermsAndConditions: 'Agree terms and conditions',
    dontShowThisMessageAgainNextTime: `Don't show this message again next time`,
    accept: 'Accept',
    winPrizes: 'Win Prizes',
    awardAnnouncement: 'Award Announcement',
    noLuckyDrawEvent: 'No Lucky Draw Event',
    usePointToWinPrizes: 'Use points to win prizes',
    point: 'point',
    right: 'right',
    numberOfUsedPoints: 'Number of used points',
    yourPointIsNotEnough: 'Your points is not enough',
    buttonWinPrizes: 'Win prizes',
    reward: 'Reward',
    viewDetail: 'View detail',
    readTermsAndConditions: 'Read terms and conditions',
    announcement: 'Announcement',
    announcementOfNameWinners: 'Announcement of name winners',
    campaign: 'Campaign',
    dateFrom: 'From',
    dateUntil: 'until',
    showMore: 'Show more',
    awardAnnounced: 'Awards announced!',
    youCanClickBtnBelowToSeeYourListName: 'You can click the button below to see your list name',
    buttonCheckMyName: 'Check my name!',
    expirePoint: 'Expire point',
    toExpire: 'To Expire',
    points: 'points',
    dontWasteYourPoint: `Don't waste your point`,
    useYourPointToExchangeForAChanceToWinPrizes: 'Use your points to exchange for a chance to win prizes',
    buttonGoToWinPrizes: 'Go to win prizes',
    total: 'Total',
    youUseYourRightToWinPrizes: 'You use your right to win prizes',
    goodLuckWithWinnigPrizes: 'Good luck with winning prizes',
    youHave: 'You have',
    pointLeft: 'points left',
    awardCount: 'Number of awards',
    showLess: "Show less",
    rewardTitle: '[quantity] prize',
    campaignDurationDate: 'Start [startDate] until [endDate]',
    campaignLuckyDrawDate: 'Prize draw on',
    announcementRewardDetail: '[rewardDetail] ([quantity] prize)',
    noAwardAnnouncement: 'No Award Announcement',
    youHaveAllThePoint: 'You have all the points :',
    rewardTitleQuantity: 'Quantity [quantity] prize',
    youHaveAMaximumRedeemablePointOfPoint: 'You have a maximum redeemable point of [point] points.',

  },
}