import config from "../../config";
import { ENUM } from "../constants/enum";
import { getLanguage } from "./language";

export const capitalizeFirstLetter = (string) => {
  if (string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
  return string;
}

export const displayUrlPath = (path) => {
  return path.indexOf('http') === 0 ? path : path ? `${config.imgPrefix}/${path}` : '';
}

export const displayWithPrefix = (number = 0, length = 3) => {
  if (number) {
    const text = (number + '');
    return text.length >= length ? text : ('0'.repeat(length) + text).slice(-length);
  }
  return '0'
}

export const displayWithComma = (number = 0) => {
  if (number) {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  return '0';
}

export const getCustomerTypeText = (text) => {
  switch (text) {
    case ENUM.CUSTOMER_TYPE.FIXERS.TYPE:
    case ENUM.CUSTOMER_TYPE.FIXERS.TEXT:
      return getLanguage('field.customerTypeFixer');
    case ENUM.CUSTOMER_TYPE.HARDWARE.TYPE:
    case ENUM.CUSTOMER_TYPE.HARDWARE.TEXT:
      return getLanguage('field.customerTypeHardware');
    case ENUM.CUSTOMER_TYPE.HOMEOWNER.TYPE:
    case ENUM.CUSTOMER_TYPE.HOMEOWNER.TEXT:
      return getLanguage('field.customerTypeHomeowner');
    case ENUM.CUSTOMER_TYPE.ROLL_FORMER.TYPE:
    case ENUM.CUSTOMER_TYPE.ROLL_FORMER.TEXT:
      return getLanguage('field.customerTypeRollFormer');
    case ENUM.CUSTOMER_TYPE.OTHERS.TYPE:
    case ENUM.CUSTOMER_TYPE.OTHERS.TEXT:
      return getLanguage('field.customerTypeOthers');
    case ENUM.CUSTOMER_TYPE.ARCHITECT.TEXT:
    case ENUM.CUSTOMER_TYPE.ARCHITECT.TYPE:
      return getLanguage('field.customerTypeArchitect', '');
    case ENUM.CUSTOMER_TYPE.ENGINEER.TEXT:
    case ENUM.CUSTOMER_TYPE.ENGINEER.TYPE:
      return getLanguage('field.customerTypeEngineer', '');
    default: return '';
  }
}

export const displayMaskPhoneNumber = (number, lengthMask = 4) => {
  const regex = new RegExp(`(\\d[ .-]?){${lengthMask}}$`)
  const splitNumber = number.slice(0, 3) + '-' + number.slice(3, 6) + '-' + number.slice(6)
  return splitNumber.toString().replace(regex, x => x.replace(/\d/g, 'X'))
}