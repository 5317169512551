export const COUNTRY_THAI_EN = {
  //state
  PROVINCE_LIST: [
    {
      "province": "Krabi",
      "provinceCode": 81
    },
    {
      "province": "Bangkok",
      "provinceCode": 10
    },
    {
      "province": "Kanchanaburi",
      "provinceCode": 71
    },
    {
      "province": "Kalasin",
      "provinceCode": 46
    },
    {
      "province": "Kamphaeng Phet",
      "provinceCode": 62
    },
    {
      "province": "Khon kaen",
      "provinceCode": 40
    },
    {
      "province": "Chanthaburi",
      "provinceCode": 22
    },
    {
      "province": "Chachoengsao",
      "provinceCode": 24
    },
    {
      "province": "Chonburi",
      "provinceCode": 20
    },
    {
      "province": "Chai Nat",
      "provinceCode": 18
    },
    {
      "province": "Chaiyaphum",
      "provinceCode": 36
    },
    {
      "province": "Chumphon",
      "provinceCode": 86
    },
    {
      "province": "Trang",
      "provinceCode": 92
    },
    {
      "province": "Trat",
      "provinceCode": 23
    },
    {
      "province": "Tak",
      "provinceCode": 63
    },
    {
      "province": "Nakhon Nayok",
      "provinceCode": 26
    },
    {
      "province": "Nakhon Pathom",
      "provinceCode": 73
    },
    {
      "province": "Nakhon Phanom",
      "provinceCode": 48
    },
    {
      "province": "Nakhon Ratchasima",
      "provinceCode": 30
    },
    {
      "province": "Nakhon Si Thammarat",
      "provinceCode": 80
    },
    {
      "province": "Nakhon Sawan",
      "provinceCode": 60
    },
    {
      "province": "Nonthaburi",
      "provinceCode": 12
    },
    {
      "province": "Narathiwat",
      "provinceCode": 96
    },
    {
      "province": "Nan",
      "provinceCode": 55
    },
    {
      "province": "Bueng Kan",
      "provinceCode": 38
    },
    {
      "province": "Buriram",
      "provinceCode": 31
    },
    {
      "province": "Pathum Thani",
      "provinceCode": 13
    },
    {
      "province": "Prachuap Khiri Khan",
      "provinceCode": 77
    },
    {
      "province": "Prachinburi",
      "provinceCode": 25
    },
    {
      "province": "Pattani",
      "provinceCode": 94
    },
    {
      "province": "Phra Nakhon Si Ayutthaya",
      "provinceCode": 14
    },
    {
      "province": "Phayao",
      "provinceCode": 56
    },
    {
      "province": "Phang Nga",
      "provinceCode": 82
    },
    {
      "province": "Phatthalung",
      "provinceCode": 93
    },
    {
      "province": "Phichit",
      "provinceCode": 66
    },
    {
      "province": "Phitsanulok",
      "provinceCode": 65
    },
    {
      "province": "Phuket",
      "provinceCode": 83
    },
    {
      "province": "Maha Sarakham",
      "provinceCode": 44
    },
    {
      "province": "Mukdahan",
      "provinceCode": 49
    },
    {
      "province": "Yala",
      "provinceCode": 95
    },
    {
      "province": "Yasothon",
      "provinceCode": 35
    },
    {
      "province": "Ranong",
      "provinceCode": 85
    },
    {
      "province": "Rayong",
      "provinceCode": 21
    },
    {
      "province": "Ratchaburi",
      "provinceCode": 70
    },
    {
      "province": "Roi Et",
      "provinceCode": 45
    },
    {
      "province": "Lopburi",
      "provinceCode": 16
    },
    {
      "province": "Lampang",
      "provinceCode": 52
    },
    {
      "province": "Lamphun",
      "provinceCode": 51
    },
    {
      "province": "Sisaket",
      "provinceCode": 33
    },
    {
      "province": "Sakon Nakhon",
      "provinceCode": 47
    },
    {
      "province": "Songkhla",
      "provinceCode": 90
    },
    {
      "province": "Satun",
      "provinceCode": 91
    },
    {
      "province": "Samut Prakan",
      "provinceCode": 11
    },
    {
      "province": "Samut Songkhram",
      "provinceCode": 75
    },
    {
      "province": "Samut Sakhon",
      "provinceCode": 74
    },
    {
      "province": "Saraburi",
      "provinceCode": 19
    },
    {
      "province": "Sa Kaeo",
      "provinceCode": 27
    },
    {
      "province": "Sing Buri",
      "provinceCode": 17
    },
    {
      "province": "Suphan Buri",
      "provinceCode": 72
    },
    {
      "province": "Surat Thani",
      "provinceCode": 84
    },
    {
      "province": "Surin",
      "provinceCode": 32
    },
    {
      "province": "Sukhothai",
      "provinceCode": 64
    },
    {
      "province": "Nong Khai",
      "provinceCode": 43
    },
    {
      "province": "Nong Bua Lamphu",
      "provinceCode": 39
    },
    {
      "province": "Amnat Charoen",
      "provinceCode": 37
    },
    {
      "province": "Udon Thani",
      "provinceCode": 41
    },
    {
      "province": "Uttaradit",
      "provinceCode": 53
    },
    {
      "province": "Uthai Thani",
      "provinceCode": 61
    },
    {
      "province": "Ubon Ratchathani",
      "provinceCode": 34
    },
    {
      "province": "Ang Thong",
      "provinceCode": 15
    },
    {
      "province": "Chiang Rai",
      "provinceCode": 57
    },
    {
      "province": "Chiang Mai",
      "provinceCode": 50
    },
    {
      "province": "Phetchaburi",
      "provinceCode": 76
    },
    {
      "province": "Phetchabun",
      "provinceCode": 67
    },
    {
      "province": "Loei",
      "provinceCode": 42
    },
    {
      "province": "Phrae",
      "provinceCode": 54
    },
    {
      "province": "Mae Hong Son",
      "provinceCode": 58
    }
  ],
  //amphoe
  CITY_LIST: [
    {
      "city": "Chanuman",
      "cityCode": 3702,
      "provinceCode": 37,
      "subDistrict": [
        {
          "id": 370201,
          "name": "Chanuman",
          "postCode": [
            "37210"
          ]
        },
        {
          "id": 370203,
          "name": "Kham Khuean Kaeo",
          "postCode": [
            "37210"
          ]
        },
        {
          "id": 370204,
          "name": "Khok Kong",
          "postCode": [
            "37210"
          ]
        },
        {
          "id": 370202,
          "name": "Khok San",
          "postCode": [
            "37210"
          ]
        },
        {
          "id": 370205,
          "name": "Pa Ko",
          "postCode": [
            "37210"
          ]
        }
      ]
    },
    {
      "city": "Hua Taphan",
      "cityCode": 3706,
      "provinceCode": 37,
      "subDistrict": [
        {
          "id": 370607,
          "name": "Chik Du",
          "postCode": [
            "37240"
          ]
        },
        {
          "id": 370601,
          "name": "Hua Taphan",
          "postCode": [
            "37240"
          ]
        },
        {
          "id": 370602,
          "name": "Kham Phra",
          "postCode": [
            "37240"
          ]
        },
        {
          "id": 370603,
          "name": "Kheng Yai",
          "postCode": [
            "37240"
          ]
        },
        {
          "id": 370604,
          "name": "Nong Kaeo",
          "postCode": [
            "37240"
          ]
        },
        {
          "id": 370605,
          "name": "Phon Mueang Noi",
          "postCode": [
            "37240"
          ]
        },
        {
          "id": 370608,
          "name": "Rattanawari",
          "postCode": [
            "37240"
          ]
        },
        {
          "id": 370606,
          "name": "Sang Tho Noi",
          "postCode": [
            "37240"
          ]
        }
      ]
    },
    {
      "city": "Lue Amnat",
      "cityCode": 3707,
      "provinceCode": 37,
      "subDistrict": [
        {
          "id": 370704,
          "name": "Dong Bang",
          "postCode": [
            "37120"
          ]
        },
        {
          "id": 370702,
          "name": "Dong Mayang",
          "postCode": [
            "37120"
          ]
        },
        {
          "id": 370707,
          "name": "Khok Klang",
          "postCode": [
            "37120"
          ]
        },
        {
          "id": 370706,
          "name": "Maet",
          "postCode": [
            "37120"
          ]
        },
        {
          "id": 370703,
          "name": "Pueai",
          "postCode": [
            "37120"
          ]
        },
        {
          "id": 370705,
          "name": "Rai Khi",
          "postCode": [
            "37120"
          ]
        },
        {
          "id": 370701,
          "name": "amnat",
          "postCode": [
            "37120"
          ]
        }
      ]
    },
    {
      "city": "Mueang Amnat Charoen",
      "cityCode": 3701,
      "provinceCode": 37,
      "subDistrict": [
        {
          "id": 370101,
          "name": "Bung",
          "postCode": [
            "37000"
          ]
        },
        {
          "id": 370117,
          "name": "Don Moei",
          "postCode": [
            "37000"
          ]
        },
        {
          "id": 370114,
          "name": "Huai Rai",
          "postCode": [
            "37000"
          ]
        },
        {
          "id": 370102,
          "name": "Kai Kham",
          "postCode": [
            "37000"
          ]
        },
        {
          "id": 370107,
          "name": "Khuem Yai",
          "postCode": [
            "37000"
          ]
        },
        {
          "id": 370116,
          "name": "Kut Pla Duk",
          "postCode": [
            "37000"
          ]
        },
        {
          "id": 370105,
          "name": "Lao Phruan",
          "postCode": [
            "37000"
          ]
        },
        {
          "id": 370103,
          "name": "Na Chik",
          "postCode": [
            "37000"
          ]
        },
        {
          "id": 370111,
          "name": "Na Mo Ma",
          "postCode": [
            "37000"
          ]
        },
        {
          "id": 370108,
          "name": "Na Phue",
          "postCode": [
            "37000"
          ]
        },
        {
          "id": 370119,
          "name": "Na Tae",
          "postCode": [
            "37000"
          ]
        },
        {
          "id": 370110,
          "name": "Na Wang",
          "postCode": [
            "37000"
          ]
        },
        {
          "id": 370118,
          "name": "Na Yom",
          "postCode": [
            "37000"
          ]
        },
        {
          "id": 370109,
          "name": "Nam Plik",
          "postCode": [
            "37000"
          ]
        },
        {
          "id": 370113,
          "name": "Non Nam Thaeng",
          "postCode": [
            "37000"
          ]
        },
        {
          "id": 370112,
          "name": "Non Pho",
          "postCode": [
            "37000"
          ]
        },
        {
          "id": 370115,
          "name": "Nong Masaeo",
          "postCode": [
            "37000"
          ]
        },
        {
          "id": 370104,
          "name": "Pla Khao",
          "postCode": [
            "37000"
          ]
        },
        {
          "id": 370106,
          "name": "Sang Nok Tha",
          "postCode": [
            "37000"
          ]
        }
      ]
    },
    {
      "city": "Pathum Ratchawongsa",
      "cityCode": 3703,
      "provinceCode": 37,
      "subDistrict": [
        {
          "id": 370305,
          "name": "Huai",
          "postCode": [
            "37110"
          ]
        },
        {
          "id": 370302,
          "name": "Kham Phon",
          "postCode": [
            "37110"
          ]
        },
        {
          "id": 370304,
          "name": "Lue",
          "postCode": [
            "37110"
          ]
        },
        {
          "id": 370307,
          "name": "Na Pa Saeng",
          "postCode": [
            "37110"
          ]
        },
        {
          "id": 370303,
          "name": "Na Wa",
          "postCode": [
            "37110"
          ]
        },
        {
          "id": 370306,
          "name": "Non Ngam",
          "postCode": [
            "37110"
          ]
        },
        {
          "id": 370301,
          "name": "Nong Kha",
          "postCode": [
            "37110"
          ]
        }
      ]
    },
    {
      "city": "Phana",
      "cityCode": 3704,
      "provinceCode": 37,
      "subDistrict": [
        {
          "id": 370402,
          "name": "Chan Lan",
          "postCode": [
            "37180"
          ]
        },
        {
          "id": 370403,
          "name": "Mai Klon",
          "postCode": [
            "37180"
          ]
        },
        {
          "id": 370401,
          "name": "Phana",
          "postCode": [
            "37180"
          ]
        },
        {
          "id": 370404,
          "name": "Phra Lao",
          "postCode": [
            "37180"
          ]
        }
      ]
    },
    {
      "city": "Senangkhanikhom",
      "cityCode": 3705,
      "provinceCode": 37,
      "subDistrict": [
        {
          "id": 370504,
          "name": "Na Wiang",
          "postCode": [
            "37290"
          ]
        },
        {
          "id": 370505,
          "name": "Nong Hai",
          "postCode": [
            "37290"
          ]
        },
        {
          "id": 370506,
          "name": "Nong Sam Si",
          "postCode": [
            "37290"
          ]
        },
        {
          "id": 370502,
          "name": "Phon Thong",
          "postCode": [
            "37290"
          ]
        },
        {
          "id": 370503,
          "name": "Rai Si Suk",
          "postCode": [
            "37290"
          ]
        },
        {
          "id": 370501,
          "name": "Senangkhanikhom",
          "postCode": [
            "37290"
          ]
        }
      ]
    },
    {
      "city": "Chaiyo",
      "cityCode": 1502,
      "provinceCode": 15,
      "subDistrict": [
        {
          "id": 150203,
          "name": "Chai Rit",
          "postCode": [
            "14140"
          ]
        },
        {
          "id": 150202,
          "name": "Chaiyaphum",
          "postCode": [
            "14140"
          ]
        },
        {
          "id": 150206,
          "name": "Chaiyo",
          "postCode": [
            "14140"
          ]
        },
        {
          "id": 150208,
          "name": "Chawai",
          "postCode": [
            "14140"
          ]
        },
        {
          "id": 150201,
          "name": "Chorakhe Rong",
          "postCode": [
            "14140"
          ]
        },
        {
          "id": 150207,
          "name": "Lak Fa",
          "postCode": [
            "14140"
          ]
        },
        {
          "id": 150205,
          "name": "Rat Sathit",
          "postCode": [
            "14140"
          ]
        },
        {
          "id": 150204,
          "name": "Thewarat",
          "postCode": [
            "14140"
          ]
        },
        {
          "id": 150209,
          "name": "Tri Narong",
          "postCode": [
            "14140"
          ]
        }
      ]
    },
    {
      "city": "Mueang Ang Thong",
      "cityCode": 1501,
      "provinceCode": 15,
      "subDistrict": [
        {
          "id": 150105,
          "name": "Ban Hae",
          "postCode": [
            "14000"
          ]
        },
        {
          "id": 150108,
          "name": "Ban It",
          "postCode": [
            "14000"
          ]
        },
        {
          "id": 150112,
          "name": "Ban Ri",
          "postCode": [
            "14000"
          ]
        },
        {
          "id": 150102,
          "name": "Bang Kaeo",
          "postCode": [
            "14000"
          ]
        },
        {
          "id": 150110,
          "name": "Champa Lo",
          "postCode": [
            "14000"
          ]
        },
        {
          "id": 150109,
          "name": "Hua Phai",
          "postCode": [
            "14000"
          ]
        },
        {
          "id": 150113,
          "name": "Khlong Wua",
          "postCode": [
            "14000"
          ]
        },
        {
          "id": 150107,
          "name": "Mahat Thai",
          "postCode": [
            "14000"
          ]
        },
        {
          "id": 150104,
          "name": "Pa Ngio",
          "postCode": [
            "14000"
          ]
        },
        {
          "id": 150111,
          "name": "Phosa",
          "postCode": [
            "14000"
          ]
        },
        {
          "id": 150103,
          "name": "Sala Daeng",
          "postCode": [
            "14000"
          ]
        },
        {
          "id": 150106,
          "name": "Talat Kruat",
          "postCode": [
            "14000"
          ]
        },
        {
          "id": 150101,
          "name": "Talat Luang",
          "postCode": [
            "14000"
          ]
        },
        {
          "id": 150114,
          "name": "Yan Sue",
          "postCode": [
            "14000"
          ]
        }
      ]
    },
    {
      "city": "Pa Mok",
      "cityCode": 1503,
      "provinceCode": 15,
      "subDistrict": [
        {
          "id": 150301,
          "name": "Bang Pla Kot",
          "postCode": [
            "14130"
          ]
        },
        {
          "id": 150305,
          "name": "Bang Sadet",
          "postCode": [
            "14130"
          ]
        },
        {
          "id": 150307,
          "name": "Ekkarat",
          "postCode": [
            "14130"
          ]
        },
        {
          "id": 150306,
          "name": "Norasing",
          "postCode": [
            "14130"
          ]
        },
        {
          "id": 150302,
          "name": "Pa Mok",
          "postCode": [
            "14130"
          ]
        },
        {
          "id": 150308,
          "name": "Phongpheng",
          "postCode": [
            "14130"
          ]
        },
        {
          "id": 150304,
          "name": "Rong Chang",
          "postCode": [
            "14130"
          ]
        },
        {
          "id": 150303,
          "name": "Sai Thong",
          "postCode": [
            "14130"
          ]
        }
      ]
    },
    {
      "city": "Pho Thong",
      "cityCode": 1504,
      "provinceCode": 15,
      "subDistrict": [
        {
          "id": 150401,
          "name": "Ang Kaeo",
          "postCode": [
            "14120"
          ]
        },
        {
          "id": 150414,
          "name": "Bang Chao Cha",
          "postCode": [
            "14120"
          ]
        },
        {
          "id": 150403,
          "name": "Bang Phlap",
          "postCode": [
            "14120"
          ]
        },
        {
          "id": 150406,
          "name": "Bang Rakam",
          "postCode": [
            "14120"
          ]
        },
        {
          "id": 150411,
          "name": "Bo Rae",
          "postCode": [
            "14120"
          ]
        },
        {
          "id": 150402,
          "name": "Inthapramun",
          "postCode": [
            "14120"
          ]
        },
        {
          "id": 150415,
          "name": "Kham Yat",
          "postCode": [
            "14120"
          ]
        },
        {
          "id": 150409,
          "name": "Khok Phutsa",
          "postCode": [
            "14120"
          ]
        },
        {
          "id": 150404,
          "name": "Nong Mae Kai",
          "postCode": [
            "14120"
          ]
        },
        {
          "id": 150408,
          "name": "Ongkharak",
          "postCode": [
            "14120"
          ]
        },
        {
          "id": 150407,
          "name": "Pho Rang Nok",
          "postCode": [
            "14120"
          ]
        },
        {
          "id": 150405,
          "name": "Ram Masak",
          "postCode": [
            "14120"
          ]
        },
        {
          "id": 150413,
          "name": "Sam Ngam",
          "postCode": [
            "14120"
          ]
        },
        {
          "id": 150412,
          "name": "Thang Phra",
          "postCode": [
            "14120"
          ]
        },
        {
          "id": 150410,
          "name": "Yang Chai",
          "postCode": [
            "14120"
          ]
        }
      ]
    },
    {
      "city": "Samko",
      "cityCode": 1507,
      "provinceCode": 15,
      "subDistrict": [
        {
          "id": 150705,
          "name": "Mongkhon Tham Nimit",
          "postCode": [
            "14160"
          ]
        },
        {
          "id": 150703,
          "name": "Op Thom",
          "postCode": [
            "14160"
          ]
        },
        {
          "id": 150704,
          "name": "Pho Muang Phan",
          "postCode": [
            "14160"
          ]
        },
        {
          "id": 150702,
          "name": "Ratsadon Phatthana",
          "postCode": [
            "14160"
          ]
        },
        {
          "id": 150701,
          "name": "Samko",
          "postCode": [
            "14160"
          ]
        }
      ]
    },
    {
      "city": "Sawaeng Ha",
      "cityCode": 1505,
      "provinceCode": 15,
      "subDistrict": [
        {
          "id": 150503,
          "name": "Ban Phran",
          "postCode": [
            "14150"
          ]
        },
        {
          "id": 150507,
          "name": "Chamlong",
          "postCode": [
            "14150"
          ]
        },
        {
          "id": 150506,
          "name": "Huai Phai",
          "postCode": [
            "14150"
          ]
        },
        {
          "id": 150501,
          "name": "Sawaeng Ha",
          "postCode": [
            "14150"
          ]
        },
        {
          "id": 150505,
          "name": "Si Bua Thong",
          "postCode": [
            "14150"
          ]
        },
        {
          "id": 150502,
          "name": "Si Phran",
          "postCode": [
            "14150"
          ]
        },
        {
          "id": 150504,
          "name": "Wang Nam Yen",
          "postCode": [
            "14150"
          ]
        }
      ]
    },
    {
      "city": "Wiset Chai Chan",
      "cityCode": 1506,
      "provinceCode": 15,
      "subDistrict": [
        {
          "id": 150607,
          "name": "Bang Chak",
          "postCode": [
            "14110"
          ]
        },
        {
          "id": 150613,
          "name": "Hua Taphan",
          "postCode": [
            "14110"
          ]
        },
        {
          "id": 150608,
          "name": "Huai Khan Laen",
          "postCode": [
            "14110"
          ]
        },
        {
          "id": 150609,
          "name": "Khlong Khanak",
          "postCode": [
            "14110"
          ]
        },
        {
          "id": 150614,
          "name": "Lak Kaeo",
          "postCode": [
            "14110"
          ]
        },
        {
          "id": 150612,
          "name": "Muang Tia",
          "postCode": [
            "14110"
          ]
        },
        {
          "id": 150601,
          "name": "Phai Cham Sin",
          "postCode": [
            "14110"
          ]
        },
        {
          "id": 150603,
          "name": "Phai Dam Phatthana",
          "postCode": [
            "14110"
          ]
        },
        {
          "id": 150610,
          "name": "Phai Wong",
          "postCode": [
            "14110"
          ]
        },
        {
          "id": 150602,
          "name": "San Chao Rong Thong",
          "postCode": [
            "14110"
          ]
        },
        {
          "id": 150604,
          "name": "Sao Rong Hai",
          "postCode": [
            "14110"
          ]
        },
        {
          "id": 150611,
          "name": "Si Roi",
          "postCode": [
            "14110"
          ]
        },
        {
          "id": 150615,
          "name": "Talat Mai",
          "postCode": [
            "14110"
          ]
        },
        {
          "id": 150605,
          "name": "Tha Chang",
          "postCode": [
            "14110"
          ]
        },
        {
          "id": 150606,
          "name": "Yi Lon",
          "postCode": [
            "14110"
          ]
        }
      ]
    },
    {
      "city": "Bang Bon",
      "cityCode": 1050,
      "provinceCode": 10,
      "subDistrict": [
        {
          "id": 105002,
          "name": "Bang Bon Nuea",
          "postCode": [
            "10150"
          ]
        },
        {
          "id": 105003,
          "name": "Bang Bon Tai",
          "postCode": [
            "10150"
          ]
        },
        {
          "id": 105005,
          "name": "Khlong Bang Bon",
          "postCode": [
            "10150"
          ]
        },
        {
          "id": 105004,
          "name": "Khlong Bang Phran",
          "postCode": [
            "10150"
          ]
        }
      ]
    },
    {
      "city": "Bang Kapi",
      "cityCode": 1006,
      "provinceCode": 10,
      "subDistrict": [
        {
          "id": 100608,
          "name": "Hua Mak",
          "postCode": [
            "10240",
            "10250"
          ]
        },
        {
          "id": 100601,
          "name": "Khlong Chan",
          "postCode": [
            "10240"
          ]
        }
      ]
    },
    {
      "city": "Bang Khae",
      "cityCode": 1040,
      "provinceCode": 10,
      "subDistrict": [
        {
          "id": 104001,
          "name": "Bang Khae",
          "postCode": [
            "10160"
          ]
        },
        {
          "id": 104002,
          "name": "Bang Khae Nuea",
          "postCode": [
            "10160"
          ]
        },
        {
          "id": 104003,
          "name": "Bang Phai",
          "postCode": [
            "10160"
          ]
        },
        {
          "id": 104004,
          "name": "Lak Song",
          "postCode": [
            "10160"
          ]
        }
      ]
    },
    {
      "city": "Bang Khen",
      "cityCode": 1005,
      "provinceCode": 10,
      "subDistrict": [
        {
          "id": 100502,
          "name": "Anusawari",
          "postCode": [
            "10220"
          ]
        },
        {
          "id": 100508,
          "name": "Tha Raeng",
          "postCode": [
            "10220",
            "10230"
          ]
        }
      ]
    },
    {
      "city": "Bang Kho Laem",
      "cityCode": 1031,
      "provinceCode": 10,
      "subDistrict": [
        {
          "id": 103103,
          "name": "Bang Khlo",
          "postCode": [
            "10120"
          ]
        },
        {
          "id": 103101,
          "name": "Bang Kho Laem",
          "postCode": [
            "10120"
          ]
        },
        {
          "id": 103102,
          "name": "Wat Phraya Krai",
          "postCode": [
            "10120"
          ]
        }
      ]
    },
    {
      "city": "Bang Khun Thian",
      "cityCode": 1021,
      "provinceCode": 10,
      "subDistrict": [
        {
          "id": 102107,
          "name": "Samae Dam",
          "postCode": [
            "10150"
          ]
        },
        {
          "id": 102105,
          "name": "Tha Kham",
          "postCode": [
            "10150"
          ]
        }
      ]
    },
    {
      "city": "Bang Na",
      "cityCode": 1047,
      "provinceCode": 10,
      "subDistrict": [
        {
          "id": 104702,
          "name": "Bang Na Nuea",
          "postCode": [
            "10260"
          ]
        },
        {
          "id": 104703,
          "name": "Bang Na Tai",
          "postCode": [
            "10260"
          ]
        }
      ]
    },
    {
      "city": "Bang Phlat",
      "cityCode": 1025,
      "provinceCode": 10,
      "subDistrict": [
        {
          "id": 102503,
          "name": "Bang Bamru",
          "postCode": [
            "10700"
          ]
        },
        {
          "id": 102502,
          "name": "Bang O",
          "postCode": [
            "10700"
          ]
        },
        {
          "id": 102501,
          "name": "Bang Phlat",
          "postCode": [
            "10700"
          ]
        },
        {
          "id": 102504,
          "name": "Bang Yi Khan",
          "postCode": [
            "10700"
          ]
        }
      ]
    },
    {
      "city": "Bang Rak",
      "cityCode": 1004,
      "provinceCode": 10,
      "subDistrict": [
        {
          "id": 100404,
          "name": "Bang Rak",
          "postCode": [
            "10500"
          ]
        },
        {
          "id": 100401,
          "name": "Maha Phruettharam",
          "postCode": [
            "10500"
          ]
        },
        {
          "id": 100402,
          "name": "Si Lom",
          "postCode": [
            "10500"
          ]
        },
        {
          "id": 100405,
          "name": "Si Phraya",
          "postCode": [
            "10500"
          ]
        },
        {
          "id": 100403,
          "name": "Suriyawong",
          "postCode": [
            "10500"
          ]
        }
      ]
    },
    {
      "city": "Bang Sue",
      "cityCode": 1029,
      "provinceCode": 10,
      "subDistrict": [
        {
          "id": 102901,
          "name": "Bang Sue",
          "postCode": [
            "10800"
          ]
        },
        {
          "id": 102902,
          "name": "Wong Sawang",
          "postCode": [
            "10800"
          ]
        }
      ]
    },
    {
      "city": "Bangkok Noi",
      "cityCode": 1020,
      "provinceCode": 10,
      "subDistrict": [
        {
          "id": 102009,
          "name": "Arun Ammarin",
          "postCode": [
            "10700"
          ]
        },
        {
          "id": 102005,
          "name": "Ban Chang Lo",
          "postCode": [
            "10700"
          ]
        },
        {
          "id": 102006,
          "name": "Bang Khun Non",
          "postCode": [
            "10700"
          ]
        },
        {
          "id": 102007,
          "name": "Bang Khun Si",
          "postCode": [
            "10700"
          ]
        },
        {
          "id": 102004,
          "name": "Sirirat",
          "postCode": [
            "10700"
          ]
        }
      ]
    },
    {
      "city": "Bangkok Yai",
      "cityCode": 1016,
      "provinceCode": 10,
      "subDistrict": [
        {
          "id": 101601,
          "name": "Wat Arun",
          "postCode": [
            "10600"
          ]
        },
        {
          "id": 101602,
          "name": "Wat Tha Phra",
          "postCode": [
            "10600"
          ]
        }
      ]
    },
    {
      "city": "Bueng Kum",
      "cityCode": 1027,
      "provinceCode": 10,
      "subDistrict": [
        {
          "id": 102701,
          "name": "Khlong Kum",
          "postCode": [
            "10230",
            "10240"
          ]
        },
        {
          "id": 102704,
          "name": "Nawamin",
          "postCode": [
            "10240"
          ]
        },
        {
          "id": 102705,
          "name": "Nuan Chan",
          "postCode": [
            "10230"
          ]
        }
      ]
    },
    {
      "city": "Chatuchak",
      "cityCode": 1030,
      "provinceCode": 10,
      "subDistrict": [
        {
          "id": 103003,
          "name": "Chan Kasem",
          "postCode": [
            "10900"
          ]
        },
        {
          "id": 103005,
          "name": "Chatuchak",
          "postCode": [
            "10900"
          ]
        },
        {
          "id": 103004,
          "name": "Chom Phon",
          "postCode": [
            "10900"
          ]
        },
        {
          "id": 103001,
          "name": "Lat Yao",
          "postCode": [
            "10900"
          ]
        },
        {
          "id": 103002,
          "name": "Sena Nikhom",
          "postCode": [
            "10900"
          ]
        }
      ]
    },
    {
      "city": "Chom Thong",
      "cityCode": 1035,
      "provinceCode": 10,
      "subDistrict": [
        {
          "id": 103502,
          "name": "Bang Kho",
          "postCode": [
            "10150"
          ]
        },
        {
          "id": 103501,
          "name": "Bang Khun Thian",
          "postCode": [
            "10150"
          ]
        },
        {
          "id": 103503,
          "name": "Bang Mot",
          "postCode": [
            "10150"
          ]
        },
        {
          "id": 103504,
          "name": "Chom Thong",
          "postCode": [
            "10150"
          ]
        }
      ]
    },
    {
      "city": "Din Daeng",
      "cityCode": 1026,
      "provinceCode": 10,
      "subDistrict": [
        {
          "id": 102601,
          "name": "Din Daeng",
          "postCode": [
            "10400"
          ]
        },
        {
          "id": 102602,
          "name": "Ratchadaphisek",
          "postCode": [
            "10400"
          ]
        }
      ]
    },
    {
      "city": "Don Mueang",
      "cityCode": 1036,
      "provinceCode": 10,
      "subDistrict": [
        {
          "id": 103604,
          "name": "Don Mueang",
          "postCode": [
            "10210"
          ]
        },
        {
          "id": 103605,
          "name": "Sanam Bin",
          "postCode": [
            "10210"
          ]
        },
        {
          "id": 103602,
          "name": "Si Kan",
          "postCode": [
            "10210"
          ]
        }
      ]
    },
    {
      "city": "Dusit",
      "cityCode": 1002,
      "provinceCode": 10,
      "subDistrict": [
        {
          "id": 100201,
          "name": "Dusit",
          "postCode": [
            "10300"
          ]
        },
        {
          "id": 100204,
          "name": "Si Yaek Mahanak",
          "postCode": [
            "10300"
          ]
        },
        {
          "id": 100203,
          "name": "Suan Chitlada",
          "postCode": [
            "10300",
            "10303"
          ]
        },
        {
          "id": 100206,
          "name": "Thanon Nakhon Chai Si",
          "postCode": [
            "10300"
          ]
        },
        {
          "id": 100202,
          "name": "Wachira Phayaban",
          "postCode": [
            "10300"
          ]
        }
      ]
    },
    {
      "city": "Huai Khwang",
      "cityCode": 1017,
      "provinceCode": 10,
      "subDistrict": [
        {
          "id": 101702,
          "name": "Bang Kapi",
          "postCode": [
            "10310"
          ]
        },
        {
          "id": 101701,
          "name": "Huai Khwang",
          "postCode": [
            "10310"
          ]
        },
        {
          "id": 101704,
          "name": "Sam Sen Nok",
          "postCode": [
            "10310"
          ]
        }
      ]
    },
    {
      "city": "Khan Na Yao",
      "cityCode": 1043,
      "provinceCode": 10,
      "subDistrict": [
        {
          "id": 104301,
          "name": "Khan Na Yao",
          "postCode": [
            "10230"
          ]
        },
        {
          "id": 104302,
          "name": "Ram Inthra",
          "postCode": [
            "10230"
          ]
        }
      ]
    },
    {
      "city": "Khlong Sam Wa",
      "cityCode": 1046,
      "provinceCode": 10,
      "subDistrict": [
        {
          "id": 104603,
          "name": "Bang Chan",
          "postCode": [
            "10510"
          ]
        },
        {
          "id": 104604,
          "name": "Sai Kong Din",
          "postCode": [
            "10510"
          ]
        },
        {
          "id": 104605,
          "name": "Sai Kong Din Tai",
          "postCode": [
            "10510"
          ]
        },
        {
          "id": 104602,
          "name": "Sam Wa Tawan Ok",
          "postCode": [
            "10510"
          ]
        },
        {
          "id": 104601,
          "name": "Sam Wa Tawan Tok",
          "postCode": [
            "10510"
          ]
        }
      ]
    },
    {
      "city": "Khlong San",
      "cityCode": 1018,
      "provinceCode": 10,
      "subDistrict": [
        {
          "id": 101803,
          "name": "Bang Lamphu Lang",
          "postCode": [
            "10600"
          ]
        },
        {
          "id": 101802,
          "name": "Khlong San",
          "postCode": [
            "10600"
          ]
        },
        {
          "id": 101804,
          "name": "Khlong Ton Sai",
          "postCode": [
            "10600"
          ]
        },
        {
          "id": 101801,
          "name": "Somdet Chao Phraya",
          "postCode": [
            "10600"
          ]
        }
      ]
    },
    {
      "city": "Khlong Toei",
      "cityCode": 1033,
      "provinceCode": 10,
      "subDistrict": [
        {
          "id": 103302,
          "name": "Khlong Tan",
          "postCode": [
            "10110"
          ]
        },
        {
          "id": 103301,
          "name": "Khlong Toei",
          "postCode": [
            "10110"
          ]
        },
        {
          "id": 103303,
          "name": "Phra Khanong",
          "postCode": [
            "10110",
            "10260"
          ]
        }
      ]
    },
    {
      "city": "Lak Si",
      "cityCode": 1041,
      "provinceCode": 10,
      "subDistrict": [
        {
          "id": 104102,
          "name": "Talat Bang Khen",
          "postCode": [
            "10210"
          ]
        },
        {
          "id": 104101,
          "name": "Thung Song Hong",
          "postCode": [
            "10210"
          ]
        }
      ]
    },
    {
      "city": "Lat Krabang",
      "cityCode": 1011,
      "provinceCode": 10,
      "subDistrict": [
        {
          "id": 101103,
          "name": "Khlong Sam Prawet",
          "postCode": [
            "10520"
          ]
        },
        {
          "id": 101102,
          "name": "Khlong Song Ton Nun",
          "postCode": [
            "10520"
          ]
        },
        {
          "id": 101106,
          "name": "Khum Thong",
          "postCode": [
            "10520"
          ]
        },
        {
          "id": 101104,
          "name": "Lam Pla Thio",
          "postCode": [
            "10520"
          ]
        },
        {
          "id": 101101,
          "name": "Lat Krabang",
          "postCode": [
            "10520"
          ]
        },
        {
          "id": 101105,
          "name": "Thap Yao",
          "postCode": [
            "10520"
          ]
        }
      ]
    },
    {
      "city": "Lat Phrao",
      "cityCode": 1038,
      "provinceCode": 10,
      "subDistrict": [
        {
          "id": 103802,
          "name": "Chorakhe Bua",
          "postCode": [
            "10230"
          ]
        },
        {
          "id": 103801,
          "name": "Lat Phrao",
          "postCode": [
            "10230"
          ]
        }
      ]
    },
    {
      "city": "Min Buri",
      "cityCode": 1010,
      "provinceCode": 10,
      "subDistrict": [
        {
          "id": 101001,
          "name": "Min Buri",
          "postCode": [
            "10510"
          ]
        },
        {
          "id": 101002,
          "name": "Saen Saep",
          "postCode": [
            "10510"
          ]
        }
      ]
    },
    {
      "city": "Nong Chok",
      "cityCode": 1003,
      "provinceCode": 10,
      "subDistrict": [
        {
          "id": 100303,
          "name": "Khlong Sip",
          "postCode": [
            "10530"
          ]
        },
        {
          "id": 100304,
          "name": "Khlong Sip Song",
          "postCode": [
            "10530"
          ]
        },
        {
          "id": 100305,
          "name": "Khok Faet",
          "postCode": [
            "10530"
          ]
        },
        {
          "id": 100306,
          "name": "Khu Fang Nuea",
          "postCode": [
            "10530"
          ]
        },
        {
          "id": 100301,
          "name": "Krathum Rai",
          "postCode": [
            "10530"
          ]
        },
        {
          "id": 100307,
          "name": "Lam Phak Chi",
          "postCode": [
            "10530"
          ]
        },
        {
          "id": 100308,
          "name": "Lam Toiting",
          "postCode": [
            "10530"
          ]
        },
        {
          "id": 100302,
          "name": "Nong Chok",
          "postCode": [
            "10530"
          ]
        }
      ]
    },
    {
      "city": "Nong Khaem",
      "cityCode": 1023,
      "provinceCode": 10,
      "subDistrict": [
        {
          "id": 102302,
          "name": "Nong Khaem",
          "postCode": [
            "10160"
          ]
        },
        {
          "id": 102303,
          "name": "Nong Khang Phlu",
          "postCode": [
            "10160"
          ]
        }
      ]
    },
    {
      "city": "Pathum Wan",
      "cityCode": 1007,
      "provinceCode": 10,
      "subDistrict": [
        {
          "id": 100704,
          "name": "Lumphini",
          "postCode": [
            "10330"
          ]
        },
        {
          "id": 100703,
          "name": "Pathum Wan",
          "postCode": [
            "10330"
          ]
        },
        {
          "id": 100701,
          "name": "Rong Mueang",
          "postCode": [
            "10330"
          ]
        },
        {
          "id": 100702,
          "name": "Wang Mai",
          "postCode": [
            "10110",
            "10120",
            "10330",
            "10400",
            "10500"
          ]
        }
      ]
    },
    {
      "city": "Phasi Charoen",
      "cityCode": 1022,
      "provinceCode": 10,
      "subDistrict": [
        {
          "id": 102206,
          "name": "Bang Chak",
          "postCode": [
            "10160"
          ]
        },
        {
          "id": 102202,
          "name": "Bang Duan",
          "postCode": [
            "10160"
          ]
        },
        {
          "id": 102201,
          "name": "Bang Wa",
          "postCode": [
            "10160"
          ]
        },
        {
          "id": 102207,
          "name": "Bang Waek",
          "postCode": [
            "10160"
          ]
        },
        {
          "id": 102208,
          "name": "Khlong Khwang",
          "postCode": [
            "10160"
          ]
        },
        {
          "id": 102210,
          "name": "Khuha Sawan",
          "postCode": [
            "10160"
          ]
        },
        {
          "id": 102209,
          "name": "Pak Khlong Phasi Charoen",
          "postCode": [
            "10160"
          ]
        }
      ]
    },
    {
      "city": "Phaya Thai",
      "cityCode": 1014,
      "provinceCode": 10,
      "subDistrict": [
        {
          "id": 101406,
          "name": "Phaya Thai",
          "postCode": [
            "10400"
          ]
        },
        {
          "id": 101401,
          "name": "Sam Sen Nai",
          "postCode": [
            "10400"
          ]
        }
      ]
    },
    {
      "city": "Phra Khanong",
      "cityCode": 1009,
      "provinceCode": 10,
      "subDistrict": [
        {
          "id": 100905,
          "name": "Bang Chak",
          "postCode": [
            "10260"
          ]
        },
        {
          "id": 100910,
          "name": "Phra Khanong Tai",
          "postCode": [
            "10260"
          ]
        }
      ]
    },
    {
      "city": "Phra Nakhon",
      "cityCode": 1001,
      "provinceCode": 10,
      "subDistrict": [
        {
          "id": 100110,
          "name": "Ban Phan Thom",
          "postCode": [
            "10200"
          ]
        },
        {
          "id": 100111,
          "name": "Bang Khun Phrom",
          "postCode": [
            "10200"
          ]
        },
        {
          "id": 100107,
          "name": "Bowon Niwet",
          "postCode": [
            "10200"
          ]
        },
        {
          "id": 100109,
          "name": "Chana Songkhram",
          "postCode": [
            "10200"
          ]
        },
        {
          "id": 100101,
          "name": "Phra Borom Maha Ratchawang",
          "postCode": [
            "10200"
          ]
        },
        {
          "id": 100104,
          "name": "Samran Rat",
          "postCode": [
            "10200"
          ]
        },
        {
          "id": 100105,
          "name": "San Chao Pho Suea",
          "postCode": [
            "10200"
          ]
        },
        {
          "id": 100106,
          "name": "Sao Chingcha",
          "postCode": [
            "10200"
          ]
        },
        {
          "id": 100108,
          "name": "Talat Yot",
          "postCode": [
            "10200"
          ]
        },
        {
          "id": 100102,
          "name": "Wang Burapha Phirom",
          "postCode": [
            "10200"
          ]
        },
        {
          "id": 100103,
          "name": "Wat Ratchabophit",
          "postCode": [
            "10200"
          ]
        },
        {
          "id": 100112,
          "name": "Wat Sam Phraya",
          "postCode": [
            "10200"
          ]
        }
      ]
    },
    {
      "city": "Pom Prap Sattru Phai",
      "cityCode": 1008,
      "provinceCode": 10,
      "subDistrict": [
        {
          "id": 100804,
          "name": "Ban Bat",
          "postCode": [
            "10100"
          ]
        },
        {
          "id": 100803,
          "name": "Khlong Maha Nak",
          "postCode": [
            "10100"
          ]
        },
        {
          "id": 100801,
          "name": "Pom Prap",
          "postCode": [
            "10100"
          ]
        },
        {
          "id": 100805,
          "name": "Wat Sommanat",
          "postCode": [
            "10100"
          ]
        },
        {
          "id": 100802,
          "name": "Wat Thep Sirin",
          "postCode": [
            "10100"
          ]
        }
      ]
    },
    {
      "city": "Prawet",
      "cityCode": 1032,
      "provinceCode": 10,
      "subDistrict": [
        {
          "id": 103203,
          "name": "Dok Mai",
          "postCode": [
            "10250"
          ]
        },
        {
          "id": 103202,
          "name": "Nong Bon",
          "postCode": [
            "10250"
          ]
        },
        {
          "id": 103201,
          "name": "Prawet",
          "postCode": [
            "10250"
          ]
        }
      ]
    },
    {
      "city": "Rat Burana",
      "cityCode": 1024,
      "provinceCode": 10,
      "subDistrict": [
        {
          "id": 102402,
          "name": "Bang Pa Kok",
          "postCode": [
            "10140"
          ]
        },
        {
          "id": 102401,
          "name": "Rat Burana",
          "postCode": [
            "10140"
          ]
        }
      ]
    },
    {
      "city": "Ratchathewi",
      "cityCode": 1037,
      "provinceCode": 10,
      "subDistrict": [
        {
          "id": 103704,
          "name": "Makkasan",
          "postCode": [
            "10400"
          ]
        },
        {
          "id": 103702,
          "name": "Thanon Phaya Thai",
          "postCode": [
            "10400"
          ]
        },
        {
          "id": 103703,
          "name": "Thanon Phetchaburi",
          "postCode": [
            "10400"
          ]
        },
        {
          "id": 103701,
          "name": "Thung Phaya Thai",
          "postCode": [
            "10400"
          ]
        }
      ]
    },
    {
      "city": "Sai Mai",
      "cityCode": 1042,
      "provinceCode": 10,
      "subDistrict": [
        {
          "id": 104203,
          "name": "Khlong Thanon",
          "postCode": [
            "10220"
          ]
        },
        {
          "id": 104202,
          "name": "O Ngoen",
          "postCode": [
            "10220"
          ]
        },
        {
          "id": 104201,
          "name": "Sai Mai",
          "postCode": [
            "10220"
          ]
        }
      ]
    },
    {
      "city": "Samphanthawong",
      "cityCode": 1013,
      "provinceCode": 10,
      "subDistrict": [
        {
          "id": 101301,
          "name": "Chakkrawat",
          "postCode": [
            "10100"
          ]
        },
        {
          "id": 101302,
          "name": "Samphanthawong",
          "postCode": [
            "10100"
          ]
        },
        {
          "id": 101303,
          "name": "Talat Noi",
          "postCode": [
            "10100"
          ]
        }
      ]
    },
    {
      "city": "Saphan Sung",
      "cityCode": 1044,
      "provinceCode": 10,
      "subDistrict": [
        {
          "id": 104402,
          "name": "Rat Phatthana",
          "postCode": [
            "10240"
          ]
        },
        {
          "id": 104401,
          "name": "Saphan Sung",
          "postCode": [
            "10240",
            "10250"
          ]
        },
        {
          "id": 104403,
          "name": "Thap Chang",
          "postCode": [
            "10250"
          ]
        }
      ]
    },
    {
      "city": "Sathon",
      "cityCode": 1028,
      "provinceCode": 10,
      "subDistrict": [
        {
          "id": 102803,
          "name": "Thung Maha Mek",
          "postCode": [
            "10120"
          ]
        },
        {
          "id": 102801,
          "name": "Thung Wat Don",
          "postCode": [
            "10120"
          ]
        },
        {
          "id": 102802,
          "name": "Yan Nawa",
          "postCode": [
            "10120"
          ]
        }
      ]
    },
    {
      "city": "Suan Luang",
      "cityCode": 1034,
      "provinceCode": 10,
      "subDistrict": [
        {
          "id": 103402,
          "name": "On Nut",
          "postCode": [
            "10250"
          ]
        },
        {
          "id": 103403,
          "name": "Phatthanakan",
          "postCode": [
            "10250"
          ]
        },
        {
          "id": 103401,
          "name": "Suan Luang",
          "postCode": [
            "10250"
          ]
        }
      ]
    },
    {
      "city": "Taling Chan",
      "cityCode": 1019,
      "provinceCode": 10,
      "subDistrict": [
        {
          "id": 101907,
          "name": "Bang Chueak Nang",
          "postCode": [
            "10170"
          ]
        },
        {
          "id": 101904,
          "name": "Bang Phrom",
          "postCode": [
            "10170"
          ]
        },
        {
          "id": 101905,
          "name": "Bang Ramat",
          "postCode": [
            "10170"
          ]
        },
        {
          "id": 101903,
          "name": "Chimphli",
          "postCode": [
            "10170"
          ]
        },
        {
          "id": 101901,
          "name": "Khlong Chak Phra",
          "postCode": [
            "10170"
          ]
        },
        {
          "id": 101902,
          "name": "Taling Chan",
          "postCode": [
            "10170"
          ]
        }
      ]
    },
    {
      "city": "Thawi Watthana",
      "cityCode": 1048,
      "provinceCode": 10,
      "subDistrict": [
        {
          "id": 104802,
          "name": "Sala Thammasop",
          "postCode": [
            "10170"
          ]
        },
        {
          "id": 104801,
          "name": "Thawi Watthana",
          "postCode": [
            "10170"
          ]
        }
      ]
    },
    {
      "city": "Thon Buri",
      "cityCode": 1015,
      "provinceCode": 10,
      "subDistrict": [
        {
          "id": 101503,
          "name": "Bang Yi Ruea",
          "postCode": [
            "10600"
          ]
        },
        {
          "id": 101504,
          "name": "Bukkhalo",
          "postCode": [
            "10600"
          ]
        },
        {
          "id": 101506,
          "name": "Dao Khanong",
          "postCode": [
            "10600"
          ]
        },
        {
          "id": 101502,
          "name": "Hiran Ruchi",
          "postCode": [
            "10600"
          ]
        },
        {
          "id": 101507,
          "name": "Samre",
          "postCode": [
            "10600"
          ]
        },
        {
          "id": 101505,
          "name": "Talat Phlu",
          "postCode": [
            "10600"
          ]
        },
        {
          "id": 101501,
          "name": "Wat Kanlaya",
          "postCode": [
            "10600"
          ]
        }
      ]
    },
    {
      "city": "Thung Khru",
      "cityCode": 1049,
      "provinceCode": 10,
      "subDistrict": [
        {
          "id": 104901,
          "name": "Bang Mot",
          "postCode": [
            "10140"
          ]
        },
        {
          "id": 104902,
          "name": "Thung Khru",
          "postCode": [
            "10140"
          ]
        }
      ]
    },
    {
      "city": "Vadhana",
      "cityCode": 1039,
      "provinceCode": 10,
      "subDistrict": [
        {
          "id": 103902,
          "name": "Khlong Tan Nuea",
          "postCode": [
            "10110"
          ]
        },
        {
          "id": 103901,
          "name": "Khlong Toei Nuea",
          "postCode": [
            "10110"
          ]
        },
        {
          "id": 103903,
          "name": "Phra Khanong Nuea",
          "postCode": [
            "10110",
            "10260"
          ]
        }
      ]
    },
    {
      "city": "Wang Thonglang",
      "cityCode": 1045,
      "provinceCode": 10,
      "subDistrict": [
        {
          "id": 104503,
          "name": "Khlong Chao Khun Sing",
          "postCode": [
            "10310"
          ]
        },
        {
          "id": 104504,
          "name": "Phlapphla",
          "postCode": [
            "10310"
          ]
        },
        {
          "id": 104502,
          "name": "Saphan Song",
          "postCode": [
            "10310"
          ]
        },
        {
          "id": 104501,
          "name": "Wang Thonglang",
          "postCode": [
            "10310"
          ]
        }
      ]
    },
    {
      "city": "Yan Nawa",
      "cityCode": 1012,
      "provinceCode": 10,
      "subDistrict": [
        {
          "id": 101204,
          "name": "Bang Phongphang",
          "postCode": [
            "10120"
          ]
        },
        {
          "id": 101203,
          "name": "Chong Nonsi",
          "postCode": [
            "10120"
          ]
        }
      ]
    },
    {
      "city": "Bueng Khong Long",
      "cityCode": 3806,
      "provinceCode": 38,
      "subDistrict": [
        {
          "id": 380601,
          "name": "Bueng Khong Long",
          "postCode": [
            "38220"
          ]
        },
        {
          "id": 380603,
          "name": "Dong Bang",
          "postCode": [
            "38220"
          ]
        },
        {
          "id": 380602,
          "name": "Pho Mak Khaeng",
          "postCode": [
            "38220"
          ]
        },
        {
          "id": 380604,
          "name": "Tha Dok Kham",
          "postCode": [
            "38220"
          ]
        }
      ]
    },
    {
      "city": "Bung Khla",
      "cityCode": 3808,
      "provinceCode": 38,
      "subDistrict": [
        {
          "id": 380801,
          "name": "Bung Khla",
          "postCode": [
            "38000"
          ]
        },
        {
          "id": 380803,
          "name": "Khok Kwang",
          "postCode": [
            "38000"
          ]
        },
        {
          "id": 380802,
          "name": "Nong Doen",
          "postCode": [
            "38000"
          ]
        }
      ]
    },
    {
      "city": "Mueang Bueng Kan",
      "cityCode": 3801,
      "provinceCode": 38,
      "subDistrict": [
        {
          "id": 380101,
          "name": "Bueng Kan",
          "postCode": [
            "38000"
          ]
        },
        {
          "id": 380109,
          "name": "Chaiyaphon",
          "postCode": [
            "38000"
          ]
        },
        {
          "id": 380104,
          "name": "Ho Kham",
          "postCode": [
            "38000"
          ]
        },
        {
          "id": 380108,
          "name": "Khai Si",
          "postCode": [
            "38000"
          ]
        },
        {
          "id": 380111,
          "name": "Kham Na Di",
          "postCode": [
            "38000"
          ]
        },
        {
          "id": 380106,
          "name": "Khok Kong",
          "postCode": [
            "38000"
          ]
        },
        {
          "id": 380107,
          "name": "Na Sawan",
          "postCode": [
            "38000"
          ]
        },
        {
          "id": 380103,
          "name": "Non Sawang",
          "postCode": [
            "38000"
          ]
        },
        {
          "id": 380102,
          "name": "Non Sombun",
          "postCode": [
            "38000"
          ]
        },
        {
          "id": 380105,
          "name": "Nong Loeng",
          "postCode": [
            "38000"
          ]
        },
        {
          "id": 380112,
          "name": "Pong Pueai",
          "postCode": [
            "38000"
          ]
        },
        {
          "id": 380110,
          "name": "Wisit",
          "postCode": [
            "38000"
          ]
        }
      ]
    },
    {
      "city": "Pak Khat",
      "cityCode": 3805,
      "provinceCode": 38,
      "subDistrict": [
        {
          "id": 380506,
          "name": "Na Dong",
          "postCode": [
            "38190"
          ]
        },
        {
          "id": 380503,
          "name": "Na Kang",
          "postCode": [
            "38190"
          ]
        },
        {
          "id": 380504,
          "name": "Non Sila",
          "postCode": [
            "38190"
          ]
        },
        {
          "id": 380502,
          "name": "Nong Yong",
          "postCode": [
            "38190"
          ]
        },
        {
          "id": 380501,
          "name": "Pak Khat",
          "postCode": [
            "38190"
          ]
        },
        {
          "id": 380505,
          "name": "Som Sanuk",
          "postCode": [
            "38190"
          ]
        }
      ]
    },
    {
      "city": "Phon Charoen",
      "cityCode": 3802,
      "provinceCode": 38,
      "subDistrict": [
        {
          "id": 380202,
          "name": "Don Ya Nang",
          "postCode": [
            "38180"
          ]
        },
        {
          "id": 380204,
          "name": "Nong Hua Chang",
          "postCode": [
            "38180"
          ]
        },
        {
          "id": 380206,
          "name": "Pa Faek",
          "postCode": [
            "38180"
          ]
        },
        {
          "id": 380203,
          "name": "Phon Charoen",
          "postCode": [
            "38180"
          ]
        },
        {
          "id": 380201,
          "name": "Si Chomphu",
          "postCode": [
            "38180"
          ]
        },
        {
          "id": 380207,
          "name": "Si Samran",
          "postCode": [
            "38180"
          ]
        },
        {
          "id": 380205,
          "name": "Wang Chomphu",
          "postCode": [
            "38180"
          ]
        }
      ]
    },
    {
      "city": "Seka",
      "cityCode": 3804,
      "provinceCode": 38,
      "subDistrict": [
        {
          "id": 380404,
          "name": "Ban Tong",
          "postCode": [
            "38150"
          ]
        },
        {
          "id": 380406,
          "name": "Nam Chan",
          "postCode": [
            "38150"
          ]
        },
        {
          "id": 380408,
          "name": "Nong Thum",
          "postCode": [
            "38150"
          ]
        },
        {
          "id": 380405,
          "name": "Pong Hai",
          "postCode": [
            "38150"
          ]
        },
        {
          "id": 380402,
          "name": "Sang",
          "postCode": [
            "38150"
          ]
        },
        {
          "id": 380401,
          "name": "Seka",
          "postCode": [
            "38150"
          ]
        },
        {
          "id": 380409,
          "name": "Sok Kam",
          "postCode": [
            "38150"
          ]
        },
        {
          "id": 380403,
          "name": "Tha Kok Daeng",
          "postCode": [
            "38150"
          ]
        },
        {
          "id": 380407,
          "name": "Tha Sa-at",
          "postCode": [
            "38150"
          ]
        }
      ]
    },
    {
      "city": "Si Wilai",
      "cityCode": 3807,
      "provinceCode": 38,
      "subDistrict": [
        {
          "id": 380702,
          "name": "Chumphu Phon",
          "postCode": [
            "38210"
          ]
        },
        {
          "id": 380704,
          "name": "Na Sabaeng",
          "postCode": [
            "38210"
          ]
        },
        {
          "id": 380703,
          "name": "Na Saeng",
          "postCode": [
            "38210"
          ]
        },
        {
          "id": 380705,
          "name": "Na Sing",
          "postCode": [
            "38210"
          ]
        },
        {
          "id": 380701,
          "name": "Si Wilai",
          "postCode": [
            "38210"
          ]
        }
      ]
    },
    {
      "city": "So Phisai",
      "cityCode": 3803,
      "provinceCode": 38,
      "subDistrict": [
        {
          "id": 380305,
          "name": "Bua Tum",
          "postCode": [
            "38170"
          ]
        },
        {
          "id": 380304,
          "name": "Kham Kaeo",
          "postCode": [
            "38170"
          ]
        },
        {
          "id": 380307,
          "name": "Lao Thong",
          "postCode": [
            "38170"
          ]
        },
        {
          "id": 380302,
          "name": "Nong Phan Tha",
          "postCode": [
            "38170"
          ]
        },
        {
          "id": 380303,
          "name": "Si Chomphu",
          "postCode": [
            "38170"
          ]
        },
        {
          "id": 380301,
          "name": "So",
          "postCode": [
            "38170"
          ]
        },
        {
          "id": 380306,
          "name": "Tham Charoen",
          "postCode": [
            "38170"
          ]
        }
      ]
    },
    {
      "city": "Ban Dan",
      "cityCode": 3121,
      "provinceCode": 31,
      "subDistrict": [
        {
          "id": 312101,
          "name": "Ban Dan",
          "postCode": [
            "31000"
          ]
        },
        {
          "id": 312104,
          "name": "Non Khwang",
          "postCode": [
            "31000"
          ]
        },
        {
          "id": 312102,
          "name": "Prasat",
          "postCode": [
            "31000"
          ]
        },
        {
          "id": 312103,
          "name": "Wang Nuea",
          "postCode": [
            "31000"
          ]
        }
      ]
    },
    {
      "city": "Ban Kruat",
      "cityCode": 3108,
      "provinceCode": 31,
      "subDistrict": [
        {
          "id": 310801,
          "name": "Ban Kruat",
          "postCode": [
            "31180"
          ]
        },
        {
          "id": 310807,
          "name": "Bueng Charoen",
          "postCode": [
            "31180"
          ]
        },
        {
          "id": 310808,
          "name": "Chanthop Phet",
          "postCode": [
            "31180"
          ]
        },
        {
          "id": 310806,
          "name": "Hin Lat",
          "postCode": [
            "31180"
          ]
        },
        {
          "id": 310809,
          "name": "Khao Din Nuea",
          "postCode": [
            "31180"
          ]
        },
        {
          "id": 310802,
          "name": "Non Charoen",
          "postCode": [
            "31180"
          ]
        },
        {
          "id": 310803,
          "name": "Nong Mai Ngam",
          "postCode": [
            "31180"
          ]
        },
        {
          "id": 310804,
          "name": "Prasat",
          "postCode": [
            "31180"
          ]
        },
        {
          "id": 310805,
          "name": "Sai Taku",
          "postCode": [
            "31180"
          ]
        }
      ]
    },
    {
      "city": "Ban Mai Chaiyaphot",
      "cityCode": 3119,
      "provinceCode": 31,
      "subDistrict": [
        {
          "id": 311903,
          "name": "Daeng Yai",
          "postCode": [
            "31120"
          ]
        },
        {
          "id": 311904,
          "name": "Ku Suan Taeng",
          "postCode": [
            "31120"
          ]
        },
        {
          "id": 311901,
          "name": "Nong Waeng",
          "postCode": [
            "31120"
          ]
        },
        {
          "id": 311905,
          "name": "Nong Yueang",
          "postCode": [
            "31120"
          ]
        },
        {
          "id": 311902,
          "name": "Thonglang",
          "postCode": [
            "31120"
          ]
        }
      ]
    },
    {
      "city": "Chaloem Phra Kiat",
      "cityCode": 3123,
      "provinceCode": 31,
      "subDistrict": [
        {
          "id": 312301,
          "name": "Charoen Suk",
          "postCode": [
            "31110"
          ]
        },
        {
          "id": 312303,
          "name": "Isan Khet",
          "postCode": [
            "31110"
          ]
        },
        {
          "id": 312302,
          "name": "Ta Pek",
          "postCode": [
            "31110"
          ]
        },
        {
          "id": 312304,
          "name": "Thawon",
          "postCode": [
            "31170"
          ]
        },
        {
          "id": 312305,
          "name": "Yai Yaem Watthana",
          "postCode": [
            "31170"
          ]
        }
      ]
    },
    {
      "city": "Chamni",
      "cityCode": 3118,
      "provinceCode": 31,
      "subDistrict": [
        {
          "id": 311801,
          "name": "Chamni",
          "postCode": [
            "31110"
          ]
        },
        {
          "id": 311804,
          "name": "Cho Phaka",
          "postCode": [
            "31110"
          ]
        },
        {
          "id": 311806,
          "name": "Khok Sanuan",
          "postCode": [
            "31110"
          ]
        },
        {
          "id": 311805,
          "name": "Laluat",
          "postCode": [
            "31110"
          ]
        },
        {
          "id": 311803,
          "name": "Mueang Yang",
          "postCode": [
            "31110"
          ]
        },
        {
          "id": 311802,
          "name": "Nong Plong",
          "postCode": [
            "31110"
          ]
        }
      ]
    },
    {
      "city": "Huai Rat",
      "cityCode": 3116,
      "provinceCode": 31,
      "subDistrict": [
        {
          "id": 311604,
          "name": "Ban Tako",
          "postCode": [
            "31000"
          ]
        },
        {
          "id": 311608,
          "name": "Huai Racha",
          "postCode": [
            "31000"
          ]
        },
        {
          "id": 311601,
          "name": "Huai Rat",
          "postCode": [
            "31000"
          ]
        },
        {
          "id": 311606,
          "name": "Khok Lek",
          "postCode": [
            "31000"
          ]
        },
        {
          "id": 311607,
          "name": "Mueang Pho",
          "postCode": [
            "31000"
          ]
        },
        {
          "id": 311602,
          "name": "Sam Waeng",
          "postCode": [
            "31000"
          ]
        },
        {
          "id": 311605,
          "name": "Sanuan",
          "postCode": [
            "31000"
          ]
        },
        {
          "id": 311603,
          "name": "Ta Sao",
          "postCode": [
            "31000"
          ]
        }
      ]
    },
    {
      "city": "Khaen Dong",
      "cityCode": 3122,
      "provinceCode": 31,
      "subDistrict": [
        {
          "id": 312202,
          "name": "Dong Phlong",
          "postCode": [
            "31150"
          ]
        },
        {
          "id": 312204,
          "name": "Hua Fai",
          "postCode": [
            "31150"
          ]
        },
        {
          "id": 312201,
          "name": "Khaen Dong",
          "postCode": [
            "31150"
          ]
        },
        {
          "id": 312203,
          "name": "Sa Bua",
          "postCode": [
            "31150"
          ]
        }
      ]
    },
    {
      "city": "Khu Mueang",
      "cityCode": 3102,
      "provinceCode": 31,
      "subDistrict": [
        {
          "id": 310203,
          "name": "Ban Phae",
          "postCode": [
            "31190"
          ]
        },
        {
          "id": 310205,
          "name": "Hin Lek Fai",
          "postCode": [
            "31190"
          ]
        },
        {
          "id": 310201,
          "name": "Khu Mueang",
          "postCode": [
            "31190"
          ]
        },
        {
          "id": 310207,
          "name": "Nong Khaman",
          "postCode": [
            "31190"
          ]
        },
        {
          "id": 310202,
          "name": "Pakhiap",
          "postCode": [
            "31190"
          ]
        },
        {
          "id": 310204,
          "name": "Phon Samran",
          "postCode": [
            "31190"
          ]
        },
        {
          "id": 310206,
          "name": "Tum Yai",
          "postCode": [
            "31190"
          ]
        }
      ]
    },
    {
      "city": "Krasang",
      "cityCode": 3103,
      "provinceCode": 31,
      "subDistrict": [
        {
          "id": 310308,
          "name": "Ban Prue",
          "postCode": [
            "31160"
          ]
        },
        {
          "id": 310307,
          "name": "Chum Saeng",
          "postCode": [
            "31160"
          ]
        },
        {
          "id": 310309,
          "name": "Huai Samran",
          "postCode": [
            "31160"
          ]
        },
        {
          "id": 310310,
          "name": "Kanthararom",
          "postCode": [
            "31160"
          ]
        },
        {
          "id": 310301,
          "name": "Krasang",
          "postCode": [
            "31160"
          ]
        },
        {
          "id": 310302,
          "name": "Lamduan",
          "postCode": [
            "31160"
          ]
        },
        {
          "id": 310306,
          "name": "Mueang Phai",
          "postCode": [
            "31160"
          ]
        },
        {
          "id": 310305,
          "name": "Nong Teng",
          "postCode": [
            "31160"
          ]
        },
        {
          "id": 310311,
          "name": "Si Phum",
          "postCode": [
            "31160"
          ]
        },
        {
          "id": 310303,
          "name": "Song Chan",
          "postCode": [
            "31160"
          ]
        },
        {
          "id": 310304,
          "name": "Sung Noen",
          "postCode": [
            "31160"
          ]
        }
      ]
    },
    {
      "city": "Lahan Sai",
      "cityCode": 3106,
      "provinceCode": 31,
      "subDistrict": [
        {
          "id": 310611,
          "name": "Khok Wan",
          "postCode": [
            "31170"
          ]
        },
        {
          "id": 310601,
          "name": "Lahan Sai",
          "postCode": [
            "31170"
          ]
        },
        {
          "id": 310610,
          "name": "Nong Takhrong",
          "postCode": [
            "31170"
          ]
        },
        {
          "id": 310607,
          "name": "Nong Waeng",
          "postCode": [
            "31170"
          ]
        },
        {
          "id": 310604,
          "name": "Samrong Mai",
          "postCode": [
            "31170"
          ]
        },
        {
          "id": 310603,
          "name": "Ta Chong",
          "postCode": [
            "31170"
          ]
        }
      ]
    },
    {
      "city": "Lam Plai Mat",
      "cityCode": 3110,
      "provinceCode": 31,
      "subDistrict": [
        {
          "id": 311010,
          "name": "Ban Yang",
          "postCode": [
            "31130"
          ]
        },
        {
          "id": 311015,
          "name": "Bu Pho",
          "postCode": [
            "31130"
          ]
        },
        {
          "id": 311013,
          "name": "Hin Khon",
          "postCode": [
            "31130"
          ]
        },
        {
          "id": 311007,
          "name": "Khok Klang",
          "postCode": [
            "31130"
          ]
        },
        {
          "id": 311012,
          "name": "Khok Lam",
          "postCode": [
            "31130"
          ]
        },
        {
          "id": 311008,
          "name": "Khok Sa-at",
          "postCode": [
            "31130"
          ]
        },
        {
          "id": 311001,
          "name": "Lam Plai Mat",
          "postCode": [
            "31130"
          ]
        },
        {
          "id": 311009,
          "name": "Mueang Faek",
          "postCode": [
            "31130"
          ]
        },
        {
          "id": 311014,
          "name": "Nong Bua Khok",
          "postCode": [
            "31130"
          ]
        },
        {
          "id": 311016,
          "name": "Nong Don",
          "postCode": [
            "31130"
          ]
        },
        {
          "id": 311006,
          "name": "Nong Kathing",
          "postCode": [
            "31130"
          ]
        },
        {
          "id": 311002,
          "name": "Nong Khu",
          "postCode": [
            "31130"
          ]
        },
        {
          "id": 311011,
          "name": "Phathairin",
          "postCode": [
            "31130"
          ]
        },
        {
          "id": 311003,
          "name": "Salaeng Phan",
          "postCode": [
            "31130"
          ]
        },
        {
          "id": 311005,
          "name": "Talat Pho",
          "postCode": [
            "31130"
          ]
        },
        {
          "id": 311004,
          "name": "Thamen Chai",
          "postCode": [
            "31130"
          ]
        }
      ]
    },
    {
      "city": "Mueang Buri Ram",
      "cityCode": 3101,
      "provinceCode": 31,
      "subDistrict": [
        {
          "id": 310104,
          "name": "Ban Bua",
          "postCode": [
            "31000"
          ]
        },
        {
          "id": 310108,
          "name": "Ban Yang",
          "postCode": [
            "31000"
          ]
        },
        {
          "id": 310119,
          "name": "Bua Thong",
          "postCode": [
            "31000"
          ]
        },
        {
          "id": 310120,
          "name": "Chum Het",
          "postCode": [
            "31000"
          ]
        },
        {
          "id": 310102,
          "name": "Isan",
          "postCode": [
            "31000"
          ]
        },
        {
          "id": 310126,
          "name": "Kalantha",
          "postCode": [
            "31000"
          ]
        },
        {
          "id": 310127,
          "name": "Krasang",
          "postCode": [
            "31000"
          ]
        },
        {
          "id": 310122,
          "name": "Lak Khet",
          "postCode": [
            "31000"
          ]
        },
        {
          "id": 310117,
          "name": "Lumpuk",
          "postCode": [
            "31000"
          ]
        },
        {
          "id": 310128,
          "name": "Mueang Fang",
          "postCode": [
            "31000"
          ]
        },
        {
          "id": 310101,
          "name": "Nai Mueang",
          "postCode": [
            "31000"
          ]
        },
        {
          "id": 310114,
          "name": "Nong Tat",
          "postCode": [
            "31000"
          ]
        },
        {
          "id": 310112,
          "name": "Phra Khru",
          "postCode": [
            "31000"
          ]
        },
        {
          "id": 310105,
          "name": "Sakae Phrong",
          "postCode": [
            "31000"
          ]
        },
        {
          "id": 310125,
          "name": "Sakae Sam",
          "postCode": [
            "31000"
          ]
        },
        {
          "id": 310103,
          "name": "Samet",
          "postCode": [
            "31000"
          ]
        },
        {
          "id": 310106,
          "name": "Sawai Chik",
          "postCode": [
            "31000"
          ]
        },
        {
          "id": 310118,
          "name": "Song Hong",
          "postCode": [
            "31000"
          ]
        },
        {
          "id": 310113,
          "name": "Thalung Lek",
          "postCode": [
            "31000"
          ]
        }
      ]
    },
    {
      "city": "Na Pho",
      "cityCode": 3113,
      "provinceCode": 31,
      "subDistrict": [
        {
          "id": 311303,
          "name": "Ban Du",
          "postCode": [
            "31230"
          ]
        },
        {
          "id": 311302,
          "name": "Ban Khu",
          "postCode": [
            "31230"
          ]
        },
        {
          "id": 311304,
          "name": "Don Kok",
          "postCode": [
            "31230"
          ]
        },
        {
          "id": 311301,
          "name": "Na Pho",
          "postCode": [
            "31230"
          ]
        },
        {
          "id": 311305,
          "name": "Si Sawang",
          "postCode": [
            "31230"
          ]
        }
      ]
    },
    {
      "city": "Nang Rong",
      "cityCode": 3104,
      "provinceCode": 31,
      "subDistrict": [
        {
          "id": 310416,
          "name": "Ban Sing",
          "postCode": [
            "31110"
          ]
        },
        {
          "id": 310405,
          "name": "Chum Saeng",
          "postCode": [
            "31110"
          ]
        },
        {
          "id": 310425,
          "name": "Hua Thanon",
          "postCode": [
            "31110"
          ]
        },
        {
          "id": 310415,
          "name": "Kan Lueang",
          "postCode": [
            "31110"
          ]
        },
        {
          "id": 310417,
          "name": "Lam Sai Yong",
          "postCode": [
            "31110"
          ]
        },
        {
          "id": 310401,
          "name": "Nang Rong",
          "postCode": [
            "31110"
          ]
        },
        {
          "id": 310406,
          "name": "Nong Bot",
          "postCode": [
            "31110"
          ]
        },
        {
          "id": 310408,
          "name": "Nong Kong",
          "postCode": [
            "31110"
          ]
        },
        {
          "id": 310414,
          "name": "Nong Sai",
          "postCode": [
            "31110"
          ]
        },
        {
          "id": 310427,
          "name": "Nong Sano",
          "postCode": [
            "31110"
          ]
        },
        {
          "id": 310424,
          "name": "Nong Yai Phim",
          "postCode": [
            "31110"
          ]
        },
        {
          "id": 310403,
          "name": "Sadao",
          "postCode": [
            "31110"
          ]
        },
        {
          "id": 310418,
          "name": "Sap Phraya",
          "postCode": [
            "31110"
          ]
        },
        {
          "id": 310413,
          "name": "Thanon Hak",
          "postCode": [
            "31110"
          ]
        },
        {
          "id": 310426,
          "name": "Thung Saeng Thong",
          "postCode": [
            "31110"
          ]
        }
      ]
    },
    {
      "city": "Non Din Daeng",
      "cityCode": 3120,
      "provinceCode": 31,
      "subDistrict": [
        {
          "id": 312003,
          "name": "Lam Nang Rong",
          "postCode": [
            "31260"
          ]
        },
        {
          "id": 312001,
          "name": "Non Din Daeng",
          "postCode": [
            "31260"
          ]
        },
        {
          "id": 312002,
          "name": "Som Poi",
          "postCode": [
            "31260"
          ]
        }
      ]
    },
    {
      "city": "Non Suwan",
      "cityCode": 3117,
      "provinceCode": 31,
      "subDistrict": [
        {
          "id": 311704,
          "name": "Dong I Chan",
          "postCode": [
            "31110"
          ]
        },
        {
          "id": 311703,
          "name": "Krok Kaeo",
          "postCode": [
            "31110"
          ]
        },
        {
          "id": 311701,
          "name": "Non Suwan",
          "postCode": [
            "31110"
          ]
        },
        {
          "id": 311702,
          "name": "Thung Changhan",
          "postCode": [
            "31110"
          ]
        }
      ]
    },
    {
      "city": "Nong Hong",
      "cityCode": 3114,
      "provinceCode": 31,
      "subDistrict": [
        {
          "id": 311402,
          "name": "Huai Hin",
          "postCode": [
            "31240"
          ]
        },
        {
          "id": 311406,
          "name": "Mueang Fai",
          "postCode": [
            "31240"
          ]
        },
        {
          "id": 311404,
          "name": "Nong Chai Si",
          "postCode": [
            "31240"
          ]
        },
        {
          "id": 311401,
          "name": "Sa Kaeo",
          "postCode": [
            "31240"
          ]
        },
        {
          "id": 311407,
          "name": "Sa Thong",
          "postCode": [
            "31240"
          ]
        },
        {
          "id": 311405,
          "name": "Sao Diao",
          "postCode": [
            "31240"
          ]
        },
        {
          "id": 311403,
          "name": "Thai Samakkhi",
          "postCode": [
            "31240"
          ]
        }
      ]
    },
    {
      "city": "Nong Ki",
      "cityCode": 3105,
      "provinceCode": 31,
      "subDistrict": [
        {
          "id": 310510,
          "name": "Bu Krasang",
          "postCode": [
            "31210"
          ]
        },
        {
          "id": 310504,
          "name": "Don Arang",
          "postCode": [
            "31210"
          ]
        },
        {
          "id": 310505,
          "name": "Khok Sawang",
          "postCode": [
            "31210"
          ]
        },
        {
          "id": 310509,
          "name": "Khok Sung",
          "postCode": [
            "31210"
          ]
        },
        {
          "id": 310503,
          "name": "Mueang Phai",
          "postCode": [
            "31210"
          ]
        },
        {
          "id": 310501,
          "name": "Nong Ki",
          "postCode": [
            "31210"
          ]
        },
        {
          "id": 310508,
          "name": "Tha Pho Chai",
          "postCode": [
            "31210"
          ]
        },
        {
          "id": 310506,
          "name": "Thung Kratat Phatthana",
          "postCode": [
            "31210"
          ]
        },
        {
          "id": 310507,
          "name": "Thung Kraten",
          "postCode": [
            "31210"
          ]
        },
        {
          "id": 310502,
          "name": "Yoei Prasat",
          "postCode": [
            "31210"
          ]
        }
      ]
    },
    {
      "city": "Pakham",
      "cityCode": 3112,
      "provinceCode": 31,
      "subDistrict": [
        {
          "id": 311205,
          "name": "Hu Thamnop",
          "postCode": [
            "31220"
          ]
        },
        {
          "id": 311204,
          "name": "Khok Mamuang",
          "postCode": [
            "31220"
          ]
        },
        {
          "id": 311203,
          "name": "Nong Bua",
          "postCode": [
            "31220"
          ]
        },
        {
          "id": 311201,
          "name": "Pakham",
          "postCode": [
            "31220"
          ]
        },
        {
          "id": 311202,
          "name": "Thai Charoen",
          "postCode": [
            "31220"
          ]
        }
      ]
    },
    {
      "city": "Phlapphla Chai",
      "cityCode": 3115,
      "provinceCode": 31,
      "subDistrict": [
        {
          "id": 311501,
          "name": "Chan Dum",
          "postCode": [
            "31250"
          ]
        },
        {
          "id": 311502,
          "name": "Khok Khamin",
          "postCode": [
            "31250"
          ]
        },
        {
          "id": 311503,
          "name": "Pa Chan",
          "postCode": [
            "31250"
          ]
        },
        {
          "id": 311504,
          "name": "Sadao",
          "postCode": [
            "31250"
          ]
        },
        {
          "id": 311505,
          "name": "Samrong",
          "postCode": [
            "31250"
          ]
        }
      ]
    },
    {
      "city": "Phutthaisong",
      "cityCode": 3109,
      "provinceCode": 31,
      "subDistrict": [
        {
          "id": 310903,
          "name": "Ban Chan",
          "postCode": [
            "31120"
          ]
        },
        {
          "id": 310906,
          "name": "Ban Pao",
          "postCode": [
            "31120"
          ]
        },
        {
          "id": 310907,
          "name": "Ban Waeng",
          "postCode": [
            "31120"
          ]
        },
        {
          "id": 310909,
          "name": "Ban Yang",
          "postCode": [
            "31120"
          ]
        },
        {
          "id": 310910,
          "name": "Hai Sok",
          "postCode": [
            "31120"
          ]
        },
        {
          "id": 310902,
          "name": "Mafueang",
          "postCode": [
            "31120"
          ]
        },
        {
          "id": 310901,
          "name": "Phutthaisong",
          "postCode": [
            "31120"
          ]
        }
      ]
    },
    {
      "city": "Prakhon Chai",
      "cityCode": 3107,
      "provinceCode": 31,
      "subDistrict": [
        {
          "id": 310703,
          "name": "Ban Sai",
          "postCode": [
            "31140"
          ]
        },
        {
          "id": 310716,
          "name": "Bong Bon",
          "postCode": [
            "31140"
          ]
        },
        {
          "id": 310706,
          "name": "Chorakhe Mak",
          "postCode": [
            "31140"
          ]
        },
        {
          "id": 310715,
          "name": "Khao Khok",
          "postCode": [
            "31140"
          ]
        },
        {
          "id": 310710,
          "name": "Khok Ma",
          "postCode": [
            "31140"
          ]
        },
        {
          "id": 310718,
          "name": "Khok Makham",
          "postCode": [
            "31140"
          ]
        },
        {
          "id": 310719,
          "name": "Khok Tum",
          "postCode": [
            "31140"
          ]
        },
        {
          "id": 310708,
          "name": "Khok Yang",
          "postCode": [
            "31140"
          ]
        },
        {
          "id": 310705,
          "name": "Lawia",
          "postCode": [
            "31140"
          ]
        },
        {
          "id": 310707,
          "name": "Pang Ku",
          "postCode": [
            "31140"
          ]
        },
        {
          "id": 310713,
          "name": "Phaisan",
          "postCode": [
            "31140"
          ]
        },
        {
          "id": 310701,
          "name": "Prakhon Chai",
          "postCode": [
            "31140"
          ]
        },
        {
          "id": 310720,
          "name": "Prathat Bu",
          "postCode": [
            "31140"
          ]
        },
        {
          "id": 310702,
          "name": "Salaeng Thon",
          "postCode": [
            "31140"
          ]
        },
        {
          "id": 310721,
          "name": "Si Liam",
          "postCode": [
            "31140"
          ]
        },
        {
          "id": 310714,
          "name": "Tako Taphi",
          "postCode": [
            "31140"
          ]
        }
      ]
    },
    {
      "city": "Satuek",
      "cityCode": 3111,
      "provinceCode": 31,
      "subDistrict": [
        {
          "id": 311110,
          "name": "Chum Saeng",
          "postCode": [
            "31150"
          ]
        },
        {
          "id": 311109,
          "name": "Don Mon",
          "postCode": [
            "31150"
          ]
        },
        {
          "id": 311115,
          "name": "Krasang",
          "postCode": [
            "31150"
          ]
        },
        {
          "id": 311104,
          "name": "Mueang Kae",
          "postCode": [
            "31150"
          ]
        },
        {
          "id": 311102,
          "name": "Nikhom",
          "postCode": [
            "31150"
          ]
        },
        {
          "id": 311105,
          "name": "Nong Yai",
          "postCode": [
            "31150"
          ]
        },
        {
          "id": 311106,
          "name": "Ron Thong",
          "postCode": [
            "31150"
          ]
        },
        {
          "id": 311112,
          "name": "Sakae",
          "postCode": [
            "31150"
          ]
        },
        {
          "id": 311114,
          "name": "Sanam Chai",
          "postCode": [
            "31150"
          ]
        },
        {
          "id": 311101,
          "name": "Satuek",
          "postCode": [
            "31150"
          ]
        },
        {
          "id": 311111,
          "name": "Tha Muang",
          "postCode": [
            "31150"
          ]
        },
        {
          "id": 311103,
          "name": "Thung Wang",
          "postCode": [
            "31150"
          ]
        }
      ]
    },
    {
      "city": "Ban Pho",
      "cityCode": 2405,
      "provinceCode": 24,
      "subDistrict": [
        {
          "id": 240501,
          "name": "Ban Pho",
          "postCode": [
            "24140"
          ]
        },
        {
          "id": 240512,
          "name": "Bang Krut",
          "postCode": [
            "24140"
          ]
        },
        {
          "id": 240511,
          "name": "Bang Son",
          "postCode": [
            "24140"
          ]
        },
        {
          "id": 240506,
          "name": "Don Sai",
          "postCode": [
            "24140"
          ]
        },
        {
          "id": 240504,
          "name": "Khlong Ban Pho",
          "postCode": [
            "24140"
          ]
        },
        {
          "id": 240503,
          "name": "Khlong Khut",
          "postCode": [
            "24140"
          ]
        },
        {
          "id": 240505,
          "name": "Khlong Prawet",
          "postCode": [
            "24140"
          ]
        },
        {
          "id": 240502,
          "name": "Ko Rai",
          "postCode": [
            "24140"
          ]
        },
        {
          "id": 240513,
          "name": "Laem Pradu",
          "postCode": [
            "24140"
          ]
        },
        {
          "id": 240514,
          "name": "Lat Khwang",
          "postCode": [
            "24140"
          ]
        },
        {
          "id": 240510,
          "name": "Nong Bua",
          "postCode": [
            "24140"
          ]
        },
        {
          "id": 240509,
          "name": "Nong Tin Nok",
          "postCode": [
            "24140"
          ]
        },
        {
          "id": 240516,
          "name": "Saen Phu Dat",
          "postCode": [
            "24140"
          ]
        },
        {
          "id": 240515,
          "name": "Sanam Chan",
          "postCode": [
            "24140"
          ]
        },
        {
          "id": 240517,
          "name": "Sip Et Sok",
          "postCode": [
            "24140"
          ]
        },
        {
          "id": 240508,
          "name": "Tha Phlap",
          "postCode": [
            "24140"
          ]
        },
        {
          "id": 240507,
          "name": "Theppharat",
          "postCode": [
            "24140"
          ]
        }
      ]
    },
    {
      "city": "Bang Khla",
      "cityCode": 2402,
      "provinceCode": 24,
      "subDistrict": [
        {
          "id": 240201,
          "name": "Bang Khla",
          "postCode": [
            "24110"
          ]
        },
        {
          "id": 240208,
          "name": "Bang Krachet",
          "postCode": [
            "24110"
          ]
        },
        {
          "id": 240204,
          "name": "Bang Suan",
          "postCode": [
            "24110"
          ]
        },
        {
          "id": 240214,
          "name": "Hua Sai",
          "postCode": [
            "24110"
          ]
        },
        {
          "id": 240209,
          "name": "Pak Nam",
          "postCode": [
            "24110"
          ]
        },
        {
          "id": 240212,
          "name": "Samet Nuea",
          "postCode": [
            "24110"
          ]
        },
        {
          "id": 240213,
          "name": "Samet Tai",
          "postCode": [
            "24110"
          ]
        },
        {
          "id": 240211,
          "name": "Sao Cha-ngok",
          "postCode": [
            "24110"
          ]
        },
        {
          "id": 240210,
          "name": "Tha Thonglang",
          "postCode": [
            "24110"
          ]
        }
      ]
    },
    {
      "city": "Bang Nam Priao",
      "cityCode": 2403,
      "provinceCode": 24,
      "subDistrict": [
        {
          "id": 240302,
          "name": "Bang Khanak",
          "postCode": [
            "24150"
          ]
        },
        {
          "id": 240301,
          "name": "Bang Nam Priao",
          "postCode": [
            "24150"
          ]
        },
        {
          "id": 240305,
          "name": "Bueng Nam Rak",
          "postCode": [
            "24170"
          ]
        },
        {
          "id": 240308,
          "name": "Don Chimphli",
          "postCode": [
            "24170"
          ]
        },
        {
          "id": 240306,
          "name": "Don Ko Ka",
          "postCode": [
            "24170"
          ]
        },
        {
          "id": 240304,
          "name": "Mon Thong",
          "postCode": [
            "24150"
          ]
        },
        {
          "id": 240310,
          "name": "Phrong Akat",
          "postCode": [
            "24150"
          ]
        },
        {
          "id": 240309,
          "name": "Sala Daeng",
          "postCode": [
            "24000"
          ]
        },
        {
          "id": 240303,
          "name": "Singto Thong",
          "postCode": [
            "24150"
          ]
        },
        {
          "id": 240307,
          "name": "Yothaka",
          "postCode": [
            "24150"
          ]
        }
      ]
    },
    {
      "city": "Bang Pakong",
      "cityCode": 2404,
      "provinceCode": 24,
      "subDistrict": [
        {
          "id": 240406,
          "name": "Bang Kluea",
          "postCode": [
            "24180"
          ]
        },
        {
          "id": 240401,
          "name": "Bang Pakong",
          "postCode": [
            "24130"
          ]
        },
        {
          "id": 240405,
          "name": "Bang Phueng",
          "postCode": [
            "24130"
          ]
        },
        {
          "id": 240404,
          "name": "Bang Samak",
          "postCode": [
            "24180"
          ]
        },
        {
          "id": 240403,
          "name": "Bang Wua",
          "postCode": [
            "24180"
          ]
        },
        {
          "id": 240411,
          "name": "Hom Sin",
          "postCode": [
            "24180"
          ]
        },
        {
          "id": 240412,
          "name": "Khao Din",
          "postCode": [
            "24130"
          ]
        },
        {
          "id": 240408,
          "name": "Nong Chok",
          "postCode": [
            "24130"
          ]
        },
        {
          "id": 240409,
          "name": "Phimpha",
          "postCode": [
            "24180"
          ]
        },
        {
          "id": 240407,
          "name": "Song Khlong",
          "postCode": [
            "24130"
          ]
        },
        {
          "id": 240410,
          "name": "Tha Kham",
          "postCode": [
            "24130"
          ]
        },
        {
          "id": 240402,
          "name": "Tha Sa-an",
          "postCode": [
            "24130"
          ]
        }
      ]
    },
    {
      "city": "Khlong Khuean",
      "cityCode": 2411,
      "provinceCode": 24,
      "subDistrict": [
        {
          "id": 241103,
          "name": "Bang Lao",
          "postCode": [
            "24000"
          ]
        },
        {
          "id": 241104,
          "name": "Bang Rong",
          "postCode": [
            "24000"
          ]
        },
        {
          "id": 241105,
          "name": "Bang Talat",
          "postCode": [
            "24110"
          ]
        },
        {
          "id": 241102,
          "name": "Khlong Khuean",
          "postCode": [
            "24000"
          ]
        },
        {
          "id": 241101,
          "name": "Kon Kaeo",
          "postCode": [
            "24000"
          ]
        }
      ]
    },
    {
      "city": "Mueang Chachoengsao",
      "cityCode": 2401,
      "provinceCode": 24,
      "subDistrict": [
        {
          "id": 240103,
          "name": "Ban Mai",
          "postCode": [
            "24000"
          ]
        },
        {
          "id": 240108,
          "name": "Bang Kaeo",
          "postCode": [
            "24000"
          ]
        },
        {
          "id": 240114,
          "name": "Bang Kahai",
          "postCode": [
            "24000"
          ]
        },
        {
          "id": 240109,
          "name": "Bang Khwan",
          "postCode": [
            "24000"
          ]
        },
        {
          "id": 240106,
          "name": "Bang Phai",
          "postCode": [
            "24000"
          ]
        },
        {
          "id": 240113,
          "name": "Bang Phra",
          "postCode": [
            "24000"
          ]
        },
        {
          "id": 240105,
          "name": "Bang Tin Pet",
          "postCode": [
            "24000"
          ]
        },
        {
          "id": 240119,
          "name": "Bang Toei",
          "postCode": [
            "24000"
          ]
        },
        {
          "id": 240107,
          "name": "Khlong Chuk Krachoe",
          "postCode": [
            "24000"
          ]
        },
        {
          "id": 240118,
          "name": "Khlong Luang Phaeng",
          "postCode": [
            "24000"
          ]
        },
        {
          "id": 240104,
          "name": "Khlong Na",
          "postCode": [
            "24000"
          ]
        },
        {
          "id": 240110,
          "name": "Khlong Nakhon Nueang Khet",
          "postCode": [
            "24000"
          ]
        },
        {
          "id": 240116,
          "name": "Khlong Preng",
          "postCode": [
            "24000"
          ]
        },
        {
          "id": 240117,
          "name": "Khlong Udom Chonlachon",
          "postCode": [
            "24000"
          ]
        },
        {
          "id": 240101,
          "name": "Na Mueang",
          "postCode": [
            "24000"
          ]
        },
        {
          "id": 240115,
          "name": "Nam Daeng",
          "postCode": [
            "24000"
          ]
        },
        {
          "id": 240112,
          "name": "Sothon",
          "postCode": [
            "24000"
          ]
        },
        {
          "id": 240102,
          "name": "Tha Khai",
          "postCode": [
            "24000"
          ]
        },
        {
          "id": 240111,
          "name": "Wang Takhian",
          "postCode": [
            "24000"
          ]
        }
      ]
    },
    {
      "city": "Phanom Sarakham",
      "cityCode": 2406,
      "provinceCode": 24,
      "subDistrict": [
        {
          "id": 240602,
          "name": "Ban Song",
          "postCode": [
            "24120"
          ]
        },
        {
          "id": 240608,
          "name": "Khao Hin Son",
          "postCode": [
            "24120"
          ]
        },
        {
          "id": 240601,
          "name": "Ko Khanun",
          "postCode": [
            "24120"
          ]
        },
        {
          "id": 240604,
          "name": "Mueang Kao",
          "postCode": [
            "24120"
          ]
        },
        {
          "id": 240607,
          "name": "Nong Nae",
          "postCode": [
            "24120"
          ]
        },
        {
          "id": 240605,
          "name": "Nong Yao",
          "postCode": [
            "24120"
          ]
        },
        {
          "id": 240603,
          "name": "Phanom Sarakham",
          "postCode": [
            "24120"
          ]
        },
        {
          "id": 240606,
          "name": "Tha Than",
          "postCode": [
            "24120"
          ]
        }
      ]
    },
    {
      "city": "Plaeng Yao",
      "cityCode": 2409,
      "provinceCode": 24,
      "subDistrict": [
        {
          "id": 240903,
          "name": "Hua Samrong",
          "postCode": [
            "24190"
          ]
        },
        {
          "id": 240904,
          "name": "Nong Mai Kaen",
          "postCode": [
            "24190"
          ]
        },
        {
          "id": 240901,
          "name": "Plaeng Yao",
          "postCode": [
            "24190"
          ]
        },
        {
          "id": 240902,
          "name": "Wang Yen",
          "postCode": [
            "24190"
          ]
        }
      ]
    },
    {
      "city": "Ratchasan",
      "cityCode": 2407,
      "provinceCode": 24,
      "subDistrict": [
        {
          "id": 240701,
          "name": "Bang Kha",
          "postCode": [
            "24120"
          ]
        },
        {
          "id": 240703,
          "name": "Dong Noi",
          "postCode": [
            "24120"
          ]
        },
        {
          "id": 240702,
          "name": "Mueang Mai",
          "postCode": [
            "24120"
          ]
        }
      ]
    },
    {
      "city": "Sanam Chai Khet",
      "cityCode": 2408,
      "provinceCode": 24,
      "subDistrict": [
        {
          "id": 240801,
          "name": "Ku Yai Mi",
          "postCode": [
            "24160"
          ]
        },
        {
          "id": 240805,
          "name": "Lat Krathing",
          "postCode": [
            "24160"
          ]
        },
        {
          "id": 240802,
          "name": "Tha Kradan",
          "postCode": [
            "24160"
          ]
        },
        {
          "id": 240803,
          "name": "Thung Phraya",
          "postCode": [
            "24160"
          ]
        }
      ]
    },
    {
      "city": "Tha Takiap",
      "cityCode": 2410,
      "provinceCode": 24,
      "subDistrict": [
        {
          "id": 241002,
          "name": "Khlong Takrao",
          "postCode": [
            "24160"
          ]
        },
        {
          "id": 241001,
          "name": "Tha Takiap",
          "postCode": [
            "24160"
          ]
        }
      ]
    },
    {
      "city": "Hankha",
      "cityCode": 1806,
      "provinceCode": 18,
      "subDistrict": [
        {
          "id": 180602,
          "name": "Ban Chian",
          "postCode": [
            "17130"
          ]
        },
        {
          "id": 180609,
          "name": "Den Yai",
          "postCode": [
            "17130"
          ]
        },
        {
          "id": 180601,
          "name": "Hankha",
          "postCode": [
            "17130"
          ]
        },
        {
          "id": 180607,
          "name": "Huai Ngu",
          "postCode": [
            "17160"
          ]
        },
        {
          "id": 180606,
          "name": "Nong Saeng",
          "postCode": [
            "17160"
          ]
        },
        {
          "id": 180605,
          "name": "Phrai Nok Yung",
          "postCode": [
            "17130"
          ]
        },
        {
          "id": 180611,
          "name": "Sam Ngam Tha Bot",
          "postCode": [
            "17160"
          ]
        },
        {
          "id": 180608,
          "name": "Wang Kai Thuean",
          "postCode": [
            "17130"
          ]
        }
      ]
    },
    {
      "city": "Manorom",
      "cityCode": 1802,
      "provinceCode": 18,
      "subDistrict": [
        {
          "id": 180205,
          "name": "Hang Nam Sakhon",
          "postCode": [
            "17170"
          ]
        },
        {
          "id": 180201,
          "name": "Khung Samphao",
          "postCode": [
            "17110"
          ]
        },
        {
          "id": 180206,
          "name": "Rai Phatthana",
          "postCode": [
            "17170"
          ]
        },
        {
          "id": 180203,
          "name": "Sila Dan",
          "postCode": [
            "17110"
          ]
        },
        {
          "id": 180204,
          "name": "Tha Chanuan",
          "postCode": [
            "17110"
          ]
        },
        {
          "id": 180207,
          "name": "U Taphao",
          "postCode": [
            "17170"
          ]
        },
        {
          "id": 180202,
          "name": "Wat Khok",
          "postCode": [
            "17110",
            "17170"
          ]
        }
      ]
    },
    {
      "city": "Mueang Chai Nat",
      "cityCode": 1801,
      "provinceCode": 18,
      "subDistrict": [
        {
          "id": 180102,
          "name": "Ban Kuai",
          "postCode": [
            "17000"
          ]
        },
        {
          "id": 180104,
          "name": "Chai Nat",
          "postCode": [
            "17000"
          ]
        },
        {
          "id": 180106,
          "name": "Hat Tha Sao",
          "postCode": [
            "17000",
            "17120"
          ]
        },
        {
          "id": 180105,
          "name": "Khao Tha Phra",
          "postCode": [
            "17000"
          ]
        },
        {
          "id": 180101,
          "name": "Nai Mueang",
          "postCode": [
            "17000"
          ]
        },
        {
          "id": 180109,
          "name": "Nang Lue",
          "postCode": [
            "17000"
          ]
        },
        {
          "id": 180108,
          "name": "Suea Hok",
          "postCode": [
            "17000"
          ]
        },
        {
          "id": 180103,
          "name": "Tha Chai",
          "postCode": [
            "17000"
          ]
        },
        {
          "id": 180107,
          "name": "Thammamun",
          "postCode": [
            "17000"
          ]
        }
      ]
    },
    {
      "city": "Noen Kham",
      "cityCode": 1808,
      "provinceCode": 18,
      "subDistrict": [
        {
          "id": 180802,
          "name": "Kabok Tia",
          "postCode": [
            "17130"
          ]
        },
        {
          "id": 180801,
          "name": "Noen Kham",
          "postCode": [
            "17130"
          ]
        },
        {
          "id": 180803,
          "name": "Suk Duean Ha",
          "postCode": [
            "17130"
          ]
        }
      ]
    },
    {
      "city": "Nong Mamong",
      "cityCode": 1807,
      "provinceCode": 18,
      "subDistrict": [
        {
          "id": 180704,
          "name": "Kut Chok",
          "postCode": [
            "17120"
          ]
        },
        {
          "id": 180701,
          "name": "Nong Mamong",
          "postCode": [
            "17120"
          ]
        },
        {
          "id": 180703,
          "name": "Saphan Hin",
          "postCode": [
            "17120"
          ]
        },
        {
          "id": 180702,
          "name": "Wang Takhian",
          "postCode": [
            "17120"
          ]
        }
      ]
    },
    {
      "city": "Sankhaburi",
      "cityCode": 1805,
      "provinceCode": 18,
      "subDistrict": [
        {
          "id": 180505,
          "name": "Bang Khut",
          "postCode": [
            "17140"
          ]
        },
        {
          "id": 180507,
          "name": "Don Kam",
          "postCode": [
            "17140"
          ]
        },
        {
          "id": 180506,
          "name": "Dong Khon",
          "postCode": [
            "17140"
          ]
        },
        {
          "id": 180503,
          "name": "Huai Krot",
          "postCode": [
            "17140"
          ]
        },
        {
          "id": 180508,
          "name": "Huai Krot Phatthana",
          "postCode": [
            "17140"
          ]
        },
        {
          "id": 180504,
          "name": "Pho Ngam",
          "postCode": [
            "17140"
          ]
        },
        {
          "id": 180501,
          "name": "Phraek Si Racha",
          "postCode": [
            "17140"
          ]
        },
        {
          "id": 180502,
          "name": "Thiang Thae",
          "postCode": [
            "17140"
          ]
        }
      ]
    },
    {
      "city": "Sapphaya",
      "cityCode": 1804,
      "provinceCode": 18,
      "subDistrict": [
        {
          "id": 180406,
          "name": "Bang Luang",
          "postCode": [
            "17150"
          ]
        },
        {
          "id": 180407,
          "name": "Hat Asa",
          "postCode": [
            "17150"
          ]
        },
        {
          "id": 180403,
          "name": "Khao Kaeo",
          "postCode": [
            "17150"
          ]
        },
        {
          "id": 180405,
          "name": "Pho Nang Dam Ok",
          "postCode": [
            "17150"
          ]
        },
        {
          "id": 180404,
          "name": "Pho Nang Dam Tok",
          "postCode": [
            "17150"
          ]
        },
        {
          "id": 180401,
          "name": "Sapphaya",
          "postCode": [
            "17150"
          ]
        },
        {
          "id": 180402,
          "name": "Taluk",
          "postCode": [
            "17150"
          ]
        }
      ]
    },
    {
      "city": "Wat Sing",
      "cityCode": 1803,
      "provinceCode": 18,
      "subDistrict": [
        {
          "id": 180307,
          "name": "Bo Rae",
          "postCode": [
            "17120"
          ]
        },
        {
          "id": 180302,
          "name": "Makham Thao",
          "postCode": [
            "17120"
          ]
        },
        {
          "id": 180304,
          "name": "Nong Bua",
          "postCode": [
            "17120"
          ]
        },
        {
          "id": 180306,
          "name": "Nong Khun",
          "postCode": [
            "17120"
          ]
        },
        {
          "id": 180303,
          "name": "Nong Noi",
          "postCode": [
            "17120"
          ]
        },
        {
          "id": 180311,
          "name": "Wang Man",
          "postCode": [
            "17120"
          ]
        },
        {
          "id": 180301,
          "name": "Wat Sing",
          "postCode": [
            "17120"
          ]
        }
      ]
    },
    {
      "city": "Bamnet Narong",
      "cityCode": 3607,
      "provinceCode": 36,
      "subDistrict": [
        {
          "id": 360701,
          "name": "Ban Chuan",
          "postCode": [
            "36160"
          ]
        },
        {
          "id": 360702,
          "name": "Ban Phet",
          "postCode": [
            "36160"
          ]
        },
        {
          "id": 360703,
          "name": "Ban Tan",
          "postCode": [
            "36220"
          ]
        },
        {
          "id": 360704,
          "name": "Hua Thale",
          "postCode": [
            "36220"
          ]
        },
        {
          "id": 360707,
          "name": "Khok Phet Phatthana",
          "postCode": [
            "36160"
          ]
        },
        {
          "id": 360705,
          "name": "Khok Roeng Rom",
          "postCode": [
            "36160"
          ]
        },
        {
          "id": 360706,
          "name": "Ko Manao",
          "postCode": [
            "36160"
          ]
        }
      ]
    },
    {
      "city": "Ban Khwao",
      "cityCode": 3602,
      "provinceCode": 36,
      "subDistrict": [
        {
          "id": 360201,
          "name": "Ban Khwao",
          "postCode": [
            "36170"
          ]
        },
        {
          "id": 360204,
          "name": "Chi Bon",
          "postCode": [
            "36170"
          ]
        },
        {
          "id": 360203,
          "name": "Lum Lam Chi",
          "postCode": [
            "36170"
          ]
        },
        {
          "id": 360206,
          "name": "Non Daeng",
          "postCode": [
            "36170"
          ]
        },
        {
          "id": 360205,
          "name": "Phu Laen Kha",
          "postCode": [
            "36170"
          ]
        },
        {
          "id": 360202,
          "name": "Talat Raeng",
          "postCode": [
            "36170"
          ]
        }
      ]
    },
    {
      "city": "Ban Thaen",
      "cityCode": 3611,
      "provinceCode": 36,
      "subDistrict": [
        {
          "id": 361104,
          "name": "Ban Tao",
          "postCode": [
            "36190"
          ]
        },
        {
          "id": 361101,
          "name": "Ban Thaen",
          "postCode": [
            "36190"
          ]
        },
        {
          "id": 361105,
          "name": "Nong Khu",
          "postCode": [
            "36190"
          ]
        },
        {
          "id": 361103,
          "name": "Sa Phang",
          "postCode": [
            "36190"
          ]
        },
        {
          "id": 361102,
          "name": "Sam Suan",
          "postCode": [
            "36190"
          ]
        }
      ]
    },
    {
      "city": "Chatturat",
      "cityCode": 3606,
      "provinceCode": 36,
      "subDistrict": [
        {
          "id": 360603,
          "name": "Ban Kham",
          "postCode": [
            "36130"
          ]
        },
        {
          "id": 360601,
          "name": "Ban Kok",
          "postCode": [
            "36130"
          ]
        },
        {
          "id": 360605,
          "name": "Kut Nam Sai",
          "postCode": [
            "36130"
          ]
        },
        {
          "id": 360607,
          "name": "Lahan",
          "postCode": [
            "36130"
          ]
        },
        {
          "id": 360602,
          "name": "Nong Bua Ban",
          "postCode": [
            "36130"
          ]
        },
        {
          "id": 360611,
          "name": "Nong Bua Khok",
          "postCode": [
            "36220"
          ]
        },
        {
          "id": 360610,
          "name": "Nong Bua Yai",
          "postCode": [
            "36130"
          ]
        },
        {
          "id": 360606,
          "name": "Nong Don",
          "postCode": [
            "36130"
          ]
        },
        {
          "id": 360613,
          "name": "Som Poi",
          "postCode": [
            "36130"
          ]
        }
      ]
    },
    {
      "city": "Kaeng Khro",
      "cityCode": 3612,
      "provinceCode": 36,
      "subDistrict": [
        {
          "id": 361204,
          "name": "Ban Kaeng",
          "postCode": [
            "36150"
          ]
        },
        {
          "id": 361201,
          "name": "Chong Sam Mo",
          "postCode": [
            "36150"
          ]
        },
        {
          "id": 361208,
          "name": "Kao Ya Di",
          "postCode": [
            "36150"
          ]
        },
        {
          "id": 361207,
          "name": "Khok Kung",
          "postCode": [
            "36150"
          ]
        },
        {
          "id": 361206,
          "name": "Lup Kha",
          "postCode": [
            "36150"
          ]
        },
        {
          "id": 361203,
          "name": "Na Nong Thum",
          "postCode": [
            "36150"
          ]
        },
        {
          "id": 361202,
          "name": "Nong Kham",
          "postCode": [
            "36150"
          ]
        },
        {
          "id": 361210,
          "name": "Nong Phai",
          "postCode": [
            "36150"
          ]
        },
        {
          "id": 361205,
          "name": "Nong Sang",
          "postCode": [
            "36150"
          ]
        },
        {
          "id": 361209,
          "name": "Tha Mafai Wan",
          "postCode": [
            "36150"
          ]
        }
      ]
    },
    {
      "city": "Kaset Sombun",
      "cityCode": 3604,
      "provinceCode": 36,
      "subDistrict": [
        {
          "id": 360410,
          "name": "Ban Bua",
          "postCode": [
            "36120"
          ]
        },
        {
          "id": 360403,
          "name": "Ban Duea",
          "postCode": [
            "36120"
          ]
        },
        {
          "id": 360402,
          "name": "Ban Han",
          "postCode": [
            "36120"
          ]
        },
        {
          "id": 360404,
          "name": "Ban Pao",
          "postCode": [
            "36120"
          ]
        },
        {
          "id": 360401,
          "name": "Ban Yang",
          "postCode": [
            "36120"
          ]
        },
        {
          "id": 360405,
          "name": "Kut Lo",
          "postCode": [
            "36120"
          ]
        },
        {
          "id": 360406,
          "name": "Non Kok",
          "postCode": [
            "36120"
          ]
        },
        {
          "id": 360412,
          "name": "Non Thong",
          "postCode": [
            "36120"
          ]
        },
        {
          "id": 360408,
          "name": "Nong Kha",
          "postCode": [
            "36120"
          ]
        },
        {
          "id": 360409,
          "name": "Nong Phon Ngam",
          "postCode": [
            "36120"
          ]
        },
        {
          "id": 360407,
          "name": "Sa Phon Thong",
          "postCode": [
            "36120"
          ]
        }
      ]
    },
    {
      "city": "Khon San",
      "cityCode": 3613,
      "provinceCode": 36,
      "subDistrict": [
        {
          "id": 361306,
          "name": "Dong Bang",
          "postCode": [
            "36180"
          ]
        },
        {
          "id": 361308,
          "name": "Dong Klang",
          "postCode": [
            "36180"
          ]
        },
        {
          "id": 361304,
          "name": "Huai Yang",
          "postCode": [
            "36180"
          ]
        },
        {
          "id": 361301,
          "name": "Khon San",
          "postCode": [
            "36180"
          ]
        },
        {
          "id": 361303,
          "name": "Non Khun",
          "postCode": [
            "36180"
          ]
        },
        {
          "id": 361305,
          "name": "Thung Luilai",
          "postCode": [
            "36180"
          ]
        },
        {
          "id": 361307,
          "name": "Thung Na Lao",
          "postCode": [
            "36180"
          ]
        },
        {
          "id": 361302,
          "name": "Thung Phra",
          "postCode": [
            "36180"
          ]
        }
      ]
    },
    {
      "city": "Khon Sawan",
      "cityCode": 3603,
      "provinceCode": 36,
      "subDistrict": [
        {
          "id": 360306,
          "name": "Ban Sok",
          "postCode": [
            "36140"
          ]
        },
        {
          "id": 360303,
          "name": "Chong Sam Mo",
          "postCode": [
            "36140"
          ]
        },
        {
          "id": 360305,
          "name": "Huai Rai",
          "postCode": [
            "36140"
          ]
        },
        {
          "id": 360307,
          "name": "Khok Mang Ngoi",
          "postCode": [
            "36140"
          ]
        },
        {
          "id": 360301,
          "name": "Khon Sawan",
          "postCode": [
            "36140"
          ]
        },
        {
          "id": 360304,
          "name": "Non Sa-at",
          "postCode": [
            "36140"
          ]
        },
        {
          "id": 360308,
          "name": "Nong Kham",
          "postCode": [
            "36140"
          ]
        },
        {
          "id": 360309,
          "name": "Si Samran",
          "postCode": [
            "36140"
          ]
        },
        {
          "id": 360302,
          "name": "Yang Wai",
          "postCode": [
            "36140"
          ]
        }
      ]
    },
    {
      "city": "Mueang Chaiyaphum",
      "cityCode": 3601,
      "provinceCode": 36,
      "subDistrict": [
        {
          "id": 360105,
          "name": "Ban Khai",
          "postCode": [
            "36240"
          ]
        },
        {
          "id": 360108,
          "name": "Ban Lao",
          "postCode": [
            "36000"
          ]
        },
        {
          "id": 360118,
          "name": "Bung Khla",
          "postCode": [
            "36000"
          ]
        },
        {
          "id": 360107,
          "name": "Chi Long",
          "postCode": [
            "36000"
          ]
        },
        {
          "id": 360115,
          "name": "Huai Bong",
          "postCode": [
            "36000"
          ]
        },
        {
          "id": 360114,
          "name": "Huai Ton",
          "postCode": [
            "36000"
          ]
        },
        {
          "id": 360117,
          "name": "Khok Sung",
          "postCode": [
            "36000"
          ]
        },
        {
          "id": 360106,
          "name": "Kut Tum",
          "postCode": [
            "36000"
          ]
        },
        {
          "id": 360111,
          "name": "Lat Yai",
          "postCode": [
            "36000"
          ]
        },
        {
          "id": 360104,
          "name": "Na Fai",
          "postCode": [
            "36000"
          ]
        },
        {
          "id": 360109,
          "name": "Na Siao",
          "postCode": [
            "36000"
          ]
        },
        {
          "id": 360101,
          "name": "Nai Mueang",
          "postCode": [
            "36000"
          ]
        },
        {
          "id": 360116,
          "name": "Non Samran",
          "postCode": [
            "36240"
          ]
        },
        {
          "id": 360110,
          "name": "Nong Na Saeng",
          "postCode": [
            "36000"
          ]
        },
        {
          "id": 360112,
          "name": "Nong Phai",
          "postCode": [
            "36240"
          ]
        },
        {
          "id": 360103,
          "name": "Phon Thong",
          "postCode": [
            "36000"
          ]
        },
        {
          "id": 360102,
          "name": "Rop Mueang",
          "postCode": [
            "36000"
          ]
        },
        {
          "id": 360119,
          "name": "Sap Si Thong",
          "postCode": [
            "36000"
          ]
        },
        {
          "id": 360113,
          "name": "Tha Hin Ngom",
          "postCode": [
            "36000"
          ]
        }
      ]
    },
    {
      "city": "Noen Sa-nga",
      "cityCode": 3615,
      "provinceCode": 36,
      "subDistrict": [
        {
          "id": 361503,
          "name": "Kahat",
          "postCode": [
            "36130"
          ]
        },
        {
          "id": 361501,
          "name": "Nong Chim",
          "postCode": [
            "36130"
          ]
        },
        {
          "id": 361504,
          "name": "Rang Ngam",
          "postCode": [
            "36130"
          ]
        },
        {
          "id": 361502,
          "name": "Ta Noen",
          "postCode": [
            "36130"
          ]
        }
      ]
    },
    {
      "city": "Nong Bua Daeng",
      "cityCode": 3605,
      "provinceCode": 36,
      "subDistrict": [
        {
          "id": 360508,
          "name": "Khu Mueang",
          "postCode": [
            "36210"
          ]
        },
        {
          "id": 360502,
          "name": "Kut Chum Saeng",
          "postCode": [
            "36210"
          ]
        },
        {
          "id": 360504,
          "name": "Nang Daet",
          "postCode": [
            "36210"
          ]
        },
        {
          "id": 360501,
          "name": "Nong Bua Daeng",
          "postCode": [
            "36210"
          ]
        },
        {
          "id": 360507,
          "name": "Nong Waeng",
          "postCode": [
            "36210"
          ]
        },
        {
          "id": 360509,
          "name": "Tha Yai",
          "postCode": [
            "36210"
          ]
        },
        {
          "id": 360503,
          "name": "Tham Wua Daeng",
          "postCode": [
            "36210"
          ]
        },
        {
          "id": 360511,
          "name": "Wang Chomphu",
          "postCode": [
            "36210"
          ]
        }
      ]
    },
    {
      "city": "Nong Bua Rawe",
      "cityCode": 3608,
      "provinceCode": 36,
      "subDistrict": [
        {
          "id": 360803,
          "name": "Huai Yae",
          "postCode": [
            "36250"
          ]
        },
        {
          "id": 360804,
          "name": "Khok Sa-at",
          "postCode": [
            "36250"
          ]
        },
        {
          "id": 360801,
          "name": "Nong Bua Rawe",
          "postCode": [
            "36250"
          ]
        },
        {
          "id": 360805,
          "name": "Sok Pla Duk",
          "postCode": [
            "36250"
          ]
        },
        {
          "id": 360802,
          "name": "Wang Takhe",
          "postCode": [
            "36250"
          ]
        }
      ]
    },
    {
      "city": "Phakdi Chumphon",
      "cityCode": 3614,
      "provinceCode": 36,
      "subDistrict": [
        {
          "id": 361401,
          "name": "Ban Chiang",
          "postCode": [
            "36260"
          ]
        },
        {
          "id": 361402,
          "name": "Chao Thong",
          "postCode": [
            "36260"
          ]
        },
        {
          "id": 361404,
          "name": "Laem Thong",
          "postCode": [
            "36260"
          ]
        },
        {
          "id": 361403,
          "name": "Wang Thong",
          "postCode": [
            "36260"
          ]
        }
      ]
    },
    {
      "city": "Phu Khiao",
      "cityCode": 3610,
      "provinceCode": 36,
      "subDistrict": [
        {
          "id": 361011,
          "name": "Ban Don",
          "postCode": [
            "36110"
          ]
        },
        {
          "id": 361004,
          "name": "Ban Kaeng",
          "postCode": [
            "36110"
          ]
        },
        {
          "id": 361006,
          "name": "Ban Phet",
          "postCode": [
            "36110"
          ]
        },
        {
          "id": 361007,
          "name": "Khok Sa-at",
          "postCode": [
            "36110"
          ]
        },
        {
          "id": 361005,
          "name": "Kut Yom",
          "postCode": [
            "36110"
          ]
        },
        {
          "id": 361002,
          "name": "Kwang Chon",
          "postCode": [
            "36110"
          ]
        },
        {
          "id": 361003,
          "name": "Nong Khon Thai",
          "postCode": [
            "36110"
          ]
        },
        {
          "id": 361008,
          "name": "Nong Tum",
          "postCode": [
            "36110"
          ]
        },
        {
          "id": 361009,
          "name": "Olo",
          "postCode": [
            "36110"
          ]
        },
        {
          "id": 361001,
          "name": "Phak Pang",
          "postCode": [
            "36110"
          ]
        },
        {
          "id": 361010,
          "name": "That Thong",
          "postCode": [
            "36110"
          ]
        }
      ]
    },
    {
      "city": "Sap Yai",
      "cityCode": 3616,
      "provinceCode": 36,
      "subDistrict": [
        {
          "id": 361601,
          "name": "Sap Yai",
          "postCode": [
            "36130"
          ]
        },
        {
          "id": 361603,
          "name": "Tako Thong",
          "postCode": [
            "36130"
          ]
        },
        {
          "id": 361602,
          "name": "Tha Kup",
          "postCode": [
            "36130"
          ]
        }
      ]
    },
    {
      "city": "Thep Sathit",
      "cityCode": 3609,
      "provinceCode": 36,
      "subDistrict": [
        {
          "id": 360904,
          "name": "Ban Rai",
          "postCode": [
            "36230"
          ]
        },
        {
          "id": 360902,
          "name": "Huai Yai Chio",
          "postCode": [
            "36230"
          ]
        },
        {
          "id": 360903,
          "name": "Na yang Klak",
          "postCode": [
            "36230"
          ]
        },
        {
          "id": 360905,
          "name": "Pong Nok",
          "postCode": [
            "36230"
          ]
        },
        {
          "id": 360901,
          "name": "Wa Tabaek",
          "postCode": [
            "36230"
          ]
        }
      ]
    },
    {
      "city": "Kaeng Hang Maeo",
      "cityCode": 2208,
      "provinceCode": 22,
      "subDistrict": [
        {
          "id": 220801,
          "name": "Kaeng Hang Maeo",
          "postCode": [
            "22160"
          ]
        },
        {
          "id": 220805,
          "name": "Khao Wongkot",
          "postCode": [
            "22160"
          ]
        },
        {
          "id": 220802,
          "name": "Khun Song",
          "postCode": [
            "22160"
          ]
        },
        {
          "id": 220804,
          "name": "Phawa",
          "postCode": [
            "22160"
          ]
        },
        {
          "id": 220803,
          "name": "Sam Phi Nong",
          "postCode": [
            "22160"
          ]
        }
      ]
    },
    {
      "city": "Khao Khitchakut",
      "cityCode": 2210,
      "provinceCode": 22,
      "subDistrict": [
        {
          "id": 221001,
          "name": "Chak Thai",
          "postCode": [
            "22210"
          ]
        },
        {
          "id": 221005,
          "name": "Chanthakhlem",
          "postCode": [
            "22210"
          ]
        },
        {
          "id": 221004,
          "name": "Khlong Phlu",
          "postCode": [
            "22210"
          ]
        },
        {
          "id": 221002,
          "name": "Phluang",
          "postCode": [
            "22210"
          ]
        },
        {
          "id": 221003,
          "name": "Takhian Thong",
          "postCode": [
            "22210"
          ]
        }
      ]
    },
    {
      "city": "Khlung",
      "cityCode": 2202,
      "provinceCode": 22,
      "subDistrict": [
        {
          "id": 220205,
          "name": "Bang Chan",
          "postCode": [
            "22110"
          ]
        },
        {
          "id": 220202,
          "name": "Bo",
          "postCode": [
            "22110"
          ]
        },
        {
          "id": 220212,
          "name": "Bo Weru",
          "postCode": [
            "22150"
          ]
        },
        {
          "id": 220201,
          "name": "Khlung",
          "postCode": [
            "22110"
          ]
        },
        {
          "id": 220203,
          "name": "Kwian Hak",
          "postCode": [
            "22110"
          ]
        },
        {
          "id": 220208,
          "name": "Map Phai",
          "postCode": [
            "22110"
          ]
        },
        {
          "id": 220207,
          "name": "Sueng",
          "postCode": [
            "22110"
          ]
        },
        {
          "id": 220204,
          "name": "Tapon",
          "postCode": [
            "22110"
          ]
        },
        {
          "id": 220211,
          "name": "Tok Phom",
          "postCode": [
            "22110"
          ]
        },
        {
          "id": 220210,
          "name": "Trok Nong",
          "postCode": [
            "22110"
          ]
        },
        {
          "id": 220206,
          "name": "Wan Yao",
          "postCode": [
            "22110"
          ]
        },
        {
          "id": 220209,
          "name": "Wang Sappharot",
          "postCode": [
            "22110"
          ]
        }
      ]
    },
    {
      "city": "Laem Sing",
      "cityCode": 2206,
      "provinceCode": 22,
      "subDistrict": [
        {
          "id": 220607,
          "name": "Bang Kachai",
          "postCode": [
            "22120"
          ]
        },
        {
          "id": 220606,
          "name": "Bang Sa Kao",
          "postCode": [
            "22190"
          ]
        },
        {
          "id": 220605,
          "name": "Khlong Nam Khem",
          "postCode": [
            "22190"
          ]
        },
        {
          "id": 220602,
          "name": "Ko Proet",
          "postCode": [
            "22130"
          ]
        },
        {
          "id": 220603,
          "name": "Nong Chim",
          "postCode": [
            "22130"
          ]
        },
        {
          "id": 220601,
          "name": "Pak Nam Laem Sing",
          "postCode": [
            "22130"
          ]
        },
        {
          "id": 220604,
          "name": "Phlio",
          "postCode": [
            "22190"
          ]
        }
      ]
    },
    {
      "city": "Makham",
      "cityCode": 2205,
      "provinceCode": 22,
      "subDistrict": [
        {
          "id": 220508,
          "name": "Ang Khiri",
          "postCode": [
            "22150"
          ]
        },
        {
          "id": 220506,
          "name": "Chaman",
          "postCode": [
            "22150"
          ]
        },
        {
          "id": 220501,
          "name": "Makham",
          "postCode": [
            "22150"
          ]
        },
        {
          "id": 220503,
          "name": "Patthawi",
          "postCode": [
            "22150"
          ]
        },
        {
          "id": 220502,
          "name": "Tha Luang",
          "postCode": [
            "22150"
          ]
        },
        {
          "id": 220504,
          "name": "Wang Saem",
          "postCode": [
            "22150"
          ]
        }
      ]
    },
    {
      "city": "Mueang Chanthaburi",
      "cityCode": 2201,
      "provinceCode": 22,
      "subDistrict": [
        {
          "id": 220108,
          "name": "Bang Kacha",
          "postCode": [
            "22000"
          ]
        },
        {
          "id": 220107,
          "name": "Chanthanimit",
          "postCode": [
            "22000"
          ]
        },
        {
          "id": 220103,
          "name": "Khlong Narai",
          "postCode": [
            "22000"
          ]
        },
        {
          "id": 220105,
          "name": "Khom Bang",
          "postCode": [
            "22000"
          ]
        },
        {
          "id": 220104,
          "name": "Ko Khwang",
          "postCode": [
            "22000"
          ]
        },
        {
          "id": 220110,
          "name": "Nong Bua",
          "postCode": [
            "22000"
          ]
        },
        {
          "id": 220111,
          "name": "Phlapphla",
          "postCode": [
            "22000"
          ]
        },
        {
          "id": 220109,
          "name": "Salaeng",
          "postCode": [
            "22000"
          ]
        },
        {
          "id": 220101,
          "name": "Talat",
          "postCode": [
            "22000"
          ]
        },
        {
          "id": 220106,
          "name": "Tha Chang",
          "postCode": [
            "22000"
          ]
        },
        {
          "id": 220102,
          "name": "Wat Mai",
          "postCode": [
            "22000"
          ]
        }
      ]
    },
    {
      "city": "Na Yai Am",
      "cityCode": 2209,
      "provinceCode": 22,
      "subDistrict": [
        {
          "id": 220905,
          "name": "Chang Kham",
          "postCode": [
            "22160"
          ]
        },
        {
          "id": 220903,
          "name": "Krachae",
          "postCode": [
            "22170"
          ]
        },
        {
          "id": 220901,
          "name": "Na Yai Am",
          "postCode": [
            "22160"
          ]
        },
        {
          "id": 220904,
          "name": "Sanam Chai",
          "postCode": [
            "22170"
          ]
        },
        {
          "id": 220906,
          "name": "Wang Mai",
          "postCode": [
            "22170"
          ]
        },
        {
          "id": 220902,
          "name": "Wang Tanot",
          "postCode": [
            "22170"
          ]
        }
      ]
    },
    {
      "city": "Pong Nam Ron",
      "cityCode": 2204,
      "provinceCode": 22,
      "subDistrict": [
        {
          "id": 220410,
          "name": "Khlong Yai",
          "postCode": [
            "22140"
          ]
        },
        {
          "id": 220404,
          "name": "Nong Ta Khong",
          "postCode": [
            "22140"
          ]
        },
        {
          "id": 220402,
          "name": "Pong Nam Ron",
          "postCode": [
            "22140"
          ]
        },
        {
          "id": 220401,
          "name": "Thap Sai",
          "postCode": [
            "22140"
          ]
        },
        {
          "id": 220409,
          "name": "Thep Nimit",
          "postCode": [
            "22140"
          ]
        }
      ]
    },
    {
      "city": "Soi Dao",
      "cityCode": 2207,
      "provinceCode": 22,
      "subDistrict": [
        {
          "id": 220701,
          "name": "Patong",
          "postCode": [
            "22180"
          ]
        },
        {
          "id": 220704,
          "name": "Sai Khao",
          "postCode": [
            "22180"
          ]
        },
        {
          "id": 220705,
          "name": "Saton",
          "postCode": [
            "22180"
          ]
        },
        {
          "id": 220703,
          "name": "Thap Chang",
          "postCode": [
            "22180"
          ]
        },
        {
          "id": 220702,
          "name": "Thung Khanan",
          "postCode": [
            "22180"
          ]
        }
      ]
    },
    {
      "city": "Tha Mai",
      "cityCode": 2203,
      "provinceCode": 22,
      "subDistrict": [
        {
          "id": 220304,
          "name": "Bo Phu",
          "postCode": [
            "22120"
          ]
        },
        {
          "id": 220312,
          "name": "Khamong",
          "postCode": [
            "22170"
          ]
        },
        {
          "id": 220307,
          "name": "Khao Baisi",
          "postCode": [
            "22120"
          ]
        },
        {
          "id": 220324,
          "name": "Khao Kaeo",
          "postCode": [
            "22170"
          ]
        },
        {
          "id": 220306,
          "name": "Khao Wua",
          "postCode": [
            "22120"
          ]
        },
        {
          "id": 220314,
          "name": "Khlong Khut",
          "postCode": [
            "22120"
          ]
        },
        {
          "id": 220305,
          "name": "Phloi Waen",
          "postCode": [
            "22120"
          ]
        },
        {
          "id": 220311,
          "name": "Ramphan",
          "postCode": [
            "22170"
          ]
        },
        {
          "id": 220303,
          "name": "Si Phaya",
          "postCode": [
            "22120"
          ]
        },
        {
          "id": 220308,
          "name": "Song Phi Nong",
          "postCode": [
            "22120"
          ]
        },
        {
          "id": 220313,
          "name": "Takat Ngao",
          "postCode": [
            "22120"
          ]
        },
        {
          "id": 220301,
          "name": "Tha Mai",
          "postCode": [
            "22120"
          ]
        },
        {
          "id": 220309,
          "name": "Thung Bencha",
          "postCode": [
            "22170"
          ]
        },
        {
          "id": 220302,
          "name": "Yai Ra",
          "postCode": [
            "22120"
          ]
        }
      ]
    },
    {
      "city": "Chai Prakan",
      "cityCode": 5021,
      "provinceCode": 50,
      "subDistrict": [
        {
          "id": 502103,
          "name": "Mae Thalop",
          "postCode": [
            "50320"
          ]
        },
        {
          "id": 502104,
          "name": "Nong Bua",
          "postCode": [
            "50320"
          ]
        },
        {
          "id": 502101,
          "name": "Pong Tam",
          "postCode": [
            "50320"
          ]
        },
        {
          "id": 502102,
          "name": "Si Dong Yen",
          "postCode": [
            "50320"
          ]
        }
      ]
    },
    {
      "city": "Chiang Dao",
      "cityCode": 5004,
      "provinceCode": 50,
      "subDistrict": [
        {
          "id": 500401,
          "name": "Chiang Dao",
          "postCode": [
            "50170"
          ]
        },
        {
          "id": 500404,
          "name": "Mae Na",
          "postCode": [
            "50170"
          ]
        },
        {
          "id": 500405,
          "name": "Mueang Khong",
          "postCode": [
            "50170"
          ]
        },
        {
          "id": 500402,
          "name": "Mueang Na",
          "postCode": [
            "50170"
          ]
        },
        {
          "id": 500403,
          "name": "Mueang Ngai",
          "postCode": [
            "50170"
          ]
        },
        {
          "id": 500406,
          "name": "Ping Khong",
          "postCode": [
            "50170"
          ]
        },
        {
          "id": 500407,
          "name": "Thung Khao Phuang",
          "postCode": [
            "50170"
          ]
        }
      ]
    },
    {
      "city": "Chom Thong",
      "cityCode": 5002,
      "provinceCode": 50,
      "subDistrict": [
        {
          "id": 500203,
          "name": "Ban Luang",
          "postCode": [
            "50160"
          ]
        },
        {
          "id": 500206,
          "name": "Ban Pae",
          "postCode": [
            "50240"
          ]
        },
        {
          "id": 500207,
          "name": "Doi Kaeo",
          "postCode": [
            "50160"
          ]
        },
        {
          "id": 500204,
          "name": "Khuang Pao",
          "postCode": [
            "50160"
          ]
        },
        {
          "id": 500209,
          "name": "Mae Soi",
          "postCode": [
            "50160",
            "50240"
          ]
        },
        {
          "id": 500205,
          "name": "Sop Tia",
          "postCode": [
            "50160"
          ]
        }
      ]
    },
    {
      "city": "Doi Lo",
      "cityCode": 5024,
      "provinceCode": 50,
      "subDistrict": [
        {
          "id": 502401,
          "name": "Doi Lo",
          "postCode": [
            "50160"
          ]
        },
        {
          "id": 502404,
          "name": "Santi Suk",
          "postCode": [
            "50160"
          ]
        },
        {
          "id": 502402,
          "name": "Song Khwae",
          "postCode": [
            "50160"
          ]
        },
        {
          "id": 502403,
          "name": "Yang Khram",
          "postCode": [
            "50160"
          ]
        }
      ]
    },
    {
      "city": "Doi Saket",
      "cityCode": 5005,
      "provinceCode": 50,
      "subDistrict": [
        {
          "id": 500501,
          "name": "Choeng Doi",
          "postCode": [
            "50220"
          ]
        },
        {
          "id": 500503,
          "name": "Luang Nuea",
          "postCode": [
            "50220"
          ]
        },
        {
          "id": 500511,
          "name": "Mae Hoi Ngoen",
          "postCode": [
            "50220"
          ]
        },
        {
          "id": 500509,
          "name": "Mae Khue",
          "postCode": [
            "50220"
          ]
        },
        {
          "id": 500512,
          "name": "Mae Pong",
          "postCode": [
            "50220"
          ]
        },
        {
          "id": 500506,
          "name": "Pa Lan",
          "postCode": [
            "50220"
          ]
        },
        {
          "id": 500513,
          "name": "Pa Miang",
          "postCode": [
            "50220"
          ]
        },
        {
          "id": 500504,
          "name": "Pa Pong",
          "postCode": [
            "50220"
          ]
        },
        {
          "id": 500505,
          "name": "Sa-nga Ban",
          "postCode": [
            "50220"
          ]
        },
        {
          "id": 500508,
          "name": "Samran Rat",
          "postCode": [
            "50220"
          ]
        },
        {
          "id": 500502,
          "name": "San Pu Loei",
          "postCode": [
            "50220"
          ]
        },
        {
          "id": 500507,
          "name": "Talat Khwan",
          "postCode": [
            "50220"
          ]
        },
        {
          "id": 500510,
          "name": "Talat Yai",
          "postCode": [
            "50220"
          ]
        },
        {
          "id": 500514,
          "name": "Thep Sadet",
          "postCode": [
            "50220"
          ]
        }
      ]
    },
    {
      "city": "Doi Tao",
      "cityCode": 5017,
      "provinceCode": 50,
      "subDistrict": [
        {
          "id": 501704,
          "name": "Ban Aen",
          "postCode": [
            "50260"
          ]
        },
        {
          "id": 501705,
          "name": "Bong Tan",
          "postCode": [
            "50260"
          ]
        },
        {
          "id": 501701,
          "name": "Doi Tao",
          "postCode": [
            "50260"
          ]
        },
        {
          "id": 501703,
          "name": "Muet Ka",
          "postCode": [
            "50260"
          ]
        },
        {
          "id": 501706,
          "name": "Pong Thung",
          "postCode": [
            "50260"
          ]
        },
        {
          "id": 501702,
          "name": "Tha Duea",
          "postCode": [
            "50260"
          ]
        }
      ]
    },
    {
      "city": "Fang",
      "cityCode": 5009,
      "provinceCode": 50,
      "subDistrict": [
        {
          "id": 500910,
          "name": "Mae Kha",
          "postCode": [
            "50110"
          ]
        },
        {
          "id": 500911,
          "name": "Mae Kha",
          "postCode": [
            "50320"
          ]
        },
        {
          "id": 500904,
          "name": "Mae Ngon",
          "postCode": [
            "50320"
          ]
        },
        {
          "id": 500905,
          "name": "Mae Sun",
          "postCode": [
            "50110"
          ]
        },
        {
          "id": 500903,
          "name": "Mon Pin",
          "postCode": [
            "50110"
          ]
        },
        {
          "id": 500912,
          "name": "Pong Nam Ron",
          "postCode": [
            "50110"
          ]
        },
        {
          "id": 500906,
          "name": "San Sai",
          "postCode": [
            "50110"
          ]
        },
        {
          "id": 500901,
          "name": "Wiang",
          "postCode": [
            "50110"
          ]
        }
      ]
    },
    {
      "city": "Galayani Vadhana",
      "cityCode": 5025,
      "provinceCode": 50,
      "subDistrict": [
        {
          "id": 502501,
          "name": "Ban Chan",
          "postCode": [
            "58130"
          ]
        },
        {
          "id": 502503,
          "name": "Chaem Luang",
          "postCode": [
            "58130"
          ]
        },
        {
          "id": 502502,
          "name": "Mae Daet",
          "postCode": [
            "58130"
          ]
        }
      ]
    },
    {
      "city": "Hang Dong",
      "cityCode": 5015,
      "provinceCode": 50,
      "subDistrict": [
        {
          "id": 501510,
          "name": "Ban Pong",
          "postCode": [
            "50230"
          ]
        },
        {
          "id": 501507,
          "name": "Ban Waen",
          "postCode": [
            "50230"
          ]
        },
        {
          "id": 501503,
          "name": "Han Kaeo",
          "postCode": [
            "50230"
          ]
        },
        {
          "id": 501501,
          "name": "Hang Dong",
          "postCode": [
            "50230"
          ]
        },
        {
          "id": 501505,
          "name": "Khun Khong",
          "postCode": [
            "50230"
          ]
        },
        {
          "id": 501511,
          "name": "Nam Phrae",
          "postCode": [
            "50230"
          ]
        },
        {
          "id": 501502,
          "name": "Nong Kaeo",
          "postCode": [
            "50230"
          ]
        },
        {
          "id": 501509,
          "name": "Nong Khwai",
          "postCode": [
            "50230"
          ]
        },
        {
          "id": 501504,
          "name": "Nong Tong",
          "postCode": [
            "50340"
          ]
        },
        {
          "id": 501508,
          "name": "San Phak Wan",
          "postCode": [
            "50230"
          ]
        },
        {
          "id": 501506,
          "name": "Sop Mae Kha",
          "postCode": [
            "50230"
          ]
        }
      ]
    },
    {
      "city": "Hot",
      "cityCode": 5016,
      "provinceCode": 50,
      "subDistrict": [
        {
          "id": 501603,
          "name": "Ban Tan",
          "postCode": [
            "50240"
          ]
        },
        {
          "id": 501604,
          "name": "Bo Luang",
          "postCode": [
            "50240"
          ]
        },
        {
          "id": 501605,
          "name": "Bo Sali",
          "postCode": [
            "50240"
          ]
        },
        {
          "id": 501601,
          "name": "Hang Dong",
          "postCode": [
            "50240"
          ]
        },
        {
          "id": 501602,
          "name": "Hot",
          "postCode": [
            "50240"
          ]
        },
        {
          "id": 501606,
          "name": "Na Kho Ruea",
          "postCode": [
            "50240"
          ]
        }
      ]
    },
    {
      "city": "Mae Ai",
      "cityCode": 5010,
      "provinceCode": 50,
      "subDistrict": [
        {
          "id": 501006,
          "name": "Ban Luang",
          "postCode": [
            "50280"
          ]
        },
        {
          "id": 501001,
          "name": "Mae Ai",
          "postCode": [
            "50280"
          ]
        },
        {
          "id": 501004,
          "name": "Mae Na Wang",
          "postCode": [
            "50280"
          ]
        },
        {
          "id": 501002,
          "name": "Mae Sao",
          "postCode": [
            "50280"
          ]
        },
        {
          "id": 501007,
          "name": "Malika",
          "postCode": [
            "50280"
          ]
        },
        {
          "id": 501003,
          "name": "San Ton Mue",
          "postCode": [
            "50280"
          ]
        },
        {
          "id": 501005,
          "name": "Tha Ton",
          "postCode": [
            "50280"
          ]
        }
      ]
    },
    {
      "city": "Mae Chaem",
      "cityCode": 5003,
      "provinceCode": 50,
      "subDistrict": [
        {
          "id": 500303,
          "name": "Ban Thap",
          "postCode": [
            "50270"
          ]
        },
        {
          "id": 500301,
          "name": "Chang Khoeng",
          "postCode": [
            "50270"
          ]
        },
        {
          "id": 500308,
          "name": "Kong Khaek",
          "postCode": [
            "50270"
          ]
        },
        {
          "id": 500305,
          "name": "Mae Na Chon",
          "postCode": [
            "50270"
          ]
        },
        {
          "id": 500304,
          "name": "Mae Suek",
          "postCode": [
            "50270"
          ]
        },
        {
          "id": 500307,
          "name": "Pang Hin Fon",
          "postCode": [
            "50270"
          ]
        },
        {
          "id": 500302,
          "name": "The Pha",
          "postCode": [
            "50270"
          ]
        }
      ]
    },
    {
      "city": "Mae On",
      "cityCode": 5023,
      "provinceCode": 50,
      "subDistrict": [
        {
          "id": 502303,
          "name": "Ban Sahakon",
          "postCode": [
            "50130"
          ]
        },
        {
          "id": 502304,
          "name": "Huai Kaeo",
          "postCode": [
            "50130"
          ]
        },
        {
          "id": 502305,
          "name": "Mae Tha",
          "postCode": [
            "50130"
          ]
        },
        {
          "id": 502302,
          "name": "On Klang",
          "postCode": [
            "50130"
          ]
        },
        {
          "id": 502301,
          "name": "On Nuea",
          "postCode": [
            "50130"
          ]
        },
        {
          "id": 502306,
          "name": "Tha Nuea",
          "postCode": [
            "50130"
          ]
        }
      ]
    },
    {
      "city": "Mae Rim",
      "cityCode": 5007,
      "provinceCode": 50,
      "subDistrict": [
        {
          "id": 500710,
          "name": "Don Kaeo",
          "postCode": [
            "50180"
          ]
        },
        {
          "id": 500706,
          "name": "Huai Sai",
          "postCode": [
            "50180"
          ]
        },
        {
          "id": 500704,
          "name": "Khilek",
          "postCode": [
            "50180"
          ]
        },
        {
          "id": 500707,
          "name": "Mae Raem",
          "postCode": [
            "50180"
          ]
        },
        {
          "id": 500709,
          "name": "Mae Sa",
          "postCode": [
            "50180"
          ]
        },
        {
          "id": 500711,
          "name": "Mueang Kaeo",
          "postCode": [
            "50180"
          ]
        },
        {
          "id": 500708,
          "name": "Pong Yaeng",
          "postCode": [
            "50180"
          ]
        },
        {
          "id": 500702,
          "name": "Rim Nuea",
          "postCode": [
            "50180"
          ]
        },
        {
          "id": 500701,
          "name": "Rim Tai",
          "postCode": [
            "50180"
          ]
        },
        {
          "id": 500705,
          "name": "Saluang",
          "postCode": [
            "50330"
          ]
        },
        {
          "id": 500703,
          "name": "San Pong",
          "postCode": [
            "50180"
          ]
        }
      ]
    },
    {
      "city": "Mae Taeng",
      "cityCode": 5006,
      "provinceCode": 50,
      "subDistrict": [
        {
          "id": 500611,
          "name": "Ban Chang",
          "postCode": [
            "50150"
          ]
        },
        {
          "id": 500607,
          "name": "Ban Pao",
          "postCode": [
            "50150"
          ]
        },
        {
          "id": 500604,
          "name": "Cho Lae",
          "postCode": [
            "50150"
          ]
        },
        {
          "id": 500613,
          "name": "Inthakhin",
          "postCode": [
            "50150"
          ]
        },
        {
          "id": 500603,
          "name": "Khilek",
          "postCode": [
            "50150"
          ]
        },
        {
          "id": 500612,
          "name": "Kuet Chang",
          "postCode": [
            "50150"
          ]
        },
        {
          "id": 500605,
          "name": "Mae Ho Phra",
          "postCode": [
            "50150"
          ]
        },
        {
          "id": 500602,
          "name": "Mae Taeng",
          "postCode": [
            "50150"
          ]
        },
        {
          "id": 500610,
          "name": "Mueang Kai",
          "postCode": [
            "50150"
          ]
        },
        {
          "id": 500609,
          "name": "Pa Pae",
          "postCode": [
            "50150"
          ]
        },
        {
          "id": 500601,
          "name": "San Maha Phon",
          "postCode": [
            "50150"
          ]
        },
        {
          "id": 500608,
          "name": "San Pa Yang",
          "postCode": [
            "50330"
          ]
        },
        {
          "id": 500606,
          "name": "Sop Poeng",
          "postCode": [
            "50150",
            "50330"
          ]
        }
      ]
    },
    {
      "city": "Mae Wang",
      "cityCode": 5022,
      "provinceCode": 50,
      "subDistrict": [
        {
          "id": 502201,
          "name": "Ban Kat",
          "postCode": [
            "50360"
          ]
        },
        {
          "id": 502205,
          "name": "Don Pao",
          "postCode": [
            "50360"
          ]
        },
        {
          "id": 502204,
          "name": "Mae Win",
          "postCode": [
            "50360"
          ]
        },
        {
          "id": 502202,
          "name": "Thung Pi",
          "postCode": [
            "50360"
          ]
        },
        {
          "id": 502203,
          "name": "Thung Ruang Thong",
          "postCode": [
            "50360"
          ]
        }
      ]
    },
    {
      "city": "Mueang Chiang Mai",
      "cityCode": 5001,
      "provinceCode": 50,
      "subDistrict": [
        {
          "id": 500105,
          "name": "Chang Khlan",
          "postCode": [
            "50100"
          ]
        },
        {
          "id": 500104,
          "name": "Chang Moi",
          "postCode": [
            "50300"
          ]
        },
        {
          "id": 500107,
          "name": "Chang Phueak",
          "postCode": [
            "50300"
          ]
        },
        {
          "id": 500114,
          "name": "Fa Ham",
          "postCode": [
            "50000"
          ]
        },
        {
          "id": 500103,
          "name": "Hai Ya",
          "postCode": [
            "50100"
          ]
        },
        {
          "id": 500109,
          "name": "Mae Hia",
          "postCode": [
            "50100"
          ]
        },
        {
          "id": 500111,
          "name": "Nong Hoi",
          "postCode": [
            "50000"
          ]
        },
        {
          "id": 500113,
          "name": "Nong Pa Khrang",
          "postCode": [
            "50000"
          ]
        },
        {
          "id": 500110,
          "name": "Pa Daet",
          "postCode": [
            "50100"
          ]
        },
        {
          "id": 500115,
          "name": "Pa Tan",
          "postCode": [
            "50300"
          ]
        },
        {
          "id": 500102,
          "name": "Phra Sing",
          "postCode": [
            "50200"
          ]
        },
        {
          "id": 500116,
          "name": "San Phi Suea",
          "postCode": [
            "50290",
            "50300"
          ]
        },
        {
          "id": 500101,
          "name": "Si Phum",
          "postCode": [
            "50200"
          ]
        },
        {
          "id": 500108,
          "name": "Suthep",
          "postCode": [
            "50200"
          ]
        },
        {
          "id": 500112,
          "name": "Tha Sala",
          "postCode": [
            "50000"
          ]
        },
        {
          "id": 500106,
          "name": "Wat Ket",
          "postCode": [
            "50000"
          ]
        }
      ]
    },
    {
      "city": "Omkoi",
      "cityCode": 5018,
      "provinceCode": 50,
      "subDistrict": [
        {
          "id": 501803,
          "name": "Mae Tuen",
          "postCode": [
            "50310"
          ]
        },
        {
          "id": 501804,
          "name": "Mon Chong",
          "postCode": [
            "50310"
          ]
        },
        {
          "id": 501806,
          "name": "Na Kian",
          "postCode": [
            "50310"
          ]
        },
        {
          "id": 501801,
          "name": "Omkoi",
          "postCode": [
            "50310"
          ]
        },
        {
          "id": 501805,
          "name": "Sop Khong",
          "postCode": [
            "50310"
          ]
        },
        {
          "id": 501802,
          "name": "Yang Piang",
          "postCode": [
            "50310"
          ]
        }
      ]
    },
    {
      "city": "Phrao",
      "cityCode": 5011,
      "provinceCode": 50,
      "subDistrict": [
        {
          "id": 501106,
          "name": "Ban Pong",
          "postCode": [
            "50190"
          ]
        },
        {
          "id": 501108,
          "name": "Khuean Phak",
          "postCode": [
            "50190"
          ]
        },
        {
          "id": 501111,
          "name": "Long Khot",
          "postCode": [
            "50190"
          ]
        },
        {
          "id": 501110,
          "name": "Mae Pang",
          "postCode": [
            "50190"
          ]
        },
        {
          "id": 501109,
          "name": "Mae Waen",
          "postCode": [
            "50190"
          ]
        },
        {
          "id": 501107,
          "name": "Nam Phrae",
          "postCode": [
            "50190"
          ]
        },
        {
          "id": 501104,
          "name": "Pa Nai",
          "postCode": [
            "50190"
          ]
        },
        {
          "id": 501103,
          "name": "Pa Tum",
          "postCode": [
            "50190"
          ]
        },
        {
          "id": 501105,
          "name": "San Sai",
          "postCode": [
            "50190"
          ]
        },
        {
          "id": 501102,
          "name": "Thung Luang",
          "postCode": [
            "50190"
          ]
        },
        {
          "id": 501101,
          "name": "Wiang",
          "postCode": [
            "50190"
          ]
        }
      ]
    },
    {
      "city": "Samoeng",
      "cityCode": 5008,
      "provinceCode": 50,
      "subDistrict": [
        {
          "id": 500804,
          "name": "Bo Kaeo",
          "postCode": [
            "50250"
          ]
        },
        {
          "id": 500803,
          "name": "Mae Sap",
          "postCode": [
            "50250"
          ]
        },
        {
          "id": 500802,
          "name": "Samoeng Nuea",
          "postCode": [
            "50250"
          ]
        },
        {
          "id": 500801,
          "name": "Samoeng Tai",
          "postCode": [
            "50250"
          ]
        },
        {
          "id": 500805,
          "name": "Yang Moen",
          "postCode": [
            "50250"
          ]
        }
      ]
    },
    {
      "city": "San Kamphaeng",
      "cityCode": 5013,
      "provinceCode": 50,
      "subDistrict": [
        {
          "id": 501304,
          "name": "Buak Khang",
          "postCode": [
            "50130"
          ]
        },
        {
          "id": 501305,
          "name": "Chae Chang",
          "postCode": [
            "50130"
          ]
        },
        {
          "id": 501311,
          "name": "Huai Sai",
          "postCode": [
            "50130"
          ]
        },
        {
          "id": 501310,
          "name": "Mae Pu Kha",
          "postCode": [
            "50130"
          ]
        },
        {
          "id": 501306,
          "name": "On Tai",
          "postCode": [
            "50130"
          ]
        },
        {
          "id": 501303,
          "name": "Rong Wua Daeng",
          "postCode": [
            "50130"
          ]
        },
        {
          "id": 501302,
          "name": "Sai Mun",
          "postCode": [
            "50130"
          ]
        },
        {
          "id": 501301,
          "name": "San Kamphaeng",
          "postCode": [
            "50130"
          ]
        },
        {
          "id": 501313,
          "name": "San Klang",
          "postCode": [
            "50130"
          ]
        },
        {
          "id": 501312,
          "name": "Ton Pao",
          "postCode": [
            "50130"
          ]
        }
      ]
    },
    {
      "city": "San Pa Tong",
      "cityCode": 5012,
      "provinceCode": 50,
      "subDistrict": [
        {
          "id": 501207,
          "name": "Ban Klang",
          "postCode": [
            "50120"
          ]
        },
        {
          "id": 501206,
          "name": "Ban Mae",
          "postCode": [
            "50120"
          ]
        },
        {
          "id": 501205,
          "name": "Mae Ka",
          "postCode": [
            "50120"
          ]
        },
        {
          "id": 501204,
          "name": "Makham Luang",
          "postCode": [
            "50120"
          ]
        },
        {
          "id": 501215,
          "name": "Makhun Wan",
          "postCode": [
            "50120"
          ]
        },
        {
          "id": 501214,
          "name": "Nam Bo Luang",
          "postCode": [
            "50120"
          ]
        },
        {
          "id": 501202,
          "name": "San Klang",
          "postCode": [
            "50120"
          ]
        },
        {
          "id": 501203,
          "name": "Tha Wang Phrao",
          "postCode": [
            "50120"
          ]
        },
        {
          "id": 501208,
          "name": "Thung Satok",
          "postCode": [
            "50120"
          ]
        },
        {
          "id": 501210,
          "name": "Thung Tom",
          "postCode": [
            "50120"
          ]
        },
        {
          "id": 501201,
          "name": "Yu Wa",
          "postCode": [
            "50120"
          ]
        }
      ]
    },
    {
      "city": "San Sai",
      "cityCode": 5014,
      "provinceCode": 50,
      "subDistrict": [
        {
          "id": 501409,
          "name": "Mae Faek",
          "postCode": [
            "50290"
          ]
        },
        {
          "id": 501410,
          "name": "Mae Faek Mai",
          "postCode": [
            "50290"
          ]
        },
        {
          "id": 501411,
          "name": "Mueang Len",
          "postCode": [
            "50210"
          ]
        },
        {
          "id": 501407,
          "name": "Nong Chom",
          "postCode": [
            "50210",
            "50290"
          ]
        },
        {
          "id": 501408,
          "name": "Nong Han",
          "postCode": [
            "50290"
          ]
        },
        {
          "id": 501406,
          "name": "Nong Yaeng",
          "postCode": [
            "50210"
          ]
        },
        {
          "id": 501412,
          "name": "Pa Phai",
          "postCode": [
            "50210"
          ]
        },
        {
          "id": 501404,
          "name": "San Na Meng",
          "postCode": [
            "50210"
          ]
        },
        {
          "id": 501405,
          "name": "San Pa Pao",
          "postCode": [
            "50210"
          ]
        },
        {
          "id": 501403,
          "name": "San Phra Net",
          "postCode": [
            "50210"
          ]
        },
        {
          "id": 501401,
          "name": "San Sai Luang",
          "postCode": [
            "50210"
          ]
        },
        {
          "id": 501402,
          "name": "San Sai Noi",
          "postCode": [
            "50210"
          ]
        }
      ]
    },
    {
      "city": "Saraphi",
      "cityCode": 5019,
      "provinceCode": 50,
      "subDistrict": [
        {
          "id": 501904,
          "name": "Chai Sathan",
          "postCode": [
            "50140"
          ]
        },
        {
          "id": 501903,
          "name": "Chomphu",
          "postCode": [
            "50140"
          ]
        },
        {
          "id": 501909,
          "name": "Don Kaeo",
          "postCode": [
            "50140"
          ]
        },
        {
          "id": 501905,
          "name": "Khua Mung",
          "postCode": [
            "50140"
          ]
        },
        {
          "id": 501906,
          "name": "Nong Faek",
          "postCode": [
            "50140"
          ]
        },
        {
          "id": 501907,
          "name": "Nong Phueng",
          "postCode": [
            "50140"
          ]
        },
        {
          "id": 501912,
          "name": "Pa Bong",
          "postCode": [
            "50140"
          ]
        },
        {
          "id": 501911,
          "name": "San Sai",
          "postCode": [
            "50140"
          ]
        },
        {
          "id": 501902,
          "name": "Saraphi",
          "postCode": [
            "50140"
          ]
        },
        {
          "id": 501908,
          "name": "Tha Kwang",
          "postCode": [
            "50140"
          ]
        },
        {
          "id": 501910,
          "name": "Tha Wang Tan",
          "postCode": [
            "50140"
          ]
        },
        {
          "id": 501901,
          "name": "Yang Noeng",
          "postCode": [
            "50140"
          ]
        }
      ]
    },
    {
      "city": "Wiang Haeng",
      "cityCode": 5020,
      "provinceCode": 50,
      "subDistrict": [
        {
          "id": 502001,
          "name": "Mueang Haeng",
          "postCode": [
            "50350"
          ]
        },
        {
          "id": 502002,
          "name": "Piang Luang",
          "postCode": [
            "50350"
          ]
        },
        {
          "id": 502003,
          "name": "Saen Hai",
          "postCode": [
            "50350"
          ]
        }
      ]
    },
    {
      "city": "Chiang Khong",
      "cityCode": 5703,
      "provinceCode": 57,
      "subDistrict": [
        {
          "id": 570304,
          "name": "Bun Rueang",
          "postCode": [
            "57140"
          ]
        },
        {
          "id": 570305,
          "name": "Huai So",
          "postCode": [
            "57140"
          ]
        },
        {
          "id": 570303,
          "name": "Khrueng",
          "postCode": [
            "57140"
          ]
        },
        {
          "id": 570310,
          "name": "Rim Khong",
          "postCode": [
            "57140"
          ]
        },
        {
          "id": 570302,
          "name": "Sathan",
          "postCode": [
            "57140"
          ]
        },
        {
          "id": 570308,
          "name": "Si Don Chai",
          "postCode": [
            "57140"
          ]
        },
        {
          "id": 570301,
          "name": "Wiang",
          "postCode": [
            "57140"
          ]
        }
      ]
    },
    {
      "city": "Chiang Saen",
      "cityCode": 5708,
      "provinceCode": 57,
      "subDistrict": [
        {
          "id": 570803,
          "name": "Ban Saeo",
          "postCode": [
            "57150"
          ]
        },
        {
          "id": 570805,
          "name": "Mae Ngoen",
          "postCode": [
            "57150"
          ]
        },
        {
          "id": 570802,
          "name": "Pa Sak",
          "postCode": [
            "57150"
          ]
        },
        {
          "id": 570804,
          "name": "Si Don Mun",
          "postCode": [
            "57150"
          ]
        },
        {
          "id": 570801,
          "name": "Wiang",
          "postCode": [
            "57150"
          ]
        },
        {
          "id": 570806,
          "name": "Yonok",
          "postCode": [
            "57150"
          ]
        }
      ]
    },
    {
      "city": "Doi Luang",
      "cityCode": 5718,
      "provinceCode": 57,
      "subDistrict": [
        {
          "id": 571802,
          "name": "Chok Chai",
          "postCode": [
            "57110"
          ]
        },
        {
          "id": 571803,
          "name": "Nong Pa Ko",
          "postCode": [
            "57110"
          ]
        },
        {
          "id": 571801,
          "name": "Pong Noi",
          "postCode": [
            "57110"
          ]
        }
      ]
    },
    {
      "city": "Khun Tan",
      "cityCode": 5714,
      "provinceCode": 57,
      "subDistrict": [
        {
          "id": 571402,
          "name": "Pa Tan",
          "postCode": [
            "57340"
          ]
        },
        {
          "id": 571401,
          "name": "Ta",
          "postCode": [
            "57340"
          ]
        },
        {
          "id": 571403,
          "name": "Yang Hom",
          "postCode": [
            "57340"
          ]
        }
      ]
    },
    {
      "city": "Mae Chan",
      "cityCode": 5707,
      "provinceCode": 57,
      "subDistrict": [
        {
          "id": 570702,
          "name": "Chan Chwa",
          "postCode": [
            "57270"
          ]
        },
        {
          "id": 570712,
          "name": "Chan Chwa Tai",
          "postCode": [
            "57270"
          ]
        },
        {
          "id": 570713,
          "name": "Chom Sawan",
          "postCode": [
            "57110"
          ]
        },
        {
          "id": 570701,
          "name": "Mae Chan",
          "postCode": [
            "57110"
          ]
        },
        {
          "id": 570703,
          "name": "Mae Kham",
          "postCode": [
            "57240"
          ]
        },
        {
          "id": 570710,
          "name": "Mae Rai",
          "postCode": [
            "57240"
          ]
        },
        {
          "id": 570704,
          "name": "Pa Sang",
          "postCode": [
            "57110"
          ]
        },
        {
          "id": 570708,
          "name": "Pa Tueng",
          "postCode": [
            "57110"
          ]
        },
        {
          "id": 570705,
          "name": "San Sai",
          "postCode": [
            "57110"
          ]
        },
        {
          "id": 570711,
          "name": "Si Kham",
          "postCode": [
            "57110"
          ]
        },
        {
          "id": 570706,
          "name": "Tha Khao Plueak",
          "postCode": [
            "57110"
          ]
        }
      ]
    },
    {
      "city": "Mae Fa Luang",
      "cityCode": 5715,
      "provinceCode": 57,
      "subDistrict": [
        {
          "id": 571504,
          "name": "Mae Fa Luang",
          "postCode": [
            "57240"
          ]
        },
        {
          "id": 571502,
          "name": "Mae Salong Nai",
          "postCode": [
            "57110"
          ]
        },
        {
          "id": 571503,
          "name": "Mae Salong Nok",
          "postCode": [
            "57110"
          ]
        },
        {
          "id": 571501,
          "name": "Thoet Thai",
          "postCode": [
            "57240"
          ]
        }
      ]
    },
    {
      "city": "Mae Lao",
      "cityCode": 5716,
      "provinceCode": 57,
      "subDistrict": [
        {
          "id": 571603,
          "name": "Bua Sali",
          "postCode": [
            "57250"
          ]
        },
        {
          "id": 571602,
          "name": "Chom Mok Kaeo",
          "postCode": [
            "57250"
          ]
        },
        {
          "id": 571601,
          "name": "Dong Mada",
          "postCode": [
            "57250"
          ]
        },
        {
          "id": 571604,
          "name": "Pa Ko Dam",
          "postCode": [
            "57250"
          ]
        },
        {
          "id": 571605,
          "name": "Pong Phrae",
          "postCode": [
            "57000"
          ]
        }
      ]
    },
    {
      "city": "Mae Sai",
      "cityCode": 5709,
      "provinceCode": 57,
      "subDistrict": [
        {
          "id": 570908,
          "name": "Ban Dai",
          "postCode": [
            "57220"
          ]
        },
        {
          "id": 570902,
          "name": "Huai Khrai",
          "postCode": [
            "57220"
          ]
        },
        {
          "id": 570903,
          "name": "Ko Chang",
          "postCode": [
            "57130"
          ]
        },
        {
          "id": 570901,
          "name": "Mae Sai",
          "postCode": [
            "57130"
          ]
        },
        {
          "id": 570909,
          "name": "Pong Ngam",
          "postCode": [
            "57130"
          ]
        },
        {
          "id": 570904,
          "name": "Pong Pha",
          "postCode": [
            "57130"
          ]
        },
        {
          "id": 570905,
          "name": "Si Mueang Chum",
          "postCode": [
            "57130"
          ]
        },
        {
          "id": 570906,
          "name": "Wiang Phang Kham",
          "postCode": [
            "57130"
          ]
        }
      ]
    },
    {
      "city": "Mae Suai",
      "cityCode": 5710,
      "provinceCode": 57,
      "subDistrict": [
        {
          "id": 571007,
          "name": "Chedi Luang",
          "postCode": [
            "57180"
          ]
        },
        {
          "id": 571003,
          "name": "Mae Phrik",
          "postCode": [
            "57180"
          ]
        },
        {
          "id": 571001,
          "name": "Mae Suai",
          "postCode": [
            "57180"
          ]
        },
        {
          "id": 571002,
          "name": "Pa Daet",
          "postCode": [
            "57180"
          ]
        },
        {
          "id": 571004,
          "name": "Si Thoi",
          "postCode": [
            "57180"
          ]
        },
        {
          "id": 571005,
          "name": "Tha Ko",
          "postCode": [
            "57180"
          ]
        },
        {
          "id": 571006,
          "name": "Wawi",
          "postCode": [
            "57180"
          ]
        }
      ]
    },
    {
      "city": "Mueang Chiang Rai",
      "cityCode": 5701,
      "provinceCode": 57,
      "subDistrict": [
        {
          "id": 570103,
          "name": "Ban Du",
          "postCode": [
            "57100"
          ]
        },
        {
          "id": 570120,
          "name": "Doi Hang",
          "postCode": [
            "57000"
          ]
        },
        {
          "id": 570115,
          "name": "Doi Lan",
          "postCode": [
            "57000"
          ]
        },
        {
          "id": 570112,
          "name": "Huai Chomphu",
          "postCode": [
            "57000"
          ]
        },
        {
          "id": 570113,
          "name": "Huai Sak",
          "postCode": [
            "57000"
          ]
        },
        {
          "id": 570105,
          "name": "Mae Khao Tom",
          "postCode": [
            "57100"
          ]
        },
        {
          "id": 570111,
          "name": "Mae Kon",
          "postCode": [
            "57000"
          ]
        },
        {
          "id": 570106,
          "name": "Mae Yao",
          "postCode": [
            "57100"
          ]
        },
        {
          "id": 570104,
          "name": "Nang Lae",
          "postCode": [
            "57100"
          ]
        },
        {
          "id": 570116,
          "name": "Pa O Don Chai",
          "postCode": [
            "57000"
          ]
        },
        {
          "id": 570114,
          "name": "Rim Kok",
          "postCode": [
            "57100"
          ]
        },
        {
          "id": 570102,
          "name": "Rop Wiang",
          "postCode": [
            "57000"
          ]
        },
        {
          "id": 570107,
          "name": "San Sai",
          "postCode": [
            "57000"
          ]
        },
        {
          "id": 570118,
          "name": "Tha Sai",
          "postCode": [
            "57000"
          ]
        },
        {
          "id": 570121,
          "name": "Tha Sut",
          "postCode": [
            "57100"
          ]
        },
        {
          "id": 570101,
          "name": "Wiang",
          "postCode": [
            "57000"
          ]
        }
      ]
    },
    {
      "city": "Pa Daet",
      "cityCode": 5706,
      "provinceCode": 57,
      "subDistrict": [
        {
          "id": 570601,
          "name": "Pa Daet",
          "postCode": [
            "57190"
          ]
        },
        {
          "id": 570602,
          "name": "Pa Ngae",
          "postCode": [
            "57190"
          ]
        },
        {
          "id": 570605,
          "name": "Rong Chang",
          "postCode": [
            "57190"
          ]
        },
        {
          "id": 570603,
          "name": "San Makha",
          "postCode": [
            "57190"
          ]
        },
        {
          "id": 570606,
          "name": "Si Pho Ngoen",
          "postCode": [
            "57190"
          ]
        }
      ]
    },
    {
      "city": "Phan",
      "cityCode": 5705,
      "provinceCode": 57,
      "subDistrict": [
        {
          "id": 570507,
          "name": "Charoen Mueang",
          "postCode": [
            "57120"
          ]
        },
        {
          "id": 570505,
          "name": "Doi Ngam",
          "postCode": [
            "57120"
          ]
        },
        {
          "id": 570506,
          "name": "Hua Ngom",
          "postCode": [
            "57120"
          ]
        },
        {
          "id": 570502,
          "name": "Mae O",
          "postCode": [
            "57120"
          ]
        },
        {
          "id": 570512,
          "name": "Mae Yen",
          "postCode": [
            "57280"
          ]
        },
        {
          "id": 570509,
          "name": "Muang Kham",
          "postCode": [
            "57120"
          ]
        },
        {
          "id": 570513,
          "name": "Mueang Phan",
          "postCode": [
            "57120"
          ]
        },
        {
          "id": 570508,
          "name": "Pa Hung",
          "postCode": [
            "57120"
          ]
        },
        {
          "id": 570510,
          "name": "Sai Khao",
          "postCode": [
            "57120"
          ]
        },
        {
          "id": 570511,
          "name": "San Klang",
          "postCode": [
            "57120"
          ]
        },
        {
          "id": 570501,
          "name": "San Makhet",
          "postCode": [
            "57120"
          ]
        },
        {
          "id": 570504,
          "name": "Santi Suk",
          "postCode": [
            "57120"
          ]
        },
        {
          "id": 570514,
          "name": "Than Tawan",
          "postCode": [
            "57280"
          ]
        },
        {
          "id": 570503,
          "name": "Than Thong",
          "postCode": [
            "57250"
          ]
        },
        {
          "id": 570515,
          "name": "Wiang Hao",
          "postCode": [
            "57120"
          ]
        }
      ]
    },
    {
      "city": "Phaya Mengrai",
      "cityCode": 5712,
      "provinceCode": 57,
      "subDistrict": [
        {
          "id": 571201,
          "name": "Mae Pao",
          "postCode": [
            "57290"
          ]
        },
        {
          "id": 571202,
          "name": "Mae Tam",
          "postCode": [
            "57290"
          ]
        },
        {
          "id": 571203,
          "name": "Mai Ya",
          "postCode": [
            "57290"
          ]
        },
        {
          "id": 571204,
          "name": "Mengrai",
          "postCode": [
            "57290"
          ]
        },
        {
          "id": 571205,
          "name": "Tat Khwan",
          "postCode": [
            "57290"
          ]
        }
      ]
    },
    {
      "city": "Thoeng",
      "cityCode": 5704,
      "provinceCode": 57,
      "subDistrict": [
        {
          "id": 570405,
          "name": "Chiang Khian",
          "postCode": [
            "57230"
          ]
        },
        {
          "id": 570404,
          "name": "Mae Loi",
          "postCode": [
            "57230"
          ]
        },
        {
          "id": 570410,
          "name": "Ngao",
          "postCode": [
            "57160"
          ]
        },
        {
          "id": 570402,
          "name": "Ngio",
          "postCode": [
            "57160",
            "57230"
          ]
        },
        {
          "id": 570413,
          "name": "Nong Raet",
          "postCode": [
            "57230"
          ]
        },
        {
          "id": 570403,
          "name": "Plong",
          "postCode": [
            "57230"
          ]
        },
        {
          "id": 570411,
          "name": "San Sai Ngam",
          "postCode": [
            "57160"
          ]
        },
        {
          "id": 570412,
          "name": "Si Don Chai",
          "postCode": [
            "57230"
          ]
        },
        {
          "id": 570409,
          "name": "Tap Tao",
          "postCode": [
            "57160"
          ]
        },
        {
          "id": 570401,
          "name": "Wiang",
          "postCode": [
            "57160"
          ]
        }
      ]
    },
    {
      "city": "Wiang Chai",
      "cityCode": 5702,
      "provinceCode": 57,
      "subDistrict": [
        {
          "id": 570206,
          "name": "Don Sila",
          "postCode": [
            "57210"
          ]
        },
        {
          "id": 570208,
          "name": "Mueang Chum",
          "postCode": [
            "57210"
          ]
        },
        {
          "id": 570203,
          "name": "Pha Ngam",
          "postCode": [
            "57210"
          ]
        },
        {
          "id": 570202,
          "name": "Wiang Chai",
          "postCode": [
            "57210"
          ]
        },
        {
          "id": 570204,
          "name": "Wiang Nuea",
          "postCode": [
            "57210"
          ]
        }
      ]
    },
    {
      "city": "Wiang Chiang Rung",
      "cityCode": 5717,
      "provinceCode": 57,
      "subDistrict": [
        {
          "id": 571702,
          "name": "Dong Maha Wan",
          "postCode": [
            "57210"
          ]
        },
        {
          "id": 571703,
          "name": "Pa Sang",
          "postCode": [
            "57210"
          ]
        },
        {
          "id": 571701,
          "name": "Thung Ko",
          "postCode": [
            "57210"
          ]
        }
      ]
    },
    {
      "city": "Wiang Kaen",
      "cityCode": 5713,
      "provinceCode": 57,
      "subDistrict": [
        {
          "id": 571303,
          "name": "Lai Ngao",
          "postCode": [
            "57310"
          ]
        },
        {
          "id": 571301,
          "name": "Muang Yai",
          "postCode": [
            "57310"
          ]
        },
        {
          "id": 571302,
          "name": "Po",
          "postCode": [
            "57310"
          ]
        },
        {
          "id": 571304,
          "name": "Tha Kham",
          "postCode": [
            "57310"
          ]
        }
      ]
    },
    {
      "city": "Wiang Pa Pao",
      "cityCode": 5711,
      "provinceCode": 57,
      "subDistrict": [
        {
          "id": 571103,
          "name": "Ban Pong",
          "postCode": [
            "57170"
          ]
        },
        {
          "id": 571106,
          "name": "Mae Chedi",
          "postCode": [
            "57260"
          ]
        },
        {
          "id": 571107,
          "name": "Mae Chedi Mai",
          "postCode": [
            "57260"
          ]
        },
        {
          "id": 571104,
          "name": "Pa Ngio",
          "postCode": [
            "57170"
          ]
        },
        {
          "id": 571101,
          "name": "San Sali",
          "postCode": [
            "57170"
          ]
        },
        {
          "id": 571102,
          "name": "Wiang",
          "postCode": [
            "57170"
          ]
        },
        {
          "id": 571105,
          "name": "Wiang Ka Long",
          "postCode": [
            "57260"
          ]
        }
      ]
    },
    {
      "city": "Ban Bueng",
      "cityCode": 2002,
      "provinceCode": 20,
      "subDistrict": [
        {
          "id": 200201,
          "name": "Ban Bueng",
          "postCode": [
            "20170"
          ]
        },
        {
          "id": 200202,
          "name": "Khlong Kio",
          "postCode": [
            "20220"
          ]
        },
        {
          "id": 200203,
          "name": "Map Phai",
          "postCode": [
            "20170"
          ]
        },
        {
          "id": 200205,
          "name": "Nong Bon Daeng",
          "postCode": [
            "20170"
          ]
        },
        {
          "id": 200206,
          "name": "Nong Chak",
          "postCode": [
            "20170"
          ]
        },
        {
          "id": 200207,
          "name": "Nong Irun",
          "postCode": [
            "20220"
          ]
        },
        {
          "id": 200208,
          "name": "Nong Phai Kaeo",
          "postCode": [
            "20220"
          ]
        },
        {
          "id": 200204,
          "name": "Nong Samsak",
          "postCode": [
            "20170"
          ]
        }
      ]
    },
    {
      "city": "Bang Lamung",
      "cityCode": 2004,
      "provinceCode": 20,
      "subDistrict": [
        {
          "id": 200401,
          "name": "Bang Lamung",
          "postCode": [
            "20150"
          ]
        },
        {
          "id": 200406,
          "name": "Huai Yai",
          "postCode": [
            "20150"
          ]
        },
        {
          "id": 200405,
          "name": "Khao Mai Kaeo",
          "postCode": [
            "20150"
          ]
        },
        {
          "id": 200408,
          "name": "Na Kluea",
          "postCode": [
            "20150"
          ]
        },
        {
          "id": 200403,
          "name": "Nong Pla Lai",
          "postCode": [
            "20150"
          ]
        },
        {
          "id": 200402,
          "name": "Nong Prue",
          "postCode": [
            "20150"
          ]
        },
        {
          "id": 200404,
          "name": "Pong",
          "postCode": [
            "20150"
          ]
        },
        {
          "id": 200407,
          "name": "Takhian Tia",
          "postCode": [
            "20150"
          ]
        }
      ]
    },
    {
      "city": "Bo Thong",
      "cityCode": 2010,
      "provinceCode": 20,
      "subDistrict": [
        {
          "id": 201003,
          "name": "Bo Kwang Thong",
          "postCode": [
            "20270"
          ]
        },
        {
          "id": 201001,
          "name": "Bo Thong",
          "postCode": [
            "20270"
          ]
        },
        {
          "id": 201005,
          "name": "Kaset Suwan",
          "postCode": [
            "20270"
          ]
        },
        {
          "id": 201006,
          "name": "Phluang Thong",
          "postCode": [
            "20270"
          ]
        },
        {
          "id": 201004,
          "name": "That Thong",
          "postCode": [
            "20270"
          ]
        },
        {
          "id": 201002,
          "name": "Wat Suwan",
          "postCode": [
            "20270"
          ]
        }
      ]
    },
    {
      "city": "Ko Chan",
      "cityCode": 2011,
      "provinceCode": 20,
      "subDistrict": [
        {
          "id": 201101,
          "name": "Ko Chan",
          "postCode": [
            "20240"
          ]
        },
        {
          "id": 201102,
          "name": "Tha Bun Mi",
          "postCode": [
            "20240"
          ]
        }
      ]
    },
    {
      "city": "Ko Sichang",
      "cityCode": 2008,
      "provinceCode": 20,
      "subDistrict": [
        {
          "id": 200801,
          "name": "Tha Thewawong",
          "postCode": [
            "20120"
          ]
        }
      ]
    },
    {
      "city": "Mueang Chon Buri",
      "cityCode": 2001,
      "provinceCode": 20,
      "subDistrict": [
        {
          "id": 200117,
          "name": "Ang Sila",
          "postCode": [
            "20000"
          ]
        },
        {
          "id": 200103,
          "name": "Ban Khot",
          "postCode": [
            "20000"
          ]
        },
        {
          "id": 200114,
          "name": "Ban Puek",
          "postCode": [
            "20130"
          ]
        },
        {
          "id": 200105,
          "name": "Ban Suan",
          "postCode": [
            "20000"
          ]
        },
        {
          "id": 200101,
          "name": "Bang Pla Soi",
          "postCode": [
            "20000"
          ]
        },
        {
          "id": 200111,
          "name": "Bang Sai",
          "postCode": [
            "20000"
          ]
        },
        {
          "id": 200109,
          "name": "Don Hua Lo",
          "postCode": [
            "20000"
          ]
        },
        {
          "id": 200115,
          "name": "Huai Kapi",
          "postCode": [
            "20000",
            "20130"
          ]
        },
        {
          "id": 200112,
          "name": "Khlong Tamru",
          "postCode": [
            "20000"
          ]
        },
        {
          "id": 200102,
          "name": "Makham Yong",
          "postCode": [
            "20000"
          ]
        },
        {
          "id": 200113,
          "name": "Mueang",
          "postCode": [
            "20130"
          ]
        },
        {
          "id": 200107,
          "name": "Na Pa",
          "postCode": [
            "20000"
          ]
        },
        {
          "id": 200108,
          "name": "Nong Khang Khok",
          "postCode": [
            "20000"
          ]
        },
        {
          "id": 200110,
          "name": "Nong Mai Daeng",
          "postCode": [
            "20000"
          ]
        },
        {
          "id": 200106,
          "name": "Nong Ri",
          "postCode": [
            "20000"
          ]
        },
        {
          "id": 200104,
          "name": "Saen Suk",
          "postCode": [
            "20130",
            "20131"
          ]
        },
        {
          "id": 200116,
          "name": "Samet",
          "postCode": [
            "20000",
            "20130"
          ]
        },
        {
          "id": 200118,
          "name": "Samnak Bok",
          "postCode": [
            "20000"
          ]
        }
      ]
    },
    {
      "city": "Nong Yai",
      "cityCode": 2003,
      "provinceCode": 20,
      "subDistrict": [
        {
          "id": 200304,
          "name": "Hang Sung",
          "postCode": [
            "20190"
          ]
        },
        {
          "id": 200305,
          "name": "Khao Sok",
          "postCode": [
            "20190"
          ]
        },
        {
          "id": 200302,
          "name": "Khlong Phlu",
          "postCode": [
            "20190"
          ]
        },
        {
          "id": 200303,
          "name": "Nong Suea Chang",
          "postCode": [
            "20190"
          ]
        },
        {
          "id": 200301,
          "name": "Nong Yai",
          "postCode": [
            "20190"
          ]
        }
      ]
    },
    {
      "city": "Phan Thong",
      "cityCode": 2005,
      "provinceCode": 20,
      "subDistrict": [
        {
          "id": 200507,
          "name": "Ban Kao",
          "postCode": [
            "20000",
            "20160"
          ]
        },
        {
          "id": 200511,
          "name": "Bang Hak",
          "postCode": [
            "20160"
          ]
        },
        {
          "id": 200509,
          "name": "Bang Nang",
          "postCode": [
            "20160"
          ]
        },
        {
          "id": 200506,
          "name": "Khok Khi Non",
          "postCode": [
            "20160"
          ]
        },
        {
          "id": 200510,
          "name": "Ko Loi",
          "postCode": [
            "20160"
          ]
        },
        {
          "id": 200503,
          "name": "Map Pong",
          "postCode": [
            "20160"
          ]
        },
        {
          "id": 200508,
          "name": "Na Pradu",
          "postCode": [
            "20160"
          ]
        },
        {
          "id": 200505,
          "name": "Nong Hong",
          "postCode": [
            "20160"
          ]
        },
        {
          "id": 200504,
          "name": "Nong Kakha",
          "postCode": [
            "20160"
          ]
        },
        {
          "id": 200502,
          "name": "Nong Tamlueng",
          "postCode": [
            "20160"
          ]
        },
        {
          "id": 200501,
          "name": "Phan Thong",
          "postCode": [
            "20160"
          ]
        }
      ]
    },
    {
      "city": "Phanat Nikhom",
      "cityCode": 2006,
      "provinceCode": 20,
      "subDistrict": [
        {
          "id": 200618,
          "name": "Ban Chang",
          "postCode": [
            "20140"
          ]
        },
        {
          "id": 200604,
          "name": "Ban Soet",
          "postCode": [
            "20140"
          ]
        },
        {
          "id": 200610,
          "name": "Hua Thanon",
          "postCode": [
            "20140"
          ]
        },
        {
          "id": 200620,
          "name": "Khok Phlo",
          "postCode": [
            "20140"
          ]
        },
        {
          "id": 200609,
          "name": "Kut Ngong",
          "postCode": [
            "20140"
          ]
        },
        {
          "id": 200606,
          "name": "Mon Nang",
          "postCode": [
            "20140"
          ]
        },
        {
          "id": 200622,
          "name": "Na Matum",
          "postCode": [
            "20140"
          ]
        },
        {
          "id": 200602,
          "name": "Na Phra That",
          "postCode": [
            "20140"
          ]
        },
        {
          "id": 200605,
          "name": "Na Roek",
          "postCode": [
            "20140",
            "20240"
          ]
        },
        {
          "id": 200617,
          "name": "Na Wang Hin",
          "postCode": [
            "20140",
            "20240"
          ]
        },
        {
          "id": 200616,
          "name": "Nong Hiang",
          "postCode": [
            "20140"
          ]
        },
        {
          "id": 200614,
          "name": "Nong Khayat",
          "postCode": [
            "20140"
          ]
        },
        {
          "id": 200613,
          "name": "Nong Prue",
          "postCode": [
            "20140"
          ]
        },
        {
          "id": 200601,
          "name": "Phanat Nikhom",
          "postCode": [
            "20140"
          ]
        },
        {
          "id": 200621,
          "name": "Rai Lak Thong",
          "postCode": [
            "20140"
          ]
        },
        {
          "id": 200607,
          "name": "Sa Si Liam",
          "postCode": [
            "20140"
          ]
        },
        {
          "id": 200611,
          "name": "Tha Kham",
          "postCode": [
            "20140"
          ]
        },
        {
          "id": 200615,
          "name": "Thung Khwang",
          "postCode": [
            "20140"
          ]
        },
        {
          "id": 200608,
          "name": "Wat Bot",
          "postCode": [
            "20140"
          ]
        },
        {
          "id": 200603,
          "name": "Wat Luang",
          "postCode": [
            "20140"
          ]
        }
      ]
    },
    {
      "city": "Sattahip",
      "cityCode": 2009,
      "provinceCode": 20,
      "subDistrict": [
        {
          "id": 200904,
          "name": "Bang Sare",
          "postCode": [
            "20250",
            "20251"
          ]
        },
        {
          "id": 200902,
          "name": "Na Chom Thian",
          "postCode": [
            "20250"
          ]
        },
        {
          "id": 200903,
          "name": "Phlu Ta Luang",
          "postCode": [
            "20180"
          ]
        },
        {
          "id": 200905,
          "name": "Samaesan",
          "postCode": [
            "20180"
          ]
        },
        {
          "id": 200901,
          "name": "Sattahip",
          "postCode": [
            "20180",
            "20182"
          ]
        }
      ]
    },
    {
      "city": "Si Racha",
      "cityCode": 2007,
      "provinceCode": 20,
      "subDistrict": [
        {
          "id": 200707,
          "name": "Bang Phra",
          "postCode": [
            "20110"
          ]
        },
        {
          "id": 200708,
          "name": "Bo Win",
          "postCode": [
            "20230"
          ]
        },
        {
          "id": 200704,
          "name": "Bueng",
          "postCode": [
            "20230"
          ]
        },
        {
          "id": 200706,
          "name": "Khao Khansong",
          "postCode": [
            "20110"
          ]
        },
        {
          "id": 200705,
          "name": "Nong Kham",
          "postCode": [
            "20110",
            "20230"
          ]
        },
        {
          "id": 200701,
          "name": "Si Racha",
          "postCode": [
            "20110"
          ]
        },
        {
          "id": 200702,
          "name": "Surasak",
          "postCode": [
            "20110"
          ]
        },
        {
          "id": 200703,
          "name": "Thung Sukhla",
          "postCode": [
            "20230"
          ]
        }
      ]
    },
    {
      "city": "Lamae",
      "cityCode": 8605,
      "provinceCode": 86,
      "subDistrict": [
        {
          "id": 860501,
          "name": "Lamae",
          "postCode": [
            "86170"
          ]
        },
        {
          "id": 860503,
          "name": "Suan Taeng",
          "postCode": [
            "86170"
          ]
        },
        {
          "id": 860504,
          "name": "Thung Kha Wat",
          "postCode": [
            "86170"
          ]
        },
        {
          "id": 860502,
          "name": "Thung Luang",
          "postCode": [
            "86170"
          ]
        }
      ]
    },
    {
      "city": "Lang Suan",
      "cityCode": 8604,
      "provinceCode": 86,
      "subDistrict": [
        {
          "id": 860406,
          "name": "Ban Khuan",
          "postCode": [
            "86110"
          ]
        },
        {
          "id": 860407,
          "name": "Bang Maphrao",
          "postCode": [
            "86110",
            "86150"
          ]
        },
        {
          "id": 860408,
          "name": "Bang Nam Chuet",
          "postCode": [
            "86150"
          ]
        },
        {
          "id": 860413,
          "name": "Hat yai",
          "postCode": [
            "86110"
          ]
        },
        {
          "id": 860402,
          "name": "Khan Ngoen",
          "postCode": [
            "86110"
          ]
        },
        {
          "id": 860411,
          "name": "Laem Sai",
          "postCode": [
            "86110"
          ]
        },
        {
          "id": 860401,
          "name": "Lang Suan",
          "postCode": [
            "86110"
          ]
        },
        {
          "id": 860404,
          "name": "Na Kha",
          "postCode": [
            "86110"
          ]
        },
        {
          "id": 860405,
          "name": "Na Phaya",
          "postCode": [
            "86110"
          ]
        },
        {
          "id": 860409,
          "name": "Pak Nam",
          "postCode": [
            "86150"
          ]
        },
        {
          "id": 860410,
          "name": "Pho Daeng",
          "postCode": [
            "86110"
          ]
        },
        {
          "id": 860403,
          "name": "Tha Maphla",
          "postCode": [
            "86110"
          ]
        },
        {
          "id": 860412,
          "name": "Wang Tako",
          "postCode": [
            "86110"
          ]
        }
      ]
    },
    {
      "city": "Mueang Chumphon",
      "cityCode": 8601,
      "provinceCode": 86,
      "subDistrict": [
        {
          "id": 860112,
          "name": "Ban Na",
          "postCode": [
            "86190"
          ]
        },
        {
          "id": 860108,
          "name": "Bang Luek",
          "postCode": [
            "86000"
          ]
        },
        {
          "id": 860104,
          "name": "Bang Mak",
          "postCode": [
            "86000"
          ]
        },
        {
          "id": 860109,
          "name": "Hat Phan Krai",
          "postCode": [
            "86000"
          ]
        },
        {
          "id": 860116,
          "name": "Hat Sai Ri",
          "postCode": [
            "86120"
          ]
        },
        {
          "id": 860113,
          "name": "Khun Krathing",
          "postCode": [
            "86000",
            "86190"
          ]
        },
        {
          "id": 860106,
          "name": "Na Cha-ang",
          "postCode": [
            "86000"
          ]
        },
        {
          "id": 860105,
          "name": "Na Thung",
          "postCode": [
            "86000"
          ]
        },
        {
          "id": 860102,
          "name": "Pak Nam",
          "postCode": [
            "86120"
          ]
        },
        {
          "id": 860107,
          "name": "Tak Daet",
          "postCode": [
            "86000",
            "86190"
          ]
        },
        {
          "id": 860101,
          "name": "Tha Taphao",
          "postCode": [
            "86000"
          ]
        },
        {
          "id": 860103,
          "name": "Tha Yang",
          "postCode": [
            "86000",
            "86120"
          ]
        },
        {
          "id": 860117,
          "name": "Tham Sing",
          "postCode": [
            "86100"
          ]
        },
        {
          "id": 860114,
          "name": "Thung Kha",
          "postCode": [
            "86100"
          ]
        },
        {
          "id": 860111,
          "name": "Wang Mai",
          "postCode": [
            "86190"
          ]
        },
        {
          "id": 860110,
          "name": "Wang Phai",
          "postCode": [
            "86000",
            "86190"
          ]
        },
        {
          "id": 860115,
          "name": "Wisai Nuea",
          "postCode": [
            "86100"
          ]
        }
      ]
    },
    {
      "city": "Pathio",
      "cityCode": 8603,
      "provinceCode": 86,
      "subDistrict": [
        {
          "id": 860301,
          "name": "Bang Son",
          "postCode": [
            "86160"
          ]
        },
        {
          "id": 860304,
          "name": "Chum Kho",
          "postCode": [
            "86160"
          ]
        },
        {
          "id": 860305,
          "name": "Don Yang",
          "postCode": [
            "86210"
          ]
        },
        {
          "id": 860307,
          "name": "Khao Chai Rat",
          "postCode": [
            "86210"
          ]
        },
        {
          "id": 860306,
          "name": "Pak Khlong",
          "postCode": [
            "86210"
          ]
        },
        {
          "id": 860303,
          "name": "Saphli",
          "postCode": [
            "86230"
          ]
        },
        {
          "id": 860302,
          "name": "Thale Sap",
          "postCode": [
            "86160"
          ]
        }
      ]
    },
    {
      "city": "Phato",
      "cityCode": 8606,
      "provinceCode": 86,
      "subDistrict": [
        {
          "id": 860602,
          "name": "Pak Song",
          "postCode": [
            "86180"
          ]
        },
        {
          "id": 860603,
          "name": "Pang Wan",
          "postCode": [
            "86180"
          ]
        },
        {
          "id": 860601,
          "name": "Phato",
          "postCode": [
            "86180"
          ]
        },
        {
          "id": 860604,
          "name": "Phra Rak",
          "postCode": [
            "86180"
          ]
        }
      ]
    },
    {
      "city": "Sawi",
      "cityCode": 8607,
      "provinceCode": 86,
      "subDistrict": [
        {
          "id": 860706,
          "name": "Dan Sawi",
          "postCode": [
            "86130"
          ]
        },
        {
          "id": 860711,
          "name": "Khao Khai",
          "postCode": [
            "86130"
          ]
        },
        {
          "id": 860710,
          "name": "Khao Thalu",
          "postCode": [
            "86130"
          ]
        },
        {
          "id": 860707,
          "name": "Khron",
          "postCode": [
            "86130"
          ]
        },
        {
          "id": 860701,
          "name": "Na Pho",
          "postCode": [
            "86130"
          ]
        },
        {
          "id": 860709,
          "name": "Na Sak",
          "postCode": [
            "86130"
          ]
        },
        {
          "id": 860705,
          "name": "Pak Phraek",
          "postCode": [
            "86130"
          ]
        },
        {
          "id": 860702,
          "name": "Sawi",
          "postCode": [
            "86130"
          ]
        },
        {
          "id": 860704,
          "name": "Tha Hin",
          "postCode": [
            "86130"
          ]
        },
        {
          "id": 860703,
          "name": "Thung Raya",
          "postCode": [
            "86130"
          ]
        },
        {
          "id": 860708,
          "name": "Wisai Tai",
          "postCode": [
            "86130"
          ]
        }
      ]
    },
    {
      "city": "Tha Sae",
      "cityCode": 8602,
      "provinceCode": 86,
      "subDistrict": [
        {
          "id": 860208,
          "name": "Hin Kaeo",
          "postCode": [
            "86190"
          ]
        },
        {
          "id": 860207,
          "name": "Hong Charoen",
          "postCode": [
            "86140"
          ]
        },
        {
          "id": 860202,
          "name": "Khuring",
          "postCode": [
            "86140"
          ]
        },
        {
          "id": 860204,
          "name": "Na Kratam",
          "postCode": [
            "86140"
          ]
        },
        {
          "id": 860205,
          "name": "Rap Ro",
          "postCode": [
            "86140",
            "86190"
          ]
        },
        {
          "id": 860203,
          "name": "Salui",
          "postCode": [
            "86140"
          ]
        },
        {
          "id": 860209,
          "name": "Sap Anan",
          "postCode": [
            "86140"
          ]
        },
        {
          "id": 860210,
          "name": "Song Phi Nong",
          "postCode": [
            "86140"
          ]
        },
        {
          "id": 860206,
          "name": "Tha Kham",
          "postCode": [
            "86140"
          ]
        },
        {
          "id": 860201,
          "name": "Tha Sae",
          "postCode": [
            "86140"
          ]
        }
      ]
    },
    {
      "city": "Thung Tako",
      "cityCode": 8608,
      "provinceCode": 86,
      "subDistrict": [
        {
          "id": 860804,
          "name": "Chong Mai Kaeo",
          "postCode": [
            "86220"
          ]
        },
        {
          "id": 860801,
          "name": "Pak Tako",
          "postCode": [
            "86220"
          ]
        },
        {
          "id": 860803,
          "name": "Tako",
          "postCode": [
            "86220"
          ]
        },
        {
          "id": 860802,
          "name": "Thung Takhrai",
          "postCode": [
            "86220"
          ]
        }
      ]
    },
    {
      "city": "Don Chan",
      "cityCode": 4617,
      "provinceCode": 46,
      "subDistrict": [
        {
          "id": 461701,
          "name": "Don Chan",
          "postCode": [
            "46000"
          ]
        },
        {
          "id": 461703,
          "name": "Dong Phayung",
          "postCode": [
            "46000"
          ]
        },
        {
          "id": 461704,
          "name": "Muang Na",
          "postCode": [
            "46000"
          ]
        },
        {
          "id": 461705,
          "name": "Na Champa",
          "postCode": [
            "46000"
          ]
        },
        {
          "id": 461702,
          "name": "Sa-at Chai Si",
          "postCode": [
            "46000"
          ]
        }
      ]
    },
    {
      "city": "Huai Mek",
      "cityCode": 4608,
      "provinceCode": 46,
      "subDistrict": [
        {
          "id": 460804,
          "name": "Bueng Na Riang",
          "postCode": [
            "46170"
          ]
        },
        {
          "id": 460805,
          "name": "Hua Hin",
          "postCode": [
            "46170"
          ]
        },
        {
          "id": 460801,
          "name": "Huai Mek",
          "postCode": [
            "46170"
          ]
        },
        {
          "id": 460807,
          "name": "Kham Mueat Kaeo",
          "postCode": [
            "46170"
          ]
        },
        {
          "id": 460802,
          "name": "Kham Yai",
          "postCode": [
            "46170"
          ]
        },
        {
          "id": 460803,
          "name": "Kut Don",
          "postCode": [
            "46170"
          ]
        },
        {
          "id": 460808,
          "name": "Non Sa-at",
          "postCode": [
            "46170"
          ]
        },
        {
          "id": 460806,
          "name": "Phimun",
          "postCode": [
            "46170"
          ]
        },
        {
          "id": 460809,
          "name": "Sai Thong",
          "postCode": [
            "46170"
          ]
        }
      ]
    },
    {
      "city": "Huai Phueng",
      "cityCode": 4614,
      "provinceCode": 46,
      "subDistrict": [
        {
          "id": 461402,
          "name": "Khai Nun",
          "postCode": [
            "46240"
          ]
        },
        {
          "id": 461401,
          "name": "Kham Bong",
          "postCode": [
            "46240"
          ]
        },
        {
          "id": 461403,
          "name": "Nikhom Huai Phueng",
          "postCode": [
            "46240"
          ]
        },
        {
          "id": 461404,
          "name": "Nong I But",
          "postCode": [
            "46240"
          ]
        }
      ]
    },
    {
      "city": "Kamalasai",
      "cityCode": 4603,
      "provinceCode": 46,
      "subDistrict": [
        {
          "id": 460310,
          "name": "Chao Tha",
          "postCode": [
            "46130"
          ]
        },
        {
          "id": 460304,
          "name": "Dong Ling",
          "postCode": [
            "46130"
          ]
        },
        {
          "id": 460301,
          "name": "Kamalasai",
          "postCode": [
            "46130"
          ]
        },
        {
          "id": 460311,
          "name": "Khok Sombun",
          "postCode": [
            "46130"
          ]
        },
        {
          "id": 460302,
          "name": "Lak Mueang",
          "postCode": [
            "46130"
          ]
        },
        {
          "id": 460308,
          "name": "Nong Paen",
          "postCode": [
            "46130"
          ]
        },
        {
          "id": 460303,
          "name": "Phon Ngam",
          "postCode": [
            "46130"
          ]
        },
        {
          "id": 460305,
          "name": "Thanya",
          "postCode": [
            "46130"
          ]
        }
      ]
    },
    {
      "city": "Kham Muang",
      "cityCode": 4610,
      "provinceCode": 46,
      "subDistrict": [
        {
          "id": 461005,
          "name": "Din Chi",
          "postCode": [
            "46180"
          ]
        },
        {
          "id": 461006,
          "name": "Na Bon",
          "postCode": [
            "46180"
          ]
        },
        {
          "id": 461007,
          "name": "Na Than",
          "postCode": [
            "46180"
          ]
        },
        {
          "id": 461009,
          "name": "Noen Yang",
          "postCode": [
            "46180"
          ]
        },
        {
          "id": 461002,
          "name": "Phon",
          "postCode": [
            "46180"
          ]
        },
        {
          "id": 461001,
          "name": "Thung Khlong",
          "postCode": [
            "46180"
          ]
        }
      ]
    },
    {
      "city": "Khao Wong",
      "cityCode": 4606,
      "provinceCode": 46,
      "subDistrict": [
        {
          "id": 460601,
          "name": "Khum Kao",
          "postCode": [
            "46160"
          ]
        },
        {
          "id": 460611,
          "name": "Kut Pla Khao",
          "postCode": [
            "46160"
          ]
        },
        {
          "id": 460606,
          "name": "Kut Sim Khum Mai",
          "postCode": [
            "46160"
          ]
        },
        {
          "id": 460603,
          "name": "Nong Phue",
          "postCode": [
            "46160"
          ]
        },
        {
          "id": 460608,
          "name": "Sa Phang Thong",
          "postCode": [
            "46160"
          ]
        },
        {
          "id": 460602,
          "name": "Song Plueai",
          "postCode": [
            "46160"
          ]
        }
      ]
    },
    {
      "city": "Khong Chai",
      "cityCode": 4618,
      "provinceCode": 46,
      "subDistrict": [
        {
          "id": 461803,
          "name": "Khok Sa-at",
          "postCode": [
            "46130"
          ]
        },
        {
          "id": 461801,
          "name": "Khong Chai Phatthana",
          "postCode": [
            "46130"
          ]
        },
        {
          "id": 461805,
          "name": "Lam Chi",
          "postCode": [
            "46130"
          ]
        },
        {
          "id": 461802,
          "name": "Lao Klang",
          "postCode": [
            "46130"
          ]
        },
        {
          "id": 461804,
          "name": "Non Sila Loeng",
          "postCode": [
            "46130"
          ]
        }
      ]
    },
    {
      "city": "Kuchinarai",
      "cityCode": 4605,
      "provinceCode": 46,
      "subDistrict": [
        {
          "id": 460501,
          "name": "Bua Khao",
          "postCode": [
            "46110"
          ]
        },
        {
          "id": 460502,
          "name": "Chaen Laen",
          "postCode": [
            "46110"
          ]
        },
        {
          "id": 460504,
          "name": "Chum Chang",
          "postCode": [
            "46110"
          ]
        },
        {
          "id": 460512,
          "name": "Kut Khao",
          "postCode": [
            "46110"
          ]
        },
        {
          "id": 460506,
          "name": "Kut Wa",
          "postCode": [
            "46110"
          ]
        },
        {
          "id": 460505,
          "name": "Lao Hai Ngam",
          "postCode": [
            "46110"
          ]
        },
        {
          "id": 460503,
          "name": "Lao Yai",
          "postCode": [
            "46110"
          ]
        },
        {
          "id": 460508,
          "name": "Na Kham",
          "postCode": [
            "46110"
          ]
        },
        {
          "id": 460510,
          "name": "Na Ko",
          "postCode": [
            "46110"
          ]
        },
        {
          "id": 460509,
          "name": "Nong Hang",
          "postCode": [
            "46110"
          ]
        },
        {
          "id": 460507,
          "name": "Sam Kha",
          "postCode": [
            "46110"
          ]
        },
        {
          "id": 460511,
          "name": "Som Sa-at",
          "postCode": [
            "46110"
          ]
        }
      ]
    },
    {
      "city": "Mueang Kalasin",
      "cityCode": 4601,
      "provinceCode": 46,
      "subDistrict": [
        {
          "id": 460108,
          "name": "Bueng Wichai",
          "postCode": [
            "46000"
          ]
        },
        {
          "id": 460107,
          "name": "Chiang Khruea",
          "postCode": [
            "46000"
          ]
        },
        {
          "id": 460109,
          "name": "Huai Pho",
          "postCode": [
            "46000"
          ]
        },
        {
          "id": 460101,
          "name": "Kalasin",
          "postCode": [
            "46000"
          ]
        },
        {
          "id": 460117,
          "name": "Khamin",
          "postCode": [
            "46000"
          ]
        },
        {
          "id": 460116,
          "name": "Klang Muen",
          "postCode": [
            "46000"
          ]
        },
        {
          "id": 460121,
          "name": "Lam Khlong",
          "postCode": [
            "46000"
          ]
        },
        {
          "id": 460105,
          "name": "Lam Pao",
          "postCode": [
            "46000"
          ]
        },
        {
          "id": 460106,
          "name": "Lam Phan",
          "postCode": [
            "46000"
          ]
        },
        {
          "id": 460103,
          "name": "Lup",
          "postCode": [
            "46000"
          ]
        },
        {
          "id": 460120,
          "name": "Na Chan",
          "postCode": [
            "46000"
          ]
        },
        {
          "id": 460115,
          "name": "Nong Kung",
          "postCode": [
            "46000"
          ]
        },
        {
          "id": 460102,
          "name": "Nuea",
          "postCode": [
            "46000"
          ]
        },
        {
          "id": 460104,
          "name": "Phai",
          "postCode": [
            "46000"
          ]
        },
        {
          "id": 460119,
          "name": "Phon Thong",
          "postCode": [
            "46000"
          ]
        },
        {
          "id": 460113,
          "name": "Phu Din",
          "postCode": [
            "46000"
          ]
        },
        {
          "id": 460111,
          "name": "Phu Po",
          "postCode": [
            "46000"
          ]
        }
      ]
    },
    {
      "city": "Na Khu",
      "cityCode": 4616,
      "provinceCode": 46,
      "subDistrict": [
        {
          "id": 461604,
          "name": "Bo Kaeo",
          "postCode": [
            "46160"
          ]
        },
        {
          "id": 461601,
          "name": "Na Khu",
          "postCode": [
            "46160"
          ]
        },
        {
          "id": 461603,
          "name": "Non Na Chan",
          "postCode": [
            "46160"
          ]
        },
        {
          "id": 461605,
          "name": "Phu Laen Chang",
          "postCode": [
            "46160"
          ]
        },
        {
          "id": 461602,
          "name": "Sai Na Wang",
          "postCode": [
            "46160"
          ]
        }
      ]
    },
    {
      "city": "Na Mon",
      "cityCode": 4602,
      "provinceCode": 46,
      "subDistrict": [
        {
          "id": 460204,
          "name": "Lak Liam",
          "postCode": [
            "46230"
          ]
        },
        {
          "id": 460201,
          "name": "Na Mon",
          "postCode": [
            "46230"
          ]
        },
        {
          "id": 460205,
          "name": "Nong Bua",
          "postCode": [
            "46230"
          ]
        },
        {
          "id": 460203,
          "name": "Song Plueai",
          "postCode": [
            "46230"
          ]
        },
        {
          "id": 460202,
          "name": "Yot Kaeng",
          "postCode": [
            "46230"
          ]
        }
      ]
    },
    {
      "city": "Nong Kung Si",
      "cityCode": 4612,
      "provinceCode": 46,
      "subDistrict": [
        {
          "id": 461207,
          "name": "Dong Mun",
          "postCode": [
            "46220"
          ]
        },
        {
          "id": 461203,
          "name": "Khok Khruea",
          "postCode": [
            "46220"
          ]
        },
        {
          "id": 461208,
          "name": "Lam Nong Saen",
          "postCode": [
            "46220"
          ]
        },
        {
          "id": 461202,
          "name": "Nong Bua",
          "postCode": [
            "46220"
          ]
        },
        {
          "id": 461209,
          "name": "Nong Hin",
          "postCode": [
            "46220"
          ]
        },
        {
          "id": 461201,
          "name": "Nong Kung Si",
          "postCode": [
            "46220"
          ]
        },
        {
          "id": 461204,
          "name": "Nong Suang",
          "postCode": [
            "46220"
          ]
        },
        {
          "id": 461206,
          "name": "Nong Yai",
          "postCode": [
            "46220"
          ]
        },
        {
          "id": 461205,
          "name": "Sao Lao",
          "postCode": [
            "46220"
          ]
        }
      ]
    },
    {
      "city": "Rong Kham",
      "cityCode": 4604,
      "provinceCode": 46,
      "subDistrict": [
        {
          "id": 460403,
          "name": "Lao Oi",
          "postCode": [
            "46210"
          ]
        },
        {
          "id": 460401,
          "name": "Rong Kham",
          "postCode": [
            "46210"
          ]
        },
        {
          "id": 460402,
          "name": "Samakkhi",
          "postCode": [
            "46210"
          ]
        }
      ]
    },
    {
      "city": "Sahatsakhan",
      "cityCode": 4609,
      "provinceCode": 46,
      "subDistrict": [
        {
          "id": 460903,
          "name": "Na Makhuea",
          "postCode": [
            "46140"
          ]
        },
        {
          "id": 460905,
          "name": "Nikhom",
          "postCode": [
            "46140"
          ]
        },
        {
          "id": 460907,
          "name": "Non Buri",
          "postCode": [
            "46140"
          ]
        },
        {
          "id": 460906,
          "name": "Non Laem Thong",
          "postCode": [
            "46140"
          ]
        },
        {
          "id": 460908,
          "name": "Non Nam Kliang",
          "postCode": [
            "46140"
          ]
        },
        {
          "id": 460904,
          "name": "Non Sila",
          "postCode": [
            "46140"
          ]
        },
        {
          "id": 460901,
          "name": "Phu Sing",
          "postCode": [
            "46140"
          ]
        },
        {
          "id": 460902,
          "name": "Sahatsakhan",
          "postCode": [
            "46140"
          ]
        }
      ]
    },
    {
      "city": "Sam Chai",
      "cityCode": 4615,
      "provinceCode": 46,
      "subDistrict": [
        {
          "id": 461503,
          "name": "Kham Sang Thiang",
          "postCode": [
            "46180"
          ]
        },
        {
          "id": 461504,
          "name": "Nong Chang",
          "postCode": [
            "46180"
          ]
        },
        {
          "id": 461501,
          "name": "Samran",
          "postCode": [
            "46180"
          ]
        },
        {
          "id": 461502,
          "name": "Samran Tai",
          "postCode": [
            "46180"
          ]
        }
      ]
    },
    {
      "city": "Somdet",
      "cityCode": 4613,
      "provinceCode": 46,
      "subDistrict": [
        {
          "id": 461308,
          "name": "Lam Huai Lua",
          "postCode": [
            "46150"
          ]
        },
        {
          "id": 461304,
          "name": "Maha Chai",
          "postCode": [
            "46150"
          ]
        },
        {
          "id": 461305,
          "name": "Mu Mon",
          "postCode": [
            "46150"
          ]
        },
        {
          "id": 461302,
          "name": "Nong Waeng",
          "postCode": [
            "46150"
          ]
        },
        {
          "id": 461306,
          "name": "Pha Sawoei",
          "postCode": [
            "46150"
          ]
        },
        {
          "id": 461303,
          "name": "Saeng Badan",
          "postCode": [
            "46150"
          ]
        },
        {
          "id": 461307,
          "name": "Si Somdet",
          "postCode": [
            "46150"
          ]
        },
        {
          "id": 461301,
          "name": "Somdet",
          "postCode": [
            "46150"
          ]
        }
      ]
    },
    {
      "city": "Tha Khantho",
      "cityCode": 4611,
      "provinceCode": 46,
      "subDistrict": [
        {
          "id": 461106,
          "name": "Dong Sombun",
          "postCode": [
            "46190"
          ]
        },
        {
          "id": 461102,
          "name": "Kung Kao",
          "postCode": [
            "46190"
          ]
        },
        {
          "id": 461104,
          "name": "Kut Chik",
          "postCode": [
            "46190"
          ]
        },
        {
          "id": 461105,
          "name": "Na Tan",
          "postCode": [
            "46190"
          ]
        },
        {
          "id": 461101,
          "name": "Tha Khantho",
          "postCode": [
            "46190"
          ]
        },
        {
          "id": 461103,
          "name": "Yang Um",
          "postCode": [
            "46190"
          ]
        }
      ]
    },
    {
      "city": "Yang Talat",
      "cityCode": 4607,
      "provinceCode": 46,
      "subDistrict": [
        {
          "id": 460704,
          "name": "Bua Ban",
          "postCode": [
            "46120"
          ]
        },
        {
          "id": 460709,
          "name": "Don Sombun",
          "postCode": [
            "46120"
          ]
        },
        {
          "id": 460707,
          "name": "Hua Na Kham",
          "postCode": [
            "46120"
          ]
        },
        {
          "id": 460702,
          "name": "Hua Ngua",
          "postCode": [
            "46120"
          ]
        },
        {
          "id": 460706,
          "name": "Itue",
          "postCode": [
            "46120"
          ]
        },
        {
          "id": 460712,
          "name": "Khao Phra Non",
          "postCode": [
            "46120"
          ]
        },
        {
          "id": 460711,
          "name": "Khlong Kham",
          "postCode": [
            "46120"
          ]
        },
        {
          "id": 460710,
          "name": "Na Chueak",
          "postCode": [
            "46120"
          ]
        },
        {
          "id": 460713,
          "name": "Na Di",
          "postCode": [
            "46120"
          ]
        },
        {
          "id": 460714,
          "name": "Non Sung",
          "postCode": [
            "46120"
          ]
        },
        {
          "id": 460708,
          "name": "Nong I Thao",
          "postCode": [
            "46120"
          ]
        },
        {
          "id": 460715,
          "name": "Nong Tok Paen",
          "postCode": [
            "46120"
          ]
        },
        {
          "id": 460703,
          "name": "Um Mao",
          "postCode": [
            "46120"
          ]
        },
        {
          "id": 460705,
          "name": "Woe",
          "postCode": [
            "46120"
          ]
        },
        {
          "id": 460701,
          "name": "Yang Talat",
          "postCode": [
            "46120"
          ]
        }
      ]
    },
    {
      "city": "Bueng Samakkhi",
      "cityCode": 6210,
      "provinceCode": 62,
      "subDistrict": [
        {
          "id": 621001,
          "name": "Bueng Samakkhi",
          "postCode": [
            "62210"
          ]
        },
        {
          "id": 621003,
          "name": "Rahan",
          "postCode": [
            "62210"
          ]
        },
        {
          "id": 621004,
          "name": "Thep Nimit",
          "postCode": [
            "62210"
          ]
        },
        {
          "id": 621002,
          "name": "Wang Cha-on",
          "postCode": [
            "62210"
          ]
        }
      ]
    },
    {
      "city": "Khanu Woralaksaburi",
      "cityCode": 6204,
      "provinceCode": 62,
      "subDistrict": [
        {
          "id": 620407,
          "name": "Bo Tham",
          "postCode": [
            "62140"
          ]
        },
        {
          "id": 620408,
          "name": "Don Taeng",
          "postCode": [
            "62140"
          ]
        },
        {
          "id": 620410,
          "name": "Khong Phai",
          "postCode": [
            "62140"
          ]
        },
        {
          "id": 620413,
          "name": "Ko Tan",
          "postCode": [
            "62130"
          ]
        },
        {
          "id": 620404,
          "name": "Pa Phutsa",
          "postCode": [
            "62130"
          ]
        },
        {
          "id": 620411,
          "name": "Pang Makha",
          "postCode": [
            "62140"
          ]
        },
        {
          "id": 620405,
          "name": "Saen To",
          "postCode": [
            "62130"
          ]
        },
        {
          "id": 620406,
          "name": "Salokbat",
          "postCode": [
            "62140"
          ]
        },
        {
          "id": 620409,
          "name": "Wang Chaphlu",
          "postCode": [
            "62140"
          ]
        },
        {
          "id": 620412,
          "name": "Wang Ham Hae",
          "postCode": [
            "62140"
          ]
        },
        {
          "id": 620403,
          "name": "Yang Sung",
          "postCode": [
            "62130"
          ]
        }
      ]
    },
    {
      "city": "Khlong Khlung",
      "cityCode": 6205,
      "provinceCode": 62,
      "subDistrict": [
        {
          "id": 620508,
          "name": "Hua Thanon",
          "postCode": [
            "62120"
          ]
        },
        {
          "id": 620501,
          "name": "Khlong Khlung",
          "postCode": [
            "62120"
          ]
        },
        {
          "id": 620516,
          "name": "Khlong Sombun",
          "postCode": [
            "62120"
          ]
        },
        {
          "id": 620505,
          "name": "Mae Lat",
          "postCode": [
            "62120"
          ]
        },
        {
          "id": 620502,
          "name": "Tha Makhuea",
          "postCode": [
            "62120"
          ]
        },
        {
          "id": 620504,
          "name": "Tha Phutsa",
          "postCode": [
            "62120"
          ]
        },
        {
          "id": 620513,
          "name": "Wang Bua",
          "postCode": [
            "62120"
          ]
        },
        {
          "id": 620507,
          "name": "Wang Khaem",
          "postCode": [
            "62120"
          ]
        },
        {
          "id": 620509,
          "name": "Wang Sai",
          "postCode": [
            "62120"
          ]
        },
        {
          "id": 620506,
          "name": "Wang Yang",
          "postCode": [
            "62120"
          ]
        }
      ]
    },
    {
      "city": "Khlong Lan",
      "cityCode": 6203,
      "provinceCode": 62,
      "subDistrict": [
        {
          "id": 620303,
          "name": "Khlong Lan Phatthana",
          "postCode": [
            "62180"
          ]
        },
        {
          "id": 620301,
          "name": "Khlong Nam Lai",
          "postCode": [
            "62180"
          ]
        },
        {
          "id": 620302,
          "name": "Pong Nam Ron",
          "postCode": [
            "62180"
          ]
        },
        {
          "id": 620304,
          "name": "Sak Ngam",
          "postCode": [
            "62180"
          ]
        }
      ]
    },
    {
      "city": "Kosamphi Nakhon",
      "cityCode": 6211,
      "provinceCode": 62,
      "subDistrict": [
        {
          "id": 621101,
          "name": "Kosamphi",
          "postCode": [
            "62000"
          ]
        },
        {
          "id": 621103,
          "name": "Lan Dokmai Tok",
          "postCode": [
            "62000"
          ]
        },
        {
          "id": 621102,
          "name": "Phet Chomphu",
          "postCode": [
            "62000"
          ]
        }
      ]
    },
    {
      "city": "Lan Krabue",
      "cityCode": 6207,
      "provinceCode": 62,
      "subDistrict": [
        {
          "id": 620706,
          "name": "Bueng Thap Raet",
          "postCode": [
            "62170"
          ]
        },
        {
          "id": 620707,
          "name": "Chanthima",
          "postCode": [
            "62170"
          ]
        },
        {
          "id": 620702,
          "name": "Chong Lom",
          "postCode": [
            "62170"
          ]
        },
        {
          "id": 620701,
          "name": "Lan Krabue",
          "postCode": [
            "62170"
          ]
        },
        {
          "id": 620704,
          "name": "Non Phluang",
          "postCode": [
            "62170"
          ]
        },
        {
          "id": 620703,
          "name": "Nong Luang",
          "postCode": [
            "62170"
          ]
        },
        {
          "id": 620705,
          "name": "Pracha Suk San",
          "postCode": [
            "62170"
          ]
        }
      ]
    },
    {
      "city": "Mueang Kamphaeng Phet",
      "cityCode": 6201,
      "provinceCode": 62,
      "subDistrict": [
        {
          "id": 620103,
          "name": "Ang Thong",
          "postCode": [
            "62000"
          ]
        },
        {
          "id": 620117,
          "name": "Khlong Mae Lai",
          "postCode": [
            "62000"
          ]
        },
        {
          "id": 620111,
          "name": "Khonthi",
          "postCode": [
            "62000"
          ]
        },
        {
          "id": 620107,
          "name": "Lan Dokmai",
          "postCode": [
            "62000"
          ]
        },
        {
          "id": 620104,
          "name": "Na Bo Kham",
          "postCode": [
            "62000"
          ]
        },
        {
          "id": 620101,
          "name": "Nai Mueang",
          "postCode": [
            "62000"
          ]
        },
        {
          "id": 620105,
          "name": "Nakhon Chum",
          "postCode": [
            "62000"
          ]
        },
        {
          "id": 620112,
          "name": "Nikhom Thung Pho Thale",
          "postCode": [
            "62000"
          ]
        },
        {
          "id": 620110,
          "name": "Nong Pling",
          "postCode": [
            "62000"
          ]
        },
        {
          "id": 620119,
          "name": "Sa Kaeo",
          "postCode": [
            "62000"
          ]
        },
        {
          "id": 620106,
          "name": "Song Tham",
          "postCode": [
            "62000"
          ]
        },
        {
          "id": 620115,
          "name": "Tha Khun Ram",
          "postCode": [
            "62000"
          ]
        },
        {
          "id": 620118,
          "name": "Thammarong",
          "postCode": [
            "62160"
          ]
        },
        {
          "id": 620113,
          "name": "Thep Nakhon",
          "postCode": [
            "62000"
          ]
        },
        {
          "id": 620102,
          "name": "Trai Trueng",
          "postCode": [
            "62160"
          ]
        },
        {
          "id": 620114,
          "name": "Wang Thong",
          "postCode": [
            "62000"
          ]
        }
      ]
    },
    {
      "city": "Pang Sila Thong",
      "cityCode": 6209,
      "provinceCode": 62,
      "subDistrict": [
        {
          "id": 620902,
          "name": "Hin Dat",
          "postCode": [
            "62120"
          ]
        },
        {
          "id": 620903,
          "name": "Pang Ta Wai",
          "postCode": [
            "62120"
          ]
        },
        {
          "id": 620901,
          "name": "Pho Thong",
          "postCode": [
            "62120"
          ]
        }
      ]
    },
    {
      "city": "Phran Kratai",
      "cityCode": 6206,
      "provinceCode": 62,
      "subDistrict": [
        {
          "id": 620610,
          "name": "Huai Yang",
          "postCode": [
            "62110"
          ]
        },
        {
          "id": 620606,
          "name": "Khao Khirit",
          "postCode": [
            "62110"
          ]
        },
        {
          "id": 620608,
          "name": "Khlong Phikrai",
          "postCode": [
            "62110"
          ]
        },
        {
          "id": 620607,
          "name": "Khui Ban Ong",
          "postCode": [
            "62110"
          ]
        },
        {
          "id": 620602,
          "name": "Nong Hua Wua",
          "postCode": [
            "62110"
          ]
        },
        {
          "id": 620601,
          "name": "Phran Kratai",
          "postCode": [
            "62110"
          ]
        },
        {
          "id": 620603,
          "name": "Tha Mai",
          "postCode": [
            "62110"
          ]
        },
        {
          "id": 620609,
          "name": "Tham Kratai Thong",
          "postCode": [
            "62110"
          ]
        },
        {
          "id": 620604,
          "name": "Wang Khuang",
          "postCode": [
            "62110"
          ]
        },
        {
          "id": 620605,
          "name": "Wang Tabaek",
          "postCode": [
            "62110"
          ]
        }
      ]
    },
    {
      "city": "Sai Ngam",
      "cityCode": 6202,
      "provinceCode": 62,
      "subDistrict": [
        {
          "id": 620205,
          "name": "Maha Chai",
          "postCode": [
            "62150"
          ]
        },
        {
          "id": 620202,
          "name": "Nong Khla",
          "postCode": [
            "62150"
          ]
        },
        {
          "id": 620207,
          "name": "Nong Mae Taeng",
          "postCode": [
            "62150"
          ]
        },
        {
          "id": 620204,
          "name": "Nong Mai Kong",
          "postCode": [
            "62150"
          ]
        },
        {
          "id": 620203,
          "name": "Nong Thong",
          "postCode": [
            "62150"
          ]
        },
        {
          "id": 620206,
          "name": "Phan Thong",
          "postCode": [
            "62150"
          ]
        },
        {
          "id": 620201,
          "name": "Sai Ngam",
          "postCode": [
            "62150"
          ]
        }
      ]
    },
    {
      "city": "Sai Thong Watthana",
      "cityCode": 6208,
      "provinceCode": 62,
      "subDistrict": [
        {
          "id": 620803,
          "name": "Thawon Watthana",
          "postCode": [
            "62190"
          ]
        },
        {
          "id": 620801,
          "name": "Thung Sai",
          "postCode": [
            "62190"
          ]
        },
        {
          "id": 620802,
          "name": "Thung Thong",
          "postCode": [
            "62190"
          ]
        }
      ]
    },
    {
      "city": "Bo Phloi",
      "cityCode": 7103,
      "provinceCode": 71,
      "subDistrict": [
        {
          "id": 710301,
          "name": "Bo Phloi",
          "postCode": [
            "71160"
          ]
        },
        {
          "id": 710308,
          "name": "Chong Dan",
          "postCode": [
            "71160"
          ]
        },
        {
          "id": 710305,
          "name": "Lum Rang",
          "postCode": [
            "71160"
          ]
        },
        {
          "id": 710309,
          "name": "Nong Krang",
          "postCode": [
            "71220"
          ]
        },
        {
          "id": 710302,
          "name": "Nong Kum",
          "postCode": [
            "71160"
          ]
        },
        {
          "id": 710303,
          "name": "Nong Ri",
          "postCode": [
            "71220"
          ]
        }
      ]
    },
    {
      "city": "Dan Makham Tia",
      "cityCode": 7111,
      "provinceCode": 71,
      "subDistrict": [
        {
          "id": 711103,
          "name": "Chorakhe Phueak",
          "postCode": [
            "71260"
          ]
        },
        {
          "id": 711101,
          "name": "Dan Makham Tia",
          "postCode": [
            "71260"
          ]
        },
        {
          "id": 711102,
          "name": "Klon Do",
          "postCode": [
            "71260"
          ]
        },
        {
          "id": 711104,
          "name": "Nong Phai",
          "postCode": [
            "71260"
          ]
        }
      ]
    },
    {
      "city": "Huai Krachao",
      "cityCode": 7113,
      "provinceCode": 71,
      "subDistrict": [
        {
          "id": 711303,
          "name": "Don Salaep",
          "postCode": [
            "71170"
          ]
        },
        {
          "id": 711301,
          "name": "Huai Krachao",
          "postCode": [
            "71170"
          ]
        },
        {
          "id": 711304,
          "name": "Sa Long Ruea",
          "postCode": [
            "71170"
          ]
        },
        {
          "id": 711302,
          "name": "Wang Phai",
          "postCode": [
            "71170"
          ]
        }
      ]
    },
    {
      "city": "Lao Khwan",
      "cityCode": 7110,
      "provinceCode": 71,
      "subDistrict": [
        {
          "id": 711001,
          "name": "Lao Khwan",
          "postCode": [
            "71210"
          ]
        },
        {
          "id": 711007,
          "name": "Nong Fai",
          "postCode": [
            "71210"
          ]
        },
        {
          "id": 711005,
          "name": "Nong Nok Kaeo",
          "postCode": [
            "71210"
          ]
        },
        {
          "id": 711004,
          "name": "Nong Pling",
          "postCode": [
            "71210"
          ]
        },
        {
          "id": 711003,
          "name": "Nong Pradu",
          "postCode": [
            "71210"
          ]
        },
        {
          "id": 711002,
          "name": "Nong Sano",
          "postCode": [
            "71210"
          ]
        },
        {
          "id": 711006,
          "name": "Thung Krabam",
          "postCode": [
            "71210"
          ]
        }
      ]
    },
    {
      "city": "Mueang Kanchanaburi",
      "cityCode": 7101,
      "provinceCode": 71,
      "subDistrict": [
        {
          "id": 710113,
          "name": "Ban Kao",
          "postCode": [
            "71000"
          ]
        },
        {
          "id": 710101,
          "name": "Ban Nuea",
          "postCode": [
            "71000"
          ]
        },
        {
          "id": 710102,
          "name": "Ban Tai",
          "postCode": [
            "71000"
          ]
        },
        {
          "id": 710109,
          "name": "Chong Sadao",
          "postCode": [
            "71190"
          ]
        },
        {
          "id": 710105,
          "name": "Kaeng Sian",
          "postCode": [
            "71000"
          ]
        },
        {
          "id": 710111,
          "name": "Ko Samrong",
          "postCode": [
            "71000"
          ]
        },
        {
          "id": 710107,
          "name": "Lat Ya",
          "postCode": [
            "71190"
          ]
        },
        {
          "id": 710106,
          "name": "Nong Bua",
          "postCode": [
            "71190"
          ]
        },
        {
          "id": 710110,
          "name": "Nong Ya",
          "postCode": [
            "71000"
          ]
        },
        {
          "id": 710103,
          "name": "Pak Phraek",
          "postCode": [
            "71000"
          ]
        },
        {
          "id": 710104,
          "name": "Tha Makham",
          "postCode": [
            "71000"
          ]
        },
        {
          "id": 710108,
          "name": "Wang Dong",
          "postCode": [
            "71190"
          ]
        },
        {
          "id": 710116,
          "name": "Wang Yen",
          "postCode": [
            "71000"
          ]
        }
      ]
    },
    {
      "city": "Nong Prue",
      "cityCode": 7112,
      "provinceCode": 71,
      "subDistrict": [
        {
          "id": 711202,
          "name": "Nong Pla Lai",
          "postCode": [
            "71220"
          ]
        },
        {
          "id": 711201,
          "name": "Nong Prue",
          "postCode": [
            "71220"
          ]
        },
        {
          "id": 711203,
          "name": "Somdet Charoen",
          "postCode": [
            "71220"
          ]
        }
      ]
    },
    {
      "city": "Phanom Thuan",
      "cityCode": 7109,
      "provinceCode": 71,
      "subDistrict": [
        {
          "id": 710904,
          "name": "Don Chedi",
          "postCode": [
            "71140"
          ]
        },
        {
          "id": 710912,
          "name": "Don Ta Phet",
          "postCode": [
            "71140"
          ]
        },
        {
          "id": 710902,
          "name": "Nong Rong",
          "postCode": [
            "71140"
          ]
        },
        {
          "id": 710911,
          "name": "Nong Sarai",
          "postCode": [
            "71140"
          ]
        },
        {
          "id": 710905,
          "name": "Phang Tru",
          "postCode": [
            "71140"
          ]
        },
        {
          "id": 710901,
          "name": "Phanom Thuan",
          "postCode": [
            "71140"
          ]
        },
        {
          "id": 710906,
          "name": "Rang Wai",
          "postCode": [
            "71170"
          ]
        },
        {
          "id": 710903,
          "name": "Thung Samo",
          "postCode": [
            "71140"
          ]
        }
      ]
    },
    {
      "city": "Sai Yok",
      "cityCode": 7102,
      "provinceCode": 71,
      "subDistrict": [
        {
          "id": 710207,
          "name": "Bongti",
          "postCode": [
            "71150"
          ]
        },
        {
          "id": 710201,
          "name": "Lum Sum",
          "postCode": [
            "71150"
          ]
        },
        {
          "id": 710204,
          "name": "Sai Yok",
          "postCode": [
            "71150"
          ]
        },
        {
          "id": 710206,
          "name": "Si Mongkhon",
          "postCode": [
            "71150"
          ]
        },
        {
          "id": 710203,
          "name": "Sing",
          "postCode": [
            "71150"
          ]
        },
        {
          "id": 710202,
          "name": "Tha Sao",
          "postCode": [
            "71150"
          ]
        },
        {
          "id": 710205,
          "name": "Wang Khrachae",
          "postCode": [
            "71150"
          ]
        }
      ]
    },
    {
      "city": "Sangkhla Buri",
      "cityCode": 7108,
      "provinceCode": 71,
      "subDistrict": [
        {
          "id": 710803,
          "name": "Laiwo",
          "postCode": [
            "71240"
          ]
        },
        {
          "id": 710801,
          "name": "Nong Lu",
          "postCode": [
            "71240"
          ]
        },
        {
          "id": 710802,
          "name": "Prang Phle",
          "postCode": [
            "71240"
          ]
        }
      ]
    },
    {
      "city": "Si Sawat",
      "cityCode": 7104,
      "provinceCode": 71,
      "subDistrict": [
        {
          "id": 710402,
          "name": "Dan Mae Chalaep",
          "postCode": [
            "71250"
          ]
        },
        {
          "id": 710405,
          "name": "Khao Chot",
          "postCode": [
            "71220"
          ]
        },
        {
          "id": 710406,
          "name": "Mae Krabung",
          "postCode": [
            "71250"
          ]
        },
        {
          "id": 710401,
          "name": "Na Suan",
          "postCode": [
            "71250"
          ]
        },
        {
          "id": 710403,
          "name": "Nong Pet",
          "postCode": [
            "71250"
          ]
        },
        {
          "id": 710404,
          "name": "Tha Kradan",
          "postCode": [
            "71250"
          ]
        }
      ]
    },
    {
      "city": "Tha Maka",
      "cityCode": 7105,
      "provinceCode": 71,
      "subDistrict": [
        {
          "id": 710503,
          "name": "Don Cha-em",
          "postCode": [
            "71130"
          ]
        },
        {
          "id": 710509,
          "name": "Don Khamin",
          "postCode": [
            "71120"
          ]
        },
        {
          "id": 710511,
          "name": "Khao Sam Sip Hap",
          "postCode": [
            "71120"
          ]
        },
        {
          "id": 710508,
          "name": "Khok Tabong",
          "postCode": [
            "71120"
          ]
        },
        {
          "id": 710517,
          "name": "Nong lan",
          "postCode": [
            "71130"
          ]
        },
        {
          "id": 710501,
          "name": "Phong Tuek",
          "postCode": [
            "71120"
          ]
        },
        {
          "id": 710512,
          "name": "Phra Thaen",
          "postCode": [
            "71130"
          ]
        },
        {
          "id": 710514,
          "name": "Saen To",
          "postCode": [
            "71130"
          ]
        },
        {
          "id": 710515,
          "name": "Sanam Yae",
          "postCode": [
            "70190"
          ]
        },
        {
          "id": 710505,
          "name": "Takhram En",
          "postCode": [
            "71130"
          ]
        },
        {
          "id": 710504,
          "name": "Tha Mai",
          "postCode": [
            "71120"
          ]
        },
        {
          "id": 710506,
          "name": "Tha Maka",
          "postCode": [
            "71120"
          ]
        },
        {
          "id": 710507,
          "name": "Tha Ruea",
          "postCode": [
            "71130"
          ]
        },
        {
          "id": 710516,
          "name": "Tha Sao",
          "postCode": [
            "71120"
          ]
        },
        {
          "id": 710510,
          "name": "Ulok Si Muen",
          "postCode": [
            "71130"
          ]
        },
        {
          "id": 710513,
          "name": "Wai Niao",
          "postCode": [
            "71120"
          ]
        },
        {
          "id": 710502,
          "name": "Yang Muang",
          "postCode": [
            "71120"
          ]
        }
      ]
    },
    {
      "city": "Tha Muang",
      "cityCode": 7106,
      "provinceCode": 71,
      "subDistrict": [
        {
          "id": 710609,
          "name": "Ban Mai",
          "postCode": [
            "71110"
          ]
        },
        {
          "id": 710607,
          "name": "Khao Noi",
          "postCode": [
            "71110"
          ]
        },
        {
          "id": 710608,
          "name": "Muang Chum",
          "postCode": [
            "71110"
          ]
        },
        {
          "id": 710605,
          "name": "Nong Khao",
          "postCode": [
            "71110"
          ]
        },
        {
          "id": 710613,
          "name": "Nong Tak Ya",
          "postCode": [
            "71110"
          ]
        },
        {
          "id": 710610,
          "name": "Phang Tru",
          "postCode": [
            "71110"
          ]
        },
        {
          "id": 710612,
          "name": "Rang Sali",
          "postCode": [
            "71110"
          ]
        },
        {
          "id": 710604,
          "name": "Tha Lo",
          "postCode": [
            "71000",
            "71110"
          ]
        },
        {
          "id": 710601,
          "name": "Tha Muang",
          "postCode": [
            "71110"
          ]
        },
        {
          "id": 710611,
          "name": "Tha Takro",
          "postCode": [
            "71130"
          ]
        },
        {
          "id": 710606,
          "name": "Thung Thong",
          "postCode": [
            "71110"
          ]
        },
        {
          "id": 710602,
          "name": "Wang Khanai",
          "postCode": [
            "71110"
          ]
        },
        {
          "id": 710603,
          "name": "Wang Sala",
          "postCode": [
            "71110",
            "71130"
          ]
        }
      ]
    },
    {
      "city": "Thong Pha Phum",
      "cityCode": 7107,
      "provinceCode": 71,
      "subDistrict": [
        {
          "id": 710705,
          "name": "Chalae",
          "postCode": [
            "71180"
          ]
        },
        {
          "id": 710703,
          "name": "Hin Dat",
          "postCode": [
            "71180"
          ]
        },
        {
          "id": 710706,
          "name": "Huai Khayeng",
          "postCode": [
            "71180"
          ]
        },
        {
          "id": 710704,
          "name": "Linthin",
          "postCode": [
            "71180"
          ]
        },
        {
          "id": 710702,
          "name": "Pilok",
          "postCode": [
            "71180"
          ]
        },
        {
          "id": 710707,
          "name": "Sahakon Nikhom",
          "postCode": [
            "71180"
          ]
        },
        {
          "id": 710701,
          "name": "Tha Khanun",
          "postCode": [
            "71180"
          ]
        }
      ]
    },
    {
      "city": "Ban Fang",
      "cityCode": 4002,
      "provinceCode": 40,
      "subDistrict": [
        {
          "id": 400206,
          "name": "Ban Fang",
          "postCode": [
            "40270"
          ]
        },
        {
          "id": 400204,
          "name": "Ban Lao",
          "postCode": [
            "40270"
          ]
        },
        {
          "id": 400207,
          "name": "Khok Ngam",
          "postCode": [
            "40270"
          ]
        },
        {
          "id": 400203,
          "name": "Non Khong",
          "postCode": [
            "40270"
          ]
        },
        {
          "id": 400201,
          "name": "Nong Bua",
          "postCode": [
            "40270"
          ]
        },
        {
          "id": 400205,
          "name": "Pa Manao",
          "postCode": [
            "40270"
          ]
        },
        {
          "id": 400202,
          "name": "Pa Wai Nang",
          "postCode": [
            "40270"
          ]
        }
      ]
    },
    {
      "city": "Ban Haet",
      "cityCode": 4024,
      "provinceCode": 40,
      "subDistrict": [
        {
          "id": 402401,
          "name": "Ban Haet",
          "postCode": [
            "40110"
          ]
        },
        {
          "id": 402402,
          "name": "Khok Samran",
          "postCode": [
            "40110"
          ]
        },
        {
          "id": 402403,
          "name": "Non Sombun",
          "postCode": [
            "40110"
          ]
        },
        {
          "id": 402404,
          "name": "Nong Saeng",
          "postCode": [
            "40110"
          ]
        }
      ]
    },
    {
      "city": "Ban Phai",
      "cityCode": 4010,
      "provinceCode": 40,
      "subDistrict": [
        {
          "id": 401009,
          "name": "Ban Lan",
          "postCode": [
            "40110"
          ]
        },
        {
          "id": 401001,
          "name": "Ban Phai",
          "postCode": [
            "40110"
          ]
        },
        {
          "id": 401014,
          "name": "Hin Tang",
          "postCode": [
            "40110"
          ]
        },
        {
          "id": 401017,
          "name": "Hua Nong",
          "postCode": [
            "40110"
          ]
        },
        {
          "id": 401010,
          "name": "Khaen Nuea",
          "postCode": [
            "40110"
          ]
        },
        {
          "id": 401005,
          "name": "Mueang Phia",
          "postCode": [
            "40110"
          ]
        },
        {
          "id": 401002,
          "name": "Nai Mueang",
          "postCode": [
            "40110"
          ]
        },
        {
          "id": 401016,
          "name": "Nong Nam Sai",
          "postCode": [
            "40110"
          ]
        },
        {
          "id": 401013,
          "name": "Pa Po",
          "postCode": [
            "40110"
          ]
        },
        {
          "id": 401011,
          "name": "Phu Lek",
          "postCode": [
            "40110"
          ]
        }
      ]
    },
    {
      "city": "Chonnabot",
      "cityCode": 4018,
      "provinceCode": 40,
      "subDistrict": [
        {
          "id": 401805,
          "name": "Ban Thaen",
          "postCode": [
            "40180"
          ]
        },
        {
          "id": 401801,
          "name": "Chonnabot",
          "postCode": [
            "40180"
          ]
        },
        {
          "id": 401804,
          "name": "Huai Kae",
          "postCode": [
            "40180"
          ]
        },
        {
          "id": 401802,
          "name": "Kut Phia Khom",
          "postCode": [
            "40180"
          ]
        },
        {
          "id": 401807,
          "name": "Non Phayom",
          "postCode": [
            "40180"
          ]
        },
        {
          "id": 401808,
          "name": "Po Daeng",
          "postCode": [
            "40180"
          ]
        },
        {
          "id": 401806,
          "name": "Si Bun Rueang",
          "postCode": [
            "40180"
          ]
        },
        {
          "id": 401803,
          "name": "Wang Saeng",
          "postCode": [
            "40180"
          ]
        }
      ]
    },
    {
      "city": "Chum Phae",
      "cityCode": 4005,
      "provinceCode": 40,
      "subDistrict": [
        {
          "id": 400507,
          "name": "Chai So",
          "postCode": [
            "40130"
          ]
        },
        {
          "id": 400501,
          "name": "Chum Phae",
          "postCode": [
            "40130"
          ]
        },
        {
          "id": 400505,
          "name": "Khua Riang",
          "postCode": [
            "40130"
          ]
        },
        {
          "id": 400503,
          "name": "Na Nong Thum",
          "postCode": [
            "40290"
          ]
        },
        {
          "id": 400509,
          "name": "Na Phiang",
          "postCode": [
            "40130"
          ]
        },
        {
          "id": 400502,
          "name": "Non Han",
          "postCode": [
            "40290"
          ]
        },
        {
          "id": 400512,
          "name": "Non Sa-at",
          "postCode": [
            "40290"
          ]
        },
        {
          "id": 400504,
          "name": "Non Udom",
          "postCode": [
            "40130"
          ]
        },
        {
          "id": 400510,
          "name": "Nong Khiat",
          "postCode": [
            "40290"
          ]
        },
        {
          "id": 400506,
          "name": "Nong Phai",
          "postCode": [
            "40130"
          ]
        },
        {
          "id": 400511,
          "name": "Nong Sao Lao",
          "postCode": [
            "40130"
          ]
        },
        {
          "id": 400508,
          "name": "Wang Hin Lat",
          "postCode": [
            "40130"
          ]
        }
      ]
    },
    {
      "city": "Khao Suan Kwang",
      "cityCode": 4019,
      "provinceCode": 40,
      "subDistrict": [
        {
          "id": 401902,
          "name": "Dong Mueang Aem",
          "postCode": [
            "40280"
          ]
        },
        {
          "id": 401905,
          "name": "Kham Muang",
          "postCode": [
            "40280"
          ]
        },
        {
          "id": 401901,
          "name": "Khao Suan Kwang",
          "postCode": [
            "40280"
          ]
        },
        {
          "id": 401903,
          "name": "Na Ngio",
          "postCode": [
            "40280"
          ]
        },
        {
          "id": 401904,
          "name": "Non Sombun",
          "postCode": [
            "40280"
          ]
        }
      ]
    },
    {
      "city": "Khok Pho Chai",
      "cityCode": 4022,
      "provinceCode": 40,
      "subDistrict": [
        {
          "id": 402201,
          "name": "Ban Khok",
          "postCode": [
            "40160"
          ]
        },
        {
          "id": 402204,
          "name": "Na Phaeng",
          "postCode": [
            "40160"
          ]
        },
        {
          "id": 402202,
          "name": "Pho Chai",
          "postCode": [
            "40160"
          ]
        },
        {
          "id": 402203,
          "name": "Sap Sombun",
          "postCode": [
            "40160"
          ]
        }
      ]
    },
    {
      "city": "Kranuan",
      "cityCode": 4009,
      "provinceCode": 40,
      "subDistrict": [
        {
          "id": 400907,
          "name": "Ban Fang",
          "postCode": [
            "40170"
          ]
        },
        {
          "id": 400909,
          "name": "Dun Sat",
          "postCode": [
            "40170"
          ]
        },
        {
          "id": 400912,
          "name": "Hua Na Kham",
          "postCode": [
            "40170"
          ]
        },
        {
          "id": 400905,
          "name": "Huai Chot",
          "postCode": [
            "40170"
          ]
        },
        {
          "id": 400906,
          "name": "Huai Yang",
          "postCode": [
            "40170"
          ]
        },
        {
          "id": 400911,
          "name": "Nam Om",
          "postCode": [
            "40170"
          ]
        },
        {
          "id": 400901,
          "name": "Nong Ko",
          "postCode": [
            "40170"
          ]
        },
        {
          "id": 400902,
          "name": "Nong Kung Yai",
          "postCode": [
            "40170"
          ]
        },
        {
          "id": 400910,
          "name": "Nong No",
          "postCode": [
            "40170"
          ]
        }
      ]
    },
    {
      "city": "Mancha Khiri",
      "cityCode": 4017,
      "provinceCode": 40,
      "subDistrict": [
        {
          "id": 401705,
          "name": "Kham Khaen",
          "postCode": [
            "40160"
          ]
        },
        {
          "id": 401701,
          "name": "Kut Khao",
          "postCode": [
            "40160"
          ]
        },
        {
          "id": 401706,
          "name": "Na Kha",
          "postCode": [
            "40160"
          ]
        },
        {
          "id": 401707,
          "name": "Na Ngam",
          "postCode": [
            "40160"
          ]
        },
        {
          "id": 401703,
          "name": "Nong Paen",
          "postCode": [
            "40160"
          ]
        },
        {
          "id": 401704,
          "name": "Phon Phek",
          "postCode": [
            "40160"
          ]
        },
        {
          "id": 401702,
          "name": "Suan Mon",
          "postCode": [
            "40160"
          ]
        },
        {
          "id": 401710,
          "name": "Tha Sala",
          "postCode": [
            "40160"
          ]
        }
      ]
    },
    {
      "city": "Mueang Khon Kaen",
      "cityCode": 4001,
      "provinceCode": 40,
      "subDistrict": [
        {
          "id": 400110,
          "name": "Ban Kho",
          "postCode": [
            "40000"
          ]
        },
        {
          "id": 400115,
          "name": "Ban Pet",
          "postCode": [
            "40000"
          ]
        },
        {
          "id": 400105,
          "name": "Ban Thum",
          "postCode": [
            "40000"
          ]
        },
        {
          "id": 400109,
          "name": "Ban Wa",
          "postCode": [
            "40000"
          ]
        },
        {
          "id": 400117,
          "name": "Bueng Niam",
          "postCode": [
            "40000"
          ]
        },
        {
          "id": 400111,
          "name": "Daeng Yai",
          "postCode": [
            "40000"
          ]
        },
        {
          "id": 400112,
          "name": "Don Chang",
          "postCode": [
            "40000"
          ]
        },
        {
          "id": 400113,
          "name": "Don Han",
          "postCode": [
            "40260"
          ]
        },
        {
          "id": 400103,
          "name": "Khok Si",
          "postCode": [
            "40000"
          ]
        },
        {
          "id": 400106,
          "name": "Mueang Kao",
          "postCode": [
            "40000"
          ]
        },
        {
          "id": 400101,
          "name": "Nai Mueang",
          "postCode": [
            "40000",
            "40002"
          ]
        },
        {
          "id": 400118,
          "name": "Non Thon",
          "postCode": [
            "40000"
          ]
        },
        {
          "id": 400116,
          "name": "Nong Tum",
          "postCode": [
            "40000"
          ]
        },
        {
          "id": 400107,
          "name": "Phra Lap",
          "postCode": [
            "40000"
          ]
        },
        {
          "id": 400102,
          "name": "Samran",
          "postCode": [
            "40000"
          ]
        },
        {
          "id": 400108,
          "name": "Sawathi",
          "postCode": [
            "40000"
          ]
        },
        {
          "id": 400114,
          "name": "Sila",
          "postCode": [
            "40000"
          ]
        },
        {
          "id": 400104,
          "name": "Tha Phra",
          "postCode": [
            "40260"
          ]
        }
      ]
    },
    {
      "city": "Nam Phong",
      "cityCode": 4007,
      "provinceCode": 40,
      "subDistrict": [
        {
          "id": 400707,
          "name": "Ban Kham",
          "postCode": [
            "40140"
          ]
        },
        {
          "id": 400708,
          "name": "Bua Ngoen",
          "postCode": [
            "40140"
          ]
        },
        {
          "id": 400704,
          "name": "Bua Yai",
          "postCode": [
            "40140"
          ]
        },
        {
          "id": 400712,
          "name": "Kut Nam Sai",
          "postCode": [
            "40310"
          ]
        },
        {
          "id": 400706,
          "name": "Muang Wan",
          "postCode": [
            "40310"
          ]
        },
        {
          "id": 400701,
          "name": "Nam Phong",
          "postCode": [
            "40140",
            "40310"
          ]
        },
        {
          "id": 400703,
          "name": "Nong Kung",
          "postCode": [
            "40140"
          ]
        },
        {
          "id": 400711,
          "name": "Phang Thui",
          "postCode": [
            "40140"
          ]
        },
        {
          "id": 400705,
          "name": "Sa-at",
          "postCode": [
            "40310"
          ]
        },
        {
          "id": 400709,
          "name": "Sai Mun",
          "postCode": [
            "40140"
          ]
        },
        {
          "id": 400710,
          "name": "Tha Krasoem",
          "postCode": [
            "40140"
          ]
        },
        {
          "id": 400702,
          "name": "Wang Chai",
          "postCode": [
            "40140"
          ]
        }
      ]
    },
    {
      "city": "Non Sila",
      "cityCode": 4025,
      "provinceCode": 40,
      "subDistrict": [
        {
          "id": 402503,
          "name": "Ban Han",
          "postCode": [
            "40110"
          ]
        },
        {
          "id": 402505,
          "name": "Non Daeng",
          "postCode": [
            "40110"
          ]
        },
        {
          "id": 402501,
          "name": "Non Sila",
          "postCode": [
            "40110"
          ]
        },
        {
          "id": 402502,
          "name": "Nong Pla Mo",
          "postCode": [
            "40110"
          ]
        },
        {
          "id": 402504,
          "name": "Pueai Yai",
          "postCode": [
            "40110"
          ]
        }
      ]
    },
    {
      "city": "Nong Na Kham",
      "cityCode": 4023,
      "provinceCode": 40,
      "subDistrict": [
        {
          "id": 402302,
          "name": "Ban Khok",
          "postCode": [
            "40150"
          ]
        },
        {
          "id": 402303,
          "name": "Khanuan",
          "postCode": [
            "40150"
          ]
        },
        {
          "id": 402301,
          "name": "Kut That",
          "postCode": [
            "40150"
          ]
        }
      ]
    },
    {
      "city": "Nong Ruea",
      "cityCode": 4004,
      "provinceCode": 40,
      "subDistrict": [
        {
          "id": 400403,
          "name": "Ban Kong",
          "postCode": [
            "40240"
          ]
        },
        {
          "id": 400402,
          "name": "Ban Meng",
          "postCode": [
            "40210"
          ]
        },
        {
          "id": 400410,
          "name": "Ban Phue",
          "postCode": [
            "40240"
          ]
        },
        {
          "id": 400405,
          "name": "Chorakhe",
          "postCode": [
            "40240"
          ]
        },
        {
          "id": 400407,
          "name": "Kut Kwang",
          "postCode": [
            "40210"
          ]
        },
        {
          "id": 400409,
          "name": "Non Sa-at",
          "postCode": [
            "40210"
          ]
        },
        {
          "id": 400408,
          "name": "Non Than",
          "postCode": [
            "40210"
          ]
        },
        {
          "id": 400406,
          "name": "Non Thong",
          "postCode": [
            "40210"
          ]
        },
        {
          "id": 400401,
          "name": "Nong Ruea",
          "postCode": [
            "40210"
          ]
        },
        {
          "id": 400404,
          "name": "Yang Kham",
          "postCode": [
            "40240"
          ]
        }
      ]
    },
    {
      "city": "Nong Song Hong",
      "cityCode": 4015,
      "provinceCode": 40,
      "subDistrict": [
        {
          "id": 401510,
          "name": "Don Dang",
          "postCode": [
            "40190"
          ]
        },
        {
          "id": 401507,
          "name": "Don Du",
          "postCode": [
            "40190"
          ]
        },
        {
          "id": 401508,
          "name": "Dong Kheng",
          "postCode": [
            "40190"
          ]
        },
        {
          "id": 401509,
          "name": "Han Chot",
          "postCode": [
            "40190"
          ]
        },
        {
          "id": 401502,
          "name": "Khuemchat",
          "postCode": [
            "40190"
          ]
        },
        {
          "id": 401503,
          "name": "Non That",
          "postCode": [
            "40190"
          ]
        },
        {
          "id": 401506,
          "name": "Nong Mek",
          "postCode": [
            "40190"
          ]
        },
        {
          "id": 401512,
          "name": "Nong Phai Lom",
          "postCode": [
            "40190"
          ]
        },
        {
          "id": 401501,
          "name": "Nong Song Hong",
          "postCode": [
            "40190"
          ]
        },
        {
          "id": 401505,
          "name": "Samrong",
          "postCode": [
            "40190"
          ]
        },
        {
          "id": 401504,
          "name": "Takua Pa",
          "postCode": [
            "40190"
          ]
        },
        {
          "id": 401511,
          "name": "Wang Hin",
          "postCode": [
            "40190"
          ]
        }
      ]
    },
    {
      "city": "Phon",
      "cityCode": 4012,
      "provinceCode": 40,
      "subDistrict": [
        {
          "id": 401203,
          "name": "Chot Nong Kae",
          "postCode": [
            "40120"
          ]
        },
        {
          "id": 401213,
          "name": "Hua Thung",
          "postCode": [
            "40120"
          ]
        },
        {
          "id": 401204,
          "name": "Kao Ngio",
          "postCode": [
            "40120"
          ]
        },
        {
          "id": 401208,
          "name": "Khok Sa-nga",
          "postCode": [
            "40120"
          ]
        },
        {
          "id": 401210,
          "name": "Lom Khom",
          "postCode": [
            "40120"
          ]
        },
        {
          "id": 401201,
          "name": "Mueang Phon",
          "postCode": [
            "40120"
          ]
        },
        {
          "id": 401211,
          "name": "Non Kha",
          "postCode": [
            "40120"
          ]
        },
        {
          "id": 401205,
          "name": "Nong Makhuea",
          "postCode": [
            "40120"
          ]
        },
        {
          "id": 401209,
          "name": "Nong Waeng Nang Bao",
          "postCode": [
            "40120"
          ]
        },
        {
          "id": 401206,
          "name": "Nong Waeng Sok Phra",
          "postCode": [
            "40120"
          ]
        },
        {
          "id": 401207,
          "name": "Phek Yai",
          "postCode": [
            "40120"
          ]
        },
        {
          "id": 401212,
          "name": "Sok Nok Ten",
          "postCode": [
            "40120"
          ]
        }
      ]
    },
    {
      "city": "Phra Yuen",
      "cityCode": 4003,
      "provinceCode": 40,
      "subDistrict": [
        {
          "id": 400303,
          "name": "Ban Ton",
          "postCode": [
            "40320"
          ]
        },
        {
          "id": 400305,
          "name": "Kham Pom",
          "postCode": [
            "40320"
          ]
        },
        {
          "id": 400304,
          "name": "Nong Waeng",
          "postCode": [
            "40320"
          ]
        },
        {
          "id": 400302,
          "name": "Phra Bu",
          "postCode": [
            "40320"
          ]
        },
        {
          "id": 400301,
          "name": "Phra Yuen",
          "postCode": [
            "40320"
          ]
        }
      ]
    },
    {
      "city": "Phu Pha Man",
      "cityCode": 4020,
      "provinceCode": 40,
      "subDistrict": [
        {
          "id": 402002,
          "name": "Na Fai",
          "postCode": [
            "40350"
          ]
        },
        {
          "id": 402001,
          "name": "Non Khom",
          "postCode": [
            "40350"
          ]
        },
        {
          "id": 402003,
          "name": "Phu Pha Man",
          "postCode": [
            "40350"
          ]
        },
        {
          "id": 402004,
          "name": "Wang Sawap",
          "postCode": [
            "40350"
          ]
        },
        {
          "id": 402005,
          "name": "huai Muang",
          "postCode": [
            "40350"
          ]
        }
      ]
    },
    {
      "city": "Phu Wiang",
      "cityCode": 4016,
      "provinceCode": 40,
      "subDistrict": [
        {
          "id": 401601,
          "name": "Ban Ruea",
          "postCode": [
            "40150"
          ]
        },
        {
          "id": 401616,
          "name": "Din Dam",
          "postCode": [
            "40150"
          ]
        },
        {
          "id": 401605,
          "name": "Kut Khon Kaen",
          "postCode": [
            "40150"
          ]
        },
        {
          "id": 401606,
          "name": "Na Chum Saeng",
          "postCode": [
            "40150"
          ]
        },
        {
          "id": 401607,
          "name": "Na Wa",
          "postCode": [
            "40150"
          ]
        },
        {
          "id": 401612,
          "name": "Nong Kung Soen",
          "postCode": [
            "40150"
          ]
        },
        {
          "id": 401610,
          "name": "Nong Kung Thanasan",
          "postCode": [
            "40150"
          ]
        },
        {
          "id": 401617,
          "name": "Phu Wiang",
          "postCode": [
            "40150"
          ]
        },
        {
          "id": 401613,
          "name": "Song Pueai",
          "postCode": [
            "40150"
          ]
        },
        {
          "id": 401614,
          "name": "Thung Chomphu",
          "postCode": [
            "40150"
          ]
        },
        {
          "id": 401604,
          "name": "Wa Thong",
          "postCode": [
            "40150"
          ]
        }
      ]
    },
    {
      "city": "Pueai Noi",
      "cityCode": 4011,
      "provinceCode": 40,
      "subDistrict": [
        {
          "id": 401103,
          "name": "Kham Pom",
          "postCode": [
            "40340"
          ]
        },
        {
          "id": 401101,
          "name": "Pueai Noi",
          "postCode": [
            "40340"
          ]
        },
        {
          "id": 401104,
          "name": "Sa Kaeo",
          "postCode": [
            "40340"
          ]
        },
        {
          "id": 401102,
          "name": "Wang Muang",
          "postCode": [
            "40340"
          ]
        }
      ]
    },
    {
      "city": "Sam Sung",
      "cityCode": 4021,
      "provinceCode": 40,
      "subDistrict": [
        {
          "id": 402103,
          "name": "Ban Non",
          "postCode": [
            "40170"
          ]
        },
        {
          "id": 402105,
          "name": "Huai Toei",
          "postCode": [
            "40170"
          ]
        },
        {
          "id": 402102,
          "name": "Kham Maet",
          "postCode": [
            "40170"
          ]
        },
        {
          "id": 402104,
          "name": "Khu Kham",
          "postCode": [
            "40170"
          ]
        },
        {
          "id": 402101,
          "name": "Kranuan",
          "postCode": [
            "40170"
          ]
        }
      ]
    },
    {
      "city": "Si Chomphu",
      "cityCode": 4006,
      "provinceCode": 40,
      "subDistrict": [
        {
          "id": 400609,
          "name": "Ban Mai",
          "postCode": [
            "40220"
          ]
        },
        {
          "id": 400608,
          "name": "Boribun",
          "postCode": [
            "40220"
          ]
        },
        {
          "id": 400607,
          "name": "Dong Lan",
          "postCode": [
            "40220"
          ]
        },
        {
          "id": 400603,
          "name": "Na Chan",
          "postCode": [
            "40220"
          ]
        },
        {
          "id": 400606,
          "name": "Nong Daeng",
          "postCode": [
            "40220"
          ]
        },
        {
          "id": 400610,
          "name": "Phu Han",
          "postCode": [
            "40220"
          ]
        },
        {
          "id": 400605,
          "name": "Sam Yang",
          "postCode": [
            "40220"
          ]
        },
        {
          "id": 400601,
          "name": "Si Chomphu",
          "postCode": [
            "40220"
          ]
        },
        {
          "id": 400602,
          "name": "Si Suk",
          "postCode": [
            "40220"
          ]
        },
        {
          "id": 400604,
          "name": "Wang Phoem",
          "postCode": [
            "40220"
          ]
        }
      ]
    },
    {
      "city": "Ubolratana",
      "cityCode": 4008,
      "provinceCode": 40,
      "subDistrict": [
        {
          "id": 400802,
          "name": "Ban Dong",
          "postCode": [
            "40250"
          ]
        },
        {
          "id": 400801,
          "name": "Khok Sung",
          "postCode": [
            "40250"
          ]
        },
        {
          "id": 400803,
          "name": "Khuean Ubolratana",
          "postCode": [
            "40250"
          ]
        },
        {
          "id": 400804,
          "name": "Na Kham",
          "postCode": [
            "40250"
          ]
        },
        {
          "id": 400805,
          "name": "Si Suk Samran",
          "postCode": [
            "40250"
          ]
        },
        {
          "id": 400806,
          "name": "Thung Pong",
          "postCode": [
            "40250"
          ]
        }
      ]
    },
    {
      "city": "Waeng Noi",
      "cityCode": 4014,
      "provinceCode": 40,
      "subDistrict": [
        {
          "id": 401402,
          "name": "Kan Lueang",
          "postCode": [
            "40230"
          ]
        },
        {
          "id": 401404,
          "name": "Lahan Na",
          "postCode": [
            "40230"
          ]
        },
        {
          "id": 401403,
          "name": "Tha Nang Naeo",
          "postCode": [
            "40230"
          ]
        },
        {
          "id": 401405,
          "name": "Tha Wat",
          "postCode": [
            "40230"
          ]
        },
        {
          "id": 401406,
          "name": "Thang Khwang",
          "postCode": [
            "40230"
          ]
        },
        {
          "id": 401401,
          "name": "Waeng Noi",
          "postCode": [
            "40230"
          ]
        }
      ]
    },
    {
      "city": "Waeng Yai",
      "cityCode": 4013,
      "provinceCode": 40,
      "subDistrict": [
        {
          "id": 401301,
          "name": "Khon Chim",
          "postCode": [
            "40330"
          ]
        },
        {
          "id": 401302,
          "name": "Mai Na Phiang",
          "postCode": [
            "40330"
          ]
        },
        {
          "id": 401305,
          "name": "Non Sa-at",
          "postCode": [
            "40330"
          ]
        },
        {
          "id": 401303,
          "name": "Non Thong",
          "postCode": [
            "40330"
          ]
        },
        {
          "id": 401304,
          "name": "Waeng Yai",
          "postCode": [
            "40330"
          ]
        }
      ]
    },
    {
      "city": "Wiang Kao",
      "cityCode": 4026,
      "provinceCode": 40,
      "subDistrict": [
        {
          "id": 402903,
          "name": "Khao Noi",
          "postCode": [
            "40150"
          ]
        },
        {
          "id": 402902,
          "name": "Mueang Kao Phatthana",
          "postCode": [
            "40150"
          ]
        },
        {
          "id": 402901,
          "name": "Nai Mueang",
          "postCode": [
            "40150"
          ]
        }
      ]
    },
    {
      "city": "Ao Luek",
      "cityCode": 8105,
      "provinceCode": 81,
      "subDistrict": [
        {
          "id": 810505,
          "name": "Ao Luek Noi",
          "postCode": [
            "81110"
          ]
        },
        {
          "id": 810506,
          "name": "Ao Luek Nuea",
          "postCode": [
            "81110"
          ]
        },
        {
          "id": 810501,
          "name": "Ao Luek Tai",
          "postCode": [
            "81110"
          ]
        },
        {
          "id": 810509,
          "name": "Ban Klang",
          "postCode": [
            "81110"
          ]
        },
        {
          "id": 810507,
          "name": "Khao Yai",
          "postCode": [
            "81110"
          ]
        },
        {
          "id": 810504,
          "name": "Khlong Hin",
          "postCode": [
            "81110"
          ]
        },
        {
          "id": 810508,
          "name": "Khlong Ya",
          "postCode": [
            "81110"
          ]
        },
        {
          "id": 810502,
          "name": "Laem Sak",
          "postCode": [
            "81110"
          ]
        },
        {
          "id": 810503,
          "name": "Na Nuea",
          "postCode": [
            "81110"
          ]
        }
      ]
    },
    {
      "city": "Khao Phanom",
      "cityCode": 8102,
      "provinceCode": 81,
      "subDistrict": [
        {
          "id": 810202,
          "name": "Khao Din",
          "postCode": [
            "81140"
          ]
        },
        {
          "id": 810201,
          "name": "Khao Phanom",
          "postCode": [
            "81140"
          ]
        },
        {
          "id": 810206,
          "name": "Khok Han",
          "postCode": [
            "80240"
          ]
        },
        {
          "id": 810205,
          "name": "Na Khao",
          "postCode": [
            "81140"
          ]
        },
        {
          "id": 810204,
          "name": "Phru Tiao",
          "postCode": [
            "81140"
          ]
        },
        {
          "id": 810203,
          "name": "Sin Pun",
          "postCode": [
            "80240"
          ]
        }
      ]
    },
    {
      "city": "Khlong Thom",
      "cityCode": 8104,
      "provinceCode": 81,
      "subDistrict": [
        {
          "id": 810405,
          "name": "Huai Nam Khao",
          "postCode": [
            "81120"
          ]
        },
        {
          "id": 810403,
          "name": "Khlong Phon",
          "postCode": [
            "81170"
          ]
        },
        {
          "id": 810402,
          "name": "Khlong Thom Nuea",
          "postCode": [
            "81120"
          ]
        },
        {
          "id": 810401,
          "name": "Khlong Thom Tai",
          "postCode": [
            "81120"
          ]
        },
        {
          "id": 810407,
          "name": "Phela",
          "postCode": [
            "81120"
          ]
        },
        {
          "id": 810406,
          "name": "Phru Din Na",
          "postCode": [
            "81120"
          ]
        },
        {
          "id": 810404,
          "name": "Sai Khao",
          "postCode": [
            "81170"
          ]
        }
      ]
    },
    {
      "city": "Ko Lanta",
      "cityCode": 8103,
      "provinceCode": 81,
      "subDistrict": [
        {
          "id": 810304,
          "name": "Khlong Yang",
          "postCode": [
            "81120"
          ]
        },
        {
          "id": 810303,
          "name": "Ko Klang",
          "postCode": [
            "81120"
          ]
        },
        {
          "id": 810302,
          "name": "Ko Lanta Noi",
          "postCode": [
            "81150"
          ]
        },
        {
          "id": 810301,
          "name": "Ko Lanta Yai",
          "postCode": [
            "81150"
          ]
        },
        {
          "id": 810305,
          "name": "Sala Dan",
          "postCode": [
            "81150"
          ]
        }
      ]
    },
    {
      "city": "Lam Thap",
      "cityCode": 8107,
      "provinceCode": 81,
      "subDistrict": [
        {
          "id": 810704,
          "name": "Din Daeng",
          "postCode": [
            "81190"
          ]
        },
        {
          "id": 810702,
          "name": "Din Udom",
          "postCode": [
            "81190"
          ]
        },
        {
          "id": 810701,
          "name": "Lam Thap",
          "postCode": [
            "81190"
          ]
        },
        {
          "id": 810703,
          "name": "Thung Sai Thong",
          "postCode": [
            "81190"
          ]
        }
      ]
    },
    {
      "city": "Mueang Krabi",
      "cityCode": 8101,
      "provinceCode": 81,
      "subDistrict": [
        {
          "id": 810116,
          "name": "Ao Nang",
          "postCode": [
            "81000"
          ]
        },
        {
          "id": 810105,
          "name": "Khao Khram",
          "postCode": [
            "81000"
          ]
        },
        {
          "id": 810106,
          "name": "Khao Thong",
          "postCode": [
            "81000"
          ]
        },
        {
          "id": 810118,
          "name": "Khlong Prasong",
          "postCode": [
            "81000"
          ]
        },
        {
          "id": 810103,
          "name": "Krabi Noi",
          "postCode": [
            "81000"
          ]
        },
        {
          "id": 810102,
          "name": "Krabi Yai",
          "postCode": [
            "81000"
          ]
        },
        {
          "id": 810117,
          "name": "Nong Thale",
          "postCode": [
            "81000"
          ]
        },
        {
          "id": 810101,
          "name": "Pak Nam",
          "postCode": [
            "81000"
          ]
        },
        {
          "id": 810115,
          "name": "Sai Thai",
          "postCode": [
            "81000"
          ]
        },
        {
          "id": 810111,
          "name": "Thap Prik",
          "postCode": [
            "81000"
          ]
        }
      ]
    },
    {
      "city": "Nuea Khlong",
      "cityCode": 8108,
      "provinceCode": 81,
      "subDistrict": [
        {
          "id": 810808,
          "name": "Huai Yung",
          "postCode": [
            "81130"
          ]
        },
        {
          "id": 810804,
          "name": "Khlong Khamao",
          "postCode": [
            "81130"
          ]
        },
        {
          "id": 810803,
          "name": "Khlong Khanan",
          "postCode": [
            "81130"
          ]
        },
        {
          "id": 810805,
          "name": "Khok Yang",
          "postCode": [
            "81130"
          ]
        },
        {
          "id": 810802,
          "name": "Ko Siboya",
          "postCode": [
            "81130"
          ]
        },
        {
          "id": 810801,
          "name": "Nuea Khlong",
          "postCode": [
            "81130"
          ]
        },
        {
          "id": 810807,
          "name": "Pakasai",
          "postCode": [
            "81130"
          ]
        },
        {
          "id": 810806,
          "name": "Taling Chan",
          "postCode": [
            "81130"
          ]
        }
      ]
    },
    {
      "city": "Plai Phraya",
      "cityCode": 8106,
      "provinceCode": 81,
      "subDistrict": [
        {
          "id": 810602,
          "name": "Khao Khen",
          "postCode": [
            "81160"
          ]
        },
        {
          "id": 810603,
          "name": "Khao To",
          "postCode": [
            "81160"
          ]
        },
        {
          "id": 810604,
          "name": "Khiri Wong",
          "postCode": [
            "81160"
          ]
        },
        {
          "id": 810601,
          "name": "Plai Phraya",
          "postCode": [
            "81160"
          ]
        }
      ]
    },
    {
      "city": "Chae Hom",
      "cityCode": 5206,
      "provinceCode": 52,
      "subDistrict": [
        {
          "id": 520602,
          "name": "Ban Sa",
          "postCode": [
            "52120"
          ]
        },
        {
          "id": 520601,
          "name": "Chae Hom",
          "postCode": [
            "52120"
          ]
        },
        {
          "id": 520604,
          "name": "Mae Suk",
          "postCode": [
            "52120"
          ]
        },
        {
          "id": 520605,
          "name": "Mueang Mai",
          "postCode": [
            "52120"
          ]
        },
        {
          "id": 520603,
          "name": "Pong Don",
          "postCode": [
            "52120"
          ]
        },
        {
          "id": 520606,
          "name": "Thung Phueng",
          "postCode": [
            "52120"
          ]
        },
        {
          "id": 520607,
          "name": "Wichet Nakhon",
          "postCode": [
            "52120"
          ]
        }
      ]
    },
    {
      "city": "Hang Chat",
      "cityCode": 5212,
      "provinceCode": 52,
      "subDistrict": [
        {
          "id": 521201,
          "name": "Hang Chat",
          "postCode": [
            "52190"
          ]
        },
        {
          "id": 521206,
          "name": "Mae San",
          "postCode": [
            "52190"
          ]
        },
        {
          "id": 521203,
          "name": "Mueang Yao",
          "postCode": [
            "52190"
          ]
        },
        {
          "id": 521202,
          "name": "Nong Lom",
          "postCode": [
            "52190"
          ]
        },
        {
          "id": 521204,
          "name": "Pong Yang Khok",
          "postCode": [
            "52190"
          ]
        },
        {
          "id": 521205,
          "name": "Wiang Tan",
          "postCode": [
            "52190"
          ]
        },
        {
          "id": 521207,
          "name": "Wo Kaeo",
          "postCode": [
            "52190"
          ]
        }
      ]
    },
    {
      "city": "Ko Kha",
      "cityCode": 5203,
      "provinceCode": 52,
      "subDistrict": [
        {
          "id": 520306,
          "name": "Ko Kha",
          "postCode": [
            "52130"
          ]
        },
        {
          "id": 520303,
          "name": "Lai Hin",
          "postCode": [
            "52130"
          ]
        },
        {
          "id": 520301,
          "name": "Lampang Luang",
          "postCode": [
            "52130"
          ]
        },
        {
          "id": 520309,
          "name": "Mai Phatthana",
          "postCode": [
            "52130"
          ]
        },
        {
          "id": 520302,
          "name": "Na Kaeo",
          "postCode": [
            "52130"
          ]
        },
        {
          "id": 520307,
          "name": "Na Saeng",
          "postCode": [
            "52130"
          ]
        },
        {
          "id": 520305,
          "name": "Sala",
          "postCode": [
            "52130"
          ]
        },
        {
          "id": 520308,
          "name": "Tha Pha",
          "postCode": [
            "52130"
          ]
        },
        {
          "id": 520304,
          "name": "Wang Phrao",
          "postCode": [
            "52130"
          ]
        }
      ]
    },
    {
      "city": "Mae Mo",
      "cityCode": 5202,
      "provinceCode": 52,
      "subDistrict": [
        {
          "id": 520201,
          "name": "Ban Dong",
          "postCode": [
            "52000",
            "52220"
          ]
        },
        {
          "id": 520203,
          "name": "Chang Nuea",
          "postCode": [
            "52220"
          ]
        },
        {
          "id": 520204,
          "name": "Mae Mo",
          "postCode": [
            "52220"
          ]
        },
        {
          "id": 520202,
          "name": "Na Sak",
          "postCode": [
            "52220"
          ]
        },
        {
          "id": 520205,
          "name": "Sop Pat",
          "postCode": [
            "52220"
          ]
        }
      ]
    },
    {
      "city": "Mae Phrik",
      "cityCode": 5209,
      "provinceCode": 52,
      "subDistrict": [
        {
          "id": 520901,
          "name": "Mae Phrik",
          "postCode": [
            "52180"
          ]
        },
        {
          "id": 520903,
          "name": "Mae Pu",
          "postCode": [
            "52180"
          ]
        },
        {
          "id": 520902,
          "name": "Pha Pang",
          "postCode": [
            "52180"
          ]
        },
        {
          "id": 520904,
          "name": "Phra Bat Wang Tuang",
          "postCode": [
            "52180",
            "52230"
          ]
        }
      ]
    },
    {
      "city": "Mae Tha",
      "cityCode": 5210,
      "provinceCode": 52,
      "subDistrict": [
        {
          "id": 521005,
          "name": "Ban Bom",
          "postCode": [
            "52150"
          ]
        },
        {
          "id": 521004,
          "name": "Ban Kio",
          "postCode": [
            "52150"
          ]
        },
        {
          "id": 521007,
          "name": "Don Fai",
          "postCode": [
            "52150"
          ]
        },
        {
          "id": 521008,
          "name": "Hua Suea",
          "postCode": [
            "52150"
          ]
        },
        {
          "id": 521001,
          "name": "Mae Tha",
          "postCode": [
            "52150",
            "52220"
          ]
        },
        {
          "id": 521002,
          "name": "Na Khrua",
          "postCode": [
            "52150"
          ]
        },
        {
          "id": 521006,
          "name": "Nam Cho",
          "postCode": [
            "52150"
          ]
        },
        {
          "id": 521003,
          "name": "Pa Tan",
          "postCode": [
            "52150"
          ]
        },
        {
          "id": 521011,
          "name": "San Don Kaeo",
          "postCode": [
            "52150"
          ]
        },
        {
          "id": 521010,
          "name": "Wang Ngoen",
          "postCode": [
            "52150"
          ]
        }
      ]
    },
    {
      "city": "Mueang Lampang",
      "cityCode": 5201,
      "provinceCode": 52,
      "subDistrict": [
        {
          "id": 520115,
          "name": "Ban Kha",
          "postCode": [
            "52100"
          ]
        },
        {
          "id": 520109,
          "name": "Ban Laeng",
          "postCode": [
            "52000"
          ]
        },
        {
          "id": 520114,
          "name": "Ban Pao",
          "postCode": [
            "52100"
          ]
        },
        {
          "id": 520110,
          "name": "Ban Sadet",
          "postCode": [
            "52000"
          ]
        },
        {
          "id": 520113,
          "name": "Ban Ueam",
          "postCode": [
            "52100"
          ]
        },
        {
          "id": 520116,
          "name": "Bo Haeo",
          "postCode": [
            "52100"
          ]
        },
        {
          "id": 520119,
          "name": "Bunnak Phatthana",
          "postCode": [
            "52000"
          ]
        },
        {
          "id": 520106,
          "name": "Chomphu",
          "postCode": [
            "52100"
          ]
        },
        {
          "id": 520102,
          "name": "Hua Wiang",
          "postCode": [
            "52000"
          ]
        },
        {
          "id": 520107,
          "name": "Kluai Phae",
          "postCode": [
            "52000"
          ]
        },
        {
          "id": 520118,
          "name": "Nikhom Phatthana",
          "postCode": [
            "52000"
          ]
        },
        {
          "id": 520111,
          "name": "Phichai",
          "postCode": [
            "52000"
          ]
        },
        {
          "id": 520105,
          "name": "Phra Bat",
          "postCode": [
            "52000",
            "52220"
          ]
        },
        {
          "id": 520108,
          "name": "Pong Saen Thong",
          "postCode": [
            "52100"
          ]
        },
        {
          "id": 520104,
          "name": "Sop Tui",
          "postCode": [
            "52100"
          ]
        },
        {
          "id": 520103,
          "name": "Suan Dok",
          "postCode": [
            "52100"
          ]
        },
        {
          "id": 520112,
          "name": "Thung Fai",
          "postCode": [
            "52000"
          ]
        },
        {
          "id": 520117,
          "name": "Ton Thong Chai",
          "postCode": [
            "52000"
          ]
        },
        {
          "id": 520101,
          "name": "Wiang Nuea",
          "postCode": [
            "52000"
          ]
        }
      ]
    },
    {
      "city": "Mueang Pan",
      "cityCode": 5213,
      "provinceCode": 52,
      "subDistrict": [
        {
          "id": 521302,
          "name": "Ban Kho",
          "postCode": [
            "52240"
          ]
        },
        {
          "id": 521304,
          "name": "Chae Son",
          "postCode": [
            "52240"
          ]
        },
        {
          "id": 521305,
          "name": "Hua Mueang",
          "postCode": [
            "52240"
          ]
        },
        {
          "id": 521301,
          "name": "Mueang Pan",
          "postCode": [
            "52240"
          ]
        },
        {
          "id": 521303,
          "name": "Thung Kwao",
          "postCode": [
            "52240"
          ]
        }
      ]
    },
    {
      "city": "Ngao",
      "cityCode": 5205,
      "provinceCode": 52,
      "subDistrict": [
        {
          "id": 520508,
          "name": "Ban Haeng",
          "postCode": [
            "52110"
          ]
        },
        {
          "id": 520509,
          "name": "Ban Huat",
          "postCode": [
            "52110"
          ]
        },
        {
          "id": 520507,
          "name": "Ban On",
          "postCode": [
            "52110"
          ]
        },
        {
          "id": 520503,
          "name": "Ban Pong",
          "postCode": [
            "52110"
          ]
        },
        {
          "id": 520504,
          "name": "Ban Rong",
          "postCode": [
            "52110"
          ]
        },
        {
          "id": 520501,
          "name": "Luang Nuea",
          "postCode": [
            "52110"
          ]
        },
        {
          "id": 520502,
          "name": "Luang Tai",
          "postCode": [
            "52110"
          ]
        },
        {
          "id": 520510,
          "name": "Mae Tip",
          "postCode": [
            "52110"
          ]
        },
        {
          "id": 520506,
          "name": "Na Kae",
          "postCode": [
            "52110"
          ]
        },
        {
          "id": 520505,
          "name": "Pong Tao",
          "postCode": [
            "52110"
          ]
        }
      ]
    },
    {
      "city": "Soem Ngam",
      "cityCode": 5204,
      "provinceCode": 52,
      "subDistrict": [
        {
          "id": 520402,
          "name": "Soem Khwa",
          "postCode": [
            "52210"
          ]
        },
        {
          "id": 520404,
          "name": "Soem Klang",
          "postCode": [
            "52210"
          ]
        },
        {
          "id": 520403,
          "name": "Soem Sai",
          "postCode": [
            "52210"
          ]
        },
        {
          "id": 520401,
          "name": "Thung Ngam",
          "postCode": [
            "52210"
          ]
        }
      ]
    },
    {
      "city": "Sop Prap",
      "cityCode": 5211,
      "provinceCode": 52,
      "subDistrict": [
        {
          "id": 521103,
          "name": "Mae Kua",
          "postCode": [
            "52170"
          ]
        },
        {
          "id": 521104,
          "name": "Na Yang",
          "postCode": [
            "52170"
          ]
        },
        {
          "id": 521102,
          "name": "Samai",
          "postCode": [
            "52170"
          ]
        },
        {
          "id": 521101,
          "name": "Sop Prap",
          "postCode": [
            "52170"
          ]
        }
      ]
    },
    {
      "city": "Thoen",
      "cityCode": 5208,
      "provinceCode": 52,
      "subDistrict": [
        {
          "id": 520801,
          "name": "Lom Raet",
          "postCode": [
            "52160"
          ]
        },
        {
          "id": 520804,
          "name": "Mae Mok",
          "postCode": [
            "52160"
          ]
        },
        {
          "id": 520803,
          "name": "Mae Pa",
          "postCode": [
            "52160"
          ]
        },
        {
          "id": 520807,
          "name": "Mae Thot",
          "postCode": [
            "52160"
          ]
        },
        {
          "id": 520802,
          "name": "Mae Wa",
          "postCode": [
            "52230"
          ]
        },
        {
          "id": 520806,
          "name": "Na Pong",
          "postCode": [
            "52160"
          ]
        },
        {
          "id": 520808,
          "name": "Thoen Buri",
          "postCode": [
            "52160"
          ]
        },
        {
          "id": 520805,
          "name": "Wiang Mok",
          "postCode": [
            "52160"
          ]
        }
      ]
    },
    {
      "city": "Wang Nuea",
      "cityCode": 5207,
      "provinceCode": 52,
      "subDistrict": [
        {
          "id": 520704,
          "name": "Rong Kho",
          "postCode": [
            "52140"
          ]
        },
        {
          "id": 520701,
          "name": "Thung Hua",
          "postCode": [
            "52140"
          ]
        },
        {
          "id": 520707,
          "name": "Wang Kaeo",
          "postCode": [
            "52140"
          ]
        },
        {
          "id": 520702,
          "name": "Wang Nuea",
          "postCode": [
            "52140"
          ]
        },
        {
          "id": 520706,
          "name": "Wang Sai",
          "postCode": [
            "52140"
          ]
        },
        {
          "id": 520708,
          "name": "Wang Sai Kham",
          "postCode": [
            "52140"
          ]
        },
        {
          "id": 520703,
          "name": "Wang Tai",
          "postCode": [
            "52140"
          ]
        },
        {
          "id": 520705,
          "name": "Wang Thong",
          "postCode": [
            "52140"
          ]
        }
      ]
    },
    {
      "city": "Ban Hong",
      "cityCode": 5103,
      "provinceCode": 51,
      "subDistrict": [
        {
          "id": 510301,
          "name": "Ban Hong",
          "postCode": [
            "51130"
          ]
        },
        {
          "id": 510303,
          "name": "Lao Yao",
          "postCode": [
            "51130"
          ]
        },
        {
          "id": 510305,
          "name": "Nong Pla Sawai",
          "postCode": [
            "51130"
          ]
        },
        {
          "id": 510302,
          "name": "Pa Phlu",
          "postCode": [
            "51130"
          ]
        },
        {
          "id": 510304,
          "name": "Si Tia",
          "postCode": [
            "51130"
          ]
        }
      ]
    },
    {
      "city": "Ban Thi",
      "cityCode": 5107,
      "provinceCode": 51,
      "subDistrict": [
        {
          "id": 510701,
          "name": "Ban Thi",
          "postCode": [
            "51180"
          ]
        },
        {
          "id": 510702,
          "name": "Huai Yap",
          "postCode": [
            "51180"
          ]
        }
      ]
    },
    {
      "city": "Li",
      "cityCode": 5104,
      "provinceCode": 51,
      "subDistrict": [
        {
          "id": 510404,
          "name": "Dong Dam",
          "postCode": [
            "51110"
          ]
        },
        {
          "id": 510405,
          "name": "Ko",
          "postCode": [
            "51110"
          ]
        },
        {
          "id": 510401,
          "name": "Li",
          "postCode": [
            "51110"
          ]
        },
        {
          "id": 510406,
          "name": "Mae Lan",
          "postCode": [
            "51110"
          ]
        },
        {
          "id": 510402,
          "name": "Mae Tuen",
          "postCode": [
            "51110"
          ]
        },
        {
          "id": 510403,
          "name": "Na Sai",
          "postCode": [
            "51110"
          ]
        },
        {
          "id": 510408,
          "name": "Pha Phai",
          "postCode": [
            "51110"
          ]
        },
        {
          "id": 510409,
          "name": "Si Wichai",
          "postCode": [
            "51110"
          ]
        }
      ]
    },
    {
      "city": "Mae Tha",
      "cityCode": 5102,
      "provinceCode": 51,
      "subDistrict": [
        {
          "id": 510203,
          "name": "Tha Kat",
          "postCode": [
            "51170"
          ]
        },
        {
          "id": 510204,
          "name": "Tha Khum Ngoen",
          "postCode": [
            "51170"
          ]
        },
        {
          "id": 510206,
          "name": "Tha Mae Lop",
          "postCode": [
            "51170"
          ]
        },
        {
          "id": 510201,
          "name": "Tha Pla Duk",
          "postCode": [
            "51140"
          ]
        },
        {
          "id": 510202,
          "name": "Tha Sop Sao",
          "postCode": [
            "51140"
          ]
        },
        {
          "id": 510205,
          "name": "Tha Thung Luang",
          "postCode": [
            "51170"
          ]
        }
      ]
    },
    {
      "city": "Mueang Lamphun",
      "cityCode": 5101,
      "provinceCode": 51,
      "subDistrict": [
        {
          "id": 510112,
          "name": "Ban Klang",
          "postCode": [
            "51000"
          ]
        },
        {
          "id": 510108,
          "name": "Ban Paen",
          "postCode": [
            "51000"
          ]
        },
        {
          "id": 510113,
          "name": "Makhuea Chae",
          "postCode": [
            "51000"
          ]
        },
        {
          "id": 510109,
          "name": "Mueang Chi",
          "postCode": [
            "51000"
          ]
        },
        {
          "id": 510102,
          "name": "Mueang Nga",
          "postCode": [
            "51000"
          ]
        },
        {
          "id": 510101,
          "name": "Nai Mueang",
          "postCode": [
            "51000"
          ]
        },
        {
          "id": 510104,
          "name": "Nong Chang Khuen",
          "postCode": [
            "51150"
          ]
        },
        {
          "id": 510117,
          "name": "Nong Nam",
          "postCode": [
            "51000"
          ]
        },
        {
          "id": 510110,
          "name": "Pa Sak",
          "postCode": [
            "51000"
          ]
        },
        {
          "id": 510105,
          "name": "Pratu Pa",
          "postCode": [
            "51000"
          ]
        },
        {
          "id": 510106,
          "name": "Rim Ping",
          "postCode": [
            "51000"
          ]
        },
        {
          "id": 510116,
          "name": "Si Bua Ban",
          "postCode": [
            "51000"
          ]
        },
        {
          "id": 510107,
          "name": "Ton Thong",
          "postCode": [
            "51000"
          ]
        },
        {
          "id": 510103,
          "name": "Umong",
          "postCode": [
            "51150"
          ]
        },
        {
          "id": 510111,
          "name": "Wiang Yong",
          "postCode": [
            "51000"
          ]
        }
      ]
    },
    {
      "city": "Pa Sang",
      "cityCode": 5106,
      "provinceCode": 51,
      "subDistrict": [
        {
          "id": 510605,
          "name": "Ban Ruean",
          "postCode": [
            "51120"
          ]
        },
        {
          "id": 510603,
          "name": "Mae Raeng",
          "postCode": [
            "51120"
          ]
        },
        {
          "id": 510606,
          "name": "Makok",
          "postCode": [
            "51120"
          ]
        },
        {
          "id": 510604,
          "name": "Muang Noi",
          "postCode": [
            "51120"
          ]
        },
        {
          "id": 510611,
          "name": "Nakhon Chedi",
          "postCode": [
            "51120"
          ]
        },
        {
          "id": 510608,
          "name": "Nam Dip",
          "postCode": [
            "51120"
          ]
        },
        {
          "id": 510602,
          "name": "Pa Sang",
          "postCode": [
            "51120"
          ]
        },
        {
          "id": 510601,
          "name": "Pak Bong",
          "postCode": [
            "51120"
          ]
        },
        {
          "id": 510607,
          "name": "Tha Tum",
          "postCode": [
            "51120"
          ]
        }
      ]
    },
    {
      "city": "Thung Hua Chang",
      "cityCode": 5105,
      "provinceCode": 51,
      "subDistrict": [
        {
          "id": 510502,
          "name": "Ban Puang",
          "postCode": [
            "51160"
          ]
        },
        {
          "id": 510503,
          "name": "Takhian Pom",
          "postCode": [
            "51160"
          ]
        },
        {
          "id": 510501,
          "name": "Thung Hua Chang",
          "postCode": [
            "51160"
          ]
        }
      ]
    },
    {
      "city": "Wiang Nong Long",
      "cityCode": 5108,
      "provinceCode": 51,
      "subDistrict": [
        {
          "id": 510801,
          "name": "Nong Long",
          "postCode": [
            "51120"
          ]
        },
        {
          "id": 510802,
          "name": "Nong Yuang",
          "postCode": [
            "51120"
          ]
        },
        {
          "id": 510803,
          "name": "Wang Phang",
          "postCode": [
            "51120"
          ]
        }
      ]
    },
    {
      "city": "Chiang Khan",
      "cityCode": 4203,
      "provinceCode": 42,
      "subDistrict": [
        {
          "id": 420306,
          "name": "Bu Hom",
          "postCode": [
            "42110"
          ]
        },
        {
          "id": 420301,
          "name": "Chiang Khan",
          "postCode": [
            "42110"
          ]
        },
        {
          "id": 420307,
          "name": "Chom Si",
          "postCode": [
            "42110"
          ]
        },
        {
          "id": 420308,
          "name": "Hat Sai Khao",
          "postCode": [
            "42110"
          ]
        },
        {
          "id": 420304,
          "name": "Khao Kaeo",
          "postCode": [
            "42110"
          ]
        },
        {
          "id": 420303,
          "name": "Na Sao",
          "postCode": [
            "42110"
          ]
        },
        {
          "id": 420305,
          "name": "Pak Tom",
          "postCode": [
            "42110"
          ]
        },
        {
          "id": 420302,
          "name": "That",
          "postCode": [
            "42110"
          ]
        }
      ]
    },
    {
      "city": "Dan Sai",
      "cityCode": 4205,
      "provinceCode": 42,
      "subDistrict": [
        {
          "id": 420501,
          "name": "Dan Sai",
          "postCode": [
            "42120"
          ]
        },
        {
          "id": 420506,
          "name": "Ipum",
          "postCode": [
            "42120"
          ]
        },
        {
          "id": 420504,
          "name": "Khok Ngam",
          "postCode": [
            "42120"
          ]
        },
        {
          "id": 420507,
          "name": "Kok Sathon",
          "postCode": [
            "42120"
          ]
        },
        {
          "id": 420503,
          "name": "Na Di",
          "postCode": [
            "42120"
          ]
        },
        {
          "id": 420510,
          "name": "Na Ho",
          "postCode": [
            "42120"
          ]
        },
        {
          "id": 420502,
          "name": "Pak Man",
          "postCode": [
            "42120"
          ]
        },
        {
          "id": 420505,
          "name": "Phon Sung",
          "postCode": [
            "42120"
          ]
        },
        {
          "id": 420508,
          "name": "Pong",
          "postCode": [
            "42120"
          ]
        },
        {
          "id": 420509,
          "name": "Wang Yao",
          "postCode": [
            "42120"
          ]
        }
      ]
    },
    {
      "city": "Erawan",
      "cityCode": 4213,
      "provinceCode": 42,
      "subDistrict": [
        {
          "id": 421301,
          "name": "Erawan",
          "postCode": [
            "42220"
          ]
        },
        {
          "id": 421302,
          "name": "Pha In Plaeng",
          "postCode": [
            "42220"
          ]
        },
        {
          "id": 421303,
          "name": "Pha Sam Yot",
          "postCode": [
            "42220"
          ]
        },
        {
          "id": 421304,
          "name": "Sap Phaiwan",
          "postCode": [
            "42220"
          ]
        }
      ]
    },
    {
      "city": "Mueang Loei",
      "cityCode": 4201,
      "provinceCode": 42,
      "subDistrict": [
        {
          "id": 420111,
          "name": "Chaiyaphruek",
          "postCode": [
            "42000"
          ]
        },
        {
          "id": 420104,
          "name": "Kok Du",
          "postCode": [
            "42000"
          ]
        },
        {
          "id": 420114,
          "name": "Kok Thong",
          "postCode": [
            "42000"
          ]
        },
        {
          "id": 420101,
          "name": "Kut Pong",
          "postCode": [
            "42000"
          ]
        },
        {
          "id": 420102,
          "name": "Mueang",
          "postCode": [
            "42000"
          ]
        },
        {
          "id": 420107,
          "name": "Na An",
          "postCode": [
            "42000"
          ]
        },
        {
          "id": 420109,
          "name": "Na Din Dam",
          "postCode": [
            "42000"
          ]
        },
        {
          "id": 420112,
          "name": "Na Khaem",
          "postCode": [
            "42000"
          ]
        },
        {
          "id": 420103,
          "name": "Na O",
          "postCode": [
            "42100"
          ]
        },
        {
          "id": 420108,
          "name": "Na Pong",
          "postCode": [
            "42000"
          ]
        },
        {
          "id": 420105,
          "name": "Nam Man",
          "postCode": [
            "42000"
          ]
        },
        {
          "id": 420110,
          "name": "Nam Suai",
          "postCode": [
            "42000"
          ]
        },
        {
          "id": 420113,
          "name": "Si Song Rak",
          "postCode": [
            "42100"
          ]
        },
        {
          "id": 420106,
          "name": "Siao",
          "postCode": [
            "42000"
          ]
        }
      ]
    },
    {
      "city": "Na Duang",
      "cityCode": 4202,
      "provinceCode": 42,
      "subDistrict": [
        {
          "id": 420202,
          "name": "Na Dok Kham",
          "postCode": [
            "42210"
          ]
        },
        {
          "id": 420201,
          "name": "Na Duang",
          "postCode": [
            "42210"
          ]
        },
        {
          "id": 420203,
          "name": "Tha Sa-at",
          "postCode": [
            "42210"
          ]
        },
        {
          "id": 420204,
          "name": "Tha Sawan",
          "postCode": [
            "42210"
          ]
        }
      ]
    },
    {
      "city": "Na Haeo",
      "cityCode": 4206,
      "provinceCode": 42,
      "subDistrict": [
        {
          "id": 420605,
          "name": "Lao Ko Hok",
          "postCode": [
            "42170"
          ]
        },
        {
          "id": 420601,
          "name": "Na Haeo",
          "postCode": [
            "42170"
          ]
        },
        {
          "id": 420604,
          "name": "Na Ma La",
          "postCode": [
            "42170"
          ]
        },
        {
          "id": 420603,
          "name": "Na Phueng",
          "postCode": [
            "42170"
          ]
        },
        {
          "id": 420602,
          "name": "Saeng Pha",
          "postCode": [
            "42170"
          ]
        }
      ]
    },
    {
      "city": "Nong Hin",
      "cityCode": 4214,
      "provinceCode": 42,
      "subDistrict": [
        {
          "id": 421401,
          "name": "Nong Hin",
          "postCode": [
            "42190"
          ]
        },
        {
          "id": 421403,
          "name": "Puan Phu",
          "postCode": [
            "42190"
          ]
        },
        {
          "id": 421402,
          "name": "Tat Kha",
          "postCode": [
            "42190"
          ]
        }
      ]
    },
    {
      "city": "Pak Chom",
      "cityCode": 4204,
      "provinceCode": 42,
      "subDistrict": [
        {
          "id": 420402,
          "name": "Chiang Klom",
          "postCode": [
            "42150"
          ]
        },
        {
          "id": 420406,
          "name": "Chom Charoen",
          "postCode": [
            "42150"
          ]
        },
        {
          "id": 420403,
          "name": "Hat Khamphi",
          "postCode": [
            "42150"
          ]
        },
        {
          "id": 420404,
          "name": "Huai Bo Suen",
          "postCode": [
            "42150"
          ]
        },
        {
          "id": 420405,
          "name": "Huai Phichai",
          "postCode": [
            "42150"
          ]
        },
        {
          "id": 420401,
          "name": "Pak Chom",
          "postCode": [
            "42150"
          ]
        }
      ]
    },
    {
      "city": "Pha Khao",
      "cityCode": 4212,
      "provinceCode": 42,
      "subDistrict": [
        {
          "id": 421205,
          "name": "Ban Phoem",
          "postCode": [
            "42240"
          ]
        },
        {
          "id": 421204,
          "name": "Non Pa Sang",
          "postCode": [
            "42240"
          ]
        },
        {
          "id": 421203,
          "name": "Non Po Daeng",
          "postCode": [
            "42240"
          ]
        },
        {
          "id": 421201,
          "name": "Pha Khao",
          "postCode": [
            "42240"
          ]
        },
        {
          "id": 421202,
          "name": "Tha Chang Khlong",
          "postCode": [
            "42240"
          ]
        }
      ]
    },
    {
      "city": "Phu Kradueng",
      "cityCode": 4210,
      "provinceCode": 42,
      "subDistrict": [
        {
          "id": 421010,
          "name": "Huai Som",
          "postCode": [
            "42180"
          ]
        },
        {
          "id": 421005,
          "name": "Pha Nok Khao",
          "postCode": [
            "42180"
          ]
        },
        {
          "id": 421007,
          "name": "Phu Kradueng",
          "postCode": [
            "42180"
          ]
        },
        {
          "id": 421001,
          "name": "Si Than",
          "postCode": [
            "42180"
          ]
        }
      ]
    },
    {
      "city": "Phu Luang",
      "cityCode": 4211,
      "provinceCode": 42,
      "subDistrict": [
        {
          "id": 421104,
          "name": "Huai Sisiat",
          "postCode": [
            "42230"
          ]
        },
        {
          "id": 421106,
          "name": "Kaeng Si Phum",
          "postCode": [
            "42230"
          ]
        },
        {
          "id": 421105,
          "name": "Loei Wang Sai",
          "postCode": [
            "42230"
          ]
        },
        {
          "id": 421102,
          "name": "Nong Khan",
          "postCode": [
            "42230"
          ]
        },
        {
          "id": 421101,
          "name": "Phu Ho",
          "postCode": [
            "42230"
          ]
        }
      ]
    },
    {
      "city": "Phu Ruea",
      "cityCode": 4207,
      "provinceCode": 42,
      "subDistrict": [
        {
          "id": 420705,
          "name": "Lat Khang",
          "postCode": [
            "42160"
          ]
        },
        {
          "id": 420701,
          "name": "Nong Bua",
          "postCode": [
            "42160"
          ]
        },
        {
          "id": 420704,
          "name": "Pla Ba",
          "postCode": [
            "42160"
          ]
        },
        {
          "id": 420703,
          "name": "Rong Chik",
          "postCode": [
            "42160"
          ]
        },
        {
          "id": 420706,
          "name": "San Tom",
          "postCode": [
            "42160"
          ]
        },
        {
          "id": 420702,
          "name": "Tha Sala",
          "postCode": [
            "42160"
          ]
        }
      ]
    },
    {
      "city": "Tha Li",
      "cityCode": 4208,
      "provinceCode": 42,
      "subDistrict": [
        {
          "id": 420803,
          "name": "Ahi",
          "postCode": [
            "42140"
          ]
        },
        {
          "id": 420805,
          "name": "Khok Yai",
          "postCode": [
            "42140"
          ]
        },
        {
          "id": 420804,
          "name": "Nam Khaem",
          "postCode": [
            "42140"
          ]
        },
        {
          "id": 420806,
          "name": "Nam Thun",
          "postCode": [
            "42140"
          ]
        },
        {
          "id": 420802,
          "name": "Nong Phue",
          "postCode": [
            "42140"
          ]
        },
        {
          "id": 420801,
          "name": "Tha Li",
          "postCode": [
            "42140"
          ]
        }
      ]
    },
    {
      "city": "Wang Saphung",
      "cityCode": 4209,
      "provinceCode": 42,
      "subDistrict": [
        {
          "id": 420911,
          "name": "Khao Luang",
          "postCode": [
            "42130"
          ]
        },
        {
          "id": 420912,
          "name": "Khok Khamin",
          "postCode": [
            "42130"
          ]
        },
        {
          "id": 420904,
          "name": "Nong Ngio",
          "postCode": [
            "42130"
          ]
        },
        {
          "id": 420903,
          "name": "Nong Ya Plong",
          "postCode": [
            "42130"
          ]
        },
        {
          "id": 420905,
          "name": "Pak Puan",
          "postCode": [
            "42130"
          ]
        },
        {
          "id": 420910,
          "name": "Pha Bing",
          "postCode": [
            "42130"
          ]
        },
        {
          "id": 420906,
          "name": "Pha Noi",
          "postCode": [
            "42130"
          ]
        },
        {
          "id": 420902,
          "name": "Sai Khao",
          "postCode": [
            "42130"
          ]
        },
        {
          "id": 420913,
          "name": "Si Songkhram",
          "postCode": [
            "42130"
          ]
        },
        {
          "id": 420901,
          "name": "Wang Saphung",
          "postCode": [
            "42130"
          ]
        }
      ]
    },
    {
      "city": "Ban Mi",
      "cityCode": 1606,
      "provinceCode": 16,
      "subDistrict": [
        {
          "id": 160605,
          "name": "Ban Chi",
          "postCode": [
            "15180"
          ]
        },
        {
          "id": 160603,
          "name": "Ban Kluai",
          "postCode": [
            "15110"
          ]
        },
        {
          "id": 160619,
          "name": "Ban Mi",
          "postCode": [
            "15110"
          ]
        },
        {
          "id": 160602,
          "name": "Ban Sai",
          "postCode": [
            "15110"
          ]
        },
        {
          "id": 160610,
          "name": "Bang Kaphi",
          "postCode": [
            "15110"
          ]
        },
        {
          "id": 160613,
          "name": "Bang Kham",
          "postCode": [
            "15180"
          ]
        },
        {
          "id": 160608,
          "name": "Bang Phueng",
          "postCode": [
            "15110",
            "15180"
          ]
        },
        {
          "id": 160620,
          "name": "Chiang Nga",
          "postCode": [
            "15110"
          ]
        },
        {
          "id": 160615,
          "name": "Chon Muang",
          "postCode": [
            "15110"
          ]
        },
        {
          "id": 160614,
          "name": "Don Dueng",
          "postCode": [
            "15110"
          ]
        },
        {
          "id": 160604,
          "name": "Dong Phlap",
          "postCode": [
            "15110"
          ]
        },
        {
          "id": 160607,
          "name": "Hin Pak",
          "postCode": [
            "15110"
          ]
        },
        {
          "id": 160618,
          "name": "Maha Son",
          "postCode": [
            "15110"
          ]
        },
        {
          "id": 160616,
          "name": "Nong Krabian",
          "postCode": [
            "15110"
          ]
        },
        {
          "id": 160621,
          "name": "Nong Mueang",
          "postCode": [
            "15110"
          ]
        },
        {
          "id": 160609,
          "name": "Nong Sai Khao",
          "postCode": [
            "15110"
          ]
        },
        {
          "id": 160611,
          "name": "Nong Tao",
          "postCode": [
            "15110"
          ]
        },
        {
          "id": 160601,
          "name": "Phai Yai",
          "postCode": [
            "15110"
          ]
        },
        {
          "id": 160612,
          "name": "Phon Thong",
          "postCode": [
            "15110"
          ]
        },
        {
          "id": 160606,
          "name": "Phu Kha",
          "postCode": [
            "15110"
          ]
        },
        {
          "id": 160617,
          "name": "Sai Huai Kaeo",
          "postCode": [
            "15110"
          ]
        },
        {
          "id": 160622,
          "name": "Sanam Chaeng",
          "postCode": [
            "15110"
          ]
        }
      ]
    },
    {
      "city": "Chai Badan",
      "cityCode": 1604,
      "provinceCode": 16,
      "subDistrict": [
        {
          "id": 160419,
          "name": "Ban Mai Samakkhi",
          "postCode": [
            "15130"
          ]
        },
        {
          "id": 160406,
          "name": "Bua Chum",
          "postCode": [
            "15130"
          ]
        },
        {
          "id": 160418,
          "name": "Chai Badan",
          "postCode": [
            "15230"
          ]
        },
        {
          "id": 160402,
          "name": "Chai Narai",
          "postCode": [
            "15130"
          ]
        },
        {
          "id": 160404,
          "name": "Huai Hin",
          "postCode": [
            "15130"
          ]
        },
        {
          "id": 160422,
          "name": "Khao Laem",
          "postCode": [
            "15130"
          ]
        },
        {
          "id": 160412,
          "name": "Ko Rang",
          "postCode": [
            "15130"
          ]
        },
        {
          "id": 160401,
          "name": "Lam Narai",
          "postCode": [
            "15130"
          ]
        },
        {
          "id": 160408,
          "name": "Makok Wan",
          "postCode": [
            "15230"
          ]
        },
        {
          "id": 160405,
          "name": "Muang Khom",
          "postCode": [
            "15230"
          ]
        },
        {
          "id": 160410,
          "name": "Na Som",
          "postCode": [
            "15190"
          ]
        },
        {
          "id": 160417,
          "name": "Nikhom Lam Narai",
          "postCode": [
            "15130"
          ]
        },
        {
          "id": 160411,
          "name": "Nong Yai To",
          "postCode": [
            "15130"
          ]
        },
        {
          "id": 160409,
          "name": "Sap Takhian",
          "postCode": [
            "15130"
          ]
        },
        {
          "id": 160403,
          "name": "Sila Thip",
          "postCode": [
            "15130"
          ]
        },
        {
          "id": 160407,
          "name": "Tha Din Dam",
          "postCode": [
            "15130"
          ]
        },
        {
          "id": 160414,
          "name": "Tha Manao",
          "postCode": [
            "15130"
          ]
        }
      ]
    },
    {
      "city": "Khok Charoen",
      "cityCode": 1609,
      "provinceCode": 16,
      "subDistrict": [
        {
          "id": 160901,
          "name": "Khok Charoen",
          "postCode": [
            "15250"
          ]
        },
        {
          "id": 160905,
          "name": "Khok Samae San",
          "postCode": [
            "15250"
          ]
        },
        {
          "id": 160903,
          "name": "Nong Makha",
          "postCode": [
            "15250"
          ]
        },
        {
          "id": 160904,
          "name": "Wang Thong",
          "postCode": [
            "15250"
          ]
        },
        {
          "id": 160902,
          "name": "Yang Rak",
          "postCode": [
            "15250"
          ]
        }
      ]
    },
    {
      "city": "Khok Samrong",
      "cityCode": 1603,
      "provinceCode": 16,
      "subDistrict": [
        {
          "id": 160310,
          "name": "Dong Marum",
          "postCode": [
            "15120"
          ]
        },
        {
          "id": 160305,
          "name": "Huai Pong",
          "postCode": [
            "15120"
          ]
        },
        {
          "id": 160306,
          "name": "Khlong Ket",
          "postCode": [
            "15120"
          ]
        },
        {
          "id": 160301,
          "name": "Khok Samrong",
          "postCode": [
            "15120"
          ]
        },
        {
          "id": 160302,
          "name": "Ko Kaeo",
          "postCode": [
            "15120"
          ]
        },
        {
          "id": 160304,
          "name": "Lum Khao",
          "postCode": [
            "15120"
          ]
        },
        {
          "id": 160322,
          "name": "Nong Khaem",
          "postCode": [
            "15120"
          ]
        },
        {
          "id": 160308,
          "name": "Phaniat",
          "postCode": [
            "15120"
          ]
        },
        {
          "id": 160307,
          "name": "Sakae Rap",
          "postCode": [
            "15120"
          ]
        },
        {
          "id": 160303,
          "name": "Thalung Lek",
          "postCode": [
            "15120"
          ]
        },
        {
          "id": 160320,
          "name": "Wang Chan",
          "postCode": [
            "15120"
          ]
        },
        {
          "id": 160318,
          "name": "Wang Khon Khwang",
          "postCode": [
            "15120"
          ]
        },
        {
          "id": 160309,
          "name": "Wang Phloeng",
          "postCode": [
            "15120"
          ]
        }
      ]
    },
    {
      "city": "Lam Sonthi",
      "cityCode": 1610,
      "provinceCode": 16,
      "subDistrict": [
        {
          "id": 161006,
          "name": "Khao Noi",
          "postCode": [
            "15130"
          ]
        },
        {
          "id": 161005,
          "name": "Khao Ruak",
          "postCode": [
            "15190"
          ]
        },
        {
          "id": 161004,
          "name": "Kut Ta Phet",
          "postCode": [
            "15190"
          ]
        },
        {
          "id": 161001,
          "name": "Lam Sonthi",
          "postCode": [
            "15190"
          ]
        },
        {
          "id": 161003,
          "name": "Nong Ri",
          "postCode": [
            "15190"
          ]
        },
        {
          "id": 161002,
          "name": "Sap Sombun",
          "postCode": [
            "15190"
          ]
        }
      ]
    },
    {
      "city": "Mueang Lop Buri",
      "cityCode": 1601,
      "provinceCode": 16,
      "subDistrict": [
        {
          "id": 160118,
          "name": "Ban Khoi",
          "postCode": [
            "15000"
          ]
        },
        {
          "id": 160117,
          "name": "Bang Khan Mak",
          "postCode": [
            "15000",
            "15160"
          ]
        },
        {
          "id": 160111,
          "name": "Don Pho",
          "postCode": [
            "15000"
          ]
        },
        {
          "id": 160105,
          "name": "Khao Phra Ngam",
          "postCode": [
            "15160"
          ]
        },
        {
          "id": 160106,
          "name": "Khao Sam Yot",
          "postCode": [
            "15000"
          ]
        },
        {
          "id": 160107,
          "name": "Khok Kathiam",
          "postCode": [
            "15160"
          ]
        },
        {
          "id": 160108,
          "name": "Khok Lam Phan",
          "postCode": [
            "15000"
          ]
        },
        {
          "id": 160109,
          "name": "Khok Tum",
          "postCode": [
            "15210"
          ]
        },
        {
          "id": 160103,
          "name": "Kok Ko",
          "postCode": [
            "15000"
          ]
        },
        {
          "id": 160104,
          "name": "Kong Thanu",
          "postCode": [
            "13240"
          ]
        },
        {
          "id": 160110,
          "name": "Ngio Rai",
          "postCode": [
            "15000"
          ]
        },
        {
          "id": 160116,
          "name": "Nikhom Sang Ton Eng",
          "postCode": [
            "15000"
          ]
        },
        {
          "id": 160120,
          "name": "Pa Tan",
          "postCode": [
            "15000"
          ]
        },
        {
          "id": 160122,
          "name": "Pho Kao Ton",
          "postCode": [
            "15000"
          ]
        },
        {
          "id": 160123,
          "name": "Pho Tru",
          "postCode": [
            "15000"
          ]
        },
        {
          "id": 160121,
          "name": "Phrommat",
          "postCode": [
            "15000"
          ]
        },
        {
          "id": 160124,
          "name": "Si Khlong",
          "postCode": [
            "15000"
          ]
        },
        {
          "id": 160112,
          "name": "Talung",
          "postCode": [
            "15000"
          ]
        },
        {
          "id": 160102,
          "name": "Tha Hin",
          "postCode": [
            "15000"
          ]
        },
        {
          "id": 160114,
          "name": "Tha Khae",
          "postCode": [
            "15000",
            "15160"
          ]
        },
        {
          "id": 160115,
          "name": "Tha Sala",
          "postCode": [
            "15000"
          ]
        },
        {
          "id": 160119,
          "name": "Thai Talat",
          "postCode": [
            "15000"
          ]
        },
        {
          "id": 160101,
          "name": "Thale Chup Son",
          "postCode": [
            "15000"
          ]
        },
        {
          "id": 160125,
          "name": "Thanon Yai",
          "postCode": [
            "15000"
          ]
        }
      ]
    },
    {
      "city": "Nong Muang",
      "cityCode": 1611,
      "provinceCode": 16,
      "subDistrict": [
        {
          "id": 161102,
          "name": "Bo Thong",
          "postCode": [
            "15170"
          ]
        },
        {
          "id": 161106,
          "name": "Chon Saradet",
          "postCode": [
            "15170"
          ]
        },
        {
          "id": 161104,
          "name": "Chon Sombun",
          "postCode": [
            "15170"
          ]
        },
        {
          "id": 161103,
          "name": "Dong Din Daeng",
          "postCode": [
            "15170"
          ]
        },
        {
          "id": 161101,
          "name": "Nong Muang",
          "postCode": [
            "15170"
          ]
        },
        {
          "id": 161105,
          "name": "Yang Thon",
          "postCode": [
            "15170"
          ]
        }
      ]
    },
    {
      "city": "Phatthana Nikhom",
      "cityCode": 1602,
      "provinceCode": 16,
      "subDistrict": [
        {
          "id": 160206,
          "name": "Chon Noi",
          "postCode": [
            "15140"
          ]
        },
        {
          "id": 160202,
          "name": "Chong Sarika",
          "postCode": [
            "15220"
          ]
        },
        {
          "id": 160204,
          "name": "Di Lang",
          "postCode": [
            "15220"
          ]
        },
        {
          "id": 160208,
          "name": "Huai Khun Ram",
          "postCode": [
            "18220"
          ]
        },
        {
          "id": 160205,
          "name": "Khok Salung",
          "postCode": [
            "15140"
          ]
        },
        {
          "id": 160203,
          "name": "Manao Wan",
          "postCode": [
            "15140"
          ]
        },
        {
          "id": 160209,
          "name": "Nam Sut",
          "postCode": [
            "15140"
          ]
        },
        {
          "id": 160207,
          "name": "Nong Bua",
          "postCode": [
            "15140"
          ]
        },
        {
          "id": 160201,
          "name": "Phatthana Nikhom",
          "postCode": [
            "15140"
          ]
        }
      ]
    },
    {
      "city": "Sa Bot",
      "cityCode": 1608,
      "provinceCode": 16,
      "subDistrict": [
        {
          "id": 160804,
          "name": "Huai Yai",
          "postCode": [
            "15240"
          ]
        },
        {
          "id": 160802,
          "name": "Maha Phot",
          "postCode": [
            "15240",
            "15250"
          ]
        },
        {
          "id": 160805,
          "name": "Niyom Chai",
          "postCode": [
            "15240"
          ]
        },
        {
          "id": 160801,
          "name": "Sa Bot",
          "postCode": [
            "15240"
          ]
        },
        {
          "id": 160803,
          "name": "Thung Tha Chang",
          "postCode": [
            "15240"
          ]
        }
      ]
    },
    {
      "city": "Tha Luang",
      "cityCode": 1607,
      "provinceCode": 16,
      "subDistrict": [
        {
          "id": 160706,
          "name": "Hua Lam",
          "postCode": [
            "15230"
          ]
        },
        {
          "id": 160702,
          "name": "Kaeng Phak Kut",
          "postCode": [
            "15230"
          ]
        },
        {
          "id": 160704,
          "name": "Nong Phak Waen",
          "postCode": [
            "15230"
          ]
        },
        {
          "id": 160703,
          "name": "Sap Champa",
          "postCode": [
            "15230"
          ]
        },
        {
          "id": 160701,
          "name": "Tha Luang",
          "postCode": [
            "15230"
          ]
        },
        {
          "id": 160705,
          "name": "Thale Wang Wat",
          "postCode": [
            "15230"
          ]
        }
      ]
    },
    {
      "city": "Tha Wung",
      "cityCode": 1605,
      "provinceCode": 16,
      "subDistrict": [
        {
          "id": 160510,
          "name": "Ban Boek",
          "postCode": [
            "15150"
          ]
        },
        {
          "id": 160502,
          "name": "Bang Khu",
          "postCode": [
            "15150"
          ]
        },
        {
          "id": 160504,
          "name": "Bang Li",
          "postCode": [
            "15150"
          ]
        },
        {
          "id": 160505,
          "name": "Bang Nga",
          "postCode": [
            "15150"
          ]
        },
        {
          "id": 160508,
          "name": "Hua Samrong",
          "postCode": [
            "15150"
          ]
        },
        {
          "id": 160507,
          "name": "Khao Samo Khon",
          "postCode": [
            "15180"
          ]
        },
        {
          "id": 160506,
          "name": "Khok Salut",
          "postCode": [
            "15150"
          ]
        },
        {
          "id": 160509,
          "name": "Lat Sali",
          "postCode": [
            "15150"
          ]
        },
        {
          "id": 160511,
          "name": "Mutchalin",
          "postCode": [
            "15150"
          ]
        },
        {
          "id": 160503,
          "name": "Pho Talat Kaeo",
          "postCode": [
            "15150"
          ]
        },
        {
          "id": 160501,
          "name": "Tha Wung",
          "postCode": [
            "15150"
          ]
        }
      ]
    },
    {
      "city": "Khun Yuam",
      "cityCode": 5802,
      "provinceCode": 58,
      "subDistrict": [
        {
          "id": 580201,
          "name": "Khun Yuam",
          "postCode": [
            "58140"
          ]
        },
        {
          "id": 580205,
          "name": "Mae Ki",
          "postCode": [
            "58140"
          ]
        },
        {
          "id": 580202,
          "name": "Mae Ngao",
          "postCode": [
            "58140"
          ]
        },
        {
          "id": 580206,
          "name": "Mae Ukho",
          "postCode": [
            "58140"
          ]
        },
        {
          "id": 580204,
          "name": "Mae Yuam Noi",
          "postCode": [
            "58140"
          ]
        },
        {
          "id": 580203,
          "name": "Mueang Pon",
          "postCode": [
            "58140"
          ]
        }
      ]
    },
    {
      "city": "Mae La Noi",
      "cityCode": 5805,
      "provinceCode": 58,
      "subDistrict": [
        {
          "id": 580505,
          "name": "Huai Hom",
          "postCode": [
            "58120"
          ]
        },
        {
          "id": 580508,
          "name": "Khun Mae La Noi",
          "postCode": [
            "58120"
          ]
        },
        {
          "id": 580502,
          "name": "Mae La Luang",
          "postCode": [
            "58120"
          ]
        },
        {
          "id": 580501,
          "name": "Mae La Noi",
          "postCode": [
            "58120"
          ]
        },
        {
          "id": 580506,
          "name": "Mae Na Chang",
          "postCode": [
            "58120"
          ]
        },
        {
          "id": 580504,
          "name": "Mae Tho",
          "postCode": [
            "58120"
          ]
        },
        {
          "id": 580507,
          "name": "Santi Khiri",
          "postCode": [
            "58120"
          ]
        },
        {
          "id": 580503,
          "name": "Tha Pha Pum",
          "postCode": [
            "58120"
          ]
        }
      ]
    },
    {
      "city": "Mae Sariang",
      "cityCode": 5804,
      "provinceCode": 58,
      "subDistrict": [
        {
          "id": 580401,
          "name": "Ban Kat",
          "postCode": [
            "58110"
          ]
        },
        {
          "id": 580404,
          "name": "Mae Ho",
          "postCode": [
            "58110"
          ]
        },
        {
          "id": 580403,
          "name": "Mae Khong",
          "postCode": [
            "58110"
          ]
        },
        {
          "id": 580402,
          "name": "Mae Sariang",
          "postCode": [
            "58110"
          ]
        },
        {
          "id": 580405,
          "name": "Mae Yuam",
          "postCode": [
            "58110"
          ]
        },
        {
          "id": 580408,
          "name": "Pa Pae",
          "postCode": [
            "58110"
          ]
        },
        {
          "id": 580406,
          "name": "Sao Hin",
          "postCode": [
            "58110"
          ]
        }
      ]
    },
    {
      "city": "Mueang Mae Hong Son",
      "cityCode": 5801,
      "provinceCode": 58,
      "subDistrict": [
        {
          "id": 580101,
          "name": "Chong Kham",
          "postCode": [
            "58000"
          ]
        },
        {
          "id": 580106,
          "name": "Huai Pha",
          "postCode": [
            "58000"
          ]
        },
        {
          "id": 580102,
          "name": "Huai Pong",
          "postCode": [
            "58000"
          ]
        },
        {
          "id": 580109,
          "name": "Huai Pu Ling",
          "postCode": [
            "58000"
          ]
        },
        {
          "id": 580105,
          "name": "Mok Champae",
          "postCode": [
            "58000"
          ]
        },
        {
          "id": 580104,
          "name": "Pang Mu",
          "postCode": [
            "58000"
          ]
        },
        {
          "id": 580103,
          "name": "Pha Bong",
          "postCode": [
            "58000"
          ]
        }
      ]
    },
    {
      "city": "Pai",
      "cityCode": 5803,
      "provinceCode": 58,
      "subDistrict": [
        {
          "id": 580304,
          "name": "Mae Hi",
          "postCode": [
            "58130"
          ]
        },
        {
          "id": 580303,
          "name": "Mae Na Toeng",
          "postCode": [
            "58130"
          ]
        },
        {
          "id": 580306,
          "name": "Mueang Paeng",
          "postCode": [
            "58130"
          ]
        },
        {
          "id": 580307,
          "name": "Pong Sa",
          "postCode": [
            "58130"
          ]
        },
        {
          "id": 580305,
          "name": "Thung Yao",
          "postCode": [
            "58130"
          ]
        },
        {
          "id": 580302,
          "name": "Wiang Nuea",
          "postCode": [
            "58130"
          ]
        },
        {
          "id": 580301,
          "name": "Wiang Tai",
          "postCode": [
            "58130"
          ]
        }
      ]
    },
    {
      "city": "Pang Mapha",
      "cityCode": 5807,
      "provinceCode": 58,
      "subDistrict": [
        {
          "id": 580704,
          "name": "Na Pu Pom",
          "postCode": [
            "58150"
          ]
        },
        {
          "id": 580702,
          "name": "Pang Mapha",
          "postCode": [
            "58150"
          ]
        },
        {
          "id": 580701,
          "name": "Sop Pong",
          "postCode": [
            "58150"
          ]
        },
        {
          "id": 580703,
          "name": "Tham Lot",
          "postCode": [
            "58150"
          ]
        }
      ]
    },
    {
      "city": "Sop Moei",
      "cityCode": 5806,
      "provinceCode": 58,
      "subDistrict": [
        {
          "id": 580603,
          "name": "Kong Koi",
          "postCode": [
            "58110"
          ]
        },
        {
          "id": 580602,
          "name": "Mae Khatuan",
          "postCode": [
            "58110"
          ]
        },
        {
          "id": 580606,
          "name": "Mae Sam Laep",
          "postCode": [
            "58110"
          ]
        },
        {
          "id": 580604,
          "name": "Mae Suat",
          "postCode": [
            "58110"
          ]
        },
        {
          "id": 580605,
          "name": "Pa Pong",
          "postCode": [
            "58110"
          ]
        },
        {
          "id": 580601,
          "name": "Sop Moei",
          "postCode": [
            "58110"
          ]
        }
      ]
    },
    {
      "city": "Borabue",
      "cityCode": 4406,
      "provinceCode": 44,
      "subDistrict": [
        {
          "id": 440602,
          "name": "Bo Yai",
          "postCode": [
            "44130"
          ]
        },
        {
          "id": 440601,
          "name": "Borabue",
          "postCode": [
            "44130"
          ]
        },
        {
          "id": 440611,
          "name": "Bua Mat",
          "postCode": [
            "44130"
          ]
        },
        {
          "id": 440620,
          "name": "Don Ngua",
          "postCode": [
            "44130"
          ]
        },
        {
          "id": 440606,
          "name": "Kam Phi",
          "postCode": [
            "44130"
          ]
        },
        {
          "id": 440608,
          "name": "Non Daeng",
          "postCode": [
            "44130"
          ]
        },
        {
          "id": 440607,
          "name": "Non Rasi",
          "postCode": [
            "44130"
          ]
        },
        {
          "id": 440610,
          "name": "Nong Chik",
          "postCode": [
            "44130"
          ]
        },
        {
          "id": 440613,
          "name": "Nong Khu Khat",
          "postCode": [
            "44130"
          ]
        },
        {
          "id": 440619,
          "name": "Nong Ko",
          "postCode": [
            "44130"
          ]
        },
        {
          "id": 440605,
          "name": "Nong Muang",
          "postCode": [
            "44130"
          ]
        },
        {
          "id": 440618,
          "name": "Nong Sim",
          "postCode": [
            "44130"
          ]
        },
        {
          "id": 440604,
          "name": "Wang Chai",
          "postCode": [
            "44130"
          ]
        },
        {
          "id": 440615,
          "name": "Wang Mai",
          "postCode": [
            "44130"
          ]
        },
        {
          "id": 440616,
          "name": "Yang",
          "postCode": [
            "44130"
          ]
        }
      ]
    },
    {
      "city": "Chiang Yuen",
      "cityCode": 4405,
      "provinceCode": 44,
      "subDistrict": [
        {
          "id": 440501,
          "name": "Chiang Yuen",
          "postCode": [
            "44160"
          ]
        },
        {
          "id": 440505,
          "name": "Don Ngoen",
          "postCode": [
            "44160"
          ]
        },
        {
          "id": 440506,
          "name": "Ku Thong",
          "postCode": [
            "44160"
          ]
        },
        {
          "id": 440512,
          "name": "Lao Bua Ban",
          "postCode": [
            "44160"
          ]
        },
        {
          "id": 440507,
          "name": "Na Thong",
          "postCode": [
            "44160"
          ]
        },
        {
          "id": 440503,
          "name": "Nong Son",
          "postCode": [
            "44160"
          ]
        },
        {
          "id": 440511,
          "name": "Phon Thong",
          "postCode": [
            "44160"
          ]
        },
        {
          "id": 440508,
          "name": "Suea Thao",
          "postCode": [
            "44160"
          ]
        }
      ]
    },
    {
      "city": "Chuen Chom",
      "cityCode": 4413,
      "provinceCode": 44,
      "subDistrict": [
        {
          "id": 441301,
          "name": "Chuen Chom",
          "postCode": [
            "44160"
          ]
        },
        {
          "id": 441302,
          "name": "Kut Pla Duk",
          "postCode": [
            "44160"
          ]
        },
        {
          "id": 441303,
          "name": "Lao Dok Mai",
          "postCode": [
            "44160"
          ]
        },
        {
          "id": 441304,
          "name": "Nong Kung",
          "postCode": [
            "44160"
          ]
        }
      ]
    },
    {
      "city": "Kae Dam",
      "cityCode": 4402,
      "provinceCode": 44,
      "subDistrict": [
        {
          "id": 440201,
          "name": "Kae Dam",
          "postCode": [
            "44190"
          ]
        },
        {
          "id": 440203,
          "name": "Mittraphap",
          "postCode": [
            "44190"
          ]
        },
        {
          "id": 440205,
          "name": "Non Phiban",
          "postCode": [
            "44190"
          ]
        },
        {
          "id": 440204,
          "name": "Nong Kung",
          "postCode": [
            "44190"
          ]
        },
        {
          "id": 440202,
          "name": "Wang Saeng",
          "postCode": [
            "44190"
          ]
        }
      ]
    },
    {
      "city": "Kantharawichai",
      "cityCode": 4404,
      "provinceCode": 44,
      "subDistrict": [
        {
          "id": 440406,
          "name": "Kham Riang",
          "postCode": [
            "44150"
          ]
        },
        {
          "id": 440410,
          "name": "Kham Thao Phatthana",
          "postCode": [
            "44150"
          ]
        },
        {
          "id": 440402,
          "name": "Khanthararat",
          "postCode": [
            "44150"
          ]
        },
        {
          "id": 440401,
          "name": "Khok Phra",
          "postCode": [
            "44150"
          ]
        },
        {
          "id": 440407,
          "name": "Khwao Yai",
          "postCode": [
            "44150"
          ]
        },
        {
          "id": 440409,
          "name": "Kut Sai Cho",
          "postCode": [
            "44150"
          ]
        },
        {
          "id": 440403,
          "name": "Makha",
          "postCode": [
            "44150"
          ]
        },
        {
          "id": 440405,
          "name": "Na Si Nuan",
          "postCode": [
            "44150"
          ]
        },
        {
          "id": 440408,
          "name": "Si Suk",
          "postCode": [
            "44150"
          ]
        },
        {
          "id": 440404,
          "name": "Tha Khon Yang",
          "postCode": [
            "44150"
          ]
        }
      ]
    },
    {
      "city": "Kosum Phisai",
      "cityCode": 4403,
      "provinceCode": 44,
      "subDistrict": [
        {
          "id": 440317,
          "name": "Don Klang",
          "postCode": [
            "44140"
          ]
        },
        {
          "id": 440314,
          "name": "Hae Tai",
          "postCode": [
            "44140"
          ]
        },
        {
          "id": 440301,
          "name": "Hua Khwang",
          "postCode": [
            "44140"
          ]
        },
        {
          "id": 440306,
          "name": "Kaeng Kae",
          "postCode": [
            "44140"
          ]
        },
        {
          "id": 440310,
          "name": "Khuean",
          "postCode": [
            "44140"
          ]
        },
        {
          "id": 440304,
          "name": "Khwao Rai",
          "postCode": [
            "44140"
          ]
        },
        {
          "id": 440309,
          "name": "Lao",
          "postCode": [
            "44140"
          ]
        },
        {
          "id": 440316,
          "name": "Loeng Tai",
          "postCode": [
            "44140"
          ]
        },
        {
          "id": 440311,
          "name": "Nong Bon",
          "postCode": [
            "44140"
          ]
        },
        {
          "id": 440308,
          "name": "Nong Bua",
          "postCode": [
            "44140"
          ]
        },
        {
          "id": 440315,
          "name": "Nong Kung Sawan",
          "postCode": [
            "44140"
          ]
        },
        {
          "id": 440307,
          "name": "Nong Lek",
          "postCode": [
            "44140"
          ]
        },
        {
          "id": 440305,
          "name": "Phaeng",
          "postCode": [
            "44140"
          ]
        },
        {
          "id": 440312,
          "name": "Phon Ngam",
          "postCode": [
            "44140"
          ]
        },
        {
          "id": 440303,
          "name": "Wang Yao",
          "postCode": [
            "44140"
          ]
        },
        {
          "id": 440302,
          "name": "Yang Noi",
          "postCode": [
            "44140"
          ]
        },
        {
          "id": 440313,
          "name": "Yang Tha Chaeng",
          "postCode": [
            "44140"
          ]
        }
      ]
    },
    {
      "city": "Kut Rang",
      "cityCode": 4412,
      "provinceCode": 44,
      "subDistrict": [
        {
          "id": 441205,
          "name": "Huai Toei",
          "postCode": [
            "44130"
          ]
        },
        {
          "id": 441201,
          "name": "Kut Rang",
          "postCode": [
            "44130"
          ]
        },
        {
          "id": 441203,
          "name": "Loeng Faek",
          "postCode": [
            "44130"
          ]
        },
        {
          "id": 441202,
          "name": "Na Pho",
          "postCode": [
            "44130"
          ]
        },
        {
          "id": 441204,
          "name": "Nong Waeng",
          "postCode": [
            "44130"
          ]
        }
      ]
    },
    {
      "city": "Mueang Maha Sarakham",
      "cityCode": 4401,
      "provinceCode": 44,
      "subDistrict": [
        {
          "id": 440114,
          "name": "Bua Kho",
          "postCode": [
            "44000"
          ]
        },
        {
          "id": 440106,
          "name": "Don Wan",
          "postCode": [
            "44000"
          ]
        },
        {
          "id": 440112,
          "name": "Huai Aeng",
          "postCode": [
            "44000"
          ]
        },
        {
          "id": 440108,
          "name": "Kaeng Loeng Chan",
          "postCode": [
            "44000"
          ]
        },
        {
          "id": 440105,
          "name": "Khok Ko",
          "postCode": [
            "44000"
          ]
        },
        {
          "id": 440102,
          "name": "Khwao",
          "postCode": [
            "44000"
          ]
        },
        {
          "id": 440107,
          "name": "Koeng",
          "postCode": [
            "44000"
          ]
        },
        {
          "id": 440110,
          "name": "Lat Phatthana",
          "postCode": [
            "44000"
          ]
        },
        {
          "id": 440113,
          "name": "Nong No",
          "postCode": [
            "44000"
          ]
        },
        {
          "id": 440111,
          "name": "Nong Pling",
          "postCode": [
            "44000"
          ]
        },
        {
          "id": 440101,
          "name": "Talat",
          "postCode": [
            "44000"
          ]
        },
        {
          "id": 440109,
          "name": "Tha Song Khon",
          "postCode": [
            "44000"
          ]
        },
        {
          "id": 440103,
          "name": "Tha Tum",
          "postCode": [
            "44000"
          ]
        },
        {
          "id": 440104,
          "name": "Waeng Nang",
          "postCode": [
            "44000"
          ]
        }
      ]
    },
    {
      "city": "Na Chueak",
      "cityCode": 4407,
      "provinceCode": 44,
      "subDistrict": [
        {
          "id": 440704,
          "name": "Khwao Rai",
          "postCode": [
            "44170"
          ]
        },
        {
          "id": 440701,
          "name": "Na Chueak",
          "postCode": [
            "44170"
          ]
        },
        {
          "id": 440703,
          "name": "Nong Daeng",
          "postCode": [
            "44170"
          ]
        },
        {
          "id": 440709,
          "name": "Nong Kung",
          "postCode": [
            "44170"
          ]
        },
        {
          "id": 440707,
          "name": "Nong Mek",
          "postCode": [
            "44170"
          ]
        },
        {
          "id": 440705,
          "name": "Nong Pho",
          "postCode": [
            "44170"
          ]
        },
        {
          "id": 440708,
          "name": "Nong Ruea",
          "postCode": [
            "44170"
          ]
        },
        {
          "id": 440706,
          "name": "Po Phan",
          "postCode": [
            "44170"
          ]
        },
        {
          "id": 440702,
          "name": "Samrong",
          "postCode": [
            "44170"
          ]
        },
        {
          "id": 440710,
          "name": "San Pa Tong",
          "postCode": [
            "44170"
          ]
        }
      ]
    },
    {
      "city": "Na Dun",
      "cityCode": 4410,
      "provinceCode": 44,
      "subDistrict": [
        {
          "id": 441004,
          "name": "Dong Bang",
          "postCode": [
            "44180"
          ]
        },
        {
          "id": 441005,
          "name": "Dong Duan",
          "postCode": [
            "44180"
          ]
        },
        {
          "id": 441007,
          "name": "Dong Yang",
          "postCode": [
            "44180"
          ]
        },
        {
          "id": 441006,
          "name": "Hua Dong",
          "postCode": [
            "44180"
          ]
        },
        {
          "id": 441008,
          "name": "Ku Santarat",
          "postCode": [
            "44180"
          ]
        },
        {
          "id": 441001,
          "name": "Na Dun",
          "postCode": [
            "44180"
          ]
        },
        {
          "id": 441003,
          "name": "Nong Khu",
          "postCode": [
            "44180"
          ]
        },
        {
          "id": 441002,
          "name": "Nong Phai",
          "postCode": [
            "44180"
          ]
        },
        {
          "id": 441009,
          "name": "Phra That",
          "postCode": [
            "44180"
          ]
        }
      ]
    },
    {
      "city": "Phayakkhaphum Phisai",
      "cityCode": 4408,
      "provinceCode": 44,
      "subDistrict": [
        {
          "id": 440802,
          "name": "Kham Pu",
          "postCode": [
            "44110"
          ]
        },
        {
          "id": 440815,
          "name": "Lan Sakae",
          "postCode": [
            "44110"
          ]
        },
        {
          "id": 440804,
          "name": "Mek Dam",
          "postCode": [
            "44110"
          ]
        },
        {
          "id": 440819,
          "name": "Mueang Suea",
          "postCode": [
            "44110"
          ]
        },
        {
          "id": 440812,
          "name": "Mueang Tao",
          "postCode": [
            "44110"
          ]
        },
        {
          "id": 440805,
          "name": "Na Si Nuan",
          "postCode": [
            "44110"
          ]
        },
        {
          "id": 440817,
          "name": "Nong Bua",
          "postCode": [
            "44110"
          ]
        },
        {
          "id": 440810,
          "name": "Nong Bua Kaeo",
          "postCode": [
            "44110"
          ]
        },
        {
          "id": 440801,
          "name": "Palan",
          "postCode": [
            "44110"
          ]
        },
        {
          "id": 440820,
          "name": "Phan Aen",
          "postCode": [
            "44110"
          ]
        },
        {
          "id": 440809,
          "name": "Rat Charoen",
          "postCode": [
            "44110"
          ]
        },
        {
          "id": 440818,
          "name": "Rat Phatthana",
          "postCode": [
            "44110"
          ]
        },
        {
          "id": 440816,
          "name": "Wiang Chai",
          "postCode": [
            "44110"
          ]
        },
        {
          "id": 440803,
          "name": "Wiang Sa-at",
          "postCode": [
            "44110"
          ]
        }
      ]
    },
    {
      "city": "Wapi Pathum",
      "cityCode": 4409,
      "provinceCode": 44,
      "subDistrict": [
        {
          "id": 440910,
          "name": "Ban Wai",
          "postCode": [
            "44120"
          ]
        },
        {
          "id": 440904,
          "name": "Dong Yai",
          "postCode": [
            "44120"
          ]
        },
        {
          "id": 440906,
          "name": "Hua Ruea",
          "postCode": [
            "44120"
          ]
        },
        {
          "id": 440907,
          "name": "Khaen",
          "postCode": [
            "44120"
          ]
        },
        {
          "id": 440902,
          "name": "Kham Pom",
          "postCode": [
            "44120"
          ]
        },
        {
          "id": 440915,
          "name": "Khok Si Thonglang",
          "postCode": [
            "44120"
          ]
        },
        {
          "id": 440909,
          "name": "Na Kha",
          "postCode": [
            "44120"
          ]
        },
        {
          "id": 440908,
          "name": "Ngua Ba",
          "postCode": [
            "44120"
          ]
        },
        {
          "id": 440911,
          "name": "Nong Hai",
          "postCode": [
            "44120"
          ]
        },
        {
          "id": 440914,
          "name": "Nong Saen",
          "postCode": [
            "44120"
          ]
        },
        {
          "id": 440901,
          "name": "Nong Saeng",
          "postCode": [
            "44120"
          ]
        },
        {
          "id": 440913,
          "name": "Nong Thum",
          "postCode": [
            "44120"
          ]
        },
        {
          "id": 440905,
          "name": "Pho Chai",
          "postCode": [
            "44120"
          ]
        },
        {
          "id": 440912,
          "name": "Pracha Phatthana",
          "postCode": [
            "44120"
          ]
        },
        {
          "id": 440903,
          "name": "Suea Kok",
          "postCode": [
            "44120"
          ]
        }
      ]
    },
    {
      "city": "Yang Si Surat",
      "cityCode": 4411,
      "provinceCode": 44,
      "subDistrict": [
        {
          "id": 441104,
          "name": "Ban Ku",
          "postCode": [
            "44210"
          ]
        },
        {
          "id": 441105,
          "name": "Dong Mueang",
          "postCode": [
            "44210"
          ]
        },
        {
          "id": 441102,
          "name": "Na Phu",
          "postCode": [
            "44210"
          ]
        },
        {
          "id": 441107,
          "name": "Nong Bua Santu",
          "postCode": [
            "44210"
          ]
        },
        {
          "id": 441106,
          "name": "Sang Saeng",
          "postCode": [
            "44210"
          ]
        },
        {
          "id": 441103,
          "name": "Waeng Dong",
          "postCode": [
            "44210"
          ]
        },
        {
          "id": 441101,
          "name": "Yang Si Surat",
          "postCode": [
            "44210"
          ]
        }
      ]
    },
    {
      "city": "Don Tan",
      "cityCode": 4903,
      "provinceCode": 49,
      "subDistrict": [
        {
          "id": 490305,
          "name": "Ban Bak",
          "postCode": [
            "49120"
          ]
        },
        {
          "id": 490307,
          "name": "Ban Kaeng",
          "postCode": [
            "49120"
          ]
        },
        {
          "id": 490301,
          "name": "Don Tan",
          "postCode": [
            "49120"
          ]
        },
        {
          "id": 490304,
          "name": "Lao Mi",
          "postCode": [
            "49120"
          ]
        },
        {
          "id": 490306,
          "name": "Na Sameng",
          "postCode": [
            "49120"
          ]
        },
        {
          "id": 490303,
          "name": "Pa Rai",
          "postCode": [
            "49120"
          ]
        },
        {
          "id": 490302,
          "name": "Pho Sai",
          "postCode": [
            "49120"
          ]
        }
      ]
    },
    {
      "city": "Dong Luang",
      "cityCode": 4904,
      "provinceCode": 49,
      "subDistrict": [
        {
          "id": 490405,
          "name": "Chanot Noi",
          "postCode": [
            "49140"
          ]
        },
        {
          "id": 490401,
          "name": "Dong Luang",
          "postCode": [
            "49140"
          ]
        },
        {
          "id": 490403,
          "name": "Kok Tum",
          "postCode": [
            "49140"
          ]
        },
        {
          "id": 490402,
          "name": "Nong Bua",
          "postCode": [
            "49140"
          ]
        },
        {
          "id": 490404,
          "name": "Nong Khaen",
          "postCode": [
            "49140"
          ]
        },
        {
          "id": 490406,
          "name": "Phang Daeng",
          "postCode": [
            "49140"
          ]
        }
      ]
    },
    {
      "city": "Khamcha-i",
      "cityCode": 4905,
      "provinceCode": 49,
      "subDistrict": [
        {
          "id": 490506,
          "name": "Ban Kho",
          "postCode": [
            "49110"
          ]
        },
        {
          "id": 490507,
          "name": "Ban Lao",
          "postCode": [
            "49110"
          ]
        },
        {
          "id": 490503,
          "name": "Ban Song",
          "postCode": [
            "49110"
          ]
        },
        {
          "id": 490512,
          "name": "Kham Bok",
          "postCode": [
            "49110"
          ]
        },
        {
          "id": 490504,
          "name": "Kham Cha-i",
          "postCode": [
            "49110"
          ]
        },
        {
          "id": 490511,
          "name": "Lao Sang Tho",
          "postCode": [
            "49110"
          ]
        },
        {
          "id": 490514,
          "name": "Nam Thiang",
          "postCode": [
            "49110"
          ]
        },
        {
          "id": 490505,
          "name": "Nong Ian",
          "postCode": [
            "49110"
          ]
        },
        {
          "id": 490508,
          "name": "Phon Ngam",
          "postCode": [
            "49110"
          ]
        }
      ]
    },
    {
      "city": "Mueang Mukdahan",
      "cityCode": 4901,
      "provinceCode": 49,
      "subDistrict": [
        {
          "id": 490103,
          "name": "Ban Khok",
          "postCode": [
            "49000"
          ]
        },
        {
          "id": 490104,
          "name": "Bang Sai Yai",
          "postCode": [
            "49000"
          ]
        },
        {
          "id": 490112,
          "name": "Dong Mon",
          "postCode": [
            "49000"
          ]
        },
        {
          "id": 490111,
          "name": "Dong Yen",
          "postCode": [
            "49000"
          ]
        },
        {
          "id": 490110,
          "name": "Kham Ahuan",
          "postCode": [
            "49000"
          ]
        },
        {
          "id": 490109,
          "name": "Kham Pa Lai",
          "postCode": [
            "49000"
          ]
        },
        {
          "id": 490113,
          "name": "Kut Khae",
          "postCode": [
            "49000"
          ]
        },
        {
          "id": 490101,
          "name": "Mukdahan",
          "postCode": [
            "49000"
          ]
        },
        {
          "id": 490108,
          "name": "Na Si Nuan",
          "postCode": [
            "49000"
          ]
        },
        {
          "id": 490107,
          "name": "Na Sok",
          "postCode": [
            "49000"
          ]
        },
        {
          "id": 490105,
          "name": "Phon Sai",
          "postCode": [
            "49000"
          ]
        },
        {
          "id": 490106,
          "name": "Phueng Daet",
          "postCode": [
            "49000"
          ]
        },
        {
          "id": 490102,
          "name": "Si Bun Rueang",
          "postCode": [
            "49000"
          ]
        }
      ]
    },
    {
      "city": "Nikhom Kham Soi",
      "cityCode": 4902,
      "provinceCode": 49,
      "subDistrict": [
        {
          "id": 490206,
          "name": "Chok Chai",
          "postCode": [
            "49130"
          ]
        },
        {
          "id": 490204,
          "name": "Kok Daeng",
          "postCode": [
            "49130"
          ]
        },
        {
          "id": 490202,
          "name": "Na Kok",
          "postCode": [
            "49130"
          ]
        },
        {
          "id": 490205,
          "name": "Na Udom",
          "postCode": [
            "49130"
          ]
        },
        {
          "id": 490201,
          "name": "Nikhom Kham Soi",
          "postCode": [
            "49130"
          ]
        },
        {
          "id": 490203,
          "name": "Nong Waeng",
          "postCode": [
            "49130"
          ]
        },
        {
          "id": 490207,
          "name": "Rom Klao",
          "postCode": [
            "49130"
          ]
        }
      ]
    },
    {
      "city": "Nong Sung",
      "cityCode": 4907,
      "provinceCode": 49,
      "subDistrict": [
        {
          "id": 490704,
          "name": "Ban Pao",
          "postCode": [
            "49160"
          ]
        },
        {
          "id": 490702,
          "name": "Non Yang",
          "postCode": [
            "49160"
          ]
        },
        {
          "id": 490701,
          "name": "Nong Sung",
          "postCode": [
            "49160"
          ]
        },
        {
          "id": 490706,
          "name": "Nong Sung Nuea",
          "postCode": [
            "49160"
          ]
        },
        {
          "id": 490705,
          "name": "Nong Sung Tai",
          "postCode": [
            "49160"
          ]
        },
        {
          "id": 490703,
          "name": "Phu Wong",
          "postCode": [
            "49160"
          ]
        }
      ]
    },
    {
      "city": "Wan Yai",
      "cityCode": 4906,
      "provinceCode": 49,
      "subDistrict": [
        {
          "id": 490603,
          "name": "Bang Sai Noi",
          "postCode": [
            "49150"
          ]
        },
        {
          "id": 490604,
          "name": "Chanot",
          "postCode": [
            "49150"
          ]
        },
        {
          "id": 490605,
          "name": "Dong Mu",
          "postCode": [
            "49150"
          ]
        },
        {
          "id": 490602,
          "name": "Pong Kham",
          "postCode": [
            "49150"
          ]
        },
        {
          "id": 490601,
          "name": "Wan Yai",
          "postCode": [
            "49150"
          ]
        }
      ]
    },
    {
      "city": "Ban Na",
      "cityCode": 2603,
      "provinceCode": 26,
      "subDistrict": [
        {
          "id": 260304,
          "name": "Asa",
          "postCode": [
            "26110"
          ]
        },
        {
          "id": 260301,
          "name": "Ban Na",
          "postCode": [
            "26110"
          ]
        },
        {
          "id": 260302,
          "name": "Ban Phrao",
          "postCode": [
            "26110"
          ]
        },
        {
          "id": 260303,
          "name": "Ban Phrik",
          "postCode": [
            "26110"
          ]
        },
        {
          "id": 260306,
          "name": "Bang O",
          "postCode": [
            "26110"
          ]
        },
        {
          "id": 260309,
          "name": "Khao Phoem",
          "postCode": [
            "26110"
          ]
        },
        {
          "id": 260308,
          "name": "Pa Kha",
          "postCode": [
            "26110"
          ]
        },
        {
          "id": 260307,
          "name": "Phikun Ok",
          "postCode": [
            "26110"
          ]
        },
        {
          "id": 260310,
          "name": "Si Ka-ang",
          "postCode": [
            "26110"
          ]
        },
        {
          "id": 260305,
          "name": "Thonglang",
          "postCode": [
            "26110"
          ]
        }
      ]
    },
    {
      "city": "Mueang Nakhon Nayok",
      "cityCode": 2601,
      "provinceCode": 26,
      "subDistrict": [
        {
          "id": 260103,
          "name": "Ban Yai",
          "postCode": [
            "26000"
          ]
        },
        {
          "id": 260106,
          "name": "Don Yo",
          "postCode": [
            "26000"
          ]
        },
        {
          "id": 260108,
          "name": "Dong Lakhon",
          "postCode": [
            "26000"
          ]
        },
        {
          "id": 260111,
          "name": "Hin Tang",
          "postCode": [
            "26000"
          ]
        },
        {
          "id": 260112,
          "name": "Khao Phra",
          "postCode": [
            "26000"
          ]
        },
        {
          "id": 260101,
          "name": "Nakhon Nayok",
          "postCode": [
            "26000"
          ]
        },
        {
          "id": 260113,
          "name": "Phrommani",
          "postCode": [
            "26000",
            "26001"
          ]
        },
        {
          "id": 260110,
          "name": "Sarika",
          "postCode": [
            "26000"
          ]
        },
        {
          "id": 260107,
          "name": "Si Chula",
          "postCode": [
            "26000"
          ]
        },
        {
          "id": 260109,
          "name": "Si Nawa",
          "postCode": [
            "26000"
          ]
        },
        {
          "id": 260102,
          "name": "Tha Chang",
          "postCode": [
            "26000"
          ]
        },
        {
          "id": 260105,
          "name": "Tha Sai",
          "postCode": [
            "26000"
          ]
        },
        {
          "id": 260104,
          "name": "Wang Krachom",
          "postCode": [
            "26000"
          ]
        }
      ]
    },
    {
      "city": "Ongkharak",
      "cityCode": 2604,
      "provinceCode": 26,
      "subDistrict": [
        {
          "id": 260408,
          "name": "Bang Luk Suea",
          "postCode": [
            "26120"
          ]
        },
        {
          "id": 260407,
          "name": "Bang Pla Kot",
          "postCode": [
            "26120"
          ]
        },
        {
          "id": 260405,
          "name": "Bang Sombun",
          "postCode": [
            "26120"
          ]
        },
        {
          "id": 260402,
          "name": "Bueng San",
          "postCode": [
            "26120"
          ]
        },
        {
          "id": 260410,
          "name": "Chumphon",
          "postCode": [
            "26120"
          ]
        },
        {
          "id": 260411,
          "name": "Khlong Yai",
          "postCode": [
            "26120"
          ]
        },
        {
          "id": 260409,
          "name": "Ongkharak",
          "postCode": [
            "26120"
          ]
        },
        {
          "id": 260404,
          "name": "Pho Thaen",
          "postCode": [
            "26120"
          ]
        },
        {
          "id": 260401,
          "name": "Phra Achan",
          "postCode": [
            "26120"
          ]
        },
        {
          "id": 260406,
          "name": "Sai Mun",
          "postCode": [
            "26120"
          ]
        },
        {
          "id": 260403,
          "name": "Sisa Krabue",
          "postCode": [
            "26120"
          ]
        }
      ]
    },
    {
      "city": "Pak Phli",
      "cityCode": 2602,
      "provinceCode": 26,
      "subDistrict": [
        {
          "id": 260204,
          "name": "Khok Kruat",
          "postCode": [
            "26130"
          ]
        },
        {
          "id": 260202,
          "name": "Ko Pho",
          "postCode": [
            "26130"
          ]
        },
        {
          "id": 260201,
          "name": "Ko Wai",
          "postCode": [
            "26130"
          ]
        },
        {
          "id": 260207,
          "name": "Na Hin Lat",
          "postCode": [
            "26130"
          ]
        },
        {
          "id": 260206,
          "name": "Nong Saeng",
          "postCode": [
            "26130"
          ]
        },
        {
          "id": 260203,
          "name": "Pak Phli",
          "postCode": [
            "26130"
          ]
        },
        {
          "id": 260205,
          "name": "Tha Ruea",
          "postCode": [
            "26130"
          ]
        }
      ]
    },
    {
      "city": "Bang Len",
      "cityCode": 7305,
      "provinceCode": 73,
      "subDistrict": [
        {
          "id": 730501,
          "name": "Bang Len",
          "postCode": [
            "73130"
          ]
        },
        {
          "id": 730503,
          "name": "Bang Luang",
          "postCode": [
            "73190"
          ]
        },
        {
          "id": 730504,
          "name": "Bang Phasi",
          "postCode": [
            "73130"
          ]
        },
        {
          "id": 730502,
          "name": "Bang Pla",
          "postCode": [
            "73130"
          ]
        },
        {
          "id": 730505,
          "name": "Bang Rakam",
          "postCode": [
            "73130"
          ]
        },
        {
          "id": 730506,
          "name": "Bang Sai Pa",
          "postCode": [
            "73130"
          ]
        },
        {
          "id": 730511,
          "name": "Bua Pak Tha",
          "postCode": [
            "73130"
          ]
        },
        {
          "id": 730509,
          "name": "Don Tum",
          "postCode": [
            "73130"
          ]
        },
        {
          "id": 730507,
          "name": "Hin Mun",
          "postCode": [
            "73190"
          ]
        },
        {
          "id": 730512,
          "name": "Khlong Nok Krathung",
          "postCode": [
            "73130"
          ]
        },
        {
          "id": 730514,
          "name": "Lam Phaya",
          "postCode": [
            "73130"
          ]
        },
        {
          "id": 730513,
          "name": "Naraphirom",
          "postCode": [
            "73130"
          ]
        },
        {
          "id": 730510,
          "name": "Nin Phet",
          "postCode": [
            "73130"
          ]
        },
        {
          "id": 730515,
          "name": "Phai Hu Chang",
          "postCode": [
            "73130"
          ]
        },
        {
          "id": 730508,
          "name": "Sai Ngam",
          "postCode": [
            "73130"
          ]
        }
      ]
    },
    {
      "city": "Don Tum",
      "cityCode": 7304,
      "provinceCode": 73,
      "subDistrict": [
        {
          "id": 730405,
          "name": "Ban Luang",
          "postCode": [
            "73150"
          ]
        },
        {
          "id": 730404,
          "name": "Don Phutsa",
          "postCode": [
            "73150"
          ]
        },
        {
          "id": 730406,
          "name": "Don Ruak",
          "postCode": [
            "73150"
          ]
        },
        {
          "id": 730407,
          "name": "Huai Duan",
          "postCode": [
            "73150"
          ]
        },
        {
          "id": 730402,
          "name": "Huai Phra",
          "postCode": [
            "73150"
          ]
        },
        {
          "id": 730403,
          "name": "Lam Hoei",
          "postCode": [
            "73150"
          ]
        },
        {
          "id": 730408,
          "name": "Lam Luk Bua",
          "postCode": [
            "73150"
          ]
        },
        {
          "id": 730401,
          "name": "Sam Ngam",
          "postCode": [
            "73150"
          ]
        }
      ]
    },
    {
      "city": "Kamphaeng Saen",
      "cityCode": 7302,
      "provinceCode": 73,
      "subDistrict": [
        {
          "id": 730208,
          "name": "Don Khoi",
          "postCode": [
            "73140"
          ]
        },
        {
          "id": 730204,
          "name": "Huai Khwang",
          "postCode": [
            "73140"
          ]
        },
        {
          "id": 730210,
          "name": "Huai Mon Thong",
          "postCode": [
            "73140"
          ]
        },
        {
          "id": 730212,
          "name": "Kamphaeng Saen",
          "postCode": [
            "73140"
          ]
        },
        {
          "id": 730202,
          "name": "Kratip",
          "postCode": [
            "73180"
          ]
        },
        {
          "id": 730214,
          "name": "Nong Krathum",
          "postCode": [
            "73140"
          ]
        },
        {
          "id": 730213,
          "name": "Rang Phikun",
          "postCode": [
            "73140"
          ]
        },
        {
          "id": 730209,
          "name": "Sa Phatthana",
          "postCode": [
            "73180"
          ]
        },
        {
          "id": 730206,
          "name": "Sa Si Mum",
          "postCode": [
            "73140",
            "73180"
          ]
        },
        {
          "id": 730207,
          "name": "Thung Bua",
          "postCode": [
            "73140"
          ]
        },
        {
          "id": 730205,
          "name": "Thung Khwang",
          "postCode": [
            "73140"
          ]
        },
        {
          "id": 730201,
          "name": "Thung Kraphang Hom",
          "postCode": [
            "73140"
          ]
        },
        {
          "id": 730203,
          "name": "Thung Luk Nok",
          "postCode": [
            "73140"
          ]
        },
        {
          "id": 730215,
          "name": "Wang Nam Khiao",
          "postCode": [
            "73140"
          ]
        },
        {
          "id": 730211,
          "name": "huai Muang",
          "postCode": [
            "73180"
          ]
        }
      ]
    },
    {
      "city": "Mueang Nakhon Pathom",
      "cityCode": 7301,
      "provinceCode": 73,
      "subDistrict": [
        {
          "id": 730125,
          "name": "Ban Yang",
          "postCode": [
            "73000"
          ]
        },
        {
          "id": 730102,
          "name": "Bang Khaem",
          "postCode": [
            "73000"
          ]
        },
        {
          "id": 730110,
          "name": "Bo Phlap",
          "postCode": [
            "73000"
          ]
        },
        {
          "id": 730108,
          "name": "Don Yai Hom",
          "postCode": [
            "73000"
          ]
        },
        {
          "id": 730122,
          "name": "Huai Chorakhe",
          "postCode": [
            "73000"
          ]
        },
        {
          "id": 730119,
          "name": "Lam Phaya",
          "postCode": [
            "73000"
          ]
        },
        {
          "id": 730106,
          "name": "Map Khae",
          "postCode": [
            "73000"
          ]
        },
        {
          "id": 730111,
          "name": "Nakhon Pathom",
          "postCode": [
            "73000"
          ]
        },
        {
          "id": 730116,
          "name": "Nong Din Daeng",
          "postCode": [
            "73000"
          ]
        },
        {
          "id": 730124,
          "name": "Nong Ngu Lueam",
          "postCode": [
            "73000"
          ]
        },
        {
          "id": 730113,
          "name": "Nong Pak Long",
          "postCode": [
            "73000"
          ]
        },
        {
          "id": 730101,
          "name": "Phra Pathom Chedi",
          "postCode": [
            "73000"
          ]
        },
        {
          "id": 730103,
          "name": "Phra Prathon",
          "postCode": [
            "73000"
          ]
        },
        {
          "id": 730118,
          "name": "Phrong Maduea",
          "postCode": [
            "73000"
          ]
        },
        {
          "id": 730114,
          "name": "Sam Khwai Phueak",
          "postCode": [
            "73000"
          ]
        },
        {
          "id": 730107,
          "name": "Sanam Chan",
          "postCode": [
            "73000"
          ]
        },
        {
          "id": 730120,
          "name": "Sra Kathiam",
          "postCode": [
            "73000"
          ]
        },
        {
          "id": 730121,
          "name": "Suan Pan",
          "postCode": [
            "73000"
          ]
        },
        {
          "id": 730105,
          "name": "Ta Kong",
          "postCode": [
            "73000"
          ]
        },
        {
          "id": 730104,
          "name": "Thammasala",
          "postCode": [
            "73000"
          ]
        },
        {
          "id": 730109,
          "name": "Thanon Khat",
          "postCode": [
            "73000"
          ]
        },
        {
          "id": 730123,
          "name": "Thap Luang",
          "postCode": [
            "73000"
          ]
        },
        {
          "id": 730115,
          "name": "Thung Noi",
          "postCode": [
            "73000"
          ]
        },
        {
          "id": 730112,
          "name": "Wang Taku",
          "postCode": [
            "73000"
          ]
        },
        {
          "id": 730117,
          "name": "Wang Yen",
          "postCode": [
            "73000"
          ]
        }
      ]
    },
    {
      "city": "Nakhon Chai Si",
      "cityCode": 7303,
      "provinceCode": 73,
      "subDistrict": [
        {
          "id": 730305,
          "name": "Bang Kaeo",
          "postCode": [
            "73120"
          ]
        },
        {
          "id": 730321,
          "name": "Bang Kaeo Fa",
          "postCode": [
            "73120"
          ]
        },
        {
          "id": 730302,
          "name": "Bang Krabao",
          "postCode": [
            "73120"
          ]
        },
        {
          "id": 730320,
          "name": "Bang Phra",
          "postCode": [
            "73120"
          ]
        },
        {
          "id": 730310,
          "name": "Bang Rakam",
          "postCode": [
            "73120"
          ]
        },
        {
          "id": 730317,
          "name": "Don Faek",
          "postCode": [
            "73120"
          ]
        },
        {
          "id": 730318,
          "name": "Huai Phlu",
          "postCode": [
            "73120"
          ]
        },
        {
          "id": 730311,
          "name": "Khok Phra Chedi",
          "postCode": [
            "73120"
          ]
        },
        {
          "id": 730307,
          "name": "Khun Kaeo",
          "postCode": [
            "73120"
          ]
        },
        {
          "id": 730313,
          "name": "Laem Bua",
          "postCode": [
            "73120"
          ]
        },
        {
          "id": 730322,
          "name": "Lan Tak Fa",
          "postCode": [
            "73120"
          ]
        },
        {
          "id": 730301,
          "name": "Nakhon Chai Si",
          "postCode": [
            "73120"
          ]
        },
        {
          "id": 730323,
          "name": "Ngio Rai",
          "postCode": [
            "73120"
          ]
        },
        {
          "id": 730309,
          "name": "Phaniat",
          "postCode": [
            "73120"
          ]
        },
        {
          "id": 730315,
          "name": "Sampathuan",
          "postCode": [
            "73120"
          ]
        },
        {
          "id": 730314,
          "name": "Si Maha Pho",
          "postCode": [
            "73120"
          ]
        },
        {
          "id": 730312,
          "name": "Sisa Thong",
          "postCode": [
            "73120"
          ]
        },
        {
          "id": 730306,
          "name": "Tha Krachap",
          "postCode": [
            "73120"
          ]
        },
        {
          "id": 730308,
          "name": "Tha Phraya",
          "postCode": [
            "73120"
          ]
        },
        {
          "id": 730304,
          "name": "Tha Tamnak",
          "postCode": [
            "73120"
          ]
        },
        {
          "id": 730324,
          "name": "Thaiyawat",
          "postCode": [
            "73120"
          ]
        },
        {
          "id": 730303,
          "name": "Wat Khae",
          "postCode": [
            "73120"
          ]
        },
        {
          "id": 730319,
          "name": "Wat Lamut",
          "postCode": [
            "73120"
          ]
        },
        {
          "id": 730316,
          "name": "Wat Samrong",
          "postCode": [
            "73120"
          ]
        }
      ]
    },
    {
      "city": "Phutthamonthon",
      "cityCode": 7307,
      "provinceCode": 73,
      "subDistrict": [
        {
          "id": 730702,
          "name": "Khlong Yong",
          "postCode": [
            "73170"
          ]
        },
        {
          "id": 730703,
          "name": "Maha Sawat",
          "postCode": [
            "73170"
          ]
        },
        {
          "id": 730701,
          "name": "Salaya",
          "postCode": [
            "73170"
          ]
        }
      ]
    },
    {
      "city": "Sam Phran",
      "cityCode": 7306,
      "provinceCode": 73,
      "subDistrict": [
        {
          "id": 730615,
          "name": "Ban Mai",
          "postCode": [
            "73110"
          ]
        },
        {
          "id": 730607,
          "name": "Bang Chang",
          "postCode": [
            "73110"
          ]
        },
        {
          "id": 730604,
          "name": "Bang Krathuek",
          "postCode": [
            "73210"
          ]
        },
        {
          "id": 730605,
          "name": "Bang Toei",
          "postCode": [
            "73210"
          ]
        },
        {
          "id": 730603,
          "name": "Hom Kret",
          "postCode": [
            "73110"
          ]
        },
        {
          "id": 730613,
          "name": "Khlong Chinda",
          "postCode": [
            "73110"
          ]
        },
        {
          "id": 730611,
          "name": "Khlong Mai",
          "postCode": [
            "73110"
          ]
        },
        {
          "id": 730610,
          "name": "Krathum Lom",
          "postCode": [
            "73220"
          ]
        },
        {
          "id": 730616,
          "name": "Om Yai",
          "postCode": [
            "73160"
          ]
        },
        {
          "id": 730608,
          "name": "Rai Khing",
          "postCode": [
            "73210"
          ]
        },
        {
          "id": 730606,
          "name": "Sam Phran",
          "postCode": [
            "73110"
          ]
        },
        {
          "id": 730602,
          "name": "Song Khanong",
          "postCode": [
            "73210"
          ]
        },
        {
          "id": 730612,
          "name": "Talat Chinda",
          "postCode": [
            "73110"
          ]
        },
        {
          "id": 730601,
          "name": "Tha Kham",
          "postCode": [
            "73110"
          ]
        },
        {
          "id": 730609,
          "name": "Tha Talat",
          "postCode": [
            "73110"
          ]
        },
        {
          "id": 730614,
          "name": "Yai Cha",
          "postCode": [
            "73110"
          ]
        }
      ]
    },
    {
      "city": "Ban Phaeng",
      "cityCode": 4804,
      "provinceCode": 48,
      "subDistrict": [
        {
          "id": 480401,
          "name": "Ban Phaeng",
          "postCode": [
            "48140"
          ]
        },
        {
          "id": 480409,
          "name": "Na Khe",
          "postCode": [
            "48140"
          ]
        },
        {
          "id": 480408,
          "name": "Na Ngua",
          "postCode": [
            "48140"
          ]
        },
        {
          "id": 480404,
          "name": "Nong Waeng",
          "postCode": [
            "48140"
          ]
        },
        {
          "id": 480402,
          "name": "Phai Lom",
          "postCode": [
            "48140"
          ]
        },
        {
          "id": 480403,
          "name": "Phon Thong",
          "postCode": [
            "48140"
          ]
        }
      ]
    },
    {
      "city": "Mueang Nakhon Phanom",
      "cityCode": 4801,
      "provinceCode": 48,
      "subDistrict": [
        {
          "id": 480107,
          "name": "At Samat",
          "postCode": [
            "48000"
          ]
        },
        {
          "id": 480109,
          "name": "Ban Klang",
          "postCode": [
            "48000"
          ]
        },
        {
          "id": 480106,
          "name": "Ban Phueng",
          "postCode": [
            "48000"
          ]
        },
        {
          "id": 480113,
          "name": "Dong Khwang",
          "postCode": [
            "48000"
          ]
        },
        {
          "id": 480108,
          "name": "Kham Thao",
          "postCode": [
            "48000"
          ]
        },
        {
          "id": 480111,
          "name": "Kham Toei",
          "postCode": [
            "48000"
          ]
        },
        {
          "id": 480105,
          "name": "Kurukhu",
          "postCode": [
            "48000"
          ]
        },
        {
          "id": 480104,
          "name": "Na Rat Khwai",
          "postCode": [
            "48000"
          ]
        },
        {
          "id": 480103,
          "name": "Na Sai",
          "postCode": [
            "48000"
          ]
        },
        {
          "id": 480101,
          "name": "Nai Mueang",
          "postCode": [
            "48000"
          ]
        },
        {
          "id": 480102,
          "name": "Nong Saeng",
          "postCode": [
            "48000"
          ]
        },
        {
          "id": 480112,
          "name": "Nong Yat",
          "postCode": [
            "48000"
          ]
        },
        {
          "id": 480115,
          "name": "Pho Tak",
          "postCode": [
            "48000"
          ]
        },
        {
          "id": 480110,
          "name": "Tha Kho",
          "postCode": [
            "48000"
          ]
        },
        {
          "id": 480114,
          "name": "Wang Ta Mua",
          "postCode": [
            "48000"
          ]
        }
      ]
    },
    {
      "city": "Na Kae",
      "cityCode": 4807,
      "provinceCode": 48,
      "subDistrict": [
        {
          "id": 480712,
          "name": "Ban Kaeng",
          "postCode": [
            "48130"
          ]
        },
        {
          "id": 480713,
          "name": "Ham Phi",
          "postCode": [
            "48130"
          ]
        },
        {
          "id": 480707,
          "name": "Kan Lueang",
          "postCode": [
            "48130"
          ]
        },
        {
          "id": 480701,
          "name": "Na Kae",
          "postCode": [
            "48130"
          ]
        },
        {
          "id": 480704,
          "name": "Na Khu",
          "postCode": [
            "48130"
          ]
        },
        {
          "id": 480709,
          "name": "Na Liang",
          "postCode": [
            "48130"
          ]
        },
        {
          "id": 480708,
          "name": "Nong Bo",
          "postCode": [
            "48130"
          ]
        },
        {
          "id": 480703,
          "name": "Nong Sang",
          "postCode": [
            "48130"
          ]
        },
        {
          "id": 480705,
          "name": "Phiman",
          "postCode": [
            "48130"
          ]
        },
        {
          "id": 480702,
          "name": "Phra Song",
          "postCode": [
            "48130"
          ]
        },
        {
          "id": 480706,
          "name": "Phum Kae",
          "postCode": [
            "48130"
          ]
        },
        {
          "id": 480715,
          "name": "Si Chomphu",
          "postCode": [
            "48130"
          ]
        }
      ]
    },
    {
      "city": "Na Thom",
      "cityCode": 4811,
      "provinceCode": 48,
      "subDistrict": [
        {
          "id": 481103,
          "name": "Don Toei",
          "postCode": [
            "48140"
          ]
        },
        {
          "id": 481101,
          "name": "Na Thom",
          "postCode": [
            "48140"
          ]
        },
        {
          "id": 481102,
          "name": "Nong Son",
          "postCode": [
            "48140"
          ]
        }
      ]
    },
    {
      "city": "Na Wa",
      "cityCode": 4809,
      "provinceCode": 48,
      "subDistrict": [
        {
          "id": 480903,
          "name": "Ban Siao",
          "postCode": [
            "48180"
          ]
        },
        {
          "id": 480905,
          "name": "Lao Phatthana",
          "postCode": [
            "48180"
          ]
        },
        {
          "id": 480904,
          "name": "Na Khun Yai",
          "postCode": [
            "48180"
          ]
        },
        {
          "id": 480902,
          "name": "Na Ngua",
          "postCode": [
            "48180"
          ]
        },
        {
          "id": 480901,
          "name": "Na Wa",
          "postCode": [
            "48180"
          ]
        },
        {
          "id": 480906,
          "name": "Tha Ruea",
          "postCode": [
            "48180"
          ]
        }
      ]
    },
    {
      "city": "Phon Sawan",
      "cityCode": 4810,
      "provinceCode": 48,
      "subDistrict": [
        {
          "id": 481005,
          "name": "Ban Kho",
          "postCode": [
            "48190"
          ]
        },
        {
          "id": 481002,
          "name": "Na Hua Bo",
          "postCode": [
            "48190"
          ]
        },
        {
          "id": 481003,
          "name": "Na Khamin",
          "postCode": [
            "48190"
          ]
        },
        {
          "id": 481007,
          "name": "Na Nai",
          "postCode": [
            "48190"
          ]
        },
        {
          "id": 481004,
          "name": "Phon Bok",
          "postCode": [
            "48190"
          ]
        },
        {
          "id": 481006,
          "name": "Phon Chan",
          "postCode": [
            "48190"
          ]
        },
        {
          "id": 481001,
          "name": "Phon Sawan",
          "postCode": [
            "48190"
          ]
        }
      ]
    },
    {
      "city": "Pla Pak",
      "cityCode": 4802,
      "provinceCode": 48,
      "subDistrict": [
        {
          "id": 480204,
          "name": "Khok Sawang",
          "postCode": [
            "48160"
          ]
        },
        {
          "id": 480205,
          "name": "Khok Sung",
          "postCode": [
            "48160"
          ]
        },
        {
          "id": 480203,
          "name": "Kutakai",
          "postCode": [
            "48160"
          ]
        },
        {
          "id": 480206,
          "name": "Maha Chai",
          "postCode": [
            "48160"
          ]
        },
        {
          "id": 480207,
          "name": "Na Makhuea",
          "postCode": [
            "48160"
          ]
        },
        {
          "id": 480202,
          "name": "Nong Hi",
          "postCode": [
            "48160"
          ]
        },
        {
          "id": 480208,
          "name": "Nong Thao Yai",
          "postCode": [
            "48160"
          ]
        },
        {
          "id": 480201,
          "name": "Pla Pak",
          "postCode": [
            "48160"
          ]
        }
      ]
    },
    {
      "city": "Renu Nakhon",
      "cityCode": 4806,
      "provinceCode": 48,
      "subDistrict": [
        {
          "id": 480605,
          "name": "Khok Hin Hae",
          "postCode": [
            "48170"
          ]
        },
        {
          "id": 480609,
          "name": "Na Kham",
          "postCode": [
            "48170"
          ]
        },
        {
          "id": 480604,
          "name": "Na Ngam",
          "postCode": [
            "48170"
          ]
        },
        {
          "id": 480607,
          "name": "Nong Yang Chin",
          "postCode": [
            "48170"
          ]
        },
        {
          "id": 480602,
          "name": "Phon Thong",
          "postCode": [
            "48170"
          ]
        },
        {
          "id": 480601,
          "name": "Renu",
          "postCode": [
            "48170"
          ]
        },
        {
          "id": 480608,
          "name": "Renu Tai",
          "postCode": [
            "48170"
          ]
        },
        {
          "id": 480603,
          "name": "Tha Lat",
          "postCode": [
            "48170"
          ]
        }
      ]
    },
    {
      "city": "Si Songkhram",
      "cityCode": 4808,
      "provinceCode": 48,
      "subDistrict": [
        {
          "id": 480806,
          "name": "Ban Kha",
          "postCode": [
            "48150"
          ]
        },
        {
          "id": 480803,
          "name": "Ban Ueang",
          "postCode": [
            "48150"
          ]
        },
        {
          "id": 480809,
          "name": "Hat Phaeng",
          "postCode": [
            "48150"
          ]
        },
        {
          "id": 480802,
          "name": "Na Duea",
          "postCode": [
            "48150"
          ]
        },
        {
          "id": 480807,
          "name": "Na Kham",
          "postCode": [
            "48150"
          ]
        },
        {
          "id": 480808,
          "name": "Phon Sawang",
          "postCode": [
            "48150"
          ]
        },
        {
          "id": 480801,
          "name": "Si Songkhram",
          "postCode": [
            "48150"
          ]
        },
        {
          "id": 480805,
          "name": "Tha Bo Songkhram",
          "postCode": [
            "48150"
          ]
        },
        {
          "id": 480804,
          "name": "sam Phong",
          "postCode": [
            "48150"
          ]
        }
      ]
    },
    {
      "city": "Tha Uthen",
      "cityCode": 4803,
      "provinceCode": 48,
      "subDistrict": [
        {
          "id": 480304,
          "name": "Chaiburi",
          "postCode": [
            "48120"
          ]
        },
        {
          "id": 480302,
          "name": "Non Tan",
          "postCode": [
            "48120"
          ]
        },
        {
          "id": 480314,
          "name": "Nong Thao",
          "postCode": [
            "48120"
          ]
        },
        {
          "id": 480305,
          "name": "Phanom",
          "postCode": [
            "48120"
          ]
        },
        {
          "id": 480306,
          "name": "Phathai",
          "postCode": [
            "48120"
          ]
        },
        {
          "id": 480312,
          "name": "Ram Rat",
          "postCode": [
            "48120"
          ]
        },
        {
          "id": 480303,
          "name": "Tha Champa",
          "postCode": [
            "48120"
          ]
        },
        {
          "id": 480301,
          "name": "Tha Uthen",
          "postCode": [
            "48120"
          ]
        },
        {
          "id": 480311,
          "name": "Woen Phrabat",
          "postCode": [
            "48120"
          ]
        }
      ]
    },
    {
      "city": "That Phanom",
      "cityCode": 4805,
      "provinceCode": 48,
      "subDistrict": [
        {
          "id": 480507,
          "name": "Don Nang Hong",
          "postCode": [
            "48110"
          ]
        },
        {
          "id": 480502,
          "name": "Fang Daeng",
          "postCode": [
            "48110"
          ]
        },
        {
          "id": 480511,
          "name": "Kut Chim",
          "postCode": [
            "48110"
          ]
        },
        {
          "id": 480510,
          "name": "Na Nat",
          "postCode": [
            "48110"
          ]
        },
        {
          "id": 480505,
          "name": "Na Thon",
          "postCode": [
            "48110"
          ]
        },
        {
          "id": 480508,
          "name": "Nam Kam",
          "postCode": [
            "48110"
          ]
        },
        {
          "id": 480503,
          "name": "Phon Phaeng",
          "postCode": [
            "48110"
          ]
        },
        {
          "id": 480504,
          "name": "Phra Klang Thung",
          "postCode": [
            "48110"
          ]
        },
        {
          "id": 480506,
          "name": "Saen Phan",
          "postCode": [
            "48110"
          ]
        },
        {
          "id": 480501,
          "name": "That Phanom",
          "postCode": [
            "48110"
          ]
        },
        {
          "id": 480512,
          "name": "That Phanom Nuea",
          "postCode": [
            "48110"
          ]
        },
        {
          "id": 480509,
          "name": "Um Mao",
          "postCode": [
            "48110"
          ]
        }
      ]
    },
    {
      "city": "Wang Yang",
      "cityCode": 4812,
      "provinceCode": 48,
      "subDistrict": [
        {
          "id": 481202,
          "name": "Khok Si",
          "postCode": [
            "48130"
          ]
        },
        {
          "id": 481204,
          "name": "Nong Pho",
          "postCode": [
            "48130"
          ]
        },
        {
          "id": 481201,
          "name": "Wang Yang",
          "postCode": [
            "48130"
          ]
        },
        {
          "id": 481203,
          "name": "Yot Chat",
          "postCode": [
            "48130"
          ]
        }
      ]
    },
    {
      "city": "Ban Lueam",
      "cityCode": 3005,
      "provinceCode": 30,
      "subDistrict": [
        {
          "id": 300501,
          "name": "Ban Lueam",
          "postCode": [
            "30350"
          ]
        },
        {
          "id": 300504,
          "name": "Cho Raka",
          "postCode": [
            "30350"
          ]
        },
        {
          "id": 300503,
          "name": "Khok Krabueang",
          "postCode": [
            "30350"
          ]
        },
        {
          "id": 300502,
          "name": "Wang Pho",
          "postCode": [
            "30350"
          ]
        }
      ]
    },
    {
      "city": "Bua Lai",
      "cityCode": 3030,
      "provinceCode": 30,
      "subDistrict": [
        {
          "id": 303003,
          "name": "Bua Lai",
          "postCode": [
            "30120"
          ]
        },
        {
          "id": 303001,
          "name": "Mueang Phalai",
          "postCode": [
            "30120"
          ]
        },
        {
          "id": 303002,
          "name": "Non Chan",
          "postCode": [
            "30120"
          ]
        },
        {
          "id": 303004,
          "name": "Nong Wa",
          "postCode": [
            "30120"
          ]
        }
      ]
    },
    {
      "city": "Bua Yai",
      "cityCode": 3012,
      "provinceCode": 30,
      "subDistrict": [
        {
          "id": 301201,
          "name": "Bua Yai",
          "postCode": [
            "30120"
          ]
        },
        {
          "id": 301215,
          "name": "Dan Chang",
          "postCode": [
            "30120"
          ]
        },
        {
          "id": 301206,
          "name": "Don Tanin",
          "postCode": [
            "30120"
          ]
        },
        {
          "id": 301203,
          "name": "Huai Yang",
          "postCode": [
            "30120"
          ]
        },
        {
          "id": 301220,
          "name": "Khun Thong",
          "postCode": [
            "30120"
          ]
        },
        {
          "id": 301214,
          "name": "Kut Chok",
          "postCode": [
            "30120"
          ]
        },
        {
          "id": 301208,
          "name": "Non Thonglang",
          "postCode": [
            "30120"
          ]
        },
        {
          "id": 301207,
          "name": "Nong Bua Sa-at",
          "postCode": [
            "30120"
          ]
        },
        {
          "id": 301224,
          "name": "Nong Chaeng Yai",
          "postCode": [
            "30120"
          ]
        },
        {
          "id": 301204,
          "name": "Sema Yai",
          "postCode": [
            "30120"
          ]
        }
      ]
    },
    {
      "city": "Chakkarat",
      "cityCode": 3006,
      "provinceCode": 30,
      "subDistrict": [
        {
          "id": 300601,
          "name": "Chakkarat",
          "postCode": [
            "30230"
          ]
        },
        {
          "id": 300613,
          "name": "Hin Khon",
          "postCode": [
            "30230"
          ]
        },
        {
          "id": 300611,
          "name": "Khlong Mueang",
          "postCode": [
            "30230"
          ]
        },
        {
          "id": 300605,
          "name": "Nong Kham",
          "postCode": [
            "30230"
          ]
        },
        {
          "id": 300607,
          "name": "Nong Phluang",
          "postCode": [
            "30230"
          ]
        },
        {
          "id": 300610,
          "name": "Si Lako",
          "postCode": [
            "30230"
          ]
        },
        {
          "id": 300604,
          "name": "Si Suk",
          "postCode": [
            "30230"
          ]
        },
        {
          "id": 300603,
          "name": "Thonglang",
          "postCode": [
            "30230"
          ]
        }
      ]
    },
    {
      "city": "Chaloem Phra Kiat",
      "cityCode": 3032,
      "provinceCode": 30,
      "subDistrict": [
        {
          "id": 303201,
          "name": "Chang Thong",
          "postCode": [
            "30230"
          ]
        },
        {
          "id": 303204,
          "name": "Nong Ngu Lueam",
          "postCode": [
            "30000"
          ]
        },
        {
          "id": 303205,
          "name": "Nong Yang",
          "postCode": [
            "30230"
          ]
        },
        {
          "id": 303203,
          "name": "Phra Phut",
          "postCode": [
            "30230"
          ]
        },
        {
          "id": 303202,
          "name": "Tha Chang",
          "postCode": [
            "30230"
          ]
        }
      ]
    },
    {
      "city": "Chok Chai",
      "cityCode": 3007,
      "provinceCode": 30,
      "subDistrict": [
        {
          "id": 300708,
          "name": "Chok Chai",
          "postCode": [
            "30190"
          ]
        },
        {
          "id": 300710,
          "name": "Dan Kwian",
          "postCode": [
            "30190"
          ]
        },
        {
          "id": 300701,
          "name": "Krathok",
          "postCode": [
            "30190"
          ]
        },
        {
          "id": 300709,
          "name": "Lalom Mai Phatthana",
          "postCode": [
            "30190"
          ]
        },
        {
          "id": 300702,
          "name": "Phlapphla",
          "postCode": [
            "30190"
          ]
        },
        {
          "id": 300703,
          "name": "Tha Ang",
          "postCode": [
            "30190"
          ]
        },
        {
          "id": 300706,
          "name": "Tha Chalung",
          "postCode": [
            "30190"
          ]
        },
        {
          "id": 300705,
          "name": "Tha Lat Khao",
          "postCode": [
            "30190"
          ]
        },
        {
          "id": 300707,
          "name": "Tha Yiam",
          "postCode": [
            "30190"
          ]
        },
        {
          "id": 300704,
          "name": "Thung Arun",
          "postCode": [
            "30190"
          ]
        }
      ]
    },
    {
      "city": "Chum Phuang",
      "cityCode": 3017,
      "provinceCode": 30,
      "subDistrict": [
        {
          "id": 301701,
          "name": "Chum Phuang",
          "postCode": [
            "30270"
          ]
        },
        {
          "id": 301710,
          "name": "Non Rang",
          "postCode": [
            "30270"
          ]
        },
        {
          "id": 301716,
          "name": "Non Tum",
          "postCode": [
            "30270"
          ]
        },
        {
          "id": 301717,
          "name": "Non Yo",
          "postCode": [
            "30270"
          ]
        },
        {
          "id": 301714,
          "name": "Nong Lak",
          "postCode": [
            "30270"
          ]
        },
        {
          "id": 301702,
          "name": "Prasuk",
          "postCode": [
            "30270"
          ]
        },
        {
          "id": 301704,
          "name": "Sarai",
          "postCode": [
            "30270"
          ]
        },
        {
          "id": 301705,
          "name": "Talat Sai",
          "postCode": [
            "30270"
          ]
        },
        {
          "id": 301703,
          "name": "Tha Lat",
          "postCode": [
            "30270"
          ]
        }
      ]
    },
    {
      "city": "Dan Khun Thot",
      "cityCode": 3008,
      "provinceCode": 30,
      "subDistrict": [
        {
          "id": 300806,
          "name": "Ban Kao",
          "postCode": [
            "30210"
          ]
        },
        {
          "id": 300807,
          "name": "Ban Praeng",
          "postCode": [
            "36220"
          ]
        },
        {
          "id": 300802,
          "name": "Dan Khun Thot",
          "postCode": [
            "30210"
          ]
        },
        {
          "id": 300804,
          "name": "Dan Nai",
          "postCode": [
            "30210"
          ]
        },
        {
          "id": 300803,
          "name": "Dan Nok",
          "postCode": [
            "30210"
          ]
        },
        {
          "id": 300813,
          "name": "Hin Dat",
          "postCode": [
            "30210"
          ]
        },
        {
          "id": 300815,
          "name": "Huai Bong",
          "postCode": [
            "30210"
          ]
        },
        {
          "id": 300801,
          "name": "Kut Phiman",
          "postCode": [
            "30210"
          ]
        },
        {
          "id": 300817,
          "name": "Non Mueang Phatthana",
          "postCode": [
            "30210"
          ]
        },
        {
          "id": 300812,
          "name": "Nong Bua Lakhon",
          "postCode": [
            "30210"
          ]
        },
        {
          "id": 300811,
          "name": "Nong Bua Takiat",
          "postCode": [
            "30210"
          ]
        },
        {
          "id": 300810,
          "name": "Nong Krat",
          "postCode": [
            "30210"
          ]
        },
        {
          "id": 300818,
          "name": "Nong Sai",
          "postCode": [
            "36220"
          ]
        },
        {
          "id": 300808,
          "name": "Phan Chana",
          "postCode": [
            "30210"
          ]
        },
        {
          "id": 300809,
          "name": "Sa Chorakhe",
          "postCode": [
            "30210"
          ]
        },
        {
          "id": 300805,
          "name": "Takhian",
          "postCode": [
            "30210"
          ]
        }
      ]
    },
    {
      "city": "Huai Thalaeng",
      "cityCode": 3016,
      "provinceCode": 30,
      "subDistrict": [
        {
          "id": 301605,
          "name": "Hin Dat",
          "postCode": [
            "30240"
          ]
        },
        {
          "id": 301610,
          "name": "Huai Khaen",
          "postCode": [
            "30240"
          ]
        },
        {
          "id": 301601,
          "name": "Huai Thalaeng",
          "postCode": [
            "30240"
          ]
        },
        {
          "id": 301607,
          "name": "Kong Rot",
          "postCode": [
            "30240"
          ]
        },
        {
          "id": 301608,
          "name": "Lung Pradu",
          "postCode": [
            "30240"
          ]
        },
        {
          "id": 301604,
          "name": "Lung Takhian",
          "postCode": [
            "30240"
          ]
        },
        {
          "id": 301603,
          "name": "Mueang Phlapphla",
          "postCode": [
            "30240"
          ]
        },
        {
          "id": 301606,
          "name": "Ngio",
          "postCode": [
            "30240"
          ]
        },
        {
          "id": 301609,
          "name": "Tako",
          "postCode": [
            "30240"
          ]
        },
        {
          "id": 301602,
          "name": "Thap Sawai",
          "postCode": [
            "30240"
          ]
        }
      ]
    },
    {
      "city": "Kaeng Sanam Nang",
      "cityCode": 3023,
      "provinceCode": 30,
      "subDistrict": [
        {
          "id": 302303,
          "name": "Bueng Phalai",
          "postCode": [
            "30440"
          ]
        },
        {
          "id": 302305,
          "name": "Bueng Samrong",
          "postCode": [
            "30440"
          ]
        },
        {
          "id": 302301,
          "name": "Kaeng Sanam Nang",
          "postCode": [
            "30440"
          ]
        },
        {
          "id": 302302,
          "name": "Non Samran",
          "postCode": [
            "30440"
          ]
        },
        {
          "id": 302304,
          "name": "Si Suk",
          "postCode": [
            "30440"
          ]
        }
      ]
    },
    {
      "city": "Kham Sakaesaeng",
      "cityCode": 3011,
      "provinceCode": 30,
      "subDistrict": [
        {
          "id": 301104,
          "name": "Chiwuek",
          "postCode": [
            "30290"
          ]
        },
        {
          "id": 301101,
          "name": "Kham Sakaesaeng",
          "postCode": [
            "30290"
          ]
        },
        {
          "id": 301107,
          "name": "Mueang Kaset",
          "postCode": [
            "30290"
          ]
        },
        {
          "id": 301103,
          "name": "Mueang Nat",
          "postCode": [
            "30290"
          ]
        },
        {
          "id": 301102,
          "name": "Non Mueang",
          "postCode": [
            "30290"
          ]
        },
        {
          "id": 301106,
          "name": "Nong Hua Fan",
          "postCode": [
            "30290"
          ]
        },
        {
          "id": 301105,
          "name": "Pha-ngat",
          "postCode": [
            "30290"
          ]
        }
      ]
    },
    {
      "city": "Kham Thale So",
      "cityCode": 3019,
      "provinceCode": 30,
      "subDistrict": [
        {
          "id": 301905,
          "name": "Bueng O",
          "postCode": [
            "30280"
          ]
        },
        {
          "id": 301901,
          "name": "Kham Thale So",
          "postCode": [
            "30280"
          ]
        },
        {
          "id": 301904,
          "name": "Nong Suang",
          "postCode": [
            "30280"
          ]
        },
        {
          "id": 301903,
          "name": "Phan Dung",
          "postCode": [
            "30280"
          ]
        },
        {
          "id": 301902,
          "name": "Pong Daeng",
          "postCode": [
            "30280"
          ]
        }
      ]
    },
    {
      "city": "Khon Buri",
      "cityCode": 3002,
      "provinceCode": 30,
      "subDistrict": [
        {
          "id": 300208,
          "name": "Ban Mai",
          "postCode": [
            "30250"
          ]
        },
        {
          "id": 300201,
          "name": "Chae",
          "postCode": [
            "30250"
          ]
        },
        {
          "id": 300202,
          "name": "Chaliang",
          "postCode": [
            "30250"
          ]
        },
        {
          "id": 300205,
          "name": "Chorakhe Hin",
          "postCode": [
            "30250"
          ]
        },
        {
          "id": 300204,
          "name": "Khok Krachai",
          "postCode": [
            "30250"
          ]
        },
        {
          "id": 300203,
          "name": "Khon Buri",
          "postCode": [
            "30250"
          ]
        },
        {
          "id": 300210,
          "name": "Khon Buri Tai",
          "postCode": [
            "30250"
          ]
        },
        {
          "id": 300209,
          "name": "Lam Phiak",
          "postCode": [
            "30250"
          ]
        },
        {
          "id": 300206,
          "name": "Map Tako En",
          "postCode": [
            "30250"
          ]
        },
        {
          "id": 300207,
          "name": "Oraphim",
          "postCode": [
            "30250"
          ]
        },
        {
          "id": 300212,
          "name": "Sa Wan Phraya",
          "postCode": [
            "30250"
          ]
        },
        {
          "id": 300211,
          "name": "Tabaek Ban",
          "postCode": [
            "30250"
          ]
        }
      ]
    },
    {
      "city": "Khong",
      "cityCode": 3004,
      "provinceCode": 30,
      "subDistrict": [
        {
          "id": 300405,
          "name": "Ban Prang",
          "postCode": [
            "30260"
          ]
        },
        {
          "id": 300409,
          "name": "Don Yai",
          "postCode": [
            "30260"
          ]
        },
        {
          "id": 300410,
          "name": "Kham Sombun",
          "postCode": [
            "30260"
          ]
        },
        {
          "id": 300402,
          "name": "Khu Khat",
          "postCode": [
            "30260"
          ]
        },
        {
          "id": 300401,
          "name": "Mueang Khong",
          "postCode": [
            "30260"
          ]
        },
        {
          "id": 300408,
          "name": "Non Teng",
          "postCode": [
            "30260"
          ]
        },
        {
          "id": 300407,
          "name": "Nong Bua",
          "postCode": [
            "30260"
          ]
        },
        {
          "id": 300406,
          "name": "Nong Manao",
          "postCode": [
            "30260"
          ]
        },
        {
          "id": 300404,
          "name": "Ta Chan",
          "postCode": [
            "30260"
          ]
        },
        {
          "id": 300403,
          "name": "Thephalai",
          "postCode": [
            "30260"
          ]
        }
      ]
    },
    {
      "city": "Lam Thamenchai",
      "cityCode": 3029,
      "provinceCode": 30,
      "subDistrict": [
        {
          "id": 302902,
          "name": "Ban Yang",
          "postCode": [
            "30270"
          ]
        },
        {
          "id": 302903,
          "name": "Chong Maeo",
          "postCode": [
            "30270"
          ]
        },
        {
          "id": 302901,
          "name": "Khui",
          "postCode": [
            "30270"
          ]
        },
        {
          "id": 302904,
          "name": "Phlai",
          "postCode": [
            "30270"
          ]
        }
      ]
    },
    {
      "city": "Mueang Nakhon Ratchasima",
      "cityCode": 3001,
      "provinceCode": 30,
      "subDistrict": [
        {
          "id": 300112,
          "name": "Ban Ko",
          "postCode": [
            "30000"
          ]
        },
        {
          "id": 300113,
          "name": "Ban Mai",
          "postCode": [
            "30000"
          ]
        },
        {
          "id": 300115,
          "name": "Ban Pho",
          "postCode": [
            "30310"
          ]
        },
        {
          "id": 300118,
          "name": "Chai Mongkhon",
          "postCode": [
            "30000"
          ]
        },
        {
          "id": 300116,
          "name": "Cho Ho",
          "postCode": [
            "30310"
          ]
        },
        {
          "id": 300111,
          "name": "Hua Thale",
          "postCode": [
            "30000"
          ]
        },
        {
          "id": 300117,
          "name": "Khok Kruat",
          "postCode": [
            "30280"
          ]
        },
        {
          "id": 300104,
          "name": "Khok Sung",
          "postCode": [
            "30310"
          ]
        },
        {
          "id": 300105,
          "name": "Maroeng",
          "postCode": [
            "30000"
          ]
        },
        {
          "id": 300108,
          "name": "Muen Wai",
          "postCode": [
            "30000"
          ]
        },
        {
          "id": 300101,
          "name": "Nai Mueang",
          "postCode": [
            "30000"
          ]
        },
        {
          "id": 300119,
          "name": "Nong Bua Sala",
          "postCode": [
            "30000"
          ]
        },
        {
          "id": 300103,
          "name": "Nong Chabok",
          "postCode": [
            "30000"
          ]
        },
        {
          "id": 300125,
          "name": "Nong Khai Nam",
          "postCode": [
            "30310"
          ]
        },
        {
          "id": 300124,
          "name": "Nong Krathum",
          "postCode": [
            "30000"
          ]
        },
        {
          "id": 300110,
          "name": "Nong Phai Lom",
          "postCode": [
            "30000"
          ]
        },
        {
          "id": 300106,
          "name": "Nong Rawiang",
          "postCode": [
            "30000"
          ]
        },
        {
          "id": 300123,
          "name": "Phanao",
          "postCode": [
            "30000"
          ]
        },
        {
          "id": 300102,
          "name": "Pho Klang",
          "postCode": [
            "30000"
          ]
        },
        {
          "id": 300109,
          "name": "Phon Krang",
          "postCode": [
            "30000"
          ]
        },
        {
          "id": 300114,
          "name": "Phutsa",
          "postCode": [
            "30000"
          ]
        },
        {
          "id": 300107,
          "name": "Pru Yai",
          "postCode": [
            "30000"
          ]
        },
        {
          "id": 300121,
          "name": "Si Mum",
          "postCode": [
            "30000"
          ]
        },
        {
          "id": 300120,
          "name": "Suranari",
          "postCode": [
            "30000"
          ]
        },
        {
          "id": 300122,
          "name": "Talat",
          "postCode": [
            "30310"
          ]
        }
      ]
    },
    {
      "city": "Mueang Yang",
      "cityCode": 3027,
      "provinceCode": 30,
      "subDistrict": [
        {
          "id": 302702,
          "name": "Krabueang Nok",
          "postCode": [
            "30270"
          ]
        },
        {
          "id": 302703,
          "name": "Lahan Pla Khao",
          "postCode": [
            "30270"
          ]
        },
        {
          "id": 302701,
          "name": "Mueang Yang",
          "postCode": [
            "30270"
          ]
        },
        {
          "id": 302704,
          "name": "Non Udom",
          "postCode": [
            "30270"
          ]
        }
      ]
    },
    {
      "city": "Non Daeng",
      "cityCode": 3024,
      "provinceCode": 30,
      "subDistrict": [
        {
          "id": 302405,
          "name": "Don Yao Yai",
          "postCode": [
            "30360"
          ]
        },
        {
          "id": 302403,
          "name": "Lam Phaniang",
          "postCode": [
            "30360"
          ]
        },
        {
          "id": 302401,
          "name": "Non Daeng",
          "postCode": [
            "30360"
          ]
        },
        {
          "id": 302402,
          "name": "Non Ta Then",
          "postCode": [
            "30360"
          ]
        },
        {
          "id": 302404,
          "name": "Wang Hin",
          "postCode": [
            "30360"
          ]
        }
      ]
    },
    {
      "city": "Non Sung",
      "cityCode": 3010,
      "provinceCode": 30,
      "subDistrict": [
        {
          "id": 301004,
          "name": "Bing",
          "postCode": [
            "30160"
          ]
        },
        {
          "id": 301010,
          "name": "Chan At",
          "postCode": [
            "30160"
          ]
        },
        {
          "id": 301012,
          "name": "Dan Khla",
          "postCode": [
            "30160"
          ]
        },
        {
          "id": 301005,
          "name": "Don Chomphu",
          "postCode": [
            "30160"
          ]
        },
        {
          "id": 301015,
          "name": "Don Wai",
          "postCode": [
            "30160"
          ]
        },
        {
          "id": 301011,
          "name": "Kham Thao",
          "postCode": [
            "30160"
          ]
        },
        {
          "id": 301013,
          "name": "Lam Kho Hong",
          "postCode": [
            "30160"
          ]
        },
        {
          "id": 301016,
          "name": "Lam Mun",
          "postCode": [
            "30160"
          ]
        },
        {
          "id": 301007,
          "name": "Lum Khao",
          "postCode": [
            "30160"
          ]
        },
        {
          "id": 301002,
          "name": "Mai",
          "postCode": [
            "30160"
          ]
        },
        {
          "id": 301008,
          "name": "Makha",
          "postCode": [
            "30160"
          ]
        },
        {
          "id": 301014,
          "name": "Mueang Prasat",
          "postCode": [
            "30160"
          ]
        },
        {
          "id": 301001,
          "name": "Non Sung",
          "postCode": [
            "30160"
          ]
        },
        {
          "id": 301009,
          "name": "Phon Songkhram",
          "postCode": [
            "30160"
          ]
        },
        {
          "id": 301003,
          "name": "Tanot",
          "postCode": [
            "30160"
          ]
        },
        {
          "id": 301006,
          "name": "Than Prasat",
          "postCode": [
            "30420"
          ]
        }
      ]
    },
    {
      "city": "Non Thai",
      "cityCode": 3009,
      "provinceCode": 30,
      "subDistrict": [
        {
          "id": 300906,
          "name": "Ban Wang",
          "postCode": [
            "30220"
          ]
        },
        {
          "id": 300907,
          "name": "Banlang",
          "postCode": [
            "30220"
          ]
        },
        {
          "id": 300902,
          "name": "Dan Chak",
          "postCode": [
            "30220"
          ]
        },
        {
          "id": 300903,
          "name": "Kampang",
          "postCode": [
            "30220"
          ]
        },
        {
          "id": 300905,
          "name": "Khang Phu",
          "postCode": [
            "30220"
          ]
        },
        {
          "id": 300914,
          "name": "Makha",
          "postCode": [
            "30220"
          ]
        },
        {
          "id": 300901,
          "name": "Non Thai",
          "postCode": [
            "30220"
          ]
        },
        {
          "id": 300908,
          "name": "Sai O",
          "postCode": [
            "30220"
          ]
        },
        {
          "id": 300904,
          "name": "Samrong",
          "postCode": [
            "30220"
          ]
        },
        {
          "id": 300909,
          "name": "Thanon Pho",
          "postCode": [
            "30220"
          ]
        }
      ]
    },
    {
      "city": "Nong Bunmak",
      "cityCode": 3022,
      "provinceCode": 30,
      "subDistrict": [
        {
          "id": 302209,
          "name": "Ban Mai",
          "postCode": [
            "30410"
          ]
        },
        {
          "id": 302205,
          "name": "Laem Thong",
          "postCode": [
            "30410"
          ]
        },
        {
          "id": 302207,
          "name": "Lung Khwao",
          "postCode": [
            "30410"
          ]
        },
        {
          "id": 302201,
          "name": "Nong Bunnak",
          "postCode": [
            "30410"
          ]
        },
        {
          "id": 302204,
          "name": "Nong Hua Raet",
          "postCode": [
            "30410"
          ]
        },
        {
          "id": 302208,
          "name": "Nong Mai Phai",
          "postCode": [
            "30410"
          ]
        },
        {
          "id": 302206,
          "name": "Nong Takai",
          "postCode": [
            "30410"
          ]
        },
        {
          "id": 302202,
          "name": "Saraphi",
          "postCode": [
            "30410"
          ]
        },
        {
          "id": 302203,
          "name": "Thai Charoen",
          "postCode": [
            "30410"
          ]
        }
      ]
    },
    {
      "city": "Pak Chong",
      "cityCode": 3021,
      "provinceCode": 30,
      "subDistrict": [
        {
          "id": 302103,
          "name": "Chanthuek",
          "postCode": [
            "30130"
          ]
        },
        {
          "id": 302107,
          "name": "Khanong Phra",
          "postCode": [
            "30130"
          ]
        },
        {
          "id": 302109,
          "name": "Khlong Muang",
          "postCode": [
            "30130"
          ]
        },
        {
          "id": 302102,
          "name": "Klang Dong",
          "postCode": [
            "30320"
          ]
        },
        {
          "id": 302105,
          "name": "Mu Si",
          "postCode": [
            "30130"
          ]
        },
        {
          "id": 302110,
          "name": "Nong Nam Daeng",
          "postCode": [
            "30130"
          ]
        },
        {
          "id": 302106,
          "name": "Nong Sarai",
          "postCode": [
            "30130"
          ]
        },
        {
          "id": 302101,
          "name": "Pak Chong",
          "postCode": [
            "30130"
          ]
        },
        {
          "id": 302112,
          "name": "Phaya Yen",
          "postCode": [
            "30320"
          ]
        },
        {
          "id": 302108,
          "name": "Pong Talong",
          "postCode": [
            "30130"
          ]
        },
        {
          "id": 302104,
          "name": "Wang Katha",
          "postCode": [
            "30130"
          ]
        },
        {
          "id": 302111,
          "name": "Wang Sai",
          "postCode": [
            "30130"
          ]
        }
      ]
    },
    {
      "city": "Pak Thong Chai",
      "cityCode": 3014,
      "provinceCode": 30,
      "subDistrict": [
        {
          "id": 301420,
          "name": "Bo Pla Thong",
          "postCode": [
            "30150"
          ]
        },
        {
          "id": 301407,
          "name": "Don",
          "postCode": [
            "30150"
          ]
        },
        {
          "id": 301419,
          "name": "Kasem Sap",
          "postCode": [
            "30150"
          ]
        },
        {
          "id": 301403,
          "name": "Khok Thai",
          "postCode": [
            "30150"
          ]
        },
        {
          "id": 301412,
          "name": "Lam Nang Kaeo",
          "postCode": [
            "30150"
          ]
        },
        {
          "id": 301401,
          "name": "Mueang Pak",
          "postCode": [
            "30150"
          ]
        },
        {
          "id": 301410,
          "name": "Ngio",
          "postCode": [
            "30150"
          ]
        },
        {
          "id": 301406,
          "name": "Nok Ok",
          "postCode": [
            "30150"
          ]
        },
        {
          "id": 301416,
          "name": "Phu Luang",
          "postCode": [
            "30150"
          ]
        },
        {
          "id": 301411,
          "name": "Sakae Rat",
          "postCode": [
            "30150"
          ]
        },
        {
          "id": 301404,
          "name": "Samrong",
          "postCode": [
            "30150"
          ]
        },
        {
          "id": 301418,
          "name": "Suk Kasem",
          "postCode": [
            "30150"
          ]
        },
        {
          "id": 301405,
          "name": "Takhop",
          "postCode": [
            "30150"
          ]
        },
        {
          "id": 301402,
          "name": "Takhu",
          "postCode": [
            "30150"
          ]
        },
        {
          "id": 301417,
          "name": "Thong Chai Nuea",
          "postCode": [
            "30150"
          ]
        },
        {
          "id": 301409,
          "name": "Tum",
          "postCode": [
            "30150"
          ]
        }
      ]
    },
    {
      "city": "Phimai",
      "cityCode": 3015,
      "provinceCode": 30,
      "subDistrict": [
        {
          "id": 301503,
          "name": "Bot",
          "postCode": [
            "30110"
          ]
        },
        {
          "id": 301507,
          "name": "Chiwan",
          "postCode": [
            "30110"
          ]
        },
        {
          "id": 301510,
          "name": "Dong Yai",
          "postCode": [
            "30110"
          ]
        },
        {
          "id": 301504,
          "name": "Krabueang Yai",
          "postCode": [
            "30110"
          ]
        },
        {
          "id": 301509,
          "name": "Krachon",
          "postCode": [
            "30110"
          ]
        },
        {
          "id": 301501,
          "name": "Nai Mueang",
          "postCode": [
            "30110"
          ]
        },
        {
          "id": 301508,
          "name": "Nikhom Sang Ton-eng Phimai",
          "postCode": [
            "30110"
          ]
        },
        {
          "id": 301512,
          "name": "Nong Rawiang",
          "postCode": [
            "30110"
          ]
        },
        {
          "id": 301506,
          "name": "Rang Ka Yai",
          "postCode": [
            "30110"
          ]
        },
        {
          "id": 301502,
          "name": "Samrit",
          "postCode": [
            "30110"
          ]
        },
        {
          "id": 301505,
          "name": "Tha Luang",
          "postCode": [
            "30110"
          ]
        },
        {
          "id": 301511,
          "name": "Than Lalot",
          "postCode": [
            "30110"
          ]
        }
      ]
    },
    {
      "city": "Phra Thong Kham",
      "cityCode": 3028,
      "provinceCode": 30,
      "subDistrict": [
        {
          "id": 302802,
          "name": "Map Krat",
          "postCode": [
            "30220"
          ]
        },
        {
          "id": 302805,
          "name": "Nong Hoi",
          "postCode": [
            "30220"
          ]
        },
        {
          "id": 302803,
          "name": "Phang Thiam",
          "postCode": [
            "30220"
          ]
        },
        {
          "id": 302801,
          "name": "Sa Phra",
          "postCode": [
            "30220"
          ]
        },
        {
          "id": 302804,
          "name": "Thap Rang",
          "postCode": [
            "30220"
          ]
        }
      ]
    },
    {
      "city": "Prathai",
      "cityCode": 3013,
      "provinceCode": 30,
      "subDistrict": [
        {
          "id": 301310,
          "name": "Don Man",
          "postCode": [
            "30180"
          ]
        },
        {
          "id": 301309,
          "name": "Han Huai Sai",
          "postCode": [
            "30180"
          ]
        },
        {
          "id": 301317,
          "name": "Khok Klang",
          "postCode": [
            "30180"
          ]
        },
        {
          "id": 301303,
          "name": "Krathum Rai",
          "postCode": [
            "30180"
          ]
        },
        {
          "id": 301318,
          "name": "Mueang Don",
          "postCode": [
            "30180"
          ]
        },
        {
          "id": 301313,
          "name": "Nang Ram",
          "postCode": [
            "30180"
          ]
        },
        {
          "id": 301314,
          "name": "Non Phet",
          "postCode": [
            "30180"
          ]
        },
        {
          "id": 301308,
          "name": "Nong Khai",
          "postCode": [
            "30180"
          ]
        },
        {
          "id": 301307,
          "name": "Nong Phluang",
          "postCode": [
            "30180"
          ]
        },
        {
          "id": 301301,
          "name": "Prathai",
          "postCode": [
            "30180"
          ]
        },
        {
          "id": 301306,
          "name": "Talat Sai",
          "postCode": [
            "30180"
          ]
        },
        {
          "id": 301315,
          "name": "Thung Sawang",
          "postCode": [
            "30180"
          ]
        },
        {
          "id": 301304,
          "name": "Wang Mai Daeng",
          "postCode": [
            "30180"
          ]
        }
      ]
    },
    {
      "city": "Sida",
      "cityCode": 3031,
      "provinceCode": 30,
      "subDistrict": [
        {
          "id": 303103,
          "name": "Non Pladu",
          "postCode": [
            "30430"
          ]
        },
        {
          "id": 303105,
          "name": "Nong Tat Yai",
          "postCode": [
            "30430"
          ]
        },
        {
          "id": 303102,
          "name": "Phon Thong",
          "postCode": [
            "30430"
          ]
        },
        {
          "id": 303104,
          "name": "Sam Mueang",
          "postCode": [
            "30430"
          ]
        },
        {
          "id": 303101,
          "name": "Sida",
          "postCode": [
            "30430"
          ]
        }
      ]
    },
    {
      "city": "Sikhio",
      "cityCode": 3020,
      "provinceCode": 30,
      "subDistrict": [
        {
          "id": 302002,
          "name": "Ban Han",
          "postCode": [
            "30140"
          ]
        },
        {
          "id": 302011,
          "name": "Don Mueang",
          "postCode": [
            "30140"
          ]
        },
        {
          "id": 302010,
          "name": "Khlong Phai",
          "postCode": [
            "30340"
          ]
        },
        {
          "id": 302003,
          "name": "Kritsana",
          "postCode": [
            "30140"
          ]
        },
        {
          "id": 302006,
          "name": "Kut Noi",
          "postCode": [
            "30140"
          ]
        },
        {
          "id": 302004,
          "name": "Lat Bua Khao",
          "postCode": [
            "30340"
          ]
        },
        {
          "id": 302009,
          "name": "Mittraphap",
          "postCode": [
            "30140"
          ]
        },
        {
          "id": 302012,
          "name": "Nong Bua Noi",
          "postCode": [
            "30140"
          ]
        },
        {
          "id": 302007,
          "name": "Nong Nam Sai",
          "postCode": [
            "30140"
          ]
        },
        {
          "id": 302005,
          "name": "Nong Ya Khao",
          "postCode": [
            "30140"
          ]
        },
        {
          "id": 302001,
          "name": "Sikhio",
          "postCode": [
            "30140"
          ]
        },
        {
          "id": 302008,
          "name": "Wang Rong Yai",
          "postCode": [
            "30140"
          ]
        }
      ]
    },
    {
      "city": "Soeng Sang",
      "cityCode": 3003,
      "provinceCode": 30,
      "subDistrict": [
        {
          "id": 300306,
          "name": "Ban Rat",
          "postCode": [
            "30330"
          ]
        },
        {
          "id": 300304,
          "name": "Kut Bot",
          "postCode": [
            "30330"
          ]
        },
        {
          "id": 300303,
          "name": "Non Sombun",
          "postCode": [
            "30330"
          ]
        },
        {
          "id": 300302,
          "name": "Sa Takhian",
          "postCode": [
            "30330"
          ]
        },
        {
          "id": 300301,
          "name": "Soeng Sang",
          "postCode": [
            "30330"
          ]
        },
        {
          "id": 300305,
          "name": "Suk Phaibun",
          "postCode": [
            "30330"
          ]
        }
      ]
    },
    {
      "city": "Sung Noen",
      "cityCode": 3018,
      "provinceCode": 30,
      "subDistrict": [
        {
          "id": 301804,
          "name": "Bung Khilek",
          "postCode": [
            "30170"
          ]
        },
        {
          "id": 301806,
          "name": "Khong Yang",
          "postCode": [
            "30170"
          ]
        },
        {
          "id": 301803,
          "name": "Khorat",
          "postCode": [
            "30170"
          ]
        },
        {
          "id": 301811,
          "name": "Kut Chik",
          "postCode": [
            "30380"
          ]
        },
        {
          "id": 301807,
          "name": "Makluea Kao",
          "postCode": [
            "30170"
          ]
        },
        {
          "id": 301808,
          "name": "Makluea Mai",
          "postCode": [
            "30170"
          ]
        },
        {
          "id": 301809,
          "name": "Na Klang",
          "postCode": [
            "30380"
          ]
        },
        {
          "id": 301805,
          "name": "Non Kha",
          "postCode": [
            "30170"
          ]
        },
        {
          "id": 301810,
          "name": "Nong Takai",
          "postCode": [
            "30380"
          ]
        },
        {
          "id": 301802,
          "name": "Sema",
          "postCode": [
            "30170"
          ]
        },
        {
          "id": 301801,
          "name": "Sung Noen",
          "postCode": [
            "30170"
          ]
        }
      ]
    },
    {
      "city": "Thepharak",
      "cityCode": 3026,
      "provinceCode": 30,
      "subDistrict": [
        {
          "id": 302603,
          "name": "Bueng Prue",
          "postCode": [
            "30210"
          ]
        },
        {
          "id": 302602,
          "name": "Nong Waeng",
          "postCode": [
            "30210"
          ]
        },
        {
          "id": 302601,
          "name": "Samnak Takhro",
          "postCode": [
            "30210"
          ]
        },
        {
          "id": 302604,
          "name": "Wang Yai Thong",
          "postCode": [
            "30210"
          ]
        }
      ]
    },
    {
      "city": "Wang Nam Khiao",
      "cityCode": 3025,
      "provinceCode": 30,
      "subDistrict": [
        {
          "id": 302503,
          "name": "Raroeng",
          "postCode": [
            "30150"
          ]
        },
        {
          "id": 302505,
          "name": "Thai Samakkhi",
          "postCode": [
            "30370"
          ]
        },
        {
          "id": 302504,
          "name": "Udom Sap",
          "postCode": [
            "30370"
          ]
        },
        {
          "id": 302502,
          "name": "Wang Mi",
          "postCode": [
            "30370"
          ]
        },
        {
          "id": 302501,
          "name": "Wang Nam Khiao",
          "postCode": [
            "30370"
          ]
        }
      ]
    },
    {
      "city": "Banphot Phisai",
      "cityCode": 6005,
      "provinceCode": 60,
      "subDistrict": [
        {
          "id": 600504,
          "name": "Ang Thong",
          "postCode": [
            "60180"
          ]
        },
        {
          "id": 600505,
          "name": "Ban Daen",
          "postCode": [
            "60180"
          ]
        },
        {
          "id": 600506,
          "name": "Bang Kaeo",
          "postCode": [
            "60180"
          ]
        },
        {
          "id": 600502,
          "name": "Bang Ta Ngai",
          "postCode": [
            "60180"
          ]
        },
        {
          "id": 600512,
          "name": "Bueng Pla Thu",
          "postCode": [
            "60180"
          ]
        },
        {
          "id": 600513,
          "name": "Charoen Phon",
          "postCode": [
            "60180"
          ]
        },
        {
          "id": 600509,
          "name": "Dan Chang",
          "postCode": [
            "60180"
          ]
        },
        {
          "id": 600503,
          "name": "Hu Kwang",
          "postCode": [
            "60180"
          ]
        },
        {
          "id": 600510,
          "name": "Nong Krot",
          "postCode": [
            "60180"
          ]
        },
        {
          "id": 600511,
          "name": "Nong Ta Ngu",
          "postCode": [
            "60180"
          ]
        },
        {
          "id": 600507,
          "name": "Ta Khit",
          "postCode": [
            "60180"
          ]
        },
        {
          "id": 600508,
          "name": "Ta Sang",
          "postCode": [
            "60180"
          ]
        },
        {
          "id": 600501,
          "name": "Tha Ngio",
          "postCode": [
            "60180"
          ]
        }
      ]
    },
    {
      "city": "Chum Saeng",
      "cityCode": 6003,
      "provinceCode": 60,
      "subDistrict": [
        {
          "id": 600306,
          "name": "Bang Khian",
          "postCode": [
            "60120"
          ]
        },
        {
          "id": 600301,
          "name": "Chum Saeng",
          "postCode": [
            "60120"
          ]
        },
        {
          "id": 600311,
          "name": "Khamang",
          "postCode": [
            "60120"
          ]
        },
        {
          "id": 600309,
          "name": "Khok Mo",
          "postCode": [
            "60120"
          ]
        },
        {
          "id": 600304,
          "name": "Koei Chai",
          "postCode": [
            "60120"
          ]
        },
        {
          "id": 600307,
          "name": "Nong Krachao",
          "postCode": [
            "60120"
          ]
        },
        {
          "id": 600310,
          "name": "Phai Sing",
          "postCode": [
            "60120"
          ]
        },
        {
          "id": 600308,
          "name": "Phan Lan",
          "postCode": [
            "60250"
          ]
        },
        {
          "id": 600303,
          "name": "Phikun",
          "postCode": [
            "60120"
          ]
        },
        {
          "id": 600305,
          "name": "Tha Mai",
          "postCode": [
            "60120"
          ]
        },
        {
          "id": 600302,
          "name": "Thap Krit",
          "postCode": [
            "60250"
          ]
        },
        {
          "id": 600312,
          "name": "Thap Krit Tai",
          "postCode": [
            "60250"
          ]
        }
      ]
    },
    {
      "city": "Chum Ta Bong",
      "cityCode": 6015,
      "provinceCode": 60,
      "subDistrict": [
        {
          "id": 601501,
          "name": "Chum Ta Bong",
          "postCode": [
            "60150"
          ]
        },
        {
          "id": 601502,
          "name": "Pang Sawan",
          "postCode": [
            "60150"
          ]
        }
      ]
    },
    {
      "city": "Kao Liao",
      "cityCode": 6006,
      "provinceCode": 60,
      "subDistrict": [
        {
          "id": 600605,
          "name": "Hua Dong",
          "postCode": [
            "60230"
          ]
        },
        {
          "id": 600602,
          "name": "Kao Liao",
          "postCode": [
            "60230"
          ]
        },
        {
          "id": 600604,
          "name": "Khao Din",
          "postCode": [
            "60230"
          ]
        },
        {
          "id": 600601,
          "name": "Maha Phot",
          "postCode": [
            "60230"
          ]
        },
        {
          "id": 600603,
          "name": "Nong Tao",
          "postCode": [
            "60230"
          ]
        }
      ]
    },
    {
      "city": "Krok Phra",
      "cityCode": 6002,
      "provinceCode": 60,
      "subDistrict": [
        {
          "id": 600203,
          "name": "Bang Mafo",
          "postCode": [
            "60170"
          ]
        },
        {
          "id": 600204,
          "name": "Bang Pramung",
          "postCode": [
            "60170"
          ]
        },
        {
          "id": 600209,
          "name": "Hat Sung",
          "postCode": [
            "60170"
          ]
        },
        {
          "id": 600201,
          "name": "Krok Phra",
          "postCode": [
            "60170"
          ]
        },
        {
          "id": 600205,
          "name": "Na Klang",
          "postCode": [
            "60170"
          ]
        },
        {
          "id": 600207,
          "name": "Noen Kwao",
          "postCode": [
            "60170"
          ]
        },
        {
          "id": 600208,
          "name": "Noen Sala",
          "postCode": [
            "60170"
          ]
        },
        {
          "id": 600206,
          "name": "Sala Daeng",
          "postCode": [
            "60170"
          ]
        },
        {
          "id": 600202,
          "name": "Yang Tan",
          "postCode": [
            "60170"
          ]
        }
      ]
    },
    {
      "city": "Lat Yao",
      "cityCode": 6011,
      "provinceCode": 60,
      "subDistrict": [
        {
          "id": 601109,
          "name": "Ban Rai",
          "postCode": [
            "60150"
          ]
        },
        {
          "id": 601102,
          "name": "Huai Nam Hom",
          "postCode": [
            "60150"
          ]
        },
        {
          "id": 601101,
          "name": "Lat Yao",
          "postCode": [
            "60150"
          ]
        },
        {
          "id": 601106,
          "name": "Map Kae",
          "postCode": [
            "60150"
          ]
        },
        {
          "id": 601110,
          "name": "Noen Khilek",
          "postCode": [
            "60150"
          ]
        },
        {
          "id": 601108,
          "name": "Nong Nom Wua",
          "postCode": [
            "60150"
          ]
        },
        {
          "id": 601107,
          "name": "Nong Yao",
          "postCode": [
            "60150"
          ]
        },
        {
          "id": 601117,
          "name": "Sa Kaeo",
          "postCode": [
            "60150"
          ]
        },
        {
          "id": 601116,
          "name": "San Chao Kai to",
          "postCode": [
            "60150"
          ]
        },
        {
          "id": 601105,
          "name": "Soi Lakhon",
          "postCode": [
            "60150"
          ]
        },
        {
          "id": 601103,
          "name": "Wang Ma",
          "postCode": [
            "60150"
          ]
        },
        {
          "id": 601104,
          "name": "Wang Mueang",
          "postCode": [
            "60150"
          ]
        }
      ]
    },
    {
      "city": "Mae Poen",
      "cityCode": 6014,
      "provinceCode": 60,
      "subDistrict": [
        {
          "id": 601401,
          "name": "Mae Poen",
          "postCode": [
            "60150"
          ]
        }
      ]
    },
    {
      "city": "Mae Wong",
      "cityCode": 6013,
      "provinceCode": 60,
      "subDistrict": [
        {
          "id": 601305,
          "name": "Khao Chon Kan",
          "postCode": [
            "60150"
          ]
        },
        {
          "id": 601303,
          "name": "Mae Le",
          "postCode": [
            "60150"
          ]
        },
        {
          "id": 601301,
          "name": "Mae Wong",
          "postCode": [
            "60150"
          ]
        },
        {
          "id": 601304,
          "name": "Wang San",
          "postCode": [
            "60150"
          ]
        }
      ]
    },
    {
      "city": "Mueang Nakhon Sawan",
      "cityCode": 6001,
      "provinceCode": 60,
      "subDistrict": [
        {
          "id": 600111,
          "name": "Ban Kaeng",
          "postCode": [
            "60000"
          ]
        },
        {
          "id": 600110,
          "name": "Ban Makluea",
          "postCode": [
            "60000"
          ]
        },
        {
          "id": 600109,
          "name": "Bang Muang",
          "postCode": [
            "60000"
          ]
        },
        {
          "id": 600108,
          "name": "Bang Phra Luang",
          "postCode": [
            "60000"
          ]
        },
        {
          "id": 600117,
          "name": "Bueng Senat",
          "postCode": [
            "60000"
          ]
        },
        {
          "id": 600104,
          "name": "Khwae Yai",
          "postCode": [
            "60000"
          ]
        },
        {
          "id": 600102,
          "name": "Klang Daet",
          "postCode": [
            "60000"
          ]
        },
        {
          "id": 600103,
          "name": "Kriangkrai",
          "postCode": [
            "60000"
          ]
        },
        {
          "id": 600107,
          "name": "Nakhon Sawan Ok",
          "postCode": [
            "60000"
          ]
        },
        {
          "id": 600106,
          "name": "Nakhon Sawan Tok",
          "postCode": [
            "60000"
          ]
        },
        {
          "id": 600115,
          "name": "Nong Kradon",
          "postCode": [
            "60240"
          ]
        },
        {
          "id": 600114,
          "name": "Nong Krot",
          "postCode": [
            "60240"
          ]
        },
        {
          "id": 600116,
          "name": "Nong Pling",
          "postCode": [
            "60000"
          ]
        },
        {
          "id": 600101,
          "name": "Pak Nam Pho",
          "postCode": [
            "60000"
          ]
        },
        {
          "id": 600112,
          "name": "Phra Non",
          "postCode": [
            "60000"
          ]
        },
        {
          "id": 600105,
          "name": "Takhian Luean",
          "postCode": [
            "60000"
          ]
        },
        {
          "id": 600113,
          "name": "Wat Sai",
          "postCode": [
            "60000"
          ]
        }
      ]
    },
    {
      "city": "Nong Bua",
      "cityCode": 6004,
      "provinceCode": 60,
      "subDistrict": [
        {
          "id": 600404,
          "name": "Huai Ruam",
          "postCode": [
            "60110"
          ]
        },
        {
          "id": 600406,
          "name": "Huai Thua Nuea",
          "postCode": [
            "60110"
          ]
        },
        {
          "id": 600405,
          "name": "Huai Thua Tai",
          "postCode": [
            "60110"
          ]
        },
        {
          "id": 600407,
          "name": "Huai Yai",
          "postCode": [
            "60110"
          ]
        },
        {
          "id": 600401,
          "name": "Nong Bua",
          "postCode": [
            "60110"
          ]
        },
        {
          "id": 600402,
          "name": "Nong Klap",
          "postCode": [
            "60110"
          ]
        },
        {
          "id": 600403,
          "name": "Than Thahan",
          "postCode": [
            "60110"
          ]
        },
        {
          "id": 600408,
          "name": "Thung Thong",
          "postCode": [
            "60110"
          ]
        },
        {
          "id": 600409,
          "name": "Wang Bo",
          "postCode": [
            "60110"
          ]
        }
      ]
    },
    {
      "city": "Phaisali",
      "cityCode": 6009,
      "provinceCode": 60,
      "subDistrict": [
        {
          "id": 600901,
          "name": "Khok Duea",
          "postCode": [
            "60220"
          ]
        },
        {
          "id": 600907,
          "name": "Na Khom",
          "postCode": [
            "60220"
          ]
        },
        {
          "id": 600908,
          "name": "Phaisali",
          "postCode": [
            "60220"
          ]
        },
        {
          "id": 600905,
          "name": "Pho Prasat",
          "postCode": [
            "60220"
          ]
        },
        {
          "id": 600902,
          "name": "Samrong Chai",
          "postCode": [
            "60220"
          ]
        },
        {
          "id": 600904,
          "name": "Takhro",
          "postCode": [
            "60220"
          ]
        },
        {
          "id": 600906,
          "name": "Wang Khoi",
          "postCode": [
            "60220"
          ]
        },
        {
          "id": 600903,
          "name": "Wang Nam Lat",
          "postCode": [
            "60220"
          ]
        }
      ]
    },
    {
      "city": "Phayuha Khiri",
      "cityCode": 6010,
      "provinceCode": 60,
      "subDistrict": [
        {
          "id": 601010,
          "name": "Khao Kala",
          "postCode": [
            "60130"
          ]
        },
        {
          "id": 601007,
          "name": "Khao Thong",
          "postCode": [
            "60130"
          ]
        },
        {
          "id": 601004,
          "name": "Muang Hak",
          "postCode": [
            "60130"
          ]
        },
        {
          "id": 601009,
          "name": "Nam Song",
          "postCode": [
            "60130"
          ]
        },
        {
          "id": 601003,
          "name": "Nikhom Khao Bo Kaeo",
          "postCode": [
            "60130"
          ]
        },
        {
          "id": 601002,
          "name": "Noen Makok",
          "postCode": [
            "60130"
          ]
        },
        {
          "id": 601001,
          "name": "Phayuha",
          "postCode": [
            "60130"
          ]
        },
        {
          "id": 601011,
          "name": "Sa Thale",
          "postCode": [
            "60130"
          ]
        },
        {
          "id": 601008,
          "name": "Tha Nam Oi",
          "postCode": [
            "60130"
          ]
        },
        {
          "id": 601006,
          "name": "Yan Matsi",
          "postCode": [
            "60130"
          ]
        },
        {
          "id": 601005,
          "name": "Yang Khao",
          "postCode": [
            "60130"
          ]
        }
      ]
    },
    {
      "city": "Tak Fa",
      "cityCode": 6012,
      "provinceCode": 60,
      "subDistrict": [
        {
          "id": 601207,
          "name": "Khao Chai Thong",
          "postCode": [
            "60190"
          ]
        },
        {
          "id": 601202,
          "name": "Lam Phayon",
          "postCode": [
            "60190"
          ]
        },
        {
          "id": 601204,
          "name": "Nong Phikun",
          "postCode": [
            "60190"
          ]
        },
        {
          "id": 601205,
          "name": "Phu Nok Yung",
          "postCode": [
            "60190"
          ]
        },
        {
          "id": 601203,
          "name": "Suk Samran",
          "postCode": [
            "60190"
          ]
        },
        {
          "id": 601201,
          "name": "Tak Fa",
          "postCode": [
            "60190"
          ]
        },
        {
          "id": 601206,
          "name": "Udom Thanya",
          "postCode": [
            "60190"
          ]
        }
      ]
    },
    {
      "city": "Takhli",
      "cityCode": 6007,
      "provinceCode": 60,
      "subDistrict": [
        {
          "id": 600703,
          "name": "Chan Sen",
          "postCode": [
            "60260"
          ]
        },
        {
          "id": 600702,
          "name": "Chong Khae",
          "postCode": [
            "60210"
          ]
        },
        {
          "id": 600705,
          "name": "Hua Wai",
          "postCode": [
            "60140"
          ]
        },
        {
          "id": 600704,
          "name": "Huai Hom",
          "postCode": [
            "60210"
          ]
        },
        {
          "id": 600709,
          "name": "Lat Thippharot",
          "postCode": [
            "60260"
          ]
        },
        {
          "id": 600707,
          "name": "Nong Mo",
          "postCode": [
            "60140"
          ]
        },
        {
          "id": 600706,
          "name": "Nong Pho",
          "postCode": [
            "60140"
          ]
        },
        {
          "id": 600710,
          "name": "Phrom Nimit",
          "postCode": [
            "60210"
          ]
        },
        {
          "id": 600708,
          "name": "Soi Thong",
          "postCode": [
            "60210"
          ]
        },
        {
          "id": 600701,
          "name": "Takhli",
          "postCode": [
            "60140"
          ]
        }
      ]
    },
    {
      "city": "Tha Tako",
      "cityCode": 6008,
      "provinceCode": 60,
      "subDistrict": [
        {
          "id": 600806,
          "name": "Don Kha",
          "postCode": [
            "60160"
          ]
        },
        {
          "id": 600803,
          "name": "Hua Thanon",
          "postCode": [
            "60160"
          ]
        },
        {
          "id": 600810,
          "name": "Nong Luang",
          "postCode": [
            "60160"
          ]
        },
        {
          "id": 600802,
          "name": "Phanom Rok",
          "postCode": [
            "60160"
          ]
        },
        {
          "id": 600809,
          "name": "Phanom Set",
          "postCode": [
            "60160"
          ]
        },
        {
          "id": 600804,
          "name": "Sai Lamphong",
          "postCode": [
            "60160"
          ]
        },
        {
          "id": 600801,
          "name": "Tha Tako",
          "postCode": [
            "60160"
          ]
        },
        {
          "id": 600807,
          "name": "Thamnop",
          "postCode": [
            "60160"
          ]
        },
        {
          "id": 600805,
          "name": "Wang Mahakon",
          "postCode": [
            "60160"
          ]
        },
        {
          "id": 600808,
          "name": "Wang Yai",
          "postCode": [
            "60160"
          ]
        }
      ]
    },
    {
      "city": "Bang Khan",
      "cityCode": 8017,
      "provinceCode": 80,
      "subDistrict": [
        {
          "id": 801702,
          "name": "Ban Lam Nao",
          "postCode": [
            "80360"
          ]
        },
        {
          "id": 801704,
          "name": "Ban Nikhom",
          "postCode": [
            "80360"
          ]
        },
        {
          "id": 801701,
          "name": "Bang Khan",
          "postCode": [
            "80360"
          ]
        },
        {
          "id": 801703,
          "name": "Wang Hin",
          "postCode": [
            "80360"
          ]
        }
      ]
    },
    {
      "city": "Cha-uat",
      "cityCode": 8007,
      "provinceCode": 80,
      "subDistrict": [
        {
          "id": 800706,
          "name": "Ban Tun",
          "postCode": [
            "80180"
          ]
        },
        {
          "id": 800701,
          "name": "Cha-uat",
          "postCode": [
            "80180"
          ]
        },
        {
          "id": 800710,
          "name": "Khao Phra Thong",
          "postCode": [
            "80180"
          ]
        },
        {
          "id": 800707,
          "name": "Khon Hat",
          "postCode": [
            "80180"
          ]
        },
        {
          "id": 800704,
          "name": "Khreng",
          "postCode": [
            "80180"
          ]
        },
        {
          "id": 800709,
          "name": "Khuan Nong Hong",
          "postCode": [
            "80180"
          ]
        },
        {
          "id": 800708,
          "name": "Ko Khan",
          "postCode": [
            "80180"
          ]
        },
        {
          "id": 800711,
          "name": "Nong Long",
          "postCode": [
            "80180"
          ]
        },
        {
          "id": 800703,
          "name": "Tha Pracha",
          "postCode": [
            "80180"
          ]
        },
        {
          "id": 800702,
          "name": "Tha Samet",
          "postCode": [
            "80180"
          ]
        },
        {
          "id": 800705,
          "name": "Wang Ang",
          "postCode": [
            "80180"
          ]
        }
      ]
    },
    {
      "city": "Chaloem Phra Kiat",
      "cityCode": 8023,
      "provinceCode": 80,
      "subDistrict": [
        {
          "id": 802301,
          "name": "Chian Khao",
          "postCode": [
            "80190"
          ]
        },
        {
          "id": 802302,
          "name": "Don Tro",
          "postCode": [
            "80290"
          ]
        },
        {
          "id": 802303,
          "name": "Suan Luang",
          "postCode": [
            "80190"
          ]
        },
        {
          "id": 802304,
          "name": "Thang Phun",
          "postCode": [
            "80290",
            "80350"
          ]
        }
      ]
    },
    {
      "city": "Chang Klang",
      "cityCode": 8022,
      "provinceCode": 80,
      "subDistrict": [
        {
          "id": 802201,
          "name": "Chang Klang",
          "postCode": [
            "80220",
            "80250"
          ]
        },
        {
          "id": 802202,
          "name": "Lak Chang",
          "postCode": [
            "80250"
          ]
        },
        {
          "id": 802203,
          "name": "Suan Khan",
          "postCode": [
            "80250"
          ]
        }
      ]
    },
    {
      "city": "Chawang",
      "cityCode": 8004,
      "provinceCode": 80,
      "subDistrict": [
        {
          "id": 800416,
          "name": "Chan Di",
          "postCode": [
            "80250"
          ]
        },
        {
          "id": 800401,
          "name": "Chawang",
          "postCode": [
            "80150",
            "80250"
          ]
        },
        {
          "id": 800409,
          "name": "Huai Prik",
          "postCode": [
            "80260"
          ]
        },
        {
          "id": 800406,
          "name": "Kapiat",
          "postCode": [
            "80260"
          ]
        },
        {
          "id": 800403,
          "name": "La-ai",
          "postCode": [
            "80250"
          ]
        },
        {
          "id": 800405,
          "name": "Mai Riang",
          "postCode": [
            "80150",
            "80260"
          ]
        },
        {
          "id": 800407,
          "name": "Na Kacha",
          "postCode": [
            "80150",
            "80260"
          ]
        },
        {
          "id": 800415,
          "name": "Na Khliang",
          "postCode": [
            "80260"
          ]
        },
        {
          "id": 800404,
          "name": "Na Wae",
          "postCode": [
            "80150",
            "80260"
          ]
        },
        {
          "id": 800410,
          "name": "Sai Ra",
          "postCode": [
            "80150"
          ]
        }
      ]
    },
    {
      "city": "Chian Yai",
      "cityCode": 8006,
      "provinceCode": 80,
      "subDistrict": [
        {
          "id": 800604,
          "name": "Ban Klang",
          "postCode": [
            "80190"
          ]
        },
        {
          "id": 800605,
          "name": "Ban Noen",
          "postCode": [
            "80190"
          ]
        },
        {
          "id": 800601,
          "name": "Chian Yai",
          "postCode": [
            "80190"
          ]
        },
        {
          "id": 800611,
          "name": "Karaket",
          "postCode": [
            "80190"
          ]
        },
        {
          "id": 800612,
          "name": "Khao Phra Bat",
          "postCode": [
            "80190"
          ]
        },
        {
          "id": 800613,
          "name": "Mae chao Yu Hua",
          "postCode": [
            "80190"
          ]
        },
        {
          "id": 800606,
          "name": "Sai Mak",
          "postCode": [
            "80190"
          ]
        },
        {
          "id": 800610,
          "name": "Suea Hueng",
          "postCode": [
            "80190"
          ]
        },
        {
          "id": 800603,
          "name": "Tha Khanan",
          "postCode": [
            "80190"
          ]
        },
        {
          "id": 800607,
          "name": "Thong Lamchiak",
          "postCode": [
            "80190"
          ]
        }
      ]
    },
    {
      "city": "Chulabhorn",
      "cityCode": 8019,
      "provinceCode": 80,
      "subDistrict": [
        {
          "id": 801902,
          "name": "Ban Cha-uat",
          "postCode": [
            "80180"
          ]
        },
        {
          "id": 801901,
          "name": "Ban Khuan Mut",
          "postCode": [
            "80180"
          ]
        },
        {
          "id": 801903,
          "name": "Khuan Nong Khwa",
          "postCode": [
            "80130"
          ]
        },
        {
          "id": 801905,
          "name": "Na Mo Bun",
          "postCode": [
            "80130"
          ]
        },
        {
          "id": 801906,
          "name": "Sam Tambon",
          "postCode": [
            "80130"
          ]
        },
        {
          "id": 801904,
          "name": "Thung Pho",
          "postCode": [
            "80130"
          ]
        }
      ]
    },
    {
      "city": "Hua Sai",
      "cityCode": 8016,
      "provinceCode": 80,
      "subDistrict": [
        {
          "id": 801606,
          "name": "Ban Ram",
          "postCode": [
            "80170"
          ]
        },
        {
          "id": 801607,
          "name": "Bang Nop",
          "postCode": [
            "80170"
          ]
        },
        {
          "id": 801601,
          "name": "Hua Sai",
          "postCode": [
            "80170"
          ]
        },
        {
          "id": 801605,
          "name": "Khao Phang Krai",
          "postCode": [
            "80170"
          ]
        },
        {
          "id": 801609,
          "name": "Khuan Chalik",
          "postCode": [
            "80170"
          ]
        },
        {
          "id": 801611,
          "name": "Ko Phet",
          "postCode": [
            "80170"
          ]
        },
        {
          "id": 801604,
          "name": "Laem",
          "postCode": [
            "80170"
          ]
        },
        {
          "id": 801602,
          "name": "Na Saton",
          "postCode": [
            "80170"
          ]
        },
        {
          "id": 801610,
          "name": "Ram Kaeo",
          "postCode": [
            "80170"
          ]
        },
        {
          "id": 801603,
          "name": "Sai Khao",
          "postCode": [
            "80170"
          ]
        },
        {
          "id": 801608,
          "name": "Tha Som",
          "postCode": [
            "80170"
          ]
        }
      ]
    },
    {
      "city": "Khanom",
      "cityCode": 8015,
      "provinceCode": 80,
      "subDistrict": [
        {
          "id": 801501,
          "name": "Khanom",
          "postCode": [
            "80210"
          ]
        },
        {
          "id": 801502,
          "name": "Khuan Thong",
          "postCode": [
            "80210"
          ]
        },
        {
          "id": 801503,
          "name": "Thong Nian",
          "postCode": [
            "80210"
          ]
        }
      ]
    },
    {
      "city": "Lan Saka",
      "cityCode": 8003,
      "provinceCode": 80,
      "subDistrict": [
        {
          "id": 800304,
          "name": "Kamlon",
          "postCode": [
            "80230"
          ]
        },
        {
          "id": 800301,
          "name": "Khao Kaeo",
          "postCode": [
            "80230"
          ]
        },
        {
          "id": 800305,
          "name": "Khun Thale",
          "postCode": [
            "80230"
          ]
        },
        {
          "id": 800302,
          "name": "Lan Saka",
          "postCode": [
            "80230"
          ]
        },
        {
          "id": 800303,
          "name": "Tha Di",
          "postCode": [
            "80230"
          ]
        }
      ]
    },
    {
      "city": "Mueang Nakhon Si Thammarat",
      "cityCode": 8001,
      "provinceCode": 80,
      "subDistrict": [
        {
          "id": 800119,
          "name": "Bang Chak",
          "postCode": [
            "80330"
          ]
        },
        {
          "id": 800113,
          "name": "Chai Montri",
          "postCode": [
            "80000"
          ]
        },
        {
          "id": 800112,
          "name": "Kamphaeng Sao",
          "postCode": [
            "80280"
          ]
        },
        {
          "id": 800103,
          "name": "Khlang",
          "postCode": [
            "80000"
          ]
        },
        {
          "id": 800114,
          "name": "Mamuang Song Ton",
          "postCode": [
            "80000"
          ]
        },
        {
          "id": 800115,
          "name": "Na Khian",
          "postCode": [
            "80000"
          ]
        },
        {
          "id": 800108,
          "name": "Na Sai",
          "postCode": [
            "80280"
          ]
        },
        {
          "id": 800101,
          "name": "Nai Mueang",
          "postCode": [
            "80000"
          ]
        },
        {
          "id": 800107,
          "name": "Pak Nakhon",
          "postCode": [
            "80000"
          ]
        },
        {
          "id": 800120,
          "name": "Pak Phun",
          "postCode": [
            "80000"
          ]
        },
        {
          "id": 800118,
          "name": "Pho Sadet",
          "postCode": [
            "80000"
          ]
        },
        {
          "id": 800116,
          "name": "Tha Ngio",
          "postCode": [
            "80280"
          ]
        },
        {
          "id": 800106,
          "name": "Tha Rai",
          "postCode": [
            "80000"
          ]
        },
        {
          "id": 800122,
          "name": "Tha Ruea",
          "postCode": [
            "80000",
            "80290"
          ]
        },
        {
          "id": 800121,
          "name": "Tha Sak",
          "postCode": [
            "80000"
          ]
        },
        {
          "id": 800102,
          "name": "Tha Wang",
          "postCode": [
            "80000"
          ]
        }
      ]
    },
    {
      "city": "Na Bon",
      "cityCode": 8010,
      "provinceCode": 80,
      "subDistrict": [
        {
          "id": 801003,
          "name": "Kaeo Saen",
          "postCode": [
            "80220",
            "80250"
          ]
        },
        {
          "id": 801001,
          "name": "Na Bon",
          "postCode": [
            "80220"
          ]
        },
        {
          "id": 801002,
          "name": "Thung Song",
          "postCode": [
            "80220"
          ]
        }
      ]
    },
    {
      "city": "Nopphitam",
      "cityCode": 8021,
      "provinceCode": 80,
      "subDistrict": [
        {
          "id": 802103,
          "name": "Kalo",
          "postCode": [
            "80160"
          ]
        },
        {
          "id": 802102,
          "name": "Krung Ching",
          "postCode": [
            "80160"
          ]
        },
        {
          "id": 802104,
          "name": "Na Reng",
          "postCode": [
            "80160"
          ]
        },
        {
          "id": 802101,
          "name": "Nopphitam",
          "postCode": [
            "80160"
          ]
        }
      ]
    },
    {
      "city": "Pak Phanang",
      "cityCode": 8012,
      "provinceCode": 80,
      "subDistrict": [
        {
          "id": 801207,
          "name": "Ban Mai",
          "postCode": [
            "80140"
          ]
        },
        {
          "id": 801215,
          "name": "Ban Phoeng",
          "postCode": [
            "80140"
          ]
        },
        {
          "id": 801212,
          "name": "Bang Phra",
          "postCode": [
            "80140"
          ]
        },
        {
          "id": 801211,
          "name": "Bang Sala",
          "postCode": [
            "80140"
          ]
        },
        {
          "id": 801213,
          "name": "Bang Taphong",
          "postCode": [
            "80140"
          ]
        },
        {
          "id": 801204,
          "name": "Chamao",
          "postCode": [
            "80330"
          ]
        },
        {
          "id": 801208,
          "name": "Hu Long",
          "postCode": [
            "80140"
          ]
        },
        {
          "id": 801218,
          "name": "Khanap Nak",
          "postCode": [
            "80140"
          ]
        },
        {
          "id": 801205,
          "name": "Khlong Krabue",
          "postCode": [
            "80140"
          ]
        },
        {
          "id": 801202,
          "name": "Khlong Noi",
          "postCode": [
            "80330"
          ]
        },
        {
          "id": 801206,
          "name": "Ko Thuat",
          "postCode": [
            "80330"
          ]
        },
        {
          "id": 801209,
          "name": "Laem Talumphuk",
          "postCode": [
            "80140"
          ]
        },
        {
          "id": 801203,
          "name": "Pa Rakam",
          "postCode": [
            "80140"
          ]
        },
        {
          "id": 801201,
          "name": "Pak Phanang",
          "postCode": [
            "80140"
          ]
        },
        {
          "id": 801214,
          "name": "Pak Phanang Fang Tawan Ok",
          "postCode": [
            "80140"
          ]
        },
        {
          "id": 801210,
          "name": "Pak Phanang Fang Tawan Tok",
          "postCode": [
            "80140"
          ]
        },
        {
          "id": 801217,
          "name": "Pak Phraek",
          "postCode": [
            "80140"
          ]
        },
        {
          "id": 801216,
          "name": "Tha Phaya",
          "postCode": [
            "80140"
          ]
        }
      ]
    },
    {
      "city": "Phipun",
      "cityCode": 8005,
      "provinceCode": 80,
      "subDistrict": [
        {
          "id": 800502,
          "name": "Kathun",
          "postCode": [
            "80270"
          ]
        },
        {
          "id": 800503,
          "name": "Khao Phra",
          "postCode": [
            "80270"
          ]
        },
        {
          "id": 800505,
          "name": "Khuan Klang",
          "postCode": [
            "80270"
          ]
        },
        {
          "id": 800501,
          "name": "Phipun",
          "postCode": [
            "80270"
          ]
        },
        {
          "id": 800504,
          "name": "Yang Khom",
          "postCode": [
            "80270"
          ]
        }
      ]
    },
    {
      "city": "Phra Phrom",
      "cityCode": 8020,
      "provinceCode": 80,
      "subDistrict": [
        {
          "id": 802004,
          "name": "Chang Sai",
          "postCode": [
            "80000"
          ]
        },
        {
          "id": 802001,
          "name": "Na Phru",
          "postCode": [
            "80000"
          ]
        },
        {
          "id": 802002,
          "name": "Na San",
          "postCode": [
            "80000"
          ]
        },
        {
          "id": 802003,
          "name": "Thai Samphao",
          "postCode": [
            "80000"
          ]
        }
      ]
    },
    {
      "city": "Phrom Khiri",
      "cityCode": 8002,
      "provinceCode": 80,
      "subDistrict": [
        {
          "id": 800202,
          "name": "Ban Ko",
          "postCode": [
            "80320"
          ]
        },
        {
          "id": 800203,
          "name": "In Khiri",
          "postCode": [
            "80320"
          ]
        },
        {
          "id": 800205,
          "name": "Na Riang",
          "postCode": [
            "80320"
          ]
        },
        {
          "id": 800201,
          "name": "Phrommalok",
          "postCode": [
            "80320"
          ]
        },
        {
          "id": 800204,
          "name": "Thon Hong",
          "postCode": [
            "80320"
          ]
        }
      ]
    },
    {
      "city": "Ron Phibun",
      "cityCode": 8013,
      "provinceCode": 80,
      "subDistrict": [
        {
          "id": 801302,
          "name": "Hin Tok",
          "postCode": [
            "80350"
          ]
        },
        {
          "id": 801306,
          "name": "Khuan Chum",
          "postCode": [
            "80130"
          ]
        },
        {
          "id": 801304,
          "name": "Khuan Koei",
          "postCode": [
            "80130"
          ]
        },
        {
          "id": 801305,
          "name": "Khuan Phang",
          "postCode": [
            "80130"
          ]
        },
        {
          "id": 801301,
          "name": "Ron Phibun",
          "postCode": [
            "80130"
          ]
        },
        {
          "id": 801303,
          "name": "Sao Thong",
          "postCode": [
            "80350"
          ]
        }
      ]
    },
    {
      "city": "Sichon",
      "cityCode": 8014,
      "provinceCode": 80,
      "subDistrict": [
        {
          "id": 801403,
          "name": "Chalong",
          "postCode": [
            "80120"
          ]
        },
        {
          "id": 801408,
          "name": "Khao Noi",
          "postCode": [
            "80120"
          ]
        },
        {
          "id": 801405,
          "name": "Plian",
          "postCode": [
            "80120"
          ]
        },
        {
          "id": 801404,
          "name": "Sao Phao",
          "postCode": [
            "80340"
          ]
        },
        {
          "id": 801406,
          "name": "Si Khit",
          "postCode": [
            "80120"
          ]
        },
        {
          "id": 801401,
          "name": "Sichon",
          "postCode": [
            "80120"
          ]
        },
        {
          "id": 801407,
          "name": "Theppharat",
          "postCode": [
            "80340"
          ]
        },
        {
          "id": 801402,
          "name": "Thung Prang",
          "postCode": [
            "80120"
          ]
        },
        {
          "id": 801409,
          "name": "Thung Sai",
          "postCode": [
            "80120"
          ]
        }
      ]
    },
    {
      "city": "Tha Sala",
      "cityCode": 8008,
      "provinceCode": 80,
      "subDistrict": [
        {
          "id": 800810,
          "name": "Don Tako",
          "postCode": [
            "80160"
          ]
        },
        {
          "id": 800804,
          "name": "Hua Taphan",
          "postCode": [
            "80160"
          ]
        },
        {
          "id": 800802,
          "name": "Klai",
          "postCode": [
            "80160"
          ]
        },
        {
          "id": 800807,
          "name": "Mokkhalan",
          "postCode": [
            "80160"
          ]
        },
        {
          "id": 800813,
          "name": "Pho Thong",
          "postCode": [
            "80160"
          ]
        },
        {
          "id": 800806,
          "name": "Sa Kaeo",
          "postCode": [
            "80160"
          ]
        },
        {
          "id": 800811,
          "name": "Taling Chan",
          "postCode": [
            "80160"
          ]
        },
        {
          "id": 800803,
          "name": "Tha Khuen",
          "postCode": [
            "80160"
          ]
        },
        {
          "id": 800801,
          "name": "Tha Sala",
          "postCode": [
            "80160"
          ]
        },
        {
          "id": 800809,
          "name": "Thai Buri",
          "postCode": [
            "80160"
          ]
        }
      ]
    },
    {
      "city": "Tham Phannara",
      "cityCode": 8018,
      "provinceCode": 80,
      "subDistrict": [
        {
          "id": 801803,
          "name": "Dusit",
          "postCode": [
            "80260"
          ]
        },
        {
          "id": 801802,
          "name": "Khlong Se",
          "postCode": [
            "80260"
          ]
        },
        {
          "id": 801801,
          "name": "Tham Phannara",
          "postCode": [
            "80260"
          ]
        }
      ]
    },
    {
      "city": "Thung Song",
      "cityCode": 8009,
      "provinceCode": 80,
      "subDistrict": [
        {
          "id": 800902,
          "name": "Chamai",
          "postCode": [
            "80110"
          ]
        },
        {
          "id": 800908,
          "name": "Kapang",
          "postCode": [
            "80310"
          ]
        },
        {
          "id": 800913,
          "name": "Khao Khao",
          "postCode": [
            "80110"
          ]
        },
        {
          "id": 800907,
          "name": "Khao Ro",
          "postCode": [
            "80110",
            "80310"
          ]
        },
        {
          "id": 800904,
          "name": "Khuan Krot",
          "postCode": [
            "80110"
          ]
        },
        {
          "id": 800906,
          "name": "Na Luang Sen",
          "postCode": [
            "80110"
          ]
        },
        {
          "id": 800905,
          "name": "Na Mai Phai",
          "postCode": [
            "80110"
          ]
        },
        {
          "id": 800912,
          "name": "Na Pho",
          "postCode": [
            "80110"
          ]
        },
        {
          "id": 800910,
          "name": "Nam Tok",
          "postCode": [
            "80110"
          ]
        },
        {
          "id": 800903,
          "name": "Nong Hong",
          "postCode": [
            "80110"
          ]
        },
        {
          "id": 800901,
          "name": "Pak Phraek",
          "postCode": [
            "80110"
          ]
        },
        {
          "id": 800911,
          "name": "Tham Yai",
          "postCode": [
            "80110"
          ]
        },
        {
          "id": 800909,
          "name": "Thi Wang",
          "postCode": [
            "80110",
            "80310"
          ]
        }
      ]
    },
    {
      "city": "Thung Yai",
      "cityCode": 8011,
      "provinceCode": 80,
      "subDistrict": [
        {
          "id": 801106,
          "name": "Bang Rup",
          "postCode": [
            "80240"
          ]
        },
        {
          "id": 801107,
          "name": "Krung Yan",
          "postCode": [
            "80240"
          ]
        },
        {
          "id": 801104,
          "name": "Kurae",
          "postCode": [
            "80240"
          ]
        },
        {
          "id": 801105,
          "name": "Prik",
          "postCode": [
            "80240"
          ]
        },
        {
          "id": 801101,
          "name": "Tha Yang",
          "postCode": [
            "80240"
          ]
        },
        {
          "id": 801102,
          "name": "Thung Sang",
          "postCode": [
            "80240"
          ]
        },
        {
          "id": 801103,
          "name": "Thung Yai",
          "postCode": [
            "80240"
          ]
        }
      ]
    },
    {
      "city": "Ban Luang",
      "cityCode": 5503,
      "provinceCode": 55,
      "subDistrict": [
        {
          "id": 550301,
          "name": "Ban Fa",
          "postCode": [
            "55190"
          ]
        },
        {
          "id": 550304,
          "name": "Ban Phi",
          "postCode": [
            "55190"
          ]
        },
        {
          "id": 550302,
          "name": "Pa Kha Luang",
          "postCode": [
            "55190"
          ]
        },
        {
          "id": 550303,
          "name": "Suat",
          "postCode": [
            "55190"
          ]
        }
      ]
    },
    {
      "city": "Bo Kluea",
      "cityCode": 5512,
      "provinceCode": 55,
      "subDistrict": [
        {
          "id": 551201,
          "name": "Bo Kluea Nuea",
          "postCode": [
            "55220"
          ]
        },
        {
          "id": 551202,
          "name": "Bo Kluea Tai",
          "postCode": [
            "55220"
          ]
        },
        {
          "id": 551205,
          "name": "Dong Phaya",
          "postCode": [
            "55220"
          ]
        },
        {
          "id": 551204,
          "name": "Phu Fa",
          "postCode": [
            "55220"
          ]
        }
      ]
    },
    {
      "city": "Chaloem Phra Kiat",
      "cityCode": 5515,
      "provinceCode": 55,
      "subDistrict": [
        {
          "id": 551501,
          "name": "Huai Kon",
          "postCode": [
            "55130"
          ]
        },
        {
          "id": 551502,
          "name": "Khun Nan",
          "postCode": [
            "55220"
          ]
        }
      ]
    },
    {
      "city": "Chiang Klang",
      "cityCode": 5509,
      "provinceCode": 55,
      "subDistrict": [
        {
          "id": 550903,
          "name": "Chiang Khan",
          "postCode": [
            "55160"
          ]
        },
        {
          "id": 550901,
          "name": "Chiang Klang",
          "postCode": [
            "55160"
          ]
        },
        {
          "id": 550908,
          "name": "Phaya Kaeo",
          "postCode": [
            "55160"
          ]
        },
        {
          "id": 550909,
          "name": "Phra Phutthabat",
          "postCode": [
            "55160"
          ]
        },
        {
          "id": 550904,
          "name": "Phra That",
          "postCode": [
            "55160"
          ]
        },
        {
          "id": 550902,
          "name": "Puea",
          "postCode": [
            "55160"
          ]
        }
      ]
    },
    {
      "city": "Mae Charim",
      "cityCode": 5502,
      "provinceCode": 55,
      "subDistrict": [
        {
          "id": 550206,
          "name": "Mae Charim",
          "postCode": [
            "55170"
          ]
        },
        {
          "id": 550203,
          "name": "Mo Mueang",
          "postCode": [
            "55170"
          ]
        },
        {
          "id": 550205,
          "name": "Nam Pai",
          "postCode": [
            "55170"
          ]
        },
        {
          "id": 550204,
          "name": "Nam Phang",
          "postCode": [
            "55170"
          ]
        },
        {
          "id": 550202,
          "name": "Nong Daeng",
          "postCode": [
            "55170"
          ]
        }
      ]
    },
    {
      "city": "Mueang Nan",
      "cityCode": 5501,
      "provinceCode": 55,
      "subDistrict": [
        {
          "id": 550102,
          "name": "Bo",
          "postCode": [
            "55000"
          ]
        },
        {
          "id": 550116,
          "name": "Bo Suak",
          "postCode": [
            "55000"
          ]
        },
        {
          "id": 550104,
          "name": "Chai Sathan",
          "postCode": [
            "55000"
          ]
        },
        {
          "id": 550108,
          "name": "Du Tai",
          "postCode": [
            "55000"
          ]
        },
        {
          "id": 550109,
          "name": "Kong Khwai",
          "postCode": [
            "55000"
          ]
        },
        {
          "id": 550107,
          "name": "Na Sao",
          "postCode": [
            "55000"
          ]
        },
        {
          "id": 550101,
          "name": "Nai Wiang",
          "postCode": [
            "55000"
          ]
        },
        {
          "id": 550103,
          "name": "Pha Sing",
          "postCode": [
            "55000"
          ]
        },
        {
          "id": 550106,
          "name": "Rueang",
          "postCode": [
            "55000"
          ]
        },
        {
          "id": 550117,
          "name": "Sanian",
          "postCode": [
            "55000"
          ]
        },
        {
          "id": 550105,
          "name": "Thuem Tong",
          "postCode": [
            "55000"
          ]
        }
      ]
    },
    {
      "city": "Na Muen",
      "cityCode": 5510,
      "provinceCode": 55,
      "subDistrict": [
        {
          "id": 551002,
          "name": "Bo Kaeo",
          "postCode": [
            "55180"
          ]
        },
        {
          "id": 551003,
          "name": "Mueang Li",
          "postCode": [
            "55180"
          ]
        },
        {
          "id": 551001,
          "name": "Na Thanung",
          "postCode": [
            "55180"
          ]
        },
        {
          "id": 551004,
          "name": "Ping Luang",
          "postCode": [
            "55180"
          ]
        }
      ]
    },
    {
      "city": "Na Noi",
      "cityCode": 5504,
      "provinceCode": 55,
      "subDistrict": [
        {
          "id": 550406,
          "name": "Bua Yai",
          "postCode": [
            "55150"
          ]
        },
        {
          "id": 550402,
          "name": "Chiang Khong",
          "postCode": [
            "55150"
          ]
        },
        {
          "id": 550401,
          "name": "Na Noi",
          "postCode": [
            "55150"
          ]
        },
        {
          "id": 550407,
          "name": "Nam Tok",
          "postCode": [
            "55150"
          ]
        },
        {
          "id": 550405,
          "name": "Santha",
          "postCode": [
            "55150"
          ]
        },
        {
          "id": 550404,
          "name": "Sathan",
          "postCode": [
            "55150"
          ]
        },
        {
          "id": 550403,
          "name": "Sisa Ket",
          "postCode": [
            "55150"
          ]
        }
      ]
    },
    {
      "city": "Phu Phiang",
      "cityCode": 5514,
      "provinceCode": 55,
      "subDistrict": [
        {
          "id": 551407,
          "name": "Fai Kaeo",
          "postCode": [
            "55000"
          ]
        },
        {
          "id": 551401,
          "name": "Muang Tuet",
          "postCode": [
            "55000"
          ]
        },
        {
          "id": 551405,
          "name": "Mueang Chang",
          "postCode": [
            "55000"
          ]
        },
        {
          "id": 551402,
          "name": "Na Pang",
          "postCode": [
            "55000"
          ]
        },
        {
          "id": 551403,
          "name": "Nam Kaen",
          "postCode": [
            "55000"
          ]
        },
        {
          "id": 551404,
          "name": "Nam Kian",
          "postCode": [
            "55000"
          ]
        },
        {
          "id": 551406,
          "name": "Tha Nao",
          "postCode": [
            "55000"
          ]
        }
      ]
    },
    {
      "city": "Pua",
      "cityCode": 5505,
      "provinceCode": 55,
      "subDistrict": [
        {
          "id": 550509,
          "name": "Chai Watthana",
          "postCode": [
            "55120"
          ]
        },
        {
          "id": 550510,
          "name": "Chedi Chai",
          "postCode": [
            "55120"
          ]
        },
        {
          "id": 550502,
          "name": "Ngaeng",
          "postCode": [
            "55120"
          ]
        },
        {
          "id": 550513,
          "name": "Pa Klang",
          "postCode": [
            "55120"
          ]
        },
        {
          "id": 550511,
          "name": "Phu Kha",
          "postCode": [
            "55120"
          ]
        },
        {
          "id": 550501,
          "name": "Pua",
          "postCode": [
            "55120"
          ]
        },
        {
          "id": 550512,
          "name": "Sakat",
          "postCode": [
            "55120"
          ]
        },
        {
          "id": 550503,
          "name": "Sathan",
          "postCode": [
            "55120"
          ]
        },
        {
          "id": 550504,
          "name": "Sila Laeng",
          "postCode": [
            "55120"
          ]
        },
        {
          "id": 550505,
          "name": "Sila Phet",
          "postCode": [
            "55120"
          ]
        },
        {
          "id": 550506,
          "name": "Uan",
          "postCode": [
            "55120"
          ]
        },
        {
          "id": 550514,
          "name": "Woranakhon",
          "postCode": [
            "55120"
          ]
        }
      ]
    },
    {
      "city": "Santi Suk",
      "cityCode": 5511,
      "provinceCode": 55,
      "subDistrict": [
        {
          "id": 551101,
          "name": "Du Phong",
          "postCode": [
            "55210"
          ]
        },
        {
          "id": 551102,
          "name": "Pa Laeo Luang",
          "postCode": [
            "55210"
          ]
        },
        {
          "id": 551103,
          "name": "Phong",
          "postCode": [
            "55210"
          ]
        }
      ]
    },
    {
      "city": "Song Khwae",
      "cityCode": 5513,
      "provinceCode": 55,
      "subDistrict": [
        {
          "id": 551302,
          "name": "Chon Daen",
          "postCode": [
            "55160"
          ]
        },
        {
          "id": 551301,
          "name": "Na Rai Luang",
          "postCode": [
            "55160"
          ]
        },
        {
          "id": 551303,
          "name": "Yot",
          "postCode": [
            "55160"
          ]
        }
      ]
    },
    {
      "city": "Tha Wang Pha",
      "cityCode": 5506,
      "provinceCode": 55,
      "subDistrict": [
        {
          "id": 550607,
          "name": "Chom Phra",
          "postCode": [
            "55140"
          ]
        },
        {
          "id": 550602,
          "name": "Pa Kha",
          "postCode": [
            "55140"
          ]
        },
        {
          "id": 550610,
          "name": "Pha Thong",
          "postCode": [
            "55140"
          ]
        },
        {
          "id": 550603,
          "name": "Pha To",
          "postCode": [
            "55140"
          ]
        },
        {
          "id": 550601,
          "name": "Rim",
          "postCode": [
            "55140"
          ]
        },
        {
          "id": 550608,
          "name": "Saen Thong",
          "postCode": [
            "55140"
          ]
        },
        {
          "id": 550606,
          "name": "Si Phum",
          "postCode": [
            "55140"
          ]
        },
        {
          "id": 550605,
          "name": "Tan Chum",
          "postCode": [
            "55140"
          ]
        },
        {
          "id": 550609,
          "name": "Tha Wang Pha",
          "postCode": [
            "55140"
          ]
        },
        {
          "id": 550604,
          "name": "Yom",
          "postCode": [
            "55140"
          ]
        }
      ]
    },
    {
      "city": "Thung Chang",
      "cityCode": 5508,
      "provinceCode": 55,
      "subDistrict": [
        {
          "id": 550803,
          "name": "Lae",
          "postCode": [
            "55130"
          ]
        },
        {
          "id": 550802,
          "name": "Ngop",
          "postCode": [
            "55130"
          ]
        },
        {
          "id": 550801,
          "name": "Pon",
          "postCode": [
            "55130"
          ]
        },
        {
          "id": 550804,
          "name": "Thung Chang",
          "postCode": [
            "55130"
          ]
        }
      ]
    },
    {
      "city": "Wiang Sa",
      "cityCode": 5507,
      "provinceCode": 55,
      "subDistrict": [
        {
          "id": 550711,
          "name": "Ai Na Lai",
          "postCode": [
            "55110"
          ]
        },
        {
          "id": 550715,
          "name": "Chom Chan",
          "postCode": [
            "55110"
          ]
        },
        {
          "id": 550702,
          "name": "Khueng",
          "postCode": [
            "55110"
          ]
        },
        {
          "id": 550701,
          "name": "Klang Wiang",
          "postCode": [
            "55110"
          ]
        },
        {
          "id": 550703,
          "name": "Lai Nan",
          "postCode": [
            "55110"
          ]
        },
        {
          "id": 550713,
          "name": "Mae Khaning",
          "postCode": [
            "55110"
          ]
        },
        {
          "id": 550716,
          "name": "Mae Sa",
          "postCode": [
            "55110"
          ]
        },
        {
          "id": 550714,
          "name": "Mae Sakhon",
          "postCode": [
            "55110"
          ]
        },
        {
          "id": 550705,
          "name": "Na Lueang",
          "postCode": [
            "55110"
          ]
        },
        {
          "id": 550707,
          "name": "Nam Muap",
          "postCode": [
            "55110"
          ]
        },
        {
          "id": 550708,
          "name": "Nam Pua",
          "postCode": [
            "55110"
          ]
        },
        {
          "id": 550710,
          "name": "Pong Sanuk",
          "postCode": [
            "55110"
          ]
        },
        {
          "id": 550706,
          "name": "San",
          "postCode": [
            "55110"
          ]
        },
        {
          "id": 550712,
          "name": "San Na Nong Mai",
          "postCode": [
            "55110"
          ]
        },
        {
          "id": 550704,
          "name": "Tan Chum",
          "postCode": [
            "55110"
          ]
        },
        {
          "id": 550717,
          "name": "Thung Si Thong",
          "postCode": [
            "55110"
          ]
        },
        {
          "id": 550709,
          "name": "Yap Hua Na",
          "postCode": [
            "55110"
          ]
        }
      ]
    },
    {
      "city": "Bacho",
      "cityCode": 9603,
      "provinceCode": 96,
      "subDistrict": [
        {
          "id": 960301,
          "name": "Bacho",
          "postCode": [
            "96170"
          ]
        },
        {
          "id": 960305,
          "name": "Bare Nuea",
          "postCode": [
            "96170"
          ]
        },
        {
          "id": 960306,
          "name": "Bare Tai",
          "postCode": [
            "96170"
          ]
        },
        {
          "id": 960303,
          "name": "Kayo Mati",
          "postCode": [
            "96170"
          ]
        },
        {
          "id": 960302,
          "name": "Lubo Sawo",
          "postCode": [
            "96170"
          ]
        },
        {
          "id": 960304,
          "name": "Paluka Samo",
          "postCode": [
            "96170"
          ]
        }
      ]
    },
    {
      "city": "Chanae",
      "cityCode": 9612,
      "provinceCode": 96,
      "subDistrict": [
        {
          "id": 961201,
          "name": "Chanae",
          "postCode": [
            "96220"
          ]
        },
        {
          "id": 961204,
          "name": "Chang Phueak",
          "postCode": [
            "96220"
          ]
        },
        {
          "id": 961202,
          "name": "Dusongyo",
          "postCode": [
            "96220"
          ]
        },
        {
          "id": 961203,
          "name": "Phadung Mat",
          "postCode": [
            "96220"
          ]
        }
      ]
    },
    {
      "city": "Cho-airong",
      "cityCode": 9613,
      "provinceCode": 96,
      "subDistrict": [
        {
          "id": 961302,
          "name": "Bukit",
          "postCode": [
            "96130"
          ]
        },
        {
          "id": 961301,
          "name": "Chuap",
          "postCode": [
            "96130"
          ]
        },
        {
          "id": 961303,
          "name": "Maruebo Ok",
          "postCode": [
            "96130"
          ]
        }
      ]
    },
    {
      "city": "Mueang Narathiwat",
      "cityCode": 9601,
      "provinceCode": 96,
      "subDistrict": [
        {
          "id": 960101,
          "name": "Bang Nak",
          "postCode": [
            "96000"
          ]
        },
        {
          "id": 960104,
          "name": "Bang Po",
          "postCode": [
            "96000"
          ]
        },
        {
          "id": 960105,
          "name": "Kaluwo",
          "postCode": [
            "96000"
          ]
        },
        {
          "id": 960106,
          "name": "Kaluwo Nuea",
          "postCode": [
            "96000"
          ]
        },
        {
          "id": 960107,
          "name": "Khok Khian",
          "postCode": [
            "96000"
          ]
        },
        {
          "id": 960102,
          "name": "Lamphu",
          "postCode": [
            "96000"
          ]
        },
        {
          "id": 960103,
          "name": "Manang Tayo",
          "postCode": [
            "96000"
          ]
        }
      ]
    },
    {
      "city": "Ra-ngae",
      "cityCode": 9605,
      "provinceCode": 96,
      "subDistrict": [
        {
          "id": 960508,
          "name": "Ba-ngo Sato",
          "postCode": [
            "96130"
          ]
        },
        {
          "id": 960506,
          "name": "Bo-ngo",
          "postCode": [
            "96220"
          ]
        },
        {
          "id": 960509,
          "name": "Chaloem",
          "postCode": [
            "96130"
          ]
        },
        {
          "id": 960507,
          "name": "Kalisa",
          "postCode": [
            "96130"
          ]
        },
        {
          "id": 960510,
          "name": "Marue Botok",
          "postCode": [
            "96130"
          ]
        },
        {
          "id": 960502,
          "name": "Tanyong Limo",
          "postCode": [
            "96130"
          ]
        },
        {
          "id": 960501,
          "name": "Tanyong Mat",
          "postCode": [
            "96130"
          ]
        }
      ]
    },
    {
      "city": "Rueso",
      "cityCode": 9606,
      "provinceCode": 96,
      "subDistrict": [
        {
          "id": 960605,
          "name": "Batong",
          "postCode": [
            "96150"
          ]
        },
        {
          "id": 960608,
          "name": "Khok Sato",
          "postCode": [
            "96150"
          ]
        },
        {
          "id": 960606,
          "name": "Lalo",
          "postCode": [
            "96150"
          ]
        },
        {
          "id": 960603,
          "name": "Riang",
          "postCode": [
            "96150"
          ]
        },
        {
          "id": 960601,
          "name": "Rueso",
          "postCode": [
            "96150"
          ]
        },
        {
          "id": 960607,
          "name": "Rueso Ok",
          "postCode": [
            "96150"
          ]
        },
        {
          "id": 960604,
          "name": "Samakkhi",
          "postCode": [
            "96150"
          ]
        },
        {
          "id": 960602,
          "name": "Sawo",
          "postCode": [
            "96150"
          ]
        },
        {
          "id": 960609,
          "name": "Suwari",
          "postCode": [
            "96150"
          ]
        }
      ]
    },
    {
      "city": "Si Sakhon",
      "cityCode": 9607,
      "provinceCode": 96,
      "subDistrict": [
        {
          "id": 960704,
          "name": "Choeng Khiri",
          "postCode": [
            "96210"
          ]
        },
        {
          "id": 960705,
          "name": "Kalong",
          "postCode": [
            "96210"
          ]
        },
        {
          "id": 960701,
          "name": "Sako",
          "postCode": [
            "96210"
          ]
        },
        {
          "id": 960706,
          "name": "Si Banphot",
          "postCode": [
            "96210"
          ]
        },
        {
          "id": 960703,
          "name": "Si Sakhon",
          "postCode": [
            "96210"
          ]
        },
        {
          "id": 960702,
          "name": "Tamayung",
          "postCode": [
            "96210"
          ]
        }
      ]
    },
    {
      "city": "Su-ngai Kolok",
      "cityCode": 9610,
      "provinceCode": 96,
      "subDistrict": [
        {
          "id": 961003,
          "name": "Muno",
          "postCode": [
            "96120"
          ]
        },
        {
          "id": 961002,
          "name": "Pasemat",
          "postCode": [
            "96120"
          ]
        },
        {
          "id": 961004,
          "name": "Puyo",
          "postCode": [
            "96120"
          ]
        },
        {
          "id": 961001,
          "name": "Su-ngai Kolok",
          "postCode": [
            "96120"
          ]
        }
      ]
    },
    {
      "city": "Su-ngai Padi",
      "cityCode": 9611,
      "provinceCode": 96,
      "subDistrict": [
        {
          "id": 961106,
          "name": "Kawa",
          "postCode": [
            "96140"
          ]
        },
        {
          "id": 961101,
          "name": "Paluru",
          "postCode": [
            "96140"
          ]
        },
        {
          "id": 961105,
          "name": "Riko",
          "postCode": [
            "96140"
          ]
        },
        {
          "id": 961104,
          "name": "Sako",
          "postCode": [
            "96140"
          ]
        },
        {
          "id": 961102,
          "name": "Su-ngai Padi",
          "postCode": [
            "96140"
          ]
        },
        {
          "id": 961103,
          "name": "Todeng",
          "postCode": [
            "96140"
          ]
        }
      ]
    },
    {
      "city": "Sukhirin",
      "cityCode": 9609,
      "provinceCode": 96,
      "subDistrict": [
        {
          "id": 960903,
          "name": "Kia",
          "postCode": [
            "96190"
          ]
        },
        {
          "id": 960901,
          "name": "Mamong",
          "postCode": [
            "96190"
          ]
        },
        {
          "id": 960904,
          "name": "Phukhao Thong",
          "postCode": [
            "96190"
          ]
        },
        {
          "id": 960905,
          "name": "Rom Sai",
          "postCode": [
            "96190"
          ]
        },
        {
          "id": 960902,
          "name": "Sukhirin",
          "postCode": [
            "96190"
          ]
        }
      ]
    },
    {
      "city": "Tak Bai",
      "cityCode": 9602,
      "provinceCode": 96,
      "subDistrict": [
        {
          "id": 960205,
          "name": "Bang Khun Thong",
          "postCode": [
            "96110"
          ]
        },
        {
          "id": 960201,
          "name": "Chehe",
          "postCode": [
            "96110"
          ]
        },
        {
          "id": 960208,
          "name": "Khosit",
          "postCode": [
            "96110"
          ]
        },
        {
          "id": 960206,
          "name": "Ko Sathon",
          "postCode": [
            "96110"
          ]
        },
        {
          "id": 960207,
          "name": "Na Nak",
          "postCode": [
            "96110"
          ]
        },
        {
          "id": 960202,
          "name": "Phraiwan",
          "postCode": [
            "96110"
          ]
        },
        {
          "id": 960203,
          "name": "Phron",
          "postCode": [
            "96110"
          ]
        },
        {
          "id": 960204,
          "name": "Sala Mai",
          "postCode": [
            "96110"
          ]
        }
      ]
    },
    {
      "city": "Waeng",
      "cityCode": 9608,
      "provinceCode": 96,
      "subDistrict": [
        {
          "id": 960806,
          "name": "Erawan",
          "postCode": [
            "96160"
          ]
        },
        {
          "id": 960802,
          "name": "Kayu Khla",
          "postCode": [
            "96160"
          ]
        },
        {
          "id": 960803,
          "name": "Kholo",
          "postCode": [
            "96160"
          ]
        },
        {
          "id": 960804,
          "name": "Lochut",
          "postCode": [
            "96160"
          ]
        },
        {
          "id": 960805,
          "name": "Mae Dong",
          "postCode": [
            "96160"
          ]
        },
        {
          "id": 960801,
          "name": "Waeng",
          "postCode": [
            "96160"
          ]
        }
      ]
    },
    {
      "city": "Yi-ngo",
      "cityCode": 9604,
      "provinceCode": 96,
      "subDistrict": [
        {
          "id": 960403,
          "name": "Cho Bo",
          "postCode": [
            "96180"
          ]
        },
        {
          "id": 960402,
          "name": "Lahan",
          "postCode": [
            "96180"
          ]
        },
        {
          "id": 960404,
          "name": "Lubo Baya",
          "postCode": [
            "96180"
          ]
        },
        {
          "id": 960405,
          "name": "Lubo Buesa",
          "postCode": [
            "96180"
          ]
        },
        {
          "id": 960406,
          "name": "Tapoyo",
          "postCode": [
            "96180"
          ]
        },
        {
          "id": 960401,
          "name": "Yi-ngo",
          "postCode": [
            "96180"
          ]
        }
      ]
    },
    {
      "city": "Mueang Nong Bua Lam Phu",
      "cityCode": 3901,
      "provinceCode": 39,
      "subDistrict": [
        {
          "id": 390106,
          "name": "Ban Kham",
          "postCode": [
            "39000"
          ]
        },
        {
          "id": 390108,
          "name": "Ban Phrao",
          "postCode": [
            "39000"
          ]
        },
        {
          "id": 390105,
          "name": "Hua Na",
          "postCode": [
            "39000"
          ]
        },
        {
          "id": 390111,
          "name": "Kut Chik",
          "postCode": [
            "39000"
          ]
        },
        {
          "id": 390110,
          "name": "Lumphu",
          "postCode": [
            "39000"
          ]
        },
        {
          "id": 390113,
          "name": "Na Kham Hai",
          "postCode": [
            "39000"
          ]
        },
        {
          "id": 390107,
          "name": "Na Mafueang",
          "postCode": [
            "39000"
          ]
        },
        {
          "id": 390109,
          "name": "Non Khamin",
          "postCode": [
            "39000"
          ]
        },
        {
          "id": 390112,
          "name": "Non Than",
          "postCode": [
            "39000"
          ]
        },
        {
          "id": 390101,
          "name": "Nong Bua",
          "postCode": [
            "39000"
          ]
        },
        {
          "id": 390102,
          "name": "Nong Phai Sun",
          "postCode": [
            "39000"
          ]
        },
        {
          "id": 390104,
          "name": "Nong Sawan",
          "postCode": [
            "39000"
          ]
        },
        {
          "id": 390115,
          "name": "Nong Wa",
          "postCode": [
            "39000"
          ]
        },
        {
          "id": 390114,
          "name": "Pa Mai Ngam",
          "postCode": [
            "39000"
          ]
        },
        {
          "id": 390103,
          "name": "Pho Chai",
          "postCode": [
            "39000"
          ]
        }
      ]
    },
    {
      "city": "Na Klang",
      "cityCode": 3902,
      "provinceCode": 39,
      "subDistrict": [
        {
          "id": 390202,
          "name": "Dan Chang",
          "postCode": [
            "39170"
          ]
        },
        {
          "id": 390211,
          "name": "Dong Sawan",
          "postCode": [
            "39350"
          ]
        },
        {
          "id": 390206,
          "name": "Fang Daeng",
          "postCode": [
            "39170"
          ]
        },
        {
          "id": 390207,
          "name": "Kao Kloi",
          "postCode": [
            "39350"
          ]
        },
        {
          "id": 390205,
          "name": "Kut Din Chi",
          "postCode": [
            "39350"
          ]
        },
        {
          "id": 390213,
          "name": "Kut Hae",
          "postCode": [
            "39170"
          ]
        },
        {
          "id": 390201,
          "name": "Na Klang",
          "postCode": [
            "39170"
          ]
        },
        {
          "id": 390209,
          "name": "Non Mueang",
          "postCode": [
            "39170"
          ]
        },
        {
          "id": 390210,
          "name": "Uthai Sawan",
          "postCode": [
            "39170"
          ]
        }
      ]
    },
    {
      "city": "Na Wang",
      "cityCode": 3906,
      "provinceCode": 39,
      "subDistrict": [
        {
          "id": 390602,
          "name": "Na Kae",
          "postCode": [
            "39170"
          ]
        },
        {
          "id": 390601,
          "name": "Na Lao",
          "postCode": [
            "39170"
          ]
        },
        {
          "id": 390605,
          "name": "Thep Khiri",
          "postCode": [
            "39170"
          ]
        },
        {
          "id": 390604,
          "name": "Wang Pla Pom",
          "postCode": [
            "39170"
          ]
        },
        {
          "id": 390603,
          "name": "Wang Thong",
          "postCode": [
            "39170"
          ]
        }
      ]
    },
    {
      "city": "Non Sang",
      "cityCode": 3903,
      "provinceCode": 39,
      "subDistrict": [
        {
          "id": 390305,
          "name": "Ban Kho",
          "postCode": [
            "39140"
          ]
        },
        {
          "id": 390302,
          "name": "Ban Thin",
          "postCode": [
            "39140"
          ]
        },
        {
          "id": 390308,
          "name": "Khok Muang",
          "postCode": [
            "39140"
          ]
        },
        {
          "id": 390307,
          "name": "Khok Yai",
          "postCode": [
            "39140"
          ]
        },
        {
          "id": 390304,
          "name": "Kut Du",
          "postCode": [
            "39140"
          ]
        },
        {
          "id": 390309,
          "name": "Nikhom Phatthana",
          "postCode": [
            "39140"
          ]
        },
        {
          "id": 390306,
          "name": "Non Mueang",
          "postCode": [
            "39140"
          ]
        },
        {
          "id": 390301,
          "name": "Non Sang",
          "postCode": [
            "39140"
          ]
        },
        {
          "id": 390303,
          "name": "Nong Ruea",
          "postCode": [
            "39140"
          ]
        },
        {
          "id": 390310,
          "name": "Pang Ku",
          "postCode": [
            "39140"
          ]
        }
      ]
    },
    {
      "city": "Si Bun Rueang",
      "cityCode": 3904,
      "provinceCode": 39,
      "subDistrict": [
        {
          "id": 390412,
          "name": "Han Na Ngam",
          "postCode": [
            "39180"
          ]
        },
        {
          "id": 390404,
          "name": "Kut Sathian",
          "postCode": [
            "39180"
          ]
        },
        {
          "id": 390401,
          "name": "Mueang Mai",
          "postCode": [
            "39180"
          ]
        },
        {
          "id": 390405,
          "name": "Na Kok",
          "postCode": [
            "39180"
          ]
        },
        {
          "id": 390408,
          "name": "Non Muang",
          "postCode": [
            "39180"
          ]
        },
        {
          "id": 390406,
          "name": "Non Sa-at",
          "postCode": [
            "39180"
          ]
        },
        {
          "id": 390403,
          "name": "Nong Bua Tai",
          "postCode": [
            "39180"
          ]
        },
        {
          "id": 390410,
          "name": "Nong Kae",
          "postCode": [
            "39180"
          ]
        },
        {
          "id": 390409,
          "name": "Nong Kung Kaeo",
          "postCode": [
            "39180"
          ]
        },
        {
          "id": 390411,
          "name": "Sai Thong",
          "postCode": [
            "39180"
          ]
        },
        {
          "id": 390402,
          "name": "Si Bun Rueang",
          "postCode": [
            "39180"
          ]
        },
        {
          "id": 390407,
          "name": "Yang Lo",
          "postCode": [
            "39180"
          ]
        }
      ]
    },
    {
      "city": "Suwannakhuha",
      "cityCode": 3905,
      "provinceCode": 39,
      "subDistrict": [
        {
          "id": 390502,
          "name": "Ban Khok",
          "postCode": [
            "39270"
          ]
        },
        {
          "id": 390507,
          "name": "Bun Than",
          "postCode": [
            "39270"
          ]
        },
        {
          "id": 390505,
          "name": "Dong Mafai",
          "postCode": [
            "39270"
          ]
        },
        {
          "id": 390508,
          "name": "Kut Phueng",
          "postCode": [
            "39270"
          ]
        },
        {
          "id": 390504,
          "name": "Na Dan",
          "postCode": [
            "39270"
          ]
        },
        {
          "id": 390503,
          "name": "Na Di",
          "postCode": [
            "39270"
          ]
        },
        {
          "id": 390501,
          "name": "Na Si",
          "postCode": [
            "39270"
          ]
        },
        {
          "id": 390506,
          "name": "Suwannakhuha",
          "postCode": [
            "39270"
          ]
        }
      ]
    },
    {
      "city": "Fao Rai",
      "cityCode": 4315,
      "provinceCode": 43,
      "subDistrict": [
        {
          "id": 431501,
          "name": "Fao Rai",
          "postCode": [
            "43120"
          ]
        },
        {
          "id": 431502,
          "name": "Na Di",
          "postCode": [
            "43120"
          ]
        },
        {
          "id": 431503,
          "name": "Nong Luang",
          "postCode": [
            "43120"
          ]
        },
        {
          "id": 431505,
          "name": "Udom Phon",
          "postCode": [
            "43120"
          ]
        },
        {
          "id": 431504,
          "name": "wang Luang",
          "postCode": [
            "43120"
          ]
        }
      ]
    },
    {
      "city": "Mueang Nong Khai",
      "cityCode": 4301,
      "provinceCode": 43,
      "subDistrict": [
        {
          "id": 430109,
          "name": "Ban Duea",
          "postCode": [
            "43000"
          ]
        },
        {
          "id": 430107,
          "name": "Hat Kham",
          "postCode": [
            "43000"
          ]
        },
        {
          "id": 430108,
          "name": "Hin Ngom",
          "postCode": [
            "43000"
          ]
        },
        {
          "id": 430110,
          "name": "Khai Bok Wan",
          "postCode": [
            "43100"
          ]
        },
        {
          "id": 430104,
          "name": "Kuan Wan",
          "postCode": [
            "43000"
          ]
        },
        {
          "id": 430102,
          "name": "Mi Chai",
          "postCode": [
            "43000"
          ]
        },
        {
          "id": 430118,
          "name": "Mueang Mi",
          "postCode": [
            "43000"
          ]
        },
        {
          "id": 430101,
          "name": "Nai Mueang",
          "postCode": [
            "43000"
          ]
        },
        {
          "id": 430116,
          "name": "Nong Kom Ko",
          "postCode": [
            "43000"
          ]
        },
        {
          "id": 430117,
          "name": "Pa Kho",
          "postCode": [
            "43000"
          ]
        },
        {
          "id": 430103,
          "name": "Pho Chai",
          "postCode": [
            "43000"
          ]
        },
        {
          "id": 430111,
          "name": "Phon Sawang",
          "postCode": [
            "43100"
          ]
        },
        {
          "id": 430113,
          "name": "Phra That Bang Phuan",
          "postCode": [
            "43100"
          ]
        },
        {
          "id": 430119,
          "name": "Si Kai",
          "postCode": [
            "43000"
          ]
        },
        {
          "id": 430106,
          "name": "Wat That",
          "postCode": [
            "43000"
          ]
        },
        {
          "id": 430105,
          "name": "Wiang Khuk",
          "postCode": [
            "43000"
          ]
        }
      ]
    },
    {
      "city": "Pho Tak",
      "cityCode": 4317,
      "provinceCode": 43,
      "subDistrict": [
        {
          "id": 431703,
          "name": "Dan Si Suk",
          "postCode": [
            "43130"
          ]
        },
        {
          "id": 431701,
          "name": "Pho Tak",
          "postCode": [
            "43130"
          ]
        },
        {
          "id": 431702,
          "name": "Phon Thong",
          "postCode": [
            "43130"
          ]
        }
      ]
    },
    {
      "city": "Phon Phisai",
      "cityCode": 4305,
      "provinceCode": 43,
      "subDistrict": [
        {
          "id": 430513,
          "name": "Ban Pho",
          "postCode": [
            "43120"
          ]
        },
        {
          "id": 430521,
          "name": "Ban Phue",
          "postCode": [
            "43120"
          ]
        },
        {
          "id": 430504,
          "name": "Chum Chang",
          "postCode": [
            "43120"
          ]
        },
        {
          "id": 430501,
          "name": "Chumphon",
          "postCode": [
            "43120"
          ]
        },
        {
          "id": 430503,
          "name": "Kut Bong",
          "postCode": [
            "43120"
          ]
        },
        {
          "id": 430507,
          "name": "Lao Tang Kham",
          "postCode": [
            "43120"
          ]
        },
        {
          "id": 430508,
          "name": "Na Nang",
          "postCode": [
            "43120"
          ]
        },
        {
          "id": 430522,
          "name": "Sang Nang Khao",
          "postCode": [
            "43120"
          ]
        },
        {
          "id": 430509,
          "name": "Soem",
          "postCode": [
            "43120"
          ]
        },
        {
          "id": 430506,
          "name": "Thung Luang",
          "postCode": [
            "43120"
          ]
        },
        {
          "id": 430502,
          "name": "Wat Luang",
          "postCode": [
            "43120"
          ]
        }
      ]
    },
    {
      "city": "Rattanawapi",
      "cityCode": 4316,
      "provinceCode": 43,
      "subDistrict": [
        {
          "id": 431603,
          "name": "Ban Ton",
          "postCode": [
            "43120"
          ]
        },
        {
          "id": 431602,
          "name": "Na Thap Hai",
          "postCode": [
            "43120"
          ]
        },
        {
          "id": 431605,
          "name": "Phon Phaeng",
          "postCode": [
            "43120"
          ]
        },
        {
          "id": 431604,
          "name": "Phra Bat Na Sing",
          "postCode": [
            "43120"
          ]
        },
        {
          "id": 431601,
          "name": "Rattanawapi",
          "postCode": [
            "43120"
          ]
        }
      ]
    },
    {
      "city": "Sakhrai",
      "cityCode": 4314,
      "provinceCode": 43,
      "subDistrict": [
        {
          "id": 431403,
          "name": "Ban Fang",
          "postCode": [
            "43100"
          ]
        },
        {
          "id": 431402,
          "name": "Khok Chang",
          "postCode": [
            "43100"
          ]
        },
        {
          "id": 431401,
          "name": "Sakhrai",
          "postCode": [
            "43100"
          ]
        }
      ]
    },
    {
      "city": "Sangkhom",
      "cityCode": 4308,
      "provinceCode": 43,
      "subDistrict": [
        {
          "id": 430803,
          "name": "Ban Muang",
          "postCode": [
            "43160"
          ]
        },
        {
          "id": 430801,
          "name": "Kaeng Kai",
          "postCode": [
            "43160"
          ]
        },
        {
          "id": 430804,
          "name": "Na Ngio",
          "postCode": [
            "43160"
          ]
        },
        {
          "id": 430802,
          "name": "Pha Tang",
          "postCode": [
            "43160"
          ]
        },
        {
          "id": 430805,
          "name": "Sangkhom",
          "postCode": [
            "43160"
          ]
        }
      ]
    },
    {
      "city": "Si Chiang Mai",
      "cityCode": 4307,
      "provinceCode": 43,
      "subDistrict": [
        {
          "id": 430703,
          "name": "Ban Mo",
          "postCode": [
            "43130"
          ]
        },
        {
          "id": 430705,
          "name": "Nong Pla Pak",
          "postCode": [
            "43130"
          ]
        },
        {
          "id": 430701,
          "name": "Phan Phrao",
          "postCode": [
            "43130"
          ]
        },
        {
          "id": 430704,
          "name": "Phra Phutthabat",
          "postCode": [
            "43130"
          ]
        }
      ]
    },
    {
      "city": "Tha Bo",
      "cityCode": 4302,
      "provinceCode": 43,
      "subDistrict": [
        {
          "id": 430205,
          "name": "Ban Duea",
          "postCode": [
            "43110"
          ]
        },
        {
          "id": 430206,
          "name": "Ban Thon",
          "postCode": [
            "43110"
          ]
        },
        {
          "id": 430207,
          "name": "Ban Wan",
          "postCode": [
            "43110"
          ]
        },
        {
          "id": 430204,
          "name": "Khok Khon",
          "postCode": [
            "43110"
          ]
        },
        {
          "id": 430203,
          "name": "Kong Nang",
          "postCode": [
            "43110"
          ]
        },
        {
          "id": 430208,
          "name": "Na Kha",
          "postCode": [
            "43110"
          ]
        },
        {
          "id": 430202,
          "name": "Nam Mong",
          "postCode": [
            "43110"
          ]
        },
        {
          "id": 430210,
          "name": "Nong Nang",
          "postCode": [
            "43110"
          ]
        },
        {
          "id": 430209,
          "name": "Phon Sa",
          "postCode": [
            "43110"
          ]
        },
        {
          "id": 430201,
          "name": "Tha Bo",
          "postCode": [
            "43110"
          ]
        }
      ]
    },
    {
      "city": "Bang Bua Thong",
      "cityCode": 1204,
      "provinceCode": 12,
      "subDistrict": [
        {
          "id": 120402,
          "name": "Bang Bua Thong",
          "postCode": [
            "11110"
          ]
        },
        {
          "id": 120404,
          "name": "Bang Khu Rat",
          "postCode": [
            "11110"
          ]
        },
        {
          "id": 120408,
          "name": "Bang Rak Phatthana",
          "postCode": [
            "11110"
          ]
        },
        {
          "id": 120403,
          "name": "Bang Rak Yai",
          "postCode": [
            "11110"
          ]
        },
        {
          "id": 120405,
          "name": "Lahan",
          "postCode": [
            "11110"
          ]
        },
        {
          "id": 120406,
          "name": "Lam Pho",
          "postCode": [
            "11110"
          ]
        },
        {
          "id": 120407,
          "name": "Phimon Rat",
          "postCode": [
            "11110"
          ]
        },
        {
          "id": 120401,
          "name": "Sano Loi",
          "postCode": [
            "11110"
          ]
        }
      ]
    },
    {
      "city": "Bang Kruai",
      "cityCode": 1202,
      "provinceCode": 12,
      "subDistrict": [
        {
          "id": 120204,
          "name": "Bang Khanun",
          "postCode": [
            "11130"
          ]
        },
        {
          "id": 120206,
          "name": "Bang Khu Wiang",
          "postCode": [
            "11130"
          ]
        },
        {
          "id": 120205,
          "name": "Bang Khun Kong",
          "postCode": [
            "11130"
          ]
        },
        {
          "id": 120202,
          "name": "Bang Kruai",
          "postCode": [
            "11130"
          ]
        },
        {
          "id": 120203,
          "name": "Bang Si Thong",
          "postCode": [
            "11130"
          ]
        },
        {
          "id": 120207,
          "name": "Maha Sawat",
          "postCode": [
            "11130"
          ]
        },
        {
          "id": 120208,
          "name": "Plai Bang",
          "postCode": [
            "11130"
          ]
        },
        {
          "id": 120209,
          "name": "Sala Klang",
          "postCode": [
            "11130"
          ]
        },
        {
          "id": 120201,
          "name": "Wat Chalo",
          "postCode": [
            "11130"
          ]
        }
      ]
    },
    {
      "city": "Bang Yai",
      "cityCode": 1203,
      "provinceCode": 12,
      "subDistrict": [
        {
          "id": 120306,
          "name": "Ban Mai",
          "postCode": [
            "11140"
          ]
        },
        {
          "id": 120303,
          "name": "Bang Len",
          "postCode": [
            "11140"
          ]
        },
        {
          "id": 120302,
          "name": "Bang Mae Nang",
          "postCode": [
            "11140"
          ]
        },
        {
          "id": 120301,
          "name": "Bang Muang",
          "postCode": [
            "11140"
          ]
        },
        {
          "id": 120305,
          "name": "Bang Yai",
          "postCode": [
            "11140"
          ]
        },
        {
          "id": 120304,
          "name": "Sao Thong Hin",
          "postCode": [
            "11140"
          ]
        }
      ]
    },
    {
      "city": "Mueang Nonthaburi",
      "cityCode": 1201,
      "provinceCode": 12,
      "subDistrict": [
        {
          "id": 120103,
          "name": "Bang Khen",
          "postCode": [
            "11000"
          ]
        },
        {
          "id": 120108,
          "name": "Bang Krang",
          "postCode": [
            "11000"
          ]
        },
        {
          "id": 120104,
          "name": "Bang Kraso",
          "postCode": [
            "11000"
          ]
        },
        {
          "id": 120106,
          "name": "Bang Phai",
          "postCode": [
            "11000"
          ]
        },
        {
          "id": 120110,
          "name": "Bang Rak Noi",
          "postCode": [
            "11000"
          ]
        },
        {
          "id": 120107,
          "name": "Bang Si Mueang",
          "postCode": [
            "11000"
          ]
        },
        {
          "id": 120109,
          "name": "Sai Ma",
          "postCode": [
            "11000"
          ]
        },
        {
          "id": 120101,
          "name": "Suan Yai",
          "postCode": [
            "11000"
          ]
        },
        {
          "id": 120102,
          "name": "Talat Khwan",
          "postCode": [
            "11000"
          ]
        },
        {
          "id": 120105,
          "name": "Tha Sai",
          "postCode": [
            "11000"
          ]
        }
      ]
    },
    {
      "city": "Pak Kret",
      "cityCode": 1206,
      "provinceCode": 12,
      "subDistrict": [
        {
          "id": 120603,
          "name": "Ban Mai",
          "postCode": [
            "11120"
          ]
        },
        {
          "id": 120611,
          "name": "Bang Phlap",
          "postCode": [
            "11120"
          ]
        },
        {
          "id": 120604,
          "name": "Bang Phut",
          "postCode": [
            "11120"
          ]
        },
        {
          "id": 120602,
          "name": "Bang Talat",
          "postCode": [
            "11120"
          ]
        },
        {
          "id": 120605,
          "name": "Bang Tanai",
          "postCode": [
            "11120"
          ]
        },
        {
          "id": 120610,
          "name": "Khlong Khoi",
          "postCode": [
            "11120"
          ]
        },
        {
          "id": 120612,
          "name": "Khlong Kluea",
          "postCode": [
            "11120"
          ]
        },
        {
          "id": 120606,
          "name": "Khlong Phra Udom",
          "postCode": [
            "11120"
          ]
        },
        {
          "id": 120608,
          "name": "Ko Kret",
          "postCode": [
            "11120"
          ]
        },
        {
          "id": 120609,
          "name": "Om Kret",
          "postCode": [
            "11120"
          ]
        },
        {
          "id": 120601,
          "name": "Pak Kret",
          "postCode": [
            "11120"
          ]
        },
        {
          "id": 120607,
          "name": "Tha It",
          "postCode": [
            "11120"
          ]
        }
      ]
    },
    {
      "city": "Sai Noi",
      "cityCode": 1205,
      "provinceCode": 12,
      "subDistrict": [
        {
          "id": 120506,
          "name": "Khlong Khwang",
          "postCode": [
            "11150"
          ]
        },
        {
          "id": 120505,
          "name": "Khun Si",
          "postCode": [
            "11150"
          ]
        },
        {
          "id": 120503,
          "name": "Nong Phrao Ngai",
          "postCode": [
            "11150"
          ]
        },
        {
          "id": 120502,
          "name": "Rat Niyom",
          "postCode": [
            "11150"
          ]
        },
        {
          "id": 120501,
          "name": "Sai Noi",
          "postCode": [
            "11150"
          ]
        },
        {
          "id": 120504,
          "name": "Sai Yai",
          "postCode": [
            "11150"
          ]
        },
        {
          "id": 120507,
          "name": "Thawi Watthana",
          "postCode": [
            "11150"
          ]
        }
      ]
    },
    {
      "city": "Khlong Luang",
      "cityCode": 1302,
      "provinceCode": 13,
      "subDistrict": [
        {
          "id": 130207,
          "name": "Khlong Chet",
          "postCode": [
            "12120"
          ]
        },
        {
          "id": 130205,
          "name": "Khlong Ha",
          "postCode": [
            "12110",
            "12120"
          ]
        },
        {
          "id": 130206,
          "name": "Khlong Hok",
          "postCode": [
            "12110",
            "12120"
          ]
        },
        {
          "id": 130201,
          "name": "Khlong Nueng",
          "postCode": [
            "12120",
            "12121",
            "13180"
          ]
        },
        {
          "id": 130203,
          "name": "Khlong Sam",
          "postCode": [
            "12120"
          ]
        },
        {
          "id": 130204,
          "name": "Khlong Si",
          "postCode": [
            "12120"
          ]
        },
        {
          "id": 130202,
          "name": "Khlong Song",
          "postCode": [
            "12120"
          ]
        }
      ]
    },
    {
      "city": "Lam Luk Ka",
      "cityCode": 1306,
      "provinceCode": 13,
      "subDistrict": [
        {
          "id": 130603,
          "name": "Bueng Kham Phroi",
          "postCode": [
            "12150"
          ]
        },
        {
          "id": 130607,
          "name": "Bueng Kho Hai",
          "postCode": [
            "12150"
          ]
        },
        {
          "id": 130605,
          "name": "Bueng Thonglang",
          "postCode": [
            "12150"
          ]
        },
        {
          "id": 130601,
          "name": "Khu Khot",
          "postCode": [
            "12130"
          ]
        },
        {
          "id": 130606,
          "name": "Lam Sai",
          "postCode": [
            "12150"
          ]
        },
        {
          "id": 130602,
          "name": "Lat Sawai",
          "postCode": [
            "12150"
          ]
        },
        {
          "id": 130604,
          "name": "Lum Luk Ka",
          "postCode": [
            "12150"
          ]
        },
        {
          "id": 130608,
          "name": "Phuet Udom",
          "postCode": [
            "12150"
          ]
        }
      ]
    },
    {
      "city": "Lat Lum Kaeo",
      "cityCode": 1305,
      "provinceCode": 13,
      "subDistrict": [
        {
          "id": 130506,
          "name": "Bo Ngoen",
          "postCode": [
            "12140"
          ]
        },
        {
          "id": 130505,
          "name": "Khlong Phra Udom",
          "postCode": [
            "12140"
          ]
        },
        {
          "id": 130503,
          "name": "Khu Bang Luang",
          "postCode": [
            "12140"
          ]
        },
        {
          "id": 130504,
          "name": "Khu Khwang",
          "postCode": [
            "12140"
          ]
        },
        {
          "id": 130502,
          "name": "Lat Lum Kaeo",
          "postCode": [
            "12140"
          ]
        },
        {
          "id": 130507,
          "name": "Na Mai",
          "postCode": [
            "12140"
          ]
        },
        {
          "id": 130501,
          "name": "Rahaeng",
          "postCode": [
            "12140"
          ]
        }
      ]
    },
    {
      "city": "Mueang Pathum Thani",
      "cityCode": 1301,
      "provinceCode": 13,
      "subDistrict": [
        {
          "id": 130104,
          "name": "Ban Chang",
          "postCode": [
            "12000"
          ]
        },
        {
          "id": 130103,
          "name": "Ban Klang",
          "postCode": [
            "12000"
          ]
        },
        {
          "id": 130105,
          "name": "Ban Krachaeng",
          "postCode": [
            "12000"
          ]
        },
        {
          "id": 130102,
          "name": "Ban Mai",
          "postCode": [
            "12000"
          ]
        },
        {
          "id": 130109,
          "name": "Bang Duea",
          "postCode": [
            "12000"
          ]
        },
        {
          "id": 130112,
          "name": "Bang Kadi",
          "postCode": [
            "12000"
          ]
        },
        {
          "id": 130106,
          "name": "Bang Khayaeng",
          "postCode": [
            "12000"
          ]
        },
        {
          "id": 130107,
          "name": "Bang Khu Wat",
          "postCode": [
            "12000"
          ]
        },
        {
          "id": 130108,
          "name": "Bang Luang",
          "postCode": [
            "12000"
          ]
        },
        {
          "id": 130111,
          "name": "Bang Phun",
          "postCode": [
            "12000"
          ]
        },
        {
          "id": 130110,
          "name": "Bang Phut",
          "postCode": [
            "12000"
          ]
        },
        {
          "id": 130101,
          "name": "Bang Prok",
          "postCode": [
            "12000"
          ]
        },
        {
          "id": 130114,
          "name": "Lak Hok",
          "postCode": [
            "12000"
          ]
        },
        {
          "id": 130113,
          "name": "Suan Phrik Thai",
          "postCode": [
            "12000"
          ]
        }
      ]
    },
    {
      "city": "Nong Suea",
      "cityCode": 1304,
      "provinceCode": 13,
      "subDistrict": [
        {
          "id": 130401,
          "name": "Bueng Ba",
          "postCode": [
            "12170"
          ]
        },
        {
          "id": 130402,
          "name": "Bueng Bon",
          "postCode": [
            "12170"
          ]
        },
        {
          "id": 130404,
          "name": "Bueng Cham O",
          "postCode": [
            "12170"
          ]
        },
        {
          "id": 130403,
          "name": "Bueng Ka Sam",
          "postCode": [
            "12170"
          ]
        },
        {
          "id": 130405,
          "name": "Nong Sam Wang",
          "postCode": [
            "12170"
          ]
        },
        {
          "id": 130407,
          "name": "Noppharat",
          "postCode": [
            "12170"
          ]
        },
        {
          "id": 130406,
          "name": "Sala Khru",
          "postCode": [
            "12170"
          ]
        }
      ]
    },
    {
      "city": "Sam Khok",
      "cityCode": 1307,
      "provinceCode": 13,
      "subDistrict": [
        {
          "id": 130708,
          "name": "Ban Ngio",
          "postCode": [
            "12160"
          ]
        },
        {
          "id": 130707,
          "name": "Ban Pathum",
          "postCode": [
            "12160"
          ]
        },
        {
          "id": 130710,
          "name": "Bang Krabue",
          "postCode": [
            "12160"
          ]
        },
        {
          "id": 130705,
          "name": "Bang Pho Nuea",
          "postCode": [
            "12160"
          ]
        },
        {
          "id": 130701,
          "name": "Bang Toei",
          "postCode": [
            "12160"
          ]
        },
        {
          "id": 130709,
          "name": "Chiang Rak Noi",
          "postCode": [
            "12160"
          ]
        },
        {
          "id": 130706,
          "name": "Chiang Rak Yai",
          "postCode": [
            "12160"
          ]
        },
        {
          "id": 130702,
          "name": "Khlong Khwai",
          "postCode": [
            "12160"
          ]
        },
        {
          "id": 130704,
          "name": "Krachaeng",
          "postCode": [
            "12160"
          ]
        },
        {
          "id": 130703,
          "name": "Sam Khok",
          "postCode": [
            "12160"
          ]
        },
        {
          "id": 130711,
          "name": "Thai Ko",
          "postCode": [
            "12160"
          ]
        }
      ]
    },
    {
      "city": "Thanyaburi",
      "cityCode": 1303,
      "provinceCode": 13,
      "subDistrict": [
        {
          "id": 130306,
          "name": "Bueng Nam Rak",
          "postCode": [
            "12110"
          ]
        },
        {
          "id": 130305,
          "name": "Bueng Sanan",
          "postCode": [
            "12110"
          ]
        },
        {
          "id": 130302,
          "name": "Bueng Yitho",
          "postCode": [
            "12130"
          ]
        },
        {
          "id": 130304,
          "name": "Lam Phak Kut",
          "postCode": [
            "12110"
          ]
        },
        {
          "id": 130301,
          "name": "Prachathipat",
          "postCode": [
            "12130"
          ]
        },
        {
          "id": 130303,
          "name": "Rangsit",
          "postCode": [
            "12110"
          ]
        }
      ]
    },
    {
      "city": "Kapho",
      "cityCode": 9411,
      "provinceCode": 94,
      "subDistrict": [
        {
          "id": 941101,
          "name": "Karubi",
          "postCode": [
            "94230"
          ]
        },
        {
          "id": 941103,
          "name": "Plong Hoi",
          "postCode": [
            "94230"
          ]
        },
        {
          "id": 941102,
          "name": "Talo Due Raman",
          "postCode": [
            "94230"
          ]
        }
      ]
    },
    {
      "city": "Khok Pho",
      "cityCode": 9402,
      "provinceCode": 94,
      "subDistrict": [
        {
          "id": 940203,
          "name": "Bang Kro",
          "postCode": [
            "94120"
          ]
        },
        {
          "id": 940215,
          "name": "Chang Hai Tok",
          "postCode": [
            "94120"
          ]
        },
        {
          "id": 940201,
          "name": "Khok Pho",
          "postCode": [
            "94120"
          ]
        },
        {
          "id": 940214,
          "name": "Khuan Nori",
          "postCode": [
            "94180"
          ]
        },
        {
          "id": 940202,
          "name": "Makrut",
          "postCode": [
            "94120"
          ]
        },
        {
          "id": 940213,
          "name": "Na Ket",
          "postCode": [
            "94120"
          ]
        },
        {
          "id": 940206,
          "name": "Na Pradu",
          "postCode": [
            "94180"
          ]
        },
        {
          "id": 940204,
          "name": "Pa Bon",
          "postCode": [
            "94120"
          ]
        },
        {
          "id": 940207,
          "name": "Pak Lo",
          "postCode": [
            "94180"
          ]
        },
        {
          "id": 940205,
          "name": "Sai Khao",
          "postCode": [
            "94120"
          ]
        },
        {
          "id": 940211,
          "name": "Tha Ruea",
          "postCode": [
            "94120"
          ]
        },
        {
          "id": 940208,
          "name": "Thung Phala",
          "postCode": [
            "94180"
          ]
        }
      ]
    },
    {
      "city": "Mae Lan",
      "cityCode": 9412,
      "provinceCode": 94,
      "subDistrict": [
        {
          "id": 941201,
          "name": "Mae Lan",
          "postCode": [
            "94180"
          ]
        },
        {
          "id": 941202,
          "name": "Muang Tia",
          "postCode": [
            "94180"
          ]
        },
        {
          "id": 941203,
          "name": "Pa Rai",
          "postCode": [
            "94180"
          ]
        }
      ]
    },
    {
      "city": "Mai Kaen",
      "cityCode": 9408,
      "provinceCode": 94,
      "subDistrict": [
        {
          "id": 940804,
          "name": "Don Sai",
          "postCode": [
            "94220"
          ]
        },
        {
          "id": 940802,
          "name": "Mai Kaen",
          "postCode": [
            "94220"
          ]
        },
        {
          "id": 940801,
          "name": "Sai Thong",
          "postCode": [
            "94220"
          ]
        },
        {
          "id": 940803,
          "name": "Talo Kraithong",
          "postCode": [
            "94220"
          ]
        }
      ]
    },
    {
      "city": "Mayo",
      "cityCode": 9405,
      "provinceCode": 94,
      "subDistrict": [
        {
          "id": 940508,
          "name": "Ko Chan",
          "postCode": [
            "94140"
          ]
        },
        {
          "id": 940507,
          "name": "Kraso",
          "postCode": [
            "94140"
          ]
        },
        {
          "id": 940504,
          "name": "Krawa",
          "postCode": [
            "94140",
            "94190"
          ]
        },
        {
          "id": 940506,
          "name": "La-nga",
          "postCode": [
            "94190"
          ]
        },
        {
          "id": 940505,
          "name": "Lubo yilai",
          "postCode": [
            "94140"
          ]
        },
        {
          "id": 940501,
          "name": "Mayo",
          "postCode": [
            "94140"
          ]
        },
        {
          "id": 940509,
          "name": "Pado",
          "postCode": [
            "94140"
          ]
        },
        {
          "id": 940513,
          "name": "Panan",
          "postCode": [
            "94140"
          ]
        },
        {
          "id": 940512,
          "name": "Sakam",
          "postCode": [
            "94140"
          ]
        },
        {
          "id": 940510,
          "name": "Sakho Bon",
          "postCode": [
            "94140"
          ]
        },
        {
          "id": 940511,
          "name": "Sakho Tai",
          "postCode": [
            "94140"
          ]
        },
        {
          "id": 940502,
          "name": "Thanon",
          "postCode": [
            "94140"
          ]
        },
        {
          "id": 940503,
          "name": "Trang",
          "postCode": [
            "94140"
          ]
        }
      ]
    },
    {
      "city": "Mueang Pattani",
      "cityCode": 9401,
      "provinceCode": 94,
      "subDistrict": [
        {
          "id": 940102,
          "name": "Anoru",
          "postCode": [
            "94000"
          ]
        },
        {
          "id": 940104,
          "name": "Bana",
          "postCode": [
            "94000"
          ]
        },
        {
          "id": 940112,
          "name": "Bara Ho",
          "postCode": [
            "94000"
          ]
        },
        {
          "id": 940108,
          "name": "Bara Hom",
          "postCode": [
            "94000"
          ]
        },
        {
          "id": 940103,
          "name": "Chabang Tiko",
          "postCode": [
            "94000"
          ]
        },
        {
          "id": 940107,
          "name": "Kamiyo",
          "postCode": [
            "94000"
          ]
        },
        {
          "id": 940106,
          "name": "Khlong Maning",
          "postCode": [
            "94000"
          ]
        },
        {
          "id": 940109,
          "name": "Paka Harang",
          "postCode": [
            "94000"
          ]
        },
        {
          "id": 940113,
          "name": "Puyut",
          "postCode": [
            "94000"
          ]
        },
        {
          "id": 940110,
          "name": "Ru Samilae",
          "postCode": [
            "94000"
          ]
        },
        {
          "id": 940101,
          "name": "Sabarang",
          "postCode": [
            "94000"
          ]
        },
        {
          "id": 940111,
          "name": "Talubo",
          "postCode": [
            "94000"
          ]
        },
        {
          "id": 940105,
          "name": "Tanyong Lulo",
          "postCode": [
            "94000"
          ]
        }
      ]
    },
    {
      "city": "Nong Chik",
      "cityCode": 9403,
      "provinceCode": 94,
      "subDistrict": [
        {
          "id": 940308,
          "name": "Bang Khao",
          "postCode": [
            "94170"
          ]
        },
        {
          "id": 940309,
          "name": "Bang Tawa",
          "postCode": [
            "94170"
          ]
        },
        {
          "id": 940307,
          "name": "Bo Thong",
          "postCode": [
            "94170"
          ]
        },
        {
          "id": 940304,
          "name": "Dato",
          "postCode": [
            "94170"
          ]
        },
        {
          "id": 940303,
          "name": "Don Rak",
          "postCode": [
            "94170"
          ]
        },
        {
          "id": 940302,
          "name": "Kholo Tanyong",
          "postCode": [
            "94170"
          ]
        },
        {
          "id": 940301,
          "name": "Ko Po",
          "postCode": [
            "94170"
          ]
        },
        {
          "id": 940312,
          "name": "Lipa Sa-ngo",
          "postCode": [
            "94170"
          ]
        },
        {
          "id": 940310,
          "name": "Pulo Puyo",
          "postCode": [
            "94170"
          ]
        },
        {
          "id": 940306,
          "name": "Tha Kamcham",
          "postCode": [
            "94170"
          ]
        },
        {
          "id": 940305,
          "name": "Tuyong",
          "postCode": [
            "94170"
          ]
        },
        {
          "id": 940311,
          "name": "Yabi",
          "postCode": [
            "94170"
          ]
        }
      ]
    },
    {
      "city": "Panare",
      "cityCode": 9404,
      "provinceCode": 94,
      "subDistrict": [
        {
          "id": 940409,
          "name": "Ban Klang",
          "postCode": [
            "94130"
          ]
        },
        {
          "id": 940410,
          "name": "Ban Nam Bo",
          "postCode": [
            "94130"
          ]
        },
        {
          "id": 940403,
          "name": "Ban Nok",
          "postCode": [
            "94130"
          ]
        },
        {
          "id": 940404,
          "name": "Don",
          "postCode": [
            "94130",
            "94190"
          ]
        },
        {
          "id": 940407,
          "name": "Khok Krabue",
          "postCode": [
            "94130"
          ]
        },
        {
          "id": 940405,
          "name": "Khuan",
          "postCode": [
            "94190"
          ]
        },
        {
          "id": 940401,
          "name": "Panare",
          "postCode": [
            "94130"
          ]
        },
        {
          "id": 940408,
          "name": "Pho Ming",
          "postCode": [
            "94130"
          ]
        },
        {
          "id": 940402,
          "name": "Tha Kham",
          "postCode": [
            "94130"
          ]
        },
        {
          "id": 940406,
          "name": "Tha Nam",
          "postCode": [
            "94130"
          ]
        }
      ]
    },
    {
      "city": "Sai Buri",
      "cityCode": 9407,
      "provinceCode": 94,
      "subDistrict": [
        {
          "id": 940704,
          "name": "Bang Kao",
          "postCode": [
            "94110"
          ]
        },
        {
          "id": 940705,
          "name": "Bue Re",
          "postCode": [
            "94110"
          ]
        },
        {
          "id": 940707,
          "name": "Kadunong",
          "postCode": [
            "94110"
          ]
        },
        {
          "id": 940708,
          "name": "Lahan",
          "postCode": [
            "94110"
          ]
        },
        {
          "id": 940709,
          "name": "Manang Dalam",
          "postCode": [
            "94110"
          ]
        },
        {
          "id": 940710,
          "name": "Paen",
          "postCode": [
            "94110"
          ]
        },
        {
          "id": 940703,
          "name": "Pase Yawo",
          "postCode": [
            "94110"
          ]
        },
        {
          "id": 940702,
          "name": "Tabing",
          "postCode": [
            "94110"
          ]
        },
        {
          "id": 940701,
          "name": "Taluban",
          "postCode": [
            "94110"
          ]
        },
        {
          "id": 940711,
          "name": "Thung Khla",
          "postCode": [
            "94190"
          ]
        },
        {
          "id": 940706,
          "name": "Tro Bon",
          "postCode": [
            "94110"
          ]
        }
      ]
    },
    {
      "city": "Thung Yang Daeng",
      "cityCode": 9406,
      "provinceCode": 94,
      "subDistrict": [
        {
          "id": 940603,
          "name": "Nam Dam",
          "postCode": [
            "94140"
          ]
        },
        {
          "id": 940604,
          "name": "Paku",
          "postCode": [
            "94140"
          ]
        },
        {
          "id": 940602,
          "name": "Phithen",
          "postCode": [
            "94140"
          ]
        },
        {
          "id": 940601,
          "name": "Talo Maena",
          "postCode": [
            "94140"
          ]
        }
      ]
    },
    {
      "city": "Yarang",
      "cityCode": 9410,
      "provinceCode": 94,
      "subDistrict": [
        {
          "id": 941012,
          "name": "Khao Tum",
          "postCode": [
            "94160"
          ]
        },
        {
          "id": 941009,
          "name": "Khlong Mai",
          "postCode": [
            "94160"
          ]
        },
        {
          "id": 941011,
          "name": "Kolam",
          "postCode": [
            "94160"
          ]
        },
        {
          "id": 941008,
          "name": "Krado",
          "postCode": [
            "94160"
          ]
        },
        {
          "id": 941010,
          "name": "Mo Mawi",
          "postCode": [
            "94160"
          ]
        },
        {
          "id": 941006,
          "name": "Pitu Mudi",
          "postCode": [
            "94160"
          ]
        },
        {
          "id": 941003,
          "name": "Prachan",
          "postCode": [
            "94160"
          ]
        },
        {
          "id": 941005,
          "name": "Rawaeng",
          "postCode": [
            "94160"
          ]
        },
        {
          "id": 941002,
          "name": "Sadawa",
          "postCode": [
            "94160"
          ]
        },
        {
          "id": 941004,
          "name": "Sano",
          "postCode": [
            "94160"
          ]
        },
        {
          "id": 941007,
          "name": "Wat",
          "postCode": [
            "94160"
          ]
        },
        {
          "id": 941001,
          "name": "Yarang",
          "postCode": [
            "94160"
          ]
        }
      ]
    },
    {
      "city": "Yaring",
      "cityCode": 9409,
      "provinceCode": 94,
      "subDistrict": [
        {
          "id": 940913,
          "name": "Baloi",
          "postCode": [
            "94190"
          ]
        },
        {
          "id": 940909,
          "name": "Bang Pu",
          "postCode": [
            "94150"
          ]
        },
        {
          "id": 940917,
          "name": "Charang",
          "postCode": [
            "94150"
          ]
        },
        {
          "id": 940918,
          "name": "Laem Pho",
          "postCode": [
            "94150"
          ]
        },
        {
          "id": 940915,
          "name": "Manang Yong",
          "postCode": [
            "94150"
          ]
        },
        {
          "id": 940910,
          "name": "Nong Raet",
          "postCode": [
            "94150"
          ]
        },
        {
          "id": 940911,
          "name": "Piya Mumang",
          "postCode": [
            "94150"
          ]
        },
        {
          "id": 940912,
          "name": "Pulakong",
          "postCode": [
            "94150"
          ]
        },
        {
          "id": 940916,
          "name": "Rata Panyang",
          "postCode": [
            "94150"
          ]
        },
        {
          "id": 940914,
          "name": "Saban",
          "postCode": [
            "94150"
          ]
        },
        {
          "id": 940906,
          "name": "Takae",
          "postCode": [
            "94150"
          ]
        },
        {
          "id": 940907,
          "name": "Tali-ai",
          "postCode": [
            "94150"
          ]
        },
        {
          "id": 940901,
          "name": "Talo",
          "postCode": [
            "94150"
          ]
        },
        {
          "id": 940902,
          "name": "Talo Kapo",
          "postCode": [
            "94150"
          ]
        },
        {
          "id": 940904,
          "name": "Tanyong Chueng-nga",
          "postCode": [
            "94190"
          ]
        },
        {
          "id": 940903,
          "name": "Tanyong Dalo",
          "postCode": [
            "94150"
          ]
        },
        {
          "id": 940905,
          "name": "Tolang",
          "postCode": [
            "94150"
          ]
        },
        {
          "id": 940908,
          "name": "Yamu",
          "postCode": [
            "94150"
          ]
        }
      ]
    },
    {
      "city": "Kapong",
      "cityCode": 8203,
      "provinceCode": 82,
      "subDistrict": [
        {
          "id": 820301,
          "name": "Kapong",
          "postCode": [
            "82170"
          ]
        },
        {
          "id": 820304,
          "name": "Le",
          "postCode": [
            "82170"
          ]
        },
        {
          "id": 820303,
          "name": "Mo",
          "postCode": [
            "82170"
          ]
        },
        {
          "id": 820305,
          "name": "Rommani",
          "postCode": [
            "82170"
          ]
        },
        {
          "id": 820302,
          "name": "Tha Na",
          "postCode": [
            "82170"
          ]
        }
      ]
    },
    {
      "city": "Khura Buri",
      "cityCode": 8206,
      "provinceCode": 82,
      "subDistrict": [
        {
          "id": 820602,
          "name": "Bang Wan",
          "postCode": [
            "82150"
          ]
        },
        {
          "id": 820601,
          "name": "Khura",
          "postCode": [
            "82150"
          ]
        },
        {
          "id": 820603,
          "name": "Ko Phra Thong",
          "postCode": [
            "82150"
          ]
        },
        {
          "id": 820605,
          "name": "Mae Nang Khao",
          "postCode": [
            "82150"
          ]
        }
      ]
    },
    {
      "city": "Ko Yao",
      "cityCode": 8202,
      "provinceCode": 82,
      "subDistrict": [
        {
          "id": 820201,
          "name": "Ko Yao Noi",
          "postCode": [
            "82160"
          ]
        },
        {
          "id": 820202,
          "name": "Ko yao Yai",
          "postCode": [
            "82160"
          ]
        },
        {
          "id": 820203,
          "name": "Phru Nai",
          "postCode": [
            "83000"
          ]
        }
      ]
    },
    {
      "city": "Mueang Phang-nga",
      "cityCode": 8201,
      "provinceCode": 82,
      "subDistrict": [
        {
          "id": 820104,
          "name": "Bang Toei",
          "postCode": [
            "82000"
          ]
        },
        {
          "id": 820108,
          "name": "Ko Panyi",
          "postCode": [
            "82000"
          ]
        },
        {
          "id": 820102,
          "name": "Nop Pring",
          "postCode": [
            "82000"
          ]
        },
        {
          "id": 820109,
          "name": "Pa Ko",
          "postCode": [
            "82000"
          ]
        },
        {
          "id": 820106,
          "name": "Song Phraek",
          "postCode": [
            "82000"
          ]
        },
        {
          "id": 820105,
          "name": "Tak Daet",
          "postCode": [
            "82000"
          ]
        },
        {
          "id": 820101,
          "name": "Thai Chang",
          "postCode": [
            "82000"
          ]
        },
        {
          "id": 820103,
          "name": "Tham Nam Phut",
          "postCode": [
            "82000"
          ]
        },
        {
          "id": 820107,
          "name": "Thung Kha Ngok",
          "postCode": [
            "82000"
          ]
        }
      ]
    },
    {
      "city": "Takua Pa",
      "cityCode": 8205,
      "provinceCode": 82,
      "subDistrict": [
        {
          "id": 820504,
          "name": "Bang Muang",
          "postCode": [
            "82110",
            "82190"
          ]
        },
        {
          "id": 820502,
          "name": "Bang Nai Si",
          "postCode": [
            "82110"
          ]
        },
        {
          "id": 820503,
          "name": "Bang Sai",
          "postCode": [
            "82110"
          ]
        },
        {
          "id": 820506,
          "name": "Khok Khian",
          "postCode": [
            "82110"
          ]
        },
        {
          "id": 820507,
          "name": "Khuekkhak",
          "postCode": [
            "82190"
          ]
        },
        {
          "id": 820508,
          "name": "Ko Kho Khao",
          "postCode": [
            "82190"
          ]
        },
        {
          "id": 820501,
          "name": "Takua Pa",
          "postCode": [
            "82110"
          ]
        },
        {
          "id": 820505,
          "name": "Tam Tua",
          "postCode": [
            "82110"
          ]
        }
      ]
    },
    {
      "city": "Takua Thung",
      "cityCode": 8204,
      "provinceCode": 82,
      "subDistrict": [
        {
          "id": 820403,
          "name": "Kalai",
          "postCode": [
            "82130"
          ]
        },
        {
          "id": 820407,
          "name": "Khlong Khian",
          "postCode": [
            "82130"
          ]
        },
        {
          "id": 820406,
          "name": "Khok Kloi",
          "postCode": [
            "82140"
          ]
        },
        {
          "id": 820402,
          "name": "Krasom",
          "postCode": [
            "82130"
          ]
        },
        {
          "id": 820405,
          "name": "Lo Yung",
          "postCode": [
            "82140"
          ]
        },
        {
          "id": 820404,
          "name": "Tha Yu",
          "postCode": [
            "82130"
          ]
        },
        {
          "id": 820401,
          "name": "Tham",
          "postCode": [
            "82130"
          ]
        }
      ]
    },
    {
      "city": "Thai Mueang",
      "cityCode": 8208,
      "provinceCode": 82,
      "subDistrict": [
        {
          "id": 820803,
          "name": "Bang Thong",
          "postCode": [
            "82120"
          ]
        },
        {
          "id": 820806,
          "name": "Lam Kaen",
          "postCode": [
            "82120",
            "82210"
          ]
        },
        {
          "id": 820805,
          "name": "Lam Phi",
          "postCode": [
            "82120"
          ]
        },
        {
          "id": 820802,
          "name": "Na Toei",
          "postCode": [
            "82120"
          ]
        },
        {
          "id": 820801,
          "name": "Thai Mueang",
          "postCode": [
            "82120"
          ]
        },
        {
          "id": 820804,
          "name": "Thung Maphrao",
          "postCode": [
            "82120"
          ]
        }
      ]
    },
    {
      "city": "Thap Put",
      "cityCode": 8207,
      "provinceCode": 82,
      "subDistrict": [
        {
          "id": 820706,
          "name": "Bang Riang",
          "postCode": [
            "82180"
          ]
        },
        {
          "id": 820703,
          "name": "Bo Saen",
          "postCode": [
            "82180"
          ]
        },
        {
          "id": 820705,
          "name": "Khok Charoen",
          "postCode": [
            "82180"
          ]
        },
        {
          "id": 820702,
          "name": "Marui",
          "postCode": [
            "82180"
          ]
        },
        {
          "id": 820704,
          "name": "Tham Thonglang",
          "postCode": [
            "82180"
          ]
        },
        {
          "id": 820701,
          "name": "Thap Put",
          "postCode": [
            "82180"
          ]
        }
      ]
    },
    {
      "city": "Bang Kaeo",
      "cityCode": 9309,
      "provinceCode": 93,
      "subDistrict": [
        {
          "id": 930903,
          "name": "Khok Sak",
          "postCode": [
            "93140",
            "93160"
          ]
        },
        {
          "id": 930902,
          "name": "Na Pakho",
          "postCode": [
            "93140"
          ]
        },
        {
          "id": 930901,
          "name": "Tha Maduea",
          "postCode": [
            "93140"
          ]
        }
      ]
    },
    {
      "city": "Khao Chaison",
      "cityCode": 9303,
      "provinceCode": 93,
      "subDistrict": [
        {
          "id": 930305,
          "name": "Chong Thanon",
          "postCode": [
            "93130"
          ]
        },
        {
          "id": 930306,
          "name": "Han Pho",
          "postCode": [
            "93130"
          ]
        },
        {
          "id": 930301,
          "name": "Khao Chaison",
          "postCode": [
            "93130"
          ]
        },
        {
          "id": 930307,
          "name": "Khok Muang",
          "postCode": [
            "93130"
          ]
        },
        {
          "id": 930302,
          "name": "Khuan Khanun",
          "postCode": [
            "93130"
          ]
        }
      ]
    },
    {
      "city": "Khuan Khanun",
      "cityCode": 9305,
      "provinceCode": 93,
      "subDistrict": [
        {
          "id": 930513,
          "name": "Chamuang",
          "postCode": [
            "93110"
          ]
        },
        {
          "id": 930510,
          "name": "Don Sai",
          "postCode": [
            "93110"
          ]
        },
        {
          "id": 930501,
          "name": "Khuan Khanun",
          "postCode": [
            "93110"
          ]
        },
        {
          "id": 930506,
          "name": "Laem Tanot",
          "postCode": [
            "93110"
          ]
        },
        {
          "id": 930511,
          "name": "Makok Nuea",
          "postCode": [
            "93150"
          ]
        },
        {
          "id": 930504,
          "name": "Na Khayat",
          "postCode": [
            "93110"
          ]
        },
        {
          "id": 930508,
          "name": "Pan Tae",
          "postCode": [
            "93110"
          ]
        },
        {
          "id": 930512,
          "name": "Phanang Tung",
          "postCode": [
            "93150"
          ]
        },
        {
          "id": 930505,
          "name": "Phanom Wang",
          "postCode": [
            "93110"
          ]
        },
        {
          "id": 930516,
          "name": "Phraek Ha",
          "postCode": [
            "93110"
          ]
        },
        {
          "id": 930509,
          "name": "Tanot Duan",
          "postCode": [
            "93110"
          ]
        },
        {
          "id": 930502,
          "name": "Thale Noi",
          "postCode": [
            "93150"
          ]
        }
      ]
    },
    {
      "city": "Kong Ra",
      "cityCode": 9302,
      "provinceCode": 93,
      "subDistrict": [
        {
          "id": 930202,
          "name": "Charat",
          "postCode": [
            "93000"
          ]
        },
        {
          "id": 930203,
          "name": "Khlong Chaloem",
          "postCode": [
            "93180"
          ]
        },
        {
          "id": 930204,
          "name": "Khlong Sai Khao",
          "postCode": [
            "93180"
          ]
        },
        {
          "id": 930201,
          "name": "Kong Ra",
          "postCode": [
            "93180"
          ]
        },
        {
          "id": 930205,
          "name": "Som Wang",
          "postCode": [
            "93000"
          ]
        }
      ]
    },
    {
      "city": "Mueang Phatthalung",
      "cityCode": 9301,
      "provinceCode": 93,
      "subDistrict": [
        {
          "id": 930113,
          "name": "Chai Buri",
          "postCode": [
            "93000"
          ]
        },
        {
          "id": 930103,
          "name": "Khao Chiak",
          "postCode": [
            "93000"
          ]
        },
        {
          "id": 930105,
          "name": "Khok Cha-ngai",
          "postCode": [
            "93000"
          ]
        },
        {
          "id": 930111,
          "name": "Khuan Maphrao",
          "postCode": [
            "93000"
          ]
        },
        {
          "id": 930101,
          "name": "Khuha Sawan",
          "postCode": [
            "93000"
          ]
        },
        {
          "id": 930109,
          "name": "Lampam",
          "postCode": [
            "93000"
          ]
        },
        {
          "id": 930114,
          "name": "Na Not",
          "postCode": [
            "93000"
          ]
        },
        {
          "id": 930106,
          "name": "Na Thom",
          "postCode": [
            "93000"
          ]
        },
        {
          "id": 930115,
          "name": "Phaya Khan",
          "postCode": [
            "93000"
          ]
        },
        {
          "id": 930107,
          "name": "Prang Mu",
          "postCode": [
            "93000"
          ]
        },
        {
          "id": 930112,
          "name": "Rom Mueang",
          "postCode": [
            "93000"
          ]
        },
        {
          "id": 930110,
          "name": "Tamnan",
          "postCode": [
            "93000"
          ]
        },
        {
          "id": 930108,
          "name": "Tha Kae",
          "postCode": [
            "93000"
          ]
        },
        {
          "id": 930104,
          "name": "Tha Miram",
          "postCode": [
            "93000"
          ]
        }
      ]
    },
    {
      "city": "Pa Bon",
      "cityCode": 9308,
      "provinceCode": 93,
      "subDistrict": [
        {
          "id": 930802,
          "name": "Khok Sai",
          "postCode": [
            "93170"
          ]
        },
        {
          "id": 930803,
          "name": "Nong Thong",
          "postCode": [
            "93170"
          ]
        },
        {
          "id": 930801,
          "name": "Pa Bon",
          "postCode": [
            "93170"
          ]
        },
        {
          "id": 930804,
          "name": "Thung Nari",
          "postCode": [
            "93170"
          ]
        },
        {
          "id": 930806,
          "name": "Wang Mai",
          "postCode": [
            "93170"
          ]
        }
      ]
    },
    {
      "city": "Pa Phayom",
      "cityCode": 9310,
      "provinceCode": 93,
      "subDistrict": [
        {
          "id": 931004,
          "name": "Ban Phrao",
          "postCode": [
            "93210"
          ]
        },
        {
          "id": 931003,
          "name": "Ko Tao",
          "postCode": [
            "93210"
          ]
        },
        {
          "id": 931002,
          "name": "Lan Khoi",
          "postCode": [
            "93210"
          ]
        },
        {
          "id": 931001,
          "name": "Pa Phayom",
          "postCode": [
            "93210"
          ]
        }
      ]
    },
    {
      "city": "Pak Phayun",
      "cityCode": 9306,
      "provinceCode": 93,
      "subDistrict": [
        {
          "id": 930602,
          "name": "Don Pradu",
          "postCode": [
            "93120"
          ]
        },
        {
          "id": 930607,
          "name": "Don Sai",
          "postCode": [
            "93120"
          ]
        },
        {
          "id": 930605,
          "name": "Falami",
          "postCode": [
            "93120"
          ]
        },
        {
          "id": 930606,
          "name": "Han Thao",
          "postCode": [
            "93120"
          ]
        },
        {
          "id": 930604,
          "name": "Ko Mak",
          "postCode": [
            "93120"
          ]
        },
        {
          "id": 930603,
          "name": "Ko Nang Kham",
          "postCode": [
            "93120"
          ]
        },
        {
          "id": 930601,
          "name": "Pak Phayun",
          "postCode": [
            "93120"
          ]
        }
      ]
    },
    {
      "city": "Si Banphot",
      "cityCode": 9307,
      "provinceCode": 93,
      "subDistrict": [
        {
          "id": 930702,
          "name": "Khao Pu",
          "postCode": [
            "93190"
          ]
        },
        {
          "id": 930701,
          "name": "Khao Ya",
          "postCode": [
            "93190"
          ]
        },
        {
          "id": 930703,
          "name": "Taphaen",
          "postCode": [
            "93190"
          ]
        }
      ]
    },
    {
      "city": "Srinagarindra",
      "cityCode": 9311,
      "provinceCode": 93,
      "subDistrict": [
        {
          "id": 931103,
          "name": "Ang Thong",
          "postCode": [
            "93000"
          ]
        },
        {
          "id": 931102,
          "name": "Ban Na",
          "postCode": [
            "93000"
          ]
        },
        {
          "id": 931101,
          "name": "Chumpon",
          "postCode": [
            "93000"
          ]
        },
        {
          "id": 931104,
          "name": "Lam Sin",
          "postCode": [
            "93000"
          ]
        }
      ]
    },
    {
      "city": "Tamot",
      "cityCode": 9304,
      "provinceCode": 93,
      "subDistrict": [
        {
          "id": 930403,
          "name": "Khlong Yai",
          "postCode": [
            "93160"
          ]
        },
        {
          "id": 930401,
          "name": "Mae Khari",
          "postCode": [
            "93160"
          ]
        },
        {
          "id": 930402,
          "name": "Tamot",
          "postCode": [
            "93160"
          ]
        }
      ]
    },
    {
      "city": "Chiang Kham",
      "cityCode": 5603,
      "provinceCode": 56,
      "subDistrict": [
        {
          "id": 560313,
          "name": "Ang Thong",
          "postCode": [
            "56110"
          ]
        },
        {
          "id": 560309,
          "name": "Chedi Kham",
          "postCode": [
            "56110"
          ]
        },
        {
          "id": 560311,
          "name": "Chiang Ban",
          "postCode": [
            "56110"
          ]
        },
        {
          "id": 560308,
          "name": "Fai Kwang",
          "postCode": [
            "56110"
          ]
        },
        {
          "id": 560312,
          "name": "Mae Lao",
          "postCode": [
            "56110"
          ]
        },
        {
          "id": 560306,
          "name": "Nam Waen",
          "postCode": [
            "56110"
          ]
        },
        {
          "id": 560310,
          "name": "Rom Yen",
          "postCode": [
            "56110"
          ]
        },
        {
          "id": 560314,
          "name": "Thung Pha Suk",
          "postCode": [
            "56110"
          ]
        },
        {
          "id": 560307,
          "name": "Wiang",
          "postCode": [
            "56110"
          ]
        },
        {
          "id": 560301,
          "name": "Yuan",
          "postCode": [
            "56110"
          ]
        }
      ]
    },
    {
      "city": "Chiang Muan",
      "cityCode": 5604,
      "provinceCode": 56,
      "subDistrict": [
        {
          "id": 560402,
          "name": "Ban Mang",
          "postCode": [
            "56160"
          ]
        },
        {
          "id": 560401,
          "name": "Chiang Muan",
          "postCode": [
            "56160"
          ]
        },
        {
          "id": 560403,
          "name": "Sa",
          "postCode": [
            "56160"
          ]
        }
      ]
    },
    {
      "city": "Chun",
      "cityCode": 5602,
      "provinceCode": 56,
      "subDistrict": [
        {
          "id": 560202,
          "name": "Chun",
          "postCode": [
            "56150"
          ]
        },
        {
          "id": 560204,
          "name": "Hong Hin",
          "postCode": [
            "56150"
          ]
        },
        {
          "id": 560201,
          "name": "Huai Khao Kam",
          "postCode": [
            "56150"
          ]
        },
        {
          "id": 560206,
          "name": "Huai Yang Kham",
          "postCode": [
            "56150"
          ]
        },
        {
          "id": 560203,
          "name": "Lo",
          "postCode": [
            "56150"
          ]
        },
        {
          "id": 560207,
          "name": "Phra That Khing Kaeng",
          "postCode": [
            "56150"
          ]
        },
        {
          "id": 560205,
          "name": "Thung Ruang Thong",
          "postCode": [
            "56150"
          ]
        }
      ]
    },
    {
      "city": "Dok Khamtai",
      "cityCode": 5605,
      "provinceCode": 56,
      "subDistrict": [
        {
          "id": 560504,
          "name": "Ban Pin",
          "postCode": [
            "56120"
          ]
        },
        {
          "id": 560503,
          "name": "Ban Tham",
          "postCode": [
            "56120"
          ]
        },
        {
          "id": 560510,
          "name": "Bun Koet",
          "postCode": [
            "56120"
          ]
        },
        {
          "id": 560501,
          "name": "Dok Kham Tai",
          "postCode": [
            "56120"
          ]
        },
        {
          "id": 560502,
          "name": "Don Si Chum",
          "postCode": [
            "56120"
          ]
        },
        {
          "id": 560509,
          "name": "Dong Suwan",
          "postCode": [
            "56120"
          ]
        },
        {
          "id": 560505,
          "name": "Huai Lan",
          "postCode": [
            "56120"
          ]
        },
        {
          "id": 560512,
          "name": "Khue Wiang",
          "postCode": [
            "56120"
          ]
        },
        {
          "id": 560508,
          "name": "Nong Lom",
          "postCode": [
            "56120"
          ]
        },
        {
          "id": 560507,
          "name": "Pa Sang",
          "postCode": [
            "56120"
          ]
        },
        {
          "id": 560506,
          "name": "San Khong",
          "postCode": [
            "56120"
          ]
        },
        {
          "id": 560511,
          "name": "Sawang Arom",
          "postCode": [
            "56120"
          ]
        }
      ]
    },
    {
      "city": "Mae Chai",
      "cityCode": 5607,
      "provinceCode": 56,
      "subDistrict": [
        {
          "id": 560705,
          "name": "Ban Lao",
          "postCode": [
            "56130"
          ]
        },
        {
          "id": 560706,
          "name": "Charoen Rat",
          "postCode": [
            "56130"
          ]
        },
        {
          "id": 560701,
          "name": "Mae Chai",
          "postCode": [
            "56130"
          ]
        },
        {
          "id": 560703,
          "name": "Mae Suk",
          "postCode": [
            "56130"
          ]
        },
        {
          "id": 560704,
          "name": "Pa Faek",
          "postCode": [
            "56130"
          ]
        },
        {
          "id": 560702,
          "name": "Si Thoi",
          "postCode": [
            "56130"
          ]
        }
      ]
    },
    {
      "city": "Mueang Phayao",
      "cityCode": 5601,
      "provinceCode": 56,
      "subDistrict": [
        {
          "id": 560111,
          "name": "Ban Mai",
          "postCode": [
            "56000"
          ]
        },
        {
          "id": 560115,
          "name": "Ban Sang",
          "postCode": [
            "56000"
          ]
        },
        {
          "id": 560106,
          "name": "Ban Tam",
          "postCode": [
            "56000"
          ]
        },
        {
          "id": 560107,
          "name": "Ban Tom",
          "postCode": [
            "56000"
          ]
        },
        {
          "id": 560105,
          "name": "Ban Tun",
          "postCode": [
            "56000"
          ]
        },
        {
          "id": 560112,
          "name": "Cham Pa Wai",
          "postCode": [
            "56000"
          ]
        },
        {
          "id": 560110,
          "name": "Mae Ka",
          "postCode": [
            "56000"
          ]
        },
        {
          "id": 560104,
          "name": "Mae Na Ruea",
          "postCode": [
            "56000"
          ]
        },
        {
          "id": 560108,
          "name": "Mae Puem",
          "postCode": [
            "56000"
          ]
        },
        {
          "id": 560114,
          "name": "Mae Sai",
          "postCode": [
            "56000"
          ]
        },
        {
          "id": 560102,
          "name": "Mae Tam",
          "postCode": [
            "56000"
          ]
        },
        {
          "id": 560118,
          "name": "San Pa Muang",
          "postCode": [
            "56000"
          ]
        },
        {
          "id": 560116,
          "name": "Tha Champi",
          "postCode": [
            "56000"
          ]
        },
        {
          "id": 560113,
          "name": "Tha Wang Thong",
          "postCode": [
            "56000"
          ]
        },
        {
          "id": 560101,
          "name": "Wiang",
          "postCode": [
            "56000"
          ]
        }
      ]
    },
    {
      "city": "Phu Kamyao",
      "cityCode": 5609,
      "provinceCode": 56,
      "subDistrict": [
        {
          "id": 560902,
          "name": "Dong Chen",
          "postCode": [
            "56000"
          ]
        },
        {
          "id": 560901,
          "name": "Huai Kaeo",
          "postCode": [
            "56000"
          ]
        },
        {
          "id": 560903,
          "name": "Mae Ing",
          "postCode": [
            "56000"
          ]
        }
      ]
    },
    {
      "city": "Phu Sang",
      "cityCode": 5608,
      "provinceCode": 56,
      "subDistrict": [
        {
          "id": 560804,
          "name": "Chiang Raeng",
          "postCode": [
            "56110"
          ]
        },
        {
          "id": 560802,
          "name": "Pa Sak",
          "postCode": [
            "56110"
          ]
        },
        {
          "id": 560801,
          "name": "Phu Sang",
          "postCode": [
            "56110"
          ]
        },
        {
          "id": 560805,
          "name": "Sop Bong",
          "postCode": [
            "56110"
          ]
        },
        {
          "id": 560803,
          "name": "Thung Kluai",
          "postCode": [
            "56110"
          ]
        }
      ]
    },
    {
      "city": "Pong",
      "cityCode": 5606,
      "provinceCode": 56,
      "subDistrict": [
        {
          "id": 560602,
          "name": "Khuan",
          "postCode": [
            "56140"
          ]
        },
        {
          "id": 560607,
          "name": "Khun Khuan",
          "postCode": [
            "56140"
          ]
        },
        {
          "id": 560606,
          "name": "Na Prang",
          "postCode": [
            "56140"
          ]
        },
        {
          "id": 560604,
          "name": "Ngim",
          "postCode": [
            "56140"
          ]
        },
        {
          "id": 560603,
          "name": "Oi",
          "postCode": [
            "56140"
          ]
        },
        {
          "id": 560605,
          "name": "Pha Chang Noi",
          "postCode": [
            "56140"
          ]
        },
        {
          "id": 560601,
          "name": "Pong",
          "postCode": [
            "56140"
          ]
        }
      ]
    },
    {
      "city": "Bueng Sam Phan",
      "cityCode": 6708,
      "provinceCode": 67,
      "subDistrict": [
        {
          "id": 670809,
          "name": "Bueng Sam Phan",
          "postCode": [
            "67160"
          ]
        },
        {
          "id": 670804,
          "name": "Kanchu",
          "postCode": [
            "67160"
          ]
        },
        {
          "id": 670803,
          "name": "Nong Chaeng",
          "postCode": [
            "67160"
          ]
        },
        {
          "id": 670806,
          "name": "Phaya Wang",
          "postCode": [
            "67160"
          ]
        },
        {
          "id": 670808,
          "name": "Sa Kaeo",
          "postCode": [
            "67160"
          ]
        },
        {
          "id": 670802,
          "name": "Sap Mai Daeng",
          "postCode": [
            "67160"
          ]
        },
        {
          "id": 670801,
          "name": "Sap Samo Thot",
          "postCode": [
            "67160"
          ]
        },
        {
          "id": 670807,
          "name": "Si Mongkhon",
          "postCode": [
            "67160"
          ]
        },
        {
          "id": 670805,
          "name": "Wang Phikun",
          "postCode": [
            "67230"
          ]
        }
      ]
    },
    {
      "city": "Chon Daen",
      "cityCode": 6702,
      "provinceCode": 67,
      "subDistrict": [
        {
          "id": 670206,
          "name": "Ban Kluai",
          "postCode": [
            "67190"
          ]
        },
        {
          "id": 670201,
          "name": "Chon Daen",
          "postCode": [
            "67150"
          ]
        },
        {
          "id": 670202,
          "name": "Dong Khui",
          "postCode": [
            "67190"
          ]
        },
        {
          "id": 670205,
          "name": "Lat Khae",
          "postCode": [
            "67150"
          ]
        },
        {
          "id": 670204,
          "name": "Phutthabat",
          "postCode": [
            "67150"
          ]
        },
        {
          "id": 670210,
          "name": "Sala Lai",
          "postCode": [
            "67150"
          ]
        },
        {
          "id": 670208,
          "name": "Sap Phutsa",
          "postCode": [
            "67150"
          ]
        },
        {
          "id": 670209,
          "name": "Takut Rai",
          "postCode": [
            "67190"
          ]
        },
        {
          "id": 670203,
          "name": "Tha Kham",
          "postCode": [
            "67150"
          ]
        }
      ]
    },
    {
      "city": "Khao Kho",
      "cityCode": 6711,
      "provinceCode": 67,
      "subDistrict": [
        {
          "id": 671102,
          "name": "Khaem Son",
          "postCode": [
            "67280"
          ]
        },
        {
          "id": 671103,
          "name": "Khao Kho",
          "postCode": [
            "67270"
          ]
        },
        {
          "id": 671107,
          "name": "Khek Noi",
          "postCode": [
            "67280"
          ]
        },
        {
          "id": 671106,
          "name": "Nong Mae Na",
          "postCode": [
            "67270"
          ]
        },
        {
          "id": 671104,
          "name": "Rim Si Muang",
          "postCode": [
            "67270"
          ]
        },
        {
          "id": 671105,
          "name": "Sado Phong",
          "postCode": [
            "67270"
          ]
        },
        {
          "id": 671101,
          "name": "Thung Samo",
          "postCode": [
            "67270"
          ]
        }
      ]
    },
    {
      "city": "Lom Kao",
      "cityCode": 6704,
      "provinceCode": 67,
      "subDistrict": [
        {
          "id": 670404,
          "name": "Ban Noen",
          "postCode": [
            "67120"
          ]
        },
        {
          "id": 670403,
          "name": "Hin Hao",
          "postCode": [
            "67120"
          ]
        },
        {
          "id": 670401,
          "name": "Lom Kao",
          "postCode": [
            "67120"
          ]
        },
        {
          "id": 670408,
          "name": "Na Ko",
          "postCode": [
            "67120"
          ]
        },
        {
          "id": 670406,
          "name": "Na Saeng",
          "postCode": [
            "67120"
          ]
        },
        {
          "id": 670402,
          "name": "Na Sam",
          "postCode": [
            "67120"
          ]
        },
        {
          "id": 670405,
          "name": "Sila",
          "postCode": [
            "67120"
          ]
        },
        {
          "id": 670409,
          "name": "Tat Kloi",
          "postCode": [
            "67120"
          ]
        },
        {
          "id": 670407,
          "name": "Wang Ban",
          "postCode": [
            "67120"
          ]
        }
      ]
    },
    {
      "city": "Lom Sak",
      "cityCode": 6703,
      "provinceCode": 67,
      "subDistrict": [
        {
          "id": 670319,
          "name": "Ban Klang",
          "postCode": [
            "67110"
          ]
        },
        {
          "id": 670321,
          "name": "Ban Rai",
          "postCode": [
            "67110"
          ]
        },
        {
          "id": 670309,
          "name": "Ban Sok",
          "postCode": [
            "67110"
          ]
        },
        {
          "id": 670310,
          "name": "Ban Tio",
          "postCode": [
            "67110"
          ]
        },
        {
          "id": 670323,
          "name": "Ban Wai",
          "postCode": [
            "67110"
          ]
        },
        {
          "id": 670317,
          "name": "Bung Khla",
          "postCode": [
            "67110"
          ]
        },
        {
          "id": 670318,
          "name": "Bung Namtao",
          "postCode": [
            "67110"
          ]
        },
        {
          "id": 670320,
          "name": "Chang Talut",
          "postCode": [
            "67110"
          ]
        },
        {
          "id": 670304,
          "name": "Fai Na Saeng",
          "postCode": [
            "67110"
          ]
        },
        {
          "id": 670311,
          "name": "Huai Rai",
          "postCode": [
            "67110"
          ]
        },
        {
          "id": 670316,
          "name": "Lan Ba",
          "postCode": [
            "67110"
          ]
        },
        {
          "id": 670301,
          "name": "Lom Sak",
          "postCode": [
            "67110"
          ]
        },
        {
          "id": 670314,
          "name": "Nam Chun",
          "postCode": [
            "67110"
          ]
        },
        {
          "id": 670306,
          "name": "Nam Hia",
          "postCode": [
            "67110"
          ]
        },
        {
          "id": 670312,
          "name": "Nam Ko",
          "postCode": [
            "67110"
          ]
        },
        {
          "id": 670315,
          "name": "Nong Khwai",
          "postCode": [
            "67110"
          ]
        },
        {
          "id": 670305,
          "name": "Nong Sawang",
          "postCode": [
            "67110"
          ]
        },
        {
          "id": 670313,
          "name": "Pak Chong",
          "postCode": [
            "67110"
          ]
        },
        {
          "id": 670322,
          "name": "Pak Duk",
          "postCode": [
            "67110"
          ]
        },
        {
          "id": 670307,
          "name": "Sak Long",
          "postCode": [
            "67110"
          ]
        },
        {
          "id": 670303,
          "name": "Tan Diao",
          "postCode": [
            "67110"
          ]
        },
        {
          "id": 670308,
          "name": "Tha Ibun",
          "postCode": [
            "67110"
          ]
        },
        {
          "id": 670302,
          "name": "Wat Pa",
          "postCode": [
            "67110"
          ]
        }
      ]
    },
    {
      "city": "Mueang Phetchabun",
      "cityCode": 6701,
      "provinceCode": 67,
      "subDistrict": [
        {
          "id": 670109,
          "name": "Ban Khok",
          "postCode": [
            "67000"
          ]
        },
        {
          "id": 670103,
          "name": "Ban Tok",
          "postCode": [
            "67000"
          ]
        },
        {
          "id": 670110,
          "name": "Chon Phrai",
          "postCode": [
            "67000"
          ]
        },
        {
          "id": 670108,
          "name": "Dong Mun Lek",
          "postCode": [
            "67000"
          ]
        },
        {
          "id": 670115,
          "name": "Huai Sakae",
          "postCode": [
            "67210"
          ]
        },
        {
          "id": 670116,
          "name": "Huai Yai",
          "postCode": [
            "67000"
          ]
        },
        {
          "id": 670106,
          "name": "Na Ngua",
          "postCode": [
            "67000"
          ]
        },
        {
          "id": 670111,
          "name": "Na Pa",
          "postCode": [
            "67000"
          ]
        },
        {
          "id": 670112,
          "name": "Na Yom",
          "postCode": [
            "67210"
          ]
        },
        {
          "id": 670101,
          "name": "Nai Mueang",
          "postCode": [
            "67000"
          ]
        },
        {
          "id": 670114,
          "name": "Nam Ron",
          "postCode": [
            "67000"
          ]
        },
        {
          "id": 670105,
          "name": "Pa Lao",
          "postCode": [
            "67000"
          ]
        },
        {
          "id": 670117,
          "name": "Rawing",
          "postCode": [
            "67210"
          ]
        },
        {
          "id": 670104,
          "name": "Sadiang",
          "postCode": [
            "67000"
          ]
        },
        {
          "id": 670102,
          "name": "Tabo",
          "postCode": [
            "67000"
          ]
        },
        {
          "id": 670107,
          "name": "Tha Phon",
          "postCode": [
            "67250"
          ]
        },
        {
          "id": 670113,
          "name": "Wang Chomphu",
          "postCode": [
            "67210"
          ]
        }
      ]
    },
    {
      "city": "Nam Nao",
      "cityCode": 6709,
      "provinceCode": 67,
      "subDistrict": [
        {
          "id": 670904,
          "name": "Khok Mon",
          "postCode": [
            "67260"
          ]
        },
        {
          "id": 670902,
          "name": "Lak Dan",
          "postCode": [
            "67260"
          ]
        },
        {
          "id": 670901,
          "name": "Nam Nao",
          "postCode": [
            "67260"
          ]
        },
        {
          "id": 670903,
          "name": "Wang Kwang",
          "postCode": [
            "67260"
          ]
        }
      ]
    },
    {
      "city": "Nong Phai",
      "cityCode": 6707,
      "provinceCode": 67,
      "subDistrict": [
        {
          "id": 670703,
          "name": "Ban Phot",
          "postCode": [
            "67140"
          ]
        },
        {
          "id": 670706,
          "name": "Bo Thai",
          "postCode": [
            "67140"
          ]
        },
        {
          "id": 670709,
          "name": "Bua Watthana",
          "postCode": [
            "67140"
          ]
        },
        {
          "id": 670707,
          "name": "Huai Pong",
          "postCode": [
            "67220"
          ]
        },
        {
          "id": 670701,
          "name": "Kong Thun",
          "postCode": [
            "67140"
          ]
        },
        {
          "id": 670702,
          "name": "Na Chaliang",
          "postCode": [
            "67220"
          ]
        },
        {
          "id": 670710,
          "name": "Nong Phai",
          "postCode": [
            "67140"
          ]
        },
        {
          "id": 670705,
          "name": "Phet Lakhon",
          "postCode": [
            "67140"
          ]
        },
        {
          "id": 670704,
          "name": "Tha Daeng",
          "postCode": [
            "67140"
          ]
        },
        {
          "id": 670713,
          "name": "Tha Duang",
          "postCode": [
            "67140"
          ]
        },
        {
          "id": 670711,
          "name": "Wang Bot",
          "postCode": [
            "67140"
          ]
        },
        {
          "id": 670708,
          "name": "Wang Tha Di",
          "postCode": [
            "67140"
          ]
        },
        {
          "id": 670712,
          "name": "Yang Ngam",
          "postCode": [
            "67220"
          ]
        }
      ]
    },
    {
      "city": "Si Thep",
      "cityCode": 6706,
      "provinceCode": 67,
      "subDistrict": [
        {
          "id": 670603,
          "name": "Khlong Krachang",
          "postCode": [
            "67170"
          ]
        },
        {
          "id": 670605,
          "name": "Khok Sa-at",
          "postCode": [
            "67170"
          ]
        },
        {
          "id": 670604,
          "name": "Na Sanun",
          "postCode": [
            "67170"
          ]
        },
        {
          "id": 670606,
          "name": "Nong Yang Thoi",
          "postCode": [
            "67170"
          ]
        },
        {
          "id": 670607,
          "name": "Pradu Ngam",
          "postCode": [
            "67170"
          ]
        },
        {
          "id": 670602,
          "name": "Sa Kruat",
          "postCode": [
            "67170"
          ]
        },
        {
          "id": 670601,
          "name": "Si Thep",
          "postCode": [
            "67170"
          ]
        }
      ]
    },
    {
      "city": "Wang Pong",
      "cityCode": 6710,
      "provinceCode": 67,
      "subDistrict": [
        {
          "id": 671003,
          "name": "Sap Poep",
          "postCode": [
            "67240"
          ]
        },
        {
          "id": 671002,
          "name": "Thai Dong",
          "postCode": [
            "67240"
          ]
        },
        {
          "id": 671004,
          "name": "Wang Hin",
          "postCode": [
            "67240"
          ]
        },
        {
          "id": 671001,
          "name": "Wang Pong",
          "postCode": [
            "67240"
          ]
        },
        {
          "id": 671005,
          "name": "Wang San",
          "postCode": [
            "67240"
          ]
        }
      ]
    },
    {
      "city": "Wichian Buri",
      "cityCode": 6705,
      "provinceCode": 67,
      "subDistrict": [
        {
          "id": 670506,
          "name": "Bo Rang",
          "postCode": [
            "67130"
          ]
        },
        {
          "id": 670511,
          "name": "Bueng Krachap",
          "postCode": [
            "67130"
          ]
        },
        {
          "id": 670504,
          "name": "Khok Prong",
          "postCode": [
            "67130"
          ]
        },
        {
          "id": 670505,
          "name": "Nam Ron",
          "postCode": [
            "67130"
          ]
        },
        {
          "id": 670508,
          "name": "Phu Kham",
          "postCode": [
            "67180"
          ]
        },
        {
          "id": 670509,
          "name": "Phu Nam Yot",
          "postCode": [
            "67180"
          ]
        },
        {
          "id": 670507,
          "name": "Phu Toei",
          "postCode": [
            "67180"
          ]
        },
        {
          "id": 670502,
          "name": "Sa Pradu",
          "postCode": [
            "67130"
          ]
        },
        {
          "id": 670503,
          "name": "Sam Yaek",
          "postCode": [
            "67130"
          ]
        },
        {
          "id": 670514,
          "name": "Sap Noi",
          "postCode": [
            "67180"
          ]
        },
        {
          "id": 670510,
          "name": "Sap Sombun",
          "postCode": [
            "67180"
          ]
        },
        {
          "id": 670501,
          "name": "Tha Rong",
          "postCode": [
            "67130"
          ]
        },
        {
          "id": 670512,
          "name": "Wang Yai",
          "postCode": [
            "67180"
          ]
        },
        {
          "id": 670513,
          "name": "Yang Sao",
          "postCode": [
            "67130"
          ]
        }
      ]
    },
    {
      "city": "Ban Laem",
      "cityCode": 7607,
      "provinceCode": 76,
      "subDistrict": [
        {
          "id": 760701,
          "name": "Ban Laem",
          "postCode": [
            "76110"
          ]
        },
        {
          "id": 760704,
          "name": "Bang Kaeo",
          "postCode": [
            "76110"
          ]
        },
        {
          "id": 760708,
          "name": "Bang Khrok",
          "postCode": [
            "76110"
          ]
        },
        {
          "id": 760702,
          "name": "Bang Khun Sai",
          "postCode": [
            "76110"
          ]
        },
        {
          "id": 760706,
          "name": "Bang Tabun",
          "postCode": [
            "76110"
          ]
        },
        {
          "id": 760707,
          "name": "Bang Tabun Ok",
          "postCode": [
            "76110"
          ]
        },
        {
          "id": 760705,
          "name": "Laem Phak Bia",
          "postCode": [
            "76100"
          ]
        },
        {
          "id": 760703,
          "name": "Pak Thale",
          "postCode": [
            "76110"
          ]
        },
        {
          "id": 760709,
          "name": "Tha Raeng",
          "postCode": [
            "76110"
          ]
        },
        {
          "id": 760710,
          "name": "Tha Raeng Ok",
          "postCode": [
            "76110"
          ]
        }
      ]
    },
    {
      "city": "Ban Lat",
      "cityCode": 7606,
      "provinceCode": 76,
      "subDistrict": [
        {
          "id": 760602,
          "name": "Ban Hat",
          "postCode": [
            "76150"
          ]
        },
        {
          "id": 760601,
          "name": "Ban Lat",
          "postCode": [
            "76150"
          ]
        },
        {
          "id": 760603,
          "name": "Ban Than",
          "postCode": [
            "76150"
          ]
        },
        {
          "id": 760615,
          "name": "Huai Khong",
          "postCode": [
            "76150"
          ]
        },
        {
          "id": 760618,
          "name": "Huai Luek",
          "postCode": [
            "76150"
          ]
        },
        {
          "id": 760610,
          "name": "Lat Pho",
          "postCode": [
            "76150"
          ]
        },
        {
          "id": 760609,
          "name": "Nong Kapu",
          "postCode": [
            "76150"
          ]
        },
        {
          "id": 760608,
          "name": "Nong Krachet",
          "postCode": [
            "76150"
          ]
        },
        {
          "id": 760612,
          "name": "Rai Khok",
          "postCode": [
            "76150"
          ]
        },
        {
          "id": 760606,
          "name": "Rai Makham",
          "postCode": [
            "76150"
          ]
        },
        {
          "id": 760614,
          "name": "Rai Sathon",
          "postCode": [
            "76150"
          ]
        },
        {
          "id": 760613,
          "name": "Rong Khe",
          "postCode": [
            "76150"
          ]
        },
        {
          "id": 760605,
          "name": "Samo Phlue",
          "postCode": [
            "76150"
          ]
        },
        {
          "id": 760611,
          "name": "Saphan Krai",
          "postCode": [
            "76150"
          ]
        },
        {
          "id": 760604,
          "name": "Tamru",
          "postCode": [
            "76150"
          ]
        },
        {
          "id": 760616,
          "name": "Tha Chang",
          "postCode": [
            "76150"
          ]
        },
        {
          "id": 760607,
          "name": "Tha Sen",
          "postCode": [
            "76150"
          ]
        },
        {
          "id": 760617,
          "name": "Tham Rong",
          "postCode": [
            "76150"
          ]
        }
      ]
    },
    {
      "city": "Cha-am",
      "cityCode": 7604,
      "provinceCode": 76,
      "subDistrict": [
        {
          "id": 760402,
          "name": "Bang Kao",
          "postCode": [
            "76120"
          ]
        },
        {
          "id": 760401,
          "name": "Cha-am",
          "postCode": [
            "76120"
          ]
        },
        {
          "id": 760409,
          "name": "Don Khun Huai",
          "postCode": [
            "76120"
          ]
        },
        {
          "id": 760406,
          "name": "Huai Sai Nuea",
          "postCode": [
            "76120"
          ]
        },
        {
          "id": 760404,
          "name": "Khao Yai",
          "postCode": [
            "76120"
          ]
        },
        {
          "id": 760403,
          "name": "Na Yang",
          "postCode": [
            "76120"
          ]
        },
        {
          "id": 760405,
          "name": "Nong Sala",
          "postCode": [
            "76120"
          ]
        },
        {
          "id": 760407,
          "name": "Rai Mai Phatthana",
          "postCode": [
            "76120"
          ]
        },
        {
          "id": 760408,
          "name": "Sam Phraya",
          "postCode": [
            "76120"
          ]
        }
      ]
    },
    {
      "city": "Kaeng Krachan",
      "cityCode": 7608,
      "provinceCode": 76,
      "subDistrict": [
        {
          "id": 760806,
          "name": "Huai Mae Phriang",
          "postCode": [
            "76170"
          ]
        },
        {
          "id": 760801,
          "name": "Kaeng Krachan",
          "postCode": [
            "76170"
          ]
        },
        {
          "id": 760804,
          "name": "Pa Deng",
          "postCode": [
            "76170"
          ]
        },
        {
          "id": 760805,
          "name": "Phu Sawan",
          "postCode": [
            "76170"
          ]
        },
        {
          "id": 760802,
          "name": "Song Phi Nong",
          "postCode": [
            "76170"
          ]
        },
        {
          "id": 760803,
          "name": "Wang Chan",
          "postCode": [
            "76170"
          ]
        }
      ]
    },
    {
      "city": "Khao Yoi",
      "cityCode": 7602,
      "provinceCode": 76,
      "subDistrict": [
        {
          "id": 760203,
          "name": "Bang Khem",
          "postCode": [
            "76140"
          ]
        },
        {
          "id": 760208,
          "name": "Huai Rong",
          "postCode": [
            "76140"
          ]
        },
        {
          "id": 760209,
          "name": "Huai Tha Chang",
          "postCode": [
            "76140"
          ]
        },
        {
          "id": 760201,
          "name": "Khao Yoi",
          "postCode": [
            "76140"
          ]
        },
        {
          "id": 760207,
          "name": "Nong Chumphon",
          "postCode": [
            "76140"
          ]
        },
        {
          "id": 760210,
          "name": "Nong Chumphon Nuea",
          "postCode": [
            "76140"
          ]
        },
        {
          "id": 760205,
          "name": "Nong Pla Lai",
          "postCode": [
            "76140"
          ]
        },
        {
          "id": 760206,
          "name": "Nong Prong",
          "postCode": [
            "76140"
          ]
        },
        {
          "id": 760202,
          "name": "Sa Phang",
          "postCode": [
            "76140"
          ]
        },
        {
          "id": 760204,
          "name": "Thap Khang",
          "postCode": [
            "76140"
          ]
        }
      ]
    },
    {
      "city": "Mueang Phetchaburi",
      "cityCode": 7601,
      "provinceCode": 76,
      "subDistrict": [
        {
          "id": 760106,
          "name": "Ban Kum",
          "postCode": [
            "76000"
          ]
        },
        {
          "id": 760111,
          "name": "Ban Mo",
          "postCode": [
            "76000"
          ]
        },
        {
          "id": 760110,
          "name": "Bang Chak",
          "postCode": [
            "76000"
          ]
        },
        {
          "id": 760103,
          "name": "Bang Chan",
          "postCode": [
            "76000"
          ]
        },
        {
          "id": 760113,
          "name": "Chong Sakae",
          "postCode": [
            "76000"
          ]
        },
        {
          "id": 760122,
          "name": "Don Yang",
          "postCode": [
            "76000"
          ]
        },
        {
          "id": 760117,
          "name": "Hat Chao Samran",
          "postCode": [
            "76100"
          ]
        },
        {
          "id": 760118,
          "name": "Hua Saphan",
          "postCode": [
            "76000"
          ]
        },
        {
          "id": 760102,
          "name": "Khlong Krachaeng",
          "postCode": [
            "76000"
          ]
        },
        {
          "id": 760104,
          "name": "Na Phan Sam",
          "postCode": [
            "76000"
          ]
        },
        {
          "id": 760114,
          "name": "Na Wung",
          "postCode": [
            "76000"
          ]
        },
        {
          "id": 760123,
          "name": "Nong Khanan",
          "postCode": [
            "76000",
            "76100"
          ]
        },
        {
          "id": 760124,
          "name": "Nong Phlap",
          "postCode": [
            "76000"
          ]
        },
        {
          "id": 760107,
          "name": "Nong Sano",
          "postCode": [
            "76000"
          ]
        },
        {
          "id": 760116,
          "name": "Pho Phra",
          "postCode": [
            "76000"
          ]
        },
        {
          "id": 760121,
          "name": "Pho Rai Wan",
          "postCode": [
            "76000"
          ]
        },
        {
          "id": 760108,
          "name": "Rai Som",
          "postCode": [
            "76000"
          ]
        },
        {
          "id": 760115,
          "name": "Sam Marong",
          "postCode": [
            "76000"
          ]
        },
        {
          "id": 760101,
          "name": "Tha Rap",
          "postCode": [
            "76000"
          ]
        },
        {
          "id": 760105,
          "name": "Thong Chai",
          "postCode": [
            "76000"
          ]
        },
        {
          "id": 760112,
          "name": "Ton Mamuang",
          "postCode": [
            "76000"
          ]
        },
        {
          "id": 760119,
          "name": "Ton Maphrao",
          "postCode": [
            "76000"
          ]
        },
        {
          "id": 760120,
          "name": "Wang Tako",
          "postCode": [
            "76000"
          ]
        },
        {
          "id": 760109,
          "name": "Wiang Khoi",
          "postCode": [
            "76000"
          ]
        }
      ]
    },
    {
      "city": "Nong Ya Plong",
      "cityCode": 7603,
      "provinceCode": 76,
      "subDistrict": [
        {
          "id": 760301,
          "name": "Nong Ya Plong",
          "postCode": [
            "76160"
          ]
        },
        {
          "id": 760304,
          "name": "Tha Ta Khro",
          "postCode": [
            "76160"
          ]
        },
        {
          "id": 760302,
          "name": "Yang Nam Klat Nuea",
          "postCode": [
            "76160"
          ]
        },
        {
          "id": 760303,
          "name": "Yang Nam Klat Tai",
          "postCode": [
            "76160"
          ]
        }
      ]
    },
    {
      "city": "Tha Yang",
      "cityCode": 7605,
      "provinceCode": 76,
      "subDistrict": [
        {
          "id": 760515,
          "name": "Ban Nai Dong",
          "postCode": [
            "76130"
          ]
        },
        {
          "id": 760513,
          "name": "Khao Krapuk",
          "postCode": [
            "76130"
          ]
        },
        {
          "id": 760511,
          "name": "Klat Luang",
          "postCode": [
            "76130"
          ]
        },
        {
          "id": 760505,
          "name": "Map Pla Khao",
          "postCode": [
            "76130"
          ]
        },
        {
          "id": 760504,
          "name": "Nong Chok",
          "postCode": [
            "76130"
          ]
        },
        {
          "id": 760512,
          "name": "Puek Tian",
          "postCode": [
            "76130"
          ]
        },
        {
          "id": 760502,
          "name": "Tha Khoi",
          "postCode": [
            "76130"
          ]
        },
        {
          "id": 760514,
          "name": "Tha Laeng",
          "postCode": [
            "76130"
          ]
        },
        {
          "id": 760506,
          "name": "Tha Mai Ruak",
          "postCode": [
            "76130"
          ]
        },
        {
          "id": 760501,
          "name": "Tha Yang",
          "postCode": [
            "76130"
          ]
        },
        {
          "id": 760507,
          "name": "Wang Khrai",
          "postCode": [
            "76130"
          ]
        },
        {
          "id": 760503,
          "name": "Yang Yong",
          "postCode": [
            "76130"
          ]
        }
      ]
    },
    {
      "city": "Bang Mun Nak",
      "cityCode": 6605,
      "provinceCode": 66,
      "subDistrict": [
        {
          "id": 660501,
          "name": "Bang Mun Nak",
          "postCode": [
            "66120"
          ]
        },
        {
          "id": 660502,
          "name": "Bang Phai",
          "postCode": [
            "66120"
          ]
        },
        {
          "id": 660503,
          "name": "Ho Krai",
          "postCode": [
            "66120"
          ]
        },
        {
          "id": 660508,
          "name": "Huai Khen",
          "postCode": [
            "66120"
          ]
        },
        {
          "id": 660514,
          "name": "Lam Prada",
          "postCode": [
            "66120"
          ]
        },
        {
          "id": 660504,
          "name": "Noen Makok",
          "postCode": [
            "66120"
          ]
        },
        {
          "id": 660506,
          "name": "Phum",
          "postCode": [
            "66120"
          ]
        },
        {
          "id": 660507,
          "name": "Wang Krot",
          "postCode": [
            "66120"
          ]
        },
        {
          "id": 660505,
          "name": "Wang Samrong",
          "postCode": [
            "66120"
          ]
        },
        {
          "id": 660509,
          "name": "Wang Taku",
          "postCode": [
            "66210"
          ]
        }
      ]
    },
    {
      "city": "Bueng Na Rang",
      "cityCode": 6610,
      "provinceCode": 66,
      "subDistrict": [
        {
          "id": 661004,
          "name": "Bang Lai",
          "postCode": [
            "66130"
          ]
        },
        {
          "id": 661005,
          "name": "Bueng Na Rang",
          "postCode": [
            "66130"
          ]
        },
        {
          "id": 661001,
          "name": "Huai Kaeo",
          "postCode": [
            "66130"
          ]
        },
        {
          "id": 661003,
          "name": "Laem Rang",
          "postCode": [
            "66130"
          ]
        },
        {
          "id": 661002,
          "name": "Pho Sai Ngam",
          "postCode": [
            "66130"
          ]
        }
      ]
    },
    {
      "city": "Dong Charoen",
      "cityCode": 6611,
      "provinceCode": 66,
      "subDistrict": [
        {
          "id": 661104,
          "name": "Huai Phuk",
          "postCode": [
            "66210"
          ]
        },
        {
          "id": 661103,
          "name": "Huai Ruam",
          "postCode": [
            "66210"
          ]
        },
        {
          "id": 661105,
          "name": "Samnak Khun Nen",
          "postCode": [
            "66210"
          ]
        },
        {
          "id": 661102,
          "name": "Wang Ngio",
          "postCode": [
            "66210"
          ]
        },
        {
          "id": 661101,
          "name": "Wang Ngio Tai",
          "postCode": [
            "66210"
          ]
        }
      ]
    },
    {
      "city": "Mueang Phichit",
      "cityCode": 6601,
      "provinceCode": 66,
      "subDistrict": [
        {
          "id": 660110,
          "name": "Ban Bung",
          "postCode": [
            "66000"
          ]
        },
        {
          "id": 660120,
          "name": "Dong Klang",
          "postCode": [
            "66170"
          ]
        },
        {
          "id": 660112,
          "name": "Dong Pa Kham",
          "postCode": [
            "66170"
          ]
        },
        {
          "id": 660113,
          "name": "Hua Dong",
          "postCode": [
            "66170"
          ]
        },
        {
          "id": 660111,
          "name": "Kha Mung",
          "postCode": [
            "66000",
            "66170"
          ]
        },
        {
          "id": 660106,
          "name": "Khlong Khachen",
          "postCode": [
            "66000"
          ]
        },
        {
          "id": 660108,
          "name": "Mueang Kao",
          "postCode": [
            "66000"
          ]
        },
        {
          "id": 660101,
          "name": "Nai Mueang",
          "postCode": [
            "66000"
          ]
        },
        {
          "id": 660115,
          "name": "Pa Makhap",
          "postCode": [
            "66000"
          ]
        },
        {
          "id": 660105,
          "name": "Pak Thang",
          "postCode": [
            "66000"
          ]
        },
        {
          "id": 660102,
          "name": "Phai Khwang",
          "postCode": [
            "66000"
          ]
        },
        {
          "id": 660107,
          "name": "Rong Chang",
          "postCode": [
            "66000"
          ]
        },
        {
          "id": 660119,
          "name": "Sai Kham Ho",
          "postCode": [
            "66000"
          ]
        },
        {
          "id": 660104,
          "name": "Tha Lo",
          "postCode": [
            "66000"
          ]
        },
        {
          "id": 660109,
          "name": "Tha Luang",
          "postCode": [
            "66000"
          ]
        },
        {
          "id": 660103,
          "name": "Yan Yao",
          "postCode": [
            "66000"
          ]
        }
      ]
    },
    {
      "city": "Pho Prathap Chang",
      "cityCode": 6603,
      "provinceCode": 66,
      "subDistrict": [
        {
          "id": 660305,
          "name": "Dong Suea Lueang",
          "postCode": [
            "66190"
          ]
        },
        {
          "id": 660306,
          "name": "Noen Sawang",
          "postCode": [
            "66190"
          ]
        },
        {
          "id": 660304,
          "name": "Phai Rop",
          "postCode": [
            "66190"
          ]
        },
        {
          "id": 660302,
          "name": "Phai Tha Pho",
          "postCode": [
            "66190"
          ]
        },
        {
          "id": 660301,
          "name": "Pho Prathap Chang",
          "postCode": [
            "66190"
          ]
        },
        {
          "id": 660307,
          "name": "Thung Yai",
          "postCode": [
            "66190"
          ]
        },
        {
          "id": 660303,
          "name": "Wang Chik",
          "postCode": [
            "66190"
          ]
        }
      ]
    },
    {
      "city": "Pho Thale",
      "cityCode": 6606,
      "provinceCode": 66,
      "subDistrict": [
        {
          "id": 660612,
          "name": "Ban Noi",
          "postCode": [
            "66130"
          ]
        },
        {
          "id": 660608,
          "name": "Bang Khlan",
          "postCode": [
            "66130"
          ]
        },
        {
          "id": 660601,
          "name": "Pho Thale",
          "postCode": [
            "66130"
          ]
        },
        {
          "id": 660604,
          "name": "Tha Bua",
          "postCode": [
            "66130"
          ]
        },
        {
          "id": 660606,
          "name": "Tha Khamin",
          "postCode": [
            "66130"
          ]
        },
        {
          "id": 660611,
          "name": "Tha Nang",
          "postCode": [
            "66130"
          ]
        },
        {
          "id": 660607,
          "name": "Tha Sao",
          "postCode": [
            "66130"
          ]
        },
        {
          "id": 660602,
          "name": "Thai Nam",
          "postCode": [
            "66130"
          ]
        },
        {
          "id": 660603,
          "name": "Thanong",
          "postCode": [
            "66130"
          ]
        },
        {
          "id": 660605,
          "name": "Thung Noi",
          "postCode": [
            "66130"
          ]
        },
        {
          "id": 660613,
          "name": "Wat Khwang",
          "postCode": [
            "66130"
          ]
        }
      ]
    },
    {
      "city": "Sak Lek",
      "cityCode": 6609,
      "provinceCode": 66,
      "subDistrict": [
        {
          "id": 660903,
          "name": "Khlong Sai",
          "postCode": [
            "66160"
          ]
        },
        {
          "id": 660904,
          "name": "Nong Ya Sai",
          "postCode": [
            "66160"
          ]
        },
        {
          "id": 660901,
          "name": "Sak Lek",
          "postCode": [
            "66160"
          ]
        },
        {
          "id": 660902,
          "name": "Tha Yiam",
          "postCode": [
            "66160"
          ]
        },
        {
          "id": 660905,
          "name": "Wang Thap Sai",
          "postCode": [
            "66160"
          ]
        }
      ]
    },
    {
      "city": "Sam Ngam",
      "cityCode": 6607,
      "provinceCode": 66,
      "subDistrict": [
        {
          "id": 660702,
          "name": "Kamphaeng Din",
          "postCode": [
            "66220"
          ]
        },
        {
          "id": 660706,
          "name": "Noen Po",
          "postCode": [
            "66140"
          ]
        },
        {
          "id": 660707,
          "name": "Nong Sano",
          "postCode": [
            "66140"
          ]
        },
        {
          "id": 660703,
          "name": "Rang Nok",
          "postCode": [
            "66140"
          ]
        },
        {
          "id": 660701,
          "name": "Sam Ngam",
          "postCode": [
            "66140"
          ]
        }
      ]
    },
    {
      "city": "Taphan Hin",
      "cityCode": 6604,
      "provinceCode": 66,
      "subDistrict": [
        {
          "id": 660407,
          "name": "Dong Takhop",
          "postCode": [
            "66110"
          ]
        },
        {
          "id": 660403,
          "name": "Huai Ket",
          "postCode": [
            "66110"
          ]
        },
        {
          "id": 660408,
          "name": "Khlong Khun",
          "postCode": [
            "66110"
          ]
        },
        {
          "id": 660402,
          "name": "Ngio Rai",
          "postCode": [
            "66110"
          ]
        },
        {
          "id": 660405,
          "name": "Nong Phayom",
          "postCode": [
            "66110"
          ]
        },
        {
          "id": 660413,
          "name": "Phai Luang",
          "postCode": [
            "66110"
          ]
        },
        {
          "id": 660404,
          "name": "Sai Rong Khon",
          "postCode": [
            "66110"
          ]
        },
        {
          "id": 660401,
          "name": "Taphan Hin",
          "postCode": [
            "66110"
          ]
        },
        {
          "id": 660412,
          "name": "Thap Man",
          "postCode": [
            "66110"
          ]
        },
        {
          "id": 660406,
          "name": "Thung Pho",
          "postCode": [
            "66150"
          ]
        },
        {
          "id": 660411,
          "name": "Wang Lum",
          "postCode": [
            "66150"
          ]
        },
        {
          "id": 660409,
          "name": "Wang Samrong",
          "postCode": [
            "66110"
          ]
        },
        {
          "id": 660410,
          "name": "Wang Wa",
          "postCode": [
            "66110"
          ]
        }
      ]
    },
    {
      "city": "Thap Khlo",
      "cityCode": 6608,
      "provinceCode": 66,
      "subDistrict": [
        {
          "id": 660803,
          "name": "Khao Chet Luk",
          "postCode": [
            "66230"
          ]
        },
        {
          "id": 660802,
          "name": "Khao Sai",
          "postCode": [
            "66230"
          ]
        },
        {
          "id": 660804,
          "name": "Thai Thung",
          "postCode": [
            "66150"
          ]
        },
        {
          "id": 660801,
          "name": "Thap Khlo",
          "postCode": [
            "66150"
          ]
        }
      ]
    },
    {
      "city": "Wachirabarami",
      "cityCode": 6612,
      "provinceCode": 66,
      "subDistrict": [
        {
          "id": 661201,
          "name": "Ban Na",
          "postCode": [
            "66140"
          ]
        },
        {
          "id": 661202,
          "name": "Bueng Bua",
          "postCode": [
            "66140"
          ]
        },
        {
          "id": 661204,
          "name": "Nong Lum",
          "postCode": [
            "66220"
          ]
        },
        {
          "id": 661203,
          "name": "Wang Mok",
          "postCode": [
            "66140"
          ]
        }
      ]
    },
    {
      "city": "Wang Sai Phun",
      "cityCode": 6602,
      "provinceCode": 66,
      "subDistrict": [
        {
          "id": 660203,
          "name": "Nong Phra",
          "postCode": [
            "66180"
          ]
        },
        {
          "id": 660202,
          "name": "Nong Pla Lai",
          "postCode": [
            "66180"
          ]
        },
        {
          "id": 660204,
          "name": "Nong Plong",
          "postCode": [
            "66180"
          ]
        },
        {
          "id": 660201,
          "name": "Wang Sai Phun",
          "postCode": [
            "66180"
          ]
        }
      ]
    },
    {
      "city": "Bang Krathum",
      "cityCode": 6505,
      "provinceCode": 65,
      "subDistrict": [
        {
          "id": 650502,
          "name": "Ban Rai",
          "postCode": [
            "65110"
          ]
        },
        {
          "id": 650501,
          "name": "Bang Krathum",
          "postCode": [
            "65110"
          ]
        },
        {
          "id": 650503,
          "name": "Khok Salut",
          "postCode": [
            "65110"
          ]
        },
        {
          "id": 650507,
          "name": "Nakhon Pa Mak",
          "postCode": [
            "65110"
          ]
        },
        {
          "id": 650508,
          "name": "Noen Kum",
          "postCode": [
            "65210"
          ]
        },
        {
          "id": 650506,
          "name": "Phai Lom",
          "postCode": [
            "65110"
          ]
        },
        {
          "id": 650504,
          "name": "Sanam Khli",
          "postCode": [
            "65110"
          ]
        },
        {
          "id": 650505,
          "name": "Tha Tan",
          "postCode": [
            "65110"
          ]
        },
        {
          "id": 650509,
          "name": "Wat Ta Yom",
          "postCode": [
            "65210"
          ]
        }
      ]
    },
    {
      "city": "Bang Rakam",
      "cityCode": 6504,
      "provinceCode": 65,
      "subDistrict": [
        {
          "id": 650401,
          "name": "Bang Rakam",
          "postCode": [
            "65140"
          ]
        },
        {
          "id": 650409,
          "name": "Bo Thong",
          "postCode": [
            "65140"
          ]
        },
        {
          "id": 650405,
          "name": "Bueng Kok",
          "postCode": [
            "65140"
          ]
        },
        {
          "id": 650407,
          "name": "Chum Saeng Songkhram",
          "postCode": [
            "65240"
          ]
        },
        {
          "id": 650411,
          "name": "Khui Muang",
          "postCode": [
            "65240"
          ]
        },
        {
          "id": 650408,
          "name": "Nikhom Phatthana",
          "postCode": [
            "65140"
          ]
        },
        {
          "id": 650406,
          "name": "Nong Kula",
          "postCode": [
            "65140"
          ]
        },
        {
          "id": 650403,
          "name": "Phan Sao",
          "postCode": [
            "65140"
          ]
        },
        {
          "id": 650402,
          "name": "Plak Raet",
          "postCode": [
            "65140"
          ]
        },
        {
          "id": 650410,
          "name": "Tha Nang Ngam",
          "postCode": [
            "65140"
          ]
        },
        {
          "id": 650404,
          "name": "Wang Ithok",
          "postCode": [
            "65140"
          ]
        }
      ]
    },
    {
      "city": "Chat Trakan",
      "cityCode": 6503,
      "provinceCode": 65,
      "subDistrict": [
        {
          "id": 650304,
          "name": "Ban Dong",
          "postCode": [
            "65170"
          ]
        },
        {
          "id": 650305,
          "name": "Bo Phak",
          "postCode": [
            "65170"
          ]
        },
        {
          "id": 650302,
          "name": "Chat Trakan",
          "postCode": [
            "65170"
          ]
        },
        {
          "id": 650301,
          "name": "Pa Daeng",
          "postCode": [
            "65170"
          ]
        },
        {
          "id": 650303,
          "name": "Suan Miang",
          "postCode": [
            "65170"
          ]
        },
        {
          "id": 650306,
          "name": "Tha Sakae",
          "postCode": [
            "65170"
          ]
        }
      ]
    },
    {
      "city": "Mueang Phitsanulok",
      "cityCode": 6501,
      "provinceCode": 65,
      "subDistrict": [
        {
          "id": 650117,
          "name": "Aranyik",
          "postCode": [
            "65000"
          ]
        },
        {
          "id": 650114,
          "name": "Ban Khlong",
          "postCode": [
            "65000"
          ]
        },
        {
          "id": 650113,
          "name": "Ban Krang",
          "postCode": [
            "65000"
          ]
        },
        {
          "id": 650109,
          "name": "Ban Pa",
          "postCode": [
            "65000"
          ]
        },
        {
          "id": 650118,
          "name": "Bueng Phra",
          "postCode": [
            "65000"
          ]
        },
        {
          "id": 650112,
          "name": "Chom Thong",
          "postCode": [
            "65000"
          ]
        },
        {
          "id": 650108,
          "name": "Don Thong",
          "postCode": [
            "65000"
          ]
        },
        {
          "id": 650111,
          "name": "Hua Ro",
          "postCode": [
            "65000"
          ]
        },
        {
          "id": 650116,
          "name": "Makham Sung",
          "postCode": [
            "65000"
          ]
        },
        {
          "id": 650101,
          "name": "Nai Mueang",
          "postCode": [
            "65000"
          ]
        },
        {
          "id": 650120,
          "name": "Ngio Ngam",
          "postCode": [
            "65230"
          ]
        },
        {
          "id": 650110,
          "name": "Pak Thok",
          "postCode": [
            "65000"
          ]
        },
        {
          "id": 650119,
          "name": "Phai Kho Don",
          "postCode": [
            "65000"
          ]
        },
        {
          "id": 650115,
          "name": "Phlai Chumphon",
          "postCode": [
            "65000"
          ]
        },
        {
          "id": 650107,
          "name": "Samo Khae",
          "postCode": [
            "65000"
          ]
        },
        {
          "id": 650106,
          "name": "Tha Pho",
          "postCode": [
            "65000"
          ]
        },
        {
          "id": 650105,
          "name": "Tha Thong",
          "postCode": [
            "65000"
          ]
        },
        {
          "id": 650102,
          "name": "Wang Nam Khu",
          "postCode": [
            "65230"
          ]
        },
        {
          "id": 650103,
          "name": "Wat Chan",
          "postCode": [
            "65000"
          ]
        },
        {
          "id": 650104,
          "name": "Wat Phrik",
          "postCode": [
            "65230"
          ]
        }
      ]
    },
    {
      "city": "Nakhon Thai",
      "cityCode": 6502,
      "provinceCode": 65,
      "subDistrict": [
        {
          "id": 650210,
          "name": "Ban Phrao",
          "postCode": [
            "65120"
          ]
        },
        {
          "id": 650203,
          "name": "Ban Yaeng",
          "postCode": [
            "65120"
          ]
        },
        {
          "id": 650209,
          "name": "Bo Pho",
          "postCode": [
            "65120"
          ]
        },
        {
          "id": 650211,
          "name": "Huai Hia",
          "postCode": [
            "65120"
          ]
        },
        {
          "id": 650205,
          "name": "Na Bua",
          "postCode": [
            "65120"
          ]
        },
        {
          "id": 650206,
          "name": "Nakhon Chum",
          "postCode": [
            "65120"
          ]
        },
        {
          "id": 650201,
          "name": "Nakhon Thai",
          "postCode": [
            "65120"
          ]
        },
        {
          "id": 650207,
          "name": "Nam Kum",
          "postCode": [
            "65120"
          ]
        },
        {
          "id": 650204,
          "name": "Noen Phoem",
          "postCode": [
            "65120"
          ]
        },
        {
          "id": 650202,
          "name": "Nong Kathao",
          "postCode": [
            "65120"
          ]
        },
        {
          "id": 650208,
          "name": "Yang Klon",
          "postCode": [
            "65120"
          ]
        }
      ]
    },
    {
      "city": "Noen Maprang",
      "cityCode": 6509,
      "provinceCode": 65,
      "subDistrict": [
        {
          "id": 650902,
          "name": "Ban Mung",
          "postCode": [
            "65190"
          ]
        },
        {
          "id": 650905,
          "name": "Ban Noi Sum Khilek",
          "postCode": [
            "65190"
          ]
        },
        {
          "id": 650901,
          "name": "Chomphu",
          "postCode": [
            "65190"
          ]
        },
        {
          "id": 650906,
          "name": "Noen Maprang",
          "postCode": [
            "65190"
          ]
        },
        {
          "id": 650903,
          "name": "Sai Yoi",
          "postCode": [
            "65190"
          ]
        },
        {
          "id": 650904,
          "name": "Wang Phrong",
          "postCode": [
            "65190"
          ]
        },
        {
          "id": 650907,
          "name": "Wang Yang",
          "postCode": [
            "65190"
          ]
        }
      ]
    },
    {
      "city": "Phrom Phiram",
      "cityCode": 6506,
      "provinceCode": 65,
      "subDistrict": [
        {
          "id": 650612,
          "name": "Dong Prakham",
          "postCode": [
            "65180"
          ]
        },
        {
          "id": 650605,
          "name": "Ho Klong",
          "postCode": [
            "65150"
          ]
        },
        {
          "id": 650610,
          "name": "Matong",
          "postCode": [
            "65180"
          ]
        },
        {
          "id": 650604,
          "name": "Matum",
          "postCode": [
            "65150"
          ]
        },
        {
          "id": 650609,
          "name": "Nong Khaem",
          "postCode": [
            "65150"
          ]
        },
        {
          "id": 650601,
          "name": "Phrom Phiram",
          "postCode": [
            "65150"
          ]
        },
        {
          "id": 650606,
          "name": "Si Phirom",
          "postCode": [
            "65180"
          ]
        },
        {
          "id": 650607,
          "name": "Taluk Thiam",
          "postCode": [
            "65180"
          ]
        },
        {
          "id": 650602,
          "name": "Tha Chang",
          "postCode": [
            "65150"
          ]
        },
        {
          "id": 650611,
          "name": "Thap Yai Chiang",
          "postCode": [
            "65150"
          ]
        },
        {
          "id": 650608,
          "name": "Wang Won",
          "postCode": [
            "65150"
          ]
        },
        {
          "id": 650603,
          "name": "Wong Khong",
          "postCode": [
            "65180"
          ]
        }
      ]
    },
    {
      "city": "Wang Thong",
      "cityCode": 6508,
      "provinceCode": 65,
      "subDistrict": [
        {
          "id": 650804,
          "name": "Ban Klang",
          "postCode": [
            "65220"
          ]
        },
        {
          "id": 650810,
          "name": "Chai Nam",
          "postCode": [
            "65130"
          ]
        },
        {
          "id": 650811,
          "name": "Din Thong",
          "postCode": [
            "65130"
          ]
        },
        {
          "id": 650806,
          "name": "Kaeng Sopha",
          "postCode": [
            "65220"
          ]
        },
        {
          "id": 650803,
          "name": "Mae Raka",
          "postCode": [
            "65130"
          ]
        },
        {
          "id": 650809,
          "name": "Nong Phra",
          "postCode": [
            "65130"
          ]
        },
        {
          "id": 650802,
          "name": "Phan Chali",
          "postCode": [
            "65130"
          ]
        },
        {
          "id": 650807,
          "name": "Tha Muen Ram",
          "postCode": [
            "65130"
          ]
        },
        {
          "id": 650808,
          "name": "Wang Nok Aen",
          "postCode": [
            "65130"
          ]
        },
        {
          "id": 650805,
          "name": "Wang Phikun",
          "postCode": [
            "65130"
          ]
        },
        {
          "id": 650801,
          "name": "Wang Thong",
          "postCode": [
            "65130"
          ]
        }
      ]
    },
    {
      "city": "Wat Bot",
      "cityCode": 6507,
      "provinceCode": 65,
      "subDistrict": [
        {
          "id": 650704,
          "name": "Ban Yang",
          "postCode": [
            "65160"
          ]
        },
        {
          "id": 650705,
          "name": "Hin Lat",
          "postCode": [
            "65160"
          ]
        },
        {
          "id": 650706,
          "name": "Khan Chong",
          "postCode": [
            "65160"
          ]
        },
        {
          "id": 650702,
          "name": "Tha Ngam",
          "postCode": [
            "65160"
          ]
        },
        {
          "id": 650703,
          "name": "Thothae",
          "postCode": [
            "65160"
          ]
        },
        {
          "id": 650701,
          "name": "Wat Bot",
          "postCode": [
            "65160"
          ]
        }
      ]
    },
    {
      "city": "Ban Phraek",
      "cityCode": 1416,
      "provinceCode": 14,
      "subDistrict": [
        {
          "id": 141602,
          "name": "Ban Mai",
          "postCode": [
            "13240"
          ]
        },
        {
          "id": 141601,
          "name": "Ban Phraek",
          "postCode": [
            "13240"
          ]
        },
        {
          "id": 141604,
          "name": "Khlong Noi",
          "postCode": [
            "13240"
          ]
        },
        {
          "id": 141603,
          "name": "Sam Phaniang",
          "postCode": [
            "13240"
          ]
        },
        {
          "id": 141605,
          "name": "Song Hong",
          "postCode": [
            "13240"
          ]
        }
      ]
    },
    {
      "city": "Bang Ban",
      "cityCode": 1405,
      "provinceCode": 14,
      "subDistrict": [
        {
          "id": 140507,
          "name": "Ban Khlang",
          "postCode": [
            "13250"
          ]
        },
        {
          "id": 140516,
          "name": "Ban Kum",
          "postCode": [
            "13250"
          ]
        },
        {
          "id": 140501,
          "name": "Bang Ban",
          "postCode": [
            "13250"
          ]
        },
        {
          "id": 140515,
          "name": "Bang Chani",
          "postCode": [
            "13250"
          ]
        },
        {
          "id": 140514,
          "name": "Bang Hak",
          "postCode": [
            "13250"
          ]
        },
        {
          "id": 140512,
          "name": "Bang Luang",
          "postCode": [
            "13250"
          ]
        },
        {
          "id": 140513,
          "name": "Bang Luang Dot",
          "postCode": [
            "13250"
          ]
        },
        {
          "id": 140506,
          "name": "Kop Chao",
          "postCode": [
            "13250"
          ]
        },
        {
          "id": 140505,
          "name": "Maha Phram",
          "postCode": [
            "13250"
          ]
        },
        {
          "id": 140509,
          "name": "Nam Tao",
          "postCode": [
            "13250"
          ]
        },
        {
          "id": 140508,
          "name": "Phra Khao",
          "postCode": [
            "13250"
          ]
        },
        {
          "id": 140503,
          "name": "Sai Noi",
          "postCode": [
            "13250"
          ]
        },
        {
          "id": 140504,
          "name": "Saphan Thai",
          "postCode": [
            "13250"
          ]
        },
        {
          "id": 140510,
          "name": "Thang Chang",
          "postCode": [
            "13250"
          ]
        },
        {
          "id": 140511,
          "name": "Wat Taku",
          "postCode": [
            "13250"
          ]
        },
        {
          "id": 140502,
          "name": "Wat Yom",
          "postCode": [
            "13250"
          ]
        }
      ]
    },
    {
      "city": "Bang Pa-in",
      "cityCode": 1406,
      "provinceCode": 14,
      "subDistrict": [
        {
          "id": 140604,
          "name": "Ban Krot",
          "postCode": [
            "13160"
          ]
        },
        {
          "id": 140601,
          "name": "Ban Len",
          "postCode": [
            "13160"
          ]
        },
        {
          "id": 140613,
          "name": "Ban Paeng",
          "postCode": [
            "13160"
          ]
        },
        {
          "id": 140612,
          "name": "Ban Phlap",
          "postCode": [
            "13160"
          ]
        },
        {
          "id": 140603,
          "name": "Ban Pho",
          "postCode": [
            "13160"
          ]
        },
        {
          "id": 140616,
          "name": "Ban Sang",
          "postCode": [
            "13170"
          ]
        },
        {
          "id": 140607,
          "name": "Ban Wa",
          "postCode": [
            "13160"
          ]
        },
        {
          "id": 140605,
          "name": "Bang Krasan",
          "postCode": [
            "13160"
          ]
        },
        {
          "id": 140609,
          "name": "Bang Pradaeng",
          "postCode": [
            "13160"
          ]
        },
        {
          "id": 140602,
          "name": "Chiang Rak Noi",
          "postCode": [
            "13180"
          ]
        },
        {
          "id": 140618,
          "name": "Khanon Luang",
          "postCode": [
            "13160"
          ]
        },
        {
          "id": 140606,
          "name": "Khlong Chik",
          "postCode": [
            "13160"
          ]
        },
        {
          "id": 140614,
          "name": "Khung Lan",
          "postCode": [
            "13160"
          ]
        },
        {
          "id": 140611,
          "name": "Ko Koet",
          "postCode": [
            "13160"
          ]
        },
        {
          "id": 140610,
          "name": "Sam Ruean",
          "postCode": [
            "13160"
          ]
        },
        {
          "id": 140617,
          "name": "Talat Kriap",
          "postCode": [
            "13160"
          ]
        },
        {
          "id": 140615,
          "name": "Taling Chan",
          "postCode": [
            "13160"
          ]
        },
        {
          "id": 140608,
          "name": "Wat Yom",
          "postCode": [
            "13160"
          ]
        }
      ]
    },
    {
      "city": "Bang Pahan",
      "cityCode": 1407,
      "provinceCode": 14,
      "subDistrict": [
        {
          "id": 140717,
          "name": "Ban Khlo",
          "postCode": [
            "13220"
          ]
        },
        {
          "id": 140713,
          "name": "Ban Li",
          "postCode": [
            "13220"
          ]
        },
        {
          "id": 140711,
          "name": "Ban Ma",
          "postCode": [
            "13220"
          ]
        },
        {
          "id": 140703,
          "name": "Bang Duea",
          "postCode": [
            "13220"
          ]
        },
        {
          "id": 140708,
          "name": "Bang Nang Ra",
          "postCode": [
            "13220"
          ]
        },
        {
          "id": 140701,
          "name": "Bang Pahan",
          "postCode": [
            "13220"
          ]
        },
        {
          "id": 140706,
          "name": "Bang Phloeng",
          "postCode": [
            "13220"
          ]
        },
        {
          "id": 140707,
          "name": "Han Sang",
          "postCode": [
            "13220"
          ]
        },
        {
          "id": 140702,
          "name": "Khayai",
          "postCode": [
            "13220"
          ]
        },
        {
          "id": 140712,
          "name": "Khwan Mueang",
          "postCode": [
            "13220"
          ]
        },
        {
          "id": 140714,
          "name": "Pho Sam Ton",
          "postCode": [
            "13220"
          ]
        },
        {
          "id": 140715,
          "name": "Phut Lao",
          "postCode": [
            "13220"
          ]
        },
        {
          "id": 140704,
          "name": "Sao Thong",
          "postCode": [
            "13220"
          ]
        },
        {
          "id": 140709,
          "name": "Ta Nim",
          "postCode": [
            "13220"
          ]
        },
        {
          "id": 140716,
          "name": "Tan En",
          "postCode": [
            "13220"
          ]
        },
        {
          "id": 140705,
          "name": "Thang Klang",
          "postCode": [
            "13220"
          ]
        },
        {
          "id": 140710,
          "name": "Thap Nam",
          "postCode": [
            "13220"
          ]
        }
      ]
    },
    {
      "city": "Bang Sai",
      "cityCode": 1404,
      "provinceCode": 14,
      "subDistrict": [
        {
          "id": 140411,
          "name": "Ban Klueng",
          "postCode": [
            "13190"
          ]
        },
        {
          "id": 140418,
          "name": "Ban Ko",
          "postCode": [
            "13190"
          ]
        },
        {
          "id": 140417,
          "name": "Ban Ma",
          "postCode": [
            "13190"
          ]
        },
        {
          "id": 140404,
          "name": "Ban Paeng",
          "postCode": [
            "13190"
          ]
        },
        {
          "id": 140402,
          "name": "Bang Phli",
          "postCode": [
            "13190"
          ]
        },
        {
          "id": 140401,
          "name": "Bang Sai",
          "postCode": [
            "13190"
          ]
        },
        {
          "id": 140406,
          "name": "Bang Yi Tho",
          "postCode": [
            "13190"
          ]
        },
        {
          "id": 140409,
          "name": "Chang Lek",
          "postCode": [
            "13190"
          ]
        },
        {
          "id": 140412,
          "name": "Chang Noi",
          "postCode": [
            "13190"
          ]
        },
        {
          "id": 140420,
          "name": "Chang Yai",
          "postCode": [
            "13290"
          ]
        },
        {
          "id": 140422,
          "name": "Chiang Rak Noi",
          "postCode": [
            "13290"
          ]
        },
        {
          "id": 140413,
          "name": "Homok",
          "postCode": [
            "13190"
          ]
        },
        {
          "id": 140407,
          "name": "Khae Ok",
          "postCode": [
            "13190"
          ]
        },
        {
          "id": 140408,
          "name": "Khae Tok",
          "postCode": [
            "13190"
          ]
        },
        {
          "id": 140423,
          "name": "Khok Chang",
          "postCode": [
            "13190"
          ]
        },
        {
          "id": 140415,
          "name": "Kok Kaeo Burapha",
          "postCode": [
            "13190"
          ]
        },
        {
          "id": 140410,
          "name": "Krachaeng",
          "postCode": [
            "13190"
          ]
        },
        {
          "id": 140416,
          "name": "Mai Tra",
          "postCode": [
            "13190"
          ]
        },
        {
          "id": 140405,
          "name": "Na Mai",
          "postCode": [
            "13190"
          ]
        },
        {
          "id": 140414,
          "name": "Phai Phra",
          "postCode": [
            "13190"
          ]
        },
        {
          "id": 140421,
          "name": "Pho Taeng",
          "postCode": [
            "13290"
          ]
        },
        {
          "id": 140419,
          "name": "Ratchakhram",
          "postCode": [
            "13290"
          ]
        },
        {
          "id": 140403,
          "name": "Sanam Chai",
          "postCode": [
            "13190"
          ]
        }
      ]
    },
    {
      "city": "Bang Sai",
      "cityCode": 1413,
      "provinceCode": 14,
      "subDistrict": [
        {
          "id": 141301,
          "name": "Bang Sai",
          "postCode": [
            "13270"
          ]
        },
        {
          "id": 141302,
          "name": "Kaeo Fa",
          "postCode": [
            "13270"
          ]
        },
        {
          "id": 141304,
          "name": "Plai Klat",
          "postCode": [
            "13270"
          ]
        },
        {
          "id": 141303,
          "name": "Tao Lao",
          "postCode": [
            "13270"
          ]
        },
        {
          "id": 141305,
          "name": "Thep Mongkhon",
          "postCode": [
            "13270"
          ]
        },
        {
          "id": 141306,
          "name": "Wang Phatthana",
          "postCode": [
            "13270"
          ]
        }
      ]
    },
    {
      "city": "Lat Bua Luang",
      "cityCode": 1410,
      "provinceCode": 14,
      "subDistrict": [
        {
          "id": 141007,
          "name": "Khlong Phraya Banlue",
          "postCode": [
            "13230"
          ]
        },
        {
          "id": 141006,
          "name": "Khu Salot",
          "postCode": [
            "13230"
          ]
        },
        {
          "id": 141002,
          "name": "Lak Chai",
          "postCode": [
            "13230"
          ]
        },
        {
          "id": 141001,
          "name": "Lat Bua Luang",
          "postCode": [
            "13230"
          ]
        },
        {
          "id": 141004,
          "name": "Phraya Banlue",
          "postCode": [
            "13230"
          ]
        },
        {
          "id": 141003,
          "name": "Sam Mueang",
          "postCode": [
            "13230"
          ]
        },
        {
          "id": 141005,
          "name": "Singhanat",
          "postCode": [
            "13230"
          ]
        }
      ]
    },
    {
      "city": "Maha Rat",
      "cityCode": 1415,
      "provinceCode": 14,
      "subDistrict": [
        {
          "id": 141510,
          "name": "Ban Khwang",
          "postCode": [
            "13150"
          ]
        },
        {
          "id": 141512,
          "name": "Ban Mai",
          "postCode": [
            "13150"
          ]
        },
        {
          "id": 141509,
          "name": "Ban Na",
          "postCode": [
            "13150"
          ]
        },
        {
          "id": 141505,
          "name": "Bang Na",
          "postCode": [
            "13150"
          ]
        },
        {
          "id": 141507,
          "name": "Chao Pluk",
          "postCode": [
            "13150"
          ]
        },
        {
          "id": 141501,
          "name": "Hua Phai",
          "postCode": [
            "13150"
          ]
        },
        {
          "id": 141502,
          "name": "Kathum",
          "postCode": [
            "13150"
          ]
        },
        {
          "id": 141503,
          "name": "Maha Rat",
          "postCode": [
            "13150"
          ]
        },
        {
          "id": 141504,
          "name": "Nam Tao",
          "postCode": [
            "13150"
          ]
        },
        {
          "id": 141508,
          "name": "Phit Phian",
          "postCode": [
            "13150"
          ]
        },
        {
          "id": 141506,
          "name": "Rong Chang",
          "postCode": [
            "13150"
          ]
        },
        {
          "id": 141511,
          "name": "Tha To",
          "postCode": [
            "13150"
          ]
        }
      ]
    },
    {
      "city": "Nakhon Luang",
      "cityCode": 1403,
      "provinceCode": 14,
      "subDistrict": [
        {
          "id": 140304,
          "name": "Ban Chung",
          "postCode": [
            "13260"
          ]
        },
        {
          "id": 140307,
          "name": "Bang Phra Khru",
          "postCode": [
            "13260"
          ]
        },
        {
          "id": 140306,
          "name": "Bang Rakam",
          "postCode": [
            "13260"
          ]
        },
        {
          "id": 140303,
          "name": "Bo Phong",
          "postCode": [
            "13260"
          ]
        },
        {
          "id": 140310,
          "name": "Khlong Sakae",
          "postCode": [
            "13260"
          ]
        },
        {
          "id": 140308,
          "name": "Mae La",
          "postCode": [
            "13260"
          ]
        },
        {
          "id": 140301,
          "name": "Nakhon Luang",
          "postCode": [
            "13260"
          ]
        },
        {
          "id": 140309,
          "name": "Nong Pling",
          "postCode": [
            "13260"
          ]
        },
        {
          "id": 140305,
          "name": "Pak Chan",
          "postCode": [
            "13260"
          ]
        },
        {
          "id": 140312,
          "name": "Phra Non",
          "postCode": [
            "13260"
          ]
        },
        {
          "id": 140311,
          "name": "Sam Thai",
          "postCode": [
            "13260"
          ]
        },
        {
          "id": 140302,
          "name": "Tha Chang",
          "postCode": [
            "13260"
          ]
        }
      ]
    },
    {
      "city": "Phachi",
      "cityCode": 1409,
      "provinceCode": 14,
      "subDistrict": [
        {
          "id": 140905,
          "name": "Don Ya Nang",
          "postCode": [
            "13140"
          ]
        },
        {
          "id": 140902,
          "name": "Khok Muang",
          "postCode": [
            "13140"
          ]
        },
        {
          "id": 140907,
          "name": "Krachio",
          "postCode": [
            "13140"
          ]
        },
        {
          "id": 140904,
          "name": "Nong Nam Sai",
          "postCode": [
            "13140"
          ]
        },
        {
          "id": 140901,
          "name": "Phachi",
          "postCode": [
            "13140"
          ]
        },
        {
          "id": 140906,
          "name": "Phai Lom",
          "postCode": [
            "13140"
          ]
        },
        {
          "id": 140908,
          "name": "Phra Kaeo",
          "postCode": [
            "13140"
          ]
        },
        {
          "id": 140903,
          "name": "Rasom",
          "postCode": [
            "13140",
            "18250"
          ]
        }
      ]
    },
    {
      "city": "Phak Hai",
      "cityCode": 1408,
      "provinceCode": 14,
      "subDistrict": [
        {
          "id": 140802,
          "name": "Ammarit",
          "postCode": [
            "13120"
          ]
        },
        {
          "id": 140803,
          "name": "Ban Khae",
          "postCode": [
            "13120"
          ]
        },
        {
          "id": 140816,
          "name": "Ban Yai",
          "postCode": [
            "13120"
          ]
        },
        {
          "id": 140812,
          "name": "Chakkarat",
          "postCode": [
            "13280"
          ]
        },
        {
          "id": 140807,
          "name": "Don Lan",
          "postCode": [
            "13280"
          ]
        },
        {
          "id": 140811,
          "name": "Khok Chang",
          "postCode": [
            "13120"
          ]
        },
        {
          "id": 140809,
          "name": "Kudi",
          "postCode": [
            "13120"
          ]
        },
        {
          "id": 140810,
          "name": "Lam Takhian",
          "postCode": [
            "13280"
          ]
        },
        {
          "id": 140814,
          "name": "Lat Chit",
          "postCode": [
            "13120"
          ]
        },
        {
          "id": 140804,
          "name": "Lat Nam Khem",
          "postCode": [
            "13120"
          ]
        },
        {
          "id": 140815,
          "name": "Na Khok",
          "postCode": [
            "13120"
          ]
        },
        {
          "id": 140808,
          "name": "Na Khu",
          "postCode": [
            "13280"
          ]
        },
        {
          "id": 140813,
          "name": "Nong Nam Yai",
          "postCode": [
            "13280"
          ]
        },
        {
          "id": 140801,
          "name": "Phak Hai",
          "postCode": [
            "13120"
          ]
        },
        {
          "id": 140805,
          "name": "Ta Lan",
          "postCode": [
            "13120"
          ]
        },
        {
          "id": 140806,
          "name": "Tha Din Daeng",
          "postCode": [
            "13120"
          ]
        }
      ]
    },
    {
      "city": "Phra Nakhon Si Ayutthaya",
      "cityCode": 1401,
      "provinceCode": 14,
      "subDistrict": [
        {
          "id": 140116,
          "name": "Ban Ko",
          "postCode": [
            "13000"
          ]
        },
        {
          "id": 140115,
          "name": "Ban Mai",
          "postCode": [
            "13000"
          ]
        },
        {
          "id": 140120,
          "name": "Ban Pom",
          "postCode": [
            "13000"
          ]
        },
        {
          "id": 140121,
          "name": "Ban Run",
          "postCode": [
            "13000"
          ]
        },
        {
          "id": 140113,
          "name": "Hantra",
          "postCode": [
            "13000"
          ]
        },
        {
          "id": 140103,
          "name": "Ho Rattanachai",
          "postCode": [
            "13000"
          ]
        },
        {
          "id": 140104,
          "name": "Hua Ro",
          "postCode": [
            "13000"
          ]
        },
        {
          "id": 140102,
          "name": "Kamang",
          "postCode": [
            "13000"
          ]
        },
        {
          "id": 140118,
          "name": "Khlong Sa Bua",
          "postCode": [
            "13000"
          ]
        },
        {
          "id": 140117,
          "name": "Khlong Suan Phlu",
          "postCode": [
            "13000"
          ]
        },
        {
          "id": 140111,
          "name": "Khlong Takhian",
          "postCode": [
            "13000"
          ]
        },
        {
          "id": 140119,
          "name": "Ko Rian",
          "postCode": [
            "13000"
          ]
        },
        {
          "id": 140114,
          "name": "Lumphli",
          "postCode": [
            "13000"
          ]
        },
        {
          "id": 140107,
          "name": "Pak Kran",
          "postCode": [
            "13000"
          ]
        },
        {
          "id": 140106,
          "name": "Phai Ling",
          "postCode": [
            "13000"
          ]
        },
        {
          "id": 140108,
          "name": "Phukhao Thong",
          "postCode": [
            "13000"
          ]
        },
        {
          "id": 140101,
          "name": "Pratu Chai",
          "postCode": [
            "13000"
          ]
        },
        {
          "id": 140109,
          "name": "Samphao Lom",
          "postCode": [
            "13000"
          ]
        },
        {
          "id": 140110,
          "name": "Suan Phrik",
          "postCode": [
            "13000"
          ]
        },
        {
          "id": 140105,
          "name": "Tha Wasukri",
          "postCode": [
            "13000"
          ]
        },
        {
          "id": 140112,
          "name": "Wat Tum",
          "postCode": [
            "13000"
          ]
        }
      ]
    },
    {
      "city": "Sena",
      "cityCode": 1412,
      "provinceCode": 14,
      "subDistrict": [
        {
          "id": 141210,
          "name": "Ban Krathum",
          "postCode": [
            "13110"
          ]
        },
        {
          "id": 141216,
          "name": "Ban Luang",
          "postCode": [
            "13110"
          ]
        },
        {
          "id": 141202,
          "name": "Ban Phaen",
          "postCode": [
            "13110"
          ]
        },
        {
          "id": 141208,
          "name": "Ban Pho",
          "postCode": [
            "13110"
          ]
        },
        {
          "id": 141211,
          "name": "Ban Thaeo",
          "postCode": [
            "13110"
          ]
        },
        {
          "id": 141205,
          "name": "Bang Nom Kho",
          "postCode": [
            "13110"
          ]
        },
        {
          "id": 141212,
          "name": "Chai Na",
          "postCode": [
            "13110"
          ]
        },
        {
          "id": 141203,
          "name": "Chao Chet",
          "postCode": [
            "13110"
          ]
        },
        {
          "id": 141217,
          "name": "Chao Sadet",
          "postCode": [
            "13110"
          ]
        },
        {
          "id": 141215,
          "name": "Don Thong",
          "postCode": [
            "13110"
          ]
        },
        {
          "id": 141206,
          "name": "Hua Wiang",
          "postCode": [
            "13110"
          ]
        },
        {
          "id": 141214,
          "name": "Lat Nga",
          "postCode": [
            "13110"
          ]
        },
        {
          "id": 141207,
          "name": "Manwichai",
          "postCode": [
            "13110"
          ]
        },
        {
          "id": 141209,
          "name": "Rang Chorakhe",
          "postCode": [
            "13110"
          ]
        },
        {
          "id": 141204,
          "name": "Sam Ko",
          "postCode": [
            "13110"
          ]
        },
        {
          "id": 141213,
          "name": "Sam Tum",
          "postCode": [
            "13110"
          ]
        },
        {
          "id": 141201,
          "name": "Sena",
          "postCode": [
            "13110"
          ]
        }
      ]
    },
    {
      "city": "Tha Ruea",
      "cityCode": 1402,
      "provinceCode": 14,
      "subDistrict": [
        {
          "id": 140204,
          "name": "Ban Rom",
          "postCode": [
            "13130"
          ]
        },
        {
          "id": 140202,
          "name": "Champa",
          "postCode": [
            "13130"
          ]
        },
        {
          "id": 140209,
          "name": "Nong Khanak",
          "postCode": [
            "13130"
          ]
        },
        {
          "id": 140208,
          "name": "Pak Tha",
          "postCode": [
            "13130"
          ]
        },
        {
          "id": 140207,
          "name": "Pho En",
          "postCode": [
            "13130"
          ]
        },
        {
          "id": 140205,
          "name": "Sala Loi",
          "postCode": [
            "13130"
          ]
        },
        {
          "id": 140210,
          "name": "Tha Chao Sanuk",
          "postCode": [
            "13130"
          ]
        },
        {
          "id": 140203,
          "name": "Tha Luang",
          "postCode": [
            "13130",
            "18270"
          ]
        },
        {
          "id": 140201,
          "name": "Tha Ruea",
          "postCode": [
            "13130"
          ]
        },
        {
          "id": 140206,
          "name": "Wang Daeng",
          "postCode": [
            "13130"
          ]
        }
      ]
    },
    {
      "city": "Uthai",
      "cityCode": 1414,
      "provinceCode": 14,
      "subDistrict": [
        {
          "id": 141402,
          "name": "Ban Chang",
          "postCode": [
            "13210"
          ]
        },
        {
          "id": 141404,
          "name": "Ban Hip",
          "postCode": [
            "13210"
          ]
        },
        {
          "id": 141401,
          "name": "Khan Ham",
          "postCode": [
            "13210"
          ]
        },
        {
          "id": 141411,
          "name": "Khao Mao",
          "postCode": [
            "13000",
            "13210"
          ]
        },
        {
          "id": 141405,
          "name": "Nong Mai Sung",
          "postCode": [
            "13210"
          ]
        },
        {
          "id": 141408,
          "name": "Nong Nam Som",
          "postCode": [
            "13210"
          ]
        },
        {
          "id": 141409,
          "name": "Pho Sao Han",
          "postCode": [
            "13210"
          ]
        },
        {
          "id": 141403,
          "name": "Sam Bandit",
          "postCode": [
            "13210"
          ]
        },
        {
          "id": 141407,
          "name": "Sena",
          "postCode": [
            "13210"
          ]
        },
        {
          "id": 141410,
          "name": "Thanu",
          "postCode": [
            "13000",
            "13210"
          ]
        },
        {
          "id": 141406,
          "name": "Uthai",
          "postCode": [
            "13210"
          ]
        }
      ]
    },
    {
      "city": "Wang Noi",
      "cityCode": 1411,
      "provinceCode": 14,
      "subDistrict": [
        {
          "id": 141102,
          "name": "Bo ta Lo",
          "postCode": [
            "13170"
          ]
        },
        {
          "id": 141110,
          "name": "Chamaep",
          "postCode": [
            "13170"
          ]
        },
        {
          "id": 141107,
          "name": "Han Taphao",
          "postCode": [
            "13170"
          ]
        },
        {
          "id": 141109,
          "name": "Khao Ngam",
          "postCode": [
            "13170"
          ]
        },
        {
          "id": 141104,
          "name": "Lam Sai",
          "postCode": [
            "13170"
          ]
        },
        {
          "id": 141101,
          "name": "Lam Ta Sao",
          "postCode": [
            "13170"
          ]
        },
        {
          "id": 141106,
          "name": "Phayom",
          "postCode": [
            "13170",
            "13180"
          ]
        },
        {
          "id": 141105,
          "name": "Sanap Thuep",
          "postCode": [
            "13170"
          ]
        },
        {
          "id": 141108,
          "name": "Wang Chula",
          "postCode": [
            "13170"
          ]
        },
        {
          "id": 141103,
          "name": "Wang Noi",
          "postCode": [
            "13170"
          ]
        }
      ]
    },
    {
      "city": "Den Chai",
      "cityCode": 5405,
      "provinceCode": 54,
      "subDistrict": [
        {
          "id": 540501,
          "name": "Den Chai",
          "postCode": [
            "54110"
          ]
        },
        {
          "id": 540504,
          "name": "Huai Rai",
          "postCode": [
            "54110"
          ]
        },
        {
          "id": 540502,
          "name": "Mae Chua",
          "postCode": [
            "54110"
          ]
        },
        {
          "id": 540505,
          "name": "Pong Pa Wai",
          "postCode": [
            "54110"
          ]
        },
        {
          "id": 540503,
          "name": "Sai Yoi",
          "postCode": [
            "54110"
          ]
        }
      ]
    },
    {
      "city": "Long",
      "cityCode": 5403,
      "provinceCode": 54,
      "subDistrict": [
        {
          "id": 540302,
          "name": "Ban Pin",
          "postCode": [
            "54150"
          ]
        },
        {
          "id": 540308,
          "name": "Bo Lek Long",
          "postCode": [
            "54150"
          ]
        },
        {
          "id": 540306,
          "name": "Hua Thung",
          "postCode": [
            "54150"
          ]
        },
        {
          "id": 540301,
          "name": "Huai O",
          "postCode": [
            "54150"
          ]
        },
        {
          "id": 540309,
          "name": "Mae Pan",
          "postCode": [
            "54150"
          ]
        },
        {
          "id": 540305,
          "name": "Pak Kang",
          "postCode": [
            "54150"
          ]
        },
        {
          "id": 540303,
          "name": "Ta Pha Mok",
          "postCode": [
            "54150"
          ]
        },
        {
          "id": 540307,
          "name": "Thung Laeng",
          "postCode": [
            "54150"
          ]
        },
        {
          "id": 540304,
          "name": "Wiang Ta",
          "postCode": [
            "54150"
          ]
        }
      ]
    },
    {
      "city": "Mueang Phrae",
      "cityCode": 5401,
      "provinceCode": 54,
      "subDistrict": [
        {
          "id": 540111,
          "name": "Ban Thin",
          "postCode": [
            "54000"
          ]
        },
        {
          "id": 540118,
          "name": "Cho Hae",
          "postCode": [
            "54000"
          ]
        },
        {
          "id": 540109,
          "name": "Huai Ma",
          "postCode": [
            "54000"
          ]
        },
        {
          "id": 540120,
          "name": "Kanchana",
          "postCode": [
            "54000"
          ]
        },
        {
          "id": 540114,
          "name": "Mae Kham Mi",
          "postCode": [
            "54000"
          ]
        },
        {
          "id": 540108,
          "name": "Mae Lai",
          "postCode": [
            "54000"
          ]
        },
        {
          "id": 540117,
          "name": "Mae Yom",
          "postCode": [
            "54000"
          ]
        },
        {
          "id": 540106,
          "name": "Mueang Mo",
          "postCode": [
            "54000"
          ]
        },
        {
          "id": 540102,
          "name": "Na Chak",
          "postCode": [
            "54000"
          ]
        },
        {
          "id": 540101,
          "name": "Nai Wiang",
          "postCode": [
            "54000"
          ]
        },
        {
          "id": 540103,
          "name": "Nam Cham",
          "postCode": [
            "54000"
          ]
        },
        {
          "id": 540104,
          "name": "Pa Daeng",
          "postCode": [
            "54000"
          ]
        },
        {
          "id": 540110,
          "name": "Pa Maet",
          "postCode": [
            "54000"
          ]
        },
        {
          "id": 540119,
          "name": "Rong Fong",
          "postCode": [
            "54000"
          ]
        },
        {
          "id": 540112,
          "name": "Suan Khuean",
          "postCode": [
            "54000"
          ]
        },
        {
          "id": 540116,
          "name": "Tha Kham",
          "postCode": [
            "54000"
          ]
        },
        {
          "id": 540105,
          "name": "Thung Hong",
          "postCode": [
            "54000"
          ]
        },
        {
          "id": 540115,
          "name": "Thung Kwao",
          "postCode": [
            "54000"
          ]
        },
        {
          "id": 540113,
          "name": "Wang Hong",
          "postCode": [
            "54000"
          ]
        },
        {
          "id": 540107,
          "name": "Wang Thong",
          "postCode": [
            "54000"
          ]
        }
      ]
    },
    {
      "city": "Nong Muang Khai",
      "cityCode": 5408,
      "provinceCode": 54,
      "subDistrict": [
        {
          "id": 540801,
          "name": "Mae Kham Mi",
          "postCode": [
            "54170"
          ]
        },
        {
          "id": 540803,
          "name": "Nam Rat",
          "postCode": [
            "54170"
          ]
        },
        {
          "id": 540802,
          "name": "Nong Muang Khai",
          "postCode": [
            "54170"
          ]
        },
        {
          "id": 540805,
          "name": "Tamnak Tham",
          "postCode": [
            "54170"
          ]
        },
        {
          "id": 540806,
          "name": "Thung Khaeo",
          "postCode": [
            "54170"
          ]
        },
        {
          "id": 540804,
          "name": "wang Luang",
          "postCode": [
            "54170"
          ]
        }
      ]
    },
    {
      "city": "Rong Kwang",
      "cityCode": 5402,
      "provinceCode": 54,
      "subDistrict": [
        {
          "id": 540206,
          "name": "Ban Wiang",
          "postCode": [
            "54140"
          ]
        },
        {
          "id": 540213,
          "name": "Huai Rong",
          "postCode": [
            "54140"
          ]
        },
        {
          "id": 540214,
          "name": "Mae Sai",
          "postCode": [
            "54140"
          ]
        },
        {
          "id": 540209,
          "name": "Mae Yang Ho",
          "postCode": [
            "54140"
          ]
        },
        {
          "id": 540215,
          "name": "Mae Yang Rong",
          "postCode": [
            "54140"
          ]
        },
        {
          "id": 540208,
          "name": "Mae Yang Tan",
          "postCode": [
            "54140"
          ]
        },
        {
          "id": 540205,
          "name": "Nam Lao",
          "postCode": [
            "54140"
          ]
        },
        {
          "id": 540210,
          "name": "Phai Thon",
          "postCode": [
            "54140"
          ]
        },
        {
          "id": 540204,
          "name": "Rong Khem",
          "postCode": [
            "54140"
          ]
        },
        {
          "id": 540201,
          "name": "Rong Kwang",
          "postCode": [
            "54140"
          ]
        },
        {
          "id": 540207,
          "name": "Thung Si",
          "postCode": [
            "54140"
          ]
        }
      ]
    },
    {
      "city": "Song",
      "cityCode": 5406,
      "provinceCode": 54,
      "subDistrict": [
        {
          "id": 540602,
          "name": "Ban Klang",
          "postCode": [
            "54120"
          ]
        },
        {
          "id": 540601,
          "name": "Ban Nun",
          "postCode": [
            "54120"
          ]
        },
        {
          "id": 540607,
          "name": "Daen Chumphon",
          "postCode": [
            "54120"
          ]
        },
        {
          "id": 540605,
          "name": "Hua Mueang",
          "postCode": [
            "54120"
          ]
        },
        {
          "id": 540603,
          "name": "Huai Mai",
          "postCode": [
            "54120"
          ]
        },
        {
          "id": 540606,
          "name": "Sa-iap",
          "postCode": [
            "54120"
          ]
        },
        {
          "id": 540604,
          "name": "Tao Pun",
          "postCode": [
            "54120"
          ]
        },
        {
          "id": 540608,
          "name": "Thung Nao",
          "postCode": [
            "54120"
          ]
        }
      ]
    },
    {
      "city": "Sung Men",
      "cityCode": 5404,
      "provinceCode": 54,
      "subDistrict": [
        {
          "id": 540408,
          "name": "Ban Kat",
          "postCode": [
            "54130"
          ]
        },
        {
          "id": 540406,
          "name": "Ban Kwang",
          "postCode": [
            "54130"
          ]
        },
        {
          "id": 540405,
          "name": "Ban Lao",
          "postCode": [
            "54130"
          ]
        },
        {
          "id": 540407,
          "name": "Ban Pong",
          "postCode": [
            "54130"
          ]
        },
        {
          "id": 540404,
          "name": "Don Mun",
          "postCode": [
            "54130"
          ]
        },
        {
          "id": 540403,
          "name": "Hua Fai",
          "postCode": [
            "54130"
          ]
        },
        {
          "id": 540402,
          "name": "Nam Cham",
          "postCode": [
            "54130"
          ]
        },
        {
          "id": 540412,
          "name": "Phra Luang",
          "postCode": [
            "54130"
          ]
        },
        {
          "id": 540409,
          "name": "Rong Kat",
          "postCode": [
            "54130"
          ]
        },
        {
          "id": 540410,
          "name": "Sop Sai",
          "postCode": [
            "54130"
          ]
        },
        {
          "id": 540401,
          "name": "Sung Men",
          "postCode": [
            "54130"
          ]
        },
        {
          "id": 540411,
          "name": "Wiang Thong",
          "postCode": [
            "54000"
          ]
        }
      ]
    },
    {
      "city": "Wang Chin",
      "cityCode": 5407,
      "provinceCode": 54,
      "subDistrict": [
        {
          "id": 540707,
          "name": "Mae Koeng",
          "postCode": [
            "54160"
          ]
        },
        {
          "id": 540703,
          "name": "Mae Pak",
          "postCode": [
            "54160"
          ]
        },
        {
          "id": 540705,
          "name": "Mae Phung",
          "postCode": [
            "54160"
          ]
        },
        {
          "id": 540704,
          "name": "Na Phun",
          "postCode": [
            "54160"
          ]
        },
        {
          "id": 540706,
          "name": "Pa Sak",
          "postCode": [
            "54160"
          ]
        },
        {
          "id": 540702,
          "name": "Soi",
          "postCode": [
            "54160"
          ]
        },
        {
          "id": 540701,
          "name": "Wang Chin",
          "postCode": [
            "54160"
          ]
        }
      ]
    },
    {
      "city": "Kathu",
      "cityCode": 8302,
      "provinceCode": 83,
      "subDistrict": [
        {
          "id": 830203,
          "name": "Kamala",
          "postCode": [
            "83150"
          ]
        },
        {
          "id": 830201,
          "name": "Kathu",
          "postCode": [
            "83120"
          ]
        },
        {
          "id": 830202,
          "name": "Pa Tong",
          "postCode": [
            "83150"
          ]
        }
      ]
    },
    {
      "city": "Mueang Phuket",
      "cityCode": 8301,
      "provinceCode": 83,
      "subDistrict": [
        {
          "id": 830106,
          "name": "Chalong",
          "postCode": [
            "83130"
          ]
        },
        {
          "id": 830108,
          "name": "Karon",
          "postCode": [
            "83100"
          ]
        },
        {
          "id": 830103,
          "name": "Ko Kaeo",
          "postCode": [
            "83000"
          ]
        },
        {
          "id": 830104,
          "name": "Ratsada",
          "postCode": [
            "83000"
          ]
        },
        {
          "id": 830107,
          "name": "Rawai",
          "postCode": [
            "83130"
          ]
        },
        {
          "id": 830102,
          "name": "Talat Nuea",
          "postCode": [
            "83000"
          ]
        },
        {
          "id": 830101,
          "name": "Talat Yai",
          "postCode": [
            "83000"
          ]
        },
        {
          "id": 830105,
          "name": "Wichit",
          "postCode": [
            "83000"
          ]
        }
      ]
    },
    {
      "city": "Thalang",
      "cityCode": 8303,
      "provinceCode": 83,
      "subDistrict": [
        {
          "id": 830303,
          "name": "Choeng Thale",
          "postCode": [
            "83110"
          ]
        },
        {
          "id": 830305,
          "name": "Mai Khao",
          "postCode": [
            "83110"
          ]
        },
        {
          "id": 830304,
          "name": "Pa Khlok",
          "postCode": [
            "83110"
          ]
        },
        {
          "id": 830306,
          "name": "Sakhu",
          "postCode": [
            "83110"
          ]
        },
        {
          "id": 830302,
          "name": "Si Sunthon",
          "postCode": [
            "83110"
          ]
        },
        {
          "id": 830301,
          "name": "Thep Krasatti",
          "postCode": [
            "83110"
          ]
        }
      ]
    },
    {
      "city": "Ban Sang",
      "cityCode": 2506,
      "provinceCode": 25,
      "subDistrict": [
        {
          "id": 250601,
          "name": "Ban Sang",
          "postCode": [
            "25150"
          ]
        },
        {
          "id": 250608,
          "name": "Bang Kham",
          "postCode": [
            "25150"
          ]
        },
        {
          "id": 250602,
          "name": "Bang Krabao",
          "postCode": [
            "25150"
          ]
        },
        {
          "id": 250606,
          "name": "Bang Phluang",
          "postCode": [
            "25150"
          ]
        },
        {
          "id": 250607,
          "name": "Bang Pla Ra",
          "postCode": [
            "25150"
          ]
        },
        {
          "id": 250605,
          "name": "Bang Taen",
          "postCode": [
            "25150"
          ]
        },
        {
          "id": 250603,
          "name": "Bang Toei",
          "postCode": [
            "25150"
          ]
        },
        {
          "id": 250604,
          "name": "Bang Yang",
          "postCode": [
            "25150"
          ]
        },
        {
          "id": 250609,
          "name": "Krathum Phaeo",
          "postCode": [
            "25150"
          ]
        }
      ]
    },
    {
      "city": "Kabin Buri",
      "cityCode": 2502,
      "provinceCode": 25,
      "subDistrict": [
        {
          "id": 250209,
          "name": "Ban Na",
          "postCode": [
            "25110"
          ]
        },
        {
          "id": 250210,
          "name": "Bo Thong",
          "postCode": [
            "25110"
          ]
        },
        {
          "id": 250207,
          "name": "Hat Nang Kaeo",
          "postCode": [
            "25110"
          ]
        },
        {
          "id": 250201,
          "name": "Kabin",
          "postCode": [
            "25110"
          ]
        },
        {
          "id": 250213,
          "name": "Khao Mai Kaeo",
          "postCode": [
            "25110"
          ]
        },
        {
          "id": 250208,
          "name": "Lat Takhian",
          "postCode": [
            "25110"
          ]
        },
        {
          "id": 250202,
          "name": "Mueang Kao",
          "postCode": [
            "25240"
          ]
        },
        {
          "id": 250212,
          "name": "Na Khaem",
          "postCode": [
            "25110"
          ]
        },
        {
          "id": 250211,
          "name": "Nong Ki",
          "postCode": [
            "25110"
          ]
        },
        {
          "id": 250204,
          "name": "Nonsi",
          "postCode": [
            "25110"
          ]
        },
        {
          "id": 250203,
          "name": "Wang Dan",
          "postCode": [
            "25110"
          ]
        },
        {
          "id": 250206,
          "name": "Wang Takhian",
          "postCode": [
            "25110"
          ]
        },
        {
          "id": 250214,
          "name": "Wang Tha Chang",
          "postCode": [
            "25110"
          ]
        },
        {
          "id": 250205,
          "name": "Yan Ri",
          "postCode": [
            "25110"
          ]
        }
      ]
    },
    {
      "city": "Mueang Prachin Buri",
      "cityCode": 2501,
      "provinceCode": 25,
      "subDistrict": [
        {
          "id": 250108,
          "name": "Ban Phra",
          "postCode": [
            "25230"
          ]
        },
        {
          "id": 250106,
          "name": "Bang Boribun",
          "postCode": [
            "25000"
          ]
        },
        {
          "id": 250104,
          "name": "Bang Decha",
          "postCode": [
            "25000"
          ]
        },
        {
          "id": 250111,
          "name": "Dong Khilek",
          "postCode": [
            "25000"
          ]
        },
        {
          "id": 250107,
          "name": "Dong Phra Ram",
          "postCode": [
            "25000"
          ]
        },
        {
          "id": 250109,
          "name": "Khok Mai Lai",
          "postCode": [
            "25230"
          ]
        },
        {
          "id": 250110,
          "name": "Mai Khet",
          "postCode": [
            "25230"
          ]
        },
        {
          "id": 250101,
          "name": "Na Mueang",
          "postCode": [
            "25000"
          ]
        },
        {
          "id": 250112,
          "name": "Noen Hom",
          "postCode": [
            "25230"
          ]
        },
        {
          "id": 250113,
          "name": "Non Hom",
          "postCode": [
            "25000"
          ]
        },
        {
          "id": 250102,
          "name": "Rop Mueang",
          "postCode": [
            "25000"
          ]
        },
        {
          "id": 250105,
          "name": "Tha Ngam",
          "postCode": [
            "25000"
          ]
        },
        {
          "id": 250103,
          "name": "Wat Bot",
          "postCode": [
            "25000"
          ]
        }
      ]
    },
    {
      "city": "Na Di",
      "cityCode": 2503,
      "provinceCode": 25,
      "subDistrict": [
        {
          "id": 250306,
          "name": "Bu Phram",
          "postCode": [
            "25220"
          ]
        },
        {
          "id": 250305,
          "name": "Kaeng Din So",
          "postCode": [
            "25220"
          ]
        },
        {
          "id": 250301,
          "name": "Na Di",
          "postCode": [
            "25220"
          ]
        },
        {
          "id": 250302,
          "name": "Sam Phan Ta",
          "postCode": [
            "25220"
          ]
        },
        {
          "id": 250303,
          "name": "Saphan Hin",
          "postCode": [
            "25220"
          ]
        },
        {
          "id": 250304,
          "name": "Thung Pho",
          "postCode": [
            "25220"
          ]
        }
      ]
    },
    {
      "city": "Prachantakham",
      "cityCode": 2507,
      "provinceCode": 25,
      "subDistrict": [
        {
          "id": 250703,
          "name": "Ban Hoi",
          "postCode": [
            "25130"
          ]
        },
        {
          "id": 250707,
          "name": "Bu Fai",
          "postCode": [
            "25130"
          ]
        },
        {
          "id": 250705,
          "name": "Dong Bang",
          "postCode": [
            "25130"
          ]
        },
        {
          "id": 250706,
          "name": "Kham Tanot",
          "postCode": [
            "25130"
          ]
        },
        {
          "id": 250702,
          "name": "Ko Loi",
          "postCode": [
            "25130"
          ]
        },
        {
          "id": 250708,
          "name": "Nong Kaeo",
          "postCode": [
            "25130"
          ]
        },
        {
          "id": 250704,
          "name": "Nong Saeng",
          "postCode": [
            "25130"
          ]
        },
        {
          "id": 250709,
          "name": "Pho Ngam",
          "postCode": [
            "25130"
          ]
        },
        {
          "id": 250701,
          "name": "Prachantakham",
          "postCode": [
            "25130"
          ]
        }
      ]
    },
    {
      "city": "Si Maha Phot",
      "cityCode": 2508,
      "provinceCode": 25,
      "subDistrict": [
        {
          "id": 250803,
          "name": "Ban Tham",
          "postCode": [
            "25140"
          ]
        },
        {
          "id": 250805,
          "name": "Bang Kung",
          "postCode": [
            "25140"
          ]
        },
        {
          "id": 250806,
          "name": "Dong Krathong Yam",
          "postCode": [
            "25140"
          ]
        },
        {
          "id": 250809,
          "name": "Hat Yang",
          "postCode": [
            "25140"
          ]
        },
        {
          "id": 250808,
          "name": "Hua Wa",
          "postCode": [
            "25140"
          ]
        },
        {
          "id": 250810,
          "name": "Krok Sombun",
          "postCode": [
            "25140"
          ]
        },
        {
          "id": 250807,
          "name": "Nong Phrong",
          "postCode": [
            "25140"
          ]
        },
        {
          "id": 250802,
          "name": "Samphan",
          "postCode": [
            "25140"
          ]
        },
        {
          "id": 250801,
          "name": "Si Maha Phot",
          "postCode": [
            "25140"
          ]
        },
        {
          "id": 250804,
          "name": "Tha Tum",
          "postCode": [
            "25140"
          ]
        }
      ]
    },
    {
      "city": "Si Mahosot",
      "cityCode": 2509,
      "provinceCode": 25,
      "subDistrict": [
        {
          "id": 250901,
          "name": "Khok Pip",
          "postCode": [
            "25190"
          ]
        },
        {
          "id": 250902,
          "name": "Khok Thai",
          "postCode": [
            "25190"
          ]
        },
        {
          "id": 250903,
          "name": "Khu Lam Phan",
          "postCode": [
            "25190"
          ]
        },
        {
          "id": 250904,
          "name": "Phai Cha Lueat",
          "postCode": [
            "25190"
          ]
        }
      ]
    },
    {
      "city": "Bang Saphan",
      "cityCode": 7704,
      "provinceCode": 77,
      "subDistrict": [
        {
          "id": 770405,
          "name": "Chai Kasem",
          "postCode": [
            "77190"
          ]
        },
        {
          "id": 770401,
          "name": "Kamnoet Nopphakhun",
          "postCode": [
            "77140"
          ]
        },
        {
          "id": 770407,
          "name": "Mae Ramphueng",
          "postCode": [
            "77140"
          ]
        },
        {
          "id": 770402,
          "name": "Phong Prasat",
          "postCode": [
            "77140"
          ]
        },
        {
          "id": 770403,
          "name": "Ron Thong",
          "postCode": [
            "77230"
          ]
        },
        {
          "id": 770404,
          "name": "Thong Chai",
          "postCode": [
            "77190"
          ]
        },
        {
          "id": 770406,
          "name": "Thong Mongkhon",
          "postCode": [
            "77230"
          ]
        }
      ]
    },
    {
      "city": "Bang Saphan Noi",
      "cityCode": 7705,
      "provinceCode": 77,
      "subDistrict": [
        {
          "id": 770502,
          "name": "Bang Saphan",
          "postCode": [
            "77170"
          ]
        },
        {
          "id": 770505,
          "name": "Chai Rat",
          "postCode": [
            "77170"
          ]
        },
        {
          "id": 770504,
          "name": "Chang Raek",
          "postCode": [
            "77170"
          ]
        },
        {
          "id": 770501,
          "name": "Pak Phraek",
          "postCode": [
            "77170"
          ]
        },
        {
          "id": 770503,
          "name": "Sai Thong",
          "postCode": [
            "77170"
          ]
        }
      ]
    },
    {
      "city": "Hua Hin",
      "cityCode": 7707,
      "provinceCode": 77,
      "subDistrict": [
        {
          "id": 770707,
          "name": "Bueng Nakhon",
          "postCode": [
            "77110"
          ]
        },
        {
          "id": 770703,
          "name": "Hin Lek Fai",
          "postCode": [
            "77110"
          ]
        },
        {
          "id": 770701,
          "name": "Hua Hin",
          "postCode": [
            "77110"
          ]
        },
        {
          "id": 770706,
          "name": "Huai Sat Yai",
          "postCode": [
            "77110"
          ]
        },
        {
          "id": 770702,
          "name": "Nong Kae",
          "postCode": [
            "77110"
          ]
        },
        {
          "id": 770704,
          "name": "Nong Phlap",
          "postCode": [
            "77110"
          ]
        },
        {
          "id": 770705,
          "name": "Thap Tai",
          "postCode": [
            "77110"
          ]
        }
      ]
    },
    {
      "city": "Kui Buri",
      "cityCode": 7702,
      "provinceCode": 77,
      "subDistrict": [
        {
          "id": 770204,
          "name": "Don Yai Nu",
          "postCode": [
            "77150"
          ]
        },
        {
          "id": 770207,
          "name": "Hat Kham",
          "postCode": [
            "77150"
          ]
        },
        {
          "id": 770203,
          "name": "Khao Daeng",
          "postCode": [
            "77150"
          ]
        },
        {
          "id": 770201,
          "name": "Kui Buri",
          "postCode": [
            "77150"
          ]
        },
        {
          "id": 770202,
          "name": "Kui Nuea",
          "postCode": [
            "77150"
          ]
        },
        {
          "id": 770206,
          "name": "Sam Krathai",
          "postCode": [
            "77150"
          ]
        }
      ]
    },
    {
      "city": "Mueang Prachuap Khiri Khan",
      "cityCode": 7701,
      "provinceCode": 77,
      "subDistrict": [
        {
          "id": 770105,
          "name": "Ao Noi",
          "postCode": [
            "77000",
            "77210"
          ]
        },
        {
          "id": 770106,
          "name": "Bo Nok",
          "postCode": [
            "77210"
          ]
        },
        {
          "id": 770104,
          "name": "Huai Sai",
          "postCode": [
            "77000"
          ]
        },
        {
          "id": 770103,
          "name": "Khlong Wan",
          "postCode": [
            "77000"
          ]
        },
        {
          "id": 770102,
          "name": "Ko Lak",
          "postCode": [
            "77000"
          ]
        },
        {
          "id": 770101,
          "name": "Prachuap Khiri Khan",
          "postCode": [
            "77000"
          ]
        }
      ]
    },
    {
      "city": "Pran Buri",
      "cityCode": 7706,
      "provinceCode": 77,
      "subDistrict": [
        {
          "id": 770609,
          "name": "Khao Chao",
          "postCode": [
            "77120"
          ]
        },
        {
          "id": 770602,
          "name": "Khao Noi",
          "postCode": [
            "77120",
            "77160"
          ]
        },
        {
          "id": 770607,
          "name": "Nong Ta Taem",
          "postCode": [
            "77120"
          ]
        },
        {
          "id": 770604,
          "name": "Pak Nam Pran",
          "postCode": [
            "77220"
          ]
        },
        {
          "id": 770601,
          "name": "Pran Buri",
          "postCode": [
            "77120"
          ]
        },
        {
          "id": 770608,
          "name": "Wang Phong",
          "postCode": [
            "77120"
          ]
        }
      ]
    },
    {
      "city": "Sam Roi Yot",
      "cityCode": 7708,
      "provinceCode": 77,
      "subDistrict": [
        {
          "id": 770803,
          "name": "Rai Kao",
          "postCode": [
            "77180"
          ]
        },
        {
          "id": 770805,
          "name": "Rai Mai",
          "postCode": [
            "77180"
          ]
        },
        {
          "id": 770804,
          "name": "Salalai",
          "postCode": [
            "77180"
          ]
        },
        {
          "id": 770801,
          "name": "Sam Roi Yot",
          "postCode": [
            "77120"
          ]
        },
        {
          "id": 770802,
          "name": "Sila Loi",
          "postCode": [
            "77180"
          ]
        }
      ]
    },
    {
      "city": "Thap Sakae",
      "cityCode": 7703,
      "provinceCode": 77,
      "subDistrict": [
        {
          "id": 770302,
          "name": "Ang Thong",
          "postCode": [
            "77130"
          ]
        },
        {
          "id": 770305,
          "name": "Huai Yang",
          "postCode": [
            "77130"
          ]
        },
        {
          "id": 770304,
          "name": "Khao Lan",
          "postCode": [
            "77130"
          ]
        },
        {
          "id": 770303,
          "name": "Na Hu Kwang",
          "postCode": [
            "77130"
          ]
        },
        {
          "id": 770306,
          "name": "Saeng Arun",
          "postCode": [
            "77130"
          ]
        },
        {
          "id": 770301,
          "name": "Thap Sakae",
          "postCode": [
            "77130"
          ]
        }
      ]
    },
    {
      "city": "Kapoe",
      "cityCode": 8503,
      "provinceCode": 85,
      "subDistrict": [
        {
          "id": 850304,
          "name": "Ban Na",
          "postCode": [
            "85120"
          ]
        },
        {
          "id": 850305,
          "name": "Bang Hin",
          "postCode": [
            "85120"
          ]
        },
        {
          "id": 850303,
          "name": "Chiao Liang",
          "postCode": [
            "85120"
          ]
        },
        {
          "id": 850302,
          "name": "Kapoe",
          "postCode": [
            "85120"
          ]
        },
        {
          "id": 850301,
          "name": "Muang Kluang",
          "postCode": [
            "85120"
          ]
        }
      ]
    },
    {
      "city": "Kra Buri",
      "cityCode": 8504,
      "provinceCode": 85,
      "subDistrict": [
        {
          "id": 850407,
          "name": "Bang Yai",
          "postCode": [
            "85110"
          ]
        },
        {
          "id": 850406,
          "name": "Choporo",
          "postCode": [
            "85110"
          ]
        },
        {
          "id": 850405,
          "name": "Lam Liang",
          "postCode": [
            "85110"
          ]
        },
        {
          "id": 850403,
          "name": "Mamu",
          "postCode": [
            "85110"
          ]
        },
        {
          "id": 850401,
          "name": "Nam Chuet",
          "postCode": [
            "85110"
          ]
        },
        {
          "id": 850402,
          "name": "Nam Chuet Noi",
          "postCode": [
            "85110"
          ]
        },
        {
          "id": 850404,
          "name": "Pak Chan",
          "postCode": [
            "85110"
          ]
        }
      ]
    },
    {
      "city": "La-un",
      "cityCode": 8502,
      "provinceCode": 85,
      "subDistrict": [
        {
          "id": 850205,
          "name": "Bang Kaeo",
          "postCode": [
            "85130"
          ]
        },
        {
          "id": 850204,
          "name": "Bang Phra Nuea",
          "postCode": [
            "85130"
          ]
        },
        {
          "id": 850203,
          "name": "Bang Phra Tai",
          "postCode": [
            "85130"
          ]
        },
        {
          "id": 850202,
          "name": "La-un Nuea",
          "postCode": [
            "85130"
          ]
        },
        {
          "id": 850201,
          "name": "La-un Tai",
          "postCode": [
            "85130"
          ]
        },
        {
          "id": 850206,
          "name": "Nai Wong Nuea",
          "postCode": [
            "85130"
          ]
        },
        {
          "id": 850207,
          "name": "Nai Wong Tai",
          "postCode": [
            "85130"
          ]
        }
      ]
    },
    {
      "city": "Mueang Ranong",
      "cityCode": 8501,
      "provinceCode": 85,
      "subDistrict": [
        {
          "id": 850106,
          "name": "Bang Non",
          "postCode": [
            "85000"
          ]
        },
        {
          "id": 850104,
          "name": "Bang Rin",
          "postCode": [
            "85000"
          ]
        },
        {
          "id": 850107,
          "name": "Hat Som Paen",
          "postCode": [
            "85000"
          ]
        },
        {
          "id": 850101,
          "name": "Khao Niwet",
          "postCode": [
            "85000"
          ]
        },
        {
          "id": 850109,
          "name": "Ko Phayam",
          "postCode": [
            "85000"
          ]
        },
        {
          "id": 850103,
          "name": "Ngao",
          "postCode": [
            "85000"
          ]
        },
        {
          "id": 850105,
          "name": "Pak Nam",
          "postCode": [
            "85000"
          ]
        },
        {
          "id": 850102,
          "name": "Ratchakrut",
          "postCode": [
            "85000"
          ]
        },
        {
          "id": 850108,
          "name": "Sai Daeng",
          "postCode": [
            "85130"
          ]
        }
      ]
    },
    {
      "city": "Suk Samran",
      "cityCode": 8505,
      "provinceCode": 85,
      "subDistrict": [
        {
          "id": 850502,
          "name": "Kamphuan",
          "postCode": [
            "85120"
          ]
        },
        {
          "id": 850501,
          "name": "Nakha",
          "postCode": [
            "85120"
          ]
        }
      ]
    },
    {
      "city": "Ban Kha",
      "cityCode": 7010,
      "provinceCode": 70,
      "subDistrict": [
        {
          "id": 701002,
          "name": "Ban Bueng",
          "postCode": [
            "70180"
          ]
        },
        {
          "id": 701001,
          "name": "Ban Kha",
          "postCode": [
            "70180"
          ]
        },
        {
          "id": 701003,
          "name": "Nong Phan Chan",
          "postCode": [
            "70180"
          ]
        }
      ]
    },
    {
      "city": "Ban Pong",
      "cityCode": 7005,
      "provinceCode": 70,
      "subDistrict": [
        {
          "id": 700510,
          "name": "Ban Muang",
          "postCode": [
            "70110"
          ]
        },
        {
          "id": 700501,
          "name": "Ban Pong",
          "postCode": [
            "70110"
          ]
        },
        {
          "id": 700514,
          "name": "Boek Phrai",
          "postCode": [
            "70110"
          ]
        },
        {
          "id": 700507,
          "name": "Don Krabueang",
          "postCode": [
            "70110"
          ]
        },
        {
          "id": 700513,
          "name": "Khao Khlung",
          "postCode": [
            "70110"
          ]
        },
        {
          "id": 700511,
          "name": "Khung Phayom",
          "postCode": [
            "70110"
          ]
        },
        {
          "id": 700503,
          "name": "Krap Yai",
          "postCode": [
            "70190"
          ]
        },
        {
          "id": 700515,
          "name": "Lat Bua Khao",
          "postCode": [
            "70110"
          ]
        },
        {
          "id": 700509,
          "name": "Nakhon Chum",
          "postCode": [
            "70110"
          ]
        },
        {
          "id": 700505,
          "name": "Nong Kop",
          "postCode": [
            "70110"
          ]
        },
        {
          "id": 700506,
          "name": "Nong O",
          "postCode": [
            "70110"
          ]
        },
        {
          "id": 700512,
          "name": "Nong Pla Mo",
          "postCode": [
            "70110"
          ]
        },
        {
          "id": 700504,
          "name": "Pak Raet",
          "postCode": [
            "70110"
          ]
        },
        {
          "id": 700508,
          "name": "Suan Kluai",
          "postCode": [
            "70110"
          ]
        },
        {
          "id": 700502,
          "name": "Tha Pha",
          "postCode": [
            "70110"
          ]
        }
      ]
    },
    {
      "city": "Bang Phae",
      "cityCode": 7006,
      "provinceCode": 70,
      "subDistrict": [
        {
          "id": 700601,
          "name": "Bang Phae",
          "postCode": [
            "70160"
          ]
        },
        {
          "id": 700606,
          "name": "Don Kha",
          "postCode": [
            "70160"
          ]
        },
        {
          "id": 700605,
          "name": "Don Yai",
          "postCode": [
            "70160"
          ]
        },
        {
          "id": 700603,
          "name": "Hua Pho",
          "postCode": [
            "70160"
          ]
        },
        {
          "id": 700607,
          "name": "Pho Hak",
          "postCode": [
            "70160"
          ]
        },
        {
          "id": 700602,
          "name": "Wang Yen",
          "postCode": [
            "70160"
          ]
        },
        {
          "id": 700604,
          "name": "Wat Kaeo",
          "postCode": [
            "70160"
          ]
        }
      ]
    },
    {
      "city": "Chom Bueng",
      "cityCode": 7002,
      "provinceCode": 70,
      "subDistrict": [
        {
          "id": 700203,
          "name": "Boek Phrai",
          "postCode": [
            "70150"
          ]
        },
        {
          "id": 700201,
          "name": "Chom Bueng",
          "postCode": [
            "70150"
          ]
        },
        {
          "id": 700204,
          "name": "Dan Thap Tako",
          "postCode": [
            "70150"
          ]
        },
        {
          "id": 700205,
          "name": "Kaem On",
          "postCode": [
            "70150"
          ]
        },
        {
          "id": 700202,
          "name": "Pak Chong",
          "postCode": [
            "70150"
          ]
        },
        {
          "id": 700206,
          "name": "Rang Bua",
          "postCode": [
            "70150"
          ]
        }
      ]
    },
    {
      "city": "Damnoen Saduak",
      "cityCode": 7004,
      "provinceCode": 70,
      "subDistrict": [
        {
          "id": 700408,
          "name": "Ban Rai",
          "postCode": [
            "70130"
          ]
        },
        {
          "id": 700407,
          "name": "Bua Ngam",
          "postCode": [
            "70210"
          ]
        },
        {
          "id": 700401,
          "name": "Damnoen Saduak",
          "postCode": [
            "70130"
          ]
        },
        {
          "id": 700406,
          "name": "Don Khlang",
          "postCode": [
            "70130"
          ]
        },
        {
          "id": 700405,
          "name": "Don Kruai",
          "postCode": [
            "70130"
          ]
        },
        {
          "id": 700413,
          "name": "Don Phai",
          "postCode": [
            "70130"
          ]
        },
        {
          "id": 700412,
          "name": "Khun Phithak",
          "postCode": [
            "70130"
          ]
        },
        {
          "id": 700409,
          "name": "Phaengphuai",
          "postCode": [
            "70130"
          ]
        },
        {
          "id": 700402,
          "name": "Prasat Sit",
          "postCode": [
            "70210"
          ]
        },
        {
          "id": 700410,
          "name": "Si Muen",
          "postCode": [
            "70130"
          ]
        },
        {
          "id": 700403,
          "name": "Si Surat",
          "postCode": [
            "70130"
          ]
        },
        {
          "id": 700404,
          "name": "Ta Luang",
          "postCode": [
            "70130"
          ]
        },
        {
          "id": 700411,
          "name": "Tha Nat",
          "postCode": [
            "70130"
          ]
        }
      ]
    },
    {
      "city": "Mueang Ratchaburi",
      "cityCode": 7001,
      "provinceCode": 70,
      "subDistrict": [
        {
          "id": 700108,
          "name": "Ang Thong",
          "postCode": [
            "70000"
          ]
        },
        {
          "id": 700122,
          "name": "Ban Rai",
          "postCode": [
            "70000"
          ]
        },
        {
          "id": 700118,
          "name": "Bang Pa",
          "postCode": [
            "70000"
          ]
        },
        {
          "id": 700102,
          "name": "Chedi Hak",
          "postCode": [
            "70000"
          ]
        },
        {
          "id": 700113,
          "name": "Don Rae",
          "postCode": [
            "70000"
          ]
        },
        {
          "id": 700103,
          "name": "Don Tako",
          "postCode": [
            "70000"
          ]
        },
        {
          "id": 700114,
          "name": "Hin Kong",
          "postCode": [
            "70000"
          ]
        },
        {
          "id": 700105,
          "name": "Huai Phai",
          "postCode": [
            "70000"
          ]
        },
        {
          "id": 700115,
          "name": "Khao Raeng",
          "postCode": [
            "70000"
          ]
        },
        {
          "id": 700109,
          "name": "Khok Mo",
          "postCode": [
            "70000"
          ]
        },
        {
          "id": 700120,
          "name": "Khu Bua",
          "postCode": [
            "70000"
          ]
        },
        {
          "id": 700107,
          "name": "Khung Krathin",
          "postCode": [
            "70000"
          ]
        },
        {
          "id": 700106,
          "name": "Khung Nam Won",
          "postCode": [
            "70000"
          ]
        },
        {
          "id": 700116,
          "name": "Ko Phlapphla",
          "postCode": [
            "70000"
          ]
        },
        {
          "id": 700117,
          "name": "Lum Din",
          "postCode": [
            "70000"
          ]
        },
        {
          "id": 700101,
          "name": "Na Mueang",
          "postCode": [
            "70000"
          ]
        },
        {
          "id": 700112,
          "name": "Nam Phu",
          "postCode": [
            "70000"
          ]
        },
        {
          "id": 700104,
          "name": "Nong Klang Na",
          "postCode": [
            "70000"
          ]
        },
        {
          "id": 700111,
          "name": "Phikun Thong",
          "postCode": [
            "70000"
          ]
        },
        {
          "id": 700119,
          "name": "Phong Sawai",
          "postCode": [
            "70000"
          ]
        },
        {
          "id": 700110,
          "name": "Sam Ruean",
          "postCode": [
            "70000"
          ]
        },
        {
          "id": 700121,
          "name": "Tha Rap",
          "postCode": [
            "70000"
          ]
        }
      ]
    },
    {
      "city": "Pak Tho",
      "cityCode": 7008,
      "provinceCode": 70,
      "subDistrict": [
        {
          "id": 700808,
          "name": "Ang Hin",
          "postCode": [
            "70140"
          ]
        },
        {
          "id": 700809,
          "name": "Bo Kradan",
          "postCode": [
            "70140"
          ]
        },
        {
          "id": 700803,
          "name": "Don Sai",
          "postCode": [
            "70140"
          ]
        },
        {
          "id": 700812,
          "name": "Huai Yang Thon",
          "postCode": [
            "70140"
          ]
        },
        {
          "id": 700804,
          "name": "Nong Krathum",
          "postCode": [
            "70140"
          ]
        },
        {
          "id": 700806,
          "name": "Pa Kai",
          "postCode": [
            "70140"
          ]
        },
        {
          "id": 700805,
          "name": "Pak Tho",
          "postCode": [
            "70140"
          ]
        },
        {
          "id": 700801,
          "name": "Thung Luang",
          "postCode": [
            "70140"
          ]
        },
        {
          "id": 700811,
          "name": "Wan Dao",
          "postCode": [
            "70140"
          ]
        },
        {
          "id": 700802,
          "name": "Wang Manao",
          "postCode": [
            "70140"
          ]
        },
        {
          "id": 700807,
          "name": "Wat Yang Ngam",
          "postCode": [
            "70140"
          ]
        },
        {
          "id": 700810,
          "name": "Yang Hak",
          "postCode": [
            "70140"
          ]
        }
      ]
    },
    {
      "city": "Photharam",
      "cityCode": 7007,
      "provinceCode": 70,
      "subDistrict": [
        {
          "id": 700706,
          "name": "Ban Khong",
          "postCode": [
            "70120"
          ]
        },
        {
          "id": 700704,
          "name": "Ban Lueak",
          "postCode": [
            "70120"
          ]
        },
        {
          "id": 700707,
          "name": "Ban Sing",
          "postCode": [
            "70120"
          ]
        },
        {
          "id": 700714,
          "name": "Bang Tanot",
          "postCode": [
            "70120"
          ]
        },
        {
          "id": 700711,
          "name": "Chamrae",
          "postCode": [
            "70120"
          ]
        },
        {
          "id": 700709,
          "name": "Chet Samian",
          "postCode": [
            "70120"
          ]
        },
        {
          "id": 700702,
          "name": "Don Krabueang",
          "postCode": [
            "70120"
          ]
        },
        {
          "id": 700708,
          "name": "Don Sai",
          "postCode": [
            "70120"
          ]
        },
        {
          "id": 700718,
          "name": "Khao Cha-ngum",
          "postCode": [
            "70120"
          ]
        },
        {
          "id": 700710,
          "name": "Khlong Khoi",
          "postCode": [
            "70120"
          ]
        },
        {
          "id": 700705,
          "name": "Khlong Ta Khot",
          "postCode": [
            "70120"
          ]
        },
        {
          "id": 700716,
          "name": "Nang Kaeo",
          "postCode": [
            "70120"
          ]
        },
        {
          "id": 700719,
          "name": "Nong Kwang",
          "postCode": [
            "70120"
          ]
        },
        {
          "id": 700703,
          "name": "Nong Pho",
          "postCode": [
            "70120"
          ]
        },
        {
          "id": 700701,
          "name": "Photharam",
          "postCode": [
            "70120"
          ]
        },
        {
          "id": 700712,
          "name": "Soi Fa",
          "postCode": [
            "70120"
          ]
        },
        {
          "id": 700715,
          "name": "Tao Pun",
          "postCode": [
            "70120"
          ]
        },
        {
          "id": 700713,
          "name": "Tha Chumphon",
          "postCode": [
            "70120"
          ]
        },
        {
          "id": 700717,
          "name": "Thammasen",
          "postCode": [
            "70120"
          ]
        }
      ]
    },
    {
      "city": "Suan Phueng",
      "cityCode": 7003,
      "provinceCode": 70,
      "subDistrict": [
        {
          "id": 700302,
          "name": "Pa Wai",
          "postCode": [
            "70180"
          ]
        },
        {
          "id": 700301,
          "name": "Suan Phueng",
          "postCode": [
            "70180"
          ]
        },
        {
          "id": 700307,
          "name": "Tanao Si",
          "postCode": [
            "70180"
          ]
        },
        {
          "id": 700304,
          "name": "Tha Khoei",
          "postCode": [
            "70180"
          ]
        }
      ]
    },
    {
      "city": "Wat Phleng",
      "cityCode": 7009,
      "provinceCode": 70,
      "subDistrict": [
        {
          "id": 700902,
          "name": "Chom Prathat",
          "postCode": [
            "70170"
          ]
        },
        {
          "id": 700901,
          "name": "Ko San Phra",
          "postCode": [
            "70170"
          ]
        },
        {
          "id": 700903,
          "name": "Wat Phleng",
          "postCode": [
            "70170"
          ]
        }
      ]
    },
    {
      "city": "Ban Chang",
      "cityCode": 2102,
      "provinceCode": 21,
      "subDistrict": [
        {
          "id": 210203,
          "name": "Ban Chang",
          "postCode": [
            "21130"
          ]
        },
        {
          "id": 210202,
          "name": "Phala",
          "postCode": [
            "21130"
          ]
        },
        {
          "id": 210201,
          "name": "Samnak Thon",
          "postCode": [
            "21130"
          ]
        }
      ]
    },
    {
      "city": "Ban Khai",
      "cityCode": 2105,
      "provinceCode": 21,
      "subDistrict": [
        {
          "id": 210501,
          "name": "Ban Khai",
          "postCode": [
            "21120"
          ]
        },
        {
          "id": 210505,
          "name": "Bang But",
          "postCode": [
            "21120"
          ]
        },
        {
          "id": 210507,
          "name": "Chak Bok",
          "postCode": [
            "21120"
          ]
        },
        {
          "id": 210506,
          "name": "Nong Bua",
          "postCode": [
            "21120"
          ]
        },
        {
          "id": 210502,
          "name": "Nong Lalok",
          "postCode": [
            "21120"
          ]
        },
        {
          "id": 210503,
          "name": "Nong Taphan",
          "postCode": [
            "21120"
          ]
        },
        {
          "id": 210504,
          "name": "Ta Khan",
          "postCode": [
            "21120"
          ]
        }
      ]
    },
    {
      "city": "Khao Chamao",
      "cityCode": 2107,
      "provinceCode": 21,
      "subDistrict": [
        {
          "id": 210703,
          "name": "Cham Kho",
          "postCode": [
            "21110"
          ]
        },
        {
          "id": 210702,
          "name": "Huai Thap Mon",
          "postCode": [
            "21110"
          ]
        },
        {
          "id": 210704,
          "name": "Khao Noi",
          "postCode": [
            "21110"
          ]
        },
        {
          "id": 210701,
          "name": "Nam Pen",
          "postCode": [
            "21110"
          ]
        }
      ]
    },
    {
      "city": "Klaeng",
      "cityCode": 2103,
      "provinceCode": 21,
      "subDistrict": [
        {
          "id": 210308,
          "name": "Ban Na",
          "postCode": [
            "21110"
          ]
        },
        {
          "id": 210303,
          "name": "Chak Don",
          "postCode": [
            "21110"
          ]
        },
        {
          "id": 210306,
          "name": "Chak Phong",
          "postCode": [
            "21190"
          ]
        },
        {
          "id": 210317,
          "name": "Huai Yang",
          "postCode": [
            "21110"
          ]
        },
        {
          "id": 210311,
          "name": "Khlong Pun",
          "postCode": [
            "21170"
          ]
        },
        {
          "id": 210310,
          "name": "Kong Din",
          "postCode": [
            "22160"
          ]
        },
        {
          "id": 210305,
          "name": "Kram",
          "postCode": [
            "21190"
          ]
        },
        {
          "id": 210307,
          "name": "Krasae Bon",
          "postCode": [
            "21110"
          ]
        },
        {
          "id": 210304,
          "name": "Noen Kho",
          "postCode": [
            "21110"
          ]
        },
        {
          "id": 210313,
          "name": "Pak Nam Krasae",
          "postCode": [
            "21170"
          ]
        },
        {
          "id": 210312,
          "name": "Phang Rat",
          "postCode": [
            "21170"
          ]
        },
        {
          "id": 210318,
          "name": "Song Salueng",
          "postCode": [
            "21110"
          ]
        },
        {
          "id": 210301,
          "name": "Thang Kwian",
          "postCode": [
            "21110"
          ]
        },
        {
          "id": 210309,
          "name": "Thung Khwai Kin",
          "postCode": [
            "21110"
          ]
        },
        {
          "id": 210302,
          "name": "Wang Wa",
          "postCode": [
            "21110"
          ]
        }
      ]
    },
    {
      "city": "Mueang Rayong",
      "cityCode": 2101,
      "provinceCode": 21,
      "subDistrict": [
        {
          "id": 210107,
          "name": "Ban Laeng",
          "postCode": [
            "21000"
          ]
        },
        {
          "id": 210102,
          "name": "Choeng Noen",
          "postCode": [
            "21000"
          ]
        },
        {
          "id": 210113,
          "name": "Huai Pong",
          "postCode": [
            "21150"
          ]
        },
        {
          "id": 210110,
          "name": "Kachet",
          "postCode": [
            "21100"
          ]
        },
        {
          "id": 210106,
          "name": "Klaeng",
          "postCode": [
            "21160"
          ]
        },
        {
          "id": 210114,
          "name": "Map Ta Phut",
          "postCode": [
            "21150"
          ]
        },
        {
          "id": 210108,
          "name": "Na Ta Khwan",
          "postCode": [
            "21000"
          ]
        },
        {
          "id": 210112,
          "name": "Nam Khok",
          "postCode": [
            "21000"
          ]
        },
        {
          "id": 210109,
          "name": "Noen Phra",
          "postCode": [
            "21000",
            "21150"
          ]
        },
        {
          "id": 210104,
          "name": "Pak Nam",
          "postCode": [
            "21000"
          ]
        },
        {
          "id": 210105,
          "name": "Phe",
          "postCode": [
            "21160"
          ]
        },
        {
          "id": 210115,
          "name": "Samnak Thong",
          "postCode": [
            "21100"
          ]
        },
        {
          "id": 210103,
          "name": "Taphong",
          "postCode": [
            "21000"
          ]
        },
        {
          "id": 210101,
          "name": "Tha Pradu",
          "postCode": [
            "21000"
          ]
        },
        {
          "id": 210111,
          "name": "Thap Ma",
          "postCode": [
            "21000"
          ]
        }
      ]
    },
    {
      "city": "Nikhom Phatthana",
      "cityCode": 2108,
      "provinceCode": 21,
      "subDistrict": [
        {
          "id": 210804,
          "name": "Makham Khu",
          "postCode": [
            "21180"
          ]
        },
        {
          "id": 210802,
          "name": "Map Kha",
          "postCode": [
            "21180"
          ]
        },
        {
          "id": 210801,
          "name": "Nikhom Phatthana",
          "postCode": [
            "21180"
          ]
        },
        {
          "id": 210803,
          "name": "Phana Nikhom",
          "postCode": [
            "21180"
          ]
        }
      ]
    },
    {
      "city": "Pluak Daeng",
      "cityCode": 2106,
      "provinceCode": 21,
      "subDistrict": [
        {
          "id": 210603,
          "name": "Lahan",
          "postCode": [
            "21140"
          ]
        },
        {
          "id": 210604,
          "name": "Maenam Khu",
          "postCode": [
            "21140"
          ]
        },
        {
          "id": 210605,
          "name": "Map Yang Phon",
          "postCode": [
            "21140"
          ]
        },
        {
          "id": 210606,
          "name": "Nong Rai",
          "postCode": [
            "21140"
          ]
        },
        {
          "id": 210601,
          "name": "Pluak Daeng",
          "postCode": [
            "21140"
          ]
        },
        {
          "id": 210602,
          "name": "Ta Sit",
          "postCode": [
            "21140"
          ]
        }
      ]
    },
    {
      "city": "Wang Chan",
      "cityCode": 2104,
      "provinceCode": 21,
      "subDistrict": [
        {
          "id": 210402,
          "name": "Chum Saeng",
          "postCode": [
            "21210"
          ]
        },
        {
          "id": 210403,
          "name": "Pa Yup Nai",
          "postCode": [
            "21210"
          ]
        },
        {
          "id": 210404,
          "name": "Phlong Ta Iam",
          "postCode": [
            "21210"
          ]
        },
        {
          "id": 210401,
          "name": "Wang Chan",
          "postCode": [
            "21210"
          ]
        }
      ]
    },
    {
      "city": "At Samat",
      "cityCode": 4514,
      "provinceCode": 45,
      "subDistrict": [
        {
          "id": 451401,
          "name": "At Samat",
          "postCode": [
            "45160"
          ]
        },
        {
          "id": 451403,
          "name": "Ban Chaeng",
          "postCode": [
            "45160"
          ]
        },
        {
          "id": 451410,
          "name": "Ban Du",
          "postCode": [
            "45160"
          ]
        },
        {
          "id": 451407,
          "name": "Hora",
          "postCode": [
            "45160"
          ]
        },
        {
          "id": 451409,
          "name": "Khilek",
          "postCode": [
            "45160"
          ]
        },
        {
          "id": 451404,
          "name": "Nom",
          "postCode": [
            "45160"
          ]
        },
        {
          "id": 451408,
          "name": "Nong Bua",
          "postCode": [
            "45160"
          ]
        },
        {
          "id": 451406,
          "name": "Nong Kham",
          "postCode": [
            "45160"
          ]
        },
        {
          "id": 451405,
          "name": "Nong Muen Than",
          "postCode": [
            "45160"
          ]
        },
        {
          "id": 451402,
          "name": "Phon Mueang",
          "postCode": [
            "45160"
          ]
        }
      ]
    },
    {
      "city": "Changhan",
      "cityCode": 4517,
      "provinceCode": 45,
      "subDistrict": [
        {
          "id": 451704,
          "name": "Changhan",
          "postCode": [
            "45000"
          ]
        },
        {
          "id": 451701,
          "name": "Din Dam",
          "postCode": [
            "45000"
          ]
        },
        {
          "id": 451705,
          "name": "Dong Sing",
          "postCode": [
            "45000"
          ]
        },
        {
          "id": 451703,
          "name": "Muang Lat",
          "postCode": [
            "45000"
          ]
        },
        {
          "id": 451702,
          "name": "Pa Fa",
          "postCode": [
            "45000"
          ]
        },
        {
          "id": 451707,
          "name": "Phak Waen",
          "postCode": [
            "45000"
          ]
        },
        {
          "id": 451708,
          "name": "Saen Chat",
          "postCode": [
            "45000"
          ]
        },
        {
          "id": 451706,
          "name": "Yang Yai",
          "postCode": [
            "45000"
          ]
        }
      ]
    },
    {
      "city": "Chaturaphak Phiman",
      "cityCode": 4504,
      "provinceCode": 45,
      "subDistrict": [
        {
          "id": 450406,
          "name": "Dong Daeng",
          "postCode": [
            "45180"
          ]
        },
        {
          "id": 450407,
          "name": "Dong Klang",
          "postCode": [
            "45180"
          ]
        },
        {
          "id": 450411,
          "name": "Du Noi",
          "postCode": [
            "45180"
          ]
        },
        {
          "id": 450401,
          "name": "Hua Chang",
          "postCode": [
            "45180"
          ]
        },
        {
          "id": 450409,
          "name": "I Ngong",
          "postCode": [
            "45180"
          ]
        },
        {
          "id": 450404,
          "name": "Khok Lam",
          "postCode": [
            "45180"
          ]
        },
        {
          "id": 450410,
          "name": "Lin Fa",
          "postCode": [
            "45180"
          ]
        },
        {
          "id": 450403,
          "name": "Mueang Hong",
          "postCode": [
            "45180"
          ]
        },
        {
          "id": 450405,
          "name": "Nam Sai",
          "postCode": [
            "45180"
          ]
        },
        {
          "id": 450402,
          "name": "Nong Phue",
          "postCode": [
            "45180"
          ]
        },
        {
          "id": 450408,
          "name": "Pa Sang",
          "postCode": [
            "45180"
          ]
        },
        {
          "id": 450412,
          "name": "Si Khot",
          "postCode": [
            "45180"
          ]
        }
      ]
    },
    {
      "city": "Chiang Khwan",
      "cityCode": 4518,
      "provinceCode": 45,
      "subDistrict": [
        {
          "id": 451806,
          "name": "Ban Khueang",
          "postCode": [
            "45000"
          ]
        },
        {
          "id": 451801,
          "name": "Chiang Khwan",
          "postCode": [
            "45000"
          ]
        },
        {
          "id": 451805,
          "name": "Mu Mon",
          "postCode": [
            "45170"
          ]
        },
        {
          "id": 451802,
          "name": "Phlapphla",
          "postCode": [
            "45170"
          ]
        },
        {
          "id": 451804,
          "name": "Phra Chao",
          "postCode": [
            "45000"
          ]
        },
        {
          "id": 451803,
          "name": "Phra That",
          "postCode": [
            "45000"
          ]
        }
      ]
    },
    {
      "city": "Kaset Wisai",
      "cityCode": 4502,
      "provinceCode": 45,
      "subDistrict": [
        {
          "id": 450206,
          "name": "Ban Fang",
          "postCode": [
            "45150"
          ]
        },
        {
          "id": 450213,
          "name": "Dong Khrang Noi",
          "postCode": [
            "45150"
          ]
        },
        {
          "id": 450205,
          "name": "Dong Khrang Yai",
          "postCode": [
            "45150"
          ]
        },
        {
          "id": 450208,
          "name": "Kamphaeng",
          "postCode": [
            "45150"
          ]
        },
        {
          "id": 450201,
          "name": "Kaset Wisai",
          "postCode": [
            "45150"
          ]
        },
        {
          "id": 450209,
          "name": "Ku Ka Sing",
          "postCode": [
            "45150"
          ]
        },
        {
          "id": 450203,
          "name": "Lao Luang",
          "postCode": [
            "45150"
          ]
        },
        {
          "id": 450202,
          "name": "Mueang Bua",
          "postCode": [
            "45150"
          ]
        },
        {
          "id": 450210,
          "name": "Nam Om",
          "postCode": [
            "45150"
          ]
        },
        {
          "id": 450211,
          "name": "Non Sawang",
          "postCode": [
            "45150"
          ]
        },
        {
          "id": 450207,
          "name": "Nong Waeng",
          "postCode": [
            "45150"
          ]
        },
        {
          "id": 450204,
          "name": "Sing Khok",
          "postCode": [
            "45150"
          ]
        },
        {
          "id": 450212,
          "name": "Thung Thong",
          "postCode": [
            "45150"
          ]
        }
      ]
    },
    {
      "city": "Moei Wadi",
      "cityCode": 4515,
      "provinceCode": 45,
      "subDistrict": [
        {
          "id": 451503,
          "name": "Bung Loet",
          "postCode": [
            "45250"
          ]
        },
        {
          "id": 451504,
          "name": "Chom Sa-at",
          "postCode": [
            "45250"
          ]
        },
        {
          "id": 451502,
          "name": "Chumphon",
          "postCode": [
            "45250"
          ]
        },
        {
          "id": 451501,
          "name": "Moei Wadi",
          "postCode": [
            "45250"
          ]
        }
      ]
    },
    {
      "city": "Mueang Roi Et",
      "cityCode": 4501,
      "provinceCode": 45,
      "subDistrict": [
        {
          "id": 450120,
          "name": "Dong Lan",
          "postCode": [
            "45000"
          ]
        },
        {
          "id": 450123,
          "name": "Khaen Yai",
          "postCode": [
            "45000"
          ]
        },
        {
          "id": 450104,
          "name": "Khon Kaen",
          "postCode": [
            "45000"
          ]
        },
        {
          "id": 450125,
          "name": "Mueang Thong",
          "postCode": [
            "45000"
          ]
        },
        {
          "id": 450105,
          "name": "Na Pho",
          "postCode": [
            "45000"
          ]
        },
        {
          "id": 450101,
          "name": "Nai Mueang",
          "postCode": [
            "45000"
          ]
        },
        {
          "id": 450110,
          "name": "Non Rang",
          "postCode": [
            "45000"
          ]
        },
        {
          "id": 450124,
          "name": "Non Tan",
          "postCode": [
            "45000"
          ]
        },
        {
          "id": 450117,
          "name": "Nong Kaeo",
          "postCode": [
            "45000"
          ]
        },
        {
          "id": 450118,
          "name": "Nong Waeng",
          "postCode": [
            "45000"
          ]
        },
        {
          "id": 450103,
          "name": "Nuea Mueang",
          "postCode": [
            "45000"
          ]
        },
        {
          "id": 450109,
          "name": "Po Phan",
          "postCode": [
            "45000"
          ]
        },
        {
          "id": 450102,
          "name": "Rop Mueang",
          "postCode": [
            "45000"
          ]
        },
        {
          "id": 450106,
          "name": "Sa-at Sombun",
          "postCode": [
            "45000"
          ]
        },
        {
          "id": 450108,
          "name": "Si Kaeo",
          "postCode": [
            "45000"
          ]
        }
      ]
    },
    {
      "city": "Mueang Suang",
      "cityCode": 4512,
      "provinceCode": 45,
      "subDistrict": [
        {
          "id": 451203,
          "name": "Khu Mueang",
          "postCode": [
            "45220"
          ]
        },
        {
          "id": 451204,
          "name": "Kok Kung",
          "postCode": [
            "45220"
          ]
        },
        {
          "id": 451205,
          "name": "Mueang Suang",
          "postCode": [
            "45220"
          ]
        },
        {
          "id": 451202,
          "name": "Nong Hin",
          "postCode": [
            "45220"
          ]
        },
        {
          "id": 451201,
          "name": "Nong Phue",
          "postCode": [
            "45220"
          ]
        }
      ]
    },
    {
      "city": "Nong Hi",
      "cityCode": 4519,
      "provinceCode": 45,
      "subDistrict": [
        {
          "id": 451904,
          "name": "Den Rat",
          "postCode": [
            "45140"
          ]
        },
        {
          "id": 451903,
          "name": "Duk Ueng",
          "postCode": [
            "45140"
          ]
        },
        {
          "id": 451901,
          "name": "Nong Hi",
          "postCode": [
            "45140"
          ]
        },
        {
          "id": 451902,
          "name": "Sao Hae",
          "postCode": [
            "45140"
          ]
        }
      ]
    },
    {
      "city": "Nong Phok",
      "cityCode": 4509,
      "provinceCode": 45,
      "subDistrict": [
        {
          "id": 450902,
          "name": "Bueng Ngam",
          "postCode": [
            "45210"
          ]
        },
        {
          "id": 450905,
          "name": "Khok Sawang",
          "postCode": [
            "45210"
          ]
        },
        {
          "id": 450904,
          "name": "Kok Pho",
          "postCode": [
            "45210"
          ]
        },
        {
          "id": 450906,
          "name": "Nong Khun Yai",
          "postCode": [
            "45210"
          ]
        },
        {
          "id": 450901,
          "name": "Nong Phok",
          "postCode": [
            "45210"
          ]
        },
        {
          "id": 450908,
          "name": "Pha Nam Yoi",
          "postCode": [
            "45210"
          ]
        },
        {
          "id": 450903,
          "name": "Phukhao Thong",
          "postCode": [
            "45210"
          ]
        },
        {
          "id": 450907,
          "name": "Rop Mueang",
          "postCode": [
            "45210"
          ]
        },
        {
          "id": 450909,
          "name": "Tha Sida",
          "postCode": [
            "45210"
          ]
        }
      ]
    },
    {
      "city": "Pathum Rat",
      "cityCode": 4503,
      "provinceCode": 45,
      "subDistrict": [
        {
          "id": 450301,
          "name": "Bua Daeng",
          "postCode": [
            "45190"
          ]
        },
        {
          "id": 450302,
          "name": "Dok Lam",
          "postCode": [
            "45190"
          ]
        },
        {
          "id": 450308,
          "name": "Khilek",
          "postCode": [
            "45190"
          ]
        },
        {
          "id": 450307,
          "name": "Non Sa-nga",
          "postCode": [
            "45190"
          ]
        },
        {
          "id": 450305,
          "name": "Non Sawan",
          "postCode": [
            "45190"
          ]
        },
        {
          "id": 450303,
          "name": "Nong Khaen",
          "postCode": [
            "45190"
          ]
        },
        {
          "id": 450304,
          "name": "Phon Sung",
          "postCode": [
            "45190"
          ]
        },
        {
          "id": 450306,
          "name": "Sa Bua",
          "postCode": [
            "45190"
          ]
        }
      ]
    },
    {
      "city": "Phanom Phrai",
      "cityCode": 4506,
      "provinceCode": 45,
      "subDistrict": [
        {
          "id": 450617,
          "name": "Chanuwan",
          "postCode": [
            "45140"
          ]
        },
        {
          "id": 450613,
          "name": "Kham Hai",
          "postCode": [
            "45140"
          ]
        },
        {
          "id": 450615,
          "name": "Kho Yai",
          "postCode": [
            "45140"
          ]
        },
        {
          "id": 450607,
          "name": "Khok Sawang",
          "postCode": [
            "45140"
          ]
        },
        {
          "id": 450603,
          "name": "Kut Nam Sai",
          "postCode": [
            "45140"
          ]
        },
        {
          "id": 450612,
          "name": "Na Nuan",
          "postCode": [
            "45140"
          ]
        },
        {
          "id": 450604,
          "name": "Nong Thap Thai",
          "postCode": [
            "45140"
          ]
        },
        {
          "id": 450601,
          "name": "Phanom Phrai",
          "postCode": [
            "45140"
          ]
        },
        {
          "id": 450611,
          "name": "Pho Chai",
          "postCode": [
            "45140"
          ]
        },
        {
          "id": 450605,
          "name": "Pho Yai",
          "postCode": [
            "45140"
          ]
        },
        {
          "id": 450614,
          "name": "Sa Kaeo",
          "postCode": [
            "45140"
          ]
        },
        {
          "id": 450602,
          "name": "Saen Suk",
          "postCode": [
            "45140"
          ]
        },
        {
          "id": 450606,
          "name": "Wari Sawat",
          "postCode": [
            "45140"
          ]
        }
      ]
    },
    {
      "city": "Pho Chai",
      "cityCode": 4508,
      "provinceCode": 45,
      "subDistrict": [
        {
          "id": 450804,
          "name": "Akkha Kham",
          "postCode": [
            "45230"
          ]
        },
        {
          "id": 450803,
          "name": "Bua Kham",
          "postCode": [
            "45230"
          ]
        },
        {
          "id": 450802,
          "name": "Chiang Mai",
          "postCode": [
            "45230"
          ]
        },
        {
          "id": 450808,
          "name": "Don Ong",
          "postCode": [
            "45230"
          ]
        },
        {
          "id": 450806,
          "name": "Kham Pha-ung",
          "postCode": [
            "45230"
          ]
        },
        {
          "id": 450801,
          "name": "Kham Pia",
          "postCode": [
            "45230"
          ]
        },
        {
          "id": 450807,
          "name": "Nong Ta Kai",
          "postCode": [
            "45230"
          ]
        },
        {
          "id": 450809,
          "name": "Pho Si",
          "postCode": [
            "45230"
          ]
        },
        {
          "id": 450805,
          "name": "Sa-at",
          "postCode": [
            "45230"
          ]
        }
      ]
    },
    {
      "city": "Phon Sai",
      "cityCode": 4513,
      "provinceCode": 45,
      "subDistrict": [
        {
          "id": 451301,
          "name": "Phon Sai",
          "postCode": [
            "45240"
          ]
        },
        {
          "id": 451302,
          "name": "Sam Kha",
          "postCode": [
            "45240"
          ]
        },
        {
          "id": 451303,
          "name": "Si Sawang",
          "postCode": [
            "45240"
          ]
        },
        {
          "id": 451305,
          "name": "Tha Hat Yao",
          "postCode": [
            "45240"
          ]
        },
        {
          "id": 451304,
          "name": "Yang Kham",
          "postCode": [
            "45240"
          ]
        }
      ]
    },
    {
      "city": "Phon Thong",
      "cityCode": 4507,
      "provinceCode": 45,
      "subDistrict": [
        {
          "id": 450702,
          "name": "Khok Kok Muang",
          "postCode": [
            "45110"
          ]
        },
        {
          "id": 450714,
          "name": "Khok Sung",
          "postCode": [
            "45110"
          ]
        },
        {
          "id": 450710,
          "name": "Na Di",
          "postCode": [
            "45110"
          ]
        },
        {
          "id": 450703,
          "name": "Na Udom",
          "postCode": [
            "45110"
          ]
        },
        {
          "id": 450707,
          "name": "Non Chai Si",
          "postCode": [
            "45110"
          ]
        },
        {
          "id": 450705,
          "name": "Nong Yai",
          "postCode": [
            "45110"
          ]
        },
        {
          "id": 450708,
          "name": "Pho Si Sawang",
          "postCode": [
            "45110"
          ]
        },
        {
          "id": 450706,
          "name": "Pho Thong",
          "postCode": [
            "45110"
          ]
        },
        {
          "id": 450711,
          "name": "Phrom Sawan",
          "postCode": [
            "45110"
          ]
        },
        {
          "id": 450712,
          "name": "Sa Nok Kaeo",
          "postCode": [
            "45110"
          ]
        },
        {
          "id": 450704,
          "name": "Sawang",
          "postCode": [
            "45110"
          ]
        },
        {
          "id": 450709,
          "name": "Um Mao",
          "postCode": [
            "45110"
          ]
        },
        {
          "id": 450701,
          "name": "Waeng",
          "postCode": [
            "45110"
          ]
        },
        {
          "id": 450713,
          "name": "Wang Samakkhi",
          "postCode": [
            "45110"
          ]
        }
      ]
    },
    {
      "city": "Selaphum",
      "cityCode": 4510,
      "provinceCode": 45,
      "subDistrict": [
        {
          "id": 451018,
          "name": "Bueng Kluea",
          "postCode": [
            "45120"
          ]
        },
        {
          "id": 451017,
          "name": "Khwan Mueang",
          "postCode": [
            "45120"
          ]
        },
        {
          "id": 451008,
          "name": "Khwao",
          "postCode": [
            "45120"
          ]
        },
        {
          "id": 451001,
          "name": "Klang",
          "postCode": [
            "45120"
          ]
        },
        {
          "id": 451011,
          "name": "Ko Kaeo",
          "postCode": [
            "45120"
          ]
        },
        {
          "id": 451013,
          "name": "Lao Noi",
          "postCode": [
            "45120"
          ]
        },
        {
          "id": 451003,
          "name": "Mueang Phrai",
          "postCode": [
            "45120"
          ]
        },
        {
          "id": 451012,
          "name": "Na Loeng",
          "postCode": [
            "45120"
          ]
        },
        {
          "id": 451005,
          "name": "Na Mueang",
          "postCode": [
            "45120"
          ]
        },
        {
          "id": 451002,
          "name": "Na Ngam",
          "postCode": [
            "45120"
          ]
        },
        {
          "id": 451004,
          "name": "Na Saeng",
          "postCode": [
            "45120"
          ]
        },
        {
          "id": 451015,
          "name": "Nong Luang",
          "postCode": [
            "45120"
          ]
        },
        {
          "id": 451009,
          "name": "Pho Thong",
          "postCode": [
            "45120"
          ]
        },
        {
          "id": 451016,
          "name": "Phon Sawan",
          "postCode": [
            "45120"
          ]
        },
        {
          "id": 451010,
          "name": "Phu Ngoen",
          "postCode": [
            "45120"
          ]
        },
        {
          "id": 451014,
          "name": "Si Wilai",
          "postCode": [
            "45120"
          ]
        },
        {
          "id": 451007,
          "name": "Tha Muang",
          "postCode": [
            "45120"
          ]
        },
        {
          "id": 451006,
          "name": "wang Luang",
          "postCode": [
            "45120"
          ]
        }
      ]
    },
    {
      "city": "Si Somdet",
      "cityCode": 4516,
      "provinceCode": 45,
      "subDistrict": [
        {
          "id": 451608,
          "name": "Ban Bak",
          "postCode": [
            "45000"
          ]
        },
        {
          "id": 451603,
          "name": "Mueang Plueai",
          "postCode": [
            "45000"
          ]
        },
        {
          "id": 451607,
          "name": "Nong Waeng Khuang",
          "postCode": [
            "45000"
          ]
        },
        {
          "id": 451604,
          "name": "Nong Yai",
          "postCode": [
            "45000"
          ]
        },
        {
          "id": 451606,
          "name": "Pho Sai",
          "postCode": [
            "45280"
          ]
        },
        {
          "id": 451601,
          "name": "Pho Thong",
          "postCode": [
            "45000"
          ]
        },
        {
          "id": 451602,
          "name": "Si Somdet",
          "postCode": [
            "45000"
          ]
        },
        {
          "id": 451605,
          "name": "Suan Chik",
          "postCode": [
            "45280"
          ]
        }
      ]
    },
    {
      "city": "Suwannaphum",
      "cityCode": 4511,
      "provinceCode": 45,
      "subDistrict": [
        {
          "id": 451107,
          "name": "Bo Phan Khan",
          "postCode": [
            "45130"
          ]
        },
        {
          "id": 451115,
          "name": "Champa Khan",
          "postCode": [
            "45130"
          ]
        },
        {
          "id": 451112,
          "name": "Chang Phueak",
          "postCode": [
            "45130"
          ]
        },
        {
          "id": 451102,
          "name": "Dok Mai",
          "postCode": [
            "45130"
          ]
        },
        {
          "id": 451104,
          "name": "Hin Kong",
          "postCode": [
            "45130"
          ]
        },
        {
          "id": 451109,
          "name": "Hua Chang",
          "postCode": [
            "45130"
          ]
        },
        {
          "id": 451106,
          "name": "Hua Thon",
          "postCode": [
            "45130"
          ]
        },
        {
          "id": 451111,
          "name": "Huai Hin Lat",
          "postCode": [
            "45130"
          ]
        },
        {
          "id": 451105,
          "name": "Mueang Thung",
          "postCode": [
            "45130"
          ]
        },
        {
          "id": 451103,
          "name": "Na Yai",
          "postCode": [
            "45130"
          ]
        },
        {
          "id": 451110,
          "name": "Nam Kham",
          "postCode": [
            "45130"
          ]
        },
        {
          "id": 451101,
          "name": "Sa Khu",
          "postCode": [
            "45130"
          ]
        },
        {
          "id": 451113,
          "name": "Thung Kula",
          "postCode": [
            "45130"
          ]
        },
        {
          "id": 451108,
          "name": "Thung Luang",
          "postCode": [
            "45130"
          ]
        },
        {
          "id": 451114,
          "name": "Thung Si Mueang",
          "postCode": [
            "45130"
          ]
        }
      ]
    },
    {
      "city": "Thawat Buri",
      "cityCode": 4505,
      "provinceCode": 45,
      "subDistrict": [
        {
          "id": 450520,
          "name": "Bueng Nakhon",
          "postCode": [
            "45170"
          ]
        },
        {
          "id": 450510,
          "name": "Khwao Thung",
          "postCode": [
            "45170"
          ]
        },
        {
          "id": 450507,
          "name": "Ma-ue",
          "postCode": [
            "45170"
          ]
        },
        {
          "id": 450517,
          "name": "Mueang Noi",
          "postCode": [
            "45170"
          ]
        },
        {
          "id": 450501,
          "name": "Niwet",
          "postCode": [
            "45170"
          ]
        },
        {
          "id": 450503,
          "name": "Nong Phai",
          "postCode": [
            "45170"
          ]
        },
        {
          "id": 450524,
          "name": "Nong Phok",
          "postCode": [
            "45170"
          ]
        },
        {
          "id": 450515,
          "name": "Phaisan",
          "postCode": [
            "45170"
          ]
        },
        {
          "id": 450522,
          "name": "Ratchathani",
          "postCode": [
            "45170"
          ]
        },
        {
          "id": 450504,
          "name": "Thawat Buri",
          "postCode": [
            "45170"
          ]
        },
        {
          "id": 450502,
          "name": "Thong Thani",
          "postCode": [
            "45170"
          ]
        },
        {
          "id": 450506,
          "name": "Um Mao",
          "postCode": [
            "45170"
          ]
        }
      ]
    },
    {
      "city": "Thung Khao Luang",
      "cityCode": 4520,
      "provinceCode": 45,
      "subDistrict": [
        {
          "id": 452003,
          "name": "Bueng Ngam",
          "postCode": [
            "45170"
          ]
        },
        {
          "id": 452005,
          "name": "Lao",
          "postCode": [
            "45170"
          ]
        },
        {
          "id": 452004,
          "name": "Maba",
          "postCode": [
            "45170"
          ]
        },
        {
          "id": 452002,
          "name": "Thoet Thai",
          "postCode": [
            "45170"
          ]
        },
        {
          "id": 452001,
          "name": "Thung Khao Luang",
          "postCode": [
            "45170"
          ]
        }
      ]
    },
    {
      "city": "Aranyaprathet",
      "cityCode": 2706,
      "provinceCode": 27,
      "subDistrict": [
        {
          "id": 270601,
          "name": "Aranyaprathet",
          "postCode": [
            "27120"
          ]
        },
        {
          "id": 270613,
          "name": "Ban Dan",
          "postCode": [
            "27120"
          ]
        },
        {
          "id": 270608,
          "name": "Ban Mai Nong Sai",
          "postCode": [
            "27120"
          ]
        },
        {
          "id": 270612,
          "name": "Fak Huai",
          "postCode": [
            "27120"
          ]
        },
        {
          "id": 270603,
          "name": "Han Sai",
          "postCode": [
            "27120"
          ]
        },
        {
          "id": 270604,
          "name": "Khlong Nam Sai",
          "postCode": [
            "27120"
          ]
        },
        {
          "id": 270611,
          "name": "Khlong Thap Chan",
          "postCode": [
            "27120"
          ]
        },
        {
          "id": 270602,
          "name": "Mueang Phai",
          "postCode": [
            "27120"
          ]
        },
        {
          "id": 270610,
          "name": "Nong Sang",
          "postCode": [
            "27120"
          ]
        },
        {
          "id": 270606,
          "name": "Pa Rai",
          "postCode": [
            "27120"
          ]
        },
        {
          "id": 270609,
          "name": "Phan Suek",
          "postCode": [
            "27120"
          ]
        },
        {
          "id": 270605,
          "name": "Tha Kham",
          "postCode": [
            "27120"
          ]
        },
        {
          "id": 270607,
          "name": "Thap Phrik",
          "postCode": [
            "27120"
          ]
        }
      ]
    },
    {
      "city": "Khao Chakan",
      "cityCode": 2707,
      "provinceCode": 27,
      "subDistrict": [
        {
          "id": 270701,
          "name": "Khao Chakan",
          "postCode": [
            "27000"
          ]
        },
        {
          "id": 270704,
          "name": "Khao Sam Sip",
          "postCode": [
            "27000"
          ]
        },
        {
          "id": 270702,
          "name": "Nong Wa",
          "postCode": [
            "27000"
          ]
        },
        {
          "id": 270703,
          "name": "Phra Phloeng",
          "postCode": [
            "27000"
          ]
        }
      ]
    },
    {
      "city": "Khlong Hat",
      "cityCode": 2702,
      "provinceCode": 27,
      "subDistrict": [
        {
          "id": 270206,
          "name": "Benchakhon",
          "postCode": [
            "27260"
          ]
        },
        {
          "id": 270205,
          "name": "Khlogn Kai Thuean",
          "postCode": [
            "27260"
          ]
        },
        {
          "id": 270201,
          "name": "Khlong Hat",
          "postCode": [
            "27260"
          ]
        },
        {
          "id": 270204,
          "name": "Sai Diao",
          "postCode": [
            "27260"
          ]
        },
        {
          "id": 270207,
          "name": "Sai Thong",
          "postCode": [
            "27260"
          ]
        },
        {
          "id": 270203,
          "name": "Sap Makrut",
          "postCode": [
            "27260"
          ]
        },
        {
          "id": 270202,
          "name": "Thai Udom",
          "postCode": [
            "27260"
          ]
        }
      ]
    },
    {
      "city": "Khok Sung",
      "cityCode": 2708,
      "provinceCode": 27,
      "subDistrict": [
        {
          "id": 270801,
          "name": "Khok Sung",
          "postCode": [
            "27120"
          ]
        },
        {
          "id": 270804,
          "name": "Non Mak Mun",
          "postCode": [
            "27120"
          ]
        },
        {
          "id": 270802,
          "name": "Nong Muang",
          "postCode": [
            "27120"
          ]
        },
        {
          "id": 270803,
          "name": "Nong Waeng",
          "postCode": [
            "27120"
          ]
        }
      ]
    },
    {
      "city": "Mueang Sa Kaeo",
      "cityCode": 2701,
      "provinceCode": 27,
      "subDistrict": [
        {
          "id": 270102,
          "name": "Ban Kaeng",
          "postCode": [
            "27000"
          ]
        },
        {
          "id": 270104,
          "name": "Khok Pi Khong",
          "postCode": [
            "27000"
          ]
        },
        {
          "id": 270111,
          "name": "Nong Bon",
          "postCode": [
            "27000"
          ]
        },
        {
          "id": 270101,
          "name": "Sa Kaeo",
          "postCode": [
            "27000"
          ]
        },
        {
          "id": 270108,
          "name": "Sa Khwan",
          "postCode": [
            "27000"
          ]
        },
        {
          "id": 270103,
          "name": "Sala Lamduan",
          "postCode": [
            "27000"
          ]
        },
        {
          "id": 270106,
          "name": "Tha Kasem",
          "postCode": [
            "27000"
          ]
        },
        {
          "id": 270105,
          "name": "Tha Yaek",
          "postCode": [
            "27000"
          ]
        }
      ]
    },
    {
      "city": "Ta Phraya",
      "cityCode": 2703,
      "provinceCode": 27,
      "subDistrict": [
        {
          "id": 270309,
          "name": "Kho Khlan",
          "postCode": [
            "27180"
          ]
        },
        {
          "id": 270301,
          "name": "Ta Phraya",
          "postCode": [
            "27180"
          ]
        },
        {
          "id": 270306,
          "name": "Thap Rat",
          "postCode": [
            "27180"
          ]
        },
        {
          "id": 270302,
          "name": "Thap Sadet",
          "postCode": [
            "27180"
          ]
        },
        {
          "id": 270307,
          "name": "Thap Thai",
          "postCode": [
            "27180"
          ]
        }
      ]
    },
    {
      "city": "Wang Nam Yen",
      "cityCode": 2704,
      "provinceCode": 27,
      "subDistrict": [
        {
          "id": 270405,
          "name": "Khlong Hin Pun",
          "postCode": [
            "27210"
          ]
        },
        {
          "id": 270403,
          "name": "Ta Lang Nai",
          "postCode": [
            "27210"
          ]
        },
        {
          "id": 270406,
          "name": "Thung Maha Charoen",
          "postCode": [
            "27210"
          ]
        },
        {
          "id": 270401,
          "name": "Wang Nam Yen",
          "postCode": [
            "27210"
          ]
        }
      ]
    },
    {
      "city": "Wang Sombun",
      "cityCode": 2709,
      "provinceCode": 27,
      "subDistrict": [
        {
          "id": 270902,
          "name": "Wang Mai",
          "postCode": [
            "27210",
            "27250"
          ]
        },
        {
          "id": 270901,
          "name": "Wang Sombun",
          "postCode": [
            "27250"
          ]
        },
        {
          "id": 270903,
          "name": "Wang Thong",
          "postCode": [
            "27250"
          ]
        }
      ]
    },
    {
      "city": "Watthana Nakhon",
      "cityCode": 2705,
      "provinceCode": 27,
      "subDistrict": [
        {
          "id": 270506,
          "name": "Chong Kum",
          "postCode": [
            "27160"
          ]
        },
        {
          "id": 270511,
          "name": "Huai Chot",
          "postCode": [
            "27160"
          ]
        },
        {
          "id": 270504,
          "name": "Non Mak Kheng",
          "postCode": [
            "27160"
          ]
        },
        {
          "id": 270509,
          "name": "Nong Mak Fai",
          "postCode": [
            "27160"
          ]
        },
        {
          "id": 270505,
          "name": "Nong Nam Sai",
          "postCode": [
            "27160"
          ]
        },
        {
          "id": 270510,
          "name": "Nong Takian Bon",
          "postCode": [
            "27160"
          ]
        },
        {
          "id": 270507,
          "name": "Nong Waeng",
          "postCode": [
            "27160"
          ]
        },
        {
          "id": 270503,
          "name": "Phak Kha",
          "postCode": [
            "27160"
          ]
        },
        {
          "id": 270508,
          "name": "Sae-o",
          "postCode": [
            "27160"
          ]
        },
        {
          "id": 270502,
          "name": "Tha Kwian",
          "postCode": [
            "27160"
          ]
        },
        {
          "id": 270501,
          "name": "Watthana Nakhon",
          "postCode": [
            "27160"
          ]
        }
      ]
    },
    {
      "city": "Akat Amnuai",
      "cityCode": 4711,
      "provinceCode": 47,
      "subDistrict": [
        {
          "id": 471101,
          "name": "Akat",
          "postCode": [
            "47170"
          ]
        },
        {
          "id": 471107,
          "name": "Ba Wa",
          "postCode": [
            "47170"
          ]
        },
        {
          "id": 471106,
          "name": "Na Hi",
          "postCode": [
            "47170"
          ]
        },
        {
          "id": 471104,
          "name": "Phon Ngam",
          "postCode": [
            "47170"
          ]
        },
        {
          "id": 471102,
          "name": "Phon Phaeng",
          "postCode": [
            "47170"
          ]
        },
        {
          "id": 471108,
          "name": "Samakkhi Phatthana",
          "postCode": [
            "47170"
          ]
        },
        {
          "id": 471105,
          "name": "Tha Kon",
          "postCode": [
            "47170"
          ]
        },
        {
          "id": 471103,
          "name": "Wa Yai",
          "postCode": [
            "47170"
          ]
        }
      ]
    },
    {
      "city": "Ban Muang",
      "cityCode": 4710,
      "provinceCode": 47,
      "subDistrict": [
        {
          "id": 471009,
          "name": "Bo Kaeo",
          "postCode": [
            "47140"
          ]
        },
        {
          "id": 471003,
          "name": "Dong Mo Thong",
          "postCode": [
            "47140"
          ]
        },
        {
          "id": 471005,
          "name": "Dong Mo Thong Tai",
          "postCode": [
            "47140"
          ]
        },
        {
          "id": 471004,
          "name": "Dong Nuea",
          "postCode": [
            "47140"
          ]
        },
        {
          "id": 471006,
          "name": "Huai Lua",
          "postCode": [
            "47140"
          ]
        },
        {
          "id": 471002,
          "name": "Mai",
          "postCode": [
            "47140"
          ]
        },
        {
          "id": 471001,
          "name": "Muang",
          "postCode": [
            "47140"
          ]
        },
        {
          "id": 471007,
          "name": "Non Sa-at",
          "postCode": [
            "47140"
          ]
        },
        {
          "id": 471008,
          "name": "Nong Kwang",
          "postCode": [
            "47140"
          ]
        }
      ]
    },
    {
      "city": "Charoen Sin",
      "cityCode": 4716,
      "provinceCode": 47,
      "subDistrict": [
        {
          "id": 471601,
          "name": "Ban Lao",
          "postCode": [
            "47290"
          ]
        },
        {
          "id": 471602,
          "name": "Charoen Sin",
          "postCode": [
            "47290"
          ]
        },
        {
          "id": 471604,
          "name": "Khok Sila",
          "postCode": [
            "47290"
          ]
        },
        {
          "id": 471605,
          "name": "Nong Paen",
          "postCode": [
            "47290"
          ]
        },
        {
          "id": 471603,
          "name": "Thung Kae",
          "postCode": [
            "47290"
          ]
        }
      ]
    },
    {
      "city": "Kham Ta Kla",
      "cityCode": 4709,
      "provinceCode": 47,
      "subDistrict": [
        {
          "id": 470901,
          "name": "Kham Ta Kla",
          "postCode": [
            "47250"
          ]
        },
        {
          "id": 470903,
          "name": "Na Tae",
          "postCode": [
            "47250"
          ]
        },
        {
          "id": 470902,
          "name": "Nong Bua Sim",
          "postCode": [
            "47250"
          ]
        },
        {
          "id": 470904,
          "name": "Phaet",
          "postCode": [
            "47250"
          ]
        }
      ]
    },
    {
      "city": "Khok Si Suphan",
      "cityCode": 4715,
      "provinceCode": 47,
      "subDistrict": [
        {
          "id": 471503,
          "name": "Dan Muang Kham",
          "postCode": [
            "47280"
          ]
        },
        {
          "id": 471502,
          "name": "Lao Phon Kho",
          "postCode": [
            "47280"
          ]
        },
        {
          "id": 471504,
          "name": "Maet Na Thom",
          "postCode": [
            "47280"
          ]
        },
        {
          "id": 471501,
          "name": "Tong Khop",
          "postCode": [
            "47280"
          ]
        }
      ]
    },
    {
      "city": "Kusuman",
      "cityCode": 4702,
      "provinceCode": 47,
      "subDistrict": [
        {
          "id": 470201,
          "name": "Kusuman",
          "postCode": [
            "47210"
          ]
        },
        {
          "id": 470203,
          "name": "Na Phiang",
          "postCode": [
            "47230"
          ]
        },
        {
          "id": 470202,
          "name": "Na Pho",
          "postCode": [
            "47210"
          ]
        },
        {
          "id": 470204,
          "name": "Phothiphaisan",
          "postCode": [
            "47210"
          ]
        },
        {
          "id": 470205,
          "name": "Um Chan",
          "postCode": [
            "47230"
          ]
        }
      ]
    },
    {
      "city": "Kut Bak",
      "cityCode": 4703,
      "provinceCode": 47,
      "subDistrict": [
        {
          "id": 470301,
          "name": "Kut Bak",
          "postCode": [
            "47180"
          ]
        },
        {
          "id": 470305,
          "name": "Kut Hai",
          "postCode": [
            "47180"
          ]
        },
        {
          "id": 470303,
          "name": "Na Mong",
          "postCode": [
            "47180"
          ]
        }
      ]
    },
    {
      "city": "Mueang Sakon Nakhon",
      "cityCode": 4701,
      "provinceCode": 47,
      "subDistrict": [
        {
          "id": 470106,
          "name": "Chiang Khruea",
          "postCode": [
            "47000"
          ]
        },
        {
          "id": 470111,
          "name": "Dong Chon",
          "postCode": [
            "47000"
          ]
        },
        {
          "id": 470115,
          "name": "Dong Mafai",
          "postCode": [
            "47000"
          ]
        },
        {
          "id": 470120,
          "name": "Hang Hong",
          "postCode": [
            "47000"
          ]
        },
        {
          "id": 470112,
          "name": "Huai Yang",
          "postCode": [
            "47000"
          ]
        },
        {
          "id": 470102,
          "name": "Khamin",
          "postCode": [
            "47220"
          ]
        },
        {
          "id": 470121,
          "name": "Khok Kong",
          "postCode": [
            "47000"
          ]
        },
        {
          "id": 470117,
          "name": "Lao Po Daeng",
          "postCode": [
            "47000"
          ]
        },
        {
          "id": 470109,
          "name": "Muang Lai",
          "postCode": [
            "47000"
          ]
        },
        {
          "id": 470103,
          "name": "Ngio Don",
          "postCode": [
            "47000"
          ]
        },
        {
          "id": 470104,
          "name": "Non Hom",
          "postCode": [
            "47000"
          ]
        },
        {
          "id": 470118,
          "name": "Nong Lat",
          "postCode": [
            "47220"
          ]
        },
        {
          "id": 470113,
          "name": "Phang Khwang",
          "postCode": [
            "47000"
          ]
        },
        {
          "id": 470107,
          "name": "Tha Rae",
          "postCode": [
            "47230"
          ]
        },
        {
          "id": 470101,
          "name": "That Choeng Chum",
          "postCode": [
            "47000"
          ]
        },
        {
          "id": 470116,
          "name": "That Na Weng",
          "postCode": [
            "47000"
          ]
        }
      ]
    },
    {
      "city": "Nikhom Nam Un",
      "cityCode": 4707,
      "provinceCode": 47,
      "subDistrict": [
        {
          "id": 470701,
          "name": "Nikhom Nam Un",
          "postCode": [
            "47270"
          ]
        },
        {
          "id": 470703,
          "name": "Nong Bua",
          "postCode": [
            "47270"
          ]
        },
        {
          "id": 470702,
          "name": "Nong Pling",
          "postCode": [
            "47270"
          ]
        },
        {
          "id": 470704,
          "name": "Suwannakham",
          "postCode": [
            "47270"
          ]
        }
      ]
    },
    {
      "city": "Phang Khon",
      "cityCode": 4705,
      "provinceCode": 47,
      "subDistrict": [
        {
          "id": 470504,
          "name": "Hai Yong",
          "postCode": [
            "47160"
          ]
        },
        {
          "id": 470502,
          "name": "Muang Khai",
          "postCode": [
            "47160"
          ]
        },
        {
          "id": 470501,
          "name": "Phang Khon",
          "postCode": [
            "47160"
          ]
        },
        {
          "id": 470503,
          "name": "Rae",
          "postCode": [
            "47160"
          ]
        },
        {
          "id": 470505,
          "name": "Ton Phueng",
          "postCode": [
            "47160"
          ]
        }
      ]
    },
    {
      "city": "Phanna Nikhom",
      "cityCode": 4704,
      "provinceCode": 47,
      "subDistrict": [
        {
          "id": 470409,
          "name": "Ba Hi",
          "postCode": [
            "47130"
          ]
        },
        {
          "id": 470406,
          "name": "Chang Ming",
          "postCode": [
            "47130"
          ]
        },
        {
          "id": 470410,
          "name": "Choeng Chum",
          "postCode": [
            "47130"
          ]
        },
        {
          "id": 470404,
          "name": "Na Hua Bo",
          "postCode": [
            "47220"
          ]
        },
        {
          "id": 470407,
          "name": "Na Nai",
          "postCode": [
            "47130"
          ]
        },
        {
          "id": 470401,
          "name": "Phanna",
          "postCode": [
            "47130"
          ]
        },
        {
          "id": 470403,
          "name": "Phok Noi",
          "postCode": [
            "47220"
          ]
        },
        {
          "id": 470405,
          "name": "Rai",
          "postCode": [
            "47130"
          ]
        },
        {
          "id": 470408,
          "name": "Sawang",
          "postCode": [
            "47130"
          ]
        },
        {
          "id": 470402,
          "name": "Wang Yang",
          "postCode": [
            "47130"
          ]
        }
      ]
    },
    {
      "city": "Phon Na Kaeo",
      "cityCode": 4717,
      "provinceCode": 47,
      "subDistrict": [
        {
          "id": 471704,
          "name": "Ban Paen",
          "postCode": [
            "47230"
          ]
        },
        {
          "id": 471701,
          "name": "Ban Phon",
          "postCode": [
            "47230"
          ]
        },
        {
          "id": 471705,
          "name": "Chiang Sue",
          "postCode": [
            "47230"
          ]
        },
        {
          "id": 471702,
          "name": "Na Kaeo",
          "postCode": [
            "47230"
          ]
        },
        {
          "id": 471703,
          "name": "Na Tong Watthana",
          "postCode": [
            "47230"
          ]
        }
      ]
    },
    {
      "city": "Phu Phan",
      "cityCode": 4718,
      "provinceCode": 47,
      "subDistrict": [
        {
          "id": 471803,
          "name": "Khok Phu",
          "postCode": [
            "47180"
          ]
        },
        {
          "id": 471804,
          "name": "Kok Pla Sio",
          "postCode": [
            "47180"
          ]
        },
        {
          "id": 471802,
          "name": "Lup Lao",
          "postCode": [
            "47180"
          ]
        },
        {
          "id": 471801,
          "name": "Sang Kho",
          "postCode": [
            "47180"
          ]
        }
      ]
    },
    {
      "city": "Sawang Daen Din",
      "cityCode": 4712,
      "provinceCode": 47,
      "subDistrict": [
        {
          "id": 471204,
          "name": "Ban Tai",
          "postCode": [
            "47110"
          ]
        },
        {
          "id": 471221,
          "name": "Ban Thon",
          "postCode": [
            "47110"
          ]
        },
        {
          "id": 471206,
          "name": "Bong Nuea",
          "postCode": [
            "47110"
          ]
        },
        {
          "id": 471211,
          "name": "Bong Tai",
          "postCode": [
            "47110"
          ]
        },
        {
          "id": 471203,
          "name": "Kham Sa-at",
          "postCode": [
            "47110"
          ]
        },
        {
          "id": 471212,
          "name": "Kho Tai",
          "postCode": [
            "47110"
          ]
        },
        {
          "id": 471208,
          "name": "Khok Si",
          "postCode": [
            "47110"
          ]
        },
        {
          "id": 471210,
          "name": "Nong Luang",
          "postCode": [
            "47110"
          ]
        },
        {
          "id": 471213,
          "name": "Phan Na",
          "postCode": [
            "47240"
          ]
        },
        {
          "id": 471207,
          "name": "Phon Sung",
          "postCode": [
            "47110"
          ]
        },
        {
          "id": 471215,
          "name": "Sai Mun",
          "postCode": [
            "47110"
          ]
        },
        {
          "id": 471201,
          "name": "Sawang Daen Din",
          "postCode": [
            "47110"
          ]
        },
        {
          "id": 471216,
          "name": "Tan Kon",
          "postCode": [
            "47240"
          ]
        },
        {
          "id": 471217,
          "name": "Tan Noeng",
          "postCode": [
            "47240"
          ]
        },
        {
          "id": 471220,
          "name": "That Thong",
          "postCode": [
            "47240"
          ]
        },
        {
          "id": 471214,
          "name": "Waeng",
          "postCode": [
            "47240"
          ]
        }
      ]
    },
    {
      "city": "Song Dao",
      "cityCode": 4713,
      "provinceCode": 47,
      "subDistrict": [
        {
          "id": 471304,
          "name": "Pathum Wapi",
          "postCode": [
            "47190"
          ]
        },
        {
          "id": 471301,
          "name": "Song Dao",
          "postCode": [
            "47190"
          ]
        },
        {
          "id": 471302,
          "name": "Tha Sila",
          "postCode": [
            "47190"
          ]
        },
        {
          "id": 471303,
          "name": "Watthana",
          "postCode": [
            "47190"
          ]
        }
      ]
    },
    {
      "city": "Tao Ngoi",
      "cityCode": 4714,
      "provinceCode": 47,
      "subDistrict": [
        {
          "id": 471402,
          "name": "Bueng Thawai",
          "postCode": [
            "47260"
          ]
        },
        {
          "id": 471404,
          "name": "Chan Phen",
          "postCode": [
            "47260"
          ]
        },
        {
          "id": 471403,
          "name": "Na Tan",
          "postCode": [
            "47260"
          ]
        },
        {
          "id": 471401,
          "name": "Tao Ngoi",
          "postCode": [
            "47260"
          ]
        }
      ]
    },
    {
      "city": "Wanon Niwat",
      "cityCode": 4708,
      "provinceCode": 47,
      "subDistrict": [
        {
          "id": 470802,
          "name": "Duea Si Khan Chai",
          "postCode": [
            "47120"
          ]
        },
        {
          "id": 470810,
          "name": "In Plaeng",
          "postCode": [
            "47120"
          ]
        },
        {
          "id": 470812,
          "name": "Khon Sawan",
          "postCode": [
            "47120"
          ]
        },
        {
          "id": 470805,
          "name": "Khu Sakham",
          "postCode": [
            "47120"
          ]
        },
        {
          "id": 470803,
          "name": "Khua Kai",
          "postCode": [
            "47120"
          ]
        },
        {
          "id": 470813,
          "name": "Kut Ruea Kham",
          "postCode": [
            "47120"
          ]
        },
        {
          "id": 470811,
          "name": "Na Kham",
          "postCode": [
            "47120"
          ]
        },
        {
          "id": 470809,
          "name": "Na So",
          "postCode": [
            "47120"
          ]
        },
        {
          "id": 470804,
          "name": "Nong Sanom",
          "postCode": [
            "47120"
          ]
        },
        {
          "id": 470807,
          "name": "Nong Waeng",
          "postCode": [
            "47120"
          ]
        },
        {
          "id": 470814,
          "name": "Nong Waeng Tai",
          "postCode": [
            "47120"
          ]
        },
        {
          "id": 470808,
          "name": "Si Wichai",
          "postCode": [
            "47120"
          ]
        },
        {
          "id": 470806,
          "name": "That",
          "postCode": [
            "47120"
          ]
        },
        {
          "id": 470801,
          "name": "Wanon Niwat",
          "postCode": [
            "47120"
          ]
        }
      ]
    },
    {
      "city": "Waritchaphum",
      "cityCode": 4706,
      "provinceCode": 47,
      "subDistrict": [
        {
          "id": 470604,
          "name": "Kham Bo",
          "postCode": [
            "47150"
          ]
        },
        {
          "id": 470605,
          "name": "Kho Khiao",
          "postCode": [
            "47150"
          ]
        },
        {
          "id": 470603,
          "name": "Nong Lat",
          "postCode": [
            "47150"
          ]
        },
        {
          "id": 470602,
          "name": "Pla Lo",
          "postCode": [
            "47150"
          ]
        },
        {
          "id": 470601,
          "name": "Waritchaphum",
          "postCode": [
            "47150"
          ]
        }
      ]
    },
    {
      "city": "Bang Bo",
      "cityCode": 1102,
      "provinceCode": 11,
      "subDistrict": [
        {
          "id": 110202,
          "name": "Ban Rakat",
          "postCode": [
            "10560"
          ]
        },
        {
          "id": 110201,
          "name": "Bang Bo",
          "postCode": [
            "10560"
          ]
        },
        {
          "id": 110203,
          "name": "Bang Phli Noi",
          "postCode": [
            "10560"
          ]
        },
        {
          "id": 110204,
          "name": "Bang Phriang",
          "postCode": [
            "10560"
          ]
        },
        {
          "id": 110205,
          "name": "Khlong Dan",
          "postCode": [
            "10550"
          ]
        },
        {
          "id": 110208,
          "name": "Khlong Niyom Yattra",
          "postCode": [
            "10560"
          ]
        },
        {
          "id": 110206,
          "name": "Khlong Suan",
          "postCode": [
            "10560"
          ]
        },
        {
          "id": 110207,
          "name": "Preng",
          "postCode": [
            "10560"
          ]
        }
      ]
    },
    {
      "city": "Bang Phli",
      "cityCode": 1103,
      "provinceCode": 11,
      "subDistrict": [
        {
          "id": 110304,
          "name": "Bang Chalong",
          "postCode": [
            "10540"
          ]
        },
        {
          "id": 110302,
          "name": "Bang Kaeo",
          "postCode": [
            "10540"
          ]
        },
        {
          "id": 110303,
          "name": "Bang Pla",
          "postCode": [
            "10540"
          ]
        },
        {
          "id": 110301,
          "name": "Blang Phli Yai",
          "postCode": [
            "10540"
          ]
        },
        {
          "id": 110309,
          "name": "Nong Prue",
          "postCode": [
            "10540"
          ]
        },
        {
          "id": 110308,
          "name": "Racha Thewa",
          "postCode": [
            "10540"
          ]
        }
      ]
    },
    {
      "city": "Bang Sao Thong",
      "cityCode": 1106,
      "provinceCode": 11,
      "subDistrict": [
        {
          "id": 110601,
          "name": "Bang Sao Thong",
          "postCode": [
            "10570"
          ]
        },
        {
          "id": 110602,
          "name": "Sisa Chorakhe Noi",
          "postCode": [
            "10570"
          ]
        },
        {
          "id": 110603,
          "name": "Sisa Chorakhe Yai",
          "postCode": [
            "10570"
          ]
        }
      ]
    },
    {
      "city": "Mueang Samut Prakan",
      "cityCode": 1101,
      "provinceCode": 11,
      "subDistrict": [
        {
          "id": 110113,
          "name": "Bang Duan",
          "postCode": [
            "10270"
          ]
        },
        {
          "id": 110103,
          "name": "Bang Mueang",
          "postCode": [
            "10270"
          ]
        },
        {
          "id": 110114,
          "name": "Bang Mueang Mai",
          "postCode": [
            "10270"
          ]
        },
        {
          "id": 110111,
          "name": "Bang Prong",
          "postCode": [
            "10270"
          ]
        },
        {
          "id": 110112,
          "name": "Bang Pu",
          "postCode": [
            "10280"
          ]
        },
        {
          "id": 110108,
          "name": "Bang Pu Mai",
          "postCode": [
            "10280"
          ]
        },
        {
          "id": 110101,
          "name": "Pak Nam",
          "postCode": [
            "10270"
          ]
        },
        {
          "id": 110110,
          "name": "Phraek Sa",
          "postCode": [
            "10280"
          ]
        },
        {
          "id": 110117,
          "name": "Phraek Sa Mai",
          "postCode": [
            "10280"
          ]
        },
        {
          "id": 110102,
          "name": "Samrong Nuea",
          "postCode": [
            "10270"
          ]
        },
        {
          "id": 110104,
          "name": "Thai Ban",
          "postCode": [
            "10280"
          ]
        },
        {
          "id": 110116,
          "name": "Thai Ban Mai",
          "postCode": [
            "10280"
          ]
        },
        {
          "id": 110115,
          "name": "Thepharak",
          "postCode": [
            "10270"
          ]
        }
      ]
    },
    {
      "city": "Phra Pradaeng",
      "cityCode": 1104,
      "provinceCode": 11,
      "subDistrict": [
        {
          "id": 110403,
          "name": "Bang Chak",
          "postCode": [
            "10130"
          ]
        },
        {
          "id": 110406,
          "name": "Bang Hua Suea",
          "postCode": [
            "10130"
          ]
        },
        {
          "id": 110409,
          "name": "Bang Kachao",
          "postCode": [
            "10130"
          ]
        },
        {
          "id": 110404,
          "name": "Bang Khru",
          "postCode": [
            "10130"
          ]
        },
        {
          "id": 110412,
          "name": "Bang Ko Bua",
          "postCode": [
            "10130"
          ]
        },
        {
          "id": 110411,
          "name": "Bang Krasop",
          "postCode": [
            "10130"
          ]
        },
        {
          "id": 110410,
          "name": "Bang Nam Phueng",
          "postCode": [
            "10130"
          ]
        },
        {
          "id": 110402,
          "name": "Bang Phueng",
          "postCode": [
            "10130"
          ]
        },
        {
          "id": 110405,
          "name": "Bang Ya Phraek",
          "postCode": [
            "10130"
          ]
        },
        {
          "id": 110408,
          "name": "Bang Yo",
          "postCode": [
            "10130"
          ]
        },
        {
          "id": 110414,
          "name": "Samrong",
          "postCode": [
            "10130"
          ]
        },
        {
          "id": 110415,
          "name": "Samrong Klang",
          "postCode": [
            "10130"
          ]
        },
        {
          "id": 110407,
          "name": "Samrong Tai",
          "postCode": [
            "10130"
          ]
        },
        {
          "id": 110413,
          "name": "Song Khanong",
          "postCode": [
            "10130"
          ]
        },
        {
          "id": 110401,
          "name": "Talat",
          "postCode": [
            "10130"
          ]
        }
      ]
    },
    {
      "city": "Phra Samut Chedi",
      "cityCode": 1105,
      "provinceCode": 11,
      "subDistrict": [
        {
          "id": 110502,
          "name": "Ban Khlong Suan",
          "postCode": [
            "10290"
          ]
        },
        {
          "id": 110503,
          "name": "Laem Fa Pha",
          "postCode": [
            "10290"
          ]
        },
        {
          "id": 110501,
          "name": "Na Kluea",
          "postCode": [
            "10290"
          ]
        },
        {
          "id": 110505,
          "name": "Nai Khlong Bang Pla Kot",
          "postCode": [
            "10290"
          ]
        },
        {
          "id": 110504,
          "name": "Pak Khlong Bang Pla Kot",
          "postCode": [
            "10290"
          ]
        }
      ]
    },
    {
      "city": "Ban Phaeo",
      "cityCode": 7403,
      "provinceCode": 74,
      "subDistrict": [
        {
          "id": 740310,
          "name": "Amphaeng",
          "postCode": [
            "74120"
          ]
        },
        {
          "id": 740301,
          "name": "Ban Phaeo",
          "postCode": [
            "74120"
          ]
        },
        {
          "id": 740308,
          "name": "Chet Rio",
          "postCode": [
            "74120"
          ]
        },
        {
          "id": 740312,
          "name": "Kaset Phatthana",
          "postCode": [
            "74120"
          ]
        },
        {
          "id": 740309,
          "name": "Khlong Tan",
          "postCode": [
            "74120"
          ]
        },
        {
          "id": 740302,
          "name": "Lak Sam",
          "postCode": [
            "74120"
          ]
        },
        {
          "id": 740307,
          "name": "Lak Song",
          "postCode": [
            "74120"
          ]
        },
        {
          "id": 740306,
          "name": "Nong Bua",
          "postCode": [
            "74120"
          ]
        },
        {
          "id": 740305,
          "name": "Nong Song Hong",
          "postCode": [
            "70210",
            "74120"
          ]
        },
        {
          "id": 740304,
          "name": "Rong Khe",
          "postCode": [
            "70210",
            "74120"
          ]
        },
        {
          "id": 740311,
          "name": "Suan Som",
          "postCode": [
            "74120"
          ]
        },
        {
          "id": 740303,
          "name": "Yokkrabat",
          "postCode": [
            "74120"
          ]
        }
      ]
    },
    {
      "city": "Krathum Baen",
      "cityCode": 7402,
      "provinceCode": 74,
      "subDistrict": [
        {
          "id": 740205,
          "name": "Bang Yang",
          "postCode": [
            "74110"
          ]
        },
        {
          "id": 740208,
          "name": "Don Kai Di",
          "postCode": [
            "74110"
          ]
        },
        {
          "id": 740209,
          "name": "Khae Rai",
          "postCode": [
            "74110"
          ]
        },
        {
          "id": 740206,
          "name": "Khlong Maduea",
          "postCode": [
            "74110"
          ]
        },
        {
          "id": 740207,
          "name": "Nong Nok Khai",
          "postCode": [
            "74110"
          ]
        },
        {
          "id": 740202,
          "name": "Om Noi",
          "postCode": [
            "74130"
          ]
        },
        {
          "id": 740204,
          "name": "Suan Luang",
          "postCode": [
            "74110"
          ]
        },
        {
          "id": 740201,
          "name": "Talad Krathum Baen",
          "postCode": [
            "74110"
          ]
        },
        {
          "id": 740203,
          "name": "Tha Mai",
          "postCode": [
            "74110"
          ]
        },
        {
          "id": 740210,
          "name": "Tha Sao",
          "postCode": [
            "74110"
          ]
        }
      ]
    },
    {
      "city": "Mueang Samut Sakhon",
      "cityCode": 7401,
      "provinceCode": 74,
      "subDistrict": [
        {
          "id": 740104,
          "name": "Ban Bo",
          "postCode": [
            "74000"
          ]
        },
        {
          "id": 740115,
          "name": "Ban Ko",
          "postCode": [
            "74000"
          ]
        },
        {
          "id": 740116,
          "name": "Bang Krachao",
          "postCode": [
            "74000"
          ]
        },
        {
          "id": 740112,
          "name": "Bang Nam Chuet",
          "postCode": [
            "74000"
          ]
        },
        {
          "id": 740105,
          "name": "Bang Tho Rat",
          "postCode": [
            "74000"
          ]
        },
        {
          "id": 740117,
          "name": "Bang Ya Phraek",
          "postCode": [
            "74000"
          ]
        },
        {
          "id": 740118,
          "name": "Chai Mongkon",
          "postCode": [
            "74000"
          ]
        },
        {
          "id": 740106,
          "name": "Kalong",
          "postCode": [
            "74000"
          ]
        },
        {
          "id": 740114,
          "name": "Khok Kham",
          "postCode": [
            "74000"
          ]
        },
        {
          "id": 740111,
          "name": "Khok Krabue",
          "postCode": [
            "74000"
          ]
        },
        {
          "id": 740103,
          "name": "Krokkrak",
          "postCode": [
            "74000"
          ]
        },
        {
          "id": 740101,
          "name": "Maha Chai",
          "postCode": [
            "74000"
          ]
        },
        {
          "id": 740109,
          "name": "Na Di",
          "postCode": [
            "74000"
          ]
        },
        {
          "id": 740107,
          "name": "Na Khok",
          "postCode": [
            "74000"
          ]
        },
        {
          "id": 740113,
          "name": "Phan Thai Norasing",
          "postCode": [
            "74000"
          ]
        },
        {
          "id": 740102,
          "name": "Tha Chalom",
          "postCode": [
            "74000"
          ]
        },
        {
          "id": 740108,
          "name": "Tha Chin",
          "postCode": [
            "74000"
          ]
        },
        {
          "id": 740110,
          "name": "Tha Sai",
          "postCode": [
            "74000"
          ]
        }
      ]
    },
    {
      "city": "Amphawa",
      "cityCode": 7503,
      "provinceCode": 75,
      "subDistrict": [
        {
          "id": 750301,
          "name": "Amphawa",
          "postCode": [
            "75110"
          ]
        },
        {
          "id": 750306,
          "name": "Bang Chang",
          "postCode": [
            "75110"
          ]
        },
        {
          "id": 750309,
          "name": "Bang Khae",
          "postCode": [
            "75110"
          ]
        },
        {
          "id": 750312,
          "name": "Bang Nang Li",
          "postCode": [
            "75110"
          ]
        },
        {
          "id": 750307,
          "name": "Khwaae Om",
          "postCode": [
            "75110"
          ]
        },
        {
          "id": 750305,
          "name": "Mueang Mai",
          "postCode": [
            "75110"
          ]
        },
        {
          "id": 750310,
          "name": "Phraek Nam Daeng",
          "postCode": [
            "75110"
          ]
        },
        {
          "id": 750308,
          "name": "Plai Phongphang",
          "postCode": [
            "75110"
          ]
        },
        {
          "id": 750302,
          "name": "Suan Luang",
          "postCode": [
            "75110"
          ]
        },
        {
          "id": 750303,
          "name": "Tha Kha",
          "postCode": [
            "75110"
          ]
        },
        {
          "id": 750304,
          "name": "Wat Pradu",
          "postCode": [
            "75110"
          ]
        },
        {
          "id": 750311,
          "name": "Yi San",
          "postCode": [
            "75110"
          ]
        }
      ]
    },
    {
      "city": "Bang Khonthi",
      "cityCode": 7502,
      "provinceCode": 75,
      "subDistrict": [
        {
          "id": 750213,
          "name": "Ban Pramot",
          "postCode": [
            "75120"
          ]
        },
        {
          "id": 750205,
          "name": "Bang Khonthi",
          "postCode": [
            "75120"
          ]
        },
        {
          "id": 750212,
          "name": "Bang Krabue",
          "postCode": [
            "75120"
          ]
        },
        {
          "id": 750208,
          "name": "Bang Kung",
          "postCode": [
            "75120"
          ]
        },
        {
          "id": 750210,
          "name": "Bang Nok Khwaek",
          "postCode": [
            "75120"
          ]
        },
        {
          "id": 750207,
          "name": "Bang Phrom",
          "postCode": [
            "75120"
          ]
        },
        {
          "id": 750202,
          "name": "Bang Sakae",
          "postCode": [
            "75120"
          ]
        },
        {
          "id": 750203,
          "name": "Bang Yi Rong",
          "postCode": [
            "75120"
          ]
        },
        {
          "id": 750209,
          "name": "Chom Pluak",
          "postCode": [
            "75120"
          ]
        },
        {
          "id": 750206,
          "name": "Don Manora",
          "postCode": [
            "75120"
          ]
        },
        {
          "id": 750201,
          "name": "Kradangnga",
          "postCode": [
            "75120"
          ]
        },
        {
          "id": 750204,
          "name": "Rong Hip",
          "postCode": [
            "75120"
          ]
        },
        {
          "id": 750211,
          "name": "Yai Phaeng",
          "postCode": [
            "75120"
          ]
        }
      ]
    },
    {
      "city": "Mueang Samut Songkhram",
      "cityCode": 7501,
      "provinceCode": 75,
      "subDistrict": [
        {
          "id": 750104,
          "name": "Ban Prok",
          "postCode": [
            "75000"
          ]
        },
        {
          "id": 750111,
          "name": "Bang Chakreng",
          "postCode": [
            "75000"
          ]
        },
        {
          "id": 750105,
          "name": "Bang Kaeo",
          "postCode": [
            "75000"
          ]
        },
        {
          "id": 750102,
          "name": "Bang Khan Taek",
          "postCode": [
            "75000"
          ]
        },
        {
          "id": 750108,
          "name": "Khlong Khoen",
          "postCode": [
            "75000"
          ]
        },
        {
          "id": 750109,
          "name": "Khlong Khon",
          "postCode": [
            "75000"
          ]
        },
        {
          "id": 750107,
          "name": "Laem Yai",
          "postCode": [
            "75000"
          ]
        },
        {
          "id": 750103,
          "name": "Lat Yai",
          "postCode": [
            "75000"
          ]
        },
        {
          "id": 750101,
          "name": "Mae Klong",
          "postCode": [
            "75000"
          ]
        },
        {
          "id": 750110,
          "name": "Nang Takian",
          "postCode": [
            "75000"
          ]
        },
        {
          "id": 750106,
          "name": "Thai Hat",
          "postCode": [
            "75000"
          ]
        }
      ]
    },
    {
      "city": "Ban Mo",
      "cityCode": 1906,
      "provinceCode": 19,
      "subDistrict": [
        {
          "id": 190608,
          "name": "Ban Khrua",
          "postCode": [
            "18270"
          ]
        },
        {
          "id": 190601,
          "name": "Ban Mo",
          "postCode": [
            "18130"
          ]
        },
        {
          "id": 190602,
          "name": "Bang Khamot",
          "postCode": [
            "18130",
            "18270"
          ]
        },
        {
          "id": 190605,
          "name": "Horathep",
          "postCode": [
            "18130",
            "18210"
          ]
        },
        {
          "id": 190606,
          "name": "Khok Yai",
          "postCode": [
            "18130"
          ]
        },
        {
          "id": 190609,
          "name": "Nong Bua",
          "postCode": [
            "18130"
          ]
        },
        {
          "id": 190607,
          "name": "Phai Khwang",
          "postCode": [
            "18130"
          ]
        },
        {
          "id": 190603,
          "name": "Sang Sok",
          "postCode": [
            "18130"
          ]
        },
        {
          "id": 190604,
          "name": "Talat Noi",
          "postCode": [
            "18130"
          ]
        }
      ]
    },
    {
      "city": "Chaloem Phra Kiat",
      "cityCode": 1913,
      "provinceCode": 19,
      "subDistrict": [
        {
          "id": 191302,
          "name": "Ban Kaeng",
          "postCode": [
            "18000"
          ]
        },
        {
          "id": 191305,
          "name": "Huai Bong",
          "postCode": [
            "18000",
            "18240"
          ]
        },
        {
          "id": 191301,
          "name": "Khao Din Phatthana",
          "postCode": [
            "18000"
          ]
        },
        {
          "id": 191306,
          "name": "Na Phra Lan",
          "postCode": [
            "18240"
          ]
        },
        {
          "id": 191304,
          "name": "Phu Khae",
          "postCode": [
            "18240"
          ]
        },
        {
          "id": 191303,
          "name": "Phueng Ruang",
          "postCode": [
            "18000"
          ]
        }
      ]
    },
    {
      "city": "Don Phut",
      "cityCode": 1907,
      "provinceCode": 19,
      "subDistrict": [
        {
          "id": 190703,
          "name": "Ban Luang",
          "postCode": [
            "18210"
          ]
        },
        {
          "id": 190701,
          "name": "Don Phut",
          "postCode": [
            "18210"
          ]
        },
        {
          "id": 190704,
          "name": "Dong Ta-ngao",
          "postCode": [
            "18210"
          ]
        },
        {
          "id": 190702,
          "name": "Phai Lio",
          "postCode": [
            "18210"
          ]
        }
      ]
    },
    {
      "city": "Kaeng Khoi",
      "cityCode": 1902,
      "provinceCode": 19,
      "subDistrict": [
        {
          "id": 190208,
          "name": "Ban Pa",
          "postCode": [
            "18110"
          ]
        },
        {
          "id": 190207,
          "name": "Ban That",
          "postCode": [
            "18110"
          ]
        },
        {
          "id": 190210,
          "name": "Cha-om",
          "postCode": [
            "18110"
          ]
        },
        {
          "id": 190213,
          "name": "Cham Phak Phaeo",
          "postCode": [
            "18110"
          ]
        },
        {
          "id": 190206,
          "name": "Hin Son",
          "postCode": [
            "18110"
          ]
        },
        {
          "id": 190204,
          "name": "Huai Haeng",
          "postCode": [
            "18110"
          ]
        },
        {
          "id": 190201,
          "name": "Kaeng Khoi",
          "postCode": [
            "18110"
          ]
        },
        {
          "id": 190211,
          "name": "Song Khon",
          "postCode": [
            "18110"
          ]
        },
        {
          "id": 190203,
          "name": "Tan Diao",
          "postCode": [
            "18110"
          ]
        },
        {
          "id": 190212,
          "name": "Tao Pun",
          "postCode": [
            "18110"
          ]
        },
        {
          "id": 190205,
          "name": "Tha Khlo",
          "postCode": [
            "18110"
          ]
        },
        {
          "id": 190215,
          "name": "Tha Maprang",
          "postCode": [
            "18110"
          ]
        },
        {
          "id": 190209,
          "name": "Tha Tum",
          "postCode": [
            "18110"
          ]
        },
        {
          "id": 190202,
          "name": "Thap Kwang",
          "postCode": [
            "18260"
          ]
        }
      ]
    },
    {
      "city": "Muak Lek",
      "cityCode": 1911,
      "provinceCode": 19,
      "subDistrict": [
        {
          "id": 191107,
          "name": "Lam Phaya Klang",
          "postCode": [
            "30130"
          ]
        },
        {
          "id": 191105,
          "name": "Lam Somphung",
          "postCode": [
            "30130"
          ]
        },
        {
          "id": 191102,
          "name": "Mittraphap",
          "postCode": [
            "18180"
          ]
        },
        {
          "id": 191101,
          "name": "Muak Lek",
          "postCode": [
            "18180"
          ]
        },
        {
          "id": 191104,
          "name": "Nong Yang Suea",
          "postCode": [
            "18180"
          ]
        },
        {
          "id": 191109,
          "name": "Sap Sanun",
          "postCode": [
            "18220"
          ]
        }
      ]
    },
    {
      "city": "Mueang Saraburi",
      "cityCode": 1901,
      "provinceCode": 19,
      "subDistrict": [
        {
          "id": 190105,
          "name": "Dao Rueang",
          "postCode": [
            "18000"
          ]
        },
        {
          "id": 190107,
          "name": "Khok Sawang",
          "postCode": [
            "18000"
          ]
        },
        {
          "id": 190112,
          "name": "Kut Nok Plao",
          "postCode": [
            "18000"
          ]
        },
        {
          "id": 190106,
          "name": "Na Chong",
          "postCode": [
            "18000"
          ]
        },
        {
          "id": 190108,
          "name": "Nong No",
          "postCode": [
            "18000"
          ]
        },
        {
          "id": 190111,
          "name": "Nong Pla Lai",
          "postCode": [
            "18000"
          ]
        },
        {
          "id": 190109,
          "name": "Nong Yao",
          "postCode": [
            "18000"
          ]
        },
        {
          "id": 190110,
          "name": "Pak Khao San",
          "postCode": [
            "18000"
          ]
        },
        {
          "id": 190101,
          "name": "Pak Phriao",
          "postCode": [
            "18000"
          ]
        },
        {
          "id": 190114,
          "name": "Takut",
          "postCode": [
            "18000"
          ]
        },
        {
          "id": 190113,
          "name": "Taling Chan",
          "postCode": [
            "18000"
          ]
        }
      ]
    },
    {
      "city": "Nong Don",
      "cityCode": 1908,
      "provinceCode": 19,
      "subDistrict": [
        {
          "id": 190802,
          "name": "Ban Klap",
          "postCode": [
            "18190"
          ]
        },
        {
          "id": 190804,
          "name": "Ban Prong",
          "postCode": [
            "18190"
          ]
        },
        {
          "id": 190803,
          "name": "Don Thong",
          "postCode": [
            "18190"
          ]
        },
        {
          "id": 190801,
          "name": "Nong Don",
          "postCode": [
            "18190"
          ]
        }
      ]
    },
    {
      "city": "Nong Khae",
      "cityCode": 1903,
      "provinceCode": 19,
      "subDistrict": [
        {
          "id": 190306,
          "name": "Bua Loi",
          "postCode": [
            "18140",
            "18230"
          ]
        },
        {
          "id": 190309,
          "name": "Huai Khamin",
          "postCode": [
            "18230"
          ]
        },
        {
          "id": 190310,
          "name": "Huai Sai",
          "postCode": [
            "18230"
          ]
        },
        {
          "id": 190304,
          "name": "Khok Tum",
          "postCode": [
            "18250"
          ]
        },
        {
          "id": 190305,
          "name": "Khok Yae",
          "postCode": [
            "18230"
          ]
        },
        {
          "id": 190303,
          "name": "Khotchasit",
          "postCode": [
            "18250"
          ]
        },
        {
          "id": 190302,
          "name": "Kum Hak",
          "postCode": [
            "18140"
          ]
        },
        {
          "id": 190313,
          "name": "Nong Chik",
          "postCode": [
            "18230"
          ]
        },
        {
          "id": 190314,
          "name": "Nong Chorakhe",
          "postCode": [
            "18140"
          ]
        },
        {
          "id": 190301,
          "name": "Nong Khae",
          "postCode": [
            "18140"
          ]
        },
        {
          "id": 190312,
          "name": "Nong Khaem",
          "postCode": [
            "18140"
          ]
        },
        {
          "id": 190311,
          "name": "Nong Khai Nam",
          "postCode": [
            "18140"
          ]
        },
        {
          "id": 190315,
          "name": "Nong Nak",
          "postCode": [
            "18230"
          ]
        },
        {
          "id": 190316,
          "name": "Nong Pla Mo",
          "postCode": [
            "18140"
          ]
        },
        {
          "id": 190317,
          "name": "Nong Pling",
          "postCode": [
            "18140"
          ]
        },
        {
          "id": 190318,
          "name": "Nong Rong",
          "postCode": [
            "18140"
          ]
        },
        {
          "id": 190307,
          "name": "Phai Tam",
          "postCode": [
            "18140"
          ]
        },
        {
          "id": 190308,
          "name": "Phon Thong",
          "postCode": [
            "18250"
          ]
        }
      ]
    },
    {
      "city": "Nong Saeng",
      "cityCode": 1905,
      "provinceCode": 19,
      "subDistrict": [
        {
          "id": 190506,
          "name": "Kai Sao",
          "postCode": [
            "18170"
          ]
        },
        {
          "id": 190509,
          "name": "Khao Din",
          "postCode": [
            "18170"
          ]
        },
        {
          "id": 190507,
          "name": "Khok Sa-at",
          "postCode": [
            "18170"
          ]
        },
        {
          "id": 190508,
          "name": "Muang Wan",
          "postCode": [
            "18170"
          ]
        },
        {
          "id": 190503,
          "name": "Nong Hua Pho",
          "postCode": [
            "18170"
          ]
        },
        {
          "id": 190502,
          "name": "Nong Khwai So",
          "postCode": [
            "18170"
          ]
        },
        {
          "id": 190505,
          "name": "Nong Kop",
          "postCode": [
            "18170"
          ]
        },
        {
          "id": 190501,
          "name": "Nong Saeng",
          "postCode": [
            "18170"
          ]
        },
        {
          "id": 190504,
          "name": "Nong Sida",
          "postCode": [
            "18170"
          ]
        }
      ]
    },
    {
      "city": "Phra Phutthabat",
      "cityCode": 1909,
      "provinceCode": 19,
      "subDistrict": [
        {
          "id": 190907,
          "name": "Huai Pa Wai",
          "postCode": [
            "18120"
          ]
        },
        {
          "id": 190906,
          "name": "Khao Wong",
          "postCode": [
            "18120"
          ]
        },
        {
          "id": 190902,
          "name": "Khun Khlon",
          "postCode": [
            "18120"
          ]
        },
        {
          "id": 190904,
          "name": "Na Yao",
          "postCode": [
            "18120"
          ]
        },
        {
          "id": 190909,
          "name": "Nong Kae",
          "postCode": [
            "18120"
          ]
        },
        {
          "id": 190901,
          "name": "Phra Phutthabat",
          "postCode": [
            "18120"
          ]
        },
        {
          "id": 190905,
          "name": "Phu Kham Chan",
          "postCode": [
            "18120"
          ]
        },
        {
          "id": 190908,
          "name": "Phu Krang",
          "postCode": [
            "18120"
          ]
        },
        {
          "id": 190903,
          "name": "Than Kasem",
          "postCode": [
            "18120"
          ]
        }
      ]
    },
    {
      "city": "Sao Hai",
      "cityCode": 1910,
      "provinceCode": 19,
      "subDistrict": [
        {
          "id": 191002,
          "name": "Ban Yang",
          "postCode": [
            "18160"
          ]
        },
        {
          "id": 191003,
          "name": "Hua Pluak",
          "postCode": [
            "18160"
          ]
        },
        {
          "id": 191009,
          "name": "Muang Ngam",
          "postCode": [
            "18160"
          ]
        },
        {
          "id": 191011,
          "name": "Mueang Kao",
          "postCode": [
            "18160"
          ]
        },
        {
          "id": 191004,
          "name": "Ngio Ngam",
          "postCode": [
            "18160"
          ]
        },
        {
          "id": 191008,
          "name": "Phraya Thot",
          "postCode": [
            "18160"
          ]
        },
        {
          "id": 191010,
          "name": "Roeng Rang",
          "postCode": [
            "18160"
          ]
        },
        {
          "id": 191005,
          "name": "Sala Ri Thai",
          "postCode": [
            "18160"
          ]
        },
        {
          "id": 191001,
          "name": "Sao Hai",
          "postCode": [
            "18160"
          ]
        },
        {
          "id": 191012,
          "name": "Suan Dok Mai",
          "postCode": [
            "18160"
          ]
        },
        {
          "id": 191007,
          "name": "Tha Chang",
          "postCode": [
            "18160"
          ]
        },
        {
          "id": 191006,
          "name": "Ton Tan",
          "postCode": [
            "18160"
          ]
        }
      ]
    },
    {
      "city": "Wang Muang",
      "cityCode": 1912,
      "provinceCode": 19,
      "subDistrict": [
        {
          "id": 191202,
          "name": "Kham Phran",
          "postCode": [
            "18220"
          ]
        },
        {
          "id": 191201,
          "name": "Salaeng Phan",
          "postCode": [
            "18220"
          ]
        },
        {
          "id": 191203,
          "name": "Wang Muang",
          "postCode": [
            "18220"
          ]
        }
      ]
    },
    {
      "city": "Wihan Daeng",
      "cityCode": 1904,
      "provinceCode": 19,
      "subDistrict": [
        {
          "id": 190402,
          "name": "Ban Lam",
          "postCode": [
            "18150"
          ]
        },
        {
          "id": 190406,
          "name": "Charoen Tham",
          "postCode": [
            "18150"
          ]
        },
        {
          "id": 190403,
          "name": "Khlong Ruea",
          "postCode": [
            "18150"
          ]
        },
        {
          "id": 190401,
          "name": "Nong Mu",
          "postCode": [
            "18150"
          ]
        },
        {
          "id": 190405,
          "name": "Nong Suang",
          "postCode": [
            "18150"
          ]
        },
        {
          "id": 190404,
          "name": "Wihan Daeng",
          "postCode": [
            "18150"
          ]
        }
      ]
    },
    {
      "city": "Khuan Don",
      "cityCode": 9102,
      "provinceCode": 91,
      "subDistrict": [
        {
          "id": 910201,
          "name": "Khuan Don",
          "postCode": [
            "91160"
          ]
        },
        {
          "id": 910202,
          "name": "Khuan Sato",
          "postCode": [
            "91160"
          ]
        },
        {
          "id": 910204,
          "name": "Wang Prachan",
          "postCode": [
            "91160"
          ]
        },
        {
          "id": 910203,
          "name": "Yan Sue",
          "postCode": [
            "91160"
          ]
        }
      ]
    },
    {
      "city": "Khuan Kalong",
      "cityCode": 9103,
      "provinceCode": 91,
      "subDistrict": [
        {
          "id": 910302,
          "name": "Khuan Kalong",
          "postCode": [
            "91130"
          ]
        },
        {
          "id": 910301,
          "name": "Thung Nui",
          "postCode": [
            "91130"
          ]
        },
        {
          "id": 910303,
          "name": "Udai Charoen",
          "postCode": [
            "91130"
          ]
        }
      ]
    },
    {
      "city": "La-ngu",
      "cityCode": 9105,
      "provinceCode": 91,
      "subDistrict": [
        {
          "id": 910501,
          "name": "Kamphaeng",
          "postCode": [
            "91110"
          ]
        },
        {
          "id": 910503,
          "name": "Khao Khao",
          "postCode": [
            "91110"
          ]
        },
        {
          "id": 910502,
          "name": "La-ngu",
          "postCode": [
            "91110"
          ]
        },
        {
          "id": 910506,
          "name": "Laem Son",
          "postCode": [
            "91110"
          ]
        },
        {
          "id": 910505,
          "name": "Nam Phut",
          "postCode": [
            "91110"
          ]
        },
        {
          "id": 910504,
          "name": "Pak Nam",
          "postCode": [
            "91110"
          ]
        }
      ]
    },
    {
      "city": "Manang",
      "cityCode": 9107,
      "provinceCode": 91,
      "subDistrict": [
        {
          "id": 910702,
          "name": "Nikhom Phatthana",
          "postCode": [
            "91130"
          ]
        },
        {
          "id": 910701,
          "name": "Pam Phatthana",
          "postCode": [
            "91130"
          ]
        }
      ]
    },
    {
      "city": "Mueang Satun",
      "cityCode": 9101,
      "provinceCode": 91,
      "subDistrict": [
        {
          "id": 910104,
          "name": "Ban Khuan",
          "postCode": [
            "91140"
          ]
        },
        {
          "id": 910105,
          "name": "Chalung",
          "postCode": [
            "91140"
          ]
        },
        {
          "id": 910108,
          "name": "Che Bilang",
          "postCode": [
            "91000"
          ]
        },
        {
          "id": 910112,
          "name": "Ket Ri",
          "postCode": [
            "91140"
          ]
        },
        {
          "id": 910102,
          "name": "Khlong Khut",
          "postCode": [
            "91000"
          ]
        },
        {
          "id": 910103,
          "name": "Khuan Khan",
          "postCode": [
            "91000"
          ]
        },
        {
          "id": 910111,
          "name": "Khuan Pho",
          "postCode": [
            "91140"
          ]
        },
        {
          "id": 910106,
          "name": "Ko Sarai",
          "postCode": [
            "91000",
            "91110"
          ]
        },
        {
          "id": 910101,
          "name": "Phiman",
          "postCode": [
            "91000"
          ]
        },
        {
          "id": 910110,
          "name": "Puyu",
          "postCode": [
            "91000"
          ]
        },
        {
          "id": 910109,
          "name": "Tam Malang",
          "postCode": [
            "91000"
          ]
        },
        {
          "id": 910107,
          "name": "Tanyong Po",
          "postCode": [
            "91000"
          ]
        }
      ]
    },
    {
      "city": "Tha Phae",
      "cityCode": 9104,
      "provinceCode": 91,
      "subDistrict": [
        {
          "id": 910402,
          "name": "Pae-ra",
          "postCode": [
            "91150"
          ]
        },
        {
          "id": 910403,
          "name": "Sakhon",
          "postCode": [
            "91150"
          ]
        },
        {
          "id": 910401,
          "name": "Tha Phae",
          "postCode": [
            "91150"
          ]
        },
        {
          "id": 910404,
          "name": "Tha Ruea",
          "postCode": [
            "91150"
          ]
        }
      ]
    },
    {
      "city": "Thung Wa",
      "cityCode": 9106,
      "provinceCode": 91,
      "subDistrict": [
        {
          "id": 910603,
          "name": "Khon Khlan",
          "postCode": [
            "91120"
          ]
        },
        {
          "id": 910602,
          "name": "Na Thon",
          "postCode": [
            "91120"
          ]
        },
        {
          "id": 910605,
          "name": "Pa Kae Bo Hin",
          "postCode": [
            "91120"
          ]
        },
        {
          "id": 910604,
          "name": "Thung Bu Lang",
          "postCode": [
            "91120"
          ]
        },
        {
          "id": 910601,
          "name": "Thung Wa",
          "postCode": [
            "91120"
          ]
        }
      ]
    },
    {
      "city": "Benchalak",
      "cityCode": 3319,
      "provinceCode": 33,
      "subDistrict": [
        {
          "id": 331904,
          "name": "Nong Hang",
          "postCode": [
            "33110"
          ]
        },
        {
          "id": 331903,
          "name": "Nong Ngu Lueam",
          "postCode": [
            "33110"
          ]
        },
        {
          "id": 331902,
          "name": "Nong Wa",
          "postCode": [
            "33110"
          ]
        },
        {
          "id": 331901,
          "name": "Siao",
          "postCode": [
            "33110"
          ]
        },
        {
          "id": 331905,
          "name": "Tha Khlo",
          "postCode": [
            "33110"
          ]
        }
      ]
    },
    {
      "city": "Bueng Bun",
      "cityCode": 3311,
      "provinceCode": 33,
      "subDistrict": [
        {
          "id": 331102,
          "name": "Bueng Bun",
          "postCode": [
            "33220"
          ]
        },
        {
          "id": 331101,
          "name": "Po",
          "postCode": [
            "33220"
          ]
        }
      ]
    },
    {
      "city": "Huai Thap Than",
      "cityCode": 3312,
      "provinceCode": 33,
      "subDistrict": [
        {
          "id": 331205,
          "name": "Chan Saen Chai",
          "postCode": [
            "33210"
          ]
        },
        {
          "id": 331201,
          "name": "Huai Thap Than",
          "postCode": [
            "33210"
          ]
        },
        {
          "id": 331203,
          "name": "Kluai Kwang",
          "postCode": [
            "33210"
          ]
        },
        {
          "id": 331202,
          "name": "Mueang Luang",
          "postCode": [
            "33210"
          ]
        },
        {
          "id": 331204,
          "name": "Phak Mai",
          "postCode": [
            "33210"
          ]
        },
        {
          "id": 331206,
          "name": "Prasat",
          "postCode": [
            "33210"
          ]
        }
      ]
    },
    {
      "city": "Kantharalak",
      "cityCode": 3304,
      "provinceCode": 33,
      "subDistrict": [
        {
          "id": 330401,
          "name": "Bueng Malu",
          "postCode": [
            "33110"
          ]
        },
        {
          "id": 330413,
          "name": "Cham",
          "postCode": [
            "33110"
          ]
        },
        {
          "id": 330411,
          "name": "Chan Yai",
          "postCode": [
            "33110"
          ]
        },
        {
          "id": 330420,
          "name": "Khanun",
          "postCode": [
            "33110"
          ]
        },
        {
          "id": 330414,
          "name": "Krachaeng",
          "postCode": [
            "33110"
          ]
        },
        {
          "id": 330402,
          "name": "Kut Salao",
          "postCode": [
            "33110"
          ]
        },
        {
          "id": 330407,
          "name": "Lalai",
          "postCode": [
            "33110"
          ]
        },
        {
          "id": 330403,
          "name": "Mueang",
          "postCode": [
            "33110"
          ]
        },
        {
          "id": 330406,
          "name": "Nam Om",
          "postCode": [
            "33110"
          ]
        },
        {
          "id": 330415,
          "name": "Non Samran",
          "postCode": [
            "33110"
          ]
        },
        {
          "id": 330416,
          "name": "Nong Ya Lat",
          "postCode": [
            "33110"
          ]
        },
        {
          "id": 330412,
          "name": "Phu Ngoen",
          "postCode": [
            "33110"
          ]
        },
        {
          "id": 330425,
          "name": "Phu Pha Mok",
          "postCode": [
            "33110"
          ]
        },
        {
          "id": 330408,
          "name": "Rung",
          "postCode": [
            "33110"
          ]
        },
        {
          "id": 330405,
          "name": "Sang Mek",
          "postCode": [
            "33110"
          ]
        },
        {
          "id": 330419,
          "name": "Sao Thong Chai",
          "postCode": [
            "33110"
          ]
        },
        {
          "id": 330421,
          "name": "Suan Kluai",
          "postCode": [
            "33110"
          ]
        },
        {
          "id": 330424,
          "name": "Thung Yai",
          "postCode": [
            "33110"
          ]
        },
        {
          "id": 330409,
          "name": "Trakat",
          "postCode": [
            "33110"
          ]
        },
        {
          "id": 330423,
          "name": "Wiang Nuea",
          "postCode": [
            "33110"
          ]
        }
      ]
    },
    {
      "city": "Kanthararom",
      "cityCode": 3303,
      "provinceCode": 33,
      "subDistrict": [
        {
          "id": 330311,
          "name": "Bua Noi",
          "postCode": [
            "33130"
          ]
        },
        {
          "id": 330315,
          "name": "Chan",
          "postCode": [
            "33130"
          ]
        },
        {
          "id": 330313,
          "name": "Du",
          "postCode": [
            "33130"
          ]
        },
        {
          "id": 330301,
          "name": "Dun",
          "postCode": [
            "33130"
          ]
        },
        {
          "id": 330310,
          "name": "I Pat",
          "postCode": [
            "33130"
          ]
        },
        {
          "id": 330320,
          "name": "Kham Niam",
          "postCode": [
            "33130"
          ]
        },
        {
          "id": 330308,
          "name": "Lathai",
          "postCode": [
            "33130"
          ]
        },
        {
          "id": 330309,
          "name": "Mueang Noi",
          "postCode": [
            "33130"
          ]
        },
        {
          "id": 330302,
          "name": "Non Sang",
          "postCode": [
            "33130"
          ]
        },
        {
          "id": 330312,
          "name": "Nong Bua",
          "postCode": [
            "33130"
          ]
        },
        {
          "id": 330303,
          "name": "Nong Hua Chang",
          "postCode": [
            "33130"
          ]
        },
        {
          "id": 330306,
          "name": "Nong Kaeo",
          "postCode": [
            "33130"
          ]
        },
        {
          "id": 330305,
          "name": "Nong Waeng",
          "postCode": [
            "33130"
          ]
        },
        {
          "id": 330314,
          "name": "Phak Phaeo",
          "postCode": [
            "33130"
          ]
        },
        {
          "id": 330307,
          "name": "Tham",
          "postCode": [
            "33130"
          ]
        },
        {
          "id": 330304,
          "name": "Yang",
          "postCode": [
            "33130"
          ]
        }
      ]
    },
    {
      "city": "Khukhan",
      "cityCode": 3305,
      "provinceCode": 33,
      "subDistrict": [
        {
          "id": 330503,
          "name": "Chai Di",
          "postCode": [
            "33140"
          ]
        },
        {
          "id": 330502,
          "name": "Chakong",
          "postCode": [
            "33140"
          ]
        },
        {
          "id": 330504,
          "name": "Dong Kam Met",
          "postCode": [
            "33140"
          ]
        },
        {
          "id": 330511,
          "name": "Hua Suea",
          "postCode": [
            "33140"
          ]
        },
        {
          "id": 330509,
          "name": "Huai Nuea",
          "postCode": [
            "33140"
          ]
        },
        {
          "id": 330522,
          "name": "Huai Samran",
          "postCode": [
            "33140"
          ]
        },
        {
          "id": 330510,
          "name": "Huai Tai",
          "postCode": [
            "33140"
          ]
        },
        {
          "id": 330501,
          "name": "Kanthararom",
          "postCode": [
            "33140"
          ]
        },
        {
          "id": 330517,
          "name": "Khok Phet",
          "postCode": [
            "33140"
          ]
        },
        {
          "id": 330524,
          "name": "Kritsana",
          "postCode": [
            "33140"
          ]
        },
        {
          "id": 330525,
          "name": "Lom Sak",
          "postCode": [
            "33140"
          ]
        },
        {
          "id": 330515,
          "name": "Nikhom Phatthana",
          "postCode": [
            "33140"
          ]
        },
        {
          "id": 330526,
          "name": "Nong Chalong",
          "postCode": [
            "33140"
          ]
        },
        {
          "id": 330518,
          "name": "Prasat",
          "postCode": [
            "33140"
          ]
        },
        {
          "id": 330506,
          "name": "Prue Yai",
          "postCode": [
            "33140"
          ]
        },
        {
          "id": 330507,
          "name": "Sadao Yai",
          "postCode": [
            "33140"
          ]
        },
        {
          "id": 330521,
          "name": "Samrong Ta Chen",
          "postCode": [
            "33140"
          ]
        },
        {
          "id": 330505,
          "name": "Sano",
          "postCode": [
            "33140"
          ]
        },
        {
          "id": 330528,
          "name": "Si Sa-at",
          "postCode": [
            "33140"
          ]
        },
        {
          "id": 330527,
          "name": "Si Trakun",
          "postCode": [
            "33140"
          ]
        },
        {
          "id": 330508,
          "name": "Ta Ut",
          "postCode": [
            "33140"
          ]
        },
        {
          "id": 330513,
          "name": "Takhian",
          "postCode": [
            "33140"
          ]
        }
      ]
    },
    {
      "city": "Khun Han",
      "cityCode": 3308,
      "provinceCode": 33,
      "subDistrict": [
        {
          "id": 330802,
          "name": "Bak Dong",
          "postCode": [
            "33150"
          ]
        },
        {
          "id": 330812,
          "name": "Huai Chan",
          "postCode": [
            "33150"
          ]
        },
        {
          "id": 330809,
          "name": "Kanthrom",
          "postCode": [
            "33150"
          ]
        },
        {
          "id": 330807,
          "name": "Khun Han",
          "postCode": [
            "33150"
          ]
        },
        {
          "id": 330806,
          "name": "Krawan",
          "postCode": [
            "33150"
          ]
        },
        {
          "id": 330808,
          "name": "Non Sung",
          "postCode": [
            "33150"
          ]
        },
        {
          "id": 330811,
          "name": "Pho Krasang",
          "postCode": [
            "33150"
          ]
        },
        {
          "id": 330804,
          "name": "Pho Wong",
          "postCode": [
            "33150"
          ]
        },
        {
          "id": 330805,
          "name": "Phrai",
          "postCode": [
            "33150"
          ]
        },
        {
          "id": 330803,
          "name": "Phran",
          "postCode": [
            "33150"
          ]
        },
        {
          "id": 330810,
          "name": "Phu Fai",
          "postCode": [
            "33150"
          ]
        },
        {
          "id": 330801,
          "name": "Si",
          "postCode": [
            "33150"
          ]
        }
      ]
    },
    {
      "city": "Mueang Chan",
      "cityCode": 3318,
      "provinceCode": 33,
      "subDistrict": [
        {
          "id": 331801,
          "name": "Mueang Chan",
          "postCode": [
            "33120"
          ]
        },
        {
          "id": 331803,
          "name": "Nong Yai",
          "postCode": [
            "33120"
          ]
        },
        {
          "id": 331802,
          "name": "Ta Kon",
          "postCode": [
            "33120"
          ]
        }
      ]
    },
    {
      "city": "Mueang Si Sa Ket",
      "cityCode": 3301,
      "provinceCode": 33,
      "subDistrict": [
        {
          "id": 330105,
          "name": "Chan",
          "postCode": [
            "33000"
          ]
        },
        {
          "id": 330103,
          "name": "Khu Sot",
          "postCode": [
            "33000"
          ]
        },
        {
          "id": 330124,
          "name": "Mak Khiap",
          "postCode": [
            "33000"
          ]
        },
        {
          "id": 330101,
          "name": "Mueang Nuea",
          "postCode": [
            "33000"
          ]
        },
        {
          "id": 330102,
          "name": "Mueang Tai",
          "postCode": [
            "33000"
          ]
        },
        {
          "id": 330122,
          "name": "Nam Kham",
          "postCode": [
            "33000"
          ]
        },
        {
          "id": 330119,
          "name": "Nong Hai",
          "postCode": [
            "33000"
          ]
        },
        {
          "id": 330121,
          "name": "Nong Kaeo",
          "postCode": [
            "33000"
          ]
        },
        {
          "id": 330107,
          "name": "Nong Khrok",
          "postCode": [
            "33000"
          ]
        },
        {
          "id": 330127,
          "name": "Nong Phai",
          "postCode": [
            "33000"
          ]
        },
        {
          "id": 330123,
          "name": "Pho",
          "postCode": [
            "33000"
          ]
        },
        {
          "id": 330111,
          "name": "Phon Kha",
          "postCode": [
            "33000"
          ]
        },
        {
          "id": 330112,
          "name": "Phon Kho",
          "postCode": [
            "33000"
          ]
        },
        {
          "id": 330115,
          "name": "Phon Khwao",
          "postCode": [
            "33000"
          ]
        },
        {
          "id": 330104,
          "name": "Sam",
          "postCode": [
            "33000"
          ]
        },
        {
          "id": 330106,
          "name": "Tadop",
          "postCode": [
            "33000"
          ]
        },
        {
          "id": 330118,
          "name": "Thum",
          "postCode": [
            "33000"
          ]
        },
        {
          "id": 330116,
          "name": "Ya Plong",
          "postCode": [
            "33000"
          ]
        }
      ]
    },
    {
      "city": "Nam Kliang",
      "cityCode": 3315,
      "provinceCode": 33,
      "subDistrict": [
        {
          "id": 331504,
          "name": "Khoen",
          "postCode": [
            "33130"
          ]
        },
        {
          "id": 331506,
          "name": "Khup",
          "postCode": [
            "33130"
          ]
        },
        {
          "id": 331502,
          "name": "La-o",
          "postCode": [
            "33130"
          ]
        },
        {
          "id": 331501,
          "name": "Nam Kliang",
          "postCode": [
            "33130"
          ]
        },
        {
          "id": 331505,
          "name": "Rung Rawi",
          "postCode": [
            "33130"
          ]
        },
        {
          "id": 331503,
          "name": "Tong Pit",
          "postCode": [
            "33130"
          ]
        }
      ]
    },
    {
      "city": "Non Khun",
      "cityCode": 3313,
      "provinceCode": 33,
      "subDistrict": [
        {
          "id": 331302,
          "name": "Bok",
          "postCode": [
            "33250"
          ]
        },
        {
          "id": 331305,
          "name": "Lao Kwang",
          "postCode": [
            "33250"
          ]
        },
        {
          "id": 331301,
          "name": "Non Kho",
          "postCode": [
            "33250"
          ]
        },
        {
          "id": 331304,
          "name": "Nong Kung",
          "postCode": [
            "33250"
          ]
        },
        {
          "id": 331303,
          "name": "Pho",
          "postCode": [
            "33250"
          ]
        }
      ]
    },
    {
      "city": "Phayu",
      "cityCode": 3320,
      "provinceCode": 33,
      "subDistrict": [
        {
          "id": 332005,
          "name": "Nong Kha",
          "postCode": [
            "33230"
          ]
        },
        {
          "id": 332004,
          "name": "Nong Phek",
          "postCode": [
            "33230"
          ]
        },
        {
          "id": 332001,
          "name": "Phayu",
          "postCode": [
            "33230"
          ]
        },
        {
          "id": 332002,
          "name": "Phrom Sawat",
          "postCode": [
            "33230"
          ]
        },
        {
          "id": 332003,
          "name": "Tamyae",
          "postCode": [
            "33230"
          ]
        }
      ]
    },
    {
      "city": "Pho Si Suwan",
      "cityCode": 3321,
      "provinceCode": 33,
      "subDistrict": [
        {
          "id": 332101,
          "name": "Dot",
          "postCode": [
            "33120"
          ]
        },
        {
          "id": 332105,
          "name": "I Se",
          "postCode": [
            "33120"
          ]
        },
        {
          "id": 332103,
          "name": "Nong Ma",
          "postCode": [
            "33120"
          ]
        },
        {
          "id": 332104,
          "name": "Phue Yai",
          "postCode": [
            "33120"
          ]
        },
        {
          "id": 332102,
          "name": "Siao",
          "postCode": [
            "33120"
          ]
        }
      ]
    },
    {
      "city": "Phrai Bueng",
      "cityCode": 3306,
      "provinceCode": 33,
      "subDistrict": [
        {
          "id": 330602,
          "name": "Din Daeng",
          "postCode": [
            "33180"
          ]
        },
        {
          "id": 330606,
          "name": "Non Pun",
          "postCode": [
            "33180"
          ]
        },
        {
          "id": 330601,
          "name": "Phrai Bueng",
          "postCode": [
            "33180"
          ]
        },
        {
          "id": 330603,
          "name": "Prasat Yoe",
          "postCode": [
            "33180"
          ]
        },
        {
          "id": 330604,
          "name": "Samrong Phlan",
          "postCode": [
            "33180"
          ]
        },
        {
          "id": 330605,
          "name": "Suk Sawat",
          "postCode": [
            "33180"
          ]
        }
      ]
    },
    {
      "city": "Phu Sing",
      "cityCode": 3317,
      "provinceCode": 33,
      "subDistrict": [
        {
          "id": 331706,
          "name": "Dong Rak",
          "postCode": [
            "33140"
          ]
        },
        {
          "id": 331702,
          "name": "Huai Ta Mon",
          "postCode": [
            "33140"
          ]
        },
        {
          "id": 331703,
          "name": "Huai Tuekchu",
          "postCode": [
            "33140"
          ]
        },
        {
          "id": 331701,
          "name": "Khok Tan",
          "postCode": [
            "33140"
          ]
        },
        {
          "id": 331704,
          "name": "Lalom",
          "postCode": [
            "33140"
          ]
        },
        {
          "id": 331707,
          "name": "Phrai Phatthana",
          "postCode": [
            "33140"
          ]
        },
        {
          "id": 331705,
          "name": "Takhian Ram",
          "postCode": [
            "33140"
          ]
        }
      ]
    },
    {
      "city": "Prang Ku",
      "cityCode": 3307,
      "provinceCode": 33,
      "subDistrict": [
        {
          "id": 330708,
          "name": "Du",
          "postCode": [
            "33170"
          ]
        },
        {
          "id": 330702,
          "name": "Ku",
          "postCode": [
            "33170"
          ]
        },
        {
          "id": 330703,
          "name": "Nong Chiang Thun",
          "postCode": [
            "33170"
          ]
        },
        {
          "id": 330701,
          "name": "Phimai",
          "postCode": [
            "33170"
          ]
        },
        {
          "id": 330710,
          "name": "Phimai Nuea",
          "postCode": [
            "33170"
          ]
        },
        {
          "id": 330706,
          "name": "Pho Si",
          "postCode": [
            "33170"
          ]
        },
        {
          "id": 330705,
          "name": "Samo",
          "postCode": [
            "33170"
          ]
        },
        {
          "id": 330707,
          "name": "Samrong Prasat",
          "postCode": [
            "33170"
          ]
        },
        {
          "id": 330709,
          "name": "Sawai",
          "postCode": [
            "33170"
          ]
        },
        {
          "id": 330704,
          "name": "Tum",
          "postCode": [
            "33170"
          ]
        }
      ]
    },
    {
      "city": "Rasi Salai",
      "cityCode": 3309,
      "provinceCode": 33,
      "subDistrict": [
        {
          "id": 330910,
          "name": "Bua Hung",
          "postCode": [
            "33160"
          ]
        },
        {
          "id": 330906,
          "name": "Chik Sang Thong",
          "postCode": [
            "33160"
          ]
        },
        {
          "id": 330907,
          "name": "Dan",
          "postCode": [
            "33160"
          ]
        },
        {
          "id": 330908,
          "name": "Du",
          "postCode": [
            "33160"
          ]
        },
        {
          "id": 330902,
          "name": "Mueang Khaen",
          "postCode": [
            "33160"
          ]
        },
        {
          "id": 330901,
          "name": "Mueang Khong",
          "postCode": [
            "33160"
          ]
        },
        {
          "id": 330903,
          "name": "Nong Khae",
          "postCode": [
            "33160"
          ]
        },
        {
          "id": 330913,
          "name": "Nong Mi",
          "postCode": [
            "33160"
          ]
        },
        {
          "id": 330909,
          "name": "Nong Ueng",
          "postCode": [
            "33160"
          ]
        },
        {
          "id": 330911,
          "name": "Phai",
          "postCode": [
            "33160"
          ]
        },
        {
          "id": 330915,
          "name": "Sang Pi",
          "postCode": [
            "33160"
          ]
        },
        {
          "id": 330912,
          "name": "Som Poi",
          "postCode": [
            "33160"
          ]
        },
        {
          "id": 330914,
          "name": "Wan Kham",
          "postCode": [
            "33160"
          ]
        }
      ]
    },
    {
      "city": "Si Rattana",
      "cityCode": 3314,
      "provinceCode": 33,
      "subDistrict": [
        {
          "id": 331402,
          "name": "Phing Phuai",
          "postCode": [
            "33240"
          ]
        },
        {
          "id": 331403,
          "name": "Sa Yao",
          "postCode": [
            "33240"
          ]
        },
        {
          "id": 331407,
          "name": "Saphung",
          "postCode": [
            "33240"
          ]
        },
        {
          "id": 331401,
          "name": "Si Kaeo",
          "postCode": [
            "33240"
          ]
        },
        {
          "id": 331406,
          "name": "Si Non Ngam",
          "postCode": [
            "33240"
          ]
        },
        {
          "id": 331405,
          "name": "Sueang Khao",
          "postCode": [
            "33240"
          ]
        },
        {
          "id": 331404,
          "name": "Tum",
          "postCode": [
            "33240"
          ]
        }
      ]
    },
    {
      "city": "Sila Lat",
      "cityCode": 3322,
      "provinceCode": 33,
      "subDistrict": [
        {
          "id": 332204,
          "name": "Chot Muang",
          "postCode": [
            "33160"
          ]
        },
        {
          "id": 332202,
          "name": "Khli Kling",
          "postCode": [
            "33160"
          ]
        },
        {
          "id": 332201,
          "name": "Kung",
          "postCode": [
            "33160"
          ]
        },
        {
          "id": 332203,
          "name": "Nong Bua Dong",
          "postCode": [
            "33160"
          ]
        }
      ]
    },
    {
      "city": "Uthumphon Phisai",
      "cityCode": 3310,
      "provinceCode": 33,
      "subDistrict": [
        {
          "id": 331011,
          "name": "Hua Chang",
          "postCode": [
            "33120"
          ]
        },
        {
          "id": 331002,
          "name": "I Lam",
          "postCode": [
            "33120"
          ]
        },
        {
          "id": 331001,
          "name": "Kamphaeng",
          "postCode": [
            "33120"
          ]
        },
        {
          "id": 331003,
          "name": "Kan Lueang",
          "postCode": [
            "33120"
          ]
        },
        {
          "id": 331015,
          "name": "Khae",
          "postCode": [
            "33120"
          ]
        },
        {
          "id": 331006,
          "name": "Khaem",
          "postCode": [
            "33120"
          ]
        },
        {
          "id": 331008,
          "name": "Khayung",
          "postCode": [
            "33120"
          ]
        },
        {
          "id": 331025,
          "name": "Khok Chan",
          "postCode": [
            "33120"
          ]
        },
        {
          "id": 331024,
          "name": "Khok Lam",
          "postCode": [
            "33120"
          ]
        },
        {
          "id": 331007,
          "name": "Nong Hai",
          "postCode": [
            "33120"
          ]
        },
        {
          "id": 331018,
          "name": "Nong Hang",
          "postCode": [
            "33120"
          ]
        },
        {
          "id": 331017,
          "name": "Pa Ao",
          "postCode": [
            "33120"
          ]
        },
        {
          "id": 331016,
          "name": "Pho Chai",
          "postCode": [
            "33120"
          ]
        },
        {
          "id": 331012,
          "name": "Rang Raeng",
          "postCode": [
            "33120"
          ]
        },
        {
          "id": 331022,
          "name": "Sa Kamphaeng Yai",
          "postCode": [
            "33120"
          ]
        },
        {
          "id": 331005,
          "name": "Samrong",
          "postCode": [
            "33120"
          ]
        },
        {
          "id": 331010,
          "name": "Ta Ket",
          "postCode": [
            "33120"
          ]
        },
        {
          "id": 331014,
          "name": "Tae",
          "postCode": [
            "33120"
          ]
        },
        {
          "id": 331004,
          "name": "Thung Chai",
          "postCode": [
            "33120"
          ]
        }
      ]
    },
    {
      "city": "Wang Hin",
      "cityCode": 3316,
      "provinceCode": 33,
      "subDistrict": [
        {
          "id": 331604,
          "name": "Bo Kaeo",
          "postCode": [
            "33270"
          ]
        },
        {
          "id": 331601,
          "name": "Bu Sung",
          "postCode": [
            "33270"
          ]
        },
        {
          "id": 331603,
          "name": "Duan Yai",
          "postCode": [
            "33270"
          ]
        },
        {
          "id": 331608,
          "name": "Phon Yang",
          "postCode": [
            "33270"
          ]
        },
        {
          "id": 331605,
          "name": "Si Samran",
          "postCode": [
            "33270"
          ]
        },
        {
          "id": 331602,
          "name": "That",
          "postCode": [
            "33270"
          ]
        },
        {
          "id": 331606,
          "name": "Thung Sawang",
          "postCode": [
            "33270"
          ]
        },
        {
          "id": 331607,
          "name": "Wang Hin",
          "postCode": [
            "33270"
          ]
        }
      ]
    },
    {
      "city": "Yang Chum Noi",
      "cityCode": 3302,
      "provinceCode": 33,
      "subDistrict": [
        {
          "id": 330206,
          "name": "Bueng Bon",
          "postCode": [
            "33190"
          ]
        },
        {
          "id": 330203,
          "name": "Khon Kam",
          "postCode": [
            "33190"
          ]
        },
        {
          "id": 330205,
          "name": "Kut Mueang Ham",
          "postCode": [
            "33190"
          ]
        },
        {
          "id": 330202,
          "name": "Lin Fa",
          "postCode": [
            "33190"
          ]
        },
        {
          "id": 330204,
          "name": "Non Khun",
          "postCode": [
            "33190"
          ]
        },
        {
          "id": 330201,
          "name": "Yang Chum Noi",
          "postCode": [
            "33190"
          ]
        },
        {
          "id": 330207,
          "name": "Yang Chum Yai",
          "postCode": [
            "33190"
          ]
        }
      ]
    },
    {
      "city": "Bang Rachan",
      "cityCode": 1702,
      "provinceCode": 17,
      "subDistrict": [
        {
          "id": 170206,
          "name": "Ban Cha",
          "postCode": [
            "16130"
          ]
        },
        {
          "id": 170203,
          "name": "Choeng Klat",
          "postCode": [
            "16130"
          ]
        },
        {
          "id": 170205,
          "name": "Mae La",
          "postCode": [
            "16130"
          ]
        },
        {
          "id": 170202,
          "name": "Mai Dat",
          "postCode": [
            "16130"
          ]
        },
        {
          "id": 170207,
          "name": "Phak Than",
          "postCode": [
            "16130"
          ]
        },
        {
          "id": 170204,
          "name": "Pho Chon Kai",
          "postCode": [
            "16130"
          ]
        },
        {
          "id": 170208,
          "name": "Sa Chaeng",
          "postCode": [
            "16130"
          ]
        },
        {
          "id": 170201,
          "name": "Sing",
          "postCode": [
            "16130"
          ]
        }
      ]
    },
    {
      "city": "In Buri",
      "cityCode": 1706,
      "provinceCode": 17,
      "subDistrict": [
        {
          "id": 170605,
          "name": "Chi Nam Rai",
          "postCode": [
            "16110"
          ]
        },
        {
          "id": 170609,
          "name": "Huai Chan",
          "postCode": [
            "16110"
          ]
        },
        {
          "id": 170601,
          "name": "In Buri",
          "postCode": [
            "16110"
          ]
        },
        {
          "id": 170607,
          "name": "Nam Tan",
          "postCode": [
            "16110"
          ]
        },
        {
          "id": 170604,
          "name": "Ngio Rai",
          "postCode": [
            "16110"
          ]
        },
        {
          "id": 170610,
          "name": "Pho Chai",
          "postCode": [
            "16110"
          ]
        },
        {
          "id": 170602,
          "name": "Prasuk",
          "postCode": [
            "16110"
          ]
        },
        {
          "id": 170606,
          "name": "Tha Ngam",
          "postCode": [
            "16110"
          ]
        },
        {
          "id": 170603,
          "name": "Thap Ya",
          "postCode": [
            "16110"
          ]
        },
        {
          "id": 170608,
          "name": "Thong En",
          "postCode": [
            "16110"
          ]
        }
      ]
    },
    {
      "city": "Khai Bang Rachan",
      "cityCode": 1703,
      "provinceCode": 17,
      "subDistrict": [
        {
          "id": 170302,
          "name": "Bang Rachan",
          "postCode": [
            "16150"
          ]
        },
        {
          "id": 170305,
          "name": "Kho Sai",
          "postCode": [
            "16150"
          ]
        },
        {
          "id": 170306,
          "name": "Nong Krathum",
          "postCode": [
            "16150"
          ]
        },
        {
          "id": 170303,
          "name": "Pho Sangkho",
          "postCode": [
            "16150"
          ]
        },
        {
          "id": 170301,
          "name": "Pho Thale",
          "postCode": [
            "16150"
          ]
        },
        {
          "id": 170304,
          "name": "Tha Kham",
          "postCode": [
            "16150"
          ]
        }
      ]
    },
    {
      "city": "Mueang Sing Buri",
      "cityCode": 1701,
      "provinceCode": 17,
      "subDistrict": [
        {
          "id": 170108,
          "name": "Bang Krabue",
          "postCode": [
            "16000"
          ]
        },
        {
          "id": 170102,
          "name": "Bang Man",
          "postCode": [
            "16000"
          ]
        },
        {
          "id": 170101,
          "name": "Bang Phutsa",
          "postCode": [
            "16000"
          ]
        },
        {
          "id": 170107,
          "name": "Chaksi",
          "postCode": [
            "16000"
          ]
        },
        {
          "id": 170105,
          "name": "Hua Phai",
          "postCode": [
            "16000"
          ]
        },
        {
          "id": 170104,
          "name": "Muang Mu",
          "postCode": [
            "16000"
          ]
        },
        {
          "id": 170103,
          "name": "Phok Ruam",
          "postCode": [
            "16000"
          ]
        },
        {
          "id": 170106,
          "name": "Ton Pho",
          "postCode": [
            "16000"
          ]
        }
      ]
    },
    {
      "city": "Phrom Buri",
      "cityCode": 1704,
      "provinceCode": 17,
      "subDistrict": [
        {
          "id": 170404,
          "name": "Ban Mo",
          "postCode": [
            "16120"
          ]
        },
        {
          "id": 170405,
          "name": "Ban Paeng",
          "postCode": [
            "16120"
          ]
        },
        {
          "id": 170403,
          "name": "Bang Nam Chiao",
          "postCode": [
            "16120"
          ]
        },
        {
          "id": 170406,
          "name": "Hua Pa",
          "postCode": [
            "16120"
          ]
        },
        {
          "id": 170401,
          "name": "Phra Ngam",
          "postCode": [
            "16120"
          ]
        },
        {
          "id": 170402,
          "name": "Phrom Buri",
          "postCode": [
            "16160"
          ]
        },
        {
          "id": 170407,
          "name": "Rong Chang",
          "postCode": [
            "16120"
          ]
        }
      ]
    },
    {
      "city": "Tha Chang",
      "cityCode": 1705,
      "provinceCode": 17,
      "subDistrict": [
        {
          "id": 170504,
          "name": "Phikun Thong",
          "postCode": [
            "16140"
          ]
        },
        {
          "id": 170502,
          "name": "Pho Prachak",
          "postCode": [
            "16140"
          ]
        },
        {
          "id": 170501,
          "name": "Thon Samo",
          "postCode": [
            "16140"
          ]
        },
        {
          "id": 170503,
          "name": "Wihan Khao",
          "postCode": [
            "16140"
          ]
        }
      ]
    },
    {
      "city": "Bang Klam",
      "cityCode": 9014,
      "provinceCode": 90,
      "subDistrict": [
        {
          "id": 901404,
          "name": "Ban Han",
          "postCode": [
            "90110"
          ]
        },
        {
          "id": 901401,
          "name": "Bang Klam",
          "postCode": [
            "90110"
          ]
        },
        {
          "id": 901403,
          "name": "Mae Thom",
          "postCode": [
            "90110"
          ]
        },
        {
          "id": 901402,
          "name": "Tha Chang",
          "postCode": [
            "90110"
          ]
        }
      ]
    },
    {
      "city": "Chana",
      "cityCode": 9003,
      "provinceCode": 90,
      "subDistrict": [
        {
          "id": 900301,
          "name": "Ban Na",
          "postCode": [
            "90130"
          ]
        },
        {
          "id": 900310,
          "name": "Chanong",
          "postCode": [
            "90130"
          ]
        },
        {
          "id": 900312,
          "name": "Khae",
          "postCode": [
            "90130"
          ]
        },
        {
          "id": 900313,
          "name": "Khlong Pia",
          "postCode": [
            "90130"
          ]
        },
        {
          "id": 900311,
          "name": "Khu",
          "postCode": [
            "90130"
          ]
        },
        {
          "id": 900308,
          "name": "Khun Tat Wai",
          "postCode": [
            "90130"
          ]
        },
        {
          "id": 900306,
          "name": "Na Thap",
          "postCode": [
            "90130"
          ]
        },
        {
          "id": 900305,
          "name": "Na Wa",
          "postCode": [
            "90130"
          ]
        },
        {
          "id": 900307,
          "name": "Nam Khao",
          "postCode": [
            "90130"
          ]
        },
        {
          "id": 900302,
          "name": "Pa Ching",
          "postCode": [
            "90130"
          ]
        },
        {
          "id": 900304,
          "name": "Sakom",
          "postCode": [
            "90130"
          ]
        },
        {
          "id": 900303,
          "name": "Saphan Mai Kaen",
          "postCode": [
            "90130"
          ]
        },
        {
          "id": 900314,
          "name": "Taling Chan",
          "postCode": [
            "90130"
          ]
        },
        {
          "id": 900309,
          "name": "Tha Mo Sai",
          "postCode": [
            "90130"
          ]
        }
      ]
    },
    {
      "city": "Hat Yai",
      "cityCode": 9011,
      "provinceCode": 90,
      "subDistrict": [
        {
          "id": 901116,
          "name": "Ban Phru",
          "postCode": [
            "90250"
          ]
        },
        {
          "id": 901108,
          "name": "Chalung",
          "postCode": [
            "90110"
          ]
        },
        {
          "id": 901101,
          "name": "Hat yai",
          "postCode": [
            "90110"
          ]
        },
        {
          "id": 901105,
          "name": "Khlong Hae",
          "postCode": [
            "90110"
          ]
        },
        {
          "id": 901107,
          "name": "Khlong U Taphao",
          "postCode": [
            "90110"
          ]
        },
        {
          "id": 901104,
          "name": "Kho Hong",
          "postCode": [
            "90110",
            "90112"
          ]
        },
        {
          "id": 901103,
          "name": "Khu Tao",
          "postCode": [
            "90110"
          ]
        },
        {
          "id": 901102,
          "name": "Khuan Lang",
          "postCode": [
            "90110"
          ]
        },
        {
          "id": 901114,
          "name": "Nam Noi",
          "postCode": [
            "90110"
          ]
        },
        {
          "id": 901118,
          "name": "Phatong",
          "postCode": [
            "90230"
          ]
        },
        {
          "id": 901113,
          "name": "Tha Kham",
          "postCode": [
            "90110"
          ]
        },
        {
          "id": 901112,
          "name": "Thung Tam Sao",
          "postCode": [
            "90110"
          ]
        },
        {
          "id": 901111,
          "name": "Thung Yai",
          "postCode": [
            "90110"
          ]
        }
      ]
    },
    {
      "city": "Khlong Hoi Khong",
      "cityCode": 9016,
      "provinceCode": 90,
      "subDistrict": [
        {
          "id": 901601,
          "name": "Khlong Hoi Khong",
          "postCode": [
            "90230"
          ]
        },
        {
          "id": 901604,
          "name": "Khlong La",
          "postCode": [
            "90110"
          ]
        },
        {
          "id": 901603,
          "name": "Khok Muang",
          "postCode": [
            "90115",
            "90230"
          ]
        },
        {
          "id": 901602,
          "name": "Thung Lan",
          "postCode": [
            "90230"
          ]
        }
      ]
    },
    {
      "city": "Khuan Niang",
      "cityCode": 9013,
      "provinceCode": 90,
      "subDistrict": [
        {
          "id": 901304,
          "name": "Bang Riang",
          "postCode": [
            "90220"
          ]
        },
        {
          "id": 901303,
          "name": "Huai Luek",
          "postCode": [
            "90220"
          ]
        },
        {
          "id": 901302,
          "name": "Khuan So",
          "postCode": [
            "90220"
          ]
        },
        {
          "id": 901301,
          "name": "Rattaphum",
          "postCode": [
            "90220"
          ]
        }
      ]
    },
    {
      "city": "Krasae Sin",
      "cityCode": 9008,
      "provinceCode": 90,
      "subDistrict": [
        {
          "id": 900803,
          "name": "Choeng Sae",
          "postCode": [
            "90270"
          ]
        },
        {
          "id": 900801,
          "name": "Ko Yai",
          "postCode": [
            "90270"
          ]
        },
        {
          "id": 900804,
          "name": "Krasae Sin",
          "postCode": [
            "90270"
          ]
        },
        {
          "id": 900802,
          "name": "Rong",
          "postCode": [
            "90270"
          ]
        }
      ]
    },
    {
      "city": "Mueang Songkhla",
      "cityCode": 9001,
      "provinceCode": 90,
      "subDistrict": [
        {
          "id": 900101,
          "name": "Bo Yang",
          "postCode": [
            "90000"
          ]
        },
        {
          "id": 900102,
          "name": "Khao Rup Chang",
          "postCode": [
            "90000"
          ]
        },
        {
          "id": 900103,
          "name": "Ko Taeo",
          "postCode": [
            "90000"
          ]
        },
        {
          "id": 900106,
          "name": "Ko Yo",
          "postCode": [
            "90100"
          ]
        },
        {
          "id": 900104,
          "name": "Phawong",
          "postCode": [
            "90100"
          ]
        },
        {
          "id": 900105,
          "name": "Thung Wang",
          "postCode": [
            "90000"
          ]
        }
      ]
    },
    {
      "city": "Na Mom",
      "cityCode": 9012,
      "provinceCode": 90,
      "subDistrict": [
        {
          "id": 901204,
          "name": "Khlong Rang",
          "postCode": [
            "90310"
          ]
        },
        {
          "id": 901201,
          "name": "Na Mom",
          "postCode": [
            "90310"
          ]
        },
        {
          "id": 901202,
          "name": "Phichit",
          "postCode": [
            "90310"
          ]
        },
        {
          "id": 901203,
          "name": "Thung Khamin",
          "postCode": [
            "90310"
          ]
        }
      ]
    },
    {
      "city": "Na Thawi",
      "cityCode": 9004,
      "provinceCode": 90,
      "subDistrict": [
        {
          "id": 900402,
          "name": "Chang",
          "postCode": [
            "90160"
          ]
        },
        {
          "id": 900410,
          "name": "Khlong Kwang",
          "postCode": [
            "90160"
          ]
        },
        {
          "id": 900404,
          "name": "Khlong Sai",
          "postCode": [
            "90160"
          ]
        },
        {
          "id": 900403,
          "name": "Na Mo Si",
          "postCode": [
            "90160"
          ]
        },
        {
          "id": 900401,
          "name": "Na Thawi",
          "postCode": [
            "90160"
          ]
        },
        {
          "id": 900405,
          "name": "Plak Nu",
          "postCode": [
            "90160"
          ]
        },
        {
          "id": 900409,
          "name": "Prakop",
          "postCode": [
            "90160"
          ]
        },
        {
          "id": 900407,
          "name": "Sathon",
          "postCode": [
            "90160"
          ]
        },
        {
          "id": 900406,
          "name": "Tha Pradu",
          "postCode": [
            "90160"
          ]
        },
        {
          "id": 900408,
          "name": "Thap Chang",
          "postCode": [
            "90160"
          ]
        }
      ]
    },
    {
      "city": "Ranot",
      "cityCode": 9007,
      "provinceCode": 90,
      "subDistrict": [
        {
          "id": 900711,
          "name": "Ban Khwa",
          "postCode": [
            "90140"
          ]
        },
        {
          "id": 900705,
          "name": "Ban Mai",
          "postCode": [
            "90140"
          ]
        },
        {
          "id": 900706,
          "name": "Bo Tru",
          "postCode": [
            "90140"
          ]
        },
        {
          "id": 900712,
          "name": "Daen Sa-nguan",
          "postCode": [
            "90140"
          ]
        },
        {
          "id": 900702,
          "name": "Khlong Daen",
          "postCode": [
            "90140"
          ]
        },
        {
          "id": 900707,
          "name": "Pak Trae",
          "postCode": [
            "90140"
          ]
        },
        {
          "id": 900708,
          "name": "Phang Yang",
          "postCode": [
            "90140"
          ]
        },
        {
          "id": 900701,
          "name": "Ranot",
          "postCode": [
            "90140"
          ]
        },
        {
          "id": 900709,
          "name": "Rawa",
          "postCode": [
            "90140"
          ]
        },
        {
          "id": 900703,
          "name": "Takhria",
          "postCode": [
            "90140"
          ]
        },
        {
          "id": 900704,
          "name": "Tha Bon",
          "postCode": [
            "90140"
          ]
        },
        {
          "id": 900710,
          "name": "Wat Son",
          "postCode": [
            "90140"
          ]
        }
      ]
    },
    {
      "city": "Rattaphum",
      "cityCode": 9009,
      "provinceCode": 90,
      "subDistrict": [
        {
          "id": 900901,
          "name": "Kamphaeng Phet",
          "postCode": [
            "90180"
          ]
        },
        {
          "id": 900909,
          "name": "Khao Phra",
          "postCode": [
            "90180"
          ]
        },
        {
          "id": 900904,
          "name": "Khuan Ru",
          "postCode": [
            "90180",
            "90220"
          ]
        },
        {
          "id": 900903,
          "name": "Khuha Tai",
          "postCode": [
            "90180"
          ]
        },
        {
          "id": 900902,
          "name": "Tha Chamuang",
          "postCode": [
            "90180"
          ]
        }
      ]
    },
    {
      "city": "Saba Yoi",
      "cityCode": 9006,
      "provinceCode": 90,
      "subDistrict": [
        {
          "id": 900608,
          "name": "Ba Hoi",
          "postCode": [
            "90210"
          ]
        },
        {
          "id": 900604,
          "name": "Ban Not",
          "postCode": [
            "90210"
          ]
        },
        {
          "id": 900605,
          "name": "Chanae",
          "postCode": [
            "90210"
          ]
        },
        {
          "id": 900607,
          "name": "Khao Daeng",
          "postCode": [
            "90210"
          ]
        },
        {
          "id": 900606,
          "name": "Khuha",
          "postCode": [
            "90210"
          ]
        },
        {
          "id": 900603,
          "name": "Pian",
          "postCode": [
            "90210"
          ]
        },
        {
          "id": 900601,
          "name": "Saba Yoi",
          "postCode": [
            "90210"
          ]
        },
        {
          "id": 900609,
          "name": "Than Khiri",
          "postCode": [
            "90210"
          ]
        },
        {
          "id": 900602,
          "name": "Thung Pho",
          "postCode": [
            "90210"
          ]
        }
      ]
    },
    {
      "city": "Sadao",
      "cityCode": 9010,
      "provinceCode": 90,
      "subDistrict": [
        {
          "id": 901009,
          "name": "Khao Mi Kiat",
          "postCode": [
            "90170"
          ]
        },
        {
          "id": 901007,
          "name": "Padang Besa",
          "postCode": [
            "90240"
          ]
        },
        {
          "id": 901003,
          "name": "Phang La",
          "postCode": [
            "90170"
          ]
        },
        {
          "id": 901002,
          "name": "Prik",
          "postCode": [
            "90120"
          ]
        },
        {
          "id": 901001,
          "name": "Sadao",
          "postCode": [
            "90120"
          ]
        },
        {
          "id": 901008,
          "name": "Samnak Kham",
          "postCode": [
            "90120",
            "90320"
          ]
        },
        {
          "id": 901004,
          "name": "Samnak Taeo",
          "postCode": [
            "90120"
          ]
        },
        {
          "id": 901006,
          "name": "Tha Pho",
          "postCode": [
            "90170"
          ]
        },
        {
          "id": 901005,
          "name": "Thung Mo",
          "postCode": [
            "90240"
          ]
        }
      ]
    },
    {
      "city": "Sathing Phra",
      "cityCode": 9002,
      "provinceCode": 90,
      "subDistrict": [
        {
          "id": 900210,
          "name": "Bo Daeng",
          "postCode": [
            "90190"
          ]
        },
        {
          "id": 900211,
          "name": "Bo Dan",
          "postCode": [
            "90190"
          ]
        },
        {
          "id": 900201,
          "name": "Chathing Phra",
          "postCode": [
            "90190"
          ]
        },
        {
          "id": 900205,
          "name": "Chumphon",
          "postCode": [
            "90190"
          ]
        },
        {
          "id": 900204,
          "name": "Di Luang",
          "postCode": [
            "90190"
          ]
        },
        {
          "id": 900206,
          "name": "Khlong Ri",
          "postCode": [
            "90190"
          ]
        },
        {
          "id": 900207,
          "name": "Khu Khut",
          "postCode": [
            "90190"
          ]
        },
        {
          "id": 900202,
          "name": "Kradang-nga",
          "postCode": [
            "90190"
          ]
        },
        {
          "id": 900203,
          "name": "Sanam Chai",
          "postCode": [
            "90190"
          ]
        },
        {
          "id": 900208,
          "name": "Tha Hin",
          "postCode": [
            "90190"
          ]
        },
        {
          "id": 900209,
          "name": "Wat Chan",
          "postCode": [
            "90190"
          ]
        }
      ]
    },
    {
      "city": "Singhanakhon",
      "cityCode": 9015,
      "provinceCode": 90,
      "subDistrict": [
        {
          "id": 901510,
          "name": "Bang Khiat",
          "postCode": [
            "90330"
          ]
        },
        {
          "id": 901506,
          "name": "Chalae",
          "postCode": [
            "90330"
          ]
        },
        {
          "id": 901501,
          "name": "Ching Kho",
          "postCode": [
            "90280"
          ]
        },
        {
          "id": 901509,
          "name": "Hua Khao",
          "postCode": [
            "90280"
          ]
        },
        {
          "id": 901511,
          "name": "Muang Ngam",
          "postCode": [
            "90330"
          ]
        },
        {
          "id": 901508,
          "name": "Pa Khat",
          "postCode": [
            "90330"
          ]
        },
        {
          "id": 901507,
          "name": "Pak Ro",
          "postCode": [
            "90330"
          ]
        },
        {
          "id": 901504,
          "name": "Ram Daeng",
          "postCode": [
            "90330"
          ]
        },
        {
          "id": 901502,
          "name": "Sathing Mo",
          "postCode": [
            "90280"
          ]
        },
        {
          "id": 901503,
          "name": "Thamnop",
          "postCode": [
            "90280"
          ]
        },
        {
          "id": 901505,
          "name": "Wat Khanun",
          "postCode": [
            "90330"
          ]
        }
      ]
    },
    {
      "city": "Thepha",
      "cityCode": 9005,
      "provinceCode": 90,
      "subDistrict": [
        {
          "id": 900503,
          "name": "Ko Saba",
          "postCode": [
            "90150"
          ]
        },
        {
          "id": 900504,
          "name": "Lam Phlai",
          "postCode": [
            "90260"
          ]
        },
        {
          "id": 900502,
          "name": "Pak Bang",
          "postCode": [
            "90150"
          ]
        },
        {
          "id": 900507,
          "name": "Sakom",
          "postCode": [
            "90150"
          ]
        },
        {
          "id": 900505,
          "name": "Tha Muang",
          "postCode": [
            "90150",
            "90260"
          ]
        },
        {
          "id": 900501,
          "name": "Thepha",
          "postCode": [
            "90150"
          ]
        },
        {
          "id": 900506,
          "name": "Wang Yai",
          "postCode": [
            "90260"
          ]
        }
      ]
    },
    {
      "city": "Ban Dan Lan Hoi",
      "cityCode": 6402,
      "provinceCode": 64,
      "subDistrict": [
        {
          "id": 640202,
          "name": "Ban Dan",
          "postCode": [
            "64140"
          ]
        },
        {
          "id": 640201,
          "name": "Lan Hoi",
          "postCode": [
            "64140"
          ]
        },
        {
          "id": 640206,
          "name": "Nong Ya Plong",
          "postCode": [
            "64140"
          ]
        },
        {
          "id": 640205,
          "name": "Taling Chan",
          "postCode": [
            "64140"
          ]
        },
        {
          "id": 640207,
          "name": "Wang Luek",
          "postCode": [
            "64140"
          ]
        },
        {
          "id": 640204,
          "name": "Wang Nam Khao",
          "postCode": [
            "64140"
          ]
        },
        {
          "id": 640203,
          "name": "Wang Takhro",
          "postCode": [
            "64140"
          ]
        }
      ]
    },
    {
      "city": "Khiri Mat",
      "cityCode": 6403,
      "provinceCode": 64,
      "subDistrict": [
        {
          "id": 640309,
          "name": "Ban Nam Phu",
          "postCode": [
            "64160"
          ]
        },
        {
          "id": 640303,
          "name": "Ban Pom",
          "postCode": [
            "64160"
          ]
        },
        {
          "id": 640307,
          "name": "Na Choeng Khiri",
          "postCode": [
            "64160"
          ]
        },
        {
          "id": 640306,
          "name": "Nong Chik",
          "postCode": [
            "64160"
          ]
        },
        {
          "id": 640308,
          "name": "Nong Krading",
          "postCode": [
            "64160"
          ]
        },
        {
          "id": 640304,
          "name": "Sam Phuang",
          "postCode": [
            "64160"
          ]
        },
        {
          "id": 640305,
          "name": "Si Khiri Mat",
          "postCode": [
            "64160"
          ]
        },
        {
          "id": 640301,
          "name": "Tanot",
          "postCode": [
            "64160"
          ]
        },
        {
          "id": 640302,
          "name": "Thung Luang",
          "postCode": [
            "64160"
          ]
        },
        {
          "id": 640310,
          "name": "Thung Yang Mueang",
          "postCode": [
            "64160"
          ]
        }
      ]
    },
    {
      "city": "Kong Krailat",
      "cityCode": 6404,
      "provinceCode": 64,
      "subDistrict": [
        {
          "id": 640402,
          "name": "Ban Krang",
          "postCode": [
            "64170"
          ]
        },
        {
          "id": 640411,
          "name": "Ban Mai Suk Kasem",
          "postCode": [
            "64170"
          ]
        },
        {
          "id": 640406,
          "name": "Dong Dueai",
          "postCode": [
            "64170"
          ]
        },
        {
          "id": 640408,
          "name": "Kok Raet",
          "postCode": [
            "64170"
          ]
        },
        {
          "id": 640401,
          "name": "Kong",
          "postCode": [
            "64170"
          ]
        },
        {
          "id": 640404,
          "name": "Krai Klang",
          "postCode": [
            "64170"
          ]
        },
        {
          "id": 640405,
          "name": "Krai Nai",
          "postCode": [
            "64170"
          ]
        },
        {
          "id": 640403,
          "name": "Krai Nok",
          "postCode": [
            "64170"
          ]
        },
        {
          "id": 640410,
          "name": "Nong Tum",
          "postCode": [
            "64170"
          ]
        },
        {
          "id": 640407,
          "name": "Pa Faek",
          "postCode": [
            "64170"
          ]
        },
        {
          "id": 640409,
          "name": "Tha Chanuan",
          "postCode": [
            "64170"
          ]
        }
      ]
    },
    {
      "city": "Mueang Sukhothai",
      "cityCode": 6401,
      "provinceCode": 64,
      "subDistrict": [
        {
          "id": 640106,
          "name": "Ban Kluai",
          "postCode": [
            "64000",
            "64210"
          ]
        },
        {
          "id": 640107,
          "name": "Ban Lum",
          "postCode": [
            "64000"
          ]
        },
        {
          "id": 640102,
          "name": "Ban Suan",
          "postCode": [
            "64220"
          ]
        },
        {
          "id": 640103,
          "name": "Mueang Kao",
          "postCode": [
            "64210"
          ]
        },
        {
          "id": 640104,
          "name": "Pak Khwae",
          "postCode": [
            "64000"
          ]
        },
        {
          "id": 640109,
          "name": "Pak Phra",
          "postCode": [
            "64000"
          ]
        },
        {
          "id": 640108,
          "name": "Tan Tia",
          "postCode": [
            "64220"
          ]
        },
        {
          "id": 640101,
          "name": "Thani",
          "postCode": [
            "64000"
          ]
        },
        {
          "id": 640110,
          "name": "Wang Thong Daeng",
          "postCode": [
            "64210"
          ]
        },
        {
          "id": 640105,
          "name": "Yang Sai",
          "postCode": [
            "64000"
          ]
        }
      ]
    },
    {
      "city": "Sawankhalok",
      "cityCode": 6407,
      "provinceCode": 64,
      "subDistrict": [
        {
          "id": 640703,
          "name": "Khlong Krachong",
          "postCode": [
            "64110"
          ]
        },
        {
          "id": 640708,
          "name": "Khlong Yang",
          "postCode": [
            "64110"
          ]
        },
        {
          "id": 640713,
          "name": "Mueang Bang Khlang",
          "postCode": [
            "64110"
          ]
        },
        {
          "id": 640709,
          "name": "Mueang Bang Yom",
          "postCode": [
            "64110"
          ]
        },
        {
          "id": 640701,
          "name": "Mueang Sawankhalok",
          "postCode": [
            "64110"
          ]
        },
        {
          "id": 640707,
          "name": "Na Thung",
          "postCode": [
            "64110"
          ]
        },
        {
          "id": 640702,
          "name": "Nai Mueang",
          "postCode": [
            "64110"
          ]
        },
        {
          "id": 640714,
          "name": "Nong Klap",
          "postCode": [
            "64110"
          ]
        },
        {
          "id": 640712,
          "name": "Pa Kum Ko",
          "postCode": [
            "64110"
          ]
        },
        {
          "id": 640711,
          "name": "Pak Nam",
          "postCode": [
            "64110"
          ]
        },
        {
          "id": 640710,
          "name": "Tha Tong",
          "postCode": [
            "64110"
          ]
        },
        {
          "id": 640705,
          "name": "Wang Mai Khon",
          "postCode": [
            "64110"
          ]
        },
        {
          "id": 640704,
          "name": "Wang Phinphat",
          "postCode": [
            "64110"
          ]
        },
        {
          "id": 640706,
          "name": "Yan Yao",
          "postCode": [
            "64110"
          ]
        }
      ]
    },
    {
      "city": "Si Nakhon",
      "cityCode": 6408,
      "provinceCode": 64,
      "subDistrict": [
        {
          "id": 640804,
          "name": "Khlong Maphlap",
          "postCode": [
            "64180"
          ]
        },
        {
          "id": 640802,
          "name": "Nakhon Doet",
          "postCode": [
            "64180"
          ]
        },
        {
          "id": 640803,
          "name": "Nam Khum",
          "postCode": [
            "64180"
          ]
        },
        {
          "id": 640805,
          "name": "Nong Bua",
          "postCode": [
            "64180"
          ]
        },
        {
          "id": 640801,
          "name": "Si Nakhon",
          "postCode": [
            "64180"
          ]
        }
      ]
    },
    {
      "city": "Si Samrong",
      "cityCode": 6406,
      "provinceCode": 64,
      "subDistrict": [
        {
          "id": 640604,
          "name": "Ban Na",
          "postCode": [
            "64120"
          ]
        },
        {
          "id": 640609,
          "name": "Ban Rai",
          "postCode": [
            "64120"
          ]
        },
        {
          "id": 640611,
          "name": "Ban San",
          "postCode": [
            "64120"
          ]
        },
        {
          "id": 640601,
          "name": "Khlong Tan",
          "postCode": [
            "64120"
          ]
        },
        {
          "id": 640607,
          "name": "Ko Ta Liang",
          "postCode": [
            "64120"
          ]
        },
        {
          "id": 640606,
          "name": "Na Khun Krai",
          "postCode": [
            "64120"
          ]
        },
        {
          "id": 640613,
          "name": "Rao Ton Chan",
          "postCode": [
            "64120"
          ]
        },
        {
          "id": 640603,
          "name": "Sam Ruean",
          "postCode": [
            "64120"
          ]
        },
        {
          "id": 640610,
          "name": "Thap Phueng",
          "postCode": [
            "64120"
          ]
        },
        {
          "id": 640602,
          "name": "Wang Luek",
          "postCode": [
            "64120"
          ]
        },
        {
          "id": 640605,
          "name": "Wang Thong",
          "postCode": [
            "64120"
          ]
        },
        {
          "id": 640612,
          "name": "Wang Yai",
          "postCode": [
            "64120"
          ]
        },
        {
          "id": 640608,
          "name": "Wat Ko",
          "postCode": [
            "64120"
          ]
        }
      ]
    },
    {
      "city": "Si Satchanalai",
      "cityCode": 6405,
      "provinceCode": 64,
      "subDistrict": [
        {
          "id": 640510,
          "name": "Ban Kaeng",
          "postCode": [
            "64130"
          ]
        },
        {
          "id": 640505,
          "name": "Ban Tuek",
          "postCode": [
            "64130"
          ]
        },
        {
          "id": 640509,
          "name": "Dong Khu",
          "postCode": [
            "64130"
          ]
        },
        {
          "id": 640501,
          "name": "Hat Siao",
          "postCode": [
            "64130"
          ]
        },
        {
          "id": 640503,
          "name": "Mae Sam",
          "postCode": [
            "64130"
          ]
        },
        {
          "id": 640504,
          "name": "Mae Sin",
          "postCode": [
            "64130"
          ]
        },
        {
          "id": 640506,
          "name": "Nong O",
          "postCode": [
            "64130"
          ]
        },
        {
          "id": 640502,
          "name": "Pa Ngio",
          "postCode": [
            "64130"
          ]
        },
        {
          "id": 640511,
          "name": "San Chit",
          "postCode": [
            "64130"
          ]
        },
        {
          "id": 640508,
          "name": "Si Satchanalai",
          "postCode": [
            "64190"
          ]
        },
        {
          "id": 640507,
          "name": "Tha Chai",
          "postCode": [
            "64190"
          ]
        }
      ]
    },
    {
      "city": "Thung Saliam",
      "cityCode": 6409,
      "provinceCode": 64,
      "subDistrict": [
        {
          "id": 640901,
          "name": "Ban Mai Chai Mongkhon",
          "postCode": [
            "64230"
          ]
        },
        {
          "id": 640905,
          "name": "Khao Kaeo Si Sombun",
          "postCode": [
            "64230"
          ]
        },
        {
          "id": 640904,
          "name": "Klang Dong",
          "postCode": [
            "64150"
          ]
        },
        {
          "id": 640902,
          "name": "Thai Chana Suek",
          "postCode": [
            "64150"
          ]
        },
        {
          "id": 640903,
          "name": "Thung Saliam",
          "postCode": [
            "64150"
          ]
        }
      ]
    },
    {
      "city": "Bang Pla Ma",
      "cityCode": 7204,
      "provinceCode": 72,
      "subDistrict": [
        {
          "id": 720410,
          "name": "Ban Laem",
          "postCode": [
            "72150"
          ]
        },
        {
          "id": 720402,
          "name": "Bang Pla Ma",
          "postCode": [
            "72150"
          ]
        },
        {
          "id": 720404,
          "name": "Bang Yai",
          "postCode": [
            "72150"
          ]
        },
        {
          "id": 720409,
          "name": "Chorakhe Yai",
          "postCode": [
            "72150"
          ]
        },
        {
          "id": 720401,
          "name": "Khok Khram",
          "postCode": [
            "72150"
          ]
        },
        {
          "id": 720405,
          "name": "Kritsana",
          "postCode": [
            "72150"
          ]
        },
        {
          "id": 720411,
          "name": "Makham Lom",
          "postCode": [
            "72150"
          ]
        },
        {
          "id": 720408,
          "name": "Ongkharak",
          "postCode": [
            "72150"
          ]
        },
        {
          "id": 720407,
          "name": "Phai Kong Din",
          "postCode": [
            "72150"
          ]
        },
        {
          "id": 720406,
          "name": "Sali",
          "postCode": [
            "72150"
          ]
        },
        {
          "id": 720403,
          "name": "Takha",
          "postCode": [
            "72150"
          ]
        },
        {
          "id": 720412,
          "name": "Wang Nam Yen",
          "postCode": [
            "72150"
          ]
        },
        {
          "id": 720413,
          "name": "Wat Bot",
          "postCode": [
            "72150"
          ]
        },
        {
          "id": 720414,
          "name": "Wat Dao",
          "postCode": [
            "72150"
          ]
        }
      ]
    },
    {
      "city": "Dan Chang",
      "cityCode": 7203,
      "provinceCode": 72,
      "subDistrict": [
        {
          "id": 720302,
          "name": "Dan Chang",
          "postCode": [
            "72180"
          ]
        },
        {
          "id": 720303,
          "name": "Huai Khamin",
          "postCode": [
            "72180"
          ]
        },
        {
          "id": 720306,
          "name": "Nikhom Krasiao",
          "postCode": [
            "72180"
          ]
        },
        {
          "id": 720301,
          "name": "Nong Makha Mong",
          "postCode": [
            "72180"
          ]
        },
        {
          "id": 720304,
          "name": "Ong Phra",
          "postCode": [
            "72180"
          ]
        },
        {
          "id": 720305,
          "name": "Wang Khan",
          "postCode": [
            "72180"
          ]
        },
        {
          "id": 720307,
          "name": "Wang Yao",
          "postCode": [
            "72180"
          ]
        }
      ]
    },
    {
      "city": "Doem Bang Nang Buat",
      "cityCode": 7202,
      "provinceCode": 72,
      "subDistrict": [
        {
          "id": 720210,
          "name": "Bo Kru",
          "postCode": [
            "72120"
          ]
        },
        {
          "id": 720202,
          "name": "Doem Bang",
          "postCode": [
            "72120"
          ]
        },
        {
          "id": 720208,
          "name": "Hua Khao",
          "postCode": [
            "72120"
          ]
        },
        {
          "id": 720209,
          "name": "Hua Na",
          "postCode": [
            "72120"
          ]
        },
        {
          "id": 720204,
          "name": "Khao Din",
          "postCode": [
            "72120"
          ]
        },
        {
          "id": 720201,
          "name": "Khao Phra",
          "postCode": [
            "72120"
          ]
        },
        {
          "id": 720207,
          "name": "Khok Chang",
          "postCode": [
            "72120"
          ]
        },
        {
          "id": 720203,
          "name": "Nang Buat",
          "postCode": [
            "72120"
          ]
        },
        {
          "id": 720214,
          "name": "Nong Krathum",
          "postCode": [
            "72120"
          ]
        },
        {
          "id": 720212,
          "name": "Pa Sakae",
          "postCode": [
            "72120"
          ]
        },
        {
          "id": 720205,
          "name": "Pak Nam",
          "postCode": [
            "72120"
          ]
        },
        {
          "id": 720206,
          "name": "Thung Khli",
          "postCode": [
            "72120"
          ]
        },
        {
          "id": 720211,
          "name": "Wang Si Rat",
          "postCode": [
            "72120"
          ]
        },
        {
          "id": 720213,
          "name": "Yang Non",
          "postCode": [
            "72120"
          ]
        }
      ]
    },
    {
      "city": "Don Chedi",
      "cityCode": 7206,
      "provinceCode": 72,
      "subDistrict": [
        {
          "id": 720601,
          "name": "Don Chedi",
          "postCode": [
            "72170"
          ]
        },
        {
          "id": 720602,
          "name": "Nong Sarai",
          "postCode": [
            "72170"
          ]
        },
        {
          "id": 720603,
          "name": "Rai Rot",
          "postCode": [
            "72170"
          ]
        },
        {
          "id": 720604,
          "name": "Sa Krachom",
          "postCode": [
            "72250"
          ]
        },
        {
          "id": 720605,
          "name": "Thale Bok",
          "postCode": [
            "72170",
            "72250"
          ]
        }
      ]
    },
    {
      "city": "Mueang Suphan Buri",
      "cityCode": 7201,
      "provinceCode": 72,
      "subDistrict": [
        {
          "id": 720115,
          "name": "Bang Kung",
          "postCode": [
            "72210"
          ]
        },
        {
          "id": 720112,
          "name": "Bang Pho",
          "postCode": [
            "72000"
          ]
        },
        {
          "id": 720110,
          "name": "Don Kamyan",
          "postCode": [
            "72000"
          ]
        },
        {
          "id": 720108,
          "name": "Don Masang",
          "postCode": [
            "72000"
          ]
        },
        {
          "id": 720111,
          "name": "Don Pho Thong",
          "postCode": [
            "72000"
          ]
        },
        {
          "id": 720107,
          "name": "Don Tan",
          "postCode": [
            "72000"
          ]
        },
        {
          "id": 720106,
          "name": "Khok Kho Thao",
          "postCode": [
            "72000"
          ]
        },
        {
          "id": 720105,
          "name": "Phai Khwang",
          "postCode": [
            "72000"
          ]
        },
        {
          "id": 720109,
          "name": "Phihan Daeng",
          "postCode": [
            "72000"
          ]
        },
        {
          "id": 720119,
          "name": "Pho Phraya",
          "postCode": [
            "72000"
          ]
        },
        {
          "id": 720102,
          "name": "Rua Yai",
          "postCode": [
            "72000"
          ]
        },
        {
          "id": 720113,
          "name": "Sa Kaeo",
          "postCode": [
            "72230"
          ]
        },
        {
          "id": 720116,
          "name": "Sala Khao",
          "postCode": [
            "72210"
          ]
        },
        {
          "id": 720118,
          "name": "Sanam Chai",
          "postCode": [
            "72000"
          ]
        },
        {
          "id": 720120,
          "name": "Sanam Khli",
          "postCode": [
            "72230"
          ]
        },
        {
          "id": 720117,
          "name": "Suan Taeng",
          "postCode": [
            "72210"
          ]
        },
        {
          "id": 720114,
          "name": "Taling Chan",
          "postCode": [
            "72230"
          ]
        },
        {
          "id": 720101,
          "name": "Tha Phi Liang",
          "postCode": [
            "72000"
          ]
        },
        {
          "id": 720104,
          "name": "Tha Rahat",
          "postCode": [
            "72000"
          ]
        },
        {
          "id": 720103,
          "name": "Thap Ti Lek",
          "postCode": [
            "72000"
          ]
        }
      ]
    },
    {
      "city": "Nong Ya Sai",
      "cityCode": 7210,
      "provinceCode": 72,
      "subDistrict": [
        {
          "id": 721004,
          "name": "Chaeng Ngam",
          "postCode": [
            "72240"
          ]
        },
        {
          "id": 721005,
          "name": "Nong Kham",
          "postCode": [
            "72240"
          ]
        },
        {
          "id": 721003,
          "name": "Nong Pho",
          "postCode": [
            "72240"
          ]
        },
        {
          "id": 721002,
          "name": "Nong Ratchawat",
          "postCode": [
            "72240"
          ]
        },
        {
          "id": 721001,
          "name": "Nong Ya Sai",
          "postCode": [
            "72240"
          ]
        },
        {
          "id": 721006,
          "name": "Thap Luang",
          "postCode": [
            "72240"
          ]
        }
      ]
    },
    {
      "city": "Sam Chuk",
      "cityCode": 7208,
      "provinceCode": 72,
      "subDistrict": [
        {
          "id": 720805,
          "name": "Ban Sa",
          "postCode": [
            "72130"
          ]
        },
        {
          "id": 720807,
          "name": "Krasiao",
          "postCode": [
            "72130"
          ]
        },
        {
          "id": 720804,
          "name": "Nong Phak Nak",
          "postCode": [
            "72130"
          ]
        },
        {
          "id": 720806,
          "name": "Nong Sadao",
          "postCode": [
            "72130"
          ]
        },
        {
          "id": 720803,
          "name": "Sam Chuk",
          "postCode": [
            "72130"
          ]
        },
        {
          "id": 720802,
          "name": "Wang Luek",
          "postCode": [
            "72130"
          ]
        },
        {
          "id": 720801,
          "name": "Yan Yao",
          "postCode": [
            "72130"
          ]
        }
      ]
    },
    {
      "city": "Si Prachan",
      "cityCode": 7205,
      "provinceCode": 72,
      "subDistrict": [
        {
          "id": 720502,
          "name": "Ban Krang",
          "postCode": [
            "72140"
          ]
        },
        {
          "id": 720504,
          "name": "Bang Ngam",
          "postCode": [
            "72140"
          ]
        },
        {
          "id": 720505,
          "name": "Don Pru",
          "postCode": [
            "72140"
          ]
        },
        {
          "id": 720503,
          "name": "Mot Daeng",
          "postCode": [
            "72140"
          ]
        },
        {
          "id": 720506,
          "name": "Plai Na",
          "postCode": [
            "72140"
          ]
        },
        {
          "id": 720501,
          "name": "Si Prachan",
          "postCode": [
            "72140"
          ]
        },
        {
          "id": 720508,
          "name": "Wang Nam Sap",
          "postCode": [
            "72140"
          ]
        },
        {
          "id": 720507,
          "name": "Wang Wa",
          "postCode": [
            "72140"
          ]
        },
        {
          "id": 720509,
          "name": "Wang Yang",
          "postCode": [
            "72140"
          ]
        }
      ]
    },
    {
      "city": "Song Phi Nong",
      "cityCode": 7207,
      "provinceCode": 72,
      "subDistrict": [
        {
          "id": 720709,
          "name": "Ban Chang",
          "postCode": [
            "72110"
          ]
        },
        {
          "id": 720705,
          "name": "Ban Kum",
          "postCode": [
            "72110"
          ]
        },
        {
          "id": 720702,
          "name": "Bang Len",
          "postCode": [
            "72110"
          ]
        },
        {
          "id": 720707,
          "name": "Bang Phlap",
          "postCode": [
            "72110"
          ]
        },
        {
          "id": 720703,
          "name": "Bang Ta Then",
          "postCode": [
            "72110"
          ]
        },
        {
          "id": 720704,
          "name": "Bang Takhian",
          "postCode": [
            "72110"
          ]
        },
        {
          "id": 720714,
          "name": "Bo Suphan",
          "postCode": [
            "72190"
          ]
        },
        {
          "id": 720715,
          "name": "Don Manao",
          "postCode": [
            "72110"
          ]
        },
        {
          "id": 720706,
          "name": "Hua Pho",
          "postCode": [
            "72110"
          ]
        },
        {
          "id": 720708,
          "name": "Noen Phra Prang",
          "postCode": [
            "72110"
          ]
        },
        {
          "id": 720713,
          "name": "Nong Bo",
          "postCode": [
            "71170",
            "72190"
          ]
        },
        {
          "id": 720711,
          "name": "Si Samran",
          "postCode": [
            "72110",
            "72190"
          ]
        },
        {
          "id": 720701,
          "name": "Song Phi Nong",
          "postCode": [
            "72110"
          ]
        },
        {
          "id": 720712,
          "name": "Thung Khok",
          "postCode": [
            "72110",
            "72190"
          ]
        },
        {
          "id": 720710,
          "name": "Ton Tan",
          "postCode": [
            "72110"
          ]
        }
      ]
    },
    {
      "city": "U Thong",
      "cityCode": 7209,
      "provinceCode": 72,
      "subDistrict": [
        {
          "id": 720904,
          "name": "Ban Don",
          "postCode": [
            "72160"
          ]
        },
        {
          "id": 720910,
          "name": "Ban Khong",
          "postCode": [
            "72160"
          ]
        },
        {
          "id": 720911,
          "name": "Chedi",
          "postCode": [
            "72160"
          ]
        },
        {
          "id": 720903,
          "name": "Chorakhe Sam Phan",
          "postCode": [
            "71170",
            "72160"
          ]
        },
        {
          "id": 720908,
          "name": "Don Kha",
          "postCode": [
            "72160"
          ]
        },
        {
          "id": 720906,
          "name": "Don Makluea",
          "postCode": [
            "72220"
          ]
        },
        {
          "id": 720913,
          "name": "Krachan",
          "postCode": [
            "72160"
          ]
        },
        {
          "id": 720907,
          "name": "Nong Ong",
          "postCode": [
            "72160"
          ]
        },
        {
          "id": 720909,
          "name": "Phlapphla Chai",
          "postCode": [
            "72160"
          ]
        },
        {
          "id": 720912,
          "name": "Sa Phang Lan",
          "postCode": [
            "72220"
          ]
        },
        {
          "id": 720902,
          "name": "Sa Yai Som",
          "postCode": [
            "72220"
          ]
        },
        {
          "id": 720901,
          "name": "U Thong",
          "postCode": [
            "72160"
          ]
        },
        {
          "id": 720905,
          "name": "Yung Thalai",
          "postCode": [
            "72160"
          ]
        }
      ]
    },
    {
      "city": "Ban Na Doem",
      "cityCode": 8413,
      "provinceCode": 84,
      "subDistrict": [
        {
          "id": 841301,
          "name": "Ban Na",
          "postCode": [
            "84240"
          ]
        },
        {
          "id": 841304,
          "name": "Na Tai",
          "postCode": [
            "84240"
          ]
        },
        {
          "id": 841303,
          "name": "Sap Thawi",
          "postCode": [
            "84240"
          ]
        },
        {
          "id": 841302,
          "name": "Tha Ruea",
          "postCode": [
            "84240"
          ]
        }
      ]
    },
    {
      "city": "Ban Na San",
      "cityCode": 8412,
      "provinceCode": 84,
      "subDistrict": [
        {
          "id": 841208,
          "name": "Khlong Prap",
          "postCode": [
            "84120"
          ]
        },
        {
          "id": 841206,
          "name": "Khuan Si",
          "postCode": [
            "84270"
          ]
        },
        {
          "id": 841207,
          "name": "Khuan Suban",
          "postCode": [
            "84120",
            "84240"
          ]
        },
        {
          "id": 841204,
          "name": "Lamphun",
          "postCode": [
            "84120"
          ]
        },
        {
          "id": 841201,
          "name": "Na San",
          "postCode": [
            "84120"
          ]
        },
        {
          "id": 841209,
          "name": "Nam Phu",
          "postCode": [
            "84120"
          ]
        },
        {
          "id": 841211,
          "name": "Phoem Phun Sap",
          "postCode": [
            "84120"
          ]
        },
        {
          "id": 841202,
          "name": "Phru Phi",
          "postCode": [
            "84270"
          ]
        },
        {
          "id": 841205,
          "name": "Tha Chi",
          "postCode": [
            "84120"
          ]
        },
        {
          "id": 841203,
          "name": "Thung Tao",
          "postCode": [
            "84120"
          ]
        },
        {
          "id": 841210,
          "name": "Thung Tao Mai",
          "postCode": [
            "84120"
          ]
        }
      ]
    },
    {
      "city": "Ban Ta Khun",
      "cityCode": 8409,
      "provinceCode": 84,
      "subDistrict": [
        {
          "id": 840904,
          "name": "Khao Phang",
          "postCode": [
            "84230"
          ]
        },
        {
          "id": 840901,
          "name": "Khao Wong",
          "postCode": [
            "84230"
          ]
        },
        {
          "id": 840902,
          "name": "Phasaeng",
          "postCode": [
            "84230"
          ]
        },
        {
          "id": 840903,
          "name": "Phru Thai",
          "postCode": [
            "84230"
          ]
        }
      ]
    },
    {
      "city": "Chai Buri",
      "cityCode": 8418,
      "provinceCode": 84,
      "subDistrict": [
        {
          "id": 841802,
          "name": "Chai Buri",
          "postCode": [
            "84350"
          ]
        },
        {
          "id": 841803,
          "name": "Khlong Noi",
          "postCode": [
            "84350"
          ]
        },
        {
          "id": 841804,
          "name": "Sai Thong",
          "postCode": [
            "84350"
          ]
        },
        {
          "id": 841801,
          "name": "Song Phraek",
          "postCode": [
            "84350"
          ]
        }
      ]
    },
    {
      "city": "Chaiya",
      "cityCode": 8406,
      "provinceCode": 84,
      "subDistrict": [
        {
          "id": 840603,
          "name": "Lamet",
          "postCode": [
            "84110"
          ]
        },
        {
          "id": 840608,
          "name": "Mo Thai",
          "postCode": [
            "84110"
          ]
        },
        {
          "id": 840606,
          "name": "Pa We",
          "postCode": [
            "84110"
          ]
        },
        {
          "id": 840609,
          "name": "Pak Mak",
          "postCode": [
            "84110"
          ]
        },
        {
          "id": 840602,
          "name": "Phum Riang",
          "postCode": [
            "84110"
          ]
        },
        {
          "id": 840607,
          "name": "Takrop",
          "postCode": [
            "84110"
          ]
        },
        {
          "id": 840601,
          "name": "Talat Chaiya",
          "postCode": [
            "84110"
          ]
        },
        {
          "id": 840605,
          "name": "Thung",
          "postCode": [
            "84110"
          ]
        },
        {
          "id": 840604,
          "name": "Wiang",
          "postCode": [
            "84110"
          ]
        }
      ]
    },
    {
      "city": "Don Sak",
      "cityCode": 8403,
      "provinceCode": 84,
      "subDistrict": [
        {
          "id": 840303,
          "name": "Chaiyakhram",
          "postCode": [
            "84220"
          ]
        },
        {
          "id": 840302,
          "name": "Chonlakhram",
          "postCode": [
            "84160"
          ]
        },
        {
          "id": 840301,
          "name": "Don Sak",
          "postCode": [
            "84220"
          ]
        },
        {
          "id": 840304,
          "name": "Pak Phraek",
          "postCode": [
            "84340"
          ]
        }
      ]
    },
    {
      "city": "Kanchanadit",
      "cityCode": 8402,
      "provinceCode": 84,
      "subDistrict": [
        {
          "id": 840210,
          "name": "Chang Khwa",
          "postCode": [
            "84160"
          ]
        },
        {
          "id": 840206,
          "name": "Chang Sai",
          "postCode": [
            "84160"
          ]
        },
        {
          "id": 840203,
          "name": "Kadae",
          "postCode": [
            "84160"
          ]
        },
        {
          "id": 840213,
          "name": "Khlong Sa",
          "postCode": [
            "84160"
          ]
        },
        {
          "id": 840205,
          "name": "Krut",
          "postCode": [
            "84160"
          ]
        },
        {
          "id": 840208,
          "name": "Pa Ron",
          "postCode": [
            "84160"
          ]
        },
        {
          "id": 840207,
          "name": "Phlai Wat",
          "postCode": [
            "84160"
          ]
        },
        {
          "id": 840209,
          "name": "Takhian Thong",
          "postCode": [
            "84160"
          ]
        },
        {
          "id": 840202,
          "name": "Tha Thong",
          "postCode": [
            "84160"
          ]
        },
        {
          "id": 840201,
          "name": "Tha Thong Mai",
          "postCode": [
            "84290"
          ]
        },
        {
          "id": 840211,
          "name": "Tha U-thae",
          "postCode": [
            "84160",
            "84340"
          ]
        },
        {
          "id": 840204,
          "name": "Thung Kong",
          "postCode": [
            "84290"
          ]
        },
        {
          "id": 840212,
          "name": "Thung Rang",
          "postCode": [
            "84290"
          ]
        }
      ]
    },
    {
      "city": "Khian Sa",
      "cityCode": 8414,
      "provinceCode": 84,
      "subDistrict": [
        {
          "id": 841404,
          "name": "Aranyakham Wari",
          "postCode": [
            "84260"
          ]
        },
        {
          "id": 841405,
          "name": "Ban Sadet",
          "postCode": [
            "84260"
          ]
        },
        {
          "id": 841403,
          "name": "Khao Tok",
          "postCode": [
            "84260"
          ]
        },
        {
          "id": 841401,
          "name": "Khian Sa",
          "postCode": [
            "84260"
          ]
        },
        {
          "id": 841402,
          "name": "Phuang Phrom Khon",
          "postCode": [
            "84210"
          ]
        }
      ]
    },
    {
      "city": "Khiri Rat Nikhom",
      "cityCode": 8408,
      "provinceCode": 84,
      "subDistrict": [
        {
          "id": 840810,
          "name": "Ban Thamniap",
          "postCode": [
            "84180"
          ]
        },
        {
          "id": 840802,
          "name": "Ban Yang",
          "postCode": [
            "84180"
          ]
        },
        {
          "id": 840806,
          "name": "Kapao",
          "postCode": [
            "84180"
          ]
        },
        {
          "id": 840803,
          "name": "Nam Hak",
          "postCode": [
            "84180"
          ]
        },
        {
          "id": 840801,
          "name": "Tha Khanon",
          "postCode": [
            "84180"
          ]
        },
        {
          "id": 840807,
          "name": "Tha Kradan",
          "postCode": [
            "84180"
          ]
        },
        {
          "id": 840809,
          "name": "Tham Singkhon",
          "postCode": [
            "84180"
          ]
        },
        {
          "id": 840808,
          "name": "Yan Yao",
          "postCode": [
            "84180"
          ]
        }
      ]
    },
    {
      "city": "Ko Pha-ngan",
      "cityCode": 8405,
      "provinceCode": 84,
      "subDistrict": [
        {
          "id": 840502,
          "name": "Ban Tai",
          "postCode": [
            "84280"
          ]
        },
        {
          "id": 840501,
          "name": "Ko Pha-ngan",
          "postCode": [
            "84280"
          ]
        },
        {
          "id": 840503,
          "name": "Ko Tao",
          "postCode": [
            "84360"
          ]
        }
      ]
    },
    {
      "city": "Ko Samui",
      "cityCode": 8404,
      "provinceCode": 84,
      "subDistrict": [
        {
          "id": 840401,
          "name": "Ang Thong",
          "postCode": [
            "84140",
            "84220"
          ]
        },
        {
          "id": 840406,
          "name": "Bo Phut",
          "postCode": [
            "84320"
          ]
        },
        {
          "id": 840402,
          "name": "Lipa Noi",
          "postCode": [
            "84140"
          ]
        },
        {
          "id": 840407,
          "name": "Mae Nam",
          "postCode": [
            "84330"
          ]
        },
        {
          "id": 840405,
          "name": "Maret",
          "postCode": [
            "84310"
          ]
        },
        {
          "id": 840404,
          "name": "Na Mueang",
          "postCode": [
            "84140"
          ]
        },
        {
          "id": 840403,
          "name": "Taling Ngam",
          "postCode": [
            "84140"
          ]
        }
      ]
    },
    {
      "city": "Mueang Surat Thani",
      "cityCode": 8401,
      "provinceCode": 84,
      "subDistrict": [
        {
          "id": 840105,
          "name": "Bang Bai Mai",
          "postCode": [
            "84000"
          ]
        },
        {
          "id": 840106,
          "name": "Bang Chana",
          "postCode": [
            "84000"
          ]
        },
        {
          "id": 840110,
          "name": "Bang Kung",
          "postCode": [
            "84000"
          ]
        },
        {
          "id": 840109,
          "name": "Bang Pho",
          "postCode": [
            "84000"
          ]
        },
        {
          "id": 840108,
          "name": "Bang Sai",
          "postCode": [
            "84000"
          ]
        },
        {
          "id": 840111,
          "name": "Khlong Chanak",
          "postCode": [
            "84000"
          ]
        },
        {
          "id": 840107,
          "name": "Khlong Noi",
          "postCode": [
            "84000"
          ]
        },
        {
          "id": 840104,
          "name": "Khun Thale",
          "postCode": [
            "84100"
          ]
        },
        {
          "id": 840102,
          "name": "Makham Tia",
          "postCode": [
            "84000"
          ]
        },
        {
          "id": 840101,
          "name": "Talat",
          "postCode": [
            "84000"
          ]
        },
        {
          "id": 840103,
          "name": "Wat Pradu",
          "postCode": [
            "84000"
          ]
        }
      ]
    },
    {
      "city": "Phanom",
      "cityCode": 8410,
      "provinceCode": 84,
      "subDistrict": [
        {
          "id": 841006,
          "name": "Khlong Cha-un",
          "postCode": [
            "84250"
          ]
        },
        {
          "id": 841003,
          "name": "Khlong Sok",
          "postCode": [
            "84250"
          ]
        },
        {
          "id": 841005,
          "name": "Phang Kan",
          "postCode": [
            "84250"
          ]
        },
        {
          "id": 841001,
          "name": "Phanom",
          "postCode": [
            "84250"
          ]
        },
        {
          "id": 841004,
          "name": "Phlu Thuean",
          "postCode": [
            "84250"
          ]
        },
        {
          "id": 841002,
          "name": "Ton Yuan",
          "postCode": [
            "84250"
          ]
        }
      ]
    },
    {
      "city": "Phrasaeng",
      "cityCode": 8416,
      "provinceCode": 84,
      "subDistrict": [
        {
          "id": 841603,
          "name": "Bang Sawan",
          "postCode": [
            "84210"
          ]
        },
        {
          "id": 841601,
          "name": "I-pan",
          "postCode": [
            "84210"
          ]
        },
        {
          "id": 841604,
          "name": "Sai Khueng",
          "postCode": [
            "84210"
          ]
        },
        {
          "id": 841606,
          "name": "Sai Sopha",
          "postCode": [
            "84210"
          ]
        },
        {
          "id": 841607,
          "name": "Sakhu",
          "postCode": [
            "84210"
          ]
        },
        {
          "id": 841605,
          "name": "Sin Charoen",
          "postCode": [
            "84210"
          ]
        },
        {
          "id": 841602,
          "name": "Sin Pun",
          "postCode": [
            "84210"
          ]
        }
      ]
    },
    {
      "city": "Phunphin",
      "cityCode": 8417,
      "provinceCode": 84,
      "subDistrict": [
        {
          "id": 841705,
          "name": "Bang Duean",
          "postCode": [
            "84130"
          ]
        },
        {
          "id": 841704,
          "name": "Bang Maduea",
          "postCode": [
            "84130"
          ]
        },
        {
          "id": 841709,
          "name": "Bang Ngon",
          "postCode": [
            "84130"
          ]
        },
        {
          "id": 841713,
          "name": "Hua Toei",
          "postCode": [
            "84130"
          ]
        },
        {
          "id": 841715,
          "name": "Khao Hua Khwai",
          "postCode": [
            "84130"
          ]
        },
        {
          "id": 841707,
          "name": "Krut",
          "postCode": [
            "84130"
          ]
        },
        {
          "id": 841703,
          "name": "Lilet",
          "postCode": [
            "84130"
          ]
        },
        {
          "id": 841712,
          "name": "Maluan",
          "postCode": [
            "84130"
          ]
        },
        {
          "id": 841711,
          "name": "Nam Rop",
          "postCode": [
            "84130"
          ]
        },
        {
          "id": 841714,
          "name": "Nong Sai",
          "postCode": [
            "84130"
          ]
        },
        {
          "id": 841708,
          "name": "Phunphin",
          "postCode": [
            "84130"
          ]
        },
        {
          "id": 841710,
          "name": "Si Wichai",
          "postCode": [
            "84130"
          ]
        },
        {
          "id": 841716,
          "name": "Tapan",
          "postCode": [
            "84130"
          ]
        },
        {
          "id": 841701,
          "name": "Tha Kham",
          "postCode": [
            "84130"
          ]
        },
        {
          "id": 841706,
          "name": "Tha Rong Chang",
          "postCode": [
            "84130"
          ]
        },
        {
          "id": 841702,
          "name": "Tha Sathon",
          "postCode": [
            "84130"
          ]
        }
      ]
    },
    {
      "city": "Tha Chana",
      "cityCode": 8407,
      "provinceCode": 84,
      "subDistrict": [
        {
          "id": 840704,
          "name": "Khan Thuli",
          "postCode": [
            "84170"
          ]
        },
        {
          "id": 840706,
          "name": "Khlong Pha",
          "postCode": [
            "84170"
          ]
        },
        {
          "id": 840703,
          "name": "Prasong",
          "postCode": [
            "84170"
          ]
        },
        {
          "id": 840702,
          "name": "Samo Thong",
          "postCode": [
            "84170"
          ]
        },
        {
          "id": 840701,
          "name": "Tha Chana",
          "postCode": [
            "84170"
          ]
        },
        {
          "id": 840705,
          "name": "Wang",
          "postCode": [
            "84170"
          ]
        }
      ]
    },
    {
      "city": "Tha Chang",
      "cityCode": 8411,
      "provinceCode": 84,
      "subDistrict": [
        {
          "id": 841104,
          "name": "Khao Than",
          "postCode": [
            "84150"
          ]
        },
        {
          "id": 841103,
          "name": "Khlong Sai",
          "postCode": [
            "84150"
          ]
        },
        {
          "id": 841106,
          "name": "Pak Chalui",
          "postCode": [
            "84150"
          ]
        },
        {
          "id": 841105,
          "name": "Sawiat",
          "postCode": [
            "84150"
          ]
        },
        {
          "id": 841101,
          "name": "Tha Chang",
          "postCode": [
            "84150"
          ]
        },
        {
          "id": 841102,
          "name": "Tha Khoei",
          "postCode": [
            "84150"
          ]
        }
      ]
    },
    {
      "city": "Vibhavadi",
      "cityCode": 8419,
      "provinceCode": 84,
      "subDistrict": [
        {
          "id": 841902,
          "name": "Takuk Nuea",
          "postCode": [
            "84370"
          ]
        },
        {
          "id": 841901,
          "name": "Takuk Tai",
          "postCode": [
            "84370"
          ]
        }
      ]
    },
    {
      "city": "Wiang Sa",
      "cityCode": 8415,
      "provinceCode": 84,
      "subDistrict": [
        {
          "id": 841502,
          "name": "Ban Song",
          "postCode": [
            "84190"
          ]
        },
        {
          "id": 841505,
          "name": "Khao Niphan",
          "postCode": [
            "84190"
          ]
        },
        {
          "id": 841503,
          "name": "Khlong Chanuan",
          "postCode": [
            "84190"
          ]
        },
        {
          "id": 841504,
          "name": "Thung Luang",
          "postCode": [
            "84190"
          ]
        },
        {
          "id": 841501,
          "name": "Wiang Sa",
          "postCode": [
            "84190"
          ]
        }
      ]
    },
    {
      "city": "Buachet",
      "cityCode": 3213,
      "provinceCode": 32,
      "subDistrict": [
        {
          "id": 321305,
          "name": "A Phon",
          "postCode": [
            "32230"
          ]
        },
        {
          "id": 321301,
          "name": "Buachet",
          "postCode": [
            "32230"
          ]
        },
        {
          "id": 321303,
          "name": "Charat",
          "postCode": [
            "32230"
          ]
        },
        {
          "id": 321302,
          "name": "Sadao",
          "postCode": [
            "32230"
          ]
        },
        {
          "id": 321306,
          "name": "Samphao Lun",
          "postCode": [
            "32230"
          ]
        },
        {
          "id": 321304,
          "name": "Ta Wang",
          "postCode": [
            "32230"
          ]
        }
      ]
    },
    {
      "city": "Chom Phra",
      "cityCode": 3204,
      "provinceCode": 32,
      "subDistrict": [
        {
          "id": 320406,
          "name": "Ban Phue",
          "postCode": [
            "32180"
          ]
        },
        {
          "id": 320404,
          "name": "Bu Kraeng",
          "postCode": [
            "32180"
          ]
        },
        {
          "id": 320401,
          "name": "Chom Phra",
          "postCode": [
            "32180"
          ]
        },
        {
          "id": 320408,
          "name": "Chum Saeng",
          "postCode": [
            "32180"
          ]
        },
        {
          "id": 320403,
          "name": "Krahat",
          "postCode": [
            "32180"
          ]
        },
        {
          "id": 320407,
          "name": "Lum Rawi",
          "postCode": [
            "32180"
          ]
        },
        {
          "id": 320402,
          "name": "Mueang Ling",
          "postCode": [
            "32180"
          ]
        },
        {
          "id": 320405,
          "name": "Nong Sanit",
          "postCode": [
            "32180"
          ]
        },
        {
          "id": 320409,
          "name": "Pen Suk",
          "postCode": [
            "32180"
          ]
        }
      ]
    },
    {
      "city": "Chumphon Buri",
      "cityCode": 3202,
      "provinceCode": 32,
      "subDistrict": [
        {
          "id": 320201,
          "name": "Chumphon Buri",
          "postCode": [
            "32190"
          ]
        },
        {
          "id": 320208,
          "name": "Krabueang",
          "postCode": [
            "32190"
          ]
        },
        {
          "id": 320206,
          "name": "Mueang Bua",
          "postCode": [
            "32190"
          ]
        },
        {
          "id": 320202,
          "name": "Na Nong Phai",
          "postCode": [
            "32190"
          ]
        },
        {
          "id": 320209,
          "name": "Nong Ruea",
          "postCode": [
            "32190"
          ]
        },
        {
          "id": 320203,
          "name": "Phrai Khla",
          "postCode": [
            "32190"
          ]
        },
        {
          "id": 320207,
          "name": "Sa Khut",
          "postCode": [
            "32190"
          ]
        },
        {
          "id": 320204,
          "name": "Si Narong",
          "postCode": [
            "32190"
          ]
        },
        {
          "id": 320205,
          "name": "Yawuek",
          "postCode": [
            "32190"
          ]
        }
      ]
    },
    {
      "city": "Kap Choeng",
      "cityCode": 3206,
      "provinceCode": 32,
      "subDistrict": [
        {
          "id": 320605,
          "name": "Dan",
          "postCode": [
            "32210"
          ]
        },
        {
          "id": 320601,
          "name": "Kap Choeng",
          "postCode": [
            "32210"
          ]
        },
        {
          "id": 320607,
          "name": "Khok Takhian",
          "postCode": [
            "32210"
          ]
        },
        {
          "id": 320604,
          "name": "Khu Tan",
          "postCode": [
            "32210"
          ]
        },
        {
          "id": 320606,
          "name": "Naeng Mut",
          "postCode": [
            "32210"
          ]
        },
        {
          "id": 320610,
          "name": "Takhian",
          "postCode": [
            "32210"
          ]
        }
      ]
    },
    {
      "city": "Khwao Sinrin",
      "cityCode": 3216,
      "provinceCode": 32,
      "subDistrict": [
        {
          "id": 321605,
          "name": "Ban Rae",
          "postCode": [
            "32000"
          ]
        },
        {
          "id": 321602,
          "name": "Bueng",
          "postCode": [
            "32000"
          ]
        },
        {
          "id": 321601,
          "name": "Khwao Sinrin",
          "postCode": [
            "32000"
          ]
        },
        {
          "id": 321604,
          "name": "Prasat Thong",
          "postCode": [
            "32000"
          ]
        },
        {
          "id": 321603,
          "name": "Ta Kuk",
          "postCode": [
            "32000"
          ]
        }
      ]
    },
    {
      "city": "Lamduan",
      "cityCode": 3211,
      "provinceCode": 32,
      "subDistrict": [
        {
          "id": 321102,
          "name": "Chok Nuea",
          "postCode": [
            "32220"
          ]
        },
        {
          "id": 321101,
          "name": "Lamduan",
          "postCode": [
            "32220"
          ]
        },
        {
          "id": 321105,
          "name": "Tra Priang Tia",
          "postCode": [
            "32220"
          ]
        },
        {
          "id": 321104,
          "name": "Tram Dom",
          "postCode": [
            "32220"
          ]
        },
        {
          "id": 321103,
          "name": "U Lok",
          "postCode": [
            "32220"
          ]
        }
      ]
    },
    {
      "city": "Mueang Surin",
      "cityCode": 3201,
      "provinceCode": 32,
      "subDistrict": [
        {
          "id": 320121,
          "name": "Bu Ruesi",
          "postCode": [
            "32000"
          ]
        },
        {
          "id": 320114,
          "name": "Chaniang",
          "postCode": [
            "32000"
          ]
        },
        {
          "id": 320126,
          "name": "Ka Ko",
          "postCode": [
            "32000"
          ]
        },
        {
          "id": 320110,
          "name": "Kae Yai",
          "postCode": [
            "32000"
          ]
        },
        {
          "id": 320112,
          "name": "Kho Kho",
          "postCode": [
            "32000"
          ]
        },
        {
          "id": 320119,
          "name": "Mueang Thi",
          "postCode": [
            "32000"
          ]
        },
        {
          "id": 320118,
          "name": "Na Bua",
          "postCode": [
            "32000"
          ]
        },
        {
          "id": 320104,
          "name": "Na Di",
          "postCode": [
            "32000"
          ]
        },
        {
          "id": 320101,
          "name": "Nai Mueang",
          "postCode": [
            "32000"
          ]
        },
        {
          "id": 320111,
          "name": "Nok Mueang",
          "postCode": [
            "32000"
          ]
        },
        {
          "id": 320103,
          "name": "Phia Ram",
          "postCode": [
            "32000"
          ]
        },
        {
          "id": 320120,
          "name": "Ram",
          "postCode": [
            "32000"
          ]
        },
        {
          "id": 320125,
          "name": "Salaeng Phan",
          "postCode": [
            "32000"
          ]
        },
        {
          "id": 320106,
          "name": "Salakdai",
          "postCode": [
            "32000"
          ]
        },
        {
          "id": 320109,
          "name": "Samrong",
          "postCode": [
            "32000"
          ]
        },
        {
          "id": 320113,
          "name": "Sawai",
          "postCode": [
            "32000"
          ]
        },
        {
          "id": 320107,
          "name": "Ta Ong",
          "postCode": [
            "32000"
          ]
        },
        {
          "id": 320102,
          "name": "Tang Chai",
          "postCode": [
            "32000"
          ]
        },
        {
          "id": 320105,
          "name": "Tha Sawang",
          "postCode": [
            "32000"
          ]
        },
        {
          "id": 320116,
          "name": "Thenmi",
          "postCode": [
            "32000"
          ]
        },
        {
          "id": 320122,
          "name": "Trasaeng",
          "postCode": [
            "32000"
          ]
        }
      ]
    },
    {
      "city": "Non Narai",
      "cityCode": 3217,
      "provinceCode": 32,
      "subDistrict": [
        {
          "id": 321702,
          "name": "Kham Phong",
          "postCode": [
            "32130"
          ]
        },
        {
          "id": 321703,
          "name": "Non",
          "postCode": [
            "32130"
          ]
        },
        {
          "id": 321701,
          "name": "Nong Luang",
          "postCode": [
            "32130"
          ]
        },
        {
          "id": 321705,
          "name": "Nong Thep",
          "postCode": [
            "32130"
          ]
        },
        {
          "id": 321704,
          "name": "Rawiang",
          "postCode": [
            "32130"
          ]
        }
      ]
    },
    {
      "city": "Phanom Dong Rak",
      "cityCode": 3214,
      "provinceCode": 32,
      "subDistrict": [
        {
          "id": 321401,
          "name": "Bak Dai",
          "postCode": [
            "32140"
          ]
        },
        {
          "id": 321403,
          "name": "Chik Daek",
          "postCode": [
            "32140"
          ]
        },
        {
          "id": 321402,
          "name": "Khok Klang",
          "postCode": [
            "32140"
          ]
        },
        {
          "id": 321404,
          "name": "Ta Miang",
          "postCode": [
            "32140"
          ]
        }
      ]
    },
    {
      "city": "Prasat",
      "cityCode": 3205,
      "provinceCode": 32,
      "subDistrict": [
        {
          "id": 320515,
          "name": "Ban Phluang",
          "postCode": [
            "32140"
          ]
        },
        {
          "id": 320510,
          "name": "Ban Sai",
          "postCode": [
            "32140"
          ]
        },
        {
          "id": 320511,
          "name": "Chok Na Sam",
          "postCode": [
            "32140"
          ]
        },
        {
          "id": 320512,
          "name": "Chuea Phloeng",
          "postCode": [
            "32140"
          ]
        },
        {
          "id": 320501,
          "name": "Kang Aen",
          "postCode": [
            "32140"
          ]
        },
        {
          "id": 320516,
          "name": "Kantuat Ramuan",
          "postCode": [
            "32140"
          ]
        },
        {
          "id": 320509,
          "name": "Khok Sa-at",
          "postCode": [
            "32140"
          ]
        },
        {
          "id": 320508,
          "name": "Khok Yang",
          "postCode": [
            "32140"
          ]
        },
        {
          "id": 320507,
          "name": "Nong Yai",
          "postCode": [
            "32140"
          ]
        },
        {
          "id": 320503,
          "name": "Phlai",
          "postCode": [
            "32140"
          ]
        },
        {
          "id": 320513,
          "name": "Prasat Thanong",
          "postCode": [
            "32140"
          ]
        },
        {
          "id": 320518,
          "name": "Prathat Bu",
          "postCode": [
            "32140"
          ]
        },
        {
          "id": 320504,
          "name": "Prue",
          "postCode": [
            "32140"
          ]
        },
        {
          "id": 320517,
          "name": "Samut",
          "postCode": [
            "32140"
          ]
        },
        {
          "id": 320506,
          "name": "Ta Bao",
          "postCode": [
            "32140"
          ]
        },
        {
          "id": 320514,
          "name": "Tani",
          "postCode": [
            "32140"
          ]
        },
        {
          "id": 320502,
          "name": "Thamo",
          "postCode": [
            "32140"
          ]
        },
        {
          "id": 320505,
          "name": "Thung Mon",
          "postCode": [
            "32140"
          ]
        }
      ]
    },
    {
      "city": "Rattanaburi",
      "cityCode": 3207,
      "provinceCode": 32,
      "subDistrict": [
        {
          "id": 320711,
          "name": "Boet",
          "postCode": [
            "32130"
          ]
        },
        {
          "id": 320704,
          "name": "Don Raet",
          "postCode": [
            "32130"
          ]
        },
        {
          "id": 320703,
          "name": "Kae",
          "postCode": [
            "32130"
          ]
        },
        {
          "id": 320714,
          "name": "Kut Kha Khim",
          "postCode": [
            "32130"
          ]
        },
        {
          "id": 320713,
          "name": "Nam Khiao",
          "postCode": [
            "32130"
          ]
        },
        {
          "id": 320706,
          "name": "Nong Bua Ban",
          "postCode": [
            "32130"
          ]
        },
        {
          "id": 320705,
          "name": "Nong Bua Thong",
          "postCode": [
            "32130"
          ]
        },
        {
          "id": 320709,
          "name": "Phai",
          "postCode": [
            "32130"
          ]
        },
        {
          "id": 320701,
          "name": "Rattanaburi",
          "postCode": [
            "32130"
          ]
        },
        {
          "id": 320716,
          "name": "Thap Yai",
          "postCode": [
            "32130"
          ]
        },
        {
          "id": 320702,
          "name": "That",
          "postCode": [
            "32130"
          ]
        },
        {
          "id": 320715,
          "name": "Yang Sawang",
          "postCode": [
            "32130"
          ]
        }
      ]
    },
    {
      "city": "Samrong Thap",
      "cityCode": 3212,
      "provinceCode": 32,
      "subDistrict": [
        {
          "id": 321206,
          "name": "Ko Kaeo",
          "postCode": [
            "32170"
          ]
        },
        {
          "id": 321203,
          "name": "Kra-om",
          "postCode": [
            "32170"
          ]
        },
        {
          "id": 321207,
          "name": "Muen Si",
          "postCode": [
            "32170"
          ]
        },
        {
          "id": 321204,
          "name": "Nong Ha",
          "postCode": [
            "32170"
          ]
        },
        {
          "id": 321202,
          "name": "Nong Phai Lom",
          "postCode": [
            "32170"
          ]
        },
        {
          "id": 321210,
          "name": "Pradu",
          "postCode": [
            "32170"
          ]
        },
        {
          "id": 321208,
          "name": "Samet",
          "postCode": [
            "32170"
          ]
        },
        {
          "id": 321201,
          "name": "Samrong Thap",
          "postCode": [
            "32170"
          ]
        },
        {
          "id": 321209,
          "name": "Sano",
          "postCode": [
            "32170"
          ]
        },
        {
          "id": 321205,
          "name": "Si Suk",
          "postCode": [
            "32170"
          ]
        }
      ]
    },
    {
      "city": "Sangkha",
      "cityCode": 3210,
      "provinceCode": 32,
      "subDistrict": [
        {
          "id": 321008,
          "name": "Ban Chan",
          "postCode": [
            "32150"
          ]
        },
        {
          "id": 321015,
          "name": "Ban Chop",
          "postCode": [
            "32150"
          ]
        },
        {
          "id": 321006,
          "name": "Dom",
          "postCode": [
            "32150"
          ]
        },
        {
          "id": 321002,
          "name": "Khon Taek",
          "postCode": [
            "32150"
          ]
        },
        {
          "id": 321009,
          "name": "Krathiam",
          "postCode": [
            "32150"
          ]
        },
        {
          "id": 321007,
          "name": "Phra Kaeo",
          "postCode": [
            "32150"
          ]
        },
        {
          "id": 321010,
          "name": "Sakat",
          "postCode": [
            "32150"
          ]
        },
        {
          "id": 321001,
          "name": "Sangkha",
          "postCode": [
            "32150"
          ]
        },
        {
          "id": 321013,
          "name": "Ta Khong",
          "postCode": [
            "32150"
          ]
        },
        {
          "id": 321011,
          "name": "Ta Tum",
          "postCode": [
            "32150"
          ]
        },
        {
          "id": 321012,
          "name": "Thap Than",
          "postCode": [
            "32150"
          ]
        },
        {
          "id": 321017,
          "name": "Thep Raksa",
          "postCode": [
            "32150"
          ]
        }
      ]
    },
    {
      "city": "Sanom",
      "cityCode": 3208,
      "provinceCode": 32,
      "subDistrict": [
        {
          "id": 320806,
          "name": "Hua Ngua",
          "postCode": [
            "32160"
          ]
        },
        {
          "id": 320805,
          "name": "Khaen",
          "postCode": [
            "32160"
          ]
        },
        {
          "id": 320804,
          "name": "Na Nuan",
          "postCode": [
            "32160"
          ]
        },
        {
          "id": 320807,
          "name": "Nong I Yo",
          "postCode": [
            "32160"
          ]
        },
        {
          "id": 320803,
          "name": "Nong Rakhang",
          "postCode": [
            "32160"
          ]
        },
        {
          "id": 320802,
          "name": "Phon Ko",
          "postCode": [
            "32160"
          ]
        },
        {
          "id": 320801,
          "name": "Sanom",
          "postCode": [
            "32160"
          ]
        }
      ]
    },
    {
      "city": "Si Narong",
      "cityCode": 3215,
      "provinceCode": 32,
      "subDistrict": [
        {
          "id": 321502,
          "name": "Chaenwaen",
          "postCode": [
            "32150"
          ]
        },
        {
          "id": 321501,
          "name": "Narong",
          "postCode": [
            "32150"
          ]
        },
        {
          "id": 321504,
          "name": "Nong Waeng",
          "postCode": [
            "32150"
          ]
        },
        {
          "id": 321505,
          "name": "Si Suk",
          "postCode": [
            "32150"
          ]
        },
        {
          "id": 321503,
          "name": "Truat",
          "postCode": [
            "32150"
          ]
        }
      ]
    },
    {
      "city": "Sikhoraphum",
      "cityCode": 3209,
      "provinceCode": 32,
      "subDistrict": [
        {
          "id": 320910,
          "name": "Chang Pi",
          "postCode": [
            "32110"
          ]
        },
        {
          "id": 320903,
          "name": "Charaphat",
          "postCode": [
            "32110"
          ]
        },
        {
          "id": 320907,
          "name": "Khalamae",
          "postCode": [
            "32110"
          ]
        },
        {
          "id": 320912,
          "name": "Khwao Yai",
          "postCode": [
            "32110"
          ]
        },
        {
          "id": 320911,
          "name": "Kut Wai",
          "postCode": [
            "32110"
          ]
        },
        {
          "id": 320913,
          "name": "Na Rung",
          "postCode": [
            "32110"
          ]
        },
        {
          "id": 320906,
          "name": "Nong Bua",
          "postCode": [
            "32110"
          ]
        },
        {
          "id": 320909,
          "name": "Nong Khwao",
          "postCode": [
            "32110"
          ]
        },
        {
          "id": 320908,
          "name": "Nong Lek",
          "postCode": [
            "32110"
          ]
        },
        {
          "id": 320915,
          "name": "Phak Mai",
          "postCode": [
            "32110"
          ]
        },
        {
          "id": 320901,
          "name": "Ra-ngaeng",
          "postCode": [
            "32110"
          ]
        },
        {
          "id": 320905,
          "name": "Taen",
          "postCode": [
            "32110"
          ]
        },
        {
          "id": 320914,
          "name": "Trom Phrai",
          "postCode": [
            "32110"
          ]
        },
        {
          "id": 320902,
          "name": "Truem",
          "postCode": [
            "32110"
          ]
        },
        {
          "id": 320904,
          "name": "Yang",
          "postCode": [
            "32110"
          ]
        }
      ]
    },
    {
      "city": "Tha Tum",
      "cityCode": 3203,
      "provinceCode": 32,
      "subDistrict": [
        {
          "id": 320306,
          "name": "Ba",
          "postCode": [
            "32120"
          ]
        },
        {
          "id": 320308,
          "name": "Bua Khok",
          "postCode": [
            "32120"
          ]
        },
        {
          "id": 320302,
          "name": "Krapho",
          "postCode": [
            "32120"
          ]
        },
        {
          "id": 320305,
          "name": "Mueang Kae",
          "postCode": [
            "32120"
          ]
        },
        {
          "id": 320307,
          "name": "Nong Bua",
          "postCode": [
            "32120"
          ]
        },
        {
          "id": 320309,
          "name": "Nong Methi",
          "postCode": [
            "32120"
          ]
        },
        {
          "id": 320304,
          "name": "Phon Khrok",
          "postCode": [
            "32120"
          ]
        },
        {
          "id": 320303,
          "name": "Phrom Thep",
          "postCode": [
            "32120"
          ]
        },
        {
          "id": 320301,
          "name": "Tha Tum",
          "postCode": [
            "32120"
          ]
        },
        {
          "id": 320310,
          "name": "Thung Kula",
          "postCode": [
            "32120"
          ]
        }
      ]
    },
    {
      "city": "Ban Tak",
      "cityCode": 6302,
      "provinceCode": 63,
      "subDistrict": [
        {
          "id": 630205,
          "name": "Ko Taphao",
          "postCode": [
            "63120"
          ]
        },
        {
          "id": 630203,
          "name": "Mae Salit",
          "postCode": [
            "63120"
          ]
        },
        {
          "id": 630202,
          "name": "Samo Khon",
          "postCode": [
            "63120"
          ]
        },
        {
          "id": 630201,
          "name": "Tak Ok",
          "postCode": [
            "63120"
          ]
        },
        {
          "id": 630204,
          "name": "Tak Tok",
          "postCode": [
            "63120"
          ]
        },
        {
          "id": 630207,
          "name": "Thong Fa",
          "postCode": [
            "63120"
          ]
        },
        {
          "id": 630206,
          "name": "Thung Kracho",
          "postCode": [
            "63120"
          ]
        }
      ]
    },
    {
      "city": "Mae Ramat",
      "cityCode": 6304,
      "provinceCode": 63,
      "subDistrict": [
        {
          "id": 630403,
          "name": "Khane Chue",
          "postCode": [
            "63140"
          ]
        },
        {
          "id": 630402,
          "name": "Mae Charao",
          "postCode": [
            "63140"
          ]
        },
        {
          "id": 630401,
          "name": "Mae Ramat",
          "postCode": [
            "63140"
          ]
        },
        {
          "id": 630404,
          "name": "Mae Tuen",
          "postCode": [
            "63140"
          ]
        },
        {
          "id": 630406,
          "name": "Phra That",
          "postCode": [
            "63140"
          ]
        },
        {
          "id": 630405,
          "name": "Sam Muen",
          "postCode": [
            "63140"
          ]
        }
      ]
    },
    {
      "city": "Mae Sot",
      "cityCode": 6306,
      "provinceCode": 63,
      "subDistrict": [
        {
          "id": 630609,
          "name": "Dan Mae Lamao",
          "postCode": [
            "63110"
          ]
        },
        {
          "id": 630605,
          "name": "Mae Kasa",
          "postCode": [
            "63110"
          ]
        },
        {
          "id": 630602,
          "name": "Mae Ku",
          "postCode": [
            "63110"
          ]
        },
        {
          "id": 630607,
          "name": "Mae Pa",
          "postCode": [
            "63110"
          ]
        },
        {
          "id": 630601,
          "name": "Mae Sot",
          "postCode": [
            "63110"
          ]
        },
        {
          "id": 630604,
          "name": "Mae Tao",
          "postCode": [
            "63110"
          ]
        },
        {
          "id": 630608,
          "name": "Maha Wan",
          "postCode": [
            "63110"
          ]
        },
        {
          "id": 630603,
          "name": "Phawo",
          "postCode": [
            "63110"
          ]
        },
        {
          "id": 630610,
          "name": "Phra That Pha Daeng",
          "postCode": [
            "63110"
          ]
        },
        {
          "id": 630606,
          "name": "Tha Sai Luat",
          "postCode": [
            "63110"
          ]
        }
      ]
    },
    {
      "city": "Mueang Tak",
      "cityCode": 6301,
      "provinceCode": 63,
      "subDistrict": [
        {
          "id": 630103,
          "name": "Chiang Ngoen",
          "postCode": [
            "63000"
          ]
        },
        {
          "id": 630104,
          "name": "Hua Diat",
          "postCode": [
            "63000"
          ]
        },
        {
          "id": 630111,
          "name": "Mae Tho",
          "postCode": [
            "63000"
          ]
        },
        {
          "id": 630106,
          "name": "Mai Ngam",
          "postCode": [
            "63000"
          ]
        },
        {
          "id": 630108,
          "name": "Nam Ruem",
          "postCode": [
            "63000"
          ]
        },
        {
          "id": 630105,
          "name": "Nong Bua Nuea",
          "postCode": [
            "63000"
          ]
        },
        {
          "id": 630113,
          "name": "Nong Bua Tai",
          "postCode": [
            "63000"
          ]
        },
        {
          "id": 630102,
          "name": "Nong Luang",
          "postCode": [
            "63000"
          ]
        },
        {
          "id": 630112,
          "name": "Pa Mamuang",
          "postCode": [
            "63000"
          ]
        },
        {
          "id": 630107,
          "name": "Pong Daeng",
          "postCode": [
            "63000"
          ]
        },
        {
          "id": 630101,
          "name": "Rahaeng",
          "postCode": [
            "63000"
          ]
        },
        {
          "id": 630115,
          "name": "Taluk Klang Thung",
          "postCode": [
            "63000"
          ]
        },
        {
          "id": 630109,
          "name": "Wang Hin",
          "postCode": [
            "63000"
          ]
        },
        {
          "id": 630114,
          "name": "Wang Prachop",
          "postCode": [
            "63000"
          ]
        }
      ]
    },
    {
      "city": "Phop Phra",
      "cityCode": 6307,
      "provinceCode": 63,
      "subDistrict": [
        {
          "id": 630702,
          "name": "Chong Khaep",
          "postCode": [
            "63160"
          ]
        },
        {
          "id": 630703,
          "name": "Khiri Rat",
          "postCode": [
            "63160"
          ]
        },
        {
          "id": 630701,
          "name": "Phop Phra",
          "postCode": [
            "63160"
          ]
        },
        {
          "id": 630705,
          "name": "Ruam Thai Phatthana",
          "postCode": [
            "63160"
          ]
        },
        {
          "id": 630704,
          "name": "Wale",
          "postCode": [
            "63160"
          ]
        }
      ]
    },
    {
      "city": "Sam Ngao",
      "cityCode": 6303,
      "provinceCode": 63,
      "subDistrict": [
        {
          "id": 630305,
          "name": "Ban Na",
          "postCode": [
            "63130"
          ]
        },
        {
          "id": 630301,
          "name": "Sam Ngao",
          "postCode": [
            "63130"
          ]
        },
        {
          "id": 630306,
          "name": "Wang Chan",
          "postCode": [
            "63130"
          ]
        },
        {
          "id": 630302,
          "name": "Wang Man",
          "postCode": [
            "63130"
          ]
        },
        {
          "id": 630304,
          "name": "Yan Ri",
          "postCode": [
            "63130"
          ]
        },
        {
          "id": 630303,
          "name": "Yokkrabat",
          "postCode": [
            "63130"
          ]
        }
      ]
    },
    {
      "city": "Tha Song Yang",
      "cityCode": 6305,
      "provinceCode": 63,
      "subDistrict": [
        {
          "id": 630504,
          "name": "Mae La",
          "postCode": [
            "63150"
          ]
        },
        {
          "id": 630503,
          "name": "Mae Song",
          "postCode": [
            "63150"
          ]
        },
        {
          "id": 630502,
          "name": "Mae Tan",
          "postCode": [
            "63150"
          ]
        },
        {
          "id": 630506,
          "name": "Mae U-su",
          "postCode": [
            "63150"
          ]
        },
        {
          "id": 630505,
          "name": "Mae Wa Luang",
          "postCode": [
            "63150"
          ]
        },
        {
          "id": 630501,
          "name": "Tha Song Yang",
          "postCode": [
            "63150"
          ]
        }
      ]
    },
    {
      "city": "Umphang",
      "cityCode": 6308,
      "provinceCode": 63,
      "subDistrict": [
        {
          "id": 630804,
          "name": "Mae Chan",
          "postCode": [
            "63170"
          ]
        },
        {
          "id": 630806,
          "name": "Mae Klong",
          "postCode": [
            "63170"
          ]
        },
        {
          "id": 630805,
          "name": "Mae Lamung",
          "postCode": [
            "63170"
          ]
        },
        {
          "id": 630803,
          "name": "Mokro",
          "postCode": [
            "63170"
          ]
        },
        {
          "id": 630802,
          "name": "Nong Luang",
          "postCode": [
            "63170"
          ]
        },
        {
          "id": 630801,
          "name": "Umphang",
          "postCode": [
            "63170"
          ]
        }
      ]
    },
    {
      "city": "Wang Chao",
      "cityCode": 6309,
      "provinceCode": 63,
      "subDistrict": [
        {
          "id": 630901,
          "name": "Chiang Thong",
          "postCode": [
            "63180"
          ]
        },
        {
          "id": 630902,
          "name": "Na Bot",
          "postCode": [
            "63180"
          ]
        },
        {
          "id": 630903,
          "name": "Pradang",
          "postCode": [
            "63180"
          ]
        }
      ]
    },
    {
      "city": "Hat Samran",
      "cityCode": 9210,
      "provinceCode": 92,
      "subDistrict": [
        {
          "id": 921002,
          "name": "Ba Wi",
          "postCode": [
            "92120"
          ]
        },
        {
          "id": 921001,
          "name": "Hat Samran",
          "postCode": [
            "92120"
          ]
        },
        {
          "id": 921003,
          "name": "Tase",
          "postCode": [
            "92120"
          ]
        }
      ]
    },
    {
      "city": "Huai Yot",
      "cityCode": 9206,
      "provinceCode": 92,
      "subDistrict": [
        {
          "id": 920605,
          "name": "Bang Di",
          "postCode": [
            "92210"
          ]
        },
        {
          "id": 920606,
          "name": "Bang Kung",
          "postCode": [
            "92210"
          ]
        },
        {
          "id": 920617,
          "name": "Huai Nang",
          "postCode": [
            "92130"
          ]
        },
        {
          "id": 920601,
          "name": "Huai Yot",
          "postCode": [
            "92130"
          ]
        },
        {
          "id": 920608,
          "name": "Khao Khao",
          "postCode": [
            "92130"
          ]
        },
        {
          "id": 920607,
          "name": "Khao Kop",
          "postCode": [
            "92130"
          ]
        },
        {
          "id": 920609,
          "name": "Khao Pun",
          "postCode": [
            "92130"
          ]
        },
        {
          "id": 920615,
          "name": "Lamphu Ra",
          "postCode": [
            "92190"
          ]
        },
        {
          "id": 920616,
          "name": "Na Wong",
          "postCode": [
            "92210"
          ]
        },
        {
          "id": 920619,
          "name": "Nai Tao",
          "postCode": [
            "92130"
          ]
        },
        {
          "id": 920602,
          "name": "Nong Chang Laen",
          "postCode": [
            "92130"
          ]
        },
        {
          "id": 920610,
          "name": "Pak Chaem",
          "postCode": [
            "92190"
          ]
        },
        {
          "id": 920611,
          "name": "Pak Khom",
          "postCode": [
            "92130"
          ]
        },
        {
          "id": 920614,
          "name": "Tha Ngio",
          "postCode": [
            "92130"
          ]
        },
        {
          "id": 920620,
          "name": "Thung To",
          "postCode": [
            "92130"
          ]
        },
        {
          "id": 920621,
          "name": "Wang Khiri",
          "postCode": [
            "92210"
          ]
        }
      ]
    },
    {
      "city": "Kantang",
      "cityCode": 9202,
      "provinceCode": 92,
      "subDistrict": [
        {
          "id": 920203,
          "name": "Bang Mak",
          "postCode": [
            "92110"
          ]
        },
        {
          "id": 920204,
          "name": "Bang Pao",
          "postCode": [
            "92110"
          ]
        },
        {
          "id": 920211,
          "name": "Bang Sak",
          "postCode": [
            "92110"
          ]
        },
        {
          "id": 920210,
          "name": "Bo Nam Ron",
          "postCode": [
            "92110"
          ]
        },
        {
          "id": 920201,
          "name": "Kantang",
          "postCode": [
            "92110"
          ]
        },
        {
          "id": 920206,
          "name": "Kantang Tai",
          "postCode": [
            "92110"
          ]
        },
        {
          "id": 920214,
          "name": "Khlong Chi Lom",
          "postCode": [
            "92110"
          ]
        },
        {
          "id": 920208,
          "name": "Khlong Lu",
          "postCode": [
            "92110"
          ]
        },
        {
          "id": 920207,
          "name": "Khok Yang",
          "postCode": [
            "92110"
          ]
        },
        {
          "id": 920202,
          "name": "Khuan Thani",
          "postCode": [
            "92110"
          ]
        },
        {
          "id": 920213,
          "name": "Ko Libong",
          "postCode": [
            "92110"
          ]
        },
        {
          "id": 920212,
          "name": "Na Kluea",
          "postCode": [
            "92110"
          ]
        },
        {
          "id": 920205,
          "name": "Wang Won",
          "postCode": [
            "92110"
          ]
        },
        {
          "id": 920209,
          "name": "Yan Sue",
          "postCode": [
            "92110"
          ]
        }
      ]
    },
    {
      "city": "Mueang Trang",
      "cityCode": 9201,
      "provinceCode": 92,
      "subDistrict": [
        {
          "id": 920105,
          "name": "Ban Khuan",
          "postCode": [
            "92000"
          ]
        },
        {
          "id": 920118,
          "name": "Ban Pho",
          "postCode": [
            "92000"
          ]
        },
        {
          "id": 920109,
          "name": "Bang Rak",
          "postCode": [
            "92000"
          ]
        },
        {
          "id": 920110,
          "name": "Khok Lo",
          "postCode": [
            "92000"
          ]
        },
        {
          "id": 920107,
          "name": "Khuan Pring",
          "postCode": [
            "92000"
          ]
        },
        {
          "id": 920106,
          "name": "Na Bin La",
          "postCode": [
            "92170"
          ]
        },
        {
          "id": 920104,
          "name": "Na Phala",
          "postCode": [
            "92000"
          ]
        },
        {
          "id": 920117,
          "name": "Na Ta Luang",
          "postCode": [
            "92000"
          ]
        },
        {
          "id": 920119,
          "name": "Na Tham Nuea",
          "postCode": [
            "92190"
          ]
        },
        {
          "id": 920120,
          "name": "Na Tham Tai",
          "postCode": [
            "92190"
          ]
        },
        {
          "id": 920113,
          "name": "Na To Ming",
          "postCode": [
            "92000"
          ]
        },
        {
          "id": 920108,
          "name": "Na Yong Tai",
          "postCode": [
            "92170"
          ]
        },
        {
          "id": 920115,
          "name": "Nam Phut",
          "postCode": [
            "92000"
          ]
        },
        {
          "id": 920114,
          "name": "Nong Trut",
          "postCode": [
            "92000"
          ]
        },
        {
          "id": 920101,
          "name": "Thap Thiang",
          "postCode": [
            "92000"
          ]
        }
      ]
    },
    {
      "city": "Na Yong",
      "cityCode": 9208,
      "provinceCode": 92,
      "subDistrict": [
        {
          "id": 920802,
          "name": "Chong",
          "postCode": [
            "92170"
          ]
        },
        {
          "id": 920804,
          "name": "Khok Saba",
          "postCode": [
            "92170"
          ]
        },
        {
          "id": 920803,
          "name": "Lamo",
          "postCode": [
            "92170"
          ]
        },
        {
          "id": 920806,
          "name": "Na Khao Sia",
          "postCode": [
            "92170"
          ]
        },
        {
          "id": 920805,
          "name": "Na Muen Si",
          "postCode": [
            "92170"
          ]
        },
        {
          "id": 920801,
          "name": "Na Yong Nuea",
          "postCode": [
            "92170"
          ]
        }
      ]
    },
    {
      "city": "Palian",
      "cityCode": 9204,
      "provinceCode": 92,
      "subDistrict": [
        {
          "id": 920407,
          "name": "Ban Na",
          "postCode": [
            "92140"
          ]
        },
        {
          "id": 920404,
          "name": "Bang Duan",
          "postCode": [
            "92140"
          ]
        },
        {
          "id": 920411,
          "name": "Ko Sukon",
          "postCode": [
            "92120"
          ]
        },
        {
          "id": 920413,
          "name": "Laem Som",
          "postCode": [
            "92180"
          ]
        },
        {
          "id": 920410,
          "name": "Liphang",
          "postCode": [
            "92180"
          ]
        },
        {
          "id": 920403,
          "name": "Palian",
          "postCode": [
            "92180"
          ]
        },
        {
          "id": 920409,
          "name": "Suso",
          "postCode": [
            "92120"
          ]
        },
        {
          "id": 920401,
          "name": "Tha Kham",
          "postCode": [
            "92120"
          ]
        },
        {
          "id": 920412,
          "name": "Tha Phaya",
          "postCode": [
            "92140"
          ]
        },
        {
          "id": 920402,
          "name": "Thung Yao",
          "postCode": [
            "92180"
          ]
        }
      ]
    },
    {
      "city": "Ratsada",
      "cityCode": 9209,
      "provinceCode": 92,
      "subDistrict": [
        {
          "id": 920905,
          "name": "Khao Phrai",
          "postCode": [
            "92160"
          ]
        },
        {
          "id": 920902,
          "name": "Khlong Pang",
          "postCode": [
            "92160"
          ]
        },
        {
          "id": 920901,
          "name": "Khuan Mao",
          "postCode": [
            "92160"
          ]
        },
        {
          "id": 920903,
          "name": "Nong Bua",
          "postCode": [
            "92160"
          ]
        },
        {
          "id": 920904,
          "name": "Nong Prue",
          "postCode": [
            "92130"
          ]
        }
      ]
    },
    {
      "city": "Sikao",
      "cityCode": 9205,
      "provinceCode": 92,
      "subDistrict": [
        {
          "id": 920501,
          "name": "Bo Hin",
          "postCode": [
            "92150"
          ]
        },
        {
          "id": 920503,
          "name": "Kalase",
          "postCode": [
            "92150"
          ]
        },
        {
          "id": 920502,
          "name": "Khao Mai Kaeo",
          "postCode": [
            "92150"
          ]
        },
        {
          "id": 920504,
          "name": "Mai Fat",
          "postCode": [
            "92150"
          ]
        },
        {
          "id": 920505,
          "name": "Na Mueang Phet",
          "postCode": [
            "92000"
          ]
        }
      ]
    },
    {
      "city": "Wang Wiset",
      "cityCode": 9207,
      "provinceCode": 92,
      "subDistrict": [
        {
          "id": 920703,
          "name": "Ao Tong",
          "postCode": [
            "92220"
          ]
        },
        {
          "id": 920701,
          "name": "Khao Wiset",
          "postCode": [
            "92220"
          ]
        },
        {
          "id": 920704,
          "name": "Tha Saba",
          "postCode": [
            "92000"
          ]
        },
        {
          "id": 920702,
          "name": "Wang Maprang",
          "postCode": [
            "92220"
          ]
        },
        {
          "id": 920705,
          "name": "Wang Maprang Nuea",
          "postCode": [
            "92220"
          ]
        }
      ]
    },
    {
      "city": "Yan Ta Khao",
      "cityCode": 9203,
      "provinceCode": 92,
      "subDistrict": [
        {
          "id": 920308,
          "name": "Ko Pia",
          "postCode": [
            "92140"
          ]
        },
        {
          "id": 920303,
          "name": "Na Chum Het",
          "postCode": [
            "92140"
          ]
        },
        {
          "id": 920304,
          "name": "Nai Khuan",
          "postCode": [
            "92140"
          ]
        },
        {
          "id": 920302,
          "name": "Nong Bo",
          "postCode": [
            "92140"
          ]
        },
        {
          "id": 920305,
          "name": "Phrong Chorakhe",
          "postCode": [
            "92140"
          ]
        },
        {
          "id": 920307,
          "name": "Thung Khai",
          "postCode": [
            "92140"
          ]
        },
        {
          "id": 920306,
          "name": "Thung Krabue",
          "postCode": [
            "92140"
          ]
        },
        {
          "id": 920301,
          "name": "Yan Ta Khao",
          "postCode": [
            "92140"
          ]
        }
      ]
    },
    {
      "city": "Bo Rai",
      "cityCode": 2304,
      "provinceCode": 23,
      "subDistrict": [
        {
          "id": 230401,
          "name": "Bo Phloi",
          "postCode": [
            "23140"
          ]
        },
        {
          "id": 230402,
          "name": "Chang Thun",
          "postCode": [
            "23140"
          ]
        },
        {
          "id": 230403,
          "name": "Dan Chumphon",
          "postCode": [
            "23140"
          ]
        },
        {
          "id": 230404,
          "name": "Nong Bon",
          "postCode": [
            "23140"
          ]
        },
        {
          "id": 230405,
          "name": "Nonsi",
          "postCode": [
            "23140"
          ]
        }
      ]
    },
    {
      "city": "Khao Saming",
      "cityCode": 2303,
      "provinceCode": 23,
      "subDistrict": [
        {
          "id": 230301,
          "name": "Khao Saming",
          "postCode": [
            "23130"
          ]
        },
        {
          "id": 230306,
          "name": "Pranit",
          "postCode": [
            "23150"
          ]
        },
        {
          "id": 230302,
          "name": "Saen Tung",
          "postCode": [
            "23150"
          ]
        },
        {
          "id": 230305,
          "name": "Sato",
          "postCode": [
            "23150"
          ]
        },
        {
          "id": 230304,
          "name": "Tha Som",
          "postCode": [
            "23150"
          ]
        },
        {
          "id": 230307,
          "name": "Thep Nimit",
          "postCode": [
            "23150"
          ]
        },
        {
          "id": 230308,
          "name": "Thung Nonsi",
          "postCode": [
            "23130"
          ]
        },
        {
          "id": 230303,
          "name": "Wang Takhian",
          "postCode": [
            "23130"
          ]
        }
      ]
    },
    {
      "city": "Khlong Yai",
      "cityCode": 2302,
      "provinceCode": 23,
      "subDistrict": [
        {
          "id": 230203,
          "name": "Hat Lek",
          "postCode": [
            "23110"
          ]
        },
        {
          "id": 230201,
          "name": "Khlong Yai",
          "postCode": [
            "23110"
          ]
        },
        {
          "id": 230202,
          "name": "Mai Rut",
          "postCode": [
            "23110"
          ]
        }
      ]
    },
    {
      "city": "Ko Chang",
      "cityCode": 2307,
      "provinceCode": 23,
      "subDistrict": [
        {
          "id": 230701,
          "name": "Ko Chang",
          "postCode": [
            "23170"
          ]
        },
        {
          "id": 230702,
          "name": "Ko Chang Tai",
          "postCode": [
            "23170"
          ]
        }
      ]
    },
    {
      "city": "Ko Kut",
      "cityCode": 2306,
      "provinceCode": 23,
      "subDistrict": [
        {
          "id": 230602,
          "name": "Ko Kut",
          "postCode": [
            "23000"
          ]
        },
        {
          "id": 230601,
          "name": "Ko Mak",
          "postCode": [
            "23120"
          ]
        }
      ]
    },
    {
      "city": "Laem Ngop",
      "cityCode": 2305,
      "provinceCode": 23,
      "subDistrict": [
        {
          "id": 230503,
          "name": "Bang Pit",
          "postCode": [
            "23120"
          ]
        },
        {
          "id": 230507,
          "name": "Khlong Yai",
          "postCode": [
            "23120"
          ]
        },
        {
          "id": 230501,
          "name": "Laem Ngop",
          "postCode": [
            "23120"
          ]
        },
        {
          "id": 230502,
          "name": "Nam Chiao",
          "postCode": [
            "23120"
          ]
        }
      ]
    },
    {
      "city": "Mueang Trat",
      "cityCode": 2301,
      "provinceCode": 23,
      "subDistrict": [
        {
          "id": 230106,
          "name": "Ao Yai",
          "postCode": [
            "23000"
          ]
        },
        {
          "id": 230101,
          "name": "Bang Phra",
          "postCode": [
            "23000"
          ]
        },
        {
          "id": 230113,
          "name": "Chamrak",
          "postCode": [
            "23000"
          ]
        },
        {
          "id": 230108,
          "name": "Huai Raeng",
          "postCode": [
            "23000"
          ]
        },
        {
          "id": 230105,
          "name": "Huang Nam Khao",
          "postCode": [
            "23000"
          ]
        },
        {
          "id": 230114,
          "name": "Laem Klat",
          "postCode": [
            "23000"
          ]
        },
        {
          "id": 230109,
          "name": "Noen Sai",
          "postCode": [
            "23000"
          ]
        },
        {
          "id": 230104,
          "name": "Nong Khan Song",
          "postCode": [
            "23000"
          ]
        },
        {
          "id": 230102,
          "name": "Nong Samet",
          "postCode": [
            "23000"
          ]
        },
        {
          "id": 230103,
          "name": "Nong Sano",
          "postCode": [
            "23000"
          ]
        },
        {
          "id": 230112,
          "name": "Takang",
          "postCode": [
            "23000"
          ]
        },
        {
          "id": 230111,
          "name": "Tha Kum",
          "postCode": [
            "23000"
          ]
        },
        {
          "id": 230110,
          "name": "Tha Phrik",
          "postCode": [
            "23000"
          ]
        },
        {
          "id": 230107,
          "name": "Wang Krachae",
          "postCode": [
            "23000"
          ]
        }
      ]
    },
    {
      "city": "Buntharik",
      "cityCode": 3410,
      "provinceCode": 34,
      "subDistrict": [
        {
          "id": 341008,
          "name": "Ban Maet",
          "postCode": [
            "34230"
          ]
        },
        {
          "id": 341007,
          "name": "Bua Ngam",
          "postCode": [
            "34230"
          ]
        },
        {
          "id": 341002,
          "name": "Huai Kha",
          "postCode": [
            "34230"
          ]
        },
        {
          "id": 341003,
          "name": "Kho Laen",
          "postCode": [
            "34230"
          ]
        },
        {
          "id": 341004,
          "name": "Na Pho",
          "postCode": [
            "34230"
          ]
        },
        {
          "id": 341006,
          "name": "Non Kho",
          "postCode": [
            "34230"
          ]
        },
        {
          "id": 341005,
          "name": "Nong Sano",
          "postCode": [
            "34230"
          ]
        },
        {
          "id": 341001,
          "name": "Phon Ngam",
          "postCode": [
            "34230"
          ]
        }
      ]
    },
    {
      "city": "Det Udom",
      "cityCode": 3407,
      "provinceCode": 34,
      "subDistrict": [
        {
          "id": 340715,
          "name": "Bua Ngam",
          "postCode": [
            "34160"
          ]
        },
        {
          "id": 340712,
          "name": "Kaeng",
          "postCode": [
            "34160"
          ]
        },
        {
          "id": 340716,
          "name": "Kham Khrang",
          "postCode": [
            "34160"
          ]
        },
        {
          "id": 340711,
          "name": "Klang",
          "postCode": [
            "34160"
          ]
        },
        {
          "id": 340709,
          "name": "Kut Prathai",
          "postCode": [
            "34160"
          ]
        },
        {
          "id": 340701,
          "name": "Mueang Det",
          "postCode": [
            "34160"
          ]
        },
        {
          "id": 340704,
          "name": "Na Charoen",
          "postCode": [
            "34160"
          ]
        },
        {
          "id": 340717,
          "name": "Na Krasaeng",
          "postCode": [
            "34160"
          ]
        },
        {
          "id": 340702,
          "name": "Na Suang",
          "postCode": [
            "34160"
          ]
        },
        {
          "id": 340723,
          "name": "Non Sombun",
          "postCode": [
            "34160"
          ]
        },
        {
          "id": 340721,
          "name": "Pa Mong",
          "postCode": [
            "34160"
          ]
        },
        {
          "id": 340720,
          "name": "Phon Ngam",
          "postCode": [
            "34160"
          ]
        },
        {
          "id": 340708,
          "name": "Som Sa-at",
          "postCode": [
            "34160"
          ]
        },
        {
          "id": 340713,
          "name": "Tha Pho Si",
          "postCode": [
            "34160"
          ]
        },
        {
          "id": 340706,
          "name": "Thung Thoeng",
          "postCode": [
            "34160"
          ]
        },
        {
          "id": 340710,
          "name": "Top Hu",
          "postCode": [
            "34160"
          ]
        }
      ]
    },
    {
      "city": "Don Mot Daeng",
      "cityCode": 3424,
      "provinceCode": 34,
      "subDistrict": [
        {
          "id": 342401,
          "name": "Don Mot Daeng",
          "postCode": [
            "34000"
          ]
        },
        {
          "id": 342404,
          "name": "Kham Hai Yai",
          "postCode": [
            "34000"
          ]
        },
        {
          "id": 342402,
          "name": "Lao Daeng",
          "postCode": [
            "34000"
          ]
        },
        {
          "id": 342403,
          "name": "Tha Muaeng",
          "postCode": [
            "34000"
          ]
        }
      ]
    },
    {
      "city": "Fang Kham",
      "cityCode": 3431,
      "provinceCode": 34,
      "subDistrict": [
        {
          "id": 343103,
          "name": "Amphoe Lao Suea Kok",
          "postCode": [
            "34000"
          ]
        },
        {
          "id": 343101,
          "name": "Nong Bok",
          "postCode": [
            "34000"
          ]
        },
        {
          "id": 343102,
          "name": "Phaeng Yai",
          "postCode": [
            "34000"
          ]
        },
        {
          "id": 343104,
          "name": "Phon Mueang",
          "postCode": [
            "34000"
          ]
        }
      ]
    },
    {
      "city": "Khemarat",
      "cityCode": 3405,
      "provinceCode": 34,
      "subDistrict": [
        {
          "id": 340504,
          "name": "Chiat",
          "postCode": [
            "34170"
          ]
        },
        {
          "id": 340513,
          "name": "Hua Na",
          "postCode": [
            "34170"
          ]
        },
        {
          "id": 340510,
          "name": "Kaeng Nuea",
          "postCode": [
            "34170"
          ]
        },
        {
          "id": 340503,
          "name": "Kham Pom",
          "postCode": [
            "34170"
          ]
        },
        {
          "id": 340501,
          "name": "Khemarat",
          "postCode": [
            "34170"
          ]
        },
        {
          "id": 340508,
          "name": "Na Waeng",
          "postCode": [
            "34170"
          ]
        },
        {
          "id": 340511,
          "name": "Nong Nok Tha",
          "postCode": [
            "34170"
          ]
        },
        {
          "id": 340507,
          "name": "Nong Phue",
          "postCode": [
            "34170"
          ]
        },
        {
          "id": 340512,
          "name": "Nong Sim",
          "postCode": [
            "34170"
          ]
        }
      ]
    },
    {
      "city": "Khong Chiam",
      "cityCode": 3403,
      "provinceCode": 34,
      "subDistrict": [
        {
          "id": 340305,
          "name": "Huai Phai",
          "postCode": [
            "34220"
          ]
        },
        {
          "id": 340302,
          "name": "Huai Yang",
          "postCode": [
            "34220"
          ]
        },
        {
          "id": 340301,
          "name": "Khong Chiam",
          "postCode": [
            "34220"
          ]
        },
        {
          "id": 340303,
          "name": "Na Pho Klang",
          "postCode": [
            "34220"
          ]
        },
        {
          "id": 340304,
          "name": "Nong Saeng Yai",
          "postCode": [
            "34220"
          ]
        }
      ]
    },
    {
      "city": "Khueang Nai",
      "cityCode": 3404,
      "provinceCode": 34,
      "subDistrict": [
        {
          "id": 340412,
          "name": "Ban Kok",
          "postCode": [
            "34320"
          ]
        },
        {
          "id": 340411,
          "name": "Ban Thai",
          "postCode": [
            "34320"
          ]
        },
        {
          "id": 340406,
          "name": "Chi Thuan",
          "postCode": [
            "34150"
          ]
        },
        {
          "id": 340409,
          "name": "Daeng Mo",
          "postCode": [
            "34150"
          ]
        },
        {
          "id": 340405,
          "name": "Hua Don",
          "postCode": [
            "34150"
          ]
        },
        {
          "id": 340403,
          "name": "Kho Thong",
          "postCode": [
            "34150"
          ]
        },
        {
          "id": 340401,
          "name": "Khueang Nai",
          "postCode": [
            "34150"
          ]
        },
        {
          "id": 340413,
          "name": "Klang Yai",
          "postCode": [
            "34320"
          ]
        },
        {
          "id": 340404,
          "name": "Ko E",
          "postCode": [
            "34150"
          ]
        },
        {
          "id": 340408,
          "name": "Na Kham Yai",
          "postCode": [
            "34150"
          ]
        },
        {
          "id": 340414,
          "name": "Non Rang",
          "postCode": [
            "34320"
          ]
        },
        {
          "id": 340418,
          "name": "Nong Lao",
          "postCode": [
            "34150"
          ]
        },
        {
          "id": 340417,
          "name": "Sahathat",
          "postCode": [
            "34150"
          ]
        },
        {
          "id": 340402,
          "name": "Sang Tho",
          "postCode": [
            "34150"
          ]
        },
        {
          "id": 340416,
          "name": "Si Suk",
          "postCode": [
            "34150"
          ]
        },
        {
          "id": 340407,
          "name": "Tha Hai",
          "postCode": [
            "34150"
          ]
        },
        {
          "id": 340410,
          "name": "That Noi",
          "postCode": [
            "34150"
          ]
        },
        {
          "id": 340415,
          "name": "Yang Khi Nok",
          "postCode": [
            "34150"
          ]
        }
      ]
    },
    {
      "city": "Kut Khaopun",
      "cityCode": 3412,
      "provinceCode": 34,
      "subDistrict": [
        {
          "id": 341204,
          "name": "Ka Bin",
          "postCode": [
            "34270"
          ]
        },
        {
          "id": 341203,
          "name": "Kaeng Kheng",
          "postCode": [
            "34270"
          ]
        },
        {
          "id": 341201,
          "name": "Khao Pun",
          "postCode": [
            "34270"
          ]
        },
        {
          "id": 341202,
          "name": "Non Sawang",
          "postCode": [
            "34270"
          ]
        },
        {
          "id": 341205,
          "name": "Nong Than Nam",
          "postCode": [
            "34270"
          ]
        }
      ]
    },
    {
      "city": "Muang Sam Sip",
      "cityCode": 3414,
      "provinceCode": 34,
      "subDistrict": [
        {
          "id": 341403,
          "name": "Dum Yai",
          "postCode": [
            "34140"
          ]
        },
        {
          "id": 341402,
          "name": "Lao Bok",
          "postCode": [
            "34140"
          ]
        },
        {
          "id": 341401,
          "name": "Muang Sam Sip",
          "postCode": [
            "34140"
          ]
        },
        {
          "id": 341413,
          "name": "Na Loeng",
          "postCode": [
            "34140"
          ]
        },
        {
          "id": 341404,
          "name": "Nong Chang Yai",
          "postCode": [
            "34140"
          ]
        },
        {
          "id": 341410,
          "name": "Nong Hang",
          "postCode": [
            "34140"
          ]
        },
        {
          "id": 341408,
          "name": "Nong Khai Nok",
          "postCode": [
            "34140"
          ]
        },
        {
          "id": 341409,
          "name": "Nong Lao",
          "postCode": [
            "34140"
          ]
        },
        {
          "id": 341405,
          "name": "Nong Mueang",
          "postCode": [
            "34140"
          ]
        },
        {
          "id": 341412,
          "name": "Phai Yai",
          "postCode": [
            "34140"
          ]
        },
        {
          "id": 341414,
          "name": "Phon Phaeng",
          "postCode": [
            "34140"
          ]
        },
        {
          "id": 341406,
          "name": "Toei",
          "postCode": [
            "34140"
          ]
        },
        {
          "id": 341407,
          "name": "Yang Sak Krapho Lum",
          "postCode": [
            "34140"
          ]
        },
        {
          "id": 341411,
          "name": "Yang Yo Phap",
          "postCode": [
            "34140"
          ]
        }
      ]
    },
    {
      "city": "Mueang Ubon Ratchathani",
      "cityCode": 3401,
      "provinceCode": 34,
      "subDistrict": [
        {
          "id": 340109,
          "name": "Chaeramae",
          "postCode": [
            "34000"
          ]
        },
        {
          "id": 340104,
          "name": "Hua Ruea",
          "postCode": [
            "34000"
          ]
        },
        {
          "id": 340108,
          "name": "Kham Yai",
          "postCode": [
            "34000"
          ]
        },
        {
          "id": 340119,
          "name": "Khilek",
          "postCode": [
            "34000"
          ]
        },
        {
          "id": 340113,
          "name": "Krasop",
          "postCode": [
            "34000"
          ]
        },
        {
          "id": 340116,
          "name": "Kut Lat",
          "postCode": [
            "34000"
          ]
        },
        {
          "id": 340101,
          "name": "Nai Mueang",
          "postCode": [
            "34000"
          ]
        },
        {
          "id": 340111,
          "name": "Nong Bo",
          "postCode": [
            "34000"
          ]
        },
        {
          "id": 340105,
          "name": "Nong Khon",
          "postCode": [
            "34000"
          ]
        },
        {
          "id": 340120,
          "name": "Pa-ao",
          "postCode": [
            "34000"
          ]
        },
        {
          "id": 340107,
          "name": "Pathum",
          "postCode": [
            "34000"
          ]
        },
        {
          "id": 340112,
          "name": "Rai Noi",
          "postCode": [
            "34000"
          ]
        }
      ]
    },
    {
      "city": "Na Chaluai",
      "cityCode": 3408,
      "provinceCode": 34,
      "subDistrict": [
        {
          "id": 340804,
          "name": "Ban Tum",
          "postCode": [
            "34280"
          ]
        },
        {
          "id": 340801,
          "name": "Na Chaluai",
          "postCode": [
            "34280"
          ]
        },
        {
          "id": 340806,
          "name": "Non Sawan",
          "postCode": [
            "34280"
          ]
        },
        {
          "id": 340802,
          "name": "Non Sombun",
          "postCode": [
            "34280"
          ]
        },
        {
          "id": 340803,
          "name": "Phon Sawan",
          "postCode": [
            "34280"
          ]
        },
        {
          "id": 340805,
          "name": "Sok Saeng",
          "postCode": [
            "34280"
          ]
        }
      ]
    },
    {
      "city": "Na Tan",
      "cityCode": 3430,
      "provinceCode": 34,
      "subDistrict": [
        {
          "id": 343003,
          "name": "Kong Phon",
          "postCode": [
            "34170"
          ]
        },
        {
          "id": 343001,
          "name": "Na Tan",
          "postCode": [
            "34170"
          ]
        },
        {
          "id": 343002,
          "name": "Phalan",
          "postCode": [
            "34170"
          ]
        },
        {
          "id": 343004,
          "name": "Phang Khen",
          "postCode": [
            "34170"
          ]
        }
      ]
    },
    {
      "city": "Na Yia",
      "cityCode": 3429,
      "provinceCode": 34,
      "subDistrict": [
        {
          "id": 342902,
          "name": "Na Di",
          "postCode": [
            "34160"
          ]
        },
        {
          "id": 342903,
          "name": "Na Rueang",
          "postCode": [
            "34160"
          ]
        },
        {
          "id": 342901,
          "name": "Na Yia",
          "postCode": [
            "34160"
          ]
        }
      ]
    },
    {
      "city": "Nam Khun",
      "cityCode": 3433,
      "provinceCode": 34,
      "subDistrict": [
        {
          "id": 343303,
          "name": "Khilek",
          "postCode": [
            "34260"
          ]
        },
        {
          "id": 343304,
          "name": "Khok Sa-at",
          "postCode": [
            "34260"
          ]
        },
        {
          "id": 343302,
          "name": "Phaibun",
          "postCode": [
            "34260"
          ]
        },
        {
          "id": 343301,
          "name": "Ta Kao",
          "postCode": [
            "34260"
          ]
        }
      ]
    },
    {
      "city": "Nam Yuen",
      "cityCode": 3409,
      "provinceCode": 34,
      "subDistrict": [
        {
          "id": 340906,
          "name": "Bu Pueai",
          "postCode": [
            "34260"
          ]
        },
        {
          "id": 340904,
          "name": "Dom Pradit",
          "postCode": [
            "34260"
          ]
        },
        {
          "id": 340911,
          "name": "Kao Kham",
          "postCode": [
            "34260"
          ]
        },
        {
          "id": 340907,
          "name": "Si Wichian",
          "postCode": [
            "34260"
          ]
        },
        {
          "id": 340901,
          "name": "Song",
          "postCode": [
            "34260"
          ]
        },
        {
          "id": 340903,
          "name": "Yang",
          "postCode": [
            "34260"
          ]
        },
        {
          "id": 340909,
          "name": "Yang Yai",
          "postCode": [
            "34260"
          ]
        }
      ]
    },
    {
      "city": "Phibun Mangsahan",
      "cityCode": 3419,
      "provinceCode": 34,
      "subDistrict": [
        {
          "id": 341914,
          "name": "Ang Sila",
          "postCode": [
            "34110"
          ]
        },
        {
          "id": 341919,
          "name": "Ban Khaem",
          "postCode": [
            "34110"
          ]
        },
        {
          "id": 341904,
          "name": "Don Chik",
          "postCode": [
            "34110"
          ]
        },
        {
          "id": 341902,
          "name": "Kut Chomphu",
          "postCode": [
            "34110"
          ]
        },
        {
          "id": 341906,
          "name": "Na Pho",
          "postCode": [
            "34110"
          ]
        },
        {
          "id": 341918,
          "name": "Non Kalong",
          "postCode": [
            "34110"
          ]
        },
        {
          "id": 341907,
          "name": "Non Klang",
          "postCode": [
            "34110"
          ]
        },
        {
          "id": 341913,
          "name": "Nong Bua Hi",
          "postCode": [
            "34110"
          ]
        },
        {
          "id": 341901,
          "name": "Phibun",
          "postCode": [
            "34110"
          ]
        },
        {
          "id": 341909,
          "name": "Pho Sai",
          "postCode": [
            "34110"
          ]
        },
        {
          "id": 341910,
          "name": "Pho Si",
          "postCode": [
            "34110"
          ]
        },
        {
          "id": 341912,
          "name": "Rai Tai",
          "postCode": [
            "34110"
          ]
        },
        {
          "id": 341911,
          "name": "Rawe",
          "postCode": [
            "34110"
          ]
        },
        {
          "id": 341905,
          "name": "Sai Mun",
          "postCode": [
            "34110"
          ]
        }
      ]
    },
    {
      "city": "Pho Sai",
      "cityCode": 3421,
      "provinceCode": 34,
      "subDistrict": [
        {
          "id": 342106,
          "name": "Lao Ngam",
          "postCode": [
            "34340"
          ]
        },
        {
          "id": 342102,
          "name": "Muang Yai",
          "postCode": [
            "34340"
          ]
        },
        {
          "id": 342101,
          "name": "Pho Sai",
          "postCode": [
            "34340"
          ]
        },
        {
          "id": 342103,
          "name": "Samrong",
          "postCode": [
            "34340"
          ]
        },
        {
          "id": 342105,
          "name": "Saraphi",
          "postCode": [
            "34340"
          ]
        },
        {
          "id": 342104,
          "name": "Song Khon",
          "postCode": [
            "34340"
          ]
        }
      ]
    },
    {
      "city": "Samrong",
      "cityCode": 3422,
      "provinceCode": 34,
      "subDistrict": [
        {
          "id": 342208,
          "name": "Bon",
          "postCode": [
            "34360"
          ]
        },
        {
          "id": 342209,
          "name": "Kham Pom",
          "postCode": [
            "34360"
          ]
        },
        {
          "id": 342204,
          "name": "Kho Noi",
          "postCode": [
            "34360"
          ]
        },
        {
          "id": 342202,
          "name": "Khok Kong",
          "postCode": [
            "34360"
          ]
        },
        {
          "id": 342206,
          "name": "Khok Sawang",
          "postCode": [
            "34360"
          ]
        },
        {
          "id": 342205,
          "name": "Non Ka Len",
          "postCode": [
            "34360"
          ]
        },
        {
          "id": 342207,
          "name": "Non Klang",
          "postCode": [
            "34360"
          ]
        },
        {
          "id": 342203,
          "name": "Nong Hai",
          "postCode": [
            "34360"
          ]
        },
        {
          "id": 342201,
          "name": "Samrong",
          "postCode": [
            "34360"
          ]
        }
      ]
    },
    {
      "city": "Sawang Wirawong",
      "cityCode": 3432,
      "provinceCode": 34,
      "subDistrict": [
        {
          "id": 343203,
          "name": "Bung Malaeng",
          "postCode": [
            "34190"
          ]
        },
        {
          "id": 343201,
          "name": "Kaeng Dom",
          "postCode": [
            "34190"
          ]
        },
        {
          "id": 343204,
          "name": "Sawang",
          "postCode": [
            "34190"
          ]
        },
        {
          "id": 343202,
          "name": "Tha Chang",
          "postCode": [
            "34190"
          ]
        }
      ]
    },
    {
      "city": "Si Mueang Mai",
      "cityCode": 3402,
      "provinceCode": 34,
      "subDistrict": [
        {
          "id": 340211,
          "name": "Don Yai",
          "postCode": [
            "34250"
          ]
        },
        {
          "id": 340202,
          "name": "Kaeng Kok",
          "postCode": [
            "34250"
          ]
        },
        {
          "id": 340208,
          "name": "Kham Lai",
          "postCode": [
            "34250"
          ]
        },
        {
          "id": 340205,
          "name": "Lat Khwai",
          "postCode": [
            "34250"
          ]
        },
        {
          "id": 340201,
          "name": "Na Kham",
          "postCode": [
            "34250"
          ]
        },
        {
          "id": 340210,
          "name": "Na Loen",
          "postCode": [
            "34250"
          ]
        },
        {
          "id": 340209,
          "name": "Nam Thaeng",
          "postCode": [
            "34250"
          ]
        },
        {
          "id": 340206,
          "name": "Song Yang",
          "postCode": [
            "34250"
          ]
        },
        {
          "id": 340207,
          "name": "Tabai",
          "postCode": [
            "34250"
          ]
        },
        {
          "id": 340203,
          "name": "Ueat Yai",
          "postCode": [
            "34250"
          ]
        },
        {
          "id": 340204,
          "name": "Warin",
          "postCode": [
            "34250"
          ]
        }
      ]
    },
    {
      "city": "Sirindhorn",
      "cityCode": 3425,
      "provinceCode": 34,
      "subDistrict": [
        {
          "id": 342502,
          "name": "Chong Mek",
          "postCode": [
            "34350"
          ]
        },
        {
          "id": 342506,
          "name": "Kham Khuean Kaeo",
          "postCode": [
            "34350"
          ]
        },
        {
          "id": 342501,
          "name": "Khan Rai",
          "postCode": [
            "34350"
          ]
        },
        {
          "id": 342503,
          "name": "Lam Dom Noi",
          "postCode": [
            "34350"
          ]
        },
        {
          "id": 342504,
          "name": "Nikhom Sang Ton-eng",
          "postCode": [
            "34350"
          ]
        },
        {
          "id": 342505,
          "name": "Non Ko",
          "postCode": [
            "34350"
          ]
        }
      ]
    },
    {
      "city": "Tan Sum",
      "cityCode": 3420,
      "provinceCode": 34,
      "subDistrict": [
        {
          "id": 342003,
          "name": "Chik Thoeng",
          "postCode": [
            "34330"
          ]
        },
        {
          "id": 342006,
          "name": "Kham Wa",
          "postCode": [
            "34330"
          ]
        },
        {
          "id": 342005,
          "name": "Na Khai",
          "postCode": [
            "34330"
          ]
        },
        {
          "id": 342004,
          "name": "Nong Kung",
          "postCode": [
            "34330"
          ]
        },
        {
          "id": 342002,
          "name": "Samrong",
          "postCode": [
            "34330"
          ]
        },
        {
          "id": 342001,
          "name": "Tan Sum",
          "postCode": [
            "34330"
          ]
        }
      ]
    },
    {
      "city": "Thung Si Udom",
      "cityCode": 3426,
      "provinceCode": 34,
      "subDistrict": [
        {
          "id": 342605,
          "name": "Khok Chamrae",
          "postCode": [
            "34160"
          ]
        },
        {
          "id": 342604,
          "name": "Kut Ruea",
          "postCode": [
            "34160"
          ]
        },
        {
          "id": 342606,
          "name": "Na Hom",
          "postCode": [
            "34160"
          ]
        },
        {
          "id": 342603,
          "name": "Na Kasem",
          "postCode": [
            "34160"
          ]
        },
        {
          "id": 342602,
          "name": "Nong Om",
          "postCode": [
            "34160"
          ]
        }
      ]
    },
    {
      "city": "Trakan Phuet Phon",
      "cityCode": 3411,
      "provinceCode": 34,
      "subDistrict": [
        {
          "id": 341122,
          "name": "Ban Daeng",
          "postCode": [
            "34130"
          ]
        },
        {
          "id": 341120,
          "name": "Huai Fai Phatthana",
          "postCode": [
            "34130"
          ]
        },
        {
          "id": 341103,
          "name": "Kasem",
          "postCode": [
            "34130"
          ]
        },
        {
          "id": 341123,
          "name": "Kham Charoen",
          "postCode": [
            "34130"
          ]
        },
        {
          "id": 341105,
          "name": "Kham Pia",
          "postCode": [
            "34130"
          ]
        },
        {
          "id": 341107,
          "name": "Khok Chan",
          "postCode": [
            "34130"
          ]
        },
        {
          "id": 341106,
          "name": "Khon Sai",
          "postCode": [
            "34130"
          ]
        },
        {
          "id": 341101,
          "name": "Khulu",
          "postCode": [
            "34130"
          ]
        },
        {
          "id": 341102,
          "name": "Kradian",
          "postCode": [
            "34130"
          ]
        },
        {
          "id": 341121,
          "name": "Kut Ya Luan",
          "postCode": [
            "34130"
          ]
        },
        {
          "id": 341104,
          "name": "Kutsakon",
          "postCode": [
            "34130"
          ]
        },
        {
          "id": 341113,
          "name": "Lai Thung",
          "postCode": [
            "34130"
          ]
        },
        {
          "id": 341108,
          "name": "Na Phin",
          "postCode": [
            "34130"
          ]
        },
        {
          "id": 341109,
          "name": "Na Samai",
          "postCode": [
            "34130"
          ]
        },
        {
          "id": 341110,
          "name": "Non Kung",
          "postCode": [
            "34130"
          ]
        },
        {
          "id": 341117,
          "name": "Nong Tao",
          "postCode": [
            "34130"
          ]
        },
        {
          "id": 341114,
          "name": "Pao",
          "postCode": [
            "34130"
          ]
        },
        {
          "id": 341116,
          "name": "Saphue",
          "postCode": [
            "34130"
          ]
        },
        {
          "id": 341115,
          "name": "Se Pet",
          "postCode": [
            "34130"
          ]
        },
        {
          "id": 341112,
          "name": "Tak Daet",
          "postCode": [
            "34130"
          ]
        },
        {
          "id": 341119,
          "name": "Tha Luang",
          "postCode": [
            "34130"
          ]
        },
        {
          "id": 341118,
          "name": "Tham Khae",
          "postCode": [
            "34130"
          ]
        },
        {
          "id": 341111,
          "name": "Trakan",
          "postCode": [
            "34130"
          ]
        }
      ]
    },
    {
      "city": "Warin Chamrap",
      "cityCode": 3415,
      "provinceCode": 34,
      "subDistrict": [
        {
          "id": 341526,
          "name": "Bung Mai",
          "postCode": [
            "34190"
          ]
        },
        {
          "id": 341511,
          "name": "Bung Wai",
          "postCode": [
            "34310"
          ]
        },
        {
          "id": 341524,
          "name": "Huai Khayung",
          "postCode": [
            "34310"
          ]
        },
        {
          "id": 341515,
          "name": "Kham Khwang",
          "postCode": [
            "34190"
          ]
        },
        {
          "id": 341510,
          "name": "Kham Nam Saep",
          "postCode": [
            "34190"
          ]
        },
        {
          "id": 341507,
          "name": "Khu Mueang",
          "postCode": [
            "34190"
          ]
        },
        {
          "id": 341522,
          "name": "Mueang Si Khai",
          "postCode": [
            "34190"
          ]
        },
        {
          "id": 341505,
          "name": "Non Non",
          "postCode": [
            "34190"
          ]
        },
        {
          "id": 341521,
          "name": "Non Phueng",
          "postCode": [
            "34190"
          ]
        },
        {
          "id": 341520,
          "name": "Nong Kin Phen",
          "postCode": [
            "34190"
          ]
        },
        {
          "id": 341516,
          "name": "Pho Yai",
          "postCode": [
            "34190"
          ]
        },
        {
          "id": 341508,
          "name": "Sa Saming",
          "postCode": [
            "34190"
          ]
        },
        {
          "id": 341518,
          "name": "Saen Suk",
          "postCode": [
            "34190"
          ]
        },
        {
          "id": 341504,
          "name": "Tha Lat",
          "postCode": [
            "34310"
          ]
        },
        {
          "id": 341502,
          "name": "That",
          "postCode": [
            "34190"
          ]
        },
        {
          "id": 341501,
          "name": "Warin Chamrap",
          "postCode": [
            "34190"
          ]
        }
      ]
    },
    {
      "city": "Ban Dung",
      "cityCode": 4111,
      "provinceCode": 41,
      "subDistrict": [
        {
          "id": 411107,
          "name": "Ban Chai",
          "postCode": [
            "41190"
          ]
        },
        {
          "id": 411106,
          "name": "Ban Chan",
          "postCode": [
            "41190"
          ]
        },
        {
          "id": 411102,
          "name": "Ban Dung",
          "postCode": [
            "41190"
          ]
        },
        {
          "id": 411111,
          "name": "Ban Muang",
          "postCode": [
            "41190"
          ]
        },
        {
          "id": 411112,
          "name": "Ban Tat",
          "postCode": [
            "41190"
          ]
        },
        {
          "id": 411103,
          "name": "Dong Yen",
          "postCode": [
            "41190"
          ]
        },
        {
          "id": 411113,
          "name": "Na Kham",
          "postCode": [
            "41190"
          ]
        },
        {
          "id": 411108,
          "name": "Na Mai",
          "postCode": [
            "41190"
          ]
        },
        {
          "id": 411105,
          "name": "Om Ko",
          "postCode": [
            "41190"
          ]
        },
        {
          "id": 411104,
          "name": "Phon Sung",
          "postCode": [
            "41190"
          ]
        },
        {
          "id": 411101,
          "name": "Si Suttho",
          "postCode": [
            "41190"
          ]
        },
        {
          "id": 411109,
          "name": "Thon Na Lap",
          "postCode": [
            "41190"
          ]
        },
        {
          "id": 411110,
          "name": "Wang Thong",
          "postCode": [
            "41190"
          ]
        }
      ]
    },
    {
      "city": "Ban Phue",
      "cityCode": 4117,
      "provinceCode": 41,
      "subDistrict": [
        {
          "id": 411712,
          "name": "Ban Kho",
          "postCode": [
            "41160"
          ]
        },
        {
          "id": 411701,
          "name": "Ban Phue",
          "postCode": [
            "41160"
          ]
        },
        {
          "id": 411707,
          "name": "Champa Mong",
          "postCode": [
            "41160"
          ]
        },
        {
          "id": 411702,
          "name": "Hai Sok",
          "postCode": [
            "41160"
          ]
        },
        {
          "id": 411704,
          "name": "Kham Bong",
          "postCode": [
            "41160"
          ]
        },
        {
          "id": 411710,
          "name": "Kham Duang",
          "postCode": [
            "41160"
          ]
        },
        {
          "id": 411706,
          "name": "Khao San",
          "postCode": [
            "41160"
          ]
        },
        {
          "id": 411703,
          "name": "Khuea Nam",
          "postCode": [
            "41160"
          ]
        },
        {
          "id": 411708,
          "name": "Klang Yai",
          "postCode": [
            "41160"
          ]
        },
        {
          "id": 411709,
          "name": "Mueang Phan",
          "postCode": [
            "41160"
          ]
        },
        {
          "id": 411705,
          "name": "Non Thong",
          "postCode": [
            "41160"
          ]
        },
        {
          "id": 411711,
          "name": "Nong Hua Khu",
          "postCode": [
            "41160"
          ]
        },
        {
          "id": 411713,
          "name": "Nong Waeng",
          "postCode": [
            "41160"
          ]
        }
      ]
    },
    {
      "city": "Chai Wan",
      "cityCode": 4108,
      "provinceCode": 41,
      "subDistrict": [
        {
          "id": 410801,
          "name": "Chai Wan",
          "postCode": [
            "41290"
          ]
        },
        {
          "id": 410803,
          "name": "Kham Lo",
          "postCode": [
            "41290"
          ]
        },
        {
          "id": 410802,
          "name": "Nong Lak",
          "postCode": [
            "41290"
          ]
        },
        {
          "id": 410804,
          "name": "Phon Sung",
          "postCode": [
            "41290"
          ]
        }
      ]
    },
    {
      "city": "Ku Kaeo",
      "cityCode": 4124,
      "provinceCode": 41,
      "subDistrict": [
        {
          "id": 412401,
          "name": "Ban Chit",
          "postCode": [
            "41130"
          ]
        },
        {
          "id": 412403,
          "name": "Kho Yai",
          "postCode": [
            "41130"
          ]
        },
        {
          "id": 412404,
          "name": "Khon Sai",
          "postCode": [
            "41130"
          ]
        },
        {
          "id": 412402,
          "name": "Non Thong In",
          "postCode": [
            "41130"
          ]
        }
      ]
    },
    {
      "city": "Kumphawapi",
      "cityCode": 4104,
      "provinceCode": 41,
      "subDistrict": [
        {
          "id": 410404,
          "name": "Chaelae",
          "postCode": [
            "41110"
          ]
        },
        {
          "id": 410406,
          "name": "Chiang Wae",
          "postCode": [
            "41110"
          ]
        },
        {
          "id": 410407,
          "name": "Huai Koeng",
          "postCode": [
            "41110"
          ]
        },
        {
          "id": 410415,
          "name": "Kumphawapi",
          "postCode": [
            "41110"
          ]
        },
        {
          "id": 410416,
          "name": "Nong Wa",
          "postCode": [
            "41110"
          ]
        },
        {
          "id": 410411,
          "name": "Pakho",
          "postCode": [
            "41370"
          ]
        },
        {
          "id": 410413,
          "name": "Pha Suk",
          "postCode": [
            "41370"
          ]
        },
        {
          "id": 410402,
          "name": "Phan Don",
          "postCode": [
            "41370"
          ]
        },
        {
          "id": 410410,
          "name": "Si O",
          "postCode": [
            "41110"
          ]
        },
        {
          "id": 410409,
          "name": "Soephloe",
          "postCode": [
            "41370"
          ]
        },
        {
          "id": 410414,
          "name": "Tha Li",
          "postCode": [
            "41110"
          ]
        },
        {
          "id": 410401,
          "name": "Tum Tai",
          "postCode": [
            "41110"
          ]
        },
        {
          "id": 410403,
          "name": "Wiang Kham",
          "postCode": [
            "41110"
          ]
        }
      ]
    },
    {
      "city": "Kut Chap",
      "cityCode": 4102,
      "provinceCode": 41,
      "subDistrict": [
        {
          "id": 410204,
          "name": "Chiang Pheng",
          "postCode": [
            "41250"
          ]
        },
        {
          "id": 410203,
          "name": "Khon Yung",
          "postCode": [
            "41250"
          ]
        },
        {
          "id": 410201,
          "name": "Kut Chap",
          "postCode": [
            "41250"
          ]
        },
        {
          "id": 410206,
          "name": "Mueang Phia",
          "postCode": [
            "41250"
          ]
        },
        {
          "id": 410202,
          "name": "Pa Kho",
          "postCode": [
            "41250"
          ]
        },
        {
          "id": 410205,
          "name": "Sang Ko",
          "postCode": [
            "41250"
          ]
        },
        {
          "id": 410207,
          "name": "Tan Lian",
          "postCode": [
            "41250"
          ]
        }
      ]
    },
    {
      "city": "Mueang Udon Thani",
      "cityCode": 4101,
      "provinceCode": 41,
      "subDistrict": [
        {
          "id": 410117,
          "name": "Ban Chan",
          "postCode": [
            "41000"
          ]
        },
        {
          "id": 410103,
          "name": "Ban Khao",
          "postCode": [
            "41000"
          ]
        },
        {
          "id": 410112,
          "name": "Ban Lueam",
          "postCode": [
            "41000"
          ]
        },
        {
          "id": 410105,
          "name": "Ban Tat",
          "postCode": [
            "41000"
          ]
        },
        {
          "id": 410113,
          "name": "Chiang Phin",
          "postCode": [
            "41000"
          ]
        },
        {
          "id": 410108,
          "name": "Chiang Yuen",
          "postCode": [
            "41000"
          ]
        },
        {
          "id": 410119,
          "name": "Khok Sa-at",
          "postCode": [
            "41000"
          ]
        },
        {
          "id": 410110,
          "name": "Kut Sa",
          "postCode": [
            "41000"
          ]
        },
        {
          "id": 410101,
          "name": "Mak Khaeng",
          "postCode": [
            "41000"
          ]
        },
        {
          "id": 410107,
          "name": "Mu Mon",
          "postCode": [
            "41000"
          ]
        },
        {
          "id": 410111,
          "name": "Na Di",
          "postCode": [
            "41000"
          ]
        },
        {
          "id": 410116,
          "name": "Na Kha",
          "postCode": [
            "41000"
          ]
        },
        {
          "id": 410120,
          "name": "Na Kwang",
          "postCode": [
            "41000"
          ]
        },
        {
          "id": 410102,
          "name": "Nikhom Songkhro",
          "postCode": [
            "41000"
          ]
        },
        {
          "id": 410106,
          "name": "Non Sung",
          "postCode": [
            "41330"
          ]
        },
        {
          "id": 410104,
          "name": "Nong Bua",
          "postCode": [
            "41000"
          ]
        },
        {
          "id": 410115,
          "name": "Nong Hai",
          "postCode": [
            "41000"
          ]
        },
        {
          "id": 410118,
          "name": "Nong Khon Kwang",
          "postCode": [
            "41000"
          ]
        },
        {
          "id": 410109,
          "name": "Nong Na Kham",
          "postCode": [
            "41000"
          ]
        },
        {
          "id": 410121,
          "name": "Nong Phai",
          "postCode": [
            "41330"
          ]
        },
        {
          "id": 410114,
          "name": "Sam Phrao",
          "postCode": [
            "41000"
          ]
        }
      ]
    },
    {
      "city": "Na Yung",
      "cityCode": 4122,
      "provinceCode": 41,
      "subDistrict": [
        {
          "id": 412202,
          "name": "Ban Kong",
          "postCode": [
            "41380"
          ]
        },
        {
          "id": 412203,
          "name": "Na Khae",
          "postCode": [
            "41380"
          ]
        },
        {
          "id": 412201,
          "name": "Na Yung",
          "postCode": [
            "41380"
          ]
        },
        {
          "id": 412204,
          "name": "Non Thong",
          "postCode": [
            "41380"
          ]
        }
      ]
    },
    {
      "city": "Nam Som",
      "cityCode": 4118,
      "provinceCode": 41,
      "subDistrict": [
        {
          "id": 411806,
          "name": "Ban Yuak",
          "postCode": [
            "41210"
          ]
        },
        {
          "id": 411801,
          "name": "Na Ngua",
          "postCode": [
            "41210"
          ]
        },
        {
          "id": 411802,
          "name": "Nam Som",
          "postCode": [
            "41210"
          ]
        },
        {
          "id": 411805,
          "name": "Nong Waeng",
          "postCode": [
            "41210"
          ]
        },
        {
          "id": 411812,
          "name": "Samakkhi",
          "postCode": [
            "41210"
          ]
        },
        {
          "id": 411810,
          "name": "Si Samran",
          "postCode": [
            "41210"
          ]
        },
        {
          "id": 411807,
          "name": "Som Yiam",
          "postCode": [
            "41210"
          ]
        }
      ]
    },
    {
      "city": "Non Sa-at",
      "cityCode": 4105,
      "provinceCode": 41,
      "subDistrict": [
        {
          "id": 410502,
          "name": "Bung Kaeo",
          "postCode": [
            "41240"
          ]
        },
        {
          "id": 410506,
          "name": "Khok Klang",
          "postCode": [
            "41240"
          ]
        },
        {
          "id": 410501,
          "name": "Non Sa-at",
          "postCode": [
            "41240"
          ]
        },
        {
          "id": 410505,
          "name": "Nong Kung Si",
          "postCode": [
            "41240"
          ]
        },
        {
          "id": 410503,
          "name": "Pho Si Samran",
          "postCode": [
            "41240"
          ]
        },
        {
          "id": 410504,
          "name": "Thom Na Ngam",
          "postCode": [
            "41240"
          ]
        }
      ]
    },
    {
      "city": "Nong Han",
      "cityCode": 4106,
      "provinceCode": 41,
      "subDistrict": [
        {
          "id": 410609,
          "name": "Ban Chiang",
          "postCode": [
            "41320"
          ]
        },
        {
          "id": 410610,
          "name": "Ban Ya",
          "postCode": [
            "41320"
          ]
        },
        {
          "id": 410617,
          "name": "Don Hai Sok",
          "postCode": [
            "41130"
          ]
        },
        {
          "id": 410601,
          "name": "Nong Han",
          "postCode": [
            "41130"
          ]
        },
        {
          "id": 410602,
          "name": "Nong Mek",
          "postCode": [
            "41130"
          ]
        },
        {
          "id": 410614,
          "name": "Nong Phai",
          "postCode": [
            "41130"
          ]
        },
        {
          "id": 410618,
          "name": "Nong Sa Pla",
          "postCode": [
            "41320"
          ]
        },
        {
          "id": 410612,
          "name": "Phak Top",
          "postCode": [
            "41130"
          ]
        },
        {
          "id": 410605,
          "name": "Phang Ngu",
          "postCode": [
            "41130"
          ]
        },
        {
          "id": 410611,
          "name": "Phon Ngam",
          "postCode": [
            "41130"
          ]
        },
        {
          "id": 410606,
          "name": "Sabaeng",
          "postCode": [
            "41130"
          ]
        },
        {
          "id": 410607,
          "name": "Soi Phrao",
          "postCode": [
            "41130"
          ]
        }
      ]
    },
    {
      "city": "Nong Saeng",
      "cityCode": 4121,
      "provinceCode": 41,
      "subDistrict": [
        {
          "id": 412103,
          "name": "Na Di",
          "postCode": [
            "41340"
          ]
        },
        {
          "id": 412101,
          "name": "Nong Saeng",
          "postCode": [
            "41340"
          ]
        },
        {
          "id": 412102,
          "name": "Saeng Sawang",
          "postCode": [
            "41340"
          ]
        },
        {
          "id": 412104,
          "name": "Thap Kung",
          "postCode": [
            "41340"
          ]
        }
      ]
    },
    {
      "city": "Nong Wua So",
      "cityCode": 4103,
      "provinceCode": 41,
      "subDistrict": [
        {
          "id": 410304,
          "name": "Kut Mak Fai",
          "postCode": [
            "41220"
          ]
        },
        {
          "id": 410301,
          "name": "Mak Ya",
          "postCode": [
            "41360"
          ]
        },
        {
          "id": 410305,
          "name": "Nam Phon",
          "postCode": [
            "41360"
          ]
        },
        {
          "id": 410307,
          "name": "Non Wai",
          "postCode": [
            "41220"
          ]
        },
        {
          "id": 410306,
          "name": "Nong Bua Ban",
          "postCode": [
            "41360"
          ]
        },
        {
          "id": 410302,
          "name": "Nong O",
          "postCode": [
            "41220"
          ]
        },
        {
          "id": 410308,
          "name": "Nong Wua So",
          "postCode": [
            "41360"
          ]
        },
        {
          "id": 410303,
          "name": "Up Mung",
          "postCode": [
            "41220"
          ]
        }
      ]
    },
    {
      "city": "Phen",
      "cityCode": 4119,
      "provinceCode": 41,
      "subDistrict": [
        {
          "id": 411907,
          "name": "Ban Lao",
          "postCode": [
            "41150"
          ]
        },
        {
          "id": 411902,
          "name": "Ban That",
          "postCode": [
            "41150"
          ]
        },
        {
          "id": 411904,
          "name": "Chiang Wang",
          "postCode": [
            "41150"
          ]
        },
        {
          "id": 411908,
          "name": "Chom Si",
          "postCode": [
            "41150"
          ]
        },
        {
          "id": 411910,
          "name": "Khok Klang",
          "postCode": [
            "41150"
          ]
        },
        {
          "id": 411906,
          "name": "Na Bua",
          "postCode": [
            "41150"
          ]
        },
        {
          "id": 411903,
          "name": "Na Phu",
          "postCode": [
            "41150"
          ]
        },
        {
          "id": 411901,
          "name": "Phen",
          "postCode": [
            "41150"
          ]
        },
        {
          "id": 411911,
          "name": "Sang Paen",
          "postCode": [
            "41150"
          ]
        },
        {
          "id": 411905,
          "name": "Sum Sao",
          "postCode": [
            "41150"
          ]
        },
        {
          "id": 411909,
          "name": "Tao Hai",
          "postCode": [
            "41150"
          ]
        }
      ]
    },
    {
      "city": "Phibun Rak",
      "cityCode": 4123,
      "provinceCode": 41,
      "subDistrict": [
        {
          "id": 412301,
          "name": "Ban Daeng",
          "postCode": [
            "41130"
          ]
        },
        {
          "id": 412303,
          "name": "Don Kloi",
          "postCode": [
            "41130"
          ]
        },
        {
          "id": 412302,
          "name": "Na Sai",
          "postCode": [
            "41130"
          ]
        }
      ]
    },
    {
      "city": "Prachak Sinlapakhom",
      "cityCode": 4125,
      "provinceCode": 41,
      "subDistrict": [
        {
          "id": 412502,
          "name": "Huai Sam Phat",
          "postCode": [
            "41110"
          ]
        },
        {
          "id": 412501,
          "name": "Na Muang",
          "postCode": [
            "41110"
          ]
        },
        {
          "id": 412503,
          "name": "Um Chan",
          "postCode": [
            "41110"
          ]
        }
      ]
    },
    {
      "city": "Sang Khom",
      "cityCode": 4120,
      "provinceCode": 41,
      "subDistrict": [
        {
          "id": 412004,
          "name": "Ban Khok",
          "postCode": [
            "41260"
          ]
        },
        {
          "id": 412003,
          "name": "Ban Yuat",
          "postCode": [
            "41260"
          ]
        },
        {
          "id": 412006,
          "name": "Bang Hin Ngom",
          "postCode": [
            "41260"
          ]
        },
        {
          "id": 412002,
          "name": "Chiang Da",
          "postCode": [
            "41260"
          ]
        },
        {
          "id": 412005,
          "name": "Na Sa-at",
          "postCode": [
            "41260"
          ]
        },
        {
          "id": 412001,
          "name": "Sang Khom",
          "postCode": [
            "41260"
          ]
        }
      ]
    },
    {
      "city": "Si That",
      "cityCode": 4109,
      "provinceCode": 41,
      "subDistrict": [
        {
          "id": 410903,
          "name": "Ban Prong",
          "postCode": [
            "41230"
          ]
        },
        {
          "id": 410902,
          "name": "Champi",
          "postCode": [
            "41230"
          ]
        },
        {
          "id": 410904,
          "name": "Hua Na Kham",
          "postCode": [
            "41230"
          ]
        },
        {
          "id": 410906,
          "name": "Na Yung",
          "postCode": [
            "41230"
          ]
        },
        {
          "id": 410905,
          "name": "Nong Nok Khian",
          "postCode": [
            "41230"
          ]
        },
        {
          "id": 410901,
          "name": "Si That",
          "postCode": [
            "41230"
          ]
        },
        {
          "id": 410907,
          "name": "Tat Thong",
          "postCode": [
            "41230"
          ]
        }
      ]
    },
    {
      "city": "Thung Fon",
      "cityCode": 4107,
      "provinceCode": 41,
      "subDistrict": [
        {
          "id": 410703,
          "name": "Na Chum Saeng",
          "postCode": [
            "41310"
          ]
        },
        {
          "id": 410704,
          "name": "Na Thom",
          "postCode": [
            "41310"
          ]
        },
        {
          "id": 410701,
          "name": "Thung Fon",
          "postCode": [
            "41310"
          ]
        },
        {
          "id": 410702,
          "name": "Thung Yai",
          "postCode": [
            "41310"
          ]
        }
      ]
    },
    {
      "city": "Wang Sam Mo",
      "cityCode": 4110,
      "provinceCode": 41,
      "subDistrict": [
        {
          "id": 411003,
          "name": "Ba Yao",
          "postCode": [
            "41280"
          ]
        },
        {
          "id": 411005,
          "name": "Kham Khok Sung",
          "postCode": [
            "41280"
          ]
        },
        {
          "id": 411001,
          "name": "Nong Kung Thap Ma",
          "postCode": [
            "41280"
          ]
        },
        {
          "id": 411002,
          "name": "Nong Ya Sai",
          "postCode": [
            "41280"
          ]
        },
        {
          "id": 411004,
          "name": "Phasuk",
          "postCode": [
            "41280"
          ]
        },
        {
          "id": 411006,
          "name": "Wang Sam Mo",
          "postCode": [
            "41280"
          ]
        }
      ]
    },
    {
      "city": "Ban Rai",
      "cityCode": 6106,
      "provinceCode": 61,
      "subDistrict": [
        {
          "id": 610611,
          "name": "Ban Bueng",
          "postCode": [
            "61140"
          ]
        },
        {
          "id": 610612,
          "name": "Ban Mai Khlong Khian",
          "postCode": [
            "61180"
          ]
        },
        {
          "id": 610601,
          "name": "Ban Rai",
          "postCode": [
            "61140"
          ]
        },
        {
          "id": 610614,
          "name": "Chao Wat",
          "postCode": [
            "61140"
          ]
        },
        {
          "id": 610610,
          "name": "Hu Chang",
          "postCode": [
            "61180"
          ]
        },
        {
          "id": 610603,
          "name": "Huai Haeng",
          "postCode": [
            "61140"
          ]
        },
        {
          "id": 610607,
          "name": "Kaen Makrut",
          "postCode": [
            "61140"
          ]
        },
        {
          "id": 610604,
          "name": "Khok Khwai",
          "postCode": [
            "61140"
          ]
        },
        {
          "id": 610606,
          "name": "Muang Ka Rung",
          "postCode": [
            "61180"
          ]
        },
        {
          "id": 610613,
          "name": "Nong Bom Kluai",
          "postCode": [
            "61180"
          ]
        },
        {
          "id": 610609,
          "name": "Nong Chok",
          "postCode": [
            "61180"
          ]
        },
        {
          "id": 610602,
          "name": "Thap Luang",
          "postCode": [
            "61140"
          ]
        },
        {
          "id": 610605,
          "name": "Wang Hin",
          "postCode": [
            "61180"
          ]
        }
      ]
    },
    {
      "city": "Huai Khot",
      "cityCode": 6108,
      "provinceCode": 61,
      "subDistrict": [
        {
          "id": 610803,
          "name": "Huai Khot",
          "postCode": [
            "61170"
          ]
        },
        {
          "id": 610801,
          "name": "Suk Ruethai",
          "postCode": [
            "61170"
          ]
        },
        {
          "id": 610802,
          "name": "Thong Lang",
          "postCode": [
            "61170"
          ]
        }
      ]
    },
    {
      "city": "Lan Sak",
      "cityCode": 6107,
      "provinceCode": 61,
      "subDistrict": [
        {
          "id": 610701,
          "name": "Lan Sak",
          "postCode": [
            "61160"
          ]
        },
        {
          "id": 610705,
          "name": "Nam Rop",
          "postCode": [
            "61160"
          ]
        },
        {
          "id": 610703,
          "name": "Pa O",
          "postCode": [
            "61160"
          ]
        },
        {
          "id": 610702,
          "name": "Pradu Yuen",
          "postCode": [
            "61160"
          ]
        },
        {
          "id": 610704,
          "name": "Rabam",
          "postCode": [
            "61160"
          ]
        },
        {
          "id": 610706,
          "name": "Thung Na Ngam",
          "postCode": [
            "61160"
          ]
        }
      ]
    },
    {
      "city": "Mueang Uthai Thani",
      "cityCode": 6101,
      "provinceCode": 61,
      "subDistrict": [
        {
          "id": 610104,
          "name": "Don Khwang",
          "postCode": [
            "61000"
          ]
        },
        {
          "id": 610105,
          "name": "Hat Thanong",
          "postCode": [
            "61000"
          ]
        },
        {
          "id": 610106,
          "name": "Ko Thepho",
          "postCode": [
            "61000"
          ]
        },
        {
          "id": 610102,
          "name": "Nam Suem",
          "postCode": [
            "61000"
          ]
        },
        {
          "id": 610114,
          "name": "Noen Chaeng",
          "postCode": [
            "61000"
          ]
        },
        {
          "id": 610109,
          "name": "Non Lek",
          "postCode": [
            "61000"
          ]
        },
        {
          "id": 610108,
          "name": "Nong Kae",
          "postCode": [
            "61000"
          ]
        },
        {
          "id": 610111,
          "name": "Nong Phai Baen",
          "postCode": [
            "61000"
          ]
        },
        {
          "id": 610112,
          "name": "Nong Phang Kha",
          "postCode": [
            "61000"
          ]
        },
        {
          "id": 610110,
          "name": "Nong Tao",
          "postCode": [
            "61000"
          ]
        },
        {
          "id": 610103,
          "name": "Sakae Krang",
          "postCode": [
            "61000"
          ]
        },
        {
          "id": 610107,
          "name": "Tha Sung",
          "postCode": [
            "61000"
          ]
        },
        {
          "id": 610113,
          "name": "Thung Yai",
          "postCode": [
            "61000"
          ]
        },
        {
          "id": 610101,
          "name": "Uthai Mai",
          "postCode": [
            "61000"
          ]
        }
      ]
    },
    {
      "city": "Nong Chang",
      "cityCode": 6104,
      "provinceCode": 61,
      "subDistrict": [
        {
          "id": 610405,
          "name": "Ban Kao",
          "postCode": [
            "61110"
          ]
        },
        {
          "id": 610409,
          "name": "Khao Bang Kraek",
          "postCode": [
            "61170"
          ]
        },
        {
          "id": 610410,
          "name": "Khao Kwang Thong",
          "postCode": [
            "61110"
          ]
        },
        {
          "id": 610401,
          "name": "Nong Chang",
          "postCode": [
            "61110"
          ]
        },
        {
          "id": 610403,
          "name": "Nong Nang Nuan",
          "postCode": [
            "61110"
          ]
        },
        {
          "id": 610404,
          "name": "Nong Suang",
          "postCode": [
            "61110"
          ]
        },
        {
          "id": 610402,
          "name": "Nong Yang",
          "postCode": [
            "61110"
          ]
        },
        {
          "id": 610407,
          "name": "Thung Pho",
          "postCode": [
            "61110"
          ]
        },
        {
          "id": 610408,
          "name": "Thung Phong",
          "postCode": [
            "61110"
          ]
        },
        {
          "id": 610406,
          "name": "Utai Kao",
          "postCode": [
            "61110"
          ]
        }
      ]
    },
    {
      "city": "Nong Khayang",
      "cityCode": 6105,
      "provinceCode": 61,
      "subDistrict": [
        {
          "id": 610503,
          "name": "Don Kloi",
          "postCode": [
            "61130"
          ]
        },
        {
          "id": 610509,
          "name": "Dong Khwang",
          "postCode": [
            "61130"
          ]
        },
        {
          "id": 610504,
          "name": "Huai Rop",
          "postCode": [
            "61130"
          ]
        },
        {
          "id": 610508,
          "name": "Lum Khao",
          "postCode": [
            "61130"
          ]
        },
        {
          "id": 610507,
          "name": "Mok Thaeo",
          "postCode": [
            "61130"
          ]
        },
        {
          "id": 610501,
          "name": "Nong Khayang",
          "postCode": [
            "61130"
          ]
        },
        {
          "id": 610502,
          "name": "Nong Phai",
          "postCode": [
            "61130"
          ]
        },
        {
          "id": 610506,
          "name": "Tha Pho",
          "postCode": [
            "61130"
          ]
        },
        {
          "id": 610505,
          "name": "Thung Phueng",
          "postCode": [
            "61130"
          ]
        }
      ]
    },
    {
      "city": "Sawang Arom",
      "cityCode": 6103,
      "provinceCode": 61,
      "subDistrict": [
        {
          "id": 610305,
          "name": "Bo Yang",
          "postCode": [
            "61150"
          ]
        },
        {
          "id": 610302,
          "name": "Nong Luang",
          "postCode": [
            "61150"
          ]
        },
        {
          "id": 610304,
          "name": "Phai Khiao",
          "postCode": [
            "61150"
          ]
        },
        {
          "id": 610303,
          "name": "Phluang Song Nang",
          "postCode": [
            "61150"
          ]
        },
        {
          "id": 610301,
          "name": "Sawang Arom",
          "postCode": [
            "61150"
          ]
        }
      ]
    },
    {
      "city": "Thap Than",
      "cityCode": 6102,
      "provinceCode": 61,
      "subDistrict": [
        {
          "id": 610203,
          "name": "Khao Khi Foi",
          "postCode": [
            "61120"
          ]
        },
        {
          "id": 610205,
          "name": "Khok Mo",
          "postCode": [
            "61120"
          ]
        },
        {
          "id": 610207,
          "name": "Nong Klang Dong",
          "postCode": [
            "61120"
          ]
        },
        {
          "id": 610208,
          "name": "Nong Krathum",
          "postCode": [
            "61120"
          ]
        },
        {
          "id": 610209,
          "name": "Nong Sa",
          "postCode": [
            "61120"
          ]
        },
        {
          "id": 610204,
          "name": "Nong Ya Plong",
          "postCode": [
            "61120"
          ]
        },
        {
          "id": 610206,
          "name": "Nong Yai Da",
          "postCode": [
            "61120"
          ]
        },
        {
          "id": 610210,
          "name": "Taluk Du",
          "postCode": [
            "61120"
          ]
        },
        {
          "id": 610201,
          "name": "Thap Than",
          "postCode": [
            "61120"
          ]
        },
        {
          "id": 610202,
          "name": "Thung Na Thai",
          "postCode": [
            "61120"
          ]
        }
      ]
    },
    {
      "city": "Ban Khok",
      "cityCode": 5306,
      "provinceCode": 53,
      "subDistrict": [
        {
          "id": 530602,
          "name": "Ban Khok",
          "postCode": [
            "53180"
          ]
        },
        {
          "id": 530604,
          "name": "Bo Bia",
          "postCode": [
            "53180"
          ]
        },
        {
          "id": 530601,
          "name": "Muang Chet Ton",
          "postCode": [
            "53180"
          ]
        },
        {
          "id": 530603,
          "name": "Na Khum",
          "postCode": [
            "53180"
          ]
        }
      ]
    },
    {
      "city": "Fak Tha",
      "cityCode": 5305,
      "provinceCode": 53,
      "subDistrict": [
        {
          "id": 530503,
          "name": "Ban Siao",
          "postCode": [
            "53160"
          ]
        },
        {
          "id": 530501,
          "name": "Fak Tha",
          "postCode": [
            "53160"
          ]
        },
        {
          "id": 530504,
          "name": "Song Hong",
          "postCode": [
            "53160"
          ]
        },
        {
          "id": 530502,
          "name": "Song Khon",
          "postCode": [
            "53160"
          ]
        }
      ]
    },
    {
      "city": "Laplae",
      "cityCode": 5308,
      "provinceCode": 53,
      "subDistrict": [
        {
          "id": 530805,
          "name": "Chai Chumphon",
          "postCode": [
            "53130"
          ]
        },
        {
          "id": 530808,
          "name": "Dan Mae Kham Man",
          "postCode": [
            "53210"
          ]
        },
        {
          "id": 530804,
          "name": "Fai Luang",
          "postCode": [
            "53130"
          ]
        },
        {
          "id": 530802,
          "name": "Mae Phun",
          "postCode": [
            "53130"
          ]
        },
        {
          "id": 530803,
          "name": "Na Nok Kok",
          "postCode": [
            "53130"
          ]
        },
        {
          "id": 530806,
          "name": "Phai Lom",
          "postCode": [
            "53210"
          ]
        },
        {
          "id": 530801,
          "name": "Si Phanom Mat",
          "postCode": [
            "53130"
          ]
        },
        {
          "id": 530807,
          "name": "Thung Yang",
          "postCode": [
            "53210"
          ]
        }
      ]
    },
    {
      "city": "Mueang Uttaradit",
      "cityCode": 5301,
      "provinceCode": 53,
      "subDistrict": [
        {
          "id": 530111,
          "name": "Ban Dan",
          "postCode": [
            "53000"
          ]
        },
        {
          "id": 530110,
          "name": "Ban Dan Na Kham",
          "postCode": [
            "53000"
          ]
        },
        {
          "id": 530103,
          "name": "Ban Ko",
          "postCode": [
            "53000",
            "53170"
          ]
        },
        {
          "id": 530107,
          "name": "Hat Kruat",
          "postCode": [
            "53000"
          ]
        },
        {
          "id": 530115,
          "name": "Hat Ngio",
          "postCode": [
            "53000"
          ]
        },
        {
          "id": 530116,
          "name": "Khun Fang",
          "postCode": [
            "53000"
          ]
        },
        {
          "id": 530105,
          "name": "Khung Taphao",
          "postCode": [
            "53000"
          ]
        },
        {
          "id": 530108,
          "name": "Nam Rit",
          "postCode": [
            "53000"
          ]
        },
        {
          "id": 530109,
          "name": "Ngio Ngam",
          "postCode": [
            "53000"
          ]
        },
        {
          "id": 530104,
          "name": "Pa Sao",
          "postCode": [
            "53000"
          ]
        },
        {
          "id": 530112,
          "name": "Pha Chuk",
          "postCode": [
            "53000"
          ]
        },
        {
          "id": 530114,
          "name": "Saen To",
          "postCode": [
            "53000"
          ]
        },
        {
          "id": 530101,
          "name": "Tha It",
          "postCode": [
            "53000"
          ]
        },
        {
          "id": 530102,
          "name": "Tha Sao",
          "postCode": [
            "53000"
          ]
        },
        {
          "id": 530117,
          "name": "Tham Chalong",
          "postCode": [
            "53000"
          ]
        },
        {
          "id": 530113,
          "name": "Wang Din",
          "postCode": [
            "53000"
          ]
        },
        {
          "id": 530106,
          "name": "Wang Kaphi",
          "postCode": [
            "53170"
          ]
        }
      ]
    },
    {
      "city": "Nam Pat",
      "cityCode": 5304,
      "provinceCode": 53,
      "subDistrict": [
        {
          "id": 530402,
          "name": "Ban Fai",
          "postCode": [
            "53110"
          ]
        },
        {
          "id": 530403,
          "name": "Den Lek",
          "postCode": [
            "53110"
          ]
        },
        {
          "id": 530406,
          "name": "Huai Mun",
          "postCode": [
            "53110"
          ]
        },
        {
          "id": 530404,
          "name": "Nam Khrai",
          "postCode": [
            "53110"
          ]
        },
        {
          "id": 530405,
          "name": "Nam Phai",
          "postCode": [
            "53110"
          ]
        },
        {
          "id": 530401,
          "name": "Saen To",
          "postCode": [
            "53110"
          ]
        },
        {
          "id": 530407,
          "name": "Tha Faek",
          "postCode": [
            "53110"
          ]
        }
      ]
    },
    {
      "city": "Phichai",
      "cityCode": 5307,
      "provinceCode": 53,
      "subDistrict": [
        {
          "id": 530702,
          "name": "Ban Dara",
          "postCode": [
            "53220"
          ]
        },
        {
          "id": 530708,
          "name": "Ban Khon",
          "postCode": [
            "53120"
          ]
        },
        {
          "id": 530706,
          "name": "Ban Mo",
          "postCode": [
            "53120"
          ]
        },
        {
          "id": 530705,
          "name": "Kho Rum",
          "postCode": [
            "53120"
          ]
        },
        {
          "id": 530710,
          "name": "Na In",
          "postCode": [
            "53120"
          ]
        },
        {
          "id": 530711,
          "name": "Na Yang",
          "postCode": [
            "53120"
          ]
        },
        {
          "id": 530701,
          "name": "Nai Mueang",
          "postCode": [
            "53120"
          ]
        },
        {
          "id": 530709,
          "name": "Phaya Maen",
          "postCode": [
            "53120"
          ]
        },
        {
          "id": 530703,
          "name": "Rai Oi",
          "postCode": [
            "53120"
          ]
        },
        {
          "id": 530707,
          "name": "Tha Mafueang",
          "postCode": [
            "53120"
          ]
        },
        {
          "id": 530704,
          "name": "Tha Sak",
          "postCode": [
            "53220"
          ]
        }
      ]
    },
    {
      "city": "Tha Pla",
      "cityCode": 5303,
      "provinceCode": 53,
      "subDistrict": [
        {
          "id": 530304,
          "name": "Chalim",
          "postCode": [
            "53150"
          ]
        },
        {
          "id": 530302,
          "name": "Hat La",
          "postCode": [
            "53150",
            "53190"
          ]
        },
        {
          "id": 530305,
          "name": "Nam Man",
          "postCode": [
            "53150"
          ]
        },
        {
          "id": 530307,
          "name": "Nang Phaya",
          "postCode": [
            "53150"
          ]
        },
        {
          "id": 530303,
          "name": "Pha Lueat",
          "postCode": [
            "53190"
          ]
        },
        {
          "id": 530308,
          "name": "Ruam Chit",
          "postCode": [
            "53190"
          ]
        },
        {
          "id": 530301,
          "name": "Tha Pla",
          "postCode": [
            "53150"
          ]
        }
      ]
    },
    {
      "city": "Thong Saen Khan",
      "cityCode": 5309,
      "provinceCode": 53,
      "subDistrict": [
        {
          "id": 530902,
          "name": "Bo Thong",
          "postCode": [
            "53230"
          ]
        },
        {
          "id": 530904,
          "name": "Nam Phi",
          "postCode": [
            "53230"
          ]
        },
        {
          "id": 530903,
          "name": "Pa Khai",
          "postCode": [
            "53230"
          ]
        },
        {
          "id": 530901,
          "name": "Phak Khuang",
          "postCode": [
            "53230"
          ]
        }
      ]
    },
    {
      "city": "Tron",
      "cityCode": 5302,
      "provinceCode": 53,
      "subDistrict": [
        {
          "id": 530202,
          "name": "Ban Kaeng",
          "postCode": [
            "53140"
          ]
        },
        {
          "id": 530203,
          "name": "Hat Song Khwae",
          "postCode": [
            "53140"
          ]
        },
        {
          "id": 530205,
          "name": "Khoi Sung",
          "postCode": [
            "53140"
          ]
        },
        {
          "id": 530204,
          "name": "Nam Ang",
          "postCode": [
            "53140"
          ]
        },
        {
          "id": 530201,
          "name": "Wang Daeng",
          "postCode": [
            "53140"
          ]
        }
      ]
    },
    {
      "city": "Bannang Sata",
      "cityCode": 9503,
      "provinceCode": 95,
      "subDistrict": [
        {
          "id": 950302,
          "name": "Bacho",
          "postCode": [
            "95130"
          ]
        },
        {
          "id": 950301,
          "name": "Bannang Sata",
          "postCode": [
            "95130"
          ]
        },
        {
          "id": 950306,
          "name": "Khuean Bang Lang",
          "postCode": [
            "95130"
          ]
        },
        {
          "id": 950305,
          "name": "Taling Chan",
          "postCode": [
            "95130"
          ]
        },
        {
          "id": 950303,
          "name": "Tanao Pute",
          "postCode": [
            "95130"
          ]
        },
        {
          "id": 950304,
          "name": "Tham Thalu",
          "postCode": [
            "95130"
          ]
        }
      ]
    },
    {
      "city": "Betong",
      "cityCode": 9502,
      "provinceCode": 95,
      "subDistrict": [
        {
          "id": 950204,
          "name": "Aiyoeweng",
          "postCode": [
            "95110"
          ]
        },
        {
          "id": 950201,
          "name": "Betong",
          "postCode": [
            "95110"
          ]
        },
        {
          "id": 950203,
          "name": "Tano Maero",
          "postCode": [
            "95110"
          ]
        },
        {
          "id": 950205,
          "name": "Than Nam Thip",
          "postCode": [
            "95110"
          ]
        },
        {
          "id": 950202,
          "name": "Yarom",
          "postCode": [
            "95110"
          ]
        }
      ]
    },
    {
      "city": "Kabang",
      "cityCode": 9507,
      "provinceCode": 95,
      "subDistrict": [
        {
          "id": 950702,
          "name": "Bala",
          "postCode": [
            "95120"
          ]
        },
        {
          "id": 950701,
          "name": "Kabang",
          "postCode": [
            "95120"
          ]
        }
      ]
    },
    {
      "city": "Krong Pinang",
      "cityCode": 9508,
      "provinceCode": 95,
      "subDistrict": [
        {
          "id": 950803,
          "name": "Huai Krathing",
          "postCode": [
            "95000"
          ]
        },
        {
          "id": 950801,
          "name": "Krong Pinang",
          "postCode": [
            "95000"
          ]
        },
        {
          "id": 950804,
          "name": "Purong",
          "postCode": [
            "95000"
          ]
        },
        {
          "id": 950802,
          "name": "Sa-e",
          "postCode": [
            "95000"
          ]
        }
      ]
    },
    {
      "city": "Mueang Yala",
      "cityCode": 9501,
      "provinceCode": 95,
      "subDistrict": [
        {
          "id": 950115,
          "name": "Bannang Sareng",
          "postCode": [
            "95000"
          ]
        },
        {
          "id": 950102,
          "name": "Budi",
          "postCode": [
            "95000"
          ]
        },
        {
          "id": 950109,
          "name": "Lam Mai",
          "postCode": [
            "95160"
          ]
        },
        {
          "id": 950111,
          "name": "Lam Phaya",
          "postCode": [
            "95160"
          ]
        },
        {
          "id": 950104,
          "name": "Lidon",
          "postCode": [
            "95160"
          ]
        },
        {
          "id": 950110,
          "name": "Na Tham",
          "postCode": [
            "95000"
          ]
        },
        {
          "id": 950114,
          "name": "Phron",
          "postCode": [
            "95160"
          ]
        },
        {
          "id": 950112,
          "name": "Po Seng",
          "postCode": [
            "95000"
          ]
        },
        {
          "id": 950101,
          "name": "Sateng",
          "postCode": [
            "95000"
          ]
        },
        {
          "id": 950116,
          "name": "Sateng Nok",
          "postCode": [
            "95000"
          ]
        },
        {
          "id": 950118,
          "name": "Tase",
          "postCode": [
            "95000"
          ]
        },
        {
          "id": 950108,
          "name": "Tha Sap",
          "postCode": [
            "95000"
          ]
        },
        {
          "id": 950106,
          "name": "Yala",
          "postCode": [
            "95000"
          ]
        },
        {
          "id": 950103,
          "name": "Yupo",
          "postCode": [
            "95000"
          ]
        }
      ]
    },
    {
      "city": "Raman",
      "cityCode": 9506,
      "provinceCode": 95,
      "subDistrict": [
        {
          "id": 950615,
          "name": "A-song",
          "postCode": [
            "95140"
          ]
        },
        {
          "id": 950611,
          "name": "Ba-ngoi",
          "postCode": [
            "95140"
          ]
        },
        {
          "id": 950610,
          "name": "Balo",
          "postCode": [
            "95140"
          ]
        },
        {
          "id": 950612,
          "name": "Buemang",
          "postCode": [
            "95140"
          ]
        },
        {
          "id": 950607,
          "name": "Cha-kwa",
          "postCode": [
            "95140"
          ]
        },
        {
          "id": 950603,
          "name": "Kalo",
          "postCode": [
            "95140"
          ]
        },
        {
          "id": 950602,
          "name": "Kalupang",
          "postCode": [
            "95140"
          ]
        },
        {
          "id": 950601,
          "name": "Kayu Boko",
          "postCode": [
            "95140"
          ]
        },
        {
          "id": 950606,
          "name": "Kero",
          "postCode": [
            "95140"
          ]
        },
        {
          "id": 950605,
          "name": "Kota Baru",
          "postCode": [
            "95140"
          ]
        },
        {
          "id": 950604,
          "name": "Koto Tuera",
          "postCode": [
            "95140"
          ]
        },
        {
          "id": 950609,
          "name": "Noen Ngam",
          "postCode": [
            "95140"
          ]
        },
        {
          "id": 950616,
          "name": "Talo Halo",
          "postCode": [
            "95140"
          ]
        },
        {
          "id": 950608,
          "name": "Tha Thong",
          "postCode": [
            "95140"
          ]
        },
        {
          "id": 950614,
          "name": "Wang Phaya",
          "postCode": [
            "95140"
          ]
        },
        {
          "id": 950613,
          "name": "Yata",
          "postCode": [
            "95140"
          ]
        }
      ]
    },
    {
      "city": "Than To",
      "cityCode": 9504,
      "provinceCode": 95,
      "subDistrict": [
        {
          "id": 950402,
          "name": "Ban Rae",
          "postCode": [
            "95150"
          ]
        },
        {
          "id": 950404,
          "name": "Khiri Khet",
          "postCode": [
            "95150"
          ]
        },
        {
          "id": 950403,
          "name": "Mae Wat",
          "postCode": [
            "95130",
            "95170"
          ]
        },
        {
          "id": 950401,
          "name": "Than To",
          "postCode": [
            "95150"
          ]
        }
      ]
    },
    {
      "city": "Yaha",
      "cityCode": 9505,
      "provinceCode": 95,
      "subDistrict": [
        {
          "id": 950507,
          "name": "Ba-ngoi Sinae",
          "postCode": [
            "95120"
          ]
        },
        {
          "id": 950504,
          "name": "Baro",
          "postCode": [
            "95120"
          ]
        },
        {
          "id": 950508,
          "name": "Ka Tong",
          "postCode": [
            "95120"
          ]
        },
        {
          "id": 950502,
          "name": "La-ae",
          "postCode": [
            "95120"
          ]
        },
        {
          "id": 950503,
          "name": "Patae",
          "postCode": [
            "95120"
          ]
        },
        {
          "id": 950506,
          "name": "Tashi",
          "postCode": [
            "95120"
          ]
        },
        {
          "id": 950501,
          "name": "Yaha",
          "postCode": [
            "95120"
          ]
        }
      ]
    },
    {
      "city": "Kham Khuean Kaeo",
      "cityCode": 3504,
      "provinceCode": 35,
      "subDistrict": [
        {
          "id": 350413,
          "name": "Dong Charoen",
          "postCode": [
            "35110"
          ]
        },
        {
          "id": 350407,
          "name": "Dong Khaen Yai",
          "postCode": [
            "35180"
          ]
        },
        {
          "id": 350412,
          "name": "Khaen Noi",
          "postCode": [
            "35180"
          ]
        },
        {
          "id": 350408,
          "name": "Ku Chan",
          "postCode": [
            "35110"
          ]
        },
        {
          "id": 350410,
          "name": "Kut Kung",
          "postCode": [
            "35110"
          ]
        },
        {
          "id": 350411,
          "name": "Lao Hai",
          "postCode": [
            "35110"
          ]
        },
        {
          "id": 350401,
          "name": "Lumphuk",
          "postCode": [
            "35110"
          ]
        },
        {
          "id": 350409,
          "name": "Na Kae",
          "postCode": [
            "35180"
          ]
        },
        {
          "id": 350406,
          "name": "Na Kham",
          "postCode": [
            "35180"
          ]
        },
        {
          "id": 350404,
          "name": "Phon Than",
          "postCode": [
            "35110"
          ]
        },
        {
          "id": 350403,
          "name": "Song Pueai",
          "postCode": [
            "35110"
          ]
        },
        {
          "id": 350405,
          "name": "Thung Mon",
          "postCode": [
            "35110"
          ]
        },
        {
          "id": 350402,
          "name": "Yo",
          "postCode": [
            "35110"
          ]
        }
      ]
    },
    {
      "city": "Kho Wang",
      "cityCode": 3507,
      "provinceCode": 35,
      "subDistrict": [
        {
          "id": 350701,
          "name": "Fa Huan",
          "postCode": [
            "35160"
          ]
        },
        {
          "id": 350704,
          "name": "Kho Wang",
          "postCode": [
            "35160"
          ]
        },
        {
          "id": 350702,
          "name": "Kut Nam Sai",
          "postCode": [
            "35160"
          ]
        },
        {
          "id": 350703,
          "name": "Nam Om",
          "postCode": [
            "35160"
          ]
        }
      ]
    },
    {
      "city": "Kut Chum",
      "cityCode": 3503,
      "provinceCode": 35,
      "subDistrict": [
        {
          "id": 350305,
          "name": "Huai Kaeng",
          "postCode": [
            "35140"
          ]
        },
        {
          "id": 350303,
          "name": "Kammaet",
          "postCode": [
            "35140"
          ]
        },
        {
          "id": 350308,
          "name": "Kham Nam Sang",
          "postCode": [
            "35140"
          ]
        },
        {
          "id": 350301,
          "name": "Kut Chum",
          "postCode": [
            "35140"
          ]
        },
        {
          "id": 350304,
          "name": "Na So",
          "postCode": [
            "35140"
          ]
        },
        {
          "id": 350302,
          "name": "Non Pueai",
          "postCode": [
            "35140"
          ]
        },
        {
          "id": 350306,
          "name": "Nong Mi",
          "postCode": [
            "35140"
          ]
        },
        {
          "id": 350309,
          "name": "Nong Nae",
          "postCode": [
            "35140"
          ]
        },
        {
          "id": 350307,
          "name": "Phon Ngam",
          "postCode": [
            "35140"
          ]
        }
      ]
    },
    {
      "city": "Loeng Nok Tha",
      "cityCode": 3508,
      "provinceCode": 35,
      "subDistrict": [
        {
          "id": 350802,
          "name": "Bung Kha",
          "postCode": [
            "35120"
          ]
        },
        {
          "id": 350805,
          "name": "Hong Saeng",
          "postCode": [
            "35120"
          ]
        },
        {
          "id": 350812,
          "name": "Khok Samran",
          "postCode": [
            "35120"
          ]
        },
        {
          "id": 350807,
          "name": "Kut Chiang Mi",
          "postCode": [
            "35120"
          ]
        },
        {
          "id": 350811,
          "name": "Kut Hae",
          "postCode": [
            "35120"
          ]
        },
        {
          "id": 350810,
          "name": "Sam Yaek",
          "postCode": [
            "35120"
          ]
        },
        {
          "id": 350806,
          "name": "Samakkhi",
          "postCode": [
            "35120"
          ]
        },
        {
          "id": 350813,
          "name": "Sang Ming",
          "postCode": [
            "35120"
          ]
        },
        {
          "id": 350803,
          "name": "Sawat",
          "postCode": [
            "35120"
          ]
        },
        {
          "id": 350814,
          "name": "Si Kaeo",
          "postCode": [
            "35120"
          ]
        }
      ]
    },
    {
      "city": "Maha Chana Chai",
      "cityCode": 3506,
      "provinceCode": 35,
      "subDistrict": [
        {
          "id": 350605,
          "name": "Bak Ruea",
          "postCode": [
            "35130"
          ]
        },
        {
          "id": 350608,
          "name": "Bueng Kae",
          "postCode": [
            "35130"
          ]
        },
        {
          "id": 350601,
          "name": "Fa Yat",
          "postCode": [
            "35130"
          ]
        },
        {
          "id": 350602,
          "name": "Hua Mueang",
          "postCode": [
            "35130"
          ]
        },
        {
          "id": 350603,
          "name": "Khu Mueang",
          "postCode": [
            "35130"
          ]
        },
        {
          "id": 350606,
          "name": "Muang",
          "postCode": [
            "35130"
          ]
        },
        {
          "id": 350607,
          "name": "Non Sai",
          "postCode": [
            "35130"
          ]
        },
        {
          "id": 350609,
          "name": "Phra Sao",
          "postCode": [
            "35130"
          ]
        },
        {
          "id": 350604,
          "name": "Phue Hi",
          "postCode": [
            "35130"
          ]
        },
        {
          "id": 350610,
          "name": "Song Yang",
          "postCode": [
            "35130"
          ]
        }
      ]
    },
    {
      "city": "Mueang Yasothon",
      "cityCode": 3501,
      "provinceCode": 35,
      "subDistrict": [
        {
          "id": 350107,
          "name": "Doet",
          "postCode": [
            "35000"
          ]
        },
        {
          "id": 350106,
          "name": "Du Thung",
          "postCode": [
            "35000"
          ]
        },
        {
          "id": 350108,
          "name": "Khan Dai Yai",
          "postCode": [
            "35000"
          ]
        },
        {
          "id": 350105,
          "name": "Kho Nuea",
          "postCode": [
            "35000"
          ]
        },
        {
          "id": 350112,
          "name": "Khueang Kham",
          "postCode": [
            "35000"
          ]
        },
        {
          "id": 350115,
          "name": "Khum Ngoen",
          "postCode": [
            "35000"
          ]
        },
        {
          "id": 350111,
          "name": "Na Samai",
          "postCode": [
            "35000"
          ]
        },
        {
          "id": 350101,
          "name": "Nai Mueang",
          "postCode": [
            "35000"
          ]
        },
        {
          "id": 350102,
          "name": "Nam Kham Yai",
          "postCode": [
            "35000"
          ]
        },
        {
          "id": 350113,
          "name": "Nong Hin",
          "postCode": [
            "35000"
          ]
        },
        {
          "id": 350114,
          "name": "Nong Khu",
          "postCode": [
            "35000"
          ]
        },
        {
          "id": 350118,
          "name": "Nong Pet",
          "postCode": [
            "35000"
          ]
        },
        {
          "id": 350117,
          "name": "Nong Ruea",
          "postCode": [
            "35000"
          ]
        },
        {
          "id": 350104,
          "name": "Samran",
          "postCode": [
            "35000"
          ]
        },
        {
          "id": 350110,
          "name": "Sing",
          "postCode": [
            "35000"
          ]
        },
        {
          "id": 350103,
          "name": "Tat Thong",
          "postCode": [
            "35000"
          ]
        },
        {
          "id": 350116,
          "name": "Thung Nang Ok",
          "postCode": [
            "35000"
          ]
        },
        {
          "id": 350109,
          "name": "Thung Tae",
          "postCode": [
            "35000"
          ]
        }
      ]
    },
    {
      "city": "Pa Tio",
      "cityCode": 3505,
      "provinceCode": 35,
      "subDistrict": [
        {
          "id": 350504,
          "name": "Chiang Pheng",
          "postCode": [
            "35150"
          ]
        },
        {
          "id": 350503,
          "name": "Khok Na Ko",
          "postCode": [
            "35150"
          ]
        },
        {
          "id": 350502,
          "name": "Krachai",
          "postCode": [
            "35150"
          ]
        },
        {
          "id": 350501,
          "name": "Pho Sai",
          "postCode": [
            "35150"
          ]
        },
        {
          "id": 350505,
          "name": "Si Than",
          "postCode": [
            "35150"
          ]
        }
      ]
    },
    {
      "city": "Sai Mun",
      "cityCode": 3502,
      "provinceCode": 35,
      "subDistrict": [
        {
          "id": 350203,
          "name": "Dong Mafai",
          "postCode": [
            "35170"
          ]
        },
        {
          "id": 350202,
          "name": "Du Lat",
          "postCode": [
            "35170"
          ]
        },
        {
          "id": 350204,
          "name": "Na Wiang",
          "postCode": [
            "35170"
          ]
        },
        {
          "id": 350205,
          "name": "Phai",
          "postCode": [
            "35170"
          ]
        },
        {
          "id": 350201,
          "name": "Sai Mun",
          "postCode": [
            "35170"
          ]
        }
      ]
    },
    {
      "city": "Thai Charoen",
      "cityCode": 3509,
      "provinceCode": 35,
      "subDistrict": [
        {
          "id": 350905,
          "name": "Kham Phai",
          "postCode": [
            "35120"
          ]
        },
        {
          "id": 350904,
          "name": "Kham Toei",
          "postCode": [
            "35120"
          ]
        },
        {
          "id": 350902,
          "name": "Nam Kham",
          "postCode": [
            "35120"
          ]
        },
        {
          "id": 350903,
          "name": "Som Pho",
          "postCode": [
            "35120"
          ]
        },
        {
          "id": 350901,
          "name": "Thai Charoen",
          "postCode": [
            "35120"
          ]
        }
      ]
    }
  ],
}
