import React from 'react'
import { redirectReplace } from '../../helpers';
import { DATA } from '../../helpers/constants/data';
import { changeLanguage } from '../../helpers/functions/language';
import { setAuthApp } from '../../helpers/functions/main';

export class AuthPage extends React.Component {
  constructor(props) {
    super(props);
    const search = props.location.search;
    const params = new URLSearchParams(search);
    let data = params.get('data');
    setAuthApp({
      isAuthRedirect: true,
    });
    this.state = {
      data,
    }
  }

  componentDidMount() {
    const {
      data,
    } = this.state;
    console.log('set language thai')
    changeLanguage(DATA.LANGUAGE.THAI);
    redirectReplace(data);
  }

  render() {
    return null
  }
}
