import { isValidResponse } from "../functions";
import { newsPromotionCommentController } from "../../apiService/apiController/newsPromotionCommentsService";
import { ModelNewsPromotionComments } from "../../models/ModelNewsPromotionComment";
import { handleTrimParams } from "../functions/data";
import _ from "lodash";

export const getNewsPromotionComments = async (params) => {
  const newsPromotionCommentsService = newsPromotionCommentController();
  let newParam = handleTrimParams({
    ...params
  });
  const res = await newsPromotionCommentsService.getNewsPromotionComments(newParam);
  if (isValidResponse(res)) {
    // let dataList = res.data.map((e) => new ModelNewsPromotionComments(e));
    let dataList = res.data;
    console.log('getNewsPromotionComments', res.data, dataList);
    return dataList;
  }
  return [];
}

export const createNewsPromotionCommentByUserId = async (params) => {
  const newsPromotionCommentsService = newsPromotionCommentController();
  const res = await newsPromotionCommentsService.createNewsPromotionCommentByUserId(params);
  if (isValidResponse(res)) {
    let data = res.data;
    console.log('createNewsPromotionCommentByUserId', res, data);
    return true;
  }
  return false;
}