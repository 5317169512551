import React from 'react'
import _ from 'lodash'
import {
  Section,
  Image,
  Grid,
  Text,
} from './../../components'
import {
  VARIABLES,
  TYPOGRAPHYS,
  ICONS,
} from './../../themes'
import {
  formatDatetime,
} from './../../helpers'
import { getLanguage } from '../../helpers/functions/language'

export class PointItem extends React.Component {
  render() {
    const {
      data,
      type,
      isVI
    } = this.props
    const isRedeem = type === 'redeem'
    return (
      <Section
        className='is-shadow'
        spacingBottom='14'
        bgColor={VARIABLES.COLORS.WHITE}
        paddingVertical='23'
        paddingLeft='18'
        paddingRight='20'
      >
        <Section spacingBottom='6'>
          <Grid gutter='6'>
            <Grid.Column>
              <Image
                widthRatio='12'
                widthRatioUnit='px'
                heightRatio='13'
                heightRatioUnit='px'
                top='-2'
                src={ICONS['ic-date-gray.svg']}
              />
            </Grid.Column>
            <Grid.Column>
              <Text
                fontStyle={TYPOGRAPHYS.FIRST.BOLD.FS_12}
                color={VARIABLES.COLORS.GRAY_3}
              >
                {isRedeem ? (
                  data.pickup_at ? formatDatetime(data.pickup_at, 'DD MMM, YYYY - H:mm') : '-'
                ) : (
                  data.create_at ? formatDatetime(data.create_at, 'DD MMM, YYYY - H:mm') : '-'
                )}
              </Text>
            </Grid.Column>
          </Grid>
        </Section>
        <Grid gutter='20' align='center'>
          <Grid.Column flex='1'>
            <Section maxWidth='328'>
              <Text
                fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_16}
                ellipsis
                title={isRedeem ? (
                  data.redeem_type === 'lucky_draw' ?
                    `Lucky Draw : ${_.get(data, 'reward_name', '')}`
                    :
                    `Redemption : ${_.get(data, 'reward_name', '')}`
                ) : (
                  _.get(data, 'f_bsproductgroupname__c', '')
                )}
              >
                {isRedeem ? (
                  data.redeem_type === 'lucky_draw' ?
                    `Lucky Draw : ${_.get(data, 'reward_name', '')}`
                    :
                    `Redemption : ${_.get(data, 'reward_name', '')}`
                ) : (
                  _.get(data, 'f_bsproductgroupname__c', '')
                )}
              </Text>
            </Section>
          </Grid.Column>
        </Grid>
        <Section
          spacingVertical='7'
          borderTopWidth='1'
          borderTopStyle='solid'
          borderTopColor={VARIABLES.COLORS.GRAY_5}
        />
        <Grid justify='space-between'>
          <Grid.Column>
            <Text
              fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_12}
              color={VARIABLES.COLORS.GRAY_3}
            >
              {_.get(data, 'ad_name', '') ? `${_.get(data, 'ad_name', '')} ${getLanguage('loyaltyPoint.tbPoints')}` : getLanguage('loyaltyPoint.tbPoints')}
            </Text>
          </Grid.Column>
          <Grid.Column>
            <Text
              fontStyle={TYPOGRAPHYS.FIRST.MEDIUM.FS_16}
              color={`${isRedeem ? VARIABLES.COLORS.ORANGE_1 : VARIABLES.COLORS.GREEN_2}`}
            >
              {`${isRedeem ?
                '-' + (_.get(data, 'points', 0) * _.get(data, 'unit', 0)).toLocaleString()
                :
                '+' + _.get(data, 'earn_point__c', 0).toLocaleString()}`}
            </Text>
          </Grid.Column>
        </Grid>
        {
          isVI &&
          <Section>
            <Text
              fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_11}
              color={VARIABLES.COLORS.GRAY_3}
            >
              {getLanguage('loyaltyPoint.numberOfRewards')}: {_.get(data, 'unit', 0).toLocaleString()}
            </Text>
          </Section>
        }
      </Section>
    )
  }
}

