export const LOCAL_STORAGE = {
  REGISTER_INFO: 'registerInfo',
  LANGUAGE_RBP_MOBILE_APP: 'LANGUAGE_RBP_MOBILE_APP',
  ALERT_VERSION_TIMEOUT_RBP_MOBILE_APP: 'ALERT_VERSION_TIMEOUT_RBP_MOBILE_APP',
  IS_IOS_OPEN_ADD_TO_HOME_SCREEN_RBP_MOBILE_APP: 'IS_IOS_OPEN_ADD_TO_HOME_SCREEN_RBP_MOBILE_APP',
  IS_OTP_AUTH_SUCCESS: 'IS_OTP_AUTH_SUCCESS',
  IS_OTP_AUTH_SUCCESS_LIST: 'IS_OTP_AUTH_SUCCESS_LIST',
  LUCKY_DRAW_SEE_ANNOUNCEMENT: 'LUCKY_DRAW_SEE_ANNOUNCEMENT',
  LAST_ACTIVE_AT: 'LAST_ACTIVE_AT',
  IS_TOAST_RE_LOGIN: 'IS_TOAST_RE_LOGIN',
  IS_TOAST_INCORRECT_MEMBER_TYPE: 'IS_TOAST_INCORRECT_MEMBER_TYPE',
}