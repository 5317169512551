import React from 'react'
import _ from 'lodash'
import {
  Section,
  Image,
  Grid,
  ShapeContainer,
  Text,
} from './../../components'
import {
  VARIABLES,
  TYPOGRAPHYS,
  ICONS,
} from './../../themes'
import {
  ROUTE_PATH,
  redirect,
} from './../../helpers'
import config from './../../config'
import { getLanguage } from '../../helpers/functions/language'

export class RewardCatItem extends React.Component {
  render() {
    const { data } = this.props
    return (
      <Section
        className='is-shadow'
        paddingVertical='10'
        spacingBottom='14'
        bgColor={VARIABLES.COLORS.WHITE}
        onClick={() => {
          redirect(`${ROUTE_PATH.REWARD_DETAIL.LINK}/${data.id}`)
        }}
      >
        <Grid
          gutter='13'
          align='center'
        >
          <Grid.Column flex='none'>
            <Section
              spacingLeft='10'
              className='reward-cat-img'
            >
              <Image
                widthRatio='66'
                widthRatioUnit='px'
                heightRatio='66'
                heightRatioUnit='px'
                borderRadius='5'
                src={`${config.imgPrefix}/${_.get(data, 'images.0')}`}
              />
              {
                data.isNew &&
                <Section className='reward-cat-img-label'>
                  {getLanguage('rewardCat.new')}
                </Section>
              }
            </Section>
          </Grid.Column>
          <Grid.Column flex='1' >
            <Section className='is-reward-catalog-content'>
              <Text
                ellipsis
                singleLine
                fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_16}
                color={VARIABLES.COLORS.BLACK}
              >
                {data.name}
              </Text>
              {data.points && <Section spacingBottom='3'>
                <Grid gutter='6'>
                  <Grid.Column>
                    <Image
                      widthRatio='11'
                      widthRatioUnit='px'
                      heightRatio='15'
                      heightRatioUnit='px'
                      src={ICONS['ic-medal-blue.svg']}
                    />
                  </Grid.Column>
                  <Grid.Column>
                    <Text
                      fontStyle={TYPOGRAPHYS.FIRST.BOLD.FS_12}
                      color={VARIABLES.COLORS.BLUE_2}
                    >
                      {data.points} {getLanguage('rewardCat.points')}
                    </Text>
                  </Grid.Column>
                </Grid>
              </Section>}
            </Section>
          </Grid.Column>
          <Grid.Column flex='none'>
            <Section
              width='48'
              height='100'
              heightUnit='%'
              justify='center'
              align='center'
            >
              <ShapeContainer
                justify='center'
                align='center'
                width='18'
                height='18'
                bgColor={VARIABLES.COLORS.BLUE_2}
                borderRadius='9'
              >
                <Image
                  widthRatio='8'
                  widthRatioUnit='px'
                  heightRatio='8'
                  heightRatioUnit='px'
                  src={ICONS['ic-arrow-more.svg']}
                  left='-1'
                />
              </ShapeContainer>
            </Section>
          </Grid.Column>
        </Grid>
      </Section >
    )
  }
}

