import React, { Fragment } from 'react'
import _ from 'lodash'
import axios from 'axios'
import {
  TransitionContainer,
  Container,
  Section,
  Image,
  NavigationBar,
} from './../../components'
import {
  VARIABLES,
  ICONS,
} from './../../themes'
import {
  ROUTE_PATH,
  redirect,
} from './../../helpers'
import {
  WarrantyItem,
} from './WarrantyItem'
import {
  Loader
} from '../Loader'
import config from './../../config'
import { LOCAL_STORAGE } from '../../helpers/constants/localStorage';
import { getLanguage } from '../../helpers/functions/language'
import { ENUM } from '../../helpers/constants/enum'
import { getSearchWarranty } from '../../helpers/services/warranty'

export class WarrantyPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      list: [],
      userId: JSON.parse(localStorage.getItem(LOCAL_STORAGE.REGISTER_INFO)) ? JSON.parse(localStorage.getItem(LOCAL_STORAGE.REGISTER_INFO)).id : '',
      isLoading: false,
    }
  }

  componentDidMount() {
    localStorage.setItem('warrantySearchType', 'warranty')
    localStorage.removeItem('warrantyList')
    this.setState({ isLoading: true }, async () => {
      try {
        const url = `${config.api.base}/api/warranty/search/`
        const options = { user_id: this.state.userId }
        console.log('options', options)
        // const response = await axios.post(url, options)
        // let data = _.get(response, 'data.data')
        let data = await getSearchWarranty(options)
        if (!_.isEmpty(data)) {
          // if (response.status === 200 && !_.isEmpty(data)) {

          // set wrt_no and project_name to detail for search
          data = data.filter(e => e.wrt_no && e.wrt_status === ENUM.WARRANTY_TYPE.READY.TEXT);
          data = data.map((item) => {
            if (!item.detail) return item;
            let length = '';
            let color = '';
            const detail = item.detail.map(ed => {
              if (ed.coil_grp === item.coil_grp) {
                if (length === '') {
                  length = ed.length;
                }
                if (color === '') {
                  color = ed.color;
                }
              }
              return {
                ...ed,
                wrt_no: item.wrt_no,
                project_name: item.project_name,
              }
            });

            return {
              ...item,
              length,
              color,
              detail,
            }
          })
          console.log('data', data)
          this.setState({ list: data }, () => {
            localStorage.setItem('warrantyList', JSON.stringify(data))
          });
        }
      } catch (_) {
      } finally {
        this.setState({ isLoading: false })
      }
    })
  }

  handleBack() {
    redirect(ROUTE_PATH.HOME.LINK)
  }

  render() {
    const { list, isLoading } = this.state
    return (
      <TransitionContainer>
        <Container
          width={VARIABLES.WEBSITE_WIDTH}
          calcMinHeight='100vh'
          bgColor={VARIABLES.COLORS.LIGHT_BLUE_2}
        >
          <NavigationBar
            shadow
            iconWidth='18'
            iconHeight='20'
            iconSrc={ICONS['ic-navigation-warranty.svg']}
            pageTitleIcon={getLanguage('warranty.title')}
            actionLeft={
              <Image
                widthRatio='24'
                widthRatioUnit='px'
                heightRatio='23'
                heightRatioUnit='px'
                src={ICONS['ic-home-black.svg']}
                onClick={this.handleBack.bind(this)}
              />
            }
            actionRight={
              <Fragment>
                <Section>
                  <Image
                    widthRatio='19'
                    widthRatioUnit='px'
                    heightRatio='17'
                    heightRatioUnit='px'
                    src={ICONS['ic-filter-black.svg']}
                    onClick={() => { redirect(ROUTE_PATH.WARRANTY_SEARCH_DATE.LINK) }}
                  />
                </Section>
                <Section paddingLeft='15'>
                  <Image
                    widthRatio='20'
                    widthRatioUnit='px'
                    heightRatio='20'
                    heightRatioUnit='px'
                    src={ICONS['ic-search-black.svg']}
                    onClick={() => { redirect(ROUTE_PATH.WARRANTY_SEARCH_NAME.LINK) }}
                  />
                </Section>
              </Fragment>
            }
          />
          <Section
            scroll
            calcHeight='100vh - 70px'
          >
            <Section
              paddingHorizontal='20'
              paddingTop='24'
              paddingBottom='40'
            >
              {list.map((data, index) => (<WarrantyItem key={index} data={data} />))}
            </Section>
          </Section>
        </Container>
        <Loader loading={isLoading} isOverlay />
      </TransitionContainer>
    )
  }
}

