import React from 'react'
import _ from 'lodash'
import axios from 'axios'
// import qs from 'qs'
import {
  TransitionContainer,
  Container,
  NavigationBar,
  Image,
  Button,
  Section,
} from './../../components'
import {
  VARIABLES,
  ICONS
} from './../../themes'
import {
  ROUTE_PATH,
  redirect,
} from './../../helpers'
import {
  DealerList,
} from './DealerList'
import MapContainer from './MapContainer'
import {
  Loader
} from '../Loader'
import { getLanguage } from '../../helpers/functions/language'

export class FindDealerSearchResultPage extends React.Component {
  constructor(props) {
    super(props)
    // const query = qs.parse(_.get(this.props, 'location.search', '').replace('?', ''))
    const type = localStorage.getItem('dealerType')
    this.state = {
      type,
      list: [],
      isLoading: false,
    }
  }

  async componentDidMount() {
    let list = JSON.parse(localStorage.getItem('dealerList'))
    if (!_.isEmpty(list)) {
      const ids = _.map(list, 'id')
      this.setState({ list })
      this.setState({ isLoading: true }, async () => {
        try {
          const url = '/api/fdreview/count/'
          const options = { ids }
          const response = await axios.post(url, options)
          const dataCount = _.get(response, 'data.data', [])
          if (response.status === 200 && !_.isEmpty(dataCount)) {
            const res = list.map((data) => {
              const sum = dataCount.find((v) => v.ad_id === data.id)
              return { 
                ...data, 
                total_rating: parseInt(_.get(sum, 'total_rating', 0)), 
                total_comment: parseInt(_.get(sum, 'total_comment', 0)) 
              }
            })
            this.setState({ list: res })
          }
        } catch (_) {
        } finally {
          this.setState({ isLoading: false })
        }
      })
    }
  }

  handleBack() {
    redirect(ROUTE_PATH.FIND_DEALER.LINK)
  }

  render() {
    const { list, type, isLoading } = this.state
    return (
      <TransitionContainer>
        <Container
          width={VARIABLES.WEBSITE_WIDTH}
          calcMinHeight='100vh'
          bgColor={VARIABLES.COLORS.WHITE}
        // bgImage={CONTENTS['dealer-map.png']}
        // bgSize='cover'
        // bgRepeat='no-repeat'
        // bgPosition='center'
        >
          <Section
            height='100'
            heightUnit='vh'
            relative
          >
            <Section
              height='100'
              heightUnit='vh'
              position='absolute'
            >
              <MapContainer list={list} />
            </Section>
            <NavigationBar
              className='is-gradient-white'
              pageTitle={`${list.length} ${getLanguage('findDealerSearch.title')}`}
              actionLeft={
                <Button
                  className='is-icon is-circle is-shadow'
                  paddingTop='-10'
                  width={34}
                  height={34}
                  borderRadius={18}
                  bgColor={VARIABLES.COLORS.WHITE}
                  onClick={() => redirect(ROUTE_PATH.FIND_DEALER.LINK)}
                >
                  <Button.Icon>
                    <Image
                      widthRatio='11'
                      widthRatioUnit='px'
                      heightRatio='21'
                      heightRatioUnit='px'
                      left='-1'
                      src={ICONS['ic-left-black.svg']}
                    />
                  </Button.Icon>
                </Button>
              }
              actionRight={
                <Button
                  className='is-icon is-circle is-shadow'
                  width={34}
                  height={34}
                  borderRadius={18}
                  bgColor={VARIABLES.COLORS.WHITE}
                  onClick={() => redirect(`${ROUTE_PATH.FIND_DEALER_TYPE.LINK}/${type}`)}
                >
                  <Button.Icon>
                    <Image
                      top='-1'
                      widthRatio='16'
                      widthRatioUnit='px'
                      heightRatio='16'
                      heightRatioUnit='px'
                      src={ICONS['ic-search-black.svg']}
                    />
                  </Button.Icon>
                </Button>
              }
            />
          </Section>
          {/* <Button
            className='is-icon is-gps-dealer-result is-shadow'
            width={34}
            height={34}
            borderRadius={18}
            bgColor={VARIABLES.COLORS.WHITE}
          >
            <Button.Icon>
              <Image
                widthRatio='22'
                widthRatioUnit='px'
                heightRatio='22'
                heightRatioUnit='px'
                src={ICONS['ic-gps-black.svg']}
              />
            </Button.Icon>
          </Button> */}
          <DealerList list={list} type={type} />
        </Container>
        <Loader
          loading={isLoading}
          isOverlay
        />
      </TransitionContainer>
    )
  }
}
