import {
  injectGlobal
} from 'styled-components'
import {
  default as VARIABLES
} from './../../../themes/styles/bases/variables'
import {
  default as TYPOGRAPHYS
} from './../../../themes/styles/bases/typographys'
// import {
//   default as MIXINS
// } from './../../../themes/styles/helpers/mixins'

// Sample using image in style
// url(${require('./../../../themes/images/icons/icon-sample.svg')});

injectGlobal`
  .field-base {
    /* Parent
    ------------------------------- */
    /* Childrens
    ------------------------------- */
    /* Modifiers
    ------------------------------- */
    &.is-otp {
      position: relative;
      width: 40px;
      height: 57px;
      border-bottom: 3px solid ${VARIABLES.COLORS.GRAY_3};

      .field-container-base,
      .field-group-base,
      .field-group-inner-base {
        width: 100%;
        height: 100%;
      }

      .field-value-base {
        transform: translateX(-50%);
        position: absolute;
        z-index: ${VARIABLES.Z_INDEXS.LV_1};
        top: 0;
        left: 50%;
      }

      &.is-error {
        border-bottom-color: ${VARIABLES.COLORS.ORANGE_1};

        .field-value-base {
          color: ${VARIABLES.COLORS.ORANGE_1};
        }
      }
    }

    &.is-email {
      .field-icon-base {
        top: 0;
        bottom: auto;
        height: 18px;
      }
    }

    &.is-phone {
      .field-icon-base {
        top: -3px;
        bottom: auto;
        height: 24px;
      }
    }

    &.is-password {
      .field-icon-base {
        top: -9px;
        bottom: auto;
        height: 28px;
      }
      &.is-register {
        position: relative;
        .field-icon-base {
          top: 0px;
        }
      }
    }

    &.is-phone-id {
      .field-icon-base {
        pointer-events: auto;
        top: 0;
        bottom: auto;
        height: 18px;
      }
    }

    &.is-float-label {
      position: relative;

      .field-label-base {
        position: absolute;
        z-index: ${VARIABLES.Z_INDEXS.LV_1};
        bottom: 100%;
        left: 10px;
        padding-right: 10px;
        padding-left: 10px;
        margin-bottom: -7px;
        background-color: ${VARIABLES.COLORS.WHITE};
      }

      &.is-estimator {
        .field-label-base {
          left: 20px;
        }

        .field-value-base {
          line-height: 46px;
        }
      }
    }

    &.is-field-float-placeholder {
      &.is-calendar {
        .field-value-base {
          position: relative;
          padding-left: 10px;

          &[convertValue=''] {
            &:before {
              content: attr(placeholder);
              color: ${VARIABLES.COLORS.GRAY_5};
            }
          }

          &:before {
            ${TYPOGRAPHYS.FIRST.REGULAR.FS_15};
            content: attr(convertValue);
            position: absolute;
            top: -2px;
            left: 11px;
            display: block;
            padding: 0 10px 0 0;
            color: ${VARIABLES.COLORS.BLACK};
          }
        }

        .field-icon-base {
          bottom: 7px;
        }
      }

      &.is-calendar-bg-white {
        .field-value-base {
          &:before {
            background-color: ${VARIABLES.COLORS.WHITE};
          }
        }
      }

      &.is-calendar-bg-gray {
        .field-value-base {
          &:before {
            background-color: ${VARIABLES.COLORS.GRAY_14};
          }
        }
      }
    }

    &.is-readonly {
      pointer-events: none;
    }

    /* Media queries
    ------------------------------- */
  }

  .select-flag {
    ${TYPOGRAPHYS.FIRST.REGULAR.FS_15};
    color: ${VARIABLES.COLORS.BLUE_2};

    &.is-gray {
      color: ${VARIABLES.COLORS.GRAY_3};
    }
  }

  .is-mobile-safari {
    .field-base {
      .field-value-base {
        top: -10px;
      }
    }
  }
`
