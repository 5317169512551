import React from 'react'
import _ from 'lodash'
import ReactHtmlParser from 'react-html-parser';
import {
  TransitionContainer,
  Container,
  SwiperCarousel,
  Image,
  Section,
  Text,
  Grid,
  Button,
} from '../../components'
import {
  VARIABLES,
  TYPOGRAPHYS,
  ICONS,
} from '../../themes'
import {
  ROUTE_PATH,
  redirect,
} from './../../helpers'
import config from './../../config'
import { getLanguage } from '../../helpers/functions/language';
import { onOpenAlert } from '../../helpers/functions/main';
import { DATA } from '../../helpers/constants/data';

export class RewardDetailPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      id: (this.props.match.params && this.props.match.params.id) ? this.props.match.params.id : '',
      data: {
        id: '',
        name: '',
        category_id: '',
        category_name: '',
        description: '',
        points: 0,
        images: [],
        create_at: '',
        status: '',
      },
    }
  }

  componentDidMount() {
    const data = JSON.parse(localStorage.getItem('rewardCatalogList'))
    if (_.isEmpty(data)) return
    const result = data.find(item => item.id === +this.state.id)
    if (result) {
      this.setState({ data: result })
    }
  }

  onShareLink() {
    if (navigator.share) {
      navigator.share({
        url: window.location.href
      })
    }
    else{
      onOpenAlert({
        type: DATA.ALERT_TYPE.ERROR,
        title: getLanguage('message.fail'),
        message: getLanguage('message.browserCantShare'),
      });
    }
  }

  render() {
    const { data } = this.state
    return (
      <TransitionContainer>
        <Container
          width={VARIABLES.WEBSITE_WIDTH}
          calcMinHeight='100vh'
          bgColor={VARIABLES.COLORS.WHITE}
        >
          <Section relative>
            <Button
              className='is-icon is-content-detail-back'
              width={36}
              height={36}
              borderRadius={18}
              bgColor={VARIABLES.COLORS.WHITE}
              onClick={() => {
                // redirect(`${ROUTE_PATH.REWARD_CAT.LINK}/${data.category_id}`);
                redirect(`${ROUTE_PATH.REWARD.LINK}`);
              }}
            >
              <Button.Icon>
                <Image
                  widthRatio='11'
                  widthRatioUnit='px'
                  heightRatio='21'
                  heightRatioUnit='px'
                  left='-1'
                  src={ICONS['ic-left-black.svg']}
                />
              </Button.Icon>
            </Button>
            {/* <Section
              nonFluid
              className='is-social-buttons'
            >
              <Grid gutter='7'>
                <Grid.Column>
                  <Button
                    width={38}
                    height={38}
                    borderRadius={19}
                    bgColor={VARIABLES.COLORS.BLUE_2}
                    onClick={this.onShareLink.bind(this)}
                  >
                    <Button.Icon>
                      <Image
                        widthRatio='17'
                        widthRatioUnit='px'
                        heightRatio='19'
                        heightRatioUnit='px'
                        src={ICONS['ic-share.svg']}
                      />
                    </Button.Icon>
                  </Button>
                </Grid.Column>
              </Grid>
            </Section> */}
            {!_.isEmpty(data.images) ? <SwiperCarousel className='is-banner-news-and-promotions'>
              {data.images.map((image, index) => (
                <SwiperCarousel.Item key={index}>
                  <Image
                    heightRatio='93'
                    src={`${config.imgPrefix}/${image}`}
                  />
                </SwiperCarousel.Item>
              ))}
            </SwiperCarousel> : <SwiperCarousel className='is-banner-news-and-promotions is-empty' />}
          </Section>
          <Section
            paddingTop='35'
            paddingBottom='30'
            paddingHorizontal='20'
          >
            <Section spacingBottom='7'>
              <Text
                fontStyle={TYPOGRAPHYS.FIRST.BOLD.FS_22}
                color={VARIABLES.COLORS.BLACK}
              >
                {data.name}
              </Text>
            </Section>
            {data.points && <Section spacingBottom='32'>
              <Grid gutter='6'>
                <Grid.Column>
                  <Image
                    widthRatio='15'
                    widthRatioUnit='px'
                    heightRatio='20'
                    heightRatioUnit='px'
                    top='-2'
                    src={ICONS['ic-medal-blue.svg']}
                  />
                </Grid.Column>
                <Grid.Column>
                  <Text
                    fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_14}
                    color={VARIABLES.COLORS.BLUE_2}
                  >
                    {data.points} {getLanguage('rewardDetail.points')}
                  </Text>
                </Grid.Column>
              </Grid>
            </Section>}
            <Section
              className='text-editor-content'
              spacingBottom='15'
            >
              {ReactHtmlParser(data.description)}
            </Section>
          </Section>
        </Container>
      </TransitionContainer>
    )
  }
}
