import React from 'react'
import _ from 'lodash'
import axios from 'axios'
// import qs from 'qs'
import {
  TransitionContainer,
  Container,
  NavigationBar,
  Section,
  Image,
} from './../../components'
import {
  VARIABLES,
  ICONS,
} from './../../themes'
import {
  ROUTE_PATH,
  redirect,
  getRouteParams,
} from './../../helpers'
import {
  DealerDetailCard
} from './DealerDetailCard'
import MapContainer from '../FindDealerSearchResultPage/MapContainer'
import {
  Loader
} from '../Loader'
import { getUserAuth } from '../../helpers/functions/auth'
import { ENUM } from '../../helpers/constants/enum'


export class FindDealerListPage extends React.Component {
  constructor(props) {
    super(props)
    // const query = qs.parse(_.get(this.props, 'location.search', '').replace('?', ''))
    const type = localStorage.getItem('dealerType');
    const userAuth = getUserAuth() || {};
    const dataId = parseInt(getRouteParams(props, 'id'));
    console.log('dataId', dataId);
    this.state = {
      type,
      list: [],
      isLoading: false,
      userLatitude: 0,
      userLongitude: 0,
      dataId,
      isMalay: userAuth.countries.toLowerCase() === ENUM.COUNTRY.MALAYSIA.toLowerCase(),
    }
  }

  async componentDidMount() {
    const {
      dataId,
    } = this.state;

    let list = JSON.parse(localStorage.getItem('dealerList'))
    if (!_.isEmpty(list)) {
      const ids = _.map(list.filter(e => e.id === dataId), 'id');
      if (ids[0]) {
        this.setState({ list })
        this.setState({ isLoading: true }, async () => {
          try {
            const url = '/api/fdreview/count/'
            const options = { ids }
            const response = await axios.post(url, options)
            const dataCount = _.get(response, 'data.data', [])
            if (response.status === 200 && !_.isEmpty(dataCount)) {
              const res = list.map((data) => {
                const sum = dataCount.find((v) => v.ad_id === data.id)
                return { ...data, total_rating: parseInt(_.get(sum, 'total_rating', 0)), total_comment: parseInt(_.get(sum, 'total_comment', 0)) }
              })
              this.setState({ list: res })
            }
          } catch (_) {
          } finally {
            this.setState({ isLoading: false })
          }
        })

        if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition(this.setPosition)
        }
      }
      else {
        this.handleBack();
      }
    }
  }

  setPosition = (position) => {
    this.setState({
      userLatitude: position.coords.latitude,
      userLongitude: position.coords.longitude,
    })
  }

  handleBack() {
    redirect(ROUTE_PATH.FIND_DEALER.LINK);
  }

  render() {
    const {
      list,
      type,
      isLoading,
      userLatitude,
      userLongitude,
      isMalay,
      dataId,
    } = this.state
    return (
      <TransitionContainer>
        <Container
          width={VARIABLES.WEBSITE_WIDTH}
          calcMinHeight='100vh'
          bgColor={VARIABLES.COLORS.LIGHT_BLUE_2}
        >
          <NavigationBar
            pageTitle='Dealer List'
            actionLeft={
              <Image
                widthRatio='11'
                widthRatioUnit='px'
                heightRatio='21'
                heightRatioUnit='px'
                src={ICONS['ic-left-black.svg']}
                onClick={this.handleBack.bind(this)}
              />
            }
            actionRight={
              <Image
                widthRatio='21'
                widthRatioUnit='px'
                heightRatio='21'
                heightRatioUnit='px'
                src={ICONS['ic-search-black.svg']}
                onClick={() => redirect(`${ROUTE_PATH.FIND_DEALER_TYPE.LINK}/${type}`)}
              />
            }
          />
          <Section
            height='300'
            relative
          >
            {/*   <Image
              heightRatio='300'
              heightRatioUnit='px'
              src={CONTENTS['dealer-map.png']}
            />
            <Button
              className='is-icon is-gps-dealer-list is-shadow'
              width={34}
              height={34}
              borderRadius={18}
              bgColor={VARIABLES.COLORS.WHITE}
            >
              <Button.Icon>
                <Image
                  widthRatio='22'
                  widthRatioUnit='px'
                  heightRatio='22'
                  heightRatioUnit='px'
                  src={ICONS['ic-gps-black.svg']}
                />
              </Button.Icon>
            </Button> */}
            <MapContainer className='is-map-dealer-list'
              list={list}
              dataId={dataId}
            />
          </Section>
          <Section
            spacingVertical={25}
            spacingBottom={20}
            paddingHorizontal={20}
          >
            {!_.isEmpty(list) && list.map((data, index) => {
              if (data.id === dataId) {
                return (
                  <DealerDetailCard
                    data={data}
                    key={index}
                    index={index + 1}
                    userLatitude={userLatitude}
                    userLongitude={userLongitude}
                    isMalay={isMalay}
                  />
                )
              }
              return undefined;
            })}
          </Section>
        </Container>
        <Loader
          loading={isLoading}
          isOverlay
        />
      </TransitionContainer>
    )
  }
}
