import { DATA } from "../constants/data"
import { setLocalStorage, getLocalStorage } from "./localStorage";
import { LOCAL_STORAGE } from "../constants/localStorage";
import { lang_th } from "../constants/languageTH";
import { lang_en } from "../constants/languageEN";
import { lang_vi } from "../constants/languageVI";
import { lang_in } from "../constants/languageIN";
import { lang_ml } from "../constants/languageML";
import { lang_ch } from "../constants/languageCH";
import { lang_la } from "../constants/languageLA";
import _ from 'lodash';

export const getLanguageLocalStorage = () => {
  return getLocalStorage(LOCAL_STORAGE.LANGUAGE_RBP_MOBILE_APP);
}

export const changeLanguage = (type = DATA.LANGUAGE.THAI) => {
  let newType = type ? type : DATA.LANGUAGE.THAI;
  language = languageCountry[newType];
  setLocalStorage(LOCAL_STORAGE.LANGUAGE_RBP_MOBILE_APP, newType);
}

export const getLanguage = (target) => {
  return _.get(language, target , '');
}

const languageCountry = {
  [DATA.LANGUAGE.THAI]: lang_th,
  [DATA.LANGUAGE.ENGLISH]: lang_en,
  [DATA.LANGUAGE.VIETNAM]: lang_vi,
  [DATA.LANGUAGE.INDONESIA]: lang_in,
  [DATA.LANGUAGE.MALAYSIA]: lang_ml,
  [DATA.LANGUAGE.CHINA]: lang_ch,
  [DATA.LANGUAGE.LAOS]: lang_la,
}

export let language = languageCountry[DATA.LANGUAGE.ENGLISH];

