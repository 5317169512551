import React from 'react'
import _ from 'lodash'
import {
  Section,
  Image,
  Grid,
  ShapeContainer,
  Text,
  Button,
  Modal,
} from './../../components'
import {
  VARIABLES,
  TYPOGRAPHYS,
  ICONS
} from './../../themes'
import {
  ROUTE_PATH,
  redirect,
  formatDatetime,
  redirectData,
} from './../../helpers'
import { getLanguage } from '../../helpers/functions/language'
import { displayWithComma } from '../../helpers/functions/display'

export class WarrantyItem extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      showModal: false,
    }
  }

  onCloseModal(event) {
    event.stopPropagation()
    this.setState({ showModal: false })
  }

  onOpenModal(event) {
    event.stopPropagation()
    this.setState({ showModal: true })
  }

  render() {
    const { showModal } = this.state
    const { data } = this.props
    return (
      <Section
        className='is-shadow'
        paddingVertical='18'
        spacingBottom='14'
        bgColor={VARIABLES.COLORS.WHITE}
        onClick={() => {
          // redirect(`${ROUTE_PATH.WARRANTY_PRODUCT.LINK}/${data.wrt_no}`)
          redirectData(`${ROUTE_PATH.WARRANTY_PRODUCT.LINK}/${data.wrt_no}`, {
            detailList: data.detail,
          })
        }}
      >
        <Grid gutter='13'>
          <Grid.Column flex='1'>
            <Section className='is-warranty-content' spacingLeft='13'>
              <Section>
                <Text
                  ellipsis
                  singleLine
                  fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_16}
                  color={VARIABLES.COLORS.BLACK}
                >
                  {_.get(data, 'coil_grp')}
                </Text>
              </Section>
              <Section>
                <Grid gutter='6'>
                  <Grid.Column>
                    <Section paddingRight='3'>
                      <Text
                        fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_12}
                        color={VARIABLES.COLORS.GRAY_3}
                      >
                        ID: {_.get(data, 'wrt_no')}
                      </Text>
                    </Section>
                  </Grid.Column>
                  <Grid.Column>
                    <Grid gutter='4'>
                      <Grid.Column>
                        <Image
                          widthRatio='10'
                          widthRatioUnit='px'
                          heightRatio='11'
                          heightRatioUnit='px'
                          top='-2'
                          src={ICONS['ic-date-gray.svg']}
                        />
                      </Grid.Column>
                      <Grid.Column>
                        <Text
                          fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_11}
                          color={VARIABLES.COLORS.GRAY_3}
                        >
                          {formatDatetime(data.created_date, 'DD MMM, YYYY - H:mm')}
                        </Text>
                      </Grid.Column>
                    </Grid>
                  </Grid.Column>
                </Grid>
              </Section>
              <Section spacingBottom='13'>
                <Text
                  fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_12}
                  color={VARIABLES.COLORS.GRAY_3}
                >
                  {/* AD : Khanittha Hongthong */}
                  AD: {_.get(data, 'ad_name')}
                </Text>
              </Section>
              <Section>
                <Button
                  borderRadius='5'
                  bgColor={VARIABLES.COLORS.BLUE_2}
                  paddingHorizontal='12'
                  paddingVertical='3'
                  onClick={this.onOpenModal.bind(this)}
                >
                  <Text
                    fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_10}
                    color={VARIABLES.COLORS.WHITE}
                  >
                    {getLanguage('field.buttonMoreDetail')}
                  </Text>
                </Button>
              </Section>
            </Section>
          </Grid.Column>
          <Grid.Column flex='none'>
            <Section
              width='48'
              height='100'
              heightUnit='%'
              justify='center'
              align='center'
            >
              <ShapeContainer
                justify='center'
                align='center'
                width='18'
                height='18'
                bgColor={VARIABLES.COLORS.BLUE_2}
                borderRadius='9'
              >
                <Image
                  widthRatio='8'
                  widthRatioUnit='px'
                  heightRatio='8'
                  heightRatioUnit='px'
                  src={ICONS['ic-arrow-more.svg']}
                  left='-1'
                />
              </ShapeContainer>
            </Section>
          </Grid.Column>
        </Grid>
        <Modal
          open={showModal}
          width={400}
          overlayColor={VARIABLES.COLORS.OVERLAY_3}
          spacing={VARIABLES.MODAL.CONTAINER.C_1.SPACING}
          transition={VARIABLES.TRANSITIONS.DEFAULT}
          transformStart={VARIABLES.MODAL.CONTAINER.C_1.TRANSFORM_START}
          transformEnd={VARIABLES.MODAL.CONTAINER.C_1.TRANSFORM_END}
          onClickClose={this.onCloseModal.bind(this)}
        >
          <Modal.Section
            paddingTop='24'
            borderTopRightRadius={VARIABLES.MODAL.HEADER.H_1.BORDER_TOP_RIGHT_RADIUS}
            borderTopLeftRadius={VARIABLES.MODAL.HEADER.H_1.BORDER_TOP_LEFT_RADIUS}
            paddingBottom={VARIABLES.MODAL.BODY.B_1.PADDING_BOTTOM}
            paddingHorizontal='20'
          >
            <Section
              paddingBottom='5'
            >
              <Text
                fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_14}
                color={VARIABLES.COLORS.GRAY_2}
              >
                {getLanguage('modal.warrantyDetail.projectName')}
              </Text>
            </Section>
            <Section
              spacingBottom='10'
              paddingBottom='15'
              borderBottomColor={VARIABLES.COLORS.GRAY_1}
              borderBottomWidth='1'
              borderBottomStyle='solid'
            >
              <Text
                fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_16}
                color={VARIABLES.COLORS.BLACK}
              >
                {_.get(data, 'project_name', '-')}
              </Text>
            </Section>
            <Section
              paddingBottom='5'
            >
              <Text
                fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_14}
                color={VARIABLES.COLORS.GRAY_2}
              >
                {getLanguage('modal.warrantyDetail.address')}
              </Text>
            </Section>
            <Section
              spacingBottom='10'
              paddingBottom='15'
              borderBottomColor={VARIABLES.COLORS.GRAY_1}
              borderBottomWidth='1'
              borderBottomStyle='solid'
            >
              <Text
                fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_16}
                color={VARIABLES.COLORS.BLACK}
              >
                {_.get(data, 'project_addr', '-')}
              </Text>
            </Section>
            <Section
              paddingBottom='5'
            >
              <Text
                fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_14}
                color={VARIABLES.COLORS.GRAY_2}
              >
                {getLanguage('modal.warrantyDetail.quantity')}
              </Text>
            </Section>
            <Section
              spacingBottom='10'
              paddingBottom='15'
              borderBottomColor={VARIABLES.COLORS.GRAY_1}
              borderBottomWidth='1'
              borderBottomStyle='solid'
            >
              <Text
                fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_16}
                color={VARIABLES.COLORS.BLACK}
              >
                {data.length ? displayWithComma(data.length) : ''}
              </Text>
            </Section>
            <Section
              paddingBottom='5'
            >
              <Text
                fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_14}
                color={VARIABLES.COLORS.GRAY_2}
              >
                {getLanguage('modal.warrantyDetail.color')}
              </Text>
            </Section>
            <Section
              spacingBottom='10'
              paddingBottom='15'
              borderBottomColor={VARIABLES.COLORS.GRAY_1}
              borderBottomWidth='1'
              borderBottomStyle='solid'
            >
              <Text
                fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_16}
                color={VARIABLES.COLORS.BLACK}
              >
                {_.get(data, 'color', '-')}
              </Text>
            </Section>
          </Modal.Section>
          <Modal.Section
            paddingBottom={VARIABLES.MODAL.FOOTER.F_2.PADDING_BOTTOM}
            paddingHorizontal={VARIABLES.MODAL.FOOTER.F_2.PADDING_HORIZONTAL}
            borderBottomRightRadius={VARIABLES.MODAL.FOOTER.F_2.BORDER_BOTTOM_RIGHT_RADIUS}
            borderBottomLeftRadius={VARIABLES.MODAL.FOOTER.F_2.BORDER_BOTTOM_LEFT_RADIUS}
          >
            <Section
              justify='center'
            >
              <Button
                width='200'
                bgColor={VARIABLES.COLORS.TRANSPARENT}
                borderWidth='1'
                borderStyle='solid'
                borderColor={VARIABLES.COLORS.BLUE_2}
                shadow
                height={VARIABLES.BUTTON.HEIGHTS.H_35}
                borderRadius={VARIABLES.BUTTON.BORDER_RADIUSES.BR_10}
                onClick={this.onCloseModal.bind(this)}
              >
                <Text
                  fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_16}
                  color={VARIABLES.COLORS.BLUE_2}
                >
                  {getLanguage('field.buttonClose')}
                </Text>
              </Button>
            </Section>
          </Modal.Section>
        </Modal>
      </Section>
    )
  }
}

