import React from 'react'
import ClassNames from 'classnames'
import PropTypes from 'prop-types'
import 'react-tabs/style/react-tabs.css'
import {
  ReactTabsControlWrapper
} from './styled'
// import {
// } from './../../../components'
// import {
//   default as VARIABLES
// } from './../../../themes/styles/bases/variables'
// import {
//   default as TYPOGRAPHYS
// } from './../../../themes/styles/bases/typographys'
// import {
//   default as UTILITIES
// } from './../../../themes/styles/helpers/utilities'

/**
 * ReactTabsControl description:
 * - ReactTabs (https://github.com/reactjs/react-tabs)
 */

export class ReactTabsControl extends React.PureComponent {
  render () {
    const {
      className,
      children,
      // modifierName
    } = this.props

    // props for css classes
    const classes = ClassNames(
      'react-tabs-control',
      // {'is-modifier-name': modifierName},
      className
    )

    return (
      <ReactTabsControlWrapper
        className={classes}
      >
        {children}
      </ReactTabsControlWrapper>
    )
  }

  static defaultProps = {
    // * If you set new default props, you should create propsType for correct styleguide document.
  }

  static propTypes = { // TYPE > node, string, number, bool, array, object, symbol, func
    /**
    * Additional classes
    */
    className: PropTypes.string
  }
}
