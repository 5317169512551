import React from 'react'
import _ from 'lodash'
import axios from 'axios'
import qs from 'qs'
import {
  TransitionContainer,
  Container,
  Section,
  Image,
  Field,
  NavigationBar,
  Text,
} from './../../components'
import {
  VARIABLES,
  TYPOGRAPHYS,
  ICONS,
} from './../../themes'
import {
  ROUTE_PATH,
  redirect,
} from './../../helpers'
import {
  Loader
} from '../Loader'
import config from '../../config'
import { getLanguage } from '../../helpers/functions/language'
import { getUserAuth } from '../../helpers/functions/auth'
import { getAuthorizedDealer } from '../../helpers/services/authorizeDealer'

export class OrderInquirySearchPage extends React.Component {
  constructor(props) {
    super(props)
    const query = qs.parse(_.get(this.props, 'location.search', '').replace('?', ''));
    const userAuth = getUserAuth();
    this.state = {
      //  dealerIdSelected: '',
      dealerNameSelected: '',
      dealerTypeSelected: _.get(query, 'dealerType', ''),
      dealerList: [],
      dealerListSelected: [],
      isLoading: false,
      toggleSearchDealer: false,
      userAuth
    }
  }

  componentDidMount() {
    this.setState({ isLoading: true }, async () => {
      try {
        const data = await getAuthorizedDealer();
        this.setState({ dealerList: data })
      } catch (_) {
      } finally {
        this.setState({ isLoading: false })
      }
    })
  }

  handleBack() {
    redirect(`${ROUTE_PATH.ORDER_INQUIRY.LINK}?dealerType=${this.state.dealerTypeSelected}`)
  }

  onChangeDealerSelected(event) {
    const value = event.target.value.toLowerCase()
    this.setState({ dealerNameSelected: value })
  }

  onChangeDealerType(event) {
    const dealerType = event.target.value
    this.setState({ dealerTypeSelected: dealerType }, () => {
      redirect(`${ROUTE_PATH.ORDER_INQUIRY.LINK}?dealerType=${dealerType}`)
    })
  }

  toggleSearchDealer() {
    this.setState({ toggleSearchDealer: true })
  }

  onSelectDealer(dealer) {
    localStorage.setItem('orderInquiryDealerSelected', JSON.stringify(dealer))
    redirect(`${ROUTE_PATH.ORDER_INQUIRY.LINK}?dealerType=${this.state.dealerTypeSelected}&dealerId=${dealer.id}&dealerRef=${dealer.ad_ref_id}`)
  }

  clearDealerName() {
    this.setState({ dealerNameSelected: '' })
  }

  render() {
    const {
      dealerList,
      dealerNameSelected,
      dealerTypeSelected,
      toggleSearchDealer,
      isLoading,
      userAuth
    } = this.state
    const dealerListSelected = dealerList.filter(dealer => {
      return (dealer.type || '').toLowerCase() === dealerTypeSelected.toLowerCase() &&
        (!dealerNameSelected || dealer.acc_name.toLowerCase().search(dealerNameSelected) >= 0) &&
        dealer.country__c === userAuth.countries
    })
    return (
      <TransitionContainer>
        <Container
          width={VARIABLES.WEBSITE_WIDTH}
          calcMinHeight='100vh'
          bgColor={VARIABLES.COLORS.LIGHT_BLUE_2}
        >
          <NavigationBar
            pageTitle={getLanguage('orderInquirySearch.title')}
            actionLeft={
              <Image
                widthRatio='11'
                widthRatioUnit='px'
                heightRatio='21'
                heightRatioUnit='px'
                src={ICONS['ic-left-black.svg']}
                onClick={this.handleBack.bind(this)}
              />
            }
          />
          <Section
            scroll
            calcHeight='100vh - 70px'
          >
            <Section
              paddingHorizontal='20'
              paddingTop='24'
              paddingBottom='100'
            >
              <Section spacingBottom='9'>
                <Field
                  type='select'
                  fluid
                  height='40'
                  paddingRight='10'
                  paddingLeft='50'
                  bgColor={VARIABLES.COLORS.TRANSPARENT}
                  borderWidth='1'
                  borderStyle='solid'
                  borderColor={VARIABLES.COLORS.BLUE_2}
                  borderRadius='20'
                  fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_14}
                  fontColor={dealerTypeSelected ? VARIABLES.COLORS.BLUE_2 : VARIABLES.COLORS.LIGHT_BLUE_3}
                  id='category-selector'
                  name='category-selector'
                  value={dealerTypeSelected}
                  fieldIcon={<img src={ICONS['ic-arrow-down-blue.svg']} alt='Icon' />}
                  fieldIconWidth='41'
                  fieldIconSpacingRight='10'
                  fieldIconLeft={dealerTypeSelected ? <img src={ICONS['ic-list-blue.svg']} alt='Icon' /> : <img src={ICONS['ic-list-light-blue.svg']} alt='Icon' />}
                  fieldIconLeftSpacingLeft='10'
                  onChange={this.onChangeDealerType.bind(this)}
                >
                  <option value="" disabled selected hidden>{getLanguage('field.selectCategoryPlaceholder')}</option>
                  <option value="BAD">{getLanguage('orderInquirySearch.category.bad')}</option>
                  <option value="OR">{getLanguage('orderInquirySearch.category.or')}</option>
                </Field>
              </Section>
              <Section spacingBottom='23'>
                <Field
                  autoFocus
                  fluid
                  height='40'
                  paddingRight='10'
                  paddingLeft='50'
                  bgColor={VARIABLES.COLORS.TRANSPARENT}
                  borderWidth='1'
                  borderStyle='solid'
                  borderColor={VARIABLES.COLORS.BLUE_2}
                  borderRadius='20'
                  fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_14}
                  fontColor={VARIABLES.COLORS.BLUE_2}
                  id='dealer-selector'
                  name='dealer-selector'
                  placeholder={getLanguage('orderInquirySearch.selectDealer')}
                  placeholderColor={VARIABLES.COLORS.LIGHT_BLUE_3}
                  value={dealerNameSelected}
                  onClickFieldIcon={this.clearDealerName.bind(this)}
                  fieldIcon={dealerNameSelected ? <img src={ICONS['ic-close-blue-circle.svg']} alt='Icon' /> : <img src={ICONS['ic-search-light-blue.svg']} alt='Icon' />}
                  fieldIconWidth='41'
                  fieldIconSpacingRight='10'
                  fieldIconLeft={dealerNameSelected ? <img src={ICONS['ic-user-card-blue.svg']} alt='Icon' /> : <img src={ICONS['ic-user-card-light-blue.svg']} alt='Icon' />}
                  fieldIconLeftSpacingLeft='10'
                  onChange={this.onChangeDealerSelected.bind(this)}
                  onFocus={this.toggleSearchDealer.bind(this)}
                />
              </Section>
              <Loader
                loading={isLoading}
                paddingTop={30}
                widthRatio={50}
                heightRatio={50}
              />
              {toggleSearchDealer && !isLoading && !_.isEmpty(dealerListSelected) &&
                <Section spacingBottom='13'>
                  <Section spacingBottom='17'>
                    <Text
                      fontStyle={TYPOGRAPHYS.FIRST.MEDIUM.FS_14}
                    >
                      {getLanguage('orderInquirySearch.suggestions')}
                    </Text>
                  </Section>
                  {dealerListSelected.map((dealer, index) => (
                    <Section
                      key={index}
                      paddingVertical='12'
                      borderBottomWidth='1'
                      borderBottomStyle='solid'
                      borderBottomColor={VARIABLES.COLORS.GRAY_5}
                      onClick={this.onSelectDealer.bind(this, dealer)}
                    >
                      <Text
                        fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_14}
                        color={VARIABLES.COLORS.GRAY_3}
                      >
                        {dealer.acc_name}
                      </Text>
                    </Section>))}
                </Section>
              }
            </Section>
          </Section>
        </Container>
      </TransitionContainer>
    )
  }
}

