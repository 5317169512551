import React from 'react';
import { storeDispatch, storeGetState } from "../../store"
import { mainCreators, MAIN_REDUX_STATE_KEY } from "../../actions/mainAction"
import { DATA } from "../constants/data";
import { ICONS } from "../../themes";
import { getLanguage } from "./language";

export const setAuthApp = (data) => {
  storeDispatch(mainCreators.setMainRedux(MAIN_REDUX_STATE_KEY.AUTH_APP, data));
}

export const setLineData = (data) => {
  storeDispatch(mainCreators.setMainRedux(MAIN_REDUX_STATE_KEY.LINE_DATA, data));
}

export const getLineData = () => {
  return storeGetState().mainRedux.lineData;
}

export const isShowLoading = (isLoading) => {
  storeDispatch(mainCreators.setMainRedux(MAIN_REDUX_STATE_KEY.IS_SHOW_LOADING, isLoading));
}

export const onOpenModal = (data = {}) => {
  const params = {
    ...data,
    openModalType: data.type,
    onClickSubmit: () => {
      onCloseModal();
      if (data.onClickSubmit) {
        data.onClickSubmit();
      }
    }
  }
  switch (data.type) {
    case DATA.MODAL_TYPE.OTP_SEND:
      params.openModalType = DATA.MODAL_TYPE.NOTICE;
      params.iconWidth = '58';
      params.iconHeight = '58';
      params.iconSrc = ICONS['ic-send.svg'];
      params.title = getLanguage('modal.forgotPassword.titleSendOtp');
      params.description = getLanguage('modal.forgotPassword.messageCheckNotification');
      params.buttonSubmitName = getLanguage('field.buttonOK');
      break;
    case DATA.MODAL_TYPE.FORGET_PASSWORD_SUCCESS:
      params.openModalType = DATA.MODAL_TYPE.NOTICE;
      params.iconWidth = '61';
      params.iconHeight = '61';
      params.iconSrc = ICONS['ic-success.svg'];
      params.title = getLanguage('modal.resetPassword.title');
      params.description = (
        <React.Fragment>
          {getLanguage('modal.resetPassword.message_1')}
          <br />
          {getLanguage('modal.resetPassword.message_2')}
        </React.Fragment>
      );
      params.buttonSubmitName = getLanguage('field.buttonOK');
      break;
  }
  storeDispatch(mainCreators.setMainRedux(MAIN_REDUX_STATE_KEY.MODAL_DATA, params));
}

export const onCloseModal = () => {
  const params = {
    openModalType: undefined
  }
  storeDispatch(mainCreators.setMainRedux(MAIN_REDUX_STATE_KEY.MODAL_DATA, params));
}

export const onOpenAlert = (data) => {
  const params = {
    ...data,
    isOpen: true,
    onClickClose: () => {
      onCloseAlert();
      if (data.onClickClose) {
        data.onClickClose();
      }
    }
  }
  storeDispatch(mainCreators.setMainRedux(MAIN_REDUX_STATE_KEY.ALERT_DATA, params));
}

export const onCloseAlert = () => {
  const params = {
    isOpen: undefined
  }
  storeDispatch(mainCreators.setMainRedux(MAIN_REDUX_STATE_KEY.ALERT_DATA, params));
}