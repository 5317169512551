import React from 'react'
import {
  TransitionContainer,
  Container,
  Section,
  Image,
  Button,
  Grid,
  Text,
  Field
} from './../../components'
import {
  VARIABLES,
  TYPOGRAPHYS,
  SHAREDS,
  LOGOS,
  ICONS
} from './../../themes'
import {
  ROUTE_PATH,
  redirect,
  isValidEmail,
} from './../../helpers'
import {
  AlertModal
} from '../AlertModal'
import axios from 'axios'
import {
  Loader
} from '../Loader';
import config from '../../config'
import { LOCAL_STORAGE } from '../../helpers/constants/localStorage';
import { handleWatchNoti } from '../../helpers/firebases/firebaseNotification'
import { onOpenAlert } from '../../helpers/functions/main'
import { DATA } from '../../helpers/constants/data'
import { getLanguage } from '../../helpers/functions/language'
import { removeLocalStorage } from '../../helpers/functions/localStorage'
import { setUerAuthLocalStorage } from '../../helpers/functions/data'

// mock user info
// const user = 'toro';
// const pwd = '123456'
export class LoginByEmailPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      username: '',
      password: '',
      showModal: false,
      msg: '',
      topic: '',
      type: '',
      isLoading: false,

    }
  }

  closeAlert() {
    this.setState({
      showModal: false
    })
  }

  componentDidMount() {
    // remove localStorage
    // localStorage.removeItem(LOCAL_STORAGE.REGISTER_INFO);
  }

  handleEmailChange(e) {
    this.setState({
      username: e.target.value
    });
  }

  handlePasswordChange(e) {
    this.setState({
      password: e.target.value
    });
  }

  validateData() {
    const {
      username
    } = this.state;
    // Email validation
    // let emailValid = this.state.username.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
    // if (!emailValid) return false;

    // return true;
    if (!isValidEmail(username)) {
      return false;
    }
    return true;
  }

  signIn() {
    let valid = this.validateData();
    if (this.state.username === '' || !valid) {
      this.setState({
        topic: getLanguage('message.fail'),
        msg: getLanguage('message.emailInCorrectFormat'),
        showModal: true,
      })
    } else if (this.state.password === '') {
      this.setState({
        topic: getLanguage('message.fail'),
        msg: getLanguage('message.pleaseEnterPassword'),
        showModal: true,
      })
    } else {
      this.setState({ isLoading: true })
      axios.post(`${config.api.base}/api/login`, {
        email: this.state.username,
        password: this.state.password,
      }).then((response) => {
        console.log('response.data.data', response.data.data)
        if (response.data.validation) {
          setUerAuthLocalStorage(response.data.data);
          this.setState({ isLoading: false })
          handleWatchNoti();
          redirect(ROUTE_PATH.HOME.LINK);
        } else {
          // alert('wrong password or username');
          this.setState({
            topic: getLanguage('message.fail'),
            msg: getLanguage('message.wrongUsernameOrPassword'),
            showModal: true,
            isLoading: false,
          })
        }
      }, (error) => {
        console.log(error);
        this.setState({ isLoading: false })
        onOpenAlert({
          type: DATA.ALERT_TYPE.ERROR,
          title: getLanguage('message.fail'),
          message: (error || {}).message,
        });
      });
    }
  }
  render() {
    return (
      <TransitionContainer
      // motion='overlap-from'
      >
        <Container
          width={VARIABLES.WEBSITE_WIDTH}
          height='100'
          heightUnit='vh'
          bgImage={SHAREDS['bg-login.jpg']}
          bgSize='cover'
          bgRepeat='no-repeat'
          bgPosition='center'
        >
          <Container
            height='100'
            heightUnit='vh'
            bgColor={VARIABLES.COLORS.OVERLAY_5}
            justify='space-between'
            align='center'
            wrap='no-wrap'
          >
            <Section
              className='is-login-container'
              width='275'
            >
              <Section
                className='is-login-logo'
                justify='center'
              >
                <Image
                  widthRatio='121'
                  widthRatioUnit='px'
                  heightRatio='120'
                  heightRatioUnit='px'
                  src={LOGOS['logo.png']}
                />
              </Section>
              <Section spacingBottom='34'>
                <Field
                  fluid
                  className='is-email'
                  placeholder={getLanguage('field.email')}
                  placeholderColor={VARIABLES.COLORS.BLUE_2}
                  height='28'
                  paddingBottom='10'
                  paddingLeft='47'
                  bgColor={VARIABLES.COLORS.TRANSPARENT}
                  borderBottomWidth='1'
                  borderBottomStyle='solid'
                  borderBottomColor={VARIABLES.COLORS.BLUE_2}
                  fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_15}
                  fontColor={VARIABLES.COLORS.BLUE_2}
                  fieldIcon={
                    <Image
                      widthRatio='24'
                      widthRatioUnit='px'
                      heightRatio='18'
                      heightRatioUnit='px'
                      src={ICONS['ic-email-lightblue.svg']}
                    />
                  }
                  fieldIconSpacingLeft='10'
                  onChange={this.handleEmailChange.bind(this)}
                />
              </Section>
              <Section spacingBottom='12'>
                <Field
                  fluid
                  className='is-password'
                  inputTextType='password'
                  placeholder={getLanguage('field.password')}
                  placeholderColor={VARIABLES.COLORS.BLUE_2}
                  height='28'
                  paddingBottom='10'
                  paddingLeft='47'
                  bgColor={VARIABLES.COLORS.TRANSPARENT}
                  borderBottomWidth='1'
                  borderBottomStyle='solid'
                  borderBottomColor={VARIABLES.COLORS.BLUE_2}
                  fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_15}
                  fontColor={VARIABLES.COLORS.BLUE_2}
                  fieldIcon={
                    <Image
                      widthRatio='21'
                      widthRatioUnit='px'
                      heightRatio='28'
                      heightRatioUnit='px'
                      src={ICONS['ic-password-lightblue.svg']}
                    />
                  }
                  fieldIconSpacingLeft='10'
                  onChange={this.handlePasswordChange.bind(this)}
                />
              </Section>
              <Section
                className='is-login-forgotpassword'
                justify='flex-end'
              >
                <Text
                  fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_14}
                  color={VARIABLES.COLORS.GRAY_3}
                  onClick={() => {
                    redirect(ROUTE_PATH.FORGOT_PASSWORD_METHOD.LINK)
                  }}
                >
                  {getLanguage('loginEmail.message')}
                </Text>
              </Section>
              <Section spacingBottom='18'>
                <Button
                  fluid
                  shadow
                  height={VARIABLES.BUTTON.HEIGHTS.H_40}
                  borderRadius={VARIABLES.BUTTON.BORDER_RADIUSES.BR_10}
                  bgColor={VARIABLES.COLORS.BLUE_2}
                  onClick={this.signIn.bind(this)}
                >
                  <Text
                    fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_16}
                    color={VARIABLES.COLORS.WHITE}
                  >
                    {getLanguage('field.buttonLogin')}
                  </Text>
                </Button>
              </Section>
              <Section>
                <Button
                  fluid
                  height={VARIABLES.BUTTON.HEIGHTS.H_40}
                  bgColor={VARIABLES.COLORS.TRANSPARENT}
                  borderRadius={VARIABLES.BUTTON.BORDER_RADIUSES.BR_10}
                  borderWidth='1'
                  borderStyle='solid'
                  borderColor={VARIABLES.COLORS.BLUE_2}
                  onClick={() => {
                    redirect(ROUTE_PATH.REGISTER.LINK)
                  }}
                >
                  <Text
                    fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_16}
                    color={VARIABLES.COLORS.BLUE_2}
                  >
                    {getLanguage('field.buttonSignUp')}
                  </Text>
                </Button>
              </Section>
            </Section>
            <Section className='is-login-back'>
              <Grid
                gutter='3'
                justify='center'
              >
                <Grid.Column>
                  <Text
                    fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_16}
                    color={VARIABLES.COLORS.BLUE_2}
                  >
                    {getLanguage('loginEmail.message_2')}
                  </Text>
                </Grid.Column>
                <Grid.Column>
                  <Text
                    className='is-underline'
                    fontStyle={TYPOGRAPHYS.FIRST.BOLD.FS_18}
                    color={VARIABLES.COLORS.BLUE_2}
                    onClick={() => {
                      redirect(ROUTE_PATH.LOGIN.LINK)
                    }}
                  >
                    {getLanguage('field.buttonBack')}
                  </Text>
                </Grid.Column>
              </Grid>
            </Section>
          </Container>
        </Container>
        {this.state.showModal ? <AlertModal topic={this.state.topic} msg={this.state.msg} closeModal={this.closeAlert.bind(this)} /> : ''}
        {<Loader loading={this.state.isLoading} isOverlay={true} />}
      </TransitionContainer>
    )
  }
}
