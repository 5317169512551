export const lang_la = {
  sideMenu: {
    points: 'Points',
    myProfile: 'My Profile',
    settings: 'Settings',
    shareWithFriends: 'Share with friends',
    termsAndConditions: 'Terms and conditions',
    logOut: 'Log Out',
  },
  splash: {
    title: 'Growing your business with you',
    description: 'Engage . Showcase . Grow',
  },
  login: {
    loginByEmail: 'Login by Email',
    or: 'Or',
    loginByPhoneNumber: 'Login by Phone Number',
    orLoginWith: 'Or login with',
    reLogin: 'For account security, please log in again.',
    incorrectMemberType: 'Incorrect member type.',
  },
  loginEmail: {
    message: 'forgot password?',
    message_2: 'Choose again?',
  },
  loginPhone: {
    message: 'forgot password?',
    message_2: 'Choose again?',
  },
  welcome: {
    message_1: 'Welcome',
    message_2: 'Thanks, you are now a member of TrueBlue Program',
    message_3: 'and',
    message_4: 'you can check your point as well',
  },
  referenceCode: {
    message_1: 'Reference Code',
    message_2: 'Expired',
  },
  referralAd: {
    message_1: 'How to get',
    message_2: 'Voucher',
    message_3: 'Must be BlueScope customer',
    message_4: 'and TrueBlue member from',
    message_5: '30 Jun 2021',
  },
  lineOaValidation: {
    message_1: 'We have added a new way to',
    message_2: 'provide news and benefits.',
    message_3: 'Please scan QR code or',
    message_4: 'click button to receive.',
    message_5: 'or',
    message_6: 'Welcome',
  },
  voucherOutOfStock: {
    message_1: 'Sorry,',
    message_2: "you do not meet the conditions to",
    message_3: 'receive the E-Voucher.',
    message_4: 'In order not to miss out on receiving special privileges from',
    message_5: 'BlueScope next time',
    message_6: 'You can sign up to become a TrueBlue',
    message_7: 'Member through',
    message_8: 'LineOA today.',
    message_9: 'The purpose is to notify TrueBlue',
    message_10: 'points on every purchase.',
  },
  registerSuccess: {
    message_1: 'Register Success!',
    message_2: 'and you get',
    message_3: 'a voucher',
    message_4: 'A Voucher will be sent to the registered address',
    message_5: 'Sorry, the voucher',
    message_6: 'is sold out.',
  },
  authenticationSuccess: {
    message_1: 'Authentication Success!',
    message_2: 'Please return to TrueBlue',
  },
  register: {
    title_1: 'Sign Up',
    title_2: 'Verification OTP',
    message_1: 'Already have an account?',
    message_2: 'sent to',
  },
  home: {
    buttonViewMore: 'View more',
    tbPoints: 'TrueBlue Points',
    tbRewards: 'TrueBlue Rewards',
    estimator: 'Estimator',
    newsAndPromotions: 'News & Promotions',
    myProjects: 'My Projects',
    products: 'Products',
    findDealer: 'Find A Dealer',
    knowledgeBase: 'Knowledge Base',
    purchaseHistory: 'Purchase History',
    orderInquiry: 'Order Inquiry',
    warranty: 'Warranty',
    training: 'TRAINING',
    your: 'Your',
    length: 'Length',
    metre: 'm',
    purchasing: 'Purchasing',
    cumulativeAmountSince: 'Cumulative amount since',
    tbMember: 'TrueBlue Member',
    rankInformation: 'Rank Information',
    moreDetail: 'More Detail',
    moreDetailExtra: 'More Details',
    noRank: 'No Rank',
    luckyDraw: 'Lucky Draw',
    willExpireOn: 'will expire on',
    point: 'point',
    checkPoint: 'Check points will expire',

  },
  myProfile: {
    title: 'My Profile',
    updateProfile: 'Update Profile',
    changePassword: 'Change Password',
  },
  changePassword: {
    title: 'Change Password',
  },
  forgotPassword: {
    title: 'Forgot Password',
    labelEnterEmail: 'Please enter your email',
    labelEnterPhone: 'Please enter your mobile phone number',
  },
  resetPassword: {
    title: 'Reset Password',
  },
  setting: {
    title: 'Settings',
    language: 'Language setting',
    notification: 'Notification setting',
  },
  settingNoti: {
    title: 'Notification setting',
    orderInquiry: 'Order Inquiry',
    newsAndPromotions: 'News & Promotions',
  },
  settingLanguage: {
    title: 'Language',
    chooseLanguage: 'Choose Your Preferred Language',
    pleaseSelectLanguage: 'Please select your language',
  },
  termPolicy: {
    title: 'Terms and conditions',
  },
  loyaltyPoint: {
    title: 'TrueBlue Points',
    points: 'Points',
    expire: 'Expire',
    expireIn: 'Expire (in next 90 days)',
    tab: {
      earnedPoints: {
        title: 'Earned Points',
      },
      redeemedPoints: {
        title: 'Redeemed Points',
      },
    },
    tbPoints: 'TrueBlue Points',
    numberOfRewards: 'Number of Rewards',
  },
  rewardCat: {
    title: 'TrueBlue Rewards',
    blank: 'Data not found',
    new: 'new',
    points: 'Points',
  },
  reward: {
    title: 'Rewards',
  },
  rewardSearch: {
    title_1: 'Search Result',
    title_2: 'Search',
    rewardName: 'Reward Name',
    searchResult: 'Search Result',
  },
  rewardDetail: {
    points: 'Points',
  },
  estimator: {
    title: 'Estimator',
    roofType: {
      gableRoof: 'Gable roof',
      hipRoof: 'Hip roof',
    },
    profileType: {
      tile: 'Tile',
      sheet: 'Sheet',
    },
    notes: 'Notes',
    noteWidth: 'Width: 4 : 12m',
    notePitch: 'Pitch: 25 : 35 deg',
    noteOverhang: 'Overhang <= 500mm',
    buttonEstimation: 'Estimation',
    footer_1: 'Disclaimer : The Estimator tool for guidance purposes only',
    footer_2: 'and is based on information provided by the user.',
    footer_3: 'NS BlueScope does not guarantee the accuracy of any',
    footer_4: 'results or appropriateness for your specific circumstances.',
    footer_5: 'Please refer to the hyperlink',
    footer_6: 'Terms of Use',
    footer_7: 'for more details.',
  },
  estimatorSummary: {
    title: 'Output Summary',
    quantityEstimate: 'Quantity Estimate',
    congratulations: 'Congratulations !!',
    note_1: 'You are one step closer to building your dream project',
    note_2: 'Please visit the nearest BlueScope Authorised Dealer to',
    note_3: 'Complete the transaction',
    category: {
      title_1: 'Category : Roof',
      note_1: 'number of screw for roofing sheet will depend on profile type.',
      title_2: 'Category : Roof Structure',
      title_3: 'Category : Accessory',
      type: 'Type',
      profile: 'Profile',
      qty: 'Qty',
      unit: 'Unit',
      notes: 'Notes',
    }
  },
  newsAndPromotions: {
    title: 'News & Promotions',
    tab: {
      news: {
        title: 'News',
      },
      promotions: {
        title: 'Promotions',
      },
    },
  },
  newsAndPromotionsComment: {
    title: 'Comments'
  },
  newsAndPromotionsWriteComment: {
    title: 'Comments',
    specifyTitle: 'Please specify title',
    specifyComment: 'Please specify comment',
    characters: 'Characters',
  },
  newsAndPromotionsSearch: {
    title: 'Search',
    searchResult: 'Search Result :',
  },
  myProjects: {
    title: 'My Projects',
  },
  projectsAdd: {
    title: 'My Projects',
  },
  projectsEdit: {
    title: 'Edit Project',
  },
  projectsSearch: {
    title_1: 'Search Result',
    title_2: 'Search',
    searchProject: 'Search project',
    searchResult: 'Search Result :',
  },
  productCatalogs: {
    title: 'Product Catalogs',
  },
  findDealer: {
    title: 'Find A Dealer',
    findDealer: 'Find A Dealer',
    selectDealer: {
      title: 'Please select type of dealer',
      bad: 'Bluescope Authorised Dealer (AD)',
      or: 'Other Rollformers',
      distributor: 'Distributor',
      tokoStore: 'Toko / Store BlueScope Zacs™',
      indoBad: 'BlueScope Authorized Dealer',
    }
  },
  findDealerType: {
    title: 'Dealer Type',
    nearestDealers: 'Finding nearest dealers',
    byName: 'Search by name',
    byArea: 'Select by Area',
    or: 'OR',
  },
  findDealerSearch: {
    title: 'Dealer near you',
    dealerCard: {
      buttonGetDirection: 'Get Direction',
      buttonViewDetail: 'View Detail',
    }
  },
  findDealerSearchByName: {
    title: 'Search by Name',
    byName: 'Search by name',
  },
  findDealerSearchByArea: {
    title: 'Select by Area',
  },
  knowledgeBase: {
    title: 'Knowledge Base'
  },
  knowledgeBaseDetail: {
    title: 'Knowledge Base',
    tab: {
      description: {
        title: 'Description',
      },
      documentsDownload: {
        title: 'Documents / Download',
      },
    },
  },
  purchaseHistory: {
    title_1: 'Filter Result',
    title_2: 'Purchase History',
    totalSales: 'Total sales',
    DataNotFound: 'Data not found',
  },
  purchaseHistorySearch: {
    title: 'Filter Date',
  },
  purchaseHistoryDetail: {
    title: 'Purchase History',
  },
  orderInquiry: {
    title: 'Order Inquiry',
    category: {
      bad: 'Authorised Dealers',
      or: 'Other Rollformers',
    },
    selectDealer: 'Select dealer',
    result: 'Result',
    orderInquiry: 'Order inquiry',
    allPreviousOrderInquiry: 'All previous order inquiry',
    dataNotFound: 'Data not found',
  },
  orderInquirySearch: {
    title: 'Search',
    category: {
      bad: 'Authorised Dealers',
      or: 'Other Rollformers',
    },
    selectDealer: 'Select dealer',
    suggestions: 'Suggestions',
  },
  orderInquiryAdd: {
    title: 'Add Order Inquiry',
  },
  warranty: {
    title: 'Warranty',
  },
  warrantySearchByDate: {
    title: 'Filter Date',
  },
  warrantySearchByName: {
    title_1: 'Search Result',
    title_2: 'Search',
    warranty: 'Warranty No., Warranty Name',
    salesADProduct: 'AD, Product, Home owner mobile phone',
    searchResult: 'Search Result',
  },
  warrantySearch: {
    title: 'Filter Result',
    totalOrders: 'Total orders',
  },
  warrantyProduct: {
    dataNotFound: 'Data not found',
  },
  warrantyProductItem: {
    issuedDate: 'Issued date',
    product: 'Product',
    thickness: 'Thickness',
    length: 'Length',
    warrantyID: 'Warranty ID',
  },
  termsAndConditions: {
    title: 'Terms and conditions',
  },
  verify: {
    title1: 'Setup Verify Questions',
    title2: 'Verify with Questions',
    title3: 'Verify with Phone Number',
    question1: 'What is your nickname?',
    question2: "What is your favorite animal?",
    question3: 'What is the province where you were born?',
  },
  container: {
    inputOtp: {
      title: 'Verification OTP',
      message: 'sent to',
      message_2: 'OTP is incorrect, Please re-send again',
    },
  },
  modal: {
    changeLanguage: {
      title: 'Change Language',
      message: 'Your Language has been updated.',
    },
    editProfile: {
      title: 'Edit Profile',
      message_1: 'Your profile has been updated.',
      message_2: 'some information maybe wrong, please try again',
      message_3: 'something wrong',
    },
    changePassword: {
      title: 'Change password success!',
      message: 'Please try your new password in the next login.',
      fail: 'Fail',
      passwordNotMatch: 'Password does not match',
      passwordIncorrectFormat: 'Password is incorrect format',
      changeSuccess: 'Change password success!',
      nextLogin: 'Please try your new password in the next login',
    },
    commentPosted: {
      title: 'Comment Posted!',
      message: 'Thank you for your feedback',
    },
    confirmation: {
      title: 'Confirmation',
      message: 'Do you want to delete',
      message_2: 'Are you sure you want to delete',
      message_2_2: 'this project?',
    },
    warrantyDetail: {
      projectName: 'Project Name',
      address: 'Address',
      quantity: 'Quantity',
      color: 'Color (If any)',
    },
    newVersion: {
      title_1: 'TrueBlue new version',
      title_2: 'is available.',
    },
    forgotPassword: {
      titleSendResetLink: 'Password reset link sent!',
      messageCheckEmail: 'Please check your email',
      titleSendOtp: 'OTP has been sent!',
      messageCheckNotification: 'Please check your notification',
    },
    resetPassword: {
      title: 'Reset password success!',
      message_1: 'Please try your new password',
      message_2: 'in the next login',
    },
    iosAddToHomeScreen: {
      title_1: 'Add to home screen',
      title_2: 'by share',
    },
    socialInputPhone: {
      title: 'Please fill your phone number',
    },
    notiTnc: {
      title_1: 'TrueBlue Services',
      title_2: 'Terms & Condition',
      message_1: 'Before you can proceed you must',
      message_2: 'Read and accept the new',
      message_3: 'Terms & Conditions.',
    },
    otpSend: {
      title: 'OTP has been sent!',
      description: 'Please check your notification',
    },
    authentication: {
      title: 'Authentication link has been sent to your phone (SMS)',
      description1: 'Please approve the access from the link within 3 minutes',
      description2: 'Reference code is',
      description3: 'Do not close this window until the authentication is complete',
      description4: 'or',
      link: 'Re-Send Authentication Link',
    },
  },
  component: {
    component: {
      likes: 'likes',
      comments: 'comments',
      buttonComment: 'Comment',
    }
  },
  field: {
    buttonOK: 'OK',
    buttonClose: 'Close',
    buttonSearch: 'Search',
    buttonClear: 'CLEAR',
    buttonSave: 'SAVE',
    buttonSaveAndChange: 'Save & Change',
    buttonSaveAsPhoto: 'Save as Photo',
    buttonSharePhoto: 'Share Photo',
    buttonSubmit: 'Submit',
    buttonAddNewProject: 'Add new project',
    buttonEditProject: 'Edit project',
    buttonDeleteProject: 'Delete Project',
    buttonAddPhoto: 'Add Photo',
    hintButtonAddPhotoLineOne: 'Select a .jpg, .jpeg, .png file and',
    hintButtonAddPhotoLineTwo: 'maximum upload file size is 1MB.',
    buttonDelete: 'Delete',
    buttonCancel: 'Cancel',
    buttonFilter: 'Filter',
    buttonAddNewOrderInquiry: 'Add new order inquiry',
    buttonMoreDetail: 'More Detail',
    buttonUpdate: 'Update',
    buttonSend: 'Send',
    buttonContinue: 'Continue',
    buttonContinueRegister: 'Continue Register',
    buttonSignUp: 'Sign up',
    buttonAgree: 'Agree',
    buttonReSend: 'Re-send',
    buttonLogin: 'Login',
    buttonBack: 'Back',
    buttonConfirm: 'Confirm',
    buttonApprove: 'Approve',
    buttonToLogin: 'Back to Login',
    buttonRegisterNow: 'Register Now',
    buttonGoToTrueBlue: 'Go to TrueBlue',
    buttonBlueScopeThailand: 'BlueScope Thailand',
    labelNew: 'NEW',
    labelSample: 'SAMPLE',
    firstname: 'First Name',
    firstnamePlaceholder: 'Insert first name',
    lastname: 'Last Name',
    lastnamePlaceholder: 'Insert last name',
    nickname: 'Nick Name',
    nicknamePlaceholder: 'Insert nick name',
    company: 'Company',
    companyPlaceholder: 'Insert company',
    type: 'Type',
    typePlaceholder: 'Please select type',
    phoneNumber: 'Phone Number',
    mobilePhoneNumber: 'Mobile Phone Number',
    email: 'Email',
    emailPlaceholder: 'Insert email',
    birthDate: 'Birth date',
    birthDatePlaceholder: 'Please select your birth date',
    address: 'Address',
    addressPlaceholder: 'Insert address',
    province: 'Province/State',
    provincePlaceholder: 'Please select province/state',
    city: 'City',
    cityPlaceholder: 'Please select city',
    district: 'District',
    districtPlaceholder: 'Insert district',
    subDistrict: 'Sub-district',
    subDistrictPlaceholder: 'Insert sub-district',
    subDistrictSelectPlaceholder: 'Please select sub-district',
    alley: 'Alley/Lane',
    alleyPlaceholder: 'Insert alley/lane',
    group: 'Group/Hamlet',
    groupPlaceholder: 'Insert group/hamlet',
    street: 'Street',
    streetPlaceholder: 'Insert street',
    ward: 'Ward',
    wardPlaceholder: 'Insert ward',
    postCode: 'Post code',
    postCodePlaceholder: 'Insert post code',
    postCodeSelectPlaceholder: 'Please select post code',
    country: 'Country',
    countryPlaceholder: 'Insert country',
    password: 'Password',
    passwordHint: '8 to 24 characters long',
    oldPassword: 'Old Password',
    newPassword: 'New Password',
    newPasswordHint: '8 to 24 characters long',
    confirmPassword: 'Confirm Password',
    roofType: 'Roof Type',
    roofTypePlaceholder: 'Select roof type',
    profileType: 'Profile Type',
    profileTypePlaceholder: 'Select profile type',
    widthUnit: 'Width (m)',
    lengthUnit: 'Length (m)',
    heightUnit: 'Height (m)',
    overhangUnit: 'Overhang (mm)',
    title: 'Title',
    titlePlaceholder: 'Insert title',
    comment: 'Comment',
    commentPlaceholder: 'what do you think about it…',
    newsAndPromotionsPlaceholder: 'Search news & promotions',
    projectName: 'Project name',
    location: 'Location',
    areaSizeProject: 'Area size of project',
    product: 'Product',
    description: 'Description',
    descriptionPlaceholder: 'Insert Description…',
    selectDealerPlaceholder: 'Please select dealer',
    selectProvincePlaceholder: 'Select a Province',
    selectAmphoePlaceholder: 'Select a Khet/Amphoe',
    filterStartDateEndDatePlaceholder: 'Filter start date - End date',
    startDatePlaceholder: 'Start date',
    endDatePlaceholder: 'End date',
    productGroup: 'Product group',
    coilName: 'Coil Name',
    thickness: 'Thickness',
    color: 'Color',
    length: 'Length',
    meter: 'meter',
    points: 'Points',
    points_2: 'points',
    where: 'Where',
    status: 'Status',
    trasactionID: 'Trasaction ID',
    selectCategoryPlaceholder: 'Select category',
    subject: 'Subject',
    subjectPlaceholder: 'Insert Subject',
    selectAreaPlaceholder: 'Select an Area',
    phoneIsIncorrectFormat: 'Phone is incorrect format.',
    agreeTermsAndConditions: 'Agree Terms and conditions',
    questionPlaceholder: 'Insert Answer',
    birthDayPlaceholder: 'Select Birthday',
    customerTypeFixer: 'Fixers / Contractors',
    customerTypeHardware: 'Hardware Store / Construction Shop',
    customerTypeHomeowner: 'Homeowner / Project Owner',
    customerTypeRollFormer: 'Roll formers / RF Sales / RF Fixers',
    customerTypeOthers: 'Others (Military, Government)',
    customerTypeArchitect: 'Architect / Designer / Consult',
    customerTypeEngineer: 'Engineer'
  },
  message: {
    fail: 'Fail',
    pleaseInputPhone: 'Please input phone',
    pleaseInputAllField: 'Please insert all field',
    pleaseInputAllRequiredField: 'Please insert all required field',
    somethingWrong: 'Something went wrong, Please try again later',
    cannotGetYourCurrentLocation: 'Cannot get your current location',
    pleaseSelectStartDateAndEndDate: 'Please select start date and end date',
    invalidDateFormat: 'Invalid date format',
    pleaseSelectCategoryAndDealer: 'Please select category and dealer',
    yourOrderInquiryHasBeenSent: 'Your order inquiry has been sent!',
    pleaseSpecifySubject: 'Please specify subject',
    pleaseSpecifyDescription: 'Please specify description',
    browserCantShare: `This browser can't share please use Chrome or Safari.`,
    phoneDoesntExist: 'This phone number doesn’t exist',
    passwordNotMatch: 'Password does not match',
    passwordIncorrectFormat: 'Password is incorrect format',
    dataNotFound: 'Data not found',
    widthOutOfCondition: 'Width out of range',
    pitchOutOfCondition: 'Pitch out of range',
    invalidEmailFormat: 'Invalid email format',
    pleaseInputFirstnameAndLastname: 'Please insert firstname and lastname',
    phoneInCorrectFormat: 'Phone is incorrect format',
    emailInCorrectFormat: 'Email is incorrect format.',
    nameInCorrectFormat: 'Name is incorrect format.',
    emailAddressAlreadyExists: 'Email address already exists.',
    loginBySocialError: 'Login by social error.',
    cantGetUserInfo: "Can't get user info.",
    phoneEmailAlreadyExists: 'Phone/Email is already exist.',
    phoneAlreadyExists: 'Phone is already exist.',
    sendOtpError: 'Send otp error.',
    getSecretOtpError: 'Get secret otp error.',
    otpIncorrect: 'OTP is incorrect, Please re-send again.',
    pleaseEnterPassword: 'Please Enter Password',
    pleaseEnterPhone: 'Please Enter Phone',
    pleaseEnterPhoneAndPassword: 'Please Enter Phone and Password',
    wrongUsernameOrPassword: 'Wrong username or password.',
    browserNotSupport: `This browser not support please use Chrome or Safari.`,
    phoneNotMatch: "Phone number doesn't match the TrueBlue member system.",
    phoneNotMatch1: 'If you have been a TrueBlue member. Please contact',
    phoneNotMatch2: 'BlueScope Metal Sheet Center',
    phoneNotMatch3: 'or if you are not a member yet.',
    wrongAnswer: 'Wrong answer',
    passwordAndConfirmPasswordNotMatch: "Password and Confirm Password aren’t match.",
  },
  luckyDraw: {
    title: 'Lucky Draw',
    termsAndConditions: 'Terms & Conditions',
    agreeTermsAndConditions: 'Agree terms and conditions',
    dontShowThisMessageAgainNextTime: `Don't show this message again next time`,
    accept: 'Accept',
    winPrizes: 'Win Prizes',
    awardAnnouncement: 'Award Announcement',
    noLuckyDrawEvent: 'No Lucky Draw Event',
    usePointToWinPrizes: 'Use points to win prizes',
    point: 'point',
    right: 'right',
    numberOfUsedPoints: 'Number of used points',
    yourPointIsNotEnough: 'Your points is not enough',
    buttonWinPrizes: 'Win prizes',
    reward: 'Reward',
    viewDetail: 'View detail',
    readTermsAndConditions: 'Read terms and conditions',
    announcement: 'Announcement',
    announcementOfNameWinners: 'Announcement of name winners',
    campaign: 'Campaign',
    dateFrom: 'From',
    dateUntil: 'until',
    showMore: 'Show more',
    awardAnnounced: 'Awards announced!',
    youCanClickBtnBelowToSeeYourListName: 'You can click the button below to see your list name',
    buttonCheckMyName: 'Check my name!',
    expirePoint: 'Expire point',
    toExpire: 'To Expire',
    points: 'points',
    dontWasteYourPoint: `Don't waste your point`,
    useYourPointToExchangeForAChanceToWinPrizes: 'Use your points to exchange for a chance to win prizes',
    buttonGoToWinPrizes: 'Go to win prizes',
    total: 'Total',
    youUseYourRightToWinPrizes: 'You use your right to win prizes',
    goodLuckWithWinnigPrizes: 'Good luck with winning prizes',
    youHave: 'You have',
    pointLeft: 'points left',
    awardCount: 'Number of awards',
    showLess: "Show less",
    rewardTitle: '[quantity] prize',
    campaignDurationDate: 'Start [startDate] until [endDate]',
    campaignLuckyDrawDate: 'Prize draw on',
    announcementRewardDetail: '[rewardDetail] ([quantity] prize)',
    noAwardAnnouncement: 'No Award Announcement',
    youHaveAllThePoint: 'You have all the points :',
    rewardTitleQuantity: 'Quantity [quantity] prize',
    youHaveAMaximumRedeemablePointOfPoint: 'You have a maximum redeemable point of [point] points.',

  },
}