import {
  firebase,
} from '../../config/firebase';
import { FIREBASE_STORAGE } from '../../config/config';

const storage = firebase.storage();

const getListAll = (listRef, pathList) => {
  return new Promise((resolve, reject) => {
    listRef.listAll().then(async (res) => {
      res.items.forEach((itemRef) => {
        pathList.push(itemRef.fullPath);
      });
      for (let i = 0; i < res.prefixes.length; i++) {
        await getListAll(res.prefixes[i], pathList);
      }
      resolve(pathList)
    }).catch((error) => {
      reject(error);
    });
  });
}

export const firebaseGetAllFileList = async (path) => {
  const listRef = storage.ref().child(`${FIREBASE_STORAGE}${path}`);
  let pathList = [];
  return await getListAll(listRef, pathList);
}

export const firebaseUploadFile = (path, file, fileName) => {
  return storage.ref().child(`${FIREBASE_STORAGE}${path}/${fileName}`).put(file).then((res) => {
    console.log(`${fileName} File successfully added!`, res)
    //res.ref.fullPath
    return res
  }).catch((err) => {
    console.error(`${fileName} Error adding file: ${err}`)
  });
}

export const firebaseDeleteFile = (filePath) => {
  try {
    return storage.ref().child(filePath).delete().then((res) => {
      console.log(`${filePath} File successfully deleted!`, res)
      return res
    }).catch((err) => {
      console.error(`${filePath} Error removing file: ${err}`)
    });
  }
  catch{
    return ''
  }
}

export const firebaseUpdateFile = async (path, file, fileName, oldFilePath) => {
  await firebaseDeleteFile(oldFilePath);
  return firebaseUploadFile(path, file, fileName);
}

export const firebaseGetFilePath = (refPath) => {
  try {
    return storage.ref(refPath).getDownloadURL().then((res) => {
      console.log(`getDownloadURL successfully!`, res);
      return res
    }).catch((err) => {
      console.error(`getDownloadURL Error: `);
      console.error({ err });
      return ''
    });
  } catch{
    return ''
  }
}
