import React from 'react'
import {
  TransitionContainer,
  Container,
  Image,
  Section,
  Text,
  Grid,
  NavigationBar,
  Field,
} from './../../components'
import {
  VARIABLES,
  TYPOGRAPHYS,
  ICONS,
} from './../../themes'
import {
  ROUTE_PATH,
  redirect,
} from './../../helpers'
import { getLanguage } from '../../helpers/functions/language';

export class SettingNotificationPage extends React.Component {
  handleBack() {
    redirect(ROUTE_PATH.SETTING.LINK);
  }

  render() {
    return (
      <TransitionContainer
      // motion='overlap-from'
      >
        <Container
          width={VARIABLES.WEBSITE_WIDTH}
          calcMinHeight='100vh'
          bgColor={VARIABLES.COLORS.LIGHT_BLUE_2}
        >
          <NavigationBar
            shadow
            iconWidth='16'
            iconHeight='20'
            iconSrc={ICONS['ic-navigation-notification.svg']}
            pageTitleIcon={getLanguage('settingNoti.title')}
            actionLeft={
              <Image
                widthRatio='11'
                widthRatioUnit='px'
                heightRatio='21'
                heightRatioUnit='px'
                src={ICONS['ic-left-black.svg']}
                onClick={this.handleBack.bind(this)}
              />
            }
          />
          <Section
            paddingHorizontal='20'
            paddingTop='10'
          >
            <Section
              paddingVertical='16'
            >
              <Grid gutter='12'>
                <Grid.Column flex='1'>
                  <Text
                    fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_16}
                    color={VARIABLES.COLORS.BLACK}
                  >
                    {getLanguage('settingNoti.orderInquiry')}
                  </Text>
                </Grid.Column>
                <Grid.Column flex='none'>
                  <Field
                    type='switch'
                    id='switch-order-inquiry'
                    name='switch-order-inquiry'
                    // value={value}
                    // checked={true}
                    // onChange={onChange}
                    switchWidth='57'
                    switchHeight='28'
                    switchBgColor={VARIABLES.COLORS.GRAY_10}
                    switchActiveBgColor={VARIABLES.COLORS.BLUE_2}
                    switchBorderRadius='36'
                    switchButtonSpacingLeft='0'
                    switchButtonSpacingRight='31'
                    switchButtonSize='28'
                    switchButtonActiveColor={VARIABLES.COLORS.WHITE}
                    switchButtonInactiveColor={VARIABLES.COLORS.WHITE}
                  />
                </Grid.Column>
              </Grid>
            </Section>
            <Section
              paddingVertical='16'
            >
              <Grid gutter='12'>
                <Grid.Column flex='1'>
                  <Text
                    fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_16}
                    color={VARIABLES.COLORS.BLACK}
                  >
                    {getLanguage('settingNoti.newsAndPromotions')}
                  </Text>
                </Grid.Column>
                <Grid.Column flex='none'>
                  <Field
                    type='switch'
                    id='switch-news-promotions'
                    name='switch-news-promotions'
                    // value={value}
                    // checked={true}
                    // onChange={onChange}
                    switchWidth='57'
                    switchHeight='28'
                    switchBgColor={VARIABLES.COLORS.GRAY_10}
                    switchActiveBgColor={VARIABLES.COLORS.BLUE_2}
                    switchBorderRadius='36'
                    switchButtonSpacingLeft='0'
                    switchButtonSpacingRight='31'
                    switchButtonSize='28'
                    switchButtonActiveColor={VARIABLES.COLORS.WHITE}
                    switchButtonInactiveColor={VARIABLES.COLORS.WHITE}
                  />
                </Grid.Column>
              </Grid>
            </Section>
          </Section>
        </Container>
      </TransitionContainer>
    )
  }
}
