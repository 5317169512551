import React from 'react';
import { Helmet } from 'react-helmet';
import {
  isMobile,
  isIPad13,
} from 'react-device-detect'
import {
  TransitionContainer,
  Container,
} from './../../components';
import {
  VARIABLES,
} from './../../themes';
import {
  ROUTE_PATH,
  redirect,
  getRouteParams,
  formatDatetime,
} from './../../helpers'
import axios from 'axios';
import config from '../../config';
import _ from 'lodash';
import { Loader } from '../Loader';

export class PublicProductPage extends React.Component {

  constructor(props) {
    super(props);
    const id = parseInt(getRouteParams(props, 'id'));
    this.state = {
      id,
      data: {}
    };
  }

  componentDidMount() {
    this.publicProduct.getData();
  }

  publicProduct = {
    getData: () => {
      const {
        id,
      } = this.state;
      this.setState({
        isLoading: true,
      }, async () => {
        const url = `${config.api.cms}/product/search/`
        const options = { id, sort: 'id desc', status: 'active' }
        const response = await axios.post(url, options)
        const dataList = _.get(response, 'data.data')
        if (response.status === 200 && !_.isEmpty(dataList)) {
          this.setState({
            data: dataList[0],
            isLoading: false
          })
        }
        else {
          this.setState({
            isLoading: false
          })
        }
      })
    }
  }


  render() {
    const {
      data,
      isLoading,
    } = this.state
    return (
      <React.Fragment>
        <Helmet>
          <title>{data.name}</title>
          <meta name='description' content={data.website} />
          <meta property='og:url' content={window.location.href} />
          <meta property='og:type' content='article' />
          <meta property='og:title' content={data.name} />
          <meta property='og:description' content={data.website} />
          <meta property='og:image' content={data.website} />
          <meta name='twitter:card' content='summary' />
        </Helmet>
        <TransitionContainer
        // motion='overlap-from'
        >
          <Container
            calcMinHeight='100vh'
            bgColor={VARIABLES.COLORS.GRAY_5}
          >
            <Container
              width='640'
              calcMinHeight='100vh'
              padding='15'
              bgColor={VARIABLES.COLORS.WHITE}
            >
              {/* {data.website && <iframe className="iframe-full-screen" src={data.website} title={data.name} />} */}

              {isMobile || isIPad13 ?
                <iframe
                  className="iframe-full-screen"
                  title={data.name}
                  src={data.website}
                  frameBorder='0'
                />
                :
                <iframe className="iframe-full-screen" src={data.website} title={data.name} />
              }
            </Container>
          </Container>
        </TransitionContainer>
        <Loader isOverlay
          loading={isLoading}
        />
      </React.Fragment>
    )
  }
}
