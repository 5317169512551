import { ICONS } from "../../themes"
import { ENUM } from "./enum"

const PHONE_PREFIX_LIST = [
  { value: '66', label: '+66', flag: ICONS['ic-flag-th.png'], subStrLength: 2, lengthList: [9], country: ENUM.COUNTRY.THAI, countryType: ENUM.COUNTRY_TYPE.THAI }, // th
  { value: '60', label: '+60', flag: ICONS['ic-flag-my.png'], subStrLength: 2, lengthList: [9, 10], country: ENUM.COUNTRY.MALAYSIA, countryType: ENUM.COUNTRY_TYPE.MALAYSIA }, // my
  { value: '84', label: '+84', flag: ICONS['ic-flag-vn.png'], subStrLength: 2, lengthList: [9], country: ENUM.COUNTRY.VIETNAM, countryType: ENUM.COUNTRY_TYPE.VIETNAM }, // vn
  { value: '62', label: '+62', flag: ICONS['ic-flag-id.png'], subStrLength: 2, lengthList: [8, 9, 10, 11, 12, 13], country: ENUM.COUNTRY.INDONESIA, countryType: ENUM.COUNTRY_TYPE.INDONESIA }, // in,
  { value: '856', label: '+856', flag: ICONS['ic-flag-la.png'], subStrLength: 3, lengthList: [10], country: ENUM.COUNTRY.LAOS, countryType: ENUM.COUNTRY_TYPE.LAOS }, // la
]

const LANGUAGE = {
  THAI: 'th',
  ENGLISH: 'en',
  VIETNAM: 'vi',
  INDONESIA: 'in',
  MALAYSIA: 'ml',
  CHINA: 'ch',
  LAOS: 'la',
}

const MODAL_TYPE = {
  NOTICE: 'notice',
  OTP_SEND: 'otpSend',
  FORGET_PASSWORD_SUCCESS: 'forgetPasswordSuccess',
}

const ALERT_TYPE = {
  SUCCESS: 'success',
  ERROR: 'error',
  SEND: 'send',
}

export const DATA = {
  PHONE_PREFIX_LIST,
  LANGUAGE,
  MODAL_TYPE,
  ALERT_TYPE,
}