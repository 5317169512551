import React from 'react'
import _ from 'lodash'
import {
  TransitionContainer,
  Container,
  Section,
  Image,
  Grid,
  Text,
  Field,
  Button,
  NavigationBar,
} from './../../components'
import {
  VARIABLES,
  TYPOGRAPHYS,
  ICONS,
} from './../../themes'
import {
  SearchResult,
} from './SearchResult'
import { getLanguage } from '../../helpers/functions/language'

export class WarrantySearchByNamePage extends React.Component {
  constructor(props) {
    super(props)
    const searchType = localStorage.getItem('warrantySearchType')
    const list = JSON.parse(localStorage.getItem('warrantyList'))
    this.state = {
      toggleSearch: false,
      keyword: '',
      list: !_.isEmpty(list) ? list : [],
      listSearch: [],
      searchType,
    }
  }

  setToggleSearch() {
    this.setState({ toggleSearch: true })
  }

  onChangeKeyword(event) {
    let keyword = event.target.value
    keyword = keyword.toLowerCase()
    const { searchType, list } = this.state
    this.setToggleSearch()
    this.setState({ keyword })
    if (!keyword) {
      this.resetData()
      return
    }
    if (!_.isEmpty(list)) {
      let listSearch = []
      if (searchType === 'warrantyProduct') {
        let details = _.flatten(_.map(list, 'detail'))
        if (!_.isEmpty(details)) {
          listSearch = details.filter(data => (data.project_name && data.project_name.toLowerCase().search(keyword) >= 0) ||
            (data.wrt_no && data.wrt_no.toLowerCase().search(keyword) >= 0))
          this.setState({ listSearch })
        }
      } else {
        listSearch = list.filter(data =>
          (data.coil_grp && data.coil_grp.toLowerCase().search(keyword) >= 0) ||
          // (data.wrt_no && data.wrt_no.toLowerCase().search(keyword) >= 0) ||
          (data.name && data.name.toLowerCase().search(keyword) >= 0) ||
          (data.project_phone && data.project_phone.search(keyword) >= 0)
        )
        this.setState({ listSearch })
      }
    }
  }

  resetData() {
    this.setState({ keyword: '', listSearch: [] })
  }

  onClickClearKeyword() {
    this.resetData()
  }

  render() {
    const { listSearch, toggleSearch, isLoading, keyword, searchType } = this.state
    return (
      <TransitionContainer>
        <Container
          width={VARIABLES.WEBSITE_WIDTH}
          calcMinHeight='100vh'
          bgColor={VARIABLES.COLORS.LIGHT_BLUE_2}
        >
          <NavigationBar
            pageTitle={`${toggleSearch ? getLanguage('warrantySearchByName.title_1') : getLanguage('warrantySearchByName.title_2')}`}
            actionLeft={
              <Image
                widthRatio='21'
                widthRatioUnit='px'
                heightRatio='21'
                heightRatioUnit='px'
                src={ICONS['ic-close-black.svg']}
                onClick={this.props.history.goBack}
              />
            }
          />
          <Section
            paddingHorizontal='20'
            paddingTop='23'
            paddingBottom='10'
          >
            <Section spacingBottom='25' position='relative'>
              {keyword && <Button
                className='is-clear-search'
                width={20}
                height={20}
                bgImage={ICONS['ic-close-blue-circle.svg']}
                bgSize='cover'
                bgRepeat='no-repeat'
                bgPosition='center'
                bgColor={VARIABLES.COLORS.LIGHT_BLUE_2}
                onClick={this.onClickClearKeyword.bind(this)}
              />}
              <Section position='absolute' className='is-search-placeholder'>
                <Grid gutter='10'>
                  <Grid.Column>
                    <Image
                      widthRatio='15'
                      widthRatioUnit='px'
                      heightRatio='15'
                      heightRatioUnit='px'
                      top='-2'
                      src={`${keyword ? ICONS['ic-search-blue.svg'] : ICONS['ic-search-light-blue.svg']}`}
                    />
                  </Grid.Column>
                  <Grid.Column >
                    {<Text
                      fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_14}
                      color={`${keyword ? VARIABLES.COLORS.BLUE_2 : VARIABLES.COLORS.LIGHT_BLUE_3}`}
                      ellipsis
                    >
                      {!keyword ? searchType === 'warrantyProduct' ? getLanguage('warrantySearchByName.warranty') : getLanguage('warrantySearchByName.salesADProduct') : ''}
                    </Text>}
                  </Grid.Column>
                  <Grid.Column >
                  </Grid.Column>
                </Grid>
              </Section>
              <Field
                fluid
                value={keyword}
                bgColor={VARIABLES.COLORS.TRANSPARENT}
                borderWidth='1'
                borderStyle='solid'
                borderColor={VARIABLES.COLORS.BLUE_2}
                borderRadius='25'
                fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_15}
                fontColor={VARIABLES.COLORS.BLUE_2}
                paddingHorizontal={42}
                paddingTop={10}
                paddingBottom={10}
                onChange={this.onChangeKeyword.bind(this)}
              />
            </Section>
            {toggleSearch && !isLoading && <SearchResult list={listSearch} searchType={searchType} />}
          </Section>
        </Container>
      </TransitionContainer>
    )
  }
}

