import styled, {
  // css
} from 'styled-components'
import {
  default as VARIABLES
} from './../../../themes/styles/bases/variables'
// import {
//   default as TYPOGRAPHYS
// } from './../../../themes/styles/bases/typographys'
// import {
//   default as MIXINS
// } from './../../../themes/styles/helpers/mixins'

// Sample using image in style
// url(${require('./../../../themes/images/icons/icon-sample.svg')});

// Wrapper
// ============================================================
export const TrueBlueRankPanelWrapper = styled.div`
  /* Parent
  ------------------------------- */
  position: relative;
  border-radius: 10px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);

  /* Childrens
  ------------------------------- */
  .tbrp-section {
    &.is-information {
      padding: 10px 10px 5px;
      background-color: ${VARIABLES.COLORS.WHITE};
      border-radius: 10px 10px 0 0;
    }

    &.is-information-length {
      display: flex;
      align-items: center;
      padding: 0 10px;
      margin: 0 0 5px;
      border-radius: 10px;
      background-size: cover;
      background-repeat: no-repeat;

      &.is-information-length-gold,
      &.is-information-length-silver {
        height: 56px;
      }

      &.is-information-length-gold {
        background-image: url(${require('./../../../themes/images/shareds/bg-rank-gold.png')});
      }

      &.is-information-length-silver {
        background-image: url(${require('./../../../themes/images/shareds/bg-rank-silver.png')});
      }

      &.is-information-length-bronze {
        height: 85px;
        background-image: url(${require('./../../../themes/images/shareds/bg-rank-bronze.png')});
      }
    }

    &.is-information-purchasing {}

    &.is-duration {
      padding: 8px 10px;
      border-radius: 0 0 10px 10px;

      &.is-duration-gold {
        background-color: ${VARIABLES.COLORS.YELLOW_2};
      }

      &.is-duration-silver {
        background-color: ${VARIABLES.COLORS.GRAY_13};
      }

      &.is-duration-bronze {
        background-color: ${VARIABLES.COLORS.ORANGE_2};
      }
    }
  }

  .tbrp-image {
    &.is-rank {
      position: absolute;
      z-index: ${VARIABLES.Z_INDEXS.LV_2};
      top: -6px;
      right: 15px;
      width: auto;
      height: 96px;

      @media (max-width: 359px) {
        height: 60px;
      }

      @media (min-width: 360px) and (max-width: 374px) {
        height: 72px;
      }
    }
  }

  .tbrp-badge {
    &.is-purchasing {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 24px;
      padding: 0 10px;
      background-color: ${VARIABLES.COLORS.DISABLED};
      border-radius: 12px;
    }

    &.is-purchasing-circle {
      width: 24px;
      padding: 0;
    }

    &.is-purchasing-gold-active {
      background: rgb(244,194,131);
      background: -moz-linear-gradient(180deg, rgba(244,194,131,1) 0%, rgba(237,165,52,1) 100%);
      background: -webkit-linear-gradient(180deg, rgba(244,194,131,1) 0%, rgba(237,165,52,1) 100%);
      background: linear-gradient(180deg, rgba(244,194,131,1) 0%, rgba(237,165,52,1) 100%);
    }

    &.is-purchasing-silver-active {
      background: rgb(221,221,221);
      background: -moz-linear-gradient(180deg, rgba(221,221,221,1) 0%, rgba(190,190,190,1) 100%);
      background: -webkit-linear-gradient(180deg, rgba(221,221,221,1) 0%, rgba(190,190,190,1) 100%);
      background: linear-gradient(180deg, rgba(221,221,221,1) 0%, rgba(190,190,190,1) 100%);
    }
  }

  .tbrp-panel {
    &.is-no-rank {
      padding: 30px 15px;
    }
  }

  /* Modifiers
  ------------------------------- */
  &.is-fluid {
    width: 100%;
  }

  &.is-no-rank {
      background-color: #ffffff;
    }
  /* Media queries
  ------------------------------- */
`
