import styled, {
  // css
} from 'styled-components'
import {
  default as VARIABLES
} from './../../../themes/styles/bases/variables'
// import {
//   default as TYPOGRAPHYS
// } from './../../../themes/styles/bases/typographys'
// import {
//   default as MIXINS
// } from './../../../themes/styles/helpers/mixins'

// Sample using image in style
// url(${require('./../../../themes/images/icons/icon-sample.svg')});

// Wrapper
// ============================================================
export const CommentPanelWrapper = styled.div`
  /* Parent
  ------------------------------- */
  transform: translateX(-50%);
  position: fixed;
  z-index: ${VARIABLES.Z_INDEXS.LV_2};
  bottom: 0;
  left: 50%;
  width: 100%;
  max-width: ${VARIABLES.WEBSITE_WIDTH_PX};
  padding: 20px 20px 35px;
  background-color: ${VARIABLES.COLORS.WHITE};
  box-shadow: ${VARIABLES.COLORS.SHADOW_1};

  /* Childrens
  ------------------------------- */
  /* Modifiers
  ------------------------------- */
  &.is-public {
    max-width: 640px;
  }

  /* Media queries
  ------------------------------- */
  /* @media (min-width: 415px) {
    left: calc(50% - 8px);
  } */
`
