/* eslint-disable no-lone-blocks */


export class ModelProject {
  constructor(props) {
    props = props || {}
    this.id = props.id;
    this.name = props.name;
    this.location = props.location;
    this.area_size = props.area_size;
    this.product = props.product;
    this.description = props.description;
    this.user_id = props.user_id;
    this.image_url = props.image_url;
    this.https = (props.https || []).map((e) => {
      return e;
    });
    this.type = props.type;
    this.status = props.status;
    this.available_country = props.available_country;
  }
}

export class ModelUploadImgProject {
  constructor(props) {
    props = props || {};
    this.url = props.url
  }
}