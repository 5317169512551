import { newsPromotionLikeController } from "../../apiService/apiController/newsPromotionLikeService";
import { isValidResponse } from "../functions";

export const updateLikeById = async (params) => {
  const newsPromotionLikeService = newsPromotionLikeController();
  const res = await newsPromotionLikeService.updateLikeById(params);
  if (isValidResponse(res)) {
    let data = res.data;
    console.log('updateLikeById', res, data);
    return true;
  }
  return false;
}