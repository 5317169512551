import React from 'react'
import {
  Section,
  Image,
  Grid,
  ShapeContainer,
  Text,
} from './../../components'
import {
  VARIABLES,
  TYPOGRAPHYS,
  ICONS
} from './../../themes'
import {
  ROUTE_PATH,
  redirect,
  formatDatetime,
} from './../../helpers'

export class OrderInquiryItem extends React.Component {
  displayLabel(status) {
    if (!status) return null

    let color = VARIABLES.COLORS.GRAY_3
    if (status === 'acknowledged') {
      color = VARIABLES.COLORS.GREEN_2
    } else if (status === 'pending') {
      color = VARIABLES.COLORS.ORANGE_1
    }

    return (<Section
      borderWidth='1'
      borderStyle='solid'
      borderColor={color}
      borderRadius='6'
      paddingHorizontal='10'
    >
      <Text
        fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_11}
        color={color}
      >
        {status.toUpperCase()}
      </Text>
    </Section>)
  }

  render() {
    const { data, dealerTypeSelected, dealerIdSelected } = this.props
    return (
      <Section
        className='is-shadow'
        paddingVertical='14'
        spacingBottom='14'
        bgColor={VARIABLES.COLORS.WHITE}
        onClick={() => {
          redirect(`${ROUTE_PATH.ORDER_INQUIRY_DETAIL.LINK}/${data.id}?dealerType=${dealerTypeSelected}&dealerId=${dealerIdSelected}`)
        }}
      >
        <Grid gutter='13' align='center'>
          <Grid.Column flex='none'>
            <Section spacingLeft='13'>
              <Image
                widthRatio='33'
                widthRatioUnit='px'
                heightRatio='33'
                heightRatioUnit='px'
                src={ICONS['ic-conversation-blue.svg']}
              />
            </Section>
          </Grid.Column>
          <Grid.Column flex='1'>
            <Section className='is-order-inquiry-content'>
              <Section>
                <Text
                  ellipsis
                  singleLine
                  fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_16}
                  color={VARIABLES.COLORS.BLACK}
                >
                  {data.subject}
                </Text>
              </Section>
              <Section spacingBottom='3'>
                <Grid gutter='6'>
                  <Grid.Column>
                    <Text
                      fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_12}
                      color={VARIABLES.COLORS.GRAY_3}
                    >
                      ID: {data.id}
                    </Text>
                  </Grid.Column>
                  <Grid.Column>
                    <Image
                      widthRatio='12'
                      widthRatioUnit='px'
                      heightRatio='13'
                      heightRatioUnit='px'
                      top='-2'
                      src={ICONS['ic-date-gray.svg']}
                    />
                  </Grid.Column>
                  <Grid.Column>
                    <Text
                      fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_12}
                      color={VARIABLES.COLORS.GRAY_3}
                    >
                      {formatDatetime(data.submit_at, 'DD MMM, YYYY - H:mm')}
                    </Text>
                  </Grid.Column>
                </Grid>
              </Section>
              <Section>
                <Grid>
                  <Grid.Column>
                    {this.displayLabel(data.status)}
                  </Grid.Column>
                </Grid>
              </Section>
            </Section>
          </Grid.Column>
          <Grid.Column flex='none'>
            <Section
              width='48'
              height='100'
              heightUnit='%'
              justify='center'
              align='center'
            >
              <ShapeContainer
                justify='center'
                align='center'
                width='18'
                height='18'
                bgColor={VARIABLES.COLORS.BLUE_2}
                borderRadius='9'
              >
                <Image
                  widthRatio='8'
                  widthRatioUnit='px'
                  heightRatio='8'
                  heightRatioUnit='px'
                  src={ICONS['ic-arrow-more.svg']}
                  left='-1'
                />
              </ShapeContainer>
            </Section>
          </Grid.Column>
        </Grid>
      </Section>
    )
  }
}

