import React from 'react'
import {
  PieChart,
  Pie,
  // Legend,
  Tooltip,
} from 'recharts'
import ClassNames from 'classnames'
import PropTypes from 'prop-types'
import {
  ChartBaseWrapper
} from './styled'
// import {
//   default as VARIABLES
// } from './../../../themes/styles/bases/variables'
// import {
//   default as TYPOGRAPHYS
// } from './../../../themes/styles/bases/typographys'

/**
 * ChartBase description:
 * - Reference: https://github.com/recharts/recharts
 */

export class ChartBase extends React.PureComponent {
  render () {
    const {
      className,
      pie,
      label,
      data,
      innerRadius,
      outerRadius,
      fill,
      width,
      height,
      cx,
      cy,
      progress,
      progressPercent,
      progressWidth,
      progressWidthUnit,
      progressHeight,
      progressTrackPadding,
      progressTrackColor,
      progressColor,
      percentNumberFontStyle,
      percentNumberSpacingLeft
    } = this.props

    return (
      <ChartBaseWrapper
        className={ClassNames(
          'chart-base',
          className
        )}
        theme={{
          progressWidth,
          progressWidthUnit,
          progressHeight,
          progressTrackPadding,
          progressTrackColor,
          progressColor,
          percentNumberFontStyle,
          percentNumberSpacingLeft
        }}
      >
        {/*  Pie chart examples: http://recharts.org/en-US/examples/TwoSimplePieChart  */}
        {pie &&
          <PieChart
            width={label ? width : ((outerRadius * 2) + 12)}
            height={label ? height : ((outerRadius * 2) + 12)}
          >
            <Pie
              label={label}
              dataKey='value'
              data={data}
              cx={label ? cx : outerRadius}
              cy={label ? cy : outerRadius}
              innerRadius={innerRadius}
              outerRadius={outerRadius}
              fill={fill}
            />
            <Tooltip />
          </PieChart>
        }

        {progress &&
          <div className='chart-progress-base'>
            <div className='chart-progress-container-base'>
              <div className='chart-progress-track-base'>
                <div className='chart-progress-percent-base'
                  style={{
                    width: progressPercent + `%`
                  }}
                />
              </div>
            </div>
            <div className='chart-progress-percent-number-base'>
              {progressPercent}%
            </div>
          </div>
        }
      </ChartBaseWrapper>
    )
  }

  static defaultProps = {
    // children: undefined,
    // className: undefined
  }

  static propTypes = { // TYPE > node, string, number, bool, array, object, symbol, func
    /**
    * Additional classes
    */
    className: PropTypes.string,

    /**
    * Additional Text, HTML, Component
    */
    children: PropTypes.oneOfType([
      PropTypes.node,
      PropTypes.string
    ]),

    /**
    * XXXXX
    */
    // xxxxx: PropTypes.string
  }
}
