import styled, {
  css,
  injectGlobal
} from 'styled-components'
import {
  default as VARIABLES
} from './../../../themes/styles/bases/variables'
// import {
//   default as TYPOGRAPHYS
// } from './../../../themes/styles/bases/typographys'
// import {
//   default as MIXINS
// } from './../../../themes/styles/helpers/mixins'
// import {
//   // Example using:
//   // background: url(${CONTENTS['image-sample.svg']});
//   CONTENTS,
//   ICONS,
//   LOGOS,
//   SHAREDS,
//   DOCUMENTS
// } from './../../../themes'

// Wrapper
// ============================================================
export const TransitionContainerWrapper = styled.div`
  /* Parent
  ------------------------------- */
  /*
    - Transition duration consistent with Timeout duration props in CSSTransition component at Route container
    - File path: src/containers/Route/index.js
    - 500 = 0.5s
  */
  transition: transform 0.5s, opacity 0.5s;
  transform: translate3d(0, 0, 0);
  overflow: auto;
  position: fixed;
  z-index: ${VARIABLES.Z_INDEXS.LV_1};

  ${props => {
    const theme = props.theme
    return css`
      top: ${theme.top + `px`};
      right: ${theme.right + `px`};
      bottom: ${theme.bottom + `px`};
      left: ${theme.left + `px`};
    `
  }}

  /* Childrens
  ------------------------------- */
  .transition-scroll-container {
    overflow: auto;
    height: 100%;
  }

  /* Modifiers
  ------------------------------- */
  /* Page transition animation ref: https://github.com/trungdq88/react-router-page-transition/blob/master/EXAMPLES.md */

  /*  Default state (Fade in/out)
  -------------------- */
  /* Transition mechanism */
  /* page-enter >>> page-enter-active */
  /* Transition from CSS properties in 'page-enter' to CSS properties in 'page-enter-active' */
  &.page-enter {
    transform: translate3d(0, 0, 0);
    /* opacity: 1; */
    opacity: 0;
  }
  &.page-enter-active {
    transform: translate3d(0, 0, 0);
    /* opacity: 1; */
    opacity: 1;
  }

  &.page-enter-done {
  }

  /* Transition mechanism */
  /* page-exit >>> page-exit-active */
  /* Transition from CSS properties in 'page-exit' to CSS properties in 'page-exit-active' */
  &.page-exit {
    transform: translate3d(0, 0, 0);
    /* opacity: 1; */
    opacity: 1;
  }
  &.page-exit-active {
    transform: translate3d(0, 0, 0);
    /* opacity: 1; */
    opacity: 0;
  }

  /*  Modifier state
  -------------------- */
  /* Overlap - From */
  &.is-motion-overlap-from {
    /* Transition mechanism */
    /* page-enter >>> page-enter-active */
    /* Transition from CSS properties in 'page-enter' to CSS properties in 'page-enter-active' */
    &.page-enter {
      transform: translate3d(0, 0, 0);
      opacity: 1;
    }
    &.page-enter-active {
      transform: translate3d(0, 0, 0);
      opacity: 1;
    }

    &.page-enter-done {
    }

    /* Transition mechanism */
    /* page-exit >>> page-exit-active */
    /* Transition from CSS properties in 'page-exit' to CSS properties in 'page-exit-active' */
    &.page-exit {
      transform: translate3d(0, 0, 0);
      opacity: 1;
    }
    &.page-exit-active {
      transform: translate3d(0, 0, 0);
      opacity: 1;
    }
  }

  /* Overlap - To */
  &.is-motion-overlap-to {
    z-index: ${VARIABLES.Z_INDEXS.LV_2};

    /* Transition mechanism */
    /* page-enter >>> page-enter-active */
    /* Transition from CSS properties in 'page-enter' to CSS properties in 'page-enter-active' */
    &.page-enter {
      transform: translate3d(100%, 0, 0);
      opacity: 1;
    }
    &.page-enter-active {
      transform: translate3d(0, 0, 0);
      opacity: 1;
    }

    &.page-enter-done {
    }

    /* Transition mechanism */
    /* page-exit >>> page-exit-active */
    /* Transition from CSS properties in 'page-exit' to CSS properties in 'page-exit-active' */
    &.page-exit {
      transform: translate3d(0, 0, 0);
      opacity: 1;
    }
    &.page-exit-active {
      transform: translate3d(100%, 0, 0);
      opacity: 1;
    }
  }

  /* Media queries
  ------------------------------- */
`

injectGlobal`
  .transition-group {
    position: fixed;
    z-index: ${VARIABLES.Z_INDEXS.LV_1};
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
`
