import React from 'react'
import _ from 'lodash'
import {
  Section,
  Image,
  Grid,
  ShapeContainer,
  Text,
} from './../../components'
import {
  VARIABLES,
  TYPOGRAPHYS,
  ICONS
} from './../../themes'
import {
  ROUTE_PATH,
  redirect,
  formatDatetime,
} from './../../helpers'

export class PurchaseHistoryItem extends React.Component {
  render() {
    const { data } = this.props
    return (
      <Section
        className='is-shadow'
        paddingVertical='25'
        spacingBottom='14'
        bgColor={VARIABLES.COLORS.WHITE}
        onClick={() => {
          redirect(`${ROUTE_PATH.PURCHASE_HISTORY_DETAIL.LINK}/${data.id}`)
        }}
      >
        <Grid gutter='13'>
          <Grid.Column flex='1'>
            <Section
              paddingHorizontal='13'
              className='is-purchase-history-content'
            >
              <Section>
                <Text
                  ellipsis
                  singleLine
                  fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_16}
                  color={VARIABLES.COLORS.BLACK}
                >
                  {_.get(data, 'f_bsproductgroupname__c', '')}
                </Text>
              </Section>
              <Section spacingBottom='3'>
                <Grid justify='space-between'>
                  <Grid.Column>
                    <Grid>
                      <Grid.Column>
                        <Section paddingRight='6'>
                          <Image
                            widthRatio='12'
                            widthRatioUnit='px'
                            heightRatio='13'
                            heightRatioUnit='px'
                            top='-2'
                            src={ICONS['ic-date-gray.svg']}
                          />
                        </Section>
                      </Grid.Column>
                      <Grid.Column>
                        <Text
                          fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_12}
                          color={VARIABLES.COLORS.GRAY_3}
                        >
                          {formatDatetime(data.create_at, 'DD MMM, YYYY')}
                        </Text>
                      </Grid.Column>
                    </Grid>
                  </Grid.Column>
                  <Grid.Column>
                    <Text
                      fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_12}
                      color={VARIABLES.COLORS.GRAY_5}
                    >
                      |
                    </Text>
                  </Grid.Column>
                  <Grid.Column>
                    <Text
                      fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_12}
                      color={VARIABLES.COLORS.GRAY_3}
                    >
                      Point :  {_.get(data, 'points', 0).toLocaleString()}
                    </Text>
                  </Grid.Column>
                  <Grid.Column>
                    <Text
                      fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_12}
                      color={VARIABLES.COLORS.GRAY_5}
                    >
                      |
                    </Text>
                  </Grid.Column>
                  <Grid.Column>
                    <Text
                      fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_12}
                      color={VARIABLES.COLORS.GRAY_3}
                    >
                      Length :  {data.coil_length__c ? `${data.coil_length__c.toLocaleString()} m` : '-'}
                    </Text>
                  </Grid.Column>
                </Grid>
              </Section>
            </Section>
          </Grid.Column>
          <Grid.Column flex='none'>
            <Section
              width='48'
              height='100'
              heightUnit='%'
              justify='center'
              align='center'
            >
              <ShapeContainer
                justify='center'
                align='center'
                width='18'
                height='18'
                bgColor={VARIABLES.COLORS.BLUE_2}
                borderRadius='9'
              >
                <Image
                  widthRatio='8'
                  widthRatioUnit='px'
                  heightRatio='8'
                  heightRatioUnit='px'
                  src={ICONS['ic-arrow-more.svg']}
                  left='-1'
                />
              </ShapeContainer>
            </Section>
          </Grid.Column>
        </Grid>
      </Section >
    )
  }
}

