import React from 'react';
import { LineOaValidationContainerConnected } from '../../containers/LineOaValidationContainer'

export class LineOaValidationPage extends React.Component {

  render() {
    return (
      <LineOaValidationContainerConnected />
    )
  }
}
