import React from 'react'
import axios from 'axios'
import _ from 'lodash'
import {
  TransitionContainer,
  Container,
  Section,
  Image,
  Text,
  Button,
  NavigationBar,
  Field,
  Alert,
} from './../../components'
import {
  VARIABLES,
  TYPOGRAPHYS,
  ICONS,
} from './../../themes'
import {
  ROUTE_PATH,
  redirect,
} from './../../helpers'
import {
  Loader
} from '../Loader';
import { LOCAL_STORAGE } from '../../helpers/constants/localStorage';
import { getLanguage } from '../../helpers/functions/language'
import config from '../../config'
import { createNewsPromotionCommentByUserId } from '../../helpers/services/newsPromotionComments'

export class NewsPromotionsWriteCommentPage extends React.Component {
  constructor(props) {
    super(props)
    const user = JSON.parse(localStorage.getItem(LOCAL_STORAGE.REGISTER_INFO))
    this.state = {
      id: (this.props.match.params && this.props.match.params.id) ? +this.props.match.params.id : '',
      // showModalSuccess: false,
      title: '',
      comment: '',
      userId: _.get(user, 'id'),
      showModal: false,
      titleModal: '',
      descriptionModal: '',
      typeModal: '',
      isLoading: false,
    }
  }

  onChangeField(key, event) {
    this.setState({ [key]: event.target.value })
  }

  onSubmit() {
    const { title, comment, userId, id } = this.state
    this.setState({ isLoading: true }, async () => {
      try {
        const resValidateData = this.validateData()
        if (resValidateData.isError) {
          this.setState({ showModal: true, typeModal: 'error', titleModal: '', descriptionModal: resValidateData.msg })
          return
        }

        // get type for update data
        const newsPromotionList = JSON.parse(localStorage.getItem('newsPromotionList'))
        const newsPromotionData = newsPromotionList && newsPromotionList.find(item => item.id === id)
        if (!newsPromotionData) {
          this.showModalError()
          return
        }

        const keyType = (newsPromotionData.type) === 'news' ? 'news_id' : 'promotion_id'
        const options = { title, comment, [keyType]: id, type: newsPromotionData.type }
        const params = {
          user_id: userId,
          ...options,
        }
        const response = await createNewsPromotionCommentByUserId(params)
        if (response) {
          this.setState({
            showModal: true,
            typeModal: 'success',
            titleModal: getLanguage('modal.commentPosted.title'),
            descriptionModal: getLanguage('modal.commentPosted.message')
          })
        } else {
          this.showModalError()
        }
      } catch (_) {
        this.showModalError()
      } finally {
        this.setState({ isLoading: false })
      }
    })
  }

  showModalError() {
    this.setState({
      showModal: true,
      typeModal: 'error',
      titleModal: '',
      descriptionModal: getLanguage('message.somethingWrong')
    })
  }

  validateData() {
    const { title, comment } = this.state
    if (!title) return {
      isError: true,
      msg: getLanguage('newsAndPromotionsWriteComment.specifyTitle')
    }
    if (!comment) return {
      isError: true,
      msg: getLanguage('newsAndPromotionsWriteComment.specifyComment')
    }

    return { isError: false, msg: '' }
  }

  onCloseModal() {
    this.setState({ showModal: false, typeModal: '' })
  }

  redirectToCommentPage() {
    redirect(`${ROUTE_PATH.NEWS_PROMOTIONS_COMMENT.LINK}/${this.state.id}`);
  }

  handleBack() {
    redirect(`${ROUTE_PATH.NEWS_PROMOTIONS_DETAIL.LINK}/${this.state.id}`)
  }

  /*   modal = {
      onClickOpenModalSuccess: () => {
        this.setState({
          showModalSuccess: true
        })
      },
  
      onClickCloseModalSuccess: () => {
        this.setState({
          showModalSuccess: false
        })
      }
    } */

  render() {
    const {
      title,
      comment,
      showModal,
      titleModal,
      descriptionModal,
      typeModal,
      isLoading,
    } = this.state

    return (
      <TransitionContainer>
        <Container
          width={VARIABLES.WEBSITE_WIDTH}
          calcMinHeight='100vh'
          bgColor={VARIABLES.COLORS.WHITE}
        >
          <NavigationBar
            shadow
            pageTitle={getLanguage('newsAndPromotionsWriteComment.title')}
            actionLeft={
              <Image
                widthRatio='11'
                widthRatioUnit='px'
                heightRatio='21'
                heightRatioUnit='px'
                src={ICONS['ic-left-black.svg']}
                onClick={this.handleBack.bind(this)}
              />
            }
          />
          <Section
            scroll
            calcHeight='100vh - 70px'
            paddingTop='30'
            paddingBottom='30'
            paddingHorizontal='20'
          >
            <Section spacingBottom='20'>
              <Field
                fluid
                floatLabel
                height='40'
                label={getLanguage('field.title')}
                labelFontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_12}
                labelFontColor={VARIABLES.COLORS.GRAY_3}
                placeholder={getLanguage('field.titlePlaceholder')}
                placeholderColor={VARIABLES.COLORS.GRAY_5}
                paddingHorizontal='10'
                borderWidth='1'
                borderStyle='solid'
                borderColor={VARIABLES.COLORS.GRAY_5}
                borderRadius='5'
                fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_14}
                fontColor={VARIABLES.COLORS.BLACK}
                value={title}
                onChange={this.onChangeField.bind(this, 'title')}
              />
            </Section>
            <Section spacingBottom='6'>
              <Field
                fluid
                floatLabel
                type='textarea'
                maxLength='1000'
                height='155'
                label={getLanguage('field.comment')}
                labelFontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_12}
                labelFontColor={VARIABLES.COLORS.GRAY_3}
                placeholder={getLanguage('field.commentPlaceholder')}
                placeholderColor={VARIABLES.COLORS.GRAY_5}
                paddingTop='12'
                paddingHorizontal='10'
                borderWidth='1'
                borderStyle='solid'
                borderColor={VARIABLES.COLORS.GRAY_5}
                borderRadius='5'
                fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_14}
                fontColor={VARIABLES.COLORS.BLACK}
                value={comment}
                onChange={this.onChangeField.bind(this, 'comment')}
              />
            </Section>
            <Section spacingBottom='28' justify='flex-end'>
              <Text
                fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_12}
                color={VARIABLES.COLORS.GRAY_4}
              >
                {getLanguage('newsAndPromotionsWriteComment.characters')} ( {comment.length} / 1,000 )
              </Text>
            </Section>
            <Section justify='center'>
              <Button
                shadow
                className='is-gradient-blue'
                width='275'
                height={VARIABLES.BUTTON.HEIGHTS.H_40}
                borderRadius={VARIABLES.BUTTON.BORDER_RADIUSES.BR_10}
                onClick={this.onSubmit.bind(this)}
              >
                <Text
                  fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_16}
                  color={VARIABLES.COLORS.WHITE}
                >
                  {getLanguage('field.buttonSubmit')}
                </Text>
              </Button>
            </Section>
          </Section>
        </Container>

        {/* <Modal
          open={showModalSuccess}
          width={VARIABLES.MODAL.WIDTHS.W_300}
          overlayColor={VARIABLES.COLORS.OVERLAY_3}
          spacing={VARIABLES.MODAL.CONTAINER.C_1.SPACING}
          transition={VARIABLES.TRANSITIONS.DEFAULT}
          transformStart={VARIABLES.MODAL.CONTAINER.C_1.TRANSFORM_START}
          transformEnd={VARIABLES.MODAL.CONTAINER.C_1.TRANSFORM_END}
          onClickClose={this.modal.onClickCloseModalSuccess}
        >
          <Modal.Section
            paddingTop={VARIABLES.MODAL.HEADER.H_1.PADDING_TOP}
            paddingBottom={VARIABLES.MODAL.HEADER.H_1.PADDING_BOTTOM}
            paddingHorizontal={VARIABLES.MODAL.HEADER.H_1.PADDING_HORIZONTAL}
            borderTopRightRadius={VARIABLES.MODAL.HEADER.H_1.BORDER_TOP_RIGHT_RADIUS}
            borderTopLeftRadius={VARIABLES.MODAL.HEADER.H_1.BORDER_TOP_LEFT_RADIUS}
          >
            <Section justify='center'>
              <Image
                widthRatio='61'
                widthRatioUnit='px'
                heightRatio='61'
                heightRatioUnit='px'
                src={ICONS['ic-success.svg']}
              />
            </Section>
          </Modal.Section>
          <Modal.Section
            paddingBottom={VARIABLES.MODAL.BODY.B_1.PADDING_BOTTOM}
            paddingHorizontal={VARIABLES.MODAL.BODY.B_1.PADDING_HORIZONTAL}
          >
            <Section
              spacingBottom='12'
              justify='center'
            >
              <Text
                fontStyle={TYPOGRAPHYS.FIRST.BOLD.FS_18}
                color={VARIABLES.COLORS.BLACK}
              >
                Comment success!
              </Text>
            </Section>
            <Section
              justify='center'
            >
              <Text
                fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_15}
                color={VARIABLES.COLORS.GRAY_6}
                align='center'
              >
                Thank you for your feedback
              </Text>
            </Section>
          </Modal.Section>
          <Modal.Section
            paddingBottom={VARIABLES.MODAL.FOOTER.F_1.PADDING_BOTTOM}
            paddingHorizontal={VARIABLES.MODAL.FOOTER.F_1.PADDING_HORIZONTAL}
            borderBottomRightRadius={VARIABLES.MODAL.FOOTER.F_1.BORDER_BOTTOM_RIGHT_RADIUS}
            borderBottomLeftRadius={VARIABLES.MODAL.FOOTER.F_1.BORDER_BOTTOM_LEFT_RADIUS}
          >
            <Button
              fluid
              shadow
              className='is-gradient-blue'
              height={VARIABLES.BUTTON.HEIGHTS.H_35}
              borderRadius={VARIABLES.BUTTON.BORDER_RADIUSES.BR_10}
              onClick={this.modal.onClickCloseModalSuccess}
            >
              <Text
                fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_16}
                color={VARIABLES.COLORS.WHITE}
              >
                OK
              </Text>
            </Button>
          </Modal.Section> 
        </Modal>*/}
        <Alert
          open={showModal}
          type={typeModal}
          title={titleModal}
          description={descriptionModal}
          onClose={typeModal === 'success' ? this.redirectToCommentPage.bind(this) : this.onCloseModal.bind(this)}
        />
        <Loader loading={isLoading} isOverlay />
      </TransitionContainer >
    )
  }
}
