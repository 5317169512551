import JavaScriptLoadImage from 'blueimp-load-image';
import EXIF from 'exif-js';
import _ from 'lodash';
import moment from 'moment';
import { DATA_REDUX_STATE_KEY, dataCreators } from '../../actions/dataAction';
import { storeDispatch, storeGetState } from '../../store';
import { COUNTRY } from '../constants/country';
import { DATA } from '../constants/data';
import { ENUM } from '../constants/enum';
import { LOCAL_STORAGE } from '../constants/localStorage';
import { getUserAuth } from './auth';
import { getCustomerTypeText } from './display';
import { getLanguageLocalStorage } from './language';
import { getLocalStorage, removeLocalStorage, setLocalStorage } from './localStorage';

export const imageResize = (files, maxPixel = 1200) => {
  return new Promise(resolve => {
    if (files) {
      const reader = new FileReader()
      reader.onload = (ev) => {
        const img = new Image();
        img.src = ev.target.result;
        img.onload = async () => {
          const elem = document.createElement('canvas');
          let scaleFactor = 1;
          let width = img.width;
          let height = img.height;
          if (width > height) {
            if (width > maxPixel) {
              scaleFactor = maxPixel / img.width;
              width = maxPixel;
            }
            elem.width = width;
            elem.height = height * scaleFactor;
          }
          else if (height > width) {
            if (height > maxPixel) {
              scaleFactor = maxPixel / img.height;
              height = maxPixel;
            }
            elem.width = width * scaleFactor;
            elem.height = height;
          }
          else {
            if (width > maxPixel && height > maxPixel) {
              elem.width = maxPixel;
              elem.height = maxPixel;
            }
            elem.width = width;
            elem.height = height;
          }
          console.log('elem.width, elem.height', elem.width, elem.height)
          // const ctx = await getCanvasOrientation(img, elem);
          const ctx = elem.getContext('2d');
          ctx.drawImage(img, 0, 0, elem.width, elem.height);
          ctx.canvas.toBlob(blob => {
            const file = new File([blob], files.name, {
              type: 'image/jpeg',
              lastModified: Date.now()
            });
            resolve(file)
          }, 'image/jpeg', 0.9);
        }
      }
      reader.readAsDataURL(files);
    }
    else {
      resolve(files);
    }
  })
}

export const loadImage = (file) => {
  return new Promise((resolve, reject) => {
    JavaScriptLoadImage.parseMetaData(file, function (data) {
      var orientation = 0;
      //if exif data available, update orientation
      if (data.exif) {
        orientation = data.exif.get('Orientation');
      }

      JavaScriptLoadImage(
        file,
        async (canvas) => {
          const newFile = await convertConvasToFile(canvas, file)
          console.log('newFile', newFile);
          resolve(newFile);
        },
        {
          maxWidth: 1200,
          maxHeight: 1200,
          canvas: true,
          orientation: orientation
        }
      );
    });
  })
}

export const convertConvasToFile = (canvas, file) => {
  return new Promise((resolve, reject) => {
    canvas.toBlob(blob => {
      const newFile = new File([blob], file.name, {
        type: 'image/jpeg',
        lastModified: Date.now()
      });
      resolve(newFile);
    }, 'image/jpeg', 0.9);
  })
}

const getCanvasOrientation = (img, canvas) => {
  return new Promise((resolve, reject) => {
    // Set variables
    let ctx = canvas.getContext("2d");
    let width = canvas.width,
      height = canvas.height;

    // Check orientation in EXIF metadatas
    EXIF.getData(img, function () {
      let allMetaData = EXIF.getAllTags(this);
      const exifOrientation = allMetaData.Orientation;
      console.log('exifOrientation', exifOrientation)

      // set proper canvas dimensions before transform & export
      if ([5, 6, 7, 8].indexOf(exifOrientation) > -1) {
        canvas.width = img.height;
        canvas.height = img.width;
      } else {
        canvas.width = img.width;
        canvas.height = img.height;
      }

      // transform context before drawing image
      switch (exifOrientation) {
        case 2:
          ctx.transform(-1, 0, 0, 1, width, 0);
          break;
        case 3:
          ctx.transform(-1, 0, 0, -1, width, height);
          break;
        case 4:
          ctx.transform(1, 0, 0, -1, 0, height);
          break;
        case 5:
          ctx.transform(0, 1, 1, 0, 0, 0);
          break;
        case 6:
          ctx.transform(0, 1, -1, 0, height, 0);
          break;
        case 7:
          ctx.transform(0, -1, -1, 0, height, width);
          break;
        case 8:
          ctx.transform(0, -1, 1, 0, 0, width);
          break;
        default:
          ctx.transform(1, 0, 0, 1, 0, 0);
      }
      // Draw img into canvas
      ctx.drawImage(img, 0, 0, width, height);
      resolve(ctx);
    });
  });
}

export const getDistanceFromLatLonInKm = (lat1, lon1, lat2, lon2) => {
  const r = 6371; // Radius of the earth in km
  const dLat = deg2rad(lat2 - lat1);  // deg2rad below
  const dLon = deg2rad(lon2 - lon1);
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) *
    Math.sin(dLon / 2) * Math.sin(dLon / 2)
    ;
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const d = r * c; // Distance in km
  return d;
}

export const deg2rad = (deg) => {
  return deg * (Math.PI / 180)
}

export const getCountryPhoneByCountry = (country) => {
  switch ((country || '').toLowerCase()) {
    case ENUM.COUNTRY.THAI.toLowerCase():
      return DATA.PHONE_PREFIX_LIST[0].value;
    case ENUM.COUNTRY.MALAYSIA.toLowerCase():
      return DATA.PHONE_PREFIX_LIST[1].value;
    case ENUM.COUNTRY.VIETNAM.toLowerCase():
      return DATA.PHONE_PREFIX_LIST[2].value;
    case ENUM.COUNTRY.INDONESIA.toLowerCase():
      return DATA.PHONE_PREFIX_LIST[3].value;
    case ENUM.COUNTRY.LAOS.toLowerCase():
      return DATA.PHONE_PREFIX_LIST[4].value;
    default:
      return DATA.PHONE_PREFIX_LIST[0].value;
  }
}

export const getCountryPhone = (phone = '') => {
  let country = DATA.PHONE_PREFIX_LIST[0];
  if (phone) {
    DATA.PHONE_PREFIX_LIST.map(e => {
      if (phone.toString().indexOf(e.value) === 0) {
        country = e;
      }
    })
  }
  return country;
}

export const getPhoneRemovePrefix = (phone = '') => {
  if (phone) {
    let isSubStr = false;
    let subStrLength = 2;
    DATA.PHONE_PREFIX_LIST.map(e => {
      if (phone.toString().indexOf(e.value) === 0) {
        isSubStr = true;
        subStrLength = e.subStrLength;
      }
    })
    if (isSubStr) {
      return phone.toString().substring(subStrLength, phone.length);
    }
    else {
      return phone
    }
  }
  return ''
}

export const getProvince = (value) => {
  if (value) {
    let province = {
      label: value,
      value,
    }
    const countryList = storeGetState().dataRedux.countryList;
    const findProvince = countryList.filter(e => e.provinceCode.toString() === value.toString())[0];
    if (findProvince) {
      province = findProvince;
    }
    return province;
  }
  return undefined;
}

export const getCity = (value) => {
  if (value) {
    const countryList = storeGetState().dataRedux.countryList;
    let city = {
      label: value,
      value,
    };
    countryList.forEach(e => {
      e.cityList.forEach(ec => {
        if (ec.cityCode.toString() === value.toString()) {
          city = ec;
        }
      });
    });
    return city;
  }
  return undefined;
}

export const getCountryType = (type) => {
  switch ((type || '').toLowerCase()) {
    case ENUM.COUNTRY.THAI.toLowerCase():
      return ENUM.COUNTRY_TYPE.THAI;
    case ENUM.COUNTRY.MALAYSIA.toLowerCase():
      return ENUM.COUNTRY_TYPE.MALAYSIA;
    case ENUM.COUNTRY.VIETNAM.toLowerCase():
      return ENUM.COUNTRY_TYPE.VIETNAM;
    case ENUM.COUNTRY.INDONESIA.toLowerCase():
      return ENUM.COUNTRY_TYPE.INDONESIA;
    case ENUM.COUNTRY.LAOS.toLowerCase():
      return ENUM.COUNTRY_TYPE.LAOS;
    default:
      return ENUM.COUNTRY_TYPE.THAI;
  }
}

export const setCountryDataList = (type = ENUM.COUNTRY.THAI) => {
  let target = 'THAI';
  const language = getLanguageLocalStorage();
  switch (type.toLowerCase()) {
    case ENUM.COUNTRY.THAI.toLowerCase():
    case ENUM.COUNTRY_TYPE.THAI:
      switch (language) {
        case DATA.LANGUAGE.THAI:
          target = 'THAI';
          break;
        default:
          target = 'THAI_EN';
          break;
      }
      break;
    case ENUM.COUNTRY.MALAYSIA.toLowerCase():
    case ENUM.COUNTRY_TYPE.MALAYSIA:
      target = 'MALAYSIA';
      break;
    case ENUM.COUNTRY.VIETNAM.toLowerCase():
    case ENUM.COUNTRY_TYPE.VIETNAM:
      target = 'VIETNAM';
      break;
    case ENUM.COUNTRY.INDONESIA.toLowerCase():
    case ENUM.COUNTRY_TYPE.INDONESIA:
      target = 'INDONESIA';
      break;
    default:
      target = 'THAI';
      break;
  }
  const countryList = _.sortBy(COUNTRY[target].PROVINCE_LIST, 'province').map(e => {
    e.cityList = COUNTRY[target].CITY_LIST.filter(ec => ec.provinceCode === e.provinceCode).map(ec => {
      ec.value = ec.cityCode;
      ec.label = ec.city;
      return ec
    });
    e.cityList = _.sortBy(e.cityList, 'city');
    e.value = e.provinceCode;
    e.label = e.province;
    return e;
  })
  console.log('countryList', countryList, type)
  storeDispatch(dataCreators.setDataRedux(DATA_REDUX_STATE_KEY.COUNTRY_LIST, countryList));
}

export const getCountryDataList = () => {
  return storeGetState().dataRedux.countryList;
}

export const getCityListByProvince = (provinceValue) => {
  let countryList = getCountryDataList();
  let cityList = [];
  if (provinceValue) {
    let province = countryList.filter(e => e.provinceCode.toString() === provinceValue.toString())[0];
    if (province) {
      cityList = province.cityList;
    }
  }
  return cityList;
}

export const getSubDistrictList = (provinceValue, cityValue) => {
  let list = [];
  if (provinceValue) {
    let cityList = getCityListByProvince(provinceValue);
    if (cityValue) {
      const cityData = cityList.filter(e => e.cityCode.toString() === cityValue.toString())[0];
      if (cityData) {
        list = cityData.subDistrict.map(e => {
          return {
            ...e,
            label: e.name,
            value: e.name,
          }
        });
      }
    }
  }
  return list;
}

export const getSubDistrictValueLanguage = (provinceValue, cityValue, subDistrict) => {
  const subDistrictList = getSubDistrictList(provinceValue, cityValue);
  let result = subDistrict;

  const subDistrictNameList = subDistrictList.map(e => e.value);
  if (subDistrictNameList.indexOf(subDistrict) === -1) {
    let findSubDistrictId;

    [...COUNTRY.THAI.CITY_LIST, ...COUNTRY.THAI_EN.CITY_LIST].forEach(e => {
      if (e.cityCode.toString() === cityValue.toString()) {
        e.subDistrict.forEach(es => {
          if (es.name === subDistrict) {
            findSubDistrictId = es.id;
          }
        });
      }
    });

    [...COUNTRY.THAI.CITY_LIST, ...COUNTRY.THAI_EN.CITY_LIST].forEach(e => {
      if (e.cityCode.toString() === cityValue.toString()) {
        e.subDistrict.forEach(es => {
          if (es.id === findSubDistrictId && es.name !== subDistrict) {
            result = es.name;
          }
        });
      }
    });
  }
  return result;
}

export const getPostCodeList = (provinceValue, cityValue, subDistrictValue) => {
  let list = [];
  if (provinceValue && cityValue) {
    let subDistrictList = getSubDistrictList(provinceValue, cityValue);
    if (subDistrictValue) {
      const subDistrictData = subDistrictList.filter(e => e.name.toString() === subDistrictValue.toString())[0];
      if (subDistrictData) {
        list = subDistrictData.postCode.map(e => {
          return {
            label: e,
            value: e,
          }
        });
      }
    }
  }
  return list;
}

export const getTodayDateDiff = (date, type = 'day') => {
  return moment().diff(date, type)
}

export const getCurrentTimeStamp = () => {
  return moment().unix() * 1000;
}

export const getAdminRoleByCountryType = () => {
  const userAuth = getUserAuth();
  const addressType = getCountryType(userAuth.countries);
  switch (addressType) {
    case ENUM.COUNTRY_TYPE.THAI:
      return ENUM.ADMIN_ROLE.TH;
    case ENUM.COUNTRY_TYPE.MALAYSIA:
      return ENUM.ADMIN_ROLE.ML;
    case ENUM.COUNTRY_TYPE.VIETNAM:
      return ENUM.ADMIN_ROLE.IN;
    case ENUM.COUNTRY_TYPE.INDONESIA:
      return ENUM.ADMIN_ROLE.VI;
    case ENUM.COUNTRY_TYPE.LAOS:
      return ENUM.ADMIN_ROLE.TH;
    default:
      return ENUM.ADMIN_ROLE.TH;
  }
}

export const getAvailableCountryDataList = (list = []) => {
  const userAuth = getUserAuth();
  const addressType = getCountryType(userAuth.countries);
  console.log('getAvailableCountryDataList', addressType)
  return list.filter(e => e.available_country !== null && e.available_country.indexOf(addressType) >= 0);
}

export const getCountryByLanguage = (data) => {
  let language = getLanguageLocalStorage();
  if (data) {
    language = data;
  }
  let countries = ENUM.COUNTRY.THAI;
  switch (language) {
    case DATA.LANGUAGE.THAI:
      countries = ENUM.COUNTRY.THAI;
      break;
    case DATA.LANGUAGE.INDONESIA:
      countries = ENUM.COUNTRY.INDONESIA;
      break;
    case DATA.LANGUAGE.MALAYSIA:
      countries = ENUM.COUNTRY.MALAYSIA;
      break;
    case DATA.LANGUAGE.LAOS:
      countries = ENUM.COUNTRY.LAOS;
      break;
    case DATA.LANGUAGE.VIETNAM:
      countries = ENUM.COUNTRY.VIETNAM;
      break;
    default:
      countries = ENUM.COUNTRY.THAI;
      break;
  }
  return countries
}

export const generateUUID = () => { // Public Domain/MIT
  var d = new Date().getTime();//Timestamp
  var d2 = ((typeof performance !== 'undefined') && performance.now && (performance.now() * 1000)) || 0;//Time in microseconds since page-load or 0 if unsupported
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    var r = Math.random() * 16;//random number between 0 and 16
    if (d > 0) {//Use timestamp until depleted
      r = (d + r) % 16 | 0;
      d = Math.floor(d / 16);
    } else {//Use microseconds since page-load if supported
      r = (d2 + r) % 16 | 0;
      d2 = Math.floor(d2 / 16);
    }
    return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
  });
}

export const convertString = (data) => {
  return data === 'undefined' || data === 'null' ? '' : data || '';
};

export const setUerAuthLocalStorage = (data) => {
  removeLocalStorage(LOCAL_STORAGE.REGISTER_INFO);
  const phonePrefix = getCountryPhoneByCountry(data.countries ? data.countries : ENUM.COUNTRY.THAI);
  const userAuth = {
    id: data.id,
    email: data.email,
    firstName: data.first_name,
    lastName: data.last_name,
    nickName: data.nick_name || data.nick_name__c,
    phonePrefix,
    phone: data.phone,
    profileImage: data.image_url,
    countries: data.countries ? data.countries : ENUM.COUNTRY.THAI,
    type: data.type,
    displayType: getCustomerTypeText(data.type),
    road: data.road,
    houseNumber: data.house_number,
    postCode: data.post_code,
    accountType: data.account_type,
    address: data.address,
    birthDate: data.birth_date,
    lineId: data.line_id,
    isTrained: !!data.trained__c,
    trainedTier__c: data.trained_tier__c,
    sfid: data.sfid,
    fullname: `${data.first_name} ${data.last_name}`,
    customerId: data.customer_id,
    // ad_ref_id
    locationAccount: data.location_account__c,
    uid: data.uid,
    company: data.company || '',
  }
  if ((userAuth.trainedTier__c || '').toLowerCase().indexOf(ENUM.CUSTOMER_TRAINED_TIER.GOLD.TEXT.toLowerCase()) >= 0) {
    userAuth.trainedTier__c = ENUM.CUSTOMER_TRAINED_TIER.GOLD.TEXT;
  }
  console.log('userAuth', userAuth)
  setLocalStorage(LOCAL_STORAGE.REGISTER_INFO, userAuth);
}

export const getCampaignData = () => {
  return storeGetState().dataRedux.campaignData;
}

export const handleSortParams = (currentParams, newParams) => {
  if (currentParams.page === newParams.page) {
    if (currentParams.sort === newParams.sort) {
      const sortList = newParams.sort.split(',');
      let sortResultList = [];
      sortList.forEach(e => {
        const sort = e.trim();
        const sortParamsList = sort.split(' ');
        if (sortParamsList.length > 1) {
          sortResultList.push(sortParamsList[0]);
        }
        else {
          sortResultList.push(`${sort} desc`);
        }
      });
      newParams.sort = sortResultList.join(', ');
    }
  }
  return newParams;
}

export const handleTrimParams = (data = {}) => {
  Object.keys(data).forEach(key => {
    if (typeof data[key] === typeof '') {
      data[key] = data[key].trim();
    }
  })
  return data;
}

export const getIsLanguageTH = () => {
  const language = getLanguageLocalStorage();
  const isLanguageTH = language === DATA.LANGUAGE.THAI;
  return isLanguageTH
}

export const getSeeAnnouncementLocalStorage = () => {
  return getLocalStorage(LOCAL_STORAGE.LUCKY_DRAW_SEE_ANNOUNCEMENT) || {}
}

export const setSeeAnnouncementLocalStorage = (data) => {
  return setLocalStorage(LOCAL_STORAGE.LUCKY_DRAW_SEE_ANNOUNCEMENT, data)
}