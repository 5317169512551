import apiService from '../apiService'

export const luckyDrawController = (configService = {}) => {
  const service = apiService(configService)
  return {
    luckyDrawCurrent: () => {
      return service.get(`/api/lucky-draw/current`)
    },
    luckyDrawCurrentDetail: () => {
      return service.get(`/api/lucky-draw/current/detail`)
    },
    luckyDrawTNCCurrent: (params) => {
      return service.post(`/api/lucky-draw/tnc/current`, params)
    },
    luckyDrawTNCCurrentAccept: (params) => {
      return service.post(`/api/lucky-draw/tnc/current/accept`, params)
    },
    luckyDrawRedemption: (params) => {
      return service.post(`/api/lucky-draw/redemption`, params)
    },
    luckyDrawAnnouncement: (params) => {
      return service.post(`/api/lucky-draw/announcement`, params)
    },
    luckyDrawAnnouncementDetail: (params = {}) => {
      return service.get(`/api/lucky-draw/announcement/detail/${params.id}`)
    },
    luckyDrawExpirePoint: (params) => {
      return service.get(`/api/lucky-draw/expire/${params}`)
    }
  }
}

