import React from 'react'
import ClassNames from 'classnames'
import PropTypes from 'prop-types'
import {
  ImageBaseWrapper
} from './styled'
// import {
//   default as VARIABLES
// } from './../../../themes/styles/bases/variables'
// import {
//   default as TYPOGRAPHYS
// } from './../../../themes/styles/bases/typographys'

/**
 * ImageBase description:
 * - base component Image
 */

export class ImageBase extends React.PureComponent {
  render() {
    const {
      // Option
      className,
      // Default
      top,
      left,
      widthRatio,
      widthRatioUnit,
      heightRatio,
      heightRatioUnit,
      borderRadius,
      src,
      alt,
      contain,
      fill,
      onClick
    } = this.props

    return (
      <ImageBaseWrapper
        className={ClassNames(
          'image-base',
          { 'is-contain': contain },
          { 'is-fill': fill },
          { 'is-cursor-pointer': onClick },
          // Option
          className
        )}
        theme={{
          // Default
          top,
          left,
          widthRatio,
          widthRatioUnit,
          heightRatio,
          heightRatioUnit,
          borderRadius
        }}
        onClick={onClick}
      >
        <img className='image-ratio-base'
          src={src}
          alt={alt}
        />
      </ImageBaseWrapper>
    )
  }

  static defaultProps = {
    // Option
    // className: undefined,
    // Default
    widthRatio: '100',
    widthRatioUnit: '%',
    heightRatio: '57',
    heightRatioUnit: '%'
  }

  static propTypes = { // TYPE > node, string, number, bool, array, object, symbol, func
    /**
    * Additional classes
    */
    className: PropTypes.string
  }
}
