import React from 'react';
import { connect } from 'react-redux';
import {
  Modal,
  Section,
  Text,
  Button,
  Image,
  NavigationBar,
  Grid,
} from '../../../components';
import { ICONS, VARIABLES, TYPOGRAPHYS } from '../../../themes';
import { getLanguage } from '../../../helpers/functions/language';
import { DATA } from '../../../helpers/constants/data';
import { displayWithComma } from '../../../helpers/functions/display';
import { redirect, ROUTE_PATH } from '../../../helpers';

export class ModalExpriePointContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { isShowModal, onClickClose, dataList, summaryPoint } = this.props;
    const { } = this.state;
    return (
      <Modal
        open={isShowModal}
        width={414}
        className='is-full-page'
        overlayColor={VARIABLES.COLORS.OVERLAY_3}
        // spacing={VARIABLES.MODAL.CONTAINER.C_1.SPACING}
        transiti
        on={VARIABLES.TRANSITIONS.DEFAULT}
        transformStart={VARIABLES.MODAL.CONTAINER.C_1.TRANSFORM_START}
        transformEnd={VARIABLES.MODAL.CONTAINER.C_1.TRANSFORM_END}
        onClickClose={onClickClose}
      >
        <Modal.Section>
          <React.Fragment>
            <NavigationBar
              shadow
              pageTitleIcon={getLanguage('luckyDraw.expirePoint')}
              actionLeft={
                <Image
                  widthRatio='24'
                  widthRatioUnit='px'
                  heightRatio='24'
                  heightRatioUnit='px'
                  src={ICONS['ic-close-black.svg']}
                  onClick={onClickClose}
                />
              }
            />
            <Section
              // absolute
              fluid
              className='is-footer-expire-luckydraw'
              height={81}
              justify='center'

              align='center'
            >
              <Section align='center' justify='center'>
                <Grid align='center' gutter='20' justify='center'>
                  <Grid.Column>
                    <Section align='center' textAlign='center'>
                      <Text
                        fontStyle={TYPOGRAPHYS.FIRST.BOLD.FS_16}
                        color={VARIABLES.COLORS.WHITE}
                      >
                        {getLanguage('luckyDraw.youHaveAllThePoint')}
                      </Text>
                    </Section>
                  </Grid.Column>
                  <Grid.Column>
                    <Text
                      fontStyle={TYPOGRAPHYS.FIRST.BOLD.FS_36}
                      color={VARIABLES.COLORS.WHITE}
                    >
                      {displayWithComma(summaryPoint || 0)}
                    </Text>
                  </Grid.Column>
                  <Grid.Column>
                    <Text
                      fontStyle={TYPOGRAPHYS.FIRST.MEDIUM.FS_24}
                      color={VARIABLES.COLORS.WHITE}
                    >
                      {getLanguage('luckyDraw.points')}
                    </Text>
                  </Grid.Column>
                </Grid>
              </Section>
            </Section>
            <Section
              scroll
              paddingHorizontal='32'
              justify='center'
              spacingTop='22'
            >
              <Section spacingBottom='16'>
                <Text
                  color={VARIABLES.COLORS.BLACK}
                  fontStyle={TYPOGRAPHYS.FIRST.BOLD.FS_20}
                >
                  {getLanguage('luckyDraw.toExpire')}
                </Text>
              </Section>
              <Section
                bgColor={VARIABLES.COLORS.PRIMARY_2}
                spacingBottom='8'
                fluid
                borderRadius='20'
                paddingHorizontal='20'
                paddingVertical='11'
              >
                <Grid justify='space-between' align='center'>
                  <Grid.Column>
                    <Text
                      color={VARIABLES.COLORS.WHITE}
                      fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_16}
                    >
                      {dataList[0].displayExpireDate}
                    </Text>
                  </Grid.Column>
                  <Grid.Column>
                    <Grid gutter='15' align='center'>
                      <Grid.Column>
                        <Text
                          color={VARIABLES.COLORS.WHITE}
                          fontStyle={TYPOGRAPHYS.FIRST.MEDIUM.FS_20}
                        >
                          {displayWithComma(
                            dataList[0].point >= 0 ? dataList[0].point : 0
                          )}
                        </Text>
                      </Grid.Column>
                      <Grid.Column>
                        <Text
                          color={VARIABLES.COLORS.WHITE}
                          fontStyle={TYPOGRAPHYS.FIRST.MEDIUM.FS_16}
                        >
                          {getLanguage('luckyDraw.points')}
                        </Text>
                      </Grid.Column>
                    </Grid>
                  </Grid.Column>
                </Grid>
              </Section>
              <Section paddingHorizontal='27' spacingBottom='16'>
                <Section spacingBottom='8'>
                  <Text
                    color={VARIABLES.COLORS.RED_4}
                    fontStyle={TYPOGRAPHYS.FIRST.BOLD.FS_12}
                  >
                    {getLanguage('luckyDraw.dontWasteYourPoint')}
                  </Text>
                </Section>
                <Button
                  fluid
                  className='is-gradient-blue'
                  borderWidth='1'
                  borderStyle='solid'
                  borderColor={VARIABLES.COLORS.BLUE_2}
                  shadow
                  height={VARIABLES.BUTTON.HEIGHTS.H_40}
                  borderRadius={VARIABLES.BUTTON.BORDER_RADIUSES.BR_10}
                  onClick={() => {
                    redirect(ROUTE_PATH.WIN_PRIZES.LINK);
                  }}
                >
                  <Text
                    fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_16}
                    color={VARIABLES.COLORS.WHITE}
                  >
                    {getLanguage('luckyDraw.buttonGoToWinPrizes')}
                  </Text>
                </Button>
              </Section>
              <Section scroll calcHeight={'100vh - 372px'}>
                {dataList.map((e, i) => {
                  return (
                    <Section spacingBottom='16' key={i}>
                      {i >= 1 && (
                        <Section
                          bgColor={VARIABLES.COLORS.WHITE}
                          fluid
                          borderRadius='20'
                          paddingHorizontal='20'
                          paddingVertical='11'
                          borderWidth='1'
                          borderStyle='solid'
                          borderColor={VARIABLES.COLORS.GRAY_1}
                        >
                          <Grid justify='space-between' align='center'>
                            <Grid.Column>
                              <Text
                                color={VARIABLES.COLORS.PRIMARY_2}
                                fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_16}
                              >
                                {e.displayExpireDate}
                              </Text>
                            </Grid.Column>
                            <Grid.Column>
                              <Grid gutter='15' align='center'>
                                <Grid.Column>
                                  <Text
                                    color={VARIABLES.COLORS.PRIMARY_2}
                                    fontStyle={TYPOGRAPHYS.FIRST.MEDIUM.FS_20}
                                  >
                                    {displayWithComma(
                                      e.point >= 0 ? e.point : 0
                                    )}
                                  </Text>
                                </Grid.Column>
                                <Grid.Column>
                                  <Text
                                    color={VARIABLES.COLORS.PRIMARY_2}
                                    fontStyle={TYPOGRAPHYS.FIRST.MEDIUM.FS_16}
                                  >
                                    {getLanguage('luckyDraw.points')}
                                  </Text>
                                </Grid.Column>
                              </Grid>
                            </Grid.Column>
                          </Grid>
                        </Section>
                      )}
                    </Section>
                  );
                })}
              </Section>
            </Section>

          </React.Fragment>
        </Modal.Section>
      </Modal>
    );
  }
}
