import {
  default as VARIABLES
} from './../../../themes/styles/bases/variables' // Use relative path for React Styleguidist

// //////////////////////////////////////////////////////////////////////////////////////////////////
// ==================================================================================================
//
// Mixins
// * Placeholder
// * Elllipsis
// * Scrollbar custom style
//
// Using default:
// ${MIXINS.PLACEHOLDER({})};
//
// Using parameters:
// ${MIXINS.PLACEHOLDER({
//   color: 'green'
// })};
//
// ==================================================================================================
// //////////////////////////////////////////////////////////////////////////////////////////////////

export default {
  // Placeholder
  // ==================================================================================================
  PLACEHOLDER: ({
    color = VARIABLES.COLORS.TEXT_PLACEHOLDER
  }) => {
    return `
      &::-moz-placeholder {
        color: ${color};
        opacity: 1;
      }

      &:-ms-input-placeholder {
        color: ${color};
      }

      &::-webkit-input-placeholder {
        color: ${color};
      }
    `
  },

  // Ellipsis
  // ==================================================================================================
  ELLIPSIS: ({
    display = 'inline-block',
    maxWidth = '100%'
  }) => {
    return `
      display: ${display};
      max-width: ${maxWidth};
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      word-wrap: normal;
    `
  },

  // Scrollbar custom style (Support browser ref: https://caniuse.com/#feat=css-scrollbar)
  // ==================================================================================================
  SCROLLBAR_CUSTOM_STYLE: ({
    scrollBarWidth = VARIABLES.SCROLLBAR.WIDTH,
    scrollBarHeight = VARIABLES.SCROLLBAR.HEIGHT,
    scrollBarBorderRadius = VARIABLES.SCROLLBAR.BORDER_RADIUS,
    scrollBarThumbColorDefault = VARIABLES.COLORS.SCROLLBAR_DEFAULT,
    scrollBarThumbColorHover = VARIABLES.COLORS.SCROLLBAR_HOVER,
    scrollBarThumbColorActive = VARIABLES.COLORS.SCROLLBAR_ACTIVE,
    scrollBarTrackColorDefault = VARIABLES.COLORS.SCROLLBAR_DEFAULT,
    scrollBarTrackColorHover = VARIABLES.COLORS.SCROLLBAR_HOVER,
    scrollBarTrackColorActive = VARIABLES.COLORS.SCROLLBAR_ACTIVE
  }) => {
    return `
      &::-webkit-scrollbar {
        width: ${scrollBarWidth + `px`};
        height: ${scrollBarHeight + `px`};
      }

      &::-webkit-scrollbar-button {
        width: 0;
        height: 0;
      }

      &::-webkit-scrollbar-thumb {
        background: ${scrollBarThumbColorDefault};
        border: 0;
        border-radius: ${scrollBarBorderRadius + `px`};

        &:hover {
          background: ${scrollBarThumbColorHover};
        }

        &:active {
          background: ${scrollBarThumbColorActive};
        }
      }

      &::-webkit-scrollbar-track {
        background: ${scrollBarTrackColorDefault};
        border: 0;
        border-radius: ${scrollBarBorderRadius + `px`};

        &:hover {
          background: ${scrollBarTrackColorHover};
        }

        &:active {
          background: ${scrollBarTrackColorActive};
        }
      }

      &::-webkit-scrollbar-corner {
        background: transparent;
      }
    `
  }
}
