import React from 'react'
import _ from 'lodash'
import moment from 'moment'
import axios from 'axios'
import {
  TransitionContainer,
  Container,
  Section,
  Image,
  Text,
  NavigationBar,
  NewsAndPromotionsList,
  Field,
} from './../../components'
import {
  VARIABLES,
  TYPOGRAPHYS,
  ICONS,
} from './../../themes'
import {
  ROUTE_PATH,
  redirect,
  formatDatetime,
  diffDays,
} from './../../helpers'
import config from './../../config'
import { getLanguage } from '../../helpers/functions/language'
import { LOCAL_STORAGE } from '../../helpers/constants/localStorage'
import { getCountryType } from '../../helpers/functions/data'

export class NewsPromotionsSearchPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      id: (this.props.match.params && this.props.match.params.id) ? this.props.match.params.id : 1,
      isLoading: false,
      toggleSearch: false,
      keyword: '',
      list: [],
    }
    this.timer = null
  }

  // setToggleSearch() {
  //   this.setState({ toggleSearch: true })
  // }

  onChangeKeyword(event) {
    const keyword = event.target.value
    // this.setToggleSearch()
    this.setState({ keyword })
    if (keyword) {
      clearTimeout(this.timer)
      this.timer = setTimeout(() => {
        this.setState({ isLoading: true }, async () => {
          try {
            let a = JSON.parse(localStorage.getItem(LOCAL_STORAGE.REGISTER_INFO));
            const addressType = getCountryType(a.countries);

            const url = `${config.api.cms}/newspromotion/search/`
            const options = { 
              subject: keyword, 
              sort: 'public_date desc', 
              status: 'active', 
              app: 'mobileapp',
              available_country: addressType
            }
            const response = await axios.post(url, options)
            let data = _.get(response, 'data.data')
        
            // data = data.filter(e => e.available_country.indexOf(addressType) >= 0);
            if (response.status === 200) {
              this.setState({ list: data })
              const storage = JSON.parse(localStorage.getItem('newsPromotionList'))
              const newsPromotionList = _.uniqBy(storage.concat(data), 'id')
              localStorage.setItem('newsPromotionList', JSON.stringify(newsPromotionList))
            }
          } catch (_) {
          } finally {
            this.setState({ isLoading: false })
          }
        })
      }, 500)
    } else {
      this.resetData()
    }
  }

  resetData() {
    this.setState({ keyword: '', list: [] })
  }

  onClickClearKeyword() {
    this.resetData()
  }

  handleBack() {
    redirect(ROUTE_PATH.NEWS_PROMOTIONS.LINK)
  }

  showLabel(now, publicDate) {
    if (!publicDate || !now) return
    const diff = diffDays(now, publicDate)
    return diff <= 7
  }

  render() {
    const { keyword, list } = this.state
    const now = moment()
    return (
      <TransitionContainer>
        <Container
          width={VARIABLES.WEBSITE_WIDTH}
          calcMinHeight='100vh'
          bgColor={VARIABLES.COLORS.LIGHT_BLUE_2}
        >
          <NavigationBar
            shadow
            pageTitle={getLanguage('newsAndPromotionsSearch.title')}
            actionLeft={
              <Image
                widthRatio='11'
                widthRatioUnit='px'
                heightRatio='21'
                heightRatioUnit='px'
                src={ICONS['ic-left-black.svg']}
                onClick={this.handleBack.bind(this)}
              />
            }
          />
          <Section
            scroll
            calcHeight='100vh - 70px'
            paddingTop='25'
            paddingBottom='25'
            paddingHorizontal='20'
          >
            <Section spacingBottom='30'>
              <Field
                fluid
                height='40'
                placeholder={getLanguage('field.newsAndPromotionsPlaceholder')}
                placeholderColor={VARIABLES.COLORS.LIGHT_BLUE_3}
                paddingRight='10'
                paddingLeft='50'
                bgColor={VARIABLES.COLORS.TRANSPARENT}
                borderWidth='1'
                borderStyle='solid'
                borderColor={VARIABLES.COLORS.BLUE_2}
                borderRadius='20'
                fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_14}
                fontColor={VARIABLES.COLORS.BLUE_2}
                fieldIcon={
                  <Image
                    widthRatio='17'
                    widthRatioUnit='px'
                    heightRatio='17'
                    heightRatioUnit='px'
                    src={ICONS['ic-search-light-blue.svg']}
                  />
                }
                fieldIconSpacingLeft='20'
                value={keyword}
                onChange={this.onChangeKeyword.bind(this)}
              />
            </Section>
            <Section spacingBottom='25'>
              <Text
                fontStyle={TYPOGRAPHYS.FIRST.BOLD.FS_14}
                color={VARIABLES.COLORS.BLACK}
              >
                {getLanguage('newsAndPromotionsSearch.searchResult')} {list.length}
              </Text>
            </Section>
            {!_.isEmpty(list) && list.map((data, index) => (
              <NewsAndPromotionsList
                key={index}
                label={this.showLabel(now, data.public_date) ? getLanguage('field.labelNew') : ''}
                coverImage={`${config.imgPrefix}/${(data.cover) ? data.cover : _.get(data, 'images.0')}`}
                title={data.subject}
                datetime={formatDatetime(data.public_date || data.create_at, 'DD MMM, YY - H:mm')}
                description={(data.content || '').replace(/<\/?[^>]+(>|$)/g, " ")}
                onClick={() => redirect(`${ROUTE_PATH.NEWS_PROMOTIONS_DETAIL.LINK}/${data.id}`)}
              />
            ))}
            {/* <NewsAndPromotionsList
              label='NEW'
              coverImage={CONTENTS['news-3.jpg']}
              title='The Prime Minister’s Ind Lorem ipsum, dolor sit amet consectetur adipisicing elit. Nisi esse itaque laudantium consequatur odit, totam at quod eius recusandae non ratione ipsum atque molestias. Tenetur dolores saepe maiores modi voluptates!'
              datetime='20 Oct, 2018 - 13:10'
              description='According to the five steel companies launched Kon Lek Lorem ipsum, dolor sit amet consectetur adipisicing elit. Nisi esse itaque laudantium consequatur odit, totam at quod eius recusandae non ratione ipsum atque molestias. Tenetur dolores saepe maiores modi voluptates!'
              onClick={() => redirect(ROUTE_PATH.NEWS_PROMOTIONS_DETAIL.LINK)}
            /> */}
          </Section>
        </Container>
      </TransitionContainer>
    )
  }
}
