import React from 'react'
import ClassNames from 'classnames'
import PropTypes from 'prop-types'
import Pagination from 'react-js-pagination'
import {
  PaginationControlBaseWrapper
} from './styled'
// import {
//   default as VARIABLES
// } from './../../../themes/styles/bases/variables'
// import {
//   default as TYPOGRAPHYS
// } from './../../../themes/styles/bases/typographys'

/**
 * Pagination description:
 * - Pagination UI control
 * - https://github.com/vayser/react-js-pagination
 */

export class PaginationControlBase extends React.PureComponent {
  render () {
    const {
      className,
      children,
      innerClass,
      itemClass,
      itemClassNext,
      itemClassPrev,
      itemClassFirst,
      itemClassLast,
      activeClass,
      disabledClass,
      firstPageText,
      lastPageText,
      activePage,
      itemsCountPerPage,
      totalItemsCount,
      pageRangeDisplayed,
      onChange
    } = this.props

    // props for css classes
    const classes = ClassNames(
      'pagination-control-base',
      className
    )

    return (
      <PaginationControlBaseWrapper
        className={classes}
      >
        <div className='pagination-control-info-base'></div>
        <Pagination
          innerClass={innerClass}
          itemClass={itemClass}
          itemClassPrev={itemClassPrev}
          itemClassNext={itemClassNext}
          itemClassFirst={itemClassFirst}
          itemClassLast={itemClassLast}
          activeClass={activeClass}
          disabledClass={disabledClass}
          firstPageText={firstPageText}
          lastPageText={lastPageText}
          activePage={activePage}
          itemsCountPerPage={itemsCountPerPage}
          totalItemsCount={totalItemsCount}
          pageRangeDisplayed={pageRangeDisplayed}
          onChange={onChange}
        />
        {children}
      </PaginationControlBaseWrapper>
    )
  }

  static defaultProps ={
    innerClass: 'pagination-control-container-base',
    itemClass: 'pagination-control-item-base',
    itemClassPrev: 'is-prev',
    itemClassNext: 'is-next',
    itemClassFirst: 'is-first',
    itemClassLast: 'is-last',
    activeClass: 'is-active',
    disabledClass: 'is-disabled',
    firstPageText: 'First',
    lastPageText: 'Last'
  }

  static propTypes = { // TYPE > node, string, number, bool, array, object, symbol, func
    /**
    * Additional classes
    */
    className: PropTypes.string,

    /**
    * Additional elements or text
    */
    children: PropTypes.oneOfType([
      PropTypes.node,
      PropTypes.string
    ]),

    /**
    * Modifier name for change default multiple UI (parent and children)
    */
    // ui: PropTypes.oneOf([]),

    /**
    * Modifier name for customize pagination container style
    */
    innerClass: PropTypes.string,

    /**
    * Modifier name for customize pagination item(button) style
    */
    itemClass: PropTypes.string,

    /**
    * Modifier name for customize pagination next item(button) style
    */
    itemClassNext: PropTypes.string,

    /**
    * Modifier name for customize pagination previous item(button) style
    */
    itemClassPrev: PropTypes.string,

    /**
    * Modifier name for customize pagination first item(button) style
    */
    itemClassFirst: PropTypes.string,

    /**
    * Modifier name for customize pagination last item(button) style
    */
    itemClassLast: PropTypes.string,

    /**
    * Modifier name for customize pagination active item(button) style
    */
    activeClass: PropTypes.string,

    /**
    * Modifier name for customize pagination disabled item(button) style
    */
    disabledClass: PropTypes.string,

    /**
    * Pagination first item(button) name
    */
    firstPageText: PropTypes.string,

    /**
    * Pagination last item(button) name
    */
    lastPageText: PropTypes.string,

    /**
    * Pagination active page number
    */
    activePage: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.array,
      PropTypes.object,
      PropTypes.func
    ]),

    /**
    * Pagination items count per page number
    */
    itemsCountPerPage: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.array,
      PropTypes.object,
      PropTypes.func
    ]),

    /**
    * Pagination total items number
    */
    totalItemsCount: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.array,
      PropTypes.object,
      PropTypes.func
    ]),

    /**
    * Pagination page range number
    */
    pageRangeDisplayed: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number
    ]),

    /**
    * Pagination function
    */
    onChange: PropTypes.func
  }
}
