import React from 'react'
import {
  TransitionContainer,
  Container,
  Image,
  Section,
  Text,
  Button,
} from './../../components'
import {
  VARIABLES,
  TYPOGRAPHYS,
  CONTENTS,
  ICONS,
} from './../../themes'
import {
  ROUTE_PATH,
  redirect,
} from './../../helpers'
import { LOCAL_STORAGE } from '../../helpers/constants/localStorage';
import { handleWatchNoti } from '../../helpers/firebases/firebaseNotification';
import { getLanguage } from '../../helpers/functions/language';
import config from '../../config';
import axios from 'axios';
import { isValidResponse, redirectReplace, getRouteRefreshWithAuthApp, getRouteParams } from '../../helpers'
import { getLocalStorage, removeLocalStorage, setLocalStorage } from '../../helpers/functions/localStorage';
import { isShowLoading, onOpenAlert } from '../../helpers/functions/main';
import { setUerAuthLocalStorage } from '../../helpers/functions/data';
import { DATA } from '../../helpers/constants/data';
import { handleUpdateOTPAuth } from '../../helpers/firebases/firebaseOTPAuth';

export class ReferenceCodePage extends React.Component {
  constructor(props) {
    super(props);
    const refCode = getRouteParams(props, 'refCode') || '';
    console.log('location.search', props.location.search);
    const params = new URLSearchParams(props.location.search);
    const data = params.get('data');
    console.log('params', params, data);
    this.state = {
      isAuthPass: false,
      data,
      refCode,
    };
  }

  componentDidMount() {
    this.referenceCode.checkAuthLoginPass();
  }

  referenceCode = {
    checkAuthLoginPass: () => {
      const {
        data,
      } = this.state;
      if (data !== null) {
        const params = {
          data
        }
        axios.post(`${config.api.base}/api/otp/verify`, params).then((res) => {
          const data = res.data;
          if (isValidResponse(data)) {
            this.setState({
              isAuthPass: true,
            })
          }
        });
      }
    },
    // onClickBackToLogin: () => {
    //   const {
    //     ssoId,
    //   } = this.state;
    //   removeLocalStorage(LOCAL_STORAGE.REGISTER_INFO);
    //   window.location.href = getRouteRefreshWithAuthApp(`${ROUTE_PATH.LOGIN_BY_LINE_PHONE.LINK}/${ssoId}`);
    // },
    onClickApprove: async () => {
      const {
        refCode,
      } = this.state;
      isShowLoading(true);
      handleUpdateOTPAuth({
        referenceCode: refCode,
      }, (otpData) => {
        if (otpData) {
          redirectReplace(ROUTE_PATH.AUTH_SUCCESS.LINK);
        }
        else {
          onOpenAlert({
            type: DATA.ALERT_TYPE.ERROR,
            title: getLanguage('message.fail'),
            message: `Can't find reference code ${refCode} in firebase`,
          });
        }
        isShowLoading(false);
      });

      // const {
      //   phone,
      // } = this.state;
      // isShowLoading(true);
      // setLocalStorage(LOCAL_STORAGE.IS_OTP_AUTH_SUCCESS, true);
      // await axios.post(`${config.api.base}/api/users`, {
      //   phone: parseInt(phone),
      // }).then(async (responseUserSearch) => {
      //   const resUserSearch = responseUserSearch.data;
      //   if (isValidResponse(resUserSearch)) {
      //     const userData = resUserSearch.data;
      //     setUerAuthLocalStorage(userData);
      //     isShowLoading(false);
      //     window.location.href = getRouteRefreshWithAuthApp();
      //   }
      //   else {
      //     isShowLoading(false);
      //     onOpenAlert({
      //       type: DATA.ALERT_TYPE.ERROR,
      //       title: getLanguage('message.fail'),
      //       message: getLanguage('message.somethingWrong'),
      //     });
      //   }
      // }, (error) => {
      //   console.log(error);
      //   isShowLoading(false);
      //   onOpenAlert({
      //     type: DATA.ALERT_TYPE.ERROR,
      //     title: getLanguage('message.fail'),
      //     message: (error || {}).message,
      //   });
      // });
    }
  }

  render() {
    const {
      isAuthPass,
      refCode,
    } = this.state;
    return (
      <TransitionContainer>
        <Container
          width={VARIABLES.WEBSITE_WIDTH}
          height='100'
          heightUnit='vh'
          bgColor={VARIABLES.COLORS.LIGHT_BLUE_2}
          justify='center'
        >
          <Section
            spacingBottom='30'
            justify='center'
          >
            <img src={ICONS['ic-ref-code-blue.png']} width='98' height='180' alt='Icon' />
          </Section>
          <Section
            spacingBottom='10'
            justify='center'
          >
            <Text
              fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_20}
              color={VARIABLES.COLORS.GRAY_3}
              align='center'
            >
              {getLanguage('referenceCode.message_1')}
            </Text>
          </Section>
          <Section
            spacingBottom='60'
            justify='center'
          >
            <Text
              fontStyle={TYPOGRAPHYS.FIRST.MEDIUM.FS_20}
              color={isAuthPass ? VARIABLES.COLORS.BLUE_2 : VARIABLES.COLORS.RED_2}
              align='center'
            >
              {isAuthPass ? refCode : getLanguage('referenceCode.message_2')}
            </Text>
          </Section>
          <Section justify='center'>
            {isAuthPass &&
              <Button
                shadow
                className='is-gradient-blue is-icon-right'
                width={VARIABLES.BUTTON.WIDTHS.W_275}
                height={VARIABLES.BUTTON.HEIGHTS.H_40}
                borderRadius={VARIABLES.BUTTON.BORDER_RADIUSES.BR_10}
                onClick={this.referenceCode.onClickApprove}
              >
                <Button.Icon>
                  <Image
                    widthRatio='15'
                    widthRatioUnit='px'
                    heightRatio='12'
                    heightRatioUnit='px'
                    src={ICONS['ic-arrow-right.svg']}
                  />
                </Button.Icon>
                <Text
                  fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_16}
                  color={VARIABLES.COLORS.WHITE}
                >
                  {getLanguage('field.buttonApprove')}
                </Text>
              </Button>
              // :
              // <Button
              //   width={VARIABLES.BUTTON.WIDTHS.W_275}
              //   height={VARIABLES.BUTTON.HEIGHTS.H_40}
              //   bgColor={VARIABLES.COLORS.TRANSPARENT}
              //   borderRadius={VARIABLES.BUTTON.BORDER_RADIUSES.BR_10}
              //   borderWidth='1'
              //   borderStyle='solid'
              //   borderColor={VARIABLES.COLORS.BLUE_2}
              //   onClick={this.referenceCode.onClickBackToLogin}
              // >
              //   <Text
              //     fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_16}
              //     color={VARIABLES.COLORS.BLUE_2}
              //   >
              //     {getLanguage('field.buttonToLogin')}
              //   </Text>
              // </Button>
            }
          </Section>
        </Container>
      </TransitionContainer>
    )
  }
}
