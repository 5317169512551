import React from 'react';
import {
  Button,
  Container,
  Field,
  Grid,
  Image,
  Modal,
  NavigationBar,
  Section,
  SelectControl,
  Text,
  TransitionContainer,
} from './../../components';
import {
  ROUTE_PATH,
  redirect,
} from './../../helpers';
import {
  FLAGS,
  ICONS,
  TYPOGRAPHYS,
  VARIABLES
} from './../../themes';

import $ from 'jquery';
import moment from 'moment';
import { SelectCityContainerConnected } from '../../containers/SelectCityContainer';
import { SelectPostCodeContainerConnected } from '../../containers/SelectPostCodeContainer';
import { SelectProvinceContainerConnected } from '../../containers/SelectProvinceContainer';
import { SelectSubDistrictContainerConnected } from '../../containers/SelectSubDistrictContainer';
import { DATA } from '../../helpers/constants/data';
import { ENUM } from '../../helpers/constants/enum';
import { getUserAuth } from '../../helpers/functions/auth';
import { convertString, getCityListByProvince, getCountryDataList, getCountryType, loadImage, setUerAuthLocalStorage } from '../../helpers/functions/data';
import { capitalizeFirstLetter, displayUrlPath, getCustomerTypeText } from '../../helpers/functions/display';
import { getLanguage } from '../../helpers/functions/language';
import { onOpenAlert } from '../../helpers/functions/main';
import {
  isValidCountryPhoneLength,
  isValidEmail,
  isValidNumberNoPrefixZero
} from '../../helpers/functions/validate';
import { getUserById, updateUserInfo, usersUpload } from '../../helpers/services/users';
import { ModelAddress } from '../../models/ModelAddress';
import { Loader } from '../Loader';

export class MyProfilePage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      profileImage: '',
      showModalSuccess: false,
      id: '',
      firstName: '',
      lastName: '',
      nickName: '',
      phone: '',
      email: '',
      oldPhone: '',
      oldEmail: '',
      type: '',
      countries: '',
      uid: '',
      address: '',
      district: '',
      subDistrict: '',
      alley: '',
      group: '',
      street: '',
      ward: '',
      houseNumber: '',
      postCode: '',
      road: '',
      birthDate: '',
      placeholderBirthDay: true,
      company: '',
    }
  }

  componentDidMount() {
    let userAuth = getUserAuth();
    if (userAuth) {
      this.setState({
        ...this.myProfile.getUserData(userAuth),
        isShowLoading: true,
      }, () => {
        this.getInfoUser(userAuth.id, () => {
          userAuth = getUserAuth();
          // console.log('a', a)
          if (userAuth) {
            this.setState({
              ...this.myProfile.getUserData(userAuth),
              isShowLoading: false,
            });
          }
        });
      });
    }
  }

  myProfile = {
    getUserData: (a) => {
      let addressGroup = {};
      try {
        addressGroup = new ModelAddress(a.address);
      } catch { }
      return {
        id: a.id,
        firstName: a.firstName,
        lastName: a.lastName,
        nickName: a.nickName,
        phone: a.phone,
        email: convertString(a.email),
        oldPhone: a.phone,
        oldEmail: convertString(a.email),
        type: a.type === 'Hardware' ? ENUM.CUSTOMER_TYPE.HARDWARE.TYPE : a.type || '',
        countries: a.countries,
        uid: a.uid,
        profileImage: a.profileImage,
        birthDate: a.birthDate ? moment(a.birthDate).format('YYYY-MM-DD') : undefined,
        placeholderBirthDay: a.birthDate ? false : true,
        road: a.road,
        houseNumber: a.houseNumber,
        postCode: a.postCode || '',
        accountType: a.accountType,
        address: addressGroup.address || '',
        selectedProvince: addressGroup.selectedProvince,
        selectedProvinceText: addressGroup.selectedProvinceText,
        selectedCity: addressGroup.selectedCity,
        selectedCityText: addressGroup.selectedCityText,
        district: addressGroup.district || '',
        subDistrict: addressGroup.subDistrict || '',
        alley: addressGroup.alley || '',
        group: addressGroup.group || '',
        street: addressGroup.street || '',
        ward: addressGroup.ward || '',
        // addressType: addressGroup.addressType || getCountryType(a.countries),
        addressType: getCountryType(a.countries),
        company: a.company
      }
    }
  }

  handleType(event) {
    console.log('event.target.value', event.target.value);
    this.setState({
      type: event.target.value,
    });
  }

  onChangeField(key, event) {
    let value = event.target.value;
    switch (key) {
      case 'phone':
        if (value.length && !isValidNumberNoPrefixZero(value)) {
          return;
        }
      default:
        this.setState({
          [key]: value
        });
    }
  }

  getInfoUser(id, callbackSuccess = () => { }) {
    getUserById({
      id: id
    }).then(async (response) => {
      setUerAuthLocalStorage(response);
      if (callbackSuccess) {
        callbackSuccess();
      }
    }, (error) => {
      console.log(error);
      onOpenAlert({
        type: DATA.ALERT_TYPE.ERROR,
        title: getLanguage('message.fail'),
        message: (error || {}).message,
      });
    });
  }

  async saveUserInfo(data) {
    const {
      oldPhone,
      oldEmail,
    } = this.state;
    const params = {
      id: data.id,
      first_name: data.firstName,
      last_name: data.lastName,
      nick_name: data.nickName,
      type: data.type,
      profileImage: data.profileImage,
      road: data.road,
      postCode: data.postCode,
      houseNumber: data.houseNumber,
      // address: data.address,
      address_json: data.address,
      birth_date: data.birthDate,
      check_duplicate_email: true,
      company: data.company
    }
    if (data.phone !== oldPhone) {
      params.phone = data.phone;
      this.setState({
        oldPhone: data.phone
      })
    }
    if (data.email !== oldEmail) {
      params.email = data.email;
      this.setState({
        oldEmail: data.email
      })
    }
    console.log('params', params)
    this.setState({
      isShowLoading: true,
    }, async () => {
      const {
        countries,
      } = this.state;
      await updateUserInfo(params).then(async (response) => {
        if (response) {
          console.log('response', response);

          const resDataUpdate = response;
          if (resDataUpdate.message !== undefined && resDataUpdate.message !== 'success') {
            const message = resDataUpdate.message;
            this.setState({
              isShowLoading: false
            })
            onOpenAlert({
              type: DATA.ALERT_TYPE.ERROR,
              title: getLanguage('message.fail'),
              message,
            });
          }
          else {
            // const urlSearchCustomer = `${config.api.trueblue}/customer/search`;
            // const paramsSearchCustomer = {
            //   user_ref_id: data.id,
            // }
            // console.log('paramsSearchCustomer', paramsSearchCustomer)
            // const resCustomer = await axios.post(urlSearchCustomer, paramsSearchCustomer);
            // const dataList = _.get(resCustomer, 'data.data');
            // if (resCustomer.status === 200 && !_.isEmpty(dataList)) {
            //   for (let i = 0; i < dataList.length; i++) {
            //     const customerData = dataList[i];
            //     customerData.image_url = data.profileImage || '';
            //     customerData.first_name = data.firstName || '';
            //     customerData.last_name = data.lastName || '';
            //     customerData.nick_name = data.nickName || '';
            //     customerData.type = data.type || '';
            //     customerData.phone = (getCountryPhoneByCountry(countries) + data.phone) || '';
            //     customerData.email = data.email || '';
            //     customerData.date_of_birth = data.birthDate ? data.birthDate : new Date();
            //     customerData.address = data.address || '';
            //     customerData.contact_address = data.address || '';
            //     console.log('customerData', customerData);
            //     const urlUpdateCustomer = `${config.api.trueblue}/customer/${customerData.id}`;
            //     customerData.no_sms = true;
            //     await axios.put(urlUpdateCustomer, customerData);
            //   }
            // }
            this.setState({
              isShowLoading: false
            })
            // save data to localStorage
            await this.getInfoUser(data.id, () => {
              this.modal.onClickOpenModalSuccess();
            });
          }
        } else {
          this.setState({
            isShowLoading: false
          })
          alert(getLanguage('modal.editProfile.message_3'))
        }
      }, (error) => {
        this.setState({
          isShowLoading: false
        })
        console.log(error);
        onOpenAlert({
          type: DATA.ALERT_TYPE.ERROR,
          title: getLanguage('message.fail'),
          message: (error || {}).message,
        });
      });
    });
  }

  validateData() {
    const {
      countries,
      email,
      type,
      address,
      selectedProvince,
      selectedCity,
      subDistrict,
      postCode,
    } = this.state;
    // Email validation
    // if (this.state.email !== 'null') {
    // let emailValid = this.state.email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
    // if (!emailValid) return false;
    // }
    if (typeof email === typeof '' && email.length && !isValidEmail(email)) {
      onOpenAlert({
        type: DATA.ALERT_TYPE.ERROR,
        title: getLanguage('message.fail'),
        message: getLanguage('message.invalidEmailFormat'),
      });
      return false;
    }
    // Data validation
    if (this.state.firstName === '' || this.state.lastName === '') {
      onOpenAlert({
        type: DATA.ALERT_TYPE.ERROR,
        title: getLanguage('message.fail'),
        message: getLanguage('message.pleaseInputFirstnameAndLastname'),
      });
      return false;
    }
    // Number validation
    // if (String(parseInt(this.state.phone)).length !== 9) return false;
    if (!isValidCountryPhoneLength(countries, this.state.phone)) {
      onOpenAlert({
        type: DATA.ALERT_TYPE.ERROR,
        title: getLanguage('message.fail'),
        message: getLanguage('message.phoneInCorrectFormat'),
      });
      return false;
    }
    //TODO: Validation Image Profile

    // new form register
    if (countries.toLowerCase() === ENUM.COUNTRY.THAI.toLowerCase()) {
      if (
        type.length === 0 ||
        address.length === 0 ||
        selectedProvince.length === 0 ||
        selectedCity.length === 0 ||
        subDistrict.length === 0 ||
        postCode.length === 0
      ) {
        onOpenAlert({
          type: DATA.ALERT_TYPE.ERROR,
          title: getLanguage('message.fail'),
          message: getLanguage('message.pleaseInputAllRequiredField'),
        });
        return false;
      }
    }
    return true;
  }

  async handleSubmit() {
    // CheckPasswordMatch
    let dataValidation = this.validateData();
    if (dataValidation) {
      //TODO: Save into databse & Redirect to HomePage
      var data = {
        id: this.state.id,
        firstName: this.state.firstName,
        lastName: this.state.lastName,
        nickName: this.state.nickName,
        type: this.state.type,
        phone: this.state.phone,
        email: this.state.email,
        profileImage: this.state.profileImage,
        road: this.state.road,
        postCode: this.state.postCode,
        houseNumber: this.state.houseNumber,
        birthDate: this.state.birthDate,
        company: this.state.company,
        address: JSON.stringify({
          address_text: this.state.address,
          province: this.state.selectedProvince,
          city: this.state.selectedCity,
          province_text: this.state.countries.toLowerCase() === ENUM.COUNTRY.LAOS.toLowerCase() ? this.state.selectedProvince : this.state.selectedProvinceText,
          city_text: this.state.countries.toLowerCase() === ENUM.COUNTRY.LAOS.toLowerCase() ? this.state.selectedCity : this.state.selectedCityText,
          district: this.state.district,
          sub_district: this.state.subDistrict,
          alley: this.state.alley,
          group: this.state.group,
          street: this.state.street,
          ward: this.state.ward,
          address_type: this.state.addressType
        })
      };
      await this.saveUserInfo(data);
      // redirect(ROUTE_PATH.WELCOME.LINK);
    } else {
      //TODO: ALERT POPUP
      // alert(getLanguage('modal.editProfile.message_2'))
    }
  }

  modal = {
    onClickOpenModalSuccess: () => {
      this.setState({
        showModalSuccess: true
      })
    },

    onClickCloseModalSuccess: () => {
      this.setState({
        showModalSuccess: false
      })
      redirect(ROUTE_PATH.HOME.LINK);
    }
  }

  uploadImgHandler() {
    $("input[id='my_file']").click();
  }

  async uploadImgChange(event) {
    // console.log('file', event.target.files[0]);
    // let img = event.target.files[0]
    // let imgName = img.name;

    // // TODO: UPLOAD TO STORAGE OF FIREBASE
    const file = event.target.files[0];
    console.log('file', file);
    const newFile = await loadImage(file);

    // TODO: UPLOAD TO STORAGE OF FIREBASE
    // const img = event.target.files[0];
    const img = newFile;

    const formdata = new FormData()
    formdata.append('file', img)
    // console.log('data ==> ', data);
    // await axios.post(`${config.api.base}/api/users/upload`, formdata, { // receive two parameter endpoint url ,form data
    await usersUpload(formdata, { // receive two parameter endpoint url ,form data
    }).then((res) => { // then print response status
      console.log('res ==> ', res.url);
      this.setState({
        profileImage: res.url,
      })
    }, (error) => {
      console.log(error);
      onOpenAlert({
        type: DATA.ALERT_TYPE.ERROR,
        title: getLanguage('message.fail'),
        message: (error || {}).message,
      });
    });
  }

  input = {
    onChangeAddress: (select, target) => {
      const {
        countries,
      } = this.state;
      if (countries.toLowerCase() === ENUM.COUNTRY.THAI.toLowerCase()) {
        if (target === 'selectedProvince') {
          this.setState({
            selectedCity: '',
            selectedCityText: '',
            subDistrict: '',
            postCode: ''
          })
        }
        if (target === 'selectedCity') {
          this.setState({
            subDistrict: '',
            postCode: ''
          })
        }
        if (target === 'subDistrict') {
          this.setState({
            postCode: ''
          })
        }
        this.setState({
          [target]: select.value,
          [`${target}Text`]: select.value === '' ? '' : select.label,
        })
      }
      else {
        if (target === 'selectedProvince') {
          this.setState({
            selectedCity: '',
            selectedCityText: '',
          })
        }
        this.setState({
          [target]: select.value,
          [`${target}Text`]: select.value === '' ? '' : select.label,
        })
      }
    },
    onChangeDate: (ev, name) => {
      const value = ev.target.value;
      if (value) {
        this.setState({
          [name]: value
        })
      }
    }
  }

  render() {
    // console.log('state', this.state)
    const {
      profileImage,
      showModalSuccess,
      accountType,
      isShowLoading,
      selectedCity,
      selectedCityText,
      selectedProvince,
      selectedProvinceText,
      address,
      district,
      subDistrict,
      alley,
      group,
      street,
      ward,
      postCode,
      placeholderBirthDay,
      birthDate,
      countries
    } = this.state
    return (
      <TransitionContainer
      // motion='overlap-from'
      >
        <Container
          width={VARIABLES.WEBSITE_WIDTH}
          height='100'
          heightUnit='vh'
          bgColor={VARIABLES.COLORS.WHITE}
        >
          <NavigationBar
            shadow
            iconWidth='19'
            iconHeight='19'
            iconSrc={ICONS['ic-navigation-my-profile.svg']}
            pageTitleIcon={getLanguage('myProfile.title')}
            actionLeft={
              <Image
                widthRatio='11'
                widthRatioUnit='px'
                heightRatio='21'
                heightRatioUnit='px'
                src={ICONS['ic-left-black.svg']}
                onClick={() => {
                  redirect(ROUTE_PATH.HOME.LINK)
                }}
              />
            }
          />
          <Section
            scroll
            justify='center'
            calcHeight='100vh - 70px'
          >
            <Section
              width='275'
              paddingTop='40'
            >
              <Section
                spacingBottom='20'
                justify='center'
                position='relative'
              >
                <input style={{ display: 'none' }} type="file" id="my_file" onChange={this.uploadImgChange.bind(this)} />

                {profileImage ? <Image
                  circle
                  widthRatio='110'
                  widthRatioUnit='px'
                  heightRatio='110'
                  heightRatioUnit='px'
                  src={displayUrlPath(profileImage)}
                  borderRadius={55}
                  onClick={this.uploadImgHandler.bind(this)}
                /> : <Button
                  className='is-icon'
                  width={110}
                  height={110}
                  borderRadius={55}
                  bgColor={VARIABLES.COLORS.LIGHT_BLUE_1}
                >
                  <Button.Icon>
                    <Image
                      widthRatio='72'
                      widthRatioUnit='px'
                      heightRatio='62'
                      heightRatioUnit='px'
                      src={ICONS['ic-profile-blue.svg']}
                      onClick={this.uploadImgHandler.bind(this)}
                    />
                  </Button.Icon>
                </Button>}
                <Button
                  className='is-register-image-icon'
                  width='40'
                  height='40'
                  borderRadius='40'
                  bgColor={VARIABLES.COLORS.BLUE_2}
                >
                  <Button.Icon>
                    <Image
                      widthRatio='21'
                      widthRatioUnit='px'
                      heightRatio='18'
                      heightRatioUnit='px'
                      top='-1'
                      src={ICONS['ic-camera.svg']}
                      onClick={this.uploadImgHandler.bind(this)}
                    />
                  </Button.Icon>
                </Button>
              </Section>
              <Section spacingBottom='27' className='is-register-floating-label'>
                <Field
                  fluid
                  height='28'
                  placeholder={getLanguage('field.firstnamePlaceholder')}
                  placeholderColor={VARIABLES.COLORS.GRAY_5}
                  paddingHorizontal='10'
                  paddingBottom='10'
                  bgColor={VARIABLES.COLORS.TRANSPARENT}
                  borderBottomWidth='1'
                  borderBottomStyle='solid'
                  value={this.state.firstName}
                  onChange={this.onChangeField.bind(this, 'firstName')}
                  borderBottomColor={VARIABLES.COLORS.GRAY_3}
                  fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_15}
                  fontColor={VARIABLES.COLORS.BLACK}
                />
                <Text
                  className='is-floating-label'
                  fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_15}
                  color={VARIABLES.COLORS.GRAY_2}
                >
                  {getLanguage('field.firstname')}
                  <Text
                    fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_15}
                    color={VARIABLES.COLORS.RED_2}
                  >
                    *
                  </Text>
                </Text>
              </Section>
              <Section spacingBottom='27' className='is-register-floating-label'>
                <Field
                  fluid
                  height='28'
                  placeholder={getLanguage('field.lastnamePlaceholder')}
                  placeholderColor={VARIABLES.COLORS.GRAY_5}
                  paddingHorizontal='10'
                  paddingBottom='10'
                  bgColor={VARIABLES.COLORS.TRANSPARENT}
                  borderBottomWidth='1'
                  borderBottomStyle='solid'
                  onChange={this.onChangeField.bind(this, 'lastName')}
                  value={this.state.lastName}
                  borderBottomColor={VARIABLES.COLORS.GRAY_3}
                  fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_15}
                  fontColor={VARIABLES.COLORS.BLACK}
                />
                <Text
                  className='is-floating-label'
                  fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_15}
                  color={VARIABLES.COLORS.GRAY_2}
                >
                  {getLanguage('field.lastname')}
                  <Text
                    fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_15}
                    color={VARIABLES.COLORS.RED_2}
                  >
                    *
                  </Text>
                </Text>
              </Section>
              <Section spacingBottom='27' className='is-register-floating-label'>
                <Field
                  fluid
                  height='28'
                  placeholder={getLanguage('field.nicknamePlaceholder')}
                  placeholderColor={VARIABLES.COLORS.GRAY_5}
                  paddingHorizontal='10'
                  paddingBottom='10'
                  bgColor={VARIABLES.COLORS.TRANSPARENT}
                  borderBottomWidth='1'
                  borderBottomStyle='solid'
                  value={this.state.nickName}
                  onChange={this.onChangeField.bind(this, 'nickName')}
                  borderBottomColor={VARIABLES.COLORS.GRAY_3}
                  fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_15}
                  fontColor={VARIABLES.COLORS.BLACK}
                />
                <Text
                  className='is-floating-label'
                  fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_15}
                  color={VARIABLES.COLORS.GRAY_2}
                >
                  {getLanguage('field.nickname')}
                  {/* <Text
                    fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_15}
                    color={VARIABLES.COLORS.RED_2}
                  >
                    *
                  </Text> */}
                </Text>
              </Section>
              <Section spacingBottom='27' className='is-register-floating-label'>
                <Field
                  fluid
                  height='28'
                  placeholder={getLanguage('field.companyPlaceholder')}
                  placeholderColor={VARIABLES.COLORS.GRAY_5}
                  paddingHorizontal='10'
                  paddingBottom='10'
                  bgColor={VARIABLES.COLORS.TRANSPARENT}
                  borderBottomWidth='1'
                  borderBottomStyle='solid'
                  value={this.state.company}
                  onChange={this.onChangeField.bind(this, 'company')}
                  borderBottomColor={VARIABLES.COLORS.GRAY_3}
                  fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_15}
                  fontColor={VARIABLES.COLORS.BLACK}
                />
                <Text
                  className='is-floating-label'
                  fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_15}
                  color={VARIABLES.COLORS.GRAY_2}
                >
                  {getLanguage('field.company')}
                </Text>
              </Section>
              <Section spacingBottom='27' className='is-register-floating-label'>
                <Field
                  type='select'
                  fluid
                  height='32'
                  paddingRight='36'
                  paddingBottom='6'
                  paddingLeft='10'
                  bgColor={VARIABLES.COLORS.WHITE}
                  borderBottomWidth='1'
                  borderBottomStyle='solid'
                  borderBottomColor={VARIABLES.COLORS.GRAY_3}
                  fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_15}
                  fontColor={VARIABLES.COLORS.BLACK}
                  value={this.state.type}
                  fieldIcon={
                    <Image
                      widthRatio='13'
                      widthRatioUnit='px'
                      heightRatio='8'
                      heightRatioUnit='px'
                      src={ICONS['ic-arrow-select.svg']}
                    />
                  }
                  fieldIconWidth='36'
                  fieldIconSpacingRight='0'
                  onChange={this.handleType.bind(this)}
                >
                  <option value=''>{getLanguage('field.typePlaceholder')}</option>
                  {Object.values(ENUM.CUSTOMER_TYPE).map((e, i) => {
                    if (countries.toLowerCase() === ENUM.COUNTRY.MALAYSIA.toLowerCase()) {
                      if (e.TYPE === ENUM.CUSTOMER_TYPE.ROLL_FORMER.TYPE) {
                        return undefined;
                      }
                    }
                    return (
                      <option
                        key={i}
                        value={e.TYPE}
                      >
                        {getCustomerTypeText(e.TEXT)}
                      </option>
                    )
                  })}
                  {/* <option value='Fixers / Contractors / Installer / Applicator'>Fixers / Contractors / Installer / Applicator</option> */}
                  {/* old comment start */}
                  {/* <option value='Contractors'>Contractors</option>
                  <option value='Installer'>Installer</option>
                  <option value='Applicator'>Applicator</option>
                  <option value='Home'>Home</option>
                  <option value='Project Owner'>Project Owner</option>
                  <option value='Users/Delvopers'>Users/Delvopers</option>
                  <option value='Roll former'>Roll former</option>
                  <option value='Hardware Store'>Hardware Store</option>
                  <option value='Construction shop'>Construction shop</option> */}
                  {/* old comment end */}
                  {/* <option value='Hardware Store / Construction Shop'>Hardware Store / Construction Shop</option>
                  <option value='Home Owner / Project Owner'>Home Owner / Project Owner</option>
                  <option value='Roll former'>Roll former</option>
                  <option value='Others'>Others</option> */}
                </Field>
                <Text
                  className='is-floating-label'
                  fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_15}
                  color={VARIABLES.COLORS.GRAY_2}
                >
                  {getLanguage('field.type')}
                  <Text
                    fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_15}
                    color={VARIABLES.COLORS.RED_2}
                  >
                    *
                  </Text>
                </Text>
              </Section>
              <Section spacingBottom='27' className='is-register-floating-label'>
                <Field
                  fluid
                  autoFocus
                  className='is-phone-id'
                  inputTextType='tel'
                  placeholder='Insert phone number'
                  placeholderColor={VARIABLES.COLORS.GRAY_2}
                  height='28'
                  paddingBottom='10'
                  paddingLeft='82'
                  bgColor={VARIABLES.COLORS.TRANSPARENT}
                  borderBottomWidth='1'
                  borderBottomStyle='solid'
                  value={this.state.phone}
                  // disabled={true}
                  onChange={this.onChangeField.bind(this, 'phone')}
                  borderBottomColor={VARIABLES.COLORS.GRAY_3}
                  fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_15}
                  fontColor={VARIABLES.COLORS.BLACK}
                  fieldIcon={
                    <Grid gutter='10'>
                      <Grid.Column>
                        {this.state.countries.toLowerCase() === ENUM.COUNTRY.THAI.toLowerCase() ? <Image
                          widthRatio='27'
                          widthRatioUnit='px'
                          heightRatio='19'
                          heightRatioUnit='px'
                          src={FLAGS['flag-thailand.svg']}
                        /> : this.state.countries.toLowerCase() === ENUM.COUNTRY.INDONESIA.toLowerCase() ? <Image
                          widthRatio='27'
                          widthRatioUnit='px'
                          heightRatio='19'
                          heightRatioUnit='px'
                          src={FLAGS['flag-indonesia.svg']}
                        /> : this.state.countries.toLowerCase() === ENUM.COUNTRY.MALAYSIA.toLowerCase() ? <Image
                          widthRatio='27'
                          widthRatioUnit='px'
                          heightRatio='19'
                          heightRatioUnit='px'
                          src={FLAGS['flag-malaysia.svg']}
                        /> : this.state.countries.toLowerCase() === ENUM.COUNTRY.LAOS.toLowerCase() ? <Image
                          widthRatio='27'
                          widthRatioUnit='px'
                          heightRatio='19'
                          heightRatioUnit='px'
                          src={FLAGS['flag-lao.svg']}
                        /> : <Image
                          widthRatio='27'
                          widthRatioUnit='px'
                          heightRatio='19'
                          heightRatioUnit='px'
                          src={FLAGS['flag-vietnam.svg']}
                        />}
                      </Grid.Column>
                      <Grid.Column>
                        <Text
                          fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_15}
                          color={VARIABLES.COLORS.BLACK}
                        >
                          {this.state.countries.toLowerCase() === ENUM.COUNTRY.THAI.toLowerCase() ? '+66'
                            : this.state.countries.toLowerCase() === ENUM.COUNTRY.INDONESIA.toLowerCase() ? '+62'
                              : this.state.countries.toLowerCase() === ENUM.COUNTRY.MALAYSIA.toLowerCase() ? '+60'
                                : this.state.countries.toLowerCase() === ENUM.COUNTRY.LAOS.toLowerCase() ? '+856' : '+84'
                          }
                        </Text>
                      </Grid.Column>
                    </Grid>
                  }
                  fieldIconSpacingLeft='10'
                />
                <Text
                  className='is-floating-label'
                  fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_15}
                  color={VARIABLES.COLORS.GRAY_2}
                >
                  {getLanguage('field.phoneNumber')}
                  <Text
                    fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_15}
                    color={VARIABLES.COLORS.RED_2}
                  >
                    *
                  </Text>
                </Text>
              </Section>
              <Section spacingBottom='27' className='is-register-floating-label'>
                <Field
                  fluid
                  height='28'
                  placeholder={getLanguage('field.emailPlaceholder')}
                  placeholderColor={VARIABLES.COLORS.GRAY_5}
                  paddingHorizontal='10'
                  paddingBottom='10'
                  bgColor={VARIABLES.COLORS.TRANSPARENT}
                  borderBottomWidth='1'
                  borderBottomStyle='solid'
                  value={this.state.email === 'null' ? '' : this.state.email}
                  onChange={this.onChangeField.bind(this, 'email')}
                  // disabled={true}
                  borderBottomColor={VARIABLES.COLORS.GRAY_3}
                  fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_15}
                  fontColor={VARIABLES.COLORS.BLACK}
                />
                <Text
                  className='is-floating-label'
                  fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_15}
                  color={VARIABLES.COLORS.GRAY_2}
                >
                  {getLanguage('field.email')}
                </Text>
              </Section>
              <Section spacingBottom='27' className='is-register-floating-label'>
                <Field
                  fluid
                  className='is-field-float-placeholder is-calendar is-calendar-bg-white'
                  type='date'
                  height='28'
                  placeholder={getLanguage('field.birthDatePlaceholder')}
                  placeholderColor={VARIABLES.COLORS.GRAY_5}
                  paddingRight='0'
                  paddingLeft='5'
                  paddingBottom='10'
                  bgColor={VARIABLES.COLORS.TRANSPARENT}
                  borderBottomWidth='1'
                  borderBottomStyle='solid'
                  value={birthDate}
                  convertValue={birthDate ? moment(birthDate, 'YYYY-MM-DD').format('DD-MM-YYYY') : ''}
                  onChange={(ev) => {
                    this.input.onChangeDate(ev, 'birthDate');
                  }}
                  // disabled={true}
                  borderBottomColor={VARIABLES.COLORS.GRAY_3}
                  fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_15}
                  fontColor={VARIABLES.COLORS.BLACK}
                  fieldIcon={
                    <Image
                      widthRatio='15'
                      widthRatioUnit='px'
                      heightRatio='16'
                      heightRatioUnit='px'
                      src={ICONS['ic-calendar.svg']}
                    />
                  }
                  fieldIconSpacingRight='0'
                  onFocus={() => {
                    this.setState({
                      placeholderBirthDay: false
                    })
                  }}
                />
                <Text
                  className='is-floating-label'
                  fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_15}
                  color={VARIABLES.COLORS.GRAY_2}
                >
                  {getLanguage('field.birthDate')}
                </Text>
                {placeholderBirthDay &&
                  <Section className='is-floating-placeholder'>
                    {getLanguage('field.birthDatePlaceholder')}
                  </Section>
                }
              </Section>
              {/* <Section spacingBottom='27' className='is-register-floating-label'>
                <Field
                  fluid
                  height='28'
                  placeholder='Insert house number'
                  placeholderColor={VARIABLES.COLORS.GRAY_5}
                  paddingHorizontal='10'
                  paddingBottom='10'
                  bgColor={VARIABLES.COLORS.TRANSPARENT}
                  borderBottomWidth='1'
                  borderBottomStyle='solid'
                  borderBottomColor={VARIABLES.COLORS.GRAY_3}
                  fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_15}
                  fontColor={VARIABLES.COLORS.BLACK}
                  onChange={this.onChangeField.bind(this, 'houseNumber')}
                  value={this.state.houseNumber}
                />
                <Text
                  className='is-floating-label'
                  fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_15}
                  color={VARIABLES.COLORS.GRAY_2}
                >
                  Insert house number
                </Text>
              </Section>
              <Section spacingBottom='27' className='is-register-floating-label'>
                <Field
                  fluid
                  height='28'
                  placeholder='Insert road'
                  placeholderColor={VARIABLES.COLORS.GRAY_5}
                  paddingHorizontal='10'
                  paddingBottom='10'
                  bgColor={VARIABLES.COLORS.TRANSPARENT}
                  borderBottomWidth='1'
                  borderBottomStyle='solid'
                  borderBottomColor={VARIABLES.COLORS.GRAY_3}
                  fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_15}
                  fontColor={VARIABLES.COLORS.BLACK}
                  onChange={this.onChangeField.bind(this, 'road')}
                  value={this.state.road}
                />
                <Text
                  className='is-floating-label'
                  fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_15}
                  color={VARIABLES.COLORS.GRAY_2}
                >
                  Road
                </Text>
              </Section> */}
              <Section spacingBottom='27' className='is-register-floating-label'>
                <Field
                  fluid
                  // type='textarea'
                  height='28'
                  placeholder={getLanguage('field.addressPlaceholder')}
                  placeholderColor={VARIABLES.COLORS.GRAY_5}
                  paddingHorizontal='10'
                  paddingBottom='10'
                  bgColor={VARIABLES.COLORS.TRANSPARENT}
                  borderBottomWidth='1'
                  borderBottomStyle='solid'
                  borderBottomColor={VARIABLES.COLORS.GRAY_3}
                  fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_15}
                  fontColor={VARIABLES.COLORS.BLACK}
                  // name=''
                  value={address}
                  onChange={this.onChangeField.bind(this, 'address')}
                />
                <Text
                  className='is-floating-label'
                  fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_15}
                  color={VARIABLES.COLORS.GRAY_2}
                >
                  {getLanguage('field.address')}
                  {this.state.countries.toLowerCase() === ENUM.COUNTRY.MALAYSIA.toLowerCase() && ' (street)'}
                  <Text
                    fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_15}
                    color={VARIABLES.COLORS.RED_2}
                  >
                    *
                  </Text>
                </Text>
              </Section>
              <Section spacingBottom='27' className='is-register-floating-label'>
                {
                  this.state.countries.toLowerCase() === ENUM.COUNTRY.LAOS.toLowerCase() ?
                    <Field
                      fluid
                      height='28'
                      placeholder={getLanguage('field.provincePlaceholder')}
                      placeholderColor={VARIABLES.COLORS.GRAY_5}
                      paddingHorizontal='10'
                      paddingBottom='10'
                      bgColor={VARIABLES.COLORS.TRANSPARENT}
                      borderBottomWidth='1'
                      borderBottomStyle='solid'
                      borderBottomColor={VARIABLES.COLORS.GRAY_3}
                      fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_15}
                      fontColor={VARIABLES.COLORS.BLACK}
                      value={selectedProvince}
                      onChange={this.onChangeField.bind(this, 'selectedProvince')}
                    />
                    :
                    <React.Fragment>
                      {
                        this.state.countries.toLowerCase() === ENUM.COUNTRY.MALAYSIA.toLowerCase() ?
                          <SelectControl
                            isCreatable
                            verticalLabel
                            required={false}
                            isSearchable
                            placeholder={getLanguage('field.provincePlaceholder', '')}
                            value={selectedProvince ? {
                              label: selectedProvinceText,
                              value: selectedProvince
                            } : null}
                            onChange={(selected) => {
                              this.input.onChangeAddress(selected, 'selectedProvince')
                            }}
                            options={getCountryDataList()}
                          />
                          :
                          <Field
                            type='select'
                            fluid
                            height='32'
                            paddingRight='36'
                            paddingBottom='6'
                            paddingLeft='7'
                            bgColor={VARIABLES.COLORS.WHITE}
                            borderBottomWidth='1'
                            borderBottomStyle='solid'
                            borderBottomColor={VARIABLES.COLORS.GRAY_3}
                            fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_15}
                            fontColor={VARIABLES.COLORS.BLACK}
                            fieldIcon={
                              <Image
                                widthRatio='13'
                                widthRatioUnit='px'
                                heightRatio='8'
                                heightRatioUnit='px'
                                src={ICONS['ic-arrow-select.svg']}
                              />
                            }
                            fieldIconWidth='36'
                            fieldIconSpacingRight='0'
                            onChange={(selected) => {
                              this.input.onChangeAddress(selected.target.selectedOptions[0], 'selectedProvince')
                            }}
                            value={selectedProvince}
                          >
                            <SelectProvinceContainerConnected
                              value={selectedProvince}
                            />
                          </Field>
                      }
                    </React.Fragment>
                }
                <Text
                  className='is-floating-label'
                  fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_15}
                  color={VARIABLES.COLORS.GRAY_2}
                >
                  {getLanguage('field.province')}
                  {this.state.countries.toLowerCase() === ENUM.COUNTRY.THAI.toLowerCase() && ' (จังหวัด)'}
                  <Text
                    fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_15}
                    color={VARIABLES.COLORS.RED_2}
                  >
                    *
                  </Text>
                </Text>
              </Section>
              <Section spacingBottom='27' className='is-register-floating-label'>
                {
                  this.state.countries.toLowerCase() === ENUM.COUNTRY.LAOS.toLowerCase() ?
                    <Field
                      fluid
                      height='28'
                      placeholder={getLanguage('field.cityPlaceholder')}
                      placeholderColor={VARIABLES.COLORS.GRAY_5}
                      paddingHorizontal='10'
                      paddingBottom='10'
                      bgColor={VARIABLES.COLORS.TRANSPARENT}
                      borderBottomWidth='1'
                      borderBottomStyle='solid'
                      borderBottomColor={VARIABLES.COLORS.GRAY_3}
                      fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_15}
                      fontColor={VARIABLES.COLORS.BLACK}
                      value={selectedCity}
                      onChange={this.onChangeField.bind(this, 'selectedCity')}
                    />
                    :
                    <React.Fragment>
                      {
                        this.state.countries.toLowerCase() === ENUM.COUNTRY.MALAYSIA.toLowerCase() ?
                          <SelectControl
                            isCreatable
                            verticalLabel
                            required={false}
                            isSearchable
                            placeholder={getLanguage('field.cityPlaceholder', '')}
                            value={selectedCity ? {
                              label: selectedCityText,
                              value: selectedCity
                            } : null}
                            onChange={(selected) => {
                              this.input.onChangeAddress(selected, 'selectedCity')
                            }}
                            options={getCityListByProvince(selectedProvince)}
                          />
                          :
                          <Field
                            type='select'
                            fluid
                            height='32'
                            paddingRight='36'
                            paddingBottom='6'
                            paddingLeft='7'
                            bgColor={VARIABLES.COLORS.WHITE}
                            borderBottomWidth='1'
                            borderBottomStyle='solid'
                            borderBottomColor={VARIABLES.COLORS.GRAY_3}
                            fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_15}
                            fontColor={VARIABLES.COLORS.BLACK}
                            fieldIcon={
                              <Image
                                widthRatio='13'
                                widthRatioUnit='px'
                                heightRatio='8'
                                heightRatioUnit='px'
                                src={ICONS['ic-arrow-select.svg']}
                              />
                            }
                            fieldIconWidth='36'
                            fieldIconSpacingRight='0'
                            onChange={(selected) => {
                              this.input.onChangeAddress(selected.target.selectedOptions[0], 'selectedCity');
                            }}
                            value={selectedCity}
                          >
                            <SelectCityContainerConnected
                              value={selectedCity}
                              valueProvince={selectedProvince}
                            />
                          </Field>
                      }
                    </React.Fragment>
                }
                <Text
                  className='is-floating-label'
                  fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_15}
                  color={VARIABLES.COLORS.GRAY_2}
                >
                  {getLanguage('field.city')}
                  {this.state.countries.toLowerCase() === ENUM.COUNTRY.THAI.toLowerCase() && ' (อำเภอ)'}
                  <Text
                    fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_15}
                    color={VARIABLES.COLORS.RED_2}
                  >
                    *
                  </Text>
                </Text>
              </Section>
              {(this.state.countries.toLowerCase() === ENUM.COUNTRY.VIETNAM.toLowerCase() || this.state.countries.toLowerCase() === ENUM.COUNTRY.INDONESIA.toLowerCase()) &&
                <Section spacingBottom='27' className='is-register-floating-label'>
                  <Field
                    fluid
                    height='28'
                    placeholder={getLanguage('field.districtPlaceholder')}
                    // for indonesia >>> Insert district (Kecamatan)
                    placeholderColor={VARIABLES.COLORS.GRAY_5}
                    paddingHorizontal='10'
                    paddingBottom='10'
                    bgColor={VARIABLES.COLORS.TRANSPARENT}
                    borderBottomWidth='1'
                    borderBottomStyle='solid'
                    borderBottomColor={VARIABLES.COLORS.GRAY_3}
                    fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_15}
                    fontColor={VARIABLES.COLORS.BLACK}
                    // name=''
                    value={district}
                    onChange={this.onChangeField.bind(this, 'district')}
                  />
                  <Text
                    className='is-floating-label'
                    fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_15}
                    color={VARIABLES.COLORS.GRAY_2}
                  >
                    {getLanguage('field.district')}
                    {this.state.countries.toLowerCase() === ENUM.COUNTRY.INDONESIA.toLowerCase() && ' (Kecamatan)'}
                    <Text
                      fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_15}
                      color={VARIABLES.COLORS.RED_2}
                    >
                      *
                    </Text>
                  </Text>
                </Section>
              }
              {(this.state.countries.toLowerCase() === ENUM.COUNTRY.THAI.toLowerCase() || this.state.countries.toLowerCase() === ENUM.COUNTRY.LAOS.toLowerCase() || this.state.countries.toLowerCase() === ENUM.COUNTRY.INDONESIA.toLowerCase()) &&
                <Section spacingBottom='27' className='is-register-floating-label'>
                  {/* RL-707 : Registration > Change input text to select */}
                  {/* TODO: laos */}
                  {countries.toLowerCase() === ENUM.COUNTRY.THAI.toLowerCase() ?
                    <Field
                      type='select'
                      fluid
                      height='32'
                      paddingRight='36'
                      paddingBottom='6'
                      paddingLeft='7'
                      bgColor={VARIABLES.COLORS.WHITE}
                      borderBottomWidth='1'
                      borderBottomStyle='solid'
                      borderBottomColor={VARIABLES.COLORS.GRAY_3}
                      fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_15}
                      fontColor={VARIABLES.COLORS.BLACK}
                      fieldIcon={
                        <Image
                          widthRatio='13'
                          widthRatioUnit='px'
                          heightRatio='8'
                          heightRatioUnit='px'
                          src={ICONS['ic-arrow-select.svg']}
                        />
                      }
                      fieldIconWidth='36'
                      fieldIconSpacingRight='0'
                      value={subDistrict}
                      onChange={(selected) => {
                        this.input.onChangeAddress(selected.target.selectedOptions[0], 'subDistrict');
                      }}
                    >
                      <SelectSubDistrictContainerConnected
                        value={subDistrict}
                        valueCity={selectedCity}
                        valueProvince={selectedProvince}
                      />
                    </Field>
                    :
                    <Field
                      fluid
                      height='28'
                      placeholder={getLanguage('field.subDistrictPlaceholder')}
                      // for thailand >>> Insert sub-district (ตำบล)
                      // for indonesia >>> Insert sub-district (Kelurahan Bahasa)
                      placeholderColor={VARIABLES.COLORS.GRAY_5}
                      paddingHorizontal='10'
                      paddingBottom='10'
                      bgColor={VARIABLES.COLORS.TRANSPARENT}
                      borderBottomWidth='1'
                      borderBottomStyle='solid'
                      borderBottomColor={VARIABLES.COLORS.GRAY_3}
                      fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_15}
                      fontColor={VARIABLES.COLORS.BLACK}
                      // name=''
                      value={subDistrict}
                      onChange={this.onChangeField.bind(this, 'subDistrict')}
                    />
                  }
                  <Text
                    className='is-floating-label'
                    fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_15}
                    color={VARIABLES.COLORS.GRAY_2}
                  >
                    {getLanguage('field.subDistrict')}
                    {this.state.countries.toLowerCase() === ENUM.COUNTRY.THAI.toLowerCase() && ' (ตำบล)'}
                    {this.state.countries.toLowerCase() === ENUM.COUNTRY.INDONESIA.toLowerCase() && ' (Kelurahan Bahasa)'}
                    <Text
                      fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_15}
                      color={VARIABLES.COLORS.RED_2}
                    >
                      *
                    </Text>
                  </Text>
                </Section>
              }
              {this.state.countries.toLowerCase() === ENUM.COUNTRY.VIETNAM.toLowerCase() &&
                <React.Fragment>
                  <Section spacingBottom='27' className='is-register-floating-label'>
                    <Field
                      fluid
                      height='28'
                      placeholder={getLanguage('field.alleyPlaceholder')}
                      placeholderColor={VARIABLES.COLORS.GRAY_5}
                      paddingHorizontal='10'
                      paddingBottom='10'
                      bgColor={VARIABLES.COLORS.TRANSPARENT}
                      borderBottomWidth='1'
                      borderBottomStyle='solid'
                      borderBottomColor={VARIABLES.COLORS.GRAY_3}
                      fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_15}
                      fontColor={VARIABLES.COLORS.BLACK}
                      // name=''
                      value={alley}
                      onChange={this.onChangeField.bind(this, 'alley')}
                    />
                    <Text
                      className='is-floating-label'
                      fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_15}
                      color={VARIABLES.COLORS.GRAY_2}
                    >
                      {getLanguage('field.alley')}
                      <Text
                        fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_15}
                        color={VARIABLES.COLORS.RED_2}
                      >
                        *
                      </Text>
                    </Text>
                  </Section>
                  <Section spacingBottom='27' className='is-register-floating-label'>
                    <Field
                      fluid
                      height='28'
                      placeholder={getLanguage('field.groupPlaceholder')}
                      placeholderColor={VARIABLES.COLORS.GRAY_5}
                      paddingHorizontal='10'
                      paddingBottom='10'
                      bgColor={VARIABLES.COLORS.TRANSPARENT}
                      borderBottomWidth='1'
                      borderBottomStyle='solid'
                      borderBottomColor={VARIABLES.COLORS.GRAY_3}
                      fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_15}
                      fontColor={VARIABLES.COLORS.BLACK}
                      // name=''
                      value={group}
                      onChange={this.onChangeField.bind(this, 'group')}
                    />
                    <Text
                      className='is-floating-label'
                      fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_15}
                      color={VARIABLES.COLORS.GRAY_2}
                    >
                      {getLanguage('field.group')}
                      <Text
                        fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_15}
                        color={VARIABLES.COLORS.RED_2}
                      >
                        *
                      </Text>
                    </Text>
                  </Section>
                  <Section spacingBottom='27' className='is-register-floating-label'>
                    <Field
                      fluid
                      height='28'
                      placeholder={getLanguage('field.streetPlaceholder')}
                      placeholderColor={VARIABLES.COLORS.GRAY_5}
                      paddingHorizontal='10'
                      paddingBottom='10'
                      bgColor={VARIABLES.COLORS.TRANSPARENT}
                      borderBottomWidth='1'
                      borderBottomStyle='solid'
                      borderBottomColor={VARIABLES.COLORS.GRAY_3}
                      fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_15}
                      fontColor={VARIABLES.COLORS.BLACK}
                      // name=''
                      value={street}
                      onChange={this.onChangeField.bind(this, 'street')}
                    />
                    <Text
                      className='is-floating-label'
                      fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_15}
                      color={VARIABLES.COLORS.GRAY_2}
                    >
                      {getLanguage('field.street')}
                      <Text
                        fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_15}
                        color={VARIABLES.COLORS.RED_2}
                      >
                        *
                      </Text>
                    </Text>
                  </Section>
                  <Section spacingBottom='27' className='is-register-floating-label'>
                    <Field
                      fluid
                      height='28'
                      placeholder={getLanguage('field.wardPlaceholder')}
                      placeholderColor={VARIABLES.COLORS.GRAY_5}
                      paddingHorizontal='10'
                      paddingBottom='10'
                      bgColor={VARIABLES.COLORS.TRANSPARENT}
                      borderBottomWidth='1'
                      borderBottomStyle='solid'
                      borderBottomColor={VARIABLES.COLORS.GRAY_3}
                      fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_15}
                      fontColor={VARIABLES.COLORS.BLACK}
                      // name=''
                      value={ward}
                      onChange={this.onChangeField.bind(this, 'ward')}
                    />
                    <Text
                      className='is-floating-label'
                      fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_15}
                      color={VARIABLES.COLORS.GRAY_2}
                    >
                      {getLanguage('field.ward')}
                      <Text
                        fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_15}
                        color={VARIABLES.COLORS.RED_2}
                      >
                        *
                      </Text>
                    </Text>
                  </Section>
                </React.Fragment>
              }
              <Section spacingBottom='27' className='is-register-floating-label'>
                {/* RL-707 : Registration > Change input text to select */}
                {/* TODO: laos */}
                {countries.toLowerCase() === ENUM.COUNTRY.THAI.toLowerCase() ?
                  <Field
                    type='select'
                    fluid
                    height='32'
                    paddingRight='36'
                    paddingBottom='6'
                    paddingLeft='7'
                    bgColor={VARIABLES.COLORS.WHITE}
                    borderBottomWidth='1'
                    borderBottomStyle='solid'
                    borderBottomColor={VARIABLES.COLORS.GRAY_3}
                    fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_15}
                    fontColor={VARIABLES.COLORS.BLACK}
                    fieldIcon={
                      <Image
                        widthRatio='13'
                        widthRatioUnit='px'
                        heightRatio='8'
                        heightRatioUnit='px'
                        src={ICONS['ic-arrow-select.svg']}
                      />
                    }
                    fieldIconWidth='36'
                    fieldIconSpacingRight='0'
                    value={postCode}
                    onChange={(selected) => {
                      this.input.onChangeAddress(selected.target.selectedOptions[0], 'postCode');
                    }}
                  >
                    <SelectPostCodeContainerConnected
                      value={postCode}
                      valueSubDistrict={subDistrict}
                      valueCity={selectedCity}
                      valueProvince={selectedProvince}
                    />
                  </Field>
                  :
                  <Field
                    fluid
                    height='28'
                    placeholder={getLanguage('field.postCodePlaceholder')}
                    placeholderColor={VARIABLES.COLORS.GRAY_5}
                    paddingHorizontal='10'
                    paddingBottom='10'
                    bgColor={VARIABLES.COLORS.TRANSPARENT}
                    borderBottomWidth='1'
                    borderBottomStyle='solid'
                    borderBottomColor={VARIABLES.COLORS.GRAY_3}
                    fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_15}
                    fontColor={VARIABLES.COLORS.BLACK}
                    value={postCode}
                    onChange={this.onChangeField.bind(this, 'postCode')}
                  />
                }
                <Text
                  className='is-floating-label'
                  fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_15}
                  color={VARIABLES.COLORS.GRAY_2}
                >
                  {getLanguage('field.postCode')}
                  <Text
                    fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_15}
                    color={VARIABLES.COLORS.RED_2}
                  >
                    *
                  </Text>
                </Text>
              </Section>
              <Section spacingBottom='27' className='is-register-floating-label'>
                <Field
                  fluid
                  readonly
                  height='28'
                  placeholder={getLanguage('field.countryPlaceholder')}
                  placeholderColor={VARIABLES.COLORS.GRAY_5}
                  paddingHorizontal='10'
                  paddingBottom='10'
                  bgColor={VARIABLES.COLORS.TRANSPARENT}
                  borderBottomWidth='1'
                  borderBottomStyle='solid'
                  borderBottomColor={VARIABLES.COLORS.BLUE_2}
                  fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_15}
                  fontColor={VARIABLES.COLORS.BLUE_2}
                  // name=''
                  // onChange={}
                  value={capitalizeFirstLetter(countries)}
                />
                <Text
                  className='is-floating-label'
                  fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_15}
                  color={VARIABLES.COLORS.GRAY_2}
                >
                  {getLanguage('field.country')}
                </Text>
              </Section>
              <Section spacingBottom={'40'}>
                <Button
                  fluid
                  shadow
                  className='is-gradient-blue'
                  height={VARIABLES.BUTTON.HEIGHTS.H_40}
                  borderRadius={VARIABLES.BUTTON.BORDER_RADIUSES.BR_10}
                  // onClick={this.modal.onClickOpenModalSuccess}
                  onClick={this.handleSubmit.bind(this)}
                >
                  <Text
                    fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_16}
                    color={VARIABLES.COLORS.WHITE}

                  >
                    {getLanguage('myProfile.updateProfile')}
                  </Text>
                </Button>
              </Section>
            </Section>
          </Section>
        </Container>
        <Modal
          open={showModalSuccess}
          width={VARIABLES.MODAL.WIDTHS.W_300}
          overlayColor={VARIABLES.COLORS.OVERLAY_3}
          spacing={VARIABLES.MODAL.CONTAINER.C_1.SPACING}
          transition={VARIABLES.TRANSITIONS.DEFAULT}
          transformStart={VARIABLES.MODAL.CONTAINER.C_1.TRANSFORM_START}
          transformEnd={VARIABLES.MODAL.CONTAINER.C_1.TRANSFORM_END}
          onClickClose={this.modal.onClickCloseModalSuccess}
        >
          <Modal.Section
            paddingTop={VARIABLES.MODAL.HEADER.H_1.PADDING_TOP}
            paddingBottom={VARIABLES.MODAL.HEADER.H_1.PADDING_BOTTOM}
            paddingHorizontal={VARIABLES.MODAL.HEADER.H_1.PADDING_HORIZONTAL}
            borderTopRightRadius={VARIABLES.MODAL.HEADER.H_1.BORDER_TOP_RIGHT_RADIUS}
            borderTopLeftRadius={VARIABLES.MODAL.HEADER.H_1.BORDER_TOP_LEFT_RADIUS}
          >
            <Section justify='center'>
              <Image
                widthRatio='61'
                widthRatioUnit='px'
                heightRatio='61'
                heightRatioUnit='px'
                src={ICONS['ic-success.svg']}
              />
            </Section>
          </Modal.Section>
          <Modal.Section
            paddingBottom={VARIABLES.MODAL.BODY.B_1.PADDING_BOTTOM}
            paddingHorizontal={VARIABLES.MODAL.BODY.B_1.PADDING_HORIZONTAL}
          >
            <Section
              spacingBottom='12'
              justify='center'
            >
              <Text
                fontStyle={TYPOGRAPHYS.FIRST.BOLD.FS_18}
                color={VARIABLES.COLORS.BLACK}
              >
                {getLanguage('modal.editProfile.title')}
              </Text>
            </Section>
            <Section
              justify='center'
            >
              <Text
                fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_15}
                color={VARIABLES.COLORS.GRAY_6}
                align='center'
              >
                {getLanguage('modal.editProfile.message_1')}
              </Text>
            </Section>
          </Modal.Section>
          <Modal.Section
            paddingBottom={VARIABLES.MODAL.FOOTER.F_1.PADDING_BOTTOM}
            paddingHorizontal={VARIABLES.MODAL.FOOTER.F_1.PADDING_HORIZONTAL}
            borderBottomRightRadius={VARIABLES.MODAL.FOOTER.F_1.BORDER_BOTTOM_RIGHT_RADIUS}
            borderBottomLeftRadius={VARIABLES.MODAL.FOOTER.F_1.BORDER_BOTTOM_LEFT_RADIUS}
          >
            <Button
              fluid
              shadow
              className='is-gradient-blue'
              height={VARIABLES.BUTTON.HEIGHTS.H_35}
              borderRadius={VARIABLES.BUTTON.BORDER_RADIUSES.BR_10}
              onClick={this.modal.onClickCloseModalSuccess}
            >
              <Text
                fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_16}
                color={VARIABLES.COLORS.WHITE}
              >
                {getLanguage('field.buttonOK')}
              </Text>
            </Button>
          </Modal.Section>
        </Modal>
        <Loader loading={isShowLoading} isOverlay={true} />
      </TransitionContainer>
    )
  }
}
