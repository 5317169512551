import React from 'react'
import {
  TransitionContainer,
  Container,
  Image,
  Section,
  Text,
  Button,
} from './../../components'
import {
  VARIABLES,
  TYPOGRAPHYS,
  ICONS,
} from './../../themes'
import { getLanguage } from '../../helpers/functions/language';
import { getRouteParams, redirect, ROUTE_PATH } from '../../helpers';

export class VoucherOutOfStockPage extends React.Component {
  constructor(props) {
    super(props);
    const ssoId = getRouteParams(props, 'id');
    console.log('ssoId', ssoId);
    this.state = {
      ssoId,
    };
  }

  voucherOutOfStock = {
    onClickContinueRegister: () => {
      const {
        ssoId,
      } = this.state;
      redirect(`${ROUTE_PATH.REGISTER.LINK}/${ssoId}`);
    }
  }

  render() {
    return (
      <TransitionContainer
        // motion='overlap-from'
      >
        <Container
          width={VARIABLES.WEBSITE_WIDTH}
          // height='100'
          // heightUnit='vh'
          calcMinHeight='calc(100vh)'
          bgColor={VARIABLES.COLORS.LIGHT_BLUE_2}
          justify='center'
        >
          <Section className='is-message-page' paddingVertical='30'>
            <Section
              spacingBottom='45'
              justify='center'
            >
              <Image className='is-message-icon'
                widthRatio='215'
                widthRatioUnit='px'
                heightRatio='153'
                heightRatioUnit='px'
                src={ICONS['ic-voucher-broken.svg']}
              />
            </Section>
            <Section justify='center' spacingBottom='45'>
              <Section className='is-message-container' maxWidth='300'>
                <Section block spacingBottom='15' textAlign='center'>
                  <Text className='is-message-large'
                    inline bold
                    fontStyle={TYPOGRAPHYS.FIRST.BOLD.FS_20}
                    color={VARIABLES.COLORS.BLUE_2}
                  >
                    {getLanguage('voucherOutOfStock.message_1')}&nbsp;
                  </Text>
                  <Text className='is-message-large'
                    inline
                    fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_20}
                    color={VARIABLES.COLORS.GRAY_3}
                  >
                    {getLanguage('voucherOutOfStock.message_2')}
                  </Text>
                  <br />
                  <Text className='is-message-large'
                    fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_20}
                    color={VARIABLES.COLORS.GRAY_3}
                  >
                    {getLanguage('voucherOutOfStock.message_3')}
                  </Text>
                </Section>
                <Section block spacingBottom='15' textAlign='center'>
                  <Text className='is-message-bold-medium'
                    bold
                    fontStyle={TYPOGRAPHYS.FIRST.BOLD.FS_16}
                    color={VARIABLES.COLORS.BLUE_2}
                  >
                    {getLanguage('voucherOutOfStock.message_4')}<br />
                    {getLanguage('voucherOutOfStock.message_5')}
                  </Text>
                </Section>
                <Section block spacingBottom='15' textAlign='center'>
                  <Text className='is-message-medium'
                    fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_16}
                    color={VARIABLES.COLORS.GRAY_3}
                  >
                    {getLanguage('voucherOutOfStock.message_6')}<br />
                    {getLanguage('voucherOutOfStock.message_7')}<br />
                    {getLanguage('voucherOutOfStock.message_8')}
                  </Text>
                  <br />
                  <Text className='is-message-medium'
                    fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_16}
                    color={VARIABLES.COLORS.BLUE_2}
                  >
                    {getLanguage('voucherOutOfStock.message_9')}&nbsp;
                    {getLanguage('voucherOutOfStock.message_10')}
                  </Text>
                </Section>
              </Section>
            </Section>
            <Section justify='center'>
              <Button
                shadow
                className='is-gradient-blue is-icon-right'
                width={VARIABLES.BUTTON.WIDTHS.W_275}
                height={VARIABLES.BUTTON.HEIGHTS.H_40}
                borderRadius={VARIABLES.BUTTON.BORDER_RADIUSES.BR_10}
                onClick={this.voucherOutOfStock.onClickContinueRegister}
              >
                <Button.Icon>
                  <Image
                    widthRatio='15'
                    widthRatioUnit='px'
                    heightRatio='12'
                    heightRatioUnit='px'
                    src={ICONS['ic-arrow-right.svg']}
                  />
                </Button.Icon>
                <Text
                  fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_16}
                  color={VARIABLES.COLORS.WHITE}
                >
                  {getLanguage('field.buttonContinueRegister')}
                </Text>
              </Button>
            </Section>
          </Section>
        </Container>
      </TransitionContainer>
    )
  }
}
