import React from 'react'
import _ from 'lodash'
import axios from 'axios'
import {
  TransitionContainer,
  Container,
  Image,
  Section,
  Text,
  Button,
  NavigationBar,
  Alert,
} from './../../components'
import {
  VARIABLES,
  TYPOGRAPHYS,
  ICONS,
} from './../../themes'
import {
  ROUTE_PATH,
  redirect,
} from './../../helpers'
import {
  Loader
} from '../Loader'
import config from './../../config'
import { getLanguage } from '../../helpers/functions/language'

export class FindDealerTypePage extends React.Component {
  constructor(props) {
    super(props)
    const type = localStorage.getItem('dealerType')
    this.state = {
      type: type || '',
      isLoading: false,
      showModal: false,
      titleModal: '',
      descriptionModal: '',
    }
  }

  componentDidMount() {
    localStorage.removeItem('dealerList')
  }

  getUserLocation() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(this.fetchNearestDealer)
    }
  }

  fetchNearestDealer = position => {
    const userLatitude = position.coords.latitude
    const userLongitude = position.coords.longitude
    if (!userLatitude && !userLongitude) {
      this.setState({
        showModal: true,
        titleModal: '',
        descriptionModal: getLanguage('message.cannotGetYourCurrentLocation')
      })
      return
    }

    const { type } = this.state
    this.setState({ isLoading: true }, async () => {
      try {
        const url = `${config.api.cms}/bsad/search/near/`
        const options = { latitude: userLatitude, longitude: userLongitude, distance: 200, status: 'active', type }
        const response = await axios.post(url, options)
        let data = _.get(response, 'data.data')
        if (response.status === 200 && !_.isEmpty(data)) {
          // data = data.map(e => {
          //   e.fullAddress = `${e.address ? e.address : ''}${e.amphur ? `, ${e.amphur}` : ''}${e.province ? `, ${e.province}` : ''}`;
          //   return e
          // })
          localStorage.setItem('dealerList', JSON.stringify(data))
          redirect(ROUTE_PATH.FIND_DEALER_SEARCH_RESULT.LINK)
        } else {
          this.setState({ showModal: true, titleModal: 'Data not found' })
        }
      } catch (_) {
      } finally {
        this.setState({ isLoading: false })
      }
    })
  }

  onCloseModal() {
    this.setState({ showModal: false })
  }

  handleBack() {
    redirect(ROUTE_PATH.FIND_DEALER.LINK)
  }

  onSerachNearestDealer() {
    this.getUserLocation()
  }

  render() {
    const { isLoading, showModal, titleModal, descriptionModal, type } = this.state
    return (
      <TransitionContainer>
        <Container
          width={VARIABLES.WEBSITE_WIDTH}
          calcMinHeight='100vh'
          bgColor={VARIABLES.COLORS.LIGHT_BLUE_2}
        >
          <NavigationBar
            pageTitle={`${getLanguage('findDealerType.title')} : ${type === 'BAD' ? 'BSAD' : type}`}
            actionLeft={
              <Image
                widthRatio='21'
                widthRatioUnit='px'
                heightRatio='21'
                heightRatioUnit='px'
                src={ICONS['ic-close-black.svg']}
                onClick={this.handleBack.bind(this)}
              />
            }
          />
          <Section
            paddingHorizontal='20'
            paddingTop='33'
            justify='center'
          >
            <Section
              spacingBottom='14'
            >
              <Button
                className='is-icon-right is-text-left'
                fluid
                height='40'
                paddingRight='10'
                paddingLeft='50'
                bgColor={VARIABLES.COLORS.TRANSPARENT}
                borderWidth='1'
                borderStyle='solid'
                borderColor={VARIABLES.COLORS.BLUE_2}
                borderRadius='20'
                justify='left'
                onClick={this.onSerachNearestDealer.bind(this)}
              >
                <Image
                  className='icon-left-button'
                  widthRatio='18'
                  widthRatioUnit='px'
                  heightRatio='18'
                  heightRatioUnit='px'
                  src={ICONS['ic-location-blue.svg']}
                />
                <Button.Icon>
                  <Image
                    widthRatio='6'
                    widthRatioUnit='px'
                    heightRatio='12'
                    heightRatioUnit='px'
                    src={ICONS['ic-arrow-right-blue.svg']}
                  />
                </Button.Icon>
                <Text
                  fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_14}
                  color={VARIABLES.COLORS.BLUE_2}
                >
                  {getLanguage('findDealerType.nearestDealers')}
                </Text>
              </Button>
            </Section>
            <Text
              fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_14}
              color={VARIABLES.COLORS.LIGHT_BLUE_3}
            >
              {getLanguage('findDealerType.or')}
            </Text>
            <Section
              spacingVertical='14'
            >
              <Button
                className='is-icon-right is-text-left'
                fluid
                height='40'
                paddingRight='10'
                paddingLeft='50'
                bgColor={VARIABLES.COLORS.TRANSPARENT}
                borderWidth='1'
                borderStyle='solid'
                borderColor={VARIABLES.COLORS.BLUE_2}
                borderRadius='20'
                justify='left'
                onClick={() => {
                  redirect(ROUTE_PATH.FIND_DEALER_SEARCH_NAME.LINK)
                }}
              >
                <Image
                  className='icon-left-button'
                  widthRatio='18'
                  widthRatioUnit='px'
                  heightRatio='18'
                  heightRatioUnit='px'
                  src={ICONS['ic-search-blue.svg']}
                />
                <Text
                  fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_14}
                  color={VARIABLES.COLORS.BLUE_2}
                >
                  {getLanguage('findDealerType.byName')}
                </Text>
              </Button>
            </Section>
            <Text
              fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_14}
              color={VARIABLES.COLORS.LIGHT_BLUE_3}
            >
              {getLanguage('findDealerType.or')}
            </Text>
            <Section
              spacingVertical='14'
            >
              <Button
                className='is-icon-right is-text-left'
                fluid
                height='40'
                paddingRight='10'
                paddingLeft='50'
                bgColor={VARIABLES.COLORS.TRANSPARENT}
                borderWidth='1'
                borderStyle='solid'
                borderColor={VARIABLES.COLORS.BLUE_2}
                borderRadius='20'
                justify='left'
                onClick={() => {
                  redirect(ROUTE_PATH.FIND_DEALER_SEARCH_AREA.LINK)
                }}
              >
                <Image
                  className='icon-left-button'
                  widthRatio='16'
                  widthRatioUnit='px'
                  heightRatio='15'
                  heightRatioUnit='px'
                  src={ICONS['ic-map-blue.svg']}
                />
                <Button.Icon>
                  <Image
                    widthRatio='14'
                    widthRatioUnit='px'
                    heightRatio='8'
                    heightRatioUnit='px'
                    src={ICONS['ic-arrow-down-blue.svg']}
                  />
                </Button.Icon>
                <Text
                  fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_14}
                  color={VARIABLES.COLORS.BLUE_2}
                >
                  {getLanguage('findDealerType.byArea')}
                </Text>
              </Button>
            </Section>
          </Section>
        </Container>
        <Alert type='error' title={titleModal} description={descriptionModal} onClose={this.onCloseModal.bind(this)} open={showModal} />
        <Loader loading={isLoading} isOverlay />
      </TransitionContainer>
    )
  }
}
