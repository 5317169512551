import React from 'react'
import ClassNames from 'classnames'
import PropTypes from 'prop-types'
import {
  ContainerBaseWrapper
} from './styled'
import {
  default as VARIABLES
} from './../../../themes/styles/bases/variables'
// import {
//   default as TYPOGRAPHYS
// } from './../../../themes/styles/bases/typographys'

/**
 * ContainerBase description:
 * - control site width
 */

export class ContainerBase extends React.PureComponent {
  render () {
    const {
      // Option
      children,
      className,
      // Default
      direction,
      wrap,
      justify,
      align,
      width,
      widthUnit,
      height,
      calcHeight,
      calcMinHeight,
      heightUnit,
      padding,
      paddingHorizontal,
      paddingVertical,
      paddingTop,
      paddingRight,
      paddingBottom,
      paddingLeft,
      paddingUnit,
      bgColor,
      bgImage,
      bgRepeat,
      bgSize,
      bgPositionX,
      bgPositionY,
      bgPosition,
      // Responsive > Mobile
      widthMobile,
      heightMobile,
      calcHeightMobile,
      calcMinHeightMobile,
      widthMobileUnit,
      heightMobileUnit,
      paddingMobile,
      paddingHorizontalMobile,
      paddingVerticalMobile,
      paddingTopMobile,
      paddingRightMobile,
      paddingBottomMobile,
      paddingLeftMobile,
      // Responsive > Phablet
      widthPhablet,
      heightPhablet,
      calcHeightPhablet,
      calcMinHeightPhablet,
      widthPhabletUnit,
      heightPhabletUnit,
      paddingPhablet,
      paddingHorizontalPhablet,
      paddingVerticalPhablet,
      paddingTopPhablet,
      paddingRightPhablet,
      paddingBottomPhablet,
      paddingLeftPhablet,
      // Responsive > Tablet
      widthTablet,
      heightTablet,
      widthTabletUnit,
      heightTabletUnit,
      calcHeightTablet,
      calcMinHeightTablet,
      paddingTablet,
      paddingHorizontalTablet,
      paddingVerticalTablet,
      paddingTopTablet,
      paddingRightTablet,
      paddingBottomTablet,
      paddingLeftTablet,
      // Breakpoint
      breakpointPaddingMobile,
      breakpointPaddingPhabletMin,
      breakpointPaddingPhabletMax,
      breakpointPaddingTabletMin,
      breakpointPaddingTabletMax
    } = this.props

    return (
      <ContainerBaseWrapper
        className={ClassNames(
          'container-base',
          // Option
          className
        )}
        theme={{
          // Default
          direction,
          wrap,
          justify,
          align,
          width,
          widthUnit,
          height,
          calcHeight,
          calcMinHeight,
          heightUnit,
          padding,
          paddingHorizontal,
          paddingVertical,
          paddingTop,
          paddingRight,
          paddingBottom,
          paddingLeft,
          paddingUnit,
          bgColor,
          bgImage,
          bgRepeat,
          bgSize,
          bgPositionX,
          bgPositionY,
          bgPosition,
          // Responsive > Mobile
          widthMobile,
          heightMobile,
          calcHeightMobile,
          calcMinHeightMobile,
          widthMobileUnit,
          heightMobileUnit,
          paddingMobile,
          paddingHorizontalMobile,
          paddingVerticalMobile,
          paddingTopMobile,
          paddingRightMobile,
          paddingBottomMobile,
          paddingLeftMobile,
          // Responsive > Phablet
          widthPhablet,
          heightPhablet,
          calcHeightPhablet,
          calcMinHeightPhablet,
          widthPhabletUnit,
          heightPhabletUnit,
          paddingPhablet,
          paddingHorizontalPhablet,
          paddingVerticalPhablet,
          paddingTopPhablet,
          paddingRightPhablet,
          paddingBottomPhablet,
          paddingLeftPhablet,
          // Responsive > Tablet
          widthTablet,
          heightTablet,
          widthTabletUnit,
          heightTabletUnit,
          calcHeightTablet,
          calcMinHeightTablet,
          paddingTablet,
          paddingHorizontalTablet,
          paddingVerticalTablet,
          paddingTopTablet,
          paddingRightTablet,
          paddingBottomTablet,
          paddingLeftTablet,
          // Breakpoint
          breakpointPaddingMobile,
          breakpointPaddingPhabletMin,
          breakpointPaddingPhabletMax,
          breakpointPaddingTabletMin,
          breakpointPaddingTabletMax
        }}
      >
        {children}
      </ContainerBaseWrapper>
    )
  }

  static defaultProps = {
    // Option
    // children: undefined,
    // className: undefined,
    // Default
    direction: 'column',
    wrap: 'wrap',
    align: 'flex-start',
    widthUnit: 'px',
    heightUnit: 'px',
    paddingUnit: 'px',
    // Responsive
    widthMobileUnit: 'px',
    widthPhabletUnit: 'px',
    widthTabletUnit: 'px',
    heightMobileUnit: 'px',
    heightPhabletUnit: 'px',
    heightTabletUnit: 'px',
    // Breakpoint
    breakpointPaddingMobile: VARIABLES.BREAKPOINTS.BP_413,
    breakpointPaddingPhabletMin: VARIABLES.BREAKPOINTS.BP_414,
    breakpointPaddingPhabletMax: VARIABLES.BREAKPOINTS.BP_767,
    breakpointPaddingTabletMin: VARIABLES.BREAKPOINTS.BP_768,
    breakpointPaddingTabletMax: VARIABLES.BREAKPOINTS.BP_1279,
  }

  static propTypes = { // TYPE > node, string, number, bool, array, object, symbol, func
    /**
    * Additional classes
    */
    className: PropTypes.string,

    /**
    * Additional Text, HTML, Component
    */
    children: PropTypes.oneOfType([
      PropTypes.node,
      PropTypes.string
    ])
  }
}
