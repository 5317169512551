import React from 'react'
import ClassNames from 'classnames'
import PropTypes from 'prop-types'
import {
  TemplateForCustomWrapper
} from './styled'
// import {
// } from './../../../components'

// -------------------- Please remove this comment after reading message. --------------------
// Notice: Cause of import separation is styguidist require a root path for generate document.
// -------------------------------------------------------------------------------------------

// import {
//   default as VARIABLES
// } from './../../../themes/styles/bases/variables'
// import {
//   default as TYPOGRAPHYS
// } from './../../../themes/styles/bases/typographys'
// import {
//   default as UTILITIES
// } from './../../../themes/styles/helpers/utilities'

/**
 * TemplateBase description:
 * - TemplateBase
 */

export class TemplateForCustom extends React.PureComponent {
  render () {
    const {
      className,
      children,
      modifierName
    } = this.props

    // props for css classes
    const classes = ClassNames(
      'template-base',
      {'is-modifier-name': modifierName},
      className
    )

    return (
      <TemplateForCustomWrapper
        className={classes}
      >
        {/* ============================================================
          PLEASE READ BEFORE BEGIN COMPONENT CREATION !!!
          This is template for custom component wrapper e.g. mix components, third party component
          if you want to wrap base component for customize style, you should use TemplateForBase for component creation.
        ================================================================ */}
        {children}
      </TemplateForCustomWrapper>
    )
  }

  static defaultProps = {
    // * If you set new default props, you should create propsType for correct styleguide document.
  }

  static propTypes = { // TYPE > node, string, number, bool, array, object, symbol, func
    /**
    * Additional classes
    */
    className: PropTypes.string
  }
}
