import React from 'react'
import {
  TransitionContainer,
  Container,
  Section,
  Image,
  Grid,
  Text,
  Field,
  Button,
  NavigationBar,
} from './../../components'
import {
  VARIABLES,
  TYPOGRAPHYS,
  ICONS,
} from './../../themes'
import {
  ROUTE_PATH,
  redirect,
} from './../../helpers'
import {
  SearchResult,
} from './SearchResult'
import axios from 'axios';
import { getLanguage } from '../../helpers/functions/language'
import config from '../../config'
import { getUserAuth } from '../../helpers/functions/auth'
import { getCountryType } from '../../helpers/functions/data'
import { getsearchProjectByUserId } from '../../helpers/services/projects'
import _ from 'lodash'

export class ProjectSearchPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      id: (this.props.match.params && this.props.match.params.id) ? this.props.match.params.id : 1,
      toggleSearch: false,
      keyword: '',
      data: [],
    }
    this.timer = null;
  }

  setToggleSearch() {
    this.setState({ toggleSearch: true });
  }

  onChangeKeyword(event) {
    let value = event.target.value;
    this.setToggleSearch();
    this.setState({ keyword: event.target.value });
    if (event && event.target) {
      clearTimeout(this.timer);
      const userAuth = getUserAuth();
      const addressType = getCountryType(userAuth.countries);
      this.timer = setTimeout(async () => {
        const params = {
          name: value,
          available_country: addressType,
          user_id: this.state.id,
        };
        return await getsearchProjectByUserId(params).then(async (res) => {
          // return await axios.post(`${config.api.base}/api/projects/search/${this.state.id}`, params).then(async (res) => {
          if (res) {
            // if (res.status === 200) {
            this.setState({ data: res })
          } else {
            alert(getLanguage('message.somethingWrong'))
          }
        })
      }, 500);
    }
  }

  onClickClearKeyword() {
    this.setState({ keyword: '', data: [] });
  }

  handleBack() {
    redirect(`${ROUTE_PATH.MY_PROJECTS.LINK}/${this.state.id}`);
  }

  render() {
    const { data, toggleSearch, keyword } = this.state;
    return (
      <TransitionContainer
      // motion='overlap-from'
      >
        <Container
          width={VARIABLES.WEBSITE_WIDTH}
          calcMinHeight='100vh'
          bgColor={VARIABLES.COLORS.LIGHT_BLUE_2}
        >
          <NavigationBar
            pageTitle={`${toggleSearch ? getLanguage('projectsSearch.title_1') : getLanguage('projectsSearch.title_2')}`}
            actionLeft={
              <Image
                widthRatio='11'
                widthRatioUnit='px'
                heightRatio='21'
                heightRatioUnit='px'
                src={ICONS['ic-left-black.svg']}
                onClick={this.handleBack.bind(this)}
              />
            }
          />
          <Section
            paddingHorizontal='20'
            paddingTop='23'
            paddingBottom='10'
          >
            <Section spacingBottom='25' position='relative'>
              {keyword && <Button
                className='is-clear-search'
                width={20}
                height={20}
                bgImage={ICONS['ic-close-blue-circle.svg']}
                bgSize='cover'
                bgRepeat='no-repeat'
                bgPosition='center'
                bgColor={VARIABLES.COLORS.LIGHT_BLUE_2}
                onClick={this.onClickClearKeyword.bind(this)}
              />}
              <Section position='absolute' className='is-search-placeholder'>
                <Grid gutter='10'>
                  <Grid.Column>
                    <Image
                      widthRatio='15'
                      widthRatioUnit='px'
                      heightRatio='15'
                      heightRatioUnit='px'
                      top='-2'
                      src={`${keyword ? ICONS['ic-search-blue.png'] : ICONS['ic-search-light-blue.png']}`}
                    />
                  </Grid.Column>
                  <Grid.Column >
                    {<Text
                      fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_14}
                      color={`${keyword ? VARIABLES.COLORS.BLUE_2 : VARIABLES.COLORS.LIGHT_BLUE_3}`}
                      ellipsis
                    >
                      {!keyword ? getLanguage('projectsSearch.searchProject') : ''}
                    </Text>}
                  </Grid.Column>
                  <Grid.Column >
                  </Grid.Column>
                </Grid>
              </Section>
              <Field
                fluid
                value={keyword}
                bgColor={VARIABLES.COLORS.TRANSPARENT}
                borderWidth='1'
                borderStyle='solid'
                borderColor={VARIABLES.COLORS.BLUE_2}
                borderRadius='25'
                fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_15}
                fontColor={VARIABLES.COLORS.BLUE_2}
                paddingHorizontal={42}
                paddingTop={10}
                paddingBottom={10}
                onChange={this.onChangeKeyword.bind(this)}
              />
            </Section>
            {toggleSearch && <SearchResult data={data} />}
          </Section>
        </Container>
      </TransitionContainer>
    )
  }
}

