import React from 'react'
import {
  TransitionContainer,
  Container,
  Image,
  Section,
  Text,
  Button,
} from './../../components'
import {
  VARIABLES,
  TYPOGRAPHYS,
  CONTENTS,
  ICONS,
} from './../../themes'
import {
  ROUTE_PATH,
  redirect,
  getRouteParams,
} from './../../helpers'
import { LOCAL_STORAGE } from '../../helpers/constants/localStorage';
import { handleWatchNoti } from '../../helpers/firebases/firebaseNotification';
import { getLanguage } from '../../helpers/functions/language';
import moment from 'moment';
import { getCampaignData } from '../../helpers/functions/data';

export class ReferralAdPage extends React.Component {
  constructor(props) {
    super(props);
    const ssoId = getRouteParams(props, 'id');
    const campaignData = getCampaignData();
    console.log('ssoId', ssoId);
    this.state = {
      ssoId,
      campaignData,
    }
  }

  referralAd = {
    onClickGoToTrueBlue: () => {
      const {
        ssoId,
      } = this.state;
      redirect(`${ROUTE_PATH.LOGIN_BY_LINE_PHONE.LINK}/${ssoId}`)
    }
  }

  render() {
    const {
      campaignData,
    } = this.state;
    return (
      <TransitionContainer>
        <Container
          width={VARIABLES.WEBSITE_WIDTH}
          height='100'
          heightUnit='vh'
          bgColor={VARIABLES.COLORS.LIGHT_BLUE_2}
          justify='center'
        >
          <Section
            spacingBottom='45'
            justify='center'
          >
            <img src={ICONS['ic-voucher-blue.svg']} alt='Icon' />
          </Section>
          <Section
            spacingBottom='15'
            justify='center'
          >
            <Text
              fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_20}
              color={VARIABLES.COLORS.GRAY_3}
              align='center'
            >
              {getLanguage('referralAd.message_1')}
            </Text>
            &nbsp;
            <Text
              fontStyle={TYPOGRAPHYS.FIRST.BOLD.FS_20}
              color={VARIABLES.COLORS.BLUE_2}
              align='center'
            >
              {getLanguage('referralAd.message_2')}
            </Text>
          </Section>
          <Section justify='center'>
            <Text
              fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_16}
              color={VARIABLES.COLORS.GRAY_3}
              align='center'
            >
              {getLanguage('referralAd.message_3')}
            </Text>
          </Section>
          <Section justify='center'>
            <Text
              fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_16}
              color={VARIABLES.COLORS.GRAY_3}
              align='center'
            >
              {getLanguage('referralAd.message_4')}
            </Text>
          </Section>
          <Section justify='center' spacingBottom='45'>
            <Text
              fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_16}
              color={VARIABLES.COLORS.BLUE_2}
              align='center'
            >
              {getLanguage('referralAd.message_5')}
              {/* {moment(campaignData.endDate).format('D MMM YYYY')} */}
            </Text>
          </Section>
          <Section justify='center'>
            <Button
              shadow
              className='is-gradient-blue'
              width={VARIABLES.BUTTON.WIDTHS.W_275}
              height={VARIABLES.BUTTON.HEIGHTS.H_40}
              borderRadius={VARIABLES.BUTTON.BORDER_RADIUSES.BR_10}
              onClick={this.referralAd.onClickGoToTrueBlue}
            >
              <Text
                fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_16}
                color={VARIABLES.COLORS.WHITE}
              >
                {getLanguage('field.buttonGoToTrueBlue')}
              </Text>
            </Button>
          </Section>
        </Container>
      </TransitionContainer>
    )
  }
}
