const UPLOAD_PATH = {
  ESTIMATOR: '/estimator',
}

const COUNTRY = {
  THAI: 'Thailand',
  MALAYSIA: 'Malaysia',
  VIETNAM: 'Vietnam',
  INDONESIA: 'Indonesia',
  LAOS: 'Laos',
}

const COUNTRY_TYPE = {
  THAI: 'th',
  MALAYSIA: 'ml',
  VIETNAM: 'vi',
  INDONESIA: 'in',
  LAOS: 'la',
}

const PROJECT_TYPE = {
  REFERENCE: 'reference'
}

const CUSTOMER_TYPE = {
  FIXERS: {
    TYPE: 'Fixers / Contractors / Installer / Applicator',
    TEXT: 'Fixers / Contractors',
  },
  HARDWARE: {
    TYPE: 'Hardware Store / Construction Shop',
    TEXT: 'Hardware Store / Construction Shop',
  },
  HOMEOWNER: {
    TYPE: 'Homeowner / Project Owner',
    TEXT: 'Homeowner / Project Owner',
  },
  ROLL_FORMER: {
    TYPE: 'Roll former',
    TEXT: 'Roll formers / RF Sales / RF Fixers',
  },
  // CR: 1483
  ARCHITECT: {
    TYPE: 'Architect / Designer / Consult',
    TEXT: 'Architect / Designer / Consult',
  },
  ENGINEER: {
    TYPE: 'Engineer',
    TEXT: 'Engineer',
  },
  OTHERS: {
    TYPE: 'Others',
    TEXT: 'Others (Military, Government)',
  },
}

const ORDER_INQUIRY_STATUS = {
  ACKNOWLEDGED: 'Acknowledged',
  PENDING: 'pending',
}

const OTP_TYPE = {
  REGISTER: 'register',
  FORGET_PASSWORD: 'forget_pw',
}

const WARRANTY_TYPE = {
  DRAFT: {
    TEXT: 'Draft',
  },
  READY: {
    TYPE: '2',
    TEXT: 'Ready',
  },
  WAITING_FOR_APPROVAL: {
    TYPE: '1',
    TEXT: 'InProgress',
  },
  CHANGE_NEEDED: {
    TYPE: '3',
    TEXT: 'ChangeNeeded',
  },
}

const ADMIN_ROLE = {
  TH: 'admin_th',
  ML: 'admin_ml',
  IN: 'admin_id',
  VI: 'admin_vn',
  // LA: 'admin_la',
  // SUPER_ADMIN: 'super_admin',
}

const CUSTOMER_TRAINED_TIER = {
  GOLD: {
    TEXT: 'Gold',
  },
  SILVER: {
    TEXT: 'Silver',
  },
  BRONZE: {
    TEXT: 'Bronze',
  }
}

export const ENUM = {
  UPLOAD_PATH,
  COUNTRY,
  COUNTRY_TYPE,
  PROJECT_TYPE,
  CUSTOMER_TYPE,
  ORDER_INQUIRY_STATUS,
  OTP_TYPE,
  WARRANTY_TYPE,
  ADMIN_ROLE,
  CUSTOMER_TRAINED_TIER,
}