import React from 'react'
import {
  TransitionContainer,
  Container,
  Section,
  Image,
  Button,
  Text,
  Field,
  NavigationBar,
  Modal,
  Grid,
} from './../../components';
import {
  VARIABLES,
  TYPOGRAPHYS,
  ICONS,
} from './../../themes';
import { ModalNoticeContainer } from '../../containers/ModalContainer/ModalNoticeContainer';
import {
  ROUTE_PATH,
  redirect,
  redirectGoBack,
  isValidNumber,
  isValidResponse,
  redirectData,
  isValidNumberNoPrefixZero,
} from './../../helpers';
import { getLanguage } from '../../helpers/functions/language';
import { onOpenModal, onOpenAlert, isShowLoading } from '../../helpers/functions/main';
import { DATA } from '../../helpers/constants/data';
import axios from 'axios';
import config from '../../config';
import { getCountryPhoneByCountry, getCountryByLanguage } from '../../helpers/functions/data';
import { InputOtpContainerConnected } from '../../containers/InputOtpContainer/InputOtpContainer';
import { ENUM } from '../../helpers/constants/enum';
import { getCurrentCountryLanguage } from '../../helpers/functions/addon';

export class ForgotPasswordVerifyWithPhoneNumberPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      phone: '',
      data: {},
      otpList: [],
      isOpenInputOtp: false,
      selectedCountry: ENUM.COUNTRY.THAI,
    }
  }

  componentDidMount() {
    getCurrentCountryLanguage().then(language => {
      let selectedCountry = getCountryByLanguage(language);
      console.log('getCurrentCountryLanguage', language, selectedCountry);
      this.setState({
        selectedCountry,
      });
    });
  }

  forgetPassword = {
    onClickSubmit: async () => {
      const {
        phone,
        selectedCountry,
      } = this.state;
      if (phone.length) {
        isShowLoading(true);
        axios.post(`${config.api.base}/api/users`, {
          phone: phone
        }).then((response) => {
          const res = response.data;
          if (isValidResponse(res)) {
            if (res.data) {
              const data = res.data;
              console.log('data', data);
              // const prefix = getCountryPhoneByCountry(data.countries);
              let prefix = DATA.PHONE_PREFIX_LIST[0].value;
              DATA.PHONE_PREFIX_LIST.forEach(e => {
                if (selectedCountry.toLowerCase() === e.country.toLowerCase()) {
                  prefix = e.value
                }
              })
              let sendOtpTo = `${prefix}${data.phone}`;
              isShowLoading(false);
              this.setState({
                data,
                sendOtpTo,
                isOpenInputOtp: true,
              });
            }
            else {
              isShowLoading(false);
              onOpenAlert({
                type: DATA.ALERT_TYPE.ERROR,
                title: getLanguage('message.fail'),
                message: getLanguage('message.phoneDoesntExist'),
              })
            }
          }
          else {
            isShowLoading(false);
            onOpenAlert({
              type: DATA.ALERT_TYPE.ERROR,
              title: getLanguage('message.fail'),
              message: getLanguage('message.phoneDoesntExist'),
            })
          }
        }, (error) => {
          console.log(error);
          isShowLoading(false);
          onOpenAlert({
            type: DATA.ALERT_TYPE.ERROR,
            title: getLanguage('message.fail'),
            message: (error || {}).message,
          });
        });
      }
      else {
        isShowLoading(false);
        onOpenAlert({
          type: DATA.ALERT_TYPE.ERROR,
          title: getLanguage('message.fail'),
          message: getLanguage('message.pleaseInputPhone'),
        })
      }
    },
    onCloseInputOtp: () => {
      this.setState({
        isOpenInputOtp: false,
      });
    },
    handleOtpSuccess: () => {
      const {
        data,
      } = this.state;
      redirectData(ROUTE_PATH.RESET_PASSWORD.LINK, {
        data,
      });
    },
    goBack: () => {
      redirect(ROUTE_PATH.LOGIN_BY_PHONE.LINK)
    }
  }

  input = {
    onChangeInput: (ev) => {
      const target = ev.target.name;
      const value = ev.target.value;
      switch (target) {
        case 'phone':
          if (value.length && !isValidNumberNoPrefixZero(value)) {
            return;
          }
        default:
          this.setState({
            [target]: value
          })
      }
    },
    onChangePhoneCountry: (ev) => {
      this.setState({
        selectedCountry: ev.target.value,
      })
    },
    getCountryFlag: () => {
      const {
        selectedCountry,
      } = this.state;
      switch (selectedCountry.toLowerCase()) {
        case ENUM.COUNTRY.THAI.toLowerCase():
          return ICONS['ic-flag-th.png'];
        case ENUM.COUNTRY.INDONESIA.toLowerCase():
          return ICONS['ic-flag-in.png'];
        case ENUM.COUNTRY.MALAYSIA.toLowerCase():
          return ICONS['ic-flag-ma.png'];
        case ENUM.COUNTRY.VIETNAM.toLowerCase():
          return ICONS['ic-flag-vt.png'];
        case ENUM.COUNTRY.LAOS.toLowerCase():
          return ICONS['ic-flag-la.png'];
      }
    }
  }

  render() {
    const {
      sendOtpTo,
      isOpenInputOtp,
      phone,
      selectedCountry,
      data,
    } = this.state
    return isOpenInputOtp ?
      <InputOtpContainerConnected messageType={ENUM.OTP_TYPE.FORGET_PASSWORD}
        userData={data}
        sendOtpTo={sendOtpTo}
        onCloseInputOtp={this.forgetPassword.onCloseInputOtp}
        callbackSuccess={this.forgetPassword.handleOtpSuccess}
      />
      :
      <TransitionContainer
      // motion='overlap-from'
      >
        <Container
          width={VARIABLES.WEBSITE_WIDTH}
          height='100'
          heightUnit='vh'
          bgColor={VARIABLES.COLORS.LIGHT_BLUE_2}
        >
          <NavigationBar
            shadow
            // pageTitle={getLanguage('forgotPassword.title')}
            pageTitle={getLanguage('verify.title3')}
            actionLeft={
              <Image
                widthRatio='11'
                widthRatioUnit='px'
                heightRatio='21'
                heightRatioUnit='px'
                src={ICONS['ic-left-black.svg']}
                onClick={this.forgetPassword.goBack}
              />
            }
          />
          <Section
            scroll
            justify='center'
            calcHeight='100vh - 70px'

            paddingTop='45'
            paddingBottom='55'
          >
            <Section
              width='275'
            // paddingTop='90'
            >
              {/* <Section
                spacingBottom='45'
                justify='center'
              >
                <Text
                  fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_14}
                  color={VARIABLES.COLORS.GRAY_3}
                >
                  {getLanguage('forgotPassword.labelEnterPhone')}
                </Text>
              </Section> */}
              <Section justify='center' spacingBottom='45'>
                <img src={ICONS['ic-verify-phone-blue.svg']} alt='Icon' />
              </Section>
              <Section
                relative
                spacingBottom='60'
              >
                <Field name='phone'
                  fluid
                  inputTextType='number'
                  className='is-phone'
                  placeholder={getLanguage('field.phoneNumberPlaceholder')}
                  placeholderColor={VARIABLES.COLORS.GRAY_7}
                  height='28'
                  paddingTop='2'
                  paddingBottom='10'
                  paddingLeft='123'
                  bgColor={VARIABLES.COLORS.TRANSPARENT}
                  borderBottomWidth='1'
                  borderBottomStyle='solid'
                  borderBottomColor={VARIABLES.COLORS.GRAY_3}
                  fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_15}
                  fontColor={VARIABLES.COLORS.GRAY_3}
                  fieldIcon={
                    <Image
                      widthRatio='24'
                      widthRatioUnit='px'
                      heightRatio='24'
                      heightRatioUnit='px'
                      src={ICONS['ic-phone-gray.svg']}
                    />
                  }
                  // fieldIconSpacingLeft='5'
                  value={phone}
                  onChange={this.input.onChangeInput}
                />
                <Section className='is-phone-id'>
                  <Grid gutter='5'>
                    <Grid.Column>
                      <Image
                        widthRatio='18'
                        widthRatioUnit='px'
                        heightRatio='12'
                        heightRatioUnit='px'
                        // Thailand   =   ic-flag-th.png
                        // England    =   ic-flag-en.png
                        // Vietnam    =   ic-flag-vt.png
                        // Indonesia  =   ic-flag-in.png
                        // Malaysia   =   ic-flag-ma.png
                        // Lao        =   ic-flag-la.png
                        src={this.input.getCountryFlag()}
                      />
                    </Grid.Column>
                    <Grid.Column>
                      <Section>
                        <select
                          className='select-flag is-gray'
                          onChange={this.input.onChangePhoneCountry}
                          value={selectedCountry}
                        >
                          <option
                            selected={selectedCountry.toLowerCase() === ENUM.COUNTRY.THAI.toLowerCase() ? 'selected' : undefined}
                            value={ENUM.COUNTRY.THAI}
                          >
                            TH +66
                          </option>
                          <option
                            selected={selectedCountry.toLowerCase() === ENUM.COUNTRY.INDONESIA.toLowerCase() ? 'selected' : undefined}
                            value={ENUM.COUNTRY.INDONESIA}
                          >
                            INA +62
                          </option>
                          <option
                            selected={selectedCountry.toLowerCase() === ENUM.COUNTRY.MALAYSIA.toLowerCase() ? 'selected' : undefined}
                            value={ENUM.COUNTRY.MALAYSIA}
                          >
                            MAS +60
                          </option>
                          <option
                            selected={selectedCountry.toLowerCase() === ENUM.COUNTRY.VIETNAM.toLowerCase() ? 'selected' : undefined}
                            value={ENUM.COUNTRY.VIETNAM}
                          >
                            VIE +84
                          </option>
                          <option
                            selected={selectedCountry.toLowerCase() === ENUM.COUNTRY.LAOS.toLowerCase() ? 'selected' : undefined}
                            value={ENUM.COUNTRY.LAOS}
                          >
                            LAOS +856
                          </option>
                        </select>
                      </Section>
                    </Grid.Column>
                  </Grid>
                </Section>
              </Section>
              <Section>
                <Button
                  fluid
                  shadow
                  className='is-gradient-blue is-icon-right'
                  height={VARIABLES.BUTTON.HEIGHTS.H_40}
                  borderRadius={VARIABLES.BUTTON.BORDER_RADIUSES.BR_10}
                  onClick={this.forgetPassword.onClickSubmit}
                >
                  <Button.Icon>
                    <Image
                      widthRatio='15'
                      widthRatioUnit='px'
                      heightRatio='12'
                      heightRatioUnit='px'
                      src={ICONS['ic-arrow-right.svg']}
                    />
                  </Button.Icon>
                  <Text
                    fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_16}
                    color={VARIABLES.COLORS.WHITE}
                  >
                    {getLanguage('field.buttonSubmit')}
                  </Text>
                </Button>
              </Section>
            </Section>
          </Section>
        </Container>
      </TransitionContainer>
  }
}
