import React from 'react'
import {
  Section,
  Image,
  Modal
} from './../../components'
import {
  VARIABLES,
  SHAREDS,
} from './../../themes'

// TODO: MOVE TO COMPONENT
export class Loader extends React.Component {
  render() {
    const {
      loading,
      isOverlay,
      paddingTop,
      widthRatio,
      heightRatio,
    } = this.props

    const loader = (<Section justify='center' paddingTop={paddingTop}>
      <Image
        widthRatio={`${widthRatio ? widthRatio : '80'}`}
        widthRatioUnit='px'
        heightRatio={`${heightRatio ? heightRatio : '80'}`}
        heightRatioUnit='px'
        src={SHAREDS['loader-spinner.svg']}
      // src={SHAREDS['loader-double-spinner.svg']}
      />
    </Section>);
    if (!loading) {
      return null;
    }
    return (
      !isOverlay ? loader : <Modal
        open={loading}
        width={VARIABLES.MODAL.WIDTHS.W_300}
        overlayColor={VARIABLES.COLORS.OVERLAY_4}
        spacing={VARIABLES.MODAL.CONTAINER.C_1.SPACING}
        transition={VARIABLES.TRANSITIONS.DEFAULT}
        transformStart={VARIABLES.MODAL.CONTAINER.C_1.TRANSFORM_START}
        transformEnd={VARIABLES.MODAL.CONTAINER.C_1.TRANSFORM_END}
      >
        {loader}
      </Modal>
    )
  }
}
