import { 
  createStore,
} from 'redux'
import { rootReducer } from '../reducers/reducer';

export let store

export const storeDispatch = (actionCreator) => {
  store.dispatch(actionCreator);
}

export const storeGetState = () => {
  return store.getState()
}

export const getCreateStore = () => {
  store = createStore(
    rootReducer()
  )
  return store
}