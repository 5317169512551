import React from 'react';
import {
  connect,
} from 'react-redux';
import { getLanguage } from '../../helpers/functions/language';
import { getUserAuth } from '../../helpers/functions/auth';
import { ENUM } from '../../helpers/constants/enum';
import { getCityListByProvince, getSubDistrictList } from '../../helpers/functions/data';
import _ from 'lodash';

class SelectSubDistrictContainer extends React.Component {

  render() {
    const {
      value = '',
      valueProvince = '',
      valueCity = '',
    } = this.props;
    let list = getSubDistrictList(valueProvince, valueCity);

    return (
      <React.Fragment>
        <option value=''>{getLanguage('field.subDistrictSelectPlaceholder')}</option>
        {list.map((e, i) => {
          return (
            <option
              key={i}
              selected={e.value.toString() === value.toString()}
              value={e.value}
            >
              {e.label}
            </option>
          )
        })}
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state) => {
  const dataRedux = state.dataRedux;
  return {
    dataList: dataRedux.countryList,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
  }
}

export const SelectSubDistrictContainerConnected = connect(
  mapStateToProps,
  mapDispatchToProps
)(SelectSubDistrictContainer)