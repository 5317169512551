import moment from 'moment';
import 'moment/locale/th';
import React from 'react';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import { luckyDrawController } from '../../apiService/apiController/luckyDrawService';
import { CustomerProfileContainerConnected } from '../../containers/CustomerProfileContainer';
import {
  ModalConfirmWinPrizesContainer,
  ModalNotificationAwardsAnnouncedContainer,
  ModalRewardDetailContainer,
  ModalTermsAndConditionContainer,
} from '../../containers/ModalContainer';
import { getUserAuth } from '../../helpers/functions/auth';
import {
  getIsLanguageTH,
  getSeeAnnouncementLocalStorage,
  setSeeAnnouncementLocalStorage,
} from '../../helpers/functions/data';
import { getLanguage } from '../../helpers/functions/language';
import { isShowLoading } from '../../helpers/functions/main';
import {
  getLuckyDrawAnnouncementList,
  getLuckyDrawCurrentDetail,
  getLuckyDrawTNCCurrent,
} from '../../helpers/services/luckyDraw';
import { getPointUser } from '../../helpers/services/point';
import { ModelLuckyDraw, ModelLuckyDrawTNC } from '../../models/ModelLuckyDraw';
import {
  Button,
  Container,
  Field,
  Grid,
  Image,
  NavigationBar,
  ReactTabsControl,
  Section,
  ShapeContainer,
  Text,
  TransitionContainer,
} from './../../components';
import {
  ROUTE_PATH,
  getRouteData,
  isValidResponse,
  redirect,
} from './../../helpers';
import { ICONS, TYPOGRAPHYS, VARIABLES } from './../../themes';
import { displayWithComma } from '../../helpers/functions/display';

export class WinPrizesPage extends React.Component {
  constructor(props) {
    super(props);
    const routeData = getRouteData(props, 'data') || {};

    this.state = {
      userAuth: getUserAuth(),
      dataLuckyDraw: new ModelLuckyDraw(),
      isOpenLuckyDrawDetailModal: false,

      rewardActiveSlideKeyModal: 0,
      isOpenRewardDetailModal: false,

      dataTNC: new ModelLuckyDrawTNC(),
      isAcceptTNC: undefined,
      isOpenTNCModal: false,

      totalPoint: 0,
      redemptionPoint: 0,
      isOpenConfirmRedemptionPointModal: false,
      isRedemptionSuccess: false,

      dataAnnouncementList: [],

      selectedTabIndex: 0,

      isOpenAlertAnnouncementModal: false,
      routeData,
    };
  }

  componentDidMount() {
    setTimeout(() => {
      const { routeData } = this.state;
      this.winPrize.getData();
      if (routeData.isFromAnnouncement) {
        this.winPrize.onChangeTab(1);
      }
    }, 1);
  }

  handleBack = () => {
    redirect(`${ROUTE_PATH.HOME.LINK}`);
  };

  winPrize = {
    getData: async () => {
      isShowLoading(true);
      await this.winPrize.getLuckyDrawCurrentDetail();
      isShowLoading(false);
    },
    getLuckyDrawCurrentDetail: async () => {
      const dataLuckyDraw = await getLuckyDrawCurrentDetail();
      if (dataLuckyDraw && dataLuckyDraw.id) {
        await this.winPrize.getTotalPoint();
        const paramsTNC = {
          lucky_draw_id: dataLuckyDraw.id,
        };
        const dataTNC = await getLuckyDrawTNCCurrent(paramsTNC);
        const isOpenTNCModal = dataTNC.id && !dataTNC.isNotShowAgain;
        this.setState({
          dataLuckyDraw,
          dataTNC,
          isOpenTNCModal,
          isAcceptTNC: !isOpenTNCModal,
        }, () => {
          if (!isOpenTNCModal) {
            this.winPrize.checkAlertAnnouncement();
          }
        });
      } else {
        this.setState({
          isAcceptTNC: false,
        }, () => {
          this.winPrize.checkAlertAnnouncement();
        });
      }
    },
    onClickAcceptTNC: async (obj) => {
      const { dataLuckyDraw, dataTNC, userAuth } = this.state;
      isShowLoading(true);
      const params = {
        lucky_draw_id: dataLuckyDraw.id,
        lucky_draw_tnc_id: dataTNC.id,
        created_by: userAuth.fullname,
        rbp_member_sfid: userAuth.sfid,
        rbp_member_uid: userAuth.uid,
        is_not_show_again: obj.isNotShowAgain,
      };
      const luckyDrawService = luckyDrawController();
      const res = await luckyDrawService.luckyDrawTNCCurrentAccept(params);
      if (isValidResponse(res)) {
        this.setState({
          isAcceptTNC: true,
          isOpenTNCModal: false,
        }, () => {
          this.winPrize.checkAlertAnnouncement();
          isShowLoading(false);
        });
      } else {
        isShowLoading(false);
      }
    },
    getTotalPoint: async () => {
      const { userAuth } = this.state;
      const params = {
        sfid: userAuth.sfid,
      };
      const data = await getPointUser(params);
      this.setState({
        totalPoint: data.pointLeft,
      });
    },
    onClickOpenLuckyDrawDetailModal: () => {
      this.setState({
        isOpenLuckyDrawDetailModal: true,
      });
    },
    onClickViewRewardDetail: (index) => {
      this.setState({
        rewardActiveSlideKeyModal: index,
        isOpenRewardDetailModal: true,
      });
    },
    onClickRedemption: () => {
      const {
        redemptionPoint,
      } = this.state;
      if (redemptionPoint > 0) {
        this.setState({
          isOpenConfirmRedemptionPointModal: true,
          isRedemptionSuccess: false,
        });
      }
    },
    checkSFRedemptionPoint: () => {
      const {
        totalPoint,
        userAuth,
      } = this.state;
      const params = {
        sfid: userAuth.sfid,
      };
      return new Promise(async (resolve) => {
        const data = await getPointUser(params);
        const newTotalPoint = data.pointLeft;
        if (totalPoint === newTotalPoint) {
          setTimeout(async () => {
            resolve(await this.winPrize.checkSFRedemptionPoint());
          }, 3000);
        } else {
          resolve(newTotalPoint);
        }
      });
    },
    onClickSubmitRedemptionPoint: async () => {
      const {
        userAuth,
        redemptionPoint,
        dataLuckyDraw,
      } = this.state;
      isShowLoading(true);
      const params = {
        lucky_draw_id: dataLuckyDraw.id,
        rbp_member_sfid: userAuth.sfid,
        rbp_member_uid: userAuth.uid,
        location_account: userAuth.locationAccount,
        redemption_date: new Date(),
        redemption_point: redemptionPoint,
        created_by: userAuth.fullname,
      };
      const luckyDrawService = luckyDrawController();
      const res = await luckyDrawService.luckyDrawRedemption(params);
      if (isValidResponse(res)) {
        const newTotalPoint = await this.winPrize.checkSFRedemptionPoint();
        await this.customerProfileRef.customerProfile.getData();
        this.setState({
          isRedemptionSuccess: true,
          redemptionPoint: 0,
          totalPoint: newTotalPoint,
        }, () => {
          isShowLoading(false);
        });
      } else {
        isShowLoading(false);
      }
    },
    //
    // tab
    //
    onChangeTab: (index) => {
      console.log('onChangeTab', index);
      switch (index) {
        case 0:
          break;
        case 1:
          this.winPrize.getLuckyDrawAnnouncementList();
          break;
        default:
          break;
      }
      this.setState({
        selectedTabIndex: index,
      });
    },
    //
    // announcement
    //
    checkAlertAnnouncement: async () => {
      const dataAnnouncementList = await this.winPrize.getLuckyDrawAnnouncementList();
      if (dataAnnouncementList.length) {
        const currentTime = new Date();
        const dataAnnouncement = dataAnnouncementList[0];
        if (currentTime >= new Date(dataAnnouncement.announcementDate)) {
          const seeAnnouncementData = getSeeAnnouncementLocalStorage();
          if (seeAnnouncementData.id !== dataAnnouncement.id) {
            this.setState({
              isOpenAlertAnnouncementModal: true,
            }, () => {
              setSeeAnnouncementLocalStorage({
                id: dataAnnouncement.id,
              });
            });
          }
        }
      }

    },
    onClickCheckAnnouncement: () => {
      const { dataAnnouncementList } = this.state;
      // this.winPrize.onChangeTab(1);
      // this.modal.onClickCloseAlertAnnouncementModal();
      redirect(
        `${ROUTE_PATH.AWARD_ANNOUNCEMENT.LINK}/${dataAnnouncementList[0].id}`
      );
    },
    getLuckyDrawAnnouncementList: async () => {
      isShowLoading(true);
      const dataAnnouncementList = await getLuckyDrawAnnouncementList();
      this.setState({
        dataAnnouncementList,
      }, () => {
        isShowLoading(false);
      });
      return dataAnnouncementList;
    },
    toggleLuckyDrawAnnouncementShowMore: (item) => {
      const { dataAnnouncementList } = this.state;
      this.setState({
        dataAnnouncementList: dataAnnouncementList.map((e) => {
          if (e.id === item.id) {
            e.isShowMore = !e.isShowMore;
          }
          return e;
        }),
      });
    },
  };

  modal = {
    onClickCloseLuckyDrawDetailModal: () => {
      this.setState({
        isOpenLuckyDrawDetailModal: false,
      });
    },
    onClickCloseRewardDetailModal: () => {
      this.setState({
        isOpenRewardDetailModal: false,
      });
    },
    onClickCloseConfirmRedemptionPointModal: () => {
      this.setState({
        isOpenConfirmRedemptionPointModal: false,
      });
    },
    onClickCloseAlertAnnouncementModal: () => {
      this.setState({
        isOpenAlertAnnouncementModal: false,
      });
    },
  };

  input = {
    onChangeRedemptionPoint: (value) => {
      this.setState({
        redemptionPoint: value,
      });
    },
    onChangeRedemptionPointInput: (ev) => {
      const { totalPoint } = this.state;
      let value = parseInt(ev.target.value);
      value = isNaN(value) ? 0 : value;
      this.setState({
        redemptionPoint: value > totalPoint ? totalPoint : value,
      });
    },
  };

  render() {
    const {
      userAuth,
      dataLuckyDraw,
      isOpenLuckyDrawDetailModal,
      isOpenRewardDetailModal,
      rewardActiveSlideKeyModal,

      dataTNC,
      isAcceptTNC,
      isOpenTNCModal,

      totalPoint,
      redemptionPoint,
      isOpenConfirmRedemptionPointModal,
      isRedemptionSuccess,

      dataAnnouncementList,

      selectedTabIndex,

      isOpenAlertAnnouncementModal,
    } = this.state;
    const isLanguageTH = getIsLanguageTH();
    return (
      <TransitionContainer>
        <Container
          width={VARIABLES.WEBSITE_WIDTH}
          calcMinHeight='100vh'
          bgColor={VARIABLES.COLORS.WHITE}
        >
          <NavigationBar
            shadow
            iconWidth='18'
            iconHeight='18'
            iconSrc={ICONS['ic-lucky-draw-black.svg']}
            pageTitleIcon={getLanguage('luckyDraw.title')}
            actionLeft={
              <Image
                widthRatio='11'
                widthRatioUnit='px'
                heightRatio='20'
                heightRatioUnit='px'
                src={ICONS['ic-left-black.svg']}
                onClick={this.handleBack}
              />
            }
          />
          <ReactTabsControl>
            <Tabs
              selectedIndex={selectedTabIndex}
              onSelect={this.winPrize.onChangeTab}
            >
              <TabList>
                <Tab>
                  <Text color={VARIABLES.COLORS.BLACK}>
                    {getLanguage('luckyDraw.winPrizes')}
                  </Text>
                </Tab>
                <Tab>
                  <Text color={VARIABLES.COLORS.BLACK}>
                    {getLanguage('luckyDraw.awardAnnouncement')}
                  </Text>
                </Tab>
              </TabList>
              <Section scroll calcHeight='100vh - 106px'>
                <CustomerProfileContainerConnected
                  ref={(r) => (this.customerProfileRef = r)}
                />
                <TabPanel>
                  {isAcceptTNC === undefined ? null : dataLuckyDraw.id ? (
                    <React.Fragment>
                      <Section
                        paddingHorizontal='20'
                        paddingTop='24'
                        paddingBottom='10'
                      >
                        <Section justify='center' spacingBottom='10'>
                          <Text
                            fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_14}
                            color={VARIABLES.COLORS.PRIMARY_2}
                          >
                            {getLanguage('luckyDraw.usePointToWinPrizes')}
                          </Text>
                        </Section>
                        <Section justify='center' spacingBottom='20'>
                          <Grid gutter='8' justify='center'>
                            <Grid.Column>
                              <Text
                                fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_18}
                                color={VARIABLES.COLORS.BLACK}
                              >
                                1 {getLanguage('luckyDraw.point')}
                              </Text>
                            </Grid.Column>
                            <Grid.Column>
                              <Text
                                fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_18}
                                color={VARIABLES.COLORS.BLACK}
                              >
                                =
                              </Text>
                            </Grid.Column>
                            <Grid.Column>
                              <Text
                                fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_18}
                                color={VARIABLES.COLORS.BLACK}
                              >
                                1 {getLanguage('luckyDraw.right')}
                              </Text>
                            </Grid.Column>
                          </Grid>
                        </Section>
                        <Section spacingBottom='20' justify='center'>
                          <Image
                            fill
                            borderRadius='10'
                            widthRatio='335'
                            widthRatioUnit='px'
                            heightRatio='200'
                            heightRatioUnit='px'
                            src={dataLuckyDraw.thumbnailPath}
                          />
                        </Section>
                        <Section spacingBottom='10' justify='center'>
                          <Text fontStyle={TYPOGRAPHYS.FIRST.BOLD.FS_14}>
                            {getLanguage('luckyDraw.numberOfUsedPoints')}
                          </Text>
                        </Section>
                        <Section spacingBottom='34'>
                          <Grid gutter='23' justify='center' align='center'>
                            <Grid.Column>
                              <Image
                                widthRatio='44'
                                widthRatioUnit='px'
                                heightRatio='44'
                                heightRatioUnit='px'
                                src={
                                  redemptionPoint === 0
                                    ? ICONS['ic-minus-circle-disabled.svg']
                                    : ICONS['ic-minus-circle.svg']
                                }
                                onClick={() => {
                                  if (redemptionPoint > 0) {
                                    this.input.onChangeRedemptionPoint(
                                      redemptionPoint - 1
                                    );
                                  }
                                }}
                              />
                            </Grid.Column>
                            <Grid.Column>
                              <Field
                                width='134'
                                height='40'
                                paddingRight={15}
                                paddingLeft={15}
                                textAlign='center'
                                fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_14}
                                fontColor={VARIABLES.COLORS.BLACK}
                                fontLineHeight={VARIABLES.FIELD.HEIGHT}
                                borderWidth='1'
                                borderStyle='solid'
                                borderColor={VARIABLES.COLORS.GRAY_8}
                                // error
                                borderRadius={20}
                                value={redemptionPoint}
                                inputMode='numeric'
                                onChange={
                                  this.input.onChangeRedemptionPointInput
                                }
                              />
                            </Grid.Column>
                            <Grid.Column>
                              <Image
                                widthRatio='44'
                                widthRatioUnit='px'
                                heightRatio='44'
                                heightRatioUnit='px'
                                src={
                                  redemptionPoint < totalPoint
                                    ? ICONS['ic-plus-circle-active.svg']
                                    : ICONS['ic-plus-circle-disabled.svg']
                                }
                                onClick={() => {
                                  if (redemptionPoint < totalPoint) {
                                    this.input.onChangeRedemptionPoint(
                                      redemptionPoint + 1
                                    );
                                  }
                                }}
                              />
                            </Grid.Column>
                          </Grid>

                          <Section justify='center' spacingTop='5'>
                            <Text
                              fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_12}
                              color={VARIABLES.COLORS.RED_1}
                            >
                              {getLanguage('luckyDraw.youHaveAMaximumRedeemablePointOfPoint')
                                .replace(
                                  '[point]',
                                  displayWithComma(totalPoint)
                                )}
                            </Text>
                          </Section>

                        </Section>
                        <Section justify='center' spacingBottom='23'>
                          <Button
                            className={
                              redemptionPoint === 0 ? '' : 'is-gradient-blue'
                            }
                            shadow
                            height={VARIABLES.BUTTON.HEIGHTS.H_40}
                            borderRadius={
                              VARIABLES.BUTTON.BORDER_RADIUSES.BR_10
                            }
                            width={VARIABLES.BUTTON.WIDTHS.W_275}
                            disabled={redemptionPoint === 0}
                            onClick={this.winPrize.onClickRedemption}
                          >
                            <Text
                              fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_16}
                              color={VARIABLES.COLORS.WHITE}
                            >
                              {getLanguage('luckyDraw.buttonWinPrizes')}
                            </Text>
                          </Button>
                        </Section>
                        <Section>
                          <Text
                            fontStyle={TYPOGRAPHYS.FIRST.BOLD.FS_20}
                            color={VARIABLES.COLORS.BLACK}
                          >
                            {getLanguage('luckyDraw.reward')}
                          </Text>
                        </Section>
                        <Section
                          padding='10'
                          scroll
                          maxWidth='374'
                          spacingBottom='24'
                        >
                          <Grid gutterVertical='10' gutter='10'>
                            {dataLuckyDraw.rewardList.map((e, i) => {
                              return (
                                <Grid.Column key={i}>
                                  <ShapeContainer
                                    bgColor={VARIABLES.COLORS.WHITE}
                                    padding='10'
                                    borderRadius='10'
                                    boxShadow={
                                      '0px 0px 4px 0px rgba(0, 0, 0, 0.20)'
                                    }
                                  >
                                    <Section
                                      spacingBottom='10'
                                      spacingTop='5'
                                      justify='center'
                                      width={143}
                                      height={80}
                                    >
                                      <Image fill src={e.rewardImagePath} />
                                    </Section>
                                    <Section
                                      align='center'
                                      spacingBottom='10'
                                      justify='center'
                                      maxWidth='143'
                                    >
                                      <Section
                                        fluid
                                        align='center'
                                        justify='center'
                                        height='26'
                                        bgColor='#F8E377'
                                        borderRadius='4'
                                        padding='4'
                                        spacingBottom='8'
                                      >
                                        <Text
                                          align='center'
                                          verticalAlign='center'
                                          fontStyle={
                                            TYPOGRAPHYS.FIRST.BOLD.FS_11
                                          }
                                          color={VARIABLES.COLORS.BLACK}
                                          title={e.rewardName}
                                          ellipsis
                                          singleLine
                                        >
                                          {e.rewardName}
                                        </Text>
                                      </Section>
                                      <Section
                                        align='center'
                                        justify='center'
                                      >
                                        <Text
                                          align='center'
                                          verticalAlign='center'
                                          fontStyle={
                                            TYPOGRAPHYS.FIRST.REGULAR.FS_11
                                          }
                                          color={VARIABLES.COLORS.BLACK}
                                          singleLine
                                        >
                                          {getLanguage(
                                            'luckyDraw.rewardTitleQuantity'
                                          ).replace(
                                            '[quantity]',
                                            e.quantity
                                          )}
                                        </Text>
                                      </Section>
                                    </Section>
                                    <Section justify='center'>
                                      <Button
                                        borderRadius={
                                          VARIABLES.BUTTON.BORDER_RADIUSES.BR_10
                                        }
                                        width={70}
                                        height={18}
                                        bgColor={VARIABLES.COLORS.PRIMARY_2}
                                        onClick={() => {
                                          this.winPrize.onClickViewRewardDetail(
                                            i
                                          );
                                        }}
                                      >
                                        <Text
                                          fontStyle={
                                            TYPOGRAPHYS.FIRST.MEDIUM.FS_10
                                          }
                                          color={VARIABLES.COLORS.WHITE}
                                        >
                                          {getLanguage('luckyDraw.viewDetail')}
                                        </Text>
                                      </Button>
                                    </Section>
                                  </ShapeContainer>
                                </Grid.Column>
                              );
                            })}
                          </Grid>
                        </Section>
                        <Section
                          onClick={
                            this.winPrize.onClickOpenLuckyDrawDetailModal
                          }
                        >
                          <ShapeContainer
                            fluid
                            className='is-card-read-term'
                            borderRadius={10}
                            paddingTop={13}
                            paddingBottom={11}
                            paddingHorizontal={18}
                          >
                            <Section spacingBottom='7'>
                              <Text
                                fontStyle={TYPOGRAPHYS.FIRST.BOLD.FS_16}
                                color={VARIABLES.COLORS.BLACK}
                              >
                                {getLanguage('luckyDraw.campaignDurationDate')
                                  .replace(
                                    '[startDate]',
                                    dataLuckyDraw.startDate
                                      ? moment(dataLuckyDraw.startDate)
                                        .locale(isLanguageTH ? 'th' : 'en')
                                        .format('D MMM YYYY')
                                      : '-'
                                  )
                                  .replace(
                                    '[endDate]',
                                    dataLuckyDraw.endDate
                                      ? moment(dataLuckyDraw.endDate)
                                        .locale(isLanguageTH ? 'th' : 'en')
                                        .format('D MMM YYYY')
                                      : '-'
                                  )}
                              </Text>
                            </Section>
                            <Section spacingBottom='10'>
                              <Text
                                fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_14}
                                color={VARIABLES.COLORS.BLACK}
                              >
                                {`${getLanguage(
                                  'luckyDraw.campaignLuckyDrawDate'
                                )} ${dataLuckyDraw.luckyDrawDate
                                  ? moment(dataLuckyDraw.luckyDrawDate)
                                    .locale(isLanguageTH ? 'th' : 'en')
                                    .format('D MMM YYYY')
                                  : '-'
                                  }`}
                              </Text>
                            </Section>
                            <Section justify='flex-end'>
                              <ShapeContainer
                                borderRadius={9}
                                bgColor={VARIABLES.COLORS.WHITE}
                                width={156}
                                height={18}
                                align='center'
                              >
                                <Text
                                  top='2'
                                  fontStyle={TYPOGRAPHYS.FIRST.BOLD.FS_10}
                                  color={VARIABLES.COLORS.PRIMARY_2}
                                >
                                  {getLanguage(
                                    'luckyDraw.readTermsAndConditions'
                                  )}
                                </Text>
                              </ShapeContainer>
                            </Section>
                          </ShapeContainer>
                        </Section>
                      </Section>
                    </React.Fragment>
                  ) : (
                    <Section
                      calcHeight='100vh - 228px'
                      bgColor={VARIABLES.COLORS.GRAY_13}
                      justify='center'
                      align='center'
                    >
                      <Text
                        color={VARIABLES.COLORS.PRIMARY_2}
                        fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_16}
                      >
                        {getLanguage('luckyDraw.noLuckyDrawEvent')}
                      </Text>
                    </Section>
                  )}
                </TabPanel>
                <TabPanel>
                  {dataAnnouncementList.length > 0 ? (
                    <Section
                      paddingHorizontal='32'
                      paddingTop='25'
                      paddingBottom='40'
                    >
                      <Section spacingBottom='10'>
                        <Text
                          color={VARIABLES.COLORS.BLACK}
                          fontStyle={TYPOGRAPHYS.FIRST.BOLD.FS_20}
                        >
                          {getLanguage('luckyDraw.announcement')}
                        </Text>
                      </Section>
                      {dataAnnouncementList.map((e, i) => {
                        return (
                          <Section spacingBottom='10' key={i}>
                            <Section
                              justify='center'
                              fluid
                              bgColor={
                                i === 0
                                  ? VARIABLES.COLORS.PRIMARY_2
                                  : VARIABLES.COLORS.BLUE_8
                              }
                              className='is-bg-logo'
                              padding='15'
                              borderRadius='10'
                              onClick={() => {
                                redirect(
                                  `${ROUTE_PATH.AWARD_ANNOUNCEMENT.LINK}/${e.id}`
                                );
                              }}
                            >
                              <React.Fragment>
                                <Section justify='center' spacingBottom='10'>
                                  <Text
                                    fontStyle={TYPOGRAPHYS.FIRST.BOLD.FS_14}
                                    color={VARIABLES.COLORS.WHITE}
                                  >
                                    {getLanguage(
                                      'luckyDraw.announcementOfNameWinners'
                                    )}
                                  </Text>
                                </Section>
                                <Section
                                  overflowHidden
                                  justify='center'
                                  spacingBottom='10'
                                >
                                  <Section justify='center' spacingBottom='5'>
                                    <Text
                                      fontStyle={TYPOGRAPHYS.FIRST.BOLD.FS_22}
                                      color={VARIABLES.COLORS.WHITE}
                                      align='center'
                                    >
                                      {getLanguage('luckyDraw.label.campaign')}
                                    </Text>
                                  </Section>
                                  <Section paddingHorizontal='8'>
                                    <Grid gutter='2' justify='center'>
                                      <Grid.Column>
                                        <Text
                                          fontStyle={
                                            TYPOGRAPHYS.FIRST.BOLD.FS_22
                                          }
                                          color={VARIABLES.COLORS.WHITE}
                                          align='center'
                                        >
                                          "
                                        </Text>
                                      </Grid.Column>
                                      <Grid.Column>
                                        <Section overflowHidden maxWidth='282'>
                                          <Text
                                            fontStyle={
                                              TYPOGRAPHYS.FIRST.BOLD.FS_22
                                            }
                                            color={VARIABLES.COLORS.WHITE}
                                            align='center'
                                            breakAll={e.isShowMore}
                                            noWrap={!e.isShowMore}
                                          >
                                            {e.campaignName}{' '}
                                            {e.isShowMore ? '"' : ''}
                                          </Text>
                                        </Section>
                                      </Grid.Column>
                                      {!e.isShowMore && (
                                        <Grid.Column>
                                          <Text
                                            fontStyle={
                                              TYPOGRAPHYS.FIRST.BOLD.FS_22
                                            }
                                            color={VARIABLES.COLORS.WHITE}
                                            align='center'
                                          >
                                            "
                                          </Text>
                                        </Grid.Column>
                                      )}
                                    </Grid>
                                  </Section>
                                </Section>
                                {e.campaignName.length >= 22 && (
                                  <Section spacingBottom={10} justify='center'>
                                    <Text
                                      fontStyle={TYPOGRAPHYS.FIRST.MEDIUM.FS_12}
                                      color={VARIABLES.COLORS.WHITE}
                                      underlineWhite
                                      onClick={(ev) => {
                                        ev.stopPropagation();
                                        this.winPrize.toggleLuckyDrawAnnouncementShowMore(
                                          e
                                        );
                                      }}
                                    >
                                      {e.isShowMore
                                        ? getLanguage('luckyDraw.showLess')
                                        : getLanguage('luckyDraw.showMore')}
                                    </Text>
                                  </Section>
                                )}
                              </React.Fragment>
                              <Section justify='center'>
                                <Text
                                  fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_12}
                                  color={VARIABLES.COLORS.WHITE}
                                >
                                  {getLanguage('luckyDraw.campaignDurationDate')
                                    .replace(
                                      '[startDate]',
                                      e.startDate
                                        ? moment(e.startDate)
                                          .locale(isLanguageTH ? 'th' : 'en')
                                          .format('D MMM YYYY')
                                        : '-'
                                    )
                                    .replace(
                                      '[endDate]',
                                      e.endDate
                                        ? moment(e.endDate)
                                          .locale(isLanguageTH ? 'th' : 'en')
                                          .format('D MMM YYYY')
                                        : '-'
                                    )}
                                </Text>
                              </Section>
                            </Section>
                          </Section>
                        );
                      })}
                    </Section>
                  ) : (
                    <Section
                      calcHeight='100vh - 228px'
                      bgColor={VARIABLES.COLORS.GRAY_13}
                      justify='center'
                      align='center'
                    >
                      <Text
                        color={VARIABLES.COLORS.PRIMARY_2}
                        fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_16}
                      >
                        {getLanguage('luckyDraw.noAwardAnnouncement')}
                      </Text>
                    </Section>
                  )}
                </TabPanel>
              </Section>
            </Tabs>
          </ReactTabsControl>
        </Container>
        {isOpenAlertAnnouncementModal && (
          <ModalNotificationAwardsAnnouncedContainer
            isShowModal
            onClickClose={this.modal.onClickCloseAlertAnnouncementModal}
            onClickCheckMyName={this.winPrize.onClickCheckAnnouncement}
          />
        )}
        {isOpenConfirmRedemptionPointModal && (
          <ModalConfirmWinPrizesContainer
            isShowModal
            redemptionPoint={redemptionPoint}
            totalPoint={totalPoint}
            isSuccess={isRedemptionSuccess}
            onClickClose={this.modal.onClickCloseConfirmRedemptionPointModal}
            onClickConfirm={this.winPrize.onClickSubmitRedemptionPoint}
          />
        )}
        {isOpenTNCModal && (
          <ModalTermsAndConditionContainer
            isShowModal
            data={dataTNC}
            onClickBack={this.handleBack}
            onAccept={this.winPrize.onClickAcceptTNC}
          />
        )}
        {isOpenLuckyDrawDetailModal && (
          <ModalTermsAndConditionContainer
            isShowModal
            isHideAccpetButton
            data={{
              detail: dataLuckyDraw.campaignTNC,
            }}
            onClickBack={this.modal.onClickCloseLuckyDrawDetailModal}
          />
        )}
        {isOpenRewardDetailModal && (
          <ModalRewardDetailContainer
            isShowModal
            activeSlideKey={rewardActiveSlideKeyModal}
            dataList={dataLuckyDraw.rewardList}
            onClickClose={this.modal.onClickCloseRewardDetailModal}
          />
        )}
      </TransitionContainer>
    );
  }
}
