import React from 'react'
import _ from 'lodash'
import axios from 'axios'
// import qs from 'qs'
import {
  TransitionContainer,
  Container,
  Image,
  Section,
  Text,
  Field,
  NavigationBar,
  Button,
  Alert,
} from './../../components'
import {
  VARIABLES,
  TYPOGRAPHYS,
  ICONS,
} from './../../themes'
import {
  ROUTE_PATH,
  redirect,
  isValidResponse,
} from './../../helpers'
import {
  Loader
} from '../Loader'
import config from './../../config'
import { getLanguage } from '../../helpers/functions/language'
import { isShowLoading, onOpenAlert } from '../../helpers/functions/main'
import { DATA } from '../../helpers/constants/data'
import { getUserAuth } from '../../helpers/functions/auth'

export class FindDealerSearchByAreaPage extends React.Component {
  constructor(props) {
    super(props);
    // const query = qs.parse(_.get(this.props, 'location.search', '').replace('?', ''))
    const type = localStorage.getItem('dealerType')
    this.state = {
      type,
      provinceSelected: '',
      districtSelected: '',
      areaSelected: '',
      areaOptionList: [],
      isLoading: false,
      showModal: false,
    }
  }

  componentDidMount() {
    this.findDealer.getAreaList();
  }

  findDealer = {
    getAreaList: async () => {
      isShowLoading(true);
      const userAuth = getUserAuth();
      const url = `${config.api.cms}/bsad/area`;
      const params = {
        countries: userAuth.countries,
      }
      const response = await axios.post(url, params);
      const dataList = _.get(response, 'data.data');
      console.log('dataList', dataList)
      if (dataList) {
        this.setState({
          areaOptionList: dataList.filter(e => e.trueblue_area__c)
        })
        isShowLoading(false);
      }
      else {
        isShowLoading(false);
        onOpenAlert({
          type: DATA.ALERT_TYPE.ERROR,
          message: getLanguage('message.dataNotFound')
        })
      }
    }
  }

  onChangeField(key, event) {
    this.setState({ [key]: event.target.value })
  }

  onClickSearch() {
    const {
      // provinceSelected, 
      // districtSelected, 
      type,
      areaSelected,
    } = this.state
    // if (!provinceSelected && !districtSelected) return
    if (!areaSelected) return;

    this.setState({ isLoading: true }, async () => {
      try {
        const url = `${config.api.cms}/bsad/search/`
        const options = {
          // province: provinceSelected,
          // amphor: districtSelected,
          trueblue_area__c: areaSelected,
          status: 'active',
          type
        }
        const response = await axios.post(url, options)
        const data = _.get(response, 'data.data')
        if (response.status === 200 && !_.isEmpty(data)) {
          localStorage.setItem('dealerList', JSON.stringify(data))
          redirect(ROUTE_PATH.FIND_DEALER_SEARCH_RESULT.LINK)
        } else {
          this.setState({ showModal: true })
        }
      } catch (_) {
      } finally {
        this.setState({ isLoading: false })
      }
    })
  }

  onCloseModal() {
    this.setState({ showModal: false })
  }

  // handleBack() {
  //   redirect(ROUTE_PATH.FIND_DEALER_TYPE.LINK)
  // }

  render() {
    const {
      provinceSelected,
      districtSelected,
      isLoading,
      showModal,
      areaOptionList,
      areaSelected,
    } = this.state
    return (
      <TransitionContainer>
        <Container
          width={VARIABLES.WEBSITE_WIDTH}
          calcMinHeight='100vh'
          bgColor={VARIABLES.COLORS.LIGHT_BLUE_2}
        >
          <NavigationBar
            pageTitle={getLanguage('findDealerSearchByArea.title')}
            actionLeft={
              <Image
                widthRatio='11'
                widthRatioUnit='px'
                heightRatio='21'
                heightRatioUnit='px'
                src={ICONS['ic-left-black.svg']}
                // onClick={this.handleBack.bind(this)}
                onClick={this.props.history.goBack}
              />
            }
          />
          <Section
            paddingHorizontal='20'
            paddingTop='33'
            justify='center'
          >
            {/* <Section spacingBottom='15'>
              <Field
                type='select'
                fluid
                height='40'
                paddingRight='10'
                paddingLeft='25'
                bgColor={VARIABLES.COLORS.TRANSPARENT}
                borderWidth='1'
                borderStyle='solid'
                borderColor={VARIABLES.COLORS.BLUE_2}
                borderRadius='20'
                fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_14}
                fontColor={VARIABLES.COLORS.BLUE_2}
                id='dealer-province-select'
                name='dealer-province-select'
                fieldIcon={<img src={ICONS['ic-arrow-down-blue.svg']} alt='Icon' />}
                fieldIconWidth='41'
                fieldIconSpacingRight='10'
                value={provinceSelected}
                onChange={this.onChangeField.bind(this, 'provinceSelected')}
              >
                <option value="" disabled selected hidden>{getLanguage('field.selectProvincePlaceholder')}</option>
                <option value="amnatcharoen">{getLanguage('findDealerSearchByArea.province.amnatcharoen')}</option>
                <option value="ayudthya">{getLanguage('findDealerSearchByArea.province.ayudthya')}</option>
                <option value="bangkok">{getLanguage('findDealerSearchByArea.province.bangkok')}</option>
                <option value="bueng kan">{getLanguage('findDealerSearchByArea.province.buengKan')}</option>
                <option value="burirum">{getLanguage('findDealerSearchByArea.province.burirum')}</option>
              </Field>
            </Section>
            <Section>
              <Field
                type='select'
                fluid
                height='40'
                paddingRight='10'
                paddingLeft='25'
                bgColor={VARIABLES.COLORS.TRANSPARENT}
                borderWidth='1'
                borderStyle='solid'
                borderColor={VARIABLES.COLORS.BLUE_2}
                borderRadius='20'
                fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_14}
                fontColor={VARIABLES.COLORS.BLUE_2}
                id='dealer-district-select'
                name='dealer-district-select'
                fieldIcon={<img src={ICONS['ic-arrow-down-blue.svg']} alt='Icon' />}
                fieldIconWidth='41'
                fieldIconSpacingRight='10'
                value={districtSelected}
                onChange={this.onChangeField.bind(this, 'districtSelected')}
              >
                <option value="" disabled selected hidden>{getLanguage('field.selectAmphoePlaceholder')}</option>
                <option value="thunyaburi">{getLanguage('findDealerSearchByArea.amphoe.thunyaburi')}</option>
                <option value="latkrabang">{getLanguage('findDealerSearchByArea.amphoe.latkrabang')}</option>
                <option value="suanluang">{getLanguage('findDealerSearchByArea.amphoe.suanluang')}</option>
                <option value="bang khen">{getLanguage('findDealerSearchByArea.amphoe.bangKhen')}</option>
                <option value="bangbon">{getLanguage('findDealerSearchByArea.amphoe.bangbon')}</option>
              </Field>
            </Section> */}
            <Section>
              <Field
                type='select'
                fluid
                height='40'
                paddingRight='10'
                paddingLeft='25'
                bgColor={VARIABLES.COLORS.TRANSPARENT}
                borderWidth='1'
                borderStyle='solid'
                borderColor={VARIABLES.COLORS.BLUE_2}
                borderRadius='20'
                fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_14}
                fontColor={VARIABLES.COLORS.BLUE_2}
                id=''
                name=''
                fieldIcon={<img src={ICONS['ic-arrow-down-blue.svg']} alt='Icon' />}
                fieldIconWidth='41'
                fieldIconSpacingRight='10'
                value={areaSelected}
                onChange={this.onChangeField.bind(this, 'areaSelected')}
              >
                <option value='' disabled selected hidden>
                  {getLanguage('field.selectAreaPlaceholder')}
                </option>
                {areaOptionList.map((e, i) => {
                  return (
                    <option
                      key={i}
                      value={e.trueblue_area__c}
                    >
                      {e.trueblue_area__c}
                    </option>
                  )
                })}
              </Field>
            </Section>
            <Section
              paddingTop='56'
              justify='center'>
              <Button
                shadow
                className='is-gradient-blue'
                width='275'
                height={VARIABLES.BUTTON.HEIGHTS.H_40}
                borderRadius={VARIABLES.BUTTON.BORDER_RADIUSES.BR_10}
                onClick={this.onClickSearch.bind(this)}
              >
                <Text
                  fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_16}
                  color={VARIABLES.COLORS.WHITE}
                >
                  {getLanguage('field.buttonSearch')}
                </Text>
              </Button>
            </Section>
          </Section>
        </Container>
        <Alert type='error' title='Data not found' onClose={this.onCloseModal.bind(this)} open={showModal} />
        <Loader loading={isLoading} isOverlay />
      </TransitionContainer>
    )
  }
}