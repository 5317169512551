import { FIREBASE_PUBLIC_STORAGE_PATH } from './config'

const config = {
    api: {
        base: '',
        cms: 'https://rbpcms-prod.herokuapp.com/api',
        trueblue: 'https://rbptrueblue-prod.herokuapp.com/api'
    },
    imgPrefix: FIREBASE_PUBLIC_STORAGE_PATH,
}

export default config;