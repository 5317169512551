import styled, {
  css
} from 'styled-components'
import {
  default as VARIABLES
} from './../../../themes/styles/bases/variables'
// import {
//   default as TYPOGRAPHYS
// } from './../../../themes/styles/bases/typographys'
// import {
//   default as MIXINS
// } from './../../../themes/styles/helpers/mixins'

// Sample using image in style
// url(${require('./images/icons/icon-sample.svg')});
// url(${require('./../../../themes/images/icons/icon-sample.svg')});

// Wrapper
// ============================================================
export const ChartBaseWrapper = styled.div`
  /* Parent
  ------------------------------- */
  width: 100%;

  /* Childrens
  ------------------------------- */
  .chart-progress-base {
    display: flex;
    align-items: center;
  }

  .chart-progress-container-base {
    ${props => {
      const theme = props.theme
      return css`
        ${theme.progressWidth && css`
            width: ${theme.progressWidth + theme.progressWidthUnit};
          `
        }

        ${theme.progressHeight && css`
            height: ${theme.progressHeight + `px`};
          `
        }
      `
    }}
  }

  .chart-progress-track-base {
    height: 100%;

    ${props => {
      const theme = props.theme
      return css`
        ${theme.progressTrackPadding && css`
            padding: ${theme.progressTrackPadding + `px`};
          `
        }

        background-color: ${theme.progressTrackColor};

        ${theme.progressHeight && css`
            border-radius: ${(theme.progressHeight - (theme.progressTrackPadding*2)) + `px`};
          `
        }

        .chart-progress-percent-base {
          transition: all 0.5s ${VARIABLES.ANIMATION_TIMINGS.ELASTIC};
          height: 100%;

          ${props => {
            const theme = props.theme
            return css`
              background-color: ${theme.progressColor};

              ${theme.progressHeight && css`
                  border-radius: ${(theme.progressHeight - (theme.progressTrackPadding*2))/2 + `px`};
                `
              }
            `
          }}
        }
      `
    }}
  }

  .chart-progress-percent-number-base {
    ${props => {
      const theme = props.theme
      return css`
        ${theme.percentNumberFontStyle}

        ${theme.percentNumberSpacingLeft && css`
            margin-left: ${theme.percentNumberSpacingLeft + `px`};
          `
        }
      `
    }}
  }

  /* Modifiers
  ------------------------------- */
  /* Media queries
  ------------------------------- */
`
