/* eslint-disable no-empty-pattern */
import apiService from "../apiService";

export const userController = (configService = {}) => {
  const service = apiService(configService);
  return {
    // usersRegister: (params) => {
    //   return service.post(`/api/users/register`, params);
    // },
    // searchUser: (params) => {
    //   return service.post('/api/users/search', params);
    // },
    getUserBy: (params) => {
      return service.post('/api/users/', params);
    },
    // getUserByUid: (params) => {
    //   return service.post('/api/users/uid', params);
    // },
    // usersIsExistByUid: (params) => {
    //   return service.post('/api/users/isexistbyuid', params);
    // },
    getUserById: (params) => {
      return service.post('/api/users/id', params);
    },
    usersUpload: (params, { }) => {
      return service.post('/api/users/upload', params, {});
    },
    updateUserInfo: (params) => {
      return service.put('/api/users/', params);
    },
    // usersConfirmProfile: (params) => {
    //   return service.post('/api/users/confirmProfile', params);
    // },
    // usersChangePassword: (params) => {
    //   return service.put('/api/users/password', params);
    // },
    // deleteUser: (params) => {
    //   return service.delete('/api/users/', params);
    // },
    getValidateUser: (params) => {
      return service.post('/api/users/validate', params);
    },
    checkMemberByPhone: (params) => {
      return service.post('/api/users/check-member-by-phone', params);
    },
  }
}