import React from 'react'
import {
  connect,
} from 'react-redux';
import { Modal } from '../../components';

export class AlertVersionContainer extends React.Component {

  constructor(props) {
    super(props);
    this.state = {}
  }

  render() {
    const {
      isOpen,
      title,
      buttonSubmitName,
      onClickSubmit,
      onClickCancel,
    } = this.props;
    return (
      <Modal ui='confirm'
        open={isOpen}
        title={title}
        buttonSubmitName={buttonSubmitName}
        onClickSubmit={onClickSubmit}
        onClickCancel={onClickCancel}
      />
    )
  }
}

const mapStateToProps = (state) => {
  const dataRedux = state.dataRedux;
  const data = dataRedux.appVersion;
  return {
    isOpen: data.isOpen,
    title: data.title,
    buttonSubmitName: data.buttonSubmitName,
    onClickSubmit: data.onClickSubmit,
    onClickCancel: data.onClickCancel,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
  }
}

export const AlertVersionContainerConnected = connect(
  mapStateToProps,
  mapDispatchToProps
)(AlertVersionContainer)