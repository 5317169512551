/* eslint-disable no-empty-pattern */
import apiService from "../apiService";

export const projectController = (configService = {}) => {
  const service = apiService(configService);
  return {
    getProjectByUserID: (params) => {
      return service.get(`/api/projects/${params.user_id}`)
    },
    getsearchProjectByUserId: (params) => {
      return service.post(`/api/projects/search/${params.user_id}`, params)
    },
    projectUpload: (params, { }) => {
      return service.post(`/api/projects/upload`, params, {});
    },
    createProjectById: (params) => {
      return service.post(`/api/projects/${params.id}`, params)
    },
    updateProjectById: (params) => {
      return service.put('/api/projects/', params);
    },
    deleteProjectById: (params) => {
      return service.delete(`/api/projects/${params.id}`)
    }
  }
}