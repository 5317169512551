
export class ModelAd {
  constructor(props) {
    props = props || {};
    this.location_account__c = props.location_account__c;
    this.sync_customer__c = props.sync_customer__c;
    this.contact__c = props.contact__c;
    this.road__c = props.road__c;
    this.member_type__c = props.member_type__c;
    this.company__c = props.company__c;
    this.email__c = props.email__c;
    this.old_points__c = props.old_points__c;
    this.identification_number__c = props.identification_number__c;
    this.first_name__c = props.first_name__c;
    this.name = props.name;
    this.role__c = props.role__c;
    this.country__c = props.country__c;
    this.last_name__c = props.last_name__c;
    this.isdeleted = props.isdeleted;
    this.systemmodstamp = props.systemmodstamp;
    this.uid__c = props.uid__c;
    this.province__c = props.province__c;
    this.phone__c = props.phone__c;
    this.district__c = props.district__c;
    this.birth_date__c = props.birth_date__c;
    this.createddate = props.createddate;
    this.post_code__c = props.post_code__c;
    this.city__c = props.city__c;
    this.house_number__c = props.house_number__c;
    this.accept_terms_conditions__c = props.accept_terms_conditions__c;
    this.dealer_account__c = props.dealer_account__c;
    this.nick_name__c = props.nick_name__c;
    this.trained__c = props.trained__c;
    this.contact_channel__c = props.contact_channel__c;
    this.contractor_type__c = props.contractor_type__c;
    this.sfid = props.sfid;
    this.id = props.id;
    this._hc_lastop = props._hc_lastop;
    this._hc_err = props._hc_err;
    this.ward__c = props.ward__c;
    this.alley__c = props.alley__c;
    this.hamlet__c = props.hamlet__c;
    this.shipping_province__c = props.shipping_province__c;
    this.shipping_district__c = props.shipping_district__c;
    this.shipping_city__c = props.shipping_city__c;
    this.shipping_post_code__c = props.shipping_post_code__c;
    this.shipping_house_number__c = props.shipping_house_number__c;
    this.shipping_road__c = props.shipping_road__c;
    this.shipping_country__c = props.shipping_country__c;
    this.course_name_3__c = props.course_name_3__c;
    this.course_name_2__c = props.course_name_2__c;
    this.course_name_1__c = props.course_name_1__c;
    this.start_date_start_working_day__c = props.start_date_start_working_day__c;
    this.position__c = props.position__c;
    this.course_date_5__c = props.course_date_5__c;
    this.course_date_4__c = props.course_date_4__c;
    this.course_date_3__c = props.course_date_3__c;
    this.course_date_2__c = props.course_date_2__c;
    this.course_name_5__c = props.course_name_5__c;
    this.course_date_1__c = props.course_date_1__c;
    this.course_name_4__c = props.course_name_4__c;
    this.trained_tier__c = props.trained_tier__c;
    this.security_answer_3__c = props.security_answer_3__c;
    this.security_answer_2__c = props.security_answer_2__c;
    this.security_answer_1__c = props.security_answer_1__c;
    this.line_uid__c = props.line_uid__c;
    this.public_image_url__c = props.public_image_url__c;
    this.acc_name = props.acc_name;
    this.business_reg_no = props.business_reg_no;
    this.mobile_no = props.mobile_no;
    this.phone_no = props.phone_no;
    this.address = props.address;
    this.post_code = props.post_code;
    this.image_url = props.image_url;
    this.company = props.company;
    this.email = props.email;
    this.created_at = props.created_at;
    this.ad_ref_id = props.ad_ref_id;
    this.accept_terms_conditions = props.accept_terms_conditions;
    this.type = props.type;

  }
}