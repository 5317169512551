/* eslint-disable no-lone-blocks */

export class ModelPurchaseHistory {
  constructor(props) {
    props = props || {};
    this.id = props.id;
    this.order_no = props.order_no;
    this.coil_name = props.coil_name;
    this.coil_id = props.coil_id;
    this.order_m = props.order_m;
    this.sales_person_id = props.sales_person_id;
    this.sales_person_name = props.sales_person_name;
    this.customer_id = props.customer_id;
    this.customer_name = props.customer_name;
    this.create_at = props.create_at;
    this.phone_no = props.phone_no;
    this.points_revenue = props.points_revenue;
    this.ad_ref_id = props.ad_ref_id;
    this.points = props.points;
    this.member__c = props.member__c;
    this.location_account__c = props.location_account__c;
    this.name = props.name;
    this.coil_length__c = props.coil_length__c;
    this.isdeleted = props.isdeleted;
    this.systemmodstamp = props.systemmodstamp;
    this.member_name__c = props.member_name__c;
    this.external_id__c = props.external_id__c;
    this.lot_no__c = props.lot_no__c;
    this.createddate = props.createddate;
    this.coil_description__c = props.coil_description__c;
    this.sfid = props.sfid;
    this._hc_lastop = props._hc_lastop;
    this._hc_err = props._hc_err;
    this.sales_transaction__c = props.sales_transaction__c;
    this.profile_indonesia__c = props.profile_indonesia__c;
    this.myr__c = props.myr__c;
    this.rbp_member__c = props.rbp_member__c;
    this.status__c = props.status__c;
    this.st_c_sfid = props.st_c_sfid;
    this.f_aging__c = props.f_aging__c;
    this.steel_grade__c = props.steel_grade__c;
    this.m3_customer_id__c = props.m3_customer_id__c;
    this.f_remaininglength__c = props.f_remaininglength__c;
    this.bs_product_group__c = props.bs_product_group__c;
    this.invoice_date__c = props.invoice_date__c;
    this.order_date__c = props.order_date__c;
    this.authorized_dealer__c = props.authorized_dealer__c;
    this.co_number__c = props.co_number__c;
    this.invoice_number__c = props.invoice_number__c;
    this.delivered_quantity_t__c = props.delivered_quantity_t__c;
    this.customer_order_number__c = props.customer_order_number__c;
    this.top_paint_color__c = props.top_paint_color__c;
    this.lot_number__c = props.lot_number__c;
    this.bmt_mm__c = props.bmt_mm__c;
    this.m3_product_group__c = props.m3_product_group__c;
    this.f_productgroupcode__c = props.f_productgroupcode__c;
    this.item_number__c = props.item_number__c;
    this.f_remainingweight__c = props.f_remainingweight__c;
    this.f_bsproductgroupid__c = props.f_bsproductgroupid__c;
    this.delivery_number__c = props.delivery_number__c;
    this.f_lengthsold__c = props.f_lengthsold__c;
    this.actual_coil_length_m__c = props.actual_coil_length_m__c;
    this.item_description__c = props.item_description__c;
    this.coating_mass_mm__c = props.coating_mass_mm__c;
    this.thickness_mm__c = props.thickness_mm__c;
    this.delivered_date__c = props.delivered_date__c;
    this.coil_width_mm__c = props.coil_width_mm__c;
    this.on_hand_blance__c = props.on_hand_blance__c;
    this.kg_per_m__c = props.kg_per_m__c;
    this.amount_thb__c = props.amount_thb__c;
    this.f_bsproductgroupname__c = props.f_bsproductgroupname__c;
    this.item_type_name__c = props.item_type_name__c;
    this.acc_name = props.acc_name;
  }
}