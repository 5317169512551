import { luckyDrawController } from "../../apiService/apiController/luckyDrawService";
import { ModelLuckyDraw, ModelLuckyDrawDetail, ModelLuckyDrawTNC, ModelLuckyExpirePoint } from "../../models/ModelLuckyDraw";
import { isValidResponse } from '../functions';
import { getUserAuth } from "../functions/auth";
import { handleTrimParams } from "../functions/data";
import _ from 'lodash';

export const getLuckyDrawExpirePoint = async (params) => {
  const luckyDrawService = luckyDrawController();
  const res = await luckyDrawService.luckyDrawExpirePoint(params);
  if (isValidResponse(res)) {
    let dataList = res.data.map((e) => new ModelLuckyExpirePoint(e));
    console.log('getLuckyDrawExpirePoint', res.data, dataList);
    return dataList;
  }
  return [];
};

export const getLuckyDrawCurrent = async () => {
  const luckyDrawService = luckyDrawController();
  const res = await luckyDrawService.luckyDrawCurrent();
  if (isValidResponse(res)) {
    let data = new ModelLuckyDraw(res.data);
    console.log('getLuckyDrawCurrent', res.data, data);
    return data;
  }
}

export const getLuckyDrawCurrentDetail = async () => {
  const luckyDrawService = luckyDrawController();
  const res = await luckyDrawService.luckyDrawCurrentDetail();
  if (isValidResponse(res)) {
    let data = new ModelLuckyDrawDetail(res.data);
    data.rewardList = _.orderBy(data.rewardList, ['orderNo'], ['asc'])
    console.log('getLuckyDrawCurrentDetail', res.data, data);
    return data;
  }
}

export const getLuckyDrawTNCCurrent = async (params = {}) => {
  let userAuth = getUserAuth();
  let newParams = handleTrimParams({
    ...params,
    rbp_member_sfid: userAuth.sfid,
  });
  const luckyDrawService = luckyDrawController();
  const res = await luckyDrawService.luckyDrawTNCCurrent(newParams);
  if (isValidResponse(res)) {
    let data = new ModelLuckyDrawTNC(res.data);
    console.log('getLuckyDrawTNCCurrent', res.data, data, newParams);
    return data;
  }
}

export const getLuckyDrawAnnouncementList = async (params) => {
  const luckyDrawService = luckyDrawController();
  const res = await luckyDrawService.luckyDrawAnnouncement(params);
  if (isValidResponse(res)) {
    let dataList = res.data.map((e) => new ModelLuckyDraw(e));
    console.log('getLuckyDrawAnnouncementList', res.data, dataList);
    return dataList;
  }
  return [];
};

export const getLuckyDrawAnnouncementDetail = async (params) => {
  const luckyDrawService = luckyDrawController();
  const res = await luckyDrawService.luckyDrawAnnouncementDetail(params);
  if (isValidResponse(res)) {
    let data = new ModelLuckyDrawDetail(res.data);
    data.rewardList = _.orderBy(data.rewardList, ['orderNo'], ['asc']);
    console.log('getLuckyDrawAnnouncementDetail', res.data, data);
    return data;
  }
};