import React from 'react'
import {
  TransitionContainer,
  Container,
  Section,
  Image,
  Button,
  Text,
  Field,
  NavigationBar,
} from './../../components';
import {
  VARIABLES,
  TYPOGRAPHYS,
  ICONS,
} from './../../themes';
import {
  ROUTE_PATH,
  redirect,
  getRouteData,
  isValidResponse,
} from './../../helpers';
import { ModalNoticeContainer } from '../../containers/ModalContainer/ModalNoticeContainer';
import { getLanguage } from '../../helpers/functions/language';
import { onOpenAlert, onOpenModal, isShowLoading } from '../../helpers/functions/main';
import { DATA } from '../../helpers/constants/data';
import axios from 'axios';
import config from '../../config';

export class ResetPasswordPage extends React.Component {
  constructor(props) {
    super(props);
    const data = getRouteData(props, 'data');
    if (!data) {
      this.resetPassword.goBack();
    }
    this.state = {
      showNewPassword: false,
      showConfirmPassword: false,
      newPassword: '',
      confirmPassword: '',
      data,
    }
    console.log('data', data)
  }

  resetPassword = {
    isValidatePass: () => {
      const {
        newPassword,
        confirmPassword,
      } = this.state;
      let isPass = true;
      if (newPassword !== confirmPassword) {
        isPass = false;
        onOpenAlert({
          type: DATA.ALERT_TYPE.ERROR,
          title: getLanguage('message.fail'),
          message: getLanguage('message.passwordAndConfirmPasswordNotMatch')
        })
      }
      if (newPassword.length < 8 || newPassword.length > 24 || confirmPassword.length < 8 || confirmPassword.length > 24) {
        isPass = false;
        onOpenAlert({
          type: DATA.ALERT_TYPE.ERROR,
          title: getLanguage('message.fail'),
          message: getLanguage('message.passwordIncorrectFormat')
        })
      }
      return isPass
    },
    onClickSubmit: () => {
      const {
        newPassword,
        data,
      } = this.state;
      if (this.resetPassword.isValidatePass()) {
        isShowLoading(true);
        axios.put(`${config.api.base}/api/users/password`, {
          id: data.id,
          password: newPassword
        }).then(async (response) => {
          const res = response.data;
          if (isValidResponse(res)) {
            isShowLoading(false);
            onOpenModal({
              type: DATA.MODAL_TYPE.FORGET_PASSWORD_SUCCESS,
              onClickSubmit: () => {
                redirect(ROUTE_PATH.LOGIN_BY_PHONE.LINK)
              }
            })
          }
          else {
            isShowLoading(false);
          }
        }, (error) => {
          console.log(error);
          isShowLoading(false);
        });
      }
    },
    goBack: () => {
      redirect(ROUTE_PATH.FORGOT_PASSWORD_METHOD.LINK);
    }
  }

  input = {
    onChangeInput: (ev) => {
      const target = ev.target.name;
      const value = ev.target.value;
      this.setState({
        [target]: value
      });
    },
    onTogglePassword: (target) => {
      this.setState({
        [target]: !this.state[target]
      });
    }
  }

  render() {
    const {
      showNewPassword,
      showConfirmPassword,
      newPassword,
      confirmPassword,
    } = this.state

    return (
      <TransitionContainer
      // motion='overlap-from'
      >
        <Container
          width={VARIABLES.WEBSITE_WIDTH}
          height='100'
          heightUnit='vh'
          bgColor={VARIABLES.COLORS.WHITE}
        >
          <NavigationBar
            shadow
            pageTitle={getLanguage('resetPassword.title')}
            actionLeft={
              <Image
                widthRatio='11'
                widthRatioUnit='px'
                heightRatio='21'
                heightRatioUnit='px'
                src={ICONS['ic-left-black.svg']}
                onClick={this.resetPassword.goBack}
              />
            }
          />
          <Section
            scroll
            justify='center'
            calcHeight='100vh - 70px'
          >
            <Section
              width='275'
              paddingTop='95'
            >
              <Section spacingBottom='10' className='is-register-floating-label'>
                <Field
                  fluid
                  name='newPassword'
                  className='is-password is-register'
                  inputTextType={`${showNewPassword ? 'text' : 'password'}`}
                  height='28'
                  paddingBottom='10'
                  bgColor={VARIABLES.COLORS.TRANSPARENT}
                  borderBottomWidth='1'
                  borderBottomStyle='solid'
                  borderBottomColor={VARIABLES.COLORS.GRAY_3}
                  fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_15}
                  fontColor={VARIABLES.COLORS.BLACK}
                  value={newPassword}
                  onChange={this.input.onChangeInput}
                />
                <Text
                  className={'is-floating-label'}
                  fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_15}
                  color={VARIABLES.COLORS.GRAY_2}
                >
                  {getLanguage('field.newPassword')}
                </Text>
                <Image
                  className='is-icon-password'
                  widthRatio='18'
                  widthRatioUnit='px'
                  heightRatio='18'
                  heightRatioUnit='px'
                  src={(showNewPassword) ? ICONS['ic-eye-password-close.svg'] : ICONS['ic-eye-password.svg']}
                  onClick={() => {
                    this.input.onTogglePassword('showNewPassword');
                  }}
                />
              </Section>
              <Section spacingBottom='13'>
                <Text
                  fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_14}
                  color={VARIABLES.COLORS.GRAY_4}
                  className='is-password-note'
                >
                  {getLanguage('field.newPasswordHint')}
                </Text>
              </Section>
              <Section spacingBottom='75' className='is-register-floating-label'>
                <Field
                  fluid
                  name='confirmPassword'
                  className='is-password'
                  inputTextType={`${showConfirmPassword ? 'text' : 'password'}`}
                  height='28'
                  paddingBottom='10'
                  bgColor={VARIABLES.COLORS.TRANSPARENT}
                  borderBottomWidth='1'
                  borderBottomStyle='solid'
                  borderBottomColor={VARIABLES.COLORS.GRAY_3}
                  fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_15}
                  fontColor={VARIABLES.COLORS.BLACK}
                  value={confirmPassword}
                  onChange={this.input.onChangeInput}
                />
                <Text
                  className='is-floating-label'
                  fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_15}
                  color={VARIABLES.COLORS.GRAY_2}
                >
                  {getLanguage('field.confirmPassword')}
                </Text>
                <Image
                  className='is-icon-password'
                  widthRatio='18'
                  widthRatioUnit='px'
                  heightRatio='18'
                  heightRatioUnit='px'
                  src={(showConfirmPassword) ? ICONS['ic-eye-password-close.svg'] : ICONS['ic-eye-password.svg']}
                  onClick={() => {
                    this.input.onTogglePassword('showConfirmPassword')
                  }}
                />
              </Section>
              <Section>
                <Button
                  fluid
                  shadow
                  className='is-gradient-blue'
                  height={VARIABLES.BUTTON.HEIGHTS.H_40}
                  borderRadius={VARIABLES.BUTTON.BORDER_RADIUSES.BR_10}
                  onClick={this.resetPassword.onClickSubmit}
                >
                  <Text
                    fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_16}
                    color={VARIABLES.COLORS.WHITE}
                  >
                    {getLanguage('field.buttonSave')}
                  </Text>
                </Button>
              </Section>
            </Section>
          </Section>
        </Container>
      </TransitionContainer>
    )
  }
}
