import styled, {
  css
} from 'styled-components'
import {
  default as VARIABLES
} from './../../../themes/styles/bases/variables'
import {
  default as TYPOGRAPHYS
} from './../../../themes/styles/bases/typographys'
import {
  default as MIXINS
} from './../../../themes/styles/helpers/mixins'

// Sample using image in style
// url(${require('./images/icons/icon-sample.svg')});
// url(${require('./../../../themes/images/icons/icon-sample.svg')});

// Wrapper
// ============================================================
export const FieldBaseWrapper = styled.div`
  /* Parent
  ------------------------------- */
  display: inline-block;

  /* Childrens
  ------------------------------- */
  .field-container-base {
    display: flex;
  }

  .field-label-base {
    flex: none;

    ${props => {
      const theme = props.theme
      return css`
        ${theme.labelFontStyle}

        ${theme.labelWidth && css`
            width: ${theme.labelWidth + `px`};
          `
        }

        ${theme.labelSpacingTop && css`
            padding-top: ${theme.labelSpacingTop + `px`};
          `
        }

        ${theme.labelSpacingRight && css`
            margin-right: ${theme.labelSpacingRight + `px`};
          `
        }

        color: ${theme.labelFontColor};
        text-align: ${theme.labelAlign};
      `
    }}
  }

  .field-group-base {
    /* Style */
  }

  .field-group-inner-base {
    position: relative;
  }

  .field-checkbox-base {
    ${props => {
    const theme = props.theme
    return css`
        ${theme.fontStyle}

        color: ${theme.fontColor};
      `
  }}
  }

  .field-value-base,
  .field-file-name-base,
  .field-info-text-base {
    ${props => {
      const theme = props.theme
      return css`
        ${MIXINS.PLACEHOLDER({
          color: theme.placeholderColor
        })};

        ${theme.fontStyle}

        ${theme.width && css`
            width: ${theme.width + `px`};
          `
        }

        ${theme.height && css`
            height: ${theme.height + `px`};
          `
        }

        ${theme.paddingHorizontal && css`
            padding-left: ${theme.paddingHorizontal + `px`};
            padding-right: ${theme.paddingHorizontal + `px`};
          `
        }

        ${theme.paddingVertical && css`
            padding-top: ${theme.paddingVertical + `px`};
            padding-bottom: ${theme.paddingVertical + `px`};
          `
        }

        ${theme.paddingTop && css`
            padding-top: ${theme.paddingTop + `px`};
          `
        }

        ${theme.paddingRight && css`
            padding-right: ${theme.paddingRight + `px`};
          `
        }

        ${theme.paddingBottom && css`
            padding-bottom: ${theme.paddingBottom + `px`};
          `
        }

        ${theme.paddingLeft && css`
            padding-left: ${theme.paddingLeft + `px`};
          `
        }

        background-color: ${theme.bgColor};

        ${theme.borderBottomWidth && css`
            border-top-width: 0;
            border-bottom: ${theme.borderBottomWidth && `${theme.borderBottomWidth + `px`}`} ${theme.borderBottomStyle} ${theme.borderBottomColor};
            border-right-width: 0;
            border-left-width: 0;
          `
        }

        border: ${theme.borderWidth && `${theme.borderWidth + `px`}`} ${theme.borderStyle} ${theme.borderColor};

        ${theme.borderRadius && css`
            border-radius: ${theme.borderRadius + `px`};
          `
        }

        color: ${theme.fontColor};
      `
    }}

    &:focus {
      outline: none;
    }
  }

  .field-value-base {
    ${props => {
      const theme = props.theme
      return css`
        text-align: ${theme.textAlign};
      `
    }}

    &[type=number] {
      -moz-appearance: textfield;

      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
      }
    }

    &[type=date] {
      &::-webkit-calendar-picker-indicator,
      &::-webkit-inner-spin-button {
        opacity: 0;
        width: 65%;
        padding: 0;
        margin: 0;
        -webkit-appearance: none;
      }
    }
  }

  textarea.field-value-base {
    resize: none;
  }

  select.field-value-base {
    cursor: pointer;
  }

  .field-icon-base {
    pointer-events: none;
    position: absolute;
    z-index: ${VARIABLES.Z_INDEXS.LV_1};
    display: flex;
    justify-content: center;
    align-items: center;

    ${props => {
      const theme = props.theme
      return css`
        ${theme.fieldIconWidth && css`
            width: ${theme.fieldIconWidth + `px`};
          `
        }

        ${(theme.height && theme.borderWidth) && css`
            height: ${(theme.height - ((theme.borderWidth !== undefined ? theme.borderWidth : 0) * 2)) + `px`};
          `
        }

        ${(theme.height && theme.borderBottomWidth) && css`
            height: ${(theme.height - ((theme.borderBottomWidth !== undefined) ? theme.borderBottomWidth : 0)) + `px`};
          `
        }

        ${theme.borderWidth && css`
            top: ${theme.borderWidth + `px`};
          `
        }

        ${theme.borderBottomWidth && css`
            bottom: ${theme.borderBottomWidth + `px`};
          `
        }

        ${theme.fieldIconSpacingLeft && css`
            left: ${theme.fieldIconSpacingLeft + `px`};
          `
        }

        ${theme.fieldIconSpacingRight && css`
            right: ${theme.fieldIconSpacingRight + `px`};
          `
        }

        background-color: ${theme.bgColor};

        ${theme.borderRadius && css`
            border-radius: ${theme.borderRadius + `px`};
          `
        }
      `
    }}

    &.is-cursor-pointer {
      pointer-events: auto;
      cursor: pointer;
    }
  }

  .field-icon-base {
    &.is-left {
      pointer-events: none;
      position: absolute;
      z-index: ${VARIABLES.Z_INDEXS.LV_1};
      display: flex;
      justify-content: center;
      align-items: center;

      ${props => {
    const theme = props.theme
    return css`
          ${theme.fieldIconLeftSpacingLeft && css`
          left: ${theme.fieldIconLeftSpacingLeft + `px`};
          `
      }
      `
  }}
    }
  }

  .field-message-base {
    ${props => {
      const theme = props.theme
      return css`
        ${theme.messageFontStyle}

        ${theme.messageSpacingTop && css`
            padding-top: ${theme.messageSpacingTop + `px`};
          `
        }

        ${theme.messageSpacingLeft && css`
            padding-left: ${theme.messageSpacingLeft + `px`};
          `
        }

        color: ${theme.messageFontColor};
        text-align: ${theme.messageAlign};
      `
    }}
  }

  .field-hint-base {
    flex: none;

    ${props => {
      const theme = props.theme
      return css`
        ${theme.hintFontStyle}

        ${theme.hintSpacingTop && css`
            padding-top: ${theme.hintSpacingTop + `px`};
          `
        }

        ${theme.hintSpacingLeft && css`
            margin-left: ${theme.hintSpacingLeft + `px`};
          `
        }

        color: ${theme.hintFontColor};
      `
    }}
  }

  .field-checkbox-base,
  .field-radio-base {
    cursor: pointer;
    position: relative;
    display: inline-block;
    vertical-align: middle;
  }

  .field-checkbox-label-base,
  .field-radio-label-base {
    display: flex;
    align-items: center;
  }

  .field-checkbox-value-base,
  .field-radio-value-base {
    cursor: pointer;
    opacity: 0;
    position: absolute;
    z-index: ${VARIABLES.Z_INDEXS.LV_2};
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    &:checked {
      + .field-checkbox-label-base,
      + .field-radio-label-base {
        .field-checkbox-icon-base,
        .field-radio-icon-base {
           /* Checkbox */
          ${props => {
            const theme = props.theme
            return css`
              background-color: ${theme.checkboxCheckedBgColor};
              border-color: ${theme.checkboxBorderCheckedColor};
            `
          }}

          /* Radio */
          ${props => {
            const theme = props.theme
            return css`
              background-color: ${theme.radioCheckedBgColor};
              border-color: ${theme.radioBorderCheckedColor};
            `
          }}
        }

        .field-checkbox-icon-checked-base,
        .field-radio-icon-checked-base {
          visibility: visible;
          opacity: 1;
        }

        .field-checkbox-icon-label-base,
        .field-radio-icon-label-base {
          /* Checkbox */
          ${props => {
            const theme = props.theme
            return css`
              color: ${theme.checkboxLabelCheckedFontColor};
            `
          }}

          /* Radio */
          ${props => {
            const theme = props.theme
            return css`
              color: ${theme.radioLabelCheckedFontColor};
            `
          }}
        }
      }
    }
  }

  .field-checkbox-icon-base,
  .field-radio-icon-base {
    transition: ${VARIABLES.TRANSITIONS.DEFAULT};
    position: relative;
    display: inline-block;
    ${props => {
      const theme = props.theme
      return css`
        /* Checkbox */
        ${theme.checkboxSize && css`
            width: ${theme.checkboxSize + `px`};
            height: ${theme.checkboxSize + `px`};
          `
        }

        background-color: ${theme.checkboxBgColor};

        border: ${theme.checkboxBorderWidth && `${theme.checkboxBorderWidth + `px`}`} ${theme.checkboxBorderStyle} ${theme.checkboxBorderColor};

        ${theme.checkboxBorderRadius && css`
            border-radius: ${theme.checkboxBorderRadius + `px`};
          `
        }

        /* Radio */
        ${theme.radioSize && css`
            width: ${theme.radioSize + `px`};
            height: ${theme.radioSize + `px`};
          `
        }

        background-color: ${theme.radioBgColor};

        border: ${theme.radioBorderWidth && `${theme.radioBorderWidth + `px`}`} ${theme.radioBorderStyle} ${theme.radioBorderColor};

        ${theme.radioBorderRadius && css`
            border-radius: ${theme.radioBorderRadius + `px`};
          `
        }
      `
    }}
  }

  .field-checkbox-icon-checked-base,
  .field-radio-icon-checked-base {
    visibility: hidden;
    opacity: 0;
    position :absolute;
    z-index: ${VARIABLES.Z_INDEXS.LV_1};
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
  }

  .field-checkbox-icon-label-base,
  .field-radio-icon-label-base {
    transition: ${VARIABLES.TRANSITIONS.DEFAULT};
    flex: none;

    ${props => {
      const theme = props.theme
      return css`
        /* Checkbox */
        ${theme.checkboxLabelFontStyle}

        ${theme.checkboxLabelSpacingLeft && css`
            margin-left: ${theme.checkboxLabelSpacingLeft + `px`};
          `
        }

        color: ${theme.checkboxLabelFontColor};

        /* Radio */
        ${theme.radioLabelFontStyle}

        ${theme.radioLabelSpacingLeft && css`
            margin-left: ${theme.radioLabelSpacingLeft + `px`};
          `
        }

        color: ${theme.radioLabelFontColor};
      `
    }}
  }

  .field-file-base {
    display: flex;
    align-items: center;

    &.is-avatar {
      flex-direction: column;
      justify-content: center;
    }
  }

  .field-file-input-base {
    opacity: 0;
    cursor: pointer;
    position: absolute;
    z-index: ${VARIABLES.Z_INDEXS.LV_1};
    top: 0;
    left: 0;
    font-size: 100px; /* Area for clickable */

    &:focus {
      outline: none;
    }

    &::-webkit-file-upload-button {
      cursor: pointer;
    }
  }

  .field-file-name-base {
    display: flex;
    align-items: center;
  }

  .field-file-name-text-base {
    ${MIXINS.ELLIPSIS({})};
  }

  .field-file-image-base {
    ${props => {
      const theme = props.theme
      return css`
        ${theme.fileImageSpacingBottom && css`
            margin-bottom: ${theme.fileImageSpacingBottom + `px`};
          `
        }
      `
    }}
  }

  .field-file-button-group-base {
    display: flex;
    align-items: center;
  }

  .field-file-button-base {
    cursor: pointer;
    overflow: hidden;
    position: relative;
    display: flex;
    align-items: center;
    flex: none;
  }

  .field-file-button-browse-base {
    ${props => {
      const theme = props.theme
      return css`
        ${theme.buttonBrowseSpacingLeft && css`
            margin-left: ${theme.buttonBrowseSpacingLeft + `px`};
          `
        }
      `
    }}
  }

  .field-file-button-upload-base,
  .field-file-button-delete-upload-base {
    flex: none;

    ${props => {
      const theme = props.theme
      return css`
        ${theme.buttonUploadSpacingLeft && css`
            margin-left: ${theme.buttonUploadSpacingLeft + `px`};
          `
        }

        ${theme.buttonDeleteUploadSpacingLeft && css`
            margin-left: ${theme.buttonDeleteUploadSpacingLeft + `px`};
          `
        }
      `
    }}
  }

  .field-info-base {
    display: flex;
    align-items: center;
  }

  .field-switch-base {
    position: relative;
    display: flex;
    flex: none;
    align-items: center;
  }

  .field-switch-input-base {
    cursor: pointer;
    opacity: 0;
    position: absolute;
    z-index: ${VARIABLES.Z_INDEXS.LV_1};
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    &:checked {
      + .field-switch-body-base {
        ${props => {
          const theme = props.theme
          return css`
            background-color: ${theme.switchActiveBgColor};
          `
        }}

        .field-switch-button-base {
           ${props => {
            const theme = props.theme
            return css`
              ${theme.switchButtonSpacingRight && css`
                  transform: translate(${theme.switchButtonSpacingRight + `px`}, -50%);
                `
              }

              background-color: ${theme.switchButtonActiveColor};
            `
          }}
        }
      }
    }
  }

  .field-switch-body-base {
    transition: ${VARIABLES.TRANSITIONS.DEFAULT};
    position: relative;
    display: flex;
    flex: none;
    justify-content: center;
    align-items: center;

    ${props => {
      const theme = props.theme
      return css`
        ${theme.switchWidth && css`
            width: ${theme.switchWidth + `px`};
          `
        }

        ${theme.switchHeight && css`
            height: ${theme.switchHeight + `px`};
          `
        }

        background-color: ${theme.switchBgColor};

        border: ${theme.switchBorderWidth && `${theme.switchBorderWidth + `px`}`} ${theme.switchBorderStyle} ${theme.switchBorderColor};

        ${theme.switchBorderRadius && css`
            border-radius: ${theme.switchBorderRadius + `px`};
          `
        }
      `
    }}
  }

  .field-switch-button-base {
    pointer-events: none;
    transition: ${VARIABLES.TRANSITIONS.DEFAULT};
    transform: translateY(-50%);
    position: absolute;
    z-index: ${VARIABLES.Z_INDEXS.LV_2};
    top: 50%;
    display: block;

    ${props => {
      const theme = props.theme
      return css`
        ${theme.switchButtonSpacingLeft && css`
            left: ${theme.switchButtonSpacingLeft + `px`};
          `
        }

        ${theme.switchButtonSize && css`
            width: ${theme.switchButtonSize + `px`};
            height: ${theme.switchButtonSize + `px`};
          `
        }

        background-color: ${theme.switchButtonInactiveColor};
        border-radius: 50%;
        box-shadow: ${theme.switchButtonShadow};
      `
    }}
  }

  .field-switch-name-base {
    display: inline-block;

    ${props => {
      const theme = props.theme
      return css`
        ${theme.switchNameFontStyle}
      `
    }}
  }

  .field-switch-name-outer-base {
    display: inline-block;

    ${props => {
      const theme = props.theme
      return css`
        ${theme.switchNameOuterFontStyle}

        ${theme.switchNameOuterSpacingLeft && css`
            margin-left: ${theme.switchNameOuterSpacingLeft + `px`};
          `
        }
      `
    }}
  }

  /* Modifiers
  ------------------------------- */
  &.is-fluid {
    width: 100%;

    .field-group-base,
    .field-value-base,
    .field-file-name-base {
      width: 100%;
    }
  }

  &.is-error {
    .field-value-base,
    .field-file-name-base {
      ${props => {
        const theme = props.theme
        return css`
          border-color: ${theme.errorColor};
        `
      }}
    }

    .field-message-base {
      ${props => {
        const theme = props.theme
        return css`
          color: ${theme.errorColor};
        `
      }}
    }
  }

  &.is-success {
    .field-value-base,
    .field-file-name-base {
      ${props => {
        const theme = props.theme
        return css`
          border-color: ${theme.successColor};
        `
      }}
    }

    .field-value-base {
      ${props => {
        const theme = props.theme
        return css`
          color: ${theme.successColor};
        `
      }}
    }

    .field-message-base {
      ${props => {
        const theme = props.theme
        return css`
          color: ${theme.successColor};
        `
      }}
    }
  }

  &.is-disabled {
    .field-value-base,
    .field-file-name-base,
    .field-icon-base {
      ${props => {
        const theme = props.theme
        return css`
          background-color: ${theme.disabledColor};
        `
      }}
    }

    .field-value-base,
    .field-file-base,
    .field-switch-base {
      pointer-events: none;
      cursor: none;
    }
  }

  &.is-info {
    .field-container-base {
      align-items: flex-end;
    }
  }

  &.field-date {
    .field-value-base {
      position: relative;

      // input date cannot change format, use placeholder for display dd/mm/yy
      // fixed ios cannot hide value by css when focus input
      color: transparent;
      &::-webkit-inner-spin-button,
      &::-webkit-clear-button,
      &::-webkit-datetime-edit,
      &::-webkit-datetime-edit-text {
        -webkit-appearance: none;
        display: none;
      }
      &::-webkit-calendar-picker-indicator {
        position: absolute;
        width: 100%;
        color: transparent;
        background: transparent;
      }
      /* &:before {
        width: 100%;
        content: attr(placeholder) !important;
        color: ${VARIABLES.COLORS.BLUE_2};
      }
      &:focus {
        &::before {
          color: ${VARIABLES.COLORS.BLUE_2};
          content: attr(placeholder) !important;
        }
      } */

      /* Fix placeholder and date format */
      &[convertValue=''] {
        &:before {
          content: attr(placeholder);
          color: ${VARIABLES.COLORS.BLUE_2};
        }
      }

      &:before {
        ${TYPOGRAPHYS.FIRST.REGULAR.FS_15};
        content: attr(convertValue);
        position: absolute;
        top: 8px;
        left: 55px;
        display: block;
        padding: 0 10px 0 0;
        color: ${VARIABLES.COLORS.BLUE_2};
      }
    }
  }
  /* Media queries
  ------------------------------- */
`
