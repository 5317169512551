import React from 'react'
import * as _ from 'lodash'
import {
  TransitionContainer,
  Container,
  Section,
  Image,
  Grid,
  NavigationBar,
  Text,
  ShapeContainer,
} from './../../components'
import {
  VARIABLES,
  TYPOGRAPHYS,
  ICONS,
} from './../../themes'
import {
  ROUTE_PATH,
  redirect,
} from './../../helpers'
import {
  Loader
} from '../Loader'

export class KnowledgeBaseTopicPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      id: (this.props.match.params && this.props.match.params.id) ? +this.props.match.params.id : '',
      isLoading: false,
      list: []
    }
  }

  componentWillMount() {
    const data = JSON.parse(localStorage.getItem('knowledgeBase'))
    if (_.isEmpty(data)) return
    const result = data.find(item => item.id === this.state.id)
    if (result) {
      this.setState({
        titleName: result.name,
        list: result.knowledge,
      })
    }
  }

  handleBack() {
    redirect(ROUTE_PATH.KNOWLEDGE_BASE.LINK)
  }

  onClickDetail(id) {
    // const data = JSON.parse(localStorage.getItem('knowledgeBase'))
    // if (_.isEmpty(data)) return
    // localStorage.setItem('knowledgeBase', JSON.stringify([...data, { selectedId: id }]))
    redirect(`${ROUTE_PATH.KNOWLEDGE_BASE_DETAIL.LINK}/${this.state.id}/${id}`)
  }

  render() {
    const {
      list,
      titleName,
    } = this.state
    return (
      <TransitionContainer>
        <Container
          width={VARIABLES.WEBSITE_WIDTH}
          calcMinHeight='100vh'
          bgColor={VARIABLES.COLORS.LIGHT_BLUE_2}
        >
          <NavigationBar
            pageTitle={titleName}
            actionLeft={<Image
              widthRatio='11'
              widthRatioUnit='px'
              heightRatio='21'
              heightRatioUnit='px'
              src={ICONS['ic-left-black.svg']}
              onClick={this.handleBack.bind(this)}
            />}
          />
          <Section
            scroll
            calcHeight='100vh - 70px'
          >
            <Section
              paddingHorizontal='20'
              paddingTop='24'
              paddingBottom='40'
            >
              {<Loader loading={this.state.isLoading} paddingTop={30} widthRatio={50} heightRatio={50} />}
              {(list || []).map((data, index) => (
                <Section
                  key={index}
                  index={index}
                  className='is-shadow'
                  spacingBottom='14'
                  bgColor={VARIABLES.COLORS.WHITE}
                  paddingVertical='25'
                  paddingLeft='18'
                  onClick={this.onClickDetail.bind(this, data.id)}
                >
                  <Grid gutter='25' align='center'>
                    <Grid.Column flex='none'>
                      <Image
                        widthRatio='37'
                        widthRatioUnit='px'
                        heightRatio='33'
                        heightRatioUnit='px'
                        src={ICONS['ic-book-blue.svg']}
                      />
                    </Grid.Column>
                    <Grid.Column flex='1'>
                      <Text
                        fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_16}
                      >
                        {data.topic_name}
                      </Text>
                    </Grid.Column>
                    <Grid.Column flex='none'>
                      <Section
                        width='48'
                        height='100'
                        heightUnit='%'
                        justify='center'
                        align='center'
                      >
                        <ShapeContainer
                          justify='center'
                          align='center'
                          width='18'
                          height='18'
                          bgColor={VARIABLES.COLORS.BLUE_2}
                          borderRadius='9'
                        >
                          <Image
                            widthRatio='8'
                            widthRatioUnit='px'
                            heightRatio='8'
                            heightRatioUnit='px'
                            src={ICONS['ic-arrow-more.svg']}
                            left='-1'
                          />
                        </ShapeContainer>
                      </Section>
                    </Grid.Column>
                  </Grid>
                </Section>
              )
              )}
            </Section>
          </Section>
        </Container>
      </TransitionContainer>
    )
  }
}

