import React from "react";
import { Button, Image, Modal, Section, Text } from "../../../components";
import { getLanguage } from "../../../helpers/functions/language";
import { ICONS, TYPOGRAPHYS, VARIABLES } from "../../../themes";

export class ModalNotificationAwardsAnnouncedContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { isShowModal, onClickClose, onClickCheckMyName } = this.props;
    return (
      <Modal
        open={isShowModal}
        width={320}
        overlayColor={VARIABLES.COLORS.OVERLAY_3}
        spacing={VARIABLES.MODAL.CONTAINER.C_1.SPACING}
        transiti
        on={VARIABLES.TRANSITIONS.DEFAULT}
        transformStart={VARIABLES.MODAL.CONTAINER.C_1.TRANSFORM_START}
        transformEnd={VARIABLES.MODAL.CONTAINER.C_1.TRANSFORM_END}
        onClickClose={onClickClose}
      >
        <Modal.Section
          paddingTop="35"
          borderTopRightRadius={
            VARIABLES.MODAL.HEADER.H_1.BORDER_TOP_RIGHT_RADIUS
          }
          borderTopLeftRadius={
            VARIABLES.MODAL.HEADER.H_1.BORDER_TOP_LEFT_RADIUS
          }
          paddingBottom={VARIABLES.MODAL.BODY.B_1.PADDING_BOTTOM}
          paddingHorizontal="20"
        >
          <React.Fragment>
            <Section justify="center" spacingBottom="15">
              <Image
                widthRatio="64"
                widthRatioUnit="px"
                heightRatio="66"
                heightRatioUnit="px"
                src={ICONS["ic-award-gold.svg"]}
              />
            </Section>
            <Section justify="center" spacingBottom="15">
              <Text fontStyle={TYPOGRAPHYS.FIRST.BOLD.FS_20} color={"#D6BF62"}>
                {getLanguage("luckyDraw.awardAnnounced")}
              </Text>
            </Section>
            <Section paddingHorizontal="25" justify="center">
              <Text align="center" fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_14}>
                {getLanguage('luckyDraw.youCanClickBtnBelowToSeeYourListName')}
              </Text>
            </Section>
          </React.Fragment>
        </Modal.Section>
        <Modal.Section
          paddingBottom={VARIABLES.MODAL.FOOTER.F_2.PADDING_BOTTOM}
          paddingHorizontal={VARIABLES.MODAL.FOOTER.F_2.PADDING_HORIZONTAL}
          borderBottomRightRadius={
            VARIABLES.MODAL.FOOTER.F_2.BORDER_BOTTOM_RIGHT_RADIUS
          }
          borderBottomLeftRadius={
            VARIABLES.MODAL.FOOTER.F_2.BORDER_BOTTOM_LEFT_RADIUS
          }
        >
          <Section justify="center" spacingBottom="15">
            <Button
              width="200"
              bgColor={VARIABLES.COLORS.TRANSPARENT}
              borderWidth="1"
              borderStyle="solid"
              borderColor={VARIABLES.COLORS.BLUE_2}
              shadow
              height={VARIABLES.BUTTON.HEIGHTS.H_35}
              borderRadius={VARIABLES.BUTTON.BORDER_RADIUSES.BR_10}
              onClick={onClickClose}
            >
              <Text
                fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_16}
                color={VARIABLES.COLORS.BLUE_2}
              >
                {getLanguage("field.buttonClose")}
              </Text>
            </Button>
          </Section>
          <Section justify="center">
            <Button
              width="200"
              className="is-gradient-blue"
              borderWidth="1"
              borderStyle="solid"
              borderColor={VARIABLES.COLORS.BLUE_2}
              shadow
              height={VARIABLES.BUTTON.HEIGHTS.H_35}
              borderRadius={VARIABLES.BUTTON.BORDER_RADIUSES.BR_10}
              onClick={onClickCheckMyName}
            >
              <Text
                fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_16}
                color={VARIABLES.COLORS.WHITE}
              >
                {getLanguage('luckyDraw.buttonCheckMyName')}
              </Text>
            </Button>
          </Section>
        </Modal.Section>
      </Modal>
    );
  }
}
