import moment from 'moment';

export const formatDatetime = (datetime, format = 'DD MM YYYY') => {
  return moment(datetime).format(format)
}

export const diffDays = (startDatetime, endDatetime) => {
  const start = moment(startDatetime)
  const end = moment(endDatetime)
  return start.diff(end, 'days')
}