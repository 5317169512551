import styled, {
  // css
} from 'styled-components'
import {
  default as VARIABLES
} from './../../../themes/styles/bases/variables'
// import {
//   default as TYPOGRAPHYS
// } from './../../../themes/styles/bases/typographys'
// import {
//   default as MIXINS
// } from './../../../themes/styles/helpers/mixins'

// Sample using image in style
// url(${require('./../../../themes/images/icons/icon-sample.svg')});

// Wrapper
// ============================================================
export const CommentListWrapper = styled.div`
  /* Parent
  ------------------------------- */
  padding: 10px 20px 5px;
  border-bottom: 2px solid ${VARIABLES.COLORS.GRAY_9};

  /* Childrens
  ------------------------------- */
  .comment-list-title {
    word-break: break-all;
  }
  
  .comment-list-detail {
    line-height: 22px;
    word-break: break-word;
  }

  /* Modifiers
  ------------------------------- */
  &.is-public {
    padding-right: 0;
    padding-left: 0;
  }

  /* Media queries
  ------------------------------- */
`
