// STAGING, PRODUCTION
const SCHEMA_CONNECTION = {
  public: "public",
  salesforce: "salesforce"
};

const TABLES = {
  salesforce_sales_transaction__c: "rbp_sales_transaction__c",
  salesforce_f_notification_template__c: "rbp_notification_template__c"
};

const SMS_TEMPLATE_ID = {
  greeting: "a3L2u000000KyjpEAC",
  redemption: "a3L2u000000KyjfEAC",
  profile_updated: "a3L2u000000KyjkEAC",
  sales_transaction: "a3L2u000000KyjuEAC",
  forget_pw: "a3L2u000000KyjzEAC"
};

const SALT = 'rbpbluescope';

module.exports = {
  SCHEMA_CONNECTION: SCHEMA_CONNECTION,
  TABLES: TABLES,
  SMS_TEMPLATE_ID: SMS_TEMPLATE_ID,
  SALT: SALT
}

// DEV
// const SCHEMA_CONNECTION = {
//   public: "public",
//   salesforce: "salesforce2"
// };

// const TABLES = {
//   salesforce_sales_transaction__c: "rbp_sales_transaction__c",
//   salesforce_f_notification_template__c: "rbp_notification_template__c"
// };

// const SMS_TEMPLATE_ID = {
//   greeting: "a3L2u000000KyjpEAC",
//   redemption: "a3L2u000000KyjfEAC",
//   profile_updated: "a3L2u000000KyjkEAC",
//   sales_transaction: "a3L2u000000KyjuEAC",
//   forget_pw: "a3L2u000000KyjzEAC"
// };

// const SALT = 'rbpbluescope';

// module.exports = {
//   SCHEMA_CONNECTION: SCHEMA_CONNECTION,
//   TABLES: TABLES,
//   SMS_TEMPLATE_ID: SMS_TEMPLATE_ID,
//   SALT: SALT
// }
