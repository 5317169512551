import React from 'react'
import QRCode from 'qrcode.react'
import {
  TransitionContainer,
  Container,
  Image,
  Section,
  Text,
  Button,
  ShapeContainer,
} from './../../components'
import {
  VARIABLES,
  TYPOGRAPHYS,
  CONTENTS,
  ICONS,
  LOGOS,
} from './../../themes'
import {
  ROUTE_PATH,
  redirect,
} from './../../helpers'
import { LOCAL_STORAGE } from '../../helpers/constants/localStorage';
import { handleWatchNoti } from '../../helpers/firebases/firebaseNotification';
import { getLanguage } from '../../helpers/functions/language';
import { setLocalStorage } from '../../helpers/functions/localStorage';
import { getUserAuth } from '../../helpers/functions/auth'
import _ from 'lodash';
import {
  connect,
} from 'react-redux'
import { LINE_OA_URL } from '../../config/line'

export class LineOaValidationContainer extends React.Component {
  constructor(props) {
    super(props);
    const userAuth = getUserAuth();
    this.state = {
      name: _.get(userAuth, 'firstName', ''),
      url: LINE_OA_URL
    };
  }

  componentDidMount() { }

  render() {
    const {
      name,
      url
    } = this.state;

    return (
      <TransitionContainer
      // motion='overlap-from'
      >
        <Container
          width={VARIABLES.WEBSITE_WIDTH}
          height='100'
          heightUnit='vh'
          bgColor={VARIABLES.COLORS.LIGHT_BLUE_2}
          justify='center'
        >
          <Section
            paddingHorizontal='30'
            spacingBottom='15'
            justify='center'
          >
            <Text
              fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_20}
              color={VARIABLES.COLORS.GRAY_3}
            >
              {getLanguage('lineOaValidation.message_6')}&nbsp;
            </Text>
            <Text
              fontStyle={TYPOGRAPHYS.FIRST.MEDIUM.FS_20}
              color={VARIABLES.COLORS.BLUE_2}
            >
              {name}
            </Text>
          </Section>
          <Section
            paddingHorizontal='30'
            spacingBottom='15'
            justify='center'
          >
            <Text
              fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_20}
              color={VARIABLES.COLORS.GRAY_3}
              align='center'
            >
              {getLanguage('lineOaValidation.message_1')}
              <br />
              {getLanguage('lineOaValidation.message_2')}
            </Text>
          </Section>
          <Section
            paddingHorizontal='30'
            spacingBottom='30'
            justify='center'
          >
            <Text
              fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_16}
              color={VARIABLES.COLORS.BLUE_2}
              align='center'
            >
              {getLanguage('lineOaValidation.message_3')}
              <br />
              {getLanguage('lineOaValidation.message_4')}
            </Text>
          </Section>
          <Section
            paddingHorizontal='30'
            spacingBottom='15'
            justify='center'
          >
            <ShapeContainer padding='15' bgColor={VARIABLES.COLORS.WHITE} boxShadow='0 0 10px rgba(0, 0, 0, 0.1)'>
              <QRCode
                value={url}
                size={192}
                renderAs='svg'
                imageSettings={{
                  src: LOGOS['logo-qr.svg'],
                  height: 42,
                  width: 42,
                }}
              />
            </ShapeContainer>
          </Section>
          <Section
            paddingHorizontal='30'
            spacingBottom='15'
            justify='center'
          >
            <Text
              fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_20}
              color={VARIABLES.COLORS.GRAY_3}
              align='center'
            >
              {getLanguage('lineOaValidation.message_5')}
            </Text>
          </Section>
          <Section
            paddingHorizontal='30'
            justify='center'
          >
            <Button
              shadow
              className='is-gradient-blue is-icon-right'
              width={VARIABLES.BUTTON.WIDTHS.W_275}
              height={VARIABLES.BUTTON.HEIGHTS.H_40}
              borderRadius={VARIABLES.BUTTON.BORDER_RADIUSES.BR_10}
              onClick={() => {
                window.location.href = url;
              }}
            >
              <Button.Icon>
                <Image
                  widthRatio='15'
                  widthRatioUnit='px'
                  heightRatio='12'
                  heightRatioUnit='px'
                  src={ICONS['ic-arrow-right.svg']}
                />
              </Button.Icon>
              <Text
                fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_16}
                color={VARIABLES.COLORS.WHITE}
              >
                {getLanguage('field.buttonBlueScopeThailand')}
              </Text>
            </Button>
          </Section>
        </Container>
      </TransitionContainer>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    authApp: state.mainRedux.authApp,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
  }
}

export const LineOaValidationContainerConnected = connect(
  mapStateToProps,
  mapDispatchToProps
)(LineOaValidationContainer)