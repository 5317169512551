import React from 'react'
import ClassNames from 'classnames'
import PropTypes from 'prop-types'
import {
  GridContainerBaseWrapper,
  GridBaseWrapper,
  GridColumnBaseWrapper
} from './styled'
import {
  default as VARIABLES
} from './../../../themes/styles/bases/variables'
// import {
//   default as TYPOGRAPHYS
// } from './../../../themes/styles/bases/typographys'

class GridColumnBase extends React.PureComponent {
  render () {
    const {
      // Option
      children,
      className,
      // Flex
      flex,
      flexMobile,
      flexPhablet,
      flexTablet,
      flexLaptop,
      flexDesktop,
      flexLargeDesktop,
      // Grid
      gridSystem,
      grid,
      gridMobile,
      gridPhablet,
      gridTablet,
      gridLaptop,
      gridDesktop,
      gridLargeDesktop,
      // Flex & Grid Stack
      flexStackMobile,
      flexStackPhablet,
      flexStackTablet,
      flexStackLaptop,
      flexStackDesktop,
      // Breakpoint
      breakpointMobile,
      breakpointPhablet,
      breakpointTablet,
      breakpointLaptop,
      breakpointDesktop,
      breakpointLargeDesktop,
      // Breakpoint stack
      breakpointColFlexMobileStack,
      breakpointColFlexPhabletStackMin,
      breakpointColFlexPhabletStackMax,
      breakpointColFlexTabletStackMin,
      breakpointColFlexTabletStackMax,
      breakpointColFlexLaptopStackMin,
      breakpointColFlexLaptopStackMax,
      breakpointColFlexDesktopStackMin,
      breakpointColFlexDesktopStackMax
    } = this.props

    return (
      <GridColumnBaseWrapper
        className={ClassNames(
          'grid-column-base',
          // Option
          className,
          // Flex
          { [`is-flex`]: flex || flexMobile || flexPhablet || flexLaptop || flexDesktop || flexLargeDesktop },
          // Grid
          { [`is-grid-${grid}`]: grid },
          { [`is-grid-${gridMobile}-mobile`]: gridMobile },
          { [`is-grid-${gridPhablet}-phablet`]: gridPhablet },
          { [`is-grid-${gridTablet}-tablet`]: gridTablet },
          { [`is-grid-${gridLaptop}-laptop`]: gridLaptop },
          { [`is-grid-${gridDesktop}-desktop`]: gridDesktop },
          { [`is-grid-${gridLargeDesktop}-large-desktop`]: gridLargeDesktop },
          // Stack
          { [`is-stack-mobile`]: flexStackMobile },
          { [`is-stack-phablet`]: flexStackPhablet },
          { [`is-stack-tablet`]: flexStackTablet },
          { [`is-stack-laptop`]: flexStackLaptop },
          { [`is-stack-desktop`]: flexStackDesktop }
        )}
        theme={{
          // Grid
          gridSystem,
          // Flex
          flex,
          flexMobile,
          flexPhablet,
          flexTablet,
          flexLaptop,
          flexDesktop,
          flexLargeDesktop,
          // Breakpoint
          breakpointMobile,
          breakpointPhablet,
          breakpointTablet,
          breakpointLaptop,
          breakpointDesktop,
          breakpointLargeDesktop,
          // Breakpoint stack
          breakpointColFlexMobileStack,
          breakpointColFlexPhabletStackMin,
          breakpointColFlexPhabletStackMax,
          breakpointColFlexTabletStackMin,
          breakpointColFlexTabletStackMax,
          breakpointColFlexLaptopStackMin,
          breakpointColFlexLaptopStackMax,
          breakpointColFlexDesktopStackMin,
          breakpointColFlexDesktopStackMax
        }}
      >
        {children}
      </GridColumnBaseWrapper>
    )
  }

  static defaultProps = {
    // Option
    // children: undefined,
    // className: undefined,
    // Grid
    gridSystem: VARIABLES.FACTORS.GRID,
    // Breakpoint
    breakpointMobile:       VARIABLES.BREAKPOINTS.BP_413,
    breakpointPhablet:      VARIABLES.BREAKPOINTS.BP_414,
    breakpointTablet:       VARIABLES.BREAKPOINTS.BP_768,
    breakpointLaptop:       VARIABLES.BREAKPOINTS.BP_1024,
    breakpointDesktop:      VARIABLES.BREAKPOINTS.BP_1366,
    breakpointLargeDesktop: VARIABLES.BREAKPOINTS.BP_1600,
    // Breakpoint stack
    breakpointColFlexMobileStack:     VARIABLES.BREAKPOINTS.BP_413,
    breakpointColFlexPhabletStackMin: VARIABLES.BREAKPOINTS.BP_414,
    breakpointColFlexPhabletStackMax: VARIABLES.BREAKPOINTS.BP_767,
    breakpointColFlexTabletStackMin:  VARIABLES.BREAKPOINTS.BP_768,
    breakpointColFlexTabletStackMax:  VARIABLES.BREAKPOINTS.BP_1023,
    breakpointColFlexLaptopStackMin:  VARIABLES.BREAKPOINTS.BP_1024,
    breakpointColFlexLaptopStackMax:  VARIABLES.BREAKPOINTS.BP_1366,
    breakpointColFlexDesktopStackMin: VARIABLES.BREAKPOINTS.BP_1366,
    breakpointColFlexDesktopStackMax: VARIABLES.BREAKPOINTS.BP_1599
  }

  static propTypes = { // TYPE > node, string, number, bool, array, object, symbol, func
    /**
    * Additional classes
    */
    className: PropTypes.string,

    /**
    * Additional Text, HTML, Component
    */
    children: PropTypes.oneOfType([
      PropTypes.node,
      PropTypes.string
    ])
  }
}

/**
 * GridBase description:
 * - 12 Columns system
 * - Breakpoints:
 *   - Mobile        (from screen to 413px)
 *   - Phablet       (min 414px)
 *   - Tablet        (min 768px)
 *   - Laptop        (min 1024px)
 *   - Desktop       (min 1366px)
 *   - Large Desktop (min 1600px)
 */

export class GridBase extends React.PureComponent {
  render () {
    const {
      // Option
      children,
      className,
      // Gutter
      gutterUnit,
      gutter,
      gutterMobile,
      gutterPhablet,
      gutterTablet,
      gutterLaptop,
      gutterDesktop,
      gutterLargeDesktop,
      gutterVertical,
      gutterVerticalMobile,
      gutterVerticalPhablet,
      gutterVerticalTablet,
      gutterVerticalLaptop,
      gutterVerticalDesktop,
      gutterVerticalLargeDesktop,
      // Justify
      justify,
      justifyMobile,
      justifyPhablet,
      justifyTablet,
      justifyLaptop,
      justifyDesktop,
      justifyLargeDesktop,
      // Align
      align,
      // Breakpoint
      breakpointMobile,
      breakpointPhablet,
      breakpointTablet,
      breakpointLaptop,
      breakpointDesktop,
      breakpointLargeDesktop,
      // No Wrap
      noWrap
    } = this.props

    return (
      <GridBaseWrapper
        className={ClassNames(
          'grid-base',
          // Option
          className
        )}
      >
        <GridContainerBaseWrapper
          className='grid-container-base'
          theme={{
            // Gutter
            gutterUnit,
            gutter,
            gutterMobile,
            gutterPhablet,
            gutterTablet,
            gutterLaptop,
            gutterDesktop,
            gutterLargeDesktop,
            gutterVertical,
            gutterVerticalMobile,
            gutterVerticalPhablet,
            gutterVerticalTablet,
            gutterVerticalLaptop,
            gutterVerticalDesktop,
            gutterVerticalLargeDesktop,
            // Justify
            justify,
            justifyMobile,
            justifyPhablet,
            justifyTablet,
            justifyLaptop,
            justifyDesktop,
            justifyLargeDesktop,
            // Align
            align,
            // Breakpoint
            breakpointMobile,
            breakpointPhablet,
            breakpointTablet,
            breakpointLaptop,
            breakpointDesktop,
            breakpointLargeDesktop,
            // No Wrap
            noWrap
          }}
        >
          {children}
        </GridContainerBaseWrapper>
      </GridBaseWrapper>
    )
  }

  static Column = GridColumnBase

  static defaultProps = {
    // Option
    // children: undefined,
    // className: undefined,
    // Gutter *Don't set default gutter value because CSS overwrite
    gutterUnit: 'px',
    // Breakpoint

    // MOBILE: VARIABLES.BREAKPOINTS.BP_413,
    // PHABLET: VARIABLES.BREAKPOINTS.BP_414,
    // TABLET: VARIABLES.BREAKPOINTS.BP_768,
    // LAPTOP: VARIABLES.BREAKPOINTS.BP_1024,
    // DESKTOP: VARIABLES.BREAKPOINTS.BP_1366,
    // LARGE_DESKTOP: VARIABLES.BREAKPOINTS.BP_1600


    breakpointMobile:       VARIABLES.BREAKPOINTS.BP_413,
    breakpointPhablet:      VARIABLES.BREAKPOINTS.BP_414,
    breakpointTablet:       VARIABLES.BREAKPOINTS.BP_768,
    breakpointLaptop:       VARIABLES.BREAKPOINTS.BP_1024,
    breakpointDesktop:      VARIABLES.BREAKPOINTS.BP_1366,
    breakpointLargeDesktop: VARIABLES.BREAKPOINTS.BP_1600,
  }

  static propTypes = { // TYPE > node, string, number, bool, array, object, symbol, func
    /**
    * Additional classes
    */
    className: PropTypes.string,

    /**
    * Additional Text, HTML, Component
    */
    children: PropTypes.oneOfType([
      PropTypes.node,
      PropTypes.string
    ])
  }
}
