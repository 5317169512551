import React from 'react'
import axios from 'axios'
import _ from 'lodash'
import {
  TransitionContainer,
  Container,
  Section,
  Image,
  Text,
  Button,
  NavigationBar,
  Field,
  // Modal,
  Grid,
  Alert,
} from './../../components'
import {
  VARIABLES,
  TYPOGRAPHYS,
  ICONS,
} from './../../themes'
import {
  ROUTE_PATH,
  redirect,
} from './../../helpers'
import {
  Loader
} from '../Loader';
import { LOCAL_STORAGE } from '../../helpers/constants/localStorage';


export class FindDealerDetailWriteCommentPage extends React.Component {
  constructor(props) {
    super(props)
    const user = JSON.parse(localStorage.getItem(LOCAL_STORAGE.REGISTER_INFO))
    this.state = {
      id: (this.props.match.params && this.props.match.params.id) ? +this.props.match.params.id : '',
      // showModalSuccess: false,
      userId: _.get(user, 'id'),
      rating: 0,
      title: '',
      review: '',
      showModal: false,
      titleModal: '',
      descriptionModal: '',
      typeModal: '',
      isLoading: false,
    }
  }

  onChangeRating(rating) {
    this.setState({ rating })
  }

  onChangeField(key, event) {
    this.setState({ [key]: event.target.value });
  }

  onSubmit() {
    const { title, review, rating, userId, id } = this.state
    this.setState({ isLoading: true }, async () => {
      try {
        const resValidateData = this.validateData()
        if (resValidateData.isError) {
          this.setState({ showModal: true, typeModal: 'error', titleModal: '', descriptionModal: resValidateData.msg })
          return
        }

        const url = '/api/fdreview/' + userId
        const options = { title, review, rating, ad_id: id }
        const response = await axios.post(url, options)
        if (response.status === 200) {
          this.setState({ showModal: true, typeModal: 'success', titleModal: 'Comment Posted!', descriptionModal: 'Thank you for your feedback' })
        } else {
          this.showModalError()
        }
      } catch (_) {
        this.showModalError()
      } finally {
        this.setState({ isLoading: false })
      }
    })
  }

  showModalError() {
    this.setState({ showModal: true, typeModal: 'error', titleModal: '', descriptionModal: 'Something went wrong, Please try again later' })
  }

  validateData() {
    const { title, review, rating } = this.state
    if (!title) return { isError: true, msg: 'Please specify title' }
    if (!review) return { isError: true, msg: 'Please specify comment' }
    if (!rating) return { isError: true, msg: 'Please specify rating' }

    return { isError: false, msg: '' }
  }

  onCloseModal() {
    this.setState({ showModal: false, typeModal: '' })
  }

  redirectToListPage() {
    const {
      id,
    } = this.state;
    redirect(`${ROUTE_PATH.FIND_DEALER_LIST.LINK}/${id}`);
  }

  redirectToCommentPage() {
    const {
      id,
    } = this.state;
    redirect(`${ROUTE_PATH.FIND_DEALER_COMMENTS.LINK}/${id}`);
  }

  renderRating() {
    const { rating } = this.state
    const stars = []
    for (let i = 1; i <= 5; i++) {
      stars.push(<Grid.Column key={i}>
        <Image
          widthRatio='34'
          widthRatioUnit='px'
          heightRatio='32'
          heightRatioUnit='px'
          src={`${rating < i ? ICONS['ic-star-blue-border.svg'] : ICONS['ic-star-blue.svg']}`}
          onClick={this.onChangeRating.bind(this, i)}
        />
      </Grid.Column>)
    }
    return (
      <Grid gutter='11' justify='flex-end'>
        {stars}
      </Grid>
    )
  }

  handleBack() {
    const {
      id,
    } = this.state;
    redirect(`${ROUTE_PATH.FIND_DEALER_LIST.LINK}/${id}`);
  }

  // modal = {
  //   onClickOpenModalSuccess: () => {
  //     this.setState({
  //       showModalSuccess: true
  //     })
  //   },

  //   onClickCloseModalSuccess: () => {
  //     this.setState({
  //       showModalSuccess: false
  //     })
  //     redirect(ROUTE_PATH.FIND_DEALER_LIST.LINK);
  //   }
  // }

  render() {
    const {
      title,
      review,
      showModal,
      titleModal,
      descriptionModal,
      typeModal,
      isLoading,
    } = this.state

    return (
      <TransitionContainer>
        <Container
          width={VARIABLES.WEBSITE_WIDTH}
          calcMinHeight='100vh'
          bgColor={VARIABLES.COLORS.WHITE}
        >
          <NavigationBar
            shadow
            pageTitle='Comments'
            actionLeft={
              <Image
                widthRatio='11'
                widthRatioUnit='px'
                heightRatio='21'
                heightRatioUnit='px'
                src={ICONS['ic-left-black.svg']}
                onClick={this.handleBack.bind(this)}
              />
            }
          />
          <Section
            scroll
            calcHeight='100vh - 70px'
            paddingTop='30'
            paddingBottom='30'
            paddingHorizontal='20'
          >
            <Section spacingBottom='30'>
              <Grid gutter='10'>
                <Grid.Column flex='1'>
                  <Section
                    paddingTop='10'
                    paddingLeft='20'
                  >
                    <Text
                      fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_12}
                      color={VARIABLES.COLORS.GRAY_3}
                    >
                      Rating
                    </Text>
                  </Section>
                </Grid.Column>
                <Grid.Column flex='4'>
                  {/* <Grid gutter='11' justify='flex-end'>
                    <Grid.Column>
                      <Image
                        widthRatio='34'
                        widthRatioUnit='px'
                        heightRatio='32'
                        heightRatioUnit='px'
                        src={ICONS['ic-star-blue-border.svg']}
                      />
                    </Grid.Column>
                    <Grid.Column>
                      <Image
                        widthRatio='34'
                        widthRatioUnit='px'
                        heightRatio='32'
                        heightRatioUnit='px'
                        src={ICONS['ic-star-blue.svg']}
                      />
                    </Grid.Column>
                    <Grid.Column>
                      <Image
                        widthRatio='34'
                        widthRatioUnit='px'
                        heightRatio='32'
                        heightRatioUnit='px'
                        src={ICONS['ic-star-blue.svg']}
                      />
                    </Grid.Column>
                    <Grid.Column>
                      <Image
                        widthRatio='34'
                        widthRatioUnit='px'
                        heightRatio='32'
                        heightRatioUnit='px'
                        src={ICONS['ic-star-blue.svg']}
                      />
                    </Grid.Column>
                    <Grid.Column>
                      <Image
                        widthRatio='34'
                        widthRatioUnit='px'
                        heightRatio='32'
                        heightRatioUnit='px'
                        src={ICONS['ic-star-blue.svg']}
                      />
                    </Grid.Column>
                  </Grid> */}
                  {this.renderRating()}
                </Grid.Column>
              </Grid>
            </Section>
            <Section spacingBottom='20'>
              <Field
                fluid
                floatLabel
                height='40'
                label='Title'
                labelFontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_12}
                labelFontColor={VARIABLES.COLORS.GRAY_3}
                placeholder='Insert title'
                placeholderColor={VARIABLES.COLORS.GRAY_5}
                paddingHorizontal='10'
                borderWidth='1'
                borderStyle='solid'
                borderColor={VARIABLES.COLORS.GRAY_5}
                borderRadius='5'
                fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_14}
                fontColor={VARIABLES.COLORS.BLACK}
                value={title}
                onChange={this.onChangeField.bind(this, 'title')}
              />
            </Section>
            <Section spacingBottom='6'>
              <Field
                fluid
                floatLabel
                type='textarea'
                maxLength='1000'
                height='155'
                label='Comment'
                labelFontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_12}
                labelFontColor={VARIABLES.COLORS.GRAY_3}
                placeholder='what do you think about it…'
                placeholderColor={VARIABLES.COLORS.GRAY_5}
                paddingTop='12'
                paddingHorizontal='10'
                borderWidth='1'
                borderStyle='solid'
                borderColor={VARIABLES.COLORS.GRAY_5}
                borderRadius='5'
                fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_14}
                fontColor={VARIABLES.COLORS.BLACK}
                value={review}
                onChange={this.onChangeField.bind(this, 'review')}
              />
            </Section>
            <Section spacingBottom='28' justify='flex-end'>
              <Text
                fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_12}
                color={VARIABLES.COLORS.GRAY_4}
              >
                Characters ( {review.length} / 1,000 )
              </Text>
            </Section>
            <Section justify='center'>
              <Button
                shadow
                className='is-gradient-blue'
                width='275'
                height={VARIABLES.BUTTON.HEIGHTS.H_40}
                borderRadius={VARIABLES.BUTTON.BORDER_RADIUSES.BR_10}
                onClick={this.onSubmit.bind(this)}
              >
                <Text
                  fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_16}
                  color={VARIABLES.COLORS.WHITE}
                >
                  Submit
                </Text>
              </Button>
            </Section>
          </Section>
        </Container>

        {/* <Modal
          open={showModalSuccess}
          width={VARIABLES.MODAL.WIDTHS.W_300}
          overlayColor={VARIABLES.COLORS.OVERLAY_3}
          spacing={VARIABLES.MODAL.CONTAINER.C_1.SPACING}
          transition={VARIABLES.TRANSITIONS.DEFAULT}
          transformStart={VARIABLES.MODAL.CONTAINER.C_1.TRANSFORM_START}
          transformEnd={VARIABLES.MODAL.CONTAINER.C_1.TRANSFORM_END}
          onClickClose={this.modal.onClickCloseModalSuccess}
        >
          <Modal.Section
            paddingTop={VARIABLES.MODAL.HEADER.H_1.PADDING_TOP}
            paddingBottom={VARIABLES.MODAL.HEADER.H_1.PADDING_BOTTOM}
            paddingHorizontal={VARIABLES.MODAL.HEADER.H_1.PADDING_HORIZONTAL}
            borderTopRightRadius={VARIABLES.MODAL.HEADER.H_1.BORDER_TOP_RIGHT_RADIUS}
            borderTopLeftRadius={VARIABLES.MODAL.HEADER.H_1.BORDER_TOP_LEFT_RADIUS}
          >
            <Section justify='center'>
              <Image
                widthRatio='61'
                widthRatioUnit='px'
                heightRatio='61'
                heightRatioUnit='px'
                src={ICONS['ic-success.svg']}
              />
            </Section>
          </Modal.Section>
          <Modal.Section
            paddingBottom={VARIABLES.MODAL.BODY.B_1.PADDING_BOTTOM}
            paddingHorizontal={VARIABLES.MODAL.BODY.B_1.PADDING_HORIZONTAL}
          >
            <Section
              spacingBottom='12'
              justify='center'
            >
              <Text
                fontStyle={TYPOGRAPHYS.FIRST.BOLD.FS_18}
                color={VARIABLES.COLORS.BLACK}
              >
                Comment success!
              </Text>
            </Section>
            <Section
              justify='center'
            >
              <Text
                fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_15}
                color={VARIABLES.COLORS.GRAY_6}
                align='center'
              >
                Thank you for your comment
              </Text>
            </Section>
          </Modal.Section>
          <Modal.Section
            paddingBottom={VARIABLES.MODAL.FOOTER.F_1.PADDING_BOTTOM}
            paddingHorizontal={VARIABLES.MODAL.FOOTER.F_1.PADDING_HORIZONTAL}
            borderBottomRightRadius={VARIABLES.MODAL.FOOTER.F_1.BORDER_BOTTOM_RIGHT_RADIUS}
            borderBottomLeftRadius={VARIABLES.MODAL.FOOTER.F_1.BORDER_BOTTOM_LEFT_RADIUS}
          >
            <Button
              fluid
              shadow
              className='is-gradient-blue'
              height={VARIABLES.BUTTON.HEIGHTS.H_35}
              borderRadius={VARIABLES.BUTTON.BORDER_RADIUSES.BR_10}
              onClick={this.modal.onClickCloseModalSuccess}
            >
              <Text
                fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_16}
                color={VARIABLES.COLORS.WHITE}
              >
                OK
              </Text>
            </Button>
          </Modal.Section>
        </Modal> */}
        <Alert
          open={showModal}
          type={typeModal}
          title={titleModal}
          description={descriptionModal}
          onClose={typeModal === 'success' ? this.redirectToListPage.bind(this) : this.onCloseModal.bind(this)}
        />
        <Loader loading={isLoading} isOverlay />
      </TransitionContainer>
    )
  }
}
