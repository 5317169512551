import React from 'react'
import _ from 'lodash'
import {
  Section,
  Button,
  Image,
} from './../../components'
import {
  ICONS,
  VARIABLES,
} from './../../themes'
import {
  Map,
  Marker,
  GoogleApiWrapper,
} from 'google-maps-react';
// import {
//   Loader
// } from '../Loader';

// const LoadingContainer = () => (
//   <Loader loading isOverlay />
// )

export class MapContainer extends React.Component {
  constructor(props) {
    super(props)
    const { list } = this.props
    const initialCenterData = !_.isEmpty(list) && _.isArray(list) && list.find(data => data.latitude && data.longitude)
    this.state = {
      userLatitude: 0,
      userLongitude: 0,
      toggleCenter: false,
      centerLatitude: (initialCenterData) ? initialCenterData.latitude : 0,
      centerLongitude: (initialCenterData) ? initialCenterData.longitude : 0,
    }
  }

  onZoomToUserLocation() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(this.setCenterPosition)
    }
  }

  setCenterPosition = (position) => {
    this.setState({
      centerLatitude: position.coords.latitude,
      centerLongitude: position.coords.longitude,
    })
  }

  render() {
    const { centerLatitude, centerLongitude } = this.state
    const { list, className, dataId } = this.props
    return (
      <Section className={className}>
        <Button
          className={`is-icon is-shadow is-gps-dealer-result ${className ? className : ''}`}
          width={34}
          height={34}
          borderRadius={50}
          bgColor={VARIABLES.COLORS.WHITE}
          onClick={this.onZoomToUserLocation.bind(this)}
        >
          <Button.Icon>
            <Image
              widthRatio='22'
              widthRatioUnit='px'
              heightRatio='22'
              heightRatioUnit='px'
              src={ICONS['ic-gps-black.svg']}
            />
          </Button.Icon>
        </Button>
        <Map
          google={this.props.google}
          zoom={11}
          initialCenter={{
            lat: centerLatitude,
            lng: centerLongitude,
          }}
          center={{
            lat: centerLatitude,
            lng: centerLongitude,
          }}
          disableDefaultUI
        >
          {list.map((data, index) => {
            if (!data.latitude || !data.longitude) return null;
            if (dataId && dataId !== data.id) {
              return undefined;
            }
            return (<Marker
              key={index}
              position={{ lat: data.latitude, lng: data.longitude }}
              onClick={this.onMarkerClick}
              icon={{
                url: ICONS['ic-map-marker-logo-blue.svg'],
                anchor: new this.props.google.maps.Point(45, 45),
                scaledSize: new this.props.google.maps.Size(90, 90),
                labelOrigin: new this.props.google.maps.Point(44, 27)
              }}
              label={{
                text: `${(index + 1)}`,
                color: 'white',
              }}
            />
            )
          })}
          {/* <Marker
            position={{ lat: 13.803071, lng: 100.5370008 }}
            onClick={this.onMarkerClick}
            icon={{
              url: ICONS['ic-map-marker-blue.svg'],
              anchor: new this.props.google.maps.Point(48, 48),
              scaledSize: new this.props.google.maps.Size(48, 48)
            }}
          /> */}
        </Map>
      </Section >
    )
  }
}

export default GoogleApiWrapper({
  apiKey: 'AIzaSyDFlJ9XDLGIENX4tK8y3vKWhgX2b0VbYwE',
  // LoadingContainer: LoadingContainer
})(MapContainer)