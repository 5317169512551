import {
  createActions
} from 'reduxsauce'
import moment from 'moment';

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  setDataRedux: ['key', 'data'],
})

export const DATA_REDUX_TYPES = Types
export const dataCreators = Creators

/* ------------- Initial State ------------- */
export const DATA_REDUX_STATE_KEY = {
  COUNTRY_LIST: 'countryList',
  FIREBASE_NOTI: 'firebaseNoti',
  APP_VERSION: 'appVersion',
  CAMPAIGN_DATA: 'campaignData',
}

const startCampaignDate = '1/1/2021';
const endCampaignDate = '6/30/2021';
const campaignCode = 'TB001';

export const DATA_REDUX_INITIAL_STATE = {
  countryList: [],
  firebaseNoti: {
    orderInquiry: {}
  },
  appVersion: {},
  campaignData: {
    // MM/DD/YYYY
    startDate: startCampaignDate,
    endDate: endCampaignDate,
    isEndCampaign: moment().startOf('day').diff(new Date(endCampaignDate)) > 0,
    campaignCode,
  },
}