import React from 'react'
import _ from 'lodash'
import qs from 'qs'
import {
  TransitionContainer,
  Container,
  Text,
  Image,
  NavigationBar,
  Section,
  Grid,
  ShapeContainer,
} from './../../components'
import {
  VARIABLES,
  TYPOGRAPHYS,
  ICONS,
  LOGOS,
  CONTENTS
} from './../../themes'
import {
  ROUTE_PATH,
  redirect,
} from './../../helpers'
import {
  EstimatorInput,
} from '../EstimatorPage/EstimatorInput'
import {
  OutputSummary,
} from './OutputSummary'
import { getLanguage } from '../../helpers/functions/language'
import {
  redirectData,
} from '../../helpers/functions/routes';

export class EstimatorSummaryPage extends React.Component {
  constructor(props) {
    super(props)
    const query = qs.parse(_.get(this.props, 'location.search', '').replace('?', ''))
    this.state = {
      isLoading: false,
      data: query,
      roofType: query.roofType,
      profileType: query.profileType,
      width: query.width,
      length: query.length,
      height: query.height,
      overhang: query.overhang,
    }
  }

  estimateSummary = {
    goBack: () => {
      const {
        data,
      } = this.state;
      redirectData(ROUTE_PATH.ESTIMATOR.LINK, {
        data
      });
    }
  }

  render() {
    const {
      roofType,
      profileType,
      width,
      length,
      height,
      overhang
    } = this.state
    const input = {
      roofType,
      profileType,
      width,
      length,
      height,
      overhang
    };
    return (
      <TransitionContainer>
        <Container
          width={VARIABLES.WEBSITE_WIDTH}
          calcMinHeight='100vh'
          bgColor={VARIABLES.COLORS.WHITE}
        >
          <NavigationBar
            shadow
            pageTitle={getLanguage('estimatorSummary.title')}
            actionLeft={
              <Image
                widthRatio='11'
                widthRatioUnit='px'
                heightRatio='21'
                heightRatioUnit='px'
                src={ICONS['ic-left-black.svg']}
                onClick={this.estimateSummary.goBack}
              />
            }
            actionRight={
              <Text
                fontStyle={TYPOGRAPHYS.FIRST.MEDIUM.FS_14}
                color={VARIABLES.COLORS.BLUE_2}
                onClick={() => redirect(ROUTE_PATH.ESTIMATOR.LINK)}
              >
                {getLanguage('field.buttonClear')}
              </Text>
            }
          />
          <Section
            paddingHorizontal='20'
            paddingTop='30'
            paddingBottom='10'
          >
            <EstimatorInput input={input} readOnly />
          </Section>
          <OutputSummary isCaptureImage
            input={input}
          />
        </Container>

        <div id='captureContainer'
          style={{
            position: 'absolute',
            left: '-9999px',
          }}
        >
          <Section
            className='is-estimator-template'
            justify='center'
            align='center'
          >
            <Section className='is-estimator-template-container'>
              <Section spacingBottom='45'>
                <Section
                  paddingBottom='25'
                  spacingBottom='45'
                  borderBottomWidth='3'
                  borderBottomStyle='solid'
                  borderBottomColor={VARIABLES.COLORS.BLUE_2}
                >
                  <Grid justify='space-between'>
                    <Grid.Column flex='none'>
                      <Section width='121'>
                        <Image
                          widthRatio='81'
                          widthRatioUnit='px'
                          heightRatio='80'
                          heightRatioUnit='px'
                          src={LOGOS['logo.svg']}
                        />
                      </Section>
                    </Grid.Column>
                    <Grid.Column flex='1'>
                      <Section
                        justify='center'
                        paddingTop='22'
                      >
                        <Text
                          fontStyle={TYPOGRAPHYS.FIRST.BOLD.FS_30}
                          color={VARIABLES.COLORS.BLACK}
                        >
                          {getLanguage('estimatorSummary.quantityEstimate')}
                        </Text>
                      </Section>
                    </Grid.Column>
                    <Grid.Column flex='none'>
                      <Section paddingTop='5'>
                        <Image
                          widthRatio='121'
                          widthRatioUnit='px'
                          heightRatio='69'
                          heightRatioUnit='px'
                          src={LOGOS['logo-light.svg']}
                        />
                      </Section>
                    </Grid.Column>
                  </Grid>
                </Section>
                <Section>
                  <Text
                    fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_22}
                    color={VARIABLES.COLORS.BLACK}
                  >
                    <Text
                      fontStyle={TYPOGRAPHYS.FIRST.BOLD.FS_22}
                      color={VARIABLES.COLORS.BLACK}
                    >
                      {getLanguage('estimatorSummary.congratulations')}
                    </Text>
                    &nbsp;
                    {getLanguage('estimatorSummary.note_1')}
                    .&nbsp;
                    {getLanguage('estimatorSummary.note_2')}
                    &nbsp;
                    {getLanguage('estimatorSummary.note_3')}
                  </Text>
                </Section>
              </Section>
              <ShapeContainer
                fluid
                paddingTop='30'
                paddingHorizontal='30'
                borderWidth='1'
                borderStyle='solid'
                borderColor={VARIABLES.COLORS.BLUE_2}
                borderRadius='7'
              >
                <Grid
                  gutter='60'
                  justify='space-between'
                >
                  <Grid.Column grid='4'>
                    <EstimatorInput input={input} readOnly />
                  </Grid.Column>
                  <Grid.Column grid='4'>
                    <OutputSummary
                      legend={false}
                      action={false}
                      input={input}
                    />
                  </Grid.Column>
                  <Grid.Column grid='4'>
                    <Section
                      spacingBottom='30'
                      justify='center'
                    >
                      <img src={CONTENTS['estimator-1.png']} alt='Estimator legend' />
                    </Section>
                    <Section
                      spacingBottom='30'
                      justify='center'
                    >
                      <img src={CONTENTS['estimator-2.png']} alt='Estimator legend' />
                    </Section>
                    <Section justify='center'>
                      <img src={CONTENTS['estimator-3.png']} alt='Estimator legend' />
                    </Section>
                  </Grid.Column>
                </Grid>
              </ShapeContainer>
            </Section>
          </Section>
        </div>
      </TransitionContainer>
    )
  }
}
