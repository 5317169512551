import React from 'react'
import ClassNames from 'classnames'
import PropTypes from 'prop-types'
import Swiper from 'react-id-swiper'
import './css/swiper.min.css'
import {
  SwiperCarouselWrapper
} from './styled'

class SwiperCarouselItem extends React.PureComponent {
  static propTypes = { // TYPE > node, string, number, bool, array, object, symbol, func
    /**
    * Additional classes
    */
    className: PropTypes.string,

    /**
    * Additional elements or text
    */
    children: PropTypes.oneOfType([
      PropTypes.node,
      PropTypes.string
    ]),

    /**
    * Modifier name for change default multiple UI (parent and children)
    */
    // ui: PropTypes.oneOf([]),

    /**
    * href for <a>
    */
    href: PropTypes.string,

    /**
    * On click event
    */
    onClick: PropTypes.func
  }

  render() {
    const {
      ui,
      className,
      children,
      href,
      onClick
    } = this.props

    // props for css classes
    const uiClasses = ClassNames(ui)
    const classes = ClassNames(
      'swiper-carousel-item',
      { [`is-ui-${uiClasses}`]: uiClasses },
      className
    )

    return (
      <React.Fragment>
        {
          href ?
            <a
              className={classes}
              href={href}
              onClick={onClick}
            >
              {children}
            </a>
            :
            <div
              className={classes}
              onClick={onClick}
            >
              {children}
            </div>
        }
      </React.Fragment>
    )
  }
}

/**
 * SwiperCarousel description:
 * - Carousel for image or anything component
 * - https://github.com/kidjp85/react-id-swiper (this library wrapped Swiper - http://idangero.us/swiper/api/)
 */

export class SwiperCarousel extends React.PureComponent {
  static defaultProps = {
    options: {
      // loop: true,
      // autoplay: {
      //   delay: 5000,
      //   disableOnInteraction: false
      // },
      pagination: {
        el: '.swiper-pagination'
      }
    }
  }

  static propTypes = { // TYPE > node, string, number, bool, array, object, symbol, func
    /**
    * Modifier name for change default multiple UI (parent and children)
    */
    ui: PropTypes.oneOf([
      'card'
    ]),

    /**
    * Additional classes
    */
    className: PropTypes.string,

    /**
    * Additional elements or text
    */
    children: PropTypes.oneOfType([
      PropTypes.node,
      PropTypes.string
    ]),

    /**
    * Options for carousel
    * - http://kidjp85.github.io/react-id-swiper/
    */
    options: PropTypes.object,

    setRef: PropTypes.func

  }

  static Item = SwiperCarouselItem

  render() {
    const {
      ui,
      className,
      children,
      options,
      setRef,
    } = this.props

    // props for css classes
    const uiClasses = ClassNames(ui)
    const classes = ClassNames(
      'swiper-carousel',
      { [`is-ui-${uiClasses}`]: uiClasses },
      className
    )

    return (
      <SwiperCarouselWrapper
        className={classes}
      >
        <Swiper {...options} ref={setRef}
        >
          {children}
        </Swiper>
      </SwiperCarouselWrapper>
    )
  }
}
