import styled from 'styled-components'
import {
  default as VARIABLES
} from './../../../themes/styles/bases/variables'
// import {
//   default as TYPOGRAPHYS
// } from './../../../themes/styles/bases/typographys'
// import {
//   default as MIXINS
// } from './../../../themes/styles/helpers/mixins'

// Sample using image in style
// url(${require('./../../../themes/images/icons/icon-sample.svg')});

// Wrapper
// ============================================================
export const SwiperCarouselWrapper = styled.div`
  /* Parent
  ------------------------------- */
  width: 100%;

  /* Childrens
  ------------------------------- */
  .swiper-carousel-item {
    display: block;
  }

  /* Overwrite default swiper classes */
  .swiper-container {
    &.swiper-container-horizontal {
      /* Pagination */
      > .swiper-pagination-bullets {
        bottom: 36px;

        .swiper-pagination-bullet {
          transition: ${VARIABLES.TRANSITIONS.DEFAULT};
          opacity: 1;
          width: 10px;
          height: 10px;
          margin-right: 2px;
          margin-left: 2px;
          background-color: ${VARIABLES.COLORS.TRANSPARENT};
          border: 2px solid ${VARIABLES.COLORS.WHITE};
          border-radius: 50%;

          &.swiper-pagination-bullet-active {
            background-color: ${VARIABLES.COLORS.WHITE};
          }
        }
      }
    }
  }

  /* Modifiers
  ------------------------------- */
  &.is-ui-card {
    .swiper-carousel-item {
      width: 162px;
      margin-left: 7px;
      margin-bottom: 5px;

      &:first-child {
        margin-left: 15px;
      }

      &:last-child {
        margin-right: 15px;
      }

      @media (max-width: ${VARIABLES.BREAKPOINTS.MOBILE_XS_MAX}) {
        width: 132px;
      }

      @media (min-width: ${VARIABLES.BREAKPOINTS.MOBILE_SM}) and (max-width: ${VARIABLES.BREAKPOINTS.MOBILE_SM_MAX}) {
        width: 152px;
      }
    }
  }

  &.is-banner-home {
    .swiper-container {
      &.swiper-container-horizontal {
        > .swiper-pagination-bullets {
          bottom: 9vh; /* 70px */
        }
      }
    }
  }

  &.is-banner-subcategory-detail {
    .swiper-container {
      &.swiper-container-horizontal {
        > .swiper-pagination-bullets {
          bottom: 20px;
        }
      }
    }
  }

  &.is-banner-news-and-promotions {
    .swiper-container {
      &.swiper-container-horizontal {
        /* Pagination */
        > .swiper-pagination-bullets {
          bottom: 15px;
        }
      }
    }
    &.is-empty {
      height: 85px;
    }
  }

  &.is-dealer-card {
    .swiper-container {
      &.swiper-container-horizontal {
        /* Pagination */
        > .swiper-pagination-bullets {
          bottom: 5px;
        }
      }
    }
  }

  &.is-dealer-list {
    .swiper-carousel-item {
      width: 80%;
    }
  }

  &.is-reward-detail {
    .swiper-container {
      position: static;

      
      &.swiper-container-horizontal  {
        > .swiper-pagination-bullets {
          bottom: -10%;
          display: flex;
          justify-content: center;
          align-items: center;

        .swiper-pagination-bullet {
          background-color: ${VARIABLES.COLORS.WHITE};

          &.swiper-pagination-bullet-active {
            width: 22px;
            height: 22px;
            background-color: ${VARIABLES.COLORS.PRIMARY_2};
          }
        }
      }
        
      }
    }
  }

  /* Media queries
  ------------------------------- */
`
