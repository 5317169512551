import styled, {
  css,
  injectGlobal
} from 'styled-components'
import {
  default as VARIABLES
} from './../../../themes/styles/bases/variables'
// import {
//   default as VARIABLES
// } from './../../../themes/styles/bases/variables'
// import {
//   default as TYPOGRAPHYS
// } from './../../../themes/styles/bases/typographys'
// import {
//   default as MIXINS
// } from './../../../themes/styles/helpers/mixins'

// Sample using image in style
// url(${require('./images/icons/icon-sample.svg')});
// url(${require('./../../../themes/images/icons/icon-sample.svg')});

// Wrapper
// ============================================================
export const NotificationBaseWrapper = styled.div`
  /* Parent
  ------------------------------- */
  position: relative;
  display: flex;
  align-items: center;

  ${props => {
    const theme = props.theme
    return css`
      /* Padding */
      ${theme.padding && css`
          padding: ${theme.padding + `px`};
        `
      }

      ${theme.paddingTop && css`
          padding-top: ${theme.paddingTop + `px`};
        `
      }

      ${theme.paddingRight && css`
          padding-right: ${theme.paddingRight + `px`};
        `
      }

      ${theme.paddingBottom && css`
          padding-bottom: ${theme.paddingBottom + `px`};
        `
      }

      ${theme.paddingLeft && css`
          padding-left: ${theme.paddingLeft + `px`};
        `
      }

      ${theme.paddingHorizontal && css`
          padding-right: ${theme.paddingHorizontal + `px`};
          padding-left: ${theme.paddingHorizontal + `px`};
        `
      }

      ${theme.paddingVertical && css`
          padding-top: ${theme.paddingVertical + `px`};
          padding-bottom: ${theme.paddingVertical + `px`};
        `
      }

      /* Spacing (Margin) */
      ${theme.spacing && css`
          margin: ${theme.spacing + `px`};
        `
      }

      ${theme.spacingTop && css`
          margin-top: ${theme.spacingTop + `px`};
        `
      }

      ${theme.spacingRight && css`
          margin-right: ${theme.spacingRight + `px`};
        `
      }

      ${theme.spacingBottom && css`
          margin-bottom: ${theme.spacingBottom + `px`};
        `
      }

      ${theme.spacingLeft && css`
          margin-left: ${theme.spacingLeft + `px`};
        `
      }

      ${theme.spacingHorizontal && css`
          margin-right: ${theme.spacingHorizontal + `px`};
          margin-left: ${theme.spacingHorizontal + `px`};
        `
      }

      ${theme.spacingVertical && css`
          margin-top: ${theme.spacingVertical + `px`};
          margin-bottom: ${theme.spacingVertical + `px`};
        `
      }

      /* Background color */
      background-color: ${theme.bgColor};

      /* Border */
      ${theme.borderWidth && css`
          border: ${theme.borderWidth && `${theme.borderWidth + `px`}`} ${theme.borderStyle} ${theme.borderColor};
        `
      }

      border-radius: ${theme.borderRadius + `px`};

      /* Box shadow */
      box-shadow: ${theme.boxShadow};
    `
  }}

  /* Childrens
  ------------------------------- */
  /* Modifiers
  ------------------------------- */
  /* Media queries
  ------------------------------- */
`

// React Toastify - Overwrite style
injectGlobal`
  .react-toastify {
    /* Container */
    &.Toastify__toast-container {
      z-index: ${VARIABLES.Z_INDEXS.LV_6};
      width: 100%;
      max-width: ${VARIABLES.NOTIFICATION.WIDTHS.DEFAULT + `px`};
      padding: 60px 0 0;
    }

    /* Position */
    &.Toastify__toast-container--top-left {
      top: 0;
      left: 0;
    }

    &.Toastify__toast-container--top-center {
      transform: translateX(-50%);
      top: 0;
      left: 50%;
      margin-left: 0;
    }

    &.Toastify__toast-container--top-right {
      top: 0;
      right: 0;
    }

    &.Toastify__toast-container--bottom-left {
      bottom: 0;
      left: 0;
    }

    &.Toastify__toast-container--bottom-center {
      transform: translateX(-50%);
      bottom: 0;
      left: 50%;
      margin-left: 0;
    }

    &.Toastify__toast-container--bottom-right {
      bottom: 0;
      right: 0;
    }

    /* List item */
    .Toastify__toast {
      overflow: visible;
      min-height: 1px;
      padding: 0;
      margin-bottom: 0;
      background-color: transparent;
      border-radius: 0;
      box-shadow: 0 0 0 transparent;
    }

    /* Close button */
    .Toastify__close-button {
      opacity: 1;
      position: absolute;
      z-index: ${VARIABLES.Z_INDEXS.LV_1};
      top: 0;
      right: 0;
      bottom: 0;
      width: ${VARIABLES.NOTIFICATION.BUTTON_CLOSE.WIDTH + `px`};
      font-size: 0;
    }
  }

  @keyframes slideDownFadeIn {
    0% {
      opacity: 0;
      transform: translateY(-30px);
    }

    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }

  .slideDownFadeIn {
    animation-name: slideDownFadeIn;
  }

  @keyframes slideDownFadeOut {
    0% {
      opacity: 1;
      transform: translateY(0);
    }

    100% {
      opacity: 0;
      transform: translateY(-30px);
    }
  }

  .slideDownFadeOut {
    animation-name: slideDownFadeOut;
  }
`
