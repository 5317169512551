import { TEXT_VERSION } from "../../config/config"
import { getLanguage } from "./language"
import { storeDispatch } from "../../store"
import { dataCreators, DATA_REDUX_STATE_KEY } from "../../actions/dataAction"
import { getLocalStorage, setLocalStorage } from "./localStorage"
import { LOCAL_STORAGE } from "../constants/localStorage"
import { getCurrentTimeStamp } from "./data"
import ReactHtmlParser from 'react-html-parser';

const alertTimeStamp = 1000 * 60 * 5;

export const handleAppVersion = (version = '') => {
  console.log('version check', version);
  console.log('version current', TEXT_VERSION);
  let isUpdateVersion = false;
  let currentVersionList = TEXT_VERSION.split('.');
  let checkVersionList = version.split('.');
  for (let i = 0; i < currentVersionList.length; i++) {
    const currentVersion = parseInt(currentVersionList[i]);
    const checkVersion = parseInt(checkVersionList[i]);
    if (currentVersion < checkVersion) {
      isUpdateVersion = true;
      break;
    }
    else if (currentVersion > checkVersion){
      break;
    }
  }
  if (isUpdateVersion && TEXT_VERSION !== version) {
    handleOpenAlert();
  }
}

export const onOpenAlertVersion = () => {
  const params = {
    isOpen: true,
    title: ReactHtmlParser(`
    ${getLanguage('modal.newVersion.title_1')}
    <br />
    ${getLanguage('modal.newVersion.title_2')}
    `),
    buttonSubmitName: getLanguage('field.buttonUpdate'),
    onClickSubmit: () => {
      handleAlertTimeout();
      window.location.reload(true);
    },
    onClickCancel: () => {
      handleAlertTimeout();
      onCloseAlertVersion();
    }
  }
  storeDispatch(dataCreators.setDataRedux(DATA_REDUX_STATE_KEY.APP_VERSION, params));
}

export const onCloseAlertVersion = () => {
  const params = {
    onClickSubmit: () => { },
    onClickCancel: () => { },
  }
  storeDispatch(dataCreators.setDataRedux(DATA_REDUX_STATE_KEY.APP_VERSION, params));
}

export const handleOpenAlert = () => {
  let prevTimeStamp = getLocalStorage(LOCAL_STORAGE.ALERT_VERSION_TIMEOUT_RBP_MOBILE_APP);
  const currentTimeStamp = getCurrentTimeStamp();
  if (!prevTimeStamp) {
    prevTimeStamp = currentTimeStamp - alertTimeStamp;
  }
  const nextTimeStamp = currentTimeStamp - prevTimeStamp;
  if (nextTimeStamp >= alertTimeStamp) {
    onOpenAlertVersion();
  }
  else {
    setTimeout(() => {
      onOpenAlertVersion();
    }, alertTimeStamp - nextTimeStamp);
  }
}

export const handleAlertTimeout = () => {
  const currentTimeStamp = getCurrentTimeStamp();
  setLocalStorage(LOCAL_STORAGE.ALERT_VERSION_TIMEOUT_RBP_MOBILE_APP, currentTimeStamp);
  setTimeout(() => {
    onOpenAlertVersion();
  }, alertTimeStamp);
}