import React from 'react'
import _ from 'lodash'
import qs from 'qs'
import {
  TransitionContainer,
  Container,
  Image,
  Section,
  Text,
  Grid,
  NavigationBar,
} from '../../components'
import {
  VARIABLES,
  TYPOGRAPHYS,
  ICONS,
} from '../../themes'
import {
  redirect,
  ROUTE_PATH,
  formatDatetime,
} from '../../helpers'
import { getLanguage } from '../../helpers/functions/language'

export class PurchaseHistoryDetailPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      id: (this.props.match.params && this.props.match.params.id) ? +this.props.match.params.id : '',
      data: {},
    }
  }

  componentDidMount() {
    const data = JSON.parse(localStorage.getItem('purchaseHistoryList'))
    if (_.isEmpty(data)) return
    const result = data.find(item => item.id === this.state.id)
    console.log('result', result)
    if (result) {
      this.setState({ data: result })
    }
  }

  handleBack() {
    const query = qs.parse(_.get(this.props, 'location.search', '').replace('?', ''))
    redirect(`${ROUTE_PATH.PURCHASE_HISTORY.LINK}?startDate=${_.get(query, 'startDate', '')}&endDate=${_.get(query, 'endDate', '')}`)
  }

  render() {
    const { data } = this.state
    return (
      <TransitionContainer>
        <Container
          width={VARIABLES.WEBSITE_WIDTH}
          calcMinHeight='100vh'
          bgColor={VARIABLES.COLORS.WHITE}
        >
          <NavigationBar
            shadow
            iconWidth='20'
            iconHeight='19'
            iconSrc={ICONS['ic-bill-black.svg']}
            pageTitleIcon={getLanguage('purchaseHistoryDetail.title')}
            actionLeft={
              <Image
                widthRatio='11'
                widthRatioUnit='px'
                heightRatio='21'
                heightRatioUnit='px'
                src={ICONS['ic-left-black.svg']}
                onClick={this.handleBack.bind(this)}
              />
            }
          />
          <Section
            paddingTop='35'
            paddingBottom='25'
            paddingHorizontal='20'
          >
            <Section spacingBottom='5'>
              <Text
                fontStyle={TYPOGRAPHYS.FIRST.BOLD.FS_22}
                color={VARIABLES.COLORS.BLACK}
              >
                {_.get(data, 'f_bsproductgroupname__c', '')}
              </Text>
            </Section>
            <Section spacingBottom='35'>
              <Grid gutter='6'>
                <Grid.Column>
                  <Image
                    widthRatio='12'
                    widthRatioUnit='px'
                    heightRatio='13'
                    heightRatioUnit='px'
                    top='-2'
                    src={ICONS['ic-date-gray.svg']}
                  />
                </Grid.Column>
                <Grid.Column>
                  <Text
                    fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_12}
                    color={VARIABLES.COLORS.GRAY_3}
                  >
                    {formatDatetime(data.create_at, 'DD MMM, YYYY')}
                  </Text>
                </Grid.Column>
              </Grid>
            </Section>
            <Section
              paddingBottom='5'
            >
              <Text
                fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_14}
                color={VARIABLES.COLORS.GRAY_2}
              >
                {getLanguage('field.coilName')}
              </Text>
            </Section>
            <Section
              spacingBottom='10'
              paddingBottom='15'
              borderBottomColor={VARIABLES.COLORS.GRAY_1}
              borderBottomWidth='1'
              borderBottomStyle='solid'
            >
              <Text
                fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_16}
                color={VARIABLES.COLORS.BLACK}
              >
                {_.get(data, 'coil_name', '-')}
              </Text>
            </Section>
            <Section
              paddingBottom='5'
            >
              <Text
                fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_16}
                color={VARIABLES.COLORS.GRAY_2}
              >
                {getLanguage('field.thickness')}
              </Text>
            </Section>
            <Section
              spacingBottom='10'
              paddingBottom='15'
              borderBottomColor={VARIABLES.COLORS.GRAY_1}
              borderBottomWidth='1'
              borderBottomStyle='solid'
            >
              <Text
                fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_16}
                color={VARIABLES.COLORS.BLACK}
              >
                {/* 0.47 mm */}
                {data.thickness_mm__c ? `${data.thickness_mm__c} mm` : '-'}
              </Text>
            </Section>
            <Section
              paddingBottom='5'
            >
              <Text
                fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_16}
                color={VARIABLES.COLORS.GRAY_2}
              >
                {getLanguage('field.color')}
              </Text>
            </Section>
            <Section
              spacingBottom='10'
              paddingBottom='15'
              borderBottomColor={VARIABLES.COLORS.GRAY_1}
              borderBottomWidth='1'
              borderBottomStyle='solid'
            >
              <Text
                fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_16}
                color={VARIABLES.COLORS.BLACK}
              >
                {_.get(data, 'top_paint_color__c', '-')}
              </Text>
            </Section>
            <Section
              paddingBottom='5'
            >
              <Text
                fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_16}
                color={VARIABLES.COLORS.GRAY_2}
              >
                {getLanguage('field.length')}
              </Text>
            </Section>
            <Section
              spacingBottom='10'
              paddingBottom='15'
              borderBottomColor={VARIABLES.COLORS.GRAY_1}
              borderBottomWidth='1'
              borderBottomStyle='solid'
            >
              <Text
                fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_16}
                color={VARIABLES.COLORS.BLACK}
              >
                {/* 560 meter */}
                {data.coil_length__c ? `${data.coil_length__c.toLocaleString()} ${getLanguage('field.meter')}` : '-'}
              </Text>
            </Section>
            <Section
              paddingBottom='5'
            >
              <Text
                fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_16}
                color={VARIABLES.COLORS.GRAY_2}
              >
                {getLanguage('field.points')}
              </Text>
            </Section>
            <Section
              spacingBottom='10'
              paddingBottom='15'
              borderBottomColor={VARIABLES.COLORS.GRAY_1}
              borderBottomWidth='1'
              borderBottomStyle='solid'
            >
              <Text
                fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_16}
                color={VARIABLES.COLORS.BLACK}
              >
                {_.get(data, 'points', 0).toLocaleString()} {getLanguage('field.points_2')}
              </Text>
            </Section>
            <Section
              paddingBottom='5'
            >
              <Text
                fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_16}
                color={VARIABLES.COLORS.GRAY_2}
              >
                {getLanguage('field.where')}
              </Text>
            </Section>
            <Section
              spacingBottom='10'
              paddingBottom='15'
              borderBottomColor={VARIABLES.COLORS.GRAY_1}
              borderBottomWidth='1'
              borderBottomStyle='solid'
            >
              <Text
                fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_16}
                color={VARIABLES.COLORS.BLACK}
              >
                {_.get(data, 'acc_name', '-')}
              </Text>
            </Section>
            <Section
              paddingBottom='5'
            >
              <Text
                fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_14}
                color={VARIABLES.COLORS.GRAY_2}
              >
                {getLanguage('field.status')}
              </Text>
            </Section>
            <Section
              spacingBottom='10'
              paddingBottom='15'
              borderBottomColor={VARIABLES.COLORS.GRAY_1}
              borderBottomWidth='1'
              borderBottomStyle='solid'
            >
              <Text
                fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_16}
                color={VARIABLES.COLORS.BLACK}
              >
                {data.status__c ? data.status__c : '-'}
              </Text>
            </Section>
            <Section
              paddingBottom='5'
            >
              <Text
                fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_14}
                color={VARIABLES.COLORS.GRAY_2}
              >
                {getLanguage('field.trasactionID')}
              </Text>
            </Section>
            <Section
              spacingBottom='10'
              paddingBottom='15'
            >
              <Text
                fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_16}
                color={VARIABLES.COLORS.BLACK}
              >
                {_.get(data, 'name', '-')}
              </Text>
            </Section>
          </Section>
        </Container>
      </TransitionContainer>
    )
  }
}
