import React from 'react'
import {
  connect,
} from 'react-redux';
import { Loader } from '../../pages/Loader';

export class LoaderContainer extends React.Component {

  constructor(props) {
    super(props);
    this.state = {}
  }

  render() {
    const {
      isShowLoading,
      isOverlay = true,
    } = this.props;
    return (
      <Loader
        isOverlay={isOverlay}
        loading={isShowLoading}
      />
    )
  }
}

const mapStateToProps = (state) => {
  const mainRedux = state.mainRedux;
  return {
    isShowLoading: mainRedux.isShowLoading,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
  }
}

export const LoaderContainerConnected = connect(
  mapStateToProps,
  mapDispatchToProps
)(LoaderContainer)