export const lang_in = {
  sideMenu: {
    points: 'Poin',
    myProfile: 'Profil Saya',
    settings: 'Pengaturan',
    shareWithFriends: 'Bagikan dengan Teman',
    termsAndConditions: 'Syarat dan Ketentuan',
    logOut: 'Keluar',
  },
  splash: {
    title: 'Growing your business with you',
    description: 'Engage . Showcase . Grow',
  },
  login: {
    loginByEmail: 'Login by Email',
    or: 'Or',
    loginByPhoneNumber: 'Login by Phone Number',
    orLoginWith: 'Or login with',
    reLogin: 'For account security, please log in again.',
    incorrectMemberType: 'Incorrect member type.',
  },
  loginEmail: {
    message: 'forgot password?',
    message_2: 'Choose again?',
  },
  loginPhone: {
    message: 'forgot password?',
    message_2: 'Choose again?',
  },
  welcome: {
    message_1: 'Welcome',
    message_2: 'Thanks, you are now a member of TrueBlue Program',
    message_3: 'and',
    message_4: 'you can check your point as well',
  },
  referenceCode: {
    message_1: 'Reference Code',
    message_2: 'Expired',
  },
  referralAd: {
    message_1: 'How to get',
    message_2: 'Voucher',
    message_3: 'Must be BlueScope customer',
    message_4: 'and TrueBlue member from',
    message_5: '30 Jun 2021',
  },
  lineOaValidation: {
    message_1: 'We have added a new way to',
    message_2: 'provide news and benefits.',
    message_3: 'Please scan QR code or',
    message_4: 'click button to receive.',
    message_5: 'or',
    message_6: 'Welcome',
  },
  voucherOutOfStock: {
    message_1: 'Sorry,',
    message_2: "you do not meet the conditions to",
    message_3: 'receive the E-Voucher.',
    message_4: 'In order not to miss out on receiving special privileges from',
    message_5: 'BlueScope next time',
    message_6: 'You can sign up to become a TrueBlue',
    message_7: 'Member through',
    message_8: 'LineOA today.',
    message_9: 'The purpose is to notify TrueBlue',
    message_10: 'points on every purchase.',
  },
  registerSuccess: {
    message_1: 'Register Success!',
    message_2: 'and you get',
    message_3: 'a voucher',
    message_4: 'A Voucher will be sent to the registered address',
    message_5: 'Sorry, the voucher',
    message_6: 'is sold out.',
  },
  authenticationSuccess: {
    message_1: 'Authentication Success!',
    message_2: 'Please return to TrueBlue',
  },
  register: {
    title_1: 'Sign Up',
    title_2: 'Verification OTP',
    message_1: 'Already have an account?',
    message_2: 'sent to',
  },
  home: {
    buttonViewMore: 'Lihat lebih lanjut',
    tbPoints: 'Poin TrueBlue',
    tbRewards: 'Hadiah TrueBlue',
    estimator: 'Estimasi Biaya',
    newsAndPromotions: 'Berita & Promosi',
    myProjects: 'Proyek Saya',
    products: 'Produk',
    findDealer: 'Cari Toko',
    knowledgeBase: 'Pengetahuan',
    purchaseHistory: 'Riwayat Pembelian',
    orderInquiry: 'Pemesanan',
    warranty: 'Garansi',
    training: 'TRAINING',
    your: 'Your',
    length: 'Length',
    metre: 'm',
    purchasing: 'Purchasing',
    cumulativeAmountSince: 'Cumulative amount since',
    tbMember: 'TrueBlue Member',
    rankInformation: 'Rank Information',
    moreDetail: 'More Detail',
    moreDetailExtra: 'More Details',
    noRank: 'No Rank',
    luckyDraw: 'Lucky Draw',
    willExpireOn: 'will expire on',
    point: 'point',
    checkPoint: 'Check points will expire',

  },
  myProfile: {
    title: 'Profil Saya',
    updateProfile: 'Perbarui profil',
    changePassword: 'Ubah Password',
  },
  changePassword: {
    title: 'Ubah Password',
  },
  forgotPassword: {
    title: 'Lupa Password',
    labelEnterEmail: 'Masukkan email anda',
    labelEnterPhone: 'Masukkan no HP anda',
  },
  resetPassword: {
    title: 'Reset Password',
  },
  setting: {
    title: 'Pengaturan',
    language: 'Pengaturan Bahasa',
    notification: 'Pengaturan Notifikasi',
  },
  settingNoti: {
    title: 'Pengaturan Notifikasi',
    orderInquiry: 'Pemesanan',
    newsAndPromotions: 'Berita & Promosi',
  },
  settingLanguage: {
    title: 'Bahasa',
    chooseLanguage: 'Pilih Bahasa',
    pleaseSelectLanguage: 'Silakan pilih Bahasa',
  },
  termPolicy: {
    title: 'Syarat dan Ketentuan',
  },
  loyaltyPoint: {
    title: 'Poin TrueBlue',
    points: 'Poin',
    expire: 'Poin yang akan kadaluarsa',
    expireIn: 'Poin yang akan kadaluarsa dalam',
    tab: {
      earnedPoints: {
        title: 'Poin yang didapat',
      },
      redeemedPoints: {
        title: 'Poin yang ditukar',
      },
    },
    tbPoints: 'Poin TrueBlue',
    numberOfRewards: 'Number of Rewards',
  },
  rewardCat: {
    title: 'Hadiah TrueBlue',
    blank: 'Data tidak ditemukan',
    new: 'Baru',
    points: 'Poin',
  },
  reward: {
    title: 'Hadiah',
  },
  rewardSearch: {
    title_1: 'Hasil Pencarian',
    title_2: 'Pencarian',
    rewardName: 'Reward Name',
    searchResult: 'Pencarian Result',
  },
  rewardDetail: {
    points: 'Poin',
  },
  estimator: {
    title: 'Estimasi',
    roofType: {
      gableRoof: 'Atap Pelana',
      hipRoof: 'Atap Limas',
    },
    profileType: {
      tile: 'Genteng Metal',
      sheet: 'Spanroof',
    },
    notes: 'Catatan',
    noteWidth: 'Lebar Bangunan: 4 - 12m',
    notePitch: 'Kemiringan Atap: 25 - 35 deg',
    noteOverhang: 'Overstek <= 500mm',
    buttonEstimation: 'Estimasi',
    footer_1: 'Ketentuan : Estimasi biaya ini hanya bersifat panduan',
    footer_2: 'Berdasarkan informasi yang dimasukkan oleh pengguna',
    footer_3: 'NS BlueScope tidak menjamin keakuratan perhitungan ini ',
    footer_4: 'dikarenakan kondisi aktual pada lokasi proyek yang dapat berbeda (Silakan konsultasi beserta survey dari aplikator / tenaga ahli pilihan anda).',
    footer_5: 'Silakan mengacu ke link berikut',
    footer_6: 'Syarat Penggunaan',
    footer_7: 'Lebih Detil',
  },
  estimatorSummary: {
    title: 'Ringkasan Hasil Perhitungan',
    quantityEstimate: 'Estimasi Jumlah',
    congratulations: 'Selamat !!',
    note_1: 'Anda selangkah lagi untuk mewujudkan bangunan impian anda',
    note_2: 'Silakan mengunjungi Toko BlueScope Zacs Terdekat Untuk',
    note_3: 'Menyelesaikan Transaksi',
    category: {
      title_1: 'Category : Roof',
      note_1: 'number of screw for roofing sheet will depend on profile type.',
      title_2: 'Category : Roof Structure',
      title_3: 'Category : Accessory',
      type: 'Tipe',
      profile: 'Profil',
      qty: 'Jumlah',
      unit: 'Unit',
      notes: 'Catatan',
    }
  },
  newsAndPromotions: {
    title: 'Berita & Promosi',
    tab: {
      news: {
        title: 'Berita',
      },
      promotions: {
        title: 'Promosi',
      },
    },
  },
  newsAndPromotionsComment: {
    title: 'Komentar'
  },
  newsAndPromotionsWriteComment: {
    title: 'Komentar',
    specifyTitle: 'Masukkan judul',
    specifyComment: 'Masukkan komentar',
    characters: 'Karakter',
  },
  newsAndPromotionsSearch: {
    title: 'Pencarian',
    searchResult: 'Hasil Pencarian :',
  },
  myProjects: {
    title: 'Proyek Saya',
  },
  projectsAdd: {
    title: 'Proyek Saya',
  },
  projectsEdit: {
    title: 'Edit Proyek',
  },
  projectsSearch: {
    title_1: 'Hasil Pencarian',
    title_2: 'Pencarian',
    searchProject: 'Pencarian proyek',
    searchResult: 'Hasil Pencarian :',
  },
  productCatalogs: {
    title: 'Katalog Produk',
  },
  findDealer: {
    title: 'Cari Toko',
    findDealer: 'Cari Toko',
    selectDealer: {
      title: 'Silakan Pilih Tipe toko',
      bad: 'Toko BlueScope Zacs(TM)',
      or: 'Toko Lainnya',
      distributor: 'Distributor',
      tokoStore: 'Toko BlueScope Zacs™',
      indoBad: 'BlueScope Authorized Dealer',
    }
  },
  findDealerType: {
    title: 'Tipe Toko',
    nearestDealers: 'Cari Toko Terdekat',
    byName: 'Pencarian nama toko',
    byArea: 'Pilih Berdasarkan Wilayah',
    or: 'Atau',
  },
  findDealerSearch: {
    title: 'Toko Dekat Anda',
    dealerCard: {
      buttonGetDirection: 'Petunjuk Arah',
      buttonViewDetail: 'Lihat Detil',
    }
  },
  findDealerSearchByName: {
    title: 'Pencarian Nama Toko',
    byName: 'Pencarian nama toko',
  },
  findDealerSearchByArea: {
    title: 'Pilih Berdasarkan Wilayah',
  },
  knowledgeBase: {
    title: 'Pengetahuan'
  },
  knowledgeBaseDetail: {
    title: 'Pengetahuan',
    tab: {
      description: {
        title: 'Deskripsi',
      },
      documentsDownload: {
        title: 'Unduh Dokumen',
      },
    },
  },
  purchaseHistory: {
    title_1: 'Hasil Filter',
    title_2: 'Riwayat Pembelian',
    totalSales: 'Total Pembelian',
    DataNotFound: 'Data Tidak Ditemukan',
  },
  purchaseHistorySearch: {
    title: 'Filter Tanggal',
  },
  purchaseHistoryDetail: {
    title: 'Riwayat Pembelian',
  },
  orderInquiry: {
    title: 'Pemesanan',
    category: {
      bad: 'Diler Resmi BlueScope',
      or: 'Toko Lainnya',
    },
    selectDealer: 'Cari dengan toko',
    result: 'Hasil',
    orderInquiry: 'Pemesanan',
    allPreviousOrderInquiry: 'Riwayat Pemesanan yang sudah diajukan',
    dataNotFound: 'Data tidak ditemukan',
  },
  orderInquirySearch: {
    title: 'Pencarian',
    category: {
      bad: 'Diler Resmi BlueScope',
      or: 'Toko Lainnya',
    },
    selectDealer: 'Cari toko',
    suggestions: 'Saran',
  },
  orderInquiryAdd: {
    title: 'Buat Pemesanan',
  },
  warranty: {
    title: 'Garansi',
  },
  warrantySearchByDate: {
    title: 'Filter Tanggal',
  },
  warrantySearchByName: {
    title_1: 'Hasil Pencarian',
    title_2: 'Pencarian',
    warranty: 'No. Garansi, Judul Garansi',
    salesADProduct: 'No Transaksi Penjualan, AD, Nama Produk',
    searchResult: 'Hasil Pencarian',
  },
  warrantySearch: {
    title: 'Hasil Filter',
    totalOrders: 'Total Pembelian',
  },
  warrantyProduct: {
    dataNotFound: 'Data tidak ditemukan',
  },
  warrantyProductItem: {
    issuedDate: 'Tanggal Rilis',
    product: 'Produk',
    thickness: 'Tebal',
    length: 'Panjang',
    warrantyID: 'No. Garansi',
  },
  termsAndConditions: {
    title: 'Syarat dan Ketentuan',
  },
  verify: {
    title1: 'Setup Verify Questions',
    title2: 'Verify with Questions',
    title3: 'Verify with Phone Number',
    question1: 'What is your nickname?',
    question2: "What is your favorite animal?",
    question3: 'What is the province where you were born?',
  },
  container: {
    inputOtp: {
      title: 'Verification OTP',
      message: 'sent to',
      message_2: 'OTP is incorrect, Please re-send again',
    },
  },
  modal: {
    changeLanguage: {
      title: 'Pilih Bahasa',
      message: 'Pengaturan Bahasa Telah Diubah',
    },
    editProfile: {
      title: 'Edit Profil',
      message_1: 'Profil anda telah diubah',
      message_2: 'Beberapa informasi masih kurang tepat, silakan coba lagi',
      message_3: 'Ada informasi yang kurang tepat',
    },
    changePassword: {
      title: 'Ubah password berhasil!',
      message: 'Silakan coba password baru anda di login selanjutnya',
      fail: 'Gagal',
      passwordNotMatch: 'Password tidak cocok',
      passwordIncorrectFormat: 'format password kurang tepat',
      changeSuccess: 'Ubah password berhasil!',
      nextLogin: 'Silakan coba password baru anda di login selanjutnya',
    },
    commentPosted: {
      title: 'Komentar berhasil diposting!',
      message: 'Terima kasih atas Masukkan anda',
    },
    confirmation: {
      title: 'Konfirmasi',
      message: 'Apakah anda ingin menghapus?',
      message_2: 'Apakah anda yakin untuk menghapus?',
      message_2_2: 'proyek ini?',
    },
    warrantyDetail: {
      projectName: 'Nama Proyek',
      address: 'Alamat',
      quantity: 'Jumlah',
      color: 'Warna (Jika Ada)',
    },
    newVersion: {
      title_1: 'Versi Baru TrueBlue',
      title_2: 'telah Tersedia.',
    },
    forgotPassword: {
      titleSendResetLink: 'Link Reset Password Sudah Dikirim',
      messageCheckEmail: 'Silakan cek email anda',
      titleSendOtp: 'Kode OTP Sudah Dikirim',
      messageCheckNotification: 'Silakan Cek SMS Anda',
    },
    resetPassword: {
      title: 'Reset password berhasil!',
      message_1: 'Silakan masukkan password baru anda',
      message_2: 'pada login berikutnya',
    },
    iosAddToHomeScreen: {
      title_1: 'Add to home screen',
      title_2: 'by share',
    },
    socialInputPhone: {
      title: 'Please fill your phone number',
    },
    notiTnc: {
      title_1: 'TrueBlue Services',
      title_2: 'Terms & Condition',
      message_1: 'Before you can proceed you must',
      message_2: 'Read and accept the new',
      message_3: 'Terms & Conditions.',
    },
    otpSend: {
      title: 'OTP has been sent!',
      description: 'Please check your notification',
    },
    authentication: {
      title: 'Authentication link has been sent to your phone (SMS)',
      description1: 'Please approve the access from the link within 3 minutes',
      description2: 'Reference code is',
      description3: 'Do not close this window until the authentication is complete',
      description4: 'or',
      link: 'Re-Send Authentication Link',
    },
  },
  component: {
    component: {
      likes: 'suka',
      comments: 'komentar',
      buttonComment: 'Komentar',
    }
  },
  field: {
    buttonOK: 'OK',
    buttonClose: 'Tutup',
    buttonSearch: 'Pencarian',
    buttonClear: 'Clear',
    buttonSave: 'SIMPAN',
    buttonSaveAndChange: 'Simpan & Ubah',
    buttonSaveAsPhoto: 'Simpan Foto',
    buttonSharePhoto: 'Share Foto',
    buttonSubmit: 'Proses',
    buttonAddNewProject: 'Tambah Proyek Baru',
    buttonEditProject: 'Edit Proyek',
    buttonDeleteProject: 'Hapus Proyek',
    buttonAddPhoto: 'Tambah Foto',
    hintButtonAddPhotoLineOne: 'Select a .jpg, .jpeg, .png file and',
    hintButtonAddPhotoLineTwo: 'maximum upload file size is 1MB.',
    buttonDelete: 'Hapus',
    buttonCancel: 'Cancel',
    buttonFilter: 'Filter',
    buttonAddNewOrderInquiry: 'Tambah Pemesanan baru',
    buttonMoreDetail: 'Lebih Detil',
    buttonUpdate: 'Perbarui',
    buttonSend: 'Kirim',
    buttonContinue: 'Continue',
    buttonContinueRegister: 'Continue Register',
    buttonSignUp: 'Sign up',
    buttonAgree: 'Agree',
    buttonReSend: 'Re-send',
    buttonLogin: 'Login',
    buttonBack: 'Back',
    buttonConfirm: 'Confirm',
    buttonApprove: 'Approve',
    buttonToLogin: 'Back to Login',
    buttonRegisterNow: 'Register Now',
    buttonGoToTrueBlue: 'Go to TrueBlue',
    buttonBlueScopeThailand: 'BlueScope Thailand',
    labelNew: 'Baru',
    labelSample: 'Sampel',
    firstname: 'Nama Depan',
    firstnamePlaceholder: 'Tulis nama depan',
    lastname: 'Nama Belakang',
    lastnamePlaceholder: 'Tulis nama belakang',
    nickname: 'Nick Name',
    nicknamePlaceholder: 'Insert nick name',
    company: 'Company',
    companyPlaceholder: 'Insert company',
    type: 'Tipe',
    typePlaceholder: 'Silakan cari berdasarkan tipe',
    phoneNumber: 'Nomor Telepon',
    mobilePhoneNumber: 'Mobile Phone Number',
    email: 'Email',
    emailPlaceholder: 'Tulis email',
    birthDate: 'Tanggal lahir',
    birthDatePlaceholder: 'Silakan cari dengan tanggal lahir anda',
    address: 'Alamat',
    addressPlaceholder: 'Tulis alamat',
    province: 'Provinsi',
    provincePlaceholder: 'Silakan cari berdasarkan provinsi',
    city: 'Kota',
    cityPlaceholder: 'Silakan cari berdasarkan kota',
    district: 'Kecamatan',
    districtPlaceholder: 'Tulis Kecamatan',
    subDistrict: 'Kelurahan',
    subDistrictPlaceholder: 'Tulis kelurahan',
    subDistrictSelectPlaceholder: 'Please select sub-district',
    alley: 'Jalan',
    alleyPlaceholder: 'Tulis Nama Jalan',
    group: 'Grup',
    groupPlaceholder: 'Tulis grup',
    street: 'Jalan',
    streetPlaceholder: 'Tulis Nama Jalan',
    ward: 'Ward',
    wardPlaceholder: 'Tulis ward',
    postCode: 'Kode pos',
    postCodePlaceholder: 'Tulis kode pos',
    postCodeSelectPlaceholder: 'Please select post code',
    country: 'Negara',
    countryPlaceholder: 'Tulis negara',
    password: 'Password',
    passwordHint: '8 to 24 characters long',
    oldPassword: 'Password Lama',
    newPassword: 'Password Baru',
    newPasswordHint: 'Panjang 8 - 24 karakter',
    confirmPassword: 'Konfirmasi Password',
    roofType: 'Tipe Atap',
    roofTypePlaceholder: 'Cari dengan tipe atap',
    profileType: 'Tipe Profil',
    profileTypePlaceholder: 'Cari dengan tipe profil',
    widthUnit: 'Lebar (m)',
    lengthUnit: 'Panjang (m)',
    heightUnit: 'Tinggi (m)',
    overhangUnit: 'Overstek (mm)',
    title: 'Judul',
    titlePlaceholder: 'Tulis judul',
    comment: 'Komentar',
    commentPlaceholder: 'Tulis Komentar',
    newsAndPromotionsPlaceholder: 'Pencarian berita & promosi',
    projectName: 'Nama Proyek',
    location: 'Lokasi',
    areaSizeProject: 'Luas Bangunan Proyek (m2)',
    product: 'Produk',
    description: 'Deskripsi',
    descriptionPlaceholder: 'Tulis Deskripsi…',
    selectDealerPlaceholder: 'Pilih Toko',
    selectProvincePlaceholder: 'Cari Berdasarkan Provinsi',
    selectAmphoePlaceholder: 'Cari dengan a Khet/Amphoe',
    filterStartDateEndDatePlaceholder: 'Saring Tanggal Awal - Tanggal Akhir',
    startDatePlaceholder: 'Tanggal Awal',
    endDatePlaceholder: 'Tanggal Akhir',
    productGroup: 'Produk',
    coilName: 'Nama Coil',
    thickness: 'Tebal',
    color: 'Warna',
    length: 'Panjang',
    meter: 'meter',
    points: 'Poin',
    points_2: 'poin',
    where: 'Nama Toko',
    status: 'Status',
    trasactionID: 'No. Transaksi',
    selectCategoryPlaceholder: 'Cari dengan kategori',
    subject: 'Subyek',
    subjectPlaceholder: 'Tulis Subyek',
    selectAreaPlaceholder: 'Cari Berdasarkan Wilayah',
    phoneIsIncorrectFormat: 'Phone is incorrect format.',
    agreeTermsAndConditions: 'Agree Terms and conditions',
    questionPlaceholder: 'Insert Answer',
    birthDayPlaceholder: 'Select Birthday',
    customerTypeFixer: 'Mandor / Tukang / Kontraktor',
    customerTypeHardware: 'Toko Bangunan / Toko Besi Baja',
    customerTypeHomeowner: 'Pemilik Rumah / Pemilik Proyek',
    customerTypeRollFormer: 'Roll formers / RF Sales / RF Fixers',
    customerTypeOthers: 'Lainnya (Military, Government)',
    customerTypeArchitect: 'Architect / Designer / Consult',
    customerTypeEngineer: 'Engineer'
  },
  message: {
    fail: 'Gagal',
    pleaseInputPhone: 'Silakan Masukkan no. HP',
    pleaseInputAllField: 'Mohon Isi semua Data yang dibutuhkan',
    pleaseInputAllRequiredField: 'Please insert all required field',
    somethingWrong: 'Proses tidak berhasil, Mohon coba lagi',
    cannotGetYourCurrentLocation: 'Tidak bisa mendeteksi lokasi anda saat ini',
    pleaseSelectStartDateAndEndDate: 'Silakan masukkan tanggal awal dan tanggal akhir',
    invalidDateFormat: 'Format Tanggal Kurang Tepat',
    pleaseSelectCategoryAndDealer: 'Silakan pilih kategori and toko',
    yourOrderInquiryHasBeenSent: 'Pemesanan Anda Telah Terkirim',
    pleaseSpecifySubject: 'Masukkan Judul',
    pleaseSpecifyDescription: 'Masukkan Deskripsi',
    browserCantShare: `Mohon gunakan Google Chrome atau Safari untuk mengaktifkan fitur Share `,
    phoneDoesntExist: 'Nomor telepon ini tidak ada',
    passwordNotMatch: 'Password tidak cocok',
    passwordIncorrectFormat: 'Format Password Kurang Tepat',
    dataNotFound: 'Data not found',
    widthOutOfCondition: 'Width out of range',
    pitchOutOfCondition: 'Pitch out of range',
    invalidEmailFormat: 'Invalid email format',
    pleaseInputFirstnameAndLastname: 'Please insert firstname and lastname',
    phoneInCorrectFormat: 'Phone is incorrect format',
    emailInCorrectFormat: 'Email is incorrect format.',
    nameInCorrectFormat: 'Name is incorrect format.',
    emailAddressAlreadyExists: 'Email address already exists.',
    loginBySocialError: 'Login by social error.',
    cantGetUserInfo: "Can't get user info.",
    phoneEmailAlreadyExists: 'Phone/Email is already exist.',
    phoneAlreadyExists: 'Phone is already exist.',
    sendOtpError: 'Send otp error.',
    getSecretOtpError: 'Get secret otp error.',
    otpIncorrect: 'OTP is incorrect, Please re-send again.',
    pleaseEnterPassword: 'Please Enter Password',
    pleaseEnterPhone: 'Please Enter Phone',
    pleaseEnterPhoneAndPassword: 'Please Enter Phone and Password',
    wrongUsernameOrPassword: 'Wrong username or password.',
    browserNotSupport: `This browser not support please use Chrome or Safari.`,
    phoneNotMatch: "Phone number doesn't match the TrueBlue member system.",
    phoneNotMatch1: 'If you have been a TrueBlue member. Please contact',
    phoneNotMatch2: 'BlueScope Metal Sheet Center',
    phoneNotMatch3: 'or if you are not a member yet.',
    wrongAnswer: 'Wrong answer',
    passwordAndConfirmPasswordNotMatch: "Password and Confirm Password aren’t match.",
  },
  luckyDraw: {
    title: 'Lucky Draw',
    termsAndConditions: 'Terms & Conditions',
    agreeTermsAndConditions: 'Agree terms and conditions',
    dontShowThisMessageAgainNextTime: `Don't show this message again next time`,
    accept: 'Accept',
    winPrizes: 'Win Prizes',
    awardAnnouncement: 'Award Announcement',
    noLuckyDrawEvent: 'No Lucky Draw Event',
    usePointToWinPrizes: 'Use points to win prizes',
    point: 'point',
    right: 'right',
    numberOfUsedPoints: 'Number of used points',
    yourPointIsNotEnough: 'Your points is not enough',
    buttonWinPrizes: 'Win prizes',
    reward: 'Reward',
    viewDetail: 'View detail',
    readTermsAndConditions: 'Read terms and conditions',
    announcement: 'Announcement',
    announcementOfNameWinners: 'Announcement of name winners',
    campaign: 'Campaign',
    dateFrom: 'From',
    dateUntil: 'until',
    showMore: 'Show more',
    awardAnnounced: 'Awards announced!',
    youCanClickBtnBelowToSeeYourListName: 'You can click the button below to see your list name',
    buttonCheckMyName: 'Check my name!',
    expirePoint: 'Expire point',
    toExpire: 'To Expire',
    points: 'points',
    dontWasteYourPoint: `Don't waste your point`,
    useYourPointToExchangeForAChanceToWinPrizes: 'Use your points to exchange for a chance to win prizes',
    buttonGoToWinPrizes: 'Go to win prizes',
    total: 'Total',
    youUseYourRightToWinPrizes: 'You use your right to win prizes',
    goodLuckWithWinnigPrizes: 'Good luck with winning prizes',
    youHave: 'You have',
    pointLeft: 'points left',
    awardCount: 'Number of awards',
    showLess: "Show less",
    rewardTitle: '[quantity] prize',
    campaignDurationDate: 'Start [startDate] until [endDate]',
    campaignLuckyDrawDate: 'Prize draw on',
    announcementRewardDetail: '[rewardDetail] ([quantity] prize)',
    noAwardAnnouncement: 'No Award Announcement',
    youHaveAllThePoint: 'You have all the points :',
    rewardTitleQuantity: 'Quantity [quantity] prize',
    youHaveAMaximumRedeemablePointOfPoint: 'You have a maximum redeemable point of [point] points.',

  },
}