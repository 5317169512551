import _ from "lodash";
import React from 'react';
import {
  connect,
} from 'react-redux';
import { getUserAuth } from "../../helpers/functions/auth";
import { displayWithComma } from "../../helpers/functions/display";
import { getLanguage } from "../../helpers/functions/language";
import { getLuckyDrawExpirePoint } from "../../helpers/services/luckyDraw";
import { getPointUser } from "../../helpers/services/point";
import {
  Grid,
  Image,
  Section,
  Text
} from "./../../components";
import { ICONS, TYPOGRAPHYS, VARIABLES } from "./../../themes";

export class CustomerProfileContainer extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      userAuth: getUserAuth(),
      totalPoint: 0,
      expirePoint: 0,
      expireDate: undefined
    }
  }

  componentDidMount() {
    this.customerProfile.getData();
  }

  customerProfile = {
    getData: async () => {
      await this.customerProfile.getPointSearch();
      await this.customerProfile.getLuckyDrawExpirePoint();
    },
    getPointSearch: async () => {
      const {
        userAuth,
      } = this.state;
      const params = {
        sfid: userAuth.sfid,
      }
      const data = await getPointUser(params);
      this.setState({
        totalPoint: data.pointLeft,
      });
    },
    getLuckyDrawExpirePoint: async () => {
      const { userAuth } = this.state;
      const data = await getLuckyDrawExpirePoint(userAuth.sfid);
      this.setState({
        expirePoint: data[0].point,
        expireDate: data[0].displayExpireDate,
      });
    },

  }

  render() {
    const {
      userAuth,
      totalPoint,
      expirePoint,
      expireDate,
    } = this.state;
    return (
      <Section
        paddingHorizontal="27"
        paddingVertical="20"
        className="is-customer-profile"
      >
        <Grid gutter="35" align="center">
          <Grid.Column flex="none">
            <Image
              circle
              widthRatio="81"
              widthRatioUnit="px"
              heightRatio="81"
              heightRatioUnit="px"
              src={userAuth.profileImage ? userAuth.profileImage : ICONS['ic-placeholder-avatar.svg']}
              borderRadius={55}
            />
          </Grid.Column>
          <Grid.Column flex="1">
            <Section spacingBottom="10">
              <Text
                fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_18}
                color={VARIABLES.COLORS.WHITE}
              >
                {_.get(userAuth, 'firstName', 'TrueBlue')} {_.get(userAuth, 'lastName', '')}
              </Text>
            </Section>
            <Grid gutter="8">
              <Grid.Column>
                <Image
                  widthRatio="16"
                  widthRatioUnit="px"
                  heightRatio="20"
                  heightRatioUnit="px"
                  top="-2"
                  src={ICONS["ic-medal.svg"]}
                />
              </Grid.Column>
              <Grid.Column>
                <Text
                  top="-5"
                  fontStyle={TYPOGRAPHYS.FIRST.MEDIUM.FS_20}
                  color={VARIABLES.COLORS.WHITE}
                >
                  {displayWithComma(totalPoint)}
                </Text>
              </Grid.Column>
              <Grid.Column>
                <Text
                  top="-5"
                  fontStyle={TYPOGRAPHYS.FIRST.MEDIUM.FS_20}
                  color={VARIABLES.COLORS.WHITE}
                >
                  {getLanguage("home.point")}
                </Text>
              </Grid.Column>
            </Grid>
            <Grid>
              <Grid.Column>
                <Section paddingLeft="3" paddingRight="5">
                  <Image
                    widthRatio="9"
                    widthRatioUnit="px"
                    heightRatio="11"
                    heightRatioUnit="px"
                    src={ICONS["ic-medal-light-blue.svg"]}
                  />
                </Section>
              </Grid.Column>
              <Grid.Column>
                <Section paddingRight="8">
                  <Text
                    fontStyle={TYPOGRAPHYS.FIRST.BOLD.FS_10}
                    color={VARIABLES.COLORS.LIGHT_BLUE_3}
                  >
                    {displayWithComma(expirePoint)} {getLanguage("home.willExpireOn")}
                  </Text>
                </Section>
              </Grid.Column>
              {expireDate && (
                <Grid.Column>
                  <Text
                    fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_10}
                    color={VARIABLES.COLORS.LIGHT_BLUE_3}
                  >
                    {expireDate}
                  </Text>
                </Grid.Column>
              )}
            </Grid>
          </Grid.Column>
        </Grid>
      </Section>
    )
  }
}

const mapStateToProps = (state) => {
  return {
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
  }
}

export const CustomerProfileContainerConnected = connect(
  mapStateToProps,
  mapDispatchToProps,
  null,
  { forwardRef: true }
)(CustomerProfileContainer)