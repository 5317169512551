export class ModelWarranty {
  constructor(props) {
    props = props || {};
    this.created_date = props.created_date;
    this.wrt_no = props.wrt_no;
    this.coil_grp = props.coil_grp;
    this.name = props.name;
    this.project_name = props.project_name;
    this.project_addr = props.project_addr;
    this.project_phone = props.project_phone;
    this.qty = props.qty;
    this.ad_name = props.ad_name;
    this.sales_trans_id = props.sales_trans_id;
    this.wrt_status = props.wrt_status;
    this.detail = (props.detail || []).map((e) => {
      return new ModelWarrantyDetail(e);
    });
  }
}

export class ModelWarrantyDetail {
  constructor(props) {
    props = props || {};
    this.order_no = props.order_no;
    this.order_date = props.order_date;
    this.coil_grp = props.coil_grp;
    this.coil_id = props.coil_id;
    this.coil_name = props.coil_name;
    this.coil_order_m = props.coil_order_m;
    this.roof = props.roof;
    this.purlin_girt = props.purlin_girt;
    this.tru_frame = props.tru_frame;
    this.cool_room = props.cool_room;
    this.decking = props.decking;
    this.thickness = props.thickness;
    this.length = props.length;
    this.wrt_path = props.wrt_path;
    this.color = props.color;
  }
}
