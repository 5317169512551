import React from 'react'
import {
  ROUTE_PATH,
  redirect,
} from './../../helpers'
import {
  WarrantyProductItem,
} from './WarrantyProductItem'

export class WarrantyProductList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openIndex: null,
    }
  }

  openDetail(index) {
    if (this.state.openIndex === index) {
      this.setState({ openIndex: null })
    } else {
      this.setState({ openIndex: index })
    }
  }

  handleBack() {
    redirect(ROUTE_PATH.WARRANTY.LINK)
  }

  render() {
    const { openIndex } = this.state
    const { list, id } = this.props
    return (
      list.map((data, index) => (
        <WarrantyProductItem
          key={index}
          index={index}
          data={data}
          id={id}
          openDetail={openIndex === index}
          onOpenDetail={this.openDetail.bind(this)} />
      ))
    )
  }
}

