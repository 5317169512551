import React from 'react'
import ClassNames from 'classnames'
import PropTypes from 'prop-types'
import Select from 'react-select'
import CreatableSelect from 'react-select/creatable'
import {
  SelectControlWrapper
} from './styled'
import {
  default as VARIABLES
} from './../../../themes/styles/bases/variables'
import {
  default as TYPOGRAPHYS
} from './../../../themes/styles/bases/typographys'

/**
 * SelectControl description:
 * - SelectControl
 */

export class SelectControl extends React.PureComponent {
  render() {
    const {
      className,
      label,
      labelFontStyle,
      labelWidth,
      labelSpacingTop,
      labelSpacingRight,
      labelFontColor,
      labelAlign,
      error,
      errorColor,
      success,
      successColor,
      disabled,
      disabledColor,
      message,
      messageFontStyle,
      messageSpacingTop,
      messageSpacingLeft,
      messageFontColor,
      messageAlign,
      noArrow,
      width,
      stackLabel,
      verticalLabel,
      required,
      filter,
      filterLarge,
      filterLargePlaceholderShort,
      filterLargePlaceholderLong,
      filterFluid,
      filterBorder,
      orderInquirySubmitted,
      orderInquiryAcknownledged,
      idPhone,
      idPhoneGlobal,
      isCreatable,
    } = this.props

    // props for css classes
    const classes = ClassNames(
      'select-control',
      { 'is-error': error },
      { 'is-success': success },
      { 'is-disabled': disabled },
      { 'is-no-arrow': noArrow },
      { 'is-stack-label': stackLabel },
      { 'is-vertical-label': verticalLabel },
      { 'is-required': required },
      { 'is-filter': filter },
      { 'is-filter-large': filterLarge },
      { 'is-filter-large-placeholder-short': filterLargePlaceholderShort },
      { 'is-filter-large-placeholder-long': filterLargePlaceholderLong },
      { 'is-filter-fluid': filterFluid },
      { 'is-filter-border': filterBorder },
      { 'is-order-inquiry-submitted': orderInquirySubmitted },
      { 'is-order-inquiry-acknownledged': orderInquiryAcknownledged },
      { 'is-id-phone': idPhone },
      { 'is-id-phone-global': idPhoneGlobal },
      className
    )

    return (
      <SelectControlWrapper
        className={classes}
        theme={{
          labelFontStyle,
          labelWidth,
          labelSpacingTop,
          labelSpacingRight,
          labelFontColor,
          labelAlign,
          errorColor,
          successColor,
          disabledColor,
          messageFontStyle,
          messageSpacingTop,
          messageSpacingLeft,
          messageFontColor,
          messageAlign,
          width
        }}
      >
        {label &&
          <div className='select-control-label'>
            {label}
          </div>
        }
        <div className='select-control-select'>
          {isCreatable ?
            <CreatableSelect
              formatCreateLabel={(optionText) => {
                return `Add "${optionText}" to the list`;
              }}
              {...this.props}
            />
            :
            <Select
              {...this.props}
            />
          }
          {message &&
            <div className='select-control-message'>
              {message}
            </div>
          }
        </div>
      </SelectControlWrapper>
    )
  }

  static defaultProps = {
    // * If you set new default props, you should create propsType for correct styleguide document.
    labelFontStyle: TYPOGRAPHYS.FIRST.REGULAR.FS_14,
    labelFontColor: VARIABLES.COLORS.GRAY_19,
    // messageFontStyle: TYPOGRAPHYS.FIRST.REGULAR.FS_9,
    // messageSpacingTop: 8,
    // messageSpacingLeft: 8,
    errorColor: VARIABLES.COLORS.ERROR,
    successColor: VARIABLES.COLORS.SUCCESS,
    disabledColor: VARIABLES.COLORS.DISABLED,
    className: 'react-select',
    classNamePrefix: 'react-select',
    theme: {
      colors: {
        primary: 'transparent',  // bg color option selected
        primary25: 'transparent' // bg color option hover
      }
    }
  }

  static propTypes = { // TYPE > node, string, number, bool, array, object, symbol, func
    /**
    * Additional classes
    */
    className: PropTypes.string
  }
}
