import styled, {
  css
} from 'styled-components'
// import {
//   default as VARIABLES
// } from './../../../themes/styles/bases/variables'
// import {
//   default as TYPOGRAPHYS
// } from './../../../themes/styles/bases/typographys'
// import {
//   default as MIXINS
// } from './../../../themes/styles/helpers/mixins'

// Sample using image in style
// url(${require('./images/icons/icon-sample.svg')});
// url(${require('./../../../themes/images/icons/icon-sample.svg')});

// Wrapper
// ============================================================
export const TemplateBaseWrapper = styled.div`
  /* Parent
  ------------------------------- */
  ${props => {
    const theme = props.theme
    return css`
      /* xxxxx: ${theme.xxxxx}; */
    `
  }}

  /* Childrens
  ------------------------------- */
  /* Modifiers
  ------------------------------- */
  /* Media queries
  ------------------------------- */
`
