import React from 'react'
import ClassNames from 'classnames'
// import PropTypes from 'prop-types'
import {
  ModalBase
} from './../../base'
import {
  Section,
  Image,
  Text,
  Button,
} from './../../../components'
import {
  VARIABLES,
  TYPOGRAPHYS,
  ICONS
} from './../../../themes'
import { getLanguage } from '../../../helpers/functions/language'

/**
 * Modal description:
 * - Modal
 */

export class Modal extends React.PureComponent {
  render () {
    const {
      className,
      ui,
      title,
      description,
      onClickSubmit,
      buttonSubmitName,
      onClickCancel,
      buttonCancelName,
      iconWidth,
      iconHeight,
      iconSrc,
      content,
      isNoIcon,
    } = this.props

    // props for css classes
    const classes = ClassNames(
      className,
      // { 'is-modifier-name': modifierName }
    )

    return (
      <React.Fragment>
        {(() => {
          switch (ui) {
            case 'confirm':
              return (
                <ModalBase
                  {...this.props}
                  className={classes}
                  width={VARIABLES.MODAL.WIDTHS.W_300}
                  overlayColor={VARIABLES.COLORS.OVERLAY_3}
                  spacing={VARIABLES.MODAL.CONTAINER.C_1.SPACING}
                  transition={VARIABLES.TRANSITIONS.DEFAULT}
                  transformStart={VARIABLES.MODAL.CONTAINER.C_1.TRANSFORM_START}
                  transformEnd={VARIABLES.MODAL.CONTAINER.C_1.TRANSFORM_END}
                >
                  <ModalBase.Section
                    paddingTop={VARIABLES.MODAL.HEADER.H_1.PADDING_TOP}
                    paddingBottom={VARIABLES.MODAL.HEADER.H_1.PADDING_BOTTOM}
                    paddingHorizontal={VARIABLES.MODAL.HEADER.H_1.PADDING_HORIZONTAL}
                    borderTopRightRadius={VARIABLES.MODAL.HEADER.H_1.BORDER_TOP_RIGHT_RADIUS}
                    borderTopLeftRadius={VARIABLES.MODAL.HEADER.H_1.BORDER_TOP_LEFT_RADIUS}
                  >
                    <Section justify='center'>
                      <Image
                        widthRatio={iconWidth}
                        widthRatioUnit='px'
                        heightRatio={iconHeight}
                        heightRatioUnit='px'
                        src={iconSrc}
                      />
                    </Section>
                  </ModalBase.Section>
                  <ModalBase.Section
                    paddingBottom='30'
                    paddingHorizontal={VARIABLES.MODAL.BODY.B_1.PADDING_HORIZONTAL}
                  >
                    <Section
                      spacingBottom={description ? '12' : '0'}
                      justify='center'
                    >
                      <Text
                        fontStyle={TYPOGRAPHYS.FIRST.BOLD.FS_18}
                        color={VARIABLES.COLORS.BLACK}
                        align='center'
                      >
                        {title}
                      </Text>
                    </Section>
                    {
                      description &&
                      <Section
                        justify='center'
                      >
                        <Text
                          fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_15}
                          color={VARIABLES.COLORS.GRAY_6}
                          align='center'
                        >
                          {description}
                        </Text>
                      </Section>
                    }
                  </ModalBase.Section>
                  <ModalBase.Section
                    paddingBottom={10}
                    paddingHorizontal={VARIABLES.MODAL.FOOTER.F_2.PADDING_HORIZONTAL}
                  >
                    <Button
                      fluid
                      shadow
                      className='is-gradient-blue'
                      height={VARIABLES.BUTTON.HEIGHTS.H_35}
                      borderRadius={VARIABLES.BUTTON.BORDER_RADIUSES.BR_10}
                      onClick={onClickSubmit}
                    >
                      <Text
                        fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_16}
                        color={VARIABLES.COLORS.WHITE}
                      >
                        {buttonSubmitName}
                      </Text>
                    </Button>
                  </ModalBase.Section>
                  <ModalBase.Section
                    paddingBottom={VARIABLES.MODAL.FOOTER.F_2.PADDING_BOTTOM}
                    paddingHorizontal={VARIABLES.MODAL.FOOTER.F_2.PADDING_HORIZONTAL}
                    borderBottomRightRadius={VARIABLES.MODAL.FOOTER.F_2.BORDER_BOTTOM_RIGHT_RADIUS}
                    borderBottomLeftRadius={VARIABLES.MODAL.FOOTER.F_2.BORDER_BOTTOM_LEFT_RADIUS}
                  >
                    <Button
                      fluid
                      shadow
                      bgColor={VARIABLES.COLORS.TRANSPARENT}
                      borderWidth='1'
                      borderStyle='solid'
                      borderColor={VARIABLES.COLORS.BLUE_2}
                      height={VARIABLES.BUTTON.HEIGHTS.H_35}
                      borderRadius={VARIABLES.BUTTON.BORDER_RADIUSES.BR_10}
                      onClick={onClickCancel}
                    >
                      <Text
                        fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_16}
                        color={VARIABLES.COLORS.BLUE_2}
                      >
                        {buttonCancelName}
                      </Text>
                    </Button>
                  </ModalBase.Section>
                </ModalBase>
              )
            case 'notice':
              return (
                <ModalBase
                  {...this.props}
                  className={classes}
                  width={VARIABLES.MODAL.WIDTHS.W_300}
                  overlayColor={VARIABLES.COLORS.OVERLAY_3}
                  spacing={VARIABLES.MODAL.CONTAINER.C_1.SPACING}
                  transition={VARIABLES.TRANSITIONS.DEFAULT}
                  transformStart={VARIABLES.MODAL.CONTAINER.C_1.TRANSFORM_START}
                  transformEnd={VARIABLES.MODAL.CONTAINER.C_1.TRANSFORM_END}
                >
                  <ModalBase.Section
                    paddingTop={isNoIcon ? '0' : VARIABLES.MODAL.HEADER.H_1.PADDING_TOP}
                    paddingBottom={VARIABLES.MODAL.HEADER.H_1.PADDING_BOTTOM}
                    paddingHorizontal={VARIABLES.MODAL.HEADER.H_1.PADDING_HORIZONTAL}
                    borderTopRightRadius={VARIABLES.MODAL.HEADER.H_1.BORDER_TOP_RIGHT_RADIUS}
                    borderTopLeftRadius={VARIABLES.MODAL.HEADER.H_1.BORDER_TOP_LEFT_RADIUS}
                  >
                    {
                      isNoIcon ?
                      undefined
                      :
                      <Section justify='center'>
                        <Image
                          widthRatio={iconWidth}
                          widthRatioUnit='px'
                          heightRatio={iconHeight}
                          heightRatioUnit='px'
                          src={iconSrc}
                        />
                      </Section>
                    }
                  </ModalBase.Section>
                  <ModalBase.Section
                    paddingBottom='30'
                    paddingHorizontal={VARIABLES.MODAL.BODY.B_1.PADDING_HORIZONTAL}
                  >
                    <Section
                      spacingBottom={(description || content) ? '12' : '0'}
                      justify='center'
                    >
                      <Text
                        fontStyle={TYPOGRAPHYS.FIRST.BOLD.FS_18}
                        color={VARIABLES.COLORS.BLACK}
                        align='center'
                      >
                        {title}
                      </Text>
                    </Section>
                    {
                      description &&
                      <Section
                        justify='center'
                      >
                        <Text
                          fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_15}
                          color={VARIABLES.COLORS.GRAY_6}
                          align='center'
                        >
                          {description}
                        </Text>
                      </Section>
                    }
                    {
                      content &&
                      <React.Fragment>
                        {content}
                      </React.Fragment>
                    }
                  </ModalBase.Section>
                  <ModalBase.Section
                    paddingBottom={VARIABLES.MODAL.FOOTER.F_2.PADDING_BOTTOM}
                    paddingHorizontal={VARIABLES.MODAL.FOOTER.F_2.PADDING_HORIZONTAL}
                    borderBottomRightRadius={VARIABLES.MODAL.FOOTER.F_2.BORDER_BOTTOM_RIGHT_RADIUS}
                    borderBottomLeftRadius={VARIABLES.MODAL.FOOTER.F_2.BORDER_BOTTOM_LEFT_RADIUS}
                  >
                    <Button
                      fluid
                      shadow
                      className='is-gradient-blue'
                      height={VARIABLES.BUTTON.HEIGHTS.H_35}
                      borderRadius={VARIABLES.BUTTON.BORDER_RADIUSES.BR_10}
                      onClick={onClickSubmit}
                    >
                      <Text
                        fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_16}
                        color={VARIABLES.COLORS.WHITE}
                      >
                        {buttonSubmitName}
                      </Text>
                    </Button>
                  </ModalBase.Section>
                </ModalBase>
              )
            default:
              return (
                <ModalBase
                  {...this.props}
                  className={classes}
                />
              )
          }
        })()}
      </React.Fragment>
    )
  }

  static Section = ModalBase.Section

  static defaultProps = {
    iconWidth: '50',
    iconHeight: '50',
    iconSrc: ICONS['ic-warning.svg'],
    buttonSubmitName: getLanguage('field.buttonSubmit'),
    buttonCancelName: getLanguage('field.buttonCancel'),
  }

  static propTypes = {
    // TYPE > node, string, number, bool, array, object, symbol, func
    /**
    * props_name_oneType
    */
    // props_name_onetype: PropTypes.string,

    /**
    * props_name_oneMoreType
    */
    // props_name_oneMoreType: PropTypes.oneOfType([
    //   PropTypes.node,
    //   PropTypes.string
    // ])
  }
}
