import React from 'react'
import ClassNames from 'classnames'
import PropTypes from 'prop-types'
import {
  ModalBaseWrapper,
  ModalSectionBaseWrapper,
  ModalOverlayBaseWrapper
} from './styled'
import {
  default as VARIABLES
} from './../../../themes/styles/bases/variables'
// import {
//   default as TYPOGRAPHYS
// } from './../../../themes/styles/bases/typographys'

/**
 * ModalBase description:
 * - base component Modal
 */

class ModalSectionBase extends React.PureComponent {
  render () {
    const {
      // Option
      children,
      className,
      // Default
      padding,
      paddingTop,
      paddingRight,
      paddingBottom,
      paddingLeft,
      paddingHorizontal,
      paddingVertical,
      bgColor,
      borderTopWidth,
      borderTopStyle,
      borderTopColor,
      borderBottomWidth,
      borderBottomStyle,
      borderBottomColor,
      borderTopRightRadius,
      borderTopLeftRadius,
      borderBottomRightRadius,
      borderBottomLeftRadius,
      borderRadius,
      maxHeight,
      overflow,
    } = this.props

    return (
      <ModalSectionBaseWrapper
        className={ClassNames(
          'modal-section-base',
          // Option
          className
        )}
        theme={{
          // Default
          padding,
          paddingTop,
          paddingRight,
          paddingBottom,
          paddingLeft,
          paddingHorizontal,
          paddingVertical,
          bgColor,
          borderTopWidth,
          borderTopStyle,
          borderTopColor,
          borderBottomWidth,
          borderBottomStyle,
          borderBottomColor,
          borderTopRightRadius,
          borderTopLeftRadius,
          borderBottomRightRadius,
          borderBottomLeftRadius,
          borderRadius,
          maxHeight,
          overflow,
        }}
      >
        {children}
      </ModalSectionBaseWrapper>
    )
  }

  static defaultProps = {
    // Option
    // children: undefined,
    // className: undefined,
    // Default
    bgColor: VARIABLES.COLORS.WHITE
  }

  static propTypes = { // TYPE > node, string, number, bool, array, object, symbol, func
    /**
    * Additional classes
    */
    className: PropTypes.string,

    /**
    * Additional Text, HTML, Component
    */
    children: PropTypes.oneOfType([
      PropTypes.node,
      PropTypes.string
    ])
  }
}

export class ModalBase extends React.PureComponent {
  render () {
    const {
      // Option
      children,
      className,
      // Default
      open,
      transition,
      transformStart,
      transformEnd,
      width,
      spacing,
      spacingTop,
      spacingRight,
      spacingBottom,
      spacingLeft,
      spacingHorizontal,
      spacingVertical,
      overlayColor,
      onClickClose,
      buttonClose,
      buttonCloseSpacingTop,
      buttonCloseSpacingRight,
      enableOverlayClose
    } = this.props

    return (
      <React.Fragment>
        <ModalBaseWrapper
          className={ClassNames(
            'modal-base',
            {'is-open': open},
            // Option
            className
          )}
          theme={{
            // Default
            transition,
            transformStart,
            transformEnd,
            width,
            spacing,
            spacingTop,
            spacingRight,
            spacingBottom,
            spacingLeft,
            spacingHorizontal,
            spacingVertical,
            buttonCloseSpacingTop,
            buttonCloseSpacingRight
          }}
        >
          <div className='modal-container-base'
            theme={{
              transition
            }}
          >
            <div className='modal-button-close-base'
              onClick={onClickClose}
            >
              {buttonClose}
            </div>
            {children}
          </div>
          {/* Use 'onClickClose' props instead 'modal-overlay' because it is under 'modal' */}
          { enableOverlayClose &&
              <div className='modal-overlay-clickable-area-base'
                onClick={onClickClose}
              />
          }
        </ModalBaseWrapper>
        {/* Separate out from 'modal' becuase z-index bug in ios webview */}
        <ModalOverlayBaseWrapper className={ClassNames(
            'modal-overlay-base',
            {'is-open': open}
          )}
          theme={{
            transition,
            overlayColor
          }}
        />
      </React.Fragment>
    )
  }

  static Section = ModalSectionBase

  static defaultProps = {
    // Option
    // children: undefined,
    // className: undefined,
    // Default
    // transition: ,
    // transformStart: ,
    // transformEnd: ,
    // width: ,
    // spacing: ,
    // spacingTop: ,
    // spacingRight: ,
    // spacingBottom: ,
    // spacingLeft: ,
    // spacingHorizontal: ,
    // spacingVertical: ,
    // overlayColor: ,
    // buttonCloseSpacingTop: ,
    // buttonCloseSpacingRight: ,
    enableOverlayClose: true
  }

  static propTypes = { // TYPE > node, string, number, bool, array, object, symbol, func
    /**
    * Additional classes
    */
    className: PropTypes.string,

    /**
    * Additional Text, HTML, Component
    */
    children: PropTypes.oneOfType([
      PropTypes.node,
      PropTypes.string
    ])
  }
}
