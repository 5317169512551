import { getAuthSuccess, getUserAuth } from '../functions/auth';
import { handleQueryFirebase, handleUpdateFirebase, handleAddFirebase, handleQueryDocFirebase } from './firebaseManageData';
import { storeDispatch, storeGetState } from '../../store';
import { dataCreators, DATA_REDUX_STATE_KEY } from '../../actions/dataAction';
import { ModelUserNoti } from '../../models/ModelUserNoti';
import { ModelAdNoti } from '../../models/ModelAdNoti';

let qSnapNotiUnsub = () => { };

export const firebaseUnsubscribeNoti = () => {
  qSnapNotiUnsub();
}

export const firebaseUpdateOrderIsNew = (isNew = false) => {
  return new Promise(async (resolve, reject) => {
    try {
      const dataNoti = storeGetState().dataRedux.firebaseNoti;
      await handleUpdateFirebase('notification', dataNoti.firebaseKey, {
        'order_inquiry.is_new': isNew
      })
      resolve();
    }
    catch (err) {
      reject(err);
      console.log('err', { err });
    }
  });
}

export const firebaseSendOrderInquiry = (params = {}) => {
  return new Promise(async (resolve, reject) => {
    try {
      const adId = params.adId;
      const where = ['ad_id', '==', adId]
      const qSnapNoti = await handleQueryFirebase('notification', {
        where
      });
      if (qSnapNoti.size === 0) {
        await handleAddFirebase('notification', new ModelAdNoti({
          adId
        }).firebase())
      }
      else {
        const qSnap = qSnapNoti.docs[0];
        await handleUpdateFirebase('notification', qSnap.id, {
          'order_inquiry.is_new': true
        })
      }
      resolve();
    }
    catch (err) {
      reject(err);
      console.log('err', { err });
    }
  });
}

export const handleWatchNoti = () => {
  return new Promise(async (resolve, reject) => {
    try {
      let userAuth = getUserAuth();
      const isOtpAuthSuccess = getAuthSuccess();
      if (userAuth && isOtpAuthSuccess) {
        firebaseUnsubscribeNoti();
        let where = ['user_id', '==', userAuth.id];
        qSnapNotiUnsub = await handleQueryFirebase('notification', {
          where,
          callType: 'onSnapshot',
          callbackSuccess: async (qSnapNoti) => {
            if (qSnapNoti.size === 0) {
              await handleAddFirebase('notification', new ModelUserNoti({
                userId: userAuth.id
              }).firebase());
            }
            else {
              const qSnap = qSnapNoti.docs[0];
              let dataNoti = qSnap.data();
              dataNoti.firebaseKey = qSnap.id;
              dataNoti.firebasePath = qSnap.ref.parent.path;
              dataNoti = new ModelUserNoti(dataNoti).data();
              storeDispatch(dataCreators.setDataRedux(DATA_REDUX_STATE_KEY.FIREBASE_NOTI, dataNoti));
            }
            resolve();
          }
        });
      }
      else {
        resolve()
      }
    }
    catch (err) {
      reject(err);
      console.log('err', { err });
    }
  });
}