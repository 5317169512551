import React from 'react'
import axios from 'axios'
import {
  TransitionContainer,
  Container,
  SwiperCarousel,
  Image,
  Section,
  Text,
  Modal,
  Button
} from '../../components'
import {
  VARIABLES,
  TYPOGRAPHYS,
  CONTENTS,
  ICONS,
} from '../../themes'
import { redirect, ROUTE_PATH } from '../../helpers';
import { LOCAL_STORAGE } from '../../helpers/constants/localStorage';
import { getLanguage } from '../../helpers/functions/language';
import { getUserAuth } from '../../helpers/functions/auth';
import { ENUM } from '../../helpers/constants/enum';
import ReactHtmlParser from 'react-html-parser';
import { onOpenAlert } from '../../helpers/functions/main';
import { DATA } from '../../helpers/constants/data';

export class ProjectDetailPage extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      id: (this.props.match.params && this.props.match.params.id) ? this.props.match.params.id : '',
      userId: JSON.parse(localStorage.getItem(LOCAL_STORAGE.REGISTER_INFO)) ? JSON.parse(localStorage.getItem(LOCAL_STORAGE.REGISTER_INFO)).id : '',
      showModal: false,
      data: {
        name: '',
        location: '',
        area_size: '',
        product: '',
        description: '',
        image_url: '',
      },
    }
  }


  componentWillMount() {
    const data = JSON.parse(localStorage.getItem('project')) || this.state.data;
    console.log(this.state.userId)
    // const user = JSON.parse(localStorage.getItem(LOCAL_STORAGE.REGISTER_INFO))
    if (data && data.length) {
      const result = data.find(d => d.id === +this.state.id);
      if (result) {
        this.setState({
          data: result,
          isSample: result.type === ENUM.PROJECT_TYPE.REFERENCE,
        })
      }
    }

  }

  componentDidMount() {
    console.log('state', this.state);
  }

  async onDelete() {
    this.setState({ showModal: true });
  }

  modal = {
    onClickCancel: () => {
      this.setState({
        showModal: false
      })
    },
    onCilckDelete: async () => {
      this.setState({
        showModal: false
      })
      await axios.delete(`/api/projects/${this.state.id}`).then(async (res) => {
        if (res.status === 200) {
          await setTimeout(redirect(`${ROUTE_PATH.MY_PROJECTS.LINK}/${this.state.userId}`), 2000);
        } else {
          alert(getLanguage('message.somethingWrong'))
        }
      });
    }
  }

  onShareLink() {
    const {
      id,
    } = this.state;
    let userAuth = getUserAuth();
    const shareUrl = `${window.location.origin}${ROUTE_PATH.PUBLIC_MY_PROJECT.LINK}/${userAuth.id}/${id}`;
    console.log('shareUrl', shareUrl)
    if (navigator.share) {
      navigator.share({
        // url: window.location.href
        url: shareUrl
      })
    }
    else{
      onOpenAlert({
        type: DATA.ALERT_TYPE.ERROR,
        title: getLanguage('message.fail'),
        message: getLanguage('message.browserCantShare'),
      });
    }
  }

  render() {
    const {
      data,
      showModal,
      isSample,
      userId
    } = this.state;
    console.log('data', data)
    console.log('isSample', isSample)
    return (
      <TransitionContainer
      // motion='overlap-from'
      >
        <Container
          width={VARIABLES.WEBSITE_WIDTH}
          calcMinHeight='100vh'
          bgColor={VARIABLES.COLORS.WHITE}
        >
          <Container
            bgColor={VARIABLES.COLORS.WHITE}
            className='is-content-detail'
          >
            <Section relative>
              <Button
                className='is-icon is-content-detail-back'
                width={35}
                height={35}
                borderRadius={35}
                bgColor={'#ffffffb8'}
                onClick={() => redirect(`${ROUTE_PATH.MY_PROJECTS.LINK}/${this.state.userId}`)}
              >
                <Button.Icon>
                  <Image
                    widthRatio='11'
                    widthRatioUnit='px'
                    heightRatio='20'
                    heightRatioUnit='px'
                    left='-1'
                    src={ICONS['ic-left-black.svg']}
                  />
                </Button.Icon>
              </Button>
              {
                (this.state.data.image_url && this.state.data.image_url.length) ? (<SwiperCarousel>{(this.state.data.image_url || []).map((img) => (
                  <SwiperCarousel.Item>
                    <Image
                      widthRatio='100'
                      heightRatio='350'
                      heightRatioUnit='px'
                      src={img}
                    />
                  </SwiperCarousel.Item>
                ))}</SwiperCarousel>) : (<SwiperCarousel><SwiperCarousel.Item>
                  <Image
                    widthRatio='100'
                    heightRatio='350'
                    heightRatioUnit='px'
                    src={CONTENTS['splash-screen-1.jpg']}
                  />
                </SwiperCarousel.Item>
                  <SwiperCarousel.Item>
                    <Image
                      widthRatio='100'
                      heightRatio='350'
                      heightRatioUnit='px'
                      src={CONTENTS['splash-screen-2.jpg']}
                    />
                  </SwiperCarousel.Item></SwiperCarousel>)
              }
            </Section>
            <Section
              paddingHorizontal='20'
              paddingTop='20'
              paddingBottom='127'
              className='is-content-detail'
              bgColor={VARIABLES.COLORS.WHITE}
            >
              <Section
                paddingBottom='20'
              >
                {/* <Button
                  className='is-icon is-content-detail-del'
                  width={38}
                  height={38}
                  borderRadius={19}
                  bgColor={'#FA295C'}
                  onClick={this.onDelete.bind(this)}
                >
                  <Button.Icon>
                    <Image
                      widthRatio='17'
                      widthRatioUnit='px'
                      heightRatio='19'
                      heightRatioUnit='px'
                      src={ICONS['ic-del.svg']}
                    />
                  </Button.Icon>
                </Button> */}
                {/* {
                  !isSample &&
                  <Button
                    className='is-icon is-content-detail-share'
                    width={38}
                    height={38}
                    borderRadius={19}
                    bgColor={VARIABLES.COLORS.BLUE_2}
                    onClick={this.onShareLink.bind(this)}
                  >
                    <Button.Icon>
                      <Image
                        widthRatio='17'
                        widthRatioUnit='px'
                        heightRatio='19'
                        heightRatioUnit='px'
                        src={ICONS['ic-share.svg']}
                      />
                    </Button.Icon>
                  </Button>
                } */}
                <Text
                  fontStyle={TYPOGRAPHYS.FIRST.BOLD.FS_22}
                  color={VARIABLES.COLORS.BLACK}
                >
                  {data.name}
                </Text>
              </Section>
              <Section
                paddingBottom='5'
              >
                <Text
                  fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_12}
                  color={VARIABLES.COLORS.GRAY_2}
                >
                  {getLanguage('field.location')}
                </Text>
              </Section>
              <Section
                spacingBottom='10'
                paddingBottom='15'
                borderBottomColor={VARIABLES.COLORS.GRAY_1}
                borderBottomWidth='1'
                borderBottomStyle='solid'
              >
                <Text
                  fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_14}
                  color={VARIABLES.COLORS.BLACK}
                >
                  {data.location}
                </Text>
              </Section>
              <Section
                paddingBottom='5'
              >
                <Text
                  fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_12}
                  color={VARIABLES.COLORS.GRAY_2}
                >
                  {getLanguage('field.areaSizeProject')}
                </Text>
              </Section>
              <Section
                spacingBottom='10'
                paddingBottom='15'
                borderBottomColor={VARIABLES.COLORS.GRAY_1}
                borderBottomWidth='1'
                borderBottomStyle='solid'
              >
                <Text
                  fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_14}
                  color={VARIABLES.COLORS.BLACK}
                >
                  {data.area_size}
                </Text>
              </Section>
              <Section
                paddingBottom='5'
              >
                <Text
                  fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_12}
                  color={VARIABLES.COLORS.GRAY_2}
                >
                  {getLanguage('field.product')}
                </Text>
              </Section>
              <Section
                spacingBottom='10'
                paddingBottom='15'
                borderBottomColor={VARIABLES.COLORS.GRAY_1}
                borderBottomWidth='1'
                borderBottomStyle='solid'
              >
                <Text
                  fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_14}
                  color={VARIABLES.COLORS.BLACK}
                >
                  {data.product}
                </Text>
              </Section>
              <Section
                paddingBottom='5'
              >
                <Text
                  fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_12}
                  color={VARIABLES.COLORS.GRAY_2}
                >
                  {getLanguage('field.description')}
                </Text>
              </Section>
              <Section
                className='text-editor-content is-my-project-description'
                spacingBottom='90'
                paddingBottom='15'
              >
                {ReactHtmlParser(data.description)}
              </Section>
            </Section>
            <Section
              className='is-floating-footer-button'
              justify='center'
              align='flex-end'
            >
              <Button
                shadow
                className='is-gradient-blue is-icon-front'
                width='275'
                height={VARIABLES.BUTTON.HEIGHTS.H_40}
                borderRadius={VARIABLES.BUTTON.BORDER_RADIUSES.BR_10}
                onClick={() => {
                  isSample ?
                    redirect(`/projects/${userId}/add`)
                    :
                    redirect(`${ROUTE_PATH.PROJECT_EDIT.LINK}/${data.id}`)
                }}
              >
                <Button.Icon>
                  <Image
                    widthRatio='16'
                    widthRatioUnit='px'
                    heightRatio='16'
                    heightRatioUnit='px'
                    top='-2'
                    // icon add project
                    // src={ICONS['ic-plus-circle.png']}
                    src={ICONS['ic-edit.png']}
                  />
                </Button.Icon>
                <Text
                  fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_16}
                  color={VARIABLES.COLORS.WHITE}
                >
                  {isSample ? getLanguage('field.buttonAddNewProject') : getLanguage('field.buttonEditProject')}
                </Text>
              </Button>
            </Section>
          </Container>
        </Container>
        <Modal
          open={showModal}
          width={VARIABLES.MODAL.WIDTHS.W_300}
          overlayColor={VARIABLES.COLORS.OVERLAY_3}
          spacing={VARIABLES.MODAL.CONTAINER.C_1.SPACING}
          transition={VARIABLES.TRANSITIONS.DEFAULT}
          transformStart={VARIABLES.MODAL.CONTAINER.C_1.TRANSFORM_START}
          transformEnd={VARIABLES.MODAL.CONTAINER.C_1.TRANSFORM_END}
          onClickClose={this.modal.onClickCloseModalSuccess}
        >
          <Modal.Section
            paddingTop={VARIABLES.MODAL.HEADER.H_1.PADDING_TOP}
            paddingBottom={VARIABLES.MODAL.HEADER.H_1.PADDING_BOTTOM}
            paddingHorizontal={VARIABLES.MODAL.HEADER.H_1.PADDING_HORIZONTAL}
            borderTopRightRadius={VARIABLES.MODAL.HEADER.H_1.BORDER_TOP_RIGHT_RADIUS}
            borderTopLeftRadius={VARIABLES.MODAL.HEADER.H_1.BORDER_TOP_LEFT_RADIUS}
          >
            <Section justify='center'>
              <Image
                widthRatio='50'
                widthRatioUnit='px'
                heightRatio='50'
                heightRatioUnit='px'
                src={ICONS['ic-warning.svg']}
              />
            </Section>
          </Modal.Section>
          <Modal.Section
            paddingBottom={VARIABLES.MODAL.BODY.B_1.PADDING_BOTTOM}
            paddingHorizontal={VARIABLES.MODAL.BODY.B_1.PADDING_HORIZONTAL}
          >
            <Section
              spacingBottom='12'
              justify='center'
            >
              <Text
                fontStyle={TYPOGRAPHYS.FIRST.BOLD.FS_18}
                color={VARIABLES.COLORS.BLACK}
              >
                {getLanguage('modal.confirmation.title')}
              </Text>
            </Section>
            <Section
              justify='center'
            >
              <Text
                fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_15}
                color={VARIABLES.COLORS.GRAY_6}
                align='center'
              >
                {`${getLanguage('modal.confirmation.message')} ${data.name} ?`}
              </Text>
            </Section>
          </Modal.Section>
          <Modal.Section
            paddingBottom={10}
            paddingHorizontal={VARIABLES.MODAL.FOOTER.F_2.PADDING_HORIZONTAL}
          >
            <Button
              fluid
              shadow
              className='is-gradient-blue'
              height={VARIABLES.BUTTON.HEIGHTS.H_35}
              borderRadius={VARIABLES.BUTTON.BORDER_RADIUSES.BR_10}
              onClick={this.modal.onCilckDelete}
            >
              <Text
                fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_16}
                color={VARIABLES.COLORS.WHITE}
              >
                {getLanguage('field.buttonDelete')}
              </Text>
            </Button>
          </Modal.Section>
          <Modal.Section
            paddingBottom={VARIABLES.MODAL.FOOTER.F_2.PADDING_BOTTOM}
            paddingHorizontal={VARIABLES.MODAL.FOOTER.F_2.PADDING_HORIZONTAL}
            borderBottomRightRadius={VARIABLES.MODAL.FOOTER.F_2.BORDER_BOTTOM_RIGHT_RADIUS}
            borderBottomLeftRadius={VARIABLES.MODAL.FOOTER.F_2.BORDER_BOTTOM_LEFT_RADIUS}
          >
            <Button
              fluid
              bgColor={VARIABLES.COLORS.TRANSPARENT}
              borderWidth='1'
              borderStyle='solid'
              borderColor={VARIABLES.COLORS.BLUE_2}
              shadow
              height={VARIABLES.BUTTON.HEIGHTS.H_35}
              borderRadius={VARIABLES.BUTTON.BORDER_RADIUSES.BR_10}
              onClick={this.modal.onClickCancel}
            >
              <Text
                fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_16}
                color={VARIABLES.COLORS.BLUE_2}
              >
                {getLanguage('field.buttonCancel')}
              </Text>
            </Button>
          </Modal.Section>
        </Modal>
      </TransitionContainer>
    )
  }
}
