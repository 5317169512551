import React from 'react'
import _ from 'lodash'
import axios from 'axios'
import qs from 'qs'
import {
  TransitionContainer,
  Container,
  Section,
  Image,
  Field,
  NavigationBar,
  Text,
  Button,
  Alert,
} from './../../components'
import {
  VARIABLES,
  TYPOGRAPHYS,
  ICONS,
} from './../../themes'
import {
  ROUTE_PATH,
  redirect,
} from './../../helpers'
import {
  OrderInquiryItem,
} from './OrderInquiryItem'
import {
  Loader
} from '../Loader'
import config from '../../config'
import { LOCAL_STORAGE } from '../../helpers/constants/localStorage';
import { getLanguage } from '../../helpers/functions/language'
import { getOrderInquiryByUserId } from '../../helpers/services/orderInquiry'

export class OrderInquiryPage extends React.Component {
  constructor(props) {
    super(props)
    const user = JSON.parse(localStorage.getItem(LOCAL_STORAGE.REGISTER_INFO))
    const dealer = JSON.parse(localStorage.getItem('orderInquiryDealerSelected'))
    const query = qs.parse(_.get(this.props, 'location.search', '').replace('?', ''))
    this.state = {
      userId: _.get(user, 'id'),
      dealerIdSelected: _.get(query, 'dealerId', ''),
      dealerTypeSelected: _.get(query, 'dealerType', ''),
      dealerRefSelected: _.get(query, 'dealerRef', ''),
      list: [],
      isLoading: false,
      isSearch: query.dealerId && query.dealerType,
      showModal: false,
      titleModal: '',
      descriptionModal: '',
      typeModal: '',
      dealer,
    }
  }

  componentDidMount() {
    localStorage.removeItem('orderInquiryList')
    this.setState({ isLoading: true }, async () => {
      try {
        const options = { sort: 'id desc' }
        const params = {
          user_id: this.state.userId,
          ...options
        }
        const data = await getOrderInquiryByUserId(params)
        if (data) {
          this.setState({ list: data }, () => {
            localStorage.setItem('orderInquiryList', JSON.stringify(data))
          })
        }
      } catch (_) {
      } finally {
        this.setState({ isLoading: false })
      }
    })
  }

  handleBack() {
    localStorage.removeItem('orderInquiryDealerSelected')
    redirect(ROUTE_PATH.HOME.LINK)
  }

  handleBackSearch() {
    this.setState({
      dealerIdSelected: '',
      dealerTypeSelected: '',
      dealerRefSelected: '',
      isSearch: false,
    })
  }

  onChangeCategotySelected(event) {
    if (this.state.dealerIdSelected) {
      this.setState({ dealerIdSelected: '', isSearch: false })
    } else {
      this.setState({ dealerTypeSelected: event.target.value })
    }
  }

  onSearchDealer() {
    const { dealerTypeSelected } = this.state
    if (!dealerTypeSelected) return
    redirect(`${ROUTE_PATH.ORDER_INQUIRY_SEARCH.LINK}?dealerType=${dealerTypeSelected}`)
  }

  onChangeDealer() {
    this.setState({ dealerIdSelected: '', isSearch: false })
  }

  onClickCreateButton() {
    const {
      dealerTypeSelected,
      dealerIdSelected,
      dealerRefSelected
    } = this.state
    if (!dealerTypeSelected || !dealerIdSelected || !dealerRefSelected) {
      this.setState({
        showModal: true,
        typeModal: 'error',
        titleModal: '',
        descriptionModal: getLanguage('message.pleaseSelectCategoryAndDealer')
      })
    } else {
      redirect(`${ROUTE_PATH.ORDER_INQUIRY_ADD.LINK}?dealerType=${dealerTypeSelected}&dealerId=${dealerIdSelected}&dealerRef=${dealerRefSelected}`)
    }
  }

  onCloseModal() {
    this.setState({ showModal: false, typeModal: '' })
  }

  filterListByDealer() {
    const {
      list,
      dealerIdSelected,
      dealerRefSelected
    } = this.state
    // return list.filter(data => data.ad_id === +dealerIdSelected)
    return list.filter(data => data.ad_ref_id === dealerRefSelected)
  }

  render() {
    let {
      list,
      dealer,
      dealerTypeSelected,
      dealerIdSelected,
      isLoading,
      isSearch,
      showModal,
      typeModal,
      titleModal,
      descriptionModal,
      dealerRefSelected,
    } = this.state
    // if (dealerIdSelected) {
    if (dealerRefSelected) {
      list = this.filterListByDealer()
    }
    return (
      <TransitionContainer>
        <Container
          width={VARIABLES.WEBSITE_WIDTH}
          calcMinHeight='100vh'
          bgColor={VARIABLES.COLORS.LIGHT_BLUE_2}
        >
          <NavigationBar
            shadow
            iconWidth='21'
            iconHeight='21'
            iconSrc={ICONS['ic-navigation-order-inquity.svg']}
            iconPositionTop='-3'
            pageTitleIcon={getLanguage('orderInquiry.title')}
            actionLeft={isSearch ?
              <Image
                widthRatio='11'
                widthRatioUnit='px'
                heightRatio='21'
                heightRatioUnit='px'
                src={ICONS['ic-left-black.svg']}
                onClick={this.handleBackSearch.bind(this)}
              /> :
              <Image
                widthRatio='24'
                widthRatioUnit='px'
                heightRatio='23'
                heightRatioUnit='px'
                src={ICONS['ic-home-black.svg']}
                onClick={this.handleBack.bind(this)}
              />
            }
          />
          <Section
            scroll
            calcHeight='100vh - 70px'
          >
            <Section
              paddingHorizontal='20'
              paddingTop='24'
              paddingBottom='30'
            >
              <Section spacingBottom='9'>
                <Field
                  type='select'
                  fluid
                  height='40'
                  paddingRight='10'
                  paddingLeft='50'
                  bgColor={VARIABLES.COLORS.TRANSPARENT}
                  borderWidth='1'
                  borderStyle='solid'
                  borderColor={VARIABLES.COLORS.BLUE_2}
                  borderRadius='20'
                  fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_14}
                  fontColor={dealerTypeSelected ? VARIABLES.COLORS.BLUE_2 : VARIABLES.COLORS.LIGHT_BLUE_3}
                  id='category-selector'
                  name='category-selector'
                  value={dealerTypeSelected}
                  fieldIcon={<img src={ICONS['ic-arrow-down-blue.svg']} alt='Icon' />}
                  fieldIconWidth='41'
                  fieldIconSpacingRight='10'
                  fieldIconLeft={dealerTypeSelected ? <img src={ICONS['ic-list-blue.svg']} alt='Icon' /> : <img src={ICONS['ic-list-light-blue.svg']} alt='Icon' />}
                  fieldIconLeftSpacingLeft='10'
                  onChange={this.onChangeCategotySelected.bind(this)}
                >
                  <option value="" disabled selected hidden>{getLanguage('field.selectCategoryPlaceholder')}</option>
                  <option value="BAD">{getLanguage('orderInquiry.category.bad')}</option>
                  <option value="OR">{getLanguage('orderInquiry.category.or')}</option>
                </Field>
              </Section>
              <Section spacingBottom='23'>
                <Button
                  className='is-icon-right is-text-left'
                  fluid
                  disabled={!dealerTypeSelected}
                  bgColorDisabled={VARIABLES.COLORS.LIGHT_BLUE_4}
                  height='40'
                  paddingRight='10'
                  paddingLeft='50'
                  bgColor={dealerTypeSelected ? VARIABLES.COLORS.TRANSPARENT : VARIABLES.COLORS.LIGHT_BLUE_4}
                  borderWidth='1'
                  borderStyle='solid'
                  borderColor={VARIABLES.COLORS.BLUE_2}
                  borderRadius='20'
                  justify='left'
                  onClick={isSearch ? this.onChangeDealer.bind(this) : this.onSearchDealer.bind(this)}
                >
                  <Image
                    className='icon-left-button is-order-dealer-selector'
                    widthRatio='19'
                    widthRatioUnit='px'
                    heightRatio='15'
                    heightRatioUnit='px'
                    src={dealerIdSelected ? ICONS['ic-user-card-blue.svg'] : ICONS['ic-user-card-light-blue.svg']}
                  />
                  <Button.Icon>
                    {dealerIdSelected ?
                      <Image
                        widthRatio='18'
                        widthRatioUnit='px'
                        heightRatio='18'
                        heightRatioUnit='px'
                        src={ICONS['ic-close-blue-circle.svg']}
                      />
                      : <Image
                        widthRatio='15'
                        widthRatioUnit='px'
                        heightRatio='15'
                        heightRatioUnit='px'
                        src={ICONS['ic-search-light-blue.svg']}
                      />}
                  </Button.Icon>
                  <Text
                    fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_14}
                    color={dealerIdSelected ? VARIABLES.COLORS.BLUE_2 : VARIABLES.COLORS.LIGHT_BLUE_3}
                  >
                    {isSearch ? _.get(dealer, 'acc_name', '') : getLanguage('orderInquiry.selectDealer')}
                  </Text>
                </Button>

              </Section>
              <Section
                justify='center'
                spacingBottom='27'
              >
                <Button
                  shadow
                  className='is-gradient-blue is-icon-front'
                  width='275'
                  height={VARIABLES.BUTTON.HEIGHTS.H_40}
                  borderRadius={VARIABLES.BUTTON.BORDER_RADIUSES.BR_10}
                  onClick={this.onClickCreateButton.bind(this)}
                  bgColorDisabled={VARIABLES.COLORS.LIGHT_BLUE_4}
                >
                  <Button.Icon>
                    <Image
                      widthRatio='17'
                      widthRatioUnit='px'
                      heightRatio='17'
                      heightRatioUnit='px'
                      top='-2'
                      src={ICONS['ic-plus-circle.svg']}
                    />
                  </Button.Icon>
                  <Text
                    fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_16}
                    color={VARIABLES.COLORS.WHITE}
                  >
                    {getLanguage('field.buttonAddNewOrderInquiry')}
                  </Text>
                </Button>
              </Section>
              <Section spacingBottom='13'>
                <Text
                  fontStyle={TYPOGRAPHYS.FIRST.MEDIUM.FS_14}
                >
                  {isSearch ? `${getLanguage('orderInquiry.result')} : ${list.length} ${getLanguage('orderInquiry.orderInquiry')}` : getLanguage('orderInquiry.allPreviousOrderInquiry')}
                </Text>
              </Section>
              {!_.isEmpty(list) && list.map((data, index) => (
                <OrderInquiryItem key={index} data={data} dealerIdSelected={dealerIdSelected} dealerTypeSelected={dealerTypeSelected} />
              ))}
              {!isLoading && _.isEmpty(list) && <Section
                paddingTop='24'
                justify='center'
              >
                <Text
                  fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_16}
                  color={VARIABLES.COLORS.BLUE_2}
                >
                  {getLanguage('orderInquiry.dataNotFound')}
                </Text>
              </Section>}
            </Section>
          </Section>
          {/* <Section className='is-fade-gradient' /> */}
        </Container>
        <Alert
          open={showModal}
          type={typeModal}
          title={titleModal}
          description={descriptionModal}
          onClose={this.onCloseModal.bind(this)}
        />
        <Loader loading={isLoading} isOverlay />
      </TransitionContainer>
    )
  }
}

