import axios from 'axios';
import firebase from 'firebase';
import 'firebase/storage';
import $ from 'jquery';
import _ from 'lodash';
import moment from 'moment';
import React from 'react';
import { fetchApi } from '../../apiService/apiService';
import apiConfig from '../../config';
import { SelectCityContainerConnected } from '../../containers/SelectCityContainer';
import { SelectPostCodeContainerConnected } from '../../containers/SelectPostCodeContainer';
import { SelectProvinceContainerConnected } from '../../containers/SelectProvinceContainer';
import { SelectSubDistrictContainerConnected } from '../../containers/SelectSubDistrictContainer';
import { DATA } from '../../helpers/constants/data';
import { ENUM } from '../../helpers/constants/enum';
import { getRouteData, getRouteParams } from '../../helpers/functions';
import { getCurrentCountryLanguage } from '../../helpers/functions/addon';
import { getCityListByProvince, getCountryByLanguage, getCountryDataList, getCountryPhoneByCountry, getCountryType, loadImage, setCountryDataList } from '../../helpers/functions/data';
import { capitalizeFirstLetter, getCustomerTypeText } from '../../helpers/functions/display';
import { getLanguage } from '../../helpers/functions/language';
import { onOpenAlert } from '../../helpers/functions/main';
import {
  isValidCountryPhoneLength
} from '../../helpers/functions/validate';
import { usersUpload } from '../../helpers/services/users';
import {
  AlertModal
} from '../AlertModal';
import {
  Loader
} from '../Loader';
import {
  Alert,
  Button,
  Container,
  Field,
  Grid,
  Image,
  NavigationBar,
  Section,
  SelectControl,
  Text,
  TransitionContainer
} from './../../components';
import {
  ROUTE_PATH,
  isValidNumberNoPrefixZero,
  isValidResponse,
  redirect,
  redirectData,
} from './../../helpers';
import {
  FLAGS,
  ICONS,
  TYPOGRAPHYS,
  VARIABLES,
} from './../../themes';
import { userController } from '../../apiService/apiController/userService';

var config = {
  apiKey: "AIzaSyDFlJ9XDLGIENX4tK8y3vKWhgX2b0VbYwE",
  authDomain: "bluescope-955e7.firebaseapp.com",
  storageBucket: "bluescope-955e7.appspot.com",
}

export class RegisterPage extends React.Component {
  constructor(props) {
    super(props)
    const ssoId = getRouteParams(props, 'id');
    const routeData = getRouteData(props, 'data');
    console.log('RegisterPage routeData', routeData);
    this.state = {
      fieldFocus: '',
      showModal: false,
      msg: '',
      topic: '',
      isLoading: false,
      // TODO: remove when connect store
      // mock field
      profileImage: '',
      firstName: '',
      lastName: '',
      nickName: '',
      type: '',
      phone: '',
      email: '',
      birthDate: '',
      address: '',
      district: '',
      subDistrict: '',
      alley: '',
      group: '',
      street: '',
      ward: '',
      houseNumber: '',
      postCode: '',
      selectedProvince: '',
      selectedCity: '',
      road: '',
      countries: ENUM.COUNTRY.THAI,
      otpList: ['', '', '', '', ''],

      ssoId,
      ...routeData,
      userId: _.get(routeData, 'userId'),
    }
  }

  componentWillMount() {
    // remove localStorage
    // localStorage.removeItem(LOCAL_STORAGE.REGISTER_INFO);

    // let countries = getCountryByLanguage();
    // this.setState({
    //   countries,
    // });

    getCurrentCountryLanguage().then(language => {
      let countries = getCountryByLanguage(language);
      console.log('getCurrentCountryLanguage', language, countries);
      setCountryDataList(countries);
      this.setState({
        countries,
      });
    });
  }

  componentDidMount() {
    if (!firebase.apps.length) {
      firebase.initializeApp(config);
    }
  }

  closeAlert() {
    this.setState({
      showModal: false
    })
  }

  async saveUserInfo(data) {
    const {
      isUpdateUserData,
    } = this.state;
    redirectData(ROUTE_PATH.VERIFY_QUESTION.LINK, {
      data: {
        ...data,
        isUpdateUserData,
        verifyType: 'register',
      },
    });
  }

  onChangeField(key, event) {
    let value = event.target.value;
    switch (key) {
      case 'phone':
        if (value.length && !isValidNumberNoPrefixZero(value)) {
          return;
        }
      default:
        this.setState({
          [key]: value
        });
    }
  }

  validateData() {
    const {
      countries,
      type,
      address,
      selectedProvince,
      selectedCity,
      subDistrict,
      postCode,
      district,
      alley,
      group,
      street,
      ward,

      ssoId,
    } = this.state;
    console.log('validateData', this.state);
    // Email validation
    if (this.state.email !== '') {
      let emailValid = this.state.email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
      if (!emailValid) return { email: false };
    }

    // Data validation
    if (this.state.firstName === '' || this.state.lastName === '') return { name: false };
    // Number validation
    if (!isValidCountryPhoneLength(countries, this.state.phone)) {
      return { phone: false };
    }

    //TODO: Validation Image Profile

    // new form register
    switch (countries.toLowerCase()) {
      case ENUM.COUNTRY.THAI.toLowerCase():
      case ENUM.COUNTRY.LAOS.toLowerCase():
        if (
          type.length === 0 ||
          address.length === 0 ||
          selectedProvince.length === 0 ||
          selectedCity.length === 0 ||
          subDistrict.length === 0 ||
          postCode.length === 0
        ) {
          return { inputAllRequired: false };
        }
        break;
      case ENUM.COUNTRY.INDONESIA.toLowerCase():
        if (
          type.length === 0 ||
          address.length === 0 ||
          selectedProvince.length === 0 ||
          selectedCity.length === 0 ||
          district.length === 0 ||
          subDistrict.length === 0 ||
          postCode.length === 0
        ) {
          return { inputAllRequired: false };
        }
        break;
      case ENUM.COUNTRY.MALAYSIA.toLowerCase():
        if (
          type.length === 0 ||
          address.length === 0 ||
          selectedProvince.length === 0 ||
          selectedCity.length === 0 ||
          postCode.length === 0
        ) {
          return { inputAllRequired: false };
        }
        break;
      case ENUM.COUNTRY.VIETNAM.toLowerCase():
        if (
          type.length === 0 ||
          address.length === 0 ||
          selectedProvince.length === 0 ||
          selectedCity.length === 0 ||
          district.length === 0 ||
          alley.length === 0 ||
          group.length === 0 ||
          street.length === 0 ||
          ward.length === 0 ||
          postCode.length === 0
        ) {
          return { inputAllRequired: false };
        }
        break;
      default: break;
    }

    return { valid: true };
  }

  async handleSubmit() {
    const {
      isUpdateUserData,
      phone,
    } = this.state;

    if (this.state.selectImg) {
      await this.getImgUrl();
    }
    // CheckPasswordMatch
    let dataValidation = this.validateData();
    if (dataValidation.valid) {
      this.setState({
        isLoading: true,
      }, async () => {
        const params = {
          phone
        }
        const configService = {
          isShowError: false,
        }
        const userService = userController(configService);
        const res = await userService.checkMemberByPhone(params);
        if (isValidResponse(res)) {
          if (isUpdateUserData) {
            // for register without otp
            // var params = this.register.handleSaveUserParams();
            // await this.saveUserInfo(params);

            // register with otp
            this.otp.sendOtp();
          }
          else {
            // this.setState({
            //   isLoading: true,
            // }, async () => {
            // const {
            //   countries,
            //   phone,
            // } = this.state;
            // const prefix = getCountryPhoneByCountry(countries);
            // const checkPhone = `${prefix}${phone}`;
            // console.log('checkPhone', checkPhone)
            await axios.post(`${apiConfig.api.base}/api/users/search`, {
              phone: parseInt(phone),
            }).then(async (response) => {
              const res = response.data;
              if (isValidResponse(res)) {
                const dataList = res.data;
                if (dataList.length) {
                  this.setState({
                    topic: getLanguage('message.fail'),
                    msg: getLanguage('message.phoneAlreadyExists'),
                    showModal: true,
                    isLoading: false,
                  })
                }
                else {
                  // for register without otp
                  // var params = this.register.handleSaveUserParams();
                  // await this.saveUserInfo(params);

                  // register with otp
                  this.otp.sendOtp();
                }
              }
              else {
                this.setState({
                  topic: getLanguage('message.fail'),
                  msg: getLanguage('message.somethingWrong'),
                  showModal: true,
                  isLoading: false,
                })
              }
            }, (error) => {
              console.log(error);
              this.setState({ isLoading: false });
              onOpenAlert({
                type: DATA.ALERT_TYPE.ERROR,
                title: getLanguage('message.fail'),
                message: (error || {}).message,
              });
            });
            // });
            // redirect(ROUTE_PATH.WELCOME.LINK);
          }
        }
        else {
          this.setState({
            topic: getLanguage('message.fail'),
            msg: getLanguage('message.phoneAlreadyExists'),
            showModal: true,
            isLoading: false,
          })
        }
      })
    } else if (dataValidation.email === false) {
      //TODO: ALERT POPUP
      // alert('some information maybe wrong, please try again')
      this.setState({
        topic: getLanguage('message.fail'),
        msg: getLanguage('message.emailInCorrectFormat'),
        showModal: true,
      })
    } else if (dataValidation.phone === false) {
      //TODO: ALERT POPUP
      // alert('some information maybe wrong, please try again')
      this.setState({
        topic: getLanguage('message.fail'),
        msg: getLanguage('message.phoneInCorrectFormat'),
        showModal: true,
      })
    } else if (dataValidation.name === false) {
      //TODO: ALERT POPUP
      // alert('some information maybe wrong, please try again')
      this.setState({
        topic: getLanguage('message.fail'),
        msg: getLanguage('message.nameInCorrectFormat'),
        showModal: true,
      })
    }
    else if (dataValidation.inputAllRequired === false) {
      this.setState({
        topic: getLanguage('message.fail'),
        msg: getLanguage('message.pleaseInputAllRequiredField'),
        showModal: true,
      })
    }
  }

  onChangePhone(event) {
    const value = event.target.value;
    console.log('value', value);
    this.setState({
      countries: value,
      selectedProvince: '',
      selectedProvinceText: '',
      selectedCity: '',
      selectedCityText: '',
      subDistrict: '',
      postCode: '',
      district: '',
      alley: '',
      group: '',
      street: '',
      ward: '',
    });
    setCountryDataList(value);
    // switch (value) {
    //   case ENUM.COUNTRY.THAI:
    //     changeLanguage(DATA.LANGUAGE.THAI);
    //     break;
    //   case ENUM.COUNTRY.INDONESIA:
    //     changeLanguage(DATA.LANGUAGE.INDONESIA);
    //     break;
    //   case ENUM.COUNTRY.MALAYSIA:
    //     changeLanguage(DATA.LANGUAGE.MALAYSIA);
    //     break;
    //   case ENUM.COUNTRY.LAOS:
    //     changeLanguage(DATA.LANGUAGE.LAOS);
    //     break;
    //   case ENUM.COUNTRY.VIETNAM:
    //     changeLanguage(DATA.LANGUAGE.VIETNAM);
    //     break;
    // }
    // window.location.reload(true);
  }

  async getImgUrl() {
    const img = this.state.selectImg;
    const formdata = new FormData()
    formdata.append('file', img)
    // console.log('data ==> ', data);
    // await axios.post(`${apiConfig.api.base}/api/users/upload`, formdata, { // receive two parameter endpoint url ,form data
    await usersUpload(formdata, {}).then(res => { // then print response status
      console.log('res ==> ', res.url);
      this.setState({
        profileImage: res.url,
      })
    }, (error) => {
      console.log(error);
      onOpenAlert({
        type: DATA.ALERT_TYPE.ERROR,
        title: getLanguage('message.fail'),
        message: (error || {}).message,
      });
    });
  }

  uploadImgHandler() {
    $("input[id='my_file']").click();
  }

  async uploadImgChange(event) {
    const file = event.target.files[0];
    console.log('file', file);
    const newFile = await loadImage(file);
    this.setState({
      selectImg: newFile,
      profileImage: URL.createObjectURL(newFile)
    })

    // const formdata = new FormData()
    // formdata.append('file', img)
    // // console.log('data ==> ', data);
    // await axios.post("/api/users/upload", formdata, { // receive two parameter endpoint url ,form data
    // }).then(res => { // then print response status
    //   console.log('res ==> ', res.data.url);
    //   this.setState({
    //     profileImage: res.data.url,
    //   })
    // });

    // const storage = firebase.storage();
    // const uploadTask = storage.ref(`rbpmobileapp/users/${img.name}`).put(img);
    // uploadTask.on('state_changed',
    //   (snapshot) => {

    //   },
    //   (error) => {
    //     console.log(error);
    //   },
    //   () => {
    //     storage.ref('rbpmobileapp/users').child(img.name).getDownloadURL().then(url => {
    //       console.log('url', url);
    //     })
    //   }
    // )
    // const path = dirname + "report_gen/" + name + "days" + ".csv"
    // console.log(path)

    // fs.writeFile(path, report, async function (err, data) {
    //   if (err) {
    //     throw err
    //   } else {
    //     const storagePath = "File/report/" + name + "days" + ".csv"
    //     await fireWrapper.uploadFile(path, storagePath)
    //     callback(storagePath)
    //   }
    // })
  }

  register = {
    handleSaveUserParams: () => {
      const {
        ssoId,
        questionInput1,
        questionInput2,
        questionInput3,
        userId,
      } = this.state;
      return {
        profileImage: this.state.profileImage,
        firstName: this.state.firstName,
        lastName: this.state.lastName,
        nickName: this.state.nickName,
        phone: parseInt(this.state.phone),
        email: this.state.email ? this.state.email : null,
        countries: this.state.countries,
        //new register params
        type: this.state.type,
        birthDate: this.state.birthDate,
        postCode: this.state.postCode,
        address: JSON.stringify({
          address_text: this.state.address,
          province: this.state.selectedProvince,
          city: this.state.selectedCity,
          province_text: this.state.countries.toLowerCase() === ENUM.COUNTRY.LAOS.toLowerCase() ? this.state.selectedProvince : this.state.selectedProvinceText,
          city_text: this.state.countries.toLowerCase() === ENUM.COUNTRY.LAOS.toLowerCase() ? this.state.selectedCity : this.state.selectedCityText,
          district: this.state.district || '',
          sub_district: this.state.subDistrict || '',
          alley: this.state.alley || '',
          group: this.state.group || '',
          street: this.state.street || '',
          ward: this.state.ward || '',
          address_type: getCountryType(this.state.countries)
        }),
        address_text: this.state.address,
        province: this.state.countries.toLowerCase() === ENUM.COUNTRY.LAOS.toLowerCase() ? this.state.selectedProvince : this.state.selectedProvinceText,
        city: this.state.countries.toLowerCase() === ENUM.COUNTRY.LAOS.toLowerCase() ? this.state.selectedCity : this.state.selectedCityText,
        district: this.state.district || '',
        subDistrict: this.state.subDistrict || '',
        alley: this.state.alley || '',
        group: this.state.group || '',
        street: this.state.street || '',
        ward: this.state.ward || '',
        addressType: getCountryType(this.state.countries),

        ssoId,
        questionInput1,
        questionInput2,
        questionInput3,
        userId,
      };
    },
    onClickGoBack: () => {
      const {
        ssoId,
      } = this.state;
      if (ssoId) {
        redirect(`${ROUTE_PATH.LOGIN_BY_LINE_PHONE.LINK}/${ssoId}`);
      }
      else {
        redirect(ROUTE_PATH.LOGIN_BY_PHONE.LINK);
      }
    }
  }

  otp = {
    onClickSubmitOtp: () => {
      const {
        otpList,
        otpSecret,
      } = this.state;
      if (otpList.filter(e => e === '').length) {
        this.setState({
          topic: getLanguage('message.fail'),
          msg: getLanguage('message.pleaseInputAllField'),
          showModal: true,
        })
      }
      else {
        this.setState({
          isLoading: true,
        }, () => {
          let token = '';
          otpList.forEach(e => {
            token += e;
          })
          axios.post(`${apiConfig.api.base}/api/otp/verify`, {
            token,
            secret: otpSecret
          }).then(async (res) => {
            const data = res.data;
            if (data.message === 'success') {
              var params = this.register.handleSaveUserParams();
              await this.saveUserInfo(params);
            }
            else {
              this.setState({
                topic: getLanguage('message.fail'),
                msg: data.message,
                showModal: true,
                isLoading: false,
              })
            }
          }, (error) => {
            console.log(error);
            this.setState({ isLoading: false });
            onOpenAlert({
              type: DATA.ALERT_TYPE.ERROR,
              title: getLanguage('message.fail'),
              message: (error || {}).message,
            });
          });
        });
      }
    },
    onCloseInputOtp: () => {
      this.setState({
        isOpenInputOtp: false,
      })
    },
    onCloseAlertOtp: () => {
      this.setState({
        isOpenAlertOtp: false,
        isOpenInputOtp: true,
        otpList: ['', '', '', '', '']
      });
    },
    sendOtp: () => {
      const {
        phone,
        countries,
        firstName,
      } = this.state;
      this.setState({
        isLoading: true,
      }, () => {
        fetchApi.get(`${apiConfig.api.base}/api/otp/getsecret`).then((resGetSecret) => {
          const dataSecret = resGetSecret.data;
          if (dataSecret.message === 'success') {
            const prefix = getCountryPhoneByCountry(countries);
            let sendOtpTo = `${prefix}${phone}`;
            axios.post(`${apiConfig.api.base}/api/otp/send`, {
              to: sendOtpTo,
              first_name: firstName,
              countries,
              message_type: ENUM.OTP_TYPE.REGISTER,
              secret: dataSecret.secret
            }).then((resSendOtp) => {
              const dataSendOtp = resSendOtp.data;
              if (dataSendOtp.message === 'success') {
                this.setState({
                  sendOtpTo,
                  otpSecret: dataSecret.secret,
                  isOpenInputOtp: true,
                  isOpenAlertOtp: true,
                  isLoading: false,
                  otpList: ['', '', '', '', ''],
                });
              }
              else {
                this.setState({ isLoading: false });
                onOpenAlert({
                  type: DATA.ALERT_TYPE.ERROR,
                  title: getLanguage('message.fail'),
                  message: getLanguage('message.sendOtpError'),
                });
              }
            }, (error) => {
              console.log(error);
              this.setState({ isLoading: false });
              onOpenAlert({
                type: DATA.ALERT_TYPE.ERROR,
                title: getLanguage('message.fail'),
                message: (error || {}).message,
              });
            });
          }
          else {
            this.setState({
              isLoading: false,
            });
            onOpenAlert({
              type: DATA.ALERT_TYPE.ERROR,
              title: getLanguage('message.fail'),
              message: getLanguage('message.getSecretOtpError'),
            });
          }
        });
      });
    }
  }

  input = {
    onChangeOtp: (ev, index) => {
      const {
        otpList,
      } = this.state;
      ev.target.value = parseInt(ev.target.value) % 10;
      const value = ev.target.value;
      otpList[index] = value;
      this.setState({
        otpList,
      })
      if (index + 1 < otpList.length) {
        this[`refOtp${index + 1}`].focus();
      }
    },
    onChangeAddress: (select, target) => {
      const {
        countries,
      } = this.state;
      if (countries.toLowerCase() === ENUM.COUNTRY.THAI.toLowerCase()) {
        if (target === 'selectedProvince') {
          this.setState({
            selectedCity: '',
            selectedCityText: '',
            subDistrict: '',
            postCode: ''
          })
        }
        if (target === 'selectedCity') {
          this.setState({
            subDistrict: '',
            postCode: ''
          })
        }
        if (target === 'subDistrict') {
          this.setState({
            postCode: ''
          })
        }
        this.setState({
          [target]: select.value,
          [`${target}Text`]: select.value === '' ? '' : select.label,
        })
      }
      else {
        if (target === 'selectedProvince') {
          this.setState({
            selectedCity: '',
            selectedCityText: '',
          })
        }
        this.setState({
          [target]: select.value,
          [`${target}Text`]: select.value === '' ? '' : select.label,
        })
      }
    },
  }

  render() {
    const {
      fieldFocus,
      profileImage,
      firstName,
      lastName,
      nickName,
      phone,
      email,
      selectImg,
      isOpenAlertOtp,
      sendOtpTo,
      isOpenInputOtp,
      isOtpInCorrect,
      otpList,
      type,
      countries,
      birthDate,
      address,
      selectedCity,
      selectedCityText,
      selectedProvince,
      selectedProvinceText,
      district,
      subDistrict,
      alley,
      group,
      street,
      ward,
      postCode,

      ssoId,
      isUpdateUserData,
    } = this.state;
    return (
      <React.Fragment>
        {
          isOpenInputOtp ?
            <TransitionContainer>
              <Container
                width={VARIABLES.WEBSITE_WIDTH}
                height='100'
                heightUnit='vh'
                bgColor={VARIABLES.COLORS.LIGHT_BLUE_2}
              >
                <NavigationBar
                  shadow
                  pageTitle={getLanguage('register.title_2')}
                  actionLeft={
                    <Image
                      widthRatio='11'
                      widthRatioUnit='px'
                      heightRatio='21'
                      heightRatioUnit='px'
                      src={ICONS['ic-left-black.svg']}
                      onClick={this.otp.onCloseInputOtp}
                    />
                  }
                />
                <Section
                  scroll
                  nowrap
                  direction='column'
                  justify='space-between'
                  align='center'
                  calcHeight='100vh - 70px'
                  paddingTop='75'
                  paddingBottom='55'
                >
                  <Section width='300'>
                    <Section>
                      <Text
                        fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_20}
                        color={VARIABLES.COLORS.GRAY_12}
                      >
                        OTP
                      </Text>
                    </Section>
                    <Section spacingBottom='35'>
                      <Text
                        fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_14}
                        color={VARIABLES.COLORS.GRAY_3}
                      >
                        {getLanguage('register.message_2')} +{sendOtpTo}
                      </Text>
                    </Section>
                    <Section
                      position='relative'
                      spacingBottom='70'
                    >
                      <Grid gutter='25'>
                        {
                          otpList.map((e, i) => {
                            return (
                              <Grid.Column flex='1'
                                key={i}
                              >
                                <Field error={isOtpInCorrect}
                                  className='is-otp'
                                  width='64'
                                  bgColor='transparent'
                                  inputTextType='number'
                                  fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_40}
                                  fontColor={VARIABLES.COLORS.BLUE_2}
                                  errorColor={VARIABLES.COLORS.ORANGE_1}
                                  textAlign='center'
                                  borderWidth='0'
                                  innerRef={r => this[`refOtp${i}`] = r}
                                  onChange={(ev) => {
                                    this.input.onChangeOtp(ev, i)
                                  }}
                                  value={e}
                                />
                              </Grid.Column>
                            )
                          })
                        }
                      </Grid>
                      {isOtpInCorrect &&
                        <Section
                          className='is-otp-incorrect-message'
                          justify='center'
                        >
                          <Text
                            fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_14}
                            color={VARIABLES.COLORS.ORANGE_1}
                          >
                            {getLanguage('message.otpIncorrect')}
                          </Text>
                        </Section>
                      }
                    </Section>
                    <Section justify='center'>
                      <Section width='275'>
                        <Section spacingBottom='15'>
                          <Button
                            fluid
                            shadow
                            className='is-gradient-blue'
                            height={VARIABLES.BUTTON.HEIGHTS.H_40}
                            borderRadius={VARIABLES.BUTTON.BORDER_RADIUSES.BR_10}
                            onClick={this.otp.onClickSubmitOtp}
                          >
                            <Text
                              fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_16}
                              color={VARIABLES.COLORS.WHITE}
                            >
                              {getLanguage('field.buttonConfirm')}
                            </Text>
                          </Button>
                        </Section>
                        <Section>
                          <Button
                            fluid
                            height={VARIABLES.BUTTON.HEIGHTS.H_40}
                            bgColor={VARIABLES.COLORS.TRANSPARENT}
                            borderRadius={VARIABLES.BUTTON.BORDER_RADIUSES.BR_10}
                            borderWidth='1'
                            borderStyle='solid'
                            borderColor={VARIABLES.COLORS.BLUE_2}
                            onClick={this.otp.sendOtp}
                          >
                            <Text
                              fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_16}
                              color={VARIABLES.COLORS.BLUE_2}
                            >
                              {getLanguage('field.buttonReSend')}
                            </Text>
                          </Button>
                        </Section>
                      </Section>
                    </Section>
                  </Section>
                </Section>
              </Container>
            </TransitionContainer >
            :
            <React.Fragment>
              <TransitionContainer>
                <Container
                  width={VARIABLES.WEBSITE_WIDTH}
                  height='100'
                  heightUnit='vh'
                  bgColor={VARIABLES.COLORS.WHITE}
                >
                  <NavigationBar
                    shadow
                    pageTitle={getLanguage('register.title_1')}
                    actionLeft={
                      <Image
                        widthRatio='11'
                        widthRatioUnit='px'
                        heightRatio='21'
                        heightRatioUnit='px'
                        src={ICONS['ic-left-black.svg']}
                        onClick={this.register.onClickGoBack}
                      />
                    }
                  />
                  <Section
                    scroll
                    justify='center'
                    calcHeight='100vh - 70px'
                    paddingTop='40'
                    paddingBottom='55'
                  >
                    <Section width='275'>
                      <Section
                        spacingBottom='20'
                        justify='center'
                        position='relative'
                      >
                        <input style={{ display: 'none' }} type="file" id="my_file" onChange={this.uploadImgChange.bind(this)} />

                        {profileImage || selectImg ? <Image
                          circle
                          widthRatio='110'
                          widthRatioUnit='px'
                          heightRatio='110'
                          heightRatioUnit='px'
                          src={profileImage ? profileImage : `${URL.createObjectURL(this.state.selectImg)}`}
                          borderRadius={55}
                          onClick={this.uploadImgHandler.bind(this)}
                        /> : <Button
                          className='is-icon'
                          width={110}
                          height={110}
                          borderRadius={55}
                          bgColor={VARIABLES.COLORS.LIGHT_BLUE_1}
                          onClick={this.uploadImgHandler.bind(this)}
                        >
                          <Button.Icon>
                            <Image
                              widthRatio='72'
                              widthRatioUnit='px'
                              heightRatio='62'
                              heightRatioUnit='px'
                              src={ICONS['ic-profile-blue.svg']}
                            />
                          </Button.Icon>
                        </Button>}
                        <Button
                          className='is-register-image-icon'
                          width='40'
                          height='40'
                          borderRadius='40'
                          bgColor={VARIABLES.COLORS.BLUE_2}
                        >
                          <Button.Icon>
                            <Image
                              widthRatio='21'
                              widthRatioUnit='px'
                              heightRatio='18'
                              heightRatioUnit='px'
                              top='-1'
                              src={ICONS['ic-camera.svg']}
                              onClick={this.uploadImgHandler.bind(this)}
                            />
                          </Button.Icon>
                        </Button>
                      </Section>
                      <Section spacingBottom='27' className='is-register-floating-label'>
                        <Field
                          fluid
                          height='28'
                          placeholder={getLanguage('field.firstnamePlaceholder')}
                          placeholderColor={VARIABLES.COLORS.GRAY_5}
                          paddingHorizontal='10'
                          paddingBottom='10'
                          bgColor={VARIABLES.COLORS.TRANSPARENT}
                          borderBottomWidth='1'
                          borderBottomStyle='solid'
                          value={firstName}
                          onChange={this.onChangeField.bind(this, 'firstName')}
                          borderBottomColor={VARIABLES.COLORS.GRAY_3}
                          fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_15}
                          fontColor={VARIABLES.COLORS.BLACK}
                        />
                        <Text
                          className='is-floating-label'
                          fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_15}
                          color={VARIABLES.COLORS.GRAY_2}
                        >
                          {getLanguage('field.firstname')}
                          <Text
                            fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_15}
                            color={VARIABLES.COLORS.RED_2}
                          >
                            *
                          </Text>
                        </Text>
                      </Section>
                      <Section spacingBottom='27' className='is-register-floating-label'>
                        <Field
                          fluid
                          height='28'
                          placeholder={getLanguage('field.lastnamePlaceholder')}
                          placeholderColor={VARIABLES.COLORS.GRAY_5}
                          paddingHorizontal='10'
                          paddingBottom='10'
                          bgColor={VARIABLES.COLORS.TRANSPARENT}
                          borderBottomWidth='1'
                          borderBottomStyle='solid'
                          value={lastName}
                          onChange={this.onChangeField.bind(this, 'lastName')}
                          borderBottomColor={VARIABLES.COLORS.GRAY_3}
                          fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_15}
                          fontColor={VARIABLES.COLORS.BLACK}
                        />
                        <Text
                          className='is-floating-label'
                          fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_15}
                          color={VARIABLES.COLORS.GRAY_2}
                        >
                          {getLanguage('field.lastname')}
                          <Text
                            fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_15}
                            color={VARIABLES.COLORS.RED_2}
                          >
                            *
                          </Text>
                        </Text>
                      </Section>
                      <Section spacingBottom='27' className='is-register-floating-label'>
                        <Field
                          fluid
                          height='28'
                          placeholder={getLanguage('field.nicknamePlaceholder')}
                          placeholderColor={VARIABLES.COLORS.GRAY_5}
                          paddingHorizontal='10'
                          paddingBottom='10'
                          bgColor={VARIABLES.COLORS.TRANSPARENT}
                          borderBottomWidth='1'
                          borderBottomStyle='solid'
                          value={nickName}
                          onChange={this.onChangeField.bind(this, 'nickName')}
                          borderBottomColor={VARIABLES.COLORS.GRAY_3}
                          fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_15}
                          fontColor={VARIABLES.COLORS.BLACK}
                        />
                        <Text
                          className='is-floating-label'
                          fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_15}
                          color={VARIABLES.COLORS.GRAY_2}
                        >
                          {getLanguage('field.nickname')}
                          {/* <Text
                            fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_15}
                            color={VARIABLES.COLORS.RED_2}
                          >
                            *
                          </Text> */}
                        </Text>
                      </Section>
                      <Section spacingBottom='27' className='is-register-floating-label'>
                        <Field
                          type='select'
                          fluid
                          height='32'
                          paddingRight='36'
                          paddingBottom='6'
                          paddingLeft='10'
                          bgColor={VARIABLES.COLORS.WHITE}
                          borderBottomWidth='1'
                          borderBottomStyle='solid'
                          borderBottomColor={VARIABLES.COLORS.GRAY_3}
                          fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_15}
                          fontColor={VARIABLES.COLORS.BLACK}
                          fieldIcon={
                            <Image
                              widthRatio='13'
                              widthRatioUnit='px'
                              heightRatio='8'
                              heightRatioUnit='px'
                              src={ICONS['ic-arrow-select.svg']}
                            />
                          }
                          fieldIconWidth='36'
                          fieldIconSpacingRight='0'
                          value={type}
                          onChange={this.onChangeField.bind(this, 'type')}
                        >
                          <option value=''>{getLanguage('field.typePlaceholder')}</option>
                          {Object.values(ENUM.CUSTOMER_TYPE).map((e, i) => {
                            if (countries.toLowerCase() === ENUM.COUNTRY.MALAYSIA.toLowerCase()) {
                              if (e.TYPE === ENUM.CUSTOMER_TYPE.ROLL_FORMER.TYPE) {
                                return undefined;
                              }
                            }
                            return (
                              <option
                                key={i}
                                value={e.TYPE}
                              >
                                {getCustomerTypeText(e.TEXT)}
                              </option>
                            )
                          })}
                        </Field>
                        <Text
                          className='is-floating-label'
                          fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_15}
                          color={VARIABLES.COLORS.GRAY_2}
                        >
                          {getLanguage('field.type')}
                          <Text
                            fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_15}
                            color={VARIABLES.COLORS.RED_2}
                          >
                            *
                          </Text>
                        </Text>
                      </Section>
                      <Section spacingBottom='27' className='is-register-floating-label'>
                        <Field
                          fluid
                          autoFocus
                          className='is-phone-id'
                          inputTextType='tel'
                          placeholder='Insert phone number'
                          placeholderColor={VARIABLES.COLORS.GRAY_2}
                          height='28'
                          paddingBottom='10'
                          paddingLeft='130'
                          bgColor={VARIABLES.COLORS.TRANSPARENT}
                          borderBottomWidth='1'
                          borderBottomStyle='solid'
                          value={phone}
                          onChange={this.onChangeField.bind(this, 'phone')}
                          borderBottomColor={VARIABLES.COLORS.GRAY_3}
                          fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_15}
                          fontColor={VARIABLES.COLORS.BLACK}
                          fieldIcon={
                            <Grid gutter='10'>
                              <Grid.Column>
                                {countries.toLowerCase() === ENUM.COUNTRY.VIETNAM.toLowerCase() ? <Image
                                  widthRatio='27'
                                  widthRatioUnit='px'
                                  heightRatio='19'
                                  heightRatioUnit='px'
                                  src={FLAGS['flag-vietnam.svg']}
                                /> : countries.toLowerCase() === ENUM.COUNTRY.INDONESIA.toLowerCase() ? <Image
                                  widthRatio='27'
                                  widthRatioUnit='px'
                                  heightRatio='19'
                                  heightRatioUnit='px'
                                  src={FLAGS['flag-indonesia.svg']}
                                /> : countries.toLowerCase() === ENUM.COUNTRY.MALAYSIA.toLowerCase() ? <Image
                                  widthRatio='27'
                                  widthRatioUnit='px'
                                  heightRatio='19'
                                  heightRatioUnit='px'
                                  src={FLAGS['flag-malaysia.svg']}
                                /> : countries.toLowerCase() === ENUM.COUNTRY.LAOS.toLowerCase() ? <Image
                                  widthRatio='27'
                                  widthRatioUnit='px'
                                  heightRatio='19'
                                  heightRatioUnit='px'
                                  src={FLAGS['flag-lao.svg']}
                                /> :
                                  <Image
                                    widthRatio='27'
                                    widthRatioUnit='px'
                                    heightRatio='19'
                                    heightRatioUnit='px'
                                    src={FLAGS['flag-thailand.svg']}
                                  />
                                }
                              </Grid.Column>
                              <Grid.Column>
                                <Text
                                  className='is-phone-country-id'
                                  fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_15}
                                  color={VARIABLES.COLORS.BLACK}
                                  top='1'
                                >
                                  <select name='' id='select-regis-flag' onChange={this.onChangePhone.bind(this)}>
                                    <option
                                      selected={countries.toLowerCase() === ENUM.COUNTRY.THAI.toLowerCase() ? 'selected' : undefined}
                                      value={ENUM.COUNTRY.THAI}
                                    >
                                      TH +66
                                    </option>
                                    <option
                                      selected={countries.toLowerCase() === ENUM.COUNTRY.INDONESIA.toLowerCase() ? 'selected' : undefined}
                                      value={ENUM.COUNTRY.INDONESIA}
                                    >
                                      INA +62
                                    </option>
                                    <option
                                      selected={countries.toLowerCase() === ENUM.COUNTRY.MALAYSIA.toLowerCase() ? 'selected' : undefined}
                                      value={ENUM.COUNTRY.MALAYSIA}
                                    >
                                      MAS +60
                                    </option>
                                    <option
                                      selected={countries.toLowerCase() === ENUM.COUNTRY.LAOS.toLowerCase() ? 'selected' : undefined}
                                      value={ENUM.COUNTRY.LAOS}
                                    >
                                      LAOS +856
                                    </option>
                                    <option
                                      selected={countries.toLowerCase() === ENUM.COUNTRY.VIETNAM.toLowerCase() ? 'selected' : undefined}
                                      value={ENUM.COUNTRY.VIETNAM}
                                    >
                                      VIE +84
                                    </option>
                                  </select>
                                </Text>
                              </Grid.Column>
                            </Grid>
                          }
                          fieldIconSpacingLeft='10'
                        />
                        <Text
                          className='is-floating-label'
                          fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_15}
                          color={VARIABLES.COLORS.GRAY_2}
                        >
                          {getLanguage('field.phoneNumber')}
                          <Text
                            fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_15}
                            color={VARIABLES.COLORS.RED_2}
                          >
                            *
                          </Text>
                        </Text>
                      </Section>
                      <Section spacingBottom='27' className='is-register-floating-label'>
                        <Field
                          fluid
                          height='28'
                          placeholder={getLanguage('field.emailPlaceholder')}
                          placeholderColor={VARIABLES.COLORS.GRAY_5}
                          paddingHorizontal='10'
                          paddingBottom='10'
                          bgColor={VARIABLES.COLORS.TRANSPARENT}
                          borderBottomWidth='1'
                          borderBottomStyle='solid'
                          value={email}
                          onChange={this.onChangeField.bind(this, 'email')}
                          borderBottomColor={VARIABLES.COLORS.GRAY_3}
                          fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_15}
                          fontColor={VARIABLES.COLORS.BLACK}
                        />
                        <Text
                          className='is-floating-label'
                          fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_15}
                          color={VARIABLES.COLORS.GRAY_2}
                        >
                          {getLanguage('field.email')}
                        </Text>
                      </Section>
                      <Section spacingBottom='27' className='is-register-floating-label'>
                        <Field
                          fluid
                          className='is-field-float-placeholder is-calendar is-calendar-bg-white'
                          type='date'
                          height='28'
                          placeholder={getLanguage('field.birthDatePlaceholder')}
                          placeholderColor={VARIABLES.COLORS.GRAY_5}
                          paddingRight='0'
                          paddingLeft='5'
                          paddingBottom='10'
                          bgColor={VARIABLES.COLORS.TRANSPARENT}
                          borderBottomWidth='1'
                          borderBottomStyle='solid'
                          value={birthDate}
                          convertValue={birthDate ? moment(birthDate, 'YYYY-MM-DD').format('DD-MM-YYYY') : ''}
                          onChange={this.onChangeField.bind(this, 'birthDate')}
                          borderBottomColor={VARIABLES.COLORS.GRAY_3}
                          fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_15}
                          fontColor={VARIABLES.COLORS.BLACK}
                          fieldIcon={
                            <Image
                              widthRatio='15'
                              widthRatioUnit='px'
                              heightRatio='16'
                              heightRatioUnit='px'
                              src={ICONS['ic-calendar.svg']}
                            />
                          }
                          fieldIconSpacingRight='0'
                        />
                        <Text
                          className='is-floating-label'
                          fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_15}
                          color={VARIABLES.COLORS.GRAY_2}
                        >
                          {getLanguage('field.birthDate')}
                        </Text>
                      </Section>
                      <Section spacingBottom='27' className='is-register-floating-label'>
                        <Field
                          fluid
                          // type='textarea'
                          height='28'
                          placeholder={getLanguage('field.addressPlaceholder')}
                          placeholderColor={VARIABLES.COLORS.GRAY_5}
                          paddingHorizontal='10'
                          paddingBottom='10'
                          bgColor={VARIABLES.COLORS.TRANSPARENT}
                          borderBottomWidth='1'
                          borderBottomStyle='solid'
                          borderBottomColor={VARIABLES.COLORS.GRAY_3}
                          fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_15}
                          fontColor={VARIABLES.COLORS.BLACK}
                          value={address}
                          onChange={this.onChangeField.bind(this, 'address')}
                        />
                        <Text
                          className='is-floating-label'
                          fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_15}
                          color={VARIABLES.COLORS.GRAY_2}
                        >
                          {getLanguage('field.address')}
                          {countries.toLowerCase() === ENUM.COUNTRY.MALAYSIA.toLowerCase() && ' (street)'}
                          <Text
                            fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_15}
                            color={VARIABLES.COLORS.RED_2}
                          >
                            *
                          </Text>
                        </Text>
                      </Section>
                      <Section spacingBottom='27' className='is-register-floating-label'>
                        {
                          countries.toLowerCase() === ENUM.COUNTRY.LAOS.toLowerCase() ?
                            <Field
                              fluid
                              height='28'
                              placeholder={getLanguage('field.provincePlaceholder')}
                              placeholderColor={VARIABLES.COLORS.GRAY_5}
                              paddingHorizontal='10'
                              paddingBottom='10'
                              bgColor={VARIABLES.COLORS.TRANSPARENT}
                              borderBottomWidth='1'
                              borderBottomStyle='solid'
                              borderBottomColor={VARIABLES.COLORS.GRAY_3}
                              fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_15}
                              fontColor={VARIABLES.COLORS.BLACK}
                              value={selectedProvince}
                              onChange={this.onChangeField.bind(this, 'selectedProvince')}
                            />
                            :
                            <React.Fragment>
                              {
                                countries.toLowerCase() === ENUM.COUNTRY.MALAYSIA.toLowerCase() ?
                                  <SelectControl
                                    isCreatable
                                    verticalLabel
                                    required={false}
                                    isSearchable
                                    placeholder={getLanguage('field.provincePlaceholder', '')}
                                    value={selectedProvince ? {
                                      label: selectedProvinceText,
                                      value: selectedProvince
                                    } : null}
                                    onChange={(selected) => {
                                      this.input.onChangeAddress(selected, 'selectedProvince')
                                    }}
                                    options={getCountryDataList()}
                                  />
                                  :
                                  <Field
                                    type='select'
                                    fluid
                                    height='32'
                                    paddingRight='36'
                                    paddingBottom='6'
                                    paddingLeft='7'
                                    bgColor={VARIABLES.COLORS.WHITE}
                                    borderBottomWidth='1'
                                    borderBottomStyle='solid'
                                    borderBottomColor={VARIABLES.COLORS.GRAY_3}
                                    fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_15}
                                    fontColor={VARIABLES.COLORS.BLACK}
                                    fieldIcon={
                                      <Image
                                        widthRatio='13'
                                        widthRatioUnit='px'
                                        heightRatio='8'
                                        heightRatioUnit='px'
                                        src={ICONS['ic-arrow-select.svg']}
                                      />
                                    }
                                    fieldIconWidth='36'
                                    fieldIconSpacingRight='0'
                                    value={selectedProvince}
                                    onChange={(selected) => {
                                      this.input.onChangeAddress(selected.target.selectedOptions[0], 'selectedProvince')
                                    }}
                                  >
                                    <SelectProvinceContainerConnected
                                      value={selectedProvince}
                                    />
                                  </Field>
                              }
                            </React.Fragment>
                        }
                        <Text
                          className='is-floating-label'
                          fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_15}
                          color={VARIABLES.COLORS.GRAY_2}
                        >
                          {getLanguage('field.province')}
                          {countries.toLowerCase() === ENUM.COUNTRY.THAI.toLowerCase() && ' (จังหวัด)'}
                          <Text
                            fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_15}
                            color={VARIABLES.COLORS.RED_2}
                          >
                            *
                          </Text>
                        </Text>
                      </Section>
                      <Section spacingBottom='27' className='is-register-floating-label'>
                        {
                          countries.toLowerCase() === ENUM.COUNTRY.LAOS.toLowerCase() ?
                            <Field
                              fluid
                              height='28'
                              placeholder={getLanguage('field.cityPlaceholder')}
                              placeholderColor={VARIABLES.COLORS.GRAY_5}
                              paddingHorizontal='10'
                              paddingBottom='10'
                              bgColor={VARIABLES.COLORS.TRANSPARENT}
                              borderBottomWidth='1'
                              borderBottomStyle='solid'
                              borderBottomColor={VARIABLES.COLORS.GRAY_3}
                              fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_15}
                              fontColor={VARIABLES.COLORS.BLACK}
                              value={selectedCity}
                              onChange={this.onChangeField.bind(this, 'selectedCity')}
                            />
                            :
                            <React.Fragment>
                              {
                                countries.toLowerCase() === ENUM.COUNTRY.MALAYSIA.toLowerCase() ?
                                  <SelectControl
                                    isCreatable
                                    verticalLabel
                                    required={false}
                                    isSearchable
                                    placeholder={getLanguage('field.cityPlaceholder', '')}
                                    value={selectedCity ? {
                                      label: selectedCityText,
                                      value: selectedCity
                                    } : null}
                                    onChange={(selected) => {
                                      this.input.onChangeAddress(selected, 'selectedCity')
                                    }}
                                    options={getCityListByProvince(selectedProvince)}
                                  />
                                  :
                                  <Field
                                    type='select'
                                    fluid
                                    height='32'
                                    paddingRight='36'
                                    paddingBottom='6'
                                    paddingLeft='7'
                                    bgColor={VARIABLES.COLORS.WHITE}
                                    borderBottomWidth='1'
                                    borderBottomStyle='solid'
                                    borderBottomColor={VARIABLES.COLORS.GRAY_3}
                                    fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_15}
                                    fontColor={VARIABLES.COLORS.BLACK}
                                    fieldIcon={
                                      <Image
                                        widthRatio='13'
                                        widthRatioUnit='px'
                                        heightRatio='8'
                                        heightRatioUnit='px'
                                        src={ICONS['ic-arrow-select.svg']}
                                      />
                                    }
                                    fieldIconWidth='36'
                                    fieldIconSpacingRight='0'
                                    value={selectedCity}
                                    onChange={(selected) => {
                                      this.input.onChangeAddress(selected.target.selectedOptions[0], 'selectedCity');
                                    }}
                                  >
                                    <SelectCityContainerConnected
                                      value={selectedCity}
                                      valueProvince={selectedProvince}
                                    />
                                  </Field>
                              }
                            </React.Fragment>
                        }
                        <Text
                          className='is-floating-label'
                          fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_15}
                          color={VARIABLES.COLORS.GRAY_2}
                        >
                          {getLanguage('field.city')}
                          {countries.toLowerCase() === ENUM.COUNTRY.THAI.toLowerCase() && ' (อำเภอ)'}
                          <Text
                            fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_15}
                            color={VARIABLES.COLORS.RED_2}
                          >
                            *
                          </Text>
                        </Text>
                      </Section>
                      {(countries.toLowerCase() === ENUM.COUNTRY.VIETNAM.toLowerCase() || countries.toLowerCase() === ENUM.COUNTRY.INDONESIA.toLowerCase()) &&
                        <Section spacingBottom='27' className='is-register-floating-label'>
                          <Field
                            fluid
                            height='28'
                            placeholder={getLanguage('field.districtPlaceholder')}
                            placeholderColor={VARIABLES.COLORS.GRAY_5}
                            paddingHorizontal='10'
                            paddingBottom='10'
                            bgColor={VARIABLES.COLORS.TRANSPARENT}
                            borderBottomWidth='1'
                            borderBottomStyle='solid'
                            borderBottomColor={VARIABLES.COLORS.GRAY_3}
                            fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_15}
                            fontColor={VARIABLES.COLORS.BLACK}
                            value={district}
                            onChange={this.onChangeField.bind(this, 'district')}
                          />
                          <Text
                            className='is-floating-label'
                            fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_15}
                            color={VARIABLES.COLORS.GRAY_2}
                          >
                            {getLanguage('field.district')}
                            {countries.toLowerCase() === ENUM.COUNTRY.INDONESIA.toLowerCase() && ' (Kecamatan)'}
                            <Text
                              fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_15}
                              color={VARIABLES.COLORS.RED_2}
                            >
                              *
                            </Text>
                          </Text>
                        </Section>
                      }
                      {(countries.toLowerCase() === ENUM.COUNTRY.THAI.toLowerCase() || countries.toLowerCase() === ENUM.COUNTRY.LAOS.toLowerCase() || countries.toLowerCase() === ENUM.COUNTRY.INDONESIA.toLowerCase()) &&
                        <Section spacingBottom='27' className='is-register-floating-label'>
                          {/* RL-707 : Registration > Change input text to select */}
                          {/* TODO: laos */}
                          {countries.toLowerCase() === ENUM.COUNTRY.THAI.toLowerCase() ?
                            <React.Fragment>
                              <Field
                                type='select'
                                fluid
                                height='32'
                                paddingRight='36'
                                paddingBottom='6'
                                paddingLeft='7'
                                bgColor={VARIABLES.COLORS.WHITE}
                                borderBottomWidth='1'
                                borderBottomStyle='solid'
                                borderBottomColor={VARIABLES.COLORS.GRAY_3}
                                fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_15}
                                fontColor={VARIABLES.COLORS.BLACK}
                                fieldIcon={
                                  <Image
                                    widthRatio='13'
                                    widthRatioUnit='px'
                                    heightRatio='8'
                                    heightRatioUnit='px'
                                    src={ICONS['ic-arrow-select.svg']}
                                  />
                                }
                                fieldIconWidth='36'
                                fieldIconSpacingRight='0'
                                value={subDistrict}
                                onChange={(selected) => {
                                  this.input.onChangeAddress(selected.target.selectedOptions[0], 'subDistrict');
                                }}
                              >
                                <SelectSubDistrictContainerConnected
                                  value={subDistrict}
                                  valueCity={selectedCity}
                                  valueProvince={selectedProvince}
                                />
                              </Field>
                              <Text
                                className='is-floating-label'
                                fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_15}
                                color={VARIABLES.COLORS.GRAY_2}
                              >
                                {getLanguage('field.subDistrict')}
                                {countries.toLowerCase() === ENUM.COUNTRY.THAI.toLowerCase() && ' (ตำบล)'}
                                {countries.toLowerCase() === ENUM.COUNTRY.INDONESIA.toLowerCase() && ' (Kelurahan Bahasa)'}
                                <Text
                                  fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_15}
                                  color={VARIABLES.COLORS.RED_2}
                                >
                                  *
                                </Text>
                              </Text>
                            </React.Fragment>
                            :
                            <React.Fragment>
                              <Field
                                fluid
                                height='28'
                                placeholder={getLanguage('field.subDistrictPlaceholder')}
                                // for thailand >>> Insert sub-district (ตำบล)
                                // for indonesia >>> Insert sub-district (Kelurahan Bahasa)
                                placeholderColor={VARIABLES.COLORS.GRAY_5}
                                paddingHorizontal='10'
                                paddingBottom='10'
                                bgColor={VARIABLES.COLORS.TRANSPARENT}
                                borderBottomWidth='1'
                                borderBottomStyle='solid'
                                borderBottomColor={VARIABLES.COLORS.GRAY_3}
                                fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_15}
                                fontColor={VARIABLES.COLORS.BLACK}
                                // name=''
                                value={subDistrict}
                                onChange={this.onChangeField.bind(this, 'subDistrict')}
                              />
                              <Text
                                className='is-floating-label'
                                fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_15}
                                color={VARIABLES.COLORS.GRAY_2}
                              >
                                {getLanguage('field.subDistrict')}
                                {this.state.countries.toLowerCase() === ENUM.COUNTRY.THAI.toLowerCase() && ' (ตำบล)'}
                                {this.state.countries.toLowerCase() === ENUM.COUNTRY.INDONESIA.toLowerCase() && ' (Kelurahan Bahasa)'}
                                <Text
                                  fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_15}
                                  color={VARIABLES.COLORS.RED_2}
                                >
                                  *
                                </Text>
                              </Text>
                            </React.Fragment>
                          }
                        </Section>
                      }
                      {countries.toLowerCase() === ENUM.COUNTRY.VIETNAM.toLowerCase() &&
                        <React.Fragment>
                          <Section spacingBottom='27' className='is-register-floating-label'>
                            <Field
                              fluid
                              height='28'
                              placeholder={getLanguage('field.alleyPlaceholder')}
                              placeholderColor={VARIABLES.COLORS.GRAY_5}
                              paddingHorizontal='10'
                              paddingBottom='10'
                              bgColor={VARIABLES.COLORS.TRANSPARENT}
                              borderBottomWidth='1'
                              borderBottomStyle='solid'
                              borderBottomColor={VARIABLES.COLORS.GRAY_3}
                              fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_15}
                              fontColor={VARIABLES.COLORS.BLACK}
                              value={alley}
                              onChange={this.onChangeField.bind(this, 'alley')}
                            />
                            <Text
                              className='is-floating-label'
                              fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_15}
                              color={VARIABLES.COLORS.GRAY_2}
                            >
                              {getLanguage('field.alley')}
                              <Text
                                fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_15}
                                color={VARIABLES.COLORS.RED_2}
                              >
                                *
                              </Text>
                            </Text>
                          </Section>
                          <Section spacingBottom='27' className='is-register-floating-label'>
                            <Field
                              fluid
                              height='28'
                              placeholder={getLanguage('field.groupPlaceholder')}
                              placeholderColor={VARIABLES.COLORS.GRAY_5}
                              paddingHorizontal='10'
                              paddingBottom='10'
                              bgColor={VARIABLES.COLORS.TRANSPARENT}
                              borderBottomWidth='1'
                              borderBottomStyle='solid'
                              borderBottomColor={VARIABLES.COLORS.GRAY_3}
                              fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_15}
                              fontColor={VARIABLES.COLORS.BLACK}
                              value={group}
                              onChange={this.onChangeField.bind(this, 'group')}
                            />
                            <Text
                              className='is-floating-label'
                              fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_15}
                              color={VARIABLES.COLORS.GRAY_2}
                            >
                              {getLanguage('field.group')}
                              <Text
                                fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_15}
                                color={VARIABLES.COLORS.RED_2}
                              >
                                *
                              </Text>
                            </Text>
                          </Section>
                          <Section spacingBottom='27' className='is-register-floating-label'>
                            <Field
                              fluid
                              height='28'
                              placeholder={getLanguage('field.streetPlaceholder')}
                              placeholderColor={VARIABLES.COLORS.GRAY_5}
                              paddingHorizontal='10'
                              paddingBottom='10'
                              bgColor={VARIABLES.COLORS.TRANSPARENT}
                              borderBottomWidth='1'
                              borderBottomStyle='solid'
                              borderBottomColor={VARIABLES.COLORS.GRAY_3}
                              fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_15}
                              fontColor={VARIABLES.COLORS.BLACK}
                              value={street}
                              onChange={this.onChangeField.bind(this, 'street')}
                            />
                            <Text
                              className='is-floating-label'
                              fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_15}
                              color={VARIABLES.COLORS.GRAY_2}
                            >
                              {getLanguage('field.street')}
                              <Text
                                fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_15}
                                color={VARIABLES.COLORS.RED_2}
                              >
                                *
                              </Text>
                            </Text>
                          </Section>
                          <Section spacingBottom='27' className='is-register-floating-label'>
                            <Field
                              fluid
                              height='28'
                              placeholder={getLanguage('field.wardPlaceholder')}
                              placeholderColor={VARIABLES.COLORS.GRAY_5}
                              paddingHorizontal='10'
                              paddingBottom='10'
                              bgColor={VARIABLES.COLORS.TRANSPARENT}
                              borderBottomWidth='1'
                              borderBottomStyle='solid'
                              borderBottomColor={VARIABLES.COLORS.GRAY_3}
                              fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_15}
                              fontColor={VARIABLES.COLORS.BLACK}
                              value={ward}
                              onChange={this.onChangeField.bind(this, 'ward')}
                            />
                            <Text
                              className='is-floating-label'
                              fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_15}
                              color={VARIABLES.COLORS.GRAY_2}
                            >
                              {getLanguage('field.ward')}
                              <Text
                                fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_15}
                                color={VARIABLES.COLORS.RED_2}
                              >
                                *
                              </Text>
                            </Text>
                          </Section>
                        </React.Fragment>
                      }
                      <Section spacingBottom='27' className='is-register-floating-label'>
                        {/* RL-707 : Registration > Change input text to select */}
                        {/* TODO: laos */}
                        {countries.toLowerCase() === ENUM.COUNTRY.THAI.toLowerCase() ?
                          <React.Fragment>
                            <Field
                              type='select'
                              fluid
                              height='32'
                              paddingRight='36'
                              paddingBottom='6'
                              paddingLeft='7'
                              bgColor={VARIABLES.COLORS.WHITE}
                              borderBottomWidth='1'
                              borderBottomStyle='solid'
                              borderBottomColor={VARIABLES.COLORS.GRAY_3}
                              fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_15}
                              fontColor={VARIABLES.COLORS.BLACK}
                              fieldIcon={
                                <Image
                                  widthRatio='13'
                                  widthRatioUnit='px'
                                  heightRatio='8'
                                  heightRatioUnit='px'
                                  src={ICONS['ic-arrow-select.svg']}
                                />
                              }
                              fieldIconWidth='36'
                              fieldIconSpacingRight='0'
                              value={postCode}
                              onChange={(selected) => {
                                this.input.onChangeAddress(selected.target.selectedOptions[0], 'postCode');
                              }}
                            >
                              <SelectPostCodeContainerConnected
                                value={postCode}
                                valueSubDistrict={subDistrict}
                                valueCity={selectedCity}
                                valueProvince={selectedProvince}
                              />
                            </Field>
                            <Text
                              className='is-floating-label'
                              fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_15}
                              color={VARIABLES.COLORS.GRAY_2}
                            >
                              {getLanguage('field.postCode')}
                              <Text
                                fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_15}
                                color={VARIABLES.COLORS.RED_2}
                              >
                                *
                              </Text>
                            </Text>
                          </React.Fragment>
                          :
                          <React.Fragment>
                            <Field
                              fluid
                              height='28'
                              placeholder={getLanguage('field.postCodePlaceholder')}
                              placeholderColor={VARIABLES.COLORS.GRAY_5}
                              paddingHorizontal='10'
                              paddingBottom='10'
                              bgColor={VARIABLES.COLORS.TRANSPARENT}
                              borderBottomWidth='1'
                              borderBottomStyle='solid'
                              borderBottomColor={VARIABLES.COLORS.GRAY_3}
                              fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_15}
                              fontColor={VARIABLES.COLORS.BLACK}
                              value={postCode}
                              onChange={this.onChangeField.bind(this, 'postCode')}
                            />
                            <Text
                              className='is-floating-label'
                              fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_15}
                              color={VARIABLES.COLORS.GRAY_2}
                            >
                              {getLanguage('field.postCode')}
                              <Text
                                fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_15}
                                color={VARIABLES.COLORS.RED_2}
                              >
                                *
                              </Text>
                            </Text>
                          </React.Fragment>
                        }
                      </Section>
                      <Section spacingBottom='27' className='is-register-floating-label'>
                        <Field
                          fluid
                          readonly
                          height='28'
                          placeholderColor={VARIABLES.COLORS.GRAY_5}
                          paddingHorizontal='10'
                          paddingBottom='10'
                          bgColor={VARIABLES.COLORS.TRANSPARENT}
                          borderBottomWidth='1'
                          borderBottomStyle='solid'
                          borderBottomColor={VARIABLES.COLORS.BLUE_2}
                          fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_15}
                          fontColor={VARIABLES.COLORS.BLUE_2}
                          value={capitalizeFirstLetter(countries)}
                        />
                        <Text
                          className='is-floating-label'
                          fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_15}
                          color={VARIABLES.COLORS.GRAY_2}
                        >
                          {getLanguage('field.country')}
                        </Text>
                      </Section>
                      <Section justify='center'>
                        <Button
                          shadow
                          className='is-gradient-blue is-icon-right'
                          width='275'
                          height={VARIABLES.BUTTON.HEIGHTS.H_40}
                          borderRadius={VARIABLES.BUTTON.BORDER_RADIUSES.BR_10}
                          onClick={this.handleSubmit.bind(this)}
                        >
                          <Button.Icon>
                            <Image
                              widthRatio='15'
                              widthRatioUnit='px'
                              heightRatio='12'
                              heightRatioUnit='px'
                              src={ICONS['ic-arrow-right.svg']}
                            />
                          </Button.Icon>
                          <Text
                            fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_16}
                            color={VARIABLES.COLORS.WHITE}
                          >
                            {isUpdateUserData ? getLanguage('field.buttonConfirm') : getLanguage('field.buttonSignUp')}
                          </Text>
                        </Button>
                      </Section>
                    </Section>
                  </Section>
                </Container>
              </TransitionContainer>
            </React.Fragment>
        }
        {this.state.showModal ? <AlertModal topic={this.state.topic} msg={this.state.msg} closeModal={this.closeAlert.bind(this)} /> : ''}
        {<Loader loading={this.state.isLoading} isOverlay={true} />}

        {
          isOpenAlertOtp &&
          <Alert open type='send'
            title={getLanguage('modal.otpSend.title')}
            description={getLanguage('modal.otpSend.description')}
            onClose={this.otp.onCloseAlertOtp}
          />
        }
      </React.Fragment>
    )

  }
}
