import { getProvince, getCity, getSubDistrictList, getSubDistrictValueLanguage } from "../helpers/functions/data";
import { ENUM } from "../helpers/constants/enum";

export class ModelAddress {
  constructor(props = {}) {
    props = props || {};
    // const countryList = storeGetState().dataRedux.countryList;
    // let selectedProvince;
    // let selectedCity;
    // if (props.province) {
    //   selectedProvince = countryList.filter(e => e.provinceCode.toString() === props.province.toString())[0];
    //   if (selectedProvince) {
    //     selectedCity = selectedProvince.cityList.filter(e => e.cityCode.toString() === props.city.toString())[0];
    //   }
    // }
    this.address = props.address_text;
    // this.selectedProvince = selectedProvince;
    // this.selectedCity = selectedCity;
    this.selectedProvince = props.address_type === ENUM.COUNTRY_TYPE.LAOS ? props.province : (getProvince(props.province) || {}).value;
    this.selectedCity = props.address_type === ENUM.COUNTRY_TYPE.LAOS ? props.city : (getCity(props.city) || {}).value;
    this.selectedProvinceText = props.address_type === ENUM.COUNTRY_TYPE.LAOS ? props.province : (getProvince(props.province) || {}).label;
    this.selectedCityText = props.address_type === ENUM.COUNTRY_TYPE.LAOS ? props.city : (getCity(props.city) || {}).label;
    this.district = props.district;
    this.subDistrict = props.sub_district;
    this.alley = props.alley;
    this.group = props.group;
    this.street = props.street;
    this.ward = props.ward;
    this.addressType = props.address_type;

    this.subDistrict = getSubDistrictValueLanguage(this.selectedProvince, this.selectedCity, this.subDistrict);
  }
}