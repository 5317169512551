import React from 'react'
import axios from 'axios'
import _ from 'lodash'
import {
  TransitionContainer,
  Container,
  Section,
  Image,
  NavigationBar,
  CommentList,
} from './../../components'
import {
  VARIABLES,
  ICONS,
} from './../../themes'
import {
  ROUTE_PATH,
  redirect,
  formatDatetime,
} from './../../helpers'
import {
  Loader
} from '../Loader'

export class FindDealerDetailCommentPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      id: (this.props.match.params && this.props.match.params.id) ? +this.props.match.params.id : '',
      list: [],
      isLoading: false,
    }
  }

  handleBack() {
    const {
      id,
    } = this.state;
    redirect(`${ROUTE_PATH.FIND_DEALER_LIST.LINK}/${id}`);
  }

  async componentWillMount() {
    const { id } = this.state
    this.setState({ isLoading: true }, async () => {
      try {
        const url = '/api/fdreview/get'
        const options = { ad_id: id, sort: 'create_at desc' }
        const response = await axios.post(url, options)
        const data = _.get(response, 'data')
        if (response.status === 200 && data.length) {
          this.setState({ list: data })
        }
      } catch (_) {
      } finally {
        this.setState({ isLoading: false })
      }
    })
  }

  render() {
    const { isLoading, list } = this.state
    return (
      <TransitionContainer>
        <Container
          width={VARIABLES.WEBSITE_WIDTH}
          calcMinHeight='100vh'
          bgColor={VARIABLES.COLORS.WHITE}
        >
          <NavigationBar
            shadow
            pageTitle='Comments'
            actionLeft={
              <Image
                widthRatio='11'
                widthRatioUnit='px'
                heightRatio='21'
                heightRatioUnit='px'
                src={ICONS['ic-left-black.svg']}
                onClick={this.handleBack.bind(this)}
              />
            }
          />
          <Section
            scroll
            calcHeight='100vh - 70px'
            paddingTop='15'
            paddingBottom='25'
          >
            {!_.isEmpty(list) && list.map((data, index) => {
              return <CommentList
                key={index}
                userAvatar={data.image_url ? data.image_url : ICONS['ic-placeholder-avatar.svg']}
                username={`${data.first_name} ${data.last_name}`}
                date={formatDatetime(data.create_at, 'DD/MM/YYYY')}
                commentTitle={data.title}
                comment={data.review}
                rating={Math.floor(parseFloat(data.rating))}
              />
            })}
            {/* <CommentList
              userAvatar={CONTENTS['user-comment-1.jpg']}
              username='Liza Hall'
              date='01/02/2019'
              commentTitle='Awesome!!!'
              comment='Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s. Lorem Ipsum is simply dummy text of the printing '
              rating='4'
            />
            <CommentList
              userAvatar={CONTENTS['user-comment-2.jpg']}
              username='Carolyne Grat'
              date='01/02/2019'
              commentTitle='Very Good'
              comment='Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s. Lorem Ipsum is simply dummy text of the printing '
              rating='4'
            />
            <CommentList
              userAvatar={CONTENTS['user-comment-3.jpg']}
              username='Ethan Lin'
              date='01/02/2019'
              commentTitle='Goodjob'
              comment='Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s. Lorem Ipsum is simply dummy text of the printing '
              rating='4'
            />
            <CommentList
              userAvatar={CONTENTS['user-comment-4.jpg']}
              username='Kristin Tomson'
              date='01/02/2019'
              commentTitle='Awesome!!!'
              comment='Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s. Lorem Ipsum is simply dummy text of the printing '
              rating='4'
            /> */}
          </Section>
        </Container>
        <Loader loading={isLoading} isOverlay />
      </TransitionContainer>
    )
  }
}
