import React from 'react'
import axios from 'axios'
import _ from 'lodash'
import qs from 'qs'
import {
  TransitionContainer,
  Container,
  Section,
  Image,
  Text,
  Button,
  NavigationBar,
  Field,
  Alert,
} from './../../components'
import {
  VARIABLES,
  TYPOGRAPHYS,
  ICONS,
} from './../../themes'
import {
  ROUTE_PATH,
  redirect,
} from './../../helpers'
import {
  Loader
} from '../Loader';
import { LOCAL_STORAGE } from '../../helpers/constants/localStorage';
import { getLanguage } from '../../helpers/functions/language'
import config from '../../config'
import { firebaseSendOrderInquiry } from '../../helpers/firebases/firebaseNotification'
import { createOrderInquiry } from '../../helpers/services/orderInquiry'

export class OrderInquiryAddPage extends React.Component {
  constructor(props) {
    super(props)
    const query = qs.parse(_.get(this.props, 'location.search', '').replace('?', ''))
    const user = JSON.parse(localStorage.getItem(LOCAL_STORAGE.REGISTER_INFO))
    this.state = {
      id: (this.props.match.params && this.props.match.params.id) ? +this.props.match.params.id : '',
      dealerIdSelected: query.dealerId,
      dealerTypeSelected: query.dealerType,
      dealerRefSelected: query.dealerRef,
      subject: '',
      description: '',
      userId: _.get(user, 'id'),
      showModal: false,
      titleModal: '',
      descriptionModal: '',
      typeModal: '',
      isLoading: false,
    }
  }

  onChangeField(key, event) {
    this.setState({ [key]: event.target.value })
  }

  onSubmit() {
    const {
      subject,
      description,
      userId,
      dealerIdSelected,
      dealerRefSelected
    } = this.state
    this.setState({ isLoading: true }, async () => {
      try {
        const resValidateData = this.validateData()
        if (resValidateData.isError) {
          this.setState({ showModal: true, typeModal: 'error', titleModal: '', descriptionModal: resValidateData.msg })
          return
        }
        // const url = `${config.api.base}/api/orderinq/` + userId;
        const options = {
          subject,
          description,
          ad_id: dealerIdSelected,
          ad_ref_id: dealerRefSelected,
        };
        // const response = await axios.post(url, options)
        const params = {
          user_id: userId,
          ...options,
        }
        const response = await createOrderInquiry(params)
        if (response) {
          // if (response.status === 200) {
          await firebaseSendOrderInquiry({
            adId: dealerRefSelected
          })
          this.setState({
            showModal: true,
            typeModal: 'success',
            titleModal: 'Successful',
            descriptionModal: getLanguage('message.yourOrderInquiryHasBeenSent')
          })
        } else {
          this.showModalError()
        }
      } catch (_) {
        this.showModalError()
      } finally {
        this.setState({ isLoading: false })
      }
    })
  }

  showModalError() {
    this.setState({ showModal: true, typeModal: 'error', titleModal: '', descriptionModal: 'Something went wrong, Please try again later' })
  }

  validateData() {
    const { subject, description } = this.state
    if (!subject) return {
      isError: true,
      msg: getLanguage('message.pleaseSpecifySubject')
    }
    if (!description) return {
      isError: true,
      msg: getLanguage('message.pleaseSpecifyDescription')
    }
    return { isError: false, msg: '' }
  }

  onCloseModal() {
    this.setState({ showModal: false, typeModal: '' })
  }

  redirectToOrderPage() {
    const {
      dealerTypeSelected,
      dealerIdSelected,
      dealerRefSelected
    } = this.state
    redirect(`${ROUTE_PATH.ORDER_INQUIRY.LINK}?dealerType=${dealerTypeSelected}&dealerId=${dealerIdSelected}&dealerRef=${dealerRefSelected}`)
  }

  render() {
    const {
      subject,
      description,
      showModal,
      titleModal,
      descriptionModal,
      typeModal,
      isLoading,
    } = this.state

    return (
      <TransitionContainer>
        <Container
          width={VARIABLES.WEBSITE_WIDTH}
          calcMinHeight='100vh'
          bgColor={VARIABLES.COLORS.WHITE}
        >
          <NavigationBar
            shadow
            pageTitle={getLanguage('orderInquiryAdd.title')}
            actionLeft={
              <Image
                widthRatio='11'
                widthRatioUnit='px'
                heightRatio='21'
                heightRatioUnit='px'
                src={ICONS['ic-left-black.svg']}
                onClick={this.redirectToOrderPage.bind(this)}
              />
            }
          />
          <Section
            scroll
            calcHeight='100vh - 70px'
            paddingTop='30'
            paddingBottom='30'
            paddingHorizontal='20'
          >
            <Section spacingBottom='20'>
              <Field
                fluid
                floatLabel
                height='40'
                label={getLanguage('field.subject')}
                labelFontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_12}
                labelFontColor={VARIABLES.COLORS.GRAY_3}
                placeholder={getLanguage('field.subjectPlaceholder')}
                placeholderColor={VARIABLES.COLORS.GRAY_5}
                paddingHorizontal='10'
                borderWidth='1'
                borderStyle='solid'
                borderColor={VARIABLES.COLORS.GRAY_5}
                borderRadius='5'
                fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_14}
                fontColor={VARIABLES.COLORS.BLACK}
                value={subject}
                onChange={this.onChangeField.bind(this, 'subject')}
              />
            </Section>
            <Section spacingBottom='41'>
              <Field
                fluid
                floatLabel
                type='textarea'
                height='155'
                label={getLanguage('field.description')}
                labelFontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_12}
                labelFontColor={VARIABLES.COLORS.GRAY_3}
                placeholder={getLanguage('field.descriptionPlaceholder')}
                placeholderColor={VARIABLES.COLORS.GRAY_5}
                paddingTop='12'
                paddingHorizontal='10'
                borderWidth='1'
                borderStyle='solid'
                borderColor={VARIABLES.COLORS.GRAY_5}
                borderRadius='5'
                fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_14}
                fontColor={VARIABLES.COLORS.BLACK}
                value={description}
                onChange={this.onChangeField.bind(this, 'description')}
              />
            </Section>
            <Section justify='center'>
              <Button
                shadow
                className='is-gradient-blue'
                width='275'
                height={VARIABLES.BUTTON.HEIGHTS.H_40}
                borderRadius={VARIABLES.BUTTON.BORDER_RADIUSES.BR_10}
                onClick={this.onSubmit.bind(this)}
              >
                <Text
                  fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_16}
                  color={VARIABLES.COLORS.WHITE}
                >
                  {getLanguage('field.buttonSubmit')}
                </Text>
              </Button>
            </Section>
          </Section>
        </Container>
        <Alert
          open={showModal}
          type={typeModal}
          title={titleModal}
          description={descriptionModal}
          onClose={typeModal === 'success' ? this.redirectToOrderPage.bind(this) : this.onCloseModal.bind(this)}
        />
        <Loader loading={isLoading} isOverlay />
      </TransitionContainer >
    )
  }
}
