import { COUNTRY_THAI } from "./countryThai";
import { COUNTRY_THAI_EN } from "./countryThaiEN";
import { COUNTRY_MALAYSIA } from "./countryMalaysia";
import { COUNTRY_VIETNAM } from "./countryVietnam";
import { COUNTRY_INDONESIA } from "./countryIndonesia";

export const COUNTRY = {
  THAI: COUNTRY_THAI,
  THAI_EN: COUNTRY_THAI_EN,
  MALAYSIA: COUNTRY_MALAYSIA,
  VIETNAM: COUNTRY_VIETNAM,
  INDONESIA: COUNTRY_INDONESIA,
}