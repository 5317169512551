import React from 'react'
import { isEmpty } from 'lodash';
import {
  Section,
  Text,
} from '../../components'
import {
  VARIABLES,
  TYPOGRAPHYS,
} from './../../themes'
import {
  WarrantyItem,
} from '../WarrantyPage/WarrantyItem'
import {
  WarrantyProductList,
} from '../WarrantyProductPage/WarrantyProductList'
import { getLanguage } from '../../helpers/functions/language';

export class SearchResult extends React.Component {
  render() {
    const { list, searchType } = this.props
    return (
      <Section
        paddingTop='10'
        paddingBottom='25'
      >
        <Section spacingBottom='10'>
          <Text
            fontStyle={TYPOGRAPHYS.FIRST.BOLD.FS_14}
            color={VARIABLES.COLORS.BLACK}
          >
            {getLanguage('warrantySearchByName.searchResult')} : {list.length}
          </Text>
        </Section>
        {searchType === 'warranty' && !isEmpty(list) && (list).map((data, index) => (<WarrantyItem key={index} data={data} />))}
        {searchType === 'warrantyProduct' && !isEmpty(list) && <WarrantyProductList list={list} />}
      </Section>
    )
  }
}
