/* eslint-disable no-empty-pattern */
import { projectController } from "../../apiService/apiController/projectService";
import { isValidResponse } from "../functions";
import _ from "lodash";
import { handleTrimParams } from "../functions/data";
import { ModelProject, ModelUploadImgProject } from "../../models/ModelProject";

export const getProjectByUserID = async (params) => {
  const projectService = projectController();
  const res = await projectService.getProjectByUserID(params);
  if (isValidResponse(res)) {
    let dataList = res.data.map((e) => new ModelProject(e));
    console.log('getProjectByUserID', res.data, dataList);
    return dataList;
  }
  return [];
}

export const getsearchProjectByUserId = async (params) => {
  const projectService = projectController();
  let newParams = handleTrimParams({
    ...params
  })
  const res = await projectService.getsearchProjectByUserId(newParams);
  if (isValidResponse(res)) {
    let dataList = res.data;
    console.log('getsearchProjectByUserId', res, dataList);
    return dataList;
  }
  return [];
}

export const projectUpload = async (params, { }) => {
  const projectService = projectController();
  const res = await projectService.projectUpload(params, {});
  if (isValidResponse(res)) {
    let data = new ModelUploadImgProject(res.data);
    console.log('projectUpload', res.data, data);
    return data;
  }
}

export const createProjectById = async (params) => {
  const projectService = projectController();
  const res = await projectService.createProjectById(params);
  if (isValidResponse(res)) {
    let data = res.data;
    console.log('createProjectById', res.data, data)
    return data
  }
}

export const updateProjectById = async (params) => {
  const projectService = projectController();
  const res = await projectService.updateProjectById(params);
  if (isValidResponse(res)) {
    let data = res.data;
    console.log('updateProjectById', res.data, data)
    return data
  }
}

export const deleteProjectById = async (params) => {
  const projectService = projectController();
  const res = await projectService.deleteProjectById(params)
  if (isValidResponse(res)) {
    let data = res.data;
    console.log('deleteProjectById', res, data)
    return true;
  }
  return false;
}