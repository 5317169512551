import styled, {
  // css
} from 'styled-components'
// import {
//   default as VARIABLES
// } from './../../../themes/styles/bases/variables'
// import {
//   default as TYPOGRAPHYS
// } from './../../../themes/styles/bases/typographys'
// import {
//   default as MIXINS
// } from './../../../themes/styles/helpers/mixins'

// Sample using image in style
// url(${require('./images/icons/icon-sample.svg')});
// url(${require('./../../../themes/images/icons/icon-sample.svg')});

// Wrapper
// ============================================================
export const PaginationControlBaseWrapper = styled.div`
  /* Parent
  ------------------------------- */
  /* Childrens
  ------------------------------- */
  .pagination-control-container-base {
    display: flex;
    align-items: center;

    .pagination-control-item-base {
      display: block;

      a {
        transition: all 0.3s ease;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 24px;
        height: 24px;
        background-color: #FFFFFF;
        border: 1px solid #CCCCCC;
        border-right-width: 0;
        font-weight: normal;
        text-transform: none;
        color: #333333;
      }

      &.is-first,
      &.is-prev,
      &.is-next,
      &.is-last {
        a {}
      }

      &.is-prev,
      &.is-next {
        position: relative;

        a {
          font-size: 0;

          &:before {
            content: ' ';
            display: block;
          }
        }

        /* Arrow */
        &:before,
        &:after {
          content: ' ';
          pointer-events: none;
          position: absolute;
          top: 50%;
          width: 0;
          height: 0;
          border: solid transparent;
          border-color: transparent;
        }

        &:before {
          border-width: 5px;
          margin-top: -5px;
        }

        &:after {
          border-width: 4px;
          margin-top: -4px;
        }
      }

      &.is-first,
      &.is-last {
        a {
          width: auto;
          padding-right: 5px;
          padding-left: 5px;
        }
      }

      &.is-prev {
        /* Arrow */
        &:before,
        &:after {
          right: 10px;
        }

        &:before {
          border-right-color: #333333;
        }

        &:after {
          border-right-color: #FFFFFF;
        }
      }

      &.is-next {
        /* Arrow */
        &:before,
        &:after {
          left: 10px;
        }

        &:before {
          border-left-color: #333333;
        }

        &:after {
          border-left-color: #FFFFFF;
        }
      }

      &.is-last {
        a {
          border-right-width: 1px;
        }
      }

      &.is-active {
        a {
          background-color: #999999;
          color: #FFFFFF;
        }
      }

      &.is-disabled {
        &.is-first,
        &.is-prev,
        &.is-next,
        &.is-last {
          a {
            pointer-events: none;
            color: #AAAAAA;
          }
        }

        &.is-prev {
          &:before {
            border-right-color: #AAAAAA;
          }
        }

        &.is-next {
          &:before {
            border-left-color: #AAAAAA;
          }
        }
      }
    }
  }

  /* Modifiers
  ------------------------------- */
  /* Media queries
  ------------------------------- */
`
