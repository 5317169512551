export const COUNTRY_THAI = {
  //state
  PROVINCE_LIST: [
    {
      "province": "กระบี่",
      "provinceCode": 81
    },
    {
      "province": "กรุงเทพมหานคร",
      "provinceCode": 10
    },
    {
      "province": "กาญจนบุรี",
      "provinceCode": 71
    },
    {
      "province": "กาฬสินธุ์",
      "provinceCode": 46
    },
    {
      "province": "กำแพงเพชร",
      "provinceCode": 62
    },
    {
      "province": "ขอนแก่น",
      "provinceCode": 40
    },
    {
      "province": "จันทบุรี",
      "provinceCode": 22
    },
    {
      "province": "ฉะเชิงเทรา",
      "provinceCode": 24
    },
    {
      "province": "ชลบุรี",
      "provinceCode": 20
    },
    {
      "province": "ชัยนาท",
      "provinceCode": 18
    },
    {
      "province": "ชัยภูมิ",
      "provinceCode": 36
    },
    {
      "province": "ชุมพร",
      "provinceCode": 86
    },
    {
      "province": "ตรัง",
      "provinceCode": 92
    },
    {
      "province": "ตราด",
      "provinceCode": 23
    },
    {
      "province": "ตาก",
      "provinceCode": 63
    },
    {
      "province": "นครนายก",
      "provinceCode": 26
    },
    {
      "province": "นครปฐม",
      "provinceCode": 73
    },
    {
      "province": "นครพนม",
      "provinceCode": 48
    },
    {
      "province": "นครราชสีมา",
      "provinceCode": 30
    },
    {
      "province": "นครศรีธรรมราช",
      "provinceCode": 80
    },
    {
      "province": "นครสวรรค์",
      "provinceCode": 60
    },
    {
      "province": "นนทบุรี",
      "provinceCode": 12
    },
    {
      "province": "นราธิวาส",
      "provinceCode": 96
    },
    {
      "province": "น่าน",
      "provinceCode": 55
    },
    {
      "province": "บึงกาฬ",
      "provinceCode": 38
    },
    {
      "province": "บุรีรัมย์",
      "provinceCode": 31
    },
    {
      "province": "ปทุมธานี",
      "provinceCode": 13
    },
    {
      "province": "ประจวบคีรีขันธ์",
      "provinceCode": 77
    },
    {
      "province": "ปราจีนบุรี",
      "provinceCode": 25
    },
    {
      "province": "ปัตตานี",
      "provinceCode": 94
    },
    {
      "province": "พระนครศรีอยุธยา",
      "provinceCode": 14
    },
    {
      "province": "พะเยา",
      "provinceCode": 56
    },
    {
      "province": "พังงา",
      "provinceCode": 82
    },
    {
      "province": "พัทลุง",
      "provinceCode": 93
    },
    {
      "province": "พิจิตร",
      "provinceCode": 66
    },
    {
      "province": "พิษณุโลก",
      "provinceCode": 65
    },
    {
      "province": "ภูเก็ต",
      "provinceCode": 83
    },
    {
      "province": "มหาสารคาม",
      "provinceCode": 44
    },
    {
      "province": "มุกดาหาร",
      "provinceCode": 49
    },
    {
      "province": "ยะลา",
      "provinceCode": 95
    },
    {
      "province": "ยโสธร",
      "provinceCode": 35
    },
    {
      "province": "ระนอง",
      "provinceCode": 85
    },
    {
      "province": "ระยอง",
      "provinceCode": 21
    },
    {
      "province": "ราชบุรี",
      "provinceCode": 70
    },
    {
      "province": "ร้อยเอ็ด",
      "provinceCode": 45
    },
    {
      "province": "ลพบุรี",
      "provinceCode": 16
    },
    {
      "province": "ลำปาง",
      "provinceCode": 52
    },
    {
      "province": "ลำพูน",
      "provinceCode": 51
    },
    {
      "province": "ศรีสะเกษ",
      "provinceCode": 33
    },
    {
      "province": "สกลนคร",
      "provinceCode": 47
    },
    {
      "province": "สงขลา",
      "provinceCode": 90
    },
    {
      "province": "สตูล",
      "provinceCode": 91
    },
    {
      "province": "สมุทรปราการ",
      "provinceCode": 11
    },
    {
      "province": "สมุทรสงคราม",
      "provinceCode": 75
    },
    {
      "province": "สมุทรสาคร",
      "provinceCode": 74
    },
    {
      "province": "สระบุรี",
      "provinceCode": 19
    },
    {
      "province": "สระแก้ว",
      "provinceCode": 27
    },
    {
      "province": "สิงห์บุรี",
      "provinceCode": 17
    },
    {
      "province": "สุพรรณบุรี",
      "provinceCode": 72
    },
    {
      "province": "สุราษฎร์ธานี",
      "provinceCode": 84
    },
    {
      "province": "สุรินทร์",
      "provinceCode": 32
    },
    {
      "province": "สุโขทัย",
      "provinceCode": 64
    },
    {
      "province": "หนองคาย",
      "provinceCode": 43
    },
    {
      "province": "หนองบัวลำภู",
      "provinceCode": 39
    },
    {
      "province": "อำนาจเจริญ",
      "provinceCode": 37
    },
    {
      "province": "อุดรธานี",
      "provinceCode": 41
    },
    {
      "province": "อุตรดิตถ์",
      "provinceCode": 53
    },
    {
      "province": "อุทัยธานี",
      "provinceCode": 61
    },
    {
      "province": "อุบลราชธานี",
      "provinceCode": 34
    },
    {
      "province": "อ่างทอง",
      "provinceCode": 15
    },
    {
      "province": "เชียงราย",
      "provinceCode": 57
    },
    {
      "province": "เชียงใหม่",
      "provinceCode": 50
    },
    {
      "province": "เพชรบุรี",
      "provinceCode": 76
    },
    {
      "province": "เพชรบูรณ์",
      "provinceCode": 67
    },
    {
      "province": "เลย",
      "provinceCode": 42
    },
    {
      "province": "แพร่",
      "provinceCode": 54
    },
    {
      "province": "แม่ฮ่องสอน",
      "provinceCode": 58
    }
  ],
  //amphoe
  CITY_LIST: [
    {
      "city": "คลองท่อม",
      "cityCode": 8104,
      "provinceCode": 81,
      "subDistrict": [
        {
          "id": 810402,
          "name": "คลองท่อมเหนือ",
          "postCode": [
            "81120"
          ]
        },
        {
          "id": 810401,
          "name": "คลองท่อมใต้",
          "postCode": [
            "81120"
          ]
        },
        {
          "id": 810403,
          "name": "คลองพน",
          "postCode": [
            "81170"
          ]
        },
        {
          "id": 810404,
          "name": "ทรายขาว",
          "postCode": [
            "81170"
          ]
        },
        {
          "id": 810406,
          "name": "พรุดินนา",
          "postCode": [
            "81120"
          ]
        },
        {
          "id": 810405,
          "name": "ห้วยน้ำขาว",
          "postCode": [
            "81120"
          ]
        },
        {
          "id": 810407,
          "name": "เพหลา",
          "postCode": [
            "81120"
          ]
        }
      ]
    },
    {
      "city": "ปลายพระยา",
      "cityCode": 8106,
      "provinceCode": 81,
      "subDistrict": [
        {
          "id": 810604,
          "name": "คีรีวง",
          "postCode": [
            "81160"
          ]
        },
        {
          "id": 810601,
          "name": "ปลายพระยา",
          "postCode": [
            "81160"
          ]
        },
        {
          "id": 810603,
          "name": "เขาต่อ",
          "postCode": [
            "81160"
          ]
        },
        {
          "id": 810602,
          "name": "เขาเขน",
          "postCode": [
            "81160"
          ]
        }
      ]
    },
    {
      "city": "ลำทับ",
      "cityCode": 8107,
      "provinceCode": 81,
      "subDistrict": [
        {
          "id": 810702,
          "name": "ดินอุดม",
          "postCode": [
            "81190"
          ]
        },
        {
          "id": 810704,
          "name": "ดินแดง",
          "postCode": [
            "81190"
          ]
        },
        {
          "id": 810703,
          "name": "ทุ่งไทรทอง",
          "postCode": [
            "81190"
          ]
        },
        {
          "id": 810701,
          "name": "ลำทับ",
          "postCode": [
            "81190"
          ]
        }
      ]
    },
    {
      "city": "อ่าวลึก",
      "cityCode": 8105,
      "provinceCode": 81,
      "subDistrict": [
        {
          "id": 810508,
          "name": "คลองยา",
          "postCode": [
            "81110"
          ]
        },
        {
          "id": 810504,
          "name": "คลองหิน",
          "postCode": [
            "81110"
          ]
        },
        {
          "id": 810503,
          "name": "นาเหนือ",
          "postCode": [
            "81110"
          ]
        },
        {
          "id": 810509,
          "name": "บ้านกลาง",
          "postCode": [
            "81110"
          ]
        },
        {
          "id": 810505,
          "name": "อ่าวลึกน้อย",
          "postCode": [
            "81110"
          ]
        },
        {
          "id": 810506,
          "name": "อ่าวลึกเหนือ",
          "postCode": [
            "81110"
          ]
        },
        {
          "id": 810501,
          "name": "อ่าวลึกใต้",
          "postCode": [
            "81110"
          ]
        },
        {
          "id": 810507,
          "name": "เขาใหญ่",
          "postCode": [
            "81110"
          ]
        },
        {
          "id": 810502,
          "name": "แหลมสัก",
          "postCode": [
            "81110"
          ]
        }
      ]
    },
    {
      "city": "เกาะลันตา",
      "cityCode": 8103,
      "provinceCode": 81,
      "subDistrict": [
        {
          "id": 810304,
          "name": "คลองยาง",
          "postCode": [
            "81120"
          ]
        },
        {
          "id": 810305,
          "name": "ศาลาด่าน",
          "postCode": [
            "81150"
          ]
        },
        {
          "id": 810303,
          "name": "เกาะกลาง",
          "postCode": [
            "81120"
          ]
        },
        {
          "id": 810302,
          "name": "เกาะลันตาน้อย",
          "postCode": [
            "81150"
          ]
        },
        {
          "id": 810301,
          "name": "เกาะลันตาใหญ่",
          "postCode": [
            "81150"
          ]
        }
      ]
    },
    {
      "city": "เขาพนม",
      "cityCode": 8102,
      "provinceCode": 81,
      "subDistrict": [
        {
          "id": 810204,
          "name": "พรุเตียว",
          "postCode": [
            "81140"
          ]
        },
        {
          "id": 810203,
          "name": "สินปุน",
          "postCode": [
            "80240"
          ]
        },
        {
          "id": 810205,
          "name": "หน้าเขา",
          "postCode": [
            "81140"
          ]
        },
        {
          "id": 810202,
          "name": "เขาดิน",
          "postCode": [
            "81140"
          ]
        },
        {
          "id": 810201,
          "name": "เขาพนม",
          "postCode": [
            "81140"
          ]
        },
        {
          "id": 810206,
          "name": "โคกหาร",
          "postCode": [
            "80240"
          ]
        }
      ]
    },
    {
      "city": "เมืองกระบี่",
      "cityCode": 8101,
      "provinceCode": 81,
      "subDistrict": [
        {
          "id": 810103,
          "name": "กระบี่น้อย",
          "postCode": [
            "81000"
          ]
        },
        {
          "id": 810102,
          "name": "กระบี่ใหญ่",
          "postCode": [
            "81000"
          ]
        },
        {
          "id": 810118,
          "name": "คลองประสงค์",
          "postCode": [
            "81000"
          ]
        },
        {
          "id": 810111,
          "name": "ทับปริก",
          "postCode": [
            "81000"
          ]
        },
        {
          "id": 810101,
          "name": "ปากน้ำ",
          "postCode": [
            "81000"
          ]
        },
        {
          "id": 810117,
          "name": "หนองทะเล",
          "postCode": [
            "81000"
          ]
        },
        {
          "id": 810116,
          "name": "อ่าวนาง",
          "postCode": [
            "81000"
          ]
        },
        {
          "id": 810105,
          "name": "เขาคราม",
          "postCode": [
            "81000"
          ]
        },
        {
          "id": 810106,
          "name": "เขาทอง",
          "postCode": [
            "81000"
          ]
        },
        {
          "id": 810115,
          "name": "ไสไทย",
          "postCode": [
            "81000"
          ]
        }
      ]
    },
    {
      "city": "เหนือคลอง",
      "cityCode": 8108,
      "provinceCode": 81,
      "subDistrict": [
        {
          "id": 810803,
          "name": "คลองขนาน",
          "postCode": [
            "81130"
          ]
        },
        {
          "id": 810804,
          "name": "คลองเขม้า",
          "postCode": [
            "81130"
          ]
        },
        {
          "id": 810806,
          "name": "ตลิ่งชัน",
          "postCode": [
            "81130"
          ]
        },
        {
          "id": 810807,
          "name": "ปกาสัย",
          "postCode": [
            "81130"
          ]
        },
        {
          "id": 810808,
          "name": "ห้วยยูง",
          "postCode": [
            "81130"
          ]
        },
        {
          "id": 810802,
          "name": "เกาะศรีบอยา",
          "postCode": [
            "81130"
          ]
        },
        {
          "id": 810801,
          "name": "เหนือคลอง",
          "postCode": [
            "81130"
          ]
        },
        {
          "id": 810805,
          "name": "โคกยาง",
          "postCode": [
            "81130"
          ]
        }
      ]
    },
    {
      "city": "คลองสาน",
      "cityCode": 1018,
      "provinceCode": 10,
      "subDistrict": [
        {
          "id": 101804,
          "name": "คลองต้นไทร",
          "postCode": [
            "10600"
          ]
        },
        {
          "id": 101802,
          "name": "คลองสาน",
          "postCode": [
            "10600"
          ]
        },
        {
          "id": 101803,
          "name": "บางลำภูล่าง",
          "postCode": [
            "10600"
          ]
        },
        {
          "id": 101801,
          "name": "สมเด็จเจ้าพระยา",
          "postCode": [
            "10600"
          ]
        }
      ]
    },
    {
      "city": "คลองสามวา",
      "cityCode": 1046,
      "provinceCode": 10,
      "subDistrict": [
        {
          "id": 104604,
          "name": "ทรายกองดิน",
          "postCode": [
            "10510"
          ]
        },
        {
          "id": 104605,
          "name": "ทรายกองดินใต้",
          "postCode": [
            "10510"
          ]
        },
        {
          "id": 104603,
          "name": "บางชัน",
          "postCode": [
            "10510"
          ]
        },
        {
          "id": 104601,
          "name": "สามวาตะวันตก",
          "postCode": [
            "10510"
          ]
        },
        {
          "id": 104602,
          "name": "สามวาตะวันออก",
          "postCode": [
            "10510"
          ]
        }
      ]
    },
    {
      "city": "คลองเตย",
      "cityCode": 1033,
      "provinceCode": 10,
      "subDistrict": [
        {
          "id": 103302,
          "name": "คลองตัน",
          "postCode": [
            "10110"
          ]
        },
        {
          "id": 103301,
          "name": "คลองเตย",
          "postCode": [
            "10110"
          ]
        },
        {
          "id": 103303,
          "name": "พระโขนง",
          "postCode": [
            "10110",
            "10260"
          ]
        }
      ]
    },
    {
      "city": "คันนายาว",
      "cityCode": 1043,
      "provinceCode": 10,
      "subDistrict": [
        {
          "id": 104301,
          "name": "คันนายาว",
          "postCode": [
            "10230"
          ]
        },
        {
          "id": 104302,
          "name": "รามอินทรา",
          "postCode": [
            "10230"
          ]
        }
      ]
    },
    {
      "city": "จตุจักร",
      "cityCode": 1030,
      "provinceCode": 10,
      "subDistrict": [
        {
          "id": 103005,
          "name": "จตุจักร",
          "postCode": [
            "10900"
          ]
        },
        {
          "id": 103004,
          "name": "จอมพล",
          "postCode": [
            "10900"
          ]
        },
        {
          "id": 103003,
          "name": "จันทรเกษม",
          "postCode": [
            "10900"
          ]
        },
        {
          "id": 103001,
          "name": "ลาดยาว",
          "postCode": [
            "10900"
          ]
        },
        {
          "id": 103002,
          "name": "เสนานิคม",
          "postCode": [
            "10900"
          ]
        }
      ]
    },
    {
      "city": "จอมทอง",
      "cityCode": 1035,
      "provinceCode": 10,
      "subDistrict": [
        {
          "id": 103504,
          "name": "จอมทอง",
          "postCode": [
            "10150"
          ]
        },
        {
          "id": 103501,
          "name": "บางขุนเทียน",
          "postCode": [
            "10150"
          ]
        },
        {
          "id": 103502,
          "name": "บางค้อ",
          "postCode": [
            "10150"
          ]
        },
        {
          "id": 103503,
          "name": "บางมด",
          "postCode": [
            "10150"
          ]
        }
      ]
    },
    {
      "city": "ดอนเมือง",
      "cityCode": 1036,
      "provinceCode": 10,
      "subDistrict": [
        {
          "id": 103604,
          "name": "ดอนเมือง",
          "postCode": [
            "10210"
          ]
        },
        {
          "id": 103605,
          "name": "สนามบิน",
          "postCode": [
            "10210"
          ]
        },
        {
          "id": 103602,
          "name": "สีกัน",
          "postCode": [
            "10210"
          ]
        }
      ]
    },
    {
      "city": "ดินแดง",
      "cityCode": 1026,
      "provinceCode": 10,
      "subDistrict": [
        {
          "id": 102601,
          "name": "ดินแดง",
          "postCode": [
            "10400"
          ]
        },
        {
          "id": 102602,
          "name": "รัชดาภิเษก",
          "postCode": [
            "10400"
          ]
        }
      ]
    },
    {
      "city": "ดุสิต",
      "cityCode": 1002,
      "provinceCode": 10,
      "subDistrict": [
        {
          "id": 100201,
          "name": "ดุสิต",
          "postCode": [
            "10300"
          ]
        },
        {
          "id": 100206,
          "name": "ถนนนครไชยศรี",
          "postCode": [
            "10300"
          ]
        },
        {
          "id": 100202,
          "name": "วชิรพยาบาล",
          "postCode": [
            "10300"
          ]
        },
        {
          "id": 100203,
          "name": "สวนจิตรลดา",
          "postCode": [
            "10300",
            "10303"
          ]
        },
        {
          "id": 100204,
          "name": "สี่แยกมหานาค",
          "postCode": [
            "10300"
          ]
        }
      ]
    },
    {
      "city": "ตลิ่งชัน",
      "cityCode": 1019,
      "provinceCode": 10,
      "subDistrict": [
        {
          "id": 101901,
          "name": "คลองชักพระ",
          "postCode": [
            "10170"
          ]
        },
        {
          "id": 101903,
          "name": "ฉิมพลี",
          "postCode": [
            "10170"
          ]
        },
        {
          "id": 101902,
          "name": "ตลิ่งชัน",
          "postCode": [
            "10170"
          ]
        },
        {
          "id": 101904,
          "name": "บางพรม",
          "postCode": [
            "10170"
          ]
        },
        {
          "id": 101905,
          "name": "บางระมาด",
          "postCode": [
            "10170"
          ]
        },
        {
          "id": 101907,
          "name": "บางเชือกหนัง",
          "postCode": [
            "10170"
          ]
        }
      ]
    },
    {
      "city": "ทวีวัฒนา",
      "cityCode": 1048,
      "provinceCode": 10,
      "subDistrict": [
        {
          "id": 104801,
          "name": "ทวีวัฒนา",
          "postCode": [
            "10170"
          ]
        },
        {
          "id": 104802,
          "name": "ศาลาธรรมสพน์",
          "postCode": [
            "10170"
          ]
        }
      ]
    },
    {
      "city": "ทุ่งครุ",
      "cityCode": 1049,
      "provinceCode": 10,
      "subDistrict": [
        {
          "id": 104902,
          "name": "ทุ่งครุ",
          "postCode": [
            "10140"
          ]
        },
        {
          "id": 104901,
          "name": "บางมด",
          "postCode": [
            "10140"
          ]
        }
      ]
    },
    {
      "city": "ธนบุรี",
      "cityCode": 1015,
      "provinceCode": 10,
      "subDistrict": [
        {
          "id": 101506,
          "name": "ดาวคะนอง",
          "postCode": [
            "10600"
          ]
        },
        {
          "id": 101505,
          "name": "ตลาดพลู",
          "postCode": [
            "10600"
          ]
        },
        {
          "id": 101503,
          "name": "บางยี่เรือ",
          "postCode": [
            "10600"
          ]
        },
        {
          "id": 101504,
          "name": "บุคคโล",
          "postCode": [
            "10600"
          ]
        },
        {
          "id": 101501,
          "name": "วัดกัลยาณ์",
          "postCode": [
            "10600"
          ]
        },
        {
          "id": 101507,
          "name": "สำเหร่",
          "postCode": [
            "10600"
          ]
        },
        {
          "id": 101502,
          "name": "หิรัญรูจี",
          "postCode": [
            "10600"
          ]
        }
      ]
    },
    {
      "city": "บางกอกน้อย",
      "cityCode": 1020,
      "provinceCode": 10,
      "subDistrict": [
        {
          "id": 102006,
          "name": "บางขุนนนท์",
          "postCode": [
            "10700"
          ]
        },
        {
          "id": 102007,
          "name": "บางขุนศรี",
          "postCode": [
            "10700"
          ]
        },
        {
          "id": 102005,
          "name": "บ้านช่างหล่อ",
          "postCode": [
            "10700"
          ]
        },
        {
          "id": 102004,
          "name": "ศิริราช",
          "postCode": [
            "10700"
          ]
        },
        {
          "id": 102009,
          "name": "อรุณอมรินทร์",
          "postCode": [
            "10700"
          ]
        }
      ]
    },
    {
      "city": "บางกอกใหญ่",
      "cityCode": 1016,
      "provinceCode": 10,
      "subDistrict": [
        {
          "id": 101602,
          "name": "วัดท่าพระ",
          "postCode": [
            "10600"
          ]
        },
        {
          "id": 101601,
          "name": "วัดอรุณ",
          "postCode": [
            "10600"
          ]
        }
      ]
    },
    {
      "city": "บางกะปิ",
      "cityCode": 1006,
      "provinceCode": 10,
      "subDistrict": [
        {
          "id": 100601,
          "name": "คลองจั่น",
          "postCode": [
            "10240"
          ]
        },
        {
          "id": 100608,
          "name": "หัวหมาก",
          "postCode": [
            "10240",
            "10250"
          ]
        }
      ]
    },
    {
      "city": "บางขุนเทียน",
      "cityCode": 1021,
      "provinceCode": 10,
      "subDistrict": [
        {
          "id": 102105,
          "name": "ท่าข้าม",
          "postCode": [
            "10150"
          ]
        },
        {
          "id": 102107,
          "name": "แสมดำ",
          "postCode": [
            "10150"
          ]
        }
      ]
    },
    {
      "city": "บางคอแหลม",
      "cityCode": 1031,
      "provinceCode": 10,
      "subDistrict": [
        {
          "id": 103101,
          "name": "บางคอแหลม",
          "postCode": [
            "10120"
          ]
        },
        {
          "id": 103103,
          "name": "บางโคล่",
          "postCode": [
            "10120"
          ]
        },
        {
          "id": 103102,
          "name": "วัดพระยาไกร",
          "postCode": [
            "10120"
          ]
        }
      ]
    },
    {
      "city": "บางซื่อ",
      "cityCode": 1029,
      "provinceCode": 10,
      "subDistrict": [
        {
          "id": 102901,
          "name": "บางซื่อ",
          "postCode": [
            "10800"
          ]
        },
        {
          "id": 102902,
          "name": "วงศ์สว่าง",
          "postCode": [
            "10800"
          ]
        }
      ]
    },
    {
      "city": "บางนา",
      "cityCode": 1047,
      "provinceCode": 10,
      "subDistrict": [
        {
          "id": 104702,
          "name": "บางนาเหนือ",
          "postCode": [
            "10260"
          ]
        },
        {
          "id": 104703,
          "name": "บางนาใต้",
          "postCode": [
            "10260"
          ]
        }
      ]
    },
    {
      "city": "บางบอน",
      "cityCode": 1050,
      "provinceCode": 10,
      "subDistrict": [
        {
          "id": 105005,
          "name": "คลองบางบอน",
          "postCode": [
            "10150"
          ]
        },
        {
          "id": 105004,
          "name": "คลองบางพราน",
          "postCode": [
            "10150"
          ]
        },
        {
          "id": 105002,
          "name": "บางบอนเหนือ",
          "postCode": [
            "10150"
          ]
        },
        {
          "id": 105003,
          "name": "บางบอนใต้",
          "postCode": [
            "10150"
          ]
        }
      ]
    },
    {
      "city": "บางพลัด",
      "cityCode": 1025,
      "provinceCode": 10,
      "subDistrict": [
        {
          "id": 102503,
          "name": "บางบำหรุ",
          "postCode": [
            "10700"
          ]
        },
        {
          "id": 102501,
          "name": "บางพลัด",
          "postCode": [
            "10700"
          ]
        },
        {
          "id": 102504,
          "name": "บางยี่ขัน",
          "postCode": [
            "10700"
          ]
        },
        {
          "id": 102502,
          "name": "บางอ้อ",
          "postCode": [
            "10700"
          ]
        }
      ]
    },
    {
      "city": "บางรัก",
      "cityCode": 1004,
      "provinceCode": 10,
      "subDistrict": [
        {
          "id": 100404,
          "name": "บางรัก",
          "postCode": [
            "10500"
          ]
        },
        {
          "id": 100401,
          "name": "มหาพฤฒาราม",
          "postCode": [
            "10500"
          ]
        },
        {
          "id": 100402,
          "name": "สีลม",
          "postCode": [
            "10500"
          ]
        },
        {
          "id": 100405,
          "name": "สี่พระยา",
          "postCode": [
            "10500"
          ]
        },
        {
          "id": 100403,
          "name": "สุริยวงศ์",
          "postCode": [
            "10500"
          ]
        }
      ]
    },
    {
      "city": "บางเขน",
      "cityCode": 1005,
      "provinceCode": 10,
      "subDistrict": [
        {
          "id": 100508,
          "name": "ท่าแร้ง",
          "postCode": [
            "10220",
            "10230"
          ]
        },
        {
          "id": 100502,
          "name": "อนุสาวรีย์",
          "postCode": [
            "10220"
          ]
        }
      ]
    },
    {
      "city": "บางแค",
      "cityCode": 1040,
      "provinceCode": 10,
      "subDistrict": [
        {
          "id": 104001,
          "name": "บางแค",
          "postCode": [
            "10160"
          ]
        },
        {
          "id": 104002,
          "name": "บางแคเหนือ",
          "postCode": [
            "10160"
          ]
        },
        {
          "id": 104003,
          "name": "บางไผ่",
          "postCode": [
            "10160"
          ]
        },
        {
          "id": 104004,
          "name": "หลักสอง",
          "postCode": [
            "10160"
          ]
        }
      ]
    },
    {
      "city": "บึงกุ่ม",
      "cityCode": 1027,
      "provinceCode": 10,
      "subDistrict": [
        {
          "id": 102701,
          "name": "คลองกุ่ม",
          "postCode": [
            "10230",
            "10240"
          ]
        },
        {
          "id": 102704,
          "name": "นวมินทร์",
          "postCode": [
            "10240"
          ]
        },
        {
          "id": 102705,
          "name": "นวลจันทร์",
          "postCode": [
            "10230"
          ]
        }
      ]
    },
    {
      "city": "ปทุมวัน",
      "cityCode": 1007,
      "provinceCode": 10,
      "subDistrict": [
        {
          "id": 100703,
          "name": "ปทุมวัน",
          "postCode": [
            "10330"
          ]
        },
        {
          "id": 100701,
          "name": "รองเมือง",
          "postCode": [
            "10330"
          ]
        },
        {
          "id": 100704,
          "name": "ลุมพินี",
          "postCode": [
            "10330"
          ]
        },
        {
          "id": 100702,
          "name": "วังใหม่",
          "postCode": [
            "10110",
            "10120",
            "10330",
            "10400",
            "10500"
          ]
        }
      ]
    },
    {
      "city": "ประเวศ",
      "cityCode": 1032,
      "provinceCode": 10,
      "subDistrict": [
        {
          "id": 103203,
          "name": "ดอกไม้",
          "postCode": [
            "10250"
          ]
        },
        {
          "id": 103201,
          "name": "ประเวศ",
          "postCode": [
            "10250"
          ]
        },
        {
          "id": 103202,
          "name": "หนองบอน",
          "postCode": [
            "10250"
          ]
        }
      ]
    },
    {
      "city": "ป้อมปราบศัตรูพ่าย",
      "cityCode": 1008,
      "provinceCode": 10,
      "subDistrict": [
        {
          "id": 100803,
          "name": "คลองมหานาค",
          "postCode": [
            "10100"
          ]
        },
        {
          "id": 100804,
          "name": "บ้านบาตร",
          "postCode": [
            "10100"
          ]
        },
        {
          "id": 100801,
          "name": "ป้อมปราบ",
          "postCode": [
            "10100"
          ]
        },
        {
          "id": 100802,
          "name": "วัดเทพศิรินทร์",
          "postCode": [
            "10100"
          ]
        },
        {
          "id": 100805,
          "name": "วัดโสมนัส",
          "postCode": [
            "10100"
          ]
        }
      ]
    },
    {
      "city": "พญาไท",
      "cityCode": 1014,
      "provinceCode": 10,
      "subDistrict": [
        {
          "id": 101406,
          "name": "พญาไท",
          "postCode": [
            "10400"
          ]
        },
        {
          "id": 101401,
          "name": "สามเสนใน",
          "postCode": [
            "10400"
          ]
        }
      ]
    },
    {
      "city": "พระนคร",
      "cityCode": 1001,
      "provinceCode": 10,
      "subDistrict": [
        {
          "id": 100109,
          "name": "ชนะสงคราม",
          "postCode": [
            "10200"
          ]
        },
        {
          "id": 100108,
          "name": "ตลาดยอด",
          "postCode": [
            "10200"
          ]
        },
        {
          "id": 100107,
          "name": "บวรนิเวศ",
          "postCode": [
            "10200"
          ]
        },
        {
          "id": 100111,
          "name": "บางขุนพรหม",
          "postCode": [
            "10200"
          ]
        },
        {
          "id": 100110,
          "name": "บ้านพานถม",
          "postCode": [
            "10200"
          ]
        },
        {
          "id": 100101,
          "name": "พระบรมมหาราชวัง",
          "postCode": [
            "10200"
          ]
        },
        {
          "id": 100102,
          "name": "วังบูรพาภิรมย์",
          "postCode": [
            "10200"
          ]
        },
        {
          "id": 100103,
          "name": "วัดราชบพิธ",
          "postCode": [
            "10200"
          ]
        },
        {
          "id": 100112,
          "name": "วัดสามพระยา",
          "postCode": [
            "10200"
          ]
        },
        {
          "id": 100105,
          "name": "ศาลเจ้าพ่อเสือ",
          "postCode": [
            "10200"
          ]
        },
        {
          "id": 100104,
          "name": "สำราญราษฎร์",
          "postCode": [
            "10200"
          ]
        },
        {
          "id": 100106,
          "name": "เสาชิงช้า",
          "postCode": [
            "10200"
          ]
        }
      ]
    },
    {
      "city": "พระโขนง",
      "cityCode": 1009,
      "provinceCode": 10,
      "subDistrict": [
        {
          "id": 100905,
          "name": "บางจาก",
          "postCode": [
            "10260"
          ]
        },
        {
          "id": 100910,
          "name": "พระโขนงใต้",
          "postCode": [
            "10260"
          ]
        }
      ]
    },
    {
      "city": "ภาษีเจริญ",
      "cityCode": 1022,
      "provinceCode": 10,
      "subDistrict": [
        {
          "id": 102208,
          "name": "คลองขวาง",
          "postCode": [
            "10160"
          ]
        },
        {
          "id": 102210,
          "name": "คูหาสวรรค์",
          "postCode": [
            "10160"
          ]
        },
        {
          "id": 102206,
          "name": "บางจาก",
          "postCode": [
            "10160"
          ]
        },
        {
          "id": 102202,
          "name": "บางด้วน",
          "postCode": [
            "10160"
          ]
        },
        {
          "id": 102201,
          "name": "บางหว้า",
          "postCode": [
            "10160"
          ]
        },
        {
          "id": 102207,
          "name": "บางแวก",
          "postCode": [
            "10160"
          ]
        },
        {
          "id": 102209,
          "name": "ปากคลองภาษีเจริญ",
          "postCode": [
            "10160"
          ]
        }
      ]
    },
    {
      "city": "มีนบุรี",
      "cityCode": 1010,
      "provinceCode": 10,
      "subDistrict": [
        {
          "id": 101001,
          "name": "มีนบุรี",
          "postCode": [
            "10510"
          ]
        },
        {
          "id": 101002,
          "name": "แสนแสบ",
          "postCode": [
            "10510"
          ]
        }
      ]
    },
    {
      "city": "ยานนาวา",
      "cityCode": 1012,
      "provinceCode": 10,
      "subDistrict": [
        {
          "id": 101203,
          "name": "ช่องนนทรี",
          "postCode": [
            "10120"
          ]
        },
        {
          "id": 101204,
          "name": "บางโพงพาง",
          "postCode": [
            "10120"
          ]
        }
      ]
    },
    {
      "city": "ราชเทวี",
      "cityCode": 1037,
      "provinceCode": 10,
      "subDistrict": [
        {
          "id": 103702,
          "name": "ถนนพญาไท",
          "postCode": [
            "10400"
          ]
        },
        {
          "id": 103703,
          "name": "ถนนเพชรบุรี",
          "postCode": [
            "10400"
          ]
        },
        {
          "id": 103701,
          "name": "ทุ่งพญาไท",
          "postCode": [
            "10400"
          ]
        },
        {
          "id": 103704,
          "name": "มักกะสัน",
          "postCode": [
            "10400"
          ]
        }
      ]
    },
    {
      "city": "ราษฎร์บูรณะ",
      "cityCode": 1024,
      "provinceCode": 10,
      "subDistrict": [
        {
          "id": 102402,
          "name": "บางปะกอก",
          "postCode": [
            "10140"
          ]
        },
        {
          "id": 102401,
          "name": "ราษฎร์บูรณะ",
          "postCode": [
            "10140"
          ]
        }
      ]
    },
    {
      "city": "ลาดกระบัง",
      "cityCode": 1011,
      "provinceCode": 10,
      "subDistrict": [
        {
          "id": 101106,
          "name": "ขุมทอง",
          "postCode": [
            "10520"
          ]
        },
        {
          "id": 101102,
          "name": "คลองสองต้นนุ่น",
          "postCode": [
            "10520"
          ]
        },
        {
          "id": 101103,
          "name": "คลองสามประเวศ",
          "postCode": [
            "10520"
          ]
        },
        {
          "id": 101105,
          "name": "ทับยาว",
          "postCode": [
            "10520"
          ]
        },
        {
          "id": 101101,
          "name": "ลาดกระบัง",
          "postCode": [
            "10520"
          ]
        },
        {
          "id": 101104,
          "name": "ลำปลาทิว",
          "postCode": [
            "10520"
          ]
        }
      ]
    },
    {
      "city": "ลาดพร้าว",
      "cityCode": 1038,
      "provinceCode": 10,
      "subDistrict": [
        {
          "id": 103802,
          "name": "จรเข้บัว",
          "postCode": [
            "10230"
          ]
        },
        {
          "id": 103801,
          "name": "ลาดพร้าว",
          "postCode": [
            "10230"
          ]
        }
      ]
    },
    {
      "city": "วังทองหลาง",
      "cityCode": 1045,
      "provinceCode": 10,
      "subDistrict": [
        {
          "id": 104503,
          "name": "คลองเจ้าคุณสิงห์",
          "postCode": [
            "10310"
          ]
        },
        {
          "id": 104504,
          "name": "พลับพลา",
          "postCode": [
            "10310"
          ]
        },
        {
          "id": 104501,
          "name": "วังทองหลาง",
          "postCode": [
            "10310"
          ]
        },
        {
          "id": 104502,
          "name": "สะพานสอง",
          "postCode": [
            "10310"
          ]
        }
      ]
    },
    {
      "city": "วัฒนา",
      "cityCode": 1039,
      "provinceCode": 10,
      "subDistrict": [
        {
          "id": 103902,
          "name": "คลองตันเหนือ",
          "postCode": [
            "10110"
          ]
        },
        {
          "id": 103901,
          "name": "คลองเตยเหนือ",
          "postCode": [
            "10110"
          ]
        },
        {
          "id": 103903,
          "name": "พระโขนงเหนือ",
          "postCode": [
            "10110",
            "10260"
          ]
        }
      ]
    },
    {
      "city": "สวนหลวง",
      "cityCode": 1034,
      "provinceCode": 10,
      "subDistrict": [
        {
          "id": 103403,
          "name": "พัฒนาการ",
          "postCode": [
            "10250"
          ]
        },
        {
          "id": 103401,
          "name": "สวนหลวง",
          "postCode": [
            "10250"
          ]
        },
        {
          "id": 103402,
          "name": "อ่อนนุช",
          "postCode": [
            "10250"
          ]
        }
      ]
    },
    {
      "city": "สะพานสูง",
      "cityCode": 1044,
      "provinceCode": 10,
      "subDistrict": [
        {
          "id": 104403,
          "name": "ทับช้าง",
          "postCode": [
            "10250"
          ]
        },
        {
          "id": 104402,
          "name": "ราษฎร์พัฒนา",
          "postCode": [
            "10240"
          ]
        },
        {
          "id": 104401,
          "name": "สะพานสูง",
          "postCode": [
            "10240",
            "10250"
          ]
        }
      ]
    },
    {
      "city": "สัมพันธวงศ์",
      "cityCode": 1013,
      "provinceCode": 10,
      "subDistrict": [
        {
          "id": 101301,
          "name": "จักรวรรดิ",
          "postCode": [
            "10100"
          ]
        },
        {
          "id": 101303,
          "name": "ตลาดน้อย",
          "postCode": [
            "10100"
          ]
        },
        {
          "id": 101302,
          "name": "สัมพันธวงศ์",
          "postCode": [
            "10100"
          ]
        }
      ]
    },
    {
      "city": "สาทร",
      "cityCode": 1028,
      "provinceCode": 10,
      "subDistrict": [
        {
          "id": 102803,
          "name": "ทุ่งมหาเมฆ",
          "postCode": [
            "10120"
          ]
        },
        {
          "id": 102801,
          "name": "ทุ่งวัดดอน",
          "postCode": [
            "10120"
          ]
        },
        {
          "id": 102802,
          "name": "ยานนาวา",
          "postCode": [
            "10120"
          ]
        }
      ]
    },
    {
      "city": "สายไหม",
      "cityCode": 1042,
      "provinceCode": 10,
      "subDistrict": [
        {
          "id": 104203,
          "name": "คลองถนน",
          "postCode": [
            "10220"
          ]
        },
        {
          "id": 104201,
          "name": "สายไหม",
          "postCode": [
            "10220"
          ]
        },
        {
          "id": 104202,
          "name": "ออเงิน",
          "postCode": [
            "10220"
          ]
        }
      ]
    },
    {
      "city": "หนองจอก",
      "cityCode": 1003,
      "provinceCode": 10,
      "subDistrict": [
        {
          "id": 100301,
          "name": "กระทุ่มราย",
          "postCode": [
            "10530"
          ]
        },
        {
          "id": 100303,
          "name": "คลองสิบ",
          "postCode": [
            "10530"
          ]
        },
        {
          "id": 100304,
          "name": "คลองสิบสอง",
          "postCode": [
            "10530"
          ]
        },
        {
          "id": 100306,
          "name": "คู้ฝั่งเหนือ",
          "postCode": [
            "10530"
          ]
        },
        {
          "id": 100308,
          "name": "ลำต้อยติ่ง",
          "postCode": [
            "10530"
          ]
        },
        {
          "id": 100307,
          "name": "ลำผักชี",
          "postCode": [
            "10530"
          ]
        },
        {
          "id": 100302,
          "name": "หนองจอก",
          "postCode": [
            "10530"
          ]
        },
        {
          "id": 100305,
          "name": "โคกแฝด",
          "postCode": [
            "10530"
          ]
        }
      ]
    },
    {
      "city": "หนองแขม",
      "cityCode": 1023,
      "provinceCode": 10,
      "subDistrict": [
        {
          "id": 102303,
          "name": "หนองค้างพลู",
          "postCode": [
            "10160"
          ]
        },
        {
          "id": 102302,
          "name": "หนองแขม",
          "postCode": [
            "10160"
          ]
        }
      ]
    },
    {
      "city": "หลักสี่",
      "cityCode": 1041,
      "provinceCode": 10,
      "subDistrict": [
        {
          "id": 104102,
          "name": "ตลาดบางเขน",
          "postCode": [
            "10210"
          ]
        },
        {
          "id": 104101,
          "name": "ทุ่งสองห้อง",
          "postCode": [
            "10210"
          ]
        }
      ]
    },
    {
      "city": "ห้วยขวาง",
      "cityCode": 1017,
      "provinceCode": 10,
      "subDistrict": [
        {
          "id": 101702,
          "name": "บางกะปิ",
          "postCode": [
            "10310"
          ]
        },
        {
          "id": 101704,
          "name": "สามเสนนอก",
          "postCode": [
            "10310"
          ]
        },
        {
          "id": 101701,
          "name": "ห้วยขวาง",
          "postCode": [
            "10310"
          ]
        }
      ]
    },
    {
      "city": "ด่านมะขามเตี้ย",
      "cityCode": 7111,
      "provinceCode": 71,
      "subDistrict": [
        {
          "id": 711102,
          "name": "กลอนโด",
          "postCode": [
            "71260"
          ]
        },
        {
          "id": 711103,
          "name": "จรเข้เผือก",
          "postCode": [
            "71260"
          ]
        },
        {
          "id": 711101,
          "name": "ด่านมะขามเตี้ย",
          "postCode": [
            "71260"
          ]
        },
        {
          "id": 711104,
          "name": "หนองไผ่",
          "postCode": [
            "71260"
          ]
        }
      ]
    },
    {
      "city": "ทองผาภูมิ",
      "cityCode": 7107,
      "provinceCode": 71,
      "subDistrict": [
        {
          "id": 710705,
          "name": "ชะแล",
          "postCode": [
            "71180"
          ]
        },
        {
          "id": 710701,
          "name": "ท่าขนุน",
          "postCode": [
            "71180"
          ]
        },
        {
          "id": 710702,
          "name": "ปิล๊อก",
          "postCode": [
            "71180"
          ]
        },
        {
          "id": 710704,
          "name": "ลิ่นถิ่น",
          "postCode": [
            "71180"
          ]
        },
        {
          "id": 710707,
          "name": "สหกรณ์นิคม",
          "postCode": [
            "71180"
          ]
        },
        {
          "id": 710703,
          "name": "หินดาด",
          "postCode": [
            "71180"
          ]
        },
        {
          "id": 710706,
          "name": "ห้วยเขย่ง",
          "postCode": [
            "71180"
          ]
        }
      ]
    },
    {
      "city": "ท่ามะกา",
      "cityCode": 7105,
      "provinceCode": 71,
      "subDistrict": [
        {
          "id": 710509,
          "name": "ดอนขมิ้น",
          "postCode": [
            "71120"
          ]
        },
        {
          "id": 710503,
          "name": "ดอนชะเอม",
          "postCode": [
            "71130"
          ]
        },
        {
          "id": 710505,
          "name": "ตะคร้ำเอน",
          "postCode": [
            "71130"
          ]
        },
        {
          "id": 710506,
          "name": "ท่ามะกา",
          "postCode": [
            "71120"
          ]
        },
        {
          "id": 710507,
          "name": "ท่าเรือ",
          "postCode": [
            "71130"
          ]
        },
        {
          "id": 710516,
          "name": "ท่าเสา",
          "postCode": [
            "71120"
          ]
        },
        {
          "id": 710504,
          "name": "ท่าไม้",
          "postCode": [
            "71120"
          ]
        },
        {
          "id": 710501,
          "name": "พงตึก",
          "postCode": [
            "71120"
          ]
        },
        {
          "id": 710512,
          "name": "พระแท่น",
          "postCode": [
            "71130"
          ]
        },
        {
          "id": 710502,
          "name": "ยางม่วง",
          "postCode": [
            "71120"
          ]
        },
        {
          "id": 710515,
          "name": "สนามแย้",
          "postCode": [
            "70190"
          ]
        },
        {
          "id": 710517,
          "name": "หนองลาน",
          "postCode": [
            "71130"
          ]
        },
        {
          "id": 710513,
          "name": "หวายเหนียว",
          "postCode": [
            "71120"
          ]
        },
        {
          "id": 710510,
          "name": "อุโลกสี่หมื่น",
          "postCode": [
            "71130"
          ]
        },
        {
          "id": 710511,
          "name": "เขาสามสิบหาบ",
          "postCode": [
            "71120"
          ]
        },
        {
          "id": 710514,
          "name": "แสนตอ",
          "postCode": [
            "71130"
          ]
        },
        {
          "id": 710508,
          "name": "โคกตะบอง",
          "postCode": [
            "71120"
          ]
        }
      ]
    },
    {
      "city": "ท่าม่วง",
      "cityCode": 7106,
      "provinceCode": 71,
      "subDistrict": [
        {
          "id": 710606,
          "name": "ทุ่งทอง",
          "postCode": [
            "71110"
          ]
        },
        {
          "id": 710611,
          "name": "ท่าตะคร้อ",
          "postCode": [
            "71130"
          ]
        },
        {
          "id": 710601,
          "name": "ท่าม่วง",
          "postCode": [
            "71110"
          ]
        },
        {
          "id": 710604,
          "name": "ท่าล้อ",
          "postCode": [
            "71000",
            "71110"
          ]
        },
        {
          "id": 710609,
          "name": "บ้านใหม่",
          "postCode": [
            "71110"
          ]
        },
        {
          "id": 710610,
          "name": "พังตรุ",
          "postCode": [
            "71110"
          ]
        },
        {
          "id": 710608,
          "name": "ม่วงชุม",
          "postCode": [
            "71110"
          ]
        },
        {
          "id": 710612,
          "name": "รางสาลี่",
          "postCode": [
            "71110"
          ]
        },
        {
          "id": 710602,
          "name": "วังขนาย",
          "postCode": [
            "71110"
          ]
        },
        {
          "id": 710603,
          "name": "วังศาลา",
          "postCode": [
            "71110",
            "71130"
          ]
        },
        {
          "id": 710605,
          "name": "หนองขาว",
          "postCode": [
            "71110"
          ]
        },
        {
          "id": 710613,
          "name": "หนองตากยา",
          "postCode": [
            "71110"
          ]
        },
        {
          "id": 710607,
          "name": "เขาน้อย",
          "postCode": [
            "71110"
          ]
        }
      ]
    },
    {
      "city": "บ่อพลอย",
      "cityCode": 7103,
      "provinceCode": 71,
      "subDistrict": [
        {
          "id": 710308,
          "name": "ช่องด่าน",
          "postCode": [
            "71160"
          ]
        },
        {
          "id": 710301,
          "name": "บ่อพลอย",
          "postCode": [
            "71160"
          ]
        },
        {
          "id": 710309,
          "name": "หนองกร่าง",
          "postCode": [
            "71220"
          ]
        },
        {
          "id": 710302,
          "name": "หนองกุ่ม",
          "postCode": [
            "71160"
          ]
        },
        {
          "id": 710303,
          "name": "หนองรี",
          "postCode": [
            "71220"
          ]
        },
        {
          "id": 710305,
          "name": "หลุมรัง",
          "postCode": [
            "71160"
          ]
        }
      ]
    },
    {
      "city": "พนมทวน",
      "cityCode": 7109,
      "provinceCode": 71,
      "subDistrict": [
        {
          "id": 710912,
          "name": "ดอนตาเพชร",
          "postCode": [
            "71140"
          ]
        },
        {
          "id": 710904,
          "name": "ดอนเจดีย์",
          "postCode": [
            "71140"
          ]
        },
        {
          "id": 710903,
          "name": "ทุ่งสมอ",
          "postCode": [
            "71140"
          ]
        },
        {
          "id": 710901,
          "name": "พนมทวน",
          "postCode": [
            "71140"
          ]
        },
        {
          "id": 710905,
          "name": "พังตรุ",
          "postCode": [
            "71140"
          ]
        },
        {
          "id": 710906,
          "name": "รางหวาย",
          "postCode": [
            "71170"
          ]
        },
        {
          "id": 710911,
          "name": "หนองสาหร่าย",
          "postCode": [
            "71140"
          ]
        },
        {
          "id": 710902,
          "name": "หนองโรง",
          "postCode": [
            "71140"
          ]
        }
      ]
    },
    {
      "city": "ศรีสวัสดิ์",
      "cityCode": 7104,
      "provinceCode": 71,
      "subDistrict": [
        {
          "id": 710402,
          "name": "ด่านแม่แฉลบ",
          "postCode": [
            "71250"
          ]
        },
        {
          "id": 710404,
          "name": "ท่ากระดาน",
          "postCode": [
            "71250"
          ]
        },
        {
          "id": 710401,
          "name": "นาสวน",
          "postCode": [
            "71250"
          ]
        },
        {
          "id": 710403,
          "name": "หนองเป็ด",
          "postCode": [
            "71250"
          ]
        },
        {
          "id": 710405,
          "name": "เขาโจด",
          "postCode": [
            "71220"
          ]
        },
        {
          "id": 710406,
          "name": "แม่กระบุง",
          "postCode": [
            "71250"
          ]
        }
      ]
    },
    {
      "city": "สังขละบุรี",
      "cityCode": 7108,
      "provinceCode": 71,
      "subDistrict": [
        {
          "id": 710802,
          "name": "ปรังเผล",
          "postCode": [
            "71240"
          ]
        },
        {
          "id": 710801,
          "name": "หนองลู",
          "postCode": [
            "71240"
          ]
        },
        {
          "id": 710803,
          "name": "ไล่โว่",
          "postCode": [
            "71240"
          ]
        }
      ]
    },
    {
      "city": "หนองปรือ",
      "cityCode": 7112,
      "provinceCode": 71,
      "subDistrict": [
        {
          "id": 711203,
          "name": "สมเด็จเจริญ",
          "postCode": [
            "71220"
          ]
        },
        {
          "id": 711201,
          "name": "หนองปรือ",
          "postCode": [
            "71220"
          ]
        },
        {
          "id": 711202,
          "name": "หนองปลาไหล",
          "postCode": [
            "71220"
          ]
        }
      ]
    },
    {
      "city": "ห้วยกระเจา",
      "cityCode": 7113,
      "provinceCode": 71,
      "subDistrict": [
        {
          "id": 711303,
          "name": "ดอนแสลบ",
          "postCode": [
            "71170"
          ]
        },
        {
          "id": 711302,
          "name": "วังไผ่",
          "postCode": [
            "71170"
          ]
        },
        {
          "id": 711304,
          "name": "สระลงเรือ",
          "postCode": [
            "71170"
          ]
        },
        {
          "id": 711301,
          "name": "ห้วยกระเจา",
          "postCode": [
            "71170"
          ]
        }
      ]
    },
    {
      "city": "เมืองกาญจนบุรี",
      "cityCode": 7101,
      "provinceCode": 71,
      "subDistrict": [
        {
          "id": 710109,
          "name": "ช่องสะเดา",
          "postCode": [
            "71190"
          ]
        },
        {
          "id": 710104,
          "name": "ท่ามะขาม",
          "postCode": [
            "71000"
          ]
        },
        {
          "id": 710113,
          "name": "บ้านเก่า",
          "postCode": [
            "71000"
          ]
        },
        {
          "id": 710101,
          "name": "บ้านเหนือ",
          "postCode": [
            "71000"
          ]
        },
        {
          "id": 710102,
          "name": "บ้านใต้",
          "postCode": [
            "71000"
          ]
        },
        {
          "id": 710103,
          "name": "ปากแพรก",
          "postCode": [
            "71000"
          ]
        },
        {
          "id": 710107,
          "name": "ลาดหญ้า",
          "postCode": [
            "71190"
          ]
        },
        {
          "id": 710108,
          "name": "วังด้ง",
          "postCode": [
            "71190"
          ]
        },
        {
          "id": 710116,
          "name": "วังเย็น",
          "postCode": [
            "71000"
          ]
        },
        {
          "id": 710106,
          "name": "หนองบัว",
          "postCode": [
            "71190"
          ]
        },
        {
          "id": 710110,
          "name": "หนองหญ้า",
          "postCode": [
            "71000"
          ]
        },
        {
          "id": 710111,
          "name": "เกาะสำโรง",
          "postCode": [
            "71000"
          ]
        },
        {
          "id": 710105,
          "name": "แก่งเสี้ยน",
          "postCode": [
            "71000"
          ]
        }
      ]
    },
    {
      "city": "เลาขวัญ",
      "cityCode": 7110,
      "provinceCode": 71,
      "subDistrict": [
        {
          "id": 711006,
          "name": "ทุ่งกระบ่ำ",
          "postCode": [
            "71210"
          ]
        },
        {
          "id": 711005,
          "name": "หนองนกแก้ว",
          "postCode": [
            "71210"
          ]
        },
        {
          "id": 711003,
          "name": "หนองประดู่",
          "postCode": [
            "71210"
          ]
        },
        {
          "id": 711004,
          "name": "หนองปลิง",
          "postCode": [
            "71210"
          ]
        },
        {
          "id": 711007,
          "name": "หนองฝ้าย",
          "postCode": [
            "71210"
          ]
        },
        {
          "id": 711002,
          "name": "หนองโสน",
          "postCode": [
            "71210"
          ]
        },
        {
          "id": 711001,
          "name": "เลาขวัญ",
          "postCode": [
            "71210"
          ]
        }
      ]
    },
    {
      "city": "ไทรโยค",
      "cityCode": 7102,
      "provinceCode": 71,
      "subDistrict": [
        {
          "id": 710202,
          "name": "ท่าเสา",
          "postCode": [
            "71150"
          ]
        },
        {
          "id": 710207,
          "name": "บ้องตี้",
          "postCode": [
            "71150"
          ]
        },
        {
          "id": 710201,
          "name": "ลุ่มสุ่ม",
          "postCode": [
            "71150"
          ]
        },
        {
          "id": 710205,
          "name": "วังกระแจะ",
          "postCode": [
            "71150"
          ]
        },
        {
          "id": 710206,
          "name": "ศรีมงคล",
          "postCode": [
            "71150"
          ]
        },
        {
          "id": 710203,
          "name": "สิงห์",
          "postCode": [
            "71150"
          ]
        },
        {
          "id": 710204,
          "name": "ไทรโยค",
          "postCode": [
            "71150"
          ]
        }
      ]
    },
    {
      "city": "กมลาไสย",
      "cityCode": 4603,
      "provinceCode": 46,
      "subDistrict": [
        {
          "id": 460301,
          "name": "กมลาไสย",
          "postCode": [
            "46130"
          ]
        },
        {
          "id": 460304,
          "name": "ดงลิง",
          "postCode": [
            "46130"
          ]
        },
        {
          "id": 460305,
          "name": "ธัญญา",
          "postCode": [
            "46130"
          ]
        },
        {
          "id": 460308,
          "name": "หนองแปน",
          "postCode": [
            "46130"
          ]
        },
        {
          "id": 460302,
          "name": "หลักเมือง",
          "postCode": [
            "46130"
          ]
        },
        {
          "id": 460310,
          "name": "เจ้าท่า",
          "postCode": [
            "46130"
          ]
        },
        {
          "id": 460311,
          "name": "โคกสมบูรณ์",
          "postCode": [
            "46130"
          ]
        },
        {
          "id": 460303,
          "name": "โพนงาม",
          "postCode": [
            "46130"
          ]
        }
      ]
    },
    {
      "city": "กุฉินารายณ์",
      "cityCode": 4605,
      "provinceCode": 46,
      "subDistrict": [
        {
          "id": 460512,
          "name": "กุดค้าว",
          "postCode": [
            "46110"
          ]
        },
        {
          "id": 460506,
          "name": "กุดหว้า",
          "postCode": [
            "46110"
          ]
        },
        {
          "id": 460504,
          "name": "จุมจัง",
          "postCode": [
            "46110"
          ]
        },
        {
          "id": 460508,
          "name": "นาขาม",
          "postCode": [
            "46110"
          ]
        },
        {
          "id": 460510,
          "name": "นาโก",
          "postCode": [
            "46110"
          ]
        },
        {
          "id": 460501,
          "name": "บัวขาว",
          "postCode": [
            "46110"
          ]
        },
        {
          "id": 460511,
          "name": "สมสะอาด",
          "postCode": [
            "46110"
          ]
        },
        {
          "id": 460507,
          "name": "สามขา",
          "postCode": [
            "46110"
          ]
        },
        {
          "id": 460509,
          "name": "หนองห้าง",
          "postCode": [
            "46110"
          ]
        },
        {
          "id": 460503,
          "name": "เหล่าใหญ่",
          "postCode": [
            "46110"
          ]
        },
        {
          "id": 460505,
          "name": "เหล่าไฮงาม",
          "postCode": [
            "46110"
          ]
        },
        {
          "id": 460502,
          "name": "แจนแลน",
          "postCode": [
            "46110"
          ]
        }
      ]
    },
    {
      "city": "คำม่วง",
      "cityCode": 4610,
      "provinceCode": 46,
      "subDistrict": [
        {
          "id": 461005,
          "name": "ดินจี่",
          "postCode": [
            "46180"
          ]
        },
        {
          "id": 461001,
          "name": "ทุ่งคลอง",
          "postCode": [
            "46180"
          ]
        },
        {
          "id": 461007,
          "name": "นาทัน",
          "postCode": [
            "46180"
          ]
        },
        {
          "id": 461006,
          "name": "นาบอน",
          "postCode": [
            "46180"
          ]
        },
        {
          "id": 461009,
          "name": "เนินยาง",
          "postCode": [
            "46180"
          ]
        },
        {
          "id": 461002,
          "name": "โพน",
          "postCode": [
            "46180"
          ]
        }
      ]
    },
    {
      "city": "ฆ้องชัย",
      "cityCode": 4618,
      "provinceCode": 46,
      "subDistrict": [
        {
          "id": 461801,
          "name": "ฆ้องชัยพัฒนา",
          "postCode": [
            "46130"
          ]
        },
        {
          "id": 461805,
          "name": "ลำชี",
          "postCode": [
            "46130"
          ]
        },
        {
          "id": 461802,
          "name": "เหล่ากลาง",
          "postCode": [
            "46130"
          ]
        },
        {
          "id": 461803,
          "name": "โคกสะอาด",
          "postCode": [
            "46130"
          ]
        },
        {
          "id": 461804,
          "name": "โนนศิลาเลิง",
          "postCode": [
            "46130"
          ]
        }
      ]
    },
    {
      "city": "ดอนจาน",
      "cityCode": 4617,
      "provinceCode": 46,
      "subDistrict": [
        {
          "id": 461703,
          "name": "ดงพยุง",
          "postCode": [
            "46000"
          ]
        },
        {
          "id": 461701,
          "name": "ดอนจาน",
          "postCode": [
            "46000"
          ]
        },
        {
          "id": 461705,
          "name": "นาจำปา",
          "postCode": [
            "46000"
          ]
        },
        {
          "id": 461704,
          "name": "ม่วงนา",
          "postCode": [
            "46000"
          ]
        },
        {
          "id": 461702,
          "name": "สะอาดไชยศรี",
          "postCode": [
            "46000"
          ]
        }
      ]
    },
    {
      "city": "ท่าคันโท",
      "cityCode": 4611,
      "provinceCode": 46,
      "subDistrict": [
        {
          "id": 461102,
          "name": "กุงเก่า",
          "postCode": [
            "46190"
          ]
        },
        {
          "id": 461104,
          "name": "กุดจิก",
          "postCode": [
            "46190"
          ]
        },
        {
          "id": 461106,
          "name": "ดงสมบูรณ์",
          "postCode": [
            "46190"
          ]
        },
        {
          "id": 461101,
          "name": "ท่าคันโท",
          "postCode": [
            "46190"
          ]
        },
        {
          "id": 461105,
          "name": "นาตาล",
          "postCode": [
            "46190"
          ]
        },
        {
          "id": 461103,
          "name": "ยางอู้ม",
          "postCode": [
            "46190"
          ]
        }
      ]
    },
    {
      "city": "นาคู",
      "cityCode": 4616,
      "provinceCode": 46,
      "subDistrict": [
        {
          "id": 461601,
          "name": "นาคู",
          "postCode": [
            "46160"
          ]
        },
        {
          "id": 461604,
          "name": "บ่อแก้ว",
          "postCode": [
            "46160"
          ]
        },
        {
          "id": 461605,
          "name": "ภูแล่นช้าง",
          "postCode": [
            "46160"
          ]
        },
        {
          "id": 461602,
          "name": "สายนาวัง",
          "postCode": [
            "46160"
          ]
        },
        {
          "id": 461603,
          "name": "โนนนาจาน",
          "postCode": [
            "46160"
          ]
        }
      ]
    },
    {
      "city": "นามน",
      "cityCode": 4602,
      "provinceCode": 46,
      "subDistrict": [
        {
          "id": 460201,
          "name": "นามน",
          "postCode": [
            "46230"
          ]
        },
        {
          "id": 460202,
          "name": "ยอดแกง",
          "postCode": [
            "46230"
          ]
        },
        {
          "id": 460203,
          "name": "สงเปลือย",
          "postCode": [
            "46230"
          ]
        },
        {
          "id": 460205,
          "name": "หนองบัว",
          "postCode": [
            "46230"
          ]
        },
        {
          "id": 460204,
          "name": "หลักเหลี่ยม",
          "postCode": [
            "46230"
          ]
        }
      ]
    },
    {
      "city": "ยางตลาด",
      "cityCode": 4607,
      "provinceCode": 46,
      "subDistrict": [
        {
          "id": 460711,
          "name": "คลองขาม",
          "postCode": [
            "46120"
          ]
        },
        {
          "id": 460709,
          "name": "ดอนสมบูรณ์",
          "postCode": [
            "46120"
          ]
        },
        {
          "id": 460713,
          "name": "นาดี",
          "postCode": [
            "46120"
          ]
        },
        {
          "id": 460710,
          "name": "นาเชือก",
          "postCode": [
            "46120"
          ]
        },
        {
          "id": 460704,
          "name": "บัวบาน",
          "postCode": [
            "46120"
          ]
        },
        {
          "id": 460701,
          "name": "ยางตลาด",
          "postCode": [
            "46120"
          ]
        },
        {
          "id": 460715,
          "name": "หนองตอกแป้น",
          "postCode": [
            "46120"
          ]
        },
        {
          "id": 460708,
          "name": "หนองอิเฒ่า",
          "postCode": [
            "46120"
          ]
        },
        {
          "id": 460702,
          "name": "หัวงัว",
          "postCode": [
            "46120"
          ]
        },
        {
          "id": 460707,
          "name": "หัวนาคำ",
          "postCode": [
            "46120"
          ]
        },
        {
          "id": 460706,
          "name": "อิตื้อ",
          "postCode": [
            "46120"
          ]
        },
        {
          "id": 460703,
          "name": "อุ่มเม่า",
          "postCode": [
            "46120"
          ]
        },
        {
          "id": 460712,
          "name": "เขาพระนอน",
          "postCode": [
            "46120"
          ]
        },
        {
          "id": 460705,
          "name": "เว่อ",
          "postCode": [
            "46120"
          ]
        },
        {
          "id": 460714,
          "name": "โนนสูง",
          "postCode": [
            "46120"
          ]
        }
      ]
    },
    {
      "city": "ร่องคำ",
      "cityCode": 4604,
      "provinceCode": 46,
      "subDistrict": [
        {
          "id": 460401,
          "name": "ร่องคำ",
          "postCode": [
            "46210"
          ]
        },
        {
          "id": 460402,
          "name": "สามัคคี",
          "postCode": [
            "46210"
          ]
        },
        {
          "id": 460403,
          "name": "เหล่าอ้อย",
          "postCode": [
            "46210"
          ]
        }
      ]
    },
    {
      "city": "สมเด็จ",
      "cityCode": 4613,
      "provinceCode": 46,
      "subDistrict": [
        {
          "id": 461306,
          "name": "ผาเสวย",
          "postCode": [
            "46150"
          ]
        },
        {
          "id": 461304,
          "name": "มหาไชย",
          "postCode": [
            "46150"
          ]
        },
        {
          "id": 461308,
          "name": "ลำห้วยหลัว",
          "postCode": [
            "46150"
          ]
        },
        {
          "id": 461307,
          "name": "ศรีสมเด็จ",
          "postCode": [
            "46150"
          ]
        },
        {
          "id": 461301,
          "name": "สมเด็จ",
          "postCode": [
            "46150"
          ]
        },
        {
          "id": 461302,
          "name": "หนองแวง",
          "postCode": [
            "46150"
          ]
        },
        {
          "id": 461305,
          "name": "หมูม่น",
          "postCode": [
            "46150"
          ]
        },
        {
          "id": 461303,
          "name": "แซงบาดาล",
          "postCode": [
            "46150"
          ]
        }
      ]
    },
    {
      "city": "สหัสขันธ์",
      "cityCode": 4609,
      "provinceCode": 46,
      "subDistrict": [
        {
          "id": 460903,
          "name": "นามะเขือ",
          "postCode": [
            "46140"
          ]
        },
        {
          "id": 460905,
          "name": "นิคม",
          "postCode": [
            "46140"
          ]
        },
        {
          "id": 460901,
          "name": "ภูสิงห์",
          "postCode": [
            "46140"
          ]
        },
        {
          "id": 460902,
          "name": "สหัสขันธ์",
          "postCode": [
            "46140"
          ]
        },
        {
          "id": 460908,
          "name": "โนนน้ำเกลี้ยง",
          "postCode": [
            "46140"
          ]
        },
        {
          "id": 460907,
          "name": "โนนบุรี",
          "postCode": [
            "46140"
          ]
        },
        {
          "id": 460904,
          "name": "โนนศิลา",
          "postCode": [
            "46140"
          ]
        },
        {
          "id": 460906,
          "name": "โนนแหลมทอง",
          "postCode": [
            "46140"
          ]
        }
      ]
    },
    {
      "city": "สามชัย",
      "cityCode": 4615,
      "provinceCode": 46,
      "subDistrict": [
        {
          "id": 461503,
          "name": "คำสร้างเที่ยง",
          "postCode": [
            "46180"
          ]
        },
        {
          "id": 461501,
          "name": "สำราญ",
          "postCode": [
            "46180"
          ]
        },
        {
          "id": 461502,
          "name": "สำราญใต้",
          "postCode": [
            "46180"
          ]
        },
        {
          "id": 461504,
          "name": "หนองช้าง",
          "postCode": [
            "46180"
          ]
        }
      ]
    },
    {
      "city": "หนองกุงศรี",
      "cityCode": 4612,
      "provinceCode": 46,
      "subDistrict": [
        {
          "id": 461207,
          "name": "ดงมูล",
          "postCode": [
            "46220"
          ]
        },
        {
          "id": 461208,
          "name": "ลำหนองแสน",
          "postCode": [
            "46220"
          ]
        },
        {
          "id": 461201,
          "name": "หนองกุงศรี",
          "postCode": [
            "46220"
          ]
        },
        {
          "id": 461202,
          "name": "หนองบัว",
          "postCode": [
            "46220"
          ]
        },
        {
          "id": 461204,
          "name": "หนองสรวง",
          "postCode": [
            "46220"
          ]
        },
        {
          "id": 461209,
          "name": "หนองหิน",
          "postCode": [
            "46220"
          ]
        },
        {
          "id": 461206,
          "name": "หนองใหญ่",
          "postCode": [
            "46220"
          ]
        },
        {
          "id": 461205,
          "name": "เสาเล้า",
          "postCode": [
            "46220"
          ]
        },
        {
          "id": 461203,
          "name": "โคกเครือ",
          "postCode": [
            "46220"
          ]
        }
      ]
    },
    {
      "city": "ห้วยผึ้ง",
      "cityCode": 4614,
      "provinceCode": 46,
      "subDistrict": [
        {
          "id": 461401,
          "name": "คำบง",
          "postCode": [
            "46240"
          ]
        },
        {
          "id": 461403,
          "name": "นิคมห้วยผึ้ง",
          "postCode": [
            "46240"
          ]
        },
        {
          "id": 461404,
          "name": "หนองอีบุตร",
          "postCode": [
            "46240"
          ]
        },
        {
          "id": 461402,
          "name": "ไค้นุ่น",
          "postCode": [
            "46240"
          ]
        }
      ]
    },
    {
      "city": "ห้วยเม็ก",
      "cityCode": 4608,
      "provinceCode": 46,
      "subDistrict": [
        {
          "id": 460803,
          "name": "กุดโดน",
          "postCode": [
            "46170"
          ]
        },
        {
          "id": 460807,
          "name": "คำเหมือดแก้ว",
          "postCode": [
            "46170"
          ]
        },
        {
          "id": 460802,
          "name": "คำใหญ่",
          "postCode": [
            "46170"
          ]
        },
        {
          "id": 460809,
          "name": "ทรายทอง",
          "postCode": [
            "46170"
          ]
        },
        {
          "id": 460804,
          "name": "บึงนาเรียง",
          "postCode": [
            "46170"
          ]
        },
        {
          "id": 460806,
          "name": "พิมูล",
          "postCode": [
            "46170"
          ]
        },
        {
          "id": 460805,
          "name": "หัวหิน",
          "postCode": [
            "46170"
          ]
        },
        {
          "id": 460801,
          "name": "ห้วยเม็ก",
          "postCode": [
            "46170"
          ]
        },
        {
          "id": 460808,
          "name": "โนนสะอาด",
          "postCode": [
            "46170"
          ]
        }
      ]
    },
    {
      "city": "เขาวง",
      "cityCode": 4606,
      "provinceCode": 46,
      "subDistrict": [
        {
          "id": 460611,
          "name": "กุดปลาค้าว",
          "postCode": [
            "46160"
          ]
        },
        {
          "id": 460606,
          "name": "กุดสิมคุ้มใหม่",
          "postCode": [
            "46160"
          ]
        },
        {
          "id": 460601,
          "name": "คุ้มเก่า",
          "postCode": [
            "46160"
          ]
        },
        {
          "id": 460602,
          "name": "สงเปลือย",
          "postCode": [
            "46160"
          ]
        },
        {
          "id": 460608,
          "name": "สระพังทอง",
          "postCode": [
            "46160"
          ]
        },
        {
          "id": 460603,
          "name": "หนองผือ",
          "postCode": [
            "46160"
          ]
        }
      ]
    },
    {
      "city": "เมืองกาฬสินธุ์",
      "cityCode": 4601,
      "provinceCode": 46,
      "subDistrict": [
        {
          "id": 460116,
          "name": "กลางหมื่น",
          "postCode": [
            "46000"
          ]
        },
        {
          "id": 460101,
          "name": "กาฬสินธุ์",
          "postCode": [
            "46000"
          ]
        },
        {
          "id": 460117,
          "name": "ขมิ้น",
          "postCode": [
            "46000"
          ]
        },
        {
          "id": 460120,
          "name": "นาจารย์",
          "postCode": [
            "46000"
          ]
        },
        {
          "id": 460108,
          "name": "บึงวิชัย",
          "postCode": [
            "46000"
          ]
        },
        {
          "id": 460113,
          "name": "ภูดิน",
          "postCode": [
            "46000"
          ]
        },
        {
          "id": 460111,
          "name": "ภูปอ",
          "postCode": [
            "46000"
          ]
        },
        {
          "id": 460121,
          "name": "ลำคลอง",
          "postCode": [
            "46000"
          ]
        },
        {
          "id": 460105,
          "name": "ลำปาว",
          "postCode": [
            "46000"
          ]
        },
        {
          "id": 460106,
          "name": "ลำพาน",
          "postCode": [
            "46000"
          ]
        },
        {
          "id": 460115,
          "name": "หนองกุง",
          "postCode": [
            "46000"
          ]
        },
        {
          "id": 460103,
          "name": "หลุบ",
          "postCode": [
            "46000"
          ]
        },
        {
          "id": 460109,
          "name": "ห้วยโพธิ์",
          "postCode": [
            "46000"
          ]
        },
        {
          "id": 460107,
          "name": "เชียงเครือ",
          "postCode": [
            "46000"
          ]
        },
        {
          "id": 460102,
          "name": "เหนือ",
          "postCode": [
            "46000"
          ]
        },
        {
          "id": 460119,
          "name": "โพนทอง",
          "postCode": [
            "46000"
          ]
        },
        {
          "id": 460104,
          "name": "ไผ่",
          "postCode": [
            "46000"
          ]
        }
      ]
    },
    {
      "city": "ขาณุวรลักษบุรี",
      "cityCode": 6204,
      "provinceCode": 62,
      "subDistrict": [
        {
          "id": 620408,
          "name": "ดอนแตง",
          "postCode": [
            "62140"
          ]
        },
        {
          "id": 620407,
          "name": "บ่อถ้ำ",
          "postCode": [
            "62140"
          ]
        },
        {
          "id": 620411,
          "name": "ปางมะค่า",
          "postCode": [
            "62140"
          ]
        },
        {
          "id": 620404,
          "name": "ป่าพุทรา",
          "postCode": [
            "62130"
          ]
        },
        {
          "id": 620403,
          "name": "ยางสูง",
          "postCode": [
            "62130"
          ]
        },
        {
          "id": 620409,
          "name": "วังชะพลู",
          "postCode": [
            "62140"
          ]
        },
        {
          "id": 620412,
          "name": "วังหามแห",
          "postCode": [
            "62140"
          ]
        },
        {
          "id": 620406,
          "name": "สลกบาตร",
          "postCode": [
            "62140"
          ]
        },
        {
          "id": 620413,
          "name": "เกาะตาล",
          "postCode": [
            "62130"
          ]
        },
        {
          "id": 620405,
          "name": "แสนตอ",
          "postCode": [
            "62130"
          ]
        },
        {
          "id": 620410,
          "name": "โค้งไผ่",
          "postCode": [
            "62140"
          ]
        }
      ]
    },
    {
      "city": "คลองขลุง",
      "cityCode": 6205,
      "provinceCode": 62,
      "subDistrict": [
        {
          "id": 620501,
          "name": "คลองขลุง",
          "postCode": [
            "62120"
          ]
        },
        {
          "id": 620516,
          "name": "คลองสมบูรณ์",
          "postCode": [
            "62120"
          ]
        },
        {
          "id": 620504,
          "name": "ท่าพุทรา",
          "postCode": [
            "62120"
          ]
        },
        {
          "id": 620502,
          "name": "ท่ามะเขือ",
          "postCode": [
            "62120"
          ]
        },
        {
          "id": 620513,
          "name": "วังบัว",
          "postCode": [
            "62120"
          ]
        },
        {
          "id": 620506,
          "name": "วังยาง",
          "postCode": [
            "62120"
          ]
        },
        {
          "id": 620507,
          "name": "วังแขม",
          "postCode": [
            "62120"
          ]
        },
        {
          "id": 620509,
          "name": "วังไทร",
          "postCode": [
            "62120"
          ]
        },
        {
          "id": 620508,
          "name": "หัวถนน",
          "postCode": [
            "62120"
          ]
        },
        {
          "id": 620505,
          "name": "แม่ลาด",
          "postCode": [
            "62120"
          ]
        }
      ]
    },
    {
      "city": "คลองลาน",
      "cityCode": 6203,
      "provinceCode": 62,
      "subDistrict": [
        {
          "id": 620301,
          "name": "คลองน้ำไหล",
          "postCode": [
            "62180"
          ]
        },
        {
          "id": 620303,
          "name": "คลองลานพัฒนา",
          "postCode": [
            "62180"
          ]
        },
        {
          "id": 620304,
          "name": "สักงาม",
          "postCode": [
            "62180"
          ]
        },
        {
          "id": 620302,
          "name": "โป่งน้ำร้อน",
          "postCode": [
            "62180"
          ]
        }
      ]
    },
    {
      "city": "ทรายทองวัฒนา",
      "cityCode": 6208,
      "provinceCode": 62,
      "subDistrict": [
        {
          "id": 620803,
          "name": "ถาวรวัฒนา",
          "postCode": [
            "62190"
          ]
        },
        {
          "id": 620801,
          "name": "ทุ่งทราย",
          "postCode": [
            "62190"
          ]
        },
        {
          "id": 620802,
          "name": "ทุ่งทอง",
          "postCode": [
            "62190"
          ]
        }
      ]
    },
    {
      "city": "บึงสามัคคี",
      "cityCode": 6210,
      "provinceCode": 62,
      "subDistrict": [
        {
          "id": 621001,
          "name": "บึงสามัคคี",
          "postCode": [
            "62210"
          ]
        },
        {
          "id": 621003,
          "name": "ระหาน",
          "postCode": [
            "62210"
          ]
        },
        {
          "id": 621002,
          "name": "วังชะโอน",
          "postCode": [
            "62210"
          ]
        },
        {
          "id": 621004,
          "name": "เทพนิมิต",
          "postCode": [
            "62210"
          ]
        }
      ]
    },
    {
      "city": "ปางศิลาทอง",
      "cityCode": 6209,
      "provinceCode": 62,
      "subDistrict": [
        {
          "id": 620903,
          "name": "ปางตาไว",
          "postCode": [
            "62120"
          ]
        },
        {
          "id": 620902,
          "name": "หินดาต",
          "postCode": [
            "62120"
          ]
        },
        {
          "id": 620901,
          "name": "โพธิ์ทอง",
          "postCode": [
            "62120"
          ]
        }
      ]
    },
    {
      "city": "พรานกระต่าย",
      "cityCode": 6206,
      "provinceCode": 62,
      "subDistrict": [
        {
          "id": 620608,
          "name": "คลองพิไกร",
          "postCode": [
            "62110"
          ]
        },
        {
          "id": 620607,
          "name": "คุยบ้านโอง",
          "postCode": [
            "62110"
          ]
        },
        {
          "id": 620609,
          "name": "ถ้ำกระต่ายทอง",
          "postCode": [
            "62110"
          ]
        },
        {
          "id": 620603,
          "name": "ท่าไม้",
          "postCode": [
            "62110"
          ]
        },
        {
          "id": 620601,
          "name": "พรานกระต่าย",
          "postCode": [
            "62110"
          ]
        },
        {
          "id": 620604,
          "name": "วังควง",
          "postCode": [
            "62110"
          ]
        },
        {
          "id": 620605,
          "name": "วังตะแบก",
          "postCode": [
            "62110"
          ]
        },
        {
          "id": 620602,
          "name": "หนองหัววัว",
          "postCode": [
            "62110"
          ]
        },
        {
          "id": 620610,
          "name": "ห้วยยั้ง",
          "postCode": [
            "62110"
          ]
        },
        {
          "id": 620606,
          "name": "เขาคีริส",
          "postCode": [
            "62110"
          ]
        }
      ]
    },
    {
      "city": "ลานกระบือ",
      "cityCode": 6207,
      "provinceCode": 62,
      "subDistrict": [
        {
          "id": 620707,
          "name": "จันทิมา",
          "postCode": [
            "62170"
          ]
        },
        {
          "id": 620702,
          "name": "ช่องลม",
          "postCode": [
            "62170"
          ]
        },
        {
          "id": 620706,
          "name": "บึงทับแรต",
          "postCode": [
            "62170"
          ]
        },
        {
          "id": 620705,
          "name": "ประชาสุขสันต์",
          "postCode": [
            "62170"
          ]
        },
        {
          "id": 620701,
          "name": "ลานกระบือ",
          "postCode": [
            "62170"
          ]
        },
        {
          "id": 620703,
          "name": "หนองหลวง",
          "postCode": [
            "62170"
          ]
        },
        {
          "id": 620704,
          "name": "โนนพลวง",
          "postCode": [
            "62170"
          ]
        }
      ]
    },
    {
      "city": "เมืองกำแพงเพชร",
      "cityCode": 6201,
      "provinceCode": 62,
      "subDistrict": [
        {
          "id": 620111,
          "name": "คณฑี",
          "postCode": [
            "62000"
          ]
        },
        {
          "id": 620117,
          "name": "คลองแม่ลาย",
          "postCode": [
            "62000"
          ]
        },
        {
          "id": 620106,
          "name": "ทรงธรรม",
          "postCode": [
            "62000"
          ]
        },
        {
          "id": 620115,
          "name": "ท่าขุนราม",
          "postCode": [
            "62000"
          ]
        },
        {
          "id": 620118,
          "name": "ธำมรงค์",
          "postCode": [
            "62160"
          ]
        },
        {
          "id": 620105,
          "name": "นครชุม",
          "postCode": [
            "62000"
          ]
        },
        {
          "id": 620104,
          "name": "นาบ่อคำ",
          "postCode": [
            "62000"
          ]
        },
        {
          "id": 620112,
          "name": "นิคมทุ่งโพธิ์ทะเล",
          "postCode": [
            "62000"
          ]
        },
        {
          "id": 620107,
          "name": "ลานดอกไม้",
          "postCode": [
            "62000"
          ]
        },
        {
          "id": 620114,
          "name": "วังทอง",
          "postCode": [
            "62000"
          ]
        },
        {
          "id": 620119,
          "name": "สระแก้ว",
          "postCode": [
            "62000"
          ]
        },
        {
          "id": 620110,
          "name": "หนองปลิง",
          "postCode": [
            "62000"
          ]
        },
        {
          "id": 620103,
          "name": "อ่างทอง",
          "postCode": [
            "62000"
          ]
        },
        {
          "id": 620113,
          "name": "เทพนคร",
          "postCode": [
            "62000"
          ]
        },
        {
          "id": 620101,
          "name": "ในเมือง",
          "postCode": [
            "62000"
          ]
        },
        {
          "id": 620102,
          "name": "ไตรตรึงษ์",
          "postCode": [
            "62160"
          ]
        }
      ]
    },
    {
      "city": "โกสัมพีนคร",
      "cityCode": 6211,
      "provinceCode": 62,
      "subDistrict": [
        {
          "id": 621103,
          "name": "ลานดอกไม้ตก",
          "postCode": [
            "62000"
          ]
        },
        {
          "id": 621102,
          "name": "เพชรชมภู",
          "postCode": [
            "62000"
          ]
        },
        {
          "id": 621101,
          "name": "โกสัมพี",
          "postCode": [
            "62000"
          ]
        }
      ]
    },
    {
      "city": "ไทรงาม",
      "cityCode": 6202,
      "provinceCode": 62,
      "subDistrict": [
        {
          "id": 620206,
          "name": "พานทอง",
          "postCode": [
            "62150"
          ]
        },
        {
          "id": 620205,
          "name": "มหาชัย",
          "postCode": [
            "62150"
          ]
        },
        {
          "id": 620202,
          "name": "หนองคล้า",
          "postCode": [
            "62150"
          ]
        },
        {
          "id": 620203,
          "name": "หนองทอง",
          "postCode": [
            "62150"
          ]
        },
        {
          "id": 620207,
          "name": "หนองแม่แตง",
          "postCode": [
            "62150"
          ]
        },
        {
          "id": 620204,
          "name": "หนองไม้กอง",
          "postCode": [
            "62150"
          ]
        },
        {
          "id": 620201,
          "name": "ไทรงาม",
          "postCode": [
            "62150"
          ]
        }
      ]
    },
    {
      "city": "กระนวน",
      "cityCode": 4009,
      "provinceCode": 40,
      "subDistrict": [
        {
          "id": 400909,
          "name": "ดูนสาด",
          "postCode": [
            "40170"
          ]
        },
        {
          "id": 400911,
          "name": "น้ำอ้อม",
          "postCode": [
            "40170"
          ]
        },
        {
          "id": 400907,
          "name": "บ้านฝาง",
          "postCode": [
            "40170"
          ]
        },
        {
          "id": 400902,
          "name": "หนองกุงใหญ่",
          "postCode": [
            "40170"
          ]
        },
        {
          "id": 400901,
          "name": "หนองโก",
          "postCode": [
            "40170"
          ]
        },
        {
          "id": 400910,
          "name": "หนองโน",
          "postCode": [
            "40170"
          ]
        },
        {
          "id": 400912,
          "name": "หัวนาคำ",
          "postCode": [
            "40170"
          ]
        },
        {
          "id": 400906,
          "name": "ห้วยยาง",
          "postCode": [
            "40170"
          ]
        },
        {
          "id": 400905,
          "name": "ห้วยโจด",
          "postCode": [
            "40170"
          ]
        }
      ]
    },
    {
      "city": "ชนบท",
      "cityCode": 4018,
      "provinceCode": 40,
      "subDistrict": [
        {
          "id": 401802,
          "name": "กุดเพียขอม",
          "postCode": [
            "40180"
          ]
        },
        {
          "id": 401801,
          "name": "ชนบท",
          "postCode": [
            "40180"
          ]
        },
        {
          "id": 401805,
          "name": "บ้านแท่น",
          "postCode": [
            "40180"
          ]
        },
        {
          "id": 401808,
          "name": "ปอแดง",
          "postCode": [
            "40180"
          ]
        },
        {
          "id": 401803,
          "name": "วังแสง",
          "postCode": [
            "40180"
          ]
        },
        {
          "id": 401806,
          "name": "ศรีบุญเรือง",
          "postCode": [
            "40180"
          ]
        },
        {
          "id": 401804,
          "name": "ห้วยแก",
          "postCode": [
            "40180"
          ]
        },
        {
          "id": 401807,
          "name": "โนนพะยอม",
          "postCode": [
            "40180"
          ]
        }
      ]
    },
    {
      "city": "ชุมแพ",
      "cityCode": 4005,
      "provinceCode": 40,
      "subDistrict": [
        {
          "id": 400505,
          "name": "ขัวเรียง",
          "postCode": [
            "40130"
          ]
        },
        {
          "id": 400501,
          "name": "ชุมแพ",
          "postCode": [
            "40130"
          ]
        },
        {
          "id": 400503,
          "name": "นาหนองทุ่ม",
          "postCode": [
            "40290"
          ]
        },
        {
          "id": 400509,
          "name": "นาเพียง",
          "postCode": [
            "40130"
          ]
        },
        {
          "id": 400508,
          "name": "วังหินลาด",
          "postCode": [
            "40130"
          ]
        },
        {
          "id": 400510,
          "name": "หนองเขียด",
          "postCode": [
            "40290"
          ]
        },
        {
          "id": 400511,
          "name": "หนองเสาเล้า",
          "postCode": [
            "40130"
          ]
        },
        {
          "id": 400506,
          "name": "หนองไผ่",
          "postCode": [
            "40130"
          ]
        },
        {
          "id": 400512,
          "name": "โนนสะอาด",
          "postCode": [
            "40290"
          ]
        },
        {
          "id": 400502,
          "name": "โนนหัน",
          "postCode": [
            "40290"
          ]
        },
        {
          "id": 400504,
          "name": "โนนอุดม",
          "postCode": [
            "40130"
          ]
        },
        {
          "id": 400507,
          "name": "ไชยสอ",
          "postCode": [
            "40130"
          ]
        }
      ]
    },
    {
      "city": "ซำสูง",
      "cityCode": 4021,
      "provinceCode": 40,
      "subDistrict": [
        {
          "id": 402101,
          "name": "กระนวน",
          "postCode": [
            "40170"
          ]
        },
        {
          "id": 402102,
          "name": "คำแมด",
          "postCode": [
            "40170"
          ]
        },
        {
          "id": 402104,
          "name": "คูคำ",
          "postCode": [
            "40170"
          ]
        },
        {
          "id": 402103,
          "name": "บ้านโนน",
          "postCode": [
            "40170"
          ]
        },
        {
          "id": 402105,
          "name": "ห้วยเตย",
          "postCode": [
            "40170"
          ]
        }
      ]
    },
    {
      "city": "น้ำพอง",
      "cityCode": 4007,
      "provinceCode": 40,
      "subDistrict": [
        {
          "id": 400712,
          "name": "กุดน้ำใส",
          "postCode": [
            "40310"
          ]
        },
        {
          "id": 400709,
          "name": "ทรายมูล",
          "postCode": [
            "40140"
          ]
        },
        {
          "id": 400710,
          "name": "ท่ากระเสริม",
          "postCode": [
            "40140"
          ]
        },
        {
          "id": 400701,
          "name": "น้ำพอง",
          "postCode": [
            "40140",
            "40310"
          ]
        },
        {
          "id": 400708,
          "name": "บัวเงิน",
          "postCode": [
            "40140"
          ]
        },
        {
          "id": 400704,
          "name": "บัวใหญ่",
          "postCode": [
            "40140"
          ]
        },
        {
          "id": 400707,
          "name": "บ้านขาม",
          "postCode": [
            "40140"
          ]
        },
        {
          "id": 400711,
          "name": "พังทุย",
          "postCode": [
            "40140"
          ]
        },
        {
          "id": 400706,
          "name": "ม่วงหวาน",
          "postCode": [
            "40310"
          ]
        },
        {
          "id": 400702,
          "name": "วังชัย",
          "postCode": [
            "40140"
          ]
        },
        {
          "id": 400705,
          "name": "สะอาด",
          "postCode": [
            "40310"
          ]
        },
        {
          "id": 400703,
          "name": "หนองกุง",
          "postCode": [
            "40140"
          ]
        }
      ]
    },
    {
      "city": "บ้านฝาง",
      "cityCode": 4002,
      "provinceCode": 40,
      "subDistrict": [
        {
          "id": 400206,
          "name": "บ้านฝาง",
          "postCode": [
            "40270"
          ]
        },
        {
          "id": 400204,
          "name": "บ้านเหล่า",
          "postCode": [
            "40270"
          ]
        },
        {
          "id": 400205,
          "name": "ป่ามะนาว",
          "postCode": [
            "40270"
          ]
        },
        {
          "id": 400202,
          "name": "ป่าหวายนั่ง",
          "postCode": [
            "40270"
          ]
        },
        {
          "id": 400201,
          "name": "หนองบัว",
          "postCode": [
            "40270"
          ]
        },
        {
          "id": 400207,
          "name": "โคกงาม",
          "postCode": [
            "40270"
          ]
        },
        {
          "id": 400203,
          "name": "โนนฆ้อง",
          "postCode": [
            "40270"
          ]
        }
      ]
    },
    {
      "city": "บ้านแฮด",
      "cityCode": 4024,
      "provinceCode": 40,
      "subDistrict": [
        {
          "id": 402401,
          "name": "บ้านแฮด",
          "postCode": [
            "40110"
          ]
        },
        {
          "id": 402404,
          "name": "หนองแซง",
          "postCode": [
            "40110"
          ]
        },
        {
          "id": 402402,
          "name": "โคกสำราญ",
          "postCode": [
            "40110"
          ]
        },
        {
          "id": 402403,
          "name": "โนนสมบูรณ์",
          "postCode": [
            "40110"
          ]
        }
      ]
    },
    {
      "city": "บ้านไผ่",
      "cityCode": 4010,
      "provinceCode": 40,
      "subDistrict": [
        {
          "id": 401009,
          "name": "บ้านลาน",
          "postCode": [
            "40110"
          ]
        },
        {
          "id": 401001,
          "name": "บ้านไผ่",
          "postCode": [
            "40110"
          ]
        },
        {
          "id": 401013,
          "name": "ป่าปอ",
          "postCode": [
            "40110"
          ]
        },
        {
          "id": 401011,
          "name": "ภูเหล็ก",
          "postCode": [
            "40110"
          ]
        },
        {
          "id": 401016,
          "name": "หนองน้ำใส",
          "postCode": [
            "40110"
          ]
        },
        {
          "id": 401017,
          "name": "หัวหนอง",
          "postCode": [
            "40110"
          ]
        },
        {
          "id": 401014,
          "name": "หินตั้ง",
          "postCode": [
            "40110"
          ]
        },
        {
          "id": 401005,
          "name": "เมืองเพีย",
          "postCode": [
            "40110"
          ]
        },
        {
          "id": 401010,
          "name": "แคนเหนือ",
          "postCode": [
            "40110"
          ]
        },
        {
          "id": 401002,
          "name": "ในเมือง",
          "postCode": [
            "40110"
          ]
        }
      ]
    },
    {
      "city": "พระยืน",
      "cityCode": 4003,
      "provinceCode": 40,
      "subDistrict": [
        {
          "id": 400305,
          "name": "ขามป้อม",
          "postCode": [
            "40320"
          ]
        },
        {
          "id": 400303,
          "name": "บ้านโต้น",
          "postCode": [
            "40320"
          ]
        },
        {
          "id": 400302,
          "name": "พระบุ",
          "postCode": [
            "40320"
          ]
        },
        {
          "id": 400301,
          "name": "พระยืน",
          "postCode": [
            "40320"
          ]
        },
        {
          "id": 400304,
          "name": "หนองแวง",
          "postCode": [
            "40320"
          ]
        }
      ]
    },
    {
      "city": "พล",
      "cityCode": 4012,
      "provinceCode": 40,
      "subDistrict": [
        {
          "id": 401210,
          "name": "ลอมคอม",
          "postCode": [
            "40120"
          ]
        },
        {
          "id": 401205,
          "name": "หนองมะเขือ",
          "postCode": [
            "40120"
          ]
        },
        {
          "id": 401209,
          "name": "หนองแวงนางเบ้า",
          "postCode": [
            "40120"
          ]
        },
        {
          "id": 401206,
          "name": "หนองแวงโสกพระ",
          "postCode": [
            "40120"
          ]
        },
        {
          "id": 401213,
          "name": "หัวทุ่ง",
          "postCode": [
            "40120"
          ]
        },
        {
          "id": 401204,
          "name": "เก่างิ้ว",
          "postCode": [
            "40120"
          ]
        },
        {
          "id": 401207,
          "name": "เพ็กใหญ่",
          "postCode": [
            "40120"
          ]
        },
        {
          "id": 401201,
          "name": "เมืองพล",
          "postCode": [
            "40120"
          ]
        },
        {
          "id": 401208,
          "name": "โคกสง่า",
          "postCode": [
            "40120"
          ]
        },
        {
          "id": 401203,
          "name": "โจดหนองแก",
          "postCode": [
            "40120"
          ]
        },
        {
          "id": 401211,
          "name": "โนนข่า",
          "postCode": [
            "40120"
          ]
        },
        {
          "id": 401212,
          "name": "โสกนกเต็น",
          "postCode": [
            "40120"
          ]
        }
      ]
    },
    {
      "city": "ภูผาม่าน",
      "cityCode": 4020,
      "provinceCode": 40,
      "subDistrict": [
        {
          "id": 402002,
          "name": "นาฝาย",
          "postCode": [
            "40350"
          ]
        },
        {
          "id": 402003,
          "name": "ภูผาม่าน",
          "postCode": [
            "40350"
          ]
        },
        {
          "id": 402004,
          "name": "วังสวาบ",
          "postCode": [
            "40350"
          ]
        },
        {
          "id": 402005,
          "name": "ห้วยม่วง",
          "postCode": [
            "40350"
          ]
        },
        {
          "id": 402001,
          "name": "โนนคอม",
          "postCode": [
            "40350"
          ]
        }
      ]
    },
    {
      "city": "ภูเวียง",
      "cityCode": 4016,
      "provinceCode": 40,
      "subDistrict": [
        {
          "id": 401605,
          "name": "กุดขอนแก่น",
          "postCode": [
            "40150"
          ]
        },
        {
          "id": 401616,
          "name": "ดินดำ",
          "postCode": [
            "40150"
          ]
        },
        {
          "id": 401614,
          "name": "ทุ่งชมพู",
          "postCode": [
            "40150"
          ]
        },
        {
          "id": 401606,
          "name": "นาชุมแสง",
          "postCode": [
            "40150"
          ]
        },
        {
          "id": 401607,
          "name": "นาหว้า",
          "postCode": [
            "40150"
          ]
        },
        {
          "id": 401601,
          "name": "บ้านเรือ",
          "postCode": [
            "40150"
          ]
        },
        {
          "id": 401617,
          "name": "ภูเวียง",
          "postCode": [
            "40150"
          ]
        },
        {
          "id": 401613,
          "name": "สงเปือย",
          "postCode": [
            "40150"
          ]
        },
        {
          "id": 401610,
          "name": "หนองกุงธนสาร",
          "postCode": [
            "40150"
          ]
        },
        {
          "id": 401612,
          "name": "หนองกุงเซิน",
          "postCode": [
            "40150"
          ]
        },
        {
          "id": 401604,
          "name": "หว้าทอง",
          "postCode": [
            "40150"
          ]
        }
      ]
    },
    {
      "city": "มัญจาคีรี",
      "cityCode": 4017,
      "provinceCode": 40,
      "subDistrict": [
        {
          "id": 401701,
          "name": "กุดเค้า",
          "postCode": [
            "40160"
          ]
        },
        {
          "id": 401705,
          "name": "คำแคน",
          "postCode": [
            "40160"
          ]
        },
        {
          "id": 401710,
          "name": "ท่าศาลา",
          "postCode": [
            "40160"
          ]
        },
        {
          "id": 401706,
          "name": "นาข่า",
          "postCode": [
            "40160"
          ]
        },
        {
          "id": 401707,
          "name": "นางาม",
          "postCode": [
            "40160"
          ]
        },
        {
          "id": 401702,
          "name": "สวนหม่อน",
          "postCode": [
            "40160"
          ]
        },
        {
          "id": 401703,
          "name": "หนองแปน",
          "postCode": [
            "40160"
          ]
        },
        {
          "id": 401704,
          "name": "โพนเพ็ก",
          "postCode": [
            "40160"
          ]
        }
      ]
    },
    {
      "city": "สีชมพู",
      "cityCode": 4006,
      "provinceCode": 40,
      "subDistrict": [
        {
          "id": 400605,
          "name": "ซำยาง",
          "postCode": [
            "40220"
          ]
        },
        {
          "id": 400607,
          "name": "ดงลาน",
          "postCode": [
            "40220"
          ]
        },
        {
          "id": 400603,
          "name": "นาจาน",
          "postCode": [
            "40220"
          ]
        },
        {
          "id": 400608,
          "name": "บริบูรณ์",
          "postCode": [
            "40220"
          ]
        },
        {
          "id": 400609,
          "name": "บ้านใหม่",
          "postCode": [
            "40220"
          ]
        },
        {
          "id": 400610,
          "name": "ภูห่าน",
          "postCode": [
            "40220"
          ]
        },
        {
          "id": 400604,
          "name": "วังเพิ่ม",
          "postCode": [
            "40220"
          ]
        },
        {
          "id": 400602,
          "name": "ศรีสุข",
          "postCode": [
            "40220"
          ]
        },
        {
          "id": 400601,
          "name": "สีชมพู",
          "postCode": [
            "40220"
          ]
        },
        {
          "id": 400606,
          "name": "หนองแดง",
          "postCode": [
            "40220"
          ]
        }
      ]
    },
    {
      "city": "หนองนาคำ",
      "cityCode": 4023,
      "provinceCode": 40,
      "subDistrict": [
        {
          "id": 402301,
          "name": "กุดธาตุ",
          "postCode": [
            "40150"
          ]
        },
        {
          "id": 402303,
          "name": "ขนวน",
          "postCode": [
            "40150"
          ]
        },
        {
          "id": 402302,
          "name": "บ้านโคก",
          "postCode": [
            "40150"
          ]
        }
      ]
    },
    {
      "city": "หนองสองห้อง",
      "cityCode": 4015,
      "provinceCode": 40,
      "subDistrict": [
        {
          "id": 401502,
          "name": "คึมชาด",
          "postCode": [
            "40190"
          ]
        },
        {
          "id": 401508,
          "name": "ดงเค็ง",
          "postCode": [
            "40190"
          ]
        },
        {
          "id": 401510,
          "name": "ดอนดั่ง",
          "postCode": [
            "40190"
          ]
        },
        {
          "id": 401507,
          "name": "ดอนดู่",
          "postCode": [
            "40190"
          ]
        },
        {
          "id": 401504,
          "name": "ตะกั่วป่า",
          "postCode": [
            "40190"
          ]
        },
        {
          "id": 401511,
          "name": "วังหิน",
          "postCode": [
            "40190"
          ]
        },
        {
          "id": 401505,
          "name": "สำโรง",
          "postCode": [
            "40190"
          ]
        },
        {
          "id": 401501,
          "name": "หนองสองห้อง",
          "postCode": [
            "40190"
          ]
        },
        {
          "id": 401506,
          "name": "หนองเม็ก",
          "postCode": [
            "40190"
          ]
        },
        {
          "id": 401512,
          "name": "หนองไผ่ล้อม",
          "postCode": [
            "40190"
          ]
        },
        {
          "id": 401509,
          "name": "หันโจด",
          "postCode": [
            "40190"
          ]
        },
        {
          "id": 401503,
          "name": "โนนธาตุ",
          "postCode": [
            "40190"
          ]
        }
      ]
    },
    {
      "city": "หนองเรือ",
      "cityCode": 4004,
      "provinceCode": 40,
      "subDistrict": [
        {
          "id": 400407,
          "name": "กุดกว้าง",
          "postCode": [
            "40210"
          ]
        },
        {
          "id": 400405,
          "name": "จระเข้",
          "postCode": [
            "40240"
          ]
        },
        {
          "id": 400403,
          "name": "บ้านกง",
          "postCode": [
            "40240"
          ]
        },
        {
          "id": 400410,
          "name": "บ้านผือ",
          "postCode": [
            "40240"
          ]
        },
        {
          "id": 400402,
          "name": "บ้านเม็ง",
          "postCode": [
            "40210"
          ]
        },
        {
          "id": 400404,
          "name": "ยางคำ",
          "postCode": [
            "40240"
          ]
        },
        {
          "id": 400401,
          "name": "หนองเรือ",
          "postCode": [
            "40210"
          ]
        },
        {
          "id": 400406,
          "name": "โนนทอง",
          "postCode": [
            "40210"
          ]
        },
        {
          "id": 400408,
          "name": "โนนทัน",
          "postCode": [
            "40210"
          ]
        },
        {
          "id": 400409,
          "name": "โนนสะอาด",
          "postCode": [
            "40210"
          ]
        }
      ]
    },
    {
      "city": "อุบลรัตน์",
      "cityCode": 4008,
      "provinceCode": 40,
      "subDistrict": [
        {
          "id": 400806,
          "name": "ทุ่งโป่ง",
          "postCode": [
            "40250"
          ]
        },
        {
          "id": 400804,
          "name": "นาคำ",
          "postCode": [
            "40250"
          ]
        },
        {
          "id": 400802,
          "name": "บ้านดง",
          "postCode": [
            "40250"
          ]
        },
        {
          "id": 400805,
          "name": "ศรีสุขสำราญ",
          "postCode": [
            "40250"
          ]
        },
        {
          "id": 400803,
          "name": "เขื่อนอุบลรัตน์",
          "postCode": [
            "40250"
          ]
        },
        {
          "id": 400801,
          "name": "โคกสูง",
          "postCode": [
            "40250"
          ]
        }
      ]
    },
    {
      "city": "เขาสวนกวาง",
      "cityCode": 4019,
      "provinceCode": 40,
      "subDistrict": [
        {
          "id": 401905,
          "name": "คำม่วง",
          "postCode": [
            "40280"
          ]
        },
        {
          "id": 401902,
          "name": "ดงเมืองแอม",
          "postCode": [
            "40280"
          ]
        },
        {
          "id": 401903,
          "name": "นางิ้ว",
          "postCode": [
            "40280"
          ]
        },
        {
          "id": 401901,
          "name": "เขาสวนกวาง",
          "postCode": [
            "40280"
          ]
        },
        {
          "id": 401904,
          "name": "โนนสมบูรณ์",
          "postCode": [
            "40280"
          ]
        }
      ]
    },
    {
      "city": "เปือยน้อย",
      "cityCode": 4011,
      "provinceCode": 40,
      "subDistrict": [
        {
          "id": 401103,
          "name": "ขามป้อม",
          "postCode": [
            "40340"
          ]
        },
        {
          "id": 401102,
          "name": "วังม่วง",
          "postCode": [
            "40340"
          ]
        },
        {
          "id": 401104,
          "name": "สระแก้ว",
          "postCode": [
            "40340"
          ]
        },
        {
          "id": 401101,
          "name": "เปือยน้อย",
          "postCode": [
            "40340"
          ]
        }
      ]
    },
    {
      "city": "เมืองขอนแก่น",
      "cityCode": 4001,
      "provinceCode": 40,
      "subDistrict": [
        {
          "id": 400112,
          "name": "ดอนช้าง",
          "postCode": [
            "40000"
          ]
        },
        {
          "id": 400113,
          "name": "ดอนหัน",
          "postCode": [
            "40260"
          ]
        },
        {
          "id": 400104,
          "name": "ท่าพระ",
          "postCode": [
            "40260"
          ]
        },
        {
          "id": 400117,
          "name": "บึงเนียม",
          "postCode": [
            "40000"
          ]
        },
        {
          "id": 400110,
          "name": "บ้านค้อ",
          "postCode": [
            "40000"
          ]
        },
        {
          "id": 400105,
          "name": "บ้านทุ่ม",
          "postCode": [
            "40000"
          ]
        },
        {
          "id": 400109,
          "name": "บ้านหว้า",
          "postCode": [
            "40000"
          ]
        },
        {
          "id": 400115,
          "name": "บ้านเป็ด",
          "postCode": [
            "40000"
          ]
        },
        {
          "id": 400107,
          "name": "พระลับ",
          "postCode": [
            "40000"
          ]
        },
        {
          "id": 400114,
          "name": "ศิลา",
          "postCode": [
            "40000"
          ]
        },
        {
          "id": 400108,
          "name": "สาวะถี",
          "postCode": [
            "40000"
          ]
        },
        {
          "id": 400102,
          "name": "สำราญ",
          "postCode": [
            "40000"
          ]
        },
        {
          "id": 400116,
          "name": "หนองตูม",
          "postCode": [
            "40000"
          ]
        },
        {
          "id": 400106,
          "name": "เมืองเก่า",
          "postCode": [
            "40000"
          ]
        },
        {
          "id": 400111,
          "name": "แดงใหญ่",
          "postCode": [
            "40000"
          ]
        },
        {
          "id": 400103,
          "name": "โคกสี",
          "postCode": [
            "40000"
          ]
        },
        {
          "id": 400118,
          "name": "โนนท่อน",
          "postCode": [
            "40000"
          ]
        },
        {
          "id": 400101,
          "name": "ในเมือง",
          "postCode": [
            "40000",
            "40002"
          ]
        }
      ]
    },
    {
      "city": "เวียงเก่า",
      "cityCode": 4026,
      "provinceCode": 40,
      "subDistrict": [
        {
          "id": 402903,
          "name": "เขาน้อย",
          "postCode": [
            "40150"
          ]
        },
        {
          "id": 402902,
          "name": "เมืองเก่าพัฒนา",
          "postCode": [
            "40150"
          ]
        },
        {
          "id": 402901,
          "name": "ในเมือง",
          "postCode": [
            "40150"
          ]
        }
      ]
    },
    {
      "city": "แวงน้อย",
      "cityCode": 4014,
      "provinceCode": 40,
      "subDistrict": [
        {
          "id": 401402,
          "name": "ก้านเหลือง",
          "postCode": [
            "40230"
          ]
        },
        {
          "id": 401406,
          "name": "ทางขวาง",
          "postCode": [
            "40230"
          ]
        },
        {
          "id": 401403,
          "name": "ท่านางแนว",
          "postCode": [
            "40230"
          ]
        },
        {
          "id": 401405,
          "name": "ท่าวัด",
          "postCode": [
            "40230"
          ]
        },
        {
          "id": 401404,
          "name": "ละหานนา",
          "postCode": [
            "40230"
          ]
        },
        {
          "id": 401401,
          "name": "แวงน้อย",
          "postCode": [
            "40230"
          ]
        }
      ]
    },
    {
      "city": "แวงใหญ่",
      "cityCode": 4013,
      "provinceCode": 40,
      "subDistrict": [
        {
          "id": 401301,
          "name": "คอนฉิม",
          "postCode": [
            "40330"
          ]
        },
        {
          "id": 401304,
          "name": "แวงใหญ่",
          "postCode": [
            "40330"
          ]
        },
        {
          "id": 401303,
          "name": "โนนทอง",
          "postCode": [
            "40330"
          ]
        },
        {
          "id": 401305,
          "name": "โนนสะอาด",
          "postCode": [
            "40330"
          ]
        },
        {
          "id": 401302,
          "name": "ใหม่นาเพียง",
          "postCode": [
            "40330"
          ]
        }
      ]
    },
    {
      "city": "โคกโพธิ์ไชย",
      "cityCode": 4022,
      "provinceCode": 40,
      "subDistrict": [
        {
          "id": 402203,
          "name": "ซับสมบูรณ์",
          "postCode": [
            "40160"
          ]
        },
        {
          "id": 402204,
          "name": "นาแพง",
          "postCode": [
            "40160"
          ]
        },
        {
          "id": 402201,
          "name": "บ้านโคก",
          "postCode": [
            "40160"
          ]
        },
        {
          "id": 402202,
          "name": "โพธิ์ไชย",
          "postCode": [
            "40160"
          ]
        }
      ]
    },
    {
      "city": "โนนศิลา",
      "cityCode": 4025,
      "provinceCode": 40,
      "subDistrict": [
        {
          "id": 402503,
          "name": "บ้านหัน",
          "postCode": [
            "40110"
          ]
        },
        {
          "id": 402502,
          "name": "หนองปลาหมอ",
          "postCode": [
            "40110"
          ]
        },
        {
          "id": 402504,
          "name": "เปือยใหญ่",
          "postCode": [
            "40110"
          ]
        },
        {
          "id": 402501,
          "name": "โนนศิลา",
          "postCode": [
            "40110"
          ]
        },
        {
          "id": 402505,
          "name": "โนนแดง",
          "postCode": [
            "40110"
          ]
        }
      ]
    },
    {
      "city": "ขลุง",
      "cityCode": 2202,
      "provinceCode": 22,
      "subDistrict": [
        {
          "id": 220201,
          "name": "ขลุง",
          "postCode": [
            "22110"
          ]
        },
        {
          "id": 220207,
          "name": "ซึ้ง",
          "postCode": [
            "22110"
          ]
        },
        {
          "id": 220211,
          "name": "ตกพรม",
          "postCode": [
            "22110"
          ]
        },
        {
          "id": 220210,
          "name": "ตรอกนอง",
          "postCode": [
            "22110"
          ]
        },
        {
          "id": 220204,
          "name": "ตะปอน",
          "postCode": [
            "22110"
          ]
        },
        {
          "id": 220205,
          "name": "บางชัน",
          "postCode": [
            "22110"
          ]
        },
        {
          "id": 220202,
          "name": "บ่อ",
          "postCode": [
            "22110"
          ]
        },
        {
          "id": 220212,
          "name": "บ่อเวฬุ",
          "postCode": [
            "22150"
          ]
        },
        {
          "id": 220208,
          "name": "มาบไพ",
          "postCode": [
            "22110"
          ]
        },
        {
          "id": 220209,
          "name": "วังสรรพรส",
          "postCode": [
            "22110"
          ]
        },
        {
          "id": 220206,
          "name": "วันยาว",
          "postCode": [
            "22110"
          ]
        },
        {
          "id": 220203,
          "name": "เกวียนหัก",
          "postCode": [
            "22110"
          ]
        }
      ]
    },
    {
      "city": "ท่าใหม่",
      "cityCode": 2203,
      "provinceCode": 22,
      "subDistrict": [
        {
          "id": 220314,
          "name": "คลองขุด",
          "postCode": [
            "22120"
          ]
        },
        {
          "id": 220313,
          "name": "ตะกาดเง้า",
          "postCode": [
            "22120"
          ]
        },
        {
          "id": 220309,
          "name": "ทุ่งเบญจา",
          "postCode": [
            "22170"
          ]
        },
        {
          "id": 220301,
          "name": "ท่าใหม่",
          "postCode": [
            "22120"
          ]
        },
        {
          "id": 220304,
          "name": "บ่อพุ",
          "postCode": [
            "22120"
          ]
        },
        {
          "id": 220305,
          "name": "พลอยแหวน",
          "postCode": [
            "22120"
          ]
        },
        {
          "id": 220302,
          "name": "ยายร้า",
          "postCode": [
            "22120"
          ]
        },
        {
          "id": 220311,
          "name": "รำพัน",
          "postCode": [
            "22170"
          ]
        },
        {
          "id": 220308,
          "name": "สองพี่น้อง",
          "postCode": [
            "22120"
          ]
        },
        {
          "id": 220303,
          "name": "สีพยา",
          "postCode": [
            "22120"
          ]
        },
        {
          "id": 220307,
          "name": "เขาบายศรี",
          "postCode": [
            "22120"
          ]
        },
        {
          "id": 220306,
          "name": "เขาวัว",
          "postCode": [
            "22120"
          ]
        },
        {
          "id": 220324,
          "name": "เขาแก้ว",
          "postCode": [
            "22170"
          ]
        },
        {
          "id": 220312,
          "name": "โขมง",
          "postCode": [
            "22170"
          ]
        }
      ]
    },
    {
      "city": "นายายอาม",
      "cityCode": 2209,
      "provinceCode": 22,
      "subDistrict": [
        {
          "id": 220903,
          "name": "กระแจะ",
          "postCode": [
            "22170"
          ]
        },
        {
          "id": 220905,
          "name": "ช้างข้าม",
          "postCode": [
            "22160"
          ]
        },
        {
          "id": 220901,
          "name": "นายายอาม",
          "postCode": [
            "22160"
          ]
        },
        {
          "id": 220902,
          "name": "วังโตนด",
          "postCode": [
            "22170"
          ]
        },
        {
          "id": 220906,
          "name": "วังใหม่",
          "postCode": [
            "22170"
          ]
        },
        {
          "id": 220904,
          "name": "สนามไชย",
          "postCode": [
            "22170"
          ]
        }
      ]
    },
    {
      "city": "มะขาม",
      "cityCode": 2205,
      "provinceCode": 22,
      "subDistrict": [
        {
          "id": 220506,
          "name": "ฉมัน",
          "postCode": [
            "22150"
          ]
        },
        {
          "id": 220502,
          "name": "ท่าหลวง",
          "postCode": [
            "22150"
          ]
        },
        {
          "id": 220503,
          "name": "ปัถวี",
          "postCode": [
            "22150"
          ]
        },
        {
          "id": 220501,
          "name": "มะขาม",
          "postCode": [
            "22150"
          ]
        },
        {
          "id": 220504,
          "name": "วังแซ้ม",
          "postCode": [
            "22150"
          ]
        },
        {
          "id": 220508,
          "name": "อ่างคีรี",
          "postCode": [
            "22150"
          ]
        }
      ]
    },
    {
      "city": "สอยดาว",
      "cityCode": 2207,
      "provinceCode": 22,
      "subDistrict": [
        {
          "id": 220704,
          "name": "ทรายขาว",
          "postCode": [
            "22180"
          ]
        },
        {
          "id": 220703,
          "name": "ทับช้าง",
          "postCode": [
            "22180"
          ]
        },
        {
          "id": 220702,
          "name": "ทุ่งขนาน",
          "postCode": [
            "22180"
          ]
        },
        {
          "id": 220701,
          "name": "ปะตง",
          "postCode": [
            "22180"
          ]
        },
        {
          "id": 220705,
          "name": "สะตอน",
          "postCode": [
            "22180"
          ]
        }
      ]
    },
    {
      "city": "เขาคิชฌกูฏ",
      "cityCode": 2210,
      "provinceCode": 22,
      "subDistrict": [
        {
          "id": 221004,
          "name": "คลองพลู",
          "postCode": [
            "22210"
          ]
        },
        {
          "id": 221005,
          "name": "จันทเขลม",
          "postCode": [
            "22210"
          ]
        },
        {
          "id": 221001,
          "name": "ชากไทย",
          "postCode": [
            "22210"
          ]
        },
        {
          "id": 221003,
          "name": "ตะเคียนทอง",
          "postCode": [
            "22210"
          ]
        },
        {
          "id": 221002,
          "name": "พลวง",
          "postCode": [
            "22210"
          ]
        }
      ]
    },
    {
      "city": "เมืองจันทบุรี",
      "cityCode": 2201,
      "provinceCode": 22,
      "subDistrict": [
        {
          "id": 220105,
          "name": "คมบาง",
          "postCode": [
            "22000"
          ]
        },
        {
          "id": 220103,
          "name": "คลองนารายณ์",
          "postCode": [
            "22000"
          ]
        },
        {
          "id": 220107,
          "name": "จันทนิมิต",
          "postCode": [
            "22000"
          ]
        },
        {
          "id": 220101,
          "name": "ตลาด",
          "postCode": [
            "22000"
          ]
        },
        {
          "id": 220106,
          "name": "ท่าช้าง",
          "postCode": [
            "22000"
          ]
        },
        {
          "id": 220108,
          "name": "บางกะจะ",
          "postCode": [
            "22000"
          ]
        },
        {
          "id": 220111,
          "name": "พลับพลา",
          "postCode": [
            "22000"
          ]
        },
        {
          "id": 220102,
          "name": "วัดใหม่",
          "postCode": [
            "22000"
          ]
        },
        {
          "id": 220110,
          "name": "หนองบัว",
          "postCode": [
            "22000"
          ]
        },
        {
          "id": 220104,
          "name": "เกาะขวาง",
          "postCode": [
            "22000"
          ]
        },
        {
          "id": 220109,
          "name": "แสลง",
          "postCode": [
            "22000"
          ]
        }
      ]
    },
    {
      "city": "แก่งหางแมว",
      "cityCode": 2208,
      "provinceCode": 22,
      "subDistrict": [
        {
          "id": 220802,
          "name": "ขุนซ่อง",
          "postCode": [
            "22160"
          ]
        },
        {
          "id": 220804,
          "name": "พวา",
          "postCode": [
            "22160"
          ]
        },
        {
          "id": 220803,
          "name": "สามพี่น้อง",
          "postCode": [
            "22160"
          ]
        },
        {
          "id": 220805,
          "name": "เขาวงกต",
          "postCode": [
            "22160"
          ]
        },
        {
          "id": 220801,
          "name": "แก่งหางแมว",
          "postCode": [
            "22160"
          ]
        }
      ]
    },
    {
      "city": "แหลมสิงห์",
      "cityCode": 2206,
      "provinceCode": 22,
      "subDistrict": [
        {
          "id": 220605,
          "name": "คลองน้ำเค็ม",
          "postCode": [
            "22190"
          ]
        },
        {
          "id": 220607,
          "name": "บางกะไชย",
          "postCode": [
            "22120"
          ]
        },
        {
          "id": 220606,
          "name": "บางสระเก้า",
          "postCode": [
            "22190"
          ]
        },
        {
          "id": 220601,
          "name": "ปากน้ำแหลมสิงห์",
          "postCode": [
            "22130"
          ]
        },
        {
          "id": 220604,
          "name": "พลิ้ว",
          "postCode": [
            "22190"
          ]
        },
        {
          "id": 220603,
          "name": "หนองชิ่ม",
          "postCode": [
            "22130"
          ]
        },
        {
          "id": 220602,
          "name": "เกาะเปริด",
          "postCode": [
            "22130"
          ]
        }
      ]
    },
    {
      "city": "โป่งน้ำร้อน",
      "cityCode": 2204,
      "provinceCode": 22,
      "subDistrict": [
        {
          "id": 220410,
          "name": "คลองใหญ่",
          "postCode": [
            "22140"
          ]
        },
        {
          "id": 220401,
          "name": "ทับไทร",
          "postCode": [
            "22140"
          ]
        },
        {
          "id": 220404,
          "name": "หนองตาคง",
          "postCode": [
            "22140"
          ]
        },
        {
          "id": 220409,
          "name": "เทพนิมิต",
          "postCode": [
            "22140"
          ]
        },
        {
          "id": 220402,
          "name": "โป่งน้ำร้อน",
          "postCode": [
            "22140"
          ]
        }
      ]
    },
    {
      "city": "คลองเขื่อน",
      "cityCode": 2411,
      "provinceCode": 24,
      "subDistrict": [
        {
          "id": 241101,
          "name": "ก้อนแก้ว",
          "postCode": [
            "24000"
          ]
        },
        {
          "id": 241102,
          "name": "คลองเขื่อน",
          "postCode": [
            "24000"
          ]
        },
        {
          "id": 241105,
          "name": "บางตลาด",
          "postCode": [
            "24110"
          ]
        },
        {
          "id": 241103,
          "name": "บางเล่า",
          "postCode": [
            "24000"
          ]
        },
        {
          "id": 241104,
          "name": "บางโรง",
          "postCode": [
            "24000"
          ]
        }
      ]
    },
    {
      "city": "ท่าตะเกียบ",
      "cityCode": 2410,
      "provinceCode": 24,
      "subDistrict": [
        {
          "id": 241002,
          "name": "คลองตะเกรา",
          "postCode": [
            "24160"
          ]
        },
        {
          "id": 241001,
          "name": "ท่าตะเกียบ",
          "postCode": [
            "24160"
          ]
        }
      ]
    },
    {
      "city": "บางคล้า",
      "cityCode": 2402,
      "provinceCode": 24,
      "subDistrict": [
        {
          "id": 240210,
          "name": "ท่าทองหลาง",
          "postCode": [
            "24110"
          ]
        },
        {
          "id": 240208,
          "name": "บางกระเจ็ด",
          "postCode": [
            "24110"
          ]
        },
        {
          "id": 240201,
          "name": "บางคล้า",
          "postCode": [
            "24110"
          ]
        },
        {
          "id": 240204,
          "name": "บางสวน",
          "postCode": [
            "24110"
          ]
        },
        {
          "id": 240209,
          "name": "ปากน้ำ",
          "postCode": [
            "24110"
          ]
        },
        {
          "id": 240211,
          "name": "สาวชะโงก",
          "postCode": [
            "24110"
          ]
        },
        {
          "id": 240214,
          "name": "หัวไทร",
          "postCode": [
            "24110"
          ]
        },
        {
          "id": 240212,
          "name": "เสม็ดเหนือ",
          "postCode": [
            "24110"
          ]
        },
        {
          "id": 240213,
          "name": "เสม็ดใต้",
          "postCode": [
            "24110"
          ]
        }
      ]
    },
    {
      "city": "บางน้ำเปรี้ยว",
      "cityCode": 2403,
      "provinceCode": 24,
      "subDistrict": [
        {
          "id": 240308,
          "name": "ดอนฉิมพลี",
          "postCode": [
            "24170"
          ]
        },
        {
          "id": 240306,
          "name": "ดอนเกาะกา",
          "postCode": [
            "24170"
          ]
        },
        {
          "id": 240302,
          "name": "บางขนาก",
          "postCode": [
            "24150"
          ]
        },
        {
          "id": 240301,
          "name": "บางน้ำเปรี้ยว",
          "postCode": [
            "24150"
          ]
        },
        {
          "id": 240305,
          "name": "บึงน้ำรักษ์",
          "postCode": [
            "24170"
          ]
        },
        {
          "id": 240309,
          "name": "ศาลาแดง",
          "postCode": [
            "24000"
          ]
        },
        {
          "id": 240303,
          "name": "สิงโตทอง",
          "postCode": [
            "24150"
          ]
        },
        {
          "id": 240304,
          "name": "หมอนทอง",
          "postCode": [
            "24150"
          ]
        },
        {
          "id": 240310,
          "name": "โพรงอากาศ",
          "postCode": [
            "24150"
          ]
        },
        {
          "id": 240307,
          "name": "โยธะกา",
          "postCode": [
            "24150"
          ]
        }
      ]
    },
    {
      "city": "บางปะกง",
      "cityCode": 2404,
      "provinceCode": 24,
      "subDistrict": [
        {
          "id": 240410,
          "name": "ท่าข้าม",
          "postCode": [
            "24130"
          ]
        },
        {
          "id": 240402,
          "name": "ท่าสะอ้าน",
          "postCode": [
            "24130"
          ]
        },
        {
          "id": 240401,
          "name": "บางปะกง",
          "postCode": [
            "24130"
          ]
        },
        {
          "id": 240405,
          "name": "บางผึ้ง",
          "postCode": [
            "24130"
          ]
        },
        {
          "id": 240403,
          "name": "บางวัว",
          "postCode": [
            "24180"
          ]
        },
        {
          "id": 240404,
          "name": "บางสมัคร",
          "postCode": [
            "24180"
          ]
        },
        {
          "id": 240406,
          "name": "บางเกลือ",
          "postCode": [
            "24180"
          ]
        },
        {
          "id": 240409,
          "name": "พิมพา",
          "postCode": [
            "24180"
          ]
        },
        {
          "id": 240407,
          "name": "สองคลอง",
          "postCode": [
            "24130"
          ]
        },
        {
          "id": 240408,
          "name": "หนองจอก",
          "postCode": [
            "24130"
          ]
        },
        {
          "id": 240411,
          "name": "หอมศีล",
          "postCode": [
            "24180"
          ]
        },
        {
          "id": 240412,
          "name": "เขาดิน",
          "postCode": [
            "24130"
          ]
        }
      ]
    },
    {
      "city": "บ้านโพธิ์",
      "cityCode": 2405,
      "provinceCode": 24,
      "subDistrict": [
        {
          "id": 240503,
          "name": "คลองขุด",
          "postCode": [
            "24140"
          ]
        },
        {
          "id": 240504,
          "name": "คลองบ้านโพธิ์",
          "postCode": [
            "24140"
          ]
        },
        {
          "id": 240505,
          "name": "คลองประเวศ",
          "postCode": [
            "24140"
          ]
        },
        {
          "id": 240506,
          "name": "ดอนทราย",
          "postCode": [
            "24140"
          ]
        },
        {
          "id": 240508,
          "name": "ท่าพลับ",
          "postCode": [
            "24140"
          ]
        },
        {
          "id": 240512,
          "name": "บางกรูด",
          "postCode": [
            "24140"
          ]
        },
        {
          "id": 240511,
          "name": "บางซ่อน",
          "postCode": [
            "24140"
          ]
        },
        {
          "id": 240501,
          "name": "บ้านโพธิ์",
          "postCode": [
            "24140"
          ]
        },
        {
          "id": 240514,
          "name": "ลาดขวาง",
          "postCode": [
            "24140"
          ]
        },
        {
          "id": 240515,
          "name": "สนามจันทร์",
          "postCode": [
            "24140"
          ]
        },
        {
          "id": 240517,
          "name": "สิบเอ็ดศอก",
          "postCode": [
            "24140"
          ]
        },
        {
          "id": 240509,
          "name": "หนองตีนนก",
          "postCode": [
            "24140"
          ]
        },
        {
          "id": 240510,
          "name": "หนองบัว",
          "postCode": [
            "24140"
          ]
        },
        {
          "id": 240502,
          "name": "เกาะไร่",
          "postCode": [
            "24140"
          ]
        },
        {
          "id": 240507,
          "name": "เทพราช",
          "postCode": [
            "24140"
          ]
        },
        {
          "id": 240516,
          "name": "แสนภูดาษ",
          "postCode": [
            "24140"
          ]
        },
        {
          "id": 240513,
          "name": "แหลมประดู่",
          "postCode": [
            "24140"
          ]
        }
      ]
    },
    {
      "city": "พนมสารคาม",
      "cityCode": 2406,
      "provinceCode": 24,
      "subDistrict": [
        {
          "id": 240606,
          "name": "ท่าถ่าน",
          "postCode": [
            "24120"
          ]
        },
        {
          "id": 240602,
          "name": "บ้านซ่อง",
          "postCode": [
            "24120"
          ]
        },
        {
          "id": 240603,
          "name": "พนมสารคาม",
          "postCode": [
            "24120"
          ]
        },
        {
          "id": 240605,
          "name": "หนองยาว",
          "postCode": [
            "24120"
          ]
        },
        {
          "id": 240607,
          "name": "หนองแหน",
          "postCode": [
            "24120"
          ]
        },
        {
          "id": 240601,
          "name": "เกาะขนุน",
          "postCode": [
            "24120"
          ]
        },
        {
          "id": 240608,
          "name": "เขาหินซ้อน",
          "postCode": [
            "24120"
          ]
        },
        {
          "id": 240604,
          "name": "เมืองเก่า",
          "postCode": [
            "24120"
          ]
        }
      ]
    },
    {
      "city": "ราชสาส์น",
      "cityCode": 2407,
      "provinceCode": 24,
      "subDistrict": [
        {
          "id": 240703,
          "name": "ดงน้อย",
          "postCode": [
            "24120"
          ]
        },
        {
          "id": 240701,
          "name": "บางคา",
          "postCode": [
            "24120"
          ]
        },
        {
          "id": 240702,
          "name": "เมืองใหม่",
          "postCode": [
            "24120"
          ]
        }
      ]
    },
    {
      "city": "สนามชัยเขต",
      "cityCode": 2408,
      "provinceCode": 24,
      "subDistrict": [
        {
          "id": 240801,
          "name": "คู้ยายหมี",
          "postCode": [
            "24160"
          ]
        },
        {
          "id": 240803,
          "name": "ทุ่งพระยา",
          "postCode": [
            "24160"
          ]
        },
        {
          "id": 240802,
          "name": "ท่ากระดาน",
          "postCode": [
            "24160"
          ]
        },
        {
          "id": 240805,
          "name": "ลาดกระทิง",
          "postCode": [
            "24160"
          ]
        }
      ]
    },
    {
      "city": "เมืองฉะเชิงเทรา",
      "cityCode": 2401,
      "provinceCode": 24,
      "subDistrict": [
        {
          "id": 240107,
          "name": "คลองจุกกระเฌอ",
          "postCode": [
            "24000"
          ]
        },
        {
          "id": 240110,
          "name": "คลองนครเนื่องเขต",
          "postCode": [
            "24000"
          ]
        },
        {
          "id": 240104,
          "name": "คลองนา",
          "postCode": [
            "24000"
          ]
        },
        {
          "id": 240118,
          "name": "คลองหลวงแพ่ง",
          "postCode": [
            "24000"
          ]
        },
        {
          "id": 240117,
          "name": "คลองอุดมชลจร",
          "postCode": [
            "24000"
          ]
        },
        {
          "id": 240116,
          "name": "คลองเปรง",
          "postCode": [
            "24000"
          ]
        },
        {
          "id": 240102,
          "name": "ท่าไข่",
          "postCode": [
            "24000"
          ]
        },
        {
          "id": 240114,
          "name": "บางกะไห",
          "postCode": [
            "24000"
          ]
        },
        {
          "id": 240109,
          "name": "บางขวัญ",
          "postCode": [
            "24000"
          ]
        },
        {
          "id": 240105,
          "name": "บางตีนเป็ด",
          "postCode": [
            "24000"
          ]
        },
        {
          "id": 240113,
          "name": "บางพระ",
          "postCode": [
            "24000"
          ]
        },
        {
          "id": 240119,
          "name": "บางเตย",
          "postCode": [
            "24000"
          ]
        },
        {
          "id": 240108,
          "name": "บางแก้ว",
          "postCode": [
            "24000"
          ]
        },
        {
          "id": 240106,
          "name": "บางไผ่",
          "postCode": [
            "24000"
          ]
        },
        {
          "id": 240103,
          "name": "บ้านใหม่",
          "postCode": [
            "24000"
          ]
        },
        {
          "id": 240111,
          "name": "วังตะเคียน",
          "postCode": [
            "24000"
          ]
        },
        {
          "id": 240115,
          "name": "หนามแดง",
          "postCode": [
            "24000"
          ]
        },
        {
          "id": 240101,
          "name": "หน้าเมือง",
          "postCode": [
            "24000"
          ]
        },
        {
          "id": 240112,
          "name": "โสธร",
          "postCode": [
            "24000"
          ]
        }
      ]
    },
    {
      "city": "แปลงยาว",
      "cityCode": 2409,
      "provinceCode": 24,
      "subDistrict": [
        {
          "id": 240902,
          "name": "วังเย็น",
          "postCode": [
            "24190"
          ]
        },
        {
          "id": 240904,
          "name": "หนองไม้แก่น",
          "postCode": [
            "24190"
          ]
        },
        {
          "id": 240903,
          "name": "หัวสำโรง",
          "postCode": [
            "24190"
          ]
        },
        {
          "id": 240901,
          "name": "แปลงยาว",
          "postCode": [
            "24190"
          ]
        }
      ]
    },
    {
      "city": "บางละมุง",
      "cityCode": 2004,
      "provinceCode": 20,
      "subDistrict": [
        {
          "id": 200407,
          "name": "ตะเคียนเตี้ย",
          "postCode": [
            "20150"
          ]
        },
        {
          "id": 200408,
          "name": "นาเกลือ",
          "postCode": [
            "20150"
          ]
        },
        {
          "id": 200401,
          "name": "บางละมุง",
          "postCode": [
            "20150"
          ]
        },
        {
          "id": 200402,
          "name": "หนองปรือ",
          "postCode": [
            "20150"
          ]
        },
        {
          "id": 200403,
          "name": "หนองปลาไหล",
          "postCode": [
            "20150"
          ]
        },
        {
          "id": 200406,
          "name": "ห้วยใหญ่",
          "postCode": [
            "20150"
          ]
        },
        {
          "id": 200405,
          "name": "เขาไม้แก้ว",
          "postCode": [
            "20150"
          ]
        },
        {
          "id": 200404,
          "name": "โป่ง",
          "postCode": [
            "20150"
          ]
        }
      ]
    },
    {
      "city": "บ่อทอง",
      "cityCode": 2010,
      "provinceCode": 20,
      "subDistrict": [
        {
          "id": 201004,
          "name": "ธาตุทอง",
          "postCode": [
            "20270"
          ]
        },
        {
          "id": 201003,
          "name": "บ่อกวางทอง",
          "postCode": [
            "20270"
          ]
        },
        {
          "id": 201001,
          "name": "บ่อทอง",
          "postCode": [
            "20270"
          ]
        },
        {
          "id": 201006,
          "name": "พลวงทอง",
          "postCode": [
            "20270"
          ]
        },
        {
          "id": 201002,
          "name": "วัดสุวรรณ",
          "postCode": [
            "20270"
          ]
        },
        {
          "id": 201005,
          "name": "เกษตรสุวรรณ",
          "postCode": [
            "20270"
          ]
        }
      ]
    },
    {
      "city": "บ้านบึง",
      "cityCode": 2002,
      "provinceCode": 20,
      "subDistrict": [
        {
          "id": 200202,
          "name": "คลองกิ่ว",
          "postCode": [
            "20220"
          ]
        },
        {
          "id": 200201,
          "name": "บ้านบึง",
          "postCode": [
            "20170"
          ]
        },
        {
          "id": 200203,
          "name": "มาบไผ่",
          "postCode": [
            "20170"
          ]
        },
        {
          "id": 200206,
          "name": "หนองชาก",
          "postCode": [
            "20170"
          ]
        },
        {
          "id": 200204,
          "name": "หนองซ้ำซาก",
          "postCode": [
            "20170"
          ]
        },
        {
          "id": 200205,
          "name": "หนองบอนแดง",
          "postCode": [
            "20170"
          ]
        },
        {
          "id": 200207,
          "name": "หนองอิรุณ",
          "postCode": [
            "20220"
          ]
        },
        {
          "id": 200208,
          "name": "หนองไผ่แก้ว",
          "postCode": [
            "20220"
          ]
        }
      ]
    },
    {
      "city": "พนัสนิคม",
      "cityCode": 2006,
      "provinceCode": 20,
      "subDistrict": [
        {
          "id": 200609,
          "name": "กุฎโง้ง",
          "postCode": [
            "20140"
          ]
        },
        {
          "id": 200615,
          "name": "ทุ่งขวาง",
          "postCode": [
            "20140"
          ]
        },
        {
          "id": 200611,
          "name": "ท่าข้าม",
          "postCode": [
            "20140"
          ]
        },
        {
          "id": 200622,
          "name": "นามะตูม",
          "postCode": [
            "20140"
          ]
        },
        {
          "id": 200617,
          "name": "นาวังหิน",
          "postCode": [
            "20140",
            "20240"
          ]
        },
        {
          "id": 200605,
          "name": "นาเริก",
          "postCode": [
            "20140",
            "20240"
          ]
        },
        {
          "id": 200618,
          "name": "บ้านช้าง",
          "postCode": [
            "20140"
          ]
        },
        {
          "id": 200604,
          "name": "บ้านเซิด",
          "postCode": [
            "20140"
          ]
        },
        {
          "id": 200601,
          "name": "พนัสนิคม",
          "postCode": [
            "20140"
          ]
        },
        {
          "id": 200603,
          "name": "วัดหลวง",
          "postCode": [
            "20140"
          ]
        },
        {
          "id": 200608,
          "name": "วัดโบสถ์",
          "postCode": [
            "20140"
          ]
        },
        {
          "id": 200607,
          "name": "สระสี่เหลี่ยม",
          "postCode": [
            "20140"
          ]
        },
        {
          "id": 200614,
          "name": "หนองขยาด",
          "postCode": [
            "20140"
          ]
        },
        {
          "id": 200613,
          "name": "หนองปรือ",
          "postCode": [
            "20140"
          ]
        },
        {
          "id": 200616,
          "name": "หนองเหียง",
          "postCode": [
            "20140"
          ]
        },
        {
          "id": 200602,
          "name": "หน้าพระธาตุ",
          "postCode": [
            "20140"
          ]
        },
        {
          "id": 200606,
          "name": "หมอนนาง",
          "postCode": [
            "20140"
          ]
        },
        {
          "id": 200610,
          "name": "หัวถนน",
          "postCode": [
            "20140"
          ]
        },
        {
          "id": 200620,
          "name": "โคกเพลาะ",
          "postCode": [
            "20140"
          ]
        },
        {
          "id": 200621,
          "name": "ไร่หลักทอง",
          "postCode": [
            "20140"
          ]
        }
      ]
    },
    {
      "city": "พานทอง",
      "cityCode": 2005,
      "provinceCode": 20,
      "subDistrict": [
        {
          "id": 200509,
          "name": "บางนาง",
          "postCode": [
            "20160"
          ]
        },
        {
          "id": 200511,
          "name": "บางหัก",
          "postCode": [
            "20160"
          ]
        },
        {
          "id": 200507,
          "name": "บ้านเก่า",
          "postCode": [
            "20000",
            "20160"
          ]
        },
        {
          "id": 200501,
          "name": "พานทอง",
          "postCode": [
            "20160"
          ]
        },
        {
          "id": 200503,
          "name": "มาบโป่ง",
          "postCode": [
            "20160"
          ]
        },
        {
          "id": 200504,
          "name": "หนองกะขะ",
          "postCode": [
            "20160"
          ]
        },
        {
          "id": 200502,
          "name": "หนองตำลึง",
          "postCode": [
            "20160"
          ]
        },
        {
          "id": 200505,
          "name": "หนองหงษ์",
          "postCode": [
            "20160"
          ]
        },
        {
          "id": 200508,
          "name": "หน้าประดู่",
          "postCode": [
            "20160"
          ]
        },
        {
          "id": 200510,
          "name": "เกาะลอย",
          "postCode": [
            "20160"
          ]
        },
        {
          "id": 200506,
          "name": "โคกขี้หนอน",
          "postCode": [
            "20160"
          ]
        }
      ]
    },
    {
      "city": "ศรีราชา",
      "cityCode": 2007,
      "provinceCode": 20,
      "subDistrict": [
        {
          "id": 200703,
          "name": "ทุ่งสุขลา",
          "postCode": [
            "20230"
          ]
        },
        {
          "id": 200707,
          "name": "บางพระ",
          "postCode": [
            "20110"
          ]
        },
        {
          "id": 200704,
          "name": "บึง",
          "postCode": [
            "20230"
          ]
        },
        {
          "id": 200708,
          "name": "บ่อวิน",
          "postCode": [
            "20230"
          ]
        },
        {
          "id": 200701,
          "name": "ศรีราชา",
          "postCode": [
            "20110"
          ]
        },
        {
          "id": 200702,
          "name": "สุรศักดิ์",
          "postCode": [
            "20110"
          ]
        },
        {
          "id": 200705,
          "name": "หนองขาม",
          "postCode": [
            "20110",
            "20230"
          ]
        },
        {
          "id": 200706,
          "name": "เขาคันทรง",
          "postCode": [
            "20110"
          ]
        }
      ]
    },
    {
      "city": "สัตหีบ",
      "cityCode": 2009,
      "provinceCode": 20,
      "subDistrict": [
        {
          "id": 200902,
          "name": "นาจอมเทียน",
          "postCode": [
            "20250"
          ]
        },
        {
          "id": 200904,
          "name": "บางเสร่",
          "postCode": [
            "20250",
            "20251"
          ]
        },
        {
          "id": 200903,
          "name": "พลูตาหลวง",
          "postCode": [
            "20180"
          ]
        },
        {
          "id": 200901,
          "name": "สัตหีบ",
          "postCode": [
            "20180",
            "20182"
          ]
        },
        {
          "id": 200905,
          "name": "แสมสาร",
          "postCode": [
            "20180"
          ]
        }
      ]
    },
    {
      "city": "หนองใหญ่",
      "cityCode": 2003,
      "provinceCode": 20,
      "subDistrict": [
        {
          "id": 200302,
          "name": "คลองพลู",
          "postCode": [
            "20190"
          ]
        },
        {
          "id": 200303,
          "name": "หนองเสือช้าง",
          "postCode": [
            "20190"
          ]
        },
        {
          "id": 200301,
          "name": "หนองใหญ่",
          "postCode": [
            "20190"
          ]
        },
        {
          "id": 200304,
          "name": "ห้างสูง",
          "postCode": [
            "20190"
          ]
        },
        {
          "id": 200305,
          "name": "เขาซก",
          "postCode": [
            "20190"
          ]
        }
      ]
    },
    {
      "city": "เกาะจันทร์",
      "cityCode": 2011,
      "provinceCode": 20,
      "subDistrict": [
        {
          "id": 201102,
          "name": "ท่าบุญมี",
          "postCode": [
            "20240"
          ]
        },
        {
          "id": 201101,
          "name": "เกาะจันทร์",
          "postCode": [
            "20240"
          ]
        }
      ]
    },
    {
      "city": "เกาะสีชัง",
      "cityCode": 2008,
      "provinceCode": 20,
      "subDistrict": [
        {
          "id": 200801,
          "name": "ท่าเทววงษ์",
          "postCode": [
            "20120"
          ]
        }
      ]
    },
    {
      "city": "เมืองชลบุรี",
      "cityCode": 2001,
      "provinceCode": 20,
      "subDistrict": [
        {
          "id": 200112,
          "name": "คลองตำหรุ",
          "postCode": [
            "20000"
          ]
        },
        {
          "id": 200109,
          "name": "ดอนหัวฬ่อ",
          "postCode": [
            "20000"
          ]
        },
        {
          "id": 200107,
          "name": "นาป่า",
          "postCode": [
            "20000"
          ]
        },
        {
          "id": 200111,
          "name": "บางทราย",
          "postCode": [
            "20000"
          ]
        },
        {
          "id": 200101,
          "name": "บางปลาสร้อย",
          "postCode": [
            "20000"
          ]
        },
        {
          "id": 200114,
          "name": "บ้านปึก",
          "postCode": [
            "20130"
          ]
        },
        {
          "id": 200105,
          "name": "บ้านสวน",
          "postCode": [
            "20000"
          ]
        },
        {
          "id": 200103,
          "name": "บ้านโขด",
          "postCode": [
            "20000"
          ]
        },
        {
          "id": 200102,
          "name": "มะขามหย่ง",
          "postCode": [
            "20000"
          ]
        },
        {
          "id": 200118,
          "name": "สำนักบก",
          "postCode": [
            "20000"
          ]
        },
        {
          "id": 200108,
          "name": "หนองข้างคอก",
          "postCode": [
            "20000"
          ]
        },
        {
          "id": 200106,
          "name": "หนองรี",
          "postCode": [
            "20000"
          ]
        },
        {
          "id": 200110,
          "name": "หนองไม้แดง",
          "postCode": [
            "20000"
          ]
        },
        {
          "id": 200115,
          "name": "ห้วยกะปิ",
          "postCode": [
            "20000",
            "20130"
          ]
        },
        {
          "id": 200117,
          "name": "อ่างศิลา",
          "postCode": [
            "20000"
          ]
        },
        {
          "id": 200116,
          "name": "เสม็ด",
          "postCode": [
            "20000",
            "20130"
          ]
        },
        {
          "id": 200113,
          "name": "เหมือง",
          "postCode": [
            "20130"
          ]
        },
        {
          "id": 200104,
          "name": "แสนสุข",
          "postCode": [
            "20130",
            "20131"
          ]
        }
      ]
    },
    {
      "city": "มโนรมย์",
      "cityCode": 1802,
      "provinceCode": 18,
      "subDistrict": [
        {
          "id": 180201,
          "name": "คุ้งสำเภา",
          "postCode": [
            "17110"
          ]
        },
        {
          "id": 180204,
          "name": "ท่าฉนวน",
          "postCode": [
            "17110"
          ]
        },
        {
          "id": 180202,
          "name": "วัดโคก",
          "postCode": [
            "17110",
            "17170"
          ]
        },
        {
          "id": 180203,
          "name": "ศิลาดาน",
          "postCode": [
            "17110"
          ]
        },
        {
          "id": 180205,
          "name": "หางน้ำสาคร",
          "postCode": [
            "17170"
          ]
        },
        {
          "id": 180207,
          "name": "อู่ตะเภา",
          "postCode": [
            "17170"
          ]
        },
        {
          "id": 180206,
          "name": "ไร่พัฒนา",
          "postCode": [
            "17170"
          ]
        }
      ]
    },
    {
      "city": "วัดสิงห์",
      "cityCode": 1803,
      "provinceCode": 18,
      "subDistrict": [
        {
          "id": 180307,
          "name": "บ่อแร่",
          "postCode": [
            "17120"
          ]
        },
        {
          "id": 180302,
          "name": "มะขามเฒ่า",
          "postCode": [
            "17120"
          ]
        },
        {
          "id": 180311,
          "name": "วังหมัน",
          "postCode": [
            "17120"
          ]
        },
        {
          "id": 180301,
          "name": "วัดสิงห์",
          "postCode": [
            "17120"
          ]
        },
        {
          "id": 180306,
          "name": "หนองขุ่น",
          "postCode": [
            "17120"
          ]
        },
        {
          "id": 180303,
          "name": "หนองน้อย",
          "postCode": [
            "17120"
          ]
        },
        {
          "id": 180304,
          "name": "หนองบัว",
          "postCode": [
            "17120"
          ]
        }
      ]
    },
    {
      "city": "สรรคบุรี",
      "cityCode": 1805,
      "provinceCode": 18,
      "subDistrict": [
        {
          "id": 180506,
          "name": "ดงคอน",
          "postCode": [
            "17140"
          ]
        },
        {
          "id": 180507,
          "name": "ดอนกำ",
          "postCode": [
            "17140"
          ]
        },
        {
          "id": 180505,
          "name": "บางขุด",
          "postCode": [
            "17140"
          ]
        },
        {
          "id": 180503,
          "name": "ห้วยกรด",
          "postCode": [
            "17140"
          ]
        },
        {
          "id": 180508,
          "name": "ห้วยกรดพัฒนา",
          "postCode": [
            "17140"
          ]
        },
        {
          "id": 180502,
          "name": "เที่ยงแท้",
          "postCode": [
            "17140"
          ]
        },
        {
          "id": 180501,
          "name": "แพรกศรีราชา",
          "postCode": [
            "17140"
          ]
        },
        {
          "id": 180504,
          "name": "โพงาม",
          "postCode": [
            "17140"
          ]
        }
      ]
    },
    {
      "city": "สรรพยา",
      "cityCode": 1804,
      "provinceCode": 18,
      "subDistrict": [
        {
          "id": 180402,
          "name": "ตลุก",
          "postCode": [
            "17150"
          ]
        },
        {
          "id": 180406,
          "name": "บางหลวง",
          "postCode": [
            "17150"
          ]
        },
        {
          "id": 180401,
          "name": "สรรพยา",
          "postCode": [
            "17150"
          ]
        },
        {
          "id": 180407,
          "name": "หาดอาษา",
          "postCode": [
            "17150"
          ]
        },
        {
          "id": 180403,
          "name": "เขาแก้ว",
          "postCode": [
            "17150"
          ]
        },
        {
          "id": 180404,
          "name": "โพนางดำตก",
          "postCode": [
            "17150"
          ]
        },
        {
          "id": 180405,
          "name": "โพนางดำออก",
          "postCode": [
            "17150"
          ]
        }
      ]
    },
    {
      "city": "หนองมะโมง",
      "cityCode": 1807,
      "provinceCode": 18,
      "subDistrict": [
        {
          "id": 180704,
          "name": "กุดจอก",
          "postCode": [
            "17120"
          ]
        },
        {
          "id": 180702,
          "name": "วังตะเคียน",
          "postCode": [
            "17120"
          ]
        },
        {
          "id": 180703,
          "name": "สะพานหิน",
          "postCode": [
            "17120"
          ]
        },
        {
          "id": 180701,
          "name": "หนองมะโมง",
          "postCode": [
            "17120"
          ]
        }
      ]
    },
    {
      "city": "หันคา",
      "cityCode": 1806,
      "provinceCode": 18,
      "subDistrict": [
        {
          "id": 180602,
          "name": "บ้านเชี่ยน",
          "postCode": [
            "17130"
          ]
        },
        {
          "id": 180608,
          "name": "วังไก่เถื่อน",
          "postCode": [
            "17130"
          ]
        },
        {
          "id": 180611,
          "name": "สามง่ามท่าโบสถ์",
          "postCode": [
            "17160"
          ]
        },
        {
          "id": 180606,
          "name": "หนองแซง",
          "postCode": [
            "17160"
          ]
        },
        {
          "id": 180601,
          "name": "หันคา",
          "postCode": [
            "17130"
          ]
        },
        {
          "id": 180607,
          "name": "ห้วยงู",
          "postCode": [
            "17160"
          ]
        },
        {
          "id": 180609,
          "name": "เด่นใหญ่",
          "postCode": [
            "17130"
          ]
        },
        {
          "id": 180605,
          "name": "ไพรนกยูง",
          "postCode": [
            "17130"
          ]
        }
      ]
    },
    {
      "city": "เนินขาม",
      "cityCode": 1808,
      "provinceCode": 18,
      "subDistrict": [
        {
          "id": 180802,
          "name": "กะบกเตี้ย",
          "postCode": [
            "17130"
          ]
        },
        {
          "id": 180803,
          "name": "สุขเดือนห้า",
          "postCode": [
            "17130"
          ]
        },
        {
          "id": 180801,
          "name": "เนินขาม",
          "postCode": [
            "17130"
          ]
        }
      ]
    },
    {
      "city": "เมืองชัยนาท",
      "cityCode": 1801,
      "provinceCode": 18,
      "subDistrict": [
        {
          "id": 180104,
          "name": "ชัยนาท",
          "postCode": [
            "17000"
          ]
        },
        {
          "id": 180103,
          "name": "ท่าชัย",
          "postCode": [
            "17000"
          ]
        },
        {
          "id": 180107,
          "name": "ธรรมามูล",
          "postCode": [
            "17000"
          ]
        },
        {
          "id": 180109,
          "name": "นางลือ",
          "postCode": [
            "17000"
          ]
        },
        {
          "id": 180102,
          "name": "บ้านกล้วย",
          "postCode": [
            "17000"
          ]
        },
        {
          "id": 180106,
          "name": "หาดท่าเสา",
          "postCode": [
            "17000",
            "17120"
          ]
        },
        {
          "id": 180105,
          "name": "เขาท่าพระ",
          "postCode": [
            "17000"
          ]
        },
        {
          "id": 180108,
          "name": "เสือโฮก",
          "postCode": [
            "17000"
          ]
        },
        {
          "id": 180101,
          "name": "ในเมือง",
          "postCode": [
            "17000"
          ]
        }
      ]
    },
    {
      "city": "คอนสวรรค์",
      "cityCode": 3603,
      "provinceCode": 36,
      "subDistrict": [
        {
          "id": 360301,
          "name": "คอนสวรรค์",
          "postCode": [
            "36140"
          ]
        },
        {
          "id": 360303,
          "name": "ช่องสามหมอ",
          "postCode": [
            "36140"
          ]
        },
        {
          "id": 360306,
          "name": "บ้านโสก",
          "postCode": [
            "36140"
          ]
        },
        {
          "id": 360302,
          "name": "ยางหวาย",
          "postCode": [
            "36140"
          ]
        },
        {
          "id": 360309,
          "name": "ศรีสำราญ",
          "postCode": [
            "36140"
          ]
        },
        {
          "id": 360308,
          "name": "หนองขาม",
          "postCode": [
            "36140"
          ]
        },
        {
          "id": 360305,
          "name": "ห้วยไร่",
          "postCode": [
            "36140"
          ]
        },
        {
          "id": 360307,
          "name": "โคกมั่งงอย",
          "postCode": [
            "36140"
          ]
        },
        {
          "id": 360304,
          "name": "โนนสะอาด",
          "postCode": [
            "36140"
          ]
        }
      ]
    },
    {
      "city": "คอนสาร",
      "cityCode": 3613,
      "provinceCode": 36,
      "subDistrict": [
        {
          "id": 361301,
          "name": "คอนสาร",
          "postCode": [
            "36180"
          ]
        },
        {
          "id": 361308,
          "name": "ดงกลาง",
          "postCode": [
            "36180"
          ]
        },
        {
          "id": 361306,
          "name": "ดงบัง",
          "postCode": [
            "36180"
          ]
        },
        {
          "id": 361307,
          "name": "ทุ่งนาเลา",
          "postCode": [
            "36180"
          ]
        },
        {
          "id": 361302,
          "name": "ทุ่งพระ",
          "postCode": [
            "36180"
          ]
        },
        {
          "id": 361305,
          "name": "ทุ่งลุยลาย",
          "postCode": [
            "36180"
          ]
        },
        {
          "id": 361304,
          "name": "ห้วยยาง",
          "postCode": [
            "36180"
          ]
        },
        {
          "id": 361303,
          "name": "โนนคูณ",
          "postCode": [
            "36180"
          ]
        }
      ]
    },
    {
      "city": "จัตุรัส",
      "cityCode": 3606,
      "provinceCode": 36,
      "subDistrict": [
        {
          "id": 360605,
          "name": "กุดน้ำใส",
          "postCode": [
            "36130"
          ]
        },
        {
          "id": 360601,
          "name": "บ้านกอก",
          "postCode": [
            "36130"
          ]
        },
        {
          "id": 360603,
          "name": "บ้านขาม",
          "postCode": [
            "36130"
          ]
        },
        {
          "id": 360607,
          "name": "ละหาน",
          "postCode": [
            "36130"
          ]
        },
        {
          "id": 360613,
          "name": "ส้มป่อย",
          "postCode": [
            "36130"
          ]
        },
        {
          "id": 360602,
          "name": "หนองบัวบาน",
          "postCode": [
            "36130"
          ]
        },
        {
          "id": 360611,
          "name": "หนองบัวโคก",
          "postCode": [
            "36220"
          ]
        },
        {
          "id": 360610,
          "name": "หนองบัวใหญ่",
          "postCode": [
            "36130"
          ]
        },
        {
          "id": 360606,
          "name": "หนองโดน",
          "postCode": [
            "36130"
          ]
        }
      ]
    },
    {
      "city": "ซับใหญ่",
      "cityCode": 3616,
      "provinceCode": 36,
      "subDistrict": [
        {
          "id": 361601,
          "name": "ซับใหญ่",
          "postCode": [
            "36130"
          ]
        },
        {
          "id": 361603,
          "name": "ตะโกทอง",
          "postCode": [
            "36130"
          ]
        },
        {
          "id": 361602,
          "name": "ท่ากูบ",
          "postCode": [
            "36130"
          ]
        }
      ]
    },
    {
      "city": "บำเหน็จณรงค์",
      "cityCode": 3607,
      "provinceCode": 36,
      "subDistrict": [
        {
          "id": 360701,
          "name": "บ้านชวน",
          "postCode": [
            "36160"
          ]
        },
        {
          "id": 360703,
          "name": "บ้านตาล",
          "postCode": [
            "36220"
          ]
        },
        {
          "id": 360702,
          "name": "บ้านเพชร",
          "postCode": [
            "36160"
          ]
        },
        {
          "id": 360704,
          "name": "หัวทะเล",
          "postCode": [
            "36220"
          ]
        },
        {
          "id": 360706,
          "name": "เกาะมะนาว",
          "postCode": [
            "36160"
          ]
        },
        {
          "id": 360707,
          "name": "โคกเพชรพัฒนา",
          "postCode": [
            "36160"
          ]
        },
        {
          "id": 360705,
          "name": "โคกเริงรมย์",
          "postCode": [
            "36160"
          ]
        }
      ]
    },
    {
      "city": "บ้านเขว้า",
      "cityCode": 3602,
      "provinceCode": 36,
      "subDistrict": [
        {
          "id": 360204,
          "name": "ชีบน",
          "postCode": [
            "36170"
          ]
        },
        {
          "id": 360202,
          "name": "ตลาดแร้ง",
          "postCode": [
            "36170"
          ]
        },
        {
          "id": 360201,
          "name": "บ้านเขว้า",
          "postCode": [
            "36170"
          ]
        },
        {
          "id": 360205,
          "name": "ภูแลนคา",
          "postCode": [
            "36170"
          ]
        },
        {
          "id": 360203,
          "name": "ลุ่มลำชี",
          "postCode": [
            "36170"
          ]
        },
        {
          "id": 360206,
          "name": "โนนแดง",
          "postCode": [
            "36170"
          ]
        }
      ]
    },
    {
      "city": "บ้านแท่น",
      "cityCode": 3611,
      "provinceCode": 36,
      "subDistrict": [
        {
          "id": 361104,
          "name": "บ้านเต่า",
          "postCode": [
            "36190"
          ]
        },
        {
          "id": 361101,
          "name": "บ้านแท่น",
          "postCode": [
            "36190"
          ]
        },
        {
          "id": 361103,
          "name": "สระพัง",
          "postCode": [
            "36190"
          ]
        },
        {
          "id": 361102,
          "name": "สามสวน",
          "postCode": [
            "36190"
          ]
        },
        {
          "id": 361105,
          "name": "หนองคู",
          "postCode": [
            "36190"
          ]
        }
      ]
    },
    {
      "city": "ภักดีชุมพล",
      "cityCode": 3614,
      "provinceCode": 36,
      "subDistrict": [
        {
          "id": 361401,
          "name": "บ้านเจียง",
          "postCode": [
            "36260"
          ]
        },
        {
          "id": 361403,
          "name": "วังทอง",
          "postCode": [
            "36260"
          ]
        },
        {
          "id": 361402,
          "name": "เจาทอง",
          "postCode": [
            "36260"
          ]
        },
        {
          "id": 361404,
          "name": "แหลมทอง",
          "postCode": [
            "36260"
          ]
        }
      ]
    },
    {
      "city": "ภูเขียว",
      "cityCode": 3610,
      "provinceCode": 36,
      "subDistrict": [
        {
          "id": 361002,
          "name": "กวางโจน",
          "postCode": [
            "36110"
          ]
        },
        {
          "id": 361005,
          "name": "กุดยม",
          "postCode": [
            "36110"
          ]
        },
        {
          "id": 361010,
          "name": "ธาตุทอง",
          "postCode": [
            "36110"
          ]
        },
        {
          "id": 361011,
          "name": "บ้านดอน",
          "postCode": [
            "36110"
          ]
        },
        {
          "id": 361006,
          "name": "บ้านเพชร",
          "postCode": [
            "36110"
          ]
        },
        {
          "id": 361004,
          "name": "บ้านแก้ง",
          "postCode": [
            "36110"
          ]
        },
        {
          "id": 361001,
          "name": "ผักปัง",
          "postCode": [
            "36110"
          ]
        },
        {
          "id": 361003,
          "name": "หนองคอนไทย",
          "postCode": [
            "36110"
          ]
        },
        {
          "id": 361008,
          "name": "หนองตูม",
          "postCode": [
            "36110"
          ]
        },
        {
          "id": 361007,
          "name": "โคกสะอาด",
          "postCode": [
            "36110"
          ]
        },
        {
          "id": 361009,
          "name": "โอโล",
          "postCode": [
            "36110"
          ]
        }
      ]
    },
    {
      "city": "หนองบัวระเหว",
      "cityCode": 3608,
      "provinceCode": 36,
      "subDistrict": [
        {
          "id": 360802,
          "name": "วังตะเฆ่",
          "postCode": [
            "36250"
          ]
        },
        {
          "id": 360801,
          "name": "หนองบัวระเหว",
          "postCode": [
            "36250"
          ]
        },
        {
          "id": 360803,
          "name": "ห้วยแย้",
          "postCode": [
            "36250"
          ]
        },
        {
          "id": 360804,
          "name": "โคกสะอาด",
          "postCode": [
            "36250"
          ]
        },
        {
          "id": 360805,
          "name": "โสกปลาดุก",
          "postCode": [
            "36250"
          ]
        }
      ]
    },
    {
      "city": "หนองบัวแดง",
      "cityCode": 3605,
      "provinceCode": 36,
      "subDistrict": [
        {
          "id": 360502,
          "name": "กุดชุมแสง",
          "postCode": [
            "36210"
          ]
        },
        {
          "id": 360508,
          "name": "คูเมือง",
          "postCode": [
            "36210"
          ]
        },
        {
          "id": 360503,
          "name": "ถ้ำวัวแดง",
          "postCode": [
            "36210"
          ]
        },
        {
          "id": 360509,
          "name": "ท่าใหญ่",
          "postCode": [
            "36210"
          ]
        },
        {
          "id": 360504,
          "name": "นางแดด",
          "postCode": [
            "36210"
          ]
        },
        {
          "id": 360511,
          "name": "วังชมภู",
          "postCode": [
            "36210"
          ]
        },
        {
          "id": 360501,
          "name": "หนองบัวแดง",
          "postCode": [
            "36210"
          ]
        },
        {
          "id": 360507,
          "name": "หนองแวง",
          "postCode": [
            "36210"
          ]
        }
      ]
    },
    {
      "city": "เกษตรสมบูรณ์",
      "cityCode": 3604,
      "provinceCode": 36,
      "subDistrict": [
        {
          "id": 360405,
          "name": "กุดเลาะ",
          "postCode": [
            "36120"
          ]
        },
        {
          "id": 360410,
          "name": "บ้านบัว",
          "postCode": [
            "36120"
          ]
        },
        {
          "id": 360401,
          "name": "บ้านยาง",
          "postCode": [
            "36120"
          ]
        },
        {
          "id": 360402,
          "name": "บ้านหัน",
          "postCode": [
            "36120"
          ]
        },
        {
          "id": 360403,
          "name": "บ้านเดื่อ",
          "postCode": [
            "36120"
          ]
        },
        {
          "id": 360404,
          "name": "บ้านเป้า",
          "postCode": [
            "36120"
          ]
        },
        {
          "id": 360407,
          "name": "สระโพนทอง",
          "postCode": [
            "36120"
          ]
        },
        {
          "id": 360408,
          "name": "หนองข่า",
          "postCode": [
            "36120"
          ]
        },
        {
          "id": 360409,
          "name": "หนองโพนงาม",
          "postCode": [
            "36120"
          ]
        },
        {
          "id": 360406,
          "name": "โนนกอก",
          "postCode": [
            "36120"
          ]
        },
        {
          "id": 360412,
          "name": "โนนทอง",
          "postCode": [
            "36120"
          ]
        }
      ]
    },
    {
      "city": "เทพสถิต",
      "cityCode": 3609,
      "provinceCode": 36,
      "subDistrict": [
        {
          "id": 360903,
          "name": "นายางกลัก",
          "postCode": [
            "36230"
          ]
        },
        {
          "id": 360904,
          "name": "บ้านไร่",
          "postCode": [
            "36230"
          ]
        },
        {
          "id": 360901,
          "name": "วะตะแบก",
          "postCode": [
            "36230"
          ]
        },
        {
          "id": 360902,
          "name": "ห้วยยายจิ๋ว",
          "postCode": [
            "36230"
          ]
        },
        {
          "id": 360905,
          "name": "โป่งนก",
          "postCode": [
            "36230"
          ]
        }
      ]
    },
    {
      "city": "เนินสง่า",
      "cityCode": 3615,
      "provinceCode": 36,
      "subDistrict": [
        {
          "id": 361503,
          "name": "กะฮาด",
          "postCode": [
            "36130"
          ]
        },
        {
          "id": 361502,
          "name": "ตาเนิน",
          "postCode": [
            "36130"
          ]
        },
        {
          "id": 361504,
          "name": "รังงาม",
          "postCode": [
            "36130"
          ]
        },
        {
          "id": 361501,
          "name": "หนองฉิม",
          "postCode": [
            "36130"
          ]
        }
      ]
    },
    {
      "city": "เมืองชัยภูมิ",
      "cityCode": 3601,
      "provinceCode": 36,
      "subDistrict": [
        {
          "id": 360106,
          "name": "กุดตุ้ม",
          "postCode": [
            "36000"
          ]
        },
        {
          "id": 360107,
          "name": "ชีลอง",
          "postCode": [
            "36000"
          ]
        },
        {
          "id": 360119,
          "name": "ซับสีทอง",
          "postCode": [
            "36000"
          ]
        },
        {
          "id": 360113,
          "name": "ท่าหินโงม",
          "postCode": [
            "36000"
          ]
        },
        {
          "id": 360104,
          "name": "นาฝาย",
          "postCode": [
            "36000"
          ]
        },
        {
          "id": 360109,
          "name": "นาเสียว",
          "postCode": [
            "36000"
          ]
        },
        {
          "id": 360118,
          "name": "บุ่งคล้า",
          "postCode": [
            "36000"
          ]
        },
        {
          "id": 360105,
          "name": "บ้านค่าย",
          "postCode": [
            "36240"
          ]
        },
        {
          "id": 360108,
          "name": "บ้านเล่า",
          "postCode": [
            "36000"
          ]
        },
        {
          "id": 360102,
          "name": "รอบเมือง",
          "postCode": [
            "36000"
          ]
        },
        {
          "id": 360111,
          "name": "ลาดใหญ่",
          "postCode": [
            "36000"
          ]
        },
        {
          "id": 360110,
          "name": "หนองนาแซง",
          "postCode": [
            "36000"
          ]
        },
        {
          "id": 360112,
          "name": "หนองไผ่",
          "postCode": [
            "36240"
          ]
        },
        {
          "id": 360114,
          "name": "ห้วยต้อน",
          "postCode": [
            "36000"
          ]
        },
        {
          "id": 360115,
          "name": "ห้วยบง",
          "postCode": [
            "36000"
          ]
        },
        {
          "id": 360117,
          "name": "โคกสูง",
          "postCode": [
            "36000"
          ]
        },
        {
          "id": 360116,
          "name": "โนนสำราญ",
          "postCode": [
            "36240"
          ]
        },
        {
          "id": 360103,
          "name": "โพนทอง",
          "postCode": [
            "36000"
          ]
        },
        {
          "id": 360101,
          "name": "ในเมือง",
          "postCode": [
            "36000"
          ]
        }
      ]
    },
    {
      "city": "แก้งคร้อ",
      "cityCode": 3612,
      "provinceCode": 36,
      "subDistrict": [
        {
          "id": 361201,
          "name": "ช่องสามหมอ",
          "postCode": [
            "36150"
          ]
        },
        {
          "id": 361209,
          "name": "ท่ามะไฟหวาน",
          "postCode": [
            "36150"
          ]
        },
        {
          "id": 361203,
          "name": "นาหนองทุ่ม",
          "postCode": [
            "36150"
          ]
        },
        {
          "id": 361204,
          "name": "บ้านแก้ง",
          "postCode": [
            "36150"
          ]
        },
        {
          "id": 361202,
          "name": "หนองขาม",
          "postCode": [
            "36150"
          ]
        },
        {
          "id": 361205,
          "name": "หนองสังข์",
          "postCode": [
            "36150"
          ]
        },
        {
          "id": 361210,
          "name": "หนองไผ่",
          "postCode": [
            "36150"
          ]
        },
        {
          "id": 361206,
          "name": "หลุบคา",
          "postCode": [
            "36150"
          ]
        },
        {
          "id": 361208,
          "name": "เก่าย่าดี",
          "postCode": [
            "36150"
          ]
        },
        {
          "id": 361207,
          "name": "โคกกุง",
          "postCode": [
            "36150"
          ]
        }
      ]
    },
    {
      "city": "ทุ่งตะโก",
      "cityCode": 8608,
      "provinceCode": 86,
      "subDistrict": [
        {
          "id": 860804,
          "name": "ช่องไม้แก้ว",
          "postCode": [
            "86220"
          ]
        },
        {
          "id": 860803,
          "name": "ตะโก",
          "postCode": [
            "86220"
          ]
        },
        {
          "id": 860802,
          "name": "ทุ่งตะไคร",
          "postCode": [
            "86220"
          ]
        },
        {
          "id": 860801,
          "name": "ปากตะโก",
          "postCode": [
            "86220"
          ]
        }
      ]
    },
    {
      "city": "ท่าแซะ",
      "cityCode": 8602,
      "provinceCode": 86,
      "subDistrict": [
        {
          "id": 860202,
          "name": "คุริง",
          "postCode": [
            "86140"
          ]
        },
        {
          "id": 860209,
          "name": "ทรัพย์อนันต์",
          "postCode": [
            "86140"
          ]
        },
        {
          "id": 860206,
          "name": "ท่าข้าม",
          "postCode": [
            "86140"
          ]
        },
        {
          "id": 860201,
          "name": "ท่าแซะ",
          "postCode": [
            "86140"
          ]
        },
        {
          "id": 860204,
          "name": "นากระตาม",
          "postCode": [
            "86140"
          ]
        },
        {
          "id": 860205,
          "name": "รับร่อ",
          "postCode": [
            "86140",
            "86190"
          ]
        },
        {
          "id": 860203,
          "name": "สลุย",
          "postCode": [
            "86140"
          ]
        },
        {
          "id": 860210,
          "name": "สองพี่น้อง",
          "postCode": [
            "86140"
          ]
        },
        {
          "id": 860207,
          "name": "หงษ์เจริญ",
          "postCode": [
            "86140"
          ]
        },
        {
          "id": 860208,
          "name": "หินแก้ว",
          "postCode": [
            "86190"
          ]
        }
      ]
    },
    {
      "city": "ปะทิว",
      "cityCode": 8603,
      "provinceCode": 86,
      "subDistrict": [
        {
          "id": 860304,
          "name": "ชุมโค",
          "postCode": [
            "86160"
          ]
        },
        {
          "id": 860305,
          "name": "ดอนยาง",
          "postCode": [
            "86210"
          ]
        },
        {
          "id": 860302,
          "name": "ทะเลทรัพย์",
          "postCode": [
            "86160"
          ]
        },
        {
          "id": 860301,
          "name": "บางสน",
          "postCode": [
            "86160"
          ]
        },
        {
          "id": 860306,
          "name": "ปากคลอง",
          "postCode": [
            "86210"
          ]
        },
        {
          "id": 860303,
          "name": "สะพลี",
          "postCode": [
            "86230"
          ]
        },
        {
          "id": 860307,
          "name": "เขาไชยราช",
          "postCode": [
            "86210"
          ]
        }
      ]
    },
    {
      "city": "พะโต๊ะ",
      "cityCode": 8606,
      "provinceCode": 86,
      "subDistrict": [
        {
          "id": 860603,
          "name": "ปังหวาน",
          "postCode": [
            "86180"
          ]
        },
        {
          "id": 860602,
          "name": "ปากทรง",
          "postCode": [
            "86180"
          ]
        },
        {
          "id": 860604,
          "name": "พระรักษ์",
          "postCode": [
            "86180"
          ]
        },
        {
          "id": 860601,
          "name": "พะโต๊ะ",
          "postCode": [
            "86180"
          ]
        }
      ]
    },
    {
      "city": "ละแม",
      "cityCode": 8605,
      "provinceCode": 86,
      "subDistrict": [
        {
          "id": 860504,
          "name": "ทุ่งคาวัด",
          "postCode": [
            "86170"
          ]
        },
        {
          "id": 860502,
          "name": "ทุ่งหลวง",
          "postCode": [
            "86170"
          ]
        },
        {
          "id": 860501,
          "name": "ละแม",
          "postCode": [
            "86170"
          ]
        },
        {
          "id": 860503,
          "name": "สวนแตง",
          "postCode": [
            "86170"
          ]
        }
      ]
    },
    {
      "city": "สวี",
      "cityCode": 8607,
      "provinceCode": 86,
      "subDistrict": [
        {
          "id": 860707,
          "name": "ครน",
          "postCode": [
            "86130"
          ]
        },
        {
          "id": 860706,
          "name": "ด่านสวี",
          "postCode": [
            "86130"
          ]
        },
        {
          "id": 860703,
          "name": "ทุ่งระยะ",
          "postCode": [
            "86130"
          ]
        },
        {
          "id": 860704,
          "name": "ท่าหิน",
          "postCode": [
            "86130"
          ]
        },
        {
          "id": 860709,
          "name": "นาสัก",
          "postCode": [
            "86130"
          ]
        },
        {
          "id": 860701,
          "name": "นาโพธิ์",
          "postCode": [
            "86130"
          ]
        },
        {
          "id": 860705,
          "name": "ปากแพรก",
          "postCode": [
            "86130"
          ]
        },
        {
          "id": 860708,
          "name": "วิสัยใต้",
          "postCode": [
            "86130"
          ]
        },
        {
          "id": 860702,
          "name": "สวี",
          "postCode": [
            "86130"
          ]
        },
        {
          "id": 860711,
          "name": "เขาค่าย",
          "postCode": [
            "86130"
          ]
        },
        {
          "id": 860710,
          "name": "เขาทะลุ",
          "postCode": [
            "86130"
          ]
        }
      ]
    },
    {
      "city": "หลังสวน",
      "cityCode": 8604,
      "provinceCode": 86,
      "subDistrict": [
        {
          "id": 860402,
          "name": "ขันเงิน",
          "postCode": [
            "86110"
          ]
        },
        {
          "id": 860403,
          "name": "ท่ามะพลา",
          "postCode": [
            "86110"
          ]
        },
        {
          "id": 860404,
          "name": "นาขา",
          "postCode": [
            "86110"
          ]
        },
        {
          "id": 860405,
          "name": "นาพญา",
          "postCode": [
            "86110"
          ]
        },
        {
          "id": 860408,
          "name": "บางน้ำจืด",
          "postCode": [
            "86150"
          ]
        },
        {
          "id": 860407,
          "name": "บางมะพร้าว",
          "postCode": [
            "86110",
            "86150"
          ]
        },
        {
          "id": 860406,
          "name": "บ้านควน",
          "postCode": [
            "86110"
          ]
        },
        {
          "id": 860409,
          "name": "ปากน้ำ",
          "postCode": [
            "86150"
          ]
        },
        {
          "id": 860410,
          "name": "พ้อแดง",
          "postCode": [
            "86110"
          ]
        },
        {
          "id": 860412,
          "name": "วังตะกอ",
          "postCode": [
            "86110"
          ]
        },
        {
          "id": 860401,
          "name": "หลังสวน",
          "postCode": [
            "86110"
          ]
        },
        {
          "id": 860413,
          "name": "หาดยาย",
          "postCode": [
            "86110"
          ]
        },
        {
          "id": 860411,
          "name": "แหลมทราย",
          "postCode": [
            "86110"
          ]
        }
      ]
    },
    {
      "city": "เมืองชุมพร",
      "cityCode": 8601,
      "provinceCode": 86,
      "subDistrict": [
        {
          "id": 860113,
          "name": "ขุนกระทิง",
          "postCode": [
            "86000",
            "86190"
          ]
        },
        {
          "id": 860107,
          "name": "ตากแดด",
          "postCode": [
            "86000",
            "86190"
          ]
        },
        {
          "id": 860117,
          "name": "ถ้ำสิงห์",
          "postCode": [
            "86100"
          ]
        },
        {
          "id": 860114,
          "name": "ทุ่งคา",
          "postCode": [
            "86100"
          ]
        },
        {
          "id": 860101,
          "name": "ท่าตะเภา",
          "postCode": [
            "86000"
          ]
        },
        {
          "id": 860103,
          "name": "ท่ายาง",
          "postCode": [
            "86000",
            "86120"
          ]
        },
        {
          "id": 860106,
          "name": "นาชะอัง",
          "postCode": [
            "86000"
          ]
        },
        {
          "id": 860105,
          "name": "นาทุ่ง",
          "postCode": [
            "86000"
          ]
        },
        {
          "id": 860108,
          "name": "บางลึก",
          "postCode": [
            "86000"
          ]
        },
        {
          "id": 860104,
          "name": "บางหมาก",
          "postCode": [
            "86000"
          ]
        },
        {
          "id": 860112,
          "name": "บ้านนา",
          "postCode": [
            "86190"
          ]
        },
        {
          "id": 860102,
          "name": "ปากน้ำ",
          "postCode": [
            "86120"
          ]
        },
        {
          "id": 860111,
          "name": "วังใหม่",
          "postCode": [
            "86190"
          ]
        },
        {
          "id": 860110,
          "name": "วังไผ่",
          "postCode": [
            "86000",
            "86190"
          ]
        },
        {
          "id": 860115,
          "name": "วิสัยเหนือ",
          "postCode": [
            "86100"
          ]
        },
        {
          "id": 860116,
          "name": "หาดทรายรี",
          "postCode": [
            "86120"
          ]
        },
        {
          "id": 860109,
          "name": "หาดพันไกร",
          "postCode": [
            "86000"
          ]
        }
      ]
    },
    {
      "city": "กันตัง",
      "cityCode": 9202,
      "provinceCode": 92,
      "subDistrict": [
        {
          "id": 920201,
          "name": "กันตัง",
          "postCode": [
            "92110"
          ]
        },
        {
          "id": 920206,
          "name": "กันตังใต้",
          "postCode": [
            "92110"
          ]
        },
        {
          "id": 920214,
          "name": "คลองชีล้อม",
          "postCode": [
            "92110"
          ]
        },
        {
          "id": 920208,
          "name": "คลองลุ",
          "postCode": [
            "92110"
          ]
        },
        {
          "id": 920202,
          "name": "ควนธานี",
          "postCode": [
            "92110"
          ]
        },
        {
          "id": 920212,
          "name": "นาเกลือ",
          "postCode": [
            "92110"
          ]
        },
        {
          "id": 920211,
          "name": "บางสัก",
          "postCode": [
            "92110"
          ]
        },
        {
          "id": 920203,
          "name": "บางหมาก",
          "postCode": [
            "92110"
          ]
        },
        {
          "id": 920204,
          "name": "บางเป้า",
          "postCode": [
            "92110"
          ]
        },
        {
          "id": 920210,
          "name": "บ่อน้ำร้อน",
          "postCode": [
            "92110"
          ]
        },
        {
          "id": 920209,
          "name": "ย่านซื่อ",
          "postCode": [
            "92110"
          ]
        },
        {
          "id": 920205,
          "name": "วังวน",
          "postCode": [
            "92110"
          ]
        },
        {
          "id": 920213,
          "name": "เกาะลิบง",
          "postCode": [
            "92110"
          ]
        },
        {
          "id": 920207,
          "name": "โคกยาง",
          "postCode": [
            "92110"
          ]
        }
      ]
    },
    {
      "city": "นาโยง",
      "cityCode": 9208,
      "provinceCode": 92,
      "subDistrict": [
        {
          "id": 920802,
          "name": "ช่อง",
          "postCode": [
            "92170"
          ]
        },
        {
          "id": 920806,
          "name": "นาข้าวเสีย",
          "postCode": [
            "92170"
          ]
        },
        {
          "id": 920805,
          "name": "นาหมื่นศรี",
          "postCode": [
            "92170"
          ]
        },
        {
          "id": 920801,
          "name": "นาโยงเหนือ",
          "postCode": [
            "92170"
          ]
        },
        {
          "id": 920803,
          "name": "ละมอ",
          "postCode": [
            "92170"
          ]
        },
        {
          "id": 920804,
          "name": "โคกสะบ้า",
          "postCode": [
            "92170"
          ]
        }
      ]
    },
    {
      "city": "ปะเหลียน",
      "cityCode": 9204,
      "provinceCode": 92,
      "subDistrict": [
        {
          "id": 920402,
          "name": "ทุ่งยาว",
          "postCode": [
            "92180"
          ]
        },
        {
          "id": 920401,
          "name": "ท่าข้าม",
          "postCode": [
            "92120"
          ]
        },
        {
          "id": 920412,
          "name": "ท่าพญา",
          "postCode": [
            "92140"
          ]
        },
        {
          "id": 920404,
          "name": "บางด้วน",
          "postCode": [
            "92140"
          ]
        },
        {
          "id": 920407,
          "name": "บ้านนา",
          "postCode": [
            "92140"
          ]
        },
        {
          "id": 920403,
          "name": "ปะเหลียน",
          "postCode": [
            "92180"
          ]
        },
        {
          "id": 920410,
          "name": "ลิพัง",
          "postCode": [
            "92180"
          ]
        },
        {
          "id": 920409,
          "name": "สุโสะ",
          "postCode": [
            "92120"
          ]
        },
        {
          "id": 920411,
          "name": "เกาะสุกร",
          "postCode": [
            "92120"
          ]
        },
        {
          "id": 920413,
          "name": "แหลมสอม",
          "postCode": [
            "92180"
          ]
        }
      ]
    },
    {
      "city": "ย่านตาขาว",
      "cityCode": 9203,
      "provinceCode": 92,
      "subDistrict": [
        {
          "id": 920306,
          "name": "ทุ่งกระบือ",
          "postCode": [
            "92140"
          ]
        },
        {
          "id": 920307,
          "name": "ทุ่งค่าย",
          "postCode": [
            "92140"
          ]
        },
        {
          "id": 920303,
          "name": "นาชุมเห็ด",
          "postCode": [
            "92140"
          ]
        },
        {
          "id": 920301,
          "name": "ย่านตาขาว",
          "postCode": [
            "92140"
          ]
        },
        {
          "id": 920302,
          "name": "หนองบ่อ",
          "postCode": [
            "92140"
          ]
        },
        {
          "id": 920308,
          "name": "เกาะเปียะ",
          "postCode": [
            "92140"
          ]
        },
        {
          "id": 920305,
          "name": "โพรงจระเข้",
          "postCode": [
            "92140"
          ]
        },
        {
          "id": 920304,
          "name": "ในควน",
          "postCode": [
            "92140"
          ]
        }
      ]
    },
    {
      "city": "รัษฎา",
      "cityCode": 9209,
      "provinceCode": 92,
      "subDistrict": [
        {
          "id": 920902,
          "name": "คลองปาง",
          "postCode": [
            "92160"
          ]
        },
        {
          "id": 920901,
          "name": "ควนเมา",
          "postCode": [
            "92160"
          ]
        },
        {
          "id": 920903,
          "name": "หนองบัว",
          "postCode": [
            "92160"
          ]
        },
        {
          "id": 920904,
          "name": "หนองปรือ",
          "postCode": [
            "92130"
          ]
        },
        {
          "id": 920905,
          "name": "เขาไพร",
          "postCode": [
            "92160"
          ]
        }
      ]
    },
    {
      "city": "วังวิเศษ",
      "cityCode": 9207,
      "provinceCode": 92,
      "subDistrict": [
        {
          "id": 920704,
          "name": "ท่าสะบ้า",
          "postCode": [
            "92000"
          ]
        },
        {
          "id": 920702,
          "name": "วังมะปราง",
          "postCode": [
            "92220"
          ]
        },
        {
          "id": 920705,
          "name": "วังมะปรางเหนือ",
          "postCode": [
            "92220"
          ]
        },
        {
          "id": 920703,
          "name": "อ่าวตง",
          "postCode": [
            "92220"
          ]
        },
        {
          "id": 920701,
          "name": "เขาวิเศษ",
          "postCode": [
            "92220"
          ]
        }
      ]
    },
    {
      "city": "สิเกา",
      "cityCode": 9205,
      "provinceCode": 92,
      "subDistrict": [
        {
          "id": 920503,
          "name": "กะลาเส",
          "postCode": [
            "92150"
          ]
        },
        {
          "id": 920505,
          "name": "นาเมืองเพชร",
          "postCode": [
            "92000"
          ]
        },
        {
          "id": 920501,
          "name": "บ่อหิน",
          "postCode": [
            "92150"
          ]
        },
        {
          "id": 920502,
          "name": "เขาไม้แก้ว",
          "postCode": [
            "92150"
          ]
        },
        {
          "id": 920504,
          "name": "ไม้ฝาด",
          "postCode": [
            "92150"
          ]
        }
      ]
    },
    {
      "city": "หาดสำราญ",
      "cityCode": 9210,
      "provinceCode": 92,
      "subDistrict": [
        {
          "id": 921003,
          "name": "ตะเสะ",
          "postCode": [
            "92120"
          ]
        },
        {
          "id": 921002,
          "name": "บ้าหวี",
          "postCode": [
            "92120"
          ]
        },
        {
          "id": 921001,
          "name": "หาดสำราญ",
          "postCode": [
            "92120"
          ]
        }
      ]
    },
    {
      "city": "ห้วยยอด",
      "cityCode": 9206,
      "provinceCode": 92,
      "subDistrict": [
        {
          "id": 920620,
          "name": "ทุ่งต่อ",
          "postCode": [
            "92130"
          ]
        },
        {
          "id": 920614,
          "name": "ท่างิ้ว",
          "postCode": [
            "92130"
          ]
        },
        {
          "id": 920616,
          "name": "นาวง",
          "postCode": [
            "92210"
          ]
        },
        {
          "id": 920606,
          "name": "บางกุ้ง",
          "postCode": [
            "92210"
          ]
        },
        {
          "id": 920605,
          "name": "บางดี",
          "postCode": [
            "92210"
          ]
        },
        {
          "id": 920611,
          "name": "ปากคม",
          "postCode": [
            "92130"
          ]
        },
        {
          "id": 920610,
          "name": "ปากแจ่ม",
          "postCode": [
            "92190"
          ]
        },
        {
          "id": 920615,
          "name": "ลำภูรา",
          "postCode": [
            "92190"
          ]
        },
        {
          "id": 920621,
          "name": "วังคีรี",
          "postCode": [
            "92210"
          ]
        },
        {
          "id": 920602,
          "name": "หนองช้างแล่น",
          "postCode": [
            "92130"
          ]
        },
        {
          "id": 920617,
          "name": "ห้วยนาง",
          "postCode": [
            "92130"
          ]
        },
        {
          "id": 920601,
          "name": "ห้วยยอด",
          "postCode": [
            "92130"
          ]
        },
        {
          "id": 920607,
          "name": "เขากอบ",
          "postCode": [
            "92130"
          ]
        },
        {
          "id": 920608,
          "name": "เขาขาว",
          "postCode": [
            "92130"
          ]
        },
        {
          "id": 920609,
          "name": "เขาปูน",
          "postCode": [
            "92130"
          ]
        },
        {
          "id": 920619,
          "name": "ในเตา",
          "postCode": [
            "92130"
          ]
        }
      ]
    },
    {
      "city": "เมืองตรัง",
      "cityCode": 9201,
      "provinceCode": 92,
      "subDistrict": [
        {
          "id": 920107,
          "name": "ควนปริง",
          "postCode": [
            "92000"
          ]
        },
        {
          "id": 920101,
          "name": "ทับเที่ยง",
          "postCode": [
            "92000"
          ]
        },
        {
          "id": 920117,
          "name": "นาตาล่วง",
          "postCode": [
            "92000"
          ]
        },
        {
          "id": 920119,
          "name": "นาท่ามเหนือ",
          "postCode": [
            "92190"
          ]
        },
        {
          "id": 920120,
          "name": "นาท่ามใต้",
          "postCode": [
            "92190"
          ]
        },
        {
          "id": 920106,
          "name": "นาบินหลา",
          "postCode": [
            "92170"
          ]
        },
        {
          "id": 920104,
          "name": "นาพละ",
          "postCode": [
            "92000"
          ]
        },
        {
          "id": 920113,
          "name": "นาโต๊ะหมิง",
          "postCode": [
            "92000"
          ]
        },
        {
          "id": 920108,
          "name": "นาโยงใต้",
          "postCode": [
            "92170"
          ]
        },
        {
          "id": 920115,
          "name": "น้ำผุด",
          "postCode": [
            "92000"
          ]
        },
        {
          "id": 920109,
          "name": "บางรัก",
          "postCode": [
            "92000"
          ]
        },
        {
          "id": 920105,
          "name": "บ้านควน",
          "postCode": [
            "92000"
          ]
        },
        {
          "id": 920118,
          "name": "บ้านโพธิ์",
          "postCode": [
            "92000"
          ]
        },
        {
          "id": 920114,
          "name": "หนองตรุด",
          "postCode": [
            "92000"
          ]
        },
        {
          "id": 920110,
          "name": "โคกหล่อ",
          "postCode": [
            "92000"
          ]
        }
      ]
    },
    {
      "city": "คลองใหญ่",
      "cityCode": 2302,
      "provinceCode": 23,
      "subDistrict": [
        {
          "id": 230201,
          "name": "คลองใหญ่",
          "postCode": [
            "23110"
          ]
        },
        {
          "id": 230203,
          "name": "หาดเล็ก",
          "postCode": [
            "23110"
          ]
        },
        {
          "id": 230202,
          "name": "ไม้รูด",
          "postCode": [
            "23110"
          ]
        }
      ]
    },
    {
      "city": "บ่อไร่",
      "cityCode": 2304,
      "provinceCode": 23,
      "subDistrict": [
        {
          "id": 230402,
          "name": "ช้างทูน",
          "postCode": [
            "23140"
          ]
        },
        {
          "id": 230403,
          "name": "ด่านชุมพล",
          "postCode": [
            "23140"
          ]
        },
        {
          "id": 230405,
          "name": "นนทรีย์",
          "postCode": [
            "23140"
          ]
        },
        {
          "id": 230401,
          "name": "บ่อพลอย",
          "postCode": [
            "23140"
          ]
        },
        {
          "id": 230404,
          "name": "หนองบอน",
          "postCode": [
            "23140"
          ]
        }
      ]
    },
    {
      "city": "เกาะกูด",
      "cityCode": 2306,
      "provinceCode": 23,
      "subDistrict": [
        {
          "id": 230602,
          "name": "เกาะกูด",
          "postCode": [
            "23000"
          ]
        },
        {
          "id": 230601,
          "name": "เกาะหมาก",
          "postCode": [
            "23120"
          ]
        }
      ]
    },
    {
      "city": "เกาะช้าง",
      "cityCode": 2307,
      "provinceCode": 23,
      "subDistrict": [
        {
          "id": 230701,
          "name": "เกาะช้าง",
          "postCode": [
            "23170"
          ]
        },
        {
          "id": 230702,
          "name": "เกาะช้างใต้",
          "postCode": [
            "23170"
          ]
        }
      ]
    },
    {
      "city": "เขาสมิง",
      "cityCode": 2303,
      "provinceCode": 23,
      "subDistrict": [
        {
          "id": 230308,
          "name": "ทุ่งนนทรี",
          "postCode": [
            "23130"
          ]
        },
        {
          "id": 230304,
          "name": "ท่าโสม",
          "postCode": [
            "23150"
          ]
        },
        {
          "id": 230306,
          "name": "ประณีต",
          "postCode": [
            "23150"
          ]
        },
        {
          "id": 230303,
          "name": "วังตะเคียน",
          "postCode": [
            "23130"
          ]
        },
        {
          "id": 230305,
          "name": "สะตอ",
          "postCode": [
            "23150"
          ]
        },
        {
          "id": 230301,
          "name": "เขาสมิง",
          "postCode": [
            "23130"
          ]
        },
        {
          "id": 230307,
          "name": "เทพนิมิต",
          "postCode": [
            "23150"
          ]
        },
        {
          "id": 230302,
          "name": "แสนตุ้ง",
          "postCode": [
            "23150"
          ]
        }
      ]
    },
    {
      "city": "เมืองตราด",
      "cityCode": 2301,
      "provinceCode": 23,
      "subDistrict": [
        {
          "id": 230113,
          "name": "ชำราก",
          "postCode": [
            "23000"
          ]
        },
        {
          "id": 230112,
          "name": "ตะกาง",
          "postCode": [
            "23000"
          ]
        },
        {
          "id": 230111,
          "name": "ท่ากุ่ม",
          "postCode": [
            "23000"
          ]
        },
        {
          "id": 230110,
          "name": "ท่าพริก",
          "postCode": [
            "23000"
          ]
        },
        {
          "id": 230101,
          "name": "บางพระ",
          "postCode": [
            "23000"
          ]
        },
        {
          "id": 230107,
          "name": "วังกระแจะ",
          "postCode": [
            "23000"
          ]
        },
        {
          "id": 230104,
          "name": "หนองคันทรง",
          "postCode": [
            "23000"
          ]
        },
        {
          "id": 230102,
          "name": "หนองเสม็ด",
          "postCode": [
            "23000"
          ]
        },
        {
          "id": 230103,
          "name": "หนองโสน",
          "postCode": [
            "23000"
          ]
        },
        {
          "id": 230105,
          "name": "ห้วงน้ำขาว",
          "postCode": [
            "23000"
          ]
        },
        {
          "id": 230108,
          "name": "ห้วยแร้ง",
          "postCode": [
            "23000"
          ]
        },
        {
          "id": 230106,
          "name": "อ่าวใหญ่",
          "postCode": [
            "23000"
          ]
        },
        {
          "id": 230109,
          "name": "เนินทราย",
          "postCode": [
            "23000"
          ]
        },
        {
          "id": 230114,
          "name": "แหลมกลัด",
          "postCode": [
            "23000"
          ]
        }
      ]
    },
    {
      "city": "แหลมงอบ",
      "cityCode": 2305,
      "provinceCode": 23,
      "subDistrict": [
        {
          "id": 230507,
          "name": "คลองใหญ่",
          "postCode": [
            "23120"
          ]
        },
        {
          "id": 230502,
          "name": "น้ำเชี่ยว",
          "postCode": [
            "23120"
          ]
        },
        {
          "id": 230503,
          "name": "บางปิด",
          "postCode": [
            "23120"
          ]
        },
        {
          "id": 230501,
          "name": "แหลมงอบ",
          "postCode": [
            "23120"
          ]
        }
      ]
    },
    {
      "city": "ท่าสองยาง",
      "cityCode": 6305,
      "provinceCode": 63,
      "subDistrict": [
        {
          "id": 630501,
          "name": "ท่าสองยาง",
          "postCode": [
            "63150"
          ]
        },
        {
          "id": 630502,
          "name": "แม่ต้าน",
          "postCode": [
            "63150"
          ]
        },
        {
          "id": 630505,
          "name": "แม่วะหลวง",
          "postCode": [
            "63150"
          ]
        },
        {
          "id": 630503,
          "name": "แม่สอง",
          "postCode": [
            "63150"
          ]
        },
        {
          "id": 630504,
          "name": "แม่หละ",
          "postCode": [
            "63150"
          ]
        },
        {
          "id": 630506,
          "name": "แม่อุสุ",
          "postCode": [
            "63150"
          ]
        }
      ]
    },
    {
      "city": "บ้านตาก",
      "cityCode": 6302,
      "provinceCode": 63,
      "subDistrict": [
        {
          "id": 630204,
          "name": "ตากตก",
          "postCode": [
            "63120"
          ]
        },
        {
          "id": 630201,
          "name": "ตากออก",
          "postCode": [
            "63120"
          ]
        },
        {
          "id": 630206,
          "name": "ทุ่งกระเชาะ",
          "postCode": [
            "63120"
          ]
        },
        {
          "id": 630207,
          "name": "ท้องฟ้า",
          "postCode": [
            "63120"
          ]
        },
        {
          "id": 630202,
          "name": "สมอโคน",
          "postCode": [
            "63120"
          ]
        },
        {
          "id": 630205,
          "name": "เกาะตะเภา",
          "postCode": [
            "63120"
          ]
        },
        {
          "id": 630203,
          "name": "แม่สลิด",
          "postCode": [
            "63120"
          ]
        }
      ]
    },
    {
      "city": "พบพระ",
      "cityCode": 6307,
      "provinceCode": 63,
      "subDistrict": [
        {
          "id": 630703,
          "name": "คีรีราษฎร์",
          "postCode": [
            "63160"
          ]
        },
        {
          "id": 630702,
          "name": "ช่องแคบ",
          "postCode": [
            "63160"
          ]
        },
        {
          "id": 630701,
          "name": "พบพระ",
          "postCode": [
            "63160"
          ]
        },
        {
          "id": 630705,
          "name": "รวมไทยพัฒนา",
          "postCode": [
            "63160"
          ]
        },
        {
          "id": 630704,
          "name": "วาเล่ย์",
          "postCode": [
            "63160"
          ]
        }
      ]
    },
    {
      "city": "วังเจ้า",
      "cityCode": 6309,
      "provinceCode": 63,
      "subDistrict": [
        {
          "id": 630902,
          "name": "นาโบสถ์",
          "postCode": [
            "63180"
          ]
        },
        {
          "id": 630903,
          "name": "ประดาง",
          "postCode": [
            "63180"
          ]
        },
        {
          "id": 630901,
          "name": "เชียงทอง",
          "postCode": [
            "63180"
          ]
        }
      ]
    },
    {
      "city": "สามเงา",
      "cityCode": 6303,
      "provinceCode": 63,
      "subDistrict": [
        {
          "id": 630305,
          "name": "บ้านนา",
          "postCode": [
            "63130"
          ]
        },
        {
          "id": 630303,
          "name": "ยกกระบัตร",
          "postCode": [
            "63130"
          ]
        },
        {
          "id": 630304,
          "name": "ย่านรี",
          "postCode": [
            "63130"
          ]
        },
        {
          "id": 630306,
          "name": "วังจันทร์",
          "postCode": [
            "63130"
          ]
        },
        {
          "id": 630302,
          "name": "วังหมัน",
          "postCode": [
            "63130"
          ]
        },
        {
          "id": 630301,
          "name": "สามเงา",
          "postCode": [
            "63130"
          ]
        }
      ]
    },
    {
      "city": "อุ้มผาง",
      "cityCode": 6308,
      "provinceCode": 63,
      "subDistrict": [
        {
          "id": 630802,
          "name": "หนองหลวง",
          "postCode": [
            "63170"
          ]
        },
        {
          "id": 630801,
          "name": "อุ้มผาง",
          "postCode": [
            "63170"
          ]
        },
        {
          "id": 630806,
          "name": "แม่กลอง",
          "postCode": [
            "63170"
          ]
        },
        {
          "id": 630804,
          "name": "แม่จัน",
          "postCode": [
            "63170"
          ]
        },
        {
          "id": 630805,
          "name": "แม่ละมุ้ง",
          "postCode": [
            "63170"
          ]
        },
        {
          "id": 630803,
          "name": "โมโกร",
          "postCode": [
            "63170"
          ]
        }
      ]
    },
    {
      "city": "เมืองตาก",
      "cityCode": 6301,
      "provinceCode": 63,
      "subDistrict": [
        {
          "id": 630115,
          "name": "ตลุกกลางทุ่ง",
          "postCode": [
            "63000"
          ]
        },
        {
          "id": 630108,
          "name": "น้ำรึม",
          "postCode": [
            "63000"
          ]
        },
        {
          "id": 630112,
          "name": "ป่ามะม่วง",
          "postCode": [
            "63000"
          ]
        },
        {
          "id": 630101,
          "name": "ระแหง",
          "postCode": [
            "63000"
          ]
        },
        {
          "id": 630114,
          "name": "วังประจบ",
          "postCode": [
            "63000"
          ]
        },
        {
          "id": 630109,
          "name": "วังหิน",
          "postCode": [
            "63000"
          ]
        },
        {
          "id": 630105,
          "name": "หนองบัวเหนือ",
          "postCode": [
            "63000"
          ]
        },
        {
          "id": 630113,
          "name": "หนองบัวใต้",
          "postCode": [
            "63000"
          ]
        },
        {
          "id": 630102,
          "name": "หนองหลวง",
          "postCode": [
            "63000"
          ]
        },
        {
          "id": 630104,
          "name": "หัวเดียด",
          "postCode": [
            "63000"
          ]
        },
        {
          "id": 630103,
          "name": "เชียงเงิน",
          "postCode": [
            "63000"
          ]
        },
        {
          "id": 630111,
          "name": "แม่ท้อ",
          "postCode": [
            "63000"
          ]
        },
        {
          "id": 630107,
          "name": "โป่งแดง",
          "postCode": [
            "63000"
          ]
        },
        {
          "id": 630106,
          "name": "ไม้งาม",
          "postCode": [
            "63000"
          ]
        }
      ]
    },
    {
      "city": "แม่ระมาด",
      "cityCode": 6304,
      "provinceCode": 63,
      "subDistrict": [
        {
          "id": 630403,
          "name": "ขะเนจื้อ",
          "postCode": [
            "63140"
          ]
        },
        {
          "id": 630406,
          "name": "พระธาตุ",
          "postCode": [
            "63140"
          ]
        },
        {
          "id": 630405,
          "name": "สามหมื่น",
          "postCode": [
            "63140"
          ]
        },
        {
          "id": 630402,
          "name": "แม่จะเรา",
          "postCode": [
            "63140"
          ]
        },
        {
          "id": 630404,
          "name": "แม่ตื่น",
          "postCode": [
            "63140"
          ]
        },
        {
          "id": 630401,
          "name": "แม่ระมาด",
          "postCode": [
            "63140"
          ]
        }
      ]
    },
    {
      "city": "แม่สอด",
      "cityCode": 6306,
      "provinceCode": 63,
      "subDistrict": [
        {
          "id": 630609,
          "name": "ด่านแม่ละเมา",
          "postCode": [
            "63110"
          ]
        },
        {
          "id": 630606,
          "name": "ท่าสายลวด",
          "postCode": [
            "63110"
          ]
        },
        {
          "id": 630610,
          "name": "พระธาตุผาแดง",
          "postCode": [
            "63110"
          ]
        },
        {
          "id": 630603,
          "name": "พะวอ",
          "postCode": [
            "63110"
          ]
        },
        {
          "id": 630608,
          "name": "มหาวัน",
          "postCode": [
            "63110"
          ]
        },
        {
          "id": 630605,
          "name": "แม่กาษา",
          "postCode": [
            "63110"
          ]
        },
        {
          "id": 630602,
          "name": "แม่กุ",
          "postCode": [
            "63110"
          ]
        },
        {
          "id": 630604,
          "name": "แม่ตาว",
          "postCode": [
            "63110"
          ]
        },
        {
          "id": 630607,
          "name": "แม่ปะ",
          "postCode": [
            "63110"
          ]
        },
        {
          "id": 630601,
          "name": "แม่สอด",
          "postCode": [
            "63110"
          ]
        }
      ]
    },
    {
      "city": "บ้านนา",
      "cityCode": 2603,
      "provinceCode": 26,
      "subDistrict": [
        {
          "id": 260305,
          "name": "ทองหลาง",
          "postCode": [
            "26110"
          ]
        },
        {
          "id": 260306,
          "name": "บางอ้อ",
          "postCode": [
            "26110"
          ]
        },
        {
          "id": 260301,
          "name": "บ้านนา",
          "postCode": [
            "26110"
          ]
        },
        {
          "id": 260303,
          "name": "บ้านพริก",
          "postCode": [
            "26110"
          ]
        },
        {
          "id": 260302,
          "name": "บ้านพร้าว",
          "postCode": [
            "26110"
          ]
        },
        {
          "id": 260308,
          "name": "ป่าขะ",
          "postCode": [
            "26110"
          ]
        },
        {
          "id": 260307,
          "name": "พิกุลออก",
          "postCode": [
            "26110"
          ]
        },
        {
          "id": 260310,
          "name": "ศรีกะอาง",
          "postCode": [
            "26110"
          ]
        },
        {
          "id": 260304,
          "name": "อาษา",
          "postCode": [
            "26110"
          ]
        },
        {
          "id": 260309,
          "name": "เขาเพิ่ม",
          "postCode": [
            "26110"
          ]
        }
      ]
    },
    {
      "city": "ปากพลี",
      "cityCode": 2602,
      "provinceCode": 26,
      "subDistrict": [
        {
          "id": 260205,
          "name": "ท่าเรือ",
          "postCode": [
            "26130"
          ]
        },
        {
          "id": 260207,
          "name": "นาหินลาด",
          "postCode": [
            "26130"
          ]
        },
        {
          "id": 260203,
          "name": "ปากพลี",
          "postCode": [
            "26130"
          ]
        },
        {
          "id": 260206,
          "name": "หนองแสง",
          "postCode": [
            "26130"
          ]
        },
        {
          "id": 260201,
          "name": "เกาะหวาย",
          "postCode": [
            "26130"
          ]
        },
        {
          "id": 260202,
          "name": "เกาะโพธิ์",
          "postCode": [
            "26130"
          ]
        },
        {
          "id": 260204,
          "name": "โคกกรวด",
          "postCode": [
            "26130"
          ]
        }
      ]
    },
    {
      "city": "องครักษ์",
      "cityCode": 2604,
      "provinceCode": 26,
      "subDistrict": [
        {
          "id": 260411,
          "name": "คลองใหญ่",
          "postCode": [
            "26120"
          ]
        },
        {
          "id": 260410,
          "name": "ชุมพล",
          "postCode": [
            "26120"
          ]
        },
        {
          "id": 260406,
          "name": "ทรายมูล",
          "postCode": [
            "26120"
          ]
        },
        {
          "id": 260407,
          "name": "บางปลากด",
          "postCode": [
            "26120"
          ]
        },
        {
          "id": 260408,
          "name": "บางลูกเสือ",
          "postCode": [
            "26120"
          ]
        },
        {
          "id": 260405,
          "name": "บางสมบูรณ์",
          "postCode": [
            "26120"
          ]
        },
        {
          "id": 260402,
          "name": "บึงศาล",
          "postCode": [
            "26120"
          ]
        },
        {
          "id": 260401,
          "name": "พระอาจารย์",
          "postCode": [
            "26120"
          ]
        },
        {
          "id": 260403,
          "name": "ศีรษะกระบือ",
          "postCode": [
            "26120"
          ]
        },
        {
          "id": 260409,
          "name": "องครักษ์",
          "postCode": [
            "26120"
          ]
        },
        {
          "id": 260404,
          "name": "โพธิ์แทน",
          "postCode": [
            "26120"
          ]
        }
      ]
    },
    {
      "city": "เมืองนครนายก",
      "cityCode": 2601,
      "provinceCode": 26,
      "subDistrict": [
        {
          "id": 260108,
          "name": "ดงละคร",
          "postCode": [
            "26000"
          ]
        },
        {
          "id": 260106,
          "name": "ดอนยอ",
          "postCode": [
            "26000"
          ]
        },
        {
          "id": 260102,
          "name": "ท่าช้าง",
          "postCode": [
            "26000"
          ]
        },
        {
          "id": 260105,
          "name": "ท่าทราย",
          "postCode": [
            "26000"
          ]
        },
        {
          "id": 260101,
          "name": "นครนายก",
          "postCode": [
            "26000"
          ]
        },
        {
          "id": 260103,
          "name": "บ้านใหญ่",
          "postCode": [
            "26000"
          ]
        },
        {
          "id": 260113,
          "name": "พรหมณี",
          "postCode": [
            "26000",
            "26001"
          ]
        },
        {
          "id": 260104,
          "name": "วังกระโจม",
          "postCode": [
            "26000"
          ]
        },
        {
          "id": 260107,
          "name": "ศรีจุฬา",
          "postCode": [
            "26000"
          ]
        },
        {
          "id": 260109,
          "name": "ศรีนาวา",
          "postCode": [
            "26000"
          ]
        },
        {
          "id": 260110,
          "name": "สาริกา",
          "postCode": [
            "26000"
          ]
        },
        {
          "id": 260111,
          "name": "หินตั้ง",
          "postCode": [
            "26000"
          ]
        },
        {
          "id": 260112,
          "name": "เขาพระ",
          "postCode": [
            "26000"
          ]
        }
      ]
    },
    {
      "city": "กำแพงแสน",
      "cityCode": 7302,
      "provinceCode": 73,
      "subDistrict": [
        {
          "id": 730202,
          "name": "กระตีบ",
          "postCode": [
            "73180"
          ]
        },
        {
          "id": 730212,
          "name": "กำแพงแสน",
          "postCode": [
            "73140"
          ]
        },
        {
          "id": 730208,
          "name": "ดอนข่อย",
          "postCode": [
            "73140"
          ]
        },
        {
          "id": 730201,
          "name": "ทุ่งกระพังโหม",
          "postCode": [
            "73140"
          ]
        },
        {
          "id": 730205,
          "name": "ทุ่งขวาง",
          "postCode": [
            "73140"
          ]
        },
        {
          "id": 730207,
          "name": "ทุ่งบัว",
          "postCode": [
            "73140"
          ]
        },
        {
          "id": 730203,
          "name": "ทุ่งลูกนก",
          "postCode": [
            "73140"
          ]
        },
        {
          "id": 730213,
          "name": "รางพิกุล",
          "postCode": [
            "73140"
          ]
        },
        {
          "id": 730215,
          "name": "วังน้ำเขียว",
          "postCode": [
            "73140"
          ]
        },
        {
          "id": 730209,
          "name": "สระพัฒนา",
          "postCode": [
            "73180"
          ]
        },
        {
          "id": 730206,
          "name": "สระสี่มุม",
          "postCode": [
            "73140",
            "73180"
          ]
        },
        {
          "id": 730214,
          "name": "หนองกระทุ่ม",
          "postCode": [
            "73140"
          ]
        },
        {
          "id": 730204,
          "name": "ห้วยขวาง",
          "postCode": [
            "73140"
          ]
        },
        {
          "id": 730211,
          "name": "ห้วยม่วง",
          "postCode": [
            "73180"
          ]
        },
        {
          "id": 730210,
          "name": "ห้วยหมอนทอง",
          "postCode": [
            "73140"
          ]
        }
      ]
    },
    {
      "city": "ดอนตูม",
      "cityCode": 7304,
      "provinceCode": 73,
      "subDistrict": [
        {
          "id": 730404,
          "name": "ดอนพุทรา",
          "postCode": [
            "73150"
          ]
        },
        {
          "id": 730406,
          "name": "ดอนรวก",
          "postCode": [
            "73150"
          ]
        },
        {
          "id": 730405,
          "name": "บ้านหลวง",
          "postCode": [
            "73150"
          ]
        },
        {
          "id": 730408,
          "name": "ลำลูกบัว",
          "postCode": [
            "73150"
          ]
        },
        {
          "id": 730403,
          "name": "ลำเหย",
          "postCode": [
            "73150"
          ]
        },
        {
          "id": 730401,
          "name": "สามง่าม",
          "postCode": [
            "73150"
          ]
        },
        {
          "id": 730407,
          "name": "ห้วยด้วน",
          "postCode": [
            "73150"
          ]
        },
        {
          "id": 730402,
          "name": "ห้วยพระ",
          "postCode": [
            "73150"
          ]
        }
      ]
    },
    {
      "city": "นครชัยศรี",
      "cityCode": 7303,
      "provinceCode": 73,
      "subDistrict": [
        {
          "id": 730307,
          "name": "ขุนแก้ว",
          "postCode": [
            "73120"
          ]
        },
        {
          "id": 730323,
          "name": "งิ้วราย",
          "postCode": [
            "73120"
          ]
        },
        {
          "id": 730317,
          "name": "ดอนแฝก",
          "postCode": [
            "73120"
          ]
        },
        {
          "id": 730306,
          "name": "ท่ากระชับ",
          "postCode": [
            "73120"
          ]
        },
        {
          "id": 730304,
          "name": "ท่าตำหนัก",
          "postCode": [
            "73120"
          ]
        },
        {
          "id": 730308,
          "name": "ท่าพระยา",
          "postCode": [
            "73120"
          ]
        },
        {
          "id": 730301,
          "name": "นครชัยศรี",
          "postCode": [
            "73120"
          ]
        },
        {
          "id": 730302,
          "name": "บางกระเบา",
          "postCode": [
            "73120"
          ]
        },
        {
          "id": 730320,
          "name": "บางพระ",
          "postCode": [
            "73120"
          ]
        },
        {
          "id": 730310,
          "name": "บางระกำ",
          "postCode": [
            "73120"
          ]
        },
        {
          "id": 730305,
          "name": "บางแก้ว",
          "postCode": [
            "73120"
          ]
        },
        {
          "id": 730321,
          "name": "บางแก้วฟ้า",
          "postCode": [
            "73120"
          ]
        },
        {
          "id": 730309,
          "name": "พะเนียด",
          "postCode": [
            "73120"
          ]
        },
        {
          "id": 730322,
          "name": "ลานตากฟ้า",
          "postCode": [
            "73120"
          ]
        },
        {
          "id": 730319,
          "name": "วัดละมุด",
          "postCode": [
            "73120"
          ]
        },
        {
          "id": 730316,
          "name": "วัดสำโรง",
          "postCode": [
            "73120"
          ]
        },
        {
          "id": 730303,
          "name": "วัดแค",
          "postCode": [
            "73120"
          ]
        },
        {
          "id": 730314,
          "name": "ศรีมหาโพธิ์",
          "postCode": [
            "73120"
          ]
        },
        {
          "id": 730312,
          "name": "ศรีษะทอง",
          "postCode": [
            "73120"
          ]
        },
        {
          "id": 730315,
          "name": "สัมปทวน",
          "postCode": [
            "73120"
          ]
        },
        {
          "id": 730318,
          "name": "ห้วยพลู",
          "postCode": [
            "73120"
          ]
        },
        {
          "id": 730313,
          "name": "แหลมบัว",
          "postCode": [
            "73120"
          ]
        },
        {
          "id": 730311,
          "name": "โคกพระเจดีย์",
          "postCode": [
            "73120"
          ]
        },
        {
          "id": 730324,
          "name": "ไทยาวาส",
          "postCode": [
            "73120"
          ]
        }
      ]
    },
    {
      "city": "บางเลน",
      "cityCode": 7305,
      "provinceCode": 73,
      "subDistrict": [
        {
          "id": 730512,
          "name": "คลองนกกระทุง",
          "postCode": [
            "73130"
          ]
        },
        {
          "id": 730509,
          "name": "ดอนตูม",
          "postCode": [
            "73130"
          ]
        },
        {
          "id": 730513,
          "name": "นราภิรมย์",
          "postCode": [
            "73130"
          ]
        },
        {
          "id": 730510,
          "name": "นิลเพชร",
          "postCode": [
            "73130"
          ]
        },
        {
          "id": 730511,
          "name": "บัวปากท่า",
          "postCode": [
            "73130"
          ]
        },
        {
          "id": 730502,
          "name": "บางปลา",
          "postCode": [
            "73130"
          ]
        },
        {
          "id": 730504,
          "name": "บางภาษี",
          "postCode": [
            "73130"
          ]
        },
        {
          "id": 730505,
          "name": "บางระกำ",
          "postCode": [
            "73130"
          ]
        },
        {
          "id": 730503,
          "name": "บางหลวง",
          "postCode": [
            "73190"
          ]
        },
        {
          "id": 730501,
          "name": "บางเลน",
          "postCode": [
            "73130"
          ]
        },
        {
          "id": 730506,
          "name": "บางไทรป่า",
          "postCode": [
            "73130"
          ]
        },
        {
          "id": 730514,
          "name": "ลำพญา",
          "postCode": [
            "73130"
          ]
        },
        {
          "id": 730507,
          "name": "หินมูล",
          "postCode": [
            "73190"
          ]
        },
        {
          "id": 730508,
          "name": "ไทรงาม",
          "postCode": [
            "73130"
          ]
        },
        {
          "id": 730515,
          "name": "ไผ่หูช้าง",
          "postCode": [
            "73130"
          ]
        }
      ]
    },
    {
      "city": "พุทธมณฑล",
      "cityCode": 7307,
      "provinceCode": 73,
      "subDistrict": [
        {
          "id": 730702,
          "name": "คลองโยง",
          "postCode": [
            "73170"
          ]
        },
        {
          "id": 730703,
          "name": "มหาสวัสดิ์",
          "postCode": [
            "73170"
          ]
        },
        {
          "id": 730701,
          "name": "ศาลายา",
          "postCode": [
            "73170"
          ]
        }
      ]
    },
    {
      "city": "สามพราน",
      "cityCode": 7306,
      "provinceCode": 73,
      "subDistrict": [
        {
          "id": 730610,
          "name": "กระทุ่มล้ม",
          "postCode": [
            "73220"
          ]
        },
        {
          "id": 730613,
          "name": "คลองจินดา",
          "postCode": [
            "73110"
          ]
        },
        {
          "id": 730611,
          "name": "คลองใหม่",
          "postCode": [
            "73110"
          ]
        },
        {
          "id": 730612,
          "name": "ตลาดจินดา",
          "postCode": [
            "73110"
          ]
        },
        {
          "id": 730602,
          "name": "ทรงคนอง",
          "postCode": [
            "73210"
          ]
        },
        {
          "id": 730601,
          "name": "ท่าข้าม",
          "postCode": [
            "73110"
          ]
        },
        {
          "id": 730609,
          "name": "ท่าตลาด",
          "postCode": [
            "73110"
          ]
        },
        {
          "id": 730604,
          "name": "บางกระทึก",
          "postCode": [
            "73210"
          ]
        },
        {
          "id": 730607,
          "name": "บางช้าง",
          "postCode": [
            "73110"
          ]
        },
        {
          "id": 730605,
          "name": "บางเตย",
          "postCode": [
            "73210"
          ]
        },
        {
          "id": 730615,
          "name": "บ้านใหม่",
          "postCode": [
            "73110"
          ]
        },
        {
          "id": 730614,
          "name": "ยายชา",
          "postCode": [
            "73110"
          ]
        },
        {
          "id": 730606,
          "name": "สามพราน",
          "postCode": [
            "73110"
          ]
        },
        {
          "id": 730603,
          "name": "หอมเกร็ด",
          "postCode": [
            "73110"
          ]
        },
        {
          "id": 730616,
          "name": "อ้อมใหญ่",
          "postCode": [
            "73160"
          ]
        },
        {
          "id": 730608,
          "name": "ไร่ขิง",
          "postCode": [
            "73210"
          ]
        }
      ]
    },
    {
      "city": "เมืองนครปฐม",
      "cityCode": 7301,
      "provinceCode": 73,
      "subDistrict": [
        {
          "id": 730108,
          "name": "ดอนยายหอม",
          "postCode": [
            "73000"
          ]
        },
        {
          "id": 730105,
          "name": "ตาก้อง",
          "postCode": [
            "73000"
          ]
        },
        {
          "id": 730109,
          "name": "ถนนขาด",
          "postCode": [
            "73000"
          ]
        },
        {
          "id": 730123,
          "name": "ทัพหลวง",
          "postCode": [
            "73000"
          ]
        },
        {
          "id": 730115,
          "name": "ทุ่งน้อย",
          "postCode": [
            "73000"
          ]
        },
        {
          "id": 730104,
          "name": "ธรรมศาลา",
          "postCode": [
            "73000"
          ]
        },
        {
          "id": 730111,
          "name": "นครปฐม",
          "postCode": [
            "73000"
          ]
        },
        {
          "id": 730102,
          "name": "บางแขม",
          "postCode": [
            "73000"
          ]
        },
        {
          "id": 730110,
          "name": "บ่อพลับ",
          "postCode": [
            "73000"
          ]
        },
        {
          "id": 730125,
          "name": "บ้านยาง",
          "postCode": [
            "73000"
          ]
        },
        {
          "id": 730101,
          "name": "พระปฐมเจดีย์",
          "postCode": [
            "73000"
          ]
        },
        {
          "id": 730103,
          "name": "พระประโทน",
          "postCode": [
            "73000"
          ]
        },
        {
          "id": 730106,
          "name": "มาบแค",
          "postCode": [
            "73000"
          ]
        },
        {
          "id": 730119,
          "name": "ลำพยา",
          "postCode": [
            "73000"
          ]
        },
        {
          "id": 730112,
          "name": "วังตะกู",
          "postCode": [
            "73000"
          ]
        },
        {
          "id": 730117,
          "name": "วังเย็น",
          "postCode": [
            "73000"
          ]
        },
        {
          "id": 730107,
          "name": "สนามจันทร์",
          "postCode": [
            "73000"
          ]
        },
        {
          "id": 730120,
          "name": "สระกะเทียม",
          "postCode": [
            "73000"
          ]
        },
        {
          "id": 730121,
          "name": "สวนป่าน",
          "postCode": [
            "73000"
          ]
        },
        {
          "id": 730114,
          "name": "สามควายเผือก",
          "postCode": [
            "73000"
          ]
        },
        {
          "id": 730124,
          "name": "หนองงูเหลือม",
          "postCode": [
            "73000"
          ]
        },
        {
          "id": 730116,
          "name": "หนองดินแดง",
          "postCode": [
            "73000"
          ]
        },
        {
          "id": 730113,
          "name": "หนองปากโลง",
          "postCode": [
            "73000"
          ]
        },
        {
          "id": 730122,
          "name": "ห้วยจรเข้",
          "postCode": [
            "73000"
          ]
        },
        {
          "id": 730118,
          "name": "โพรงมะเดื่อ",
          "postCode": [
            "73000"
          ]
        }
      ]
    },
    {
      "city": "ท่าอุเทน",
      "cityCode": 4803,
      "provinceCode": 48,
      "subDistrict": [
        {
          "id": 480303,
          "name": "ท่าจำปา",
          "postCode": [
            "48120"
          ]
        },
        {
          "id": 480301,
          "name": "ท่าอุเทน",
          "postCode": [
            "48120"
          ]
        },
        {
          "id": 480305,
          "name": "พนอม",
          "postCode": [
            "48120"
          ]
        },
        {
          "id": 480306,
          "name": "พะทาย",
          "postCode": [
            "48120"
          ]
        },
        {
          "id": 480312,
          "name": "รามราช",
          "postCode": [
            "48120"
          ]
        },
        {
          "id": 480314,
          "name": "หนองเทา",
          "postCode": [
            "48120"
          ]
        },
        {
          "id": 480311,
          "name": "เวินพระบาท",
          "postCode": [
            "48120"
          ]
        },
        {
          "id": 480302,
          "name": "โนนตาล",
          "postCode": [
            "48120"
          ]
        },
        {
          "id": 480304,
          "name": "ไชยบุรี",
          "postCode": [
            "48120"
          ]
        }
      ]
    },
    {
      "city": "ธาตุพนม",
      "cityCode": 4805,
      "provinceCode": 48,
      "subDistrict": [
        {
          "id": 480511,
          "name": "กุดฉิม",
          "postCode": [
            "48110"
          ]
        },
        {
          "id": 480507,
          "name": "ดอนนางหงส์",
          "postCode": [
            "48110"
          ]
        },
        {
          "id": 480501,
          "name": "ธาตุพนม",
          "postCode": [
            "48110"
          ]
        },
        {
          "id": 480512,
          "name": "ธาตุพนมเหนือ",
          "postCode": [
            "48110"
          ]
        },
        {
          "id": 480505,
          "name": "นาถ่อน",
          "postCode": [
            "48110"
          ]
        },
        {
          "id": 480510,
          "name": "นาหนาด",
          "postCode": [
            "48110"
          ]
        },
        {
          "id": 480508,
          "name": "น้ำก่ำ",
          "postCode": [
            "48110"
          ]
        },
        {
          "id": 480502,
          "name": "ฝั่งแดง",
          "postCode": [
            "48110"
          ]
        },
        {
          "id": 480504,
          "name": "พระกลางทุ่ง",
          "postCode": [
            "48110"
          ]
        },
        {
          "id": 480509,
          "name": "อุ่มเหม้า",
          "postCode": [
            "48110"
          ]
        },
        {
          "id": 480506,
          "name": "แสนพัน",
          "postCode": [
            "48110"
          ]
        },
        {
          "id": 480503,
          "name": "โพนแพง",
          "postCode": [
            "48110"
          ]
        }
      ]
    },
    {
      "city": "นาทม",
      "cityCode": 4811,
      "provinceCode": 48,
      "subDistrict": [
        {
          "id": 481103,
          "name": "ดอนเตย",
          "postCode": [
            "48140"
          ]
        },
        {
          "id": 481101,
          "name": "นาทม",
          "postCode": [
            "48140"
          ]
        },
        {
          "id": 481102,
          "name": "หนองซน",
          "postCode": [
            "48140"
          ]
        }
      ]
    },
    {
      "city": "นาหว้า",
      "cityCode": 4809,
      "provinceCode": 48,
      "subDistrict": [
        {
          "id": 480906,
          "name": "ท่าเรือ",
          "postCode": [
            "48180"
          ]
        },
        {
          "id": 480904,
          "name": "นาคูณใหญ่",
          "postCode": [
            "48180"
          ]
        },
        {
          "id": 480902,
          "name": "นางัว",
          "postCode": [
            "48180"
          ]
        },
        {
          "id": 480901,
          "name": "นาหว้า",
          "postCode": [
            "48180"
          ]
        },
        {
          "id": 480903,
          "name": "บ้านเสียว",
          "postCode": [
            "48180"
          ]
        },
        {
          "id": 480905,
          "name": "เหล่าพัฒนา",
          "postCode": [
            "48180"
          ]
        }
      ]
    },
    {
      "city": "นาแก",
      "cityCode": 4807,
      "provinceCode": 48,
      "subDistrict": [
        {
          "id": 480707,
          "name": "ก้านเหลือง",
          "postCode": [
            "48130"
          ]
        },
        {
          "id": 480713,
          "name": "คำพี้",
          "postCode": [
            "48130"
          ]
        },
        {
          "id": 480704,
          "name": "นาคู่",
          "postCode": [
            "48130"
          ]
        },
        {
          "id": 480709,
          "name": "นาเลียง",
          "postCode": [
            "48130"
          ]
        },
        {
          "id": 480701,
          "name": "นาแก",
          "postCode": [
            "48130"
          ]
        },
        {
          "id": 480712,
          "name": "บ้านแก้ง",
          "postCode": [
            "48130"
          ]
        },
        {
          "id": 480702,
          "name": "พระซอง",
          "postCode": [
            "48130"
          ]
        },
        {
          "id": 480705,
          "name": "พิมาน",
          "postCode": [
            "48130"
          ]
        },
        {
          "id": 480706,
          "name": "พุ่มแก",
          "postCode": [
            "48130"
          ]
        },
        {
          "id": 480715,
          "name": "สีชมพู",
          "postCode": [
            "48130"
          ]
        },
        {
          "id": 480708,
          "name": "หนองบ่อ",
          "postCode": [
            "48130"
          ]
        },
        {
          "id": 480703,
          "name": "หนองสังข์",
          "postCode": [
            "48130"
          ]
        }
      ]
    },
    {
      "city": "บ้านแพง",
      "cityCode": 4804,
      "provinceCode": 48,
      "subDistrict": [
        {
          "id": 480408,
          "name": "นางัว",
          "postCode": [
            "48140"
          ]
        },
        {
          "id": 480409,
          "name": "นาเข",
          "postCode": [
            "48140"
          ]
        },
        {
          "id": 480401,
          "name": "บ้านแพง",
          "postCode": [
            "48140"
          ]
        },
        {
          "id": 480404,
          "name": "หนองแวง",
          "postCode": [
            "48140"
          ]
        },
        {
          "id": 480403,
          "name": "โพนทอง",
          "postCode": [
            "48140"
          ]
        },
        {
          "id": 480402,
          "name": "ไผ่ล้อม",
          "postCode": [
            "48140"
          ]
        }
      ]
    },
    {
      "city": "ปลาปาก",
      "cityCode": 4802,
      "provinceCode": 48,
      "subDistrict": [
        {
          "id": 480203,
          "name": "กุตาไก้",
          "postCode": [
            "48160"
          ]
        },
        {
          "id": 480207,
          "name": "นามะเขือ",
          "postCode": [
            "48160"
          ]
        },
        {
          "id": 480201,
          "name": "ปลาปาก",
          "postCode": [
            "48160"
          ]
        },
        {
          "id": 480206,
          "name": "มหาชัย",
          "postCode": [
            "48160"
          ]
        },
        {
          "id": 480202,
          "name": "หนองฮี",
          "postCode": [
            "48160"
          ]
        },
        {
          "id": 480208,
          "name": "หนองเทาใหญ่",
          "postCode": [
            "48160"
          ]
        },
        {
          "id": 480204,
          "name": "โคกสว่าง",
          "postCode": [
            "48160"
          ]
        },
        {
          "id": 480205,
          "name": "โคกสูง",
          "postCode": [
            "48160"
          ]
        }
      ]
    },
    {
      "city": "วังยาง",
      "cityCode": 4812,
      "provinceCode": 48,
      "subDistrict": [
        {
          "id": 481203,
          "name": "ยอดชาด",
          "postCode": [
            "48130"
          ]
        },
        {
          "id": 481201,
          "name": "วังยาง",
          "postCode": [
            "48130"
          ]
        },
        {
          "id": 481204,
          "name": "หนองโพธิ์",
          "postCode": [
            "48130"
          ]
        },
        {
          "id": 481202,
          "name": "โคกสี",
          "postCode": [
            "48130"
          ]
        }
      ]
    },
    {
      "city": "ศรีสงคราม",
      "cityCode": 4808,
      "provinceCode": 48,
      "subDistrict": [
        {
          "id": 480805,
          "name": "ท่าบ่อสงคราม",
          "postCode": [
            "48150"
          ]
        },
        {
          "id": 480807,
          "name": "นาคำ",
          "postCode": [
            "48150"
          ]
        },
        {
          "id": 480802,
          "name": "นาเดื่อ",
          "postCode": [
            "48150"
          ]
        },
        {
          "id": 480806,
          "name": "บ้านข่า",
          "postCode": [
            "48150"
          ]
        },
        {
          "id": 480803,
          "name": "บ้านเอื้อง",
          "postCode": [
            "48150"
          ]
        },
        {
          "id": 480801,
          "name": "ศรีสงคราม",
          "postCode": [
            "48150"
          ]
        },
        {
          "id": 480804,
          "name": "สามผง",
          "postCode": [
            "48150"
          ]
        },
        {
          "id": 480809,
          "name": "หาดแพง",
          "postCode": [
            "48150"
          ]
        },
        {
          "id": 480808,
          "name": "โพนสว่าง",
          "postCode": [
            "48150"
          ]
        }
      ]
    },
    {
      "city": "เมืองนครพนม",
      "cityCode": 4801,
      "provinceCode": 48,
      "subDistrict": [
        {
          "id": 480105,
          "name": "กุรุคุ",
          "postCode": [
            "48000"
          ]
        },
        {
          "id": 480108,
          "name": "ขามเฒ่า",
          "postCode": [
            "48000"
          ]
        },
        {
          "id": 480111,
          "name": "คำเตย",
          "postCode": [
            "48000"
          ]
        },
        {
          "id": 480113,
          "name": "ดงขวาง",
          "postCode": [
            "48000"
          ]
        },
        {
          "id": 480110,
          "name": "ท่าค้อ",
          "postCode": [
            "48000"
          ]
        },
        {
          "id": 480103,
          "name": "นาทราย",
          "postCode": [
            "48000"
          ]
        },
        {
          "id": 480104,
          "name": "นาราชควาย",
          "postCode": [
            "48000"
          ]
        },
        {
          "id": 480109,
          "name": "บ้านกลาง",
          "postCode": [
            "48000"
          ]
        },
        {
          "id": 480106,
          "name": "บ้านผึ้ง",
          "postCode": [
            "48000"
          ]
        },
        {
          "id": 480114,
          "name": "วังตามัว",
          "postCode": [
            "48000"
          ]
        },
        {
          "id": 480112,
          "name": "หนองญาติ",
          "postCode": [
            "48000"
          ]
        },
        {
          "id": 480102,
          "name": "หนองแสง",
          "postCode": [
            "48000"
          ]
        },
        {
          "id": 480107,
          "name": "อาจสามารถ",
          "postCode": [
            "48000"
          ]
        },
        {
          "id": 480115,
          "name": "โพธิ์ตาก",
          "postCode": [
            "48000"
          ]
        },
        {
          "id": 480101,
          "name": "ในเมือง",
          "postCode": [
            "48000"
          ]
        }
      ]
    },
    {
      "city": "เรณูนคร",
      "cityCode": 4806,
      "provinceCode": 48,
      "subDistrict": [
        {
          "id": 480603,
          "name": "ท่าลาด",
          "postCode": [
            "48170"
          ]
        },
        {
          "id": 480609,
          "name": "นาขาม",
          "postCode": [
            "48170"
          ]
        },
        {
          "id": 480604,
          "name": "นางาม",
          "postCode": [
            "48170"
          ]
        },
        {
          "id": 480607,
          "name": "หนองย่างชิ้น",
          "postCode": [
            "48170"
          ]
        },
        {
          "id": 480601,
          "name": "เรณู",
          "postCode": [
            "48170"
          ]
        },
        {
          "id": 480608,
          "name": "เรณูใต้",
          "postCode": [
            "48170"
          ]
        },
        {
          "id": 480605,
          "name": "โคกหินแฮ่",
          "postCode": [
            "48170"
          ]
        },
        {
          "id": 480602,
          "name": "โพนทอง",
          "postCode": [
            "48170"
          ]
        }
      ]
    },
    {
      "city": "โพนสวรรค์",
      "cityCode": 4810,
      "provinceCode": 48,
      "subDistrict": [
        {
          "id": 481003,
          "name": "นาขมิ้น",
          "postCode": [
            "48190"
          ]
        },
        {
          "id": 481002,
          "name": "นาหัวบ่อ",
          "postCode": [
            "48190"
          ]
        },
        {
          "id": 481007,
          "name": "นาใน",
          "postCode": [
            "48190"
          ]
        },
        {
          "id": 481005,
          "name": "บ้านค้อ",
          "postCode": [
            "48190"
          ]
        },
        {
          "id": 481006,
          "name": "โพนจาน",
          "postCode": [
            "48190"
          ]
        },
        {
          "id": 481004,
          "name": "โพนบก",
          "postCode": [
            "48190"
          ]
        },
        {
          "id": 481001,
          "name": "โพนสวรรค์",
          "postCode": [
            "48190"
          ]
        }
      ]
    },
    {
      "city": "ขามทะเลสอ",
      "cityCode": 3019,
      "provinceCode": 30,
      "subDistrict": [
        {
          "id": 301901,
          "name": "ขามทะเลสอ",
          "postCode": [
            "30280"
          ]
        },
        {
          "id": 301905,
          "name": "บึงอ้อ",
          "postCode": [
            "30280"
          ]
        },
        {
          "id": 301903,
          "name": "พันดุง",
          "postCode": [
            "30280"
          ]
        },
        {
          "id": 301904,
          "name": "หนองสรวง",
          "postCode": [
            "30280"
          ]
        },
        {
          "id": 301902,
          "name": "โป่งแดง",
          "postCode": [
            "30280"
          ]
        }
      ]
    },
    {
      "city": "ขามสะแกแสง",
      "cityCode": 3011,
      "provinceCode": 30,
      "subDistrict": [
        {
          "id": 301101,
          "name": "ขามสะแกแสง",
          "postCode": [
            "30290"
          ]
        },
        {
          "id": 301104,
          "name": "ชีวึก",
          "postCode": [
            "30290"
          ]
        },
        {
          "id": 301105,
          "name": "พะงาด",
          "postCode": [
            "30290"
          ]
        },
        {
          "id": 301106,
          "name": "หนองหัวฟาน",
          "postCode": [
            "30290"
          ]
        },
        {
          "id": 301103,
          "name": "เมืองนาท",
          "postCode": [
            "30290"
          ]
        },
        {
          "id": 301107,
          "name": "เมืองเกษตร",
          "postCode": [
            "30290"
          ]
        },
        {
          "id": 301102,
          "name": "โนนเมือง",
          "postCode": [
            "30290"
          ]
        }
      ]
    },
    {
      "city": "คง",
      "cityCode": 3004,
      "provinceCode": 30,
      "subDistrict": [
        {
          "id": 300410,
          "name": "ขามสมบูรณ์",
          "postCode": [
            "30260"
          ]
        },
        {
          "id": 300402,
          "name": "คูขาด",
          "postCode": [
            "30260"
          ]
        },
        {
          "id": 300409,
          "name": "ดอนใหญ่",
          "postCode": [
            "30260"
          ]
        },
        {
          "id": 300404,
          "name": "ตาจั่น",
          "postCode": [
            "30260"
          ]
        },
        {
          "id": 300405,
          "name": "บ้านปรางค์",
          "postCode": [
            "30260"
          ]
        },
        {
          "id": 300407,
          "name": "หนองบัว",
          "postCode": [
            "30260"
          ]
        },
        {
          "id": 300406,
          "name": "หนองมะนาว",
          "postCode": [
            "30260"
          ]
        },
        {
          "id": 300403,
          "name": "เทพาลัย",
          "postCode": [
            "30260"
          ]
        },
        {
          "id": 300401,
          "name": "เมืองคง",
          "postCode": [
            "30260"
          ]
        },
        {
          "id": 300408,
          "name": "โนนเต็ง",
          "postCode": [
            "30260"
          ]
        }
      ]
    },
    {
      "city": "ครบุรี",
      "cityCode": 3002,
      "provinceCode": 30,
      "subDistrict": [
        {
          "id": 300203,
          "name": "ครบุรี",
          "postCode": [
            "30250"
          ]
        },
        {
          "id": 300210,
          "name": "ครบุรีใต้",
          "postCode": [
            "30250"
          ]
        },
        {
          "id": 300205,
          "name": "จระเข้หิน",
          "postCode": [
            "30250"
          ]
        },
        {
          "id": 300211,
          "name": "ตะแบกบาน",
          "postCode": [
            "30250"
          ]
        },
        {
          "id": 300208,
          "name": "บ้านใหม่",
          "postCode": [
            "30250"
          ]
        },
        {
          "id": 300206,
          "name": "มาบตะโกเอน",
          "postCode": [
            "30250"
          ]
        },
        {
          "id": 300209,
          "name": "ลำเพียก",
          "postCode": [
            "30250"
          ]
        },
        {
          "id": 300212,
          "name": "สระว่านพระยา",
          "postCode": [
            "30250"
          ]
        },
        {
          "id": 300207,
          "name": "อรพิมพ์",
          "postCode": [
            "30250"
          ]
        },
        {
          "id": 300202,
          "name": "เฉลียง",
          "postCode": [
            "30250"
          ]
        },
        {
          "id": 300201,
          "name": "แชะ",
          "postCode": [
            "30250"
          ]
        },
        {
          "id": 300204,
          "name": "โคกกระชาย",
          "postCode": [
            "30250"
          ]
        }
      ]
    },
    {
      "city": "จักราช",
      "cityCode": 3006,
      "provinceCode": 30,
      "subDistrict": [
        {
          "id": 300611,
          "name": "คลองเมือง",
          "postCode": [
            "30230"
          ]
        },
        {
          "id": 300601,
          "name": "จักราช",
          "postCode": [
            "30230"
          ]
        },
        {
          "id": 300603,
          "name": "ทองหลาง",
          "postCode": [
            "30230"
          ]
        },
        {
          "id": 300610,
          "name": "ศรีละกอ",
          "postCode": [
            "30230"
          ]
        },
        {
          "id": 300604,
          "name": "สีสุก",
          "postCode": [
            "30230"
          ]
        },
        {
          "id": 300605,
          "name": "หนองขาม",
          "postCode": [
            "30230"
          ]
        },
        {
          "id": 300607,
          "name": "หนองพลวง",
          "postCode": [
            "30230"
          ]
        },
        {
          "id": 300613,
          "name": "หินโคน",
          "postCode": [
            "30230"
          ]
        }
      ]
    },
    {
      "city": "ชุมพวง",
      "cityCode": 3017,
      "provinceCode": 30,
      "subDistrict": [
        {
          "id": 301701,
          "name": "ชุมพวง",
          "postCode": [
            "30270"
          ]
        },
        {
          "id": 301705,
          "name": "ตลาดไทร",
          "postCode": [
            "30270"
          ]
        },
        {
          "id": 301703,
          "name": "ท่าลาด",
          "postCode": [
            "30270"
          ]
        },
        {
          "id": 301702,
          "name": "ประสุข",
          "postCode": [
            "30270"
          ]
        },
        {
          "id": 301704,
          "name": "สาหร่าย",
          "postCode": [
            "30270"
          ]
        },
        {
          "id": 301714,
          "name": "หนองหลัก",
          "postCode": [
            "30270"
          ]
        },
        {
          "id": 301716,
          "name": "โนนตูม",
          "postCode": [
            "30270"
          ]
        },
        {
          "id": 301717,
          "name": "โนนยอ",
          "postCode": [
            "30270"
          ]
        },
        {
          "id": 301710,
          "name": "โนนรัง",
          "postCode": [
            "30270"
          ]
        }
      ]
    },
    {
      "city": "ด่านขุนทด",
      "cityCode": 3008,
      "provinceCode": 30,
      "subDistrict": [
        {
          "id": 300801,
          "name": "กุดพิมาน",
          "postCode": [
            "30210"
          ]
        },
        {
          "id": 300802,
          "name": "ด่านขุนทด",
          "postCode": [
            "30210"
          ]
        },
        {
          "id": 300803,
          "name": "ด่านนอก",
          "postCode": [
            "30210"
          ]
        },
        {
          "id": 300804,
          "name": "ด่านใน",
          "postCode": [
            "30210"
          ]
        },
        {
          "id": 300805,
          "name": "ตะเคียน",
          "postCode": [
            "30210"
          ]
        },
        {
          "id": 300806,
          "name": "บ้านเก่า",
          "postCode": [
            "30210"
          ]
        },
        {
          "id": 300807,
          "name": "บ้านแปรง",
          "postCode": [
            "36220"
          ]
        },
        {
          "id": 300808,
          "name": "พันชนะ",
          "postCode": [
            "30210"
          ]
        },
        {
          "id": 300809,
          "name": "สระจรเข้",
          "postCode": [
            "30210"
          ]
        },
        {
          "id": 300810,
          "name": "หนองกราด",
          "postCode": [
            "30210"
          ]
        },
        {
          "id": 300811,
          "name": "หนองบัวตะเกียด",
          "postCode": [
            "30210"
          ]
        },
        {
          "id": 300812,
          "name": "หนองบัวละคร",
          "postCode": [
            "30210"
          ]
        },
        {
          "id": 300818,
          "name": "หนองไทร",
          "postCode": [
            "36220"
          ]
        },
        {
          "id": 300813,
          "name": "หินดาด",
          "postCode": [
            "30210"
          ]
        },
        {
          "id": 300815,
          "name": "ห้วยบง",
          "postCode": [
            "30210"
          ]
        },
        {
          "id": 300817,
          "name": "โนนเมืองพัฒนา",
          "postCode": [
            "30210"
          ]
        }
      ]
    },
    {
      "city": "บัวลาย",
      "cityCode": 3030,
      "provinceCode": 30,
      "subDistrict": [
        {
          "id": 303003,
          "name": "บัวลาย",
          "postCode": [
            "30120"
          ]
        },
        {
          "id": 303004,
          "name": "หนองหว้า",
          "postCode": [
            "30120"
          ]
        },
        {
          "id": 303001,
          "name": "เมืองพะไล",
          "postCode": [
            "30120"
          ]
        },
        {
          "id": 303002,
          "name": "โนนจาน",
          "postCode": [
            "30120"
          ]
        }
      ]
    },
    {
      "city": "บัวใหญ่",
      "cityCode": 3012,
      "provinceCode": 30,
      "subDistrict": [
        {
          "id": 301214,
          "name": "กุดจอก",
          "postCode": [
            "30120"
          ]
        },
        {
          "id": 301220,
          "name": "ขุนทอง",
          "postCode": [
            "30120"
          ]
        },
        {
          "id": 301206,
          "name": "ดอนตะหนิน",
          "postCode": [
            "30120"
          ]
        },
        {
          "id": 301215,
          "name": "ด่านช้าง",
          "postCode": [
            "30120"
          ]
        },
        {
          "id": 301201,
          "name": "บัวใหญ่",
          "postCode": [
            "30120"
          ]
        },
        {
          "id": 301207,
          "name": "หนองบัวสะอาด",
          "postCode": [
            "30120"
          ]
        },
        {
          "id": 301224,
          "name": "หนองแจ้งใหญ่",
          "postCode": [
            "30120"
          ]
        },
        {
          "id": 301203,
          "name": "ห้วยยาง",
          "postCode": [
            "30120"
          ]
        },
        {
          "id": 301204,
          "name": "เสมาใหญ่",
          "postCode": [
            "30120"
          ]
        },
        {
          "id": 301208,
          "name": "โนนทองหลาง",
          "postCode": [
            "30120"
          ]
        }
      ]
    },
    {
      "city": "บ้านเหลื่อม",
      "cityCode": 3005,
      "provinceCode": 30,
      "subDistrict": [
        {
          "id": 300504,
          "name": "ช่อระกา",
          "postCode": [
            "30350"
          ]
        },
        {
          "id": 300501,
          "name": "บ้านเหลื่อม",
          "postCode": [
            "30350"
          ]
        },
        {
          "id": 300502,
          "name": "วังโพธิ์",
          "postCode": [
            "30350"
          ]
        },
        {
          "id": 300503,
          "name": "โคกกระเบื้อง",
          "postCode": [
            "30350"
          ]
        }
      ]
    },
    {
      "city": "ประทาย",
      "cityCode": 3013,
      "provinceCode": 30,
      "subDistrict": [
        {
          "id": 301303,
          "name": "กระทุ่มราย",
          "postCode": [
            "30180"
          ]
        },
        {
          "id": 301310,
          "name": "ดอนมัน",
          "postCode": [
            "30180"
          ]
        },
        {
          "id": 301306,
          "name": "ตลาดไทร",
          "postCode": [
            "30180"
          ]
        },
        {
          "id": 301315,
          "name": "ทุ่งสว่าง",
          "postCode": [
            "30180"
          ]
        },
        {
          "id": 301313,
          "name": "นางรำ",
          "postCode": [
            "30180"
          ]
        },
        {
          "id": 301301,
          "name": "ประทาย",
          "postCode": [
            "30180"
          ]
        },
        {
          "id": 301304,
          "name": "วังไม้แดง",
          "postCode": [
            "30180"
          ]
        },
        {
          "id": 301308,
          "name": "หนองค่าย",
          "postCode": [
            "30180"
          ]
        },
        {
          "id": 301307,
          "name": "หนองพลวง",
          "postCode": [
            "30180"
          ]
        },
        {
          "id": 301309,
          "name": "หันห้วยทราย",
          "postCode": [
            "30180"
          ]
        },
        {
          "id": 301318,
          "name": "เมืองโดน",
          "postCode": [
            "30180"
          ]
        },
        {
          "id": 301317,
          "name": "โคกกลาง",
          "postCode": [
            "30180"
          ]
        },
        {
          "id": 301314,
          "name": "โนนเพ็ด",
          "postCode": [
            "30180"
          ]
        }
      ]
    },
    {
      "city": "ปักธงชัย",
      "cityCode": 3014,
      "provinceCode": 30,
      "subDistrict": [
        {
          "id": 301410,
          "name": "งิ้ว",
          "postCode": [
            "30150"
          ]
        },
        {
          "id": 301407,
          "name": "ดอน",
          "postCode": [
            "30150"
          ]
        },
        {
          "id": 301405,
          "name": "ตะขบ",
          "postCode": [
            "30150"
          ]
        },
        {
          "id": 301402,
          "name": "ตะคุ",
          "postCode": [
            "30150"
          ]
        },
        {
          "id": 301409,
          "name": "ตูม",
          "postCode": [
            "30150"
          ]
        },
        {
          "id": 301417,
          "name": "ธงชัยเหนือ",
          "postCode": [
            "30150"
          ]
        },
        {
          "id": 301406,
          "name": "นกออก",
          "postCode": [
            "30150"
          ]
        },
        {
          "id": 301420,
          "name": "บ่อปลาทอง",
          "postCode": [
            "30150"
          ]
        },
        {
          "id": 301416,
          "name": "ภูหลวง",
          "postCode": [
            "30150"
          ]
        },
        {
          "id": 301412,
          "name": "ลำนางแก้ว",
          "postCode": [
            "30150"
          ]
        },
        {
          "id": 301411,
          "name": "สะแกราช",
          "postCode": [
            "30150"
          ]
        },
        {
          "id": 301404,
          "name": "สำโรง",
          "postCode": [
            "30150"
          ]
        },
        {
          "id": 301418,
          "name": "สุขเกษม",
          "postCode": [
            "30150"
          ]
        },
        {
          "id": 301419,
          "name": "เกษมทรัพย์",
          "postCode": [
            "30150"
          ]
        },
        {
          "id": 301401,
          "name": "เมืองปัก",
          "postCode": [
            "30150"
          ]
        },
        {
          "id": 301403,
          "name": "โคกไทย",
          "postCode": [
            "30150"
          ]
        }
      ]
    },
    {
      "city": "ปากช่อง",
      "cityCode": 3021,
      "provinceCode": 30,
      "subDistrict": [
        {
          "id": 302102,
          "name": "กลางดง",
          "postCode": [
            "30320"
          ]
        },
        {
          "id": 302107,
          "name": "ขนงพระ",
          "postCode": [
            "30130"
          ]
        },
        {
          "id": 302109,
          "name": "คลองม่วง",
          "postCode": [
            "30130"
          ]
        },
        {
          "id": 302103,
          "name": "จันทึก",
          "postCode": [
            "30130"
          ]
        },
        {
          "id": 302101,
          "name": "ปากช่อง",
          "postCode": [
            "30130"
          ]
        },
        {
          "id": 302112,
          "name": "พญาเย็น",
          "postCode": [
            "30320"
          ]
        },
        {
          "id": 302104,
          "name": "วังกะทะ",
          "postCode": [
            "30130"
          ]
        },
        {
          "id": 302111,
          "name": "วังไทร",
          "postCode": [
            "30130"
          ]
        },
        {
          "id": 302110,
          "name": "หนองน้ำแดง",
          "postCode": [
            "30130"
          ]
        },
        {
          "id": 302106,
          "name": "หนองสาหร่าย",
          "postCode": [
            "30130"
          ]
        },
        {
          "id": 302105,
          "name": "หมูสี",
          "postCode": [
            "30130"
          ]
        },
        {
          "id": 302108,
          "name": "โป่งตาลอง",
          "postCode": [
            "30130"
          ]
        }
      ]
    },
    {
      "city": "พระทองคำ",
      "cityCode": 3028,
      "provinceCode": 30,
      "subDistrict": [
        {
          "id": 302804,
          "name": "ทัพรั้ง",
          "postCode": [
            "30220"
          ]
        },
        {
          "id": 302803,
          "name": "พังเทียม",
          "postCode": [
            "30220"
          ]
        },
        {
          "id": 302802,
          "name": "มาบกราด",
          "postCode": [
            "30220"
          ]
        },
        {
          "id": 302801,
          "name": "สระพระ",
          "postCode": [
            "30220"
          ]
        },
        {
          "id": 302805,
          "name": "หนองหอย",
          "postCode": [
            "30220"
          ]
        }
      ]
    },
    {
      "city": "พิมาย",
      "cityCode": 3015,
      "provinceCode": 30,
      "subDistrict": [
        {
          "id": 301509,
          "name": "กระชอน",
          "postCode": [
            "30110"
          ]
        },
        {
          "id": 301504,
          "name": "กระเบื้องใหญ่",
          "postCode": [
            "30110"
          ]
        },
        {
          "id": 301507,
          "name": "ชีวาน",
          "postCode": [
            "30110"
          ]
        },
        {
          "id": 301510,
          "name": "ดงใหญ่",
          "postCode": [
            "30110"
          ]
        },
        {
          "id": 301505,
          "name": "ท่าหลวง",
          "postCode": [
            "30110"
          ]
        },
        {
          "id": 301511,
          "name": "ธารละหลอด",
          "postCode": [
            "30110"
          ]
        },
        {
          "id": 301508,
          "name": "นิคมสร้างตนเอง",
          "postCode": [
            "30110"
          ]
        },
        {
          "id": 301506,
          "name": "รังกาใหญ่",
          "postCode": [
            "30110"
          ]
        },
        {
          "id": 301502,
          "name": "สัมฤทธิ์",
          "postCode": [
            "30110"
          ]
        },
        {
          "id": 301512,
          "name": "หนองระเวียง",
          "postCode": [
            "30110"
          ]
        },
        {
          "id": 301503,
          "name": "โบสถ์",
          "postCode": [
            "30110"
          ]
        },
        {
          "id": 301501,
          "name": "ในเมือง",
          "postCode": [
            "30110"
          ]
        }
      ]
    },
    {
      "city": "ลำทะเมนชัย",
      "cityCode": 3029,
      "provinceCode": 30,
      "subDistrict": [
        {
          "id": 302901,
          "name": "ขุย",
          "postCode": [
            "30270"
          ]
        },
        {
          "id": 302903,
          "name": "ช่องแมว",
          "postCode": [
            "30270"
          ]
        },
        {
          "id": 302902,
          "name": "บ้านยาง",
          "postCode": [
            "30270"
          ]
        },
        {
          "id": 302904,
          "name": "ไพล",
          "postCode": [
            "30270"
          ]
        }
      ]
    },
    {
      "city": "วังน้ำเขียว",
      "cityCode": 3025,
      "provinceCode": 30,
      "subDistrict": [
        {
          "id": 302503,
          "name": "ระเริง",
          "postCode": [
            "30150"
          ]
        },
        {
          "id": 302501,
          "name": "วังน้ำเขียว",
          "postCode": [
            "30370"
          ]
        },
        {
          "id": 302502,
          "name": "วังหมี",
          "postCode": [
            "30370"
          ]
        },
        {
          "id": 302504,
          "name": "อุดมทรัพย์",
          "postCode": [
            "30370"
          ]
        },
        {
          "id": 302505,
          "name": "ไทยสามัคคี",
          "postCode": [
            "30370"
          ]
        }
      ]
    },
    {
      "city": "สีคิ้ว",
      "cityCode": 3020,
      "provinceCode": 30,
      "subDistrict": [
        {
          "id": 302003,
          "name": "กฤษณา",
          "postCode": [
            "30140"
          ]
        },
        {
          "id": 302006,
          "name": "กุดน้อย",
          "postCode": [
            "30140"
          ]
        },
        {
          "id": 302010,
          "name": "คลองไผ่",
          "postCode": [
            "30340"
          ]
        },
        {
          "id": 302011,
          "name": "ดอนเมือง",
          "postCode": [
            "30140"
          ]
        },
        {
          "id": 302002,
          "name": "บ้านหัน",
          "postCode": [
            "30140"
          ]
        },
        {
          "id": 302009,
          "name": "มิตรภาพ",
          "postCode": [
            "30140"
          ]
        },
        {
          "id": 302004,
          "name": "ลาดบัวขาว",
          "postCode": [
            "30340"
          ]
        },
        {
          "id": 302008,
          "name": "วังโรงใหญ่",
          "postCode": [
            "30140"
          ]
        },
        {
          "id": 302001,
          "name": "สีคิ้ว",
          "postCode": [
            "30140"
          ]
        },
        {
          "id": 302007,
          "name": "หนองน้ำใส",
          "postCode": [
            "30140"
          ]
        },
        {
          "id": 302012,
          "name": "หนองบัวน้อย",
          "postCode": [
            "30140"
          ]
        },
        {
          "id": 302005,
          "name": "หนองหญ้าขาว",
          "postCode": [
            "30140"
          ]
        }
      ]
    },
    {
      "city": "สีดา",
      "cityCode": 3031,
      "provinceCode": 30,
      "subDistrict": [
        {
          "id": 303104,
          "name": "สามเมือง",
          "postCode": [
            "30430"
          ]
        },
        {
          "id": 303101,
          "name": "สีดา",
          "postCode": [
            "30430"
          ]
        },
        {
          "id": 303105,
          "name": "หนองตาดใหญ่",
          "postCode": [
            "30430"
          ]
        },
        {
          "id": 303103,
          "name": "โนนประดู่",
          "postCode": [
            "30430"
          ]
        },
        {
          "id": 303102,
          "name": "โพนทอง",
          "postCode": [
            "30430"
          ]
        }
      ]
    },
    {
      "city": "สูงเนิน",
      "cityCode": 3018,
      "provinceCode": 30,
      "subDistrict": [
        {
          "id": 301811,
          "name": "กุดจิก",
          "postCode": [
            "30380"
          ]
        },
        {
          "id": 301809,
          "name": "นากลาง",
          "postCode": [
            "30380"
          ]
        },
        {
          "id": 301804,
          "name": "บุ่งขี้เหล็ก",
          "postCode": [
            "30170"
          ]
        },
        {
          "id": 301807,
          "name": "มะเกลือเก่า",
          "postCode": [
            "30170"
          ]
        },
        {
          "id": 301808,
          "name": "มะเกลือใหม่",
          "postCode": [
            "30170"
          ]
        },
        {
          "id": 301801,
          "name": "สูงเนิน",
          "postCode": [
            "30170"
          ]
        },
        {
          "id": 301810,
          "name": "หนองตะไก้",
          "postCode": [
            "30380"
          ]
        },
        {
          "id": 301802,
          "name": "เสมา",
          "postCode": [
            "30170"
          ]
        },
        {
          "id": 301803,
          "name": "โคราช",
          "postCode": [
            "30170"
          ]
        },
        {
          "id": 301806,
          "name": "โค้งยาง",
          "postCode": [
            "30170"
          ]
        },
        {
          "id": 301805,
          "name": "โนนค่า",
          "postCode": [
            "30170"
          ]
        }
      ]
    },
    {
      "city": "หนองบุญมาก",
      "cityCode": 3022,
      "provinceCode": 30,
      "subDistrict": [
        {
          "id": 302209,
          "name": "บ้านใหม่",
          "postCode": [
            "30410"
          ]
        },
        {
          "id": 302207,
          "name": "ลุงเขว้า",
          "postCode": [
            "30410"
          ]
        },
        {
          "id": 302202,
          "name": "สารภี",
          "postCode": [
            "30410"
          ]
        },
        {
          "id": 302206,
          "name": "หนองตะไก้",
          "postCode": [
            "30410"
          ]
        },
        {
          "id": 302201,
          "name": "หนองบุนนาก",
          "postCode": [
            "30410"
          ]
        },
        {
          "id": 302204,
          "name": "หนองหัวแรต",
          "postCode": [
            "30410"
          ]
        },
        {
          "id": 302208,
          "name": "หนองไม้ไผ่",
          "postCode": [
            "30410"
          ]
        },
        {
          "id": 302205,
          "name": "แหลมทอง",
          "postCode": [
            "30410"
          ]
        },
        {
          "id": 302203,
          "name": "ไทยเจริญ",
          "postCode": [
            "30410"
          ]
        }
      ]
    },
    {
      "city": "ห้วยแถลง",
      "cityCode": 3016,
      "provinceCode": 30,
      "subDistrict": [
        {
          "id": 301607,
          "name": "กงรถ",
          "postCode": [
            "30240"
          ]
        },
        {
          "id": 301606,
          "name": "งิ้ว",
          "postCode": [
            "30240"
          ]
        },
        {
          "id": 301609,
          "name": "ตะโก",
          "postCode": [
            "30240"
          ]
        },
        {
          "id": 301602,
          "name": "ทับสวาย",
          "postCode": [
            "30240"
          ]
        },
        {
          "id": 301604,
          "name": "หลุ่งตะเคียน",
          "postCode": [
            "30240"
          ]
        },
        {
          "id": 301608,
          "name": "หลุ่งประดู่",
          "postCode": [
            "30240"
          ]
        },
        {
          "id": 301605,
          "name": "หินดาด",
          "postCode": [
            "30240"
          ]
        },
        {
          "id": 301610,
          "name": "ห้วยแคน",
          "postCode": [
            "30240"
          ]
        },
        {
          "id": 301601,
          "name": "ห้วยแถลง",
          "postCode": [
            "30240"
          ]
        },
        {
          "id": 301603,
          "name": "เมืองพลับพลา",
          "postCode": [
            "30240"
          ]
        }
      ]
    },
    {
      "city": "เฉลิมพระเกียรติ",
      "cityCode": 3032,
      "provinceCode": 30,
      "subDistrict": [
        {
          "id": 303201,
          "name": "ช้างทอง",
          "postCode": [
            "30230"
          ]
        },
        {
          "id": 303202,
          "name": "ท่าช้าง",
          "postCode": [
            "30230"
          ]
        },
        {
          "id": 303203,
          "name": "พระพุทธ",
          "postCode": [
            "30230"
          ]
        },
        {
          "id": 303204,
          "name": "หนองงูเหลือม",
          "postCode": [
            "30000"
          ]
        },
        {
          "id": 303205,
          "name": "หนองยาง",
          "postCode": [
            "30230"
          ]
        }
      ]
    },
    {
      "city": "เทพารักษ์",
      "cityCode": 3026,
      "provinceCode": 30,
      "subDistrict": [
        {
          "id": 302603,
          "name": "บึงปรือ",
          "postCode": [
            "30210"
          ]
        },
        {
          "id": 302604,
          "name": "วังยายทอง",
          "postCode": [
            "30210"
          ]
        },
        {
          "id": 302601,
          "name": "สำนักตะคร้อ",
          "postCode": [
            "30210"
          ]
        },
        {
          "id": 302602,
          "name": "หนองแวง",
          "postCode": [
            "30210"
          ]
        }
      ]
    },
    {
      "city": "เมืองนครราชสีมา",
      "cityCode": 3001,
      "provinceCode": 30,
      "subDistrict": [
        {
          "id": 300116,
          "name": "จอหอ",
          "postCode": [
            "30310"
          ]
        },
        {
          "id": 300122,
          "name": "ตลาด",
          "postCode": [
            "30310"
          ]
        },
        {
          "id": 300112,
          "name": "บ้านเกาะ",
          "postCode": [
            "30000"
          ]
        },
        {
          "id": 300115,
          "name": "บ้านโพธิ์",
          "postCode": [
            "30310"
          ]
        },
        {
          "id": 300113,
          "name": "บ้านใหม่",
          "postCode": [
            "30000"
          ]
        },
        {
          "id": 300107,
          "name": "ปรุใหญ่",
          "postCode": [
            "30000"
          ]
        },
        {
          "id": 300109,
          "name": "พลกรัง",
          "postCode": [
            "30000"
          ]
        },
        {
          "id": 300123,
          "name": "พะเนา",
          "postCode": [
            "30000"
          ]
        },
        {
          "id": 300114,
          "name": "พุดซา",
          "postCode": [
            "30000"
          ]
        },
        {
          "id": 300105,
          "name": "มะเริง",
          "postCode": [
            "30000"
          ]
        },
        {
          "id": 300121,
          "name": "สีมุม",
          "postCode": [
            "30000"
          ]
        },
        {
          "id": 300120,
          "name": "สุรนารี",
          "postCode": [
            "30000"
          ]
        },
        {
          "id": 300124,
          "name": "หนองกระทุ่ม",
          "postCode": [
            "30000"
          ]
        },
        {
          "id": 300103,
          "name": "หนองจะบก",
          "postCode": [
            "30000"
          ]
        },
        {
          "id": 300119,
          "name": "หนองบัวศาลา",
          "postCode": [
            "30000"
          ]
        },
        {
          "id": 300106,
          "name": "หนองระเวียง",
          "postCode": [
            "30000"
          ]
        },
        {
          "id": 300125,
          "name": "หนองไข่น้ำ",
          "postCode": [
            "30310"
          ]
        },
        {
          "id": 300110,
          "name": "หนองไผ่ล้อม",
          "postCode": [
            "30000"
          ]
        },
        {
          "id": 300108,
          "name": "หมื่นไวย",
          "postCode": [
            "30000"
          ]
        },
        {
          "id": 300111,
          "name": "หัวทะเล",
          "postCode": [
            "30000"
          ]
        },
        {
          "id": 300117,
          "name": "โคกกรวด",
          "postCode": [
            "30280"
          ]
        },
        {
          "id": 300104,
          "name": "โคกสูง",
          "postCode": [
            "30310"
          ]
        },
        {
          "id": 300102,
          "name": "โพธิ์กลาง",
          "postCode": [
            "30000"
          ]
        },
        {
          "id": 300101,
          "name": "ในเมือง",
          "postCode": [
            "30000"
          ]
        },
        {
          "id": 300118,
          "name": "ไชยมงคล",
          "postCode": [
            "30000"
          ]
        }
      ]
    },
    {
      "city": "เมืองยาง",
      "cityCode": 3027,
      "provinceCode": 30,
      "subDistrict": [
        {
          "id": 302702,
          "name": "กระเบื้องนอก",
          "postCode": [
            "30270"
          ]
        },
        {
          "id": 302703,
          "name": "ละหานปลาค้าว",
          "postCode": [
            "30270"
          ]
        },
        {
          "id": 302701,
          "name": "เมืองยาง",
          "postCode": [
            "30270"
          ]
        },
        {
          "id": 302704,
          "name": "โนนอุดม",
          "postCode": [
            "30270"
          ]
        }
      ]
    },
    {
      "city": "เสิงสาง",
      "cityCode": 3003,
      "provinceCode": 30,
      "subDistrict": [
        {
          "id": 300304,
          "name": "กุดโบสถ์",
          "postCode": [
            "30330"
          ]
        },
        {
          "id": 300306,
          "name": "บ้านราษฎร์",
          "postCode": [
            "30330"
          ]
        },
        {
          "id": 300302,
          "name": "สระตะเคียน",
          "postCode": [
            "30330"
          ]
        },
        {
          "id": 300305,
          "name": "สุขไพบูลย์",
          "postCode": [
            "30330"
          ]
        },
        {
          "id": 300301,
          "name": "เสิงสาง",
          "postCode": [
            "30330"
          ]
        },
        {
          "id": 300303,
          "name": "โนนสมบูรณ์",
          "postCode": [
            "30330"
          ]
        }
      ]
    },
    {
      "city": "แก้งสนามนาง",
      "cityCode": 3023,
      "provinceCode": 30,
      "subDistrict": [
        {
          "id": 302303,
          "name": "บึงพะไล",
          "postCode": [
            "30440"
          ]
        },
        {
          "id": 302305,
          "name": "บึงสำโรง",
          "postCode": [
            "30440"
          ]
        },
        {
          "id": 302304,
          "name": "สีสุก",
          "postCode": [
            "30440"
          ]
        },
        {
          "id": 302301,
          "name": "แก้งสนามนาง",
          "postCode": [
            "30440"
          ]
        },
        {
          "id": 302302,
          "name": "โนนสำราญ",
          "postCode": [
            "30440"
          ]
        }
      ]
    },
    {
      "city": "โชคชัย",
      "cityCode": 3007,
      "provinceCode": 30,
      "subDistrict": [
        {
          "id": 300701,
          "name": "กระโทก",
          "postCode": [
            "30190"
          ]
        },
        {
          "id": 300710,
          "name": "ด่านเกวียน",
          "postCode": [
            "30190"
          ]
        },
        {
          "id": 300704,
          "name": "ทุ่งอรุณ",
          "postCode": [
            "30190"
          ]
        },
        {
          "id": 300706,
          "name": "ท่าจะหลุง",
          "postCode": [
            "30190"
          ]
        },
        {
          "id": 300705,
          "name": "ท่าลาดขาว",
          "postCode": [
            "30190"
          ]
        },
        {
          "id": 300703,
          "name": "ท่าอ่าง",
          "postCode": [
            "30190"
          ]
        },
        {
          "id": 300707,
          "name": "ท่าเยี่ยม",
          "postCode": [
            "30190"
          ]
        },
        {
          "id": 300702,
          "name": "พลับพลา",
          "postCode": [
            "30190"
          ]
        },
        {
          "id": 300709,
          "name": "ละลมใหม่พัฒนา",
          "postCode": [
            "30190"
          ]
        },
        {
          "id": 300708,
          "name": "โชคชัย",
          "postCode": [
            "30190"
          ]
        }
      ]
    },
    {
      "city": "โนนสูง",
      "cityCode": 3010,
      "provinceCode": 30,
      "subDistrict": [
        {
          "id": 301011,
          "name": "ขามเฒ่า",
          "postCode": [
            "30160"
          ]
        },
        {
          "id": 301010,
          "name": "จันอัด",
          "postCode": [
            "30160"
          ]
        },
        {
          "id": 301005,
          "name": "ดอนชมพู",
          "postCode": [
            "30160"
          ]
        },
        {
          "id": 301015,
          "name": "ดอนหวาย",
          "postCode": [
            "30160"
          ]
        },
        {
          "id": 301012,
          "name": "ด่านคล้า",
          "postCode": [
            "30160"
          ]
        },
        {
          "id": 301006,
          "name": "ธารปราสาท",
          "postCode": [
            "30420"
          ]
        },
        {
          "id": 301004,
          "name": "บิง",
          "postCode": [
            "30160"
          ]
        },
        {
          "id": 301009,
          "name": "พลสงคราม",
          "postCode": [
            "30160"
          ]
        },
        {
          "id": 301008,
          "name": "มะค่า",
          "postCode": [
            "30160"
          ]
        },
        {
          "id": 301013,
          "name": "ลำคอหงษ์",
          "postCode": [
            "30160"
          ]
        },
        {
          "id": 301016,
          "name": "ลำมูล",
          "postCode": [
            "30160"
          ]
        },
        {
          "id": 301007,
          "name": "หลุมข้าว",
          "postCode": [
            "30160"
          ]
        },
        {
          "id": 301014,
          "name": "เมืองปราสาท",
          "postCode": [
            "30160"
          ]
        },
        {
          "id": 301003,
          "name": "โตนด",
          "postCode": [
            "30160"
          ]
        },
        {
          "id": 301001,
          "name": "โนนสูง",
          "postCode": [
            "30160"
          ]
        },
        {
          "id": 301002,
          "name": "ใหม่",
          "postCode": [
            "30160"
          ]
        }
      ]
    },
    {
      "city": "โนนแดง",
      "cityCode": 3024,
      "provinceCode": 30,
      "subDistrict": [
        {
          "id": 302405,
          "name": "ดอนยาวใหญ่",
          "postCode": [
            "30360"
          ]
        },
        {
          "id": 302404,
          "name": "วังหิน",
          "postCode": [
            "30360"
          ]
        },
        {
          "id": 302403,
          "name": "สำพะเนียง",
          "postCode": [
            "30360"
          ]
        },
        {
          "id": 302402,
          "name": "โนนตาเถร",
          "postCode": [
            "30360"
          ]
        },
        {
          "id": 302401,
          "name": "โนนแดง",
          "postCode": [
            "30360"
          ]
        }
      ]
    },
    {
      "city": "โนนไทย",
      "cityCode": 3009,
      "provinceCode": 30,
      "subDistrict": [
        {
          "id": 300903,
          "name": "กำปัง",
          "postCode": [
            "30220"
          ]
        },
        {
          "id": 300905,
          "name": "ค้างพลู",
          "postCode": [
            "30220"
          ]
        },
        {
          "id": 300902,
          "name": "ด่านจาก",
          "postCode": [
            "30220"
          ]
        },
        {
          "id": 300909,
          "name": "ถนนโพธิ์",
          "postCode": [
            "30220"
          ]
        },
        {
          "id": 300907,
          "name": "บัลลังก์",
          "postCode": [
            "30220"
          ]
        },
        {
          "id": 300906,
          "name": "บ้านวัง",
          "postCode": [
            "30220"
          ]
        },
        {
          "id": 300914,
          "name": "มะค่า",
          "postCode": [
            "30220"
          ]
        },
        {
          "id": 300908,
          "name": "สายออ",
          "postCode": [
            "30220"
          ]
        },
        {
          "id": 300904,
          "name": "สำโรง",
          "postCode": [
            "30220"
          ]
        },
        {
          "id": 300901,
          "name": "โนนไทย",
          "postCode": [
            "30220"
          ]
        }
      ]
    },
    {
      "city": "ขนอม",
      "cityCode": 8015,
      "provinceCode": 80,
      "subDistrict": [
        {
          "id": 801501,
          "name": "ขนอม",
          "postCode": [
            "80210"
          ]
        },
        {
          "id": 801502,
          "name": "ควนทอง",
          "postCode": [
            "80210"
          ]
        },
        {
          "id": 801503,
          "name": "ท้องเนียน",
          "postCode": [
            "80210"
          ]
        }
      ]
    },
    {
      "city": "จุฬาภรณ์",
      "cityCode": 8019,
      "provinceCode": 80,
      "subDistrict": [
        {
          "id": 801903,
          "name": "ควนหนองคว้า",
          "postCode": [
            "80130"
          ]
        },
        {
          "id": 801904,
          "name": "ทุ่งโพธิ์",
          "postCode": [
            "80130"
          ]
        },
        {
          "id": 801905,
          "name": "นาหมอบุญ",
          "postCode": [
            "80130"
          ]
        },
        {
          "id": 801901,
          "name": "บ้านควนมุด",
          "postCode": [
            "80180"
          ]
        },
        {
          "id": 801902,
          "name": "บ้านชะอวด",
          "postCode": [
            "80180"
          ]
        },
        {
          "id": 801906,
          "name": "สามตำบล",
          "postCode": [
            "80130"
          ]
        }
      ]
    },
    {
      "city": "ฉวาง",
      "cityCode": 8004,
      "provinceCode": 80,
      "subDistrict": [
        {
          "id": 800406,
          "name": "กะเปียด",
          "postCode": [
            "80260"
          ]
        },
        {
          "id": 800416,
          "name": "จันดี",
          "postCode": [
            "80250"
          ]
        },
        {
          "id": 800401,
          "name": "ฉวาง",
          "postCode": [
            "80150",
            "80250"
          ]
        },
        {
          "id": 800407,
          "name": "นากะชะ",
          "postCode": [
            "80150",
            "80260"
          ]
        },
        {
          "id": 800415,
          "name": "นาเขลียง",
          "postCode": [
            "80260"
          ]
        },
        {
          "id": 800404,
          "name": "นาแว",
          "postCode": [
            "80150",
            "80260"
          ]
        },
        {
          "id": 800403,
          "name": "ละอาย",
          "postCode": [
            "80250"
          ]
        },
        {
          "id": 800409,
          "name": "ห้วยปริก",
          "postCode": [
            "80260"
          ]
        },
        {
          "id": 800405,
          "name": "ไม้เรียง",
          "postCode": [
            "80150",
            "80260"
          ]
        },
        {
          "id": 800410,
          "name": "ไสหร้า",
          "postCode": [
            "80150"
          ]
        }
      ]
    },
    {
      "city": "ชะอวด",
      "cityCode": 8007,
      "provinceCode": 80,
      "subDistrict": [
        {
          "id": 800707,
          "name": "ขอนหาด",
          "postCode": [
            "80180"
          ]
        },
        {
          "id": 800709,
          "name": "ควนหนองหงษ์",
          "postCode": [
            "80180"
          ]
        },
        {
          "id": 800701,
          "name": "ชะอวด",
          "postCode": [
            "80180"
          ]
        },
        {
          "id": 800703,
          "name": "ท่าประจะ",
          "postCode": [
            "80180"
          ]
        },
        {
          "id": 800702,
          "name": "ท่าเสม็ด",
          "postCode": [
            "80180"
          ]
        },
        {
          "id": 800711,
          "name": "นางหลง",
          "postCode": [
            "80180"
          ]
        },
        {
          "id": 800706,
          "name": "บ้านตูล",
          "postCode": [
            "80180"
          ]
        },
        {
          "id": 800705,
          "name": "วังอ่าง",
          "postCode": [
            "80180"
          ]
        },
        {
          "id": 800708,
          "name": "เกาะขันธ์",
          "postCode": [
            "80180"
          ]
        },
        {
          "id": 800710,
          "name": "เขาพระทอง",
          "postCode": [
            "80180"
          ]
        },
        {
          "id": 800704,
          "name": "เคร็ง",
          "postCode": [
            "80180"
          ]
        }
      ]
    },
    {
      "city": "ช้างกลาง",
      "cityCode": 8022,
      "provinceCode": 80,
      "subDistrict": [
        {
          "id": 802201,
          "name": "ช้างกลาง",
          "postCode": [
            "80220",
            "80250"
          ]
        },
        {
          "id": 802203,
          "name": "สวนขัน",
          "postCode": [
            "80250"
          ]
        },
        {
          "id": 802202,
          "name": "หลักช้าง",
          "postCode": [
            "80250"
          ]
        }
      ]
    },
    {
      "city": "ถ้ำพรรณรา",
      "cityCode": 8018,
      "provinceCode": 80,
      "subDistrict": [
        {
          "id": 801802,
          "name": "คลองเส",
          "postCode": [
            "80260"
          ]
        },
        {
          "id": 801803,
          "name": "ดุสิต",
          "postCode": [
            "80260"
          ]
        },
        {
          "id": 801801,
          "name": "ถ้ำพรรณรา",
          "postCode": [
            "80260"
          ]
        }
      ]
    },
    {
      "city": "ทุ่งสง",
      "cityCode": 8009,
      "provinceCode": 80,
      "subDistrict": [
        {
          "id": 800908,
          "name": "กะปาง",
          "postCode": [
            "80310"
          ]
        },
        {
          "id": 800904,
          "name": "ควนกรด",
          "postCode": [
            "80110"
          ]
        },
        {
          "id": 800902,
          "name": "ชะมาย",
          "postCode": [
            "80110"
          ]
        },
        {
          "id": 800911,
          "name": "ถ้ำใหญ่",
          "postCode": [
            "80110"
          ]
        },
        {
          "id": 800909,
          "name": "ที่วัง",
          "postCode": [
            "80110",
            "80310"
          ]
        },
        {
          "id": 800906,
          "name": "นาหลวงเสน",
          "postCode": [
            "80110"
          ]
        },
        {
          "id": 800912,
          "name": "นาโพธิ์",
          "postCode": [
            "80110"
          ]
        },
        {
          "id": 800905,
          "name": "นาไม้ไผ่",
          "postCode": [
            "80110"
          ]
        },
        {
          "id": 800910,
          "name": "น้ำตก",
          "postCode": [
            "80110"
          ]
        },
        {
          "id": 800901,
          "name": "ปากแพรก",
          "postCode": [
            "80110"
          ]
        },
        {
          "id": 800903,
          "name": "หนองหงส์",
          "postCode": [
            "80110"
          ]
        },
        {
          "id": 800913,
          "name": "เขาขาว",
          "postCode": [
            "80110"
          ]
        },
        {
          "id": 800907,
          "name": "เขาโร",
          "postCode": [
            "80110",
            "80310"
          ]
        }
      ]
    },
    {
      "city": "ทุ่งใหญ่",
      "cityCode": 8011,
      "provinceCode": 80,
      "subDistrict": [
        {
          "id": 801107,
          "name": "กรุงหยัน",
          "postCode": [
            "80240"
          ]
        },
        {
          "id": 801104,
          "name": "กุแหระ",
          "postCode": [
            "80240"
          ]
        },
        {
          "id": 801102,
          "name": "ทุ่งสัง",
          "postCode": [
            "80240"
          ]
        },
        {
          "id": 801103,
          "name": "ทุ่งใหญ่",
          "postCode": [
            "80240"
          ]
        },
        {
          "id": 801101,
          "name": "ท่ายาง",
          "postCode": [
            "80240"
          ]
        },
        {
          "id": 801106,
          "name": "บางรูป",
          "postCode": [
            "80240"
          ]
        },
        {
          "id": 801105,
          "name": "ปริก",
          "postCode": [
            "80240"
          ]
        }
      ]
    },
    {
      "city": "ท่าศาลา",
      "cityCode": 8008,
      "provinceCode": 80,
      "subDistrict": [
        {
          "id": 800802,
          "name": "กลาย",
          "postCode": [
            "80160"
          ]
        },
        {
          "id": 800810,
          "name": "ดอนตะโก",
          "postCode": [
            "80160"
          ]
        },
        {
          "id": 800811,
          "name": "ตลิ่งชัน",
          "postCode": [
            "80160"
          ]
        },
        {
          "id": 800803,
          "name": "ท่าขึ้น",
          "postCode": [
            "80160"
          ]
        },
        {
          "id": 800801,
          "name": "ท่าศาลา",
          "postCode": [
            "80160"
          ]
        },
        {
          "id": 800806,
          "name": "สระแก้ว",
          "postCode": [
            "80160"
          ]
        },
        {
          "id": 800804,
          "name": "หัวตะพาน",
          "postCode": [
            "80160"
          ]
        },
        {
          "id": 800813,
          "name": "โพธิ์ทอง",
          "postCode": [
            "80160"
          ]
        },
        {
          "id": 800807,
          "name": "โมคลาน",
          "postCode": [
            "80160"
          ]
        },
        {
          "id": 800809,
          "name": "ไทยบุรี",
          "postCode": [
            "80160"
          ]
        }
      ]
    },
    {
      "city": "นบพิตำ",
      "cityCode": 8021,
      "provinceCode": 80,
      "subDistrict": [
        {
          "id": 802102,
          "name": "กรุงชิง",
          "postCode": [
            "80160"
          ]
        },
        {
          "id": 802103,
          "name": "กะหรอ",
          "postCode": [
            "80160"
          ]
        },
        {
          "id": 802101,
          "name": "นบพิตำ",
          "postCode": [
            "80160"
          ]
        },
        {
          "id": 802104,
          "name": "นาเหรง",
          "postCode": [
            "80160"
          ]
        }
      ]
    },
    {
      "city": "นาบอน",
      "cityCode": 8010,
      "provinceCode": 80,
      "subDistrict": [
        {
          "id": 801002,
          "name": "ทุ่งสง",
          "postCode": [
            "80220"
          ]
        },
        {
          "id": 801001,
          "name": "นาบอน",
          "postCode": [
            "80220"
          ]
        },
        {
          "id": 801003,
          "name": "แก้วแสน",
          "postCode": [
            "80220",
            "80250"
          ]
        }
      ]
    },
    {
      "city": "บางขัน",
      "cityCode": 8017,
      "provinceCode": 80,
      "subDistrict": [
        {
          "id": 801701,
          "name": "บางขัน",
          "postCode": [
            "80360"
          ]
        },
        {
          "id": 801704,
          "name": "บ้านนิคม",
          "postCode": [
            "80360"
          ]
        },
        {
          "id": 801702,
          "name": "บ้านลำนาว",
          "postCode": [
            "80360"
          ]
        },
        {
          "id": 801703,
          "name": "วังหิน",
          "postCode": [
            "80360"
          ]
        }
      ]
    },
    {
      "city": "ปากพนัง",
      "cityCode": 8012,
      "provinceCode": 80,
      "subDistrict": [
        {
          "id": 801218,
          "name": "ขนาบนาก",
          "postCode": [
            "80140"
          ]
        },
        {
          "id": 801205,
          "name": "คลองกระบือ",
          "postCode": [
            "80140"
          ]
        },
        {
          "id": 801202,
          "name": "คลองน้อย",
          "postCode": [
            "80330"
          ]
        },
        {
          "id": 801204,
          "name": "ชะเมา",
          "postCode": [
            "80330"
          ]
        },
        {
          "id": 801216,
          "name": "ท่าพยา",
          "postCode": [
            "80140"
          ]
        },
        {
          "id": 801213,
          "name": "บางตะพง",
          "postCode": [
            "80140"
          ]
        },
        {
          "id": 801212,
          "name": "บางพระ",
          "postCode": [
            "80140"
          ]
        },
        {
          "id": 801211,
          "name": "บางศาลา",
          "postCode": [
            "80140"
          ]
        },
        {
          "id": 801215,
          "name": "บ้านเพิง",
          "postCode": [
            "80140"
          ]
        },
        {
          "id": 801207,
          "name": "บ้านใหม่",
          "postCode": [
            "80140"
          ]
        },
        {
          "id": 801201,
          "name": "ปากพนัง",
          "postCode": [
            "80140"
          ]
        },
        {
          "id": 801210,
          "name": "ปากพนังฝั่งตะวันตก",
          "postCode": [
            "80140"
          ]
        },
        {
          "id": 801214,
          "name": "ปากพนังฝั่งตะวันออก",
          "postCode": [
            "80140"
          ]
        },
        {
          "id": 801217,
          "name": "ปากแพรก",
          "postCode": [
            "80140"
          ]
        },
        {
          "id": 801203,
          "name": "ป่าระกำ",
          "postCode": [
            "80140"
          ]
        },
        {
          "id": 801208,
          "name": "หูล่อง",
          "postCode": [
            "80140"
          ]
        },
        {
          "id": 801206,
          "name": "เกาะทวด",
          "postCode": [
            "80330"
          ]
        },
        {
          "id": 801209,
          "name": "แหลมตะลุมพุก",
          "postCode": [
            "80140"
          ]
        }
      ]
    },
    {
      "city": "พรหมคีรี",
      "cityCode": 8002,
      "provinceCode": 80,
      "subDistrict": [
        {
          "id": 800204,
          "name": "ทอนหงส์",
          "postCode": [
            "80320"
          ]
        },
        {
          "id": 800205,
          "name": "นาเรียง",
          "postCode": [
            "80320"
          ]
        },
        {
          "id": 800202,
          "name": "บ้านเกาะ",
          "postCode": [
            "80320"
          ]
        },
        {
          "id": 800201,
          "name": "พรหมโลก",
          "postCode": [
            "80320"
          ]
        },
        {
          "id": 800203,
          "name": "อินคีรี",
          "postCode": [
            "80320"
          ]
        }
      ]
    },
    {
      "city": "พระพรหม",
      "cityCode": 8020,
      "provinceCode": 80,
      "subDistrict": [
        {
          "id": 802004,
          "name": "ช้างซ้าย",
          "postCode": [
            "80000"
          ]
        },
        {
          "id": 802003,
          "name": "ท้ายสำเภา",
          "postCode": [
            "80000"
          ]
        },
        {
          "id": 802001,
          "name": "นาพรุ",
          "postCode": [
            "80000"
          ]
        },
        {
          "id": 802002,
          "name": "นาสาร",
          "postCode": [
            "80000"
          ]
        }
      ]
    },
    {
      "city": "พิปูน",
      "cityCode": 8005,
      "provinceCode": 80,
      "subDistrict": [
        {
          "id": 800502,
          "name": "กะทูน",
          "postCode": [
            "80270"
          ]
        },
        {
          "id": 800505,
          "name": "ควนกลาง",
          "postCode": [
            "80270"
          ]
        },
        {
          "id": 800501,
          "name": "พิปูน",
          "postCode": [
            "80270"
          ]
        },
        {
          "id": 800504,
          "name": "ยางค้อม",
          "postCode": [
            "80270"
          ]
        },
        {
          "id": 800503,
          "name": "เขาพระ",
          "postCode": [
            "80270"
          ]
        }
      ]
    },
    {
      "city": "ร่อนพิบูลย์",
      "cityCode": 8013,
      "provinceCode": 80,
      "subDistrict": [
        {
          "id": 801306,
          "name": "ควนชุม",
          "postCode": [
            "80130"
          ]
        },
        {
          "id": 801305,
          "name": "ควนพัง",
          "postCode": [
            "80130"
          ]
        },
        {
          "id": 801304,
          "name": "ควนเกย",
          "postCode": [
            "80130"
          ]
        },
        {
          "id": 801301,
          "name": "ร่อนพิบูลย์",
          "postCode": [
            "80130"
          ]
        },
        {
          "id": 801302,
          "name": "หินตก",
          "postCode": [
            "80350"
          ]
        },
        {
          "id": 801303,
          "name": "เสาธง",
          "postCode": [
            "80350"
          ]
        }
      ]
    },
    {
      "city": "ลานสกา",
      "cityCode": 8003,
      "provinceCode": 80,
      "subDistrict": [
        {
          "id": 800304,
          "name": "กำโลน",
          "postCode": [
            "80230"
          ]
        },
        {
          "id": 800305,
          "name": "ขุนทะเล",
          "postCode": [
            "80230"
          ]
        },
        {
          "id": 800303,
          "name": "ท่าดี",
          "postCode": [
            "80230"
          ]
        },
        {
          "id": 800302,
          "name": "ลานสกา",
          "postCode": [
            "80230"
          ]
        },
        {
          "id": 800301,
          "name": "เขาแก้ว",
          "postCode": [
            "80230"
          ]
        }
      ]
    },
    {
      "city": "สิชล",
      "cityCode": 8014,
      "provinceCode": 80,
      "subDistrict": [
        {
          "id": 801403,
          "name": "ฉลอง",
          "postCode": [
            "80120"
          ]
        },
        {
          "id": 801402,
          "name": "ทุ่งปรัง",
          "postCode": [
            "80120"
          ]
        },
        {
          "id": 801409,
          "name": "ทุ่งใส",
          "postCode": [
            "80120"
          ]
        },
        {
          "id": 801401,
          "name": "สิชล",
          "postCode": [
            "80120"
          ]
        },
        {
          "id": 801406,
          "name": "สี่ขีด",
          "postCode": [
            "80120"
          ]
        },
        {
          "id": 801408,
          "name": "เขาน้อย",
          "postCode": [
            "80120"
          ]
        },
        {
          "id": 801407,
          "name": "เทพราช",
          "postCode": [
            "80340"
          ]
        },
        {
          "id": 801405,
          "name": "เปลี่ยน",
          "postCode": [
            "80120"
          ]
        },
        {
          "id": 801404,
          "name": "เสาเภา",
          "postCode": [
            "80340"
          ]
        }
      ]
    },
    {
      "city": "หัวไทร",
      "cityCode": 8016,
      "provinceCode": 80,
      "subDistrict": [
        {
          "id": 801609,
          "name": "ควนชะลิก",
          "postCode": [
            "80170"
          ]
        },
        {
          "id": 801603,
          "name": "ทรายขาว",
          "postCode": [
            "80170"
          ]
        },
        {
          "id": 801608,
          "name": "ท่าซอม",
          "postCode": [
            "80170"
          ]
        },
        {
          "id": 801607,
          "name": "บางนบ",
          "postCode": [
            "80170"
          ]
        },
        {
          "id": 801606,
          "name": "บ้านราม",
          "postCode": [
            "80170"
          ]
        },
        {
          "id": 801610,
          "name": "รามแก้ว",
          "postCode": [
            "80170"
          ]
        },
        {
          "id": 801602,
          "name": "หน้าสตน",
          "postCode": [
            "80170"
          ]
        },
        {
          "id": 801601,
          "name": "หัวไทร",
          "postCode": [
            "80170"
          ]
        },
        {
          "id": 801611,
          "name": "เกาะเพชร",
          "postCode": [
            "80170"
          ]
        },
        {
          "id": 801605,
          "name": "เขาพังไกร",
          "postCode": [
            "80170"
          ]
        },
        {
          "id": 801604,
          "name": "แหลม",
          "postCode": [
            "80170"
          ]
        }
      ]
    },
    {
      "city": "เฉลิมพระเกียรติ",
      "cityCode": 8023,
      "provinceCode": 80,
      "subDistrict": [
        {
          "id": 802302,
          "name": "ดอนตรอ",
          "postCode": [
            "80290"
          ]
        },
        {
          "id": 802304,
          "name": "ทางพูน",
          "postCode": [
            "80290",
            "80350"
          ]
        },
        {
          "id": 802303,
          "name": "สวนหลวง",
          "postCode": [
            "80190"
          ]
        },
        {
          "id": 802301,
          "name": "เชียรเขา",
          "postCode": [
            "80190"
          ]
        }
      ]
    },
    {
      "city": "เชียรใหญ่",
      "cityCode": 8006,
      "provinceCode": 80,
      "subDistrict": [
        {
          "id": 800611,
          "name": "การะเกด",
          "postCode": [
            "80190"
          ]
        },
        {
          "id": 800603,
          "name": "ท่าขนาน",
          "postCode": [
            "80190"
          ]
        },
        {
          "id": 800607,
          "name": "ท้องลำเจียก",
          "postCode": [
            "80190"
          ]
        },
        {
          "id": 800604,
          "name": "บ้านกลาง",
          "postCode": [
            "80190"
          ]
        },
        {
          "id": 800605,
          "name": "บ้านเนิน",
          "postCode": [
            "80190"
          ]
        },
        {
          "id": 800612,
          "name": "เขาพระบาท",
          "postCode": [
            "80190"
          ]
        },
        {
          "id": 800601,
          "name": "เชียรใหญ่",
          "postCode": [
            "80190"
          ]
        },
        {
          "id": 800610,
          "name": "เสือหึง",
          "postCode": [
            "80190"
          ]
        },
        {
          "id": 800613,
          "name": "แม่เจ้าอยู่หัว",
          "postCode": [
            "80190"
          ]
        },
        {
          "id": 800606,
          "name": "ไสหมาก",
          "postCode": [
            "80190"
          ]
        }
      ]
    },
    {
      "city": "เมืองนครศรีธรรมราช",
      "cityCode": 8001,
      "provinceCode": 80,
      "subDistrict": [
        {
          "id": 800112,
          "name": "กำแพงเซา",
          "postCode": [
            "80280"
          ]
        },
        {
          "id": 800103,
          "name": "คลัง",
          "postCode": [
            "80000"
          ]
        },
        {
          "id": 800116,
          "name": "ท่างิ้ว",
          "postCode": [
            "80280"
          ]
        },
        {
          "id": 800121,
          "name": "ท่าซัก",
          "postCode": [
            "80000"
          ]
        },
        {
          "id": 800102,
          "name": "ท่าวัง",
          "postCode": [
            "80000"
          ]
        },
        {
          "id": 800122,
          "name": "ท่าเรือ",
          "postCode": [
            "80000",
            "80290"
          ]
        },
        {
          "id": 800106,
          "name": "ท่าไร่",
          "postCode": [
            "80000"
          ]
        },
        {
          "id": 800108,
          "name": "นาทราย",
          "postCode": [
            "80280"
          ]
        },
        {
          "id": 800115,
          "name": "นาเคียน",
          "postCode": [
            "80000"
          ]
        },
        {
          "id": 800119,
          "name": "บางจาก",
          "postCode": [
            "80330"
          ]
        },
        {
          "id": 800107,
          "name": "ปากนคร",
          "postCode": [
            "80000"
          ]
        },
        {
          "id": 800120,
          "name": "ปากพูน",
          "postCode": [
            "80000"
          ]
        },
        {
          "id": 800114,
          "name": "มะม่วงสองต้น",
          "postCode": [
            "80000"
          ]
        },
        {
          "id": 800118,
          "name": "โพธิ์เสด็จ",
          "postCode": [
            "80000"
          ]
        },
        {
          "id": 800101,
          "name": "ในเมือง",
          "postCode": [
            "80000"
          ]
        },
        {
          "id": 800113,
          "name": "ไชยมนตรี",
          "postCode": [
            "80000"
          ]
        }
      ]
    },
    {
      "city": "ชุมตาบง",
      "cityCode": 6015,
      "provinceCode": 60,
      "subDistrict": [
        {
          "id": 601501,
          "name": "ชุมตาบง",
          "postCode": [
            "60150"
          ]
        },
        {
          "id": 601502,
          "name": "ปางสวรรค์",
          "postCode": [
            "60150"
          ]
        }
      ]
    },
    {
      "city": "ชุมแสง",
      "cityCode": 6003,
      "provinceCode": 60,
      "subDistrict": [
        {
          "id": 600311,
          "name": "ฆะมัง",
          "postCode": [
            "60120"
          ]
        },
        {
          "id": 600301,
          "name": "ชุมแสง",
          "postCode": [
            "60120"
          ]
        },
        {
          "id": 600302,
          "name": "ทับกฤช",
          "postCode": [
            "60250"
          ]
        },
        {
          "id": 600312,
          "name": "ทับกฤชใต้",
          "postCode": [
            "60250"
          ]
        },
        {
          "id": 600305,
          "name": "ท่าไม้",
          "postCode": [
            "60120"
          ]
        },
        {
          "id": 600306,
          "name": "บางเคียน",
          "postCode": [
            "60120"
          ]
        },
        {
          "id": 600308,
          "name": "พันลาน",
          "postCode": [
            "60250"
          ]
        },
        {
          "id": 600303,
          "name": "พิกุล",
          "postCode": [
            "60120"
          ]
        },
        {
          "id": 600307,
          "name": "หนองกระเจา",
          "postCode": [
            "60120"
          ]
        },
        {
          "id": 600304,
          "name": "เกยไชย",
          "postCode": [
            "60120"
          ]
        },
        {
          "id": 600309,
          "name": "โคกหม้อ",
          "postCode": [
            "60120"
          ]
        },
        {
          "id": 600310,
          "name": "ไผ่สิงห์",
          "postCode": [
            "60120"
          ]
        }
      ]
    },
    {
      "city": "ตากฟ้า",
      "cityCode": 6012,
      "provinceCode": 60,
      "subDistrict": [
        {
          "id": 601201,
          "name": "ตากฟ้า",
          "postCode": [
            "60190"
          ]
        },
        {
          "id": 601205,
          "name": "พุนกยูง",
          "postCode": [
            "60190"
          ]
        },
        {
          "id": 601202,
          "name": "ลำพยนต์",
          "postCode": [
            "60190"
          ]
        },
        {
          "id": 601203,
          "name": "สุขสำราญ",
          "postCode": [
            "60190"
          ]
        },
        {
          "id": 601204,
          "name": "หนองพิกุล",
          "postCode": [
            "60190"
          ]
        },
        {
          "id": 601206,
          "name": "อุดมธัญญา",
          "postCode": [
            "60190"
          ]
        },
        {
          "id": 601207,
          "name": "เขาชายธง",
          "postCode": [
            "60190"
          ]
        }
      ]
    },
    {
      "city": "ตาคลี",
      "cityCode": 6007,
      "provinceCode": 60,
      "subDistrict": [
        {
          "id": 600703,
          "name": "จันเสน",
          "postCode": [
            "60260"
          ]
        },
        {
          "id": 600702,
          "name": "ช่องแค",
          "postCode": [
            "60210"
          ]
        },
        {
          "id": 600701,
          "name": "ตาคลี",
          "postCode": [
            "60140"
          ]
        },
        {
          "id": 600710,
          "name": "พรหมนิมิต",
          "postCode": [
            "60210"
          ]
        },
        {
          "id": 600709,
          "name": "ลาดทิพรส",
          "postCode": [
            "60260"
          ]
        },
        {
          "id": 600708,
          "name": "สร้อยทอง",
          "postCode": [
            "60210"
          ]
        },
        {
          "id": 600707,
          "name": "หนองหม้อ",
          "postCode": [
            "60140"
          ]
        },
        {
          "id": 600706,
          "name": "หนองโพ",
          "postCode": [
            "60140"
          ]
        },
        {
          "id": 600705,
          "name": "หัวหวาย",
          "postCode": [
            "60140"
          ]
        },
        {
          "id": 600704,
          "name": "ห้วยหอม",
          "postCode": [
            "60210"
          ]
        }
      ]
    },
    {
      "city": "ท่าตะโก",
      "cityCode": 6008,
      "provinceCode": 60,
      "subDistrict": [
        {
          "id": 600806,
          "name": "ดอนคา",
          "postCode": [
            "60160"
          ]
        },
        {
          "id": 600807,
          "name": "ทำนบ",
          "postCode": [
            "60160"
          ]
        },
        {
          "id": 600801,
          "name": "ท่าตะโก",
          "postCode": [
            "60160"
          ]
        },
        {
          "id": 600802,
          "name": "พนมรอก",
          "postCode": [
            "60160"
          ]
        },
        {
          "id": 600809,
          "name": "พนมเศษ",
          "postCode": [
            "60160"
          ]
        },
        {
          "id": 600805,
          "name": "วังมหากร",
          "postCode": [
            "60160"
          ]
        },
        {
          "id": 600808,
          "name": "วังใหญ่",
          "postCode": [
            "60160"
          ]
        },
        {
          "id": 600804,
          "name": "สายลำโพง",
          "postCode": [
            "60160"
          ]
        },
        {
          "id": 600810,
          "name": "หนองหลวง",
          "postCode": [
            "60160"
          ]
        },
        {
          "id": 600803,
          "name": "หัวถนน",
          "postCode": [
            "60160"
          ]
        }
      ]
    },
    {
      "city": "บรรพตพิสัย",
      "cityCode": 6005,
      "provinceCode": 60,
      "subDistrict": [
        {
          "id": 600509,
          "name": "ด่านช้าง",
          "postCode": [
            "60180"
          ]
        },
        {
          "id": 600507,
          "name": "ตาขีด",
          "postCode": [
            "60180"
          ]
        },
        {
          "id": 600508,
          "name": "ตาสัง",
          "postCode": [
            "60180"
          ]
        },
        {
          "id": 600501,
          "name": "ท่างิ้ว",
          "postCode": [
            "60180"
          ]
        },
        {
          "id": 600502,
          "name": "บางตาหงาย",
          "postCode": [
            "60180"
          ]
        },
        {
          "id": 600506,
          "name": "บางแก้ว",
          "postCode": [
            "60180"
          ]
        },
        {
          "id": 600512,
          "name": "บึงปลาทู",
          "postCode": [
            "60180"
          ]
        },
        {
          "id": 600505,
          "name": "บ้านแดน",
          "postCode": [
            "60180"
          ]
        },
        {
          "id": 600510,
          "name": "หนองกรด",
          "postCode": [
            "60180"
          ]
        },
        {
          "id": 600511,
          "name": "หนองตางู",
          "postCode": [
            "60180"
          ]
        },
        {
          "id": 600503,
          "name": "หูกวาง",
          "postCode": [
            "60180"
          ]
        },
        {
          "id": 600504,
          "name": "อ่างทอง",
          "postCode": [
            "60180"
          ]
        },
        {
          "id": 600513,
          "name": "เจริญผล",
          "postCode": [
            "60180"
          ]
        }
      ]
    },
    {
      "city": "พยุหะคีรี",
      "cityCode": 6010,
      "provinceCode": 60,
      "subDistrict": [
        {
          "id": 601008,
          "name": "ท่าน้ำอ้อย",
          "postCode": [
            "60130"
          ]
        },
        {
          "id": 601003,
          "name": "นิคมเขาบ่อแก้ว",
          "postCode": [
            "60130"
          ]
        },
        {
          "id": 601009,
          "name": "น้ำทรง",
          "postCode": [
            "60130"
          ]
        },
        {
          "id": 601001,
          "name": "พยุหะ",
          "postCode": [
            "60130"
          ]
        },
        {
          "id": 601004,
          "name": "ม่วงหัก",
          "postCode": [
            "60130"
          ]
        },
        {
          "id": 601005,
          "name": "ยางขาว",
          "postCode": [
            "60130"
          ]
        },
        {
          "id": 601006,
          "name": "ย่านมัทรี",
          "postCode": [
            "60130"
          ]
        },
        {
          "id": 601011,
          "name": "สระทะเล",
          "postCode": [
            "60130"
          ]
        },
        {
          "id": 601010,
          "name": "เขากะลา",
          "postCode": [
            "60130"
          ]
        },
        {
          "id": 601007,
          "name": "เขาทอง",
          "postCode": [
            "60130"
          ]
        },
        {
          "id": 601002,
          "name": "เนินมะกอก",
          "postCode": [
            "60130"
          ]
        }
      ]
    },
    {
      "city": "ลาดยาว",
      "cityCode": 6011,
      "provinceCode": 60,
      "subDistrict": [
        {
          "id": 601109,
          "name": "บ้านไร่",
          "postCode": [
            "60150"
          ]
        },
        {
          "id": 601106,
          "name": "มาบแก",
          "postCode": [
            "60150"
          ]
        },
        {
          "id": 601101,
          "name": "ลาดยาว",
          "postCode": [
            "60150"
          ]
        },
        {
          "id": 601103,
          "name": "วังม้า",
          "postCode": [
            "60150"
          ]
        },
        {
          "id": 601104,
          "name": "วังเมือง",
          "postCode": [
            "60150"
          ]
        },
        {
          "id": 601116,
          "name": "ศาลเจ้าไก่ต่อ",
          "postCode": [
            "60150"
          ]
        },
        {
          "id": 601117,
          "name": "สระแก้ว",
          "postCode": [
            "60150"
          ]
        },
        {
          "id": 601105,
          "name": "สร้อยละคร",
          "postCode": [
            "60150"
          ]
        },
        {
          "id": 601108,
          "name": "หนองนมวัว",
          "postCode": [
            "60150"
          ]
        },
        {
          "id": 601107,
          "name": "หนองยาว",
          "postCode": [
            "60150"
          ]
        },
        {
          "id": 601102,
          "name": "ห้วยน้ำหอม",
          "postCode": [
            "60150"
          ]
        },
        {
          "id": 601110,
          "name": "เนินขี้เหล็ก",
          "postCode": [
            "60150"
          ]
        }
      ]
    },
    {
      "city": "หนองบัว",
      "cityCode": 6004,
      "provinceCode": 60,
      "subDistrict": [
        {
          "id": 600408,
          "name": "ทุ่งทอง",
          "postCode": [
            "60110"
          ]
        },
        {
          "id": 600403,
          "name": "ธารทหาร",
          "postCode": [
            "60110"
          ]
        },
        {
          "id": 600409,
          "name": "วังบ่อ",
          "postCode": [
            "60110"
          ]
        },
        {
          "id": 600402,
          "name": "หนองกลับ",
          "postCode": [
            "60110"
          ]
        },
        {
          "id": 600401,
          "name": "หนองบัว",
          "postCode": [
            "60110"
          ]
        },
        {
          "id": 600406,
          "name": "ห้วยถั่วเหนือ",
          "postCode": [
            "60110"
          ]
        },
        {
          "id": 600405,
          "name": "ห้วยถั่วใต้",
          "postCode": [
            "60110"
          ]
        },
        {
          "id": 600404,
          "name": "ห้วยร่วม",
          "postCode": [
            "60110"
          ]
        },
        {
          "id": 600407,
          "name": "ห้วยใหญ่",
          "postCode": [
            "60110"
          ]
        }
      ]
    },
    {
      "city": "เก้าเลี้ยว",
      "cityCode": 6006,
      "provinceCode": 60,
      "subDistrict": [
        {
          "id": 600601,
          "name": "มหาโพธิ",
          "postCode": [
            "60230"
          ]
        },
        {
          "id": 600603,
          "name": "หนองเต่า",
          "postCode": [
            "60230"
          ]
        },
        {
          "id": 600605,
          "name": "หัวดง",
          "postCode": [
            "60230"
          ]
        },
        {
          "id": 600602,
          "name": "เก้าเลี้ยว",
          "postCode": [
            "60230"
          ]
        },
        {
          "id": 600604,
          "name": "เขาดิน",
          "postCode": [
            "60230"
          ]
        }
      ]
    },
    {
      "city": "เมืองนครสวรรค์",
      "cityCode": 6001,
      "provinceCode": 60,
      "subDistrict": [
        {
          "id": 600102,
          "name": "กลางแดด",
          "postCode": [
            "60000"
          ]
        },
        {
          "id": 600105,
          "name": "ตะเคียนเลื่อน",
          "postCode": [
            "60000"
          ]
        },
        {
          "id": 600106,
          "name": "นครสวรรค์ตก",
          "postCode": [
            "60000"
          ]
        },
        {
          "id": 600107,
          "name": "นครสวรรค์ออก",
          "postCode": [
            "60000"
          ]
        },
        {
          "id": 600108,
          "name": "บางพระหลวง",
          "postCode": [
            "60000"
          ]
        },
        {
          "id": 600109,
          "name": "บางม่วง",
          "postCode": [
            "60000"
          ]
        },
        {
          "id": 600117,
          "name": "บึงเสนาท",
          "postCode": [
            "60000"
          ]
        },
        {
          "id": 600110,
          "name": "บ้านมะเกลือ",
          "postCode": [
            "60000"
          ]
        },
        {
          "id": 600111,
          "name": "บ้านแก่ง",
          "postCode": [
            "60000"
          ]
        },
        {
          "id": 600101,
          "name": "ปากน้ำโพ",
          "postCode": [
            "60000"
          ]
        },
        {
          "id": 600112,
          "name": "พระนอน",
          "postCode": [
            "60000"
          ]
        },
        {
          "id": 600113,
          "name": "วัดไทร",
          "postCode": [
            "60000"
          ]
        },
        {
          "id": 600114,
          "name": "หนองกรด",
          "postCode": [
            "60240"
          ]
        },
        {
          "id": 600115,
          "name": "หนองกระโดน",
          "postCode": [
            "60240"
          ]
        },
        {
          "id": 600116,
          "name": "หนองปลิง",
          "postCode": [
            "60000"
          ]
        },
        {
          "id": 600103,
          "name": "เกรียงไกร",
          "postCode": [
            "60000"
          ]
        },
        {
          "id": 600104,
          "name": "แควใหญ่",
          "postCode": [
            "60000"
          ]
        }
      ]
    },
    {
      "city": "แม่วงก์",
      "cityCode": 6013,
      "provinceCode": 60,
      "subDistrict": [
        {
          "id": 601304,
          "name": "วังซ่าน",
          "postCode": [
            "60150"
          ]
        },
        {
          "id": 601305,
          "name": "เขาชนกัน",
          "postCode": [
            "60150"
          ]
        },
        {
          "id": 601301,
          "name": "แม่วงก์",
          "postCode": [
            "60150"
          ]
        },
        {
          "id": 601303,
          "name": "แม่เล่ย์",
          "postCode": [
            "60150"
          ]
        }
      ]
    },
    {
      "city": "แม่เปิน",
      "cityCode": 6014,
      "provinceCode": 60,
      "subDistrict": [
        {
          "id": 601401,
          "name": "แม่เปิน",
          "postCode": [
            "60150"
          ]
        }
      ]
    },
    {
      "city": "โกรกพระ",
      "cityCode": 6002,
      "provinceCode": 60,
      "subDistrict": [
        {
          "id": 600205,
          "name": "นากลาง",
          "postCode": [
            "60170"
          ]
        },
        {
          "id": 600204,
          "name": "บางประมุง",
          "postCode": [
            "60170"
          ]
        },
        {
          "id": 600203,
          "name": "บางมะฝ่อ",
          "postCode": [
            "60170"
          ]
        },
        {
          "id": 600202,
          "name": "ยางตาล",
          "postCode": [
            "60170"
          ]
        },
        {
          "id": 600206,
          "name": "ศาลาแดง",
          "postCode": [
            "60170"
          ]
        },
        {
          "id": 600209,
          "name": "หาดสูง",
          "postCode": [
            "60170"
          ]
        },
        {
          "id": 600207,
          "name": "เนินกว้าว",
          "postCode": [
            "60170"
          ]
        },
        {
          "id": 600208,
          "name": "เนินศาลา",
          "postCode": [
            "60170"
          ]
        },
        {
          "id": 600201,
          "name": "โกรกพระ",
          "postCode": [
            "60170"
          ]
        }
      ]
    },
    {
      "city": "ไพศาลี",
      "cityCode": 6009,
      "provinceCode": 60,
      "subDistrict": [
        {
          "id": 600904,
          "name": "ตะคร้อ",
          "postCode": [
            "60220"
          ]
        },
        {
          "id": 600907,
          "name": "นาขอม",
          "postCode": [
            "60220"
          ]
        },
        {
          "id": 600906,
          "name": "วังข่อย",
          "postCode": [
            "60220"
          ]
        },
        {
          "id": 600903,
          "name": "วังน้ำลัด",
          "postCode": [
            "60220"
          ]
        },
        {
          "id": 600902,
          "name": "สำโรงชัย",
          "postCode": [
            "60220"
          ]
        },
        {
          "id": 600901,
          "name": "โคกเดื่อ",
          "postCode": [
            "60220"
          ]
        },
        {
          "id": 600905,
          "name": "โพธิ์ประสาท",
          "postCode": [
            "60220"
          ]
        },
        {
          "id": 600908,
          "name": "ไพศาลี",
          "postCode": [
            "60220"
          ]
        }
      ]
    },
    {
      "city": "บางกรวย",
      "cityCode": 1202,
      "provinceCode": 12,
      "subDistrict": [
        {
          "id": 120202,
          "name": "บางกรวย",
          "postCode": [
            "11130"
          ]
        },
        {
          "id": 120204,
          "name": "บางขนุน",
          "postCode": [
            "11130"
          ]
        },
        {
          "id": 120205,
          "name": "บางขุนกอง",
          "postCode": [
            "11130"
          ]
        },
        {
          "id": 120206,
          "name": "บางคูเวียง",
          "postCode": [
            "11130"
          ]
        },
        {
          "id": 120203,
          "name": "บางสีทอง",
          "postCode": [
            "11130"
          ]
        },
        {
          "id": 120208,
          "name": "ปลายบาง",
          "postCode": [
            "11130"
          ]
        },
        {
          "id": 120207,
          "name": "มหาสวัสดิ์",
          "postCode": [
            "11130"
          ]
        },
        {
          "id": 120201,
          "name": "วัดชลอ",
          "postCode": [
            "11130"
          ]
        },
        {
          "id": 120209,
          "name": "ศาลากลาง",
          "postCode": [
            "11130"
          ]
        }
      ]
    },
    {
      "city": "บางบัวทอง",
      "cityCode": 1204,
      "provinceCode": 12,
      "subDistrict": [
        {
          "id": 120404,
          "name": "บางคูรัด",
          "postCode": [
            "11110"
          ]
        },
        {
          "id": 120402,
          "name": "บางบัวทอง",
          "postCode": [
            "11110"
          ]
        },
        {
          "id": 120408,
          "name": "บางรักพัฒนา",
          "postCode": [
            "11110"
          ]
        },
        {
          "id": 120403,
          "name": "บางรักใหญ่",
          "postCode": [
            "11110"
          ]
        },
        {
          "id": 120407,
          "name": "พิมลราช",
          "postCode": [
            "11110"
          ]
        },
        {
          "id": 120405,
          "name": "ละหาร",
          "postCode": [
            "11110"
          ]
        },
        {
          "id": 120406,
          "name": "ลำโพ",
          "postCode": [
            "11110"
          ]
        },
        {
          "id": 120401,
          "name": "โสนลอย",
          "postCode": [
            "11110"
          ]
        }
      ]
    },
    {
      "city": "บางใหญ่",
      "cityCode": 1203,
      "provinceCode": 12,
      "subDistrict": [
        {
          "id": 120301,
          "name": "บางม่วง",
          "postCode": [
            "11140"
          ]
        },
        {
          "id": 120303,
          "name": "บางเลน",
          "postCode": [
            "11140"
          ]
        },
        {
          "id": 120302,
          "name": "บางแม่นาง",
          "postCode": [
            "11140"
          ]
        },
        {
          "id": 120305,
          "name": "บางใหญ่",
          "postCode": [
            "11140"
          ]
        },
        {
          "id": 120306,
          "name": "บ้านใหม่",
          "postCode": [
            "11140"
          ]
        },
        {
          "id": 120304,
          "name": "เสาธงหิน",
          "postCode": [
            "11140"
          ]
        }
      ]
    },
    {
      "city": "ปากเกร็ด",
      "cityCode": 1206,
      "provinceCode": 12,
      "subDistrict": [
        {
          "id": 120610,
          "name": "คลองข่อย",
          "postCode": [
            "11120"
          ]
        },
        {
          "id": 120606,
          "name": "คลองพระอุดม",
          "postCode": [
            "11120"
          ]
        },
        {
          "id": 120612,
          "name": "คลองเกลือ",
          "postCode": [
            "11120"
          ]
        },
        {
          "id": 120607,
          "name": "ท่าอิฐ",
          "postCode": [
            "11120"
          ]
        },
        {
          "id": 120602,
          "name": "บางตลาด",
          "postCode": [
            "11120"
          ]
        },
        {
          "id": 120605,
          "name": "บางตะไนย์",
          "postCode": [
            "11120"
          ]
        },
        {
          "id": 120611,
          "name": "บางพลับ",
          "postCode": [
            "11120"
          ]
        },
        {
          "id": 120604,
          "name": "บางพูด",
          "postCode": [
            "11120"
          ]
        },
        {
          "id": 120603,
          "name": "บ้านใหม่",
          "postCode": [
            "11120"
          ]
        },
        {
          "id": 120601,
          "name": "ปากเกร็ด",
          "postCode": [
            "11120"
          ]
        },
        {
          "id": 120609,
          "name": "อ้อมเกร็ด",
          "postCode": [
            "11120"
          ]
        },
        {
          "id": 120608,
          "name": "เกาะเกร็ด",
          "postCode": [
            "11120"
          ]
        }
      ]
    },
    {
      "city": "เมืองนนทบุรี",
      "cityCode": 1201,
      "provinceCode": 12,
      "subDistrict": [
        {
          "id": 120102,
          "name": "ตลาดขวัญ",
          "postCode": [
            "11000"
          ]
        },
        {
          "id": 120105,
          "name": "ท่าทราย",
          "postCode": [
            "11000"
          ]
        },
        {
          "id": 120104,
          "name": "บางกระสอ",
          "postCode": [
            "11000"
          ]
        },
        {
          "id": 120108,
          "name": "บางกร่าง",
          "postCode": [
            "11000"
          ]
        },
        {
          "id": 120110,
          "name": "บางรักน้อย",
          "postCode": [
            "11000"
          ]
        },
        {
          "id": 120107,
          "name": "บางศรีเมือง",
          "postCode": [
            "11000"
          ]
        },
        {
          "id": 120103,
          "name": "บางเขน",
          "postCode": [
            "11000"
          ]
        },
        {
          "id": 120106,
          "name": "บางไผ่",
          "postCode": [
            "11000"
          ]
        },
        {
          "id": 120101,
          "name": "สวนใหญ่",
          "postCode": [
            "11000"
          ]
        },
        {
          "id": 120109,
          "name": "ไทรม้า",
          "postCode": [
            "11000"
          ]
        }
      ]
    },
    {
      "city": "ไทรน้อย",
      "cityCode": 1205,
      "provinceCode": 12,
      "subDistrict": [
        {
          "id": 120505,
          "name": "ขุนศรี",
          "postCode": [
            "11150"
          ]
        },
        {
          "id": 120506,
          "name": "คลองขวาง",
          "postCode": [
            "11150"
          ]
        },
        {
          "id": 120507,
          "name": "ทวีวัฒนา",
          "postCode": [
            "11150"
          ]
        },
        {
          "id": 120502,
          "name": "ราษฎร์นิยม",
          "postCode": [
            "11150"
          ]
        },
        {
          "id": 120503,
          "name": "หนองเพรางาย",
          "postCode": [
            "11150"
          ]
        },
        {
          "id": 120501,
          "name": "ไทรน้อย",
          "postCode": [
            "11150"
          ]
        },
        {
          "id": 120504,
          "name": "ไทรใหญ่",
          "postCode": [
            "11150"
          ]
        }
      ]
    },
    {
      "city": "จะแนะ",
      "cityCode": 9612,
      "provinceCode": 96,
      "subDistrict": [
        {
          "id": 961201,
          "name": "จะแนะ",
          "postCode": [
            "96220"
          ]
        },
        {
          "id": 961204,
          "name": "ช้างเผือก",
          "postCode": [
            "96220"
          ]
        },
        {
          "id": 961202,
          "name": "ดุซงญอ",
          "postCode": [
            "96220"
          ]
        },
        {
          "id": 961203,
          "name": "ผดุงมาตร",
          "postCode": [
            "96220"
          ]
        }
      ]
    },
    {
      "city": "ตากใบ",
      "cityCode": 9602,
      "provinceCode": 96,
      "subDistrict": [
        {
          "id": 960207,
          "name": "นานาค",
          "postCode": [
            "96110"
          ]
        },
        {
          "id": 960205,
          "name": "บางขุนทอง",
          "postCode": [
            "96110"
          ]
        },
        {
          "id": 960203,
          "name": "พร่อน",
          "postCode": [
            "96110"
          ]
        },
        {
          "id": 960204,
          "name": "ศาลาใหม่",
          "postCode": [
            "96110"
          ]
        },
        {
          "id": 960206,
          "name": "เกาะสะท้อน",
          "postCode": [
            "96110"
          ]
        },
        {
          "id": 960201,
          "name": "เจ๊ะเห",
          "postCode": [
            "96110"
          ]
        },
        {
          "id": 960208,
          "name": "โฆษิต",
          "postCode": [
            "96110"
          ]
        },
        {
          "id": 960202,
          "name": "ไพรวัน",
          "postCode": [
            "96110"
          ]
        }
      ]
    },
    {
      "city": "บาเจาะ",
      "cityCode": 9603,
      "provinceCode": 96,
      "subDistrict": [
        {
          "id": 960303,
          "name": "กาเยาะมาตี",
          "postCode": [
            "96170"
          ]
        },
        {
          "id": 960301,
          "name": "บาเจาะ",
          "postCode": [
            "96170"
          ]
        },
        {
          "id": 960305,
          "name": "บาเระเหนือ",
          "postCode": [
            "96170"
          ]
        },
        {
          "id": 960306,
          "name": "บาเระใต้",
          "postCode": [
            "96170"
          ]
        },
        {
          "id": 960304,
          "name": "ปะลุกาสาเมาะ",
          "postCode": [
            "96170"
          ]
        },
        {
          "id": 960302,
          "name": "ลุโบะสาวอ",
          "postCode": [
            "96170"
          ]
        }
      ]
    },
    {
      "city": "ยี่งอ",
      "cityCode": 9604,
      "provinceCode": 96,
      "subDistrict": [
        {
          "id": 960403,
          "name": "จอเบาะ",
          "postCode": [
            "96180"
          ]
        },
        {
          "id": 960406,
          "name": "ตะปอเยาะ",
          "postCode": [
            "96180"
          ]
        },
        {
          "id": 960401,
          "name": "ยี่งอ",
          "postCode": [
            "96180"
          ]
        },
        {
          "id": 960402,
          "name": "ละหาร",
          "postCode": [
            "96180"
          ]
        },
        {
          "id": 960404,
          "name": "ลุโบะบายะ",
          "postCode": [
            "96180"
          ]
        },
        {
          "id": 960405,
          "name": "ลุโบะบือซา",
          "postCode": [
            "96180"
          ]
        }
      ]
    },
    {
      "city": "ระแงะ",
      "cityCode": 9605,
      "provinceCode": 96,
      "subDistrict": [
        {
          "id": 960507,
          "name": "กาลิซา",
          "postCode": [
            "96130"
          ]
        },
        {
          "id": 960501,
          "name": "ตันหยงมัส",
          "postCode": [
            "96130"
          ]
        },
        {
          "id": 960502,
          "name": "ตันหยงลิมอ",
          "postCode": [
            "96130"
          ]
        },
        {
          "id": 960506,
          "name": "บองอ",
          "postCode": [
            "96220"
          ]
        },
        {
          "id": 960508,
          "name": "บาโงสะโต",
          "postCode": [
            "96130"
          ]
        },
        {
          "id": 960510,
          "name": "มะรือโบตก",
          "postCode": [
            "96130"
          ]
        },
        {
          "id": 960509,
          "name": "เฉลิม",
          "postCode": [
            "96130"
          ]
        }
      ]
    },
    {
      "city": "รือเสาะ",
      "cityCode": 9606,
      "provinceCode": 96,
      "subDistrict": [
        {
          "id": 960605,
          "name": "บาตง",
          "postCode": [
            "96150"
          ]
        },
        {
          "id": 960601,
          "name": "รือเสาะ",
          "postCode": [
            "96150"
          ]
        },
        {
          "id": 960607,
          "name": "รือเสาะออก",
          "postCode": [
            "96150"
          ]
        },
        {
          "id": 960606,
          "name": "ลาโละ",
          "postCode": [
            "96150"
          ]
        },
        {
          "id": 960604,
          "name": "สามัคคี",
          "postCode": [
            "96150"
          ]
        },
        {
          "id": 960602,
          "name": "สาวอ",
          "postCode": [
            "96150"
          ]
        },
        {
          "id": 960609,
          "name": "สุวารี",
          "postCode": [
            "96150"
          ]
        },
        {
          "id": 960603,
          "name": "เรียง",
          "postCode": [
            "96150"
          ]
        },
        {
          "id": 960608,
          "name": "โคกสะตอ",
          "postCode": [
            "96150"
          ]
        }
      ]
    },
    {
      "city": "ศรีสาคร",
      "cityCode": 9607,
      "provinceCode": 96,
      "subDistrict": [
        {
          "id": 960705,
          "name": "กาหลง",
          "postCode": [
            "96210"
          ]
        },
        {
          "id": 960701,
          "name": "ซากอ",
          "postCode": [
            "96210"
          ]
        },
        {
          "id": 960702,
          "name": "ตะมะยูง",
          "postCode": [
            "96210"
          ]
        },
        {
          "id": 960706,
          "name": "ศรีบรรพต",
          "postCode": [
            "96210"
          ]
        },
        {
          "id": 960703,
          "name": "ศรีสาคร",
          "postCode": [
            "96210"
          ]
        },
        {
          "id": 960704,
          "name": "เชิงคีรี",
          "postCode": [
            "96210"
          ]
        }
      ]
    },
    {
      "city": "สุคิริน",
      "cityCode": 9609,
      "provinceCode": 96,
      "subDistrict": [
        {
          "id": 960904,
          "name": "ภูเขาทอง",
          "postCode": [
            "96190"
          ]
        },
        {
          "id": 960901,
          "name": "มาโมง",
          "postCode": [
            "96190"
          ]
        },
        {
          "id": 960905,
          "name": "ร่มไทร",
          "postCode": [
            "96190"
          ]
        },
        {
          "id": 960902,
          "name": "สุคิริน",
          "postCode": [
            "96190"
          ]
        },
        {
          "id": 960903,
          "name": "เกียร์",
          "postCode": [
            "96190"
          ]
        }
      ]
    },
    {
      "city": "สุไหงปาดี",
      "cityCode": 9611,
      "provinceCode": 96,
      "subDistrict": [
        {
          "id": 961106,
          "name": "กาวะ",
          "postCode": [
            "96140"
          ]
        },
        {
          "id": 961101,
          "name": "ปะลุรู",
          "postCode": [
            "96140"
          ]
        },
        {
          "id": 961105,
          "name": "ริโก๋",
          "postCode": [
            "96140"
          ]
        },
        {
          "id": 961104,
          "name": "สากอ",
          "postCode": [
            "96140"
          ]
        },
        {
          "id": 961102,
          "name": "สุไหงปาดี",
          "postCode": [
            "96140"
          ]
        },
        {
          "id": 961103,
          "name": "โต๊ะเด็ง",
          "postCode": [
            "96140"
          ]
        }
      ]
    },
    {
      "city": "สุไหงโก-ลก",
      "cityCode": 9610,
      "provinceCode": 96,
      "subDistrict": [
        {
          "id": 961002,
          "name": "ปาเสมัส",
          "postCode": [
            "96120"
          ]
        },
        {
          "id": 961004,
          "name": "ปูโยะ",
          "postCode": [
            "96120"
          ]
        },
        {
          "id": 961003,
          "name": "มูโนะ",
          "postCode": [
            "96120"
          ]
        },
        {
          "id": 961001,
          "name": "สุไหงโก-ลก",
          "postCode": [
            "96120"
          ]
        }
      ]
    },
    {
      "city": "เจาะไอร้อง",
      "cityCode": 9613,
      "provinceCode": 96,
      "subDistrict": [
        {
          "id": 961301,
          "name": "จวบ",
          "postCode": [
            "96130"
          ]
        },
        {
          "id": 961302,
          "name": "บูกิต",
          "postCode": [
            "96130"
          ]
        },
        {
          "id": 961303,
          "name": "มะรือโบออก",
          "postCode": [
            "96130"
          ]
        }
      ]
    },
    {
      "city": "เมืองนราธิวาส",
      "cityCode": 9601,
      "provinceCode": 96,
      "subDistrict": [
        {
          "id": 960105,
          "name": "กะลุวอ",
          "postCode": [
            "96000"
          ]
        },
        {
          "id": 960106,
          "name": "กะลุวอเหนือ",
          "postCode": [
            "96000"
          ]
        },
        {
          "id": 960101,
          "name": "บางนาค",
          "postCode": [
            "96000"
          ]
        },
        {
          "id": 960104,
          "name": "บางปอ",
          "postCode": [
            "96000"
          ]
        },
        {
          "id": 960103,
          "name": "มะนังตายอ",
          "postCode": [
            "96000"
          ]
        },
        {
          "id": 960102,
          "name": "ลำภู",
          "postCode": [
            "96000"
          ]
        },
        {
          "id": 960107,
          "name": "โคกเคียน",
          "postCode": [
            "96000"
          ]
        }
      ]
    },
    {
      "city": "แว้ง",
      "cityCode": 9608,
      "provinceCode": 96,
      "subDistrict": [
        {
          "id": 960802,
          "name": "กายูคละ",
          "postCode": [
            "96160"
          ]
        },
        {
          "id": 960803,
          "name": "ฆอเลาะ",
          "postCode": [
            "96160"
          ]
        },
        {
          "id": 960806,
          "name": "เอราวัณ",
          "postCode": [
            "96160"
          ]
        },
        {
          "id": 960805,
          "name": "แม่ดง",
          "postCode": [
            "96160"
          ]
        },
        {
          "id": 960801,
          "name": "แว้ง",
          "postCode": [
            "96160"
          ]
        },
        {
          "id": 960804,
          "name": "โละจูด",
          "postCode": [
            "96160"
          ]
        }
      ]
    },
    {
      "city": "ทุ่งช้าง",
      "cityCode": 5508,
      "provinceCode": 55,
      "subDistrict": [
        {
          "id": 550802,
          "name": "งอบ",
          "postCode": [
            "55130"
          ]
        },
        {
          "id": 550804,
          "name": "ทุ่งช้าง",
          "postCode": [
            "55130"
          ]
        },
        {
          "id": 550801,
          "name": "ปอน",
          "postCode": [
            "55130"
          ]
        },
        {
          "id": 550803,
          "name": "และ",
          "postCode": [
            "55130"
          ]
        }
      ]
    },
    {
      "city": "ท่าวังผา",
      "cityCode": 5506,
      "provinceCode": 55,
      "subDistrict": [
        {
          "id": 550607,
          "name": "จอมพระ",
          "postCode": [
            "55140"
          ]
        },
        {
          "id": 550605,
          "name": "ตาลชุม",
          "postCode": [
            "55140"
          ]
        },
        {
          "id": 550609,
          "name": "ท่าวังผา",
          "postCode": [
            "55140"
          ]
        },
        {
          "id": 550602,
          "name": "ป่าคา",
          "postCode": [
            "55140"
          ]
        },
        {
          "id": 550603,
          "name": "ผาตอ",
          "postCode": [
            "55140"
          ]
        },
        {
          "id": 550610,
          "name": "ผาทอง",
          "postCode": [
            "55140"
          ]
        },
        {
          "id": 550604,
          "name": "ยม",
          "postCode": [
            "55140"
          ]
        },
        {
          "id": 550601,
          "name": "ริม",
          "postCode": [
            "55140"
          ]
        },
        {
          "id": 550606,
          "name": "ศรีภูมิ",
          "postCode": [
            "55140"
          ]
        },
        {
          "id": 550608,
          "name": "แสนทอง",
          "postCode": [
            "55140"
          ]
        }
      ]
    },
    {
      "city": "นาน้อย",
      "cityCode": 5504,
      "provinceCode": 55,
      "subDistrict": [
        {
          "id": 550401,
          "name": "นาน้อย",
          "postCode": [
            "55150"
          ]
        },
        {
          "id": 550407,
          "name": "น้ำตก",
          "postCode": [
            "55150"
          ]
        },
        {
          "id": 550406,
          "name": "บัวใหญ่",
          "postCode": [
            "55150"
          ]
        },
        {
          "id": 550403,
          "name": "ศรีษะเกษ",
          "postCode": [
            "55150"
          ]
        },
        {
          "id": 550404,
          "name": "สถาน",
          "postCode": [
            "55150"
          ]
        },
        {
          "id": 550405,
          "name": "สันทะ",
          "postCode": [
            "55150"
          ]
        },
        {
          "id": 550402,
          "name": "เชียงของ",
          "postCode": [
            "55150"
          ]
        }
      ]
    },
    {
      "city": "นาหมื่น",
      "cityCode": 5510,
      "provinceCode": 55,
      "subDistrict": [
        {
          "id": 551001,
          "name": "นาทะนุง",
          "postCode": [
            "55180"
          ]
        },
        {
          "id": 551002,
          "name": "บ่อแก้ว",
          "postCode": [
            "55180"
          ]
        },
        {
          "id": 551004,
          "name": "ปิงหลวง",
          "postCode": [
            "55180"
          ]
        },
        {
          "id": 551003,
          "name": "เมืองลี",
          "postCode": [
            "55180"
          ]
        }
      ]
    },
    {
      "city": "บ่อเกลือ",
      "cityCode": 5512,
      "provinceCode": 55,
      "subDistrict": [
        {
          "id": 551205,
          "name": "ดงพญา",
          "postCode": [
            "55220"
          ]
        },
        {
          "id": 551201,
          "name": "บ่อเกลือเหนือ",
          "postCode": [
            "55220"
          ]
        },
        {
          "id": 551202,
          "name": "บ่อเกลือใต้",
          "postCode": [
            "55220"
          ]
        },
        {
          "id": 551204,
          "name": "ภูฟ้า",
          "postCode": [
            "55220"
          ]
        }
      ]
    },
    {
      "city": "บ้านหลวง",
      "cityCode": 5503,
      "provinceCode": 55,
      "subDistrict": [
        {
          "id": 550304,
          "name": "บ้านพี้",
          "postCode": [
            "55190"
          ]
        },
        {
          "id": 550301,
          "name": "บ้านฟ้า",
          "postCode": [
            "55190"
          ]
        },
        {
          "id": 550302,
          "name": "ป่าคาหลวง",
          "postCode": [
            "55190"
          ]
        },
        {
          "id": 550303,
          "name": "สวด",
          "postCode": [
            "55190"
          ]
        }
      ]
    },
    {
      "city": "ปัว",
      "cityCode": 5505,
      "provinceCode": 55,
      "subDistrict": [
        {
          "id": 550501,
          "name": "ปัว",
          "postCode": [
            "55120"
          ]
        },
        {
          "id": 550513,
          "name": "ป่ากลาง",
          "postCode": [
            "55120"
          ]
        },
        {
          "id": 550511,
          "name": "ภูคา",
          "postCode": [
            "55120"
          ]
        },
        {
          "id": 550514,
          "name": "วรนคร",
          "postCode": [
            "55120"
          ]
        },
        {
          "id": 550505,
          "name": "ศิลาเพชร",
          "postCode": [
            "55120"
          ]
        },
        {
          "id": 550504,
          "name": "ศิลาแลง",
          "postCode": [
            "55120"
          ]
        },
        {
          "id": 550512,
          "name": "สกาด",
          "postCode": [
            "55120"
          ]
        },
        {
          "id": 550503,
          "name": "สถาน",
          "postCode": [
            "55120"
          ]
        },
        {
          "id": 550506,
          "name": "อวน",
          "postCode": [
            "55120"
          ]
        },
        {
          "id": 550510,
          "name": "เจดีย์ชัย",
          "postCode": [
            "55120"
          ]
        },
        {
          "id": 550502,
          "name": "แงง",
          "postCode": [
            "55120"
          ]
        },
        {
          "id": 550509,
          "name": "ไชยวัฒนา",
          "postCode": [
            "55120"
          ]
        }
      ]
    },
    {
      "city": "ภูเพียง",
      "cityCode": 5514,
      "provinceCode": 55,
      "subDistrict": [
        {
          "id": 551406,
          "name": "ท่าน้าว",
          "postCode": [
            "55000"
          ]
        },
        {
          "id": 551402,
          "name": "นาปัง",
          "postCode": [
            "55000"
          ]
        },
        {
          "id": 551404,
          "name": "น้ำเกี๋ยน",
          "postCode": [
            "55000"
          ]
        },
        {
          "id": 551403,
          "name": "น้ำแก่น",
          "postCode": [
            "55000"
          ]
        },
        {
          "id": 551407,
          "name": "ฝายแก้ว",
          "postCode": [
            "55000"
          ]
        },
        {
          "id": 551401,
          "name": "ม่วงตึ๊ด",
          "postCode": [
            "55000"
          ]
        },
        {
          "id": 551405,
          "name": "เมืองจัง",
          "postCode": [
            "55000"
          ]
        }
      ]
    },
    {
      "city": "สองแคว",
      "cityCode": 5513,
      "provinceCode": 55,
      "subDistrict": [
        {
          "id": 551302,
          "name": "ชนแดน",
          "postCode": [
            "55160"
          ]
        },
        {
          "id": 551301,
          "name": "นาไร่หลวง",
          "postCode": [
            "55160"
          ]
        },
        {
          "id": 551303,
          "name": "ยอด",
          "postCode": [
            "55160"
          ]
        }
      ]
    },
    {
      "city": "สันติสุข",
      "cityCode": 5511,
      "provinceCode": 55,
      "subDistrict": [
        {
          "id": 551101,
          "name": "ดู่พงษ์",
          "postCode": [
            "55210"
          ]
        },
        {
          "id": 551102,
          "name": "ป่าแลวหลวง",
          "postCode": [
            "55210"
          ]
        },
        {
          "id": 551103,
          "name": "พงษ์",
          "postCode": [
            "55210"
          ]
        }
      ]
    },
    {
      "city": "เฉลิมพระเกียรติ",
      "cityCode": 5515,
      "provinceCode": 55,
      "subDistrict": [
        {
          "id": 551502,
          "name": "ขุนน่าน",
          "postCode": [
            "55220"
          ]
        },
        {
          "id": 551501,
          "name": "ห้วยโก๋น",
          "postCode": [
            "55130"
          ]
        }
      ]
    },
    {
      "city": "เชียงกลาง",
      "cityCode": 5509,
      "provinceCode": 55,
      "subDistrict": [
        {
          "id": 550908,
          "name": "พญาแก้ว",
          "postCode": [
            "55160"
          ]
        },
        {
          "id": 550904,
          "name": "พระธาตุ",
          "postCode": [
            "55160"
          ]
        },
        {
          "id": 550909,
          "name": "พระพุทธบาท",
          "postCode": [
            "55160"
          ]
        },
        {
          "id": 550901,
          "name": "เชียงกลาง",
          "postCode": [
            "55160"
          ]
        },
        {
          "id": 550903,
          "name": "เชียงคาน",
          "postCode": [
            "55160"
          ]
        },
        {
          "id": 550902,
          "name": "เปือ",
          "postCode": [
            "55160"
          ]
        }
      ]
    },
    {
      "city": "เมืองน่าน",
      "cityCode": 5501,
      "provinceCode": 55,
      "subDistrict": [
        {
          "id": 550109,
          "name": "กองควาย",
          "postCode": [
            "55000"
          ]
        },
        {
          "id": 550108,
          "name": "ดู่ใต้",
          "postCode": [
            "55000"
          ]
        },
        {
          "id": 550105,
          "name": "ถืมตอง",
          "postCode": [
            "55000"
          ]
        },
        {
          "id": 550107,
          "name": "นาซาว",
          "postCode": [
            "55000"
          ]
        },
        {
          "id": 550102,
          "name": "บ่อ",
          "postCode": [
            "55000"
          ]
        },
        {
          "id": 550116,
          "name": "บ่อสวก",
          "postCode": [
            "55000"
          ]
        },
        {
          "id": 550103,
          "name": "ผาสิงห์",
          "postCode": [
            "55000"
          ]
        },
        {
          "id": 550117,
          "name": "สะเนียน",
          "postCode": [
            "55000"
          ]
        },
        {
          "id": 550106,
          "name": "เรือง",
          "postCode": [
            "55000"
          ]
        },
        {
          "id": 550101,
          "name": "ในเวียง",
          "postCode": [
            "55000"
          ]
        },
        {
          "id": 550104,
          "name": "ไชยสถาน",
          "postCode": [
            "55000"
          ]
        }
      ]
    },
    {
      "city": "เวียงสา",
      "cityCode": 5507,
      "provinceCode": 55,
      "subDistrict": [
        {
          "id": 550701,
          "name": "กลางเวียง",
          "postCode": [
            "55110"
          ]
        },
        {
          "id": 550702,
          "name": "ขึ่ง",
          "postCode": [
            "55110"
          ]
        },
        {
          "id": 550715,
          "name": "จอมจันทร์",
          "postCode": [
            "55110"
          ]
        },
        {
          "id": 550704,
          "name": "ตาลชุม",
          "postCode": [
            "55110"
          ]
        },
        {
          "id": 550717,
          "name": "ทุ่งศรีทอง",
          "postCode": [
            "55110"
          ]
        },
        {
          "id": 550705,
          "name": "นาเหลือง",
          "postCode": [
            "55110"
          ]
        },
        {
          "id": 550708,
          "name": "น้ำปั้ว",
          "postCode": [
            "55110"
          ]
        },
        {
          "id": 550707,
          "name": "น้ำมวบ",
          "postCode": [
            "55110"
          ]
        },
        {
          "id": 550710,
          "name": "ปงสนุก",
          "postCode": [
            "55110"
          ]
        },
        {
          "id": 550709,
          "name": "ยาบหัวนา",
          "postCode": [
            "55110"
          ]
        },
        {
          "id": 550706,
          "name": "ส้าน",
          "postCode": [
            "55110"
          ]
        },
        {
          "id": 550712,
          "name": "ส้านนาหนองใหม่",
          "postCode": [
            "55110"
          ]
        },
        {
          "id": 550711,
          "name": "อ่ายนาไลย",
          "postCode": [
            "55110"
          ]
        },
        {
          "id": 550713,
          "name": "แม่ขะนิง",
          "postCode": [
            "55110"
          ]
        },
        {
          "id": 550716,
          "name": "แม่สา",
          "postCode": [
            "55110"
          ]
        },
        {
          "id": 550714,
          "name": "แม่สาคร",
          "postCode": [
            "55110"
          ]
        },
        {
          "id": 550703,
          "name": "ไหล่น่าน",
          "postCode": [
            "55110"
          ]
        }
      ]
    },
    {
      "city": "แม่จริม",
      "cityCode": 5502,
      "provinceCode": 55,
      "subDistrict": [
        {
          "id": 550205,
          "name": "น้ำปาย",
          "postCode": [
            "55170"
          ]
        },
        {
          "id": 550204,
          "name": "น้ำพาง",
          "postCode": [
            "55170"
          ]
        },
        {
          "id": 550202,
          "name": "หนองแดง",
          "postCode": [
            "55170"
          ]
        },
        {
          "id": 550203,
          "name": "หมอเมือง",
          "postCode": [
            "55170"
          ]
        },
        {
          "id": 550206,
          "name": "แม่จริม",
          "postCode": [
            "55170"
          ]
        }
      ]
    },
    {
      "city": "บึงโขงหลง",
      "cityCode": 3806,
      "provinceCode": 38,
      "subDistrict": [
        {
          "id": 380603,
          "name": "ดงบัง",
          "postCode": [
            "38220"
          ]
        },
        {
          "id": 380604,
          "name": "ท่าดอกคำ",
          "postCode": [
            "38220"
          ]
        },
        {
          "id": 380601,
          "name": "บึงโขงหลง",
          "postCode": [
            "38220"
          ]
        },
        {
          "id": 380602,
          "name": "โพธิ์หมากแข้ง",
          "postCode": [
            "38220"
          ]
        }
      ]
    },
    {
      "city": "บุ่งคล้า",
      "cityCode": 3808,
      "provinceCode": 38,
      "subDistrict": [
        {
          "id": 380801,
          "name": "บุ่งคล้า",
          "postCode": [
            "38000"
          ]
        },
        {
          "id": 380802,
          "name": "หนองเดิ่น",
          "postCode": [
            "38000"
          ]
        },
        {
          "id": 380803,
          "name": "โคกกว้าง",
          "postCode": [
            "38000"
          ]
        }
      ]
    },
    {
      "city": "ปากคาด",
      "cityCode": 3805,
      "provinceCode": 38,
      "subDistrict": [
        {
          "id": 380503,
          "name": "นากั้ง",
          "postCode": [
            "38190"
          ]
        },
        {
          "id": 380506,
          "name": "นาดง",
          "postCode": [
            "38190"
          ]
        },
        {
          "id": 380501,
          "name": "ปากคาด",
          "postCode": [
            "38190"
          ]
        },
        {
          "id": 380505,
          "name": "สมสนุก",
          "postCode": [
            "38190"
          ]
        },
        {
          "id": 380502,
          "name": "หนองยอง",
          "postCode": [
            "38190"
          ]
        },
        {
          "id": 380504,
          "name": "โนนศิลา",
          "postCode": [
            "38190"
          ]
        }
      ]
    },
    {
      "city": "พรเจริญ",
      "cityCode": 3802,
      "provinceCode": 38,
      "subDistrict": [
        {
          "id": 380202,
          "name": "ดอนหญ้านาง",
          "postCode": [
            "38180"
          ]
        },
        {
          "id": 380206,
          "name": "ป่าแฝก",
          "postCode": [
            "38180"
          ]
        },
        {
          "id": 380203,
          "name": "พรเจริญ",
          "postCode": [
            "38180"
          ]
        },
        {
          "id": 380205,
          "name": "วังชมภู",
          "postCode": [
            "38180"
          ]
        },
        {
          "id": 380201,
          "name": "ศรีชมภู",
          "postCode": [
            "38180"
          ]
        },
        {
          "id": 380207,
          "name": "ศรีสำราญ",
          "postCode": [
            "38180"
          ]
        },
        {
          "id": 380204,
          "name": "หนองหัวช้าง",
          "postCode": [
            "38180"
          ]
        }
      ]
    },
    {
      "city": "ศรีวิไล",
      "cityCode": 3807,
      "provinceCode": 38,
      "subDistrict": [
        {
          "id": 380702,
          "name": "ชุมภูพร",
          "postCode": [
            "38210"
          ]
        },
        {
          "id": 380704,
          "name": "นาสะแบง",
          "postCode": [
            "38210"
          ]
        },
        {
          "id": 380705,
          "name": "นาสิงห์",
          "postCode": [
            "38210"
          ]
        },
        {
          "id": 380703,
          "name": "นาแสง",
          "postCode": [
            "38210"
          ]
        },
        {
          "id": 380701,
          "name": "ศรีวิไล",
          "postCode": [
            "38210"
          ]
        }
      ]
    },
    {
      "city": "เซกา",
      "cityCode": 3804,
      "provinceCode": 38,
      "subDistrict": [
        {
          "id": 380402,
          "name": "ซาง",
          "postCode": [
            "38150"
          ]
        },
        {
          "id": 380403,
          "name": "ท่ากกแดง",
          "postCode": [
            "38150"
          ]
        },
        {
          "id": 380407,
          "name": "ท่าสะอาด",
          "postCode": [
            "38150"
          ]
        },
        {
          "id": 380406,
          "name": "น้ำจั้น",
          "postCode": [
            "38150"
          ]
        },
        {
          "id": 380404,
          "name": "บ้านต้อง",
          "postCode": [
            "38150"
          ]
        },
        {
          "id": 380405,
          "name": "ป่งไฮ",
          "postCode": [
            "38150"
          ]
        },
        {
          "id": 380408,
          "name": "หนองทุ่ม",
          "postCode": [
            "38150"
          ]
        },
        {
          "id": 380401,
          "name": "เซกา",
          "postCode": [
            "38150"
          ]
        },
        {
          "id": 380409,
          "name": "โสกก่าม",
          "postCode": [
            "38150"
          ]
        }
      ]
    },
    {
      "city": "เมืองบึงกาฬ",
      "cityCode": 3801,
      "provinceCode": 38,
      "subDistrict": [
        {
          "id": 380111,
          "name": "คำนาดี",
          "postCode": [
            "38000"
          ]
        },
        {
          "id": 380109,
          "name": "ชัยพร",
          "postCode": [
            "38000"
          ]
        },
        {
          "id": 380107,
          "name": "นาสวรรค์",
          "postCode": [
            "38000"
          ]
        },
        {
          "id": 380101,
          "name": "บึงกาฬ",
          "postCode": [
            "38000"
          ]
        },
        {
          "id": 380110,
          "name": "วิศิษฐ์",
          "postCode": [
            "38000"
          ]
        },
        {
          "id": 380105,
          "name": "หนองเลิง",
          "postCode": [
            "38000"
          ]
        },
        {
          "id": 380104,
          "name": "หอคำ",
          "postCode": [
            "38000"
          ]
        },
        {
          "id": 380106,
          "name": "โคกก่อง",
          "postCode": [
            "38000"
          ]
        },
        {
          "id": 380102,
          "name": "โนนสมบูรณ์",
          "postCode": [
            "38000"
          ]
        },
        {
          "id": 380103,
          "name": "โนนสว่าง",
          "postCode": [
            "38000"
          ]
        },
        {
          "id": 380112,
          "name": "โป่งเปือย",
          "postCode": [
            "38000"
          ]
        },
        {
          "id": 380108,
          "name": "ไคสี",
          "postCode": [
            "38000"
          ]
        }
      ]
    },
    {
      "city": "โซ่พิสัย",
      "cityCode": 3803,
      "provinceCode": 38,
      "subDistrict": [
        {
          "id": 380304,
          "name": "คำแก้ว",
          "postCode": [
            "38170"
          ]
        },
        {
          "id": 380306,
          "name": "ถ้ำเจริญ",
          "postCode": [
            "38170"
          ]
        },
        {
          "id": 380305,
          "name": "บัวตูม",
          "postCode": [
            "38170"
          ]
        },
        {
          "id": 380303,
          "name": "ศรีชมภู",
          "postCode": [
            "38170"
          ]
        },
        {
          "id": 380302,
          "name": "หนองพันทา",
          "postCode": [
            "38170"
          ]
        },
        {
          "id": 380307,
          "name": "เหล่าทอง",
          "postCode": [
            "38170"
          ]
        },
        {
          "id": 380301,
          "name": "โซ่",
          "postCode": [
            "38170"
          ]
        }
      ]
    },
    {
      "city": "กระสัง",
      "cityCode": 3103,
      "provinceCode": 31,
      "subDistrict": [
        {
          "id": 310301,
          "name": "กระสัง",
          "postCode": [
            "31160"
          ]
        },
        {
          "id": 310310,
          "name": "กันทรารมย์",
          "postCode": [
            "31160"
          ]
        },
        {
          "id": 310307,
          "name": "ชุมแสง",
          "postCode": [
            "31160"
          ]
        },
        {
          "id": 310308,
          "name": "บ้านปรือ",
          "postCode": [
            "31160"
          ]
        },
        {
          "id": 310302,
          "name": "ลำดวน",
          "postCode": [
            "31160"
          ]
        },
        {
          "id": 310311,
          "name": "ศรีภูมิ",
          "postCode": [
            "31160"
          ]
        },
        {
          "id": 310303,
          "name": "สองชั้น",
          "postCode": [
            "31160"
          ]
        },
        {
          "id": 310304,
          "name": "สูงเนิน",
          "postCode": [
            "31160"
          ]
        },
        {
          "id": 310305,
          "name": "หนองเต็ง",
          "postCode": [
            "31160"
          ]
        },
        {
          "id": 310309,
          "name": "ห้วยสำราญ",
          "postCode": [
            "31160"
          ]
        },
        {
          "id": 310306,
          "name": "เมืองไผ่",
          "postCode": [
            "31160"
          ]
        }
      ]
    },
    {
      "city": "คูเมือง",
      "cityCode": 3102,
      "provinceCode": 31,
      "subDistrict": [
        {
          "id": 310201,
          "name": "คูเมือง",
          "postCode": [
            "31190"
          ]
        },
        {
          "id": 310206,
          "name": "ตูมใหญ่",
          "postCode": [
            "31190"
          ]
        },
        {
          "id": 310203,
          "name": "บ้านแพ",
          "postCode": [
            "31190"
          ]
        },
        {
          "id": 310202,
          "name": "ปะเคียบ",
          "postCode": [
            "31190"
          ]
        },
        {
          "id": 310204,
          "name": "พรสำราญ",
          "postCode": [
            "31190"
          ]
        },
        {
          "id": 310207,
          "name": "หนองขมาร",
          "postCode": [
            "31190"
          ]
        },
        {
          "id": 310205,
          "name": "หินเหล็กไฟ",
          "postCode": [
            "31190"
          ]
        }
      ]
    },
    {
      "city": "ชำนิ",
      "cityCode": 3118,
      "provinceCode": 31,
      "subDistrict": [
        {
          "id": 311801,
          "name": "ชำนิ",
          "postCode": [
            "31110"
          ]
        },
        {
          "id": 311804,
          "name": "ช่อผกา",
          "postCode": [
            "31110"
          ]
        },
        {
          "id": 311805,
          "name": "ละลวด",
          "postCode": [
            "31110"
          ]
        },
        {
          "id": 311802,
          "name": "หนองปล่อง",
          "postCode": [
            "31110"
          ]
        },
        {
          "id": 311803,
          "name": "เมืองยาง",
          "postCode": [
            "31110"
          ]
        },
        {
          "id": 311806,
          "name": "โคกสนวน",
          "postCode": [
            "31110"
          ]
        }
      ]
    },
    {
      "city": "นางรอง",
      "cityCode": 3104,
      "provinceCode": 31,
      "subDistrict": [
        {
          "id": 310415,
          "name": "ก้านเหลือง",
          "postCode": [
            "31110"
          ]
        },
        {
          "id": 310405,
          "name": "ชุมแสง",
          "postCode": [
            "31110"
          ]
        },
        {
          "id": 310413,
          "name": "ถนนหัก",
          "postCode": [
            "31110"
          ]
        },
        {
          "id": 310418,
          "name": "ทรัพย์พระยา",
          "postCode": [
            "31110"
          ]
        },
        {
          "id": 310426,
          "name": "ทุ่งแสงทอง",
          "postCode": [
            "31110"
          ]
        },
        {
          "id": 310401,
          "name": "นางรอง",
          "postCode": [
            "31110"
          ]
        },
        {
          "id": 310416,
          "name": "บ้านสิงห์",
          "postCode": [
            "31110"
          ]
        },
        {
          "id": 310417,
          "name": "ลำไทรโยง",
          "postCode": [
            "31110"
          ]
        },
        {
          "id": 310403,
          "name": "สะเดา",
          "postCode": [
            "31110"
          ]
        },
        {
          "id": 310408,
          "name": "หนองกง",
          "postCode": [
            "31110"
          ]
        },
        {
          "id": 310424,
          "name": "หนองยายพิมพ์",
          "postCode": [
            "31110"
          ]
        },
        {
          "id": 310406,
          "name": "หนองโบสถ์",
          "postCode": [
            "31110"
          ]
        },
        {
          "id": 310427,
          "name": "หนองโสน",
          "postCode": [
            "31110"
          ]
        },
        {
          "id": 310414,
          "name": "หนองไทร",
          "postCode": [
            "31110"
          ]
        },
        {
          "id": 310425,
          "name": "หัวถนน",
          "postCode": [
            "31110"
          ]
        }
      ]
    },
    {
      "city": "นาโพธิ์",
      "cityCode": 3113,
      "provinceCode": 31,
      "subDistrict": [
        {
          "id": 311304,
          "name": "ดอนกอก",
          "postCode": [
            "31230"
          ]
        },
        {
          "id": 311301,
          "name": "นาโพธิ์",
          "postCode": [
            "31230"
          ]
        },
        {
          "id": 311302,
          "name": "บ้านคู",
          "postCode": [
            "31230"
          ]
        },
        {
          "id": 311303,
          "name": "บ้านดู่",
          "postCode": [
            "31230"
          ]
        },
        {
          "id": 311305,
          "name": "ศรีสว่าง",
          "postCode": [
            "31230"
          ]
        }
      ]
    },
    {
      "city": "บ้านกรวด",
      "cityCode": 3108,
      "provinceCode": 31,
      "subDistrict": [
        {
          "id": 310808,
          "name": "จันทบเพชร",
          "postCode": [
            "31180"
          ]
        },
        {
          "id": 310807,
          "name": "บึงเจริญ",
          "postCode": [
            "31180"
          ]
        },
        {
          "id": 310801,
          "name": "บ้านกรวด",
          "postCode": [
            "31180"
          ]
        },
        {
          "id": 310804,
          "name": "ปราสาท",
          "postCode": [
            "31180"
          ]
        },
        {
          "id": 310805,
          "name": "สายตะกู",
          "postCode": [
            "31180"
          ]
        },
        {
          "id": 310803,
          "name": "หนองไม้งาม",
          "postCode": [
            "31180"
          ]
        },
        {
          "id": 310806,
          "name": "หินลาด",
          "postCode": [
            "31180"
          ]
        },
        {
          "id": 310809,
          "name": "เขาดินเหนือ",
          "postCode": [
            "31180"
          ]
        },
        {
          "id": 310802,
          "name": "โนนเจริญ",
          "postCode": [
            "31180"
          ]
        }
      ]
    },
    {
      "city": "บ้านด่าน",
      "cityCode": 3121,
      "provinceCode": 31,
      "subDistrict": [
        {
          "id": 312101,
          "name": "บ้านด่าน",
          "postCode": [
            "31000"
          ]
        },
        {
          "id": 312102,
          "name": "ปราสาท",
          "postCode": [
            "31000"
          ]
        },
        {
          "id": 312103,
          "name": "วังเหนือ",
          "postCode": [
            "31000"
          ]
        },
        {
          "id": 312104,
          "name": "โนนขวาง",
          "postCode": [
            "31000"
          ]
        }
      ]
    },
    {
      "city": "บ้านใหม่ไชยพจน์",
      "cityCode": 3119,
      "provinceCode": 31,
      "subDistrict": [
        {
          "id": 311904,
          "name": "กู่สวนแตง",
          "postCode": [
            "31120"
          ]
        },
        {
          "id": 311902,
          "name": "ทองหลาง",
          "postCode": [
            "31120"
          ]
        },
        {
          "id": 311905,
          "name": "หนองเยือง",
          "postCode": [
            "31120"
          ]
        },
        {
          "id": 311901,
          "name": "หนองแวง",
          "postCode": [
            "31120"
          ]
        },
        {
          "id": 311903,
          "name": "แดงใหญ่",
          "postCode": [
            "31120"
          ]
        }
      ]
    },
    {
      "city": "ประโคนชัย",
      "cityCode": 3107,
      "provinceCode": 31,
      "subDistrict": [
        {
          "id": 310706,
          "name": "จรเข้มาก",
          "postCode": [
            "31140"
          ]
        },
        {
          "id": 310714,
          "name": "ตะโกตาพิ",
          "postCode": [
            "31140"
          ]
        },
        {
          "id": 310703,
          "name": "บ้านไทร",
          "postCode": [
            "31140"
          ]
        },
        {
          "id": 310720,
          "name": "ประทัดบุ",
          "postCode": [
            "31140"
          ]
        },
        {
          "id": 310701,
          "name": "ประโคนชัย",
          "postCode": [
            "31140"
          ]
        },
        {
          "id": 310707,
          "name": "ปังกู",
          "postCode": [
            "31140"
          ]
        },
        {
          "id": 310705,
          "name": "ละเวี้ย",
          "postCode": [
            "31140"
          ]
        },
        {
          "id": 310721,
          "name": "สี่เหลี่ยม",
          "postCode": [
            "31140"
          ]
        },
        {
          "id": 310716,
          "name": "หนองบอน",
          "postCode": [
            "31140"
          ]
        },
        {
          "id": 310715,
          "name": "เขาคอก",
          "postCode": [
            "31140"
          ]
        },
        {
          "id": 310702,
          "name": "แสลงโทน",
          "postCode": [
            "31140"
          ]
        },
        {
          "id": 310719,
          "name": "โคกตูม",
          "postCode": [
            "31140"
          ]
        },
        {
          "id": 310718,
          "name": "โคกมะขาม",
          "postCode": [
            "31140"
          ]
        },
        {
          "id": 310710,
          "name": "โคกม้า",
          "postCode": [
            "31140"
          ]
        },
        {
          "id": 310708,
          "name": "โคกย่าง",
          "postCode": [
            "31140"
          ]
        },
        {
          "id": 310713,
          "name": "ไพศาล",
          "postCode": [
            "31140"
          ]
        }
      ]
    },
    {
      "city": "ปะคำ",
      "cityCode": 3112,
      "provinceCode": 31,
      "subDistrict": [
        {
          "id": 311201,
          "name": "ปะคำ",
          "postCode": [
            "31220"
          ]
        },
        {
          "id": 311203,
          "name": "หนองบัว",
          "postCode": [
            "31220"
          ]
        },
        {
          "id": 311205,
          "name": "หูทำนบ",
          "postCode": [
            "31220"
          ]
        },
        {
          "id": 311204,
          "name": "โคกมะม่วง",
          "postCode": [
            "31220"
          ]
        },
        {
          "id": 311202,
          "name": "ไทยเจริญ",
          "postCode": [
            "31220"
          ]
        }
      ]
    },
    {
      "city": "พลับพลาชัย",
      "cityCode": 3115,
      "provinceCode": 31,
      "subDistrict": [
        {
          "id": 311501,
          "name": "จันดุม",
          "postCode": [
            "31250"
          ]
        },
        {
          "id": 311503,
          "name": "ป่าชัน",
          "postCode": [
            "31250"
          ]
        },
        {
          "id": 311504,
          "name": "สะเดา",
          "postCode": [
            "31250"
          ]
        },
        {
          "id": 311505,
          "name": "สำโรง",
          "postCode": [
            "31250"
          ]
        },
        {
          "id": 311502,
          "name": "โคกขมิ้น",
          "postCode": [
            "31250"
          ]
        }
      ]
    },
    {
      "city": "พุทไธสง",
      "cityCode": 3109,
      "provinceCode": 31,
      "subDistrict": [
        {
          "id": 310903,
          "name": "บ้านจาน",
          "postCode": [
            "31120"
          ]
        },
        {
          "id": 310909,
          "name": "บ้านยาง",
          "postCode": [
            "31120"
          ]
        },
        {
          "id": 310906,
          "name": "บ้านเป้า",
          "postCode": [
            "31120"
          ]
        },
        {
          "id": 310907,
          "name": "บ้านแวง",
          "postCode": [
            "31120"
          ]
        },
        {
          "id": 310901,
          "name": "พุทไธสง",
          "postCode": [
            "31120"
          ]
        },
        {
          "id": 310902,
          "name": "มะเฟือง",
          "postCode": [
            "31120"
          ]
        },
        {
          "id": 310910,
          "name": "หายโศก",
          "postCode": [
            "31120"
          ]
        }
      ]
    },
    {
      "city": "ละหานทราย",
      "cityCode": 3106,
      "provinceCode": 31,
      "subDistrict": [
        {
          "id": 310603,
          "name": "ตาจง",
          "postCode": [
            "31170"
          ]
        },
        {
          "id": 310601,
          "name": "ละหานทราย",
          "postCode": [
            "31170"
          ]
        },
        {
          "id": 310604,
          "name": "สำโรงใหม่",
          "postCode": [
            "31170"
          ]
        },
        {
          "id": 310610,
          "name": "หนองตะครอง",
          "postCode": [
            "31170"
          ]
        },
        {
          "id": 310607,
          "name": "หนองแวง",
          "postCode": [
            "31170"
          ]
        },
        {
          "id": 310611,
          "name": "โคกว่าน",
          "postCode": [
            "31170"
          ]
        }
      ]
    },
    {
      "city": "ลำปลายมาศ",
      "cityCode": 3110,
      "provinceCode": 31,
      "subDistrict": [
        {
          "id": 311005,
          "name": "ตลาดโพธิ์",
          "postCode": [
            "31130"
          ]
        },
        {
          "id": 311004,
          "name": "ทะเมนชัย",
          "postCode": [
            "31130"
          ]
        },
        {
          "id": 311015,
          "name": "บุโพธิ์",
          "postCode": [
            "31130"
          ]
        },
        {
          "id": 311010,
          "name": "บ้านยาง",
          "postCode": [
            "31130"
          ]
        },
        {
          "id": 311011,
          "name": "ผไทรินทร์",
          "postCode": [
            "31130"
          ]
        },
        {
          "id": 311001,
          "name": "ลำปลายมาศ",
          "postCode": [
            "31130"
          ]
        },
        {
          "id": 311006,
          "name": "หนองกะทิง",
          "postCode": [
            "31130"
          ]
        },
        {
          "id": 311002,
          "name": "หนองคู",
          "postCode": [
            "31130"
          ]
        },
        {
          "id": 311014,
          "name": "หนองบัวโคก",
          "postCode": [
            "31130"
          ]
        },
        {
          "id": 311016,
          "name": "หนองโดน",
          "postCode": [
            "31130"
          ]
        },
        {
          "id": 311013,
          "name": "หินโคน",
          "postCode": [
            "31130"
          ]
        },
        {
          "id": 311009,
          "name": "เมืองแฝก",
          "postCode": [
            "31130"
          ]
        },
        {
          "id": 311003,
          "name": "แสลงพัน",
          "postCode": [
            "31130"
          ]
        },
        {
          "id": 311007,
          "name": "โคกกลาง",
          "postCode": [
            "31130"
          ]
        },
        {
          "id": 311012,
          "name": "โคกล่าม",
          "postCode": [
            "31130"
          ]
        },
        {
          "id": 311008,
          "name": "โคกสะอาด",
          "postCode": [
            "31130"
          ]
        }
      ]
    },
    {
      "city": "สตึก",
      "cityCode": 3111,
      "provinceCode": 31,
      "subDistrict": [
        {
          "id": 311115,
          "name": "กระสัง",
          "postCode": [
            "31150"
          ]
        },
        {
          "id": 311110,
          "name": "ชุมแสง",
          "postCode": [
            "31150"
          ]
        },
        {
          "id": 311109,
          "name": "ดอนมนต์",
          "postCode": [
            "31150"
          ]
        },
        {
          "id": 311103,
          "name": "ทุ่งวัง",
          "postCode": [
            "31150"
          ]
        },
        {
          "id": 311111,
          "name": "ท่าม่วง",
          "postCode": [
            "31150"
          ]
        },
        {
          "id": 311102,
          "name": "นิคม",
          "postCode": [
            "31150"
          ]
        },
        {
          "id": 311106,
          "name": "ร่อนทอง",
          "postCode": [
            "31150"
          ]
        },
        {
          "id": 311101,
          "name": "สตึก",
          "postCode": [
            "31150"
          ]
        },
        {
          "id": 311114,
          "name": "สนามชัย",
          "postCode": [
            "31150"
          ]
        },
        {
          "id": 311112,
          "name": "สะแก",
          "postCode": [
            "31150"
          ]
        },
        {
          "id": 311105,
          "name": "หนองใหญ่",
          "postCode": [
            "31150"
          ]
        },
        {
          "id": 311104,
          "name": "เมืองแก",
          "postCode": [
            "31150"
          ]
        }
      ]
    },
    {
      "city": "หนองกี่",
      "cityCode": 3105,
      "provinceCode": 31,
      "subDistrict": [
        {
          "id": 310504,
          "name": "ดอนอะราง",
          "postCode": [
            "31210"
          ]
        },
        {
          "id": 310506,
          "name": "ทุ่งกระตาดพัฒนา",
          "postCode": [
            "31210"
          ]
        },
        {
          "id": 310507,
          "name": "ทุ่งกระเต็น",
          "postCode": [
            "31210"
          ]
        },
        {
          "id": 310508,
          "name": "ท่าโพธิ์ชัย",
          "postCode": [
            "31210"
          ]
        },
        {
          "id": 310510,
          "name": "บุกระสัง",
          "postCode": [
            "31210"
          ]
        },
        {
          "id": 310501,
          "name": "หนองกี่",
          "postCode": [
            "31210"
          ]
        },
        {
          "id": 310503,
          "name": "เมืองไผ่",
          "postCode": [
            "31210"
          ]
        },
        {
          "id": 310502,
          "name": "เย้ยปราสาท",
          "postCode": [
            "31210"
          ]
        },
        {
          "id": 310505,
          "name": "โคกสว่าง",
          "postCode": [
            "31210"
          ]
        },
        {
          "id": 310509,
          "name": "โคกสูง",
          "postCode": [
            "31210"
          ]
        }
      ]
    },
    {
      "city": "หนองหงส์",
      "cityCode": 3114,
      "provinceCode": 31,
      "subDistrict": [
        {
          "id": 311407,
          "name": "สระทอง",
          "postCode": [
            "31240"
          ]
        },
        {
          "id": 311401,
          "name": "สระแก้ว",
          "postCode": [
            "31240"
          ]
        },
        {
          "id": 311404,
          "name": "หนองชัยศรี",
          "postCode": [
            "31240"
          ]
        },
        {
          "id": 311402,
          "name": "ห้วยหิน",
          "postCode": [
            "31240"
          ]
        },
        {
          "id": 311406,
          "name": "เมืองฝ้าย",
          "postCode": [
            "31240"
          ]
        },
        {
          "id": 311405,
          "name": "เสาเดียว",
          "postCode": [
            "31240"
          ]
        },
        {
          "id": 311403,
          "name": "ไทยสามัคคี",
          "postCode": [
            "31240"
          ]
        }
      ]
    },
    {
      "city": "ห้วยราช",
      "cityCode": 3116,
      "provinceCode": 31,
      "subDistrict": [
        {
          "id": 311603,
          "name": "ตาเสา",
          "postCode": [
            "31000"
          ]
        },
        {
          "id": 311604,
          "name": "บ้านตะโก",
          "postCode": [
            "31000"
          ]
        },
        {
          "id": 311605,
          "name": "สนวน",
          "postCode": [
            "31000"
          ]
        },
        {
          "id": 311602,
          "name": "สามแวง",
          "postCode": [
            "31000"
          ]
        },
        {
          "id": 311601,
          "name": "ห้วยราช",
          "postCode": [
            "31000"
          ]
        },
        {
          "id": 311608,
          "name": "ห้วยราชา",
          "postCode": [
            "31000"
          ]
        },
        {
          "id": 311607,
          "name": "เมืองโพธิ์",
          "postCode": [
            "31000"
          ]
        },
        {
          "id": 311606,
          "name": "โคกเหล็ก",
          "postCode": [
            "31000"
          ]
        }
      ]
    },
    {
      "city": "เฉลิมพระเกียรติ",
      "cityCode": 3123,
      "provinceCode": 31,
      "subDistrict": [
        {
          "id": 312302,
          "name": "ตาเป๊ก",
          "postCode": [
            "31110"
          ]
        },
        {
          "id": 312304,
          "name": "ถาวร",
          "postCode": [
            "31170"
          ]
        },
        {
          "id": 312305,
          "name": "ยายแย้มวัฒนา",
          "postCode": [
            "31170"
          ]
        },
        {
          "id": 312303,
          "name": "อีสานเขต",
          "postCode": [
            "31110"
          ]
        },
        {
          "id": 312301,
          "name": "เจริญสุข",
          "postCode": [
            "31110"
          ]
        }
      ]
    },
    {
      "city": "เมืองบุรีรัมย์",
      "cityCode": 3101,
      "provinceCode": 31,
      "subDistrict": [
        {
          "id": 310127,
          "name": "กระสัง",
          "postCode": [
            "31000"
          ]
        },
        {
          "id": 310126,
          "name": "กลันทา",
          "postCode": [
            "31000"
          ]
        },
        {
          "id": 310120,
          "name": "ชุมเห็ด",
          "postCode": [
            "31000"
          ]
        },
        {
          "id": 310113,
          "name": "ถลุงเหล็ก",
          "postCode": [
            "31000"
          ]
        },
        {
          "id": 310119,
          "name": "บัวทอง",
          "postCode": [
            "31000"
          ]
        },
        {
          "id": 310104,
          "name": "บ้านบัว",
          "postCode": [
            "31000"
          ]
        },
        {
          "id": 310108,
          "name": "บ้านยาง",
          "postCode": [
            "31000"
          ]
        },
        {
          "id": 310112,
          "name": "พระครู",
          "postCode": [
            "31000"
          ]
        },
        {
          "id": 310117,
          "name": "ลุมปุ๊ก",
          "postCode": [
            "31000"
          ]
        },
        {
          "id": 310106,
          "name": "สวายจีก",
          "postCode": [
            "31000"
          ]
        },
        {
          "id": 310118,
          "name": "สองห้อง",
          "postCode": [
            "31000"
          ]
        },
        {
          "id": 310125,
          "name": "สะแกซำ",
          "postCode": [
            "31000"
          ]
        },
        {
          "id": 310105,
          "name": "สะแกโพรง",
          "postCode": [
            "31000"
          ]
        },
        {
          "id": 310114,
          "name": "หนองตาด",
          "postCode": [
            "31000"
          ]
        },
        {
          "id": 310122,
          "name": "หลักเขต",
          "postCode": [
            "31000"
          ]
        },
        {
          "id": 310102,
          "name": "อิสาณ",
          "postCode": [
            "31000"
          ]
        },
        {
          "id": 310128,
          "name": "เมืองฝาง",
          "postCode": [
            "31000"
          ]
        },
        {
          "id": 310103,
          "name": "เสม็ด",
          "postCode": [
            "31000"
          ]
        },
        {
          "id": 310101,
          "name": "ในเมือง",
          "postCode": [
            "31000"
          ]
        }
      ]
    },
    {
      "city": "แคนดง",
      "cityCode": 3122,
      "provinceCode": 31,
      "subDistrict": [
        {
          "id": 312202,
          "name": "ดงพลอง",
          "postCode": [
            "31150"
          ]
        },
        {
          "id": 312203,
          "name": "สระบัว",
          "postCode": [
            "31150"
          ]
        },
        {
          "id": 312204,
          "name": "หัวฝาย",
          "postCode": [
            "31150"
          ]
        },
        {
          "id": 312201,
          "name": "แคนดง",
          "postCode": [
            "31150"
          ]
        }
      ]
    },
    {
      "city": "โนนดินแดง",
      "cityCode": 3120,
      "provinceCode": 31,
      "subDistrict": [
        {
          "id": 312003,
          "name": "ลำนางรอง",
          "postCode": [
            "31260"
          ]
        },
        {
          "id": 312002,
          "name": "ส้มป่อย",
          "postCode": [
            "31260"
          ]
        },
        {
          "id": 312001,
          "name": "โนนดินแดง",
          "postCode": [
            "31260"
          ]
        }
      ]
    },
    {
      "city": "โนนสุวรรณ",
      "cityCode": 3117,
      "provinceCode": 31,
      "subDistrict": [
        {
          "id": 311704,
          "name": "ดงอีจาน",
          "postCode": [
            "31110"
          ]
        },
        {
          "id": 311702,
          "name": "ทุ่งจังหัน",
          "postCode": [
            "31110"
          ]
        },
        {
          "id": 311703,
          "name": "โกรกแก้ว",
          "postCode": [
            "31110"
          ]
        },
        {
          "id": 311701,
          "name": "โนนสุวรรณ",
          "postCode": [
            "31110"
          ]
        }
      ]
    },
    {
      "city": "คลองหลวง",
      "cityCode": 1302,
      "provinceCode": 13,
      "subDistrict": [
        {
          "id": 130202,
          "name": "คลองสอง",
          "postCode": [
            "12120"
          ]
        },
        {
          "id": 130203,
          "name": "คลองสาม",
          "postCode": [
            "12120"
          ]
        },
        {
          "id": 130204,
          "name": "คลองสี่",
          "postCode": [
            "12120"
          ]
        },
        {
          "id": 130206,
          "name": "คลองหก",
          "postCode": [
            "12110",
            "12120"
          ]
        },
        {
          "id": 130201,
          "name": "คลองหนึ่ง",
          "postCode": [
            "12120",
            "12121",
            "13180"
          ]
        },
        {
          "id": 130205,
          "name": "คลองห้า",
          "postCode": [
            "12110",
            "12120"
          ]
        },
        {
          "id": 130207,
          "name": "คลองเจ็ด",
          "postCode": [
            "12120"
          ]
        }
      ]
    },
    {
      "city": "ธัญบุรี",
      "cityCode": 1303,
      "provinceCode": 13,
      "subDistrict": [
        {
          "id": 130306,
          "name": "บึงน้ำรักษ์",
          "postCode": [
            "12110"
          ]
        },
        {
          "id": 130302,
          "name": "บึงยี่โถ",
          "postCode": [
            "12130"
          ]
        },
        {
          "id": 130305,
          "name": "บึงสนั่น",
          "postCode": [
            "12110"
          ]
        },
        {
          "id": 130301,
          "name": "ประชาธิปัตย์",
          "postCode": [
            "12130"
          ]
        },
        {
          "id": 130303,
          "name": "รังสิต",
          "postCode": [
            "12110"
          ]
        },
        {
          "id": 130304,
          "name": "ลำผักกูด",
          "postCode": [
            "12110"
          ]
        }
      ]
    },
    {
      "city": "ลาดหลุมแก้ว",
      "cityCode": 1305,
      "provinceCode": 13,
      "subDistrict": [
        {
          "id": 130505,
          "name": "คลองพระอุดม",
          "postCode": [
            "12140"
          ]
        },
        {
          "id": 130504,
          "name": "คูขวาง",
          "postCode": [
            "12140"
          ]
        },
        {
          "id": 130503,
          "name": "คูบางหลวง",
          "postCode": [
            "12140"
          ]
        },
        {
          "id": 130506,
          "name": "บ่อเงิน",
          "postCode": [
            "12140"
          ]
        },
        {
          "id": 130501,
          "name": "ระแหง",
          "postCode": [
            "12140"
          ]
        },
        {
          "id": 130502,
          "name": "ลาดหลุมแก้ว",
          "postCode": [
            "12140"
          ]
        },
        {
          "id": 130507,
          "name": "หน้าไม้",
          "postCode": [
            "12140"
          ]
        }
      ]
    },
    {
      "city": "ลำลูกกา",
      "cityCode": 1306,
      "provinceCode": 13,
      "subDistrict": [
        {
          "id": 130601,
          "name": "คูคต",
          "postCode": [
            "12130"
          ]
        },
        {
          "id": 130607,
          "name": "บึงคอไห",
          "postCode": [
            "12150"
          ]
        },
        {
          "id": 130603,
          "name": "บึงคำพร้อย",
          "postCode": [
            "12150"
          ]
        },
        {
          "id": 130605,
          "name": "บึงทองหลาง",
          "postCode": [
            "12150"
          ]
        },
        {
          "id": 130608,
          "name": "พืชอุดม",
          "postCode": [
            "12150"
          ]
        },
        {
          "id": 130602,
          "name": "ลาดสวาย",
          "postCode": [
            "12150"
          ]
        },
        {
          "id": 130604,
          "name": "ลำลูกกา",
          "postCode": [
            "12150"
          ]
        },
        {
          "id": 130606,
          "name": "ลำไทร",
          "postCode": [
            "12150"
          ]
        }
      ]
    },
    {
      "city": "สามโคก",
      "cityCode": 1307,
      "provinceCode": 13,
      "subDistrict": [
        {
          "id": 130704,
          "name": "กระแชง",
          "postCode": [
            "12160"
          ]
        },
        {
          "id": 130702,
          "name": "คลองควาย",
          "postCode": [
            "12160"
          ]
        },
        {
          "id": 130711,
          "name": "ท้ายเกาะ",
          "postCode": [
            "12160"
          ]
        },
        {
          "id": 130710,
          "name": "บางกระบือ",
          "postCode": [
            "12160"
          ]
        },
        {
          "id": 130701,
          "name": "บางเตย",
          "postCode": [
            "12160"
          ]
        },
        {
          "id": 130705,
          "name": "บางโพธิ์เหนือ",
          "postCode": [
            "12160"
          ]
        },
        {
          "id": 130708,
          "name": "บ้านงิ้ว",
          "postCode": [
            "12160"
          ]
        },
        {
          "id": 130707,
          "name": "บ้านปทุม",
          "postCode": [
            "12160"
          ]
        },
        {
          "id": 130703,
          "name": "สามโคก",
          "postCode": [
            "12160"
          ]
        },
        {
          "id": 130709,
          "name": "เชียงรากน้อย",
          "postCode": [
            "12160"
          ]
        },
        {
          "id": 130706,
          "name": "เชียงรากใหญ่",
          "postCode": [
            "12160"
          ]
        }
      ]
    },
    {
      "city": "หนองเสือ",
      "cityCode": 1304,
      "provinceCode": 13,
      "subDistrict": [
        {
          "id": 130407,
          "name": "นพรัตน์",
          "postCode": [
            "12170"
          ]
        },
        {
          "id": 130403,
          "name": "บึงกาสาม",
          "postCode": [
            "12170"
          ]
        },
        {
          "id": 130404,
          "name": "บึงชำอ้อ",
          "postCode": [
            "12170"
          ]
        },
        {
          "id": 130402,
          "name": "บึงบอน",
          "postCode": [
            "12170"
          ]
        },
        {
          "id": 130401,
          "name": "บึงบา",
          "postCode": [
            "12170"
          ]
        },
        {
          "id": 130406,
          "name": "ศาลาครุ",
          "postCode": [
            "12170"
          ]
        },
        {
          "id": 130405,
          "name": "หนองสามวัง",
          "postCode": [
            "12170"
          ]
        }
      ]
    },
    {
      "city": "เมืองปทุมธานี",
      "cityCode": 1301,
      "provinceCode": 13,
      "subDistrict": [
        {
          "id": 130112,
          "name": "บางกะดี",
          "postCode": [
            "12000"
          ]
        },
        {
          "id": 130106,
          "name": "บางขะแยง",
          "postCode": [
            "12000"
          ]
        },
        {
          "id": 130107,
          "name": "บางคูวัด",
          "postCode": [
            "12000"
          ]
        },
        {
          "id": 130101,
          "name": "บางปรอก",
          "postCode": [
            "12000"
          ]
        },
        {
          "id": 130110,
          "name": "บางพูด",
          "postCode": [
            "12000"
          ]
        },
        {
          "id": 130111,
          "name": "บางพูน",
          "postCode": [
            "12000"
          ]
        },
        {
          "id": 130108,
          "name": "บางหลวง",
          "postCode": [
            "12000"
          ]
        },
        {
          "id": 130109,
          "name": "บางเดื่อ",
          "postCode": [
            "12000"
          ]
        },
        {
          "id": 130105,
          "name": "บ้านกระแชง",
          "postCode": [
            "12000"
          ]
        },
        {
          "id": 130103,
          "name": "บ้านกลาง",
          "postCode": [
            "12000"
          ]
        },
        {
          "id": 130104,
          "name": "บ้านฉาง",
          "postCode": [
            "12000"
          ]
        },
        {
          "id": 130102,
          "name": "บ้านใหม่",
          "postCode": [
            "12000"
          ]
        },
        {
          "id": 130113,
          "name": "สวนพริกไทย",
          "postCode": [
            "12000"
          ]
        },
        {
          "id": 130114,
          "name": "หลักหก",
          "postCode": [
            "12000"
          ]
        }
      ]
    },
    {
      "city": "กุยบุรี",
      "cityCode": 7702,
      "provinceCode": 77,
      "subDistrict": [
        {
          "id": 770201,
          "name": "กุยบุรี",
          "postCode": [
            "77150"
          ]
        },
        {
          "id": 770202,
          "name": "กุยเหนือ",
          "postCode": [
            "77150"
          ]
        },
        {
          "id": 770204,
          "name": "ดอนยายหนู",
          "postCode": [
            "77150"
          ]
        },
        {
          "id": 770206,
          "name": "สามกระทาย",
          "postCode": [
            "77150"
          ]
        },
        {
          "id": 770207,
          "name": "หาดขาม",
          "postCode": [
            "77150"
          ]
        },
        {
          "id": 770203,
          "name": "เขาแดง",
          "postCode": [
            "77150"
          ]
        }
      ]
    },
    {
      "city": "ทับสะแก",
      "cityCode": 7703,
      "provinceCode": 77,
      "subDistrict": [
        {
          "id": 770301,
          "name": "ทับสะแก",
          "postCode": [
            "77130"
          ]
        },
        {
          "id": 770303,
          "name": "นาหูกวาง",
          "postCode": [
            "77130"
          ]
        },
        {
          "id": 770305,
          "name": "ห้วยยาง",
          "postCode": [
            "77130"
          ]
        },
        {
          "id": 770302,
          "name": "อ่างทอง",
          "postCode": [
            "77130"
          ]
        },
        {
          "id": 770304,
          "name": "เขาล้าน",
          "postCode": [
            "77130"
          ]
        },
        {
          "id": 770306,
          "name": "แสงอรุณ",
          "postCode": [
            "77130"
          ]
        }
      ]
    },
    {
      "city": "บางสะพาน",
      "cityCode": 7704,
      "provinceCode": 77,
      "subDistrict": [
        {
          "id": 770401,
          "name": "กำเนิดนพคุณ",
          "postCode": [
            "77140"
          ]
        },
        {
          "id": 770405,
          "name": "ชัยเกษม",
          "postCode": [
            "77190"
          ]
        },
        {
          "id": 770406,
          "name": "ทองมงคล",
          "postCode": [
            "77230"
          ]
        },
        {
          "id": 770404,
          "name": "ธงชัย",
          "postCode": [
            "77190"
          ]
        },
        {
          "id": 770402,
          "name": "พงศ์ประศาสน์",
          "postCode": [
            "77140"
          ]
        },
        {
          "id": 770403,
          "name": "ร่อนทอง",
          "postCode": [
            "77230"
          ]
        },
        {
          "id": 770407,
          "name": "แม่รำพึง",
          "postCode": [
            "77140"
          ]
        }
      ]
    },
    {
      "city": "บางสะพานน้อย",
      "cityCode": 7705,
      "provinceCode": 77,
      "subDistrict": [
        {
          "id": 770504,
          "name": "ช้างแรก",
          "postCode": [
            "77170"
          ]
        },
        {
          "id": 770503,
          "name": "ทรายทอง",
          "postCode": [
            "77170"
          ]
        },
        {
          "id": 770502,
          "name": "บางสะพาน",
          "postCode": [
            "77170"
          ]
        },
        {
          "id": 770501,
          "name": "ปากแพรก",
          "postCode": [
            "77170"
          ]
        },
        {
          "id": 770505,
          "name": "ไชยราช",
          "postCode": [
            "77170"
          ]
        }
      ]
    },
    {
      "city": "ปราณบุรี",
      "cityCode": 7706,
      "provinceCode": 77,
      "subDistrict": [
        {
          "id": 770601,
          "name": "ปราณบุรี",
          "postCode": [
            "77120"
          ]
        },
        {
          "id": 770604,
          "name": "ปากน้ำปราณ",
          "postCode": [
            "77220"
          ]
        },
        {
          "id": 770608,
          "name": "วังก์พง",
          "postCode": [
            "77120"
          ]
        },
        {
          "id": 770607,
          "name": "หนองตาแต้ม",
          "postCode": [
            "77120"
          ]
        },
        {
          "id": 770609,
          "name": "เขาจ้าว",
          "postCode": [
            "77120"
          ]
        },
        {
          "id": 770602,
          "name": "เขาน้อย",
          "postCode": [
            "77120",
            "77160"
          ]
        }
      ]
    },
    {
      "city": "สามร้อยยอด",
      "cityCode": 7708,
      "provinceCode": 77,
      "subDistrict": [
        {
          "id": 770804,
          "name": "ศาลาลัย",
          "postCode": [
            "77180"
          ]
        },
        {
          "id": 770802,
          "name": "ศิลาลอย",
          "postCode": [
            "77180"
          ]
        },
        {
          "id": 770801,
          "name": "สามร้อยยอด",
          "postCode": [
            "77120"
          ]
        },
        {
          "id": 770803,
          "name": "ไร่เก่า",
          "postCode": [
            "77180"
          ]
        },
        {
          "id": 770805,
          "name": "ไร่ใหม่",
          "postCode": [
            "77180"
          ]
        }
      ]
    },
    {
      "city": "หัวหิน",
      "cityCode": 7707,
      "provinceCode": 77,
      "subDistrict": [
        {
          "id": 770705,
          "name": "ทับใต้",
          "postCode": [
            "77110"
          ]
        },
        {
          "id": 770707,
          "name": "บึงนคร",
          "postCode": [
            "77110"
          ]
        },
        {
          "id": 770704,
          "name": "หนองพลับ",
          "postCode": [
            "77110"
          ]
        },
        {
          "id": 770702,
          "name": "หนองแก",
          "postCode": [
            "77110"
          ]
        },
        {
          "id": 770701,
          "name": "หัวหิน",
          "postCode": [
            "77110"
          ]
        },
        {
          "id": 770703,
          "name": "หินเหล็กไฟ",
          "postCode": [
            "77110"
          ]
        },
        {
          "id": 770706,
          "name": "ห้วยสัตว์ใหญ่",
          "postCode": [
            "77110"
          ]
        }
      ]
    },
    {
      "city": "เมืองประจวบคีรีขันธ์",
      "cityCode": 7701,
      "provinceCode": 77,
      "subDistrict": [
        {
          "id": 770103,
          "name": "คลองวาฬ",
          "postCode": [
            "77000"
          ]
        },
        {
          "id": 770106,
          "name": "บ่อนอก",
          "postCode": [
            "77210"
          ]
        },
        {
          "id": 770101,
          "name": "ประจวบคีรีขันธ์",
          "postCode": [
            "77000"
          ]
        },
        {
          "id": 770104,
          "name": "ห้วยทราย",
          "postCode": [
            "77000"
          ]
        },
        {
          "id": 770105,
          "name": "อ่าวน้อย",
          "postCode": [
            "77000",
            "77210"
          ]
        },
        {
          "id": 770102,
          "name": "เกาะหลัก",
          "postCode": [
            "77000"
          ]
        }
      ]
    },
    {
      "city": "กบินทร์บุรี",
      "cityCode": 2502,
      "provinceCode": 25,
      "subDistrict": [
        {
          "id": 250201,
          "name": "กบินทร์",
          "postCode": [
            "25110"
          ]
        },
        {
          "id": 250204,
          "name": "นนทรี",
          "postCode": [
            "25110"
          ]
        },
        {
          "id": 250212,
          "name": "นาแขม",
          "postCode": [
            "25110"
          ]
        },
        {
          "id": 250210,
          "name": "บ่อทอง",
          "postCode": [
            "25110"
          ]
        },
        {
          "id": 250209,
          "name": "บ้านนา",
          "postCode": [
            "25110"
          ]
        },
        {
          "id": 250205,
          "name": "ย่านรี",
          "postCode": [
            "25110"
          ]
        },
        {
          "id": 250208,
          "name": "ลาดตะเคียน",
          "postCode": [
            "25110"
          ]
        },
        {
          "id": 250203,
          "name": "วังดาล",
          "postCode": [
            "25110"
          ]
        },
        {
          "id": 250206,
          "name": "วังตะเคียน",
          "postCode": [
            "25110"
          ]
        },
        {
          "id": 250214,
          "name": "วังท่าช้าง",
          "postCode": [
            "25110"
          ]
        },
        {
          "id": 250211,
          "name": "หนองกี่",
          "postCode": [
            "25110"
          ]
        },
        {
          "id": 250207,
          "name": "หาดนางแก้ว",
          "postCode": [
            "25110"
          ]
        },
        {
          "id": 250213,
          "name": "เขาไม้แก้ว",
          "postCode": [
            "25110"
          ]
        },
        {
          "id": 250202,
          "name": "เมืองเก่า",
          "postCode": [
            "25240"
          ]
        }
      ]
    },
    {
      "city": "นาดี",
      "cityCode": 2503,
      "provinceCode": 25,
      "subDistrict": [
        {
          "id": 250304,
          "name": "ทุ่งโพธิ์",
          "postCode": [
            "25220"
          ]
        },
        {
          "id": 250301,
          "name": "นาดี",
          "postCode": [
            "25220"
          ]
        },
        {
          "id": 250306,
          "name": "บุพราหมณ์",
          "postCode": [
            "25220"
          ]
        },
        {
          "id": 250303,
          "name": "สะพานหิน",
          "postCode": [
            "25220"
          ]
        },
        {
          "id": 250302,
          "name": "สำพันตา",
          "postCode": [
            "25220"
          ]
        },
        {
          "id": 250305,
          "name": "แก่งดินสอ",
          "postCode": [
            "25220"
          ]
        }
      ]
    },
    {
      "city": "บ้านสร้าง",
      "cityCode": 2506,
      "provinceCode": 25,
      "subDistrict": [
        {
          "id": 250609,
          "name": "กระทุ่มแพ้ว",
          "postCode": [
            "25150"
          ]
        },
        {
          "id": 250602,
          "name": "บางกระเบา",
          "postCode": [
            "25150"
          ]
        },
        {
          "id": 250608,
          "name": "บางขาม",
          "postCode": [
            "25150"
          ]
        },
        {
          "id": 250607,
          "name": "บางปลาร้า",
          "postCode": [
            "25150"
          ]
        },
        {
          "id": 250606,
          "name": "บางพลวง",
          "postCode": [
            "25150"
          ]
        },
        {
          "id": 250604,
          "name": "บางยาง",
          "postCode": [
            "25150"
          ]
        },
        {
          "id": 250603,
          "name": "บางเตย",
          "postCode": [
            "25150"
          ]
        },
        {
          "id": 250605,
          "name": "บางแตน",
          "postCode": [
            "25150"
          ]
        },
        {
          "id": 250601,
          "name": "บ้านสร้าง",
          "postCode": [
            "25150"
          ]
        }
      ]
    },
    {
      "city": "ประจันตคาม",
      "cityCode": 2507,
      "provinceCode": 25,
      "subDistrict": [
        {
          "id": 250706,
          "name": "คำโตนด",
          "postCode": [
            "25130"
          ]
        },
        {
          "id": 250705,
          "name": "ดงบัง",
          "postCode": [
            "25130"
          ]
        },
        {
          "id": 250707,
          "name": "บุฝ้าย",
          "postCode": [
            "25130"
          ]
        },
        {
          "id": 250703,
          "name": "บ้านหอย",
          "postCode": [
            "25130"
          ]
        },
        {
          "id": 250701,
          "name": "ประจันตคาม",
          "postCode": [
            "25130"
          ]
        },
        {
          "id": 250708,
          "name": "หนองแก้ว",
          "postCode": [
            "25130"
          ]
        },
        {
          "id": 250704,
          "name": "หนองแสง",
          "postCode": [
            "25130"
          ]
        },
        {
          "id": 250702,
          "name": "เกาะลอย",
          "postCode": [
            "25130"
          ]
        },
        {
          "id": 250709,
          "name": "โพธิ์งาม",
          "postCode": [
            "25130"
          ]
        }
      ]
    },
    {
      "city": "ศรีมหาโพธิ",
      "cityCode": 2508,
      "provinceCode": 25,
      "subDistrict": [
        {
          "id": 250810,
          "name": "กรอกสมบูรณ์",
          "postCode": [
            "25140"
          ]
        },
        {
          "id": 250806,
          "name": "ดงกระทงยาม",
          "postCode": [
            "25140"
          ]
        },
        {
          "id": 250804,
          "name": "ท่าตูม",
          "postCode": [
            "25140"
          ]
        },
        {
          "id": 250805,
          "name": "บางกุ้ง",
          "postCode": [
            "25140"
          ]
        },
        {
          "id": 250803,
          "name": "บ้านทาม",
          "postCode": [
            "25140"
          ]
        },
        {
          "id": 250801,
          "name": "ศรีมหาโพธิ",
          "postCode": [
            "25140"
          ]
        },
        {
          "id": 250802,
          "name": "สัมพันธ์",
          "postCode": [
            "25140"
          ]
        },
        {
          "id": 250807,
          "name": "หนองโพรง",
          "postCode": [
            "25140"
          ]
        },
        {
          "id": 250808,
          "name": "หัวหว้า",
          "postCode": [
            "25140"
          ]
        },
        {
          "id": 250809,
          "name": "หาดยาง",
          "postCode": [
            "25140"
          ]
        }
      ]
    },
    {
      "city": "ศรีมโหสถ",
      "cityCode": 2509,
      "provinceCode": 25,
      "subDistrict": [
        {
          "id": 250903,
          "name": "คู้ลำพัน",
          "postCode": [
            "25190"
          ]
        },
        {
          "id": 250901,
          "name": "โคกปีบ",
          "postCode": [
            "25190"
          ]
        },
        {
          "id": 250902,
          "name": "โคกไทย",
          "postCode": [
            "25190"
          ]
        },
        {
          "id": 250904,
          "name": "ไผ่ชะเลือด",
          "postCode": [
            "25190"
          ]
        }
      ]
    },
    {
      "city": "เมืองปราจีนบุรี",
      "cityCode": 2501,
      "provinceCode": 25,
      "subDistrict": [
        {
          "id": 250111,
          "name": "ดงขี้เหล็ก",
          "postCode": [
            "25000"
          ]
        },
        {
          "id": 250107,
          "name": "ดงพระราม",
          "postCode": [
            "25000"
          ]
        },
        {
          "id": 250105,
          "name": "ท่างาม",
          "postCode": [
            "25000"
          ]
        },
        {
          "id": 250106,
          "name": "บางบริบูรณ์",
          "postCode": [
            "25000"
          ]
        },
        {
          "id": 250104,
          "name": "บางเดชะ",
          "postCode": [
            "25000"
          ]
        },
        {
          "id": 250108,
          "name": "บ้านพระ",
          "postCode": [
            "25230"
          ]
        },
        {
          "id": 250102,
          "name": "รอบเมือง",
          "postCode": [
            "25000"
          ]
        },
        {
          "id": 250103,
          "name": "วัดโบสถ์",
          "postCode": [
            "25000"
          ]
        },
        {
          "id": 250101,
          "name": "หน้าเมือง",
          "postCode": [
            "25000"
          ]
        },
        {
          "id": 250112,
          "name": "เนินหอม",
          "postCode": [
            "25230"
          ]
        },
        {
          "id": 250109,
          "name": "โคกไม้ลาย",
          "postCode": [
            "25230"
          ]
        },
        {
          "id": 250113,
          "name": "โนนห้อม",
          "postCode": [
            "25000"
          ]
        },
        {
          "id": 250110,
          "name": "ไม้เค็ด",
          "postCode": [
            "25230"
          ]
        }
      ]
    },
    {
      "city": "กะพ้อ",
      "cityCode": 9411,
      "provinceCode": 94,
      "subDistrict": [
        {
          "id": 941101,
          "name": "กะรุบี",
          "postCode": [
            "94230"
          ]
        },
        {
          "id": 941102,
          "name": "ตะโละดือรามัน",
          "postCode": [
            "94230"
          ]
        },
        {
          "id": 941103,
          "name": "ปล่องหอย",
          "postCode": [
            "94230"
          ]
        }
      ]
    },
    {
      "city": "ทุ่งยางแดง",
      "cityCode": 9406,
      "provinceCode": 94,
      "subDistrict": [
        {
          "id": 940601,
          "name": "ตะโละแมะนา",
          "postCode": [
            "94140"
          ]
        },
        {
          "id": 940603,
          "name": "น้ำดำ",
          "postCode": [
            "94140"
          ]
        },
        {
          "id": 940604,
          "name": "ปากู",
          "postCode": [
            "94140"
          ]
        },
        {
          "id": 940602,
          "name": "พิเทน",
          "postCode": [
            "94140"
          ]
        }
      ]
    },
    {
      "city": "ปะนาเระ",
      "cityCode": 9404,
      "provinceCode": 94,
      "subDistrict": [
        {
          "id": 940405,
          "name": "ควน",
          "postCode": [
            "94190"
          ]
        },
        {
          "id": 940407,
          "name": "คอกกระบือ",
          "postCode": [
            "94130"
          ]
        },
        {
          "id": 940404,
          "name": "ดอน",
          "postCode": [
            "94130",
            "94190"
          ]
        },
        {
          "id": 940402,
          "name": "ท่าข้าม",
          "postCode": [
            "94130"
          ]
        },
        {
          "id": 940406,
          "name": "ท่าน้ำ",
          "postCode": [
            "94130"
          ]
        },
        {
          "id": 940409,
          "name": "บ้านกลาง",
          "postCode": [
            "94130"
          ]
        },
        {
          "id": 940403,
          "name": "บ้านนอก",
          "postCode": [
            "94130"
          ]
        },
        {
          "id": 940410,
          "name": "บ้านน้ำบ่อ",
          "postCode": [
            "94130"
          ]
        },
        {
          "id": 940401,
          "name": "ปะนาเระ",
          "postCode": [
            "94130"
          ]
        },
        {
          "id": 940408,
          "name": "พ่อมิ่ง",
          "postCode": [
            "94130"
          ]
        }
      ]
    },
    {
      "city": "มายอ",
      "cityCode": 9405,
      "provinceCode": 94,
      "subDistrict": [
        {
          "id": 940504,
          "name": "กระหวะ",
          "postCode": [
            "94140",
            "94190"
          ]
        },
        {
          "id": 940507,
          "name": "กระเสาะ",
          "postCode": [
            "94140"
          ]
        },
        {
          "id": 940503,
          "name": "ตรัง",
          "postCode": [
            "94140"
          ]
        },
        {
          "id": 940502,
          "name": "ถนน",
          "postCode": [
            "94140"
          ]
        },
        {
          "id": 940509,
          "name": "ปะโด",
          "postCode": [
            "94140"
          ]
        },
        {
          "id": 940513,
          "name": "ปานัน",
          "postCode": [
            "94140"
          ]
        },
        {
          "id": 940501,
          "name": "มายอ",
          "postCode": [
            "94140"
          ]
        },
        {
          "id": 940506,
          "name": "ลางา",
          "postCode": [
            "94190"
          ]
        },
        {
          "id": 940505,
          "name": "ลุโบะยิไร",
          "postCode": [
            "94140"
          ]
        },
        {
          "id": 940512,
          "name": "สะกำ",
          "postCode": [
            "94140"
          ]
        },
        {
          "id": 940510,
          "name": "สาคอบน",
          "postCode": [
            "94140"
          ]
        },
        {
          "id": 940511,
          "name": "สาคอใต้",
          "postCode": [
            "94140"
          ]
        },
        {
          "id": 940508,
          "name": "เกาะจัน",
          "postCode": [
            "94140"
          ]
        }
      ]
    },
    {
      "city": "ยะรัง",
      "cityCode": 9410,
      "provinceCode": 94,
      "subDistrict": [
        {
          "id": 941008,
          "name": "กระโด",
          "postCode": [
            "94160"
          ]
        },
        {
          "id": 941011,
          "name": "กอลำ",
          "postCode": [
            "94160"
          ]
        },
        {
          "id": 941009,
          "name": "คลองใหม่",
          "postCode": [
            "94160"
          ]
        },
        {
          "id": 941003,
          "name": "ประจัน",
          "postCode": [
            "94160"
          ]
        },
        {
          "id": 941006,
          "name": "ปิตูมุดี",
          "postCode": [
            "94160"
          ]
        },
        {
          "id": 941001,
          "name": "ยะรัง",
          "postCode": [
            "94160"
          ]
        },
        {
          "id": 941005,
          "name": "ระแว้ง",
          "postCode": [
            "94160"
          ]
        },
        {
          "id": 941007,
          "name": "วัด",
          "postCode": [
            "94160"
          ]
        },
        {
          "id": 941002,
          "name": "สะดาวา",
          "postCode": [
            "94160"
          ]
        },
        {
          "id": 941004,
          "name": "สะนอ",
          "postCode": [
            "94160"
          ]
        },
        {
          "id": 941012,
          "name": "เขาตูม",
          "postCode": [
            "94160"
          ]
        },
        {
          "id": 941010,
          "name": "เมาะมาวี",
          "postCode": [
            "94160"
          ]
        }
      ]
    },
    {
      "city": "ยะหริ่ง",
      "cityCode": 9409,
      "provinceCode": 94,
      "subDistrict": [
        {
          "id": 940917,
          "name": "จะรัง",
          "postCode": [
            "94150"
          ]
        },
        {
          "id": 940905,
          "name": "ตอหลัง",
          "postCode": [
            "94150"
          ]
        },
        {
          "id": 940901,
          "name": "ตะโละ",
          "postCode": [
            "94150"
          ]
        },
        {
          "id": 940902,
          "name": "ตะโละกาโปร์",
          "postCode": [
            "94150"
          ]
        },
        {
          "id": 940904,
          "name": "ตันหยงจึงงา",
          "postCode": [
            "94190"
          ]
        },
        {
          "id": 940903,
          "name": "ตันหยงดาลอ",
          "postCode": [
            "94150"
          ]
        },
        {
          "id": 940907,
          "name": "ตาลีอายร์",
          "postCode": [
            "94150"
          ]
        },
        {
          "id": 940906,
          "name": "ตาแกะ",
          "postCode": [
            "94150"
          ]
        },
        {
          "id": 940909,
          "name": "บางปู",
          "postCode": [
            "94150"
          ]
        },
        {
          "id": 940913,
          "name": "บาโลย",
          "postCode": [
            "94190"
          ]
        },
        {
          "id": 940911,
          "name": "ปิยามุมัง",
          "postCode": [
            "94150"
          ]
        },
        {
          "id": 940912,
          "name": "ปุลากง",
          "postCode": [
            "94150"
          ]
        },
        {
          "id": 940915,
          "name": "มะนังยง",
          "postCode": [
            "94150"
          ]
        },
        {
          "id": 940908,
          "name": "ยามู",
          "postCode": [
            "94150"
          ]
        },
        {
          "id": 940916,
          "name": "ราตาปันยัง",
          "postCode": [
            "94150"
          ]
        },
        {
          "id": 940914,
          "name": "สาบัน",
          "postCode": [
            "94150"
          ]
        },
        {
          "id": 940910,
          "name": "หนองแรต",
          "postCode": [
            "94150"
          ]
        },
        {
          "id": 940918,
          "name": "แหลมโพธิ์",
          "postCode": [
            "94150"
          ]
        }
      ]
    },
    {
      "city": "สายบุรี",
      "cityCode": 9407,
      "provinceCode": 94,
      "subDistrict": [
        {
          "id": 940707,
          "name": "กะดุนง",
          "postCode": [
            "94110"
          ]
        },
        {
          "id": 940702,
          "name": "ตะบิ้ง",
          "postCode": [
            "94110"
          ]
        },
        {
          "id": 940701,
          "name": "ตะลุบัน",
          "postCode": [
            "94110"
          ]
        },
        {
          "id": 940711,
          "name": "ทุ่งคล้า",
          "postCode": [
            "94190"
          ]
        },
        {
          "id": 940704,
          "name": "บางเก่า",
          "postCode": [
            "94110"
          ]
        },
        {
          "id": 940705,
          "name": "บือเระ",
          "postCode": [
            "94110"
          ]
        },
        {
          "id": 940703,
          "name": "ปะเสยะวอ",
          "postCode": [
            "94110"
          ]
        },
        {
          "id": 940709,
          "name": "มะนังดาลำ",
          "postCode": [
            "94110"
          ]
        },
        {
          "id": 940708,
          "name": "ละหาร",
          "postCode": [
            "94110"
          ]
        },
        {
          "id": 940706,
          "name": "เตราะบอน",
          "postCode": [
            "94110"
          ]
        },
        {
          "id": 940710,
          "name": "แป้น",
          "postCode": [
            "94110"
          ]
        }
      ]
    },
    {
      "city": "หนองจิก",
      "cityCode": 9403,
      "provinceCode": 94,
      "subDistrict": [
        {
          "id": 940302,
          "name": "คอลอตันหยง",
          "postCode": [
            "94170"
          ]
        },
        {
          "id": 940303,
          "name": "ดอนรัก",
          "postCode": [
            "94170"
          ]
        },
        {
          "id": 940304,
          "name": "ดาโต๊ะ",
          "postCode": [
            "94170"
          ]
        },
        {
          "id": 940305,
          "name": "ตุยง",
          "postCode": [
            "94170"
          ]
        },
        {
          "id": 940306,
          "name": "ท่ากำชำ",
          "postCode": [
            "94170"
          ]
        },
        {
          "id": 940309,
          "name": "บางตาวา",
          "postCode": [
            "94170"
          ]
        },
        {
          "id": 940308,
          "name": "บางเขา",
          "postCode": [
            "94170"
          ]
        },
        {
          "id": 940307,
          "name": "บ่อทอง",
          "postCode": [
            "94170"
          ]
        },
        {
          "id": 940310,
          "name": "ปุโละปุโย",
          "postCode": [
            "94170"
          ]
        },
        {
          "id": 940311,
          "name": "ยาบี",
          "postCode": [
            "94170"
          ]
        },
        {
          "id": 940312,
          "name": "ลิปะสะโง",
          "postCode": [
            "94170"
          ]
        },
        {
          "id": 940301,
          "name": "เกาะเปาะ",
          "postCode": [
            "94170"
          ]
        }
      ]
    },
    {
      "city": "เมืองปัตตานี",
      "cityCode": 9401,
      "provinceCode": 94,
      "subDistrict": [
        {
          "id": 940107,
          "name": "กะมิยอ",
          "postCode": [
            "94000"
          ]
        },
        {
          "id": 940106,
          "name": "คลองมานิง",
          "postCode": [
            "94000"
          ]
        },
        {
          "id": 940103,
          "name": "จะบังติกอ",
          "postCode": [
            "94000"
          ]
        },
        {
          "id": 940111,
          "name": "ตะลุโบะ",
          "postCode": [
            "94000"
          ]
        },
        {
          "id": 940105,
          "name": "ตันหยงลุโละ",
          "postCode": [
            "94000"
          ]
        },
        {
          "id": 940104,
          "name": "บานา",
          "postCode": [
            "94000"
          ]
        },
        {
          "id": 940112,
          "name": "บาราเฮาะ",
          "postCode": [
            "94000"
          ]
        },
        {
          "id": 940108,
          "name": "บาราโหม",
          "postCode": [
            "94000"
          ]
        },
        {
          "id": 940109,
          "name": "ปะกาฮะรัง",
          "postCode": [
            "94000"
          ]
        },
        {
          "id": 940113,
          "name": "ปุยุด",
          "postCode": [
            "94000"
          ]
        },
        {
          "id": 940110,
          "name": "รูสะมิแล",
          "postCode": [
            "94000"
          ]
        },
        {
          "id": 940101,
          "name": "สะบารัง",
          "postCode": [
            "94000"
          ]
        },
        {
          "id": 940102,
          "name": "อาเนาะรู",
          "postCode": [
            "94000"
          ]
        }
      ]
    },
    {
      "city": "แม่ลาน",
      "cityCode": 9412,
      "provinceCode": 94,
      "subDistrict": [
        {
          "id": 941203,
          "name": "ป่าไร่",
          "postCode": [
            "94180"
          ]
        },
        {
          "id": 941202,
          "name": "ม่วงเตี้ย",
          "postCode": [
            "94180"
          ]
        },
        {
          "id": 941201,
          "name": "แม่ลาน",
          "postCode": [
            "94180"
          ]
        }
      ]
    },
    {
      "city": "โคกโพธิ์",
      "cityCode": 9402,
      "provinceCode": 94,
      "subDistrict": [
        {
          "id": 940214,
          "name": "ควนโนรี",
          "postCode": [
            "94180"
          ]
        },
        {
          "id": 940215,
          "name": "ช้างให้ตก",
          "postCode": [
            "94120"
          ]
        },
        {
          "id": 940205,
          "name": "ทรายขาว",
          "postCode": [
            "94120"
          ]
        },
        {
          "id": 940208,
          "name": "ทุ่งพลา",
          "postCode": [
            "94180"
          ]
        },
        {
          "id": 940211,
          "name": "ท่าเรือ",
          "postCode": [
            "94120"
          ]
        },
        {
          "id": 940206,
          "name": "นาประดู่",
          "postCode": [
            "94180"
          ]
        },
        {
          "id": 940213,
          "name": "นาเกตุ",
          "postCode": [
            "94120"
          ]
        },
        {
          "id": 940203,
          "name": "บางโกระ",
          "postCode": [
            "94120"
          ]
        },
        {
          "id": 940207,
          "name": "ปากล่อ",
          "postCode": [
            "94180"
          ]
        },
        {
          "id": 940204,
          "name": "ป่าบอน",
          "postCode": [
            "94120"
          ]
        },
        {
          "id": 940202,
          "name": "มะกรูด",
          "postCode": [
            "94120"
          ]
        },
        {
          "id": 940201,
          "name": "โคกโพธิ์",
          "postCode": [
            "94120"
          ]
        }
      ]
    },
    {
      "city": "ไม้แก่น",
      "cityCode": 9408,
      "provinceCode": 94,
      "subDistrict": [
        {
          "id": 940804,
          "name": "ดอนทราย",
          "postCode": [
            "94220"
          ]
        },
        {
          "id": 940803,
          "name": "ตะโละไกรทอง",
          "postCode": [
            "94220"
          ]
        },
        {
          "id": 940801,
          "name": "ไทรทอง",
          "postCode": [
            "94220"
          ]
        },
        {
          "id": 940802,
          "name": "ไม้แก่น",
          "postCode": [
            "94220"
          ]
        }
      ]
    },
    {
      "city": "ท่าเรือ",
      "cityCode": 1402,
      "provinceCode": 14,
      "subDistrict": [
        {
          "id": 140202,
          "name": "จำปา",
          "postCode": [
            "13130"
          ]
        },
        {
          "id": 140203,
          "name": "ท่าหลวง",
          "postCode": [
            "13130",
            "18270"
          ]
        },
        {
          "id": 140210,
          "name": "ท่าเจ้าสนุก",
          "postCode": [
            "13130"
          ]
        },
        {
          "id": 140201,
          "name": "ท่าเรือ",
          "postCode": [
            "13130"
          ]
        },
        {
          "id": 140204,
          "name": "บ้านร่อม",
          "postCode": [
            "13130"
          ]
        },
        {
          "id": 140208,
          "name": "ปากท่า",
          "postCode": [
            "13130"
          ]
        },
        {
          "id": 140206,
          "name": "วังแดง",
          "postCode": [
            "13130"
          ]
        },
        {
          "id": 140205,
          "name": "ศาลาลอย",
          "postCode": [
            "13130"
          ]
        },
        {
          "id": 140209,
          "name": "หนองขนาก",
          "postCode": [
            "13130"
          ]
        },
        {
          "id": 140207,
          "name": "โพธิ์เอน",
          "postCode": [
            "13130"
          ]
        }
      ]
    },
    {
      "city": "นครหลวง",
      "cityCode": 1403,
      "provinceCode": 14,
      "subDistrict": [
        {
          "id": 140310,
          "name": "คลองสะแก",
          "postCode": [
            "13260"
          ]
        },
        {
          "id": 140302,
          "name": "ท่าช้าง",
          "postCode": [
            "13260"
          ]
        },
        {
          "id": 140301,
          "name": "นครหลวง",
          "postCode": [
            "13260"
          ]
        },
        {
          "id": 140307,
          "name": "บางพระครู",
          "postCode": [
            "13260"
          ]
        },
        {
          "id": 140306,
          "name": "บางระกำ",
          "postCode": [
            "13260"
          ]
        },
        {
          "id": 140303,
          "name": "บ่อโพง",
          "postCode": [
            "13260"
          ]
        },
        {
          "id": 140304,
          "name": "บ้านชุ้ง",
          "postCode": [
            "13260"
          ]
        },
        {
          "id": 140305,
          "name": "ปากจั่น",
          "postCode": [
            "13260"
          ]
        },
        {
          "id": 140312,
          "name": "พระนอน",
          "postCode": [
            "13260"
          ]
        },
        {
          "id": 140311,
          "name": "สามไถ",
          "postCode": [
            "13260"
          ]
        },
        {
          "id": 140309,
          "name": "หนองปลิง",
          "postCode": [
            "13260"
          ]
        },
        {
          "id": 140308,
          "name": "แม่ลา",
          "postCode": [
            "13260"
          ]
        }
      ]
    },
    {
      "city": "บางซ้าย",
      "cityCode": 1413,
      "provinceCode": 14,
      "subDistrict": [
        {
          "id": 141301,
          "name": "บางซ้าย",
          "postCode": [
            "13270"
          ]
        },
        {
          "id": 141304,
          "name": "ปลายกลัด",
          "postCode": [
            "13270"
          ]
        },
        {
          "id": 141306,
          "name": "วังพัฒนา",
          "postCode": [
            "13270"
          ]
        },
        {
          "id": 141303,
          "name": "เต่าเล่า",
          "postCode": [
            "13270"
          ]
        },
        {
          "id": 141305,
          "name": "เทพมงคล",
          "postCode": [
            "13270"
          ]
        },
        {
          "id": 141302,
          "name": "แก้วฟ้า",
          "postCode": [
            "13270"
          ]
        }
      ]
    },
    {
      "city": "บางบาล",
      "cityCode": 1405,
      "provinceCode": 14,
      "subDistrict": [
        {
          "id": 140506,
          "name": "กบเจา",
          "postCode": [
            "13250"
          ]
        },
        {
          "id": 140510,
          "name": "ทางช้าง",
          "postCode": [
            "13250"
          ]
        },
        {
          "id": 140509,
          "name": "น้ำเต้า",
          "postCode": [
            "13250"
          ]
        },
        {
          "id": 140515,
          "name": "บางชะนี",
          "postCode": [
            "13250"
          ]
        },
        {
          "id": 140501,
          "name": "บางบาล",
          "postCode": [
            "13250"
          ]
        },
        {
          "id": 140512,
          "name": "บางหลวง",
          "postCode": [
            "13250"
          ]
        },
        {
          "id": 140513,
          "name": "บางหลวงโดด",
          "postCode": [
            "13250"
          ]
        },
        {
          "id": 140514,
          "name": "บางหัก",
          "postCode": [
            "13250"
          ]
        },
        {
          "id": 140516,
          "name": "บ้านกุ่ม",
          "postCode": [
            "13250"
          ]
        },
        {
          "id": 140507,
          "name": "บ้านคลัง",
          "postCode": [
            "13250"
          ]
        },
        {
          "id": 140508,
          "name": "พระขาว",
          "postCode": [
            "13250"
          ]
        },
        {
          "id": 140505,
          "name": "มหาพราหมณ์",
          "postCode": [
            "13250"
          ]
        },
        {
          "id": 140511,
          "name": "วัดตะกู",
          "postCode": [
            "13250"
          ]
        },
        {
          "id": 140502,
          "name": "วัดยม",
          "postCode": [
            "13250"
          ]
        },
        {
          "id": 140504,
          "name": "สะพานไทย",
          "postCode": [
            "13250"
          ]
        },
        {
          "id": 140503,
          "name": "ไทรน้อย",
          "postCode": [
            "13250"
          ]
        }
      ]
    },
    {
      "city": "บางปะหัน",
      "cityCode": 1407,
      "provinceCode": 14,
      "subDistrict": [
        {
          "id": 140702,
          "name": "ขยาย",
          "postCode": [
            "13220"
          ]
        },
        {
          "id": 140712,
          "name": "ขวัญเมือง",
          "postCode": [
            "13220"
          ]
        },
        {
          "id": 140709,
          "name": "ตานิม",
          "postCode": [
            "13220"
          ]
        },
        {
          "id": 140716,
          "name": "ตาลเอน",
          "postCode": [
            "13220"
          ]
        },
        {
          "id": 140710,
          "name": "ทับน้ำ",
          "postCode": [
            "13220"
          ]
        },
        {
          "id": 140705,
          "name": "ทางกลาง",
          "postCode": [
            "13220"
          ]
        },
        {
          "id": 140708,
          "name": "บางนางร้า",
          "postCode": [
            "13220"
          ]
        },
        {
          "id": 140701,
          "name": "บางปะหัน",
          "postCode": [
            "13220"
          ]
        },
        {
          "id": 140703,
          "name": "บางเดื่อ",
          "postCode": [
            "13220"
          ]
        },
        {
          "id": 140706,
          "name": "บางเพลิง",
          "postCode": [
            "13220"
          ]
        },
        {
          "id": 140717,
          "name": "บ้านขล้อ",
          "postCode": [
            "13220"
          ]
        },
        {
          "id": 140711,
          "name": "บ้านม้า",
          "postCode": [
            "13220"
          ]
        },
        {
          "id": 140713,
          "name": "บ้านลี่",
          "postCode": [
            "13220"
          ]
        },
        {
          "id": 140715,
          "name": "พุทเลา",
          "postCode": [
            "13220"
          ]
        },
        {
          "id": 140707,
          "name": "หันสัง",
          "postCode": [
            "13220"
          ]
        },
        {
          "id": 140704,
          "name": "เสาธง",
          "postCode": [
            "13220"
          ]
        },
        {
          "id": 140714,
          "name": "โพธิ์สามต้น",
          "postCode": [
            "13220"
          ]
        }
      ]
    },
    {
      "city": "บางปะอิน",
      "cityCode": 1406,
      "provinceCode": 14,
      "subDistrict": [
        {
          "id": 140618,
          "name": "ขนอนหลวง",
          "postCode": [
            "13160"
          ]
        },
        {
          "id": 140606,
          "name": "คลองจิก",
          "postCode": [
            "13160"
          ]
        },
        {
          "id": 140614,
          "name": "คุ้งลาน",
          "postCode": [
            "13160"
          ]
        },
        {
          "id": 140617,
          "name": "ตลาดเกรียบ",
          "postCode": [
            "13160"
          ]
        },
        {
          "id": 140615,
          "name": "ตลิ่งชัน",
          "postCode": [
            "13160"
          ]
        },
        {
          "id": 140605,
          "name": "บางกระสั้น",
          "postCode": [
            "13160"
          ]
        },
        {
          "id": 140609,
          "name": "บางประแดง",
          "postCode": [
            "13160"
          ]
        },
        {
          "id": 140604,
          "name": "บ้านกรด",
          "postCode": [
            "13160"
          ]
        },
        {
          "id": 140612,
          "name": "บ้านพลับ",
          "postCode": [
            "13160"
          ]
        },
        {
          "id": 140616,
          "name": "บ้านสร้าง",
          "postCode": [
            "13170"
          ]
        },
        {
          "id": 140607,
          "name": "บ้านหว้า",
          "postCode": [
            "13160"
          ]
        },
        {
          "id": 140601,
          "name": "บ้านเลน",
          "postCode": [
            "13160"
          ]
        },
        {
          "id": 140613,
          "name": "บ้านแป้ง",
          "postCode": [
            "13160"
          ]
        },
        {
          "id": 140603,
          "name": "บ้านโพ",
          "postCode": [
            "13160"
          ]
        },
        {
          "id": 140608,
          "name": "วัดยม",
          "postCode": [
            "13160"
          ]
        },
        {
          "id": 140610,
          "name": "สามเรือน",
          "postCode": [
            "13160"
          ]
        },
        {
          "id": 140611,
          "name": "เกาะเกิด",
          "postCode": [
            "13160"
          ]
        },
        {
          "id": 140602,
          "name": "เชียงรากน้อย",
          "postCode": [
            "13180"
          ]
        }
      ]
    },
    {
      "city": "บางไทร",
      "cityCode": 1404,
      "provinceCode": 14,
      "subDistrict": [
        {
          "id": 140415,
          "name": "กกแก้วบูรพา",
          "postCode": [
            "13190"
          ]
        },
        {
          "id": 140410,
          "name": "กระแชง",
          "postCode": [
            "13190"
          ]
        },
        {
          "id": 140409,
          "name": "ช่างเหล็ก",
          "postCode": [
            "13190"
          ]
        },
        {
          "id": 140412,
          "name": "ช้างน้อย",
          "postCode": [
            "13190"
          ]
        },
        {
          "id": 140420,
          "name": "ช้างใหญ่",
          "postCode": [
            "13290"
          ]
        },
        {
          "id": 140402,
          "name": "บางพลี",
          "postCode": [
            "13190"
          ]
        },
        {
          "id": 140406,
          "name": "บางยี่โท",
          "postCode": [
            "13190"
          ]
        },
        {
          "id": 140401,
          "name": "บางไทร",
          "postCode": [
            "13190"
          ]
        },
        {
          "id": 140411,
          "name": "บ้านกลึง",
          "postCode": [
            "13190"
          ]
        },
        {
          "id": 140417,
          "name": "บ้านม้า",
          "postCode": [
            "13190"
          ]
        },
        {
          "id": 140418,
          "name": "บ้านเกาะ",
          "postCode": [
            "13190"
          ]
        },
        {
          "id": 140404,
          "name": "บ้านแป้ง",
          "postCode": [
            "13190"
          ]
        },
        {
          "id": 140419,
          "name": "ราชคราม",
          "postCode": [
            "13290"
          ]
        },
        {
          "id": 140403,
          "name": "สนามชัย",
          "postCode": [
            "13190"
          ]
        },
        {
          "id": 140405,
          "name": "หน้าไม้",
          "postCode": [
            "13190"
          ]
        },
        {
          "id": 140413,
          "name": "ห่อหมก",
          "postCode": [
            "13190"
          ]
        },
        {
          "id": 140422,
          "name": "เชียงรากน้อย",
          "postCode": [
            "13290"
          ]
        },
        {
          "id": 140408,
          "name": "แคตก",
          "postCode": [
            "13190"
          ]
        },
        {
          "id": 140407,
          "name": "แคออก",
          "postCode": [
            "13190"
          ]
        },
        {
          "id": 140423,
          "name": "โคกช้าง",
          "postCode": [
            "13190"
          ]
        },
        {
          "id": 140421,
          "name": "โพแตง",
          "postCode": [
            "13290"
          ]
        },
        {
          "id": 140414,
          "name": "ไผ่พระ",
          "postCode": [
            "13190"
          ]
        },
        {
          "id": 140416,
          "name": "ไม้ตรา",
          "postCode": [
            "13190"
          ]
        }
      ]
    },
    {
      "city": "บ้านแพรก",
      "cityCode": 1416,
      "provinceCode": 14,
      "subDistrict": [
        {
          "id": 141604,
          "name": "คลองน้อย",
          "postCode": [
            "13240"
          ]
        },
        {
          "id": 141601,
          "name": "บ้านแพรก",
          "postCode": [
            "13240"
          ]
        },
        {
          "id": 141602,
          "name": "บ้านใหม่",
          "postCode": [
            "13240"
          ]
        },
        {
          "id": 141605,
          "name": "สองห้อง",
          "postCode": [
            "13240"
          ]
        },
        {
          "id": 141603,
          "name": "สำพะเนียง",
          "postCode": [
            "13240"
          ]
        }
      ]
    },
    {
      "city": "ผักไห่",
      "cityCode": 1408,
      "provinceCode": 14,
      "subDistrict": [
        {
          "id": 140809,
          "name": "กุฎี",
          "postCode": [
            "13120"
          ]
        },
        {
          "id": 140812,
          "name": "จักราช",
          "postCode": [
            "13280"
          ]
        },
        {
          "id": 140807,
          "name": "ดอนลาน",
          "postCode": [
            "13280"
          ]
        },
        {
          "id": 140805,
          "name": "ตาลาน",
          "postCode": [
            "13120"
          ]
        },
        {
          "id": 140806,
          "name": "ท่าดินแดง",
          "postCode": [
            "13120"
          ]
        },
        {
          "id": 140808,
          "name": "นาคู",
          "postCode": [
            "13280"
          ]
        },
        {
          "id": 140803,
          "name": "บ้านแค",
          "postCode": [
            "13120"
          ]
        },
        {
          "id": 140816,
          "name": "บ้านใหญ่",
          "postCode": [
            "13120"
          ]
        },
        {
          "id": 140801,
          "name": "ผักไห่",
          "postCode": [
            "13120"
          ]
        },
        {
          "id": 140814,
          "name": "ลาดชิด",
          "postCode": [
            "13120"
          ]
        },
        {
          "id": 140804,
          "name": "ลาดน้ำเค็ม",
          "postCode": [
            "13120"
          ]
        },
        {
          "id": 140810,
          "name": "ลำตะเคียน",
          "postCode": [
            "13280"
          ]
        },
        {
          "id": 140813,
          "name": "หนองน้ำใหญ่",
          "postCode": [
            "13280"
          ]
        },
        {
          "id": 140815,
          "name": "หน้าโคก",
          "postCode": [
            "13120"
          ]
        },
        {
          "id": 140802,
          "name": "อมฤต",
          "postCode": [
            "13120"
          ]
        },
        {
          "id": 140811,
          "name": "โคกช้าง",
          "postCode": [
            "13120"
          ]
        }
      ]
    },
    {
      "city": "พระนครศรีอยุธยา",
      "cityCode": 1401,
      "provinceCode": 14,
      "subDistrict": [
        {
          "id": 140102,
          "name": "กะมัง",
          "postCode": [
            "13000"
          ]
        },
        {
          "id": 140111,
          "name": "คลองตะเคียน",
          "postCode": [
            "13000"
          ]
        },
        {
          "id": 140118,
          "name": "คลองสระบัว",
          "postCode": [
            "13000"
          ]
        },
        {
          "id": 140117,
          "name": "คลองสวนพลู",
          "postCode": [
            "13000"
          ]
        },
        {
          "id": 140105,
          "name": "ท่าวาสุกรี",
          "postCode": [
            "13000"
          ]
        },
        {
          "id": 140120,
          "name": "บ้านป้อม",
          "postCode": [
            "13000"
          ]
        },
        {
          "id": 140121,
          "name": "บ้านรุน",
          "postCode": [
            "13000"
          ]
        },
        {
          "id": 140116,
          "name": "บ้านเกาะ",
          "postCode": [
            "13000"
          ]
        },
        {
          "id": 140115,
          "name": "บ้านใหม่",
          "postCode": [
            "13000"
          ]
        },
        {
          "id": 140101,
          "name": "ประตูชัย",
          "postCode": [
            "13000"
          ]
        },
        {
          "id": 140107,
          "name": "ปากกราน",
          "postCode": [
            "13000"
          ]
        },
        {
          "id": 140108,
          "name": "ภูเขาทอง",
          "postCode": [
            "13000"
          ]
        },
        {
          "id": 140114,
          "name": "ลุมพลี",
          "postCode": [
            "13000"
          ]
        },
        {
          "id": 140112,
          "name": "วัดตูม",
          "postCode": [
            "13000"
          ]
        },
        {
          "id": 140110,
          "name": "สวนพริก",
          "postCode": [
            "13000"
          ]
        },
        {
          "id": 140109,
          "name": "สำเภาล่ม",
          "postCode": [
            "13000"
          ]
        },
        {
          "id": 140103,
          "name": "หอรัตนไชย",
          "postCode": [
            "13000"
          ]
        },
        {
          "id": 140113,
          "name": "หันตรา",
          "postCode": [
            "13000"
          ]
        },
        {
          "id": 140104,
          "name": "หัวรอ",
          "postCode": [
            "13000"
          ]
        },
        {
          "id": 140119,
          "name": "เกาะเรียน",
          "postCode": [
            "13000"
          ]
        },
        {
          "id": 140106,
          "name": "ไผ่ลิง",
          "postCode": [
            "13000"
          ]
        }
      ]
    },
    {
      "city": "ภาชี",
      "cityCode": 1409,
      "provinceCode": 14,
      "subDistrict": [
        {
          "id": 140907,
          "name": "กระจิว",
          "postCode": [
            "13140"
          ]
        },
        {
          "id": 140905,
          "name": "ดอนหญ้านาง",
          "postCode": [
            "13140"
          ]
        },
        {
          "id": 140908,
          "name": "พระแก้ว",
          "postCode": [
            "13140"
          ]
        },
        {
          "id": 140901,
          "name": "ภาชี",
          "postCode": [
            "13140"
          ]
        },
        {
          "id": 140903,
          "name": "ระโสม",
          "postCode": [
            "13140",
            "18250"
          ]
        },
        {
          "id": 140904,
          "name": "หนองน้ำใส",
          "postCode": [
            "13140"
          ]
        },
        {
          "id": 140902,
          "name": "โคกม่วง",
          "postCode": [
            "13140"
          ]
        },
        {
          "id": 140906,
          "name": "ไผ่ล้อม",
          "postCode": [
            "13140"
          ]
        }
      ]
    },
    {
      "city": "มหาราช",
      "cityCode": 1415,
      "provinceCode": 14,
      "subDistrict": [
        {
          "id": 141502,
          "name": "กะทุ่ม",
          "postCode": [
            "13150"
          ]
        },
        {
          "id": 141511,
          "name": "ท่าตอ",
          "postCode": [
            "13150"
          ]
        },
        {
          "id": 141504,
          "name": "น้ำเต้า",
          "postCode": [
            "13150"
          ]
        },
        {
          "id": 141505,
          "name": "บางนา",
          "postCode": [
            "13150"
          ]
        },
        {
          "id": 141510,
          "name": "บ้านขวาง",
          "postCode": [
            "13150"
          ]
        },
        {
          "id": 141509,
          "name": "บ้านนา",
          "postCode": [
            "13150"
          ]
        },
        {
          "id": 141512,
          "name": "บ้านใหม่",
          "postCode": [
            "13150"
          ]
        },
        {
          "id": 141508,
          "name": "พิตเพียน",
          "postCode": [
            "13150"
          ]
        },
        {
          "id": 141503,
          "name": "มหาราช",
          "postCode": [
            "13150"
          ]
        },
        {
          "id": 141501,
          "name": "หัวไผ่",
          "postCode": [
            "13150"
          ]
        },
        {
          "id": 141507,
          "name": "เจ้าปลุก",
          "postCode": [
            "13150"
          ]
        },
        {
          "id": 141506,
          "name": "โรงช้าง",
          "postCode": [
            "13150"
          ]
        }
      ]
    },
    {
      "city": "ลาดบัวหลวง",
      "cityCode": 1410,
      "provinceCode": 14,
      "subDistrict": [
        {
          "id": 141007,
          "name": "คลองพระยาบันลือ",
          "postCode": [
            "13230"
          ]
        },
        {
          "id": 141006,
          "name": "คู้สลอด",
          "postCode": [
            "13230"
          ]
        },
        {
          "id": 141004,
          "name": "พระยาบันลือ",
          "postCode": [
            "13230"
          ]
        },
        {
          "id": 141001,
          "name": "ลาดบัวหลวง",
          "postCode": [
            "13230"
          ]
        },
        {
          "id": 141003,
          "name": "สามเมือง",
          "postCode": [
            "13230"
          ]
        },
        {
          "id": 141005,
          "name": "สิงหนาท",
          "postCode": [
            "13230"
          ]
        },
        {
          "id": 141002,
          "name": "หลักชัย",
          "postCode": [
            "13230"
          ]
        }
      ]
    },
    {
      "city": "วังน้อย",
      "cityCode": 1411,
      "provinceCode": 14,
      "subDistrict": [
        {
          "id": 141109,
          "name": "ข้าวงาม",
          "postCode": [
            "13170"
          ]
        },
        {
          "id": 141110,
          "name": "ชะแมบ",
          "postCode": [
            "13170"
          ]
        },
        {
          "id": 141102,
          "name": "บ่อตาโล่",
          "postCode": [
            "13170"
          ]
        },
        {
          "id": 141106,
          "name": "พยอม",
          "postCode": [
            "13170",
            "13180"
          ]
        },
        {
          "id": 141101,
          "name": "ลำตาเสา",
          "postCode": [
            "13170"
          ]
        },
        {
          "id": 141104,
          "name": "ลำไทร",
          "postCode": [
            "13170"
          ]
        },
        {
          "id": 141108,
          "name": "วังจุฬา",
          "postCode": [
            "13170"
          ]
        },
        {
          "id": 141103,
          "name": "วังน้อย",
          "postCode": [
            "13170"
          ]
        },
        {
          "id": 141105,
          "name": "สนับทึบ",
          "postCode": [
            "13170"
          ]
        },
        {
          "id": 141107,
          "name": "หันตะเภา",
          "postCode": [
            "13170"
          ]
        }
      ]
    },
    {
      "city": "อุทัย",
      "cityCode": 1414,
      "provinceCode": 14,
      "subDistrict": [
        {
          "id": 141411,
          "name": "ข้าวเม่า",
          "postCode": [
            "13000",
            "13210"
          ]
        },
        {
          "id": 141401,
          "name": "คานหาม",
          "postCode": [
            "13210"
          ]
        },
        {
          "id": 141410,
          "name": "ธนู",
          "postCode": [
            "13000",
            "13210"
          ]
        },
        {
          "id": 141402,
          "name": "บ้านช้าง",
          "postCode": [
            "13210"
          ]
        },
        {
          "id": 141404,
          "name": "บ้านหีบ",
          "postCode": [
            "13210"
          ]
        },
        {
          "id": 141403,
          "name": "สามบัณฑิต",
          "postCode": [
            "13210"
          ]
        },
        {
          "id": 141408,
          "name": "หนองน้ำส้ม",
          "postCode": [
            "13210"
          ]
        },
        {
          "id": 141405,
          "name": "หนองไม้ซุง",
          "postCode": [
            "13210"
          ]
        },
        {
          "id": 141406,
          "name": "อุทัย",
          "postCode": [
            "13210"
          ]
        },
        {
          "id": 141407,
          "name": "เสนา",
          "postCode": [
            "13210"
          ]
        },
        {
          "id": 141409,
          "name": "โพสาวหาญ",
          "postCode": [
            "13210"
          ]
        }
      ]
    },
    {
      "city": "เสนา",
      "cityCode": 1412,
      "provinceCode": 14,
      "subDistrict": [
        {
          "id": 141212,
          "name": "ชายนา",
          "postCode": [
            "13110"
          ]
        },
        {
          "id": 141215,
          "name": "ดอนทอง",
          "postCode": [
            "13110"
          ]
        },
        {
          "id": 141205,
          "name": "บางนมโค",
          "postCode": [
            "13110"
          ]
        },
        {
          "id": 141210,
          "name": "บ้านกระทุ่ม",
          "postCode": [
            "13110"
          ]
        },
        {
          "id": 141216,
          "name": "บ้านหลวง",
          "postCode": [
            "13110"
          ]
        },
        {
          "id": 141211,
          "name": "บ้านแถว",
          "postCode": [
            "13110"
          ]
        },
        {
          "id": 141202,
          "name": "บ้านแพน",
          "postCode": [
            "13110"
          ]
        },
        {
          "id": 141208,
          "name": "บ้านโพธิ์",
          "postCode": [
            "13110"
          ]
        },
        {
          "id": 141207,
          "name": "มารวิชัย",
          "postCode": [
            "13110"
          ]
        },
        {
          "id": 141209,
          "name": "รางจรเข้",
          "postCode": [
            "13110"
          ]
        },
        {
          "id": 141214,
          "name": "ลาดงา",
          "postCode": [
            "13110"
          ]
        },
        {
          "id": 141204,
          "name": "สามกอ",
          "postCode": [
            "13110"
          ]
        },
        {
          "id": 141213,
          "name": "สามตุ่ม",
          "postCode": [
            "13110"
          ]
        },
        {
          "id": 141206,
          "name": "หัวเวียง",
          "postCode": [
            "13110"
          ]
        },
        {
          "id": 141203,
          "name": "เจ้าเจ็ด",
          "postCode": [
            "13110"
          ]
        },
        {
          "id": 141217,
          "name": "เจ้าเสด็จ",
          "postCode": [
            "13110"
          ]
        },
        {
          "id": 141201,
          "name": "เสนา",
          "postCode": [
            "13110"
          ]
        }
      ]
    },
    {
      "city": "จุน",
      "cityCode": 5602,
      "provinceCode": 56,
      "subDistrict": [
        {
          "id": 560202,
          "name": "จุน",
          "postCode": [
            "56150"
          ]
        },
        {
          "id": 560205,
          "name": "ทุ่งรวงทอง",
          "postCode": [
            "56150"
          ]
        },
        {
          "id": 560207,
          "name": "พระธาตุขิงแกง",
          "postCode": [
            "56150"
          ]
        },
        {
          "id": 560203,
          "name": "ลอ",
          "postCode": [
            "56150"
          ]
        },
        {
          "id": 560204,
          "name": "หงส์หิน",
          "postCode": [
            "56150"
          ]
        },
        {
          "id": 560201,
          "name": "ห้วยข้าวก่ำ",
          "postCode": [
            "56150"
          ]
        },
        {
          "id": 560206,
          "name": "ห้วยยางขาม",
          "postCode": [
            "56150"
          ]
        }
      ]
    },
    {
      "city": "ดอกคำใต้",
      "cityCode": 5605,
      "provinceCode": 56,
      "subDistrict": [
        {
          "id": 560512,
          "name": "คือเวียง",
          "postCode": [
            "56120"
          ]
        },
        {
          "id": 560509,
          "name": "ดงสุวรรณ",
          "postCode": [
            "56120"
          ]
        },
        {
          "id": 560501,
          "name": "ดอกคำใต้",
          "postCode": [
            "56120"
          ]
        },
        {
          "id": 560502,
          "name": "ดอนศรีชุม",
          "postCode": [
            "56120"
          ]
        },
        {
          "id": 560510,
          "name": "บุญเกิด",
          "postCode": [
            "56120"
          ]
        },
        {
          "id": 560503,
          "name": "บ้านถ้ำ",
          "postCode": [
            "56120"
          ]
        },
        {
          "id": 560504,
          "name": "บ้านปิน",
          "postCode": [
            "56120"
          ]
        },
        {
          "id": 560507,
          "name": "ป่าซาง",
          "postCode": [
            "56120"
          ]
        },
        {
          "id": 560511,
          "name": "สว่างอารมณ์",
          "postCode": [
            "56120"
          ]
        },
        {
          "id": 560506,
          "name": "สันโค้ง",
          "postCode": [
            "56120"
          ]
        },
        {
          "id": 560508,
          "name": "หนองหล่ม",
          "postCode": [
            "56120"
          ]
        },
        {
          "id": 560505,
          "name": "ห้วยลาน",
          "postCode": [
            "56120"
          ]
        }
      ]
    },
    {
      "city": "ปง",
      "cityCode": 5606,
      "provinceCode": 56,
      "subDistrict": [
        {
          "id": 560607,
          "name": "ขุนควร",
          "postCode": [
            "56140"
          ]
        },
        {
          "id": 560602,
          "name": "ควร",
          "postCode": [
            "56140"
          ]
        },
        {
          "id": 560604,
          "name": "งิม",
          "postCode": [
            "56140"
          ]
        },
        {
          "id": 560606,
          "name": "นาปรัง",
          "postCode": [
            "56140"
          ]
        },
        {
          "id": 560601,
          "name": "ปง",
          "postCode": [
            "56140"
          ]
        },
        {
          "id": 560605,
          "name": "ผาช้างน้อย",
          "postCode": [
            "56140"
          ]
        },
        {
          "id": 560603,
          "name": "ออย",
          "postCode": [
            "56140"
          ]
        }
      ]
    },
    {
      "city": "ภูกามยาว",
      "cityCode": 5609,
      "provinceCode": 56,
      "subDistrict": [
        {
          "id": 560902,
          "name": "ดงเจน",
          "postCode": [
            "56000"
          ]
        },
        {
          "id": 560901,
          "name": "ห้วยแก้ว",
          "postCode": [
            "56000"
          ]
        },
        {
          "id": 560903,
          "name": "แม่อิง",
          "postCode": [
            "56000"
          ]
        }
      ]
    },
    {
      "city": "ภูซาง",
      "cityCode": 5608,
      "provinceCode": 56,
      "subDistrict": [
        {
          "id": 560803,
          "name": "ทุ่งกล้วย",
          "postCode": [
            "56110"
          ]
        },
        {
          "id": 560802,
          "name": "ป่าสัก",
          "postCode": [
            "56110"
          ]
        },
        {
          "id": 560801,
          "name": "ภูซาง",
          "postCode": [
            "56110"
          ]
        },
        {
          "id": 560805,
          "name": "สบบง",
          "postCode": [
            "56110"
          ]
        },
        {
          "id": 560804,
          "name": "เชียงแรง",
          "postCode": [
            "56110"
          ]
        }
      ]
    },
    {
      "city": "เชียงคำ",
      "cityCode": 5603,
      "provinceCode": 56,
      "subDistrict": [
        {
          "id": 560314,
          "name": "ทุ่งผาสุข",
          "postCode": [
            "56110"
          ]
        },
        {
          "id": 560306,
          "name": "น้ำแวน",
          "postCode": [
            "56110"
          ]
        },
        {
          "id": 560308,
          "name": "ฝายกวาง",
          "postCode": [
            "56110"
          ]
        },
        {
          "id": 560310,
          "name": "ร่มเย็น",
          "postCode": [
            "56110"
          ]
        },
        {
          "id": 560301,
          "name": "หย่วน",
          "postCode": [
            "56110"
          ]
        },
        {
          "id": 560313,
          "name": "อ่างทอง",
          "postCode": [
            "56110"
          ]
        },
        {
          "id": 560309,
          "name": "เจดีย์คำ",
          "postCode": [
            "56110"
          ]
        },
        {
          "id": 560311,
          "name": "เชียงบาน",
          "postCode": [
            "56110"
          ]
        },
        {
          "id": 560307,
          "name": "เวียง",
          "postCode": [
            "56110"
          ]
        },
        {
          "id": 560312,
          "name": "แม่ลาว",
          "postCode": [
            "56110"
          ]
        }
      ]
    },
    {
      "city": "เชียงม่วน",
      "cityCode": 5604,
      "provinceCode": 56,
      "subDistrict": [
        {
          "id": 560402,
          "name": "บ้านมาง",
          "postCode": [
            "56160"
          ]
        },
        {
          "id": 560403,
          "name": "สระ",
          "postCode": [
            "56160"
          ]
        },
        {
          "id": 560401,
          "name": "เชียงม่วน",
          "postCode": [
            "56160"
          ]
        }
      ]
    },
    {
      "city": "เมืองพะเยา",
      "cityCode": 5601,
      "provinceCode": 56,
      "subDistrict": [
        {
          "id": 560112,
          "name": "จำป่าหวาย",
          "postCode": [
            "56000"
          ]
        },
        {
          "id": 560116,
          "name": "ท่าจำปี",
          "postCode": [
            "56000"
          ]
        },
        {
          "id": 560113,
          "name": "ท่าวังทอง",
          "postCode": [
            "56000"
          ]
        },
        {
          "id": 560105,
          "name": "บ้านตุ่น",
          "postCode": [
            "56000"
          ]
        },
        {
          "id": 560106,
          "name": "บ้านต๊ำ",
          "postCode": [
            "56000"
          ]
        },
        {
          "id": 560107,
          "name": "บ้านต๋อม",
          "postCode": [
            "56000"
          ]
        },
        {
          "id": 560115,
          "name": "บ้านสาง",
          "postCode": [
            "56000"
          ]
        },
        {
          "id": 560111,
          "name": "บ้านใหม่",
          "postCode": [
            "56000"
          ]
        },
        {
          "id": 560118,
          "name": "สันป่าม่วง",
          "postCode": [
            "56000"
          ]
        },
        {
          "id": 560101,
          "name": "เวียง",
          "postCode": [
            "56000"
          ]
        },
        {
          "id": 560110,
          "name": "แม่กา",
          "postCode": [
            "56000"
          ]
        },
        {
          "id": 560102,
          "name": "แม่ต๋ำ",
          "postCode": [
            "56000"
          ]
        },
        {
          "id": 560104,
          "name": "แม่นาเรือ",
          "postCode": [
            "56000"
          ]
        },
        {
          "id": 560108,
          "name": "แม่ปืม",
          "postCode": [
            "56000"
          ]
        },
        {
          "id": 560114,
          "name": "แม่ใส",
          "postCode": [
            "56000"
          ]
        }
      ]
    },
    {
      "city": "แม่ใจ",
      "cityCode": 5607,
      "provinceCode": 56,
      "subDistrict": [
        {
          "id": 560705,
          "name": "บ้านเหล่า",
          "postCode": [
            "56130"
          ]
        },
        {
          "id": 560704,
          "name": "ป่าแฝก",
          "postCode": [
            "56130"
          ]
        },
        {
          "id": 560702,
          "name": "ศรีถ้อย",
          "postCode": [
            "56130"
          ]
        },
        {
          "id": 560706,
          "name": "เจริญราษฎร์",
          "postCode": [
            "56130"
          ]
        },
        {
          "id": 560703,
          "name": "แม่สุก",
          "postCode": [
            "56130"
          ]
        },
        {
          "id": 560701,
          "name": "แม่ใจ",
          "postCode": [
            "56130"
          ]
        }
      ]
    },
    {
      "city": "กะปง",
      "cityCode": 8203,
      "provinceCode": 82,
      "subDistrict": [
        {
          "id": 820301,
          "name": "กะปง",
          "postCode": [
            "82170"
          ]
        },
        {
          "id": 820302,
          "name": "ท่านา",
          "postCode": [
            "82170"
          ]
        },
        {
          "id": 820305,
          "name": "รมณีย์",
          "postCode": [
            "82170"
          ]
        },
        {
          "id": 820303,
          "name": "เหมาะ",
          "postCode": [
            "82170"
          ]
        },
        {
          "id": 820304,
          "name": "เหล",
          "postCode": [
            "82170"
          ]
        }
      ]
    },
    {
      "city": "คุระบุรี",
      "cityCode": 8206,
      "provinceCode": 82,
      "subDistrict": [
        {
          "id": 820601,
          "name": "คุระ",
          "postCode": [
            "82150"
          ]
        },
        {
          "id": 820602,
          "name": "บางวัน",
          "postCode": [
            "82150"
          ]
        },
        {
          "id": 820603,
          "name": "เกาะพระทอง",
          "postCode": [
            "82150"
          ]
        },
        {
          "id": 820605,
          "name": "แม่นางขาว",
          "postCode": [
            "82150"
          ]
        }
      ]
    },
    {
      "city": "ตะกั่วทุ่ง",
      "cityCode": 8204,
      "provinceCode": 82,
      "subDistrict": [
        {
          "id": 820402,
          "name": "กระโสม",
          "postCode": [
            "82130"
          ]
        },
        {
          "id": 820403,
          "name": "กะไหล",
          "postCode": [
            "82130"
          ]
        },
        {
          "id": 820407,
          "name": "คลองเคียน",
          "postCode": [
            "82130"
          ]
        },
        {
          "id": 820401,
          "name": "ถ้ำ",
          "postCode": [
            "82130"
          ]
        },
        {
          "id": 820404,
          "name": "ท่าอยู่",
          "postCode": [
            "82130"
          ]
        },
        {
          "id": 820405,
          "name": "หล่อยูง",
          "postCode": [
            "82140"
          ]
        },
        {
          "id": 820406,
          "name": "โคกกลอย",
          "postCode": [
            "82140"
          ]
        }
      ]
    },
    {
      "city": "ตะกั่วป่า",
      "cityCode": 8205,
      "provinceCode": 82,
      "subDistrict": [
        {
          "id": 820507,
          "name": "คึกคัก",
          "postCode": [
            "82190"
          ]
        },
        {
          "id": 820501,
          "name": "ตะกั่วป่า",
          "postCode": [
            "82110"
          ]
        },
        {
          "id": 820505,
          "name": "ตำตัว",
          "postCode": [
            "82110"
          ]
        },
        {
          "id": 820502,
          "name": "บางนายสี",
          "postCode": [
            "82110"
          ]
        },
        {
          "id": 820504,
          "name": "บางม่วง",
          "postCode": [
            "82110",
            "82190"
          ]
        },
        {
          "id": 820503,
          "name": "บางไทร",
          "postCode": [
            "82110"
          ]
        },
        {
          "id": 820508,
          "name": "เกาะคอเขา",
          "postCode": [
            "82190"
          ]
        },
        {
          "id": 820506,
          "name": "โคกเคียน",
          "postCode": [
            "82110"
          ]
        }
      ]
    },
    {
      "city": "ทับปุด",
      "cityCode": 8207,
      "provinceCode": 82,
      "subDistrict": [
        {
          "id": 820704,
          "name": "ถ้ำทองหลาง",
          "postCode": [
            "82180"
          ]
        },
        {
          "id": 820701,
          "name": "ทับปุด",
          "postCode": [
            "82180"
          ]
        },
        {
          "id": 820706,
          "name": "บางเหรียง",
          "postCode": [
            "82180"
          ]
        },
        {
          "id": 820703,
          "name": "บ่อแสน",
          "postCode": [
            "82180"
          ]
        },
        {
          "id": 820702,
          "name": "มะรุ่ย",
          "postCode": [
            "82180"
          ]
        },
        {
          "id": 820705,
          "name": "โคกเจริญ",
          "postCode": [
            "82180"
          ]
        }
      ]
    },
    {
      "city": "ท้ายเหมือง",
      "cityCode": 8208,
      "provinceCode": 82,
      "subDistrict": [
        {
          "id": 820804,
          "name": "ทุ่งมะพร้าว",
          "postCode": [
            "82120"
          ]
        },
        {
          "id": 820801,
          "name": "ท้ายเหมือง",
          "postCode": [
            "82120"
          ]
        },
        {
          "id": 820802,
          "name": "นาเตย",
          "postCode": [
            "82120"
          ]
        },
        {
          "id": 820803,
          "name": "บางทอง",
          "postCode": [
            "82120"
          ]
        },
        {
          "id": 820805,
          "name": "ลำภี",
          "postCode": [
            "82120"
          ]
        },
        {
          "id": 820806,
          "name": "ลำแก่น",
          "postCode": [
            "82120",
            "82210"
          ]
        }
      ]
    },
    {
      "city": "เกาะยาว",
      "cityCode": 8202,
      "provinceCode": 82,
      "subDistrict": [
        {
          "id": 820203,
          "name": "พรุใน",
          "postCode": [
            "83000"
          ]
        },
        {
          "id": 820201,
          "name": "เกาะยาวน้อย",
          "postCode": [
            "82160"
          ]
        },
        {
          "id": 820202,
          "name": "เกาะยาวใหญ่",
          "postCode": [
            "82160"
          ]
        }
      ]
    },
    {
      "city": "เมืองพังงา",
      "cityCode": 8201,
      "provinceCode": 82,
      "subDistrict": [
        {
          "id": 820105,
          "name": "ตากแดด",
          "postCode": [
            "82000"
          ]
        },
        {
          "id": 820103,
          "name": "ถ้ำน้ำผุด",
          "postCode": [
            "82000"
          ]
        },
        {
          "id": 820107,
          "name": "ทุ่งคาโงก",
          "postCode": [
            "82000"
          ]
        },
        {
          "id": 820101,
          "name": "ท้ายช้าง",
          "postCode": [
            "82000"
          ]
        },
        {
          "id": 820102,
          "name": "นบปริง",
          "postCode": [
            "82000"
          ]
        },
        {
          "id": 820104,
          "name": "บางเตย",
          "postCode": [
            "82000"
          ]
        },
        {
          "id": 820109,
          "name": "ป่ากอ",
          "postCode": [
            "82000"
          ]
        },
        {
          "id": 820106,
          "name": "สองแพรก",
          "postCode": [
            "82000"
          ]
        },
        {
          "id": 820108,
          "name": "เกาะปันหยี",
          "postCode": [
            "82000"
          ]
        }
      ]
    },
    {
      "city": "กงหรา",
      "cityCode": 9302,
      "provinceCode": 93,
      "subDistrict": [
        {
          "id": 930201,
          "name": "กงหรา",
          "postCode": [
            "93180"
          ]
        },
        {
          "id": 930204,
          "name": "คลองทรายขาว",
          "postCode": [
            "93180"
          ]
        },
        {
          "id": 930203,
          "name": "คลองเฉลิม",
          "postCode": [
            "93180"
          ]
        },
        {
          "id": 930202,
          "name": "ชะรัด",
          "postCode": [
            "93000"
          ]
        },
        {
          "id": 930205,
          "name": "สมหวัง",
          "postCode": [
            "93000"
          ]
        }
      ]
    },
    {
      "city": "ควนขนุน",
      "cityCode": 9305,
      "provinceCode": 93,
      "subDistrict": [
        {
          "id": 930501,
          "name": "ควนขนุน",
          "postCode": [
            "93110"
          ]
        },
        {
          "id": 930513,
          "name": "ชะมวง",
          "postCode": [
            "93110"
          ]
        },
        {
          "id": 930510,
          "name": "ดอนทราย",
          "postCode": [
            "93110"
          ]
        },
        {
          "id": 930502,
          "name": "ทะเลน้อย",
          "postCode": [
            "93150"
          ]
        },
        {
          "id": 930504,
          "name": "นาขยาด",
          "postCode": [
            "93110"
          ]
        },
        {
          "id": 930508,
          "name": "ปันแต",
          "postCode": [
            "93110"
          ]
        },
        {
          "id": 930505,
          "name": "พนมวังก์",
          "postCode": [
            "93110"
          ]
        },
        {
          "id": 930512,
          "name": "พนางตุง",
          "postCode": [
            "93150"
          ]
        },
        {
          "id": 930511,
          "name": "มะกอกเหนือ",
          "postCode": [
            "93150"
          ]
        },
        {
          "id": 930516,
          "name": "แพรกหา",
          "postCode": [
            "93110"
          ]
        },
        {
          "id": 930506,
          "name": "แหลมโตนด",
          "postCode": [
            "93110"
          ]
        },
        {
          "id": 930509,
          "name": "โตนดด้วน",
          "postCode": [
            "93110"
          ]
        }
      ]
    },
    {
      "city": "ตะโหมด",
      "cityCode": 9304,
      "provinceCode": 93,
      "subDistrict": [
        {
          "id": 930403,
          "name": "คลองใหญ่",
          "postCode": [
            "93160"
          ]
        },
        {
          "id": 930402,
          "name": "ตะโหมด",
          "postCode": [
            "93160"
          ]
        },
        {
          "id": 930401,
          "name": "แม่ขรี",
          "postCode": [
            "93160"
          ]
        }
      ]
    },
    {
      "city": "บางแก้ว",
      "cityCode": 9309,
      "provinceCode": 93,
      "subDistrict": [
        {
          "id": 930901,
          "name": "ท่ามะเดื่อ",
          "postCode": [
            "93140"
          ]
        },
        {
          "id": 930902,
          "name": "นาปะขอ",
          "postCode": [
            "93140"
          ]
        },
        {
          "id": 930903,
          "name": "โคกสัก",
          "postCode": [
            "93140",
            "93160"
          ]
        }
      ]
    },
    {
      "city": "ปากพะยูน",
      "cityCode": 9306,
      "provinceCode": 93,
      "subDistrict": [
        {
          "id": 930607,
          "name": "ดอนทราย",
          "postCode": [
            "93120"
          ]
        },
        {
          "id": 930602,
          "name": "ดอนประดู่",
          "postCode": [
            "93120"
          ]
        },
        {
          "id": 930601,
          "name": "ปากพะยูน",
          "postCode": [
            "93120"
          ]
        },
        {
          "id": 930605,
          "name": "ฝาละมี",
          "postCode": [
            "93120"
          ]
        },
        {
          "id": 930606,
          "name": "หารเทา",
          "postCode": [
            "93120"
          ]
        },
        {
          "id": 930603,
          "name": "เกาะนางคำ",
          "postCode": [
            "93120"
          ]
        },
        {
          "id": 930604,
          "name": "เกาะหมาก",
          "postCode": [
            "93120"
          ]
        }
      ]
    },
    {
      "city": "ป่าบอน",
      "cityCode": 9308,
      "provinceCode": 93,
      "subDistrict": [
        {
          "id": 930804,
          "name": "ทุ่งนารี",
          "postCode": [
            "93170"
          ]
        },
        {
          "id": 930801,
          "name": "ป่าบอน",
          "postCode": [
            "93170"
          ]
        },
        {
          "id": 930806,
          "name": "วังใหม่",
          "postCode": [
            "93170"
          ]
        },
        {
          "id": 930803,
          "name": "หนองธง",
          "postCode": [
            "93170"
          ]
        },
        {
          "id": 930802,
          "name": "โคกทราย",
          "postCode": [
            "93170"
          ]
        }
      ]
    },
    {
      "city": "ป่าพะยอม",
      "cityCode": 9310,
      "provinceCode": 93,
      "subDistrict": [
        {
          "id": 931004,
          "name": "บ้านพร้าว",
          "postCode": [
            "93210"
          ]
        },
        {
          "id": 931001,
          "name": "ป่าพะยอม",
          "postCode": [
            "93210"
          ]
        },
        {
          "id": 931002,
          "name": "ลานข่อย",
          "postCode": [
            "93210"
          ]
        },
        {
          "id": 931003,
          "name": "เกาะเต่า",
          "postCode": [
            "93210"
          ]
        }
      ]
    },
    {
      "city": "ศรีนครินทร์",
      "cityCode": 9311,
      "provinceCode": 93,
      "subDistrict": [
        {
          "id": 931101,
          "name": "ชุมพล",
          "postCode": [
            "93000"
          ]
        },
        {
          "id": 931102,
          "name": "บ้านนา",
          "postCode": [
            "93000"
          ]
        },
        {
          "id": 931104,
          "name": "ลำสินธุ์",
          "postCode": [
            "93000"
          ]
        },
        {
          "id": 931103,
          "name": "อ่างทอง",
          "postCode": [
            "93000"
          ]
        }
      ]
    },
    {
      "city": "ศรีบรรพต",
      "cityCode": 9307,
      "provinceCode": 93,
      "subDistrict": [
        {
          "id": 930703,
          "name": "ตะแพน",
          "postCode": [
            "93190"
          ]
        },
        {
          "id": 930702,
          "name": "เขาปู่",
          "postCode": [
            "93190"
          ]
        },
        {
          "id": 930701,
          "name": "เขาย่า",
          "postCode": [
            "93190"
          ]
        }
      ]
    },
    {
      "city": "เขาชัยสน",
      "cityCode": 9303,
      "provinceCode": 93,
      "subDistrict": [
        {
          "id": 930302,
          "name": "ควนขนุน",
          "postCode": [
            "93130"
          ]
        },
        {
          "id": 930305,
          "name": "จองถนน",
          "postCode": [
            "93130"
          ]
        },
        {
          "id": 930306,
          "name": "หานโพธิ์",
          "postCode": [
            "93130"
          ]
        },
        {
          "id": 930301,
          "name": "เขาชัยสน",
          "postCode": [
            "93130"
          ]
        },
        {
          "id": 930307,
          "name": "โคกม่วง",
          "postCode": [
            "93130"
          ]
        }
      ]
    },
    {
      "city": "เมืองพัทลุง",
      "cityCode": 9301,
      "provinceCode": 93,
      "subDistrict": [
        {
          "id": 930111,
          "name": "ควนมะพร้าว",
          "postCode": [
            "93000"
          ]
        },
        {
          "id": 930101,
          "name": "คูหาสวรรค์",
          "postCode": [
            "93000"
          ]
        },
        {
          "id": 930113,
          "name": "ชัยบุรี",
          "postCode": [
            "93000"
          ]
        },
        {
          "id": 930110,
          "name": "ตำนาน",
          "postCode": [
            "93000"
          ]
        },
        {
          "id": 930104,
          "name": "ท่ามิหรำ",
          "postCode": [
            "93000"
          ]
        },
        {
          "id": 930108,
          "name": "ท่าแค",
          "postCode": [
            "93000"
          ]
        },
        {
          "id": 930106,
          "name": "นาท่อม",
          "postCode": [
            "93000"
          ]
        },
        {
          "id": 930114,
          "name": "นาโหนด",
          "postCode": [
            "93000"
          ]
        },
        {
          "id": 930107,
          "name": "ปรางหมู่",
          "postCode": [
            "93000"
          ]
        },
        {
          "id": 930115,
          "name": "พญาขัน",
          "postCode": [
            "93000"
          ]
        },
        {
          "id": 930112,
          "name": "ร่มเมือง",
          "postCode": [
            "93000"
          ]
        },
        {
          "id": 930109,
          "name": "ลำปำ",
          "postCode": [
            "93000"
          ]
        },
        {
          "id": 930103,
          "name": "เขาเจียก",
          "postCode": [
            "93000"
          ]
        },
        {
          "id": 930105,
          "name": "โคกชะงาย",
          "postCode": [
            "93000"
          ]
        }
      ]
    },
    {
      "city": "ดงเจริญ",
      "cityCode": 6611,
      "provinceCode": 66,
      "subDistrict": [
        {
          "id": 661102,
          "name": "วังงิ้ว",
          "postCode": [
            "66210"
          ]
        },
        {
          "id": 661101,
          "name": "วังงิ้วใต้",
          "postCode": [
            "66210"
          ]
        },
        {
          "id": 661105,
          "name": "สำนักขุนเณร",
          "postCode": [
            "66210"
          ]
        },
        {
          "id": 661104,
          "name": "ห้วยพุก",
          "postCode": [
            "66210"
          ]
        },
        {
          "id": 661103,
          "name": "ห้วยร่วม",
          "postCode": [
            "66210"
          ]
        }
      ]
    },
    {
      "city": "ตะพานหิน",
      "cityCode": 6604,
      "provinceCode": 66,
      "subDistrict": [
        {
          "id": 660408,
          "name": "คลองคูณ",
          "postCode": [
            "66110"
          ]
        },
        {
          "id": 660402,
          "name": "งิ้วราย",
          "postCode": [
            "66110"
          ]
        },
        {
          "id": 660407,
          "name": "ดงตะขบ",
          "postCode": [
            "66110"
          ]
        },
        {
          "id": 660401,
          "name": "ตะพานหิน",
          "postCode": [
            "66110"
          ]
        },
        {
          "id": 660412,
          "name": "ทับหมัน",
          "postCode": [
            "66110"
          ]
        },
        {
          "id": 660406,
          "name": "ทุ่งโพธิ์",
          "postCode": [
            "66150"
          ]
        },
        {
          "id": 660409,
          "name": "วังสำโรง",
          "postCode": [
            "66110"
          ]
        },
        {
          "id": 660411,
          "name": "วังหลุม",
          "postCode": [
            "66150"
          ]
        },
        {
          "id": 660410,
          "name": "วังหว้า",
          "postCode": [
            "66110"
          ]
        },
        {
          "id": 660405,
          "name": "หนองพยอม",
          "postCode": [
            "66110"
          ]
        },
        {
          "id": 660403,
          "name": "ห้วยเกตุ",
          "postCode": [
            "66110"
          ]
        },
        {
          "id": 660404,
          "name": "ไทรโรงโขน",
          "postCode": [
            "66110"
          ]
        },
        {
          "id": 660413,
          "name": "ไผ่หลวง",
          "postCode": [
            "66110"
          ]
        }
      ]
    },
    {
      "city": "ทับคล้อ",
      "cityCode": 6608,
      "provinceCode": 66,
      "subDistrict": [
        {
          "id": 660801,
          "name": "ทับคล้อ",
          "postCode": [
            "66150"
          ]
        },
        {
          "id": 660804,
          "name": "ท้ายทุ่ง",
          "postCode": [
            "66150"
          ]
        },
        {
          "id": 660802,
          "name": "เขาทราย",
          "postCode": [
            "66230"
          ]
        },
        {
          "id": 660803,
          "name": "เขาเจ็ดลูก",
          "postCode": [
            "66230"
          ]
        }
      ]
    },
    {
      "city": "บางมูลนาก",
      "cityCode": 6605,
      "provinceCode": 66,
      "subDistrict": [
        {
          "id": 660501,
          "name": "บางมูลนาก",
          "postCode": [
            "66120"
          ]
        },
        {
          "id": 660502,
          "name": "บางไผ่",
          "postCode": [
            "66120"
          ]
        },
        {
          "id": 660506,
          "name": "ภูมิ",
          "postCode": [
            "66120"
          ]
        },
        {
          "id": 660514,
          "name": "ลำประดา",
          "postCode": [
            "66120"
          ]
        },
        {
          "id": 660507,
          "name": "วังกรด",
          "postCode": [
            "66120"
          ]
        },
        {
          "id": 660509,
          "name": "วังตะกู",
          "postCode": [
            "66210"
          ]
        },
        {
          "id": 660505,
          "name": "วังสำโรง",
          "postCode": [
            "66120"
          ]
        },
        {
          "id": 660503,
          "name": "หอไกร",
          "postCode": [
            "66120"
          ]
        },
        {
          "id": 660508,
          "name": "ห้วยเขน",
          "postCode": [
            "66120"
          ]
        },
        {
          "id": 660504,
          "name": "เนินมะกอก",
          "postCode": [
            "66120"
          ]
        }
      ]
    },
    {
      "city": "บึงนาราง",
      "cityCode": 6610,
      "provinceCode": 66,
      "subDistrict": [
        {
          "id": 661004,
          "name": "บางลาย",
          "postCode": [
            "66130"
          ]
        },
        {
          "id": 661005,
          "name": "บึงนาราง",
          "postCode": [
            "66130"
          ]
        },
        {
          "id": 661001,
          "name": "ห้วยแก้ว",
          "postCode": [
            "66130"
          ]
        },
        {
          "id": 661003,
          "name": "แหลมรัง",
          "postCode": [
            "66130"
          ]
        },
        {
          "id": 661002,
          "name": "โพธิ์ไทรงาม",
          "postCode": [
            "66130"
          ]
        }
      ]
    },
    {
      "city": "วชิรบารมี",
      "cityCode": 6612,
      "provinceCode": 66,
      "subDistrict": [
        {
          "id": 661202,
          "name": "บึงบัว",
          "postCode": [
            "66140"
          ]
        },
        {
          "id": 661201,
          "name": "บ้านนา",
          "postCode": [
            "66140"
          ]
        },
        {
          "id": 661203,
          "name": "วังโมกข์",
          "postCode": [
            "66140"
          ]
        },
        {
          "id": 661204,
          "name": "หนองหลุม",
          "postCode": [
            "66220"
          ]
        }
      ]
    },
    {
      "city": "วังทรายพูน",
      "cityCode": 6602,
      "provinceCode": 66,
      "subDistrict": [
        {
          "id": 660201,
          "name": "วังทรายพูน",
          "postCode": [
            "66180"
          ]
        },
        {
          "id": 660202,
          "name": "หนองปลาไหล",
          "postCode": [
            "66180"
          ]
        },
        {
          "id": 660204,
          "name": "หนองปล้อง",
          "postCode": [
            "66180"
          ]
        },
        {
          "id": 660203,
          "name": "หนองพระ",
          "postCode": [
            "66180"
          ]
        }
      ]
    },
    {
      "city": "สากเหล็ก",
      "cityCode": 6609,
      "provinceCode": 66,
      "subDistrict": [
        {
          "id": 660903,
          "name": "คลองทราย",
          "postCode": [
            "66160"
          ]
        },
        {
          "id": 660902,
          "name": "ท่าเยี่ยม",
          "postCode": [
            "66160"
          ]
        },
        {
          "id": 660905,
          "name": "วังทับไทร",
          "postCode": [
            "66160"
          ]
        },
        {
          "id": 660901,
          "name": "สากเหล็ก",
          "postCode": [
            "66160"
          ]
        },
        {
          "id": 660904,
          "name": "หนองหญ้าไทร",
          "postCode": [
            "66160"
          ]
        }
      ]
    },
    {
      "city": "สามง่าม",
      "cityCode": 6607,
      "provinceCode": 66,
      "subDistrict": [
        {
          "id": 660702,
          "name": "กำแพงดิน",
          "postCode": [
            "66220"
          ]
        },
        {
          "id": 660703,
          "name": "รังนก",
          "postCode": [
            "66140"
          ]
        },
        {
          "id": 660701,
          "name": "สามง่าม",
          "postCode": [
            "66140"
          ]
        },
        {
          "id": 660707,
          "name": "หนองโสน",
          "postCode": [
            "66140"
          ]
        },
        {
          "id": 660706,
          "name": "เนินปอ",
          "postCode": [
            "66140"
          ]
        }
      ]
    },
    {
      "city": "เมืองพิจิตร",
      "cityCode": 6601,
      "provinceCode": 66,
      "subDistrict": [
        {
          "id": 660106,
          "name": "คลองคะเชนทร์",
          "postCode": [
            "66000"
          ]
        },
        {
          "id": 660111,
          "name": "ฆะมัง",
          "postCode": [
            "66000",
            "66170"
          ]
        },
        {
          "id": 660120,
          "name": "ดงกลาง",
          "postCode": [
            "66170"
          ]
        },
        {
          "id": 660112,
          "name": "ดงป่าคำ",
          "postCode": [
            "66170"
          ]
        },
        {
          "id": 660109,
          "name": "ท่าหลวง",
          "postCode": [
            "66000"
          ]
        },
        {
          "id": 660104,
          "name": "ท่าฬ่อ",
          "postCode": [
            "66000"
          ]
        },
        {
          "id": 660110,
          "name": "บ้านบุ่ง",
          "postCode": [
            "66000"
          ]
        },
        {
          "id": 660105,
          "name": "ปากทาง",
          "postCode": [
            "66000"
          ]
        },
        {
          "id": 660115,
          "name": "ป่ามะคาบ",
          "postCode": [
            "66000"
          ]
        },
        {
          "id": 660103,
          "name": "ย่านยาว",
          "postCode": [
            "66000"
          ]
        },
        {
          "id": 660119,
          "name": "สายคำโห้",
          "postCode": [
            "66000"
          ]
        },
        {
          "id": 660113,
          "name": "หัวดง",
          "postCode": [
            "66170"
          ]
        },
        {
          "id": 660108,
          "name": "เมืองเก่า",
          "postCode": [
            "66000"
          ]
        },
        {
          "id": 660107,
          "name": "โรงช้าง",
          "postCode": [
            "66000"
          ]
        },
        {
          "id": 660101,
          "name": "ในเมือง",
          "postCode": [
            "66000"
          ]
        },
        {
          "id": 660102,
          "name": "ไผ่ขวาง",
          "postCode": [
            "66000"
          ]
        }
      ]
    },
    {
      "city": "โพทะเล",
      "cityCode": 6606,
      "provinceCode": 66,
      "subDistrict": [
        {
          "id": 660603,
          "name": "ทะนง",
          "postCode": [
            "66130"
          ]
        },
        {
          "id": 660605,
          "name": "ทุ่งน้อย",
          "postCode": [
            "66130"
          ]
        },
        {
          "id": 660606,
          "name": "ท่าขมิ้น",
          "postCode": [
            "66130"
          ]
        },
        {
          "id": 660611,
          "name": "ท่านั่ง",
          "postCode": [
            "66130"
          ]
        },
        {
          "id": 660604,
          "name": "ท่าบัว",
          "postCode": [
            "66130"
          ]
        },
        {
          "id": 660607,
          "name": "ท่าเสา",
          "postCode": [
            "66130"
          ]
        },
        {
          "id": 660602,
          "name": "ท้ายน้ำ",
          "postCode": [
            "66130"
          ]
        },
        {
          "id": 660608,
          "name": "บางคลาน",
          "postCode": [
            "66130"
          ]
        },
        {
          "id": 660612,
          "name": "บ้านน้อย",
          "postCode": [
            "66130"
          ]
        },
        {
          "id": 660613,
          "name": "วัดขวาง",
          "postCode": [
            "66130"
          ]
        },
        {
          "id": 660601,
          "name": "โพทะเล",
          "postCode": [
            "66130"
          ]
        }
      ]
    },
    {
      "city": "โพธิ์ประทับช้าง",
      "cityCode": 6603,
      "provinceCode": 66,
      "subDistrict": [
        {
          "id": 660305,
          "name": "ดงเสือเหลือง",
          "postCode": [
            "66190"
          ]
        },
        {
          "id": 660307,
          "name": "ทุ่งใหญ่",
          "postCode": [
            "66190"
          ]
        },
        {
          "id": 660303,
          "name": "วังจิก",
          "postCode": [
            "66190"
          ]
        },
        {
          "id": 660306,
          "name": "เนินสว่าง",
          "postCode": [
            "66190"
          ]
        },
        {
          "id": 660301,
          "name": "โพธิ์ประทับช้าง",
          "postCode": [
            "66190"
          ]
        },
        {
          "id": 660302,
          "name": "ไผ่ท่าโพ",
          "postCode": [
            "66190"
          ]
        },
        {
          "id": 660304,
          "name": "ไผ่รอบ",
          "postCode": [
            "66190"
          ]
        }
      ]
    },
    {
      "city": "ชาติตระการ",
      "cityCode": 6503,
      "provinceCode": 65,
      "subDistrict": [
        {
          "id": 650302,
          "name": "ชาติตระการ",
          "postCode": [
            "65170"
          ]
        },
        {
          "id": 650306,
          "name": "ท่าสะแก",
          "postCode": [
            "65170"
          ]
        },
        {
          "id": 650305,
          "name": "บ่อภาค",
          "postCode": [
            "65170"
          ]
        },
        {
          "id": 650304,
          "name": "บ้านดง",
          "postCode": [
            "65170"
          ]
        },
        {
          "id": 650301,
          "name": "ป่าแดง",
          "postCode": [
            "65170"
          ]
        },
        {
          "id": 650303,
          "name": "สวนเมี่ยง",
          "postCode": [
            "65170"
          ]
        }
      ]
    },
    {
      "city": "นครไทย",
      "cityCode": 6502,
      "provinceCode": 65,
      "subDistrict": [
        {
          "id": 650206,
          "name": "นครชุม",
          "postCode": [
            "65120"
          ]
        },
        {
          "id": 650201,
          "name": "นครไทย",
          "postCode": [
            "65120"
          ]
        },
        {
          "id": 650205,
          "name": "นาบัว",
          "postCode": [
            "65120"
          ]
        },
        {
          "id": 650207,
          "name": "น้ำกุ่ม",
          "postCode": [
            "65120"
          ]
        },
        {
          "id": 650209,
          "name": "บ่อโพธิ์",
          "postCode": [
            "65120"
          ]
        },
        {
          "id": 650210,
          "name": "บ้านพร้าว",
          "postCode": [
            "65120"
          ]
        },
        {
          "id": 650203,
          "name": "บ้านแยง",
          "postCode": [
            "65120"
          ]
        },
        {
          "id": 650208,
          "name": "ยางโกลน",
          "postCode": [
            "65120"
          ]
        },
        {
          "id": 650202,
          "name": "หนองกะท้าว",
          "postCode": [
            "65120"
          ]
        },
        {
          "id": 650211,
          "name": "ห้วยเฮี้ย",
          "postCode": [
            "65120"
          ]
        },
        {
          "id": 650204,
          "name": "เนินเพิ่ม",
          "postCode": [
            "65120"
          ]
        }
      ]
    },
    {
      "city": "บางกระทุ่ม",
      "cityCode": 6505,
      "provinceCode": 65,
      "subDistrict": [
        {
          "id": 650505,
          "name": "ท่าตาล",
          "postCode": [
            "65110"
          ]
        },
        {
          "id": 650507,
          "name": "นครป่าหมาก",
          "postCode": [
            "65110"
          ]
        },
        {
          "id": 650501,
          "name": "บางกระทุ่ม",
          "postCode": [
            "65110"
          ]
        },
        {
          "id": 650502,
          "name": "บ้านไร่",
          "postCode": [
            "65110"
          ]
        },
        {
          "id": 650509,
          "name": "วัดตายม",
          "postCode": [
            "65210"
          ]
        },
        {
          "id": 650504,
          "name": "สนามคลี",
          "postCode": [
            "65110"
          ]
        },
        {
          "id": 650508,
          "name": "เนินกุ่ม",
          "postCode": [
            "65210"
          ]
        },
        {
          "id": 650503,
          "name": "โคกสลุด",
          "postCode": [
            "65110"
          ]
        },
        {
          "id": 650506,
          "name": "ไผ่ล้อม",
          "postCode": [
            "65110"
          ]
        }
      ]
    },
    {
      "city": "บางระกำ",
      "cityCode": 6504,
      "provinceCode": 65,
      "subDistrict": [
        {
          "id": 650411,
          "name": "คุยม่วง",
          "postCode": [
            "65240"
          ]
        },
        {
          "id": 650407,
          "name": "ชุมแสงสงคราม",
          "postCode": [
            "65240"
          ]
        },
        {
          "id": 650410,
          "name": "ท่านางงาม",
          "postCode": [
            "65140"
          ]
        },
        {
          "id": 650408,
          "name": "นิคมพัฒนา",
          "postCode": [
            "65140"
          ]
        },
        {
          "id": 650401,
          "name": "บางระกำ",
          "postCode": [
            "65140"
          ]
        },
        {
          "id": 650405,
          "name": "บึงกอก",
          "postCode": [
            "65140"
          ]
        },
        {
          "id": 650409,
          "name": "บ่อทอง",
          "postCode": [
            "65140"
          ]
        },
        {
          "id": 650402,
          "name": "ปลักแรด",
          "postCode": [
            "65140"
          ]
        },
        {
          "id": 650403,
          "name": "พันเสา",
          "postCode": [
            "65140"
          ]
        },
        {
          "id": 650404,
          "name": "วังอิทก",
          "postCode": [
            "65140"
          ]
        },
        {
          "id": 650406,
          "name": "หนองกุลา",
          "postCode": [
            "65140"
          ]
        }
      ]
    },
    {
      "city": "พรหมพิราม",
      "cityCode": 6506,
      "provinceCode": 65,
      "subDistrict": [
        {
          "id": 650612,
          "name": "ดงประคำ",
          "postCode": [
            "65180"
          ]
        },
        {
          "id": 650607,
          "name": "ตลุกเทียม",
          "postCode": [
            "65180"
          ]
        },
        {
          "id": 650611,
          "name": "ทับยายเชียง",
          "postCode": [
            "65150"
          ]
        },
        {
          "id": 650602,
          "name": "ท่าช้าง",
          "postCode": [
            "65150"
          ]
        },
        {
          "id": 650601,
          "name": "พรหมพิราม",
          "postCode": [
            "65150"
          ]
        },
        {
          "id": 650604,
          "name": "มะตูม",
          "postCode": [
            "65150"
          ]
        },
        {
          "id": 650610,
          "name": "มะต้อง",
          "postCode": [
            "65180"
          ]
        },
        {
          "id": 650603,
          "name": "วงฆ้อง",
          "postCode": [
            "65180"
          ]
        },
        {
          "id": 650608,
          "name": "วังวน",
          "postCode": [
            "65150"
          ]
        },
        {
          "id": 650606,
          "name": "ศรีภิรมย์",
          "postCode": [
            "65180"
          ]
        },
        {
          "id": 650609,
          "name": "หนองแขม",
          "postCode": [
            "65150"
          ]
        },
        {
          "id": 650605,
          "name": "หอกลอง",
          "postCode": [
            "65150"
          ]
        }
      ]
    },
    {
      "city": "วังทอง",
      "cityCode": 6508,
      "provinceCode": 65,
      "subDistrict": [
        {
          "id": 650810,
          "name": "ชัยนาม",
          "postCode": [
            "65130"
          ]
        },
        {
          "id": 650811,
          "name": "ดินทอง",
          "postCode": [
            "65130"
          ]
        },
        {
          "id": 650807,
          "name": "ท่าหมื่นราม",
          "postCode": [
            "65130"
          ]
        },
        {
          "id": 650804,
          "name": "บ้านกลาง",
          "postCode": [
            "65220"
          ]
        },
        {
          "id": 650802,
          "name": "พันชาลี",
          "postCode": [
            "65130"
          ]
        },
        {
          "id": 650801,
          "name": "วังทอง",
          "postCode": [
            "65130"
          ]
        },
        {
          "id": 650808,
          "name": "วังนกแอ่น",
          "postCode": [
            "65130"
          ]
        },
        {
          "id": 650805,
          "name": "วังพิกุล",
          "postCode": [
            "65130"
          ]
        },
        {
          "id": 650809,
          "name": "หนองพระ",
          "postCode": [
            "65130"
          ]
        },
        {
          "id": 650806,
          "name": "แก่งโสภา",
          "postCode": [
            "65220"
          ]
        },
        {
          "id": 650803,
          "name": "แม่ระกา",
          "postCode": [
            "65130"
          ]
        }
      ]
    },
    {
      "city": "วัดโบสถ์",
      "cityCode": 6507,
      "provinceCode": 65,
      "subDistrict": [
        {
          "id": 650706,
          "name": "คันโช้ง",
          "postCode": [
            "65160"
          ]
        },
        {
          "id": 650702,
          "name": "ท่างาม",
          "postCode": [
            "65160"
          ]
        },
        {
          "id": 650703,
          "name": "ท้อแท้",
          "postCode": [
            "65160"
          ]
        },
        {
          "id": 650704,
          "name": "บ้านยาง",
          "postCode": [
            "65160"
          ]
        },
        {
          "id": 650701,
          "name": "วัดโบสถ์",
          "postCode": [
            "65160"
          ]
        },
        {
          "id": 650705,
          "name": "หินลาด",
          "postCode": [
            "65160"
          ]
        }
      ]
    },
    {
      "city": "เนินมะปราง",
      "cityCode": 6509,
      "provinceCode": 65,
      "subDistrict": [
        {
          "id": 650901,
          "name": "ชมพู",
          "postCode": [
            "65190"
          ]
        },
        {
          "id": 650905,
          "name": "บ้านน้อยซุ้มขี้เหล็ก",
          "postCode": [
            "65190"
          ]
        },
        {
          "id": 650902,
          "name": "บ้านมุง",
          "postCode": [
            "65190"
          ]
        },
        {
          "id": 650907,
          "name": "วังยาง",
          "postCode": [
            "65190"
          ]
        },
        {
          "id": 650904,
          "name": "วังโพรง",
          "postCode": [
            "65190"
          ]
        },
        {
          "id": 650906,
          "name": "เนินมะปราง",
          "postCode": [
            "65190"
          ]
        },
        {
          "id": 650903,
          "name": "ไทรย้อย",
          "postCode": [
            "65190"
          ]
        }
      ]
    },
    {
      "city": "เมืองพิษณุโลก",
      "cityCode": 6501,
      "provinceCode": 65,
      "subDistrict": [
        {
          "id": 650120,
          "name": "งิ้วงาม",
          "postCode": [
            "65230"
          ]
        },
        {
          "id": 650112,
          "name": "จอมทอง",
          "postCode": [
            "65000"
          ]
        },
        {
          "id": 650108,
          "name": "ดอนทอง",
          "postCode": [
            "65000"
          ]
        },
        {
          "id": 650105,
          "name": "ท่าทอง",
          "postCode": [
            "65000"
          ]
        },
        {
          "id": 650106,
          "name": "ท่าโพธิ์",
          "postCode": [
            "65000"
          ]
        },
        {
          "id": 650118,
          "name": "บึงพระ",
          "postCode": [
            "65000"
          ]
        },
        {
          "id": 650113,
          "name": "บ้านกร่าง",
          "postCode": [
            "65000"
          ]
        },
        {
          "id": 650114,
          "name": "บ้านคลอง",
          "postCode": [
            "65000"
          ]
        },
        {
          "id": 650109,
          "name": "บ้านป่า",
          "postCode": [
            "65000"
          ]
        },
        {
          "id": 650110,
          "name": "ปากโทก",
          "postCode": [
            "65000"
          ]
        },
        {
          "id": 650115,
          "name": "พลายชุมพล",
          "postCode": [
            "65000"
          ]
        },
        {
          "id": 650116,
          "name": "มะขามสูง",
          "postCode": [
            "65000"
          ]
        },
        {
          "id": 650102,
          "name": "วังน้ำคู้",
          "postCode": [
            "65230"
          ]
        },
        {
          "id": 650103,
          "name": "วัดจันทร์",
          "postCode": [
            "65000"
          ]
        },
        {
          "id": 650104,
          "name": "วัดพริก",
          "postCode": [
            "65230"
          ]
        },
        {
          "id": 650107,
          "name": "สมอแข",
          "postCode": [
            "65000"
          ]
        },
        {
          "id": 650111,
          "name": "หัวรอ",
          "postCode": [
            "65000"
          ]
        },
        {
          "id": 650117,
          "name": "อรัญญิก",
          "postCode": [
            "65000"
          ]
        },
        {
          "id": 650101,
          "name": "ในเมือง",
          "postCode": [
            "65000"
          ]
        },
        {
          "id": 650119,
          "name": "ไผ่ขอดอน",
          "postCode": [
            "65000"
          ]
        }
      ]
    },
    {
      "city": "กะทู้",
      "cityCode": 8302,
      "provinceCode": 83,
      "subDistrict": [
        {
          "id": 830203,
          "name": "กมลา",
          "postCode": [
            "83150"
          ]
        },
        {
          "id": 830201,
          "name": "กะทู้",
          "postCode": [
            "83120"
          ]
        },
        {
          "id": 830202,
          "name": "ป่าตอง",
          "postCode": [
            "83150"
          ]
        }
      ]
    },
    {
      "city": "ถลาง",
      "cityCode": 8303,
      "provinceCode": 83,
      "subDistrict": [
        {
          "id": 830304,
          "name": "ป่าคลอก",
          "postCode": [
            "83110"
          ]
        },
        {
          "id": 830302,
          "name": "ศรีสุนทร",
          "postCode": [
            "83110"
          ]
        },
        {
          "id": 830306,
          "name": "สาคู",
          "postCode": [
            "83110"
          ]
        },
        {
          "id": 830303,
          "name": "เชิงทะเล",
          "postCode": [
            "83110"
          ]
        },
        {
          "id": 830301,
          "name": "เทพกระษัตรี",
          "postCode": [
            "83110"
          ]
        },
        {
          "id": 830305,
          "name": "ไม้ขาว",
          "postCode": [
            "83110"
          ]
        }
      ]
    },
    {
      "city": "เมืองภูเก็ต",
      "cityCode": 8301,
      "provinceCode": 83,
      "subDistrict": [
        {
          "id": 830108,
          "name": "กะรน",
          "postCode": [
            "83100"
          ]
        },
        {
          "id": 830106,
          "name": "ฉลอง",
          "postCode": [
            "83130"
          ]
        },
        {
          "id": 830102,
          "name": "ตลาดเหนือ",
          "postCode": [
            "83000"
          ]
        },
        {
          "id": 830101,
          "name": "ตลาดใหญ่",
          "postCode": [
            "83000"
          ]
        },
        {
          "id": 830104,
          "name": "รัษฎา",
          "postCode": [
            "83000"
          ]
        },
        {
          "id": 830107,
          "name": "ราไวย์",
          "postCode": [
            "83130"
          ]
        },
        {
          "id": 830105,
          "name": "วิชิต",
          "postCode": [
            "83000"
          ]
        },
        {
          "id": 830103,
          "name": "เกาะแก้ว",
          "postCode": [
            "83000"
          ]
        }
      ]
    },
    {
      "city": "กันทรวิชัย",
      "cityCode": 4404,
      "provinceCode": 44,
      "subDistrict": [
        {
          "id": 440409,
          "name": "กุดใส้จ่อ",
          "postCode": [
            "44150"
          ]
        },
        {
          "id": 440410,
          "name": "ขามเฒ่าพัฒนา",
          "postCode": [
            "44150"
          ]
        },
        {
          "id": 440406,
          "name": "ขามเรียง",
          "postCode": [
            "44150"
          ]
        },
        {
          "id": 440402,
          "name": "คันธารราษฎร์",
          "postCode": [
            "44150"
          ]
        },
        {
          "id": 440404,
          "name": "ท่าขอนยาง",
          "postCode": [
            "44150"
          ]
        },
        {
          "id": 440405,
          "name": "นาสีนวน",
          "postCode": [
            "44150"
          ]
        },
        {
          "id": 440403,
          "name": "มะค่า",
          "postCode": [
            "44150"
          ]
        },
        {
          "id": 440408,
          "name": "ศรีสุข",
          "postCode": [
            "44150"
          ]
        },
        {
          "id": 440407,
          "name": "เขวาใหญ่",
          "postCode": [
            "44150"
          ]
        },
        {
          "id": 440401,
          "name": "โคกพระ",
          "postCode": [
            "44150"
          ]
        }
      ]
    },
    {
      "city": "กุดรัง",
      "cityCode": 4412,
      "provinceCode": 44,
      "subDistrict": [
        {
          "id": 441201,
          "name": "กุดรัง",
          "postCode": [
            "44130"
          ]
        },
        {
          "id": 441202,
          "name": "นาโพธิ์",
          "postCode": [
            "44130"
          ]
        },
        {
          "id": 441204,
          "name": "หนองแวง",
          "postCode": [
            "44130"
          ]
        },
        {
          "id": 441205,
          "name": "ห้วยเตย",
          "postCode": [
            "44130"
          ]
        },
        {
          "id": 441203,
          "name": "เลิงแฝก",
          "postCode": [
            "44130"
          ]
        }
      ]
    },
    {
      "city": "ชื่นชม",
      "cityCode": 4413,
      "provinceCode": 44,
      "subDistrict": [
        {
          "id": 441302,
          "name": "กุดปลาดุก",
          "postCode": [
            "44160"
          ]
        },
        {
          "id": 441301,
          "name": "ชื่นชม",
          "postCode": [
            "44160"
          ]
        },
        {
          "id": 441304,
          "name": "หนองกุง",
          "postCode": [
            "44160"
          ]
        },
        {
          "id": 441303,
          "name": "เหล่าดอกไม้",
          "postCode": [
            "44160"
          ]
        }
      ]
    },
    {
      "city": "นาดูน",
      "cityCode": 4410,
      "provinceCode": 44,
      "subDistrict": [
        {
          "id": 441008,
          "name": "กู่สันตรัตน์",
          "postCode": [
            "44180"
          ]
        },
        {
          "id": 441005,
          "name": "ดงดวน",
          "postCode": [
            "44180"
          ]
        },
        {
          "id": 441004,
          "name": "ดงบัง",
          "postCode": [
            "44180"
          ]
        },
        {
          "id": 441007,
          "name": "ดงยาง",
          "postCode": [
            "44180"
          ]
        },
        {
          "id": 441001,
          "name": "นาดูน",
          "postCode": [
            "44180"
          ]
        },
        {
          "id": 441009,
          "name": "พระธาตุ",
          "postCode": [
            "44180"
          ]
        },
        {
          "id": 441003,
          "name": "หนองคู",
          "postCode": [
            "44180"
          ]
        },
        {
          "id": 441002,
          "name": "หนองไผ่",
          "postCode": [
            "44180"
          ]
        },
        {
          "id": 441006,
          "name": "หัวดง",
          "postCode": [
            "44180"
          ]
        }
      ]
    },
    {
      "city": "นาเชือก",
      "cityCode": 4407,
      "provinceCode": 44,
      "subDistrict": [
        {
          "id": 440701,
          "name": "นาเชือก",
          "postCode": [
            "44170"
          ]
        },
        {
          "id": 440706,
          "name": "ปอพาน",
          "postCode": [
            "44170"
          ]
        },
        {
          "id": 440710,
          "name": "สันป่าตอง",
          "postCode": [
            "44170"
          ]
        },
        {
          "id": 440702,
          "name": "สำโรง",
          "postCode": [
            "44170"
          ]
        },
        {
          "id": 440709,
          "name": "หนองกุง",
          "postCode": [
            "44170"
          ]
        },
        {
          "id": 440707,
          "name": "หนองเม็ก",
          "postCode": [
            "44170"
          ]
        },
        {
          "id": 440708,
          "name": "หนองเรือ",
          "postCode": [
            "44170"
          ]
        },
        {
          "id": 440703,
          "name": "หนองแดง",
          "postCode": [
            "44170"
          ]
        },
        {
          "id": 440705,
          "name": "หนองโพธิ์",
          "postCode": [
            "44170"
          ]
        },
        {
          "id": 440704,
          "name": "เขวาไร่",
          "postCode": [
            "44170"
          ]
        }
      ]
    },
    {
      "city": "บรบือ",
      "cityCode": 4406,
      "provinceCode": 44,
      "subDistrict": [
        {
          "id": 440606,
          "name": "กำพี้",
          "postCode": [
            "44130"
          ]
        },
        {
          "id": 440620,
          "name": "ดอนงัว",
          "postCode": [
            "44130"
          ]
        },
        {
          "id": 440601,
          "name": "บรบือ",
          "postCode": [
            "44130"
          ]
        },
        {
          "id": 440611,
          "name": "บัวมาศ",
          "postCode": [
            "44130"
          ]
        },
        {
          "id": 440602,
          "name": "บ่อใหญ่",
          "postCode": [
            "44130"
          ]
        },
        {
          "id": 440616,
          "name": "ยาง",
          "postCode": [
            "44130"
          ]
        },
        {
          "id": 440615,
          "name": "วังใหม่",
          "postCode": [
            "44130"
          ]
        },
        {
          "id": 440604,
          "name": "วังไชย",
          "postCode": [
            "44130"
          ]
        },
        {
          "id": 440613,
          "name": "หนองคูขาด",
          "postCode": [
            "44130"
          ]
        },
        {
          "id": 440610,
          "name": "หนองจิก",
          "postCode": [
            "44130"
          ]
        },
        {
          "id": 440605,
          "name": "หนองม่วง",
          "postCode": [
            "44130"
          ]
        },
        {
          "id": 440618,
          "name": "หนองสิม",
          "postCode": [
            "44130"
          ]
        },
        {
          "id": 440619,
          "name": "หนองโก",
          "postCode": [
            "44130"
          ]
        },
        {
          "id": 440607,
          "name": "โนนราษี",
          "postCode": [
            "44130"
          ]
        },
        {
          "id": 440608,
          "name": "โนนแดง",
          "postCode": [
            "44130"
          ]
        }
      ]
    },
    {
      "city": "พยัคฆภูมิพิสัย",
      "cityCode": 4408,
      "provinceCode": 44,
      "subDistrict": [
        {
          "id": 440802,
          "name": "ก้ามปู",
          "postCode": [
            "44110"
          ]
        },
        {
          "id": 440805,
          "name": "นาสีนวล",
          "postCode": [
            "44110"
          ]
        },
        {
          "id": 440801,
          "name": "ปะหลาน",
          "postCode": [
            "44110"
          ]
        },
        {
          "id": 440820,
          "name": "ภารแอ่น",
          "postCode": [
            "44110"
          ]
        },
        {
          "id": 440818,
          "name": "ราษฎร์พัฒนา",
          "postCode": [
            "44110"
          ]
        },
        {
          "id": 440809,
          "name": "ราษฎร์เจริญ",
          "postCode": [
            "44110"
          ]
        },
        {
          "id": 440815,
          "name": "ลานสะแก",
          "postCode": [
            "44110"
          ]
        },
        {
          "id": 440817,
          "name": "หนองบัว",
          "postCode": [
            "44110"
          ]
        },
        {
          "id": 440810,
          "name": "หนองบัวแก้ว",
          "postCode": [
            "44110"
          ]
        },
        {
          "id": 440812,
          "name": "เมืองเตา",
          "postCode": [
            "44110"
          ]
        },
        {
          "id": 440819,
          "name": "เมืองเสือ",
          "postCode": [
            "44110"
          ]
        },
        {
          "id": 440804,
          "name": "เม็กดำ",
          "postCode": [
            "44110"
          ]
        },
        {
          "id": 440816,
          "name": "เวียงชัย",
          "postCode": [
            "44110"
          ]
        },
        {
          "id": 440803,
          "name": "เวียงสะอาด",
          "postCode": [
            "44110"
          ]
        }
      ]
    },
    {
      "city": "ยางสีสุราช",
      "cityCode": 4411,
      "provinceCode": 44,
      "subDistrict": [
        {
          "id": 441105,
          "name": "ดงเมือง",
          "postCode": [
            "44210"
          ]
        },
        {
          "id": 441102,
          "name": "นาภู",
          "postCode": [
            "44210"
          ]
        },
        {
          "id": 441104,
          "name": "บ้านกู่",
          "postCode": [
            "44210"
          ]
        },
        {
          "id": 441101,
          "name": "ยางสีสุราช",
          "postCode": [
            "44210"
          ]
        },
        {
          "id": 441106,
          "name": "สร้างแซ่ง",
          "postCode": [
            "44210"
          ]
        },
        {
          "id": 441107,
          "name": "หนองบัวสันตุ",
          "postCode": [
            "44210"
          ]
        },
        {
          "id": 441103,
          "name": "แวงดง",
          "postCode": [
            "44210"
          ]
        }
      ]
    },
    {
      "city": "วาปีปทุม",
      "cityCode": 4409,
      "provinceCode": 44,
      "subDistrict": [
        {
          "id": 440902,
          "name": "ขามป้อม",
          "postCode": [
            "44120"
          ]
        },
        {
          "id": 440908,
          "name": "งัวบา",
          "postCode": [
            "44120"
          ]
        },
        {
          "id": 440904,
          "name": "ดงใหญ่",
          "postCode": [
            "44120"
          ]
        },
        {
          "id": 440909,
          "name": "นาข่า",
          "postCode": [
            "44120"
          ]
        },
        {
          "id": 440910,
          "name": "บ้านหวาย",
          "postCode": [
            "44120"
          ]
        },
        {
          "id": 440912,
          "name": "ประชาพัฒนา",
          "postCode": [
            "44120"
          ]
        },
        {
          "id": 440913,
          "name": "หนองทุ่ม",
          "postCode": [
            "44120"
          ]
        },
        {
          "id": 440901,
          "name": "หนองแสง",
          "postCode": [
            "44120"
          ]
        },
        {
          "id": 440914,
          "name": "หนองแสน",
          "postCode": [
            "44120"
          ]
        },
        {
          "id": 440911,
          "name": "หนองไฮ",
          "postCode": [
            "44120"
          ]
        },
        {
          "id": 440906,
          "name": "หัวเรือ",
          "postCode": [
            "44120"
          ]
        },
        {
          "id": 440903,
          "name": "เสือโก้ก",
          "postCode": [
            "44120"
          ]
        },
        {
          "id": 440907,
          "name": "แคน",
          "postCode": [
            "44120"
          ]
        },
        {
          "id": 440915,
          "name": "โคกสีทองหลาง",
          "postCode": [
            "44120"
          ]
        },
        {
          "id": 440905,
          "name": "โพธิ์ชัย",
          "postCode": [
            "44120"
          ]
        }
      ]
    },
    {
      "city": "เชียงยืน",
      "cityCode": 4405,
      "provinceCode": 44,
      "subDistrict": [
        {
          "id": 440506,
          "name": "กู่ทอง",
          "postCode": [
            "44160"
          ]
        },
        {
          "id": 440505,
          "name": "ดอนเงิน",
          "postCode": [
            "44160"
          ]
        },
        {
          "id": 440507,
          "name": "นาทอง",
          "postCode": [
            "44160"
          ]
        },
        {
          "id": 440503,
          "name": "หนองซอน",
          "postCode": [
            "44160"
          ]
        },
        {
          "id": 440501,
          "name": "เชียงยืน",
          "postCode": [
            "44160"
          ]
        },
        {
          "id": 440508,
          "name": "เสือเฒ่า",
          "postCode": [
            "44160"
          ]
        },
        {
          "id": 440512,
          "name": "เหล่าบัวบาน",
          "postCode": [
            "44160"
          ]
        },
        {
          "id": 440511,
          "name": "โพนทอง",
          "postCode": [
            "44160"
          ]
        }
      ]
    },
    {
      "city": "เมืองมหาสารคาม",
      "cityCode": 4401,
      "provinceCode": 44,
      "subDistrict": [
        {
          "id": 440106,
          "name": "ดอนหว่าน",
          "postCode": [
            "44000"
          ]
        },
        {
          "id": 440101,
          "name": "ตลาด",
          "postCode": [
            "44000"
          ]
        },
        {
          "id": 440103,
          "name": "ท่าตูม",
          "postCode": [
            "44000"
          ]
        },
        {
          "id": 440109,
          "name": "ท่าสองคอน",
          "postCode": [
            "44000"
          ]
        },
        {
          "id": 440114,
          "name": "บัวค้อ",
          "postCode": [
            "44000"
          ]
        },
        {
          "id": 440110,
          "name": "ลาดพัฒนา",
          "postCode": [
            "44000"
          ]
        },
        {
          "id": 440111,
          "name": "หนองปลิง",
          "postCode": [
            "44000"
          ]
        },
        {
          "id": 440113,
          "name": "หนองโน",
          "postCode": [
            "44000"
          ]
        },
        {
          "id": 440112,
          "name": "ห้วยแอ่ง",
          "postCode": [
            "44000"
          ]
        },
        {
          "id": 440107,
          "name": "เกิ้ง",
          "postCode": [
            "44000"
          ]
        },
        {
          "id": 440102,
          "name": "เขวา",
          "postCode": [
            "44000"
          ]
        },
        {
          "id": 440108,
          "name": "แก่งเลิงจาน",
          "postCode": [
            "44000"
          ]
        },
        {
          "id": 440104,
          "name": "แวงน่าง",
          "postCode": [
            "44000"
          ]
        },
        {
          "id": 440105,
          "name": "โคกก่อ",
          "postCode": [
            "44000"
          ]
        }
      ]
    },
    {
      "city": "แกดำ",
      "cityCode": 4402,
      "provinceCode": 44,
      "subDistrict": [
        {
          "id": 440203,
          "name": "มิตรภาพ",
          "postCode": [
            "44190"
          ]
        },
        {
          "id": 440202,
          "name": "วังแสง",
          "postCode": [
            "44190"
          ]
        },
        {
          "id": 440204,
          "name": "หนองกุง",
          "postCode": [
            "44190"
          ]
        },
        {
          "id": 440201,
          "name": "แกดำ",
          "postCode": [
            "44190"
          ]
        },
        {
          "id": 440205,
          "name": "โนนภิบาล",
          "postCode": [
            "44190"
          ]
        }
      ]
    },
    {
      "city": "โกสุมพิสัย",
      "cityCode": 4403,
      "provinceCode": 44,
      "subDistrict": [
        {
          "id": 440317,
          "name": "ดอนกลาง",
          "postCode": [
            "44140"
          ]
        },
        {
          "id": 440313,
          "name": "ยางท่าแจ้ง",
          "postCode": [
            "44140"
          ]
        },
        {
          "id": 440302,
          "name": "ยางน้อย",
          "postCode": [
            "44140"
          ]
        },
        {
          "id": 440303,
          "name": "วังยาว",
          "postCode": [
            "44140"
          ]
        },
        {
          "id": 440315,
          "name": "หนองกุงสวรรค์",
          "postCode": [
            "44140"
          ]
        },
        {
          "id": 440311,
          "name": "หนองบอน",
          "postCode": [
            "44140"
          ]
        },
        {
          "id": 440308,
          "name": "หนองบัว",
          "postCode": [
            "44140"
          ]
        },
        {
          "id": 440307,
          "name": "หนองเหล็ก",
          "postCode": [
            "44140"
          ]
        },
        {
          "id": 440301,
          "name": "หัวขวาง",
          "postCode": [
            "44140"
          ]
        },
        {
          "id": 440304,
          "name": "เขวาไร่",
          "postCode": [
            "44140"
          ]
        },
        {
          "id": 440310,
          "name": "เขื่อน",
          "postCode": [
            "44140"
          ]
        },
        {
          "id": 440316,
          "name": "เลิงใต้",
          "postCode": [
            "44140"
          ]
        },
        {
          "id": 440309,
          "name": "เหล่า",
          "postCode": [
            "44140"
          ]
        },
        {
          "id": 440306,
          "name": "แก้งแก",
          "postCode": [
            "44140"
          ]
        },
        {
          "id": 440305,
          "name": "แพง",
          "postCode": [
            "44140"
          ]
        },
        {
          "id": 440314,
          "name": "แห่ใต้",
          "postCode": [
            "44140"
          ]
        },
        {
          "id": 440312,
          "name": "โพนงาม",
          "postCode": [
            "44140"
          ]
        }
      ]
    },
    {
      "city": "คำชะอี",
      "cityCode": 4905,
      "provinceCode": 49,
      "subDistrict": [
        {
          "id": 490504,
          "name": "คำชะอี",
          "postCode": [
            "49110"
          ]
        },
        {
          "id": 490512,
          "name": "คำบก",
          "postCode": [
            "49110"
          ]
        },
        {
          "id": 490514,
          "name": "น้ำเที่ยง",
          "postCode": [
            "49110"
          ]
        },
        {
          "id": 490506,
          "name": "บ้านค้อ",
          "postCode": [
            "49110"
          ]
        },
        {
          "id": 490503,
          "name": "บ้านซ่ง",
          "postCode": [
            "49110"
          ]
        },
        {
          "id": 490507,
          "name": "บ้านเหล่า",
          "postCode": [
            "49110"
          ]
        },
        {
          "id": 490505,
          "name": "หนองเอี่ยน",
          "postCode": [
            "49110"
          ]
        },
        {
          "id": 490511,
          "name": "เหล่าสร้างถ่อ",
          "postCode": [
            "49110"
          ]
        },
        {
          "id": 490508,
          "name": "โพนงาม",
          "postCode": [
            "49110"
          ]
        }
      ]
    },
    {
      "city": "ดงหลวง",
      "cityCode": 4904,
      "provinceCode": 49,
      "subDistrict": [
        {
          "id": 490403,
          "name": "กกตูม",
          "postCode": [
            "49140"
          ]
        },
        {
          "id": 490405,
          "name": "ชะโนดน้อย",
          "postCode": [
            "49140"
          ]
        },
        {
          "id": 490401,
          "name": "ดงหลวง",
          "postCode": [
            "49140"
          ]
        },
        {
          "id": 490406,
          "name": "พังแดง",
          "postCode": [
            "49140"
          ]
        },
        {
          "id": 490402,
          "name": "หนองบัว",
          "postCode": [
            "49140"
          ]
        },
        {
          "id": 490404,
          "name": "หนองแคน",
          "postCode": [
            "49140"
          ]
        }
      ]
    },
    {
      "city": "ดอนตาล",
      "cityCode": 4903,
      "provinceCode": 49,
      "subDistrict": [
        {
          "id": 490301,
          "name": "ดอนตาล",
          "postCode": [
            "49120"
          ]
        },
        {
          "id": 490306,
          "name": "นาสะเม็ง",
          "postCode": [
            "49120"
          ]
        },
        {
          "id": 490305,
          "name": "บ้านบาก",
          "postCode": [
            "49120"
          ]
        },
        {
          "id": 490307,
          "name": "บ้านแก้ง",
          "postCode": [
            "49120"
          ]
        },
        {
          "id": 490303,
          "name": "ป่าไร่",
          "postCode": [
            "49120"
          ]
        },
        {
          "id": 490304,
          "name": "เหล่าหมี",
          "postCode": [
            "49120"
          ]
        },
        {
          "id": 490302,
          "name": "โพธิ์ไทร",
          "postCode": [
            "49120"
          ]
        }
      ]
    },
    {
      "city": "นิคมคำสร้อย",
      "cityCode": 4902,
      "provinceCode": 49,
      "subDistrict": [
        {
          "id": 490204,
          "name": "กกแดง",
          "postCode": [
            "49130"
          ]
        },
        {
          "id": 490202,
          "name": "นากอก",
          "postCode": [
            "49130"
          ]
        },
        {
          "id": 490205,
          "name": "นาอุดม",
          "postCode": [
            "49130"
          ]
        },
        {
          "id": 490201,
          "name": "นิคมคำสร้อย",
          "postCode": [
            "49130"
          ]
        },
        {
          "id": 490207,
          "name": "ร่มเกล้า",
          "postCode": [
            "49130"
          ]
        },
        {
          "id": 490203,
          "name": "หนองแวง",
          "postCode": [
            "49130"
          ]
        },
        {
          "id": 490206,
          "name": "โชคชัย",
          "postCode": [
            "49130"
          ]
        }
      ]
    },
    {
      "city": "หนองสูง",
      "cityCode": 4907,
      "provinceCode": 49,
      "subDistrict": [
        {
          "id": 490704,
          "name": "บ้านเป้า",
          "postCode": [
            "49160"
          ]
        },
        {
          "id": 490703,
          "name": "ภูวง",
          "postCode": [
            "49160"
          ]
        },
        {
          "id": 490701,
          "name": "หนองสูง",
          "postCode": [
            "49160"
          ]
        },
        {
          "id": 490706,
          "name": "หนองสูงเหนือ",
          "postCode": [
            "49160"
          ]
        },
        {
          "id": 490705,
          "name": "หนองสูงใต้",
          "postCode": [
            "49160"
          ]
        },
        {
          "id": 490702,
          "name": "โนนยาง",
          "postCode": [
            "49160"
          ]
        }
      ]
    },
    {
      "city": "หว้านใหญ่",
      "cityCode": 4906,
      "provinceCode": 49,
      "subDistrict": [
        {
          "id": 490604,
          "name": "ชะโนด",
          "postCode": [
            "49150"
          ]
        },
        {
          "id": 490605,
          "name": "ดงหมู",
          "postCode": [
            "49150"
          ]
        },
        {
          "id": 490603,
          "name": "บางทรายน้อย",
          "postCode": [
            "49150"
          ]
        },
        {
          "id": 490602,
          "name": "ป่งขาม",
          "postCode": [
            "49150"
          ]
        },
        {
          "id": 490601,
          "name": "หว้านใหญ่",
          "postCode": [
            "49150"
          ]
        }
      ]
    },
    {
      "city": "เมืองมุกดาหาร",
      "cityCode": 4901,
      "provinceCode": 49,
      "subDistrict": [
        {
          "id": 490113,
          "name": "กุดแข้",
          "postCode": [
            "49000"
          ]
        },
        {
          "id": 490109,
          "name": "คำป่าหลาย",
          "postCode": [
            "49000"
          ]
        },
        {
          "id": 490110,
          "name": "คำอาฮวน",
          "postCode": [
            "49000"
          ]
        },
        {
          "id": 490112,
          "name": "ดงมอน",
          "postCode": [
            "49000"
          ]
        },
        {
          "id": 490111,
          "name": "ดงเย็น",
          "postCode": [
            "49000"
          ]
        },
        {
          "id": 490108,
          "name": "นาสีนวน",
          "postCode": [
            "49000"
          ]
        },
        {
          "id": 490107,
          "name": "นาโสก",
          "postCode": [
            "49000"
          ]
        },
        {
          "id": 490104,
          "name": "บางทรายใหญ่",
          "postCode": [
            "49000"
          ]
        },
        {
          "id": 490103,
          "name": "บ้านโคก",
          "postCode": [
            "49000"
          ]
        },
        {
          "id": 490106,
          "name": "ผึ่งแดด",
          "postCode": [
            "49000"
          ]
        },
        {
          "id": 490101,
          "name": "มุกดาหาร",
          "postCode": [
            "49000"
          ]
        },
        {
          "id": 490102,
          "name": "ศรีบุญเรือง",
          "postCode": [
            "49000"
          ]
        },
        {
          "id": 490105,
          "name": "โพนทราย",
          "postCode": [
            "49000"
          ]
        }
      ]
    },
    {
      "city": "กรงปินัง",
      "cityCode": 9508,
      "provinceCode": 95,
      "subDistrict": [
        {
          "id": 950801,
          "name": "กรงปินัง",
          "postCode": [
            "95000"
          ]
        },
        {
          "id": 950804,
          "name": "ปุโรง",
          "postCode": [
            "95000"
          ]
        },
        {
          "id": 950802,
          "name": "สะเอะ",
          "postCode": [
            "95000"
          ]
        },
        {
          "id": 950803,
          "name": "ห้วยกระทิง",
          "postCode": [
            "95000"
          ]
        }
      ]
    },
    {
      "city": "กาบัง",
      "cityCode": 9507,
      "provinceCode": 95,
      "subDistrict": [
        {
          "id": 950701,
          "name": "กาบัง",
          "postCode": [
            "95120"
          ]
        },
        {
          "id": 950702,
          "name": "บาละ",
          "postCode": [
            "95120"
          ]
        }
      ]
    },
    {
      "city": "ธารโต",
      "cityCode": 9504,
      "provinceCode": 95,
      "subDistrict": [
        {
          "id": 950404,
          "name": "คีรีเขต",
          "postCode": [
            "95150"
          ]
        },
        {
          "id": 950401,
          "name": "ธารโต",
          "postCode": [
            "95150"
          ]
        },
        {
          "id": 950402,
          "name": "บ้านแหร",
          "postCode": [
            "95150"
          ]
        },
        {
          "id": 950403,
          "name": "แม่หวาด",
          "postCode": [
            "95130",
            "95170"
          ]
        }
      ]
    },
    {
      "city": "บันนังสตา",
      "cityCode": 9503,
      "provinceCode": 95,
      "subDistrict": [
        {
          "id": 950305,
          "name": "ตลิ่งชัน",
          "postCode": [
            "95130"
          ]
        },
        {
          "id": 950303,
          "name": "ตาเนาะปูเต๊ะ",
          "postCode": [
            "95130"
          ]
        },
        {
          "id": 950304,
          "name": "ถ้ำทะลุ",
          "postCode": [
            "95130"
          ]
        },
        {
          "id": 950301,
          "name": "บันนังสตา",
          "postCode": [
            "95130"
          ]
        },
        {
          "id": 950302,
          "name": "บาเจาะ",
          "postCode": [
            "95130"
          ]
        },
        {
          "id": 950306,
          "name": "เขื่อนบางลาง",
          "postCode": [
            "95130"
          ]
        }
      ]
    },
    {
      "city": "ยะหา",
      "cityCode": 9505,
      "provinceCode": 95,
      "subDistrict": [
        {
          "id": 950508,
          "name": "กาตอง",
          "postCode": [
            "95120"
          ]
        },
        {
          "id": 950506,
          "name": "ตาชี",
          "postCode": [
            "95120"
          ]
        },
        {
          "id": 950507,
          "name": "บาโงยซิแน",
          "postCode": [
            "95120"
          ]
        },
        {
          "id": 950504,
          "name": "บาโร๊ะ",
          "postCode": [
            "95120"
          ]
        },
        {
          "id": 950503,
          "name": "ปะแต",
          "postCode": [
            "95120"
          ]
        },
        {
          "id": 950501,
          "name": "ยะหา",
          "postCode": [
            "95120"
          ]
        },
        {
          "id": 950502,
          "name": "ละแอ",
          "postCode": [
            "95120"
          ]
        }
      ]
    },
    {
      "city": "รามัน",
      "cityCode": 9506,
      "provinceCode": 95,
      "subDistrict": [
        {
          "id": 950604,
          "name": "กอตอตือร๊ะ",
          "postCode": [
            "95140"
          ]
        },
        {
          "id": 950601,
          "name": "กายูบอเกาะ",
          "postCode": [
            "95140"
          ]
        },
        {
          "id": 950603,
          "name": "กาลอ",
          "postCode": [
            "95140"
          ]
        },
        {
          "id": 950602,
          "name": "กาลูปัง",
          "postCode": [
            "95140"
          ]
        },
        {
          "id": 950607,
          "name": "จะกว๊ะ",
          "postCode": [
            "95140"
          ]
        },
        {
          "id": 950616,
          "name": "ตะโล๊ะหะลอ",
          "postCode": [
            "95140"
          ]
        },
        {
          "id": 950608,
          "name": "ท่าธง",
          "postCode": [
            "95140"
          ]
        },
        {
          "id": 950610,
          "name": "บาลอ",
          "postCode": [
            "95140"
          ]
        },
        {
          "id": 950611,
          "name": "บาโงย",
          "postCode": [
            "95140"
          ]
        },
        {
          "id": 950612,
          "name": "บือมัง",
          "postCode": [
            "95140"
          ]
        },
        {
          "id": 950613,
          "name": "ยะต๊ะ",
          "postCode": [
            "95140"
          ]
        },
        {
          "id": 950614,
          "name": "วังพญา",
          "postCode": [
            "95140"
          ]
        },
        {
          "id": 950615,
          "name": "อาซ่อง",
          "postCode": [
            "95140"
          ]
        },
        {
          "id": 950606,
          "name": "เกะรอ",
          "postCode": [
            "95140"
          ]
        },
        {
          "id": 950609,
          "name": "เนินงาม",
          "postCode": [
            "95140"
          ]
        },
        {
          "id": 950605,
          "name": "โกตาบารู",
          "postCode": [
            "95140"
          ]
        }
      ]
    },
    {
      "city": "เบตง",
      "cityCode": 9502,
      "provinceCode": 95,
      "subDistrict": [
        {
          "id": 950203,
          "name": "ตาเนาะแมเราะ",
          "postCode": [
            "95110"
          ]
        },
        {
          "id": 950205,
          "name": "ธารน้ำทิพย์",
          "postCode": [
            "95110"
          ]
        },
        {
          "id": 950202,
          "name": "ยะรม",
          "postCode": [
            "95110"
          ]
        },
        {
          "id": 950204,
          "name": "อัยเยอร์เวง",
          "postCode": [
            "95110"
          ]
        },
        {
          "id": 950201,
          "name": "เบตง",
          "postCode": [
            "95110"
          ]
        }
      ]
    },
    {
      "city": "เมืองยะลา",
      "cityCode": 9501,
      "provinceCode": 95,
      "subDistrict": [
        {
          "id": 950118,
          "name": "ตาเซะ",
          "postCode": [
            "95000"
          ]
        },
        {
          "id": 950108,
          "name": "ท่าสาป",
          "postCode": [
            "95000"
          ]
        },
        {
          "id": 950115,
          "name": "บันนังสาเรง",
          "postCode": [
            "95000"
          ]
        },
        {
          "id": 950102,
          "name": "บุดี",
          "postCode": [
            "95000"
          ]
        },
        {
          "id": 950114,
          "name": "พร่อน",
          "postCode": [
            "95160"
          ]
        },
        {
          "id": 950106,
          "name": "ยะลา",
          "postCode": [
            "95000"
          ]
        },
        {
          "id": 950103,
          "name": "ยุโป",
          "postCode": [
            "95000"
          ]
        },
        {
          "id": 950111,
          "name": "ลำพะยา",
          "postCode": [
            "95160"
          ]
        },
        {
          "id": 950109,
          "name": "ลำใหม่",
          "postCode": [
            "95160"
          ]
        },
        {
          "id": 950104,
          "name": "ลิดล",
          "postCode": [
            "95160"
          ]
        },
        {
          "id": 950101,
          "name": "สะเตง",
          "postCode": [
            "95000"
          ]
        },
        {
          "id": 950116,
          "name": "สะเตงนอก",
          "postCode": [
            "95000"
          ]
        },
        {
          "id": 950110,
          "name": "หน้าถ้ำ",
          "postCode": [
            "95000"
          ]
        },
        {
          "id": 950112,
          "name": "เปาะเส้ง",
          "postCode": [
            "95000"
          ]
        }
      ]
    },
    {
      "city": "กุดชุม",
      "cityCode": 3503,
      "provinceCode": 35,
      "subDistrict": [
        {
          "id": 350303,
          "name": "กำแมด",
          "postCode": [
            "35140"
          ]
        },
        {
          "id": 350301,
          "name": "กุดชุม",
          "postCode": [
            "35140"
          ]
        },
        {
          "id": 350308,
          "name": "คำน้ำสร้าง",
          "postCode": [
            "35140"
          ]
        },
        {
          "id": 350304,
          "name": "นาโส่",
          "postCode": [
            "35140"
          ]
        },
        {
          "id": 350306,
          "name": "หนองหมี",
          "postCode": [
            "35140"
          ]
        },
        {
          "id": 350309,
          "name": "หนองแหน",
          "postCode": [
            "35140"
          ]
        },
        {
          "id": 350305,
          "name": "ห้วยแก้ง",
          "postCode": [
            "35140"
          ]
        },
        {
          "id": 350302,
          "name": "โนนเปือย",
          "postCode": [
            "35140"
          ]
        },
        {
          "id": 350307,
          "name": "โพนงาม",
          "postCode": [
            "35140"
          ]
        }
      ]
    },
    {
      "city": "คำเขื่อนแก้ว",
      "cityCode": 3504,
      "provinceCode": 35,
      "subDistrict": [
        {
          "id": 350410,
          "name": "กุดกุง",
          "postCode": [
            "35110"
          ]
        },
        {
          "id": 350408,
          "name": "กู่จาน",
          "postCode": [
            "35110"
          ]
        },
        {
          "id": 350413,
          "name": "ดงเจริญ",
          "postCode": [
            "35110"
          ]
        },
        {
          "id": 350407,
          "name": "ดงแคนใหญ่",
          "postCode": [
            "35180"
          ]
        },
        {
          "id": 350405,
          "name": "ทุ่งมน",
          "postCode": [
            "35110"
          ]
        },
        {
          "id": 350406,
          "name": "นาคำ",
          "postCode": [
            "35180"
          ]
        },
        {
          "id": 350409,
          "name": "นาแก",
          "postCode": [
            "35180"
          ]
        },
        {
          "id": 350402,
          "name": "ย่อ",
          "postCode": [
            "35110"
          ]
        },
        {
          "id": 350401,
          "name": "ลุมพุก",
          "postCode": [
            "35110"
          ]
        },
        {
          "id": 350403,
          "name": "สงเปือย",
          "postCode": [
            "35110"
          ]
        },
        {
          "id": 350411,
          "name": "เหล่าไฮ",
          "postCode": [
            "35110"
          ]
        },
        {
          "id": 350412,
          "name": "แคนน้อย",
          "postCode": [
            "35180"
          ]
        },
        {
          "id": 350404,
          "name": "โพนทัน",
          "postCode": [
            "35110"
          ]
        }
      ]
    },
    {
      "city": "ค้อวัง",
      "cityCode": 3507,
      "provinceCode": 35,
      "subDistrict": [
        {
          "id": 350702,
          "name": "กุดน้ำใส",
          "postCode": [
            "35160"
          ]
        },
        {
          "id": 350704,
          "name": "ค้อวัง",
          "postCode": [
            "35160"
          ]
        },
        {
          "id": 350703,
          "name": "น้ำอ้อม",
          "postCode": [
            "35160"
          ]
        },
        {
          "id": 350701,
          "name": "ฟ้าห่วน",
          "postCode": [
            "35160"
          ]
        }
      ]
    },
    {
      "city": "ทรายมูล",
      "cityCode": 3502,
      "provinceCode": 35,
      "subDistrict": [
        {
          "id": 350203,
          "name": "ดงมะไฟ",
          "postCode": [
            "35170"
          ]
        },
        {
          "id": 350202,
          "name": "ดู่ลาด",
          "postCode": [
            "35170"
          ]
        },
        {
          "id": 350201,
          "name": "ทรายมูล",
          "postCode": [
            "35170"
          ]
        },
        {
          "id": 350204,
          "name": "นาเวียง",
          "postCode": [
            "35170"
          ]
        },
        {
          "id": 350205,
          "name": "ไผ่",
          "postCode": [
            "35170"
          ]
        }
      ]
    },
    {
      "city": "ป่าติ้ว",
      "cityCode": 3505,
      "provinceCode": 35,
      "subDistrict": [
        {
          "id": 350502,
          "name": "กระจาย",
          "postCode": [
            "35150"
          ]
        },
        {
          "id": 350505,
          "name": "ศรีฐาน",
          "postCode": [
            "35150"
          ]
        },
        {
          "id": 350504,
          "name": "เชียงเพ็ง",
          "postCode": [
            "35150"
          ]
        },
        {
          "id": 350503,
          "name": "โคกนาโก",
          "postCode": [
            "35150"
          ]
        },
        {
          "id": 350501,
          "name": "โพธิ์ไทร",
          "postCode": [
            "35150"
          ]
        }
      ]
    },
    {
      "city": "มหาชนะชัย",
      "cityCode": 3506,
      "provinceCode": 35,
      "subDistrict": [
        {
          "id": 350603,
          "name": "คูเมือง",
          "postCode": [
            "35130"
          ]
        },
        {
          "id": 350605,
          "name": "บากเรือ",
          "postCode": [
            "35130"
          ]
        },
        {
          "id": 350608,
          "name": "บึงแก",
          "postCode": [
            "35130"
          ]
        },
        {
          "id": 350604,
          "name": "ผือฮี",
          "postCode": [
            "35130"
          ]
        },
        {
          "id": 350609,
          "name": "พระเสาร์",
          "postCode": [
            "35130"
          ]
        },
        {
          "id": 350601,
          "name": "ฟ้าหยาด",
          "postCode": [
            "35130"
          ]
        },
        {
          "id": 350606,
          "name": "ม่วง",
          "postCode": [
            "35130"
          ]
        },
        {
          "id": 350610,
          "name": "สงยาง",
          "postCode": [
            "35130"
          ]
        },
        {
          "id": 350602,
          "name": "หัวเมือง",
          "postCode": [
            "35130"
          ]
        },
        {
          "id": 350607,
          "name": "โนนทราย",
          "postCode": [
            "35130"
          ]
        }
      ]
    },
    {
      "city": "เมืองยโสธร",
      "cityCode": 3501,
      "provinceCode": 35,
      "subDistrict": [
        {
          "id": 350108,
          "name": "ขั้นไดใหญ่",
          "postCode": [
            "35000"
          ]
        },
        {
          "id": 350115,
          "name": "ขุมเงิน",
          "postCode": [
            "35000"
          ]
        },
        {
          "id": 350105,
          "name": "ค้อเหนือ",
          "postCode": [
            "35000"
          ]
        },
        {
          "id": 350106,
          "name": "ดู่ทุ่ง",
          "postCode": [
            "35000"
          ]
        },
        {
          "id": 350103,
          "name": "ตาดทอง",
          "postCode": [
            "35000"
          ]
        },
        {
          "id": 350116,
          "name": "ทุ่งนางโอก",
          "postCode": [
            "35000"
          ]
        },
        {
          "id": 350109,
          "name": "ทุ่งแต้",
          "postCode": [
            "35000"
          ]
        },
        {
          "id": 350111,
          "name": "นาสะไมย์",
          "postCode": [
            "35000"
          ]
        },
        {
          "id": 350102,
          "name": "น้ำคำใหญ่",
          "postCode": [
            "35000"
          ]
        },
        {
          "id": 350104,
          "name": "สำราญ",
          "postCode": [
            "35000"
          ]
        },
        {
          "id": 350110,
          "name": "สิงห์",
          "postCode": [
            "35000"
          ]
        },
        {
          "id": 350114,
          "name": "หนองคู",
          "postCode": [
            "35000"
          ]
        },
        {
          "id": 350113,
          "name": "หนองหิน",
          "postCode": [
            "35000"
          ]
        },
        {
          "id": 350118,
          "name": "หนองเป็ด",
          "postCode": [
            "35000"
          ]
        },
        {
          "id": 350117,
          "name": "หนองเรือ",
          "postCode": [
            "35000"
          ]
        },
        {
          "id": 350112,
          "name": "เขื่องคำ",
          "postCode": [
            "35000"
          ]
        },
        {
          "id": 350107,
          "name": "เดิด",
          "postCode": [
            "35000"
          ]
        },
        {
          "id": 350101,
          "name": "ในเมือง",
          "postCode": [
            "35000"
          ]
        }
      ]
    },
    {
      "city": "เลิงนกทา",
      "cityCode": 3508,
      "provinceCode": 35,
      "subDistrict": [
        {
          "id": 350807,
          "name": "กุดเชียงหมี",
          "postCode": [
            "35120"
          ]
        },
        {
          "id": 350811,
          "name": "กุดแห่",
          "postCode": [
            "35120"
          ]
        },
        {
          "id": 350802,
          "name": "บุ่งค้า",
          "postCode": [
            "35120"
          ]
        },
        {
          "id": 350814,
          "name": "ศรีแก้ว",
          "postCode": [
            "35120"
          ]
        },
        {
          "id": 350813,
          "name": "สร้างมิ่ง",
          "postCode": [
            "35120"
          ]
        },
        {
          "id": 350803,
          "name": "สวาท",
          "postCode": [
            "35120"
          ]
        },
        {
          "id": 350806,
          "name": "สามัคคี",
          "postCode": [
            "35120"
          ]
        },
        {
          "id": 350810,
          "name": "สามแยก",
          "postCode": [
            "35120"
          ]
        },
        {
          "id": 350805,
          "name": "ห้องแซง",
          "postCode": [
            "35120"
          ]
        },
        {
          "id": 350812,
          "name": "โคกสำราญ",
          "postCode": [
            "35120"
          ]
        }
      ]
    },
    {
      "city": "ไทยเจริญ",
      "cityCode": 3509,
      "provinceCode": 35,
      "subDistrict": [
        {
          "id": 350904,
          "name": "คำเตย",
          "postCode": [
            "35120"
          ]
        },
        {
          "id": 350905,
          "name": "คำไผ่",
          "postCode": [
            "35120"
          ]
        },
        {
          "id": 350902,
          "name": "น้ำคำ",
          "postCode": [
            "35120"
          ]
        },
        {
          "id": 350903,
          "name": "ส้มผ่อ",
          "postCode": [
            "35120"
          ]
        },
        {
          "id": 350901,
          "name": "ไทยเจริญ",
          "postCode": [
            "35120"
          ]
        }
      ]
    },
    {
      "city": "กระบุรี",
      "cityCode": 8504,
      "provinceCode": 85,
      "subDistrict": [
        {
          "id": 850406,
          "name": "จ.ป.ร.",
          "postCode": [
            "85110"
          ]
        },
        {
          "id": 850401,
          "name": "น้ำจืด",
          "postCode": [
            "85110"
          ]
        },
        {
          "id": 850402,
          "name": "น้ำจืดน้อย",
          "postCode": [
            "85110"
          ]
        },
        {
          "id": 850407,
          "name": "บางใหญ่",
          "postCode": [
            "85110"
          ]
        },
        {
          "id": 850404,
          "name": "ปากจั่น",
          "postCode": [
            "85110"
          ]
        },
        {
          "id": 850403,
          "name": "มะมุ",
          "postCode": [
            "85110"
          ]
        },
        {
          "id": 850405,
          "name": "ลำเลียง",
          "postCode": [
            "85110"
          ]
        }
      ]
    },
    {
      "city": "กะเปอร์",
      "cityCode": 8503,
      "provinceCode": 85,
      "subDistrict": [
        {
          "id": 850302,
          "name": "กะเปอร์",
          "postCode": [
            "85120"
          ]
        },
        {
          "id": 850305,
          "name": "บางหิน",
          "postCode": [
            "85120"
          ]
        },
        {
          "id": 850304,
          "name": "บ้านนา",
          "postCode": [
            "85120"
          ]
        },
        {
          "id": 850301,
          "name": "ม่วงกลวง",
          "postCode": [
            "85120"
          ]
        },
        {
          "id": 850303,
          "name": "เชี่ยวเหลียง",
          "postCode": [
            "85120"
          ]
        }
      ]
    },
    {
      "city": "ละอุ่น",
      "cityCode": 8502,
      "provinceCode": 85,
      "subDistrict": [
        {
          "id": 850204,
          "name": "บางพระเหนือ",
          "postCode": [
            "85130"
          ]
        },
        {
          "id": 850203,
          "name": "บางพระใต้",
          "postCode": [
            "85130"
          ]
        },
        {
          "id": 850205,
          "name": "บางแก้ว",
          "postCode": [
            "85130"
          ]
        },
        {
          "id": 850202,
          "name": "ละอุ่นเหนือ",
          "postCode": [
            "85130"
          ]
        },
        {
          "id": 850201,
          "name": "ละอุ่นใต้",
          "postCode": [
            "85130"
          ]
        },
        {
          "id": 850206,
          "name": "ในวงเหนือ",
          "postCode": [
            "85130"
          ]
        },
        {
          "id": 850207,
          "name": "ในวงใต้",
          "postCode": [
            "85130"
          ]
        }
      ]
    },
    {
      "city": "สุขสำราญ",
      "cityCode": 8505,
      "provinceCode": 85,
      "subDistrict": [
        {
          "id": 850502,
          "name": "กำพวน",
          "postCode": [
            "85120"
          ]
        },
        {
          "id": 850501,
          "name": "นาคา",
          "postCode": [
            "85120"
          ]
        }
      ]
    },
    {
      "city": "เมืองระนอง",
      "cityCode": 8501,
      "provinceCode": 85,
      "subDistrict": [
        {
          "id": 850108,
          "name": "ทรายแดง",
          "postCode": [
            "85130"
          ]
        },
        {
          "id": 850106,
          "name": "บางนอน",
          "postCode": [
            "85000"
          ]
        },
        {
          "id": 850104,
          "name": "บางริ้น",
          "postCode": [
            "85000"
          ]
        },
        {
          "id": 850105,
          "name": "ปากน้ำ",
          "postCode": [
            "85000"
          ]
        },
        {
          "id": 850102,
          "name": "ราชกรูด",
          "postCode": [
            "85000"
          ]
        },
        {
          "id": 850103,
          "name": "หงาว",
          "postCode": [
            "85000"
          ]
        },
        {
          "id": 850107,
          "name": "หาดส้มแป้น",
          "postCode": [
            "85000"
          ]
        },
        {
          "id": 850109,
          "name": "เกาะพยาม",
          "postCode": [
            "85000"
          ]
        },
        {
          "id": 850101,
          "name": "เขานิเวศน์",
          "postCode": [
            "85000"
          ]
        }
      ]
    },
    {
      "city": "นิคมพัฒนา",
      "cityCode": 2108,
      "provinceCode": 21,
      "subDistrict": [
        {
          "id": 210801,
          "name": "นิคมพัฒนา",
          "postCode": [
            "21180"
          ]
        },
        {
          "id": 210803,
          "name": "พนานิคม",
          "postCode": [
            "21180"
          ]
        },
        {
          "id": 210804,
          "name": "มะขามคู่",
          "postCode": [
            "21180"
          ]
        },
        {
          "id": 210802,
          "name": "มาบข่า",
          "postCode": [
            "21180"
          ]
        }
      ]
    },
    {
      "city": "บ้านค่าย",
      "cityCode": 2105,
      "provinceCode": 21,
      "subDistrict": [
        {
          "id": 210507,
          "name": "ชากบก",
          "postCode": [
            "21120"
          ]
        },
        {
          "id": 210504,
          "name": "ตาขัน",
          "postCode": [
            "21120"
          ]
        },
        {
          "id": 210505,
          "name": "บางบุตร",
          "postCode": [
            "21120"
          ]
        },
        {
          "id": 210501,
          "name": "บ้านค่าย",
          "postCode": [
            "21120"
          ]
        },
        {
          "id": 210503,
          "name": "หนองตะพาน",
          "postCode": [
            "21120"
          ]
        },
        {
          "id": 210506,
          "name": "หนองบัว",
          "postCode": [
            "21120"
          ]
        },
        {
          "id": 210502,
          "name": "หนองละลอก",
          "postCode": [
            "21120"
          ]
        }
      ]
    },
    {
      "city": "บ้านฉาง",
      "cityCode": 2102,
      "provinceCode": 21,
      "subDistrict": [
        {
          "id": 210203,
          "name": "บ้านฉาง",
          "postCode": [
            "21130"
          ]
        },
        {
          "id": 210202,
          "name": "พลา",
          "postCode": [
            "21130"
          ]
        },
        {
          "id": 210201,
          "name": "สำนักท้อน",
          "postCode": [
            "21130"
          ]
        }
      ]
    },
    {
      "city": "ปลวกแดง",
      "cityCode": 2106,
      "provinceCode": 21,
      "subDistrict": [
        {
          "id": 210602,
          "name": "ตาสิทธิ์",
          "postCode": [
            "21140"
          ]
        },
        {
          "id": 210601,
          "name": "ปลวกแดง",
          "postCode": [
            "21140"
          ]
        },
        {
          "id": 210605,
          "name": "มาบยางพร",
          "postCode": [
            "21140"
          ]
        },
        {
          "id": 210603,
          "name": "ละหาร",
          "postCode": [
            "21140"
          ]
        },
        {
          "id": 210606,
          "name": "หนองไร่",
          "postCode": [
            "21140"
          ]
        },
        {
          "id": 210604,
          "name": "แม่น้ำคู้",
          "postCode": [
            "21140"
          ]
        }
      ]
    },
    {
      "city": "วังจันทร์",
      "cityCode": 2104,
      "provinceCode": 21,
      "subDistrict": [
        {
          "id": 210402,
          "name": "ชุมแสง",
          "postCode": [
            "21210"
          ]
        },
        {
          "id": 210403,
          "name": "ป่ายุบใน",
          "postCode": [
            "21210"
          ]
        },
        {
          "id": 210404,
          "name": "พลงตาเอี่ยม",
          "postCode": [
            "21210"
          ]
        },
        {
          "id": 210401,
          "name": "วังจันทร์",
          "postCode": [
            "21210"
          ]
        }
      ]
    },
    {
      "city": "เขาชะเมา",
      "cityCode": 2107,
      "provinceCode": 21,
      "subDistrict": [
        {
          "id": 210703,
          "name": "ชำฆ้อ",
          "postCode": [
            "21110"
          ]
        },
        {
          "id": 210701,
          "name": "น้ำเป็น",
          "postCode": [
            "21110"
          ]
        },
        {
          "id": 210702,
          "name": "ห้วยทับมอญ",
          "postCode": [
            "21110"
          ]
        },
        {
          "id": 210704,
          "name": "เขาน้อย",
          "postCode": [
            "21110"
          ]
        }
      ]
    },
    {
      "city": "เมืองระยอง",
      "cityCode": 2101,
      "provinceCode": 21,
      "subDistrict": [
        {
          "id": 210110,
          "name": "กะเฉด",
          "postCode": [
            "21100"
          ]
        },
        {
          "id": 210103,
          "name": "ตะพง",
          "postCode": [
            "21000"
          ]
        },
        {
          "id": 210111,
          "name": "ทับมา",
          "postCode": [
            "21000"
          ]
        },
        {
          "id": 210101,
          "name": "ท่าประดู่",
          "postCode": [
            "21000"
          ]
        },
        {
          "id": 210108,
          "name": "นาตาขวัญ",
          "postCode": [
            "21000"
          ]
        },
        {
          "id": 210112,
          "name": "น้ำคอก",
          "postCode": [
            "21000"
          ]
        },
        {
          "id": 210107,
          "name": "บ้านแลง",
          "postCode": [
            "21000"
          ]
        },
        {
          "id": 210104,
          "name": "ปากน้ำ",
          "postCode": [
            "21000"
          ]
        },
        {
          "id": 210114,
          "name": "มาบตาพุด",
          "postCode": [
            "21150"
          ]
        },
        {
          "id": 210115,
          "name": "สำนักทอง",
          "postCode": [
            "21100"
          ]
        },
        {
          "id": 210113,
          "name": "ห้วยโป่ง",
          "postCode": [
            "21150"
          ]
        },
        {
          "id": 210102,
          "name": "เชิงเนิน",
          "postCode": [
            "21000"
          ]
        },
        {
          "id": 210109,
          "name": "เนินพระ",
          "postCode": [
            "21000",
            "21150"
          ]
        },
        {
          "id": 210105,
          "name": "เพ",
          "postCode": [
            "21160"
          ]
        },
        {
          "id": 210106,
          "name": "แกลง",
          "postCode": [
            "21160"
          ]
        }
      ]
    },
    {
      "city": "แกลง",
      "cityCode": 2103,
      "provinceCode": 21,
      "subDistrict": [
        {
          "id": 210307,
          "name": "กระแสบน",
          "postCode": [
            "21110"
          ]
        },
        {
          "id": 210305,
          "name": "กร่ำ",
          "postCode": [
            "21190"
          ]
        },
        {
          "id": 210310,
          "name": "กองดิน",
          "postCode": [
            "22160"
          ]
        },
        {
          "id": 210311,
          "name": "คลองปูน",
          "postCode": [
            "21170"
          ]
        },
        {
          "id": 210306,
          "name": "ชากพง",
          "postCode": [
            "21190"
          ]
        },
        {
          "id": 210303,
          "name": "ชากโดน",
          "postCode": [
            "21110"
          ]
        },
        {
          "id": 210301,
          "name": "ทางเกวียน",
          "postCode": [
            "21110"
          ]
        },
        {
          "id": 210309,
          "name": "ทุ่งควายกิน",
          "postCode": [
            "21110"
          ]
        },
        {
          "id": 210308,
          "name": "บ้านนา",
          "postCode": [
            "21110"
          ]
        },
        {
          "id": 210313,
          "name": "ปากน้ำกระแส",
          "postCode": [
            "21170"
          ]
        },
        {
          "id": 210312,
          "name": "พังราด",
          "postCode": [
            "21170"
          ]
        },
        {
          "id": 210302,
          "name": "วังหว้า",
          "postCode": [
            "21110"
          ]
        },
        {
          "id": 210318,
          "name": "สองสลึง",
          "postCode": [
            "21110"
          ]
        },
        {
          "id": 210317,
          "name": "ห้วยยาง",
          "postCode": [
            "21110"
          ]
        },
        {
          "id": 210304,
          "name": "เนินฆ้อ",
          "postCode": [
            "21110"
          ]
        }
      ]
    },
    {
      "city": "จอมบึง",
      "cityCode": 7002,
      "provinceCode": 70,
      "subDistrict": [
        {
          "id": 700201,
          "name": "จอมบึง",
          "postCode": [
            "70150"
          ]
        },
        {
          "id": 700204,
          "name": "ด่านทับตะโก",
          "postCode": [
            "70150"
          ]
        },
        {
          "id": 700202,
          "name": "ปากช่อง",
          "postCode": [
            "70150"
          ]
        },
        {
          "id": 700206,
          "name": "รางบัว",
          "postCode": [
            "70150"
          ]
        },
        {
          "id": 700203,
          "name": "เบิกไพร",
          "postCode": [
            "70150"
          ]
        },
        {
          "id": 700205,
          "name": "แก้มอ้น",
          "postCode": [
            "70150"
          ]
        }
      ]
    },
    {
      "city": "ดำเนินสะดวก",
      "cityCode": 7004,
      "provinceCode": 70,
      "subDistrict": [
        {
          "id": 700412,
          "name": "ขุนพิทักษ์",
          "postCode": [
            "70130"
          ]
        },
        {
          "id": 700405,
          "name": "ดอนกรวย",
          "postCode": [
            "70130"
          ]
        },
        {
          "id": 700406,
          "name": "ดอนคลัง",
          "postCode": [
            "70130"
          ]
        },
        {
          "id": 700413,
          "name": "ดอนไผ่",
          "postCode": [
            "70130"
          ]
        },
        {
          "id": 700401,
          "name": "ดำเนินสะดวก",
          "postCode": [
            "70130"
          ]
        },
        {
          "id": 700404,
          "name": "ตาหลวง",
          "postCode": [
            "70130"
          ]
        },
        {
          "id": 700411,
          "name": "ท่านัด",
          "postCode": [
            "70130"
          ]
        },
        {
          "id": 700407,
          "name": "บัวงาม",
          "postCode": [
            "70210"
          ]
        },
        {
          "id": 700408,
          "name": "บ้านไร่",
          "postCode": [
            "70130"
          ]
        },
        {
          "id": 700402,
          "name": "ประสาทสิทธิ์",
          "postCode": [
            "70210"
          ]
        },
        {
          "id": 700403,
          "name": "ศรีสุราษฎร์",
          "postCode": [
            "70130"
          ]
        },
        {
          "id": 700410,
          "name": "สี่หมื่น",
          "postCode": [
            "70130"
          ]
        },
        {
          "id": 700409,
          "name": "แพงพวย",
          "postCode": [
            "70130"
          ]
        }
      ]
    },
    {
      "city": "บางแพ",
      "cityCode": 7006,
      "provinceCode": 70,
      "subDistrict": [
        {
          "id": 700606,
          "name": "ดอนคา",
          "postCode": [
            "70160"
          ]
        },
        {
          "id": 700605,
          "name": "ดอนใหญ่",
          "postCode": [
            "70160"
          ]
        },
        {
          "id": 700601,
          "name": "บางแพ",
          "postCode": [
            "70160"
          ]
        },
        {
          "id": 700602,
          "name": "วังเย็น",
          "postCode": [
            "70160"
          ]
        },
        {
          "id": 700604,
          "name": "วัดแก้ว",
          "postCode": [
            "70160"
          ]
        },
        {
          "id": 700603,
          "name": "หัวโพ",
          "postCode": [
            "70160"
          ]
        },
        {
          "id": 700607,
          "name": "โพหัก",
          "postCode": [
            "70160"
          ]
        }
      ]
    },
    {
      "city": "บ้านคา",
      "cityCode": 7010,
      "provinceCode": 70,
      "subDistrict": [
        {
          "id": 701001,
          "name": "บ้านคา",
          "postCode": [
            "70180"
          ]
        },
        {
          "id": 701002,
          "name": "บ้านบึง",
          "postCode": [
            "70180"
          ]
        },
        {
          "id": 701003,
          "name": "หนองพันจันทร์",
          "postCode": [
            "70180"
          ]
        }
      ]
    },
    {
      "city": "บ้านโป่ง",
      "cityCode": 7005,
      "provinceCode": 70,
      "subDistrict": [
        {
          "id": 700503,
          "name": "กรับใหญ่",
          "postCode": [
            "70190"
          ]
        },
        {
          "id": 700511,
          "name": "คุ้งพยอม",
          "postCode": [
            "70110"
          ]
        },
        {
          "id": 700507,
          "name": "ดอนกระเบื้อง",
          "postCode": [
            "70110"
          ]
        },
        {
          "id": 700502,
          "name": "ท่าผา",
          "postCode": [
            "70110"
          ]
        },
        {
          "id": 700509,
          "name": "นครชุมน์",
          "postCode": [
            "70110"
          ]
        },
        {
          "id": 700510,
          "name": "บ้านม่วง",
          "postCode": [
            "70110"
          ]
        },
        {
          "id": 700501,
          "name": "บ้านโป่ง",
          "postCode": [
            "70110"
          ]
        },
        {
          "id": 700504,
          "name": "ปากแรต",
          "postCode": [
            "70110"
          ]
        },
        {
          "id": 700515,
          "name": "ลาดบัวขาว",
          "postCode": [
            "70110"
          ]
        },
        {
          "id": 700508,
          "name": "สวนกล้วย",
          "postCode": [
            "70110"
          ]
        },
        {
          "id": 700505,
          "name": "หนองกบ",
          "postCode": [
            "70110"
          ]
        },
        {
          "id": 700512,
          "name": "หนองปลาหมอ",
          "postCode": [
            "70110"
          ]
        },
        {
          "id": 700506,
          "name": "หนองอ้อ",
          "postCode": [
            "70110"
          ]
        },
        {
          "id": 700513,
          "name": "เขาขลุง",
          "postCode": [
            "70110"
          ]
        },
        {
          "id": 700514,
          "name": "เบิกไพร",
          "postCode": [
            "70110"
          ]
        }
      ]
    },
    {
      "city": "ปากท่อ",
      "cityCode": 7008,
      "provinceCode": 70,
      "subDistrict": [
        {
          "id": 700803,
          "name": "ดอนทราย",
          "postCode": [
            "70140"
          ]
        },
        {
          "id": 700801,
          "name": "ทุ่งหลวง",
          "postCode": [
            "70140"
          ]
        },
        {
          "id": 700809,
          "name": "บ่อกระดาน",
          "postCode": [
            "70140"
          ]
        },
        {
          "id": 700805,
          "name": "ปากท่อ",
          "postCode": [
            "70140"
          ]
        },
        {
          "id": 700806,
          "name": "ป่าไก่",
          "postCode": [
            "70140"
          ]
        },
        {
          "id": 700810,
          "name": "ยางหัก",
          "postCode": [
            "70140"
          ]
        },
        {
          "id": 700802,
          "name": "วังมะนาว",
          "postCode": [
            "70140"
          ]
        },
        {
          "id": 700807,
          "name": "วัดยางงาม",
          "postCode": [
            "70140"
          ]
        },
        {
          "id": 700811,
          "name": "วันดาว",
          "postCode": [
            "70140"
          ]
        },
        {
          "id": 700804,
          "name": "หนองกระทุ่ม",
          "postCode": [
            "70140"
          ]
        },
        {
          "id": 700812,
          "name": "ห้วยยางโทน",
          "postCode": [
            "70140"
          ]
        },
        {
          "id": 700808,
          "name": "อ่างหิน",
          "postCode": [
            "70140"
          ]
        }
      ]
    },
    {
      "city": "วัดเพลง",
      "cityCode": 7009,
      "provinceCode": 70,
      "subDistrict": [
        {
          "id": 700902,
          "name": "จอมประทัด",
          "postCode": [
            "70170"
          ]
        },
        {
          "id": 700903,
          "name": "วัดเพลง",
          "postCode": [
            "70170"
          ]
        },
        {
          "id": 700901,
          "name": "เกาะศาลพระ",
          "postCode": [
            "70170"
          ]
        }
      ]
    },
    {
      "city": "สวนผึ้ง",
      "cityCode": 7003,
      "provinceCode": 70,
      "subDistrict": [
        {
          "id": 700307,
          "name": "ตะนาวศรี",
          "postCode": [
            "70180"
          ]
        },
        {
          "id": 700304,
          "name": "ท่าเคย",
          "postCode": [
            "70180"
          ]
        },
        {
          "id": 700302,
          "name": "ป่าหวาย",
          "postCode": [
            "70180"
          ]
        },
        {
          "id": 700301,
          "name": "สวนผึ้ง",
          "postCode": [
            "70180"
          ]
        }
      ]
    },
    {
      "city": "เมืองราชบุรี",
      "cityCode": 7001,
      "provinceCode": 70,
      "subDistrict": [
        {
          "id": 700107,
          "name": "คุ้งกระถิน",
          "postCode": [
            "70000"
          ]
        },
        {
          "id": 700106,
          "name": "คุ้งน้ำวน",
          "postCode": [
            "70000"
          ]
        },
        {
          "id": 700120,
          "name": "คูบัว",
          "postCode": [
            "70000"
          ]
        },
        {
          "id": 700103,
          "name": "ดอนตะโก",
          "postCode": [
            "70000"
          ]
        },
        {
          "id": 700113,
          "name": "ดอนแร่",
          "postCode": [
            "70000"
          ]
        },
        {
          "id": 700121,
          "name": "ท่าราบ",
          "postCode": [
            "70000"
          ]
        },
        {
          "id": 700112,
          "name": "น้ำพุ",
          "postCode": [
            "70000"
          ]
        },
        {
          "id": 700118,
          "name": "บางป่า",
          "postCode": [
            "70000"
          ]
        },
        {
          "id": 700122,
          "name": "บ้านไร่",
          "postCode": [
            "70000"
          ]
        },
        {
          "id": 700119,
          "name": "พงสวาย",
          "postCode": [
            "70000"
          ]
        },
        {
          "id": 700111,
          "name": "พิกุลทอง",
          "postCode": [
            "70000"
          ]
        },
        {
          "id": 700110,
          "name": "สามเรือน",
          "postCode": [
            "70000"
          ]
        },
        {
          "id": 700104,
          "name": "หนองกลางนา",
          "postCode": [
            "70000"
          ]
        },
        {
          "id": 700101,
          "name": "หน้าเมือง",
          "postCode": [
            "70000"
          ]
        },
        {
          "id": 700117,
          "name": "หลุมดิน",
          "postCode": [
            "70000"
          ]
        },
        {
          "id": 700114,
          "name": "หินกอง",
          "postCode": [
            "70000"
          ]
        },
        {
          "id": 700105,
          "name": "ห้วยไผ่",
          "postCode": [
            "70000"
          ]
        },
        {
          "id": 700108,
          "name": "อ่างทอง",
          "postCode": [
            "70000"
          ]
        },
        {
          "id": 700116,
          "name": "เกาะพลับพลา",
          "postCode": [
            "70000"
          ]
        },
        {
          "id": 700115,
          "name": "เขาแร้ง",
          "postCode": [
            "70000"
          ]
        },
        {
          "id": 700102,
          "name": "เจดีย์หัก",
          "postCode": [
            "70000"
          ]
        },
        {
          "id": 700109,
          "name": "โคกหม้อ",
          "postCode": [
            "70000"
          ]
        }
      ]
    },
    {
      "city": "โพธาราม",
      "cityCode": 7007,
      "provinceCode": 70,
      "subDistrict": [
        {
          "id": 700710,
          "name": "คลองข่อย",
          "postCode": [
            "70120"
          ]
        },
        {
          "id": 700705,
          "name": "คลองตาคต",
          "postCode": [
            "70120"
          ]
        },
        {
          "id": 700711,
          "name": "ชำแระ",
          "postCode": [
            "70120"
          ]
        },
        {
          "id": 700702,
          "name": "ดอนกระเบื้อง",
          "postCode": [
            "70120"
          ]
        },
        {
          "id": 700708,
          "name": "ดอนทราย",
          "postCode": [
            "70120"
          ]
        },
        {
          "id": 700713,
          "name": "ท่าชุมพล",
          "postCode": [
            "70120"
          ]
        },
        {
          "id": 700717,
          "name": "ธรรมเสน",
          "postCode": [
            "70120"
          ]
        },
        {
          "id": 700716,
          "name": "นางแก้ว",
          "postCode": [
            "70120"
          ]
        },
        {
          "id": 700714,
          "name": "บางโตนด",
          "postCode": [
            "70120"
          ]
        },
        {
          "id": 700706,
          "name": "บ้านฆ้อง",
          "postCode": [
            "70120"
          ]
        },
        {
          "id": 700707,
          "name": "บ้านสิงห์",
          "postCode": [
            "70120"
          ]
        },
        {
          "id": 700704,
          "name": "บ้านเลือก",
          "postCode": [
            "70120"
          ]
        },
        {
          "id": 700712,
          "name": "สร้อยฟ้า",
          "postCode": [
            "70120"
          ]
        },
        {
          "id": 700719,
          "name": "หนองกวาง",
          "postCode": [
            "70120"
          ]
        },
        {
          "id": 700703,
          "name": "หนองโพ",
          "postCode": [
            "70120"
          ]
        },
        {
          "id": 700718,
          "name": "เขาชะงุ้ม",
          "postCode": [
            "70120"
          ]
        },
        {
          "id": 700709,
          "name": "เจ็ดเสมียน",
          "postCode": [
            "70120"
          ]
        },
        {
          "id": 700715,
          "name": "เตาปูน",
          "postCode": [
            "70120"
          ]
        },
        {
          "id": 700701,
          "name": "โพธาราม",
          "postCode": [
            "70120"
          ]
        }
      ]
    },
    {
      "city": "จตุรพักตรพิมาน",
      "cityCode": 4504,
      "provinceCode": 45,
      "subDistrict": [
        {
          "id": 450407,
          "name": "ดงกลาง",
          "postCode": [
            "45180"
          ]
        },
        {
          "id": 450406,
          "name": "ดงแดง",
          "postCode": [
            "45180"
          ]
        },
        {
          "id": 450411,
          "name": "ดู่น้อย",
          "postCode": [
            "45180"
          ]
        },
        {
          "id": 450405,
          "name": "น้ำใส",
          "postCode": [
            "45180"
          ]
        },
        {
          "id": 450408,
          "name": "ป่าสังข์",
          "postCode": [
            "45180"
          ]
        },
        {
          "id": 450410,
          "name": "ลิ้นฟ้า",
          "postCode": [
            "45180"
          ]
        },
        {
          "id": 450412,
          "name": "ศรีโคตร",
          "postCode": [
            "45180"
          ]
        },
        {
          "id": 450402,
          "name": "หนองผือ",
          "postCode": [
            "45180"
          ]
        },
        {
          "id": 450401,
          "name": "หัวช้าง",
          "postCode": [
            "45180"
          ]
        },
        {
          "id": 450409,
          "name": "อีง่อง",
          "postCode": [
            "45180"
          ]
        },
        {
          "id": 450403,
          "name": "เมืองหงส์",
          "postCode": [
            "45180"
          ]
        },
        {
          "id": 450404,
          "name": "โคกล่าม",
          "postCode": [
            "45180"
          ]
        }
      ]
    },
    {
      "city": "จังหาร",
      "cityCode": 4517,
      "provinceCode": 45,
      "subDistrict": [
        {
          "id": 451704,
          "name": "จังหาร",
          "postCode": [
            "45000"
          ]
        },
        {
          "id": 451705,
          "name": "ดงสิงห์",
          "postCode": [
            "45000"
          ]
        },
        {
          "id": 451701,
          "name": "ดินดำ",
          "postCode": [
            "45000"
          ]
        },
        {
          "id": 451702,
          "name": "ปาฝา",
          "postCode": [
            "45000"
          ]
        },
        {
          "id": 451707,
          "name": "ผักแว่น",
          "postCode": [
            "45000"
          ]
        },
        {
          "id": 451703,
          "name": "ม่วงลาด",
          "postCode": [
            "45000"
          ]
        },
        {
          "id": 451706,
          "name": "ยางใหญ่",
          "postCode": [
            "45000"
          ]
        },
        {
          "id": 451708,
          "name": "แสนชาติ",
          "postCode": [
            "45000"
          ]
        }
      ]
    },
    {
      "city": "ทุ่งเขาหลวง",
      "cityCode": 4520,
      "provinceCode": 45,
      "subDistrict": [
        {
          "id": 452001,
          "name": "ทุ่งเขาหลวง",
          "postCode": [
            "45170"
          ]
        },
        {
          "id": 452003,
          "name": "บึงงาม",
          "postCode": [
            "45170"
          ]
        },
        {
          "id": 452004,
          "name": "มะบ้า",
          "postCode": [
            "45170"
          ]
        },
        {
          "id": 452002,
          "name": "เทอดไทย",
          "postCode": [
            "45170"
          ]
        },
        {
          "id": 452005,
          "name": "เหล่า",
          "postCode": [
            "45170"
          ]
        }
      ]
    },
    {
      "city": "ธวัชบุรี",
      "cityCode": 4505,
      "provinceCode": 45,
      "subDistrict": [
        {
          "id": 450502,
          "name": "ธงธานี",
          "postCode": [
            "45170"
          ]
        },
        {
          "id": 450504,
          "name": "ธวัชบุรี",
          "postCode": [
            "45170"
          ]
        },
        {
          "id": 450501,
          "name": "นิเวศน์",
          "postCode": [
            "45170"
          ]
        },
        {
          "id": 450520,
          "name": "บึงนคร",
          "postCode": [
            "45170"
          ]
        },
        {
          "id": 450507,
          "name": "มะอึ",
          "postCode": [
            "45170"
          ]
        },
        {
          "id": 450522,
          "name": "ราชธานี",
          "postCode": [
            "45170"
          ]
        },
        {
          "id": 450524,
          "name": "หนองพอก",
          "postCode": [
            "45170"
          ]
        },
        {
          "id": 450503,
          "name": "หนองไผ่",
          "postCode": [
            "45170"
          ]
        },
        {
          "id": 450506,
          "name": "อุ่มเม้า",
          "postCode": [
            "45170"
          ]
        },
        {
          "id": 450510,
          "name": "เขวาทุ่ง",
          "postCode": [
            "45170"
          ]
        },
        {
          "id": 450517,
          "name": "เมืองน้อย",
          "postCode": [
            "45170"
          ]
        },
        {
          "id": 450515,
          "name": "ไพศาล",
          "postCode": [
            "45170"
          ]
        }
      ]
    },
    {
      "city": "ปทุมรัตต์",
      "cityCode": 4503,
      "provinceCode": 45,
      "subDistrict": [
        {
          "id": 450308,
          "name": "ขี้เหล็ก",
          "postCode": [
            "45190"
          ]
        },
        {
          "id": 450302,
          "name": "ดอกล้ำ",
          "postCode": [
            "45190"
          ]
        },
        {
          "id": 450301,
          "name": "บัวแดง",
          "postCode": [
            "45190"
          ]
        },
        {
          "id": 450306,
          "name": "สระบัว",
          "postCode": [
            "45190"
          ]
        },
        {
          "id": 450303,
          "name": "หนองแคน",
          "postCode": [
            "45190"
          ]
        },
        {
          "id": 450307,
          "name": "โนนสง่า",
          "postCode": [
            "45190"
          ]
        },
        {
          "id": 450305,
          "name": "โนนสวรรค์",
          "postCode": [
            "45190"
          ]
        },
        {
          "id": 450304,
          "name": "โพนสูง",
          "postCode": [
            "45190"
          ]
        }
      ]
    },
    {
      "city": "พนมไพร",
      "cityCode": 4506,
      "provinceCode": 45,
      "subDistrict": [
        {
          "id": 450603,
          "name": "กุดน้ำใส",
          "postCode": [
            "45140"
          ]
        },
        {
          "id": 450613,
          "name": "คำไฮ",
          "postCode": [
            "45140"
          ]
        },
        {
          "id": 450615,
          "name": "ค้อใหญ่",
          "postCode": [
            "45140"
          ]
        },
        {
          "id": 450617,
          "name": "ชานุวรรณ",
          "postCode": [
            "45140"
          ]
        },
        {
          "id": 450612,
          "name": "นานวล",
          "postCode": [
            "45140"
          ]
        },
        {
          "id": 450601,
          "name": "พนมไพร",
          "postCode": [
            "45140"
          ]
        },
        {
          "id": 450606,
          "name": "วารีสวัสดิ์",
          "postCode": [
            "45140"
          ]
        },
        {
          "id": 450614,
          "name": "สระแก้ว",
          "postCode": [
            "45140"
          ]
        },
        {
          "id": 450604,
          "name": "หนองทัพไทย",
          "postCode": [
            "45140"
          ]
        },
        {
          "id": 450602,
          "name": "แสนสุข",
          "postCode": [
            "45140"
          ]
        },
        {
          "id": 450607,
          "name": "โคกสว่าง",
          "postCode": [
            "45140"
          ]
        },
        {
          "id": 450611,
          "name": "โพธิ์ชัย",
          "postCode": [
            "45140"
          ]
        },
        {
          "id": 450605,
          "name": "โพธิ์ใหญ่",
          "postCode": [
            "45140"
          ]
        }
      ]
    },
    {
      "city": "ศรีสมเด็จ",
      "cityCode": 4516,
      "provinceCode": 45,
      "subDistrict": [
        {
          "id": 451608,
          "name": "บ้านบาก",
          "postCode": [
            "45000"
          ]
        },
        {
          "id": 451602,
          "name": "ศรีสมเด็จ",
          "postCode": [
            "45000"
          ]
        },
        {
          "id": 451605,
          "name": "สวนจิก",
          "postCode": [
            "45280"
          ]
        },
        {
          "id": 451607,
          "name": "หนองแวงควง",
          "postCode": [
            "45000"
          ]
        },
        {
          "id": 451604,
          "name": "หนองใหญ่",
          "postCode": [
            "45000"
          ]
        },
        {
          "id": 451603,
          "name": "เมืองเปลือย",
          "postCode": [
            "45000"
          ]
        },
        {
          "id": 451601,
          "name": "โพธิ์ทอง",
          "postCode": [
            "45000"
          ]
        },
        {
          "id": 451606,
          "name": "โพธิ์สัย",
          "postCode": [
            "45280"
          ]
        }
      ]
    },
    {
      "city": "สุวรรณภูมิ",
      "cityCode": 4511,
      "provinceCode": 45,
      "subDistrict": [
        {
          "id": 451115,
          "name": "จำปาขัน",
          "postCode": [
            "45130"
          ]
        },
        {
          "id": 451112,
          "name": "ช้างเผือก",
          "postCode": [
            "45130"
          ]
        },
        {
          "id": 451102,
          "name": "ดอกไม้",
          "postCode": [
            "45130"
          ]
        },
        {
          "id": 451113,
          "name": "ทุ่งกุลา",
          "postCode": [
            "45130"
          ]
        },
        {
          "id": 451114,
          "name": "ทุ่งศรีเมือง",
          "postCode": [
            "45130"
          ]
        },
        {
          "id": 451108,
          "name": "ทุ่งหลวง",
          "postCode": [
            "45130"
          ]
        },
        {
          "id": 451103,
          "name": "นาใหญ่",
          "postCode": [
            "45130"
          ]
        },
        {
          "id": 451110,
          "name": "น้ำคำ",
          "postCode": [
            "45130"
          ]
        },
        {
          "id": 451107,
          "name": "บ่อพันขัน",
          "postCode": [
            "45130"
          ]
        },
        {
          "id": 451101,
          "name": "สระคู",
          "postCode": [
            "45130"
          ]
        },
        {
          "id": 451109,
          "name": "หัวช้าง",
          "postCode": [
            "45130"
          ]
        },
        {
          "id": 451106,
          "name": "หัวโทน",
          "postCode": [
            "45130"
          ]
        },
        {
          "id": 451104,
          "name": "หินกอง",
          "postCode": [
            "45130"
          ]
        },
        {
          "id": 451111,
          "name": "ห้วยหินลาด",
          "postCode": [
            "45130"
          ]
        },
        {
          "id": 451105,
          "name": "เมืองทุ่ง",
          "postCode": [
            "45130"
          ]
        }
      ]
    },
    {
      "city": "หนองพอก",
      "cityCode": 4509,
      "provinceCode": 45,
      "subDistrict": [
        {
          "id": 450904,
          "name": "กกโพธิ์",
          "postCode": [
            "45210"
          ]
        },
        {
          "id": 450909,
          "name": "ท่าสีดา",
          "postCode": [
            "45210"
          ]
        },
        {
          "id": 450902,
          "name": "บึงงาม",
          "postCode": [
            "45210"
          ]
        },
        {
          "id": 450908,
          "name": "ผาน้ำย้อย",
          "postCode": [
            "45210"
          ]
        },
        {
          "id": 450903,
          "name": "ภูเขาทอง",
          "postCode": [
            "45210"
          ]
        },
        {
          "id": 450907,
          "name": "รอบเมือง",
          "postCode": [
            "45210"
          ]
        },
        {
          "id": 450906,
          "name": "หนองขุ่นใหญ่",
          "postCode": [
            "45210"
          ]
        },
        {
          "id": 450901,
          "name": "หนองพอก",
          "postCode": [
            "45210"
          ]
        },
        {
          "id": 450905,
          "name": "โคกสว่าง",
          "postCode": [
            "45210"
          ]
        }
      ]
    },
    {
      "city": "หนองฮี",
      "cityCode": 4519,
      "provinceCode": 45,
      "subDistrict": [
        {
          "id": 451903,
          "name": "ดูกอึ่ง",
          "postCode": [
            "45140"
          ]
        },
        {
          "id": 451902,
          "name": "สาวแห",
          "postCode": [
            "45140"
          ]
        },
        {
          "id": 451901,
          "name": "หนองฮี",
          "postCode": [
            "45140"
          ]
        },
        {
          "id": 451904,
          "name": "เด่นราษฎร์",
          "postCode": [
            "45140"
          ]
        }
      ]
    },
    {
      "city": "อาจสามารถ",
      "cityCode": 4514,
      "provinceCode": 45,
      "subDistrict": [
        {
          "id": 451409,
          "name": "ขี้เหล็ก",
          "postCode": [
            "45160"
          ]
        },
        {
          "id": 451410,
          "name": "บ้านดู่",
          "postCode": [
            "45160"
          ]
        },
        {
          "id": 451403,
          "name": "บ้านแจ้ง",
          "postCode": [
            "45160"
          ]
        },
        {
          "id": 451406,
          "name": "หนองขาม",
          "postCode": [
            "45160"
          ]
        },
        {
          "id": 451408,
          "name": "หนองบัว",
          "postCode": [
            "45160"
          ]
        },
        {
          "id": 451405,
          "name": "หนองหมื่นถ่าน",
          "postCode": [
            "45160"
          ]
        },
        {
          "id": 451404,
          "name": "หน่อม",
          "postCode": [
            "45160"
          ]
        },
        {
          "id": 451401,
          "name": "อาจสามารถ",
          "postCode": [
            "45160"
          ]
        },
        {
          "id": 451402,
          "name": "โพนเมือง",
          "postCode": [
            "45160"
          ]
        },
        {
          "id": 451407,
          "name": "โหรา",
          "postCode": [
            "45160"
          ]
        }
      ]
    },
    {
      "city": "เกษตรวิสัย",
      "cityCode": 4502,
      "provinceCode": 45,
      "subDistrict": [
        {
          "id": 450208,
          "name": "กำแพง",
          "postCode": [
            "45150"
          ]
        },
        {
          "id": 450209,
          "name": "กู่กาสิงห์",
          "postCode": [
            "45150"
          ]
        },
        {
          "id": 450213,
          "name": "ดงครั่งน้อย",
          "postCode": [
            "45150"
          ]
        },
        {
          "id": 450205,
          "name": "ดงครั่งใหญ่",
          "postCode": [
            "45150"
          ]
        },
        {
          "id": 450212,
          "name": "ทุ่งทอง",
          "postCode": [
            "45150"
          ]
        },
        {
          "id": 450210,
          "name": "น้ำอ้อม",
          "postCode": [
            "45150"
          ]
        },
        {
          "id": 450206,
          "name": "บ้านฝาง",
          "postCode": [
            "45150"
          ]
        },
        {
          "id": 450204,
          "name": "สิงห์โคก",
          "postCode": [
            "45150"
          ]
        },
        {
          "id": 450207,
          "name": "หนองแวง",
          "postCode": [
            "45150"
          ]
        },
        {
          "id": 450201,
          "name": "เกษตรวิสัย",
          "postCode": [
            "45150"
          ]
        },
        {
          "id": 450202,
          "name": "เมืองบัว",
          "postCode": [
            "45150"
          ]
        },
        {
          "id": 450203,
          "name": "เหล่าหลวง",
          "postCode": [
            "45150"
          ]
        },
        {
          "id": 450211,
          "name": "โนนสว่าง",
          "postCode": [
            "45150"
          ]
        }
      ]
    },
    {
      "city": "เชียงขวัญ",
      "cityCode": 4518,
      "provinceCode": 45,
      "subDistrict": [
        {
          "id": 451806,
          "name": "บ้านเขือง",
          "postCode": [
            "45000"
          ]
        },
        {
          "id": 451803,
          "name": "พระธาตุ",
          "postCode": [
            "45000"
          ]
        },
        {
          "id": 451804,
          "name": "พระเจ้า",
          "postCode": [
            "45000"
          ]
        },
        {
          "id": 451802,
          "name": "พลับพลา",
          "postCode": [
            "45170"
          ]
        },
        {
          "id": 451805,
          "name": "หมูม้น",
          "postCode": [
            "45170"
          ]
        },
        {
          "id": 451801,
          "name": "เชียงขวัญ",
          "postCode": [
            "45000"
          ]
        }
      ]
    },
    {
      "city": "เมยวดี",
      "cityCode": 4515,
      "provinceCode": 45,
      "subDistrict": [
        {
          "id": 451504,
          "name": "ชมสะอาด",
          "postCode": [
            "45250"
          ]
        },
        {
          "id": 451502,
          "name": "ชุมพร",
          "postCode": [
            "45250"
          ]
        },
        {
          "id": 451503,
          "name": "บุ่งเลิศ",
          "postCode": [
            "45250"
          ]
        },
        {
          "id": 451501,
          "name": "เมยวดี",
          "postCode": [
            "45250"
          ]
        }
      ]
    },
    {
      "city": "เมืองร้อยเอ็ด",
      "cityCode": 4501,
      "provinceCode": 45,
      "subDistrict": [
        {
          "id": 450104,
          "name": "ขอนแก่น",
          "postCode": [
            "45000"
          ]
        },
        {
          "id": 450120,
          "name": "ดงลาน",
          "postCode": [
            "45000"
          ]
        },
        {
          "id": 450105,
          "name": "นาโพธิ์",
          "postCode": [
            "45000"
          ]
        },
        {
          "id": 450109,
          "name": "ปอภาร",
          "postCode": [
            "45000"
          ]
        },
        {
          "id": 450102,
          "name": "รอบเมือง",
          "postCode": [
            "45000"
          ]
        },
        {
          "id": 450106,
          "name": "สะอาดสมบูรณ์",
          "postCode": [
            "45000"
          ]
        },
        {
          "id": 450108,
          "name": "สีแก้ว",
          "postCode": [
            "45000"
          ]
        },
        {
          "id": 450117,
          "name": "หนองแก้ว",
          "postCode": [
            "45000"
          ]
        },
        {
          "id": 450118,
          "name": "หนองแวง",
          "postCode": [
            "45000"
          ]
        },
        {
          "id": 450125,
          "name": "เมืองทอง",
          "postCode": [
            "45000"
          ]
        },
        {
          "id": 450103,
          "name": "เหนือเมือง",
          "postCode": [
            "45000"
          ]
        },
        {
          "id": 450123,
          "name": "แคนใหญ่",
          "postCode": [
            "45000"
          ]
        },
        {
          "id": 450124,
          "name": "โนนตาล",
          "postCode": [
            "45000"
          ]
        },
        {
          "id": 450110,
          "name": "โนนรัง",
          "postCode": [
            "45000"
          ]
        },
        {
          "id": 450101,
          "name": "ในเมือง",
          "postCode": [
            "45000"
          ]
        }
      ]
    },
    {
      "city": "เมืองสรวง",
      "cityCode": 4512,
      "provinceCode": 45,
      "subDistrict": [
        {
          "id": 451204,
          "name": "กกกุง",
          "postCode": [
            "45220"
          ]
        },
        {
          "id": 451203,
          "name": "คูเมือง",
          "postCode": [
            "45220"
          ]
        },
        {
          "id": 451201,
          "name": "หนองผือ",
          "postCode": [
            "45220"
          ]
        },
        {
          "id": 451202,
          "name": "หนองหิน",
          "postCode": [
            "45220"
          ]
        },
        {
          "id": 451205,
          "name": "เมืองสรวง",
          "postCode": [
            "45220"
          ]
        }
      ]
    },
    {
      "city": "เสลภูมิ",
      "cityCode": 4510,
      "provinceCode": 45,
      "subDistrict": [
        {
          "id": 451001,
          "name": "กลาง",
          "postCode": [
            "45120"
          ]
        },
        {
          "id": 451017,
          "name": "ขวัญเมือง",
          "postCode": [
            "45120"
          ]
        },
        {
          "id": 451008,
          "name": "ขวาว",
          "postCode": [
            "45120"
          ]
        },
        {
          "id": 451007,
          "name": "ท่าม่วง",
          "postCode": [
            "45120"
          ]
        },
        {
          "id": 451002,
          "name": "นางาม",
          "postCode": [
            "45120"
          ]
        },
        {
          "id": 451005,
          "name": "นาเมือง",
          "postCode": [
            "45120"
          ]
        },
        {
          "id": 451012,
          "name": "นาเลิง",
          "postCode": [
            "45120"
          ]
        },
        {
          "id": 451004,
          "name": "นาแซง",
          "postCode": [
            "45120"
          ]
        },
        {
          "id": 451018,
          "name": "บึงเกลือ",
          "postCode": [
            "45120"
          ]
        },
        {
          "id": 451016,
          "name": "พรสวรรค์",
          "postCode": [
            "45120"
          ]
        },
        {
          "id": 451010,
          "name": "ภูเงิน",
          "postCode": [
            "45120"
          ]
        },
        {
          "id": 451006,
          "name": "วังหลวง",
          "postCode": [
            "45120"
          ]
        },
        {
          "id": 451014,
          "name": "ศรีวิลัย",
          "postCode": [
            "45120"
          ]
        },
        {
          "id": 451015,
          "name": "หนองหลวง",
          "postCode": [
            "45120"
          ]
        },
        {
          "id": 451011,
          "name": "เกาะแก้ว",
          "postCode": [
            "45120"
          ]
        },
        {
          "id": 451003,
          "name": "เมืองไพร",
          "postCode": [
            "45120"
          ]
        },
        {
          "id": 451013,
          "name": "เหล่าน้อย",
          "postCode": [
            "45120"
          ]
        },
        {
          "id": 451009,
          "name": "โพธิ์ทอง",
          "postCode": [
            "45120"
          ]
        }
      ]
    },
    {
      "city": "โพธิ์ชัย",
      "cityCode": 4508,
      "provinceCode": 45,
      "subDistrict": [
        {
          "id": 450801,
          "name": "ขามเปี้ย",
          "postCode": [
            "45230"
          ]
        },
        {
          "id": 450806,
          "name": "คำพอุง",
          "postCode": [
            "45230"
          ]
        },
        {
          "id": 450808,
          "name": "ดอนโอง",
          "postCode": [
            "45230"
          ]
        },
        {
          "id": 450803,
          "name": "บัวคำ",
          "postCode": [
            "45230"
          ]
        },
        {
          "id": 450805,
          "name": "สะอาด",
          "postCode": [
            "45230"
          ]
        },
        {
          "id": 450807,
          "name": "หนองตาไก้",
          "postCode": [
            "45230"
          ]
        },
        {
          "id": 450804,
          "name": "อัคคะคำ",
          "postCode": [
            "45230"
          ]
        },
        {
          "id": 450802,
          "name": "เชียงใหม่",
          "postCode": [
            "45230"
          ]
        },
        {
          "id": 450809,
          "name": "โพธิ์ศรี",
          "postCode": [
            "45230"
          ]
        }
      ]
    },
    {
      "city": "โพนทราย",
      "cityCode": 4513,
      "provinceCode": 45,
      "subDistrict": [
        {
          "id": 451305,
          "name": "ท่าหาดยาว",
          "postCode": [
            "45240"
          ]
        },
        {
          "id": 451304,
          "name": "ยางคำ",
          "postCode": [
            "45240"
          ]
        },
        {
          "id": 451303,
          "name": "ศรีสว่าง",
          "postCode": [
            "45240"
          ]
        },
        {
          "id": 451302,
          "name": "สามขา",
          "postCode": [
            "45240"
          ]
        },
        {
          "id": 451301,
          "name": "โพนทราย",
          "postCode": [
            "45240"
          ]
        }
      ]
    },
    {
      "city": "โพนทอง",
      "cityCode": 4507,
      "provinceCode": 45,
      "subDistrict": [
        {
          "id": 450710,
          "name": "คำนาดี",
          "postCode": [
            "45110"
          ]
        },
        {
          "id": 450703,
          "name": "นาอุดม",
          "postCode": [
            "45110"
          ]
        },
        {
          "id": 450711,
          "name": "พรมสวรรค์",
          "postCode": [
            "45110"
          ]
        },
        {
          "id": 450713,
          "name": "วังสามัคคี",
          "postCode": [
            "45110"
          ]
        },
        {
          "id": 450712,
          "name": "สระนกแก้ว",
          "postCode": [
            "45110"
          ]
        },
        {
          "id": 450704,
          "name": "สว่าง",
          "postCode": [
            "45110"
          ]
        },
        {
          "id": 450705,
          "name": "หนองใหญ่",
          "postCode": [
            "45110"
          ]
        },
        {
          "id": 450709,
          "name": "อุ่มเม่า",
          "postCode": [
            "45110"
          ]
        },
        {
          "id": 450701,
          "name": "แวง",
          "postCode": [
            "45110"
          ]
        },
        {
          "id": 450702,
          "name": "โคกกกม่วง",
          "postCode": [
            "45110"
          ]
        },
        {
          "id": 450714,
          "name": "โคกสูง",
          "postCode": [
            "45110"
          ]
        },
        {
          "id": 450707,
          "name": "โนนชัยศรี",
          "postCode": [
            "45110"
          ]
        },
        {
          "id": 450706,
          "name": "โพธิ์ทอง",
          "postCode": [
            "45110"
          ]
        },
        {
          "id": 450708,
          "name": "โพธิ์ศรีสว่าง",
          "postCode": [
            "45110"
          ]
        }
      ]
    },
    {
      "city": "ชัยบาดาล",
      "cityCode": 1604,
      "provinceCode": 16,
      "subDistrict": [
        {
          "id": 160402,
          "name": "ชัยนารายณ์",
          "postCode": [
            "15130"
          ]
        },
        {
          "id": 160418,
          "name": "ชัยบาดาล",
          "postCode": [
            "15230"
          ]
        },
        {
          "id": 160409,
          "name": "ซับตะเคียน",
          "postCode": [
            "15130"
          ]
        },
        {
          "id": 160407,
          "name": "ท่าดินดำ",
          "postCode": [
            "15130"
          ]
        },
        {
          "id": 160414,
          "name": "ท่ามะนาว",
          "postCode": [
            "15130"
          ]
        },
        {
          "id": 160410,
          "name": "นาโสม",
          "postCode": [
            "15190"
          ]
        },
        {
          "id": 160417,
          "name": "นิคมลำนารายณ์",
          "postCode": [
            "15130"
          ]
        },
        {
          "id": 160406,
          "name": "บัวชุม",
          "postCode": [
            "15130"
          ]
        },
        {
          "id": 160419,
          "name": "บ้านใหม่สามัคคี",
          "postCode": [
            "15130"
          ]
        },
        {
          "id": 160408,
          "name": "มะกอกหวาน",
          "postCode": [
            "15230"
          ]
        },
        {
          "id": 160405,
          "name": "ม่วงค่อม",
          "postCode": [
            "15230"
          ]
        },
        {
          "id": 160401,
          "name": "ลำนารายณ์",
          "postCode": [
            "15130"
          ]
        },
        {
          "id": 160403,
          "name": "ศิลาทิพย์",
          "postCode": [
            "15130"
          ]
        },
        {
          "id": 160411,
          "name": "หนองยายโต๊ะ",
          "postCode": [
            "15130"
          ]
        },
        {
          "id": 160404,
          "name": "ห้วยหิน",
          "postCode": [
            "15130"
          ]
        },
        {
          "id": 160412,
          "name": "เกาะรัง",
          "postCode": [
            "15130"
          ]
        },
        {
          "id": 160422,
          "name": "เขาแหลม",
          "postCode": [
            "15130"
          ]
        }
      ]
    },
    {
      "city": "ท่าวุ้ง",
      "cityCode": 1605,
      "provinceCode": 16,
      "subDistrict": [
        {
          "id": 160501,
          "name": "ท่าวุ้ง",
          "postCode": [
            "15150"
          ]
        },
        {
          "id": 160502,
          "name": "บางคู้",
          "postCode": [
            "15150"
          ]
        },
        {
          "id": 160505,
          "name": "บางงา",
          "postCode": [
            "15150"
          ]
        },
        {
          "id": 160504,
          "name": "บางลี่",
          "postCode": [
            "15150"
          ]
        },
        {
          "id": 160510,
          "name": "บ้านเบิก",
          "postCode": [
            "15150"
          ]
        },
        {
          "id": 160511,
          "name": "มุจลินท์",
          "postCode": [
            "15150"
          ]
        },
        {
          "id": 160509,
          "name": "ลาดสาลี่",
          "postCode": [
            "15150"
          ]
        },
        {
          "id": 160508,
          "name": "หัวสำโรง",
          "postCode": [
            "15150"
          ]
        },
        {
          "id": 160507,
          "name": "เขาสมอคอน",
          "postCode": [
            "15180"
          ]
        },
        {
          "id": 160506,
          "name": "โคกสลุด",
          "postCode": [
            "15150"
          ]
        },
        {
          "id": 160503,
          "name": "โพตลาดแก้ว",
          "postCode": [
            "15150"
          ]
        }
      ]
    },
    {
      "city": "ท่าหลวง",
      "cityCode": 1607,
      "provinceCode": 16,
      "subDistrict": [
        {
          "id": 160703,
          "name": "ซับจำปา",
          "postCode": [
            "15230"
          ]
        },
        {
          "id": 160705,
          "name": "ทะเลวังวัด",
          "postCode": [
            "15230"
          ]
        },
        {
          "id": 160701,
          "name": "ท่าหลวง",
          "postCode": [
            "15230"
          ]
        },
        {
          "id": 160704,
          "name": "หนองผักแว่น",
          "postCode": [
            "15230"
          ]
        },
        {
          "id": 160706,
          "name": "หัวลำ",
          "postCode": [
            "15230"
          ]
        },
        {
          "id": 160702,
          "name": "แก่งผักกูด",
          "postCode": [
            "15230"
          ]
        }
      ]
    },
    {
      "city": "บ้านหมี่",
      "cityCode": 1606,
      "provinceCode": 16,
      "subDistrict": [
        {
          "id": 160615,
          "name": "ชอนม่วง",
          "postCode": [
            "15110"
          ]
        },
        {
          "id": 160604,
          "name": "ดงพลับ",
          "postCode": [
            "15110"
          ]
        },
        {
          "id": 160614,
          "name": "ดอนดึง",
          "postCode": [
            "15110"
          ]
        },
        {
          "id": 160610,
          "name": "บางกะพี้",
          "postCode": [
            "15110"
          ]
        },
        {
          "id": 160613,
          "name": "บางขาม",
          "postCode": [
            "15180"
          ]
        },
        {
          "id": 160608,
          "name": "บางพึ่ง",
          "postCode": [
            "15110",
            "15180"
          ]
        },
        {
          "id": 160603,
          "name": "บ้านกล้วย",
          "postCode": [
            "15110"
          ]
        },
        {
          "id": 160605,
          "name": "บ้านชี",
          "postCode": [
            "15180"
          ]
        },
        {
          "id": 160602,
          "name": "บ้านทราย",
          "postCode": [
            "15110"
          ]
        },
        {
          "id": 160619,
          "name": "บ้านหมี่",
          "postCode": [
            "15110"
          ]
        },
        {
          "id": 160606,
          "name": "พุคา",
          "postCode": [
            "15110"
          ]
        },
        {
          "id": 160618,
          "name": "มหาสอน",
          "postCode": [
            "15110"
          ]
        },
        {
          "id": 160622,
          "name": "สนามแจง",
          "postCode": [
            "15110"
          ]
        },
        {
          "id": 160617,
          "name": "สายห้วยแก้ว",
          "postCode": [
            "15110"
          ]
        },
        {
          "id": 160616,
          "name": "หนองกระเบียน",
          "postCode": [
            "15110"
          ]
        },
        {
          "id": 160609,
          "name": "หนองทรายขาว",
          "postCode": [
            "15110"
          ]
        },
        {
          "id": 160611,
          "name": "หนองเต่า",
          "postCode": [
            "15110"
          ]
        },
        {
          "id": 160621,
          "name": "หนองเมือง",
          "postCode": [
            "15110"
          ]
        },
        {
          "id": 160607,
          "name": "หินปัก",
          "postCode": [
            "15110"
          ]
        },
        {
          "id": 160620,
          "name": "เชียงงา",
          "postCode": [
            "15110"
          ]
        },
        {
          "id": 160612,
          "name": "โพนทอง",
          "postCode": [
            "15110"
          ]
        },
        {
          "id": 160601,
          "name": "ไผ่ใหญ่",
          "postCode": [
            "15110"
          ]
        }
      ]
    },
    {
      "city": "พัฒนานิคม",
      "cityCode": 1602,
      "provinceCode": 16,
      "subDistrict": [
        {
          "id": 160206,
          "name": "ชอนน้อย",
          "postCode": [
            "15140"
          ]
        },
        {
          "id": 160202,
          "name": "ช่องสาริกา",
          "postCode": [
            "15220"
          ]
        },
        {
          "id": 160204,
          "name": "ดีลัง",
          "postCode": [
            "15220"
          ]
        },
        {
          "id": 160209,
          "name": "น้ำสุด",
          "postCode": [
            "15140"
          ]
        },
        {
          "id": 160201,
          "name": "พัฒนานิคม",
          "postCode": [
            "15140"
          ]
        },
        {
          "id": 160203,
          "name": "มะนาวหวาน",
          "postCode": [
            "15140"
          ]
        },
        {
          "id": 160207,
          "name": "หนองบัว",
          "postCode": [
            "15140"
          ]
        },
        {
          "id": 160208,
          "name": "ห้วยขุนราม",
          "postCode": [
            "18220"
          ]
        },
        {
          "id": 160205,
          "name": "โคกสลุง",
          "postCode": [
            "15140"
          ]
        }
      ]
    },
    {
      "city": "ลำสนธิ",
      "cityCode": 1610,
      "provinceCode": 16,
      "subDistrict": [
        {
          "id": 161004,
          "name": "กุดตาเพชร",
          "postCode": [
            "15190"
          ]
        },
        {
          "id": 161002,
          "name": "ซับสมบูรณ์",
          "postCode": [
            "15190"
          ]
        },
        {
          "id": 161001,
          "name": "ลำสนธิ",
          "postCode": [
            "15190"
          ]
        },
        {
          "id": 161003,
          "name": "หนองรี",
          "postCode": [
            "15190"
          ]
        },
        {
          "id": 161006,
          "name": "เขาน้อย",
          "postCode": [
            "15130"
          ]
        },
        {
          "id": 161005,
          "name": "เขารวก",
          "postCode": [
            "15190"
          ]
        }
      ]
    },
    {
      "city": "สระโบสถ์",
      "cityCode": 1608,
      "provinceCode": 16,
      "subDistrict": [
        {
          "id": 160803,
          "name": "ทุ่งท่าช้าง",
          "postCode": [
            "15240"
          ]
        },
        {
          "id": 160805,
          "name": "นิยมชัย",
          "postCode": [
            "15240"
          ]
        },
        {
          "id": 160802,
          "name": "มหาโพธิ",
          "postCode": [
            "15240",
            "15250"
          ]
        },
        {
          "id": 160801,
          "name": "สระโบสถ์",
          "postCode": [
            "15240"
          ]
        },
        {
          "id": 160804,
          "name": "ห้วยใหญ่",
          "postCode": [
            "15240"
          ]
        }
      ]
    },
    {
      "city": "หนองม่วง",
      "cityCode": 1611,
      "provinceCode": 16,
      "subDistrict": [
        {
          "id": 161104,
          "name": "ชอนสมบูรณ์",
          "postCode": [
            "15170"
          ]
        },
        {
          "id": 161106,
          "name": "ชอนสารเดช",
          "postCode": [
            "15170"
          ]
        },
        {
          "id": 161103,
          "name": "ดงดินแดง",
          "postCode": [
            "15170"
          ]
        },
        {
          "id": 161102,
          "name": "บ่อทอง",
          "postCode": [
            "15170"
          ]
        },
        {
          "id": 161105,
          "name": "ยางโทน",
          "postCode": [
            "15170"
          ]
        },
        {
          "id": 161101,
          "name": "หนองม่วง",
          "postCode": [
            "15170"
          ]
        }
      ]
    },
    {
      "city": "เมืองลพบุรี",
      "cityCode": 1601,
      "provinceCode": 16,
      "subDistrict": [
        {
          "id": 160103,
          "name": "กกโก",
          "postCode": [
            "15000"
          ]
        },
        {
          "id": 160110,
          "name": "งิ้วราย",
          "postCode": [
            "15000"
          ]
        },
        {
          "id": 160111,
          "name": "ดอนโพธิ์",
          "postCode": [
            "15000"
          ]
        },
        {
          "id": 160112,
          "name": "ตะลุง",
          "postCode": [
            "15000"
          ]
        },
        {
          "id": 160125,
          "name": "ถนนใหญ่",
          "postCode": [
            "15000"
          ]
        },
        {
          "id": 160101,
          "name": "ทะเลชุบศร",
          "postCode": [
            "15000"
          ]
        },
        {
          "id": 160115,
          "name": "ท่าศาลา",
          "postCode": [
            "15000"
          ]
        },
        {
          "id": 160102,
          "name": "ท่าหิน",
          "postCode": [
            "15000"
          ]
        },
        {
          "id": 160114,
          "name": "ท่าแค",
          "postCode": [
            "15000",
            "15160"
          ]
        },
        {
          "id": 160119,
          "name": "ท้ายตลาด",
          "postCode": [
            "15000"
          ]
        },
        {
          "id": 160116,
          "name": "นิคมสร้างตนเอง",
          "postCode": [
            "15000"
          ]
        },
        {
          "id": 160117,
          "name": "บางขันหมาก",
          "postCode": [
            "15000",
            "15160"
          ]
        },
        {
          "id": 160118,
          "name": "บ้านข่อย",
          "postCode": [
            "15000"
          ]
        },
        {
          "id": 160120,
          "name": "ป่าตาล",
          "postCode": [
            "15000"
          ]
        },
        {
          "id": 160121,
          "name": "พรหมมาสตร์",
          "postCode": [
            "15000"
          ]
        },
        {
          "id": 160124,
          "name": "สี่คลอง",
          "postCode": [
            "15000"
          ]
        },
        {
          "id": 160105,
          "name": "เขาพระงาม",
          "postCode": [
            "15160"
          ]
        },
        {
          "id": 160106,
          "name": "เขาสามยอด",
          "postCode": [
            "15000"
          ]
        },
        {
          "id": 160104,
          "name": "โก่งธนู",
          "postCode": [
            "13240"
          ]
        },
        {
          "id": 160107,
          "name": "โคกกะเทียม",
          "postCode": [
            "15160"
          ]
        },
        {
          "id": 160109,
          "name": "โคกตูม",
          "postCode": [
            "15210"
          ]
        },
        {
          "id": 160108,
          "name": "โคกลำพาน",
          "postCode": [
            "15000"
          ]
        },
        {
          "id": 160123,
          "name": "โพธิ์ตรุ",
          "postCode": [
            "15000"
          ]
        },
        {
          "id": 160122,
          "name": "โพธิ์เก้าต้น",
          "postCode": [
            "15000"
          ]
        }
      ]
    },
    {
      "city": "โคกสำโรง",
      "cityCode": 1603,
      "provinceCode": 16,
      "subDistrict": [
        {
          "id": 160306,
          "name": "คลองเกตุ",
          "postCode": [
            "15120"
          ]
        },
        {
          "id": 160310,
          "name": "ดงมะรุม",
          "postCode": [
            "15120"
          ]
        },
        {
          "id": 160303,
          "name": "ถลุงเหล็ก",
          "postCode": [
            "15120"
          ]
        },
        {
          "id": 160318,
          "name": "วังขอนขว้าง",
          "postCode": [
            "15120"
          ]
        },
        {
          "id": 160320,
          "name": "วังจั่น",
          "postCode": [
            "15120"
          ]
        },
        {
          "id": 160309,
          "name": "วังเพลิง",
          "postCode": [
            "15120"
          ]
        },
        {
          "id": 160307,
          "name": "สะแกราบ",
          "postCode": [
            "15120"
          ]
        },
        {
          "id": 160322,
          "name": "หนองแขม",
          "postCode": [
            "15120"
          ]
        },
        {
          "id": 160304,
          "name": "หลุมข้าว",
          "postCode": [
            "15120"
          ]
        },
        {
          "id": 160305,
          "name": "ห้วยโป่ง",
          "postCode": [
            "15120"
          ]
        },
        {
          "id": 160302,
          "name": "เกาะแก้ว",
          "postCode": [
            "15120"
          ]
        },
        {
          "id": 160308,
          "name": "เพนียด",
          "postCode": [
            "15120"
          ]
        },
        {
          "id": 160301,
          "name": "โคกสำโรง",
          "postCode": [
            "15120"
          ]
        }
      ]
    },
    {
      "city": "โคกเจริญ",
      "cityCode": 1609,
      "provinceCode": 16,
      "subDistrict": [
        {
          "id": 160902,
          "name": "ยางราก",
          "postCode": [
            "15250"
          ]
        },
        {
          "id": 160904,
          "name": "วังทอง",
          "postCode": [
            "15250"
          ]
        },
        {
          "id": 160903,
          "name": "หนองมะค่า",
          "postCode": [
            "15250"
          ]
        },
        {
          "id": 160901,
          "name": "โคกเจริญ",
          "postCode": [
            "15250"
          ]
        },
        {
          "id": 160905,
          "name": "โคกแสมสาร",
          "postCode": [
            "15250"
          ]
        }
      ]
    },
    {
      "city": "งาว",
      "cityCode": 5205,
      "provinceCode": 52,
      "subDistrict": [
        {
          "id": 520506,
          "name": "นาแก",
          "postCode": [
            "52110"
          ]
        },
        {
          "id": 520504,
          "name": "บ้านร้อง",
          "postCode": [
            "52110"
          ]
        },
        {
          "id": 520509,
          "name": "บ้านหวด",
          "postCode": [
            "52110"
          ]
        },
        {
          "id": 520507,
          "name": "บ้านอ้อน",
          "postCode": [
            "52110"
          ]
        },
        {
          "id": 520508,
          "name": "บ้านแหง",
          "postCode": [
            "52110"
          ]
        },
        {
          "id": 520503,
          "name": "บ้านโป่ง",
          "postCode": [
            "52110"
          ]
        },
        {
          "id": 520505,
          "name": "ปงเตา",
          "postCode": [
            "52110"
          ]
        },
        {
          "id": 520501,
          "name": "หลวงเหนือ",
          "postCode": [
            "52110"
          ]
        },
        {
          "id": 520502,
          "name": "หลวงใต้",
          "postCode": [
            "52110"
          ]
        },
        {
          "id": 520510,
          "name": "แม่ตีบ",
          "postCode": [
            "52110"
          ]
        }
      ]
    },
    {
      "city": "วังเหนือ",
      "cityCode": 5207,
      "provinceCode": 52,
      "subDistrict": [
        {
          "id": 520701,
          "name": "ทุ่งฮั้ว",
          "postCode": [
            "52140"
          ]
        },
        {
          "id": 520704,
          "name": "ร่องเคาะ",
          "postCode": [
            "52140"
          ]
        },
        {
          "id": 520706,
          "name": "วังซ้าย",
          "postCode": [
            "52140"
          ]
        },
        {
          "id": 520708,
          "name": "วังทรายคำ",
          "postCode": [
            "52140"
          ]
        },
        {
          "id": 520705,
          "name": "วังทอง",
          "postCode": [
            "52140"
          ]
        },
        {
          "id": 520702,
          "name": "วังเหนือ",
          "postCode": [
            "52140"
          ]
        },
        {
          "id": 520707,
          "name": "วังแก้ว",
          "postCode": [
            "52140"
          ]
        },
        {
          "id": 520703,
          "name": "วังใต้",
          "postCode": [
            "52140"
          ]
        }
      ]
    },
    {
      "city": "สบปราบ",
      "cityCode": 5211,
      "provinceCode": 52,
      "subDistrict": [
        {
          "id": 521104,
          "name": "นายาง",
          "postCode": [
            "52170"
          ]
        },
        {
          "id": 521101,
          "name": "สบปราบ",
          "postCode": [
            "52170"
          ]
        },
        {
          "id": 521102,
          "name": "สมัย",
          "postCode": [
            "52170"
          ]
        },
        {
          "id": 521103,
          "name": "แม่กัวะ",
          "postCode": [
            "52170"
          ]
        }
      ]
    },
    {
      "city": "ห้างฉัตร",
      "cityCode": 5212,
      "provinceCode": 52,
      "subDistrict": [
        {
          "id": 521204,
          "name": "ปงยางคก",
          "postCode": [
            "52190"
          ]
        },
        {
          "id": 521207,
          "name": "วอแก้ว",
          "postCode": [
            "52190"
          ]
        },
        {
          "id": 521202,
          "name": "หนองหล่ม",
          "postCode": [
            "52190"
          ]
        },
        {
          "id": 521201,
          "name": "ห้างฉัตร",
          "postCode": [
            "52190"
          ]
        },
        {
          "id": 521203,
          "name": "เมืองยาว",
          "postCode": [
            "52190"
          ]
        },
        {
          "id": 521205,
          "name": "เวียงตาล",
          "postCode": [
            "52190"
          ]
        },
        {
          "id": 521206,
          "name": "แม่สัน",
          "postCode": [
            "52190"
          ]
        }
      ]
    },
    {
      "city": "เกาะคา",
      "cityCode": 5203,
      "provinceCode": 52,
      "subDistrict": [
        {
          "id": 520308,
          "name": "ท่าผา",
          "postCode": [
            "52130"
          ]
        },
        {
          "id": 520302,
          "name": "นาแก้ว",
          "postCode": [
            "52130"
          ]
        },
        {
          "id": 520307,
          "name": "นาแส่ง",
          "postCode": [
            "52130"
          ]
        },
        {
          "id": 520301,
          "name": "ลำปางหลวง",
          "postCode": [
            "52130"
          ]
        },
        {
          "id": 520304,
          "name": "วังพร้าว",
          "postCode": [
            "52130"
          ]
        },
        {
          "id": 520305,
          "name": "ศาลา",
          "postCode": [
            "52130"
          ]
        },
        {
          "id": 520306,
          "name": "เกาะคา",
          "postCode": [
            "52130"
          ]
        },
        {
          "id": 520309,
          "name": "ใหม่พัฒนา",
          "postCode": [
            "52130"
          ]
        },
        {
          "id": 520303,
          "name": "ไหล่หิน",
          "postCode": [
            "52130"
          ]
        }
      ]
    },
    {
      "city": "เถิน",
      "cityCode": 5208,
      "provinceCode": 52,
      "subDistrict": [
        {
          "id": 520806,
          "name": "นาโป่ง",
          "postCode": [
            "52160"
          ]
        },
        {
          "id": 520801,
          "name": "ล้อมแรด",
          "postCode": [
            "52160"
          ]
        },
        {
          "id": 520808,
          "name": "เถินบุรี",
          "postCode": [
            "52160"
          ]
        },
        {
          "id": 520805,
          "name": "เวียงมอก",
          "postCode": [
            "52160"
          ]
        },
        {
          "id": 520807,
          "name": "แม่ถอด",
          "postCode": [
            "52160"
          ]
        },
        {
          "id": 520803,
          "name": "แม่ปะ",
          "postCode": [
            "52160"
          ]
        },
        {
          "id": 520804,
          "name": "แม่มอก",
          "postCode": [
            "52160"
          ]
        },
        {
          "id": 520802,
          "name": "แม่วะ",
          "postCode": [
            "52230"
          ]
        }
      ]
    },
    {
      "city": "เมืองปาน",
      "cityCode": 5213,
      "provinceCode": 52,
      "subDistrict": [
        {
          "id": 521303,
          "name": "ทุ่งกว๋าว",
          "postCode": [
            "52240"
          ]
        },
        {
          "id": 521302,
          "name": "บ้านขอ",
          "postCode": [
            "52240"
          ]
        },
        {
          "id": 521305,
          "name": "หัวเมือง",
          "postCode": [
            "52240"
          ]
        },
        {
          "id": 521301,
          "name": "เมืองปาน",
          "postCode": [
            "52240"
          ]
        },
        {
          "id": 521304,
          "name": "แจ้ซ้อน",
          "postCode": [
            "52240"
          ]
        }
      ]
    },
    {
      "city": "เมืองลำปาง",
      "cityCode": 5201,
      "provinceCode": 52,
      "subDistrict": [
        {
          "id": 520107,
          "name": "กล้วยแพะ",
          "postCode": [
            "52000"
          ]
        },
        {
          "id": 520106,
          "name": "ชมพู",
          "postCode": [
            "52100"
          ]
        },
        {
          "id": 520117,
          "name": "ต้นธงชัย",
          "postCode": [
            "52000"
          ]
        },
        {
          "id": 520112,
          "name": "ทุ่งฝาย",
          "postCode": [
            "52000"
          ]
        },
        {
          "id": 520118,
          "name": "นิคมพัฒนา",
          "postCode": [
            "52000"
          ]
        },
        {
          "id": 520119,
          "name": "บุญนาคพัฒนา",
          "postCode": [
            "52000"
          ]
        },
        {
          "id": 520116,
          "name": "บ่อแฮ้ว",
          "postCode": [
            "52100"
          ]
        },
        {
          "id": 520115,
          "name": "บ้านค่า",
          "postCode": [
            "52100"
          ]
        },
        {
          "id": 520114,
          "name": "บ้านเป้า",
          "postCode": [
            "52100"
          ]
        },
        {
          "id": 520110,
          "name": "บ้านเสด็จ",
          "postCode": [
            "52000"
          ]
        },
        {
          "id": 520113,
          "name": "บ้านเอื้อม",
          "postCode": [
            "52100"
          ]
        },
        {
          "id": 520109,
          "name": "บ้านแลง",
          "postCode": [
            "52000"
          ]
        },
        {
          "id": 520108,
          "name": "ปงแสนทอง",
          "postCode": [
            "52100"
          ]
        },
        {
          "id": 520105,
          "name": "พระบาท",
          "postCode": [
            "52000",
            "52220"
          ]
        },
        {
          "id": 520111,
          "name": "พิชัย",
          "postCode": [
            "52000"
          ]
        },
        {
          "id": 520104,
          "name": "สบตุ๋ย",
          "postCode": [
            "52100"
          ]
        },
        {
          "id": 520103,
          "name": "สวนดอก",
          "postCode": [
            "52100"
          ]
        },
        {
          "id": 520102,
          "name": "หัวเวียง",
          "postCode": [
            "52000"
          ]
        },
        {
          "id": 520101,
          "name": "เวียงเหนือ",
          "postCode": [
            "52000"
          ]
        }
      ]
    },
    {
      "city": "เสริมงาม",
      "cityCode": 5204,
      "provinceCode": 52,
      "subDistrict": [
        {
          "id": 520401,
          "name": "ทุ่งงาม",
          "postCode": [
            "52210"
          ]
        },
        {
          "id": 520404,
          "name": "เสริมกลาง",
          "postCode": [
            "52210"
          ]
        },
        {
          "id": 520402,
          "name": "เสริมขวา",
          "postCode": [
            "52210"
          ]
        },
        {
          "id": 520403,
          "name": "เสริมซ้าย",
          "postCode": [
            "52210"
          ]
        }
      ]
    },
    {
      "city": "แจ้ห่ม",
      "cityCode": 5206,
      "provinceCode": 52,
      "subDistrict": [
        {
          "id": 520606,
          "name": "ทุ่งผึ้ง",
          "postCode": [
            "52120"
          ]
        },
        {
          "id": 520602,
          "name": "บ้านสา",
          "postCode": [
            "52120"
          ]
        },
        {
          "id": 520603,
          "name": "ปงดอน",
          "postCode": [
            "52120"
          ]
        },
        {
          "id": 520607,
          "name": "วิเชตนคร",
          "postCode": [
            "52120"
          ]
        },
        {
          "id": 520605,
          "name": "เมืองมาย",
          "postCode": [
            "52120"
          ]
        },
        {
          "id": 520601,
          "name": "แจ้ห่ม",
          "postCode": [
            "52120"
          ]
        },
        {
          "id": 520604,
          "name": "แม่สุก",
          "postCode": [
            "52120"
          ]
        }
      ]
    },
    {
      "city": "แม่ทะ",
      "cityCode": 5210,
      "provinceCode": 52,
      "subDistrict": [
        {
          "id": 521007,
          "name": "ดอนไฟ",
          "postCode": [
            "52150"
          ]
        },
        {
          "id": 521002,
          "name": "นาครัว",
          "postCode": [
            "52150"
          ]
        },
        {
          "id": 521006,
          "name": "น้ำโจ้",
          "postCode": [
            "52150"
          ]
        },
        {
          "id": 521004,
          "name": "บ้านกิ่ว",
          "postCode": [
            "52150"
          ]
        },
        {
          "id": 521005,
          "name": "บ้านบอม",
          "postCode": [
            "52150"
          ]
        },
        {
          "id": 521003,
          "name": "ป่าตัน",
          "postCode": [
            "52150"
          ]
        },
        {
          "id": 521010,
          "name": "วังเงิน",
          "postCode": [
            "52150"
          ]
        },
        {
          "id": 521011,
          "name": "สันดอนแก้ว",
          "postCode": [
            "52150"
          ]
        },
        {
          "id": 521008,
          "name": "หัวเสือ",
          "postCode": [
            "52150"
          ]
        },
        {
          "id": 521001,
          "name": "แม่ทะ",
          "postCode": [
            "52150",
            "52220"
          ]
        }
      ]
    },
    {
      "city": "แม่พริก",
      "cityCode": 5209,
      "provinceCode": 52,
      "subDistrict": [
        {
          "id": 520902,
          "name": "ผาปัง",
          "postCode": [
            "52180"
          ]
        },
        {
          "id": 520904,
          "name": "พระบาทวังตวง",
          "postCode": [
            "52180",
            "52230"
          ]
        },
        {
          "id": 520903,
          "name": "แม่ปุ",
          "postCode": [
            "52180"
          ]
        },
        {
          "id": 520901,
          "name": "แม่พริก",
          "postCode": [
            "52180"
          ]
        }
      ]
    },
    {
      "city": "แม่เมาะ",
      "cityCode": 5202,
      "provinceCode": 52,
      "subDistrict": [
        {
          "id": 520203,
          "name": "จางเหนือ",
          "postCode": [
            "52220"
          ]
        },
        {
          "id": 520202,
          "name": "นาสัก",
          "postCode": [
            "52220"
          ]
        },
        {
          "id": 520201,
          "name": "บ้านดง",
          "postCode": [
            "52000",
            "52220"
          ]
        },
        {
          "id": 520205,
          "name": "สบป้าด",
          "postCode": [
            "52220"
          ]
        },
        {
          "id": 520204,
          "name": "แม่เมาะ",
          "postCode": [
            "52220"
          ]
        }
      ]
    },
    {
      "city": "ทุ่งหัวช้าง",
      "cityCode": 5105,
      "provinceCode": 51,
      "subDistrict": [
        {
          "id": 510503,
          "name": "ตะเคียนปม",
          "postCode": [
            "51160"
          ]
        },
        {
          "id": 510501,
          "name": "ทุ่งหัวช้าง",
          "postCode": [
            "51160"
          ]
        },
        {
          "id": 510502,
          "name": "บ้านปวง",
          "postCode": [
            "51160"
          ]
        }
      ]
    },
    {
      "city": "บ้านธิ",
      "cityCode": 5107,
      "provinceCode": 51,
      "subDistrict": [
        {
          "id": 510701,
          "name": "บ้านธิ",
          "postCode": [
            "51180"
          ]
        },
        {
          "id": 510702,
          "name": "ห้วยยาบ",
          "postCode": [
            "51180"
          ]
        }
      ]
    },
    {
      "city": "บ้านโฮ่ง",
      "cityCode": 5103,
      "provinceCode": 51,
      "subDistrict": [
        {
          "id": 510301,
          "name": "บ้านโฮ่ง",
          "postCode": [
            "51130"
          ]
        },
        {
          "id": 510302,
          "name": "ป่าพลู",
          "postCode": [
            "51130"
          ]
        },
        {
          "id": 510304,
          "name": "ศรีเตี้ย",
          "postCode": [
            "51130"
          ]
        },
        {
          "id": 510305,
          "name": "หนองปลาสะวาย",
          "postCode": [
            "51130"
          ]
        },
        {
          "id": 510303,
          "name": "เหล่ายาว",
          "postCode": [
            "51130"
          ]
        }
      ]
    },
    {
      "city": "ป่าซาง",
      "cityCode": 5106,
      "provinceCode": 51,
      "subDistrict": [
        {
          "id": 510607,
          "name": "ท่าตุ้ม",
          "postCode": [
            "51120"
          ]
        },
        {
          "id": 510611,
          "name": "นครเจดีย์",
          "postCode": [
            "51120"
          ]
        },
        {
          "id": 510608,
          "name": "น้ำดิบ",
          "postCode": [
            "51120"
          ]
        },
        {
          "id": 510605,
          "name": "บ้านเรือน",
          "postCode": [
            "51120"
          ]
        },
        {
          "id": 510601,
          "name": "ปากบ่อง",
          "postCode": [
            "51120"
          ]
        },
        {
          "id": 510602,
          "name": "ป่าซาง",
          "postCode": [
            "51120"
          ]
        },
        {
          "id": 510606,
          "name": "มะกอก",
          "postCode": [
            "51120"
          ]
        },
        {
          "id": 510604,
          "name": "ม่วงน้อย",
          "postCode": [
            "51120"
          ]
        },
        {
          "id": 510603,
          "name": "แม่แรง",
          "postCode": [
            "51120"
          ]
        }
      ]
    },
    {
      "city": "ลี้",
      "cityCode": 5104,
      "provinceCode": 51,
      "subDistrict": [
        {
          "id": 510405,
          "name": "ก้อ",
          "postCode": [
            "51110"
          ]
        },
        {
          "id": 510404,
          "name": "ดงดำ",
          "postCode": [
            "51110"
          ]
        },
        {
          "id": 510403,
          "name": "นาทราย",
          "postCode": [
            "51110"
          ]
        },
        {
          "id": 510408,
          "name": "ป่าไผ่",
          "postCode": [
            "51110"
          ]
        },
        {
          "id": 510401,
          "name": "ลี้",
          "postCode": [
            "51110"
          ]
        },
        {
          "id": 510409,
          "name": "ศรีวิชัย",
          "postCode": [
            "51110"
          ]
        },
        {
          "id": 510402,
          "name": "แม่ตืน",
          "postCode": [
            "51110"
          ]
        },
        {
          "id": 510406,
          "name": "แม่ลาน",
          "postCode": [
            "51110"
          ]
        }
      ]
    },
    {
      "city": "เมืองลำพูน",
      "cityCode": 5101,
      "provinceCode": 51,
      "subDistrict": [
        {
          "id": 510107,
          "name": "ต้นธง",
          "postCode": [
            "51000"
          ]
        },
        {
          "id": 510112,
          "name": "บ้านกลาง",
          "postCode": [
            "51000"
          ]
        },
        {
          "id": 510108,
          "name": "บ้านแป้น",
          "postCode": [
            "51000"
          ]
        },
        {
          "id": 510105,
          "name": "ประตูป่า",
          "postCode": [
            "51000"
          ]
        },
        {
          "id": 510110,
          "name": "ป่าสัก",
          "postCode": [
            "51000"
          ]
        },
        {
          "id": 510113,
          "name": "มะเขือแจ้",
          "postCode": [
            "51000"
          ]
        },
        {
          "id": 510106,
          "name": "ริมปิง",
          "postCode": [
            "51000"
          ]
        },
        {
          "id": 510116,
          "name": "ศรีบัวบาน",
          "postCode": [
            "51000"
          ]
        },
        {
          "id": 510104,
          "name": "หนองช้างคืน",
          "postCode": [
            "51150"
          ]
        },
        {
          "id": 510117,
          "name": "หนองหนาม",
          "postCode": [
            "51000"
          ]
        },
        {
          "id": 510103,
          "name": "อุโมงค์",
          "postCode": [
            "51150"
          ]
        },
        {
          "id": 510111,
          "name": "เวียงยอง",
          "postCode": [
            "51000"
          ]
        },
        {
          "id": 510102,
          "name": "เหมืองง่า",
          "postCode": [
            "51000"
          ]
        },
        {
          "id": 510109,
          "name": "เหมืองจี้",
          "postCode": [
            "51000"
          ]
        },
        {
          "id": 510101,
          "name": "ในเมือง",
          "postCode": [
            "51000"
          ]
        }
      ]
    },
    {
      "city": "เวียงหนองล่อง",
      "cityCode": 5108,
      "provinceCode": 51,
      "subDistrict": [
        {
          "id": 510803,
          "name": "วังผาง",
          "postCode": [
            "51120"
          ]
        },
        {
          "id": 510802,
          "name": "หนองยวง",
          "postCode": [
            "51120"
          ]
        },
        {
          "id": 510801,
          "name": "หนองล่อง",
          "postCode": [
            "51120"
          ]
        }
      ]
    },
    {
      "city": "แม่ทา",
      "cityCode": 5102,
      "provinceCode": 51,
      "subDistrict": [
        {
          "id": 510203,
          "name": "ทากาศ",
          "postCode": [
            "51170"
          ]
        },
        {
          "id": 510204,
          "name": "ทาขุมเงิน",
          "postCode": [
            "51170"
          ]
        },
        {
          "id": 510205,
          "name": "ทาทุ่งหลวง",
          "postCode": [
            "51170"
          ]
        },
        {
          "id": 510201,
          "name": "ทาปลาดุก",
          "postCode": [
            "51140"
          ]
        },
        {
          "id": 510202,
          "name": "ทาสบเส้า",
          "postCode": [
            "51140"
          ]
        },
        {
          "id": 510206,
          "name": "ทาแม่ลอบ",
          "postCode": [
            "51170"
          ]
        }
      ]
    },
    {
      "city": "กันทรลักษ์",
      "cityCode": 3304,
      "provinceCode": 33,
      "subDistrict": [
        {
          "id": 330414,
          "name": "กระแชง",
          "postCode": [
            "33110"
          ]
        },
        {
          "id": 330402,
          "name": "กุดเสลา",
          "postCode": [
            "33110"
          ]
        },
        {
          "id": 330420,
          "name": "ขนุน",
          "postCode": [
            "33110"
          ]
        },
        {
          "id": 330411,
          "name": "จานใหญ่",
          "postCode": [
            "33110"
          ]
        },
        {
          "id": 330413,
          "name": "ชำ",
          "postCode": [
            "33110"
          ]
        },
        {
          "id": 330409,
          "name": "ตระกาจ",
          "postCode": [
            "33110"
          ]
        },
        {
          "id": 330424,
          "name": "ทุ่งใหญ่",
          "postCode": [
            "33110"
          ]
        },
        {
          "id": 330406,
          "name": "น้ำอ้อม",
          "postCode": [
            "33110"
          ]
        },
        {
          "id": 330401,
          "name": "บึงมะลู",
          "postCode": [
            "33110"
          ]
        },
        {
          "id": 330425,
          "name": "ภูผาหมอก",
          "postCode": [
            "33110"
          ]
        },
        {
          "id": 330412,
          "name": "ภูเงิน",
          "postCode": [
            "33110"
          ]
        },
        {
          "id": 330408,
          "name": "รุง",
          "postCode": [
            "33110"
          ]
        },
        {
          "id": 330407,
          "name": "ละลาย",
          "postCode": [
            "33110"
          ]
        },
        {
          "id": 330421,
          "name": "สวนกล้วย",
          "postCode": [
            "33110"
          ]
        },
        {
          "id": 330405,
          "name": "สังเม็ก",
          "postCode": [
            "33110"
          ]
        },
        {
          "id": 330416,
          "name": "หนองหญ้าลาด",
          "postCode": [
            "33110"
          ]
        },
        {
          "id": 330403,
          "name": "เมือง",
          "postCode": [
            "33110"
          ]
        },
        {
          "id": 330423,
          "name": "เวียงเหนือ",
          "postCode": [
            "33110"
          ]
        },
        {
          "id": 330419,
          "name": "เสาธงชัย",
          "postCode": [
            "33110"
          ]
        },
        {
          "id": 330415,
          "name": "โนนสำราญ",
          "postCode": [
            "33110"
          ]
        }
      ]
    },
    {
      "city": "กันทรารมย์",
      "cityCode": 3303,
      "provinceCode": 33,
      "subDistrict": [
        {
          "id": 330320,
          "name": "คำเนียม",
          "postCode": [
            "33130"
          ]
        },
        {
          "id": 330315,
          "name": "จาน",
          "postCode": [
            "33130"
          ]
        },
        {
          "id": 330301,
          "name": "ดูน",
          "postCode": [
            "33130"
          ]
        },
        {
          "id": 330313,
          "name": "ดู่",
          "postCode": [
            "33130"
          ]
        },
        {
          "id": 330307,
          "name": "ทาม",
          "postCode": [
            "33130"
          ]
        },
        {
          "id": 330311,
          "name": "บัวน้อย",
          "postCode": [
            "33130"
          ]
        },
        {
          "id": 330314,
          "name": "ผักแพว",
          "postCode": [
            "33130"
          ]
        },
        {
          "id": 330304,
          "name": "ยาง",
          "postCode": [
            "33130"
          ]
        },
        {
          "id": 330308,
          "name": "ละทาย",
          "postCode": [
            "33130"
          ]
        },
        {
          "id": 330312,
          "name": "หนองบัว",
          "postCode": [
            "33130"
          ]
        },
        {
          "id": 330303,
          "name": "หนองหัวช้าง",
          "postCode": [
            "33130"
          ]
        },
        {
          "id": 330306,
          "name": "หนองแก้ว",
          "postCode": [
            "33130"
          ]
        },
        {
          "id": 330305,
          "name": "หนองแวง",
          "postCode": [
            "33130"
          ]
        },
        {
          "id": 330310,
          "name": "อีปาด",
          "postCode": [
            "33130"
          ]
        },
        {
          "id": 330309,
          "name": "เมืองน้อย",
          "postCode": [
            "33130"
          ]
        },
        {
          "id": 330302,
          "name": "โนนสัง",
          "postCode": [
            "33130"
          ]
        }
      ]
    },
    {
      "city": "ขุขันธ์",
      "cityCode": 3305,
      "provinceCode": 33,
      "subDistrict": [
        {
          "id": 330524,
          "name": "กฤษณา",
          "postCode": [
            "33140"
          ]
        },
        {
          "id": 330501,
          "name": "กันทรารมย์",
          "postCode": [
            "33140"
          ]
        },
        {
          "id": 330502,
          "name": "จะกง",
          "postCode": [
            "33140"
          ]
        },
        {
          "id": 330504,
          "name": "ดองกำเม็ด",
          "postCode": [
            "33140"
          ]
        },
        {
          "id": 330513,
          "name": "ตะเคียน",
          "postCode": [
            "33140"
          ]
        },
        {
          "id": 330508,
          "name": "ตาอุด",
          "postCode": [
            "33140"
          ]
        },
        {
          "id": 330515,
          "name": "นิคมพัฒนา",
          "postCode": [
            "33140"
          ]
        },
        {
          "id": 330518,
          "name": "ปราสาท",
          "postCode": [
            "33140"
          ]
        },
        {
          "id": 330506,
          "name": "ปรือใหญ่",
          "postCode": [
            "33140"
          ]
        },
        {
          "id": 330525,
          "name": "ลมศักดิ์",
          "postCode": [
            "33140"
          ]
        },
        {
          "id": 330527,
          "name": "ศรีตระกูล",
          "postCode": [
            "33140"
          ]
        },
        {
          "id": 330528,
          "name": "ศรีสะอาด",
          "postCode": [
            "33140"
          ]
        },
        {
          "id": 330507,
          "name": "สะเดาใหญ่",
          "postCode": [
            "33140"
          ]
        },
        {
          "id": 330521,
          "name": "สำโรงตาเจ็น",
          "postCode": [
            "33140"
          ]
        },
        {
          "id": 330526,
          "name": "หนองฉลอง",
          "postCode": [
            "33140"
          ]
        },
        {
          "id": 330511,
          "name": "หัวเสือ",
          "postCode": [
            "33140"
          ]
        },
        {
          "id": 330522,
          "name": "ห้วยสำราญ",
          "postCode": [
            "33140"
          ]
        },
        {
          "id": 330509,
          "name": "ห้วยเหนือ",
          "postCode": [
            "33140"
          ]
        },
        {
          "id": 330510,
          "name": "ห้วยใต้",
          "postCode": [
            "33140"
          ]
        },
        {
          "id": 330517,
          "name": "โคกเพชร",
          "postCode": [
            "33140"
          ]
        },
        {
          "id": 330505,
          "name": "โสน",
          "postCode": [
            "33140"
          ]
        },
        {
          "id": 330503,
          "name": "ใจดี",
          "postCode": [
            "33140"
          ]
        }
      ]
    },
    {
      "city": "ขุนหาญ",
      "cityCode": 3308,
      "provinceCode": 33,
      "subDistrict": [
        {
          "id": 330806,
          "name": "กระหวัน",
          "postCode": [
            "33150"
          ]
        },
        {
          "id": 330809,
          "name": "กันทรอม",
          "postCode": [
            "33150"
          ]
        },
        {
          "id": 330807,
          "name": "ขุนหาญ",
          "postCode": [
            "33150"
          ]
        },
        {
          "id": 330802,
          "name": "บักดอง",
          "postCode": [
            "33150"
          ]
        },
        {
          "id": 330803,
          "name": "พราน",
          "postCode": [
            "33150"
          ]
        },
        {
          "id": 330810,
          "name": "ภูฝ้าย",
          "postCode": [
            "33150"
          ]
        },
        {
          "id": 330801,
          "name": "สิ",
          "postCode": [
            "33150"
          ]
        },
        {
          "id": 330812,
          "name": "ห้วยจันทร์",
          "postCode": [
            "33150"
          ]
        },
        {
          "id": 330808,
          "name": "โนนสูง",
          "postCode": [
            "33150"
          ]
        },
        {
          "id": 330811,
          "name": "โพธิ์กระสังข์",
          "postCode": [
            "33150"
          ]
        },
        {
          "id": 330804,
          "name": "โพธิ์วงศ์",
          "postCode": [
            "33150"
          ]
        },
        {
          "id": 330805,
          "name": "ไพร",
          "postCode": [
            "33150"
          ]
        }
      ]
    },
    {
      "city": "น้ำเกลี้ยง",
      "cityCode": 3315,
      "provinceCode": 33,
      "subDistrict": [
        {
          "id": 331506,
          "name": "คูบ",
          "postCode": [
            "33130"
          ]
        },
        {
          "id": 331503,
          "name": "ตองปิด",
          "postCode": [
            "33130"
          ]
        },
        {
          "id": 331501,
          "name": "น้ำเกลี้ยง",
          "postCode": [
            "33130"
          ]
        },
        {
          "id": 331505,
          "name": "รุ่งระวี",
          "postCode": [
            "33130"
          ]
        },
        {
          "id": 331502,
          "name": "ละเอาะ",
          "postCode": [
            "33130"
          ]
        },
        {
          "id": 331504,
          "name": "เขิน",
          "postCode": [
            "33130"
          ]
        }
      ]
    },
    {
      "city": "บึงบูรพ์",
      "cityCode": 3311,
      "provinceCode": 33,
      "subDistrict": [
        {
          "id": 331102,
          "name": "บึงบูรพ์",
          "postCode": [
            "33220"
          ]
        },
        {
          "id": 331101,
          "name": "เป๊าะ",
          "postCode": [
            "33220"
          ]
        }
      ]
    },
    {
      "city": "ปรางค์กู่",
      "cityCode": 3307,
      "provinceCode": 33,
      "subDistrict": [
        {
          "id": 330702,
          "name": "กู่",
          "postCode": [
            "33170"
          ]
        },
        {
          "id": 330708,
          "name": "ดู่",
          "postCode": [
            "33170"
          ]
        },
        {
          "id": 330704,
          "name": "ตูม",
          "postCode": [
            "33170"
          ]
        },
        {
          "id": 330701,
          "name": "พิมาย",
          "postCode": [
            "33170"
          ]
        },
        {
          "id": 330710,
          "name": "พิมายเหนือ",
          "postCode": [
            "33170"
          ]
        },
        {
          "id": 330705,
          "name": "สมอ",
          "postCode": [
            "33170"
          ]
        },
        {
          "id": 330709,
          "name": "สวาย",
          "postCode": [
            "33170"
          ]
        },
        {
          "id": 330707,
          "name": "สำโรงปราสาท",
          "postCode": [
            "33170"
          ]
        },
        {
          "id": 330703,
          "name": "หนองเชียงทูน",
          "postCode": [
            "33170"
          ]
        },
        {
          "id": 330706,
          "name": "โพธิ์ศรี",
          "postCode": [
            "33170"
          ]
        }
      ]
    },
    {
      "city": "พยุห์",
      "cityCode": 3320,
      "provinceCode": 33,
      "subDistrict": [
        {
          "id": 332003,
          "name": "ตำแย",
          "postCode": [
            "33230"
          ]
        },
        {
          "id": 332001,
          "name": "พยุห์",
          "postCode": [
            "33230"
          ]
        },
        {
          "id": 332002,
          "name": "พรหมสวัสดิ์",
          "postCode": [
            "33230"
          ]
        },
        {
          "id": 332005,
          "name": "หนองค้า",
          "postCode": [
            "33230"
          ]
        },
        {
          "id": 332004,
          "name": "โนนเพ็ก",
          "postCode": [
            "33230"
          ]
        }
      ]
    },
    {
      "city": "ภูสิงห์",
      "cityCode": 3317,
      "provinceCode": 33,
      "subDistrict": [
        {
          "id": 331706,
          "name": "ดงรัก",
          "postCode": [
            "33140"
          ]
        },
        {
          "id": 331705,
          "name": "ตะเคียนราม",
          "postCode": [
            "33140"
          ]
        },
        {
          "id": 331704,
          "name": "ละลม",
          "postCode": [
            "33140"
          ]
        },
        {
          "id": 331702,
          "name": "ห้วยตามอญ",
          "postCode": [
            "33140"
          ]
        },
        {
          "id": 331703,
          "name": "ห้วยตึ๊กชู",
          "postCode": [
            "33140"
          ]
        },
        {
          "id": 331701,
          "name": "โคกตาล",
          "postCode": [
            "33140"
          ]
        },
        {
          "id": 331707,
          "name": "ไพรพัฒนา",
          "postCode": [
            "33140"
          ]
        }
      ]
    },
    {
      "city": "ยางชุมน้อย",
      "cityCode": 3302,
      "provinceCode": 33,
      "subDistrict": [
        {
          "id": 330205,
          "name": "กุดเมืองฮาม",
          "postCode": [
            "33190"
          ]
        },
        {
          "id": 330203,
          "name": "คอนกาม",
          "postCode": [
            "33190"
          ]
        },
        {
          "id": 330206,
          "name": "บึงบอน",
          "postCode": [
            "33190"
          ]
        },
        {
          "id": 330201,
          "name": "ยางชุมน้อย",
          "postCode": [
            "33190"
          ]
        },
        {
          "id": 330207,
          "name": "ยางชุมใหญ่",
          "postCode": [
            "33190"
          ]
        },
        {
          "id": 330202,
          "name": "ลิ้นฟ้า",
          "postCode": [
            "33190"
          ]
        },
        {
          "id": 330204,
          "name": "โนนคูณ",
          "postCode": [
            "33190"
          ]
        }
      ]
    },
    {
      "city": "ราษีไศล",
      "cityCode": 3309,
      "provinceCode": 33,
      "subDistrict": [
        {
          "id": 330906,
          "name": "จิกสังข์ทอง",
          "postCode": [
            "33160"
          ]
        },
        {
          "id": 330908,
          "name": "ดู่",
          "postCode": [
            "33160"
          ]
        },
        {
          "id": 330907,
          "name": "ด่าน",
          "postCode": [
            "33160"
          ]
        },
        {
          "id": 330910,
          "name": "บัวหุ่ง",
          "postCode": [
            "33160"
          ]
        },
        {
          "id": 330915,
          "name": "สร้างปี่",
          "postCode": [
            "33160"
          ]
        },
        {
          "id": 330912,
          "name": "ส้มป่อย",
          "postCode": [
            "33160"
          ]
        },
        {
          "id": 330913,
          "name": "หนองหมี",
          "postCode": [
            "33160"
          ]
        },
        {
          "id": 330909,
          "name": "หนองอึ่ง",
          "postCode": [
            "33160"
          ]
        },
        {
          "id": 330903,
          "name": "หนองแค",
          "postCode": [
            "33160"
          ]
        },
        {
          "id": 330914,
          "name": "หว้านคำ",
          "postCode": [
            "33160"
          ]
        },
        {
          "id": 330901,
          "name": "เมืองคง",
          "postCode": [
            "33160"
          ]
        },
        {
          "id": 330902,
          "name": "เมืองแคน",
          "postCode": [
            "33160"
          ]
        },
        {
          "id": 330911,
          "name": "ไผ่",
          "postCode": [
            "33160"
          ]
        }
      ]
    },
    {
      "city": "วังหิน",
      "cityCode": 3316,
      "provinceCode": 33,
      "subDistrict": [
        {
          "id": 331603,
          "name": "ดวนใหญ่",
          "postCode": [
            "33270"
          ]
        },
        {
          "id": 331606,
          "name": "ทุ่งสว่าง",
          "postCode": [
            "33270"
          ]
        },
        {
          "id": 331602,
          "name": "ธาตุ",
          "postCode": [
            "33270"
          ]
        },
        {
          "id": 331601,
          "name": "บุสูง",
          "postCode": [
            "33270"
          ]
        },
        {
          "id": 331604,
          "name": "บ่อแก้ว",
          "postCode": [
            "33270"
          ]
        },
        {
          "id": 331607,
          "name": "วังหิน",
          "postCode": [
            "33270"
          ]
        },
        {
          "id": 331605,
          "name": "ศรีสำราญ",
          "postCode": [
            "33270"
          ]
        },
        {
          "id": 331608,
          "name": "โพนยาง",
          "postCode": [
            "33270"
          ]
        }
      ]
    },
    {
      "city": "ศรีรัตนะ",
      "cityCode": 3314,
      "provinceCode": 33,
      "subDistrict": [
        {
          "id": 331404,
          "name": "ตูม",
          "postCode": [
            "33240"
          ]
        },
        {
          "id": 331402,
          "name": "พิงพวย",
          "postCode": [
            "33240"
          ]
        },
        {
          "id": 331401,
          "name": "ศรีแก้ว",
          "postCode": [
            "33240"
          ]
        },
        {
          "id": 331406,
          "name": "ศรีโนนงาม",
          "postCode": [
            "33240"
          ]
        },
        {
          "id": 331403,
          "name": "สระเยาว์",
          "postCode": [
            "33240"
          ]
        },
        {
          "id": 331407,
          "name": "สะพุง",
          "postCode": [
            "33240"
          ]
        },
        {
          "id": 331405,
          "name": "เสื่องข้าว",
          "postCode": [
            "33240"
          ]
        }
      ]
    },
    {
      "city": "ศิลาลาด",
      "cityCode": 3322,
      "provinceCode": 33,
      "subDistrict": [
        {
          "id": 332201,
          "name": "กุง",
          "postCode": [
            "33160"
          ]
        },
        {
          "id": 332202,
          "name": "คลีกลิ้ง",
          "postCode": [
            "33160"
          ]
        },
        {
          "id": 332203,
          "name": "หนองบัวดง",
          "postCode": [
            "33160"
          ]
        },
        {
          "id": 332204,
          "name": "โจดม่วง",
          "postCode": [
            "33160"
          ]
        }
      ]
    },
    {
      "city": "ห้วยทับทัน",
      "cityCode": 3312,
      "provinceCode": 33,
      "subDistrict": [
        {
          "id": 331203,
          "name": "กล้วยกว้าง",
          "postCode": [
            "33210"
          ]
        },
        {
          "id": 331205,
          "name": "จานแสนไชย",
          "postCode": [
            "33210"
          ]
        },
        {
          "id": 331206,
          "name": "ปราสาท",
          "postCode": [
            "33210"
          ]
        },
        {
          "id": 331204,
          "name": "ผักไหม",
          "postCode": [
            "33210"
          ]
        },
        {
          "id": 331201,
          "name": "ห้วยทับทัน",
          "postCode": [
            "33210"
          ]
        },
        {
          "id": 331202,
          "name": "เมืองหลวง",
          "postCode": [
            "33210"
          ]
        }
      ]
    },
    {
      "city": "อุทุมพรพิสัย",
      "cityCode": 3310,
      "provinceCode": 33,
      "subDistrict": [
        {
          "id": 331001,
          "name": "กำแพง",
          "postCode": [
            "33120"
          ]
        },
        {
          "id": 331003,
          "name": "ก้านเหลือง",
          "postCode": [
            "33120"
          ]
        },
        {
          "id": 331008,
          "name": "ขะยูง",
          "postCode": [
            "33120"
          ]
        },
        {
          "id": 331010,
          "name": "ตาเกษ",
          "postCode": [
            "33120"
          ]
        },
        {
          "id": 331004,
          "name": "ทุ่งไชย",
          "postCode": [
            "33120"
          ]
        },
        {
          "id": 331017,
          "name": "ปะอาว",
          "postCode": [
            "33120"
          ]
        },
        {
          "id": 331012,
          "name": "รังแร้ง",
          "postCode": [
            "33120"
          ]
        },
        {
          "id": 331022,
          "name": "สระกำแพงใหญ่",
          "postCode": [
            "33120"
          ]
        },
        {
          "id": 331005,
          "name": "สำโรง",
          "postCode": [
            "33120"
          ]
        },
        {
          "id": 331018,
          "name": "หนองห้าง",
          "postCode": [
            "33120"
          ]
        },
        {
          "id": 331007,
          "name": "หนองไฮ",
          "postCode": [
            "33120"
          ]
        },
        {
          "id": 331011,
          "name": "หัวช้าง",
          "postCode": [
            "33120"
          ]
        },
        {
          "id": 331002,
          "name": "อี่หล่ำ",
          "postCode": [
            "33120"
          ]
        },
        {
          "id": 331006,
          "name": "แขม",
          "postCode": [
            "33120"
          ]
        },
        {
          "id": 331015,
          "name": "แข้",
          "postCode": [
            "33120"
          ]
        },
        {
          "id": 331014,
          "name": "แต้",
          "postCode": [
            "33120"
          ]
        },
        {
          "id": 331025,
          "name": "โคกจาน",
          "postCode": [
            "33120"
          ]
        },
        {
          "id": 331024,
          "name": "โคกหล่าม",
          "postCode": [
            "33120"
          ]
        },
        {
          "id": 331016,
          "name": "โพธิ์ชัย",
          "postCode": [
            "33120"
          ]
        }
      ]
    },
    {
      "city": "เบญจลักษ์",
      "cityCode": 3319,
      "provinceCode": 33,
      "subDistrict": [
        {
          "id": 331905,
          "name": "ท่าคล้อ",
          "postCode": [
            "33110"
          ]
        },
        {
          "id": 331903,
          "name": "หนองงูเหลือม",
          "postCode": [
            "33110"
          ]
        },
        {
          "id": 331902,
          "name": "หนองหว้า",
          "postCode": [
            "33110"
          ]
        },
        {
          "id": 331904,
          "name": "หนองฮาง",
          "postCode": [
            "33110"
          ]
        },
        {
          "id": 331901,
          "name": "เสียว",
          "postCode": [
            "33110"
          ]
        }
      ]
    },
    {
      "city": "เมืองจันทร์",
      "cityCode": 3318,
      "provinceCode": 33,
      "subDistrict": [
        {
          "id": 331802,
          "name": "ตาโกน",
          "postCode": [
            "33120"
          ]
        },
        {
          "id": 331803,
          "name": "หนองใหญ่",
          "postCode": [
            "33120"
          ]
        },
        {
          "id": 331801,
          "name": "เมืองจันทร์",
          "postCode": [
            "33120"
          ]
        }
      ]
    },
    {
      "city": "เมืองศรีสะเกษ",
      "cityCode": 3301,
      "provinceCode": 33,
      "subDistrict": [
        {
          "id": 330103,
          "name": "คูซอด",
          "postCode": [
            "33000"
          ]
        },
        {
          "id": 330105,
          "name": "จาน",
          "postCode": [
            "33000"
          ]
        },
        {
          "id": 330104,
          "name": "ซำ",
          "postCode": [
            "33000"
          ]
        },
        {
          "id": 330106,
          "name": "ตะดอบ",
          "postCode": [
            "33000"
          ]
        },
        {
          "id": 330118,
          "name": "ทุ่ม",
          "postCode": [
            "33000"
          ]
        },
        {
          "id": 330122,
          "name": "น้ำคำ",
          "postCode": [
            "33000"
          ]
        },
        {
          "id": 330116,
          "name": "หญ้าปล้อง",
          "postCode": [
            "33000"
          ]
        },
        {
          "id": 330107,
          "name": "หนองครก",
          "postCode": [
            "33000"
          ]
        },
        {
          "id": 330121,
          "name": "หนองแก้ว",
          "postCode": [
            "33000"
          ]
        },
        {
          "id": 330127,
          "name": "หนองไผ่",
          "postCode": [
            "33000"
          ]
        },
        {
          "id": 330119,
          "name": "หนองไฮ",
          "postCode": [
            "33000"
          ]
        },
        {
          "id": 330124,
          "name": "หมากเขียบ",
          "postCode": [
            "33000"
          ]
        },
        {
          "id": 330101,
          "name": "เมืองเหนือ",
          "postCode": [
            "33000"
          ]
        },
        {
          "id": 330102,
          "name": "เมืองใต้",
          "postCode": [
            "33000"
          ]
        },
        {
          "id": 330123,
          "name": "โพธิ์",
          "postCode": [
            "33000"
          ]
        },
        {
          "id": 330111,
          "name": "โพนข่า",
          "postCode": [
            "33000"
          ]
        },
        {
          "id": 330112,
          "name": "โพนค้อ",
          "postCode": [
            "33000"
          ]
        },
        {
          "id": 330115,
          "name": "โพนเขวา",
          "postCode": [
            "33000"
          ]
        }
      ]
    },
    {
      "city": "โนนคูณ",
      "cityCode": 3313,
      "provinceCode": 33,
      "subDistrict": [
        {
          "id": 331302,
          "name": "บก",
          "postCode": [
            "33250"
          ]
        },
        {
          "id": 331304,
          "name": "หนองกุง",
          "postCode": [
            "33250"
          ]
        },
        {
          "id": 331305,
          "name": "เหล่ากวาง",
          "postCode": [
            "33250"
          ]
        },
        {
          "id": 331301,
          "name": "โนนค้อ",
          "postCode": [
            "33250"
          ]
        },
        {
          "id": 331303,
          "name": "โพธิ์",
          "postCode": [
            "33250"
          ]
        }
      ]
    },
    {
      "city": "โพธิ์ศรีสุวรรณ",
      "cityCode": 3321,
      "provinceCode": 33,
      "subDistrict": [
        {
          "id": 332104,
          "name": "ผือใหญ่",
          "postCode": [
            "33120"
          ]
        },
        {
          "id": 332103,
          "name": "หนองม้า",
          "postCode": [
            "33120"
          ]
        },
        {
          "id": 332105,
          "name": "อีเซ",
          "postCode": [
            "33120"
          ]
        },
        {
          "id": 332102,
          "name": "เสียว",
          "postCode": [
            "33120"
          ]
        },
        {
          "id": 332101,
          "name": "โดด",
          "postCode": [
            "33120"
          ]
        }
      ]
    },
    {
      "city": "ไพรบึง",
      "cityCode": 3306,
      "provinceCode": 33,
      "subDistrict": [
        {
          "id": 330602,
          "name": "ดินแดง",
          "postCode": [
            "33180"
          ]
        },
        {
          "id": 330603,
          "name": "ปราสาทเยอ",
          "postCode": [
            "33180"
          ]
        },
        {
          "id": 330604,
          "name": "สำโรงพลัน",
          "postCode": [
            "33180"
          ]
        },
        {
          "id": 330605,
          "name": "สุขสวัสดิ์",
          "postCode": [
            "33180"
          ]
        },
        {
          "id": 330606,
          "name": "โนนปูน",
          "postCode": [
            "33180"
          ]
        },
        {
          "id": 330601,
          "name": "ไพรบึง",
          "postCode": [
            "33180"
          ]
        }
      ]
    },
    {
      "city": "กุดบาก",
      "cityCode": 4703,
      "provinceCode": 47,
      "subDistrict": [
        {
          "id": 470301,
          "name": "กุดบาก",
          "postCode": [
            "47180"
          ]
        },
        {
          "id": 470305,
          "name": "กุดไห",
          "postCode": [
            "47180"
          ]
        },
        {
          "id": 470303,
          "name": "นาม่อง",
          "postCode": [
            "47180"
          ]
        }
      ]
    },
    {
      "city": "กุสุมาลย์",
      "cityCode": 4702,
      "provinceCode": 47,
      "subDistrict": [
        {
          "id": 470201,
          "name": "กุสุมาลย์",
          "postCode": [
            "47210"
          ]
        },
        {
          "id": 470203,
          "name": "นาเพียง",
          "postCode": [
            "47230"
          ]
        },
        {
          "id": 470202,
          "name": "นาโพธิ์",
          "postCode": [
            "47210"
          ]
        },
        {
          "id": 470205,
          "name": "อุ่มจาน",
          "postCode": [
            "47230"
          ]
        },
        {
          "id": 470204,
          "name": "โพธิไพศาล",
          "postCode": [
            "47210"
          ]
        }
      ]
    },
    {
      "city": "คำตากล้า",
      "cityCode": 4709,
      "provinceCode": 47,
      "subDistrict": [
        {
          "id": 470901,
          "name": "คำตากล้า",
          "postCode": [
            "47250"
          ]
        },
        {
          "id": 470903,
          "name": "นาแต้",
          "postCode": [
            "47250"
          ]
        },
        {
          "id": 470902,
          "name": "หนองบัวสิม",
          "postCode": [
            "47250"
          ]
        },
        {
          "id": 470904,
          "name": "แพด",
          "postCode": [
            "47250"
          ]
        }
      ]
    },
    {
      "city": "นิคมน้ำอูน",
      "cityCode": 4707,
      "provinceCode": 47,
      "subDistrict": [
        {
          "id": 470701,
          "name": "นิคมน้ำอูน",
          "postCode": [
            "47270"
          ]
        },
        {
          "id": 470704,
          "name": "สุวรรณคาม",
          "postCode": [
            "47270"
          ]
        },
        {
          "id": 470703,
          "name": "หนองบัว",
          "postCode": [
            "47270"
          ]
        },
        {
          "id": 470702,
          "name": "หนองปลิง",
          "postCode": [
            "47270"
          ]
        }
      ]
    },
    {
      "city": "บ้านม่วง",
      "cityCode": 4710,
      "provinceCode": 47,
      "subDistrict": [
        {
          "id": 471003,
          "name": "ดงหม้อทอง",
          "postCode": [
            "47140"
          ]
        },
        {
          "id": 471005,
          "name": "ดงหม้อทองใต้",
          "postCode": [
            "47140"
          ]
        },
        {
          "id": 471004,
          "name": "ดงเหนือ",
          "postCode": [
            "47140"
          ]
        },
        {
          "id": 471009,
          "name": "บ่อแก้ว",
          "postCode": [
            "47140"
          ]
        },
        {
          "id": 471002,
          "name": "มาย",
          "postCode": [
            "47140"
          ]
        },
        {
          "id": 471001,
          "name": "ม่วง",
          "postCode": [
            "47140"
          ]
        },
        {
          "id": 471008,
          "name": "หนองกวั่ง",
          "postCode": [
            "47140"
          ]
        },
        {
          "id": 471006,
          "name": "ห้วยหลัว",
          "postCode": [
            "47140"
          ]
        },
        {
          "id": 471007,
          "name": "โนนสะอาด",
          "postCode": [
            "47140"
          ]
        }
      ]
    },
    {
      "city": "พรรณานิคม",
      "cityCode": 4704,
      "provinceCode": 47,
      "subDistrict": [
        {
          "id": 470406,
          "name": "ช้างมิ่ง",
          "postCode": [
            "47130"
          ]
        },
        {
          "id": 470404,
          "name": "นาหัวบ่อ",
          "postCode": [
            "47220"
          ]
        },
        {
          "id": 470407,
          "name": "นาใน",
          "postCode": [
            "47130"
          ]
        },
        {
          "id": 470409,
          "name": "บะฮี",
          "postCode": [
            "47130"
          ]
        },
        {
          "id": 470401,
          "name": "พรรณา",
          "postCode": [
            "47130"
          ]
        },
        {
          "id": 470403,
          "name": "พอกน้อย",
          "postCode": [
            "47220"
          ]
        },
        {
          "id": 470402,
          "name": "วังยาง",
          "postCode": [
            "47130"
          ]
        },
        {
          "id": 470408,
          "name": "สว่าง",
          "postCode": [
            "47130"
          ]
        },
        {
          "id": 470410,
          "name": "เชิงชุม",
          "postCode": [
            "47130"
          ]
        },
        {
          "id": 470405,
          "name": "ไร่",
          "postCode": [
            "47130"
          ]
        }
      ]
    },
    {
      "city": "พังโคน",
      "cityCode": 4705,
      "provinceCode": 47,
      "subDistrict": [
        {
          "id": 470505,
          "name": "ต้นผึ้ง",
          "postCode": [
            "47160"
          ]
        },
        {
          "id": 470501,
          "name": "พังโคน",
          "postCode": [
            "47160"
          ]
        },
        {
          "id": 470502,
          "name": "ม่วงไข่",
          "postCode": [
            "47160"
          ]
        },
        {
          "id": 470503,
          "name": "แร่",
          "postCode": [
            "47160"
          ]
        },
        {
          "id": 470504,
          "name": "ไฮหย่อง",
          "postCode": [
            "47160"
          ]
        }
      ]
    },
    {
      "city": "ภูพาน",
      "cityCode": 4718,
      "provinceCode": 47,
      "subDistrict": [
        {
          "id": 471804,
          "name": "กกปลาซิว",
          "postCode": [
            "47180"
          ]
        },
        {
          "id": 471801,
          "name": "สร้างค้อ",
          "postCode": [
            "47180"
          ]
        },
        {
          "id": 471802,
          "name": "หลุบเลา",
          "postCode": [
            "47180"
          ]
        },
        {
          "id": 471803,
          "name": "โคกภู",
          "postCode": [
            "47180"
          ]
        }
      ]
    },
    {
      "city": "วานรนิวาส",
      "cityCode": 4708,
      "provinceCode": 47,
      "subDistrict": [
        {
          "id": 470813,
          "name": "กุดเรือคำ",
          "postCode": [
            "47120"
          ]
        },
        {
          "id": 470803,
          "name": "ขัวก่าย",
          "postCode": [
            "47120"
          ]
        },
        {
          "id": 470812,
          "name": "คอนสวรรค์",
          "postCode": [
            "47120"
          ]
        },
        {
          "id": 470805,
          "name": "คูสะคาม",
          "postCode": [
            "47120"
          ]
        },
        {
          "id": 470806,
          "name": "ธาตุ",
          "postCode": [
            "47120"
          ]
        },
        {
          "id": 470811,
          "name": "นาคำ",
          "postCode": [
            "47120"
          ]
        },
        {
          "id": 470809,
          "name": "นาซอ",
          "postCode": [
            "47120"
          ]
        },
        {
          "id": 470801,
          "name": "วานรนิวาส",
          "postCode": [
            "47120"
          ]
        },
        {
          "id": 470808,
          "name": "ศรีวิชัย",
          "postCode": [
            "47120"
          ]
        },
        {
          "id": 470804,
          "name": "หนองสนม",
          "postCode": [
            "47120"
          ]
        },
        {
          "id": 470807,
          "name": "หนองแวง",
          "postCode": [
            "47120"
          ]
        },
        {
          "id": 470814,
          "name": "หนองแวงใต้",
          "postCode": [
            "47120"
          ]
        },
        {
          "id": 470810,
          "name": "อินทร์แปลง",
          "postCode": [
            "47120"
          ]
        },
        {
          "id": 470802,
          "name": "เดื่อศรีคันไชย",
          "postCode": [
            "47120"
          ]
        }
      ]
    },
    {
      "city": "วาริชภูมิ",
      "cityCode": 4706,
      "provinceCode": 47,
      "subDistrict": [
        {
          "id": 470604,
          "name": "คำบ่อ",
          "postCode": [
            "47150"
          ]
        },
        {
          "id": 470605,
          "name": "ค้อเขียว",
          "postCode": [
            "47150"
          ]
        },
        {
          "id": 470602,
          "name": "ปลาโหล",
          "postCode": [
            "47150"
          ]
        },
        {
          "id": 470601,
          "name": "วาริชภูมิ",
          "postCode": [
            "47150"
          ]
        },
        {
          "id": 470603,
          "name": "หนองลาด",
          "postCode": [
            "47150"
          ]
        }
      ]
    },
    {
      "city": "สว่างแดนดิน",
      "cityCode": 4712,
      "provinceCode": 47,
      "subDistrict": [
        {
          "id": 471203,
          "name": "คำสะอาด",
          "postCode": [
            "47110"
          ]
        },
        {
          "id": 471212,
          "name": "ค้อใต้",
          "postCode": [
            "47110"
          ]
        },
        {
          "id": 471217,
          "name": "ตาลเนิ้ง",
          "postCode": [
            "47240"
          ]
        },
        {
          "id": 471216,
          "name": "ตาลโกน",
          "postCode": [
            "47240"
          ]
        },
        {
          "id": 471215,
          "name": "ทรายมูล",
          "postCode": [
            "47110"
          ]
        },
        {
          "id": 471220,
          "name": "ธาตุทอง",
          "postCode": [
            "47240"
          ]
        },
        {
          "id": 471206,
          "name": "บงเหนือ",
          "postCode": [
            "47110"
          ]
        },
        {
          "id": 471211,
          "name": "บงใต้",
          "postCode": [
            "47110"
          ]
        },
        {
          "id": 471204,
          "name": "บ้านต้าย",
          "postCode": [
            "47110"
          ]
        },
        {
          "id": 471221,
          "name": "บ้านถ่อน",
          "postCode": [
            "47110"
          ]
        },
        {
          "id": 471213,
          "name": "พันนา",
          "postCode": [
            "47240"
          ]
        },
        {
          "id": 471201,
          "name": "สว่างแดนดิน",
          "postCode": [
            "47110"
          ]
        },
        {
          "id": 471210,
          "name": "หนองหลวง",
          "postCode": [
            "47110"
          ]
        },
        {
          "id": 471214,
          "name": "แวง",
          "postCode": [
            "47240"
          ]
        },
        {
          "id": 471208,
          "name": "โคกสี",
          "postCode": [
            "47110"
          ]
        },
        {
          "id": 471207,
          "name": "โพนสูง",
          "postCode": [
            "47110"
          ]
        }
      ]
    },
    {
      "city": "ส่องดาว",
      "cityCode": 4713,
      "provinceCode": 47,
      "subDistrict": [
        {
          "id": 471302,
          "name": "ท่าศิลา",
          "postCode": [
            "47190"
          ]
        },
        {
          "id": 471304,
          "name": "ปทุมวาปี",
          "postCode": [
            "47190"
          ]
        },
        {
          "id": 471303,
          "name": "วัฒนา",
          "postCode": [
            "47190"
          ]
        },
        {
          "id": 471301,
          "name": "ส่องดาว",
          "postCode": [
            "47190"
          ]
        }
      ]
    },
    {
      "city": "อากาศอำนวย",
      "cityCode": 4711,
      "provinceCode": 47,
      "subDistrict": [
        {
          "id": 471105,
          "name": "ท่าก้อน",
          "postCode": [
            "47170"
          ]
        },
        {
          "id": 471106,
          "name": "นาฮี",
          "postCode": [
            "47170"
          ]
        },
        {
          "id": 471107,
          "name": "บะหว้า",
          "postCode": [
            "47170"
          ]
        },
        {
          "id": 471103,
          "name": "วาใหญ่",
          "postCode": [
            "47170"
          ]
        },
        {
          "id": 471108,
          "name": "สามัคคีพัฒนา",
          "postCode": [
            "47170"
          ]
        },
        {
          "id": 471101,
          "name": "อากาศ",
          "postCode": [
            "47170"
          ]
        },
        {
          "id": 471104,
          "name": "โพนงาม",
          "postCode": [
            "47170"
          ]
        },
        {
          "id": 471102,
          "name": "โพนแพง",
          "postCode": [
            "47170"
          ]
        }
      ]
    },
    {
      "city": "เจริญศิลป์",
      "cityCode": 4716,
      "provinceCode": 47,
      "subDistrict": [
        {
          "id": 471603,
          "name": "ทุ่งแก",
          "postCode": [
            "47290"
          ]
        },
        {
          "id": 471601,
          "name": "บ้านเหล่า",
          "postCode": [
            "47290"
          ]
        },
        {
          "id": 471605,
          "name": "หนองแปน",
          "postCode": [
            "47290"
          ]
        },
        {
          "id": 471602,
          "name": "เจริญศิลป์",
          "postCode": [
            "47290"
          ]
        },
        {
          "id": 471604,
          "name": "โคกศิลา",
          "postCode": [
            "47290"
          ]
        }
      ]
    },
    {
      "city": "เต่างอย",
      "cityCode": 4714,
      "provinceCode": 47,
      "subDistrict": [
        {
          "id": 471404,
          "name": "จันทร์เพ็ญ",
          "postCode": [
            "47260"
          ]
        },
        {
          "id": 471403,
          "name": "นาตาล",
          "postCode": [
            "47260"
          ]
        },
        {
          "id": 471402,
          "name": "บึงทวาย",
          "postCode": [
            "47260"
          ]
        },
        {
          "id": 471401,
          "name": "เต่างอย",
          "postCode": [
            "47260"
          ]
        }
      ]
    },
    {
      "city": "เมืองสกลนคร",
      "cityCode": 4701,
      "provinceCode": 47,
      "subDistrict": [
        {
          "id": 470102,
          "name": "ขมิ้น",
          "postCode": [
            "47220"
          ]
        },
        {
          "id": 470103,
          "name": "งิ้วด่อน",
          "postCode": [
            "47000"
          ]
        },
        {
          "id": 470111,
          "name": "ดงชน",
          "postCode": [
            "47000"
          ]
        },
        {
          "id": 470115,
          "name": "ดงมะไฟ",
          "postCode": [
            "47000"
          ]
        },
        {
          "id": 470107,
          "name": "ท่าแร่",
          "postCode": [
            "47230"
          ]
        },
        {
          "id": 470116,
          "name": "ธาตุนาเวง",
          "postCode": [
            "47000"
          ]
        },
        {
          "id": 470101,
          "name": "ธาตุเชิงชุม",
          "postCode": [
            "47000"
          ]
        },
        {
          "id": 470113,
          "name": "พังขว้าง",
          "postCode": [
            "47000"
          ]
        },
        {
          "id": 470109,
          "name": "ม่วงลาย",
          "postCode": [
            "47000"
          ]
        },
        {
          "id": 470118,
          "name": "หนองลาด",
          "postCode": [
            "47220"
          ]
        },
        {
          "id": 470112,
          "name": "ห้วยยาง",
          "postCode": [
            "47000"
          ]
        },
        {
          "id": 470120,
          "name": "ฮางโฮง",
          "postCode": [
            "47000"
          ]
        },
        {
          "id": 470106,
          "name": "เชียงเครือ",
          "postCode": [
            "47000"
          ]
        },
        {
          "id": 470117,
          "name": "เหล่าปอแดง",
          "postCode": [
            "47000"
          ]
        },
        {
          "id": 470121,
          "name": "โคกก่อง",
          "postCode": [
            "47000"
          ]
        },
        {
          "id": 470104,
          "name": "โนนหอม",
          "postCode": [
            "47000"
          ]
        }
      ]
    },
    {
      "city": "โคกศรีสุพรรณ",
      "cityCode": 4715,
      "provinceCode": 47,
      "subDistrict": [
        {
          "id": 471503,
          "name": "ด่านม่วงคำ",
          "postCode": [
            "47280"
          ]
        },
        {
          "id": 471501,
          "name": "ตองโขบ",
          "postCode": [
            "47280"
          ]
        },
        {
          "id": 471502,
          "name": "เหล่าโพนค้อ",
          "postCode": [
            "47280"
          ]
        },
        {
          "id": 471504,
          "name": "แมดนาท่ม",
          "postCode": [
            "47280"
          ]
        }
      ]
    },
    {
      "city": "โพนนาแก้ว",
      "cityCode": 4717,
      "provinceCode": 47,
      "subDistrict": [
        {
          "id": 471703,
          "name": "นาตงวัฒนา",
          "postCode": [
            "47230"
          ]
        },
        {
          "id": 471702,
          "name": "นาแก้ว",
          "postCode": [
            "47230"
          ]
        },
        {
          "id": 471704,
          "name": "บ้านแป้น",
          "postCode": [
            "47230"
          ]
        },
        {
          "id": 471701,
          "name": "บ้านโพน",
          "postCode": [
            "47230"
          ]
        },
        {
          "id": 471705,
          "name": "เชียงสือ",
          "postCode": [
            "47230"
          ]
        }
      ]
    },
    {
      "city": "กระแสสินธุ์",
      "cityCode": 9008,
      "provinceCode": 90,
      "subDistrict": [
        {
          "id": 900804,
          "name": "กระแสสินธุ์",
          "postCode": [
            "90270"
          ]
        },
        {
          "id": 900801,
          "name": "เกาะใหญ่",
          "postCode": [
            "90270"
          ]
        },
        {
          "id": 900803,
          "name": "เชิงแส",
          "postCode": [
            "90270"
          ]
        },
        {
          "id": 900802,
          "name": "โรง",
          "postCode": [
            "90270"
          ]
        }
      ]
    },
    {
      "city": "คลองหอยโข่ง",
      "cityCode": 9016,
      "provinceCode": 90,
      "subDistrict": [
        {
          "id": 901604,
          "name": "คลองหลา",
          "postCode": [
            "90110"
          ]
        },
        {
          "id": 901601,
          "name": "คลองหอยโข่ง",
          "postCode": [
            "90230"
          ]
        },
        {
          "id": 901602,
          "name": "ทุ่งลาน",
          "postCode": [
            "90230"
          ]
        },
        {
          "id": 901603,
          "name": "โคกม่วง",
          "postCode": [
            "90115",
            "90230"
          ]
        }
      ]
    },
    {
      "city": "ควนเนียง",
      "cityCode": 9013,
      "provinceCode": 90,
      "subDistrict": [
        {
          "id": 901302,
          "name": "ควนโส",
          "postCode": [
            "90220"
          ]
        },
        {
          "id": 901304,
          "name": "บางเหรียง",
          "postCode": [
            "90220"
          ]
        },
        {
          "id": 901301,
          "name": "รัตภูมิ",
          "postCode": [
            "90220"
          ]
        },
        {
          "id": 901303,
          "name": "ห้วยลึก",
          "postCode": [
            "90220"
          ]
        }
      ]
    },
    {
      "city": "จะนะ",
      "cityCode": 9003,
      "provinceCode": 90,
      "subDistrict": [
        {
          "id": 900308,
          "name": "ขุนตัดหวาย",
          "postCode": [
            "90130"
          ]
        },
        {
          "id": 900313,
          "name": "คลองเปียะ",
          "postCode": [
            "90130"
          ]
        },
        {
          "id": 900311,
          "name": "คู",
          "postCode": [
            "90130"
          ]
        },
        {
          "id": 900310,
          "name": "จะโหนง",
          "postCode": [
            "90130"
          ]
        },
        {
          "id": 900314,
          "name": "ตลิ่งชัน",
          "postCode": [
            "90130"
          ]
        },
        {
          "id": 900309,
          "name": "ท่าหมอไทร",
          "postCode": [
            "90130"
          ]
        },
        {
          "id": 900306,
          "name": "นาทับ",
          "postCode": [
            "90130"
          ]
        },
        {
          "id": 900305,
          "name": "นาหว้า",
          "postCode": [
            "90130"
          ]
        },
        {
          "id": 900307,
          "name": "น้ำขาว",
          "postCode": [
            "90130"
          ]
        },
        {
          "id": 900301,
          "name": "บ้านนา",
          "postCode": [
            "90130"
          ]
        },
        {
          "id": 900302,
          "name": "ป่าชิง",
          "postCode": [
            "90130"
          ]
        },
        {
          "id": 900304,
          "name": "สะกอม",
          "postCode": [
            "90130"
          ]
        },
        {
          "id": 900303,
          "name": "สะพานไม้แก่น",
          "postCode": [
            "90130"
          ]
        },
        {
          "id": 900312,
          "name": "แค",
          "postCode": [
            "90130"
          ]
        }
      ]
    },
    {
      "city": "นาทวี",
      "cityCode": 9004,
      "provinceCode": 90,
      "subDistrict": [
        {
          "id": 900410,
          "name": "คลองกวาง",
          "postCode": [
            "90160"
          ]
        },
        {
          "id": 900404,
          "name": "คลองทราย",
          "postCode": [
            "90160"
          ]
        },
        {
          "id": 900402,
          "name": "ฉาง",
          "postCode": [
            "90160"
          ]
        },
        {
          "id": 900408,
          "name": "ทับช้าง",
          "postCode": [
            "90160"
          ]
        },
        {
          "id": 900406,
          "name": "ท่าประดู่",
          "postCode": [
            "90160"
          ]
        },
        {
          "id": 900401,
          "name": "นาทวี",
          "postCode": [
            "90160"
          ]
        },
        {
          "id": 900403,
          "name": "นาหมอศรี",
          "postCode": [
            "90160"
          ]
        },
        {
          "id": 900409,
          "name": "ประกอบ",
          "postCode": [
            "90160"
          ]
        },
        {
          "id": 900405,
          "name": "ปลักหนู",
          "postCode": [
            "90160"
          ]
        },
        {
          "id": 900407,
          "name": "สะท้อน",
          "postCode": [
            "90160"
          ]
        }
      ]
    },
    {
      "city": "นาหม่อม",
      "cityCode": 9012,
      "provinceCode": 90,
      "subDistrict": [
        {
          "id": 901204,
          "name": "คลองหรัง",
          "postCode": [
            "90310"
          ]
        },
        {
          "id": 901203,
          "name": "ทุ่งขมิ้น",
          "postCode": [
            "90310"
          ]
        },
        {
          "id": 901201,
          "name": "นาหม่อม",
          "postCode": [
            "90310"
          ]
        },
        {
          "id": 901202,
          "name": "พิจิตร",
          "postCode": [
            "90310"
          ]
        }
      ]
    },
    {
      "city": "บางกล่ำ",
      "cityCode": 9014,
      "provinceCode": 90,
      "subDistrict": [
        {
          "id": 901402,
          "name": "ท่าช้าง",
          "postCode": [
            "90110"
          ]
        },
        {
          "id": 901401,
          "name": "บางกล่ำ",
          "postCode": [
            "90110"
          ]
        },
        {
          "id": 901404,
          "name": "บ้านหาร",
          "postCode": [
            "90110"
          ]
        },
        {
          "id": 901403,
          "name": "แม่ทอม",
          "postCode": [
            "90110"
          ]
        }
      ]
    },
    {
      "city": "ระโนด",
      "cityCode": 9007,
      "provinceCode": 90,
      "subDistrict": [
        {
          "id": 900702,
          "name": "คลองแดน",
          "postCode": [
            "90140"
          ]
        },
        {
          "id": 900703,
          "name": "ตะเครียะ",
          "postCode": [
            "90140"
          ]
        },
        {
          "id": 900704,
          "name": "ท่าบอน",
          "postCode": [
            "90140"
          ]
        },
        {
          "id": 900706,
          "name": "บ่อตรุ",
          "postCode": [
            "90140"
          ]
        },
        {
          "id": 900711,
          "name": "บ้านขาว",
          "postCode": [
            "90140"
          ]
        },
        {
          "id": 900705,
          "name": "บ้านใหม่",
          "postCode": [
            "90140"
          ]
        },
        {
          "id": 900707,
          "name": "ปากแตระ",
          "postCode": [
            "90140"
          ]
        },
        {
          "id": 900708,
          "name": "พังยาง",
          "postCode": [
            "90140"
          ]
        },
        {
          "id": 900709,
          "name": "ระวะ",
          "postCode": [
            "90140"
          ]
        },
        {
          "id": 900701,
          "name": "ระโนด",
          "postCode": [
            "90140"
          ]
        },
        {
          "id": 900710,
          "name": "วัดสน",
          "postCode": [
            "90140"
          ]
        },
        {
          "id": 900712,
          "name": "แดนสงวน",
          "postCode": [
            "90140"
          ]
        }
      ]
    },
    {
      "city": "รัตภูมิ",
      "cityCode": 9009,
      "provinceCode": 90,
      "subDistrict": [
        {
          "id": 900901,
          "name": "กำแพงเพชร",
          "postCode": [
            "90180"
          ]
        },
        {
          "id": 900904,
          "name": "ควนรู",
          "postCode": [
            "90180",
            "90220"
          ]
        },
        {
          "id": 900903,
          "name": "คูหาใต้",
          "postCode": [
            "90180"
          ]
        },
        {
          "id": 900902,
          "name": "ท่าชะมวง",
          "postCode": [
            "90180"
          ]
        },
        {
          "id": 900909,
          "name": "เขาพระ",
          "postCode": [
            "90180"
          ]
        }
      ]
    },
    {
      "city": "สทิงพระ",
      "cityCode": 9002,
      "provinceCode": 90,
      "subDistrict": [
        {
          "id": 900202,
          "name": "กระดังงา",
          "postCode": [
            "90190"
          ]
        },
        {
          "id": 900206,
          "name": "คลองรี",
          "postCode": [
            "90190"
          ]
        },
        {
          "id": 900207,
          "name": "คูขุด",
          "postCode": [
            "90190"
          ]
        },
        {
          "id": 900201,
          "name": "จะทิ้งพระ",
          "postCode": [
            "90190"
          ]
        },
        {
          "id": 900205,
          "name": "ชุมพล",
          "postCode": [
            "90190"
          ]
        },
        {
          "id": 900204,
          "name": "ดีหลวง",
          "postCode": [
            "90190"
          ]
        },
        {
          "id": 900208,
          "name": "ท่าหิน",
          "postCode": [
            "90190"
          ]
        },
        {
          "id": 900211,
          "name": "บ่อดาน",
          "postCode": [
            "90190"
          ]
        },
        {
          "id": 900210,
          "name": "บ่อแดง",
          "postCode": [
            "90190"
          ]
        },
        {
          "id": 900209,
          "name": "วัดจันทร์",
          "postCode": [
            "90190"
          ]
        },
        {
          "id": 900203,
          "name": "สนามชัย",
          "postCode": [
            "90190"
          ]
        }
      ]
    },
    {
      "city": "สะบ้าย้อย",
      "cityCode": 9006,
      "provinceCode": 90,
      "subDistrict": [
        {
          "id": 900606,
          "name": "คูหา",
          "postCode": [
            "90210"
          ]
        },
        {
          "id": 900605,
          "name": "จะแหน",
          "postCode": [
            "90210"
          ]
        },
        {
          "id": 900602,
          "name": "ทุ่งพอ",
          "postCode": [
            "90210"
          ]
        },
        {
          "id": 900609,
          "name": "ธารคีรี",
          "postCode": [
            "90210"
          ]
        },
        {
          "id": 900608,
          "name": "บาโหย",
          "postCode": [
            "90210"
          ]
        },
        {
          "id": 900604,
          "name": "บ้านโหนด",
          "postCode": [
            "90210"
          ]
        },
        {
          "id": 900601,
          "name": "สะบ้าย้อย",
          "postCode": [
            "90210"
          ]
        },
        {
          "id": 900607,
          "name": "เขาแดง",
          "postCode": [
            "90210"
          ]
        },
        {
          "id": 900603,
          "name": "เปียน",
          "postCode": [
            "90210"
          ]
        }
      ]
    },
    {
      "city": "สะเดา",
      "cityCode": 9010,
      "provinceCode": 90,
      "subDistrict": [
        {
          "id": 901005,
          "name": "ทุ่งหมอ",
          "postCode": [
            "90240"
          ]
        },
        {
          "id": 901006,
          "name": "ท่าโพธิ์",
          "postCode": [
            "90170"
          ]
        },
        {
          "id": 901002,
          "name": "ปริก",
          "postCode": [
            "90120"
          ]
        },
        {
          "id": 901007,
          "name": "ปาดังเบซาร์",
          "postCode": [
            "90240"
          ]
        },
        {
          "id": 901003,
          "name": "พังลา",
          "postCode": [
            "90170"
          ]
        },
        {
          "id": 901001,
          "name": "สะเดา",
          "postCode": [
            "90120"
          ]
        },
        {
          "id": 901008,
          "name": "สำนักขาม",
          "postCode": [
            "90120",
            "90320"
          ]
        },
        {
          "id": 901004,
          "name": "สำนักแต้ว",
          "postCode": [
            "90120"
          ]
        },
        {
          "id": 901009,
          "name": "เขามีเกียรติ",
          "postCode": [
            "90170"
          ]
        }
      ]
    },
    {
      "city": "สิงหนคร",
      "cityCode": 9015,
      "provinceCode": 90,
      "subDistrict": [
        {
          "id": 901506,
          "name": "ชะแล้",
          "postCode": [
            "90330"
          ]
        },
        {
          "id": 901501,
          "name": "ชิงโค",
          "postCode": [
            "90280"
          ]
        },
        {
          "id": 901503,
          "name": "ทำนบ",
          "postCode": [
            "90280"
          ]
        },
        {
          "id": 901510,
          "name": "บางเขียด",
          "postCode": [
            "90330"
          ]
        },
        {
          "id": 901507,
          "name": "ปากรอ",
          "postCode": [
            "90330"
          ]
        },
        {
          "id": 901508,
          "name": "ป่าขาด",
          "postCode": [
            "90330"
          ]
        },
        {
          "id": 901511,
          "name": "ม่วงงาม",
          "postCode": [
            "90330"
          ]
        },
        {
          "id": 901504,
          "name": "รำแดง",
          "postCode": [
            "90330"
          ]
        },
        {
          "id": 901505,
          "name": "วัดขนุน",
          "postCode": [
            "90330"
          ]
        },
        {
          "id": 901502,
          "name": "สทิงหม้อ",
          "postCode": [
            "90280"
          ]
        },
        {
          "id": 901509,
          "name": "หัวเขา",
          "postCode": [
            "90280"
          ]
        }
      ]
    },
    {
      "city": "หาดใหญ่",
      "cityCode": 9011,
      "provinceCode": 90,
      "subDistrict": [
        {
          "id": 901107,
          "name": "คลองอู่ตะเภา",
          "postCode": [
            "90110"
          ]
        },
        {
          "id": 901105,
          "name": "คลองแห",
          "postCode": [
            "90110"
          ]
        },
        {
          "id": 901102,
          "name": "ควนลัง",
          "postCode": [
            "90110"
          ]
        },
        {
          "id": 901104,
          "name": "คอหงส์",
          "postCode": [
            "90110",
            "90112"
          ]
        },
        {
          "id": 901103,
          "name": "คูเต่า",
          "postCode": [
            "90110"
          ]
        },
        {
          "id": 901108,
          "name": "ฉลุง",
          "postCode": [
            "90110"
          ]
        },
        {
          "id": 901112,
          "name": "ทุ่งตำเสา",
          "postCode": [
            "90110"
          ]
        },
        {
          "id": 901111,
          "name": "ทุ่งใหญ่",
          "postCode": [
            "90110"
          ]
        },
        {
          "id": 901113,
          "name": "ท่าข้าม",
          "postCode": [
            "90110"
          ]
        },
        {
          "id": 901114,
          "name": "น้ำน้อย",
          "postCode": [
            "90110"
          ]
        },
        {
          "id": 901116,
          "name": "บ้านพรุ",
          "postCode": [
            "90250"
          ]
        },
        {
          "id": 901118,
          "name": "พะตง",
          "postCode": [
            "90230"
          ]
        },
        {
          "id": 901101,
          "name": "หาดใหญ่",
          "postCode": [
            "90110"
          ]
        }
      ]
    },
    {
      "city": "เทพา",
      "cityCode": 9005,
      "provinceCode": 90,
      "subDistrict": [
        {
          "id": 900505,
          "name": "ท่าม่วง",
          "postCode": [
            "90150",
            "90260"
          ]
        },
        {
          "id": 900502,
          "name": "ปากบาง",
          "postCode": [
            "90150"
          ]
        },
        {
          "id": 900504,
          "name": "ลำไพล",
          "postCode": [
            "90260"
          ]
        },
        {
          "id": 900506,
          "name": "วังใหญ่",
          "postCode": [
            "90260"
          ]
        },
        {
          "id": 900507,
          "name": "สะกอม",
          "postCode": [
            "90150"
          ]
        },
        {
          "id": 900503,
          "name": "เกาะสะบ้า",
          "postCode": [
            "90150"
          ]
        },
        {
          "id": 900501,
          "name": "เทพา",
          "postCode": [
            "90150"
          ]
        }
      ]
    },
    {
      "city": "เมืองสงขลา",
      "cityCode": 9001,
      "provinceCode": 90,
      "subDistrict": [
        {
          "id": 900105,
          "name": "ทุ่งหวัง",
          "postCode": [
            "90000"
          ]
        },
        {
          "id": 900101,
          "name": "บ่อยาง",
          "postCode": [
            "90000"
          ]
        },
        {
          "id": 900104,
          "name": "พะวง",
          "postCode": [
            "90100"
          ]
        },
        {
          "id": 900106,
          "name": "เกาะยอ",
          "postCode": [
            "90100"
          ]
        },
        {
          "id": 900103,
          "name": "เกาะแต้ว",
          "postCode": [
            "90000"
          ]
        },
        {
          "id": 900102,
          "name": "เขารูปช้าง",
          "postCode": [
            "90000"
          ]
        }
      ]
    },
    {
      "city": "ควนกาหลง",
      "cityCode": 9103,
      "provinceCode": 91,
      "subDistrict": [
        {
          "id": 910302,
          "name": "ควนกาหลง",
          "postCode": [
            "91130"
          ]
        },
        {
          "id": 910301,
          "name": "ทุ่งนุ้ย",
          "postCode": [
            "91130"
          ]
        },
        {
          "id": 910303,
          "name": "อุใดเจริญ",
          "postCode": [
            "91130"
          ]
        }
      ]
    },
    {
      "city": "ควนโดน",
      "cityCode": 9102,
      "provinceCode": 91,
      "subDistrict": [
        {
          "id": 910202,
          "name": "ควนสตอ",
          "postCode": [
            "91160"
          ]
        },
        {
          "id": 910201,
          "name": "ควนโดน",
          "postCode": [
            "91160"
          ]
        },
        {
          "id": 910203,
          "name": "ย่านซื่อ",
          "postCode": [
            "91160"
          ]
        },
        {
          "id": 910204,
          "name": "วังประจัน",
          "postCode": [
            "91160"
          ]
        }
      ]
    },
    {
      "city": "ทุ่งหว้า",
      "cityCode": 9106,
      "provinceCode": 91,
      "subDistrict": [
        {
          "id": 910603,
          "name": "ขอนคลาน",
          "postCode": [
            "91120"
          ]
        },
        {
          "id": 910604,
          "name": "ทุ่งบุหลัง",
          "postCode": [
            "91120"
          ]
        },
        {
          "id": 910601,
          "name": "ทุ่งหว้า",
          "postCode": [
            "91120"
          ]
        },
        {
          "id": 910602,
          "name": "นาทอน",
          "postCode": [
            "91120"
          ]
        },
        {
          "id": 910605,
          "name": "ป่าแก่บ่อหิน",
          "postCode": [
            "91120"
          ]
        }
      ]
    },
    {
      "city": "ท่าแพ",
      "cityCode": 9104,
      "provinceCode": 91,
      "subDistrict": [
        {
          "id": 910404,
          "name": "ท่าเรือ",
          "postCode": [
            "91150"
          ]
        },
        {
          "id": 910401,
          "name": "ท่าแพ",
          "postCode": [
            "91150"
          ]
        },
        {
          "id": 910403,
          "name": "สาคร",
          "postCode": [
            "91150"
          ]
        },
        {
          "id": 910402,
          "name": "แป-ระ",
          "postCode": [
            "91150"
          ]
        }
      ]
    },
    {
      "city": "มะนัง",
      "cityCode": 9107,
      "provinceCode": 91,
      "subDistrict": [
        {
          "id": 910702,
          "name": "นิคมพัฒนา",
          "postCode": [
            "91130"
          ]
        },
        {
          "id": 910701,
          "name": "ปาล์มพัฒนา",
          "postCode": [
            "91130"
          ]
        }
      ]
    },
    {
      "city": "ละงู",
      "cityCode": 9105,
      "provinceCode": 91,
      "subDistrict": [
        {
          "id": 910501,
          "name": "กำแพง",
          "postCode": [
            "91110"
          ]
        },
        {
          "id": 910505,
          "name": "น้ำผุด",
          "postCode": [
            "91110"
          ]
        },
        {
          "id": 910504,
          "name": "ปากน้ำ",
          "postCode": [
            "91110"
          ]
        },
        {
          "id": 910502,
          "name": "ละงู",
          "postCode": [
            "91110"
          ]
        },
        {
          "id": 910503,
          "name": "เขาขาว",
          "postCode": [
            "91110"
          ]
        },
        {
          "id": 910506,
          "name": "แหลมสน",
          "postCode": [
            "91110"
          ]
        }
      ]
    },
    {
      "city": "เมืองสตูล",
      "cityCode": 9101,
      "provinceCode": 91,
      "subDistrict": [
        {
          "id": 910102,
          "name": "คลองขุด",
          "postCode": [
            "91000"
          ]
        },
        {
          "id": 910103,
          "name": "ควนขัน",
          "postCode": [
            "91000"
          ]
        },
        {
          "id": 910111,
          "name": "ควนโพธิ์",
          "postCode": [
            "91140"
          ]
        },
        {
          "id": 910105,
          "name": "ฉลุง",
          "postCode": [
            "91140"
          ]
        },
        {
          "id": 910107,
          "name": "ตันหยงโป",
          "postCode": [
            "91000"
          ]
        },
        {
          "id": 910109,
          "name": "ตำมะลัง",
          "postCode": [
            "91000"
          ]
        },
        {
          "id": 910104,
          "name": "บ้านควน",
          "postCode": [
            "91140"
          ]
        },
        {
          "id": 910110,
          "name": "ปูยู",
          "postCode": [
            "91000"
          ]
        },
        {
          "id": 910101,
          "name": "พิมาน",
          "postCode": [
            "91000"
          ]
        },
        {
          "id": 910112,
          "name": "เกตรี",
          "postCode": [
            "91140"
          ]
        },
        {
          "id": 910106,
          "name": "เกาะสาหร่าย",
          "postCode": [
            "91000",
            "91110"
          ]
        },
        {
          "id": 910108,
          "name": "เจ๊ะบิลัง",
          "postCode": [
            "91000"
          ]
        }
      ]
    },
    {
      "city": "บางบ่อ",
      "cityCode": 1102,
      "provinceCode": 11,
      "subDistrict": [
        {
          "id": 110205,
          "name": "คลองด่าน",
          "postCode": [
            "10550"
          ]
        },
        {
          "id": 110208,
          "name": "คลองนิยมยาตรา",
          "postCode": [
            "10560"
          ]
        },
        {
          "id": 110206,
          "name": "คลองสวน",
          "postCode": [
            "10560"
          ]
        },
        {
          "id": 110201,
          "name": "บางบ่อ",
          "postCode": [
            "10560"
          ]
        },
        {
          "id": 110203,
          "name": "บางพลีน้อย",
          "postCode": [
            "10560"
          ]
        },
        {
          "id": 110204,
          "name": "บางเพรียง",
          "postCode": [
            "10560"
          ]
        },
        {
          "id": 110202,
          "name": "บ้านระกาศ",
          "postCode": [
            "10560"
          ]
        },
        {
          "id": 110207,
          "name": "เปร็ง",
          "postCode": [
            "10560"
          ]
        }
      ]
    },
    {
      "city": "บางพลี",
      "cityCode": 1103,
      "provinceCode": 11,
      "subDistrict": [
        {
          "id": 110303,
          "name": "บางปลา",
          "postCode": [
            "10540"
          ]
        },
        {
          "id": 110301,
          "name": "บางพลีใหญ่",
          "postCode": [
            "10540"
          ]
        },
        {
          "id": 110302,
          "name": "บางแก้ว",
          "postCode": [
            "10540"
          ]
        },
        {
          "id": 110304,
          "name": "บางโฉลง",
          "postCode": [
            "10540"
          ]
        },
        {
          "id": 110308,
          "name": "ราชาเทวะ",
          "postCode": [
            "10540"
          ]
        },
        {
          "id": 110309,
          "name": "หนองปรือ",
          "postCode": [
            "10540"
          ]
        }
      ]
    },
    {
      "city": "บางเสาธง",
      "cityCode": 1106,
      "provinceCode": 11,
      "subDistrict": [
        {
          "id": 110601,
          "name": "บางเสาธง",
          "postCode": [
            "10570"
          ]
        },
        {
          "id": 110602,
          "name": "ศีรษะจรเข้น้อย",
          "postCode": [
            "10570"
          ]
        },
        {
          "id": 110603,
          "name": "ศีรษะจรเข้ใหญ่",
          "postCode": [
            "10570"
          ]
        }
      ]
    },
    {
      "city": "พระประแดง",
      "cityCode": 1104,
      "provinceCode": 11,
      "subDistrict": [
        {
          "id": 110401,
          "name": "ตลาด",
          "postCode": [
            "10130"
          ]
        },
        {
          "id": 110413,
          "name": "ทรงคนอง",
          "postCode": [
            "10130"
          ]
        },
        {
          "id": 110411,
          "name": "บางกระสอบ",
          "postCode": [
            "10130"
          ]
        },
        {
          "id": 110412,
          "name": "บางกอบัว",
          "postCode": [
            "10130"
          ]
        },
        {
          "id": 110409,
          "name": "บางกะเจ้า",
          "postCode": [
            "10130"
          ]
        },
        {
          "id": 110404,
          "name": "บางครุ",
          "postCode": [
            "10130"
          ]
        },
        {
          "id": 110403,
          "name": "บางจาก",
          "postCode": [
            "10130"
          ]
        },
        {
          "id": 110410,
          "name": "บางน้ำผึ้ง",
          "postCode": [
            "10130"
          ]
        },
        {
          "id": 110402,
          "name": "บางพึ่ง",
          "postCode": [
            "10130"
          ]
        },
        {
          "id": 110408,
          "name": "บางยอ",
          "postCode": [
            "10130"
          ]
        },
        {
          "id": 110405,
          "name": "บางหญ้าแพรก",
          "postCode": [
            "10130"
          ]
        },
        {
          "id": 110406,
          "name": "บางหัวเสือ",
          "postCode": [
            "10130"
          ]
        },
        {
          "id": 110414,
          "name": "สำโรง",
          "postCode": [
            "10130"
          ]
        },
        {
          "id": 110415,
          "name": "สำโรงกลาง",
          "postCode": [
            "10130"
          ]
        },
        {
          "id": 110407,
          "name": "สำโรงใต้",
          "postCode": [
            "10130"
          ]
        }
      ]
    },
    {
      "city": "พระสมุทรเจดีย์",
      "cityCode": 1105,
      "provinceCode": 11,
      "subDistrict": [
        {
          "id": 110501,
          "name": "นาเกลือ",
          "postCode": [
            "10290"
          ]
        },
        {
          "id": 110502,
          "name": "บ้านคลองสวน",
          "postCode": [
            "10290"
          ]
        },
        {
          "id": 110504,
          "name": "ปากคลองบางปลากด",
          "postCode": [
            "10290"
          ]
        },
        {
          "id": 110503,
          "name": "แหลมฟ้าผ่า",
          "postCode": [
            "10290"
          ]
        },
        {
          "id": 110505,
          "name": "ในคลองบางปลากด",
          "postCode": [
            "10290"
          ]
        }
      ]
    },
    {
      "city": "เมืองสมุทรปราการ",
      "cityCode": 1101,
      "provinceCode": 11,
      "subDistrict": [
        {
          "id": 110104,
          "name": "ท้ายบ้าน",
          "postCode": [
            "10280"
          ]
        },
        {
          "id": 110116,
          "name": "ท้ายบ้านใหม่",
          "postCode": [
            "10280"
          ]
        },
        {
          "id": 110113,
          "name": "บางด้วน",
          "postCode": [
            "10270"
          ]
        },
        {
          "id": 110112,
          "name": "บางปู",
          "postCode": [
            "10280"
          ]
        },
        {
          "id": 110108,
          "name": "บางปูใหม่",
          "postCode": [
            "10280"
          ]
        },
        {
          "id": 110103,
          "name": "บางเมือง",
          "postCode": [
            "10270"
          ]
        },
        {
          "id": 110114,
          "name": "บางเมืองใหม่",
          "postCode": [
            "10270"
          ]
        },
        {
          "id": 110111,
          "name": "บางโปรง",
          "postCode": [
            "10270"
          ]
        },
        {
          "id": 110101,
          "name": "ปากน้ำ",
          "postCode": [
            "10270"
          ]
        },
        {
          "id": 110102,
          "name": "สำโรงเหนือ",
          "postCode": [
            "10270"
          ]
        },
        {
          "id": 110115,
          "name": "เทพารักษ์",
          "postCode": [
            "10270"
          ]
        },
        {
          "id": 110110,
          "name": "แพรกษา",
          "postCode": [
            "10280"
          ]
        },
        {
          "id": 110117,
          "name": "แพรกษาใหม่",
          "postCode": [
            "10280"
          ]
        }
      ]
    },
    {
      "city": "บางคนที",
      "cityCode": 7502,
      "provinceCode": 75,
      "subDistrict": [
        {
          "id": 750201,
          "name": "กระดังงา",
          "postCode": [
            "75120"
          ]
        },
        {
          "id": 750209,
          "name": "จอมปลวก",
          "postCode": [
            "75120"
          ]
        },
        {
          "id": 750206,
          "name": "ดอนมะโนรา",
          "postCode": [
            "75120"
          ]
        },
        {
          "id": 750212,
          "name": "บางกระบือ",
          "postCode": [
            "75120"
          ]
        },
        {
          "id": 750208,
          "name": "บางกุ้ง",
          "postCode": [
            "75120"
          ]
        },
        {
          "id": 750205,
          "name": "บางคนที",
          "postCode": [
            "75120"
          ]
        },
        {
          "id": 750210,
          "name": "บางนกแขวก",
          "postCode": [
            "75120"
          ]
        },
        {
          "id": 750207,
          "name": "บางพรม",
          "postCode": [
            "75120"
          ]
        },
        {
          "id": 750203,
          "name": "บางยี่รงค์",
          "postCode": [
            "75120"
          ]
        },
        {
          "id": 750202,
          "name": "บางสะแก",
          "postCode": [
            "75120"
          ]
        },
        {
          "id": 750213,
          "name": "บ้านปราโมทย์",
          "postCode": [
            "75120"
          ]
        },
        {
          "id": 750211,
          "name": "ยายแพง",
          "postCode": [
            "75120"
          ]
        },
        {
          "id": 750204,
          "name": "โรงหีบ",
          "postCode": [
            "75120"
          ]
        }
      ]
    },
    {
      "city": "อัมพวา",
      "cityCode": 7503,
      "provinceCode": 75,
      "subDistrict": [
        {
          "id": 750303,
          "name": "ท่าคา",
          "postCode": [
            "75110"
          ]
        },
        {
          "id": 750306,
          "name": "บางช้าง",
          "postCode": [
            "75110"
          ]
        },
        {
          "id": 750312,
          "name": "บางนางลี่",
          "postCode": [
            "75110"
          ]
        },
        {
          "id": 750309,
          "name": "บางแค",
          "postCode": [
            "75110"
          ]
        },
        {
          "id": 750308,
          "name": "ปลายโพงพาง",
          "postCode": [
            "75110"
          ]
        },
        {
          "id": 750311,
          "name": "ยี่สาร",
          "postCode": [
            "75110"
          ]
        },
        {
          "id": 750304,
          "name": "วัดประดู่",
          "postCode": [
            "75110"
          ]
        },
        {
          "id": 750302,
          "name": "สวนหลวง",
          "postCode": [
            "75110"
          ]
        },
        {
          "id": 750301,
          "name": "อัมพวา",
          "postCode": [
            "75110"
          ]
        },
        {
          "id": 750305,
          "name": "เหมืองใหม่",
          "postCode": [
            "75110"
          ]
        },
        {
          "id": 750307,
          "name": "แควอ้อม",
          "postCode": [
            "75110"
          ]
        },
        {
          "id": 750310,
          "name": "แพรกหนามแดง",
          "postCode": [
            "75110"
          ]
        }
      ]
    },
    {
      "city": "เมืองสมุทรสงคราม",
      "cityCode": 7501,
      "provinceCode": 75,
      "subDistrict": [
        {
          "id": 750108,
          "name": "คลองเขิน",
          "postCode": [
            "75000"
          ]
        },
        {
          "id": 750109,
          "name": "คลองโคน",
          "postCode": [
            "75000"
          ]
        },
        {
          "id": 750106,
          "name": "ท้ายหาด",
          "postCode": [
            "75000"
          ]
        },
        {
          "id": 750110,
          "name": "นางตะเคียน",
          "postCode": [
            "75000"
          ]
        },
        {
          "id": 750102,
          "name": "บางขันแตก",
          "postCode": [
            "75000"
          ]
        },
        {
          "id": 750111,
          "name": "บางจะเกร็ง",
          "postCode": [
            "75000"
          ]
        },
        {
          "id": 750105,
          "name": "บางแก้ว",
          "postCode": [
            "75000"
          ]
        },
        {
          "id": 750104,
          "name": "บ้านปรก",
          "postCode": [
            "75000"
          ]
        },
        {
          "id": 750103,
          "name": "ลาดใหญ่",
          "postCode": [
            "75000"
          ]
        },
        {
          "id": 750101,
          "name": "แม่กลอง",
          "postCode": [
            "75000"
          ]
        },
        {
          "id": 750107,
          "name": "แหลมใหญ่",
          "postCode": [
            "75000"
          ]
        }
      ]
    },
    {
      "city": "กระทุ่มแบน",
      "cityCode": 7402,
      "provinceCode": 74,
      "subDistrict": [
        {
          "id": 740206,
          "name": "คลองมะเดื่อ",
          "postCode": [
            "74110"
          ]
        },
        {
          "id": 740208,
          "name": "ดอนไก่ดี",
          "postCode": [
            "74110"
          ]
        },
        {
          "id": 740201,
          "name": "ตลาดกระทุ่มแบน",
          "postCode": [
            "74110"
          ]
        },
        {
          "id": 740210,
          "name": "ท่าเสา",
          "postCode": [
            "74110"
          ]
        },
        {
          "id": 740203,
          "name": "ท่าไม้",
          "postCode": [
            "74110"
          ]
        },
        {
          "id": 740205,
          "name": "บางยาง",
          "postCode": [
            "74110"
          ]
        },
        {
          "id": 740204,
          "name": "สวนหลวง",
          "postCode": [
            "74110"
          ]
        },
        {
          "id": 740207,
          "name": "หนองนกไข่",
          "postCode": [
            "74110"
          ]
        },
        {
          "id": 740202,
          "name": "อ้อมน้อย",
          "postCode": [
            "74130"
          ]
        },
        {
          "id": 740209,
          "name": "แคราย",
          "postCode": [
            "74110"
          ]
        }
      ]
    },
    {
      "city": "บ้านแพ้ว",
      "cityCode": 7403,
      "provinceCode": 74,
      "subDistrict": [
        {
          "id": 740309,
          "name": "คลองตัน",
          "postCode": [
            "74120"
          ]
        },
        {
          "id": 740301,
          "name": "บ้านแพ้ว",
          "postCode": [
            "74120"
          ]
        },
        {
          "id": 740303,
          "name": "ยกกระบัตร",
          "postCode": [
            "74120"
          ]
        },
        {
          "id": 740311,
          "name": "สวนส้ม",
          "postCode": [
            "74120"
          ]
        },
        {
          "id": 740306,
          "name": "หนองบัว",
          "postCode": [
            "74120"
          ]
        },
        {
          "id": 740305,
          "name": "หนองสองห้อง",
          "postCode": [
            "70210",
            "74120"
          ]
        },
        {
          "id": 740307,
          "name": "หลักสอง",
          "postCode": [
            "74120"
          ]
        },
        {
          "id": 740302,
          "name": "หลักสาม",
          "postCode": [
            "74120"
          ]
        },
        {
          "id": 740310,
          "name": "อำแพง",
          "postCode": [
            "74120"
          ]
        },
        {
          "id": 740312,
          "name": "เกษตรพัฒนา",
          "postCode": [
            "74120"
          ]
        },
        {
          "id": 740308,
          "name": "เจ็ดริ้ว",
          "postCode": [
            "74120"
          ]
        },
        {
          "id": 740304,
          "name": "โรงเข้",
          "postCode": [
            "70210",
            "74120"
          ]
        }
      ]
    },
    {
      "city": "เมืองสมุทรสาคร",
      "cityCode": 7401,
      "provinceCode": 74,
      "subDistrict": [
        {
          "id": 740106,
          "name": "กาหลง",
          "postCode": [
            "74000"
          ]
        },
        {
          "id": 740111,
          "name": "คอกกระบือ",
          "postCode": [
            "74000"
          ]
        },
        {
          "id": 740118,
          "name": "ชัยมงคล",
          "postCode": [
            "74000"
          ]
        },
        {
          "id": 740108,
          "name": "ท่าจีน",
          "postCode": [
            "74000"
          ]
        },
        {
          "id": 740102,
          "name": "ท่าฉลอม",
          "postCode": [
            "74000"
          ]
        },
        {
          "id": 740110,
          "name": "ท่าทราย",
          "postCode": [
            "74000"
          ]
        },
        {
          "id": 740109,
          "name": "นาดี",
          "postCode": [
            "74000"
          ]
        },
        {
          "id": 740107,
          "name": "นาโคก",
          "postCode": [
            "74000"
          ]
        },
        {
          "id": 740116,
          "name": "บางกระเจ้า",
          "postCode": [
            "74000"
          ]
        },
        {
          "id": 740112,
          "name": "บางน้ำจืด",
          "postCode": [
            "74000"
          ]
        },
        {
          "id": 740117,
          "name": "บางหญ้าแพรก",
          "postCode": [
            "74000"
          ]
        },
        {
          "id": 740105,
          "name": "บางโทรัด",
          "postCode": [
            "74000"
          ]
        },
        {
          "id": 740104,
          "name": "บ้านบ่อ",
          "postCode": [
            "74000"
          ]
        },
        {
          "id": 740115,
          "name": "บ้านเกาะ",
          "postCode": [
            "74000"
          ]
        },
        {
          "id": 740113,
          "name": "พันท้ายนรสิงห์",
          "postCode": [
            "74000"
          ]
        },
        {
          "id": 740101,
          "name": "มหาชัย",
          "postCode": [
            "74000"
          ]
        },
        {
          "id": 740103,
          "name": "โกรกกราก",
          "postCode": [
            "74000"
          ]
        },
        {
          "id": 740114,
          "name": "โคกขาม",
          "postCode": [
            "74000"
          ]
        }
      ]
    },
    {
      "city": "ดอนพุด",
      "cityCode": 1907,
      "provinceCode": 19,
      "subDistrict": [
        {
          "id": 190704,
          "name": "ดงตะงาว",
          "postCode": [
            "18210"
          ]
        },
        {
          "id": 190701,
          "name": "ดอนพุด",
          "postCode": [
            "18210"
          ]
        },
        {
          "id": 190703,
          "name": "บ้านหลวง",
          "postCode": [
            "18210"
          ]
        },
        {
          "id": 190702,
          "name": "ไผ่หลิ่ว",
          "postCode": [
            "18210"
          ]
        }
      ]
    },
    {
      "city": "บ้านหมอ",
      "cityCode": 1906,
      "provinceCode": 19,
      "subDistrict": [
        {
          "id": 190604,
          "name": "ตลาดน้อย",
          "postCode": [
            "18130"
          ]
        },
        {
          "id": 190602,
          "name": "บางโขมด",
          "postCode": [
            "18130",
            "18270"
          ]
        },
        {
          "id": 190608,
          "name": "บ้านครัว",
          "postCode": [
            "18270"
          ]
        },
        {
          "id": 190601,
          "name": "บ้านหมอ",
          "postCode": [
            "18130"
          ]
        },
        {
          "id": 190603,
          "name": "สร่างโศก",
          "postCode": [
            "18130"
          ]
        },
        {
          "id": 190609,
          "name": "หนองบัว",
          "postCode": [
            "18130"
          ]
        },
        {
          "id": 190605,
          "name": "หรเทพ",
          "postCode": [
            "18130",
            "18210"
          ]
        },
        {
          "id": 190606,
          "name": "โคกใหญ่",
          "postCode": [
            "18130"
          ]
        },
        {
          "id": 190607,
          "name": "ไผ่ขวาง",
          "postCode": [
            "18130"
          ]
        }
      ]
    },
    {
      "city": "พระพุทธบาท",
      "cityCode": 1909,
      "provinceCode": 19,
      "subDistrict": [
        {
          "id": 190902,
          "name": "ขุนโขลน",
          "postCode": [
            "18120"
          ]
        },
        {
          "id": 190903,
          "name": "ธารเกษม",
          "postCode": [
            "18120"
          ]
        },
        {
          "id": 190904,
          "name": "นายาว",
          "postCode": [
            "18120"
          ]
        },
        {
          "id": 190901,
          "name": "พระพุทธบาท",
          "postCode": [
            "18120"
          ]
        },
        {
          "id": 190908,
          "name": "พุกร่าง",
          "postCode": [
            "18120"
          ]
        },
        {
          "id": 190905,
          "name": "พุคำจาน",
          "postCode": [
            "18120"
          ]
        },
        {
          "id": 190909,
          "name": "หนองแก",
          "postCode": [
            "18120"
          ]
        },
        {
          "id": 190907,
          "name": "ห้วยป่าหวาย",
          "postCode": [
            "18120"
          ]
        },
        {
          "id": 190906,
          "name": "เขาวง",
          "postCode": [
            "18120"
          ]
        }
      ]
    },
    {
      "city": "มวกเหล็ก",
      "cityCode": 1911,
      "provinceCode": 19,
      "subDistrict": [
        {
          "id": 191109,
          "name": "ซับสนุ่น",
          "postCode": [
            "18220"
          ]
        },
        {
          "id": 191101,
          "name": "มวกเหล็ก",
          "postCode": [
            "18180"
          ]
        },
        {
          "id": 191102,
          "name": "มิตรภาพ",
          "postCode": [
            "18180"
          ]
        },
        {
          "id": 191107,
          "name": "ลำพญากลาง",
          "postCode": [
            "30130"
          ]
        },
        {
          "id": 191105,
          "name": "ลำสมพุง",
          "postCode": [
            "30130"
          ]
        },
        {
          "id": 191104,
          "name": "หนองย่างเสือ",
          "postCode": [
            "18180"
          ]
        }
      ]
    },
    {
      "city": "วังม่วง",
      "cityCode": 1912,
      "provinceCode": 19,
      "subDistrict": [
        {
          "id": 191202,
          "name": "คำพราน",
          "postCode": [
            "18220"
          ]
        },
        {
          "id": 191203,
          "name": "วังม่วง",
          "postCode": [
            "18220"
          ]
        },
        {
          "id": 191201,
          "name": "แสลงพัน",
          "postCode": [
            "18220"
          ]
        }
      ]
    },
    {
      "city": "วิหารแดง",
      "cityCode": 1904,
      "provinceCode": 19,
      "subDistrict": [
        {
          "id": 190403,
          "name": "คลองเรือ",
          "postCode": [
            "18150"
          ]
        },
        {
          "id": 190402,
          "name": "บ้านลำ",
          "postCode": [
            "18150"
          ]
        },
        {
          "id": 190404,
          "name": "วิหารแดง",
          "postCode": [
            "18150"
          ]
        },
        {
          "id": 190405,
          "name": "หนองสรวง",
          "postCode": [
            "18150"
          ]
        },
        {
          "id": 190401,
          "name": "หนองหมู",
          "postCode": [
            "18150"
          ]
        },
        {
          "id": 190406,
          "name": "เจริญธรรม",
          "postCode": [
            "18150"
          ]
        }
      ]
    },
    {
      "city": "หนองแค",
      "cityCode": 1903,
      "provinceCode": 19,
      "subDistrict": [
        {
          "id": 190302,
          "name": "กุ่มหัก",
          "postCode": [
            "18140"
          ]
        },
        {
          "id": 190303,
          "name": "คชสิทธิ์",
          "postCode": [
            "18250"
          ]
        },
        {
          "id": 190306,
          "name": "บัวลอย",
          "postCode": [
            "18140",
            "18230"
          ]
        },
        {
          "id": 190314,
          "name": "หนองจรเข้",
          "postCode": [
            "18140"
          ]
        },
        {
          "id": 190313,
          "name": "หนองจิก",
          "postCode": [
            "18230"
          ]
        },
        {
          "id": 190315,
          "name": "หนองนาก",
          "postCode": [
            "18230"
          ]
        },
        {
          "id": 190316,
          "name": "หนองปลาหมอ",
          "postCode": [
            "18140"
          ]
        },
        {
          "id": 190317,
          "name": "หนองปลิง",
          "postCode": [
            "18140"
          ]
        },
        {
          "id": 190312,
          "name": "หนองแขม",
          "postCode": [
            "18140"
          ]
        },
        {
          "id": 190301,
          "name": "หนองแค",
          "postCode": [
            "18140"
          ]
        },
        {
          "id": 190318,
          "name": "หนองโรง",
          "postCode": [
            "18140"
          ]
        },
        {
          "id": 190311,
          "name": "หนองไข่น้ำ",
          "postCode": [
            "18140"
          ]
        },
        {
          "id": 190309,
          "name": "ห้วยขมิ้น",
          "postCode": [
            "18230"
          ]
        },
        {
          "id": 190310,
          "name": "ห้วยทราย",
          "postCode": [
            "18230"
          ]
        },
        {
          "id": 190304,
          "name": "โคกตูม",
          "postCode": [
            "18250"
          ]
        },
        {
          "id": 190305,
          "name": "โคกแย้",
          "postCode": [
            "18230"
          ]
        },
        {
          "id": 190308,
          "name": "โพนทอง",
          "postCode": [
            "18250"
          ]
        },
        {
          "id": 190307,
          "name": "ไผ่ต่ำ",
          "postCode": [
            "18140"
          ]
        }
      ]
    },
    {
      "city": "หนองแซง",
      "cityCode": 1905,
      "provinceCode": 19,
      "subDistrict": [
        {
          "id": 190508,
          "name": "ม่วงหวาน",
          "postCode": [
            "18170"
          ]
        },
        {
          "id": 190505,
          "name": "หนองกบ",
          "postCode": [
            "18170"
          ]
        },
        {
          "id": 190502,
          "name": "หนองควายโซ",
          "postCode": [
            "18170"
          ]
        },
        {
          "id": 190504,
          "name": "หนองสีดา",
          "postCode": [
            "18170"
          ]
        },
        {
          "id": 190503,
          "name": "หนองหัวโพ",
          "postCode": [
            "18170"
          ]
        },
        {
          "id": 190501,
          "name": "หนองแซง",
          "postCode": [
            "18170"
          ]
        },
        {
          "id": 190509,
          "name": "เขาดิน",
          "postCode": [
            "18170"
          ]
        },
        {
          "id": 190507,
          "name": "โคกสะอาด",
          "postCode": [
            "18170"
          ]
        },
        {
          "id": 190506,
          "name": "ไก่เส่า",
          "postCode": [
            "18170"
          ]
        }
      ]
    },
    {
      "city": "หนองโดน",
      "cityCode": 1908,
      "provinceCode": 19,
      "subDistrict": [
        {
          "id": 190803,
          "name": "ดอนทอง",
          "postCode": [
            "18190"
          ]
        },
        {
          "id": 190802,
          "name": "บ้านกลับ",
          "postCode": [
            "18190"
          ]
        },
        {
          "id": 190804,
          "name": "บ้านโปร่ง",
          "postCode": [
            "18190"
          ]
        },
        {
          "id": 190801,
          "name": "หนองโดน",
          "postCode": [
            "18190"
          ]
        }
      ]
    },
    {
      "city": "เฉลิมพระเกียรติ",
      "cityCode": 1913,
      "provinceCode": 19,
      "subDistrict": [
        {
          "id": 191302,
          "name": "บ้านแก้ง",
          "postCode": [
            "18000"
          ]
        },
        {
          "id": 191303,
          "name": "ผึ้งรวง",
          "postCode": [
            "18000"
          ]
        },
        {
          "id": 191304,
          "name": "พุแค",
          "postCode": [
            "18240"
          ]
        },
        {
          "id": 191306,
          "name": "หน้าพระลาน",
          "postCode": [
            "18240"
          ]
        },
        {
          "id": 191305,
          "name": "ห้วยบง",
          "postCode": [
            "18000",
            "18240"
          ]
        },
        {
          "id": 191301,
          "name": "เขาดินพัฒนา",
          "postCode": [
            "18000"
          ]
        }
      ]
    },
    {
      "city": "เมืองสระบุรี",
      "cityCode": 1901,
      "provinceCode": 19,
      "subDistrict": [
        {
          "id": 190112,
          "name": "กุดนกเปล้า",
          "postCode": [
            "18000"
          ]
        },
        {
          "id": 190105,
          "name": "ดาวเรือง",
          "postCode": [
            "18000"
          ]
        },
        {
          "id": 190113,
          "name": "ตลิ่งชัน",
          "postCode": [
            "18000"
          ]
        },
        {
          "id": 190114,
          "name": "ตะกุด",
          "postCode": [
            "18000"
          ]
        },
        {
          "id": 190106,
          "name": "นาโฉง",
          "postCode": [
            "18000"
          ]
        },
        {
          "id": 190110,
          "name": "ปากข้าวสาร",
          "postCode": [
            "18000"
          ]
        },
        {
          "id": 190101,
          "name": "ปากเพรียว",
          "postCode": [
            "18000"
          ]
        },
        {
          "id": 190111,
          "name": "หนองปลาไหล",
          "postCode": [
            "18000"
          ]
        },
        {
          "id": 190109,
          "name": "หนองยาว",
          "postCode": [
            "18000"
          ]
        },
        {
          "id": 190108,
          "name": "หนองโน",
          "postCode": [
            "18000"
          ]
        },
        {
          "id": 190107,
          "name": "โคกสว่าง",
          "postCode": [
            "18000"
          ]
        }
      ]
    },
    {
      "city": "เสาไห้",
      "cityCode": 1910,
      "provinceCode": 19,
      "subDistrict": [
        {
          "id": 191004,
          "name": "งิ้วงาม",
          "postCode": [
            "18160"
          ]
        },
        {
          "id": 191006,
          "name": "ต้นตาล",
          "postCode": [
            "18160"
          ]
        },
        {
          "id": 191007,
          "name": "ท่าช้าง",
          "postCode": [
            "18160"
          ]
        },
        {
          "id": 191002,
          "name": "บ้านยาง",
          "postCode": [
            "18160"
          ]
        },
        {
          "id": 191008,
          "name": "พระยาทด",
          "postCode": [
            "18160"
          ]
        },
        {
          "id": 191009,
          "name": "ม่วงงาม",
          "postCode": [
            "18160"
          ]
        },
        {
          "id": 191005,
          "name": "ศาลารีไทย",
          "postCode": [
            "18160"
          ]
        },
        {
          "id": 191012,
          "name": "สวนดอกไม้",
          "postCode": [
            "18160"
          ]
        },
        {
          "id": 191003,
          "name": "หัวปลวก",
          "postCode": [
            "18160"
          ]
        },
        {
          "id": 191011,
          "name": "เมืองเก่า",
          "postCode": [
            "18160"
          ]
        },
        {
          "id": 191010,
          "name": "เริงราง",
          "postCode": [
            "18160"
          ]
        },
        {
          "id": 191001,
          "name": "เสาไห้",
          "postCode": [
            "18160"
          ]
        }
      ]
    },
    {
      "city": "แก่งคอย",
      "cityCode": 1902,
      "provinceCode": 19,
      "subDistrict": [
        {
          "id": 190210,
          "name": "ชะอม",
          "postCode": [
            "18110"
          ]
        },
        {
          "id": 190213,
          "name": "ชำผักแพว",
          "postCode": [
            "18110"
          ]
        },
        {
          "id": 190203,
          "name": "ตาลเดี่ยว",
          "postCode": [
            "18110"
          ]
        },
        {
          "id": 190202,
          "name": "ทับกวาง",
          "postCode": [
            "18260"
          ]
        },
        {
          "id": 190205,
          "name": "ท่าคล้อ",
          "postCode": [
            "18110"
          ]
        },
        {
          "id": 190209,
          "name": "ท่าตูม",
          "postCode": [
            "18110"
          ]
        },
        {
          "id": 190215,
          "name": "ท่ามะปราง",
          "postCode": [
            "18110"
          ]
        },
        {
          "id": 190207,
          "name": "บ้านธาตุ",
          "postCode": [
            "18110"
          ]
        },
        {
          "id": 190208,
          "name": "บ้านป่า",
          "postCode": [
            "18110"
          ]
        },
        {
          "id": 190211,
          "name": "สองคอน",
          "postCode": [
            "18110"
          ]
        },
        {
          "id": 190206,
          "name": "หินซ้อน",
          "postCode": [
            "18110"
          ]
        },
        {
          "id": 190204,
          "name": "ห้วยแห้ง",
          "postCode": [
            "18110"
          ]
        },
        {
          "id": 190212,
          "name": "เตาปูน",
          "postCode": [
            "18110"
          ]
        },
        {
          "id": 190201,
          "name": "แก่งคอย",
          "postCode": [
            "18110"
          ]
        }
      ]
    },
    {
      "city": "คลองหาด",
      "cityCode": 2702,
      "provinceCode": 27,
      "subDistrict": [
        {
          "id": 270201,
          "name": "คลองหาด",
          "postCode": [
            "27260"
          ]
        },
        {
          "id": 270205,
          "name": "คลองไก่เถื่อน",
          "postCode": [
            "27260"
          ]
        },
        {
          "id": 270203,
          "name": "ซับมะกรูด",
          "postCode": [
            "27260"
          ]
        },
        {
          "id": 270206,
          "name": "เบญจขร",
          "postCode": [
            "27260"
          ]
        },
        {
          "id": 270202,
          "name": "ไทยอุดม",
          "postCode": [
            "27260"
          ]
        },
        {
          "id": 270207,
          "name": "ไทรทอง",
          "postCode": [
            "27260"
          ]
        },
        {
          "id": 270204,
          "name": "ไทรเดี่ยว",
          "postCode": [
            "27260"
          ]
        }
      ]
    },
    {
      "city": "ตาพระยา",
      "cityCode": 2703,
      "provinceCode": 27,
      "subDistrict": [
        {
          "id": 270301,
          "name": "ตาพระยา",
          "postCode": [
            "27180"
          ]
        },
        {
          "id": 270306,
          "name": "ทัพราช",
          "postCode": [
            "27180"
          ]
        },
        {
          "id": 270302,
          "name": "ทัพเสด็จ",
          "postCode": [
            "27180"
          ]
        },
        {
          "id": 270307,
          "name": "ทัพไทย",
          "postCode": [
            "27180"
          ]
        },
        {
          "id": 270309,
          "name": "โคคลาน",
          "postCode": [
            "27180"
          ]
        }
      ]
    },
    {
      "city": "วังน้ำเย็น",
      "cityCode": 2704,
      "provinceCode": 27,
      "subDistrict": [
        {
          "id": 270405,
          "name": "คลองหินปูน",
          "postCode": [
            "27210"
          ]
        },
        {
          "id": 270403,
          "name": "ตาหลังใน",
          "postCode": [
            "27210"
          ]
        },
        {
          "id": 270406,
          "name": "ทุ่งมหาเจริญ",
          "postCode": [
            "27210"
          ]
        },
        {
          "id": 270401,
          "name": "วังน้ำเย็น",
          "postCode": [
            "27210"
          ]
        }
      ]
    },
    {
      "city": "วังสมบูรณ์",
      "cityCode": 2709,
      "provinceCode": 27,
      "subDistrict": [
        {
          "id": 270903,
          "name": "วังทอง",
          "postCode": [
            "27250"
          ]
        },
        {
          "id": 270901,
          "name": "วังสมบูรณ์",
          "postCode": [
            "27250"
          ]
        },
        {
          "id": 270902,
          "name": "วังใหม่",
          "postCode": [
            "27210",
            "27250"
          ]
        }
      ]
    },
    {
      "city": "วัฒนานคร",
      "cityCode": 2705,
      "provinceCode": 27,
      "subDistrict": [
        {
          "id": 270506,
          "name": "ช่องกุ่ม",
          "postCode": [
            "27160"
          ]
        },
        {
          "id": 270502,
          "name": "ท่าเกวียน",
          "postCode": [
            "27160"
          ]
        },
        {
          "id": 270503,
          "name": "ผักขะ",
          "postCode": [
            "27160"
          ]
        },
        {
          "id": 270501,
          "name": "วัฒนานคร",
          "postCode": [
            "27160"
          ]
        },
        {
          "id": 270510,
          "name": "หนองตะเคียนบอน",
          "postCode": [
            "27160"
          ]
        },
        {
          "id": 270505,
          "name": "หนองน้ำใส",
          "postCode": [
            "27160"
          ]
        },
        {
          "id": 270509,
          "name": "หนองหมากฝ้าย",
          "postCode": [
            "27160"
          ]
        },
        {
          "id": 270507,
          "name": "หนองแวง",
          "postCode": [
            "27160"
          ]
        },
        {
          "id": 270511,
          "name": "ห้วยโจด",
          "postCode": [
            "27160"
          ]
        },
        {
          "id": 270508,
          "name": "แซร์ออ",
          "postCode": [
            "27160"
          ]
        },
        {
          "id": 270504,
          "name": "โนนหมากเค็ง",
          "postCode": [
            "27160"
          ]
        }
      ]
    },
    {
      "city": "อรัญประเทศ",
      "cityCode": 2706,
      "provinceCode": 27,
      "subDistrict": [
        {
          "id": 270611,
          "name": "คลองทับจันทร์",
          "postCode": [
            "27120"
          ]
        },
        {
          "id": 270604,
          "name": "คลองน้ำใส",
          "postCode": [
            "27120"
          ]
        },
        {
          "id": 270607,
          "name": "ทับพริก",
          "postCode": [
            "27120"
          ]
        },
        {
          "id": 270605,
          "name": "ท่าข้าม",
          "postCode": [
            "27120"
          ]
        },
        {
          "id": 270613,
          "name": "บ้านด่าน",
          "postCode": [
            "27120"
          ]
        },
        {
          "id": 270608,
          "name": "บ้านใหม่หนองไทร",
          "postCode": [
            "27120"
          ]
        },
        {
          "id": 270606,
          "name": "ป่าไร่",
          "postCode": [
            "27120"
          ]
        },
        {
          "id": 270609,
          "name": "ผ่านศึก",
          "postCode": [
            "27120"
          ]
        },
        {
          "id": 270612,
          "name": "ฟากห้วย",
          "postCode": [
            "27120"
          ]
        },
        {
          "id": 270610,
          "name": "หนองสังข์",
          "postCode": [
            "27120"
          ]
        },
        {
          "id": 270603,
          "name": "หันทราย",
          "postCode": [
            "27120"
          ]
        },
        {
          "id": 270601,
          "name": "อรัญประเทศ",
          "postCode": [
            "27120"
          ]
        },
        {
          "id": 270602,
          "name": "เมืองไผ่",
          "postCode": [
            "27120"
          ]
        }
      ]
    },
    {
      "city": "เขาฉกรรจ์",
      "cityCode": 2707,
      "provinceCode": 27,
      "subDistrict": [
        {
          "id": 270703,
          "name": "พระเพลิง",
          "postCode": [
            "27000"
          ]
        },
        {
          "id": 270702,
          "name": "หนองหว้า",
          "postCode": [
            "27000"
          ]
        },
        {
          "id": 270701,
          "name": "เขาฉกรรจ์",
          "postCode": [
            "27000"
          ]
        },
        {
          "id": 270704,
          "name": "เขาสามสิบ",
          "postCode": [
            "27000"
          ]
        }
      ]
    },
    {
      "city": "เมืองสระแก้ว",
      "cityCode": 2701,
      "provinceCode": 27,
      "subDistrict": [
        {
          "id": 270106,
          "name": "ท่าเกษม",
          "postCode": [
            "27000"
          ]
        },
        {
          "id": 270105,
          "name": "ท่าแยก",
          "postCode": [
            "27000"
          ]
        },
        {
          "id": 270102,
          "name": "บ้านแก้ง",
          "postCode": [
            "27000"
          ]
        },
        {
          "id": 270103,
          "name": "ศาลาลำดวน",
          "postCode": [
            "27000"
          ]
        },
        {
          "id": 270108,
          "name": "สระขวัญ",
          "postCode": [
            "27000"
          ]
        },
        {
          "id": 270101,
          "name": "สระแก้ว",
          "postCode": [
            "27000"
          ]
        },
        {
          "id": 270111,
          "name": "หนองบอน",
          "postCode": [
            "27000"
          ]
        },
        {
          "id": 270104,
          "name": "โคกปี่ฆ้อง",
          "postCode": [
            "27000"
          ]
        }
      ]
    },
    {
      "city": "โคกสูง",
      "cityCode": 2708,
      "provinceCode": 27,
      "subDistrict": [
        {
          "id": 270802,
          "name": "หนองม่วง",
          "postCode": [
            "27120"
          ]
        },
        {
          "id": 270803,
          "name": "หนองแวง",
          "postCode": [
            "27120"
          ]
        },
        {
          "id": 270801,
          "name": "โคกสูง",
          "postCode": [
            "27120"
          ]
        },
        {
          "id": 270804,
          "name": "โนนหมากมุ่น",
          "postCode": [
            "27120"
          ]
        }
      ]
    },
    {
      "city": "ค่ายบางระจัน",
      "cityCode": 1703,
      "provinceCode": 17,
      "subDistrict": [
        {
          "id": 170305,
          "name": "คอทราย",
          "postCode": [
            "16150"
          ]
        },
        {
          "id": 170304,
          "name": "ท่าข้าม",
          "postCode": [
            "16150"
          ]
        },
        {
          "id": 170302,
          "name": "บางระจัน",
          "postCode": [
            "16150"
          ]
        },
        {
          "id": 170306,
          "name": "หนองกระทุ่ม",
          "postCode": [
            "16150"
          ]
        },
        {
          "id": 170301,
          "name": "โพทะเล",
          "postCode": [
            "16150"
          ]
        },
        {
          "id": 170303,
          "name": "โพสังโฆ",
          "postCode": [
            "16150"
          ]
        }
      ]
    },
    {
      "city": "ท่าช้าง",
      "cityCode": 1705,
      "provinceCode": 17,
      "subDistrict": [
        {
          "id": 170501,
          "name": "ถอนสมอ",
          "postCode": [
            "16140"
          ]
        },
        {
          "id": 170504,
          "name": "พิกุลทอง",
          "postCode": [
            "16140"
          ]
        },
        {
          "id": 170503,
          "name": "วิหารขาว",
          "postCode": [
            "16140"
          ]
        },
        {
          "id": 170502,
          "name": "โพประจักษ์",
          "postCode": [
            "16140"
          ]
        }
      ]
    },
    {
      "city": "บางระจัน",
      "cityCode": 1702,
      "provinceCode": 17,
      "subDistrict": [
        {
          "id": 170206,
          "name": "บ้านจ่า",
          "postCode": [
            "16130"
          ]
        },
        {
          "id": 170207,
          "name": "พักทัน",
          "postCode": [
            "16130"
          ]
        },
        {
          "id": 170208,
          "name": "สระแจง",
          "postCode": [
            "16130"
          ]
        },
        {
          "id": 170201,
          "name": "สิงห์",
          "postCode": [
            "16130"
          ]
        },
        {
          "id": 170203,
          "name": "เชิงกลัด",
          "postCode": [
            "16130"
          ]
        },
        {
          "id": 170205,
          "name": "แม่ลา",
          "postCode": [
            "16130"
          ]
        },
        {
          "id": 170204,
          "name": "โพชนไก่",
          "postCode": [
            "16130"
          ]
        },
        {
          "id": 170202,
          "name": "ไม้ดัด",
          "postCode": [
            "16130"
          ]
        }
      ]
    },
    {
      "city": "พรหมบุรี",
      "cityCode": 1704,
      "provinceCode": 17,
      "subDistrict": [
        {
          "id": 170403,
          "name": "บางน้ำเชี่ยว",
          "postCode": [
            "16120"
          ]
        },
        {
          "id": 170404,
          "name": "บ้านหม้อ",
          "postCode": [
            "16120"
          ]
        },
        {
          "id": 170405,
          "name": "บ้านแป้ง",
          "postCode": [
            "16120"
          ]
        },
        {
          "id": 170402,
          "name": "พรหมบุรี",
          "postCode": [
            "16160"
          ]
        },
        {
          "id": 170401,
          "name": "พระงาม",
          "postCode": [
            "16120"
          ]
        },
        {
          "id": 170406,
          "name": "หัวป่า",
          "postCode": [
            "16120"
          ]
        },
        {
          "id": 170407,
          "name": "โรงช้าง",
          "postCode": [
            "16120"
          ]
        }
      ]
    },
    {
      "city": "อินทร์บุรี",
      "cityCode": 1706,
      "provinceCode": 17,
      "subDistrict": [
        {
          "id": 170604,
          "name": "งิ้วราย",
          "postCode": [
            "16110"
          ]
        },
        {
          "id": 170605,
          "name": "ชีน้ำร้าย",
          "postCode": [
            "16110"
          ]
        },
        {
          "id": 170608,
          "name": "ทองเอน",
          "postCode": [
            "16110"
          ]
        },
        {
          "id": 170603,
          "name": "ทับยา",
          "postCode": [
            "16110"
          ]
        },
        {
          "id": 170606,
          "name": "ท่างาม",
          "postCode": [
            "16110"
          ]
        },
        {
          "id": 170607,
          "name": "น้ำตาล",
          "postCode": [
            "16110"
          ]
        },
        {
          "id": 170602,
          "name": "ประศุก",
          "postCode": [
            "16110"
          ]
        },
        {
          "id": 170609,
          "name": "ห้วยชัน",
          "postCode": [
            "16110"
          ]
        },
        {
          "id": 170601,
          "name": "อินทร์บุรี",
          "postCode": [
            "16110"
          ]
        },
        {
          "id": 170610,
          "name": "โพธิ์ชัย",
          "postCode": [
            "16110"
          ]
        }
      ]
    },
    {
      "city": "เมืองสิงห์บุรี",
      "cityCode": 1701,
      "provinceCode": 17,
      "subDistrict": [
        {
          "id": 170107,
          "name": "จักรสีห์",
          "postCode": [
            "16000"
          ]
        },
        {
          "id": 170106,
          "name": "ต้นโพธิ์",
          "postCode": [
            "16000"
          ]
        },
        {
          "id": 170108,
          "name": "บางกระบือ",
          "postCode": [
            "16000"
          ]
        },
        {
          "id": 170101,
          "name": "บางพุทรา",
          "postCode": [
            "16000"
          ]
        },
        {
          "id": 170102,
          "name": "บางมัญ",
          "postCode": [
            "16000"
          ]
        },
        {
          "id": 170104,
          "name": "ม่วงหมู่",
          "postCode": [
            "16000"
          ]
        },
        {
          "id": 170105,
          "name": "หัวไผ่",
          "postCode": [
            "16000"
          ]
        },
        {
          "id": 170103,
          "name": "โพกรวม",
          "postCode": [
            "16000"
          ]
        }
      ]
    },
    {
      "city": "ดอนเจดีย์",
      "cityCode": 7206,
      "provinceCode": 72,
      "subDistrict": [
        {
          "id": 720601,
          "name": "ดอนเจดีย์",
          "postCode": [
            "72170"
          ]
        },
        {
          "id": 720605,
          "name": "ทะเลบก",
          "postCode": [
            "72170",
            "72250"
          ]
        },
        {
          "id": 720604,
          "name": "สระกระโจม",
          "postCode": [
            "72250"
          ]
        },
        {
          "id": 720602,
          "name": "หนองสาหร่าย",
          "postCode": [
            "72170"
          ]
        },
        {
          "id": 720603,
          "name": "ไร่รถ",
          "postCode": [
            "72170"
          ]
        }
      ]
    },
    {
      "city": "ด่านช้าง",
      "cityCode": 7203,
      "provinceCode": 72,
      "subDistrict": [
        {
          "id": 720302,
          "name": "ด่านช้าง",
          "postCode": [
            "72180"
          ]
        },
        {
          "id": 720306,
          "name": "นิคมกระเสียว",
          "postCode": [
            "72180"
          ]
        },
        {
          "id": 720305,
          "name": "วังคัน",
          "postCode": [
            "72180"
          ]
        },
        {
          "id": 720307,
          "name": "วังยาว",
          "postCode": [
            "72180"
          ]
        },
        {
          "id": 720301,
          "name": "หนองมะค่าโมง",
          "postCode": [
            "72180"
          ]
        },
        {
          "id": 720303,
          "name": "ห้วยขมิ้น",
          "postCode": [
            "72180"
          ]
        },
        {
          "id": 720304,
          "name": "องค์พระ",
          "postCode": [
            "72180"
          ]
        }
      ]
    },
    {
      "city": "บางปลาม้า",
      "cityCode": 7204,
      "provinceCode": 72,
      "subDistrict": [
        {
          "id": 720405,
          "name": "กฤษณา",
          "postCode": [
            "72150"
          ]
        },
        {
          "id": 720409,
          "name": "จรเข้ใหญ่",
          "postCode": [
            "72150"
          ]
        },
        {
          "id": 720403,
          "name": "ตะค่า",
          "postCode": [
            "72150"
          ]
        },
        {
          "id": 720402,
          "name": "บางปลาม้า",
          "postCode": [
            "72150"
          ]
        },
        {
          "id": 720404,
          "name": "บางใหญ่",
          "postCode": [
            "72150"
          ]
        },
        {
          "id": 720410,
          "name": "บ้านแหลม",
          "postCode": [
            "72150"
          ]
        },
        {
          "id": 720411,
          "name": "มะขามล้ม",
          "postCode": [
            "72150"
          ]
        },
        {
          "id": 720412,
          "name": "วังน้ำเย็น",
          "postCode": [
            "72150"
          ]
        },
        {
          "id": 720414,
          "name": "วัดดาว",
          "postCode": [
            "72150"
          ]
        },
        {
          "id": 720413,
          "name": "วัดโบสถ์",
          "postCode": [
            "72150"
          ]
        },
        {
          "id": 720406,
          "name": "สาลี",
          "postCode": [
            "72150"
          ]
        },
        {
          "id": 720408,
          "name": "องครักษ์",
          "postCode": [
            "72150"
          ]
        },
        {
          "id": 720401,
          "name": "โคกคราม",
          "postCode": [
            "72150"
          ]
        },
        {
          "id": 720407,
          "name": "ไผ่กองดิน",
          "postCode": [
            "72150"
          ]
        }
      ]
    },
    {
      "city": "ศรีประจันต์",
      "cityCode": 7205,
      "provinceCode": 72,
      "subDistrict": [
        {
          "id": 720505,
          "name": "ดอนปรู",
          "postCode": [
            "72140"
          ]
        },
        {
          "id": 720504,
          "name": "บางงาม",
          "postCode": [
            "72140"
          ]
        },
        {
          "id": 720502,
          "name": "บ้านกร่าง",
          "postCode": [
            "72140"
          ]
        },
        {
          "id": 720506,
          "name": "ปลายนา",
          "postCode": [
            "72140"
          ]
        },
        {
          "id": 720503,
          "name": "มดแดง",
          "postCode": [
            "72140"
          ]
        },
        {
          "id": 720508,
          "name": "วังน้ำซับ",
          "postCode": [
            "72140"
          ]
        },
        {
          "id": 720509,
          "name": "วังยาง",
          "postCode": [
            "72140"
          ]
        },
        {
          "id": 720507,
          "name": "วังหว้า",
          "postCode": [
            "72140"
          ]
        },
        {
          "id": 720501,
          "name": "ศรีประจันต์",
          "postCode": [
            "72140"
          ]
        }
      ]
    },
    {
      "city": "สองพี่น้อง",
      "cityCode": 7207,
      "provinceCode": 72,
      "subDistrict": [
        {
          "id": 720715,
          "name": "ดอนมะนาว",
          "postCode": [
            "72110"
          ]
        },
        {
          "id": 720710,
          "name": "ต้นตาล",
          "postCode": [
            "72110"
          ]
        },
        {
          "id": 720712,
          "name": "ทุ่งคอก",
          "postCode": [
            "72110",
            "72190"
          ]
        },
        {
          "id": 720704,
          "name": "บางตะเคียน",
          "postCode": [
            "72110"
          ]
        },
        {
          "id": 720703,
          "name": "บางตาเถร",
          "postCode": [
            "72110"
          ]
        },
        {
          "id": 720707,
          "name": "บางพลับ",
          "postCode": [
            "72110"
          ]
        },
        {
          "id": 720702,
          "name": "บางเลน",
          "postCode": [
            "72110"
          ]
        },
        {
          "id": 720714,
          "name": "บ่อสุพรรณ",
          "postCode": [
            "72190"
          ]
        },
        {
          "id": 720705,
          "name": "บ้านกุ่ม",
          "postCode": [
            "72110"
          ]
        },
        {
          "id": 720709,
          "name": "บ้านช้าง",
          "postCode": [
            "72110"
          ]
        },
        {
          "id": 720711,
          "name": "ศรีสำราญ",
          "postCode": [
            "72110",
            "72190"
          ]
        },
        {
          "id": 720701,
          "name": "สองพี่น้อง",
          "postCode": [
            "72110"
          ]
        },
        {
          "id": 720713,
          "name": "หนองบ่อ",
          "postCode": [
            "71170",
            "72190"
          ]
        },
        {
          "id": 720706,
          "name": "หัวโพธิ์",
          "postCode": [
            "72110"
          ]
        },
        {
          "id": 720708,
          "name": "เนินพระปรางค์",
          "postCode": [
            "72110"
          ]
        }
      ]
    },
    {
      "city": "สามชุก",
      "cityCode": 7208,
      "provinceCode": 72,
      "subDistrict": [
        {
          "id": 720807,
          "name": "กระเสียว",
          "postCode": [
            "72130"
          ]
        },
        {
          "id": 720805,
          "name": "บ้านสระ",
          "postCode": [
            "72130"
          ]
        },
        {
          "id": 720801,
          "name": "ย่านยาว",
          "postCode": [
            "72130"
          ]
        },
        {
          "id": 720802,
          "name": "วังลึก",
          "postCode": [
            "72130"
          ]
        },
        {
          "id": 720803,
          "name": "สามชุก",
          "postCode": [
            "72130"
          ]
        },
        {
          "id": 720804,
          "name": "หนองผักนาก",
          "postCode": [
            "72130"
          ]
        },
        {
          "id": 720806,
          "name": "หนองสะเดา",
          "postCode": [
            "72130"
          ]
        }
      ]
    },
    {
      "city": "หนองหญ้าไซ",
      "cityCode": 7210,
      "provinceCode": 72,
      "subDistrict": [
        {
          "id": 721006,
          "name": "ทัพหลวง",
          "postCode": [
            "72240"
          ]
        },
        {
          "id": 721005,
          "name": "หนองขาม",
          "postCode": [
            "72240"
          ]
        },
        {
          "id": 721002,
          "name": "หนองราชวัตร",
          "postCode": [
            "72240"
          ]
        },
        {
          "id": 721001,
          "name": "หนองหญ้าไซ",
          "postCode": [
            "72240"
          ]
        },
        {
          "id": 721003,
          "name": "หนองโพธิ์",
          "postCode": [
            "72240"
          ]
        },
        {
          "id": 721004,
          "name": "แจงงาม",
          "postCode": [
            "72240"
          ]
        }
      ]
    },
    {
      "city": "อู่ทอง",
      "cityCode": 7209,
      "provinceCode": 72,
      "subDistrict": [
        {
          "id": 720913,
          "name": "กระจัน",
          "postCode": [
            "72160"
          ]
        },
        {
          "id": 720903,
          "name": "จรเข้สามพัน",
          "postCode": [
            "71170",
            "72160"
          ]
        },
        {
          "id": 720908,
          "name": "ดอนคา",
          "postCode": [
            "72160"
          ]
        },
        {
          "id": 720906,
          "name": "ดอนมะเกลือ",
          "postCode": [
            "72220"
          ]
        },
        {
          "id": 720904,
          "name": "บ้านดอน",
          "postCode": [
            "72160"
          ]
        },
        {
          "id": 720910,
          "name": "บ้านโข้ง",
          "postCode": [
            "72160"
          ]
        },
        {
          "id": 720909,
          "name": "พลับพลาไชย",
          "postCode": [
            "72160"
          ]
        },
        {
          "id": 720905,
          "name": "ยุ้งทะลาย",
          "postCode": [
            "72160"
          ]
        },
        {
          "id": 720912,
          "name": "สระพังลาน",
          "postCode": [
            "72220"
          ]
        },
        {
          "id": 720902,
          "name": "สระยายโสม",
          "postCode": [
            "72220"
          ]
        },
        {
          "id": 720907,
          "name": "หนองโอ่ง",
          "postCode": [
            "72160"
          ]
        },
        {
          "id": 720901,
          "name": "อู่ทอง",
          "postCode": [
            "72160"
          ]
        },
        {
          "id": 720911,
          "name": "เจดีย์",
          "postCode": [
            "72160"
          ]
        }
      ]
    },
    {
      "city": "เดิมบางนางบวช",
      "cityCode": 7202,
      "provinceCode": 72,
      "subDistrict": [
        {
          "id": 720206,
          "name": "ทุ่งคลี",
          "postCode": [
            "72120"
          ]
        },
        {
          "id": 720203,
          "name": "นางบวช",
          "postCode": [
            "72120"
          ]
        },
        {
          "id": 720210,
          "name": "บ่อกรุ",
          "postCode": [
            "72120"
          ]
        },
        {
          "id": 720205,
          "name": "ปากน้ำ",
          "postCode": [
            "72120"
          ]
        },
        {
          "id": 720212,
          "name": "ป่าสะแก",
          "postCode": [
            "72120"
          ]
        },
        {
          "id": 720213,
          "name": "ยางนอน",
          "postCode": [
            "72120"
          ]
        },
        {
          "id": 720211,
          "name": "วังศรีราช",
          "postCode": [
            "72120"
          ]
        },
        {
          "id": 720214,
          "name": "หนองกระทุ่ม",
          "postCode": [
            "72120"
          ]
        },
        {
          "id": 720209,
          "name": "หัวนา",
          "postCode": [
            "72120"
          ]
        },
        {
          "id": 720208,
          "name": "หัวเขา",
          "postCode": [
            "72120"
          ]
        },
        {
          "id": 720204,
          "name": "เขาดิน",
          "postCode": [
            "72120"
          ]
        },
        {
          "id": 720201,
          "name": "เขาพระ",
          "postCode": [
            "72120"
          ]
        },
        {
          "id": 720202,
          "name": "เดิมบาง",
          "postCode": [
            "72120"
          ]
        },
        {
          "id": 720207,
          "name": "โคกช้าง",
          "postCode": [
            "72120"
          ]
        }
      ]
    },
    {
      "city": "เมืองสุพรรณบุรี",
      "cityCode": 7201,
      "provinceCode": 72,
      "subDistrict": [
        {
          "id": 720110,
          "name": "ดอนกำยาน",
          "postCode": [
            "72000"
          ]
        },
        {
          "id": 720107,
          "name": "ดอนตาล",
          "postCode": [
            "72000"
          ]
        },
        {
          "id": 720108,
          "name": "ดอนมะสังข์",
          "postCode": [
            "72000"
          ]
        },
        {
          "id": 720111,
          "name": "ดอนโพธิ์ทอง",
          "postCode": [
            "72000"
          ]
        },
        {
          "id": 720114,
          "name": "ตลิ่งชัน",
          "postCode": [
            "72230"
          ]
        },
        {
          "id": 720103,
          "name": "ทับตีเหล็ก",
          "postCode": [
            "72000"
          ]
        },
        {
          "id": 720101,
          "name": "ท่าพี่เลี้ยง",
          "postCode": [
            "72000"
          ]
        },
        {
          "id": 720104,
          "name": "ท่าระหัด",
          "postCode": [
            "72000"
          ]
        },
        {
          "id": 720115,
          "name": "บางกุ้ง",
          "postCode": [
            "72210"
          ]
        },
        {
          "id": 720112,
          "name": "บ้านโพธิ์",
          "postCode": [
            "72000"
          ]
        },
        {
          "id": 720109,
          "name": "พิหารแดง",
          "postCode": [
            "72000"
          ]
        },
        {
          "id": 720102,
          "name": "รั้วใหญ่",
          "postCode": [
            "72000"
          ]
        },
        {
          "id": 720116,
          "name": "ศาลาขาว",
          "postCode": [
            "72210"
          ]
        },
        {
          "id": 720120,
          "name": "สนามคลี",
          "postCode": [
            "72230"
          ]
        },
        {
          "id": 720118,
          "name": "สนามชัย",
          "postCode": [
            "72000"
          ]
        },
        {
          "id": 720113,
          "name": "สระแก้ว",
          "postCode": [
            "72230"
          ]
        },
        {
          "id": 720117,
          "name": "สวนแตง",
          "postCode": [
            "72210"
          ]
        },
        {
          "id": 720106,
          "name": "โคกโคเฒ่า",
          "postCode": [
            "72000"
          ]
        },
        {
          "id": 720119,
          "name": "โพธิ์พระยา",
          "postCode": [
            "72000"
          ]
        },
        {
          "id": 720105,
          "name": "ไผ่ขวาง",
          "postCode": [
            "72000"
          ]
        }
      ]
    },
    {
      "city": "กาญจนดิษฐ์",
      "cityCode": 8402,
      "provinceCode": 84,
      "subDistrict": [
        {
          "id": 840205,
          "name": "กรูด",
          "postCode": [
            "84160"
          ]
        },
        {
          "id": 840203,
          "name": "กะแดะ",
          "postCode": [
            "84160"
          ]
        },
        {
          "id": 840213,
          "name": "คลองสระ",
          "postCode": [
            "84160"
          ]
        },
        {
          "id": 840210,
          "name": "ช้างขวา",
          "postCode": [
            "84160"
          ]
        },
        {
          "id": 840206,
          "name": "ช้างซ้าย",
          "postCode": [
            "84160"
          ]
        },
        {
          "id": 840209,
          "name": "ตะเคียนทอง",
          "postCode": [
            "84160"
          ]
        },
        {
          "id": 840204,
          "name": "ทุ่งกง",
          "postCode": [
            "84290"
          ]
        },
        {
          "id": 840212,
          "name": "ทุ่งรัง",
          "postCode": [
            "84290"
          ]
        },
        {
          "id": 840202,
          "name": "ท่าทอง",
          "postCode": [
            "84160"
          ]
        },
        {
          "id": 840201,
          "name": "ท่าทองใหม่",
          "postCode": [
            "84290"
          ]
        },
        {
          "id": 840211,
          "name": "ท่าอุแท",
          "postCode": [
            "84160",
            "84340"
          ]
        },
        {
          "id": 840208,
          "name": "ป่าร่อน",
          "postCode": [
            "84160"
          ]
        },
        {
          "id": 840207,
          "name": "พลายวาส",
          "postCode": [
            "84160"
          ]
        }
      ]
    },
    {
      "city": "คีรีรัฐนิคม",
      "cityCode": 8408,
      "provinceCode": 84,
      "subDistrict": [
        {
          "id": 840806,
          "name": "กะเปา",
          "postCode": [
            "84180"
          ]
        },
        {
          "id": 840809,
          "name": "ถ้ำสิงขร",
          "postCode": [
            "84180"
          ]
        },
        {
          "id": 840807,
          "name": "ท่ากระดาน",
          "postCode": [
            "84180"
          ]
        },
        {
          "id": 840801,
          "name": "ท่าขนอน",
          "postCode": [
            "84180"
          ]
        },
        {
          "id": 840803,
          "name": "น้ำหัก",
          "postCode": [
            "84180"
          ]
        },
        {
          "id": 840810,
          "name": "บ้านทำเนียบ",
          "postCode": [
            "84180"
          ]
        },
        {
          "id": 840802,
          "name": "บ้านยาง",
          "postCode": [
            "84180"
          ]
        },
        {
          "id": 840808,
          "name": "ย่านยาว",
          "postCode": [
            "84180"
          ]
        }
      ]
    },
    {
      "city": "ชัยบุรี",
      "cityCode": 8418,
      "provinceCode": 84,
      "subDistrict": [
        {
          "id": 841803,
          "name": "คลองน้อย",
          "postCode": [
            "84350"
          ]
        },
        {
          "id": 841802,
          "name": "ชัยบุรี",
          "postCode": [
            "84350"
          ]
        },
        {
          "id": 841801,
          "name": "สองแพรก",
          "postCode": [
            "84350"
          ]
        },
        {
          "id": 841804,
          "name": "ไทรทอง",
          "postCode": [
            "84350"
          ]
        }
      ]
    },
    {
      "city": "ดอนสัก",
      "cityCode": 8403,
      "provinceCode": 84,
      "subDistrict": [
        {
          "id": 840302,
          "name": "ชลคราม",
          "postCode": [
            "84160"
          ]
        },
        {
          "id": 840301,
          "name": "ดอนสัก",
          "postCode": [
            "84220"
          ]
        },
        {
          "id": 840304,
          "name": "ปากแพรก",
          "postCode": [
            "84340"
          ]
        },
        {
          "id": 840303,
          "name": "ไชยคราม",
          "postCode": [
            "84220"
          ]
        }
      ]
    },
    {
      "city": "ท่าฉาง",
      "cityCode": 8411,
      "provinceCode": 84,
      "subDistrict": [
        {
          "id": 841103,
          "name": "คลองไทร",
          "postCode": [
            "84150"
          ]
        },
        {
          "id": 841101,
          "name": "ท่าฉาง",
          "postCode": [
            "84150"
          ]
        },
        {
          "id": 841102,
          "name": "ท่าเคย",
          "postCode": [
            "84150"
          ]
        },
        {
          "id": 841106,
          "name": "ปากฉลุย",
          "postCode": [
            "84150"
          ]
        },
        {
          "id": 841104,
          "name": "เขาถ่าน",
          "postCode": [
            "84150"
          ]
        },
        {
          "id": 841105,
          "name": "เสวียด",
          "postCode": [
            "84150"
          ]
        }
      ]
    },
    {
      "city": "ท่าชนะ",
      "cityCode": 8407,
      "provinceCode": 84,
      "subDistrict": [
        {
          "id": 840706,
          "name": "คลองพา",
          "postCode": [
            "84170"
          ]
        },
        {
          "id": 840704,
          "name": "คันธุลี",
          "postCode": [
            "84170"
          ]
        },
        {
          "id": 840701,
          "name": "ท่าชนะ",
          "postCode": [
            "84170"
          ]
        },
        {
          "id": 840703,
          "name": "ประสงค์",
          "postCode": [
            "84170"
          ]
        },
        {
          "id": 840705,
          "name": "วัง",
          "postCode": [
            "84170"
          ]
        },
        {
          "id": 840702,
          "name": "สมอทอง",
          "postCode": [
            "84170"
          ]
        }
      ]
    },
    {
      "city": "บ้านตาขุน",
      "cityCode": 8409,
      "provinceCode": 84,
      "subDistrict": [
        {
          "id": 840903,
          "name": "พรุไทย",
          "postCode": [
            "84230"
          ]
        },
        {
          "id": 840902,
          "name": "พะแสง",
          "postCode": [
            "84230"
          ]
        },
        {
          "id": 840904,
          "name": "เขาพัง",
          "postCode": [
            "84230"
          ]
        },
        {
          "id": 840901,
          "name": "เขาวง",
          "postCode": [
            "84230"
          ]
        }
      ]
    },
    {
      "city": "บ้านนาสาร",
      "cityCode": 8412,
      "provinceCode": 84,
      "subDistrict": [
        {
          "id": 841208,
          "name": "คลองปราบ",
          "postCode": [
            "84120"
          ]
        },
        {
          "id": 841206,
          "name": "ควนศรี",
          "postCode": [
            "84270"
          ]
        },
        {
          "id": 841207,
          "name": "ควนสุบรรณ",
          "postCode": [
            "84120",
            "84240"
          ]
        },
        {
          "id": 841203,
          "name": "ทุ่งเตา",
          "postCode": [
            "84120"
          ]
        },
        {
          "id": 841210,
          "name": "ทุ่งเตาใหม่",
          "postCode": [
            "84120"
          ]
        },
        {
          "id": 841205,
          "name": "ท่าชี",
          "postCode": [
            "84120"
          ]
        },
        {
          "id": 841201,
          "name": "นาสาร",
          "postCode": [
            "84120"
          ]
        },
        {
          "id": 841209,
          "name": "น้ำพุ",
          "postCode": [
            "84120"
          ]
        },
        {
          "id": 841202,
          "name": "พรุพี",
          "postCode": [
            "84270"
          ]
        },
        {
          "id": 841204,
          "name": "ลำพูน",
          "postCode": [
            "84120"
          ]
        },
        {
          "id": 841211,
          "name": "เพิ่มพูนทรัพย์",
          "postCode": [
            "84120"
          ]
        }
      ]
    },
    {
      "city": "บ้านนาเดิม",
      "cityCode": 8413,
      "provinceCode": 84,
      "subDistrict": [
        {
          "id": 841303,
          "name": "ทรัพย์ทวี",
          "postCode": [
            "84240"
          ]
        },
        {
          "id": 841302,
          "name": "ท่าเรือ",
          "postCode": [
            "84240"
          ]
        },
        {
          "id": 841304,
          "name": "นาใต้",
          "postCode": [
            "84240"
          ]
        },
        {
          "id": 841301,
          "name": "บ้านนา",
          "postCode": [
            "84240"
          ]
        }
      ]
    },
    {
      "city": "พนม",
      "cityCode": 8410,
      "provinceCode": 84,
      "subDistrict": [
        {
          "id": 841006,
          "name": "คลองชะอุ่น",
          "postCode": [
            "84250"
          ]
        },
        {
          "id": 841003,
          "name": "คลองศก",
          "postCode": [
            "84250"
          ]
        },
        {
          "id": 841002,
          "name": "ต้นยวน",
          "postCode": [
            "84250"
          ]
        },
        {
          "id": 841001,
          "name": "พนม",
          "postCode": [
            "84250"
          ]
        },
        {
          "id": 841004,
          "name": "พลูเถื่อน",
          "postCode": [
            "84250"
          ]
        },
        {
          "id": 841005,
          "name": "พังกาญจน์",
          "postCode": [
            "84250"
          ]
        }
      ]
    },
    {
      "city": "พระแสง",
      "cityCode": 8416,
      "provinceCode": 84,
      "subDistrict": [
        {
          "id": 841603,
          "name": "บางสวรรค์",
          "postCode": [
            "84210"
          ]
        },
        {
          "id": 841607,
          "name": "สาคู",
          "postCode": [
            "84210"
          ]
        },
        {
          "id": 841602,
          "name": "สินปุน",
          "postCode": [
            "84210"
          ]
        },
        {
          "id": 841605,
          "name": "สินเจริญ",
          "postCode": [
            "84210"
          ]
        },
        {
          "id": 841601,
          "name": "อิปัน",
          "postCode": [
            "84210"
          ]
        },
        {
          "id": 841604,
          "name": "ไทรขึง",
          "postCode": [
            "84210"
          ]
        },
        {
          "id": 841606,
          "name": "ไทรโสภา",
          "postCode": [
            "84210"
          ]
        }
      ]
    },
    {
      "city": "พุนพิน",
      "cityCode": 8417,
      "provinceCode": 84,
      "subDistrict": [
        {
          "id": 841707,
          "name": "กรูด",
          "postCode": [
            "84130"
          ]
        },
        {
          "id": 841716,
          "name": "ตะปาน",
          "postCode": [
            "84130"
          ]
        },
        {
          "id": 841701,
          "name": "ท่าข้าม",
          "postCode": [
            "84130"
          ]
        },
        {
          "id": 841702,
          "name": "ท่าสะท้อน",
          "postCode": [
            "84130"
          ]
        },
        {
          "id": 841706,
          "name": "ท่าโรงช้าง",
          "postCode": [
            "84130"
          ]
        },
        {
          "id": 841711,
          "name": "น้ำรอบ",
          "postCode": [
            "84130"
          ]
        },
        {
          "id": 841709,
          "name": "บางงอน",
          "postCode": [
            "84130"
          ]
        },
        {
          "id": 841704,
          "name": "บางมะเดื่อ",
          "postCode": [
            "84130"
          ]
        },
        {
          "id": 841705,
          "name": "บางเดือน",
          "postCode": [
            "84130"
          ]
        },
        {
          "id": 841708,
          "name": "พุนพิน",
          "postCode": [
            "84130"
          ]
        },
        {
          "id": 841712,
          "name": "มะลวน",
          "postCode": [
            "84130"
          ]
        },
        {
          "id": 841703,
          "name": "ลีเล็ด",
          "postCode": [
            "84130"
          ]
        },
        {
          "id": 841710,
          "name": "ศรีวิชัย",
          "postCode": [
            "84130"
          ]
        },
        {
          "id": 841714,
          "name": "หนองไทร",
          "postCode": [
            "84130"
          ]
        },
        {
          "id": 841713,
          "name": "หัวเตย",
          "postCode": [
            "84130"
          ]
        },
        {
          "id": 841715,
          "name": "เขาหัวควาย",
          "postCode": [
            "84130"
          ]
        }
      ]
    },
    {
      "city": "วิภาวดี",
      "cityCode": 8419,
      "provinceCode": 84,
      "subDistrict": [
        {
          "id": 841902,
          "name": "ตะกุกเหนือ",
          "postCode": [
            "84370"
          ]
        },
        {
          "id": 841901,
          "name": "ตะกุกใต้",
          "postCode": [
            "84370"
          ]
        }
      ]
    },
    {
      "city": "เกาะพะงัน",
      "cityCode": 8405,
      "provinceCode": 84,
      "subDistrict": [
        {
          "id": 840502,
          "name": "บ้านใต้",
          "postCode": [
            "84280"
          ]
        },
        {
          "id": 840501,
          "name": "เกาะพะงัน",
          "postCode": [
            "84280"
          ]
        },
        {
          "id": 840503,
          "name": "เกาะเต่า",
          "postCode": [
            "84360"
          ]
        }
      ]
    },
    {
      "city": "เกาะสมุย",
      "cityCode": 8404,
      "provinceCode": 84,
      "subDistrict": [
        {
          "id": 840403,
          "name": "ตลิ่งงาม",
          "postCode": [
            "84140"
          ]
        },
        {
          "id": 840406,
          "name": "บ่อผุด",
          "postCode": [
            "84320"
          ]
        },
        {
          "id": 840405,
          "name": "มะเร็ต",
          "postCode": [
            "84310"
          ]
        },
        {
          "id": 840402,
          "name": "ลิปะน้อย",
          "postCode": [
            "84140"
          ]
        },
        {
          "id": 840404,
          "name": "หน้าเมือง",
          "postCode": [
            "84140"
          ]
        },
        {
          "id": 840401,
          "name": "อ่างทอง",
          "postCode": [
            "84140",
            "84220"
          ]
        },
        {
          "id": 840407,
          "name": "แม่น้ำ",
          "postCode": [
            "84330"
          ]
        }
      ]
    },
    {
      "city": "เคียนซา",
      "cityCode": 8414,
      "provinceCode": 84,
      "subDistrict": [
        {
          "id": 841405,
          "name": "บ้านเสด็จ",
          "postCode": [
            "84260"
          ]
        },
        {
          "id": 841402,
          "name": "พ่วงพรมคร",
          "postCode": [
            "84210"
          ]
        },
        {
          "id": 841404,
          "name": "อรัญคามวารี",
          "postCode": [
            "84260"
          ]
        },
        {
          "id": 841403,
          "name": "เขาตอก",
          "postCode": [
            "84260"
          ]
        },
        {
          "id": 841401,
          "name": "เคียนซา",
          "postCode": [
            "84260"
          ]
        }
      ]
    },
    {
      "city": "เมืองสุราษฎร์ธานี",
      "cityCode": 8401,
      "provinceCode": 84,
      "subDistrict": [
        {
          "id": 840104,
          "name": "ขุนทะเล",
          "postCode": [
            "84100"
          ]
        },
        {
          "id": 840111,
          "name": "คลองฉนาก",
          "postCode": [
            "84000"
          ]
        },
        {
          "id": 840107,
          "name": "คลองน้อย",
          "postCode": [
            "84000"
          ]
        },
        {
          "id": 840101,
          "name": "ตลาด",
          "postCode": [
            "84000"
          ]
        },
        {
          "id": 840110,
          "name": "บางกุ้ง",
          "postCode": [
            "84000"
          ]
        },
        {
          "id": 840106,
          "name": "บางชนะ",
          "postCode": [
            "84000"
          ]
        },
        {
          "id": 840109,
          "name": "บางโพธิ์",
          "postCode": [
            "84000"
          ]
        },
        {
          "id": 840105,
          "name": "บางใบไม้",
          "postCode": [
            "84000"
          ]
        },
        {
          "id": 840108,
          "name": "บางไทร",
          "postCode": [
            "84000"
          ]
        },
        {
          "id": 840102,
          "name": "มะขามเตี้ย",
          "postCode": [
            "84000"
          ]
        },
        {
          "id": 840103,
          "name": "วัดประดู่",
          "postCode": [
            "84000"
          ]
        }
      ]
    },
    {
      "city": "เวียงสระ",
      "cityCode": 8415,
      "provinceCode": 84,
      "subDistrict": [
        {
          "id": 841503,
          "name": "คลองฉนวน",
          "postCode": [
            "84190"
          ]
        },
        {
          "id": 841504,
          "name": "ทุ่งหลวง",
          "postCode": [
            "84190"
          ]
        },
        {
          "id": 841502,
          "name": "บ้านส้อง",
          "postCode": [
            "84190"
          ]
        },
        {
          "id": 841505,
          "name": "เขานิพันธ์",
          "postCode": [
            "84190"
          ]
        },
        {
          "id": 841501,
          "name": "เวียงสระ",
          "postCode": [
            "84190"
          ]
        }
      ]
    },
    {
      "city": "ไชยา",
      "cityCode": 8406,
      "provinceCode": 84,
      "subDistrict": [
        {
          "id": 840601,
          "name": "ตลาดไชยา",
          "postCode": [
            "84110"
          ]
        },
        {
          "id": 840607,
          "name": "ตะกรบ",
          "postCode": [
            "84110"
          ]
        },
        {
          "id": 840605,
          "name": "ทุ่ง",
          "postCode": [
            "84110"
          ]
        },
        {
          "id": 840609,
          "name": "ปากหมาก",
          "postCode": [
            "84110"
          ]
        },
        {
          "id": 840606,
          "name": "ป่าเว",
          "postCode": [
            "84110"
          ]
        },
        {
          "id": 840602,
          "name": "พุมเรียง",
          "postCode": [
            "84110"
          ]
        },
        {
          "id": 840603,
          "name": "เลม็ด",
          "postCode": [
            "84110"
          ]
        },
        {
          "id": 840604,
          "name": "เวียง",
          "postCode": [
            "84110"
          ]
        },
        {
          "id": 840608,
          "name": "โมถ่าย",
          "postCode": [
            "84110"
          ]
        }
      ]
    },
    {
      "city": "กาบเชิง",
      "cityCode": 3206,
      "provinceCode": 32,
      "subDistrict": [
        {
          "id": 320601,
          "name": "กาบเชิง",
          "postCode": [
            "32210"
          ]
        },
        {
          "id": 320604,
          "name": "คูตัน",
          "postCode": [
            "32210"
          ]
        },
        {
          "id": 320605,
          "name": "ด่าน",
          "postCode": [
            "32210"
          ]
        },
        {
          "id": 320610,
          "name": "ตะเคียน",
          "postCode": [
            "32210"
          ]
        },
        {
          "id": 320606,
          "name": "แนงมุด",
          "postCode": [
            "32210"
          ]
        },
        {
          "id": 320607,
          "name": "โคกตะเคียน",
          "postCode": [
            "32210"
          ]
        }
      ]
    },
    {
      "city": "จอมพระ",
      "cityCode": 3204,
      "provinceCode": 32,
      "subDistrict": [
        {
          "id": 320403,
          "name": "กระหาด",
          "postCode": [
            "32180"
          ]
        },
        {
          "id": 320401,
          "name": "จอมพระ",
          "postCode": [
            "32180"
          ]
        },
        {
          "id": 320408,
          "name": "ชุมแสง",
          "postCode": [
            "32180"
          ]
        },
        {
          "id": 320404,
          "name": "บุแกรง",
          "postCode": [
            "32180"
          ]
        },
        {
          "id": 320406,
          "name": "บ้านผือ",
          "postCode": [
            "32180"
          ]
        },
        {
          "id": 320407,
          "name": "ลุ่มระวี",
          "postCode": [
            "32180"
          ]
        },
        {
          "id": 320405,
          "name": "หนองสนิท",
          "postCode": [
            "32180"
          ]
        },
        {
          "id": 320409,
          "name": "เป็นสุข",
          "postCode": [
            "32180"
          ]
        },
        {
          "id": 320402,
          "name": "เมืองลีง",
          "postCode": [
            "32180"
          ]
        }
      ]
    },
    {
      "city": "ชุมพลบุรี",
      "cityCode": 3202,
      "provinceCode": 32,
      "subDistrict": [
        {
          "id": 320208,
          "name": "กระเบื้อง",
          "postCode": [
            "32190"
          ]
        },
        {
          "id": 320201,
          "name": "ชุมพลบุรี",
          "postCode": [
            "32190"
          ]
        },
        {
          "id": 320202,
          "name": "นาหนองไผ่",
          "postCode": [
            "32190"
          ]
        },
        {
          "id": 320205,
          "name": "ยะวึก",
          "postCode": [
            "32190"
          ]
        },
        {
          "id": 320204,
          "name": "ศรีณรงค์",
          "postCode": [
            "32190"
          ]
        },
        {
          "id": 320207,
          "name": "สระขุด",
          "postCode": [
            "32190"
          ]
        },
        {
          "id": 320209,
          "name": "หนองเรือ",
          "postCode": [
            "32190"
          ]
        },
        {
          "id": 320206,
          "name": "เมืองบัว",
          "postCode": [
            "32190"
          ]
        },
        {
          "id": 320203,
          "name": "ไพรขลา",
          "postCode": [
            "32190"
          ]
        }
      ]
    },
    {
      "city": "ท่าตูม",
      "cityCode": 3203,
      "provinceCode": 32,
      "subDistrict": [
        {
          "id": 320302,
          "name": "กระโพ",
          "postCode": [
            "32120"
          ]
        },
        {
          "id": 320310,
          "name": "ทุ่งกุลา",
          "postCode": [
            "32120"
          ]
        },
        {
          "id": 320301,
          "name": "ท่าตูม",
          "postCode": [
            "32120"
          ]
        },
        {
          "id": 320306,
          "name": "บะ",
          "postCode": [
            "32120"
          ]
        },
        {
          "id": 320308,
          "name": "บัวโคก",
          "postCode": [
            "32120"
          ]
        },
        {
          "id": 320303,
          "name": "พรมเทพ",
          "postCode": [
            "32120"
          ]
        },
        {
          "id": 320307,
          "name": "หนองบัว",
          "postCode": [
            "32120"
          ]
        },
        {
          "id": 320309,
          "name": "หนองเมธี",
          "postCode": [
            "32120"
          ]
        },
        {
          "id": 320305,
          "name": "เมืองแก",
          "postCode": [
            "32120"
          ]
        },
        {
          "id": 320304,
          "name": "โพนครก",
          "postCode": [
            "32120"
          ]
        }
      ]
    },
    {
      "city": "บัวเชด",
      "cityCode": 3213,
      "provinceCode": 32,
      "subDistrict": [
        {
          "id": 321303,
          "name": "จรัส",
          "postCode": [
            "32230"
          ]
        },
        {
          "id": 321304,
          "name": "ตาวัง",
          "postCode": [
            "32230"
          ]
        },
        {
          "id": 321301,
          "name": "บัวเชด",
          "postCode": [
            "32230"
          ]
        },
        {
          "id": 321302,
          "name": "สะเดา",
          "postCode": [
            "32230"
          ]
        },
        {
          "id": 321306,
          "name": "สำเภาลูน",
          "postCode": [
            "32230"
          ]
        },
        {
          "id": 321305,
          "name": "อาโพน",
          "postCode": [
            "32230"
          ]
        }
      ]
    },
    {
      "city": "ปราสาท",
      "cityCode": 3205,
      "provinceCode": 32,
      "subDistrict": [
        {
          "id": 320501,
          "name": "กังแอน",
          "postCode": [
            "32140"
          ]
        },
        {
          "id": 320516,
          "name": "กันตวจระมวล",
          "postCode": [
            "32140"
          ]
        },
        {
          "id": 320514,
          "name": "ตานี",
          "postCode": [
            "32140"
          ]
        },
        {
          "id": 320506,
          "name": "ตาเบา",
          "postCode": [
            "32140"
          ]
        },
        {
          "id": 320502,
          "name": "ทมอ",
          "postCode": [
            "32140"
          ]
        },
        {
          "id": 320505,
          "name": "ทุ่งมน",
          "postCode": [
            "32140"
          ]
        },
        {
          "id": 320515,
          "name": "บ้านพลวง",
          "postCode": [
            "32140"
          ]
        },
        {
          "id": 320510,
          "name": "บ้านไทร",
          "postCode": [
            "32140"
          ]
        },
        {
          "id": 320518,
          "name": "ประทัดบุ",
          "postCode": [
            "32140"
          ]
        },
        {
          "id": 320513,
          "name": "ปราสาททนง",
          "postCode": [
            "32140"
          ]
        },
        {
          "id": 320504,
          "name": "ปรือ",
          "postCode": [
            "32140"
          ]
        },
        {
          "id": 320517,
          "name": "สมุด",
          "postCode": [
            "32140"
          ]
        },
        {
          "id": 320507,
          "name": "หนองใหญ่",
          "postCode": [
            "32140"
          ]
        },
        {
          "id": 320512,
          "name": "เชื้อเพลิง",
          "postCode": [
            "32140"
          ]
        },
        {
          "id": 320508,
          "name": "โคกยาง",
          "postCode": [
            "32140"
          ]
        },
        {
          "id": 320509,
          "name": "โคกสะอาด",
          "postCode": [
            "32140"
          ]
        },
        {
          "id": 320511,
          "name": "โชคนาสาม",
          "postCode": [
            "32140"
          ]
        },
        {
          "id": 320503,
          "name": "ไพล",
          "postCode": [
            "32140"
          ]
        }
      ]
    },
    {
      "city": "พนมดงรัก",
      "cityCode": 3214,
      "provinceCode": 32,
      "subDistrict": [
        {
          "id": 321403,
          "name": "จีกแดก",
          "postCode": [
            "32140"
          ]
        },
        {
          "id": 321404,
          "name": "ตาเมียง",
          "postCode": [
            "32140"
          ]
        },
        {
          "id": 321401,
          "name": "บักได",
          "postCode": [
            "32140"
          ]
        },
        {
          "id": 321402,
          "name": "โคกกลาง",
          "postCode": [
            "32140"
          ]
        }
      ]
    },
    {
      "city": "รัตนบุรี",
      "cityCode": 3207,
      "provinceCode": 32,
      "subDistrict": [
        {
          "id": 320714,
          "name": "กุดขาคีม",
          "postCode": [
            "32130"
          ]
        },
        {
          "id": 320704,
          "name": "ดอนแรด",
          "postCode": [
            "32130"
          ]
        },
        {
          "id": 320716,
          "name": "ทับใหญ่",
          "postCode": [
            "32130"
          ]
        },
        {
          "id": 320702,
          "name": "ธาตุ",
          "postCode": [
            "32130"
          ]
        },
        {
          "id": 320713,
          "name": "น้ำเขียว",
          "postCode": [
            "32130"
          ]
        },
        {
          "id": 320715,
          "name": "ยางสว่าง",
          "postCode": [
            "32130"
          ]
        },
        {
          "id": 320701,
          "name": "รัตนบุรี",
          "postCode": [
            "32130"
          ]
        },
        {
          "id": 320705,
          "name": "หนองบัวทอง",
          "postCode": [
            "32130"
          ]
        },
        {
          "id": 320706,
          "name": "หนองบัวบาน",
          "postCode": [
            "32130"
          ]
        },
        {
          "id": 320711,
          "name": "เบิด",
          "postCode": [
            "32130"
          ]
        },
        {
          "id": 320703,
          "name": "แก",
          "postCode": [
            "32130"
          ]
        },
        {
          "id": 320709,
          "name": "ไผ่",
          "postCode": [
            "32130"
          ]
        }
      ]
    },
    {
      "city": "ลำดวน",
      "cityCode": 3211,
      "provinceCode": 32,
      "subDistrict": [
        {
          "id": 321105,
          "name": "ตระเปียงเตีย",
          "postCode": [
            "32220"
          ]
        },
        {
          "id": 321104,
          "name": "ตรำดม",
          "postCode": [
            "32220"
          ]
        },
        {
          "id": 321101,
          "name": "ลำดวน",
          "postCode": [
            "32220"
          ]
        },
        {
          "id": 321103,
          "name": "อู่โลก",
          "postCode": [
            "32220"
          ]
        },
        {
          "id": 321102,
          "name": "โชคเหนือ",
          "postCode": [
            "32220"
          ]
        }
      ]
    },
    {
      "city": "ศรีณรงค์",
      "cityCode": 3215,
      "provinceCode": 32,
      "subDistrict": [
        {
          "id": 321501,
          "name": "ณรงค์",
          "postCode": [
            "32150"
          ]
        },
        {
          "id": 321503,
          "name": "ตรวจ",
          "postCode": [
            "32150"
          ]
        },
        {
          "id": 321505,
          "name": "ศรีสุข",
          "postCode": [
            "32150"
          ]
        },
        {
          "id": 321504,
          "name": "หนองแวง",
          "postCode": [
            "32150"
          ]
        },
        {
          "id": 321502,
          "name": "แจนแวน",
          "postCode": [
            "32150"
          ]
        }
      ]
    },
    {
      "city": "ศีขรภูมิ",
      "cityCode": 3209,
      "provinceCode": 32,
      "subDistrict": [
        {
          "id": 320911,
          "name": "กุดหวาย",
          "postCode": [
            "32110"
          ]
        },
        {
          "id": 320912,
          "name": "ขวาวใหญ่",
          "postCode": [
            "32110"
          ]
        },
        {
          "id": 320907,
          "name": "คาละแมะ",
          "postCode": [
            "32110"
          ]
        },
        {
          "id": 320903,
          "name": "จารพัต",
          "postCode": [
            "32110"
          ]
        },
        {
          "id": 320910,
          "name": "ช่างปี่",
          "postCode": [
            "32110"
          ]
        },
        {
          "id": 320914,
          "name": "ตรมไพร",
          "postCode": [
            "32110"
          ]
        },
        {
          "id": 320902,
          "name": "ตรึม",
          "postCode": [
            "32110"
          ]
        },
        {
          "id": 320913,
          "name": "นารุ่ง",
          "postCode": [
            "32110"
          ]
        },
        {
          "id": 320915,
          "name": "ผักไหม",
          "postCode": [
            "32110"
          ]
        },
        {
          "id": 320904,
          "name": "ยาง",
          "postCode": [
            "32110"
          ]
        },
        {
          "id": 320901,
          "name": "ระแงง",
          "postCode": [
            "32110"
          ]
        },
        {
          "id": 320909,
          "name": "หนองขวาว",
          "postCode": [
            "32110"
          ]
        },
        {
          "id": 320906,
          "name": "หนองบัว",
          "postCode": [
            "32110"
          ]
        },
        {
          "id": 320908,
          "name": "หนองเหล็ก",
          "postCode": [
            "32110"
          ]
        },
        {
          "id": 320905,
          "name": "แตล",
          "postCode": [
            "32110"
          ]
        }
      ]
    },
    {
      "city": "สนม",
      "cityCode": 3208,
      "provinceCode": 32,
      "subDistrict": [
        {
          "id": 320804,
          "name": "นานวน",
          "postCode": [
            "32160"
          ]
        },
        {
          "id": 320801,
          "name": "สนม",
          "postCode": [
            "32160"
          ]
        },
        {
          "id": 320803,
          "name": "หนองระฆัง",
          "postCode": [
            "32160"
          ]
        },
        {
          "id": 320807,
          "name": "หนองอียอ",
          "postCode": [
            "32160"
          ]
        },
        {
          "id": 320806,
          "name": "หัวงัว",
          "postCode": [
            "32160"
          ]
        },
        {
          "id": 320805,
          "name": "แคน",
          "postCode": [
            "32160"
          ]
        },
        {
          "id": 320802,
          "name": "โพนโก",
          "postCode": [
            "32160"
          ]
        }
      ]
    },
    {
      "city": "สังขะ",
      "cityCode": 3210,
      "provinceCode": 32,
      "subDistrict": [
        {
          "id": 321009,
          "name": "กระเทียม",
          "postCode": [
            "32150"
          ]
        },
        {
          "id": 321002,
          "name": "ขอนแตก",
          "postCode": [
            "32150"
          ]
        },
        {
          "id": 321006,
          "name": "ดม",
          "postCode": [
            "32150"
          ]
        },
        {
          "id": 321013,
          "name": "ตาคง",
          "postCode": [
            "32150"
          ]
        },
        {
          "id": 321011,
          "name": "ตาตุม",
          "postCode": [
            "32150"
          ]
        },
        {
          "id": 321012,
          "name": "ทับทัน",
          "postCode": [
            "32150"
          ]
        },
        {
          "id": 321008,
          "name": "บ้านจารย์",
          "postCode": [
            "32150"
          ]
        },
        {
          "id": 321015,
          "name": "บ้านชบ",
          "postCode": [
            "32150"
          ]
        },
        {
          "id": 321007,
          "name": "พระแก้ว",
          "postCode": [
            "32150"
          ]
        },
        {
          "id": 321010,
          "name": "สะกาด",
          "postCode": [
            "32150"
          ]
        },
        {
          "id": 321001,
          "name": "สังขะ",
          "postCode": [
            "32150"
          ]
        },
        {
          "id": 321017,
          "name": "เทพรักษา",
          "postCode": [
            "32150"
          ]
        }
      ]
    },
    {
      "city": "สำโรงทาบ",
      "cityCode": 3212,
      "provinceCode": 32,
      "subDistrict": [
        {
          "id": 321203,
          "name": "กระออม",
          "postCode": [
            "32170"
          ]
        },
        {
          "id": 321210,
          "name": "ประดู่",
          "postCode": [
            "32170"
          ]
        },
        {
          "id": 321205,
          "name": "ศรีสุข",
          "postCode": [
            "32170"
          ]
        },
        {
          "id": 321209,
          "name": "สะโน",
          "postCode": [
            "32170"
          ]
        },
        {
          "id": 321201,
          "name": "สำโรงทาบ",
          "postCode": [
            "32170"
          ]
        },
        {
          "id": 321204,
          "name": "หนองฮะ",
          "postCode": [
            "32170"
          ]
        },
        {
          "id": 321202,
          "name": "หนองไผ่ล้อม",
          "postCode": [
            "32170"
          ]
        },
        {
          "id": 321207,
          "name": "หมื่นศรี",
          "postCode": [
            "32170"
          ]
        },
        {
          "id": 321206,
          "name": "เกาะแก้ว",
          "postCode": [
            "32170"
          ]
        },
        {
          "id": 321208,
          "name": "เสม็จ",
          "postCode": [
            "32170"
          ]
        }
      ]
    },
    {
      "city": "เขวาสินรินทร์",
      "cityCode": 3216,
      "provinceCode": 32,
      "subDistrict": [
        {
          "id": 321603,
          "name": "ตากูก",
          "postCode": [
            "32000"
          ]
        },
        {
          "id": 321602,
          "name": "บึง",
          "postCode": [
            "32000"
          ]
        },
        {
          "id": 321605,
          "name": "บ้านแร่",
          "postCode": [
            "32000"
          ]
        },
        {
          "id": 321604,
          "name": "ปราสาททอง",
          "postCode": [
            "32000"
          ]
        },
        {
          "id": 321601,
          "name": "เขวาสินรินทร์",
          "postCode": [
            "32000"
          ]
        }
      ]
    },
    {
      "city": "เมืองสุรินทร์",
      "cityCode": 3201,
      "provinceCode": 32,
      "subDistrict": [
        {
          "id": 320126,
          "name": "กาเกาะ",
          "postCode": [
            "32000"
          ]
        },
        {
          "id": 320112,
          "name": "คอโค",
          "postCode": [
            "32000"
          ]
        },
        {
          "id": 320122,
          "name": "ตระแสง",
          "postCode": [
            "32000"
          ]
        },
        {
          "id": 320102,
          "name": "ตั้งใจ",
          "postCode": [
            "32000"
          ]
        },
        {
          "id": 320107,
          "name": "ตาอ็อง",
          "postCode": [
            "32000"
          ]
        },
        {
          "id": 320105,
          "name": "ท่าสว่าง",
          "postCode": [
            "32000"
          ]
        },
        {
          "id": 320111,
          "name": "นอกเมือง",
          "postCode": [
            "32000"
          ]
        },
        {
          "id": 320104,
          "name": "นาดี",
          "postCode": [
            "32000"
          ]
        },
        {
          "id": 320118,
          "name": "นาบัว",
          "postCode": [
            "32000"
          ]
        },
        {
          "id": 320121,
          "name": "บุฤาษี",
          "postCode": [
            "32000"
          ]
        },
        {
          "id": 320120,
          "name": "ราม",
          "postCode": [
            "32000"
          ]
        },
        {
          "id": 320106,
          "name": "สลักได",
          "postCode": [
            "32000"
          ]
        },
        {
          "id": 320113,
          "name": "สวาย",
          "postCode": [
            "32000"
          ]
        },
        {
          "id": 320109,
          "name": "สำโรง",
          "postCode": [
            "32000"
          ]
        },
        {
          "id": 320114,
          "name": "เฉนียง",
          "postCode": [
            "32000"
          ]
        },
        {
          "id": 320116,
          "name": "เทนมีย์",
          "postCode": [
            "32000"
          ]
        },
        {
          "id": 320103,
          "name": "เพี้ยราม",
          "postCode": [
            "32000"
          ]
        },
        {
          "id": 320119,
          "name": "เมืองที",
          "postCode": [
            "32000"
          ]
        },
        {
          "id": 320110,
          "name": "แกใหญ่",
          "postCode": [
            "32000"
          ]
        },
        {
          "id": 320125,
          "name": "แสลงพันธ์",
          "postCode": [
            "32000"
          ]
        },
        {
          "id": 320101,
          "name": "ในเมือง",
          "postCode": [
            "32000"
          ]
        }
      ]
    },
    {
      "city": "โนนนารายณ์",
      "cityCode": 3217,
      "provinceCode": 32,
      "subDistrict": [
        {
          "id": 321702,
          "name": "คำผง",
          "postCode": [
            "32130"
          ]
        },
        {
          "id": 321704,
          "name": "ระเวียง",
          "postCode": [
            "32130"
          ]
        },
        {
          "id": 321701,
          "name": "หนองหลวง",
          "postCode": [
            "32130"
          ]
        },
        {
          "id": 321705,
          "name": "หนองเทพ",
          "postCode": [
            "32130"
          ]
        },
        {
          "id": 321703,
          "name": "โนน",
          "postCode": [
            "32130"
          ]
        }
      ]
    },
    {
      "city": "กงไกรลาศ",
      "cityCode": 6404,
      "provinceCode": 64,
      "subDistrict": [
        {
          "id": 640408,
          "name": "กกแรต",
          "postCode": [
            "64170"
          ]
        },
        {
          "id": 640401,
          "name": "กง",
          "postCode": [
            "64170"
          ]
        },
        {
          "id": 640406,
          "name": "ดงเดือย",
          "postCode": [
            "64170"
          ]
        },
        {
          "id": 640409,
          "name": "ท่าฉนวน",
          "postCode": [
            "64170"
          ]
        },
        {
          "id": 640402,
          "name": "บ้านกร่าง",
          "postCode": [
            "64170"
          ]
        },
        {
          "id": 640411,
          "name": "บ้านใหม่สุขเกษม",
          "postCode": [
            "64170"
          ]
        },
        {
          "id": 640407,
          "name": "ป่าแฝก",
          "postCode": [
            "64170"
          ]
        },
        {
          "id": 640410,
          "name": "หนองตูม",
          "postCode": [
            "64170"
          ]
        },
        {
          "id": 640404,
          "name": "ไกรกลาง",
          "postCode": [
            "64170"
          ]
        },
        {
          "id": 640403,
          "name": "ไกรนอก",
          "postCode": [
            "64170"
          ]
        },
        {
          "id": 640405,
          "name": "ไกรใน",
          "postCode": [
            "64170"
          ]
        }
      ]
    },
    {
      "city": "คีรีมาศ",
      "cityCode": 6403,
      "provinceCode": 64,
      "subDistrict": [
        {
          "id": 640310,
          "name": "ทุ่งยางเมือง",
          "postCode": [
            "64160"
          ]
        },
        {
          "id": 640302,
          "name": "ทุ่งหลวง",
          "postCode": [
            "64160"
          ]
        },
        {
          "id": 640307,
          "name": "นาเชิงคีรี",
          "postCode": [
            "64160"
          ]
        },
        {
          "id": 640309,
          "name": "บ้านน้ำพุ",
          "postCode": [
            "64160"
          ]
        },
        {
          "id": 640303,
          "name": "บ้านป้อม",
          "postCode": [
            "64160"
          ]
        },
        {
          "id": 640305,
          "name": "ศรีคีรีมาศ",
          "postCode": [
            "64160"
          ]
        },
        {
          "id": 640304,
          "name": "สามพวง",
          "postCode": [
            "64160"
          ]
        },
        {
          "id": 640308,
          "name": "หนองกระดิ่ง",
          "postCode": [
            "64160"
          ]
        },
        {
          "id": 640306,
          "name": "หนองจิก",
          "postCode": [
            "64160"
          ]
        },
        {
          "id": 640301,
          "name": "โตนด",
          "postCode": [
            "64160"
          ]
        }
      ]
    },
    {
      "city": "ทุ่งเสลี่ยม",
      "cityCode": 6409,
      "provinceCode": 64,
      "subDistrict": [
        {
          "id": 640904,
          "name": "กลางดง",
          "postCode": [
            "64150"
          ]
        },
        {
          "id": 640903,
          "name": "ทุ่งเสลี่ยม",
          "postCode": [
            "64150"
          ]
        },
        {
          "id": 640901,
          "name": "บ้านใหม่ไชยมงคล",
          "postCode": [
            "64230"
          ]
        },
        {
          "id": 640905,
          "name": "เขาแก้วศรีสมบูรณ์",
          "postCode": [
            "64230"
          ]
        },
        {
          "id": 640902,
          "name": "ไทยชนะศึก",
          "postCode": [
            "64150"
          ]
        }
      ]
    },
    {
      "city": "บ้านด่านลานหอย",
      "cityCode": 6402,
      "provinceCode": 64,
      "subDistrict": [
        {
          "id": 640205,
          "name": "ตลิ่งชัน",
          "postCode": [
            "64140"
          ]
        },
        {
          "id": 640202,
          "name": "บ้านด่าน",
          "postCode": [
            "64140"
          ]
        },
        {
          "id": 640201,
          "name": "ลานหอย",
          "postCode": [
            "64140"
          ]
        },
        {
          "id": 640203,
          "name": "วังตะคร้อ",
          "postCode": [
            "64140"
          ]
        },
        {
          "id": 640204,
          "name": "วังน้ำขาว",
          "postCode": [
            "64140"
          ]
        },
        {
          "id": 640207,
          "name": "วังลึก",
          "postCode": [
            "64140"
          ]
        },
        {
          "id": 640206,
          "name": "หนองหญ้าปล้อง",
          "postCode": [
            "64140"
          ]
        }
      ]
    },
    {
      "city": "ศรีนคร",
      "cityCode": 6408,
      "provinceCode": 64,
      "subDistrict": [
        {
          "id": 640804,
          "name": "คลองมะพลับ",
          "postCode": [
            "64180"
          ]
        },
        {
          "id": 640802,
          "name": "นครเดิฐ",
          "postCode": [
            "64180"
          ]
        },
        {
          "id": 640803,
          "name": "น้ำขุม",
          "postCode": [
            "64180"
          ]
        },
        {
          "id": 640801,
          "name": "ศรีนคร",
          "postCode": [
            "64180"
          ]
        },
        {
          "id": 640805,
          "name": "หนองบัว",
          "postCode": [
            "64180"
          ]
        }
      ]
    },
    {
      "city": "ศรีสัชนาลัย",
      "cityCode": 6405,
      "provinceCode": 64,
      "subDistrict": [
        {
          "id": 640509,
          "name": "ดงคู่",
          "postCode": [
            "64130"
          ]
        },
        {
          "id": 640507,
          "name": "ท่าชัย",
          "postCode": [
            "64190"
          ]
        },
        {
          "id": 640505,
          "name": "บ้านตึก",
          "postCode": [
            "64130"
          ]
        },
        {
          "id": 640510,
          "name": "บ้านแก่ง",
          "postCode": [
            "64130"
          ]
        },
        {
          "id": 640502,
          "name": "ป่างิ้ว",
          "postCode": [
            "64130"
          ]
        },
        {
          "id": 640508,
          "name": "ศรีสัชนาลัย",
          "postCode": [
            "64190"
          ]
        },
        {
          "id": 640511,
          "name": "สารจิตร",
          "postCode": [
            "64130"
          ]
        },
        {
          "id": 640506,
          "name": "หนองอ้อ",
          "postCode": [
            "64130"
          ]
        },
        {
          "id": 640501,
          "name": "หาดเสี้ยว",
          "postCode": [
            "64130"
          ]
        },
        {
          "id": 640503,
          "name": "แม่สำ",
          "postCode": [
            "64130"
          ]
        },
        {
          "id": 640504,
          "name": "แม่สิน",
          "postCode": [
            "64130"
          ]
        }
      ]
    },
    {
      "city": "ศรีสำโรง",
      "cityCode": 6406,
      "provinceCode": 64,
      "subDistrict": [
        {
          "id": 640601,
          "name": "คลองตาล",
          "postCode": [
            "64120"
          ]
        },
        {
          "id": 640610,
          "name": "ทับผึ้ง",
          "postCode": [
            "64120"
          ]
        },
        {
          "id": 640606,
          "name": "นาขุนไกร",
          "postCode": [
            "64120"
          ]
        },
        {
          "id": 640611,
          "name": "บ้านซ่าน",
          "postCode": [
            "64120"
          ]
        },
        {
          "id": 640604,
          "name": "บ้านนา",
          "postCode": [
            "64120"
          ]
        },
        {
          "id": 640609,
          "name": "บ้านไร่",
          "postCode": [
            "64120"
          ]
        },
        {
          "id": 640613,
          "name": "ราวต้นจันทร์",
          "postCode": [
            "64120"
          ]
        },
        {
          "id": 640605,
          "name": "วังทอง",
          "postCode": [
            "64120"
          ]
        },
        {
          "id": 640602,
          "name": "วังลึก",
          "postCode": [
            "64120"
          ]
        },
        {
          "id": 640612,
          "name": "วังใหญ่",
          "postCode": [
            "64120"
          ]
        },
        {
          "id": 640608,
          "name": "วัดเกาะ",
          "postCode": [
            "64120"
          ]
        },
        {
          "id": 640603,
          "name": "สามเรือน",
          "postCode": [
            "64120"
          ]
        },
        {
          "id": 640607,
          "name": "เกาะตาเลี้ยง",
          "postCode": [
            "64120"
          ]
        }
      ]
    },
    {
      "city": "สวรรคโลก",
      "cityCode": 6407,
      "provinceCode": 64,
      "subDistrict": [
        {
          "id": 640703,
          "name": "คลองกระจง",
          "postCode": [
            "64110"
          ]
        },
        {
          "id": 640708,
          "name": "คลองยาง",
          "postCode": [
            "64110"
          ]
        },
        {
          "id": 640710,
          "name": "ท่าทอง",
          "postCode": [
            "64110"
          ]
        },
        {
          "id": 640707,
          "name": "นาทุ่ง",
          "postCode": [
            "64110"
          ]
        },
        {
          "id": 640711,
          "name": "ปากน้ำ",
          "postCode": [
            "64110"
          ]
        },
        {
          "id": 640712,
          "name": "ป่ากุมเกาะ",
          "postCode": [
            "64110"
          ]
        },
        {
          "id": 640706,
          "name": "ย่านยาว",
          "postCode": [
            "64110"
          ]
        },
        {
          "id": 640704,
          "name": "วังพิณพาทย์",
          "postCode": [
            "64110"
          ]
        },
        {
          "id": 640705,
          "name": "วังไม้ขอน",
          "postCode": [
            "64110"
          ]
        },
        {
          "id": 640714,
          "name": "หนองกลับ",
          "postCode": [
            "64110"
          ]
        },
        {
          "id": 640713,
          "name": "เมืองบางขลัง",
          "postCode": [
            "64110"
          ]
        },
        {
          "id": 640709,
          "name": "เมืองบางยม",
          "postCode": [
            "64110"
          ]
        },
        {
          "id": 640701,
          "name": "เมืองสวรรคโลก",
          "postCode": [
            "64110"
          ]
        },
        {
          "id": 640702,
          "name": "ในเมือง",
          "postCode": [
            "64110"
          ]
        }
      ]
    },
    {
      "city": "เมืองสุโขทัย",
      "cityCode": 6401,
      "provinceCode": 64,
      "subDistrict": [
        {
          "id": 640108,
          "name": "ตาลเตี้ย",
          "postCode": [
            "64220"
          ]
        },
        {
          "id": 640101,
          "name": "ธานี",
          "postCode": [
            "64000"
          ]
        },
        {
          "id": 640106,
          "name": "บ้านกล้วย",
          "postCode": [
            "64000",
            "64210"
          ]
        },
        {
          "id": 640102,
          "name": "บ้านสวน",
          "postCode": [
            "64220"
          ]
        },
        {
          "id": 640107,
          "name": "บ้านหลุม",
          "postCode": [
            "64000"
          ]
        },
        {
          "id": 640109,
          "name": "ปากพระ",
          "postCode": [
            "64000"
          ]
        },
        {
          "id": 640104,
          "name": "ปากแคว",
          "postCode": [
            "64000"
          ]
        },
        {
          "id": 640105,
          "name": "ยางซ้าย",
          "postCode": [
            "64000"
          ]
        },
        {
          "id": 640110,
          "name": "วังทองแดง",
          "postCode": [
            "64210"
          ]
        },
        {
          "id": 640103,
          "name": "เมืองเก่า",
          "postCode": [
            "64210"
          ]
        }
      ]
    },
    {
      "city": "ท่าบ่อ",
      "cityCode": 4302,
      "provinceCode": 43,
      "subDistrict": [
        {
          "id": 430203,
          "name": "กองนาง",
          "postCode": [
            "43110"
          ]
        },
        {
          "id": 430201,
          "name": "ท่าบ่อ",
          "postCode": [
            "43110"
          ]
        },
        {
          "id": 430208,
          "name": "นาข่า",
          "postCode": [
            "43110"
          ]
        },
        {
          "id": 430202,
          "name": "น้ำโมง",
          "postCode": [
            "43110"
          ]
        },
        {
          "id": 430206,
          "name": "บ้านถ่อน",
          "postCode": [
            "43110"
          ]
        },
        {
          "id": 430207,
          "name": "บ้านว่าน",
          "postCode": [
            "43110"
          ]
        },
        {
          "id": 430205,
          "name": "บ้านเดื่อ",
          "postCode": [
            "43110"
          ]
        },
        {
          "id": 430210,
          "name": "หนองนาง",
          "postCode": [
            "43110"
          ]
        },
        {
          "id": 430204,
          "name": "โคกคอน",
          "postCode": [
            "43110"
          ]
        },
        {
          "id": 430209,
          "name": "โพนสา",
          "postCode": [
            "43110"
          ]
        }
      ]
    },
    {
      "city": "รัตนวาปี",
      "cityCode": 4316,
      "provinceCode": 43,
      "subDistrict": [
        {
          "id": 431602,
          "name": "นาทับไฮ",
          "postCode": [
            "43120"
          ]
        },
        {
          "id": 431603,
          "name": "บ้านต้อน",
          "postCode": [
            "43120"
          ]
        },
        {
          "id": 431604,
          "name": "พระบาทนาสิงห์",
          "postCode": [
            "43120"
          ]
        },
        {
          "id": 431601,
          "name": "รัตนวาปี",
          "postCode": [
            "43120"
          ]
        },
        {
          "id": 431605,
          "name": "โพนแพง",
          "postCode": [
            "43120"
          ]
        }
      ]
    },
    {
      "city": "ศรีเชียงใหม่",
      "cityCode": 4307,
      "provinceCode": 43,
      "subDistrict": [
        {
          "id": 430703,
          "name": "บ้านหม้อ",
          "postCode": [
            "43130"
          ]
        },
        {
          "id": 430704,
          "name": "พระพุทธบาท",
          "postCode": [
            "43130"
          ]
        },
        {
          "id": 430701,
          "name": "พานพร้าว",
          "postCode": [
            "43130"
          ]
        },
        {
          "id": 430705,
          "name": "หนองปลาปาก",
          "postCode": [
            "43130"
          ]
        }
      ]
    },
    {
      "city": "สระใคร",
      "cityCode": 4314,
      "provinceCode": 43,
      "subDistrict": [
        {
          "id": 431402,
          "name": "คอกช้าง",
          "postCode": [
            "43100"
          ]
        },
        {
          "id": 431403,
          "name": "บ้านฝาง",
          "postCode": [
            "43100"
          ]
        },
        {
          "id": 431401,
          "name": "สระใคร",
          "postCode": [
            "43100"
          ]
        }
      ]
    },
    {
      "city": "สังคม",
      "cityCode": 4308,
      "provinceCode": 43,
      "subDistrict": [
        {
          "id": 430804,
          "name": "นางิ้ว",
          "postCode": [
            "43160"
          ]
        },
        {
          "id": 430803,
          "name": "บ้านม่วง",
          "postCode": [
            "43160"
          ]
        },
        {
          "id": 430802,
          "name": "ผาตั้ง",
          "postCode": [
            "43160"
          ]
        },
        {
          "id": 430805,
          "name": "สังคม",
          "postCode": [
            "43160"
          ]
        },
        {
          "id": 430801,
          "name": "แก้งไก่",
          "postCode": [
            "43160"
          ]
        }
      ]
    },
    {
      "city": "เฝ้าไร่",
      "cityCode": 4315,
      "provinceCode": 43,
      "subDistrict": [
        {
          "id": 431502,
          "name": "นาดี",
          "postCode": [
            "43120"
          ]
        },
        {
          "id": 431504,
          "name": "วังหลวง",
          "postCode": [
            "43120"
          ]
        },
        {
          "id": 431503,
          "name": "หนองหลวง",
          "postCode": [
            "43120"
          ]
        },
        {
          "id": 431505,
          "name": "อุดมพร",
          "postCode": [
            "43120"
          ]
        },
        {
          "id": 431501,
          "name": "เฝ้าไร่",
          "postCode": [
            "43120"
          ]
        }
      ]
    },
    {
      "city": "เมืองหนองคาย",
      "cityCode": 4301,
      "provinceCode": 43,
      "subDistrict": [
        {
          "id": 430104,
          "name": "กวนวัน",
          "postCode": [
            "43000"
          ]
        },
        {
          "id": 430110,
          "name": "ค่ายบกหวาน",
          "postCode": [
            "43100"
          ]
        },
        {
          "id": 430109,
          "name": "บ้านเดื่อ",
          "postCode": [
            "43000"
          ]
        },
        {
          "id": 430117,
          "name": "ปะโค",
          "postCode": [
            "43000"
          ]
        },
        {
          "id": 430113,
          "name": "พระธาตุบังพวน",
          "postCode": [
            "43100"
          ]
        },
        {
          "id": 430102,
          "name": "มีชัย",
          "postCode": [
            "43000"
          ]
        },
        {
          "id": 430106,
          "name": "วัดธาตุ",
          "postCode": [
            "43000"
          ]
        },
        {
          "id": 430119,
          "name": "สีกาย",
          "postCode": [
            "43000"
          ]
        },
        {
          "id": 430116,
          "name": "หนองกอมเกาะ",
          "postCode": [
            "43000"
          ]
        },
        {
          "id": 430107,
          "name": "หาดคำ",
          "postCode": [
            "43000"
          ]
        },
        {
          "id": 430108,
          "name": "หินโงม",
          "postCode": [
            "43000"
          ]
        },
        {
          "id": 430118,
          "name": "เมืองหมี",
          "postCode": [
            "43000"
          ]
        },
        {
          "id": 430105,
          "name": "เวียงคุก",
          "postCode": [
            "43000"
          ]
        },
        {
          "id": 430103,
          "name": "โพธิ์ชัย",
          "postCode": [
            "43000"
          ]
        },
        {
          "id": 430111,
          "name": "โพนสว่าง",
          "postCode": [
            "43100"
          ]
        },
        {
          "id": 430101,
          "name": "ในเมือง",
          "postCode": [
            "43000"
          ]
        }
      ]
    },
    {
      "city": "โพธิ์ตาก",
      "cityCode": 4317,
      "provinceCode": 43,
      "subDistrict": [
        {
          "id": 431703,
          "name": "ด่านศรีสุข",
          "postCode": [
            "43130"
          ]
        },
        {
          "id": 431701,
          "name": "โพธิ์ตาก",
          "postCode": [
            "43130"
          ]
        },
        {
          "id": 431702,
          "name": "โพนทอง",
          "postCode": [
            "43130"
          ]
        }
      ]
    },
    {
      "city": "โพนพิสัย",
      "cityCode": 4305,
      "provinceCode": 43,
      "subDistrict": [
        {
          "id": 430503,
          "name": "กุดบง",
          "postCode": [
            "43120"
          ]
        },
        {
          "id": 430501,
          "name": "จุมพล",
          "postCode": [
            "43120"
          ]
        },
        {
          "id": 430504,
          "name": "ชุมช้าง",
          "postCode": [
            "43120"
          ]
        },
        {
          "id": 430506,
          "name": "ทุ่งหลวง",
          "postCode": [
            "43120"
          ]
        },
        {
          "id": 430508,
          "name": "นาหนัง",
          "postCode": [
            "43120"
          ]
        },
        {
          "id": 430521,
          "name": "บ้านผือ",
          "postCode": [
            "43120"
          ]
        },
        {
          "id": 430513,
          "name": "บ้านโพธิ์",
          "postCode": [
            "43120"
          ]
        },
        {
          "id": 430502,
          "name": "วัดหลวง",
          "postCode": [
            "43120"
          ]
        },
        {
          "id": 430522,
          "name": "สร้างนางขาว",
          "postCode": [
            "43120"
          ]
        },
        {
          "id": 430509,
          "name": "เซิม",
          "postCode": [
            "43120"
          ]
        },
        {
          "id": 430507,
          "name": "เหล่าต่างคำ",
          "postCode": [
            "43120"
          ]
        }
      ]
    },
    {
      "city": "นากลาง",
      "cityCode": 3902,
      "provinceCode": 39,
      "subDistrict": [
        {
          "id": 390205,
          "name": "กุดดินจี่",
          "postCode": [
            "39350"
          ]
        },
        {
          "id": 390213,
          "name": "กุดแห่",
          "postCode": [
            "39170"
          ]
        },
        {
          "id": 390211,
          "name": "ดงสวรรค์",
          "postCode": [
            "39350"
          ]
        },
        {
          "id": 390202,
          "name": "ด่านช้าง",
          "postCode": [
            "39170"
          ]
        },
        {
          "id": 390201,
          "name": "นากลาง",
          "postCode": [
            "39170"
          ]
        },
        {
          "id": 390206,
          "name": "ฝั่งแดง",
          "postCode": [
            "39170"
          ]
        },
        {
          "id": 390210,
          "name": "อุทัยสวรรค์",
          "postCode": [
            "39170"
          ]
        },
        {
          "id": 390207,
          "name": "เก่ากลอย",
          "postCode": [
            "39350"
          ]
        },
        {
          "id": 390209,
          "name": "โนนเมือง",
          "postCode": [
            "39170"
          ]
        }
      ]
    },
    {
      "city": "นาวัง",
      "cityCode": 3906,
      "provinceCode": 39,
      "subDistrict": [
        {
          "id": 390601,
          "name": "นาเหล่า",
          "postCode": [
            "39170"
          ]
        },
        {
          "id": 390602,
          "name": "นาแก",
          "postCode": [
            "39170"
          ]
        },
        {
          "id": 390603,
          "name": "วังทอง",
          "postCode": [
            "39170"
          ]
        },
        {
          "id": 390604,
          "name": "วังปลาป้อม",
          "postCode": [
            "39170"
          ]
        },
        {
          "id": 390605,
          "name": "เทพคีรี",
          "postCode": [
            "39170"
          ]
        }
      ]
    },
    {
      "city": "ศรีบุญเรือง",
      "cityCode": 3904,
      "provinceCode": 39,
      "subDistrict": [
        {
          "id": 390404,
          "name": "กุดสะเทียน",
          "postCode": [
            "39180"
          ]
        },
        {
          "id": 390411,
          "name": "ทรายทอง",
          "postCode": [
            "39180"
          ]
        },
        {
          "id": 390405,
          "name": "นากอก",
          "postCode": [
            "39180"
          ]
        },
        {
          "id": 390407,
          "name": "ยางหล่อ",
          "postCode": [
            "39180"
          ]
        },
        {
          "id": 390402,
          "name": "ศรีบุญเรือง",
          "postCode": [
            "39180"
          ]
        },
        {
          "id": 390409,
          "name": "หนองกุงแก้ว",
          "postCode": [
            "39180"
          ]
        },
        {
          "id": 390403,
          "name": "หนองบัวใต้",
          "postCode": [
            "39180"
          ]
        },
        {
          "id": 390410,
          "name": "หนองแก",
          "postCode": [
            "39180"
          ]
        },
        {
          "id": 390412,
          "name": "หันนางาม",
          "postCode": [
            "39180"
          ]
        },
        {
          "id": 390401,
          "name": "เมืองใหม่",
          "postCode": [
            "39180"
          ]
        },
        {
          "id": 390408,
          "name": "โนนม่วง",
          "postCode": [
            "39180"
          ]
        },
        {
          "id": 390406,
          "name": "โนนสะอาด",
          "postCode": [
            "39180"
          ]
        }
      ]
    },
    {
      "city": "สุวรรณคูหา",
      "cityCode": 3905,
      "provinceCode": 39,
      "subDistrict": [
        {
          "id": 390508,
          "name": "กุดผึ้ง",
          "postCode": [
            "39270"
          ]
        },
        {
          "id": 390505,
          "name": "ดงมะไฟ",
          "postCode": [
            "39270"
          ]
        },
        {
          "id": 390503,
          "name": "นาดี",
          "postCode": [
            "39270"
          ]
        },
        {
          "id": 390504,
          "name": "นาด่าน",
          "postCode": [
            "39270"
          ]
        },
        {
          "id": 390501,
          "name": "นาสี",
          "postCode": [
            "39270"
          ]
        },
        {
          "id": 390507,
          "name": "บุญทัน",
          "postCode": [
            "39270"
          ]
        },
        {
          "id": 390502,
          "name": "บ้านโคก",
          "postCode": [
            "39270"
          ]
        },
        {
          "id": 390506,
          "name": "สุวรรณคูหา",
          "postCode": [
            "39270"
          ]
        }
      ]
    },
    {
      "city": "เมืองหนองบัวลำภู",
      "cityCode": 3901,
      "provinceCode": 39,
      "subDistrict": [
        {
          "id": 390111,
          "name": "กุดจิก",
          "postCode": [
            "39000"
          ]
        },
        {
          "id": 390113,
          "name": "นาคำไฮ",
          "postCode": [
            "39000"
          ]
        },
        {
          "id": 390107,
          "name": "นามะเฟือง",
          "postCode": [
            "39000"
          ]
        },
        {
          "id": 390106,
          "name": "บ้านขาม",
          "postCode": [
            "39000"
          ]
        },
        {
          "id": 390108,
          "name": "บ้านพร้าว",
          "postCode": [
            "39000"
          ]
        },
        {
          "id": 390114,
          "name": "ป่าไม้งาม",
          "postCode": [
            "39000"
          ]
        },
        {
          "id": 390110,
          "name": "ลำภู",
          "postCode": [
            "39000"
          ]
        },
        {
          "id": 390101,
          "name": "หนองบัว",
          "postCode": [
            "39000"
          ]
        },
        {
          "id": 390102,
          "name": "หนองภัยศูนย์",
          "postCode": [
            "39000"
          ]
        },
        {
          "id": 390104,
          "name": "หนองสวรรค์",
          "postCode": [
            "39000"
          ]
        },
        {
          "id": 390115,
          "name": "หนองหว้า",
          "postCode": [
            "39000"
          ]
        },
        {
          "id": 390105,
          "name": "หัวนา",
          "postCode": [
            "39000"
          ]
        },
        {
          "id": 390109,
          "name": "โนนขมิ้น",
          "postCode": [
            "39000"
          ]
        },
        {
          "id": 390112,
          "name": "โนนทัน",
          "postCode": [
            "39000"
          ]
        },
        {
          "id": 390103,
          "name": "โพธิ์ชัย",
          "postCode": [
            "39000"
          ]
        }
      ]
    },
    {
      "city": "โนนสัง",
      "cityCode": 3903,
      "provinceCode": 39,
      "subDistrict": [
        {
          "id": 390304,
          "name": "กุดดู่",
          "postCode": [
            "39140"
          ]
        },
        {
          "id": 390309,
          "name": "นิคมพัฒนา",
          "postCode": [
            "39140"
          ]
        },
        {
          "id": 390305,
          "name": "บ้านค้อ",
          "postCode": [
            "39140"
          ]
        },
        {
          "id": 390302,
          "name": "บ้านถิ่น",
          "postCode": [
            "39140"
          ]
        },
        {
          "id": 390310,
          "name": "ปางกู่",
          "postCode": [
            "39140"
          ]
        },
        {
          "id": 390303,
          "name": "หนองเรือ",
          "postCode": [
            "39140"
          ]
        },
        {
          "id": 390308,
          "name": "โคกม่วง",
          "postCode": [
            "39140"
          ]
        },
        {
          "id": 390307,
          "name": "โคกใหญ่",
          "postCode": [
            "39140"
          ]
        },
        {
          "id": 390301,
          "name": "โนนสัง",
          "postCode": [
            "39140"
          ]
        },
        {
          "id": 390306,
          "name": "โนนเมือง",
          "postCode": [
            "39140"
          ]
        }
      ]
    },
    {
      "city": "ชานุมาน",
      "cityCode": 3702,
      "provinceCode": 37,
      "subDistrict": [
        {
          "id": 370203,
          "name": "คำเขื่อนแก้ว",
          "postCode": [
            "37210"
          ]
        },
        {
          "id": 370201,
          "name": "ชานุมาน",
          "postCode": [
            "37210"
          ]
        },
        {
          "id": 370205,
          "name": "ป่าก่อ",
          "postCode": [
            "37210"
          ]
        },
        {
          "id": 370204,
          "name": "โคกก่ง",
          "postCode": [
            "37210"
          ]
        },
        {
          "id": 370202,
          "name": "โคกสาร",
          "postCode": [
            "37210"
          ]
        }
      ]
    },
    {
      "city": "ปทุมราชวงศา",
      "cityCode": 3703,
      "provinceCode": 37,
      "subDistrict": [
        {
          "id": 370302,
          "name": "คำโพน",
          "postCode": [
            "37110"
          ]
        },
        {
          "id": 370307,
          "name": "นาป่าแซง",
          "postCode": [
            "37110"
          ]
        },
        {
          "id": 370303,
          "name": "นาหว้า",
          "postCode": [
            "37110"
          ]
        },
        {
          "id": 370304,
          "name": "ลือ",
          "postCode": [
            "37110"
          ]
        },
        {
          "id": 370301,
          "name": "หนองข่า",
          "postCode": [
            "37110"
          ]
        },
        {
          "id": 370305,
          "name": "ห้วย",
          "postCode": [
            "37110"
          ]
        },
        {
          "id": 370306,
          "name": "โนนงาม",
          "postCode": [
            "37110"
          ]
        }
      ]
    },
    {
      "city": "พนา",
      "cityCode": 3704,
      "provinceCode": 37,
      "subDistrict": [
        {
          "id": 370402,
          "name": "จานลาน",
          "postCode": [
            "37180"
          ]
        },
        {
          "id": 370401,
          "name": "พนา",
          "postCode": [
            "37180"
          ]
        },
        {
          "id": 370404,
          "name": "พระเหลา",
          "postCode": [
            "37180"
          ]
        },
        {
          "id": 370403,
          "name": "ไม้กลอน",
          "postCode": [
            "37180"
          ]
        }
      ]
    },
    {
      "city": "ลืออำนาจ",
      "cityCode": 3707,
      "provinceCode": 37,
      "subDistrict": [
        {
          "id": 370704,
          "name": "ดงบัง",
          "postCode": [
            "37120"
          ]
        },
        {
          "id": 370702,
          "name": "ดงมะยาง",
          "postCode": [
            "37120"
          ]
        },
        {
          "id": 370701,
          "name": "อำนาจ",
          "postCode": [
            "37120"
          ]
        },
        {
          "id": 370703,
          "name": "เปือย",
          "postCode": [
            "37120"
          ]
        },
        {
          "id": 370706,
          "name": "แมด",
          "postCode": [
            "37120"
          ]
        },
        {
          "id": 370707,
          "name": "โคกกลาง",
          "postCode": [
            "37120"
          ]
        },
        {
          "id": 370705,
          "name": "ไร่ขี",
          "postCode": [
            "37120"
          ]
        }
      ]
    },
    {
      "city": "หัวตะพาน",
      "cityCode": 3706,
      "provinceCode": 37,
      "subDistrict": [
        {
          "id": 370602,
          "name": "คำพระ",
          "postCode": [
            "37240"
          ]
        },
        {
          "id": 370607,
          "name": "จิกดู่",
          "postCode": [
            "37240"
          ]
        },
        {
          "id": 370608,
          "name": "รัตนวารี",
          "postCode": [
            "37240"
          ]
        },
        {
          "id": 370606,
          "name": "สร้างถ่อน้อย",
          "postCode": [
            "37240"
          ]
        },
        {
          "id": 370604,
          "name": "หนองแก้ว",
          "postCode": [
            "37240"
          ]
        },
        {
          "id": 370601,
          "name": "หัวตะพาน",
          "postCode": [
            "37240"
          ]
        },
        {
          "id": 370603,
          "name": "เค็งใหญ่",
          "postCode": [
            "37240"
          ]
        },
        {
          "id": 370605,
          "name": "โพนเมืองน้อย",
          "postCode": [
            "37240"
          ]
        }
      ]
    },
    {
      "city": "เมืองอำนาจเจริญ",
      "cityCode": 3701,
      "provinceCode": 37,
      "subDistrict": [
        {
          "id": 370116,
          "name": "กุดปลาดุก",
          "postCode": [
            "37000"
          ]
        },
        {
          "id": 370107,
          "name": "คึมใหญ่",
          "postCode": [
            "37000"
          ]
        },
        {
          "id": 370117,
          "name": "ดอนเมย",
          "postCode": [
            "37000"
          ]
        },
        {
          "id": 370103,
          "name": "นาจิก",
          "postCode": [
            "37000"
          ]
        },
        {
          "id": 370108,
          "name": "นาผือ",
          "postCode": [
            "37000"
          ]
        },
        {
          "id": 370118,
          "name": "นายม",
          "postCode": [
            "37000"
          ]
        },
        {
          "id": 370110,
          "name": "นาวัง",
          "postCode": [
            "37000"
          ]
        },
        {
          "id": 370111,
          "name": "นาหมอม้า",
          "postCode": [
            "37000"
          ]
        },
        {
          "id": 370119,
          "name": "นาแต้",
          "postCode": [
            "37000"
          ]
        },
        {
          "id": 370109,
          "name": "น้ำปลีก",
          "postCode": [
            "37000"
          ]
        },
        {
          "id": 370101,
          "name": "บุ่ง",
          "postCode": [
            "37000"
          ]
        },
        {
          "id": 370104,
          "name": "ปลาค้าว",
          "postCode": [
            "37000"
          ]
        },
        {
          "id": 370106,
          "name": "สร้างนกทา",
          "postCode": [
            "37000"
          ]
        },
        {
          "id": 370115,
          "name": "หนองมะแซว",
          "postCode": [
            "37000"
          ]
        },
        {
          "id": 370114,
          "name": "ห้วยไร่",
          "postCode": [
            "37000"
          ]
        },
        {
          "id": 370105,
          "name": "เหล่าพรวน",
          "postCode": [
            "37000"
          ]
        },
        {
          "id": 370113,
          "name": "โนนหนามแท่ง",
          "postCode": [
            "37000"
          ]
        },
        {
          "id": 370112,
          "name": "โนนโพธิ์",
          "postCode": [
            "37000"
          ]
        },
        {
          "id": 370102,
          "name": "ไก่คำ",
          "postCode": [
            "37000"
          ]
        }
      ]
    },
    {
      "city": "เสนางคนิคม",
      "cityCode": 3705,
      "provinceCode": 37,
      "subDistrict": [
        {
          "id": 370504,
          "name": "นาเวียง",
          "postCode": [
            "37290"
          ]
        },
        {
          "id": 370506,
          "name": "หนองสามสี",
          "postCode": [
            "37290"
          ]
        },
        {
          "id": 370505,
          "name": "หนองไฮ",
          "postCode": [
            "37290"
          ]
        },
        {
          "id": 370501,
          "name": "เสนางคนิคม",
          "postCode": [
            "37290"
          ]
        },
        {
          "id": 370502,
          "name": "โพนทอง",
          "postCode": [
            "37290"
          ]
        },
        {
          "id": 370503,
          "name": "ไร่สีสุก",
          "postCode": [
            "37290"
          ]
        }
      ]
    },
    {
      "city": "กุดจับ",
      "cityCode": 4102,
      "provinceCode": 41,
      "subDistrict": [
        {
          "id": 410201,
          "name": "กุดจับ",
          "postCode": [
            "41250"
          ]
        },
        {
          "id": 410203,
          "name": "ขอนยูง",
          "postCode": [
            "41250"
          ]
        },
        {
          "id": 410207,
          "name": "ตาลเลียน",
          "postCode": [
            "41250"
          ]
        },
        {
          "id": 410202,
          "name": "ปะโค",
          "postCode": [
            "41250"
          ]
        },
        {
          "id": 410205,
          "name": "สร้างก่อ",
          "postCode": [
            "41250"
          ]
        },
        {
          "id": 410204,
          "name": "เชียงเพ็ง",
          "postCode": [
            "41250"
          ]
        },
        {
          "id": 410206,
          "name": "เมืองเพีย",
          "postCode": [
            "41250"
          ]
        }
      ]
    },
    {
      "city": "กุมภวาปี",
      "cityCode": 4104,
      "provinceCode": 41,
      "subDistrict": [
        {
          "id": 410415,
          "name": "กุมภวาปี",
          "postCode": [
            "41110"
          ]
        },
        {
          "id": 410401,
          "name": "ตูมใต้",
          "postCode": [
            "41110"
          ]
        },
        {
          "id": 410414,
          "name": "ท่าลี่",
          "postCode": [
            "41110"
          ]
        },
        {
          "id": 410411,
          "name": "ปะโค",
          "postCode": [
            "41370"
          ]
        },
        {
          "id": 410413,
          "name": "ผาสุก",
          "postCode": [
            "41370"
          ]
        },
        {
          "id": 410402,
          "name": "พันดอน",
          "postCode": [
            "41370"
          ]
        },
        {
          "id": 410410,
          "name": "สีออ",
          "postCode": [
            "41110"
          ]
        },
        {
          "id": 410416,
          "name": "หนองหว้า",
          "postCode": [
            "41110"
          ]
        },
        {
          "id": 410407,
          "name": "ห้วยเกิ้ง",
          "postCode": [
            "41110"
          ]
        },
        {
          "id": 410406,
          "name": "เชียงแหว",
          "postCode": [
            "41110"
          ]
        },
        {
          "id": 410403,
          "name": "เวียงคำ",
          "postCode": [
            "41110"
          ]
        },
        {
          "id": 410409,
          "name": "เสอเพลอ",
          "postCode": [
            "41370"
          ]
        },
        {
          "id": 410404,
          "name": "แชแล",
          "postCode": [
            "41110"
          ]
        }
      ]
    },
    {
      "city": "กู่แก้ว",
      "cityCode": 4124,
      "provinceCode": 41,
      "subDistrict": [
        {
          "id": 412404,
          "name": "คอนสาย",
          "postCode": [
            "41130"
          ]
        },
        {
          "id": 412403,
          "name": "ค้อใหญ่",
          "postCode": [
            "41130"
          ]
        },
        {
          "id": 412401,
          "name": "บ้านจีต",
          "postCode": [
            "41130"
          ]
        },
        {
          "id": 412402,
          "name": "โนนทองอินทร์",
          "postCode": [
            "41130"
          ]
        }
      ]
    },
    {
      "city": "ทุ่งฝน",
      "cityCode": 4107,
      "provinceCode": 41,
      "subDistrict": [
        {
          "id": 410701,
          "name": "ทุ่งฝน",
          "postCode": [
            "41310"
          ]
        },
        {
          "id": 410702,
          "name": "ทุ่งใหญ่",
          "postCode": [
            "41310"
          ]
        },
        {
          "id": 410703,
          "name": "นาชุมแสง",
          "postCode": [
            "41310"
          ]
        },
        {
          "id": 410704,
          "name": "นาทม",
          "postCode": [
            "41310"
          ]
        }
      ]
    },
    {
      "city": "นายูง",
      "cityCode": 4122,
      "provinceCode": 41,
      "subDistrict": [
        {
          "id": 412201,
          "name": "นายูง",
          "postCode": [
            "41380"
          ]
        },
        {
          "id": 412203,
          "name": "นาแค",
          "postCode": [
            "41380"
          ]
        },
        {
          "id": 412202,
          "name": "บ้านก้อง",
          "postCode": [
            "41380"
          ]
        },
        {
          "id": 412204,
          "name": "โนนทอง",
          "postCode": [
            "41380"
          ]
        }
      ]
    },
    {
      "city": "น้ำโสม",
      "cityCode": 4118,
      "provinceCode": 41,
      "subDistrict": [
        {
          "id": 411801,
          "name": "นางัว",
          "postCode": [
            "41210"
          ]
        },
        {
          "id": 411802,
          "name": "น้ำโสม",
          "postCode": [
            "41210"
          ]
        },
        {
          "id": 411806,
          "name": "บ้านหยวก",
          "postCode": [
            "41210"
          ]
        },
        {
          "id": 411810,
          "name": "ศรีสำราญ",
          "postCode": [
            "41210"
          ]
        },
        {
          "id": 411812,
          "name": "สามัคคี",
          "postCode": [
            "41210"
          ]
        },
        {
          "id": 411805,
          "name": "หนองแวง",
          "postCode": [
            "41210"
          ]
        },
        {
          "id": 411807,
          "name": "โสมเยี่ยม",
          "postCode": [
            "41210"
          ]
        }
      ]
    },
    {
      "city": "บ้านดุง",
      "cityCode": 4111,
      "provinceCode": 41,
      "subDistrict": [
        {
          "id": 411103,
          "name": "ดงเย็น",
          "postCode": [
            "41190"
          ]
        },
        {
          "id": 411109,
          "name": "ถ่อนนาลับ",
          "postCode": [
            "41190"
          ]
        },
        {
          "id": 411113,
          "name": "นาคำ",
          "postCode": [
            "41190"
          ]
        },
        {
          "id": 411108,
          "name": "นาไหม",
          "postCode": [
            "41190"
          ]
        },
        {
          "id": 411106,
          "name": "บ้านจันทน์",
          "postCode": [
            "41190"
          ]
        },
        {
          "id": 411107,
          "name": "บ้านชัย",
          "postCode": [
            "41190"
          ]
        },
        {
          "id": 411102,
          "name": "บ้านดุง",
          "postCode": [
            "41190"
          ]
        },
        {
          "id": 411112,
          "name": "บ้านตาด",
          "postCode": [
            "41190"
          ]
        },
        {
          "id": 411111,
          "name": "บ้านม่วง",
          "postCode": [
            "41190"
          ]
        },
        {
          "id": 411110,
          "name": "วังทอง",
          "postCode": [
            "41190"
          ]
        },
        {
          "id": 411101,
          "name": "ศรีสุทโธ",
          "postCode": [
            "41190"
          ]
        },
        {
          "id": 411105,
          "name": "อ้อมกอ",
          "postCode": [
            "41190"
          ]
        },
        {
          "id": 411104,
          "name": "โพนสูง",
          "postCode": [
            "41190"
          ]
        }
      ]
    },
    {
      "city": "บ้านผือ",
      "cityCode": 4117,
      "provinceCode": 41,
      "subDistrict": [
        {
          "id": 411708,
          "name": "กลางใหญ่",
          "postCode": [
            "41160"
          ]
        },
        {
          "id": 411706,
          "name": "ข้าวสาร",
          "postCode": [
            "41160"
          ]
        },
        {
          "id": 411710,
          "name": "คำด้วง",
          "postCode": [
            "41160"
          ]
        },
        {
          "id": 411704,
          "name": "คำบง",
          "postCode": [
            "41160"
          ]
        },
        {
          "id": 411707,
          "name": "จำปาโมง",
          "postCode": [
            "41160"
          ]
        },
        {
          "id": 411712,
          "name": "บ้านค้อ",
          "postCode": [
            "41160"
          ]
        },
        {
          "id": 411701,
          "name": "บ้านผือ",
          "postCode": [
            "41160"
          ]
        },
        {
          "id": 411711,
          "name": "หนองหัวคู",
          "postCode": [
            "41160"
          ]
        },
        {
          "id": 411713,
          "name": "หนองแวง",
          "postCode": [
            "41160"
          ]
        },
        {
          "id": 411702,
          "name": "หายโศก",
          "postCode": [
            "41160"
          ]
        },
        {
          "id": 411703,
          "name": "เขือน้ำ",
          "postCode": [
            "41160"
          ]
        },
        {
          "id": 411709,
          "name": "เมืองพาน",
          "postCode": [
            "41160"
          ]
        },
        {
          "id": 411705,
          "name": "โนนทอง",
          "postCode": [
            "41160"
          ]
        }
      ]
    },
    {
      "city": "ประจักษ์ศิลปาคม",
      "cityCode": 4125,
      "provinceCode": 41,
      "subDistrict": [
        {
          "id": 412501,
          "name": "นาม่วง",
          "postCode": [
            "41110"
          ]
        },
        {
          "id": 412502,
          "name": "ห้วยสามพาด",
          "postCode": [
            "41110"
          ]
        },
        {
          "id": 412503,
          "name": "อุ่มจาน",
          "postCode": [
            "41110"
          ]
        }
      ]
    },
    {
      "city": "พิบูลย์รักษ์",
      "cityCode": 4123,
      "provinceCode": 41,
      "subDistrict": [
        {
          "id": 412303,
          "name": "ดอนกลอย",
          "postCode": [
            "41130"
          ]
        },
        {
          "id": 412302,
          "name": "นาทราย",
          "postCode": [
            "41130"
          ]
        },
        {
          "id": 412301,
          "name": "บ้านแดง",
          "postCode": [
            "41130"
          ]
        }
      ]
    },
    {
      "city": "วังสามหมอ",
      "cityCode": 4110,
      "provinceCode": 41,
      "subDistrict": [
        {
          "id": 411005,
          "name": "คำโคกสูง",
          "postCode": [
            "41280"
          ]
        },
        {
          "id": 411003,
          "name": "บะยาว",
          "postCode": [
            "41280"
          ]
        },
        {
          "id": 411004,
          "name": "ผาสุก",
          "postCode": [
            "41280"
          ]
        },
        {
          "id": 411006,
          "name": "วังสามหมอ",
          "postCode": [
            "41280"
          ]
        },
        {
          "id": 411001,
          "name": "หนองกุงทับม้า",
          "postCode": [
            "41280"
          ]
        },
        {
          "id": 411002,
          "name": "หนองหญ้าไซ",
          "postCode": [
            "41280"
          ]
        }
      ]
    },
    {
      "city": "ศรีธาตุ",
      "cityCode": 4109,
      "provinceCode": 41,
      "subDistrict": [
        {
          "id": 410902,
          "name": "จำปี",
          "postCode": [
            "41230"
          ]
        },
        {
          "id": 410907,
          "name": "ตาดทอง",
          "postCode": [
            "41230"
          ]
        },
        {
          "id": 410906,
          "name": "นายูง",
          "postCode": [
            "41230"
          ]
        },
        {
          "id": 410903,
          "name": "บ้านโปร่ง",
          "postCode": [
            "41230"
          ]
        },
        {
          "id": 410901,
          "name": "ศรีธาตุ",
          "postCode": [
            "41230"
          ]
        },
        {
          "id": 410905,
          "name": "หนองนกเขียน",
          "postCode": [
            "41230"
          ]
        },
        {
          "id": 410904,
          "name": "หัวนาคำ",
          "postCode": [
            "41230"
          ]
        }
      ]
    },
    {
      "city": "สร้างคอม",
      "cityCode": 4120,
      "provinceCode": 41,
      "subDistrict": [
        {
          "id": 412005,
          "name": "นาสะอาด",
          "postCode": [
            "41260"
          ]
        },
        {
          "id": 412003,
          "name": "บ้านยวด",
          "postCode": [
            "41260"
          ]
        },
        {
          "id": 412006,
          "name": "บ้านหินโงม",
          "postCode": [
            "41260"
          ]
        },
        {
          "id": 412004,
          "name": "บ้านโคก",
          "postCode": [
            "41260"
          ]
        },
        {
          "id": 412001,
          "name": "สร้างคอม",
          "postCode": [
            "41260"
          ]
        },
        {
          "id": 412002,
          "name": "เชียงดา",
          "postCode": [
            "41260"
          ]
        }
      ]
    },
    {
      "city": "หนองวัวซอ",
      "cityCode": 4103,
      "provinceCode": 41,
      "subDistrict": [
        {
          "id": 410304,
          "name": "กุดหมากไฟ",
          "postCode": [
            "41220"
          ]
        },
        {
          "id": 410305,
          "name": "น้ำพ่น",
          "postCode": [
            "41360"
          ]
        },
        {
          "id": 410306,
          "name": "หนองบัวบาน",
          "postCode": [
            "41360"
          ]
        },
        {
          "id": 410308,
          "name": "หนองวัวซอ",
          "postCode": [
            "41360"
          ]
        },
        {
          "id": 410302,
          "name": "หนองอ้อ",
          "postCode": [
            "41220"
          ]
        },
        {
          "id": 410301,
          "name": "หมากหญ้า",
          "postCode": [
            "41360"
          ]
        },
        {
          "id": 410303,
          "name": "อูบมุง",
          "postCode": [
            "41220"
          ]
        },
        {
          "id": 410307,
          "name": "โนนหวาย",
          "postCode": [
            "41220"
          ]
        }
      ]
    },
    {
      "city": "หนองหาน",
      "cityCode": 4106,
      "provinceCode": 41,
      "subDistrict": [
        {
          "id": 410617,
          "name": "ดอนหายโศก",
          "postCode": [
            "41130"
          ]
        },
        {
          "id": 410610,
          "name": "บ้านยา",
          "postCode": [
            "41320"
          ]
        },
        {
          "id": 410609,
          "name": "บ้านเชียง",
          "postCode": [
            "41320"
          ]
        },
        {
          "id": 410612,
          "name": "ผักตบ",
          "postCode": [
            "41130"
          ]
        },
        {
          "id": 410605,
          "name": "พังงู",
          "postCode": [
            "41130"
          ]
        },
        {
          "id": 410607,
          "name": "สร้อยพร้าว",
          "postCode": [
            "41130"
          ]
        },
        {
          "id": 410606,
          "name": "สะแบง",
          "postCode": [
            "41130"
          ]
        },
        {
          "id": 410618,
          "name": "หนองสระปลา",
          "postCode": [
            "41320"
          ]
        },
        {
          "id": 410601,
          "name": "หนองหาน",
          "postCode": [
            "41130"
          ]
        },
        {
          "id": 410602,
          "name": "หนองเม็ก",
          "postCode": [
            "41130"
          ]
        },
        {
          "id": 410614,
          "name": "หนองไผ่",
          "postCode": [
            "41130"
          ]
        },
        {
          "id": 410611,
          "name": "โพนงาม",
          "postCode": [
            "41130"
          ]
        }
      ]
    },
    {
      "city": "หนองแสง",
      "cityCode": 4121,
      "provinceCode": 41,
      "subDistrict": [
        {
          "id": 412104,
          "name": "ทับกุง",
          "postCode": [
            "41340"
          ]
        },
        {
          "id": 412103,
          "name": "นาดี",
          "postCode": [
            "41340"
          ]
        },
        {
          "id": 412101,
          "name": "หนองแสง",
          "postCode": [
            "41340"
          ]
        },
        {
          "id": 412102,
          "name": "แสงสว่าง",
          "postCode": [
            "41340"
          ]
        }
      ]
    },
    {
      "city": "เพ็ญ",
      "cityCode": 4119,
      "provinceCode": 41,
      "subDistrict": [
        {
          "id": 411908,
          "name": "จอมศรี",
          "postCode": [
            "41150"
          ]
        },
        {
          "id": 411906,
          "name": "นาบัว",
          "postCode": [
            "41150"
          ]
        },
        {
          "id": 411903,
          "name": "นาพู่",
          "postCode": [
            "41150"
          ]
        },
        {
          "id": 411902,
          "name": "บ้านธาตุ",
          "postCode": [
            "41150"
          ]
        },
        {
          "id": 411907,
          "name": "บ้านเหล่า",
          "postCode": [
            "41150"
          ]
        },
        {
          "id": 411911,
          "name": "สร้างแป้น",
          "postCode": [
            "41150"
          ]
        },
        {
          "id": 411905,
          "name": "สุมเส้า",
          "postCode": [
            "41150"
          ]
        },
        {
          "id": 411904,
          "name": "เชียงหวาง",
          "postCode": [
            "41150"
          ]
        },
        {
          "id": 411909,
          "name": "เตาไห",
          "postCode": [
            "41150"
          ]
        },
        {
          "id": 411901,
          "name": "เพ็ญ",
          "postCode": [
            "41150"
          ]
        },
        {
          "id": 411910,
          "name": "โคกกลาง",
          "postCode": [
            "41150"
          ]
        }
      ]
    },
    {
      "city": "เมืองอุดรธานี",
      "cityCode": 4101,
      "provinceCode": 41,
      "subDistrict": [
        {
          "id": 410110,
          "name": "กุดสระ",
          "postCode": [
            "41000"
          ]
        },
        {
          "id": 410120,
          "name": "นากว้าง",
          "postCode": [
            "41000"
          ]
        },
        {
          "id": 410116,
          "name": "นาข่า",
          "postCode": [
            "41000"
          ]
        },
        {
          "id": 410111,
          "name": "นาดี",
          "postCode": [
            "41000"
          ]
        },
        {
          "id": 410102,
          "name": "นิคมสงเคราะห์",
          "postCode": [
            "41000"
          ]
        },
        {
          "id": 410103,
          "name": "บ้านขาว",
          "postCode": [
            "41000"
          ]
        },
        {
          "id": 410117,
          "name": "บ้านจั่น",
          "postCode": [
            "41000"
          ]
        },
        {
          "id": 410105,
          "name": "บ้านตาด",
          "postCode": [
            "41000"
          ]
        },
        {
          "id": 410112,
          "name": "บ้านเลื่อม",
          "postCode": [
            "41000"
          ]
        },
        {
          "id": 410114,
          "name": "สามพร้าว",
          "postCode": [
            "41000"
          ]
        },
        {
          "id": 410118,
          "name": "หนองขอนกว้าง",
          "postCode": [
            "41000"
          ]
        },
        {
          "id": 410109,
          "name": "หนองนาคำ",
          "postCode": [
            "41000"
          ]
        },
        {
          "id": 410104,
          "name": "หนองบัว",
          "postCode": [
            "41000"
          ]
        },
        {
          "id": 410121,
          "name": "หนองไผ่",
          "postCode": [
            "41330"
          ]
        },
        {
          "id": 410115,
          "name": "หนองไฮ",
          "postCode": [
            "41000"
          ]
        },
        {
          "id": 410101,
          "name": "หมากแข้ง",
          "postCode": [
            "41000"
          ]
        },
        {
          "id": 410107,
          "name": "หมูม่น",
          "postCode": [
            "41000"
          ]
        },
        {
          "id": 410113,
          "name": "เชียงพิณ",
          "postCode": [
            "41000"
          ]
        },
        {
          "id": 410108,
          "name": "เชียงยืน",
          "postCode": [
            "41000"
          ]
        },
        {
          "id": 410119,
          "name": "โคกสะอาด",
          "postCode": [
            "41000"
          ]
        },
        {
          "id": 410106,
          "name": "โนนสูง",
          "postCode": [
            "41330"
          ]
        }
      ]
    },
    {
      "city": "โนนสะอาด",
      "cityCode": 4105,
      "provinceCode": 41,
      "subDistrict": [
        {
          "id": 410504,
          "name": "ทมนางาม",
          "postCode": [
            "41240"
          ]
        },
        {
          "id": 410502,
          "name": "บุ่งแก้ว",
          "postCode": [
            "41240"
          ]
        },
        {
          "id": 410505,
          "name": "หนองกุงศรี",
          "postCode": [
            "41240"
          ]
        },
        {
          "id": 410506,
          "name": "โคกกลาง",
          "postCode": [
            "41240"
          ]
        },
        {
          "id": 410501,
          "name": "โนนสะอาด",
          "postCode": [
            "41240"
          ]
        },
        {
          "id": 410503,
          "name": "โพธิ์ศรีสำราญ",
          "postCode": [
            "41240"
          ]
        }
      ]
    },
    {
      "city": "ไชยวาน",
      "cityCode": 4108,
      "provinceCode": 41,
      "subDistrict": [
        {
          "id": 410803,
          "name": "คำเลาะ",
          "postCode": [
            "41290"
          ]
        },
        {
          "id": 410802,
          "name": "หนองหลัก",
          "postCode": [
            "41290"
          ]
        },
        {
          "id": 410804,
          "name": "โพนสูง",
          "postCode": [
            "41290"
          ]
        },
        {
          "id": 410801,
          "name": "ไชยวาน",
          "postCode": [
            "41290"
          ]
        }
      ]
    },
    {
      "city": "ตรอน",
      "cityCode": 5302,
      "provinceCode": 53,
      "subDistrict": [
        {
          "id": 530205,
          "name": "ข่อยสูง",
          "postCode": [
            "53140"
          ]
        },
        {
          "id": 530204,
          "name": "น้ำอ่าง",
          "postCode": [
            "53140"
          ]
        },
        {
          "id": 530202,
          "name": "บ้านแก่ง",
          "postCode": [
            "53140"
          ]
        },
        {
          "id": 530201,
          "name": "วังแดง",
          "postCode": [
            "53140"
          ]
        },
        {
          "id": 530203,
          "name": "หาดสองแคว",
          "postCode": [
            "53140"
          ]
        }
      ]
    },
    {
      "city": "ทองแสนขัน",
      "cityCode": 5309,
      "provinceCode": 53,
      "subDistrict": [
        {
          "id": 530904,
          "name": "น้ำพี้",
          "postCode": [
            "53230"
          ]
        },
        {
          "id": 530902,
          "name": "บ่อทอง",
          "postCode": [
            "53230"
          ]
        },
        {
          "id": 530903,
          "name": "ป่าคาย",
          "postCode": [
            "53230"
          ]
        },
        {
          "id": 530901,
          "name": "ผักขวง",
          "postCode": [
            "53230"
          ]
        }
      ]
    },
    {
      "city": "ท่าปลา",
      "cityCode": 5303,
      "provinceCode": 53,
      "subDistrict": [
        {
          "id": 530304,
          "name": "จริม",
          "postCode": [
            "53150"
          ]
        },
        {
          "id": 530301,
          "name": "ท่าปลา",
          "postCode": [
            "53150"
          ]
        },
        {
          "id": 530307,
          "name": "นางพญา",
          "postCode": [
            "53150"
          ]
        },
        {
          "id": 530305,
          "name": "น้ำหมัน",
          "postCode": [
            "53150"
          ]
        },
        {
          "id": 530303,
          "name": "ผาเลือด",
          "postCode": [
            "53190"
          ]
        },
        {
          "id": 530308,
          "name": "ร่วมจิต",
          "postCode": [
            "53190"
          ]
        },
        {
          "id": 530302,
          "name": "หาดล้า",
          "postCode": [
            "53150",
            "53190"
          ]
        }
      ]
    },
    {
      "city": "น้ำปาด",
      "cityCode": 5304,
      "provinceCode": 53,
      "subDistrict": [
        {
          "id": 530407,
          "name": "ท่าแฝก",
          "postCode": [
            "53110"
          ]
        },
        {
          "id": 530404,
          "name": "น้ำไคร้",
          "postCode": [
            "53110"
          ]
        },
        {
          "id": 530405,
          "name": "น้ำไผ่",
          "postCode": [
            "53110"
          ]
        },
        {
          "id": 530402,
          "name": "บ้านฝาย",
          "postCode": [
            "53110"
          ]
        },
        {
          "id": 530406,
          "name": "ห้วยมุ่น",
          "postCode": [
            "53110"
          ]
        },
        {
          "id": 530403,
          "name": "เด่นเหล็ก",
          "postCode": [
            "53110"
          ]
        },
        {
          "id": 530401,
          "name": "แสนตอ",
          "postCode": [
            "53110"
          ]
        }
      ]
    },
    {
      "city": "บ้านโคก",
      "cityCode": 5306,
      "provinceCode": 53,
      "subDistrict": [
        {
          "id": 530603,
          "name": "นาขุม",
          "postCode": [
            "53180"
          ]
        },
        {
          "id": 530604,
          "name": "บ่อเบี้ย",
          "postCode": [
            "53180"
          ]
        },
        {
          "id": 530602,
          "name": "บ้านโคก",
          "postCode": [
            "53180"
          ]
        },
        {
          "id": 530601,
          "name": "ม่วงเจ็ดต้น",
          "postCode": [
            "53180"
          ]
        }
      ]
    },
    {
      "city": "พิชัย",
      "cityCode": 5307,
      "provinceCode": 53,
      "subDistrict": [
        {
          "id": 530705,
          "name": "คอรุม",
          "postCode": [
            "53120"
          ]
        },
        {
          "id": 530707,
          "name": "ท่ามะเฟือง",
          "postCode": [
            "53120"
          ]
        },
        {
          "id": 530704,
          "name": "ท่าสัก",
          "postCode": [
            "53220"
          ]
        },
        {
          "id": 530711,
          "name": "นายาง",
          "postCode": [
            "53120"
          ]
        },
        {
          "id": 530710,
          "name": "นาอิน",
          "postCode": [
            "53120"
          ]
        },
        {
          "id": 530702,
          "name": "บ้านดารา",
          "postCode": [
            "53220"
          ]
        },
        {
          "id": 530706,
          "name": "บ้านหม้อ",
          "postCode": [
            "53120"
          ]
        },
        {
          "id": 530708,
          "name": "บ้านโคน",
          "postCode": [
            "53120"
          ]
        },
        {
          "id": 530709,
          "name": "พญาแมน",
          "postCode": [
            "53120"
          ]
        },
        {
          "id": 530701,
          "name": "ในเมือง",
          "postCode": [
            "53120"
          ]
        },
        {
          "id": 530703,
          "name": "ไร่อ้อย",
          "postCode": [
            "53120"
          ]
        }
      ]
    },
    {
      "city": "ฟากท่า",
      "cityCode": 5305,
      "provinceCode": 53,
      "subDistrict": [
        {
          "id": 530503,
          "name": "บ้านเสี้ยว",
          "postCode": [
            "53160"
          ]
        },
        {
          "id": 530501,
          "name": "ฟากท่า",
          "postCode": [
            "53160"
          ]
        },
        {
          "id": 530502,
          "name": "สองคอน",
          "postCode": [
            "53160"
          ]
        },
        {
          "id": 530504,
          "name": "สองห้อง",
          "postCode": [
            "53160"
          ]
        }
      ]
    },
    {
      "city": "ลับแล",
      "cityCode": 5308,
      "provinceCode": 53,
      "subDistrict": [
        {
          "id": 530805,
          "name": "ชัยจุมพล",
          "postCode": [
            "53130"
          ]
        },
        {
          "id": 530808,
          "name": "ด่านแม่คำมัน",
          "postCode": [
            "53210"
          ]
        },
        {
          "id": 530807,
          "name": "ทุ่งยั้ง",
          "postCode": [
            "53210"
          ]
        },
        {
          "id": 530803,
          "name": "นานกกก",
          "postCode": [
            "53130"
          ]
        },
        {
          "id": 530804,
          "name": "ฝายหลวง",
          "postCode": [
            "53130"
          ]
        },
        {
          "id": 530801,
          "name": "ศรีพนมมาศ",
          "postCode": [
            "53130"
          ]
        },
        {
          "id": 530802,
          "name": "แม่พูล",
          "postCode": [
            "53130"
          ]
        },
        {
          "id": 530806,
          "name": "ไผ่ล้อม",
          "postCode": [
            "53210"
          ]
        }
      ]
    },
    {
      "city": "เมืองอุตรดิตถ์",
      "cityCode": 5301,
      "provinceCode": 53,
      "subDistrict": [
        {
          "id": 530116,
          "name": "ขุนฝาง",
          "postCode": [
            "53000"
          ]
        },
        {
          "id": 530105,
          "name": "คุ้งตะเภา",
          "postCode": [
            "53000"
          ]
        },
        {
          "id": 530109,
          "name": "งิ้วงาม",
          "postCode": [
            "53000"
          ]
        },
        {
          "id": 530117,
          "name": "ถ้ำฉลอง",
          "postCode": [
            "53000"
          ]
        },
        {
          "id": 530101,
          "name": "ท่าอิฐ",
          "postCode": [
            "53000"
          ]
        },
        {
          "id": 530102,
          "name": "ท่าเสา",
          "postCode": [
            "53000"
          ]
        },
        {
          "id": 530108,
          "name": "น้ำริด",
          "postCode": [
            "53000"
          ]
        },
        {
          "id": 530111,
          "name": "บ้านด่าน",
          "postCode": [
            "53000"
          ]
        },
        {
          "id": 530110,
          "name": "บ้านด่านนาขาม",
          "postCode": [
            "53000"
          ]
        },
        {
          "id": 530103,
          "name": "บ้านเกาะ",
          "postCode": [
            "53000",
            "53170"
          ]
        },
        {
          "id": 530104,
          "name": "ป่าเซ่า",
          "postCode": [
            "53000"
          ]
        },
        {
          "id": 530112,
          "name": "ผาจุก",
          "postCode": [
            "53000"
          ]
        },
        {
          "id": 530106,
          "name": "วังกะพี้",
          "postCode": [
            "53170"
          ]
        },
        {
          "id": 530113,
          "name": "วังดิน",
          "postCode": [
            "53000"
          ]
        },
        {
          "id": 530107,
          "name": "หาดกรวด",
          "postCode": [
            "53000"
          ]
        },
        {
          "id": 530115,
          "name": "หาดงิ้ว",
          "postCode": [
            "53000"
          ]
        },
        {
          "id": 530114,
          "name": "แสนตอ",
          "postCode": [
            "53000"
          ]
        }
      ]
    },
    {
      "city": "ทัพทัน",
      "cityCode": 6102,
      "provinceCode": 61,
      "subDistrict": [
        {
          "id": 610210,
          "name": "ตลุกดู่",
          "postCode": [
            "61120"
          ]
        },
        {
          "id": 610201,
          "name": "ทัพทัน",
          "postCode": [
            "61120"
          ]
        },
        {
          "id": 610202,
          "name": "ทุ่งนาไทย",
          "postCode": [
            "61120"
          ]
        },
        {
          "id": 610208,
          "name": "หนองกระทุ่ม",
          "postCode": [
            "61120"
          ]
        },
        {
          "id": 610207,
          "name": "หนองกลางดง",
          "postCode": [
            "61120"
          ]
        },
        {
          "id": 610206,
          "name": "หนองยายดา",
          "postCode": [
            "61120"
          ]
        },
        {
          "id": 610209,
          "name": "หนองสระ",
          "postCode": [
            "61120"
          ]
        },
        {
          "id": 610204,
          "name": "หนองหญ้าปล้อง",
          "postCode": [
            "61120"
          ]
        },
        {
          "id": 610203,
          "name": "เขาขี้ฝอย",
          "postCode": [
            "61120"
          ]
        },
        {
          "id": 610205,
          "name": "โคกหม้อ",
          "postCode": [
            "61120"
          ]
        }
      ]
    },
    {
      "city": "บ้านไร่",
      "cityCode": 6106,
      "provinceCode": 61,
      "subDistrict": [
        {
          "id": 610604,
          "name": "คอกควาย",
          "postCode": [
            "61140"
          ]
        },
        {
          "id": 610602,
          "name": "ทัพหลวง",
          "postCode": [
            "61140"
          ]
        },
        {
          "id": 610611,
          "name": "บ้านบึง",
          "postCode": [
            "61140"
          ]
        },
        {
          "id": 610612,
          "name": "บ้านใหม่คลองเคียน",
          "postCode": [
            "61180"
          ]
        },
        {
          "id": 610601,
          "name": "บ้านไร่",
          "postCode": [
            "61140"
          ]
        },
        {
          "id": 610605,
          "name": "วังหิน",
          "postCode": [
            "61180"
          ]
        },
        {
          "id": 610609,
          "name": "หนองจอก",
          "postCode": [
            "61180"
          ]
        },
        {
          "id": 610613,
          "name": "หนองบ่มกล้วย",
          "postCode": [
            "61180"
          ]
        },
        {
          "id": 610610,
          "name": "หูช้าง",
          "postCode": [
            "61180"
          ]
        },
        {
          "id": 610603,
          "name": "ห้วยแห้ง",
          "postCode": [
            "61140"
          ]
        },
        {
          "id": 610614,
          "name": "เจ้าวัด",
          "postCode": [
            "61140"
          ]
        },
        {
          "id": 610606,
          "name": "เมืองการุ้ง",
          "postCode": [
            "61180"
          ]
        },
        {
          "id": 610607,
          "name": "แก่นมะกรูด",
          "postCode": [
            "61140"
          ]
        }
      ]
    },
    {
      "city": "ลานสัก",
      "cityCode": 6107,
      "provinceCode": 61,
      "subDistrict": [
        {
          "id": 610706,
          "name": "ทุ่งนางาม",
          "postCode": [
            "61160"
          ]
        },
        {
          "id": 610705,
          "name": "น้ำรอบ",
          "postCode": [
            "61160"
          ]
        },
        {
          "id": 610702,
          "name": "ประดู่ยืน",
          "postCode": [
            "61160"
          ]
        },
        {
          "id": 610703,
          "name": "ป่าอ้อ",
          "postCode": [
            "61160"
          ]
        },
        {
          "id": 610704,
          "name": "ระบำ",
          "postCode": [
            "61160"
          ]
        },
        {
          "id": 610701,
          "name": "ลานสัก",
          "postCode": [
            "61160"
          ]
        }
      ]
    },
    {
      "city": "สว่างอารมณ์",
      "cityCode": 6103,
      "provinceCode": 61,
      "subDistrict": [
        {
          "id": 610305,
          "name": "บ่อยาง",
          "postCode": [
            "61150"
          ]
        },
        {
          "id": 610303,
          "name": "พลวงสองนาง",
          "postCode": [
            "61150"
          ]
        },
        {
          "id": 610301,
          "name": "สว่างอารมณ์",
          "postCode": [
            "61150"
          ]
        },
        {
          "id": 610302,
          "name": "หนองหลวง",
          "postCode": [
            "61150"
          ]
        },
        {
          "id": 610304,
          "name": "ไผ่เขียว",
          "postCode": [
            "61150"
          ]
        }
      ]
    },
    {
      "city": "หนองขาหย่าง",
      "cityCode": 6105,
      "provinceCode": 61,
      "subDistrict": [
        {
          "id": 610509,
          "name": "ดงขวาง",
          "postCode": [
            "61130"
          ]
        },
        {
          "id": 610503,
          "name": "ดอนกลอย",
          "postCode": [
            "61130"
          ]
        },
        {
          "id": 610505,
          "name": "ทุ่งพึ่ง",
          "postCode": [
            "61130"
          ]
        },
        {
          "id": 610506,
          "name": "ท่าโพ",
          "postCode": [
            "61130"
          ]
        },
        {
          "id": 610501,
          "name": "หนองขาหย่าง",
          "postCode": [
            "61130"
          ]
        },
        {
          "id": 610502,
          "name": "หนองไผ่",
          "postCode": [
            "61130"
          ]
        },
        {
          "id": 610507,
          "name": "หมกแถว",
          "postCode": [
            "61130"
          ]
        },
        {
          "id": 610508,
          "name": "หลุมเข้า",
          "postCode": [
            "61130"
          ]
        },
        {
          "id": 610504,
          "name": "ห้วยรอบ",
          "postCode": [
            "61130"
          ]
        }
      ]
    },
    {
      "city": "หนองฉาง",
      "cityCode": 6104,
      "provinceCode": 61,
      "subDistrict": [
        {
          "id": 610408,
          "name": "ทุ่งพง",
          "postCode": [
            "61110"
          ]
        },
        {
          "id": 610407,
          "name": "ทุ่งโพ",
          "postCode": [
            "61110"
          ]
        },
        {
          "id": 610405,
          "name": "บ้านเก่า",
          "postCode": [
            "61110"
          ]
        },
        {
          "id": 610401,
          "name": "หนองฉาง",
          "postCode": [
            "61110"
          ]
        },
        {
          "id": 610403,
          "name": "หนองนางนวล",
          "postCode": [
            "61110"
          ]
        },
        {
          "id": 610402,
          "name": "หนองยาง",
          "postCode": [
            "61110"
          ]
        },
        {
          "id": 610404,
          "name": "หนองสรวง",
          "postCode": [
            "61110"
          ]
        },
        {
          "id": 610406,
          "name": "อุทัยเก่า",
          "postCode": [
            "61110"
          ]
        },
        {
          "id": 610410,
          "name": "เขากวางทอง",
          "postCode": [
            "61110"
          ]
        },
        {
          "id": 610409,
          "name": "เขาบางแกรก",
          "postCode": [
            "61170"
          ]
        }
      ]
    },
    {
      "city": "ห้วยคต",
      "cityCode": 6108,
      "provinceCode": 61,
      "subDistrict": [
        {
          "id": 610802,
          "name": "ทองหลาง",
          "postCode": [
            "61170"
          ]
        },
        {
          "id": 610801,
          "name": "สุขฤทัย",
          "postCode": [
            "61170"
          ]
        },
        {
          "id": 610803,
          "name": "ห้วยคต",
          "postCode": [
            "61170"
          ]
        }
      ]
    },
    {
      "city": "เมืองอุทัยธานี",
      "cityCode": 6101,
      "provinceCode": 61,
      "subDistrict": [
        {
          "id": 610104,
          "name": "ดอนขวาง",
          "postCode": [
            "61000"
          ]
        },
        {
          "id": 610113,
          "name": "ทุ่งใหญ่",
          "postCode": [
            "61000"
          ]
        },
        {
          "id": 610107,
          "name": "ท่าซุง",
          "postCode": [
            "61000"
          ]
        },
        {
          "id": 610102,
          "name": "น้ำซึม",
          "postCode": [
            "61000"
          ]
        },
        {
          "id": 610103,
          "name": "สะแกกรัง",
          "postCode": [
            "61000"
          ]
        },
        {
          "id": 610112,
          "name": "หนองพังค่า",
          "postCode": [
            "61000"
          ]
        },
        {
          "id": 610110,
          "name": "หนองเต่า",
          "postCode": [
            "61000"
          ]
        },
        {
          "id": 610108,
          "name": "หนองแก",
          "postCode": [
            "61000"
          ]
        },
        {
          "id": 610111,
          "name": "หนองไผ่แบน",
          "postCode": [
            "61000"
          ]
        },
        {
          "id": 610105,
          "name": "หาดทนง",
          "postCode": [
            "61000"
          ]
        },
        {
          "id": 610101,
          "name": "อุทัยใหม่",
          "postCode": [
            "61000"
          ]
        },
        {
          "id": 610106,
          "name": "เกาะเทโพ",
          "postCode": [
            "61000"
          ]
        },
        {
          "id": 610114,
          "name": "เนินแจง",
          "postCode": [
            "61000"
          ]
        },
        {
          "id": 610109,
          "name": "โนนเหล็ก",
          "postCode": [
            "61000"
          ]
        }
      ]
    },
    {
      "city": "กุดข้าวปุ้น",
      "cityCode": 3412,
      "provinceCode": 34,
      "subDistrict": [
        {
          "id": 341204,
          "name": "กาบิน",
          "postCode": [
            "34270"
          ]
        },
        {
          "id": 341201,
          "name": "ข้าวปุ้น",
          "postCode": [
            "34270"
          ]
        },
        {
          "id": 341205,
          "name": "หนองทันน้ำ",
          "postCode": [
            "34270"
          ]
        },
        {
          "id": 341203,
          "name": "แก่งเค็ง",
          "postCode": [
            "34270"
          ]
        },
        {
          "id": 341202,
          "name": "โนนสวาง",
          "postCode": [
            "34270"
          ]
        }
      ]
    },
    {
      "city": "ดอนมดแดง",
      "cityCode": 3424,
      "provinceCode": 34,
      "subDistrict": [
        {
          "id": 342404,
          "name": "คำไฮใหญ่",
          "postCode": [
            "34000"
          ]
        },
        {
          "id": 342401,
          "name": "ดอนมดแดง",
          "postCode": [
            "34000"
          ]
        },
        {
          "id": 342403,
          "name": "ท่าเมือง",
          "postCode": [
            "34000"
          ]
        },
        {
          "id": 342402,
          "name": "เหล่าแดง",
          "postCode": [
            "34000"
          ]
        }
      ]
    },
    {
      "city": "ตระการพืชผล",
      "cityCode": 3411,
      "provinceCode": 34,
      "subDistrict": [
        {
          "id": 341102,
          "name": "กระเดียน",
          "postCode": [
            "34130"
          ]
        },
        {
          "id": 341121,
          "name": "กุดยาลวน",
          "postCode": [
            "34130"
          ]
        },
        {
          "id": 341104,
          "name": "กุศกร",
          "postCode": [
            "34130"
          ]
        },
        {
          "id": 341105,
          "name": "ขามเปี้ย",
          "postCode": [
            "34130"
          ]
        },
        {
          "id": 341101,
          "name": "ขุหลุ",
          "postCode": [
            "34130"
          ]
        },
        {
          "id": 341106,
          "name": "คอนสาย",
          "postCode": [
            "34130"
          ]
        },
        {
          "id": 341123,
          "name": "คำเจริญ",
          "postCode": [
            "34130"
          ]
        },
        {
          "id": 341111,
          "name": "ตระการ",
          "postCode": [
            "34130"
          ]
        },
        {
          "id": 341112,
          "name": "ตากแดด",
          "postCode": [
            "34130"
          ]
        },
        {
          "id": 341118,
          "name": "ถ้ำแข้",
          "postCode": [
            "34130"
          ]
        },
        {
          "id": 341119,
          "name": "ท่าหลวง",
          "postCode": [
            "34130"
          ]
        },
        {
          "id": 341108,
          "name": "นาพิน",
          "postCode": [
            "34130"
          ]
        },
        {
          "id": 341109,
          "name": "นาสะไม",
          "postCode": [
            "34130"
          ]
        },
        {
          "id": 341122,
          "name": "บ้านแดง",
          "postCode": [
            "34130"
          ]
        },
        {
          "id": 341116,
          "name": "สะพือ",
          "postCode": [
            "34130"
          ]
        },
        {
          "id": 341117,
          "name": "หนองเต่า",
          "postCode": [
            "34130"
          ]
        },
        {
          "id": 341120,
          "name": "ห้วยฝ้ายพัฒนา",
          "postCode": [
            "34130"
          ]
        },
        {
          "id": 341103,
          "name": "เกษม",
          "postCode": [
            "34130"
          ]
        },
        {
          "id": 341115,
          "name": "เซเป็ด",
          "postCode": [
            "34130"
          ]
        },
        {
          "id": 341114,
          "name": "เป้า",
          "postCode": [
            "34130"
          ]
        },
        {
          "id": 341107,
          "name": "โคกจาน",
          "postCode": [
            "34130"
          ]
        },
        {
          "id": 341110,
          "name": "โนนกุง",
          "postCode": [
            "34130"
          ]
        },
        {
          "id": 341113,
          "name": "ไหล่ทุ่ง",
          "postCode": [
            "34130"
          ]
        }
      ]
    },
    {
      "city": "ตาลสุม",
      "cityCode": 3420,
      "provinceCode": 34,
      "subDistrict": [
        {
          "id": 342006,
          "name": "คำหว้า",
          "postCode": [
            "34330"
          ]
        },
        {
          "id": 342003,
          "name": "จิกเทิง",
          "postCode": [
            "34330"
          ]
        },
        {
          "id": 342001,
          "name": "ตาลสุม",
          "postCode": [
            "34330"
          ]
        },
        {
          "id": 342005,
          "name": "นาคาย",
          "postCode": [
            "34330"
          ]
        },
        {
          "id": 342002,
          "name": "สำโรง",
          "postCode": [
            "34330"
          ]
        },
        {
          "id": 342004,
          "name": "หนองกุง",
          "postCode": [
            "34330"
          ]
        }
      ]
    },
    {
      "city": "ทุ่งศรีอุดม",
      "cityCode": 3426,
      "provinceCode": 34,
      "subDistrict": [
        {
          "id": 342604,
          "name": "กุดเรือ",
          "postCode": [
            "34160"
          ]
        },
        {
          "id": 342606,
          "name": "นาห่อม",
          "postCode": [
            "34160"
          ]
        },
        {
          "id": 342603,
          "name": "นาเกษม",
          "postCode": [
            "34160"
          ]
        },
        {
          "id": 342602,
          "name": "หนองอ้ม",
          "postCode": [
            "34160"
          ]
        },
        {
          "id": 342605,
          "name": "โคกชำแระ",
          "postCode": [
            "34160"
          ]
        }
      ]
    },
    {
      "city": "นาจะหลวย",
      "cityCode": 3408,
      "provinceCode": 34,
      "subDistrict": [
        {
          "id": 340801,
          "name": "นาจะหลวย",
          "postCode": [
            "34280"
          ]
        },
        {
          "id": 340804,
          "name": "บ้านตูม",
          "postCode": [
            "34280"
          ]
        },
        {
          "id": 340803,
          "name": "พรสวรรค์",
          "postCode": [
            "34280"
          ]
        },
        {
          "id": 340802,
          "name": "โนนสมบูรณ์",
          "postCode": [
            "34280"
          ]
        },
        {
          "id": 340806,
          "name": "โนนสวรรค์",
          "postCode": [
            "34280"
          ]
        },
        {
          "id": 340805,
          "name": "โสกแสง",
          "postCode": [
            "34280"
          ]
        }
      ]
    },
    {
      "city": "นาตาล",
      "cityCode": 3430,
      "provinceCode": 34,
      "subDistrict": [
        {
          "id": 343003,
          "name": "กองโพน",
          "postCode": [
            "34170"
          ]
        },
        {
          "id": 343001,
          "name": "นาตาล",
          "postCode": [
            "34170"
          ]
        },
        {
          "id": 343002,
          "name": "พะลาน",
          "postCode": [
            "34170"
          ]
        },
        {
          "id": 343004,
          "name": "พังเคน",
          "postCode": [
            "34170"
          ]
        }
      ]
    },
    {
      "city": "นาเยีย",
      "cityCode": 3429,
      "provinceCode": 34,
      "subDistrict": [
        {
          "id": 342902,
          "name": "นาดี",
          "postCode": [
            "34160"
          ]
        },
        {
          "id": 342901,
          "name": "นาเยีย",
          "postCode": [
            "34160"
          ]
        },
        {
          "id": 342903,
          "name": "นาเรือง",
          "postCode": [
            "34160"
          ]
        }
      ]
    },
    {
      "city": "น้ำขุ่น",
      "cityCode": 3433,
      "provinceCode": 34,
      "subDistrict": [
        {
          "id": 343303,
          "name": "ขี้เหล็ก",
          "postCode": [
            "34260"
          ]
        },
        {
          "id": 343301,
          "name": "ตาเกา",
          "postCode": [
            "34260"
          ]
        },
        {
          "id": 343304,
          "name": "โคกสะอาด",
          "postCode": [
            "34260"
          ]
        },
        {
          "id": 343302,
          "name": "ไพบูลย์",
          "postCode": [
            "34260"
          ]
        }
      ]
    },
    {
      "city": "น้ำยืน",
      "cityCode": 3409,
      "provinceCode": 34,
      "subDistrict": [
        {
          "id": 340906,
          "name": "บุเปือย",
          "postCode": [
            "34260"
          ]
        },
        {
          "id": 340903,
          "name": "ยาง",
          "postCode": [
            "34260"
          ]
        },
        {
          "id": 340909,
          "name": "ยางใหญ่",
          "postCode": [
            "34260"
          ]
        },
        {
          "id": 340907,
          "name": "สีวิเชียร",
          "postCode": [
            "34260"
          ]
        },
        {
          "id": 340911,
          "name": "เก่าขาม",
          "postCode": [
            "34260"
          ]
        },
        {
          "id": 340901,
          "name": "โซง",
          "postCode": [
            "34260"
          ]
        },
        {
          "id": 340904,
          "name": "โดมประดิษฐ์",
          "postCode": [
            "34260"
          ]
        }
      ]
    },
    {
      "city": "บุณฑริก",
      "cityCode": 3410,
      "provinceCode": 34,
      "subDistrict": [
        {
          "id": 341003,
          "name": "คอแลน",
          "postCode": [
            "34230"
          ]
        },
        {
          "id": 341004,
          "name": "นาโพธิ์",
          "postCode": [
            "34230"
          ]
        },
        {
          "id": 341007,
          "name": "บัวงาม",
          "postCode": [
            "34230"
          ]
        },
        {
          "id": 341008,
          "name": "บ้านแมด",
          "postCode": [
            "34230"
          ]
        },
        {
          "id": 341005,
          "name": "หนองสะโน",
          "postCode": [
            "34230"
          ]
        },
        {
          "id": 341002,
          "name": "ห้วยข่า",
          "postCode": [
            "34230"
          ]
        },
        {
          "id": 341006,
          "name": "โนนค้อ",
          "postCode": [
            "34230"
          ]
        },
        {
          "id": 341001,
          "name": "โพนงาม",
          "postCode": [
            "34230"
          ]
        }
      ]
    },
    {
      "city": "พิบูลมังสาหาร",
      "cityCode": 3419,
      "provinceCode": 34,
      "subDistrict": [
        {
          "id": 341902,
          "name": "กุดชมภู",
          "postCode": [
            "34110"
          ]
        },
        {
          "id": 341904,
          "name": "ดอนจิก",
          "postCode": [
            "34110"
          ]
        },
        {
          "id": 341905,
          "name": "ทรายมูล",
          "postCode": [
            "34110"
          ]
        },
        {
          "id": 341906,
          "name": "นาโพธิ์",
          "postCode": [
            "34110"
          ]
        },
        {
          "id": 341919,
          "name": "บ้านแขม",
          "postCode": [
            "34110"
          ]
        },
        {
          "id": 341901,
          "name": "พิบูล",
          "postCode": [
            "34110"
          ]
        },
        {
          "id": 341911,
          "name": "ระเว",
          "postCode": [
            "34110"
          ]
        },
        {
          "id": 341913,
          "name": "หนองบัวฮี",
          "postCode": [
            "34110"
          ]
        },
        {
          "id": 341914,
          "name": "อ่างศิลา",
          "postCode": [
            "34110"
          ]
        },
        {
          "id": 341907,
          "name": "โนนกลาง",
          "postCode": [
            "34110"
          ]
        },
        {
          "id": 341918,
          "name": "โนนกาหลง",
          "postCode": [
            "34110"
          ]
        },
        {
          "id": 341910,
          "name": "โพธิ์ศรี",
          "postCode": [
            "34110"
          ]
        },
        {
          "id": 341909,
          "name": "โพธิ์ไทร",
          "postCode": [
            "34110"
          ]
        },
        {
          "id": 341912,
          "name": "ไร่ใต้",
          "postCode": [
            "34110"
          ]
        }
      ]
    },
    {
      "city": "ม่วงสามสิบ",
      "cityCode": 3414,
      "provinceCode": 34,
      "subDistrict": [
        {
          "id": 341403,
          "name": "ดุมใหญ่",
          "postCode": [
            "34140"
          ]
        },
        {
          "id": 341413,
          "name": "นาเลิง",
          "postCode": [
            "34140"
          ]
        },
        {
          "id": 341401,
          "name": "ม่วงสามสิบ",
          "postCode": [
            "34140"
          ]
        },
        {
          "id": 341407,
          "name": "ยางสักกระโพหลุ่ม",
          "postCode": [
            "34140"
          ]
        },
        {
          "id": 341411,
          "name": "ยางโยภาพ",
          "postCode": [
            "34140"
          ]
        },
        {
          "id": 341404,
          "name": "หนองช้างใหญ่",
          "postCode": [
            "34140"
          ]
        },
        {
          "id": 341410,
          "name": "หนองฮาง",
          "postCode": [
            "34140"
          ]
        },
        {
          "id": 341405,
          "name": "หนองเมือง",
          "postCode": [
            "34140"
          ]
        },
        {
          "id": 341409,
          "name": "หนองเหล่า",
          "postCode": [
            "34140"
          ]
        },
        {
          "id": 341408,
          "name": "หนองไข่นก",
          "postCode": [
            "34140"
          ]
        },
        {
          "id": 341406,
          "name": "เตย",
          "postCode": [
            "34140"
          ]
        },
        {
          "id": 341402,
          "name": "เหล่าบก",
          "postCode": [
            "34140"
          ]
        },
        {
          "id": 341414,
          "name": "โพนแพง",
          "postCode": [
            "34140"
          ]
        },
        {
          "id": 341412,
          "name": "ไผ่ใหญ่",
          "postCode": [
            "34140"
          ]
        }
      ]
    },
    {
      "city": "วารินชำราบ",
      "cityCode": 3415,
      "provinceCode": 34,
      "subDistrict": [
        {
          "id": 341515,
          "name": "คำขวาง",
          "postCode": [
            "34190"
          ]
        },
        {
          "id": 341510,
          "name": "คำน้ำแซบ",
          "postCode": [
            "34190"
          ]
        },
        {
          "id": 341507,
          "name": "คูเมือง",
          "postCode": [
            "34190"
          ]
        },
        {
          "id": 341504,
          "name": "ท่าลาด",
          "postCode": [
            "34310"
          ]
        },
        {
          "id": 341502,
          "name": "ธาตุ",
          "postCode": [
            "34190"
          ]
        },
        {
          "id": 341511,
          "name": "บุ่งหวาย",
          "postCode": [
            "34310"
          ]
        },
        {
          "id": 341526,
          "name": "บุ่งไหม",
          "postCode": [
            "34190"
          ]
        },
        {
          "id": 341501,
          "name": "วารินชำราบ",
          "postCode": [
            "34190"
          ]
        },
        {
          "id": 341508,
          "name": "สระสมิง",
          "postCode": [
            "34190"
          ]
        },
        {
          "id": 341520,
          "name": "หนองกินเพล",
          "postCode": [
            "34190"
          ]
        },
        {
          "id": 341524,
          "name": "ห้วยขะยุง",
          "postCode": [
            "34310"
          ]
        },
        {
          "id": 341522,
          "name": "เมืองศรีไค",
          "postCode": [
            "34190"
          ]
        },
        {
          "id": 341518,
          "name": "แสนสุข",
          "postCode": [
            "34190"
          ]
        },
        {
          "id": 341521,
          "name": "โนนผึ้ง",
          "postCode": [
            "34190"
          ]
        },
        {
          "id": 341505,
          "name": "โนนโหนน",
          "postCode": [
            "34190"
          ]
        },
        {
          "id": 341516,
          "name": "โพธิ์ใหญ่",
          "postCode": [
            "34190"
          ]
        }
      ]
    },
    {
      "city": "ศรีเมืองใหม่",
      "cityCode": 3402,
      "provinceCode": 34,
      "subDistrict": [
        {
          "id": 340208,
          "name": "คำไหล",
          "postCode": [
            "34250"
          ]
        },
        {
          "id": 340211,
          "name": "ดอนใหญ่",
          "postCode": [
            "34250"
          ]
        },
        {
          "id": 340207,
          "name": "ตะบ่าย",
          "postCode": [
            "34250"
          ]
        },
        {
          "id": 340201,
          "name": "นาคำ",
          "postCode": [
            "34250"
          ]
        },
        {
          "id": 340210,
          "name": "นาเลิน",
          "postCode": [
            "34250"
          ]
        },
        {
          "id": 340205,
          "name": "ลาดควาย",
          "postCode": [
            "34250"
          ]
        },
        {
          "id": 340204,
          "name": "วาริน",
          "postCode": [
            "34250"
          ]
        },
        {
          "id": 340206,
          "name": "สงยาง",
          "postCode": [
            "34250"
          ]
        },
        {
          "id": 340209,
          "name": "หนามแท่ง",
          "postCode": [
            "34250"
          ]
        },
        {
          "id": 340203,
          "name": "เอือดใหญ่",
          "postCode": [
            "34250"
          ]
        },
        {
          "id": 340202,
          "name": "แก้งกอก",
          "postCode": [
            "34250"
          ]
        }
      ]
    },
    {
      "city": "สว่างวีระวงศ์",
      "cityCode": 3432,
      "provinceCode": 34,
      "subDistrict": [
        {
          "id": 343202,
          "name": "ท่าช้าง",
          "postCode": [
            "34190"
          ]
        },
        {
          "id": 343203,
          "name": "บุ่งมะแลง",
          "postCode": [
            "34190"
          ]
        },
        {
          "id": 343204,
          "name": "สว่าง",
          "postCode": [
            "34190"
          ]
        },
        {
          "id": 343201,
          "name": "แก่งโดม",
          "postCode": [
            "34190"
          ]
        }
      ]
    },
    {
      "city": "สำโรง",
      "cityCode": 3422,
      "provinceCode": 34,
      "subDistrict": [
        {
          "id": 342209,
          "name": "ขามป้อม",
          "postCode": [
            "34360"
          ]
        },
        {
          "id": 342204,
          "name": "ค้อน้อย",
          "postCode": [
            "34360"
          ]
        },
        {
          "id": 342208,
          "name": "บอน",
          "postCode": [
            "34360"
          ]
        },
        {
          "id": 342201,
          "name": "สำโรง",
          "postCode": [
            "34360"
          ]
        },
        {
          "id": 342203,
          "name": "หนองไฮ",
          "postCode": [
            "34360"
          ]
        },
        {
          "id": 342202,
          "name": "โคกก่อง",
          "postCode": [
            "34360"
          ]
        },
        {
          "id": 342206,
          "name": "โคกสว่าง",
          "postCode": [
            "34360"
          ]
        },
        {
          "id": 342207,
          "name": "โนนกลาง",
          "postCode": [
            "34360"
          ]
        },
        {
          "id": 342205,
          "name": "โนนกาเล็น",
          "postCode": [
            "34360"
          ]
        }
      ]
    },
    {
      "city": "สิรินธร",
      "cityCode": 3425,
      "provinceCode": 34,
      "subDistrict": [
        {
          "id": 342501,
          "name": "คันไร่",
          "postCode": [
            "34350"
          ]
        },
        {
          "id": 342506,
          "name": "คำเขื่อนแก้ว",
          "postCode": [
            "34350"
          ]
        },
        {
          "id": 342502,
          "name": "ช่องเม็ก",
          "postCode": [
            "34350"
          ]
        },
        {
          "id": 342504,
          "name": "นิคมสร้างตนเองลำโดมน้อย",
          "postCode": [
            "34350"
          ]
        },
        {
          "id": 342505,
          "name": "ฝางคำ",
          "postCode": [
            "34350"
          ]
        },
        {
          "id": 342503,
          "name": "โนนก่อ",
          "postCode": [
            "34350"
          ]
        }
      ]
    },
    {
      "city": "เขมราฐ",
      "cityCode": 3405,
      "provinceCode": 34,
      "subDistrict": [
        {
          "id": 340503,
          "name": "ขามป้อม",
          "postCode": [
            "34170"
          ]
        },
        {
          "id": 340508,
          "name": "นาแวง",
          "postCode": [
            "34170"
          ]
        },
        {
          "id": 340511,
          "name": "หนองนกทา",
          "postCode": [
            "34170"
          ]
        },
        {
          "id": 340507,
          "name": "หนองผือ",
          "postCode": [
            "34170"
          ]
        },
        {
          "id": 340512,
          "name": "หนองสิม",
          "postCode": [
            "34170"
          ]
        },
        {
          "id": 340513,
          "name": "หัวนา",
          "postCode": [
            "34170"
          ]
        },
        {
          "id": 340501,
          "name": "เขมราฐ",
          "postCode": [
            "34170"
          ]
        },
        {
          "id": 340504,
          "name": "เจียด",
          "postCode": [
            "34170"
          ]
        },
        {
          "id": 340510,
          "name": "แก้งเหนือ",
          "postCode": [
            "34170"
          ]
        }
      ]
    },
    {
      "city": "เขื่องใน",
      "cityCode": 3404,
      "provinceCode": 34,
      "subDistrict": [
        {
          "id": 340413,
          "name": "กลางใหญ่",
          "postCode": [
            "34320"
          ]
        },
        {
          "id": 340404,
          "name": "ก่อเอ้",
          "postCode": [
            "34150"
          ]
        },
        {
          "id": 340403,
          "name": "ค้อทอง",
          "postCode": [
            "34150"
          ]
        },
        {
          "id": 340406,
          "name": "ชีทวน",
          "postCode": [
            "34150"
          ]
        },
        {
          "id": 340407,
          "name": "ท่าไห",
          "postCode": [
            "34150"
          ]
        },
        {
          "id": 340410,
          "name": "ธาตุน้อย",
          "postCode": [
            "34150"
          ]
        },
        {
          "id": 340408,
          "name": "นาคำใหญ่",
          "postCode": [
            "34150"
          ]
        },
        {
          "id": 340412,
          "name": "บ้านกอก",
          "postCode": [
            "34320"
          ]
        },
        {
          "id": 340411,
          "name": "บ้านไทย",
          "postCode": [
            "34320"
          ]
        },
        {
          "id": 340415,
          "name": "ยางขี้นก",
          "postCode": [
            "34150"
          ]
        },
        {
          "id": 340416,
          "name": "ศรีสุข",
          "postCode": [
            "34150"
          ]
        },
        {
          "id": 340402,
          "name": "สร้างถ่อ",
          "postCode": [
            "34150"
          ]
        },
        {
          "id": 340417,
          "name": "สหธาตุ",
          "postCode": [
            "34150"
          ]
        },
        {
          "id": 340418,
          "name": "หนองเหล่า",
          "postCode": [
            "34150"
          ]
        },
        {
          "id": 340405,
          "name": "หัวดอน",
          "postCode": [
            "34150"
          ]
        },
        {
          "id": 340401,
          "name": "เขื่องใน",
          "postCode": [
            "34150"
          ]
        },
        {
          "id": 340409,
          "name": "แดงหม้อ",
          "postCode": [
            "34150"
          ]
        },
        {
          "id": 340414,
          "name": "โนนรัง",
          "postCode": [
            "34320"
          ]
        }
      ]
    },
    {
      "city": "เดชอุดม",
      "cityCode": 3407,
      "provinceCode": 34,
      "subDistrict": [
        {
          "id": 340711,
          "name": "กลาง",
          "postCode": [
            "34160"
          ]
        },
        {
          "id": 340709,
          "name": "กุดประทาย",
          "postCode": [
            "34160"
          ]
        },
        {
          "id": 340716,
          "name": "คำครั่ง",
          "postCode": [
            "34160"
          ]
        },
        {
          "id": 340710,
          "name": "ตบหู",
          "postCode": [
            "34160"
          ]
        },
        {
          "id": 340706,
          "name": "ทุ่งเทิง",
          "postCode": [
            "34160"
          ]
        },
        {
          "id": 340713,
          "name": "ท่าโพธิ์ศรี",
          "postCode": [
            "34160"
          ]
        },
        {
          "id": 340717,
          "name": "นากระแซง",
          "postCode": [
            "34160"
          ]
        },
        {
          "id": 340702,
          "name": "นาส่วง",
          "postCode": [
            "34160"
          ]
        },
        {
          "id": 340704,
          "name": "นาเจริญ",
          "postCode": [
            "34160"
          ]
        },
        {
          "id": 340715,
          "name": "บัวงาม",
          "postCode": [
            "34160"
          ]
        },
        {
          "id": 340721,
          "name": "ป่าโมง",
          "postCode": [
            "34160"
          ]
        },
        {
          "id": 340708,
          "name": "สมสะอาด",
          "postCode": [
            "34160"
          ]
        },
        {
          "id": 340701,
          "name": "เมืองเดช",
          "postCode": [
            "34160"
          ]
        },
        {
          "id": 340712,
          "name": "แก้ง",
          "postCode": [
            "34160"
          ]
        },
        {
          "id": 340723,
          "name": "โนนสมบูรณ์",
          "postCode": [
            "34160"
          ]
        },
        {
          "id": 340720,
          "name": "โพนงาม",
          "postCode": [
            "34160"
          ]
        }
      ]
    },
    {
      "city": "เมืองอุบลราชธานี",
      "cityCode": 3401,
      "provinceCode": 34,
      "subDistrict": [
        {
          "id": 340113,
          "name": "กระโสบ",
          "postCode": [
            "34000"
          ]
        },
        {
          "id": 340116,
          "name": "กุดลาด",
          "postCode": [
            "34000"
          ]
        },
        {
          "id": 340108,
          "name": "ขามใหญ่",
          "postCode": [
            "34000"
          ]
        },
        {
          "id": 340119,
          "name": "ขี้เหล็ก",
          "postCode": [
            "34000"
          ]
        },
        {
          "id": 340107,
          "name": "ปทุม",
          "postCode": [
            "34000"
          ]
        },
        {
          "id": 340120,
          "name": "ปะอาว",
          "postCode": [
            "34000"
          ]
        },
        {
          "id": 340105,
          "name": "หนองขอน",
          "postCode": [
            "34000"
          ]
        },
        {
          "id": 340111,
          "name": "หนองบ่อ",
          "postCode": [
            "34000"
          ]
        },
        {
          "id": 340104,
          "name": "หัวเรือ",
          "postCode": [
            "34000"
          ]
        },
        {
          "id": 340109,
          "name": "แจระแม",
          "postCode": [
            "34000"
          ]
        },
        {
          "id": 340101,
          "name": "ในเมือง",
          "postCode": [
            "34000"
          ]
        },
        {
          "id": 340112,
          "name": "ไร่น้อย",
          "postCode": [
            "34000"
          ]
        }
      ]
    },
    {
      "city": "เหล่าเสือโก้ก",
      "cityCode": 3431,
      "provinceCode": 34,
      "subDistrict": [
        {
          "id": 343104,
          "name": "หนองบก",
          "postCode": [
            "34000"
          ]
        },
        {
          "id": 343101,
          "name": "เหล่าเสือโก้ก",
          "postCode": [
            "34000"
          ]
        },
        {
          "id": 343103,
          "name": "แพงใหญ่",
          "postCode": [
            "34000"
          ]
        },
        {
          "id": 343102,
          "name": "โพนเมือง",
          "postCode": [
            "34000"
          ]
        }
      ]
    },
    {
      "city": "โขงเจียม",
      "cityCode": 3403,
      "provinceCode": 34,
      "subDistrict": [
        {
          "id": 340303,
          "name": "นาโพธิ์กลาง",
          "postCode": [
            "34220"
          ]
        },
        {
          "id": 340304,
          "name": "หนองแสงใหญ่",
          "postCode": [
            "34220"
          ]
        },
        {
          "id": 340302,
          "name": "ห้วยยาง",
          "postCode": [
            "34220"
          ]
        },
        {
          "id": 340305,
          "name": "ห้วยไผ่",
          "postCode": [
            "34220"
          ]
        },
        {
          "id": 340301,
          "name": "โขงเจียม",
          "postCode": [
            "34220"
          ]
        }
      ]
    },
    {
      "city": "โพธิ์ไทร",
      "cityCode": 3421,
      "provinceCode": 34,
      "subDistrict": [
        {
          "id": 342102,
          "name": "ม่วงใหญ่",
          "postCode": [
            "34340"
          ]
        },
        {
          "id": 342104,
          "name": "สองคอน",
          "postCode": [
            "34340"
          ]
        },
        {
          "id": 342105,
          "name": "สารภี",
          "postCode": [
            "34340"
          ]
        },
        {
          "id": 342103,
          "name": "สำโรง",
          "postCode": [
            "34340"
          ]
        },
        {
          "id": 342106,
          "name": "เหล่างาม",
          "postCode": [
            "34340"
          ]
        },
        {
          "id": 342101,
          "name": "โพธิ์ไทร",
          "postCode": [
            "34340"
          ]
        }
      ]
    },
    {
      "city": "ป่าโมก",
      "cityCode": 1503,
      "provinceCode": 15,
      "subDistrict": [
        {
          "id": 150306,
          "name": "นรสิงห์",
          "postCode": [
            "14130"
          ]
        },
        {
          "id": 150301,
          "name": "บางปลากด",
          "postCode": [
            "14130"
          ]
        },
        {
          "id": 150305,
          "name": "บางเสด็จ",
          "postCode": [
            "14130"
          ]
        },
        {
          "id": 150302,
          "name": "ป่าโมก",
          "postCode": [
            "14130"
          ]
        },
        {
          "id": 150303,
          "name": "สายทอง",
          "postCode": [
            "14130"
          ]
        },
        {
          "id": 150307,
          "name": "เอกราช",
          "postCode": [
            "14130"
          ]
        },
        {
          "id": 150308,
          "name": "โผงเผง",
          "postCode": [
            "14130"
          ]
        },
        {
          "id": 150304,
          "name": "โรงช้าง",
          "postCode": [
            "14130"
          ]
        }
      ]
    },
    {
      "city": "วิเศษชัยชาญ",
      "cityCode": 1506,
      "provinceCode": 15,
      "subDistrict": [
        {
          "id": 150609,
          "name": "คลองขนาก",
          "postCode": [
            "14110"
          ]
        },
        {
          "id": 150615,
          "name": "ตลาดใหม่",
          "postCode": [
            "14110"
          ]
        },
        {
          "id": 150605,
          "name": "ท่าช้าง",
          "postCode": [
            "14110"
          ]
        },
        {
          "id": 150607,
          "name": "บางจัก",
          "postCode": [
            "14110"
          ]
        },
        {
          "id": 150612,
          "name": "ม่วงเตี้ย",
          "postCode": [
            "14110"
          ]
        },
        {
          "id": 150606,
          "name": "ยี่ล้น",
          "postCode": [
            "14110"
          ]
        },
        {
          "id": 150602,
          "name": "ศาลเจ้าโรงทอง",
          "postCode": [
            "14110"
          ]
        },
        {
          "id": 150604,
          "name": "สาวร้องไห้",
          "postCode": [
            "14110"
          ]
        },
        {
          "id": 150611,
          "name": "สี่ร้อย",
          "postCode": [
            "14110"
          ]
        },
        {
          "id": 150614,
          "name": "หลักแก้ว",
          "postCode": [
            "14110"
          ]
        },
        {
          "id": 150613,
          "name": "หัวตะพาน",
          "postCode": [
            "14110"
          ]
        },
        {
          "id": 150608,
          "name": "ห้วยคันแหลน",
          "postCode": [
            "14110"
          ]
        },
        {
          "id": 150601,
          "name": "ไผ่จำศีล",
          "postCode": [
            "14110"
          ]
        },
        {
          "id": 150603,
          "name": "ไผ่ดำพัฒนา",
          "postCode": [
            "14110"
          ]
        },
        {
          "id": 150610,
          "name": "ไผ่วง",
          "postCode": [
            "14110"
          ]
        }
      ]
    },
    {
      "city": "สามโก้",
      "cityCode": 1507,
      "provinceCode": 15,
      "subDistrict": [
        {
          "id": 150705,
          "name": "มงคลธรรมนิมิต",
          "postCode": [
            "14160"
          ]
        },
        {
          "id": 150702,
          "name": "ราษฎรพัฒนา",
          "postCode": [
            "14160"
          ]
        },
        {
          "id": 150701,
          "name": "สามโก้",
          "postCode": [
            "14160"
          ]
        },
        {
          "id": 150703,
          "name": "อบทม",
          "postCode": [
            "14160"
          ]
        },
        {
          "id": 150704,
          "name": "โพธิ์ม่วงพันธ์",
          "postCode": [
            "14160"
          ]
        }
      ]
    },
    {
      "city": "เมืองอ่างทอง",
      "cityCode": 1501,
      "provinceCode": 15,
      "subDistrict": [
        {
          "id": 150113,
          "name": "คลองวัว",
          "postCode": [
            "14000"
          ]
        },
        {
          "id": 150110,
          "name": "จำปาหล่อ",
          "postCode": [
            "14000"
          ]
        },
        {
          "id": 150106,
          "name": "ตลาดกรวด",
          "postCode": [
            "14000"
          ]
        },
        {
          "id": 150101,
          "name": "ตลาดหลวง",
          "postCode": [
            "14000"
          ]
        },
        {
          "id": 150102,
          "name": "บางแก้ว",
          "postCode": [
            "14000"
          ]
        },
        {
          "id": 150112,
          "name": "บ้านรี",
          "postCode": [
            "14000"
          ]
        },
        {
          "id": 150108,
          "name": "บ้านอิฐ",
          "postCode": [
            "14000"
          ]
        },
        {
          "id": 150105,
          "name": "บ้านแห",
          "postCode": [
            "14000"
          ]
        },
        {
          "id": 150104,
          "name": "ป่างิ้ว",
          "postCode": [
            "14000"
          ]
        },
        {
          "id": 150107,
          "name": "มหาดไทย",
          "postCode": [
            "14000"
          ]
        },
        {
          "id": 150114,
          "name": "ย่านซื่อ",
          "postCode": [
            "14000"
          ]
        },
        {
          "id": 150103,
          "name": "ศาลาแดง",
          "postCode": [
            "14000"
          ]
        },
        {
          "id": 150109,
          "name": "หัวไผ่",
          "postCode": [
            "14000"
          ]
        },
        {
          "id": 150111,
          "name": "โพสะ",
          "postCode": [
            "14000"
          ]
        }
      ]
    },
    {
      "city": "แสวงหา",
      "cityCode": 1505,
      "provinceCode": 15,
      "subDistrict": [
        {
          "id": 150507,
          "name": "จำลอง",
          "postCode": [
            "14150"
          ]
        },
        {
          "id": 150503,
          "name": "บ้านพราน",
          "postCode": [
            "14150"
          ]
        },
        {
          "id": 150504,
          "name": "วังน้ำเย็น",
          "postCode": [
            "14150"
          ]
        },
        {
          "id": 150502,
          "name": "ศรีพราน",
          "postCode": [
            "14150"
          ]
        },
        {
          "id": 150505,
          "name": "สีบัวทอง",
          "postCode": [
            "14150"
          ]
        },
        {
          "id": 150506,
          "name": "ห้วยไผ่",
          "postCode": [
            "14150"
          ]
        },
        {
          "id": 150501,
          "name": "แสวงหา",
          "postCode": [
            "14150"
          ]
        }
      ]
    },
    {
      "city": "โพธิ์ทอง",
      "cityCode": 1504,
      "provinceCode": 15,
      "subDistrict": [
        {
          "id": 150415,
          "name": "คำหยาด",
          "postCode": [
            "14120"
          ]
        },
        {
          "id": 150412,
          "name": "ทางพระ",
          "postCode": [
            "14120"
          ]
        },
        {
          "id": 150403,
          "name": "บางพลับ",
          "postCode": [
            "14120"
          ]
        },
        {
          "id": 150406,
          "name": "บางระกำ",
          "postCode": [
            "14120"
          ]
        },
        {
          "id": 150414,
          "name": "บางเจ้าฉ่า",
          "postCode": [
            "14120"
          ]
        },
        {
          "id": 150411,
          "name": "บ่อแร่",
          "postCode": [
            "14120"
          ]
        },
        {
          "id": 150410,
          "name": "ยางช้าย",
          "postCode": [
            "14120"
          ]
        },
        {
          "id": 150405,
          "name": "รำมะสัก",
          "postCode": [
            "14120"
          ]
        },
        {
          "id": 150413,
          "name": "สามง่าม",
          "postCode": [
            "14120"
          ]
        },
        {
          "id": 150404,
          "name": "หนองแม่ไก่",
          "postCode": [
            "14120"
          ]
        },
        {
          "id": 150408,
          "name": "องครักษ์",
          "postCode": [
            "14120"
          ]
        },
        {
          "id": 150402,
          "name": "อินทประมูล",
          "postCode": [
            "14120"
          ]
        },
        {
          "id": 150401,
          "name": "อ่างแก้ว",
          "postCode": [
            "14120"
          ]
        },
        {
          "id": 150409,
          "name": "โคกพุทรา",
          "postCode": [
            "14120"
          ]
        },
        {
          "id": 150407,
          "name": "โพธิ์รังนก",
          "postCode": [
            "14120"
          ]
        }
      ]
    },
    {
      "city": "ไชโย",
      "cityCode": 1502,
      "provinceCode": 15,
      "subDistrict": [
        {
          "id": 150201,
          "name": "จรเข้ร้อง",
          "postCode": [
            "14140"
          ]
        },
        {
          "id": 150208,
          "name": "ชะไว",
          "postCode": [
            "14140"
          ]
        },
        {
          "id": 150203,
          "name": "ชัยฤทธิ์",
          "postCode": [
            "14140"
          ]
        },
        {
          "id": 150209,
          "name": "ตรีณรงค์",
          "postCode": [
            "14140"
          ]
        },
        {
          "id": 150205,
          "name": "ราชสถิตย์",
          "postCode": [
            "14140"
          ]
        },
        {
          "id": 150207,
          "name": "หลักฟ้า",
          "postCode": [
            "14140"
          ]
        },
        {
          "id": 150204,
          "name": "เทวราช",
          "postCode": [
            "14140"
          ]
        },
        {
          "id": 150202,
          "name": "ไชยภูมิ",
          "postCode": [
            "14140"
          ]
        },
        {
          "id": 150206,
          "name": "ไชโย",
          "postCode": [
            "14140"
          ]
        }
      ]
    },
    {
      "city": "ขุนตาล",
      "cityCode": 5714,
      "provinceCode": 57,
      "subDistrict": [
        {
          "id": 571401,
          "name": "ต้า",
          "postCode": [
            "57340"
          ]
        },
        {
          "id": 571402,
          "name": "ป่าตาล",
          "postCode": [
            "57340"
          ]
        },
        {
          "id": 571403,
          "name": "ยางฮอม",
          "postCode": [
            "57340"
          ]
        }
      ]
    },
    {
      "city": "ดอยหลวง",
      "cityCode": 5718,
      "provinceCode": 57,
      "subDistrict": [
        {
          "id": 571801,
          "name": "ปงน้อย",
          "postCode": [
            "57110"
          ]
        },
        {
          "id": 571803,
          "name": "หนองป่าก่อ",
          "postCode": [
            "57110"
          ]
        },
        {
          "id": 571802,
          "name": "โชคชัย",
          "postCode": [
            "57110"
          ]
        }
      ]
    },
    {
      "city": "ป่าแดด",
      "cityCode": 5706,
      "provinceCode": 57,
      "subDistrict": [
        {
          "id": 570602,
          "name": "ป่าแงะ",
          "postCode": [
            "57190"
          ]
        },
        {
          "id": 570601,
          "name": "ป่าแดด",
          "postCode": [
            "57190"
          ]
        },
        {
          "id": 570606,
          "name": "ศรีโพธิ์เงิน",
          "postCode": [
            "57190"
          ]
        },
        {
          "id": 570603,
          "name": "สันมะค่า",
          "postCode": [
            "57190"
          ]
        },
        {
          "id": 570605,
          "name": "โรงช้าง",
          "postCode": [
            "57190"
          ]
        }
      ]
    },
    {
      "city": "พญาเม็งราย",
      "cityCode": 5712,
      "provinceCode": 57,
      "subDistrict": [
        {
          "id": 571205,
          "name": "ตาดควัน",
          "postCode": [
            "57290"
          ]
        },
        {
          "id": 571204,
          "name": "เม็งราย",
          "postCode": [
            "57290"
          ]
        },
        {
          "id": 571202,
          "name": "แม่ต๋ำ",
          "postCode": [
            "57290"
          ]
        },
        {
          "id": 571201,
          "name": "แม่เปา",
          "postCode": [
            "57290"
          ]
        },
        {
          "id": 571203,
          "name": "ไม้ยา",
          "postCode": [
            "57290"
          ]
        }
      ]
    },
    {
      "city": "พาน",
      "cityCode": 5705,
      "provinceCode": 57,
      "subDistrict": [
        {
          "id": 570505,
          "name": "ดอยงาม",
          "postCode": [
            "57120"
          ]
        },
        {
          "id": 570510,
          "name": "ทรายขาว",
          "postCode": [
            "57120"
          ]
        },
        {
          "id": 570514,
          "name": "ทานตะวัน",
          "postCode": [
            "57280"
          ]
        },
        {
          "id": 570503,
          "name": "ธารทอง",
          "postCode": [
            "57250"
          ]
        },
        {
          "id": 570508,
          "name": "ป่าหุ่ง",
          "postCode": [
            "57120"
          ]
        },
        {
          "id": 570509,
          "name": "ม่วงคำ",
          "postCode": [
            "57120"
          ]
        },
        {
          "id": 570511,
          "name": "สันกลาง",
          "postCode": [
            "57120"
          ]
        },
        {
          "id": 570504,
          "name": "สันติสุข",
          "postCode": [
            "57120"
          ]
        },
        {
          "id": 570501,
          "name": "สันมะเค็ด",
          "postCode": [
            "57120"
          ]
        },
        {
          "id": 570506,
          "name": "หัวง้ม",
          "postCode": [
            "57120"
          ]
        },
        {
          "id": 570507,
          "name": "เจริญเมือง",
          "postCode": [
            "57120"
          ]
        },
        {
          "id": 570513,
          "name": "เมืองพาน",
          "postCode": [
            "57120"
          ]
        },
        {
          "id": 570515,
          "name": "เวียงห้าว",
          "postCode": [
            "57120"
          ]
        },
        {
          "id": 570502,
          "name": "แม่อ้อ",
          "postCode": [
            "57120"
          ]
        },
        {
          "id": 570512,
          "name": "แม่เย็น",
          "postCode": [
            "57280"
          ]
        }
      ]
    },
    {
      "city": "เชียงของ",
      "cityCode": 5703,
      "provinceCode": 57,
      "subDistrict": [
        {
          "id": 570303,
          "name": "ครึ่ง",
          "postCode": [
            "57140"
          ]
        },
        {
          "id": 570304,
          "name": "บุญเรือง",
          "postCode": [
            "57140"
          ]
        },
        {
          "id": 570310,
          "name": "ริมโขง",
          "postCode": [
            "57140"
          ]
        },
        {
          "id": 570308,
          "name": "ศรีดอนชัย",
          "postCode": [
            "57140"
          ]
        },
        {
          "id": 570302,
          "name": "สถาน",
          "postCode": [
            "57140"
          ]
        },
        {
          "id": 570305,
          "name": "ห้วยซ้อ",
          "postCode": [
            "57140"
          ]
        },
        {
          "id": 570301,
          "name": "เวียง",
          "postCode": [
            "57140"
          ]
        }
      ]
    },
    {
      "city": "เชียงแสน",
      "cityCode": 5708,
      "provinceCode": 57,
      "subDistrict": [
        {
          "id": 570803,
          "name": "บ้านแซว",
          "postCode": [
            "57150"
          ]
        },
        {
          "id": 570802,
          "name": "ป่าสัก",
          "postCode": [
            "57150"
          ]
        },
        {
          "id": 570804,
          "name": "ศรีดอนมูล",
          "postCode": [
            "57150"
          ]
        },
        {
          "id": 570801,
          "name": "เวียง",
          "postCode": [
            "57150"
          ]
        },
        {
          "id": 570805,
          "name": "แม่เงิน",
          "postCode": [
            "57150"
          ]
        },
        {
          "id": 570806,
          "name": "โยนก",
          "postCode": [
            "57150"
          ]
        }
      ]
    },
    {
      "city": "เทิง",
      "cityCode": 5704,
      "provinceCode": 57,
      "subDistrict": [
        {
          "id": 570402,
          "name": "งิ้ว",
          "postCode": [
            "57160",
            "57230"
          ]
        },
        {
          "id": 570409,
          "name": "ตับเต่า",
          "postCode": [
            "57160"
          ]
        },
        {
          "id": 570403,
          "name": "ปล้อง",
          "postCode": [
            "57230"
          ]
        },
        {
          "id": 570412,
          "name": "ศรีดอนไชย",
          "postCode": [
            "57230"
          ]
        },
        {
          "id": 570411,
          "name": "สันทรายงาม",
          "postCode": [
            "57160"
          ]
        },
        {
          "id": 570410,
          "name": "หงาว",
          "postCode": [
            "57160"
          ]
        },
        {
          "id": 570413,
          "name": "หนองแรด",
          "postCode": [
            "57230"
          ]
        },
        {
          "id": 570405,
          "name": "เชียงเคี่ยน",
          "postCode": [
            "57230"
          ]
        },
        {
          "id": 570401,
          "name": "เวียง",
          "postCode": [
            "57160"
          ]
        },
        {
          "id": 570404,
          "name": "แม่ลอย",
          "postCode": [
            "57230"
          ]
        }
      ]
    },
    {
      "city": "เมืองเชียงราย",
      "cityCode": 5701,
      "provinceCode": 57,
      "subDistrict": [
        {
          "id": 570115,
          "name": "ดอยลาน",
          "postCode": [
            "57000"
          ]
        },
        {
          "id": 570120,
          "name": "ดอยฮาง",
          "postCode": [
            "57000"
          ]
        },
        {
          "id": 570118,
          "name": "ท่าสาย",
          "postCode": [
            "57000"
          ]
        },
        {
          "id": 570121,
          "name": "ท่าสุด",
          "postCode": [
            "57100"
          ]
        },
        {
          "id": 570104,
          "name": "นางแล",
          "postCode": [
            "57100"
          ]
        },
        {
          "id": 570103,
          "name": "บ้านดู่",
          "postCode": [
            "57100"
          ]
        },
        {
          "id": 570116,
          "name": "ป่าอ้อดอนชัย",
          "postCode": [
            "57000"
          ]
        },
        {
          "id": 570102,
          "name": "รอบเวียง",
          "postCode": [
            "57000"
          ]
        },
        {
          "id": 570114,
          "name": "ริมกก",
          "postCode": [
            "57100"
          ]
        },
        {
          "id": 570107,
          "name": "สันทราย",
          "postCode": [
            "57000"
          ]
        },
        {
          "id": 570112,
          "name": "ห้วยชมภู",
          "postCode": [
            "57000"
          ]
        },
        {
          "id": 570113,
          "name": "ห้วยสัก",
          "postCode": [
            "57000"
          ]
        },
        {
          "id": 570101,
          "name": "เวียง",
          "postCode": [
            "57000"
          ]
        },
        {
          "id": 570111,
          "name": "แม่กรณ์",
          "postCode": [
            "57000"
          ]
        },
        {
          "id": 570105,
          "name": "แม่ข้าวต้ม",
          "postCode": [
            "57100"
          ]
        },
        {
          "id": 570106,
          "name": "แม่ยาว",
          "postCode": [
            "57100"
          ]
        }
      ]
    },
    {
      "city": "เวียงชัย",
      "cityCode": 5702,
      "provinceCode": 57,
      "subDistrict": [
        {
          "id": 570206,
          "name": "ดอนศิลา",
          "postCode": [
            "57210"
          ]
        },
        {
          "id": 570203,
          "name": "ผางาม",
          "postCode": [
            "57210"
          ]
        },
        {
          "id": 570208,
          "name": "เมืองชุม",
          "postCode": [
            "57210"
          ]
        },
        {
          "id": 570202,
          "name": "เวียงชัย",
          "postCode": [
            "57210"
          ]
        },
        {
          "id": 570204,
          "name": "เวียงเหนือ",
          "postCode": [
            "57210"
          ]
        }
      ]
    },
    {
      "city": "เวียงป่าเป้า",
      "cityCode": 5711,
      "provinceCode": 57,
      "subDistrict": [
        {
          "id": 571103,
          "name": "บ้านโป่ง",
          "postCode": [
            "57170"
          ]
        },
        {
          "id": 571104,
          "name": "ป่างิ้ว",
          "postCode": [
            "57170"
          ]
        },
        {
          "id": 571101,
          "name": "สันสลี",
          "postCode": [
            "57170"
          ]
        },
        {
          "id": 571102,
          "name": "เวียง",
          "postCode": [
            "57170"
          ]
        },
        {
          "id": 571105,
          "name": "เวียงกาหลง",
          "postCode": [
            "57260"
          ]
        },
        {
          "id": 571106,
          "name": "แม่เจดีย์",
          "postCode": [
            "57260"
          ]
        },
        {
          "id": 571107,
          "name": "แม่เจดีย์ใหม่",
          "postCode": [
            "57260"
          ]
        }
      ]
    },
    {
      "city": "เวียงเชียงรุ้ง",
      "cityCode": 5717,
      "provinceCode": 57,
      "subDistrict": [
        {
          "id": 571702,
          "name": "ดงมหาวัน",
          "postCode": [
            "57210"
          ]
        },
        {
          "id": 571701,
          "name": "ทุ่งก่อ",
          "postCode": [
            "57210"
          ]
        },
        {
          "id": 571703,
          "name": "ป่าซาง",
          "postCode": [
            "57210"
          ]
        }
      ]
    },
    {
      "city": "เวียงแก่น",
      "cityCode": 5713,
      "provinceCode": 57,
      "subDistrict": [
        {
          "id": 571304,
          "name": "ท่าข้าม",
          "postCode": [
            "57310"
          ]
        },
        {
          "id": 571302,
          "name": "ปอ",
          "postCode": [
            "57310"
          ]
        },
        {
          "id": 571301,
          "name": "ม่วงยาย",
          "postCode": [
            "57310"
          ]
        },
        {
          "id": 571303,
          "name": "หล่ายงาว",
          "postCode": [
            "57310"
          ]
        }
      ]
    },
    {
      "city": "แม่จัน",
      "cityCode": 5707,
      "provinceCode": 57,
      "subDistrict": [
        {
          "id": 570713,
          "name": "จอมสวรรค์",
          "postCode": [
            "57110"
          ]
        },
        {
          "id": 570702,
          "name": "จันจว้า",
          "postCode": [
            "57270"
          ]
        },
        {
          "id": 570712,
          "name": "จันจว้าใต้",
          "postCode": [
            "57270"
          ]
        },
        {
          "id": 570706,
          "name": "ท่าข้าวเปลือก",
          "postCode": [
            "57110"
          ]
        },
        {
          "id": 570704,
          "name": "ป่าซาง",
          "postCode": [
            "57110"
          ]
        },
        {
          "id": 570708,
          "name": "ป่าตึง",
          "postCode": [
            "57110"
          ]
        },
        {
          "id": 570711,
          "name": "ศรีค้ำ",
          "postCode": [
            "57110"
          ]
        },
        {
          "id": 570705,
          "name": "สันทราย",
          "postCode": [
            "57110"
          ]
        },
        {
          "id": 570703,
          "name": "แม่คำ",
          "postCode": [
            "57240"
          ]
        },
        {
          "id": 570701,
          "name": "แม่จัน",
          "postCode": [
            "57110"
          ]
        },
        {
          "id": 570710,
          "name": "แม่ไร่",
          "postCode": [
            "57240"
          ]
        }
      ]
    },
    {
      "city": "แม่ฟ้าหลวง",
      "cityCode": 5715,
      "provinceCode": 57,
      "subDistrict": [
        {
          "id": 571501,
          "name": "เทอดไทย",
          "postCode": [
            "57240"
          ]
        },
        {
          "id": 571504,
          "name": "แม่ฟ้าหลวง",
          "postCode": [
            "57240"
          ]
        },
        {
          "id": 571503,
          "name": "แม่สลองนอก",
          "postCode": [
            "57110"
          ]
        },
        {
          "id": 571502,
          "name": "แม่สลองใน",
          "postCode": [
            "57110"
          ]
        }
      ]
    },
    {
      "city": "แม่ลาว",
      "cityCode": 5716,
      "provinceCode": 57,
      "subDistrict": [
        {
          "id": 571602,
          "name": "จอมหมอกแก้ว",
          "postCode": [
            "57250"
          ]
        },
        {
          "id": 571601,
          "name": "ดงมะดะ",
          "postCode": [
            "57250"
          ]
        },
        {
          "id": 571603,
          "name": "บัวสลี",
          "postCode": [
            "57250"
          ]
        },
        {
          "id": 571604,
          "name": "ป่าก่อดำ",
          "postCode": [
            "57250"
          ]
        },
        {
          "id": 571605,
          "name": "โป่งแพร่",
          "postCode": [
            "57000"
          ]
        }
      ]
    },
    {
      "city": "แม่สรวย",
      "cityCode": 5710,
      "provinceCode": 57,
      "subDistrict": [
        {
          "id": 571005,
          "name": "ท่าก๊อ",
          "postCode": [
            "57180"
          ]
        },
        {
          "id": 571002,
          "name": "ป่าแดด",
          "postCode": [
            "57180"
          ]
        },
        {
          "id": 571006,
          "name": "วาวี",
          "postCode": [
            "57180"
          ]
        },
        {
          "id": 571004,
          "name": "ศรีถ้อย",
          "postCode": [
            "57180"
          ]
        },
        {
          "id": 571007,
          "name": "เจดีย์หลวง",
          "postCode": [
            "57180"
          ]
        },
        {
          "id": 571003,
          "name": "แม่พริก",
          "postCode": [
            "57180"
          ]
        },
        {
          "id": 571001,
          "name": "แม่สรวย",
          "postCode": [
            "57180"
          ]
        }
      ]
    },
    {
      "city": "แม่สาย",
      "cityCode": 5709,
      "provinceCode": 57,
      "subDistrict": [
        {
          "id": 570908,
          "name": "บ้านด้าย",
          "postCode": [
            "57220"
          ]
        },
        {
          "id": 570905,
          "name": "ศรีเมืองชุม",
          "postCode": [
            "57130"
          ]
        },
        {
          "id": 570902,
          "name": "ห้วยไคร้",
          "postCode": [
            "57220"
          ]
        },
        {
          "id": 570903,
          "name": "เกาะช้าง",
          "postCode": [
            "57130"
          ]
        },
        {
          "id": 570906,
          "name": "เวียงพางคำ",
          "postCode": [
            "57130"
          ]
        },
        {
          "id": 570901,
          "name": "แม่สาย",
          "postCode": [
            "57130"
          ]
        },
        {
          "id": 570909,
          "name": "โป่งงาม",
          "postCode": [
            "57130"
          ]
        },
        {
          "id": 570904,
          "name": "โป่งผา",
          "postCode": [
            "57130"
          ]
        }
      ]
    },
    {
      "city": "กัลยาณิวัฒนา",
      "cityCode": 5025,
      "provinceCode": 50,
      "subDistrict": [
        {
          "id": 502501,
          "name": "บ้านจันทร์",
          "postCode": [
            "58130"
          ]
        },
        {
          "id": 502503,
          "name": "แจ่มหลวง",
          "postCode": [
            "58130"
          ]
        },
        {
          "id": 502502,
          "name": "แม่แดด",
          "postCode": [
            "58130"
          ]
        }
      ]
    },
    {
      "city": "จอมทอง",
      "cityCode": 5002,
      "provinceCode": 50,
      "subDistrict": [
        {
          "id": 500204,
          "name": "ข่วงเปา",
          "postCode": [
            "50160"
          ]
        },
        {
          "id": 500207,
          "name": "ดอยแก้ว",
          "postCode": [
            "50160"
          ]
        },
        {
          "id": 500203,
          "name": "บ้านหลวง",
          "postCode": [
            "50160"
          ]
        },
        {
          "id": 500206,
          "name": "บ้านแปะ",
          "postCode": [
            "50240"
          ]
        },
        {
          "id": 500205,
          "name": "สบเตี๊ยะ",
          "postCode": [
            "50160"
          ]
        },
        {
          "id": 500209,
          "name": "แม่สอย",
          "postCode": [
            "50160",
            "50240"
          ]
        }
      ]
    },
    {
      "city": "ดอยสะเก็ด",
      "cityCode": 5005,
      "provinceCode": 50,
      "subDistrict": [
        {
          "id": 500507,
          "name": "ตลาดขวัญ",
          "postCode": [
            "50220"
          ]
        },
        {
          "id": 500510,
          "name": "ตลาดใหญ่",
          "postCode": [
            "50220"
          ]
        },
        {
          "id": 500504,
          "name": "ป่าป้อง",
          "postCode": [
            "50220"
          ]
        },
        {
          "id": 500506,
          "name": "ป่าลาน",
          "postCode": [
            "50220"
          ]
        },
        {
          "id": 500513,
          "name": "ป่าเมี่ยง",
          "postCode": [
            "50220"
          ]
        },
        {
          "id": 500503,
          "name": "ลวงเหนือ",
          "postCode": [
            "50220"
          ]
        },
        {
          "id": 500505,
          "name": "สง่าบ้าน",
          "postCode": [
            "50220"
          ]
        },
        {
          "id": 500502,
          "name": "สันปูเลย",
          "postCode": [
            "50220"
          ]
        },
        {
          "id": 500508,
          "name": "สำราญราษฎร์",
          "postCode": [
            "50220"
          ]
        },
        {
          "id": 500501,
          "name": "เชิงดอย",
          "postCode": [
            "50220"
          ]
        },
        {
          "id": 500514,
          "name": "เทพเสด็จ",
          "postCode": [
            "50220"
          ]
        },
        {
          "id": 500509,
          "name": "แม่คือ",
          "postCode": [
            "50220"
          ]
        },
        {
          "id": 500511,
          "name": "แม่ฮ้อยเงิน",
          "postCode": [
            "50220"
          ]
        },
        {
          "id": 500512,
          "name": "แม่โป่ง",
          "postCode": [
            "50220"
          ]
        }
      ]
    },
    {
      "city": "ดอยหล่อ",
      "cityCode": 5024,
      "provinceCode": 50,
      "subDistrict": [
        {
          "id": 502401,
          "name": "ดอยหล่อ",
          "postCode": [
            "50160"
          ]
        },
        {
          "id": 502403,
          "name": "ยางคราม",
          "postCode": [
            "50160"
          ]
        },
        {
          "id": 502402,
          "name": "สองแคว",
          "postCode": [
            "50160"
          ]
        },
        {
          "id": 502404,
          "name": "สันติสุข",
          "postCode": [
            "50160"
          ]
        }
      ]
    },
    {
      "city": "ดอยเต่า",
      "cityCode": 5017,
      "provinceCode": 50,
      "subDistrict": [
        {
          "id": 501701,
          "name": "ดอยเต่า",
          "postCode": [
            "50260"
          ]
        },
        {
          "id": 501702,
          "name": "ท่าเดื่อ",
          "postCode": [
            "50260"
          ]
        },
        {
          "id": 501705,
          "name": "บงตัน",
          "postCode": [
            "50260"
          ]
        },
        {
          "id": 501704,
          "name": "บ้านแอ่น",
          "postCode": [
            "50260"
          ]
        },
        {
          "id": 501703,
          "name": "มืดกา",
          "postCode": [
            "50260"
          ]
        },
        {
          "id": 501706,
          "name": "โปงทุ่ง",
          "postCode": [
            "50260"
          ]
        }
      ]
    },
    {
      "city": "ฝาง",
      "cityCode": 5009,
      "provinceCode": 50,
      "subDistrict": [
        {
          "id": 500903,
          "name": "ม่อนปิ่น",
          "postCode": [
            "50110"
          ]
        },
        {
          "id": 500906,
          "name": "สันทราย",
          "postCode": [
            "50110"
          ]
        },
        {
          "id": 500901,
          "name": "เวียง",
          "postCode": [
            "50110"
          ]
        },
        {
          "id": 500911,
          "name": "แม่ข่า",
          "postCode": [
            "50320"
          ]
        },
        {
          "id": 500910,
          "name": "แม่คะ",
          "postCode": [
            "50110"
          ]
        },
        {
          "id": 500904,
          "name": "แม่งอน",
          "postCode": [
            "50320"
          ]
        },
        {
          "id": 500905,
          "name": "แม่สูน",
          "postCode": [
            "50110"
          ]
        },
        {
          "id": 500912,
          "name": "โป่งน้ำร้อน",
          "postCode": [
            "50110"
          ]
        }
      ]
    },
    {
      "city": "พร้าว",
      "cityCode": 5011,
      "provinceCode": 50,
      "subDistrict": [
        {
          "id": 501102,
          "name": "ทุ่งหลวง",
          "postCode": [
            "50190"
          ]
        },
        {
          "id": 501107,
          "name": "น้ำแพร่",
          "postCode": [
            "50190"
          ]
        },
        {
          "id": 501106,
          "name": "บ้านโป่ง",
          "postCode": [
            "50190"
          ]
        },
        {
          "id": 501103,
          "name": "ป่าตุ้ม",
          "postCode": [
            "50190"
          ]
        },
        {
          "id": 501104,
          "name": "ป่าไหน่",
          "postCode": [
            "50190"
          ]
        },
        {
          "id": 501105,
          "name": "สันทราย",
          "postCode": [
            "50190"
          ]
        },
        {
          "id": 501108,
          "name": "เขื่อนผาก",
          "postCode": [
            "50190"
          ]
        },
        {
          "id": 501101,
          "name": "เวียง",
          "postCode": [
            "50190"
          ]
        },
        {
          "id": 501110,
          "name": "แม่ปั๋ง",
          "postCode": [
            "50190"
          ]
        },
        {
          "id": 501109,
          "name": "แม่แวน",
          "postCode": [
            "50190"
          ]
        },
        {
          "id": 501111,
          "name": "โหล่งขอด",
          "postCode": [
            "50190"
          ]
        }
      ]
    },
    {
      "city": "สะเมิง",
      "cityCode": 5008,
      "provinceCode": 50,
      "subDistrict": [
        {
          "id": 500804,
          "name": "บ่อแก้ว",
          "postCode": [
            "50250"
          ]
        },
        {
          "id": 500805,
          "name": "ยั้งเมิน",
          "postCode": [
            "50250"
          ]
        },
        {
          "id": 500802,
          "name": "สะเมิงเหนือ",
          "postCode": [
            "50250"
          ]
        },
        {
          "id": 500801,
          "name": "สะเมิงใต้",
          "postCode": [
            "50250"
          ]
        },
        {
          "id": 500803,
          "name": "แม่สาบ",
          "postCode": [
            "50250"
          ]
        }
      ]
    },
    {
      "city": "สันกำแพง",
      "cityCode": 5013,
      "provinceCode": 50,
      "subDistrict": [
        {
          "id": 501312,
          "name": "ต้นเปา",
          "postCode": [
            "50130"
          ]
        },
        {
          "id": 501302,
          "name": "ทรายมูล",
          "postCode": [
            "50130"
          ]
        },
        {
          "id": 501304,
          "name": "บวกค้าง",
          "postCode": [
            "50130"
          ]
        },
        {
          "id": 501303,
          "name": "ร้องวัวแดง",
          "postCode": [
            "50130"
          ]
        },
        {
          "id": 501313,
          "name": "สันกลาง",
          "postCode": [
            "50130"
          ]
        },
        {
          "id": 501301,
          "name": "สันกำแพง",
          "postCode": [
            "50130"
          ]
        },
        {
          "id": 501311,
          "name": "ห้วยทราย",
          "postCode": [
            "50130"
          ]
        },
        {
          "id": 501306,
          "name": "ออนใต้",
          "postCode": [
            "50130"
          ]
        },
        {
          "id": 501305,
          "name": "แช่ช้าง",
          "postCode": [
            "50130"
          ]
        },
        {
          "id": 501310,
          "name": "แม่ปูคา",
          "postCode": [
            "50130"
          ]
        }
      ]
    },
    {
      "city": "สันทราย",
      "cityCode": 5014,
      "provinceCode": 50,
      "subDistrict": [
        {
          "id": 501412,
          "name": "ป่าไผ่",
          "postCode": [
            "50210"
          ]
        },
        {
          "id": 501402,
          "name": "สันทรายน้อย",
          "postCode": [
            "50210"
          ]
        },
        {
          "id": 501401,
          "name": "สันทรายหลวง",
          "postCode": [
            "50210"
          ]
        },
        {
          "id": 501404,
          "name": "สันนาเม็ง",
          "postCode": [
            "50210"
          ]
        },
        {
          "id": 501405,
          "name": "สันป่าเปา",
          "postCode": [
            "50210"
          ]
        },
        {
          "id": 501403,
          "name": "สันพระเนตร",
          "postCode": [
            "50210"
          ]
        },
        {
          "id": 501407,
          "name": "หนองจ๊อม",
          "postCode": [
            "50210",
            "50290"
          ]
        },
        {
          "id": 501408,
          "name": "หนองหาร",
          "postCode": [
            "50290"
          ]
        },
        {
          "id": 501406,
          "name": "หนองแหย่ง",
          "postCode": [
            "50210"
          ]
        },
        {
          "id": 501411,
          "name": "เมืองเล็น",
          "postCode": [
            "50210"
          ]
        },
        {
          "id": 501409,
          "name": "แม่แฝก",
          "postCode": [
            "50290"
          ]
        },
        {
          "id": 501410,
          "name": "แม่แฝกใหม่",
          "postCode": [
            "50290"
          ]
        }
      ]
    },
    {
      "city": "สันป่าตอง",
      "cityCode": 5012,
      "provinceCode": 50,
      "subDistrict": [
        {
          "id": 501210,
          "name": "ทุ่งต้อม",
          "postCode": [
            "50120"
          ]
        },
        {
          "id": 501208,
          "name": "ทุ่งสะโตก",
          "postCode": [
            "50120"
          ]
        },
        {
          "id": 501203,
          "name": "ท่าวังพร้าว",
          "postCode": [
            "50120"
          ]
        },
        {
          "id": 501214,
          "name": "น้ำบ่อหลวง",
          "postCode": [
            "50120"
          ]
        },
        {
          "id": 501207,
          "name": "บ้านกลาง",
          "postCode": [
            "50120"
          ]
        },
        {
          "id": 501206,
          "name": "บ้านแม",
          "postCode": [
            "50120"
          ]
        },
        {
          "id": 501204,
          "name": "มะขามหลวง",
          "postCode": [
            "50120"
          ]
        },
        {
          "id": 501215,
          "name": "มะขุนหวาน",
          "postCode": [
            "50120"
          ]
        },
        {
          "id": 501201,
          "name": "ยุหว่า",
          "postCode": [
            "50120"
          ]
        },
        {
          "id": 501202,
          "name": "สันกลาง",
          "postCode": [
            "50120"
          ]
        },
        {
          "id": 501205,
          "name": "แม่ก๊า",
          "postCode": [
            "50120"
          ]
        }
      ]
    },
    {
      "city": "สารภี",
      "cityCode": 5019,
      "provinceCode": 50,
      "subDistrict": [
        {
          "id": 501905,
          "name": "ขัวมุง",
          "postCode": [
            "50140"
          ]
        },
        {
          "id": 501903,
          "name": "ชมภู",
          "postCode": [
            "50140"
          ]
        },
        {
          "id": 501909,
          "name": "ดอนแก้ว",
          "postCode": [
            "50140"
          ]
        },
        {
          "id": 501908,
          "name": "ท่ากว้าง",
          "postCode": [
            "50140"
          ]
        },
        {
          "id": 501910,
          "name": "ท่าวังตาล",
          "postCode": [
            "50140"
          ]
        },
        {
          "id": 501912,
          "name": "ป่าบง",
          "postCode": [
            "50140"
          ]
        },
        {
          "id": 501901,
          "name": "ยางเนิ้ง",
          "postCode": [
            "50140"
          ]
        },
        {
          "id": 501911,
          "name": "สันทราย",
          "postCode": [
            "50140"
          ]
        },
        {
          "id": 501902,
          "name": "สารภี",
          "postCode": [
            "50140"
          ]
        },
        {
          "id": 501907,
          "name": "หนองผึ้ง",
          "postCode": [
            "50140"
          ]
        },
        {
          "id": 501906,
          "name": "หนองแฝก",
          "postCode": [
            "50140"
          ]
        },
        {
          "id": 501904,
          "name": "ไชยสถาน",
          "postCode": [
            "50140"
          ]
        }
      ]
    },
    {
      "city": "หางดง",
      "cityCode": 5015,
      "provinceCode": 50,
      "subDistrict": [
        {
          "id": 501505,
          "name": "ขุนคง",
          "postCode": [
            "50230"
          ]
        },
        {
          "id": 501511,
          "name": "น้ำแพร่",
          "postCode": [
            "50230"
          ]
        },
        {
          "id": 501510,
          "name": "บ้านปง",
          "postCode": [
            "50230"
          ]
        },
        {
          "id": 501507,
          "name": "บ้านแหวน",
          "postCode": [
            "50230"
          ]
        },
        {
          "id": 501506,
          "name": "สบแม่ข่า",
          "postCode": [
            "50230"
          ]
        },
        {
          "id": 501508,
          "name": "สันผักหวาน",
          "postCode": [
            "50230"
          ]
        },
        {
          "id": 501509,
          "name": "หนองควาย",
          "postCode": [
            "50230"
          ]
        },
        {
          "id": 501504,
          "name": "หนองตอง",
          "postCode": [
            "50340"
          ]
        },
        {
          "id": 501502,
          "name": "หนองแก๋ว",
          "postCode": [
            "50230"
          ]
        },
        {
          "id": 501501,
          "name": "หางดง",
          "postCode": [
            "50230"
          ]
        },
        {
          "id": 501503,
          "name": "หารแก้ว",
          "postCode": [
            "50230"
          ]
        }
      ]
    },
    {
      "city": "อมก๋อย",
      "cityCode": 5018,
      "provinceCode": 50,
      "subDistrict": [
        {
          "id": 501806,
          "name": "นาเกียน",
          "postCode": [
            "50310"
          ]
        },
        {
          "id": 501804,
          "name": "ม่อนจอง",
          "postCode": [
            "50310"
          ]
        },
        {
          "id": 501802,
          "name": "ยางเปียง",
          "postCode": [
            "50310"
          ]
        },
        {
          "id": 501805,
          "name": "สบโขง",
          "postCode": [
            "50310"
          ]
        },
        {
          "id": 501801,
          "name": "อมก๋อย",
          "postCode": [
            "50310"
          ]
        },
        {
          "id": 501803,
          "name": "แม่ตื่น",
          "postCode": [
            "50310"
          ]
        }
      ]
    },
    {
      "city": "ฮอด",
      "cityCode": 5016,
      "provinceCode": 50,
      "subDistrict": [
        {
          "id": 501606,
          "name": "นาคอเรือ",
          "postCode": [
            "50240"
          ]
        },
        {
          "id": 501605,
          "name": "บ่อสลี",
          "postCode": [
            "50240"
          ]
        },
        {
          "id": 501604,
          "name": "บ่อหลวง",
          "postCode": [
            "50240"
          ]
        },
        {
          "id": 501603,
          "name": "บ้านตาล",
          "postCode": [
            "50240"
          ]
        },
        {
          "id": 501601,
          "name": "หางดง",
          "postCode": [
            "50240"
          ]
        },
        {
          "id": 501602,
          "name": "ฮอด",
          "postCode": [
            "50240"
          ]
        }
      ]
    },
    {
      "city": "เชียงดาว",
      "cityCode": 5004,
      "provinceCode": 50,
      "subDistrict": [
        {
          "id": 500407,
          "name": "ทุ่งข้าวพวง",
          "postCode": [
            "50170"
          ]
        },
        {
          "id": 500406,
          "name": "ปิงโค้ง",
          "postCode": [
            "50170"
          ]
        },
        {
          "id": 500401,
          "name": "เชียงดาว",
          "postCode": [
            "50170"
          ]
        },
        {
          "id": 500405,
          "name": "เมืองคอง",
          "postCode": [
            "50170"
          ]
        },
        {
          "id": 500403,
          "name": "เมืองงาย",
          "postCode": [
            "50170"
          ]
        },
        {
          "id": 500402,
          "name": "เมืองนะ",
          "postCode": [
            "50170"
          ]
        },
        {
          "id": 500404,
          "name": "แม่นะ",
          "postCode": [
            "50170"
          ]
        }
      ]
    },
    {
      "city": "เมืองเชียงใหม่",
      "cityCode": 5001,
      "provinceCode": 50,
      "subDistrict": [
        {
          "id": 500105,
          "name": "ช้างคลาน",
          "postCode": [
            "50100"
          ]
        },
        {
          "id": 500104,
          "name": "ช้างม่อย",
          "postCode": [
            "50300"
          ]
        },
        {
          "id": 500107,
          "name": "ช้างเผือก",
          "postCode": [
            "50300"
          ]
        },
        {
          "id": 500112,
          "name": "ท่าศาลา",
          "postCode": [
            "50000"
          ]
        },
        {
          "id": 500115,
          "name": "ป่าตัน",
          "postCode": [
            "50300"
          ]
        },
        {
          "id": 500110,
          "name": "ป่าแดด",
          "postCode": [
            "50100"
          ]
        },
        {
          "id": 500102,
          "name": "พระสิงห์",
          "postCode": [
            "50200"
          ]
        },
        {
          "id": 500114,
          "name": "ฟ้าฮ่าม",
          "postCode": [
            "50000"
          ]
        },
        {
          "id": 500106,
          "name": "วัดเกต",
          "postCode": [
            "50000"
          ]
        },
        {
          "id": 500101,
          "name": "ศรีภูมิ",
          "postCode": [
            "50200"
          ]
        },
        {
          "id": 500116,
          "name": "สันผีเสื้อ",
          "postCode": [
            "50290",
            "50300"
          ]
        },
        {
          "id": 500108,
          "name": "สุเทพ",
          "postCode": [
            "50200"
          ]
        },
        {
          "id": 500113,
          "name": "หนองป่าครั่ง",
          "postCode": [
            "50000"
          ]
        },
        {
          "id": 500111,
          "name": "หนองหอย",
          "postCode": [
            "50000"
          ]
        },
        {
          "id": 500103,
          "name": "หายยา",
          "postCode": [
            "50100"
          ]
        },
        {
          "id": 500109,
          "name": "แม่เหียะ",
          "postCode": [
            "50100"
          ]
        }
      ]
    },
    {
      "city": "เวียงแหง",
      "cityCode": 5020,
      "provinceCode": 50,
      "subDistrict": [
        {
          "id": 502002,
          "name": "เปียงหลวง",
          "postCode": [
            "50350"
          ]
        },
        {
          "id": 502001,
          "name": "เมืองแหง",
          "postCode": [
            "50350"
          ]
        },
        {
          "id": 502003,
          "name": "แสนไห",
          "postCode": [
            "50350"
          ]
        }
      ]
    },
    {
      "city": "แม่ริม",
      "cityCode": 5007,
      "provinceCode": 50,
      "subDistrict": [
        {
          "id": 500704,
          "name": "ขี้เหล็ก",
          "postCode": [
            "50180"
          ]
        },
        {
          "id": 500710,
          "name": "ดอนแก้ว",
          "postCode": [
            "50180"
          ]
        },
        {
          "id": 500702,
          "name": "ริมเหนือ",
          "postCode": [
            "50180"
          ]
        },
        {
          "id": 500701,
          "name": "ริมใต้",
          "postCode": [
            "50180"
          ]
        },
        {
          "id": 500705,
          "name": "สะลวง",
          "postCode": [
            "50330"
          ]
        },
        {
          "id": 500703,
          "name": "สันโป่ง",
          "postCode": [
            "50180"
          ]
        },
        {
          "id": 500706,
          "name": "ห้วยทราย",
          "postCode": [
            "50180"
          ]
        },
        {
          "id": 500711,
          "name": "เหมืองแก้ว",
          "postCode": [
            "50180"
          ]
        },
        {
          "id": 500709,
          "name": "แม่สา",
          "postCode": [
            "50180"
          ]
        },
        {
          "id": 500707,
          "name": "แม่แรม",
          "postCode": [
            "50180"
          ]
        },
        {
          "id": 500708,
          "name": "โป่งแยง",
          "postCode": [
            "50180"
          ]
        }
      ]
    },
    {
      "city": "แม่วาง",
      "cityCode": 5022,
      "provinceCode": 50,
      "subDistrict": [
        {
          "id": 502205,
          "name": "ดอนเปา",
          "postCode": [
            "50360"
          ]
        },
        {
          "id": 502202,
          "name": "ทุ่งปี๊",
          "postCode": [
            "50360"
          ]
        },
        {
          "id": 502203,
          "name": "ทุ่งรวงทอง",
          "postCode": [
            "50360"
          ]
        },
        {
          "id": 502201,
          "name": "บ้านกาด",
          "postCode": [
            "50360"
          ]
        },
        {
          "id": 502204,
          "name": "แม่วิน",
          "postCode": [
            "50360"
          ]
        }
      ]
    },
    {
      "city": "แม่ออน",
      "cityCode": 5023,
      "provinceCode": 50,
      "subDistrict": [
        {
          "id": 502306,
          "name": "ทาเหนือ",
          "postCode": [
            "50130"
          ]
        },
        {
          "id": 502303,
          "name": "บ้านสหกรณ์",
          "postCode": [
            "50130"
          ]
        },
        {
          "id": 502304,
          "name": "ห้วยแก้ว",
          "postCode": [
            "50130"
          ]
        },
        {
          "id": 502302,
          "name": "ออนกลาง",
          "postCode": [
            "50130"
          ]
        },
        {
          "id": 502301,
          "name": "ออนเหนือ",
          "postCode": [
            "50130"
          ]
        },
        {
          "id": 502305,
          "name": "แม่ทา",
          "postCode": [
            "50130"
          ]
        }
      ]
    },
    {
      "city": "แม่อาย",
      "cityCode": 5010,
      "provinceCode": 50,
      "subDistrict": [
        {
          "id": 501005,
          "name": "ท่าตอน",
          "postCode": [
            "50280"
          ]
        },
        {
          "id": 501006,
          "name": "บ้านหลวง",
          "postCode": [
            "50280"
          ]
        },
        {
          "id": 501007,
          "name": "มะลิกา",
          "postCode": [
            "50280"
          ]
        },
        {
          "id": 501003,
          "name": "สันต้นหมื้อ",
          "postCode": [
            "50280"
          ]
        },
        {
          "id": 501004,
          "name": "แม่นาวาง",
          "postCode": [
            "50280"
          ]
        },
        {
          "id": 501002,
          "name": "แม่สาว",
          "postCode": [
            "50280"
          ]
        },
        {
          "id": 501001,
          "name": "แม่อาย",
          "postCode": [
            "50280"
          ]
        }
      ]
    },
    {
      "city": "แม่แจ่ม",
      "cityCode": 5003,
      "provinceCode": 50,
      "subDistrict": [
        {
          "id": 500308,
          "name": "กองแขก",
          "postCode": [
            "50270"
          ]
        },
        {
          "id": 500301,
          "name": "ช่างเคิ่ง",
          "postCode": [
            "50270"
          ]
        },
        {
          "id": 500302,
          "name": "ท่าผา",
          "postCode": [
            "50270"
          ]
        },
        {
          "id": 500303,
          "name": "บ้านทับ",
          "postCode": [
            "50270"
          ]
        },
        {
          "id": 500307,
          "name": "ปางหินฝน",
          "postCode": [
            "50270"
          ]
        },
        {
          "id": 500305,
          "name": "แม่นาจร",
          "postCode": [
            "50270"
          ]
        },
        {
          "id": 500304,
          "name": "แม่ศึก",
          "postCode": [
            "50270"
          ]
        }
      ]
    },
    {
      "city": "แม่แตง",
      "cityCode": 5006,
      "provinceCode": 50,
      "subDistrict": [
        {
          "id": 500612,
          "name": "กื้ดช้าง",
          "postCode": [
            "50150"
          ]
        },
        {
          "id": 500603,
          "name": "ขี้เหล็ก",
          "postCode": [
            "50150"
          ]
        },
        {
          "id": 500604,
          "name": "ช่อแล",
          "postCode": [
            "50150"
          ]
        },
        {
          "id": 500611,
          "name": "บ้านช้าง",
          "postCode": [
            "50150"
          ]
        },
        {
          "id": 500607,
          "name": "บ้านเป้า",
          "postCode": [
            "50150"
          ]
        },
        {
          "id": 500609,
          "name": "ป่าแป๋",
          "postCode": [
            "50150"
          ]
        },
        {
          "id": 500606,
          "name": "สบเปิง",
          "postCode": [
            "50150",
            "50330"
          ]
        },
        {
          "id": 500608,
          "name": "สันป่ายาง",
          "postCode": [
            "50330"
          ]
        },
        {
          "id": 500601,
          "name": "สันมหาพน",
          "postCode": [
            "50150"
          ]
        },
        {
          "id": 500613,
          "name": "อินทขิล",
          "postCode": [
            "50150"
          ]
        },
        {
          "id": 500610,
          "name": "เมืองก๋าย",
          "postCode": [
            "50150"
          ]
        },
        {
          "id": 500605,
          "name": "แม่หอพระ",
          "postCode": [
            "50150"
          ]
        },
        {
          "id": 500602,
          "name": "แม่แตง",
          "postCode": [
            "50150"
          ]
        }
      ]
    },
    {
      "city": "ไชยปราการ",
      "cityCode": 5021,
      "provinceCode": 50,
      "subDistrict": [
        {
          "id": 502101,
          "name": "ปงตำ",
          "postCode": [
            "50320"
          ]
        },
        {
          "id": 502102,
          "name": "ศรีดงเย็น",
          "postCode": [
            "50320"
          ]
        },
        {
          "id": 502104,
          "name": "หนองบัว",
          "postCode": [
            "50320"
          ]
        },
        {
          "id": 502103,
          "name": "แม่ทะลบ",
          "postCode": [
            "50320"
          ]
        }
      ]
    },
    {
      "city": "ชะอำ",
      "cityCode": 7604,
      "provinceCode": 76,
      "subDistrict": [
        {
          "id": 760401,
          "name": "ชะอำ",
          "postCode": [
            "76120"
          ]
        },
        {
          "id": 760409,
          "name": "ดอนขุนห้วย",
          "postCode": [
            "76120"
          ]
        },
        {
          "id": 760403,
          "name": "นายาง",
          "postCode": [
            "76120"
          ]
        },
        {
          "id": 760402,
          "name": "บางเก่า",
          "postCode": [
            "76120"
          ]
        },
        {
          "id": 760408,
          "name": "สามพระยา",
          "postCode": [
            "76120"
          ]
        },
        {
          "id": 760405,
          "name": "หนองศาลา",
          "postCode": [
            "76120"
          ]
        },
        {
          "id": 760406,
          "name": "ห้วยทรายเหนือ",
          "postCode": [
            "76120"
          ]
        },
        {
          "id": 760404,
          "name": "เขาใหญ่",
          "postCode": [
            "76120"
          ]
        },
        {
          "id": 760407,
          "name": "ไร่ใหม่พัฒนา",
          "postCode": [
            "76120"
          ]
        }
      ]
    },
    {
      "city": "ท่ายาง",
      "cityCode": 7605,
      "provinceCode": 76,
      "subDistrict": [
        {
          "id": 760511,
          "name": "กลัดหลวง",
          "postCode": [
            "76130"
          ]
        },
        {
          "id": 760502,
          "name": "ท่าคอย",
          "postCode": [
            "76130"
          ]
        },
        {
          "id": 760501,
          "name": "ท่ายาง",
          "postCode": [
            "76130"
          ]
        },
        {
          "id": 760514,
          "name": "ท่าแลง",
          "postCode": [
            "76130"
          ]
        },
        {
          "id": 760506,
          "name": "ท่าไม้รวก",
          "postCode": [
            "76130"
          ]
        },
        {
          "id": 760515,
          "name": "บ้านในดง",
          "postCode": [
            "76130"
          ]
        },
        {
          "id": 760512,
          "name": "ปึกเตียน",
          "postCode": [
            "76130"
          ]
        },
        {
          "id": 760505,
          "name": "มาบปลาเค้า",
          "postCode": [
            "76130"
          ]
        },
        {
          "id": 760503,
          "name": "ยางหย่อง",
          "postCode": [
            "76130"
          ]
        },
        {
          "id": 760507,
          "name": "วังไคร้",
          "postCode": [
            "76130"
          ]
        },
        {
          "id": 760504,
          "name": "หนองจอก",
          "postCode": [
            "76130"
          ]
        },
        {
          "id": 760513,
          "name": "เขากระปุก",
          "postCode": [
            "76130"
          ]
        }
      ]
    },
    {
      "city": "บ้านลาด",
      "cityCode": 7606,
      "provinceCode": 76,
      "subDistrict": [
        {
          "id": 760604,
          "name": "ตำหรุ",
          "postCode": [
            "76150"
          ]
        },
        {
          "id": 760617,
          "name": "ถ้ำรงค์",
          "postCode": [
            "76150"
          ]
        },
        {
          "id": 760616,
          "name": "ท่าช้าง",
          "postCode": [
            "76150"
          ]
        },
        {
          "id": 760607,
          "name": "ท่าเสน",
          "postCode": [
            "76150"
          ]
        },
        {
          "id": 760603,
          "name": "บ้านทาน",
          "postCode": [
            "76150"
          ]
        },
        {
          "id": 760601,
          "name": "บ้านลาด",
          "postCode": [
            "76150"
          ]
        },
        {
          "id": 760602,
          "name": "บ้านหาด",
          "postCode": [
            "76150"
          ]
        },
        {
          "id": 760610,
          "name": "ลาดโพธิ์",
          "postCode": [
            "76150"
          ]
        },
        {
          "id": 760605,
          "name": "สมอพลือ",
          "postCode": [
            "76150"
          ]
        },
        {
          "id": 760611,
          "name": "สะพานไกร",
          "postCode": [
            "76150"
          ]
        },
        {
          "id": 760608,
          "name": "หนองกระเจ็ด",
          "postCode": [
            "76150"
          ]
        },
        {
          "id": 760609,
          "name": "หนองกะปุ",
          "postCode": [
            "76150"
          ]
        },
        {
          "id": 760615,
          "name": "ห้วยข้อง",
          "postCode": [
            "76150"
          ]
        },
        {
          "id": 760618,
          "name": "ห้วยลึก",
          "postCode": [
            "76150"
          ]
        },
        {
          "id": 760613,
          "name": "โรงเข้",
          "postCode": [
            "76150"
          ]
        },
        {
          "id": 760606,
          "name": "ไร่มะขาม",
          "postCode": [
            "76150"
          ]
        },
        {
          "id": 760614,
          "name": "ไร่สะท้อน",
          "postCode": [
            "76150"
          ]
        },
        {
          "id": 760612,
          "name": "ไร่โคก",
          "postCode": [
            "76150"
          ]
        }
      ]
    },
    {
      "city": "บ้านแหลม",
      "cityCode": 7607,
      "provinceCode": 76,
      "subDistrict": [
        {
          "id": 760709,
          "name": "ท่าแร้ง",
          "postCode": [
            "76110"
          ]
        },
        {
          "id": 760710,
          "name": "ท่าแร้งออก",
          "postCode": [
            "76110"
          ]
        },
        {
          "id": 760702,
          "name": "บางขุนไทร",
          "postCode": [
            "76110"
          ]
        },
        {
          "id": 760708,
          "name": "บางครก",
          "postCode": [
            "76110"
          ]
        },
        {
          "id": 760706,
          "name": "บางตะบูน",
          "postCode": [
            "76110"
          ]
        },
        {
          "id": 760707,
          "name": "บางตะบูนออก",
          "postCode": [
            "76110"
          ]
        },
        {
          "id": 760704,
          "name": "บางแก้ว",
          "postCode": [
            "76110"
          ]
        },
        {
          "id": 760701,
          "name": "บ้านแหลม",
          "postCode": [
            "76110"
          ]
        },
        {
          "id": 760703,
          "name": "ปากทะเล",
          "postCode": [
            "76110"
          ]
        },
        {
          "id": 760705,
          "name": "แหลมผักเบี้ย",
          "postCode": [
            "76100"
          ]
        }
      ]
    },
    {
      "city": "หนองหญ้าปล้อง",
      "cityCode": 7603,
      "provinceCode": 76,
      "subDistrict": [
        {
          "id": 760304,
          "name": "ท่าตะคร้อ",
          "postCode": [
            "76160"
          ]
        },
        {
          "id": 760302,
          "name": "ยางน้ำกลัดเหนือ",
          "postCode": [
            "76160"
          ]
        },
        {
          "id": 760303,
          "name": "ยางน้ำกลัดใต้",
          "postCode": [
            "76160"
          ]
        },
        {
          "id": 760301,
          "name": "หนองหญ้าปล้อง",
          "postCode": [
            "76160"
          ]
        }
      ]
    },
    {
      "city": "เขาย้อย",
      "cityCode": 7602,
      "provinceCode": 76,
      "subDistrict": [
        {
          "id": 760204,
          "name": "ทับคาง",
          "postCode": [
            "76140"
          ]
        },
        {
          "id": 760203,
          "name": "บางเค็ม",
          "postCode": [
            "76140"
          ]
        },
        {
          "id": 760202,
          "name": "สระพัง",
          "postCode": [
            "76140"
          ]
        },
        {
          "id": 760207,
          "name": "หนองชุมพล",
          "postCode": [
            "76140"
          ]
        },
        {
          "id": 760210,
          "name": "หนองชุมพลเหนือ",
          "postCode": [
            "76140"
          ]
        },
        {
          "id": 760206,
          "name": "หนองปรง",
          "postCode": [
            "76140"
          ]
        },
        {
          "id": 760205,
          "name": "หนองปลาไหล",
          "postCode": [
            "76140"
          ]
        },
        {
          "id": 760209,
          "name": "ห้วยท่าช้าง",
          "postCode": [
            "76140"
          ]
        },
        {
          "id": 760208,
          "name": "ห้วยโรง",
          "postCode": [
            "76140"
          ]
        },
        {
          "id": 760201,
          "name": "เขาย้อย",
          "postCode": [
            "76140"
          ]
        }
      ]
    },
    {
      "city": "เมืองเพชรบุรี",
      "cityCode": 7601,
      "provinceCode": 76,
      "subDistrict": [
        {
          "id": 760102,
          "name": "คลองกระแชง",
          "postCode": [
            "76000"
          ]
        },
        {
          "id": 760113,
          "name": "ช่องสะแก",
          "postCode": [
            "76000"
          ]
        },
        {
          "id": 760122,
          "name": "ดอนยาง",
          "postCode": [
            "76000"
          ]
        },
        {
          "id": 760119,
          "name": "ต้นมะพร้าว",
          "postCode": [
            "76000"
          ]
        },
        {
          "id": 760112,
          "name": "ต้นมะม่วง",
          "postCode": [
            "76000"
          ]
        },
        {
          "id": 760101,
          "name": "ท่าราบ",
          "postCode": [
            "76000"
          ]
        },
        {
          "id": 760105,
          "name": "ธงชัย",
          "postCode": [
            "76000"
          ]
        },
        {
          "id": 760104,
          "name": "นาพันสาม",
          "postCode": [
            "76000"
          ]
        },
        {
          "id": 760114,
          "name": "นาวุ้ง",
          "postCode": [
            "76000"
          ]
        },
        {
          "id": 760110,
          "name": "บางจาก",
          "postCode": [
            "76000"
          ]
        },
        {
          "id": 760103,
          "name": "บางจาน",
          "postCode": [
            "76000"
          ]
        },
        {
          "id": 760106,
          "name": "บ้านกุ่ม",
          "postCode": [
            "76000"
          ]
        },
        {
          "id": 760111,
          "name": "บ้านหม้อ",
          "postCode": [
            "76000"
          ]
        },
        {
          "id": 760120,
          "name": "วังตะโก",
          "postCode": [
            "76000"
          ]
        },
        {
          "id": 760115,
          "name": "สำมะโรง",
          "postCode": [
            "76000"
          ]
        },
        {
          "id": 760123,
          "name": "หนองขนาน",
          "postCode": [
            "76000",
            "76100"
          ]
        },
        {
          "id": 760124,
          "name": "หนองพลับ",
          "postCode": [
            "76000"
          ]
        },
        {
          "id": 760107,
          "name": "หนองโสน",
          "postCode": [
            "76000"
          ]
        },
        {
          "id": 760118,
          "name": "หัวสะพาน",
          "postCode": [
            "76000"
          ]
        },
        {
          "id": 760117,
          "name": "หาดเจ้าสำราญ",
          "postCode": [
            "76100"
          ]
        },
        {
          "id": 760109,
          "name": "เวียงคอย",
          "postCode": [
            "76000"
          ]
        },
        {
          "id": 760116,
          "name": "โพพระ",
          "postCode": [
            "76000"
          ]
        },
        {
          "id": 760121,
          "name": "โพไร่หวาน",
          "postCode": [
            "76000"
          ]
        },
        {
          "id": 760108,
          "name": "ไร่ส้ม",
          "postCode": [
            "76000"
          ]
        }
      ]
    },
    {
      "city": "แก่งกระจาน",
      "cityCode": 7608,
      "provinceCode": 76,
      "subDistrict": [
        {
          "id": 760804,
          "name": "ป่าเด็ง",
          "postCode": [
            "76170"
          ]
        },
        {
          "id": 760805,
          "name": "พุสวรรค์",
          "postCode": [
            "76170"
          ]
        },
        {
          "id": 760803,
          "name": "วังจันทร์",
          "postCode": [
            "76170"
          ]
        },
        {
          "id": 760802,
          "name": "สองพี่น้อง",
          "postCode": [
            "76170"
          ]
        },
        {
          "id": 760806,
          "name": "ห้วยแม่เพรียง",
          "postCode": [
            "76170"
          ]
        },
        {
          "id": 760801,
          "name": "แก่งกระจาน",
          "postCode": [
            "76170"
          ]
        }
      ]
    },
    {
      "city": "ชนแดน",
      "cityCode": 6702,
      "provinceCode": 67,
      "subDistrict": [
        {
          "id": 670201,
          "name": "ชนแดน",
          "postCode": [
            "67150"
          ]
        },
        {
          "id": 670208,
          "name": "ซับพุทรา",
          "postCode": [
            "67150"
          ]
        },
        {
          "id": 670202,
          "name": "ดงขุย",
          "postCode": [
            "67190"
          ]
        },
        {
          "id": 670209,
          "name": "ตะกุดไร",
          "postCode": [
            "67190"
          ]
        },
        {
          "id": 670203,
          "name": "ท่าข้าม",
          "postCode": [
            "67150"
          ]
        },
        {
          "id": 670206,
          "name": "บ้านกล้วย",
          "postCode": [
            "67190"
          ]
        },
        {
          "id": 670204,
          "name": "พุทธบาท",
          "postCode": [
            "67150"
          ]
        },
        {
          "id": 670205,
          "name": "ลาดแค",
          "postCode": [
            "67150"
          ]
        },
        {
          "id": 670210,
          "name": "ศาลาลาย",
          "postCode": [
            "67150"
          ]
        }
      ]
    },
    {
      "city": "น้ำหนาว",
      "cityCode": 6709,
      "provinceCode": 67,
      "subDistrict": [
        {
          "id": 670901,
          "name": "น้ำหนาว",
          "postCode": [
            "67260"
          ]
        },
        {
          "id": 670903,
          "name": "วังกวาง",
          "postCode": [
            "67260"
          ]
        },
        {
          "id": 670902,
          "name": "หลักด่าน",
          "postCode": [
            "67260"
          ]
        },
        {
          "id": 670904,
          "name": "โคกมน",
          "postCode": [
            "67260"
          ]
        }
      ]
    },
    {
      "city": "บึงสามพัน",
      "cityCode": 6708,
      "provinceCode": 67,
      "subDistrict": [
        {
          "id": 670804,
          "name": "กันจุ",
          "postCode": [
            "67160"
          ]
        },
        {
          "id": 670801,
          "name": "ซับสมอทอด",
          "postCode": [
            "67160"
          ]
        },
        {
          "id": 670802,
          "name": "ซับไม้แดง",
          "postCode": [
            "67160"
          ]
        },
        {
          "id": 670809,
          "name": "บึงสามพัน",
          "postCode": [
            "67160"
          ]
        },
        {
          "id": 670806,
          "name": "พญาวัง",
          "postCode": [
            "67160"
          ]
        },
        {
          "id": 670805,
          "name": "วังพิกุล",
          "postCode": [
            "67230"
          ]
        },
        {
          "id": 670807,
          "name": "ศรีมงคล",
          "postCode": [
            "67160"
          ]
        },
        {
          "id": 670808,
          "name": "สระแก้ว",
          "postCode": [
            "67160"
          ]
        },
        {
          "id": 670803,
          "name": "หนองแจง",
          "postCode": [
            "67160"
          ]
        }
      ]
    },
    {
      "city": "วังโป่ง",
      "cityCode": 6710,
      "provinceCode": 67,
      "subDistrict": [
        {
          "id": 671003,
          "name": "ซับเปิบ",
          "postCode": [
            "67240"
          ]
        },
        {
          "id": 671002,
          "name": "ท้ายดง",
          "postCode": [
            "67240"
          ]
        },
        {
          "id": 671005,
          "name": "วังศาล",
          "postCode": [
            "67240"
          ]
        },
        {
          "id": 671004,
          "name": "วังหิน",
          "postCode": [
            "67240"
          ]
        },
        {
          "id": 671001,
          "name": "วังโป่ง",
          "postCode": [
            "67240"
          ]
        }
      ]
    },
    {
      "city": "วิเชียรบุรี",
      "cityCode": 6705,
      "provinceCode": 67,
      "subDistrict": [
        {
          "id": 670514,
          "name": "ซับน้อย",
          "postCode": [
            "67180"
          ]
        },
        {
          "id": 670510,
          "name": "ซับสมบูรณ์",
          "postCode": [
            "67180"
          ]
        },
        {
          "id": 670501,
          "name": "ท่าโรง",
          "postCode": [
            "67130"
          ]
        },
        {
          "id": 670505,
          "name": "น้ำร้อน",
          "postCode": [
            "67130"
          ]
        },
        {
          "id": 670511,
          "name": "บึงกระจับ",
          "postCode": [
            "67130"
          ]
        },
        {
          "id": 670506,
          "name": "บ่อรัง",
          "postCode": [
            "67130"
          ]
        },
        {
          "id": 670508,
          "name": "พุขาม",
          "postCode": [
            "67180"
          ]
        },
        {
          "id": 670507,
          "name": "พุเตย",
          "postCode": [
            "67180"
          ]
        },
        {
          "id": 670509,
          "name": "ภูน้ำหยด",
          "postCode": [
            "67180"
          ]
        },
        {
          "id": 670513,
          "name": "ยางสาว",
          "postCode": [
            "67130"
          ]
        },
        {
          "id": 670512,
          "name": "วังใหญ่",
          "postCode": [
            "67180"
          ]
        },
        {
          "id": 670502,
          "name": "สระประดู่",
          "postCode": [
            "67130"
          ]
        },
        {
          "id": 670503,
          "name": "สามแยก",
          "postCode": [
            "67130"
          ]
        },
        {
          "id": 670504,
          "name": "โคกปรง",
          "postCode": [
            "67130"
          ]
        }
      ]
    },
    {
      "city": "ศรีเทพ",
      "cityCode": 6706,
      "provinceCode": 67,
      "subDistrict": [
        {
          "id": 670603,
          "name": "คลองกระจัง",
          "postCode": [
            "67170"
          ]
        },
        {
          "id": 670604,
          "name": "นาสนุ่น",
          "postCode": [
            "67170"
          ]
        },
        {
          "id": 670607,
          "name": "ประดู่งาม",
          "postCode": [
            "67170"
          ]
        },
        {
          "id": 670601,
          "name": "ศรีเทพ",
          "postCode": [
            "67170"
          ]
        },
        {
          "id": 670602,
          "name": "สระกรวด",
          "postCode": [
            "67170"
          ]
        },
        {
          "id": 670606,
          "name": "หนองย่างทอย",
          "postCode": [
            "67170"
          ]
        },
        {
          "id": 670605,
          "name": "โคกสะอาด",
          "postCode": [
            "67170"
          ]
        }
      ]
    },
    {
      "city": "หนองไผ่",
      "cityCode": 6707,
      "provinceCode": 67,
      "subDistrict": [
        {
          "id": 670701,
          "name": "กองทูล",
          "postCode": [
            "67140"
          ]
        },
        {
          "id": 670713,
          "name": "ท่าด้วง",
          "postCode": [
            "67140"
          ]
        },
        {
          "id": 670704,
          "name": "ท่าแดง",
          "postCode": [
            "67140"
          ]
        },
        {
          "id": 670702,
          "name": "นาเฉลียง",
          "postCode": [
            "67220"
          ]
        },
        {
          "id": 670709,
          "name": "บัววัฒนา",
          "postCode": [
            "67140"
          ]
        },
        {
          "id": 670706,
          "name": "บ่อไทย",
          "postCode": [
            "67140"
          ]
        },
        {
          "id": 670703,
          "name": "บ้านโภชน์",
          "postCode": [
            "67140"
          ]
        },
        {
          "id": 670712,
          "name": "ยางงาม",
          "postCode": [
            "67220"
          ]
        },
        {
          "id": 670708,
          "name": "วังท่าดี",
          "postCode": [
            "67140"
          ]
        },
        {
          "id": 670711,
          "name": "วังโบสถ์",
          "postCode": [
            "67140"
          ]
        },
        {
          "id": 670710,
          "name": "หนองไผ่",
          "postCode": [
            "67140"
          ]
        },
        {
          "id": 670707,
          "name": "ห้วยโป่ง",
          "postCode": [
            "67220"
          ]
        },
        {
          "id": 670705,
          "name": "เพชรละคร",
          "postCode": [
            "67140"
          ]
        }
      ]
    },
    {
      "city": "หล่มสัก",
      "cityCode": 6703,
      "provinceCode": 67,
      "subDistrict": [
        {
          "id": 670320,
          "name": "ช้างตะลูด",
          "postCode": [
            "67110"
          ]
        },
        {
          "id": 670303,
          "name": "ตาลเดี่ยว",
          "postCode": [
            "67110"
          ]
        },
        {
          "id": 670308,
          "name": "ท่าอิบุญ",
          "postCode": [
            "67110"
          ]
        },
        {
          "id": 670312,
          "name": "น้ำก้อ",
          "postCode": [
            "67110"
          ]
        },
        {
          "id": 670314,
          "name": "น้ำชุน",
          "postCode": [
            "67110"
          ]
        },
        {
          "id": 670306,
          "name": "น้ำเฮี้ย",
          "postCode": [
            "67110"
          ]
        },
        {
          "id": 670317,
          "name": "บุ่งคล้า",
          "postCode": [
            "67110"
          ]
        },
        {
          "id": 670318,
          "name": "บุ่งน้ำเต้า",
          "postCode": [
            "67110"
          ]
        },
        {
          "id": 670319,
          "name": "บ้านกลาง",
          "postCode": [
            "67110"
          ]
        },
        {
          "id": 670310,
          "name": "บ้านติ้ว",
          "postCode": [
            "67110"
          ]
        },
        {
          "id": 670323,
          "name": "บ้านหวาย",
          "postCode": [
            "67110"
          ]
        },
        {
          "id": 670309,
          "name": "บ้านโสก",
          "postCode": [
            "67110"
          ]
        },
        {
          "id": 670321,
          "name": "บ้านไร่",
          "postCode": [
            "67110"
          ]
        },
        {
          "id": 670313,
          "name": "ปากช่อง",
          "postCode": [
            "67110"
          ]
        },
        {
          "id": 670322,
          "name": "ปากดุก",
          "postCode": [
            "67110"
          ]
        },
        {
          "id": 670304,
          "name": "ฝายนาแซง",
          "postCode": [
            "67110"
          ]
        },
        {
          "id": 670316,
          "name": "ลานบ่า",
          "postCode": [
            "67110"
          ]
        },
        {
          "id": 670302,
          "name": "วัดป่า",
          "postCode": [
            "67110"
          ]
        },
        {
          "id": 670307,
          "name": "สักหลง",
          "postCode": [
            "67110"
          ]
        },
        {
          "id": 670305,
          "name": "หนองสว่าง",
          "postCode": [
            "67110"
          ]
        },
        {
          "id": 670315,
          "name": "หนองไขว่",
          "postCode": [
            "67110"
          ]
        },
        {
          "id": 670301,
          "name": "หล่มสัก",
          "postCode": [
            "67110"
          ]
        },
        {
          "id": 670311,
          "name": "ห้วยไร่",
          "postCode": [
            "67110"
          ]
        }
      ]
    },
    {
      "city": "หล่มเก่า",
      "cityCode": 6704,
      "provinceCode": 67,
      "subDistrict": [
        {
          "id": 670409,
          "name": "ตาดกลอย",
          "postCode": [
            "67120"
          ]
        },
        {
          "id": 670402,
          "name": "นาซำ",
          "postCode": [
            "67120"
          ]
        },
        {
          "id": 670408,
          "name": "นาเกาะ",
          "postCode": [
            "67120"
          ]
        },
        {
          "id": 670406,
          "name": "นาแซง",
          "postCode": [
            "67120"
          ]
        },
        {
          "id": 670404,
          "name": "บ้านเนิน",
          "postCode": [
            "67120"
          ]
        },
        {
          "id": 670407,
          "name": "วังบาล",
          "postCode": [
            "67120"
          ]
        },
        {
          "id": 670405,
          "name": "ศิลา",
          "postCode": [
            "67120"
          ]
        },
        {
          "id": 670401,
          "name": "หล่มเก่า",
          "postCode": [
            "67120"
          ]
        },
        {
          "id": 670403,
          "name": "หินฮาว",
          "postCode": [
            "67120"
          ]
        }
      ]
    },
    {
      "city": "เขาค้อ",
      "cityCode": 6711,
      "provinceCode": 67,
      "subDistrict": [
        {
          "id": 671101,
          "name": "ทุ่งสมอ",
          "postCode": [
            "67270"
          ]
        },
        {
          "id": 671104,
          "name": "ริมสีม่วง",
          "postCode": [
            "67270"
          ]
        },
        {
          "id": 671105,
          "name": "สะเดาะพง",
          "postCode": [
            "67270"
          ]
        },
        {
          "id": 671106,
          "name": "หนองแม่นา",
          "postCode": [
            "67270"
          ]
        },
        {
          "id": 671103,
          "name": "เขาค้อ",
          "postCode": [
            "67270"
          ]
        },
        {
          "id": 671107,
          "name": "เข็กน้อย",
          "postCode": [
            "67280"
          ]
        },
        {
          "id": 671102,
          "name": "แคมป์สน",
          "postCode": [
            "67280"
          ]
        }
      ]
    },
    {
      "city": "เมืองเพชรบูรณ์",
      "cityCode": 6701,
      "provinceCode": 67,
      "subDistrict": [
        {
          "id": 670110,
          "name": "ชอนไพร",
          "postCode": [
            "67000"
          ]
        },
        {
          "id": 670108,
          "name": "ดงมูลเหล็ก",
          "postCode": [
            "67000"
          ]
        },
        {
          "id": 670102,
          "name": "ตะเบาะ",
          "postCode": [
            "67000"
          ]
        },
        {
          "id": 670107,
          "name": "ท่าพล",
          "postCode": [
            "67250"
          ]
        },
        {
          "id": 670106,
          "name": "นางั่ว",
          "postCode": [
            "67000"
          ]
        },
        {
          "id": 670111,
          "name": "นาป่า",
          "postCode": [
            "67000"
          ]
        },
        {
          "id": 670112,
          "name": "นายม",
          "postCode": [
            "67210"
          ]
        },
        {
          "id": 670114,
          "name": "น้ำร้อน",
          "postCode": [
            "67000"
          ]
        },
        {
          "id": 670109,
          "name": "บ้านโคก",
          "postCode": [
            "67000"
          ]
        },
        {
          "id": 670103,
          "name": "บ้านโตก",
          "postCode": [
            "67000"
          ]
        },
        {
          "id": 670105,
          "name": "ป่าเลา",
          "postCode": [
            "67000"
          ]
        },
        {
          "id": 670117,
          "name": "ระวิง",
          "postCode": [
            "67210"
          ]
        },
        {
          "id": 670113,
          "name": "วังชมภู",
          "postCode": [
            "67210"
          ]
        },
        {
          "id": 670104,
          "name": "สะเดียง",
          "postCode": [
            "67000"
          ]
        },
        {
          "id": 670115,
          "name": "ห้วยสะแก",
          "postCode": [
            "67210"
          ]
        },
        {
          "id": 670116,
          "name": "ห้วยใหญ่",
          "postCode": [
            "67000"
          ]
        },
        {
          "id": 670101,
          "name": "ในเมือง",
          "postCode": [
            "67000"
          ]
        }
      ]
    },
    {
      "city": "ด่านซ้าย",
      "cityCode": 4205,
      "provinceCode": 42,
      "subDistrict": [
        {
          "id": 420507,
          "name": "กกสะทอน",
          "postCode": [
            "42120"
          ]
        },
        {
          "id": 420501,
          "name": "ด่านซ้าย",
          "postCode": [
            "42120"
          ]
        },
        {
          "id": 420503,
          "name": "นาดี",
          "postCode": [
            "42120"
          ]
        },
        {
          "id": 420510,
          "name": "นาหอ",
          "postCode": [
            "42120"
          ]
        },
        {
          "id": 420502,
          "name": "ปากหมัน",
          "postCode": [
            "42120"
          ]
        },
        {
          "id": 420509,
          "name": "วังยาว",
          "postCode": [
            "42120"
          ]
        },
        {
          "id": 420506,
          "name": "อิปุ่ม",
          "postCode": [
            "42120"
          ]
        },
        {
          "id": 420504,
          "name": "โคกงาม",
          "postCode": [
            "42120"
          ]
        },
        {
          "id": 420508,
          "name": "โป่ง",
          "postCode": [
            "42120"
          ]
        },
        {
          "id": 420505,
          "name": "โพนสูง",
          "postCode": [
            "42120"
          ]
        }
      ]
    },
    {
      "city": "ท่าลี่",
      "cityCode": 4208,
      "provinceCode": 42,
      "subDistrict": [
        {
          "id": 420801,
          "name": "ท่าลี่",
          "postCode": [
            "42140"
          ]
        },
        {
          "id": 420806,
          "name": "น้ำทูน",
          "postCode": [
            "42140"
          ]
        },
        {
          "id": 420804,
          "name": "น้ำแคม",
          "postCode": [
            "42140"
          ]
        },
        {
          "id": 420802,
          "name": "หนองผือ",
          "postCode": [
            "42140"
          ]
        },
        {
          "id": 420803,
          "name": "อาฮี",
          "postCode": [
            "42140"
          ]
        },
        {
          "id": 420805,
          "name": "โคกใหญ่",
          "postCode": [
            "42140"
          ]
        }
      ]
    },
    {
      "city": "นาด้วง",
      "cityCode": 4202,
      "provinceCode": 42,
      "subDistrict": [
        {
          "id": 420204,
          "name": "ท่าสวรรค์",
          "postCode": [
            "42210"
          ]
        },
        {
          "id": 420203,
          "name": "ท่าสะอาด",
          "postCode": [
            "42210"
          ]
        },
        {
          "id": 420202,
          "name": "นาดอกคำ",
          "postCode": [
            "42210"
          ]
        },
        {
          "id": 420201,
          "name": "นาด้วง",
          "postCode": [
            "42210"
          ]
        }
      ]
    },
    {
      "city": "นาแห้ว",
      "cityCode": 4206,
      "provinceCode": 42,
      "subDistrict": [
        {
          "id": 420603,
          "name": "นาพึง",
          "postCode": [
            "42170"
          ]
        },
        {
          "id": 420604,
          "name": "นามาลา",
          "postCode": [
            "42170"
          ]
        },
        {
          "id": 420601,
          "name": "นาแห้ว",
          "postCode": [
            "42170"
          ]
        },
        {
          "id": 420605,
          "name": "เหล่ากอหก",
          "postCode": [
            "42170"
          ]
        },
        {
          "id": 420602,
          "name": "แสงภา",
          "postCode": [
            "42170"
          ]
        }
      ]
    },
    {
      "city": "ปากชม",
      "cityCode": 4204,
      "provinceCode": 42,
      "subDistrict": [
        {
          "id": 420406,
          "name": "ชมเจริญ",
          "postCode": [
            "42150"
          ]
        },
        {
          "id": 420401,
          "name": "ปากชม",
          "postCode": [
            "42150"
          ]
        },
        {
          "id": 420403,
          "name": "หาดคัมภีร์",
          "postCode": [
            "42150"
          ]
        },
        {
          "id": 420404,
          "name": "ห้วยบ่อซืน",
          "postCode": [
            "42150"
          ]
        },
        {
          "id": 420405,
          "name": "ห้วยพิชัย",
          "postCode": [
            "42150"
          ]
        },
        {
          "id": 420402,
          "name": "เชียงกลม",
          "postCode": [
            "42150"
          ]
        }
      ]
    },
    {
      "city": "ผาขาว",
      "cityCode": 4212,
      "provinceCode": 42,
      "subDistrict": [
        {
          "id": 421202,
          "name": "ท่าช้างคล้อง",
          "postCode": [
            "42240"
          ]
        },
        {
          "id": 421205,
          "name": "บ้านเพิ่ม",
          "postCode": [
            "42240"
          ]
        },
        {
          "id": 421201,
          "name": "ผาขาว",
          "postCode": [
            "42240"
          ]
        },
        {
          "id": 421203,
          "name": "โนนปอแดง",
          "postCode": [
            "42240"
          ]
        },
        {
          "id": 421204,
          "name": "โนนป่าซาง",
          "postCode": [
            "42240"
          ]
        }
      ]
    },
    {
      "city": "ภูกระดึง",
      "cityCode": 4210,
      "provinceCode": 42,
      "subDistrict": [
        {
          "id": 421005,
          "name": "ผานกเค้า",
          "postCode": [
            "42180"
          ]
        },
        {
          "id": 421007,
          "name": "ภูกระดึง",
          "postCode": [
            "42180"
          ]
        },
        {
          "id": 421001,
          "name": "ศรีฐาน",
          "postCode": [
            "42180"
          ]
        },
        {
          "id": 421010,
          "name": "ห้วยส้ม",
          "postCode": [
            "42180"
          ]
        }
      ]
    },
    {
      "city": "ภูหลวง",
      "cityCode": 4211,
      "provinceCode": 42,
      "subDistrict": [
        {
          "id": 421101,
          "name": "ภูหอ",
          "postCode": [
            "42230"
          ]
        },
        {
          "id": 421102,
          "name": "หนองคัน",
          "postCode": [
            "42230"
          ]
        },
        {
          "id": 421104,
          "name": "ห้วยสีเสียด",
          "postCode": [
            "42230"
          ]
        },
        {
          "id": 421105,
          "name": "เลยวังไสย์",
          "postCode": [
            "42230"
          ]
        },
        {
          "id": 421106,
          "name": "แก่งศรีภูมิ",
          "postCode": [
            "42230"
          ]
        }
      ]
    },
    {
      "city": "ภูเรือ",
      "cityCode": 4207,
      "provinceCode": 42,
      "subDistrict": [
        {
          "id": 420702,
          "name": "ท่าศาลา",
          "postCode": [
            "42160"
          ]
        },
        {
          "id": 420704,
          "name": "ปลาบ่า",
          "postCode": [
            "42160"
          ]
        },
        {
          "id": 420703,
          "name": "ร่องจิก",
          "postCode": [
            "42160"
          ]
        },
        {
          "id": 420705,
          "name": "ลาดค่าง",
          "postCode": [
            "42160"
          ]
        },
        {
          "id": 420706,
          "name": "สานตม",
          "postCode": [
            "42160"
          ]
        },
        {
          "id": 420701,
          "name": "หนองบัว",
          "postCode": [
            "42160"
          ]
        }
      ]
    },
    {
      "city": "วังสะพุง",
      "cityCode": 4209,
      "provinceCode": 42,
      "subDistrict": [
        {
          "id": 420902,
          "name": "ทรายขาว",
          "postCode": [
            "42130"
          ]
        },
        {
          "id": 420905,
          "name": "ปากปวน",
          "postCode": [
            "42130"
          ]
        },
        {
          "id": 420906,
          "name": "ผาน้อย",
          "postCode": [
            "42130"
          ]
        },
        {
          "id": 420910,
          "name": "ผาบิ้ง",
          "postCode": [
            "42130"
          ]
        },
        {
          "id": 420901,
          "name": "วังสะพุง",
          "postCode": [
            "42130"
          ]
        },
        {
          "id": 420913,
          "name": "ศรีสงคราม",
          "postCode": [
            "42130"
          ]
        },
        {
          "id": 420904,
          "name": "หนองงิ้ว",
          "postCode": [
            "42130"
          ]
        },
        {
          "id": 420903,
          "name": "หนองหญ้าปล้อง",
          "postCode": [
            "42130"
          ]
        },
        {
          "id": 420911,
          "name": "เขาหลวง",
          "postCode": [
            "42130"
          ]
        },
        {
          "id": 420912,
          "name": "โคกขมิ้น",
          "postCode": [
            "42130"
          ]
        }
      ]
    },
    {
      "city": "หนองหิน",
      "cityCode": 4214,
      "provinceCode": 42,
      "subDistrict": [
        {
          "id": 421402,
          "name": "ตาดข่า",
          "postCode": [
            "42190"
          ]
        },
        {
          "id": 421403,
          "name": "ปวนพุ",
          "postCode": [
            "42190"
          ]
        },
        {
          "id": 421401,
          "name": "หนองหิน",
          "postCode": [
            "42190"
          ]
        }
      ]
    },
    {
      "city": "เชียงคาน",
      "cityCode": 4203,
      "provinceCode": 42,
      "subDistrict": [
        {
          "id": 420307,
          "name": "จอมศรี",
          "postCode": [
            "42110"
          ]
        },
        {
          "id": 420302,
          "name": "ธาตุ",
          "postCode": [
            "42110"
          ]
        },
        {
          "id": 420303,
          "name": "นาซ่าว",
          "postCode": [
            "42110"
          ]
        },
        {
          "id": 420306,
          "name": "บุฮม",
          "postCode": [
            "42110"
          ]
        },
        {
          "id": 420305,
          "name": "ปากตม",
          "postCode": [
            "42110"
          ]
        },
        {
          "id": 420308,
          "name": "หาดทรายขาว",
          "postCode": [
            "42110"
          ]
        },
        {
          "id": 420304,
          "name": "เขาแก้ว",
          "postCode": [
            "42110"
          ]
        },
        {
          "id": 420301,
          "name": "เชียงคาน",
          "postCode": [
            "42110"
          ]
        }
      ]
    },
    {
      "city": "เมืองเลย",
      "cityCode": 4201,
      "provinceCode": 42,
      "subDistrict": [
        {
          "id": 420104,
          "name": "กกดู่",
          "postCode": [
            "42000"
          ]
        },
        {
          "id": 420114,
          "name": "กกทอง",
          "postCode": [
            "42000"
          ]
        },
        {
          "id": 420101,
          "name": "กุดป่อง",
          "postCode": [
            "42000"
          ]
        },
        {
          "id": 420111,
          "name": "ชัยพฤกษ์",
          "postCode": [
            "42000"
          ]
        },
        {
          "id": 420109,
          "name": "นาดินดำ",
          "postCode": [
            "42000"
          ]
        },
        {
          "id": 420107,
          "name": "นาอาน",
          "postCode": [
            "42000"
          ]
        },
        {
          "id": 420103,
          "name": "นาอ้อ",
          "postCode": [
            "42100"
          ]
        },
        {
          "id": 420112,
          "name": "นาแขม",
          "postCode": [
            "42000"
          ]
        },
        {
          "id": 420108,
          "name": "นาโป่ง",
          "postCode": [
            "42000"
          ]
        },
        {
          "id": 420110,
          "name": "น้ำสวย",
          "postCode": [
            "42000"
          ]
        },
        {
          "id": 420105,
          "name": "น้ำหมาน",
          "postCode": [
            "42000"
          ]
        },
        {
          "id": 420113,
          "name": "ศรีสองรัก",
          "postCode": [
            "42100"
          ]
        },
        {
          "id": 420102,
          "name": "เมือง",
          "postCode": [
            "42000"
          ]
        },
        {
          "id": 420106,
          "name": "เสี้ยว",
          "postCode": [
            "42000"
          ]
        }
      ]
    },
    {
      "city": "เอราวัณ",
      "cityCode": 4213,
      "provinceCode": 42,
      "subDistrict": [
        {
          "id": 421304,
          "name": "ทรัพย์ไพวัลย์",
          "postCode": [
            "42220"
          ]
        },
        {
          "id": 421303,
          "name": "ผาสามยอด",
          "postCode": [
            "42220"
          ]
        },
        {
          "id": 421302,
          "name": "ผาอินทร์แปลง",
          "postCode": [
            "42220"
          ]
        },
        {
          "id": 421301,
          "name": "เอราวัณ",
          "postCode": [
            "42220"
          ]
        }
      ]
    },
    {
      "city": "ร้องกวาง",
      "cityCode": 5402,
      "provinceCode": 54,
      "subDistrict": [
        {
          "id": 540207,
          "name": "ทุ่งศรี",
          "postCode": [
            "54140"
          ]
        },
        {
          "id": 540205,
          "name": "น้ำเลา",
          "postCode": [
            "54140"
          ]
        },
        {
          "id": 540206,
          "name": "บ้านเวียง",
          "postCode": [
            "54140"
          ]
        },
        {
          "id": 540201,
          "name": "ร้องกวาง",
          "postCode": [
            "54140"
          ]
        },
        {
          "id": 540204,
          "name": "ร้องเข็ม",
          "postCode": [
            "54140"
          ]
        },
        {
          "id": 540213,
          "name": "ห้วยโรง",
          "postCode": [
            "54140"
          ]
        },
        {
          "id": 540214,
          "name": "แม่ทราย",
          "postCode": [
            "54140"
          ]
        },
        {
          "id": 540208,
          "name": "แม่ยางตาล",
          "postCode": [
            "54140"
          ]
        },
        {
          "id": 540215,
          "name": "แม่ยางร้อง",
          "postCode": [
            "54140"
          ]
        },
        {
          "id": 540209,
          "name": "แม่ยางฮ่อ",
          "postCode": [
            "54140"
          ]
        },
        {
          "id": 540210,
          "name": "ไผ่โทน",
          "postCode": [
            "54140"
          ]
        }
      ]
    },
    {
      "city": "ลอง",
      "cityCode": 5403,
      "provinceCode": 54,
      "subDistrict": [
        {
          "id": 540303,
          "name": "ต้าผามอก",
          "postCode": [
            "54150"
          ]
        },
        {
          "id": 540307,
          "name": "ทุ่งแล้ง",
          "postCode": [
            "54150"
          ]
        },
        {
          "id": 540308,
          "name": "บ่อเหล็กลอง",
          "postCode": [
            "54150"
          ]
        },
        {
          "id": 540302,
          "name": "บ้านปิน",
          "postCode": [
            "54150"
          ]
        },
        {
          "id": 540305,
          "name": "ปากกาง",
          "postCode": [
            "54150"
          ]
        },
        {
          "id": 540306,
          "name": "หัวทุ่ง",
          "postCode": [
            "54150"
          ]
        },
        {
          "id": 540301,
          "name": "ห้วยอ้อ",
          "postCode": [
            "54150"
          ]
        },
        {
          "id": 540304,
          "name": "เวียงต้า",
          "postCode": [
            "54150"
          ]
        },
        {
          "id": 540309,
          "name": "แม่ปาน",
          "postCode": [
            "54150"
          ]
        }
      ]
    },
    {
      "city": "วังชิ้น",
      "cityCode": 5407,
      "provinceCode": 54,
      "subDistrict": [
        {
          "id": 540704,
          "name": "นาพูน",
          "postCode": [
            "54160"
          ]
        },
        {
          "id": 540706,
          "name": "ป่าสัก",
          "postCode": [
            "54160"
          ]
        },
        {
          "id": 540701,
          "name": "วังชิ้น",
          "postCode": [
            "54160"
          ]
        },
        {
          "id": 540702,
          "name": "สรอย",
          "postCode": [
            "54160"
          ]
        },
        {
          "id": 540703,
          "name": "แม่ป้าก",
          "postCode": [
            "54160"
          ]
        },
        {
          "id": 540705,
          "name": "แม่พุง",
          "postCode": [
            "54160"
          ]
        },
        {
          "id": 540707,
          "name": "แม่เกิ๋ง",
          "postCode": [
            "54160"
          ]
        }
      ]
    },
    {
      "city": "สอง",
      "cityCode": 5406,
      "provinceCode": 54,
      "subDistrict": [
        {
          "id": 540608,
          "name": "ทุ่งน้าว",
          "postCode": [
            "54120"
          ]
        },
        {
          "id": 540602,
          "name": "บ้านกลาง",
          "postCode": [
            "54120"
          ]
        },
        {
          "id": 540601,
          "name": "บ้านหนุน",
          "postCode": [
            "54120"
          ]
        },
        {
          "id": 540606,
          "name": "สะเอียบ",
          "postCode": [
            "54120"
          ]
        },
        {
          "id": 540605,
          "name": "หัวเมือง",
          "postCode": [
            "54120"
          ]
        },
        {
          "id": 540603,
          "name": "ห้วยหม้าย",
          "postCode": [
            "54120"
          ]
        },
        {
          "id": 540604,
          "name": "เตาปูน",
          "postCode": [
            "54120"
          ]
        },
        {
          "id": 540607,
          "name": "แดนชุมพล",
          "postCode": [
            "54120"
          ]
        }
      ]
    },
    {
      "city": "สูงเม่น",
      "cityCode": 5404,
      "provinceCode": 54,
      "subDistrict": [
        {
          "id": 540404,
          "name": "ดอนมูล",
          "postCode": [
            "54130"
          ]
        },
        {
          "id": 540402,
          "name": "น้ำชำ",
          "postCode": [
            "54130"
          ]
        },
        {
          "id": 540406,
          "name": "บ้านกวาง",
          "postCode": [
            "54130"
          ]
        },
        {
          "id": 540408,
          "name": "บ้านกาศ",
          "postCode": [
            "54130"
          ]
        },
        {
          "id": 540407,
          "name": "บ้านปง",
          "postCode": [
            "54130"
          ]
        },
        {
          "id": 540405,
          "name": "บ้านเหล่า",
          "postCode": [
            "54130"
          ]
        },
        {
          "id": 540412,
          "name": "พระหลวง",
          "postCode": [
            "54130"
          ]
        },
        {
          "id": 540409,
          "name": "ร่องกาศ",
          "postCode": [
            "54130"
          ]
        },
        {
          "id": 540410,
          "name": "สบสาย",
          "postCode": [
            "54130"
          ]
        },
        {
          "id": 540401,
          "name": "สูงเม่น",
          "postCode": [
            "54130"
          ]
        },
        {
          "id": 540403,
          "name": "หัวฝาย",
          "postCode": [
            "54130"
          ]
        },
        {
          "id": 540411,
          "name": "เวียงทอง",
          "postCode": [
            "54000"
          ]
        }
      ]
    },
    {
      "city": "หนองม่วงไข่",
      "cityCode": 5408,
      "provinceCode": 54,
      "subDistrict": [
        {
          "id": 540805,
          "name": "ตำหนักธรรม",
          "postCode": [
            "54170"
          ]
        },
        {
          "id": 540806,
          "name": "ทุ่งแค้ว",
          "postCode": [
            "54170"
          ]
        },
        {
          "id": 540803,
          "name": "น้ำรัด",
          "postCode": [
            "54170"
          ]
        },
        {
          "id": 540804,
          "name": "วังหลวง",
          "postCode": [
            "54170"
          ]
        },
        {
          "id": 540802,
          "name": "หนองม่วงไข่",
          "postCode": [
            "54170"
          ]
        },
        {
          "id": 540801,
          "name": "แม่คำมี",
          "postCode": [
            "54170"
          ]
        }
      ]
    },
    {
      "city": "เด่นชัย",
      "cityCode": 5405,
      "provinceCode": 54,
      "subDistrict": [
        {
          "id": 540505,
          "name": "ปงป่าหวาย",
          "postCode": [
            "54110"
          ]
        },
        {
          "id": 540504,
          "name": "ห้วยไร่",
          "postCode": [
            "54110"
          ]
        },
        {
          "id": 540501,
          "name": "เด่นชัย",
          "postCode": [
            "54110"
          ]
        },
        {
          "id": 540502,
          "name": "แม่จั๊วะ",
          "postCode": [
            "54110"
          ]
        },
        {
          "id": 540503,
          "name": "ไทรย้อย",
          "postCode": [
            "54110"
          ]
        }
      ]
    },
    {
      "city": "เมืองแพร่",
      "cityCode": 5401,
      "provinceCode": 54,
      "subDistrict": [
        {
          "id": 540120,
          "name": "กาญจนา",
          "postCode": [
            "54000"
          ]
        },
        {
          "id": 540118,
          "name": "ช่อแฮ",
          "postCode": [
            "54000"
          ]
        },
        {
          "id": 540115,
          "name": "ทุ่งกวาว",
          "postCode": [
            "54000"
          ]
        },
        {
          "id": 540105,
          "name": "ทุ่งโฮ้ง",
          "postCode": [
            "54000"
          ]
        },
        {
          "id": 540116,
          "name": "ท่าข้าม",
          "postCode": [
            "54000"
          ]
        },
        {
          "id": 540102,
          "name": "นาจักร",
          "postCode": [
            "54000"
          ]
        },
        {
          "id": 540103,
          "name": "น้ำชำ",
          "postCode": [
            "54000"
          ]
        },
        {
          "id": 540111,
          "name": "บ้านถิ่น",
          "postCode": [
            "54000"
          ]
        },
        {
          "id": 540104,
          "name": "ป่าแดง",
          "postCode": [
            "54000"
          ]
        },
        {
          "id": 540110,
          "name": "ป่าแมต",
          "postCode": [
            "54000"
          ]
        },
        {
          "id": 540119,
          "name": "ร่องฟอง",
          "postCode": [
            "54000"
          ]
        },
        {
          "id": 540107,
          "name": "วังธง",
          "postCode": [
            "54000"
          ]
        },
        {
          "id": 540113,
          "name": "วังหงส์",
          "postCode": [
            "54000"
          ]
        },
        {
          "id": 540112,
          "name": "สวนเขื่อน",
          "postCode": [
            "54000"
          ]
        },
        {
          "id": 540109,
          "name": "ห้วยม้า",
          "postCode": [
            "54000"
          ]
        },
        {
          "id": 540106,
          "name": "เหมืองหม้อ",
          "postCode": [
            "54000"
          ]
        },
        {
          "id": 540114,
          "name": "แม่คำมี",
          "postCode": [
            "54000"
          ]
        },
        {
          "id": 540117,
          "name": "แม่ยม",
          "postCode": [
            "54000"
          ]
        },
        {
          "id": 540108,
          "name": "แม่หล่าย",
          "postCode": [
            "54000"
          ]
        },
        {
          "id": 540101,
          "name": "ในเวียง",
          "postCode": [
            "54000"
          ]
        }
      ]
    },
    {
      "city": "ขุนยวม",
      "cityCode": 5802,
      "provinceCode": 58,
      "subDistrict": [
        {
          "id": 580201,
          "name": "ขุนยวม",
          "postCode": [
            "58140"
          ]
        },
        {
          "id": 580203,
          "name": "เมืองปอน",
          "postCode": [
            "58140"
          ]
        },
        {
          "id": 580205,
          "name": "แม่กิ๊",
          "postCode": [
            "58140"
          ]
        },
        {
          "id": 580204,
          "name": "แม่ยวมน้อย",
          "postCode": [
            "58140"
          ]
        },
        {
          "id": 580206,
          "name": "แม่อูคอ",
          "postCode": [
            "58140"
          ]
        },
        {
          "id": 580202,
          "name": "แม่เงา",
          "postCode": [
            "58140"
          ]
        }
      ]
    },
    {
      "city": "ปางมะผ้า",
      "cityCode": 5807,
      "provinceCode": 58,
      "subDistrict": [
        {
          "id": 580703,
          "name": "ถ้ำลอด",
          "postCode": [
            "58150"
          ]
        },
        {
          "id": 580704,
          "name": "นาปู่ป้อม",
          "postCode": [
            "58150"
          ]
        },
        {
          "id": 580702,
          "name": "ปางมะผ้า",
          "postCode": [
            "58150"
          ]
        },
        {
          "id": 580701,
          "name": "สบป่อง",
          "postCode": [
            "58150"
          ]
        }
      ]
    },
    {
      "city": "ปาย",
      "cityCode": 5803,
      "provinceCode": 58,
      "subDistrict": [
        {
          "id": 580305,
          "name": "ทุ่งยาว",
          "postCode": [
            "58130"
          ]
        },
        {
          "id": 580306,
          "name": "เมืองแปง",
          "postCode": [
            "58130"
          ]
        },
        {
          "id": 580302,
          "name": "เวียงเหนือ",
          "postCode": [
            "58130"
          ]
        },
        {
          "id": 580301,
          "name": "เวียงใต้",
          "postCode": [
            "58130"
          ]
        },
        {
          "id": 580303,
          "name": "แม่นาเติง",
          "postCode": [
            "58130"
          ]
        },
        {
          "id": 580304,
          "name": "แม่ฮี้",
          "postCode": [
            "58130"
          ]
        },
        {
          "id": 580307,
          "name": "โป่งสา",
          "postCode": [
            "58130"
          ]
        }
      ]
    },
    {
      "city": "สบเมย",
      "cityCode": 5806,
      "provinceCode": 58,
      "subDistrict": [
        {
          "id": 580603,
          "name": "กองก๋อย",
          "postCode": [
            "58110"
          ]
        },
        {
          "id": 580605,
          "name": "ป่าโปง",
          "postCode": [
            "58110"
          ]
        },
        {
          "id": 580601,
          "name": "สบเมย",
          "postCode": [
            "58110"
          ]
        },
        {
          "id": 580602,
          "name": "แม่คะตวน",
          "postCode": [
            "58110"
          ]
        },
        {
          "id": 580604,
          "name": "แม่สวด",
          "postCode": [
            "58110"
          ]
        },
        {
          "id": 580606,
          "name": "แม่สามแลบ",
          "postCode": [
            "58110"
          ]
        }
      ]
    },
    {
      "city": "เมืองแม่ฮ่องสอน",
      "cityCode": 5801,
      "provinceCode": 58,
      "subDistrict": [
        {
          "id": 580101,
          "name": "จองคำ",
          "postCode": [
            "58000"
          ]
        },
        {
          "id": 580104,
          "name": "ปางหมู",
          "postCode": [
            "58000"
          ]
        },
        {
          "id": 580103,
          "name": "ผาบ่อง",
          "postCode": [
            "58000"
          ]
        },
        {
          "id": 580105,
          "name": "หมอกจำแป่",
          "postCode": [
            "58000"
          ]
        },
        {
          "id": 580109,
          "name": "ห้วยปูลิง",
          "postCode": [
            "58000"
          ]
        },
        {
          "id": 580106,
          "name": "ห้วยผา",
          "postCode": [
            "58000"
          ]
        },
        {
          "id": 580102,
          "name": "ห้วยโป่ง",
          "postCode": [
            "58000"
          ]
        }
      ]
    },
    {
      "city": "แม่ลาน้อย",
      "cityCode": 5805,
      "provinceCode": 58,
      "subDistrict": [
        {
          "id": 580508,
          "name": "ขุนแม่ลาน้อย",
          "postCode": [
            "58120"
          ]
        },
        {
          "id": 580503,
          "name": "ท่าผาปุ้ม",
          "postCode": [
            "58120"
          ]
        },
        {
          "id": 580507,
          "name": "สันติคีรี",
          "postCode": [
            "58120"
          ]
        },
        {
          "id": 580505,
          "name": "ห้วยห้อม",
          "postCode": [
            "58120"
          ]
        },
        {
          "id": 580506,
          "name": "แม่นาจาง",
          "postCode": [
            "58120"
          ]
        },
        {
          "id": 580501,
          "name": "แม่ลาน้อย",
          "postCode": [
            "58120"
          ]
        },
        {
          "id": 580502,
          "name": "แม่ลาหลวง",
          "postCode": [
            "58120"
          ]
        },
        {
          "id": 580504,
          "name": "แม่โถ",
          "postCode": [
            "58120"
          ]
        }
      ]
    },
    {
      "city": "แม่สะเรียง",
      "cityCode": 5804,
      "provinceCode": 58,
      "subDistrict": [
        {
          "id": 580401,
          "name": "บ้านกาศ",
          "postCode": [
            "58110"
          ]
        },
        {
          "id": 580408,
          "name": "ป่าแป๋",
          "postCode": [
            "58110"
          ]
        },
        {
          "id": 580406,
          "name": "เสาหิน",
          "postCode": [
            "58110"
          ]
        },
        {
          "id": 580403,
          "name": "แม่คง",
          "postCode": [
            "58110"
          ]
        },
        {
          "id": 580405,
          "name": "แม่ยวม",
          "postCode": [
            "58110"
          ]
        },
        {
          "id": 580402,
          "name": "แม่สะเรียง",
          "postCode": [
            "58110"
          ]
        },
        {
          "id": 580404,
          "name": "แม่เหาะ",
          "postCode": [
            "58110"
          ]
        }
      ]
    }
  ]
}
