import React from 'react'
import ClassNames from 'classnames'
import PropTypes from 'prop-types'
import {
  TemplateBaseWrapper
} from './styled'
// import {
//   default as VARIABLES
// } from './../../../themes/styles/bases/variables'
// import {
//   default as TYPOGRAPHYS
// } from './../../../themes/styles/bases/typographys'

/**
 * TemplateBase description:
 * - base component template
 */

export class TemplateBase extends React.PureComponent {
  render () {
    const {
      children,
      className
    } = this.props

    return (
      <TemplateBaseWrapper
        className={ClassNames(
          'template-base',
          className
        )}
        // theme={{}}
      >
        {children}
      </TemplateBaseWrapper>
    )
  }

  static defaultProps = {
    // children: undefined,
    // className: undefined
  }

  static propTypes = { // TYPE > node, string, number, bool, array, object, symbol, func
    /**
    * Additional classes
    */
    className: PropTypes.string,

    /**
    * Additional Text, HTML, Component
    */
    children: PropTypes.oneOfType([
      PropTypes.node,
      PropTypes.string
    ])
  }
}
