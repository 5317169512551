import React from 'react'
import { isEmpty } from 'lodash';
import {
  Section,
  Text,
} from '../../components'
import {
  VARIABLES,
  TYPOGRAPHYS,
} from './../../themes'
import {
  RewardCatItem,
} from '../RewardCatPage/RewardCatItem'
import { getLanguage } from '../../helpers/functions/language';

export class SearchResult extends React.Component {
  render() {
    const { list } = this.props
    return (
      <Section
        paddingTop='10'
        paddingBottom='25'
      >
        <Section spacingBottom='10'>
          <Text
            fontStyle={TYPOGRAPHYS.FIRST.MEDIUM.FS_16}
            color={VARIABLES.COLORS.BLACK}
            ellipsis
          >
            {getLanguage('rewardSearch.searchResult')} : {list.length}
          </Text>
        </Section>
        {!isEmpty(list) && (list).map((data, index) => (<RewardCatItem key={index} data={data} />))}
      </Section>
    )
  }
}
