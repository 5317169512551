import { pointController } from "../../apiService/apiController/pointService";
import { ModelPointUser } from "../../models/ModelPoint";
import { isValidResponse } from '../functions';

export const getPointUser = async (params = {}) => {
  const pointService = pointController();
  const res = await pointService.pointUserById(params);
  if (isValidResponse(res)) {
    let data = new ModelPointUser(res.data);
    console.log('getPointUser', res.data, data);
    return data;
  }
};