import React from 'react';
import {
  Image,
  Modal,
  Section,
  SwiperCarousel,
  Text,
} from '../../../components';
import { getLanguage } from '../../../helpers/functions/language';
import { ICONS, TYPOGRAPHYS, VARIABLES } from '../../../themes';

export class ModalRewardDetailContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const { isShowModal, onClickClose, dataList, activeSlideKey } = this.props;
    return (
      <Modal
        open={isShowModal}
        width={300}
        overlayColor={VARIABLES.COLORS.OVERLAY_3}
        // spacing={VARIABLES.MODAL.CONTAINER.C_1.SPACING}
        transiti
        on={VARIABLES.TRANSITIONS.DEFAULT}
        transformStart={VARIABLES.MODAL.CONTAINER.C_1.TRANSFORM_START}
        transformEnd={VARIABLES.MODAL.CONTAINER.C_1.TRANSFORM_END}
        onClickClose={onClickClose}
      >
        <Section justify='flex-end' relative top='-36'>
          <Image
            widthRatio='24'
            widthRatioUnit='px'
            heightRatio='24'
            heightRatioUnit='px'
            src={ICONS['ic-close-white-slim.svg']}
            onClick={onClickClose}
          />
        </Section>
        <Modal.Section
          borderTopRightRadius={
            VARIABLES.MODAL.HEADER.H_1.BORDER_TOP_RIGHT_RADIUS
          }
          borderTopLeftRadius={
            VARIABLES.MODAL.HEADER.H_1.BORDER_TOP_LEFT_RADIUS
          }
          borderBottomRightRadius={
            VARIABLES.MODAL.FOOTER.F_2.BORDER_BOTTOM_RIGHT_RADIUS
          }
          borderBottomLeftRadius={
            VARIABLES.MODAL.FOOTER.F_2.BORDER_BOTTOM_LEFT_RADIUS
          }
          maxHeight='480'
          overflow='hidden'
        >
          <React.Fragment>
            <SwiperCarousel
              className='is-reward-detail'
              setRef={(r) => (this.swiperCarouselRef = r)}
              options={{
                activeSlideKey: (activeSlideKey || 0).toString(),
                on: {
                  slideChangeTransitionEnd: () => {
                    if (this.swiperCarouselRef) {
                      const swiperDom = this.swiperCarouselRef.swiper.el;
                      swiperDom
                        .querySelectorAll('div.section-base.is-scroll')
                        .forEach((ev) => {
                          ev.scrollTop = 0;
                        });
                    }
                  },
                },
                pagination: {
                  el: '.swiper-pagination',
                },
              }}
            >
              {dataList.map((e, i) => (
                <SwiperCarousel.Item key={i}>
                  <Section
                    bgColor={VARIABLES.COLORS.WHITE}
                    fluid
                    borderBottomRightRadius={
                      VARIABLES.MODAL.FOOTER.F_2.BORDER_BOTTOM_RIGHT_RADIUS
                    }
                    borderBottomLeftRadius={
                      VARIABLES.MODAL.FOOTER.F_2.BORDER_BOTTOM_LEFT_RADIUS
                    }
                  >
                    <Section
                      spacingTop='24'
                      justify='center'
                      paddingHorizontal='24'
                      spacingBottom='20'
                    >
                      <Image
                        fill
                        widthRatio='224'
                        widthRatioUnit='px'
                        heightRatio='134'
                        heightRatioUnit='px'
                        src={e.rewardImagePath}
                      />
                    </Section>
                    <Section
                      ref={(r) => (this[`refSwiper_${i}`] = r)}
                      paddingTop='20'
                      bgColor={VARIABLES.COLORS.LIGHT_BLUE_5}
                      paddingHorizontal='24'
                      paddingBottom='10'
                      minHeight='302'
                      maxHeight='302'
                      scroll
                      borderBottomRightRadius={
                        VARIABLES.MODAL.FOOTER.F_2.BORDER_BOTTOM_RIGHT_RADIUS
                      }
                      borderBottomLeftRadius={
                        VARIABLES.MODAL.FOOTER.F_2.BORDER_BOTTOM_LEFT_RADIUS
                      }
                    >
                      <Section
                        maxWidth='185'
                        paddingVertical='10'
                        align='center'
                        height='60'
                        className='is-gradient-blue'
                        borderRadius='0 30px 30px 0'
                        spacingBottom='10'
                        justify='center'
                      >
                        <Section maxWidth='100' align='center' justify='center'>
                          <Text
                            align='center'
                            verticalAlign='center'
                            fontStyle={TYPOGRAPHYS.FIRST.BOLD.FS_16}
                            color={VARIABLES.COLORS.WHITE}
                            title={e.rewardName}
                            ellipsis
                          >
                            {e.rewardName}
                          </Text>
                        </Section>
                        <Section align='center' justify='center'>
                          <Text
                            align='center'
                            verticalAlign='center'
                            fontStyle={TYPOGRAPHYS.FIRST.BOLD.FS_16}
                            color={VARIABLES.COLORS.WHITE}
                            singleLine
                          >
                            {getLanguage('luckyDraw.rewardTitle').replace(
                              '[quantity]',
                              e.quantity
                            )}
                          </Text>
                        </Section>
                      </Section>
                      <Section>
                        <Text
                          fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_12}
                          color={VARIABLES.COLORS.BLACK}
                          breakAll
                        >
                          {e.rewardDetail}
                        </Text>
                      </Section>
                    </Section>
                  </Section>
                </SwiperCarousel.Item>
              ))}
            </SwiperCarousel>
          </React.Fragment>
        </Modal.Section>
      </Modal>
    );
  }
}
