import React from 'react'
import _ from 'lodash'
import axios from 'axios'
import {
  TransitionContainer,
  Container,
  Section,
  Text,
  Image,
  NavigationBar
} from './../../components'
import {
  VARIABLES,
  TYPOGRAPHYS,
  ICONS,
} from './../../themes'
import {
  ROUTE_PATH,
  redirect,
} from './../../helpers'
import {
  Loader
} from '../Loader'
import config from './../../config'
import { getLanguage } from '../../helpers/functions/language'

export class RewardPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isLoading: false,
      list: [],
    }
  }

  componentDidMount() {
    localStorage.removeItem('rewardGroupList')
    this.setState({ isLoading: true }, async () => {
      try {
        const url = `${config.api.cms}/rewardgroup/get/`
        const options = { sort: 'id desc', status: 'active' }
        const response = await axios.post(url, options)
        const data = _.get(response, 'data.data')
        if (response.status === 200 && data) {
          this.setState({ list: data }, () => {
            localStorage.setItem('rewardGroupList', JSON.stringify(data))
          });
        }
      } catch (_) {
      } finally {
        this.setState({ isLoading: false })
      }
    })
  }

  handleBack() {
    redirect(ROUTE_PATH.HOME.LINK);
  }

  onClickItem(id) {
    redirect(`${ROUTE_PATH.REWARD_CAT.LINK}/${id}`);
  }

  render() {
    // let data = [
    //   { id: 1, cat: 'ELECTRIC APPLIANCE', file: 'reward-electric-appliance.png', type: 'electric-appliance', labelColor: VARIABLES.COLORS.OVERLAY_12 },
    //   { id: 2, cat: 'INSTRUMENTS', file: 'reward-instruments.png', type: 'instruments', labelColor: VARIABLES.COLORS.OVERLAY_12 },
    //   { id: 3, cat: 'ACCESSORY', file: 'reward-accessory.png', type: 'accessory', labelColor: VARIABLES.COLORS.OVERLAY_12 },
    // ]
    const { list, isLoading } = this.state
    return (
      <TransitionContainer>
        <Container
          width={VARIABLES.WEBSITE_WIDTH}
          calcMinHeight='100vh'
          bgColor={VARIABLES.COLORS.LIGHT_BLUE_2}
        >
          <NavigationBar
            shadow
            iconWidth='20'
            iconHeight='20'
            iconSrc={ICONS['ic-navigation-reward.svg']}
            pageTitleIcon={getLanguage('reward.title')}
            actionLeft={
              <Image
                widthRatio='24'
                widthRatioUnit='px'
                heightRatio='23'
                heightRatioUnit='px'
                src={ICONS['ic-home-black.svg']}
                onClick={this.handleBack.bind(this)}
              />
            }
          />
          <Section
            scroll
            calcHeight='100vh - 70px'
            paddingVertical='25'
            paddingHorizontal='20'
          >
            {!_.isEmpty(list) && list.map((data, index) => (
              <Section
                className='is-reward-catalog'
                key={index}
                spacingBottom='20'
                onClick={this.onClickItem.bind(this, data.id)}
              >
                <Image
                  heightRatio='32'
                  src={`${config.imgPrefix}/${data.cover}`}
                />
                <Section
                  className='is-reward-catalog-name'
                  justify='center'
                  paddingTop='10'
                  paddingBottom='11'
                  bgColor={VARIABLES.COLORS.OVERLAY_12}
                >
                  <Text
                    singleLine
                    fontStyle={TYPOGRAPHYS.FIRST.BOLD.FS_11}
                    color={VARIABLES.COLORS.WHITE}
                  >
                    {data.name}
                  </Text>
                </Section>
              </Section>
            ))}
          </Section>
        </Container>
        <Loader loading={isLoading} isOverlay />
      </TransitionContainer>
    )
  }
}
