import styled, {
  // css
} from 'styled-components'
import {
  default as VARIABLES
} from './../../../themes/styles/bases/variables'
// import {
//   default as TYPOGRAPHYS
// } from './../../../themes/styles/bases/typographys'
// import {
//   default as MIXINS
// } from './../../../themes/styles/helpers/mixins'

// Sample using image in style
// url(${require('./../../../themes/images/icons/icon-sample.svg')});

// Wrapper
// ============================================================
export const NavigationBarWrapper = styled.div`
  /* Parent
  ------------------------------- */
  /* position: fixed;
  z-index: ${VARIABLES.Z_INDEXS.LV_2};
  top: 0;
  left: 0; */
  width: 100%;
  -webkit-box-shadow: 0px 2px 11px -5px rgba(148,190,211,1);
  -moz-box-shadow: 0px 2px 11px -5px rgba(148,190,211,1);
  box-shadow: 0px 2px 11px -5px rgba(148,190,211,1);

  /* Childrens
  ------------------------------- */
  .navigation-bar-container {
    position: relative;
    display: flex;
    align-items: center;
    height: 70px;
    padding-left: 20px;
    padding-right: 20px;
    background-color: ${VARIABLES.COLORS.WHITE};
  }

  .navigation-bar-action {
    position: absolute;
    z-index: ${VARIABLES.Z_INDEXS.LV_1};
    top: 0;
    display: flex;
    align-items: center;
    height: 100%;

    &.is-left {
      left: 20px;
    }

    &.is-right {
      right: 20px;
    }
  }

  /* Modifiers
  ------------------------------- */
  &.is-shadow {
    box-shadow: ${VARIABLES.COLORS.SHADOW_2};
  }

  &.is-gradient-white {
    box-shadow: none;
    .navigation-bar-container {
      background: rgb(255,255,255);
      background: linear-gradient(180deg, rgba(255,255,255,0.9867297260701156) 0%, rgba(255,255,255,0) 100%);
    }
    .is-circle {
      margin-top: -10px;
    }
  }

  /* Media queries
  ------------------------------- */
`
