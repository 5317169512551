import React from 'react'
import {
  connect,
} from 'react-redux';
import {
  TransitionContainer,
  Container,
  Section,
  Image,
  Button,
  Text,
  Field,
  NavigationBar,
  Modal,
  Grid,
} from './../../components';
import {
  VARIABLES,
  TYPOGRAPHYS,
  ICONS,
} from './../../themes';
import { ModalNoticeContainer } from '../../containers/ModalContainer/ModalNoticeContainer';
import {
  ROUTE_PATH,
  redirect,
  redirectGoBack,
  isValidNumber,
  isValidResponse,
} from './../../helpers';
import { getLanguage } from '../../helpers/functions/language';
import { onOpenModal, onOpenAlert, isShowLoading } from '../../helpers/functions/main';
import { DATA } from '../../helpers/constants/data';
import axios from 'axios';
import config from '../../config';
import { getCountryPhoneByCountry } from '../../helpers/functions/data';
import { fetchApi } from '../../apiService/apiService';

export class InputOtpContainer extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      otpList: ['', '', '', '', ''],
      otpSecret: '',
    }
  }

  componentDidMount() {
    this.otp.sendOtp();
  }

  otp = {
    onClickSubmitOtp: () => {
      const {
        callbackSuccess,
      } = this.props;
      const {
        otpList,
        otpSecret,
      } = this.state;
      if (otpList.filter(e => e === '').length) {
        onOpenAlert({
          type: DATA.ALERT_TYPE.ERROR,
          title: getLanguage('message.fail'),
          message: getLanguage('message.pleaseInputAllField'),
        })
      }
      else {
        isShowLoading(true);
        let token = '';
        otpList.forEach(e => {
          token += e;
        })
        axios.post(`${config.api.base}/api/otp/verify`, {
          token,
          secret: otpSecret
        }).then(async (res) => {
          const data = res.data;
          if (isValidResponse(data)) {
            callbackSuccess();
            isShowLoading(false);
          }
          else {
            onOpenAlert({
              type: DATA.ALERT_TYPE.ERROR,
              title: getLanguage('message.fail'),
              message: data.message,
              onClickClose: () => {
                this.setState({
                  otpList: ['', '', '', '', '']
                });
              }
            });
            isShowLoading(false);
          }
        });

      }
    },
    onCloseInputOtp: () => {
      const {
        onCloseInputOtp,
      } = this.props;
      onCloseInputOtp();
    },
    sendOtp: () => {
      const {
        sendOtpTo,
        userData,
        messageType
      } = this.props;
      isShowLoading(true);
      fetchApi.get(`${config.api.base}/api/otp/getsecret`).then((resGetSecret) => {
        const dataSecret = resGetSecret.data;
        if (isValidResponse(dataSecret)) {
          axios.post(`${config.api.base}/api/otp/send`, {
            to: sendOtpTo,
            first_name: userData.first_name,
            countries: userData.countries,
            message_type: messageType,
            secret: dataSecret.secret
          }).then((resSendOtp) => {
            const dataSendOtp = resSendOtp.data;
            if (isValidResponse(dataSendOtp)) {
              isShowLoading(false);
              onOpenModal({
                type: DATA.MODAL_TYPE.OTP_SEND
              });
              this.setState({
                otpSecret: dataSecret.secret,
              })
            }
            else {
              isShowLoading(false);
            }
          });
        }
        else {
          isShowLoading(false);
        }
      });
    }
  }

  input = {
    onChangeOtp: (ev, index) => {
      const {
        otpList,
      } = this.state;
      const value = ev.target.value;
      otpList[index] = value;
      this.setState({
        otpList,
      })
      if (index + 1 < otpList.length) {
        this[`refOtp${index + 1}`].focus();
      }
    }
  }

  render() {
    const {
      sendOtpTo,
    } = this.props;
    const {
      isOtpInCorrect,
      otpList,
    } = this.state;
    return (
      <TransitionContainer
      // motion='overlap-from'
      >
        <Container
          width={VARIABLES.WEBSITE_WIDTH}
          height='100'
          heightUnit='vh'
          bgColor={VARIABLES.COLORS.LIGHT_BLUE_2}
        >
          <NavigationBar
            shadow
            pageTitle={getLanguage('container.inputOtp.title')}
            actionLeft={
              <Image
                widthRatio='11'
                widthRatioUnit='px'
                heightRatio='21'
                heightRatioUnit='px'
                src={ICONS['ic-left-black.svg']}
                onClick={this.otp.onCloseInputOtp}
              />
            }
          />
          <Section
            scroll
            nowrap
            direction='column'
            justify='space-between'
            align='center'
            calcHeight='100vh - 70px'
            paddingTop='75'
            paddingBottom='55'
          >
            <Section width='300'>
              <Section>
                <Text
                  fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_20}
                  color={VARIABLES.COLORS.GRAY_12}
                >
                  OTP
                </Text>
              </Section>
              <Section spacingBottom='35'>
                <Text
                  fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_14}
                  color={VARIABLES.COLORS.GRAY_3}
                >
                  {getLanguage('container.inputOtp.message')} +{sendOtpTo}
                </Text>
              </Section>
              <Section
                position='relative'
                spacingBottom='70'
              >
                <Grid gutter='25'>
                  {
                    otpList.map((e, i) => {
                      return (
                        <Grid.Column flex='1'
                          key={i}
                        >
                          <Field error={isOtpInCorrect}
                            className='is-otp'
                            width='64'
                            bgColor='transparent'
                            inputTextType='number'
                            fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_40}
                            fontColor={VARIABLES.COLORS.BLUE_2}
                            errorColor={VARIABLES.COLORS.ORANGE_1}
                            textAlign='center'
                            borderWidth='0'
                            maxLength='1'
                            innerRef={r => this[`refOtp${i}`] = r}
                            onChange={(ev) => {
                              this.input.onChangeOtp(ev, i)
                            }}
                            value={e}
                          />
                        </Grid.Column>
                      )
                    })
                  }
                </Grid>
                {isOtpInCorrect &&
                  <Section
                    className='is-otp-incorrect-message'
                    justify='center'
                  >
                    <Text
                      fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_14}
                      color={VARIABLES.COLORS.ORANGE_1}
                    >
                      {getLanguage('container.inputOtp.message_2')}
                    </Text>
                  </Section>
                }
              </Section>
              <Section justify='center'>
                <Section width='275'>
                  <Section spacingBottom='15'>
                    <Button
                      fluid
                      shadow
                      className='is-gradient-blue'
                      height={VARIABLES.BUTTON.HEIGHTS.H_40}
                      borderRadius={VARIABLES.BUTTON.BORDER_RADIUSES.BR_10}
                      onClick={this.otp.onClickSubmitOtp}
                    >
                      <Text
                        fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_16}
                        color={VARIABLES.COLORS.WHITE}
                      >
                        {getLanguage('field.buttonSubmit')}
                      </Text>
                    </Button>
                  </Section>
                  <Section>
                    <Button
                      fluid
                      height={VARIABLES.BUTTON.HEIGHTS.H_40}
                      bgColor={VARIABLES.COLORS.TRANSPARENT}
                      borderRadius={VARIABLES.BUTTON.BORDER_RADIUSES.BR_10}
                      borderWidth='1'
                      borderStyle='solid'
                      borderColor={VARIABLES.COLORS.BLUE_2}
                      onClick={this.otp.sendOtp}
                    >
                      <Text
                        fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_16}
                        color={VARIABLES.COLORS.BLUE_2}
                      >
                          {getLanguage('field.buttonReSend')}
                      </Text>
                    </Button>
                  </Section>
                </Section>
              </Section>
            </Section>
          </Section>
        </Container>
      </TransitionContainer >
    )
  }
}

const mapStateToProps = (state) => {
  const mainRedux = state.mainRedux;
  const alertData = mainRedux.alertData;
  return {
    isOpen: alertData.isOpen,
    title: alertData.title,
    message: alertData.message,
    onClickClose: alertData.onClickClose,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
  }
}

export const InputOtpContainerConnected = connect(
  mapStateToProps,
  mapDispatchToProps
)(InputOtpContainer)