import _ from "lodash";
import { ModelAd } from "../../models/ModelAd";
import { adController } from "../../apiService/apiController/adService";
import { isValidResponse } from "../functions";

export const getAuthorizedDealer = async () => {
  const adService = adController();
  const res = await adService.getAuthorizedDealer();
  if (isValidResponse(res)) {
    let data = res.data.map((e) => new ModelAd(e));
    console.log('getAuthorizedDealer', res.data, data);
    return data;
  }
};