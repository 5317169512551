import React from 'react'
import ClassNames from 'classnames'
// import PropTypes from 'prop-types'
import {
  TextBase
} from './../../base'
// import {
// } from './../../../components'
// import {
//   default as VARIABLES
// } from './../../../themes/styles/bases/variables'
// import {
//   default as TYPOGRAPHYS
// } from './../../../themes/styles/bases/typographys'
// import {
//   default as UTILITIES
// } from './../../../themes/styles/helpers/utilities'

/**
 * Text description:
 * - Text
 */

export class Text extends React.PureComponent {
  render () {
    const {
      className,
      ellipsisTwoLine,
      underline,
      underlineWhite,
      inline,
      bold,
      breakAll,
      noWrap
    } = this.props

    // props for css classes
    const classes = ClassNames(
      className,
      { 'is-ellipsis-two-line': ellipsisTwoLine },
      { 'is-underline': underline },
      { 'is-underline-white': underlineWhite },
      { 'is-inline': inline },
      { 'is-bold': bold },
      { 'is-break-all': breakAll },
      { 'is-no-wrap': noWrap },
    )

    return (
      <TextBase
        {...this.props}
        className={classes}
      />
    )
  }

  static defaultProps = {
    // * If you set new default props, you should create propsType for correct styleguide document.
  }

  static propTypes = {
    // TYPE > node, string, number, bool, array, object, symbol, func
    /**
    * props_name_oneType
    */
    // props_name_onetype: PropTypes.string,

    /**
    * props_name_oneMoreType
    */
    // props_name_oneMoreType: PropTypes.oneOfType([
    //   PropTypes.node,
    //   PropTypes.string
    // ])
  }
}
