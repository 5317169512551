import { warrantyController } from "../../apiService/apiController/warrantyService";
import { isValidResponse } from "../functions";
import { handleTrimParams } from "../functions/data";
import _ from "lodash";
import { ModelWarranty } from "../../models/ModelWarranty";

export const getSearchWarranty = async (params) => {
  const warrantyService = warrantyController();
  let newParams = handleTrimParams({
    ...params
  })
  const res = await warrantyService.getSearchWarranty(newParams);
  if (isValidResponse(res)) {
    let dataList = res.data.map((e) => new ModelWarranty(e));
    console.log('getSearchWarranty', res.data, dataList);
    return dataList;
  }
  return [];
}