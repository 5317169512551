/* eslint-disable no-empty-pattern */
import { userController } from "../../apiService/apiController/userService";
import { isValidResponse } from "../functions";
import { handleTrimParams } from "../functions/data";
import _ from "lodash";
import { ModelUser } from "../../models/ModelUser";

// export const usersRegister = async (params) => {
//   const userService = userController();
//   const res = await userService.usersRegister(params);
//   if (isValidResponse(res)) {
//     let data = res.data;
//     console.log('usersRegister', res.data, data);
//     return data;
//   }
// }

// export const searchUser = async (params) => {
//   const userService = userController();
//   let newParams = handleTrimParams({
//     ...params
//   })
//   const res = await userService.searchUser(newParams);
//   if (isValidResponse(res)) {
//     let dataList = res.data;
//     console.log('searchUser', res.data, dataList);
//     return dataList;
//   }
//   return []
// }

export const getUserBy = async (params) => {
  const userService = userController();
  const res = await userService.getUserBy(params);
  if (isValidResponse(res)) {
    let data = new ModelUser(res.data);
    console.log('getUserBy', res.data, data);
    return data;
  }
}

// export const getUserByUid = async (params) => {
//   const userService = userController();
//   const res = await userService.getUserByUid(params);
//   if (isValidResponse(res)) {
//     let data = new ModelUser(res.data);
//     console.log('getUserByUid', res, data);
//     return data;
//   }
// }

// export const usersIsExistByUid = async (params) => {
//   const userService = userController();
//   const res = await userService.usersIsExistByUid(params);
//   if (isValidResponse(res)) {
//     let data = res.data;
//     console.log('usersIsExistByUid', res.data, data);
//     return data;
//   }
// }

export const getUserById = async (params) => {
  const userService = userController();
  const res = await userService.getUserById(params);
  if (isValidResponse(res)) {
    let data = new ModelUser(res.data);
    console.log('getUserById', res.data, data);
    return data;
  }
}

export const usersUpload = async (params, { }) => {
  const userService = userController();
  const res = await userService.usersUpload(params, {});
  if (isValidResponse(res)) {
    let data = res.data;
    console.log('usersUpload', res.data, data);
    return data;
  }
}

export const updateUserInfo = async (params) => {
  const userService = userController();
  const res = await userService.updateUserInfo(params);
  if (isValidResponse(res)) {
    let data = res;
    console.log('updateUserInfo', res, data);
    return data;
  }
}

// export const usersConfirmProfile = async (params) => {
//   const userService = userController();
//   const res = await userService.usersConfirmProfile(params);
//   if (isValidResponse(res)) {
//     let data = res.data;
//     return data;
//   }
// }

// export const usersChangePassword = async (params) => {
//   const userService = userController();
//   await userService.usersChangePassword(params);
//   return 'success'
// }

// export const deleteUser = async (params) => {
//   const userService = userController();
//   const res = await userService.deleteUser(params)
//   if (isValidResponse(res)) {
//     let data = res.data;
//     console.log('deleteUser', res, data)
//     return data;
//   }
// }
