import React from 'react'
import _ from 'lodash'
import {
  SwiperCarousel,
  Image,
  Section,
  Text,
  Grid,
  Button,
} from '../../components'
import {
  VARIABLES,
  TYPOGRAPHYS,
  // CONTENTS,
  ICONS,
} from '../../themes'
import {
  ROUTE_PATH,
  redirect,
} from '../../helpers'
import {
  getDistanceFromLatLonInKm
} from '../../helpers/functions/data'
import config from './../../config'
import { getLanguage } from '../../helpers/functions/language'
import { getUserAuth } from '../../helpers/functions/auth'
import { ENUM } from '../../helpers/constants/enum'

export class DealerCard extends React.Component {

  constructor(props) {
    super(props);
    const userAuth = getUserAuth() || {};
    this.state = {
      isMalay: userAuth.countries.toLowerCase() === ENUM.COUNTRY.MALAYSIA.toLowerCase(),
    }
  }

  renderRating(rating) {
    if (!rating) return
    rating = parseFloat(rating)
    const stars = []
    for (let i = 0; i < Math.floor(rating); i++) {
      if (i === 5) break
      stars.push(<Grid.Column key={i}>
        <Image
          widthRatio='14'
          widthRatioUnit='px'
          heightRatio='14'
          heightRatioUnit='px'
          src={ICONS['ic-star-blue.svg']}
        />
      </Grid.Column >)
    }

    return (
      <Grid gutter='2' justify='flex-end'>
        {stars}
        <Grid.Column>
          <Section
            paddingTop='2'
          >
            <Text
              fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_12}
              color={VARIABLES.COLORS.BLUE_2}
            > {rating.toFixed(1)}
            </Text>
          </Section>
        </Grid.Column>
      </Grid>
    )
  }

  render() {
    const { data, index, userLatitude, userLongitude } = this.props;
    const {
      isMalay,
    } = this.state;
    // force value when empty gallery for display img box
    const gallery = _.isArray(data.gallery) ? data.gallery : ['']
    let distance = null
    if (userLatitude && userLongitude) {
      distance = getDistanceFromLatLonInKm(userLatitude, userLongitude, data.latitude, data.longitude)
      distance = distance.toFixed(2)
    }
    return (
      <Section
        className={`is-dealer-card ${this.props.mini ? 'is-mini' : ''}`}
        width={VARIABLES.WEBSITE_WIDTH}
        maxWidth={VARIABLES.WEBSITE_WIDTH}
        bgColor={VARIABLES.COLORS.WHITE}
        borderRadius={5}
      >
        <Section
          relative
          borderRadius={5}
        >
          <Section relative>
            <SwiperCarousel className='is-dealer-card'>
              {gallery.map((img, i) => (
                <SwiperCarousel.Item key={i}>
                  <Image
                    className='is-dealer-card-img image-ratio-base'
                    widthRatio='100'
                    widthRatioUnit='%'
                    heightRatio='97'
                    heightRatioUnit='px'
                    // src={`${config.imgPrefix}/${img}`}
                    src={`${img}`}
                  />
                  <Section
                    className='is-dealer-card-number'
                    bgColor={VARIABLES.COLORS.BLUE_3}
                    position='absolute'
                    width='32'
                    height='32'
                    justify='center'
                    align='center'
                  >
                    <Text
                      fontStyle={TYPOGRAPHYS.FIRST.BOLD.FS_14}
                      color={VARIABLES.COLORS.WHITE}
                    >
                      {index}
                    </Text>
                  </Section>
                  {distance && <Section
                    className='is-dealer-card-distance'
                    bgColor='#00000080'
                    position='absolute'
                    width='auto'
                    paddingHorizontal='10'
                    borderRadius='15'
                    height='23'
                    justify='center'
                    align='center'
                  >
                    <Text
                      fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_11}
                      color={VARIABLES.COLORS.WHITE}
                    >
                      {distance} km
                  </Text>
                  </Section>}
                </SwiperCarousel.Item>
              ))}
              {/* <SwiperCarousel.Item>
                <Image
                  className='is-dealer-card-img image-ratio-base'
                  widthRatio='100'
                  widthRatioUnit='%'
                  heightRatio='97'
                  heightRatioUnit='px'
                  src={CONTENTS['dealer-detail-2.png']}
                />
                <Section
                  className='is-dealer-card-number'
                  bgColor={VARIABLES.COLORS.BLUE_3}
                  position='absolute'
                  width='auto'
                  paddingHorizontal='12'
                  height='32'
                  justify='center'
                  align='center'
                >
                  <Text
                    fontStyle={TYPOGRAPHYS.FIRST.BOLD.FS_14}
                    color={VARIABLES.COLORS.WHITE}
                  >
                    1
                  </Text>
                </Section>
                <Section
                  className='is-dealer-card-distance'
                  bgColor='#00000080'
                  position='absolute'
                  width='auto'
                  paddingHorizontal='10'
                  borderRadius='15'
                  height='23'
                  justify='center'
                  align='center'
                >
                  <Text
                    fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_11}
                    color={VARIABLES.COLORS.WHITE}
                  >
                    0.58 km
                  </Text>
                </Section>
              </SwiperCarousel.Item> */}
            </SwiperCarousel>
          </Section>
        </Section>
        <Section
          paddingTop='12'
          paddingHorizontal='15'
        >
          <Grid gutter='10'>
            <Grid.Column flex='2'>
              <Text
                fontStyle={TYPOGRAPHYS.FIRST.BOLD.FS_14}
                color={VARIABLES.COLORS.BLACK}
              >
                {data.name}
              </Text>
            </Grid.Column>
            {data.total_rating > 0 &&
              <Grid.Column flex='1'>
                {this.renderRating(data.total_rating)}
              </Grid.Column>
            }
          </Grid>
          {data.opening_hours && <Section>
            <Text
              fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_12}
              color={VARIABLES.COLORS.GRAY_3}
            >
              {/* Open until 17:30 */}
              Open {data.opening_hours}{`${data.close_hours ? ` - ${data.close_hours}` : ''}`}
            </Text>
          </Section>}
          <Section
            paddingTop='11'
          >
            <Text
              fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_12}
              color={VARIABLES.COLORS.GRAY_3}
            >
              {/* 954/2 Ekkachai Road, Kh.Bangbon<br />
              Bangkok, 10150 */}
              {data.billing_address ? data.billing_address : ''}
            </Text>
          </Section>
          <Section
            paddingTop='11'
          >
            <Grid gutter='5'>
              <Grid.Column>
                <Image
                  widthRatio='14'
                  widthRatioUnit='px'
                  heightRatio='14'
                  heightRatioUnit='px'
                  src={ICONS['ic-phone-blue.svg']}
                />
              </Grid.Column>
              <Grid.Column>
                <Text
                  fontStyle={TYPOGRAPHYS.FIRST.BOLD.FS_12}
                  color={VARIABLES.COLORS.BLUE_2}
                  top='2'
                >
                  Tel. {data.phone_number}
                </Text>
              </Grid.Column>
            </Grid>
          </Section>
          <Section
            paddingTop='20'
            paddingBottom='20'
          >
            <Grid gutter='15'>
              {!isMalay &&
                <Grid.Column flex='1'>
                  <Button
                    shadow
                    className='is-gradient-blue'
                    fluid
                    height={31}
                    borderRadius={VARIABLES.BUTTON.BORDER_RADIUSES.BR_10}
                    onClick={() => {

                      window.location.href = `https://www.google.com/maps/search/?api=1&query=${data.latitude},${data.longitude}`;
                    }}
                  >
                    <Text
                      fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_14}
                      color={VARIABLES.COLORS.WHITE}
                    >
                      {getLanguage('findDealerSearch.dealerCard.buttonGetDirection')}
                    </Text>
                  </Button>
                </Grid.Column>
              }
              <Grid.Column flex='1'>
                <Button
                  shadow
                  className='is-gradient-blue'
                  fluid
                  height={31}
                  borderRadius={VARIABLES.BUTTON.BORDER_RADIUSES.BR_10}
                  onClick={() => redirect(`${ROUTE_PATH.FIND_DEALER_LIST.LINK}/${data.id}`)}
                >
                  <Text
                    fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_14}
                    color={VARIABLES.COLORS.WHITE}
                  >
                    {getLanguage('findDealerSearch.dealerCard.buttonViewDetail')}
                  </Text>
                </Button>
              </Grid.Column>
            </Grid>
          </Section>
        </Section>
      </Section>
    )
  }
}
