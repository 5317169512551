import styled, {
  css
} from 'styled-components'
// import {
//   default as VARIABLES
// } from './../../../themes/styles/bases/variables'
// import {
//   default as TYPOGRAPHYS
// } from './../../../themes/styles/bases/typographys'
// import {
//   default as MIXINS
// } from './../../../themes/styles/helpers/mixins'

// Sample using image in style
// url(${require('./images/icons/icon-sample.svg')});
// url(${require('./../../../themes/images/icons/icon-sample.svg')});

// Wrapper
// ============================================================
export const ContainerBaseWrapper = styled.div`
  /* Parent
  ------------------------------- */
  display: flex;
  width: 100%;
  margin-left: auto;
  margin-right: auto;

  ${props => {
    const theme = props.theme
    return css`
      flex-direction: ${theme.direction};
      flex-wrap: ${theme.wrap};
      justify-content: ${theme.justify};
      align-items: ${theme.align};
      background-color: ${theme.bgColor};
      background-repeat: ${theme.bgRepeat};
      background-size: ${theme.bgSize};
      background-position-x: ${theme.bgPositionX};
      background-position-y: ${theme.bgPositionY};
      background-position: ${theme.bgPosition};

      ${theme.width && css`
          max-width: ${theme.width + theme.widthUnit};
        `
      }

      ${theme.height && css`
          height: ${theme.height + theme.heightUnit};
        `
      }

      ${theme.calcHeight && css`
          height: calc(${theme.calcHeight});
        `
      }

      ${theme.calcMinHeight && css`
          min-height: calc(${theme.calcMinHeight});
        `
      }

      ${theme.padding && css`
          padding: ${theme.padding + theme.paddingUnit};
        `
      }

      ${theme.paddingHorizontal && css`
          padding-right: ${theme.paddingHorizontal + theme.paddingUnit};
          padding-left: ${theme.paddingHorizontal + theme.paddingUnit};
        `
      }

      ${theme.paddingVertical && css`
          padding-top: ${theme.paddingVertical + theme.paddingUnit};
          padding-bottom: ${theme.paddingVertical + theme.paddingUnit};
        `
      }

      ${theme.paddingTop && css`
          padding-top: ${theme.paddingTop + theme.paddingUnit};
        `
      }

      ${theme.paddingRight && css`
          padding-right: ${theme.paddingRight + theme.paddingUnit};
        `
      }

      ${theme.paddingBottom && css`
          padding-bottom: ${theme.paddingBottom + theme.paddingUnit};
        `
      }

      ${theme.paddingLeft && css`
          padding-left: ${theme.paddingLeft + theme.paddingUnit};
        `
      }

      ${theme.bgImage && css`
          background-image: url(${theme.bgImage});
        `
      }

      @media (max-width: ${theme.breakpointPaddingMobile}) {
        ${theme.widthMobile && css`
            max-width: ${theme.widthMobile + theme.widthMobileUnit};
          `
        }

        ${theme.heightMobile && css`
            height: ${theme.heightMobile + theme.heightMobileUnit};
          `
        }

        ${theme.calcHeightMobile && css`
            height: calc(${theme.calcHeightMobile});
          `
        }

        ${theme.calcMinHeightMobile && css`
            min-height: calc(${theme.calcMinHeightMobile});
          `
        }

        ${theme.paddingMobile && css`
            padding: ${theme.paddingMobile + theme.paddingUnit};
          `
        }

        ${theme.paddingHorizontalMobile && css`
            padding-right: ${theme.paddingHorizontalMobile + theme.paddingUnit};
            padding-left: ${theme.paddingHorizontalMobile + theme.paddingUnit};
          `
        }

        ${theme.paddingVerticalMobile && css`
            padding-top: ${theme.paddingVerticalMobile + theme.paddingUnit};
            padding-bottom: ${theme.paddingVerticalMobile + theme.paddingUnit};
          `
        }

        ${theme.paddingTopMobile && css`
            padding-top: ${theme.paddingTopMobile + theme.paddingUnit};
          `
        }

        ${theme.paddingRightMobile && css`
            padding-right: ${theme.paddingRightMobile + theme.paddingUnit};
          `
        }

        ${theme.paddingBottomMobile && css`
            padding-bottom: ${theme.paddingBottomMobile + theme.paddingUnit};
          `
        }

        ${theme.paddingLeftMobile && css`
            padding-left: ${theme.paddingLeftMobile + theme.paddingUnit};
          `
        }
      }

      @media (min-width: ${theme.breakpointPaddingPhabletMin}) and (max-width: ${theme.breakpointPaddingPhabletMax}) {
        ${theme.widthPhablet && css`
            max-width: ${theme.widthPhablet + theme.widthPhabletUnit};
          `
        }

        ${theme.heightPhablet && css`
            height: ${theme.heightPhablet + theme.heightPhabletUnit};
          `
        }

        ${theme.calcHeightPhablet && css`
            height: calc(${theme.calcHeightPhablet});
          `
              }

        ${theme.calcMinHeightPhablet && css`
            min-height: calc(${theme.calcMinHeightPhablet});
          `
        }

        ${theme.paddingPhablet && css`
            padding: ${theme.paddingPhablet + theme.paddingUnit};
          `
        }

        ${theme.paddingHorizontalPhablet && css`
            padding-right: ${theme.paddingHorizontalPhablet + theme.paddingUnit};
            padding-left: ${theme.paddingHorizontalPhablet + theme.paddingUnit};
          `
        }

        ${theme.paddingVerticalPhablet && css`
            padding-top: ${theme.paddingVerticalPhablet + theme.paddingUnit};
            padding-bottom: ${theme.paddingVerticalPhablet + theme.paddingUnit};
          `
        }

        ${theme.paddingTopPhablet && css`
            padding-top: ${theme.paddingTopPhablet + theme.paddingUnit};
          `
        }

        ${theme.paddingRightPhablet && css`
            padding-right: ${theme.paddingRightPhablet + theme.paddingUnit};
          `
        }

        ${theme.paddingBottomPhablet && css`
            padding-bottom: ${theme.paddingBottomPhablet + theme.paddingUnit};
          `
        }

        ${theme.paddingLeftPhablet && css`
            padding-left: ${theme.paddingLeftPhablet + theme.paddingUnit};
          `
        }
      }

      @media (min-width: ${theme.breakpointPaddingTabletMin}) and (max-width: ${theme.breakpointPaddingTabletMax}) {
        ${theme.widthTablet && css`
            max-width: ${theme.widthTablet + theme.widthTabletUnit};
          `
        }

        ${theme.heightTablet && css`
            height: ${theme.heightTablet + theme.heightTabletUnit};
          `
        }

        ${theme.calcHeightTablet && css`
            height: calc(${theme.calcHeightTablet});
          `
        }

        ${theme.calcMinHeightTablet && css`
            min-height: calc(${theme.calcMinHeightTablet});
          `
        }

        ${theme.paddingTablet && css`
            padding: ${theme.paddingTablet + theme.paddingUnit};
          `
        }

        ${theme.paddingHorizontalTablet && css`
            padding-right: ${theme.paddingHorizontalTablet + theme.paddingUnit};
            padding-left: ${theme.paddingHorizontalTablet + theme.paddingUnit};
          `
        }

        ${theme.paddingVerticalTablet && css`
            padding-top: ${theme.paddingVerticalTablet + theme.paddingUnit};
            padding-bottom: ${theme.paddingVerticalTablet + theme.paddingUnit};
          `
        }

        ${theme.paddingTopTablet && css`
            padding-top: ${theme.paddingTopTablet + theme.paddingUnit};
          `
        }

        ${theme.paddingRightTablet && css`
            padding-right: ${theme.paddingRightTablet + theme.paddingUnit};
          `
        }

        ${theme.paddingBottomTablet && css`
            padding-bottom: ${theme.paddingBottomTablet + theme.paddingUnit};
          `
        }

        ${theme.paddingLeftTablet && css`
            padding-left: ${theme.paddingLeftTablet + theme.paddingUnit};
          `
        }
      }
    `
  }}

  /* Childrens
  ------------------------------- */
  /* Modifiers
  ------------------------------- */
  /* Media queries
  ------------------------------- */
`
