import styled, {
  // css
} from 'styled-components'
import { VARIABLES } from '../../../themes';
// import {
//   default as VARIABLES
// } from './../../../themes/styles/bases/variables'
import {
  default as TYPOGRAPHYS
} from './../../../themes/styles/bases/typographys'
// import {
//   default as MIXINS
// } from './../../../themes/styles/helpers/mixins'

// Sample using image in style
// url(${require('./../../../themes/images/icons/icon-sample.svg')});

// Wrapper
// ============================================================
export const ReactTabsControlWrapper = styled.div`
  /* Parent
  ------------------------------- */
  width: 100%;

  /* Childrens
  ------------------------------- */
  .react-tabs {}

  .react-tabs__tab-list {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 0;
    background-color: ${VARIABLES.COLORS.WHITE};
    border-bottom: none;
  }

  .react-tabs__tab {
    ${TYPOGRAPHYS.FIRST.REGULAR.FS_14};
    transition: ${VARIABLES.TRANSITIONS.DEFAULT};
    display: block;
    flex: 1;
    bottom: 0;
    padding: 8px 0;
    border: none;
    border-bottom: 2px solid ${VARIABLES.COLORS.GRAY_7};
    color: ${VARIABLES.COLORS.GRAY_3};
    text-align: center;

    &:focus {
      // border: none;
      box-shadow: none;
    }

    &.react-tabs__tab--selected {
      background: ${VARIABLES.COLORS.TRANSPARENT};
      border-color: ${VARIABLES.COLORS.BLUE_2};
      border-radius: 0;
      color: ${VARIABLES.COLORS.BLUE_2};
      &:focus {
        &:after {
          display: none;
        }
      }
    }
  }

  .react-tabs__tab-panel {
    &.react-tabs__tab-panel--selected {

    }
  }

  /* Modifiers
  ------------------------------- */

  /* Media queries
  ------------------------------- */
`
