import React from 'react'
import {
  BrowserRouter as Router,
  Route,
} from 'react-router-dom'
import {
  Provider,
} from 'react-redux'
import {
  getCreateStore,
} from './../src/store'
import {
  RouteContainer,
} from './../src/containers'
import {
  DeviceDetect
} from './../src/components'

const store = getCreateStore()

export default class App extends React.Component {
  render() {
    return (
      <Provider
        store={store}
      >
        <DeviceDetect>
          <Router>
            <Route path='/' component={RouteContainer} />
          </Router>
        </DeviceDetect>
      </Provider>
    )
  }
}
