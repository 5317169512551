import React from 'react'
import {
  Image,
  Section,
  Text,
  Button,
  Grid,
} from '../../components'
import {
  VARIABLES,
  TYPOGRAPHYS,
  ICONS,
} from './../../themes'
import {
  ROUTE_PATH,
  redirect,
} from './../../helpers'
import { isEmpty } from 'lodash';
import { getLanguage } from '../../helpers/functions/language';


export class SearchResult extends React.Component {
  render() {
    const { data } = this.props
    return (
      <Section
        paddingTop='10'
        paddingBottom='25'
      >
        <Section spacingBottom='10'>
          <Text
            fontStyle={TYPOGRAPHYS.FIRST.MEDIUM.FS_16}
            color={VARIABLES.COLORS.BLACK}
            ellipsis
          >
            {getLanguage('projectsSearch.searchResult')} {data.length}
          </Text>
        </Section>
        {/* TODO: USE COMPONENT MY PROJECT PAGE*/}
        {!isEmpty(data) && (data || []).map((data, index) => (<Section
          className='is-my-project'
          spacingBottom='20'
          bgColor={VARIABLES.COLORS.WHITE}
          paddingVertical='10'
          paddingLeft='10'
          paddingRight='30'
          key={index}
          position='sticky'
          onClick={() => {
            redirect(`${ROUTE_PATH.PROJECT_DETAIL.LINK}/${data.id}`)
          }}
        >
          <Grid gutter='12' align='center'>
            <Grid.Column>
              <Image
                widthRatio='60'
                widthRatioUnit='px'
                heightRatio='60'
                heightRatioUnit='px'
                borderRadius='10'
                src={(data.image_url && data.image_url.length) ? `${data.image_url[0]}` : 'https://www.amrita.edu/sites/default/files/school-of-business-research.jpg'}
              />
            </Grid.Column>
            <Grid.Column grid={7}>
              <Section>
                <Text
                  fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_15}
                  ellipsis
                >
                  {data.name}
                </Text>
              </Section>
              <Grid gutter='10'>
                <Grid.Column>
                  <Image
                    widthRatio='13'
                    widthRatioUnit='px'
                    heightRatio='15'
                    heightRatioUnit='px'
                    top='-2'
                    src={ICONS['ic-pin-blue.png']}
                  />
                </Grid.Column>
                <Grid.Column>
                  <Text
                    fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_12}
                    color={VARIABLES.COLORS.BLUE_2}
                    ellipsis
                  >
                    {data.location}
                  </Text>
                </Grid.Column>
              </Grid>
            </Grid.Column>
            <Grid.Column grid={1} className='is-right'>
              <Button
                fluid
                className='is-icon'
                width={20}
                height={20}
                borderRadius={30}
                bgColor={VARIABLES.COLORS.BLUE_2}
              >
                <Button.Icon>
                  <Image
                    left='-1'
                    widthRatio='10'
                    widthRatioUnit='px'
                    heightRatio='8'
                    heightRatioUnit='px'
                    src={ICONS['ic-union.png']}
                  />
                </Button.Icon>
              </Button>
            </Grid.Column>
          </Grid>
        </Section>))}
      </Section>
    )
  }
}
