import {
  createActions
} from 'reduxsauce'

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  setMainRedux: ['key', 'data'],
})

export const MAIN_REDUX_TYPES = Types
export const mainCreators = Creators

/* ------------- Initial State ------------- */
export const MAIN_REDUX_STATE_KEY = {
  // USER_AUTH: 'userAuth',
  IS_SHOW_LOADING: 'isShowLoading',
  DEV_HOSTNAME_LIST: 'devHostnameList',
  AUTH_APP: 'authApp',
  LINE_DATA: 'lineData',
  MODAL_DATA: 'modalData',
  ALERT_DATA: 'alertData',
}

const devHostnameList = ['localhost', '192.168.1.35']

export const MAIN_REDUX_INITIAL_STATE = {
  // userAuth: {},
  isShowLoading: false,
  devHostnameList,
  authApp: {
    isAuthRedirect: devHostnameList.indexOf(window.location.hostname) >= 0 ? true : undefined,
  },
  lineData: {},
  modalData: {},
  alertData: {},
}