import React from 'react'
import _ from 'lodash'
import axios from 'axios'
import {
  TransitionContainer,
  Container,
  Section,
  Text,
  Image,
  NavigationBar
} from './../../components'
import {
  VARIABLES,
  TYPOGRAPHYS,
  ICONS,
} from './../../themes'
import {
  ROUTE_PATH,
  redirect,
} from './../../helpers'
import {
  Loader
} from '../Loader'
import config from './../../config'
import { getLanguage } from '../../helpers/functions/language'
import { getAvailableCountryDataList } from '../../helpers/functions/data'

export class ProductPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isLoading: false,
      list: [],
    }
  }

  componentDidMount() {
    localStorage.removeItem('productGroupList')
    this.setState({ isLoading: true }, async () => {
      try {
        const url = `${config.api.cms}/productgroup/get/`
        const options = {
          sort: 'id desc',
          status: 'active'
        }
        const response = await axios.post(url, options)
        let data = _.get(response, 'data.data')
        if (response.status === 200 && data) {
          data = getAvailableCountryDataList(data);
          this.setState({ list: data }, () => {
            localStorage.setItem('productGroupList', JSON.stringify(data))
          });
        }
      } catch (_) {
      } finally {
        this.setState({ isLoading: false })
      }
    })
  }


  handleBack() {
    redirect(ROUTE_PATH.HOME.LINK);
  }

  onClickItem(id, index) {
    redirect(`${ROUTE_PATH.PRODUCTCAT.LINK}/${id}?c-index=${index}`);
  }

  render() {
    const { list, isLoading } = this.state
    // let data = [
    //   // { id: 1, cat: 'COATED STEEL', file: 'product-coated-steel.png', type: 'coatedsteel', labelColor: VARIABLES.COLORS.OVERLAY_7 },
    //   { id: 2, cat: 'ROOFING & WALLING', file: 'product-solution-roofing-walling.png', type: 'roofingandwalling', labelColor: VARIABLES.COLORS.OVERLAY_8 },
    //   { id: 3, cat: 'FARMING & TRUSS', file: 'product-solution-framing-truss.png', type: 'farmingandtruss', labelColor: VARIABLES.COLORS.OVERLAY_9 },
    //   { id: 4, cat: 'SHED', file: 'product-solution-shed.png', type: 'shed', labelColor: VARIABLES.COLORS.OVERLAY_10 }
    // ]

    const labelColors = [VARIABLES.COLORS.OVERLAY_8, VARIABLES.COLORS.OVERLAY_9, VARIABLES.COLORS.OVERLAY_10];
    
    return (
      <TransitionContainer>
        <Container
          width={VARIABLES.WEBSITE_WIDTH}
          calcMinHeight='100vh'
          bgColor={VARIABLES.COLORS.LIGHT_BLUE_2}
        >
          <NavigationBar
            shadow
            iconWidth='16'
            iconHeight='17'
            iconSrc={ICONS['ic-navigation-product.svg']}
            pageTitleIcon={getLanguage('productCatalogs.title')}
            actionLeft={
              <Image
                widthRatio='24'
                widthRatioUnit='px'
                heightRatio='23'
                heightRatioUnit='px'
                src={ICONS['ic-home-black.svg']}
                onClick={this.handleBack.bind(this)}
              />
            }
          />
          <Section
            scroll
            calcHeight='100vh - 70px'
            paddingVertical='25'
            paddingHorizontal='20'
          >
            {!_.isEmpty(list) && list.map((data, index) => (
              <Section
                className='is-product-catalog'
                key={index}
                spacingBottom='20'
                onClick={this.onClickItem.bind(this, data.id, index)}
              >
                <Image
                  heightRatio='32'
                  src={`${config.imgPrefix}/${data.cover}`}
                />
                <Section
                  className='is-product-catalog-name'
                  justify='center'
                  paddingTop='10'
                  paddingBottom='11'
                  bgColor={labelColors[index % 3]}
                >
                  <Text
                    singleLine
                    fontStyle={TYPOGRAPHYS.FIRST.BOLD.FS_11}
                    color={VARIABLES.COLORS.WHITE}
                  >
                    {data.category_name}
                  </Text>
                </Section>
              </Section>
            ))}
          </Section>
        </Container>
        <Loader loading={isLoading} isOverlay />
      </TransitionContainer>
    )
  }
}
