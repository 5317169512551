import React from 'react'
import {
  TransitionContainer,
  Container,
  Section,
  Image,
  NavigationBar
} from './../../components'
import {
  VARIABLES,
  CONTENTS,
  ICONS,
} from './../../themes'
// import {
//   ROUTE_PATH,
//   redirect,
// } from './../../helpers'
// import axios from 'axios'
// import { AlertModal } from '../AlertModal'
// import { Loader } from '../Loader'

export class ProductDetailBrochurePage extends React.Component {

  // handleBack() {
  //   redirect(ROUTE_PATH.PRODUCT_DETAIL.LINK);
  // }

  render() {
    return (
      <TransitionContainer
      // motion='overlap-from'
      >
        <Container
          width={VARIABLES.WEBSITE_WIDTH}
          calcMinHeight='100vh'
          bgColor={VARIABLES.COLORS.LIGHT_BLUE_2}
        >
          <NavigationBar
            shadow
            pageTitle='Indonesia COLORBOND®'
            actionLeft={
              <Image
                widthRatio='11'
                widthRatioUnit='px'
                heightRatio='21'
                heightRatioUnit='px'
                src={ICONS['ic-left-black.svg']}
                // onClick={this.handleBack.bind(this)}
                onClick={this.props.history.goBack}
              />
            }
          />
          <Section
            scroll
            calcHeight='100vh - 70px'
            paddingVertical='25'
            paddingHorizontal='20'
          >
            <img src={CONTENTS['brochure-1.jpg']} alt='Brochure' />
          </Section>
        </Container>
      </TransitionContainer>
    )
  }
}
