import firebase from 'firebase';
import _ from 'lodash';
import moment from "moment";
import { getRouteRefreshWithAuthApp } from ".";
import { storeGetState } from '../../store';
import { ROUTE_PATH } from "../constants";
import { LOCAL_STORAGE } from "../constants/localStorage";
import { firebaseUnsubscribeNoti } from "../firebases/firebaseNotification";
import { getLocalStorage, removeLocalStorage, setLocalStorage } from "./localStorage";

export const getUserAuth = () => {
  return getLocalStorage(LOCAL_STORAGE.REGISTER_INFO);
}

export const getAuthSuccess = () => {
  return getLocalStorage(LOCAL_STORAGE.IS_OTP_AUTH_SUCCESS);
}

export const logout = () => {
  const userAuth = getUserAuth();
  const lineId = userAuth.lineId;
  removeLocalStorage(LOCAL_STORAGE.REGISTER_INFO);
  removeLocalStorage(LOCAL_STORAGE.IS_OTP_AUTH_SUCCESS);
  removeLocalStorage(LOCAL_STORAGE.LAST_ACTIVE_AT);

  const handleLogoutRedirect = () => {
    if (lineId) {
      const devHostnameList = storeGetState().mainRedux.devHostnameList;
      if (devHostnameList.indexOf(window.location.hostname) >= 0) {
        window.location.href = getRouteRefreshWithAuthApp(`${ROUTE_PATH.LOGIN_BY_LINE_PHONE.LINK}/${lineId}`);
      }
      else {
        window.location.href = `${window.location.origin}${ROUTE_PATH.LINE_LIFF.LINK_WITH_PARAMS}${ROUTE_PATH.LOGIN_BY_LINE_PHONE.LINK}`;
      }
    }
    else {
      window.location.href = window.location.origin;
    }
  }
  if (firebase.apps.length) {
    firebase.auth().signOut().then(() => {
      firebaseUnsubscribeNoti();
      handleLogoutRedirect();
    }, err => {
      console.log('err', err)
    });
  }
  else {
    handleLogoutRedirect();
  }

}

export const getLastOTPSuccessDay = (username) => {
  let otpSuccessList = getLocalStorage(LOCAL_STORAGE.IS_OTP_AUTH_SUCCESS_LIST) || [];
  const otpSuccessData = otpSuccessList.filter(e => e.username === username)[0] || { otpSuccessDate: null };
  let otpSuccessDate = otpSuccessData.otpSuccessDate;
  // for developer
  const devHostnameList = storeGetState().mainRedux.devHostnameList;
  if (devHostnameList.indexOf(window.location.hostname) >= 0) {
    console.log('developer')
    otpSuccessDate = new Date();
  }
  const lastOTPSuccessDay = moment().startOf('day').diff(moment(otpSuccessDate).startOf('day'), 'days');
  console.log('================');
  console.log('getLastOTPSuccessDay', otpSuccessList, username, otpSuccessDate, lastOTPSuccessDay);
  console.log('================');
  return lastOTPSuccessDay
}

export const setLastOTPSuccessDay = (data) => {
  let otpSuccessList = getLocalStorage(LOCAL_STORAGE.IS_OTP_AUTH_SUCCESS_LIST) || [];
  const otpSuccessIDList = otpSuccessList.map(e => e.username);
  if (otpSuccessIDList.indexOf(data.username) >= 0) {
    otpSuccessList = otpSuccessList.map(e => {
      if (e.username === data.username) {
        e = _.cloneDeep(data);
      }
      return e;
    });
  }
  else {
    otpSuccessList.push(_.cloneDeep(data))
  }
  setLocalStorage(LOCAL_STORAGE.IS_OTP_AUTH_SUCCESS_LIST, otpSuccessList)
}

export const handleValidateAuth = () => {
  const userAuth = getUserAuth();
  if (userAuth) {
    const dateNow = moment();
    const currentDate = dateNow.format('YYYY-MM-DD');
    const lastActiveDate = moment(getLocalStorage(LOCAL_STORAGE.LAST_ACTIVE_AT));
    const diffDate = moment(currentDate).diff(lastActiveDate, 'days');
    if (diffDate > 7) {
      setLocalStorage(LOCAL_STORAGE.IS_TOAST_RE_LOGIN, true);
      logout();
      return false
    } else {
      setLocalStorage(LOCAL_STORAGE.LAST_ACTIVE_AT, currentDate);
      return true
    }
  }
  return true
}