import React from 'react'
import _ from 'lodash'
import axios from 'axios'
import qs from 'qs'
import {
  TransitionContainer,
  Container,
  Section,
  Image,
  Field,
  NavigationBar,
  Text,
  Grid,
} from './../../components'
import {
  VARIABLES,
  TYPOGRAPHYS,
  ICONS,
} from './../../themes'
import {
  ROUTE_PATH,
  redirect,
} from './../../helpers'
import {
  Loader
} from '../Loader'
import config from '../../config'
import { getLanguage } from '../../helpers/functions/language'

export class OrderInquiryNotificationPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
    }
  }

  render() {
    return (
      <TransitionContainer>
        <Container
          width={VARIABLES.WEBSITE_WIDTH}
          calcMinHeight='100vh'
          bgColor={VARIABLES.COLORS.LIGHT_BLUE_2}
        >
          <NavigationBar
            pageTitle={getLanguage('orderInquiry.title')}
            actionLeft={
              <Image
                widthRatio='24'
                widthRatioUnit='px'
                heightRatio='23'
                heightRatioUnit='px'
                src={ICONS['ic-home-black.svg']}
                onClick={() => {
                  redirect(ROUTE_PATH.HOME.LINK)
                }}
              />
            }
          />
          <Section
            scroll
            calcHeight='100vh - 70px'
          >
            <Section
              paddingHorizontal='20'
              paddingTop='24'
              paddingBottom='24'
            >
              <Section
                paddingVertical='12'
                borderBottomWidth='1'
                borderBottomStyle='solid'
                borderBottomColor={VARIABLES.COLORS.GRAY_5}
                onClick={() => {}}
              >
                <Grid
                  gutter='30'
                  justify='space-between'
                >
                  <Grid.Column>
                    <Section spacingBottom='5'>
                      <Text
                        fontStyle={TYPOGRAPHYS.FIRST.BOLD.FS_18}
                        color={VARIABLES.COLORS.BLACK}
                      >
                        Order 900
                      </Text>
                    </Section>
                    <Section>
                      <Grid gutter='6'>
                        <Grid.Column>
                          <Image
                            widthRatio='12'
                            widthRatioUnit='px'
                            heightRatio='13'
                            heightRatioUnit='px'
                            top='-2'
                            src={ICONS['ic-date-gray.svg']}
                          />
                        </Grid.Column>
                        <Grid.Column>
                          <Text
                            fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_12}
                            color={VARIABLES.COLORS.GRAY_3}
                          >
                            11 Dec, 2019 - 11:52
                          </Text>
                        </Grid.Column>
                      </Grid>
                    </Section>
                  </Grid.Column>
                  <Grid.Column>
                    <Section
                      height='100'
                      heightUnit='%'
                      align='center'
                    >
                      <Image
                        widthRatio='8'
                        widthRatioUnit='px'
                        heightRatio='13'
                        heightRatioUnit='px'
                        src={ICONS['ic-arrow-right-blue.svg']}
                      />
                    </Section>
                  </Grid.Column>
                </Grid>
              </Section>
            </Section>
          </Section>
        </Container>
      </TransitionContainer>
    )
  }
}

