import {
  createReducer,
} from 'reduxsauce';
import _ from 'lodash';
import { DATA_REDUX_TYPES, DATA_REDUX_INITIAL_STATE } from '../actions/dataAction';

/* ------------- Reducers ------------- */

export const setDataRedux = (state, { key, data }) => {
  state[key] = _.clone(data);
  return _.clone(state);
}

/* ------------- Hookup Reducers To Types ------------- */

export const dataReducer = createReducer(DATA_REDUX_INITIAL_STATE, {
  [DATA_REDUX_TYPES.SET_DATA_REDUX]: setDataRedux,
})