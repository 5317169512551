export const ROUTE_PATH = {
  SPLASH_SCREEN: {
    TEXT: 'Splash Screen',
    LINK: '/'
  },

  CHANGE_PASSWORD: {
    TEXT: 'Change password',
    LINK: '/change-password'
  },

  HOME: {
    TEXT: 'Home',
    LINK: '/home'
  },

  LOGIN: {
    TEXT: 'Login',
    LINK: '/login'
  },

  LOGIN_METHOD: {
    TEXT: 'Login Method',
    LINK: '/login/method'
  },

  LOGIN_BY_EMAIL: {
    TEXT: 'Login by Email',
    LINK: '/login/email'
  },

  LOGIN_BY_PHONE: {
    TEXT: 'Login by Phone',
    LINK: '/login/phone'
  },

  LOGIN_BY_LINE_PHONE: {
    TEXT: 'Login by Line Phone',
    LINK: '/login/line-phone',
    LINK_WITH_PARAMS: '/login/line-phone/:id',
  },

  TERMS_CONDITIONS: {
    TEXT: 'Terms and Conditions',
    LINK: '/terms-conditions'
  },

  TERM_POLICY: {
    TEXT: 'Term and Policy',
    LINK: '/term-policy'
  },

  REGISTER: {
    TEXT: 'Register',
    LINK: '/register',
    LINK_WITH_PARAMS: '/register/:id',
  },

  NEWS_PROMOTIONS: {
    TEXT: 'News and Promotions',
    LINK: '/news-promotions'
  },

  NEWS_PROMOTIONS_SEARCH: {
    TEXT: 'News and Promotions Search',
    LINK: '/news-promotions/search'
  },

  NEWS_PROMOTIONS_DETAIL: {
    TEXT: 'News and Promotions Detail',
    LINK: '/news-promotions/detail',
    LINK_WITH_PARAMS: '/news-promotions/detail/:id',
  },

  NEWS_PROMOTIONS_COMMENT: {
    TEXT: 'News and Promotions Comment',
    // LINK: '/news-promotions/detail/comment'
    LINK: '/news-promotions/comment',
    LINK_WITH_PARAMS: '/news-promotions/comment/:id',
  },

  NEWS_PROMOTIONS_WRITE_COMMENT: {
    TEXT: 'News and Promotions Write Comment',
    // LINK: '/news-promotions/detail/writecomment'
    LINK: '/news-promotions/writecomment',
    LINK_WITH_PARAMS: '/news-promotions/writecomment/:id',
  },

  AWARD_ANNOUNCEMENT: {
    TEXT: 'Award Announcement',
    LINK: '/award-announcement'
  },
  
  WIN_PRIZES: {
    TEXT: 'Win Prizes',
    LINK: '/win-prizes'
  },
  
  MY_PROFILE: {
    TEXT: 'My Profile',
    LINK: '/profile',
    LINK_WITH_PARAMS: '/profile/:id',
  },

  MY_PROJECTS: {
    TEXT: 'My Projects',
    LINK: '/projects',
    LINK_WITH_PARAMS: '/projects/:id',
  },

  WELCOME: {
    TEXT: 'Welcome',
    LINK: '/welcome'
  },

  PROJECT_DETAIL: {
    TEXT: 'Project Detail',
    LINK: '/project-detail',
    LINK_WITH_PARAMS: '/project-detail/:id',
  },

  PROJECT_EDIT: {
    TEXT: 'Project Edit',
    LINK: '/projects/edit',
    LINK_WITH_PARAMS: '/projects/edit/:id',
  },

  PROJECT_ADD: {
    TEXT: 'Project Add',
    LINK: '/projects/add',
    LINK_WITH_PARAMS: '/projects/:id/add',
  },

  PROJECT_SEARCH: {
    TEXT: 'Project Search',
    LINK: '/projects/search',
    LINK_WITH_PARAMS: '/projects/search/:id',
  },

  SETTING: {
    TEXT: 'Setting',
    LINK: '/setting',
  },

  SETTING_NOTIFICATION: {
    TEXT: 'Notification Setting',
    LINK: '/setting/notification',
  },

  SETTING_LANGUAGE: {
    TEXT: 'Language Setting',
    LINK: '/setting/language',
  },

  FIND_DEALER: {
    TEXT: 'Find A Dealer',
    LINK: '/find-a-dealer',
  },

  FIND_DEALER_TYPE: {
    TEXT: 'Dealer Type',
    LINK: '/find-a-dealer/type',
    LINK_WITH_PARAMS: '/find-a-dealer/type/:type',
  },

  FIND_DEALER_LIST: {
    TEXT: 'Dealer List',
    LINK: '/find-a-dealer/list',
    LINK_WITH_PARAMS: '/find-a-dealer/list/:id',
  },

  FIND_DEALER_SEARCH_NAME: {
    TEXT: 'Search Dealer By Name',
    LINK: '/find-a-dealer/search/name',
  },

  FIND_DEALER_SEARCH_AREA: {
    TEXT: 'Search Dealer By Area',
    LINK: '/find-a-dealer/search/area',
  },

  FIND_DEALER_SEARCH_RESULT: {
    TEXT: 'Search Dealer Result',
    LINK: '/find-a-dealer/search/result',
  },

  FIND_DEALER_COMMENTS: {
    TEXT: 'Dealer Comments',
    LINK: '/find-a-dealer/comments',
    LINK_WITH_PARAMS: '/find-a-dealer/comments/:id',
  },

  FIND_DEALER_WRITE_COMMENT: {
    TEXT: 'Dealer Write Comment',
    LINK: '/find-a-dealer/writecomment',
    LINK_WITH_PARAMS: '/find-a-dealer/writecomment/:id',
  },

  PRODUCT: {
    TEXT: 'Product',
    LINK: '/product'
  },

  PRODUCTCAT: {
    TEXT: 'Product Catalogs',
    LINK_WITH_PARAMS: '/product/cat/:id',
    LINK: '/product/cat'
  },

  PRODUCT_DETAIL: {
    TEXT: 'Product Detail',
    LINK_WITH_PARAMS: '/product/detail/:id',
    LINK: '/product/detail'
  },

  PRODUCT_DETAIL_BROCHURE: {
    TEXT: 'Product Brochure',
    LINK_WITH_PARAMS: '/product/detail/brochure/:id',
    LINK: '/product/detail/brochure'
  },

  ORDER_INQUIRY: {
    TEXT: 'Order Inquiry',
    LINK: '/order-inquiry'
  },

  ORDER_INQUIRY_SEARCH: {
    TEXT: 'Order Inquiry Search',
    LINK: '/order-inquiry/search'
  },

  ORDER_INQUIRY_DETAIL: {
    TEXT: 'Order Inquiry Detail',
    LINK: '/order-inquiry/detail',
    LINK_WITH_PARAMS: '/order-inquiry/detail/:id',
  },

  ORDER_INQUIRY_ADD: {
    TEXT: 'Order Inquiry Add',
    LINK: '/order-inquiry/add',
  },

  ORDER_INQUIRY_NOTIFICATION: {
    TEXT: 'Order Inquiry Notification',
    LINK: '/order-inquiry/notification',
  },

  KNOWLEDGE_BASE: {
    TEXT: 'Knowledge Base',
    LINK: '/knowledge-base'
  },

  KNOWLEDGE_BASE_TOPIC: {
    TEXT: 'Knowledge Base Topic',
    LINK: '/knowledge-base/topic',
    LINK_WITH_PARAMS: '/knowledge-base/topic/:id',
  },

  KNOWLEDGE_BASE_DETAIL: {
    TEXT: 'Knowledge Base Detail',
    LINK: '/knowledge-base/detail',
    LINK_WITH_PARAMS: '/knowledge-base/detail/:catid/:knowledgeid',
  },

  PURCHASE_HISTORY: {
    TEXT: 'Purchase History',
    LINK: '/purchase-history'
  },

  PURCHASE_HISTORY_DETAIL: {
    TEXT: 'Purchase History Detail',
    LINK: '/purchase-history/detail',
    LINK_WITH_PARAMS: '/purchase-history/detail/:id',
  },

  PURCHASE_HISTORY_SEARCH: {
    TEXT: 'Purchase History Search',
    LINK: '/purchase-history/search'
  },

  LOYALTY_POINT: {
    TEXT: 'TrueBlue Points',
    LINK: '/loyalty-points'
  },

  REWARD: {
    TEXT: 'Rewards',
    LINK: '/rewards',
  },

  REWARD_CAT: {
    TEXT: 'Reward Catalogs',
    LINK: '/rewards/category',
    LINK_WITH_PARAMS: '/rewards/category/:id',
  },

  REWARD_DETAIL: {
    TEXT: 'Reward Detail',
    LINK: '/rewards/detail',
    LINK_WITH_PARAMS: '/rewards/detail/:id',
  },

  REWARD_SEARCH: {
    TEXT: 'Reward Search',
    LINK: '/rewards/search',
  },

  WARRANTY: {
    TEXT: 'Warranty',
    LINK: '/warranty',
  },

  WARRANTY_PRODUCT: {
    TEXT: 'Warranty Product',
    LINK: '/warranty/product',
    LINK_WITH_PARAMS: '/warranty/product/:id',
  },

  WARRANTY_PRODUCT_FILE: {
    TEXT: 'Warranty Product File',
    LINK_WITH_PARAMS: '/warranty/product/:id/file/:productId',
  },

  WARRANTY_SEARCH_NAME: {
    TEXT: 'Search Warranty By Name',
    LINK: '/warranty/search/name',
  },

  WARRANTY_SEARCH_DATE: {
    TEXT: 'Search Warranty By Date',
    LINK: '/warranty/search/date',
  },

  WARRANTY_SEARCH_RESULT: {
    TEXT: 'Search Warranty Result',
    LINK: '/warranty/search/result',
  },

  ESTIMATOR: {
    TEXT: 'Estimator',
    LINK: '/estimator',
  },

  ESTIMATOR_SUMMARY: {
    TEXT: 'Estimator Output Summary',
    LINK: '/estimator/summary',
  },

  OTP: {
    TEXT: 'Verification OTP',
    LINK: '/otp',
  },

  PUBLIC_NEWS_PROMOTIONS: {
    TEXT: 'Public News and Promotions',
    LINK: '/public-news-promotions/detail',
    LINK_WITH_PARAMS: '/public-news-promotions/detail/:id',
  },

  PUBLIC_NEWS_PROMOTIONS_COMMENT: {
    TEXT: 'Public News and Promotions Comment',
    LINK: '/public-news-promotions/comment',
    LINK_WITH_PARAMS: '/public-news-promotions/comment/:id',
  },

  PUBLIC_NEWS_PROMOTIONS_WRITE_COMMENT: {
    TEXT: 'Public News and Promotions Write Comment',
    LINK: '/public-news-promotions/writecomment',
    LINK_WITH_PARAMS: '/public-news-promotions/writecomment/:id',
  },

  PUBLIC_MY_PROJECT: {
    TEXT: 'Public My Projects',
    LINK: '/public-projects',
    LINK_WITH_PARAMS: '/public-projects/:userId/:projectId',
  },

  PUBLIC_PRODUCT: {
    TEXT: 'Public Products',
    LINK: '/public-products',
    LINK_WITH_PARAMS: '/public-products/:id',
  },

  PUBLIC_KNOWLEDGE_BASE: {
    TEXT: 'Public Knowledge Base',
    LINK: '/public-knowledge-base',
    LINK_WITH_PARAMS: '/public-knowledge-base/:categoryId/:knowledgeId',
  },

  FORGOT_PASSWORD_METHOD: {
    TEXT: 'Forgot password method',
    LINK: '/forgot-password-method'
  },

  FORGOT_PASSWORD_PHONE_NUMBER: {
    TEXT: 'Forgot password phone number',
    LINK: '/forgot-password-phone-number'
  },

  VERIFY_QUESTION: {
    TEXT: 'Verify question',
    LINK: '/verify-question'
  },

  RESET_PASSWORD: {
    TEXT: 'Reset password',
    LINK: '/reset-password'
  },

  REFERENCE_CODE: {
    TEXT: 'Reference Code',
    LINK: '/reference-code',
    LINK_WITH_PARAMS: '/reference-code/:refCode',
  },

  REFERRAL_AD: {
    TEXT: 'Referral AD',
    LINK: '/referral-ad',
    LINK_WITH_PARAMS: '/referral-ad/:id',
  },

  AUTH: {
    TEXT: 'Auth',
    LINK: '/auth',
    LINK_WITH_PARAMS: '/auth?data=',
  },

  AUTH_SUCCESS: {
    TEXT: 'Authentication Success',
    LINK: '/authentication-success',
  },

  LINE_LIFF: {
    TEXT: 'Line liff',
    LINK: '/line-liff',
    LINK_WITH_PARAMS: '/line-liff?data=',
  },

  LINE_OA_VALIDATION: {
    TEXT: 'LINE OA Validation',
    LINK: '/line-oa-validation',
  },

  VOUCHER_OUT_OF_STOCK: {
    TEXT: 'Voucher Out of Stock',
    LINK: '/voucher-out-of-stock',
    LINK_WITH_PARAMS: '/voucher-out-of-stock/:id',
  },

  REGISTER_SUCCESS: {
    TEXT: 'Register Success',
    LINK: '/register-success',
  },
}
