import React from 'react'
import _ from 'lodash'
import axios from 'axios'
import ReactHtmlParser from 'react-html-parser';
import {
  TransitionContainer,
  Container,
  SwiperCarousel,
  Image,
  Section,
  Text,
  Grid,
  Button,
  CommentPanel,
  Alert,
} from '../../components'
import {
  VARIABLES,
  TYPOGRAPHYS,
  ICONS,
} from '../../themes'
import {
  ROUTE_PATH,
  redirect,
  formatDatetime,
} from './../../helpers'
import {
  Loader
} from '../Loader'
import config from './../../config'
import { LOCAL_STORAGE } from '../../helpers/constants/localStorage';
import { getLanguage } from '../../helpers/functions/language';
import { DATA } from '../../helpers/constants/data';
import { onOpenAlert } from '../../helpers/functions/main';
import { fetchApi } from '../../apiService/apiService';
import { getCountCommentByNewsPromotionId } from '../../helpers/services/newsPromotion';
import { updateLikeById } from '../../helpers/services/newsPromotionLike';
export class NewsPromotionsDetailPage extends React.Component {
  constructor(props) {
    super(props)
    const user = JSON.parse(localStorage.getItem(LOCAL_STORAGE.REGISTER_INFO))
    this.state = {
      id: (this.props.match.params && this.props.match.params.id) ? +this.props.match.params.id : '',
      data: {
        subject: '',
        type: '',
        content: '',
        app: '',
        images: [],
        public_date: '',
        expire_date: '',
        create_at: '',
        status: '',
        is_banner: false,
        // like_count: 0,
      },

      totalLike: 0,
      totalComment: 0,

      userId: _.get(user, 'id'),
      isLoading: false,

      showModal: false,
      titleModal: '',
      descriptionModal: '',
      typeModal: '',
      isInit: true,
    }
  }

  async componentDidMount() {
    const data = JSON.parse(localStorage.getItem('newsPromotionList'))
    if (_.isEmpty(data)) return
    const result = data.find(item => item.id === this.state.id)
    if (result) {
      this.setState({
        data: result,
        isInit: false
      })
    } else {
      this.setState({ isLoading: true }, async () => {
        try {
          const url = `${config.api.cms}/newspromotion/get/${this.state.id}/`
          const response = await fetchApi.get(url)
          const data = _.get(response, 'data.data')
          if (response.status === 200 && !_.isEmpty(data)) {
            this.setState({
              data: result,
              isInit: false
            })
          }
        } catch (_) {
        } finally {
          this.setState({ isLoading: false })
        }
      })
    }

    this.fetchCountLikeComment()
  }

  fetchCountLikeComment() {
    this.setState({ isLoading: true }, async () => {
      try {
        const data = await getCountCommentByNewsPromotionId(this.state.id)
        if (!_.isEmpty(data)) {
          // if (response.status === 200 && !_.isEmpty(data)) {
          this.setState({ totalLike: data.total_like, totalComment: data.total_comment })
        }
      } catch (_) {
      } finally {
        this.setState({ isLoading: false })
      }
    })
  }

  async onClickLikeButton() {
    const { id, data, userId } = this.state
    this.setState({ isLoading: true }, async () => {
      try {
        const keyType = (data.type) === 'news' ? 'news_id' : 'promotion_id'
        // const url = `${config.api.base}/api/nplike/like`;
        const options = { [keyType]: id, user_id: userId, type: data.type }
        // const response = await axios.put(url, options)
        const response = await updateLikeById(options)
        // if (response.status === 200) {
        if (response) {
          this.fetchCountLikeComment()
        } else {
          this.showModalError()
        }
      } catch (_) {
        this.showModalError()
      } finally {
        this.setState({ isLoading: false })
      }
    })
  }

  onShareLink() {
    const {
      id,
    } = this.state;
    const shareUrl = `${window.location.origin}${ROUTE_PATH.PUBLIC_NEWS_PROMOTIONS.LINK}/${id}`;
    console.log('shareUrl', shareUrl)
    if (navigator.share) {
      navigator.share({
        // url: window.location.href
        url: shareUrl
      })
    }
    else {
      onOpenAlert({
        type: DATA.ALERT_TYPE.ERROR,
        title: getLanguage('message.fail'),
        message: getLanguage('message.browserCantShare'),
      });
    }
  }

  showModalError() {
    this.setState({
      showModal: true,
      typeModal: 'error',
      titleModal: '',
      descriptionModal: getLanguage('message.somethingWrong')
    })
  }

  onCloseModal() {
    this.setState({ showModal: false, typeModal: '' })
  }

  render() {
    const {
      data,
      showModal,
      titleModal,
      descriptionModal,
      typeModal,
      isLoading,
      totalLike,
      totalComment,
      isInit
    } = this.state

    return (
      <TransitionContainer>
        <Container
          width={VARIABLES.WEBSITE_WIDTH}
          calcMinHeight='100vh'
          bgColor={VARIABLES.COLORS.WHITE}
        >
          <Section relative>
            <Button
              className='is-icon is-content-detail-back'
              width={36}
              height={36}
              borderRadius={18}
              bgColor={VARIABLES.COLORS.WHITE}
              onClick={() => redirect(ROUTE_PATH.NEWS_PROMOTIONS.LINK)}
            >
              <Button.Icon>
                <Image
                  widthRatio='11'
                  widthRatioUnit='px'
                  heightRatio='21'
                  heightRatioUnit='px'
                  left='-1'
                  src={ICONS['ic-left-black.svg']}
                />
              </Button.Icon>
            </Button>
            <Section
              nonFluid
              className='is-social-buttons'
            >
              <Grid gutter='7'>
                <Grid.Column>
                  <Button
                    width={38}
                    height={38}
                    borderRadius={19}
                    bgColor={VARIABLES.COLORS.GRAY_3}
                    onClick={this.onClickLikeButton.bind(this)}
                  >
                    <Button.Icon>
                      <Image
                        widthRatio='19'
                        widthRatioUnit='px'
                        heightRatio='17'
                        heightRatioUnit='px'
                        src={ICONS['ic-thumbs-up.svg']}
                        top='-1'
                      />
                    </Button.Icon>
                  </Button>
                </Grid.Column>
                {/* <Grid.Column>
                  <Button
                    width={38}
                    height={38}
                    borderRadius={19}
                    bgColor={VARIABLES.COLORS.BLUE_2}
                    onClick={this.onShareLink.bind(this)}
                  >
                    <Button.Icon>
                      <Image
                        widthRatio='17'
                        widthRatioUnit='px'
                        heightRatio='19'
                        heightRatioUnit='px'
                        src={ICONS['ic-share.svg']}
                      />
                    </Button.Icon>
                  </Button>
                </Grid.Column> */}
              </Grid>
            </Section>
            {isInit ?
              undefined :
              !_.isEmpty(data.images) ? <SwiperCarousel className='is-banner-news-and-promotions'>
                {data.images.map((image, index) => (
                  <SwiperCarousel.Item key={index}>
                    <Image
                      heightRatio='93'
                      src={`${config.imgPrefix}/${image}`}
                    />
                  </SwiperCarousel.Item>
                ))}
              </SwiperCarousel> :
                <SwiperCarousel className='is-banner-news-and-promotions is-empty' />
              // undefined
              // <Image
              //   heightRatio='93'
              //   src={ICONS['ic-placeholder-news-and-promotions.svg']}
              // />
            }
          </Section>
          <Section
            paddingTop='35'
            paddingBottom='125'
            paddingHorizontal='20'
          >
            <Section spacingBottom='10'>
              <Text
                fontStyle={TYPOGRAPHYS.FIRST.BOLD.FS_22}
                color={VARIABLES.COLORS.BLACK}
              >
                {data.subject}
              </Text>
            </Section>
            <Section spacingBottom='20'>
              <Grid gutter='6'>
                <Grid.Column>
                  <Image
                    widthRatio='12'
                    widthRatioUnit='px'
                    heightRatio='13'
                    heightRatioUnit='px'
                    top='-2'
                    src={ICONS['ic-date-gray.svg']}
                  />
                </Grid.Column>
                <Grid.Column>
                  <Text
                    fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_12}
                    color={VARIABLES.COLORS.GRAY_3}
                  >
                    {formatDatetime(data.public_date || data.create_at, 'DD MMM, YYYY')}
                  </Text>
                </Grid.Column>
              </Grid>
            </Section>
            <Section>
              <Section
                className='text-editor-content'
                spacingBottom='15'
              >
                {ReactHtmlParser(data.content)}
              </Section>
            </Section>
          </Section>
          <CommentPanel
            likeAmount={totalLike || 0}
            commentAmount={totalComment || 0}
            onClickSeeComments={() => {
              if (totalComment) {
                redirect(`${ROUTE_PATH.NEWS_PROMOTIONS_COMMENT.LINK}/${data.id}`);
              }
            }}
            onClickWriteComment={() => {
              redirect(`${ROUTE_PATH.NEWS_PROMOTIONS_WRITE_COMMENT.LINK}/${data.id}`);
            }}
          />
        </Container>
        <Alert
          open={showModal}
          type={typeModal}
          title={titleModal}
          description={descriptionModal}
          onClose={this.onCloseModal.bind(this)}
        />
        <Loader
          loading={isLoading}
          isOverlay
        />
      </TransitionContainer>
    )
  }
}
