import React from 'react'
import {
  TransitionContainer,
  Container,
  Image,
  Section,
  Text,
  Button,
} from './../../components'
import {
  VARIABLES,
  TYPOGRAPHYS,
  CONTENTS,
  ICONS,
} from './../../themes'
import {
  ROUTE_PATH,
  redirect,
  getRouteRefreshWithAuthApp,
} from './../../helpers'
import { LOCAL_STORAGE } from '../../helpers/constants/localStorage';
import { handleWatchNoti } from '../../helpers/firebases/firebaseNotification';
import { getLanguage } from '../../helpers/functions/language';
import { setLocalStorage } from '../../helpers/functions/localStorage';

export class WelcomePage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: ''
    };
  }

  componentDidMount() {
    // TODO: need to change from localStorage to Server
    // bring data from localStorage
    let a = JSON.parse(localStorage.getItem(LOCAL_STORAGE.REGISTER_INFO));
    if (a) {
      this.setState({
        name: a.firstName,
      });
      setLocalStorage(LOCAL_STORAGE.IS_OTP_AUTH_SUCCESS, true);
    }
  }

  onClickContinue() {
    window.location.href = getRouteRefreshWithAuthApp();
  }

  render() {
    return (
      <TransitionContainer
      // motion='overlap-from'
      >
        <Container
          width={VARIABLES.WEBSITE_WIDTH}
          height='100'
          heightUnit='vh'
          bgColor={VARIABLES.COLORS.LIGHT_BLUE_2}
          justify='center'
        >
          <Section
            spacingBottom='35'
            justify='center'
          >
            <Image
              widthRatio='230'
              widthRatioUnit='px'
              heightRatio='238'
              heightRatioUnit='px'
              src={CONTENTS['welcome.png']}
            />
          </Section>
          <Section
            spacingBottom='15'
            justify='center'
          >
            <Text
              fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_20}
              color={VARIABLES.COLORS.GRAY_3}
            >
              {getLanguage('welcome.message_1')}&nbsp;
            </Text>
            <Text
              fontStyle={TYPOGRAPHYS.FIRST.MEDIUM.FS_20}
              color={VARIABLES.COLORS.BLUE_2}
            >
              {this.state.name}
            </Text>
          </Section>
          <Section
            paddingHorizontal='40'
            spacingBottom='40'
            justify='center'
          >
            <Text
              fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_20}
              color={VARIABLES.COLORS.GRAY_3}
              align='center'
            >
              {getLanguage('welcome.message_2')} {getLanguage('welcome.message_3')}
              <br />
              {getLanguage('welcome.message_4')}
            </Text>
          </Section>
          <Section justify='center'>
            <Button
              shadow
              className='is-gradient-blue is-icon-right'
              width={VARIABLES.BUTTON.WIDTHS.W_275}
              height={VARIABLES.BUTTON.HEIGHTS.H_40}
              borderRadius={VARIABLES.BUTTON.BORDER_RADIUSES.BR_10}
              onClick={this.onClickContinue.bind(this)}
            >
              <Button.Icon>
                <Image
                  widthRatio='15'
                  widthRatioUnit='px'
                  heightRatio='12'
                  heightRatioUnit='px'
                  src={ICONS['ic-arrow-right.svg']}
                />
              </Button.Icon>
              <Text
                fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_16}
                color={VARIABLES.COLORS.WHITE}
              >
                {getLanguage('field.buttonContinue')}
              </Text>
            </Button>
          </Section>
        </Container>
      </TransitionContainer>
    )
  }
}
