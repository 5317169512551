import React from 'react'
import ClassNames from 'classnames'
import PropTypes from 'prop-types'
import {
  NotificationBaseWrapper
} from './styled'
// import {
//   default as VARIABLES
// } from './../../../themes/styles/bases/variables'
// import {
//   default as TYPOGRAPHYS
// } from './../../../themes/styles/bases/typographys'

/**
 * NotificationBase description:
 * - base component Notification
 */

export class NotificationBase extends React.PureComponent {
  render () {
    const {
      // Option
      children,
      className,
      // Default
      padding,
      paddingTop,
      paddingRight,
      paddingBottom,
      paddingLeft,
      paddingHorizontal,
      paddingVertical,
      spacing,
      spacingTop,
      spacingRight,
      spacingBottom,
      spacingLeft,
      spacingHorizontal,
      spacingVertical,
      bgColor,
      borderWidth,
      borderStyle,
      borderColor,
      borderRadius,
      boxShadow
    } = this.props

    return (
      <NotificationBaseWrapper
        className={ClassNames(
          'notification-base',
          // Option
          className
        )}
        theme={{
          // Default
          padding,
          paddingTop,
          paddingRight,
          paddingBottom,
          paddingLeft,
          paddingHorizontal,
          paddingVertical,
          spacing,
          spacingTop,
          spacingRight,
          spacingBottom,
          spacingLeft,
          spacingHorizontal,
          spacingVertical,
          bgColor,
          borderWidth,
          borderStyle,
          borderColor,
          borderRadius,
          boxShadow
        }}
      >
        {children}
      </NotificationBaseWrapper>
    )
  }

  static defaultProps = {
    // Option
    // children: undefined,
    // className: undefined,
    // Default
  }

  static propTypes = { // TYPE > node, string, number, bool, array, object, symbol, func
    /**
    * Additional classes
    */
    className: PropTypes.string,

    /**
    * Additional Text, HTML, Component
    */
    children: PropTypes.oneOfType([
      PropTypes.node,
      PropTypes.string
    ])
  }
}
