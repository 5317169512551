import React from 'react'
import _ from 'lodash'
import axios from 'axios'
import {
  TransitionContainer,
  Container,
  Section,
  Image,
  NavigationBar,
  Text
} from './../../components'
import {
  VARIABLES,
  TYPOGRAPHYS,
  ICONS,
} from './../../themes'
import {
  ROUTE_PATH,
  redirect,
  getUrlParams,
} from './../../helpers'
import {
  Loader
} from '../Loader'
import config from './../../config'

export class ProductCatPage extends React.Component {
  constructor(props) {
    super(props)
    const categoryIndex = getUrlParams('c-index') || 0;
    this.state = {
      id: (this.props.match.params && this.props.match.params.id) ? +this.props.match.params.id : '',
      isLoading: false,
      list: [],
      titleProductGroup: '',
      categoryIndex,
      labelColorList: [VARIABLES.COLORS.OVERLAY_8, VARIABLES.COLORS.OVERLAY_9, VARIABLES.COLORS.OVERLAY_10]
    }
  }

  componentDidMount() {
    this.setTitleFromProductGroup()
    localStorage.removeItem('productList')
    this.setState({ isLoading: true }, async () => {
      try {
        const url = `${config.api.cms}/product/search/`
        const options = { group_id: this.state.id, sort: 'id desc', status: 'active' }
        const response = await axios.post(url, options)
        const data = _.get(response, 'data.data')
        if (response.status === 200 && data) {
          this.setState({ list: data }, () => {
            localStorage.setItem('productList', JSON.stringify(data))
          });
        }
      } catch (_) {
      } finally {
        this.setState({ isLoading: false })
      }
    })
  }

  setTitleFromProductGroup() {
    const data = JSON.parse(localStorage.getItem('productGroupList'))
    if (_.isEmpty(data)) return
    const result = data.find(item => item.id === this.state.id)
    if (result) {
      this.setState({ titleProductGroup: result.category_name })
    }
  }

  handleBack() {
    redirect(ROUTE_PATH.PRODUCT.LINK);
  }

  render() {
    // let info = [
    //   {
    //     1: [
    //       'banner-category-1.jpg',
    //       'banner-category-2.jpg',
    //       'banner-category-3.jpg',
    //       'banner-category-4.jpg',
    //       'banner-category-5.jpg'
    //     ]
    //   },
    //   {
    //     2: ['image-44.png', 'image-44.png']
    //   },
    //   {
    //     3: ['image-47.png', 'image-48.png']
    //   },
    //   {
    //     4: ['image-46.png', 'image-47.png', 'image-48.png']
    //   }
    // ]
    const {
      list,
      isLoading,
      titleProductGroup,
      labelColorList,
      categoryIndex,
    } = this.state
    return (
      <TransitionContainer>
        <Container
          width={VARIABLES.WEBSITE_WIDTH}
          calcMinHeight='100vh'
          bgColor={VARIABLES.COLORS.LIGHT_BLUE_2}
        >
          <NavigationBar
            shadow
            pageTitle={titleProductGroup}
            actionLeft={
              <Image
                widthRatio='11'
                widthRatioUnit='px'
                heightRatio='21'
                heightRatioUnit='px'
                src={ICONS['ic-left-black.svg']}
                onClick={this.handleBack.bind(this)}
              />
            }
          />
          <Section
            scroll
            calcHeight='100vh - 70px'
            paddingVertical='25'
            paddingHorizontal='20'
          >
            {!_.isEmpty(list) && list.map((data, index) => (
              <Section
                className='is-product-subcatalog'
                spacingBottom='15'
                bgColor={VARIABLES.COLORS.WHITE}
                key={index}
                onClick={() => {
                  window.open(data.website, '_blank');
                  // redirect(`${ROUTE_PATH.PRODUCT_DETAIL.LINK}/${data.id}`) 
                }}
              >
                <Image
                  heightRatio='26'
                  src={`${config.imgPrefix}/${data.cover}`}
                />
                <Section
                  className='is-product-catalog-name'
                  justify='center'
                  paddingTop='10'
                  paddingBottom='11'
                  bgColor={labelColorList[categoryIndex % 3]}
                >
                  <Text
                    singleLine
                    fontStyle={TYPOGRAPHYS.FIRST.BOLD.FS_11}
                    color={VARIABLES.COLORS.WHITE}
                  >
                    {data.name}
                  </Text>
                </Section>
              </Section>
            ))}
            {!isLoading && _.isEmpty(list) && <Section
              paddingTop='24'
              justify='center'
            >
              <Text
                fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_16}
                color={VARIABLES.COLORS.BLUE_2}
              >
                Data not found
              </Text>
            </Section>}
          </Section>
        </Container>
        <Loader loading={isLoading} isOverlay />
      </TransitionContainer>
    )
  }
}