import React from 'react'
import ClassNames from 'classnames'
import PropTypes from 'prop-types'
import {
  CommentPanelWrapper
} from './styled'
import {
  Section,
  Grid,
  Image,
  Text,
  Button
} from './../../../components'
import {
  VARIABLES,
  TYPOGRAPHYS,
  ICONS
} from './../../../themes'
import { getLanguage } from '../../../helpers/functions/language'
// import {
//   default as VARIABLES
// } from './../../../themes/styles/bases/variables'
// import {
//   default as TYPOGRAPHYS
// } from './../../../themes/styles/bases/typographys'
// import {
//   default as UTILITIES
// } from './../../../themes/styles/helpers/utilities'

/**
 * TemplateBase description:
 * - TemplateBase
 */

export class CommentPanel extends React.PureComponent {
  render() {
    const {
      className,
      // children,
      // modifierName
      likeAmount,
      commentAmount,
      onClickSeeComments,
      onClickWriteComment
    } = this.props

    // props for css classes
    const classes = ClassNames(
      'comment-panel',
      // {'is-modifier-name': modifierName},
      className
    )

    return (
      <CommentPanelWrapper
        className={classes}
      >
        <Section justify='space-between'>
          <Section nonFluid>
            <Section spacingBottom='8'>
              <Grid gutter='8'>
                <Grid.Column>
                  <Image
                    widthRatio='16'
                    widthRatioUnit='px'
                    heightRatio='14'
                    heightRatioUnit='px'
                    src={ICONS['ic-thumbs-up-gray.svg']}
                  />
                </Grid.Column>
                <Grid.Column>
                  <Text
                    fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_14}
                    color={VARIABLES.COLORS.GRAY_3}
                    top='2'
                  >
                    {likeAmount} {getLanguage('component.component.likes')}
                  </Text>
                </Grid.Column>
              </Grid>
            </Section>
            <Section>
              <Grid gutter='8'>
                <Grid.Column>
                  <Image
                    widthRatio='16'
                    widthRatioUnit='px'
                    heightRatio='16'
                    heightRatioUnit='px'
                    src={ICONS['ic-comment.svg']}
                  />
                </Grid.Column>
                <Grid.Column>
                  <Text
                    className='is-underline'
                    fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_14}
                    color={VARIABLES.COLORS.BLUE_2}
                    onClick={onClickSeeComments}
                  >
                    {commentAmount} {getLanguage('component.component.comments')}
                  </Text>
                </Grid.Column>
              </Grid>
            </Section>
          </Section>
          <Section
            nonFluid
            paddingTop='3'
          >
            <Button
              height={VARIABLES.BUTTON.HEIGHTS.H_40}
              paddingHorizontal='20'
              bgColor={VARIABLES.COLORS.TRANSPARENT}
              borderRadius={VARIABLES.BUTTON.BORDER_RADIUSES.BR_10}
              borderWidth='1'
              borderStyle='solid'
              borderColor={VARIABLES.COLORS.BLUE_2}
              onClick={onClickWriteComment}
            >
              <Button.Icon spacingRight='15'>
                <Image
                  widthRatio='19'
                  widthRatioUnit='px'
                  heightRatio='19'
                  heightRatioUnit='px'
                  src={ICONS['ic-write-comment.svg']}
                  top='-2'
                />
              </Button.Icon>
              <Text
                fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_16}
                color={VARIABLES.COLORS.BLUE_2}
              >
                {getLanguage('component.component.buttonComment')}
              </Text>
            </Button>
          </Section>
        </Section>
      </CommentPanelWrapper>
    )
  }

  static defaultProps = {
    // * If you set new default props, you should create propsType for correct styleguide document.
  }

  static propTypes = { // TYPE > node, string, number, bool, array, object, symbol, func
    /**
    * Additional classes
    */
    className: PropTypes.string
  }
}
