import React from 'react'
import {
  TransitionContainer,
  Container,
  Image,
  Section,
  Text,
  Button,
  ShapeContainer,
  Grid,
} from './../../components'
import {
  VARIABLES,
  TYPOGRAPHYS,
  ICONS,
  LOGOS,
} from './../../themes'
import { getLanguage } from '../../helpers/functions/language';
import { getRouteData, redirectData, ROUTE_PATH } from '../../helpers';
import _ from 'lodash';
import { isShowLoading } from '../../helpers/functions/main';
import { getUserAuth } from '../../helpers/functions/auth';
import axios from 'axios';

export class RegisterSuccessPage extends React.Component {
  constructor(props) {
    super(props);
    const data = getRouteData(props, 'data');
    const isGetVoucher = getRouteData(props, 'isGetVoucher');
    const userAuth = getUserAuth();
    console.log('data', data);
    console.log('userAuth', userAuth);
    console.log('isGetVoucher', isGetVoucher);
    this.state = {
      ssoId: _.get(data, 'ssoId'),
      isGetVoucher,
      userAuth,
    };
  }

  registerSuccess = {
    onClickContinue: () => {
      const {
        ssoId,
      } = this.state;
      redirectData(ROUTE_PATH.TERMS_CONDITIONS.LINK, {
        data: {
          ssoId,
        }
      });
    }
  }


  render() {
    const {
      isGetVoucher,
    } = this.state;

    return (
      <TransitionContainer
      // motion='overlap-from'
      >
        <Container className='is-register-success'
          width={VARIABLES.WEBSITE_WIDTH}
          height='100'
          heightUnit='vh'
          bgColor={VARIABLES.COLORS.LIGHT_BLUE_2}
        >
          <Section
            spacingTop='45'
            spacingBottom='30'
            justify='center'
          >
            <Image
              widthRatio='313'
              widthRatioUnit='px'
              heightRatio='215'
              heightRatioUnit='px'
              src={LOGOS['logo-with-firework.png']}
            />
          </Section>
          <Section justify='center' spacingBottom='60'>
            <Section maxWidth='275'>
              <Section block spacingBottom='15' textAlign='center'>
                <Text
                  inline
                  fontStyle={TYPOGRAPHYS.FIRST.BOLD.FS_20}
                  color={VARIABLES.COLORS.WHITE}
                >
                  {getLanguage('registerSuccess.message_1')}
                </Text>
                {isGetVoucher &&
                  <React.Fragment>
                    <br />
                    <Text
                      inline
                      fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_20}
                      color={VARIABLES.COLORS.WHITE}
                    >
                      {getLanguage('registerSuccess.message_2')}
                    </Text>
                    &nbsp;
                    <Text
                      fontStyle={TYPOGRAPHYS.FIRST.BOLD.FS_20}
                      color={VARIABLES.COLORS.WHITE}
                    >
                      {getLanguage('registerSuccess.message_3')}
                    </Text>
                  </React.Fragment>
                }
              </Section>
              <ShapeContainer
                fluid
                padding='15'
                bgColor='rgba(255, 255, 255, 0.15)'
                borderRadius='10'
              >
                <Grid gutter='15' justify='center' align='center'>
                  <Grid.Column>
                    {isGetVoucher ?
                      <Image
                        widthRatio='36'
                        widthRatioUnit='px'
                        heightRatio='36'
                        heightRatioUnit='px'
                        src={ICONS['ic-vouncher-white.svg']}
                      />
                      :
                      <Image
                        widthRatio='59'
                        widthRatioUnit='px'
                        heightRatio='42'
                        heightRatioUnit='px'
                        src={ICONS['ic-voucher-broken-white.svg']}
                      />
                    }
                  </Grid.Column>
                  <Grid.Column>
                    <Section block textAlign='center'>
                      {isGetVoucher ?
                        <Section maxWidth='155'>
                          <Text
                            fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_14}
                            color={VARIABLES.COLORS.WHITE}
                          >
                            {getLanguage('registerSuccess.message_4')}
                          </Text>
                        </Section>
                        :
                        <Text
                          fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_14}
                          color={VARIABLES.COLORS.WHITE}
                        >
                          {getLanguage('registerSuccess.message_5')}
                          <br />
                          {getLanguage('registerSuccess.message_6')}
                        </Text>
                      }
                    </Section>
                  </Grid.Column>
                </Grid>
              </ShapeContainer>
            </Section>
          </Section>
          <Section justify='center'>
            <Button
              shadow
              className='is-gradient-blue is-icon-right'
              width={VARIABLES.BUTTON.WIDTHS.W_275}
              height={VARIABLES.BUTTON.HEIGHTS.H_40}
              borderRadius={VARIABLES.BUTTON.BORDER_RADIUSES.BR_10}
              onClick={this.registerSuccess.onClickContinue}
            >
              <Button.Icon>
                <Image
                  widthRatio='15'
                  widthRatioUnit='px'
                  heightRatio='12'
                  heightRatioUnit='px'
                  src={ICONS['ic-arrow-right.svg']}
                />
              </Button.Icon>
              <Text
                fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_16}
                color={VARIABLES.COLORS.WHITE}
              >
                {getLanguage('field.buttonContinue')}
              </Text>
            </Button>
          </Section>
        </Container>
      </TransitionContainer>
    )
  }
}
