import apiService from "../apiService";

export const newsPromotionCommentController = (configService = {}) => {
  const service = apiService(configService);
  return {
    getNewsPromotionComments: (params) => {
      return service.post(`/api/npcomments/get`, params)
    },
    createNewsPromotionCommentByUserId: (params) => {
      return service.post(`/api/npcomments/${params.user_id}`, params)
    }
  }
}