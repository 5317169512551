import React, { Fragment } from 'react'
import firebase from 'firebase'

import {
  TransitionContainer,
  Container,
  Section,
  Image,
  Button,
  Grid,
  Modal,
  Text,
  Field,

} from './../../components'
import {
  VARIABLES,
  TYPOGRAPHYS,
  SHAREDS,
  LOGOS,
  ICONS
} from './../../themes'
import {
  ROUTE_PATH,
  redirect,
} from './../../helpers'

import axios from 'axios'
import { TEXT_VERSION } from '../../config/config'
import { LOCAL_STORAGE } from '../../helpers/constants/localStorage';
import { ENUM } from '../../helpers/constants/enum';
import { onOpenAlert } from '../../helpers/functions/main'
import { DATA } from '../../helpers/constants/data'
import { getLanguage } from '../../helpers/functions/language'
import { removeLocalStorage } from '../../helpers/functions/localStorage'
import { setUerAuthLocalStorage } from '../../helpers/functions/data'

var config = {
  apiKey: "AIzaSyDFlJ9XDLGIENX4tK8y3vKWhgX2b0VbYwE",
  authDomain: "bluescope-955e7.firebaseapp.com",
}


export class LoginPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showPhoneModal: false,
      phone: '',
      countries: ENUM.COUNTRY.THAI,
      fieldFocus: 'phone',
      userData: {},
      isInValid: false,
    }
  }

  validateData() {
    // Number validation
    if (String(parseInt(this.state.phone)).length !== 9) return false;
    return true;
  }


  componentWillMount() {
    let a = JSON.parse(localStorage.getItem(LOCAL_STORAGE.REGISTER_INFO));
    if (a) {
      redirect(ROUTE_PATH.HOME.LINK)
    }
  }

  componentDidMount() {
    if (!firebase.apps.length) {
      firebase.initializeApp(config);
    }
  }

  handleLoginByFacebook() {
    // console.log('call login facebook')
    var provider = new firebase.auth.FacebookAuthProvider();
    // console.log('provider ==> ', provider);
    firebase.auth().signInWithPopup(provider).then(async result => {
      // var token = result.credential.accessToken;
      var user = result.user;
      // console.log('token==>', token);
      // console.log('user==>', user);
      var name = user.displayName.split(" ");
      let data = {
        email: user.email,
        firstName: name[0],
        lastName: name[1],
        phone: user.phoneNumber,
        profileImage: user.photoURL,
        loginType: 'facebook',
        uid: user.uid,
      }
      this.setState({ userData: data })

      await axios.post('api/users/isexistbyuid', { uid: user.uid }).then(async (response) => {
        // console.log('11111', response);
        if (!response.data.isExist) {
          this.setState({ showPhoneModal: true })
          // await this.saveUserInfo(data);
        } else {
          await this.getInfoUser(data);
        }
      }, (error) => {
        console.log('errr===>', error);
        onOpenAlert({
          type: DATA.ALERT_TYPE.ERROR,
          title: getLanguage('message.fail'),
          message: (error || {}).message,
        })
      });
    }, err => {
      console.log('err', err);
      this.handleSocialError(err.code)
    })
  }

  handleSocialError(code) {
    switch (code) {
      case 'auth/account-exists-with-different-credential':
        onOpenAlert({
          type: DATA.ALERT_TYPE.ERROR,
          title: getLanguage('message.fail'),
          message: getLanguage('message.emailAddressAlreadyExists'),
        });
        break;
    }
  }

  handleLoginByGoogle() {
    //TODO : login by google account
    var provider = new firebase.auth.GoogleAuthProvider();
    firebase.auth().signInWithPopup(provider).then(async result => {
      // var token = result.credential.accessToken;
      var user = result.user;
      var name = user.displayName.split(" ");
      let data = {
        email: user.email,
        firstName: name[0],
        lastName: name[1],
        phone: user.phoneNumber,
        profileImage: user.photoURL,
        loginType: 'google',
        uid: user.uid,
        // phone: this.state.phone,
      }
      this.setState({ userData: data })
      await axios.post('api/users/isexistbyuid', { uid: user.uid }).then(async (response) => {
        // console.log('11111', response);
        if (!response.data.isExist) {
          this.setState({ showPhoneModal: true })
          // await this.saveUserInfo(data);
        } else {
          await this.getInfoUser(data);
        }
      }, (error) => {
        console.log('errr===>', error);
        onOpenAlert({
          type: DATA.ALERT_TYPE.ERROR,
          title: getLanguage('message.fail'),
          message: (error || {}).message,
        })
      });
    }, err => {
      console.log('err', err);
      this.handleSocialError(err.code)
    })
  }

  async saveUserInfo(data) {
    let dataInfo = {
      profileImage: data.profileImage,
      firstName: data.firstName,
      lastName: data.lastName,
      phone: data.phone,
      email: data.email,
      password: data.password ? data.password : null,
      countries: data.countries ? data.countries : null,
      uid: data.uid,
      loginType: data.loginType,
    };
    // console.log('dataInfo ==> ', dataInfo);
    await axios.post('api/users/register', dataInfo).then(async (response) => {
      if (response.data.success) {
        // save data to localStorage
        await this.getInfoUser(dataInfo);
      } else {
        console.log('err', 'login by facebook error');
        onOpenAlert({
          type: DATA.ALERT_TYPE.ERROR,
          title: getLanguage('message.fail'),
          message: getLanguage('message.loginBySocialError'),
        })
      }
    }, (error) => {
      console.log('errr===>', error);
      onOpenAlert({
        type: DATA.ALERT_TYPE.ERROR,
        title: getLanguage('message.fail'),
        message: (error || {}).message,
      })
    });

  }

  getInfoUser(dataInfo) {
    let req = {
      // loginType: dataInfo.loginType,
      // uid: dataInfo.loginType === 'facebook' ? dataInfo.uid : ''
      uid: dataInfo.uid
    }
    axios.post('api/users/uid', req).then(async (response) => {
      if (response.data.validation) {
        setUerAuthLocalStorage(response.data.data);
        redirect(ROUTE_PATH.WELCOME.LINK);
      } else {
        console.log('error save to storage');
        onOpenAlert({
          type: DATA.ALERT_TYPE.ERROR,
          title: getLanguage('message.fail'),
          message: getLanguage('message.cantGetUserInfo'),
        });
      }
    }, (error) => {
      console.log(error);
      onOpenAlert({
        type: DATA.ALERT_TYPE.ERROR,
        title: getLanguage('message.fail'),
        message: (error || {}).message,
      });
    });
  }

  modal = {
    onClickOpenModalSuccess: async () => {
      this.setState({
        showPhoneModal: true,
      })
    },

    onClickCloseModalSuccess: async () => {
      const isValid = this.validateData();
      if (isValid) {
        this.setState({
          showPhoneModal: false
        })
        const data = {
          ...this.state.userData,
          phone: this.state.phone,
          countries: this.state.countries
        }
        await this.saveUserInfo(data);
      } else {
        this.setState({ isInValid: true })
      }
    }
  }

  onChangeField(key, event) {
    if (key === 'phone') {
      this.setState({ isInValid: false })
    }
    this.setState({ [key]: event.target.value });
  }

  handleSelect(event) {
    console.log('event', event.target.value);
    this.setState({
      countries: event.target.value
    });
  }

  onFocusField(field) {
    this.setState({ fieldFocus: field });
  }

  closeAlert() {
    this.setState({
      showModal: false
    })
  }

  render() {
    // const { phone, fieldFocus } = this.state;
    // console.log('phone ==> ', phone);
    return (
      <TransitionContainer
      // motion='overlap-from'
      >
        <Container
          width={VARIABLES.WEBSITE_WIDTH}
          height='100'
          heightUnit='vh'
          bgImage={SHAREDS['bg-login.jpg']}
          bgSize='cover'
          bgRepeat='no-repeat'
          bgPosition='center'
        >
          <Container
            relative
            height='100'
            heightUnit='vh'
            bgColor={VARIABLES.COLORS.OVERLAY_5}
            align='center'
          >
            <Section
              className='is-login-container'
              width='275'
            >
              <Section
                justify='center'
                spacingBottom='120'
              >
                <Image
                  widthRatio='121'
                  widthRatioUnit='px'
                  heightRatio='120'
                  heightRatioUnit='px'
                  src={LOGOS['logo.png']}
                />
              </Section>
              <Section spacingBottom='18'>
                <Button
                  fluid
                  className='is-border-blue'
                  paddingRight='5'
                  paddingBottom='10'
                  paddingLeft='10'
                  bgColor={VARIABLES.COLORS.TRANSPARENT}
                  onClick={() => {
                    redirect(ROUTE_PATH.LOGIN_BY_EMAIL.LINK)
                  }}
                >
                  <Grid justify='space-between'>
                    <Grid.Column>
                      <Section
                        direction='row'
                        align='center'
                      >
                        <Button.Icon spacingRight='15'>
                          <Image
                            widthRatio='24'
                            widthRatioUnit='px'
                            heightRatio='18'
                            heightRatioUnit='px'
                            src={ICONS['ic-email-lightblue.svg']}
                          />
                        </Button.Icon>
                        <Text
                          fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_15}
                          color={VARIABLES.COLORS.BLUE_2}
                        >
                          {getLanguage('login.loginByEmail')}
                        </Text>
                      </Section>
                    </Grid.Column>
                    <Grid.Column>
                      <Button.Icon spacingLeft='15'>
                        <Image
                          widthRatio='17'
                          widthRatioUnit='px'
                          heightRatio='11'
                          heightRatioUnit='px'
                          src={ICONS['ic-arrow-right-lightblue.png']}
                        />
                      </Button.Icon>
                    </Grid.Column>
                  </Grid>
                </Button>
              </Section>
              <Section
                spacingBottom='15'
                justify='center'
              >
                <Text
                  fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_14}
                  color={VARIABLES.COLORS.BLUE_1}
                >
                  {getLanguage('login.or')}
                </Text>
              </Section>
              <Section spacingBottom='40'>
                <Button
                  fluid
                  className='is-border-blue'
                  paddingRight='5'
                  paddingBottom='10'
                  paddingLeft='10'
                  bgColor={VARIABLES.COLORS.TRANSPARENT}
                  onClick={() => {
                    redirect(ROUTE_PATH.LOGIN_BY_PHONE.LINK)
                  }}
                >
                  <Grid justify='space-between'>
                    <Grid.Column>
                      <Section
                        direction='row'
                        align='center'
                      >
                        <Button.Icon spacingRight='15'>
                          <Image
                            widthRatio='24'
                            widthRatioUnit='px'
                            heightRatio='24'
                            heightRatioUnit='px'
                            src={ICONS['ic-phone-lightblue.svg']}
                          />
                        </Button.Icon>
                        <Text
                          fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_15}
                          color={VARIABLES.COLORS.BLUE_2}
                        >
                          {getLanguage('login.loginByPhoneNumber')}
                        </Text>
                      </Section>
                    </Grid.Column>
                    <Grid.Column>
                      <Button.Icon spacingLeft='15'>
                        <Image
                          widthRatio='17'
                          widthRatioUnit='px'
                          heightRatio='11'
                          heightRatioUnit='px'
                          src={ICONS['ic-arrow-right-lightblue.svg']}
                        />
                      </Button.Icon>
                    </Grid.Column>
                  </Grid>
                </Button>
              </Section>
              <Section
                spacingBottom='40'
                justify='center'
              >
                <Text
                  fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_14}
                  color={VARIABLES.COLORS.BLUE_1}
                  top='1'
                >
                  {getLanguage('login.orLoginWith')}
                </Text>
              </Section>
              <Section spacingBottom='18'>
                <Grid gutter='15'>
                  <Grid.Column flex='1'>
                    <Button
                      fluid
                      height={VARIABLES.BUTTON.HEIGHTS.H_40}
                      borderRadius={VARIABLES.BUTTON.BORDER_RADIUSES.BR_10}
                      bgColor={VARIABLES.COLORS.BLUE_2}
                      // onClick={() => { }}
                      onClick={this.handleLoginByFacebook.bind(this)}

                    >
                      <Button.Icon spacingRight='15'>
                        <Image
                          widthRatio='11'
                          widthRatioUnit='px'
                          heightRatio='20'
                          heightRatioUnit='px'
                          src={ICONS['ic-facebook.svg']}
                        />
                      </Button.Icon>
                      <Text
                        fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_16}
                        color={VARIABLES.COLORS.WHITE}
                        top='1'
                      >
                        Facebook
                      </Text>
                    </Button>
                  </Grid.Column>
                  <Grid.Column flex='1'>
                    <Button
                      fluid
                      height={VARIABLES.BUTTON.HEIGHTS.H_40}
                      borderRadius={VARIABLES.BUTTON.BORDER_RADIUSES.BR_10}
                      bgColor={VARIABLES.COLORS.RED_1}
                      // onClick={() => { }}
                      onClick={this.handleLoginByGoogle.bind(this)}
                    >
                      <Button.Icon spacingRight='15'>
                        <Image
                          widthRatio='20'
                          widthRatioUnit='px'
                          heightRatio='20'
                          heightRatioUnit='px'
                          src={ICONS['ic-google.svg']}
                        />
                      </Button.Icon>
                      <Text
                        fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_16}
                        color={VARIABLES.COLORS.WHITE}
                        top='1'
                      >
                        Google
                      </Text>
                    </Button>
                  </Grid.Column>
                </Grid>
              </Section>
              <Section>
                <Button
                  fluid
                  height={VARIABLES.BUTTON.HEIGHTS.H_40}
                  bgColor={VARIABLES.COLORS.TRANSPARENT}
                  borderRadius={VARIABLES.BUTTON.BORDER_RADIUSES.BR_10}
                  borderWidth='1'
                  borderStyle='solid'
                  borderColor={VARIABLES.COLORS.BLUE_2}
                  onClick={() => {
                    redirect(ROUTE_PATH.TERMS_CONDITIONS.LINK)
                  }}
                >
                  <Text
                    fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_16}
                    color={VARIABLES.COLORS.BLUE_2}
                  >
                    {getLanguage('field.buttonSignUp')}
                  </Text>
                </Button>
              </Section>
            </Section>
            <Section
              nonFluid
              className='is-version-number is-version-number-right'>
              <Text
                singleLine
                fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_14}
                color={VARIABLES.COLORS.WHITE}
              >
                Ver {TEXT_VERSION}
              </Text>
            </Section>
          </Container>
        </Container>
        <Modal
          open={this.state.showPhoneModal}
          width={VARIABLES.MODAL.WIDTHS.W_300}
          overlayColor={VARIABLES.COLORS.OVERLAY_3}
          spacing={VARIABLES.MODAL.CONTAINER.C_1.SPACING}
          transition={VARIABLES.TRANSITIONS.DEFAULT}
          transformStart={VARIABLES.MODAL.CONTAINER.C_1.TRANSFORM_START}
          transformEnd={VARIABLES.MODAL.CONTAINER.C_1.TRANSFORM_END}
        // onClickClose={this.modal.onClickCloseModalSuccess}
        >
          <Modal.Section
            paddingTop={VARIABLES.MODAL.HEADER.H_1.PADDING_TOP}
            paddingBottom={VARIABLES.MODAL.HEADER.H_1.PADDING_BOTTOM}
            paddingHorizontal={VARIABLES.MODAL.HEADER.H_1.PADDING_HORIZONTAL}
            borderTopRightRadius={VARIABLES.MODAL.HEADER.H_1.BORDER_TOP_RIGHT_RADIUS}
            borderTopLeftRadius={VARIABLES.MODAL.HEADER.H_1.BORDER_TOP_LEFT_RADIUS}
          >
            <Text
              fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_16}
            >
              {getLanguage('modal.socialInputPhone.title')}
            </Text>
          </Modal.Section>
          <Modal.Section
            paddingBottom={VARIABLES.MODAL.BODY.B_1.PADDING_BOTTOM}
            paddingHorizontal={VARIABLES.MODAL.BODY.B_1.PADDING_HORIZONTAL}
          >
            <Section spacingBottom='27' className='is-register-floating-label'>
              <Fragment>
                <Field
                  error={this.state.isInValid}
                  messageFontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_11}
                  message={this.state.isInValid ? getLanguage('field.phoneIsIncorrectFormat') : ''}
                  fluid
                  autoFocus
                  className='is-phone-id'
                  inputTextType='tel'
                  placeholder='88 888 8888'
                  placeholderColor={VARIABLES.COLORS.GRAY_2}
                  height='28'
                  paddingBottom='10'
                  paddingLeft='112'
                  bgColor={VARIABLES.COLORS.TRANSPARENT}
                  borderBottomWidth='1'
                  borderBottomStyle='solid'
                  borderBottomColor={VARIABLES.COLORS.GRAY_3}
                  fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_15}
                  fontColor={VARIABLES.COLORS.BLACK}
                  onChange={this.onChangeField.bind(this, 'phone')}
                  onFocus={this.onFocusField.bind(this, 'phone')}
                  onBlur={this.onFocusField.bind(this, '')}
                  fieldIcon={
                    <Grid gutter='10'>
                      <Grid.Column>
                        {this.state.countries.toLowerCase() === ENUM.COUNTRY.THAI.toLowerCase() ? <Image
                          widthRatio='27'
                          widthRatioUnit='px'
                          heightRatio='18'
                          heightRatioUnit='px'
                          src={ICONS['ic-flag-th.png']}
                        /> : this.state.countries.toLowerCase() === ENUM.COUNTRY.INDONESIA.toLowerCase() ? <Image
                          widthRatio='27'
                          widthRatioUnit='px'
                          heightRatio='18'
                          heightRatioUnit='px'
                          src={ICONS['ic-flag-in.png']}
                        /> : this.state.countries.toLowerCase() === ENUM.COUNTRY.MALAYSIA.toLowerCase() ? <Image
                          widthRatio='27'
                          widthRatioUnit='px'
                          heightRatio='18'
                          heightRatioUnit='px'
                          src={ICONS['ic-flag-ma.png']}
                        /> : this.state.countries.toLowerCase() === ENUM.COUNTRY.LAOS.toLowerCase() ? <Image
                          widthRatio='27'
                          widthRatioUnit='px'
                          heightRatio='18'
                          heightRatioUnit='px'
                          src={ICONS['ic-flag-la.png']}
                        /> : <Image
                          widthRatio='27'
                          widthRatioUnit='px'
                          heightRatio='18'
                          heightRatioUnit='px'
                          src={ICONS['ic-flag-vn.png']}
                        />}
                      </Grid.Column>
                      <Grid.Column>
                        <Section paddingTop='1'>
                          <select name='' id='select-regis-flag' className='select-flag' onChange={this.handleSelect.bind(this)}>
                            <option value={ENUM.COUNTRY.THAI}>TH +66 </option>
                            <option value={ENUM.COUNTRY.INDONESIA}>INA +62 </option>
                            <option value={ENUM.COUNTRY.MALAYSIA}>MAS +60</option>
                            <option value={ENUM.COUNTRY.LAOS}>LAOS +856</option>
                            <option value={ENUM.COUNTRY.VIETNAM}>VIE +84</option>
                          </select>
                        </Section>
                      </Grid.Column>
                    </Grid>
                  }
                  fieldIconSpacingLeft='10'
                />
              </Fragment>
              <Text
                className={'is-floating-label'}
                fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_15}
                color={VARIABLES.COLORS.GRAY_2}
              >
                {getLanguage('field.phoneNumber')}
                <Text
                  fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_15}
                  color={VARIABLES.COLORS.RED_2}
                >
                  *
                </Text>
              </Text>
            </Section>

          </Modal.Section>
          {/* {this.state.notValid &&
            <Modal.Section
              paddingBottom={VARIABLES.MODAL.FOOTER.F_1.PADDING_BOTTOM}
              paddingHorizontal={VARIABLES.MODAL.FOOTER.F_1.PADDING_HORIZONTAL}
              borderBottomRightRadius={VARIABLES.MODAL.FOOTER.F_1.BORDER_BOTTOM_RIGHT_RADIUS}
              borderBottomLeftRadius={VARIABLES.MODAL.FOOTER.F_1.BORDER_BOTTOM_LEFT_RADIUS}
            >
              <Text
                fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_16}
                color={VARIABLES.COLORS.RED_1}
              >
                Phone is incorrect format
              </Text>
            </Modal.Section>
          } */}
          <Modal.Section
            paddingBottom={VARIABLES.MODAL.FOOTER.F_1.PADDING_BOTTOM}
            paddingHorizontal={VARIABLES.MODAL.FOOTER.F_1.PADDING_HORIZONTAL}
            borderBottomRightRadius={VARIABLES.MODAL.FOOTER.F_1.BORDER_BOTTOM_RIGHT_RADIUS}
            borderBottomLeftRadius={VARIABLES.MODAL.FOOTER.F_1.BORDER_BOTTOM_LEFT_RADIUS}
          >
            <Button
              fluid
              shadow
              className='is-gradient-blue'
              height={VARIABLES.BUTTON.HEIGHTS.H_35}
              borderRadius={VARIABLES.BUTTON.BORDER_RADIUSES.BR_10}
              onClick={this.modal.onClickCloseModalSuccess}
            >
              <Text
                fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_16}
                color={VARIABLES.COLORS.WHITE}
              >
                {getLanguage('field.buttonOK')}
              </Text>
            </Button>
          </Modal.Section>
        </Modal>
      </TransitionContainer>
    )
  }
}
